import Loader from 'App/common/shipwellLoader';
import Map from 'App/components/trimbleMap';
import {convertGeocodeResultsToLocations, makeRouteConfig} from 'App/components/trimbleMap/utils/typed';
import useGeocodeLocation from 'App/components/trimbleMap/utils/useGeocodeLocation';
import {useLegTracking, useDrayageLeg} from 'App/data-hooks';

export const LegMapContainer = ({legId}: {legId: string}) => {
  const legQuery = useDrayageLeg(legId);
  const {queryInfo: legTrackingQuery, trackingLocations} = useLegTracking(legId);

  const {pickupStopInfo, deliveryStopInfo, legInfoDisplayValues} = legQuery.context;

  const pickupGeocodeQuery = useGeocodeLocation(pickupStopInfo?.stop?.location);
  const deliveryGeocodeQuery = useGeocodeLocation(deliveryStopInfo?.stop?.location);

  if (
    legQuery.isInitialLoading ||
    pickupGeocodeQuery.isInitialLoading ||
    deliveryGeocodeQuery.isInitialLoading ||
    legTrackingQuery.isInitialLoading
  ) {
    return <Loader loading />;
  }

  if (
    !pickupGeocodeQuery.data ||
    !deliveryGeocodeQuery.data ||
    !pickupStopInfo?.stop ||
    !deliveryStopInfo?.stop ||
    !pickupStopInfo?.stopDisplayValues ||
    !deliveryStopInfo?.stopDisplayValues
  ) {
    return <span>Error retrieving route</span>;
  }

  const route = makeRouteConfig([pickupGeocodeQuery.data, deliveryGeocodeQuery.data]);

  const stopLocations = convertGeocodeResultsToLocations(
    [pickupGeocodeQuery.data, deliveryGeocodeQuery.data],
    [pickupStopInfo.stopDisplayValues, deliveryStopInfo.stopDisplayValues]
  );

  return (
    <div className="relative h-full">
      <Map
        additionalClassNames="absolute inset-0"
        elementId="shipment-tracking-map"
        route={route}
        locations={[...stopLocations, ...trackingLocations]}
        totalDistance={legInfoDisplayValues?.totalMiles}
      />
    </div>
  );
};
