import {useCallback, useState} from 'react';
import {PaginatedShipmentDocumentMetadata, ShipmentDocumentMetadata} from '@shipwell/backend-core-sdk';
import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {deleteShipmentDocument, getShipmentDocuments, postShipmentDocument} from '../../api/shipment/typed';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const useLegacyShipmentDocuments = (
  shipmentId: string,
  queryOptions?: UseQueryOptions<
    PaginatedShipmentDocumentMetadata,
    unknown,
    PaginatedShipmentDocumentMetadata,
    string[]
  >
) => {
  const shipmentDocumentsQuery = useQuery(
    [SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment id'));
      }
      const response = await getShipmentDocuments(shipmentId);
      return response.data;
    },
    {staleTime: HOT_DATA_STALE_TIME, enabled: !!shipmentId, ...queryOptions}
  );
  return shipmentDocumentsQuery;
};

export const useMutateLegacyShipmentDocuments = (
  shipmentId: string,
  isCarrierDocument = false
): {
  uploadDocument: (args: {file: File; type: string; description: string}) => Promise<ShipmentDocumentMetadata>;
  deleteDocument: (documentId: string) => Promise<void>;
  isMutating: boolean;
} => {
  const client = useQueryClient();
  const [isMutating, setIsMutating] = useState(false);

  const uploadDocument = useCallback(
    async (args: {file: File; type: string; description: string}) => {
      let document: ShipmentDocumentMetadata;
      if (isMutating) {
        throw new Error('Unexpected concurrent mutation');
      }
      setIsMutating(true);
      try {
        document = await postShipmentDocument(shipmentId, args, isCarrierDocument);
        const data = client.getQueryData<PaginatedShipmentDocumentMetadata>([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId]);
        if (Array.isArray(data?.results)) {
          data?.results.push(document);
          client.setQueryData([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId], data);
        }
        return document;
      } finally {
        setIsMutating(false);
      }
    },
    [isMutating, client, shipmentId, isCarrierDocument]
  );

  const deleteDocument = useCallback(
    async (documentId: string) => {
      if (isMutating) {
        throw new Error('Unexpected concurrent mutation');
      }
      setIsMutating(true);
      try {
        await deleteShipmentDocument({shipmentId, documentId});
      } finally {
        setIsMutating(false);
      }
      const data = client.getQueryData<PaginatedShipmentDocumentMetadata>([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId]);
      if (data && Array.isArray(data.results)) {
        client.setQueryData([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId], {
          ...data,
          results: data.results.filter((doc) => doc.id !== documentId)
        });
      }
    },
    [shipmentId, client, isMutating]
  );

  return {
    uploadDocument,
    deleteDocument,
    isMutating
  };
};
