import {array, boolean, object, string} from 'yup';
import {nullablePhoneNumberSchemaField} from 'App/utils/yupHelpersTyped';

export const SUPPLIER_CONTACTS_FORM_SCHEMA = object({
  contacts: array().of(
    object({
      full_name: string().required('Contact name is required'),
      email: string().email('Invalid email').required('Email is required'),
      phone_number: nullablePhoneNumberSchemaField('contact_phone'),
      should_send_notifications: boolean()
    })
  )
});
