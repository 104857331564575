import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Toast} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import {createTenderRequest, getTenderRequests} from 'App/actions/_tenders';
import TenderRequestForm from 'App/formComponents/forms/tendering';
import {cleanPayload} from 'App/utils/globals';
import {getCarrierRelationshipByCarrierId, formatCarrierPOCs} from 'App/utils/carrierHelpers';
import {WORKFLOW_WARNING_OVER_CAPACITY} from 'App/containers/workflows/workflowConstants';
import WithStatusToasts from 'App/components/withStatusToasts';
import {TENDERS_KEY} from 'App/data-hooks/queryKeys';

/**
 * New Tender Request Container
 */
const CreateTenderRequest = (props) => {
  const {
    show,
    onClose,
    dispatch,
    selectedShipment,
    router,
    submitSucceeded,
    shipmentDetailData,
    company,
    selectedCarrier,
    onSuccess,
    setWarning,
    setError
  } = props;
  const [tenderSuccess, setTenderSuccess] = useState(false);
  const [shipmentCreated, setShipmentCreated] = useState(false);
  const [isShipmentDetail, setIsShipmentDetail] = useState(false);
  const [defaultCarrierValue, setDefaultCarrierValue] = useState(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedShipment && selectedShipment.id) {
      setShipmentCreated(true);
    }
  }, [selectedShipment]);

  useEffect(() => {
    if (shipmentDetailData && shipmentDetailData.id) {
      setIsShipmentDetail(true);
    }
  }, [shipmentDetailData]);

  const selectedCarrierId = selectedCarrier?.id || '';

  useEffect(() => {
    const getFormattedCarrierValue = async (carrier) => {
      const options = await getCarrierRelationshipByCarrierId(carrier.id);

      if (options?.length > 0) {
        const formattedOptions = formatCarrierPOCs(options);
        let formattedCarrierValue = null;

        if (carrier.poc) {
          formattedCarrierValue = formattedOptions.filter((poc) => {
            return poc.pocId === carrier.poc && poc.carrierStatus === 'ACTIVE';
          })[0];
        } else if (formattedOptions?.[0]?.carrierStatus === 'ACTIVE') {
          formattedCarrierValue = formattedOptions[0];
        }

        if (formattedCarrierValue) {
          setDefaultCarrierValue(formattedCarrierValue);
        }
      }
    };

    if (selectedCarrierId) {
      getFormattedCarrierValue(selectedCarrier);
    }
  }, [selectedCarrierId]);

  const handleClose = () => {
    onClose();
    if (router && router.location && ['/new-quote', 'new-shipment'].includes(router.location.pathname)) {
      setTimeout(() => {
        if (selectedShipment && selectedShipment.id) {
          router.push(`/marketplace/${selectedShipment.id}/bids`);
        }
      }, 1000);
    }
  };

  /**
   * Create Tender Request
   * @param {*} attrs
   * @param {*} actions
   */
  const handleSubmit = async (attrs, {setErrors, resetForm, setSubmitting}, {showWarning}) => {
    //convert time to ISO
    if (attrs && attrs.expires_at) {
      const expireDate = new Date(attrs.expires_at);
      attrs.expires_at = expireDate.toISOString();
    }
    //get appropriate shipment data
    if (shipmentCreated) {
      attrs.shipment = selectedShipment && selectedShipment.id;
    }
    if (isShipmentDetail) {
      attrs.shipment = shipmentDetailData && shipmentDetailData.id;
    }
    const values = cleanPayload(attrs);
    //build a promise for each tender_to_company
    let promises = [];
    //Add all recipient users to their company's tender
    const recipientUsersCompanyObj =
      values?.tender_to_company &&
      values.tender_to_company.reduce((tenderRequest, carrierObj) => {
        if (!tenderRequest[carrierObj.carrier]) {
          tenderRequest[carrierObj.carrier] = [carrierObj.id];
        } else if (tenderRequest[carrierObj.carrier]) {
          tenderRequest[carrierObj.carrier].push(carrierObj.id);
        }
        return tenderRequest;
      }, {});
    const matchedArray = [];
    values &&
      values.tender_to_company &&
      values.tender_to_company.forEach(({carrier}) => {
        //consolidate involved_tender_to_company_users under company tender, one tender per company
        if (recipientUsersCompanyObj[carrier] && !matchedArray.includes(carrier)) {
          const tenderValues = {...values};
          tenderValues.involved_tender_to_company_users = recipientUsersCompanyObj[carrier];
          matchedArray.push(carrier);
          tenderValues.tender_to_company = carrier;
          promises = [...promises, dispatch(createTenderRequest(tenderValues))];
        }
      });

    try {
      const response = await Promise.all(promises);
      if (response && response.length > 0) {
        dispatch(getTenderRequests({shipmentId: response[0].shipment, createdByCompanyId: company.id}));
        queryClient.invalidateQueries([TENDERS_KEY]);
        setShipmentCreated(false); //we've tendered, dont notify about shipment creation
        if (onSuccess) {
          onSuccess();
        } else {
          showWarning
            ? setWarning('Location Capacity Exceeded!', WORKFLOW_WARNING_OVER_CAPACITY, 'top-right', {
                portal: true
              })
            : setTenderSuccess(true);
          resetForm();
          handleClose();
        }
      }
    } catch (error) {
      //parse error string
      const errors = JSON.parse(error.message);
      if (errors.involved_tender_to_company_users) {
        errors.tender_to_company = errors.involved_tender_to_company_users;
      }
      if (errors._error) {
        setError('Tender Request Failed!', errors._error);
      }
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <div className="tenders">
      <TenderRequestForm
        show={submitSucceeded && show}
        onSubmit={handleSubmit}
        onClose={() => handleClose()}
        shipmentDetailData={shipmentDetailData || {}}
        values={{tender_to_company: defaultCarrierValue ? [defaultCarrierValue] : []}}
      />
      <Toast
        show={tenderSuccess}
        variant="success"
        title="Tender Request Sent!"
        onClose={() => setTenderSuccess(false)}
        portal
      >
        Your tender request(s) were sent to the carriers you selected.
      </Toast>
    </div>
  );
};
export default compose(
  WithStatusToasts,
  connect((state) => ({
    carrierTags: state.vendors.carrierTags,
    authenticated: state.auth.authenticated,
    selectedShipment: state.shipments.selectedShipment,
    company: state.userCompany.company
  }))
)(CreateTenderRequest);
