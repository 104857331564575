import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {FieldArray, reduxForm} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import renderVendorsPOC from 'App/formComponents/renderVendorsPOC';
import * as actions from 'App/actions/vendors';
import {getCarrierRoles} from 'App/actions/_vendors';
import {scrollToFirstErrorField} from 'App/utils/globals';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect(
  (state) => ({
    company: state.auth.company
  }),
  {...actions, getCarrierRoles}
)
export class VendorsPOC extends Component {
  constructor(props) {
    super(props);
    this.editPOC = this.editPOC.bind(this);
    this.deletePOC = this.deletePOC.bind(this);
    this.addPOC = this.addPOC.bind(this);
    this.state = {pocList: []};
  }

  componentDidMount() {
    const {ManageVendorsForm = {}, company} = this.props;
    const {values = {}} = ManageVendorsForm;
    let pocList = [];
    // pre-fill pocList to figure out if we need to set first poc entry to edit
    if (values.point_of_contacts && values.point_of_contacts.length > 1) {
      for (let i = 0; i < values.point_of_contacts.length; i++) {
        pocList.push(false);
      }
    } else if (values.point_of_contacts && values.point_of_contacts.length === 1) {
      if (this.props.edit) {
        pocList = [false];
      } else {
        pocList = [true];
      }
    } else {
      pocList = [true];
    }
    if (
      ManageVendorsForm.syncErrors &&
      ManageVendorsForm.syncErrors.point_of_contacts &&
      ManageVendorsForm.syncErrors.point_of_contacts.length > 0
    ) {
      ManageVendorsForm.syncErrors.point_of_contacts.forEach((poc, index) => {
        pocList[index] = !_.isEmpty(poc);
      });
    }
    if (company && company.id) {
      this.props.getCarrierRoles();
    }
    this.setState({pocList: pocList});
  }

  componentDidUpdate(nextProps) {
    if (this.props.company.id && this.props.company !== nextProps.company) {
      this.props.getCarrierRoles();
    }
  }

  addPOC() {
    const pocList = JSON.parse(JSON.stringify(this.state.pocList));
    pocList.push(true);
    this.setState({pocList: pocList});
  }

  editPOC(idx) {
    const pocList = JSON.parse(JSON.stringify(this.state.pocList));
    pocList[idx] = true;
    this.setState({pocList: pocList});
  }

  deletePOC(idx) {
    const pocList = JSON.parse(JSON.stringify(this.state.pocList));
    pocList.splice(idx, 1);
    this.setState({pocList: pocList});
  }

  resendLoadboardEmail(poc) {
    const {selectedRelationship} = this.props;
    this.props.resendLoadboardInvite(selectedRelationship.id, poc.id).then((response) => {
      if (response.status === 200) {
        this.setState({showSuccess: true}, function () {
          setTimeout(() => {
            this.setState({showSuccess: false});
          }, 2500);
        });
      }
    });
  }

  renderForm() {
    return (
      <FieldArray
        name="point_of_contacts"
        component={renderVendorsPOC}
        props={{
          pocList: this.state.pocList,
          editPOC: this.editPOC,
          deletePOC: this.deletePOC,
          addPOC: this.addPOC,
          selectedRelationship: this.props.selectedRelationship,
          ManageVendorsForm: this.props.ManageVendorsForm,
          featureFlags: this.props.featureFlags,
          resendLoadboardEmail: this.resendLoadboardEmail.bind(this),
          carrierRoles: this.props.carrierRoles
        }}
      />
    );
  }

  render() {
    return (
      <div>
        <Paper>
          <div className="vendors__header">
            <h3>
              <b>Contact(s)</b>
            </h3>
          </div>
          <Element name={`position-vendor.poc`} />
          {this.renderForm()}
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.showSuccess}
          autoHideDuration={2500}
          className="snackbar-success"
          message={'Invite sent!'}
        />
      </div>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(VendorsPOC);
