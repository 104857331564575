import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Field, useFormikContext, Formik, Form} from 'formik';
import classNames from 'classnames';
import {
  Title,
  FormikSelect,
  FormikTextInput,
  FormikDateTimePicker,
  FormikPhoneInput,
  Modal
} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import {DrayageShipmentModeSpecificDataModeEnum} from '@shipwell/corrogo-sdk';
import isNil from 'lodash/isNil';
import {getIdentificationCodesPayload} from '../../create/components/LegForm/validation';
import {portTypes, shipmentDirections, contacts} from 'App/containers/shipments/utils/constants';
import CustomerAccountField from 'App/formComponents/formSections/customerAccountField';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {useUpdateServices, useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import useFormErrorScroll from 'App/utils/hooks/useFormErrorScroll';
import ContainerStopCheckboxField from 'App/containers/shipments/drayage/components/LegDetailsFields/ContainerStopCheckboxField';
import {transformIdentificationCodeScac} from 'App/containers/shipments/utils/typed';

export const LegDetailsFields = ({shipmentId}) => {
  const {values, setFieldValue} = useFormikContext();
  useFormErrorScroll();

  const direction = values.direction;
  useEffect(() => {
    if (direction === shipmentDirections.IMPORT) {
      setFieldValue('containerPickupRequired', false);
    } else if (direction === shipmentDirections.EXPORT) {
      setFieldValue('containerReturnRequired', false);
    }
  }, [direction, setFieldValue]);

  return (
    <>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <CustomerAccountField name="customer" label="Customer Account" required disabled={!!shipmentId} />
      </div>

      <div className="mb-4 grid grid-cols-2 gap-4">
        <div className={classNames('grid auto-rows-max gap-4', {'rounded odd:bg-sw-background p-2': values.direction})}>
          <Field
            required
            disabled={!!shipmentId}
            name="direction"
            label="Import/Export"
            clearable={false}
            simpleValue
            options={[
              {value: shipmentDirections.IMPORT, label: 'Import'},
              {value: shipmentDirections.EXPORT, label: 'Export'}
            ]}
            component={FormikSelect}
          />
          {values.direction === shipmentDirections.IMPORT ? (
            <>
              <Field name="bol_number" label="BOL #" component={FormikTextInput} />
              <Field name="house_bol_number" label="House BOL #" component={FormikTextInput} />
              <Field name="estimated_arrival" label="Estimated Time of Arrival" component={FormikDateTimePicker} />
              <ContainerStopCheckboxField name="containerReturnRequired" label="Container Return Required" />
            </>
          ) : values.direction === shipmentDirections.EXPORT ? (
            <>
              <Field name="booking_number" label="Booking #" required component={FormikTextInput} />
              <Field name="estimated_arrival" label="Estimated Time of Departure" component={FormikDateTimePicker} />
              <Field
                name="early_return_date"
                label="Early Return Date"
                showTimeSelect={false}
                component={FormikDateTimePicker}
              />
              <Field name="cut_date" label="Cut Date" showTimeSelect={false} component={FormikDateTimePicker} />
              <ContainerStopCheckboxField name="containerPickupRequired" label="Container Pickup Required" />
            </>
          ) : null}
        </div>
        <div className={classNames('grid auto-rows-max gap-4', {'rounded bg-sw-background p-2': values.port_type})}>
          <Field
            disabled={!!shipmentId}
            name="port_type"
            label="Ocean/Inland Port"
            clearable
            required
            simpleValue
            options={[
              {value: portTypes.OCEAN, label: 'Ocean Port'},
              {value: portTypes.RAIL, label: 'Inland Port'}
            ]}
            component={FormikSelect}
          />
          {[portTypes.OCEAN, portTypes.RAIL].includes(values.port_type) ? (
            <>
              <Field name="steamship_line" label="Steamship Line" component={FormikTextInput} />
              <Field
                simpleValue
                name="identification_codes[0].value"
                label="Ocean Carrier SCAC"
                component={FormikTextInput}
              />
            </>
          ) : null}
          {values.port_type === portTypes.OCEAN ? (
            <>
              <Field name="vessel_name" label="Vessel Name" component={FormikTextInput} />
              <Field name="voyage_number" label="Voyage Number" component={FormikTextInput} />
            </>
          ) : null}
        </div>
      </div>
      <ThirdPartyContact />
    </>
  );
};

LegDetailsFields.defaultProps = {
  shipmentId: null
};

LegDetailsFields.propTypes = {
  shipmentId: PropTypes.string
};

export const LegFieldsModal = ({onClose, showEditState, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const {
    context: {modeSpecificDisplayValues}
  } = shipmentQuery;

  const {isImport} = modeSpecificDisplayValues;

  if (shipmentQuery.isLoading) {
    return <Loader loading />;
  }

  return (
    <Modal
      title={isImport ? 'Edit Inbound Leg Information' : 'Edit Outbound Leg Information'}
      onClose={onClose}
      show={showEditState}
      footerComponent={null}
      bodyVariant="disableOverflowScroll"
    >
      {isImport ? (
        <ImportLegForm onClose={onClose} shipmentId={shipmentId} />
      ) : (
        <ExportLegForm onClose={onClose} shipmentId={shipmentId} />
      )}
    </Modal>
  );
};

LegFieldsModal.propTypes = {
  onClose: PropTypes.func,
  showEditState: PropTypes.bool,
  shipmentId: PropTypes.string
};

export const ImportLegForm = ({onClose, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const importLegFormValues = get(shipmentQuery, 'context.importLegFormValues');
  const serviceId = importLegFormValues.trackingServiceId;

  const {updateDrayageSpecificDataMutation, updateShipmentReferencesMutation} = useCreateOrUpdateShipment(shipmentId);

  const {
    updateOceanTrackingServiceMutation,
    updateOceanTrackingServiceContactMutation,
    updateRailTrackingServiceMutation,
    updateRailTrackingServiceContactMutation
  } = useUpdateServices(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const handleImportSubmit = (values) => {
    const {
      estimated_arrival,
      actual_arrival,
      last_free_day,
      release_date,
      steamship_line,
      identification_codes,
      vessel_name,
      voyage_number,
      third_party_contact
    } = values;

    const drayageReferences = [];
    const drayageTrackingReference = transformIdentificationCodeScac(identification_codes);
    if (!isNil(drayageTrackingReference)) {
      drayageReferences.push(drayageTrackingReference);
    }

    updateDrayageSpecificDataMutation.mutate({
      shipmentId,
      data: {
        references: drayageReferences,
        import_info: {
          release_date: release_date,
          last_free_day: last_free_day
        },
        mode: DrayageShipmentModeSpecificDataModeEnum.Drayage
      }
    });
    updateShipmentReferencesMutation.mutate({
      shipmentId,
      data: {
        references: drayageReferences
      }
    });

    if (importLegFormValues.port_type === portTypes.OCEAN) {
      updateOceanTrackingServiceMutation.mutate({
        serviceId,
        data: {
          actual_arrival: actual_arrival,
          planned_arrival: estimated_arrival,
          steamship_line: steamship_line,
          identification_codes: getIdentificationCodesPayload(identification_codes),
          vessel_name: vessel_name,
          voyage_number: voyage_number,
          references: drayageReferences
        }
      });

      updateOceanTrackingServiceContactMutation.mutate({
        serviceId,
        contactId: third_party_contact.id,
        data: {
          person_name: `${third_party_contact.first_name || ''} ${third_party_contact.last_name || ''}`.trim() || null,
          email: third_party_contact.email,
          phone: third_party_contact.phone_number,
          contact_type: contacts.OTHER
        }
      });
    } else if (importLegFormValues.port_type === portTypes.RAIL) {
      updateRailTrackingServiceMutation.mutate({
        serviceId,
        data: {
          actual_arrival: actual_arrival,
          planned_arrival: estimated_arrival,
          rail_company: steamship_line,
          identification_codes: getIdentificationCodesPayload(identification_codes),
          references: drayageReferences
        }
      });

      updateRailTrackingServiceContactMutation.mutate({
        serviceId,
        contactId: third_party_contact.id,
        data: {
          person_name: `${third_party_contact.first_name || ''} ${third_party_contact.last_name || ''}`.trim() || null,
          email: third_party_contact.email,
          phone: third_party_contact.phone_number,
          contact_type: contacts.OTHER
        }
      });
    }

    onClose();
  };
  return (
    <Formik onSubmit={handleImportSubmit} initialValues={importLegFormValues}>
      <Form>
        <Title className="pb-2" variant="sectionTitle">
          Import
        </Title>
        <div className="grid grid-cols-2 gap-4">
          <Field
            simpleValue
            name="estimated_arrival"
            label="Estimated Time of Arrival"
            component={FormikDateTimePicker}
          />
          <Field simpleValue name="actual_arrival" label="Actual Arrival Date" component={FormikDateTimePicker} />
          <Field
            showTimeSelect={false}
            simpleValue
            name="last_free_day"
            label="Last Free Date"
            component={FormikDateTimePicker}
          />
          <Field
            showTimeSelect={false}
            simpleValue
            name="release_date"
            label="Release Date"
            component={FormikDateTimePicker}
          />
        </div>
        <div className="mb-4 border-b border-sw-border pb-4" />
        {importLegFormValues.port_type === portTypes.OCEAN ? <OceanPort /> : <InlandPort />}
        <div className="mb-4 border-b border-sw-border pb-4" />
        <ThirdPartyContact />
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
};

ImportLegForm.propTypes = {
  onClose: PropTypes.func,
  shipmentId: PropTypes.string
};

export const ExportLegForm = ({onClose, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const exportLegFormValues = get(shipmentQuery, 'context.exportLegFormValues');
  const serviceId = exportLegFormValues.trackingServiceId;

  const {
    updateOceanTrackingServiceMutation,
    updateOceanTrackingServiceContactMutation,
    updateRailTrackingServiceMutation,
    updateRailTrackingServiceContactMutation
  } = useUpdateServices(shipmentId);

  const {updateDrayageSpecificDataMutation} = useCreateOrUpdateShipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const handleExportSubmit = (values) => {
    const {
      return_date,
      cut_date,
      steamship_line,
      identification_codes,
      vessel_name,
      voyage_number,
      estimated_arrival,
      actual_arrival,
      third_party_contact
    } = values;

    const drayageReferences = [];
    const drayageTrackingReference = transformIdentificationCodeScac(identification_codes);
    if (!isNil(drayageTrackingReference)) {
      drayageReferences.push(drayageTrackingReference);
    }
    updateDrayageSpecificDataMutation.mutate({
      shipmentId,
      data: {
        references: drayageReferences,
        export_info: {
          return_date: return_date,
          cut_date: cut_date
        },
        mode: DrayageShipmentModeSpecificDataModeEnum.Drayage
      }
    });

    if (exportLegFormValues.port_type === portTypes.OCEAN) {
      updateOceanTrackingServiceMutation.mutate({
        serviceId,
        data: {
          actual_arrival: actual_arrival,
          planned_arrival: estimated_arrival,
          steamship_line: steamship_line,
          identification_codes: getIdentificationCodesPayload(identification_codes),
          vessel_name: vessel_name,
          voyage_number: voyage_number,
          references: drayageReferences
        }
      });

      updateOceanTrackingServiceContactMutation.mutate({
        serviceId,
        contactId: third_party_contact.id,
        data: {
          person_name: `${third_party_contact.first_name || ''} ${third_party_contact.last_name || ''}`.trim() || null,
          email: third_party_contact.email,
          phone: third_party_contact.phone_number,
          id: third_party_contact.id,
          contact_type: contacts.OTHER
        }
      });
    } else {
      updateRailTrackingServiceMutation.mutate({
        serviceId,
        data: {
          actual_arrival: actual_arrival,
          planned_arrival: estimated_arrival,
          rail_company: steamship_line,
          identification_codes: getIdentificationCodesPayload(identification_codes),
          references: drayageReferences
        }
      });

      updateRailTrackingServiceContactMutation.mutate({
        serviceId,
        contactId: third_party_contact.id,
        data: {
          person_name: `${third_party_contact.first_name || ''} ${third_party_contact.last_name || ''}`.trim() || null,
          email: third_party_contact.email,
          phone: third_party_contact.phone_number,
          id: third_party_contact.id,
          contact_type: contacts.OTHER
        }
      });
    }

    onClose();
  };

  return (
    <Formik onSubmit={handleExportSubmit} initialValues={exportLegFormValues}>
      <Form>
        <Title className="pb-2" variant="sectionTitle">
          Export
        </Title>
        <div className="grid grid-cols-2 gap-4">
          <Field
            simpleValue
            name="estimated_arrival"
            label="Estimated Time of Arrival"
            component={FormikDateTimePicker}
          />
          <Field simpleValue showTimeSelect={false} name="cut_date" label="Cut Date" component={FormikDateTimePicker} />
          <Field
            simpleValue
            showTimeSelect={false}
            name="return_date"
            label="Early Return Date"
            component={FormikDateTimePicker}
          />
        </div>
        <div className="mb-4 border-b border-sw-border pb-4" />
        {exportLegFormValues.port_type === portTypes.OCEAN ? <OceanPort /> : <InlandPort />}
        <div className="mb-4 border-b border-sw-border pb-4" />
        <ThirdPartyContact />
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
};

ExportLegForm.propTypes = {
  onClose: PropTypes.func,
  shipmentId: PropTypes.string
};

const InlandPort = () => {
  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        Inland Port
      </Title>
      <div className="grid grid-cols-2 gap-4">
        <Field simpleValue name="steamship_line" label="Steamship Line" component={FormikTextInput} />
        <Field
          simpleValue
          name="identification_codes[0].value"
          label="Ocean Carrier SCAC"
          component={FormikTextInput}
        />
      </div>
    </>
  );
};

const OceanPort = () => {
  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        Ocean Port
      </Title>
      <div className="grid grid-cols-2 gap-4">
        <Field simpleValue name="steamship_line" label="Steamship Line" component={FormikTextInput} />
        <Field
          simpleValue
          name="identification_codes[0].value"
          label="Ocean Carrier SCAC"
          component={FormikTextInput}
        />
        <Field simpleValue name="vessel_name" label="Vessel Name" component={FormikTextInput} />
        <Field simpleValue name="voyage_number" label="Voyage Number" component={FormikTextInput} />
      </div>
    </>
  );
};

const ThirdPartyContact = () => {
  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        3rd Party Contact
      </Title>
      <div className="mb-10 grid grid-cols-2 gap-4">
        <div className="flex items-center justify-around gap-2">
          <div className="grow">
            <Field name="third_party_contact.first_name" label="First Name" component={FormikTextInput} />
          </div>
          <div className="grow">
            <Field name="third_party_contact.last_name" label="Last Name" component={FormikTextInput} />
          </div>
        </div>
        <Field name="third_party_contact.phone_number" label="Phone Number" component={FormikPhoneInput} />
        <Field name="third_party_contact.email" label="Email" component={FormikTextInput} />
      </div>
    </>
  );
};
