import {MouseEvent} from 'react';
import {IconButtonWithTooltip} from '@shipwell/shipwell-ui';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {stopWatchingShipment, watchShipment} from 'App/api/shipwellUI/typed';
import {SHIPMENTS_DASHBOARD_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const WatchShipmentToggle = ({
  shipmentId,
  isWatched = false,
  isDisabled = false
}: {
  shipmentId: string;
  isWatched?: boolean | null;
  isDisabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toggleWatchMutation = useMutation(
    (shipmentId: string) => {
      if (isWatched) {
        return stopWatchingShipment(shipmentId);
      }
      return watchShipment(shipmentId);
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
      }
    }
  );

  return (
    <IconButtonWithTooltip
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        toggleWatchMutation.mutate(shipmentId);
      }}
      disabled={isDisabled}
      isActive={isWatched ?? undefined}
      isLoading={toggleWatchMutation.isLoading}
      iconName="Visibility"
      aria-label={isDisabled ? 'Shipment is older than 7 days' : isWatched ? 'Watched shipment' : 'Watch shipment'}
    />
  );
};
