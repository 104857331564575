import {PDFDocument} from 'pdf-lib';
import isString from 'lodash/isString';
import {DocumentMetadata} from '@shipwell/backend-core-sdk';

function getFilesToMerge(selectedDocumentIds: string[], bolDocuments: DocumentMetadata[]) {
  if (selectedDocumentIds.length > 0) {
    const selectedBolDocuments = bolDocuments.filter((bol) => {
      return bol?.id ? selectedDocumentIds.includes(bol.id) : false;
    });

    return selectedBolDocuments.map((document) => document.file).filter(isString);
  }
  return bolDocuments.map((document) => document.file).filter(isString);
}

export async function mergePdf(selectedDocumentIds: string[], bolDocuments: DocumentMetadata[]) {
  try {
    const pdfsToMerge = getFilesToMerge(selectedDocumentIds, bolDocuments);

    const mergedPdf = await PDFDocument.create();
    for (const pdfCopyDoc of pdfsToMerge) {
      const pdfBytes = await fetch(pdfCopyDoc).then((res) => res.arrayBuffer());
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const req = await mergedPdf.save();
    return req;
  } catch (error) {
    console.error((error as Error).message);
  }
}
