import {object, mixed, string} from 'yup';

export const alertTypeConstants = {
  TAGGED_SHIPMENT: 'TAGGED_SHIPMENT',
  NOT_TRACKING: 'NOT_TRACKING',
  LATE_PICKUP: 'LATE_PICKUP',
  LATE_DELIVERY: 'LATE_DELIVERY',
  INACTIVE_CARRIER: 'INACTIVE_CARRIER',
  CARRIER_NOT_ASSIGNED: 'CARRIER_NOT_ASSIGNED',
  WATCHED_SHIPMENT: 'WATCHED_SHIPMENT',
  RUNNING_LATE: 'RUNNING_LATE',
  RESOLVED_ALERTS: 'RESOLVED_ALERTS',
  DETENTION: 'DETENTION',
  CARRIER_INSURANCE_EXPIRED: 'CARRIER_INSURANCE_EXPIRED',
  TRACKING_AT_RISK: 'TRACKING_AT_RISK'
};

export const alertActionConstants = {
  RESOLVE_OR_UNRESOLVE: 'RESOLVE_OR_UNRESOLVE',
  ASSIGN_USER: 'ASSIGN_USER'
};

export const sampleAlerts = [
  {
    id: '1',
    enabled: true,
    type: alertTypeConstants.CARRIER_NOT_ASSIGNED,
    name: 'Carrier Not Assigned',
    threshold: {
      value: 28
    },
    manual_resolve: true
  },
  {
    id: '2',
    enabled: true,
    type: alertTypeConstants.INACTIVE_CARRIER,
    name: 'Carrier Status',
    manual_resolve: true
  },
  {
    id: '3',
    enabled: true,
    type: alertTypeConstants.LATE_DELIVERY,
    name: 'Late Delivery',
    threshold: {
      value: 4
    },
    manual_resolve: false
  },
  {
    id: '4',
    enabled: true,
    type: alertTypeConstants.LATE_PICKUP,
    name: 'Late Pickup',
    threshold: {
      value: 4
    },
    manual_resolve: false
  },
  {
    id: '5',
    enabled: false,
    type: alertTypeConstants.NOT_TRACKING,
    name: 'No Tracking',
    threshold: {
      value: 2
    },
    manual_resolve: false
  },
  {
    id: '6',
    name: 'Sketchy Tagged Shipments',
    threshold: {
      value: 'abc 1'
    },
    type: alertTypeConstants.TAGGED_SHIPMENT,
    manual_resolve: true,
    enabled: true
  },
  {
    id: '7',
    type: 'RESOLVED_ALERTS',
    name: 'Resolved Alerts',
    threshold: {
      value: 168
    },
    enabled: true,
    manual_resolve: false
  }
];

export const sampleAlertTypes = [
  {
    type: alertTypeConstants.CARRIER_NOT_ASSIGNED,
    title: 'Carrier Not Assigned Alert',
    short_description: 'Carrier has not been assigned to shipment',
    description:
      'The ‘Carrier Not Assigned’ alert will allow the system to generate an alert if a carrier is not yet assigned to a shipment within a set amount of hours of the shipment’s pick up date and time.',
    resolve_description:
      'This alert will remain active until a carrier is assigned to the shipment or the alert gets manually resolved by a user on the Compass Alert Dashboard.',
    threshold_definition: {
      type: 'counter',
      description: 'Hours prior to a shipment’s Pickup Date and Time.',
      min: 24,
      max: 168
    },
    unique: true
  },
  {
    type: alertTypeConstants.INACTIVE_CARRIER,
    title: 'Carrier Status Alert',
    short_description: 'Carrier status has changed from Active to Inactive or DNU',
    description:
      'The ‘Carrier Status’ alert will allow the system to generate an alert if a carrier is assigned to a shipment and the carrier status changes to ‘Inactive’ or ‘Do Not Use’. This alert will apply to all shipments that have not completed the delivery at their final stop, and all future shipments not yet in transit.',
    resolve_description:
      'This alert will remain active until a carrier is removed from the shipment, the carrier status changes to ‘Active’, or the shipment completes delivery at the final stop.',
    unique: true
  },
  {
    type: alertTypeConstants.LATE_DELIVERY,
    title: 'Late Delivery Alert',
    short_description: 'Scheduled date/time for Delivery has passed',
    description:
      'The ‘Late Delivery’ alert will allow the system to generate an alert if a shipment is not delivered within a set amount of hours of the shipment’s delivery date and time.',
    resolve_description:
      'This alert will remain active until the stop status changed to ‘At Dropoff’, the Shipment status changes to ‘At Delivery’ or ‘Delivered’, or the alert gets manually resolved by a user on the Compass Alert Dashboard.',
    threshold_definition: {
      type: 'counter',
      description: 'Hours after the scheduled shipment delivery date and time.',
      min: 1,
      max: 48
    },
    unique: true
  },
  {
    type: alertTypeConstants.LATE_PICKUP,
    title: 'Late Pickup Alert',
    short_description: 'Scheduled date/time for Pick Up has passed',
    description:
      'The ‘Late Pickup’ alert will allow the system to generate an alert if a shipment is not picked up within a set amount of hours of the shipment’s pick up date and time.',
    resolve_description:
      'This alert will remain active until the shipment status changes to ‘At Pickup’ or ‘In Transit’, or the alert gets manually resolved by a user on the Compass Alert Dashboard.',
    threshold_definition: {
      type: 'counter',
      description: 'Hours after the scheduled shipment pick up date and time.',
      min: 1,
      max: 48
    },
    unique: true
  },
  {
    type: alertTypeConstants.NOT_TRACKING,
    title: 'No Tracking',
    short_description: 'No ETA or location updates have occured',
    description:
      'The ‘No Tracking’ alert will allow the system to generate an alert if a shipment is not updated with any ETA or location updates for a set amount of hours after the shipment status changes to ‘In Transit’.',
    resolve_description:
      'This alert will remain active until an ETA or location update occurs on the shipment, or the alert gets manually resolved by a user on the Compass Alert Dashboard.',
    threshold_definition: {
      type: 'counter',
      description:
        'Hours after the shipment status has changed to ‘In Transit’ and no ETA or location updates have occurred.',
      min: 1,
      max: 48
    },
    unique: true
  },
  {
    type: alertTypeConstants.TAGGED_SHIPMENT,
    title: 'Tagged Shipment Alert',
    short_description: 'Alert on any shipment containing this tag',
    description: 'Fire an alert when a shipment has tags...',
    resolve_description:
      'This alert will remain active until the Tag is removed from the shipment, the Shipment status changes to ‘Delivered’ or ‘Cancelled’, or the alert gets manually resolved by a user on the Compass Alert Dashboard.',
    threshold_definition: {
      type: 'tag'
    },
    unique: false
  },
  {
    type: alertTypeConstants.RESOLVED_ALERTS,
    title: 'Resolved',
    description:
      "The 'Resolved' alert will allow the user to view all resolved shipment alerts in the Compass Alert Dashboard. This enables a user to view how many issues were resolved for a given timeframe.",
    short_description: 'Alerts resolved within a given timeframe',
    resolve_description:
      'This alert will remain active until the current date exceeds the threshold value since the original alert was resolved.',
    threshold_definition: {
      type: 'counter',
      description: 'Hours after the original alert was changed to Resolved.',
      min: 1,
      max: 168
    },
    unique: true
  }
];

export const headers = {
  statusHeader: 'STATUS',
  remainingHeaders: ['ALERT NAME', 'ALERT DESCRIPTION', 'THRESHOLD']
};

export const createValidationSchema = (duplicateNames) =>
  object().shape({
    threshold: mixed().required('This field must have a value set.'),
    name: string()
      .required(' ')
      .test('name', 'Duplicate Alert Name, must be unique alert name.', (value) => !duplicateNames.includes(value))
  });

export const sortAlerts = (alerts) => {
  return alerts.sort((a, b) => {
    if (a.enabled && !b.enabled) {
      return -1;
    }

    if (!a.enabled && b.enabled) {
      return 1;
    }

    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
};
