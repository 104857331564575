import {Fragment} from 'react';
import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {NetsuiteCustomField} from '@shipwell/backend-core-sdk';
import MappingFormFieldsHeader from 'App/formComponents/forms/netSuiteDataMapping/components/mappingFormFieldsHeader';

const shipmentDetailsFields = [
  {
    id: 'shipment_id_ns_field_id',
    label: 'Shipment ID',
    type: 'transactionBodyCustomField',
    required: false
  },
  {
    id: 'service_date_ns_field_id',
    label: 'Service Date',
    type: 'transactionBodyCustomField',
    required: false
  }
];

const ShipmentDetailsMappingFormFields = ({options}: {options: NetsuiteCustomField[]}) => (
  <>
    <MappingFormFieldsHeader labelLeft="Shipment Details" labelRight="Custom Fields" />
    <div className="mt-3 grid grid-cols-2 items-start justify-items-stretch gap-x-[10px] gap-y-1">
      {shipmentDetailsFields.map((field) => (
        <Fragment key={field.id}>
          <div className="flex h-full flex-col justify-center">{field.label}</div>
          <Field
            simpleValue
            clearable={!field.required}
            name={`custom_fields.${field.id}`}
            label="Custom NetSuite Field"
            component={FormikSelect}
            options={Array.isArray(options) ? options.filter((option) => option.type === field.type) : []}
            getOptionLabel={(option: NetsuiteCustomField) => option.field_name}
            getOptionValue={(option: NetsuiteCustomField) => option.id}
            required={field.required}
          />
        </Fragment>
      ))}
    </div>
  </>
);

export default ShipmentDetailsMappingFormFields;
