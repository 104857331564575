import {Formik, Form, Field, useFormikContext, FieldArray, FormikHelpers} from 'formik';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import noop from 'lodash/noop';
import first from 'lodash/first';
import {AxiosError} from 'axios';
import {
  Modal,
  StepIndicator,
  DeprecatedButton,
  useStepIndicator,
  FormikDateTimePicker,
  FormikTextarea
} from '@shipwell/shipwell-ui';
import {
  CreateDrayageTransportationOrderFromMultipleLegs,
  ChargeCategory,
  CreateChargeLegItem,
  DrayageTransportationOrder,
  ShipwellApiError
} from '@shipwell/corrogo-sdk';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import CarrierField from 'App/formComponents/fields/carrierSelect';
import ContainerSelect, {useContainerOptions} from 'App/containers/shipments/components/TenderFields/ContainerSelect';
import Loader from 'App/common/shipwellLoader';
import {createTransportationOrder} from 'App/api/corrogo';
import RateDetails from 'App/containers/shipments/components/TenderFields/RateDetails';
import type {TenderFormValues} from 'App/containers/shipments/components/TenderFields/validation';
import {validationSchema} from 'App/containers/shipments/components/TenderFields/validation';
import AddAccessorialForm from 'App/containers/shipments/components/TenderFields/AddAccessorialForm';
import ContainersView from 'App/containers/shipments/components/TenderFields/ContainersView';
import withStatusToasts from 'App/components/withStatusToasts';
import type {WithStatusToastProps} from 'App/components/withStatusToasts';
import {TRANSPORTATION_ORDER_QUERY_KEY, SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useV3Shipment} from 'App/data-hooks';

export const AccessorialsForm = ({onPrevious, shipmentId}: {onPrevious(): void; shipmentId: string}) => (
  <>
    <div className="mb-10 grid gap-y-4">
      <span>
        In addition to the line haul & fuel surcharge you can add accessorials to containers. Accessorials can be added
        to all containers or individual containers. You can also add, remove or edit accessorials any time on the
        financials tab of the shipment or container.
      </span>
      <FieldArray
        name="debits"
        render={(arrayHelpers) => (
          <>
            <AddAccessorialForm shipmentId={shipmentId} push={arrayHelpers.push} />
            <div className="border-b border-sw-border" />
            <ContainersView shipmentId={shipmentId} remove={(index) => arrayHelpers.remove(index)} />
          </>
        )}
      />
    </div>
    <ModalFormFooter
      primaryActionName="Send Tender Request"
      options={
        <DeprecatedButton variant="tertiary" onClick={onPrevious}>
          Back to Tender Details
        </DeprecatedButton>
      }
    />
  </>
);

const TenderDetailsForm = ({onNextStep, shipmentId}: {onNextStep(): void; shipmentId: string}) => {
  const {validateForm, setErrors} = useFormikContext();

  const handleNextStep = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    onNextStep();
  };

  return (
    <>
      <div className="mb-10 grid gap-y-4">
        <CarrierField />
        <ContainerSelect shipmentId={shipmentId} />
        <RateDetails />
        <Field
          className="border-t border-sw-border pt-4"
          component={FormikDateTimePicker}
          name="expiration"
          label="Expiration"
        />
        <Field component={FormikTextarea} name="message" label="Custom Message (optional)" />
      </div>
      <ModalFormFooter
        primaryActionName="Next - Add Accessorials"
        onPrimaryActionClick={handleNextStep}
        options={
          <DeprecatedButton variant="tertiary" type="submit">
            Skip Accessorials & Send Tender Request{' '}
          </DeprecatedButton>
        }
      />
    </>
  );
};

function createServiceProviderPayload(values: TenderFormValues) {
  if (!values.carrier) {
    return;
  }
  const companyName =
    values.carrier.carrierRelationship.shipwell_vendor?.dba_name ||
    values.carrier.carrierRelationship.shipwell_vendor?.name ||
    'Unknown Carrier';
  return {
    service_provider: {
      provider_id: values.carrier.carrierFAVRid,
      identification_codes: values.carrier.carrierRelationship?.shipwell_vendor?.identifying_codes?.map((code) => ({
        qualifier: code.type,
        value: code.value
      })),
      name: companyName
    },
    service_provider_contacts: values.carrier.carrierRelationship?.point_of_contacts?.map((poc) => ({
      system_id: poc.id,
      company_name: companyName,
      person_name: `${poc.first_name || ''} ${poc.last_name || ''}`.trim(),
      email: poc.email || '',
      phone: poc.phone_number || ''
    }))
  };
}

interface TenderModalProps {
  showModal: boolean;
  onClose(): void;
  shipmentId: string;
  onSuccess?(): void;
}
const TenderModal = ({
  showModal,
  onClose = noop,
  shipmentId,
  onSuccess = noop,
  setError
}: TenderModalProps & WithStatusToastProps) => {
  const steps = ['Tender Details', 'Accessorials'];
  const {activeStep, setNextStep, setPreviousStep} = useStepIndicator(steps);
  const {tenderableContainerOptions, queryInfo} = useContainerOptions(shipmentId);

  const shipmentQuery = useV3Shipment(shipmentId);

  const createTransportationOrderMutation = useMutation<
    Awaited<ReturnType<typeof createTransportationOrder>>,
    AxiosError<DrayageTransportationOrder, CreateDrayageTransportationOrderFromMultipleLegs>,
    {data: CreateDrayageTransportationOrderFromMultipleLegs}
  >(({data}) => createTransportationOrder(data));

  const queryClient = useQueryClient();

  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }

  const handleSubmit = (values: TenderFormValues, actions: FormikHelpers<TenderFormValues>) => {
    if (!values.containers || !values.carrier) {
      return;
    }
    const serviceProviderPayload = createServiceProviderPayload(values);
    if (!serviceProviderPayload) {
      return;
    }
    const rateDebits = values.containers
      .map((container) =>
        (
          [
            {
              quantity: 1,
              unit_amount: {
                value: parseFloat(values.lineHaul)
              },
              category: ChargeCategory.LineHaul,
              description: 'Line haul',
              leg_item_id: queryInfo.getItemFromLeg(container.value)?.id
            },
            values.fuelIncludedInLineHaul
              ? undefined
              : {
                  quantity: 1,
                  unit_amount: {
                    value: (parseFloat(values.fuelSurcharge || '0') / 100) * parseFloat(values.lineHaul)
                  },
                  category: ChargeCategory.FuelSurcharge,
                  description: `${values.fuelSurcharge || 0}${values.fuelSurchargeUnit}`,
                  leg_item_id: queryInfo.getItemFromLeg(container.value)?.id
                }
          ] as (CreateChargeLegItem | undefined)[]
        ).filter((debit): debit is CreateChargeLegItem => debit !== undefined)
      )
      .flat();
    createTransportationOrderMutation.mutate(
      {
        data: {
          ...serviceProviderPayload,
          initial_debits: [...rateDebits, ...values.debits],
          expiration: values.expiration || undefined,
          message: values.message || undefined,
          leg_ids: values.containers.map((container) => container.value),
          instructions: shipmentQuery.data?.instructions || undefined
        }
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
          onSuccess();
          onClose();
          return void queryClient.invalidateQueries([SHIPMENTS_QUERY_KEY, shipmentId]);
        },
        onError: (error) => {
          if (!error) {
            return;
          }

          // Manually cast as unknown because Typescript thinks these are built-in Error[]
          // Cast the error we care about to ShipwellApiError, which it is but is incorrectly typed by the SDK
          const firstError = first(error.response?.data as unknown as ShipwellApiError[]);

          if (!firstError) {
            return;
          }

          const errorDetail = firstError.detail || 'Unknown error occurred.';
          const errorMessage = `${firstError.title}: ${errorDetail}`;
          setError('Error!', `Error creating tender request: ${errorMessage}`, undefined, {delay: 300000});
        },
        onSettled: () => void queryClient.invalidateQueries([TRANSPORTATION_ORDER_QUERY_KEY, shipmentId])
      }
    );
  };

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      title="Tender To Carrier"
      footerComponent={null}
      bodyVariant="disableOverflowScroll"
    >
      <div className="flex justify-center py-4">
        <StepIndicator steps={steps} activeStep={activeStep} alternativeLabel />
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          {
            carrier: null,
            containers: tenderableContainerOptions,
            lineHaul: '',
            fuelSurcharge: '',
            fuelSurchargeUnit: '%',
            fuelIncludedInLineHaul: false,
            expiration: null,
            message: '',
            debits: [] as CreateChargeLegItem[]
          } as TenderFormValues
        }
        onSubmit={handleSubmit}
      >
        {() => (
          <Form noValidate>
            {activeStep === 0 ? (
              <TenderDetailsForm onNextStep={setNextStep} shipmentId={shipmentId} />
            ) : (
              <AccessorialsForm onPrevious={setPreviousStep} shipmentId={shipmentId} />
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default withStatusToasts<TenderModalProps>(TenderModal);
