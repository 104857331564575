import {RouteComponentProps} from 'react-router';
import ErrorPage from 'App/common/ErrorPage';
import {useDrayageLeg} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import {LegDetailsController} from 'App/containers/shipments/details';

const LegDetails = ({params}: RouteComponentProps<{leg_id: string}, {leg_id: string}>) => {
  const legId = params.leg_id;

  const drayageLegQuery = useDrayageLeg(legId, {refetchOnWindowFocus: false});

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading={drayageLegQuery.isInitialLoading} />;
  }

  if (drayageLegQuery.isError) {
    return (
      <ErrorPage
        primaryMessage="Sorry, we couldn't find this leg."
        secondaryMessage="Please check your URL and try again, or contact your account admin for assistance."
      />
    );
  }

  return <LegDetailsController legId={legId} />;
};

export default LegDetails;
