import {Component} from 'react';
import {Button, InputGroup, Checkbox} from 'react-bootstrap';
import {DateTimePicker} from 'react-widgets';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import {connect} from 'react-redux';
import * as actions from 'App/actions/shipments';
import * as shipmentActions from 'App/actions/_shipments';
import ShipmentStatuses from 'App/components/shipmentStatuses';
import {cleanPayload} from 'App/containers/quotes/create/utils/createQuote';
import {numberWithCommas, truncateNumber, pickupIcon, deliveryIcon} from 'App/utils/globals';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import '../CreateMultipleShipments/style.scss';
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

@connect(
  (state) => ({
    shipmentModes: state.shipments.shipmentModes,
    equipmentTypes: state.shipments.equipmentTypes,
    shipmentStatuses: state.shipments.shipmentStatuses,
    unitPreferences: state.userCompany.unitPreferences
  }),
  {...actions, ...shipmentActions}
)
class CreateMultipleConfirmation extends Component {
  state = {shipmentData: [], savingShipments: false, showReadOnlyTable: false};

  componentDidMount() {
    this.props.fetchShipmentStatuses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.creatingShipments !== this.props.creatingShipments && this.props.createdShipments) {
      this.setState({shipmentData: this.props.createdShipments});
    }
  }

  renderEditable(cellInfo) {
    return (
      <div
        className="multipleShipments__editable"
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const shipmentData = [...this.state.shipmentData];
          shipmentData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({shipmentData});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.shipmentData[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  renderEditableDropdown(cellInfo) {
    const {shipmentStatuses} = this.props;
    const value = shipmentStatuses[cellInfo.index][cellInfo.column.id];
    return (
      <ShipmentStatuses
        simpleValue
        placeholder="Status"
        labelKey="name"
        valueKey="id"
        name="state"
        value={value}
        onChange={(value) => {
          const data = [...this.state.shipmentData];
          data[cellInfo.index].state = value;
          this.setState({shipmentData: data});
        }}
      />
    );
  }

  renderEditableCheckbox(cellInfo) {
    return (
      <div className="multipleShipments__editable-enabled">
        <InputGroup>
          <Checkbox
            checked={this.state.shipmentData[cellInfo.index] && this.state.shipmentData[cellInfo.index].metadata.open}
            onChange={(e) => {
              const data = [...this.state.shipmentData];
              data[cellInfo.index].metadata.open = e.target.checked;
              this.setState({shipmentData: data});
            }}
          />
        </InputGroup>
      </div>
    );
  }

  renderEditableDatePicker(cellInfo) {
    const index = cellInfo.column.id === 'pickup' ? 0 : 1;
    const value = moment(this.state.shipmentData[cellInfo.index].stops[index].planned_date).toDate();
    return (
      <div className="multipleShipments__editable-enabled">
        <DateTimePicker
          dropUp
          format={'MMM D, YYYY'}
          value={value}
          onChange={(value) => {
            const data = [...this.state.shipmentData];
            data[cellInfo.index].stops[index].planned_date = moment(value).format('YYYY-MM-DD');
            this.setState({shipmentData: data});
          }}
          min={moment().toDate()}
          time={false}
        />
      </div>
    );
  }

  renderNormalCell(cellInfo) {
    return <div>{this.state.shipmentData[cellInfo.index][cellInfo.column.id]}</div>;
  }

  async handleSaveShipments() {
    this.setState({savingShipments: true, saved: 0});
    const shipmentsToSave = this.state.shipmentData;

    await this.performAllSaves(shipmentsToSave).then((results) => {
      this.setState({savingShipments: false, showReadOnlyTable: true});
    });
  }

  async performAllSaves(shipmentsToSave) {
    const results = [];
    for (const shipment of shipmentsToSave) {
      const payload = cleanPayload(shipment, {oldValue: ['', null, 'null', NaN], hardDelete: false});

      const opts = payload.customer.id ? {xCompanyId: payload.customer.id} : {};
      //add the xShipperId

      const request = this.props.editShipment(payload.id, payload, opts);

      results.push(await this.handleShipmentPut(request));
    }
    return results;
  }

  async handleShipmentPut(request) {
    return await request.then((response) => {
      this.setState({saved: this.state.saved + 1});
      return response;
    });
  }

  render() {
    const {
      open,
      shipmentModes,
      equipmentTypes,
      createdShipments,
      created,
      totalToCreate,
      infoOnly,
      numberToCreate,
      unitPreferences
    } = this.props;
    const {shipmentData, savingShipments, showReadOnlyTable, saved} = this.state;

    const shipment = createdShipments.length && createdShipments[0];
    let pickupLocationString = '';
    let pickupTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length && shipment.stops[0]) {
      pickupTimeString += `${moment(shipment.stops[0].planned_date).format('ddd, MMM D')} ${moment(
        shipment.stops[0].planned_time_window_start,
        'HH:mm:ss'
      ).format('HH:mm')} - ${moment(shipment.stops[0].planned_time_window_end, 'HH:mm:ss').format('HH:mm')}`;
      if (shipment.stops[0].location && shipment.stops[0].location.address) {
        pickupLocationString +=
          shipment.stops[0].location.address.city + ', ' + shipment.stops[0].location.address.state_province;
      }
    }

    let deliveryLocationString = '';
    let deliveryTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length > 1) {
      deliveryTimeString = `${moment(shipment.stops[shipment.stops.length - 1].planned_date).format(
        'ddd, MMM D'
      )} ${moment(shipment.stops[shipment.stops.length - 1].planned_time_window_start, 'HH:mm:ss').format(
        'HH:mm'
      )} - ${moment(shipment.stops[shipment.stops.length - 1].planned_time_window_end, 'HH:mm:ss').format('HH:mm')}`;
      if (
        shipment.stops[shipment.stops.length - 1].location &&
        shipment.stops[shipment.stops.length - 1].location.address
      ) {
        deliveryLocationString =
          shipment.stops[shipment.stops.length - 1].location.address.city +
          ', ' +
          shipment.stops[shipment.stops.length - 1].location.address.state_province;
      }
    }
    const totals =
      Array.isArray(shipment?.line_items) &&
      shipment.line_items.length > 0 &&
      calculateShipmentTotals({
        line_items: shipment.line_items,
        unitPreferences,
        totalWeight: shipment.total_weight_override || {}
      });
    const totalWeight =
      shipment?.total_weight_override?.value || totals?.weight
        ? numberWithCommas(truncateNumber(shipment?.total_weight_override?.value || totals?.weight, 0))
        : '--';

    const mode =
      shipment &&
      shipment.mode &&
      shipmentModes &&
      shipmentModes.filter((e) => e.id === Number(shipment.mode)).length &&
      shipmentModes.filter((e) => e.id === Number(shipment.mode))[0].description;
    const equipment =
      shipment &&
      shipment.equipment_type &&
      equipmentTypes &&
      equipmentTypes.filter((e) => e.id === Number(shipment.equipment_type)).length &&
      equipmentTypes.filter((e) => e.id === Number(shipment.equipment_type))[0].name;

    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="multipleShipments__title">
          <span>Are you sure you want to build {numberToCreate} shipments?</span>
        </DialogTitle>
        <DialogContent className="multipleShipments__content">
          <div className="multipleShipments">
            <div className="acceptNow__content">
              <div className="acceptNow__content-stops">
                <span className="acceptNow__content-stop">
                  <span className="acceptNow__content-icon">{pickupIcon}</span>
                  <span className="acceptNow__content-text">
                    <span className="acceptNow__content-address">{pickupLocationString}</span>
                    <span className="acceptNow__content-time">{pickupTimeString}</span>
                  </span>
                </span>
                <span className="acceptNow__content-stop">
                  <span className="acceptNow__content-icon">{deliveryIcon}</span>
                  <span className="acceptNow__content-text">
                    <span className="acceptNow__content-address">{deliveryLocationString}</span>
                    <span className="acceptNow__content-time">{deliveryTimeString}</span>
                  </span>
                </span>
              </div>
              <div className="acceptNow__content-details">
                <div className="acceptNow__content-columns multipleShipments__details">
                  <span className="acceptNow__content-column">
                    <b>Customer: </b>
                    {shipment.book_as_customer && shipment.book_as_customer.label}
                  </span>
                  <span className="acceptNow__content-column">
                    <b>Mode: </b>
                    {mode}
                  </span>
                  <span className="acceptNow__content-column">
                    <b>Equipment: </b>
                    {equipment}
                  </span>
                  <span className="acceptNow__content-column">
                    <span>
                      <b>Weight: </b>
                      {totalWeight} lbs
                    </span>
                  </span>
                </div>
              </div>
            </div>{' '}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.cancel} bsStyle="default">
            Cancel
          </Button>
          <Button onClick={this.props.continue} bsStyle="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateMultipleConfirmation;
