import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Title, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader';
import './styles.scss';
import 'App/components/pricingIntelEmbeddedPricing/styles.scss';
const EmbeddedPricingTile = ({title, tooltipContent, disabled, children, loading, containerClassNames = []}) => {
  return (
    <div className={classnames('embeddedPricing__container', ...containerClassNames)}>
      <Title
        variant="cardTitle"
        className={classnames('embeddedPricing__title', {disabled: 'embeddedPricing__disabled'})}
      >
        {title}
        <Tooltip
          tooltipContent={tooltipContent}
          placement="right"
          tooltipClassname="embeddedPricing__tooltip-content"
          wrapperClassname="embeddedPricing__tooltip-wrapper"
        >
          <SvgIcon name="InfoOutlined" color={disabled ? '$sw-disabled' : '$sw-icon'} />
        </Tooltip>
      </Title>
      {loading ? (
        <ShipwellLoader loading additionalClassNames={['embeddedPricing__loader-container']} />
      ) : (
        <div className="embeddedPricing__values">{children}</div>
      )}
    </div>
  );
};

EmbeddedPricingTile.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  tooltipContent: PropTypes.string,
  containerClassNames: PropTypes.arrayOf(PropTypes.string)
};
export default EmbeddedPricingTile;
