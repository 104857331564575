import {Component} from 'react';
import {FormGroup, ControlLabel, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Phone from 'react-phone-number-input';
import {verifyPhoneNumber} from 'App/actions/vendors';

/**
 * Phone Number Input Field
 * A ReduxForm field component that formats phone number with optional verification of cell number
 */
class PhoneNumberField extends Component {
  constructor() {
    super();

    this.state = {
      verificationMessage: ''
    };
  }

  /**
   * Handles verification of cell number
   * @param  {Object} event     Blur event object
   * @param  {String} lineType  LANDLINE, MOBILE, or VOIP
   */
  verifyCellNumber(e, lineType = 'mobile') {
    const {value} = e.target;

    if (value) {
      verifyPhoneNumber({phone_number: value})
        .then((resp) => {
          const lineTypeResp = resp && String(resp.type).toLowerCase();
          const invalidMessage = (
            <span className="error-text">
              <i className="icon icon-x-small" /> Not a valid cell number
            </span>
          );
          const validMessage = (
            <span className="success-text">
              <i className="icon icon-Check" /> {resp.carrier_name} {resp.caller_name && `- ${resp.caller_name}`}
            </span>
          );

          if (resp && lineTypeResp !== 'unverified' && lineTypeResp !== lineType) {
            this.setState({verificationMessage: invalidMessage});
          } else {
            this.setState({verificationMessage: validMessage});
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  resetVerificationMessage() {
    this.setState({verificationMessage: ''});
  }

  handleBlur(e) {
    const {
      verifyNumber,
      meta: {error}
    } = this.props;

    if (!error && verifyNumber) {
      this.verifyCellNumber(e);
    }
  }

  handleChange(value) {
    if (this.state.verificationMessage) {
      this.resetVerificationMessage();
    }
  }

  render() {
    const {
      input,
      label,
      icon,
      tooltip,
      placeholder,
      req,
      disabled,
      onChangePhone = () => {},
      meta: {touched, error}
    } = this.props;

    return (
      <FormGroup controlId={input.name} validationState={touched && error ? 'error' : null}>
        {label && (
          <ControlLabel>
            {label}
            {req && <span className="requiredField">*</span>}
          </ControlLabel>
        )}

        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <div style={{display: 'block', textAlign: 'left'}}>{tooltip}</div>
              </Tooltip>
            }
          >
            <a tabIndex="-1">
              <i className="icon icon-Info" aria-hidden="true" />
            </a>
          </OverlayTrigger>
        )}

        <InputGroup className="input-group-inline" style={{width: '100%'}}>
          {icon && (
            <InputGroup.Addon>
              <i className={icon} />
            </InputGroup.Addon>
          )}
          <Phone
            country="US"
            value={input.value}
            placeholder={placeholder}
            type="text"
            disabled={disabled ? true : false}
            onChange={(phone) => {
              input.onChange(phone);
              this.handleChange(phone);
              onChangePhone(phone);
            }}
            onBlur={(e) => {
              input.onBlur(e);
              this.handleBlur(e);
            }}
            international
            countryOptionsOrder={['US', 'CA', 'MX', '|', '...']}
            defaultCountry="US"
          />
        </InputGroup>

        {touched && error && (
          <p className="error-text">
            <i className="icon icon-Delayed" />
            {error}
          </p>
        )}

        {input.value && this.state.verificationMessage}
      </FormGroup>
    );
  }
}

export default PhoneNumberField;
