import {TiveCredentials} from '@shipwell/locus-sdk';
import {Button, Card, CollapsibleCardContent, TextInput} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useMemo} from 'react';
import {CardTitle} from './cartTitle/cardTitle';
import {TiveDetailsFormValues} from './useTiveDetailsForm/tiveDetailsFormValues';
import {useTiveDetailsForm} from './useTiveDetailsForm/useTiveDetailsForm';

export interface ConnectionFormCardProps {
  credentials?: TiveCredentials;
  isFetching: boolean;
  isSaving: boolean;
  onConnect: (values: TiveDetailsFormValues) => void;
}

export function ConnectionFormCard(props: ConnectionFormCardProps) {
  const initialValues = useMemo<TiveDetailsFormValues>(
    () => ({
      clientId: props.credentials?.client_id || '',
      password: props.credentials?.client_secret || ''
    }),
    [props.credentials]
  );

  const form = useTiveDetailsForm({
    initialValues,
    onSubmit: props.onConnect
  });

  return (
    <Card title={<CardTitle />} draggableProvided={null}>
      <CollapsibleCardContent>
        {props.isFetching && <ShipwellLoader loading />}

        {!props.isFetching && (
          <form onSubmit={form.handleSubmit}>
            <fieldset disabled={false}>
              <div className="grid grid-cols-2 gap-3">
                <div className="col-span-2">
                  To enable the Tive integration, you will need to input a client id and secret. This will then be used
                  to validate setup and connection to Tive sensors.
                </div>

                <div className="col-span-1">
                  <TextInput label={'Client Id *'} {...form.getBasicProps('clientId')} />
                </div>

                <div className="col-span-1">
                  <TextInput label={'Secret *'} {...form.getBasicProps('password')} />
                </div>

                <div className="col-span-2 flex flex-col items-end text-right ">
                  <Button type="submit" isLoading={props.isSaving} disabled={props.isSaving}>
                    Connect
                  </Button>
                </div>
              </div>
            </fieldset>
          </form>
        )}
      </CollapsibleCardContent>
    </Card>
  );
}
