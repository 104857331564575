import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, formValueSelector, FormSection, submit, FieldArray} from 'redux-form';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import * as documentActions from 'App/actions/documents';
import renderField from 'App/formComponents/renderField';
import renderToggle from 'App/formComponents/renderToggle';
import renderAddress from 'App/formComponents/renderAddress';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderCheckBox from 'App/formComponents/renderCheckBox';
import renderMultiSelectList from 'App/formComponents/renderMultiSelectList';
import renderCustomCustomerFieldValues from 'App/formComponents/renderCustomCustomerFieldValues';
import SelectField from 'App/formComponents/fields/select';
import CustomerDocumentForm from 'App/containers/Settings/ManageCustomers/CustomerDocumentForm';
import NewCustomerDocumentForm from 'App/containers/Settings/ManageCustomers/NewCustomerDocumentForm';
import SubmitButton from 'App/utils/SubmitButton';
import {billingOptions, scrollToFirstErrorField, formatCurrencyValue} from 'App/utils/globals';
import validate from 'App/utils/validateCustomerForm';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect(
  (state) => ({
    documents: state.documents.documents
  }),
  {...documentActions}
)
class CustomerForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  renderContent() {
    const {
      initialValues,
      repList,
      brokerageId,
      canBook,
      canQuote,
      documentError,
      error,
      existingCustomer,
      ftlMode,
      handleDelete,
      handleSubmit,
      handleUploadDoc,
      isEdit,
      isLoading,
      ltlMode,
      onDocumentFormSubmit,
      cancel,
      shipperRelationshipId,
      submittedForm,
      submitting,
      customFields
    } = this.props;
    const repListDisplay = [];
    if (repList) {
      for (const rep in repList) {
        repListDisplay.push({
          display_name: repList[rep].first_name + ' ' + repList[rep].last_name,
          id: repList[rep].id
        });
      }
    }
    return (
      <div>
        <Form onSubmit={handleSubmit} className="customerForm formstyle-light">
          <Element name={`position-company.name`} />
          <FormSection name="company">
            <Field
              label="Company Name"
              name="name"
              required
              component={renderField}
              type="text"
              placeholder="Company Name"
            />
            <Element name={`position-company.mailing_address`} />
            <Field
              name="mailing_address"
              component={renderAddress}
              type="text"
              placeholder="Company Address"
              icon="icon icon-Pointer"
              label="Company Address"
            />
          </FormSection>
          {!existingCustomer && (
            //if this customer doesn't have an ID, we need to gather user name to create the account
            <FormSection name="user">
              <Element name={`position-user.first_name`} />
              <Element name={`position-user.last_name`} />
              <Field
                label="Primary Contact First Name"
                required
                component={renderField}
                type="text"
                name="first_name"
                placeholder="First Name"
              />
              <Field
                label="Primary Contact Last Name"
                required
                component={renderField}
                name="last_name"
                type="text"
                placeholder="Last Name"
              />
            </FormSection>
          )}

          <FormSection name="company">
            <Element name={`position-company.primary_phone_number`} />
            <Field
              label="Phone"
              name="primary_phone_number"
              component={renderPhoneNumberField}
              type="phone"
              placeholder="Phone"
            />
            <Element name={`position-company.primary_email`} />
            <Field
              label="Admin Email"
              name="primary_email"
              disabled={isEdit}
              component={renderField}
              type="email"
              placeholder="Admin Email"
            />
            <Element name={`position-company.billing_email`} />
            <Field
              label="Billing Email"
              name="billing_email"
              component={renderField}
              type="email"
              placeholder="Billing Email"
            />
          </FormSection>

          <div className="customerForm__toggleFields">
            <Field
              extraClass="customerForm__toggleFields-items"
              label=" Can Quote With My Rates?"
              name="can_quote_with_rates"
              component={renderToggle}
              defaultValue
              height="31px"
              width="57px"
            />
            <Field
              extraClass="customerForm__toggleFields-items"
              label=" Can Book With My Rates?"
              name="can_book_with_rates"
              component={renderToggle}
              defaultValue
              height="31px"
              width="57px"
            />
          </div>
          <Element name={`position-markup.ftl_markup_type`} />

          <FormSection name="markup">
            {(canQuote || canBook) && (
              <Field
                label="FTL Billing Settings"
                name="ftl_markup_type"
                component={renderDropDownSelector}
                data={billingOptions}
              />
            )}
            <Element name={`position-markup.ftl_markup_flat_fee`} />

            {
              //use loose equals here because this value is sometimes a number, sometimes a string
              ftlMode == 1 && (canQuote || canBook) && (
                <Field label="FTL Markup Flat Fee ($)" name="ftl_markup_flat_fee" component={renderField} />
              )
            }
            <Element name={`position-markup.ftl_markup_percentage`} />

            {ftlMode == 2 && (canQuote || canBook) && (
              <Field label="FTL Markup (%)" name="ftl_markup_percentage" component={renderField} />
            )}
          </FormSection>

          <FormSection name="markup">
            <Element name={`position-markup.ltl_markup_type`} />

            {(canQuote || canBook) && (
              <Field
                label="LTL Billing Settings"
                name="ltl_markup_type"
                component={renderDropDownSelector}
                data={billingOptions}
              />
            )}
            <Element name={`position-markup.ltl_markup_flat_fee`} />

            {ltlMode == 1 && (canQuote || canBook) && (
              <Field label="LTL Markup Flat Fee ($)" name="ltl_markup_flat_fee" component={renderField} />
            )}
            <Element name={`position-markup.ltl_markup_percentage`} />

            {ltlMode == 2 && (canQuote || canBook) && (
              <Field label="LTL Markup (%)" name="ltl_markup_percentage" component={renderField} />
            )}
            {ltlMode == 2 && (canQuote || canBook) && (
              <Field label="LTL Markup Minimum ($)" name="ltl_markup_minimum" component={renderField} />
            )}
          </FormSection>

          <Field
            clearable
            label="Sales Rep"
            name="sales_reps[0]"
            component={SelectField}
            options={repListDisplay}
            labelKey="display_name"
            valueKey="id"
            placeholder="Select sales rep"
          />

          <Field
            multi
            label="Account Rep(s)"
            name="account_reps"
            component={SelectField}
            options={repListDisplay}
            labelKey="display_name"
            placeholder="Select account rep(s)"
          />
          <Element name={`position-broker_imposed_credit_limit`} />

          <Field
            label="Credit Limit ($)"
            name="broker_imposed_credit_limit"
            component={renderField}
            placeholder="e.g., 10,000"
          />

          <FieldArray
            name="custom_customer_field_values"
            component={renderCustomCustomerFieldValues}
            props={{customFields: customFields}}
          />

          {error && (
            <p className="error-text-form-level">
              <i className="icon icon-Delayed pad-right" />
              {error}
            </p>
          )}
        </Form>
        {existingCustomer && (
          <CustomerDocumentForm
            handleUploadDoc={handleUploadDoc}
            handleDelete={handleDelete}
            onSubmit={onDocumentFormSubmit}
            isLoading={isLoading}
            error={documentError}
            cancel={cancel}
          />
        )}
        {!existingCustomer && (
          <NewCustomerDocumentForm
            brokerageId={brokerageId}
            cancel={cancel}
            error={documentError}
            handleDelete={handleDelete}
            handleUploadDoc={handleUploadDoc}
            isLoading={isLoading}
            shipperRelationshipId={shipperRelationshipId}
            submittedForm={submittedForm}
          />
        )}
      </div>
    );
  }
  render() {
    const {isEdit, submitting} = this.props;

    return (
      <div
        ref={(elem) => {
          this.testEl = elem;
          if (elem && isEdit) {
            this.props.setHeight(elem.clientHeight);
          }
        }}
      >
        {isEdit ? (
          this.renderContent()
        ) : (
          <Paper className="manage-customers__card-form-new">{this.renderContent()}</Paper>
        )}
        {!isEdit && (
          <div className="manage-customers__footer-btns">
            <Button
              onClick={() => {
                this.context.router.push('/manage_customers');
              }}
            >
              Cancel
            </Button>
            <SubmitButton handleSubmit={this.props.handleSubmit} submitting={submitting} />
          </div>
        )}
      </div>
    );
  }
}

CustomerForm = reduxForm({
  form: 'customerForm',
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(CustomerForm);

const selector = formValueSelector('customerForm');

CustomerForm = connect((state) => ({
  initialValues: state.brokers.selectedCustomer
}))(CustomerForm);

CustomerForm = connect((state) => {
  const canQuote = selector(state, 'can_quote_with_rates');
  const canBook = selector(state, 'can_book_with_rates');
  const ltlMode = selector(state, 'markup.ltl_markup_type');
  const ftlMode = selector(state, 'markup.ftl_markup_type');
  const existingCustomer = typeof selector(state, 'id') !== 'undefined';
  return {
    canQuote,
    canBook,
    ltlMode,
    ftlMode,
    existingCustomer
  };
})(CustomerForm);

export default CustomerForm;
