import moment from 'moment';
import {ShippingDashboardStop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatTime} from 'App/utils/globals';

export const StopDateCell = ({
  stop,
  usePlannedWindow = false
}: {
  stop?: ShippingDashboardStop;
  usePlannedWindow?: boolean;
}) => {
  //if we have confirmed departure time, start with that
  const validDepartureAt = Boolean(stop?.confirmed_departure_at) && moment(stop?.confirmed_departure_at).isValid();
  if (validDepartureAt) {
    //if the stop is completed, show that date and time
    return (
      <div>
        <span>{moment(stop?.confirmed_departure_at).format('ddd MMM D,')}</span>
        {stop?.location.address ? (
          <span>{formatTime(stop.confirmed_departure_at, true, stop.location.address.timezone || '')}</span>
        ) : null}
      </div>
    );
  }

  //for LTL, fall back to planned window
  if (usePlannedWindow) {
    return (
      <div>
        {stop && stop.display_planned_window?.split(', ').length === 2 ? (
          <div className="date">
            <span>{stop.display_planned_window.split(', ')[0] + ','}</span>
            <br />
            <span>{stop.display_planned_window.split(', ')[1]}</span>
          </div>
        ) : stop ? (
          <div className="date">
            <span>{stop.display_planned_window}</span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  //for FTL, fall back to eta_window
  return (
    <div>
      {stop && stop.display_eta_window?.split(', ').length === 2 ? (
        <div className="date">
          <span>{stop.display_eta_window.split(', ')[0] + ','}</span>
          <br />
          <span>{stop.display_eta_window.split(', ')[1]}</span>
        </div>
      ) : stop ? (
        <div className="date">
          <span>{stop.display_eta_window}</span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
