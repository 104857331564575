import {SvgIcon} from '@shipwell/shipwell-ui';

export interface StopDisplayValues {
  addressSingleLine: string;
  companyName: string;
}

const DrayageStopToolTip = ({stopNumber, stop}: {stopNumber: number; stop: StopDisplayValues}) => (
  <div className="grid gap-1">
    <div className="flex flex-row items-center">
      <SvgIcon name={`Num${stopNumber + 1}Outlined`} color="sw-text-reverse" />
      <span className="pl-1 font-bold">{stopNumber === 0 ? 'Pickup' : 'Delivery'}</span>
    </div>
    <div>
      <span className="font-bold">Company:</span> {stop.companyName}
    </div>
    <div>
      <span className="font-bold"> Address:</span> {stop.addressSingleLine}
    </div>
  </div>
);

export default DrayageStopToolTip;
