import _ from 'lodash';
import moment from 'moment';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import set from 'lodash/set';
import isNil from 'lodash/isNil';
import {
  validateEmail,
  validatePhoneNumber,
  removeCommasAndDollarSign,
  validateIntegerValue,
  validateDollarValue,
  validateNumber
} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {makeValidationErrors} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import isInternationalShipment from 'App/utils/isInternationalShipment';
import validateNMFCCode, {validateNMFCSubCode} from 'App/utils/validateNMFCCode';

const validate = (
  values,
  {referencesCustomFields = [], stopCustomFields = [], customFields = [], decimalSupportForShipmentLineItems} = {}
) => {
  const errors = {};
  let isFTL = false;
  let isLTL = false;
  let isVLTL = false;
  let isDrayage = false;
  let isParcel = false;

  if (values && values.mode) {
    ({
      hasFTL: isFTL,
      hasLTL: isLTL,
      hasVLTL: isVLTL,
      hasDrayage: isDrayage,
      hasParcel: isParcel
    } = checkShipmentModes(values.mode));
  }

  isLTL = isLTL || isVLTL;

  //STOPS
  if (values.stops) {
    errors.stops = [];
    const prevStopDates = [];
    const prevConfirmDates = [];
    for (var i = 0; i < values.stops.length; i++) {
      errors.stops[i] = {};
      if (
        (values.stops[i] && Object.keys(values.stops[i]).length === 0) ||
        !values.stops[i].location ||
        Object.keys(values.stops[i].location).length === 0
      ) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      } else if (!values.stops[i]) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      } else if (
        values.stops[i] &&
        values.stops[i].location &&
        values.stops[i].location.address &&
        !values.stops[i].location.address.address_1
      ) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      }
      if (values.stops[i].planned_date && moment(values.stops[i].planned_date).isValid()) {
        prevStopDates.push(moment(values.stops[i].planned_date));
      }
      if (!moment(values.stops[i].planned_date).isValid()) {
        errors.stops[i].planned_date = 'Enter a date';
      }

      if (!values.stops[i].location.company_name) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          company_name: 'Company required'
        };
      }

      if (values.stops[i].location.point_of_contacts && values.stops[i].location.point_of_contacts.length > 0) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          point_of_contacts: []
        };
        for (var k = 0; k < values.stops[i].location.point_of_contacts.length; k++) {
          errors.stops[i].location.point_of_contacts[k] = {};
          if (!values.stops[i].location.point_of_contacts[k].first_name) {
            errors.stops[i].location.point_of_contacts[k].first_name = 'First name required';
          }
          if (!values.stops[i].location.point_of_contacts[k].email) {
            errors.stops[i].location.point_of_contacts[k].email = 'Email required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].email &&
            !validateEmail(values.stops[i].location.point_of_contacts[k].email)
          ) {
            errors.stops[i].location.point_of_contacts[k].email = 'Invalid email';
          }
          if (!values.stops[i].location.point_of_contacts[k].phone_number) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Phone number required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].phone_number &&
            !validatePhoneNumber(values.stops[i].location.point_of_contacts[k].phone_number)
          ) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Invalid phone number';
          }
        }
      } else {
        errors.stops[i].location = {point_of_contacts: {}};
        errors.stops[i].location.point_of_contacts = {
          _error: 'At least one point of contact is required for each stop'
        };
      }

      if (stopCustomFields?.length > 0) {
        set(
          errors.stops[i],
          `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}`,
          makeValidationErrors(values.stops[i], stopCustomFields, CustomFieldEntityTypesEnum.ShipmentStop)
        );
      }

      if (values.stops[0] && values.stops[0].planned_date) {
        //make sure date is not in the past
        if (isLTL && moment(values.stops[0].planned_date, 'MM/DD/YYYY').isBefore(moment(), 'day')) {
          errors.stops[0] = {};
          errors.stops[0].planned_date = 'Pickup date cannot be in the past';
        }
      }

      if (values.stops[i]) {
        if (i > 0) {
          const currentStopDate = moment(values.stops[i].planned_date);

          if (
            currentStopDate.isValid() &&
            prevStopDates.some((date) => moment(values.stops[i].planned_date).isBefore(date, 'day'))
          ) {
            errors.stops[i].planned_date = 'Date must be after previous stop dates';
          }
        }
        /** Ensure completion date is after arrival date */
        if (
          moment(values.stops[i].confirmed_arrival_at).isValid() &&
          moment(values.stops[i].confirmed_departure_at).isValid()
        ) {
          if (moment(values.stops[i].confirmed_departure_at).isBefore(moment(values.stops[i].confirmed_arrival_at))) {
            errors.stops[i].confirmed_departure_at = 'Completed date must be after arrival date.';
          }
        }
        /** Ensure current completion date and arrival date are after previous dates */
        if (i > 0 && moment(values.stops[i].confirmed_arrival_at).isValid()) {
          if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_arrival_at).isBefore(prevDate))) {
            errors.stops[i].confirmed_arrival_at = 'Date must be after previous dates.';
          }
        }
        if (i > 0 && moment(values.stops[i].confirmed_departure_at).isValid()) {
          if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_departure_at).isBefore(prevDate))) {
            errors.stops[i].confirmed_departure_at = 'Date must be after previous dates.';
          }
        }

        if (moment(values.stops[i].confirmed_arrival_at).isValid()) {
          prevConfirmDates.push(moment(values.stops[i].confirmed_arrival_at));
        }
        if (moment(values.stops[i].confirmed_departure_at).isValid()) {
          prevConfirmDates.push(moment(values.stops[i].confirmed_departure_at));
        }

        if (values.stops[i].planned_time_window_start && values.stops[i].planned_time_window_end) {
          const startTime = moment(values.stops[i].planned_time_window_start, 'HH:mm');
          const stopTime = moment(values.stops[i].planned_time_window_end, 'HH:mm');
          //check if schedule selection is 'Open' before validating start and stop time
          const scheduleSelectOpen = values.stops[i]['schedule-select'] === 2;
          if (startTime.isValid() && stopTime.isValid() && moment(stopTime).isBefore(startTime) && scheduleSelectOpen) {
            errors.stops[i].planned_time_window_end = 'Stop time must be after start time';
          }
        }
      }
    }
  } else if (!values.stops) {
    errors.stops = [];
    errors.stops[0] = {};
    errors.stops[0].location = 'Enter a pickup location';
    errors.stops[1] = {};
    errors.stops[1].location = 'Enter a delivery location';
  }

  //LINE ITEMS
  if (isFTL && values.manual_total_weight) {
    if (!values.total_weight_override?.value || values.total_weight_override?.value <= 0) {
      errors.total_shipment_weight = 'Enter total shipment weight.';
    }
  }
  if (values.line_items && values.line_items.length > 0) {
    errors.line_items = [];
    for (let i = 0; i < values.line_items.length; i++) {
      errors.line_items[i] = {};
      if (!values.line_items[i].total_packages && isLTL) {
        errors.line_items[i].total_packages = 'Quantity required';
      } else if (
        values.line_items[i].total_packages &&
        !decimalSupportForShipmentLineItems &&
        !validateIntegerValue(values.line_items[i].total_packages)
      ) {
        errors.line_items[i].total_packages = 'Enter a valid whole number';
      }
      if (!values.line_items[i].height && isLTL) {
        errors.line_items[i].height = 'Height required';
      } else if (values.line_items[i].height && !validateNumber(values.line_items[i].height)) {
        errors.line_items[i].height = 'Enter a valid number';
      }
      if (!values.line_items[i].width && isLTL) {
        errors.line_items[i].width = 'Width required';
      } else if (values.line_items[i].width && !validateNumber(values.line_items[i].width)) {
        errors.line_items[i].width = 'Enter a valid number';
      }
      if (!values.line_items[i].length && isLTL) {
        errors.line_items[i].length = 'Length required';
      } else if (values.line_items[i].length && !validateNumber(values.line_items[i].length)) {
        errors.line_items[i].length = 'Enter a valid number';
      }
      if (!values.line_items[i].total_packages) {
        errors.line_items[i].total_packages = 'Quantity required';
      }
      if (!values.manual_total_weight && !values.line_items[i].package_weight) {
        errors.line_items[i].package_weight = 'Package Weight required';
      } else if (values.line_items[i].package_weight && !validateNumber(values.line_items[i].package_weight)) {
        errors.line_items[i].package_weight = 'Enter a valid number';
      } else if (!isParcel && values.line_items[i].package_weight && values.line_items[i].package_weight < 1) {
        errors.line_items[
          i
        ].package_weight = `Package Weight cannot be lees than 1 ${values.line_items[i].weight_unit}`;
      }
      if (!values.line_items[i].description) {
        errors.line_items[i].description = 'Product description required';
      }
      if (values.line_items[i].total_pieces && !validateIntegerValue(values.line_items[i].total_pieces)) {
        errors.line_items[i].total_pieces = 'Enter a valid whole number';
      }

      if (
        values.line_items[i].hazmat === true &&
        (!values.line_items[i].hazmatDetails || values.line_items[i].hazmatDetails === null)
      ) {
        errors.line_items[i].hazmatDetails = 'Enter Hazmat details';
      }
      //in FTL, we dont need a freight class
      if ((!values.line_items[i].freight_class || values.line_items[i].freight_class === 'select') && isLTL) {
        errors.line_items[i].freight_class = 'Freight class required';
      }

      if (!values.line_items[i].package_type && isLTL) {
        errors.line_items[i].package_type = 'Select a package type';
      }

      if (values.line_items[i].refrigeration_required && isNil(values.line_items[i].refrigeration_min_temp)) {
        errors.line_items[i].refrigeration_min_temp = 'Enter minimum temperature.';
      }
      if (values.line_items[i].refrigeration_required && isNil(values.line_items[i].refrigeration_max_temp)) {
        errors.line_items[i].refrigeration_max_temp = 'Enter maximum temperature.';
      }
      if (isParcel && isInternationalShipment(values.stops)) {
        if (!values.line_items[i].total_pieces) {
          errors.line_items[i].total_pieces = 'Number of pieces is required.';
        }
        if (!values.line_items[i].value_per_piece) {
          errors.line_items[i].value_per_piece = 'Value per piece is required.';
        }
        if (!values.line_items[i].country_of_manufacture) {
          errors.line_items[i].country_of_manufacture = 'Country of manufacture is required.';
        }
      }
      set(
        errors.line_items[i],
        `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}`,
        makeValidationErrors(values.line_items[i], customFields)
      );

      if (values.line_items[i].nmfc_item_code) {
        try {
          values.line_items[i].nmfc_item_code = validateNMFCCode(values.line_items[i].nmfc_item_code);
        } catch (e) {
          errors.line_items[i].nmfc_item_code = e.message;
        }
      }
      if (values.line_items[i].nmfc_sub_code) {
        try {
          values.line_items[i].nmfc_sub_code = validateNMFCSubCode(values.line_items[i].nmfc_sub_code);
        } catch (e) {
          errors.line_items[i].nmfc_sub_code = e.message;
        }
      }
    }
  }
  //if no line items entered and mode selected includes LTL, error
  if (isLTL && (!values.line_items || values.line_items.length === 0)) {
    errors.line_items = {_error: 'At least one line item is required for LTL'};
  }

  //if there are no line items and we're in FTL, then the total weight and commodity description are required
  if (isFTL && (!values.line_items || values.line_items.length === 0)) {
    if (!values.ftl_estimated_weight) {
      errors.ftl_estimated_weight = 'Total weight is required if there are no line items';
    } else if (
      !parseFloat(removeCommasAndDollarSign(values.ftl_estimated_weight)) ||
      /^[0-9,.]*$/.test(values.ftl_estimated_weight) === false
    ) {
      errors.ftl_estimated_weight = 'Enter a valid number';
    }
    if (!values.ftl_description) {
      errors.ftl_description = 'Description is required if there are no line items';
    }
  }

  errors.metadata = {};

  //validate max buy if it exists
  if (values.metadata?.max_buy_amount) {
    if (!validateDollarValue(values.metadata.max_buy_amount)) {
      errors.metadata.max_buy_amount = 'Enter a valid dollar value';
    }
  }
  //validate buy it now if it exists
  if (values.metadata?.buy_it_now_amount) {
    if (!validateDollarValue(values.metadata.buy_it_now_amount)) {
      errors.metadata.buy_it_now_amount = 'Enter a valid dollar value';
    }
  }
  //validate target rate if it exists
  if (values.metadata?.target_rate_amount) {
    if (!validateDollarValue(values.metadata.target_rate_amount)) {
      errors.metadata.target_rate_amount = 'Enter a valid dollar value';
    }
  }
  if (Object.keys(errors.metadata).length === 0) {
    delete errors.metadata;
  }

  if (referencesCustomFields.length > 0) {
    set(
      errors,
      `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}`,
      makeValidationErrors(values, referencesCustomFields, CustomFieldEntityTypesEnum.Shipment)
    );
  }

  if (values.override_bill_to === true && values.bill_to_override) {
    errors.bill_to_override = {};
    if (
      !values.bill_to_override.company_address ||
      (values.bill_to_override.company_address && Object.keys(values.bill_to_override.company_address).length === 0)
    ) {
      errors.bill_to_override.company_address = 'Address is required';
    }
    if (!values.bill_to_override.company_name) {
      errors.bill_to_override.company_name = 'Company name is required';
    }
    if (!values.bill_to_override.contact_email) {
      errors.bill_to_override.contact_email = 'Email is required';
    }
    if (values.bill_to_override.contact_email && !validateEmail(values.bill_to_override.contact_email)) {
      errors.bill_to_override.contact_email = 'Invalid email';
    }
    if (!values.bill_to_override.contact_phone) {
      errors.bill_to_override.contact_phone = 'Phone is required';
    }
    if (values.bill_to_override.contact_phone && !validatePhoneNumber(values.bill_to_override.contact_phone)) {
      errors.bill_to_override.contact_phone = 'Invalid phone number';
    }
    if (!values.bill_to_override.direction || values.bill_to_override.direction === 'select') {
      errors.bill_to_override.direction = 'Select an option';
    }
  }

  // PARCEL SERVICES
  if (isParcel) {
    if (values.fedex_specific_options) {
      errors.fedex_specific_options = {};
      const parcelErrors = validateProviderOptions(values.fedex_specific_options);

      if (
        values.fedex_specific_options.battery_material_type &&
        !values.fedex_specific_options.battery_packaging_type
      ) {
        errors.fedex_specific_options.battery_packaging_type = 'Battery packaging type is required.';
      }
      if (
        values.fedex_specific_options.battery_packaging_type &&
        !values.fedex_specific_options.battery_material_type
      ) {
        errors.fedex_specific_options.battery_material_type = 'Battery material type is required.';
      }
      errors.fedex_specific_options = {...errors.fedex_specific_options, ...parcelErrors};
    }

    if (values.ups_specific_options) {
      errors.ups_specific_options = validateProviderOptions(values.ups_specific_options);
    }

    if (values.usps_specific_options) {
      errors.usps_specific_options = validateProviderOptions(values.usps_specific_options);
    }
  }

  return errors;
};

const validateProviderOptions = (options) => {
  const errors = {};
  const type = options.cod_collection_type;
  const amount = options.cod_collection_amount;

  if (type && !amount) {
    errors.cod_collection_amount = 'An amount to collect on delivery is required.';
  }

  if (amount) {
    if (!validateDollarValue(amount)) {
      errors.cod_collection_amount = 'Enter a valid dollar value.';
    }
    if (validateDollarValue(amount) && (Number(amount) > 99999999.99 || Number(amount) < 0.01)) {
      errors.cod_collection_amount = 'Enter an amount between $0.01 - $99,999,999.99';
    }
  }

  if (!type && amount) {
    errors.cod_collection_type = 'C.O.D. options is required.';
  }

  if (['COLLECT', 'RECIPIENT', 'THIRD_PARTY'].includes(options?.bill_to_payment_type)) {
    if (!options?.bill_to_account_number) {
      errors.bill_to_account_number = 'Bill To Account ID is required.';
    }
    if (!options?.bill_to_country_code) {
      errors.bill_to_country_code = 'Country is required.';
    }
    if (!options?.bill_to_postal_code) {
      errors.bill_to_postal_code = 'Zip Code is required.';
    }
  }

  return errors;
};

export default validate;
