import {useState, useEffect} from 'react';
import {Link} from 'react-router';
import {useFormikContext} from 'formik';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {TenderCreate, Contract} from '@shipwell/backend-core-sdk';

export const TenderContractError = ({contract}: {contract?: Contract}) => {
  const {errors, submitCount, isSubmitting} = useFormikContext<TenderCreate>();
  const [tenderError, setTenderError] = useState({});
  const [errorSubmitCount, setErrorSubmitCount] = useState(0);

  useEffect(() => {
    if (
      errors.involved_tender_to_company_users &&
      submitCount > errorSubmitCount &&
      !isSubmitting &&
      typeof contract?.id === 'string'
    ) {
      setErrorSubmitCount(submitCount);
      setTenderError({...tenderError, [contract.id]: errors.involved_tender_to_company_users});
    }
  }, [contract, errors, submitCount, isSubmitting, errorSubmitCount, tenderError]);

  return contract && contract.id && typeof contract.carrier_relationship === 'string' && contract.id in tenderError ? (
    <div className="flex rounded-md bg-sw-error-background p-2 pl-0 text-sw-error">
      <SvgIcon width="3rem" name="InfoFilled" />
      <span className="font-bold text-sw-text">
        Carrier contact(s) are no longer valid for this carrier&apos;s profile. Please update contact(s) on the{' '}
        <Link target="_blank" to={`/carriers/${contract.carrier_relationship}`}>
          carrier profile
        </Link>{' '}
        to tender from contract.
      </span>
    </div>
  ) : null;
};
