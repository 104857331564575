import {Field} from 'formik';
import {FormikSelect, FormikDateTimePicker} from '@shipwell/shipwell-ui';

export const AccessorialSettingsFields = () => {
  return (
    <div className="grid gap-4">
      <Field
        required
        label="Status"
        name="status"
        simpleValue
        clearable={false}
        component={FormikSelect}
        options={[
          {value: 'ACTIVE', label: 'Active'},
          {value: 'INACTIVE', label: 'Inactive'}
        ]}
      />
      <div className="grid grid-cols-2 gap-4">
        <Field
          label="Start Date"
          name="start_date"
          showTimeSelect={false}
          popperPlacement="top-start"
          component={FormikDateTimePicker}
        />
        <Field
          label="End Date"
          name="end_date"
          showTimeSelect={false}
          popperPlacement="top-start"
          component={FormikDateTimePicker}
        />
      </div>
    </div>
  );
};
