import {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import {DeprecatedButton, Dropdown, Modal, SearchField, SvgIcon} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import FuelSurchargeTableCreate from '../create';
import ConfirmDelete from '../confirmDelete';
import {formatDate, toTitleCase} from 'App/utils/globals';
import {getFuelSurchargeTables} from 'App/api/rateTables';
import SWTable from 'App/components/swTable';
import PageHeader from 'App/common/pageHeader';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import buildPathParams from 'App/utils/buildPathParams';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Error404Page from 'App/common/Error404Page2';
import withFlags from 'App/utils/withFlags';
import {useCarrierDetails} from 'App/data-hooks';
import formatCountryAddress from 'App/utils/formatCountryAddress';
import Loader from 'App/common/shipwellLoader';
import {placeholderAccessor} from 'App/utils/tableUtils';
import CreateImport from 'App/components/imports/create';
import './styles.scss';

/** Debounced path update - keep outside of functional component */
const pathUpdateDebounced = debounce((router, path) => router.push(path), 300);

const FuelSurcharges = ({router, location, setSuccess, setError}) => {
  const [fuelSurchargeTables, setFuelSurchargeTables] = useState([]);
  const [searchTerm, setSearchTerm] = useState(location.query.q);
  const [meta, setMeta] = useState({
    page: 1,
    pages: 1,
    pageSize: 20,
    ordering: 'name'
  });
  const [isFetching, setIsFetching] = useState(false);
  const [deletingFuelSurchargeTable, setDeletingFuelSurchargeTable] = useState(null);
  const [showCreateFuelSurchargeTableModal, setShowCreateFuelSurchargeTableModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [useFullScreen, setUseFullScreen] = useState(false);
  const carrierDetails = useCarrierDetails(
    uniq(
      fuelSurchargeTables.reduce((ids, fuelSurchargeTable) => [...ids, ...fuelSurchargeTable.carrier_relationships], [])
    )
  );

  const fetchFuelSurchargeTables = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await getFuelSurchargeTables({
        page: meta.page,
        pageSize: meta.pageSize,
        ordering: meta.ordering,
        ...location.query
      });
      setFuelSurchargeTables(get(response, 'body.results', []));
      setMeta((prevMeta) => ({
        ...location.query,
        ...prevMeta,
        page: location.query.page || 1,
        pages: response.body.total_pages,
        pageSize: response.body.page_size
      }));
    } catch (error) {
      console.error('Error fetching fuel surcharge tables', error);
      setError('Error!', `Error retrieving fuel surcharge tables! ${get(error, 'error_description')}`);
    } finally {
      setIsFetching(false);
    }
  }, [meta.page, meta.pageSize, meta.ordering, location.query, setError]);

  useEffect(() => {
    fetchFuelSurchargeTables();
  }, [fetchFuelSurchargeTables]);

  const handleAddFuelSurchargeTable = () => {
    setShowCreateFuelSurchargeTableModal(true);
  };

  const handleAddSuccess = (response) => {
    fetchFuelSurchargeTables();
    setSuccess(
      'Fuel Surcharges Table Updated!',
      <span>
        Your fuel surcharge table is now active{' '}
        <Link to={`/company/lane-management/fuel-surcharges/${response.body.id}`}>here</Link>.
      </span>
    );
    setShowCreateFuelSurchargeTableModal(false);
  };

  const handleDeleteSuccess = () => {
    setSuccess('Success!', `Fuel surcharge table "${deletingFuelSurchargeTable.name}" was removed.`);
    setDeletingFuelSurchargeTable(null);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    const updatedPath = buildPathParams(location, {q: term, page: 1});
    setSearchTerm(term);
    pathUpdateDebounced(router, updatedPath);
  };

  return (
    <>
      <div className="fuelSurcharges__list">
        <PageHeader
          title="Fuel Surcharges"
          actions={
            <>
              <DeprecatedButton
                variant="tertiary"
                icon={<SvgIcon name="AddCircleOutlined" />}
                onClick={() => setShowImportModal(true)}
              >
                Import Table
              </DeprecatedButton>
              <DeprecatedButton
                variant="tertiary"
                icon={<SvgIcon name="AddCircleOutlined" />}
                onClick={handleAddFuelSurchargeTable}
              >
                Fuel Surcharge
              </DeprecatedButton>
            </>
          }
        >
          <SearchField label="Search" name="search-fuel-surcharge-tables" value={searchTerm} onChange={handleSearch} />
        </PageHeader>
        <div className="fuelSurcharges__list-table">
          <SWTable
            data={fuelSurchargeTables}
            loading={isFetching}
            location={location}
            router={router}
            page={Number(meta.page) - 1}
            pages={Number(meta.pages)}
            pageSize={Number(meta.pageSize)}
            noDataText="No fuel surcharge tables to show."
            getTrProps={(state, rowInfo) => ({className: !rowInfo ? 'empty' : ''})}
            columns={[
              {
                Header: (
                  <>
                    Name <i className="btn-sort" />
                  </>
                ),
                id: 'name',
                accessor: 'name',
                sortable: true,
                minWidth: 125,

                Cell: ({original, value}) => (
                  <DeprecatedButton
                    variant="tertiary"
                    onClick={() => {
                      router.push(`/company/lane-management/fuel-surcharges/${original.id}`);
                    }}
                  >
                    {value}
                  </DeprecatedButton>
                )
              },
              {
                Header: (
                  <>
                    Reference <i className="btn-sort" />
                  </>
                ),
                id: 'external_reference',
                accessor: placeholderAccessor('external_reference'),
                sortable: true,
                minWidth: 125
              },
              {
                Header: (
                  <>
                    Status <i className="btn-sort" />
                  </>
                ),
                id: 'status',
                accessor: (row) => upperFirst(row.status.toLowerCase()),
                sortable: true,
                minWidth: 125
              },
              {
                Header: (
                  <>
                    Carriers <i className="btn-sort" />
                  </>
                ),
                id: 'carrier_relationships',
                accessor: 'carrier_relationships',
                minWidth: 120,
                sortable: true,
                className: 'fuelSurcharges__list-multiline',
                Cell: ({value}) => {
                  const carriers = value || [];
                  return carriers.length > 0
                    ? carriers.map((carrierRelationshipId) => (
                        <span key={carrierRelationshipId}>
                          {get(carrierDetails, `${carrierRelationshipId}.shipwell_vendor.name`, '--')}
                        </span>
                      ))
                    : '--';
                }
              },
              {
                Header: (
                  <>
                    Locations <i className="btn-sort" />
                  </>
                ),
                id: 'locations',
                accessor: 'locations',
                minWidth: 200,
                sortable: true,
                className: 'fuelSurcharges__list-multiline',
                Cell: (row) => {
                  const locations = get(row, 'value') || [];
                  return locations.length > 0
                    ? locations.map((address) => (
                        <div key={address.id}>{formatCountryAddress(address.formatted_address)}</div>
                      ))
                    : '--';
                }
              },
              {
                Header: (
                  <>
                    Modes <i className="btn-sort" />
                  </>
                ),
                id: 'modes',
                accessor: (row) => (get(row, 'modes') || []).join(', ') || '--',
                sortable: true,
                minWidth: 125
              },
              {
                Header: (
                  <>
                    Equipment <i className="btn-sort" />
                  </>
                ),
                id: 'equipment_types',
                accessor: (row) => (get(row, 'equipment_types') || []).map(toTitleCase).join(', ') || '--',
                sortable: true,
                minWidth: 150
              },
              {
                Header: (
                  <>
                    Start Date <i className="btn-sort" />
                  </>
                ),
                id: 'start_date',
                accessor: (row) => (row.start_date ? formatDate(row.start_date) : '--'),
                sortable: true,
                minWidth: 150
              },
              {
                Header: (
                  <>
                    End Date <i className="btn-sort" />
                  </>
                ),
                id: 'end_date',
                accessor: (row) => (row.end_date ? formatDate(row.end_date) : '--'),
                sortable: true,
                minWidth: 150
              },
              {
                Header: (
                  <>
                    Updated On <i className="btn-sort" />
                  </>
                ),
                id: 'rates_updated_day_of_week',
                accessor: (row) => toTitleCase(placeholderAccessor('rates_updated_day_of_week')(row)),
                sortable: true,
                minWidth: 150
              },

              {
                Header: (
                  <>
                    Base Fuel Price <i className="btn-sort" />
                  </>
                ),
                id: 'base_fuel_price',
                accessor: placeholderAccessor('base_fuel_price'),
                sortable: true,
                minWidth: 175
              },
              {
                Header: (
                  <>
                    Miles Per Gallon <i className="btn-sort" />
                  </>
                ),
                id: 'miles_per_gallon',
                accessor: placeholderAccessor('miles_per_gallon'),
                sortable: true,
                minWidth: 175
              },
              {
                Header: 'Actions',
                id: 'actions',
                accessor: '',
                sortable: false,
                className: 'overflow-visible',

                Cell: ({original}) => {
                  return (
                    <Dropdown
                      indicator={false}
                      drop="left"
                      variant="icon"
                      className="action-menu"
                      icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                    >
                      {({onClick}) => (
                        <>
                          <li
                            className="text-danger"
                            onClick={() => {
                              onClick();
                              setDeletingFuelSurchargeTable(original);
                            }}
                          >
                            Delete
                          </li>
                        </>
                      )}
                    </Dropdown>
                  );
                }
              }
            ]}
          />
        </div>
      </div>
      <ConfirmDelete
        show={Boolean(deletingFuelSurchargeTable)}
        fuelSurchargeTable={deletingFuelSurchargeTable}
        onSuccess={handleDeleteSuccess}
        onClose={() => setDeletingFuelSurchargeTable(null)}
      />
      <FuelSurchargeTableCreate
        show={showCreateFuelSurchargeTableModal}
        onClose={() => setShowCreateFuelSurchargeTableModal(false)}
        onSubmitSuccess={handleAddSuccess}
        onError={setError}
      />
      <Modal
        fullScreen={useFullScreen}
        show={showImportModal}
        title="Import Table"
        footerComponent={null}
        onClose={() => {
          setShowImportModal(false);
          setUseFullScreen(false);
        }}
      >
        <CreateImport
          setUseFullScreen={setUseFullScreen}
          onCancel={() => {
            setShowImportModal(false);
            setUseFullScreen(false);
          }}
          type="FUEL_SURCHARGE_TABLES"
          onImportSuccess={setSuccess}
        />
      </Modal>
    </>
  );
};

FuelSurcharges.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    query: PropTypes.objectOf(PropTypes.string)
  }),
  original: PropTypes.shape({
    id: PropTypes.string
  }),
  value: PropTypes.any,
  ...WithStatusToastsPropTypes
};

FuelSurcharges.defaultProps = {
  router: {
    push: () => {}
  },
  location: {
    query: {}
  },
  ...WithStatusToastsDefaultProps
};

export default compose(
  withFlags('fuelSurchargeRateTable'),
  withConditionalFallback(({fuelSurchargeRateTable}) => fuelSurchargeRateTable === undefined, Loader),
  withConditionalFallback(({fuelSurchargeRateTable}) => !fuelSurchargeRateTable, Error404Page),
  withStatusToasts
)(FuelSurcharges);
