import {DisplayValue, Modal, Button, FormikCheckbox} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {AutoDisputeInvoiceConfiguration} from 'App/api/settlements/typeGuards';
import {useUpdateAutoDisputeInvoiceConfiguration} from 'App/data-hooks/settlements';
type InitialValues = {
  invoice_amount: boolean;
  missing_required_document: boolean;
  multiple_invoices_per_invoiceable: boolean;
  service_provider_name_mismatch: boolean;
  shipment_not_delivered: boolean;
};

export const AutoDisputeInvoiceEditModal = ({
  showModal,
  onClose,
  title,
  configuration
}: {
  showModal: boolean;
  onClose: () => void;
  title: string;
  configuration: AutoDisputeInvoiceConfiguration;
}) => {
  const {details} = configuration;
  const initialValues = {
    invoice_amount: details.invoice_amount,
    missing_required_document: details.missing_required_document,
    multiple_invoices_per_invoiceable: details.multiple_invoices_per_invoiceable,
    service_provider_name_mismatch: details.service_provider_name_mismatch,
    shipment_not_delivered: details.shipment_not_delivered
  };

  const {mutate: updateAutoDisputeInvoiceConfiguration, isLoading} = useUpdateAutoDisputeInvoiceConfiguration();

  const handleSubmit = ({
    invoice_amount,
    missing_required_document,
    multiple_invoices_per_invoiceable,
    service_provider_name_mismatch,
    shipment_not_delivered
  }: InitialValues) => {
    updateAutoDisputeInvoiceConfiguration(
      {
        id: configuration.id,
        invoice_amount,
        missing_required_document,
        multiple_invoices_per_invoiceable,
        service_provider_name_mismatch,
        shipment_not_delivered
      },
      {
        onSuccess() {
          onClose();
        }
      }
    );
  };

  return (
    <Modal show={showModal} onClose={onClose} title={title} footerComponent={null} portal>
      <DisplayValue className="mb-6" label="Trigger">
        Invoice received and contains the selected exceptions
      </DisplayValue>

      <DisplayValue className="mb-6" label="Action">
        Invoice status changed to &lsquo;Disputed&lsquo; and carrier notified
      </DisplayValue>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <DisplayValue className="mb-6" label="Threshold">
            <div className="flex flex-col gap-2">
              <Field
                name="invoice_amount"
                component={FormikCheckbox}
                label="Invoiced amount does not match shipment total"
              />
              <Field
                name="missing_required_document"
                component={FormikCheckbox}
                label="All required documents have not been provided"
              />
              <Field
                name="shipment_not_delivered"
                component={FormikCheckbox}
                label="Shipment is not marked as delivered"
              />
              <Field
                name="service_provider_name_mismatch"
                component={FormikCheckbox}
                label="Carrier name on invoice does not match shipment carrier"
              />
              <Field
                name="multiple_invoices_per_invoiceable"
                component={FormikCheckbox}
                label="Multiple active invoices received on the same shipment"
              />
            </div>
          </DisplayValue>
          <div className="flex justify-end gap-4 pt-4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button isLoading={isLoading} variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
