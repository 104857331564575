import {useQuery} from '@tanstack/react-query';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import {PaginatedUser} from '@shipwell/backend-core-sdk';
import {getCompanyUsers} from 'App/api/user/typed';
import {COMPANY_USERS} from 'App/data-hooks/queryKeys';

/*
 * Fetch user details for the given ids and make them available in an object keyed by those ids.
 * This is useful when you have a list of users ids in an API response object but you
 * want to display other details about the user, like name.
 */
export default function useUserDetails(companyId: string, userIds: string[]) {
  const companyUsersQuery = useQuery<PaginatedUser>(
    [COMPANY_USERS, companyId, {userIds}],
    async () => {
      const response = await getCompanyUsers(companyId, {pageSize: 1000, id: userIds});
      return response.data;
    },
    {
      enabled: !isEmpty(companyId) && !isEmpty(userIds),
      onError: (error) => console.error(error)
    }
  );

  return keyBy(companyUsersQuery.data?.results, 'id');
}
