import {Field, getFormValues} from 'redux-form';
import {DispatchProp, connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ShipmentMode} from '@shipwell/backend-core-singlerequestparam-sdk';

import CheckboxField from 'App/formComponents/fields/checkbox';
import {State} from 'App/reducers/types';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {useCarrierRelationships, useUserPermissions} from 'App/data-hooks';

interface ShipmentCarrierInsuranceFieldsProps {
  form: string;
  values: Partial<{
    mode: number | ShipmentMode | ShipmentMode[];
    carrier: string | {label: string; value: string};
    metadata?: {
      bypass_carrier_insurance_coverage: boolean;
    };
  }>;
}

const ShipmentCarrierInsuranceFields = ({values}: ShipmentCarrierInsuranceFieldsProps & DispatchProp<AnyAction>) => {
  const companyManagementPermissions = useUserPermissions([
    'company.administrator',
    'company.view_company_details',
    'company.update_company_details',
    'carrier_compliance.override_insurance_limit_check'
  ]);

  const hasManageCompanyPermission =
    companyManagementPermissions['company.administrator'] ||
    companyManagementPermissions['company.view_company_details'] ||
    companyManagementPermissions['company.update_company_details'];

  const carrierTenantId = typeof values.carrier === 'string' ? undefined : values.carrier?.value;
  const {carrierRelationshipsQuery} = useCarrierRelationships(
    {vendorId: carrierTenantId},
    {enabled: !!carrierTenantId}
  );
  const carrierRelationship = carrierRelationshipsQuery.data?.results?.[0];

  const hasOverrideInsurancePermission =
    companyManagementPermissions['carrier_compliance.override_insurance_limit_check'] &&
    carrierRelationship?.is_carrier_insurance_compliance_enabled;

  const {hasFTL} = checkShipmentModes(values?.mode);
  const showOverrideInsuranceCheckbox = hasFTL && hasManageCompanyPermission;

  return showOverrideInsuranceCheckbox ? (
    <div className="ml-[5px] mt-[13px] grid pl-[8px]">
      <Field
        className="mb-0"
        name="metadata.bypass_carrier_insurance_coverage"
        label="Override Carrier Insurance Coverage"
        component={CheckboxField}
        disabled={!hasOverrideInsurancePermission}
      />
    </div>
  ) : null;
};

const mapStateToProps = (state: State, props: ShipmentCarrierInsuranceFieldsProps) => ({
  values: getFormValues(props.form)(state)
});

export default connect(mapStateToProps)(ShipmentCarrierInsuranceFields);
