import {Dropdown} from '@shipwell/shipwell-ui';

export const allowedModifiers = ['Exclude'];
const defaultModifier = 'Or';

const FilterModifier = ({
  modifierDisplayName,
  filterId,
  handleChangeFilterModifier
}: {
  modifierDisplayName?: string | null;
  filterId: string;
  handleChangeFilterModifier: ({filterId, modifier}: {filterId: string; modifier: string | null}) => void;
}) => {
  return (
    <Dropdown
      title={modifierDisplayName || defaultModifier}
      variant="tertiary"
      trigger="click"
      indicatorSvgIconName="ExpandMore"
      buttonClassName="text-sw-text-reverse hover:text-sw-secondary focus:text-sw-active"
    >
      {({onClick}: {onClick: () => void}) => [
        // default modifier needs to return a null modifier
        [defaultModifier, ...allowedModifiers].map((modifier, index) => (
          <li
            className="text-sw-text"
            key={modifier}
            onClick={() => {
              onClick();
              handleChangeFilterModifier({filterId, modifier: index === 0 ? null : modifier});
            }}
          >
            {modifier}
          </li>
        ))
      ]}
    </Dropdown>
  );
};

export default FilterModifier;
