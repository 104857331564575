import {SvgIcon} from '@shipwell/shipwell-ui';
import {
  RFPBidOptResponse,
  RFPBidOptResponseStateEnum,
  RfpCarrierBidRequestOptRequestBidRequestStateEnum,
  RfpCarrierBidRequestOptResponse
} from '@shipwell/backend-core-sdk';
import pluralize from 'pluralize';
import {formatDateTimeTimestamp, isPastDate, timeRemainingFromToday} from 'App/utils/dateTimeGlobalsTyped';
import {isShipperRfp} from 'App/containers/userCompany/rfps/utils/typeChecking';

export const InfoBlock = ({rfp}: {rfp: RFPBidOptResponse | RfpCarrierBidRequestOptResponse}) => {
  const rfpDetails = isShipperRfp(rfp) ? rfp : rfp.rfp_bid_opt_details;

  const isArchived = rfpDetails?.archived || false;
  if (isArchived) {
    return (
      <div className="col-span-2 grid grid-cols-10 items-center gap-2 rounded bg-sw-background px-4 py-2 text-sw-label">
        <SvgIcon name="Archive" />
        <span className="col-span-9 font-bold">Archived</span>
        <div />
        <span className="col-span-9">
          {formatDateTimeTimestamp(rfpDetails?.updated_at)}
          {/* {moment(rfpDetails?.updated_at).tz(moment.tz.guess()).format('MM/D/YY H:mm zz')} */}
        </span>
      </div>
    );
  }
  const isExpiredRfp = isPastDate(rfpDetails?.bid_expiry_date_time || '');
  if (isExpiredRfp) {
    return (
      <div className="col-span-2 flex items-center gap-2 rounded bg-sw-background px-4 py-2 text-sw-label">
        <SvgIcon name="Time" />
        <span className="font-bold">{isShipperRfp(rfp) ? 'RFP Dates Expired' : 'Bid Request Expired'}</span>
      </div>
    );
  }
  if (rfpDetails?.state === RFPBidOptResponseStateEnum.Created) {
    return (
      <div className="col-span-2 flex items-center gap-2 rounded bg-sw-active-light px-4 py-2 text-sw-active">
        <SvgIcon name="CheckCircleFilled" />
        <span className="text-sw-text">
          <span className="font-bold">{rfpDetails.number_of_lanes}</span> Lanes Imported
        </span>
      </div>
    );
  }
  const shipperBidsRequested = rfpDetails?.state === RFPBidOptResponseStateEnum.BidRequested;
  const carrierBidsNotDownloaded =
    !isShipperRfp(rfp) &&
    rfp.bid_request_state === RfpCarrierBidRequestOptRequestBidRequestStateEnum.RequestNotDownloaded;
  const carrierBidsDownloaded =
    !isShipperRfp(rfp) && rfp.bid_request_state === RfpCarrierBidRequestOptRequestBidRequestStateEnum.RequestDownloaded;
  if (shipperBidsRequested || carrierBidsNotDownloaded || carrierBidsDownloaded) {
    const {days, hours} = timeRemainingFromToday(rfpDetails?.bid_expiry_date_time || '');
    const lessThanOneHour = days === 0 && hours === 0;
    return (
      <div className="col-span-2 grid grid-cols-10 items-center rounded bg-sw-warning-background px-4 py-2">
        <SvgIcon color="sw-warning" name="ErrorFilled" />
        <span className="col-span-9">Bidding Ends:</span>
        <div />
        <span className="col-span-9">
          {lessThanOneHour ? (
            <span>
              Less than <span className="font-bold">1</span> Hr
            </span>
          ) : (
            <>
              <span className="font-bold">{days}</span> {pluralize('Day', days)}{' '}
              <span className="font-bold">{hours}</span> {pluralize('Hr', hours)}
            </>
          )}
        </span>
      </div>
    );
  }
  // backend added enums but did not migrate existing data, this tries to account for that until we discuss a migration
  const rgCreatedStates = [
    RFPBidOptResponseStateEnum.BidComplete,
    RFPBidOptResponseStateEnum.ContractsRgCreateRequested
  ];
  // this should be shown only after routing guides have been created
  if (rgCreatedStates.some((status) => status === rfpDetails?.state) && rfpDetails?.number_of_created_routing_guides) {
    return (
      <div className="col-span-2 flex items-center gap-2 rounded bg-sw-active-light px-4 py-2 text-sw-active">
        <SvgIcon name="CheckCircleFilled" />
        <div className="flex flex-col text-sw-text">
          <span>
            <span className="font-bold">{rfpDetails.number_of_created_routing_guides}</span> Routing Guides Created on
            RFP
          </span>
        </div>
      </div>
    );
  }

  const isCompletedShipperRfp = isShipperRfp(rfp) && rfpDetails?.state === RFPBidOptResponseStateEnum.BidComplete;
  const isCompletedCarrierRfp =
    !isShipperRfp(rfp) && rfp?.bid_request_state === RfpCarrierBidRequestOptRequestBidRequestStateEnum.ResponseUploaded;
  if (isCompletedShipperRfp || isCompletedCarrierRfp) {
    const label = isCompletedCarrierRfp ? 'Upload Complete!' : 'Bidding Complete!';
    return (
      <div className="col-span-2 grid grid-cols-10 items-center rounded bg-sw-success-background px-4 py-2">
        <SvgIcon color="sw-success" name="CheckCircleFilled" />
        <span className="col-span-9">{label}</span>
        <div />
        <span className="col-span-9">
          <span className="font-bold">
            {rfpDetails?.number_of_lanes_with_bids}/{rfpDetails?.number_of_lanes}
          </span>{' '}
          lanes have bids
        </span>
      </div>
    );
  }
  return <div className="col-span-2" />;
};
