import {AppointmentStatusEnum, Facility} from '@shipwell/tempus-sdk';
import isNil from 'lodash/isNil';
import {Stop} from 'App/api/shipment/typed';
import {AppointmentCard} from 'App/components/appointments';
import {useShipmentsStopAppointmentQuery, useShipmentStopQuery} from 'App/data-hooks';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import ShipwellLoader from 'App/common/shipwellLoader';
import {formatDate} from 'App/utils/dateTimeGlobalsTyped';
import {formatParts} from 'App/data-hooks/appointments/utils';

export type AppointmentStopCardProps = {
  shipmentId?: string | null;
  stopId?: string | null;
  onCancelClick: (appointment: AppointmentEntry | null) => void;
  onScheduleClick: (stop: Stop, facility: Facility, appointment: AppointmentEntry | null) => void;
};
/**
 * Container component wrapping around the AppointmentCard to display a shipment stop card
 * with its associated facility, and appointment information. If there is no facility associated
 * with a stop the card will return `null` and thus not be rendered.
 */
const AppointmentStopCard = ({shipmentId, stopId, onCancelClick, onScheduleClick}: AppointmentStopCardProps) => {
  const {stop, isStopsFetching, isStopsInitialLoading, isStopsSuccess} = useShipmentStopQuery({
    shipmentId,
    stopId
  });
  const {data, isAppointmentLoading} = useShipmentsStopAppointmentQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: isStopsSuccess
    }
  );
  const {facility, dock, appointment, isFirstComeFirstServe} = data;

  const isLoading = Boolean(isStopsFetching || isStopsInitialLoading || isAppointmentLoading);
  if (isLoading) {
    return <ShipwellLoader loading />;
  }

  if (isNil(facility) || isNil(stop)) {
    return null; // stop cannot be scheduled since there is no facility associated with it.
  }
  const appointmentStartTime =
    appointment?.start && formatParts(new Date(appointment?.start?.timestamp), appointment?.start?.timezone);

  const appointmentEndTime =
    appointment?.end && formatParts(new Date(appointment?.end?.timestamp), appointment?.end?.timezone);

  return (
    <AppointmentCard
      stopNumber={stop.stopNumber}
      startTime={appointmentStartTime?.time}
      endTime={appointmentEndTime?.time}
      isFirstComeFirstServe={isFirstComeFirstServe}
      status={appointment?.status || AppointmentStatusEnum.Unscheduled}
      dockName={dock?.name}
      deliveryType={stop.is_pickup ? 'Shipping' : stop.is_dropoff ? 'Receiving' : null}
      facility={{
        facilityName: facility.name,
        facilityCity: facility.address?.locality,
        facilityState: facility.address?.region,
        facilityPostalCode: facility.address?.postal_code
      }}
      onCancelClick={() => onCancelClick(appointment)}
      onScheduleClick={() => onScheduleClick(stop, facility, appointment)}
      onRescheduleClick={() => onScheduleClick(stop, facility, appointment)}
      appointmentDate={appointment?.start?.timestamp ? formatDate(appointment?.start?.timestamp) : '--'}
    />
  );
};

export default AppointmentStopCard;
