import {Shipment, User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {LoadboardStatus, LoadboardType, LoadPostResponse} from '@shipwell/tabula-sdk';
import {get} from 'lodash';

export type ShipmentCardLoadBoardsProps = {
  ordinalIndex: number;
  isCollapsed: boolean;
  onCollapse: () => void;
  draggableProvided: {
    draggableProps: object;
    dragHandleProps: object;
    innerRef: unknown;
  };
  shipmentId: string;
  shipment: Shipment;
  user: User;
};

export type LoadboardItemProps = {
  loadboard: LoadboardStatus;
  shipmentId: Shipment['id'];
  userId: User['id'];
  listLoadPosts: LoadPostResponse[];
  toggleEditing: () => void;
  setLoadpostId: (loadpostId: string) => void;
  setLoadboardType: (loadboardType: LoadboardType) => void;
  setLoadboardName: (loadboardNAme: string) => void;
};

export const getErrorDetail = (error: unknown, fallbackErrorMessage: string) =>
  get(error, 'response.data.errors[0].detail', fallbackErrorMessage);

export type PrivateLoadBoardItemProps = {
  shipment: Shipment;
};
