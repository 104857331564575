import moment from 'moment';
import {allowedEquipmentTypes} from 'App/components/pricingIntelChart/pricingIntelConstants';

export const showPricingIntel = ({hasFTL, historicalPricingEnabled, equipmentType, stops}) => {
  return (
    hasFTL &&
    historicalPricingEnabled &&
    equipmentType?.[0] &&
    allowedEquipmentTypes.some((type) => type.id === equipmentType[0].id) &&
    stops?.length <= 2
  );
};

export const getPickupStopPlannedDate = (stops) => {
  return (
    stops
      .filter(({is_pickup: isPickup}) => isPickup)
      //ensure that pickup_date is formatted according to format backend expects
      .map(({planned_date: plannedDate}) => plannedDate && moment(plannedDate).format('YYYY-MM-DD'))
  );
};

import {hasEmptyStops} from 'App/components/pricingIntelChart/utils';

export const getLaneString = (stops) =>
  !hasEmptyStops(stops)
    ? `${stops[0]?.address?.formatted_address || ''} to ${stops[1]?.address?.formatted_address || ''}`
    : '';
