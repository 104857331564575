import {ContractChargeItem, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, Tooltip} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {Link} from 'react-router';
import {ContractTotalRate} from './ContractTotalRate';
import ShipwellLoader from 'App/common/shipwellLoader';
import {getIsCarrierTendered} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import {useGetTenders, useUserMe} from 'App/data-hooks';
import CarrierDetails from 'App/containers/Shipment/components/EmbeddedPricingIntel/CarrierDetails';
import {formatCurrency} from 'App/utils/internationalConstants';

export type CarrierToDisplay = {
  id: string;
  name: string;
  rateCurrency: string;
  contractId?: string;
  weightUnit?: string;
  rate?: number;
  charges?: ContractChargeItem[];
};

const DisplayCarriers = ({
  isLoading,
  isSectionExpanded,
  handleSelectCarrier,
  carriers,
  shipment,
  isContractRates = false,
  isFTLShipment,
  showDPM,
  totalMiles
}: {
  isLoading: boolean;
  isSectionExpanded: boolean;
  handleSelectCarrier: (carrierId?: string) => void;
  shipment: Shipment;
  carriers: CarrierToDisplay[];
  isContractRates?: boolean;
  isFTLShipment: boolean;
  showDPM?: boolean;
  totalMiles?: number | null;
}) => {
  const {data: {company} = {}} = useUserMe();
  const {data: {results: tendersList = []} = {}} = useGetTenders(
    {shipmentId: shipment.id, createdByCompanyId: company?.id as string},
    {enabled: !!company?.id}
  );

  return (
    <div>
      {isLoading ? (
        <ShipwellLoader loading additionalClassNames={['h-36']} />
      ) : (
        <div>
          {isSectionExpanded ? (
            <>
              <div className="grid grid-cols-[35%,65%] border-b-1 border-sw-border px-3">
                <div className="border-r-1 border-sw-border py-2">Carrier</div>
                <div className="px-3 py-2">Rate</div>
              </div>
              <div>
                {carriers?.length > 0 ? (
                  carriers.map((carrier, idx) => (
                    <div
                      key={carrier.id || idx}
                      className={classNames('grid py-2 grid-cols-[35%,35%,30%]', {
                        'bg-sw-background': idx % 2 === 0
                      })}
                    >
                      <Tooltip
                        tooltipContent={<CarrierDetails carrier={carrier} />}
                        placement="top"
                        trigger="hover"
                        portal
                      >
                        <div className="truncate pl-3">{carrier.name}</div>
                      </Tooltip>
                      {carrier.contractId ? (
                        <ContractTotalRate
                          className="pl-3"
                          charges={carrier.charges}
                          showLaneStatsDPM={showDPM}
                          totalDistance={totalMiles}
                        />
                      ) : (
                        <div className="pl-3">{formatCurrency(carrier.rate, carrier.rateCurrency)}</div>
                      )}
                      {isFTLShipment ? (
                        <div>
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => handleSelectCarrier(isContractRates ? carrier?.contractId : carrier.id)}
                            disabled={getIsCarrierTendered(carrier, tendersList)}
                          >
                            {getIsCarrierTendered(carrier, tendersList) ? 'Tendered' : 'Select'}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center py-10">
                    <div className="flex flex-col items-center">
                      {isContractRates ? (
                        <>
                          <span>No matching contract rates.</span>
                          <Link to="company/lane-management/contracts">View contracts</Link>
                        </>
                      ) : (
                        'No Lane Pricing History.'
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DisplayCarriers;
