import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {useMutation} from '@tanstack/react-query';
import {Card, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import ReferencesView from 'App/containers/shipments/components/ReferencesView';
import TagsView from 'App/containers/shipments/components/TagsView';
import ContainersView from 'App/containers/shipments/drayage/components/ContainersView';
import {ReviewStopsView} from 'App/containers/shipments/components/StopsView';
import {ViewOnlyDocuments} from 'App/containers/shipments/components/Documents';
import {PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import LegDetailsView from 'App/containers/shipments/drayage/components/LegDetailsView';
import {Footer} from 'App/formComponents/formSections/formFooter';
import {createDrayageLegs} from 'App/api/corrogo';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useInvalidateOnSettled} from 'App/utils/queryHelpers';

const EditButton = ({onClick}) => {
  return (
    <DeprecatedButton variant="icon" onClick={onClick}>
      <SvgIcon name="Edit" color="$sw-primary" />
    </DeprecatedButton>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func
};

EditButton.defaultProps = {
  onClick: () => {}
};

const DrayageBookingReview = ({shipmentId, onEdit, router}) => {
  const createInvalidateOnSettled = useInvalidateOnSettled();
  const createDrayageLegsMutation = useMutation(({shipmentId}) => createDrayageLegs(shipmentId), {
    onSettled: createInvalidateOnSettled([SHIPMENTS_QUERY_KEY, shipmentId])
  });

  return (
    <div className="px-6 pb-6">
      <Card title="Shipment Information" className="mb-6" actions={<EditButton onClick={() => onEdit(0)} />}>
        <LegDetailsView shipmentId={shipmentId} />
      </Card>
      <Card title="Stops" className="mb-6" actions={<EditButton onClick={() => onEdit(0)} />}>
        <ReviewStopsView shipmentId={shipmentId} />
      </Card>
      <div className="flex">
        <Card title="References" className="mb-6 mr-3 h-fit" actions={<EditButton onClick={() => onEdit(0)} />}>
          <ReferencesView shipmentId={shipmentId} />
        </Card>
        <Card title="Documents" className="mb-6 ml-3" actions={<EditButton onClick={() => onEdit(0)} />}>
          <ViewOnlyDocuments shipmentId={shipmentId} />
        </Card>
      </div>
      <Card
        title="Tags"
        className="mb-6"
        bodyClassName="flex flex-wrap items-center"
        actions={<EditButton onClick={() => onEdit(0)} />}
      >
        <TagsView shipmentId={shipmentId} />
      </Card>
      <div className="pb-10">
        <Card
          title="Container Details"
          bodyClassName="p-0"
          className="mb-6"
          actions={<EditButton onClick={() => onEdit(1)} />}
        >
          <ContainersView shipmentId={shipmentId} />
        </Card>
      </div>
      <Footer
        primaryActionName="Create Shipment"
        secondaryActionName="Previous - Container Details"
        onCancel={() => onEdit(1)}
        onSubmit={async () => {
          await createDrayageLegsMutation.mutateAsync({shipmentId});
          router.push(`/shipments/${shipmentId}`);
        }}
        displaySecondaryLeft
        isSubmitting={createDrayageLegsMutation.isLoading}
      />
    </div>
  );
};

DrayageBookingReview.propTypes = {
  shipmentId: PropTypes.string,
  onEdit: PropTypes.func,
  router: PROPTYPE_ROUTER
};

DrayageBookingReview.defaultProps = {
  onEdit: () => {}
};

export default withRouter(DrayageBookingReview);
