import {ComponentType} from 'react';
import {compose} from 'recompose';
import pick from 'lodash/pick';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';

export type ProvidedFlagProps<T extends string = string> = {
  [key in T]: boolean | undefined;
};

type ProvidedFlagPredicate<T extends string = string> = (flagValues: ProvidedFlagProps<T>) => boolean;

type WithFlagsFallbackOptions<T extends string = string> = {
  Fallback?: ComponentType;
  fallbackPredicate?: ProvidedFlagPredicate<T>;
  LoadingFallback?: ComponentType;
  loadingFallbackPredicate?: ProvidedFlagPredicate<T>;
};

function someFlagsUndefined<T extends string = string>(flagValues: ProvidedFlagProps<T>) {
  return Object.values(flagValues).some((flagValue) => flagValue === undefined);
}

function someFlagsFalsy<T extends string = string>(flagValues: ProvidedFlagProps<T>) {
  return Object.values(flagValues).some((flagValue) => !flagValue);
}

const withFlagsFallbackOptionsDefaults = {
  Fallback: null,
  fallbackPredicate: someFlagsFalsy,
  LoadingFallback: null,
  loadingFallbackPredicate: someFlagsUndefined
} as const;

/**
 * Return a higher order component that will retrieve values for the given flags, then render a
 * fallback when the flag is falsy and a fallback when the flag is undefined (e.g. when the flag
 * value is loading).
 */
function withFlagsFallback<TComponentProps = Record<string, unknown>>(
  flags: string[],
  options: WithFlagsFallbackOptions<(typeof flags)[number]> = {}
) {
  const {Fallback, LoadingFallback, fallbackPredicate, loadingFallbackPredicate} = {
    ...withFlagsFallbackOptionsDefaults,
    ...options
  };
  return compose<ProvidedFlagProps<(typeof flags)[number]> & TComponentProps, TComponentProps>(
    withFlags(...flags),
    withConditionalFallback(
      (props: ProvidedFlagProps<(typeof flags)[number]>) => loadingFallbackPredicate(pick(props, ...flags)),
      LoadingFallback
    ),
    withConditionalFallback(
      (props: ProvidedFlagProps<(typeof flags)[number]>) => fallbackPredicate(pick(props, ...flags)),
      Fallback
    )
  );
}

export default withFlagsFallback;
