import {ReactNode} from 'react';

export const BidRequestMessage = ({message, children}: {message: string; children?: ReactNode}) => {
  return (
    <div className="flex flex-col gap-8 p-8">
      <div className="flex flex-col items-center gap-8 rounded bg-sw-background p-6">
        <span className="text-center text-3xl font-bold">{message}</span>
        {children}
      </div>
    </div>
  );
};
