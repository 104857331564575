import {useQueryClient} from '@tanstack/react-query';
import {DrayageLegOverallStatus} from '@shipwell/corrogo-sdk';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useDrayageLeg, useCancelLeg} from 'App/data-hooks';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const CancelLeg = ({legId}: {legId: string}) => {
  const {data, isLoading} = useDrayageLeg(legId);
  const isCancelled = data?.overall_status === DrayageLegOverallStatus.Cancelled;
  const cancelLegMutation = useCancelLeg(legId);
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    await cancelLegMutation.mutateAsync();
    return queryClient.invalidateQueries([SHIPMENTS_QUERY_KEY, data?.shipment_ids[0]]);
  };

  return (
    <DeprecatedButton
      disabled={isLoading || isCancelled || cancelLegMutation.isLoading}
      variant="text"
      onClick={() => void handleSubmit()}
    >
      Cancel Container
    </DeprecatedButton>
  );
};

export default CancelLeg;
