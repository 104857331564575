import {Formik, Field} from 'formik';
import PropTypes from 'prop-types';
import {object, string} from 'yup';
import {DeprecatedButton, FormikTextInput} from '@shipwell/shipwell-ui';

/** Password reset form validation */
export const validationSchema = object().shape({
  email: string().email('Please enter a valid email.').required('Please enter a valid email.')
});

/** Default values needed for Formik */
const defaultFormValues = {
  email: ''
};

/**
 * Password Reset Form
 */
const ForgotPasswordForm = (props) => {
  const {onSubmit} = props;

  /** Submit password values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={{...defaultFormValues}} onSubmit={handleSubmit}>
      {({handleSubmit, isSubmitting}) => {
        return (
          <form noValidate="novalidate" onSubmit={handleSubmit}>
            <div className="field-grid grid-1-1">
              <Field
                required
                name="email"
                label="Email"
                type="text"
                component={FormikTextInput}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
            </div>
            <DeprecatedButton disabled={isSubmitting} className="forgot-password__submit" type="submit">
              {isSubmitting ? (
                <span>
                  <i className="icon icon-Restart rotate" /> Requesting
                </span>
              ) : (
                'Request Link'
              )}
            </DeprecatedButton>
          </form>
        );
      }}
    </Formik>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func
};

export default ForgotPasswordForm;
