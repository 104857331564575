import {useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import set from 'lodash/set';
import {Formik, Form} from 'formik';
import {object, string} from 'yup';
import {Card} from '@shipwell/shipwell-ui';
import {fetchShipmentModes, fetchEquipmentTypes} from 'App/actions/_shipments';
import {RoutingGuideDefaultFields} from 'App/formComponents/formSections/routingGuideFields';
import {RoutingGuideActionsDefaultFields} from 'App/formComponents/formSections/routingGuideActionsFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import WithStatusToasts from 'App/components/withStatusToasts';
import {updateCompanySetting} from 'App/containers/userCompany/actions/async';
import 'App/containers/userCompany/defaults/styles.scss';

export const validationSchema = object().shape({
  routingGuides: object().shape({
    email: string().email('A valid email is required.').nullable()
  }),
  actions: object().shape({
    info_message: string().nullable()
  })
});

const CompanyRoutingGuideDefaults = ({dispatch, modes, equipmentTypes, company, setSuccess, setError}) => {
  const handleDefaultsSubmit = async (values) => {
    const companyCustomData = company.custom_data || {};
    set(companyCustomData, 'shipwell_custom_data.company_default_values', values);
    try {
      const response = await dispatch(updateCompanySetting(company.id, {...company, custom_data: companyCustomData}));
      if (response?.ok) {
        setSuccess('Success!', 'Company Routing Guide Defaults Updated Successfully.');
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  useEffect(() => {
    if (modes.length < 1) {
      dispatch(fetchShipmentModes());
    }
    if (equipmentTypes.length < 1) {
      dispatch(fetchEquipmentTypes());
    }
  }, [modes, equipmentTypes, dispatch]);

  return (
    <div className="company-settings-wrapper">
      <div className="companyDefaults__wrapper">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={get(company, 'custom_data.shipwell_custom_data.company_default_values', {})}
          onSubmit={handleDefaultsSubmit}
        >
          {({isSubmitting, dirty, setFieldValue, values, ...props}) => (
            <Form className="contracts__form">
              <Card
                title="Routing Guides"
                className="companyDefaultCard"
                tooltip={
                  <div className="explainer">
                    <div className="explainer__title">Routing Guide Defaults</div>
                    <div className="explainer__details">
                      The defaults you set here for routing guides will be applied across the company as you create new
                      routing guides.
                    </div>
                    <div className="explainer__details">
                      These values can be overridden when you create or edit a Routing Guide.
                    </div>
                  </div>
                }
              >
                <RoutingGuideDefaultFields
                  {...props}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                />
              </Card>
              <Card
                title="Routing Guide Actions"
                className="companyDefaultCard"
                tooltip={
                  <div className="explainer">
                    <div className="explainer__title">Routing Guide Action Defaults</div>
                    <div className="explainer__details">
                      The defaults you set here for routing guides actions will be applied across the company as you
                      create new actions on your routing guides.
                    </div>
                    <div className="explainer__details">
                      These values can be overridden when you add an action to a routing guide.
                    </div>
                  </div>
                }
              >
                <RoutingGuideActionsDefaultFields
                  {...props}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                />
              </Card>
              {dirty && <FormFooter isSubmitting={isSubmitting} />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    modes: state.shipments.shipmentModes,
    equipmentTypes: state.shipments.equipmentTypes,
    company: state.userCompany.company
  })),
  WithStatusToasts
)(CompanyRoutingGuideDefaults);
