/* eslint-disable @typescript-eslint/ban-ts-comment */
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ShipmentStatesValues, ShipwellError, SlimShipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {LEGACY_SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';
import {getShipments} from 'App/api/shipment/typed';
import {ShipwellApiErrorResponse, convertShipwellAxiosError} from 'App/utils/errors';

export type UseShipmentSearchParameters = {
  q?: string;
  pickupLte?: string | null;
  pickupGte?: string | null;
  dropOffLte?: string | null;
  dropOffGte?: string | null;
  excludeStatus?: ShipmentStatesValues[] | null;
  page?: number | null;
  pageSize?: number | null;
  carrierId?: string | null;
};

export type UseShipmentSearchOptions = Omit<
  UseQueryOptions<SlimShipment[], AxiosError<ShipwellApiErrorResponse>, SlimShipment[], string[]>,
  'queryKey' | 'queryFn'
>;

/**
 * Creates a react query search hook for commonly searched fields and yields the results.
 * The query key will be constructed from the values which exist and omits the ones that do not.
 */
const useShipmentSearchQuery = (requestParameters: UseShipmentSearchParameters, options?: UseShipmentSearchOptions) => {
  const nonEmptyRequestValues = Object.values(omitEmptyKeysWithEmptyObjectsRemoved(requestParameters))
    .filter(Boolean)
    .map(String)
    .sort(); // sort to make sure this is always in the exact same format
  const queryKey = [LEGACY_SHIPMENTS_QUERY_KEY, ...nonEmptyRequestValues];
  const query = useQuery(
    queryKey,
    async () => {
      try {
        const results = await getShipments({
          q: requestParameters.q,
          // need to do this really strange casting because of the api requiring a value or undefined
          pickupGte: requestParameters.pickupGte ?? undefined,
          pickupLte: requestParameters.pickupLte ?? undefined,
          dropoffGte: requestParameters.dropOffGte ?? undefined,
          dropoffLte: requestParameters.dropOffLte ?? undefined,
          page: requestParameters.page ?? 1,
          pageSize: requestParameters.pageSize ?? 10,
          //@ts-ignore: statusExclude is required to be an inline subset of ShipmentStatesValues
          statusExclude: requestParameters.excludeStatus ?? undefined,
          vendorId: requestParameters.carrierId ?? undefined
        });
        return results?.results || [];
      } catch (err) {
        // need to catch and rethrow to convert the error to be consistent for our error toasts
        // to handle a single method
        const axiosError = err as AxiosError<ShipwellError>;
        throw convertShipwellAxiosError(axiosError);
      }
    },
    options
  );

  return {
    shipments: query.data || [],
    isShipmentSearchInitialLoading: query.isInitialLoading,
    isShipmentSearchFetching: query.isFetching,
    isShipmentSearchError: query.isError,
    shipmentSearchError: query.error || null,
    shipmentSearchSuccess: query.isSuccess
  };
};

export default useShipmentSearchQuery;
