import {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getBrokerShipperRelationship} from 'App/api/brokers/typed';
import {useCompanyPreferences, useUserMe} from 'App/data-hooks';

export const useBrokerShipperRelationship = () => {
  const [q, setQ] = useState('');
  const user = useUserMe();
  const hasShipperRelationshipPermissions = user.data?.user?.permissions?.some(
    (permission) => permission === 'customer_relationships.view'
  );

  const companyId = user.data?.company?.id || '';
  const companyPreferencesQuery = useCompanyPreferences(companyId);
  const showModalConfigSettings = companyPreferencesQuery.data?.prompt_for_mode_and_customer_when_consolidating_orders;
  const brokerageId = user.data?.company?.brokerage?.id;
  const brokerShipperQuery = useQuery(
    [BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY, brokerageId, q],
    async () => {
      const data = await getBrokerShipperRelationship({
        brokerageId: brokerageId || '',
        q: q
      });
      return data;
    },
    {
      enabled: hasShipperRelationshipPermissions && !!brokerageId,
      keepPreviousData: true
    }
  );

  const showCustomerSelect =
    (!brokerShipperQuery.isInitialLoading && (brokerShipperQuery.data?.results?.length || 0) > 1) || q !== '';
  const showShipperRelationshipModal = showModalConfigSettings || showCustomerSelect;
  const hasSingleCustomer =
    !brokerShipperQuery.isInitialLoading && (brokerShipperQuery.data?.results?.length || 0) === 1 && q === '';

  return {brokerShipperQuery, setQ, showCustomerSelect, hasSingleCustomer, showShipperRelationshipModal};
};
