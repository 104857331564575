/* eslint-disable @typescript-eslint/no-unused-expressions */
import {ChangeEvent, ReactNode, useState} from 'react';
import {DisplayValue, FormikCheckbox, FormikSelect, Modal, Title, Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import {Form, Formik, Field} from 'formik';
import {FuelSurchargeTable} from '@shipwell/backend-core-sdk';
import {object, string, bool} from 'yup';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import type {InferType} from 'yup';
import {compose} from 'recompose';
import pluralize from 'pluralize';
import {AxiosError} from 'axios';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import getTimeOptions from 'App/utils/getTimeOptions';
import {getFuelSurchargeTables} from 'App/api/rateTables/typed';
import {createRoutingGuides, createContracts} from 'App/api/rfpOptimized/typed';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {RFP_OPT_QUERY, RFP_OPT_ROWS_QUERY} from 'App/data-hooks/queryKeys';
import useToggle from 'App/utils/hooks/useToggle';

const validationSchema = object().shape({
  fuel_included: bool().nullable(),
  fuel_surcharge_table: string()
    .nullable()
    .when('fuel_included', {
      is: false,
      then: string().required('Surcharge table is required if fuel is not included.').nullable()
    })
});
const routingGuideValidationSchema = validationSchema.shape({
  tender_expirations: string().required('Expiration time is required.')
});
type SchemaValidation = InferType<typeof validationSchema | typeof routingGuideValidationSchema>;
const initialValues = {tender_expirations: '', fuel_included: true, fuel_surcharge_table: ''} as SchemaValidation;

type CreateRoutingGuideProps = {
  isDisabled: boolean;
  rfpId: string;
  laneCount: number;
  children?: ReactNode;
};

const CONTRACTS_MODAL_CONTEXT = 'contracts';
const ROUTING_GUIDES_MODAL_CONTEXT = 'routingGuides';
type ModalContext = typeof CONTRACTS_MODAL_CONTEXT | typeof ROUTING_GUIDES_MODAL_CONTEXT;

const CreateRoutingGuide = ({
  isDisabled,
  rfpId,
  children,
  laneCount,
  setSuccess,
  setError
}: CreateRoutingGuideProps & WithStatusToastProps) => {
  const [showModal, toggleShowModal] = useToggle(false);
  const [modalContext, setModalContext] = useState<ModalContext>(CONTRACTS_MODAL_CONTEXT);
  const queryClient = useQueryClient();

  const isRoutingGuide = modalContext === ROUTING_GUIDES_MODAL_CONTEXT;
  const title = isRoutingGuide ? 'Create Routing Guides and Contracts' : 'Create Contracts';
  const primaryActionName = isRoutingGuide ? 'Create Routing Guides' : 'Create Contracts';

  const createRoutingGuideMutation = useMutation(createRoutingGuides, {
    onSuccess: (data) => setSuccess(`${pluralize('Routing Guide', laneCount, true)} Requested!`, data.data.status),
    onError: (error: AxiosError<{error_description?: string}>) =>
      setError(
        `${pluralize('Routing Guide', laneCount)} Request Failed!`,
        error.response?.data.error_description || 'There was an error request Routing Guides.'
      ),
    onSettled: async () => {
      toggleShowModal();
      await queryClient.invalidateQueries([RFP_OPT_QUERY, rfpId]);
      await queryClient.invalidateQueries([RFP_OPT_ROWS_QUERY, rfpId]);
    }
  });

  const createContractMutation = useMutation(createContracts, {
    onSuccess: (data) => setSuccess('Contracts requested!', data.data.status),
    onError: (error: AxiosError<{error_description?: string}>) => {
      setError(
        `${pluralize('Contract', laneCount)} Request Failed!`,
        error.response?.data.error_description || 'There was an error requesting Contracts.'
      );
    },
    onSettled: async () => {
      toggleShowModal();
      await queryClient.invalidateQueries([RFP_OPT_QUERY, rfpId]);
      await queryClient.invalidateQueries([RFP_OPT_ROWS_QUERY, rfpId]);
    }
  });

  const isMutatingOrDidMutate =
    createContractMutation.isLoading ||
    createContractMutation.data ||
    createRoutingGuideMutation.data ||
    createRoutingGuideMutation.isLoading;

  const handleSubmit = (values: SchemaValidation) => {
    const variables = {
      rfpBidOptId: rfpId,
      ...(!values.fuel_included && values.fuel_surcharge_table ? {fuelSurchargeId: values.fuel_surcharge_table} : {})
    };
    isRoutingGuide
      ? createRoutingGuideMutation.mutate({
          ...variables,
          workflowTenderExpirySeconds: parseInt(values.tender_expirations)
        })
      : createContractMutation.mutate(variables);
  };

  return (
    <>
      <Dropdown title="RFP Actions" indicator variant="primary" disabled={isDisabled || isMutatingOrDidMutate}>
        {({onClick}: {onClick: () => void}) => [
          <li
            onClick={() => {
              onClick();
              setModalContext(CONTRACTS_MODAL_CONTEXT);
              toggleShowModal();
            }}
            key={CONTRACTS_MODAL_CONTEXT}
          >
            Only Create Contracts
          </li>,
          <li
            onClick={() => {
              onClick();
              setModalContext(ROUTING_GUIDES_MODAL_CONTEXT);
              toggleShowModal();
            }}
            key={ROUTING_GUIDES_MODAL_CONTEXT}
          >
            Create Contracts and Routing Guides
          </li>
        ]}
      </Dropdown>
      <Modal bodyVariant="disableOverflowScroll" show={showModal} onClose={toggleShowModal} title={title}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={isRoutingGuide ? routingGuideValidationSchema : validationSchema}
        >
          {(formikProps) => (
            <Form>
              <div className="flex flex-col gap-4">
                <div className="items-top flex gap-2 rounded bg-sw-error-background p-4 font-bold">
                  <SvgIcon color="sw-error" name="InfoFilled" />
                  <span>
                    {isRoutingGuide ? (
                      <>
                        This is a one time action, once Routing Guides are created no other actions can take place on
                        this RFP.
                      </>
                    ) : (
                      <>
                        This is a one time action, once Contracts are created no other actions can take place on this
                        RFP.
                        <br />
                        <br />
                        Note: Routing Guides cannot be created on this RFP once contracts have been created.
                      </>
                    )}
                  </span>
                </div>
                {children}
                <DisplayValue>
                  {isRoutingGuide ? (
                    <>
                      When all carrier bids have been collected, reviewed, and are ready to be created into Routing
                      Guide workflows, set the tender expiration that will be applied to all Routing Guides created.
                    </>
                  ) : (
                    <>Attach any Fuel Surcharge Tables at this time before the Contracts are created.</>
                  )}
                </DisplayValue>
                {isRoutingGuide ? (
                  <>
                    <Title variant="formTitle">Routing Guide Preferences</Title>
                    <Field
                      simpleValue
                      label="Tender Expiration"
                      required
                      options={getTimeOptions()}
                      name="tender_expirations"
                      component={FormikSelect}
                    />
                  </>
                ) : null}
                <Title variant="formTitle">Fuel Surcharges</Title>
                <div className="grid grid-cols-6 gap-4">
                  <Field
                    label="Fuel Included"
                    name="fuel_included"
                    component={FormikCheckbox}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const {checked} = e.target;
                      // if fuel is included, reset fuel surcharge table to empty string
                      if (checked) {
                        formikProps.setFieldValue('fuel_surcharge_table', '');
                        formikProps.setFieldError('fuel_surcharge_table', '');
                        formikProps.setFieldTouched('fuel_surcharge_table', false);
                      }
                      formikProps.setFieldValue('fuel_included', checked);
                    }}
                  />
                  <div className="col-span-5">
                    <Field
                      async
                      simpleValue
                      disabled={formikProps.values.fuel_included}
                      label="Fuel Surcharge Table"
                      name="fuel_surcharge_table"
                      component={FormikSelect}
                      getOptionValue={(option: FuelSurchargeTable & {id: string}) => option.id}
                      formatOptionLabel={(option: FuelSurchargeTable) => <div>{option.name}</div>}
                      loadOptions={async (q: string) => {
                        const response = await getFuelSurchargeTables({q});
                        return response.results;
                      }}
                      defaultOptions
                    />
                  </div>
                </div>
              </div>
              <ModalFormFooter
                isSubmitting={formikProps.isSubmitting}
                onCancel={toggleShowModal}
                primaryActionName={primaryActionName}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export const CreateRoutingGuideRequest = compose<
  WithStatusToastProps & CreateRoutingGuideProps,
  CreateRoutingGuideProps
>(WithStatusToasts)(CreateRoutingGuide);
