import {Ref, forwardRef} from 'react';
import {ReadbackTag} from 'App/components/TypedTable/complementaryComponents';
import {
  dateOptions,
  getDefaultMode,
  getInputValue,
  getRadioButtonValue
} from 'App/containers/Dashboard/filters/dateSelectUtils';

interface DateReadbackProps {
  label: string;
  start: string;
  stop: string;
  onClear: () => void;
  isHidden?: boolean;
}

export const DateReadback = forwardRef<HTMLButtonElement, DateReadbackProps>(
  ({label, start, stop, onClear, isHidden}, ref: Ref<HTMLButtonElement>) => {
    const mode = getDefaultMode([start, stop]);
    const dateValue = getRadioButtonValue([start, stop]);
    const dateLabel = dateOptions.find((option) => option.value === dateValue)?.label;
    const customDateLabel = getInputValue([start, stop], mode);

    const labelValue = dateLabel ?? customDateLabel;
    return <ReadbackTag label={`${label}: ${labelValue}`} onClick={onClear} isHidden={isHidden} ref={ref} />;
  }
);

DateReadback.displayName = 'DateReadback';
