import {useMutation} from '@tanstack/react-query';
import {NoContentResponse, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {deleteTimelineEvent} from 'App/api/shipment/typed';
import {GROUPED_TIMELINE_EVENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useDeleteTimelineEvent = (shipmentId: string, queryOptions = {}) => {
  const queryKey = [GROUPED_TIMELINE_EVENTS_QUERY_KEY, shipmentId];
  const createOptimisticUpdateHandlers = useOptimisticUpdate<NoContentResponse, ShipwellError, {eventId: string}>();
  const deleteEventMutation = useMutation(
    ({eventId}: {eventId: string}) => deleteTimelineEvent({shipmentId, shipmentTimelineEventId: eventId}),
    createOptimisticUpdateHandlers(queryKey, queryOptions)
  );
  return deleteEventMutation;
};
