import {string, array, object} from 'yup';
import {validateDollarValue} from 'App/utils/globals';

//This file houses both common Yup validation functions that can be passed a form value and return a boolean value as well as Yup helper functions.
//https://github.com/jquense/yup#mixedtestoptions-object-schema

/**
 * Helper function to convert an empty string to null to preserve number data type.
 * Pass into the transform method as detailed here: https://github.com/jquense/yup#mixedtransformcurrentvalue-any-originalvalue-any--any-schema
 * @param {*} value
 * @param {*} originalValue
 */
export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

//helper function used in validateDecimalPoints to return decimals on a float
const countDecimals = (value) => {
  //return 0 if no decimal
  if (Math.floor(value) === value) {
    return 0;
  }
  //long decimals (7+ decimal places) return an exponential. Since we will never need that much precision
  //it's better to return undefined, which can be equivalent to false for decimal validation
  return value.toString().split('.')[1]?.length;
};

/**
 * Test function to determine whether or not a float exceeds the specified number of decimal points.
 * @param {Number} value
 * @param {Number} decimalPlaces (default two decimal places)
 * @return Boolean
 */
export const validateDecimalPoints = (value, decimalPlaces = 2) =>
  !Number.isNaN(value) && countDecimals(value) <= decimalPlaces;

export const validationSchemaDollarValue = string()
  .nullable()
  .test('rate', 'A valid dollar value is required.', (value) => {
    if (value) {
      return validateDollarValue(value, 2);
    }
    return true;
  });

export function requiredMessage({label, path}) {
  return `${label ?? path} is required.`;
}

export const carriersValidationSchema = array()
  .label('Carrier(s)')
  .nullable()
  .required(requiredMessage)
  .min(1, requiredMessage)
  .of(
    object().shape({
      id: string().label('ID').required(requiredMessage),
      pocId: string(),
      carrier: string(),
      carrierName: string(),
      carrierStatus: string()
    })
  );

export const selectOptionValidationSchema = object().nullable().required(requiredMessage).shape({
  label: string().nullable(),
  value: string().nullable()
});
