import {AttachedDevice} from '@shipwell/locus-sdk';
import {useToastManager} from '@shipwell/shipwell-ui';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {getAttachedDevices, quickstartResourceTrackingConfig} from 'App/api/locus/tracking';
import {getTiveError} from 'App/utils/integrations/tive/errors/errors';
import {tryGetDefaultTiveAccount} from 'App/utils/integrations/tive/tive';
import {AVAILABLE_DEVICE_SELECT_QUERY_KEY} from '../devicesForm/availableDeviceSelect/availableDeviceSelect';
import {UseDevicesFormValues} from '../devicesForm/useDevicesForm/useDevicesFormValues';
import {getQuickstartResourceTrackingConfig} from './helpers';

export const USE_DEVICE_SERVICE_LIST_DEVICES_QUERY_KEY = 'USE_DEVICE_SERVICE_LIST_DEVICES_QUERY_KEY';

export interface UseDeviceServiceProps {
  isEnabled: boolean;
  shipmentId: string;
}

export function useDeviceService(props: UseDeviceServiceProps) {
  const {enqueueToast} = useToastManager();
  const queryClient = useQueryClient();

  const listQuery = useQuery({
    queryKey: [USE_DEVICE_SERVICE_LIST_DEVICES_QUERY_KEY],
    queryFn: async () => {
      const response = await getAttachedDevices({
        resourceId: props.shipmentId,
        resourceType: 'SHIPMENT'
      });

      // Temporary workaround due wrong type defined by the SDK
      const list = response.data as unknown as AttachedDevice[];
      const connectedDevices = list.filter((item) => item.connection_status === 'CONNECTED');
      return connectedDevices;
    },
    enabled: props.isEnabled
  });

  function refreshRelatedQueries() {
    queryClient.invalidateQueries([USE_DEVICE_SERVICE_LIST_DEVICES_QUERY_KEY]);
    queryClient.invalidateQueries([AVAILABLE_DEVICE_SELECT_QUERY_KEY]);
  }

  const attachMutation = useMutation({
    mutationFn: async (formValues: UseDevicesFormValues) => {
      const tiveDefaultAccount = await tryGetDefaultTiveAccount();
      const data = getQuickstartResourceTrackingConfig(formValues, tiveDefaultAccount.id, props.shipmentId);
      await quickstartResourceTrackingConfig(data);
    },
    onError: (error) => {
      const tiveError = getTiveError(error);
      enqueueToast({
        variant: 'error',
        title: 'Error!',
        content: (
          <div className="flex flex-col gap-2">
            <div className="font-bold">{tiveError.error}</div>
            <div>{tiveError.details.error}</div>
          </div>
        )
      });
    },
    onSuccess: () => {
      enqueueToast({
        variant: 'success',
        title: 'Success!',
        content: 'Device was successfully attached.'
      });
    },
    onSettled: refreshRelatedQueries
  });

  return {
    isFetching: listQuery.isFetching,
    isAttaching: attachMutation.isLoading,
    attachedDevices: listQuery.data,
    refetch: refreshRelatedQueries,
    attach: attachMutation.mutate
  };
}
