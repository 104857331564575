import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import NewLoadForm from './NewLoadForm';
import * as actions from 'App/actions/shipments';
import * as formActions from 'App/actions/forms';
import * as documentActions from 'App/actions/documents';
import './_new-shipment.scss';
import {
  unpackErrors,
  constructShipmentObject,
  transformLocationAppointmentTypes,
  transformEquipmentType,
  transformShipmentMode
} from 'App/utils/globals';

@connect(
  (state) => ({
    company: state.auth.company,
    newLoadForm: state.form.newLoadForm,
    addressBook: state.shipments.addressbook,
    hazmatCodes: state.shipments.hazmatCodes,
    locationTypes: state.addresses.locationTypes,
    actingAsCompany: state.brokers.actingAsCompany,
    carrierRelationships: state.vendors.carrierRelationships,
    equipmentTypes: state.shipments.equipmentTypes,
    shipmentModes: state.shipments.shipmentModes,
    appointmentTypes: state.shipments.appointmentTypes
  }),
  {
    ...formActions,
    ...actions,
    ...documentActions
  }
)
export default class NewLoad extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  static propTypes = {
    fetchShipmentId: PropTypes.func,
    handleRedirect: PropTypes.func,
    handleSetShipId: PropTypes.func,
    onFormSubmit: PropTypes.func
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.saveNewShipmentForm = this.saveNewShipmentForm.bind(this);
    this.state = {errorOverride: null};
  }

  saveNewShipmentForm(attrs) {
    if (attrs.stops) {
      attrs.stops = transformLocationAppointmentTypes(
        attrs.stops,
        this.props.locationTypes,
        this.props.appointmentTypes
      );
    }

    if (attrs.mode) {
      attrs.mode = transformShipmentMode(attrs.mode, this.props.shipmentModes);
    }

    if (attrs.equipment_type) {
      attrs.equipment_type = transformEquipmentType(attrs.equipment_type, this.props.equipmentTypes);
    }
    const shipmentObj = constructShipmentObject(attrs);
    let equipment_config = {};
    if (attrs.equipment_config) {
      equipment_config = {
        driver: attrs.equipment_config.driver,
        power_unit: attrs.equipment_config.power_unit,
        trailer: attrs.equipment_config.trailer
      };
    }

    const metadata = {tags: []};
    if (attrs.metadata && attrs.metadata.tags && attrs.metadata.tags.length > 0) {
      for (let i = 0; i < attrs.metadata.tags.length; i++) {
        if (attrs.metadata.tags[i].id) {
          metadata.tags.push(attrs.metadata.tags[i].id);
        } else {
          metadata.tags.push(attrs.metadata.tags[i]);
        }
      }
    }
    shipmentObj.metadata = metadata;

    const opts = {};
    if (attrs.book_as_customer) {
      //add the xShipperId
      opts['xCompanyId'] = attrs.book_as_customer.value;
    }
    //POST the shipment object
    return this.props.createShipment(shipmentObj, opts).then((response) => {
      if (response.status === 200) {
        //proceed to kick off the RFQ process
        const rfqObj = {};
        //default to false
        rfqObj.autoquote = false;
        rfqObj.shipment = response.details.id;
        rfqObj.parent_rfq = null;
        rfqObj.equipment_types = [attrs.equipment_type] || [];
        rfqObj.shipment_modes = [attrs.mode];
        //only create an RFQ if there is financial information on this shipment, otherwise just redirect
        if (attrs.financials) {
          return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
            if (rfqResponse.status === 200) {
              //RFQ was created successfully, since this is a manual shipment, we need to assign the financials and carrier info
              //create a new quote
              const quoteObj = {};
              if (attrs.financials) {
                quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials));
              } else {
                quoteObj.charge_line_items = [];
              }
              if (quoteObj.charge_line_items.length > 0) {
                let total = 0;
                for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
                  //strip out any commas or leading $
                  if (
                    typeof quoteObj.charge_line_items[i].unit_amount === 'string' &&
                    quoteObj.charge_line_items[i].unit_amount.startsWith('$')
                  ) {
                    quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.slice(1);
                  }
                  //replace commas if this is a string
                  if (typeof quoteObj.charge_line_items[i].unit_amount === 'string') {
                    quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.replace(
                      /,/g,
                      ''
                    );
                  }
                  quoteObj.charge_line_items[i].unit_amount = parseFloat(quoteObj.charge_line_items[i].unit_amount);
                  quoteObj.charge_line_items[i].unit_quantity = parseInt(quoteObj.charge_line_items[i].unit_quantity);
                  total += parseFloat(
                    quoteObj.charge_line_items[i].unit_amount * quoteObj.charge_line_items[i].unit_quantity
                  );
                }
                quoteObj.total = total;
                //if there is markup
                if (attrs.markup) {
                  //add a new charge_line_item and update the total
                  quoteObj.charge_line_items.push({
                    unit_amount: parseFloat(attrs.markup),
                    unit_name: 'Item Charge',
                    is_provider_markup: true,
                    unit_quantity: 1,
                    category: ChargeCategory.Lh,
                    charge_code: 'LHS'
                  });
                  quoteObj.total += parseFloat(attrs.markup);
                  quoteObj.total = parseFloat(quoteObj.total).toFixed(2);
                }
              }
              //add the mode that this quote will use

              quoteObj.rfq = rfqResponse.details.id;

              //add the default service mode TODO make these selectable
              quoteObj.mode = attrs.mode.id || 1;
              quoteObj.service_level = attrs.service_level || 19;
              quoteObj.equipment_type = attrs.equipment_type.id;
              //create the new quote
              return this.props.createQuote(quoteObj.rfq, quoteObj).then((quoteResponse) => {
                if (quoteResponse.status === 200) {
                  //now award the quote to the shipment
                  return this.props
                    .awardQuote(response.details.id, {
                      quote: quoteResponse.details.id
                    })
                    .then((awardResponse) => {
                      if (awardResponse.status === 200) {
                        this.props.resetNewShipmentForm();
                        this.context.router.push('/dashboard/');
                      } else {
                        //show errors
                        const errors = awardResponse.field_errors || [];
                        let submissionError = {};
                        submissionError = unpackErrors(errors, submissionError, [
                          'stops',
                          'line_items',
                          'financials',
                          'charge_line_items'
                        ]);
                        submissionError._error = awardResponse.error_description;
                        //handle edge cases for errors here
                        throw new SubmissionError(submissionError);
                      }
                    });
                }
                //show errors
                const errors = quoteResponse.field_errors || [];
                let submissionError = {};
                submissionError = unpackErrors(errors, submissionError, [
                  'stops',
                  'line_items',
                  'financials',
                  'charge_line_items'
                ]);
                submissionError._error = quoteResponse.error_description;
                if (submissionError.charge_line_items) {
                  submissionError.financials = submissionError.charge_line_items;
                }
                //handle edge cases for errors here
                this.setState({
                  errorOverride: quoteResponse.error_description
                });
                throw new SubmissionError(submissionError);
              });
            }
            //show errors
            const errors = rfqResponse.field_errors || [];
            let submissionError = {};
            submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
            submissionError._error = rfqResponse.error_description;
            //handle edge cases for errors here
            this.setState({
              errorOverride: rfqResponse.error_description
            });
            throw new SubmissionError(submissionError);
          });
        }
        this.props.resetNewShipmentForm();
        this.context.router.push('/dashboard/');
      } else {
        //show errors
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items', 'financials']);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        this.setState({
          errorOverride: response.error_description
        });
        throw new SubmissionError(submissionError);
      }
    });
  }

  onFormSubmit(attrs) {
    if (attrs.option === 'save-only') {
      return this.saveNewShipmentForm(attrs);
    }
    if (attrs.stops) {
      attrs.stops = transformLocationAppointmentTypes(
        attrs.stops,
        this.props.locationTypes,
        this.props.appointmentTypes
      );
    }

    if (attrs.mode) {
      attrs.mode = transformShipmentMode(attrs.mode, this.props.shipmentModes);
    }
    //transform equipment type to the dict
    if (attrs.equipment_type) {
      attrs.equipment_type = transformEquipmentType(attrs.equipment_type, this.props.equipmentTypes);
    }

    const shipmentObj = constructShipmentObject(attrs);
    // Set self as carrier
    shipmentObj.current_carrier = this.props.company;

    // Equipment config
    let equipment_config = {};
    if (attrs.equipment_config) {
      equipment_config = {
        power_unit: attrs.equipment_config.power_unit,
        trailer: attrs.equipment_config.trailer
      };
      if (attrs.equipment_config.driver && attrs.equipment_config.driver.phone_number) {
        equipment_config.driver = attrs.equipment_config.driver;
      }
    }
    const opts = {};
    if (attrs.book_as_customer) {
      //add the xShipperId
      if (attrs.book_as_customer.value) {
        opts['xCompanyId'] = attrs.book_as_customer.value;
      } else {
        opts['xCompanyId'] = attrs.book_as_customer;
      }
    }
    if (shipmentObj && shipmentObj.line_items && shipmentObj.line_items.length > 0) {
      for (let i = 0; i < shipmentObj.line_items.length; i++) {
        if (shipmentObj.line_items[i].hasOwnProperty('package_weight') && !shipmentObj.line_items[i].package_weight) {
          delete shipmentObj.line_items[i].package_weight;
        }
      }
    }

    const metadata = {tags: []};
    if (attrs.metadata && attrs.metadata.tags && attrs.metadata.tags.length > 0) {
      for (let i = 0; i < attrs.metadata.tags.length; i++) {
        if (attrs.metadata.tags[i].id) {
          metadata.tags.push(attrs.metadata.tags[i].id);
        } else {
          metadata.tags.push(attrs.metadata.tags[i]);
        }
      }
    }
    shipmentObj.metadata = metadata;

    //POST the shipment object
    return this.props.createShipment(shipmentObj, opts).then((response) => {
      if (response.status === 200) {
        //proceed to kick off the RFQ process
        const rfqObj = {};
        //default to false
        rfqObj.autoquote = false;
        rfqObj.shipment = response.details.id;
        rfqObj.parent_rfq = null;
        rfqObj.equipment_types = [attrs.equipment_type] || [];
        rfqObj.shipment_modes = [attrs.mode];
        //pass in opts in case this is a broker working on a shipper account
        return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
          if (rfqResponse.status === 200) {
            //RFQ was created successfully, since this is a manual shipment, we need to assign the financials and carrier info
            //create a new quote
            const quoteObj = {};
            if (attrs.financials) {
              quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials));
            } else {
              quoteObj.charge_line_items = [];
            }
            if (quoteObj.charge_line_items.length > 0) {
              let total = 0;
              for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
                //strip out any commas or leading $
                if (
                  typeof quoteObj.charge_line_items[i].unit_amount === 'string' &&
                  quoteObj.charge_line_items[i].unit_amount.startsWith('$')
                ) {
                  quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.slice(1);
                }
                //replace commas if this is a string
                if (typeof quoteObj.charge_line_items[i].unit_amount === 'string') {
                  quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.replace(
                    /,/g,
                    ''
                  );
                }
                quoteObj.charge_line_items[i].unit_amount = parseFloat(quoteObj.charge_line_items[i].unit_amount);
                quoteObj.charge_line_items[i].unit_quantity = parseInt(quoteObj.charge_line_items[i].unit_quantity);
                total += parseFloat(
                  quoteObj.charge_line_items[i].unit_amount * quoteObj.charge_line_items[i].unit_quantity
                );
              }
              quoteObj.total = total;
              //if there is markup
              if (attrs.markup) {
                //add a new charge_line_item and update the total
                quoteObj.charge_line_items.push({
                  unit_amount: parseFloat(attrs.markup),
                  unit_name: 'Item Charge',
                  is_provider_markup: true,
                  unit_quantity: 1,
                  category: ChargeCategory.Lh,
                  charge_code: 'LHS'
                });
                quoteObj.total += parseFloat(attrs.markup);
                quoteObj.total = parseFloat(quoteObj.total).toFixed(2);
              }
            }
            //add the mode that this quote will use

            quoteObj.rfq = rfqResponse.details.id;

            //add the default service mode TODO make these selectable
            quoteObj.mode = attrs.mode.id || 1;
            quoteObj.service_level = attrs.service_level || 19;
            //create the new quote
            quoteObj.equipment_type = attrs.equipment_type.id;

            //deliberately NOT sending opts in here, assuming that the quote should be attributed to the logged in user
            return this.props.createQuote(quoteObj.rfq, quoteObj).then((quoteResponse) => {
              if (quoteResponse.status === 200) {
                //now award the quote to the shipment
                return this.props
                  .awardQuote(response.details.id, {
                    quote: quoteResponse.details.id
                  })
                  .then((awardResponse) => {
                    if (awardResponse.status === 200) {
                      //generate a BOL
                      let BOLid = null;
                      this.props.generateBOL(response.details.id).then((BOLResponse) => {
                        if (BOLResponse.details) {
                          BOLid = BOLResponse.details.id;
                        }
                        //send the shipment booked emails (to the POCs)
                        this.props.sendShipmentBooked(response.details.id);
                        equipment_config.carrier = this.props.company;
                        return this.props
                          .assignEquipmentConfig(response.details.id, equipment_config)
                          .then((assignEquipmentResponse) => {
                            if (assignEquipmentResponse.status === 200) {
                              if (attrs.option === 1 && BOLid !== null) {
                                //notify the BOL page that this is successful dispatch
                                this.props.triggerSuccessfulDispatch(true, true);

                                this.props.resetNewLoadForm();
                                //go straight to the BOL
                                this.context.router.push('/shipments/' + response.details.id + '/documents/' + BOLid);
                              } else {
                                //proceed to the shipment details screen now that it's all set
                                this.props.resetNewLoadForm();
                                this.context.router.push('/shipments/' + response.details.id);
                              }
                            } else {
                              //show errors
                              const errors = assignEquipmentResponse.field_errors || [];
                              let submissionError = {};
                              submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
                              submissionError._error = assignEquipmentResponse.error_description;
                              //handle edge cases for errors here
                              throw new SubmissionError(submissionError);
                            }
                          });
                      });
                    } else {
                      //show errors
                      const errors = awardResponse.field_errors || [];
                      let submissionError = {};
                      submissionError = unpackErrors(errors, submissionError, [
                        'stops',
                        'line_items',
                        'financials',
                        'charge_line_items'
                      ]);
                      submissionError._error = awardResponse.error_description;
                      //handle edge cases for errors here
                      throw new SubmissionError(submissionError);
                    }
                  });
              }
              //show errors
              const errors = quoteResponse.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, [
                'stops',
                'line_items',
                'financials',
                'charge_line_items'
              ]);
              submissionError._error = quoteResponse.error_description;
              //handle edge cases for errors here
              throw new SubmissionError(submissionError);
            });
          }
          //show errors
          const errors = rfqResponse.field_errors || [];
          let submissionError = {};
          submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
          submissionError._error = rfqResponse.error_description;
          //handle edge cases for errors here
          throw new SubmissionError(submissionError);
        });
      }
      //show errors
      const errors = response.field_errors || [];
      let submissionError = {};

      submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
      submissionError._error = response.error_description;
      //handle edge cases for errors here
      if (submissionError.stops) {
        for (var i = 0; i < submissionError.stops.length; i++) {
          if (submissionError.stops[i].location && submissionError.stops[i].location.point_of_contacts) {
            submissionError.stops[i].point_of_contacts = [];
            submissionError.stops[i].point_of_contacts[0] = submissionError.stops[i].location.point_of_contacts[0];
            delete submissionError.stops[i].location.point_of_contacts;
            if (Object.keys(submissionError.stops[i].location).length === 0) {
              delete submissionError.stops[i].location;
            }
          } else {
            if (Object.keys(submissionError.stops[i]).length === 0) {
              delete submissionError.stops[i];
            }
          }
        }
      }
      throw new SubmissionError(submissionError);
    });
  }

  render() {
    return (
      <div className="new-shipment content-wrapper">
        <section className="content ">
          <NewLoadForm
            errorOverride={this.state.errorOverride}
            onSubmit={this.onFormSubmit}
            saveNewShipmentForm={this.saveNewShipmentForm}
          />
        </section>
      </div>
    );
  }
}
