import {Checkbox} from '@shipwell/shipwell-ui';
import {useFormikContext} from 'formik';
import {isEqual, uniq} from 'lodash';
import {UserWithPermissions} from '../UserForm';
import {PermissionsByActionType} from '../../permissionsCategories';
import {ChangeEvent} from 'react';

const writeActions = ['update', 'create', 'delete'] as const;

export const PermissionsCheckbox = ({
  permissions,
  actions,
  'aria-label': ariaLabel,
  enableReadCheckboxIfWriteGranted = false
}: {
  permissions: PermissionsByActionType;
  actions: ('view' | 'update' | 'create' | 'delete')[];

  'aria-label': string;
  enableReadCheckboxIfWriteGranted?: boolean;
}) => {
  const {values, setFieldValue} = useFormikContext<UserWithPermissions>();
  const userPermissions = values.permissions;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const viewPermissions = permissions['view'];
      const deduplicatedNewUserPermissions = uniq([...userPermissions, ...allAvailablePermissions, ...viewPermissions]);
      setFieldValue('permissions', deduplicatedNewUserPermissions.sort()); // sorting keeps Formik's `dirty` flag accurate
    } else {
      const permissionsWithThisGroupRemoved = userPermissions.filter((perm) => !allAvailablePermissions.includes(perm));
      setFieldValue('permissions', permissionsWithThisGroupRemoved);
    }
  };

  const isInUserPermissions = (permission: string) => userPermissions.includes(permission);
  const allAvailablePermissions = actions.map((actionType) => permissions[actionType]).flat();
  const isChecked = allAvailablePermissions.length > 0 && allAvailablePermissions.every(isInUserPermissions);
  const isIndeterminate = allAvailablePermissions.some(isInUserPermissions) && !isChecked;
  const allWritePermissions = writeActions.map((actionType) => permissions[actionType]).flat();
  const hasSomeWritePermissions = allWritePermissions.some(isInUserPermissions);
  const isViewCheckbox = isEqual(actions, ['view']);
  const isDisabledViewCheckbox =
    !enableReadCheckboxIfWriteGranted && isViewCheckbox && hasSomeWritePermissions && isChecked;
  const isDisabled = allAvailablePermissions.length === 0 || isDisabledViewCheckbox;

  return (
    <Checkbox
      aria-label={ariaLabel}
      checked={isChecked}
      indeterminate={isIndeterminate}
      disabled={isDisabled}
      onChange={handleChange}
    />
  );
};
