import {WithRouterProps} from 'react-router';
import {useQuery, useQueries} from '@tanstack/react-query';
import isUndefined from 'lodash/isUndefined';
import {CarrierTag, ServiceProvider} from '@shipwell/backend-core-sdk';
import ServiceProviderForm from 'App/formComponents/forms/serviceProvider';
import PageHeader from 'App/common/pageHeader';
import {getCarrierTagsById} from 'App/api/carriers/typed';
import {getServiceProviderPromise} from 'App/api/serviceProviders/typed';
import {SERVICE_PROVIDER_QUERY_KEY, CARRIER_TAG_KEY} from 'App/data-hooks/queryKeys';

const ServiceProviderDetailsContainer = ({router, params}: WithRouterProps) => {
  const {serviceProviderId} = params;
  const getServiceProviderQuery = useQuery<ServiceProvider>(
    [SERVICE_PROVIDER_QUERY_KEY, serviceProviderId],
    async () => {
      const result = await getServiceProviderPromise(serviceProviderId);
      return result.data;
    },
    {enabled: !!serviceProviderId}
  );
  const tagsQueries = useQueries({
    queries:
      getServiceProviderQuery.data?.tags?.map((tagId) => ({
        queryKey: [CARRIER_TAG_KEY, tagId],
        queryFn: async () => {
          const response = await getCarrierTagsById(tagId);
          return response.data;
        },
        enabled: !!tagId
      })) || []
  });

  const serviceProvider: (Omit<ServiceProvider, 'tags'> & {tags: CarrierTag[]}) | undefined =
    getServiceProviderQuery.data
      ? {
          ...getServiceProviderQuery.data,
          tags: tagsQueries.map((query) => query.data).filter((tag) => !isUndefined(tag)) as CarrierTag[]
        }
      : undefined;

  const redirectToList = () => {
    router.push('/service-providers');
  };

  const isTagsLoading = tagsQueries.reduce((memo, query) => memo || query.isInitialLoading, false);

  return serviceProvider && !isTagsLoading ? (
    <>
      <PageHeader title={serviceProvider?.name || ''} backRoute={`/service-providers`} />
      <div className="mx-auto mb-[100px] mt-0 flex w-full max-w-[1600px] flex-col items-center px-5 py-8">
        <ServiceProviderForm defaultValues={serviceProvider} onSubmit={redirectToList} onCancel={redirectToList} />
      </div>
    </>
  ) : null;
};

export default ServiceProviderDetailsContainer;
