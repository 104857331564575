import {useState, useCallback, useEffect} from 'react';
import isEqual from 'lodash/isEqual';
import has from 'lodash/has';

interface ItemStateItem {
  key?: string;
  [x: string]: unknown;
}

interface ItemState {
  item: ItemStateItem;
  state: unknown;
}

/*
 * Hook used to store metadata relating to specific list items.
 * The constructor takes in a list of items, and associates a defaultState to that item.
 * The data provided in the list must be unique and support equality comparisons, or have a unique key property associated with each item.
 */
export default function useListState(listItems: Array<ItemStateItem> = [], defaultState: unknown = false) {
  const [itemStates, setItemStates] = useState<Array<ItemState>>([]);

  useEffect(() => {
    setItemStates((previousItemStates) =>
      listItems.map((item) => {
        const prevItemState = previousItemStates.find((itemState) =>
          has(itemState.item, 'key') && has(item, 'key')
            ? isEqual(itemState.item.key, item.key)
            : isEqual(itemState.item, item)
        );
        return {item, state: prevItemState ? prevItemState.state : defaultState};
      })
    );
  }, [listItems, defaultState]);

  // gets the metadata, or "state", of a particular list item
  const getItemState = useCallback(
    (item: ItemStateItem) => {
      return itemStates.find((itemState) =>
        has(itemState.item, 'key') && has(item, 'key')
          ? isEqual(itemState.item.key, item.key)
          : isEqual(itemState.item, item)
      )?.state;
    },
    [itemStates]
  );

  // gets all list items matching a particular state
  const filterByState = useCallback(
    (state: ItemState['state']) => {
      return itemStates.filter((itemState) => isEqual(itemState.state, state)).map((itemState) => itemState.item);
    },
    [itemStates]
  );

  // sets the metadata, or "state", of a particular list item
  const setItemState = useCallback(
    (item: ItemStateItem, state: unknown) => {
      const selectedItemIndex = itemStates.findIndex((itemState) =>
        has(itemState.item, 'key') && has(item, 'key')
          ? isEqual(itemState.item.key, item.key)
          : isEqual(itemState.item, item)
      );
      const selectedItem = itemStates[selectedItemIndex];
      selectedItem.state = state;
      const newItemStates = itemStates.slice();
      newItemStates.splice(selectedItemIndex, 1, selectedItem);
      setItemStates(newItemStates);
    },
    [itemStates]
  );

  // sets the metadata, or "state", of all list items
  const setAllItemStates = useCallback((state: unknown) => {
    setItemStates((previousItemStates) => previousItemStates.map((itemState) => ({item: itemState.item, state})));
  }, []);

  // returns true if at least one item matches the provided state
  const someMatchState = useCallback(
    (state: unknown) => {
      return filterByState(state).length > 0;
    },
    [filterByState]
  );

  // returns true if every item matches the provided state
  const everyMatchesState = useCallback(
    (state: unknown) => {
      return isEqual(filterByState(state).length, itemStates.length);
    },
    [filterByState, itemStates]
  );

  return {
    getItemState,
    setItemState,
    setAllItemStates,
    filterByState,
    someMatchState,
    everyMatchesState
  };
}
