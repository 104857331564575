import {useState} from 'react';
import {RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Select} from '@shipwell/shipwell-ui';
import {useGetFedExAccountsQuery} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedexAccounts';

export const SelectFedexRatesAccount = ({
  rfq,
  onAccountChange
}: {
  rfq?: RFQ;
  onAccountChange?: (selectedAccount: string, provider: string) => Promise<void>;
}) => {
  const [account, setAccount] = useState(rfq?.fedex_specific_options?.account || null);
  const {fedExAccounts, fedExQuery} = useGetFedExAccountsQuery();
  const accounts = fedExAccounts?.map(({id, account_number}) => ({id: id, label: account_number})) || [];

  const handleAccountChange = async (selectedAccount?: string) => {
    if (selectedAccount) {
      setAccount(selectedAccount);
      await onAccountChange?.(selectedAccount, 'fedex');
    }
  };

  return (
    <Select
      simpleValue
      value={account}
      label="Account"
      options={accounts}
      onChange={handleAccountChange}
      clearable={false}
      isLoading={fedExQuery.isInitialLoading}
    />
  );
};
