import {Fragment, Component} from 'react';
import moment from 'moment';
import _ from 'lodash';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      selectedStartDate: null,
      selectedEndDate: null,
      calendarMode: 'on',
      showCalendar: false,
      multiDateFilter: false
    };
  }

  componentDidMount() {
    //there may be default selections we need to populate the state initially
    if (this.props.defaultValues) {
      this.loadData(this.props.defaultValues);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValues !== this.props.defaultValues && this.props.defaultValues === null) {
      //we received a 'clear' signal from the parent
      this.setState({
        selected: null,
        showCalendar: false,
        selectedStartDate: null,
        selectedEndDate: null,
        calendarMode: 'on'
      });
    } else if (prevProps.defaultValues !== this.props.defaultValues) {
      this.loadData(this.props.defaultValues);
    }
  }

  /*
   * Parse the default values and set state appropriately
   */
  loadData(defaultValues) {
    //first check if this is a single date or a range.
    if (defaultValues.length === 1) {
      //parse using Moment, to determine if this is today, yesterday, or tomorrow
      const dateToLoad = defaultValues[0];
      if (moment(dateToLoad, 'YYYY-MM-DD').isSame(moment(), 'day')) {
        //this is today
        if (!this.state.showCalendar) {
          this.setState({selected: moment(new Date()).format('YYYY-MM-DD')});
        }
      } else if (moment(dateToLoad, 'YYYY-MM-DD').isSame(moment().subtract(1, 'day'), 'day')) {
        //this is yesterday
        if (!this.state.showCalendar) {
          this.setState({
            selected: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD')
          });
        }
      } else if (moment(dateToLoad, 'YYYY-MM-DD').isSame(moment().add(1, 'day'), 'day')) {
        //this is tomorrow
        if (!this.state.showCalendar) {
          this.setState({
            selected: moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
          });
        }
      } else {
        //this is another day on the calendar
        this.setState({
          calendarMode: 'on',
          selectedStartDate: moment(dateToLoad).toDate(),
          showCalendar: true,
          selected: null
        });
      }
      // it is possible for a user to select today and one week from today when calendarMode is set to 'between'...
      // ...the added !== 'between' check makes sure this logic doesn't override user settings
    } else if (defaultValues.length === 2 && this.state.calendarMode !== 'between') {
      if (
        moment(defaultValues[0], 'YYYY-MM-DD').isSame(moment().subtract(7, 'day'), 'day') &&
        moment(defaultValues[1], 'YYYY-MM-DD').isSame(moment(), 'day')
      ) {
        this.setState({
          selected: `${moment(new Date()).subtract(7, 'day').format('YYYY-MM-DD')},
            '-',
            ${moment(new Date()).format('YYYY-MM-DD')}`,
          selectedStartDate: moment(new Date()).subtract(7, 'day').format('YYYY-MM-DD'),
          selectedEndDate: moment(new Date()).format('YYYY-MM-DD'),
          standardMultiDateFilter: true
        });
      } else if (
        moment(defaultValues[0], 'YYYY-MM-DD').isSame(moment().subtract(30, 'day'), 'day') &&
        moment(defaultValues[1], 'YYYY-MM-DD').isSame(moment(), 'day')
      ) {
        this.setState({
          selected: `${moment(new Date()).subtract(30, 'day').format('YYYY-MM-DD')},
            '-',
            ${moment(new Date()).format('YYYY-MM-DD')}`,
          selectedStartDate: moment(new Date()).subtract(30, 'day').format('YYYY-MM-DD'),
          selectedEndDate: moment(new Date()).format('YYYY-MM-DD'),
          standardMultiDateFilter: true
        });
      } else if (
        moment(defaultValues[1], 'YYYY-MM-DD').isSame(moment().add(7, 'day'), 'day') &&
        moment(defaultValues[0], 'YYYY-MM-DD').isSame(moment(), 'day')
      ) {
        this.setState({
          selected: `${moment(new Date()).format('YYYY-MM-DD')},
            '-',
            ${moment(new Date()).add(7, 'day').format('YYYY-MM-DD')}`,
          selectedStartDate: moment(new Date()).format('YYYY-MM-DD'),
          selectedEndDate: moment(new Date()).add(7, 'day').format('YYYY-MM-DD'),
          standardMultiDateFilter: true
        });
      } else if (
        moment(defaultValues[1], 'YYYY-MM-DD').isSame(moment().add(30, 'day'), 'day') &&
        moment(defaultValues[0], 'YYYY-MM-DD').isSame(moment(), 'day')
      ) {
        this.setState({
          selected: `${moment(new Date()).format('YYYY-MM-DD')},
          '-',
          ${moment(new Date()).add(30, 'day').format('YYYY-MM-DD')}`,
          selectedStartDate: moment(new Date()).format('YYYY-MM-DD'),
          selectedEndDate: moment(new Date()).add(30, 'day').format('YYYY-MM-DD'),
          standardMultiDateFilter: true
        });
      } else {
        //selecting a range or before/after
        const startDate = defaultValues[0];
        const endDate = defaultValues[1];
        //determine the mode based on what data was supplied
        const calendarMode = startDate ? (endDate ? 'between' : 'after') : 'before';
        this.setState({
          showCalendar: true,
          calendarMode: calendarMode,
          selectedStartDate: startDate ? moment(startDate).toDate() : null,
          selectedEndDate: endDate ? moment(endDate).toDate() : null
        });
      }
    }
  }

  /*
   * Handle selection of the primary filter options (today, tomorrow, yesterday, custom)
   */
  handleSelect(value) {
    const {selected, standardMultiDateFilter, selectedStartDate, selectedEndDate} = this.state;
    const clearFilter = () => {
      this.setState({selected: null, standardMultiDateFilter: false, selectedStartDate: null});
      const filterObject = {};
      filterObject[this.props.fieldName] = null;
      this.props.updateActiveFilters(filterObject);
    };
    //switching between standard multi-date filters
    if (selected === value) {
      return clearFilter();
    }
    this.setState({selected: value});
    this.closeCalendar();
    const filterObject = {};
    //pass as an array, which will be used to determine GTE/LTE by the parent
    if (value.length === 2) {
      if (value[0] === selectedStartDate && value[1] === selectedEndDate) {
        return clearFilter();
      }
      //parse date range value arrays properly
      filterObject[this.props.fieldName] = value;
    } else {
      filterObject[this.props.fieldName] = [value];
    }
    this.props.updateActiveFilters(filterObject);
  }

  /*
   * Handle switches in the calendar mode
   */
  selectCalendarMode(mode) {
    if (this.state.calendarMode === mode) {
      //remove end date
      const startDate = this.state.selectedStartDate
        ? this.state.selectedStartDate
        : this.state.selectedEndDate
        ? this.state.selectedEndDate
        : null;
      this.setState({calendarMode: 'on', selectedEndDate: null}, () => {
        this.onCalendarChange(startDate);
      });
      return;
    }
    if (mode === 'before') {
      //remove start date
      const endDate = this.state.selectedEndDate
        ? this.state.selectedEndDate
        : this.state.selectedStartDate
        ? this.state.selectedStartDate
        : null;
      this.setState({calendarMode: mode, selectedStartDate: null, selectedEndDate: endDate}, () => {
        this.onCalendarChange(endDate);
      });

      return;
    }
    if (mode === 'after') {
      //remove end date
      const startDate = this.state.selectedStartDate
        ? this.state.selectedStartDate
        : this.state.selectedEndDate
        ? this.state.selectedEndDate
        : null;
      this.setState({calendarMode: mode, selectedEndDate: null, selectedStartDate: startDate}, () => {
        this.onCalendarChange(startDate);
      });
      return;
    }
    if (mode === 'between') {
      this.setState({calendarMode: mode});
    }
  }

  /*
   * Pass calendar mode changes on to parent
   */
  onCalendarChange(date) {
    const {calendarMode} = this.state;
    switch (calendarMode) {
      case 'on':
        //set just the start date and inform parent
        this.setState({selectedStartDate: date});
        this.updateParent([moment(date).format('YYYY-MM-DD')]);
        break;
      case 'before':
        //set just the end date (LTE) and inform parent
        this.setState({selectedEndDate: date, selectedStartDate: null});
        this.updateParent([null, moment(date).format('YYYY-MM-DD')]);
        break;

      case 'after':
        //set just the start date (GTE) and inform parent
        this.setState({selectedEndDate: null, selectedStartDate: date});
        this.updateParent([moment(date).format('YYYY-MM-DD'), null]);
        break;

      case 'between':
        //the date we receive is an array
        this.setState({selectedStartDate: date[0], selectedEndDate: date[1]});
        this.updateParent([moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')]);
        break;
    }
  }

  /*
   * Pass values up to the parent component for reloading
   */
  updateParent(value) {
    const filterObject = {};
    //the value we receive is already an array
    filterObject[this.props.fieldName] = value;
    this.props.updateActiveFilters(filterObject);
  }

  /*
   * Display the calendar component and set the default start and end dates to today
   */
  openCalendar() {
    this.setState({
      selected: null,
      showCalendar: true,
      selectedStartDate: moment().toDate(),
      selectedEndDate: moment().toDate()
    });
  }

  closeCalendar() {
    this.setState({showCalendar: false, selectedStartDate: null, selectedEndDate: null});
  }

  render() {
    const options = [
      {
        label: 'Yesterday',
        value: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
      },
      {label: 'Today', value: moment(new Date()).format('YYYY-MM-DD')},
      {
        label: 'Last 7 Days',
        value: [moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      },
      {
        label: 'Last 30 Days',
        value: [moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }
    ];

    const {preventFuture} = this.props;
    if (!preventFuture) {
      options.splice(2, 0, {
        label: 'Tomorrow',
        value: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      }),
        options.push(
          {
            label: 'Next 7 Days',
            value: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(7, 'days').format('YYYY-MM-DD')]
          },
          {
            label: 'Next 30 Days',
            value: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(30, 'days').format('YYYY-MM-DD')]
          }
        );
    }

    const {selectedStartDate, selectedEndDate, showCalendar, calendarMode, standardMultiDateFilter, selected} =
      this.state;

    const calendarValue =
      calendarMode === 'between'
        ? [selectedStartDate, selectedEndDate]
        : calendarMode === 'before'
        ? selectedEndDate
        : selectedStartDate; // for 'on' or 'after', use the start date

    const rangePickerOptions = [
      {label: 'Before', id: 'before'},
      {label: 'After', id: 'after'},
      {label: 'Between', id: 'between'}
    ];

    return (
      <div className="tableFilters__listSelect tableFilters__datePicker">
        {/* show custom option if standardMultiDate or no date range selected */}
        {((!selectedStartDate && !selectedEndDate) || standardMultiDateFilter) && (
          <div onClick={this.openCalendar.bind(this)} className="tableFilters__datePicker-placeholder">
            Custom
          </div>
        )}
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            let isSelected;
            //if multi-date filter, compare start and end date with options
            if (selected && selectedStartDate && selectedEndDate && option.value && option.value.length === 2) {
              if (selectedStartDate === option.value[0] && selectedEndDate === option.value[1]) {
                isSelected = true;
              }
            } else if (selected === option.value) {
              isSelected = true;
            } else {
              isSelected = false;
            }
            const selectedClass = isSelected ? 'selected' : '';
            return (
              <div
                className={`tableFilters__listSelect-option ${selectedClass}`}
                key={index}
                onClick={() => this.handleSelect(option.value)}
              >
                {option.label}
              </div>
            );
          })}

        {(selectedStartDate || selectedEndDate) && !standardMultiDateFilter && (
          <div className="tableFilters__datePicker-customRange">
            {_.upperFirst(calendarMode) + ' '}
            {selectedStartDate && <span>{moment(selectedStartDate).format('MMM D, YYYY')}</span>}
            {selectedStartDate && selectedEndDate && <span> - </span>}
            {selectedEndDate && <span>{moment(selectedEndDate).format('MMM D, YYYY')}</span>}
          </div>
        )}
        {showCalendar && (
          <Fragment>
            <div className="tableFilters__datePicker-rangePicker">
              {rangePickerOptions.map((option, index) => {
                const isSelectedRangePicker = calendarMode === option.id ? 'selected' : '';

                return (
                  <span
                    key={index}
                    className={`${isSelectedRangePicker} tableFilters__datePicker-rangePickerOption`}
                    onClick={() => this.selectCalendarMode(option.id)}
                  >
                    {option.label}
                  </span>
                );
              })}
            </div>
            <Calendar
              className="tableFilters__datePicker-calendar"
              selectRange={calendarMode === 'between'}
              value={calendarValue}
              onChange={this.onCalendarChange.bind(this)}
              calendarType="US"
              maxDate={preventFuture ? new Date() : null}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default DatePicker;
