import {REQUEST_PERMISSIONS_SUCCESS} from '../actions';

/** Initial reducer state */
export const INITIAL_STATE = {
  permissions: [],
  permissionsByType: {},
  permissionsOrdering: [
    'analytics',
    'billing',
    'carrier',
    'carrier_relationships',
    'company',
    'contracts',
    'customer_relationships',
    'drivers',
    'invoicing',
    'messaging',
    'orders',
    'quotes',
    'shipments',
    'third_party_app_integrations',
    'users'
  ]
};

/**
 * Company user permissions reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: payload,
        permissionsByType: Object.keys(payload).reduce((permissionsByType, permission) => {
          return {
            ...permissionsByType,
            [permission]: payload[permission].permissions.map((permission) => permission.key)
          };
        }, {})
      };

    default:
      return state;
  }
};
