import {
  DeliveryTypeEnum,
  AvailabilityWindow,
  AvailabilityRestriction,
  ScheduledResourceTypeEnum,
  FacilityDock,
  Facility,
  LoadType,
  AppointmentStatusEnum,
  ScheduledResourceMetadata
} from '@shipwell/tempus-sdk';
import isNil from 'lodash/isNil';

import {CreateAppointmentParameters, ScheduledResourceType} from 'App/api/appointments/types';
import {
  AppointmentAvailabilityRestriction,
  AppointmentAvailabilityWindow,
  TimezoneAwareDateTime,
  AppointmentEntry
} from 'App/data-hooks/appointments/types';
import {addMinutes, convertDuration, timeZoneAwareParse} from 'App/utils/dateTimeGlobalsTyped';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';
import {MappedDockType} from 'App/data-hooks/facilities/types';

export type CreateAppointmentProperties = {
  carrierName?: string | null;
  carrierTenantId?: string | null;
  driverId?: string | null;
  start: Date;
  end: Date;
  scheduledResourceId?: string | null;
  scheduledResourceType?: ScheduledResourceType | null;
  shipmentReferenceId?: string | null;
  stopId?: string | null;
  timezone: string;
  deliveryType?: string | null;
  loadType?: string;
  isAllDay?: boolean;
};

const formatParts = (date: Date | string, timezone: string): TimezoneAwareDateTime => {
  if (typeof date === 'string') {
    date = timeZoneAwareParse(date, timezone);
  }

  const dateTimeParts = Intl.DateTimeFormat(undefined, {
    timeZone: timezone,
    year: 'numeric', // gives 4 digit year
    month: '2-digit', // gives leading 0,
    hour12: true,
    hour: '2-digit', // gives leading 0
    minute: '2-digit', // gives leading 0
    second: '2-digit', // gives leading 0
    day: '2-digit', // gives leading 0
    timeZoneName: 'short' // gives abbreviated timezone name
  }).formatToParts(date);

  let hour = '';
  let minute = '';
  let dayPeriod = '';
  let timeZoneName = '';
  let year = '';
  let month = '';
  let day = '';

  dateTimeParts.forEach(({type, value}) => {
    switch (type) {
      case 'year':
        year = value;
        break;
      case 'month':
        month = value;
        break;
      case 'day':
        day = value;
        break;
      case 'hour':
        hour = value;
        break;
      case 'minute':
        minute = value;
        break;
      case 'dayPeriod':
        dayPeriod = value;
        break;
      case 'timeZoneName':
        timeZoneName = value; // abbreviated timezone name
        break;
      case 'second': // not interested
      case 'literal': // not interested
      default:
        break;
    }
  });

  return {
    original: date,
    timezone: {
      name: timezone,
      abbreviated: timeZoneName
    },
    date: `${year}-${month}-${day}`,
    time: `${hour}:${minute} ${dayPeriod}`,
    full: `${year}-${month}-${day} ${hour}:${minute} ${dayPeriod}`
  };
};

const minTimezoneAwareDateTime = (a: TimezoneAwareDateTime, b: TimezoneAwareDateTime): TimezoneAwareDateTime => {
  return +a.original <= +b.original ? a : b;
};
const maxTimezoneAwareDateTime = (a: TimezoneAwareDateTime, b: TimezoneAwareDateTime): TimezoneAwareDateTime => {
  return +a.original >= +b.original ? a : b;
};

const cmpTimezoneAwareDateTime = (a: TimezoneAwareDateTime, b: TimezoneAwareDateTime): number => {
  return Math.sign(+a.original - +b.original);
};

const transformDeliveryType = (deliveryType?: string | null): DeliveryTypeEnum | undefined => {
  let deliveryTypeEnum: DeliveryTypeEnum | undefined = undefined;
  if (deliveryType?.toLowerCase() === 'shipping') {
    deliveryTypeEnum = DeliveryTypeEnum.Shipping;
  } else if (deliveryType?.toLowerCase() === 'receiving') {
    deliveryTypeEnum = DeliveryTypeEnum.Receiving;
  }
  return deliveryTypeEnum;
};

function parseAvailabilityWindows(availabilityWindows: AvailabilityWindow[]): AppointmentAvailabilityWindow[] {
  return availabilityWindows.map(({start, end, dock_id}) => ({
    startDate: formatParts(new Date(start.timestamp), start.timezone),
    endDate: formatParts(new Date(end.timestamp), end.timezone),
    dockId: dock_id || '',
    isAllDay: false
  }));
}

function parseAvailabilityRestrictions(restrictions: AvailabilityRestriction[]): AppointmentAvailabilityRestriction[] {
  return restrictions.map(({start, end, dock_id: dockId, reason}) => ({
    startDate: formatParts(new Date(start.timestamp), start.timezone),
    endDate: formatParts(new Date(end.timestamp), end.timezone),
    dockId,
    reason
  }));
}

/**
 * Gets the first matching load type id and returns the number of minutes the appointment
 * takes. If no duration is provided by the API or if no load type was matched a default
 * of 30 minutes is returned.
 * @param {MappedDockType} dock This should be a filtered dock off of the API response which will be used to validate that the load type exists on the dock and can be applied.
 * @param {LoadType[]} loadTypes Full set of load types available at the facility.
 * @param {string[]|null} matchedLoadTypeIds the load type ids that were matched from the API.
 * @throws {Error} An error will be thrown if there are no load types on a facility.
 * @returns {number} number of minutes 0 to infinity
 */
const getLoadTypeMinutes = (
  dock: MappedDockType,
  loadTypes: LoadType[],
  matchedLoadTypeIds?: string[] | null
): number => {
  const defaultWindowMinutes = 30;
  if (!matchedLoadTypeIds?.length) {
    return defaultWindowMinutes;
  }
  if (!loadTypes?.length) {
    return defaultWindowMinutes;
  }
  // perform this in order assuming the API returns the load type IDs in the order of "best match"
  // search for the best match in the dock that we are getting minutes for or default to 30 minutes
  // if not found.
  for (let i = 0; i < matchedLoadTypeIds.length; ++i) {
    const matchedLoadType = loadTypes.find((loadType) => loadType.id === matchedLoadTypeIds[i]);
    if (isNil(matchedLoadType) || isNil(matchedLoadType.appointment_duration)) {
      continue;
    }

    if (dock.dockRules.some((rule) => rule.load_type_id === matchedLoadType.id)) {
      return convertDuration(matchedLoadType.appointment_duration, 'minutes');
    }
  }

  return defaultWindowMinutes;
};

/**
 * Gets the dock which is best suited for the requested window for the appointment.
 * @param {Date} start the inclusive staring datetime of the appointment
 * @param {Date} end the inclusive ending datetime of the appointment
 * @param {AvailabilityWindow} availabilityWindows Broad windows which were/are returned from the API
 * @param {actualAvailableDocks} actualAvailableDocks filtered set of docks which are usable filtered to be best suited for the appointment.
 * @returns {MappedDockType|null} either the best dock for the job or none.
 */
const getBestMatchedDock = (
  start: Date,
  end: Date,
  availabilityWindows: AvailabilityWindow[],
  actualAvailableDocks: MappedDockType[]
): MappedDockType | null => {
  const filteredWindows = availabilityWindows.filter((window) => {
    const windowStart = new Date(window.start.timestamp);
    const windowEnd = new Date(window.end.timestamp);
    return windowStart.valueOf() <= start.valueOf() && windowEnd.valueOf() >= end.valueOf();
  });
  for (let i = 0; i < actualAvailableDocks.length; ++i) {
    const dock = actualAvailableDocks[i];
    if (filteredWindows.some((window) => window.dock_id === dock.id)) {
      return dock;
    }
  }
  return null;
};

/**
 * Creates a calculated set of windows to the best matched dock for the appointment.
 * @param {AvailabilityWindow} availabilityWindows the full list of windows the API provided
 * @param {number} splitMinutes the number of minutes to slice each appointment by.
 * @returns {{start: TimezoneAwareDateTime, end: TimezoneAwareDateTime}[]} list of windows which can be selected for the appointment.
 */
const chunkDateTimeWindows = (
  availabilityWindows: AvailabilityWindow[],
  splitMinutes: number
): {start: TimezoneAwareDateTime; end: TimezoneAwareDateTime}[] => {
  const calculatedAvailabilityWindows = availabilityWindows.map(({start, end}) => {
    const earliestDate = new Date(start.timestamp);
    const latestDate = new Date(end.timestamp);
    const innerWindows: {start: TimezoneAwareDateTime; end: TimezoneAwareDateTime}[] = [];
    for (
      let windowStart = earliestDate;
      windowStart.valueOf() < latestDate.valueOf();
      windowStart = addMinutes(windowStart, splitMinutes)
    ) {
      const windowEnd = addMinutes(windowStart, splitMinutes);
      if (windowEnd.valueOf() > latestDate.valueOf()) {
        break; // don't add a window that is past the latest date
      }
      innerWindows.push({
        start: formatParts(windowStart, start.timezone),
        end: formatParts(windowEnd, end.timezone)
      });
    }
    return innerWindows;
  });

  return calculatedAvailabilityWindows.flat();
};

function chunkTimezoneAwareDateTimeRange(
  start: TimezoneAwareDateTime,
  end: TimezoneAwareDateTime,
  timezone: string,
  splitMinutes: number
): {start: TimezoneAwareDateTime; end: TimezoneAwareDateTime}[] {
  const earliestDate = new Date(start.original);
  const latestDate = new Date(end.original);
  const innerWindows: {start: TimezoneAwareDateTime; end: TimezoneAwareDateTime}[] = [];
  for (
    let windowStart = earliestDate;
    windowStart.valueOf() < latestDate.valueOf();
    windowStart = addMinutes(windowStart, splitMinutes)
  ) {
    const windowEnd = addMinutes(windowStart, splitMinutes);
    if (windowEnd.valueOf() > latestDate.valueOf()) {
      break; // don't add a window that is past the latest date
    }
    innerWindows.push({
      start: formatParts(windowStart, timezone),
      end: formatParts(windowEnd, timezone)
    });
  }
  return innerWindows;
}

const transformScheduleResourceType = (
  type?: ScheduledResourceType | 'FREIGHT_GENERAL' | null
): ScheduledResourceTypeEnum | undefined => {
  switch (type) {
    case 'LEG':
      return ScheduledResourceTypeEnum.Leg;
    case 'FREIGHT_GENERAL':
      return ScheduledResourceTypeEnum.FreightGeneral;
    case 'SERVICE':
      return ScheduledResourceTypeEnum.Service;
    case 'SHIPMENT':
      return 'SHIPMENT' as ScheduledResourceTypeEnum; // we're going to lie for now because the SDK isn't completely up to date
    default:
      return undefined;
  }
};

const transformCreateAppointment = (
  facilityId: string,
  dockId: string | null | undefined,
  appointment: CreateAppointmentProperties
): CreateAppointmentParameters => {
  const {
    carrierName,
    carrierTenantId,
    driverId,
    end,
    scheduledResourceId,
    scheduledResourceType,
    shipmentReferenceId,
    start,
    stopId,
    timezone,
    deliveryType,
    loadType,
    isAllDay
  } = appointment;
  return {
    facilityId,
    dockId: dockId ?? undefined,
    createAppointment: omitEmptyKeysWithEmptyObjectsRemoved({
      stop_id: stopId ?? undefined,
      delivery_type: transformDeliveryType(deliveryType),
      carrier_name: carrierName ?? undefined,
      carrier_tenant_id: carrierTenantId ?? undefined,
      driver_id: driverId ?? undefined,
      scheduled_resource_id: scheduledResourceId ?? undefined,
      scheduled_resource_type: transformScheduleResourceType(scheduledResourceType),
      reference_id: shipmentReferenceId ?? undefined,
      start: {
        timestamp: start.toISOString(),
        timezone: timezone
      },
      end: {
        timestamp: end.toISOString(),
        timezone: timezone
      },
      matched_load_type_id: loadType,
      is_all_day: isAllDay
    })
  };
};

const transformSupplierAppointmentCreationPayload = ({
  supplierAppointmentFormData,
  availabilityDock,
  availabilityWindow,
  facility
}: {
  supplierAppointmentFormData: SupplierAppointmentCreationFormType;
  availabilityDock: FacilityDock | null;
  availabilityWindow: AppointmentAvailabilityWindow;
  facility: Facility | null;
}): CreateAppointmentParameters => {
  const {
    reference_number,
    reference_type,
    carrier_name,
    delivery_type,
    mode,
    equipment_type,
    stackable,
    is_hazmat,
    carrier_email,
    carrier_phone_number,
    notes,
    is_all_day,
    load_type
  } = supplierAppointmentFormData;
  const supplierAppointmentScheduledResourceMetadata: ScheduledResourceMetadata = omitEmptyKeysWithEmptyObjectsRemoved({
    resource_type: 'FREIGHT_GENERAL',
    reference_number,
    reference_number_type: reference_type,
    delivery_type,
    mode,
    equipment_type,
    stackable,
    is_hazmat,
    carrier_name,
    carrier_email,
    carrier_phone_number,
    notes
  });
  return {
    facilityId: facility?.id || '',
    dockId: availabilityDock?.id,
    createAppointment: omitEmptyKeysWithEmptyObjectsRemoved({
      start: {
        timestamp: availabilityWindow.startDate.original.toISOString(),
        timezone: availabilityWindow.startDate.timezone.name
      },
      end: {
        timestamp: availabilityWindow.endDate.original.toISOString(),
        timezone: availabilityWindow.endDate.timezone.name
      },
      is_all_day,
      reference_id: reference_number,
      carrier_name,
      delivery_type,
      scheduled_resource_type: ScheduledResourceTypeEnum.FreightGeneral,
      scheduled_resource_metadata: supplierAppointmentScheduledResourceMetadata,
      matched_load_type_id: load_type || undefined
    })
  };
};

/**
 * sorts all appointments by most recent to least recent and returns the msot recent one.
 * If the appointments are missing or have no data then `null` is returned.
 */
const getMostRecentAppointment = (appointments?: AppointmentEntry[] | null): AppointmentEntry | null => {
  if (isNil(appointments)) {
    return null;
  }
  const excludeStatuses = [AppointmentStatusEnum.Cancelled.toString()];
  const appointmentsFiltered = appointments.filter(({status}) => !excludeStatuses.includes(status));
  if (!appointmentsFiltered.length) {
    return null;
  }
  // sort by start timestamp desc to get the most recent time that an appointment is/was scheduled
  const mostRecentAppointment = appointmentsFiltered.sort((left, right) => {
    // sort in most recent to least recent.
    // both of these will be converted to the current system's perceived timezone so
    // comparing them is safe. i.e. no funny business with timezone differences.
    const leftStartDate = new Date(left.start?.timestamp ?? '1970-01-02T00:00:00Z');
    const rightStartDate = new Date(right.start?.timestamp ?? '1970-01-02T00:00:00Z');
    return leftStartDate.valueOf() - rightStartDate.valueOf();
  })[appointmentsFiltered.length - 1];

  return mostRecentAppointment;
};

export {
  getMostRecentAppointment,
  formatParts,
  transformCreateAppointment,
  transformDeliveryType,
  parseAvailabilityWindows,
  parseAvailabilityRestrictions,
  chunkDateTimeWindows,
  transformSupplierAppointmentCreationPayload,
  getLoadTypeMinutes,
  getBestMatchedDock,
  minTimezoneAwareDateTime,
  maxTimezoneAwareDateTime,
  cmpTimezoneAwareDateTime,
  chunkTimezoneAwareDateTimeRange
};
