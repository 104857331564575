import isNil from 'lodash/isNil';
import {FormControl} from 'react-bootstrap';
import FormGroup from '../../formGroup';

/**
 * Input Field
 */
const InputField = (props) => {
  const {req, input, icon = 'form-control-no-icon', ...rest} = props;
  const value = !isNil(input.value) ? input.value : props.value;
  const name = input.name || props.name;
  const onChange = input.onChange || props.onChange;

  return (
    <FormGroup {...props}>
      {() => (
        <FormControl
          {...rest}
          className={icon}
          name={name}
          value={value}
          onChange={(value) => onChange && onChange(value)}
        />
      )}
    </FormGroup>
  );
};

InputField.defaultProps = {
  name: '',
  value: '',
  input: {}
};

export default InputField;
