import {ShipmentDocumentMetadata} from '@shipwell/backend-core-sdk';
import {STOP_POC_MESSAGE, BILL_OF_LADING_ATTACHED} from 'App/containers/Dashboard/utils/constants';

function groupByShipmentId(queryDocuments: ShipmentDocumentMetadata[]) {
  const shipmentMap: Record<string, string[]> = {};

  for (const document of queryDocuments) {
    const {id, shipment} = document;

    if (shipment && id) {
      if (shipmentMap[shipment]) {
        shipmentMap[shipment].push(id);
      } else {
        shipmentMap[shipment] = [id];
      }
    }
  }

  return shipmentMap;
}

export function shipmentEmailPayload(
  queryDocuments: ShipmentDocumentMetadata[],
  selectedDocumentIds: string[],
  emails: string[]
) {
  const documents = queryDocuments.filter((document) => selectedDocumentIds.includes(document?.id || ''));

  const groupedShipments = groupByShipmentId(documents);
  const payload = Object.entries(groupedShipments).map((shipment) => {
    return {
      shipment_id: shipment[0],
      shipment_documents: [...shipment[1]],
      recipients: emails.flat(),
      type: STOP_POC_MESSAGE,
      message: BILL_OF_LADING_ATTACHED
    };
  });

  return payload;
}
