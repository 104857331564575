import _ from 'lodash';
import moment from 'moment';
import {
  ALERT_ERROR,
  DISABLE_SIGNUP_SUBMIT,
  ENABLE_SIGNUP_SUBMIT,
  SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS,
  INITIAL_QUOTE_VALUES,
  INITIAL_SHIPMENT_VALUES,
  SUCCESSFUL_DISPATCH,
  SET_CLONE_TRIGGER,
  CANCEL_ONDEMAND,
  FETCH_SHIPPER_LINE_ITEMS,
  POST_SHIPPER_LINE_ITEM,
  PUT_SHIPPER_LINE_ITEM,
  TRIGGER_MARKETPLACE_REDIRECT,
  GET_SHIPMENT_POST_OFFERS,
  GET_SHIPPER_CARRIERS,
  FETCH_SHIPPER_FTL_CARRIERS,
  GET_SHIPPER_DASHBOARDS,
  POPULATE_RECIPIENT_EMAILS,
  TRIGGER_REBOOK,
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  SELECT_SHIPMENT,
  CREATE_RFQ,
  SELECT_RFQ,
  SELECT_BROKER_RFQ,
  CREATE_QUOTE,
  SELECT_QUOTE,
  INITIAL_QUOTE_LOADING,
  SELECT_CARRIER_RATE,
  CLEAR_SELECT_CARRIER_RATE,
  EDIT_QUOTE,
  AWARD_QUOTE,
  GO_TO_STOPS,
  GET_SHIPMENT_MESSAGES,
  POST_SHIPMENT_MESSAGES,
  CLEAR_SHIPMENT_MESSAGES,
  SET_HISTORY_FILTER,
  GET_SHIPPER_CREDIT,
  FETCH_ALLONDEMANDS,
  FETCH_ONDEMAND,
  FETCH_UNPAID_SHIPMENTS,
  FETCH_SHIPMENT_OFFERS,
  DELETE_OFFERS,
  FETCH_SHIPMENT_QUOTES,
  DELETE_SHIPMENT_QUOTES,
  FETCH_BOL,
  FETCH_RATE_CON,
  DISPATCH_SHIPMENT_QUOTE,
  UPDATE_BROKER_MARGIN,
  CLEAR_SELECTED_SHIPMENT,
  CLEAR_SELECTED_QUOTE,
  SELECT_SHIPMENT_FOR_FORM,
  RESET_SHIPMENTS,
  FETCH_SHIPMENTS,
  FETCH_SHIPMENTS_AND_PERSIST,
  GET_SHIPMENT_TIMELINE,
  DELETE_SHIPMENT_TIMELINE_EVENT,
  FETCH_ACCESSORIAL_CODES,
  GET_NMFC_CODES,
  GET_APPOINTMENT_TYPES,
  GET_SERVICE_LEVELS,
  GET_SHIPMENT_MODES,
  GET_EQUIPMENT_TYPES,
  GET_PACKAGE_TYPES,
  GET_PIECE_TYPES,
  FETCH_PACKAGE_TYPES_SUCCESS,
  FETCH_UPS_PACKAGE_TYPES_SUCCESS,
  FETCH_USPS_PACKAGE_TYPES_SUCCESS,
  GET_HAZMAT_CODES,
  GET_CHARGE_CATEGORIES,
  REQ_DATA,
  RESET_ERROR,
  SHIPMENTS_ERROR,
  SHIPPER_ID,
  FETCH_DASHBOARD,
  FETCH_TAGS,
  FETCH_TAG,
  CLEAR_TAG,
  GET_SHIPMENT_STATUSES
} from '../actions/types';
import {defaultStopsFields} from 'App/formComponents/formSections/shipmentStopsFields/utils/constants';
import {filterAccessorials} from 'App/utils/globals';

const INITIAL_STATE = {
  accessorialCodes: [],
  //since viable shipment acccesorial codes are not clearly demarcated in backend,
  //and are instead a list of P44-approved codes that we know work, store
  //shipment accessorials in it's own separate key for access
  shipmentAccessorialCodes: [],
  alertError: null,
  all: null,
  appointmentTypes: [],
  breadcrumbs: [],
  cancelled: [],
  carrierGroups: [],
  carriers: [],
  chargeCategories: [],
  currLoc: [],
  dashboard: {},
  detailLoc: [],
  details: null,
  equipmentTypes: [],
  error: false,
  eta: [],
  goToStops: false,
  hazmatCodes: [],
  historyFilter: null,
  isLoading: false,
  lastGeo: [],
  lineItems: [],
  marketplaceRedirect: false,
  messages: [],
  ondemands: [],
  one: [],
  order: [],
  packageTypes: [],
  pieceTypes: [],
  fedexPackageTypes: [],
  upsPackageTypes: [],
  uspsPackageTypes: [],
  postOffers: [],
  priced: [],
  quotes: null,
  rebook: [],
  shipmentCharges: [],
  selectedShipment: null,
  selectedShipmentAuctionStatus: false,
  selectedRFQ: null,
  selectedQuote: null,
  selectedShipmentForForm: null,
  shipmentOffer: null,
  shipperCredit: null,
  shipperDashboards: [],
  successfulDispatch: false,
  timeline: [],
  triggerRebook: null,
  unpaidShipments: [],
  serviceLevels: [],
  nmfcCodes: [],
  shipmentModes: [],
  selectedCarrierRate: {
    service_level: '19',
    mode: '1',
    equipment_type: '1',
    expires_at: moment().add(7, 'days').toDate()
  },
  selectedBrokerRFQ: null,
  sendCustomerQuote: null,
  initialQuoteLoading: true,
  newShipmentFormInitial: {
    stops: [
      {
        ...defaultStopsFields[0]
      },
      {
        ...defaultStopsFields[1]
      }
    ],
    ftl_estimated_weight_unit: 'LB',
    accessorials: [],
    service_level: '19',
    carrier: 'select'
  },
  newLoadFormInitial: {
    stops: [
      {
        rKey: 1,
        is_pickup: true,
        is_dropoff: false,
        location: {
          address: {}
        },
        location_type: 1,
        accessorials: [],
        schedule_date_toggle: '1',
        schedule_time_toggle: '2',
        planned_time_window_start: moment().hour(8).minute(0).second(0).toDate(),
        planned_time_window_end: moment().hour(18).minute(0).second(0).toDate(),
        point_of_contacts: []
      },
      {
        rKey: 2,
        is_pickup: false,
        is_dropoff: true,
        location: {
          address: {}
        },
        location_type: 1,
        schedule_date_toggle: '1',
        schedule_time_toggle: '2',
        planned_time_window_start: moment().hour(8).minute(0).second(0).toDate(),
        planned_time_window_end: moment().hour(18).minute(0).second(0).toDate(),
        accessorials: [],
        point_of_contacts: []
      }
    ],
    ftl_estimated_weight_unit: 'LB',
    accessorials: [],
    service_level: '19',
    mode: '1',
    equipment_type: '1',
    carrier: 'select'
  },
  disableSubmit: false,
  cloneTrigger: false,
  cloneShipment: {},
  shipmentCount: 0,
  tags: [],
  selectedTag: {},
  savedQuoteForCustomerFinancials: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALERT_ERROR:
      return {
        ...state,
        alertError: action.payload
      };
    case DISABLE_SIGNUP_SUBMIT:
      return {
        ...state,
        disableSubmit: true
      };
    case ENABLE_SIGNUP_SUBMIT:
      return {
        ...state,
        disableSubmit: false
      };
    case SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS:
      return {
        ...state,
        savedQuoteForCustomerFinancials: action.payload
      };
    case INITIAL_QUOTE_VALUES:
      return {
        ...state,
        newQuoteFormInitial: action.payload
      };
    case INITIAL_SHIPMENT_VALUES:
      return {
        ...state,
        newShipmentFormInitial: action.payload
      };
    case SUCCESSFUL_DISPATCH: {
      const isFTL = action.payload.isFTL;
      return {
        ...state,
        successfulDispatch: action.payload.trigger,
        dispatchWasFTL: isFTL
      };
    }
    case SET_CLONE_TRIGGER:
      return {
        ...state,
        cloneTrigger: action.payload.bool,
        cloneShipment: action.payload.shipment
      };
    case CANCEL_ONDEMAND:
      return {
        ...state,
        cancelled: action.payload
      };
    case FETCH_SHIPPER_LINE_ITEMS:
      return {
        ...state,
        lineItems: action.payload
      };
    case POST_SHIPPER_LINE_ITEM: {
      const lineItems = state.lineItems;
      lineItems.push(action.payload);
      return {
        ...state,
        lineItems: lineItems
      };
    }
    case PUT_SHIPPER_LINE_ITEM: {
      const lineItems = state.lineItems;
      for (let i = 0; i < lineItems.length; i++) {
        if (lineItems[i].id === action.payload.id) {
          lineItems[i] = action.payload;
        }
      }
      return {
        ...state,
        lineItems: lineItems
      };
    }
    case TRIGGER_MARKETPLACE_REDIRECT:
      return {
        ...state,
        marketplaceRedirect: action.payload
      };
    case GET_SHIPMENT_POST_OFFERS:
      return {
        ...state,
        postOffers: action.payload
      };
    case GET_SHIPPER_CARRIERS:
      return {
        ...state,
        carriers: action.payload
      };
    case FETCH_SHIPPER_FTL_CARRIERS:
      return {
        ...state,
        shipperFTLCarriers: action.payload
      };
    case GET_SHIPPER_DASHBOARDS:
      return {
        ...state,
        shipperDashboards: action.payload
      };
    case POPULATE_RECIPIENT_EMAILS:
      return {
        ...state,
        sendCustomerQuote: action.payload
      };
    case TRIGGER_REBOOK:
      return {
        ...state,
        triggerRebook: action.payload
      };

    case CREATE_SHIPMENT:
      return {
        ...state,
        selectedShipment: action.payload,
        selectedShipmentAuctionStatus: action.payload.metadata && action.payload.metadata.has_open_auction
      };
    case EDIT_SHIPMENT:
      return {
        ...state,
        selectedShipment: action.payload,
        selectedShipmentAuctionStatus: action.payload.metadata && action.payload.metadata.has_open_auction
      };
    case SELECT_SHIPMENT:
      return {
        ...state,
        selectedShipment: action.payload,
        selectedShipmentAuctionStatus: action.payload.metadata && action.payload.metadata.has_open_auction
      };
    case CREATE_RFQ:
      return {
        ...state,
        selectedRFQ: action.payload
      };
    case SELECT_RFQ:
      return {
        ...state,
        selectedRFQ: action.payload
      };
    case SELECT_BROKER_RFQ:
      return {
        ...state,
        selectedBrokerRFQ: action.payload
      };
    case CREATE_QUOTE:
      return {
        ...state,
        selectedQuote: action.payload
      };
    case SELECT_QUOTE:
      return {
        ...state,
        selectedQuote: action.payload
      };
    case INITIAL_QUOTE_LOADING:
      return {
        ...state,
        initialQuoteLoading: action.payload
      };
    case SELECT_CARRIER_RATE:
      return {
        ...state,
        selectedCarrierRate: action.payload
      };
    case CLEAR_SELECT_CARRIER_RATE:
      return {
        ...state,
        selectedCarrierRate: INITIAL_STATE.selectedCarrierRate
      };
    case EDIT_QUOTE:
      return {
        ...state,
        selectedQuote: action.payload
      };
    case AWARD_QUOTE:
      return {
        ...state,
        selectedShipment: action.payload,
        selectedShipmentAuctionStatus: action.payload.metadata && action.payload.metadata.has_open_auction
      };

    case GO_TO_STOPS:
      return {
        ...state,
        goToStops: action.payload
      };

    case GET_SHIPMENT_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };

    case POST_SHIPMENT_MESSAGES: {
      const currentMessages = state.messages;
      currentMessages.push(action.payload);
      return {
        ...state,
        messages: currentMessages
      };
    }

    case CLEAR_SHIPMENT_MESSAGES:
      return {
        ...state,
        messages: []
      };

    case SET_HISTORY_FILTER:
      return {
        ...state,
        historyFilter: action.payload
      };

    case GET_SHIPPER_CREDIT:
      return {
        ...state,
        shipperCredit: action.payload
      };

    case FETCH_ALLONDEMANDS:
      return {
        ...state,
        error: false,
        isLoading: false,
        lastGeo: _.zip(_.map(action.payload, 'last_latitude'), _.map(action.payload, 'last_longitude')),
        ondemands: action.payload
      };

    case FETCH_ONDEMAND:
      return {
        ...state,
        error: false,
        isLoading: false,
        one: action.payload
      };

    case FETCH_UNPAID_SHIPMENTS:
      return {
        ...state,
        unpaidShipments: action.payload,
        error: false,
        isLoading: false
      };

    case FETCH_SHIPMENT_OFFERS:
      return {
        ...state,
        shipmentOffers: action.payload
      };

    case DELETE_OFFERS:
      return {
        ...state,
        shipmentOffers: null
      };

    case FETCH_SHIPMENT_QUOTES:
      if (typeof action.payload === 'string') {
        action.payload = null;
      }
      return {
        ...state,
        quotes: action.payload
      };

    case DELETE_SHIPMENT_QUOTES:
      return {
        ...state,
        quotes: null
      };

    case FETCH_BOL:
      return {
        ...state,
        bol: action.payload
      };

    case FETCH_RATE_CON:
      return {
        ...state,
        rateCon: action.payload
      };

    case DISPATCH_SHIPMENT_QUOTE:
      return {
        ...state,
        one: action.payload
      };

    case UPDATE_BROKER_MARGIN: {
      const currentShip = state.one;
      currentShip.invoice_line_items = action.payload.invoice_line_items;
      currentShip.cost = action.payload.cost;
      return {
        ...state,
        one: currentShip
      };
    }

    case CLEAR_SELECTED_SHIPMENT:
      return {
        ...state,
        selectedShipment: INITIAL_STATE.selectedShipment,
        selectedShipmentAuctionStatus: INITIAL_STATE.selectedShipmentAuctionStatus,
        selectedRFQ: INITIAL_STATE.selectedRFQ,
        selectedQuote: INITIAL_STATE.selectedQuote
      };

    case CLEAR_SELECTED_QUOTE:
      return {
        ...state,
        selectedQuote: INITIAL_STATE.selectedQuote
      };

    case SELECT_SHIPMENT_FOR_FORM:
      return {
        ...state,
        selectedShipmentForForm: action.payload
      };

    case RESET_SHIPMENTS:
      return {
        ...state,
        shipmentCount: 0
      };

    case FETCH_SHIPMENTS:
      if (action.meta.key < state.shipmentCount) {
        return {
          ...state,
          error: false,
          isLoading: false
        };
      }
      return {
        ...state,
        all: action.payload,
        error: false,
        isLoading: false,
        shipmentCount: action.meta.key
      };

    case FETCH_SHIPMENTS_AND_PERSIST: {
      const currentShips = JSON.parse(JSON.stringify(state.all));
      currentShips.results = currentShips.results.concat(action.payload.results);
      return {
        ...state,
        all: currentShips,
        error: false,
        isLoading: false
      };
    }

    case GET_SHIPMENT_TIMELINE:
      return {
        ...state,
        timeline: action.payload
      };

    case DELETE_SHIPMENT_TIMELINE_EVENT: {
      const timeline = state.timeline;
      for (let i = 0; i < timeline.length; i++) {
        if (timeline[i].id === action.payload) {
          timeline.splice(i, 1);
        }
      }
      return {
        ...state,
        timeline: timeline
      };
    }

    case FETCH_ACCESSORIAL_CODES:
      // @hack - sorting by type-desc here because instances with type: "accessorial-status" are ugly
      return {
        ...state,
        accessorialCodes: _.orderBy(action.payload, ['type', 'description'], ['desc', 'asc']),
        shipmentAccessorialCodes: filterAccessorials(action.payload).shipment
      };
    case GET_NMFC_CODES:
      return {
        ...state,
        nmfcCodes: action.payload
      };

    case GET_APPOINTMENT_TYPES:
      return {
        ...state,
        appointmentTypes: action.payload
      };

    case GET_SERVICE_LEVELS:
      return {
        ...state,
        serviceLevels: action.payload
      };

    case GET_SHIPMENT_MODES:
      return {
        ...state,
        shipmentModes: action.payload
      };

    case GET_EQUIPMENT_TYPES:
      return {
        ...state,
        equipmentTypes: action.payload
      };

    case GET_PACKAGE_TYPES:
      return {
        ...state,
        packageTypes: action.payload
      };

    case GET_PIECE_TYPES:
      return {
        ...state,
        pieceTypes: action.payload
      };

    case FETCH_PACKAGE_TYPES_SUCCESS:
      return {
        ...state,
        fedexPackageTypes: action.payload
      };

    case FETCH_UPS_PACKAGE_TYPES_SUCCESS:
      return {
        ...state,
        upsPackageTypes: action.payload
      };

    case FETCH_USPS_PACKAGE_TYPES_SUCCESS:
      return {
        ...state,
        uspsPackageTypes: action.payload
      };

    case GET_HAZMAT_CODES:
      return {
        ...state,
        hazmatCodes: action.payload
      };

    case GET_CHARGE_CATEGORIES:
      return {
        ...state,
        chargeCategories: action.payload
      };

    case REQ_DATA:
      return {
        ...state,
        error: false,
        isLoading: true
      };

    case RESET_ERROR:
      return {
        ...state,
        details: null,
        error: false,
        isLoading: false
      };

    case SHIPMENTS_ERROR:
      return {
        ...state,
        details: action.payload,
        error: true,
        isLoading: false
      };

    case SHIPPER_ID:
      return {
        ...state,
        ...action.payload
      };

    case FETCH_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload
      };

    case FETCH_TAGS:
      return {
        ...state,
        tags: action.payload
      };

    case FETCH_TAG:
      return {
        ...state,
        tag: action.payload
      };

    case CLEAR_TAG:
      return {
        ...state,
        tag: {}
      };
    case GET_SHIPMENT_STATUSES:
      return {
        ...state,
        shipmentStatuses: action.payload
      };

    default:
      return state;
  }
};
