import {
  Company,
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery} from '@tanstack/react-query';
import {BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getBrokerShipperRelationship} from 'App/api/brokers/typed';

export function useCustomersTyped(
  company?: Company,
  fetchOptions?: Omit<ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsGetRequest, 'brokerageId'>
) {
  const brokerageId = company?.brokerage?.id || '';

  const getBrokerShipperRelationshipQuery = useQuery(
    [BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY, brokerageId, fetchOptions],
    async () => {
      const request = await getBrokerShipperRelationship({brokerageId, ...fetchOptions});
      return request;
    },
    {
      enabled: Boolean(brokerageId)
    }
  );

  const {data: shipperRelationshipData, status: shipperRelationshipStatus} = getBrokerShipperRelationshipQuery;

  const getCustomerOptionFromId = (id: string) =>
    shipperRelationshipData?.results?.find((customer) => customer.id === id || customer.company.id === id);

  return {
    shipperRelationshipData,
    shipperRelationshipStatus,
    getCustomerOptionFromId,
    getBrokerShipperRelationshipQuery
  };
}
