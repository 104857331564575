import {ReactNode} from 'react';
import {Button, FormikCheckbox, FormikTextInput, Title} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {UserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import {dashboardValidationSchema} from '../tableUtils';

export const SaveDashboardForm = ({
  label,
  dashboard,
  onSave,
  onCancel,
  actions
}: {
  label: string;
  dashboard?: UserDashboardConfiguration;
  onSave: (isDefault: boolean, name: string) => void;
  onCancel: () => void;
  actions?: ReactNode;
}) => {
  const initialValues = {
    name: dashboard?.name || '',
    isDefault: dashboard?.is_default || false
  };

  const handleSubmit = (values: typeof initialValues) => {
    onSave(values.isDefault, values.name);
  };

  return (
    <Formik
      validationSchema={dashboardValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      noValidate
    >
      {() => (
        <Form className="flex w-full flex-col gap-2 p-2">
          <Title variant="formTitle">{label}</Title>
          <Field name="name" label="Dashboard name" component={FormikTextInput} required />
          <Field name="isDefault" label="Set as default" component={FormikCheckbox} fixedHeight={false} />
          <div className={`flex items-center ${actions ? 'justify-between' : 'justify-end'} gap-2`}>
            {actions}
            <div className="flex gap-2">
              <Button size="sm" variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button size="sm" type="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
