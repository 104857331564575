import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  SaferWatchCarrierPolicy,
  ShipwellError,
  CarrierRelationshipsApiCarrierComplianceIntegrationsSaferwatchPolicyPutRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SAFERWATCH_COMPLIANCE_POLICY} from '../queryKeys';
import {setSaferwatchCarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseUpdateSaferwatchPolicyMutationOptions = Omit<
  UseMutationOptions<
    SaferWatchCarrierPolicy,
    AxiosError<ShipwellError>,
    CarrierRelationshipsApiCarrierComplianceIntegrationsSaferwatchPolicyPutRequest,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;

const useUpdateSaferwatchPolicyMutation = (options?: UseUpdateSaferwatchPolicyMutationOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (updatedPolicy: CarrierRelationshipsApiCarrierComplianceIntegrationsSaferwatchPolicyPutRequest) =>
      setSaferwatchCarrierCompliancePolicy(updatedPolicy),
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        void queryClient.invalidateQueries([SAFERWATCH_COMPLIANCE_POLICY]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {
    isSaferwatchLoading: mutation.isLoading,
    isSaferwatchSuccess: mutation.isSuccess,
    isSaferwatchError: mutation.isError,
    SaferwatchError: mutation.error || null,
    SaferwatchPolicy: mutation.data || null,
    updateSaferwatchPolicy: mutation.mutate,
    updateSaferwatchPolicyAsync: mutation.mutateAsync
  };
};

export default useUpdateSaferwatchPolicyMutation;
