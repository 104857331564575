import pluralize from 'pluralize';

export const defaultTimeOptions = [
  {label: '0 Minutes', value: 0},
  {label: '30 Minutes', value: 1800},
  {label: '1 Hour 0 Minutes', value: 3600},
  {label: '1 Hour 30 Minutes', value: 5400},
  {label: '2 Hours 0 Minutes', value: 7200},
  {label: '2 Hours 30 Minutes', value: 9000},
  {label: '3 Hours 0 Minutes', value: 10800},
  {label: '3 Hours 30 Minutes', value: 12600},
  {label: '4 Hours 0 Minutes', value: 14400},
  {label: '4 Hours 30 Minutes', value: 16200},
  {label: '5 Hours 0 Minutes', value: 18000},
  {label: '5 Hours 30 Minutes', value: 19800},
  {label: '6 Hours 0 Minutes', value: 21600},
  {label: '6 Hours 30 Minutes', value: 23400},
  {label: '7 Hours 0 Minutes', value: 25200},
  {label: '7 Hours 30 Minutes', value: 27000},
  {label: '8 Hours 0 Minutes', value: 28800},
  {label: '8 Hours 30 Minutes', value: 30600},
  {label: '9 Hours 0 Minutes', value: 32400},
  {label: '9 Hours 30 Minutes', value: 34200},
  {label: '10 Hours 0 Minutes', value: 36000},
  {label: '10 Hours 30 Minutes', value: 37800},
  {label: '11 Hours 0 Minutes', value: 39600},
  {label: '11 Hours 30 Minutes', value: 41400},
  {label: '12 Hours 0 Minutes', value: 43200},
  {label: '12 Hours 30 Minutes', value: 45000},
  {label: '13 Hours 0 Minutes', value: 46800},
  {label: '13 Hours 30 Minutes', value: 48600},
  {label: '14 Hours 0 Minutes', value: 50400},
  {label: '14 Hours 30 Minutes', value: 52200},
  {label: '15 Hours 0 Minutes', value: 54000},
  {label: '15 Hours 30 Minutes', value: 55800},
  {label: '16 Hours 0 Minutes', value: 57600},
  {label: '16 Hours 30 Minutes', value: 59400},
  {label: '17 Hours 0 Minutes', value: 61200},
  {label: '17 Hours 30 Minutes', value: 63000},
  {label: '18 Hours 0 Minutes', value: 64800},
  {label: '18 Hours 30 Minutes', value: 66600},
  {label: '19 Hours 0 Minutes', value: 68400},
  {label: '19 Hours 30 Minutes', value: 70200},
  {label: '20 Hours 0 Minutes', value: 72000},
  {label: '20 Hours 30 Minutes', value: 73800},
  {label: '21 Hours 0 Minutes', value: 75600},
  {label: '21 Hours 30 Minutes', value: 77400},
  {label: '22 Hours 0 Minutes', value: 79200},
  {label: '22 Hours 30 Minutes', value: 81000},
  {label: '23 Hours 0 Minutes', value: 82800},
  {label: '23 Hours 30 Minutes', value: 84600},
  {label: '24 Hours 0 Minutes', value: 86400},
  {label: '48 Hours 0 Minutes', value: 172800},
  {label: '96 Hours 0 Minutes', value: 345600}
];

const shortTimeOptions = [
  {label: '15 Seconds', value: 15},
  {label: '30 Seconds', value: 30},
  {label: '1 Minute', value: 60},
  {label: '1 Minute 30 Seconds', value: 90},
  {label: '2 Minutes', value: 120},
  {label: '2 Minutes 30 Seconds', value: 150},
  {label: '3 Minutes', value: 180}
];

export default function getTimeOptions() {
  if (process.env.USE_SHORT_TIME_OPTIONS === 'true') {
    const [firstDefaultTimeOption, ...remainingDefaultTimeOptions] = defaultTimeOptions;
    return [firstDefaultTimeOption, ...shortTimeOptions, ...remainingDefaultTimeOptions];
  }

  return defaultTimeOptions;
}

// takes an amount on seconds and returns a string in english
// ex: (180) => 3 Minutes
// ex: (7260) => 2 Hours 1 Minute
export const getReadableTime = (seconds: number | string) => {
  const secondsInt = typeof seconds === 'string' ? parseInt(seconds, 10) : seconds;
  // if less than one minute, return seconds
  if (secondsInt < 60) {
    return pluralize('Second', secondsInt, true);
  }
  // if less than 3 minutes, return minutes and seconds
  if (secondsInt < 180) {
    const countInMinutes = secondsInt / 60;
    const wholeMinutes = Math.floor(countInMinutes);
    const remainingSeconds = Math.round((countInMinutes - wholeMinutes) * 60);
    return `${pluralize('Minute', wholeMinutes, true)} ${
      remainingSeconds ? pluralize('Second', remainingSeconds, true) : ''
    }`;
  }
  const minuteCount = Math.round(secondsInt / 60);
  // if an hour or less, return minutes
  if (secondsInt <= 3600) {
    return pluralize('Minute', minuteCount, true);
  }
  // return hours and minutes
  const countInHours = minuteCount / 60;
  const wholeHours = Math.floor(countInHours);
  const remainingMinutes = Math.round((countInHours - wholeHours) * 60);
  return `${pluralize('Hour', wholeHours, true)} ${
    remainingMinutes ? pluralize('Minute', remainingMinutes, true) : ''
  }`;
};

// create an array of ReactSelect compatible options
export const createHoursList = (max: number, min?: number) => {
  const hours = Array.from({length: max + 1 - (min || 0)}, (_, i) => i + (min || 0));
  // value = hour * minute * sec
  return hours.map((hour) => ({label: pluralize('Hour', hour, true), value: hour * 60 * 60}));
};
