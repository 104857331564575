/**
 * Company Settings
 */
export const REQUEST_USER_COMPANY_SUCCESS = 'REQUEST_USER_COMPANY_SUCCESS';
export const REQUEST_USER_COMPANY_PENDING = 'REQUEST_USER_COMPANY_PENDING';
export const REQUEST_USER_COMPANY_ERROR = 'REQUEST_USER_COMPANY_ERROR';
export const UPDATE_USER_COMPANY_SUCCESS = 'UPDATE_USER_COMPANY_SUCCESS';

export const requestUserCompanyPending = () => {
  return {
    type: REQUEST_USER_COMPANY_PENDING
  };
};

export const requestUserCompanySuccess = (payload) => {
  return {
    type: REQUEST_USER_COMPANY_SUCCESS,
    payload: payload
  };
};

export const requestUserCompanyError = (payload) => {
  return {
    type: REQUEST_USER_COMPANY_ERROR,
    payload: payload
  };
};

export const updateUserCompanySuccess = (payload) => {
  return {
    type: UPDATE_USER_COMPANY_SUCCESS,
    payload: payload
  };
};

/**
 * Company Preferences
 */
export const REQUEST_USER_COMPANY_PREFERENCES_PENDING = 'REQUEST_USER_COMPANY_PREFERENCES_PENDING';
export const REQUEST_USER_COMPANY_PREFERENCES_SUCCESS = 'REQUEST_USER_COMPANY_PREFERENCES_SUCCESS';
export const REQUEST_USER_COMPANY_PREFERENCES_ERROR = 'REQUEST_USER_COMPANY_PREFERENCES_ERROR';

export const requestUserCompanyPreferencesPending = () => {
  return {
    type: REQUEST_USER_COMPANY_PREFERENCES_PENDING
  };
};

export const requestUserCompanyPreferencesSuccess = (payload) => {
  return {
    type: REQUEST_USER_COMPANY_PREFERENCES_SUCCESS,
    payload: payload
  };
};

export const requestUserCompanyPreferencesError = (payload) => {
  return {
    type: REQUEST_USER_COMPANY_PREFERENCES_ERROR,
    payload: payload
  };
};

/**
 * Get company permission details
 */
export const REQUEST_PERMISSIONS_SUCCESS = 'REQUEST_PERMISSIONS_SUCCESS';

export const requestPermissionsSuccess = (response) => ({
  type: REQUEST_PERMISSIONS_SUCCESS,
  payload: response.body
});

/**
 * Get company indentifying codes
 */
export const REQUEST_IDENTIFYING_CODES_SUCCESS = 'REQUEST_IDENTIFYING_CODES_SUCCESS';

export const requestIdentifyingCodesSuccess = (response) => ({
  type: REQUEST_IDENTIFYING_CODES_SUCCESS,
  payload: response.body
});
