import {useMutation, MutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ColumnNames, ShipmentIds} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getFilteredColumnNames} from './utils';
import {getShipmentsDownloadCsv} from 'App/api/referenceData/typed';
import prepareAndDownloadFile from 'App/utils/fileUtilsTyped';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

export type GetShipmentsDownloadCsvResponse = Awaited<ReturnType<typeof getShipmentsDownloadCsv>>;
export type GetShipmentsDownloadCsvError = AxiosError<{error_message: string}>;
export type GetShipmentsDownloadCsvMutationParams = {
  shipmentIds: ShipmentIds;
  columnNames: ColumnNames;
  downloadFileName?: string;
};

export type GetShipmentsDownloadCsvMutationOptions = Omit<
  MutationOptions<GetShipmentsDownloadCsvResponse, GetShipmentsDownloadCsvError, GetShipmentsDownloadCsvMutationParams>,
  'mutationFn' | 'onSuccess' | 'onError'
> &
  Partial<Pick<WithStatusToastProps, 'setError'>> & {handleSuccess?: () => void};

const defaultFileName = 'shipments.csv';

export const useGetShipmentsDownloadCsvMutation = ({
  setError,
  handleSuccess,
  ...mutationOptions
}: GetShipmentsDownloadCsvMutationOptions = {}) => {
  return useMutation({
    mutationFn: ({shipmentIds, columnNames}: GetShipmentsDownloadCsvMutationParams) =>
      getShipmentsDownloadCsv({
        shipmentIds,
        columnNames: getFilteredColumnNames(columnNames)
      }),
    onSuccess: (data, {downloadFileName = defaultFileName}) => {
      prepareAndDownloadFile(data, downloadFileName);
      handleSuccess?.();
    },
    onError: ({response}) => {
      setError?.('Error downloading CSV', response?.data.error_message || 'An unknown error occurred.');
      console.error(response?.data);
    },
    ...mutationOptions
  });
};
