import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Quoting RFQ - API
 * @async
 * @public
 * @method createRFQPromise
 * @param {import('@shipwell/backend-core-singlerequestparam-sdk').RFQ} rfqObj post object.
 * @param {Object|undefined} opts
 * @returns {Promise<{body: any}>} returns created Request for Quote.
 */
export async function createRFQPromise(rfqObj, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRfqsPost(rfqObj, opts, apiCallback(resolve, reject));
  });
}

/**
 * Gets a Request for Quote (RFQ) by it's identifier.
 * @returns {Promise<{body: import('@shipwell/backend-core-sdk').RFQ}>}
 */
export async function getRFQDetailsPromise(rfqId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRfqsRfqIdGet(rfqId, opts, apiCallback(resolve, reject));
  });
}

/**
 * Quote - API
 */
export async function createQuotePromise(rfqId, quoteObj, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRfqsRfqIdQuotesPost(rfqId, quoteObj, opts, apiCallback(resolve, reject));
  });
}

export async function getQuoteDetailsPromise(rfqId, quoteId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRfqsRfqIdQuotesQuoteIdGet(rfqId, quoteId, opts, apiCallback(resolve, reject));
  });
}

export async function editQuotePromise(rfqId, quoteId, quoteObj, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRfqsRfqIdQuotesQuoteIdPut(rfqId, quoteId, quoteObj, opts, apiCallback(resolve, reject));
  });
}

/**
 * Loadboard
 */
export async function getLoadboardShipments(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingLoadboardGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * Quoting - Spot Negotiations - API
 */
export async function createPrivateMarketplaceSpotNegotiationsPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPost(
      shipmentId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function getSpotNegotiationMessagesPromise(spotId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesGet(
      spotId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getSpotNegotiationEmailPreviewPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPreviewGet(
      shipmentId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getSpotNegotiationQuotesPromise(spotId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesGet(spotId, apiCallback(resolve, reject));
  });
}

export async function getSpotNegotiationDetailsPromise(spotId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdGet(spotId, apiCallback(resolve, reject));
  });
}

export async function getCustomerNegotiationPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsCustomerNegotiationPost(
      shipmentId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function sendSpotNegotiationMessagePromise(spotId, messageBody) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesPost(
      spotId,
      messageBody,
      apiCallback(resolve, reject)
    );
  });
}

export async function createSpotNegotiationQuotePromise(spotId, quote) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesPost(
      spotId,
      quote,
      apiCallback(resolve, reject)
    );
  });
}

export async function editSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdPut(
      spotNegotiationId,
      spotNegotiationQuoteId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDelete(
      spotNegotiationId,
      spotNegotiationQuoteId,
      apiCallback(resolve, reject)
    );
  });
}

export async function awardDeclineQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdAwardDeclinePut(
      spotNegotiationId,
      spotNegotiationQuoteId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function openCloseSpotNegotiationPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsOpenClosePost(body, apiCallback(resolve, reject));
  });
}

export async function getAllSpotNegotiationsByShipmentPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsGet(
      shipmentId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getBiddingShipmentsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * FedEx
 */
export async function getFedExPackageTypes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingFedexPackageTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * UPS
 */
export async function getUPSPackageTypes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingUpsPackageTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * USPS
 */
export async function getUSPSPackageTypes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingUspsPackageTypesGet(apiCallback(resolve, reject));
  });
}

/*
 * Carrier Bids
 */

export async function createCarrierBid(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingCarrierBidsPost(body, apiCallback(resolve, reject));
  });
}

export async function updateCarrierBid(bidId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingCarrierBidsCarrierBidIdPut(bidId, body, apiCallback(resolve, reject));
  });
}

export async function deleteCarrierBid(bidId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingCarrierBidsCarrierBidIdDelete(bidId, apiCallback(resolve, reject));
  });
}

export async function getCarrierBids(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingCarrierBidsGet(opts, apiCallback(resolve, reject));
  });
}

export async function getShipmentsWithCarrierBids(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingCarrierBidsShipmentSummaryGet(opts, apiCallback(resolve, reject));
  });
}

export async function getHistoricalFuelSurchargeRates(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesEiaFuelSurchargeRatesGet(opts, apiCallback(resolve, reject));
  });
}
