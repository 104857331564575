import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import {unpackErrors} from 'App/utils/globals';

/**
 * Parses out field errors and a generic error description from multiple responses. If there are no
 * errors, errorDescription will be empty. If there are error responses (status >= 400) but no more
 * details, the default error description will be used.
 * The returned fieldErrors will always have the same length as the given responses. This helps to
 * match up errors with arrays that were rendered in a FieldArray form container. Checking the
 * returned errorDescription can help determine if the responses actually contained any errors.
 *
 * @param {Array.<Object.<String,Number|String>>} responses
 * @param {String} defaultErrorDescription
 *
 * @return {Array} A tuple of [fieldErrors, errorDescription]
 */
export default function parseMultipleRequestErrors(responses, defaultErrorDescription = 'Error during request') {
  const fieldErrors = responses
    .map((error) => get(error, 'field_errors', [{}]))
    .flat()
    .map((fieldError) => (isEmpty(fieldError) ? fieldError : unpackErrors([fieldError], {})));
  const errors = responses.filter((response) => response.status >= 400);
  const errorDescription = uniq(errors.map((error) => get(error, 'error_description', defaultErrorDescription))).join(
    '\n'
  );
  return [fieldErrors, errorDescription];
}
