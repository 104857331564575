import {Component} from 'react';
import './styles.scss';
import startCase from 'lodash/startCase';
import classNames from 'classnames';

class TableReadback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilterName:
        (this.props.myViews &&
          this.props.myViews.filter((e) => e.id === this.props.selectedFilter).length &&
          this.props.myViews.filter((e) => e.id === this.props.selectedFilter)[0].name) ||
        null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedFilter !== this.props.selectedFilter) {
      if (this.props.selectedFilter) {
        const filterToApply =
          this.props.myViews && this.props.myViews.filter((e) => e.id === this.props.selectedFilter)[0];
        this.setState({selectedFilterName: filterToApply.name});
      } else {
        this.setState({selectedFilterName: ''});
      }
    }
  }

  render() {
    const {selectedFilterName} = this.state;
    const filters = this.props.parseFiltersForReadback(this.props.currentFilters);
    return (
      <div
        ref={this.props.readbackRef}
        className={classNames('tableReadback gap-y-1 gap-x-2', {empty: !filters?.length})}
      >
        {selectedFilterName && <span className="tableReadback__filterName">{selectedFilterName}:</span>}
        {filters && filters.length ? (
          <>
            {filters.map((filter, i) => {
              //startCase removes all special characters so in the case where we need to
              //show (Or) or (Exclude) we can't use it
              const filterParam = /^[A-Za-z0-9]/.test(Object.keys(filter)[0])
                ? startCase(Object.keys(filter)[0])
                : Object.keys(filter)[0];
              const filterValue = Object.values(filter)[0];
              return (
                <span className="tableReadback__filter" key={i}>
                  {`${filterParam}: ${filterValue}`}
                </span>
              );
            })}
            <span onClick={this.props.clearAll} className="tableReadback__clear">
              Clear All{' '}
            </span>
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TableReadback;
