import {Link, withRouter, WithRouterProps} from 'react-router';
import {Modal, Button, SvgIcon} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {useUserMe} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import useDisconnectAmazonFreightMutation from 'App/containers/integrations/details/components/amazonFreightDetails/hooks/mutations';

interface AmazonPropsTypes extends WithStatusToastProps, WithRouterProps {}

const AmazonFreightDetails = (props: AmazonPropsTypes) => {
  const userMeQuery = useUserMe();
  const [showDisconnectModal, toggleDisconnectModal] = useToggle();

  const {mutate: disconnectAmazonAccount} = useDisconnectAmazonFreightMutation({
    onSuccess() {
      props.router.goBack();
    },
    onError(error) {
      props.setError('Error!', error.message);
    }
  });

  const handleDisconnect = () => {
    const companyId = userMeQuery?.data?.company?.id;
    if (companyId) {
      disconnectAmazonAccount(companyId);
    }
  };

  const DisconnectModal = () => (
    <Modal
      title="Disconnect Integration"
      className="w-96 align-middle"
      show={showDisconnectModal}
      onClose={() => toggleDisconnectModal()}
      footerComponent={null}
      variant="warning"
    >
      <div className="flex flex-row items-center px-2 py-1">
        <span>You will no longer see the rates from this carrier</span>
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button variant="secondary" type="button" onClick={() => toggleDisconnectModal()}>
          Cancel
        </Button>
        <Button type="button" variant="primary" color="warning" onClick={() => handleDisconnect()}>
          Yes, Disconnect
        </Button>
      </div>
    </Modal>
  );

  const DisconnectButton = () => (
    <div className="flex justify-end pb-6">
      <Button title="disconnect" variant="secondary" onClick={() => toggleDisconnectModal()}>
        Disconnect
      </Button>
    </div>
  );

  return (
    <div>
      <DisconnectButton />
      <DisconnectModal />
      <div className="flex flex-col divide-solid rounded border border-sw-border bg-sw-background-component p-6">
        <div className="mb-4 flex w-full items-center">
          <div className="flex w-full">
            <div className="flex">
              <span>
                For carrier information, please visit the{' '}
                <Link to="/carriers?page=1&q=amazon">Amazon Freight carrier page</Link>.
                <br />
                <br />
                <span className="w-full">
                  <SvgIcon name="CheckCircleFilled" color="$sw-success" className="float-left" />
                  <span className="float-left mt-1 text-sw-success">You are connected with Amazon Freight.</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose<AmazonPropsTypes, unknown>(withRouter, WithStatusToasts)(AmazonFreightDetails);
