import {map_styles} from './map_styles';

export const options = {
  backgroundColor: '#ecf0f5',
  //official center point of the US
  center: {
    lat: 38.0,
    lng: -97.0
  },
  //default zoom level is the entire US
  zoom: 3,
  maxZoom: 15,
  minZoom: 3,
  //disable the zoom-controls - true
  disableDefaultUI: false,
  //prevent the map from scrolling
  scrollwheel: false,
  draggable: true,
  /*mapTypeId: google.maps.MapTypeId.ROADMAP,
  // maxZoom: 11,
  // minZoom: 9,
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
    style: google.maps.ZoomControlStyle.SMALL,
  },
  panControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },*/
  clusterer: {
    options: {
      imagePath: '/images/img/cluster_image/m'
    }
  },
  infoWindow: {
    maxWidth: 300
  },
  styles: map_styles
};
