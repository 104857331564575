import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {Contract, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CONTRACTS_QUERY_KEY} from '../queryKeys';
import {getContract} from 'App/api/contracts/typed';

export const useGetContract = ({
  contractId,
  options
}: {
  contractId?: string;
  options?: Omit<
    UseQueryOptions<Contract, AxiosError<ShipwellError>, Contract, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const contractQuery = useQuery(
    [CONTRACTS_QUERY_KEY, contractId || ''],
    () => {
      if (!contractId) throw 'contractId is required.';
      return getContract(contractId);
    },
    {
      enabled: Boolean(contractId),
      ...options
    }
  );
  const {data: contractData, status: contractStatus} = contractQuery;
  return {
    contractData,
    contractStatus,
    contractQuery,
    isLoading:
      contractId &&
      (contractQuery.isLoading ||
        contractQuery.isFetching ||
        contractQuery.isInitialLoading ||
        contractQuery.isRefetching)
  };
};
