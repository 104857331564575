// import moment from 'moment';
import {
  GET_INBOX,
  DELETEALL_INBOX,
  DELETE_INBOX_MESSAGE,
  UPDATE_INBOX_MESSAGE,
  READALL_INBOX,
  UNREADALL_INBOX
} from 'App/actions/types';

const INITIAL_STATE = {
  inbox: [],
  isInboxLoading: true
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INBOX:
      return {
        ...state,
        inbox: action.payload.results,
        isInboxLoading: false
      };

    case DELETEALL_INBOX:
      return {
        ...state,
        inbox: action.payload.results
      };

    case DELETE_INBOX_MESSAGE:
      return {
        ...state,
        inbox: action.payload.results
      };

    case UPDATE_INBOX_MESSAGE:
      return {
        ...state,
        inbox: state.inbox.map((item) => (item.id === action.payload.id ? action.payload : item))
      };

    case READALL_INBOX:
      return {
        ...state,
        inbox: action.payload.results
      };

    case UNREADALL_INBOX:
      return {
        ...state,
        inbox: action.payload.results
      };

    default:
      return state;
  }
}
