import {Appointment} from '@shipwell/tempus-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ShipwellApiErrorResponse} from '@shipwell/settlements-sdk';
import {AxiosError} from 'axios';
import {CreateAppointmentProperties, transformCreateAppointment} from './utils';
import {createAppointment} from 'App/api/appointments';

export type UseCreateAppointmentMutationVariables = {
  facilityId: string;
  dockId?: string | null;
  appointment: CreateAppointmentProperties;
};
/**
 * Provides type safety
 */
export type UseCreateAppointmentMutationOptions = Omit<
  UseMutationOptions<
    Appointment,
    AxiosError<ShipwellApiErrorResponse, unknown>,
    UseCreateAppointmentMutationVariables,
    unknown
  >,
  'mutationFn'
>;

/**
 * Creates or update an appointment for a shipment stop. If an appointment
 * is found at a facility or dock belonging to the shipment stop then it is
 * flagged for reschedule rather than scheduled.
 */
const useCreateAppointmentMutation = (options?: UseCreateAppointmentMutationOptions) => {
  const mutation = useMutation(({facilityId, dockId, appointment}: UseCreateAppointmentMutationVariables) => {
    return createAppointment(transformCreateAppointment(facilityId, dockId, appointment));
  }, options);

  return {
    isCreatingAppointment: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    appointment: mutation.data || null,
    createAppointmentErrors: mutation.error?.response?.data?.errors,
    createAppointment: mutation.mutate,
    createAppointmentAsync: mutation.mutateAsync
  };
};

export {useCreateAppointmentMutation};
