import {SvgIcon} from '@shipwell/shipwell-ui';
import {SortDirection} from '@tanstack/react-table';

export const TableHeaderSortIcon = ({isSorted}: {isSorted: false | SortDirection}) => {
  return (
    <div className={`rounded-full ${isSorted ? 'bg-sw-active-light text-sw-active' : ''}`}>
      <SvgIcon name={isSorted === 'asc' ? 'CarrotUp' : 'CarrotDown'} />
    </div>
  );
};
