import {useEffect, useState} from 'react';
import {Typeahead, TypeaheadProps} from 'react-bootstrap-typeahead';
import {TokenDisplay} from 'App/components/TableFiltersWithSavedViews/FilterComponents/TokenDisplay';

interface ReactTypeaheadOption {
  label: string;
}
export const CustomSelection = ({
  updateActiveFilters,
  fieldName,
  defaultValues
}: {
  updateActiveFilters: (selected: {[x: string]: string[]}) => void;
  fieldName: string;
  defaultValues: string[] | null;
}) => {
  const [selected, setSelected] = useState<TypeaheadProps<ReactTypeaheadOption>['options']>([]);
  const handleChange = (selected: ReactTypeaheadOption[]) => {
    setSelected(selected);
    const selectionLabel = selected.map((selection) => selection.label);
    updateActiveFilters({[fieldName]: selectionLabel});
  };
  useEffect(() => {
    if (!defaultValues) {
      return;
    }
    const mappedDefaultValues = defaultValues.map((value) => {
      return {label: value};
    });
    setSelected(mappedDefaultValues);
  }, [defaultValues]);
  return (
    //sass styling has lots of selectors targeting classes
    //inside of the react bootstrap typeahead component
    <div className="tableFilters__typeahead">
      <Typeahead
        allowNew
        //since we pass default values to the typeahead, we use the selected prop
        //to imperatively set the value of the typeahead.
        selected={selected}
        aria-label={`${fieldName}CustomSelection`}
        id={`${fieldName}TypeaheadDashboard`}
        multiple
        autoFocus
        newSelectionPrefix="Filter by: "
        options={[]}
        placeholder="Enter Search Term"
        onChange={handleChange}
        //format the selected search term(s)
        renderToken={(option, props, index) => <TokenDisplay key={index} option={option} onRemove={props.onRemove} />}
      />
    </div>
  );
};
