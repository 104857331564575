import {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Grid, Row, Button} from 'react-bootstrap';

export default class Error404Page2 extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    return (
      <div className="login-page">
        <Grid>
          <Row
            style={{
              padding: '100px 0',
              margin: '0 auto'
            }}
          >
            <div className="text-center">
              <h1
                className="headline"
                style={{
                  fontSize: '10.0rem'
                }}
              >
                404
              </h1>
              <div className="error-content text-center">
                <h3>
                  <i className="icon icon-Delayed pad-right" />
                  Oops! Page not found.
                </h3>
                <p>We could not find the page you were looking for.</p>
                <Link to="/">
                  <Button bsStyle="default">Return to Login Page</Button>
                </Link>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}
