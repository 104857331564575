import {Component, Fragment} from 'react';
import {Button, FormControl} from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {connect} from 'react-redux';
import {numberWithCommas, truncateNumber, pickupIcon, deliveryIcon} from 'App/utils/globals';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {formatCurrency} from 'App/utils/internationalConstants';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

@connect(
  (state) => ({
    equipmentTypes: state.shipments.equipmentTypes,
    shipmentModes: state.shipments.shipmentModes,
    unitPreferences: state.userCompany.unitPreferences
  }),
  {}
)
class BookNowConfirmation extends Component {
  state = {
    open: false,
    requiresModeSelection:
      !this.props.shipment.modes || (Array.isArray(this.props.shipment.modes) && this.props.shipment.modes.length > 1),
    requiresEquipmentSelection:
      !this.props.shipment.equipment_types ||
      (Array.isArray(this.props.shipment.equipment_types) && this.props.shipment.equipment_types.length > 1),
    modeValue:
      this.props.shipment.modes && Array.isArray(this.props.shipment.modes) && this.props.shipment.modes.length === 1
        ? this.props.shipment.modes[0].id
        : '',
    equipmentValue:
      this.props.shipment.equipment_types &&
      Array.isArray(this.props.shipment.equipment_types) &&
      this.props.shipment.equipment_types.length === 1
        ? this.props.shipment.equipment_types[0].id
        : ''
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleContinue = () => {
    this.setState({requiresModeSelection: false, requiresEquipmentSelection: false});
  };

  render() {
    const {accept, cancel, open, shipment, submittingBookNow, unitPreferences} = this.props;
    const {requiresModeSelection, requiresEquipmentSelection} = this.state;
    let pickupLocationString = '';
    let pickupTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length && shipment.stops[0]) {
      pickupTimeString += shipment.stops[0].display_planned_window;
      if (shipment.stops[0].location && shipment.stops[0].location.address) {
        pickupLocationString +=
          shipment.stops[0].location.address.city + ', ' + shipment.stops[0].location.address.state_province;
      }
    }

    let deliveryLocationString = '';
    let deliveryTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length > 1) {
      deliveryTimeString = shipment.stops[shipment.stops.length - 1].display_planned_window;
      if (
        shipment.stops[shipment.stops.length - 1].location &&
        shipment.stops[shipment.stops.length - 1].location.address
      ) {
        deliveryLocationString =
          shipment.stops[shipment.stops.length - 1].location.address.city +
          ', ' +
          shipment.stops[shipment.stops.length - 1].location.address.state_province;
      }
    }

    const totals = calculateShipmentTotals({
      line_items: shipment.line_items,
      unitPreferences,
      totalWeight: shipment?.total_weight_override
    });
    const totalWeight = numberWithCommas(truncateNumber(shipment?.total_weight_override?.value ?? totals?.weight, 0));

    let {shipmentModes, equipmentTypes} = this.props;
    //limit mode/equipment based on shipment details if provided
    if (shipment.modes && shipment.modes.length > 0) {
      shipmentModes = shipment.modes;
    }

    if (shipment.equipment_types && shipment.equipment_types.length > 0) {
      equipmentTypes = shipment.equipment_types;
    }

    const selectedModeValue =
      shipmentModes && shipmentModes.filter((e) => e.id === parseInt(this.state.modeValue)).length > 0
        ? shipmentModes.filter((e) => e.id === parseInt(this.state.modeValue))[0].code
        : null;
    const selectedEquipmentValue =
      equipmentTypes && equipmentTypes.filter((e) => e.id === parseInt(this.state.equipmentValue)).length > 0
        ? equipmentTypes.filter((e) => e.id === parseInt(this.state.equipmentValue))[0].name
        : null;
    return (
      <Dialog
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {requiresModeSelection || requiresEquipmentSelection ? (
          <DialogTitle className="acceptNow__title">
            <span>{`This load is available for ${
              requiresModeSelection
                ? requiresEquipmentSelection
                  ? 'multiple modes and equipment types'
                  : 'multiple modes'
                : 'multiple equipment types'
            } at `}</span>
            <span className="text-success">
              {formatCurrency(shipment.buy_it_now_amount, shipment.preferred_currency)}
            </span>
            <span>.</span>
          </DialogTitle>
        ) : (
          <DialogTitle className="acceptNow__title">
            <span>{`Are you sure you want to book this load for `}</span>
            <span className="text-success">
              {formatCurrency(shipment.buy_it_now_amount, shipment.preferred_currency)}
            </span>
            <span>?</span>
          </DialogTitle>
        )}
        <DialogContent>
          {(requiresModeSelection || requiresEquipmentSelection) && (
            <Fragment>
              <p>{`Please select the ${
                requiresModeSelection ? (requiresEquipmentSelection ? 'mode and equipment ' : 'mode ') : 'equipment '
              } you will be using before booking.`}</p>
              <div className="acceptNow__selections">
                {requiresModeSelection && (
                  <FormControl
                    value={this.state.modeValue}
                    componentClass="select"
                    onChange={(e) => this.setState({modeValue: e.target.value})}
                  >
                    <option value="" disabled>
                      Select Mode
                    </option>
                    {shipmentModes
                      .filter((mode) => mode.code !== 'PARCEL') // always hide parcel
                      .map((val, i) => {
                        return (
                          <option key={i} value={val.id}>
                            {val.code}
                          </option>
                        );
                      })}
                  </FormControl>
                )}
                {requiresEquipmentSelection && (
                  <FormControl
                    value={this.state.equipmentValue}
                    componentClass="select"
                    onChange={(e) => this.setState({equipmentValue: e.target.value})}
                  >
                    <option value="" disabled>
                      Select Equipment
                    </option>
                    {equipmentTypes.map((val, i) => {
                      return (
                        <option key={i} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </FormControl>
                )}
              </div>
            </Fragment>
          )}
          <div className="acceptNow__content">
            <div className="acceptNow__content-stops">
              <span className="acceptNow__content-stop">
                <span className="acceptNow__content-icon">{pickupIcon}</span>
                <span className="acceptNow__content-text">
                  <span className="acceptNow__content-address">{pickupLocationString}</span>
                  <span className="acceptNow__content-time">{pickupTimeString}</span>
                </span>
              </span>
              <span className="acceptNow__content-stop">
                <span className="acceptNow__content-icon">{deliveryIcon}</span>
                <span className="acceptNow__content-text">
                  <span className="acceptNow__content-address">{deliveryLocationString}</span>
                  <span className="acceptNow__content-time">{deliveryTimeString}</span>
                </span>
              </span>
            </div>
            <div className="acceptNow__content-details">
              <div>
                <span>
                  <b>Equipment: </b>
                  {shipment.modes && shipment.modes.length === 1
                    ? shipment.modes[0].code
                    : selectedModeValue
                    ? selectedModeValue
                    : ''}{' '}
                  {shipment.equipment_types && shipment.equipment_types.length === 1
                    ? shipment.equipment_types[0].name
                    : selectedEquipmentValue
                    ? selectedEquipmentValue
                    : ''}
                </span>
              </div>
              <div className="acceptNow__content-columns">
                <span className="acceptNow__content-column">
                  <span>
                    <b>Weight: </b>
                    {totalWeight} lbs
                  </span>
                </span>
                <span className="acceptNow__content-column">
                  <span>
                    <b>Distance: </b>
                    {shipment.total_miles && numberWithCommas(shipment.total_miles)} mi
                  </span>
                </span>
              </div>
            </div>
          </div>
          <p className="acceptNow__instructions">
            By accepting this load, you agree to perform the transportation services at the above confirmed rate. You
            will receive an email with your rate confirmation.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} bsStyle="default">
            Cancel
          </Button>
          {requiresModeSelection || requiresEquipmentSelection ? (
            <Button
              onClick={this.handleContinue}
              disabled={
                (requiresModeSelection && !this.state.modeValue) ||
                (requiresEquipmentSelection && !this.state.equipmentValue)
              }
              bsStyle="success"
            >
              Continue
            </Button>
          ) : (
            <Button
              onClick={() =>
                accept({mode: parseInt(this.state.modeValue), equipment_type: parseInt(this.state.equipmentValue)})
              }
              bsStyle="success"
              disabled={submittingBookNow}
            >
              {submittingBookNow ? (
                <span>
                  <i className="icon icon-Restart rotate" /> Saving...
                </span>
              ) : (
                'Book Now'
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default BookNowConfirmation;
