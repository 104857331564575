import {Button} from '@shipwell/shipwell-ui';
import {useDeviceService} from './useDeviceService/useDeviceService';

export interface DeviceCardProps {
  shipmentId: string;
  device: string;
  id: string;
  isConnected: boolean;
  battery?: number;
  onDisconnected: () => void;
}

export function DeviceCard(props: DeviceCardProps) {
  const service = useDeviceService({
    shipmentId: props.shipmentId,
    deviceId: props.id
  });

  async function handleDisconnect() {
    service.disconnect(undefined, {
      onSuccess: props.onDisconnected
    });
  }

  return (
    <>
      <div className="bg-sw-background rounded-lg py-2 px-4">
        <div className="flex items-center justify-between">
          <div className="grid grid-cols-4 gap-3 flex-grow">
            <div className="col-span-1">
              <div className="text-sw-disabled-text text-xxs">DEVICE</div>
              <div className="text-gray-900">{props.device}</div>
            </div>

            <div className="col-span-1">
              <div className="text-sw-disabled-text text-xxs">ID</div>
              <div className="text-gray-900">{props.id}</div>
            </div>

            <div className="col-span-1">
              <div className="text-sw-disabled-text text-xxs">STATUS</div>
              <div className={`font-bold ${props.isConnected ? 'text-sw-success' : 'text-sw-error'}`}>
                {props.isConnected ? 'Connected' : 'Disconnected'}
              </div>
            </div>

            <div className="col-span-1">
              <div className="text-sw-disabled-text text-xxs">BATTERY %</div>
              <div>{props.battery || '−'}</div>
            </div>
          </div>

          <Button
            variant="secondary"
            color="warning"
            size="sm"
            isLoading={service.isDisconnecting}
            disabled={service.isDisconnecting}
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </>
  );
}
