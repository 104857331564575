import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {CardTitle} from './cartTitle/cardTitle';
import {useDevicesService} from './useDevicesService/useDevicesService';
import ShipwellLoader from 'App/common/shipwellLoader';

export interface DevicesListCardProps {
  accountId: number;
}

export function DevicesListCard(props: DevicesListCardProps) {
  const service = useDevicesService({
    accountId: props.accountId
  });

  return (
    <Card isCollapsible draggableProvided={null} title={<CardTitle devicesCount={service.data?.length ?? 0} />}>
      <CollapsibleCardContent>
        {service.isLoading && <ShipwellLoader loading />}

        {!service.isLoading && (
          <table className="w-full">
            <thead>
              <tr>
                <td className="border-b-1 border-b-sw-border py-3 text-xxs">ID</td>
                <td className="border-b-1 border-b-sw-border py-3 text-xxs">NAME</td>
              </tr>
            </thead>
            <tbody>
              {service.data?.map((item) => (
                <tr key={item.device_id}>
                  <td className={'py-3'}>{item.device_id}</td>
                  <td className={'py-3'}>{item.device_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </CollapsibleCardContent>
    </Card>
  );
}
