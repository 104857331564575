import {useQuery} from '@tanstack/react-query';
import {UsersApiCompaniesCompanyIdUsersGetRequest} from '@shipwell/backend-core-singlerequestparam-sdk';
import isEmpty from 'lodash/isEmpty';
import {PaginatedUser} from '@shipwell/backend-core-sdk';

import {getCompanyUsers} from 'App/api/user/typed';
import {COMPANY_USERS} from 'App/data-hooks/queryKeys';

/* useCompanyUser
 * returns company users by given companyId
 */
const useCompanyUser = (companyId: string, params: Omit<UsersApiCompaniesCompanyIdUsersGetRequest, 'companyId'>) => {
  const response = useQuery<PaginatedUser>(
    [COMPANY_USERS, companyId, params],
    async () => {
      const response = await getCompanyUsers(companyId, params);
      return response.data;
    },
    {
      enabled: !isEmpty(companyId),
      onError: (error) => console.error(error)
    }
  );

  return response;
};

export default useCompanyUser;
