import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Formik, Field} from 'formik';
import {Modal, Card, DeprecatedButton, Select, FormikSelect, Pill} from '@shipwell/shipwell-ui';
import {getCompanyDetailsPromise} from 'App/api/company';
import SchedulePickups from 'App/components/parcelPickups';
import {smartPostHubIds} from 'App/formComponents/forms/fedexRegistration/utils/constants';
import {getFedExRegistrationAccounts, updateFedExRegistrationAccount} from 'App/containers/fedex/actions/async';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {unpackErrors, cleanPayload} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

/**
 * FedEx Account Details
 * @param {*} props
 */
const FedExAccountDetails = (props) => {
  const {dispatch, isEditable} = props;
  // Data states
  const [isFetchingFedexAccounts, setIsFetchingFedexAccounts] = useState(true);
  const [fedexAccounts, setFedexAccounts] = useState([]);
  const [fedexAccount, setFedexAccount] = useState(null);
  const [isFetchingCompany, setIsFetchingCompany] = useState(true);
  const [company, setCompany] = useState(null);
  // UI states
  const [showSmartPostModal, setShowSmartPostModal] = useState(false);
  const [smartPostHubDisplay, setSmartPostHubDisplay] = useState(null);

  /**
   * Request FedEx accounts
   */
  const fetchFedExRegistrationAccounts = async () => {
    try {
      const response = await dispatch(getFedExRegistrationAccounts());

      if (response && response.body && response.body.results.length) {
        setFedexAccounts(response.body.results);
        setFedexAccount(response.body.results[0]);
      }
    } catch (error) {
      console.error('ERROR in fetchFedExRegistrationAccounts()', error);
    }
    setIsFetchingFedexAccounts(false);
  };

  /**
   * Request company associated with FedEx account
   * @param {Number} companyId
   */
  const getCompanyDetails = async (companyId) => {
    setIsFetchingCompany(true);
    try {
      const response = await getCompanyDetailsPromise(companyId);

      if (response && response.body) {
        setCompany(response.body);
      }
    } catch (error) {
      console.error('ERROR in getCompanyDetails()', error);
    }
    setIsFetchingCompany(false);
  };

  /**
   * Save SmartPost
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSaveSmartPost = async (values, {setSubmitting, setErrors}) => {
    try {
      const response = await dispatch(updateFedExRegistrationAccount(fedexAccount.id, cleanPayload(values)));

      if (response && response.body) {
        setFedexAccount(response.body);
        setShowSmartPostModal(false);
      }
    } catch (error) {
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }
    setSubmitting(false);
  };

  /** Set FedEx account from select field */
  const handleFedExAccountSelection = (selectedOption) => {
    const selectedAccount = fedexAccounts.find((account) => account.account_number === selectedOption.value);
    setFedexAccount(selectedAccount);
  };

  /** Toggle SmartPost update modal */
  const handleToggleSmartPost = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowSmartPostModal(!showSmartPostModal);
  };

  /** Get FedEx accounts and company */
  useEffect(() => {
    // Fetch FedEx accounts
    if (!fedexAccounts.length) {
      fetchFedExRegistrationAccounts();
    }
    // Fetch account company
    if (fedexAccount && fedexAccount.company) {
      getCompanyDetails(fedexAccount.company);
    }
    // Set SmartPost Hub as readable display
    if (fedexAccount && fedexAccount.smart_post_hub_id) {
      const smartPostHub = smartPostHubIds.find((smartPost) => smartPost.value === fedexAccount.smart_post_hub_id);

      if (smartPostHub) {
        setSmartPostHubDisplay(`${smartPostHub.label} ${smartPostHub.value}`);
      }
    }
  }, [fedexAccount]);

  const formatFedexAccountLabel = ({label, nickname}) => {
    return (
      <div className="fedex-accounts-list__account-label">
        <span>{label}</span>
        {nickname && <Pill size="sm">{nickname}</Pill>}
      </div>
    );
  };

  const fedexLabels = fedexAccounts.map((item) => ({
    label: item.account_number,
    value: item.account_number,
    nickname: item.account_nickname
  }));

  return (
    <div>
      {
        // Note use of short circuiting boolean operators instead of ternaries is so that the reader
        // does not have to remember the precedence of nested ternaries. Remember that AND, `&&`, is
        // of high precedence than OR, `||`. - Joe 2022-12-09
        (!isFetchingFedexAccounts && fedexAccounts.length < 1 && <p>No FedEx Account Connected</p>) ||
          ((isFetchingFedexAccounts || !fedexAccount || !company) && <ShipwellLoader loading />) || (
            <div className="fedex-account-container field-grid">
              <div className="grid-item-1">
                <Select
                  className="fedex-accounts-list"
                  clearable={false}
                  label="FedEx Account ID"
                  name="fedex_account"
                  options={fedexLabels}
                  defaultValue={fedexLabels[0]}
                  formatOptionLabel={formatFedexAccountLabel}
                  onChange={handleFedExAccountSelection}
                  disabled={!isEditable}
                />
              </div>
              {fedexAccount && company && (
                <div className="grid-item-2 fedex-account-details">
                  <div className="field-grid">
                    <div>
                      <strong>Company Name</strong>
                      <p>{company.name}</p>
                    </div>
                    <div>
                      <strong>Billing Address</strong>
                      <p>{fedexAccount.address && fedexAccount.address.line_1}</p>
                    </div>
                    <div>
                      <strong>Contact Email Address</strong>
                      <p>{company.primary_email}</p>
                    </div>
                    <div>
                      <strong>Contact Phone Number</strong>
                      <p>{company.primary_phone_number && getPhoneHyperlink(company.primary_phone_number)}</p>
                    </div>
                    <div>
                      <strong>FedEx SmartPost® Hub ID</strong>
                      {fedexAccount.smart_post_hub_id && (
                        <p>
                          {smartPostHubDisplay}
                          <br />
                          <a href="#" onClick={handleToggleSmartPost}>
                            Update FedEx SmartPost®
                          </a>
                        </p>
                      )}
                      {!fedexAccount.smart_post_hub_id && (
                        <p>
                          <a href="#" onClick={handleToggleSmartPost}>
                            Connect FedEx SmartPost®
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                  <SchedulePickups account={fedexAccount} providerCode="FEDEX" />
                </div>
              )}
            </div>
          )
      }
      <Modal
        show={Boolean(showSmartPostModal)}
        title={
          fedexAccount && fedexAccount.smart_post_hub_id
            ? 'Update FedEx SmartPost® Hub ID'
            : 'Add FedEx SmartPost® Hub ID'
        }
        footerComponent={null}
        onClose={handleToggleSmartPost}
      >
        <Formik enableReinitialize initialValues={{...fedexAccount}} onSubmit={handleSaveSmartPost}>
          {({handleSubmit, isSubmitting, dirty}) => (
            <form onSubmit={handleSubmit}>
              <Card title="FedEx SmartPost®">
                <Field
                  simpleValue
                  name="smart_post_hub_id"
                  label="FedEx SmartPost® Hub ID"
                  getOptionLabel={({value, label}) => `${label} ${value}`}
                  options={smartPostHubIds}
                  component={FormikSelect}
                />
              </Card>
              <div className="sw-modal-footer">
                <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={handleToggleSmartPost}>
                  Cancel
                </DeprecatedButton>
                <DeprecatedButton
                  disabled={!dirty || isSubmitting}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                  onClick={handleSubmit}
                >
                  Save
                </DeprecatedButton>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default connect()(FedExAccountDetails);
