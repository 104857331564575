import {FormGroup, InputGroup, FormControl} from 'react-bootstrap';

const style = {
  inputs: {
    background: '#fff',
    border: '0',
    padding: '0'
  }
};

const renderNoLabelField = ({input, tabindex, placeholder, type, disabled, extraClass, meta: {touched, error}}) => (
  <FormGroup
    className={extraClass && extraClass}
    controlId={input.name}
    validationState={touched && error ? 'error' : null}
  >
    <InputGroup className="input-group-inline" style={{width: '100%'}}>
      <FormControl
        tabIndex={tabindex}
        disabled={disabled ? true : false}
        {...input}
        type={type}
        style={style.inputs}
        placeholder={placeholder}
      />
    </InputGroup>
  </FormGroup>
);

export default renderNoLabelField;
