import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {toTitleCase} from 'App/utils/globals';
import getNil from 'App/utils/getNil';
import {renderAdditionalContent} from 'App/utils/additionalContent';
import './styles.scss';

const StopFlagsTooltip = ({stops, wrapperClassname}) => {
  let newTooltipContent = [];

  stops.forEach((stop) => {
    getNil(stop, 'alerts', []).forEach((stopAlert) => {
      const stopIndex = findIndex(stops, (stop) => stop.id === stopAlert.stop);
      newTooltipContent.push(
        <div>
          <span className="stopIndex">Stop {stopIndex + 1}</span>: {toTitleCase(stopAlert.alert_type)} -{' '}
          {toTitleCase(stopAlert.reason_code)}
        </div>
      );
    });
  });

  if (newTooltipContent.length > 7) {
    newTooltipContent = renderAdditionalContent(newTooltipContent, 7, 'more', 'more');
  }

  if (newTooltipContent.length > 0) {
    return (
      <Tooltip
        tooltipContent={newTooltipContent}
        wrapperClassname={wrapperClassname}
        placement="bottom"
        trigger="hover"
        portal
      >
        <SvgIcon name="FlagFilled" color="sw-error" width="20" height="20" />
      </Tooltip>
    );
  }

  return null;
};

StopFlagsTooltip.propTypes = {
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),
  wrapperClassname: PropTypes.string
};

StopFlagsTooltip.defaultProps = {
  wrapperClassname: ''
};

export default StopFlagsTooltip;
