import {useState} from 'react';
import {browserHistory} from 'react-router';
import {RowSelectionState} from '@tanstack/react-table';
import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, Modal} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import Drawer from '@material-ui/core/Drawer';
import {DashboardSearch} from './components/DashboardSearch';
import {DashboardTable} from 'App/containers/Dashboard/DashboardTable';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {ShipmentBulkActionsDropdown} from 'App/containers/Dashboard/bulkActions';
import useToggle from 'App/utils/hooks/useToggle';
import CreateImport from 'App/components/imports/create';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {TryItNowTag} from 'App/containers/Dashboard/components/TryItNowTag';
import {FlexBox} from 'App/components/Box';
import DashboardSummary from 'App/containers/shipments/components/DashboardSummary';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';
import {LegacyDashboardSummaryWrapper} from 'App/containers/Dashboard/ShipmentSummary';
import {defaultKeys} from 'App/containers/Dashboard/columns';

const drawerWidth = '500px';
export const DashboardRoute = WithStatusToasts(
  ({setSuccess, onUpdateDashboardFlag}: WithStatusToastProps & {onUpdateDashboardFlag: () => void}) => {
    const [showImportFullScreen, setShowImportFullScreen] = useState(false);
    const [selectedShipments, setSelectedShipments] = useState<ShippingDashboardRow[]>([]);
    // state specific to table
    const [tableRowSelectionState, setTableRowSelectionState] = useState<RowSelectionState>({});

    const [columnExportIds, setColumnExportIds] = useState<string[]>(defaultKeys);
    const [showImportShipmentModal, toggleShowImportShipmentModal] = useToggle();
    const {modeMultiModeDashboard} = useFlags();
    const [chosenShipment, setChosenShipment] = useState<ShippingDashboardRow>();

    const ulidShipmentId = chosenShipment?.id ?? '';
    const searchParamURL = new URLSearchParams(location.search);
    const query = searchParamURL.get('q') || '';

    const handleQuery = (newQuery: string) => {
      searchParamURL.set('q', newQuery);
      browserHistory.replace({
        pathname: location.pathname,
        search: `?${searchParamURL.toString()}`
      });
    };

    const handleSelectShipmentForDrawer = (shipment: ShippingDashboardRow) => {
      setChosenShipment((prevShipment) => {
        if (prevShipment?.id === shipment.id) {
          return undefined;
        }
        return shipment;
      });
    };
    const isV3Shipment = chosenShipment?.version === V3_VERSION;

    const debouncedQuery = useDebounce(query, 300);

    const handleCloseImportModal = () => {
      toggleShowImportShipmentModal();
      setShowImportFullScreen(false);
    };

    return (
      <div
        className="flex w-full flex-col h-screen-minus-16"
        style={{
          width: chosenShipment ? `calc(100% - ${drawerWidth})` : '',
          transition: 'width 300ms'
        }}
      >
        <header className="border-b border-sw-border bg-sw-background px-4 py-2">
          <div className="flex flex-col gap-2 md:flex-row md:justify-between">
            <FlexBox gap="m" items="center">
              <h2 className="m-0">Shipments</h2>
              <DashboardSearch />
            </FlexBox>
            <FlexBox gap="m" items="center">
              <ShipmentBulkActionsDropdown
                selectedShipments={selectedShipments}
                onSuccess={() => {
                  setSelectedShipments([]);
                  setTableRowSelectionState({});
                }}
                toggleShowImportShipmentModal={toggleShowImportShipmentModal}
                columnExportIds={columnExportIds}
              />
              <TryItNowTag>
                <FlexBox items="center" gap="s">
                  <span className="font-bold">Want the Classic Dashboard?</span>
                  <a
                    className="text-xs underline"
                    href="https://docs.google.com/document/d/15iJpaGG15kpR574JPZ5NOuKLKAiEe_HgAFcLcKQ5nZA/edit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                  <Button size="sm" isCompact onClick={onUpdateDashboardFlag}>
                    Switch Back
                  </Button>
                </FlexBox>
              </TryItNowTag>
            </FlexBox>
          </div>
        </header>
        <DashboardTable
          globalQuery={debouncedQuery.trim()}
          onChangeGlobalQuery={handleQuery}
          onSelectShipmentRows={setSelectedShipments}
          onColumnVisibilityChange={setColumnExportIds}
          tableRowSelectionState={tableRowSelectionState}
          setTableRowSelectionState={setTableRowSelectionState}
          shipmentDrawerId={chosenShipment?.id}
          onSelectShipment={handleSelectShipmentForDrawer}
        />
        <Drawer
          anchor="right"
          variant="persistent"
          open={Boolean(chosenShipment?.id)}
          onClose={() => setChosenShipment(undefined)}
          classes={{
            paper: 'h-screen-minus-16 top-auto bottom-0 z-10'
          }}
        >
          <div className="h-full " style={{width: drawerWidth}}>
            {modeMultiModeDashboard && isV3Shipment ? (
              <DashboardSummary
                shipmentId={chosenShipment?.v3_shipment_id || ''}
                resourceId={ulidShipmentId}
                onClose={() => setChosenShipment(undefined)}
              />
            ) : (
              <LegacyDashboardSummaryWrapper
                defaultShipment={chosenShipment}
                onClose={() => setChosenShipment(undefined)}
              />
            )}
          </div>
        </Drawer>
        <Modal
          fullScreen={showImportFullScreen}
          show={showImportShipmentModal}
          title="Import Shipments"
          footerComponent={null}
          onClose={handleCloseImportModal}
        >
          <CreateImport
            setUseFullScreen={setShowImportFullScreen}
            onCancel={handleCloseImportModal}
            type="SHIPMENTS"
            setShowImportSuccess={(numberOfSuccessImports: number) =>
              setSuccess('Success!', `${numberOfSuccessImports} records imported.`)
            }
          />
        </Modal>
      </div>
    );
  }
);
