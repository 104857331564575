import {ProductLengthUnitEnum, ProductWeightUnitEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {numberWithCommas, formatMileage} from 'App/utils/globals';
import {getTotalWeight} from 'App/utils/globalsTyped';
import {toCurrency} from 'App/utils/grammar';

// countries
const US = 'US';
const CA = 'CA';

// measurements
export const INCHES = 'IN';
export const CENTIMETERS = 'CM';
export const POUNDS = 'LB';
export const KILOGRAMS = 'KG';
export const FAHRENHEIT = 'F';
export const CELSIUS = 'C';

// currency options
export const US_DOLLARS = 'USD';
export const CA_DOLLARS = 'CAD';

//overall system identifiers
export const METRIC = 'METRIC';
export const IMPERIAL = 'IMPERIAL';
export const OTHER = 'OTHER';

// defaults for known countries
export const countryDefaults = [
  {
    country: US,
    unitPreferences: {
      lengthUnit: ProductLengthUnitEnum.In,
      weightUnit: ProductWeightUnitEnum.Lb,
      temperatureUnit: FAHRENHEIT,
      currency: US_DOLLARS,
      system: IMPERIAL
    }
  },
  {
    country: CA,
    unitPreferences: {
      lengthUnit: ProductLengthUnitEnum.Cm,
      weightUnit: ProductWeightUnitEnum.Kg,
      temperatureUnit: CELSIUS,
      currency: CA_DOLLARS,
      system: METRIC
    }
  }
];

export const milesToKmConversion = 1.609344;

// conversion functions
// pounds to kg
export const convertPoundsToKilograms = (value) => {
  return parseFloat(Math.round((100 * (value / 2.20462)) / 100)).toFixed(2);
};

// kg to pounds
export const convertKilogramsToPounds = (value) => {
  return parseFloat(value * 2.20462).toFixed(2);
};

// inches to centimeters
export const convertInchesToCentimeters = (value) => {
  return parseFloat(value * 2.54).toFixed(2);
};

// centimeters to inches
export const convertCentimetersToInches = (value) => {
  return parseFloat(value / 2.54).toFixed(2);
};

// cubic feet to cubic meters
export const convertCubicFeetToCubicMeters = (value) => {
  return parseFloat(value / 35.315).toFixed(2);
};

// cubic meters to cubic feet
export const convertCubicMetersToCubicFeet = (value) => {
  return parseFloat(value * 35.315).toFixed(2);
};

// miles to kilometers
export const convertMilesToKilometers = (value, decimalPlaces = 0, useCommas = true) => {
  const distance = Number(value) * milesToKmConversion;

  return formatMileage(distance, decimalPlaces, useCommas);
};

// kilometers to miles
export const convertKilometersToMiles = (value, decimalPlaces = 0, useCommas = true) => {
  const distance = Number(value) / milesToKmConversion;

  return formatMileage(distance, decimalPlaces, useCommas);
};

// fahrenheit to celsius
export const convertFahrenheitToCelsius = (value) => {
  return parseFloat(((value - 32) * 5) / 9).toFixed(2);
};

// celsius to fahrenheit
export const convertCelsiusToFahrenheit = (value) => {
  return parseFloat((value * 9) / 5 + 32).toFixed(2);
};

// format currency with country prefix
export const formatCurrency = (value, currency = US_DOLLARS) => {
  if (value && !isNaN(value)) {
    return toCurrency(parseFloat(value), currency);
  }
  return `${currency} 0.00`;
};

//convert weight to measurement unit specified in company preferences
export const displayTotalWeight = ({
  shipment,
  unitPreferences,
  returnNullWeight = false,
  returnWeightLabel = false,
  keepOnlyUnit = false
}) => {
  const inputLabel = keepOnlyUnit ? '' : 'Total Weight:';

  if (!shipment) {
    if (returnNullWeight) {
      return `${returnWeightLabel ? `${inputLabel}  '--'` : '--'}`;
    }
    return;
  }

  const totalWeightOverrideWithLabel = shipment.total_weight_override?.value
    ? `${inputLabel} ${numberWithCommas(shipment.total_weight_override.value)} ${
        shipment.total_weight_override.unit || ''
      }`
    : '';

  if (unitPreferences?.system === METRIC) {
    if (shipment.total_weight_override?.value) {
      return `${
        returnWeightLabel
          ? totalWeightOverrideWithLabel
          : shipment.total_weight_override?.unit === POUNDS
          ? convertPoundsToKilograms(shipment.total_weight_override.value)
          : shipment.total_weight_override.value
      }`;
    }
    if (getTotalWeight(shipment?.line_items, METRIC) > 0) {
      return `${
        returnWeightLabel
          ? `${inputLabel} ${numberWithCommas(getTotalWeight(shipment?.line_items, METRIC).toFixed(2))} KG`
          : numberWithCommas(getTotalWeight(shipment?.line_items, METRIC).toFixed(2))
      }`;
    }
    if (returnNullWeight) {
      return `${returnWeightLabel ? `${inputLabel}  '--'` : '--'}`;
    }
  } else {
    if (shipment.total_weight_override?.value) {
      return `${
        returnWeightLabel
          ? totalWeightOverrideWithLabel
          : shipment.total_weight_override?.unit === KILOGRAMS
          ? convertKilogramsToPounds(shipment.total_weight_override.value)
          : shipment.total_weight_override.value
      }`;
    }
    if (getTotalWeight(shipment?.line_items, IMPERIAL) > 0) {
      return `${
        returnWeightLabel
          ? `${inputLabel} ${numberWithCommas(getTotalWeight(shipment?.line_items, IMPERIAL).toFixed(2))} LB`
          : numberWithCommas(getTotalWeight(shipment?.line_items, IMPERIAL).toFixed(2))
      }`;
    }
    if (returnNullWeight) {
      return `${returnWeightLabel ? `${inputLabel}  '--'` : '--'}`;
    }
  }
};
