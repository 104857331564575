import {Formik} from 'formik';
import {object, string} from 'yup';
import {debounce} from 'lodash';

import SearchUsersFields from 'App/formComponents/formSections/searchUsersFields';

/** Default values needed for Formik */
const defaultFormValues = {
  q: ''
};

const validationSchema = object().shape({
  q: string().nullable()
});

type ValuesProps = {
  q: string;
};

type SearchUsersFormProps = {
  values: ValuesProps;
  onSubmit: (values: ValuesProps) => void;
};

/**
 * Search Users Form
 */
const SearchUsersForm = ({values, onSubmit}: SearchUsersFormProps) => {
  const initialValues = {defaultFormValues, ...values};

  const handleSubmit = (values: ValuesProps) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({handleSubmit}) => (
        <form noValidate onSubmit={handleSubmit} onChange={debounce(handleSubmit, 300)}>
          <SearchUsersFields />
        </form>
      )}
    </Formik>
  );
};

export default SearchUsersForm;
