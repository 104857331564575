/**
 * String manipulation for country labels, which return from the backend like ", US"
 * @param {String} formattedAddress A formatted address string
 *
 * @return {String}
 */
export default function formatCountryAddress(formattedAddress) {
  if (formattedAddress.split('')[0] === ',') {
    formattedAddress = formattedAddress.slice(1).trim();
  }
  return formattedAddress;
}
