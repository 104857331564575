import {PRIVACY_POLICY, TERMS_CONDITIONS} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case PRIVACY_POLICY:
      return {
        ...state,
        privacy: action.payload
      };
    case TERMS_CONDITIONS:
      return {
        ...state,
        termsconditions: action.payload
      };
  }

  return state;
}
