import {JSX} from 'react';
import {CompanyInternationalPreferences} from '@shipwell/backend-core-sdk';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {getCompanyInternationalPreferences} from 'App/api/internationalPreferences';
import {useUserMe} from 'App/data-hooks';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {INTERNATIONAL_CONFIGS} from 'App/data-hooks/queryKeys';

export const GetInternationalPreferencesQuery = ({
  // TODO: rename this to a InternationalPreferencesContextProvider and move to the UI layer of the application
  children
}: {
  children: (params: UseQueryResult<CompanyInternationalPreferences>) => JSX.Element;
}) => {
  const userMeData = useUserMe();
  const companyId = userMeData?.data?.company?.id || '';
  const query = useQuery(
    [INTERNATIONAL_CONFIGS],
    async () => {
      if (!companyId) {
        return Promise.reject('Missing required company id parameter');
      }
      const {data} = await getCompanyInternationalPreferences(companyId);
      return data;
    },
    {
      staleTime: STATIC_DATA_STALE_TIME,
      enabled: !!companyId
    }
  );
  return children(query);
};
