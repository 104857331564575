import {Dispatch, MouseEvent, SetStateAction, useState} from 'react';
import {
  PackageType,
  Shipment,
  ShipmentLineItem,
  ShipmentMetadata,
  TotalWeightOverride,
  CustomFieldEntityTypesEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  Card,
  CollapsibleCardContent,
  SvgIcon,
  DeprecatedButton,
  Modal,
  Toast,
  Title,
  Popover
} from '@shipwell/shipwell-ui';
import defer from 'lodash/defer';
import CardItems from './items';
import {calculateShipmentTotals, ShipmentModeEnum} from 'App/utils/globalsTyped';
import {countryDefaults, METRIC} from 'App/utils/internationalConstants';
import ShipmentItemTotals from 'App/containers/quotes/create/components/shipmentItemTotals';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import ShipmentLineItemsForm from 'App/formComponents/forms/ShipmentLineItemsForm';
import {useCompanyPreferences, usePackageTypes, useCustomFields, useUserMe} from 'App/data-hooks';
import {
  AssembleHandlingUnits,
  HandlingUnitsFeatureFlag
} from 'App/containers/Shipment/components/ShipmentItems/AssembleHandlingUnits';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';

type ShipmentCardItemsProps = {
  ordinalIndex: number;
  isCollapsed: boolean;
  onCollapse: () => void;
  draggableProvided: {
    draggableProps: object;
    dragHandleProps: object;
    innerRef: unknown;
  };
  shipment: Shipment;
  packageTypes: PackageType[];
  fetchDetails: (x: string) => void;
};

type Values = {
  line_items: Array<ShipmentLineItem>;
  metadata: ShipmentMetadata;
  total_weight_override: TotalWeightOverride;
  total_declared_value: number;
  total_quantity_override: number;
};

const ShipmentCardItems = ({
  ordinalIndex = 0,
  isCollapsed = false,
  onCollapse,
  draggableProvided,
  shipment,
  fetchDetails
}: ShipmentCardItemsProps) => {
  const editShipmentMutation = useUpdateShipment();
  const {data: packageTypes} = usePackageTypes();
  const userMe = useUserMe();
  const user = userMe.data?.user;
  const company = userMe.data?.company;
  const companyId = company?.id || '';
  const shipmentId = shipment.id;

  const {data: companyPreferences} = useCompanyPreferences(companyId);
  const unitPreferences = countryDefaults.find((e) => e.country === companyPreferences?.country)?.unitPreferences;
  const unitPreferencesWithPreferredCurrency = {
    ...unitPreferences,
    ...(shipment?.preferred_currency && {currency: shipment.preferred_currency})
  } as typeof unitPreferences;

  const [showHandlingUnitModal, setShowHandlingUnitModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalState, setModalState] = useState({
    mode: 'add',
    index: 0
  });

  const [lineItemIdToDelete, setLineItemIdToDelete] = useState('');
  const [deletingLineItem, setDeletingLineItem] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {customFields} = useCustomFields(CustomFieldEntityTypesEnum.ShipmentLineItem, shipmentId);
  const [showLineItemDeleteModal, setShowLineItemDeleteModal] = useState(false);
  const [lineItemDeleteSuccess, setLineItemDeleteSuccess] = useState(false);
  const [saveProductSuccess, setSaveProductSuccess] = useState(false);

  const lineitems = shipment?.line_items || [];
  const canEdit =
    ((user?.permissions || []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      (user?.permissions || []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
    !user?.is_quoting_limited_user;
  const totalWeight = shipment?.total_weight_override;
  const totalValue = shipment?.total_declared_value;
  const valueCurrency = shipment?.total_declared_value
    ? shipment?.total_declared_value_currency
    : shipment.preferred_currency || unitPreferences?.currency;
  const totalQuantity = shipment?.total_quantity_override;
  const hasFTL = shipment?.mode?.id === 1;
  const hasLTL = shipment?.mode?.code?.includes(ShipmentModeEnum.LTL.toString()) || shipment?.mode?.id === 2;
  const hasVLTL = shipment?.mode?.code?.includes(ShipmentModeEnum.VLTL.toString()) || shipment?.mode?.id === 4;

  const {weight: calculatedWeight, system} = calculateShipmentTotals({
    line_items: lineitems,
    unitPreferences: unitPreferencesWithPreferredCurrency,
    totalWeight
  });

  const weight = hasFTL && Number(totalWeight?.value) ? Number(totalWeight?.value) : calculatedWeight;
  const isMetricSystem = system === METRIC;

  const updated = () => {
    setShowEditModal(false);
    setLineItemIdToDelete('');
    defer(() => fetchDetails(shipment.id));
  };

  const handleSubmit = (values: Values) => {
    setIsSubmitting(true);
    if (!values) {
      return;
    }

    editShipmentMutation.mutate(
      {
        shipmentId,
        shipment: {
          ...shipment,
          ...values
        }
      },
      {
        onSuccess: () => {
          setSaveProductSuccess(true);
          updated();
        },
        onError: (error) => {
          console.error(error);
        },
        onSettled: () => {
          setIsSubmitting(false);
        }
      }
    );
  };

  const handleDeleteLineItem = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.currentTarget.disabled = true;
    setDeletingLineItem(true);

    editShipmentMutation.mutate(
      {
        shipmentId,
        shipment: {
          ...shipment,
          line_items: lineitems.filter((lineItem) => lineItem.id !== lineItemIdToDelete)
        }
      },
      {
        onSuccess: () => {
          updated();
          setShowLineItemDeleteModal(false);
          setLineItemDeleteSuccess(true);
          setDeletingLineItem(false);
          setLineItemIdToDelete('');
        },
        onError: (error) => {
          console.error(error);
        },
        onSettled: () => {
          setIsSubmitting(false);
        }
      }
    );
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    setLineItemIdToDelete('');
  };

  return (
    <Card
      title={
        <div className="flex items-center py-4">
          <span className="mr-3">
            <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
          </span>
          <div className="flex items-baseline text-nowrap">
            <span>Items</span>
            <span className="text-grey-5 text-xxs font-normal">
              <span className="mx-1">•</span>
              {lineitems.length} {lineitems.length === 1 ? 'ITEM' : 'ITEMS'}
              <span className="mx-1">•</span>
              {weight && weight > 0
                ? parseFloat(String(weight)).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : 0}
              {isMetricSystem ? ' KGS' : ' LBS'}
            </span>
          </div>
        </div>
      }
      tabOrder={ordinalIndex}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      draggableProvided={draggableProvided}
      bodyClassName="p-4 empty:p-0"
      actions={
        userMe.status === 'loading' ? (
          <SvgIcon name="LoadingDots" />
        ) : (
          canEdit && (
            <Popover
              placement="bottom-end"
              trigger={<Popover.IconButton aria-label="Show line item options" iconName="Overflow" />}
            >
              {({setIsOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>}) => (
                <Popover.MenuList>
                  <Popover.MenuListItemButton
                    onClick={() => {
                      setShowEditModal(true);
                      setIsOpen(false);
                    }}
                  >
                    Edit
                  </Popover.MenuListItemButton>
                  <HandlingUnitsFeatureFlag>
                    <Popover.MenuListItemButton
                      onClick={() => {
                        setShowHandlingUnitModal(true);
                        setIsOpen(false);
                      }}
                    >
                      Assemble Handling Units
                    </Popover.MenuListItemButton>
                  </HandlingUnitsFeatureFlag>
                </Popover.MenuList>
              )}
            </Popover>
          )
        )
      }
    >
      <CollapsibleCardContent>
        <CardItems
          shipment={shipment}
          packageTypes={packageTypes || []}
          canEdit={canEdit}
          setShowEditModal={setShowEditModal}
          setModalState={setModalState}
          setLineItemIdToDelete={setLineItemIdToDelete}
          setShowLineItemDeleteModal={setShowLineItemDeleteModal}
          customFields={customFields}
        />
        {lineitems.length && canEdit ? (
          <Card
            title={
              <DeprecatedButton
                variant="tertiary"
                icon={<SvgIcon name="AddCircleOutlined" />}
                onClick={() => {
                  setShowEditModal(true);
                  setModalState({
                    mode: 'add',
                    index: 0
                  });
                }}
              >
                Add Item
              </DeprecatedButton>
            }
            className="shipment-line-items__form-card collapsed shipment-line-items__form-add mb-4 flex"
            draggableProvided={false}
            bodyClassName="p-4 empty:p-0"
          />
        ) : (
          <Card
            title="All line items have been removed from current shipment"
            draggableProvided={false}
            bodyClassName="p-4 empty:p-0"
          ></Card>
        )}
        {lineitems.length ? (
          <ShipmentItemTotals
            unitPreferences={unitPreferencesWithPreferredCurrency}
            lineItems={lineitems}
            totalWeight={totalWeight}
            totalValue={totalValue}
            totalQuantity={totalQuantity}
            valueCurrency={valueCurrency}
            hasFTL={hasFTL}
            isSidebar
          />
        ) : null}
      </CollapsibleCardContent>
      {lineitems.length > 0 && canEdit && (
        <Modal
          show={showEditModal}
          title="Edit Line Items"
          footerComponent={null}
          size="large"
          onClose={() => setShowEditModal(false)}
        >
          <ShipmentLineItemsForm
            shipmentDetails={shipment}
            lineItems={lineitems}
            hasLTL={hasLTL}
            hasVLTL={hasVLTL}
            modalState={modalState}
            onCancel={handleCancelEdit}
            onSubmit={handleSubmit}
            submitDisabled={isSubmitting || deletingLineItem}
          />
        </Modal>
      )}
      <Modal title="Delete Line item?" show={showLineItemDeleteModal} onClose={() => setShowLineItemDeleteModal(false)}>
        <Title variant="CardTitle">
          Are you sure you want to delete {lineitems.find((item) => item.id === lineItemIdToDelete)?.description}?
        </Title>
        <div className="mt-2">
          If this is the only line item on one of the orders associated with this shipment, the line item cannot be
          deleted.
        </div>
        <ModalFormFooter
          primaryActionName={
            lineitems?.length < 2 || lineitems.find((item) => item.id === lineItemIdToDelete)?.purchase_order
              ? 'This item cannot be deleted'
              : 'Delete'
          }
          primaryActionDisabled={
            lineitems?.length < 2 ||
            !!lineitems.find((item) => item.id === lineItemIdToDelete)?.purchase_order ||
            deletingLineItem
          }
          onPrimaryActionClick={handleDeleteLineItem}
          onCancel={() => setShowLineItemDeleteModal(false)}
        />
      </Modal>
      <Modal
        title="Assemble Handling Units"
        show={showHandlingUnitModal}
        onClose={() => setShowHandlingUnitModal(false)}
        footerComponent={null}
        fullScreen
        portal={false}
        bodyClassName="p-0"
      >
        <AssembleHandlingUnits
          shipment={shipment}
          onSuccess={() => defer(() => fetchDetails(shipment.id))}
          onClose={() => setShowHandlingUnitModal(false)}
        />
      </Modal>
      <Toast
        show={lineItemDeleteSuccess}
        variant="success"
        title="Success!"
        anchor="bottom-right"
        onClose={() => setLineItemDeleteSuccess(false)}
      >
        Line item deleted.
      </Toast>
      <Toast
        show={saveProductSuccess}
        variant="success"
        title="Success!"
        anchor="bottom-right"
        onClose={() => setSaveProductSuccess(false)}
      >
        Line item saved.
      </Toast>
    </Card>
  );
};

export default ShipmentCardItems;
