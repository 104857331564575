/* eslint-disable @typescript-eslint/no-unused-expressions */
import PropTypes from 'prop-types';
import {useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {DeprecatedButton, Card} from '@shipwell/shipwell-ui';
import {useSurchargeCalculation} from 'App/containers/userCompany/rateTables/hooks/useSurchargeCalculation';
import {FUEL_SURCHARGE, RATE_TYPE_PER_MILE} from 'App/containers/userCompany/rateTables/constants';
import {RateTablePreview} from 'App/containers/userCompany/rateTables/helpers';
import DOERateHistory from 'App/containers/userCompany/fuelSurcharges/details/doeRateHistory';
import Table from 'App/components/Table';
import {makeFuelSurchargeRateTableRows} from 'App/containers/userCompany/fuelSurcharges/utils';
import {formatCurrencyValue} from 'App/utils/globals';

const BEM = (name) => (!name ? 'rateTable__financial__details-table' : `rateTable__financial__details-table-${name}`);

const RateTableDetailsFuelSurcharge = ({
  shipment,
  values,
  dispatch,
  fuelSurchargeCustomer,
  fuelSurchargeVendor,
  isEditMode,
  financialSide
}) => {
  const [buttonId, setButtonId] = useState('');
  const [doeRates, setDoeRates] = useState(null);
  const [matchingCustomerFuelSurchargeRate, setMatchingCustomerFuelSurchargeRate] = useState(null);
  const [matchingVendorFuelSurchargeRate, setMatchingVendorFuelSurchargeRate] = useState(null);
  const [isFetchingRates, setIsFetchingRates] = useState(false);
  const currentCustomer = fuelSurchargeCustomer?.calculation_rows;
  const currentVendor = fuelSurchargeVendor?.calculation_rows;
  const customerChargeLineItems = shipment?.relationship_to_customer?.customer_charge_line_items;
  const vendorChargeLineItems = shipment?.relationship_to_vendor?.customer_charge_line_items;
  const totalMiles = shipment?.total_miles;
  const isCustomer = financialSide === 'customer';
  const isDisabledLineItem =
    !isEditMode ||
    isFetchingRates ||
    ((fuelSurchargeVendor.rate_type || fuelSurchargeCustomer.rate_type) === RATE_TYPE_PER_MILE && !totalMiles);

  useSurchargeCalculation({
    buttonId,
    isCustomer,
    customerChargeLineItems,
    vendorChargeLineItems,
    matchingCustomerFuelSurchargeRate,
    matchingVendorFuelSurchargeRate,
    fuelSurchargeCustomer,
    fuelSurchargeVendor,
    totalMiles,
    dispatch,
    setButtonId
  });

  // Pure function for getMatchingRowIndex
  const getMatchingRowIndex = (doeRates, customerOrVendor) => {
    if (doeRates < customerOrVendor[0].fuel_price_lower_bound) {
      return 0;
    }
    if (doeRates > customerOrVendor[customerOrVendor.length - 1].fuel_price_lower_bound) {
      return customerOrVendor.length - 1;
    }
    return customerOrVendor.findIndex(({fuel_price_lower_bound: fscLowerBound}) => fscLowerBound > doeRates) - 1;
  };
  useEffect(() => {
    const fetchDoeRateHistory = () => {
      try {
        const customerOrVendor = isCustomer ? currentCustomer : currentVendor;
        if (customerOrVendor) {
          const matchingRowIndex = getMatchingRowIndex(doeRates, customerOrVendor);
          const dollarValue = formatCurrencyValue(customerOrVendor[matchingRowIndex]?.fuel_surcharge, 3);
          if (matchingRowIndex > -1) {
            isCustomer
              ? setMatchingCustomerFuelSurchargeRate(dollarValue)
              : setMatchingVendorFuelSurchargeRate(dollarValue);
          }
        } else {
          setMatchingCustomerFuelSurchargeRate(null) ?? setMatchingVendorFuelSurchargeRate(null);
        }
      } catch (error) {
        console.error('Error retrieving fuel surcharge table', error);
      }
    };
    fetchDoeRateHistory();
  }, [currentVendor, fuelSurchargeVendor, fuelSurchargeCustomer, currentCustomer, doeRates, isCustomer]);

  const fuelSurchargeTableData = useMemo(
    () => [...makeFuelSurchargeRateTableRows(values?.fuelsurcharge ?? {})],
    [values]
  );

  const fuelSurchargeTableColumns = useMemo(
    () => [
      {
        Header: 'DOE Rate (from)',
        accessor: 'doe_from'
      },
      {
        Header: 'DOE Rate (to)',
        accessor: 'doe_to'
      },
      {
        Header: `Surcharge (${
          values?.fuelsurcharge?.rate_type === RATE_TYPE_PER_MILE ? 'Per Mile' : 'Percent of Line Haul'
        })`,
        accessor: 'surcharge'
      }
    ],

    [values]
  );

  return (
    <div className="grid-item-1-12">
      <Card title={FUEL_SURCHARGE} className="rateTable__financial-card mb-16 p-0">
        {fuelSurchargeTableData.length > 0 ? (
          <RateTablePreview>
            <Table columns={fuelSurchargeTableColumns} data={fuelSurchargeTableData} />
          </RateTablePreview>
        ) : fuelSurchargeCustomer || fuelSurchargeVendor ? (
          <div className={BEM('doeRateHistory')}>
            <DOERateHistory
              fuelSurchargeTable={fuelSurchargeCustomer || fuelSurchargeVendor}
              handleDoeRateChange={setDoeRates}
              setIsFetchingRates={setIsFetchingRates}
            />
            <DeprecatedButton
              className={BEM('addLineItem')}
              size="small"
              variant="primary"
              disabled={isDisabledLineItem}
              name="Add Line Item"
              onClick={() => {
                isCustomer ? setButtonId('customer') : setButtonId('vendor');
              }}
            >
              Add as Line Item
            </DeprecatedButton>
          </div>
        ) : null}
      </Card>
      {fuelSurchargeCustomer || fuelSurchargeVendor ? (
        <Link
          to={
            fuelSurchargeCustomer
              ? `/company/lane-management/fuel-surcharges/${fuelSurchargeCustomer.id}`
              : `/company/lane-management/fuel-surcharges/${fuelSurchargeVendor?.id}`
          }
          className={BEM('detailsLink')}
          target="_blank"
          rel="noopener noreferrer"
          activeClassName="active"
        >
          View full {fuelSurchargeCustomer ? `${fuelSurchargeCustomer.name}` : `${fuelSurchargeVendor?.name}`} table
          details
        </Link>
      ) : null}
    </div>
  );
};

RateTableDetailsFuelSurcharge.propTypes = {
  fuelSurchargeCustomer: PropTypes.object,
  fuelSurchargeVendor: PropTypes.object,
  financialSide: PropTypes.string,
  dispatch: PropTypes.func,
  shipment: PropTypes.shape({
    total_miles: PropTypes.number,
    relationship_to_vendor: PropTypes.shape({
      customer_charge_line_items: PropTypes.object
    }),
    relationship_to_customer: PropTypes.shape({
      customer_charge_line_items: PropTypes.object
    })
  }),
  values: PropTypes.shape({
    fuelsurcharge: PropTypes.object
  }),
  isEditMode: PropTypes.bool
};

export default connect((state) => ({
  selectedRateTable: state.shipmentdetails.selectedRateTable,
  shipment: state.shipmentdetails.one
}))(RateTableDetailsFuelSurcharge);
