import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Shipment} from '@shipwell/backend-core-sdk';
import isEqual from 'lodash/isEqual';
import {checkCarrierInsurance, getCarrierRelationshipsPromise} from 'App/api/carriers/carrierRelationship';

interface Carrier {
  name: string;
}

const useCheckIsInsuranceValid = (
  shipment: Shipment | undefined,
  fiCarrierComplianceInsurance: boolean,
  carriers: Carrier[] = []
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInsuranceInvalid, setIsInsuranceInvalid] = useState(false);
  const previousSelectedCarrier = useRef<Carrier[] | []>([]);

  const validCarriers = useMemo(() => carriers.filter((carrier) => carrier && carrier.name), [carriers]);

  const carrierRelationshipsOpts = useMemo(
    () =>
      validCarriers.map((carrier) => ({
        q: carrier.name,
        carrierStatus: 'ACTIVE' as const
      })),
    [validCarriers]
  );

  const checkIsInsuranceInvalid = useCallback(async () => {
    if (!shipment?.id || !fiCarrierComplianceInsurance || carrierRelationshipsOpts.length === 0) {
      return setIsInsuranceInvalid(false);
    }

    setIsLoading(true);
    let isInvalid = false;

    for (const opts of carrierRelationshipsOpts) {
      const {data} = await getCarrierRelationshipsPromise(opts);

      if (data.results?.length && data.results[0].id) {
        const {violated} = await checkCarrierInsurance(shipment.id, data.results?.[0].id);

        if (violated) {
          isInvalid = true;
          break;
        }
      }
    }

    setIsInsuranceInvalid(isInvalid);
    setIsLoading(false);
  }, [fiCarrierComplianceInsurance, carrierRelationshipsOpts, shipment]);

  useEffect(() => {
    if (isEqual(carriers, previousSelectedCarrier.current)) {
      return;
    }

    void checkIsInsuranceInvalid();

    previousSelectedCarrier.current = carriers;
  }, [shipment, fiCarrierComplianceInsurance, carriers, checkIsInsuranceInvalid]);

  return {
    isInsuranceInvalid,
    isLoading
  };
};

export default useCheckIsInsuranceValid;
