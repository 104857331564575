import {useMutation} from '@tanstack/react-query';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {OrderStop} from '@shipwell/corrogo-sdk';
import {updatePurchaseOrderShipFrom} from 'App/api/corrogo/typed';

export const useUpdatePurchaseOrderShipFrom = () => {
  const {decimalSupportForShipmentLineItems}: {decimalSupportForShipmentLineItems: boolean} = useFlags();
  const {mutate, mutateAsync, isLoading} = useMutation((params: {orderId: string; orderStop: OrderStop}) =>
    updatePurchaseOrderShipFrom(params, decimalSupportForShipmentLineItems)
  );
  return {
    updatePurchaseOrderShipFrom: mutate,
    updatePurchaseOrderShipFromAsync: mutateAsync,
    isUpdatingPurchaseOrderShipFrom: isLoading
  };
};
