import {compose} from 'redux';
import {useMutation} from '@tanstack/react-query';
import {ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {InjectedRouter, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {useShippingLabels} from 'App/api/digitalDocuments/useShippingLabels';
import {generateShippingLabel} from 'App/api/shipment/typed';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export const GenerateShippingLabelLink = ({
  shipment,
  router,
  setError,
  canEdit
}: {
  shipment?: {id: string};
  router: InjectedRouter;
  canEdit: boolean;
  setError: WithStatusToastProps['setError'];
}) => {
  const {getShippingLabelsQuery, shippingLabels} = useShippingLabels();
  const generateShippingLabelMutation = useMutation<
    Awaited<ReturnType<typeof generateShippingLabel>>,
    AxiosError<ShipwellError>
  >(() => generateShippingLabel(shipment?.id || ''));

  if (!shipment) {
    return null;
  }

  const errorMessage = `Error generating shipping label for ${shipment.id || ''}`;

  const handleGenerateShippingLabel = async () => {
    const response = await generateShippingLabelMutation.mutateAsync(undefined, {
      onError: (error: AxiosError<ShipwellError>) => {
        setError('Error!', error.message ?? errorMessage);
      }
    });
    if (response?.data.id) {
      router.push(`/shipments/${shipment.id}/documents/${response.data.id}`);
    } else {
      setError(errorMessage);
    }
  };

  if (getShippingLabelsQuery.isInitialLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  // do not display button if there is not an active shipping label
  if (shippingLabels.length === 0) {
    return null;
  }

  return (
    <DeprecatedButton
      disabled={generateShippingLabelMutation.isLoading || !canEdit}
      variant="text"
      onClick={() => void handleGenerateShippingLabel()}
    >
      Generate Shipping Label
    </DeprecatedButton>
  );
};

export default compose(
  withFlags('modeShipmentLabels'),
  withConditionalFallback(({modeShipmentLabels}: {modeShipmentLabels: boolean}) => !modeShipmentLabels, null),
  withStatusToasts,
  withRouter,
  connect((state?: {userProfile?: {user?: {permissions?: string[]}}}) => ({
    canEdit:
      (state?.userProfile?.user?.permissions || []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      (state?.userProfile?.user?.permissions || []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)
  }))
)(GenerateShippingLabelLink);
