import {ReactNode} from 'react';
import {compose} from 'recompose';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Error404Page from 'App/common/Error404Page2';

const ServiceProvidersContainer = ({children}: {children: ReactNode}) => {
  return <div className="flex flex-col overflow-hidden bg-sw-background h-screen-minus-16">{children}</div>;
};

const ConditionalServiceProvidersContainer = compose<{children: ReactNode}, unknown>(
  withFlags('modeServiceProviders'),
  withConditionalFallback(
    ({modeServiceProviders}: {modeServiceProviders: boolean}) => !modeServiceProviders,
    Error404Page
  )
)(ServiceProvidersContainer);

export default ConditionalServiceProvidersContainer;
