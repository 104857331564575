import {Field, useFormikContext} from 'formik';
import {FormikTextInput, DeprecatedButton, DisplayValue, SvgIcon, FormikPhoneInput, Title} from '@shipwell/shipwell-ui';
import {StopTypeType, StopFormValues} from './constants';
import {getAddressEntry} from './utils';

const StopTypeContactsModalDisplay = ({
  stopType,
  remove,
  handleAddNewContact
}: {
  stopType: StopTypeType;
  remove: (index: number) => void;
  handleAddNewContact: () => void;
}) => {
  const {values} = useFormikContext<StopFormValues>();
  const entry = getAddressEntry(values, stopType.id);
  const contacts = entry?.point_of_contacts || [];
  const contactsValuesPath = `${stopType.id}.point_of_contacts`;

  return (
    <div className="mb-10">
      <div className="mt-6 flex flex-row justify-between border-t border-sw-border pt-6">
        <Title variant="sectionTitle">Contact Information</Title>
        <div>
          <DeprecatedButton variant="tertiary" onClick={handleAddNewContact}>
            <SvgIcon name="Plus" height="16px" width="16px" />
            Add Additional {stopType.label} Contact
          </DeprecatedButton>
        </div>
      </div>
      {contacts.map((contact, index) => (
        <div key={contact?.id || index} className="pb-2">
          <div className="flex flex-row items-end justify-between">
            <DisplayValue label={`${stopType.label} Contact ${index + 1}`} />
            {contacts.length > 1 ? (
              <DeprecatedButton variant="icon" icon={<SvgIcon name="TrashOutlined" />} onClick={() => remove(index)} />
            ) : null}
          </div>
          <div className="grid grid-cols-2 gap-4 pb-4">
            <Field name={`${contactsValuesPath}[${index}].first_name`} label="First Name" component={FormikTextInput} />
            <Field name={`${contactsValuesPath}[${index}].last_name`} label="Last Name" component={FormikTextInput} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Field
              name={`${contactsValuesPath}[${index}].phone_number`}
              label="Phone Number"
              component={FormikPhoneInput}
            />
            <Field name={`${contactsValuesPath}[${index}].email`} label="Email" component={FormikTextInput} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StopTypeContactsModalDisplay;
