import {compose} from 'recompose';
import {TenantPicker} from 'App/components/TenantPicker';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';

const TenantPickerMenuItem = () => (
  <>
    <div className="my-2">
      <TenantPicker />
    </div>
    <hr />
  </>
);

const ConditionalTenantPickerMenuItem = compose(
  withFlags('fmMultiAccountImpersonation'),
  withConditionalFallback(
    ({fmMultiAccountImpersonation}: {fmMultiAccountImpersonation: boolean}) => !fmMultiAccountImpersonation,
    null
  )
)(TenantPickerMenuItem);

export default ConditionalTenantPickerMenuItem;
