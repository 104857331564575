import {useState} from 'react';
import {CellProps, Column} from 'react-table';
import {DeprecatedButton, Card, CollapsibleCardContent, DisplayValue, Modal} from '@shipwell/shipwell-ui';
import {RfpRowResponse, RfpRowResponseEquipmentEnum} from '@shipwell/backend-core-sdk';
import RoutingGuidePolicyDetails from 'App/containers/routingGuides/components/policyDetails';
import {FlexBox} from 'App/components/Box';
import {toCurrency} from 'App/utils/grammar';
import {toTitleCase} from 'App/utils/globals';
import {useWorkflows} from 'App/utils/hooks/useWorkflows';
import ShipwellLoader from 'App/common/shipwellLoader';
import RoutingGuideActionDetails from 'App/containers/routingGuides/components/actionDetails';

export const columnDataAlpha: (Column<RfpRowResponse> & {label?: string})[] = [
  {
    id: 'laneId',
    accessor: 'row_identifier',
    Header: 'Lane ID',
    label: 'Lane ID'
  },
  {
    id: 'originCity',
    accessor: 'origin_city',
    Header: 'Origin City',
    label: 'Origin City'
  },
  {
    id: 'originState',
    accessor: 'origin_state',
    Header: 'Origin State',
    label: 'Origin State',
    width: 125
  },
  {
    id: 'originZip',
    accessor: 'origin_postal_code',
    Header: 'Origin Zip',
    label: 'Origin Zip',
    width: 110
  },
  {
    id: 'destinationCity',
    accessor: 'destination_city',
    Header: 'Dest City',
    label: 'Dest City'
  },
  {
    id: 'destinationState',
    accessor: 'destination_state',
    Header: 'Dest State',
    label: 'Dest State',
    width: 120
  },
  {
    id: 'destinationZip',
    accessor: 'destination_postal_code',
    Header: 'Dest Zip',
    label: 'Dest Zip',
    width: 110
  },
  {
    id: 'carrierReference',
    accessor: 'carrier_reference',
    Header: 'Carrier Reference',
    label: 'Carrier Reference'
  },
  {
    id: 'carrierName',
    accessor: 'carrier_name',
    Header: 'Carrier Name',
    label: 'Carrier Name'
  },
  {
    id: 'rpm',
    accessor: 'rate_per_mile',
    Header: 'RPM',
    label: 'RPM',
    Cell: ({value}: {value?: number}) => <>{value ? toCurrency(value) : '--'}</>,
    width: 75
  },
  {
    id: 'flatRate',
    accessor: 'flat_rate',
    Header: 'Flat Rate',
    label: 'Flat Rate',
    Cell: ({value}: {value?: number}) => <>{value ? toCurrency(value) : '--'}</>,
    width: 110
  },
  {
    id: 'mode',
    accessor: 'mode',
    Header: 'Mode',
    label: 'Mode',
    width: 90
  },
  {
    id: 'equipment',
    accessor: 'equipment',
    Header: 'Equipment',
    label: 'Equipment',
    Cell: ({value}: {value?: RfpRowResponseEquipmentEnum}) => <>{toTitleCase(value)}</>,
    width: 125
  },
  {
    id: 'routingGuide',
    accessor: (row) => ({lanePolicyName: row.lane_policy_name, lanePolicyId: row.lane_policy_id}),
    Header: 'Routing Guide',
    label: 'Routing Guide',
    Cell: ({row: {original}}: CellProps<RfpRowResponse>) => {
      const [showModal, setShowModal] = useState(false);

      const id = original.lane_policy_id || '';

      const {policyQuery, workflowFormValuesQuery} = useWorkflows({isEnabled: showModal, policyId: id});

      const actions = workflowFormValuesQuery.data?.actions || [];

      const handleManageRoutingGuide = () => {
        const routingGuideUrl = `/automation/routing-guides/${id}`;
        window.open(routingGuideUrl, '_blank');
      };
      if (!original?.lane_policy_name) {
        return <>--</>;
      }
      return (
        <>
          <DeprecatedButton onClick={() => setShowModal(true)} variant="tertiary">
            {original.lane_policy_name}
          </DeprecatedButton>
          <Modal show={showModal} title="Routing Guide" footerComponent={null} onClose={() => setShowModal(false)}>
            <DisplayValue label="Routing Guide Name">{policyQuery?.data?.name}</DisplayValue>
            <RoutingGuidePolicyDetails policy={policyQuery?.data} />
            <Card title="Actions" isCollapsible={false} draggableProvided={() => undefined}>
              <CollapsibleCardContent>
                <FlexBox direction="col" gap="m">
                  {workflowFormValuesQuery.isInitialLoading ? <ShipwellLoader loading /> : null}
                  {actions?.map((action, index) => {
                    const actionType = action.type?.label ?? null;
                    const tenderToCompany = () => {
                      const involvedTenderToCompanyUser = action.involved_tender_to_company_users?.[0];
                      if (involvedTenderToCompanyUser && typeof involvedTenderToCompanyUser !== 'string') {
                        return involvedTenderToCompanyUser.carrierName;
                      }
                    };
                    const rate = toCurrency(action?.rate || 0);
                    return (
                      <Card
                        draggableProvided={undefined}
                        key={index}
                        title={
                          <span>
                            Step {index + 1} {actionType ? `- ${actionType}` : ''}{' '}
                            {tenderToCompany() ? `- ${tenderToCompany() || ''}` : ''}{' '}
                            {action.type?.value === 'TENDER' ? <span className="text-sw-success">({rate})</span> : ''}
                          </span>
                        }
                        isCollapsible
                        isCollapsed
                      >
                        <CollapsibleCardContent>
                          <RoutingGuideActionDetails action={action} />
                        </CollapsibleCardContent>
                      </Card>
                    );
                  })}
                </FlexBox>
              </CollapsibleCardContent>
            </Card>
            <FlexBox justify="end" gap="m" pad={['t', 'm']}>
              <DeprecatedButton onClick={() => setShowModal(false)} variant="secondary">
                Close
              </DeprecatedButton>
              <DeprecatedButton onClick={handleManageRoutingGuide} variant="primary">
                Manage Routing Guide
              </DeprecatedButton>
            </FlexBox>
          </Modal>
        </>
      );
    }
  }
];
