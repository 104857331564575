export const ExcessiveLengthWarning = ({isWhiteLabel}: {isWhiteLabel: boolean}) => (
  <div className="length-warning">
    <span>
      <strong className="error-text">Disclaimer:</strong> Items over 8 ft may be subject to an extra charge. While each
      carrier has different length requirements, please add an “Excessive Length” accessorial to your shipment to avoid
      unexpected charges upon tender to your carrier. Contact{' '}
      {isWhiteLabel ? 'your account representative' : <a href="mailto:support@shipwell.com">support@shipwell.com</a>}{' '}
      with questions.
    </span>
  </div>
);
