import {useMutation} from '@tanstack/react-query';
import {removePurchaseOrderFromShipment} from 'App/api/shipment/typed';

export const useRemovePurchaseOrderFromShipment = () => {
  const {mutate, mutateAsync, isLoading} = useMutation(removePurchaseOrderFromShipment);
  return {
    removePurchaseOrderFromShipment: mutate,
    removePurchaseOrderFromShipmentAsync: mutateAsync,
    isRemovingPurchaseOrderFromShipment: isLoading
  };
};
