import {useState, useEffect} from 'react';
import _ from 'lodash';
import {SearchField} from '@shipwell/shipwell-ui';
import buildPathParams from 'App/utils/buildPathParams';

/*
 * Filter Component for Search
 */

const Search = (props) => {
  const {onChange, label, name, parentValue} = props;
  const [value, setValue] = useState(parentValue || []);

  const handleSearch = (e) => {
    const term = e.target.value;
    setValue([term]);
  };

  /*
   * Reset value when parent component sends signal
   */
  useEffect(() => {
    if (parentValue && !parentValue.length && value.length) {
      setValue([]);
    }
  }, [parentValue]);

  /*
   * Trigger to update parent component
   */
  useEffect(() => {
    if (value) {
      handleSelection(value);
    }
  }, [value]);

  const handleSelection = (value) => {
    if (onChange) {
      const newValue = {};
      newValue[name] = value;
      onChange(newValue);
    }
  };

  return (
    <div className="filter__search filter__option">
      <SearchField label={label} name={name} value={value} onChange={handleSearch} />
    </div>
  );
};

export default Search;
