import {useState, useEffect} from 'react';
import moment from 'moment';
import classnames from 'classnames';
import {DatePicker, Select} from '@shipwell/shipwell-ui';
import {FuelSurchargeTableRegionEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatCurrencyValue} from 'App/utils/globals';
import {findMatchingFuelSurchargeRate} from 'App/containers/userCompany/fuelSurcharges/utils/findMatchingFuelSurchargeRate';
import {RATE_TYPE_PER_MILE, RATE_TYPE_PERCENT_OF_LINE_HAUL} from 'App/containers/userCompany/rateTables/constants';
import {FuelSurchargeTableType} from 'src/@types/fuelSurchargeTableType';

const regionOptions = [
  {id: FuelSurchargeTableRegionEnum.Us, label: 'U.S.'},
  {id: FuelSurchargeTableRegionEnum.EastCoast, label: 'East Coast (PADD1)'},
  {id: FuelSurchargeTableRegionEnum.NewEngland, label: 'New England (PADD1A)'},
  {id: FuelSurchargeTableRegionEnum.CentralAtlantic, label: 'Central Atlantic (PADD1B)'},
  {id: FuelSurchargeTableRegionEnum.LowerAtlantic, label: 'Lower Atlantic (PADD1C)'},
  {id: FuelSurchargeTableRegionEnum.Midwest, label: 'Midwest (PADD2)'},
  {id: FuelSurchargeTableRegionEnum.GulfCoast, label: 'Gulf Coast (PADD3)'},
  {id: FuelSurchargeTableRegionEnum.RockyMountain, label: 'Rocky Mountain (PADD4)'},
  {id: FuelSurchargeTableRegionEnum.WestCoast, label: 'West Coast (PADD5)'},
  {id: FuelSurchargeTableRegionEnum.WestCoastLessCalifornia, label: 'West Coast Less California'},
  {id: FuelSurchargeTableRegionEnum.California, label: 'California'}
];

const DOERateHistory = ({
  fuelSurchargeTable,
  handleDoeRateChange,
  setIsFetchingRates
}: {
  fuelSurchargeTable: FuelSurchargeTableType;
  handleDoeRateChange?: (rate: number) => void;
  setIsFetchingRates?: (isFetching: boolean) => void;
}) => {
  const [matchingDoeRate, setMatchingDoeRate] = useState<null | number>(null);
  const [matchingFuelSurchageRate, setMatchingFuelSurchargeRate] = useState<null | string>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedRegion, setSelectedRegion] = useState(
    regionOptions.find((region) => region.id === fuelSurchargeTable?.region) || regionOptions[0]
  );

  useEffect(() => {
    if (!handleDoeRateChange) {
      return;
    }
    if (matchingDoeRate) {
      handleDoeRateChange(matchingDoeRate);
    }
  }, [matchingDoeRate, handleDoeRateChange]);

  useEffect(() => {
    const fetchDoeRateHistory = () => {
      setIsFetchingRates?.(true);
      setMatchingDoeRate(fuelSurchargeTable?.current_us_dept_of_energy_per_gallon_rate);

      // sets the matching surcharge based on the supplied Fuel Surcharge table calculation rows
      const matchingFuelSurchargeRate = findMatchingFuelSurchargeRate({
        fuelSurchargeTable
      });

      if (matchingFuelSurchargeRate) {
        setMatchingFuelSurchargeRate(formatCurrencyValue(matchingFuelSurchargeRate, 3));
        setIsFetchingRates?.(false);
      } else {
        setMatchingFuelSurchargeRate(null);
        setIsFetchingRates?.(false);
      }
    };

    fetchDoeRateHistory();
  }, [selectedRegion.id, selectedDate, fuelSurchargeTable, setIsFetchingRates]);

  return (
    <>
      <div className="mb-2 grid grid-cols-2 gap-2">
        <Select
          label="Region"
          options={regionOptions}
          onChange={(region: {id: FuelSurchargeTableRegionEnum; label: string}) => setSelectedRegion(region)}
          value={selectedRegion}
          clearable={false}
          // we'll display the field, but not let user change its value (https://shipwell.atlassian.net/browse/TITAN-3919?focusedCommentId=88284)
          disabled
        />
        <DatePicker
          label="date"
          showTimeSelect={false}
          defaultValue={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          // we'll display the field, but not let user change its value (https://shipwell.atlassian.net/browse/TITAN-3919?focusedCommentId=88284)
          disabled
        />
      </div>
      <div className="mb-2 rounded bg-sw-background px-6 py-3">
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-2 font-bold">DOE Rate</div>
          <div className="mb-2 font-bold">
            Surcharge ({fuelSurchargeTable?.rate_type === RATE_TYPE_PER_MILE ? 'Per Mile' : 'Percent of Line Haul'})*
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div
            className={classnames('text-3xl font-bold ', {
              'text-sw-form-helper-text': !matchingDoeRate,
              'text-sw-success': matchingDoeRate
            })}
          >
            {matchingDoeRate ? `$${formatCurrencyValue(matchingDoeRate, 3)}` : '--'}
          </div>
          <div
            className={classnames('text-3xl font-bold', {
              'text-sw-form-helper-text': !matchingDoeRate,
              'text-sw-success': matchingDoeRate
            })}
          >
            {matchingFuelSurchageRate
              ? `${fuelSurchargeTable?.rate_type === RATE_TYPE_PER_MILE ? '$' : ''}${formatCurrencyValue(
                  matchingFuelSurchageRate,
                  3
                )}${fuelSurchargeTable?.rate_type === RATE_TYPE_PERCENT_OF_LINE_HAUL ? '%' : ''}`
              : '--'}
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <span className="text-xs italic text-sw-form-helper-text">Prices include all taxes</span>
        </div>
      </div>
      <span className="text-xs italic text-sw-form-helper-text">
        {fuelSurchargeTable?.rate_type === RATE_TYPE_PER_MILE
          ? '*Shipment must have total distance in order to calculate line item'
          : '*A line haul line item is required to calculate fuel surcharge'}
      </span>
    </>
  );
};

export default DOERateHistory;
