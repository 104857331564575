import {CONTRACT_RATE_TABLE_LANES_KEY} from '../queryKeys';
import {getContractLanes} from 'App/api/contracts/typed';
import useInfiniteScroll, {PaginationParams} from 'App/utils/hooks/useInfiniteScroll';

export const useGetContractLanes = ({contractId, pageSize = 10}: {contractId?: string | null; pageSize?: number}) => {
  const {handleScroll, pages, isFetchingNextPage, isLoading} = useInfiniteScroll(
    [CONTRACT_RATE_TABLE_LANES_KEY, contractId],
    ({page, pageSize}: PaginationParams) => {
      if (!contractId) {
        throw new Error('contractId is required');
      }
      return getContractLanes({contractId, page, pageSize});
    },
    {
      enabled: !!contractId,
      onError: (error) => console.error(error)
    },
    pageSize
  );

  return {
    handleScroll,
    pages,
    isFetchingNextPage,
    isLoading,
    hasContractLanes: pages?.[0]?.results && pages?.[0]?.results?.length > 0
  };
};
