import {AvailabilityWindow, FacilityDockAppointmentRule, LoadType} from '@shipwell/tempus-sdk';

import {RankedAvailabilityWindow, RankedMappedDockType, TimezoneAwareDateTime} from '../types';

import {
  chunkTimezoneAwareDateTimeRange,
  cmpTimezoneAwareDateTime,
  formatParts,
  maxTimezoneAwareDateTime,
  minTimezoneAwareDateTime
} from '.';

import {MappedDockType} from 'App/data-hooks/facilities/types';
import {convertISO8601ToMinutes, timeZoneAwareParse} from 'App/utils/dateTimeGlobalsTyped';
import {cmp} from 'App/utils/cmp';
import {arrayIndexOf} from 'App/utils/betterTypedArrayMethods';
import {DefaultAppointmentDurationMinutes} from 'App/containers/appointments/constants';

type WindowBag = {
  startDate: TimezoneAwareDateTime;
  endDate: TimezoneAwareDateTime;
  rule: FacilityDockAppointmentRule;
  loadType?: LoadType;
  dock: RankedMappedDockType;
  rank: number;
};

export type ComputeAvailabilityWindowsArg = {
  timezone: string;
  facilityOpens: string;
  facilityCloses: string;
  loadTypes: LoadType[];
  docks: MappedDockType[];
  matchedLoadTypeIds: string[];
  matchedDockIds: string[];
  rawWindows: AvailabilityWindow[];
  requiredDockId?: string;
  requiredLoadTypeId?: string;
  supplierAppointmentDuration?: string;
  now: Date;
};

export function computeAvailabilityWindows(args: ComputeAvailabilityWindowsArg): {
  windows: RankedAvailabilityWindow[];
  docks: RankedMappedDockType[];
  isAllDay: boolean;
} {
  const {
    timezone,
    facilityOpens,
    facilityCloses,
    matchedLoadTypeIds,
    matchedDockIds,
    rawWindows,
    docks,
    loadTypes,
    requiredDockId,
    requiredLoadTypeId,
    supplierAppointmentDuration,
    now
  } = args;

  const rankedDocks: RankedMappedDockType[] = rankDocks(docks, requiredDockId, matchedDockIds, matchedLoadTypeIds);

  const isAllDay =
    (supplierAppointmentDuration === 'All Day' ||
      loadTypes.find((lt) => lt.id === matchedLoadTypeIds[0])?.all_day_appointment) ??
    false;

  const futureWindows = rawWindows.flatMap((rawWindow) => {
    const start = timeZoneAwareParse(rawWindow.start.timestamp, rawWindow.start.timezone);
    const end = timeZoneAwareParse(rawWindow.end.timestamp, rawWindow.end.timezone);
    if (end <= now) {
      return [];
    }
    if (start < now) {
      return [
        {
          ...rawWindow,
          start: {
            timestamp: now.toISOString(),
            timezone: rawWindow.start.timezone
          }
        }
      ];
    }
    return [rawWindow];
  });

  const windowsBags: WindowBag[] = splitByRulesAndRankWindows(
    futureWindows,
    timezone,
    rankedDocks,
    loadTypes,
    requiredLoadTypeId,
    facilityOpens,
    facilityCloses,
    matchedDockIds
  );

  const windows: RankedAvailabilityWindow[] = splitWindowsByDuration(
    windowsBags,
    supplierAppointmentDuration,
    timezone
  );

  return {
    windows,
    docks: rankedDocks,
    isAllDay
  };
}

function splitWindowsByDuration(
  windowsBags: WindowBag[],
  supplierAppointmentDuration: string | undefined,
  timezone: string
): RankedAvailabilityWindow[] {
  return windowsBags.flatMap((bag): RankedAvailabilityWindow[] => {
    if (bag.loadType?.all_day_appointment || supplierAppointmentDuration === 'All Day') {
      return [
        {
          startDate: bag.startDate,
          endDate: bag.endDate,
          dockId: bag.dock.id,
          loadTypeId: bag.loadType?.id,
          rank: bag.rank,
          isAllDay: true
        }
      ];
    }

    let splitMinutes = DefaultAppointmentDurationMinutes;
    if (supplierAppointmentDuration) {
      splitMinutes = convertISO8601ToMinutes(supplierAppointmentDuration);
    } else if (bag.loadType?.appointment_duration) {
      splitMinutes = convertISO8601ToMinutes(bag.loadType.appointment_duration);
    }
    const start = new Date(bag.startDate.original);
    start.setSeconds(0);
    start.setMilliseconds(0);
    const minutes0 = start.getMinutes();
    if (minutes0 % 15 !== 0) {
      start.setMinutes(minutes0 + (15 - (minutes0 % 15)));
    }
    const windows = chunkTimezoneAwareDateTimeRange(formatParts(start, timezone), bag.endDate, timezone, splitMinutes);
    return windows.map(({start, end}) => ({
      startDate: start,
      endDate: end,
      dockId: bag.dock.id,
      loadTypeId: bag.loadType?.id,
      rank: bag.rank,
      isAllDay: false
    }));
  });
}

function splitByRulesAndRankWindows(
  rawWindows: AvailabilityWindow[],
  timezone: string,
  rankedDocks: RankedMappedDockType[],
  loadTypes: LoadType[],
  requiredLoadTypeId: string | undefined,
  facilityOpens: string,
  facilityCloses: string,
  matchedDockIds: string[]
): WindowBag[] {
  return rawWindows.flatMap((w) => {
    // For each "raw" window, we will emit either zero window "bags" or one
    // for each distinct dock rule match.
    if (!w.dock_id) {
      // we can't currently handle an availability window with no dock
      return [];
    }

    // First turn the window's time bounds into the one representation we have good utilities for.
    const start = formatParts(w.start.timestamp, timezone);
    const end = formatParts(w.end.timestamp, timezone);
    // find the dock
    const dock = rankedDocks.find((d) => d.id === w.dock_id);
    // We do not bother about windows without docks or without matching docks at all
    if (!dock) {
      return [];
    }
    /** The day of the window. We assume windows can NOT span multiple days. */
    const day = formatParts(w.start.timestamp, timezone).date;

    return dock.dockRules.flatMap((rule) => {
      // For each dock rule, if the load type is acceptable, yield the
      // intersection of the window and the dock rule's time span.
      const loadType = loadTypes.find((loadType) => loadType.id === rule.load_type_id);
      let loadTypeRank = arrayIndexOf(loadTypes, loadType);
      if (loadTypeRank < 0) loadTypeRank = loadTypes.length;

      if (requiredLoadTypeId && loadType?.id !== requiredLoadTypeId) {
        return [];
      }
      const ruleStart = formatParts(`${day}T${rule.first_appointment_start_time ?? facilityOpens}`, timezone);
      const ruleEnd = formatParts(`${day}T${rule.last_appointment_end_time ?? facilityCloses}`, timezone);
      // If the rule's time span does not intersect the window then bail.
      if (cmpTimezoneAwareDateTime(ruleStart, end) > 0 || cmpTimezoneAwareDateTime(start, ruleEnd) > 0) {
        return [];
      }
      // Otherwise compute the intersection
      const startDate = maxTimezoneAwareDateTime(start, ruleStart);
      const endDate = minTimezoneAwareDateTime(end, ruleEnd);
      // If the intersection is of zero length, bail.
      if (startDate.original >= endDate.original) {
        return [];
      }
      // prefer preferred load types and fall back to preferred docks.
      const rank = loadTypeRank * matchedDockIds.length + dock.rank;

      return [
        {
          startDate,
          endDate,
          rule,
          dock,
          loadType,
          rank
        }
      ];
    });
  });
}

function rankDocks(
  docks: MappedDockType[],
  requiredDockId: string | undefined,
  matchedDockIds: string[],
  matchedLoadTypeIds: string[]
): RankedMappedDockType[] {
  return docks
    .flatMap((dock) => {
      if (requiredDockId && dock.id !== requiredDockId) {
        return [];
      }
      const rank = matchedDockIds.indexOf(dock.id);
      if (rank < 0) {
        return [];
      }
      const rules = dock.dockRules.filter(
        (rule) => !rule.load_type_id || matchedLoadTypeIds.includes(rule.load_type_id)
      );
      if (rules.length === 0) {
        return [];
      }
      return [
        {
          ...dock,
          dockRules: rules,
          rank
        }
      ];
    })
    .sort((a, b) => a.rank - b.rank);
}

/**
 * Returns the best ranked windows with unique start times, because we don't
 * want to show duplicate appointment times to the user.
 */
export function uniqueAvailabilityWindows(windowsIn: RankedAvailabilityWindow[]): RankedAvailabilityWindow[] {
  return windowsIn
    .slice()
    .sort((a, b) => cmpTimezoneAwareDateTime(a.startDate, b.startDate) || cmp(a.rank, b.rank))
    .filter((w, i, a) => i === 0 || cmpTimezoneAwareDateTime(w.startDate, a[i - 1].startDate) > 0);
}
