import {
  DrayageLegCarrierViewCard,
  DrayageShipmentCarrierViewCard
} from 'App/containers/shipments/components/CarriersView';
import RepsView from 'App/containers/shipments/components/RepsView';
import {FullReferences} from 'App/containers/shipments/components/ReferencesView';
import {LegView} from 'App/containers/shipments/drayage/components/LegDetailsView';
import {MutableContainersTable} from 'App/containers/shipments/drayage/components/ContainersView';
import ContainerView from 'App/containers/shipments/drayage/components/ContainerView';
import {LegStopsView, ShipmentStopsView} from 'App/containers/shipments/components/StopsView';
import {LegLineItemsView} from 'App/containers/shipments/drayage/components/ProductLineItems';
import TendersView from 'App/containers/shipments/components/TendersView';
import {ContainerSelectionFooterWithRouter} from 'App/containers/shipments/components/Footer';
import {ShipmentTrackingOverview, LegTrackingOverview} from 'App/components/ShipmentTrackingOverview';
import FinancialsView from 'App/containers/shipments/components/FinancialsView';

export const REFERENCES_VIEW = 'VIEW_REFERENCES';
export const LEG_VIEW = 'VIEW_LEG';
export const REPS_VIEW = 'VIEW_REPS';
export const CONTAINER_VIEW = 'VIEW_CONTAINER';
export const DRAYAGE_SHIPMENT_CARRIER_VIEW = 'VIEW_DRAYAGE_SHIPMENT_CARRIER_AND_EQUIPMENT';
export const DRAYAGE_LEG_CARRIER_VIEW = 'VIEW_DRAYAGE_LEG_CARRIER_AND_EQUIPMENT';
export const DRAYAGE_SHIPMENT_HEADER = 'SHIPMENT_HEADER';
export const DRAYAGE_LEG_HEADER = 'LEG_HEADER';

export const drayageLegCardSchema = [{id: CONTAINER_VIEW}, {id: DRAYAGE_LEG_CARRIER_VIEW}];
export const drayageShipmentCardSchema = [
  {id: DRAYAGE_SHIPMENT_CARRIER_VIEW},
  {id: LEG_VIEW},
  {id: REFERENCES_VIEW},
  {id: REPS_VIEW}
];

export const detailsComponentMap = {
  [REFERENCES_VIEW]: {
    view: FullReferences
  },
  [LEG_VIEW]: {
    view: LegView
  },
  [REPS_VIEW]: {
    view: RepsView
  },
  [CONTAINER_VIEW]: {
    view: ContainerView
  },
  [DRAYAGE_SHIPMENT_CARRIER_VIEW]: {
    view: DrayageShipmentCarrierViewCard
  },
  [DRAYAGE_LEG_CARRIER_VIEW]: {
    view: DrayageLegCarrierViewCard
  }
};

export const drayageShipmentTabSchema = [
  {title: 'Stop Details', components: [ShipmentStopsView]},
  {title: 'Containers', components: [MutableContainersTable]},
  {title: 'Tenders', components: [TendersView]},
  {title: 'Tracking', components: [ShipmentTrackingOverview]},
  {title: 'Financials', components: [FinancialsView]}
];

export const drayageShipmentTabRoutes = drayageShipmentTabSchema.map((tab) => tabNameToRoute(tab.title));

export function tabNameToRoute(tabName) {
  return tabName.toLowerCase().replace(/[^a-zA-Z0-9]/, '-');
}

export const drayageLegTabSchema = [
  {title: 'Details', components: [LegStopsView, LegLineItemsView]},
  {title: 'Tracking', components: [LegTrackingOverview]}
];

export const drayageLegTabRoutes = drayageLegTabSchema.map((tab) => tabNameToRoute(tab.title));

const entityTypeToSchemaMap = {
  DRAYAGE_SHIPMENT: {
    card: drayageShipmentCardSchema,
    tabs: drayageShipmentTabSchema
  },
  DRAYAGE_LEG: {card: drayageLegCardSchema, tabs: drayageLegTabSchema, footer: ContainerSelectionFooterWithRouter}
};

export const entityTypes = {DRAYAGE_SHIPMENT: 'DRAYAGE_SHIPMENT', DRAYAGE_LEG: 'DRAYAGE_LEG'};

export const getSchemaByEntityType = (entityType) => entityTypeToSchemaMap[entityType];
