import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {CarrierSpecificShipperRelationship, ShipwellError} from '@shipwell/backend-core-sdk';
import {getBrokerShipperRelationshipCarriers} from 'App/api/brokers/typed';
import {SHIPPER_RELATIONSHIP_CARRIERS} from 'App/data-hooks/queryKeys';

export const useGetBrokerShipperRelationshipCarriers = ({
  brokerageId,
  shipperRelationshipId,
  options = {}
}: {
  brokerageId: string;
  shipperRelationshipId: string;
  options?: UseQueryOptions<CarrierSpecificShipperRelationship[], ShipwellError>;
}) => {
  const getBrokerShipperRelationshipCarriersQuery = useQuery(
    [SHIPPER_RELATIONSHIP_CARRIERS, brokerageId, shipperRelationshipId] as QueryKey,
    async () => {
      const response = await getBrokerShipperRelationshipCarriers({brokerageId, shipperRelationshipId});
      return response;
    },
    {...options}
  );

  const {data: brokerShipperRelationshipCarriers} = getBrokerShipperRelationshipCarriersQuery;

  return {getBrokerShipperRelationshipCarriersQuery, brokerShipperRelationshipCarriers};
};
