import isNil from 'lodash/isNil';
import {validateNumber, validateDollarValue} from 'App/utils/globals';

export const validation = (values) => {
  const errors = {};

  if (!values.product_ref) {
    errors.product_ref = 'Reference is required';
  }
  if (!values.description) {
    errors.description = 'Description is required';
  }
  if (values.value_per_piece && !validateDollarValue(values.value_per_piece)) {
    errors.value_per_piece = 'Enter a valid dollar value';
  }
  if (values.package_weight && !validateNumber(values.length)) {
    errors.length = 'Enter a valid number';
  }
  if (values.length && !validateNumber(values.length)) {
    errors.length = 'Enter a valid number';
  }
  if (values.width && !validateNumber(values.width)) {
    errors.width = 'Enter a valid number';
  }
  if (values.height && !validateNumber(values.height)) {
    errors.height = 'Enter a valid number';
  }
  if (values.hazmat === true && !values.hazmatDetails) {
    errors.hazmatDetails = 'Enter hazmat details';
  }
  if (values.refrigeration_required && isNil(values.refrigeration_min_temp)) {
    errors.refrigeration_min_temp = 'Enter minimum temperature';
  }
  if (values.refrigeration_required && isNil(values.refrigeration_max_temp)) {
    errors.refrigeration_max_temp = 'Enter maximum temperature';
  }
  if (parseFloat(values.refrigeration_min_temp) > parseFloat(values.refrigeration_max_temp)) {
    errors.refrigeration_max_temp = 'Upper temperature must be greater than lower temperature.';
  }
  return errors;
};
