import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Formik, Form, FieldArray} from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {array, object, number, string} from 'yup';
import parseMultipleRequestErrors from 'App/utils/parseMultipleRequestErrors';
import {fetchReps, postRep, putRep, deleteRep} from 'App/actions/_shipmentDetails';
import EditActionButtons from 'App/containers/Shipment/components/EditActionButtons';
import EditErrorDisplay from 'App/containers/Shipment/components/EditErrorDisplay';
import {RepsFields} from 'App/containers/shipments/components/RepsFields';

const validationSchema = object().shape({
  reps: array().of(
    object().shape({
      role: number().nullable().required('A role is required.'),
      user: string().nullable().required('A Rep name is required.')
    })
  )
});

const Edit = ({
  companyUsers,
  shipment,
  onSuccessEdit,
  reps,
  postRep,
  putRep,
  fetchReps,
  deleteRep,
  shipmentRepRoles,
  ...props
}) => {
  const shipmentId = shipment.id;

  useEffect(() => {
    if (isEmpty(companyUsers)) {
      fetchReps(shipmentId);
    }
  }, [companyUsers, fetchReps, shipmentId]);

  const handleSubmit = async (values, {setSubmitting, setErrors}) => {
    const updateOrCreatePromises = get(values, 'reps', []).map((rep) =>
      rep.id ? putRep(shipmentId, rep.id, rep) : postRep(shipmentId, rep)
    );

    const repsToDelete = reps.filter(
      (originalRep) => !get(values, 'reps', []).find((rep) => originalRep.id === rep.id)
    );

    const deletePromises = repsToDelete.map((repToDelete) => deleteRep(shipmentId, repToDelete.id));

    const responses = await Promise.allSettled([...updateOrCreatePromises, ...deletePromises]);
    const [fieldErrors, errorDescription] = parseMultipleRequestErrors(
      // Promise.allSettled wraps all repsonses in {status: fulfilled, value: <response>}
      // `status` can be `rejected` but that currently will not happen with the way we handle errors
      // in our action creators
      responses.map((response) => response.value),
      'Error saving Reps'
    );
    if (!isEmpty(errorDescription)) {
      setErrors({reps: fieldErrors, _error: errorDescription});
    } else {
      onSuccessEdit();
    }
    setSubmitting(false);
    return fetchReps(shipmentId);
  };

  return (
    <div className="shipment__edit">
      <Formik initialValues={{reps}} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {(FormikProps) => (
          <Form>
            <FieldArray name="reps">
              {(arrayHelpers) => (
                <RepsFields
                  shipment={shipment}
                  companyUsers={companyUsers}
                  shipmentRepRoles={shipmentRepRoles.map(({name, id}) => ({label: name, name, value: id}))}
                  {...FormikProps}
                  {...arrayHelpers}
                />
              )}
            </FieldArray>

            <EditActionButtons {...props} {...FormikProps} />

            <EditErrorDisplay {...FormikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

Edit.propTypes = {
  reps: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.number,
      user: PropTypes.string
    })
  ),
  shipment: PropTypes.object,
  onSuccessEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  postRep: PropTypes.func,
  putRep: PropTypes.func,
  fetchReps: PropTypes.func,
  deleteRep: PropTypes.func
};

export default connect(
  (state) => ({
    companyUsers: state.users.companyUsers,
    reps: get(state, 'shipmentdetails.reps.results', []),
    shipmentRepRoles: state.shipmentdetails.shipmentRepRoles,
    shipment: state.shipmentdetails.one
  }),
  {
    fetchReps,
    postRep,
    putRep,
    deleteRep
  }
)(Edit);
