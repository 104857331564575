import {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Button} from 'react-bootstrap';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import {Document, Page} from 'react-pdf';
import {Title} from '@shipwell/shipwell-ui';
import renderEmailMultiselect from 'App/formComponents/renderEmailMultiselect';
import {formatCurrencyValue} from 'App/utils/globals';
import {startCaseToLower} from 'App/utils/startCaseToLower';

class ShipmentEmailInvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {customerEmails: []};
  }

  componentDidMount() {
    const {customer} = this.props.customerAssignment;
    // check length of non null billing emails
    if (customer?.billing_emails?.filter((email) => email).length > 0) {
      this.setState({customerEmails: customer.billing_emails});
    } else if (customer.primary_email) {
      this.setState({customerEmails: [customer.primary_email]});
    }
  }

  splitAddress(address) {
    return address.split(/,(.+)/, 2);
  }

  render() {
    const {handleSubmit, submitting, error, invoice, company, customerAssignment, configuredFms} = this.props;
    const {customerEmails = []} = this.state;
    const emailOptions = customerEmails
      .filter((email) => email) // filter out any falsy values before populating form
      .map((email) => {
        return {value: email, label: email};
      });
    const customer = customerAssignment.customer;
    let mailingAdd = [];
    let billingAdd = [];
    if (company && company.mailing_address && company.mailing_address.formatted_address) {
      mailingAdd = this.splitAddress(company.mailing_address.formatted_address);
    }
    if (company && company.billing_address && company.billing_address.formatted_address) {
      billingAdd = this.splitAddress(company.billing_address.formatted_address);
    }
    return (
      <Form onSubmit={handleSubmit}>
        <div className="shipment__invoice-email">
          <div className="shipment__invoice-email-preview">
            <div className="shipment__invoice-email-preview-mailing">
              <div>
                <b>{company.dba_name}</b>
              </div>
              {mailingAdd.length > 1 ? (
                <Fragment>
                  <div>{mailingAdd[0]}</div>
                  <div>{mailingAdd[1]}</div>
                </Fragment>
              ) : mailingAdd.length === 1 ? (
                <div>{mailingAdd[0]}</div>
              ) : (
                ''
              )}

              <div>{company.primary_email}</div>
            </div>
            <div className="shipment__invoice-email-preview-header">
              <h3>Invoice</h3>
            </div>
            <div className="shipment__invoice-email-preview-billing">
              <div>
                <div>
                  <b>Bill To</b>
                </div>
                {billingAdd.length > 1 ? (
                  <Fragment>
                    <div>{billingAdd[0]}</div>
                    <div>{billingAdd[1]}</div>
                  </Fragment>
                ) : billingAdd.length === 1 ? (
                  <div>{billingAdd[0]}</div>
                ) : (
                  ''
                )}{' '}
                <div>{customer.primary_email}</div>
              </div>
              <div>
                <div>
                  <b>Invoice # </b>
                  {invoice && invoice.invoice_number}
                </div>
                <div>
                  <b>Date </b>
                  {invoice && moment(invoice.created_at).format('MM/DD/YYYY')}
                </div>
              </div>
            </div>
            <div className="shipment__invoice-email-preview-lineItems">
              {invoice && invoice.content_object && (
                <Fragment>
                  {invoice.content_object.bol_number && (
                    <p>
                      <b>BOL# </b>
                      {invoice.content_object.bol_number}
                    </p>
                  )}
                  {invoice.content_object.pro_number && (
                    <p>
                      <b>PRO# </b>
                      {invoice.content_object.pro_number}
                    </p>
                  )}
                  {invoice.content_object.pickup_number && (
                    <p>
                      <b>Pickup# </b>
                      {invoice.content_object.pickup_number}
                    </p>
                  )}
                  {invoice.content_object.purchase_order_number && (
                    <p>
                      <b>PO# </b>
                      {invoice.content_object.purchase_order_number}
                    </p>
                  )}
                  {invoice.content_object.customer_reference_number && (
                    <p>
                      <b>Customer Reference # </b>
                      {invoice.content_object.customer_reference_number}
                    </p>
                  )}
                </Fragment>
              )}
              <div>
                <Grid container spacing={8} className="shipment__invoice-email-preview-lineItems-header">
                  <Grid item sm={4}>
                    Line Items
                  </Grid>
                  <Grid item sm={2}>
                    Qty
                  </Grid>
                  <Grid item sm={3}>
                    Rate
                  </Grid>
                  <Grid item sm={3}>
                    Cost
                  </Grid>
                </Grid>
                {invoice &&
                  invoice.line_items &&
                  invoice.line_items.length > 0 &&
                  invoice.line_items.map((el) => (
                    <Grid container key={el.id} spacing={8} className="shipment__invoice-email-preview-lineItems-item">
                      <Grid item sm={4}>
                        {el.unit_name}
                      </Grid>
                      <Grid item sm={2}>
                        {el.unit_quantity}
                      </Grid>
                      <Grid item sm={3}>
                        ${formatCurrencyValue(el.unit_amount)}
                      </Grid>
                      <Grid item sm={3}>
                        ${formatCurrencyValue(el.amount)}
                      </Grid>
                    </Grid>
                  ))}
                <div className="shipment__invoice-email-preview-lineItems-total">
                  Balance Due: ${invoice ? formatCurrencyValue(invoice.balance) : 0}
                </div>
              </div>
            </div>
            {invoice && invoice.documents && invoice.documents.length > 0 && (
              <div className="shipment__invoice-email-preview-docs">
                {invoice.documents.map((el) => {
                  return (
                    <div key={el.id} className="shipment__invoice-email-preview-docs-doc">
                      {el.content_object.filename.indexOf('.pdf') === -1 && (
                        <img
                          src={el.content_object.file}
                          alt="shipment-document"
                          className="shipment__invoice-email-preview-docs-docImg"
                        />
                      )}
                      {el.content_object.filename.indexOf('.pdf') !== -1 && (
                        <Document className="shipment__invoice-email-preview-docs-docImg" file={el.content_object.file}>
                          <Page pageNumber={1} width={50} />
                        </Document>
                      )}
                      <div className="shipment__invoice-email-preview-docs-docName">{el.content_object.filename}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="shipment__invoice-email-form">
            {configuredFms?.[0]?.name && (
              <div>
                <Title variant="formTitle">
                  Your invoice was created and sent to {startCaseToLower(configuredFms[0].name)}!
                </Title>
              </div>
            )}
            <div>Enter an email recipient to send a copy of the invoice</div>
            <Field
              req
              label="Emails"
              name="emails"
              component={renderEmailMultiselect}
              placeholder="Emails"
              defaultValue={emailOptions}
              allowNew
            />
          </div>
        </div>
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
        <div className="shipment__invoice-email-footer">
          <Button bsStyle="primary" type="submit" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" /> Sending...
              </span>
            ) : (
              'Send'
            )}
          </Button>
        </div>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.emails) {
    errors.emails = 'Email is required.';
  }

  if (values.emails) {
    // quickbooks limits the number of emails to 3, and the total char length to 100
    if (Array.isArray(values.emails) && values.emails.length > 3) {
      errors.emails = 'A maximum of 3 emails is allowed.';
    } else if (Array.isArray(values.emails) && values.emails.length < 1) {
      errors.emails = 'Email is required.';
    } else if (typeof values.emails === 'string' && values.emails.length > 100) {
      errors.emails = 'Email is too long.';
    } else if (Array.isArray(values.emails) && checkCharacterLength(values.emails) > 100) {
      errors.emails = 'A maximum of 100 characters is allowed.';
    }
  }
  return errors;
};

const checkCharacterLength = (emails) => {
  return emails.reduce((acc, email) => (email.value ? acc + email.value.length : acc), 0);
};

ShipmentEmailInvoiceForm = reduxForm({
  form: 'shipmentEmailInvoiceForm',
  validate: validate,
  enableReinitialize: true
})(ShipmentEmailInvoiceForm);

ShipmentEmailInvoiceForm = connect((state, ownProps) => {
  const {customer} = ownProps?.customerAssignment;
  const defaultEmails =
    customer?.billing_emails.length > 0
      ? customer.billing_emails
      : customer?.primary_email
      ? [customer.primary_email]
      : [];

  return {
    initialValues: {
      emails: defaultEmails.map((email) => {
        return {value: email, label: email};
      })
    }
  };
})(ShipmentEmailInvoiceForm);

export default ShipmentEmailInvoiceForm;
