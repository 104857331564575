import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';

import useDeleteLoadboard from 'App/api/loadboards/hooks/useDeleteLoadboard';
import {DAT} from 'App/containers/integrations/details/components/datDetails/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';

type DisconnectIntegrationModalProps = {
  show: boolean;
  toggleShow: () => void;
  setIsActive: (val: boolean) => void;
};

const DisconnectIntegrationModal = ({
  show,
  toggleShow,
  setIsActive,
  setSuccess,
  setError
}: DisconnectIntegrationModalProps & WithStatusToastProps) => {
  const {mutate: disconnectDat, isLoading} = useDeleteLoadboard({
    onSuccess: () => {
      toggleShow();
      setIsActive(false);
      setSuccess('Success!', 'You have disconnected DAT integration.');
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const handleDisconnect = () => {
    disconnectDat({loadboardType: DAT});
  };

  return (
    <Modal
      show={show}
      className="top-1/2 mt-0 -translate-y-2/4"
      size="small"
      title="Disconnect DAT integration"
      variant="warning"
      onClose={toggleShow}
      onPrimaryAction={handleDisconnect}
      footerComponent={null}
      bodyClassName={classNames({'pointer-events-none': isLoading})}
    >
      <>
        <div className="mb-5">Are you sure you want to disconnect DAT integration?</div>
        <div className="flex justify-end gap-5 pt-5">
          <DeprecatedButton type="button" variant="secondary" onClick={toggleShow} className="w-full max-w-[150px]">
            No, go back
          </DeprecatedButton>
          <DeprecatedButton type="button" variant="warning" onClick={handleDisconnect} className="w-full max-w-[150px]">
            {isLoading ? <SvgIcon name="LoadingDots" /> : 'Yes, disconnect'}
          </DeprecatedButton>
        </div>
      </>
    </Modal>
  );
};

export default WithStatusToasts(DisconnectIntegrationModal);
