import {Card} from '@shipwell/shipwell-ui';
import WithStatusToasts from 'App/components/withStatusToasts';
import TriumphPayMappingForm from 'App/formComponents/forms/triumphPayMapping';

const TriumphPayDetails = () => {
  return (
    <div className="integration-details-content">
      <Card
        title={
          <div className="integration-details-card-title">
            <img alt="integration-logo" className="integration-logo" src="/images/integration-triumphpay.png" />
            <span>Triumph Pay</span>
          </div>
        }
        draggableProvided={null}
      >
        <TriumphPayMappingForm />
      </Card>
    </div>
  );
};

export default WithStatusToasts(TriumphPayDetails);
