import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {object} from 'yup';
import get from 'lodash/get';
import {useCarrierDetails} from 'App/data-hooks';
import {fuelSurchargeRateTableBuilderSchemaFields} from 'App/containers/userCompany/fuelSurcharges/validation';
import FuelSurchargeRateTableBuilder from 'App/containers/userCompany/fuelSurcharges/createForm/rateTableBuilder';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {makeCarrierOption} from 'App/containers/userCompany/fuelSurcharges/utils';
import {initializeDoeTable} from 'App/containers/userCompany/fuelSurcharges/utils/utils';

const FuelSurchargeEditRateTableForm = ({fuelSurchargeTable, onSubmit, onCancel}) => {
  const carrierDetails = useCarrierDetails(get(fuelSurchargeTable, 'carrier_relationships', []));

  const initialValues = {
    ...fuelSurchargeTable,
    calculation_rows: initializeDoeTable(fuelSurchargeTable),
    carrier_relationships: get(fuelSurchargeTable, 'carrier_relationships', []).map((carrierId) =>
      makeCarrierOption(get(carrierDetails, carrierId, {}))
    )
  };

  return (
    <Formik
      validationSchema={object().shape({
        ...fuelSurchargeRateTableBuilderSchemaFields
      })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form noValidate>
          <FuelSurchargeRateTableBuilder values={formikProps.values} />
          <ModalFormFooter {...formikProps} onCancel={onCancel} primaryActionName={'Save Table'} />
        </Form>
      )}
    </Formik>
  );
};

FuelSurchargeEditRateTableForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  fuelSurchargeTable: PropTypes.shape({
    id: PropTypes.string
  })
};

FuelSurchargeEditRateTableForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  fuelSurchargeTable: {
    id: null
  }
};

export default FuelSurchargeEditRateTableForm;
