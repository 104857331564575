import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';
import printJS from 'print-js';
import {Modal, DeprecatedButton, Toast} from '@shipwell/shipwell-ui';
import {getShipmentsPromise} from 'App/api/shipment';
import {
  getShipmentPickup,
  updateShipmentPickup,
  cancelShipmentPickup,
  getBulkShipmentLabels,
  createShipmentPickup
} from 'App/containers/fedex/actions/async';
import SWTable from 'App/components/swTable';
import PageHeader from 'App/common/pageHeader';
import SideNav from 'App/common/sideNav';
import SchedulePickupForm from 'App/formComponents/forms/schedulePickup';
import {unpackErrors, cleanPayload} from 'App/utils/globals';
import ShipwellLoader from 'App/common/shipwellLoader';
import {
  fedexServiceOptions,
  upsServiceOptions,
  uspsServiceOptions,
  getPickupValues,
  parcelPickupStatusMap
} from 'App/utils/parcelConstants';
import SchedulePickup from 'App/components/schedulePickup';

import './styles.scss';

/**
 * Fedex Manifest Container
 * @param {*} props
 */
const ParcelManifest = (props) => {
  const {params, dispatch, router, location} = props;

  // Data states
  const [pickup, setPickup] = useState({});
  const [shipments, setShipments] = useState([]);
  const [isFetchingShipments, setIsFetchingShipments] = useState(false);
  const [isFetchingLabels, setIsFetchingLabels] = useState(false);
  const [isRemovingShipment, setIsRemovingShipment] = useState(false);

  // UI States
  const [showCancelPickupModal, setShowCancelPickupModal] = useState(false);
  const [showRemoveShipmentModal, setShowRemoveShipmentModal] = useState(false);
  const [showCreatePickupModal, setShowCreatePickupModal] = useState(false);
  const [showSchedulePickupModal, setShowSchedulePickupModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const provider = pickup && pickup.provider_code && pickup.provider_code.toLowerCase();
  const serviceOptions =
    provider && provider === 'fedex'
      ? fedexServiceOptions
      : provider === 'ups'
      ? upsServiceOptions
      : provider === 'usps'
      ? uspsServiceOptions
      : [];

  const serviceLevels = serviceOptions.reduce((options, option) => {
    const {id, name} = option;
    return {...options, [id]: name};
  }, {});

  /**
   * Get Shipments Specific to a Pickup
   * @param {Number} pickupId
   */
  const getShipments = async (pickupId) => {
    setIsFetchingShipments(true);

    try {
      const response = await getShipmentsPromise({pickupId, pageSize: 20});

      if (response && response.body) {
        setShipments(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchingShipments(false);
  };

  /**
   * Get Shipments Pickup details
   * @param {Number} pickupId
   */
  const fetchShipmentPickup = async (pickupId) => {
    try {
      const response = await dispatch(getShipmentPickup(pickupId));

      if (response?.body) {
        setPickup(response.body);
        setShipments(response.body.shipments || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Update Shipment Pickup with removed Shipment
   * @param {Number} shipmentId
   */
  const handleRemoveShipment = async (shipmentId) => {
    setIsRemovingShipment(true);

    try {
      const shipments = pickup.shipments.filter((shipment) => shipment.id !== shipmentId).map((s) => s.id);
      const updatedPickup = {
        ...pickup,
        shipments
      };

      const response = await dispatch(updateShipmentPickup(pickup.id, updatedPickup));

      if (response && response.body) {
        setShipments(response.body.shipments);
        setSubmitSuccess('Shipment successfully removed from pickup.');
      }
    } catch (error) {
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
    }

    setIsRemovingShipment(false);
    setShowRemoveShipmentModal(false);
  };

  /**
   * Cancel Shipment Pickup
   * @param {Object} values
   */
  const handleCancelPickup = async () => {
    try {
      await dispatch(cancelShipmentPickup(pickup.id));
      getShipments(pickup.id);
      setSubmitSuccess('Pickup successfully canceled.');
    } catch (error) {
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
    }

    setShowCancelPickupModal(false);
  };

  /** Print labels for all shipments in this pickup */
  const handlePrintAllLabels = async () => {
    // fetch all labels using the bulk operation
    const shipmentsToPrint = shipments.map((shipment) => ({shipment: shipment.id}));

    setIsFetchingLabels(true);

    try {
      const response = await dispatch(getBulkShipmentLabels(shipmentsToPrint));
      if (response && response.body) {
        setIsFetchingLabels(false);
        printJS({
          printable: response.body.batch_document,
          type: 'pdf',
          onError: (error) => {
            setSubmitError(error);
          }
        });
      }
    } catch (error) {
      console.error(error);
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
      setIsFetchingLabels(false);
    }
  };

  /**
   * Print Manifest details
   */
  const handlePrintManifest = async () => {
    const {scheduled_date, time_window_start, time_window_end} = pickup;
    const printableHeader = `
      <table width="100%" style="margin-bottom: 20px">
      <tbody>
        <tr>
          <td valign="top">
            <strong>Selected Pickup</strong><br/>
            <span>${moment(scheduled_date).isValid() && moment(scheduled_date).format('ddd, MMM D')}</span><br/>
            <span>Between ${moment(time_window_start, 'HH:mm:ss').format('HH:mm')} - ${moment(
      time_window_end,
      'HH:mm:ss'
    ).format('HH:mm')}</span>
          </td>
          <td valign="top">
            <strong># of Shipments</strong><br/>
            <span>${shipments.length}</span>
          </td>
          <td valign="top">
            <strong>Mode</strong><br/>
            <span>Parcel</span>
          </td>
        </tr>
      </tbody>
      </table>
    `;
    printJS({
      type: 'json',
      gridHeaderStyle: 'font-size: 12px; font-weight: bold;',
      gridStyle: 'font-size: 12px; border: 1px solid #ccc; padding: 2px 5px;',
      properties: ['id', 'status', 'items', 'origin', 'destination', 'planned delivery', 'shipping service'],
      header: printableHeader,
      printable: shipments.map(
        ({reference_id, line_items, stops, fedex_specific_options, ups_specific_options, usps_specific_options}) => ({
          id: reference_id,
          status: 'Scheduled',
          items: line_items.reduce((total, item) => total + item.total_packages, 0),
          origin:
            stops.length && stops[0].location
              ? `${stops[0].location.address.city}, ${stops[0].location.address.state_province}`
              : '--',
          destination:
            stops.length && stops[stops.length - 1].location
              ? `${stops[stops.length - 1].location.address.city}, ${
                  stops[stops.length - 1].location.address.state_province
                }`
              : '--',
          'planned delivery':
            stops && stops.length && stops[stops.length - 1].planned_date
              ? moment(stops[stops.length - 1].planned_date).isValid() &&
                moment(stops[stops.length - 1].planned_date).format('ddd, MMM D')
              : '--',
          'shipping service': fedex_specific_options
            ? serviceLevels[fedex_specific_options.service_code]
            : ups_specific_options
            ? serviceLevels[ups_specific_options.service_code]
            : usps_specific_options
            ? serviceLevels[usps_specific_options.service_code]
            : '--'
        })
      ),
      onError: (error) => {
        setSubmitError(error);
      }
    });
  };

  const handleCreatePickup = async (values, {setSubmitting, setErrors}) => {
    const [shipment] = shipments;
    const providerCarrierCode =
      (shipment && shipment.fedex_specific_options && shipment.fedex_specific_options.carrier_code) || null;

    try {
      /** Clear shipments from current pickup */
      await dispatch(
        updateShipmentPickup(
          pickup.id,
          cleanPayload({
            ...pickup,
            provider_carrier_code: providerCarrierCode,
            shipments: []
          })
        )
      );

      /** Create new pickup and add shipments */
      const response = await dispatch(
        createShipmentPickup(
          cleanPayload({
            ...values,
            provider_carrier_code: providerCarrierCode,
            shipments: [...shipments.map((shipment) => shipment.id)]
          })
        )
      );

      if (response && response.body) {
        router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }

    setShowCreatePickupModal(false);
    setSubmitting(false);
  };

  /**
   * Swap shipment between pickups
   * @param {Object} selectedPickup
   */
  const handlePickupSwap = async (selectedPickup) => {
    const [shipment] = shipments;
    // only fedex has provider carrier codes, other parcel carriers don't,
    // this needs to be null if shipment is not fedex or if no carrier code is present
    const providerCarrierCode =
      (shipment && shipment.fedex_specific_options && shipment.fedex_specific_options.carrier_code) || null;

    try {
      /** Clear shipments from current pickup */
      await dispatch(
        updateShipmentPickup(
          pickup.id,
          cleanPayload({
            ...pickup,
            provider_carrier_code: providerCarrierCode,
            shipments: []
          })
        )
      );
      /** Add shipments to selected pickup */
      const response = await dispatch(
        updateShipmentPickup(
          selectedPickup.id,
          cleanPayload({
            ...selectedPickup,
            provider_carrier_code: providerCarrierCode,
            shipments: [...selectedPickup.shipments.map((s) => s.id), ...shipments.map((s) => s.id)]
          })
        )
      );

      if (response && response.body) {
        router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      console.error(error);
    }

    setShowSchedulePickupModal(false);
  };

  /** Toggle Cancel Pickup modal */
  const handleToggleCancelPickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowCancelPickupModal(!showCancelPickupModal);
  };

  /** Toggle Remove Shipment modal */
  const handleToggleRemoveShipmentModal = (shipmentId) => {
    setShowRemoveShipmentModal(shipmentId);
  };

  /** Toggle Schedule Pickup modal */
  const handleToggleSchedulePickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowSchedulePickupModal(!showSchedulePickupModal);
  };

  /** Toggle Create Pickup modal */
  const handleToggleCreatePickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowCreatePickupModal(!showCreatePickupModal);
  };

  useEffect(() => {
    if (params.pickupId) {
      fetchShipmentPickup(params.pickupId);
    }
  }, [pickup.id, location]);

  return (
    <div className="fedex-manifest-wrapper">
      <SideNav />
      <div className="fedex-manifest-container">
        <PageHeader
          title="Bulk Pickup/Manifest"
          actions={
            <>
              <DeprecatedButton variant="tertiary" onClick={handlePrintManifest}>
                Print Manifest
              </DeprecatedButton>
              <DeprecatedButton
                variant="tertiary"
                onClick={handlePrintAllLabels}
                disabled={!shipments || shipments.length < 1}
              >
                Print Labels
              </DeprecatedButton>
              <DeprecatedButton
                variant="tertiary"
                onClick={handleToggleSchedulePickupModal}
                disabled={!shipments || shipments.length < 1}
              >
                Select a Different Pickup
              </DeprecatedButton>
              <DeprecatedButton variant="tertiary" onClick={handleToggleCancelPickupModal}>
                Cancel Pickup
              </DeprecatedButton>
            </>
          }
        />
        <div id="fedex-manifest" className="fedex-manifest-list">
          <div className="manifest-pickup-details grid-1-12">
            <div className="grid-item-2">
              <strong>Selected Pickup</strong>
              <p>{pickup.address && pickup.address.formatted_address}</p>
              <p>
                {moment(pickup.scheduled_date).isValid() && moment(pickup.scheduled_date).format('ddd, MMM D')}
                <br />
                Between {moment(pickup.time_window_start, 'HH:mm:ss').format('HH:mm')} -{' '}
                {moment(pickup.time_window_end, 'HH:mm:ss').format('HH:mm')}
              </p>
            </div>
            <div className="grid-item-2">
              <strong># of Shipments</strong>
              <p>{shipments.length}</p>
            </div>
            <div className="grid-item-1">
              <strong>Mode</strong>
              <p>Parcel</p>
            </div>
          </div>
          <SWTable
            data={shipments}
            loading={isFetchingShipments}
            getTrProps={(state, rowInfo) => ({className: !rowInfo ? 'empty' : ''})}
            columns={[
              {
                Header: 'ID',
                id: 'id',
                accessor: 'reference_id',
                sortable: false,
                Cell: ({value, original}) => <Link to={`/shipments/${original.id}`}>{value || original.id}</Link>
              },
              {
                Header: 'Status',
                id: 'status',
                accessor: 'parcel_pickup_status',
                Cell: ({value, original}) => (
                  <strong>
                    {original?.state === 'carrier_confirmed'
                      ? 'Carrier Confirmed'
                      : parcelPickupStatusMap[value]?.label || '--'}
                  </strong>
                )
              },
              {
                Header: '# of Items',
                id: 'items',
                accessor: 'line_items',
                sortable: false,
                Cell: ({value}) => value.reduce((total, item) => total + item.total_packages, 0)
              },
              {
                Header: 'Origin',
                id: 'origin',
                accessor: 'stops',
                sortable: false,
                Cell: ({value}) =>
                  value && value[0] && value[0].location
                    ? `${value[0].location.address.city}, ${value[0].location.address.state_province}`
                    : '--'
              },
              {
                Header: 'Destination',
                id: 'destination',
                accessor: 'stops',
                sortable: false,
                Cell: ({value}) =>
                  value && value.length && value[value.length - 1].location
                    ? `${value[value.length - 1].location.address.city}, ${
                        value[value.length - 1].location.address.state_province
                      }`
                    : '--'
              },
              {
                Header: 'Planned Delivery',
                id: 'delivery',
                accessor: 'stops',
                sortable: false,
                Cell: ({value}) =>
                  value && value.length && value[value.length - 1].planned_date
                    ? moment(value[value.length - 1].planned_date).isValid() &&
                      moment(value[value.length - 1].planned_date).format('ddd, MMM D')
                    : '--'
              },
              {
                Header: 'Shipping Service',
                id: `${provider}_specific_options`,
                accessor: `${provider}_specific_options`,
                sortable: false,
                Cell: ({value}) => (value && value.service_code ? serviceLevels[value.service_code] : '--')
              },
              {
                id: 'actions',
                Cell: ({original}) => (
                  <DeprecatedButton variant="tertiary" onClick={() => handleToggleRemoveShipmentModal(original.id)}>
                    Remove
                  </DeprecatedButton>
                )
              }
            ]}
          />
        </div>
      </div>
      <Modal show={Boolean(isFetchingLabels)} headerComponent={null} footerComponent={null} onClose={null}>
        <ShipwellLoader loading />
        <h4 className="text-center">Generating Labels...</h4>
      </Modal>
      <Modal
        show={Boolean(showSchedulePickupModal)}
        title="Schedule Pickup"
        className="schedule-pickup-modal"
        onClose={handleToggleSchedulePickupModal}
        footerComponent={null}
      >
        <SchedulePickup
          shipment={shipments[0]}
          filters={{providerCode: pickup.provider_code}}
          currentPickup={pickup}
          onCancel={handleToggleSchedulePickupModal}
          onCreatePickup={() => {
            setShowSchedulePickupModal(false);
            setShowCreatePickupModal(true);
          }}
          onUpdatePickup={handlePickupSwap}
        />
      </Modal>
      <Modal
        show={Boolean(showCreatePickupModal)}
        title="Add Scheduled Pickup"
        footerComponent={null}
        onClose={handleToggleCreatePickupModal}
      >
        <SchedulePickupForm
          providerCode={pickup.provider_code}
          onCancelPickup={handleToggleCancelPickupModal}
          onCancel={handleToggleCreatePickupModal}
          onSubmit={handleCreatePickup}
          values={getPickupValues(shipments[0])}
        />
      </Modal>
      <Modal
        variant="warning"
        show={Boolean(showCancelPickupModal)}
        title="Cancel Scheduled Pickup?"
        primaryBtnName="Cancel Pickup"
        onClose={handleToggleCancelPickupModal}
        onPrimaryAction={handleCancelPickup}
      >
        <p>Are you sure you want to cancel this pickup? Any shipments assigned to this pickup will not be picked up.</p>
        {pickup.is_regularly_scheduled && (
          <p>
            Cancelling a recurring pickup will remove it from your list of scheduled pickups. Contact FedEx to cancel
            the pickup appointment.
          </p>
        )}
      </Modal>
      <Modal
        variant="warning"
        show={Boolean(showRemoveShipmentModal)}
        title="Remove Shipment from Pickup?"
        primaryBtnName={
          <>
            {isRemovingShipment && (
              <span className="pad-right">
                <i className="icon icon-Restart rotate" />
              </span>
            )}{' '}
            Remove Shipment
          </>
        }
        onClose={handleToggleRemoveShipmentModal}
        onPrimaryAction={() => handleRemoveShipment(showRemoveShipmentModal)}
      >
        <p>Are you sure you want to remove this shipment from this pickup?</p>
      </Modal>
      <Toast
        show={Boolean(submitSuccess)}
        title="Success"
        variant="success"
        anchor="bottom-right"
        onClose={() => setSubmitSuccess(null)}
      >
        {submitSuccess}
      </Toast>
      <Toast
        show={Boolean(submitError)}
        title="Error"
        variant="error"
        anchor="bottom-right"
        onClose={() => setSubmitError(null)}
      >
        {submitError}
      </Toast>
    </div>
  );
};

export default connect()(ParcelManifest);
