import moment from 'moment';
import {BaseShipment, ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatCurrency} from 'App/utils/internationalConstants';

export const renderFinancialsTable = (shipment: BaseShipment | ShippingDashboardRow) => {
  const vendorTotal = formatCurrency(
    getVendorTotalFinancials(shipment).toFixed(2).toString(),
    shipment.preferred_currency || 'USD'
  );
  const customerTotal = formatCurrency(
    getCustomerTotalFinancials(shipment).toFixed(2).toString(),
    shipment.preferred_currency || 'USD'
  );
  return (
    <div className="flex flex-col">
      <div>Customer: {customerTotal}</div>
      <div>Carrier: {vendorTotal}</div>
    </div>
  );
};

export const renderCustomerFinancialsTable = (shipment: BaseShipment | ShippingDashboardRow) =>
  formatCurrency(getCustomerTotalFinancials(shipment).toFixed(2).toString(), shipment.preferred_currency || 'USD');

export const renderProviderFinancialsTable = (shipment: BaseShipment | ShippingDashboardRow) =>
  formatCurrency(getVendorTotalFinancials(shipment).toFixed(2).toString(), shipment.preferred_currency || 'USD');

export const renderNetFinancialsTable = (shipment: BaseShipment | ShippingDashboardRow) =>
  formatCurrency(
    (getCustomerTotalFinancials(shipment) - getVendorTotalFinancials(shipment)).toFixed(2).toString(),
    shipment.preferred_currency || 'USD'
  );

export const getCustomerTotalFinancials = (shipment: {
  relationship_to_customer?: {vendor_charge_line_items?: Array<{amount?: number | null}>};
}) =>
  shipment.relationship_to_customer?.vendor_charge_line_items?.reduce(
    (total: number, item: {amount?: number | null}) => total + (item.amount || 0),
    0
  ) || 0;

export const getVendorTotalFinancials = (shipment: {
  relationship_to_vendor?: {
    customer_charge_line_items?: Array<{amount?: number | null; prepaid_amount?: number | null}>;
  };
}): number =>
  shipment.relationship_to_vendor?.customer_charge_line_items?.reduce(
    (total: number, item: {amount?: number | null; prepaid_amount?: number | null}) =>
      total + (item.amount || 0) - (item.prepaid_amount || 0),
    0
  ) || 0;

export const handleDateFilter = (
  filterObj: Record<string, (string | null)[]>,
  key: string,
  gteKey: string,
  lteKey: string,
  isSaveAction: boolean,
  isFromParent: boolean
) => {
  const filterCopy = {...filterObj};
  if (filterCopy[gteKey]?.length && filterCopy[lteKey]?.length) {
    const gteDateValue = filterObj[gteKey][0];
    const lteDateValue = filterObj[lteKey][0];
    if (gteDateValue === 'today' && lteDateValue === 'today') {
      filterCopy[gteKey][0] = moment().format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().format('YYYY-MM-DD');
    } else if (gteDateValue === 'tomorrow' && lteDateValue === 'tomorrow') {
      filterCopy[gteKey][0] = moment().add(1, 'days').format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().add(1, 'days').format('YYYY-MM-DD');
    } else if (gteDateValue === 'yesterday' && lteDateValue === 'yesterday') {
      filterCopy[gteKey][0] = moment().subtract(1, 'days').format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().subtract(1, 'days').format('YYYY-MM-DD');
    } else if (gteDateValue === '30 days ago' && lteDateValue === 'today') {
      filterCopy[gteKey][0] = moment().subtract(30, 'days').format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().format('YYYY-MM-DD');
    } else if (gteDateValue === '7 days ago' && lteDateValue === 'today') {
      filterCopy[gteKey][0] = moment().subtract(7, 'days').format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().format('YYYY-MM-DD');
    } else if (gteDateValue === 'today' && lteDateValue === '7 days from now') {
      filterCopy[gteKey][0] = moment().format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().add(7, 'days').format('YYYY-MM-DD');
    } else if (gteDateValue === 'today' && lteDateValue === '30 days from now') {
      filterCopy[gteKey][0] = moment().format('YYYY-MM-DD');
      filterCopy[lteKey][0] = moment().add(30, 'days').format('YYYY-MM-DD');
    }
  }

  if (filterCopy[key]) {
    //if length is 1, this goes in both fields
    if (filterCopy[key]?.length === 1) {
      const dateValue = filterCopy[key][0];
      filterCopy[gteKey] = [dateValue];
      filterCopy[lteKey] = [dateValue];
      //special handling when we are saving, and the value is yesterday, today, or tomorrow
      if (isSaveAction) {
        if (moment(dateValue).isSame(moment(), 'day')) {
          //this is today
          filterCopy[gteKey] = ['today'];
          filterCopy[lteKey] = ['today'];
        } else if (moment(dateValue).isSame(moment().add(1, 'days'), 'day')) {
          //this is tomorrow
          filterCopy[gteKey] = ['tomorrow'];
          filterCopy[lteKey] = ['tomorrow'];
        } else if (moment(dateValue).isSame(moment().subtract(1, 'days'), 'day')) {
          //this is yesterday
          filterCopy[gteKey] = ['yesterday'];
          filterCopy[lteKey] = ['yesterday'];
        }
      }
    } else if (filterCopy[key]?.length === 2) {
      const gteDateValue = filterCopy[key][0];
      const lteDateValue = filterCopy[key][1];
      if (gteDateValue) {
        filterCopy[gteKey] = [gteDateValue];
      }
      if (lteDateValue) {
        filterCopy[lteKey] = [lteDateValue];
      }
      if (isSaveAction && filterCopy[gteKey] && filterCopy[lteKey]) {
        if (
          moment(gteDateValue, 'YYYY-MM-DD').isSame(moment().subtract(7, 'days'), 'day') &&
          moment(lteDateValue, 'YYYY-MM-DD').isSame(moment(), 'day')
        ) {
          filterCopy[gteKey] = ['7 days ago'];
          filterCopy[lteKey] = ['today'];
        } else if (
          moment(gteDateValue, 'YYYY-MM-DD').isSame(moment().subtract(30, 'days'), 'day') &&
          moment(lteDateValue, 'YYYY-MM-DD').isSame(moment(), 'day')
        ) {
          filterCopy[gteKey] = ['30 days ago'];
          filterCopy[lteKey] = ['today'];
        } else if (
          moment(gteDateValue, 'YYYY-MM-DD').isSame(moment(), 'day') &&
          moment(lteDateValue, 'YYYY-MM-DD').isSame(moment().add(7, 'days'), 'day')
        ) {
          filterCopy[gteKey] = ['today'];
          filterCopy[lteKey] = ['7 days from now'];
        } else if (
          moment(gteDateValue, 'YYYY-MM-DD').isSame(moment(), 'day') &&
          moment(lteDateValue, 'YYYY-MM-DD').isSame(moment().add(30, 'days'), 'day')
        ) {
          filterCopy[gteKey] = ['today'];
          filterCopy[lteKey] = ['30 days from now'];
        }
      }
    }
    delete filterCopy[key];
  }

  if (isFromParent) {
    if (filterCopy[gteKey] && filterCopy[lteKey]) {
      if (filterCopy[gteKey][0] === filterCopy[lteKey][0]) {
        const dateValue = filterCopy[gteKey][0];
        //when dates are the same, only one value is needed
        filterCopy[key] = [dateValue];
        //handle cases where a string was saved for yesterday/today/tomorrow
        if (dateValue === 'today') {
          filterCopy[key] = [moment().format('YYYY-MM-DD')];
          filterCopy[gteKey][0] = moment().format('YYYY-MM-DD');
          filterCopy[lteKey][0] = moment().format('YYYY-MM-DD');
        } else if (dateValue === 'tomorrow') {
          filterCopy[key] = [moment().add(1, 'days').format('YYYY-MM-DD')];
          filterCopy[gteKey][0] = moment().add(1, 'days').format('YYYY-MM-DD');
          filterCopy[lteKey][0] = moment().add(1, 'days').format('YYYY-MM-DD');
        } else if (dateValue === 'yesterday') {
          filterCopy[key] = [moment().subtract(1, 'days').format('YYYY-MM-DD')];
          filterCopy[gteKey][0] = moment().subtract(1, 'days').format('YYYY-MM-DD');
          filterCopy[lteKey][0] = moment().subtract(1, 'days').format('YYYY-MM-DD');
        }
      } else {
        filterCopy[key] = [filterCopy[gteKey][0], filterCopy[lteKey][0]];
      }
    } else if (filterCopy[gteKey] && !filterCopy[lteKey]) {
      filterCopy[key] = [filterCopy[gteKey][0], null];
    } else if (filterCopy[lteKey] && !filterCopy[gteKey]) {
      filterCopy[key] = [null, filterCopy[lteKey][0]];
    }
  }

  return filterCopy;
};
