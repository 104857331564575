import {useQueries} from '@tanstack/react-query';
import {ShipmentDocumentMetadataTypeEnum} from '@shipwell/backend-core-sdk';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getShipmentDocuments} from 'App/api/shipment/typed';
import {PRINT_DOCUMENT} from 'App/containers/Dashboard/utils/constants';

export function useLegacyDocuments(
  shipmentIds: string[],
  mode: string,
  documentType: ShipmentDocumentMetadataTypeEnum
) {
  const shipmentDocumentQueries = useQueries({
    queries: shipmentIds.map((shipmentId) => ({
      queryKey: [SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId],
      queryFn: () => getShipmentDocuments(shipmentId)
    }))
  });

  return shipmentDocumentQueries.map((shipmentDocumentQuery) => {
    const documents =
      shipmentDocumentQuery?.data?.data?.results?.filter((document) => {
        if (document.filename && mode === PRINT_DOCUMENT) {
          return document.type === documentType && document.filename.slice(-4).toLowerCase() === '.pdf';
        }
        return document.type === documentType;
      }) || [];

    return {
      documents,
      isLoading: shipmentDocumentQuery.isInitialLoading,
      isSuccess: shipmentDocumentQuery.isSuccess
    };
  });
}
