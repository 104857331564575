import {compose} from 'recompose';
import TenantPicker from './TenantPicker';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import MenuToggle from 'App/common/mainHeader/mobileNavigation/MenuToggle';

const TenantPickerMobileMenuItem = () => (
  <MenuToggle name="Accounts">
    <TenantPicker />
  </MenuToggle>
);

const ConditionalTenantPickerMobileMenuItem = compose(
  withFlags('fmMultiAccountImpersonation'),
  withConditionalFallback(
    ({fmMultiAccountImpersonation}: {fmMultiAccountImpersonation: boolean}) => !fmMultiAccountImpersonation,
    null
  )
)(TenantPickerMobileMenuItem);

export default ConditionalTenantPickerMobileMenuItem;
