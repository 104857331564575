import {
  Title,
  Card,
  Rule,
  Pill,
  FormikTextInput,
  FormikCheckbox,
  FormikSelect,
  FormikDateTimePicker,
  Tooltip
} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {formatISO, addDays} from 'date-fns';

import {currencyUnit, daysList, PostSettingsFormProps, timeOptions} from './utils';

const PostSettingsForm = ({values, setFieldValue}: PostSettingsFormProps) => {
  return (
    <Card
      title="Post Settings"
      draggableProvided={false}
      bodyClassName="p-4 empty:p-0"
      data-testid="post-settings-content"
    >
      <div>
        <Title variant="formTitle" className="mb-6">
          Settings
        </Title>
        <div className="mb-4 flex items-center gap-3">
          <div className="text-lg">{values.load_reference}</div>
          <Pill>{values.status}</Pill>
        </div>
        <div className="mb-4 flex flex-col gap-4">
          <Field label="Load Description" name="load_description" component={FormikTextInput} />
          <Field label="Commodity" name="commodity.description" component={FormikTextInput} />
        </div>
        <div className="mb-4 grid grid-cols-4 gap-4">
          <Field label="Book Now" name="book_now_eligible" component={FormikCheckbox} />
          <Field label="Allow Bidding" name="bid_eligible" component={FormikCheckbox} />
          <Field label="High Priority" name="use_24_hour_time" component={FormikCheckbox} />
          <Field label="Allow List Only" name="allow_list_only" component={FormikCheckbox} />
        </div>
        <Rule />
        <Title variant="formTitle" className="my-4">
          <span className="flex items-center gap-2">
            Rates
            <Tooltip
              placement="right"
              tooltipContent="If book now is enabled. The Book Now/Tender Rate field will be used to set a book now price."
              trigger="hover"
              tooltipClassname="w-[150px]"
            >
              <i className="material-icons">info_outline</i>
            </Tooltip>
          </span>
        </Title>
        <div className="mb-6 grid grid-cols-2 gap-4">
          <div className="one-row-fields">
            <Field
              label="Offer All-in Rate"
              name="rate_attributes.offer_all_in_rate.value"
              component={FormikTextInput}
            />
            <div className="max-w-[80px]">
              <Field
                simpleValue
                searchable={false}
                name="rate_attributes.offer_all_in_rate.currency_code"
                component={FormikSelect}
                options={currencyUnit}
                className="min-w-0"
                clearable={false}
              />
            </div>
          </div>
          <div className="one-row-fields">
            <Field
              label="Book Now/Tender Rate"
              name="rate_attributes.book_now_or_tender_rate.value"
              component={FormikTextInput}
            />
            <div className="max-w-[80px]">
              <Field
                simpleValue
                name="rate_attributes.book_now_or_tender_rate.currency_code"
                component={FormikSelect}
                options={currencyUnit}
                className="min-w-0"
                clearable={false}
              />
            </div>
          </div>
        </div>
        <Rule />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Title variant="formTitle" className="my-4">
              Expiration
            </Title>
            <div className="grid gap-4">
              <Field label="Starts at" name="starts_at" component={FormikDateTimePicker} portalId="body" />
              <Field label="Post expiration" name="expires_at" component={FormikDateTimePicker} portalId="body" />
            </div>
          </div>
          <div>
            <Title variant="formTitle" className="my-4">
              Refresh Schedule
            </Title>
            <div className="grid gap-4">
              <Field
                simpleValue
                searchable={false}
                label="Refresh every"
                name="schedule.rerun_in_minutes"
                className="min-w-full"
                component={FormikSelect}
                options={timeOptions}
                clearable={false}
                menuPortalTarget={document.body}
                styles={{menuPortal: (base: {[prop: string]: string | number}) => ({...base, zIndex: 9999})}}
              />
              <Field
                simpleValue
                searchable={false}
                label="For"
                name="rerun_in_days"
                className="min-w-full"
                component={FormikSelect}
                options={daysList}
                onChange={(value: string) => {
                  if (value === '999') {
                    setFieldValue('schedule.until', String(values.expires_at));
                  } else {
                    const newExpiresAt = formatISO(addDays(new Date(), Number(value)));
                    setFieldValue('schedule.until', newExpiresAt);
                  }
                  setFieldValue('rerun_in_days', value);
                }}
                clearable={false}
                menuPortalTarget={document.body}
                styles={{menuPortal: (base: {[prop: string]: string | number}) => ({...base, zIndex: 9999})}}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PostSettingsForm;
