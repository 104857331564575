import {Component} from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import SelectField from 'App/formComponents/fields/select';
import {fetchShipmentStatuses} from 'App/actions/_shipments';

/**
 * Shipment Status Field
 */
class ShipmentStatuses extends Component {
  static defaultProps = {
    shipmentStatuses: [],
    placeholder: '',
    multi: false,
    value: '',
    name: '',
    input: {},
    excludeStopAutomatedStatuses: false
  };

  componentDidMount() {
    const {shipmentStatuses} = this.props;

    if (!shipmentStatuses.length) {
      this.getShipmentStatuses();
    }
  }

  /**
   * Request shipment statuses
   */
  async getShipmentStatuses() {
    const {excludeStopAutomatedStatuses} = this.props;
    try {
      const results = {options: []};
      const response = await this.props.dispatch(
        fetchShipmentStatuses({
          excludeStopAutomatedStatuses,
          filterDraftStatus: true
        })
      );

      if (response) {
        results.options = response;
      }
      return results;
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {input, placeholder, multi, shipmentStatuses, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const options = this.props.options || shipmentStatuses;
    const onChange = input.onChange || rest.onChange;

    return (
      <SelectField
        {...rest}
        multi={multi}
        name={name}
        value={value}
        placeholder={placeholder}
        options={options ? options.map((options) => ({id: options.id, name: options.label})) : ''}
        onChange={(value) => onChange && onChange(value)}
      />
    );
  }
}

export default connect((state) => ({
  shipmentStatuses: state.shipments.shipmentStatuses,
  excludeStopAutomatedStatuses: get(state, 'userCompany.company.feature_flags.stop_status_enabled', false)
}))(ShipmentStatuses);
