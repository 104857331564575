import {ReactNode} from 'react';
import {LegacyShipmentStage} from '@shipwell/corrogo-sdk';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import useToggle from 'App/utils/hooks/useToggle';

export const ExpandableContainerViewButton = ({
  children,
  legacyShipmentId,
  stageCount
}: {
  children: ReactNode;
  legacyShipmentId: LegacyShipmentStage['id'];
  stageCount: number;
}) => {
  const {
    shipmentQuery: {data: legacyShipment, isLoading: isLoadingLegacyShipment}
  } = useLegacyShipment(legacyShipmentId, {
    enabled: !!legacyShipmentId
  });
  const expandableStageContainerLabel = legacyShipment?.drayage_container_number || legacyShipment?.reference_id;
  const [containerExpanded, setContainerExpanded] = useToggle();

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <DeprecatedButton variant="tertiary" onClick={setContainerExpanded}>
          <SvgIcon name={containerExpanded ? 'ExpandLess' : 'ExpandMore'} />
          {isLoadingLegacyShipment ? (
            <SvgIcon name="LoadingDots" />
          ) : (
            <label role="label" className="mb-0 ml-[2px] font-normal">{`${expandableStageContainerLabel || ''}`}</label>
          )}
        </DeprecatedButton>
        <label role="label" className="mb-0 text-xxs font-normal uppercase text-sw-label">{`${pluralize(
          'Stage',
          stageCount,
          true
        )}`}</label>
      </div>
      {containerExpanded ? children : null}
    </>
  );
};
