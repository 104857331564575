import {Button, FormikSelect, FormikTextarea, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {ChangeEvent, useEffect, useState} from 'react';
import {CapacitySearchResponseMultiple} from '@shipwell/tabula-sdk';
import {BulkCreateSpotNegotiationRequest, Shipment} from '@shipwell/backend-core-sdk';
import {FlexBox} from 'App/components/Box';
import useToggle from 'App/utils/hooks/useToggle';
import {useGetSpotNegotiationsEmailPreview} from 'App/data-hooks';
import RequestBidsMessagePreview from 'App/containers/Shipment/forms/SourceCapacityRequestBidsForm/RequestBidsMessagePreview';

export type SourceCapacityRequestBidsFormType = {
  vendors: Array<CapacitySearchResponseMultiple>;
  message: string;
  special_instructions?: string;
};

export type RequestBidsPayloadType = SourceCapacityRequestBidsFormType & {
  solicited_users: string[];
  additional_customer_users: string[];
};

const SourceCapacityRequestBidsForm = ({
  selectedCarriers,
  shipment,
  handleRequestBids
}: {
  selectedCarriers: CapacitySearchResponseMultiple[];
  shipment: Shipment;
  handleRequestBids: (
    payload: {shipmentId: string; body: BulkCreateSpotNegotiationRequest},
    setSubmitting: FormikHelpers<SourceCapacityRequestBidsFormType>['setSubmitting']
  ) => void;
}) => {
  const {spotNegotiationsEmailPreview} = useGetSpotNegotiationsEmailPreview(shipment.id);
  const [openEmailPreview, toggleOpenEmailPreview] = useToggle(false);
  const [specialInstructions, setSpecialInstructions] = useState('');

  useEffect(() => {
    if (shipment?.notes_for_carrier) {
      setSpecialInstructions(shipment.notes_for_carrier);
    }
  }, [shipment]);

  const initialValues = {
    vendors: selectedCarriers,
    message: spotNegotiationsEmailPreview?.message || '',
    special_instructions: specialInstructions
  };

  const handleSpecialInstructionsChange = (
    value: string,
    setFieldValue: FormikHelpers<SourceCapacityRequestBidsFormType>['setFieldValue']
  ) => {
    setSpecialInstructions(value);
    setFieldValue('special_instructions', value);
  };

  const handleSubmit = (
    values: SourceCapacityRequestBidsFormType,
    setSubmitting: FormikHelpers<SourceCapacityRequestBidsFormType>['setSubmitting']
  ) => {
    const payload = {...values} as RequestBidsPayloadType;
    payload.solicited_users = payload.vendors.filter((v) => v.carrier_id).map((v) => v.carrier_id as string);
    //dont pass any additional users on the customer side for now
    payload.additional_customer_users = [];
    // delete the special_instructions field if it is "" - BE rejects if sent with ""
    if (payload.special_instructions === '') {
      delete payload.special_instructions;
    }

    handleRequestBids({shipmentId: shipment.id, body: payload}, setSubmitting);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
        enableReinitialize
      >
        {({isSubmitting, setFieldValue}) => (
          <Form>
            <>
              <div className="rounded-t border-1 border-b-0 border-sw-border p-4 font-bold">Carrier</div>
              <div className="mb-6 rounded-b border-1 border-sw-border">
                <div className="flex flex-col gap-4 p-4">
                  <Field
                    isMulti
                    required
                    label="Carrier(s)"
                    name="vendors"
                    component={FormikSelect}
                    disabled
                    getOptionLabel={(option: CapacitySearchResponseMultiple) => option.contact?.company_name}
                  />
                  <Field label="Message" name="message" component={FormikTextarea} disabled />
                  <Field
                    label="Special Instructions"
                    name="special_instructions"
                    component={FormikTextarea}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSpecialInstructionsChange(e.target.value, setFieldValue)
                    }
                  />
                </div>
              </div>
              <FlexBox direction="row" justify="end" gap="m">
                <FlexBox direction="row" gap="m">
                  <Button variant="secondary" onClick={() => toggleOpenEmailPreview()}>
                    <SvgIcon name="EmailOutlined" />
                    Preview Email
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Send
                  </Button>
                </FlexBox>
              </FlexBox>
            </>
          </Form>
        )}
      </Formik>
      <Modal
        title="Email Preview"
        show={openEmailPreview}
        onClose={() => toggleOpenEmailPreview()}
        footerComponent={null}
        bodyClassName="p-4"
      >
        <RequestBidsMessagePreview
          spotNegotiationEmailPreview={spotNegotiationsEmailPreview}
          specialInstructions={specialInstructions}
          loadId={shipment?.metadata?.load_board_id}
        />
      </Modal>
    </>
  );
};

export default SourceCapacityRequestBidsForm;
