import classNames from 'classnames';
import get from 'lodash/get';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {watchShipment, stopWatchingShipment} from 'App/api/shipwellUI';
import withConditionalFallback from 'App/components/withConditionalFallback';
import './styles.scss';
import WithStatusToasts from 'App/components/withStatusToasts';

const WatchShipmentToggle = ({onSuccess, shipment, setError}) => {
  const {is_watched: isWatched, is_watchable: isWatchable} = shipment;

  const toggleWatchShipment = async (shipmentId, value) => {
    try {
      if (value) {
        await watchShipment({shipment_id: shipmentId});
      } else {
        await stopWatchingShipment({shipment_id: shipmentId});
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  return (
    <div
      className={classNames('shipmentCard__actions-watch actionLink', {watching: isWatched, disabled: !isWatchable})}
    >
      <Tooltip
        tooltipClassname="actionLink__tooltip"
        tooltipContent={
          isWatchable && isWatched ? (
            <span className="shipmentCard__actions-watch-tooltip flex items-center">
              <SvgIcon name="Check" color="$sw-success" /> Watched Shipment
            </span>
          ) : isWatchable ? (
            'Watch Shipment'
          ) : (
            'Shipment is older than 7 days'
          )
        }
        portal
        placement="top"
      >
        <SvgIcon
          name="Visibility"
          role="img"
          color={isWatched ? '$sw-primary' : isWatchable ? '$sw-icon' : '$sw-disabled'}
          onClick={(e) => {
            e.stopPropagation();
            if (isWatchable) {
              toggleWatchShipment(shipment.id, !isWatched);
            }
          }}
        />
      </Tooltip>
    </div>
  );
};

WatchShipmentToggle.propTypes = {
  onSuccess: PropTypes.func,
  shipment: PropTypes.object,
  setError: PropTypes.func
};

export default compose(
  connect((state) => ({
    alertsDashboardEnabled: get(state, 'userCompany.company.feature_flags.alerts_dashboard_enabled')
  })),
  withConditionalFallback(({alertsDashboardEnabled}) => !alertsDashboardEnabled, null),
  WithStatusToasts
)(WatchShipmentToggle);
