import {Checkbox} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

export const CheckboxesFilter = ({
  options,
  selected,
  onChange
}: {
  options: {label: string; value: string}[];
  selected: string[];
  onChange: (newSelected: string[]) => void;
}) => {
  const isCheckedOption = (val: string) => selected.some((sel) => sel === val);
  const handleChange = (value: string) => {
    // if checked, remove from values
    if (isCheckedOption(value)) {
      return onChange(selected.filter((sel) => sel !== value));
    }
    onChange([...selected, value]);
  };
  return (
    <FlexBox direction="col" gap="s">
      {options.map((option) => (
        <Checkbox
          key={option.value}
          label={option.label}
          name={option.value}
          checked={isCheckedOption(option.value)}
          onChange={() => handleChange(option.value)}
          fixedHeight={false}
        />
      ))}
    </FlexBox>
  );
};
