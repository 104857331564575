import {connect} from 'react-redux';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import {Field, useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import {Title} from '@shipwell/shipwell-ui';
import Tags from 'App/components/Tags';
import {PROPTYPE_TAGS} from 'App/utils/propTypeConstants';

/**
 * A Formik wrapper for the tags input.
 */
export const FormikTagsInput = ({companyTags, user}) => {
  const {setFieldValue, values} = useFormikContext();
  const {tags = []} = values || {};

  const availableTags = (companyTags || []).filter((ct) => tags.indexOf(ct.id) === -1);
  const handleAddTag = (tagId) => {
    setFieldValue('tags', [...tags, tagId]);
  };
  const handleRemoveTag = (tagId) => {
    setFieldValue('tags', without(tags, tagId));
  };

  return (
    <>
      {isEmpty(tags) ? (
        <Title className="flex justify-center" variant="emptyStateHeader">
          No Tags Added
        </Title>
      ) : null}
      <Tags
        companyTags={availableTags}
        initialTags={tags}
        onItemClick={(tagId) => handleAddTag(tagId)}
        addTag={handleAddTag}
        removeTag={handleRemoveTag}
        overflowTagDisplay={false}
        showTagBorder={false}
        hideTagMarginBottom={false}
        showLabel={false}
        userDefaults={user}
        tagTitle={'Add Tags'}
        closeDropdownOnSelect
      />
    </>
  );
};

FormikTagsInput.propTypes = {
  companyTags: PROPTYPE_TAGS,
  user: PropTypes.shape({
    default_shipment_tags: PropTypes.arrayOf(PropTypes.string)
  })
};

FormikTagsInput.defaultProps = {
  companyTags: [],
  user: {default_shipment_tags: []}
};

const ConnectedFormikTagsInput = connect((state) => ({
  user: state.userProfile.user
}))(FormikTagsInput);

const TagsFields = () => <Field name="tags" component={ConnectedFormikTagsInput} />;

export default TagsFields;
