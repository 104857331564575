import {
  AddToDeliveredDashboardDetails,
  AutoDisputeInvoiceDetails,
  AutoGenerateInvoiceDetails,
  Details,
  FreightSettlementsConfiguration,
  FreightSettlementsConfigurationType
} from '@shipwell/settlements-sdk';

export const isAddToDeliveredDashboardDetails = (
  details: FreightSettlementsConfiguration['details']
): details is AddToDeliveredDashboardDetails & Details => !!details && 'days_to_wait' in details && 'mode' in details;

export const isAutoGenerateInvoiceDetails = (details: FreightSettlementsConfiguration['details']) =>
  !!details && 'business_days_after_delivery' in details && 'start_date' in details;

export const isAutoDisputeInvoiceDetails = (
  details: FreightSettlementsConfiguration['details']
): details is AutoDisputeInvoiceDetails & Details =>
  !!details && 'invoice_amount' in details && 'missing_required_document' in details;

type FreightSettlementsConfigs<TDetails, TType extends FreightSettlementsConfigurationType> = Omit<
  FreightSettlementsConfiguration,
  'type'
> & {
  details: TDetails;
  type: TType;
};

export type AddToDeliveredDashboardConfiguration = FreightSettlementsConfigs<
  AddToDeliveredDashboardDetails,
  'ADD_TO_DELIVERED_DASHBOARD'
>;
export const isAddToDeliveredDashboardConfiguration = (
  config: FreightSettlementsConfiguration
): config is AddToDeliveredDashboardConfiguration => config.type === 'ADD_TO_DELIVERED_DASHBOARD';

export type AutoGenerateInvoiceConfiguration = FreightSettlementsConfigs<
  AutoGenerateInvoiceDetails,
  'AUTO_GENERATE_INVOICE'
>;
export const isAutoGenerateInvoiceConfiguration = (
  config: FreightSettlementsConfiguration
): config is AutoGenerateInvoiceConfiguration => config.type === 'AUTO_GENERATE_INVOICE';

export type AutoDisputeInvoiceConfiguration = FreightSettlementsConfigs<
  AutoDisputeInvoiceDetails,
  'AUTO_DISPUTE_INVOICE'
>;
export const isAutoDisputeInvoiceConfiguration = (
  config: FreightSettlementsConfiguration
): config is AutoDisputeInvoiceConfiguration => config.type === 'AUTO_DISPUTE_INVOICE';
