import {useMemo} from 'react';
import {Banner, Button} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomField,
  Shipment,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {AggregateCharge, GrandTotals} from '../../types';
import {AggregateTotalCell, FinancialsGridRow, FinancialsSubTotalRow, TextCell} from './components';
import {getCustomDataValueByFieldType} from './utils';
import {formatCurrency} from 'App/utils/internationalConstants';
import Loader from 'App/common/shipwellLoader';
import {useSortedChargeCategories} from 'App/data-hooks';

export const NewCustomerTable = ({
  customerAssignment,
  aggregateCustomerCharges,
  theirCustomerCharges,
  myCustomerCharges,
  showCustomerColumn,
  grandTotals,
  shipmentCurrency,
  customFields,
  onEditClick
}: {
  customerAssignment: Shipment['relationship_to_customer'];
  aggregateCustomerCharges: AggregateCharge[];
  theirCustomerCharges: ShipmentChargeLineItem[];
  myCustomerCharges: ShipmentChargeLineItem[];
  showCustomerColumn?: boolean;
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
  customFields: CustomField[];
  onEditClick?: () => void;
}) => {
  const {
    chargeLineItemsCustomFieldsFix,
    decimalSupportForShipmentLineItems
  }: {chargeLineItemsCustomFieldsFix: boolean; decimalSupportForShipmentLineItems: boolean} = useFlags();
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories({
    sendShipwellVersionHeader: decimalSupportForShipmentLineItems
  });
  const visibleCustomFields = customFields.filter((customField) => customField.ui_enabled);
  const columnNumber = showCustomerColumn ? 2 : 1;

  // backend requires all charges be the same currency, safe to use the first currency found
  const currency = useMemo(
    () =>
      aggregateCustomerCharges.find((customerCharge) => !!customerCharge.unit_amount_currency)?.unit_amount_currency ||
      shipmentCurrency,
    [aggregateCustomerCharges, shipmentCurrency]
  );

  if (chargeCategories) {
    const hasCustomerFinancials = !!customerAssignment && !!aggregateCustomerCharges?.length;

    const formattedMarkup = formatCurrency(grandTotals.customerMarkup, currency);
    const formattedMargin = grandTotals.customerMargin?.toFixed(2) || '0';
    const financiaslSubTotal = `${formattedMarkup} (${formattedMargin}%)`;

    const financialsGrandTotal = formatCurrency(grandTotals.customer, currency);

    return hasCustomerFinancials ? (
      <div>
        {aggregateCustomerCharges.map((aggregateCustomerCharge, index) => {
          const chargeCategoryName = chargeCategories.find((c) => c.id === aggregateCustomerCharge?.category)?.name;

          const theirCustomerChargesOfThisAggregateCategory = theirCustomerCharges?.filter(
            (theirCustomerCharge) => theirCustomerCharge.category === aggregateCustomerCharge?.category
          );

          const myChargesOfThisAggregateCategory = myCustomerCharges?.filter(
            (myCustomerCharge) => myCustomerCharge.category === aggregateCustomerCharge?.category
          );

          return (
            <FinancialsGridRow
              key={`${aggregateCustomerCharge.category}-${index}}`}
              columnCount={visibleCustomFields.length + columnNumber}
              isNewFinancialsTable
              hasMismatchedTotals={aggregateCustomerCharge?.total_theirs !== aggregateCustomerCharge?.total_mine}
            >
              <TextCell>{`${aggregateCustomerCharge.charge_code ?? 'N/A'} (${chargeCategoryName ?? ''})`}</TextCell>

              <div className="flex items-center gap-2">
                <TextCell>{aggregateCustomerCharge.unit_name}</TextCell>
              </div>

              {visibleCustomFields.map((customField) => {
                const customFieldValue = getCustomDataValueByFieldType(
                  aggregateCustomerCharge.customData,
                  customField,
                  chargeLineItemsCustomFieldsFix
                );
                return <TextCell key={customField.id}>{customFieldValue}</TextCell>;
              })}

              {showCustomerColumn ? (
                <AggregateTotalCell
                  aggregateTotal={aggregateCustomerCharge.total_theirs}
                  breakdownCharges={theirCustomerChargesOfThisAggregateCategory}
                  currency={currency}
                />
              ) : null}

              <AggregateTotalCell
                aggregateTotal={aggregateCustomerCharge.total_mine}
                breakdownCharges={myChargesOfThisAggregateCategory}
                currency={currency}
              />
            </FinancialsGridRow>
          );
        })}

        <div className="quote-flow__financialsLineItem-addButtons pad-top pl-4">
          <Button variant="tertiary" iconName="AddCircleOutlined" onClick={onEditClick} isCompact>
            Add Line Item
          </Button>
        </div>

        <div className="bg-sw-success-background">
          <FinancialsSubTotalRow label="Total Markup" total={financiaslSubTotal} />
          <div className="flex items-center justify-between rounded px-4 py-2 font-semibold text-sw-success">
            <div>Amount Due to Carrier</div>
            <div>{financialsGrandTotal}</div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex flex-wrap justify-center py-4 text-center">
        <h3 className="w-full">No customer financials</h3>
        <Button variant="secondary" size="sm" onClick={onEditClick}>
          Add Item
        </Button>
      </div>
    );
  }

  if (isLoadingChargeCategories) return <Loader loading />;

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
