import {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import {object, mixed, string} from 'yup';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import ShipmentDocumentFields from 'App/formComponents/formSections/shipmentDocumentFields';
import {getDocumentTypes} from 'App/api/shipment';
import './styles.scss';

/** Default values needed for Formik */
const defaultFormValues = {
  file: null,
  description: null,
  type: null
};

export const createValidationSchema = (hideTypeField) => {
  const typeFieldDefaultSchema = string().nullable();
  const typeFieldSchema = hideTypeField ? typeFieldDefaultSchema : typeFieldDefaultSchema.required('Type is required.');
  return object().shape({
    file: mixed().required('File is required.'),
    description: string().nullable().required('Description is required.'),
    type: typeFieldSchema
  });
};

/**
 * Document Form
 */
const ShipmentDocumentForm = (props) => {
  const {edit, values, onSubmit, onCancel, hideTypeField = false, allowedDocuments} = props;
  const [documentTypes, setDocumentTypes] = useState([]);
  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit customer values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  const handleFetchDocumentTypes = async () => {
    try {
      const response = await getDocumentTypes();
      if (response.ok) {
        setDocumentTypes(response.body);
      }
      return response;
    } catch (error) {
      console.error('error retrieving document types', error);
      throw error;
    }
  };
  useEffect(() => {
    if (documentTypes.length === 0 && !hideTypeField) {
      handleFetchDocumentTypes();
    }
  }, [documentTypes.length, hideTypeField]);

  const filteredDocuments = allowedDocuments
    ? documentTypes.filter((document) => allowedDocuments.includes(document.id))
    : documentTypes;

  return (
    <Formik
      enableReinitialize
      validationSchema={createValidationSchema(hideTypeField)}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, dirty}) => (
        <Form noValidate="novalidate" className="shipment-document-form">
          <ShipmentDocumentFields edit={edit} documentTypes={filteredDocuments} hideTypeField={hideTypeField} />
          <div className="page-footer">
            {onCancel && (
              <DeprecatedButton variant="secondary" onClick={handleCancel}>
                Cancel
              </DeprecatedButton>
            )}
            {onSubmit && (
              <DeprecatedButton type="submit" disabled={isSubmitting || !dirty} loading={isSubmitting}>
                Save
              </DeprecatedButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

ShipmentDocumentForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.any,
  hideTypeField: PropTypes.bool,
  allowedDocuments: PropTypes.array
};

export default ShipmentDocumentForm;
