import {object, string, bool} from 'yup';
import {validatePhoneNumber} from 'App/utils/globals';

export const supplierAppointmentValidationSchema = object().shape({
  appointment_date: string().nullable().required('Appointment Date is required.'),
  link_appointment: string().nullable().optional(),
  reference_type: string().nullable().required('Reference Type is required.'),
  reference_number: string().nullable().required('Reference Number is required.'),
  delivery_type: string().nullable().required('Delivery type is required.'),
  load_type: string().nullable().optional(),
  mode: string().nullable().optional(),
  equipment_type: string().nullable().optional(),
  is_hazmat: bool().nullable().optional(),
  stackable: bool().nullable().optional(),
  carrier_name: string().nullable().required('Carrier Name is required.'),
  carrier_email: string().nullable().required('Carrier Email is required.'),
  carrier_phone_number: string()
    .nullable()
    .test('carrier_phone_number', 'Invalid phone number.', (value) => {
      if (value) {
        return validatePhoneNumber(value);
      }
      return true;
    })
    .optional(),
  is_all_day: bool().optional(),
  appointment_duration: string().nullable().required('Appointment time window is required.'),
  notes: string().nullable().optional()
});
