import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/without';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {get, find} from 'lodash';
import pluralize from 'pluralize';
import {compose} from 'recompose';
import {Checkbox, FileDisplay, Title, DeprecatedButton, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import getNil from 'App/utils/getNil';
import {formatDateTime} from 'App/utils/globals';
import {fetchDocumentTypes, fetchDocuments, postDocument} from 'App/actions/_documents';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import {documentPropType} from 'App/components/DocumentView';
import './styles.scss';

const UploadDocumentsBase = ({dispatch, shipment, setSuccess, setError, hideIcon, labelText}) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const handleUploadDocument = async (document) => {
    try {
      await dispatch(postDocument(shipment.id, document));
      setShowDocumentUpload(false);
      setSuccess('Success!', 'The document was uploaded.');
      dispatch(fetchDocuments(shipment.id));
    } catch (error) {
      setError('Error!', `There was an error uploading the document. ${get(error, 'error_description', '')}`);
      console.error('Error creating a shipment document', error);
    }
  };

  return (
    <>
      <DeprecatedButton
        variant="tertiary"
        icon={hideIcon ? null : <SvgIcon name="Upload" />}
        onClick={() => setShowDocumentUpload(true)}
      >
        {labelText}
      </DeprecatedButton>
      <Modal
        show={showDocumentUpload}
        title="Upload Document"
        footerComponent={null}
        onClose={() => setShowDocumentUpload(false)}
      >
        <ShipmentDocumentForm
          onCancel={() => setShowDocumentUpload(false)}
          onSubmit={handleUploadDocument}
          handleFetchDocumentTypes={() => dispatch(fetchDocumentTypes())}
        />
      </Modal>
    </>
  );
};

const UploadDocuments = compose(
  withStatusToasts,
  connect((state) => ({
    shipment: state.shipmentdetails.one
  }))
)(UploadDocumentsBase);

UploadDocumentsBase.propTypes = {
  dispatch: PropTypes.func,
  shipment: PropTypes.shape({
    id: PropTypes.string
  }),
  ...WithStatusToastsPropTypes,
  hideIcon: PropTypes.bool,
  labelText: PropTypes.node
};

UploadDocumentsBase.defaultProps = {
  dispatch: () => {},
  ...WithStatusToastsDefaultProps,
  hideIcon: false,
  labelText: 'Upload'
};

export {UploadDocuments};

export const NoDocuments = ({showUploadButton}) => (
  <div className="documentSelect__noDocuments">
    <Title variant="emptyStateHeader" className="documentSelect__noDocuments-title">
      No Documents
    </Title>
    {showUploadButton ? <UploadDocuments hideIcon labelText="Upload Document" /> : null}
  </div>
);

NoDocuments.propTypes = {
  showUploadButton: PropTypes.bool
};
NoDocuments.defaultProps = {
  showUploadButton: true
};

const DocumentSelect = ({
  documents,
  selectedDocuments,
  setSelectedDocuments,
  previewingDocument,
  onClickDocument,
  dispatch,
  documentTypes,
  hidePreviewCta,
  shipments,
  hideCheckboxes
}) => {
  useEffect(() => {
    if (!documentTypes.length) {
      dispatch(fetchDocumentTypes());
    }
  }, [documentTypes, dispatch]);
  const handleSelect = (event, id) => {
    if (event.target.checked) {
      setSelectedDocuments([...selectedDocuments, id]);
    } else {
      setSelectedDocuments(without(selectedDocuments, id));
    }
  };

  return (
    <div className="documentSelect">
      <ul data-testid="document-select-list">
        {documents.map((document) => {
          const isSelectedPreview = previewingDocument?.id === document.id;
          return (
            <li
              key={document.id}
              className={classNames('documentSelect__document', {
                previewing: isSelectedPreview,
                hidePreviewCta: hidePreviewCta,
                'pl-0': hideCheckboxes
              })}
            >
              {!hideCheckboxes ? (
                <div className="mr-2">
                  <Checkbox
                    checked={!!selectedDocuments?.includes(document.id)}
                    onChange={(e) => handleSelect(e, document.id)}
                  />
                </div>
              ) : null}
              <div className="documentSelect__document-container" onClick={() => onClickDocument(document)}>
                <div
                  className={classNames('documentSelect__document-imageContainer', {
                    previewing: isSelectedPreview
                  })}
                >
                  <FileDisplay
                    maxHeight={40}
                    maxWidth={40}
                    fileURL={document.file}
                    fileName={document.filename}
                    isThumbnail
                    altText={document.description}
                  />
                </div>
                <div
                  className={classNames('documentSelect__document-details', {
                    hidePreviewCta: hidePreviewCta
                  })}
                >
                  <span
                    className={classNames('documentSelect__document-details-primary', {
                      previewing: isSelectedPreview
                    })}
                  >
                    {shipments
                      ? `Shipment ${
                          shipments?.find((shipmentId) => shipmentId?.id === document?.shipment)?.reference_id
                        }`
                      : `${find(documentTypes, {id: get(document, 'type') || get(document, 'document_type')})?.name}`}
                  </span>
                  <span
                    className={classNames('documentSelect__document-details-primary', {
                      previewing: isSelectedPreview
                    })}
                  >
                    {getNil(document, 'description', '')}
                  </span>
                  <span className="documentSelect__document-details-secondary">
                    {`${formatDateTime(document.created_at)} ${
                      document.created_by_name ? `by ${document.created_by_name}` : ''
                    }`}
                  </span>
                </div>
                {!hidePreviewCta ? <span className="documentSelect__document-preview">PREVIEW</span> : null}
              </div>
            </li>
          );
        })}
      </ul>
      {selectedDocuments?.length > 0 && (
        <div className="documentSelect__selectedCount">{`${selectedDocuments.length} ${pluralize(
          'Document',
          selectedDocuments.length
        )} Selected`}</div>
      )}
    </div>
  );
};

DocumentSelect.propTypes = {
  documents: PropTypes.arrayOf(documentPropType),
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  setSelectedDocuments: PropTypes.func,
  previewingDocument: documentPropType,
  onClickDocument: PropTypes.func,
  dispatch: PropTypes.func,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  hidePreviewCta: PropTypes.bool,
  hideCheckboxes: PropTypes.bool,
  shipments: PropTypes.array
};
DocumentSelect.defaultProps = {
  hideCheckboxes: false
};

export const UnconnectedDocumentSelect = DocumentSelect;

export default connect((state) => ({
  documentTypes: state.documents.documentTypes
}))(DocumentSelect);
