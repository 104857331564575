import {
  TrackingCredentialsApi,
  TrackingCredentialsApiTrackingProviderCredentialsListRequest,
  TrackingCredentialsApiTrackingProviderCredentialsSaveRequest
} from '@shipwell/locus-sdk';
import {configuration} from './config';

const trackingCredentialsApi = new TrackingCredentialsApi(configuration);

export async function trackingProviderCredentialsList(
  params?: TrackingCredentialsApiTrackingProviderCredentialsListRequest
) {
  const response = await trackingCredentialsApi.trackingProviderCredentialsList(params);
  return response.data;
}

export async function trackingProviderCredentialsSave(
  params?: TrackingCredentialsApiTrackingProviderCredentialsSaveRequest
) {
  const response = await trackingCredentialsApi.trackingProviderCredentialsSave(params);
  return response.data;
}
