import {Field} from 'formik';
import {FormikTextarea, FormikFileSelect} from '@shipwell/shipwell-ui';

/**
 * Document Fields
 * @param {*} props
 */
const DocumentFields = ({isUpload = true}) => {
  return isUpload ? (
    <div className="field-grid">
      <div className="grid-item-2">
        <Field required name="file" label="Document description" component={FormikFileSelect} />
      </div>
      <div className="grid-item-2 mt-4">
        <Field name="description" label="Document description" component={FormikTextarea} />
      </div>
    </div>
  ) : (
    <div className="field-grid">
      <div className="grid-item-2">
        <Field name="description" label="Document description" component={FormikTextarea} />
      </div>
    </div>
  );
};

export default DocumentFields;
