import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Field} from 'formik';
import {FormikSelect, FormikTextarea} from '@shipwell/shipwell-ui';
import {getCarrierRelationshipsPromise} from 'App/actions/vendors';
import {getCarrierTagsPromise} from 'App/api/carriers';
import {getCarrierRelationships} from 'App/utils/carrierHelpers';
import {formatCarrierSearchOptions} from 'App/formComponents/fields/carrierSelect';

const GetSpotQuotesFormFields = (props) => {
  const {setFieldValue, values, authenticated, handleSpecialInstructions, special_instructions} = props;
  const [pagination, setPagination] = useState({page: 1, pageSize: 25, ordering: 'company', q: ''});

  // fetch carrier tags
  const getCarrierTags = async (name) => {
    const tagsPagination = pagination;
    if (name) {
      tagsPagination.name = name;
      tagsPagination.pageSize = null;
    }
    try {
      const response = await getCarrierTagsPromise(tagsPagination);
      if (response?.body?.results) {
        return response.body.results.map((result) => ({value: result.id, label: result.name}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // fetch carriers by passing the tag id's
  const handleVendorPOCsFromTags = () => {
    const tags = values.recipient_groups[values.recipient_groups.length - 1].value;
    getCarrierRelationshipsPromise({pageSize: 10000, tags: [tags]}).then((response) => {
      const userObjs = values.vendors && values.vendors.length > 0 ? [...values.vendors] : [];
      if (response && response.results) {
        response.results.forEach((vendor) => {
          if (vendor.point_of_contacts) {
            vendor.point_of_contacts.forEach((poc) => {
              const nameStr =
                vendor.shipwell_vendor.name +
                ' - ' +
                poc.first_name +
                (poc.last_name ? ' ' + poc.last_name : '') +
                ' (' +
                poc.email +
                ')';
              if (userObjs.filter((e) => e.value === poc.user).length === 0) {
                userObjs.push({label: nameStr, id: poc.user, carrier: vendor.id, carrierName: vendor.name});
              }
            });
          }
        });
        // populate the carriers Select with the result
        setFieldValue('vendors', userObjs);
      }
    });
  };

  /* handler for the 'special_instructions' field
      passes the value to the parent component, which
      then drills it down to <MessagePreview/> */
  const handleSpecialInstructionsChange = (value, setFieldValue) => {
    handleSpecialInstructions(value);
    setFieldValue('special_instructions', value);
  };
  /* Set the initial value on special_instructions, passing default value causes form reset */
  const setSpecialInstructions = (specialInstructions) => {
    setFieldValue('special_instructions', specialInstructions);
  };

  // update formik carrier value imperatively when tag is selected
  useEffect(() => {
    if (values.recipient_groups && values.recipient_groups.length > 0) {
      handleVendorPOCsFromTags();
    }
  }, [values.recipient_groups]);

  useEffect(() => {
    setSpecialInstructions(special_instructions);
  }, [special_instructions]);

  return (
    <>
      <div className="field-grid grid-1-1">
        <Field
          async
          defaultOptions
          loadOptions={getCarrierTags}
          isMulti
          label="Add Carriers by Tag"
          name="recipient_groups"
          component={FormikSelect}
        />
        {authenticated && (
          <Field
            label="Carrier(s)"
            name="vendors"
            async
            isMulti
            loadOptions={(val) =>
              getCarrierRelationships({q: val, carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']})
            }
            getOptionValue={(option) => option.id}
            formatOptionLabel={formatCarrierSearchOptions}
            component={FormikSelect}
            defaultOptions
            isOptionDisabled={(option) => option.carrierStatus === 'DO_NOT_USE'}
          />
        )}
        <div className="get-spot-quotes-message">
          <Field label="Message" disabled name="message" minRows={2} component={FormikTextarea} />
        </div>
        <Field
          label="Special Instructions"
          name="special_instructions"
          minRows={1}
          component={FormikTextarea}
          onChange={(e) => handleSpecialInstructionsChange(e.target.value, setFieldValue)}
        />
      </div>
    </>
  );
};
export default connect((state) => ({
  authenticated: state.auth.authenticated
}))(GetSpotQuotesFormFields);
