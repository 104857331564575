/* eslint-disable max-len */
import axios from 'axios';
import omit from 'lodash/omit';
import {ShipmentsApi} from '@shipwell/backend-core-sdk';
import {getShipwellApi, basePath} from 'App/api/config';
import {apiCallback, getAccessToken} from 'App/api/utils';

function createShipmentApi() {
  return new ShipmentsApi({
    basePath,
    apiKey: getAccessToken
  });
}

/**
 * Shipment - API
 */
export async function createShipmentPromise(shipmentObj, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPost(shipmentObj, opts, apiCallback(resolve, reject));
  });
}

export async function getShipmentDetailsPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function editShipmentPromise(shipmentId, shipmentObj, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdPut(shipmentId, shipmentObj, opts, apiCallback(resolve, reject));
  });
}

/**
 * Shipments - API
 * @deprecated use getShipments in `./typed.ts`
 */
export async function getShipmentsPromise(opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsGet(opts, apiCallback(resolve, reject));
  });
}

export async function fetchShipmentsByDatePromise(shipperId, authorization, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipperIdShipmentsPaymentsGet(shipperId, authorization, opts, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Documents
 */
export async function getShipmentDocuments(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function getShipmentDocument(shipmentId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdGet(shipmentId, documentId, apiCallback(resolve, reject));
  });
}

export async function updateShipmentDocument(shipmentId, documentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdPut(shipmentId, documentId, body, apiCallback(resolve, reject));
  });
}

export async function createDocument(shipmentId, file, type, description, isCarrierDocument) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsPost(
      shipmentId,
      file,
      type,
      description,
      isCarrierDocument,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteShipmentDocument(shipmentId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdDelete(shipmentId, documentId, apiCallback(resolve, reject));
  });
}

export async function getDocumentTypes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.documentsDocumentTypesGet(apiCallback(resolve, reject));
  });
}

export async function createShipmentDocument(shipmentId, formProps = {}) {
  const accessToken = await getAccessToken();

  return axios.post(`${basePath}/shipments/${shipmentId}/documents/`, formProps, {
    transformRequest: [
      (data) => {
        const formData = new FormData();
        formData.append('is_carrier_documents', data.isCarrierDocument);
        formData.append('description', data.description);
        formData.append('type', data.type);
        formData.append('file', data.file[0] || data.file);
        return formData;
      }
    ],
    headers: {
      authorization: accessToken
    }
  });
}

/**
 * Shipment BOL
 */
export async function createShipmentBOL(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsGenerateBolGet(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Rate Confirmation
 */
export async function createShipmentRateConfirmation(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsGenerateRateConfirmationGet(shipmentId, apiCallback(resolve, reject));
  });
}

export async function sendShipmentDocuments(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsSendPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function getShipmentDocumentsAuditlog(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdDocumentsAuditlogGet(shipmentId, apiCallback(resolve, reject));
  });
}

export async function getCustomerDocuments(brokerageId, shipperRelationshipId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsGet(
      brokerageId,
      shipperRelationshipId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function configCustomerDocumentPost(formProps, brokerageId, shipperRelationshipId) {
  const accessToken = await getAccessToken();

  const transformRequest = (data) => {
    const formData = new FormData();
    formData.append('description', data.description);
    formData.append('file', data.file[0] || data.file);
    return formData;
  };
  return axios.post(
    `${basePath}/brokerages/${brokerageId}/shipper-relationships/${shipperRelationshipId}/documents/`,
    formProps,
    {
      transformRequest: [transformRequest],
      headers: {
        authorization: accessToken
      }
    }
  );
}

export async function configCarrierDocumentPost(formProps, carrierRelationshipId) {
  const accessToken = await getAccessToken();

  const transformRequest = (data) => {
    const formData = new FormData();
    formData.append('description', data.description);
    formData.append('file', data.file[0] || data.file);
    return formData;
  };
  return axios.post(`${basePath}/carrier-relationships/${carrierRelationshipId}/documents/`, formProps, {
    transformRequest: [transformRequest],
    headers: {
      authorization: accessToken
    }
  });
}

export async function updateCustomerDocument(brokerageId, shipperRelationshipId, documentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsDocumentIdPut(
      brokerageId,
      shipperRelationshipId,
      documentId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteCustomerDocument(brokerageId, shipperRelationshipId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsDocumentIdDelete(
      brokerageId,
      shipperRelationshipId,
      documentId,
      apiCallback(resolve, reject)
    );
  });
}

export async function getCarrierDocuments(carrierRelationshipId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsGet(
      carrierRelationshipId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function updateCarrierDocument(carrierRelationshipId, documentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsDocumentIdPut(
      carrierRelationshipId,
      documentId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteCarrierDocumentPromise(carrierRelationshipId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsDocumentIdDelete(
      carrierRelationshipId,
      documentId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipment Tags - API
 */
export async function fetchTagsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTagsGet(apiCallback(resolve, reject));
  });
}

export async function postTagPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTagsPost(body, apiCallback(resolve, reject));
  });
}

export async function getTagPromise(shipmentTagId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTagsShipmentTagIdGet(shipmentTagId, apiCallback(resolve, reject));
  });
}

export async function editTagPromise(shipmentTagId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTagsShipmentTagIdPut(shipmentTagId, body, apiCallback(resolve, reject));
  });
}

export async function deleteTagPromise(shipmentTagId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTagsShipmentTagIdDelete(shipmentTagId, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Notes - API
 */
export async function getNotesPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdNotesGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function postNotesPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdNotesPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function putNotePromise(shipmentId, shipmentNoteId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdNotesShipmentNoteIdPut(
      shipmentId,
      shipmentNoteId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteNotePromise(shipmentId, shipmentNoteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdNotesShipmentNoteIdDelete(shipmentId, shipmentNoteId, apiCallback(resolve, reject));
  });
}

export async function postNotePromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdNotesPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Line Items - API
 */
export async function deleteShipmentLineItemPromise(shipmentId, lineItemId, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipmentsShipmentIdLineitemsShipmentLineItemIdDelete(
      shipmentId,
      lineItemId,
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

export async function postBreadcrumbPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTrackingPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Stops - API
 */
export async function shipmentsShipmentIdStopsPostPromise(shipmentId, body, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsPost(shipmentId, body, opts, apiCallback(resolve, reject));
  });
}
/**
 * Gets a single stop and it's children details by shipment id and the stop identifier.
 * @param {string} shipmentId
 * @param {string} stopId
 * @returns {Promise<{status: number, statusText: string, body: import('@shipwell/backend-core-sdk').Stop}>}
 */
export async function fetchStopPromise(shipmentId, stopId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdGet(shipmentId, stopId, {}, apiCallback(resolve, reject));
  });
}

export async function fetchStopsPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function deleteShipmentStopPromise(shipmentId, stopId, opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdDelete(shipmentId, stopId, opts, apiCallback(resolve, reject));
  });
}

export async function fetchStopAlerts(shipmentId, stopId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdAlertsGet(shipmentId, stopId, opts, apiCallback(resolve, reject));
  });
}

export async function removeStopAlert(shipmentId, stopId, alertId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdAlertsStopAlertIdDelete(
      shipmentId,
      stopId,
      alertId,
      apiCallback(resolve, reject)
    );
  });
}

export async function createStopAlert(shipmentId, stopId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdAlertsPost(shipmentId, stopId, body, apiCallback(resolve, reject));
  });
}

export async function createStopEvent(shipmentId, stopId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdEventsPost(shipmentId, stopId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Timeline - API
 */
export async function getShipmentTimelinePromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function postShipmentTimelinePromise(shipmentId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsPost(shipmentId, event, apiCallback(resolve, reject));
  });
}

export async function deleteShipmentTimelineEventPromise(shipmentId, eventId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdDelete(
      shipmentId,
      eventId,
      apiCallback(resolve, reject)
    );
  });
}

export async function shipmentsTimelineEventPutPromise(shipmentId, eventId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPut(
      shipmentId,
      eventId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipments Post Offers - API
 */
export async function postShipmentOfferPromise(shipmentId, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipmentsShipmentIdOnDemandPostOfferPost(
      shipmentId,
      body,
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

export async function getPostShipmentOfferPromise(shipmentId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipmentsShipmentIdOnDemandPostOfferGet(
      shipmentId,
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

export async function listShipmentOfferSuggestionsPromise(shipmentId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipmentsShipmentIdOnDemandOfferSuggestionGet(
      shipmentId,
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipments Messages - API
 */
export async function getShipmentMessagesPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdMessagesGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function postShipmentMessagePromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdMessagesPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Cancel - API
 */
export async function cancelShipmentPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCancelPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Share - API
 */
export async function shareLinkPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdSharePost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Truck Load Pricing - API
 */
export async function getTruckloadPriceEstimatePromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTruckloadPricingPost(body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Historical Truck Load Pricing Export - API
 */
export async function exportStandaloneTruckloadHistoricalPriceEstimate(stops, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsTruckloadHistoricalPricingCsvGet(stops, opts, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Download App - API
 */
export async function requestAppDownloadPromise(shipmentId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRequestAppDownloadPost(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Cell Tracking - API
 */
export async function requestApplessPingPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCellTrackingPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Start App Tracking - API
 */
export async function startAppTrackingPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.driversPushNotificationsTrackingPost({shipment: shipmentId}, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Auto Book - API
 * @param {string} shipmentId
 * @param {import('@shipwell/backend-core-sdk').AutoBookRequest} body
 * @returns {Promise<import('axios').AxiosResponse<import('@shipwell/backend-core-sdk').Shipment>>}
 */
export async function autoBookShipmentPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutoBookPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipment FTL Instant rate dispatch
 */
export async function dispatchFTLInstantRatePromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdFtlInstantRateDispatchPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Booked - API
 */
export async function sendShipmentBookedPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdSendShipmentBookedGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Award Quote - API
 */
export async function awardQuotePromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAwardQuotePost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Vendor Assignments - API
 */
export async function assignVendorPromise(shipmentId, body, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdVendorAssignmentsPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function editVendorAssignmentPromise(shipmentId, vendorAssignmentId, body, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCarrierAssignmentsCarrierAssignmentIdPut(
      shipmentId,
      vendorAssignmentId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function unassignCarrierPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCarrierAssignmentsDelete(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Cases - API
 */
export async function getCasesByShipmentIdPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCasesGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function createCaseForShipmentPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCasesPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function editCasePromise(shipmentId, shipmentCaseId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCasesShipmentCaseIdPut(
      shipmentId,
      shipmentCaseId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteCasePromise(shipmentId, caseId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCasesShipmentCaseIdDelete(shipmentId, caseId, apiCallback(resolve, reject));
  });
}

/**
 * Case Notes - API
 */
export async function getNotesByCaseIdPromise(shipmentCaseId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesGet(shipmentCaseId, opts, apiCallback(resolve, reject));
  });
}

export async function createNoteByCaseIdPromise(shipmentCaseId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesPost(shipmentCaseId, body, apiCallback(resolve, reject));
  });
}

export async function editCaseNotePromise(shipmentCaseId, caseNoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesCaseNoteIdPut(
      shipmentCaseId,
      caseNoteId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteNoteByCaseIdPromise(shipmentCaseId, noteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesCaseNoteIdDelete(shipmentCaseId, noteId, apiCallback(resolve, reject));
  });
}

/**
 * Case Tasks - API
 */
export async function getTasksByCaseIdPromise(shipmentCaseId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdTasksGet(shipmentCaseId, opts, apiCallback(resolve, reject));
  });
}

export async function createTaskInCasePromise(caseId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsCasesShipmentCaseIdTasksPost(caseId, body, apiCallback(resolve, reject));
  });
}

/**
 * Tasks - API
 */
export async function getTasksPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tasksGet(opts, apiCallback(resolve, reject));
  });
}

export async function createTaskPromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tasksPost(body, apiCallback(resolve, reject));
  });
}

export async function editTaskPromise(taskId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tasksTaskIdPut(taskId, body, apiCallback(resolve, reject));
  });
}

export async function deleteTaskPromise(taskId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tasksTaskIdDelete(taskId, apiCallback(resolve, reject));
  });
}

/**
 * External Shipment - API
 */
export async function fetchExternalShipmentPromise(shipmentId, key) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsExternalShipmentIdGet(shipmentId, key, apiCallback(resolve, reject));
  });
}

/**
 * Shipper Credit - API
 */
export async function getCreditOutstandingPromise(shipperId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipperIdCreditOutstandingGet(shipperId, authorization, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Reps - API
 */
export async function postRepPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function putRepPromise(shipmentId, repId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsRepIdPut(shipmentId, repId, body, apiCallback(resolve, reject));
  });
}

export async function deleteRepPromise(shipmentId, repId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsRepIdDelete(shipmentId, repId, apiCallback(resolve, reject));
  });
}

export async function fetchRepsPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsGet(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Shipment Automation Events - API
 */
export async function getAutomatedEventsPromise(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutomationEventsGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function scheduleAutomatedEventPromise(shipmentId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutomationEventsPost(shipmentId, event, apiCallback(resolve, reject));
  });
}

export async function editAutomatedEventPromise(shipmentId, eventId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutomationEventsAutomationEventIdPut(
      shipmentId,
      eventId,
      event,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteAutomatedEventPromise(shipmentId, eventId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutomationEventsAutomationEventIdDelete(
      shipmentId,
      eventId,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteScheduledCallsPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdAutomationEventsCancelScheduledDelete(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Check call - API
 */
export async function performOnDemandCheckCallPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdInitiateCheckCallPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * Equipment Config - API
 */
export async function assignEquipmentConfigPromise(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdEquipmentConfigPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function fetchEquipmentConfigPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdEquipmentConfigGet(shipmentId, apiCallback(resolve, reject));
  });
}

/**
 * Accessorials - API
 */
export async function fetchAccessorialCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsAccessorialsGet(apiCallback(resolve, reject));
  });
}

export async function fetchAccessorialChargeTablesPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesAccessorialChargeTablesGet(opts, apiCallback(resolve, reject));
  });
}

export async function fetchAccessorialChargeTablePromise(accessorialChargeTableId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdGet(
      accessorialChargeTableId,
      apiCallback(resolve, reject)
    );
  });
}

export async function postAccessorialChargeTablePromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesAccessorialChargeTablesPost(body, apiCallback(resolve, reject));
  });
}

export async function updateAccessorialChargeTablePromise(accessorialChargeTableId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdPut(
      accessorialChargeTableId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteAccessorialChargeTablePromise(accessorialChargeTableId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdDelete(
      accessorialChargeTableId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * NMFC Codes - API
 */
export async function fetchNMFCCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsNmfcGet(apiCallback(resolve, reject));
  });
}

/**
 * Hazmat Codes - API
 */
export async function fetchHazmatCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsHazmatGet(apiCallback(resolve, reject));
  });
}

/**
 * Package Types - API
 */
export async function fetchPackageTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPackageTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * Piece Types - API
 */
export async function fetchPieceTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPieceTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * Equipment Types - API
 */
export async function fetchEquipmentTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsEquipmentTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * Appointment Types - API
 */
export async function fetchAppointmentTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsAppointmentTypesGet(apiCallback(resolve, reject));
  });
}

/**
 * Service Levels - API
 */
export async function fetchServiceLevelsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsServiceLevelsGet(apiCallback(resolve, reject));
  });
}

/**
 * Shipment Modes - API
 */
export async function fetchShipmentModesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentModesGet(apiCallback(resolve, reject));
  });
}

/**
 * Shipment Statuses - API
 */
export const fetchShipmentStatusesPromise = async (opts = {}) => {
  const shipwellApi = await getShipwellApi();

  const response = await new Promise((resolve, reject) => {
    shipwellApi.shipmentsStatusesGet(omit(opts, 'filterDraftStatus'), apiCallback(resolve, reject));
  });

  if (opts.filterDraftStatus) {
    response.body = response.body.filter((option) => option?.id !== 'draft');
  }
  return response;
};
/**
 * Rep Roles - API
 */
export async function fetchShipmentRepRolesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentRepRolesGet(apiCallback(resolve, reject));
  });
}

/**
 * Linear Feet - API
 */
export async function getLinearFeetEstimatePromise(lineItems) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsLinearFeetPost(lineItems, apiCallback(resolve, reject));
  });
}

/**
 * Charge Line Item Categories - API
 */
export async function fetchChargeCategoriesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsChargeLineItemCategoriesGet(apiCallback(resolve, reject));
  });
}

/**
 * Shipment Pay - API
 */
export async function payShipmentPromise(shipmentId, source, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shippersShipmentsShipmentIdPayPut(
      shipmentId,
      {source_id: source},
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipment Pickups
 */
export async function createShipmentPickup(body = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPickupsPost(body, apiCallback(resolve, reject));
  });
}

export async function getShipmentPickup(pickupId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPickupsShipmentPickupIdGet(pickupId, apiCallback(resolve, reject));
  });
}

export async function updateShipmentPickup(pickupId, body = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPickupsShipmentPickupIdPut(pickupId, body, apiCallback(resolve, reject));
  });
}

export async function cancelShipmentPickup(pickupId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPickupsShipmentPickupIdCancelPost(pickupId, apiCallback(resolve, reject));
  });
}

export async function getShipmentPickups(opts = {}) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsPickupsGet(opts, apiCallback(resolve, reject));
  });
}

/*
 * Execute a routing guide for a shipment
 */
export async function initiateRoutingGuide(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRoutingGuideInitiatePost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function fetchCarrierConfig(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdCarrierConfigGet(shipmentId, apiCallback(resolve, reject));
  });
}

export async function postCarrierConfig(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCarrierConfigPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/*
 * Stop Event Types
 */
export async function getShipmentStopEventTypes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsStopEventTypesGet(apiCallback(resolve, reject));
  });
}

/*
 * Stop Status Reason Codes
 */
export async function getShipmentStopStatusReasonCodes() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsStopStatusReasonCodesGet(apiCallback(resolve, reject));
  });
}

/*
 * Stop Locations By Company Name
 */
export const getShipmentsStopLocationsByCompanyName = async (options) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsStopLocationsGet(
      {...options, companyNameContains: options?.q || ''},
      apiCallback(resolve, reject)
    );
  });
};

export async function validateTriumphPayAddress(address) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayValidateMailtoAddressPost(
      {formatted_address: address},
      apiCallback(resolve, reject)
    );
  });
}

/*
 * Shipment Tags
 */

export const getTagsFromShipment = (shipmentId) => {
  return createShipmentApi().shipmentsBundlesShipmentIdTagsGet(shipmentId);
};

export const addTagToShipment = (shipmentId, tagId) => {
  return createShipmentApi().shipmentsBundlesShipmentIdTagsShipmentTagIdPost(shipmentId, tagId);
};

export const removeTagFromShipment = (shipmentId, tagId) => {
  return createShipmentApi().shipmentsBundlesShipmentIdTagsShipmentTagIdDelete(shipmentId, tagId);
};

export const getDispatchJobDetails = (shipmentId, dispatchJobId) => {
  return createShipmentApi().shipmentsShipmentIdDispatchJobDispatchJobIdGet(shipmentId, dispatchJobId);
};
