import queryString from 'query-string';

/**
 * Build path params to be added to react router
 * @param {String} pathName A pathname string
 *
 * @return {Object}
 */

export default function parsePathParams(pathName) {
  const query = queryString.extract(pathName);
  const parsed = queryString.parse(query, {parseNumbers: true});
  for (const [key, value] of Object.entries(parsed)) {
    if (typeof value === 'string' && !isNaN(value)) {
      parsed[key] = parseInt(value, 10);
    }
  }
  return parsed;
}
