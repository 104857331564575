import _ from 'lodash';
import moment from 'moment';
import {
  ALERT_ERROR,
  CLEAR_SHIPMENT_STATE,
  GET_SHIPMENT_BY_ID,
  PUT_SHIPMENT_DETAILS,
  POST_NEW_LINEITEM,
  DELETE_SHIPMENT_LINEITEM,
  NEW_SHIPMENT_TIMELINE_EVENT,
  EDIT_SHIPMENT_TIMELINE_EVENT,
  CLEAR_TIMELINE_ADD_FORM,
  GET_SHIPMENT_TIMELINE,
  POST_SHIPMENT_TIMELINE_EVENT,
  PUT_TIMELINE_EVENT,
  DELETE_SHIPMENT_TIMELINE_EVENT,
  MANUAL_RATE_FINANCIALS,
  SELECT_FINANCIALS,
  SELECT_RATE_TABLE,
  CLEAR_RATE_TABLE,
  GET_SHIPMENT_MESSAGES,
  CANCEL_SHIPMENT,
  SHARE_LINK,
  CLEAR_NOTES,
  NOTES_LOADING,
  GET_NOTES,
  NEW_TRUCK_LOCATION,
  FETCH_BREADCRUMBS,
  CLEAR_BREADCRUMBS,
  CLEAR_STOP,
  POST_NEW_STOP,
  STOPS_LOADING,
  FETCH_STOPS,
  FETCH_STOP,
  FETCH_REPS,
  POST_REPS,
  PUT_REPS,
  GET_AUTOMATED_EVENTS,
  SELECT_AUTOMATED_EVENT
} from './types';
import {formatTime} from 'App/utils/globals';
import {shipment} from 'App/api';

function cleanLineItems(lineItems) {
  const propertiesToCheck = [
    'total_packages',
    'package_type',
    'freight_class',
    'length',
    'width',
    'height',
    'package_weight',
    'total_pieces',
    'nmfc_item_code',
    'nmfc_sub_code'
  ];

  lineItems.forEach((item) => {
    propertiesToCheck.forEach((property) => {
      if (!item[property]) {
        item[property] = null;
      }
    });
  });
}

/**
 * @todo Break this file out into separate files
 */
export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

const validatePayload = (shipment) => {
  // read only status should never be sent in payload
  delete shipment.rail_car_status;

  if (shipment.line_items) {
    cleanLineItems(shipment.line_items);
  }

  if (shipment.stops) {
    shipment.stops.forEach((stop, index) => {
      // format 24 hour if date object (i.e. 18:00:00)
      if (stop.planned_time_window_start && typeof stop.planned_time_window_start !== 'string') {
        stop.planned_time_window_start = formatTime(stop.planned_time_window_start);
      }

      if (stop.planned_time_window_end && typeof stop.planned_time_window_end !== 'string') {
        stop.planned_time_window_end = formatTime(stop.planned_time_window_end);
      }

      if (stop.planned_date) {
        stop.planned_date = moment(stop.planned_date).format('YYYY-MM-DD');
      }

      // ensure ordinal_index is an integer
      stop.ordinal_index = Number(stop.ordinal_index);

      // update first & last stop is_dropoff & is_pickup
      // in case ordinal_index was changed
      if (stop.ordinal_index === 0) {
        stop.is_pickup = true;
        stop.is_dropoff = false;
      } else if (index === shipment.stops.length - 1) {
        stop.is_pickup = false;
        stop.is_dropoff = true;
      }

      if (stop.confirmed_arrival_at && moment(stop.confirmed_arrival_at).isValid()) {
        stop.confirmed_arrival_at = moment(stop.confirmed_arrival_at).toDate().toISOString();
      } else {
        stop.confirmed_arrival_at = null;
      }

      if (stop.confirmed_departure_at && moment(stop.confirmed_departure_at).isValid()) {
        stop.confirmed_departure_at = moment(stop.confirmed_departure_at).toDate().toISOString();
      } else {
        stop.confirmed_departure_at = null;
      }
    });
  }

  // metadata and metadata.tags are required
  if (_.isEmpty(shipment.metadata)) {
    shipment.metadata = {};
  }

  // metadata and metadata.tags are required
  if (_.isEmpty(shipment.metadata.tags)) {
    shipment.metadata.tags = [];
  }

  return shipment;
};

/**
 * Shipment Details
 */
export function clearShipmentState() {
  return (dispatch) => {
    dispatch({type: CLEAR_SHIPMENT_STATE});
  };
}

export const shipmentRequestSuccess = (response) => ({
  type: GET_SHIPMENT_BY_ID,
  payload: response.body
});

export const shipmentUpdateSuccess = (response) => ({
  type: PUT_SHIPMENT_DETAILS,
  payload: response.body
});

export function shipmentsShipmentIdGet(shipmentId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getShipmentDetailsPromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function shipmentsShipmentIdPut(shipmentId, body, opts = {}) {
  validatePayload(body);

  return (dispatch) => {
    return shipment
      .editShipmentPromise(shipmentId, body, opts)
      .then((response) => {
        dispatch(shipmentUpdateSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function deleteShipmentStop(shipmentId, stopId, opts = {}) {
  return (dispatch) => {
    return shipment
      .deleteShipmentStopPromise(shipmentId, stopId, opts)
      .then(() => {
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error deleting this stop.'}));
      });
  };
}

/**
 * Shipment Line Items
 */
export function postNewLineitem() {
  return (dispatch) => {
    dispatch({type: POST_NEW_LINEITEM});
  };
}

export const shipmentLineitemDeleteSuccess = (response) => ({
  type: DELETE_SHIPMENT_LINEITEM,
  payload: response.body
});

export function deleteShipmentLineItem(shipmentId, body, removeLine, opts = {}) {
  validatePayload(body);
  body.line_items = body.line_items.filter((l, i) => i !== removeLine);

  return (dispatch) => {
    return shipment
      .editShipmentPromise(shipmentId, body, opts)
      .then((response) => {
        dispatch(shipmentLineitemDeleteSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Shipment Timeline
 */
export function postNewTimelineEvent() {
  return (dispatch) => {
    dispatch({type: NEW_SHIPMENT_TIMELINE_EVENT});
  };
}

export function editTimelineEvent(timeline) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SHIPMENT_TIMELINE_EVENT,
      payload: timeline
    });
  };
}

export function resetTimelineAddForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TIMELINE_ADD_FORM,
      payload: {}
    });
  };
}

export const shipmentTimelineRequestSuccess = (response) => ({
  type: GET_SHIPMENT_TIMELINE,
  payload: response.body
});

export const shipmentTimelineEventCreateSuccess = (response) => ({
  type: POST_SHIPMENT_TIMELINE_EVENT,
  payload: response.body
});

export const shipmentTimelineEventUpdateSuccess = (response) => ({
  type: PUT_TIMELINE_EVENT,
  payload: response.body
});

export const shipmentTimelineEventDeleteSuccess = (eventId) => ({
  type: DELETE_SHIPMENT_TIMELINE_EVENT,
  payload: eventId
});

export function getShipmentTimeline(shipmentId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getShipmentTimelinePromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentTimelineRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

export function postShipmentTimeline(shipmentId, event) {
  return (dispatch) => {
    return shipment
      .postShipmentTimelinePromise(shipmentId, event)
      .then((response) => {
        dispatch(shipmentTimelineEventCreateSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

export function deleteShipmentTimelineEvent(shipmentId, eventId) {
  return (dispatch) => {
    return shipment
      .deleteShipmentTimelineEventPromise(shipmentId, eventId)
      .then(() => {
        dispatch(shipmentTimelineEventDeleteSuccess(eventId));
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

export function shipmentsTimelineEventPut(shipmentId, eventId, body = {}) {
  return (dispatch) => {
    return shipment
      .shipmentsTimelineEventPutPromise(shipmentId, eventId, body)
      .then((response) => {
        dispatch(shipmentTimelineEventUpdateSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Shipment Financial
 */
export function manualRateFinancials() {
  return (dispatch) => {
    dispatch({
      type: MANUAL_RATE_FINANCIALS,
      payload: {markup: 0, financials: [{unit_quantity: 1}]}
    });
  };
}

export function selectFinancials(charge_line_items) {
  return (dispatch) => {
    return dispatch({
      type: SELECT_FINANCIALS,
      payload: charge_line_items
    });
  };
}

export const clearRateTableDetails = () => {
  return {
    type: CLEAR_RATE_TABLE,
    payload: {}
  };
};

export const selectedRateTableDetails = (selectRateTable) => ({
  type: SELECT_RATE_TABLE,
  payload: selectRateTable
});

/**
 * Shipment Messages
 */
export const shipmentMessagesRequestSuccess = (response) => ({
  type: GET_SHIPMENT_MESSAGES,
  payload: response.body
});

export function getShipmentMessages(shipmentId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getShipmentMessagesPromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentMessagesRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function postShipmentMessage(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .postShipmentMessagePromise(shipmentId, body)
      .then(() => {
        return {status: 200};
      })
      .catch((response) => {
        console.error('Error posting shipment message', response);
        throw response;
      });
  };
}

/**
 * Cancel Shipment
 */
export const shipmentCanceledSuccess = (response) => ({
  type: CANCEL_SHIPMENT,
  payload: response.body
});

export function cancelShipment(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .cancelShipmentPromise(shipmentId, body)
      .then((response) => {
        dispatch(shipmentCanceledSuccess(response));
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error cancelling this shipment.'}));
      });
  };
}

/**
 * Shipment Share Links
 */
export const shareLinkSuccess = (response) => ({
  type: SHARE_LINK,
  payload: response.body
});

export function shareLink(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .shareLinkPromise(shipmentId, body)
      .then((response) => {
        dispatch(shareLinkSuccess(response));
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error sharing this link'}));
      });
  };
}

/**
 * Shipment Notes
 */
export function clearCurrentNotes() {
  return (dispatch) => {
    dispatch({type: CLEAR_NOTES});
  };
}

export const shipmentNotesRequestPending = () => ({
  type: NOTES_LOADING
});

export const shipmentNotesRequestSuccess = (response) => ({
  type: GET_NOTES,
  payload: response.body
});

export function getNotes(shipmentId, opts = {}) {
  return (dispatch) => {
    dispatch(shipmentNotesRequestPending());

    return shipment
      .getNotesPromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentNotesRequestSuccess(response));
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error fetching notes'}));
        return {status: 400};
      });
  };
}

export function postNote(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .postNotePromise(shipmentId, body)
      .then(() => {
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error creating this note'}));
        return {status: 400};
      });
  };
}

export function deleteNote(shipmentId, shipmentNoteId) {
  return (dispatch) => {
    return shipment
      .deleteNotePromise(shipmentId, shipmentNoteId)
      .then(() => {
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error deleting this note'}));
        return {status: 400};
      });
  };
}

export function putNote(shipmentId, shipmentNoteId, body) {
  return (dispatch) => {
    return shipment
      .putNotePromise(shipmentId, shipmentNoteId, body)
      .then(() => {
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error editing this note'}));
        return {status: 400};
      });
  };
}

/**
 * Shipment Bread Crumbs
 */
export function postNewTruckLocation() {
  return (dispatch) => {
    dispatch({type: NEW_TRUCK_LOCATION});
  };
}

export const breadcrumbsRequestSuccess = (response, count) => ({
  type: FETCH_BREADCRUMBS,
  payload: response.body,
  meta: {
    key: count
  }
});

// NOTE: from v1
export function clearBreadcrumbs() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BREADCRUMBS
    });
  };
}

export function postBreadcrumb(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .postBreadcrumbPromise(shipmentId, body)
      .then(() => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400, details: response};
      });
  };
}

/**
 * Shipment Stops
 */
export function clearStop() {
  return (dispatch) => {
    dispatch({type: CLEAR_STOP});
  };
}

export function postNewStop() {
  return (dispatch) => {
    dispatch({type: POST_NEW_STOP});
  };
}

export const shipmentStopsRequestPending = () => ({
  type: STOPS_LOADING
});

export const shipmentStopsRequestSuccess = (response) => ({
  type: FETCH_STOPS,
  payload: response.body
});

export const shipmentStopRequestSuccess = (response) => ({
  type: FETCH_STOP,
  payload: response.body
});

export function fetchStops(shipmentId, opts = {}) {
  return (dispatch) => {
    dispatch(shipmentStopsRequestPending());

    return shipment
      .fetchStopsPromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentStopsRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400};
      });
  };
}

export function fetchStop(shipmentId, stopId) {
  return (dispatch) => {
    dispatch(shipmentStopsRequestPending());

    return shipment
      .fetchStopPromise(shipmentId, stopId)
      .then((response) => {
        dispatch(shipmentStopRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400};
      });
  };
}

/**
 * Shipment Equipment Config
 */
export function fetchEquipmentConfig(shipmentId) {
  return (dispatch) => {
    return shipment
      .fetchEquipmentConfigPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400};
      });
  };
}

/**
 * Shipment Reps
 */
export const shipmentRepsRequestSuccess = (response) => ({
  type: FETCH_REPS,
  payload: response.body
});

export const shipmentRepsCreateSuccess = (response) => ({
  type: POST_REPS,
  payload: response.body
});

export const shipmentRepsUpdateSuccess = (response) => ({
  type: PUT_REPS,
  payload: response.body
});

export function fetchReps(shipmentId) {
  return (dispatch) => {
    return shipment
      .fetchRepsPromise(shipmentId)
      .then((response) => {
        dispatch(shipmentRepsRequestSuccess(response));
        return {status: 200};
      })
      .catch(() => {
        dispatch(displayError({error_description: 'There was an error getting reps'}));
        return {status: 400};
      });
  };
}

export function postRep(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .postRepPromise(shipmentId, body)
      .then((response) => {
        dispatch(shipmentRepsCreateSuccess(response));
        return {status: 200};
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error adding a rep'}));
        return error;
      });
  };
}

export function putRep(shipmentId, repId, body = {}) {
  return (dispatch) => {
    return shipment
      .putRepPromise(shipmentId, repId, body)
      .then((response) => {
        dispatch(shipmentRepsUpdateSuccess(response));
        return {status: 200};
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error updating a rep'}));
        return error;
      });
  };
}

export function deleteRep(shipmentId, repId) {
  return (dispatch) => {
    return shipment
      .deleteRepPromise(shipmentId, repId)
      .then((response) => {
        return {status: 200};
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error deleting a rep'}));
        return error;
      });
  };
}

/**
 * Shipment Automated Events
 */
export function clearAutomatedEvents() {
  return (dispatch) => {
    dispatch({
      type: GET_AUTOMATED_EVENTS,
      payload: {results: []}
    });
  };
}

export function selectAutomatedEvent(event) {
  return (dispatch) => {
    dispatch({
      type: SELECT_AUTOMATED_EVENT,
      payload: event
    });
  };
}

export const automatedEventsRequest = (response) => ({
  type: GET_AUTOMATED_EVENTS,
  payload: response.body
});

export function getAutomatedEvents(shipmentId, opts = {pageSize: 100}) {
  return (dispatch) => {
    return shipment
      .getAutomatedEventsPromise(shipmentId, opts)
      .then((response) => {
        dispatch(automatedEventsRequest(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function scheduleAutomatedEvent(shipmentId, event) {
  return (dispatch) => {
    return shipment
      .scheduleAutomatedEventPromise(shipmentId, event)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function editAutomatedEvent(shipmentId, eventId, event) {
  return (dispatch) => {
    return shipment
      .editAutomatedEventPromise(shipmentId, eventId, event)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function deleteAutomatedEvent(shipmentId, eventId) {
  return (dispatch) => {
    return shipment
      .deleteAutomatedEventPromise(shipmentId, eventId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function deleteScheduledCalls(shipmentId) {
  return (dispatch) => {
    return shipment
      .deleteScheduledCallsPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function performOnDemandCheckCall(shipmentId, callBody) {
  return (dispatch) => {
    return shipment
      .performOnDemandCheckCallPromise(shipmentId, callBody)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}
