import {object, string, number, boolean} from 'yup';
import mergeWith from 'lodash/mergeWith';

const twoDecimalDigitRegex = /^\d+(\.?\d{1,2})?$/;

/* --FMCSA-- */

export const FMCSACardSettings = {
  cardTitle: 'FMCSA',
  cardIcon: '/images/fmcsa_logo.svg',
  cardHeader:
    'To set a global carrier status based on incoming FMCSA data values, map the value from the leftside column to what the Shipwell value should be on the right.',
  cardObjectTitle: 'FMCSAValues',
  sections: [
    {
      type: 'status',
      header: 'Operating Authority Status',
      selectHeader: 'Carrier Status',
      options: [
        {label: 'ACTIVE', correspondingFieldId: 'operating_authority_status_active'},
        {label: 'INACTIVE', correspondingFieldId: 'operating_authority_status_inactive'},
        {label: 'PENDING', correspondingFieldId: 'operating_authority_status_pending'},
        {label: 'NONE', correspondingFieldId: 'operating_authority_status_none'}
      ]
    },
    {
      type: 'status',
      header: 'Safety Rating Status',
      options: [
        {label: 'SATISFACTORY', correspondingFieldId: 'safety_rating_status_satisfactory'},
        {label: 'UNSATISFACTORY', correspondingFieldId: 'safety_rating_status_unsatisfactory'},
        {label: 'CONDITIONAL', correspondingFieldId: 'safety_rating_status_conditional'},
        {label: 'UNRATED', correspondingFieldId: 'safety_rating_status_unrated'}
      ]
    },
    {
      type: 'date',
      header: 'Safety Rating Date',
      textBefore: 'If the safety rating date is less than',
      textAfter: 'days old.',
      correspondingCounterFieldId: 'safety_rating_date_threshold',
      correspondingFieldId: 'safety_rating_date_policy',
      hideBorder: true
    }
  ]
};

export const FMCSABaseValues = {
  operating_authority_status_active: '',
  operating_authority_status_inactive: '',
  operating_authority_status_pending: '',
  operating_authority_status_none: '',
  safety_rating_status_satisfactory: '',
  safety_rating_status_unsatisfactory: '',
  safety_rating_status_conditional: '',
  safety_rating_status_unrated: '',
  safety_rating_date_threshold: null,
  safety_rating_date_policy: ''
};

/* --SAFERWATCH-- */

export const saferwatchCardSettings = {
  cardTitle: 'SaferWatch',
  cardIcon: '/images/saferwatch_icon.svg',
  cardHeader:
    'To set a global carrier status based on incoming SaferWatch data values, map the value from the leftside column to what the Shipwell value should be on the right.',
  cardObjectTitle: 'saferwatchValues',
  sections: [
    {
      type: 'status',
      header: 'Certification Status',
      selectHeader: 'Carrier Status',
      options: [
        {label: 'ACCEPTABLE', correspondingFieldId: 'certification_status_acceptable'},
        {label: 'MODERATE', correspondingFieldId: 'certification_status_moderate'},
        {label: 'UNACCEPTABLE', correspondingFieldId: 'certification_status_unacceptable'}
      ]
    }
  ],
  collapsedSections: [
    {
      type: 'status',
      header: 'Operating Authority Status',
      selectHeader: 'Carrier Status',
      options: [
        {label: 'ACTIVE', correspondingFieldId: 'operating_authority_status_active'},
        {label: 'AUTHORIZED', correspondingFieldId: 'operating_authority_status_authorized'},
        {label: 'INACTIVE', correspondingFieldId: 'operating_authority_status_inactive'},
        {label: 'NOT AUTHORIZED', correspondingFieldId: 'operating_authority_status_not_authorized'},
        {label: 'OUT OF SERVICE', correspondingFieldId: 'operating_authority_status_out_of_service'}
      ]
    },
    {
      type: 'status',
      header: 'Safety Rating Status',
      options: [
        {label: 'SATISFACTORY', correspondingFieldId: 'safety_rating_status_satisfactory'},
        {label: 'NON SATISFACTORY', correspondingFieldId: 'safety_rating_status_nonsatisfactory'},
        {label: 'CONDITIONAL', correspondingFieldId: 'safety_rating_status_conditional'},
        {label: 'UNRATED', correspondingFieldId: 'safety_rating_status_unrated'}
      ]
    },
    {
      type: 'date',
      header: 'Safety Rating Date',
      textBefore: 'If the safety rating date is less than',
      textAfter: 'days old.',
      correspondingCounterFieldId: 'safety_rating_date_threshold',
      correspondingFieldId: 'safety_rating_date_policy'
    },
    {
      type: 'status',
      header: 'Insurance Status',
      options: [
        {label: 'ACTIVE', correspondingFieldId: 'insurance_status_active'},
        {label: 'INACTIVE', correspondingFieldId: 'insurance_status_inactive'}
      ]
    },
    {
      type: 'select',
      header: 'Insurance Rating',
      textBefore: 'If rating is:',
      toolTipText: 'The typical range of actively used carriers has a rating of A++ to B.',
      correspondingSelectFieldId: 'insurance_rating_threshold',
      selectFieldOptions: [
        {label: 'A++ and below', value: 'A++'},
        {label: 'A+ and below', value: 'A+'},
        {label: 'A and below', value: 'A'},
        {label: 'A- and below', value: 'A-'},
        {label: 'B++ and below', value: 'B++'},
        {label: 'B+ and below', value: 'B+'},
        {label: 'B and below', value: 'B'},
        {label: 'B- and below', value: 'B-'},
        {label: 'C++ and below', value: 'C++'},
        {label: 'C+ and below', value: 'C+'},
        {label: 'C and below', value: 'C'},
        {label: 'C- and below', value: 'C-'},
        {label: 'D and below', value: 'D'},
        {label: 'E and below', value: 'E'},
        {label: 'F and below', value: 'F'},
        {label: 'S and below', value: 'S'}
      ],
      correspondingFieldId: 'insurance_rating_policy',
      fieldLabel: 'Rating Score'
    },
    {
      type: 'date',
      header: 'Cargo Insurance Expiration',
      textBefore: 'If insurance will expire within',
      textAfter: 'days.',
      correspondingCounterFieldId: 'cargo_insurance_expiration_threshold',
      correspondingFieldId: 'cargo_insurance_expiration_policy'
    },
    {
      type: 'select',
      header: 'Cargo Insurance Amount',
      textBefore: 'If the insured amount is:',
      toolTipText: 'The typical insured amount is anything greater than $200,000.',
      correspondingSelectFieldId: 'cargo_insurance_amount_threshold',
      selectFieldOptions: [
        {label: '< 1,000,000', value: 1000000},
        {label: '< 900,000', value: 900000},
        {label: '< 800,000', value: 800000},
        {label: '< 700,000', value: 700000},
        {label: '< 600,000', value: 600000},
        {label: '< 500,000', value: 500000},
        {label: '< 400,000', value: 400000},
        {label: '< 300,000', value: 300000},
        {label: '< 200,000', value: 200000},
        {label: '< 100,000', value: 100000}
      ],
      correspondingFieldId: 'cargo_insurance_amount_policy',
      fieldLabel: 'Insured Amount (USD)',
      hideBorder: true
    }
  ]
};

export const saferwatchBaseValues = {
  operating_authority_status_active: '',
  operating_authority_status_authorized: '',
  operating_authority_status_inactive: '',
  operating_authority_status_not_authorized: '',
  operating_authority_status_out_of_service: '',
  safety_rating_status_satisfactory: '',
  safety_rating_status_nonsatisfactory: '',
  safety_rating_status_conditional: '',
  safety_rating_status_unrated: '',
  safety_rating_date_threshold: null,
  safety_rating_date_policy: '',
  insurance_status_active: '',
  insurance_status_inactive: '',
  insurance_rating_threshold: '',
  insurance_rating_policy: '',
  cargo_insurance_expiration_threshold: null,
  cargo_insurance_expiration_policy: '',
  cargo_insurance_amount_threshold: 0,
  cargo_insurance_amount_policy: '',
  certification_status_acceptable: '',
  certification_status_moderate: '',
  certification_status_unacceptable: ''
};

/* --VALIDATION-- */

// returns true if all or none of the sectional values are set. sectionPrefix represents the prefix for the section, f.ex. operating_authority_status
const validateSection = (value, sectionPrefix, parent) => {
  const filteredKeys = Object.keys(parent).filter((key) => key.startsWith(sectionPrefix));
  return value || !filteredKeys.filter((key) => parent[key]).length;
};

const createSingleValidationSchema = (testedValue, testedValueSection) => {
  return string()
    .nullable()
    .test(testedValue, 'This field is required to have a status assigned.', function (value) {
      return validateSection(value, testedValueSection, this.parent);
    });
};

export const validationSchema = object().shape({
  FMCSAValues: object().shape({
    safety_rating_date_policy: string()
      .nullable()
      .test(
        'safety_rating_date_threshold',
        'Safety Rating Date must have a number value to set the Carrier Status to inactive.',
        function (value) {
          return !value || this.parent.safety_rating_date_threshold;
        }
      )
      .test('safety_rating_date_policy', 'This field is required to have a status assigned.', function (value) {
        return value || !this.parent.safety_rating_date_threshold;
      })
  }),
  saferwatchValues: object().shape({
    safety_rating_date_policy: string()
      .nullable()
      .test(
        'safety_rating_date_threshold',
        'Safety Rating Date must have a number value to set the Carrier Status to inactive.',
        function (value) {
          return !value || this.parent.safety_rating_date_threshold;
        }
      )
      .test('safety_rating_date_policy', 'This field is required to have a status assigned.', function (value) {
        return value || !this.parent.safety_rating_date_threshold;
      }),
    insurance_rating_threshold: createSingleValidationSchema('insurance_rating_threshold', 'insurance_rating'),
    insurance_rating_policy: createSingleValidationSchema('insurance_rating_policy', 'insurance_rating'),
    cargo_insurance_expiration_policy: string()
      .nullable()
      .test(
        'cargo_insurance_expiration_threshold',
        'Cargo Insurance Expiration Date must have a number value to set the Carrier Status to inactive.',
        function (value) {
          return !value || this.parent.cargo_insurance_expiration_threshold;
        }
      )
      .test('cargo_insurance_expiration_policy', 'This field is required to have a status assigned.', function (value) {
        return value || !this.parent.cargo_insurance_expiration_threshold;
      }),
    cargo_insurance_amount_threshold: number()
      .nullable()
      .test('cargo_insurance_amount_threshold', 'This field is required to have a status assigned.', function (value) {
        return validateSection(value, 'cargo_insurance_amount', this.parent);
      }),
    cargo_insurance_amount_policy: createSingleValidationSchema(
      'cargo_insurance_amount_policy',
      'cargo_insurance_amount'
    )
  }),
  RMISValues: object().shape({
    safety_rating_date_policy: string()
      .nullable()
      .test(
        'safety_rating_date_threshold',
        'Safety Rating Date must have a number value to set the Carrier Status to inactive.',
        function (value) {
          return !value || this.parent.safety_rating_date_threshold;
        }
      )
      .test('safety_rating_date_policy', 'This field is required to have a status assigned.', function (value) {
        return value || !this.parent.safety_rating_date_threshold;
      }),
    insurance_rating_threshold: createSingleValidationSchema('insurance_rating_threshold', 'insurance_rating'),
    insurance_rating_policy: createSingleValidationSchema('insurance_rating_policy', 'insurance_rating'),
    cargo_insurance_expiration_policy: string()
      .nullable()
      .test(
        'cargo_insurance_expiration_threshold',
        'Cargo Insurance Expiration Date must have a number value to set the Carrier Status to inactive.',
        function (value) {
          return !value || this.parent.cargo_insurance_expiration_threshold;
        }
      )
      .test('cargo_insurance_expiration_policy', 'This field is required to have a status assigned.', function (value) {
        return value || !this.parent.cargo_insurance_expiration_threshold;
      }),
    cargo_insurance_amount_threshold: number()
      .nullable()
      .test('cargo_insurance_amount_threshold', 'This field is required to have a status assigned.', function (value) {
        return validateSection(value, 'cargo_insurance_amount', this.parent);
      }),
    cargo_insurance_amount_policy: createSingleValidationSchema(
      'cargo_insurance_amount_policy',
      'cargo_insurance_amount'
    )
  }),
  // backend allows up to 10 digits, 2 decimals
  CarrierInsuranceDefaultValues: object().shape({
    ftl_default_declared_value: number()
      .min(0, 'Default Declared Value must be a positive number')
      .max(99999999.99, 'Default Declared Value must be less than 99999999.99')
      .test('ftl_default_declared_value', 'Only two decimal places allowed.', function (value) {
        if (!value) {
          return true;
        }

        return twoDecimalDigitRegex.test(value);
      })
      .nullable(),
    ftl_default_declared_value_currency: string().nullable(false).default('USD'),
    is_ftl_default_declared_value_enabled: boolean().nullable(false).default(false),
    ltl_default_declared_value: number()
      .min(0, 'Default Declared Value must be a positive number')
      .max(99999999.99, 'Default Declared Value must be less than 99999999.99')
      .test('ftl_default_declared_value', 'Only two decimal places allowed.', function (value) {
        if (!value) {
          return true;
        }

        return twoDecimalDigitRegex.test(value);
      })
      .nullable(),
    ltl_default_declared_value_currency: string().nullable(false).default('USD'),
    is_ltl_default_declared_value_enabled: boolean().nullable(false).default(false),
    vltl_default_declared_value: number()
      .min(0, 'Default Declared Value must be a positive number')
      .max(99999999.99, 'Default Declared Value must be less than 99999999.99')
      .test('ftl_default_declared_value', 'Only two decimal places allowed.', function (value) {
        if (!value) {
          return true;
        }

        return twoDecimalDigitRegex.test(value);
      })
      .nullable(),
    vltl_default_declared_value_currency: string().nullable(false).default('USD'),
    is_vltl_default_declared_value_enabled: boolean().nullable(false).default(false),
    rail_default_declared_value: number()
      .min(0, 'Default Declared Value must be a positive number')
      .max(99999999.99, 'Default Declared Value must be less than 99999999.99')
      .test('ftl_default_declared_value', 'Only two decimal places allowed.', function (value) {
        if (!value) {
          return true;
        }

        return twoDecimalDigitRegex.test(value);
      })
      .nullable(),
    rail_default_declared_value_currency: string().nullable(false).default('USD'),
    is_rail_default_declared_value_enabled: boolean().nullable(false).default(false),
    drayage_default_declared_value: number()
      .min(0, 'Default Declared Value must be a positive number')
      .max(99999999.99, 'Default Declared Value must be less than 99999999.99')
      .test('ftl_default_declared_value', 'Only two decimal places allowed.', function (value) {
        if (!value) {
          return true;
        }

        return twoDecimalDigitRegex.test(value);
      })
      .nullable(),
    drayage_default_declared_value_currency: string().nullable(false).default('USD'),
    is_drayage_default_declared_value_enabled: boolean().nullable(false).default(false)
  })
});

export const massageFormValues = (baseValues, formValues) => {
  // ensures that status fields are empty strings and number fields are null
  return mergeWith({}, baseValues, formValues, (baseValue, newValue) =>
    !baseValue && !newValue ? baseValue : newValue
  );
};

/* --RMIS-- */

export const RMISCardSettings = {
  cardTitle: 'RMIS',
  cardIcon: '/images/RMIS_icon.svg',
  cardHeader:
    'To set a global carrier status based on incoming RMIS data values, map the value from the leftside column to what the Shipwell value should be on the right.',
  cardObjectTitle: 'RMISValues',
  sections: [
    {
      type: 'status',
      header: 'Certification Status',
      selectHeader: 'Carrier Status',
      options: [
        {label: 'CERTIFIED', correspondingFieldId: 'certification_status_certified'},
        {label: 'NOT CERTIFIED', correspondingFieldId: 'certification_status_not_certified'},
        {label: 'UNKNOWN', correspondingFieldId: 'certification_status_unknown'}
      ]
    },
    {
      type: 'checkbox',
      header: 'Perform real time status check when assigning carrier.',
      correspondingFieldId: 'real_time_sync'
    }
  ],
  collapsedSections: [
    {
      type: 'status',
      header: 'Operating Authority Status',
      selectHeader: 'Carrier Status',
      options: [
        {label: 'ACTIVE', correspondingFieldId: 'operating_authority_status_active'},
        {label: 'AUTHORIZED', correspondingFieldId: 'operating_authority_status_authorized'},
        {label: 'INACTIVE', correspondingFieldId: 'operating_authority_status_inactive'},
        {label: 'NOT AUTHORIZED', correspondingFieldId: 'operating_authority_status_not_authorized'},
        {label: 'OUT OF SERVICE', correspondingFieldId: 'operating_authority_status_out_of_service'}
      ]
    },
    {
      type: 'status',
      header: 'Safety Rating Status',
      options: [
        {label: 'SATISFACTORY', correspondingFieldId: 'safety_rating_status_satisfactory'},
        {label: 'NON SATISFACTORY', correspondingFieldId: 'safety_rating_status_nonsatisfactory'},
        {label: 'CONDITIONAL', correspondingFieldId: 'safety_rating_status_conditional'},
        {label: 'UNRATED', correspondingFieldId: 'safety_rating_status_unrated'}
      ]
    },
    {
      type: 'date',
      header: 'Safety Rating Date',
      textBefore: 'If the safety rating date is less than',
      textAfter: 'days old.',
      correspondingCounterFieldId: 'safety_rating_date_threshold',
      correspondingFieldId: 'safety_rating_date_policy'
    },
    {
      type: 'status',
      header: 'Insurance Status',
      options: [
        {label: 'ACTIVE', correspondingFieldId: 'insurance_status_active'},
        {label: 'INACTIVE', correspondingFieldId: 'insurance_status_inactive'}
      ]
    },
    {
      type: 'select',
      header: 'Insurance Rating',
      textBefore: 'If rating is:',
      toolTipText: 'The typical range of actively used carriers has a rating of A++ to B.',
      correspondingSelectFieldId: 'insurance_rating_threshold',
      selectFieldOptions: [
        {label: 'A++ and below', value: 'A++'},
        {label: 'A+ and below', value: 'A+'},
        {label: 'A and below', value: 'A'},
        {label: 'A- and below', value: 'A-'},
        {label: 'B++ and below', value: 'B++'},
        {label: 'B+ and below', value: 'B+'},
        {label: 'B and below', value: 'B'},
        {label: 'B- and below', value: 'B-'},
        {label: 'C++ and below', value: 'C++'},
        {label: 'C+ and below', value: 'C+'},
        {label: 'C and below', value: 'C'},
        {label: 'C- and below', value: 'C-'},
        {label: 'D and below', value: 'D'},
        {label: 'E and below', value: 'E'},
        {label: 'F and below', value: 'F'},
        {label: 'S and below', value: 'S'}
      ],
      correspondingFieldId: 'insurance_rating_policy',
      fieldLabel: 'Rating Score'
    },
    {
      type: 'date',
      header: 'Cargo Insurance Expiration',
      textBefore: 'If insurance will expire within',
      textAfter: 'days.',
      correspondingCounterFieldId: 'cargo_insurance_expiration_threshold',
      correspondingFieldId: 'cargo_insurance_expiration_policy'
    },
    {
      type: 'select',
      header: 'Cargo Insurance Amount',
      textBefore: 'If the insured amount is:',
      toolTipText: 'The typical insured amount is anything greater than $200,000.',
      correspondingSelectFieldId: 'cargo_insurance_amount_threshold',
      selectFieldOptions: [
        {label: '< 1,000,000', value: 1000000},
        {label: '< 900,000', value: 900000},
        {label: '< 800,000', value: 800000},
        {label: '< 700,000', value: 700000},
        {label: '< 600,000', value: 600000},
        {label: '< 500,000', value: 500000},
        {label: '< 400,000', value: 400000},
        {label: '< 300,000', value: 300000},
        {label: '< 200,000', value: 200000},
        {label: '< 100,000', value: 100000}
      ],
      correspondingFieldId: 'cargo_insurance_amount_policy',
      fieldLabel: 'Insured Amount (USD)',
      hideBorder: true
    }
  ]
};

export const RMISBaseValues = {
  operating_authority_status_active: '',
  operating_authority_status_authorized: '',
  operating_authority_status_inactive: '',
  operating_authority_status_not_authorized: '',
  operating_authority_status_out_of_service: '',
  safety_rating_status_satisfactory: '',
  safety_rating_status_nonsatisfactory: '',
  safety_rating_status_conditional: '',
  safety_rating_status_unrated: '',
  safety_rating_date_threshold: null,
  safety_rating_date_policy: '',
  insurance_status_active: '',
  insurance_status_inactive: '',
  insurance_rating_threshold: '',
  insurance_rating_policy: '',
  cargo_insurance_expiration_threshold: null,
  cargo_insurance_expiration_policy: '',
  cargo_insurance_amount_threshold: 0,
  cargo_insurance_amount_policy: '',
  certification_status_certified: '',
  certification_status_not_certified: '',
  certification_status_unknown: ''
};

export const CarrierInsuranceCoverageRulesBaseValues = {
  is_ftl_default_declared_value_enabled: false,
  ftl_default_declared_value: null,
  ftl_default_declared_value_currency: 'USD',

  is_ltl_default_declared_value_enabled: false,
  ltl_default_declared_value: null,
  ltl_default_declared_value_currency: 'USD',

  is_vltl_default_declared_value_enabled: false,
  vltl_default_declared_value: null,
  vltl_default_declared_value_currency: 'USD',

  is_rail_default_declared_value_enabled: false,
  rail_default_declared_value: null,
  rail_default_declared_value_currency: 'USD',

  is_drayage_default_declared_value_enabled: false,
  drayage_default_declared_value: null,
  drayage_default_declared_value_currency: 'USD'
};
