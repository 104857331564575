import isNil from 'lodash/isNil';
import {useMemo} from 'react';
import {UseQueryOptions} from '@tanstack/react-query';
import {Facility, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {AxiosError} from 'axios';
import {useFacilityQuery} from './useFacilities';
import {useShipmentStopQuery} from 'App/data-hooks/stops/useShipmentStops';

export type UseShipmentStopFacilityQueryOptions = Omit<
  UseQueryOptions<
    Facility | null,
    AxiosError<ShipwellApiErrorResponse>,
    Facility | null,
    (string | null | undefined)[]
  >,
  'queryKey' | 'queryFn'
>;

export type UseShipmentStopFacilityQueryRequest = {
  shipmentId?: string | null;
  stopId?: string | null;
};
/**
 * Fetches stop details and gets the associated facility with the stop if it was created
 * using a shippers Address Book Entry.
 */
const useShipmentStopFacilityQuery = (
  {shipmentId, stopId}: UseShipmentStopFacilityQueryRequest,
  options?: UseShipmentStopFacilityQueryOptions
) => {
  const isEnabled = useMemo(() => {
    if (isNil(options?.enabled)) {
      return Boolean(shipmentId) && Boolean(stopId);
    }
    return Boolean(shipmentId) && Boolean(stopId) && options?.enabled;
  }, [shipmentId, stopId, options]);

  const {stop, isStopsInitialLoading, isStopsSuccess, isStopsError, stopError} = useShipmentStopQuery(
    {shipmentId, stopId},
    {
      enabled: isEnabled
    }
  );

  const {facility_id} = stop?.location ?? {facility_id: null};

  const {facility, isFacilityError, isFacilityLoading, isFacilitySuccess, facilityError, refetchFacility} =
    useFacilityQuery({
      facilityId: facility_id
    });

  const missingFacilityError = useMemo<ShipwellApiErrorResponse | null>(() => {
    // build a custom error for the consumer to know that we have no data that
    // was requested of the API. This can occur if there is a stop but no address book entry
    // exits on that stop and thus the hook never fires which presents an indefinite "isSuccess"
    // as a false state.
    if (isNil(facility_id) && isStopsSuccess) {
      return {
        errors: [
          {
            title: 'Error',
            code: '404',
            status: 'NotFound',
            detail: 'No Facility exists with the given Stop'
          }
        ]
      };
    }
    return null;
  }, [facility_id, isStopsSuccess]);

  return {
    isFacilityLoading: isStopsInitialLoading || isFacilityLoading,
    isFacilitySuccess: isStopsSuccess && isFacilitySuccess,
    isFacilityError: isStopsError || isFacilityError || Boolean(missingFacilityError),
    facilityError: facilityError ?? stopError ?? missingFacilityError,
    facility: facility,
    refetchShipmentStopFacility: refetchFacility
  };
};

export {useShipmentStopFacilityQuery};
