/* global google */
import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {Image, Navbar, Label, OverlayTrigger, Tooltip, Row, Col, Button} from 'react-bootstrap';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import {style} from 'App/InLineStyles/AnalyzeStyle';
import {options} from 'App/utils/map_options_details';
import {getOS} from 'App/utils/globals';
import './_mobile-redirect.scss';

var markersArray = [];

@connect(
  (state) => ({
    brokerLogos: state.brokers.brokerLogos,
    outsideShipment: state.shipments.outsideShipment
  }),
  {...actions, ...brokerActions}
)
export default class MobileRedirect extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
  }

  componentWillMount() {
    const os = getOS();
    this.setState({operatingSystem: os});
    //if ios or android, redirect to the app
    if (os === 'iOS') {
      window.location.assign('https://itunes.apple.com/us/app/shipwell/id1236974263?mt=8');
    } else if (os === 'Android') {
      window.location.assign('https://play.google.com/store/apps/details?id=com.shipwell');
      //TODO figure out this redirect when ready
      //window.location.assign('shipwell://home');
    }
  }

  render() {
    let inlineLogo = '';
    let whiteLogo = '';

    if (this.props.brokerLogos) {
      for (var i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'INLINE_COLOR') {
          inlineLogo = this.props.brokerLogos[i];
        } else if (this.props.brokerLogos[i].image_type === 'LOGO_WHITE') {
          whiteLogo = this.props.brokerLogos[i];
        }
      }
    }
    return (
      <div className="mobile-redirect">
        <section className="container">
          <div className="mobile-redirect__container">
            <Image className="mobile-redirect__shipwell" src={inlineLogo.logo} alt="Logo" />
            <div className="mobile-redirect__badges">
              <a href="https://itunes.apple.com/us/app/shipwell/id1236974263?mt=8">
                <Image className="mobile-redirect__apple" src="/images/app_store.svg" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.shipwell">
                <Image className="mobile-redirect__google" src="/images/google_store.png" />
              </a>
            </div>
          </div>
        </section>

        <footer className="text-center">
          <small>© 2016-{new Date().getFullYear()} Shipwell®, All Rights Reserved.</small>
        </footer>
      </div>
    );
  }
}
