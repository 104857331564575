import {useState} from 'react';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {WithRouterProps} from 'react-router';
import {RfpCarrierBidRequestOptResponseBidRequestStateEnum} from '@shipwell/backend-core-sdk';
import {BidStep, BidRequestDetails, BidRequestMessage, ImportBidModal, BiddingCountdown} from './';
import {getOptimizedRfpCarrierBidDetails, getRfpCarrierCsv} from 'App/api/rfpOptimized/typed';
import Loader from 'App/common/shipwellLoader';
import {RFP_CSV_QUERY, RFP_BIDS_QUERY} from 'App/data-hooks/queryKeys';
import PageHeader from 'App/common/pageHeader';

const RfpBidRequest = ({params}: Partial<WithRouterProps>) => {
  const bidId = params?.bidId;
  const [showBidModal, setShowBidModal] = useState(false);

  const queryClient = useQueryClient();

  const rfpBidRequestQuery = useQuery(
    [RFP_BIDS_QUERY, bidId],
    async () => {
      if (!bidId) {
        return Promise.reject(new Error('Invalid bidId'));
      }
      const response = await getOptimizedRfpCarrierBidDetails(bidId);
      return response?.data;
    },
    {
      enabled: !!bidId
    }
  );

  const rfpCsvQuery = useQuery(
    [RFP_CSV_QUERY, bidId],
    async () => {
      const response = await getRfpCarrierCsv(bidId || '');
      return response.data;
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess(data) {
        const csvHeader = 'data:text/csv;charset=uft-8,';
        const csvContent = `${csvHeader}${data}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${rfpBidRequestQuery.data?.shipper_name || ''}-carrier-bid-request.csv`);
        document.body.appendChild(link);
        link.click();
        // refetch data to see step progess
        return queryClient.invalidateQueries([RFP_BIDS_QUERY, bidId]);
      }
    }
  );

  if (rfpBidRequestQuery.isError) {
    return (
      <BidRequestMessage message="Bidding has expired for this RFP.">
        <SvgIcon color="sw-error" width="84" height="84" name="ErrorOutlined" />
      </BidRequestMessage>
    );
  }

  const handleImportSuccess = () => {
    setShowBidModal(false);
    // on import success fetch fresh data to see step 4 completed
    void queryClient.invalidateQueries([RFP_BIDS_QUERY, bidId]);
  };

  return (
    <div className="bg-sw-background min-h-screen-minus-16">
      <PageHeader backArrow title="All RFP Requests" />
      <div className="flex max-w-7xl flex-col gap-8 p-8">
        {rfpBidRequestQuery.isInitialLoading ? (
          <Loader loading />
        ) : rfpBidRequestQuery.isSuccess ? (
          <div className="flex flex-col gap-4 md:grid md:auto-rows-min md:grid-cols-2 lg:grid-cols-4">
            <BiddingCountdown
              expiryDateTime={rfpBidRequestQuery.data.rfp_bid_opt_details?.bid_expiry_date_time || ''}
            />
            <div className="flex lg:col-span-3">
              <BidRequestDetails rfpBidRequest={rfpBidRequestQuery.data} />
            </div>
            <BidStep isDisabled={false} number={1} label="Download RFP">
              <DeprecatedButton
                variant={
                  rfpBidRequestQuery.data.bid_request_state ===
                  RfpCarrierBidRequestOptResponseBidRequestStateEnum.RequestNotDownloaded
                    ? 'primary'
                    : 'secondary'
                }
                disabled={rfpCsvQuery.isInitialLoading}
                onClick={() => void rfpCsvQuery.refetch()}
              >
                Download RFP
              </DeprecatedButton>
            </BidStep>
            <BidStep
              isDisabled={
                rfpBidRequestQuery.data.bid_request_state ===
                RfpCarrierBidRequestOptResponseBidRequestStateEnum.RequestNotDownloaded
              }
              number={2}
              label="Input Bid on Lanes"
            >
              <span className="italic">Add either a Flat Rate or RPM per lane on spreadsheet.</span>
            </BidStep>
            <BidStep
              isDisabled={
                rfpBidRequestQuery.data.bid_request_state ===
                RfpCarrierBidRequestOptResponseBidRequestStateEnum.RequestNotDownloaded
              }
              number={3}
              label="Upload Responses"
            >
              <DeprecatedButton
                disabled={
                  rfpBidRequestQuery.data.bid_request_state ===
                  RfpCarrierBidRequestOptResponseBidRequestStateEnum.RequestNotDownloaded
                }
                variant={
                  rfpBidRequestQuery.data.bid_request_state ===
                  RfpCarrierBidRequestOptResponseBidRequestStateEnum.RequestDownloaded
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() => setShowBidModal(true)}
              >
                Upload Responses
              </DeprecatedButton>
            </BidStep>
            <BidStep
              isDisabled={
                rfpBidRequestQuery.data.bid_request_state !==
                RfpCarrierBidRequestOptResponseBidRequestStateEnum.ResponseUploaded
              }
              number={4}
              label="Complete!"
            >
              <div className="flex items-center justify-center">
                {rfpBidRequestQuery.data.bid_request_state ===
                RfpCarrierBidRequestOptResponseBidRequestStateEnum.ResponseUploaded ? (
                  <SvgIcon color="sw-success" height="40" width="40" name="CheckCircleFilled" />
                ) : (
                  <SvgIcon color="grey-3" height="40" width="40" name="CheckCircleOutlined" />
                )}
              </div>
            </BidStep>
          </div>
        ) : null}
      </div>
      {showBidModal ? (
        <ImportBidModal
          rfpCarrierBidRequestId={rfpBidRequestQuery.data?.id || ''}
          onClose={() => setShowBidModal(false)}
          onSuccess={handleImportSuccess}
        />
      ) : null}
    </div>
  );
};

export default RfpBidRequest;
