/* eslint-disable promise/prefer-await-to-then */
import {CREATE_TENDER, GET_TENDERS_SUCCESS} from './types';
import {tenders} from 'App/api';
import {fetchEquipmentTypesPromise} from 'App/api/shipment';
import {unpackErrors} from 'App/utils/globals';

export const getEquipmentTypes = async () => {
  const response = await fetchEquipmentTypesPromise();
  if (response && response.body && response.body.length) {
    return response.body;
  }
};
export const tenderCreateSuccess = (response) => ({
  type: CREATE_TENDER,
  payload: response.body
});

export function createTenderRequest(body) {
  return (dispatch) => {
    return tenders
      .createTender(body)
      .then((response) => {
        dispatch(tenderCreateSuccess(response));
        return response.body;
      })
      .catch((error) => {
        const errors = error.field_errors || [];
        let myError = {};
        myError = unpackErrors(errors, myError, ['charge_line_items']);
        myError._error = error.error_description;
        //return myError object as string
        throw new Error(JSON.stringify(myError));
      });
  };
}

export const tenderGetSuccess = (response) => ({
  type: GET_TENDERS_SUCCESS,
  payload: response.body
});

export const getTenderRequests = (opts) => {
  return async (dispatch) => {
    try {
      const response = await tenders.getTenders(opts);
      const equipResponse = await getEquipmentTypes();
      if (response && response.body && response.body.results && equipResponse) {
        //convert machine readable to consumable object
        response.body.results.forEach((tender) => {
          equipResponse.forEach((equip) => {
            if (tender.equipment_type === equip.machine_readable) {
              tender.equipment_type = equip;
            }
          });
        });
      }
      dispatch(tenderGetSuccess(response));
      return response.body;
    } catch (error) {
      return error;
    }
  };
};
