import {CarrierSelectionPageResponse} from '@shipwell/backend-core-sdk';

// returns an array of point of contacts with their carrier details
export const getCarrierPocs = (carriers: CarrierSelectionPageResponse[]) =>
  carriers
    .map((carrier) => {
      return carrier.point_of_contacts?.map((poc) => {
        return {
          // order here is important:
          // both the poc and carrier have tags, but only the carrier tags are populated
          ...poc,
          ...carrier,
          id: poc.user
        };
      });
    })
    .flat();
