import {JSX, useCallback, useState} from 'react';
import {WithRouterProps} from 'react-router';
import {Form, Formik} from 'formik';
import {cloneDeep} from 'lodash/fp';

import {Loader} from '@shipwell/shipwell-ui';

import {facilityOperationCapacityValidationSchema} from '../schema';
import {FacilityAttributesFields} from '../forms/AttributesForm';
import {facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';
import {FacilityAttributesFormValues} from 'App/data-hooks/facilities/types';
import {useMutableFacilityAttributes} from 'App/data-hooks';

import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {parseV3ApiError} from 'App/api/typedUtils';
import FormFooter from 'App/formComponents/formSections/formFooter';
import Error404Page from 'App/common/Error404Page';
import ErrorPage from 'App/common/ErrorPage';

type FacilityAttributesProps = WithRouterProps<{id: string}> & WithStatusToastProps;

const FacilityAttributesBase = ({
  params: {id: facilityId},
  setError,
  setSuccess
}: FacilityAttributesProps): JSX.Element => {
  const onSuccess = useCallback(() => {
    setSuccess('Update Successful!', 'Successfully updated facility attributes.');
  }, [setSuccess]);
  const onError = useCallback(
    (error: Error) => {
      const formattedError = parseV3ApiError(error);
      setError('Failed to update facility attributes', `${formattedError.title}<br />${formattedError.detail}`);
    },
    [setError]
  );
  const {
    isLoading,
    values: initialValues,
    error,
    mutate
  } = useMutableFacilityAttributes(facilityId, {
    onSuccess,
    onError
  });

  const onSubmit = useCallback(
    (values: FacilityAttributesFormValues) => {
      mutate(values);
    },
    [mutate]
  );

  const formattedError = parseV3ApiError(error);

  const [addedHours, setAddedHours] = useState(initialValues.facilityOperationCapacity.length > 0);

  return (
    <div className="grid grid-flow-row">
      <div className="overflow-auto">
        <h2 className="border-b border-sw-border pb-3">Attributes</h2>
        {(formattedError.is404 && <Error404Page />) ||
          (formattedError.isError && (
            <ErrorPage primaryMessage={formattedError.title} secondaryMessage={formattedError.detail} />
          )) ||
          (initialValues && !isLoading && (
            <Formik
              validateOnMount
              initialValues={initialValues}
              validationSchema={facilityOperationCapacityValidationSchema}
              onSubmit={onSubmit}
            >
              {({isValid, dirty, values, resetForm, isSubmitting, setFieldValue, submitForm}) => {
                // Facilities in the dev environment that were created before the hours functionality was implemented
                // in the BE have empty hours listings, which is more confusing in the UI than the "Save" footer
                // appearing before anything is touched, so we go ahead and add defaults and bring the footer up so
                // that the user is enticed to hit Save and fix it. - Joe 2023-01-12
                if (!addedHours && values.facilityOperationCapacity.length === 0) {
                  setFieldValue(
                    'facilityOperationCapacity',
                    cloneDeep(facilityFormDataDefaultValues.facilityOperationCapacity),
                    true
                  );
                  setAddedHours(true);
                  void submitForm().then(() => {
                    console.info('Added hours where there were none.');
                  }, onError);
                }

                return (
                  <Form noValidate>
                    <FacilityAttributesFields />
                    {/** A little spacer so that we can comfortably scroll a little
                     *  past the end, even with the footer covering the bottom.
                     */}
                    <div className={dirty ? 'h-24' : 'h-4'}></div>
                    {dirty ? (
                      <FormFooter
                        secondaryActionName="Reset"
                        onCancel={resetForm}
                        dirty={dirty && isValid}
                        isSubmitting={isSubmitting}
                      />
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
          )) || <Loader show>Loading Facility Attributes...</Loader>}
      </div>
    </div>
  );
};

const FacilityAttributes = WithStatusToasts(FacilityAttributesBase);
export default FacilityAttributes;
