import {Product, ShipwellError} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {createProduct} from 'App/api/products/typed';

export const useCreateProduct = (
  mutationOptions: UseMutationOptions<Awaited<ReturnType<typeof createProduct>>, ShipwellError, Product> = {}
) => {
  return useMutation<Awaited<ReturnType<typeof createProduct>>, ShipwellError, Product>(
    async (product: Product) => createProduct(product),
    mutationOptions
  );
};
