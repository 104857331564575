import moment from 'moment-timezone';
import {DateTimePicker} from 'react-widgets';
import FormGroup from '../../formGroup';
import {formatHrMin} from 'App/utils/globals';

/**
 * Date Time Field
 */
const DateField = (props) => {
  const {input, time = false, req, min, placeholder = 'MM/DD/YYYY', timezone = moment.tz.guess(true), ...rest} = props;

  let value = null;

  if (time && input.value && moment(input.value).isValid()) {
    const formattedDate = moment.utc(input.value).tz(timezone).format('MMM D, YYYY HH:mm:ss');

    value = moment(formattedDate).toDate();
  } else if (input.value && moment(input.value).isValid()) {
    value = moment(moment.utc(input.value).format('MM/DD/YYYY')).toDate();
  }

  return (
    <FormGroup {...props}>
      {() => (
        <DateTimePicker
          {...rest}
          min={min}
          name={input.name}
          value={value}
          defaultValue={value}
          time={time}
          timeFormat={formatHrMin}
          format={time ? 'MMM D, YYYY HH:mm' : 'MM/DD/YYYY'}
          placeholder={placeholder}
          onChange={(value) => {
            if (time && value) {
              return input.onChange(moment.utc(value).format());
            }
            return input.onChange(value);
          }}
        />
      )}
    </FormGroup>
  );
};

export default DateField;
