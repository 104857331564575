import {useCallback, useMemo} from 'react';
import {useQuery, useQueryClient, useMutation, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {StandardScheduledHoliday} from '@shipwell/tempus-sdk';

import {ShipwellError} from '@shipwell/backend-core-sdk';
import {OTHER} from 'App/data-hooks/facilities/constants';
import {Facility, FacilityStandardHolidayOption} from 'App/data-hooks/facilities/types';
import {FACILITIES_QUERY_KEY, STANDARD_HOLIDAYS, FACILITY_DOCUMENTS} from 'App/data-hooks/queryKeys';
import {getFacilities, getFacility, updateFacility as putFacility, getStandardHolidays} from 'App/api/facilities';
import useConsistentReference from 'App/utils/hooks/useConsistentReference';
import {useUpsertFacilityAddressBookEntryMutation} from 'App/data-hooks';
import {UpsertFacility} from 'App/data-hooks/locations/useUpdateFacilityAddressBookEntryMutation';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';
import {transformAxiosError} from 'App/utils/globalsTyped';
import {ShipwellApiErrorResponse} from 'App/utils/errors';

export type UseFacilitiesQueryOptions = Omit<
  UseQueryOptions<Facility[], AxiosError<ShipwellApiErrorResponse>, Facility[], string[]>,
  'queryFn' | 'queryKey'
>;
export const useFacilitiesQuery = (
  options?: UseFacilitiesQueryOptions,
  pointOfContactUserId: string | undefined = undefined
) =>
  useQuery(
    [FACILITIES_QUERY_KEY],
    async () => {
      const facilities = await getFacilities(pointOfContactUserId);
      return facilities.map((facility) => ({dock_count: -1, ...facility}));
    },
    {
      enabled: true,
      ...options
    }
  );

export type UseFacilityQueryOptions = {
  facilityId?: string | null;
} & Pick<
  UseQueryOptions<Facility, AxiosError<ShipwellApiErrorResponse>, Facility, string[]>,
  'onSuccess' | 'onError' | 'enabled'
>;

export const useFacilityQuery = ({facilityId, onSuccess, onError}: UseFacilityQueryOptions) => {
  const query = useQuery(
    [FACILITIES_QUERY_KEY, facilityId],
    async () => {
      if (!facilityId) {
        return null;
      }
      const facility: Facility = await getFacility(facilityId);
      try {
        // much of the UI depends on the address ID being present to determine if
        // an address book entry should be created for this facility or not
        const {data} = await getAddressBookAddresses({
          q: facilityId
        });
        if (data?.results?.length) {
          facility.address.id = data.results[0].id;
        }
      } catch (error) {
        const legacyError = error as AxiosError<ShipwellError>;
        // it is possible that a carrier is requesting this information or a user whom does not have
        // access to the address book. In that case we can ignore this error message and let the consumer
        // of the hook handle this situation
        if (![404, 401, 403].some((statusCode) => statusCode === legacyError.response?.status)) {
          throw transformAxiosError(legacyError);
        }
      }
      return facility;
    },
    {
      onSuccess: onSuccess,
      onError: onError
    }
  );

  return {
    facility: query.data || null,
    isFacilityLoading: query.isInitialLoading,
    isFacilityError: query.isError,
    isFacilitySuccess: query.isSuccess,
    facilityError: query.error,
    refetchFacility: query.refetch
  };
};

export const useMutableFacility = (
  facilityId: string,
  options?: {onSuccess?: () => void; onError?: (reason: AxiosError<ShipwellApiErrorResponse>) => void}
): {
  isLoading: boolean;
  updateFacility: (facility: Facility) => void;
  updateFacilityAsync: (facility: Facility) => Promise<Facility>;
  facility: Facility | null;
  error: AxiosError<ShipwellApiErrorResponse> | null;
  isMutating: boolean;
} => {
  const onError = options?.onError ?? null;
  const onSuccess = options?.onSuccess ?? null;
  const client = useQueryClient();
  const query = useFacilityQuery({
    facilityId
  });

  const {
    upsertFacilityAddressBookEntryAsync,
    upsertFacilityAddressBookEntryError,
    isUpsertFacilityAddressBookEntryLoading
  } = useUpsertFacilityAddressBookEntryMutation();

  const mutation = useMutation<Facility, AxiosError<ShipwellApiErrorResponse>, Facility>(
    async (facility: Facility) => {
      if (!facility) {
        throw {
          isAxiosError: false,
          response: {
            status: 400,
            statusText: 'BadRequest',
            data: {
              errors: [
                {
                  detail: 'Facility is required',
                  code: '400',
                  title: 'Bad Request'
                }
              ]
            } as ShipwellApiErrorResponse
          }
        } as AxiosError<ShipwellApiErrorResponse>;
      }
      // this need sto happen first so the facility doesn't get out of sync with the address book entry
      await upsertFacilityAddressBookEntryAsync({
        facility: facility as UpsertFacility // cast for now until we get better mapping types :(
      });
      const response = await putFacility(facility);
      const updatedFacility = {dock_count: -1, ...response.data} as Facility;

      return updatedFacility;
    },
    {
      mutationKey: [`Facility(${facilityId})`],
      onMutate: useCallback(
        (facility: Facility) => {
          if (facility.id !== facilityId) {
            throw new Error('Facility Mismatch in mutation.');
          }
          client.setQueryData([FACILITIES_QUERY_KEY, facility.id], facility);
          void client.invalidateQueries([FACILITY_DOCUMENTS]);
        },
        [facilityId, client]
      ),
      onSuccess: useCallback(() => {
        if (onSuccess) {
          onSuccess();
        }
      }, [onSuccess]),
      onError: useCallback(
        (error: AxiosError<ShipwellApiErrorResponse>) => {
          if (onError) {
            onError(error);
          }
        },
        [onError]
      )
    }
  );

  const isLoading = query.isFacilityLoading || mutation.isLoading;
  const updateFacility = mutation.mutate;
  const updateFacilityAsync = mutation.mutateAsync;
  const isMutating = mutation.isLoading || isUpsertFacilityAddressBookEntryLoading;
  const error = query.facilityError || mutation.error || upsertFacilityAddressBookEntryError;
  return {
    isLoading,
    updateFacility,
    updateFacilityAsync,
    facility: useConsistentReference<Facility>(query.facility),
    error,
    isMutating
  };
};

export const useStandardHolidays = (): {
  isLoading: boolean;
  holidays: FacilityStandardHolidayOption[];
} => {
  const standardHolidaysQuery = useQuery([STANDARD_HOLIDAYS], async () => {
    const response = await getStandardHolidays(new Date().getFullYear(), new Date().getFullYear() + 1);
    return response.data.data;
  });
  const {data} = standardHolidaysQuery;
  const standardHolidays: {label: string; value: string; standardHoliday: StandardScheduledHoliday}[] = useMemo(
    () =>
      data
        ?.filter((standardHoliday) => !standardHoliday.observed)
        .map((standardHoliday) => ({
          label: standardHoliday.human_readable_holiday_name,
          value: standardHoliday.standard_holiday_name,
          standardHoliday
        })) ?? [],
    [data]
  );

  const currentDate = new Date();
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);

  const filteredHolidays = standardHolidays.filter((holiday) => {
    const holidayDate = new Date(holiday.standardHoliday.date);
    return holidayDate >= currentDate && holidayDate <= oneYearFromNow;
  });

  const isLoading = standardHolidaysQuery.isLoading || !standardHolidaysQuery?.data?.length;
  return {
    isLoading,
    holidays: [
      ...filteredHolidays,
      {
        label: 'other',
        value: OTHER,
        standardHoliday: {
          human_readable_holiday_name: '',
          date: '',
          observed: false,
          standard_holiday_name: OTHER
        } as StandardScheduledHoliday
      }
    ]
  };
};
