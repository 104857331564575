import {Loader} from '@shipwell/shipwell-ui';
import LegacyMapContainer from 'App/components/ShipmentTrackingOverview/MapContainer';
import LegacyTimelineContainer from 'App/components/ShipmentTrackingOverview/TimelineContainer';

const LegacyShipmentTrackingOverview = ({shipmentId, sidebarOpen}: {shipmentId: string; sidebarOpen: boolean}) => {
  return (
    <div className="flex min-h-full">
      <div className="max-w-md flex-[1] overflow-y-auto rounded-md border-y-1 border-r-1 border-sw-disabled p-2 h-screen-minus-56">
        <LegacyTimelineContainer shipmentId={shipmentId} loader={<Loader show>Loading Events...</Loader>} />
      </div>
      <div className="h-auto flex-[3]">
        <LegacyMapContainer shipmentId={shipmentId} resizeMap={sidebarOpen} />
      </div>
    </div>
  );
};

export default LegacyShipmentTrackingOverview;
