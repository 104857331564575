import {Link, WithRouterProps, withRouter} from 'react-router';
import invariant from 'tiny-invariant';
import {Banner} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {UserForm, UserWithPermissions} from '../userForm';
import {permissionsCategories} from '../permissionsCategories';
import PageHeader from 'App/common/pageHeader';
import {useUserMeContext} from 'App/data-hooks/users/useUserMe';
import ShipwellLoader from 'App/common/shipwellLoader';
import {is404Error} from 'App/utils/errors';
import {useUpdateUser, useGetUser} from 'App/data-hooks';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type EditUserProps = Pick<WithRouterProps<{userId: string}>, 'params'> &
  Pick<WithStatusToastProps, 'setError' | 'setSuccess'>;

const EditUserBase = ({params, setError, setSuccess}: EditUserProps) => {
  const {userId} = params;
  const {company} = useUserMeContext();
  invariant(company?.id, 'No company id found for user');

  const {user, isLoadingUser, error} = useGetUser({companyId: company.id, userId});
  const {updateUser, isUpdatingUser} = useUpdateUser(company.id, {
    onError: () => setError('Error', 'There was a problem updating the user. Please try again.'),
    onSuccess: () => setSuccess('Success!', 'User successfully updated.')
  });

  if (user) {
    const handleSubmit = (updatedUser: UserWithPermissions) => {
      invariant(updatedUser.id, 'No user id found for user');
      const {id: userId, ...user} = updatedUser;

      updateUser({
        userId,
        userDetails: {
          ...user,
          avatar: user.avatar // avatar field is required, but is of type `any`?!! ¯\_(ツ)_/¯. Remove this after STM-2567 is completed.
        }
      });
    };

    return (
      <div className="w-full">
        <PageHeader
          title={user.id ? `${user.first_name} ${user.last_name}` : ''}
          backArrow
          backRoute="/company/users"
        />
        <div className="mx-auto max-w-[1600px] p-5">
          <UserForm
            user={user}
            onSubmit={handleSubmit}
            isSubmitting={isUpdatingUser}
            permissionsCategories={permissionsCategories}
          />
        </div>
      </div>
    );
  }

  if (isLoadingUser) return <ShipwellLoader loading />;

  if (is404Error(error)) {
    return (
      <Banner title={'User not found'}>
        <p>
          Try going to <Link to="/company/users">the users list page</Link>
        </p>
      </Banner>
    );
  }

  return <Banner title={'Error'}>There was an error loading information for this user. Try reloading the page.</Banner>;
};

export const EditUser = compose<EditUserProps, Record<string, never>>(withRouter, withStatusToasts)(EditUserBase);
