import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {generateShipmentBOL, generateShipmentRateConfirmation} from 'App/api/corrogo/typed';

export default function useShipmentActions(shipmentId: string) {
  const generateShipmentBOLMutation = useMutation<Awaited<ReturnType<typeof generateShipmentBOL>>, AxiosError, null>(
    () => generateShipmentBOL(shipmentId),
    {
      onError: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error creating a shipment document', message);
      }
    }
  );

  const generateShipmentRateConfirmationMutation = useMutation<
    Awaited<ReturnType<typeof generateShipmentRateConfirmation>>,
    AxiosError,
    {transportationOrderId: string}
  >(({transportationOrderId}) => generateShipmentRateConfirmation(transportationOrderId), {
    onError: (error: unknown) => {
      const {message} = error as Error;
      console.error('Error creating a shipment document', message);
    }
  });

  return {
    generateShipmentBOLMutation,
    generateShipmentRateConfirmationMutation,
    isLoading: generateShipmentBOLMutation.isLoading || generateShipmentRateConfirmationMutation.isLoading
  };
}
