import Rollbar from 'rollbar';

/**
 * Helper to (re-)configure Rollbar so that we can transform the payload and make source maps work
 * across multiple dynamic domains, including our white-labeled sites.
 *
 */
export function configureRollbarInstance() {
  if (!window.Rollbar) {
    // create a fake disabled Rollbar instance if Segment has not configured Rollbar
    window.Rollbar = new Rollbar({enabled: false, accessToken: true});
  }

  window.Rollbar.configure({
    // adapted from https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
    transform: (payload) => {
      const trace = payload.body.trace;
      const locRegex = /^(https?):\/\/(?:[a-zA-Z0-9._-]+\.shipwell(?:-dev)?\.com|localhost(?::[0-9]+))(.*)/;
      if (trace?.frames) {
        trace.frames = trace.frames.map((frame) => {
          const filename = frame.filename;
          if (filename) {
            const m = filename.match(locRegex);
            console.log('transform', filename, m);
            // `rollbarSourceMapHost` must match the minifiedUrl config value when uploading source maps
            frame.filename = `${m[1]}://rollbarSourceMapHost${m[2]}`;
          }
          return frame;
        });
      }
    },
    enabled: process.NODE_ENV === 'production'
  });
}
