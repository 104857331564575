import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection, formValueSelector, change} from 'redux-form';
import renderToggle from 'App/formComponents/renderToggle';
import {tooltips} from 'App/utils/tooltips';
import './styles.scss';

const preferences = [
  {
    label: 'Booked',
    name: 'shipment_booked',
    defaultValue: false
  },
  {
    label: 'Picked Up',
    name: 'picked_up',
    defaultValue: false
  },
  {
    label: 'ETA Changed',
    name: 'eta_changed',
    defaultValue: false
  },
  {
    label: 'Delayed',
    name: 'delayed',
    defaultValue: false
  },
  {
    label: 'Cancellation',
    name: 'cancellation',
    defaultValue: false
  },
  {
    label: 'Delivered',
    name: 'delivered',
    defaultValue: false
  },
  {
    label: 'Trailer Assigned',
    name: 'trailer_assigned',
    defaultValue: false
  }
];

/**
 * Point of Contact Notification Fields
 */
class PointOfContactNotificationFields extends Component {
  constructor() {
    super();

    this.state = {
      showAll: false
    };
  }

  static defaultProps = {
    name: 'preferences'
  };

  componentDidMount() {
    const {
      context: {_reduxForm}
    } = this.el;
    const formContext = _reduxForm;
    const {select_all, receive_bol_on_shipment_booked, ...preferenceValues} =
      this.props.formValueSelector(formContext.sectionPrefix) || {};
    const allSelected =
      preferenceValues && Object.keys(preferenceValues).length > 0
        ? Object.values(preferenceValues).every((value) => value)
        : false;
    const selectAllField = `${formContext.sectionPrefix}.select_all`;
    this.allSelected = allSelected;
    this.props.dispatch(change(this.props.form, selectAllField, allSelected));
    // sets default value for each email pref if no value exists
    if (!allSelected) {
      preferences.forEach((p) => {
        if (!Object.keys(preferenceValues)?.includes(p.name)) {
          this.props.dispatch(change(this.props.form, `${formContext.sectionPrefix}.${p.name}`, p.defaultValue));
        }
      });
    }
  }

  /**
   * Toggle all preference options
   * @param  {Object}  e         Click event
   * @param  {Boolean} value     Toggle value
   */
  handleSelectAllToggle(e, value, prevValue, field) {
    const {
      context: {_reduxForm}
    } = this.el;
    const formContext = _reduxForm;
    const formSectionName = field && field.split('.').slice(0, -1).join('.');
    const {receive_bol_on_shipment_booked} = this.props.formValueSelector(formContext.sectionPrefix) || {};
    const values = preferences.reduce((values, pref) => Object.assign({}, values, {[pref.name]: value}), {
      receive_bol_on_shipment_booked: receive_bol_on_shipment_booked
    });

    this.allSelected = value;

    this.props.dispatch(change(this.props.form, formSectionName, values));
  }

  handleSelectToggle(e, value, prevValue, field) {
    e.preventDefault();
    value = typeof prevValue === 'undefined' ? false : value;

    this.props.dispatch(change(this.props.form, field, value));

    const formSectionName = field && field.split('.').slice(0, -1).join('.');
    const fieldName = field && field.split('.').slice(-1)[0];
    const {select_all, receive_bol_on_shipment_booked, ...preferences} = Object.assign(
      {},
      this.props.formValueSelector(formSectionName),
      {[fieldName]: value}
    );
    const allSelected = preferences && Object.values(preferences).every((value) => value);
    const selectAllField = `${formSectionName}.select_all`;

    this.allSelected = allSelected;

    this.props.dispatch(change(this.props.form, selectAllField, allSelected));
  }

  /**
   * Toggle visibility of all options
   * @param  {Object} e Click event
   */
  handleToggleDetails(e) {
    e.preventDefault();

    this.setState({showAll: !this.state.showAll});
  }

  render() {
    const {showAll} = this.state;
    const {name} = this.props;

    return (
      <FormSection name={name}>
        <div className="notification-header">
          <h4>Receive All Email Notifications</h4>
          <Field
            ref={(el) => (this.el = el)}
            name="select_all"
            component={renderToggle}
            height={29}
            width={54}
            defaultValue={this.allSelected}
            onChange={this.handleSelectAllToggle.bind(this)}
          />
        </div>
        {showAll && (
          <div className={`additional-options ${showAll ? 'show' : ''}`}>
            {preferences.map((preference, index) => (
              <div className="notification-pref" key={index}>
                <div className="quote-flow__stopsPOC-notificationDetails">
                  <b>{preference.label}</b>
                  <p>{tooltips.notifications[preference.name]}</p>
                </div>
                <Field
                  name={preference.name}
                  component={renderToggle}
                  height={29}
                  width={54}
                  onChange={this.handleSelectToggle.bind(this)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="notification-footer">
          <a href="#" onClick={this.handleToggleDetails.bind(this)}>
            {showAll ? (
              <span>
                <i className="icon icon-Up" /> Hide details
              </span>
            ) : (
              <span>
                <i className="icon icon-Down" /> Show details
              </span>
            )}
          </a>
        </div>
      </FormSection>
    );
  }
}

export default connect((state, props) => ({
  formValueSelector: formValueSelector(props.form).bind(null, state)
}))(PointOfContactNotificationFields);
