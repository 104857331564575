import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const LegacyReferenceId = ({shipment, notFound}) => {
  const referenceId = shipment.reference_id;

  return (
    <div className="text-lg">
      {referenceId} {notFound && 'NOT FOUND'}
    </div>
  );
};

LegacyReferenceId.propTypes = {
  shipment: PropTypes.shape({
    reference_id: PropTypes.string
  }).isRequired,
  notFound: PropTypes.bool
};

LegacyReferenceId.defaultProps = {
  notFound: false
};

export default connect((state) => ({
  shipment: state.shipmentdetails.one
}))(LegacyReferenceId);
