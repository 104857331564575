import {NetsuiteSuiteAppConfig} from '@shipwell/backend-core-sdk';
import {DeprecatedButton, Card, SvgIcon} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';
import {downloadFile} from 'App/utils/fileUtilsTyped';
import useToggle from 'App/utils/hooks/useToggle';

const DOWNLOAD_SUCCESS_TIMEOUT = 5000;

export const CertificateAndKeySection = ({
  suiteAppConfig,
  toggleShowGenerateNewKeyModal,
  isGenerateNewKeyLoading
}: {
  suiteAppConfig?: NetsuiteSuiteAppConfig;
  toggleShowGenerateNewKeyModal: () => void;
  isGenerateNewKeyLoading: boolean;
}) => {
  const [isCertificateDownloaded, toggleCertificateDownloaded] = useToggle(false);
  const [isPublicKeyDownloaded, togglePublicKeyDownloaded] = useToggle(false);

  const handleDownloadCertificate = () => {
    if (!suiteAppConfig?.public_certificate) return;
    const blob = new Blob([suiteAppConfig.public_certificate], {type: 'text/plain'});
    downloadFile({blob, escapedFileName: 'SuiteAppCertificate.pem'});
    toggleCertificateDownloaded();
    setTimeout(() => toggleCertificateDownloaded(), DOWNLOAD_SUCCESS_TIMEOUT);
  };

  const handleDownloadKey = () => {
    if (!suiteAppConfig?.public_key) return;
    const blob = new Blob([suiteAppConfig.public_key], {type: 'text/plain'});
    downloadFile({blob, escapedFileName: 'PublicKey'});
    togglePublicKeyDownloaded();
    setTimeout(() => togglePublicKeyDownloaded(), DOWNLOAD_SUCCESS_TIMEOUT);
  };

  return (
    <>
      {suiteAppConfig?.public_key && suiteAppConfig?.public_certificate ? (
        <Card title={'Certificate & Key'} draggableProvided={false} bodyClassName="p-4">
          <div className="grid gap-4">
            <div className="flex justify-between">
              <div>
                <span className="text-[10px] uppercase">Pem certificate</span>
                <p className="m-0 text-sm">SuiteAppCertificate.PEM</p>
              </div>
              {isCertificateDownloaded ? (
                <SvgIcon color="$sw-success" name="CheckCircleOutlined" />
              ) : (
                <DeprecatedButton variant="tertiary" onClick={handleDownloadCertificate}>
                  <SvgIcon name="Download" />
                </DeprecatedButton>
              )}
            </div>
            <div className="flex justify-between">
              <div>
                <span className="text-[10px] uppercase">Public key</span>
                <p className="m-0 text-sm">PublicKey.txt</p>
              </div>
              {isPublicKeyDownloaded ? (
                <SvgIcon color="$sw-success" name="CheckCircleOutlined" />
              ) : (
                <DeprecatedButton variant="tertiary" onClick={handleDownloadKey}>
                  <SvgIcon name="Download" />
                </DeprecatedButton>
              )}
            </div>
            <FlexBox direction="row" justify="end" gap="m">
              <DeprecatedButton
                size="small"
                className="h-7 px-6"
                disabled={isGenerateNewKeyLoading || !suiteAppConfig.certificate_id}
                onClick={() => toggleShowGenerateNewKeyModal()}
              >
                {isGenerateNewKeyLoading ? <SvgIcon name="LoadingDots" /> : 'Generate New Key'}
              </DeprecatedButton>
            </FlexBox>
          </div>
        </Card>
      ) : (
        <div className="flex flex-col items-center justify-center rounded border border-sw-border bg-sw-background">
          <p className="text-center text-xl font-bold text-sw-disabled-text">No Certificate or Key</p>
          <p className="text-center text-sw-disabled-text">
            To generate your certificate and key enter your
            <br /> NetSuite account number and select &apos;Generate Key&apos;
          </p>
        </div>
      )}
    </>
  );
};
