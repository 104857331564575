import {Field, useFormikContext} from 'formik';
import {FormikTextInput, DeprecatedButton, DisplayValue, SvgIcon, FormikPhoneInput} from '@shipwell/shipwell-ui';
import {StopTypeType, StopFormValues} from './constants';
import {getAddressEntry} from './utils';

const StopTypeContactsCardDisplay = ({
  remove,
  stopType,
  handleAddNewContact
}: {
  stopType: StopTypeType;
  remove: (index: number) => void;
  handleAddNewContact: () => void;
}) => {
  const {values} = useFormikContext<StopFormValues>();
  const entry = getAddressEntry(values, stopType.id);
  const contacts = entry?.point_of_contacts || [];
  const contactsValuesPath = `${stopType.id}.point_of_contacts`;
  return (
    <>
      {contacts.map((contact, index) => (
        <div key={contact?.id || index} className="relative mb-2 md:-mt-3.5">
          {contacts.length > 1 ? (
            <div className="absolute right-0 top-0 -mt-4">
              <DeprecatedButton variant="icon" icon={<SvgIcon name="TrashOutlined" />} onClick={() => remove(index)} />
            </div>
          ) : null}
          <DisplayValue label={`${stopType.label} Contact ${index + 1}`}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4">
                <div className="grow">
                  <Field
                    name={`${contactsValuesPath}[${index}].first_name`}
                    label="First Name"
                    component={FormikTextInput}
                  />
                </div>
                <div className="grow">
                  <Field
                    name={`${contactsValuesPath}[${index}].last_name`}
                    label="Last Name"
                    component={FormikTextInput}
                  />
                </div>
              </div>
              <Field
                name={`${contactsValuesPath}[${index}].phone_number`}
                label="Phone Number"
                component={FormikPhoneInput}
              />
              <Field name={`${contactsValuesPath}[${index}].email`} label="Email" component={FormikTextInput} />
            </div>
          </DisplayValue>
        </div>
      ))}
      <div>
        <DeprecatedButton variant="tertiary" onClick={handleAddNewContact}>
          Add Additional {stopType.label} Contact
        </DeprecatedButton>
      </div>
    </>
  );
};

export default StopTypeContactsCardDisplay;
