import {
  REQ_BROKER_DATA,
  REQ_CUSTOMER_LIST,
  FETCH_BROKER_SHIPPER_RELATIONSHIPS,
  ALERT_ERROR,
  CREATE_BROKER_SHIPPER_RELATIONSHIP,
  EDIT_BROKER_SHIPPER_RELATIONSHIP,
  GET_SHIPPER_CARRIER_RELATIONSHIPS,
  EDIT_SHIPPER_CARRIER_RELATIONSHIPS,
  CLEAR_SELECTED_CUSTOMER,
  SELECT_CUSTOMER,
  CLEAR_SELECTED_SHIPPER_CARRIER,
  FETCH_CARRIERS,
  GET_BROKER_DASHBOARDS,
  CREATE_BROKER_SHIPPER,
  EDIT_BROKER_SHIPPER,
  FETCH_BROKER_SHIPMENTS,
  FETCH_BROKERAGE,
  ACT_AS_COMPANY,
  GET_BROKER_LOGOS,
  UNAUTH_BROKER_USER
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';

// import fetch from 'isomorphic-fetch';
// import {browserHistory} from 'react-router';
// import {reset} from 'redux-form';

function requestData() {
  return {type: REQ_BROKER_DATA};
}

function requestCustomerList() {
  return {type: REQ_CUSTOMER_LIST};
}
//get all shippers for a brokerage
export function fetchBrokerShipperRelationships(brokerageId, opts) {
  opts = opts || {};

  return (dispatch) => {
    dispatch(requestCustomerList());
    return fetchBrokerShipperRelationshipsPromise(brokerageId, opts)
      .then((response) => {
        dispatch({
          type: FETCH_BROKER_SHIPPER_RELATIONSHIPS,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: FETCH_BROKER_SHIPPER_RELATIONSHIPS,
          payload: []
        });
        dispatch({
          type: ALERT_ERROR,
          payload: 'Unable to retrieve your customer accounts.'
        });
      });
  };
}

export async function fetchBrokerShipperRelationshipsPromise(brokerageId, opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsGet(brokerageId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//DUPLICATE API call here to ensure that the header's props dont change when relationships are used in other locations
export function fetchBrokerShipperRelationshipsForTypeaheads(brokerageId, opts) {
  opts = opts || {};

  return (dispatch) => {
    dispatch(requestCustomerList());

    return fetchBrokerShipperRelationshipsForTypeaheadsPromise(brokerageId, opts)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error retrieving your shipper accounts.'
        });
      });
  };
}

async function fetchBrokerShipperRelationshipsForTypeaheadsPromise(brokerageId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsGet(
      brokerageId,
      opts,

      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/**
 * Creates a new shipper along with a relationship to the authenticated broker
 * @param {String} brokerageId The id of the brokerage
 * @param {module:model/CreateShipperRelationship} body
 * @param {module:api/shipwellApi~brokeragesBrokerageIdShipperRelationshipsPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipperRelationship}
 */
export function createBrokerShipperRelationship(brokerageId, customerObj) {
  return (dispatch) => {
    return createBrokerShipperRelationshipPromise(brokerageId, customerObj)
      .then((response) => {
        dispatch({
          type: CREATE_BROKER_SHIPPER_RELATIONSHIP,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function createBrokerShipperRelationshipPromise(brokerageId, customerObj) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsPost(brokerageId, customerObj, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Update a shipper relationship
 * @param {String} brokerageId The id of the brokerage
 * @param {String} shipperRelationshipId The id of the brokerage rate
 * @param {module:model/ShipperRelationship} body
 * @param {module:api/shipwellApi~brokeragesBrokerageIdShipperRelationshipShipperRelationshipIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipperRelationship}
 */
export function editBrokerShipperRelationship(brokerageId, relationshipId, customerObj) {
  return (dispatch) => {
    return editBrokerShipperRelationshipPromise(brokerageId, relationshipId, customerObj)
      .then((response) => {
        dispatch({
          type: EDIT_BROKER_SHIPPER_RELATIONSHIP,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function editBrokerShipperRelationshipPromise(brokerageId, relationshipId, customerObj) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPut(
      brokerageId,
      relationshipId,
      customerObj,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/**
 * Delete a shipper relationship
 * @param {String} brokerageId The id of the brokerage
 * @param {String} shipperRelationshipId The id of the brokerage rate
 * @param {module:model/ShipperRelationship} body
 * @param {module:api/shipwellApi~brokeragesBrokerageIdShipperRelationshipShipperRelationshipIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipperRelationship}
 */
export function deleteBrokerShipperRelationship(brokerageId, relationshipId) {
  return (dispatch) => {
    return deleteBrokerShipperRelationshipPromise(brokerageId, relationshipId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteBrokerShipperRelationshipPromise(brokerageId, relationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDelete(
      brokerageId,
      relationshipId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/**
 * Returns a list of carrier relationships for a given shipper to brokerage relationship
 * @param {String} brokerageId The id of the brokerage
 * @param {String} shipperRelationshipId The id of the shipper relationship
 * @param {module:api/shipwellApi~brokeragesBrokerageIdShipperRelationshipShipperRelationshipIdCarriersGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/InlineResponse2003}
 */
export function getShipperCarrierRelationships(brokerageId, shipperRelationshipId) {
  return (dispatch) => {
    return getShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId)
      .then((response) => {
        dispatch({
          type: GET_SHIPPER_CARRIER_RELATIONSHIPS,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersGet(
      brokerageId,
      shipperRelationshipId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/**
 * Updates any modified carrier relationships
 * @param {String} brokerageId The id of the brokerage
 * @param {String} shipperRelationshipId The id of the shipper relationship
 * @param {module:model/CarrierSpecificRateShareConfig} body
 * @param {module:api/shipwellApi~brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/CarrierSpecificRateShareConfig}
 */
export function editShipperCarrierRelationships(brokerageId, shipperRelationshipId, body) {
  return (dispatch) => {
    return editShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId, body)
      .then((response) => {
        dispatch({
          type: EDIT_SHIPPER_CARRIER_RELATIONSHIPS,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function editShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPut(
      brokerageId,
      shipperRelationshipId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

export function clearSelectedCustomer() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_CUSTOMER});
  };
}

export function selectCustomer(customer) {
  return (dispatch) => {
    dispatch({type: SELECT_CUSTOMER, payload: customer});
  };
}

export function clearSelectedShipperCarrierRelationship() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_SHIPPER_CARRIER, payload: {}});
  };
}

//get all carrier relationships for a brokerage

export function fetchCarriers() {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return fetchCarriersPromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_CARRIERS, payload: response});
      })
      .catch((response) => {
        dispatch({type: ALERT_ERROR, payload: response});
      });
  };
}

async function fetchCarriersPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersGet(authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//get list of looker dashboards for a shipper
export function getBrokerDashboards() {
  const token = localStorage.getItem('msajnfhiua');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return getBrokerDashboardsPromise(authorization)
      .then((response) => {
        dispatch({type: GET_BROKER_DASHBOARDS, payload: response});
      })
      .catch((response) => {});
  };
}

async function getBrokerDashboardsPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.lookerBrokersDashboardsGet(authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//get a looker URL for a dashboard for a shipper
export function getBrokerDashboardURL(dashboardID) {
  const token = localStorage.getItem('msajnfhiua');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return getBrokerDashboardURLPromise(dashboardID, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function getBrokerDashboardURLPromise(dashboardID, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.lookerBrokersDashboardsDashboardIdGet(dashboardID, authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//create shipper
export function createBrokerShipper(brokerageID, body) {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return createBrokerShipperPromise(brokerageID, body, authorization)
      .then((response) => {
        dispatch({type: CREATE_BROKER_SHIPPER, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function createBrokerShipperPromise(brokerageID, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokersBrokerageIdShippersPost(brokerageID, body, authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//edit shipper
export function editBrokerShipper(shipperId, body) {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return editBrokerShipperPromise(shipperId, body, authorization)
      .then((response) => {
        dispatch({type: EDIT_BROKER_SHIPPER, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function editBrokerShipperPromise(shipperId, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokersShippersShipperIdPut(shipperId, body, authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//get all shipments for a broker
export function getBrokerShipments() {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    dispatch(requestData());
    return getBrokerShipmentsPromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_BROKER_SHIPMENTS, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error retrieving shipments.'
        });
      });
  };
}

async function getBrokerShipmentsPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    //sending empty object for opts for now
    shipwellApi.brokersShippersShipmentsGet(authorization, {}, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//edit a brokerage object
export function editBrokerage(brokerageId, brokerage) {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return editBrokeragePromise(authorization, brokerageId, brokerage)
      .then((response) => {
        dispatch({type: FETCH_BROKERAGE, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function editBrokeragePromise(authorization, brokerageId, brokerage) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    //sending empty object for opts for now
    shipwellApi.brokersBrokerageIdPut(brokerageId, brokerage, authorization, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//mark shipments as paid
export function brokerMarkAsPaid(lineItems) {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return brokerMarkAsPaidPromise(lineItems, authorization)
      .then((response) => {
        dispatch({type: FETCH_BROKER_SHIPMENTS, payload: response});
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error marking these line items as paid.'
        });
      });
  };
}

async function brokerMarkAsPaidPromise(lineItems, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokersShipmentsInvoiceLineItemsMarkAsPaidPost(
      lineItems,
      authorization,
      function (err, data, response) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });
}

//mark shipments as paid
export function brokerMarkAsUnpaid(lineItems) {
  var token = localStorage.getItem('msajnfhiua');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return brokerMarkAsUnpaidPromise(lineItems, authorization)
      .then((response) => {
        dispatch({type: FETCH_BROKER_SHIPMENTS, payload: response});
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error marking these line items as unpaid.'
        });
      });
  };
}

async function brokerMarkAsUnpaidPromise(lineItems, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.brokersShipmentsInvoiceLineItemsMarkAsUnpaidPost(
      lineItems,
      authorization,
      function (err, data, response) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });
}

//Set the company you are impersonating/masquerading as
export function setActingAsCompany(companyId) {
  //set a value in localstorage for use on reload
  localStorage.setItem('swmasq', companyId);
  return (dispatch) => {
    dispatch({
      type: ACT_AS_COMPANY,
      payload: companyId
    });
  };
}

//log in as a shipper
export function clearShipperToken() {
  localStorage.removeItem('dsiajksdjk');
  localStorage.removeItem('oovlxkkp');
}

//log in as a shipper
export function getCompanyLogo(subdomain) {
  const nonWhiteLabel = [
    {
      image_type: 'INLINE_WHITE',
      logo: '/images/svgs/shipwell-logo-white.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'INLINE_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_WHITE',
      logo: '/images/shipwell_logo_white.png',
      company_name: 'Shipwell'
    }
  ];
  return (dispatch) => {
    return getCompanyLogoPromise(subdomain)
      .then((response) => {
        dispatch({type: GET_BROKER_LOGOS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: GET_BROKER_LOGOS,
          payload: nonWhiteLabel
        });
        return {
          status: 400,
          details: nonWhiteLabel
        };
      });
  };
}

async function getCompanyLogoPromise(subdomain) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesLogosGet(subdomain, {}, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export function signoutBrokerUser() {
  return {type: UNAUTH_BROKER_USER};
}
