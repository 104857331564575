import {NetsuiteSuiteAppConfig} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {postSuiteAppConfig} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useCreateSuiteAppConfig = (
  options?: UseMutationOptions<Awaited<ReturnType<typeof postSuiteAppConfig>>, AxiosError, NetsuiteSuiteAppConfig>
) => {
  const queryClient = useQueryClient();
  const createSuiteAppConfigMutation = useMutation([SUITE_APP_CONFIG_QUERY], (body) => postSuiteAppConfig(body), {
    onSettled: () => void queryClient.invalidateQueries([SUITE_APP_CONFIG_QUERY]),
    ...options
  });
  return {
    createSuiteAppConfig: createSuiteAppConfigMutation.mutate,
    isCreateSuiteAppConfigLoading: createSuiteAppConfigMutation.isLoading
  };
};
