import {ShippingDashboardRow, ShippingDashboardStop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import moment from 'moment';
import {createColumnHelper, flexRender} from '@tanstack/react-table';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {FlexBox} from 'App/components/Box';
import {TableCell} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import getItineraryAddressString from 'App/utils/getItineraryAddressString';
import {formatDayOfWeekDateTime} from 'App/utils/globals';
import {StopEtaLastUpdated, StopEtaPill} from 'App/components/stopEta';

export const StopsCell = ({stops, mode}: {stops?: ShippingDashboardStop[]; mode: ShippingDashboardRow['mode']}) => {
  const pickupLength = stops?.filter((stop) => stop.is_pickup).length;
  const dropoffLength = stops?.filter((stop) => stop.is_dropoff).length;
  const {hasLTL} = checkShipmentModes(mode);
  return (
    <Tooltip
      portal
      placement="top"
      tooltipClassname="p-2"
      tooltipContent={<TooltipContent stops={stops || []} hasLtl={hasLTL} />}
    >
      <FlexBox direction="col">
        <FlexBox items="center" gap="s">
          <SvgIcon name="ArrowUpward" width="16" height="16" />
          <span>{pickupLength}</span>
        </FlexBox>
        <FlexBox items="center" gap="s">
          <SvgIcon name="ArrowDownward" width="16" height="16" />
          <span>{dropoffLength}</span>
        </FlexBox>
      </FlexBox>
    </Tooltip>
  );
};

const columnHelper = createColumnHelper<ShippingDashboardStop>();
const columns = (tripManagementPredictedEta = false, hasLtl = false) => [
  columnHelper.accessor('id', {
    header: 'Stop',
    enableSorting: false,
    cell: (info) => {
      const stop = info.row.original;
      const isDropoff = stop.is_dropoff;
      return (
        <FlexBox items="center" gap="s">
          <SvgIcon className="min-w-[16px]" name={isDropoff ? 'ArrowDownward' : 'ArrowUpward'} height="16" width="16" />
          {getItineraryAddressString(stop)}
        </FlexBox>
      );
    }
  }),
  columnHelper.accessor('location.address.id', {
    header: 'Time',
    enableSorting: false,
    size: 320,
    cell: (info) => {
      const stop = info.row.original;
      const timezone = stop?.location?.address?.timezone ?? moment.tz.guess();
      const formattedTripManagementETA = moment(stop.trip_management_eta).isValid()
        ? formatDayOfWeekDateTime(stop.trip_management_eta, true, timezone)
        : null;
      if (tripManagementPredictedEta && formattedTripManagementETA && !hasLtl) {
        return (
          <FlexBox gap="s">
            <span>{formattedTripManagementETA}</span>
            <FlexBox direction="col" items="center">
              <StopEtaPill stop={stop} duration="hours" />
              <StopEtaLastUpdated
                isExpandedLastUpdateDisplay={false}
                stop={stop}
                warningIconWidth="16"
                warningIconHeight="16"
                shipment={undefined}
                setSuccess={undefined}
                fetchDetails={undefined}
              />
            </FlexBox>
          </FlexBox>
        );
      }
      return <>{stop.display_planned_window}</>;
    }
  })
];

const TooltipContent = ({stops, hasLtl}: {stops: ShippingDashboardStop[]; hasLtl?: boolean}) => {
  const {tripManagementPredictedEta} = useFlags();
  const table = useTypedTable({
    data: stops,
    columns: columns(Boolean(tripManagementPredictedEta), hasLtl)
  });

  return (
    <div className="p-2">
      <h2 className="m-0 pb-2">Itinerary</h2>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="p-2">
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{width: header.getSize()}}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  <span className="whitespace-nowrap">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
