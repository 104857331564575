/* eslint-disable no-unsafe-optional-chaining */
import {useEffect} from 'react';
import {FormikHelpers, useFormikContext} from 'formik';
import {DeprecatedButton, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import {v4} from 'uuid';
import {FreightInvoiceFormData, FreightInvoiceShipmentDocument} from '../../types';
import NewDocumentForm from './NewDocumentForm';
import {isDocumentRequiredRule, isFile} from './utils';
import DocumentSelect from 'App/formComponents/fields/documentSelect';
import {useInvoiceRuleConfigurations} from 'App/data-hooks/invoices/useInvoiceRuleConfigurations';
import {useLegacyShipmentDocuments} from 'App/data-hooks/documents/useLegacyShipmentDocuments';
import Loader from 'App/common/shipwellLoader';
import useToggle from 'App/utils/hooks/useToggle';
import {useUserMe} from 'App/data-hooks';

const UploadedDocuments = ({
  shipmentId,
  invoiceSource
}: {
  shipmentId: string;
  invoiceSource: 'carrier_generated' | 'shipper_generated';
}) => {
  const {data: ruleConfigurations, isLoading: isRuleConfigurationsLoading} = useInvoiceRuleConfigurations(
    shipmentId,
    invoiceSource
  );
  const {values, setFieldValue} = useFormikContext<FreightInvoiceFormData>();
  const {data: paginatedShipmentDocumentData, isLoading: isLoadingLegacyShipmentDocuments} =
    useLegacyShipmentDocuments(shipmentId);
  const [showNewDocumentModal, toggleShowNewDocumentModal] = useToggle();
  const {data: userMeData} = useUserMe();
  useEffect(() => {
    const paginatedShipmentDocuments = paginatedShipmentDocumentData?.results;
    if (!ruleConfigurations || !paginatedShipmentDocuments || paginatedShipmentDocuments.length < 1) {
      return;
    }
    if (!values.document_metadata || values.document_metadata.length === 0) {
      const documentRequiredRules = ruleConfigurations.filter(isDocumentRequiredRule);
      const ruleEnabled = documentRequiredRules.some((rule) => rule.enabled);
      const requiredDocuments = documentRequiredRules.map((rule) => rule.details.document_types).flat();
      const documentMetadata = paginatedShipmentDocuments.map((document) => ({
        ...document,
        document_type: document.type,
        add_to_invoice: !ruleEnabled || requiredDocuments.some((requiredDocument) => requiredDocument === document.type)
      }));
      setFieldValue('document_metadata', documentMetadata);
    }
  }, [paginatedShipmentDocumentData, setFieldValue, values.document_metadata, ruleConfigurations]);

  const setSelectedDocumentIdsAndUpdateFormState = (selectedDocumentIds: string[]) => {
    setFieldValue(
      'document_metadata',
      values.document_metadata?.map((document) => {
        return {...document, add_to_invoice: selectedDocumentIds.includes(document.id || '')};
      })
    );
  };

  const handleSubmitNewDocument = (
    newDocument: FreightInvoiceShipmentDocument,
    {setSubmitting}: FormikHelpers<FreightInvoiceShipmentDocument>
  ) => {
    if (!newDocument.file || !isFile(newDocument.file)) {
      throw new Error('Missing required document submission properties');
    }
    const reader = new FileReader();
    reader.readAsDataURL(newDocument.file);
    //once reader has read file as data url..
    //generate the document payload
    reader.onload = () => {
      const fileDataUrl = reader.result;
      const newDocumentWithMetadataFields = {
        ...newDocument,
        //this is what's used to display the file in the documents list
        file: fileDataUrl,
        //we will send this as part of the form data request
        original_file: newDocument.file,
        filename: newDocument.file && isFile(newDocument.file) && newDocument.file.name,
        filetype: newDocument.document_type,
        //assign document creation user to currently logged in user
        created_by_name: `${userMeData?.user?.first_name || ''} ${userMeData?.user?.last_name || ''}`,
        //assign uuid to new document to allow management of new document in state
        id: v4()
      };
      setFieldValue(
        'document_metadata',
        values.document_metadata
          ? [...values?.document_metadata, newDocumentWithMetadataFields]
          : [newDocumentWithMetadataFields]
      );
      setSubmitting(false);
      toggleShowNewDocumentModal();
    };
  };

  if (isLoadingLegacyShipmentDocuments || isRuleConfigurationsLoading) {
    return <Loader loading additionalClassNames={['w-full h-[10rem]']} />;
  }
  const selectedDocumentIds = values.document_metadata
    ?.filter((document) => document.add_to_invoice)
    .map((document) => document.id);
  return (
    <div>
      <div className="text-xxs uppercase">Uploaded Documents</div>
      <DocumentSelect
        documents={values.document_metadata || []}
        selectedDocuments={selectedDocumentIds}
        setSelectedDocuments={setSelectedDocumentIdsAndUpdateFormState}
        hidePreviewCta
        onClickDocument={() => undefined}
      />
      <DeprecatedButton
        className="mt-2"
        variant="tertiary"
        icon={<SvgIcon name="AddCircleOutlined" />}
        onClick={toggleShowNewDocumentModal}
      >
        Add Document
      </DeprecatedButton>
      <Modal show={showNewDocumentModal} title="Upload Document" footerComponent={null}>
        <NewDocumentForm onCancel={toggleShowNewDocumentModal} onSubmit={handleSubmitNewDocument} />
      </Modal>
    </div>
  );
};
export default UploadedDocuments;
