import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Pagination, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import './_manage-equipment.scss';
import {SubmissionError} from 'redux-form';
import ReactTable from 'react-table-6';
import PropTypes from 'prop-types';
import PowerUnitForm from '../forms/PowerUnitForm';
import TrailerForm from '../forms/TrailerForm';
import * as actions from 'App/actions/carriers';
import * as formActions from 'App/actions/forms';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {unpackErrors, numberWithCommas} from 'App/utils/globals';
import 'react-table-6/react-table.css';

@connect(
  (state) => ({
    company: state.auth.company,
    powerUnit: state.carriers.powerUnit,
    trailer: state.carriers.trailer
  }),
  {...actions, ...formActions}
)
export default class EditEquipment extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.getEquipment = this.getEquipment.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onFormSubmitEditTrailer = this.onFormSubmitEditTrailer.bind(this);
    this.onFormSubmitEditPowerUnit = this.onFormSubmitEditPowerUnit.bind(this);
    this.onFormSubmitAddPowerUnit = this.onFormSubmitAddPowerUnit.bind(this);
    this.onFormSubmitAddTrailer = this.onFormSubmitAddTrailer.bind(this);
    this.state = {showEditEquipment: false, equipmentType: null};
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getEquipment();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.company && this.props.company !== prevProps.company) {
      this.getEquipment();
    }
  }

  getEquipment() {
    const id = this.props.params.equipment_id;
    let showEditEquipment = false;

    if (this.props.params) {
      if (this.props.params.equipment_type && this.props.params.equipment_type === 'trailer') {
        if (id && id !== 'new_equipment') {
          this.props.getTrailer(this.props.company.carrier.id, id);
          showEditEquipment = true;
        }
        this.setState({showEditEquipment: showEditEquipment, equipmentType: 'trailer'});
      } else if (this.props.params.equipment_type && this.props.params.equipment_type === 'power_unit') {
        if (id && id !== 'new_equipment') {
          this.props.getPowerUnit(this.props.company.carrier.id, id);
          showEditEquipment = true;
        }
        this.setState({showEditEquipment: showEditEquipment, equipmentType: 'power_unit'});
      }
    }
  }

  cancel() {
    this.context.router.push('/manage_equipment');
  }

  onFormSubmitEditTrailer(attrs) {
    return this.props.putTrailer(this.props.company.carrier.id, this.props.trailer.id, attrs).then((response) => {
      if (response.status === 200) {
        this.props.resetTrailerForm();
        this.props.clearTrailer();
        this.cancel();
      } else {
        const errors = response.field_errors || [];
        let myError = {};
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;

        //handle edge cases for errors here

        throw new SubmissionError(myError);
      }
    });
  }

  onFormSubmitAddTrailer(attrs) {
    return this.props.postTrailer(this.props.company.carrier.id, attrs).then((response) => {
      if (response.status === 200) {
        this.props.resetTrailerForm();
        this.props.clearTrailer();
        this.cancel();
      } else {
        const errors = response.field_errors || [];
        let myError = {};
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;

        //handle edge cases for errors here

        throw new SubmissionError(myError);
      }
    });
  }

  onFormSubmitEditPowerUnit(attrs) {
    return this.props.putPowerUnit(this.props.company.carrier.id, this.props.powerUnit.id, attrs).then((response) => {
      if (response.status === 200) {
        this.props.resetPowerUnitForm();
        this.props.clearPowerUnit();

        this.cancel();
      } else {
        const errors = response.field_errors || [];
        let myError = {};
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;

        //handle edge cases for errors here

        throw new SubmissionError(myError);
      }
    });
  }

  onFormSubmitAddPowerUnit(attrs) {
    return this.props.postPowerUnit(this.props.company.carrier.id, attrs).then((response) => {
      if (response.status === 200) {
        this.props.resetPowerUnitForm();
        this.props.clearPowerUnit();

        this.cancel();
      } else {
        const errors = response.field_errors || [];
        let myError = {};
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;

        //handle edge cases for errors here

        throw new SubmissionError(myError);
      }
    });
  }

  render() {
    return (
      <div className="equipment content-wrapper">
        <section className="content">
          {this.state.showEditEquipment && this.state.equipmentType === 'trailer' && (
            <TrailerForm cancel={this.cancel} onSubmit={this.onFormSubmitEditTrailer} />
          )}
          {this.state.showEditEquipment && this.state.equipmentType === 'power_unit' && (
            <PowerUnitForm cancel={this.cancel} onSubmit={this.onFormSubmitEditPowerUnit} />
          )}
          {!this.state.showEditEquipment && this.state.equipmentType === 'power_unit' && (
            <PowerUnitForm cancel={this.cancel} onSubmit={this.onFormSubmitAddPowerUnit} />
          )}

          {!this.state.showEditEquipment && this.state.equipmentType === 'trailer' && (
            <TrailerForm cancel={this.cancel} onSubmit={this.onFormSubmitAddTrailer} />
          )}
        </section>
      </div>
    );
  }
}
