import {Button, Checkbox, FormikCheckbox, FormikTextInput} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {useState, ChangeEvent} from 'react';

import {isDefined} from 'App/utils/guard';

const initialValues = {
  invoicedAmount: false,
  missingRequiredDocument: false,
  otherReason: ''
};

type FormValues = typeof initialValues;

export const DisputeReasonForm = ({
  invoiceNumber,
  onDispute,
  onCancel,
  isLoading
}: {
  invoiceNumber?: string;
  onDispute: (disputeReason: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleDispute = (values: FormValues) => {
    const messageBase = `Invoice${invoiceNumber ? ` #${invoiceNumber} ` : ' '}is being disputed`;
    const standardReasons = [
      values.invoicedAmount ? 'invoiced amount' : null,
      values.missingRequiredDocument ? 'missing required document' : null
    ].filter(isDefined);

    const message = `${messageBase}${standardReasons.length ? ` because: ${standardReasons.join(', ')}.` : '.'}${
      values.otherReason ? ` ${values.otherReason}` : ''
    }`;

    onDispute(message);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleDispute}>
      <div className="p-1">
        <div className="cursor-auto whitespace-nowrap bg-transparent px-5 py-1 text-left">
          <div className="font-bold">Items in Dispute</div>
          <Form>
            <Field name="invoicedAmount" label="Invoiced amount" component={FormikCheckbox} />
            <Field name="missingRequiredDocument" label="Missing required document" component={FormikCheckbox} />

            <div className="flex min-w-72 gap-2">
              <Checkbox
                checked={showOtherInput}
                onChange={(value: ChangeEvent<HTMLInputElement>) => setShowOtherInput(value.target.checked)}
                label="Other"
              />
              {showOtherInput ? (
                <Field className="grow" name="otherReason" label="Dispute reason" component={FormikTextInput} />
              ) : null}
            </div>

            <div className="flex justify-end gap-2 pt-4">
              <Button variant="secondary" size="sm" type="submit" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" color="primary" size="sm" type="submit" isLoading={isLoading}>
                Dispute
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  );
};
