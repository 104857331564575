import {createColumnHelper, flexRender} from '@tanstack/react-table';
import {ReactNode} from 'react';
import {Tooltip} from '@shipwell/shipwell-ui';
import {Table} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {CarrierToDisplay} from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayCarriers';
import CarrierDetails from 'App/containers/Shipment/components/EmbeddedPricingIntel/CarrierDetails';
import {PricingIntelTableKindEnum} from 'App/components/NewShipmentEmbeddedPricingIntel';
import {ContractTotalRate} from 'App/containers/Shipment/components/EmbeddedPricingIntel/ContractTotalRate';
import {formatCurrency} from 'App/utils/internationalConstants';

const columnHelper = createColumnHelper<CarrierToDisplay>();

const getRateColumns = ({showDPM, totalMiles}: {showDPM?: boolean; totalMiles?: number | null}) => [
  columnHelper.display({
    header: 'Carrier',
    cell: (props) => {
      const {original} = props.row;
      return (
        <Tooltip tooltipContent={<CarrierDetails carrier={original} />} placement="top" trigger="hover" portal>
          <div>{original.name}</div>
        </Tooltip>
      );
    }
  }),
  columnHelper.display({
    header: 'Rate',
    cell: (props) => {
      const {original} = props.row;
      return original.contractId ? (
        <ContractTotalRate charges={original.charges} showLaneStatsDPM={showDPM} totalDistance={totalMiles} />
      ) : (
        formatCurrency(original.rate, original.rateCurrency)
      );
    }
  })
];

const RatesTable = ({
  tableData,
  tableKind,
  showDPM,
  totalMiles
}: {
  tableData: CarrierToDisplay[];
  tableKind: PricingIntelTableKindEnum;
  showDPM?: boolean;
  totalMiles?: number | null;
}) => {
  const table = useTypedTable({
    data: tableData,
    columns: getRateColumns({showDPM, totalMiles})
  });

  return (
    <>
      {tableData?.length ? (
        <Table
          head={table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id}>
                  <div className="mb-2 flex items-center gap-1 text-[10px] font-normal uppercase">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          ))}
          body={table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  <div className="truncate">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
          isFixedTableLayout
        />
      ) : (
        <div className="flex h-[184px] items-center justify-center text-lg text-sw-disabled-text">
          {tableKind === PricingIntelTableKindEnum.contracts ? 'No Matching Contract Rates' : 'No Lane Pricing History'}
        </div>
      )}
    </>
  );
};

const TableHeader = ({children}: {children?: ReactNode; width?: number}) => {
  return <th className="text-sw-background-header">{children}</th>;
};
const TableRow = ({children}: {children?: ReactNode}) => {
  return <tr className="border-b-1 border-sw-border [&>*:nth-child(1)]:w-2/3">{children}</tr>;
};
const TableCell = ({children}: {children?: ReactNode}) => {
  return <td className="h-8 pr-3">{children}</td>;
};

export default RatesTable;
