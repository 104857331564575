import {CreateSupplierUser, Supplier, SupplierUser} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {createSupplierUser} from 'App/api/suppliers';
import {UseMutationOptionsUtil} from 'App/utils/queryHelpers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const createSupplierUsers = async ({supplierId, users}: {supplierId: string; users: CreateSupplierUser[]}) => {
  const results = await Promise.allSettled(users.map((user) => createSupplierUser({supplierId, user})));
  if (results.some((result) => result.status === 'rejected'))
    throw new Error('There was an error creating supplier contacts.');
};

export const useCreateSupplierUsers = (options?: UseMutationOptionsUtil<typeof createSupplierUsers>) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(createSupplierUsers, {
    ...options,
    async onMutate(variables) {
      await queryClient.cancelQueries([[SUPPLIERS_QUERY_KEY, variables.supplierId]]);
      const previousSupplier = queryClient.getQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId]);
      queryClient.setQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId], (oldSupplier) => {
        if (oldSupplier)
          return {
            ...oldSupplier,
            users: [
              ...(oldSupplier.users || []),
              ...(variables.users.map((u) => [
                {...u, created_at: new Date().toISOString(), id: new Date().getTime()}
                // I don't love this type assertion because it is actually a lie. I'm temporarily putting type
                // CreateSupplierUser[] into a query cache that should only hold SupplierUser[], so several fields
                // are missing. I don't see another option for doing an optimistic update here though. The `id` and
                // `created_at` fields are used for logic in the UI, so I'm faking those, but others are still missing.
              ]) as unknown as SupplierUser[])
            ]
          };
      });
      options?.onMutate?.(variables);
      return {previousSupplier};
    },
    onError(error, variables, context) {
      queryClient.setQueryData([SUPPLIERS_QUERY_KEY, variables.supplierId], context?.previousSupplier);
      options?.onError?.(error, variables, context);
    },
    onSettled: (...args) => {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
      options?.onSettled?.(...args);
    }
  });
  return {createSupplierUsers: mutate, isCreatingUsers: isLoading};
};
