export const tooltips = {
  sidebar: {
    dashboard: 'View and manage all active shipments and quotes',
    new: 'Create a new quote or manual shipment.',
    quote: 'Get instant less-than-truckload and volume quotes or spot quotes for truckload shipments.',
    shipment: 'Create a new shipment and manually route it to a carrier.',
    load: 'Create a new load. A load requires shipping and asset information in order to dispatch. A load does not require POC information on stops.',

    history: 'View all active and inactive quotes and shipments',
    addressbook: 'Add, edit, and search all of your frequently used addresses and contacts',
    customers: 'Manage customer permissions, contact information,  credit limits, assigned agents, and markups',
    company: 'Add, edit, and remove users, set permissions, and update company settings',
    carriers: 'View and manage carrier relationships, tags, and contact information',
    settings: 'Edit company, carrier, and customer specific settings',
    products: 'View and manage your shipping products',
    bids: 'View and bid on shipments on load boards you have access to'
  },
  quoting: {
    total_packages:
      'This is the number of shipping units, such as a pallet, that will be physically handled by the carrier during transport.',
    package_weight: 'This is the total weight of all units in this line item.',
    total_pieces:
      'This is the total number of pieces across all shipping units of this type (e.g., 10 boxes on 2 pallets is 10 total pieces). This field is not required.',
    nmfc_item_code: 'Enter the NMFC code associated with this commodity.',
    nmfc_sub_code: 'If the commodity uses a NMFC sub-code, enter it here.',
    total_linear_feet:
      'Volume LTL rates can vary greatly based on total linear feet. Please call your customer care representative before you schedule your shipment if you are unsure of the correct total linear feet.  The estimate provided for you is only an estimate - you must enter the correct total linear feet to ensure no additional freight charges.'
  },
  location: 'ie. business, d/c, factory, store, warehouse, etc.',
  address: 'Street Address/ City/State/Zip',
  weight: 'Please enter estimated total weight of your shipment. Note: the typical truck maxes out at 44,000 lbs.',
  description: 'Add a brief description of the cargo being shipped. eg. "20 pallets of tractor tires".',
  name: 'Shipment is how Otter Connect identifies this shipment. eg. "Thursday Shipment of Tires"',
  contact: 'We will send a notification to the POC when the driver is less than 2 hours away',
  phone: 'To notify contact person when shipment is close',
  note: 'Notes & additional info. eg. "requires forklift, tarp/tiedown, and/or lumper, etc..."',
  loadNumber: 'Enter your unique load number (Optional)',
  etaNote:
    'ETA is based on single driver assuming standard hours-of-service and current traffic and weather conditions',
  notifications: {
    shipment_booked: 'Receive a notification when a shipment is booked.',
    eta_changed: 'Receive a notification when the ETA of a shipment changes.',
    delayed: 'Receive a notification when a shipment is marked as delayed.',
    cancellation: 'Receive a notification when a shipment is cancelled.',
    delivered: 'Receive a notification when a shipment is delivered.',
    picked_up: 'Receive a notification when a shipment is picked up.',
    trailer_assigned: 'Receive a notification when a trailer has been assigned'
  }
};
