import {useIntercom} from 'react-use-intercom';
import useToggle from 'App/utils/hooks/useToggle';

function useToggleIntercom(initialIsShowing = false) {
  const intercom = useIntercom();
  const [isIntercomShowing, toggleIsIntercomShowing] = useToggle(initialIsShowing);

  function show() {
    intercom.show();
    toggleIsIntercomShowing();
  }

  function hide() {
    intercom.hide();
    toggleIsIntercomShowing();
  }

  function toggleIntercom() {
    if (isIntercomShowing) {
      hide();
    } else {
      show();
    }
  }

  return {...intercom, isIntercomShowing, toggleIntercom};
}

export default useToggleIntercom;
