import {calculateFreightClass, freightClasses} from 'App/utils/globals';

export const warnings = (lineItem, props) => {
  const {hasLTL} = props;
  const line_item = lineItem[props.name] || lineItem;
  const {length, width, height, length_unit, package_weight, weight_unit, total_packages, freight_class} = line_item;
  const maxLengthByUnit = {CM: 243.84, IN: 96, FT: 8};
  let calculatedFreightClass = calculateFreightClass(
    length,
    width,
    height,
    length_unit,
    package_weight,
    weight_unit,
    total_packages
  );
  const warnings = {};

  if (Number.isNaN(calculatedFreightClass)) {
    calculatedFreightClass = null;
  }

  if (freight_class && calculatedFreightClass) {
    /** Create warning if selected freight class is more than 2 classes from suggested class */
    const indexOfSuggestedFreight = freightClasses.findIndex(
      (freightClass) => freightClass.id === Number(calculatedFreightClass)
    );
    const indexOfSelectedFreight = freightClasses.findIndex(
      (freightClass) => freightClass.id === Number(freight_class)
    );
    const threshhold = 2;
    const freightClassWarning = Math.abs(indexOfSuggestedFreight - indexOfSelectedFreight) > threshhold;

    if (freightClassWarning) {
      warnings.freightClassWarning = true;
    }
  }

  if (hasLTL && length && maxLengthByUnit[length_unit] <= length) {
    warnings.excessiveLengthWarning = true;
  }

  if (hasLTL && width && maxLengthByUnit[length_unit] <= width) {
    warnings.excessiveLengthWarning = true;
  }

  if (hasLTL && height && maxLengthByUnit[length_unit] <= height) {
    warnings.excessiveLengthWarning = true;
  }

  return warnings;
};
