import {Supplier} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateSupplier} from 'App/api/suppliers';
import {UseMutationOptionsUtil} from 'App/utils/queryHelpers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useUpdateSupplier = (options?: UseMutationOptionsUtil<typeof updateSupplier>) => {
  const queryClient = useQueryClient();

  const {mutate, isLoading} = useMutation(updateSupplier, {
    ...options,
    async onMutate(variables) {
      await queryClient.cancelQueries([SUPPLIERS_QUERY_KEY]);
      const previousSupplier = queryClient.getQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId]);
      queryClient.setQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId], (oldSupplier) => {
        if (oldSupplier) {
          return {
            ...oldSupplier,
            ...variables.updatedSupplier
          };
        }
      });

      options?.onMutate?.(variables);

      return {previousSupplier};
    },
    onError(error, variables, context) {
      queryClient.setQueryData([SUPPLIERS_QUERY_KEY, variables.supplierId], context?.previousSupplier);
      options?.onError?.(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
      options?.onSettled?.(data, error, variables, context);
    }
  });
  return {updateSupplier: mutate, isUpdatingSupplier: isLoading};
};
