import remove from 'lodash/remove';
import PropTypes from 'prop-types';
import {Checkbox} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import withFlags from 'App/utils/withFlags';
import {shouldDisableShipmentSelection} from 'App/utils/shipmentTableHelpers';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';

/**
 * Render function for header checkbox (Select All functionality)
 */
const RenderBulkSelectCheckbox = ({currentPage = [], selected = [], onSelect}) => {
  const allOrdersSelected = selected.length > 0 && currentPage.every((item) => selected.some((s) => s.id === item.id));
  const ordersOnCurrentPageSelected = currentPage.some((item) => selected.some((s) => s.id === item.id));
  const isIndeterminate = selected.length > 0 && !allOrdersSelected && ordersOnCurrentPageSelected;

  const handleBulkSelect = () => {
    // if indeterminate or all orders on page are selected, remove items from current page
    if (isIndeterminate || allOrdersSelected) {
      const filteredItems = selected.filter((item) => currentPage.every((i) => i.id !== item.id));
      return onSelect(filteredItems);
    }
    onSelect([...selected, ...currentPage]);
  };

  return (
    <Checkbox
      fixedHeight={false}
      indeterminate={isIndeterminate}
      checked={allOrdersSelected || isIndeterminate}
      onChange={handleBulkSelect}
    />
  );
};

RenderBulkSelectCheckbox.propTypes = {
  currentPage: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func
};

/**
 * Render function for row checkbox (Select individual row functionality)
 */
const RenderCheckboxBase = ({
  original,
  selected = [],
  onSelect,
  isDashboard,
  shipment,
  wfaBulkActions,
  wfaBulkActionShipmentStatusChange,
  isFedexEnabled
}) => {
  const handleSelect = (item) => {
    const toSelect = [...selected];
    // remove item if found/ already selected
    const removed = remove(toSelect, (s) => {
      return s.id === item?.id;
    });
    // if no item was found/ not already selected, select it
    if (removed.length === 0) {
      toSelect.push(item);
    }
    onSelect([...toSelect]);
  };

  const isDisabled =
    isDashboard &&
    shouldDisableShipmentSelection(
      original,
      shipment,
      isFedexEnabled,
      wfaBulkActions || wfaBulkActionShipmentStatusChange
    );

  return (
    <Checkbox
      disabled={isDisabled}
      checked={selected.some((e) => e.id === original.id)}
      onChange={() => handleSelect(original)}
      onClick={(event) => event.stopPropagation()}
    />
  );
};

RenderCheckboxBase.propTypes = {
  original: PropTypes.shape({
    id: PropTypes.string
  }),
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  isDashboard: PropTypes.bool,
  shipment: PropTypes.object,
  wfaBulkActions: PropTypes.bool,
  wfaBulkActionShipmentStatusChange: PropTypes.bool,
  isFedexEnabled: PropTypes.bool
};

/**
 *
 * @param {Array} currentPage - array of visible rows for this resource
 * @param {Array} selected  - array of currently-selected rows for this resource
 * @param {Function} onSelect  - state function to select rows
 * @param {Boolean} isDashboard  - used to pass in specific dashboard functionality
 * @param {String} propertyToDisableOn  - string property within the table data to disable the checkbox on
 */
const renderBulkSelectColumn = ({
  currentPage,
  selected,
  onSelect,
  isDashboard,
  shipment,
  propertyToDisableOn = undefined
}) => {
  return {
    Header: () => (
      <RenderBulkSelectCheckbox
        currentPage={
          currentPage?.length > 0 && propertyToDisableOn
            ? currentPage.filter((row) => !row[propertyToDisableOn])
            : currentPage
        }
        selected={selected}
        onSelect={onSelect}
      />
    ),
    id: 'id',
    maxWidth: 75,
    sortable: false,
    accessor: 'id',
    fixed: 'left',
    // eslint-disable-next-line react/prop-types
    Cell: ({original}) => {
      // eslint-disable-next-line react/prop-types
      const {version} = original;
      const isV3 = version === V3_VERSION;
      return isV3 || (propertyToDisableOn && original[propertyToDisableOn]) ? (
        <Checkbox disabled />
      ) : (
        <RenderCheckbox
          original={original}
          selected={selected}
          onSelect={onSelect}
          isDashboard={isDashboard}
          shipment={shipment}
        />
      );
    }
  };
};

export const RenderCheckbox = compose(
  withFlags('wfaBulkActions', 'wfaBulkActionShipmentStatusChange'),
  connect((state) => ({
    isFedexEnabled: state.userCompany?.company?.feature_flags?.fedex_enabled
  }))
)(RenderCheckboxBase);

export default renderBulkSelectColumn;
