import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Form} from 'redux-form';
import {Button} from 'react-bootstrap';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import StopsExpanded from 'App/containers/Book/FormSections/StopsExpanded';
import validate from 'App/utils/validateShipmentDetails';
import {scrollToFirstErrorField} from 'App/utils/globals';

class ShipmentStopsUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {hasScrolled: false};
  }

  componentWillReceiveProps(nextProps) {
    const scrollOptions = {behavior: 'smooth', block: 'start'};
    // scroll to edit stop
    if (nextProps.stop && !this.state.hasScrolled) {
      this.setState({hasScrolled: true});
      const scrollToStop = document.getElementById(`stop-${nextProps.stop + 1}`);
      if (scrollToStop) {
        scrollToStop.scrollIntoView(scrollOptions);
      }
    }
    // scroll to edit accessorials
    if (nextProps.editingAccessorials && !this.state.hasScrolled) {
      this.setState({hasScrolled: true});
      const scrollToAccessorials = document.getElementById('js-scrollto-accessorials');
      if (scrollToAccessorials) {
        scrollToAccessorials.scrollIntoView(scrollOptions);
      }
    }
  }

  handleCancel() {
    const {reset, cancelEdit, updated} = this.props;
    const stopOne = document.getElementById('stop-1');

    reset();
    cancelEdit();

    if (stopOne) {
      // scroll back to top
      stopOne.scrollIntoView({behavior: 'smooth'});
    }
    updated();
  }

  render() {
    const {
      initialValues = {},
      shipmentStopsUpdateForm,
      handleSubmit,
      error,
      submitting,
      updated,
      customFields = []
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <StopsExpanded
          isUpdateForm
          isDetailsForm
          form="shipmentStopsUpdateForm"
          formValues={shipmentStopsUpdateForm}
          onUpdateShipmentSuccess={updated}
          customFields={customFields}
          setAddOrderSuccess={this.props.setAddOrderSuccess}
          setAddOrderError={this.props.setAddOrderError}
        />
        <div className="update-footer">
          {initialValues.stops && initialValues.stops.length > 0 && (
            <Button
              type="button"
              bsStyle="default"
              disabled={submitting}
              onClick={this.handleCancel.bind(this)}
              className="cancel-edit"
              name="cancel-edit"
            >
              Cancel
            </Button>
          )}
          <Button bsStyle="primary" type="submit" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" /> Saving...
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </div>
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
      </Form>
    );
  }
}

ShipmentStopsUpdateForm = reduxForm({
  form: 'shipmentStopsUpdateForm',
  validate: validate,
  enableReinitialize: true,
  touchOnChange: true,
  onSubmitFail: scrollToFirstErrorField,
  onSubmitSuccess: (result, dispatch, props) => {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }
})(ShipmentStopsUpdateForm);

export default connect(
  (state) => ({
    initialValues: state.shipmentdetails.one,
    accessorials: state.shipments.accessorialCodes,
    shipmentStopsUpdateForm: state.form.shipmentStopsUpdateForm,
    companyId: state.auth.company.id
  }),
  {
    ...shipmentdetailsActions
  }
)(ShipmentStopsUpdateForm);
