import {Field} from 'formik';
import {FormikTextInput, FormikImageSelect} from '@shipwell/shipwell-ui';
import './styles.scss';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';
import Loader from 'App/common/shipwellLoader';

const MiniProductFields = () => {
  const {data: internationalPreferences, isInitialLoading: isLoadingInternationalPreferences} =
    useGetInternationalPreferences();
  if (isLoadingInternationalPreferences) {
    return <Loader loading />;
  }
  return (
    <>
      <div className="mini-product-image-container">
        <Field name="image" component={FormikImageSelect} isAvatar={false} />
      </div>
      <Field
        required
        label="Reference"
        name="product_ref"
        placeholder="SKU or other primary product reference"
        component={FormikTextInput}
        className="mini-product-margin-top"
      />
      <Field
        label={`Value Per Piece (${internationalPreferences?.currency || ''})`}
        name="value_per_piece"
        component={FormikTextInput}
        className="mini-product-margin-top"
      />
    </>
  );
};

export default MiniProductFields;
