import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SubmissionError} from 'redux-form';
import {Modal, DeprecatedButton} from '@shipwell/shipwell-ui';
import ShareLinkForm from '../../forms/ShareLinkForm';
import {shareLink} from 'App/actions/shipmentdetails';
import {unpackErrors} from 'App/utils/globals';

import './styles.scss';

const SHARE_EXTERNAL_TRACKING_TITLE = 'Share External Tracking';

const ShareLinkAction = ({shipment, shareLink}) => {
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);

  const handleShareLinkClick = () => {
    setShowShareLinkModal(true);
  };

  const handleShareLink = async (values) => {
    const payload = {
      recipient_emails: values?.emails?.map((email) => email.value),
      recipient_phone_number: values?.recipient_phone_number,
      include_map: values?.include_map
    };

    try {
      const response = await shareLink(shipment.id, payload);
      setShowShareLinkModal(false);
      return response;
    } catch (e) {
      console.error(`Error sharing link for ${shipment.id}`, e);
      const errors = e.field_errors || [];
      const submissionError = unpackErrors(errors, {});
      submissionError._error = e.error_description;
      throw new SubmissionError(submissionError);
    }
  };

  return (
    <>
      <DeprecatedButton variant="text" onClick={handleShareLinkClick}>
        {SHARE_EXTERNAL_TRACKING_TITLE}
      </DeprecatedButton>
      <Modal
        show={showShareLinkModal}
        onClose={() => setShowShareLinkModal(false)}
        footerComponent={null}
        title={SHARE_EXTERNAL_TRACKING_TITLE}
      >
        <ShareLinkForm values={shipment} onSubmit={handleShareLink} onCancel={() => setShowShareLinkModal(false)} />
      </Modal>
    </>
  );
};

ShareLinkAction.propTypes = {
  shipment: PropTypes.object,
  shareLink: PropTypes.func,
  shareLinkForm: PropTypes.object
};

ShareLinkAction.defaultProps = {
  shipment: {},
  shareLink: () => {},
  shareLinkForm: {}
};

export default connect(
  (state) => ({shareLinkForm: state.form.shareLinkForm}),
  (dispatch) => bindActionCreators({shareLink}, dispatch)
)(ShareLinkAction);
