import {
  InvoicingApiInvoicingShipmentsShipmentIdInvoicesGetRequest,
  PaginatedInvoice
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {QueryKey, UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getShipmentInvoices} from 'App/api/integrations/typed';
import {SHIPMENT_INVOICES_KEY} from 'App/data-hooks/queryKeys';

export const useGetShipmentInvoices = ({
  requestBody,
  options
}: {
  requestBody: InvoicingApiInvoicingShipmentsShipmentIdInvoicesGetRequest;
  options?: Omit<
    UseQueryOptions<PaginatedInvoice, unknown, PaginatedInvoice, QueryKey>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const query = useQuery(
    [SHIPMENT_INVOICES_KEY, requestBody],
    async () => {
      const response = await getShipmentInvoices(requestBody);
      return response.data;
    },
    options
  );

  const {data: shipmentInvoices, status: shipmentInvoicesStatus} = query;

  return {
    shipmentInvoices,
    shipmentInvoicesStatus,
    query
  };
};
