import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {set, get} from 'lodash';
import {Select} from '@shipwell/shipwell-ui';
import store from 'App/routes/store';

/*
 * Filter Component for lookup (async or local)
 */
const Lookup = (props) => {
  const {
    label,
    loadOptions,
    name,
    secondaryName,
    onChange,
    options,
    useStore,
    parentValue,
    parentSecondaryValue,
    getOptionValue,
    getOptionLabel,
    labelPath
  } = props;
  const [value, setValue] = useState([]);

  /*
   * Update parent component with new values
   */
  const handleSelection = (values) => {
    if (getOptionValue && getOptionLabel && values.length) {
      values = values.map((e) => {
        return {...e, id: getOptionValue(e)};
      });
    }
    setValue(values);
    if (onChange) {
      const newValue = {};
      newValue[name] = values;
      //also save a display name for async
      if (loadOptions && secondaryName) {
        newValue[secondaryName] = values.map((e) => getOptionLabel(e));
      }

      onChange(newValue);
    }
  };

  useEffect(() => {
    if (parentValue && parentValue.length) {
      let selectedOptions = [];
      if (useStore) {
        const options = get(store.getState(), useStore);
        if (Array.isArray(parentValue)) {
          selectedOptions = parentValue.map((val) => options.find((opt) => getOptionValue(opt) === val));
        } else {
          selectedOptions = [options.find((opt) => getOptionValue(opt) === parentValue)];
        }
      } else {
        //for async lookups, we store a secondary value in the URL that can be used for the display
        if (Array.isArray(parentSecondaryValue) && parentSecondaryValue.length) {
          selectedOptions = parentValue.map((e, index) => {
            const option = {id: e};
            set(option, labelPath, parentSecondaryValue[index]);
            return option;
          });
        } else {
          const option = {id: parentValue};
          set(option, labelPath, parentSecondaryValue);
          selectedOptions = [option];
        }
      }
      if (selectedOptions.length > 0) {
        setValue(selectedOptions);
      }
    }
  }, []);

  /*
   * Update values when parent value changes, i.e. a selection is cleared
   */
  useEffect(() => {
    if (value.length > 0 && parentValue?.length !== value.length) {
      const newValues = value.filter((val) => parentValue.includes(val.id));
      setValue(newValues);
    }
  }, [parentValue, value]);

  return (
    <div className="filter__lookup filter__option">
      <Select
        {...props}
        value={value}
        name={name}
        label={label}
        onChange={handleSelection}
        clearable
        async={Boolean(loadOptions)}
        options={useStore ? get(store.getState(), useStore) : options}
        loadOptions={loadOptions}
      />
    </div>
  );
};

Lookup.propTypes = {
  label: PropTypes.string,
  loadOptions: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  useStore: PropTypes.string
};

export default Lookup;
