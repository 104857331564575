import {useState, ReactNode, SetStateAction, Dispatch} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {FreightInvoiceStatus, SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';
import moment from 'moment';
import {Pill, Tooltip, Popover} from '@shipwell/shipwell-ui';
import {FreightInvoiceStatus as SettlementsFreightInvoiceStatus} from '@shipwell/settlements-sdk';
import invariant from 'tiny-invariant';
import useUpdateFreightInvoiceStatus from 'App/api/settlements/queryHooks';
import {formatTime} from 'App/utils/globals';
import {StatusData, isSelectableStatus} from 'App/containers/settlements/freightInvoices/types';
import {useCreateShipmentMessage} from 'App/api/shipment/useCreateShipmentMessage';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {RejectReasonForm} from 'App/containers/settlements/freightInvoices/components/FreightInvoicesList/components/FreightInvoiceStatusCell/components/RejectReasonForm';
import {DisputeReasonForm} from 'App/containers/settlements/freightInvoices/components/FreightInvoicesList/components/FreightInvoiceStatusCell/components/DisputeReasonForm';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {UPDATE_FREIGHT_INVOICES_PERMISSION} from 'App/containers/settlements/freightInvoices/permissionsConstants';
import {useHasUserPermissions} from 'App/data-hooks/users/useUserPermissions';

const StatusPill = ({
  status,
  isOpen,
  onClick,
  children
}: {
  status: FreightInvoiceStatus;
  isOpen?: boolean;
  onClick?: () => void;
  children: ReactNode;
}) => {
  const {variant} = StatusData[status];
  const hasPermissions = useHasUserPermissions([UPDATE_FREIGHT_INVOICES_PERMISSION]);
  return (
    <Pill
      onClick={onClick}
      UNSAFEclassName="uppercase"
      variant={variant}
      minWidth={false}
      iconName={hasPermissions && typeof isOpen === 'boolean' ? (isOpen ? 'ExpandLess' : 'ExpandMore') : undefined}
    >
      {children}
    </Pill>
  );
};

const StatusPillTooltip = ({updatedAt, children}: {updatedAt?: string; children: ReactNode}) => (
  <Tooltip
    portal
    tooltipClassname="pointer-events-none"
    placement="top"
    tooltipContent={
      <div>
        <b>Last Update</b>
        <br />
        {moment(updatedAt).format('MMM DD, YYYY')} {formatTime(updatedAt, true, moment.tz.guess())}
      </div>
    }
  >
    {children}
  </Tooltip>
);

type FreightInvoiceStatusCellProps = {
  invoiceId: string;
  invoiceNumber: string;
  status: FreightInvoiceStatus;
  updatedAt?: string;
  shipmentId: string;
  role: SettlementsDashboardGeneratedBy;
};

/**
 * Handles user feedback for the success or failure of rejecting an invoice, given that rejecting an invoice requires
 * both updating the status of the invoice, and posting a message to shipment messages indicating the reason the invoice
 * was rejected.
 *
 * `onRejectComplete` - Called  when `statusChangeStatus==='fulfilled'`
 *
 * `onError` - Called with an error message when `messagePostStatus==='rejected'` or `statusChangeStatus==='rejected'`, or both.
 *
 */
export const handleInvoiceRejectionError = ({
  messagePostStatus,
  statusChangeStatus,
  invoiceNumber,
  onRejectComplete,
  onError
}: {
  messagePostStatus: PromiseSettledResult<unknown>['status'];
  statusChangeStatus: PromiseSettledResult<unknown>['status'];
  invoiceNumber?: string;
  onRejectComplete: () => void;
  onError: (errorMessage: string) => void;
}) => {
  if (messagePostStatus === 'rejected' && statusChangeStatus === 'rejected') {
    onError(`Failed to reject invoice${invoiceNumber ? ` #${invoiceNumber}` : ''}. Try again.`);
  } else if (messagePostStatus === 'rejected') {
    onError(
      `Invoice${
        invoiceNumber ? ` #${invoiceNumber} ` : ' '
      }was successfully rejected, but rejection reason failed to post to shipment messages.`
    );
    onRejectComplete();
  } else if (statusChangeStatus === 'rejected') {
    onError(
      `Invoice${
        invoiceNumber ? ` #${invoiceNumber} ` : ' '
      } failed to be rejected, but rejection reason was posted to shipment messages. Try again.`
    );
  } else {
    onRejectComplete();
  }
};

export const handleInvoiceDisputedError = ({
  messagePostStatus,
  statusChangeStatus,
  invoiceNumber,
  onDisputeComplete,
  onError
}: {
  messagePostStatus: PromiseSettledResult<unknown>['status'];
  statusChangeStatus: PromiseSettledResult<unknown>['status'];
  invoiceNumber?: string;
  onDisputeComplete: () => void;
  onError: (errorMessage: string) => void;
}) => {
  if (messagePostStatus === 'rejected' && statusChangeStatus === 'rejected') {
    onError(`Failed to dispute invoice${invoiceNumber ? ` #${invoiceNumber}` : ''}. Try again.`);
  } else if (messagePostStatus === 'rejected') {
    onError(
      `Invoice${
        invoiceNumber ? ` #${invoiceNumber} ` : ' '
      }was successfully disputed, but disputed reason failed to post to shipment messages.`
    );
    onDisputeComplete();
  } else if (statusChangeStatus === 'rejected') {
    onError(
      `Invoice${
        invoiceNumber ? ` #${invoiceNumber} ` : ' '
      } failed to be disputed, but disputed reason was posted to shipment messages. Try again.`
    );
  } else {
    onDisputeComplete();
  }
};

const FreightInvoiceStatusCellBase = ({
  invoiceId,
  invoiceNumber,
  status,
  updatedAt,
  shipmentId,
  role,
  setError
}: FreightInvoiceStatusCellProps & {setError: WithStatusToastProps['setError']}) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [isDisputing, setIsDisputing] = useState(false);
  const {mutateAsync: updateFreightInvoiceStatus, isLoading: isUpdatingStatus} =
    useUpdateFreightInvoiceStatus(shipmentId);
  const {stmDisputedInvoice} = useFlags();

  const updateStatus = (optionId: FreightInvoiceStatus) => {
    assertAsFreightInvoiceStatus(optionId);
    void updateFreightInvoiceStatus({invoiceId, status: optionId});
  };

  const {mutateAsync: createMessage, isLoading: isPostingMessage} = useCreateShipmentMessage(shipmentId);

  const setToastError = (message: string) =>
    setError('Error rejecting invoice', <div>{message}</div>, 'top-right', {delay: null});

  const handleReject = async (rejectReason: string, {onRejectComplete}: {onRejectComplete: () => void}) => {
    const [messagePostResult, statusChangeResult] = await Promise.allSettled([
      createMessage({message: rejectReason, shipment: shipmentId}),
      updateFreightInvoiceStatus({invoiceId, status: 'REJECTED'})
    ]);
    handleInvoiceRejectionError({
      messagePostStatus: messagePostResult.status,
      statusChangeStatus: statusChangeResult.status,
      invoiceNumber: invoiceNumber,
      onError: (errorMessage) => setToastError(errorMessage),
      onRejectComplete: () => onRejectComplete()
    });
  };

  const handleDispute = async (disputeReason: string, {onDisputeComplete}: {onDisputeComplete: () => void}) => {
    const [messagePostResult, statusChangeResult] = await Promise.allSettled([
      createMessage({message: disputeReason, shipment: shipmentId}),
      updateFreightInvoiceStatus({invoiceId, status: 'DISPUTED'})
    ]);
    handleInvoiceDisputedError({
      messagePostStatus: messagePostResult.status,
      statusChangeStatus: statusChangeResult.status,
      invoiceNumber: invoiceNumber,
      onError: (errorMessage) => setToastError(errorMessage),
      onDisputeComplete: () => onDisputeComplete()
    });
  };

  if (status === FreightInvoiceStatus.Voided || status === FreightInvoiceStatus.Rejected) {
    const statusValues = StatusData[status];
    return (
      <StatusPillTooltip updatedAt={updatedAt}>
        <StatusPill status={status}>{statusValues.label || status}</StatusPill>
      </StatusPillTooltip>
    );
  }

  const flaggedStatuses: FreightInvoiceStatus[] = [FreightInvoiceStatus.Disputed, FreightInvoiceStatus.Resolved];
  const statusList = StatusData[status][role]
    .filter((statusItem) => statusItem !== status)
    .filter((statusItem) => !flaggedStatuses.includes(statusItem) || stmDisputedInvoice);
  const StatusPillViewOnly = () => {
    return (
      <StatusPillTooltip updatedAt={updatedAt}>
        <StatusPill status={status} isOpen={false}>
          {status}
        </StatusPill>
      </StatusPillTooltip>
    );
  };
  return statusList.length > 0 ? (
    <Popover
      placement="bottom-start"
      trigger={({
        isOpen,
        setIsOpen,
        setTriggerElement
      }: {
        isOpen: boolean;
        setIsOpen: Dispatch<SetStateAction<boolean>>;
        setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
      }) => {
        //if the user is rejecting or disputing a status, we temporarily override the invoice status
        //while the rejection/disputed reasons are filled out.
        const statusWithTemporaryOverride = isRejecting ? 'REJECTED' : isDisputing ? 'DISPUTED' : status;
        return (
          <PermissionsFallback
            permissions={[UPDATE_FREIGHT_INVOICES_PERMISSION]}
            fallbackComponent={<StatusPillViewOnly />}
          >
            <StatusPillTooltip updatedAt={updatedAt}>
              <div ref={setTriggerElement}>
                <StatusPill
                  onClick={() => setIsOpen((prev) => !prev)}
                  status={statusWithTemporaryOverride}
                  isOpen={isOpen}
                >
                  {statusWithTemporaryOverride}
                </StatusPill>
              </div>
            </StatusPillTooltip>
          </PermissionsFallback>
        );
      }}
    >
      {({setIsOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>}) => {
        if (isRejecting) {
          return (
            <RejectReasonForm
              invoiceNumber={invoiceNumber}
              onCancel={() => {
                setIsRejecting(false);
                setIsOpen(false);
              }}
              onReject={(reason) => {
                void handleReject(reason, {
                  onRejectComplete() {
                    setIsRejecting(false);
                    setIsOpen(false);
                  }
                });
              }}
              isLoading={isUpdatingStatus || isPostingMessage}
            />
          );
        }
        if (isDisputing) {
          return (
            <DisputeReasonForm
              invoiceNumber={invoiceNumber}
              onCancel={() => {
                setIsDisputing(false);
                setIsOpen(false);
              }}
              onDispute={(reason) => {
                void handleDispute(reason, {
                  onDisputeComplete() {
                    setIsDisputing(false);
                    setIsOpen(false);
                  }
                });
              }}
              isLoading={isUpdatingStatus || isPostingMessage}
            />
          );
        }
        return (
          <ul className="flex w-24 flex-col gap-1 p-1">
            {statusList.map((status) => {
              const statusData = StatusData[status];
              if (isSelectableStatus(statusData)) {
                return (
                  <StatusPill
                    key={status}
                    onClick={() => {
                      if (status === FreightInvoiceStatus.Rejected) {
                        setIsRejecting(true);
                      } else if (status === FreightInvoiceStatus.Disputed) {
                        setIsDisputing(true);
                      } else {
                        assertAsFreightInvoiceStatus(status);
                        updateStatus(status);
                        setIsOpen(false);
                      }
                    }}
                    status={status}
                  >
                    {statusData.actionLabel}
                  </StatusPill>
                );
              }
            })}
          </ul>
        );
      }}
    </Popover>
  ) : (
    <StatusPillTooltip updatedAt={updatedAt}>
      <StatusPill status={status}>{StatusData[status].label ?? status}</StatusPill>
    </StatusPillTooltip>
  );
};

function assertAsFreightInvoiceStatus(
  status: FreightInvoiceStatus | SettlementsFreightInvoiceStatus
): asserts status is SettlementsFreightInvoiceStatus {
  invariant(
    Object.values(SettlementsFreightInvoiceStatus).includes(status as SettlementsFreightInvoiceStatus),
    `Invoice status ${status} is not assignable to SettlementsFreightInvoiceStatus. Users may not assign this status. Check the clickable options configured in the component`
  );
}

export const FreightInvoiceStatusCell = withStatusToasts<FreightInvoiceStatusCellProps>(FreightInvoiceStatusCellBase);
