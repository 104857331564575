import {Title} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import ShipwellLoader from 'App/common/shipwellLoader';
import ConflictingWorkflows from 'App/containers/ConflictingWorkflows';
import WorkflowSummaryCard from 'App/containers/WorkflowSummaryCard';
import {WORKFLOW_EXECUTION_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useGetWorkflowExecutions, useGetWorkflowTriggeringEvents} from 'App/data-hooks/workflows';

export const DashboardSummaryWorkflow = ({shipmentId}: {shipmentId: string}) => {
  const queryClient = useQueryClient();
  const {workflowExecutionsData, workflowExecutionsStatus, getWorkflowExecutionsQuery} = useGetWorkflowExecutions({
    shipmentId
  });
  const routingGuides = workflowExecutionsData?.data || [];
  const {workflowTriggeringEventsData, workflowTriggeringEventsStatus} = useGetWorkflowTriggeringEvents({
    triggeringResourceIds: [shipmentId]
  });
  const workflowExecutionErrorDetails = workflowTriggeringEventsData?.data?.[0];
  const isLoading =
    [workflowExecutionsStatus, workflowTriggeringEventsStatus].includes('loading') ||
    getWorkflowExecutionsQuery.isFetching;

  const handleSelectRoutingGuide = async (shipmentId: string) =>
    await queryClient.invalidateQueries([WORKFLOW_EXECUTION_QUERY_KEY, shipmentId]);
  return (
    <div className="mt-4 flex h-full flex-col gap-4 px-4">
      {/* Is loading */}
      {isLoading ? (
        <div className="inset-0 flex items-center justify-center">
          <ShipwellLoader loading />
        </div>
      ) : // Has routing guides
      routingGuides?.length ? (
        routingGuides.map((routingGuide) => (
          <WorkflowSummaryCard key={routingGuide.id} summary={routingGuide} isMobileView />
        ))
      ) : // Has error details and no routing guides
      workflowExecutionErrorDetails ? (
        <ConflictingWorkflows
          errorDetails={workflowExecutionErrorDetails}
          onSelectRoutingGuide={handleSelectRoutingGuide}
          shipmentId={shipmentId}
        />
      ) : (
        <div className="flex h-full flex-col items-center justify-center gap-4 pb-4">
          <Title variant="emptyStateHeader" className="grow-0">
            No Workflows on this Shipment
          </Title>
        </div>
      )}
    </div>
  );
};
