export const accessorialPickupBylocationType = {
  2: 21,
  3: 35,
  4: 22,
  5: 22,
  6: 7,
  7: 22,
  8: 22,
  9: 22,
  10: 22,
  11: 22,
  12: 22,
  13: 22,
  14: 22
};

export const accessorialDropOffBylocationType = {
  2: 71,
  3: 85,
  4: 72,
  5: 72,
  6: 52,
  7: 72,
  8: 72,
  9: 72,
  10: 72,
  11: 72,
  12: 72,
  13: 72,
  14: 72
};
