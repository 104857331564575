import {DisplayValue, Title, Tooltip} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import {ShipmentReference} from '@shipwell/backend-core-singlerequestparam-sdk';
import type {
  ReferenceItem,
  Context,
  ReferenceFieldName
} from 'App/containers/Shipment/components/References/utils/constants';

const displayValueMapper = (context: Context) => {
  const DisplayValueMapper = ({label, fieldName}: ReferenceItem) => {
    const contextPropValue = context[fieldName]?.split(',');

    return (
      <DisplayValue key={`shipment_references.${fieldName}`} label={label}>
        {contextPropValue && contextPropValue?.length > 1 ? (
          <div className="flex flex-row">
            {contextPropValue[0]}
            <div className="pl-0.5 text-sw-primary">
              <Tooltip
                placement={'right'}
                tooltipContent={
                  <>
                    <div className="pb-2 font-bold">{label.toUpperCase()}</div>
                    {contextPropValue.slice(1).map((value, index) => {
                      return <div key={index}>{value}</div>;
                    })}
                  </>
                }
              >
                +{contextPropValue.length - 1}
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="break-words">{get(context, fieldName) || '--'}</div>
        )}
      </DisplayValue>
    );
  };

  return DisplayValueMapper;
};

const ReferenceColumns = ({
  title = 'Reference Numbers',
  references = [],
  context,
  referencesArray = []
}: {
  title?: string;
  references: ReferenceItem[];
  context: Context;
  referencesArray?: ShipmentReference[];
}) => {
  // this function transforms ReferencesArrayItem[] into Context, and merges
  // the plain fields coming form the entity object to the reference values
  // coming from the references array, if it's provided.

  const transformReferencesArrayIntoContext = (
    EntityContext: Context,
    referencesArray: ShipmentReference[]
  ): Context => {
    return referencesArray.reduce(
      (prev, curr) => {
        prev[curr?.qualifier as ReferenceFieldName] = curr.value;
        return prev;
      },
      {...EntityContext}
    );
  };

  const mergedContext = transformReferencesArrayIntoContext(context, referencesArray);

  return (
    <>
      <Title variant="sectionTitle">{title}</Title>
      <div className="grid grid-cols-2 gap-2">{references.map(displayValueMapper(mergedContext))}</div>
    </>
  );
};

export default ReferenceColumns;
