import {useState, useCallback, useEffect} from 'react';
import {debounce, startCase, toLower} from 'lodash';
import classNames from 'classnames';
import {Link} from 'react-router';
import moment from 'moment';
import {connect} from 'react-redux';
import {Toast, Modal, DeprecatedButton, SearchField, SvgIcon} from '@shipwell/shipwell-ui';
import {filters} from './filters';
import ShipwellTable from 'App/components/swTable';
import {getSupplierInvites, createSupplierInvite, updateSupplierInvite} from 'App/api/orders';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';
import SupplierShipmentRequestForm from 'App/formComponents/forms/supplierShipments';
import {formatDateTime} from 'App/utils/globals';
import buildPathParams from 'App/utils/buildPathParams';
import PageHeader from 'App/common/pageHeader';
import {useUserPageSize} from 'App/utils/hooks/useUserTableHooks';
import {defaultTableMeta} from 'App/utils/tableUtils';
import '../styles.scss';

/** Debounced path update - keep outside of functional component */
const pathUpdateDebounced = debounce((router, path) => router.push(path), 300);

const SupplierShipmentList = (props) => {
  const {location, router, tableType = 'SUPPLIER_SHIPMENT_LIST', company} = props;
  const {query} = location;
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [shipmentSelectedForRevoke, setShipmentSelectedForRevoke] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showRevokeSuccess, setShowRevokeSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [meta, setMeta] = useState(defaultTableMeta);
  const [customers, setCustomers] = useState([]);

  const userPageSize = useUserPageSize(tableType);

  /** Fetch requests on pagination changes */
  useEffect(() => {
    try {
      fetchShipmentRequests(location.query);
    } catch (error) {
      console.error(error);
    }
  }, [location.query, userPageSize, fetchShipmentRequests]);

  useEffect(() => {
    const fetchInitalCustomerList = async () => {
      const customers = await getCustomers();
      // response includes own brokerage,
      // only save results and allow assignment if more than one
      if (customers?.length > 1) {
        setCustomers(customers);
      }
    };
    fetchInitalCustomerList();
  }, [getCustomers]);

  const getCustomers = useCallback(
    async (opts = {}) => {
      const {brokerage = {}} = company;
      if (brokerage.id) {
        try {
          const response = await fetchBrokerShipperRelationshipsPromise(brokerage.id, opts);
          if (response.ok) {
            return response.body.results;
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    [company]
  );

  /**
   * Search shipment requests
   * @param {*} options
   */
  const fetchShipmentRequests = useCallback(
    async (opts) => {
      if (userPageSize === null) {
        return;
      }
      setLoading(true);
      if (opts && Object.keys(opts).length) {
        Object.keys(opts).forEach((key) => {
          const matchingFilter = filters.find((filter) => filter.name === key || filter.secondaryName === key);
          if (matchingFilter && matchingFilter.useArray && !Array.isArray(opts[key])) {
            opts[key] = [opts[key]];
          }
        });
      }
      opts = {
        pageSize: userPageSize,
        ...opts
      };
      try {
        await getSupplierInvites(opts).then((response) => {
          setLoading(false);
          setMeta({
            page: query.page || 1,
            pages: response.body.total_pages,
            pageSize: response.body.page_size
          });
          setResults(response.body.results);
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [userPageSize, query.page]
  );

  const handleSearch = (e) => {
    const term = e.target.value;
    const updatedPath = buildPathParams(location, {q: term, page: 1});

    setSearchTerm(term);
    pathUpdateDebounced(router, updatedPath);
  };

  const onFormSubmit = async (values, {setSubmitting}) => {
    setShowError(false);
    setSubmitting(true);
    try {
      const response = await createSupplierInvite(values);
      if (response && response.body) {
        // refetch the list
        fetchShipmentRequests(location.query);
        handleToggleRequestModal();
        setShowSuccess(true);
      }
    } catch (error) {
      setShowError(error.error_description);
    }
    setSubmitting(false);
  };

  const revokeRequest = async (shipment) => {
    shipmentSelectedForRevoke.revoked_at = new Date();
    try {
      const response = await updateSupplierInvite(shipmentSelectedForRevoke.key, shipmentSelectedForRevoke);
      if (response && response.body) {
        handleToggleRevokeModal();
        fetchShipmentRequests(meta);
        setShowRevokeSuccess(true);
      }
    } catch (error) {
      setShowError(error.error_description);
    }
  };

  const handleToggleRequestModal = () => {
    setShowRequestModal(!showRequestModal);
  };
  const handleToggleRevokeModal = (shipment) => {
    setShipmentSelectedForRevoke(shipment);
  };

  return (
    <div className="requests-wrapper">
      <div className="requests-list">
        <PageHeader
          title="Shipment Requests"
          actions={
            <DeprecatedButton
              onClick={() => handleToggleRequestModal()}
              icon={<SvgIcon name="AddCircleOutlined" />}
              variant="tertiary"
            >
              Request Shipment
            </DeprecatedButton>
          }
        >
          <SearchField
            label="Search shipment requests"
            name="search_shipment_requests"
            value={searchTerm}
            onChange={handleSearch}
          />{' '}
        </PageHeader>
        <ShipwellTable
          router={router}
          location={location}
          data={results}
          filters={filters}
          loading={loading}
          noDataText="No Requests"
          page={Number(meta.page) - 1}
          pages={Number(meta.pages)}
          pageSize={Number(meta.pageSize)}
          tableType={tableType}
          columns={[
            {
              Header: (
                <>
                  ID <i className="btn-sort" />
                </>
              ),

              id: 'shipment_reference_id',
              sortable: true,
              accessor: (d) => d.shipment,
              Cell: (row) =>
                row.value ? <Link to={`/shipments/${row.value.id}`}>{row.value.reference_id}</Link> : '--'
            },
            {
              Header: (
                <>
                  Sent To
                  <i className="btn-sort" />
                </>
              ),
              id: 'supplier_email_address',
              sortable: true,
              accessor: (d) => d.supplier_email_address
            },
            {
              Header: (
                <>
                  PO # <i className="btn-sort" />
                </>
              ),
              id: 'po_number',
              sortable: true,
              accessor: (d) => d.po_number,
              Cell: (row) => {
                return row.value ? row.value : '--';
              }
            },
            {
              Header: (
                <>
                  Invite Sent <i className="btn-sort" />
                </>
              ),
              id: 'created_at',
              sortable: true,
              accessor: (d) => d.created_at,
              Cell: (row) => formatDateTime(row.value, true, moment.tz.guess())
            },
            {
              Header: (
                <>
                  Shipment Created <i className="btn-sort" />
                </>
              ),
              id: 'shipment_created_at',
              sortable: true,
              accessor: (d) => d.shipment_created_at,
              Cell: (row) => (row.value ? formatDateTime(row.value, true, moment.tz.guess()) : '--')
            },
            {
              Header: (
                <>
                  Requester <i className="btn-sort" />
                </>
              ),
              id: 'sent_by_user_name',
              sortable: true,
              accessor: (d) => d.sent_by_user && `${d.sent_by_user.first_name} ${d.sent_by_user.last_name}`,
              Cell: (row) => row.value
            },
            {
              Header: (
                <>
                  Status <i className="btn-sort" />
                </>
              ),
              id: 'status',
              sortable: true,
              accessor: (d) => d.status,
              Cell: (row) => {
                const val = row.value || '--';
                return (
                  <span
                    className={classNames({
                      'text-success': val === 'BOOKED',
                      'text-cancelled': val === 'CANCELLED',
                      'text-danger': val === 'UNABLE_TO_BOOK',
                      'text-secondary': ['REQUESTED', 'PENDING_BOOKING'].includes(val)
                    })}
                  >
                    {startCase(toLower(val))}
                  </span>
                );
              }
            },
            {
              Header: '',
              id: 'actions',
              sortable: false,
              accessor: (d) => d.shipment,
              Cell: (row) => {
                if ((row.value && row.value.id) || row.original.revoked_at) {
                  return '';
                }
                //no shipment yet, can be revoked
                return (
                  <span>
                    <DeprecatedButton
                      variant="icon"
                      onClick={() => handleToggleRevokeModal(row.original)}
                      className="pad-left"
                    >
                      <SvgIcon name="TrashOutlined" title="Cancel Request" />
                    </DeprecatedButton>
                  </span>
                );
              }
            }
          ]}
        />

        <Modal
          show={showRequestModal}
          title="Request Shipment"
          footerComponent={null}
          bodyVariant="disableOverflowScroll"
          onClose={() => handleToggleRequestModal()}
        >
          <SupplierShipmentRequestForm
            onCancel={() => handleToggleRequestModal()}
            onSubmit={onFormSubmit}
            customers={customers}
            getCustomers={getCustomers}
          />
        </Modal>
        <Modal
          show={Boolean(shipmentSelectedForRevoke)}
          title="Confirm Revoke"
          primaryBtnName="Revoke"
          onClose={() => handleToggleRevokeModal()}
          onPrimaryAction={revokeRequest}
        >
          Are you sure you want to revoke this shipment request?
        </Modal>

        <Toast anchor="bottom-right" show={showError} variant="error" title="Error" onClose={() => setShowError(false)}>
          {showError}
        </Toast>

        <Toast
          anchor="bottom-right"
          show={showSuccess}
          variant="success"
          title="Success"
          onClose={() => setShowSuccess(false)}
        >
          Shipment request sent!
        </Toast>

        <Toast
          anchor="bottom-right"
          show={showRevokeSuccess}
          variant="success"
          title="Success"
          onClose={() => setShowRevokeSuccess(false)}
        >
          Shipment request revoked!
        </Toast>
      </div>
    </div>
  );
};

export default connect((state) => ({
  company: state.userCompany.company
}))(SupplierShipmentList);
