export const TokenDisplay = ({
  option,
  onRemove,
  simpleValue
}: {
  key: number;
  option: {label: string} | string;
  onRemove?: (() => void) | undefined;
  simpleValue?: boolean;
}) => {
  return (
    <div className="mb-[5px] mr-[5px] inline-block rounded bg-sw-primary p-[5px] text-[13px] text-sw-text-reverse">
      <span className="flex justify-between">
        <span>
          {simpleValue && typeof option === 'string' ? option : typeof option !== 'string' ? option.label : ''}
        </span>
        <button onClick={onRemove} className="ml-[10px] text-[.9em]" aria-label="remove-option">
          <i className="flaticon-multiply" />
        </button>
      </span>
    </div>
  );
};
