import {Field, useFormikContext} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {ShipmentBillingFormData} from 'App/containers/Shipment/forms/ShipmentBillingForm';
import {DATE_TERMS} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/constants';
import {DateTerm, FreightInvoiceFormData} from 'App/containers/settlements/freightInvoices/types';

const DateTermField = ({isDueDateField = false}: {isDueDateField?: boolean}) => {
  const {values, setValues} = useFormikContext<FreightInvoiceFormData | ShipmentBillingFormData>();
  return (
    <Field
      label={isDueDateField ? 'Due Date Term' : 'Approved Date Term'}
      name={isDueDateField ? 'due_date_term' : 'approved_date_term'}
      component={FormikSelect}
      options={DATE_TERMS.map((option) => {
        return {id: option.label, ...option};
      })}
      onChange={(val: DateTerm) => {
        if (val.date) {
          setValues(
            isDueDateField
              ? {...values, due_date_term: val, due_date: val.date}
              : {...values, approved_date_term: val, approved_date: val.date}
          );
        }
      }}
      clearable={false}
      required={false}
    />
  );
};
export default DateTermField;
