import {Component} from 'react';

class ListSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {selected: this.props.defaultValues || []};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValues !== this.props.defaultValues && this.props.defaultValues === null) {
      //we received a 'clear' signal from the parent
      this.setState({selected: []});
    } else if (prevProps.defaultValues !== this.props.defaultValues) {
      this.setState({selected: this.props.defaultValues});
    }
  }

  handleSelect(value) {
    const {allowMultiSelect} = this.props;
    let {selected} = this.state;
    if (!allowMultiSelect) {
      if (selected.length && selected.includes(value)) {
        selected = [];
      } else {
        selected = [value];
      }

      const filterObject = {};
      filterObject[this.props.fieldName] = selected;
      this.props.updateActiveFilters(filterObject);
      this.setState({selected});
      return;
    }
    if (selected.includes(value)) {
      selected.splice(selected.indexOf(value), 1);
    } else {
      selected.push(value);
    }
    const filterObject = {};
    filterObject[this.props.fieldName] = selected;
    this.props.updateActiveFilters(filterObject);
    this.setState({selected});
  }

  render() {
    const {options} = this.props;
    return (
      <div className="tableFilters__listSelect">
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            const isSelected = this.state.selected.includes(option.value);
            const selectedClass = isSelected ? 'selected' : '';
            return (
              <div
                className={`tableFilters__listSelect-option ${selectedClass}`}
                key={index}
                onClick={() => this.handleSelect(option.value)}
              >
                {option.icon ? <i className={option.icon} /> : ''} {option.label}
              </div>
            );
          })}
      </div>
    );
  }
}

export default ListSelect;
