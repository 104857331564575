import {useEffect} from 'react';
import type {RouteComponentProps} from 'react-router';
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryKey} from '@tanstack/react-query';
import type {TokenResponse} from '@shipwell/backend-core-sdk';
import {IMPERSONATION_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getAuthenticationToken} from 'App/api/user/typed';

function useImpersonation(
  impersonationToken: string | undefined,
  options: Omit<UseQueryOptions<TokenResponse>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    [IMPERSONATION_QUERY_KEY] as QueryKey,
    async () => {
      if (!impersonationToken) {
        return Promise.reject(new Error('Invalid impersonation token!'));
      }
      const response = await getAuthenticationToken(impersonationToken);
      return response.data;
    },
    {
      enabled: !!impersonationToken,
      ...options
    }
  );
}

function Impersonation({
  location,
  router
}: RouteComponentProps<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  {impersonation_token?: string}
>) {
  useEffect(() => {
    if (!localStorage.getItem('swlogout')) {
      localStorage.setItem('swlogout', `logout${Math.random()}`);
    }
  }, []);

  useEffect(() => {
    if (!location.query.impersonation_token) {
      router.push('/');
    }
  }, [location.query.impersonation_token, router]);

  useImpersonation(location.query.impersonation_token, {
    onSuccess(data) {
      const token = data?.token;
      if (token) {
        localStorage.setItem('swimpersonator', `Token ${token}`);
        localStorage.removeItem('swlogout');
      }
    },
    onSettled() {
      router.push('/');
    }
  });

  return (
    <div>
      <section className="flex justify-center">
        <img
          src="/images/shipping-containers.png"
          alt="Shipping Containers"
          className="block size-full max-w-xs px-8"
        />
      </section>
      <section className="mx-auto my-32">
        <h3 className="text-center">Logging you in…</h3>
      </section>
      <footer className="text-center">
        <small>© 2016-{new Date().getFullYear()} Shipwell®, All Rights Reserved.</small>
      </footer>
    </div>
  );
}

export default Impersonation;
