const plugin = require('tailwindcss/plugin');
const range = require('lodash/range');
const startCase = require('lodash/startCase');

const shipwellUIColors = {
  'well-water': '#006ba2',
  'sky-ship': '#11a2dc',
  black: '#26282b',
  'black-90': 'rgba(38, 40, 43, 0.9)',
  'black-20': 'rgba(38, 40, 43, 0.2)',
  'grey-5': '#58595b',
  'grey-4': '#838383',
  'grey-3': '#b9bab9',
  'grey-2': '#d8d8d8',
  'grey-1': '#f5f5f5',
  white: '#ffffff',
  'red-alert': '#b41d13',
  'red-alert-dark': '#87160f',
  'red-alert-light': '#cb5241',
  'red-alert-opacity': 'rgba(180, 29, 19, 0.1)',
  'red-alert-opacity-hex': '#F7E8E7',
  'saffron-submarine': '#df9100',
  'saffron-submarine-opacity': 'rgba(223, 145, 0, 0.1)',
  'saffron-submarine-opacity-hex': '#FCF4E6',
  'green-goblin': '#1b7b3b',
  'green-goblin-light': '#7ec7a8',
  'green-goblin-dark': '#14592b',
  'green-goblin-opacity': 'rgba(27, 123, 59, 0.1)',
  'green-goblin-opacity-hex': '#E8F2EB',
  'blue-man-crew': '#0a6fdb',
  'blue-man-crew-opacity': 'rgba(10, 111, 219, 0.1)',
  'blue-man-crew-opacity-hex': '#e6f0fb',
  'happy-clouds': '#e7f1fb',
  'marooned-on-an-island': '#870058',
  'peritwinkle-toes': '#7180ac',
  'in-the-navy': '#0b517c',
  'gradient-blue-blue': 'linear-gradient(108.78deg, #006ba2 12.69%, #11a2dc 87.31%)',
  'gradient-blue-grey': 'linear-gradient(90deg, #11a2dc 0%, #f5f5f5 100%)',
  'tropical-blue': '#cee2f8',
  'cornflower-blue': '#6ca9e9',
  'merman-blue': '#C2D2E2'
};

module.exports = {
  mode: 'jit',
  content: ['./src/app/**/*.{js,jsx,ts,tsx}', './src/www/**/*.html'],
  corePlugins: {},
  safelist: [
    {
      pattern: /^((bg|border)-.*)$/,
      variants: ['hover']
    }
  ],
  important: '#body', // all tailwind styles will be applied with !important if they are under the #body parent element, which should be the <body> HTML tag
  theme: {
    screens: {
      sm: {min: '480px'},
      md: {min: '768px'},
      lg: {min: '1024px'},
      xl: {min: '1200px'},
      '2xl': {min: '1536px'}
    },
    colors: {
      'sw-primary': shipwellUIColors['well-water'],
      'sw-secondary': shipwellUIColors['sky-ship'],
      'sw-active': shipwellUIColors['blue-man-crew'],
      'sw-active-light': shipwellUIColors['blue-man-crew-opacity'],
      'sw-active-light-hex': shipwellUIColors['blue-man-crew-opacity-hex'],
      'sw-link-light': shipwellUIColors['sky-ship'],
      'sw-hover-light': shipwellUIColors['merman-blue'],
      'sw-focused': shipwellUIColors['well-water'],
      'sw-disabled': shipwellUIColors['grey-3'],
      'sw-disabled-alternate': shipwellUIColors['grey-2'],
      'sw-text': shipwellUIColors['black'],
      'sw-text-reverse': shipwellUIColors['white'],
      'sw-text-reverse-alternate': shipwellUIColors['grey-1'],
      'sw-disabled-text': shipwellUIColors['grey-4'],
      'sw-text-section-title': shipwellUIColors['grey-5'],
      'sw-form-helper-text': shipwellUIColors['grey-4'],
      'sw-background': shipwellUIColors['grey-1'],
      'sw-background-component': shipwellUIColors['white'],
      'sw-background-header': shipwellUIColors['grey-5'],
      'sw-overlay-background': shipwellUIColors['happy-clouds'],
      'sw-form-field': shipwellUIColors['grey-5'],
      'sw-tag-default': shipwellUIColors['grey-5'],
      'sw-icon': shipwellUIColors['grey-5'],
      'sw-label': shipwellUIColors['grey-5'],
      'sw-icon-alternate': shipwellUIColors['grey-4'],
      'sw-icon-small': shipwellUIColors['grey-4'],
      'sw-selected': shipwellUIColors['grey-4'],
      'sw-divider': shipwellUIColors['grey-2'],
      'sw-border': shipwellUIColors['grey-2'],
      'sw-border-alternate': shipwellUIColors['grey-3'],
      'sw-border-light': shipwellUIColors['grey-5'],
      'sw-boxShadow': shipwellUIColors['black-20'],
      'sw-tooltip': shipwellUIColors['black-90'],
      'sw-success': shipwellUIColors['green-goblin'],
      'sw-success-light': shipwellUIColors['green-goblin-light'],
      'sw-success-dark': shipwellUIColors['green-goblin-dark'],
      'sw-error': shipwellUIColors['red-alert'],
      'sw-error-dark': shipwellUIColors['red-alert-dark'],
      'sw-error-light': shipwellUIColors['red-alert-light'],
      'sw-destroy': shipwellUIColors['red-alert'],
      'sw-warning': shipwellUIColors['saffron-submarine'],
      'sw-warning-opacity': shipwellUIColors['saffron-submarine-opacity'],
      'sw-info': shipwellUIColors['black'],
      'sw-success-background': shipwellUIColors['green-goblin-opacity'],
      'sw-success-background-hex': shipwellUIColors['green-goblin-opacity-hex'],
      'sw-warning-background': shipwellUIColors['saffron-submarine-opacity'],
      'sw-warning-background-hex': shipwellUIColors['saffron-submarine-opacity-hex'],
      'sw-error-background': shipwellUIColors['red-alert-opacity'],
      'sw-error-background-hex': shipwellUIColors['red-alert-opacity-hex'],
      'sw-very-high-price': shipwellUIColors['grey-1'],
      'sw-high-price': shipwellUIColors['tropical-blue'],
      'sw-medium-price': shipwellUIColors['cornflower-blue'],
      'sw-low-price': shipwellUIColors['blue-man-crew'],
      'sw-high-price-background': shipwellUIColors['red-alert-opacity'],
      'sw-low-price-background': shipwellUIColors['blue-man-crew-opacity'],
      transparent: 'transparent'
    },
    extend: {
      borderWidth: {
        1: '1px'
      },
      fontSize: {
        xxs: ['.625rem', '.75rem'] // [font-size, line-height]
      },
      gridTemplateColumns: {
        '1fr-24': '1fr 24rem'
      },
      width: {
        176: '44rem'
      },
      gridAutoRows: {
        '40-auto': 'minmax(40px, auto)',
        '60-auto': 'minmax(60px, auto)'
      },
      maxWidth: {
        xxs: '15rem',
        tag: '12rem'
      },
      backgroundImage: () => ({
        'external-background': "url('/images/sw_external_bg.png')",
        arrowhead: 'url(/images/arrowhead.svg)',
        line: 'url(/images/line.svg)'
      }),
      boxShadow: {
        'inner-bottom': 'inset 0 -1px 0 0 #d8d8d8'
      },
      keyframes: {
        'horizontal-bounce': {
          '0%': {transform: 'translateX(-100%)'},
          '50%': {transform: 'translateX(100%)'},
          '100%': {transform: 'translateX(-100%)'}
        }
      },
      animation: {
        'slow-spin': 'spin 8s linear infinite',
        'horizontal-bounce': 'horizontal-bounce 3s linear infinite'
      },
      height: {
        'screen-minus-header': 'calc(100vh - 64px)',
        'screen-minus-header-and-page-title': 'calc(100vh - 125px)'
      }
    }
  },
  plugins: [
    require('@tailwindcss/container-queries'),
    plugin(function heightMinus({addUtilities}) {
      const heightMinusUtils = range(16).map((num) => ({
        [`.h-full-minus-${num + 1}`]: {
          height: `calc(100% - ${(num + 1) / 4}rem)`
        }
      }));

      addUtilities(heightMinusUtils);
    }),
    plugin(function heightScreenMinus({addUtilities}) {
      const heightScreenMinusUtils = range(60).map((num) => ({
        [`.h-screen-minus-${num + 1}`]: {
          height: `calc(100vh - ${(num + 1) / 4}rem)`
        },
        [`.min-h-screen-minus-${num + 1}`]: {
          minHeight: `calc(100vh - ${(num + 1) / 4}rem)`
        }
      }));

      addUtilities(heightScreenMinusUtils);
    }),
    plugin(function autoHyphens({addUtilities}) {
      const autoHyphensUtil = {
        '.hyphens': {
          hyphens: 'auto'
        }
      };
      addUtilities(autoHyphensUtil);
    }),
    plugin(function viewPortSizes({addUtilities}) {
      const viewPortSizesUtils = range(5, 100, 5).map((num) => ({
        [`.h-screen-${num}`]: {
          height: `${num}vh`
        },
        [`.w-screen-${num}`]: {
          width: `${num}vw`
        }
      }));
      addUtilities(viewPortSizesUtils);
    }),
    plugin(function maxViewPortSizes({addUtilities}) {
      const viewPortSizesUtils = range(5, 100, 5).map((num) => ({
        [`.max-h-screen-${num}`]: {
          'max-height': `${num}vh`
        },
        [`.max-w-screen-${num}`]: {
          'max-width': `${num}vw`
        }
      }));
      addUtilities(viewPortSizesUtils);
    }),
    plugin(function gridAutoRows({addUtilities}) {
      const gridAutoRowsUtils = range(16).map((num) => ({
        [`.grid-auto-rows-${num + 1}`]: {
          'grid-auto-rows': `minmax(${(num + 1) / 4}rem, auto)`
        }
      }));
      addUtilities(gridAutoRowsUtils);
    }),
    plugin(function borders({addUtilities, theme}) {
      const bordersUtils = ['top', 'right', 'bottom', 'left'].map((borderPosition) => ({
        [`.sw-border-${borderPosition.substring(0, 1)}`]: {
          borderWidth: 0,
          [`border${startCase(borderPosition)}Width`]: '1px',
          borderStyle: 'solid',
          borderColor: theme('colors.sw-border')
        }
      }));
      addUtilities(bordersUtils);
    }),
    plugin(function hidden({addUtilities}) {
      const hiddenUtils = {
        '.tw-hidden': {
          display: 'none'
        }
      };
      addUtilities(hiddenUtils);
    })
  ]
};
