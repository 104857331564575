import {CompanySchema} from '@shipwell/shipment-assembly-sdk';
import {Link} from 'react-router';
import {Pill, SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import {
  LOAD_OPTIMIZATION_CONFIG_DELETE_ERROR_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_DELETE_SUCCESS_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE
} from 'App/containers/userCompany/defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigDetail/constants';
import {formatDateTimeTimestamp} from 'App/utils/dateTimeGlobalsTyped';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {archiveCompanySettings} from 'App/api/loadOptimization';
import {useUpdateCompanySettings} from 'App/api/loadOptimization/hooks/useUpdateCompanySettings';

interface ActionsCellProps extends CompanySchema, WithStatusToastProps {
  value: CompanySchema;
}
const ActionsCellBase = ({value, setSuccess, setError}: ActionsCellProps) => {
  const archiveCompanySettingsMutation = useMutation(
    (companyId: CompanySchema['id']) => archiveCompanySettings(companyId),
    {
      onSuccess: () => {
        setSuccess('Configuration Deleted!', LOAD_OPTIMIZATION_CONFIG_DELETE_SUCCESS_MESSAGE);
      },
      onError: () => setError(LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE, LOAD_OPTIMIZATION_CONFIG_DELETE_ERROR_MESSAGE)
    }
  );
  const {updateCompanySettingsMutation, invalidateConfigListQuery} = useUpdateCompanySettings({
    onSuccess: () => {
      setSuccess(LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE, LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE);
    },
    onError: () => setError(LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE, LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE)
  });
  const handleUpdateCompanySettings = () =>
    updateCompanySettingsMutation.mutate(
      {...value, is_default: !value.is_default},
      {
        onSuccess: () => void invalidateConfigListQuery()
      }
    );
  const handleArchiveCompanySettings = () =>
    archiveCompanySettingsMutation.mutate(value.id, {
      onSuccess: () => void invalidateConfigListQuery()
    });

  return (
    <Dropdown
      variant="icon"
      indicator={false}
      drop="left"
      disabled={value.is_default}
      icon={<SvgIcon name="Overflow" />}
    >
      {({onClick}: {onClick: () => void}) => {
        return (
          <>
            <li
              onClick={() => {
                onClick();
                handleUpdateCompanySettings();
              }}
            >
              Set As Default
            </li>
            <li
              onClick={() => {
                onClick();
                handleArchiveCompanySettings();
              }}
            >
              Delete
            </li>
          </>
        );
      }}
    </Dropdown>
  );
};
const ActionsCell = WithStatusToasts(ActionsCellBase);
const columnData = [
  {
    id: 'setting_name',
    accessor: (row: CompanySchema) => (
      <div className="flex max-w-full flex-row items-center gap-1">
        <div className="max-w-full truncate">
          <Link to={`/company/defaults/load-optimization-configurations/${row.id || ''}`}>{row.setting_name}</Link>
        </div>
        {row.is_default ? <Pill variant="default">DEFAULT</Pill> : null}
      </div>
    ),
    Header: 'Configuration Name',
    minWidth: 175
  },
  {
    id: 'created_at',
    accessor: (row: CompanySchema) => formatDateTimeTimestamp(row.created_at) || '--',
    Header: 'Created Date',
    minWidth: 175
  },
  {
    id: 'updated_at',
    accessor: (row: CompanySchema) => formatDateTimeTimestamp(row.updated_at) || '--',
    Header: 'Last Edit',
    minWidth: 175
  },
  {
    id: 'actions',
    accessor: (row: CompanySchema) => row,
    Cell: ActionsCell,
    Header: 'Actions',
    minWidth: 175
  }
];

export default columnData;
