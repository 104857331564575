import {useState, useEffect} from 'react';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import usePrevious from 'App/utils/hooks/usePrevious';

/*
 * Fetch carrier details for the given ids and make them available in an object keyed by those ids.
 * This is useful when you have a list of carrier relationship ids in an API response object but you
 * want to display other details about the carrier, like name.
 */
function useCarrierDetails(ids) {
  const previousIds = usePrevious(ids);
  const [carrierDetails, setCarrierDetails] = useState({});

  useEffect(() => {
    if (isEmpty(ids) || isEqual(ids, previousIds)) {
      return;
    }
    const fetchCarrierDetails = async () => {
      try {
        const response = await getCarrierRelationshipsPromise({id: ids, pageSize: 100});
        setCarrierDetails(keyBy(response.body.results, 'id'));
      } catch (error) {
        console.error('Error retrieving carrier details', error);
      }
    };
    fetchCarrierDetails();
  }, [ids, previousIds]);

  return carrierDetails;
}

export default useCarrierDetails;
