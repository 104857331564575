import {useMutation} from '@tanstack/react-query';
import set from 'lodash/set';
import {StopExecutionStatus, DrayageBooking} from '@shipwell/corrogo-sdk';
import {DisplayValue, Card, SvgIcon, DeprecatedButton, Modal, Pill} from '@shipwell/shipwell-ui';
import useToggle from 'App/utils/hooks/useToggle';
import DrayageLegStopsForm from 'App/containers/shipments/components/StopsFields/DrayageLegStopsFields';
import {useDrayageLeg} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import ContactDisplay from 'App/containers/shipments/components/StopsView/ContactDisplay';
import {setStopExecutionStatus} from 'App/api/corrogo/typed';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {StopTypeId} from 'App/containers/shipments/components/StopsFields/constants';
import {PickupStopType} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';

const DrayageLegDetailsStopsView = ({legId}: {legId: string}) => {
  const {
    context: {stopsDisplayValues}
  } = useDrayageLeg(legId);

  return (
    <div className="p-4">
      {stopsDisplayValues?.map((stopDisplayValues) => (
        <DrayageLegStopCardWrapper
          key={stopDisplayValues.sequenceNumber}
          legId={legId}
          sequenceNumber={stopDisplayValues.sequenceNumber}
        />
      ))}
    </div>
  );
};

export default DrayageLegDetailsStopsView;

const DrayageLegStopCardWrapper = ({legId, sequenceNumber}: {legId: string; sequenceNumber: number}) => {
  const [showDrayageLegStopModal, toggleDrayageLegStopModal] = useToggle();
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = drayageLegQuery;
  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  return (
    <>
      <DrayageLegStopCard legId={legId} sequenceNumber={sequenceNumber} onEdit={toggleDrayageLegStopModal} />
      {showDrayageLegStopModal ? (
        <>
          <Modal
            show={showDrayageLegStopModal}
            title={`Edit ${stop.title} Stop`}
            onClose={toggleDrayageLegStopModal}
            footerComponent={null}
            bodyVariant="disableOverflowScroll"
          >
            <DrayageLegStopsForm legId={legId} onClose={toggleDrayageLegStopModal} sequenceNumber={sequenceNumber} />
          </Modal>
        </>
      ) : null}
    </>
  );
};

function StopAction({legId, sequenceNumber}: {legId: string; sequenceNumber: number}) {
  const drayageLegQuery = useDrayageLeg(legId);
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DrayageBooking, unknown, {status: StopExecutionStatus}>();
  const updateStopStatusMutation = useMutation<
    Awaited<ReturnType<typeof setStopExecutionStatus>>,
    unknown,
    {status: StopExecutionStatus}
  >(
    ({status}) => setStopExecutionStatus(legId, sequenceNumber, status),
    createOptimisticUpdateHandlers([DRAYAGE_QUERY_KEY, legId], {
      mergeData: (drayageLeg, {status} = {status: StopExecutionStatus.Pending}) => {
        if (!drayageLeg) {
          return;
        }

        return set(drayageLeg, `stops[${sequenceNumber - 1}].execution_status`, status);
      }
    })
  );

  if (drayageLegQuery.isInitialLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  const {getStopStatusAction} = drayageLegQuery.context;
  const stopsDetails = drayageLegQuery.data?.stops;

  const stop = stopsDetails?.find((stopDetails) => stopDetails.sequence_number === sequenceNumber);

  if (!stop) {
    return null;
  }

  const legOverallStatus = drayageLegQuery.data?.overall_status;
  const stopStatusAction = getStopStatusAction(sequenceNumber);

  if (!stopStatusAction || !legOverallStatus) {
    return null;
  }

  return (
    <DeprecatedButton
      variant="secondary"
      onClick={() => updateStopStatusMutation.mutate({status: stopStatusAction.nextStatus})}
      loading={updateStopStatusMutation.isLoading}
    >
      {stopStatusAction.label}
    </DeprecatedButton>
  );
}

function StopStatus({legId, sequenceNumber}: {legId: string; sequenceNumber: number}) {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  const {context} = drayageLegQuery;
  const {stopsDisplayValues} = context;
  const stopsDetails = drayageLegQuery.data?.stops;

  const stop = stopsDetails?.find((stopDetails) => stopDetails.sequence_number === sequenceNumber);
  const stopDisplay = stopsDisplayValues?.find(
    (stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber
  );

  if (!stopDisplay || !stop || stop.execution_status !== StopExecutionStatus.Departed) {
    return null;
  }

  return (
    <Pill variant="default" UNSAFEclassName="uppercase">
      {stopDisplay.executionStatus}
    </Pill>
  );
}

export const DrayageLegStopCard = ({
  legId,
  sequenceNumber,
  onEdit
}: {
  legId: string;
  sequenceNumber: number;
  onEdit: () => void;
}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = drayageLegQuery;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  const {title, companyName} = stop;

  return (
    <Card
      draggableProvided={null}
      isCollapsible
      className="mb-4"
      title={
        <div className="mr-2 flex w-full flex-row items-center justify-between gap-2 text-center">
          <div className="flex flex-row items-center gap-2 text-center">
            <SvgIcon name={`Num${sequenceNumber}Outlined`} color="sw-icon" className="mr-2" />
            <DisplayValue label={title} className="m-0 items-start">
              <div>{companyName}</div>
            </DisplayValue>
            <StopStatus legId={legId} sequenceNumber={sequenceNumber} />
          </div>
          <div className="flex flex-row items-center gap-2 text-center">
            <StopAction legId={legId} sequenceNumber={sequenceNumber} />
          </div>
        </div>
      }
      actions={
        <DeprecatedButton variant="icon" onClick={onEdit}>
          <SvgIcon name="Edit" color="$sw-icon" />
        </DeprecatedButton>
      }
    >
      <DrayageLegStopCardBody legId={legId} sequenceNumber={sequenceNumber} />
    </Card>
  );
};

export const DrayageLegStopCardBody = ({
  legId,
  expanded,
  sequenceNumber
}: {
  legId: string;
  sequenceNumber: number;
  expanded?: boolean;
}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {context} = drayageLegQuery;
  const {stopsDisplayValues} = context;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  const {addressSingleLine, contacts} = stop;

  return (
    <>
      <DisplayValue label="Address">
        <div>{addressSingleLine}</div>
      </DisplayValue>
      {expanded ? (
        <>
          <div className="mt-4 border-y border-sw-border pt-4">
            {!!contacts && contacts?.length > 0 ? (
              contacts.map((contact, index) => <ContactDisplay key={contact.id} contact={contact} index={index} />)
            ) : (
              <ContactDisplay index={0} />
            )}
          </div>
          <StopCardPickupReferences legId={legId} sequenceNumber={sequenceNumber} />
          <StopCardInstructions legId={legId} sequenceNumber={sequenceNumber} />
        </>
      ) : null}
    </>
  );
};

export const StopCardPickupReferences = ({legId, sequenceNumber}: {legId: string; sequenceNumber: number}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {context} = drayageLegQuery;
  const {stopsDisplayValues, getStopType} = context;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  const pickupNumber = stop?.pickupQualifier?.value || '--';
  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;

  return (
    <>
      {stopType.id === StopTypeId.Pickup ? (
        <div className="border-b border-sw-border py-4">
          <DisplayValue label="Pickup #">{pickupNumber}</DisplayValue>
        </div>
      ) : null}
    </>
  );
};

export const StopCardInstructions = ({legId, sequenceNumber}: {legId: string; sequenceNumber: number}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {context} = drayageLegQuery;
  const {stopsDisplayValues, legInfoDisplayValues, getStopType} = context;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);
  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;

  if (!stop) {
    return null;
  }

  const {
    instructions,
    formattedAppointmentTime,
    formattedPickupDate,
    formattedActualArrivalTime,
    formattedCompletedAtTime
  } = stop;
  const isContainerPickupStop = stopType.id === StopTypeId.ContainerPickup;
  const isContainerReturnStop = stopType.id === StopTypeId.ContainerReturn;
  const isPickupDeliveryStop = stopType.id === StopTypeId.Pickup || stopType.id === StopTypeId.Delivery;
  const inGate = legInfoDisplayValues?.formattedInGate;
  const outGate = legInfoDisplayValues?.formattedOutGate;

  return (
    <>
      <DisplayValue label="Instructions" className="pt-4">
        {instructions}
      </DisplayValue>
      {isPickupDeliveryStop ? (
        <div className="m-4 bg-sw-background p-4">
          <DisplayValue label="Appointment Time">{formattedAppointmentTime}</DisplayValue>
          <div className="grid grid-cols-3">
            <DisplayValue label={`${stopType.id === StopTypeId.Pickup ? 'Pickup' : 'Delivery'} Date`}>
              {formattedPickupDate}
            </DisplayValue>
            <DisplayValue label="Actual Arrival">{formattedActualArrivalTime}</DisplayValue>
            <DisplayValue label="Completed At">{formattedCompletedAtTime}</DisplayValue>
          </div>
        </div>
      ) : null}
      {isContainerPickupStop ? (
        <div className="m-4 bg-sw-background p-4">
          <DisplayValue label="Out-Gate">{outGate}</DisplayValue>
        </div>
      ) : null}
      {isContainerReturnStop ? (
        <div className="m-4 bg-sw-background p-4">
          <DisplayValue label="In-Gate">{inGate}</DisplayValue>
        </div>
      ) : null}
    </>
  );
};
