import {useEffect, useMemo, useState} from 'react';
import pluralize from 'pluralize';
import capitalize from 'lodash/capitalize';
import {createColumnHelper, flexRender, Row, Table as TanstackTableType} from '@tanstack/react-table';
import {CapacitySearchResponseMultiple, LoadboardType} from '@shipwell/tabula-sdk';
import {Checkbox, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {useGetCapacitySearchCarrierDetails, useLoadboardCapacitySearch, useRMISStatus} from 'App/data-hooks';
import {
  TableContainer,
  Table,
  TableHeaderRow,
  TableHeader,
  TableHeaderSortIcon,
  TableRow,
  TableCell,
  TableFooter
} from 'App/components/TypedTable/baseComponents';
import {useTablePagination, useTableSort, useTypedTable} from 'App/components/TypedTable/hooks';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/components/TypedTable/tableUtils';
import ShipwellLoader from 'App/common/shipwellLoader';
import {formatDateMonthDayYear} from 'App/utils/dateTimeGlobalsTyped';
import {CAPACITY_SEARCH_TABLE_KEY} from 'App/utils/tableTypeKeys';
import {CapacitySearchFormValues, DEFAULT_SOURCE} from 'App/containers/Shipment/ShipmentSourceCapacity';

const columnHelper = createColumnHelper<CapacitySearchResponseMultiple>();

const columns = [
  {
    id: 'select',
    size: 50,
    minSize: 50,
    header: ({table}: {table: TanstackTableType<CapacitySearchResponseMultiple>}) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        disabled={table.getRowModel().flatRows.every((row) => row.original.loadboard_name !== LoadboardType.Internal)}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
      />
    ),
    cell: ({row}: {row: Row<CapacitySearchResponseMultiple>}) => (
      <div className="px-1">
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
    )
  },
  columnHelper.accessor('contact.company_name', {
    header: 'Carrier',
    size: 200,
    minSize: 200,
    cell: (props) => {
      const {original} = props.row;

      if (original?.contact?.person_name || original?.contact?.email || original?.contact?.phone) {
        return (
          <Tooltip
            arrow
            placement="right"
            portal
            tooltipContent={<CarrierDetails carrier={original} />}
            tooltipClassname="max-w-xs"
          >
            <CarrierColumn carrier={original} />
          </Tooltip>
        );
      }

      return <CarrierColumn carrier={original} />;
    }
  }),
  columnHelper.accessor('rate', {
    header: 'All In Rate',
    size: 120,
    minSize: 120,
    cell: (props) => {
      const {original} = props.row;
      if (original.rate?.value && original.rate?.currency_code) {
        return <span> {`${original.rate?.value} ${original.rate?.currency_code}`}</span>;
      }
      return <span>--</span>;
    }
  }),
  columnHelper.accessor('available_at', {
    header: 'Available',
    size: 110,
    minSize: 110,
    cell: (props) => {
      const {original} = props.row;
      if (original.available_at) {
        return <span>{formatDateMonthDayYear(original.available_at)}</span>;
      }
      return <span>---</span>;
    }
  }),
  columnHelper.accessor('origin', {
    header: 'Origin',
    size: 170,
    minSize: 170,
    cell: (props) => {
      const {original} = props.row;
      const address = [original?.origin?.locality, original?.origin?.region, original?.origin?.country]
        .filter((item) => item)
        .join(', ');
      return <span>{address || '--'}</span>;
    }
  }),
  columnHelper.accessor('destination', {
    header: 'Destination',
    size: 180,
    minSize: 180,
    cell: (props) => {
      const {original} = props.row;
      const address = [original?.destination?.locality, original?.destination?.region, original?.destination?.country]
        .filter((item) => item)
        .join(', ');
      return <span>{address || '--'}</span>;
    }
  }),
  columnHelper.display({
    header: 'Network',
    size: 120,
    minSize: 120,
    cell: (props) => {
      const {original} = props.row;
      return <span>{original?.in_network ? 'In Network' : 'Out of Network'}</span>;
    }
  }),
  columnHelper.display({
    header: 'Source',
    size: 100,
    minSize: 100,
    cell: (props) => {
      const {original} = props.row;
      return <span>{original.loadboard_name || '--'}</span>;
    }
  }),
  columnHelper.accessor('age_minutes', {
    header: 'Age',
    size: 80,
    minSize: 80,
    cell: (props) => {
      const {original} = props.row;

      return <span>{pluralize('Min', original.age_minutes, true)}</span>;
    }
  }),
  columnHelper.accessor('origin_distance', {
    header: 'DH-O',
    size: 90,
    minSize: 90,
    cell: (props) => {
      const {original} = props.row;

      if (original.origin_distance && original.origin_distance_units) {
        return (
          <span>{pluralize(capitalize(original.origin_distance_units), Number(original.origin_distance), true)}</span>
        );
      }

      return <span>--</span>;
    }
  })
];

const CarrierColumn = ({carrier}: {carrier: CapacitySearchResponseMultiple}) => {
  const {isRMISIntegrated, isRMISStatusLoading} = useRMISStatus();

  if (isRMISStatusLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  return (
    <div className="flex flex-col">
      {carrier.contact?.company_name ? (
        carrier.carrier_id ? (
          <a href={`/carriers/${carrier.carrier_id}`} target="_blank" rel="noreferrer">
            {carrier.contact.company_name}
          </a>
        ) : isRMISIntegrated ? (
          <a href="/company/integrations/rmis" target="_blank" rel="noreferrer">
            {carrier.contact.company_name}
          </a>
        ) : (
          <span>{carrier.contact.company_name}</span>
        )
      ) : null}
      {carrier.contact?.phone ? getPhoneHyperlink(carrier.contact.phone) : null}
      {carrier.contact?.email ? (
        <a href={`mailto:${carrier.contact.email}`} className="text-xxs">
          {carrier.contact.email}
        </a>
      ) : null}
    </div>
  );
};

const CarrierDetails = ({carrier}: {carrier: CapacitySearchResponseMultiple}) => {
  const {data: carrierDetails, isInitialLoading: isCarrierDetailsLoading} = useGetCapacitySearchCarrierDetails(
    carrier?.loadboard_search_request_id
  );

  if (isCarrierDetailsLoading) {
    <ShipwellLoader loading />;
  }

  return (
    <div>
      {carrier?.contact?.person_name ? <p className="mb-0">{carrier?.contact?.person_name}</p> : null}
      <p>P: {carrier?.contact?.phone ? getPhoneHyperlink(carrier.contact.phone) : '--'}</p>
      <p className="mb-3">E: {carrier?.contact?.email || '--'}</p>
      <p>MC# {carrierDetails?.mc_number || '--'}</p>
      <p className="mb-3">USDOT# {carrierDetails?.usdot_number || '--'}</p>
      {carrierDetails?.comments ? (
        <>
          <div className="mb-1">Comments:</div>
          <p>{carrierDetails.comments}</p>
        </>
      ) : null}
    </div>
  );
};
const ShipmentSourceCapacityTable = ({
  queryId,
  setLastRunAgo,
  shipmentId,
  isFetching,
  setSelectedCarriers,
  filters
}: {
  queryId?: string;
  setLastRunAgo: (x: number) => void;
  shipmentId: string;
  isFetching: boolean;
  setSelectedCarriers: (carriers: CapacitySearchResponseMultiple[]) => void;
  filters?: CapacitySearchFormValues;
}) => {
  const [pagination, setPagination] = useTablePagination({
    tableType: CAPACITY_SEARCH_TABLE_KEY,
    defaultPageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0]
  });
  //TODO add sorting by default
  const [sorting, setSorting] = useTableSort();
  const params = {
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize
  };

  const getFiltersPayload = (selectedFilters?: CapacitySearchFormValues) => {
    return {
      inNetwork: selectedFilters?.status_in_network,
      outNetwork: selectedFilters?.status_out_network,
      sources: selectedFilters?.source_dat ? [...DEFAULT_SOURCE, LoadboardType.Dat] : DEFAULT_SOURCE,
      originDistance: Number(selectedFilters?.origin_range_distance),
      destinationDistance: Number(selectedFilters?.destination_range_distance),
      equipmentType: selectedFilters?.equipment?.map((eq) => eq.equipment_type),
      age: selectedFilters?.age_limit_minutes,
      earliest: selectedFilters?.earliest ? new Date(selectedFilters.earliest).toISOString() : new Date().toISOString(),
      latest: selectedFilters?.latest ? new Date(selectedFilters.latest).toISOString() : new Date().toISOString()
    };
  };
  const {data, isInitialLoading, dataUpdatedAt} = useLoadboardCapacitySearch(
    queryId || '',
    shipmentId,
    params,
    getFiltersPayload(filters)
  );

  const memoizedData = useMemo(() => {
    //For MVP  displaying only carriers that are status of “Active” if they are In Network.
    const filteredResults = data?.data?.results.filter(
      (carrier) => !carrier?.in_network || (carrier?.in_network && carrier?.status === 'ACTIVE')
    );
    return filteredResults || [];
  }, [data]);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    if (memoizedData.length) {
      setLastRunAgo(dataUpdatedAt);
    }
  }, [dataUpdatedAt, memoizedData, setLastRunAgo]);

  const table = useTypedTable({
    data: memoizedData,
    columns,
    pageCount: data?.total_pages || 1,
    state: {
      sorting,
      pagination,
      rowSelection
    },
    enableRowSelection: (row) => row.original?.loadboard_name !== LoadboardType.Dat,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination
  });

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedCarriers(table.getSelectedRowModel().flatRows.map((row) => row.original));
    } else {
      setSelectedCarriers([]);
    }
  }, [table, rowSelection, setSelectedCarriers]);

  return (
    <div className="h-full">
      <TableContainer>
        <TableContainer>
          <Table
            isFixedTableLayout
            head={table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    width={header.getSize()}
                    sortDirection={header.column.getIsSorted()}
                    onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                    onResize={header.getResizeHandler()}
                  >
                    <div className="flex items-center gap-1">
                      {header.column.getCanSort() ? (
                        <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                      ) : null}
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
            body={table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          />
          {isInitialLoading ? <ShipwellLoader loading /> : null}
          {!memoizedData.length && !isInitialLoading ? (
            <div className="flex h-full items-center justify-center p-8 text-center font-bold">
              <span className="text-lg font-bold text-sw-disabled-text">
                {isFetching ? 'Searching...' : 'Run a search to view results'}
              </span>
            </div>
          ) : null}
        </TableContainer>
        <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS.slice(0, 3)} />
      </TableContainer>
    </div>
  );
};

export default ShipmentSourceCapacityTable;
