import {Quote} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Rate} from '@shipwell/genesis-sdk';
import _ from 'lodash';

export const combineQuotesWithParcelRates = ({
  parcelRates,
  quotes
}: {
  quotes: Record<string, Quote[]>;
  parcelRates: Record<string, Rate[]>;
}): Record<string, (Quote | Rate)[]> => {
  const combined = _.mergeWith({}, quotes, parcelRates, (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue) as (Quote | Rate)[];
    }
  });

  return combined;
};
