import withConditionalFallback from 'App/components/withConditionalFallback';

/**
 * A higher-order component that renders the wrapped component if the `expanded` prop is true and
 * the CollapsedFallback component if it is false.
 *
 * @param {ReactElement} CollapsedFallback The component to render if the `expanded` prop is false
 *
 * @return {Function} A higher-order component.
 */
export const withCollapsedContent = (CollapsedFallback) =>
  withConditionalFallback(({expanded}) => !expanded, CollapsedFallback);

// By default, we want to export an easier-to-use HOC that renders nothing when collapsed
export default withCollapsedContent();
