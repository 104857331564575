import Dropzone from 'react-dropzone';
import {FormGroup, ControlLabel, Image} from 'react-bootstrap';

const renderDropZone = ({input, label, id, files, meta: {touched, error}, ...rest}) => (
  <FormGroup>
    <Dropzone
      {...input}
      multiple={false}
      id={id}
      name="file"
      style={{}}
      accept={[
        'text/plain',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.formula',
        'application/pdf',
        'image/jpeg',
        'image/svg+xml',
        'image/png',
        'image/tiff',
        'image/gif',
        'image/bmp'
      ]}
      onDrop={(files) => {
        input.onChange(files);
      }}
      {...rest}
    >
      {!input.value.length ? (
        <span>
          <div className="doc-drop">
            {label && (
              <ControlLabel className="sr-only">
                {label}
                <span className="requiredField">*</span>
              </ControlLabel>
            )}
            <p>
              <i className="flaticon-upload" />
              &nbsp;&nbsp;
              <b>
                Drop files here to upload or <span className="choose-file">choose a file</span>
              </b>
            </p>
          </div>
        </span>
      ) : (
        <span>
          {input.value[0].type !== 'application/pdf' ? (
            <Image src={input.value[0].preview} className="img-responsive" height="100%" width="100%" alt="Photo" />
          ) : (
            <object
              aria-label="PDF preview"
              type="application/pdf"
              data={input.value[0].preview}
              width="100%"
              height="100%"
            />
          )}
        </span>
      )}
    </Dropzone>
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderDropZone;
