import {FreightInvoiceDocument} from '@shipwell/settlements-sdk';

/**
 * Sorts document to put invoices first, then everything else in alphabetical order by document type.
 */
export const sortDisplayDocuments = (
  displayDocuments: FreightInvoiceDocument[] | undefined,
  documentTypes: Record<string, string> | undefined
): FreightInvoiceDocument[] | undefined => {
  if (!displayDocuments || displayDocuments.length === 0) return undefined;

  const {invoices, nonInvoices} = displayDocuments.reduce<{
    invoices: FreightInvoiceDocument[];
    nonInvoices: FreightInvoiceDocument[];
  }>(
    (result, currDoc) => {
      if (currDoc.document_type === 'INVOICE') result.invoices.push(currDoc);
      else result.nonInvoices.push(currDoc);
      return result;
    },
    {invoices: [], nonInvoices: []}
  );

  const sortByDocumentTypeName = (a: FreightInvoiceDocument, b: FreightInvoiceDocument) => {
    const aTypeName = a.document_type ? documentTypes?.[a.document_type] : null;
    const bTypeName = b.document_type ? documentTypes?.[b.document_type] : null;
    return aTypeName && bTypeName ? aTypeName.localeCompare(bTypeName) : 0;
  };

  return [...invoices, ...nonInvoices.sort(sortByDocumentTypeName)];
};
