import {isNull, isUndefined} from 'lodash';
import {
  Quote,
  SpotNegotiationsApi,
  Configuration,
  LoadboardApi,
  QuotingApi,
  QuotingApiQuotingRfqsRfqIdGetRequest,
  QuotingApiQuotingRfqsPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getAccessToken} from 'App/api/utils';
import {basePath} from 'App/api/config';
import {Rate} from 'src/@types/quotingTypes';

export enum SourceTypeEnum {
  Instant = 'INSTANT',
  CarrierConnection = 'CARRIER_CONNECTION',
  PrivateMarketplace = 'PRIVATE_MARKETPLACE'
}

/**
 * genesis. This is a middle carry over and should be deprecated once genesis becomes
 * the source of truth for integrations and quoting.
 */
export async function createCarrierConnectionQuote(rate: Partial<Rate>): Promise<Quote> {
  if (isUndefined(rate.legacyRfqId) || isNull(rate.legacyRfqId)) {
    throw Error('Cannot create carrier connection without legacy RFQ id');
  }
  const SHIPWELL_BASE_URL: string | undefined = process.env.SHIPWELL_API_BASE_PATH;
  if (isUndefined(SHIPWELL_BASE_URL)) {
    throw Error('Cannot call shipwell api without base url');
  }
  const token: string = (await getAccessToken()) as string;
  const json: string = JSON.stringify({
    rate_request_id: rate.rateRequestId,
    id: rate.rateId
  });
  const resp = await fetch(`${SHIPWELL_BASE_URL}/quoting/rfqs/${rate.legacyRfqId}/carrier-connection-rates/`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: json
  });

  const quote: Quote = await resp.json();
  return quote;
}

const configuration = new Configuration({
  basePath,
  apiKey: getAccessToken
});

/**
 * Spot Negotiations
 */
const spotNegotiationsApi = new SpotNegotiationsApi(configuration);
const loadboardApi = new LoadboardApi(configuration);

export const createSpotNegotiationFromLoadboard = (
  opts: Parameters<typeof loadboardApi.quotingLoadboardLoadBoardShipmentIdSpotNegotiationsPost>[0]
) => loadboardApi.quotingLoadboardLoadBoardShipmentIdSpotNegotiationsPost(opts);

/**
 * Spot Negotiations-Quote
 */
export const createSpotNegotiationQuote = (
  opts: Parameters<typeof spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesPost>[0]
) => spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesPost(opts);

export const createSpotNegotiationQuoteDocument = (
  opts: Parameters<
    typeof spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDocumentsPost
  >[0]
) =>
  spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDocumentsPost(
    opts
  );

export const getSpotNegotiationQuoteDocument = (
  opts: Parameters<
    typeof spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDocumentsSpotNegotiationQuoteDocumentIdGet
  >[0]
) =>
  spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDocumentsSpotNegotiationQuoteDocumentIdGet(
    opts
  );

export const deleteSpotNegotiationQuote = (
  opts: Parameters<
    typeof spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDelete
  >[0]
) =>
  spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDelete(opts);

export const getSpotNegotiationsEmailPreview = (
  opts: Parameters<typeof spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPreviewGet>[0]
) => spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPreviewGet(opts);

export const createPrivateMarketplaceSpotNegotiations = (
  opts: Parameters<typeof spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPost>[0]
) => spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPost(opts);

export const getShipmentSpotNegotiations = (
  opts: Parameters<typeof spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsGet>[0]
) => spotNegotiationsApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsGet(opts);

export const awardDeclineQuote = (
  opts: Parameters<
    typeof spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdAwardDeclinePut
  >[0]
) =>
  spotNegotiationsApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdAwardDeclinePut(
    opts
  );

// Quoting API

const quotingApi = new QuotingApi(configuration);

export const getRfqDetails = (opts: QuotingApiQuotingRfqsRfqIdGetRequest) => quotingApi.quotingRfqsRfqIdGet(opts);

export const createRfq = (opts: QuotingApiQuotingRfqsPostRequest) => quotingApi.quotingRfqsPost(opts);
