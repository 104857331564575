import {validateDollarValue, validateNumber, countDecimals} from 'App/utils/globals';

const validate = (values, props) => {
  const requiredCustomFields = props.shipmentChargeLineItemCustomFields.filter((customField) => customField.required);
  const errors = {};
  if (values.financials && values.financials.length > 0) {
    //loop through financial line items;
    errors.financials = [];
    for (var i = 0; i < values.financials.length; i++) {
      errors.financials[i] = {};
      if (!values.financials[i].category) {
        errors.financials[i].category = 'Charge Item is required.';
      }
      if (!values.financials[i].unit_name) {
        errors.financials[i].unit_name = 'Description is required.';
      }
      if (!values.financials[i].unit_amount && values.financials[i].unit_amount !== 0) {
        errors.financials[i].unit_amount = 'Rate is required.';
      } else if (values.financials[i].unit_amount) {
        if (!validateDollarValue(values.financials[i].unit_amount)) {
          errors.financials[i].unit_amount = 'Rate must be a valid dollar amount.';
        } else if (countDecimals(Number(values.financials[i].unit_amount)) > 4) {
          errors.financials[i].unit_amount = 'A maximum of 4 decimal places is allowed.';
        }
      }
      if (values.financials[i].prepaid_amount) {
        if (!validateDollarValue(values.financials[i].prepaid_amount)) {
          errors.financials[i].prepaid_amount = 'Prepaid amount must be a valid dollar amount.';
        }
      }
      if (values.financials[i].prepaid_amount && parseInt(values.financials[i].prepaid_amount) < 0) {
        errors.financials[i].prepaid_amount = 'Prepaid amount must be a positive number.';
      }
      if (!values.financials[i].unit_quantity) {
        errors.financials[i].unit_quantity = 'Quantity is required.';
      } else if (values.financials[i].unit_quantity) {
        if (!validateNumber(values.financials[i].unit_quantity)) {
          errors.financials[i].unit_quantity = 'Quanity must be a number.';
        } else if (Number(values.financials[i].unit_quantity) < 0) {
          errors.financials[i].unit_quantity = 'Quantity must be greater than 0.';
        } else if (countDecimals(Number(values.financials[i].unit_quantity)) > 3) {
          errors.financials[i].unit_quantity = 'A maximum of 3 decimal places is allowed.';
        }
      }

      if (requiredCustomFields.length > 0) {
        errors.financials[i].custom_data = {};
        for (const requiredField of requiredCustomFields) {
          if (!values.financials[i].custom_data?.[requiredField.name]) {
            errors.financials[i].custom_data[name] = `Required field`;
          }
        }
      }
    }
  }
  return errors;
};

export default validate;
