import {FETCH_ANALYTICS, FETCH_KPIS, LANE_ERROR} from '../actions/types';

const INITIAL_STATE = {
  kpi: [],
  kpiDash: [],
  error: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ANALYTICS:
      return {
        ...state,
        kpi: action.payload
      };

    case FETCH_KPIS:
      return {
        ...state,
        kpiDash: action.payload
      };

    case LANE_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
