import {useState, createContext, useEffect, useContext} from 'react';
import {wrapDisplayName} from 'recompose';
import {getPageTitle} from 'App/utils/getPageTitle';

/**
 * The usePageTitle hook is used to keep track of the current page title and also provides the constructPageTitle method to update the page title
 * @param {*} location
 * @param {*} params
 * @param {*} identifier
 */
const usePageTitle = (location, params, identifier) => {
  const [title, setTitle] = useState(localStorage.getItem('whiteLabelTitle'));

  useEffect(() => {
    if (location) {
      constructPageTitle(location, params, identifier);
    }
  }, [location, params, identifier]);

  const constructPageTitle = (location, params, identifier) => {
    const newTitle = getPageTitle(location, params, identifier);
    setTitle(newTitle);
  };

  return [title, constructPageTitle];
};

export default usePageTitle;

const TitleContext = createContext({title: null, constructPageTitle: () => {}});
/**
 * TitleContextProvider provides the methods for automatically updating the title when the location, params, or identifier change
 */
const TitleContextProvider = ({location, params, identifier, ...props}) => {
  const [title, constructPageTitle] = usePageTitle(location, params, identifier);
  return <TitleContext.Provider value={{title, constructPageTitle}} {...props} />;
};
/**
 * TitleContextConsumer simply consumes the context for the Title, and is used by the react-helmet library to set the page title
 */
const TitleContextConsumer = TitleContext.Consumer;

export {TitleContextProvider, TitleContextConsumer};

/**
 * witTitleProvider is a HOC that can wrap any component, providing access to the constructPageTitle method to update the page title
 * @param {*} Component
 */
export const withPageTitle = (Component) => {
  const ComponentWithPageTitle = (props) => {
    const {title, constructPageTitle} = useContext(TitleContext);
    return <Component title={title} constructPageTitle={constructPageTitle} {...props} />;
  };
  ComponentWithPageTitle.displayName = wrapDisplayName(Component, 'withPageTitle');
  return ComponentWithPageTitle;
};
