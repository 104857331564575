import {Route, Link, IndexRoute} from 'react-router';
import {compose} from 'recompose';
import ServiceProviderCreateContainer from 'App/containers/serviceProviders/create';
import ServiceProviderDetailsContainer from 'App/containers/serviceProviders/details';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import ServiceProvidersListContainer from 'App/containers/serviceProviders';
import ConditionalServiceProvidersListContainer from 'App/containers/serviceProviders/list';

export default (
  <Route component={ServiceProvidersListContainer}>
    <IndexRoute component={ConditionalServiceProvidersListContainer} />
    <Route path="/service-providers/create" component={ServiceProviderCreateContainer} />
    <Route path="/service-providers/:serviceProviderId" component={ServiceProviderDetailsContainer} />
  </Route>
);

const ServiceProvidersLink = () => (
  <Link to="/service-providers" activeClassName="active">
    Service Providers
  </Link>
);

export const ConditionalServiceProvidersLink = compose(
  withFlags('modeServiceProviders'),
  withConditionalFallback(({modeServiceProviders}: {modeServiceProviders: boolean}) => !modeServiceProviders, null)
)(ServiceProvidersLink);
