export interface CardTitleProps {
  devicesCount: number;
}

export function CardTitle(props: CardTitleProps) {
  const devicesText = props.devicesCount === 1 ? 'DEVICE' : 'DEVICES';

  return (
    <div className={'flex items-center gap-2'}>
      <div>Tracking Devices</div>
      <div className="text-xxs font-normal text-sw-disabled-text">•</div>
      <div className="text-xxs font-normal text-sw-disabled-text">
        {props.devicesCount} {devicesText}
      </div>
    </div>
  );
}
