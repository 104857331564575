import {useState, useCallback, ComponentPropsWithoutRef, ComponentType} from 'react';

import {func, ReactNodeLike} from 'prop-types';
import {wrapDisplayName} from 'recompose';
import noop from 'lodash/noop';
import {Toast} from '@shipwell/shipwell-ui';

type ToastProps = ComponentPropsWithoutRef<typeof Toast>;
type AnchorPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
type SetToast = (title?: string, message?: ReactNodeLike, anchor?: AnchorPosition, props?: ToastProps) => void;

export interface WithStatusToastProps {
  setSuccess: SetToast;
  setError: SetToast;
  setInfo: SetToast;
  setWarning: SetToast;
}

function WithStatusToasts<PropsT = unknown>(Component: ComponentType<PropsT & WithStatusToastProps>) {
  const ComponentWithStatusToasts = (props: PropsT) => {
    const [errorMessage, setErrorMessage] = useState<ReactNodeLike>('');
    const [errorTitle, setErrorTitle] = useState('Error!');
    const [errorAnchor, setErrorAnchor] = useState('top-right');
    const [errorToastProps, setErrorToastProps] = useState<ToastProps>({});

    const [successMessage, setSuccessMessage] = useState<ReactNodeLike>('');
    const [successTitle, setSuccessTitle] = useState('Success!');
    const [successAnchor, setSuccessAnchor] = useState('top-right');
    const [successToastProps, setSuccessToastProps] = useState<ToastProps>({});

    const [warningMessage, setWarningMessage] = useState<ReactNodeLike>('');
    const [warningTitle, setWarningTitle] = useState('');
    const [warningAnchor, setWarningAnchor] = useState('top-right');
    const [warningToastProps, setWarningToastProps] = useState<ToastProps>({});

    const [infoMessage, setInfoMessage] = useState<ReactNodeLike>('');
    const [infoTitle, setInfoTitle] = useState('');
    const [infoAnchor, setInfoAnchor] = useState('top-right');
    const [infoToastProps, setInfoToastProps] = useState<ToastProps>({});

    /**
     * Set the Error toast params
     * @param {string} title  - title of the toast
     * @param {string} message - message to display
     * @param {string} anchor - location of the toast
     */
    const setError = useCallback<SetToast>((title = 'Error!', message, anchor = 'top-right', props = {}) => {
      setErrorTitle(title);
      setErrorMessage(message || '');
      setErrorAnchor(anchor);
      setErrorToastProps(props);
    }, []);

    /**
     * Set the Success toast params
     * @param {string} title  - title of the toast
     * @param {string} message - message to display
     * @param {string} anchor - location of the toast
     */
    const setSuccess = useCallback<SetToast>((title = 'Success!', message, anchor = 'top-right', props = {}) => {
      setSuccessTitle(title);
      setSuccessMessage(message || '');
      setSuccessAnchor(anchor);
      setSuccessToastProps(props);
    }, []);

    /**
     * Set the Warning toast params
     * @param {string} title  - title of the toast
     * @param {string} message - message to display
     * @param {string} anchor - location of the toast
     */
    const setWarning = useCallback<SetToast>((title, message, anchor = 'top-right', props = {}) => {
      setWarningTitle(title || '');
      setWarningMessage(message || '');
      setWarningAnchor(anchor);
      setWarningToastProps(props);
    }, []);

    /**
     * Set the Info toast params
     * @param {string} title  - title of the toast
     * @param {string} message - message to display
     * @param {string} anchor - location of the toast
     */
    const setInfo = useCallback<SetToast>((title, message, anchor = 'top-right', props = {}) => {
      setInfoTitle(title || '');
      setInfoMessage(message || '');
      setInfoAnchor(anchor);
      setInfoToastProps(props);
    }, []);

    return (
      <>
        <Component setError={setError} setSuccess={setSuccess} setWarning={setWarning} setInfo={setInfo} {...props} />
        <Toast
          variant="error"
          show={Boolean(errorMessage)}
          title={errorTitle}
          anchor={errorAnchor}
          onClose={() => setErrorMessage('')}
          {...errorToastProps}
        >
          {errorMessage}
        </Toast>
        <Toast
          variant="success"
          show={Boolean(successMessage)}
          title={successTitle}
          anchor={successAnchor}
          onClose={() => setSuccessMessage('')}
          {...successToastProps}
        >
          {successMessage}
        </Toast>
        <Toast
          variant="neutral"
          show={Boolean(infoMessage)}
          title={infoTitle}
          anchor={infoAnchor}
          onClose={() => setInfoMessage('')}
          {...infoToastProps}
        >
          {infoMessage}
        </Toast>
        <Toast
          variant="warning"
          show={Boolean(warningMessage)}
          title={warningTitle}
          anchor={warningAnchor}
          onClose={() => setWarningMessage('')}
          {...warningToastProps}
        >
          {warningMessage}
        </Toast>
      </>
    );
  };

  ComponentWithStatusToasts.displayName = wrapDisplayName(Component, 'withStatusToasts');
  return ComponentWithStatusToasts;
}

export default WithStatusToasts;

export const WithStatusToastsPropTypes = {
  setSuccess: func,
  setError: func,
  setWarning: func,
  setInfo: func
};

export const WithStatusToastsDefaultProps = {
  setSuccess: noop,
  setError: noop,
  setWarning: noop,
  setInfo: noop
};
