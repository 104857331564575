// NOTE: still imported my Ondemand/, Settings/forms, Shipment_Doc_Form,
// Shipment_Doc_Update_Form, SignIn_Form & SignUp_Form

// TODO: separate out what is used per page
import {validateEmail, validatePhoneNumber, validateDollarValue} from 'App/utils/globals';

const validate = (values) => {
  const errors = {customer_carrier: []};
  if (values && values.customer_carrier) {
    for (var i = 0; i < values.customer_carrier.length; i++) {
      errors.customer_carrier[i] = {};
      if (values.customer_carrier[i].markup) {
        errors.customer_carrier[i].markup = {};
        if (
          values.customer_carrier[i].markup.ftl_markup_flat_fee &&
          !validateDollarValue(values.customer_carrier[i].markup.ftl_markup_flat_fee)
        ) {
          errors.customer_carrier[i].markup.ftl_markup_flat_fee = 'Enter a valid number';
        }
        if (
          values.customer_carrier[i].markup.ftl_markup_percentage &&
          !validateDollarValue(values.customer_carrier[i].markup.ftl_markup_percentage)
        ) {
          errors.customer_carrier[i].markup.ftl_markup_percentage = 'Enter a valid number';
        }
        if (
          values.customer_carrier[i].markup.ltl_markup_flat_fee &&
          !validateDollarValue(values.customer_carrier[i].markup.ltl_markup_flat_fee)
        ) {
          errors.customer_carrier[i].markup.ltl_markup_flat_fee = 'Enter a valid number';
        }
        if (
          values.customer_carrier[i].markup.ltl_markup_percentage &&
          !validateDollarValue(values.customer_carrier[i].markup.ltl_markup_percentage)
        ) {
          errors.customer_carrier[i].markup.ltl_markup_percentage = 'Enter a valid number';
        }
        if (
          values.customer_carrier[i].markup.ltl_markup_minimum &&
          !validateDollarValue(values.customer_carrier[i].markup.ltl_markup_minimum)
        ) {
          errors.customer_carrier[i].markup.ltl_markup_minimum = 'Enter a valid number';
        }
      }
    }
  }

  return errors;
};

export default validate;
