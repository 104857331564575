import _, {capitalize} from 'lodash';
import moment from 'moment';
import {
  CLEAR_SHIPMENT_STATE,
  POST_NEW_STOP,
  POST_NEW_LINEITEM,
  NEW_SHIPMENT_TIMELINE_EVENT,
  EDIT_SHIPMENT_TIMELINE_EVENT,
  CLEAR_TIMELINE_ADD_FORM,
  NEW_TRUCK_LOCATION,
  MANUAL_RATE_FINANCIALS,
  GET_SHIPMENT_BY_ID,
  ALERT_ERROR,
  DELETE_SHIPMENT_LINEITEM,
  PUT_SHIPMENT_DETAILS,
  GET_SHIPMENT_TIMELINE,
  POST_SHIPMENT_TIMELINE_EVENT,
  DELETE_SHIPMENT_TIMELINE_EVENT,
  PUT_TIMELINE_EVENT,
  SELECT_FINANCIALS,
  SELECT_RATE_TABLE,
  CLEAR_RATE_TABLE,
  GET_SHIPMENT_MESSAGES,
  CANCEL_SHIPMENT,
  SHARE_LINK,
  CLEAR_NOTES,
  NOTES_LOADING,
  GET_NOTES,
  FETCH_BREADCRUMBS,
  CLEAR_BREADCRUMBS,
  STOPS_LOADING,
  FETCH_STOPS,
  FETCH_STOP,
  CLEAR_STOP,
  FETCH_REPS,
  POST_REPS,
  PUT_REPS,
  GET_AUTOMATED_EVENTS,
  SELECT_AUTOMATED_EVENT
} from 'App/actions/types';
import {parseErrors, formatTime, unpackErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';
import {createGrammaticList} from 'App/utils/grammar';

export function clearShipmentState() {
  return (dispatch) => {
    dispatch({type: CLEAR_SHIPMENT_STATE});
  };
}

export function postNewStop() {
  return (dispatch) => {
    dispatch({type: POST_NEW_STOP});
  };
}

export function postNewLineitem() {
  return (dispatch) => {
    dispatch({type: POST_NEW_LINEITEM});
  };
}

export function postNewTimelineEvent() {
  return (dispatch) => {
    dispatch({type: NEW_SHIPMENT_TIMELINE_EVENT});
  };
}

export function editTimelineEvent(timeline) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SHIPMENT_TIMELINE_EVENT,
      payload: timeline
    });
  };
}

export function resetTimelineAddForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TIMELINE_ADD_FORM,
      payload: {}
    });
  };
}

export function postNewTruckLocation() {
  return (dispatch) => {
    dispatch({type: NEW_TRUCK_LOCATION});
  };
}

export function manualRateFinancials() {
  return (dispatch) => {
    dispatch({
      type: MANUAL_RATE_FINANCIALS,
      payload: {markup: 0, financials: [{unit_quantity: 1}]}
    });
  };
}

const validatePayload = (shipment) => {
  // read only status should never be sent in payload
  delete shipment.rail_car_status;
  if (shipment.line_items) {
    shipment.line_items.forEach((item) => {
      // FTL only requires description and total weight
      if (!item.total_packages || isNaN(item.total_packages)) {
        delete item.total_packages;
      }
      if (!item.package_type) {
        delete item.package_type;
      }
      if (!item.freight_class) {
        delete item.freight_class;
      }
      if (!item.length || isNaN(item.length)) {
        delete item.length;
      }
      if (!item.width || isNaN(item.width)) {
        delete item.width;
      }
      if (!item.height || isNaN(item.height)) {
        delete item.height;
      }
      if (!item.package_weight || isNaN(item.package_weight)) {
        delete item.package_weight;
      }
      if (!item.total_pieces) {
        delete item.total_pieces;
      }
      if (!item.nmfc_item_code) {
        delete item.nmfc_item_code;
      }
      if (!item.nmfc_sub_code) {
        delete item.nmfc_sub_code;
      }
    });
  }

  if (shipment.stops) {
    shipment.stops.forEach((stop, index) => {
      // format 24 hour if date object (i.e. 18:00:00)
      if (stop.planned_time_window_start && typeof stop.planned_time_window_start !== 'string') {
        stop.planned_time_window_start = formatTime(stop.planned_time_window_start);
      }

      if (stop.planned_time_window_end && typeof stop.planned_time_window_end !== 'string') {
        stop.planned_time_window_end = formatTime(stop.planned_time_window_end);
      }

      if (stop.planned_date) {
        stop.planned_date = moment(stop.planned_date).format('YYYY-MM-DD');
      }

      // ensure ordinal_index is an integer
      stop.ordinal_index = Number(stop.ordinal_index);

      // update first & last stop is_dropoff & is_pickup
      // in case ordinal_index was changed
      if (stop.ordinal_index === 0) {
        stop.is_pickup = true;
        stop.is_dropoff = false;
      } else if (index === shipment.stops.length - 1) {
        stop.is_pickup = false;
        stop.is_dropoff = true;
      }

      if (stop.confirmed_arrival_at && moment(stop.confirmed_arrival_at).isValid()) {
        stop.confirmed_arrival_at = moment(stop.confirmed_arrival_at).toDate().toISOString();
      } else {
        stop.confirmed_arrival_at = null;
      }

      if (stop.confirmed_departure_at && moment(stop.confirmed_departure_at).isValid()) {
        stop.confirmed_departure_at = moment(stop.confirmed_departure_at).toDate().toISOString();
      } else {
        stop.confirmed_departure_at = null;
      }
    });
  }

  // metadata and metadata.tags are required
  if (_.isEmpty(shipment.metadata)) {
    shipment.metadata = {};
  }

  // metadata and metadata.tags are required
  if (_.isEmpty(shipment.metadata.tags)) {
    shipment.metadata.tags = [];
  }

  return shipment;
};

/**
 * Obtain information about a Shipment
 * @param {String} shipmentId The id of the shipment
 * @param {module:api/shipwellApi~shipmentsShipmentIdGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Shipment}
 */
export function shipmentsShipmentIdGet(shipmentId, opts) {
  opts = opts || {};

  return (dispatch) => {
    return shipmentsShipmentIdGetPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: GET_SHIPMENT_BY_ID, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Return shipment details
 */
async function shipmentsShipmentIdGetPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);
  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Delete a Stop
 * @param {String} shipmentId The id of the shipment
 * @param {String} stopId The id of the stop
 * @param {Object} opts Optional parameters
 * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
 * @param {module:api/shipwellApi~shipmentsShipmentIdStopsStopIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/NoContentResponse}
 */
export function deleteShipmentStop(shipmentId, stopId, opts) {
  opts = opts || opts;
  return (dispatch) => {
    return deleteShipmentStopPromise(shipmentId, stopId, {})
      .then((response) => {
        //dispatch({type: types.DELETE_SHIPMENT_STOP, payload: stopId});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting this stop.'
        });
      });
  };
}

async function deleteShipmentStopPromise(shipmentId, stopId, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdStopsStopIdDelete(shipmentId, stopId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve({});
      }
    });
  });
}

/**
 * Update a Shipment
 * After a successful update, the &#39;stops&#39; and &#39;line_items&#39; of the Shipment will be exactly the same as those provided in the request. This means that if existing Stops or ShipmentLineItems were left out of their arrays, those objects would be deleted. Providing existing objects in those arrays with their IDs will update those objects instead of creating or deleting them. Providing objects in the arrays that do not have IDs will create new objects. If you omit the &#39;stops&#39; or &#39;line_items&#39; arrays from the request body, no changes will be made to those related object sets.
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/CreateShipment} body
 * @param {Object} opts Optional parameters
 * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
 * @param {module:api/shipwellApi~shipmentsShipmentIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Shipment}
 */
export function shipmentsShipmentIdPut(shipmentId, body, opts) {
  opts = opts || {};
  const shipment = body;

  validatePayload(shipment);

  return (dispatch) => {
    return shipmentsShipmentIdPutPromise(shipmentId, body, opts)
      .then((response) => {
        dispatch({type: GET_SHIPMENT_BY_ID, payload: response});
        return {status: 200, payload: response};
      })
      .catch((response) => {
        throw response;
      });
  };
}

/**
 * Return shipment details
 */
async function shipmentsShipmentIdPutPromise(shipmentId, body, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdPut(shipmentId, body, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * delete a shipment
 */
export function deleteShipmentLineItem(shipmentId, body, removeLine, opts) {
  opts = opts || {};
  const shipment = body;
  let lineItems = Object.assign([], body.line_items);
  validatePayload(shipment);

  // delete line item
  lineItems = [...lineItems.slice(0, removeLine), ...lineItems.slice(removeLine + 1)];

  shipment.line_items = lineItems;

  return (dispatch) => {
    return shipmentsShipmentIdPutPromise(shipmentId, shipment, opts)
      .then((response) => {
        dispatch({type: DELETE_SHIPMENT_LINEITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Create a new Stop
 * The new Stop must not have the same &#39;ordinal_index&#39; as any other Stop associated with that Shipment.
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/Stop} body
 * @param {Object} opts Optional parameters
 * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
 * @param {module:api/shipwellApi~shipmentsShipmentIdStopsPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Stop}
 */
export function shipmentsShipmentIdStopsPost(shipmentId, body, opts) {
  opts = opts || {};
  const stop = body;
  let startTime, endTime, plannedStart, plannedEnd;

  startTime = stop.location.dock_hours_start;
  endTime = stop.location.dock_hours_end;
  // etas
  plannedStart = stop.planned_time_window_start;
  plannedEnd = stop.planned_time_window_end;

  // format 24 hour (i.e. 18:00:00)
  if (startTime && typeof startTime !== 'string') {
    stop.location.dock_hours_start = formatTime(startTime);
  }
  if (endTime && typeof endTime !== 'string') {
    stop.location.dock_hours_end = formatTime(endTime);
  }
  if (plannedStart && typeof plannedStart !== 'string') {
    stop.planned_time_window_start = formatTime(plannedStart);
  }
  if (plannedEnd && typeof plannedEnd !== 'string') {
    stop.planned_time_window_end = formatTime(plannedEnd);
  }

  return (dispatch) => {
    return shipmentsShipmentIdStopsPostPromise(shipmentId, body, opts)
      .then((response) => {
        dispatch({type: PUT_SHIPMENT_DETAILS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Return shipment details
 */
async function shipmentsShipmentIdStopsPostPromise(shipmentId, body, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdStopsPost(shipmentId, body, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Get a list of ShipmentTimelineEvents for a given Shipment
 * @param {String} shipmentId The id of the shipment
 * @param {Object} opts Optional parameters
 * @param {Integer} opts.page indicates what page of a list of query results you want to view
 * @param {Integer} opts.pageSize indicates the how many items to return per page in a list request
 * @param {module:api/shipwellApi~shipmentsShipmentIdTimelineEventsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedShipmentTimelineEvent}
 */
export function getShipmentTimeline(shipmentId, opts) {
  opts = opts || {};
  return (dispatch) => {
    return getShipmentTimelinePromise(shipmentId, opts)
      .then((response) => {
        dispatch({
          type: GET_SHIPMENT_TIMELINE,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

/**
 * Return shipment timeline
 */
async function getShipmentTimelinePromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdTimelineEventsGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Create a new ShipmentTimelineEvent
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/CreateShipmentTimelineEvent} body
 * @param {module:api/shipwellApi~shipmentsShipmentIdTimelineEventsPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipmentTimelineEvent}
 */
export function postShipmentTimeline(shipmentId, event) {
  return (dispatch) => {
    return postShipmentTimelinePromise(shipmentId, event)
      .then((response) => {
        dispatch({
          type: POST_SHIPMENT_TIMELINE_EVENT,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function postShipmentTimelinePromise(shipmentId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsPost(shipmentId, event, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// Delete an event from the shipment timeline
export function deleteShipmentTimelineEvent(shipmentId, eventId) {
  return (dispatch) => {
    return deleteShipmentTimelineEventPromise(shipmentId, eventId)
      .then((response) => {
        dispatch({
          type: DELETE_SHIPMENT_TIMELINE_EVENT,
          payload: eventId
        });
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function deleteShipmentTimelineEventPromise(shipmentId, eventId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdDelete(
      shipmentId,
      eventId,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response);
        }
      }
    );
  });
}

/**
 * Update a ShipmentTimelineEvent
 * @param {String} shipmentId The id of the shipment
 * @param {String} shipmentTimelineEventId
 * @param {module:model/CreateShipmentTimelineEvent} body
 * @param {module:api/shipwellApi~shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipmentTimelineEvent}
 */
export function shipmentsTimelineEventPut(shipmentId, eventId, body) {
  return (dispatch) => {
    return shipmentsTimelineEventPutPromise(shipmentId, eventId, body)
      .then((response) => {
        dispatch({type: PUT_TIMELINE_EVENT, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Return shipment details
 */
async function shipmentsTimelineEventPutPromise(shipmentId, eventId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPut(
      shipmentId,
      eventId,
      body,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//Select charge line items for modification
export function selectFinancials(charge_line_items) {
  return (dispatch) => {
    return dispatch({
      type: SELECT_FINANCIALS,
      payload: charge_line_items
    });
  };
}

export const clearRateTableDetails = () => {
  return {
    type: CLEAR_RATE_TABLE,
    payload: {}
  };
};

export const selectedRateTableDetails = (selectRateTable) => ({
  type: SELECT_RATE_TABLE,
  payload: selectRateTable
});

// Get messages associated with the shipment
export function getShipmentMessages(shipmentId, opts) {
  return (dispatch) => {
    return getShipmentMessagesPromise(shipmentId, opts)
      .then((response) => {
        dispatch({
          type: GET_SHIPMENT_MESSAGES,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getShipmentMessagesPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdMessagesGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// Post a messages
export function postShipmentMessage(shipmentId, body) {
  return (dispatch) => {
    return postShipmentMessagePromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function postShipmentMessagePromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdMessagesPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Cancel a shipment, and notify the currently assigned carrier of cancellation in an email
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/CancellationRequest} body
 * @param {module:api/shipwellApi~shipmentsShipmentIdCancelPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Shipment}
 */
export function cancelShipment(shipmentId, body) {
  return (dispatch) => {
    return cancelShipmentPromise(shipmentId, body)
      .then((response) => {
        dispatch({type: CANCEL_SHIPMENT, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error cancelling this shipment.'
        });
      });
  };
}

async function cancelShipmentPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCancelPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Share a Shipment with outside parties and get a link to it
 */
export function shareLink(shipmentId, body) {
  return (dispatch) => {
    return shareLinkPromise(shipmentId, body)
      .then((response) => {
        dispatch({type: SHARE_LINK, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error sharing this link'
        });
      });
  };
}

async function shareLinkPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdSharePost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/*------------ Notes ------------*/

export function clearCurrentNotes() {
  return (dispatch) => {
    dispatch({type: CLEAR_NOTES});
  };
}

export function getNotes(shipmentId, opts) {
  return (dispatch) => {
    dispatch({type: NOTES_LOADING});
    return getNotesPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: GET_NOTES, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error fetching notes'
        });
        return {status: 400};
      });
  };
}

async function getNotesPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdNotesGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function postNote(shipmentId, body) {
  return (dispatch) => {
    return postNotePromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error creating this note'
        });
        return {status: 400};
      });
  };
}

async function postNotePromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdNotesPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteNote(shipmentId, shipmentNoteId) {
  return (dispatch) => {
    return deleteNotePromise(shipmentId, shipmentNoteId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting this note'
        });
        return {status: 400};
      });
  };
}

async function deleteNotePromise(shipmentId, shipmentNoteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdNotesShipmentNoteIdDelete(
      shipmentId,
      shipmentNoteId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

export function putNote(shipmentId, shipmentNoteId, body) {
  return (dispatch) => {
    return putNotePromise(shipmentId, shipmentNoteId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error editing this note'
        });
        return {status: 400};
      });
  };
}

async function putNotePromise(shipmentId, shipmentNoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdNotesShipmentNoteIdPut(
      shipmentId,
      shipmentNoteId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Get locations for the shipment
 * @param {String} shipmentId The id of the shipment
 * @param {Object} opts Optional parameters
 * @param {Date} opts.beginDatetime For requesting data starting at a given date
 * @param {Date} opts.endDatetime For requesting data ending at a given date
 * @param {String} opts.filterDistance Applies a distance filter and only returns points greater than filter_distance km apart. To return all, set this to -1
 */
export function getBreadcrumbs(shipmentId, opts, count) {
  opts = opts || {};

  return (dispatch) => {
    return getBreadcrumbsPromise(shipmentId, opts)
      .then((response) => {
        dispatch({
          type: FETCH_BREADCRUMBS,
          payload: response,
          meta: {
            key: count
          }
        });
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400};
      });
  };
}

export async function getBreadcrumbsPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdTrackingGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Get locations for the shipment using a query param key instead of authentication
 */
export function getBreadcrumbsUnauth(shipmentId, key, opts, count) {
  opts = opts || {};

  return (dispatch) => {
    return getBreadcrumbsUnauthPromise(shipmentId, key, opts)
      .then((response) => {
        dispatch({
          type: FETCH_BREADCRUMBS,
          payload: response,
          meta: {
            key: count
          }
        });
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400};
      });
  };
}

async function getBreadcrumbsUnauthPromise(shipmentId, key, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsExternalShipmentIdTrackingGet(shipmentId, key, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Manually update Shipment Location. Authenticated drivers can also use this route to update their location
 */
export function postBreadcrumb(shipmentId, body) {
  return (dispatch) => {
    return postBreadcrumbPromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function postBreadcrumbPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdTrackingPost(shipmentId, body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// NOTE: from v1
export function clearBreadcrumbs() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BREADCRUMBS
    });
  };
}

/*
 * Get a list of Stops for a given Shipment
 * @param {String} shipmentId The id of the shipment
 * @param {Object} opts Optional parameters
 * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
 * @param {Integer} opts.page indicates what page of a list of query results you want to view
 * @param {Integer} opts.pageSize indicates the how many items to return per page in a list request
 * @param {String} opts.ordering determines which column to sort by. Prepend with &#39;-&#39; to sort desc
 * @param {module:api/shipwellApi~shipmentsShipmentIdStopsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedStop}
 */

export function fetchStops(shipmentId, opts) {
  return (dispatch) => {
    dispatch({type: STOPS_LOADING});
    return fetchStopsPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: FETCH_STOPS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400};
      });
  };
}

async function fetchStopsPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsGet(shipmentId, opts, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Obtain information about a Stop
 * @param {String} shipmentId The id of the shipment
 * @param {String} stopId The id of the stop
 * @param {Object} opts Optional parameters
 * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
 * @param {module:api/shipwellApi~shipmentsShipmentIdStopsStopIdGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Stop}
 */

export function fetchStop(shipmentId, stopId) {
  return (dispatch) => {
    dispatch({type: STOPS_LOADING});
    return fetchStopPromise(shipmentId, stopId)
      .then((response) => {
        dispatch({type: FETCH_STOP, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400};
      });
  };
}
/**
 * Gets a single stop by it shipment ID and it's identifier.
 * @returns {Promise<import("@shipwell/backend-core-sdk").Stop>} stop or throws an error if no stop was found.
 */
export async function fetchStopPromise(shipmentId, stopId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdStopsStopIdGet(shipmentId, stopId, {}, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function clearStop() {
  return (dispatch) => {
    dispatch({type: CLEAR_STOP});
  };
}

/**
 * Get the current equipment config associated with this shipment
 * @param {String} shipmentId The id of the shipment
 * @param {module:api/shipwellApi~shipmentsShipmentIdEquipmentConfigGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/EquipmentConfig}
 */

export function fetchEquipmentConfig(shipmentId) {
  return (dispatch) => {
    return fetchEquipmentConfigPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400};
      });
  };
}

async function fetchEquipmentConfigPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdEquipmentConfigGet(shipmentId, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchReps(shipmentId) {
  return (dispatch) => {
    return fetchRepsPromise(shipmentId)
      .then((response) => {
        dispatch({type: FETCH_REPS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting reps'
        });
        return {status: 400};
      });
  };
}

async function fetchRepsPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsGet(shipmentId, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/* post rep to a shipment */
/* body:
{
    "id": "f50c325e-8543-473c-9adc-d38fb41e1710",
    "role": 1,
    "user": "47d6db7a-2f62-457d-8aef-6dfe23a8ebbc"
}
*/
export function postRep(shipmentId, body) {
  return (dispatch) => {
    return postRepPromise(shipmentId, body)
      .then((response) => {
        dispatch({type: POST_REPS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error adding a rep'
        });
        return {status: 400};
      });
  };
}

async function postRepPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsPost(shipmentId, body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/* update rep */
export function putRep(shipmentId, repId, body) {
  return (dispatch) => {
    return putRepPromise(shipmentId, repId, body)
      .then((response) => {
        dispatch({type: PUT_REPS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error updating a rep'
        });
        return {status: 400};
      });
  };
}

async function putRepPromise(shipmentId, repId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsRepIdPut(shipmentId, repId, body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/* delete rep */
export function deleteRep(shipmentId, repId) {
  return (dispatch) => {
    return deleteRepPromise(shipmentId, repId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting a rep'
        });
        return {status: 400};
      });
  };
}

async function deleteRepPromise(shipmentId, repId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipmentsShipmentIdRepsRepIdDelete(shipmentId, repId, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//a function to clear automated events from the store so that when you switch between shipments they dont appear incorrectly initially
export function clearAutomatedEvents() {
  return (dispatch) => {
    dispatch({type: GET_AUTOMATED_EVENTS, payload: {results: []}});
  };
}

export function getAutomatedEvents(shipmentId, opts) {
  opts = opts || {pageSize: 100};
  return (dispatch) => {
    return getAutomatedEventsPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: GET_AUTOMATED_EVENTS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function getAutomatedEventsPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutomationEventsGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function selectAutomatedEvent(event) {
  return (dispatch) => {
    dispatch({
      type: SELECT_AUTOMATED_EVENT,
      payload: event
    });
  };
}

export function scheduleAutomatedEvent(shipmentId, event) {
  return (dispatch) => {
    return scheduleAutomatedEventPromise(shipmentId, event)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function scheduleAutomatedEventPromise(shipmentId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutomationEventsPost(shipmentId, event, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function editAutomatedEvent(shipmentId, eventId, event) {
  return (dispatch) => {
    return editAutomatedEventPromise(shipmentId, eventId, event)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function editAutomatedEventPromise(shipmentId, eventId, event) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutomationEventsAutomationEventIdPut(
      shipmentId,
      eventId,
      event,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

export function deleteAutomatedEvent(shipmentId, eventId) {
  return (dispatch) => {
    return deleteAutomatedEventPromise(shipmentId, eventId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function deleteAutomatedEventPromise(shipmentId, eventId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutomationEventsAutomationEventIdDelete(
      shipmentId,
      eventId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//when certain events happen, use this route to delete all future scheduled automated calls
export function deleteScheduledCalls(shipmentId) {
  return (dispatch) => {
    return deleteScheduledCallsPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function deleteScheduledCallsPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutomationEventsCancelScheduledDelete(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function performOnDemandCheckCall(shipmentId, callBody) {
  return (dispatch) => {
    return performOnDemandCheckCallPromise(shipmentId, callBody)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function performOnDemandCheckCallPromise(shipmentId, callBody) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdInitiateCheckCallPost(shipmentId, callBody, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}
