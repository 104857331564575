import {DocumentType} from '@shipwell/corrogo-sdk';
import get from 'lodash/get';

export const DocumentTypeLabels: Record<DocumentType, string> = {
  [DocumentType.Bol]: 'Bill of Lading (BOL)',
  [DocumentType.Fuel]: 'Fuel Receipt',
  [DocumentType.Lumper]: 'Lumper Receipt',
  [DocumentType.Hos]: 'Hours of Service Log',
  [DocumentType.Signature]: 'Signature',
  [DocumentType.RateConfirmation]: 'Rate Confirmation',
  [DocumentType.DeliveryReceipt]: 'Delivery Receipt',
  [DocumentType.WeightCertificate]: 'Weight Certificate',
  [DocumentType.OveragesShortagesAndDamages]: 'Overages, Shortages and Damages',
  [DocumentType.InventoryRelease]: 'InventoryRelease',
  [DocumentType.Invoice]: 'Invoice',
  [DocumentType.InventoryRelease]: 'Inventory Release',
  [DocumentType.Insurance]: 'Insurance',
  [DocumentType.CustomsPaperwork]: 'Customs Paperwork',
  [DocumentType.PackingSlips]: 'Packing Slips',
  [DocumentType.Other]: 'Other'
};

export const getDocumentTypeLabel = (documentType = ''): string =>
  get(DocumentTypeLabels, documentType, DocumentTypeLabels[DocumentType.Other]);
