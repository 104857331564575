import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import withConditionalFallback from 'App/components/withConditionalFallback';

/**
 * A higher-order component that renders the wrapped component if the given prop or path to a prop
 * is not empty and the CollapsedFallback component if it is false.
 *
 * @param {String} prop The path to a prop that is checked for emptiness before rendering either the wrapped component or the EmptyFallback component
 * @param {ReactElement} EmptyFallback The component to render if the given prop is empty
 *
 * @return {Function} A higher-order component.
 */
export default (prop, EmptyFallback) => withConditionalFallback((props) => isEmpty(get(props, prop)), EmptyFallback);
