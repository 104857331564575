import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import validateProfile from 'App/utils/validateProfile';
import renderImageDropzone from 'App/formComponents/renderImageDropzone';

let ProfileForm = (props) => {
  const {handleSubmit, submitting, timezones, saved, updateBlobImage, user, setRef, handleSaveAvatar, deleteAvatar} =
    props;
  return (
    <Form onSubmit={handleSubmit}>
      <Paper className="settings__card">
        <h2>Update your information</h2>
        <div className="settings__profile">
          <div className="settings__profile-avatar">
            <Field
              name="avatar"
              style={{}}
              props={{user: user, setRef: setRef, handleSaveAvatar: handleSaveAvatar, deleteAvatar: deleteAvatar}}
              component={renderImageDropzone}
              id="dropzone-image"
            />
          </div>
          <div className="settings__profile-fields">
            <Field
              label="First Name"
              name="first_name"
              required
              component={renderField}
              type="text"
              placeholder="First Name"
            />

            <Field
              label="Last Name"
              name="last_name"
              required
              component={renderField}
              type="text"
              placeholder="Last Name"
            />

            <Field label="Email" name="email" component={renderField} type="email" placeholder="Email" />

            <Field
              label="Phone"
              name="phone_number"
              component={renderPhoneNumberField}
              type="phone"
              placeholder="Phone"
            />

            <Field
              label="Preferred Timezone"
              tooltip="Select the timezone you prefer to see on notifications about shipments sent via email"
              name="timezone"
              component={renderDropDownSelector}
              data={timezones}
              placeholder="Select"
            />
          </div>
        </div>
      </Paper>
      <div className="settings__footer">
        {saved && (
          <span className="form-saved pad-right">
            <i className="icon icon-Check pad-right" />
            Saved!
          </span>
        )}
        <Button type="submit" disabled={submitting} className="btn btn-primary">
          Update
        </Button>
      </div>
    </Form>
  );
};

ProfileForm = reduxForm({
  form: 'profileForm',
  validate: validateProfile,
  enableReinitialize: true
})(ProfileForm);

ProfileForm = connect((state) => ({
  initialValues: state.auth.user
}))(ProfileForm);

export default ProfileForm;
