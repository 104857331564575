export const defaultColors = [
  '#000000',
  '#444444',
  '#676767',
  '#999999',
  '#B7B7B7',
  '#CCCCCC',
  '#D9D9D9',
  '#EFEFEF',
  '#F3F3F3',
  '#FFFFFF',

  '#801710',
  '#D82E20',
  '#EA9C39',
  '#FDFD55',
  '#77F94D',
  '#75FBFD',
  '#5A85DB',
  '#0022EB',
  '#802BED',
  '#D63AEC',

  '#C6796F',
  '#D6959C',
  '#EFCBA5',
  '#FAE8A2',
  '#BEDCAE',
  '#AAC5C9',
  '#ABC2EB',
  '#A9C6E0',
  '#B1A2D0',
  '#C9A3BB',

  '#B34032',
  '#CA6269',
  '#E8B176',
  '#F2DC7A',
  '#A2CC87',
  '#82A8AC',
  '#7A9FE3',
  '#7BA9D5',
  '#8778BC',
  '#B4769D',

  '#8E2114',
  '#AA2216',
  '#D5924E',
  '#E5C654',
  '#80B360',
  '#58878D',
  '#4E7ACE',
  '#006BA2',
  '#604A9F',
  '#904876',

  '#752119',
  '#821712',
  '#A16024',
  '#B39431',
  '#4E7E2F',
  '#2A555B',
  '#2757C2',
  '#26568C',
  '#2D196F',
  '#631A46'
];
