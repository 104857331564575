import {Field} from 'formik';
import {FormikCheckbox} from '@shipwell/shipwell-ui';

const CompanyTenderEmail = () => {
  return (
    <>
      <div className="font-bold">Tender Emails</div>
      <Field
        component={FormikCheckbox}
        name="email_tender_accept_reject"
        label="Display options to Accept or Reject Tenders directly from email."
      />
    </>
  );
};

export default CompanyTenderEmail;
