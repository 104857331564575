import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Button, Row, Col} from 'react-bootstrap';
import select from 'App/formComponents/fields/select';
import creatable from 'App/formComponents/fields/creatable';
import validate from 'App/utils/validateEquipmentConfigForm';
import {validatePhoneNumber} from 'App/utils/globals';
import './_dispatch.scss';
import ShipwellLoader from 'App/common/shipwellLoader/index';

let EquipmentConfigForm = (props) => {
  const {handleSubmit, submitting, powerUnits, trailers, drivers, activeInfoTab, isLoading, update, formValues} = props;
  const driverList = [];
  if (drivers) {
    for (let i = 0; i < drivers.results.length; i++) {
      if (drivers.results[i].phone_number) {
        driverList.push({
          id: drivers.results[i].id,
          phone_number: drivers.results[i].phone_number,
          name: drivers.results[i].first_name + ' ' + drivers.results[i].last_name
        });
      }
    }
  }
  return isLoading ? (
    <ShipwellLoader loading={isLoading} />
  ) : (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={12}>
          <Field
            component={creatable}
            options={driverList}
            name="driver"
            labelKey="name"
            valueKey="phone_number"
            label="Driver"
            placeholder="Select a driver or enter a number"
            simpleValue
            onNewOptionClick={(value) => {
              let phoneNum;
              const cleaned = ('' + value.phone_number).replace(/\D/g, '');
              const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
              if (match) {
                phoneNum = ['+1', match[2], match[3], match[4]].join('');
              }
              if (validatePhoneNumber(phoneNum)) {
                const body = {phone_number: phoneNum, name: phoneNum};
                return body;
              }
              return null;
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Field
            component={select}
            options={powerUnits && powerUnits.results && powerUnits.results.length > 0 ? powerUnits.results : []}
            name="power_unit"
            labelKey="name"
            valueKey="id"
            label="Power Unit"
            placeholder="Select a Power Unit"
            simpleValue
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Field
            component={select}
            options={trailers && trailers.results && trailers.results.length > 0 ? trailers.results : []}
            name="trailer"
            labelKey="name"
            valueKey="id"
            label="Trailer"
            placeholder="Select a Trailer"
            simpleValue
          />
        </Col>
      </Row>

      <div className="equipment__footer-btns">
        {update && activeInfoTab === 'unassigned' && (
          <div className="text-success" style={{lineHeight: '33px'}}>
            <i className="icon icon-Check pad-right" />
            Moving shipment to assigned loads.
          </div>
        )}
        <Button bsStyle="primary" disabled={submitting} type="submit">
          {submitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

EquipmentConfigForm = reduxForm({
  form: 'EquipmentConfigForm',
  validate: validate,
  enableReinitialize: true
})(EquipmentConfigForm);

EquipmentConfigForm = connect((state) => ({
  initialValues: {
    carrier: state.auth.company && state.auth.company.carrier ? state.auth.company.carrier : undefined,
    driver:
      state.shipmentdetails.one && state.shipmentdetails.one.equipment_config
        ? state.shipmentdetails.one.equipment_config.driver
          ? {
              name:
                state.shipmentdetails.one.equipment_config.driver.first_name +
                ' ' +
                state.shipmentdetails.one.equipment_config.driver.last_name,
              phone_number: state.shipmentdetails.one.equipment_config.driver.phone_number
            }
          : state.shipmentdetails.one.equipment_config.driver.phone_number
          ? {
              name: state.shipmentdetails.one.equipment_config.driver.phone_number,
              phone_number: state.shipmentdetails.one.equipment_config.driver.phone_number
            }
          : ''
        : '',
    power_unit:
      state.shipmentdetails.one && state.shipmentdetails.one.equipment_config
        ? state.shipmentdetails.one.equipment_config.power_unit
          ? state.shipmentdetails.one.equipment_config.power_unit
          : ''
        : '',
    trailer:
      state.shipmentdetails.one && state.shipmentdetails.one.equipment_config
        ? state.shipmentdetails.one.equipment_config.trailer
          ? state.shipmentdetails.one.equipment_config.trailer
          : ''
        : ''
  }
}))(EquipmentConfigForm);

export default EquipmentConfigForm;
