import {FormikErrors} from 'formik';

// returns the number of errors in formik's `errors` object
export function getErrorCount<T>(errors: FormikErrors<T> | string | string[]): number {
  return Object.keys(errors).reduce((acc, key) => {
    if (typeof errors[key as keyof typeof errors] === 'string') {
      return (acc += 1);
    }
    if (typeof errors[key as keyof typeof errors] === 'object') {
      return (acc += getErrorCount(errors[key as keyof typeof errors] as FormikErrors<T>));
    }
    return acc;
  }, 0);
}
