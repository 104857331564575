import PropTypes from 'prop-types';
import {Field} from 'formik';
import {FormikTextarea, FormikFileSelect, FormikSelect} from '@shipwell/shipwell-ui';

/**
 * Shipment Document Fields
 * @param {*} props
 */
const ShipmentDocumentFields = ({documentTypes, edit, hideTypeField}) => (
  <div className="mt-4 grid auto-rows-40-auto gap-y-4">
    {!edit ? <Field required name="file" component={FormikFileSelect} /> : null}
    {hideTypeField ? null : (
      <Field
        name="type"
        required
        component={FormikSelect}
        options={documentTypes}
        placeholder="Select a type"
        label="Type"
        getOptionLabel={({name}) => name}
        getOptionValue={({id}) => id}
        simpleValue
      />
    )}
    <Field name="description" required label="Description" component={FormikTextarea} />
  </div>
);

ShipmentDocumentFields.propTypes = {
  documentTypes: PropTypes.array,
  edit: PropTypes.bool,
  hideTypeField: PropTypes.bool
};

export default ShipmentDocumentFields;
