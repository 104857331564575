import {useQuery} from '@tanstack/react-query';
import {getShipmentContainerDetails} from 'App/api/shipment/typed';

export const useGetShipmentContainerDetails = ({shipmentId}: {shipmentId: string}) => {
  const containerDetailsQuery = useQuery(
    ['containerDetails', shipmentId],
    async () => {
      const response = await getShipmentContainerDetails(shipmentId);
      return response.data;
    },
    {
      enabled: Boolean(shipmentId)
    }
  );

  const {data: containerDetailsData, status: containerDetailsStatus} = containerDetailsQuery;

  return {
    containerDetailsData,
    containerDetailsStatus,
    containerDetailsQuery
  };
};
