import {ReactNode, useMemo} from 'react';
import {Field} from 'formik';
import pluralize from 'pluralize';
import {
  CollapsibleCardContent,
  SvgIcon,
  Dropdown,
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
  Card,
  FormikDateTimePicker,
  Button,
  Tooltip
} from '@shipwell/shipwell-ui';
import {Equipment} from '@shipwell/tabula-sdk';
import classNames from 'classnames';
import {Link} from 'react-router';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import useToggle from 'App/utils/hooks/useToggle';
import {addDays} from 'App/utils/dateTimeGlobalsTyped';
import {
  DEFAULT_AGE_LIMIT_MINUTES,
  DEFAULT_EARLIEST_AVAILABILITY,
  DEFAULT_LATEST_AVAILABILITY,
  DEFAULT_RANGE_DISTANCE,
  CapacitySearchFormValues
} from 'App/containers/Shipment/ShipmentSourceCapacity';
import {useDatIntegration} from 'App/data-hooks';

const CardWrapper = ({
  children,
  title,
  showClearBtn,
  clearFilter
}: {
  children: ReactNode;
  title: string;
  showClearBtn: boolean;
  clearFilter: () => void;
}) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle(false);

  return (
    <Card
      title={title}
      draggableProvided={undefined}
      isCollapsible
      actions={
        showClearBtn ? (
          <Button isCompact size="sm" variant="tertiary" onClick={clearFilter}>
            Clear
          </Button>
        ) : null
      }
      isCollapsed={isCollapsed}
      onCollapse={toggleIsCollapsed}
      className={classNames('rounded-none', 'border-b-0', 'font-normal', {'bg-sw-background': !isCollapsed})}
      headerClassName={classNames('min-h-min', 'pr-0')}
      titleClassName={classNames('font-normal', 'cursor-default', 'leading-7', {'!font-bold': !isCollapsed})}
      iconBoxClassName={classNames(
        'min-h-full',
        'border-none',
        'mr-2.5',
        'h-7',
        '!w-8',
        `${isCollapsed ? '' : 'h-8 [&>svg]:fill-sw-primary bg-sw-active rounded-full bg-opacity-10 mt-1'}`
      )}
      bodyClassName={classNames(
        'bg-sw-background',
        'px-4',
        'py-2',
        'empty:p-0',
        'pt-0',
        `${isCollapsed ? '' : 'mt-2 border-t-0'}`
      )}
    >
      <CollapsibleCardContent>{children}</CollapsibleCardContent>
    </Card>
  );
};

const equipmentTypeDefaultOptions = ['DRY_VAN', 'REEFER', 'FLATBED'];

export type ShipmentSourceCapacityTableFiltersProps = {
  equipments: Equipment[];
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
  filters: CapacitySearchFormValues;
  defaultEquipments?: Equipment[];
};

const ShipmentSourceCapacityTableFilters = ({
  equipments,
  defaultEquipments,
  setFieldValue,
  filters
}: ShipmentSourceCapacityTableFiltersProps) => {
  const equipmentQuery = useEquipmentTypesQuery();
  const {isDatIntegrated} = useDatIntegration();
  const equipmentData = equipments?.[0];
  const equipmentValues = equipments.map((equipment) => equipment.equipment_type);
  const defaultEquipmentWithoutAdditionalInfo = useMemo(
    () => defaultEquipments?.map((el) => ({equipment_type: el.equipment_type})) as unknown as Equipment[],
    [defaultEquipments]
  );

  const equipmentTypeArr = filters?.equipment.map((el) => el.equipment_type) || [];
  const defaultequipmentTypeArr = defaultEquipmentWithoutAdditionalInfo?.map((el) => el.equipment_type) || [];
  const isEquipmentFilterChanged = !equipmentTypeArr.every((el) => defaultequipmentTypeArr.includes(el));

  const isDeadHeadFilterChanged =
    filters.origin_range_distance !== DEFAULT_RANGE_DISTANCE ||
    filters.destination_range_distance !== DEFAULT_RANGE_DISTANCE;

  const isAvailabilityFilterChanged =
    filters.earliest !== DEFAULT_EARLIEST_AVAILABILITY || filters.latest !== DEFAULT_LATEST_AVAILABILITY;

  const equipmentTypeOptions = (equipmentQuery?.data || [])
    .map((item) => {
      if (item.machine_readable && equipmentTypeDefaultOptions.includes(item.machine_readable)) {
        return {
          value: item.machine_readable,
          label: item.name
        };
      }
    })
    .filter((item) => !!item);

  type FilterToDefaultValueMap = Partial<CapacitySearchFormValues>;

  const FILTER_FIELD_TO_DEFAULT_VALUES_MAP: FilterToDefaultValueMap = useMemo(
    () => ({
      status_in_network: true,
      status_out_network: true,
      source_dat: true,
      origin_range_distance: DEFAULT_RANGE_DISTANCE,
      destination_range_distance: DEFAULT_RANGE_DISTANCE,
      equipment: defaultEquipmentWithoutAdditionalInfo,
      age_limit_minutes: DEFAULT_AGE_LIMIT_MINUTES,
      earliest: DEFAULT_EARLIEST_AVAILABILITY,
      latest: DEFAULT_LATEST_AVAILABILITY
    }),
    [defaultEquipmentWithoutAdditionalInfo]
  );

  const setDefaultValue = (field: keyof FilterToDefaultValueMap) => {
    setFieldValue(field, FILTER_FIELD_TO_DEFAULT_VALUES_MAP[field]);
  };

  const numberOfFiltersActive = useMemo(() => {
    const filterKeys = Object.keys(filters) as unknown as (keyof FilterToDefaultValueMap)[];
    const changedFilters = filterKeys.filter((key) => {
      const hasDefaultValue = key in FILTER_FIELD_TO_DEFAULT_VALUES_MAP;
      let isCurrentlyUsingCustomValue =
        typeof filters[key] === 'object'
          ? JSON.stringify(filters[key]) !== JSON.stringify(FILTER_FIELD_TO_DEFAULT_VALUES_MAP[key])
          : filters[key] !== FILTER_FIELD_TO_DEFAULT_VALUES_MAP[key];

      if (key === 'equipment') {
        isCurrentlyUsingCustomValue = isEquipmentFilterChanged;
      }

      return hasDefaultValue && isCurrentlyUsingCustomValue;
    });

    return changedFilters.length;
  }, [FILTER_FIELD_TO_DEFAULT_VALUES_MAP, filters, isEquipmentFilterChanged]);

  return (
    <div className="flex items-center gap-3">
      <div>
        <span>{pluralize('Filter', numberOfFiltersActive, true)} Active</span>
      </div>
      <Dropdown alignEnd variant="icon" indicator={false} icon={<SvgIcon name="Filter" />}>
        {({onClick}: {onClick: () => void}) => (
          <div className="my-[-5px] w-[300px] border-r-[10px] border-sw-primary bg-sw-background p-0">
            <div className="flex items-center justify-between bg-sw-background-component pr-4">
              <span className="text-primary border-b-2 border-b-sw-primary bg-sw-active-light px-4 py-2">Filters</span>
              <SvgIcon name="Close" onClick={onClick} />
            </div>
            <div className="border-b-1 border-sw-border">
              <CardWrapper
                title="Network"
                showClearBtn={!filters.status_out_network || !filters.status_in_network}
                clearFilter={() => {
                  setDefaultValue('status_in_network');
                  setDefaultValue('status_out_network');
                }}
              >
                <Field
                  label="In Network"
                  name="status_in_network"
                  component={FormikCheckbox}
                  disabled={filters.status_in_network && !filters.status_out_network}
                />
                <Field
                  label="Out of Network"
                  name="status_out_network"
                  component={FormikCheckbox}
                  disabled={filters.status_out_network && !filters.status_in_network}
                />
              </CardWrapper>
              <CardWrapper
                title="Source"
                showClearBtn={!filters.source_dat}
                clearFilter={() => setDefaultValue('source_dat')}
              >
                {isDatIntegrated ? (
                  <Field label="DAT" name="source_dat" component={FormikCheckbox} />
                ) : (
                  <div className="flex items-center">
                    <Field label="DAT" name="source_dat" component={FormikCheckbox} disabled />
                    <Tooltip
                      portal
                      placement="top"
                      trigger="hover"
                      tooltipContent={
                        <div>
                          <div className="text-bold">DAT Capacity Search</div>
                          <div>
                            To view truck postings from DAT{' '}
                            <Link to="/company/integrations" activeClassName="active">
                              Connect DAT Capacity Search
                            </Link>
                          </div>
                        </div>
                      }
                      wrapperClassname="w-fit"
                      tooltipClassname="max-w-[200px]"
                    >
                      <SvgIcon className="ml-2" name="InfoOutlined" color="$sw-icon" />
                    </Tooltip>
                  </div>
                )}
              </CardWrapper>
              <CardWrapper
                title="Deadhead"
                showClearBtn={isDeadHeadFilterChanged}
                clearFilter={() => {
                  setDefaultValue('origin_range_distance');
                  setDefaultValue('destination_range_distance');
                }}
              >
                <div className="one-row-fields mb-3">
                  <div className="flex max-w-[40px] items-center justify-center border-1 border-r-0 border-sw-border bg-sw-background-component">
                    MI
                  </div>
                  <Field label="Origin (DH-O)" name="origin_range_distance" component={FormikTextInput} />
                </div>
                <div className="one-row-fields">
                  <div className="flex max-w-[40px] items-center justify-center border-1 border-r-0 border-sw-border bg-sw-background-component">
                    MI
                  </div>
                  <Field label="Destination (DH-D)" name="destination_range_distance" component={FormikTextInput} />
                </div>
              </CardWrapper>
              <CardWrapper
                title="Equipment"
                showClearBtn={isEquipmentFilterChanged}
                clearFilter={() => setDefaultValue('equipment')}
              >
                <Field
                  isMulti
                  label="Equipment"
                  name="equipment"
                  value={equipmentTypeOptions?.filter((val) => equipmentValues?.find((item) => item === val?.value))}
                  component={FormikSelect}
                  options={equipmentTypeOptions}
                  onChange={(arr: Array<{value: string; label: string}>) => {
                    const newValues = arr.map((item) => ({...equipmentData, equipment_type: item.value}));
                    setFieldValue('equipment', newValues);
                  }}
                />
              </CardWrapper>
              <CardWrapper
                title="Post Age"
                showClearBtn={Number(filters.age_limit_minutes) !== DEFAULT_AGE_LIMIT_MINUTES}
                clearFilter={() => setDefaultValue('age_limit_minutes')}
              >
                <Field label="Age Limit (Minutes)" name="age_limit_minutes" component={FormikTextInput} />
              </CardWrapper>
              <CardWrapper
                title="Availability"
                showClearBtn={isAvailabilityFilterChanged}
                clearFilter={() => {
                  setDefaultValue('earliest');
                  setDefaultValue('latest');
                }}
              >
                <Field
                  label="Earliest"
                  name="earliest"
                  component={FormikDateTimePicker}
                  defaultValue={new Date()}
                  className="mb-3"
                />
                <Field
                  label="Latest"
                  name="latest"
                  component={FormikDateTimePicker}
                  defaultValue={addDays(new Date(), 7)}
                />
              </CardWrapper>
            </div>
          </div>
        )}
      </Dropdown>
    </div>
  );
};

export default ShipmentSourceCapacityTableFilters;
