import {Ref, forwardRef} from 'react';
import {ReadbackTag} from 'App/components/TypedTable/complementaryComponents';
import {useGetShipmentTags} from 'App/data-hooks';

interface TagsReadbackProps {
  label: string;
  tags: string[];
  onClear: () => void;
  isHidden?: boolean;
}

export const TagsReadback = forwardRef<HTMLButtonElement, TagsReadbackProps>(
  ({label, tags, onClear, isHidden}, ref: Ref<HTMLButtonElement>) => {
    const {shipmentTagsData, shipmentTagsStatus} = useGetShipmentTags({});
    const tagsLabel = tags.map((tag) => shipmentTagsData?.find((tagData) => tagData.id === tag)?.name);
    return (
      <ReadbackTag
        label={shipmentTagsStatus === 'loading' ? 'Loading...' : `${label}: ${tagsLabel.join(', ')}`}
        onClick={onClear}
        isHidden={isHidden}
        ref={ref}
      />
    );
  }
);

TagsReadback.displayName = 'TagsReadback';
