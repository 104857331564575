import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * QuickBooks Invoice Mapping
 */
export async function getQuickbooksItemCategoryMapping(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksItemCategoriesMappingGet(opts, apiCallback(resolve, reject));
  });
}

export async function createQuickbooksItemCategoryMapping(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksItemCategoriesMappingPost(body, apiCallback(resolve, reject));
  });
}

export async function updateQuickbooksItemCategoryMapping(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksItemCategoriesMappingQuickbooksItemCategoryMappingIdPut(
      id,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteQuickbooksItemCategoryMapping(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksItemCategoriesMappingQuickbooksItemCategoryMappingIdDelete(
      id,
      apiCallback(resolve, reject)
    );
  });
}

export async function createQuickbooksItems() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksItemsPost(apiCallback(resolve, reject));
  });
}
