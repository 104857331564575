import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {DeprecatedButton} from '@shipwell/shipwell-ui';

const RequestBidsAction = ({router}) => {
  const handleRequestBidsClick = () => router.push(`/shipments/${router.params.shipment_id}/bids/?requestRates=true`);

  return (
    <DeprecatedButton variant="text" onClick={handleRequestBidsClick}>
      Request Bids
    </DeprecatedButton>
  );
};

RequestBidsAction.propTypes = {
  shipment: PropTypes.object,
  router: PropTypes.shape({
    push: PropTypes.func,
    params: PropTypes.shape({
      shipment_id: PropTypes.string
    })
  }).isRequired
};

RequestBidsAction.defaultProps = {
  shipment: {}
};

export default withRouter(RequestBidsAction);
