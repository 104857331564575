/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  CarrierRelationship,
  Shipment,
  TenderChargeLineItem,
  TenderCreate
} from '@shipwell/backend-core-singlerequestparam-sdk';

type TenderToCompany = {
  label: string;
  id: string;
  pocId: string;
  carrier: string;
  carrierName?: string;
  carrierStatus?: string;
  carrierFAVRid?: string;
  carrierRelationship: CarrierRelationship;
};

type TenderContractValues = {
  shipment: string;
  contract: string | null | undefined;
  charge_line_items?: TenderChargeLineItem[];
  expires_at?: string;
  info_message: string;
  message: string;
  mode: number | null | undefined;
  equipment_type: string | undefined;
  tender_to_company: TenderToCompany[];
};

export const getDefaultValueRate = (shipment: Shipment) => {
  if (shipment?.metadata && shipment.metadata?.buy_it_now_amount) {
    return Number(parseFloat(shipment.metadata.buy_it_now_amount.toString()).toFixed(2));
  }
  return 0;
};

export const getTenderPayload = (payload: TenderContractValues) => {
  //convert time to ISO
  if (payload && payload.expires_at) {
    const expireDate = new Date(payload.expires_at);
    payload.expires_at = expireDate.toISOString();
  }

  //Add all recipient users to their company's tender
  const recipientUsersCompanyObj =
    payload?.tender_to_company &&
    payload.tender_to_company.reduce((tenderRequest: {[key: string]: string[]}, carrierObj) => {
      if (!tenderRequest[carrierObj.carrier]) {
        tenderRequest[carrierObj.carrier] = [carrierObj.id];
      } else if (tenderRequest[carrierObj.carrier]) {
        tenderRequest[carrierObj.carrier].push(carrierObj.id);
      }
      return tenderRequest;
    }, {});

  const matchedArray: string[] = [];
  const tenderPayload: TenderCreate[] = [];
  payload?.tender_to_company &&
    payload.tender_to_company.forEach(({carrier}) => {
      //consolidate involved_tender_to_company_users under company tender, one tender per company
      if (recipientUsersCompanyObj[carrier] && !matchedArray.includes(carrier)) {
        const tenderValues = {...payload};
        matchedArray.push(carrier);
        tenderPayload.push({
          ...tenderValues,
          tender_to_company: carrier,
          involved_tender_to_company_users: recipientUsersCompanyObj[carrier]
        });
      }
    });

  return tenderPayload;
};
