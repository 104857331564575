import {integrations} from 'App/api';

/**
 * QuickBooks
 */
export function checkQuickBooksAuthentication() {
  return () => integrations.checkQuickBooksAuthentication();
}

export function authenticateQuickBooks(opts) {
  return () => integrations.authenticateQuickBooks(opts);
}

/**
 * TriumphPay
 */
export function checkTriumphPayAuthentication() {
  return () => integrations.checkTriumphPayAuthentication();
}

export function authenticateTriumphPay(opts) {
  return () => integrations.authenticateTriumphPay(opts);
}

export function getTriumphPayAuthenticationDetails() {
  return () => integrations.getTriumphPayAuthenticationDetails();
}

/**
 * RMIS
 */
export function checkRMISAuthentication() {
  return () => integrations.checkRMISAuthentication();
}
