import {UseQueryOptions} from '@tanstack/react-query';
import {
  PaginatedContract,
  ShipmentMode,
  ContractStatusEnum,
  StopLocation,
  ContractCriteriaByValueRequestModeEnum,
  ShipmentLineItem,
  TotalWeightOverride,
  EquipmentTypeValues,
  EquipmentType,
  ShipmentPreferredCurrencyEnum,
  ContractCriteriaByValueRequestCurrencyEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useGetApplicableContracts} from '../contracts/useGetApplicableContracts';

export const MAX_AMOUNT_OF_CONTRACTS_TO_DISPLAY = 100;

export type PricingIntelFormValues = {
  equipmentType?: EquipmentTypeValues;
  mode?: ShipmentMode;
  stops?: Array<Pick<StopLocation, 'address'>>;
};

export const useGetPIContracts = ({
  equipmentType,
  stops,
  pageSize = MAX_AMOUNT_OF_CONTRACTS_TO_DISPLAY,
  options,
  status = ContractStatusEnum.Active,
  mode = ContractCriteriaByValueRequestModeEnum.Ftl,
  lineItems,
  totalWeightOverride,
  currencyOfRecord
}: {
  equipmentType?: EquipmentType;
  stops?: Array<Pick<StopLocation, 'address'>>;
  pageSize?: number;
  options?: Pick<UseQueryOptions<PaginatedContract>, 'enabled'>;
  status?: ContractStatusEnum;
  mode?: ContractCriteriaByValueRequestModeEnum;
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
  currencyOfRecord?: ShipmentPreferredCurrencyEnum;
}) => {
  const stopAddresses = stops?.map((stop) => stop.address);
  const {enabled = true} = options || {};
  const {
    applicableContracts,
    applicableContractsQuery,
    contractsChargeItemsQuery,
    isFetchingApplicableContractsWithCharges
  } = useGetApplicableContracts({
    equipmentType: equipmentType?.machine_readable as EquipmentTypeValues | undefined,
    mode,
    stopAddresses,
    // It's okay to typecast here, contracts only support USD/CAD but passing it other currency values does no harm
    currencyOfRecord: currencyOfRecord as ContractCriteriaByValueRequestCurrencyEnum,
    options: {
      enabled
    },
    status,
    lineItems,
    totalWeightOverride
  });

  //to simulate a pageSize request since this used to be a list endpoint. Max results shown
  const slicedApplicableContracts = (applicableContracts || []).slice(0, pageSize);
  const slicedContracts = slicedApplicableContracts.map((applicableContract) => applicableContract.contract);

  return {
    applicableContracts: slicedApplicableContracts,
    contracts: slicedContracts,
    applicableContractsQuery,
    contractsChargeItemsQuery,
    isFetching: isFetchingApplicableContractsWithCharges
  };
};
