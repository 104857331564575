import {Dropdown, Button, Modal} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import {PurchaseOrder, PurchaseOrderOverallStatusEnum} from '@shipwell/backend-core-sdk';
import {compose} from 'recompose';
import pluralize from 'pluralize';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import useToggle from 'App/utils/hooks/useToggle';
import {
  CREATE_QUOTES_USER_PERMISSION,
  CREATE_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export enum BulkOrderActions {
  Quote = 'quote',
  Shipment = 'shipment',
  Optimize = 'optimize',
  MultipleShipments = 'multipleShipments',
  SplitOrder = 'splitOrder'
}

type SelectedPurchaseOrderActions = {
  handleModalDisplayLogic?: (creationType: BulkOrderActions) => void;
  handleArchivePurchaseOrders: () => void;
  selectedPurchaseOrders: Array<PurchaseOrder>;
  canCreateShipment?: boolean;
};
const ArchiveButton = ({handleArchivePurchaseOrders, selectedPurchaseOrders}: SelectedPurchaseOrderActions) => (
  <Button
    variant="secondary"
    onClick={() => handleArchivePurchaseOrders()}
    disabled={!selectedPurchaseOrders.length}
    size="sm"
  >
    Archive
  </Button>
);

export const SelectedPurchaseOrdersListActionsDropdown = ({
  handleModalDisplayLogic,
  handleArchivePurchaseOrders,
  selectedPurchaseOrders,
  canCreateShipment
}: SelectedPurchaseOrderActions) => {
  const [showArchivePurchaseOrderModal, toggleShowArchivePurchaseOrderModal] = useToggle();
  // only show split order option if order is unassigned and only one is selected
  const [selectedPurchaseOrder] = selectedPurchaseOrders;
  const showSplitOrderOption =
    selectedPurchaseOrders.length === 1 &&
    selectedPurchaseOrder.overall_status === PurchaseOrderOverallStatusEnum.Unassigned;

  return (
    <>
      <Dropdown title="Order Bulk Actions" className="ml-4">
        {() => [
          canCreateShipment ? (
            <>
              <li
                role="listitem"
                onClick={() =>
                  handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.Optimize) : undefined
                }
              >
                Optimize Orders
              </li>
              <PermissionsFallback permissions={[CREATE_QUOTES_USER_PERMISSION]}>
                <li
                  role="listitem"
                  onClick={() =>
                    handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.Quote) : undefined
                  }
                >
                  Create Quote
                </li>
              </PermissionsFallback>
              <PermissionsFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
                <li
                  role="listitem"
                  onClick={() =>
                    handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.Shipment) : undefined
                  }
                >
                  Create Shipment
                </li>
                <li
                  role="listitem"
                  onClick={() =>
                    handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.MultipleShipments) : undefined
                  }
                >
                  Create Multiple Shipments
                </li>
              </PermissionsFallback>
            </>
          ) : null,
          selectedPurchaseOrders.length > 0 ? (
            <li key="archive" role="listitem" onClick={toggleShowArchivePurchaseOrderModal}>
              Archive Orders
            </li>
          ) : null,
          showSplitOrderOption ? (
            <Link
              key="splitOrder"
              role="listitem"
              className="block text-sw-text"
              to={{
                pathname: `/purchase-orders/${selectedPurchaseOrder.id || ''}`,
                query: {splitOrder: 'true'}
              }}
            >
              Split Order
            </Link>
          ) : null
        ]}
      </Dropdown>
      <Modal
        show={showArchivePurchaseOrderModal}
        title="Archive Orders?"
        primaryBtnName="Archive"
        onClose={toggleShowArchivePurchaseOrderModal}
        onPrimaryAction={() => {
          handleArchivePurchaseOrders();
          toggleShowArchivePurchaseOrderModal();
        }}
      >
        <div className="font-bold">
          {`Are you sure you want to archive ${selectedPurchaseOrders.length}
          ${pluralize('order', selectedPurchaseOrders.length)}?`}
        </div>
        <div>Archived orders will not be shown on the dashboard unless you select the &#39;Archived&#39; filter.</div>
      </Modal>
    </>
  );
};

const CreateShipmentButtons = ({
  canCreateShipment,
  handleModalDisplayLogic,
  handleArchivePurchaseOrders,
  selectedPurchaseOrders
}: SelectedPurchaseOrderActions) => {
  return (
    <>
      <ArchiveButton
        handleArchivePurchaseOrders={handleArchivePurchaseOrders}
        selectedPurchaseOrders={selectedPurchaseOrders}
      />
      <Button
        variant="secondary"
        onClick={() => (handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.Quote) : null)}
        disabled={!canCreateShipment}
        size="sm"
      >
        Create Quote
      </Button>
      <Button
        variant="secondary"
        onClick={() => (handleModalDisplayLogic ? handleModalDisplayLogic(BulkOrderActions.Shipment) : null)}
        disabled={!canCreateShipment}
        size="sm"
      >
        Create Shipment
      </Button>
    </>
  );
};

export const SelectedPurchaseOrdersListHeaderActions = compose<
  SelectedPurchaseOrderActions,
  SelectedPurchaseOrderActions
>(
  withFlags('gaShipmentAssemblyMultiTruck'),
  withConditionalFallback(
    ({gaShipmentAssemblyMultiTruck}: {gaShipmentAssemblyMultiTruck: string}) => !gaShipmentAssemblyMultiTruck,
    CreateShipmentButtons
  )
)(SelectedPurchaseOrdersListActionsDropdown);
