import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {FacilityDocument, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {FACILITY_DOCUMENTS} from 'App/data-hooks/queryKeys';
import {createFacilityDocument, getFacilityDocuments, deleteFacilityDocument} from 'App/api/facilities';

export const useGetFacilityDocuments = (
  facilityId: string,
  queryOptions?: UseQueryOptions<FacilityDocument[], AxiosError<ShipwellApiErrorResponse>>
) => {
  const getFacilityDocumentsQuery = useQuery(
    [FACILITY_DOCUMENTS, facilityId] as QueryKey,
    async () => {
      const response = await getFacilityDocuments(facilityId);
      return response;
    },
    {enabled: !!facilityId, refetchOnWindowFocus: false, ...queryOptions}
  );
  return getFacilityDocumentsQuery;
};

export const useCreateFacilityDocument = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createFacilityDocument>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; file: File; document_type?: string; description?: string}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof createFacilityDocument>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; file: File; document_type?: string; description?: string}
  >(
    ({facilityId, file, document_type, description}) =>
      createFacilityDocument(facilityId, file, document_type, description),
    {onSettled: () => queryClient.invalidateQueries([FACILITY_DOCUMENTS]), ...mutationOptions}
  );
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useDeleteFacilityDocument = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFacilityDocument>>,
    AxiosError<ShipwellApiErrorResponse>,
    {documentId: string; facilityId: string}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof deleteFacilityDocument>>,
    AxiosError<ShipwellApiErrorResponse>,
    {documentId: string; facilityId: string}
  >(({documentId, facilityId}) => deleteFacilityDocument(documentId, facilityId), {
    onSettled: () => queryClient.invalidateQueries([FACILITY_DOCUMENTS]),
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};
