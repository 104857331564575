import {useMutation, useQueryClient} from '@tanstack/react-query';
import {SERVICE_PROVIDERS_QUERY_KEY} from '../queryKeys';
import {deleteServiceProvider} from 'App/api/serviceProviders/typed';

export const useDeleteServiceProvider = () => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(deleteServiceProvider, {
    onSettled: async () => {
      await queryClient.invalidateQueries([SERVICE_PROVIDERS_QUERY_KEY]);
    }
  });
  return {deleteServiceProvider: mutate, isDeletingServiceProviding: isLoading};
};
