import {useCallback, useMemo} from 'react';
import {IconButton} from '@shipwell/shipwell-ui';
import {monthDateYear} from 'App/utils/dateTimeGlobalsTyped';

export type WeekPaginatorProps = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

const WeekPaginator = ({selectedDate, setSelectedDate}: WeekPaginatorProps) => {
  const onClickPreviousWeek = useCallback(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() - 7);
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  const onClickNextWeek = useCallback(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + 7);
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  const sunday: Date = useMemo(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() - date.getDay());
    return date;
  }, [selectedDate]);

  const saturday: Date = useMemo(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + 6 - date.getDay());
    return date;
  }, [selectedDate]);

  return (
    <WeekPaginatorUI
      sunday={sunday}
      saturday={saturday}
      onClickPreviousWeek={onClickPreviousWeek}
      onClickNextWeek={onClickNextWeek}
    />
  );
};

const WeekPaginatorUI = (props: {
  sunday: Date;
  saturday: Date;
  onClickPreviousWeek: () => void;
  onClickNextWeek: () => void;
}) => {
  const {saturday, sunday, onClickPreviousWeek, onClickNextWeek} = props;
  const sundayStr = monthDateYear(sunday);
  const saturdayStr = monthDateYear(saturday);
  return (
    <div className="flex-0 sw-week-paginator flex flex-row flex-wrap content-center justify-between">
      {/* NOTE: adding `active:translate-x-0.5 active:translate-y-0.5` to className makes a nice click effect, consider adding it to <SVGIcon /> */}
      <IconButton iconName="ArrowSmallLeft" onClick={onClickPreviousWeek} aria-label={'Go To Previous Week'} />
      <span className="mt-1.5 flex flex-1 select-none">
        {sundayStr} - {saturdayStr}
      </span>
      <IconButton iconName="ArrowSmallRight" onClick={onClickNextWeek} aria-label={'Go To Next Week'} />
    </div>
  );
};

export default WeekPaginator;
