import {SidebarTabs, SidebarContent} from '@shipwell/shipwell-ui';
import {LegStageStageTypeEnum} from '@shipwell/corrogo-sdk';
import StagesView from 'App/containers/shipments/components/DashboardSummary/StagesView';

function DashboardSummary({
  onClose,
  shipmentId,
  resourceId
}: {
  onClose: () => void;
  shipmentId: string;
  resourceId: string;
}) {
  return (
    <SidebarTabs onClose={onClose} buttons={[{name: 'Stages', label: 'Stages'}]} tooltipPlacement="left">
      <SidebarContent onClose={onClose} title="Stages" action={null} fullWidth>
        <StagesView shipmentId={shipmentId} resourceId={resourceId} stageType={LegStageStageTypeEnum.Leg} />
      </SidebarContent>
    </SidebarTabs>
  );
}

export default DashboardSummary;
