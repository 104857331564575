import {Field, useFormikContext} from 'formik';
import {FormikTextInput, SvgIcon} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

export const DoeRateTableFormFields = () => {
  const {values} = useFormikContext<{
    calculation_rows: {fuel_price_lower_bound?: number; fuel_price_upper_bound?: number; fuel_surcharge?: number}[];
  }>();
  const calculationRows = values.calculation_rows;

  return (
    <FlexBox direction="col" gap="s">
      {calculationRows?.map((_, i) => (
        <FlexBox items="center" gap="s" key={i}>
          <Field
            label="Rate"
            className="w-full"
            name={`calculation_rows[${i}].fuel_price_lower_bound`}
            component={FormikTextInput}
            required
            disabled
          />
          <SvgIcon name="ArrowForward" className="min-w-[24px]" color="$sw-icon" width="24" />
          {i < calculationRows.length - 1 ? (
            <Field
              label={i < 1 ? 'Base Rate' : 'Rate'}
              className="w-full"
              name={`calculation_rows[${i}].fuel_price_upper_bound`}
              component={FormikTextInput}
              required
              disabled
            />
          ) : (
            <span className="flex w-full items-center justify-center font-bold text-sw-disabled-text">(and above)</span>
          )}
        </FlexBox>
      ))}
    </FlexBox>
  );
};
