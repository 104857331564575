/* eslint-disable react/prop-types */
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Field, FieldArray, reduxForm, change, destroy} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import EmailSearchField from 'App/formComponents/fields/emailSearch';
import * as addressBookActions from 'App/actions/addressBook';
import * as actions from 'App/actions/shipments';
import * as formActions from 'App/actions/forms';
import RenderStopsMap from 'App/components/RenderStopsMap';
import ShipmentStopsFields from 'App/formComponents/formSections/shipmentStopsFields';
import TerminalScheduleFields from 'App/formComponents/formSections/terminalScheduleFields';
import ShipmentPurchaseOrderConsolidationForm from 'App/formComponents/forms/shipmentPurchaseOrders';
import ShipmentAccessorialsField from 'App/formComponents/fields/accessorials';
import SuccessToast from 'App/components/successToast';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import './_stops.scss';
import {AddOrdersButton} from 'App/containers/orders/components/AddOrdersButton';
import withFlags from 'App/utils/withFlags';

@connect(
  (state) => ({
    company: state.auth.company,
    addressBook: state.addresses.addressbook,
    accessorials: state.shipments.accessorialCodes,
    hasPOUpdatePermission: state.auth.user.permissions && state.auth.user.permissions.includes('orders.update_orders'),
    selectedQuote: state.shipments.selectedQuote
  }),
  {...actions, ...addressBookActions, ...formActions}
)
export class StopsExpanded extends Component {
  constructor() {
    super();

    this.state = {
      showPurchaseOrdersModal: false,
      showPurchaseOrderSuccessToast: false
    };
  }

  componentWillUnmount() {
    const {form} = this.props;
    this.props.dispatch(destroy(form));
  }

  componentDidUpdate() {
    const {isConfirmationForm, form, dispatch, selectedQuote, formValues, titanDevDoNotUpdateDelDateOnLtlDispatch} =
      this.props;

    const shouldUpdateDeliveryDate =
      //change planned_date to the quote's delivery_date when doing a LTL or VLTL confirmation
      ['LTL', 'VLTL'].includes(formValues?.values?.mode?.[0]?.code) &&
      isConfirmationForm &&
      selectedQuote &&
      // TITAN-4011
      (!titanDevDoNotUpdateDelDateOnLtlDispatch || !formValues?.values?.stops?.at(-1).planned_date);

    if (shouldUpdateDeliveryDate) {
      const lastStop = formValues?.values?.stops[formValues?.values?.stops?.length - 1];
      const otherStops = formValues?.values?.stops.slice(0, formValues?.values?.stops?.length - 1);

      const updatedStops = [
        ...otherStops,
        {
          ...lastStop,
          planned_date: selectedQuote?.delivery_date || lastStop.planned_date
        }
      ];
      dispatch(change(form, 'stops', updatedStops));
    }
  }

  getLocationMarkers() {
    const {formValues = {}} = this.props;
    const markerArray = [];

    if (formValues.values && formValues.values.stops) {
      formValues.values.stops.forEach((stop, index) => {
        if (stop.location && stop.location.address) {
          const {latitude, longitude} = stop.location.address;

          if (latitude && longitude) {
            markerArray.push({
              show: true,
              position: {
                lat: Number(latitude),
                lng: Number(longitude)
              },
              info: `<p>${stop.location.address.formatted_address}</p>`,
              markerNumber: index + 1
            });
          }
        }
      });
    }
    return markerArray;
  }

  handlePurchaseOrdersModal() {
    this.setState({showPurchaseOrdersModal: !this.state.showPurchaseOrdersModal});
  }

  togglePurchaseSuccessToast() {
    this.setState({showPurchaseOrderSuccessToast: !this.state.showPurchaseOrderSuccessToast});
  }

  handleAddPurchaseOrderSuccess(values = {}) {
    const {form, onUpdateShipmentSuccess} = this.props;

    this.props.dispatch(change(form, 'stops', values.stops));
    this.props.dispatch(change(form, 'line_items', values.line_items));

    if (onUpdateShipmentSuccess) {
      this.props.onUpdateShipmentSuccess(values);
    }
  }

  handleAddPurchaseOrderFailure() {
    this.setState({
      showPurchaseOrdersModal: false,
      showPurchaseOrderSuccessToast: false
    });
  }

  render() {
    const {showPurchaseOrdersModal, showPurchaseOrderSuccessToast} = this.state;
    const {
      form,
      externalForm = false,
      isUpdateForm,
      isConfirmationForm,
      formValues = {},
      isDetailsForm,
      quote,
      hasPOUpdatePermission,
      customFields
    } = this.props;
    const {values = {}} = formValues;
    const showMap = !isUpdateForm;
    const markerArray = this.getLocationMarkers();

    const {hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal} = checkShipmentModes(values?.mode);

    return (
      <div id="pageFour" className="quote-flow quote-flow__page-4">
        <h2 className={`border-bottom ${isUpdateForm ? 'hidden' : ''}`}>Stops</h2>
        {values.id && hasPOUpdatePermission && !hasParcel ? (
          <div className="mt-2">
            <AddOrdersButton onClick={this.handlePurchaseOrdersModal.bind(this)}>Add Orders</AddOrdersButton>
          </div>
        ) : null}
        <Row>
          <Col xs={12} md={isUpdateForm || externalForm ? 12 : 8} style={{padding: 0}}>
            {hasDrayage && (
              <div className="terminal-schedule">
                <div className="shipment-stop-header">Terminal Schedule</div>
                <TerminalScheduleFields slimForm={false} form={form} />
              </div>
            )}
            <div className="quote-flow__stops-delivery">
              <FieldArray
                form={form}
                name="stops"
                component={ShipmentStopsFields}
                props={{
                  externalForm,
                  hasFTL,
                  hasLTL,
                  hasVLTL,
                  hasDrayage,
                  hasParcel,
                  hasIntermodal,
                  detailsForm: isDetailsForm,
                  isConfirmationForm,
                  quote,
                  customFields
                }}
              />
            </div>
            <Col xs={12} style={!externalForm ? {padding: '0 45px'} : {}}>
              <div id="js-scrollto-accessorials" className="quote-flow__stops-accessorials formstyle-light">
                <ShipmentAccessorialsField
                  dropup
                  label="Shipment Accessorials"
                  hasDrayage={hasDrayage}
                  hasParcel={hasParcel}
                />
              </div>
              {!isUpdateForm && !externalForm && !hasParcel && (
                <Field
                  allowNew
                  name="additional_bol_recipients"
                  label="Additional BOL Recipients"
                  placeholder="Type or select emails"
                  defaultValue={formValues && formValues.values && formValues.values.additional_bol_recipients}
                  component={EmailSearchField}
                />
              )}
            </Col>
          </Col>
          {showMap && !externalForm && (
            <Col xs={12} md={4}>
              <RenderStopsMap markerArray={markerArray} mapID="mapQuoting" />
            </Col>
          )}
        </Row>

        {values.id && (
          <ShipmentPurchaseOrderConsolidationForm
            show={showPurchaseOrdersModal}
            onHide={this.handlePurchaseOrdersModal.bind(this)}
            shipment={values}
            onUpdateFailure={this.handleAddPurchaseOrderFailure.bind(this)}
            onUpdateSuccess={this.handleAddPurchaseOrderSuccess.bind(this)}
            dispatch={this.props.dispatch}
            setAddOrderSuccess={this.props.setAddOrderSuccess}
            setAddOrderError={this.props.setAddOrderError}
          />
        )}

        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={showPurchaseOrderSuccessToast}
          autoHideDuration={2000}
          onClose={this.togglePurchaseSuccessToast.bind(this)}
        >
          <SuccessToast title="Orders Added to Shipment!" onClose={this.togglePurchaseSuccessToast.bind(this)}>
            Communicate these changes with your carrier
            <br /> to ensure all items are shipped.
          </SuccessToast>
        </Snackbar>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  }),
  withFlags('titanDevDoNotUpdateDelDateOnLtlDispatch')
)(StopsExpanded);
