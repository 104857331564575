import {DeprecatedButton, FormikDateTimePicker, FormikTextInput, FormikTextarea, SvgIcon} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {useEffect} from 'react';
import {mixed, object} from 'yup';
import {
  Shipment,
  TendersApiTendersPostRequest,
  ShipmentPreferredCurrencyEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ContractsDetails} from '../tendering/ContractsDetails';
import {FlexBox} from 'App/components/Box';
import {validateDateTimeNotInPast} from 'App/utils/dateTimeGlobals';
import {useTenderContract} from 'App/data-hooks';
import {getTenderPayload} from 'App/formComponents/forms/tenderToSpecificCarrier/utils';
import {ApplicableContractWithCharges} from 'App/data-hooks/contracts/useGetApplicableContracts';
import {getChargeItemsTotals} from 'App/data-hooks/contracts/utils';

export type TenderToSpecificCarrierFormValues = {
  contract: string;
  carrier: string;
  rate_type: string;
  rate: number;
  expires_at: string;
  info_message: string;
};

const contractTenderRequestSchema = object().shape({
  expires_at: mixed().test({
    name: 'date not in the past',
    message: `Expiration date must not be in the past`,
    test: (value) => {
      if (value) {
        return validateDateTimeNotInPast(value);
      }
      return true;
    }
  })
});

const TenderToSpecificCarrierForm = ({
  onClose,
  applicableContract,
  shipment,
  handleTenderToCarrier
}: {
  onClose: () => void;
  applicableContract?: ApplicableContractWithCharges;
  shipment: Shipment;
  handleTenderToCarrier: (
    payload: TendersApiTendersPostRequest,
    setSubmitting: FormikHelpers<TenderToSpecificCarrierFormValues>['setSubmitting']
  ) => void;
}) => {
  const {contract, charges, contract_lane: contractLane} = applicableContract || {};
  const {carrierCapacityIsAvailable, handleContractChange, getContractValues} = useTenderContract({
    shipment
  });

  const {total, currency} = getChargeItemsTotals(charges);

  useEffect(() => {
    if (contract) {
      handleContractChange(contract);
    }
  }, [contract, handleContractChange]);

  const initialValues = {
    contract: contract?.name || '',
    carrier: contract?.carrier_name || '',
    rate_type: contractLane?.rate_info?.rate_type || contract?.rate_type || '',
    rate: +total.toFixed(2) || 0,
    expires_at: '',
    info_message: shipment?.notes_for_carrier || ''
  };

  const handleSubmit = (
    values: TenderToSpecificCarrierFormValues,
    setSubmitting: FormikHelpers<TenderToSpecificCarrierFormValues>['setSubmitting']
  ) => {
    const contractValues = {
      ...getContractValues({
        expires_at: values.expires_at,
        info_message: values.info_message,
        contractRateCurrency: currency as ShipmentPreferredCurrencyEnum
      }),
      shipment: shipment.id
    };

    const payload = getTenderPayload(contractValues);

    payload.forEach((tender) => handleTenderToCarrier({tenderCreate: tender}, setSubmitting));
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contractTenderRequestSchema}
      onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
      enableReinitialize
    >
      {({isSubmitting}) => (
        <Form>
          <>
            <div className="rounded-t border-1 border-b-0 border-sw-border p-4 font-bold">Tender</div>
            <div className="mb-6 rounded-b border-1 border-sw-border">
              <div className="flex flex-col gap-4 p-4">
                {!carrierCapacityIsAvailable ? (
                  <div className="flex rounded-md bg-sw-error-background p-2 pl-0 text-sw-error">
                    <SvgIcon width="3rem" name="InfoFilled" />
                    <span className="font-bold text-sw-text">
                      <div className="ml-2">This carrier is at capacity and can&apos;t accept any more tenders.</div>
                    </span>
                  </div>
                ) : null}
                <Field required label="Tender From Contract" name="contract" component={FormikTextInput} disabled />
                <ContractsDetails
                  carrierCapacityIsAvailable={carrierCapacityIsAvailable}
                  selectedApplicableContract={applicableContract}
                  modes={[shipment.mode?.code || '--']}
                  equipment={[shipment.equipment_type?.name || '--']}
                  shipmentId={shipment.id}
                />
                <Field label="Expiration" name="expires_at" component={FormikDateTimePicker} />
                <div>
                  <Field label="Notes for Carriers" name="info_message" component={FormikTextarea} />
                  <span className="text-[9px] uppercase text-sw-disabled-text">
                    <span className="font-meduim">Shift + Return</span> to add a new line
                  </span>
                </div>
              </div>
            </div>
            <FlexBox direction="row" justify="end" gap="m">
              <DeprecatedButton variant="secondary" onClick={onClose}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" disabled={!carrierCapacityIsAvailable || isSubmitting}>
                Save
              </DeprecatedButton>
            </FlexBox>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default TenderToSpecificCarrierForm;
