import {Title, SvgIcon} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {startCaseToLower} from 'App/utils/startCaseToLower';

export const WorkflowStatus = ({color, icon, status}) => {
  const shouldSpin = icon === 'Running';
  return (
    <div className="text-right">
      <div className={`text- flex gap-2${color} items-center justify-end `}>
        <Title>{startCaseToLower(status)}</Title>
        <SvgIcon className={classNames({'animate-slow-spin': shouldSpin})} name={icon} />
      </div>
    </div>
  );
};

WorkflowStatus.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  status: PropTypes.string
};
