import {useFlags} from 'launchdarkly-react-client-sdk';
import {ReactNode} from 'react';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback/PermissionsFallback';

/**
 * This is a temporary component that extends PermissionsFallback, and returns the children
 * if krknTenderingUserPermissions is off. This component will be removed with KRKN-458
 */
export const TenderingUserPermissionFallback = ({
  children,
  permissions
}: {
  children: ReactNode;
  permissions: string[];
}) => {
  const {krknTenderingUserPermissions} = useFlags();
  if (!krknTenderingUserPermissions) {
    return <>{children}</>;
  }
  return (
    <PermissionsFallback permissions={permissions} fallbackComponent={null}>
      {children}
    </PermissionsFallback>
  );
};
