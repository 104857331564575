import {ShipmentAuctionStats} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {AUCTION_STATS_KEY} from 'App/data-hooks/queryKeys';
import {AuctionStatsCell as AuctionStatsCellLegacy} from 'App/containers/Dashboard/components/Columns/AuctionStatsCell';

export const AuctionStatsCell = ({shipmentId}: {shipmentId: string}) => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching([AUCTION_STATS_KEY, 'list']) > 0;

  // this function is reaching into the query cache and grabbing the proper data based on shipmentId
  // this allows us to make one call at the table level instead of a call for every one of these cells rendered
  const getAuctionStatsFromListQuery = () => {
    if (!shipmentId) {
      return;
    }
    const auctionStatsQueries = queryClient.getQueriesData([AUCTION_STATS_KEY, 'list']);
    const auctionStatsByShipmentId = auctionStatsQueries.reduce(
      (final: ShipmentAuctionStats | undefined, statsQuery) => {
        const [, queryData] = statsQuery;
        const typedQueryData = queryData as ShipmentAuctionStats[] | undefined;
        if (!typedQueryData?.length) {
          return;
        }
        const stats = typedQueryData.find((typedData) => typedData.shipment_id === shipmentId);
        if (stats) {
          return (final = stats);
        }
        return final;
      },
      {}
    );
    return auctionStatsByShipmentId;
  };

  const getAuctionStatsObj = () => {
    const stats = getAuctionStatsFromListQuery()?.auction_stats;
    return {
      visible_companies: stats?.visible_companies || 0,
      messages: stats?.messages || 0,
      quotes: stats?.quotes || 0,
      has_open_auction: stats?.has_open_auction
    };
  };

  const auctionStats = getAuctionStatsObj();

  return (
    <div className="relative">
      {isFetching ? (
        <div className="absolute inset-0 flex w-full items-center justify-center">
          <SvgIcon name="LoadingDots" />
        </div>
      ) : null}
      <AuctionStatsCellLegacy
        shipmentId={shipmentId}
        hasOpenAuction={auctionStats.has_open_auction}
        auctionStats={auctionStats}
      />
    </div>
  );
};
