import {SvgIcon} from '@shipwell/shipwell-ui';

const OriginAndDestinationBanner = ({origin, destination}: {origin: string; destination: string}) => (
  <h4 className="m-0 flex max-h-[50px] items-center justify-evenly bg-sw-overlay-background">
    <span className="p-2">{origin}</span>
    <SvgIcon height="80" width="80" color="white" name="ArrowLargeRight" />
    <span className="p-2">{destination} </span>
  </h4>
);

export default OriginAndDestinationBanner;
