import {
  FormikCheckbox,
  FormikPhoneInput,
  FormikTextInput,
  SvgIcon,
  Card,
  IconButtonWithTooltip
} from '@shipwell/shipwell-ui';
import {Field} from 'formik';

export const SupplierUserFields = ({index}: {index: number}) => (
  <div className="grid gap-4">
    <Field
      name={`contacts[${index}].full_name`}
      data-new-supplier-contact-name
      label="Contact Name"
      component={FormikTextInput}
      required
    />
    <div className="grid grid-cols-2 gap-4">
      <Field name={`contacts[${index}].phone_number`} label="Phone Number" component={FormikPhoneInput} />
      <Field
        prepend={<SvgIcon name="EmailOutlined" />}
        name={`contacts[${index}].email`}
        label="Email"
        component={FormikTextInput}
        required
      />
    </div>
    <Field
      name={`contacts[${index}].should_send_notifications`}
      label="Receive order-related emails/notifications"
      component={FormikCheckbox}
    />
  </div>
);

export const SupplierUserFieldsCard = ({index, onDelete}: {index: number; onDelete?: (() => void) | null}) => (
  <Card
    draggableProvided={false}
    title={`Contact ${index + 1}`}
    actions={
      onDelete ? (
        <IconButtonWithTooltip
          aria-label="Remove this contact"
          tooltipPlacement="left"
          iconName="TrashOutlined"
          onClick={onDelete}
        />
      ) : null
    }
  >
    <SupplierUserFields index={index} />
  </Card>
);
