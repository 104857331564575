import {Component, Fragment} from 'react';
import {defer, get, set} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {bindActionCreators} from 'redux';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import ShipmentReferencesUpdateForm from './forms/ShipmentReferencesUpdateForm';
import {bind} from 'App/utils/camelize';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import {unpackErrors} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {getReadableCreatedByString} from 'App/utils/createdBy';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export class ShipmentReferences extends Component {
  constructor(props) {
    super(props);

    bind(this, ['cancelEdit', 'deleteRep', 'editReferences', 'handleFormSubmit', 'renderEditReferences', 'updated']);

    this.state = {
      editReferences: false
    };
  }

  cancelEdit() {
    this.setState({
      editReferences: false
    });
  }

  updated(shipmentId) {
    this.props.fetchReps(shipmentId);
    this.cancelEdit();
    defer(() => {
      this.props.fetchDetails(this.props.one.id);
    });
  }

  editReferences() {
    this.setState({
      editReferences: !this.state.editReferences
    });
  }

  handleFormSubmit() {
    const attrs = JSON.parse(JSON.stringify(this.props.shipmentReferencesUpdateForm.values));
    const shipmentId = this.props.shipmentId;
    let shipmentObj = this.props.one;

    shipmentObj = Object.assign(shipmentObj, attrs);

    return this.props.shipmentsShipmentIdPut(shipmentId, shipmentObj, {}).then((response) => {
      if (response.status === 200) {
        this.updateReps(attrs.reps);
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  updateReps(reps) {
    const shipmentId = this.props.shipmentId;

    // PUT existing fields and POST new ones
    if (reps && reps.length > 0) {
      reps.forEach((rep) => {
        if (rep.id && rep.role) {
          this.props
            .putRep(shipmentId, rep.id, {
              role: Number(rep.role),
              user: rep.user
            })
            .then((response) => {
              if (response.status === 200) {
                this.updated(shipmentId);
              }
            });
        } else {
          // POST rep
          this.props.postRep(shipmentId, rep).then((response) => {
            if (response.status === 200) {
              this.updated(shipmentId);
            }
          });
        }
      });
    } else {
      this.updated(shipmentId);
    }
  }

  deleteRep(repId) {
    this.props.deleteRep(this.props.shipmentId, repId).then((response) => {
      if (response.status === 200) {
        //do nothing
      }
    });
  }

  renderEditReferences() {
    const {one} = this.props;
    const {hasDrayage} = checkShipmentModes(one.mode);
    const createdBy = getReadableCreatedByString(one?.created_by_user, one?.created_by_source);

    return (
      <ShipmentReferencesUpdateForm
        hasDrayage={hasDrayage}
        cancelEdit={this.updated.bind(this, this.props.shipmentId)}
        deleteRep={this.deleteRep}
        onSubmit={this.handleFormSubmit}
        shipmentCustomData={one?.custom_data}
        customFields={this.props.customFields}
        createdBy={createdBy}
        carrier={one?.relationship_to_vendor?.vendor}
      />
    );
  }

  render() {
    const {one, reps, purchaseOrders, company, customFields = []} = this.props;
    if (!one) {
      return null;
    }
    const perms = this.props.user ? this.props.user.permissions : [];
    const canEdit =
      perms &&
      (perms.includes(UPDATE_SHIPMENTS_USER_PERMISSION) || perms.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
      !this.props.is_quoting_limited_user
        ? true
        : false;
    const {hasDrayage, hasParcel} = checkShipmentModes(one.mode);
    const createdBy = getReadableCreatedByString(one?.created_by_user, one?.created_by_source);

    return (
      <div className="shipment__references">
        <h2 className="shipment__references--title">
          <span>References</span>
          {canEdit && (
            <Link className="btn-action" onClick={() => this.editReferences()}>
              <i className="flaticon-edit action-link" title="edit" />
            </Link>
          )}
        </h2>

        {this.state.editReferences && <div className="form-update-references">{this.renderEditReferences()}</div>}
        <div className={`${this.state.editReferences ? 'hide' : 'show lineitem-col--readonly'}`}>
          {this.props.showCustomerField && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Customer</span>
              <p>{one.relationship_to_customer && one.relationship_to_customer.customer.name}</p>
            </div>
          )}

          <div className="shipmentDetails__row">
            <span className="shipmentDetails__row-title">BOL #</span>
            <p>{one.bol_number ? one.bol_number : '--'}</p>
          </div>

          <div className="shipmentDetails__row">
            <span className="shipmentDetails__row-title">PRO #</span>
            <p>{one.pro_number ? one.pro_number : '--'}</p>
          </div>

          <div className="shipmentDetails__row">
            <span className="shipmentDetails__row-title">Pickup #</span>
            <p>{one.pickup_number ? one.pickup_number : '--'}</p>
          </div>
          <div className="shipmentDetails__row">
            <span className="shipmentDetails__row-title">PO #</span>
            <p>{one.purchase_order_number ? one.purchase_order_number : '--'}</p>
          </div>
          {hasDrayage && (
            <Fragment>
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Booking #</span>
                <p>{one.drayage_booking_number ? one.drayage_booking_number : '--'}</p>
              </div>
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Container #</span>
                <p>{one.drayage_container_number ? one.drayage_container_number : '--'}</p>
              </div>
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Seal #</span>
                <p>{one.drayage_seal_number ? one.drayage_seal_number : '--'}</p>
              </div>
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Chassis #</span>
                <p>{one.drayage_chassis_number ? one.drayage_chassis_number : '--'}</p>
              </div>
            </Fragment>
          )}
          {purchaseOrders && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Order #</span>
              <p>
                {purchaseOrders.map((order, i) => (
                  <Fragment key={order.id}>
                    {order.company === company.id ? (
                      <Link to={`/purchase-orders/${order.id}`}>{order.order_number}</Link>
                    ) : (
                      order.order_number
                    )}
                    {i === purchaseOrders.length - 1 ? '' : ', '}
                  </Fragment>
                ))}
              </p>
            </div>
          )}
          {hasParcel && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Tracking #</span>
              <p>{one.tracking_number || '--'}</p>
            </div>
          )}
          <div className="shipmentDetails__row">
            <span className="shipmentDetails__row-title">Customer Reference #</span>
            <p>{one.customer_reference_number ? one.customer_reference_number : '--'}</p>
          </div>
          {one.relationship_to_vendor?.vendor && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Carrier Reference Code #</span>
              <p>{one.carrier_reference_code ? one.carrier_reference_code : '--'}</p> {/* todo update field*/}
            </div>
          )}

          {customFields.map((cf) => {
            const value = get(
              one,
              `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`,
              null
            );
            return (
              <div className="shipmentDetails__row shipment__references-custom" key={cf.id}>
                <span className="shipmentDetails__row-title">{cf.label}</span>
                <p>{value || '--'}</p>
              </div>
            );
          })}

          {createdBy && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Created By</span>
              <p>{createdBy}</p>
            </div>
          )}

          {this.props.showShipmentReps === true && (
            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Shipment Reps</span>
              {reps && reps.results.length > 0 ? (
                <div>
                  {reps.results.map((rep, i) => {
                    return (
                      <p key={i}>
                        <b>{rep.role_name ? rep.role_name + ': ' : ''}</b>
                        {rep.display_name}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <p>--</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const ShipmentReferencesWithCustomFields = withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment)(
  ShipmentReferences
);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.auth.company,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    reps: state.shipmentdetails.reps,
    shipmentReferencesUpdateForm: state.form.shipmentReferencesUpdateForm,
    shipmentRepRoles: state.shipmentdetails.shipmentRepRoles,
    companyUsers: state.users.companyUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...shipmentdetailsActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentReferencesWithCustomFields);
