import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import get from 'lodash/get';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {shipmentsShipmentIdPut} from 'App/actions/shipmentdetails';
import withStatusToasts from 'App/components/withStatusToasts';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const AddToLoadBoardAction = ({
  shipment,
  canEdit,
  featureFlags,
  onUpdateShipment,
  shipmentsShipmentIdPut,
  setError
}) => {
  const [changingLoadBoardStatus, setChangingLoadBoardStatus] = useState(false);
  const {load_board_enabled} = get(shipment, 'metadata', false);
  const statusCanBeAddedToLoadBoard = ![
    'tendered',
    'dispatched',
    'at_pickup',
    'in_transit',
    'delivered',
    'carrier_confirmed',
    'reconciled',
    'cancelled'
  ].includes(get(shipment, 'state', ''));

  const handleAddToLoadBoardClick = async () => {
    setChangingLoadBoardStatus(true);
    try {
      const payload = {
        ...shipment,
        metadata: {
          ...shipment.metadata,
          load_board_enabled: !load_board_enabled
        }
      };

      const response = await shipmentsShipmentIdPut(shipment.id, payload);
      onUpdateShipment(response);
    } catch (e) {
      const message = `Error changing load board status for ${shipment.id}`;
      console.error(message, e);
      setError('Error!', message);
    } finally {
      setChangingLoadBoardStatus(false);
    }
  };

  const canRemoveFromLoadboard = load_board_enabled;
  const canAddToLoadBoard = !load_board_enabled && statusCanBeAddedToLoadBoard;
  const label = `${load_board_enabled ? 'Remove from' : 'Add to'} Load Board`;

  return (
    canEdit &&
    featureFlags.load_board_enabled &&
    (canRemoveFromLoadboard || canAddToLoadBoard) && (
      <DeprecatedButton
        disabled={changingLoadBoardStatus}
        variant="text"
        onClick={changingLoadBoardStatus ? undefined : handleAddToLoadBoardClick}
      >
        {label}
      </DeprecatedButton>
    )
  );
};

AddToLoadBoardAction.propTypes = {
  shipment: PropTypes.object,
  canEdit: PropTypes.bool,
  featureFlags: PropTypes.object,
  onUpdateShipment: PropTypes.func,
  shipmentsShipmentIdPut: PropTypes.func
};

AddToLoadBoardAction.defaultProps = {
  shipment: {},
  canEdit: false,
  featureFlags: {},
  onUpdateShipment: () => {},
  shipmentsShipmentIdPut: () => {}
};

export default compose(
  withStatusToasts,
  connect(
    (state) => ({
      canEdit:
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION),
      featureFlags: get(state, 'userCompany.company.feature_flags', {})
    }),
    (dispatch) =>
      bindActionCreators(
        {
          shipmentsShipmentIdPut
        },
        dispatch
      )
  )
)(AddToLoadBoardAction);
