import {RegularSelect} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {AvailableDeviceSelectValue} from './availableDeviceSelectValue';
import {getTiveDevices} from 'App/utils/integrations/tive/tive';

export const AVAILABLE_DEVICE_SELECT_QUERY_KEY = 'AVAILABLE_DEVICE_SELECT_QUERY_KEY';

export interface AvailableDevicesSelectProps {
  name: string;
  value: string;
  error?: string;
  onChange: (value: string | null) => void;
}

export function AvailableDeviceSelect(props: AvailableDevicesSelectProps) {
  const listQuery = useQuery({
    queryKey: [AVAILABLE_DEVICE_SELECT_QUERY_KEY],
    queryFn: async () => {
      const items = await getTiveDevices();
      return items.map<AvailableDeviceSelectValue>((item) => ({
        label: item.device_name || item.device_id,
        value: item.device_id
      }));
    }
  });

  const selectedValue = useMemo(() => {
    return listQuery.data?.find((item) => item.value === props.value) || null;
  }, [listQuery.data, props.value]);

  return (
    <RegularSelect
      label="Device ID*"
      name={props.name}
      value={selectedValue}
      isLoading={listQuery.isFetching}
      error={props.error}
      options={listQuery.data}
      onChange={(newValue) => props.onChange(newValue?.value || null)}
    />
  );
}
