import PropTypes from 'prop-types';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import WithStatusToasts from 'App/components/withStatusToasts';
import {createBulkOperation} from 'App/api/bulkOperations';

const CheckCall = ({shipments, setError, onSuccess, onClose}) => {
  const performCheckCall = async () => {
    try {
      const bulkOperationBody = {
        resource_type: 'shipment',
        workflow: {
          name: 'Bulk op',
          start_events: [
            {
              step_id: 'STEP_1',
              trigger: {
                trigger_type: 'NONE',
                outputs: [
                  {name: 'shipment_id', data_type: 'String', push_to_list: null},
                  {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                ]
              },
              next_step: {
                step_id: 'STEP_2'
              }
            }
          ],
          end_events: [
            {
              step_id: 'STEP_END',
              should_terminate: true
            }
          ],
          actions: [
            {
              action_id: 'INITIATE_CHECK_CALL',
              step_id: 'STEP_2',
              inputs: [
                {
                  name: 'shipment_id',
                  data_type: 'String',
                  is_required: true
                }
              ]
            }
          ]
        },
        criteria: {
          id: {
            $in: shipments
          }
        }
      };
      const response = await createBulkOperation(bulkOperationBody);
      onSuccess(response.data.id);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  return (
    <div>
      <div className="font-bold">Are you sure you want to make a check call?</div>
      <div>These calls will be sent to the driver phone number associated with each shipment if it is available.</div>

      <div className="mt-4 flex justify-between">
        <DeprecatedButton variant="secondary" onClick={onClose}>
          Cancel
        </DeprecatedButton>
        <DeprecatedButton variant="primary" onClick={performCheckCall}>
          Make Check Call
        </DeprecatedButton>
      </div>
    </div>
  );
};

CheckCall.propTypes = {
  shipments: PropTypes.array,
  setError: PropTypes.func,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
};

export default WithStatusToasts(CheckCall);
