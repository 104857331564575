import Toggle from 'react-bootstrap-toggle';
import {FormGroup, OverlayTrigger, Tooltip, ControlLabel} from 'react-bootstrap';
const renderToggle = ({
  input,
  defaultValue,
  active,
  label,
  extraClass,
  on,
  off,
  disabled,
  height,
  width,
  meta: {touched, error}
}) => (
  <FormGroup className={extraClass} validationState={touched && error ? 'error' : null}>
    {label && <ControlLabel className="pad-right">{label}</ControlLabel>}

    <Toggle
      disabled={disabled}
      {...input}
      on={<p style={{margin: '0px', fontSize: '14px'}}>{on ? on : 'Yes'}</p>}
      off={<p style={{margin: '0px', fontSize: '14px'}}>{off ? off : 'No'}</p>}
      size="md"
      active={input.value !== '' ? input.value : defaultValue}
      value={input.value !== '' ? input.value : defaultValue}
      height={height ? height : null}
      width={width ? width : null}
      onClick={() => {
        if (input.value) {
          input.onChange(!input.value);
        } else {
          input.onChange(!active);
        }
      }}
      recalculateOnResize
    />
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderToggle;
