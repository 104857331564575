import {useState} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import resolveConfig from 'tailwindcss/resolveConfig';
// There's got to be a better way to import this?
import tailwindConfig from '../../../../../../tailwind.config';
import {RateTableOverview} from '../../contractFields/RateTable/RateTableOverview';
import {getContracts} from 'App/api/contracts/typed';
import {formatCurrency} from 'App/utils/internationalConstants';
import {formatCarrierPOCs} from 'App/utils/carrierHelpers';
import {getCarrierRelationshipsCarrierRelationshipIdPromise} from 'App/api/carriers';
import {
  RATE_TYPE_PER_MILE,
  RATE_TYPE_FLAT_RATE,
  RATE_TYPE_RATE_TABLE
} from 'App/containers/userCompany/rateTables/constants';
import getTimeOptions from 'App/utils/getTimeOptions';
import './styles.scss';
import {getCarrierWithDataMetrics} from 'App/api/rfpOptimized/typed';
import {getCarrierPocs} from 'App/containers/userCompany/rfps/utils/carriers';
import {CarrierOption} from 'App/containers/userCompany/rfps/ActionButton';
import {useGetContract} from 'App/data-hooks/contracts/useGetContract';
import {useGetApplicableContracts} from 'App/data-hooks/contracts/useGetApplicableContracts';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';

const fullConfig = resolveConfig(tailwindConfig);
const errorColor = fullConfig.theme.colors['sw-error'];
const errorHoverColor = fullConfig.theme.colors['sw-error-dark'];
const invertTextColor = fullConfig.theme.colors['sw-text-reverse'];

const parameterizePolicyDetails = (policy) => {
  const policyObj = {};
  policyObj.modes = policy.modes.map((e) => e.code);
  policyObj.equipmentTypes = policy.equipment_types.map((e) => e.machine_readable);
  policyObj.originsContains = policy.origins.map((e) => e.formatted_address);
  policyObj.destinationsContains = policy.destinations.map((e) => e.formatted_address);
  policyObj.stopAddresses = [policy.origins?.[0], policy.destinations?.[0]];
  policyObj.equipmentType = policy.equipment_types?.[0]?.machine_readable;
  policyObj.mode = policy.modes?.[0]?.code;
  return policyObj;
};

const TenderFields = (props) => {
  const {index, modes, equipmentTypes, selectedPolicy} = props;
  const [selectedContract, setSelectedContract] = useState({});

  const internationalPreferences = useGetInternationalPreferences();
  const preferredCurrency = internationalPreferences?.data?.currency;

  const {applicableContracts, applicableContractsQuery} = useGetApplicableContracts(
    parameterizePolicyDetails(selectedPolicy)
  );
  const contracts = applicableContracts?.map((applicableContract) => applicableContract.contract) || [];

  const {setFieldValue, values, errors, touched, setFieldTouched} = useFormikContext();
  const {isLoading: isLoadingSelectedContract} = useGetContract({
    contractId: values?.actions?.[index]?.contract_id?.value,
    options: {
      onSuccess: (contract) => {
        setSelectedContract(contract);
        setFieldValue(`actions[${index}].rate_type`, contract.rate_type);
        setFieldValue(`actions[${index}].rate`, contract.rate);
      }
    }
  });

  const isRateTable = selectedContract.rate_type === RATE_TYPE_RATE_TABLE;

  const formatOptionLabel = (contract, {context}) => {
    //format the label differently when selected value vs in menu
    if (context === 'value') {
      return contract.name;
    }
    let contractDetailString =
      contract.rate_type === RATE_TYPE_RATE_TABLE
        ? 'Rate Table'
        : `${formatCurrency(contract.rate, contract.rate_currency)} ${
            contract.rate_type === RATE_TYPE_PER_MILE ? '/mile' : ''
          }`;
    contractDetailString += ` · ${contract.carrier_name}`;
    if (contract.origins && (contract.origins.length > 0 || contract.destinations.length > 0)) {
      contractDetailString += ` · `;
      if (contract.origins.length > 0) {
        contractDetailString += contract.origins.map((loc) => loc.formatted_address).join(', ');
      } else {
        contractDetailString += '--';
      }
      contractDetailString += ' > ';
      if (contract.destinations && contract.destinations.length > 0) {
        contractDetailString += contract.destinations.map((loc) => loc.formatted_address).join(', ');
      } else {
        contractDetailString += '--';
      }
    }
    if (contract.modes && contract.modes.length > 0) {
      contractDetailString += ` · `;
      contractDetailString += contract.modes.map((mode) => mode.code).join(', ');
    }
    if (contract.equipment_types && contract.equipment_types.length > 0) {
      contractDetailString += ` · `;
      contractDetailString += contract.equipment_types.map((mode) => mode.name).join(', ');
    }
    if (contract.service_level_agreement_name) {
      contractDetailString += ` · `;
      contractDetailString += contract.service_level_agreement_name;
    }
    return (
      <div className="contract__option">
        <div className="contract__option-name">{contract.name}</div>
        <div className="contract__option-details">{contractDetailString}</div>
      </div>
    );
  };

  const populateActionFromContract = async (contract, index) => {
    if (contract) {
      setSelectedContract(contract);
      try {
        //get all POCs from the contract carrier to add as involved_users
        const response = await getCarrierRelationshipsCarrierRelationshipIdPromise(contract.carrier_relationship);
        if (response && response.body) {
          const formattedPOCs = formatCarrierPOCs([response.body]);
          const formValues = {...values.actions[index]};
          formValues.contract_id = {name: contract.name, value: contract.id};
          formValues.rate = contract.rate;
          formValues.rate_currency = contract.rate_currency;
          formValues.rate_type = contract.rate_type;
          if (contract.modes.length) {
            formValues.mode = contract.modes[0];
          }
          if (contract.equipment_types.length) {
            formValues.equipment_type = contract.equipment_types[0];
          }
          formValues.involved_tender_to_company_users = formattedPOCs;

          setFieldValue(`actions[${index}]`, formValues);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedContract({});
      const formValues = {...values.actions[index], contract_id: null};
      setFieldValue(`actions[${index}]`, formValues);
    }
  };

  const searchContracts = async (input) => {
    try {
      if (!input) {
        //loading default values based on the policy
        searchQuery = parameterizePolicyDetails(selectedPolicy);
      }

      const response = await getContracts({
        q: input,
        status: 'ACTIVE'
      });

      if (response) {
        const filteredContractsByPreferredCurrency = response.results.filter(
          (contract) => contract.rate_currency === preferredCurrency
        );
        return filteredContractsByPreferredCurrency;
      }
      return [];
    } catch (error) {
      console.error(error);
    }
  };

  const carrierIdsInUse =
    values.actions
      // filter out the current step because we may want to target multiple POCs of the same carrier
      ?.filter((_, i) => i !== index)
      // map returns a list of carrier ids
      ?.map((action) => {
        return action.involved_tender_to_company_users?.map((user) => user.carrier);
      })
      // flatten to remove multidimensional array
      ?.flat() || [];

  const getCarrierRelationships = async (q) => {
    try {
      const response = await getCarrierWithDataMetrics({q});
      // const results = response.data.results || [];
      let pocs = getCarrierPocs(response.data.results);

      if (values.actions[index].involved_tender_to_company_users?.length > 0) {
        pocs = pocs.filter(
          (poc) => poc.carrier_company_id === values.actions[index].involved_tender_to_company_users[0].carrier
        );
      }

      const filterOtherActions =
        pocs?.filter((poc) => carrierIdsInUse.every((id) => poc.carrier_company_id !== id)) || [];
      return filterOtherActions;
    } catch (err) {
      console.error(err);
    }
  };

  const isOptionDisabled = (option) => option.carrier_status !== 'ACTIVE';

  const actionErrors = values?.actions?.map((action) => action?.error);
  const missingCarrierId = values.actions[index].involved_tender_to_company_users?.some((carrier) => !carrier.id);
  return (
    <>
      <div className="grid-item-1-12">
        <Field
          name={`actions[${index}].contract_id`}
          async
          required={false}
          label="Load from Contract"
          component={FormikSelect}
          clearable
          defaultOptions
          loadOptions={searchContracts}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          formatOptionLabel={formatOptionLabel}
          options={contracts}
          error={
            errors?.actions?.[index]?.contract_id || !touched?.actions?.[index]?.contract_id
              ? actionErrors?.[index]?.detail
              : null
          }
          onChange={(val) => {
            setFieldTouched(`actions[${index}].contract_id`, true);
            populateActionFromContract(val, index);
          }}
          isLoading={applicableContractsQuery.isInitialLoading || isLoadingSelectedContract}
        />
      </div>
      {isRateTable ? (
        <div className="grid-item-1-12 ml-2">
          <RateTableOverview contract={selectedContract} />
        </div>
      ) : null}
      <div className="grid-item-1-12">
        <Field
          name={`actions[${index}].involved_tender_to_company_users`}
          isMulti
          required
          label="Carrier"
          component={FormikSelect}
          loadOptions={getCarrierRelationships}
          async
          getOptionValue={(option) => option.id}
          styles={{
            // This component is ReactSelect under the hood.
            // Updating ReactSelect styling is not super fun.
            multiValueLabel: (styles, {data}) => {
              return {
                // spreading current styles
                ...styles,
                // if data (carrier) id does not exist, we can assume that carrier was deleted or is otherwise not valid
                // in which case we should show the error color to highlight the issue to the user.
                ...(!data.id
                  ? {
                      backgroundColor: errorColor,
                      color: invertTextColor
                    }
                  : {})
              };
            },
            multiValueRemove: (styles, {data}) => {
              return {
                ...styles,
                ...(!data.id
                  ? {
                      backgroundColor: errorColor,
                      color: invertTextColor,
                      hover: {
                        backgroundColor: errorHoverColor
                      }
                    }
                  : {})
              };
            }
          }}
          formatOptionLabel={(option, context) => {
            if (typeof option === 'object' && 'label' in option) {
              return option.label;
            }
            return CarrierOption(option, context, isOptionDisabled(option));
          }}
          error={
            errors?.actions?.[index]?.involved_tender_to_company_users || missingCarrierId
              ? 'Missing Carrier Contact'
              : null
          }
          onChange={(values) => {
            setFieldTouched(`actions[${index}].involved_tender_to_company_users`, true);
            const convertValues = values.map((value) => {
              if ('label' in value) {
                return value;
              }
              return {
                carrier: value?.carrier_company_id,
                carrierName: value?.company_name,
                carrierFAVRid: value?.id,
                id: value.user,
                label: `${value?.company_name || ''} - ${value?.first_name || ''} ${value?.last_name || ''} (${
                  value?.email || ''
                })`
              };
            });
            setFieldValue(`actions[${index}].involved_tender_to_company_users`, convertValues);
          }}
          isOptionDisabled={isOptionDisabled}
        />
      </div>
      <div className="grid-item-1-6">
        <Field
          required
          options={modes}
          getOptionLabel={(option) => option.description}
          getOptionValue={(option) => option.id}
          label="Mode"
          name={`actions[${index}].mode`}
          component={FormikSelect}
        />
      </div>
      <div className="grid-item-7-12">
        <Field
          required
          options={equipmentTypes}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          label="Equipment"
          name={`actions[${index}].equipment_type`}
          component={FormikSelect}
        />
      </div>
      <div className="grid-item-1-6">
        <Field
          label="Rate"
          required={selectedContract.rate_type !== RATE_TYPE_RATE_TABLE}
          name={`actions[${index}].rate`}
          component={FormikTextInput}
          onChange={(e) => {
            const {value} = e.target;
            setFieldValue(`actions[${index}].rate`, value.replace(/[^0-9.,]/g, ''));
          }}
          prepend={preferredCurrency}
          disabled={!!selectedContract?.id || !!values?.actions?.[index]?.contract_id}
        />
      </div>
      <div className="grid-item-7-12">
        <Field
          label="Rate Type"
          required
          simpleValue
          clearable={false}
          options={
            isRateTable
              ? [{label: 'Rate Table', id: RATE_TYPE_RATE_TABLE}]
              : [
                  {label: 'Flat Rate', id: RATE_TYPE_FLAT_RATE},
                  {label: 'Per Mile', id: RATE_TYPE_PER_MILE}
                ]
          }
          name={`actions[${index}].rate_type`}
          component={FormikSelect}
          disabled={!!selectedContract?.id || !!values?.actions?.[index]?.contract_id}
        />
      </div>
      <div className="grid-item-1-12">
        <Field
          label="Expiration"
          required
          options={getTimeOptions().filter((e) => e.value !== 0)}
          name={`actions[${index}].expires_after_seconds`}
          component={FormikSelect}
        />
      </div>
      <div className="grid-item-1-12">
        <Field label="Special Instructions" name={`actions[${index}].info_message`} component={FormikTextInput} />
      </div>
    </>
  );
};

export default TenderFields;
