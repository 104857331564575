import {User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {createColumnHelper} from '@tanstack/react-table';
import {Link} from 'react-router';
import {ActionCell, PermissionsCell} from './cells';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

const columnHelper = createColumnHelper<User>();

export const columns = [
  columnHelper.accessor('first_name', {
    id: 'first_name',
    header: 'Name',
    cell: (info) => {
      const fullUser = info.row.original;
      return (
        <Link to={`/company/users/${fullUser.id || ''}`} className="truncate">
          {fullUser.first_name} {fullUser.last_name}
        </Link>
      );
    }
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    cell: (info) => (info.getValue() ? <a href={`mailto:${info.getValue()}`}>{info.getValue()}</a> : '--')
  }),
  columnHelper.accessor('phone_number', {
    id: 'phone_number',
    header: 'Phone',
    sortDescFirst: false,
    cell: (info) => (info.getValue() ? <span className="truncate">{getPhoneHyperlink(info.getValue())}</span> : '--')
  }),
  columnHelper.accessor('permissions', {
    id: 'permissions',
    header: 'Permissions',
    enableSorting: false,
    cell: (info) => {
      return <PermissionsCell permissionKeys={info.getValue()} />;
    }
  }),
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Actions',
    enableSorting: false,
    cell: (info) => {
      const user = info.row.original;
      const userName = `${user.first_name} ${user.last_name}`;
      return <ActionCell userId={info.getValue() || ''} userName={userName} />;
    }
  })
];
