import {DisplayValue, Card, SvgIcon, DeprecatedButton, Modal} from '@shipwell/shipwell-ui';
import {useV3Shipment} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import DrayageShipmentStopsForm from 'App/containers/shipments/components/StopsFields/DrayageShipmentStopsFields';
import Loader from 'App/common/shipwellLoader';
import ContactDisplay from 'App/containers/shipments/components/StopsView/ContactDisplay';

const DrayageShipmentDetailsStopsView = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = shipmentQuery;

  return (
    <div className="p-4">
      {stopsDisplayValues?.map((stopDisplayValues) => (
        <DrayageShipmentStopCardWrapper
          key={stopDisplayValues.sequenceNumber}
          shipmentId={shipmentId}
          sequenceNumber={stopDisplayValues.sequenceNumber}
        />
      ))}
    </div>
  );
};

export default DrayageShipmentDetailsStopsView;

export const DrayageShipmentStopCardWrapper = ({
  shipmentId,
  sequenceNumber
}: {
  shipmentId: string;
  sequenceNumber: number;
}) => {
  const [showStopModal, toggleStopModal] = useToggle();
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = shipmentQuery;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  return (
    <>
      <DrayageShipmentStopCard shipmentId={shipmentId} sequenceNumber={sequenceNumber} onEdit={toggleStopModal} />
      {showStopModal ? (
        <>
          <Modal
            show={showStopModal}
            title={`Edit ${stop.title} Stop`}
            onClose={toggleStopModal}
            footerComponent={null}
          >
            <DrayageShipmentStopsForm
              shipmentId={shipmentId}
              onClose={toggleStopModal}
              sequenceNumber={sequenceNumber}
            />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export const DrayageShipmentStopCard = ({
  shipmentId,
  sequenceNumber,
  onEdit
}: {
  shipmentId: string;
  sequenceNumber: number;
  onEdit: () => void;
}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = shipmentQuery;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  const {title, companyName} = stop;

  return (
    <Card
      draggableProvided={null}
      isCollapsible
      className="mb-4"
      title={
        <div className="flex flex-row items-center text-center">
          <SvgIcon name={`Num${sequenceNumber}Outlined`} color="sw-icon" className="mr-2" />
          <DisplayValue label={title} className="m-0 items-start">
            <div>{companyName}</div>
          </DisplayValue>
        </div>
      }
      actions={
        <DeprecatedButton variant="icon" onClick={onEdit}>
          <SvgIcon name="Edit" color="$sw-icon" />
        </DeprecatedButton>
      }
    >
      <DrayageShipmentStopCardBody shipmentId={shipmentId} sequenceNumber={sequenceNumber} />
    </Card>
  );
};

export const DrayageShipmentStopCardBody = ({
  shipmentId,
  expanded,
  sequenceNumber
}: {
  shipmentId: string;
  sequenceNumber: number;
  expanded?: boolean;
}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = shipmentQuery;

  const stop = stopsDisplayValues?.find((stopDisplayValues) => stopDisplayValues.sequenceNumber === sequenceNumber);

  if (!stop) {
    return null;
  }

  const {addressSingleLine, contacts, instructions} = stop;

  return (
    <>
      <DisplayValue label="Address">
        <div>{addressSingleLine}</div>
      </DisplayValue>
      {expanded ? (
        <>
          <div className="mt-4 border-y border-sw-border pt-4">
            {!!contacts &&
              contacts.map((contact, index) => <ContactDisplay key={contact.id} contact={contact} index={index} />)}
          </div>
          <DisplayValue label="Instructions" className="pt-4">
            {instructions}
          </DisplayValue>
        </>
      ) : null}
    </>
  );
};
