import {ReactNode} from 'react';
import {SvgIcon} from '@shipwell/shipwell-ui';

const ErrorBoundaryFallback = ({
  error,
  errorMessage = 'Something went wrong when displaying this information'
}: {
  error?: Error;
  errorMessage?: ReactNode;
}) => (
  <div className="w-full p-2">
    <span className="mb-2 mt-4 flex items-center px-2">
      <SvgIcon name="ErrorFilled" className="mr-2" />
      <h2 className="m-0 border-0 p-0 text-2xl leading-none">{errorMessage}</h2>
    </span>
    <pre className="p-4">{error?.message}</pre>
  </div>
);

export default ErrorBoundaryFallback;
