import {useCancelAppointmentMutation, UseCancelAppointmentMutationOptions} from './useCancelAppointmentMutation';
import {
  useRescheduleAppointmentMutation,
  UseRescheduleAppointmentMutationOptions
} from './useRescheduleAppointmentMutation';
import {useCreateAppointmentMutation, UseCreateAppointmentMutationOptions} from './useCreateAppointmentMutation';
import {useScheduleAppointmentMutation, UseScheduleAppointmentMutationOptions} from './useScheduleAppointmentMutation';

export type UseAppointmentMutationsOptions = {
  cancelOptions: UseCancelAppointmentMutationOptions;
  createOptions: UseCreateAppointmentMutationOptions;
  rescheduleOptions: UseRescheduleAppointmentMutationOptions;
  scheduleOptions: UseScheduleAppointmentMutationOptions;
};

const useAppointmentMutations = (options?: UseAppointmentMutationsOptions) => {
  const cancelAppointmentMutation = useCancelAppointmentMutation(options?.cancelOptions);
  const createAppointmentMutation = useCreateAppointmentMutation(options?.createOptions);
  const rescheduleAppointmentMutation = useRescheduleAppointmentMutation(options?.rescheduleOptions);
  const scheduleAppointmentMutation = useScheduleAppointmentMutation(options?.scheduleOptions);

  return {
    isMutating:
      cancelAppointmentMutation.isDeleting ||
      createAppointmentMutation.isCreatingAppointment ||
      rescheduleAppointmentMutation.isReschedulingAppointment ||
      scheduleAppointmentMutation.isSchedulingAppointment,
    cancelAppointment: cancelAppointmentMutation.cancelAppointment,
    cancelAppointmentAsync: cancelAppointmentMutation.cancelAppointmentAsync,
    createAppointment: createAppointmentMutation.createAppointment,
    createAppointmentAsync: createAppointmentMutation.createAppointmentAsync,
    rescheduleAppointment: rescheduleAppointmentMutation.rescheduleAppointment,
    rescheduleAppointmentAsync: rescheduleAppointmentMutation.rescheduleAppointment,
    scheduleAppointment: scheduleAppointmentMutation.scheduleAppointment,
    scheduleAppointmentAsync: scheduleAppointmentMutation.scheduleAppointmentAsync
  };
};

export {useAppointmentMutations};
