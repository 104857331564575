import {useEffect} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {withRouter} from 'react-router';
import {compose} from 'recompose';
import LoginErrorPage from 'App/containers/authentication/components/loginErrorPage';
import AccessDeniedError from 'App/containers/authentication/components/AccessDeniedError';
import withCompanyBrand from 'App/components/withCompanyBrand';

const ConfigurationErrorPageWrapper = ({router, company, location}) => {
  useEffect(() => {
    // We only send an error to Rollbar if there are query params, which is what Auth0 uses to pass us error information.
    if (!isEmpty(location.query) && window.Rollbar) {
      const clientId = get(location, 'query.client_id');
      const connection = get(location, 'query.connection');
      const lang = get(location, 'query.lang');
      const error = get(location, 'query.error');
      const errorDescription = get(location, 'query.error_description');
      const trackingId = get(location, 'query.tracking_id');
      const logUrl = get(location, 'query.log_url');

      window.Rollbar.error(
        'There was a misconfiguration in the Auth0Provider. See https://auth0.com/docs/universal-login/custom-error-pages for parameter descriptions.',
        {
          clientId,
          connection,
          lang,
          error,
          errorDescription,
          trackingId,
          logUrl,
          location: window.location
        }
      );
    }
  }, [location]);

  if (location.query.error === 'access_denied') {
    return <AccessDeniedError />;
  }

  return (
    <LoginErrorPage
      companyName={company}
      callback={() => router.push('/')}
      message="We had trouble connecting you through our secure login portal. Please click the button below to try again. If the issue persists, contact your administrator."
      buttonLabel="Try Again"
    />
  );
};

ConfigurationErrorPageWrapper.propTypes = {
  router: PropTypes.object,
  company: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      clientId: PropTypes.string,
      connection: PropTypes.string,
      lang: PropTypes.string,
      error: PropTypes.string,
      errorDescription: PropTypes.string,
      trackingId: PropTypes.string,
      logUrl: PropTypes.string
    }),
    origin: PropTypes.string
  })
};

export default compose(withCompanyBrand, withRouter)(ConfigurationErrorPageWrapper);
