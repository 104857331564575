import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * SMS - api
 */
export async function verifyPhoneNumber(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.smsLookupPost(opts, apiCallback(resolve, reject));
  });
}

/**
 * Carriers - api
 */
export async function searchForCarrierPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersSearchGet(opts, apiCallback(resolve, reject));
  });
}

export async function getCarrierPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersGet(opts, apiCallback(resolve, reject));
  });
}

export async function reportInaccurateCarrierInfoPromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersReportInaccuratePost(body, apiCallback(resolve, reject));
  });
}

/**
 * Carrier Relationships - api
 */
export async function getCarrierRelationshipsPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsGet(opts, apiCallback(resolve, reject));
  });
}

export async function postCarrierRelationshipsPromise(manualEntry, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsPost(manualEntry, body, apiCallback(resolve, reject));
  });
}

export async function putCarrierRelationshipsCarrierRelationshipIdPromise(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdPut(id, body, apiCallback(resolve, reject));
  });
}

export async function deleteCarrierRelationshipsCarrierRelationshipIdPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDelete(id, apiCallback(resolve, reject));
  });
}

export async function getCarrierRelationshipsCarrierRelationshipIdPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdGet(id, apiCallback(resolve, reject));
  });
}

export async function resendLoadboardInvite(carrierId, pocId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdPointOfContactsCarrierRelationshipPointOfContactIdSendLoadBoardInvitePost(
      carrierId,
      pocId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Carrier Tags - api
 */
export async function getCarrierTagsPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierTagsGet(opts, apiCallback(resolve, reject));
  });
}

export async function postCarrierTagsPromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierTagsPost(body, apiCallback(resolve, reject));
  });
}

export async function getCarrierRolesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.vendorsVendorPocRolesGet(apiCallback(resolve, reject));
  });
}

/**
 * Carrier Power Units
 */
export async function getCarrierPowerUnits(carrierId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdPowerUnitsGet(carrierId, opts, apiCallback(resolve, reject));
  });
}

export async function createCarrierPowerUnit(carrierId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdPowerUnitsPost(carrierId, body, apiCallback(resolve, reject));
  });
}

export async function getCarrierPowerUnit(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdGet(carrierId, powerUnitId, apiCallback(resolve, reject));
  });
}

export async function updateCarrierPowerUnit(carrierId, powerUnitId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdPut(carrierId, powerUnitId, body, apiCallback(resolve, reject));
  });
}

export async function deleteCarrierPowerUnit(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdDelete(carrierId, powerUnitId, apiCallback(resolve, reject));
  });
}

/**
 * Carrier Trailers
 */
export async function getCarrierTrailers(carrierId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdTrailersGet(carrierId, opts, apiCallback(resolve, reject));
  });
}

export async function getCarrierTrailer(carrierId, trailerId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdTrailersTrailerIdGet(carrierId, trailerId, apiCallback(resolve, reject));
  });
}

export async function createCarrierTrailer(carrierId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdTrailersPost(carrierId, body, apiCallback(resolve, reject));
  });
}

export async function updateCarrierTrailer(carrierId, trailerId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdTrailersTrailerIdPut(carrierId, trailerId, body, apiCallback(resolve, reject));
  });
}

export async function deleteCarrierTrailer(carrierId, trailerId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdTrailersTrailerIdDelete(carrierId, trailerId, apiCallback(resolve, reject));
  });
}

/**
 * Carrier Hours of Service
 */
export async function getCarrierHoursOfService(carrierId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersCarrierIdEldsHosGet(carrierId, opts, apiCallback(resolve, reject));
  });
}

/**
 * TriumphPay Carrier Details - api
 */
export async function getTriumphPayVendorDetails(triumphId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdGet(triumphId, apiCallback(resolve, reject));
  });
}

export async function getTriumphPayPaymentAccountDetails(triumphId, triumphPaymentTypeId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdGet(
      triumphId,
      triumphPaymentTypeId,
      apiCallback(resolve, reject)
    );
  });
}

export async function putTriumphPayPaymentAccountDetails(triumphId, triumphPaymentTypeId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdPut(
      triumphId,
      triumphPaymentTypeId,
      {body: body},
      apiCallback(resolve, reject)
    );
  });
}

export async function postTriumphPayPaymentAccount(triumphId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesPost(
      triumphId,
      body,
      apiCallback(resolve, reject)
    );
  });
}
export async function deleteTriumphPayPaymentAccount(triumphId, triumphPaymentTypeId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdDelete(
      triumphId,
      triumphPaymentTypeId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}
export async function postDefaultTriumphPayPaymentAccount(triumphId, triumphPaymentTypeId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdSetDefaultPost(
      triumphId,
      triumphPaymentTypeId,
      apiCallback(resolve, reject)
    );
  });
}
export async function payViaTriumphPay(triumphId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPayViaTriumphPayPost(
      triumphId,
      {postTriumphPayVendor: opts},
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Carrier analytics
 */
export async function getCarrierAnalytics(carrierRelationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdAnalyticsGet(
      carrierRelationshipId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Carrier Contracts
 */
export async function createCarrierContract(carrierRelationshipId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdContractsPost(
      carrierRelationshipId,
      body,
      apiCallback(resolve, reject)
    );
  });
}
export async function updateCarrierContract(carrierRelationshipId, contractId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdContractsContractIdPut(
      carrierRelationshipId,
      contractId,
      body,
      apiCallback(resolve, reject)
    );
  });
}
export async function deleteCarrierContract(carrierRelationshipId, contractId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdContractsContractIdDelete(
      carrierRelationshipId,
      contractId,
      apiCallback(resolve, reject)
    );
  });
}

export async function getCarrierContracts(carrierRelationshipId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdContractsGet(
      carrierRelationshipId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getVendorFactoringCompanies(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayVendorsVendorFactoringCompaniesGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * Carrier Capacity
 */
export async function getCarrierLocationCapacityAvailability(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierCapacityLocationCapacityAvailableGet(shipmentId, apiCallback(resolve, reject));
  });
}

export async function getCarrierCapacityAvailability(shipmentId, contractId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierCapacityCarrierCapacityAvailableGet(shipmentId, contractId, apiCallback(resolve, reject));
  });
}
