import {RegisterUPS, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {registrationUPSPost} from 'App/api/registration/typed';
import {COMPANY_INTEGRATION_UPS} from 'App/data-hooks/queryKeys';

export const useCreateUpsAccountMutation = (
  mutationOptions: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUPSPost>>,
    AxiosError<ShipwellError>,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(async (upsAccount: RegisterUPS) => await registrationUPSPost(upsAccount), {
    onSettled: async () => {
      await queryClient.invalidateQueries([COMPANY_INTEGRATION_UPS]);
    },
    ...mutationOptions
  });
};
