import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {SvgIcon, DisplayValue} from '@shipwell/shipwell-ui';
import StopFlagDetails from 'App/containers/Shipment/components/StopFlags';
import StopStatus from 'App/containers/Shipment/components/StopStatus';
import {formatDayOfWeekDateTime} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import withFlags from 'App/utils/withFlags';
import {StopEtaPill} from 'App/components/stopEta';
import {getNumOutlinedSvgIconName} from 'App/common';

const StopHeader = ({stop, index, getFullShipmentDetails, stopStatusEnabled, shipment}) => {
  return (
    <div className="flex h-6">
      <span className="ml-2 self-center font-bold">{`Stop ${index + 1}`}</span>
      <span className="flex items-center">
        <StopFlagDetails
          stop={stop}
          alerts={stop?.alerts}
          onRemoveFlag={() => {
            getFullShipmentDetails(shipment.id);
          }}
        />
      </span>
      {stopStatusEnabled && <StopStatus stop={stop} allowRemove={false} />}
    </div>
  );
};
StopHeader.propTypes = {
  stop: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.string)
  }),
  shipment: PropTypes.shape({
    id: PropTypes.string
  }),
  index: PropTypes.number,
  getFullShipmentDetails: PropTypes.func,
  stopStatusEnabled: PropTypes.bool
};

export const CompleteStop = ({stop}) => {
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      <DisplayValue className="col-span-1" label="Actual Arrival">
        {stop?.confirmed_arrival_at
          ? formatDayOfWeekDateTime(stop?.confirmed_arrival_at, true, stop?.location?.address?.timezone)
          : stop?.unconfirmed_arrival_at
          ? formatDayOfWeekDateTime(stop?.unconfirmed_arrival_at, true, stop?.location?.address?.timezone)
          : '--'}
      </DisplayValue>
      <DisplayValue className="col-span-1" label="Completed At">
        {stop?.confirmed_departure_at
          ? formatDayOfWeekDateTime(stop?.confirmed_departure_at, true, stop?.location?.address?.timezone)
          : stop?.unconfirmed_departure_at
          ? formatDayOfWeekDateTime(stop?.unconfirmed_departure_at, true, stop?.location?.address?.timezone)
          : '--'}
      </DisplayValue>
    </div>
  );
};
CompleteStop.propTypes = {
  stop: PropTypes.shape({
    confirmed_departure_at: PropTypes.string,
    confirmed_arrival_at: PropTypes.string,
    unconfirmed_arrival_at: PropTypes.string,
    unconfirmed_departure_at: PropTypes.string,
    location: PropTypes.shape({
      address: PropTypes.shape({
        timezone: PropTypes.string
      })
    })
  })
};

export const IncompleteStopBase = ({stop, tripManagementPredictedEta, hasLTL}) => {
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      <DisplayValue
        className="col-span-2"
        label={`${stop?.is_pickup ? (stop?.is_dropoff ? 'Stop ' : 'Pickup') : 'Delivery'} Location`}
      >
        {stop?.location?.address?.formatted_address}
      </DisplayValue>
      <DisplayValue className="col-span-1" label="Planned For">
        <span className="flex items-center">
          <span className="mr-1">{stop?.display_planned_window}</span>
          {!hasLTL && tripManagementPredictedEta ? <StopEtaPill stop={stop} size="xs" duration="hours" /> : null}
        </span>
      </DisplayValue>
      <DisplayValue className="col-span-1" label="Appointment Type">
        {stop?.appointment_type?.name || '--'}
      </DisplayValue>
    </div>
  );
};
IncompleteStopBase.propTypes = {
  stop: PropTypes.shape({
    is_pickup: PropTypes.bool,
    is_dropoff: PropTypes.bool,
    location: PropTypes.shape({
      address: PropTypes.shape({
        formatted_address: PropTypes.string
      })
    }),
    display_planned_window: PropTypes.string,
    appointment_type: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  tripManagementPredictedEta: PropTypes.bool,
  hasLTL: PropTypes.bool
};

export const IncompleteStop = withFlags('tripManagementPredictedEta')(IncompleteStopBase);

const StopsDetails = ({shipment, getFullShipmentDetails, stopStatusEnabled}) => {
  const {hasLTL} = checkShipmentModes(shipment?.mode);
  if (shipment.stops.length < 1) {
    return '';
  }
  return (
    <div className="flex flex-col">
      {shipment.stops.map((stop, index) => {
        const numOutlinedSvgIconName = getNumOutlinedSvgIconName(index);
        return (
          <div key={stop.id} className="flex border-b border-sw-border px-6 py-3">
            <div>
              <SvgIcon name={numOutlinedSvgIconName} color="$sw-icon" />
            </div>
            <div className="flex-1 flex-col">
              <StopHeader
                stop={stop}
                index={index}
                getFullShipmentDetails={getFullShipmentDetails}
                stopStatusEnabled={stopStatusEnabled}
                shipment={shipment}
              />
              <div className="ml-2 mt-3">
                {stop?.confirmed_arrival_at || stop?.unconfirmed_arrival_at ? (
                  <CompleteStop stop={stop} />
                ) : (
                  <IncompleteStop stop={stop} hasLTL={hasLTL} />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

StopsDetails.propTypes = {
  shipment: PropTypes.shape({
    mode: PropTypes.object,
    stops: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.object,
        id: PropTypes.string,
        confirmed_departure_at: PropTypes.string,
        unconfirmed_departure_at: PropTypes.string
      })
    )
  }),
  getFullShipmentDetails: PropTypes.func,
  stopStatusEnabled: PropTypes.bool
};

export default compose(
  connect((state) => ({
    stopStatusEnabled: get(state, 'userCompany.company.feature_flags.stop_status_enabled', false)
  })),
  withFlags('tripManagementPredictedEta')
)(StopsDetails);
