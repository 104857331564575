import {FacilityDock, Facility} from '@shipwell/tempus-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ShipwellApiErrorResponse} from '@shipwell/settlements-sdk';
import {AxiosError} from 'axios';
import {transformSupplierAppointmentCreationPayload} from './utils';
import {createAppointment as appointmentPost} from 'App/api/appointments';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {AppointmentAvailabilityWindow} from 'App/data-hooks/appointments/types';

export const useCreateSupplierAppointment = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof appointmentPost>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      supplierAppointmentFormData: SupplierAppointmentCreationFormType;
      availabilityDock: FacilityDock;
      availabilityWindow: AppointmentAvailabilityWindow;
      facility: Facility;
    }
  >
) => {
  const mutation = useMutation<
    Awaited<ReturnType<typeof appointmentPost>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      supplierAppointmentFormData: SupplierAppointmentCreationFormType;
      availabilityDock: FacilityDock;
      availabilityWindow: AppointmentAvailabilityWindow;
      facility: Facility;
    }
  >(
    async ({supplierAppointmentFormData, availabilityDock, availabilityWindow, facility}) =>
      await appointmentPost(
        transformSupplierAppointmentCreationPayload({
          supplierAppointmentFormData,
          availabilityDock,
          availabilityWindow,
          facility
        })
      ),
    {
      ...mutationOptions
    }
  );
  return {
    createSupplierAppointment: mutation.mutate,
    isCreateSupplierAppointmentLoading: mutation.isLoading
  };
};
