import {useState} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {useCompanyUsers, useUserMe} from 'App/data-hooks';
import {useDebounce} from 'App/utils/hooks/useDebounce';

export const RepsFilter = ({
  defaultRepIds,
  defaultRepLabels,
  onChange
}: {
  defaultRepIds: string[];
  defaultRepLabels: string[];
  onChange: (ids: string[], labels: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);
  const user = useUserMe();
  const companyUsersQuery = useCompanyUsers(user.data?.company?.id || '', {
    q: debouncedSearchVal,
    pageSize: 100,
    page: 1
  });
  const options =
    companyUsersQuery.data?.results?.map((user) => ({
      value: user.id || '',
      label: `${user.first_name} ${user.last_name}`
    })) || [];

  const defaultValues = defaultRepIds.map((repId, i) => ({
    value: repId,
    label: defaultRepLabels[i]
  }));

  return (
    <Select
      value={defaultValues}
      onInputChange={setSearchVal}
      onChange={(options: {label: string; value: string}[]) => {
        const repIds = options.map((option) => option.value);
        const repLabels = options.map((option) => option.label);
        onChange(repIds, repLabels);
      }}
      isLoading={companyUsersQuery.isInitialLoading}
      options={options}
      isMulti
      label="Search Reps"
    />
  );
};
