import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table-6';
import {Link} from 'react-router';
import moment from 'moment';
import {
  Tooltip,
  Modal,
  Toast,
  Card,
  DeprecatedButton,
  SvgIcon,
  Dropdown,
  CollapsibleCardContent
} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {formatCurrency} from 'App/utils/internationalConstants';
import {fetchEquipmentTypes} from 'App/actions/_shipments';
import {formatDateTime, truncateNumber, formatDate, formatTime} from 'App/utils/globals';
import CarrierBidCreateContainer from 'App/containers/carrierBids/create';
import {quoting} from 'App/api';
import './styles.scss';

const CarrierBidsListContainer = (props) => {
  const {bids, equipmentTypes, dispatch, selectedShipment, getCarrierBids, carrierBidsMeta} = props;

  const [showBidForm, setShowBidForm] = useState(false);
  const [showCarrierBidSuccess, setShowCarrierBidSuccess] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);
  const [deletingBid, setDeletingBid] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (equipmentTypes.length < 1) {
      dispatch(fetchEquipmentTypes());
    }
  }, []);

  const handlePageChange = (page) => {
    getCarrierBids({...carrierBidsMeta, page: page + 1});
  };

  const handlePageSizeChange = (pageSize) => {
    getCarrierBids({...carrierBidsMeta, pageSize});
  };

  const handleSortedChange = (ordering) => {
    getCarrierBids({...carrierBidsMeta, ordering});
  };

  const handleDeleteBid = async (bid) => {
    try {
      await quoting.deleteCarrierBid(bid);
      setShowDeleteSuccess(true);
      setDeletingBid(null);
      getCarrierBids({...carrierBidsMeta, page: 1});
    } catch (error) {
      setShowDeleteError(error.error_description);
    }
  };

  const handleSelectBid = (bid) => {
    bid.carrier = {};
    bid.carrier.usdot = bid.usdot_number || null;
    bid.carrier.mc_number = bid.mc_number || null;
    bid.carrier.name = bid.carrier_name || null;

    setSelectedBid(bid);
  };

  return (
    <Card
      title="Direct Bids"
      bodyClassName="carrierBids__list-container"
      actions={
        selectedShipment &&
        selectedShipment.state === 'quote_accepted' &&
        selectedShipment.metadata.open && (
          <DeprecatedButton
            variant="tertiary"
            icon={<SvgIcon name="AddCircleOutlined" />}
            onClick={() => setShowBidForm(true)}
          >
            Create New Bid
          </DeprecatedButton>
        )
      }
      isCollapsible
    >
      <CollapsibleCardContent>
        <div className="carrierBids__list">
          <ReactTable
            data={bids}
            showPagination
            minRows={0}
            manual
            className="sw-table -highlight -striped"
            pageSizeOptions={[20, 50, 100]}
            page={Number(carrierBidsMeta.page) - 1}
            pages={Number(carrierBidsMeta.pages)}
            pageSize={Number(carrierBidsMeta.pageSize)}
            LoadingComponent={ShipwellLoader}
            previousText={<i className="icon icon-Left" />}
            nextText={<i className="icon icon-Right" />}
            noDataText="No bids to show."
            {...props}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortedChange={handleSortedChange}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: () => {
                  if (column.id !== 'actions' && column.id !== 'carrier') {
                    handleSelectBid(rowInfo.original);
                    setReadOnly(true);
                    setShowBidForm(true);
                  }
                }
              };
            }}
            columns={[
              {
                Header: 'Carrier',
                id: 'carrier',
                sortable: false,
                accessor: 'carrier_relationship',
                Cell: (row) => {
                  const {
                    mc_number,
                    usdot_number,
                    contact_first_name,
                    contact_last_name,
                    contact_email,
                    contact_phone_number
                  } = row.original;

                  const tooltipContent = (
                    <>
                      {!row.value && (
                        <>
                          <p>
                            <b>Carrier Not Currently In-Network</b>
                          </p>
                          <br />
                        </>
                      )}
                      <div>
                        <p>
                          <b>MC: </b>
                          {mc_number || '--'}
                        </p>
                        <p>
                          <b>USDOT: </b>
                          {usdot_number || '--'}
                        </p>
                        <br />
                        <p>{`${contact_first_name || ''} ${contact_last_name || ''}`}</p>
                        <p>{`${contact_phone_number || ''}`}</p>
                        <p>{`${contact_email || ''}`}</p>
                      </div>
                    </>
                  );

                  return (
                    <Tooltip
                      tooltipClassname="carrierBids__list-tooltip"
                      placement="right"
                      tooltipContent={tooltipContent}
                    >
                      {row.value ? (
                        <Link to={`/carriers/${row.value}`}>{row.original.carrier_name}</Link>
                      ) : (
                        <span className="carrierBids__list-disabled">{row.original.carrier_name}</span>
                      )}
                    </Tooltip>
                  );
                }
              },
              {
                Header: 'Bid Amount',
                id: 'bid_amount',
                sortable: false,
                accessor: 'bid_amount',
                Cell: (row) => {
                  return formatCurrency(row.value, row.original.bid_amount_currency);
                }
              },
              {
                Header: 'Notes',
                id: 'notes',
                sortable: false,
                accessor: 'notes',
                Cell: (row) => {
                  return row.value ? (
                    <Tooltip
                      tooltipClassname="carrierBids__list-notes"
                      placement="top"
                      tooltipContent={<span>{row.value}</span>}
                    >
                      <span className="material-icons">insert_drive_file</span>
                    </Tooltip>
                  ) : (
                    '--'
                  );
                }
              },
              {
                Header: 'Available Time',
                id: 'available_date',
                sortable: false,
                accessor: 'available_date',
                Cell: (row) => {
                  if (row.value && row.original.available_time) {
                    return formatDateTime(
                      moment(`${row.value} ${row.original.available_time}`).toDate(),
                      true,
                      row.original.current_location.timezone
                    );
                  }
                  if (row.value && !row.original.available_time) {
                    return formatDate(moment(`${row.value}`).toDate(), true, row.original.current_location.timezone);
                  }
                  if (!row.value && row.original.available_time) {
                    return formatTime(
                      moment(`${row.original.available_time}`, 'HH:mm:ss').toDate(),
                      true,
                      row.original.current_location.timezone
                    );
                  }
                  return '--';
                }
              },
              {
                Header: 'Available Location',
                id: 'current_location',
                sortable: false,
                accessor: 'current_location',
                Cell: (row) => {
                  return row.value ? `${row.value.formatted_address}` : '--';
                }
              },
              {
                Header: 'Distance from Pickup',
                id: 'distance_from_pickup_miles',
                sortable: false,
                accessor: 'distance_from_pickup_miles',
                Cell: (row) => {
                  return row.value ? truncateNumber(Math.round(row.value), 0) + ' mi' : '--';
                }
              },
              {
                Header: 'Equipment',
                id: 'equipment_type',
                sortable: false,
                accessor: 'equipment_type',
                Cell: (row) => {
                  const equipment = equipmentTypes.find((e) => e.id === row.value);
                  return equipment ? equipment.name : '--';
                }
              },
              {
                Header: 'Created By',
                id: 'created_by_user_full_name',
                sortable: false,
                accessor: 'created_by_user_full_name'
              },
              {
                Header: 'Created Date',
                id: 'created_at',
                sortable: false,
                accessor: 'created_at',
                Cell: (row) => {
                  return formatDateTime(row.value);
                }
              },
              {
                Header: '',
                id: 'actions',
                sortable: false,
                accessor: 'actions',
                className: 'overflow-visible',
                width: 40,
                Cell: (row) => {
                  return (
                    <Dropdown
                      indicator={false}
                      drop="left"
                      variant="icon"
                      className="action-menu"
                      icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                    >
                      {({onClick}) => (
                        <>
                          <li
                            onClick={() => {
                              onClick();
                              handleSelectBid(row.original);
                              setShowBidForm(true);
                            }}
                          >
                            Edit Bid
                          </li>

                          <li
                            className="text-danger"
                            onClick={() => {
                              onClick();
                              setDeletingBid(row.original.id);
                            }}
                          >
                            Delete Bid
                          </li>
                        </>
                      )}
                    </Dropdown>
                  );
                }
              }
            ]}
          />
          <Modal
            show={showBidForm}
            title={selectedBid ? (readOnly ? 'View Bid' : 'Edit Bid') : 'New Bid'}
            footerComponent={null}
            onClose={() => {
              setShowBidForm(false);
              setReadOnly(false);
              setSelectedBid(null);
            }}
          >
            <CarrierBidCreateContainer
              readOnly={readOnly}
              shipment={selectedShipment}
              values={selectedBid}
              isEdit={selectedBid}
              onCancel={() => {
                setShowBidForm(false);
                setReadOnly(false);
                setSelectedBid(null);
              }}
              onSubmitSuccess={() => {
                setShowBidForm(false);
                setShowCarrierBidSuccess(true);
                getCarrierBids(carrierBidsMeta);
                setSelectedBid(null);
              }}
            />
          </Modal>
          <Toast
            show={showCarrierBidSuccess}
            title="Bid Saved!"
            anchor="bottom-right"
            onClose={() => setShowCarrierBidSuccess(false)}
          >
            Carrier bid saved successfully.
          </Toast>
          <Modal
            variant="warning"
            show={Boolean(deletingBid)}
            title="Delete Bid"
            primaryBtnName="Delete Bid"
            onClose={() => setDeletingBid(null)}
            onPrimaryAction={() => handleDeleteBid(deletingBid)}
          >
            <p>Are you sure you want to delete this bid?</p>
          </Modal>
          <Toast
            show={Boolean(showDeleteSuccess)}
            title="Bid Deleted."
            anchor="bottom-right"
            onClose={() => setShowDeleteSuccess(false)}
          >
            Bid removed.
          </Toast>
          <Toast
            show={showDeleteError}
            title="Bid Not Deleted."
            variant="error"
            anchor="bottom-right"
            onClose={() => setShowDeleteError(false)}
          >
            {showDeleteError}
          </Toast>
        </div>
      </CollapsibleCardContent>
    </Card>
  );
};

export default connect((state) => ({
  equipmentTypes: state.shipments.equipmentTypes
}))(CarrierBidsListContainer);
