import {Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderTypeahead from 'App/formComponents/renderTypeahead';

const renderMultipleShipmentReps = ({
  deleteRep,
  fields,
  shipmentRepRoles,
  companyUsers,
  reps,
  selected,
  meta: {touched, error},
  ...rest
}) => (
  <div>
    {fields.map((rep, index) => (
      <div key={index} className="shipmentRep__inlineFields">
        <Field
          name={`${rep}.role`}
          component={renderDropDownSelector}
          data={shipmentRepRoles}
          nolabel
          placeholder="Select a role"
        />
        <Field
          name={`${rep}.user`}
          labelKey="display_name"
          component={renderTypeahead}
          nolabel
          selected={selected[index] ? [selected[index]] : []}
          options={companyUsers}
          placeholder="Select a user"
        />
        <DeprecatedButton
          variant="icon"
          className="action-link showAsLink"
          onClick={() => {
            // delete rep if saved
            if (reps.results[index]) {
              deleteRep(reps.results[index].id);
            }
            fields.remove(index);
          }}
          title={'Remove this rep'}
        >
          <SvgIcon name="TrashOutlined" />
        </DeprecatedButton>
      </div>
    ))}
    {(!fields || fields.length === 0) && <p>No shipment reps specified.</p>}
    {error && (
      <p className="error-text-form-level pad-top">
        <i className="icon icon-Delayed pad-right" />
        {error}
      </p>
    )}
    <div className="pad-top text-right">
      <Button bsStyle="default" type="button" onClick={() => fields.push({})}>
        <i className="flaticon-plus pad-right" /> Add Rep
      </Button>
    </div>
  </div>
);

export default renderMultipleShipmentReps;
