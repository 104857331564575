import {useState, useEffect, useRef} from 'react';
import {
  ContractAdditionalStopChargesTypeEnum,
  ContractPerHourStartFieldEnum,
  ContractPerHourEndFieldEnum,
  Company,
  DocumentMetadata,
  Address,
  SlimCarrierRelationship,
  StopLocation,
  Contract,
  CompanyPreferencesCountryEnum,
  ContractRateCurrencyEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {DragDropContext, Draggable, Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import {Field, FieldArray, useFormikContext} from 'formik';
import {
  FormikTextInput,
  FormikSelect,
  FormikDateTimePicker,
  FormikCheckbox,
  Tooltip,
  Rule,
  Title,
  SvgIcon,
  Button,
  IconButton,
  FormikRadioGroup
} from '@shipwell/shipwell-ui';
import {RateTableOverview} from './RateTable/RateTableOverview';
import {SelectAccessorialRateTableField} from './SelectAccessorialRateTableField/SelectContractDefaultAccessorials';
import withFlags from 'App/utils/withFlags';
import {getCarrierRelationshipsPromise} from 'App/api/carriers/carrierRelationship';
import {getSLADetails} from 'App/api/serviceLevelAgreements/typed';
import {getCompanyDocuments} from 'App/api/company/typed';
import ServiceLevelAgreementReadOnlyDetails from 'App/containers/serviceLevelAgreements/readOnlyDetails';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import FuelSurchargeField from 'App/formComponents/formSections/fuelSurchargeField';
import {
  RATE_TYPE_PER_MILE,
  RATE_TYPE_RATE_TABLE,
  RATE_TYPE_PER_HOUR
} from 'App/containers/userCompany/rateTables/constants';
import {FlexBox} from 'App/components/Box';
import {formatCurrencyValue} from 'App/utils/globals';
import {roundNumberToSpecificDecimals} from 'App/utils/mathHelpers';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {reorder} from 'App/utils/dragAndDrop';
import {useCompanyPreferences, useTotalDistance} from 'App/data-hooks';
import type {State} from 'App/reducers/types';
import {
  DestinationsField,
  DocumentsField,
  FuelIncludedField,
  MinimumRateField,
  NotesField,
  OriginsField,
  RateCurrencyField,
  RateField,
  RateTypeField,
  ServiceLevelAgreementField
} from 'App/formComponents/formSections/contractFields/fields';
import {
  ContractFormValues,
  contractsModeOptionsWhiteList,
  contractsTooltips
} from 'App/formComponents/formSections/contractFields/constants';

type ContractFieldsBaseProps = {
  values: ContractFormValues;
  lockCarrier?: boolean;
  attachFscToCustomerContract?: boolean;
  cloningContract?: Contract;
  hasContractLanes?: boolean;
};

type ContractFieldsProps = ContractFieldsBaseProps & {company?: Partial<Company>};

/**
 * Contract Fields - component for use in Contract Create/Edit pages
 * @param {*} props
 */
const ContractFieldsBase = ({
  values,
  lockCarrier,
  attachFscToCustomerContract,
  company,
  cloningContract,
  hasContractLanes
}: ContractFieldsProps) => {
  const {data: companyPreferences} = useCompanyPreferences(company?.id);
  const isDefaultCanada = companyPreferences?.country === CompanyPreferencesCountryEnum.Ca;
  const defaultCurrency = isDefaultCanada ? ContractRateCurrencyEnum.Cad : ContractRateCurrencyEnum.Usd;
  const [companyDocuments, setCompanyDocuments] = useState<DocumentMetadata[]>([]);
  const {errors, touched, initialValues, setFormikState, setFieldValue} = useFormikContext<ContractFormValues>();

  const inputRef = useRef<HTMLInputElement>();

  const getCarrierRelationships = async (input: string) => {
    try {
      const response = await getCarrierRelationshipsPromise({q: input});
      return response.data.results;
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceLevelAgreementDetails = async (slaID: string) => {
    try {
      const response = await getSLADetails(slaID);
      setFieldValue('service_level_agreement', response);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Get company documents
   * @param {*} companyId
   */
  const getCompanyDocumentsList = async (companyId: string) => {
    try {
      const response = await getCompanyDocuments({companyId, pageSize: 50});

      if (response?.results) {
        setCompanyDocuments(response.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (company?.id) {
      void getCompanyDocumentsList(company.id);
    }
  }, [company]);

  useEffect(() => {
    if (typeof values.service_level_agreement === 'string') {
      void getServiceLevelAgreementDetails(values.service_level_agreement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef?.current && cloningContract) {
      inputRef.current.select();
    }
  }, [inputRef, cloningContract]);

  // ensure stops are sorted by order_index and not initial array index
  const additionalStops =
    values.additional_stops?.sort((a: {order_index: number}, b: {order_index: number}) => {
      if (a.order_index < b.order_index) {
        return -1;
      }
      if (a.order_index > b.order_index) {
        return 1;
      }
      return 0;
    }) || [];

  const isCustomStopCharge =
    values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.CustomStopCharges;
  const origin = values?.origins?.length ? values.origins[0] : undefined;
  const destination = values?.destinations?.length ? values.destinations[0] : undefined;
  const hasMultipleOriginsOrDestinations = [values?.origins?.length, values?.destinations?.length]?.some(
    (len) => len && len > 1
  );

  useEffect(() => {
    if (!hasMultipleOriginsOrDestinations) {
      return;
    }
    // if there are multiple origins or destinations, we should clear the additional stops
    setFieldValue('additional_stops', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMultipleOriginsOrDestinations]);

  // the useTotalDistance hook wants each "stop" to be formed as {address: Address}
  const additionalStopsWithAddress = additionalStops.filter((stop) => stop.address_1);
  const locations = [origin, ...additionalStopsWithAddress, destination]?.map((location) => ({address: location}));
  // each stop should have an address
  const hasValidAdditionalStops = additionalStops.length == additionalStopsWithAddress.length;

  const currentDistance = values?.distance !== undefined ? parseFloat(values.distance) : undefined;

  const addressesAreTheSame =
    initialValues.origins?.[0]?.address_1 == origin?.address_1 &&
    initialValues.origins?.[0]?.city == origin?.city &&
    initialValues.origins?.[0]?.postal_code == origin?.postal_code &&
    initialValues.destinations?.[0]?.address_1 == destination?.address_1 &&
    initialValues.destinations?.[0]?.city == destination?.city &&
    initialValues.destinations?.[0]?.postal_code == destination?.postal_code;

  const isNullDistance = hasMultipleOriginsOrDestinations || !hasValidAdditionalStops;
  const shouldCalculateDistance =
    !currentDistance || (!addressesAreTheSame && !hasMultipleOriginsOrDestinations && hasValidAdditionalStops);

  const {totalDistance: calculatedDistance, isFetchingDistance} = useTotalDistance({
    stops: locations as StopLocation[],
    enabled: shouldCalculateDistance
  });

  useEffect(() => {
    setFormikState((prevState) => ({...prevState, isValidating: isFetchingDistance}));
  }, [isFetchingDistance, setFormikState]);

  const totalDistance = currentDistance && !shouldCalculateDistance ? currentDistance : calculatedDistance;

  const distance = totalDistance.toFixed(2).toString();

  useEffect(() => {
    if (isNullDistance) {
      // wipe distance if it should be null
      setFieldValue('distance', null);
    } else if (shouldCalculateDistance && values.distance != distance) {
      setFieldValue('distance', distance);
    }
  }, [distance, isNullDistance, setFieldValue, shouldCalculateDistance, values.distance]);

  // not supported by backend yet
  // const totalDistanceToDisplay =
  //   values.distance_unit === ContractDistanceUnitEnum.Kilometer ? totalDistanceKM : totalDistance;
  // const distanceUnitToDisplay = values.distance_unit === ContractDistanceUnitEnum.Kilometer ? 'km' : 'Miles';
  const distanceUnitToDisplay = 'Miles';

  const getLineHaul = () => {
    const rate = values?.rate || 0;
    if (values.rate_type === RATE_TYPE_PER_MILE) {
      return rate * totalDistance;
    }
    return rate;
  };
  const getStopTotals = () => {
    if (isCustomStopCharge) {
      return additionalStops.reduce((total, stop) => (total += Number(stop?.additional_stop_charge) || 0), 0);
    }
    return (values?.additional_stop_charge_flat_fee || 0) * (additionalStops?.length || 0);
  };

  const handleDropEnd: OnDragEndResponder = (result) => {
    const draggedFromIndex = result?.source?.index;
    const draggedToIndex = result?.destination?.index;
    if (draggedFromIndex === undefined || draggedToIndex === undefined || draggedFromIndex === draggedToIndex) {
      return;
    }
    if (values?.additional_stops) {
      // get stops in newly dragged order
      const newStopsOrder = reorder(values.additional_stops, draggedFromIndex, draggedToIndex);
      // update stop's order_index based on newly dragged order
      const newStops = newStopsOrder.map((stop, i) => {
        stop.order_index = i;
        return stop;
      });
      // set formik state
      setFieldValue('additional_stops', newStops);
    }
  };

  // All stops' order_indexes should be updated when a stop is removed from the array
  const handleRemoveAdditionalStop = (removedIndex: number) => {
    const newAdditionalStops = values.additional_stops
      ?.filter((stop) => stop.order_index !== removedIndex)
      .map((stop, i) => {
        stop.order_index = i;
        return stop;
      });
    setFieldValue('additional_stop', newAdditionalStops);
  };

  const showStopAddressInputError = (index: number) =>
    (touched as unknown as {additional_stops: Array<boolean>})?.additional_stops?.[index] &&
    Object.keys(errors?.additional_stops?.[index] ?? {})?.filter((key) => key !== 'additional_stop_charge')?.length;

  const isRateTable = values?.rate_type === RATE_TYPE_RATE_TABLE;
  const isHourlyRate = values?.rate_type === RATE_TYPE_PER_HOUR;

  return (
    <>
      <div className="field-grid">
        <Field
          required
          label="Status"
          name="status"
          simpleValue
          clearable={false}
          component={FormikSelect}
          options={[
            {value: 'ACTIVE', label: 'Active'},
            {value: 'PAUSED', label: 'Paused'},
            {value: 'SUSPENDED', label: 'Suspended'},
            {value: 'EXPIRED', label: 'Expired'}
          ]}
        />
      </div>
      <Rule />
      <Title variant="sectionTitle">Details</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-12">
          <Field
            ref={inputRef}
            required
            label="Contract Name"
            name="name"
            component={FormikTextInput}
            disabled={hasContractLanes}
          />
        </div>
        <div className="grid-item-1-12">
          <Field
            async
            required
            label="Carrier"
            name="carrier"
            disabled={lockCarrier || hasContractLanes}
            component={FormikSelect}
            loadOptions={getCarrierRelationships}
            getOptionLabel={(option: SlimCarrierRelationship) => option.shipwell_vendor && option.shipwell_vendor.name}
            getOptionValue={(option: SlimCarrierRelationship) => option.id}
          />
        </div>
        <div className="grid-item-1-12">
          <ModesField optionsWhiteList={contractsModeOptionsWhiteList} disabled={hasContractLanes} />
        </div>
        <div className="grid-item-1-12">
          <EquipmentTypesField disabled={hasContractLanes} />
        </div>
        <div className="grid-item-1-6">
          <Field label="Start Date" name="start_date" showTimeSelect={false} component={FormikDateTimePicker} />
        </div>
        <div className="grid-item-7-12">
          <Field label="End Date" name="end_date" showTimeSelect={false} component={FormikDateTimePicker} />
        </div>
      </div>
      <Rule />

      <Title variant="sectionTitle">Rates</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-4">
          <RateTypeField required disabled={hasContractLanes} />
        </div>
        <div className="grid-item-5-8">
          <RateCurrencyField />
        </div>
        {isRateTable ? (
          <>
            <div className="grid-item-9-12 mb-5">
              <RateTableOverview contract={values} />
            </div>
            <div className="grid-item-1-6">
              <MinimumRateField disabled={hasContractLanes} />
            </div>
          </>
        ) : (
          <div className="grid-item-9-12">
            <RateField required disabled={hasContractLanes} />
          </div>
        )}
        {isHourlyRate ? (
          <>
            <div className="grid-item-1-6">
              <Field
                name="calculate_per_hour_rate_automatically"
                component={FormikRadioGroup}
                options={[
                  {label: 'Auto Calculate Time', value: true},
                  {label: 'Manually Enter Time', value: false}
                ]}
                direction="row"
              />
            </div>
            {values?.calculate_per_hour_rate_automatically &&
            [true, 'true'].includes(values.calculate_per_hour_rate_automatically) ? (
              <>
                <div className="grid-item-1-6">
                  <Field
                    label="Start Time"
                    name="per_hour_start_field"
                    component={FormikSelect}
                    options={[
                      {label: 'Pickup Arrival Time', value: ContractPerHourStartFieldEnum.PickupArrivalTime},
                      {label: 'Pickup Departure Time', value: ContractPerHourStartFieldEnum.PickupDepartureTime},
                      {label: 'Delivery Arrival Time', value: ContractPerHourStartFieldEnum.DeliveryArrivalTime}
                    ]}
                    simpleValue
                    required
                    clearable={false}
                  />
                </div>
                <div className="grid-item-7-12">
                  <Field
                    label="End Time"
                    name="per_hour_end_field"
                    component={FormikSelect}
                    options={[
                      {label: 'Pickup Departure Time', value: ContractPerHourEndFieldEnum.PickupDepartureTime},
                      {label: 'Delivery Arrival Time', value: ContractPerHourEndFieldEnum.DeliveryArrivalTime},
                      {label: 'Delivery Departure Time', value: ContractPerHourEndFieldEnum.DeliveryDepartureTime}
                    ]}
                    simpleValue
                    required
                    clearable={false}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <div className="grid-item-1-6">
          <FuelIncludedField />
        </div>
        {values.rate_type && values.rate_type === RATE_TYPE_PER_MILE ? (
          <div className="grid-item-7-12">
            <MinimumRateField disabled={hasContractLanes} />
          </div>
        ) : null}
        <div className="grid-item-1-6">
          <Field
            label="Add Additional Stop Charges"
            name="add_additional_stop_charges"
            component={FormikCheckbox}
            disabled={hasContractLanes}
          />
        </div>
        {values.add_additional_stop_charges ? (
          <>
            <div className="grid-item-7-12">
              <Field
                required
                simpleValue
                label="Charge Type"
                name="additional_stop_charges_type"
                component={FormikSelect}
                options={[
                  {label: 'Custom', value: ContractAdditionalStopChargesTypeEnum.CustomStopCharges},
                  {label: 'Charge Per Stop', value: ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop}
                ]}
                clearable={false}
                disabled={hasContractLanes}
              />
            </div>
            {values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop ? (
              <>
                <div className="grid-item-1-6">
                  <span>Additional Charge Per Stop</span>
                </div>
                <div className="grid-item-7-12">
                  <Field
                    required
                    label="Charge Per Stop"
                    name="additional_stop_charge_flat_fee"
                    component={FormikTextInput}
                    disabled={hasContractLanes}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
      <Rule />
      <Title variant="sectionTitle">Stops</Title>
      <div className="flex flex-col gap-4 pb-4">
        <OriginsField disabled={hasContractLanes} />
        <FieldArray name="additional_stops">
          {({push, remove}) => (
            <>
              {additionalStops?.length ? (
                <DragDropContext onDragEnd={handleDropEnd}>
                  <Droppable droppableId="additional-stops">
                    {(provided) => (
                      <div ref={provided.innerRef} className="flex flex-col gap-2">
                        {!hasMultipleOriginsOrDestinations &&
                          additionalStops.map((additionalStop, i) => (
                            <Draggable
                              key={additionalStop.id || i}
                              index={i}
                              draggableId={additionalStop?.id || i.toString()}
                            >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <div
                                    className={
                                      isCustomStopCharge ? 'grid grid-cols-2 gap-2' : 'flex items-center gap-2'
                                    }
                                  >
                                    <FlexBox items="center" gap="s" grow={1}>
                                      <div {...provided.dragHandleProps}>
                                        <SvgIcon height="20" name="Drag" color="sw-icon" />
                                      </div>
                                      <div className="flex w-full flex-col">
                                        <Field
                                          label={`Stop ${i + 1}`}
                                          name={`additional_stops[${i}]`}
                                          component={AddressSearch}
                                          onAddressSelect={(address: Address) => {
                                            // augment returned address with order and charge info
                                            setFieldValue(`additional_stops[${i}]`, {
                                              ...address,
                                              order_index: i,
                                              additional_stop_charge_currency:
                                                values?.additional_stop_charge_flat_fee_currency
                                            });
                                          }}
                                          disabled={hasContractLanes}
                                        />
                                        {showStopAddressInputError(i) ? (
                                          <span className="pl-2 text-xs text-sw-error">
                                            An exact address is required.
                                          </span>
                                        ) : null}
                                      </div>
                                    </FlexBox>
                                    <FlexBox items="center" gap="s">
                                      {isCustomStopCharge ? (
                                        <div className="w-full">
                                          <Field
                                            label="Stop Charge"
                                            name={`additional_stops[${i}].additional_stop_charge`}
                                            component={FormikTextInput}
                                            prepend={<SvgIcon name="DollarOutlined" />}
                                            disabled={hasContractLanes}
                                          />
                                        </div>
                                      ) : null}
                                      <IconButton
                                        iconName="TrashOutlined"
                                        aria-label="Remove stop"
                                        onClick={() => {
                                          remove(i);
                                          handleRemoveAdditionalStop(i);
                                        }}
                                      />
                                    </FlexBox>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
              <DestinationsField disabled={hasContractLanes} />
              {!hasMultipleOriginsOrDestinations ? (
                <FlexBox justifyItems="start">
                  <Button
                    iconName="AddCircleOutlined"
                    variant="tertiary"
                    width="compact"
                    onClick={() => push({order_index: values.additional_stops?.length})}
                    disabled={hasContractLanes}
                  >
                    Add Stop
                  </Button>
                </FlexBox>
              ) : null}
            </>
          )}
        </FieldArray>
      </div>
      <Rule />

      {values.rate_type !== RATE_TYPE_RATE_TABLE ? (
        <div className="mb-4 rounded bg-sw-background p-4">
          <FlexBox gap="m">
            <div className="w-full">
              <h4 className="m-0 pb-2 font-bold">Shipment Totals</h4>
              {values.defaultAccessorials?.map((accessorial, index) => {
                if (!accessorial?.accessorial?.description) return null;
                return (
                  <FlexBox key={index} items="baseline" gap="s">
                    <span>{accessorial.accessorial.description}</span>
                    <div className="grow border-b border-dashed" />
                    <span>
                      {values?.rate_currency} {formatCurrencyValue(accessorial.rate)}
                    </span>
                  </FlexBox>
                );
              })}
              <FlexBox items="baseline" gap="s">
                <span>Estimated Total Distance</span>
                <div className="grow border-b border-dashed" />
                <span>
                  {hasMultipleOriginsOrDestinations
                    ? 'N/A'
                    : `${totalDistance.toFixed(2).toLocaleString()} ${distanceUnitToDisplay}`}
                </span>
              </FlexBox>
              <FlexBox items="baseline" gap="s">
                <span>Estimated Total Line Haul</span>
                <div className="grow border-b border-dashed" />
                <span>
                  {hasMultipleOriginsOrDestinations
                    ? 'N/A'
                    : `${values?.rate_currency || defaultCurrency} ${formatCurrencyValue(
                        roundNumberToSpecificDecimals(getLineHaul(), 2)
                      )}`}
                </span>
              </FlexBox>
              <FlexBox items="baseline" gap="s">
                <span>Estimated Stop Totals</span>
                <div className="grow border-b border-dashed" />
                <span>
                  {values?.rate_currency || defaultCurrency}{' '}
                  {formatCurrencyValue(roundNumberToSpecificDecimals(getStopTotals(), 2))}
                </span>
              </FlexBox>
            </div>
          </FlexBox>
        </div>
      ) : null}

      <Rule />
      <Title variant="sectionTitle">Service Level Agreement</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <ServiceLevelAgreementField />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent={contractsTooltips.SERVICE_LEVEL_AGREEMENT}
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
        {values.service_level_agreement && (
          <div className="grid-item-1-12">
            <ServiceLevelAgreementReadOnlyDetails sla={values.service_level_agreement} />
          </div>
        )}
        <div className="grid-item-1-12 grid-auto-field">
          <NotesField />
        </div>
      </div>
      {attachFscToCustomerContract ? (
        <>
          <Rule />
          <div className="field-grid grid-1-12">
            <div className="grid-item-1-11">
              <FuelSurchargeField />
            </div>
          </div>
        </>
      ) : null}
      <div className="mb-4">
        <SelectAccessorialRateTableField />
      </div>
      <Rule />
      <Title variant="sectionTitle">Documents</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <DocumentsField documents={companyDocuments} />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent={contractsTooltips.DOCUMENTS}
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
      </div>
      <Rule />
      <Title variant="sectionTitle">Notifications</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <Field name="email" label="Email" component={FormikTextInput} disabled />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent="This email is used to notify someone when certain events occur with Contracts not associated with shipments or actions with a direct contact."
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export const ContractFields = compose<ContractFieldsProps, ContractFieldsBaseProps>(
  connect((state: State) => ({
    company: state.userCompany.company
  })),
  withFlags('attachFscToCustomerContract')
)(ContractFieldsBase);
