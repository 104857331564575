import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal} from '@shipwell/shipwell-ui';
import GetSpotQuotesForm from 'App/formComponents/forms/spotQuotes';
import * as actions from 'App/actions/shipments';
import * as carrierActions from 'App/actions/vendors';
import * as marketplaceActions from 'App/actions/marketplace';
import MessagePreview from 'App/components/MessagePreview/MessagePreview';
import './_get-spot-quotes.scss';

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    selectedQuote: state.shipments.selectedQuote,
    company: state.auth.company,
    carrierRelationships: state.vendors.carrierRelationships,
    getSpotQuotes: state.form.getSpotQuotes,
    authenticated: state.auth.authenticated,
    carrierTags: state.vendors.carrierTags,
    showShipwellNetwork:
      state.auth.company &&
      state.auth.company.feature_flags &&
      state.auth.company.feature_flags.send_shipwell_network_enabled
  }),
  {...actions, ...carrierActions, ...marketplaceActions}
)
export default class GetSpotQuotes extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.handleSpecialInstructions = this.handleSpecialInstructions.bind(this);
    this.state = {
      showPreview: false,
      tagsPagination: {
        page: 1,
        pageSize: 10000,
        ordering: 'name'
      },
      specialInstructions: ''
    };
  }
  componentDidMount() {
    const {notes_for_carrier} = this.props.selectedShipment;
    if (this.props.authenticated) {
      this.props.getCarrierTags({
        page: this.state.tagsPagination.page,
        pageSize: this.state.tagsPagination.pageSize,
        ordering: this.state.tagsPagination.ordering
      });
    }
    if (notes_for_carrier) {
      this.setState({specialInstructions: notes_for_carrier});
    }
  }

  componentDidUpdate(prevProps) {
    const {notes_for_carrier} = this.props.selectedShipment;
    if (prevProps.authenticated !== this.props.authenticated) {
      this.props.getCarrierTags({
        page: this.state.tagsPagination.page,
        pageSize: this.state.tagsPagination.pageSize,
        ordering: this.state.tagsPagination.ordering
      });
    }
    if (prevProps.selectedShipment.notes_for_carrier !== notes_for_carrier) {
      this.setState({specialInstructions: notes_for_carrier});
    }
  }

  onFormSubmit(attrs) {
    const {showShipwellNetwork} = this.props;
    const request = JSON.parse(JSON.stringify(attrs));
    //send in user id for dispatch@shipwell if checkbox was selected and on prod
    if (request.send_to_carrier_network === true && showShipwellNetwork && process.env.SHIPWELL_SPOT_QUOTE_VENDOR_ID) {
      if (request.vendors) {
        request.vendors.push({id: process.env.SHIPWELL_SPOT_QUOTE_VENDOR_ID});
      } else {
        request.vendors = [{id: process.env.SHIPWELL_SPOT_QUOTE_VENDOR_ID}];
      }
    }

    request.solicited_users = request.vendors.map((e) => e.id);
    //dont pass any additional users on the customer side for now
    request.additional_customer_users = [];
    // delete the special_instructions field if it is "" - BE rejects if sent with ""
    if (request.special_instructions === '') {
      delete request.special_instructions;
    }
    //post the new spot negotiations in bulk
    return this.props
      .createPrivateMarketplaceSpotNegotiations(this.props.selectedShipment.id, request)
      .then((response) => {
        if (response.status === 200) {
          if (this.props.handleClose) {
            this.props.handleClose();
          } else {
            //redirect to the shipment communication page
            this.props.triggerRedirect();
          }
          // show success toast for request bid
          this.props.toggleRequestBidSuccess();
        } else {
        }
      });
  }

  showPreview() {
    this.setState({showPreview: true});
  }
  // pass special instructions down to <MessagePreview/>
  handleSpecialInstructions(text) {
    this.setState({specialInstructions: text});
  }

  render() {
    const carrierTags = [];
    if (this.props.carrierTags && this.props.carrierTags) {
      for (var i = 0; i < this.props.carrierTags.length; i++) {
        carrierTags.push({name: this.props.carrierTags[i].name, id: this.props.carrierTags[i].id});
      }
      carrierTags.sort();
    }

    return (
      <div className="get-spot-quotes">
        <GetSpotQuotesForm
          onSubmit={this.onFormSubmit}
          showPreview={this.showPreview}
          handleSpecialInstructions={this.handleSpecialInstructions}
          special_instructions={this.state.specialInstructions}
        />
        <Modal
          show={this.state.showPreview}
          bsSize="large"
          onClose={() => {
            this.setState({
              showPreview: false
            });
          }}
          title="Preview Email"
          children={
            <MessagePreview
              loadId={
                this.props.selectedShipment &&
                this.props.selectedShipment.metadata &&
                this.props.selectedShipment.metadata.load_board_id
              }
              message={
                this.props.getSpotQuotes && this.props.getSpotQuotes.values && this.props.getSpotQuotes.values.message
              }
              specialInstructions={this.state.specialInstructions}
            />
          }
          footerComponent={null}
        />
      </div>
    );
  }
}
