import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  RMISCarrierPolicy,
  ShipwellError,
  CarrierRelationshipsApiCarrierComplianceIntegrationsRmisPolicyRmisPolicyIdPutRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {RMIS_COMPLIANCE_POLICY} from '../queryKeys';
import {setRMISCarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseUpdateRMISPolicyMutationOptions = Omit<
  UseMutationOptions<
    RMISCarrierPolicy,
    AxiosError<ShipwellError>,
    CarrierRelationshipsApiCarrierComplianceIntegrationsRmisPolicyRmisPolicyIdPutRequest,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;

const useFMCSAPolicyMutation = (options?: UseUpdateRMISPolicyMutationOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (updatedPolicy: CarrierRelationshipsApiCarrierComplianceIntegrationsRmisPolicyRmisPolicyIdPutRequest) =>
      setRMISCarrierCompliancePolicy(updatedPolicy),
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        void queryClient.invalidateQueries([RMIS_COMPLIANCE_POLICY]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {
    isRMISLoading: mutation.isLoading,
    isRMISSuccess: mutation.isSuccess,
    isRMISError: mutation.isError,
    RMISError: mutation.error || null,
    RMISPolicy: mutation.data || null,
    updateRMISPolicy: mutation.mutate,
    updateRMISPolicyAsync: mutation.mutateAsync
  };
};

export default useFMCSAPolicyMutation;
