import {
  ALERT_ERROR,
  REQ_CARRIER_DATA,
  SEARCH_FOR_CARRIERS,
  GET_CARRIER,
  CLEAR_CARRIERS,
  SELECT_CARRIER,
  CLEAR_CARRIER,
  CLEAR_SELECTED_RELATIONSHIP,
  GET_CARRIER_RELATIONSHIPS,
  GET_CARRIER_BY_ID,
  GET_CARRIER_RELATIONSHIP,
  GET_CARRIER_TAGS,
  POST_CARRIER_TAGS,
  GET_CARRIER_ROLES,
  GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS,
  GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS
} from './types';
import {carriers} from 'App/api';
import {getTriumphPayVendorInvoices} from 'App/api/carriers/typed';

/**
 * @todo Break this file out into separate files
 */
export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

export const requestData = () => ({
  type: REQ_CARRIER_DATA
});

/**
 * Carriers - actions
 */
export const carrierSearchRequestSuccess = (response) => ({
  type: SEARCH_FOR_CARRIERS,
  payload: response.body
});

export const carrierRequestSuccess = (response) => ({
  type: GET_CARRIER,
  payload: response.body
});

export function searchForCarrier(opts = {}) {
  return (dispatch) => {
    return carriers
      .searchForCarrierPromise(opts)
      .then((response) => {
        dispatch(carrierSearchRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function checkForUSDOT(opts = {}) {
  return (dispatch) => {
    return carriers
      .searchForCarrierPromise(opts)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function getCarrier(opts = {}) {
  return (dispatch) => {
    return carriers
      .getCarrierPromise(opts)
      .then((response) => {
        dispatch(carrierRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function reportInaccurateCarrierInfo(body = {}) {
  return (dispatch) => {
    return carriers
      .reportInaccurateCarrierInfoPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function clearCarriers() {
  return (dispatch) => {
    dispatch({type: CLEAR_CARRIERS});
  };
}

export function selectCarrier(carrier) {
  return (dispatch) => {
    dispatch({type: SELECT_CARRIER, payload: carrier});
  };
}

export function clearSelectedCarrier(opts) {
  return (dispatch) => {
    dispatch({type: CLEAR_CARRIER});
  };
}

/**
 * Carrier Relationships - actions
 */
export function clearSelectedRelationship() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_RELATIONSHIP});
  };
}

export const carrierRelationshipsRequestSuccess = (response) => ({
  type: GET_CARRIER_RELATIONSHIPS,
  payload: response.body
});

export const carrierRelationshipsByIdRequestSuccess = (response) => ({
  type: GET_CARRIER_BY_ID,
  payload: response.body
});

export const carrierRelationshipRequestSuccess = (response) => ({
  type: GET_CARRIER_RELATIONSHIP,
  payload: response.body
});

export function getCarrierRelationships(opts = {}) {
  return (dispatch) => {
    dispatch(requestData());

    return carriers
      .getCarrierRelationshipsPromise(opts)
      .then((response) => {
        dispatch(carrierRelationshipsRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400, details: response};
      });
  };
}

export function searchForCarrierByID(id) {
  return (dispatch) => {
    return carriers
      .getCarrierRelationshipsPromise({vendorId: id})
      .then((response) => {
        dispatch(carrierRelationshipsByIdRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400, details: response};
      });
  };
}

export function postCarrierRelationships(manualEntry, body = {}) {
  return (dispatch) => {
    return carriers
      .postCarrierRelationshipsPromise(manualEntry, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError({error_description: 'There was an error adding this carrier.'}));
        return response;
      });
  };
}

export function putCarrierRelationshipsCarrierRelationshipId(id, body) {
  return (dispatch) => {
    return carriers
      .putCarrierRelationshipsCarrierRelationshipIdPromise(id, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError({error_description: 'There was an error editing this carrier.'}));
        return response;
      });
  };
}

export function deleteCarrierRelationshipsCarrierRelationshipId(id) {
  return (dispatch) => {
    return carriers
      .deleteCarrierRelationshipsCarrierRelationshipIdPromise(id)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function getCarrierRelationshipsCarrierRelationshipId(id) {
  return (dispatch) => {
    dispatch(requestData());

    return carriers
      .getCarrierRelationshipsCarrierRelationshipIdPromise(id)
      .then((response) => {
        dispatch(carrierRelationshipRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Carrier Tags - actions
 */
export const carrierTagsRequestSuccess = (response) => ({
  type: GET_CARRIER_TAGS,
  payload: response.body
});

export const carrierTagsCreateSuccess = (response) => ({
  type: POST_CARRIER_TAGS,
  payload: response.body
});

export function getCarrierTags(opts = {}) {
  return (dispatch) => {
    return carriers
      .getCarrierTagsPromise(opts)
      .then((response) => {
        dispatch(carrierTagsRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError({error_description: 'There was an error fetching tags'}));
        return {status: 400, details: response};
      });
  };
}

export function postCarrierTags(body = {}) {
  return (dispatch) => {
    return carriers
      .postCarrierTagsPromise(body)
      .then((response) => {
        dispatch(carrierTagsCreateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Carrier Roles - actions
 */
export const carrierRolesRequestSuccess = (response) => ({
  type: GET_CARRIER_ROLES,
  payload: response.body
});

export function getCarrierRoles() {
  return (dispatch) => {
    return carriers
      .getCarrierRolesPromise()
      .then((response) => {
        dispatch(carrierRolesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error fetching roles'}));
        return error;
      });
  };
}
/**
 * TriumphPay Carrier Details - actions
 */

export const triumphPayVendorRequestSuccess = (response) => ({
  type: GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS,
  payload: response.body
});

export const getTriumphPayVendorDetails = (triumphId) => {
  return (dispatch) => {
    return carriers
      .getTriumphPayVendorDetails(triumphId)
      .then((response) => {
        dispatch(triumphPayVendorRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const triumphPayVendorInvoiceRequestSuccess = (response) => ({
  type: GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS,
  payload: response.data.results
});

export const fetchTriumphPayVendorInvoices = (triumphId, shipmentId) => {
  return (dispatch) => {
    return getTriumphPayVendorInvoices({triumphId, shipmentId})
      .then((response) => {
        dispatch(triumphPayVendorInvoiceRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error fetching TriumphPay invoices.'}));
        return error;
      });
  };
};

export const triumphPayAccountDetailsRequestSuccess = (response) => ({
  type: GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  payload: response.body
});

export const getTriumphPayAccountDetails = (triumphId) => {
  return (dispatch) => {
    return carriers
      .getTriumphPayPaymentAccountDetails(triumphId)
      .then((response) => {
        dispatch(triumphPayAccountDetailsRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error fetching TriumphPay payment account details.'}));
        return error;
      });
  };
};

export const triumphPayAccountDetailsUpdateSuccess = (response) => ({
  type: EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  payload: response.body
});

export const putTriumphPayAccountDetails = (triumphId, triumphBankAccountId, body) => {
  return (dispatch) => {
    return carriers
      .putTriumphPayPaymentAccountDetails(triumphId, triumphBankAccountId, {paymentType: body})
      .then((response) => {
        dispatch(triumphPayAccountDetailsUpdateSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error updating TriumphPay payment account details.'}));
        throw new Error('There was an error updating TriumphPay payment account details.');
      });
  };
};

export const triumphPayAccountCreateSuccess = (response) => ({
  type: CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  payload: response.body
});

export const postTriumphPayAccount = (triumphId, body) => {
  return (dispatch) => {
    return carriers
      .postTriumphPayPaymentAccount(triumphId, body)
      .then((response) => {
        dispatch(triumphPayAccountCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error creating TriumphPay account(s).'}));
        throw new Error('There was an error creating TriumphPay account(s).');
      });
  };
};

export const triumphPayAccountDeleteSuccess = (triumphBankAccountId) => ({
  type: DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  payload: triumphBankAccountId
});

export const deleteTriumphPayAccount = (triumphId, triumphBankAccountId, opts) => {
  return (dispatch) => {
    return carriers
      .deleteTriumphPayPaymentAccount(triumphId, triumphBankAccountId, opts)
      .then((response) => {
        dispatch(triumphPayAccountDeleteSuccess(triumphBankAccountId));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error deleting this TriumphPay account.'}));
        return error;
      });
  };
};

export const triumphPayAccountSetDefaultSuccess = (triumphBankAccountId) => ({
  type: SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  payload: triumphBankAccountId
});
export const setDefaultTriumphAccount = (triumphId, triumphBankAccountId) => {
  return (dispatch) => {
    return carriers
      .postDefaultTriumphPayPaymentAccount(triumphId, triumphBankAccountId)
      .then((response) => {
        dispatch(triumphPayAccountSetDefaultSuccess(triumphBankAccountId));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error updating TriumphPay account details.'}));
        return error;
      });
  };
};
