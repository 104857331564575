import {ChangeEvent, ReactNode, useEffect, useState} from 'react';
import {Field, useFormikContext} from 'formik';
import capitalize from 'lodash/capitalize';
import {FormikCheckbox, FormikSelect, TypographyLabel} from '@shipwell/shipwell-ui';
import {getOperatingDays} from './utils';
import {FlexBox} from 'App/components/Box';
import {halfHourOptions} from 'App/utils/dateTimeGlobalsTyped';
import useFormErrorScroll from 'App/utils/hooks/useFormErrorScroll';

export enum OperatingHoursEnums {
  weekdays = 'WEEKDAYS',
  weekdaysAndWeekends = 'WEEKDAYS_AND_WEEKENDS',
  custom = 'CUSTOM'
}

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'] as const;
const weekends = ['saturday', 'sunday'] as const;

const operatingHoursOptions = [
  {
    label: 'Weekdays',
    value: OperatingHoursEnums.weekdays
  },
  {
    label: 'Weekdays & Weekends',
    value: OperatingHoursEnums.weekdaysAndWeekends
  },
  {
    label: 'Custom',
    value: OperatingHoursEnums.custom
  }
];

export type OpenTimes = {
  open_sunday?: boolean;
  sunday_open_time?: string | null;
  sunday_close_time?: string | null;

  open_monday?: boolean;
  monday_open_time?: string | null;
  monday_close_time?: string | null;

  open_tuesday?: boolean;
  tuesday_open_time?: string | null;
  tuesday_close_time?: string | null;

  open_wednesday?: boolean;
  wednesday_open_time?: string | null;
  wednesday_close_time?: string | null;

  open_thursday?: boolean;
  thursday_open_time?: string | null;
  thursday_close_time?: string | null;

  open_friday?: boolean;
  friday_open_time?: string | null;
  friday_close_time?: string | null;

  open_saturday?: boolean;
  saturday_open_time?: string | null;
  saturday_close_time?: string | null;
};

export function OperatingDaysInputs<T extends OpenTimes>({initialValues}: {initialValues: T}) {
  const [initValues] = useState(initialValues);
  const [operatingDays, setOperatingDays] = useState<OperatingHoursEnums>();
  const {setFieldValue, values} = useFormikContext<OpenTimes>();

  const handleOperatingDaysChange = (
    val: OperatingHoursEnums,
    setFieldValue: (field: string, value: boolean | string, shouldValidate?: boolean) => void
  ) => {
    setOperatingDays(val);
    switch (val) {
      case OperatingHoursEnums.weekdays:
        weekdays.forEach((weekday) => {
          setFieldValue(`open_${weekday}`, true);
          setFieldValue(`${weekday}_open_time`, values?.[`${weekdays[0]}_open_time`] || '08:00:00');
          setFieldValue(`${weekday}_close_time`, values?.[`${weekdays[0]}_close_time`] || '18:00:00');
        });
        weekends.forEach((weekend) => {
          setFieldValue(`open_${weekend}`, false);
        });
        break;
      case OperatingHoursEnums.weekdaysAndWeekends:
        weekdays.forEach((weekday) => {
          setFieldValue(`open_${weekday}`, true);
          setFieldValue(`${weekday}_open_time`, values?.[`${weekdays[0]}_open_time`] || '08:00:00');
          setFieldValue(`${weekday}_close_time`, values?.[`${weekdays[0]}_close_time`] || '18:00:00');
        });
        weekends.forEach((weekend) => {
          setFieldValue(`open_${weekend}`, true);
        });
        break;
      case OperatingHoursEnums.custom:
        [...weekdays, ...weekends].forEach((day) => {
          setFieldValue(`open_${day}`, false);
        });
        break;
    }
  };

  const handleOpenDayChange = (
    event: ChangeEvent<HTMLInputElement>,
    day: (typeof weekdays)[number] | (typeof weekends)[number],
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
    initial: Partial<OpenTimes>,
    values: OpenTimes
  ) => {
    setFieldValue(`open_${day}`, event.target.checked);
    if (event.target.checked) return;
    const openTime = `${day}_open_time` as keyof OpenTimes;
    const closeTime = `${day}_close_time` as keyof OpenTimes;
    // if currently no value, reset to initial
    if (!values[openTime]) {
      setFieldValue(openTime, initial[openTime]);
    }
    if (!values[closeTime]) {
      setFieldValue(closeTime, initial[closeTime]);
    }
  };

  useEffect(() => {
    setOperatingDays(getOperatingDays(initValues));
  }, [initValues]);

  return (
    <FlexBox direction="col" gap="m">
      <FlexBox direction="col" gap="s">
        <Field
          name="operating_days"
          isClearable={false}
          label="Operating Days"
          options={operatingHoursOptions}
          value={operatingDays}
          component={FormikSelect}
          onChange={(val: OperatingHoursEnums) => handleOperatingDaysChange(val, setFieldValue)}
          simpleValue
          required
        />
      </FlexBox>
      {operatingDays === OperatingHoursEnums.weekdays ? (
        <OpenAndCloseTimeRow day="weekdays">
          <span>Monday-Friday</span>
        </OpenAndCloseTimeRow>
      ) : null}
      {operatingDays === OperatingHoursEnums.weekdaysAndWeekends ? (
        <>
          <TypographyLabel className="">Weekdays</TypographyLabel>
          <OpenAndCloseTimeRow day="weekdays">
            <span>Monday-Friday</span>
          </OpenAndCloseTimeRow>
          <TypographyLabel className="">Weekends</TypographyLabel>
          <OpenAndCloseTimeRow day="saturday">
            <Field
              fixedHeight={false}
              label="Saturday"
              name="open_saturday"
              component={FormikCheckbox}
              onChange={(val: ChangeEvent<HTMLInputElement>) =>
                handleOpenDayChange(val, 'saturday', setFieldValue, initialValues, values)
              }
            />
          </OpenAndCloseTimeRow>
          <OpenAndCloseTimeRow day="sunday">
            <Field
              fixedHeight={false}
              label="Sunday"
              name="open_sunday"
              component={FormikCheckbox}
              onChange={(val: ChangeEvent<HTMLInputElement>) =>
                handleOpenDayChange(val, 'sunday', setFieldValue, initialValues, values)
              }
            />
          </OpenAndCloseTimeRow>
        </>
      ) : null}
      {operatingDays === OperatingHoursEnums.custom ? (
        <>
          {[...weekdays, ...weekends].map((day) => (
            <OpenAndCloseTimeRow day={day} key={day}>
              <Field fixedHeight={false} label={capitalize(day)} name={`open_${day}`} component={FormikCheckbox} />
            </OpenAndCloseTimeRow>
          ))}
        </>
      ) : null}
    </FlexBox>
  );
}

function OpenAndCloseTimeRow<T extends OpenTimes>({
  children,
  day
}: {
  children: ReactNode;
  day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'weekdays';
}) {
  const {setFieldValue, values} = useFormikContext<T>();
  useFormErrorScroll();

  const handleChange = (val: string, timeOfDay: 'open' | 'close') => {
    // if not 'weekdays' treat like normal day
    if (day !== 'weekdays') {
      setFieldValue(`${day}_${timeOfDay}_time`, val);
      return;
    }
    // else update each weekday to match input
    weekdays.forEach((weekday) => {
      setFieldValue(`${weekday}_${timeOfDay}_time`, val);
    });
  };

  // if set to weekdays, display monday's time because it will be consistent with the rest of the week
  const dayName = day === 'weekdays' ? 'monday' : day;
  const openDay = `open_${dayName}` as keyof T;
  return (
    <div className="@container/openCloseTime">
      <div className="grid grid-cols-6 items-center gap-x-4 gap-y-2 @2xl:grid-cols-7">
        <div className="col-span-6 @2xl:col-span-1">{children}</div>
        <div className="col-span-3">
          <Field
            simpleValue
            name={`${dayName}_open_time`}
            label="Open time"
            showTimeSelectOnly
            options={halfHourOptions}
            component={FormikSelect}
            disabled={!values[openDay]}
            onChange={(val: string) => handleChange(val, 'open')}
            required={values[openDay]}
            clearable={false}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="col-span-3">
          <Field
            simpleValue
            name={`${dayName}_close_time`}
            label="Close time"
            showTimeSelectOnly
            options={halfHourOptions}
            component={FormikSelect}
            disabled={!values[openDay]}
            onChange={(val: string) => handleChange(val, 'close')}
            required={values[openDay]}
            clearable={false}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </div>
  );
}
