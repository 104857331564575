import {ReactNode, JSX} from 'react';
import {Formik, Field, FormikHelpers, Form} from 'formik';
import {FormikTextInput, FormikSelect, Card} from '@shipwell/shipwell-ui';
import moment from 'moment';
import {string, object, number} from 'yup';
import {withRouter, WithRouterProps} from 'react-router';
import {TriumphPayAuthSettings} from '@shipwell/backend-core-sdk';
import {compose} from 'recompose';
import {useQueryClient} from '@tanstack/react-query';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {
  useGetTriumphPaySettings,
  useGetTriumphPaySettingsApprovedDateOptions,
  useTriumphPaySettingsMutation
} from 'App/containers/integrations/details/components/triumphPayDetails/hooks';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {COMPANY_INTEGRATION_TRIUMPH_PAY_DETAILS} from 'App/data-hooks/queryKeys';
import Loader from 'App/common/shipwellLoader';

interface TriumphPayMappingFormPropTypes extends Partial<WithRouterProps>, Partial<WithStatusToastProps> {}

const defaultValues = {
  documents_submission_email: '',
  created_at: '',
  authorized_by: {
    first_name: '',
    last_name: '',
    email: ''
  },
  approved_date_source: '',
  factor_approved_date_source: '',
  carriers_payment_terms: ''
};

const triumphPayMappingFormValidationSchema = object().shape({
  documents_submission_email: string(),
  created_at: string(),
  authorized_by: object().shape({
    first_name: string(),
    last_name: string(),
    email: string()
  }),
  approved_date_source: string(),
  factor_approved_date_source: string(),
  carriers_payment_term: number()
    .max(99, 'Carriers payment term must be less than three digits.')
    .typeError('Carriers payment term must be a number.')
});

const TriumphPayMappingForm = ({router, setSuccess}: TriumphPayMappingFormPropTypes): JSX.Element => {
  const {data: approvedDateOptions, isLoading: isLoadingApprovedDateOptions} =
    useGetTriumphPaySettingsApprovedDateOptions();
  const approvedDateDropdownOptions = approvedDateOptions?.map((option) => {
    return {label: option.name, value: option.name};
  });
  const {data: settingsData, isLoading: isLoadingSettings} = useGetTriumphPaySettings();
  const {mutate: updateTriumphPaySettings} = useTriumphPaySettingsMutation();
  const queryClient = useQueryClient();
  const handleSubmit = (values: TriumphPayAuthSettings, {setSubmitting}: FormikHelpers<TriumphPayAuthSettings>) =>
    updateTriumphPaySettings(values, {
      onSettled: () => {
        setSubmitting(false);
        void queryClient.invalidateQueries([COMPANY_INTEGRATION_TRIUMPH_PAY_DETAILS]);
      },
      onSuccess: () => setSuccess?.('Success!', 'Triumph Pay Settings Updated.')
    });

  const FieldGridContainer = ({children}: {children: ReactNode}) => (
    <div className="grid auto-rows-[minmax(60px,auto)] grid-cols-1 items-start justify-items-stretch gap-3 md:grid-cols-2">
      {children}
    </div>
  );
  if (isLoadingSettings || isLoadingApprovedDateOptions) {
    return <Loader loading />;
  }
  return (
    <Formik
      validationSchema={triumphPayMappingFormValidationSchema}
      onSubmit={handleSubmit}
      initialValues={{...defaultValues, ...settingsData}}
    >
      {({values}: {values: TriumphPayAuthSettings}) => (
        <Form>
          <Card title="Details" draggableProvided={null}>
            <div className="flex flex-col">
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Date Activated</div>
                <div className="integration-details-card-detail">
                  {moment(values.created_at).isValid() ? moment(values.created_at).format('MM/DD/YYYY') : '--'}
                </div>
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Activated by</div>
                <div className="integration-details-card-detail">
                  {values.authorized_by?.first_name} {values.authorized_by?.last_name}
                </div>
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Activated by email</div>
                <div className="integration-details-card-detail">{values.authorized_by?.email}</div>
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Triumph pay documents submission email</div>
                <Field name="documents_submission_email" label="Submission Email" component={FormikTextInput} />
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Carrier Invoice Approved Date</div>
                <Field
                  simpleValue
                  clearable={false}
                  label="Carrier Approved Date"
                  name="approved_date_source"
                  component={FormikSelect}
                  options={approvedDateDropdownOptions || []}
                />
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Factor Invoice Approved Date</div>
                <Field
                  simpleValue
                  clearable={false}
                  label="Factor Approved Date"
                  name="factor_approved_date_source"
                  component={FormikSelect}
                  options={approvedDateDropdownOptions || []}
                />
              </FieldGridContainer>
              <FieldGridContainer>
                <div className="text-md font-bold capitalize">Default Carrier Payment Terms</div>
                <Field label="Payment Terms" name="carriers_payment_term" component={FormikTextInput} />
              </FieldGridContainer>
            </div>
          </Card>
          <FormFooter
            primaryActionName="Update Triumph Pay Settings"
            onCancel={() => router?.push('/company/integrations')}
          />
        </Form>
      )}
    </Formik>
  );
};

export default compose<TriumphPayMappingFormPropTypes, TriumphPayMappingFormPropTypes>(
  WithStatusToasts,
  withRouter
)(TriumphPayMappingForm);
