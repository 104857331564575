import {addDaysAndFormatDate} from 'App/utils/dateTimeGlobalsTyped';

//this ensures the due date term date matches the input format of the date picker
export const DATE_PICKER_DATE_ONLY_INPUT_FORMAT = 'YYYY-MM-DD';
//this ensures the due date term date matches the output format of the date picker
export const DATE_PICKER_DATE_ONLY_OUTPUT_FORMAT = 'MM/DD/YYYY';
export const DATE_TERMS = [
  {label: '7 Days', date: addDaysAndFormatDate(7, DATE_PICKER_DATE_ONLY_INPUT_FORMAT)},
  {label: '14 Days', date: addDaysAndFormatDate(14, DATE_PICKER_DATE_ONLY_INPUT_FORMAT)},
  {label: '30 Days', date: addDaysAndFormatDate(30, DATE_PICKER_DATE_ONLY_INPUT_FORMAT)},
  {label: '45 Days', date: addDaysAndFormatDate(45, DATE_PICKER_DATE_ONLY_INPUT_FORMAT)},
  {label: '60 Days', date: addDaysAndFormatDate(60, DATE_PICKER_DATE_ONLY_INPUT_FORMAT)},
  {label: 'Custom', date: null}
];
