import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {useTransportationOrder} from 'App/data-hooks';
import {isContainerOrderItem, generateContainerLabel} from 'App/containers/shipments/utils/typed';
import Loader from 'App/common/shipwellLoader';
import LineItemsListView from 'App/containers/shipments/components/FinancialsView/LineItemsListView';
import useToggle from 'App/utils/hooks/useToggle';

const ContainerFinancialsCard = ({
  transportationOrderId,
  shipmentId,
  orderItemId,
  index
}: {
  transportationOrderId: string;
  shipmentId: string;
  orderItemId: string;
  index: number;
}) => {
  // add order item display values
  const {queryInfo, getOrderItemById, getServiceProviderFinancialDisplayValuesFromOrderItem} = useTransportationOrder(
    transportationOrderId,
    shipmentId
  );

  const [isCollapsed, toggleIsCollapsed] = useToggle(true);

  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }

  const orderItem = getOrderItemById(orderItemId);

  // if the order item doesn't exist, or the order item type is not of type container order item,
  // something is wrong and we should not show the card
  if (!orderItem || !isContainerOrderItem(orderItem)) {
    return null;
  }

  const {associatedDebits, totalCharge} = getServiceProviderFinancialDisplayValuesFromOrderItem(orderItem);

  return (
    <Card
      draggableProvided={null}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={toggleIsCollapsed}
      bodyClassName="p-0"
      title={
        <div className="flex w-full flex-row items-center justify-between">
          <div>{generateContainerLabel(index, orderItem.identification_number, orderItem.seals?.[0]?.value)}</div>
          {isCollapsed ? (
            <div className="flex flex-row items-center">
              <div className="pr-2">
                <span className="font-normal">Line Items:</span> {associatedDebits.length}
              </div>
              <div className="rounded bg-sw-success-background px-2 py-3">
                <span className="font-normal">Amount Due:</span> <span className="text-sw-success">{totalCharge}</span>
              </div>
            </div>
          ) : null}
        </div>
      }
    >
      <CollapsibleCardContent>
        <LineItemsListView
          shipmentId={shipmentId}
          transportationOrderId={transportationOrderId}
          orderItemId={orderItemId}
        />
      </CollapsibleCardContent>
    </Card>
  );
};

export default ContainerFinancialsCard;
