import {useQuery} from '@tanstack/react-query';
import {WORKFLOW_TRIGGERING_EVENTS_KEY} from '../queryKeys';
import {getWorkflowTriggeringEvents} from 'App/api/workflows/typed';

export const useGetWorkflowTriggeringEvents = ({triggeringResourceIds}: {triggeringResourceIds: string[]}) => {
  const getWorkflowTriggeringEventsQuery = useQuery(
    [WORKFLOW_TRIGGERING_EVENTS_KEY, ...triggeringResourceIds],
    async () => {
      const response = await getWorkflowTriggeringEvents({triggeringResourceIds});
      return response.data;
    }
  );

  const {data: workflowTriggeringEventsData, status: workflowTriggeringEventsStatus} = getWorkflowTriggeringEventsQuery;
  return {workflowTriggeringEventsData, workflowTriggeringEventsStatus, getWorkflowTriggeringEventsQuery};
};
