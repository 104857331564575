import {MouseEvent} from 'react';
import {
  RfpLaneOptResponse,
  RFPBidOptContractWorkflowCreationRecordResponse,
  RFPBidOptContractWorkflowCreationRecordResponseWorkflowStatusEnum
} from '@shipwell/backend-core-sdk';
import {Card, CollapsibleCardContent, DisplayValue, Modal, Button} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import ShipwellLoader from 'App/common/shipwellLoader';
import {FlexBox} from 'App/components/Box';
import RoutingGuideActionDetails from 'App/containers/routingGuides/components/actionDetails';
import RoutingGuidePolicyDetails from 'App/containers/routingGuides/components/policyDetails';
import {toCurrency} from 'App/utils/grammar';
import useToggle from 'App/utils/hooks/useToggle';
import {useWorkflows} from 'App/utils/hooks/useWorkflows';
import {RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY} from 'App/data-hooks/queryKeys';

export const RoutingGuideCell = ({lane}: {lane: RfpLaneOptResponse}) => {
  const [showModal, toggleModal] = useToggle(false);

  const {policyQuery, workflowFormValuesQuery} = useWorkflows({
    isEnabled: !!(showModal && lane.policy),
    policyId: lane.policy || ''
  });
  const actions = workflowFormValuesQuery.data?.actions || [];

  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleModal();
  };

  const handleManageRoutingGuide = () => {
    const routingGuideUrl = `/automation/routing-guides/${lane.policy || ''}`;
    window.open(routingGuideUrl, '_blank');
  };

  const queryClient = useQueryClient();
  const creationRecord: RFPBidOptContractWorkflowCreationRecordResponse[] | undefined = queryClient.getQueryData([
    RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY,
    lane.rfp_bid_opt
  ]);
  const laneStatus = creationRecord?.find((record) => record.rfp_lane_opt === lane.id);

  if (!lane.policy) {
    switch (laneStatus?.workflow_status) {
      case RFPBidOptContractWorkflowCreationRecordResponseWorkflowStatusEnum.InProgress:
        return <span>Creating Routing Guide...</span>;
      case RFPBidOptContractWorkflowCreationRecordResponseWorkflowStatusEnum.WorkflowCreationFailed:
        return <span className="text-sw-error">Error Creating Routing Guide</span>;
      default:
        return <span>--</span>;
    }
  }

  return (
    <>
      <Button variant="tertiary" isCompact onClick={handleOpenModal}>
        {lane.name}
      </Button>
      <Modal show={showModal} title="Routing Guide" footerComponent={null} onClose={toggleModal}>
        <DisplayValue label="Routing Guide Name">{policyQuery?.data?.name}</DisplayValue>
        <RoutingGuidePolicyDetails policy={policyQuery?.data} />
        <Card title="Actions" isCollapsible={false} draggableProvided={() => undefined}>
          <CollapsibleCardContent>
            <FlexBox direction="col" gap="m">
              {workflowFormValuesQuery.isInitialLoading ? <ShipwellLoader loading /> : null}
              {actions?.map((action, index) => {
                const actionType = action.type?.label ?? null;
                const tenderToCompany = () => {
                  const involvedTenderToCompanyUser = action.involved_tender_to_company_users?.[0];
                  if (involvedTenderToCompanyUser && typeof involvedTenderToCompanyUser !== 'string') {
                    return involvedTenderToCompanyUser.carrierName;
                  }
                };
                const rate = toCurrency(action?.rate || 0);
                return (
                  <Card
                    draggableProvided={undefined}
                    key={index}
                    title={
                      <span>
                        Step {index + 1} {actionType ? `- ${actionType}` : ''}{' '}
                        {tenderToCompany() ? `- ${tenderToCompany() || ''}` : ''}{' '}
                        {action.type?.value === 'TENDER' ? <span className="text-sw-success">({rate})</span> : ''}
                      </span>
                    }
                    isCollapsible
                    isCollapsed
                  >
                    <CollapsibleCardContent>
                      <RoutingGuideActionDetails action={action} />
                    </CollapsibleCardContent>
                  </Card>
                );
              })}
            </FlexBox>
          </CollapsibleCardContent>
        </Card>
        <FlexBox justify="end" gap="m" pad={['t', 'm']}>
          <Button onClick={toggleModal} variant="secondary">
            Close
          </Button>
          <Button onClick={handleManageRoutingGuide} variant="primary">
            Manage Routing Guide
          </Button>
        </FlexBox>
      </Modal>
    </>
  );
};
