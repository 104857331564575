import PropTypes from 'prop-types';
import classnames from 'classnames';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import './styles.scss';
/**
 * Form Footer
 * A sticky footer for full form
 */
const ModalFormFooter = ({
  isSubmitting,
  isValid,
  primaryActionName,
  secondaryActionName,
  tertiaryActionName,
  onCancel,
  onPrimaryActionClick,
  onTertiaryActionClick,
  options,
  primaryActionDisabled,
  className,
  tertiaryActionDisabled
}) => (
  <div className={classnames('modal-form-footer', className)}>
    <div className="modal-form-footer__options">{options}</div>
    <div className="modal-form-footer__actions">
      {tertiaryActionName ? (
        <DeprecatedButton
          variant="tertiary"
          disabled={isSubmitting || tertiaryActionDisabled}
          onClick={onTertiaryActionClick}
        >
          {tertiaryActionName}
        </DeprecatedButton>
      ) : null}
      {onCancel && (
        <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={onCancel}>
          {secondaryActionName}
        </DeprecatedButton>
      )}
      <DeprecatedButton
        type="submit"
        disabled={!isValid || isSubmitting || primaryActionDisabled}
        loading={isSubmitting}
        onClick={onPrimaryActionClick}
      >
        {primaryActionName}
      </DeprecatedButton>
    </div>
  </div>
);

ModalFormFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  onCancel: PropTypes.func,
  onPrimaryActionClick: PropTypes.func,
  onTertiaryActionClick: PropTypes.func,
  primaryActionDisabled: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  primaryActionName: PropTypes.string,
  secondaryActionName: PropTypes.string,
  tertiaryActionName: PropTypes.string,
  className: PropTypes.string,
  tertiaryActionDisabled: PropTypes.bool
};

ModalFormFooter.defaultProps = {
  isValid: true,
  primaryActionName: 'Save',
  secondaryActionName: 'Cancel',
  className: null
};

export default ModalFormFooter;
