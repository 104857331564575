import {useState} from 'react';
import {Tabs, Tab, TabList, TabPanel, DeprecatedButton} from '@shipwell/shipwell-ui';
import {SlimShipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {LoadType, Facility} from '@shipwell/tempus-sdk';
import classNames from 'classnames';
import {SelectShipment} from 'App/components/shipments';
import {SearchResultCard} from 'App/containers/appointments/components/forms/SearchShipmentForm/components';
import {getFirstAndLastStops, getShipmentLane} from 'App/utils/stops';
import useToggle from 'App/utils/hooks/useToggle';
import SupplierAppointment from 'App/containers/appointments/components/forms/SupplierAppointment';

export type SearchShipmentFormProps = {
  onScheduleClick: (shipmentId: string) => void;
  onCancelClick: () => void;
  loadTypes: LoadType[];
  facility: Facility | null;
  clickedTime?: Date | null;
};
/**
 * Searches for shipments by reference number and allows the users to select from
 * one of the options. When an option is selected a card will appear with the details
 * about the shipment and they can move forward for scheduling.
 */
const SearchShipmentForm = ({
  onCancelClick,
  onScheduleClick,
  loadTypes,
  facility,
  clickedTime
}: SearchShipmentFormProps) => {
  const [tab, setTab] = useState<number>(0);
  const [selectedShipment, setSelectedShipment] = useState<SlimShipment | null>(null);
  const [showCreateApptTiles, toggleShowCreateApptTiles] = useToggle();

  const {firstStop, lastStop} = getFirstAndLastStops(selectedShipment);
  const lane = getShipmentLane(firstStop, lastStop);

  return (
    <div className={classNames({'h-96': !showCreateApptTiles, 'max-h-screen-75': showCreateApptTiles})}>
      <div className="flex h-full flex-row">
        {!showCreateApptTiles ? (
          <div className="flex shrink flex-col bg-sw-background px-8 py-6">
            <p>
              <strong>Schedule New Appointment</strong>
            </p>

            <p className="w-48 break-words">
              Schedule a new appointment by entering the Shipwell Reference Id or by selecting Custom Appointment to
              schedule for alternative freight.
            </p>
          </div>
        ) : null}
        {!showCreateApptTiles ? (
          <div className="flex grow flex-col px-8 py-6">
            <div className="grow">
              <Tabs selectedIndex={tab} onSelect={(tabIndex: number) => setTab(tabIndex)}>
                <TabList>
                  <Tab>By Shipment Search</Tab>
                </TabList>
                <TabPanel>
                  <div className="flex flex-col pt-4">
                    <SelectShipment
                      name="select-shipment"
                      clearable
                      label="Search by Shipment Reference Number"
                      required
                      onSelectChanged={(shipment: SlimShipment | null) => setSelectedShipment(shipment)}
                      onSelectCreateAppt={toggleShowCreateApptTiles}
                    />
                    {selectedShipment ? (
                      <div className="mt-4">
                        <SearchResultCard
                          referenceId={selectedShipment.reference_id}
                          lane={lane}
                          loadType={'--'}
                          totalAppointmentsRequired={selectedShipment?.stops?.length}
                          carrierName={selectedShipment?.current_carrier?.name}
                          plannedDateRange={`${firstStop?.planned_date ?? ''} • ${lastStop?.planned_date ?? ''}`}
                          onScheduleClick={() => onScheduleClick(selectedShipment?.id)}
                        />
                      </div>
                    ) : null}
                  </div>
                </TabPanel>
              </Tabs>
            </div>

            <div className="mt-4 flex flex-row justify-end">
              <DeprecatedButton onClick={onCancelClick} variant="secondary">
                Cancel
              </DeprecatedButton>
            </div>
          </div>
        ) : (
          <SupplierAppointment
            onCancel={onCancelClick}
            loadTypes={loadTypes}
            facility={facility}
            clickedTime={clickedTime}
          />
        )}
      </div>
    </div>
  );
};

export default SearchShipmentForm;
