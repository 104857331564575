import {useState} from 'react';
import {AxiosError} from 'axios';
import {User, ShipwellError, CreateSpotNegotiationQuote} from '@shipwell/backend-core-singlerequestparam-sdk';
import useCreateSpotNegotiationQuote from 'App/api/quoting/hooks/useCreateSpotNegotiationQuote';
import useCreateSpotNegotiationFromLoadboard from 'App/api/quoting/hooks/useCreateSpotNegotiationFromLoadboard';
import useCreateSpotNegotiationQuoteDocument from 'App/api/quoting/hooks/useCreateSpotNegotiationQuoteDocument';
import {transformAxiosError} from 'App/utils/globalsTyped';
import {UploadDocumentType} from 'App/containers/shipments/details/components/SidebarDocuments/UploadDocuments';

interface UsePlaceBidParams {
  spotNegotiationId?: string | null;
  loadBoardShipmentId: string;
  setError?: (title: string, message: string | JSX.Element) => void;
  user?: User;
  documentToUpload?: UploadDocumentType | null;
  handlePlaceBidSuccess: (spotNegotiationId: string) => Promise<void>;
  handleClose: () => void;
  handleSettled?: () => void;
}

export const usePlaceBid = ({
  spotNegotiationId,
  loadBoardShipmentId,
  setError,
  user,
  documentToUpload,
  handlePlaceBidSuccess,
  handleClose,
  handleSettled
}: UsePlaceBidParams) => {
  const [uploadDocumentError, setUploadDocumentError] = useState<{
    spotNegotiationId: string;
    spotNegotiationQuoteId: string;
  } | null>(null);

  const handleSuccess = async (spotNegotiationId: string) => {
    await handlePlaceBidSuccess(spotNegotiationId);
    handleClose();
  };

  const onError = (error: AxiosError<ShipwellError>) => {
    if (setError) {
      const {title, message} = transformAxiosError(error);
      setError(title, message);
    }
    console.error(error);
  };

  //the various mutations that handle quote creation
  const {mutate: createSpotNegotiationFromLoadboard} = useCreateSpotNegotiationFromLoadboard({
    onError,
    onSettled: (data, error) => {
      //only call handleSettled if there was an error
      if (error) handleSettled?.();
    }
  });

  const {mutate: createSpotNegotiationQuote} = useCreateSpotNegotiationQuote({
    onError,
    onSuccess: async ({data}, variables) => {
      if (documentToUpload && data.id) {
        void handlePlaceBidSuccess(variables.spotNegotiationId);
        handleCreateSpotNegotiationQuoteDocument({
          spotNegotiationId: variables.spotNegotiationId,
          spotNegotiationQuoteId: data.id
        });
      } else {
        await handleSuccess(variables.spotNegotiationId);
      }
    },
    onSettled: () => {
      if (!documentToUpload) handleSettled?.();
    }
  });

  const {mutate: createSpotNegotiationQuoteDocument} = useCreateSpotNegotiationQuoteDocument({
    onSuccess: async (data, variables) => await handleSuccess(variables.spotNegotiationId),
    onError: (error, variables) => {
      if (setError) {
        const {message} = transformAxiosError(error);
        setError('Error during file upload', message);
      }
      setUploadDocumentError({
        spotNegotiationId: variables.spotNegotiationId,
        spotNegotiationQuoteId: variables.spotNegotiationQuoteId
      });
    },
    onSettled: () => handleSettled?.()
  });

  //uploads a document to the quote
  const handleCreateSpotNegotiationQuoteDocument = ({
    spotNegotiationId,
    spotNegotiationQuoteId
  }: {
    spotNegotiationId: string;
    spotNegotiationQuoteId: string;
  }) => {
    //if there is no document to upload just close modal
    if (!documentToUpload) return handleClose();

    createSpotNegotiationQuoteDocument({
      spotNegotiationId,
      spotNegotiationQuoteId,
      ...documentToUpload
    });
  };

  //the main star, handles all logic around placing bids
  const createLoadboardBid = ({quote}: {quote: CreateSpotNegotiationQuote}) => {
    if (uploadDocumentError) return handleCreateSpotNegotiationQuoteDocument(uploadDocumentError);
    if (spotNegotiationId) return createSpotNegotiationQuote({spotNegotiationId, quote});
    if (!user) return;

    createSpotNegotiationFromLoadboard(
      {
        loadBoardShipmentId,
        spotNegotiation: {
          involved_vendor_users: [user],
          involved_customer_users: []
        }
      },
      {
        onSuccess: ({data: {id: spotNegotiationId}}) =>
          spotNegotiationId && createSpotNegotiationQuote({spotNegotiationId, quote})
      }
    );
  };

  return {createLoadboardBid, uploadDocumentError};
};
