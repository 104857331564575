import {ShipmentOverallStatus} from '@shipwell/corrogo-sdk';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useCancelShipment, useV3Shipment} from 'App/data-hooks';

const CancelShipment = ({shipmentId}: {shipmentId: string}) => {
  const {data, isLoading} = useV3Shipment(shipmentId);
  const isCancelled = data?.overall_status === ShipmentOverallStatus.Cancelled;
  const {cancelShipment, cancelLegMutation} = useCancelShipment(shipmentId);
  return (
    <DeprecatedButton
      disabled={isLoading || isCancelled || cancelLegMutation.isLoading}
      variant="text"
      onClick={() => void cancelShipment()}
    >
      Cancel Shipment
    </DeprecatedButton>
  );
};

export default CancelShipment;
