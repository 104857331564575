import {useState} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {useShipmentDashboardQuery} from 'App/data-hooks';

export const DrayageContainerNumberFilter = ({
  defaultContainerNumbers,
  onChange
}: {
  defaultContainerNumbers: string[];
  onChange: (newNumbers: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);

  const {data, isFetching} = useShipmentDashboardQuery({
    ...(debouncedSearchVal ? {drayageContainerNumberContains: debouncedSearchVal} : {}),
    pageSize: 100,
    ordering: 'drayage_container_number',
    optimized: true
  });

  const options =
    data?.results?.reduce((options: {label: string; value: string}[], shipment) => {
      if (shipment.drayage_container_number) {
        options.push({
          label: shipment.drayage_container_number || '',
          value: shipment.drayage_container_number || ''
        });
      }
      return options;
    }, []) || [];

  return (
    <Select
      value={defaultContainerNumbers.map((containerNumber) => ({label: containerNumber, value: containerNumber}))}
      onInputChange={setSearchVal}
      onChange={(options: {label: string; value: string}[]) => {
        onChange(options.map((option) => option.value));
      }}
      isLoading={isFetching}
      options={options}
      isMulti
      label="Search Container Numbers"
      getOptionValue={(option: {label: string; value: string}) => option.value}
      getOptionLabel={(option: {label: string; value: string}) => option.label}
    />
  );
};
