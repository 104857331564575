import filter from 'lodash/filter';

export type DocumentType = {
  created_at?: string;
  created_by_user_id?: string;
  description: string;
  filename: string;
  id?: string;
  type: string;
  url?: string;
};

export type UploadDocumentVariablesType = {
  file: File;
  description: string;
  type: string;
};

export type UpdateDocumentVariablesType = {
  documentId: string;
  description: string;
  type: string;
};

export type DeleteDocumentVariablesType = {
  documentId: string;
};

export const mergeUploadDocument = (documents?: DocumentType[], variables?: unknown): DocumentType[] => {
  const {description, file, type} = variables as UploadDocumentVariablesType;
  const existingDocuments: DocumentType[] = documents || [];
  const document: DocumentType = {
    created_at: new Date().toISOString(),
    description,
    filename: file.name || '',
    type
  };
  return variables ? [...existingDocuments, document] : existingDocuments;
};

export const mergeUpdateDocument = (documents?: DocumentType[], variables?: unknown): DocumentType[] => {
  const {description, documentId, type} = variables as UpdateDocumentVariablesType;
  return (
    documents?.map((document) => {
      return document.id === documentId ? {...document, description, type} : document;
    }) || []
  );
};

export const mergeDeleteDocument = (documents?: DocumentType[], variables?: unknown): DocumentType[] => {
  const {documentId} = variables as DeleteDocumentVariablesType;
  const existingDocuments: DocumentType[] = documents || [];
  return filter(existingDocuments, ({id}) => id !== documentId);
};
