import {useState, useEffect, useCallback} from 'react';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {FileDisplay, SvgIcon} from '@shipwell/shipwell-ui';
import {getShipmentDocuments, editShipmentPromise} from 'App/api/shipment';
import {getEmailNotificationTypesPromise, sendEmailNotificationPromise} from 'App/api/shipwellUI';
import DocumentSelect, {NoDocuments} from 'App/formComponents/fields/documentSelect';
import withStatusToasts from 'App/components/withStatusToasts';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import EmailShipmentContacts from 'App/formComponents/forms/emailShipmentContacts';
import './styles.scss';
import ShipwellLoader from 'App/common/shipwellLoader';
import {SEND_DOCUMENTS, EMAIL_CONTACTS, emailContexts} from 'App/formComponents/forms/sendShipmentDocuments/constants';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {useViewZpl} from 'App/components/DocumentView/useViewZpl';

const SendShipmentDocuments = ({
  shipmentId,
  setError,
  onSuccess,
  onSubmit,
  onCancel,
  mode,
  initialSelectedDocuments,
  context = emailContexts.SHIPMENT_DETAILS
}) => {
  const [step, setStep] = useState(mode);
  const [documents, setDocuments] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState(initialSelectedDocuments || []);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [previewingDocument, setPreviewingDocument] = useState(null);
  const [message, setMessage] = useState('');
  const [additionalEmails, setAdditionalEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {queryInfo: shipmentQuery} = useGetFullShipmentDetails(shipmentId, {
    enabled: Boolean(shipmentId),
    staleTime: 20000,
    onSuccess: (data) => setAdditionalEmails(data.additional_bol_recipients || [])
  });
  const shipment = shipmentQuery.data;

  const fetchShipmentDocuments = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getShipmentDocuments(shipmentId, {pageSize: 100});
      setDocuments(response.body?.results || []);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
    setLoading(false);
  }, [shipmentId, setError]);

  const fetchNotificationTypes = useCallback(async () => {
    try {
      const response = await getEmailNotificationTypesPromise();
      setNotificationTypes(response.body?.email_notification_types);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  }, [setError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step === SEND_DOCUMENTS) {
      setStep(EMAIL_CONTACTS);
    } else {
      setSubmitting(true);
      try {
        await sendEmailNotificationPromise({
          shipment_documents: selectedDocuments,
          message: message,
          recipients: [...additionalEmails, ...selectedContacts.map((user) => user.email)],
          shipment_id: shipmentId,
          type: notificationTypes[0]
        });
        if (additionalEmails.length > 0) {
          //update the shipment with these emails
          await updateShipmentContacts(additionalEmails);
        }
        onSuccess(
          'Email Successfully Sent!',
          `Your email was sent to the ${pluralize('contact', selectedContacts.length)} for Shipment ${
            shipment.reference_id
          } ${
            selectedDocuments.length
              ? `with ${selectedDocuments.length} ${pluralize('attachment', selectedDocuments.length)}`
              : ''
          }`
        );
        onCancel();
      } catch (error) {
        console.error(error);
        setError('Error!', error.error_description);
        setSubmitting(false);
      }
    }
  };

  const updateShipmentContacts = async (additionalEmails) => {
    try {
      await editShipmentPromise(shipmentId, {...shipment, additional_bol_recipients: additionalEmails});
      if (onSubmit) {
        onSubmit(); //trigger refresh of dashboard
      }
    } catch (error) {
      console.error('Error updating shipment details - ', error);
      //we don't want this error to stop the user - the email has been sent successfully already
      return;
    }
  };

  useEffect(() => {
    if (shipmentId) {
      fetchShipmentDocuments();
      fetchNotificationTypes();
    }
  }, [fetchShipmentDocuments, fetchNotificationTypes, shipmentId]);

  const isZpl = previewingDocument?.filename?.includes('zpl');
  const generateZplImageQuery = useViewZpl({document: previewingDocument, isEnabled: isZpl});
  const zplImage = generateZplImageQuery?.data;
  const zplStatus = generateZplImageQuery?.status;

  return (
    <form onSubmit={handleSubmit}>
      <div className="sendShipmentDocuments">
        {loading || shipmentQuery.isLoading ? (
          <ShipwellLoader loading />
        ) : (
          <>
            {step === SEND_DOCUMENTS && (
              <>
                <div>
                  Select one or more documents to email to the shipment contacts. Click the document thumbnails to see a
                  larger view of the document.
                </div>
                <div className="sendShipmentDocuments__container field-grid grid-1-12">
                  <div className="grid-item-1-6">
                    {documents.length ? (
                      <DocumentSelect
                        documents={documents}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                        previewingDocument={previewingDocument}
                        onClickDocument={setPreviewingDocument}
                      />
                    ) : (
                      <NoDocuments />
                    )}
                  </div>
                  <div className="grid-item-7-12">
                    {isZpl ? (
                      zplStatus === 'loading' ? (
                        <ShipwellLoader loading />
                      ) : zplStatus === 'error' ? (
                        <span>There was an error loading your ZPL label.</span>
                      ) : (
                        <img className="mx-auto h-full rotate-180" src={zplImage} alt="ZPL label" />
                      )
                    ) : (
                      <FileDisplay
                        maxHeight={420}
                        maxWidth={420}
                        fileURL={isZpl ? zplImage : previewingDocument?.file}
                        fileName={previewingDocument?.filename}
                        emptyText="Select a document to preview here"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {step === EMAIL_CONTACTS && (
              <EmailShipmentContacts
                shipment={shipment}
                message={message}
                context={context}
                addAttachment={() => setStep(SEND_DOCUMENTS)}
                selectedDocuments={documents.filter((doc) => selectedDocuments.includes(doc?.id))}
                setSelectedDocuments={setSelectedDocuments}
                selectedContacts={selectedContacts}
                onChangeContacts={setSelectedContacts}
                onChangeMessage={setMessage}
                onChangeAdditionalEmails={setAdditionalEmails}
                selectedAdditionalEmails={additionalEmails}
              />
            )}
          </>
        )}
      </div>

      <ModalFormFooter
        options={
          <>
            {mode !== step && (
              <span className="modal-form-footer__options-back clickable" onClick={() => setStep(mode)}>
                <SvgIcon name="ArrowBack" />
                Back
              </span>
            )}
          </>
        }
        isSubmitting={submitting}
        onCancel={onCancel}
        isValid={
          (step === SEND_DOCUMENTS && selectedDocuments.length > 0) ||
          (step === EMAIL_CONTACTS &&
            (selectedContacts.length > 0 || additionalEmails?.length > 0) &&
            message.length > 0 &&
            (mode === EMAIL_CONTACTS || selectedDocuments.length > 0))
        }
        primaryActionName={step === SEND_DOCUMENTS && mode === EMAIL_CONTACTS ? 'Attach' : 'Email'}
      />
    </form>
  );
};

SendShipmentDocuments.propTypes = {
  shipmentId: PropTypes.string,
  setError: PropTypes.func,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  mode: PropTypes.string,
  initialSelectedDocuments: PropTypes.array,
  context: PropTypes.string
};

export default compose(withStatusToasts)(SendShipmentDocuments);
