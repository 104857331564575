import React, {JSX, useState} from 'react';
import {Checkbox, Button, Popover} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';

export type AppointmentStatusSelectProps = {
  disabled?: boolean;
  /**
   * Callback handler for when the value changes
   * @param {AppointmentStatusEnum[]|undefined} value one or more AppointmentStatusEnum values selected for filtering
   * @returns {void}
   */
  onChange: (value?: AppointmentStatusEnum[]) => void;
};

/**
 * Lightweight wrapper around `import('@shipwell/shipwell-ui').Select` that provides a list of AppointmentStatusEnum values as options
 * @param {AppointmentStatusSelectProps} props
 * @returns {JSX.Element}
 */
const AppointmentStatusSelect = ({disabled, onChange}: AppointmentStatusSelectProps): JSX.Element => {
  const options = enumToOptionConverter(AppointmentStatusEnum);
  const [selectedValues, setSelectedValues] = useState<AppointmentStatusEnum[]>([
    'UNSCHEDULED',
    'SCHEDULED',
    'RESCHEDULED',
    'CANCELLED',
    'ARRIVED',
    'ARRIVED_LATE',
    'DELAYED',
    'DOCK_IN_USE',
    'REJECTED',
    'NO_SHOW',
    'COMPLETED',
    'DOCK_IS_READY'
  ]);

  const handleCheckboxValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let values: AppointmentStatusEnum[] = [];
    if (event.target.checked) {
      values = [...selectedValues, event.target.value as AppointmentStatusEnum];
      setSelectedValues(values);
    } else {
      values = selectedValues.filter((value) => value !== (event.target.value as AppointmentStatusEnum));
      setSelectedValues(values);
    }

    onChange?.(values);
  };

  return (
    <Popover
      placement="bottom-start"
      showArrow={false}
      portal
      trigger={({
        setIsOpen,
        setTriggerElement
      }: {
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setTriggerElement: (el: HTMLElement | null) => void;
      }) => (
        <Button
          ref={setTriggerElement}
          onClick={() => setIsOpen((prev) => !prev)}
          variant="secondary"
          name="filterBtn"
          size="lg"
          iconName="Filter"
          aria-label="Filter"
          iconSide="right"
          disabled={disabled}
        >
          {selectedValues?.length || 0} Filter(s)
        </Button>
      )}
    >
      <ul className="py-1">
        {options.map(({value, label}) => (
          <li key={value} className="px-2 hover:bg-sw-text-reverse-alternate">
            <Checkbox
              id={`${value}-checkbox`}
              label={label}
              onChange={handleCheckboxValueChanged}
              value={value}
              checked={selectedValues.includes(value as AppointmentStatusEnum)}
            />
          </li>
        ))}
      </ul>
    </Popover>
  );
};

export default AppointmentStatusSelect;
