import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

export const stopFlagAlertTypes = [
  {id: 'at_risk'},
  {id: 'delayed'},
  {id: 'early'},
  {id: 'late'},
  {id: 'other', label: 'Alert'},
  {id: 'waiting'},
  {id: 'detention'}
];

export const stopFlagAlertTypeMap = keyBy(stopFlagAlertTypes, (alertType) => alertType.id);

const stopFlagsAlertTypeApi = stopFlagAlertTypes.map((stopFlagAlertType) => stopFlagAlertType.id.toUpperCase());

export const stopFlagPropType = PropTypes.shape({
  alert_type: PropTypes.oneOf(stopFlagsAlertTypeApi),
  reason_code: PropTypes.string
});
