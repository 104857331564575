import {useMutation} from '@tanstack/react-query';
import {DrayageTransportationOrder, OrderStatus} from '@shipwell/corrogo-sdk';
import {revokeTransportationOrder} from 'App/api/corrogo/typed';
import {TRANSPORTATION_ORDER_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

const useUpdateTransportationOrder = (transportationOrderId: string, shipmentId: string) => {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DrayageTransportationOrder[]>();

  const revokeTransportationOrderMutation = useMutation(
    () => revokeTransportationOrder(transportationOrderId),
    createOptimisticUpdateHandlers([TRANSPORTATION_ORDER_QUERY_KEY, shipmentId], {
      mergeData: (previousTransportationOrders) =>
        previousTransportationOrders?.map((transportationOrder) =>
          transportationOrder.id === transportationOrderId
            ? {...transportationOrder, status: OrderStatus.Revoked}
            : transportationOrder
        ) || []
    })
  );

  return {revokeTransportationOrderMutation};
};

export default useUpdateTransportationOrder;
