import {Component} from 'react';
import {AsyncCreatable} from 'react-select';
import FormGroup from '../../formGroup';
import {validateEmail} from 'App/utils/globals';
import {getAddressBookPromise} from 'App/actions/addressBook';

/**
 * Email Search Field
 */
class EmailSearchField extends Component {
  static defaultProps = {
    placeholder: '',
    multi: true,
    autoload: true,
    value: '',
    name: '',
    input: {}
  };

  /**
   * Search emails from address book by query
   * @param  {String} value Search input value
   */
  async searchAddressBook(value) {
    try {
      const results = {options: []};
      const response = await getAddressBookPromise({query: value});

      if (response) {
        results.options = this.filterEmailResults(response);
      }
      return results;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Normalize email results from address book response
   * @param  {Object} resp Address Book api response
   * @return {Array}       List of unique email addresses
   */
  filterEmailResults(resp) {
    const emailSet = new Set();
    const emails = [];

    if (resp && resp.results) {
      resp.results.forEach((result) => {
        result.point_of_contacts.forEach(({email}) => {
          if (!emailSet.has(email)) {
            emailSet.add(email);
            emails.push({label: email, value: email});
          }
        });
      });
    }
    return emails;
  }

  /**
   * Prompt text for creatable value
   * @param  {String} value Input value
   * @return {String}       Prompt text
   */
  renderPromptText(value) {
    const hasCommaOrSimicolon = /(,|;)/.test(value);
    const isValid = validateEmail(value) || hasCommaOrSimicolon;

    if (!isValid) {
      return `Invalid email: ${value}`;
    }
    return `Add new email: ${value}`;
  }

  render() {
    const {input, placeholder, multi, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const onChange = input.onChange || rest.onChange;

    return (
      <FormGroup {...this.props}>
        {() => (
          <AsyncCreatable
            {...rest}
            multi={multi}
            name={name}
            value={value}
            placeholder={placeholder}
            loadOptions={this.searchAddressBook.bind(this)}
            promptTextCreator={this.renderPromptText.bind(this)}
            onChange={(value) => onChange && onChange(value)}
          />
        )}
      </FormGroup>
    );
  }
}

export default EmailSearchField;
