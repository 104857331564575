import {LoadboardType} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {deleteLoadPost} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

type DeleteLoadPostPayload = {
  loadboardType: LoadboardType;
  loadpostId: string;
};

const useDeleteLoadPost = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof deleteLoadPost>>, unknown, DeleteLoadPostPayload>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof deleteLoadPost>>, unknown, DeleteLoadPostPayload>(
    ({loadboardType, loadpostId}) => deleteLoadPost(loadboardType, loadpostId),
    {
      onSettled: () => queryClient.invalidateQueries([LOADPOST_KEY]),
      ...mutationOptions
    }
  );
};

export default useDeleteLoadPost;
