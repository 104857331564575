import {
  DisplayValue,
  Title,
  DeprecatedButton,
  Card,
  Dropdown,
  SvgIcon,
  Pill,
  TwoColumnLayoutLeft,
  TwoColumnLayoutRight
} from '@shipwell/shipwell-ui';
import {DrayageLegOverallStatus, OrderStatus} from '@shipwell/corrogo-sdk';
import {DraggableProvided} from 'react-beautiful-dnd';
import noop from 'lodash/noop';
import classnames from 'classnames';
import upperCase from 'lodash/upperCase';
import {Link} from 'react-router';
import {generateContainerLabel, isContainerOrderItem, isLegStage} from 'App/containers/shipments/utils/typed';
import {NotesModal} from 'App/containers/shipments/components/CarriersField';
import TenderModal from 'App/containers/shipments/components/TenderFields';
import {useDrayageLeg, useCarrierRelationships, useV3Shipment, useShipmentFromLeg} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import Loader from 'App/common/shipwellLoader';
import withStatusToasts, {WithStatusToastsDefaultProps} from 'App/components/withStatusToasts';
import useTransportationOrders from 'App/data-hooks/orders/useTransportationOrders';

const DrayageShipmentCarrierViewCardBase = ({
  shipmentId,
  draggableProvided,
  setSuccess
}: {
  shipmentId: string;
  draggableProvided?: DraggableProvided;
  setSuccess(title: string, description: string): void;
}) => {
  const [showNotesModal, toggleShowNotesModal] = useToggle();
  const [showTenderModal, toggleShowTenderModal] = useToggle();

  const handleCreateTender = () => setSuccess('Success!', 'Successfully created tender request.');

  return (
    <Card
      title="Carrier"
      className="mb-2"
      isCollapsible
      draggableProvided={draggableProvided}
      actions={
        <CarriersViewActionsDropDown
          shipmentId={shipmentId}
          toggleShowTenderModal={toggleShowTenderModal}
          toggleShowNotesModal={toggleShowNotesModal}
        />
      }
    >
      <DrayageShipmentCarrierView shipmentId={shipmentId} toggleShowTenderModal={toggleShowTenderModal} />
      {showNotesModal ? (
        <NotesModal shipmentId={shipmentId} showModal={showNotesModal} onClose={toggleShowNotesModal} />
      ) : null}
      {showTenderModal ? (
        <TenderModal
          showModal={showTenderModal}
          onClose={toggleShowTenderModal}
          shipmentId={shipmentId}
          onSuccess={handleCreateTender}
        />
      ) : null}
    </Card>
  );
};

DrayageShipmentCarrierViewCardBase.defaultProps = {
  ...WithStatusToastsDefaultProps
};

export const DrayageShipmentCarrierViewCard = withStatusToasts(DrayageShipmentCarrierViewCardBase);

export const DrayageShipmentCarrierView = ({
  expanded,
  shipmentId,
  toggleShowTenderModal
}: {
  expanded: boolean;
  shipmentId: string;
  toggleShowTenderModal(): void;
}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {isCancelled} = shipmentQuery.context;
  const {
    getTransportationOrdersOfStatus,
    queryInfo: {isLoading: transportationOrdersQueryIsLoading}
  } = useTransportationOrders(shipmentId);
  const requestedTenders = getTransportationOrdersOfStatus(OrderStatus.Requested);
  const assignedTenders = getTransportationOrdersOfStatus(OrderStatus.Accepted);
  const drayageStages = shipmentQuery.data?.stages?.filter(
    (stage) => isLegStage(stage) && stage.leg.overall_status !== DrayageLegOverallStatus.Cancelled
  );

  const requestedContainerIds = new Set(requestedTenders?.map((tender) => tender.leg_ids).flat());

  const numOfContainersRequested = requestedContainerIds.size || 0;
  const numOfContainersAssigned =
    assignedTenders?.reduce(
      (numOfContainersTendered, acceptedTender) => numOfContainersTendered + acceptedTender.order_items.length,
      0
    ) || 0;

  const numOfContainersUnassigned = (drayageStages?.length ?? 0) - numOfContainersAssigned;

  if (shipmentQuery.isInitialLoading || transportationOrdersQueryIsLoading) {
    return <Loader loading />;
  }

  return (
    <>
      <div className="flex justify-between pb-2">
        <DisplayValue label="Unassigned">{numOfContainersUnassigned}</DisplayValue>
        <DisplayValue label="Requested">{numOfContainersRequested}</DisplayValue>
        <DisplayValue label="Assigned">{numOfContainersAssigned}</DisplayValue>
      </div>

      {expanded ? (
        <div className="border-t border-sw-border">
          {assignedTenders?.length ? (
            <>
              <Title className="mb-0 pt-2" variant="sectionTitle">
                Assigned
              </Title>
              {assignedTenders.map((tender) => (
                <div className="border-b border-sw-border py-2" key={tender.id}>
                  <CarrierDetailsSection
                    shipmentId={shipmentId}
                    transportationOrderId={tender.id}
                    carrierId={tender.service_provider.provider_id}
                    showContainers
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="flex flex-col items-center border-b border-sw-border pb-4">
              <Title className="pb-2 pt-4" variant="emptyStateHeader">
                No Carriers Assigned
              </Title>
              <DeprecatedButton
                variant="primary"
                size="small"
                className="w-full"
                disabled={isCancelled}
                onClick={toggleShowTenderModal}
              >
                Tender To Carrier
              </DeprecatedButton>
            </div>
          )}
          <NotesSection shipmentId={shipmentId} />
        </div>
      ) : null}
    </>
  );
};

DrayageShipmentCarrierView.defaultProps = {
  expanded: true
};

export const CarrierDetailsSection = ({
  carrierId,
  shipmentId,
  transportationOrderId,
  showContainers
}: {
  carrierId: string;
  shipmentId: string;
  transportationOrderId: string;
  showContainers: boolean;
}) => {
  const {getTransportationOrderItems} = useTransportationOrders(shipmentId);
  const {
    isLoading,
    context: {carrierDisplayValues}
  } = useCarrierRelationships({id: [carrierId]});
  const containers = getTransportationOrderItems(transportationOrderId) || [];
  const carrierInformation = carrierDisplayValues[0];
  const carrierRelationshipId = carrierInformation?.carrierId;
  const carrierName = carrierInformation?.name;
  const status = carrierInformation?.carrierStatus;
  const contacts = carrierInformation?.contacts || [];
  const usDotNumber = carrierInformation?.usdotNumber;
  const mcNumber = carrierInformation?.mcNumber;

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <>
      <DisplayValue label="Name">
        <div className="flex flex-col">
          <div className="mb-0.5 flex items-center gap-2">
            {carrierRelationshipId ? <Link to={`/carriers/${carrierRelationshipId}`}>{carrierName}</Link> : carrierName}
            <Pill variant="primary">{upperCase(status)}</Pill>
          </div>
        </div>
      </DisplayValue>
      <DisplayValue label="Contacts">
        {contacts.map((contact, index) => (
          <div key={index} className="py-1">
            <div>{contact.name}</div>
            <div>{contact.email && <a href={`mailto:${contact.email}`}>{contact.email}</a>}</div>
            <div>{contact.phone && getPhoneHyperlink(contact.phone)}</div>
          </div>
        ))}
      </DisplayValue>
      <div className="flex">
        <TwoColumnLayoutLeft className="mr-10">
          <DisplayValue label="US DOT #">{usDotNumber}</DisplayValue>
        </TwoColumnLayoutLeft>
        <TwoColumnLayoutRight>
          <DisplayValue label="MC #">{mcNumber}</DisplayValue>
        </TwoColumnLayoutRight>
      </div>
      {showContainers ? (
        <DisplayValue label={`Containers (${containers?.length})`} isCollapsible isCollapsed={false}>
          {containers?.map((container, index) => {
            if (!isContainerOrderItem(container)) {
              return null;
            }
            return `${generateContainerLabel(index, container.identification_number)} ${
              index !== containers.length - 1 ? '•' : ''
            } `;
          })}
        </DisplayValue>
      ) : null}
    </>
  );
};

CarrierDetailsSection.defaultProps = {
  carrierId: '',
  shipmentId: '',
  transportationOrderId: '',
  showContainers: false
};

export const DrayageLegCarrierViewCard = ({
  legId,
  draggableProvided
}: {
  legId: string;
  draggableProvided?: DraggableProvided;
}) => {
  const drayageLegQuery = useDrayageLeg(legId);
  const shipmentQuery = useShipmentFromLeg(drayageLegQuery.data);
  const {
    context: {shipmentId}
  } = shipmentQuery;

  if (drayageLegQuery.isInitialLoading || shipmentQuery.isInitialLoading || !shipmentId) {
    return <Loader loading />;
  }
  return (
    <Card title="Carrier" className="mb-2" isCollapsible draggableProvided={draggableProvided}>
      <DrayageLegCarrierView shipmentId={shipmentId} legId={legId} />
    </Card>
  );
};

const DrayageLegCarrierView = ({
  expanded,
  shipmentId,
  legId
}: {
  expanded: boolean;
  shipmentId: string;
  legId: string;
}) => {
  const {getTransportationOrdersOfStatus} = useTransportationOrders(shipmentId);
  const assignedTender = (getTransportationOrdersOfStatus(OrderStatus.Accepted) || []).find((tender) =>
    tender.leg_ids?.includes(legId)
  );

  return (
    <>
      <div
        className={classnames({
          'border-b border-sw-border': expanded
        })}
      >
        {assignedTender ? (
          <CarrierDetailsSection carrierId={assignedTender.service_provider.provider_id} />
        ) : (
          <div className="flex flex-col items-center pb-4">
            <Title className="py-10" variant="emptyStateHeader">
              No Carrier Assigned
            </Title>
          </div>
        )}
      </div>
      {expanded ? <NotesSection shipmentId={shipmentId} /> : null}
    </>
  );
};

DrayageLegCarrierView.defaultProps = {
  expanded: true
};

const CarriersViewActionsDropDown = ({
  toggleShowNotesModal,
  toggleShowTenderModal = noop,
  shipmentId
}: {
  toggleShowNotesModal(): void;
  toggleShowTenderModal?: () => void;
  shipmentId: string;
}) => {
  const {
    context: {isCancelled}
  } = useV3Shipment(shipmentId);
  return (
    <Dropdown variant="icon" disabled={isCancelled} indicator={false} icon={<SvgIcon name="Overflow" />}>
      {({onClick}: {onClick(): void}) => (
        <>
          <li
            onClick={() => {
              onClick();
              toggleShowTenderModal();
            }}
          >
            Tender to Carrier
          </li>

          <li
            onClick={() => {
              onClick();
              toggleShowNotesModal();
            }}
          >
            Edit Notes for Carrier
          </li>
        </>
      )}
    </Dropdown>
  );
};

const NotesSection = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const instructions = shipmentQuery.data?.instructions || '--';

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }
  return (
    <div>
      <Title className="py-2" variant="sectionTitle">
        Notes for Carrier
      </Title>
      <DisplayValue label="Notes">{instructions}</DisplayValue>
    </div>
  );
};
