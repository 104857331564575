import {connect} from 'react-redux';
import {Field, reduxForm, Form, change} from 'redux-form';
import {Button, Row, Col, FormControl, FormGroup, ControlLabel} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import validate from 'App/utils/validateDriverForm';
import {states} from 'App/utils/globals';

let DriverForm = (props) => {
  const {handleSubmit, submitting, back, users} = props;
  return (
    <Form onSubmit={handleSubmit} className="formstyle-light driver-form">
      <Paper className="driver-form-card">
        <Row>
          <Col sm={12}>
            <FormGroup>
              <FormControl
                componentClass="select"
                defaultValue="select"
                onChange={(e) => {
                  if (e.target.value !== 'select') {
                    const user = JSON.parse(e.target.value);
                    props.dispatch(change('DriverForm', 'user.first_name', user.first_name));
                    props.dispatch(change('DriverForm', 'user.last_name', user.last_name));
                    props.dispatch(change('DriverForm', 'user.email', user.email));
                    props.dispatch(change('DriverForm', 'phone_number', user.phone_number));
                  } else {
                    props.dispatch(change('DriverForm', 'user.first_name', ''));
                    props.dispatch(change('DriverForm', 'user.last_name', ''));
                    props.dispatch(change('DriverForm', 'user.email', ''));
                    props.dispatch(change('DriverForm', 'phone_number', null));
                  }
                }}
              >
                <option value="select">Select a user</option>
                {users &&
                  users.results &&
                  users.results.length > 0 &&
                  users.results.map((el, idx) => {
                    return (
                      <option key={el.id} value={JSON.stringify(el)}>
                        {el.first_name + ' ' + el.last_name}
                      </option>
                    );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              label="First Name"
              name="user.first_name"
              component={renderField}
              type="text"
              placeholder="First Name"
              req
            />
          </Col>
          <Col sm={6}>
            <Field
              label="Last Name"
              name="user.last_name"
              component={renderField}
              type="text"
              placeholder="Last Name"
              req
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field label="email" name="user.email" component={renderField} type="text" placeholder="Email" />
          </Col>
          <Col sm={6}>
            <Field
              label="phone"
              name="phone_number"
              component={renderPhoneNumberField}
              type="text"
              placeholder="Phone number"
            />
          </Col>
        </Row>
      </Paper>
      <div className="driver__modal__footer-btns">
        <Button bsStyle="default" disabled={submitting} onClick={back}>
          Cancel
        </Button>
        <Button bsStyle="primary" disabled={submitting} type="submit">
          {submitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

DriverForm = reduxForm({
  form: 'DriverForm',
  validate: validate,
  enableReinitialize: true
})(DriverForm);

export default DriverForm;
