import {Fragment, Component} from 'react';
import Dropzone from 'react-dropzone';
import {FormGroup, ControlLabel, Image, Button} from 'react-bootstrap';
import ReactAvatarEditor from 'react-avatar-editor';
import {Link} from 'react-router';
import {defaultAvatars} from 'App/utils/globals';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';

class renderImageDropzone extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);

    this.state = {
      image: null,
      scale: 1.0,
      showModal: false,
      blobImage: null,
      hasAvatar: false,
      usingDefaultAvatar: false
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.avatar) {
      this.setState({hasAvatar: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user.avatar) {
        this.setState({hasAvatar: true});
      }
    }
  }

  handleDrop(dropped) {
    if (dropped.length > 0) {
      this.setState({image: dropped[0]});
    }
  }

  renderAvatarModal() {
    return (
      <div className="settings__profile-defaultAvatars">
        {defaultAvatars &&
          defaultAvatars.length &&
          defaultAvatars.map((link, ind) => {
            return <Image onClick={() => this.selectDefaultAvatar(link)} height={100} src={link} key={ind} />;
          })}
      </div>
    );
  }

  selectDefaultAvatar(link) {
    //grab the selected avatar and convert to a blob file for saving
    const xhr = new XMLHttpRequest();
    xhr.open('GET', link + '?ts=' + new Date().getTime().toString(), true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const file = new File([xhr.response], 'userAvatar');
      this.setState({image: file, showModal: false, scale: 1.0});
    };
    xhr.send();
  }

  deleteAvatar() {
    this.setState({image: null, hasAvatar: false});
  }

  handleCancel() {
    this.props.input.onChange('');
    if (this.props.user.avatar) {
      this.setState({hasAvatar: true, image: null});
    } else {
      this.setState({image: null, hasAvatar: false});
    }
  }

  setRef(ref) {
    this.dropzone = ref;
  }

  render() {
    const {
      input,
      label,
      id,
      files,
      setRef,
      meta: {touched, error},
      handleSaveAvatar,
      ...rest
    } = this.props;
    const {image} = this.state;
    return (
      <FormGroup>
        {this.state.hasAvatar ? (
          <Fragment>
            <img alt="avatar" height={250} width={250} src={this.props.user.avatar} />
            <div className="settings__profile-avatar-removeImage">
              <p className="showAsLink" onClick={() => this.deleteAvatar()}>
                Update Image
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Dropzone
              {...input}
              multiple={false}
              id={id}
              name="avatar"
              disableClick={image !== null ? true : false}
              height={'auto'}
              width={'100%'}
              accept="image/jpeg, image/png"
              ref={this.setRef}
              onDrop={(files) => {
                this.handleDrop(files);
              }}
              {...rest}
            >
              <ReactAvatarEditor
                width={240}
                height={240}
                border={5}
                color={[232, 232, 232, 1]}
                borderRadius={120}
                ref={this.props.setRef}
                scale={parseFloat(this.state.scale)}
                image={image}
              />
              {image !== null && (
                <Fragment>
                  <div>
                    <input
                      type="range"
                      min="0.5"
                      max="3.0"
                      className="profile-slider"
                      step="0.1"
                      value={this.state.scale}
                      onChange={(event) => {
                        this.setState({scale: event.target.value});
                      }}
                    />
                  </div>
                  <div className="settings__profile-avatar-buttons">
                    <Button onClick={() => this.handleCancel()} bsStyle="default">
                      Cancel
                    </Button>
                    <Button onClick={() => handleSaveAvatar()} bsStyle="primary">
                      Set Photo
                    </Button>
                  </div>
                </Fragment>
              )}
              {!image && (
                <div className="settings__profile-avatar-uploadContainer">
                  <p>Drag a Photo Here</p>
                  <Button bsStyle="default">Upload Image</Button>
                </div>
              )}
            </Dropzone>
            {touched && error && (
              <p className="error-text">
                <i className="icon icon-Delayed" />
                {error}
              </p>
            )}
            {!image && (
              <p className="showAsLink" onClick={() => this.setState({showModal: true})}>
                Select from Default Images
              </p>
            )}
          </Fragment>
        )}
        <InfoModalWrapper
          show={this.state.showModal}
          onHide={() =>
            this.setState({
              showModal: false
            })
          }
          title="Select an Avatar"
          children={this.renderAvatarModal()}
        />
      </FormGroup>
    );
  }
}

export default renderImageDropzone;
