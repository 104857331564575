import {DisplayValue, Title} from '@shipwell/shipwell-ui';
import {useV3Shipment} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import ContactDisplay from 'App/containers/shipments/components/StopsView/ContactDisplay';
import type {StopDisplay} from 'App/containers/shipments/utils/typed';

const StopView = ({stop, classNames}: {stop: StopDisplay; classNames: string}) => {
  const {title, companyName, addressLineOne, addressLineTwo, city, region, zip, contacts, instructions} = stop;

  return (
    <div className={`grid grid-cols-2 gap-4 ${classNames}`}>
      <div>
        <div className="pb-2">
          <Title>{title}</Title>
          <div>{companyName}</div>
          <div>{addressLineOne}</div>
          {addressLineTwo ? <div>{addressLineTwo}</div> : null}
          <div>{`${city}, ${region} ${zip}`}</div>
        </div>
        {contacts && contacts?.length > 0 ? (
          contacts.map((contact, index) => <ContactDisplay key={contact.id} contact={contact} index={index} />)
        ) : (
          <ContactDisplay index={0} />
        )}
      </div>
      <DisplayValue label={`${title} Instructions`}>{instructions}</DisplayValue>
    </div>
  );
};

const DrayageLegStopsReview = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsDisplayValues}
  } = shipmentQuery;

  return (
    <div className="flex flex-col md:flex-row">
      {stopsDisplayValues?.map((stop) => (
        <StopView
          key={stop.addressSingleLine}
          classNames="flex-1 pl-4 pt-4 md:pt-0 border-t-1 md:border-t-0 md:border-l-1 first:border-0 border-sw-border"
          stop={stop}
        />
      ))}
    </div>
  );
};

export default DrayageLegStopsReview;
