import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

export async function getUserTableConfigurationPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsGet(opts, apiCallback(resolve, reject));
  });
}

export async function getUserTableByIdConfigurationPromise(configId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsUserDashboardConfigurationIdGet(
      configId,
      apiCallback(resolve, reject)
    );
  });
}

export async function updateUserTableConfigurationPromise(configId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsUserDashboardConfigurationIdPut(
      configId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function createUserTableConfigurationPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsPost(opts, apiCallback(resolve, reject));
  });
}

export async function deleteUserTableConfigurationPromise(configId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsUserDashboardConfigurationIdDelete(
      configId,
      apiCallback(resolve, reject)
    );
  });
}
