import {Fragment} from 'react';
import {FieldArray, useFormikContext} from 'formik';
import classNames from 'classnames';
import {LineItemProductLookupField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemProductLookupField';
import {LineItemDescriptionField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemDescriptionField';
import {LineItemDeleteButton} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemDeleteButton';
import {LineItemQuantityField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemQuantityField';
import {LineItemPackagingField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemPackagingField';
import {LineItemWeightField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemWeightField';
import {LineItemWeightUnitField} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/LineItemWeightUnitField';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';
import {TotalWeightLabel} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/TotalWeightLabel';
// Shows a subset of the legacy shipment line item fields
// to allow simple edits to existing shipment line items
const TruncatedLegacyShipmentLineItemsFields = () => {
  const {
    values: {line_items}
  } = useFormikContext<NewStageFormValues>();
  return (
    <div className="py-4">
      <span className="font-bold text-sw-form-helper-text">{`Line Items (${line_items?.length || 0})`}</span>
      <FieldArray name="line_items">
        {({remove, replace}) => (
          <>
            {line_items?.map((lineItem, index) => (
              <Fragment key={lineItem.id}>
                <div className="grid gap-4 py-2">
                  <div className="grid grid-cols-2 gap-4">
                    <LineItemProductLookupField index={index} onProductSelect={replace} />
                    <div className="grid grid-cols-[auto_min-content] items-start gap-2">
                      <LineItemDescriptionField index={index} />
                      {/* only show delete button if there are at least two line items */}
                      {line_items?.length > 1 ? (
                        <div className="mt-2">
                          <LineItemDeleteButton onClick={remove} index={index} />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="grid grid-cols-[1fr_1fr_2.25fr] gap-4">
                    <LineItemQuantityField index={index} />
                    <LineItemPackagingField index={index} />

                    <div className="grid grid-cols-[auto_5rem]">
                      <LineItemWeightField index={index} />
                      <div className="-ml-0.5">
                        <LineItemWeightUnitField index={index} disabled />
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  className={classNames('border-t border-solid border-sw-divider', {
                    //dont show the divider for the last line item
                    hidden: index === line_items.length - 1
                  })}
                />
              </Fragment>
            ))}
          </>
        )}
      </FieldArray>
      <div className="flex flex-col text-end">
        <TotalWeightLabel />
      </div>
    </div>
  );
};

export const ConditionalTruncatedLegacyShipmentLineItemsFields = ({
  isCloningContainer
}: {
  isCloningContainer: boolean;
}) => (isCloningContainer ? <TruncatedLegacyShipmentLineItemsFields /> : null);
