/* eslint-disable camelcase */
import {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DisplayValue} from '@shipwell/shipwell-ui';
import {getLowestBid} from 'App/utils/globalsTyped';
import {editShipment} from 'App/actions/shipments';
import {formatCurrency} from 'App/utils/internationalConstants';

@connect((state) => ({company: state.auth.company}), {
  editShipment
})
export default class MarketplaceFinancials extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {selectedShipment = {}, shipmentSpotNegotiations = {}, company, carrierBids} = this.props;
    const {max_buy_amount, buy_it_now_amount, target_rate_amount} = selectedShipment.metadata || {};

    const customer_amount =
      selectedShipment.relationship_to_customer?.vendor_charge_line_items?.length > 0
        ? selectedShipment.relationship_to_customer.vendor_charge_line_items.reduce((acc, cur) => {
            return parseFloat(acc) + parseFloat(cur.amount);
          }, 0)
        : undefined;

    const spotNegsToShow = {results: []};
    if (shipmentSpotNegotiations && shipmentSpotNegotiations.results && shipmentSpotNegotiations.results.length > 0) {
      spotNegsToShow.results = shipmentSpotNegotiations.results.filter((e) => e.vendor.id !== company.id);
    }
    let lowestBid =
      spotNegsToShow.results &&
      spotNegsToShow.results.length > 0 &&
      getLowestBid({spotNegotiations: spotNegsToShow})?.total;
    const loadBoardEnabled = company && company.feature_flags && company.feature_flags.load_board_enabled;

    if (carrierBids && carrierBids.length) {
      if (lowestBid && carrierBids.filter((e) => e.bid_amount < lowestBid).length > 0) {
        lowestBid = carrierBids
          .filter((e) => e.bid_amount < lowestBid)
          .sort((a, b) => a.bid_amount < b.bid_amount)[0].bid_amount;
      } else if (!lowestBid) {
        lowestBid = carrierBids.sort((a, b) => a.bid_amount < b.bid_amount)[0].bid_amount;
      }
    }

    return (
      <div className="marketplace__financials">
        <DisplayValue label="Customer">
          {customer_amount ? formatCurrency(customer_amount, selectedShipment.preferred_currency) : '--'}
        </DisplayValue>
        <DisplayValue label="Low Bid">
          {lowestBid ? formatCurrency(lowestBid, selectedShipment.preferred_currency) : '--'}
        </DisplayValue>
        <DisplayValue label="Max Buy">
          {max_buy_amount ? formatCurrency(max_buy_amount, selectedShipment.preferred_currency) : '--'}
        </DisplayValue>
        {loadBoardEnabled ? (
          <>
            <DisplayValue label="Book Now">
              {buy_it_now_amount ? formatCurrency(buy_it_now_amount, selectedShipment.preferred_currency) : '--'}
            </DisplayValue>
            <DisplayValue label="Target Rate">
              {target_rate_amount
                ? formatCurrency(
                    target_rate_amount,
                    selectedShipment.metadata?.target_rate_amount_currency || selectedShipment.preferred_currency
                  )
                : '--'}
            </DisplayValue>
          </>
        ) : null}
      </div>
    );
  }
}

MarketplaceFinancials.propTypes = {
  selectedShipment: PropTypes.object,
  shipmentSpotNegotiations: PropTypes.object,
  company: PropTypes.object,
  carrierBids: PropTypes.array,
  editShipment: PropTypes.func
};
