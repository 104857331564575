import {useMemo} from 'react';

/**
 * This hook accepts any data, say from an async request, and even if the given object changes, the
 * object returned will only change when the given object's JSON serialization has changed.
 */
export default function useConsistentReference<T>(data: T | null): T {
  const key = useMemo(() => JSON.stringify(data ?? null), [data]);
  return useMemo(() => JSON.parse(key) as T, [key]);
}
