import PropTypes from 'prop-types';
import {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {Modal, Toast, Card} from '@shipwell/shipwell-ui';
import {createFedexRegistration} from '../actions/async';
import PageHeader from 'App/common/pageHeader';
import {FedexRegistrationForm} from 'App/formComponents/forms/fedexRegistration';
import ShipwellLoading from 'App/common/shipwellLoader/index';
import {unpackErrors, cleanPayload} from 'App/utils/globals';
import {getFedexAccountDetails, updateFedexAccount} from 'App/api/integrations';
import './styles.scss';
import withConditionalFallback from 'App/components/withConditionalFallback';

/**
 * Fedex Registration Container
 * @param {*} props
 */
const FedexRegistration = (props) => {
  const {dispatch, router} = props;
  const {params} = router;
  const {fedexId, cloneFedexId} = params;
  const [submitError, setSubmitError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registrationFormValues, setRegistrationFormValues] = useState({});
  const handleCancel = () => {
    router.push('/carriers/search');
  };

  /**
   * Submit Fedex Account Form
   * @param {Object} values
   * @param {Object} actions
   */
  const handleSubmit = async (values, {setErrors}) => {
    if (!values.secondary_email) {
      values.secondary_email = values.contact.email_address;
    }
    values.shipping_address = values.billing_address;

    // if creating the backend-sdk does not allow a null smart_post_hub_id
    if (!fedexId && !values.smart_post_hub_id) {
      delete values.smart_post_hub_id;
    }

    try {
      /* fedexId will have a value if user is trying to edit an account.
        Otherwise, it means that they're trying to register so just use the POST route
      */
      if (fedexId) {
        await updateFedexAccount(values.id, values);
      } else {
        await dispatch(createFedexRegistration(values.account_number, cleanPayload(values)));
      }
      router.push('/carriers?page=1&q=fedex&fedexRegistrationSuccess');
    } catch (error) {
      if (
        error &&
        (error.error_description.includes('Invalid Account number') ||
          error.error_description.includes('Invalid Customer Account Nbr'))
      ) {
        setSubmitError(error.error_description);
      } else if (error && Array.isArray(error.error_description)) {
        setValidationError(error.error_description[0]);
      } else if (error && error.error_description) {
        setValidationError(error.error_description);
      }
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }
  };

  const fetchFedexAccountDetails = useCallback(
    async (fedexId) => {
      setLoading(true);
      try {
        const response = await getFedexAccountDetails(fedexId);
        if (response?.body) {
          const formValues = {
            billing_address: get(response.body, 'address'),
            ...omit(response.body, 'address'),
            account_number: cloneFedexId ? undefined : get(response.body, 'account_number'),
            has_freight_account: get(response.body.freight_account, 'freight_account_number') !== undefined
          };
          setRegistrationFormValues(formValues);
        }
      } catch (error) {
        console.error(error?.error_description);
      } finally {
        setLoading(false);
      }
    },
    [cloneFedexId]
  );

  useEffect(() => {
    if (fedexId || cloneFedexId) {
      fetchFedexAccountDetails(fedexId || cloneFedexId);
    }
  }, [fetchFedexAccountDetails, fedexId, cloneFedexId]);

  return (
    <div className="fedex-registration-wrapper">
      <PageHeader title={fedexId || cloneFedexId ? 'Edit Account' : 'Fedex Registration'} backArrow />
      {loading ? (
        <ShipwellLoading loading />
      ) : (
        <div className="container py-5">
          <div className="bg-sw-text-reverse p-4">
            <div>
              <p>
                Enter your FedEx account information below to add FedEx as a carrier in the Shipwell platform or sign up
                for an account at{' '}
                <a href="https://fedex.com/" rel="noreferrer" target="_blank">
                  fedex.com
                </a>
              </p>
            </div>
            {fedexId && (
              <Card
                title="Please make sure your account number matches the billing information you have on file with FedEx for this account."
                guided
                className="mb-4"
              />
            )}
            <FedexRegistrationForm
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              values={registrationFormValues}
              isEditing={!!fedexId}
            />
          </div>

          <Modal
            className="fedex-registration-modal"
            show={Boolean(submitError)}
            headerComponent={null}
            primaryBtnName="Try Again"
            onPrimaryAction={() => setSubmitError(null)}
            onClose={() => setSubmitError(null)}
          >
            <h2>Your FedEx Account Could Not be Connected!</h2>
            <p>
              Your 9 digit account number is invalid or doesn’t match the account information you provided when
              registering for your FedEx account. Please double check and try again or contact{' '}
              <a href="fedex.com" target="_target">
                fedex.com
              </a>{' '}
              to retrieve your information.
            </p>
          </Modal>
          <Toast
            show={Boolean(validationError)}
            title="Error"
            variant="error"
            anchor="bottom-right"
            onClose={() => setValidationError(null)}
          >
            {validationError}
          </Toast>
        </div>
      )}
    </div>
  );
};

FedexRegistration.propTypes = {
  dispatch: PropTypes.func,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape({
        includes: PropTypes.func
      })
    }),
    params: PropTypes.shape({
      cloneFedexId: PropTypes.any,
      fedexId: PropTypes.any
    }),
    push: PropTypes.func
  })
};

const FedexDisabledFalllback = () => <div className="text-center">You don't have access to this integration.</div>;

export default compose(
  connect((state) => ({
    isFedexEnabled: state.company.company.feature_flags && state.company.company.feature_flags.fedex_enabled
  })),
  withConditionalFallback(({isFedexEnabled}) => isFedexEnabled === undefined, ShipwellLoading),
  withConditionalFallback(({isFedexEnabled}) => !isFedexEnabled, FedexDisabledFalllback)
)(FedexRegistration);
