import {ShipmentMetadataAlert} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getShipmentMetaDataAlerts} from 'App/api/shipwellUI/typed';
import {SHIPMENT_METADATA_ALERTS_KEY} from 'App/data-hooks/queryKeys';

export const useGetShipmentMetaDataAlerts = ({
  shipmentIds,
  options
}: {
  shipmentIds: string[];
  options?: Omit<
    UseQueryOptions<ShipmentMetadataAlert[], unknown, ShipmentMetadataAlert[], (string | string[])[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const metadataAlertsQuery = useQuery(
    [SHIPMENT_METADATA_ALERTS_KEY, 'list', shipmentIds],
    async () => {
      const response = await getShipmentMetaDataAlerts(shipmentIds);
      return response.data.data || [];
    },
    {
      enabled: Boolean(shipmentIds.length),
      ...options
    }
  );

  const {data: metadataAlertsData, status: metadataAlertsStatus} = metadataAlertsQuery;
  return {
    metadataAlertsData,
    metadataAlertsStatus,
    metadataAlertsQuery
  };
};
