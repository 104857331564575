/* eslint-disable @typescript-eslint/no-unused-expressions */
import {useState, useEffect} from 'react';
import {useMutation} from '@tanstack/react-query';
import {Formik, Form, Field} from 'formik';
import {FileDisplay, Title, FormikSelect} from '@shipwell/shipwell-ui';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import {compose} from 'recompose';
import {
  BaseShipment,
  DocumentMetadata,
  ShipmentDocumentMetadataTypeEnum,
  ShippingDashboardRow
} from '@shipwell/backend-core-singlerequestparam-sdk';
import printJS from 'print-js';
import pluralize from 'pluralize';
import {mergePdf} from './utils/pdfMerger';
import {shipmentEmailPayload} from './utils/shipmentEmailPayload';
import {getDocumentTitle} from './utils/utils';
import {sendEmailNotificationPromise} from 'App/api/shipwellUI/typed';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {useLegacyDocuments} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {PRINT_DOCUMENT, EMAIL_DOCUMENT} from 'App/containers/Dashboard/utils/constants';
import DocumentSelect from 'App/formComponents/fields/documentSelect';
import usePrevious from 'App/utils/hooks/usePrevious';
import {validateEmail} from 'App/utils/globals';

interface FormatOptions {
  __isNew__: string;
  value: string;
  label: string;
}

type ShipmentDocumentProps = {
  shipments: ShippingDashboardRow[] | BaseShipment[];
  onCancel: () => void;
  onSuccess?: () => void;
  mode: string;
  documentType: ShipmentDocumentMetadataTypeEnum;
} & Partial<WithStatusToastProps>;

const ShipmentDocument = ({
  shipments,
  onCancel,
  onSuccess,
  mode,
  documentType,
  setSuccess,
  setError
}: ShipmentDocumentProps) => {
  const documentTitle = getDocumentTitle(documentType);
  const shipmentIds = shipments?.map((shipment) => shipment.id);
  const shipmentReferenceId = shipments?.map((shipment) => {
    return {shipmentId: shipment.id, referenceId: shipment.reference_id};
  });
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [previewingDocument, setPreviewingDocument] = useState<DocumentMetadata>({});
  const [emails, setEmails] = useState<string[]>([]);

  const documentQueries = useLegacyDocuments(shipmentIds, mode, documentType);
  const queryDocuments = documentQueries.map((response) => response.documents).flat();
  const queryDocumentIds = queryDocuments.map((document) => document.id).filter((id): id is string => isString(id));

  const previousQueryDocumentIds = usePrevious(queryDocumentIds);

  const sendShipmentDocuments = useMutation(sendEmailNotificationPromise, {
    onSuccess: () => {
      if (selectedDocumentIds.length > 0) {
        setSuccess?.(
          `${selectedDocumentIds.length} ${pluralize('Item', selectedDocumentIds.length)} Emailed Successfully!`,
          ` `
        );
      }
    },
    onError: () => {
      setError?.(
        `${selectedDocumentIds.length} ${pluralize('Item', selectedDocumentIds.length)} Could Not Be Emailed`,
        <div>
          <div>{`The ${documentTitle} could not be emailed on the following  ${pluralize(
            'shipment',
            shipmentIds.length
          )}.`}</div>
          <div>
            {shipmentReferenceId.map((shipment) => {
              return (
                <div key={shipment.shipmentId}>
                  <a href={`/shipments/${shipment.shipmentId}`}>{shipment.referenceId}</a>
                </div>
              );
            })}
          </div>
        </div>,
        'top-right',
        {delay: null}
      );
    }
  });

  useEffect(() => {
    if (queryDocumentIds?.length > 0 && !isEqual(queryDocumentIds, previousQueryDocumentIds)) {
      setSelectedDocumentIds(queryDocumentIds);
    }
  }, [queryDocumentIds, previousQueryDocumentIds]);

  const handleSubmit = async () => {
    if (mode === PRINT_DOCUMENT) {
      const mergedPdfFile = await mergePdf(selectedDocumentIds, queryDocuments);

      if (mergedPdfFile) {
        const blob = new Blob([mergedPdfFile], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        printJS({
          printable: url,
          type: 'pdf'
        });
        onSuccess && onSuccess();
      }
    } else if (mode === EMAIL_DOCUMENT) {
      const shipments = shipmentEmailPayload(queryDocuments, selectedDocumentIds, emails);
      const mutations = shipments.map(
        (shipment) =>
          shipment &&
          sendShipmentDocuments.mutateAsync({
            emailNotificationRequest: shipment
          })
      );
      await Promise.allSettled(mutations);
      onSuccess && onSuccess();
    }
  };

  const handleEmailChange = (
    value: string[],
    setFieldValue: (field: string, value: string[] | null | undefined) => void
  ) => {
    setFieldValue('emails', value);
    setEmails(value);
  };

  if (documentQueries.some((documentQuery) => documentQuery.isLoading)) {
    return <Loader loading />;
  }

  const initialValues = {
    emails: emails,
    documents: queryDocuments
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({isSubmitting, setFieldValue, values}) => (
        <Form>
          {mode === EMAIL_DOCUMENT ? (
            <>
              <div className="mb-2">
                Select email contacts to send a copy of the {documentTitle}. Click the document thumbnails to see a
                larger view of the document.
              </div>
              <Field
                required
                isMulti
                isClearable
                simpleValue
                allowCreate
                className="mb-6"
                label="Email Contact(s)"
                name="emails"
                component={FormikSelect}
                noOptionsMessage={() => 'Enter a valid email.'}
                options={[]}
                getOptionValue={(option: string) => option}
                formatOptionLabel={(option: FormatOptions) => {
                  if (option?.__isNew__) {
                    return option.value;
                  }
                  return option;
                }}
                isValidNewOption={(inputValue: string) =>
                  validateEmail(inputValue) && !values.emails?.includes(inputValue)
                }
                onChange={(value: string[]) => handleEmailChange(value, setFieldValue)}
              />
            </>
          ) : null}

          {mode === PRINT_DOCUMENT ? (
            <div>Click the document thumbnails to see a larger view of the document.</div>
          ) : null}

          <div className="field-grid grid-1-12">
            <div className="grid-item-1-6">
              {values.documents.length > 0 ? (
                <div>
                  <Title>Shipment {documentTitle}s</Title>
                  <DocumentSelect
                    documents={values.documents}
                    selectedDocuments={selectedDocumentIds}
                    setSelectedDocuments={setSelectedDocumentIds}
                    previewingDocument={previewingDocument}
                    onClickDocument={setPreviewingDocument}
                    shipments={shipments}
                  />
                </div>
              ) : (
                <div className="flex-column flex justify-center">
                  <Title variant="emptyStateHeader" className="mt-[200px] flex justify-center">
                    No {documentTitle}s available
                  </Title>
                </div>
              )}
            </div>
            <div className="grid-item-7-12 sticky top-0">
              <FileDisplay
                maxHeight={420}
                maxWidth={420}
                fileURL={previewingDocument?.file}
                fileName={previewingDocument?.filename}
                emptyText="Select a document to preview here"
              />
            </div>
          </div>

          <div className="mt-12">
            {mode === PRINT_DOCUMENT ? (
              <ModalFormFooter
                isSubmitting={isSubmitting}
                isValid={values.documents.length > 0 && selectedDocumentIds.length > 0}
                onCancel={onCancel}
                primaryActionName={`Print All ${documentTitle}s`}
              />
            ) : (
              <ModalFormFooter
                isSubmitting={isSubmitting}
                isValid={values.documents.length > 0 && selectedDocumentIds.length > 0 && emails.length > 0}
                onCancel={onCancel}
                primaryActionName={'Send Email'}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default compose<ShipmentDocumentProps, ShipmentDocumentProps>(withStatusToasts)(ShipmentDocument);
