import {
  CarrierTagsApi,
  CarrierTag,
  CarrierTagsApiCarrierTagsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {configuration} from './configuration';

const carrierTagsApi = new CarrierTagsApi(configuration);

export async function getCarrierTags(requestParameters?: CarrierTagsApiCarrierTagsGetRequest) {
  const axiosResponse = await carrierTagsApi.carrierTagsGet(requestParameters);
  return axiosResponse.data;
}

export function getCarrierTagsById(id: string) {
  return carrierTagsApi.carrierTagsTagIdGet({tagId: id});
}

export function postCarrierTags(carrierTag: CarrierTag) {
  return carrierTagsApi.carrierTagsPost({carrierTag});
}
