// import fetch from 'isomorphic-fetch';
import {
  ALERT_ERROR,
  FETCH_AUTOMATED_VENDORS,
  FETCH_QUICKBOOKS_CUSTOMERS,
  GET_SHIPMENT_INVOICES,
  SHIPMENT_BILL_CREATE_SUCCESS,
  FETCH_EXPENSE_ACCOUNTS,
  FETCH_EXPENSE_CLASSES,
  FETCH_PAYMENT_TERMS
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';
import {setAccessTokenFunctionOverride} from 'App/api/utils';

export function clearAlertError() {
  return (dispatch) => {
    return dispatch({type: ALERT_ERROR, payload: null});
  };
}

export function dispatchAlertError(error) {
  return (dispatch) => {
    return dispatch({type: ALERT_ERROR, payload: error});
  };
}

// carriers/integrations/automated-vendors/ GET
export function fetchAutomatedVendors(token) {
  setAccessTokenFunctionOverride(() => {
    return new Promise((resolve) => resolve(token));
  });

  return (dispatch) => {
    return fetchAutomatedVendorsPromise()
      .then((response) => {
        dispatch({type: FETCH_AUTOMATED_VENDORS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function fetchAutomatedVendorsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersIntegrationsAutomatedVendorsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// Invoicing
// /invoicing/integrations/quickbooks/auth GET
export function getQuickBooksAuth(opts) {
  return (dispatch) => {
    return getQuickBooksAuthPromise(opts)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function getQuickBooksAuthPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksAuthGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// /invoicing/integrations/quickbooks/customers/ GET
export function getQuickBooksCustomers(opts) {
  opts = opts || {};
  return (dispatch) => {
    return getQuickBooksCustomersPromise(opts)
      .then((response) => {
        dispatch({type: FETCH_QUICKBOOKS_CUSTOMERS, payload: response});
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function getQuickBooksCustomersPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// find 1 customer
export function getQuickBooksCustomer(customerRef) {
  return (dispatch) => {
    return getQuickBooksCustomersPromise({q: 'customer_reference_number:' + '"' + customerRef + '"'})
      .then((response) => {
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

// /invoicing/integrations/quickbooks/shipments/{shipmentId}/invoices/ GET
export function getShipmentInvoices(shipmentId) {
  return (dispatch) => {
    return getShipmentInvoicesPromise(shipmentId)
      .then((response) => {
        dispatch({type: GET_SHIPMENT_INVOICES, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function getShipmentInvoicesPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksShipmentsShipmentIdInvoicesGet(
      shipmentId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

// /invoicing/integrations/quickbooks/auth/check/ GET
export function checkAuthentication() {
  return (dispatch) => {
    return checkAuthenticationPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function checkAuthenticationPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksAuthCheckGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// /invoicing/integrations/quickbooks/invoices/{invoiceId}/email/ POST
export function checkCustomerMapping(companyId) {
  return (dispatch) => {
    return checkCustomerMappingPromise(companyId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function checkCustomerMappingPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersMappingCompanyIdGet(companyId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Populates company&#39;s quickbooks customers to the platform
 * @param {module:api/shipwellApi~invoicingIntegrationsQuickbooksCustomersPopulatePostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/NoContentResponse}
 */
export function populateCustomer() {
  return (dispatch) => {
    return populateCustomerPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function populateCustomerPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersPopulatePost(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

const billCreateSuccess = (response) => ({
  type: SHIPMENT_BILL_CREATE_SUCCESS,
  payload: response
});

//Billing
// create bill
export function createBill(shipmentId, body) {
  return (dispatch) => {
    return createBillPromise(shipmentId, body)
      .then((response) => {
        dispatch(billCreateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function createBillPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksShipmentsShipmentIdBillsPost(
      shipmentId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

// Get Vendors
async function getQuickBooksVendorsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// get vendor
export function getQuickBooksVendor(vendorRef) {
  return (dispatch) => {
    return getQuickBooksVendorsPromise({q: vendorRef})
      .then((response) => {
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

//create vendor
export function createQuickBooksVendor(body) {
  return (dispatch) => {
    return createQuickBooksPromise(body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function createQuickBooksPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//populate carriers from qb
export function populateQuickBooksCarrier() {
  return (dispatch) => {
    return populateQuickBooksCarrierPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function populateQuickBooksCarrierPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsPopulatePost(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// check vendor mapping
export function checkVendorMapping(companyId) {
  return (dispatch) => {
    return checkVendorMappingPromise(companyId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function checkVendorMappingPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsMappingCompanyIdGet(companyId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//populate expense-account
export function populateExpenseAccounts() {
  return (dispatch) => {
    return populateExpenseAccountsPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function populateExpenseAccountsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseAccountsPost(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}
//fetch expense classes
export function fetchExpenseAccounts(opts) {
  return (dispatch) => {
    return fetchExpenseAccountsPromise(opts)
      .then((response) => {
        dispatch({
          type: FETCH_EXPENSE_ACCOUNTS,
          payload: response
        });
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function fetchExpenseAccountsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseAccountsGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//populate expense-classes
export function populateExpenseClasses() {
  return (dispatch) => {
    return populateExpenseClassesPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function populateExpenseClassesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseClassesPost(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}
//fetch expense classes
export function fetchExpenseClasses(opts) {
  return (dispatch) => {
    return fetchExpenseClassesPromise(opts)
      .then((response) => {
        dispatch({
          type: FETCH_EXPENSE_CLASSES,
          payload: response
        });
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function fetchExpenseClassesPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseClassesGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//fetch payment terms
export function fetchPaymentTerms() {
  return (dispatch) => {
    return fetchPaymentTermsPromise()
      .then((response) => {
        dispatch({
          type: FETCH_PAYMENT_TERMS,
          payload: response
        });
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function fetchPaymentTermsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksPaymentTermsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}
//create payment terms from qb
export function postPaymentTerms() {
  return (dispatch) => {
    return postPaymentTermsPromise()
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function postPaymentTermsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksPaymentTermsPost(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export async function authenticateLooker(lookerResource) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.analyticsIntegrationsLookerSignurlGet(lookerResource, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function getLookerDashboards() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.analyticsIntegrationsLookerContentGet((err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}
