import {Address, ContractAdditionalStopChargesTypeEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import isUndefined from 'lodash/isUndefined';
import {RATE_TYPE_FLAT_RATE, RATE_TYPE_PER_HOUR} from 'App/containers/userCompany/rateTables/constants';
import {formatAddresses} from 'App/containers/workflows/utils';
import {ContractFormValues} from 'App/formComponents/formSections/contractFields/constants';

export const formatContractValuesBeforeSubmit = (formValues: ContractFormValues) => {
  const values = {...formValues};
  values.origins = formatAddresses(values.origins) as Address[];
  values.destinations = formatAddresses(values.destinations) as Address[];
  if (values.rate_type === RATE_TYPE_FLAT_RATE) {
    //minimum rate isnt valid when we have a flat rate
    values.minimum_rate = null;
  }
  if (typeof values.service_level_agreement === 'object' && values.service_level_agreement?.id) {
    values.service_level_agreement = values.service_level_agreement.id;
  }
  if (typeof values.fuel_surcharge_table === 'object' && values.fuel_surcharge_table?.id) {
    values.fuel_surcharge_table = values.fuel_surcharge_table.id || values.fuel_surcharge_table;
  }

  // if additional_stop_charges_type is Custom, remove additional_stop_charge_flat_fee
  if (values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.CustomStopCharges) {
    values.additional_stop_charge_flat_fee = null;
  }
  // if additional_stop_charges_type is Charge per stop, remove additional_stop_charges on each additional stop
  if (
    values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop &&
    values.additional_stops?.length
  ) {
    values.additional_stops = values.additional_stops.map((additionalStop) => {
      additionalStop.additional_stop_charge = null;
      return additionalStop;
    });
  }

  // handle per hour contract rate type
  if (
    (values.rate_type === RATE_TYPE_PER_HOUR &&
      !isUndefined(values.calculate_per_hour_rate_automatically) &&
      [false, 'false'].includes(values.calculate_per_hour_rate_automatically)) ||
    values.rate_type !== RATE_TYPE_PER_HOUR
  ) {
    values.per_hour_start_field = null;
    values.per_hour_end_field = null;
  }

  // The UI only exposes rate_currency, use it to set the other currency values
  if (values.rate_currency) {
    values.additional_stop_charge_flat_fee_currency = values.rate_currency;
    values.additional_stop_charge_per_mile_currency = values.rate_currency;
    values.minimum_rate_currency = values.rate_currency;
  }

  if (typeof values.accessorial_charge_table === 'object' && values.accessorial_charge_table?.id) {
    values.accessorial_charge_table = values.accessorial_charge_table.id || values.accessorial_charge_table;
  }

  const defaultAccessorials = values.defaultAccessorials?.map((accessorial) => {
    return {
      description: accessorial.description,
      price: accessorial.rate,
      code: accessorial.accessorial.code,
      type: accessorial.accessorial.type,
      label: accessorial.accessorial.description
    };
  });

  delete values.defaultAccessorials;

  return {
    ...values,
    default_accessorials: defaultAccessorials
  };
};
