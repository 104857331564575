import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {LoadboardType, LoadPostRequest} from '@shipwell/tabula-sdk';
import {getLoadpost} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

const useGetLoadpost = (
  loadboardType: LoadboardType,
  loadpostId: string,
  options?: UseQueryOptions<LoadPostRequest>
) => {
  const response = useQuery(
    [LOADPOST_KEY, loadboardType, loadpostId] as QueryKey,
    async () => {
      const response = await getLoadpost(loadboardType, loadpostId);
      return response.data;
    },
    {
      enabled: !!loadboardType && !!loadpostId,
      ...options
    }
  );

  return response;
};

export default useGetLoadpost;
