import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  parcelReferences,
  drayageReferences,
  ltlReferences,
  vltlReferences,
  ftlReferences,
  intermodalReferences,
  railReferences,
  airReferences
} from 'App/containers/Shipment/components/References/utils/constants';
import ReferenceColumns from 'App/containers/Shipment/components/References/View/ReferenceColumns';
import {checkShipmentModes} from 'App/utils/globalsTyped';

const ReferenceFields = ({shipment}: {shipment: Shipment}) => {
  const {hasParcel, hasDrayage, hasLTL, hasVLTL, hasFTL, hasIntermodal, hasRail, hasAir} = checkShipmentModes(
    shipment.mode
  );
  return (
    <>
      {hasParcel ? (
        <ReferenceColumns references={parcelReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasDrayage ? (
        <ReferenceColumns references={drayageReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasLTL ? (
        <ReferenceColumns references={ltlReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasVLTL ? (
        <ReferenceColumns references={vltlReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasFTL ? (
        <ReferenceColumns references={ftlReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasIntermodal ? (
        <ReferenceColumns references={intermodalReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasRail ? (
        <ReferenceColumns references={railReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
      {hasAir ? (
        <ReferenceColumns references={airReferences} context={shipment} referencesArray={shipment.references} />
      ) : null}
    </>
  );
};

export default ReferenceFields;
