import {Formik, Field, Form} from 'formik';
import PropTypes from 'prop-types';
import {object, string, number} from 'yup';
import {Card, Tooltip, FormikCheckbox, SvgIcon} from '@shipwell/shipwell-ui';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import FormFooter from 'App/formComponents/formSections/formFooter';
import ServiceLevelAgreementFields from 'App/formComponents/formSections/serviceLevelAgreementFields';
import './styles.scss';

/** Default values needed for Formik */
const defaultFormValues = {
  name: '',
  status: 'ACTIVE',
  capacity: '',
  capacity_interval: 'WEEK',
  start_date: null,
  end_date: null,
  notes: ''
};

export const validationSchema = object().shape({
  name: string().nullable().required('Service Level Agreement name is required.'),
  status: string().nullable().required('Status is required.'),
  capacity: number()
    .typeError('Capacity must be a number.')
    .integer('Capacity must be an integer.')
    .min(0, 'Capacity must be 0 or greater.')
    .nullable()
});

/**
 * Contract Form
 */
const ServiceLevelAgreementForm = (props) => {
  const {isEdit, values, onSubmit, onCancel, canEditContracts, canViewContracts, canCreateContracts, cloningSLA} =
    props;

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit SLA values */
  const handleSLASubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSLASubmit}
    >
      {({isSubmitting, dirty, ...props}) => (
        <Form className="contracts__form mb-10">
          <Card title="Service Level Agreement">
            <ServiceLevelAgreementFields
              {...props}
              cloningSLA={cloningSLA}
              readOnly={canViewContracts && ((isEdit && !canEditContracts) || (!isEdit && !canCreateContracts))}
            />
          </Card>
          {!isEdit && (
            <ModalFormFooter
              options={
                <>
                  <Field component={FormikCheckbox} name="clone_on_save" label={'Clone SLA after saving'} />
                  <Tooltip
                    placement="top"
                    tooltipClassname="contracts__form-tooltips"
                    tooltipContent={
                      <span>
                        This allows you to create a copy of an existing SLA to use as a starting point for a new SLA.
                      </span>
                    }
                  >
                    <SvgIcon name="InfoOutlined" />
                  </Tooltip>
                </>
              }
              isSubmitting={isSubmitting}
              onCancel={handleCancel}
            />
          )}
          {isEdit && dirty && canEditContracts && <FormFooter isSubmitting={isSubmitting} onCancel={handleCancel} />}
        </Form>
      )}
    </Formik>
  );
};

ServiceLevelAgreementForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default ServiceLevelAgreementForm;
