import {connect} from 'react-redux';
import {reduxForm, Form, formValueSelector, getFormValues, FieldArray} from 'redux-form';
import {Button, Table} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderCustomerCarriers from 'App/formComponents/renderMultipleCustomerCarriers';
import validate from 'App/utils/validateCustomerCarrierForm';

let CustomerCarrierForm = (props) => {
  const {handleSubmit, submitting, error, carriers, formValues, isEdit} = props;
  const el = document.getElementsByClassName('table-responsive');
  if (el[0] && props.height) {
    el[0].style.maxHeight = props.height;
  }
  const renderContent = function () {
    return (
      <div className="customerCarrierForm__container">
        <div>
          <p>
            Use the form below to manage carrier relationships for{' '}
            {props.selectedCustomer && props.selectedCustomer.company && props.selectedCustomer.company.name}. Carriers
            you have modified the relationship for are marked with a{' '}
            <i style={{color: '#00A3E0'}} className="icon icon-Circle" />.
          </p>
          <p>Unless you mark a carrier as not enabled, it will be shown when searching for LTL quotes.</p>
          <p>
            You can set carrier-specific margins here. Any carrier you don't set a margin for will use the customer's
            default margin.
          </p>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Carrier</th>
              <th>Billing Settings</th>
              <th>Details</th>
              <th>Enabled?</th>
            </tr>
          </thead>

          <FieldArray
            name="customer_carrier"
            component={renderCustomerCarriers}
            props={{formValues: formValues, carriers: carriers}}
          />
        </Table>
      </div>
    );
  };
  return (
    <Form onSubmit={handleSubmit} className="formstyle-light customerCarrierForm">
      {isEdit ? (
        renderContent()
      ) : (
        <Paper className="manage-customers__card-form-new">
          {renderContent()}
          {error && (
            <p className="error-text-form-level">
              <i className="icon icon-Delayed pad-right" />
              {error}
            </p>
          )}
        </Paper>
      )}
      {!isEdit && (
        <div className="manage-customers__footer-btns">
          <Button
            onClick={() => {
              props.backToManageCustomers();
            }}
          >
            Cancel
          </Button>
          <Button disabled={submitting} className="pull-right" type="submit" bsStyle="primary">
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" />
                &nbsp;&nbsp;Saving...
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </div>
      )}
    </Form>
  );
};

CustomerCarrierForm = reduxForm({
  form: 'customerCarrierForm',
  validate: validate,
  enableReinitialize: true
})(CustomerCarrierForm);

CustomerCarrierForm = connect((state) => ({
  formValues: getFormValues('customerCarrierForm')(state)
}))(CustomerCarrierForm);

CustomerCarrierForm = connect((state) => ({
  initialValues: {
    customer_carrier:
      state.brokers.selectedShipperCarrierRelationships &&
      state.brokers.selectedShipperCarrierRelationships.customer_carrier
        ? JSON.parse(JSON.stringify(state.brokers.selectedShipperCarrierRelationships.customer_carrier))
        : []
  }
}))(CustomerCarrierForm);

export default CustomerCarrierForm;
