import {useState} from 'react';
import {IconButtonWithTooltip, Modal} from '@shipwell/shipwell-ui';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteUser} from 'App/api/user/typed';
import {useUserMe} from 'App/data-hooks';
import {COMPANY_USERS} from 'App/data-hooks/queryKeys';

export const ActionCell = ({userId, userName}: {userId: string; userName: string}) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const {data} = useUserMe();
  const companyId = data?.company?.id || '';
  const deleteMutation = useMutation(deleteUser);

  const handleDelete = () => {
    if (!companyId) {
      return;
    }
    deleteMutation.mutate(
      {companyId, userId},
      {
        onSettled: (data, err) => {
          if (err) {
            console.warn(err);
          }
          if (data) {
            void queryClient.invalidateQueries([COMPANY_USERS, companyId]);
          }
          setShowModal(false);
        }
      }
    );
    return;
  };
  return (
    <>
      <IconButtonWithTooltip
        onClick={() => setShowModal(true)}
        aria-label={`Delete ${userName}?`}
        iconName="TrashOutlined"
        variant="tertiary"
        color="sw-destroy"
      />
      <Modal
        show={showModal}
        title="Delete User"
        onClose={() => setShowModal(false)}
        primaryBtnName="Delete"
        onPrimaryAction={handleDelete}
        variant="warning"
      >
        <p>
          Are you sure you want to delete <span className="font-bold">{userName}</span>
        </p>
      </Modal>
    </>
  );
};
