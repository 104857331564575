import {useQuery} from '@tanstack/react-query';
import {getCompanyInternationalPreferences} from 'App/api/internationalPreferences';
import {useUserMe} from 'App/data-hooks';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {INTERNATIONAL_CONFIGS} from 'App/data-hooks/queryKeys';

export const useGetInternationalPreferences = () => {
  const userMeData = useUserMe();
  const companyId = userMeData?.data?.company?.id;
  return useQuery(
    [INTERNATIONAL_CONFIGS],
    async () => {
      if (!companyId) {
        return Promise.reject('Missing required company id parameter');
      }
      return (await getCompanyInternationalPreferences(companyId)).data;
    },
    {staleTime: STATIC_DATA_STALE_TIME, enabled: !!companyId}
  );
};
