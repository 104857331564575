import {Formik, Field, FormikHelpers} from 'formik';
import {object, string, bool} from 'yup';
import {FormikTextInput, FormikPhoneInput, FormikCheckbox, Card} from '@shipwell/shipwell-ui';
import {RegisterUPS} from '@shipwell/backend-core-singlerequestparam-sdk';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {validatePhoneNumber} from 'App/components/forms/utils';
import {UPSBillingAddress} from 'App/formComponents/forms/upsRegistration/fields/UPSBillingAddress';
import './styles.scss';

/** Default UPS Registration values */
export const defaultValues = {
  account_number: '',
  address: {
    line_1: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country_code: 'US'
  },
  name: '',
  company: '',
  email: '',
  phone: '',
  accept_terms: false
};

/** UPS Registration validation rules */
export const createUpsAccountValidationSchema = object().shape({
  account_number: string().required('Account number is required.'),
  address: object().shape({
    line_1: string().required('Street address is required.'),
    city: string().required('City is required.'),
    state_or_province: string().required('State is required.'),
    postal_code: string().required('Postal code is required.'),
    country_code: string().required('Country is required.')
  }),
  name: string().required('Name is required.'),
  company: string().required('Company is required.'),
  email: string().email('Enter a valid email.').required('Email is required.'),
  phone: string()
    .test('valid-phone', 'Enter a valid phone number.', (value) => {
      return validatePhoneNumber(value);
    })
    .required('Phone number is required'),
  accept_terms: bool().oneOf([true], 'Must accept the UPS License Agreement.')
});

export type UPSRegistrationFormValues = Omit<RegisterUPS, 'invoice_amount'> & {
  accept_terms: boolean;
};
type UPSRegistrationFormProps = {
  onSubmit: (
    values: UPSRegistrationFormValues,
    formikHelpers: FormikHelpers<UPSRegistrationFormValues>
  ) => Promise<void>;
  onCancel: () => void;
  values?: UPSRegistrationFormValues;
};

export const UPSRegistrationForm = ({values, onCancel, onSubmit}: UPSRegistrationFormProps) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={createUpsAccountValidationSchema}
      onSubmit={onSubmit}
      initialValues={{...defaultValues, ...values}}
    >
      {({handleSubmit, isSubmitting, isValid, dirty}) => (
        <form className="ups-registration__form" noValidate onSubmit={handleSubmit}>
          <Card title="Account" draggableProvided={null}>
            <div className="ups-registration__form-section account-number">
              <div className="ups-registration__section-title">
                <b>Account Number</b>
              </div>
              <div className="field-grid">
                <Field required name="account_number" label="UPS Account Number" component={FormikTextInput} />
              </div>
            </div>
          </Card>
          <Card title="Contact Information" draggableProvided={null}>
            <div className="field-grid">
              <Field required name="name" label="First and Last Name" component={FormikTextInput} />
              <Field required name="company" label="Company Name" component={FormikTextInput} />
              <Field required name="email" label="Email Address" component={FormikTextInput} />
              <Field required name="phone" label="Phone Number" component={FormikPhoneInput} />
            </div>
          </Card>
          <Card title="Billing Address" draggableProvided={null}>
            <UPSBillingAddress />
          </Card>
          <div className="grid-checkbox-field ups-registration__terms-checkbox">
            <Field
              name="accept_terms"
              label={
                <span>
                  I accept the{' '}
                  <a href="https://www.ups.com/assets/resources/media/en_US/UTA.pdf" target="_blank" rel="noreferrer">
                    UPS License Agreement
                  </a>
                </span>
              }
              component={FormikCheckbox}
            />
          </div>
          {dirty && (
            <FormFooter
              primaryActionName="Connect UPS"
              isValid={isValid}
              isSubmitting={isSubmitting}
              onCancel={onCancel}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
