import pluralize from 'pluralize';
import omit from 'lodash/omit';
import {
  FacilityAddress,
  Facility,
  CreateFacilityDockAppointmentRule,
  FacilityAppointmentType
} from '@shipwell/tempus-sdk';
import {AddressBookEntry, Address as LegacyAddress} from '@shipwell/backend-core-sdk';

import {AppointmentRulesType} from './types';

import {convertTimeToISO8601} from 'App/utils/dateTimeGlobalsTyped';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

export const toAppointmentRulesType = ({
  appointment_lead_time_duration,
  late_appointment_threshold_duration,
  first_appointment_start_time,
  last_appointment_end_time,
  exclude_dock_assignment_from_appointment_scheduling
}: Facility) => ({
  appointment_type: FacilityAppointmentType.ByAppointmentOnly, // HACK HACK HACK
  appointment_lead_time_duration,
  late_appointment_threshold_duration,
  first_appointment_start_time,
  last_appointment_end_time,
  exclude_dock_assignment_from_appointment_scheduling
});

export const withAppointmentRules = (facility: Facility, rules: AppointmentRulesType) => ({
  ...facility,
  ...rules
});
/**
 * Gets mapped the facility address object to create with dock scheduling. If the id property is populated there is an address
 * book entry associated with that address.
 */
export const formatV2AddressToFacilityAddress = (
  address: LegacyAddress | AddressBookEntry,
  timezone?: string
): FacilityAddress & {id?: string | null} => {
  if ('address' in address) {
    return mapAddressBookEntryToFacilityAddress(address, timezone);
  }
  return mapLegacyAddressToFacilityAddress(address, timezone);
};

function mapAddressBookEntryToFacilityAddress(
  address: AddressBookEntry,
  timezone?: string
): FacilityAddress & {id?: string | null} {
  const facilityAddress = mapLegacyAddressToFacilityAddress(address.address, timezone);
  return {
    ...facilityAddress,
    id: address.id
  };
}

function mapLegacyAddressToFacilityAddress(address: LegacyAddress, timezone?: string): FacilityAddress {
  const facilityAddress: FacilityAddress = {
    country: address.country || '',
    postal_code: address.postal_code || undefined,
    line_1: address.address_1 || undefined,
    line_2: address.address_2 || undefined,
    region: address.state_province || undefined,
    locality: address.city || undefined,
    timezone: timezone || address.timezone || ''
  };
  if (address.longitude != null && address.latitude != null) {
    facilityAddress.geolocation = {
      longitude: address.longitude,
      latitude: address.latitude
    };
  }
  return facilityAddress;
}

export function formatFacilityAddress(address: FacilityAddress & {formatted_address?: string}): string {
  return (
    address.formatted_address ||
    [
      address.line_1,
      address.line_2,
      address.line_3,
      [address.locality, address.region].filter(Boolean).join(', '),
      address.postal_code && `(${address.postal_code})`,
      address.country
    ]
      .filter(Boolean)
      .join(' ')
      .replace(/\n/g, ' ')
      .replace(/\s\s+/g, ' ')
      .trim()
  );
}

export function facilityAddressToAddressBookEntry(address: FacilityAddress): Partial<LegacyAddress> {
  const {line_1, line_2, line_3, locality: city, region, postal_code} = address;
  let {country} = address;
  country = country ?? '';
  let address2 = line_2 ?? '';
  if (line_3) {
    address2 = [address2, line_3].filter(Boolean).join('\n');
  }
  return {
    city,
    country,
    postal_code,
    address_1: line_1,
    address_2: address2,
    state_province: region,
    formatted_address: formatFacilityAddress(address)
  };
}

export function facilityAddressToLegacyAddress(address: FacilityAddress): LegacyAddress {
  const {line_1, line_2, line_3, locality: city, region, postal_code, timezone} = address;
  let {country} = address;
  country = country ?? '';
  let address2 = line_2 ?? '';
  if (line_3) {
    address2 = [address2, line_3].filter(Boolean).join('\n');
  }
  return {
    city,
    country,
    postal_code,
    address_1: line_1,
    address_2: address2,
    state_province: region,
    formatted_address: formatFacilityAddress(address),
    timezone
  };
}

type IntervalOptionsType = {totalHours: number; interval: number};

/**
 * function that takes an hour and interval as params
 * to generate a list of time interval strings as label
 * and their corresponding ISO-8601 duration as value
 * example output: [..., {label: "4 hours 15 minutes", value: "P0DT4H15M0.000000S"}]
 */
export const generateTimeIntervalOptions = ({totalHours, interval = 15}: IntervalOptionsType) => {
  /* convert the given hours to minutes 
    so that we can divide the hours into intervals in the loop below */
  const hoursInMinutes = totalHours * 60;
  const timeIntervalOptions = [];
  for (let i = 0; i <= hoursInMinutes; i += interval) {
    // division result will be the hour
    const currentHour = Math.floor(i / 60);
    // remainder result will be intervals of 15 minutes (or whatever the value for interval is)
    const currentMinute = i % 60;
    const hourString = `${currentHour} ${pluralize('hour', currentHour)}`;
    const minuteString = currentMinute === 0 ? '' : `${currentMinute} minutes`;
    const timeStringLabel = `${hourString} ${minuteString}`.trim();
    if (timeStringLabel === '0 hours') continue;
    // get the ISO-8601 value for the time string
    const timeStringValue = convertTimeToISO8601({
      hrs: currentHour,
      mins: currentMinute
    });
    timeIntervalOptions.push({
      label: timeStringLabel,
      value: timeStringValue
    });
  }
  return timeIntervalOptions;
};
export const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> =>
  input.status === 'fulfilled';

export const formatDockRuleCreationPayload = (dockRule: CreateFacilityDockAppointmentRule, loadTypeId?: string) => {
  // payload that's common across both scenarios - load type vs. no load type
  const defaultPayload = {
    ...omitEmptyKeysWithEmptyObjectsRemoved(dockRule),
    dock_accessorials: dockRule.dock_accessorials,
    is_public: dockRule.is_public
  };
  const payloadWithLoadType = {
    ...defaultPayload,
    load_type_id: loadTypeId
  };

  // route requires we omit the following fields when there's a load type id
  const filteredPayloadWithLoadType = omit(payloadWithLoadType, [
    'delivery_type',
    'mode',
    'equipment_type',
    'product_reference',
    'packaging_type',
    'product_category'
  ]);
  return loadTypeId ? filteredPayloadWithLoadType : defaultPayload;
};
