import {toTitleCase} from 'App/utils/string';

export type EnumType = {[k: string]: string};
type CaseType = 'uppercase' | 'titleCase' | 'default';

/* converts enums from the SDK to the label/value format
    to be used with FormikSelect components */
export const enumToOptionConverter = (enumerable: EnumType, caseType: CaseType = 'titleCase') => {
  return Object.keys(enumerable).map((key) => ({
    label: formatLabel(enumerable[key], caseType),
    value: enumerable[key]
  }));
};

const formatLabel = (value: string, caseType: CaseType): string => {
  if (caseType === 'uppercase') return value.toUpperCase();
  if (caseType === 'titleCase') return toTitleCase(value);
  return value;
};
