import {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {noop} from 'lodash';
import {Modal} from '@shipwell/shipwell-ui';
import UnassignedPurchaseOrdersList from './unassignedPurchaseOrdersList';
import ConfirmOrderDatesForm from './confirmOrderDatesForm';
import withStatusToasts from 'App/components/withStatusToasts';
import {updatePurchaseOrdersShipment, updatePurchaseOrderById} from 'App/api/purchaseOrders';
import {getPurchaseOrderByShipment} from 'App/containers/purchaseOrders/actions/async';
import './styles.scss';

const ShipmentPurchaseOrderConsolidationForm = (props) => {
  const {
    show,
    onHide,
    dispatch,
    shipment,
    onUpdateFailure,
    onUpdateSuccess,
    setAddOrderSuccess = noop,
    setAddOrderError = noop,
    shipmentAssemblyVariant = false,
    addOrdersToShipmentAssembly
  } = props;
  /**
   ** Lifecycle
   */
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // selected orders were checked and allocated appropriately to undatedPurchaseOrders and validPurchaseOrders containers.
  const [datesChecked, setDatesChecked] = useState(false);
  // bool confirming selected orders were updated and valid to build shipment
  const [ordersDidUpdate, setOrdersDidUpdate] = useState(false);

  /**
   ** Containers
   */

  // Orders selected by table multi check on step 1
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState([]);
  // Orders selected that don't have dates from step 1 to step 2
  const [undatedPurchaseOrders, setUndatedSelectedPurchaseOrders] = useState([]);
  // Orders selected that have dates from step 1 to step 2
  const [validPurchaseOrders, setValidSelectedPurchaseOrders] = useState([]);
  // Orders whose dates were updated for handling submit
  const [updatedOrders, setUpdatedOrders] = useState([]);

  useEffect(() => {
    if (datesChecked === false) {
      setUndatedSelectedPurchaseOrders([]);
      setValidSelectedPurchaseOrders([]);
    }
  }, [datesChecked]);

  // Submit case
  useEffect(() => {
    if (ordersDidUpdate && updatedOrders.length) {
      handleUpdateShipmentPurchaseOrders(updatedOrders);
    }
  }, [ordersDidUpdate, updatedOrders, handleUpdateShipmentPurchaseOrders]);

  /**
   ** Functions
   */

  // Checks the selected orders from <UnassignedPurchaseOrdersList> and allocates them
  // appropriately to [undatedPurchaseOrders] and [validPurchaseOrders] containers.
  const checkDates = () => {
    const validOrders = [];
    const undatedOrders = [];
    selectedPurchaseOrders.forEach((order) => {
      const orderToConfirm = {...order};
      orderToConfirm.planned_delivery_end_datetime =
        orderToConfirm.planned_delivery_end_datetime || orderToConfirm.planned_delivery_start_datetime;
      orderToConfirm.planned_delivery_start_datetime =
        orderToConfirm.planned_delivery_start_datetime || orderToConfirm.planned_delivery_end_datetime;
      orderToConfirm.planned_pickup_end_datetime =
        orderToConfirm.planned_pickup_end_datetime || orderToConfirm.planned_pickup_start_datetime;
      orderToConfirm.planned_pickup_start_datetime =
        orderToConfirm.planned_pickup_start_datetime || orderToConfirm.planned_pickup_end_datetime;
      if (
        orderToConfirm.planned_delivery_end_datetime &&
        orderToConfirm.planned_delivery_start_datetime &&
        orderToConfirm.planned_pickup_end_datetime &&
        orderToConfirm.planned_pickup_start_datetime
      ) {
        validOrders.push(orderToConfirm);
      } else {
        undatedOrders.push(orderToConfirm);
      }
    });
    setValidSelectedPurchaseOrders(validOrders);
    setUndatedSelectedPurchaseOrders(undatedOrders);
    setDatesChecked(true);
  };

  // Updates the selected purchase orders with the new or updated dates so they
  // can be valid to build to the shipment
  const handleUpdatePurchaseOrders = useCallback(
    async (orders = []) => {
      if (selectedPurchaseOrders.length < orders.length || !datesChecked || !orders.length) {
        return;
      }

      const promises = [];
      try {
        orders.forEach((order) => {
          promises.push(updatePurchaseOrderById(order.id, order));
        });

        const updatePurchaseOrderByIdResponse = await Promise.all(promises);
        if (updatePurchaseOrderByIdResponse.length && updatePurchaseOrderByIdResponse[0].body) {
          const updatedOrders = updatePurchaseOrderByIdResponse.map((response) => response.body);
          setUpdatedOrders(updatedOrders);
          setOrdersDidUpdate(true);
        }
      } catch (error) {
        console.error(error);
        setAddOrderError('Error', error.error_description);
        setSubmitting(false);
      }
    },
    [datesChecked, selectedPurchaseOrders.length, setAddOrderError]
  );

  // Asssigns the updated purchase orders to the shipment
  const handleUpdateShipmentPurchaseOrders = useCallback(
    async (orders = []) => {
      if (orders.length < 1 && selectedPurchaseOrders.length < 1) {
        return;
      }
      setSubmitting(true);
      const selectedPurchaseOrderIds = orders.map(({id}) => id);
      try {
        const response = await updatePurchaseOrdersShipment({
          shipment: shipment.id,
          purchase_orders: selectedPurchaseOrderIds
        });
        if (response && response.body) {
          setAddOrderSuccess();
          dispatch(getPurchaseOrderByShipment(response.body.id));
          onUpdateSuccess(response.body);
          setTimeout(() => {
            onHide();
          }, 3000);
        }
      } catch (error) {
        console.error(error);
        setSelectedPurchaseOrders([]);
        setLoading(false);
        setAddOrderError('Error!', error.error_description);
        setSubmitting(false);
        setTimeout(() => {
          if (onUpdateFailure) {
            onUpdateFailure();
          }
        }, 3000);
      }
      setSubmitting(false);
    },
    [
      dispatch,
      onUpdateFailure,
      onUpdateSuccess,
      selectedPurchaseOrders,
      shipment?.id,
      onHide,
      setAddOrderError,
      setAddOrderSuccess
    ]
  );

  const handleFormSubmit = (values) => {
    const {undatedOrders = [], validOrders = []} = values;
    handleUpdatePurchaseOrders([...undatedOrders, ...validOrders]);
  };

  const resetFormState = () => {
    setSelectedPurchaseOrders([]);
    setValidSelectedPurchaseOrders([]);
    setUndatedSelectedPurchaseOrders([]);
    setDatesChecked(false);
    onHide();
  };

  const handleAddOrdersToShipmentAssembly = (selectedPurchaseOrders) => {
    addOrdersToShipmentAssembly(selectedPurchaseOrders);
    resetFormState();
  };

  return (
    <>
      <ConfirmOrderDatesForm
        shipment={shipment}
        setSelectedPurchaseOrders={setSelectedPurchaseOrders}
        undatedPurchaseOrders={undatedPurchaseOrders}
        setUndatedSelectedPurchaseOrders={setUndatedSelectedPurchaseOrders}
        validPurchaseOrders={validPurchaseOrders}
        setValidSelectedPurchaseOrders={setValidSelectedPurchaseOrders}
        setDatesChecked={setDatesChecked}
        onBack={() => {
          setUndatedSelectedPurchaseOrders([]);
          setValidSelectedPurchaseOrders([]);
          setDatesChecked(false);
        }}
        show={show && datesChecked}
        handleFormSubmit={handleFormSubmit}
        PrimaryButtonProps={{
          loading: loading || submitting,
          disabled: loading || submitting
        }}
        onClose={() => {
          resetFormState();
        }}
      />

      <Modal
        className="shipment-purchase-orders-modal"
        show={show && !datesChecked}
        title="Add Orders"
        onClose={() => {
          resetFormState();
        }}
        size="large"
        primaryBtnName="Add To Shipment"
        onPrimaryAction={
          shipmentAssemblyVariant ? () => handleAddOrdersToShipmentAssembly(selectedPurchaseOrders) : checkDates
        }
        PrimaryButtonProps={{
          loading: loading || submitting,
          disabled: loading || submitting || selectedPurchaseOrders.length < 1
        }}
        disabled={submitting}
      >
        <UnassignedPurchaseOrdersList
          shipment={shipment}
          selectedPurchaseOrders={selectedPurchaseOrders}
          setSelectedPurchaseOrders={setSelectedPurchaseOrders}
        />
      </Modal>
    </>
  );
};

ShipmentPurchaseOrderConsolidationForm.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  shipment: PropTypes.object,
  onUpdateFailure: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAddOrderSuccess: PropTypes.func,
  setAddOrderError: PropTypes.func,
  shipmentAssemblyVariant: PropTypes.bool,
  addOrdersToShipmentAssembly: PropTypes.func
};

export default compose(withStatusToasts)(ShipmentPurchaseOrderConsolidationForm);
