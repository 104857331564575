import {useState} from 'react';
import {Modal, StepIndicator, useStepIndicator} from '@shipwell/shipwell-ui';
import {Formik, Form} from 'formik';
import {useMutation, useQuery} from '@tanstack/react-query';
import pluralize from 'pluralize';
import {PaginatedRfpBid, RfpBidRequest} from '@shipwell/backend-core-sdk';
import CriteriaFormFieldsAlpha from 'App/containers/userCompany/rfpsAlpha/CriteriaFormAlpha';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {createRfpBid, updateRfpBid, deleteRfpBid, getRfpImportResults} from 'App/api/rfp/typed';
import {RFP_BIDS_ALPHA_QUERY, RFP_IMPORT_RESULTS} from 'App/data-hooks/queryKeys';
import CreateImport from 'App/components/imports/create';
import type {CriteriaFormValues} from 'App/containers/userCompany/rfpsAlpha/utils/validation';
import {validationSchema} from 'App/containers/userCompany/rfpsAlpha/utils/validation';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

const ImportModalAlpha = ({
  showModal,
  toggleModal,
  setError
}: {
  toggleModal: () => void;
  showModal: boolean;
} & Pick<WithStatusToastProps, 'setError'>) => {
  const [showSteps, setShowSteps] = useState(true);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [criteriaFormValues, setCriteriaFormValues] = useState({
    rfp_date_range: [''],
    tender_expiration: 1800,
    rfp_name: ''
  });

  const steps = ['DEFINE CRITERIA', 'IMPORT DATA'];
  const {activeStep, setNextStep, setPreviousStep} = useStepIndicator(steps);

  const createBid = useMutation(createRfpBid);

  const rfpBidId = createBid.data?.data.id;

  const importResultsQuery = useQuery(
    [RFP_IMPORT_RESULTS, rfpBidId],
    async () => {
      const response = await getRfpImportResults(rfpBidId || '');
      return response.data;
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.imported_rows_failed_count) {
          setError(
            'Error!',
            `${pluralize(
              'row',
              data.imported_rows_failed_count,
              true
            )} of your upload were unsuccessfully matched, please try again.`
          );
        }
      },
      onError: (error) => {
        console.error(error);
        setError('Error!', 'There was a problem importing the file!');
      }
    }
  );

  const updateBidMutation = useMutation<
    Awaited<ReturnType<typeof updateRfpBid>>,
    unknown,
    {rfpBidId: string; body: RfpBidRequest}
  >(({rfpBidId, body}) => updateRfpBid(rfpBidId, body));

  const handleSubmit = (values: CriteriaFormValues) => {
    const {rfp_date_range, rfp_name, tender_expiration} = values;
    setCriteriaFormValues(values);

    if (rfpBidId) {
      updateBidMutation.mutate({
        rfpBidId: rfpBidId,
        body: {
          name: rfp_name,
          start_date: rfp_date_range[0],
          end_date: rfp_date_range[1],
          workflow_expiration_seconds: tender_expiration
        }
      });
    } else {
      createBid.mutate({
        name: rfp_name,
        start_date: rfp_date_range[0],
        end_date: rfp_date_range[1],
        workflow_expiration_seconds: tender_expiration
      });
    }

    setNextStep();
  };

  const deleteBidOptimisticHandlers = useOptimisticUpdate<PaginatedRfpBid, unknown, string>();

  const deleteBidMutation = useMutation(
    deleteRfpBid,
    deleteBidOptimisticHandlers([RFP_BIDS_ALPHA_QUERY], {
      mergeData: (prevRfps, deletedId) => {
        const filteredRfps = prevRfps?.results?.filter((rfp) => rfp.id !== deletedId);
        const newRfps = {
          ...prevRfps,
          results: filteredRfps
        };
        return newRfps;
      }
    })
  );

  const handleCancel = () => {
    toggleModal();
    if (rfpBidId) {
      deleteBidMutation.mutate(rfpBidId);
    }
  };

  return (
    <Modal
      fullScreen={showFullScreen}
      onClose={handleCancel}
      title={`Import ${!activeStep ? 'Lane' : ''} RFP`}
      show={showModal}
      bodyVariant={activeStep === 0 ? 'disableOverflowScroll' : null}
      footerComponent={null}
    >
      {showSteps ? (
        <div className="flex justify-center py-4">
          <div className="w-1/2">
            <StepIndicator steps={steps} activeStep={activeStep} alternativeLabel />
          </div>
        </div>
      ) : null}

      {activeStep === 0 ? (
        <Formik
          onSubmit={handleSubmit}
          initialValues={criteriaFormValues}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({isSubmitting, isValid}) => (
            <Form>
              <CriteriaFormFieldsAlpha />
              <ModalFormFooter
                isValid={isValid}
                isSubmitting={isSubmitting}
                onCancel={toggleModal}
                primaryActionName="Next"
              />
            </Form>
          )}
        </Formik>
      ) : (
        <CreateImport
          setUseFullScreen={(useFullScreen: boolean) => {
            setShowFullScreen(useFullScreen);
            setShowSteps(false);
          }}
          onCancel={toggleModal}
          type="RFP_ROWS"
          onPrevious={() => setPreviousStep()}
          opts={{rfpBidId}}
          onImportSuccess={async () => {
            toggleModal();
            await importResultsQuery.refetch();
          }}
          skipOnSuccessModalToggle
          validationMessage={
            'You can now close this modal and check the RFPs page later. This import could take several minutes to complete.'
          }
        />
      )}
    </Modal>
  );
};

export default ImportModalAlpha;
