import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {PropsWithChildren} from 'react';

type FieldWrapperProps = {
  TooltipProps?: {
    title: string;
    description: string;
  };
};

export const FieldWrapper = ({children, TooltipProps}: PropsWithChildren<FieldWrapperProps>) => (
  <div className="flex justify-between gap-1">
    <div className="w-full">{children}</div>
    {!!TooltipProps && (
      <Tooltip
        placement="right"
        tooltipContent={
          <div className="flex flex-col gap-2">
            <span className="font-bold">{TooltipProps.title}</span>
            <span>{TooltipProps.description}</span>
          </div>
        }
        wrapperClassname="items-center"
      >
        <SvgIcon name="InfoOutlined" />
      </Tooltip>
    )}
  </div>
);
