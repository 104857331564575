/* eslint-disable max-len */
import {Component} from 'react';
import {connect} from 'react-redux';
import SelectField from 'App/formComponents/fields/select';
import {fetchPackageTypes} from 'App/actions/shipments';

/**
 * Package Type Field
 */
class PackageTypeField extends Component {
  static defaultProps = {
    value: '',
    name: 'package_type',
    input: {},
    placeholder: 'Select',
    expenseClasses: [],
    clearable: true
  };

  componentDidMount() {
    const {company, packageTypes} = this.props;

    if (company.id && !packageTypes.length) {
      this.getPackageTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const {company, packageTypes} = this.props;

    if (company.id && prevProps.company !== company && !packageTypes.length) {
      this.getPackageTypes();
    }
  }

  /**
   * Package Types - non parcel
   */
  async getPackageTypes() {
    try {
      return await this.props.dispatch(fetchPackageTypes());
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      input,
      arrowRenderer,
      placeholder,
      packageTypes,
      fedexPackageTypes,
      isParcel,
      field = {},
      ...rest
    } = this.props;
    const value = input.value || field.value || rest.value;
    const name = input.name || field.name || rest.name;
    const options = this.props.options || packageTypes;
    const onChange = input.onChange || field.onChange || rest.onChange;

    return (
      <SelectField
        {...rest}
        value={value}
        name={name}
        placeholder={placeholder}
        options={options}
        onChange={(value) => {
          if (value && field && field.onChange) {
            return onChange({target: {value, name}});
          }
          if (value && onChange) {
            return onChange(value);
          }
          if (!value && field && field.onChange) {
            return onChange({target: {value: null, name}});
          }
          if (!value) {
            return onChange(null);
          }
        }}
      />
    );
  }
}

export default connect((state) => ({
  company: state.auth.company,
  packageTypes: state.shipments.packageTypes.map((type) => ({id: type.code, name: type.description}))
}))(PackageTypeField);
