import {
  REQUEST_USER_COMPANY_PENDING,
  REQUEST_USER_COMPANY_SUCCESS,
  REQUEST_USER_COMPANY_ERROR,
  UPDATE_USER_COMPANY_SUCCESS
} from '../actions';

/** Initial state */
export const DEFAULT_STATE = {
  isFetching: false,
  error: false
};

export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_USER_COMPANY_PENDING:
      return {
        ...state,
        isFetching: true
      };

    case REQUEST_USER_COMPANY_SUCCESS:
    case UPDATE_USER_COMPANY_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false
      };

    case REQUEST_USER_COMPANY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};
