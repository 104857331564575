import {
  getSchemaByEntityType,
  entityTypes
} from 'App/containers/shipments/details/TenderDetails/ShipperTenderDetailsSchema';

export const ShipperTenderDetailsController = ({
  shipmentId,
  transportationOrderId,
  onActionSelect
}: {
  shipmentId: string;
  transportationOrderId: string;
  onActionSelect(): void;
}) => {
  const cardSchema = getSchemaByEntityType(entityTypes.DRAYAGE_TENDER);

  const Header = cardSchema.header;
  const Footer = cardSchema.footer;

  const leftHandCardComponents = cardSchema.leftHandCards;
  const rightHandCardComponents = cardSchema.rightHandCards;

  return (
    <div className="grid gap-4">
      <Header shipmentId={shipmentId} transportationOrderId={transportationOrderId} />
      <div className="grid grid-cols-[35%,65%] items-start gap-4">
        <div className="grid gap-4">
          {leftHandCardComponents.map((CardComponent) => (
            <CardComponent
              key={transportationOrderId}
              shipmentId={shipmentId}
              transportationOrderId={transportationOrderId}
            />
          ))}
        </div>
        <div className="grid gap-4">
          {rightHandCardComponents.map((CardComponent) => (
            <CardComponent
              key={transportationOrderId}
              shipmentId={shipmentId}
              transportationOrderId={transportationOrderId}
            />
          ))}
        </div>
      </div>
      <Footer onActionSelect={onActionSelect} shipmentId={shipmentId} transportationOrderId={transportationOrderId} />
    </div>
  );
};
