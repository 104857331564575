// globals.js
// globally shared constants and functions
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import curryRight from 'lodash/curryRight';
import get from 'lodash/get';
import head from 'lodash/head';
import moment from 'moment-timezone';
import GoogleMapsLoader from 'google-maps';
import {ChargeCategory} from '@shipwell/backend-core-sdk';
import getNil from 'App/utils/getNil';
import {getSubdomain} from 'App/utils/location';
import {checkShipmentModes} from 'App/utils/globalsTyped';
// CONSTANTS
export const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
export const PNF = require('google-libphonenumber').PhoneNumberFormat;
export const freightClasses = [
  {id: 50, name: '50'},
  {id: 55, name: '55'},
  {id: 60, name: '60'},
  {id: 65, name: '65'},
  {id: 70, name: '70'},
  {id: 77.5, name: '77.5'},
  {id: 85, name: '85'},
  {id: 92.5, name: '92.5'},
  {id: 100, name: '100'},
  {id: 110, name: '110'},
  {id: 125, name: '125'},
  {id: 150, name: '150'},
  {id: 175, name: '175'},
  {id: 200, name: '200'},
  {id: 250, name: '250'},
  {id: 300, name: '300'},
  {id: 400, name: '400'},
  {id: 500, name: '500'}
];

export const loadingMessages = [
  'To get a quote for a shipment, click on New -> Quote.',
  'Let’s build the future of freight together.',
  'Make heroic choices to drive value and impact.',
  'Be diverse in thought.',
  'Diverse thinkers guard against groupthink.',
  'Diverse thinkers increase the scale of new insights.',
  'Diverse thinkers identify who can best tackle a problem.',
  'Diversity is the core to our business.',
  'Customer service is the cornerstone of Shipwell.',
  'Did you know Shipwell is connected to over 2 million carriers?',
  'Shipwell is the easiest, most automated way for any business to ship anything anywhere.',
  'Shipwell is connecting, automating, and optimizing the world’s supply chains.',
  'Shipwell is the advocate for the shipper, broker, and carrier.',
  'Shipwell is bringing the world of freight into one place.',
  '90% of the food Americans eat is hauled by refrigerated trucks.',
  '1 out of every 14 jobs in the U.S. is created or directly affected by the trucking industry.',
  'If you lined up all the trucks in operation today, they would reach the moon!',
  'Charles Fruehauf invented the first tractor-trailer over one hundred years ago in 1914 when a customer wanted a vehicle that could haul a boat.',
  'Most trucking companies in America are small businesses.',
  'In 2017, the freight industry revenue was $700.3 billion. That’s 3.61% of America’s GDP.'
];

export const supportedTimezones = [
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC'
];

export const timelineEventTypes = [
  {id: 'BOOKED', name: 'Booked'},
  {id: 'CARRIER_MATCHED', name: 'Carrier Matched'},
  {id: 'DETAILS_CHANGED', name: 'Details Changed'},
  {id: 'DRIVER_ASSIGNED', name: 'Driver Assigned'},
  {id: 'ETA_CHANGED', name: 'ETA Changed'},
  {id: 'LOCATION_UPDATED', name: 'Location Updated'},
  {id: 'TRACK_RECEIVED', name: 'Track Received'}
];

export const tagColors = [
  {id: '#7180ac', name: 'well_water'}, // peritwinkle-toes
  {id: '#B41D13', name: 'cherry_bomb'}, // red-alert
  {id: '#DF9100', name: 'ricky_fowler'}, // saffron-submarine
  {id: '#1B7B3B', name: 'electric_trees'}, // green-goblin
  {id: '#11A2DC', name: 'so_cirrus'}, // sky-ship
  {id: '#838383', name: 'rock_bottom'}, // grey-4
  {id: '#870058', name: 'the_hotness'}, // marooned-on-an-island
  {id: '#0A6FDB', name: 'sky_ship'}, // blue-man-crew
  {id: '#14592B', name: 'lake_travis'}, // green-goblin-dark
  {id: '#B9BAB9', name: 'faded_white'} // grey-3
];

export const notificationDescriptions = {
  automated_carrier_status_changes:
    'Get a notification that summarizes the carrier status changes made by the automated compliance policy process',
  alert_event_assigned: 'Get a notification when an alert from the Compass Dashboard gets assigned to you',
  cancellation: "Get a notification when a shipment you've booked or been assigned to is canceled",
  case_assigned: 'Get a notification when someone else assigns a case to you',
  daily_digest_email: 'Get a daily summary of all your container tracking updates',
  delayed: "Get a notification when a shipment you've booked or been assigned to is marked as delayed",
  delivered: "Get a notification when a shipment you've booked or been assigned to is delivered",
  delivered_with_exception:
    "Get a notification when a shipment you've booked or been assigned to is delivered with an exception (e.g., recipient unavailable, incorrect address, etc)",
  dispatched: "Get a notification when a shipment you've created is dispatched to a carrier",
  load_board_shipment_booked:
    "Get notifications for loads on your company's load board, including when a carrier uses 'Book Now', submits a bid and sends a message on a load",
  missed_delivery:
    "Get a notification when a shipment you've booked or been assigned to is marked as 'Missed Delivery' by the carrier",
  missed_pickup:
    "Get a notification when a shipment you've booked or been assigned to is marked as 'Missed Pickup' by the carrier",
  new_bid_on_quote: 'Get a notification when you receive a new bid from a carrier on a marketplace shipment auction',
  new_quote_action: 'Get a notification when a customer or carrier accepts, declines, or modifies a quote',
  new_quoting_message:
    'Get a notification when you receive a new message from a carrier on a marketplace shipment auction',
  new_shipment_message:
    'Get a notification when you receive a new message from a customer, carrier, or teammate on a shipment you own or for which you are assigned',
  new_spot_quote: 'Get a notification when a carrier submits a new quote',
  out_for_delivery:
    "Get a notification when a shipment you've booked or been assigned to is marked as 'Out for delivery' by the carrier",
  picked_up:
    "Get a notification when a shipment you've booked or been assigned to is marked as 'Picked up' by the carrier",
  quote_request: 'Get a notification when another company requests a quote from you on a shipment',
  reconsigned: "Get a notification when a shipment you've booked or been assigned to is reconsigned to a new carrier",
  rejection_at_delivery:
    "Get a notification when a shipment you've booked or been assigned to is marked as 'Rejected at delivery' by the carrier",
  request_appointment_scheduling:
    'Get a notification when a stop requires an appointment to be set through third party dock scheduling software',
  shipment_booked: 'Get a notification when you book a shipment successfully',
  shipment_rep_assigned: 'Get a notification when you are added as shipment representative on a shipment',
  spot_negotiation_closed: 'Get a notification when a spot negotiation on which you bid is closed',
  task_assigned: 'Get a notification when someone else assigns a task to you',
  carrier_point_of_contact_assigned:
    'Get a notification when you are assigned as a carrier point of contact for a shipment',
  pre_pro_threshold_reached:
    'Get a notification when carrier PRO Number blocks reach the threshold set up in the carrier profile',
  no_available_pre_pro: 'Get a notification when there are no carrier PRO Numbers remaining in the carrier profile'
};

export const notificationTitles = {
  pre_pro_threshold_reached: 'PRO # Threshold Reached',
  no_available_pre_pro: 'No Available PRO #',
  eta_changed: 'ETA Changed',
  daily_digest_email: 'Container Tracking Updates'
};

const disabledNotificationTypes = {
  daily_digest_email: ['inbox']
};

export const isDisabledNotificationType = (notificationType, medium) =>
  disabledNotificationTypes[notificationType]?.includes(medium);

export const defaultAvatars = [
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/bear.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/cat.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/dog.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/elephant.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/giraffe.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/hippopotamus.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/monkey.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/panda.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/penguin.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/pig.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/rabbit.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/sheep.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/tiger.png',
  'https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/wolf.png'
];

export const reeferTypes = [2, 41, 42, 43, 67, 68];

export const pickupIcon = (
  <svg width="25" height="25" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="9.5" r="9" fill="#006CA3" stroke="#006CA3" />
    <path
      d="M9.88914 4.79053L9.88901 4.79047C9.75849 4.73645 9.6119 4.7366 9.48154 4.79034L9.48108 4.79053C9.41636 4.81736 9.3577 4.8564 9.30831 4.90524L9.30735 4.90621L5.90635 8.30749C5.69788 8.51596 5.69788 8.8534 5.90635 9.06186C6.11481 9.27033 6.45226 9.27033 6.66072 9.06186L9.15165 6.57094L9.15165 14.6374C9.15165 14.9323 9.39028 15.1709 9.68511 15.1709C9.97994 15.1709 10.2186 14.9323 10.2186 14.6374L10.2186 6.57094L12.7095 9.06186C12.918 9.27033 13.2554 9.27033 13.4639 9.06186C13.5678 8.95793 13.6201 8.821 13.6201 8.68468C13.6201 8.54836 13.5678 8.41142 13.4639 8.30749L10.0635 4.90716C10.0633 4.90697 10.0632 4.90678 10.063 4.90659C10.0122 4.85543 9.95257 4.81683 9.88914 4.79053Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.5"
    />
  </svg>
);

export const deliveryIcon = (
  <svg width="25" height="25" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="9.5" r="9" fill="#006CA3" stroke="#006CA3" />
    <path
      d="M9.48097 15.1304L9.48111 15.1304C9.61162 15.1844 9.75822 15.1843 9.88857 15.1306L9.88904 15.1304C9.95376 15.1035 10.0124 15.0645 10.0618 15.0157L10.0628 15.0147L13.4638 11.6134C13.6722 11.4049 13.6722 11.0675 13.4638 10.859C13.2553 10.6506 12.9179 10.6506 12.7094 10.859L10.2185 13.35L10.2185 5.28346C10.2185 4.98864 9.97983 4.75 9.68501 4.75C9.39018 4.75 9.15154 4.98864 9.15154 5.28346L9.15154 13.35L6.66062 10.859C6.45215 10.6506 6.11471 10.6506 5.90624 10.859C5.80231 10.963 5.74997 11.0999 5.74997 11.2362C5.74997 11.3725 5.80231 11.5095 5.90624 11.6134L9.30658 15.0137C9.30677 15.0139 9.30696 15.0141 9.30715 15.0143C9.35792 15.0655 9.41755 15.1041 9.48097 15.1304Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.5"
    />
  </svg>
);
export const initialShipmentObj = {
  stops: [
    {
      location: {
        address: {}
      },
      location_type: 1,
      accessorials: [],
      schedule_date_toggle: '1',
      schedule_time_toggle: '2',
      planned_time_window_start: moment().hour(8).minute(0).second(0).toDate(),
      planned_time_window_end: moment().hour(18).minute(0).second(0).toDate(),
      planning_window: {
        start: moment(Date.now()).hours(8).format('YYYY-MM-DD HH:mm'),
        end: moment(Date.now()).add(1, 'days').hours(18).format('YYYY-MM-DD HH:mm')
      },
      point_of_contacts: [
        {
          preferences: {
            select_all: false,
            shipment_booked: false,
            picked_up: false,
            eta_changed: false,
            delayed: false,
            cancellation: false,
            delivered: false
          }
        }
      ]
    },
    {
      location: {
        address: {}
      },
      location_type: 1,
      schedule_date_toggle: '1',
      schedule_time_toggle: '2',
      planned_time_window_start: moment().hour(8).minute(0).second(0).toDate(),
      planned_time_window_end: moment().hour(18).minute(0).second(0).toDate(),
      planning_window: {
        start: moment(Date.now()).add(2, 'days').hours(8).format('YYYY-MM-DD HH:mm'),
        end: moment(Date.now()).add(2, 'days').hours(18).format('YYYY-MM-DD HH:mm')
      },
      accessorials: [],
      point_of_contacts: [
        {
          preferences: {
            select_all: false,
            shipment_booked: false,
            picked_up: false,
            eta_changed: false,
            delayed: false,
            cancellation: false,
            delivered: false
          }
        }
      ]
    }
  ],
  ftl_estimated_weight_unit: 'LB',
  accessorials: [],
  service_level: '19',
  mode: '1',
  equipment_type: '1',
  carrier: 'select'
};

export const initialQuotingObj = {
  stops: [
    {
      location_type: 1,
      schedule_date_toggle: '1',
      schedule_time_toggle: '2',
      accessorials: [],
      location: {}
    },
    {
      location_type: 1,
      schedule_date_toggle: '1',
      schedule_time_toggle: '2',
      accessorials: [],
      location: {}
    }
  ],
  ignoreValidation: false,
  ftl_estimated_weight_unit: 'LB',
  mode: [],
  accessorials: []
};

export const billingOptions = [
  {name: 'Flat Fee', id: 1},
  {name: 'Percentage', id: 2}
];

export const states = [
  {name: 'Alabama', id: 'AL'},
  {name: 'Alaska', id: 'AK'},
  {name: 'Arizona', id: 'AZ'},
  {name: 'Arkansas', id: 'AR'},
  {name: 'California', id: 'CA'},
  {name: 'Colorado', id: 'CO'},
  {name: 'Connecticut', id: 'CT'},
  {name: 'Delaware', id: 'DE'},
  {name: 'District Of Columbia', id: 'DC'},
  {name: 'Florida', id: 'FL'},
  {name: 'Georgia', id: 'GA'},
  {name: 'Hawaii', id: 'HI'},
  {name: 'Idaho', id: 'ID'},
  {name: 'Illinois', id: 'IL'},
  {name: 'Indiana', id: 'IN'},
  {name: 'Iowa', id: 'IA'},
  {name: 'Kansas', id: 'KS'},
  {name: 'Kentucky', id: 'KY'},
  {name: 'Louisiana', id: 'LA'},
  {name: 'Maine', id: 'ME'},
  {name: 'Maryland', id: 'MD'},
  {name: 'Massachusetts', id: 'MA'},
  {name: 'Michigan', id: 'MI'},
  {name: 'Minnesota', id: 'MN'},
  {name: 'Mississippi', id: 'MS'},
  {name: 'Missouri', id: 'MO'},
  {name: 'Montana', id: 'MT'},
  {name: 'Nebraska', id: 'NE'},
  {name: 'Nevada', id: 'NV'},
  {name: 'New Hampshire', id: 'NH'},
  {name: 'New Jersey', id: 'NJ'},
  {name: 'New Mexico', id: 'NM'},
  {name: 'New York', id: 'NY'},
  {name: 'North Carolina', id: 'NC'},
  {name: 'North Dakota', id: 'ND'},
  {name: 'Ohio', id: 'OH'},
  {name: 'Oklahoma', id: 'OK'},
  {name: 'Oregon', id: 'OR'},
  {name: 'Pennsylvania', id: 'PA'},
  {name: 'Rhode Island', id: 'RI'},
  {name: 'South Carolina', id: 'SC'},
  {name: 'South Dakota', id: 'SD'},
  {name: 'Tennessee', id: 'TN'},
  {name: 'Texas', id: 'TX'},
  {name: 'Utah', id: 'UT'},
  {name: 'Vermont', id: 'VT'},
  {name: 'Virginia', id: 'VA'},
  {name: 'Washington', id: 'WA'},
  {name: 'West Virginia', id: 'WV'},
  {name: 'Wisconsin', id: 'WI'},
  {name: 'Wyoming', id: 'WY'}
];

export const provinces = [
  {name: 'Alberta', id: 'AB'},
  {name: 'British Columbia', id: 'BC'},
  {name: 'Manitoba', id: 'MB'},
  {name: 'New Brunswick', id: 'NB'},
  {name: 'Newfoundland and Labrador', id: 'NL'},
  {name: 'Nova Scotia', id: 'NS'},
  {name: 'Northwest Territories', id: 'NT'},
  {name: 'Nunavut', id: 'NU'},
  {name: 'Ontario', id: 'ON'},
  {name: 'Prince Edward Island', id: 'PE'},
  {name: 'Quebec', id: 'QC'},
  {name: 'Saskatchewan', id: 'SK'},
  {name: 'Yukon', id: 'YT'}
];

// PERMISSIONS
export const permUpdateShipment = 'shipments.update_shipments';
export const permViewCustomers = 'customer_relationships.view';
export const permViewCompany = 'company.view_company_details';
export const permViewUsers = 'users.view_company_users';
export const permViewCarriers = 'carrier_relationships.view_carrier_relationships';
export const permCreateCarrierRelationship = 'carrier_relationships.create_carrier_relationships';
export const permRemoveCarrierRelationship = 'carrier_relationships.remove_carrier_relationships';
export const permUpdateQuotes = 'quotes.update_quotes';
export const permViewDispatch = 'carrier.view_driver_relationships';
export const permViewOrders = 'orders.view_orders';
export const permViewInvoice = 'invoicing.view_invoices'; //using this for now may switch to billing.view_invoices once backend figures out which permission they want to use
export const permViewContracts = 'contracts.view_contracts';

// FUNCTIONS

// filter shipment tags
export function filterShipmentTags(tags, selectedTags, searchTerm) {
  let myTags = [];
  if (tags?.length) {
    myTags = tags.filter((el) => {
      let selected = false;
      for (let i = 0; i < selectedTags.length; i++) {
        if (selectedTags[i] === el.id) {
          selected = true;
        }
      }
      return !selected;
    });
  }

  if (searchTerm?.length) {
    myTags = myTags.filter((tag) => {
      return tag.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  return myTags;
}

//formatBytes
export function formatBytes(a) {
  if (0 == a) {
    return '0 Bytes';
  }
  const c = 1000,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(0)) + ' ' + e[f];
}

export function determineToFrom(stop, isCompleted) {
  // check is_dropoff / is_pickup
  let phrase = '';

  if (stop.is_pickup && isCompleted) {
    phrase = 'Picked up from';
  } else if (stop.is_dropoff && isCompleted) {
    phrase = 'Delivered to';
  } else if (stop.is_pickup && !stop.is_dropoff) {
    phrase = 'Picking up from';
  } else if (!stop.is_pickup && stop.is_dropoff) {
    phrase = 'Delivering to';
  } else if (stop.is_pickup && stop.is_dropoff) {
    phrase = 'Pickup / dropoff';
  }

  return phrase;
}

//validatePhoneNumber
export function validatePhoneNumber(number) {
  let parsedNumber;
  if (!number || number.length < 6 || number.length > 17) {
    return false;
  }
  if (number && number.length > 5) {
    try {
      parsedNumber = phoneUtil.parse(number);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  if (number && parsedNumber && number.length > 5 && !phoneUtil.isValidNumber(parsedNumber)) {
    return false;
  }

  return true;
}

// validateEmail: accepts a string or array of emails
export function validateEmail(email) {
  const regexEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailList = [];
  if (!email) {
    return false;
  }
  if (typeof email === 'string') {
    return regexEmailValidation.test(email.trim());
  }
  if (Array.isArray(email)) {
    email.forEach((item) => {
      emailList.push(regexEmailValidation.test(item.trim()));
    });
    return emailList.includes(false) ? false : true;
  }
  if (email.value) {
    return regexEmailValidation.test(email.value.trim());
  }
}

//validateTemperature: accepts a string and returns the value if the value is a minus symbol, a positive or negative number.

export const validateTemperature = (value) => {
  if (!Number.isNaN(Number(value)) || value === '-') {
    return value;
  }
  return '';
};

// locates a URL in a string and returns the URL
// only returns the first complete URL found in the string
// returns null if no URL is found in the string
export const findURL = (string) => {
  const regexp = /\bhttps?:\/\/\S+/gi;

  if (typeof string !== 'string' || !string.match(regexp)) {
    return null;
  }

  return string.match(regexp)[0];
};

// isWhitelabel
export function isWhiteLabel() {
  const hostname = window.location.hostname;
  const sub = getSubdomain();

  if (!sub) {
    return false;
  }
  // check is dev or shipwell site
  if (
    hostname !== 'localhost' &&
    sub !== 'localdev' &&
    sub !== 'shipper' &&
    sub !== 'app' &&
    sub !== 'dev-shippers' &&
    sub !== 'demo-shippers' &&
    sub !== 'carriers' &&
    sub !== 'dev-carriers' &&
    sub !== 'beta-shippers' &&
    sub !== 'dev-app' &&
    sub !== 'demo-app'
  ) {
    return true;
  }
  return false;
}
/**
 * Converts a string of characters to all title case. If the phrase contains underscores they
 * are split apart. IF the phrase contains spaces they are also split apart.
 * @param {string|null|undefined|import('@shipwell/backend-core-sdk').RfpRowResponseEquipmentEnum|import('@shipwell/backend-core-sdk').ProductPackageType} str
 * @returns {string} the string converted to title case or an empty string.
 */
export function toTitleCase(str) {
  if (!str) {
    return '';
  }
  str = str.split('_').join(' ');
  const strTitleCased = str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
  return strTitleCased;
}

export function validateDollarValue(value, maxDecimalPlaces) {
  if (typeof value === 'string') {
    if (value === '0' || value === '0.00' || value === '0.0') {
      return true;
    }
    if (
      !parseFloat(removeCommasAndDollarSign(value)) ||
      /^[0-9,.-]*$/.test(removeCommasAndDollarSign(value)) === false ||
      (value.match(/[.]/g) && value.match(/[.]/g).length > 1)
    ) {
      return false;
    }
    //check decimals
    if (maxDecimalPlaces) {
      return countDecimals(value) <= 2;
    }
    return true;
  }
  return true;
}

export function countDecimals(value) {
  if (Math.floor(value) !== value && value?.toString().split('.').length > 1) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
}

export function validatePercentValue(value) {
  if (typeof value === 'string') {
    if (value === '0' || value === '0.00' || value === '0.0') {
      return true;
    }
    if (
      !parseFloat(value) ||
      /^[0-9,.-]*$/.test(value) === false ||
      (value.match(/[.]/g) && value.match(/[.]/g).length > 1)
    ) {
      return false;
    }
    return true;
  }
  return true;
}

export function validateIntegerValue(value) {
  if (typeof value === 'string') {
    if (!parseInt(removeCommasAndDollarSign(value)) || /^[0-9,]*$/.test(removeCommasAndDollarSign(value)) === false) {
      return false;
    }
    return true;
  }
  if (value % 1 !== 0) {
    return false;
  }
  return true;
}
/**
 * Validate that value is a number
 * @param  {String} value
 * @return Boolean
 */
export function validateNumber(value) {
  return !Number.isNaN(Number(value));
}

// checkIsHoliday
export function checkIsHoliday(date) {
  const _holidays = {
    M: {
      // Month, Day
      '01/01': "New Year's Day",
      '07/04': 'Independence Day',
      '11/11': "Veteran's Day",
      '12/24': 'Christmas Eve',
      '12/25': 'Christmas Day',
      '12/31': "New Year's Eve"
    },
    W: {
      // Month, Week of Month, Day of Week
      '1/3/1': 'Martin Luther King Jr. Day',
      '2/3/1': "Washington's Birthday",
      '5/5/1': 'Memorial Day',
      '9/1/1': 'Labor Day',
      '10/2/1': 'Columbus Day',
      '11/4/4': 'Thanksgiving Day'
    }
  };
  const diff = 1 + (0 | ((new Date(date).getDate() - 1) / 7));
  const memorial = new Date(date).getDay() === 1 && new Date(date).getDate() + 7 > 30 ? '5' : null;

  return (
    _holidays['M'][moment(date).format('MM/DD')] ||
    _holidays['W'][moment(date).format('M/' + (memorial || diff) + '/d')]
  );
}

export function isWeekday(date) {
  if (moment(date).weekday() === 0 || moment(date).weekday() === 6) {
    return false;
  }
  return true;
}

export function formatCurrencyValue(value, digits = 2) {
  if (value && !isNaN(value)) {
    return parseFloat((value * 100) / 100)
      .toFixed(digits)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  return '0.00';
}

// MOMENT
export const formatHrMinSec = 'HH:mm:ss';
export const formatHrMin = 'HH:mm';
//format a date in the format "Sept 20, 2017"
export function formatDate(date) {
  return moment(date).format('MMM D, YYYY');
}
// format time -> 13:20:01 || 13:20
export function formatTime(time, noSec = true, timezone = '', format) {
  if (timezone) {
    if (noSec) {
      return moment.tz(time, timezone).format('HH:mm zz');
    }
    return moment.tz(time, timezone).format('HH:mm:ss zz');
  }
  if (noSec) {
    return moment(time, format).format('HH:mm');
  }
  return moment(time, format).format('HH:mm:ss');
}
// format date time -> Feb 8, 2019 13:20:01 || Feb 8, 2019 13:20
export function formatDateTime(date, noSec = true, timezone = '') {
  if (timezone) {
    if (noSec) {
      return moment.tz(date, timezone).format('MMM D, YYYY HH:mm zz');
    }
    return moment.tz(date, timezone).format('MMM D, YYYY HH:mm:ss zz');
  }
  if (noSec) {
    return moment(date).format('MMM D, YYYY HH:mm');
  }
  return moment(date).format('MMM D, YYYY HH:mm:ss');
}
// format day of week date time -> Fri, Feb 8, 2019 13:20:01 || Fri, Feb 8, 2019 13:20
export function formatDayOfWeekDateTime(date, noSec = true, timezone = '') {
  if (timezone) {
    if (noSec) {
      return moment.tz(date, timezone).format('ddd, MMM D, YYYY HH:mm zz');
    }
    return moment.tz(date, timezone).format('ddd, MMM D, YYYY HH:mm:ss zz');
  }
  if (noSec) {
    return moment(date).format('ddd, MMM D, YYYY HH:mm');
  }
  return moment(date).format('ddd, MMM D, YYYY HH:mm:ss');
}
// format day of week + date -> Wed, Feb 20, 2019
export function formatDayOfWeekDate(date) {
  return moment(date).format('ddd, MMM D, YYYY');
}

export function formatTimeAgo(dateTime) {
  let string = '--';
  if (dateTime && moment(dateTime).isValid) {
    string = moment(dateTime).fromNow();
  }
  return string;
}

/**
 * Format two dates using dates and times, unless the two dates have the same day, in which case the
 * earlier date is formatted once and the hours are formatted as a range.
 *
 * E.g.
 * formatDateTimeRange(
 *   new Date('Sat Jul 31 2020 13:00:00 UTC'),
 *   new Date('Thu Sept 3 2020 15:00:00 UTC')
 *   'America/Chicago'
 * ); // Sat, Jul 31, 2020 08:00 CDT – Thu, Sept 3, 2020 10:00 CDT
 *
 * formatDateTimeRange(
 *   new Date('Sat Jul 31 2020 13:00:00 UTC'),
 *   new Date('Sat Jul 31 2020 15:00:00 UTC'),
 *   'America/Chicago'
 * ); // Sat, Jul 31, 2020 08:00–10:00 CDT
 */
export function formatDateTimeRange(earlier, later, timezone) {
  const fn = timezone ? curryRight(moment.tz, 2)(timezone) : moment;
  const earlierMoment = fn(earlier);
  const laterMoment = fn(later);
  if (earlierMoment.isSame(laterMoment, 'day')) {
    return `${earlierMoment.format('ddd, MMM D, HH:mm')}-${laterMoment.format(`HH:mm${timezone ? ' zz' : ''}`)}`;
  }
  return `${formatDayOfWeekDateTime(earlier, true, timezone)} - ${formatDayOfWeekDateTime(later, true, timezone)}`;
}

export function removeCommasAndDollarSign(value) {
  if (typeof value === 'number') {
    return value;
  }
  let valueParsed = '';
  if (value && typeof value === 'string') {
    valueParsed = value.replace(/,/g, '');
  }
  if (valueParsed && valueParsed.startsWith('$')) {
    valueParsed = valueParsed.slice(1);
  }
  return valueParsed;
}

export function unpackErrors(errors = [], submissionError, arrayFields, flattenArrayObjects = []) {
  if (errors.length) {
    errors.forEach(function (item) {
      if (arrayFields && arrayFields.includes(item.field_name)) {
        submissionError[item.field_name] = [];
        for (var i = 0; i < item.field_errors.length; i++) {
          if (typeof item.field_errors[i] === 'string') {
            submissionError[item.field_name][i] = item.field_errors[i];
          } else if (typeof item.field_errors[i] === 'object' && Object.keys(item.field_errors[i]).length > 0) {
            //construct a string from the object keys if flattenArrayObjects is specified
            //we flatten the array for cases like address fields, where the error has multiple parts
            if (flattenArrayObjects.length > 0 && flattenArrayObjects.includes(item.field_name)) {
              submissionError[item.field_name][i] = '';
              Object.keys(item.field_errors[i]).forEach((key) => {
                const errorText = item.field_errors[i][key].map((e) => e).join(', ');
                submissionError[item.field_name][i] += `Error: ${key.split('_').join(' ')} - ${errorText}`;
              });
            } else {
              //the entire object is used as the error (useful for cases like point_of_contacts)
              submissionError[item.field_name][i] = item.field_errors[i];
            }
          } else {
            submissionError[item.field_name][i] = {};
          }
        }
      } else {
        if (item.field_name.includes('.')) {
          const parentObj = item.field_name.split('.')[0];
          const childObj = item.field_name.split('.')[1];
          if (submissionError[parentObj]) {
            submissionError[parentObj][childObj] = item.field_errors[0];
          } else {
            submissionError[parentObj] = {[childObj]: item.field_errors[0]};
          }
        } else {
          submissionError[item.field_name] = item.field_errors[0];
        }
      }
    });
  }
  return submissionError;
}

export function getIdentifyingCodeErrors(codes, errors) {
  const submissionErrors = [];
  for (let i = 0; i < codes.length; i++) {
    if (errors.MC_NUMBER && codes[i].type === 'MC_NUMBER') {
      submissionErrors[i] = {
        value: errors.MC_NUMBER,
        _error: errors.MC_NUMBER
      };
    } else if (errors.USDOT && codes[i].type === 'USDOT') {
      submissionErrors[i] = {
        value: errors.USDOT,
        _error: errors.USDOT
      };
    } else if (errors.SCAC && codes[i].type === 'SCAC') {
      submissionErrors[i] = {
        value: errors.SCAC,
        _error: errors.SCAC
      };
    } else if (errors.MX_NUMBER && codes[i].type === 'MX_NUMBER') {
      submissionErrors[i] = {
        value: errors.MX_NUMBER,
        _error: errors.MX_NUMBER
      };
    } else if (errors.FF_NUMBER && codes[i].type === 'FF_NUMBER') {
      submissionErrors[i] = {
        value: errors.FF_NUMBER,
        _error: errors.FF_NUMBER
      };
    }
  }
  return submissionErrors;
}

export function loadGoogleMapsAPI(callback) {
  //load google maps
  GoogleMapsLoader.KEY = 'AIzaSyAvG0WUiKwgvOL6jizDOh8_Jby5TPNLLU0';
  GoogleMapsLoader.LIBRARIES = ['places'];
  GoogleMapsLoader.VERSION = 'weekly';
  GoogleMapsLoader.load(() => {});
  GoogleMapsLoader.onLoad(function () {
    if (callback) {
      callback();
    }
  });
}

//generate a random 6-digit alphanumeric string for use in the customer_reference_id when creating new shipment
export function generateRandomId() {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (var i = 6; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
}

//calculate freight class for a line item
export function calculateFreightClass(
  length,
  width,
  height,
  sizeunits = 'IN',
  weight,
  weightunits = 'LB',
  handlingUnits
) {
  const density = calculateDensity(length, width, height, sizeunits, weight, weightunits, handlingUnits);

  if (density < 1) {
    return 400;
  }
  if (density < 2) {
    return 300;
  }
  if (density < 4) {
    return 250;
  }
  if (density < 6) {
    return 175;
  }
  if (density < 8) {
    return 125;
  }
  if (density < 10) {
    return 100;
  }
  if (density < 12) {
    return 92.5;
  }
  if (density < 15) {
    return 85;
  }
  if (density < 22.5) {
    return 70;
  }
  if (density < 30) {
    return 65;
  }
  if (density >= 30) {
    return 60;
  }
}

//calculate density for a line item
export function calculateDensity(length, width, height, sizeunits = 'IN', weight, weightunits = 'LB') {
  const CUBIC_INCHES_TO_CUBIC_FEET_RATIO = 1728;
  const CUBIC_CENTIMETERS_TO_CUBIC_FEET_RATIO = 28316.8;
  const KILOGRAMS_TO_POUNDS_RATIO = 2.20462;
  const PRECISION = 10000; // 0.0001

  let totalVolume = 0;
  let totalWeight = 0;
  if (sizeunits === 'IN' || '') {
    totalVolume = parseFloat(length * width * height) / CUBIC_INCHES_TO_CUBIC_FEET_RATIO;
  } else if (sizeunits === 'CM') {
    totalVolume = parseFloat(length * width * height) / CUBIC_CENTIMETERS_TO_CUBIC_FEET_RATIO;
  }
  if (weightunits === 'LB' || '') {
    totalWeight = parseFloat(removeCommasAndDollarSign(weight));
  } else if (weightunits === 'KG') {
    totalWeight = parseFloat(removeCommasAndDollarSign(weight) * KILOGRAMS_TO_POUNDS_RATIO);
  }

  // in lb/cu.ft.
  const density = totalWeight / totalVolume;
  return Math.ceil(density * PRECISION) / PRECISION;
}

//calculate weight per unit
export function calculateWeightPerUnit(package_weight, weight_unit = 'LB', total_packages) {
  if (weight_unit === 'LB') {
    return parseFloat(removeCommasAndDollarSign(package_weight) / total_packages);
  }
  return parseFloat((removeCommasAndDollarSign(package_weight) * 2.20462) / total_packages);
}

const workingAccessorialCodes = [
  'AIRDEL',
  'AIRPU',
  'APPTDEL',
  'APPTPU',
  'CAMPDEL',
  'CAMPPU',
  'CFSDEL',
  'CFSPU',
  'CHRCDEL',
  'CHRCPU',
  'CLUBDEL',
  'CLUBPU',
  'CNVDEL',
  'CNVPU',
  'CONDEL',
  'CONPU',
  'CROSSDEL',
  'CROSSPU',
  'DCDEL',
  'DOCKDEL',
  'DOCKPU',
  'EDUDEL',
  'EDUPU',
  'FARMDEL',
  'FARMPU',
  'GOVDEL',
  'GOVPU',
  'GRODEL',
  'GROPU',
  'HDAYDEL',
  'HDAYPU',
  'HOSDEL',
  'HOSPU',
  'HOTLDEL',
  'HOTLPU',
  'INDEL',
  'INEDEL',
  'INNEDEL',
  'INPU',
  'LGDEL',
  'LGPU',
  'LTDDEL',
  'LTDPU',
  'MALLDEL',
  'MILDEL',
  'MILPU',
  'MINEDEL',
  'MINEPU',
  'NARDEL',
  'NARPU',
  'NBDEL',
  'NBPU',
  'NCDEL',
  'NOTIFY',
  'NURSDEL',
  'NURSPU',
  'PARKDEL',
  'PARKPU',
  'PIERDEL',
  'PIERPU',
  'PRISDEL',
  'PRISPU',
  'RESDEL',
  'RESPU',
  'ROOMOFCHOICE',
  'RSRTDEL',
  'SATDEL',
  'SATPU',
  'SORTDEL',
  'SORTPU',
  'SSTORDEL',
  'SSTORPU',
  'SUNDEL',
  'SUNPU',
  'TIPU',
  'TIPUPKG',
  'TRANSLOADDEL',
  'TRANSLOADPU',
  'UNLOADDEL',
  'UTLDEL',
  'UTLPU',
  'WEDEL',
  'WHITEGLOVE'
];

export function filterAccessorials(accessorials) {
  accessorials = accessorials || [];
  //filter down to pickup, dropoff, and shipment level accessorials. shipment-level is a specific list of acc that we know work
  const accessorialObj = {};
  const drayageAccessorialPickupIds = new Set([113, 253, 258, 256, 135, 232, 125, 259, 260]);
  const drayageAccessorialDropoffIds = new Set([261, 262, 263, 264, 257, 125]);
  const drayageAccessorialShipmentIds = new Set([162, 205, 206, 207, 265, 127, 266, 267, 228, 197, 179, 299, 300]);
  const saturdayDeliveryAccessorialId = 87;

  accessorialObj.drayagePickup = accessorials.filter((accessorial) => drayageAccessorialPickupIds.has(accessorial.id));
  accessorialObj.drayageDropoff = accessorials.filter((accessorial) =>
    drayageAccessorialDropoffIds.has(accessorial.id)
  );
  accessorialObj.drayageShipment = accessorials.filter((accessorial) =>
    drayageAccessorialShipmentIds.has(accessorial.id)
  );

  accessorialObj.parcelShipment = accessorials.filter((a) => a?.id === saturdayDeliveryAccessorialId);

  accessorialObj.pickup = accessorials.filter((e) => e.type === 'pickup' && workingAccessorialCodes.includes(e.code));
  accessorialObj.dropoff = accessorials.filter(
    (e) => (e.type === 'dropoff' || e.type === 'inside-delivery') && workingAccessorialCodes.includes(e.code)
  );
  accessorialObj.pickupDropoff = accessorials.filter(
    (e) =>
      (e.type === 'dropoff' || e.type === 'pickup' || e.type === 'inside-delivery') &&
      workingAccessorialCodes.includes(e.code)
  );
  accessorialObj.shipment = accessorials.filter(
    (item) =>
      item.type !== 'dropoff' &&
      item.type !== 'pickup' &&
      (item.id === 176 ||
        item.id === 104 ||
        item.id === 121 ||
        item.id === 153 ||
        item.id === 163 ||
        item.id === 178 ||
        item.id === 179 ||
        item.id === 201 ||
        item.id === 205 ||
        item.id === 209 ||
        item.id === 225 ||
        item.id === 67 ||
        item.id === 69 ||
        item.id === 70 ||
        item.id === 68 ||
        item.id === 113 ||
        item.id === 197 ||
        item.id === 253 ||
        item.id === 254 ||
        item.id === 255 ||
        item.id === 256 ||
        item.id === 257 ||
        item.id === 228 ||
        item.id === 206 ||
        item.id === 258 ||
        item.id === 268 ||
        item.id === 232 ||
        item.id === 299 ||
        item.id === 300 ||
        (item.id >= 273 && item.id <= 295))
  );

  accessorialObj.dropoff = accessorialObj.dropoff.sort((a, b) => {
    return a.description < b.description ? -1 : 1;
  });

  return accessorialObj;
}

export function filterStopAccessorials(stop) {
  const isPickup = stop.is_pickup;
  const isDropoff = stop.is_dropoff;
  let accessorialsToAdd = [];
  if (!isDropoff && isPickup) {
    accessorialsToAdd = filterAccessorials(stop.accessorials).pickup;
  } else if (isDropoff && !isPickup) {
    accessorialsToAdd = filterAccessorials(stop.accessorials).dropoff;
  } else if (isDropoff && isPickup) {
    accessorialsToAdd = filterAccessorials(stop.accessorials).pickupDropoff;
  } else if (!isDropoff && !isPickup) {
    accessorialsToAdd = [];
  }
  return accessorialsToAdd;
}

/**
 * format addressToUse for dispatching address changes on quote/shipment/load forms
 *
 * @param {object} entry
 * @param {number} stopIndex
 * @param {number} stopsLength
 * @param {object} formProps
 * @param {object} accessorials
 * @returns {object}
 */
export function formatAddressChange(entry, stopIndex, stopsLength, formProps, accessorials) {
  //construct the address to use from whats in the form already and the address book details
  let addressToUse = {};
  stopsLength = stopsLength - 1;
  if (formProps.values.stops && formProps.values.stops[stopIndex]) {
    addressToUse = JSON.parse(JSON.stringify(formProps.values.stops[stopIndex]));
  }
  addressToUse.location = entry.address;
  addressToUse.company = entry.company_name;
  addressToUse.planned_time_window_start = moment(entry.dock_hours_start, 'HH:mm:ss').toDate();
  addressToUse.planned_time_window_end = moment(entry.dock_hours_end, 'HH:mm:ss').toDate();
  //determine email notification settings here
  if (entry.point_of_contacts && entry.point_of_contacts.length > 0) {
    addressToUse.point_of_contacts = entry.point_of_contacts;
  }
  addressToUse.instructions = entry.notes;
  addressToUse.location_type = entry.location_type.id;
  addressToUse.accessorials = [];
  const whichStop = stopIndex === 0 ? 'pickup' : 'dropoff';
  //get accessorials for the new location type
  let accessorialsToAdd = selectStopAccessorials(
    addressToUse.location_type.toString(),
    whichStop,
    accessorials,
    formProps.values.stops[stopIndex].accessorials
  );
  // add unique accessorials saved in addressbook
  if (entry.accessorials && entry.accessorials.length > 0) {
    accessorialsToAdd = accessorialsToAdd.concat(entry.accessorials);
    accessorialsToAdd = uniqWith(accessorialsToAdd, isEqual);
    // filter invalid pickup/dropoff accessorials by stopIndex
    if (stopIndex === 0) {
      accessorialsToAdd = filterAccessorials(accessorialsToAdd).pickup;
    } else if (stopIndex === stopsLength) {
      accessorialsToAdd = filterAccessorials(accessorialsToAdd).dropoff;
    } else {
      // middle stops, check for is_pickup / is_dropoff
      const stop = {};
      stop.is_pickup = formProps.values.stops[stopIndex].is_pickup;
      stop.is_dropoff = formProps.values.stops[stopIndex].is_dropoff;
      stop.accessorials = accessorialsToAdd;
      accessorialsToAdd = filterStopAccessorials(stop);
    }
  }

  for (let i = 0; i < accessorialsToAdd.length; i++) {
    if (accessorialsToAdd[i] === null || typeof accessorialsToAdd[i] === 'undefined') {
      accessorialsToAdd.splice(i, 1);
    }
  }
  if (accessorialsToAdd.length > 0) {
    addressToUse.accessorials = accessorialsToAdd;
  }

  return addressToUse;
}

export function isLocalhost() {
  const hostname = window.location.hostname;
  let isLocal = false;
  if (hostname === 'localhost' || hostname === 'localdev.shipwell.com') {
    isLocal = true;
  }
  return isLocal;
}

/**
 * Scroll to first field error in form
 */
export function scrollToFirstErrorField(errors) {
  setTimeout(() => {
    let errorsToShow = getErrorFieldNames(errors);
    errorsToShow = uniq(errorsToShow);
    const fieldErrors = errorsToShow.filter((e) => e !== '_error');
    const doc = document.body.getBoundingClientRect();
    const firstError = document.querySelector('.form-group.has-error');
    if (firstError) {
      const elem = firstError.getBoundingClientRect();
      window.scrollTo(0, elem.y - doc.y - 65, {behavior: 'smooth'});
    }
    if (fieldErrors.length && !firstError) {
      if (window.FS && window.FS.log) {
        //use fullstory silent logging
        window.FS.log('Rollbar since there must be an error not mapped to a field');
      }
      if (window.Rollbar) {
        window.Rollbar.error('Uncaught Form Error', {fieldErrors});
      }
    }
  }, 40);
}

export function getErrorFieldNames(obj, name = '', errorArr = []) {
  if (obj) {
    errorArr.push(
      Object.keys(obj)
        .map((key) => {
          const next = obj[key];
          if (next && next._error) {
            //this is a parent-level error
            errorArr.push(name + key);
          } else if (next) {
            if (typeof next === 'string') {
              return name + key;
            }
            // Keep looking
            if (Array.isArray(next)) {
              if (next.filter((e) => typeof e === 'string').length === next.length) {
                return name + key;
              }
              errorArr.push(
                next
                  .map((item, index) => getErrorFieldNames(item, `${name}${key}[${index}].`, errorArr))
                  .filter((o) => o)
              );
            } else if (typeof next === 'object' && Object.keys(next).length !== 0) {
              getErrorFieldNames(next, `${name}${key}.`, errorArr);
            }
          }
          return null;
        })
        .filter((o) => o)
    );
  }
  return flatten(errorArr);
}

function flatten(arr) {
  return arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);
}

export function selectStopAccessorials(location_type, whichStop, allAccessorials, currentList) {
  //default to empty list for now, unsure if we want to use existing acc
  const accessorialList = [];
  allAccessorials = filterAccessorials(allAccessorials);
  if (allAccessorials) {
    //TODO if the current list already contains the id that we received, return no additional accessorials
    //need to use manual checking here to know which ID is matched with which accessorial, since the lists arent in sync
    switch (location_type) {
      case '1':
        //Business with dock or forklift -- add nothing
        break;
      case '2':
        //Business without dock or forklift
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Liftgate Pickup')[0]);
        } else {
          accessorialList.push(allAccessorials.dropoff.filter((acc) => acc.description === 'Liftgate Delivery')[0]);
        }
        break;
      case '3':
        //Residence/home business
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Residential Pickup')[0]);
        } else {
          accessorialList.push(allAccessorials.dropoff.filter((acc) => acc.description === 'Residential Delivery')[0]);
        }
        break;
      case '4':
        //commercial farm/ranch
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '5':
        //self/mini storage
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '6':
        //"Convention/Tradeshow Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(
            allAccessorials.pickup.filter((acc) => acc.description === 'Convention/Tradeshow Pickup')[0]
          );
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Convention/Tradeshow Delivery')[0]
          );
        }
        break;
      case '7':
        //"Construction Site Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '8':
        //"School Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '9':
        //"Government Site Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '10':
        //"Church Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '11':
        //"Hotel Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '12':
        //"Military Installation Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '13':
        //"Airport Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      case '14':
        //"Limited Access Pickup"
        if (whichStop === 'pickup') {
          accessorialList.push(allAccessorials.pickup.filter((acc) => acc.description === 'Limited Access Pickup')[0]);
        } else {
          accessorialList.push(
            allAccessorials.dropoff.filter((acc) => acc.description === 'Limited Access Delivery')[0]
          );
        }
        break;
      default:
        // return empty accessorialList
        break;
    }
  }
  return accessorialList;
}

// transform location_type to object
export function transformLocationType(locationType, locationTypes) {
  // if integer, map to object
  if (parseInt(locationType, 10) && locationTypes) {
    locationType = locationTypes.find((type) => type.id === parseInt(locationType, 10));
  }
  return locationType;
}

//selects the right location type object based on the id in the field from a form
export function transformLocationAppointmentTypes(stops, locationTypes, appointmentTypes) {
  if (!stops?.length) {
    return stops;
  }
  return stops.map((stop) => {
    let locationType = stop.location_type?.id ? stop.location_type : null;
    let appointmentType = stop.appointment_type?.id ? stop.appointment_type : null;

    if (stop.location_type && !stop.location_type.id) {
      locationType = locationTypes.find((e) => e.id == stop.location_type);
    }
    if (!stop.location?.facility_id && stop.appointment_type && !stop.appointment_type.id) {
      // this is the legacy workflow and only allowed when a location does not have a facility_id which belongs
      // to the new dock scheduling service that utilizes the `facility_id` as an identifier determining eligability
      // for first come, first serve or by appointment, appointments.
      appointmentType = appointmentTypes.find((e) => e.id == stop.appointment_type);
    }

    return {
      ...stop,
      location_type: locationType,
      appointment_type: appointmentType
    };
  });
}

//selects the right location type object based on the id in the field from a form
export function transformEquipmentType(equipment_type, equipmentTypes) {
  equipment_type = equipmentTypes.find((e) => e.id === equipment_type);
  return equipment_type;
}

//selects the right location type object based on the id in the field from a form
export function transformShipmentMode(mode, modes) {
  mode = modes.find((e) => e.id === mode);
  return mode;
}

//Converts a shipment object into a nice format for use in redux forms
export function transformShipmentToForm(shipment, hazmatCodes, mode, equipment_types, isConfirm, isNewShipmentForm) {
  const transformedShipment = {};
  transformedShipment.metadata = shipment.metadata;
  transformedShipment.accessorials = shipment.accessorials;
  transformedShipment.id = shipment.id;
  //todo? need to get null strings out of here
  transformedShipment.line_items = shipment.line_items;
  transformedShipment.total_weight_override = shipment.total_weight_override;
  transformedShipment.total_declared_value = shipment.total_declared_value;
  transformedShipment.total_declared_value_currency = shipment.total_declared_value_currency;
  transformedShipment.total_quantity_override = shipment.total_quantity_override;
  transformedShipment.service_level = shipment.service_level?.id;
  transformedShipment.preferred_currency = shipment.preferred_currency;

  //get the hazmat details here
  for (var l = 0; l < transformedShipment.line_items.length; l++) {
    if (!isNil(transformedShipment.line_items[l].hazmat_identification_number)) {
      transformedShipment.line_items[l].hazmat = true;
      if (hazmatCodes) {
        const match = hazmatCodes.filter(
          (e) => e.identification_number == transformedShipment.line_items[l].hazmat_identification_number
        );
        if (match.length > 0) {
          transformedShipment.line_items[l].hazmatDetails = match[0].id;
        } else {
          transformedShipment.line_items[l].hazmatDetails = {
            hazmat_identification_number: transformedShipment.line_items[l].hazmat_identification_number,
            hazmat_hazard_class: transformedShipment.line_items[l].hazmat_hazard_class,
            hazmat_packing_group: transformedShipment.line_items[l].hazmat_packing_group,
            hazmat_proper_shipping_name: transformedShipment.line_items[l].hazmat_proper_shipping_name
          };
        }
      }
    }
  }

  //for masquerading, make sure the book_as_customer is set
  if (shipment.customer && shipment.customer.id) {
    transformedShipment.book_as_customer = {
      label: shipment.customer.name,
      value: shipment.customer.id
    };
  }

  transformedShipment.reference_id = shipment.reference_id;
  transformedShipment.customer_reference_number = shipment.customer_reference_number;
  if (shipment.additional_bol_recipients) {
    transformedShipment.additional_bol_recipients = shipment.additional_bol_recipients.map((email) => {
      return {value: email, label: email};
    });
  }

  transformedShipment.stops = JSON.parse(JSON.stringify(shipment.stops));

  for (var i = 0; i < transformedShipment.stops.length; i++) {
    if (transformedShipment.stops[i].location && transformedShipment.stops[i].location.location_type) {
      transformedShipment.stops[i].location_type = transformedShipment.stops[i].location.location_type.id;
    }
    if (!transformedShipment.stops[i].location?.facility_id) {
      // this use case is for dock scheduling and appointments for shipments. The dock scheduling service
      // expects this to be null to be able to identify if the shipment is eligible for first come, first serve
      // versus by appointment.
      transformedShipment.stops[i].appointment_type = null;
    } else if (transformedShipment.stops[i].appointment_type && !transformedShipment.stops[i].appointment_type.id) {
      // legacy workflow for using the AddressBookEntry
      transformedShipment.stops[i].appointment_type = transformedShipment.stops[i].appointment_type.id;
    }
    if (
      transformedShipment.stops[i].location.point_of_contacts &&
      transformedShipment.stops[i].location.point_of_contacts.length > 0
    ) {
      transformedShipment.stops[i].point_of_contacts = transformedShipment.stops[i].location.point_of_contacts;
      //check what select_all should be
      transformedShipment.stops[i].point_of_contacts.forEach((poc) => {
        if (poc.preferences) {
          const copy = JSON.parse(JSON.stringify(poc.preferences));
          delete copy.receive_bol_on_shipment_booked;
          if (Object.values(copy).filter((e) => e === true).length === Object.keys(copy).length) {
            poc.preferences.select_all = false;
          } else {
            poc.preferences.select_all = false;
          }
        }
      });
    } else {
      if (isConfirm) {
        transformedShipment.stops[i].point_of_contacts = [
          {
            preferences: {
              select_all: false,
              shipment_booked: false,
              picked_up: false,
              eta_changed: false,
              delayed: false,
              cancellation: false,
              delivered: false
            }
          }
        ];
      } else {
        transformedShipment.stops[i].point_of_contacts = [];
      }
    }
    if (transformedShipment.stops[i].location.company_name) {
      transformedShipment.stops[i].company = transformedShipment.stops[i].location.company_name;
    }
    //next line wont be necessary if date is delivered correctly from backend
    if (transformedShipment.stops[i].planned_date) {
      transformedShipment.stops[i].planned_date = moment(transformedShipment.stops[i].planned_date).isValid()
        ? moment(transformedShipment.stops[i].planned_date).format('MM/DD/YYYY')
        : null;
      //if this date is before today, set it to today (can happen on clone)
      if (moment(transformedShipment.stops[i].planned_date).isBefore(moment(), 'day')) {
        transformedShipment.stops[i].planned_date = moment().format('MM/DD/YYYY');
      }
    }
    //temp set toggle to 1 for date
    transformedShipment.stops[i]['schedule-select'] = '1';
    if (transformedShipment.stops[i].planned_time_window_start) {
      if (transformedShipment.stops[i].planned_time_window_end) {
        //if these are equal, it is pickup at a specific time
        if (
          transformedShipment.stops[i].planned_time_window_end ===
          transformedShipment.stops[i].planned_time_window_start
        ) {
          transformedShipment.stops[i]['schedule-select'] = '1';
        } else {
          //this must be a range
          transformedShipment.stops[i]['schedule-select'] = '2';
        }
      } else {
        //just the start, arrive after
        transformedShipment.stops[i]['schedule-select'] = '4';
        transformedShipment.stops[i].planned_time_window_end = '18:00:00';
      }
    } else if (transformedShipment.stops[i].planned_time_window_end) {
      //must be arrive before
      transformedShipment.stops[i]['schedule-select'] = '3';
    } else {
      transformedShipment.stops[i].planned_time_window_start = '08:00:00';
      transformedShipment.stops[i].planned_time_window_end = '18:00:00';
    }
    if (moment(transformedShipment.stops[i].planned_time_window_start).isValid()) {
      transformedShipment.stops[i].planned_time_window_start = moment(
        transformedShipment.stops[i].planned_time_window_start,
        'HH:mm:ss'
      ).toDate();
    }
    if (moment(transformedShipment.stops[i].planned_time_window_end).isValid()) {
      transformedShipment.stops[i].planned_time_window_end = moment(
        transformedShipment.stops[i].planned_time_window_end,
        'HH:mm:ss'
      ).toDate();
    }
    //default to one point of contact if not in shipment

    transformedShipment.stops[i].rkey = i + 1;
  }
  if (mode) {
    transformedShipment.mode = mode;
  }
  if (equipment_types) {
    transformedShipment.equipment_types = equipment_types;
  }
  transformedShipment.temperature_lower_limit = shipment.temperature_lower_limit;
  transformedShipment.temperature_upper_limit = shipment.temperature_upper_limit;
  transformedShipment.temperature_unit = shipment.temperature_unit;

  if (shipment.metadata && shipment.metadata.bill_to_override) {
    transformedShipment.bill_to_override = shipment.metadata.bill_to_override;
    transformedShipment.bill_to_override.company_address = {
      formatted_address: shipment.metadata.bill_to_override.company_address
    };
  }

  //add references and instructions
  transformedShipment.name = shipment.name;
  transformedShipment.notes_for_carrier = shipment.notes_for_carrier;
  transformedShipment.purchase_order_number = shipment.purchase_order_number;
  //special actions when on new shipment form and cloning
  if (isNewShipmentForm === true) {
    if (shipment.equipment_type) {
      transformedShipment.equipment_type = shipment.equipment_type;
    }
    if (transformedShipment.equipment_types && transformedShipment.equipment_types.length > 0) {
      transformedShipment.equipment_type = transformedShipment.equipment_types[0].id;
    }
    if (shipment.carrier_assignment) {
      //get carrier assignment, then use this on shipment page to find relationship.
      transformedShipment.carrier_assignment = shipment.carrier_assignment;
    }
    if (shipment.most_recently_awarded_quote) {
      //get financials
      let fins = shipment.most_recently_awarded_quote.charge_line_items;
      const markup = fins.filter((e) => e.is_provider_markup === true);
      fins = fins.filter((e) => e.is_provider_markup === false);
      for (var j = 0; j < fins.length; j++) {
        delete fins[j].id;
      }
      transformedShipment.financials = fins;
      //delete the IDs out of here

      if (markup && markup.length > 0) {
        transformedShipment.markup = markup[0].amount.toString();
      } else if (shipment.most_recently_awarded_quote.customer_markup) {
        transformedShipment.markup = shipment.most_recently_awarded_quote.customer_markup.toString();
      }
      transformedShipment.service_level =
        shipment.most_recently_awarded_quote.service_level && shipment.most_recently_awarded_quote.service_level.id;
    }
  }

  if (shipment.fedex_specific_options) {
    transformedShipment.fedex_specific_options = shipment.fedex_specific_options;
  }
  if (shipment.ups_specific_options) {
    transformedShipment.ups_specific_options = shipment.ups_specific_options;
  }
  if (shipment.usps_specific_options) {
    transformedShipment.usps_specific_options = shipment.usps_specific_options;
  }
  if (shipment.shipment_pickup) {
    transformedShipment.shipment_pickup = shipment.shipment_pickup;
  }
  if (shipment.custom_data) {
    transformedShipment.custom_data = shipment.custom_data;
  }

  return transformedShipment;
}

export function hasPlanningWindownFlag(featureFlags) {
  if (!featureFlags) {
    return false;
  }

  return featureFlags.frontendPlannedWindows;
}

//construct a shipment object for sending in API calls from the form fields
export function constructShipmentObject(attrs, featureFlags = {}) {
  const shipmentObj = {};
  shipmentObj.accessorials = attrs.accessorials || [];
  //TODO: auto generate ID or allow user input
  shipmentObj.customer_reference_number = attrs.customer_reference_number;
  //LINE ITEMS
  shipmentObj.line_items = attrs.line_items;
  if (shipmentObj.line_items) {
    for (const [i, lineItem] of shipmentObj.line_items.entries()) {
      shipmentObj.line_items[i].package_weight = removeCommasAndDollarSign(lineItem.package_weight);
      if (lineItem.hazmat) {
        const hazmatDetails = lineItem.hazmatDetails || {};
        shipmentObj.line_items[i].hazmat_hazard_class =
          hazmatDetails.hazard_class || hazmatDetails.hazmat_hazard_class || lineItem.hazmat_hazard_class || null;
        shipmentObj.line_items[i].hazmat_identification_number =
          hazmatDetails.identification_number ||
          hazmatDetails.hazmat_identification_number ||
          lineItem.hazmat_identification_number ||
          null;
        shipmentObj.line_items[i].hazmat_packing_group =
          hazmatDetails.packing_group || hazmatDetails.hazmat_packing_group || lineItem.hazmat_packing_group || null;
        shipmentObj.line_items[i].hazmat_proper_shipping_name =
          hazmatDetails.proper_shipping_name ||
          hazmatDetails.hazmat_proper_shipping_name ||
          lineItem.hazmat_proper_shipping_name ||
          null;
      } else {
        shipmentObj.line_items[i].hazmat_hazard_class = null;
        shipmentObj.line_items[i].hazmat_identification_number = null;
        shipmentObj.line_items[i].hazmat_packing_group = null;
        shipmentObj.line_items[i].hazmat_proper_shipping_name = null;
      }
      if (shipmentObj.line_items[i].nmfc_item_code === '') {
        shipmentObj.line_items[i].nmfc_item_code = null;
      }
      if (shipmentObj.line_items[i].nmfc_sub_code === '') {
        shipmentObj.line_items[i].nmfc_sub_code = null;
      }
      if (shipmentObj.line_items[i].total_pieces === '') {
        shipmentObj.line_items[i].total_pieces = null;
      }
      if (shipmentObj.line_items[i].package_type === '') {
        shipmentObj.line_items[i].package_type = null;
      }
      if (shipmentObj.line_items[i].freight_class === '') {
        shipmentObj.line_items[i].freight_class = null;
      }
    }
  }
  let isFTL = false;
  if (!shipmentObj.line_items || shipmentObj.line_items.length === 0) {
    shipmentObj.line_items = [];
    if (attrs.mode && Array.isArray(attrs.mode) && attrs.mode.filter((e) => e.id === 1).length > 0) {
      isFTL = true;
      if (attrs.ftl_estimated_weight && attrs.ftl_description) {
        const parsedWeight = removeCommasAndDollarSign(attrs.ftl_estimated_weight);
        //this is an FTL shipment, and we should fill in the details of one line item from the ftl totals
        shipmentObj.line_items.push({
          description: attrs.ftl_description,
          package_weight: parseFloat(removeCommasAndDollarSign(parsedWeight.trim())),
          weight_unit: attrs.ftl_estimated_weight_unit
        });
      }
    } else if (attrs.mode && !Array.isArray(attrs.mode) && (attrs.mode === '1' || attrs.mode.id == 1)) {
      isFTL = true;
      if (attrs.ftl_estimated_weight) {
        const parsedWeight = removeCommasAndDollarSign(attrs.ftl_estimated_weight);
        //this is an FTL shipment, and we should fill in the details of one line item from the ftl totals
        shipmentObj.line_items.push({
          description: attrs.ftl_description,
          package_weight: parseFloat(removeCommasAndDollarSign(parsedWeight.trim())),
          weight_unit: attrs.ftl_estimated_weight_unit
        });
      }
    }
  } else {
    if (
      (attrs.mode && !Array.isArray(attrs.mode) && (attrs.mode === '1' || attrs.mode.id == 1)) ||
      (attrs.mode && Array.isArray(attrs.mode) && attrs.mode.filter((e) => e.id === 1).length > 0)
    ) {
      isFTL = true;
    }
  }
  if (attrs.total_linear_feet) {
    shipmentObj.total_linear_feet = attrs.total_linear_feet;
  }
  //STOPS
  shipmentObj.stops = [];

  if (attrs.drayage_estimated_arrival_date) {
    shipmentObj.drayage_estimated_arrival_date = moment(attrs.drayage_estimated_arrival_date).isValid()
      ? moment(attrs.drayage_estimated_arrival_date).format('YYYY-MM-DD')
      : null;
  }
  if (attrs.drayage_release_date) {
    shipmentObj.drayage_release_date = moment(attrs.drayage_release_date).isValid()
      ? moment(attrs.drayage_release_date).format('YYYY-MM-DD')
      : null;
  }
  if (attrs.drayage_last_free_date) {
    shipmentObj.drayage_last_free_date = moment(attrs.drayage_last_free_date).isValid()
      ? moment(attrs.drayage_last_free_date).format('YYYY-MM-DD')
      : null;
  }
  if (attrs.drayage_container_return_date) {
    shipmentObj.drayage_container_return_date = moment(attrs.drayage_container_return_date).isValid()
      ? moment(attrs.drayage_container_return_date).format('YYYY-MM-DD')
      : null;
  }

  //loop through stops
  if (attrs.stops) {
    for (const [j, stop] of attrs.stops.entries()) {
      const stopObj = {
        planning_window: {
          start: '',
          end: ''
        }
      };
      if (stop.id) {
        stopObj.id = stop.id;
      }
      stopObj.accessorials = stop.accessorials || [];
      if (isFTL && !hasPlanningWindownFlag(featureFlags)) {
        //for FTL, there are dates on all stops
        if (stop.planned_date) {
          stopObj.planned_date = moment(stop.planned_date).isValid()
            ? moment(stop.planned_date).format('YYYY-MM-DD')
            : null;
        } else if (j === 0) {
          stopObj.planned_date = moment(stop.planned_date).isValid() ? moment().format('YYYY-MM-DD') : null;
        } else {
          stopObj.planned_date = moment().add(2, 'days').format('YYYY-MM-DD');
        }
      } else if (!hasPlanningWindownFlag(featureFlags)) {
        if (stop.planned_date) {
          stopObj.planned_date = moment(stop.planned_date).isValid()
            ? moment(stop.planned_date).format('YYYY-MM-DD')
            : null;
        } else if (j === 0) {
          stopObj.planned_date = moment().format('YYYY-MM-DD');
        }
      }
      stopObj.ordinal_index = getNil(attrs, `stops.${j}.ordinal_index`, j);

      // allow middle stops to be pickup or delivery
      if (j === 0) {
        stopObj.is_pickup = true;
        stopObj.is_dropoff = false;
      } else if (j === attrs.stops.length - 1) {
        stopObj.is_pickup = false;
        stopObj.is_dropoff = true;
      } else {
        stopObj.is_pickup = stop.is_pickup;
        stopObj.is_dropoff = stop.is_dropoff;
      }

      stopObj.location = {};

      // send the address book ID forward to retrieve address book information in later flows
      stopObj.location.created_using_address_book_entry_id = attrs.stops[j].location.addressBookId;
      stopObj.location.facility_id = attrs.stops[j].location?.facility_id;

      if (attrs.additional_bol_recipients) {
        shipmentObj.additional_bol_recipients = attrs.additional_bol_recipients.map((e) => e.value);
      }
      if (stop.location?.company_name) {
        stopObj.location.company_name = stop.location.company_name;
      }
      if (stop.location) {
        if (stop.location.address?.formatted_address) {
          stopObj.location.address = stop.location.address;
        }
        stopObj.location.location_name =
          stop.location.location_name ||
          stopObj.location?.company_name ||
          stopObj.location?.address?.formatted_address ||
          '';

        if (stop.appointment_type) {
          stopObj.appointment_type = stop.appointment_type;
        }
        stopObj['schedule-select'] = stop['schedule-select'];
        stopObj.planned_time_window_start = stop.planned_time_window_start;
        stopObj.planned_time_window_end = stop.planned_time_window_end;

        //location types  - default to business (1)
        stopObj.location.location_type = stop.location.location_type || {
          id: 1,
          name: 'Business (with dock or forklift)'
        };
      }
      const {hasLTL, hasVLTL, hasDrayage} = checkShipmentModes(attrs.mode);
      if (hasPlanningWindownFlag(featureFlags)) {
        const timezone = stop?.location?.address?.address?.timezone || moment.tz.guess();
        if (isFTL || hasLTL || hasVLTL || hasDrayage) {
          stopObj.planning_window = applyPlanningWindowRules(stop, stop['schedule-select'], j, timezone);
        } else {
          stopObj.planning_window = {
            start: stop.planning_window?.start || createStopIndexDate(j, 8, timezone),
            end: stop.planning_window?.end || createStopIndexDate(j, 18, timezone)
          };
        }
        delete stopObj.planned_date;
        delete stopObj.planned_time_window_end;
        delete stopObj.planned_time_window_start;
      } else {
        if (isFTL || hasLTL || hasVLTL || hasDrayage) {
          //special handling for windows on FTL & LTL
          if (stop['schedule-select'] === '1' || stop['schedule-select'] === 1) {
            if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
              stopObj.planned_time_window_start = '8:00';
              stopObj.planned_time_window_end = stopObj.planned_time_window_start;
            } else {
              //use the same time for both fields if Arrive at Time
              stopObj.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
                ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
                : moment(stop.planned_time_window_start).isValid()
                ? moment(stop.planned_time_window_start).format('HH:mm:ss')
                : null;
              stopObj.planned_time_window_end = stopObj.planned_time_window_start;
            }
          } else if (
            stop['schedule-select'] === '2' ||
            stop['schedule-select'] === 2 ||
            //if this is undefined, we show the same fields that we do for open, so the presumption is that the same data
            //cleaning that we do for open stop can also be applied if the user does not select a 'Scheduled For Time'
            !stop['schedule-select']
          ) {
            //if planned time window start is falsy or the string 'null', use the default 08:00 value
            if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
              stopObj.planned_time_window_start = '8:00';
            } else {
              stopObj.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
                ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
                : moment(stop.planned_time_window_start).isValid()
                ? moment(stop.planned_time_window_start).format('HH:mm:ss')
                : null;
            }

            if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
              stopObj.planned_time_window_end = '18:00';
            } else {
              stopObj.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
                ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
                : moment(stop.planned_time_window_end).isValid()
                ? moment(stop.planned_time_window_end).format('HH:mm:ss')
                : null;
            }
          } else if (stop['schedule-select'] === '3' || stop['schedule-select'] === 3) {
            if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
              stopObj.planned_time_window_end = '18:00';
            } else {
              //just use the end time for Arrive Before Time
              stopObj.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
                ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
                : moment(stop.planned_time_window_end).isValid()
                ? moment(stop.planned_time_window_end).format('HH:mm:ss')
                : null;
            }
            delete stopObj.planned_time_window_start;
          } else if (stop['schedule-select'] === '4' || stop['schedule-select'] === 4) {
            if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
              stopObj.planned_time_window_start = '8:00';
            } else {
              //just use the start time for Arrive After Time
              stopObj.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
                ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
                : moment(stop.planned_time_window_start).isValid()
                ? moment(stop.planned_time_window_start).format('HH:mm:ss')
                : null;
            }
            delete stopObj.planned_time_window_end;
          }
        } else {
          if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
            //default dock hours
            stopObj.planned_time_window_start = '8:00';
          } else {
            //when loading from address book, format should already be correct
            stopObj.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_start).isValid()
              ? moment(stop.planned_time_window_start).format('HH:mm:ss')
              : null;
          }
          if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
            stopObj.planned_time_window_end = '18:00';
          } else {
            stopObj.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_end).isValid()
              ? moment(stop.planned_time_window_end).format('HH:mm:ss')
              : null;
          }
        }
        delete stopObj.planning_window;
      }

      if (hasLTL || hasVLTL) {
        stopObj.appointment_needed = stop.appointment_needed;
      }

      //if there is point of contact info on here (from address book), save that too
      if (
        stop.location &&
        stop.location.point_of_contacts &&
        stop.location.point_of_contacts.length > 0 &&
        (stop.location.point_of_contacts[0].email || stop.location.point_of_contacts[0].first_name)
      ) {
        stopObj.location.point_of_contacts = stop.location.point_of_contacts;

        if (stop.location.point_of_contacts.length > 0) {
          //get all the emails that need the BOL and push to the array
          for (const [p, poc] of stop.location.point_of_contacts.entries()) {
            if (poc.email) {
              stopObj.location.point_of_contacts[p].email = poc.email.trim();
            }
          }
        }
      } else {
        //get rid of POC if it's not entered
        delete stopObj.location.point_of_contacts;
      }
      //if there are instructions (from address book) save those
      if (stop.instructions) {
        stopObj.instructions = stop.instructions;
      }

      //if there is custom stop data, save it
      if (stop.custom_data) {
        stopObj.custom_data = stop.custom_data;
      }

      if (
        Object.keys(stopObj.location).length === 0 ||
        (stopObj.location && (!stopObj.location.address || Object.keys(stopObj.location.address).length === 0))
      ) {
        //dont bother saving this stop, since a location is required, and address is required on location
      } else {
        shipmentObj.stops.push(stopObj);
      }
    }

    // set serial ordinal_index after all valid stops are added
    const initialOrdinalIndex = get(head(shipmentObj.stops), 'ordinal_index', 0);
    shipmentObj.stops = shipmentObj.stops.map((stop, index) => ({
      ...stop,
      ordinal_index: initialOrdinalIndex + index
    }));
  }

  //on new shipment/ confirm details page, equipment_type is a single item
  if (attrs.equipment_type) {
    shipmentObj.equipment_type = attrs.equipment_type;
  }

  if (attrs.service_level) {
    shipmentObj.service_level = attrs.service_level;
  }

  if (attrs.mode && !Array.isArray(attrs.mode)) {
    shipmentObj.mode = attrs.mode;
  }
  if (attrs.bill_to_override && !isBillToFormEmpty(attrs.bill_to_override)) {
    shipmentObj.metadata = {
      ...shipmentObj.metadata,
      bill_to_override: JSON.parse(JSON.stringify(attrs.bill_to_override))
    };
    shipmentObj.metadata.bill_to_override.company_address = JSON.parse(
      JSON.stringify(attrs.bill_to_override.company_address.formatted_address)
    );
  } else {
    shipmentObj.metadata = {
      ...shipmentObj.metadata,
      bill_to_override: null
    };
  }
  if (attrs.mode && Array.isArray(attrs.mode) && attrs.mode.length === 1) {
    //if only one mode is selected on new quote, use it as the shipment's mode
    shipmentObj.mode = attrs.mode[0];
  }

  //if there is a temperature specified, add it
  if (!isNil(attrs.temperature_lower_limit)) {
    shipmentObj.temperature_lower_limit = attrs.temperature_lower_limit;
  }
  if (!isNil(attrs.temperature_upper_limit)) {
    shipmentObj.temperature_upper_limit = attrs.temperature_upper_limit;
  }
  if (attrs.temperature_unit) {
    shipmentObj.temperature_unit = attrs.temperature_unit;
  }

  //add references and instructions
  shipmentObj.name = attrs.name;
  shipmentObj.notes_for_carrier = attrs.notes_for_carrier;
  shipmentObj.purchase_order_number = attrs.purchase_order_number;

  //checks to see if these fields exist (don't want these to show up on confirm shipment)
  if (attrs.bol_number) {
    shipmentObj.bol_number = attrs.bol_number;
  }
  if (attrs.pro_number) {
    shipmentObj.pro_number = attrs.pro_number;
  }
  if (attrs.pickup_number) {
    shipmentObj.pickup_number = attrs.pickup_number;
  }
  if (attrs.drayage_chassis_number) {
    shipmentObj.drayage_chassis_number = attrs.drayage_chassis_number;
  }
  if (attrs.drayage_seal_number) {
    shipmentObj.drayage_seal_number = attrs.drayage_seal_number;
  }
  if (attrs.drayage_container_number) {
    shipmentObj.drayage_container_number = attrs.drayage_container_number;
  }
  if (attrs.drayage_carrier_scac_code) {
    shipmentObj.drayage_carrier_scac_code = attrs.drayage_carrier_scac_code;
  }
  if (attrs.drayage_booking_number) {
    shipmentObj.drayage_booking_number = attrs.drayage_booking_number;
  }
  if (attrs.drayage_house_bol_number) {
    shipmentObj.drayage_house_bol_number = attrs.drayage_house_bol_number;
  }
  if (attrs.fedex_specific_options) {
    shipmentObj.fedex_specific_options = attrs.fedex_specific_options;
  }
  if (attrs.rail_car_number) {
    shipmentObj.rail_car_number = attrs.rail_car_number;
  }

  shipmentObj.total_declared_value = attrs.total_declared_value;
  shipmentObj.total_declared_value_currency = attrs.total_declared_value_currency;
  shipmentObj.total_weight_override = attrs.total_weight_override;
  shipmentObj.total_quantity_override = attrs.total_quantity_override;

  return shipmentObj;
}

export function validateStopDate(date, timezone = moment.tz.guess()) {
  const obj = moment(date).isValid() ? moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ssZ') : null;
  return obj;
}

export function createStopIndexDate(index, defaultHour, timezone = moment.tz.guess()) {
  if (index !== 0) {
    const date = timezone
      ? // By multiplying the index by 2 we guarantee a minimum interval of 2 days between each stop by default.
        moment
          .tz(Date.now(), timezone)
          .add(index * 2, 'days')
          .hours(defaultHour || 8)
          .format('YYYY-MM-DDTHH:mm:ssZ')
      : moment()
          .add(index * 2, 'days')
          .hours(defaultHour || 8)
          .format('YYYY-MM-DDTHH:mm:ssZ');
    return date;
  }
  const date = timezone
    ? moment
        .tz(Date.now(), timezone)
        .hours(defaultHour || 8)
        .format('YYYY-MM-DDTHH:mm:ssZ')
    : moment()
        .hours(defaultHour || 8)
        .format('YYYY-MM-DDTHH:mm:ssZ');
  return date;
}

export function applyPlanningWindowRules(stop, timeToggle, index, timezone = moment.tz.guess()) {
  const planningWindow = {
    start: createStopIndexDate(index, 8, timezone),
    end: createStopIndexDate(index, 18, timezone)
  };

  switch (timeToggle) {
    case '1':
    case 1:
      // Arrive At Time
      planningWindow.start = validateStopDate(stop.planning_window.start, timezone) || planningWindow.start;
      planningWindow.end = validateStopDate(stop.planning_window.start, timezone) || planningWindow.start;
      break;
    case '2':
    case 2:
      // Arrive during Time Range
      planningWindow.start = validateStopDate(stop.planning_window.start, timezone) || planningWindow.start;
      planningWindow.end = validateStopDate(stop.planning_window.end, timezone) || planningWindow.end;
      break;
    case '3':
    case 3:
      // Arrive Before Time
      planningWindow.end = validateStopDate(stop.planning_window.end, timezone) || planningWindow.end;
      planningWindow.end = '';
      break;
    case '4':
    case 4:
      // Arrive After Time
      planningWindow.start = validateStopDate(stop.planning_window.start, timezone) || planningWindow.start;
      planningWindow.end = '';
      break;
  }
  return planningWindow;
}

//get users' operating system
export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

//parse the errors as a result ofthe API call
export function parseErrors(response) {
  if (response && response.body && response.body.field_errors_condensed) {
    //log the errors
    console.error(response.body.field_errors_condensed);
    return {
      status: 400,
      error_description: response.body.error_description,
      field_errors: response.body.field_errors_condensed
    };
  }
  if (response && response.body && response.body.non_field_errors) {
    //log the errors
    console.error(response.body.non_field_errors);
    return {
      status: 400,
      error_description: response.body.non_field_errors[0]
    };
  }
  if (response && response.body && response.body.error_description) {
    //log the errors
    console.error(response.body.error_description);
    if (typeof response.body.error_description !== 'string') {
      if (window.Rollbar) {
        window.Rollbar.error('Unexpected Backend Error ', response);
      }
      return {
        status: 400,
        error_description: 'An unexpected error occurred.'
      };
    }
    return {
      status: 400,
      error_description: response.body.error_description
    };
  }
  if (response && response.status && response.status === 502) {
    //log the errors
    console.error('Request timed out.');
    return {
      status: 400,
      error_description: 'The server took too long to respond to this request. Please try again.'
    };
  }
  if (!response || typeof response === 'undefined') {
    return {
      status: 400,
      error_description: 'Unable to connect to the Shipwell server. Please refresh your browser and try again.'
    };
  }
  return {
    status: 400,
    error_description: 'An unexpected error occurred.'
  };
}

export function formatMileage(miles, decimalPlaces = 0, useCommas = true) {
  const split = Number(miles).toFixed(decimalPlaces).split('.');

  // if it's less than 1, always show 3 decimal places
  if (Number(miles) && Number(miles < 1) && Number(miles) >= 0) {
    return Number(miles).toFixed(3);
  }

  // we don't want to remove trailing zeros so we only send the left side of the float
  if (useCommas && Array.isArray(split)) {
    split[0] = numberWithCommas(split[0]);
  }

  // if the result is NaN due to an unexpected value type, just return the value
  return (split && Array.isArray(split) && split[0] && split.join('.')) || miles;
}

//humanize large numbers
export function numberWithCommas(val, minimumFractionDigits) {
  const number = Number(val);
  return !isNaN(number)
    ? number.toLocaleString(undefined, {...(minimumFractionDigits && {minimumFractionDigits})})
    : '';
}

/**
 * Strips value of anything but digits
 * @param  {String} value
 * @return {String}
 */
export function cleanNumber(value, options = {}) {
  const {allowDecimals = false} = options;

  if (typeof value === 'string' && allowDecimals) {
    const [integer, ...fractional] = value.replace(/[^\d(^\.)]+/g, '').split('.');

    return `${integer}${fractional.length ? `.${fractional.join('')}` : ''}`;
  }
  if (typeof value === 'string') {
    return value.replace(/[^\d]+/g, '');
  }
  return '';
}

/**
 * Truncate number to specified fractional
 * @param  {String} value
 * @param  {String} fractional
 * @return {String}
 */
export function truncateNumber(value, fractLength = 2) {
  if (typeof fractLength !== 'number' || Number.isNaN(Number(fractLength))) {
    fractLength = 2;
  }
  const pattern = new RegExp(`^\\d+(\\.\\d{${fractLength},})$`);
  const isNumber = typeof value === 'number' && !Number.isNaN(Number(value));
  const isNumString = typeof value === 'string' && !Number.isNaN(Number(value)) && Number.isFinite(Number(value));

  if (isNumber) {
    return value.toFixed(fractLength);
  }
  if (pattern.test(value) && isNumString) {
    return Number(value).toFixed(fractLength);
  }
  if (!isNumber && !isNumString) {
    return '';
  }
  return value;
}

/**
 * Clean payload, removing or replacing invalid values
 * @param  {Object} data    Object to clean
 * @param  {Object} options Options settings
 *
 * @todo Should return new object and not mutate original object
 */
export const cleanPayload = (data, options = {}) => {
  const stack = [data];
  const {oldValue = ['', 'null'], nextValue = null, hardDelete = false} = options;

  while (stack.length) {
    const obj = stack.pop();

    for (const item in obj) {
      if (obj.hasOwnProperty(item)) {
        if (new Set(oldValue).has(obj[item])) {
          if (hardDelete) {
            delete obj[item];
          } else {
            obj[item] = nextValue;
          }
        } else if (typeof obj[item] === 'object' && !Array.isArray(obj[item])) {
          stack.push(obj[item]);
        } else if (Array.isArray(obj[item])) {
          stack.push(...obj[item]);
        }
      }
    }
  }
  return data;
};

/**
 * Get browser specific Visibility API keys and events
 * @returns {Object}
 */
export const getTabVisibilityEventTypes = () => {
  let hidden;
  let event;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    event = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    event = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    event = 'webkitvisibilitychange';
  }
  return {event, hidden};
};

/**
 * Add tab visibility event listener to page
 * @param {Function} handleEvent Event handler for Browser page visibility change
 */
export const addTabVisibilityEvent = (handleEvent = () => {}) => {
  const {event, hidden} = getTabVisibilityEventTypes();

  // Supply browser specific document property to callback arguments
  document.addEventListener(event, handleEvent, false);
};

/**
 * Remove tab visibility event listener to page
 * @param {Function} handleEvent Event handler for Browser page visibility change
 */
export const removeTabVisibilityEvent = (handleEvent = () => {}) => {
  const {event} = getTabVisibilityEventTypes();

  document.removeEventListener(event, handleEvent);
};

export const formatWeightByUnit = (value, currentUnit = 'LB', nextUnit = 'LB') => {
  const kgPerLbs = 2.20462;
  if (value && currentUnit === 'LB' && nextUnit === 'KG') {
    value /= kgPerLbs;
  } else if (value && currentUnit === 'KG' && nextUnit === 'LB') {
    value *= kgPerLbs;
  }
  return value;
};

export function isBillToFormEmpty(bill_to_override) {
  let isEmptyForm = true;
  if (bill_to_override) {
    Object.keys(bill_to_override).forEach((key) => {
      const hasDefinedCompanyAddressKeys =
        key === 'company_address' &&
        Object.values(bill_to_override?.company_address).some(
          (value) =>
            //check if there are any company address keys that are defined
            !isNil(value)
        );
      if (key === 'company_address' && hasDefinedCompanyAddressKeys) {
        isEmptyForm = false;
      } else if (bill_to_override[key] && key !== 'company_address') {
        isEmptyForm = false;
      }
    });
  }
  return isEmptyForm;
}

export function renderLineItemString(item, i) {
  const provider_specific_packaging = item.provider_specific_packaging
    ? item.provider_specific_packaging
        .replace(/\_/g, ' ')
        .replace('FEDEX', 'FedEx®')
        .toLowerCase()
        .split(' ')
        .map((word) => {
          const letters = [...word];
          letters[0] = letters[0].toUpperCase();
          return letters.join('');
        })
        .join(' ')
    : '';

  return `${i + 1}. ${item.description} - ${item.total_packages ? item.total_packages : ''} ${
    item.package_type ? item.package_type + ',' : ''
  } ${item.provider_specific_packaging ? provider_specific_packaging + ',' : ''} ${
    item.length ? item.length + 'x' : ''
  }${item.width ? item.width : ''}${item.width && item.height ? 'x' : ''}${item.height ? item.height : ''}${
    (item.height || item.length || item.width) && item.length_unit ? item.length_unit + ',' : ''
  } ${item.package_weight ? numberWithCommas(item.package_weight) + item.weight_unit : ''}${
    item.freight_class ? ', Class: ' + item.freight_class : ''
  }${
    item.nmfc_item_code ? ', NMFC: ' + item.nmfc_item_code + (item.nmfc_sub_code ? '-' + item.nmfc_sub_code : '') : ''
  }${item.hazmat_identification_number ? ', Hazmat: ' + item.hazmat_identification_number : ''}`;
}

//When placing bids and requesting tenders, set category, unit name, and quantity for the user to simplify bid/tender creation process.
/**
 * @todo remove global variables and implement more nuanced tendering/bidding process
 */
export const chargeLineItemDefaults = {
  unit_name: 'Line Haul',
  unit_quantity: '1',
  category: ChargeCategory.Lh,
  charge_code: 'LHS'
};

/**
 * Takes a list of strings and turns them into an English comma separated list.
 *
 * makeListPhrase(['one', 'two', 'three']) => 'one, two, and three'
 * makeListPhrase('one') => 'one'
 * makeListPhrase(['one', 'two']) => 'one and two'
 *
 * @param {Array.<String>} list
 *
 * @return {String}
 */
export function makeListPhrase(list) {
  if (!list || !Array.isArray(list) || list.length === 0) {
    return (list && list.length && list) || '';
  }
  if (list.length === 1) {
    return list[0];
  }

  return `${list.slice(0, -1).join(', ')}${list.length > 2 ? ',' : ''} and ${list.slice(-1)}`;
}
