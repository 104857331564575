import {Address, Location, OrderStop, OrderSupplier} from '@shipwell/corrogo-sdk';
import {Address as LegacyAddress, AddressBookEntry, Supplier} from '@shipwell/backend-core-singlerequestparam-sdk';
import omit from 'lodash/omit';
export const getFormattedAddressFromAddressFields = (address?: Address): {formatted_address: string} | undefined => {
  if (!address) {
    return;
  }
  return {
    formatted_address: [
      address.line_1 || '',
      address.line_2 || '',
      address.line_3 || '',
      address.locality || '',
      //do not put a comma between region (generally state- e.g., TX) and postal code
      address.region && address.postal_code ? `${address.region} ${address.postal_code}` : address.region || '',
      address.country || ''
    ]
      //filter out empty address parts to prevent empty join spaces
      .filter((addressPart) => !!addressPart)
      .join(', ')
      .trim()
  };
};

export const mapAddressLookupFieldsToAddress = (
  legacyAddress: LegacyAddress | AddressBookEntry['address']
): Location => {
  return {
    address: {
      country: legacyAddress.country,
      postal_code: legacyAddress.postal_code || undefined,
      line_1: legacyAddress.address_1 || undefined,
      line_2: legacyAddress.address_2 || undefined,
      region: legacyAddress.state_province || undefined,
      locality: legacyAddress.city || undefined,
      geolocation:
        legacyAddress.longitude && legacyAddress.latitude
          ? {longitude: legacyAddress.longitude, latitude: legacyAddress.latitude}
          : undefined
    },
    timezone: legacyAddress.timezone || undefined
  };
};

export const mapSupplierToOrderSupplier = (supplier: Supplier): OrderSupplier => {
  const inferredSupplierPhoneNumber = supplier.users?.find(
    (user) => !!user.phone_number && !!user.should_send_notifications
  )?.phone_number;

  return {
    name: supplier.name,
    supplier_id: supplier.id,
    email: supplier.billing_email || undefined,
    address: mapAddressLookupFieldsToOrderStopAddress(supplier.mailing_address),
    phone_number: inferredSupplierPhoneNumber || undefined
  };
};

export const mapAddressToAddressLookupFields = (location: Location): LegacyAddress => ({
  country: location.address.country,
  postal_code: location.address.postal_code,
  address_1: location.address.line_1 || null,
  address_2: location.address.line_2 || null,
  state_province: location.address.region || null,
  city: location.address.locality || null,
  longitude: location.address.geolocation?.longitude || null,
  latitude: location.address.geolocation?.latitude || null,
  timezone: location.timezone
});

export const mapOrderStopAddressToAddressLookupFields = (
  orderStop: OrderStop
): //this prevents issues with type definition disparities between
//backend-core and corrogo SDKs.
LegacyAddress & {postal_code?: string} => ({
  country: orderStop.country,
  postal_code: orderStop.postal_code || undefined,
  address_1: orderStop.line_1,
  address_2: orderStop.line_2,
  state_province: orderStop.region,
  city: orderStop.locality,
  longitude: orderStop.geolocation?.longitude,
  latitude: orderStop.geolocation?.latitude,
  timezone: orderStop.timezone,
  formatted_address: getFormattedAddressFromAddressFields(orderStop)?.formatted_address
});

export const mapAddressLookupFieldsToOrderStopAddress = (
  legacyAddress: LegacyAddress | AddressBookEntry['address']
): //there isn't currently an OrderStopAddress type, so we have to roll with using the
//OrderStop type here.
OrderStop => {
  return {
    country: legacyAddress.country,
    postal_code: legacyAddress.postal_code || undefined,
    line_1: legacyAddress.address_1 || undefined,
    line_2: legacyAddress.address_2 || undefined,
    region: legacyAddress.state_province || undefined,
    locality: legacyAddress.city || undefined,
    geolocation:
      legacyAddress.longitude && legacyAddress.latitude
        ? {longitude: legacyAddress.longitude, latitude: legacyAddress.latitude}
        : undefined,
    timezone: legacyAddress.timezone || undefined
  };
};
const orderStopAddressFields: (keyof OrderStop)[] = [
  'country',
  'postal_code',
  'line_1',
  'line_2',
  'region',
  'locality',
  'geolocation',
  'timezone'
];
export const excludeAddressFieldsFromOrderStop = (
  orderStop: OrderStop
): Omit<
  OrderStop,
  'country' | 'postal_code' | 'line_1' | 'line_2' | 'region' | 'locality' | 'geolocation' | 'timezone'
> => omit(orderStop, orderStopAddressFields);
