import {SlimShipment, Stop} from '@shipwell/backend-core-singlerequestparam-sdk';
import isNil from 'lodash/isNil';

/**
 * Gets the very first stop flagged as a pickup stop and the very last drop stop.
 * If there are multiple pickups or drops the min/max will always be taken.
 *
 * If no pickup or drop stop can be found null is returned.
 */
const getFirstAndLastStops = (shipment: SlimShipment | null): {firstStop: Stop | null; lastStop: Stop | null} => {
  let pickStop = null;
  let dropStop = null;

  if (isNil(shipment)) {
    return {firstStop: null, lastStop: null};
  }

  if (!shipment?.stops?.length) {
    return {firstStop: null, lastStop: null};
  }
  // search for first pick and last drop
  for (let i = 0; i < shipment.stops.length; ++i) {
    const itrStop = shipment.stops[i];
    if (itrStop.is_pickup) {
      if (!pickStop) {
        pickStop = itrStop; // none assigned for first pickup yet we can just assign immediately
        continue;
      }
      if (pickStop.ordinal_index > itrStop.ordinal_index) {
        pickStop = itrStop;
        continue;
      }
    }
    if (itrStop.is_dropoff) {
      if (!dropStop) {
        dropStop = itrStop; // none assigned for last drop off yet we can just assign immediately
        continue;
      }
      if (dropStop.ordinal_index < itrStop.ordinal_index) {
        dropStop = itrStop;
        continue;
      }
    }
  }

  return {
    firstStop: pickStop,
    lastStop: dropStop
  };
};
/**
 * Generates a lane text or returns an emptry string if one cannot be constructed.
 * @example "Amarillo, TX > Dallas, TX"
 */
const getShipmentLane = (firstStop: Stop | null, lastStop: Stop | null) => {
  const lane = [];
  if (firstStop?.location?.address?.city) {
    lane.push(firstStop.location.address.city);
  }
  if (firstStop?.location?.address?.state_province) {
    if (lane.length) {
      lane.push(', ');
    }
    lane.push(firstStop?.location.address.state_province);
  }
  if (lastStop?.location?.address?.city) {
    if (lane.length) {
      lane.push(' > ');
    }
    lane.push(lastStop.location.address.city);
  }
  if (lastStop?.location?.address?.state_province) {
    if (lane.length) {
      lane.push(', ');
    }
    lane.push(lastStop?.location.address.state_province);
  }
  return lane.join('');
};

export {getShipmentLane, getFirstAndLastStops};
