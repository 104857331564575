import {Form, Formik, FormikHelpers} from 'formik';
import {object, string, mixed} from 'yup';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import MiniProductsFields from '../../formSections/miniProductFields';
import './styles.scss';
import {validateDollarValue} from 'App/utils/globals';
import {LineItemFormValues} from 'App/formComponents/formSections/LineItemFields/types';

const miniProductsFieldsSchema = object().shape({
  product_ref: string().required('Reference is required'),
  value_per_piece: mixed().test({
    name: 'valid dollar value',
    message: 'Enter a dollar value',
    test: (value) => validateDollarValue(value)
  })
});
const MiniProductsForm = ({
  onCancel,
  handleSubmit,
  values,
  lineItemIndex
}: {
  onCancel: () => void;
  handleSubmit: (
    product: LineItemFormValues,
    setSubmitting: FormikHelpers<LineItemFormValues>['setSubmitting'],
    setErrors: FormikHelpers<LineItemFormValues>['setErrors'],
    lineItemIndex: number
  ) => void;
  values: LineItemFormValues;
  lineItemIndex: number;
}) => {
  const defaultFormValues = {
    product_ref: values.product_ref || '',
    value_per_piece: values.value_per_piece || ''
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={miniProductsFieldsSchema}
      initialValues={{...values, ...defaultFormValues} as LineItemFormValues}
      onSubmit={(product, {setSubmitting, setErrors}) => handleSubmit(product, setSubmitting, setErrors, lineItemIndex)}
    >
      {({isSubmitting}) => (
        <Form noValidate>
          <MiniProductsFields />
          <div className="page-footer">
            {onCancel && (
              <DeprecatedButton variant="secondary" onClick={onCancel}>
                Cancel
              </DeprecatedButton>
            )}
            <DeprecatedButton type="submit" disabled={isSubmitting} loading={isSubmitting}>
              Save
            </DeprecatedButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MiniProductsForm;
