import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import classNames from 'classnames';

const ShipmentFinancialStatus = ({
  processingStatus,
  paymentStatus,
  errorDescription
}: {
  processingStatus: string;
  paymentStatus: string;
  errorDescription?: string;
}) => {
  return (
    <span
      className={classNames('flex grow flex-row items-center', {
        'text-sw-error': processingStatus === 'ERROR'
      })}
    >
      {processingStatus === 'ERROR' && errorDescription && (
        <Tooltip
          tooltipContent={errorDescription}
          trigger="hover"
          wrapperClassname="mr-1.5"
          tooltipClassname="max-w-xl"
        >
          <SvgIcon name="InfoOutlined" color="$sw-error" />
        </Tooltip>
      )}
      {processingStatus === 'SUCCESS' ? paymentStatus : startCase(lowerCase(processingStatus))}
    </span>
  );
};

export default ShipmentFinancialStatus;
