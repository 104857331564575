import {TableOptions, useReactTable, getCoreRowModel, getSortedRowModel} from '@tanstack/react-table';

// this very basic hook builds in column resize
export function useTypedTable<T>(options: Omit<TableOptions<T>, 'getCoreRowModel'>) {
  const table = useReactTable({
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    sortDescFirst: false,
    ...options
  });

  return table;
}
