import {Field} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';

const RMISFTPFields = ({isFTPDisabled}: {isFTPDisabled: boolean}) => {
  return (
    <div className="field-grid grid-1-1">
      <Field required label="FTP Username" disabled={isFTPDisabled} name="username" component={FormikTextInput} />
      <Field
        required
        label="FTP Password"
        disabled={isFTPDisabled}
        name="password"
        component={FormikTextInput}
        type="password"
      />
      <Field required label="FTP Host" disabled={isFTPDisabled} name="hostname" component={FormikTextInput} />
    </div>
  );
};
export default RMISFTPFields;
