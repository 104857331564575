import {Stop, StopAlert, StopEvent} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, FormikCollapsibleGroupSelect, FormikDateTimePicker, FormikTextarea} from '@shipwell/shipwell-ui';
import {Field, Form, useFormikContext} from 'formik';
import {get} from 'lodash';
import {useEffect, useMemo} from 'react';
import makeLabelForOptionObject from 'App/utils/makeLabelForOptionObject';
import useGroupedStopEventTypes from 'App/api/shipment/useGroupedStopEventTypes';
import useGroupedStopStatusReasonCodes from 'App/api/shipment/useGroupedStopStatusReasonCodes';
import {stopFlagAlertTypes} from 'App/utils/stopFlag';

type UnifiedStopEventsAndFlags = StopEvent & StopAlert;

interface UnifiedStopEventsAndFlagsFormProps {
  stop: Stop;
  onCancel: () => void;
}

export const UnifiedStopEventsAndFlagsForm = ({onCancel}: UnifiedStopEventsAndFlagsFormProps) => {
  const {isSubmitting, dirty, isValid, setFieldValue, values} = useFormikContext<UnifiedStopEventsAndFlags>();

  useEffect(() => {
    // create fields for both alerts and events
    setFieldValue('alert_type', values.stop_event_type);
    setFieldValue('reason_code', values.stop_event_reason_code);
  }, [setFieldValue, values]);

  const groupedStopEventTypes = useGroupedStopEventTypes();

  const unifiedTypeOptions = useMemo(() => {
    if (!groupedStopEventTypes) {
      return [];
    }

    return [{label: 'Flags', options: stopFlagAlertTypes}, ...groupedStopEventTypes];
  }, [groupedStopEventTypes]);

  const groupedStopStatusReasonCodes = useGroupedStopStatusReasonCodes();

  return (
    <Form>
      <div className="flex flex-col gap-2">
        <Field
          component={FormikCollapsibleGroupSelect}
          name="stop_event_type"
          label="Event Type"
          simpleValue
          options={unifiedTypeOptions}
          getOptionLabel={makeLabelForOptionObject}
          maxMenuHeight={500}
        />
        <Field
          component={FormikCollapsibleGroupSelect}
          name="stop_event_reason_code"
          label="Reason"
          simpleValue
          options={groupedStopStatusReasonCodes}
          getOptionLabel={makeLabelForOptionObject}
          maxMenuHeight={500}
        />
        <Field
          name="occurred_at"
          label="Event Time"
          component={FormikDateTimePicker}
          timezone={get(stop, 'location.address.timezone')}
          defaultValue={new Date()}
        />
        <div className="mt-2">
          <Field component={FormikTextarea} name="description" label="Description" />
        </div>
      </div>
      <div className="mt-4 flex items-center justify-end gap-4">
        <Button variant="secondary" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !dirty || !isValid} isLoading={isSubmitting} type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};
