import {SpotNegotiationQuoteDocument} from '@shipwell/backend-core-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getSpotNegotiationQuoteDocument} from 'App/api/quoting/typed';
import {SPOT_NEGOTIATION_QUOTE_DOCUMENT} from 'App/data-hooks/queryKeys';

const useSpotNegotiationQuoteDocument = ({
  spotNegotiationId,
  spotNegotiationQuoteId,
  spotNegotiationQuoteDocumentId,
  queryOptions
}: {
  spotNegotiationId?: string | null;
  spotNegotiationQuoteId?: string | null;
  spotNegotiationQuoteDocumentId?: string;
  queryOptions?: UseQueryOptions<SpotNegotiationQuoteDocument | null | undefined>;
}) => {
  const {data, remove} = useQuery({
    queryKey: [
      SPOT_NEGOTIATION_QUOTE_DOCUMENT,
      spotNegotiationId,
      spotNegotiationQuoteId,
      spotNegotiationQuoteDocumentId
    ],
    queryFn: async () => {
      if (!spotNegotiationId || !spotNegotiationQuoteId || !spotNegotiationQuoteDocumentId) return null;

      const response = await getSpotNegotiationQuoteDocument({
        spotNegotiationId,
        spotNegotiationQuoteId,
        spotNegotiationQuoteDocumentId
      });

      return response.data;
    },
    ...queryOptions
  });

  return {spotNegotiationQuoteDocument: data, remove};
};

export default useSpotNegotiationQuoteDocument;
