import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {COMPANY_INTEGRATION_AMAZON_DISCONNECT} from 'App/data-hooks/queryKeys';
import {disconnectAmazonFreight} from 'App/api/integrations';

const useDisconnectAmazonFreightMutation = (options: UseMutationOptions<unknown, AxiosError, unknown>) =>
  useMutation(
    [COMPANY_INTEGRATION_AMAZON_DISCONNECT],
    async (companyId: string) => {
      await disconnectAmazonFreight(companyId);
    },
    options
  );

export default useDisconnectAmazonFreightMutation;
