import {
  PoliciesApi,
  Configuration,
  PoliciesApiRoutingGuidePoliciesGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_API_BASE_PATH;

function createPoliciesApi() {
  return new PoliciesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

const policiesApi = createPoliciesApi();

export function getPolicies(requestParams?: PoliciesApiRoutingGuidePoliciesGetRequest) {
  return policiesApi.routingGuidePoliciesGet(requestParams);
}

export function getPolicy(policyId: string) {
  return policiesApi.routingGuidePoliciesPolicyIdGet({policyId});
}

export function deletePolicy(policyId: string) {
  return policiesApi.routingGuidePoliciesPolicyIdDelete({policyId});
}
