import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {useFormikContext} from 'formik';
import {v4} from 'uuid';
import useToggle from 'App/utils/hooks/useToggle';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import {FacilityDocumentPostType, FacilityCreationForm} from 'App/data-hooks/facilities/types';
import {FACILITY_DOCUMENT_UPLOAD_LIMIT} from 'App/data-hooks/facilities/constants';

const FacilityDocuments = () => {
  const [showDocumentsModal, toggleShowDocumentsModal] = useToggle();
  const {setFieldValue, values} = useFormikContext<FacilityCreationForm['facilityInfo']>();
  const isUploadBtnDisabled = values?.documents && values?.documents?.length >= FACILITY_DOCUMENT_UPLOAD_LIMIT;

  // add the uploaded document to the form object
  const handleUploadDocument = ({file, description}: FacilityDocumentPostType) => {
    const currentDocs = values?.documents || [];
    setFieldValue('documents', [...currentDocs, {file, description}]);
    toggleShowDocumentsModal();
  };

  return (
    <div className="mt-3">
      <div className="flex items-center">
        <SvgIcon name="Upload" color={isUploadBtnDisabled ? `sw-disabled` : `sw-primary`} />
        <DeprecatedButton variant="tertiary" onClick={toggleShowDocumentsModal} disabled={isUploadBtnDisabled}>
          Upload Facility Map
        </DeprecatedButton>
      </div>
      {
        /* upon uploading a document, show its filename on the facility modal */
        (values?.documents ?? []).map((doc) => (
          <div key={v4()} className="my-1 flex items-center">
            <SvgIcon name="Attachment" />
            <div>{'file' in doc && doc?.file?.name}</div>
          </div>
        ))
      }
      <Modal
        show={showDocumentsModal}
        title="Upload Document"
        footerComponent={null}
        onClose={toggleShowDocumentsModal}
      >
        <ShipmentDocumentForm onCancel={toggleShowDocumentsModal} onSubmit={handleUploadDocument} hideTypeField />
      </Modal>
    </div>
  );
};

export default FacilityDocuments;
