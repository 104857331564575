import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  RateRequestFailure,
  ProviderCode,
  TransportationMode,
  GenesisCommonSchemasCharge,
  RateV2,
  CreateRateRequest,
  GenesisRatingSchemasRatingRateRequest
} from '@shipwell/genesis-sdk';
import {Quote, RFQ, Shipment, SlimRFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {isNil, isUndefined, isNull} from 'lodash';
import invariant from 'tiny-invariant';
import {getRate} from 'App/api/genesis';
import {createRFQPromise} from 'App/api/quoting';
import {createCarrierConnectionQuote, getRfqDetails, SourceTypeEnum} from 'App/api/quoting/typed';
import {COMPANY_INTEGRATION_CARRIERS, RATING_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {Rate} from 'src/@types/quotingTypes';
import {fetchEquipmentTypesPromise} from 'App/api/shipment/typed';
import {createRates, getRates, listRateRequests, updateRate} from 'App/api/genesis/typed';
import {getCapacityProvidersByMode, getConnectionsByMode} from 'App/api/integrations/typed';
import {toCreateRateRequest} from 'App/containers/InstantRatesV2/common/transform';
import {QuoteChargeLineItem, ShipmentMode} from '@shipwell/backend-core-sdk';
import {getFedExRegistrationAccounts} from 'App/api/registration';
import {PackagingTypes} from 'App/utils/packagingTypes';
import {getShipmentModeFlags} from 'App/containers/quotes/create/utils/createQuote';
import store from 'App/routes/store';
import {instantQuotesFilter} from 'App/containers/Marketplace/components/InstantRates/legacy/utils';
import {
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk/dist/api';
import {useState} from 'react';

interface OptionsRFQ {
  fedex_specific_options: FedexShipmentOptions;
  usps_specific_options: UPSShipmentOptions;
  ups_specific_options: USPSShipmentOptions;
}

interface FedexResponse {
  id: string;
  is_freight: boolean;
  meter_number: number;
}

const getScac = (providerCode: ProviderCode): {scac: string; displayText: string} => {
  const defaultDisplayScac = {
    scac: 'unknown',
    displayText: 'unknown'
  };
  const carrierIdentificationMap: {[key: string]: string} = {
    UBER: 'Uber Freight',
    AMAZON: 'Amazon Freight',
    CHROBINSON: 'CH Robinson'
  };

  if (isNil(providerCode)) {
    return defaultDisplayScac;
  }

  const carrierIdentificationCode: string = providerCode;
  return {
    scac: carrierIdentificationCode,
    displayText: carrierIdentificationMap[carrierIdentificationCode]
  };
};

export function transformRate(rate: RateV2, rateRequestId: string, legacyRfqId?: string | null): Rate {
  const carrierScacInfo = getScac(rate.provider_code);
  return {
    rateId: rate.rate_id as string,
    rateRequestId,
    legacyRfqId,
    rate: {
      amount: rate.charges?.total_charges?.amount ?? -1,
      currency: rate.charges?.total_charges?.currency ?? 'USD'
    },
    can_dispatch: true,
    charge_breakdown: rate.charges.charge_breakdown?.map((charge) => transformChargeBreakDown(charge)),
    charge_total: {
      currency: rate.charges.total_charges.currency,
      amount: rate.charges.total_charges.amount
    },
    carrier: {
      scac: carrierScacInfo.scac,
      displayName: carrierScacInfo.displayText
    },
    transitDays: rate.transit_time?.transit_days,
    deliveryDate: rate.transit_time?.delivery?.earliest || rate.transit_time?.delivery?.latest,
    pickupDate: rate.transit_time?.pickup?.earliest || rate.transit_time?.pickup?.latest,
    expirationDate: rate.expiry,
    messages: rate.messages,
    recommendations: rate.recommendations,
    errors: rate.messages?.flatMap((value) => value.detail)
  } as Rate;
}

const transformChargeBreakDown = (charge: GenesisCommonSchemasCharge) => {
  return {
    charge_code: charge.charge_code,
    description: charge.description,
    charge_detail: charge.charge_detail
  };
};

const transformChargeBreakDownLegacy = (charge: QuoteChargeLineItem) => {
  return {
    charge_code: charge.charge_code,
    description: charge.unit_name,
    charge_detail: {
      unit_rate: {
        currency: charge.unit_amount_currency,
        amount: charge.unit_amount
      },
      unit_quantity: charge.unit_quantity
    }
  };
};

export function transformRateError(error: RateRequestFailure): RateV2 {
  return {
    provider_code: error.provider_code,
    charges: {
      total_charges: {
        currency: '',
        amount: 0
      }
    },
    rate_id: error.rate_request_id,
    transportation_mode: TransportationMode.Ftl,
    capacity_provider: {
      identification_codes: [
        {
          qualifier: error?.carrier_identification.qualifier,
          value: error?.carrier_identification.value
        }
      ]
    },
    messages: [
      {
        detail: error.error
      }
    ]
  };
}

export function transformRatesLegacy(quote: Quote, rateRequestId: string, legacyRfqId?: string | null): Rate {
  return {
    quoteLegacy: quote,
    rateId: quote.id as string,
    rateRequestId,
    legacyRfqId,
    charge_breakdown: quote?.charge_line_items?.map((charge) => transformChargeBreakDownLegacy(charge)),
    charge_total: {
      currency: quote.currency,
      amount: quote.total
    },
    rate: {
      amount: quote.total,
      currency: quote.currency
    },
    carrier: {
      displayName: quote?.carrier?.display_name ? quote?.carrier?.display_name : '-'
    },
    provider_logo_url: quote.provider_logo_url,
    service_level: {
      code: quote.service_level?.code,
      description: quote.service_level?.description
    },
    customer_markup: quote?.customer_markup,
    error_message: quote?.error_message,
    can_dispatch: quote.can_dispatch,
    lane_type: quote.lane_type,
    created_by_company: quote.created_by_company,
    is_csp_rate: quote.is_csp_rate,
    mode: quote.mode,
    transitDays: quote?.transit_days,
    deliveryDate: quote.delivery_date,
    pickupDate: quote.earliest_pickup_date,
    expirationDate: quote.expires_at
  } as Rate;
}

/**
 * react-query hook for creating a rate request against the rating and quoting api.
 * The query will continue requesting data from the rating and quoting API until
 * the response contains a body with valid data.
 *
 * The hook will not trigger until a valid rateRequestId parameter has been provided.
 * @param {string|undefined} rateRequestId the original identifier that was created when requesting rate quotes from carriers.
 * @param {string|undefined} legacyRfqId should come from backend-core
 * @param connections
 * @param options
 */
export const useRatesPolling = (
  legacyRfqId: string,
  mode?: ShipmentMode,
  rateRequestId?: string,
  connections?: boolean[],
  options?: Omit<
    UseQueryOptions<unknown, AxiosError, {rates: Rate[]; finish: boolean}, (string | undefined)[]>,
    'queryKey'
  >
): UseQueryResult<{rates: Rate[]; finish: boolean}, AxiosError> => {
  const [hasRun, setHasRun] = useState(true);
  const [rates, setRates] = useState<Rate[]>();

  const refetchIntervalCallback = function (data: Rate[] | undefined): number {
    if (data?.length !== connections?.length) {
      return 1500;
    }
    return 0;
  };

  return useQuery(
    [RATING_QUERY_KEY, rateRequestId],
    async () => {
      const rates: Rate[] = [];
      let finish = false;
      if (mode?.code === 'LTL' && legacyRfqId) {
        const rfqRatesPolling = await getRfqDetails({rfqId: legacyRfqId, includeFailures: false});
        if (rfqRatesPolling.data.quotes && rfqRatesPolling?.data?.quotes?.length > 0) {
          instantQuotesFilter(rfqRatesPolling.data?.quotes)
            .filter(({source_type: sourceType}) => sourceType === SourceTypeEnum.Instant)
            ?.map<Rate>((value) => transformRatesLegacy(value as Quote, rateRequestId || '', value.rfq))
            .forEach((value) => rates.push(value));
          finish = rfqRatesPolling?.data?.did_finish_autoquote || false;
          setHasRun(!rfqRatesPolling?.data?.did_finish_autoquote);
        }
      }

      if (rateRequestId) {
        const genesisRates = await processingGenesisRates(rateRequestId, legacyRfqId);
        genesisRates.forEach((value) => {
          rates.push(value);
        });
      }
      setRates(rates);
      return {rates, finish};
    },
    {
      ...options,
      refetchInterval: mode?.code === 'FTL' ? refetchIntervalCallback(rates) : 2000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry: 0,
      enabled: hasRun
    }
  );
};

const processingGenesisRates = async (rateRequestId: string, legacyRfqId: string) => {
  const axiosResponse = await getRates(rateRequestId);
  const data: RateV2[] = axiosResponse.data?.data || [];
  const errors = axiosResponse.data?.errors || [];
  const errorsRates = errors.map((error: RateRequestFailure) => transformRateError(error));

  const rates: Rate[] = data.map<Rate>((value) => transformRate(value as RateV2, rateRequestId, legacyRfqId));

  errorsRates.forEach((error: RateV2) => {
    rates.push(transformRate(error, rateRequestId, legacyRfqId));
  });

  return rates;
};

export const useConnectionsQuery = (
  mode: string,
  options?: Omit<UseQueryOptions<unknown, AxiosError, boolean[], (string | undefined)[]>, 'queryKey'>
): UseQueryResult<boolean[], AxiosError> => {
  return useQuery(
    [COMPANY_INTEGRATION_CARRIERS],
    async () => {
      return getCapacityProvidersByMode(mode === 'LTL' ? TransportationMode.Ltl : TransportationMode.Ftl);
    },
    options
  );
};

/**
 * Get valid connection to enable to execute the rates
 * @param mode
 */
export const getActiveConnections = (mode: TransportationMode) => {
  return getCapacityProvidersByMode(mode);
};

/**
 * Hook to create a new quote
 * @param shipment
 * @param initialRateRequestId
 * @param legacyRfqId
 * @param options
 */
export const useNewQuoteCreationMutation = (
  shipment: Shipment,
  initialRateRequestId?: string,
  legacyRfqId?: string,
  options?: Omit<UseMutationOptions<{rfq?: RFQ; rateRequestId: string}, AxiosError, Shipment, unknown>, 'queryFn'>
): UseMutationResult<{rfqId?: string; rateRequestId: string}, AxiosError, Shipment, unknown> => {
  return useMutation(async (shipment: Shipment) => {
    if (legacyRfqId && shipment.mode?.code === 'LTL') {
      const {data: rfq} = await getRfqDetails({
        rfqId: legacyRfqId || ''
      });

      return {rfq: rfq || undefined, rateRequestId: initialRateRequestId || ''};
    }
    return createNewQuote(shipment);
  }, options);
};

/**
 * Retrieve the most recent rate execute
 * @param rates
 */
const getMostRecentRateGenesis = (rates: GenesisRatingSchemasRatingRateRequest[]) => {
  if (!rates.length) return null;

  return rates.sort((a, b) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    return bDate.getTime() - aDate.getTime();
  })[0];
};

/**
 * check exist the most recent rate
 * @param shipmentId
 */
export const existRates = async (shipmentId: string) => {
  try {
    const response = await listRateRequests({shipmentId});
    return getMostRecentRateGenesis(response.data);
  } catch (e) {
    console.log(e);
  }
};

const createNewQuote = async (shipment: Shipment) => {
  const {userCompany} = store.getState();
  const {fedex_enabled: fedExEnabled} = userCompany.feature_flags;
  const {isLTL} = getShipmentModeFlags({mode: shipment.mode});

  const config: OptionsRFQ = {
    fedex_specific_options: {},
    ups_specific_options: {},
    usps_specific_options: {}
  };

  const rfqPayload = {
    autoquote: true,
    shipment: shipment.id,
    shipment_modes: shipment.mode ? [shipment.mode] : [],
    equipment_types: shipment.equipment_type ? [shipment.equipment_type] : [],
    has_parent_rfq: false,
    parent_rfq: null as unknown as SlimRFQ
  };

  if (isLTL && fedExEnabled) {
    const fedExAccountResp = await getFedExRegistrationAccounts({});

    if (fedExAccountResp.ok) {
      const accounts: FedexResponse[] = fedExAccountResp.body?.results || [];

      const freightAccount = accounts.find((account) => account?.is_freight && account?.meter_number != null);

      if (freightAccount) {
        config.fedex_specific_options = {
          account: freightAccount.id,
          packaging: PackagingTypes.YourPackaging
        };
      }
    }
  }

  const rfqPayloadWithOptions = {
    ...rfqPayload,
    fedex_specific_options: config.fedex_specific_options || undefined,
    ups_specific_options: null,
    usps_specific_options: null
  };

  // @ts-expect-error: problem with types the backend expect null values
  const resp = await createRFQPromise(rfqPayloadWithOptions);

  const rfqBody: RFQ = resp.body;
  if (isNull(rfqBody) || isNull(rfqBody?.id) || isUndefined(rfqBody?.id)) {
    throw Error('Could not create legacy RFQ');
  }

  const gen_response = await crateGenesisRateRequest(shipment);

  return {rateRequestId: gen_response?.rateRequestId || '', rfq: rfqBody};
};

/**
 * create request to LTL or FTL to genesis
 * @param shipment
 */
const crateGenesisRateRequest = async (shipment: Shipment) => {
  const mode = shipment.mode?.code === 'LTL' ? TransportationMode.Ltl : TransportationMode.Ftl;
  const connections = await getActiveConnections(mode);

  const connectionsFull = await getConnectionsByMode(mode);

  if (connections.length !== 0) {
    const equipmentTypesResp = await fetchEquipmentTypesPromise();
    const request: CreateRateRequest = toCreateRateRequest(equipmentTypesResp.data, shipment, connectionsFull);
    const resp = await createRates({
      createRateRequest: request
    });

    const rateRequestId = resp.data?.id;
    if (rateRequestId === undefined || rateRequestId === null) {
      throw Error('Rate Request did not return an identifier.');
    }
    invariant(shipment.state, 'Missing required shipment state.');

    return {rateRequestId: rateRequestId};
  }
};

export interface ActivateRateMutation {
  legacyQuote?: Quote;
  rate: Rate;
}

/**
 * Creates a rate appending to a request for rate in backend core. This function
 * is meant to stitch together genesis and backend-core which don't
 * communicate rates.
 */
export const useActiveRateMutation = (
  mode: string,
  options?: Omit<UseMutationOptions<ActivateRateMutation, AxiosError, Rate, unknown | Promise<unknown>>, 'mutationFn'>
) => {
  return useMutation(async (variables: Rate) => {
    const rate = variables;
    if (mode === 'FTL') {
      const resp = await getRate(variables.rateRequestId, variables.rateId);
      const ftlRate: RateV2 = resp.data;
      ftlRate.is_active = true; // set to active for new world logic
      const rateResp = await updateRate({
        rateRequestId: variables.rateRequestId,
        id: variables.rateId,
        rateV2: ftlRate
      });
      const rate = transformRate(rateResp.data, variables.rateRequestId, variables.legacyRfqId);
      if (isUndefined(variables.legacyRfqId)) {
        return {
          rate
        };
      }
    }
    const legacyQuote: Quote = await createCarrierConnectionQuote(variables);
    return {
      rate,
      legacyQuote
    };
  }, options);
};

/**
 * Creates a new request for rates from shipwell (only). Does not support
 * external capacity providers. An existing shipment is required before
 * the Request for quote can be sent.
 */
export const useLegacyCreateRateRequestMutation = (
  options?: Omit<UseMutationOptions<RFQ, AxiosError, RFQ, unknown>, 'mutationFn'>
): UseMutationResult<RFQ, AxiosError, RFQ, unknown> => {
  return useMutation(async (rfq: RFQ) => {
    const resp = await createRFQPromise(rfq);

    const rfqBody: RFQ = resp.body;
    if (isNull(rfqBody) || isNull(rfqBody?.id) || isUndefined(rfqBody?.id)) {
      throw Error('Could not create legacy RFQ');
    }
    return rfqBody;
  }, options);
};
