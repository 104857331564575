import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import get from 'lodash/get';
import omit from 'lodash/omit';

/**
 * Convert an object with groups and values properties to a list of grouped options to give to a
 * select component. labelOverridesMap can override labels for specific ids of either groups or
 * labels.
 *
 * @param {Object} groupedOptions An object with groups and values properties, whose values are
 * arrays of objects with string properties.
 * @param {Array} filterGroups A list of groups to filter out and not display
 * @param {Object} labelOverridesMap An object whose keys are group codes or value ids and whose
 * values are labels to override with.
 *
 * @return {Array}
 */
export default function formatGroupedOptions({groups, values}, filterGroups, labelOverridesMap) {
  const groupsMap = keyBy(groups, 'code');
  const grouped = groupBy(values, 'group');
  return map(omit(grouped, filterGroups), (options, groupId) => ({
    label: get(labelOverridesMap, groupId, get(groupsMap, `${groupId}.description`, '')),
    options: options.map((option) => ({id: get(option, 'name'), label: get(labelOverridesMap, get(option, 'name'))}))
  }));
}
