import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getUSPSRegistrationAccounts} from 'App/containers/usps/actions/async';
import SchedulePickups from 'App/components/parcelPickups';
import ShipwellLoader from 'App/common/shipwellLoader/index';

const USPSAccountDetails = (props) => {
  const {dispatch} = props;

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);

  const fetchUSPSRegistrationAccounts = async () => {
    setIsFetchingAccounts(true);
    try {
      const response = await dispatch(getUSPSRegistrationAccounts());

      if (response && response.body && response.body.results && response.body.results.length > 0) {
        setAccounts(response.body.results);
        setAccount(response.body.results[0]);
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchingAccounts(false);
  };

  useEffect(() => {
    if (accounts.length < 1) {
      fetchUSPSRegistrationAccounts();
    }
  }, []);

  return (
    <div>
      {isFetchingAccounts && !account && <ShipwellLoader loading />}
      {!isFetchingAccounts && account && (
        <div className="usps-account-container field-grid">
          <div className="grid-item-2 usps-account-details">
            <SchedulePickups account={account} providerCode="USPS" />
          </div>
        </div>
      )}
      {!isFetchingAccounts && accounts.length < 1 && <p>No USPS Account Connected</p>}
    </div>
  );
};

export default connect()(USPSAccountDetails);
