import {LoadboardType, LoadPostRequestUpdate} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {updateLoadpost} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

type UpdateLoadpostPayload = {
  loadboardType: LoadboardType;
  loadpostId: string;
  loadPostRequestUpdate: LoadPostRequestUpdate;
};

const useUpdateLoadpost = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof updateLoadpost>>, unknown, UpdateLoadpostPayload>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof updateLoadpost>>, unknown, UpdateLoadpostPayload>(
    async ({loadboardType, loadpostId, loadPostRequestUpdate}) =>
      await updateLoadpost(loadboardType, loadpostId, loadPostRequestUpdate),
    {
      onSettled: () => queryClient.invalidateQueries([LOADPOST_KEY]),
      ...mutationOptions
    }
  );
};

export default useUpdateLoadpost;
