import {useContext, useState} from 'react';
import {SvgIcon, Card} from '@shipwell/shipwell-ui';
import {FieldArray, Formik, Form} from 'formik';
import {FacilityPointOfContactRole} from '@shipwell/tempus-sdk';
import cloneDeep from 'lodash/cloneDeep';
import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import {facilityContactsValidationSchema} from 'App/containers/facilities/schema';
import {FieldArraySideNav} from 'App/components/FieldArray/FieldArraySideNav';
import {facilityContactRoleOptions} from 'App/data-hooks/facilities/constants';
import {FacilityContactType} from 'App/data-hooks/facilities/types';
import FacilityCreationModalFooter from 'App/containers/facilities/components/FacilityCreationModalFooter';
import FacilityContactFields from 'App/formComponents/formSections/facilityContactFields';
import {generateKey} from 'App/utils/generateKey';

type NewContactType = FacilityContactType & {person_name: string};
type NewNewContactType = NewContactType;
const ContactsForm = () => {
  const {setNextStep, facilityFormData, setFacilityFormData} = useContext(StepFormContext);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  const onFacilityContactsSubmit = (values: {facilityContacts: NewNewContactType[]}) => {
    const submittedContacts = cloneDeep(values.facilityContacts);
    setFacilityFormData({
      ...facilityFormData,
      facilityContacts: submittedContacts
    });
    setNextStep();
  };

  const handleAddNew = (
    push: (facilityContact: FacilityContactType) => void,
    values: {facilityContacts: FacilityContactType[]}
  ) => {
    setCurrentFormIndex(values?.facilityContacts?.length);
    push({
      phone_number: '',
      email: '',
      facility_role: '' as FacilityPointOfContactRole,
      person_name: '',
      is_default_for_facility: false,
      key: generateKey()
    });
  };

  const handleRemove = (remove: (index: number) => void, values: {facilityContacts: FacilityContactType[]}) => {
    setCurrentFormIndex(values?.facilityContacts?.length - 2);
    remove(currentFormIndex);
  };

  const getLabelsForContacts = (values: {facilityContacts: FacilityContactType[]}) => {
    const contactLabels =
      values?.facilityContacts?.map((contact, index) => {
        const targetRoleLabel = facilityContactRoleOptions.find((role) => role.value === contact?.facility_role)?.label;
        const contactRole = targetRoleLabel ? `(${targetRoleLabel})` : '';
        const label = `Contact ${index + 1} \u00b7 ${contact.person_name} ${contactRole}`;
        return label;
      }) ?? [];
    return contactLabels;
  };

  return (
    <div className="my-10">
      <Formik
        validateOnMount
        initialValues={{
          facilityContacts: facilityFormData.facilityContacts
        }}
        validationSchema={facilityContactsValidationSchema}
        onSubmit={onFacilityContactsSubmit}
      >
        {({isValid, values, errors}) => (
          <>
            <Form className="h-[520px]" noValidate>
              <FieldArray
                name="facilityContacts"
                render={({push, remove}) => (
                  <div className="flex h-full">
                    <div className="mr-3 flex-[1] bg-sw-background pt-5">
                      <FieldArraySideNav
                        title="Contact"
                        labels={getLabelsForContacts(values)}
                        activeIndex={currentFormIndex}
                        onActiveIndexChange={setCurrentFormIndex}
                        onAddNew={() => handleAddNew(push, values)}
                        isAddNewDisabled={!isValid}
                      />
                    </div>
                    <div className="flex-[4]">
                      <Card
                        draggableProvided={false}
                        title={`Contact ${currentFormIndex + 1}`}
                        actions={
                          <>
                            {values?.facilityContacts?.length > 1 ? (
                              <SvgIcon name="TrashOutlined" onClick={() => handleRemove(remove, values)} />
                            ) : null}
                          </>
                        }
                      >
                        <FacilityContactFields currentFormIndex={currentFormIndex} isEditable tabIndex={1} />
                      </Card>
                      <div className="mt-2 italic text-sw-disabled">
                        * Minimum of one contact is required per facility. Only one contact per facility can be set as
                        the primary contact.
                      </div>
                      {typeof errors?.facilityContacts === 'string' ? (
                        <div className="my-2 text-sw-error">{errors?.facilityContacts}</div>
                      ) : null}
                    </div>
                  </div>
                )}
              />
              <FacilityCreationModalFooter isValid={isValid} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ContactsForm;
