import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Button, Form, FormGroup, FormControl, ControlLabel, Image, HelpBlock, InputGroup} from 'react-bootstrap';
import {Link} from 'react-router';
import * as actions from '../actions/auth';
import * as userActions from '../actions/users';
import * as brokerActions from '../actions/brokers';

@connect(
  (state) => ({
    brokerLogos: state.brokers.brokerLogos,
    isVerifying: state.auth.isVerifying,
    error: state.users.error,
    details: state.users.details
  }),
  {...brokerActions, ...actions, ...userActions}
)
export default class SetPassword extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePasswordView = this.handlePasswordView.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.state = {
      error: {},
      password: '',
      showSuccess: false,
      viewPassword: false,
      requirements: {length: false, capital: false, special: false}
    };
  }

  handleSuccess() {
    if (!this.props.error) {
      this.setState({showSuccess: true});

      setTimeout(
        function () {
          this.context.router.push('/');
        }.bind(this),
        2000
      );
    }
  }

  onFormSubmit() {
    if (!this.state.requirements.special) {
      this.setState({
        error: {password: 'Password requires at least one special character'}
      });
    }
    if (!this.state.requirements.capital) {
      this.setState({
        error: {password: 'Password requires at least one captial letter'}
      });
    }
    if (!this.state.requirements.length) {
      this.setState({error: {password: 'Password is too short'}});
    }
    if (this.state.requirements.special && this.state.requirements.capital && this.state.requirements.length) {
      this.props
        .setPassword({
          token: this.props.location.query.token,
          password: this.state.password
        })
        .then((response) => {
          if (response.status === 200) {
            this.handleSuccess();
          } else {
            const errorMessage = {};
            if (response.field_errors) {
              errorMessage.error =
                response.field_errors[0].field_name + ': ' + response.field_errors[0].field_errors[0];
            } else {
              if (response.error_description && Array.isArray(response.error_description)) {
                errorMessage.password = response.error_description[0];
              } else {
                errorMessage.password = response.error_description;
              }
            }
            this.setState({
              error: errorMessage
            });
          }
        });
    }
  }

  handleChangePassword(e) {
    const value = e.target.value;
    const requirements = {length: false, capital: false, special: false};
    if (value.length >= 8) {
      requirements.length = true;
    }
    for (let i = 0; i < value.length; i++) {
      if (/[A-Z]/.test(value[i])) {
        requirements.capital = true;
      }
      if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@]/.test(value[i])) {
        requirements.special = true;
      }
    }
    this.setState({password: e.target.value, requirements: requirements});
  }

  getValidationState() {
    if (this.state.error.password) {
      return 'error';
    }
    return null;
  }

  handlePasswordView() {
    this.setState({viewPassword: !this.state.viewPassword});
  }

  render() {
    let stackedColorLogo = '';
    if (this.props.brokerLogos && this.props.brokerLogos.length > 0) {
      for (var i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'INLINE_COLOR') {
          stackedColorLogo = this.props.brokerLogos[i];
        }
      }
    }

    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div
              style={{
                textAlign: 'center'
              }}
            >
              {this.props.brokerLogos.length > 0 && (
                <Image
                  src={stackedColorLogo.logo + '?cachebust=' + new Date().getTime()}
                  height="80%"
                  width="80%"
                  style={{marginBottom: '15px'}}
                />
              )}
            </div>
            <div className="login-box-body">
              <h4 className="login-box-msg">Set Password</h4>

              <Form>
                <FormGroup validationState={this.getValidationState()}>
                  <ControlLabel>Password</ControlLabel>
                  <InputGroup>
                    <FormControl
                      type={this.state.viewPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.onFormSubmit();
                        }
                      }}
                    />
                    <InputGroup.Addon
                      onClick={() => {
                        this.handlePasswordView();
                      }}
                      className="password-selector"
                    >
                      {!this.state.viewPassword && <span className="text-danger">/</span>}
                      <i className="icon icon-Eye text-danger" />
                    </InputGroup.Addon>
                  </InputGroup>
                  {this.state.error.password && (
                    <HelpBlock className="control-label">
                      <i className="icon icon-Delayed" />
                      &nbsp;
                      <strong>{this.state.error.password}</strong>
                    </HelpBlock>
                  )}
                  {this.state.error.error && (
                    <HelpBlock className="control-label">
                      <i className="icon icon-Delayed" />
                      &nbsp;
                      <strong>{this.state.error.error}</strong>
                    </HelpBlock>
                  )}
                  <br />
                  <p>
                    <i
                      className={
                        'icon icon-' + (this.state.requirements.length ? 'Check text-success' : 'x-small text-danger')
                      }
                    />
                    &nbsp;Must be at least 8 characters
                  </p>
                  <p>
                    <i
                      className={
                        'icon icon-' + (this.state.requirements.capital ? 'Check text-success' : 'x-small text-danger')
                      }
                    />
                    &nbsp;At least 1 uppercase letter
                  </p>
                  <p>
                    <i
                      className={
                        'icon icon-' + (this.state.requirements.special ? 'Check text-success' : 'x-small text-danger')
                      }
                    />
                    &nbsp;At least 1 special character (e.g. !@#$%...)
                  </p>

                  {this.state.showSuccess && (
                    <HelpBlock className="control-label">
                      <strong>Password has been set. Please login with your username and new password.</strong>
                    </HelpBlock>
                  )}
                </FormGroup>

                <Row style={{textAlign: 'center'}}>
                  <Button onClick={this.onFormSubmit} bsStyle="primary">
                    Set Password
                  </Button>
                </Row>
              </Form>
              <p style={{marginTop: '10px'}}>
                Already have an account?
                <Link to="/">&nbsp;Sign in.</Link>
              </p>
              <p style={{marginTop: '10px'}}>
                Need help?
                <a href="mailto:support@shipwell.com">&nbsp;Contact support.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
