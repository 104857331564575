import {ConnectionCard} from './connectionCard/connectionCard';
import PageHeader from 'App/common/pageHeader';

export function MacropointDetailsPage() {
  return (
    <div className="w-full">
      <PageHeader title="Integrations" backRoute="/company/integrations" />

      <div className="flex flex-col items-center p-5">
        <div className="w-full max-w-[1600px]">
          <div className="flex flex-col gap-4">
            <ConnectionCard />
          </div>
        </div>
      </div>
    </div>
  );
}
