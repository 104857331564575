import {useEffect} from 'react';
import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {LoadboardType} from '@shipwell/tabula-sdk';

import useDeleteLoadboard from 'App/api/loadboards/hooks/useDeleteLoadboard';
import {UserData} from 'App/containers/integrations/details/components/truckstopDetails/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';

type DeactivateUserModalProps = {
  show: boolean;
  toggleShow: () => void;
  userData: UserData;
  setShowSpinner: (x: boolean) => void;
};
const DeactivateUserModal = ({
  show,
  toggleShow,
  userData,
  setSuccess,
  setError,
  setShowSpinner
}: DeactivateUserModalProps & WithStatusToastProps) => {
  const userName = `${userData.first_name} ${userData.last_name}`;

  const {mutate: deactivateUser, isLoading} = useDeleteLoadboard({
    onSuccess: () => {
      toggleShow();
      setSuccess('Success!', `You have deactivated ${userName} as a user for Truckstop.`);
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  useEffect(() => {
    setShowSpinner(isLoading);
  }, [isLoading, setShowSpinner]);

  const onPrimaryAction = () => {
    deactivateUser({
      loadboardType: LoadboardType.Truckstop,
      isUser: true,
      userId: userData.id
    });
  };

  return (
    <Modal
      show={show}
      portal
      className="top-1/2 mt-0 -translate-y-2/4"
      size="small"
      title="Deactivate Truckstop User"
      variant="warning"
      footerComponent={null}
      bodyClassName={classNames({'pointer-events-none': isLoading})}
      onClose={toggleShow}
    >
      <div>
        <div className="mb-4">
          Are you sure you want to deactivate user <span className="font-bold">{userName}</span>?
        </div>
        <div className="flex justify-end gap-2">
          <DeprecatedButton variant="secondary" type="button" onClick={toggleShow}>
            No, keep active
          </DeprecatedButton>
          <DeprecatedButton type="button" variant="warning" className="min-w-[166px]" onClick={onPrimaryAction}>
            {isLoading ? <SvgIcon name="LoadingDots" /> : 'Yes, deactivate user'}
          </DeprecatedButton>
        </div>
      </div>
    </Modal>
  );
};

export default WithStatusToasts(DeactivateUserModal);
