/* Constant values used in location lookup component */

export const stateProvinceOptions = [
  {value: {formatted_address: 'AL'}, label: 'Alabama - AL', type: 'state_province'},
  {value: {formatted_address: 'AK'}, label: 'Alaska - AK', type: 'state_province'},
  {value: {formatted_address: 'AZ'}, label: 'Arizona - AZ', type: 'state_province'},
  {value: {formatted_address: 'AR'}, label: 'Arkansas - AR', type: 'state_province'},
  {value: {formatted_address: 'CA'}, label: 'California - CA', type: 'state_province'},
  {value: {formatted_address: 'CO'}, label: 'Colorado - CO', type: 'state_province'},
  {value: {formatted_address: 'CT'}, label: 'Connecticut - CT', type: 'state_province'},
  {value: {formatted_address: 'DE'}, label: 'Delaware - DE', type: 'state_province'},
  {value: {formatted_address: 'FL'}, label: 'Florida - FL', type: 'state_province'},
  {value: {formatted_address: 'GA'}, label: 'Georgia - GA', type: 'state_province'},
  {value: {formatted_address: 'HI'}, label: 'Hawaii - HI', type: 'state_province'},
  {value: {formatted_address: 'ID'}, label: 'Idaho - ID', type: 'state_province'},
  {value: {formatted_address: 'IL'}, label: 'Illinois - IL', type: 'state_province'},
  {value: {formatted_address: 'IN'}, label: 'Indiana - IN', type: 'state_province'},
  {value: {formatted_address: 'IA'}, label: 'Iowa - IA', type: 'state_province'},
  {value: {formatted_address: 'KS'}, label: 'Kansas - KS', type: 'state_province'},
  {value: {formatted_address: 'KY'}, label: 'Kentucky - KY', type: 'state_province'},
  {value: {formatted_address: 'LA'}, label: 'Louisiana - LA', type: 'state_province'},
  {value: {formatted_address: 'ME'}, label: 'Maine - ME', type: 'state_province'},
  {value: {formatted_address: 'MD'}, label: 'Maryland - MD', type: 'state_province'},
  {value: {formatted_address: 'MA'}, label: 'Massachusetts - MA', type: 'state_province'},
  {value: {formatted_address: 'MI'}, label: 'Michigan - MI', type: 'state_province'},
  {value: {formatted_address: 'MN'}, label: 'Minnesota - MN', type: 'state_province'},
  {value: {formatted_address: 'MS'}, label: 'Mississippi - MS', type: 'state_province'},
  {value: {formatted_address: 'MO'}, label: 'Missouri - MO', type: 'state_province'},
  {value: {formatted_address: 'MT'}, label: 'Montana - MT', type: 'state_province'},
  {value: {formatted_address: 'NE'}, label: 'Nebraska - NE', type: 'state_province'},
  {value: {formatted_address: 'NV'}, label: 'Nevada - NV', type: 'state_province'},
  {value: {formatted_address: 'NH'}, label: 'New Hampshire - NH', type: 'state_province'},
  {value: {formatted_address: 'NJ'}, label: 'New Jersey - NJ', type: 'state_province'},
  {value: {formatted_address: 'NM'}, label: 'New Mexico - NM', type: 'state_province'},
  {value: {formatted_address: 'NY'}, label: 'New York - NY', type: 'state_province'},
  {value: {formatted_address: 'NC'}, label: 'North Carolina - NC', type: 'state_province'},
  {value: {formatted_address: 'ND'}, label: 'North Dakota - ND', type: 'state_province'},
  {value: {formatted_address: 'OH'}, label: 'Ohio - OH', type: 'state_province'},
  {value: {formatted_address: 'OK'}, label: 'Oklahoma - OK', type: 'state_province'},
  {value: {formatted_address: 'OR'}, label: 'Oregon - OR', type: 'state_province'},
  {value: {formatted_address: 'PA'}, label: 'Pennsylvania - PA', type: 'state_province'},
  {value: {formatted_address: 'RI'}, label: 'Rhode Island - RI', type: 'state_province'},
  {value: {formatted_address: 'SC'}, label: 'South Carolina - SC', type: 'state_province'},
  {value: {formatted_address: 'SD'}, label: 'South Dakota - SD', type: 'state_province'},
  {value: {formatted_address: 'TN'}, label: 'Tennessee - TN', type: 'state_province'},
  {value: {formatted_address: 'TX'}, label: 'Texas - TX', type: 'state_province'},
  {value: {formatted_address: 'UT'}, label: 'Utah - UT', type: 'state_province'},
  {value: {formatted_address: 'VT'}, label: 'Vermont - VT', type: 'state_province'},
  {value: {formatted_address: 'VA'}, label: 'Virginia - VA', type: 'state_province'},
  {value: {formatted_address: 'WA'}, label: 'Washington - WA', type: 'state_province'},
  {value: {formatted_address: 'WV'}, label: 'West Virginia - WV', type: 'state_province'},
  {value: {formatted_address: 'WI'}, label: 'Wisconsin - WI', type: 'state_province'},
  {value: {formatted_address: 'WY'}, label: 'Wyoming - WY', type: 'state_province'},
  // Canada provinces
  {value: {formatted_address: 'AB'}, label: 'Alberta - AB', type: 'state_province'},
  {value: {formatted_address: 'BC'}, label: 'British Columbia - BC', type: 'state_province'},
  {value: {formatted_address: 'MB'}, label: 'Manitoba - MB', type: 'state_province'},
  {value: {formatted_address: 'NB'}, label: 'New Brunswick - NB', type: 'state_province'},
  {value: {formatted_address: 'NL'}, label: 'Newfoundland and Labrador - NL', type: 'state_province'},
  {value: {formatted_address: 'NS'}, label: 'Nova Scotia - NS', type: 'state_province'},
  {value: {formatted_address: 'NT'}, label: 'Northwest Territories - NT', type: 'state_province'},
  {value: {formatted_address: 'NU'}, label: 'Nunavut - NU', type: 'state_province'},
  {value: {formatted_address: 'ON'}, label: 'Ontario - ON', type: 'state_province'},
  {value: {formatted_address: 'PE'}, label: 'Prince Edward Island - PE', type: 'state_province'},
  {value: {formatted_address: 'QC'}, label: 'Quebec - QC', type: 'state_province'},
  {value: {formatted_address: 'SK'}, label: 'Saskatchewan - SK', type: 'state_province'},
  {value: {formatted_address: 'YT'}, label: 'Yukon - YT', type: 'state_province'},
  // Mexico states
  {value: {formatted_address: 'AG'}, label: 'Aguascalientes - AG', type: 'state_province', validated: true},
  {value: {formatted_address: 'BC'}, label: 'Baja California - BC', type: 'state_province', validated: true},
  {value: {formatted_address: 'BS'}, label: 'Baja California Sur - BS', type: 'state_province', validated: true},
  {value: {formatted_address: 'CM'}, label: 'Campeche - CM', type: 'state_province', validated: true},
  {value: {formatted_address: 'CS'}, label: 'Chiapas - CS', type: 'state_province', validated: true},
  {value: {formatted_address: 'CH'}, label: 'Chihuahua - CH', type: 'state_province', validated: true},
  {value: {formatted_address: 'CO'}, label: 'Coahuila - CO', type: 'state_province', validated: true},
  {value: {formatted_address: 'CL'}, label: 'Colima - CL', type: 'state_province', validated: true},
  {value: {formatted_address: 'DF'}, label: 'Mexico City - DF', type: 'state_province', validated: true},
  {value: {formatted_address: 'DG'}, label: 'Durango - DG', type: 'state_province', validated: true},
  {value: {formatted_address: 'GT'}, label: 'Guanajuato - GT', type: 'state_province', validated: true},
  {value: {formatted_address: 'GR'}, label: 'Guerrero - GR', type: 'state_province', validated: true},
  {value: {formatted_address: 'HG'}, label: 'Hidalgo - HG', type: 'state_province', validated: true},
  {value: {formatted_address: 'JA'}, label: 'Jalisco - JA', type: 'state_province', validated: true},
  {value: {formatted_address: 'EM'}, label: 'México - EM', type: 'state_province', validated: true},
  {value: {formatted_address: 'MI'}, label: 'Michoacán - MI', type: 'state_province', validated: true},
  {value: {formatted_address: 'MO'}, label: 'Morelos - MO', type: 'state_province', validated: true},
  {value: {formatted_address: 'NA'}, label: 'Nayarit - NA', type: 'state_province', validated: true},
  {value: {formatted_address: 'NL'}, label: 'Nuevo León - NL', type: 'state_province', validated: true},
  {value: {formatted_address: 'OA'}, label: 'Oaxaca - OA', type: 'state_province', validated: true},
  {value: {formatted_address: 'PU'}, label: 'Puebla - PU', type: 'state_province', validated: true},
  {value: {formatted_address: 'QT'}, label: 'Quintana Roo - QR', type: 'state_province', validated: true},
  {value: {formatted_address: 'SL'}, label: 'San Luis Potosí - SL', type: 'state_province', validated: true},
  {value: {formatted_address: 'SI'}, label: 'Sinaloa - SI', type: 'state_province', validated: true},
  {value: {formatted_address: 'SO'}, label: 'Sonora - SO', type: 'state_province', validated: true},
  {value: {formatted_address: 'TB'}, label: 'Tabasco - TB', type: 'state_province', validated: true},
  {value: {formatted_address: 'TM'}, label: 'Tamaulipas - TM', type: 'state_province', validated: true},
  {value: {formatted_address: 'TL'}, label: 'Tlaxcala - TL', type: 'state_province', validated: true},
  {value: {formatted_address: 'VE'}, label: 'Veracruz - VE', type: 'state_province', validated: true},
  {value: {formatted_address: 'YU'}, label: 'Yucatán - YU', type: 'state_province', validated: true},
  {value: {formatted_address: 'ZA'}, label: 'Zacatecas - ZA', type: 'state_province', validated: true}
];

export const radius_options = [
  {value: 0, label: 'Specific location'},
  {value: 50, label: '+50 miles'},
  {value: 100, label: '+100 miles'},
  {value: 150, label: '+150 miles'},
  {value: 200, label: '+200 miles'},
  {value: 300, label: '+300 miles'}
];
