import {CustomField, CustomFieldFieldTypeEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, FormikTextInput, FormikCheckbox, FormikSelect, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import snakeCase from 'lodash/snakeCase';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {array, boolean, object, string} from 'yup';
import {ChangeEvent, ReactNode} from 'react';
import {CustomFieldPropertyTypes, EntityTypeGroupType, entityTypeGroups} from '../config';
import {CustomFieldAllowedValueInput} from './CustomFieldAllowedValueInput';

const customFieldTemplate: CustomField = {
  display_order: 0,
  field_type: 'STRING',
  label: '',
  name: '',
  allow_carrier_edit: false,
  allow_carrier_view: true,
  required: false,
  allowed_values: undefined,
  ui_enabled: true
};

const validationSchema = object({
  label: string().required('Field name is required'),
  required: boolean(),
  allow_carrier_edit: boolean(),
  allow_carrier_view: boolean(),
  field_type: string().oneOf(['STRING', 'SELECTION']),
  allowed_values: array().when('field_type', {
    is: 'SELECTION',
    then: array().required('At least one list item is required.').min(1, 'At least one list item is required.')
  })
});

const fieldTypeOptions = [
  {label: 'Text Field', value: CustomFieldFieldTypeEnum.String},
  {label: 'Single Select List', value: CustomFieldFieldTypeEnum.Selection}
];

const getInputComponent = (
  groupType: EntityTypeGroupType,
  selectedFieldType: CustomFieldFieldTypeEnum,
  setFieldValue: FormikHelpers<CustomField>['setFieldValue'],
  values: CustomField
): Record<CustomFieldPropertyTypes, ReactNode> => ({
  label: <Field name="label" label={`${entityTypeGroups[groupType].label} Field`} component={FormikTextInput} />,
  ui_enabled: <Field name="ui_enabled" label="Visible" component={FormikCheckbox} />,
  required: <Field name="required" label="Required" component={FormikCheckbox} />,
  allow_carrier_edit: (
    <Field
      className="whitespace-nowrap"
      name="allow_carrier_edit"
      label="Carrier edit"
      component={FormikCheckbox}
      value={values.allow_carrier_edit}
      disabled={!values.allow_carrier_view}
    />
  ),
  allow_carrier_view: (
    <Field
      className="whitespace-nowrap"
      name="allow_carrier_view"
      label="Carrier view"
      component={FormikCheckbox}
      checked={values.allow_carrier_view}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('allow_carrier_view', e.target.checked);
        if (!e.target.checked) {
          setFieldValue('allow_carrier_edit', false);
        }
      }}
    />
  ),
  field_type: (
    <div className="flex w-full items-center">
      <div className="w-1/2 pr-4">
        <Field
          name="field_type"
          label="Field type"
          simpleValue
          options={fieldTypeOptions}
          clearable={false}
          component={FormikSelect}
        />
      </div>
      {selectedFieldType === 'SELECTION' ? (
        <Tooltip
          placement="top"
          tooltipClassname="customData__section-tooltip"
          tooltipContent={
            <div className="w-60">
              To create a list enter each item separated by a semi-colon. User will be able to select one item from the
              list.
            </div>
          }
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      ) : null}
    </div>
  )
});

const inputLayout: Record<CustomFieldPropertyTypes, string> = {
  label: 'w-1/2 pr-4',
  ui_enabled: 'pr-4',
  required: 'pr-4',
  allow_carrier_edit: 'pr-4',
  allow_carrier_view: 'pr-4',
  field_type: 'w-full'
};

export const CustomFieldForm = ({
  groupType,
  customField,
  isSubmitting,
  onSubmit,
  onCancel
}: {
  groupType: EntityTypeGroupType;
  customField?: CustomField;
  isSubmitting: boolean;
  onSubmit: (customField: CustomField) => void;
  onCancel: () => void;
}) => {
  const entityTypeGroup = entityTypeGroups[groupType];

  const handleSubmit = (values: CustomField) => {
    onSubmit({
      ...customField,
      ...values,
      name: snakeCase(values.label),
      entity_types: entityTypeGroup.entityTypes
    });
  };

  return (
    <Formik
      initialValues={customField || customFieldTemplate}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({values, setFieldValue}) => (
        <Form noValidate className="flex flex-wrap gap-y-4 rounded bg-sw-background p-4" aria-label="Custom field">
          {entityTypeGroup.formFields.map((formField) => (
            <div key={formField} className={`${inputLayout[formField]}`}>
              {getInputComponent(groupType, values.field_type, setFieldValue, values)[formField]}
            </div>
          ))}
          {values.field_type === 'SELECTION' ? (
            <Field
              className="w-full"
              name="allowed_values"
              label="List Items"
              initialAllowedValues={customField?.allowed_values}
              component={CustomFieldAllowedValueInput}
            />
          ) : null}

          <div className="ml-auto flex items-center justify-end gap-4">
            <Button isCompact variant="tertiary" onClick={onCancel} size="sm">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} size="sm">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
