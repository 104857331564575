import {ChangeEvent} from 'react';
import classNames from 'classnames';
import {Button, Checkbox, FormikSelect, FormikTextInput, IconButton, TypedFileDisplay} from '@shipwell/shipwell-ui';
import {DocumentType as DocumentTypeEnum} from '@shipwell/settlements-sdk';
import {Field, Form, Formik} from 'formik';
import {object, string} from 'yup';
import {DocumentType} from '@shipwell/backend-core-singlerequestparam-sdk';
import {isDefined} from 'App/utils/guard';
import {getLastUrlSegment} from 'App/utils/fileUtilsTyped';
import Loader from 'App/common/shipwellLoader';
import {getDocumentTypesMap, useDocumentTypes} from 'App/data-hooks/documents/useDocumentTypes';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';

export type DocumentSelectDocument = {
  id: string;
  document_type?: string;
  public_url?: string;
  description?: string;
  created_at?: string;
};

export const TypedDocumentSelect = <TDocument extends DocumentSelectDocument>({
  documents,
  activeDocumentId,
  onDocumentClick,
  onEdit,
  editId,
  onEditSave,
  onEditCancel,
  isSaving,
  selectedDocuments,
  onSelectedDocumentsChange
}: {
  documents: TDocument[];
  activeDocumentId?: string;
  onDocumentClick?: (document?: TDocument) => void;
  onEdit?: (id: string) => void;
  editId?: string | null;
  onEditSave?: (document: TDocument) => void;
  onEditCancel?: (id: string) => void;
  isSaving?: boolean;
  selectedDocuments?: TDocument[];
  onSelectedDocumentsChange?: (selectedDocuments: TDocument[]) => void;
}) => {
  // useDocumentTypes is used in the child components. Lifting just the loading state up to the parent here to get
  // one loading spinner for the whole list instead of a spinner for each list item, which would be gross.
  const {isLoading: isDocumentTypesLoading} = useDocumentTypes();
  const selectedDocumentIds = selectedDocuments?.map((doc) => doc.id).filter(isDefined);

  const selectDocument = (document: TDocument) => {
    if (document.id && !selectedDocumentIds?.includes(document.id)) {
      const newSelectedDocuments = selectedDocuments ? [...selectedDocuments, document] : [document];
      onSelectedDocumentsChange?.(newSelectedDocuments);
    }
  };

  const unselectDocument = (document: TDocument) => {
    if (!selectedDocuments) return;
    onSelectedDocumentsChange?.(selectedDocuments.filter(({id}) => id !== document.id));
  };

  if (isDocumentTypesLoading) return <Loader loading />;

  return (
    <div className="pt-4">
      <ul>
        {documents.map((document) => {
          const isSelected = selectedDocuments?.some((doc) => doc.id === document.id);
          const isActive = activeDocumentId === document.id;

          const showEditButton = !!onEdit && !editId;

          return (
            <DocumentListItem
              key={document.id}
              document={document}
              isActive={isActive}
              showEditButton={showEditButton}
              editId={editId}
              onEdit={() => onEdit?.(document.id)}
              onEditSave={(updatedDocument) => onEditSave?.({...document, ...updatedDocument})}
              onEditCancel={() => onEditCancel?.(document.id)}
              isSaving={!!isSaving}
              isSelected={isSelected}
              isSelectable={!!onSelectedDocumentsChange}
              onIsSelectedChange={(isSelected) => {
                (isSelected ? selectDocument : unselectDocument)(document);
              }}
              onClick={() => onDocumentClick?.(document)}
            />
          );
        })}
      </ul>
    </div>
  );
};

const DocumentListItem = ({
  document,
  isActive,
  showEditButton,
  editId,
  onEdit,
  onEditCancel,
  onEditSave,
  isSaving,
  isSelected,
  isSelectable,
  onIsSelectedChange,
  onClick
}: {
  document: DocumentSelectDocument;
  isActive: boolean;
  showEditButton: boolean;
  editId?: string | null;
  onEdit: () => void;
  onEditCancel?: () => void;
  onEditSave: ((document: {document_type?: string; description?: string}) => void) | undefined;
  isSaving: boolean;
  isSelected?: boolean;
  isSelectable: boolean;
  onIsSelectedChange?: (isSelected: boolean) => void;
  onClick: () => void;
}) => {
  const documentTypes = getDocumentTypesMap(useDocumentTypes().data);

  const handleEditDocument = (document: DocumentSelectEditFormValues) => {
    onEditSave?.(document);
  };

  const label =
    documentTypes && document.document_type && document.document_type in documentTypes
      ? documentTypes[document.document_type]
      : document.document_type;

  const isEditing = editId === document.id;

  return (
    <li
      key={document.id}
      className={classNames('border-b-1 border-b-sw-divider p-2', {
        'bg-sw-background': isEditing
      })}
      aria-label={label}
    >
      {isEditing ? <div className="p-1 font-bold">Edit Document</div> : null}
      <div className="flex items-center gap-1 py-2">
        {isSelectable ? (
          <Checkbox
            checked={isSelected}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onIsSelectedChange?.(e.target.checked);
            }}
          />
        ) : null}
        <button
          onClick={onClick}
          aria-selected={isActive}
          className={classNames('text-xs text-left flex gap-1 grow overflow-hidden', {
            ['font-bold']: isActive
          })}
        >
          <div
            className={classNames('h-12 w-12 border-2 flex shrink-0 items-center justify-center overflow-hidden', {
              'border-sw-primary': isActive,
              'border-transparent': !isActive
            })}
          >
            <TypedFileDisplay
              maxHeight="2.5rem"
              maxWidth="2.5rem"
              fileURL={document.public_url}
              fileName={document.public_url ? getLastUrlSegment(document.public_url) : undefined}
              isThumbnail
              altText={document.description}
            />
          </div>
          <div className="shrink grow-0 overflow-hidden">
            <div className="whitespace-nowrap">{label}</div>
            <div className="truncate">{document.description || <>&nbsp;</>}</div>
            {document.created_at ? (
              <div className="whitespace-nowrap text-xxs font-normal">{formatDateTime(document.created_at)}</div>
            ) : null}
          </div>
        </button>
        {showEditButton ? <IconButton onClick={onEdit} iconName="Edit" aria-label="Edit document" /> : null}
      </div>
      {isEditing ? (
        <EditForm document={document} onCancel={onEditCancel} onSave={handleEditDocument} isSaving={isSaving} />
      ) : null}
    </li>
  );
};

const editDocumentSchema = object().shape({
  document_type: string().required('Document type is required.'),
  description: string().required('Description is required.')
});

export type DocumentSelectEditFormValues = {id: string; document_type?: string; description?: string};

const EditForm = ({
  document,
  onCancel,
  onSave,
  isSaving
}: {
  document: DocumentSelectEditFormValues;
  onCancel?: () => void;
  onSave: (formValues: DocumentSelectEditFormValues) => void;
  isSaving?: boolean;
}) => {
  const {data: documentTypesArray} = useDocumentTypes();

  return (
    <Formik initialValues={document} onSubmit={onSave} validationSchema={editDocumentSchema}>
      {({dirty}) => {
        return (
          <Form className="grid gap-4">
            <Field
              label="Document type"
              name="document_type"
              disabled={document.document_type === DocumentTypeEnum.Invoice}
              component={FormikSelect}
              options={documentTypesArray}
              simpleValue
              clearable={false}
              getOptionValue={(option: DocumentType) => option.id}
              getOptionLabel={(option: DocumentType) => option.name}
            />
            <Field label="Document description" name="description" component={FormikTextInput} />
            <div className="flex justify-end gap-4 pb-2">
              <Button variant="secondary" size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={isSaving || !dirty} isLoading={isSaving} type="submit" size="sm">
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
