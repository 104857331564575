import {ShipmentNote} from '@shipwell/backend-core-singlerequestparam-sdk';

import {formatDateTime} from 'App/utils/globals';
import {getMessageUserAvatarURL} from 'App/utils/userAvatar';

export type NoteDisplayEntryProps = {
  note: ShipmentNote;
};
export function NoteDisplayEntry({note}: NoteDisplayEntryProps) {
  const {message, creator_full_name, creator_avatar, updated_at} = note;
  const avatar = getMessageUserAvatarURL(creator_full_name ?? '', creator_avatar ?? '');
  return (
    <div className="my-1 flex flex-col whitespace-pre-line text-sm">
      <div>
        <span className="mr-2 font-bold">{creator_full_name}</span>
        <span className="text-sw-disabled-text">{formatDateTime(updated_at)}</span>
      </div>
      <div className="flex items-start">
        <span className="mr-2 py-2">
          <img className="size-5 rounded-full" src={avatar} alt="userAvatar" />
        </span>

        <span className="flex-1 break-words rounded bg-sw-background p-3">{message}</span>
      </div>
    </div>
  );
}
