import {ActionRefActionIdEnum, NoneTriggerTriggerTypeEnum, TimerTriggerTriggerTypeEnum} from '@shipwell/opus-sdk';

// Policy Types
export const POLICY_TYPE_ROUTING_GUIDE = 'ROUTING_GUIDE';
export const POLICY_TYPE_ORDER_CONSOLIDATION = 'ORDER_CONSOLIDATION';

// Table Types
export const TABLE_TYPE_ORDER_CONSOLIDATION = 'ORDER_CONSOLIDATION_WORKFLOWS_LIST';
export const TABLE_TYPE_WORKFLOW_ACTIVITY_LOG = 'WORKFLOW_ACTIVITY_LOG_LIST';

// Signal Types
export const SIGNAL_TYPE_SHIPMENT_CREATED = 'SHIPMENT_CREATED';
export const SIGNAL_TYPE_PURCHASE_ORDER_CREATED = 'PURCHASE_ORDER_CREATED';

// Workflow actions
export const WORKFLOW_ACTION_TENDER = 'TENDER';
export const WORKFLOW_ACTION_POST_TO_LOADBOARD = 'POST_TO_LOADBOARD';
export const WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS = 'CREATE_SPOT_NEGOTIATIONS';
export const WORKFLOW_ACTION_SEND_EMAIL = 'SEND_EMAIL';
export const WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER = 'CREATE_SHIPMENT_FROM_PURCHASE_ORDER';
export const WORKFLOW_ACTION_QUOTE_AND_TENDER_LTL = 'QUOTE_AND_TENDER_LTL';

export const JUNCTION_TYPES = {CONDITIONAL_SPLIT: 'CONDITIONAL_SPLIT'};

export const WORKFLOW_ACTION_OPTIONS = {
  [ActionRefActionIdEnum.QuoteAndTenderLtl]: {
    value: ActionRefActionIdEnum.QuoteAndTenderLtl,
    label: 'Quote & Tender',
    triggerType: NoneTriggerTriggerTypeEnum.None,
    inputs: [
      {
        name: 'shipment_id',
        data_type: 'String',
        is_required: true
      }
    ],
    outputs: [
      {
        name: 'shipment',
        data_type: 'Shipment'
      }
    ]
  },
  [ActionRefActionIdEnum.Tender]: {
    value: ActionRefActionIdEnum.Tender,
    label: 'Tender',
    triggerType: TimerTriggerTriggerTypeEnum.Timer,
    triggerExpirationField: 'expires_after_seconds',
    inputs: [
      {
        name: 'shipment_id',
        data_type: 'String',
        is_required: true
      }
    ],
    outputs: [
      {
        name: 'tender',
        data_type: 'Tender'
      }
    ]
  },
  [ActionRefActionIdEnum.SendEmail]: {
    value: ActionRefActionIdEnum.SendEmail,
    label: 'Email',
    triggerType: NoneTriggerTriggerTypeEnum.None,
    inputs: [
      {
        name: 'purchase_order',
        data_type: 'PurchaseOrder',
        is_required: false
      },
      {
        name: 'shipment_id',
        data_type: 'String',
        is_required: false
      }
    ]
  },
  [ActionRefActionIdEnum.PostToLoadboard]: {
    value: ActionRefActionIdEnum.PostToLoadboard,
    label: 'Add to Load Board',
    triggerType: TimerTriggerTriggerTypeEnum.Timer,
    triggerExpirationField: 'step_timer',
    inputs: [
      {
        name: 'shipment_id',
        data_type: 'String',
        is_required: true
      }
    ]
  },
  [ActionRefActionIdEnum.CreateSpotNegotiations]: {
    value: ActionRefActionIdEnum.CreateSpotNegotiations,
    label: 'Broadcast',
    triggerType: TimerTriggerTriggerTypeEnum.Timer,
    triggerExpirationField: 'step_timer',
    inputs: [
      {
        name: 'shipment_id',
        data_type: 'String',
        is_required: true
      }
    ]
  },
  [ActionRefActionIdEnum.CreateShipmentFromPurchaseOrder]: {
    value: ActionRefActionIdEnum.CreateShipmentFromPurchaseOrder,
    label: 'Create Shipment',
    triggerType: NoneTriggerTriggerTypeEnum.None,
    inputs: [
      {
        name: 'purchase_order',
        data_type: 'PurchaseOrder',
        is_required: true
      }
    ]
  }
};

export const WORKFLOW_OPTIONS_BY_POLICY_TYPE = {
  [POLICY_TYPE_ROUTING_GUIDE]: {
    workflowConditionalField: 'shipment_created',
    automatedExecutionSignalType: SIGNAL_TYPE_SHIPMENT_CREATED
  },
  [POLICY_TYPE_ORDER_CONSOLIDATION]: {
    workflowConditionalField: 'order_created',
    automatedExecutionSignalType: SIGNAL_TYPE_PURCHASE_ORDER_CREATED
  }
};

export const workflowFormSteps = [
  {
    title: 'DEFINE CRITERIA',
    explainer:
      'Your order criteria will define which orders are automatically pushed into the workflow you define on the next page.'
  },
  {
    title: 'CREATE WORKFLOW',
    explainer: 'The following workflow will run when an order matches the criteria defined on the previous page.'
  }
];

export const workflowToggles = {
  [POLICY_TYPE_ROUTING_GUIDE]: {
    fields: [
      {
        name: WORKFLOW_OPTIONS_BY_POLICY_TYPE?.[POLICY_TYPE_ROUTING_GUIDE]?.workflowConditionalField,
        label: 'Shipment Created'
      }
    ]
  },
  [POLICY_TYPE_ORDER_CONSOLIDATION]: {
    fields: [
      {
        name: WORKFLOW_OPTIONS_BY_POLICY_TYPE?.[POLICY_TYPE_ORDER_CONSOLIDATION]?.workflowConditionalField,
        label: 'Order Created'
      }
    ]
  }
};

export const workflowStatus = {
  [POLICY_TYPE_ROUTING_GUIDE]: {
    fields: [
      {
        name: 'status',
        label: 'Status'
      }
    ]
  },
  [POLICY_TYPE_ORDER_CONSOLIDATION]: {
    fields: [
      {
        name: 'status',
        label: 'Status'
      }
    ]
  }
};

export const workflowStart = {
  [POLICY_TYPE_ROUTING_GUIDE]: {
    fields: [
      {
        name: 'start_date',
        label: 'Workflow Start Date'
      }
    ]
  },
  [POLICY_TYPE_ORDER_CONSOLIDATION]: {
    fields: [
      {
        name: 'start_date',
        label: 'Workflow Start Date'
      }
    ]
  }
};

export const workflowEnd = {
  [POLICY_TYPE_ROUTING_GUIDE]: {
    fields: [
      {
        name: 'end_date',
        label: 'Workflow End Date'
      }
    ]
  },
  [POLICY_TYPE_ORDER_CONSOLIDATION]: {
    fields: [
      {
        name: 'end_date',
        label: 'Workflow End Date'
      }
    ]
  }
};

export const WORKFLOW_WARNING_OVER_CAPACITY = 'The origin on this shipment is over capacity.';
export const WORKFLOW_WARNING_OVER_CAPACITY_ON_EXECUTION =
  'The origin of this shipment will be over capacity when a carrier accepts a tender.';
export const ROUTING_GUIDE_SUCCESS_ON_EXECUTION =
  'Your guide is running and you will be notified when carriers accept or reject your shipment.';
