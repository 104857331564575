import PropTypes from 'prop-types';
import {Modal} from '@shipwell/shipwell-ui';
import View from './View';
import Edit from './Edit';

const ShipmentReps = ({editing, onCancelEdit, ...props}) => {
  return (
    <>
      <View {...props} />
      <Modal
        show={editing}
        title="Reps"
        footerComponent={null}
        onClose={onCancelEdit}
        bodyVariant="disableOverflowScroll"
      >
        <Edit onCancelEdit={onCancelEdit} {...props} />
      </Modal>
    </>
  );
};

ShipmentReps.propTypes = {
  editing: PropTypes.bool.isRequired,
  onCancelEdit: PropTypes.func
};

ShipmentReps.defaultProps = {
  editing: false,
  onCancelEdit: () => {}
};

export default ShipmentReps;
