import {PostCompaniesCompanyidUsers, User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {COMPANY_USERS} from '../queryKeys';
import {BackendCoreError, createUser} from 'App/api/user/typed';

export const useCreateUser = (
  companyId: string,
  options?: Omit<
    UseMutationOptions<User, BackendCoreError, {userDetails: PostCompaniesCompanyidUsers}, unknown>,
    'mutationFn'
  >
) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    ({userDetails}: {userDetails: PostCompaniesCompanyidUsers}) => createUser({companyId, body: userDetails}),
    {
      ...options,
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries([COMPANY_USERS]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {createUser: mutate, isCreatingUser: isLoading};
};
