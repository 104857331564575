import {Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Template} from '@shipwell/template-builder-sdk';
import {EditTemplateAction} from './EditTemplateAction/EditTemplateAction';
import {TemplateActionButton} from 'App/containers/userCompany/templates/actions/components/';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';

type TemplateActionsColumnProps = {
  template: Template;
};

export const TemplateActionsColumn = ({template}: TemplateActionsColumnProps) => {
  const editModal = useVisibility();
  const {pdfGeneratorApiForDocumentGeneration} = useFlags();

  return (
    <>
      <Dropdown
        variant="icon"
        size="small"
        indicator={false}
        icon={<SvgIcon name="Overflow" aria-label="tag actions" />}
      >
        {({onClick}: {onClick: () => void}) => (
          <>
            {pdfGeneratorApiForDocumentGeneration && (
              <TemplateActionButton
                onClick={() => {
                  onClick();
                  editModal.show();
                }}
              >
                Edit Settings
              </TemplateActionButton>
            )}
          </>
        )}
      </Dropdown>

      <EditTemplateAction open={editModal.isVisible} onClose={() => editModal.hide()} template={template} />
    </>
  );
};
