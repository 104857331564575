import {DrayageBooking, DrayageLegOverallStatus} from '@shipwell/corrogo-sdk';
import {useMutation} from '@tanstack/react-query';
import {cancelDrayageLeg} from 'App/api/corrogo/typed';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';

function useCancelLeg(legId: string) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DrayageBooking>();

  const cancelLegMutation = useMutation(
    () => cancelDrayageLeg(legId),
    createOptimisticUpdateHandlers([DRAYAGE_QUERY_KEY, legId], {
      mergeData: (leg) => {
        if (!leg) {
          return leg;
        }
        return {...leg, overall_status: DrayageLegOverallStatus.Cancelled};
      }
    })
  );

  return cancelLegMutation;
}

export default useCancelLeg;
