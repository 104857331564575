import {Fragment} from 'react';
import {FormControl, Button, InputGroup, FormGroup, Checkbox, OverlayTrigger, Popover} from 'react-bootstrap';
import moment from 'moment';
import './_notes.scss';
import Textarea from 'react-textarea-autosize';
import {formatDateTime} from 'App/utils/globals';

const Notes = (props) => {
  const {
    title,
    handleKeyPressNew,
    handleKeyPressEdit,
    inputChange,
    inputPlaceholder,
    inputTop,
    inputValue,
    primaryButton,
    noteClass,
    notes,
    secondaryButton,
    editNote,
    deleteNote,
    submitEdit,
    editValue,
    selectedNoteIdx,
    cancelEdit,
    pinNote,
    currentUser,
    getMoreNotes,
    mode
  } = props;

  const input = (handleKeyPressNew, inputChange, inputPlaceholder, inputValue, primaryButton, secondaryButton) => {
    return (
      <div className="notes-input-container">
        <Textarea
          value={inputValue}
          placeholder={inputPlaceholder}
          onChange={(event) => {
            inputChange(event);
          }}
          onKeyPress={(e) => {
            handleKeyPressNew(e);
          }}
          name="newNote"
        />

        <div className="button-container">
          {secondaryButton && (
            <Button
              bsStyle="default"
              onClick={() => {
                secondaryButton.action();
              }}
            >
              {secondaryButton.icon && <i className={'icon icon-' + secondaryButton.icon} />}
              {secondaryButton.icon ? ' ' : ''}
              {secondaryButton.label}
            </Button>
          )}
          {primaryButton && (
            <Button
              bsStyle="primary"
              onClick={() => {
                primaryButton.action();
              }}
            >
              {primaryButton.icon && <i className={'icon icon-' + primaryButton.icon} />}
              {primaryButton.icon ? ' ' : ''}
              {primaryButton.label}
            </Button>
          )}
        </div>
      </div>
    );
  };

  let myNotes = [];

  if (notes) {
    myNotes = notes.results;
  }
  //some special overrides when this component is used in cases using the MODE prop

  return (
    <div className={noteClass}>
      {title && <h2>{title}</h2>}

      {inputTop && input(handleKeyPressNew, inputChange, inputPlaceholder, inputValue, primaryButton, secondaryButton)}

      <div
        className="notes"
        onScroll={(e) => {
          const notesContainer = e.target;
          if (notesContainer.scrollTop >= notesContainer.scrollHeight - notesContainer.offsetHeight) {
            getMoreNotes();
          }
        }}
      >
        {myNotes && myNotes.length > 0 ? (
          <Fragment>
            {myNotes.map((note, i) => {
              return (
                <div
                  key={i}
                  className={
                    'note-container' + (note.is_pinned ? ' pinned' : '') + (selectedNoteIdx === note.id ? ' edit' : '')
                  }
                >
                  {selectedNoteIdx !== note.id && (
                    <Fragment>
                      <div className="message">
                        <span>
                          {mode === 'case'
                            ? note.created_by_user.first_name + ' ' + note.created_by_user.last_name
                            : note.creator_full_name}{' '}
                        </span>
                        <span>
                          {formatDateTime(note.updated_at, true, moment.tz.guess())}
                          &nbsp;&nbsp;
                        </span>
                        <span>{mode === 'case' ? note.note : note.message}</span>
                      </div>
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement={i === 0 ? 'bottom' : 'top'}
                        overlay={
                          <Popover id="shipment-tags-actions">
                            {mode !== 'case' && (
                              <div>
                                <Button
                                  bsStyle="link"
                                  onClick={() => {
                                    pinNote(note);
                                    document.body.click();
                                  }}
                                >
                                  {note.is_pinned ? 'Unpin' : 'Pin'}
                                </Button>
                              </div>
                            )}
                            <div>
                              <Button
                                bsStyle="link"
                                onClick={() => {
                                  editNote(note);
                                  document.body.click();
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                            <div>
                              <Button
                                bsStyle="link"
                                onClick={() => {
                                  deleteNote(note.id);
                                  document.body.click();
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Popover>
                        }
                      >
                        <div
                          className="context-menu"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <span />
                          <span />
                          <span />
                        </div>
                      </OverlayTrigger>
                    </Fragment>
                  )}
                  {selectedNoteIdx === note.id && (
                    <Fragment>
                      <FormGroup>
                        <Textarea
                          value={editValue}
                          onChange={(event) => {
                            inputChange(event);
                          }}
                          onKeyPress={(e) => {
                            handleKeyPressEdit(e, note.id);
                          }}
                          name="editNote"
                        />
                      </FormGroup>
                      <div className="notes__actions">
                        <Button onClick={() => submitEdit(note.id)} bsStyle="primary">
                          Save
                        </Button>

                        <Button
                          onClick={() => {
                            cancelEdit();
                          }}
                          bsStyle="default"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Fragment>
                  )}
                </div>
              );
            })}
            {mode === 'case' && <div className="notes__dummy" />}
          </Fragment>
        ) : (
          <h3 className="no-note">No notes</h3>
        )}
      </div>

      {!inputTop && input(handleKeyPressNew, inputChange, inputPlaceholder, inputValue, primaryButton, secondaryButton)}
    </div>
  );
};

export default Notes;
