import {EquipmentType, ShipmentMode, EquipmentTypeValues} from '@shipwell/backend-core-sdk';
import {checkShipmentModes} from 'App/utils/globalsTyped';

const drayageEquipmentTypeNames = [
  EquipmentTypeValues.ContainerBreakBulk,
  EquipmentTypeValues.ContainerFlatTrack20Foot,
  EquipmentTypeValues.ContainerHighCube20Foot,
  EquipmentTypeValues.ContainerFlatTrack40Foot,
  EquipmentTypeValues.ContainerHeavyTested20Foot,
  EquipmentTypeValues.ContainerHighCube40Foot,
  EquipmentTypeValues.ContainerOpenTop20Foot,
  EquipmentTypeValues.ContainerOpenTop40Foot,
  EquipmentTypeValues.ContainerReefer20Foot,
  EquipmentTypeValues.ContainerReefer40Foot,
  EquipmentTypeValues.ContainerStandard20Foot,
  EquipmentTypeValues.ContainerStandard40Foot,
  EquipmentTypeValues.ContainerHighCube45Foot,
  EquipmentTypeValues.ContainerIsoTank20Foot,
  EquipmentTypeValues.ContainerIsoTank24Foot,
  EquipmentTypeValues.ContainerIsoTank40Foot,
  EquipmentTypeValues.Container53Foot,
  EquipmentTypeValues.ContainerOversize,
  EquipmentTypeValues.ContainerReeferTank20Foot,
  EquipmentTypeValues.TboxRailcar,
  EquipmentTypeValues.CryoRailcar,
  EquipmentTypeValues.CenterbeamFlatcar,
  EquipmentTypeValues.Boxcar
];

export const drayageEquipmentTypesFilter = (equipmentType: EquipmentType) =>
  drayageEquipmentTypeNames.some((drayageEquipmentType) => drayageEquipmentType === equipmentType.machine_readable);

export const equipmentTypesByModeFilter = (mode?: ShipmentMode) => (equipmentType: EquipmentType) => {
  const {hasDrayage, hasIntermodal, hasFTL} = checkShipmentModes(mode);
  if (hasDrayage || hasIntermodal) {
    return drayageEquipmentTypesFilter(equipmentType);
  }
  if (hasFTL) {
    return true;
  }
  return equipmentType.machine_readable !== EquipmentTypeValues.MultiCompartment;
};
