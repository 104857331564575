import {Modal} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import useSpotNegotiationQuoteDocument from './hooks/useSpotNegotiationQuoteDocument';
import {DocumentDisplay, DocumentMetadata, DocumentActions} from 'App/components/DocumentView';
import ShipwellLoader from 'App/common/shipwellLoader';

export const QuoteDocumentModal = ({
  spotNegotiationId,
  spotNegotiationQuoteId,
  spotNegotiationQuoteDocumentId,
  shipperView = false,
  onClose
}: {
  spotNegotiationId?: string | null;
  spotNegotiationQuoteId?: string | null;
  spotNegotiationQuoteDocumentId?: string;
  shipperView?: boolean;
  onClose: () => void;
}) => {
  const {spotNegotiationQuoteDocument} = useSpotNegotiationQuoteDocument({
    spotNegotiationId,
    spotNegotiationQuoteId,
    spotNegotiationQuoteDocumentId,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  });

  const documentType = startCase(spotNegotiationQuoteDocument?.type);
  const fileType =
    get(spotNegotiationQuoteDocument, 'filename', '').slice(-4).toLowerCase() === '.pdf' ? 'pdf' : undefined;

  return (
    <Modal
      className="h-screen-85"
      show={!!spotNegotiationQuoteDocumentId}
      title="Document Preview"
      onClose={onClose}
      size="large"
      footerComponent={null}
    >
      <div className="flex h-full">
        {spotNegotiationQuoteDocument ? (
          <>
            {shipperView ? (
              <DocumentMetadata document={spotNegotiationQuoteDocument} documentType={documentType} />
            ) : null}
            <DocumentDisplay document={spotNegotiationQuoteDocument} fileType={fileType} />
            <DocumentActions
              document={spotNegotiationQuoteDocument}
              fileType={fileType}
              showPrintDocument={shipperView}
            />
          </>
        ) : (
          <ShipwellLoader loading />
        )}
      </div>
    </Modal>
  );
};

export default QuoteDocumentModal;
