import {useState} from 'react';
import {StepIndicator} from '@shipwell/shipwell-ui';
import {CreateSupplier, CreateSupplierUser} from '@shipwell/backend-core-sdk';
import classNames from 'classnames';
import {browserHistory} from 'react-router';
import {NewSupplierForm} from '../forms/NewSupplierForm';
import {NewSupplierContactsForm} from '../forms/NewSupplierContactsForm';
import {useCreateSupplier, useCreateSupplierUsers} from 'App/data-hooks/suppliers';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {parseV3ApiError} from 'App/api/typedUtils';

export const CreateSupplierSteps = ({
  onClose,
  setError
}: {
  onClose: () => void;
  setError: WithStatusToastProps['setError'];
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [supplier, setSupplier] = useState<CreateSupplier | undefined>();

  const {createSupplierUsers, isCreatingUsers} = useCreateSupplierUsers();
  const {createSupplier, isCreatingSupplier} = useCreateSupplier();

  const handleNext = (supplier: CreateSupplier) => {
    setSupplier(supplier);
    setActiveStep(activeStep + 1);
  };

  const handleSubmit = (users: CreateSupplierUser[]) => {
    createSupplier(supplier, {
      onError: (error) => {
        // if creating the supplier fails, we won't create any users and we'll keep them on this page
        const {title, detail} = parseV3ApiError(error);
        setError(title, detail);
      },
      onSuccess({id: supplierId}) {
        createSupplierUsers(
          {supplierId, users},
          {
            onError: (error) => {
              // Unfortunately this error toast will unmount as soon as the page navigates away. That's just one of the
              // problems with not having a global toasts provider. See FUTR-168 and FUTR-172.
              const {title, detail} = parseV3ApiError(error);
              setError(title, detail);
            },
            onSettled: () => {
              // If creating the supplier succeeds but creating the users fails, we'll still take them to the supplier page
              // so they can see the supplier they created and try to create the users again
              onClose();
              browserHistory.push(`/suppliers/${supplierId}`);
            }
          }
        );
      }
    });
  };

  const steps = [
    <NewSupplierForm key="NewSupplierForm" supplier={supplier} onCancel={onClose} onNext={handleNext} />,

    <NewSupplierContactsForm
      key="NewSupplierContactsForm"
      onSubmit={handleSubmit}
      isSubmitting={isCreatingUsers || isCreatingSupplier}
      onBack={() => setActiveStep(activeStep - 1)}
      onCancel={onClose}
    />
  ];

  const hasSidebar = activeStep === 1;

  return (
    <div className={classNames('py-6 pr-6', {'pl-6': !hasSidebar})}>
      <StepIndicator steps={['SUPPLIER INFO', 'CONTACTS']} activeStep={activeStep} alternativeLabel />
      <div className="mt-6">{steps[activeStep]}</div>
    </div>
  );
};
