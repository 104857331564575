import {ReactNode, useState, MouseEvent, useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';

import {IconButton, SvgIconName} from '@shipwell/shipwell-ui';

import './styles.scss';

export type TabbedSlidingDrawerProps = {
  tabs: {
    key: string;
    iconName: SvgIconName;
    content: (props: TabContentProps) => ReactNode;
  }[];
  coverShim: boolean;
  selectedTab?: string | null;
  onTabSelect?: (key: string | null) => unknown;
  onOpen?: () => unknown;
  onClose?: () => unknown;
  className?: string;
};

export type TabContentProps = {
  selected: boolean;
  close: () => void;
};

/** Ensure that clicks on the side panel don't get conducted through anywhere else. */
const swallowMouseEvent = (e: MouseEvent) => {
  e.stopPropagation();
};

export function TabbedSlidingDrawer(props: TabbedSlidingDrawerProps) {
  const {tabs, onTabSelect, onOpen, onClose, coverShim, className} = props;
  const [selectedTabKey, setSelectedTabKey] = useState(props.selectedTab ?? null);
  const innerRef = useRef({everExtended: false, isMounted: false});

  useEffect(() => {
    innerRef.current.isMounted = true;
    return () => {
      // Disabling lint noise because the whole point of `useRef` is that `innerRef` will stay valid.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      innerRef.current.isMounted = false;
    };
  });

  useEffect(() => {
    if (props.selectedTab !== undefined && props.selectedTab !== selectedTabKey) {
      setSelectedTabKey(props.selectedTab);
    }
  }, [props.selectedTab, selectedTabKey, setSelectedTabKey]);

  const onTabClickHandler = useCallback(
    (clickEvent: MouseEvent): void => {
      if (!innerRef.current.isMounted) {
        return;
      }
      const el = clickEvent.currentTarget as HTMLButtonElement;
      const key = el.dataset.key ?? '';
      if (key === selectedTabKey) {
        setSelectedTabKey(null);
        onTabSelect?.(null);
      } else {
        setSelectedTabKey(key);
        onTabSelect?.(key);
      }
    },
    [onTabSelect, selectedTabKey]
  );

  const onClickAway = useCallback(() => {
    setSelectedTabKey(null);
    onTabSelect?.(null);
  }, [onTabSelect]);

  useEffect(() => {
    if (!innerRef.current.isMounted) {
      return;
    }
    if (!selectedTabKey && !innerRef.current.everExtended) {
      return;
    }
    if (selectedTabKey) {
      innerRef.current.everExtended = true;
      onOpen?.();
      onTabSelect?.(selectedTabKey);
    } else {
      onClose?.();
    }
  }, [selectedTabKey, onClose, onOpen, onTabSelect]);
  const selectedTab = tabs.find((tab) => tab.key === selectedTabKey);
  return (
    <div className="sw-tabbed-sliding-drawer-anchor absolute inset-y-0 right-0 z-50 w-0 overflow-visible">
      {selectedTabKey && coverShim ? (
        <div className="sw-tabbed-sliding-drawer-cover-shim fixed inset-0 bg-sw-boxShadow" onClick={onClickAway} />
      ) : null}
      <div
        className={classNames(
          'sw-tabbed-sliding-drawer',
          'flex flex-row absolute inset-y-0 right-0',
          'bg-sw-background-component drop-shadow-2xl rounded-l-lg',
          'overflow-hidden whitespace-nowrap',
          selectedTabKey ? 'sw-tabbed-sliding-drawer-extended' : 'sw-tabbed-sliding-drawer-unextended',
          className
        )}
        onClick={swallowMouseEvent}
      >
        <div className="sw-tabbed-sliding-drawer__shim absolute inset-0 flex h-full flex-row">
          <div
            className={classNames(
              'sw-tabbed-sliding-drawer__tabs',
              'flex flex-0 flex-col h-full w-12 justify-self-stretch border-r-1 border-sw-divider content-around'
            )}
          >
            {tabs.map((tab) => (
              <div key={tab.key} className="p-2">
                <IconButton
                  data-key={tab.key}
                  color={tab.key === selectedTabKey ? 'sw-focused' : 'sw-icon'}
                  iconName={tab.iconName}
                  aria-label={tab.key}
                  onClick={onTabClickHandler}
                />
              </div>
            ))}
          </div>
          <div className="sw-tabbed-sliding-drawer-content flex flex-1">
            {selectedTab?.content({selected: selectedTab.key === selectedTabKey, close: onClickAway})}
          </div>
        </div>
      </div>
    </div>
  );
}
