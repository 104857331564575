import {useQueryClient, useIsFetching} from '@tanstack/react-query';
import {ShipmentCurrentWorkflowExecutionInfo} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {WorkflowExecutionInstanceStatusEnum} from '@shipwell/opus-sdk';
import {WorkflowTooltip} from 'App/containers/Dashboard/components/Columns/WorkflowTooltip';
import {WORKFLOW_EXECUTIONS} from 'App/data-hooks/queryKeys';
import {startCaseToLower} from 'App/utils/startCaseToLower';

export const WorkflowsCell = ({shipmentId}: {shipmentId: string}) => {
  const queryClient = useQueryClient();
  // returns the number of queries that are fetching
  const isFetching = useIsFetching([WORKFLOW_EXECUTIONS, 'list']) > 0;

  // getQueriesData returns an array of queries from each dashboard page
  const currentWorkflowExecutionQueries = queryClient.getQueriesData([WORKFLOW_EXECUTIONS, 'list']);
  // find the execution we need based on shipmentId
  const currentWorkflowExecution = currentWorkflowExecutionQueries.reduce(
    (result: ShipmentCurrentWorkflowExecutionInfo | undefined, executionsQuery) => {
      const [, queryData] = executionsQuery;
      const typedQueryData = queryData as ShipmentCurrentWorkflowExecutionInfo[];
      if (!typedQueryData?.length) {
        return;
      }
      const execution = typedQueryData.find((typedData) => typedData.shipment_id === shipmentId);
      if (execution) {
        return (result = execution);
      }
      return result;
    },
    {} as ShipmentCurrentWorkflowExecutionInfo
  );

  const {workflow_execution_info: workflowExecutionInfo} = currentWorkflowExecution || {};
  const {
    workflow_name: workflowName,
    current_status: currentStatus,
    end_time: endTime,
    current_step: currentStep,
    start_time: startTime,
    workflow_execution_id: workflowExecutionId
  } = workflowExecutionInfo || {};

  const getFinalStatus = () => {
    if (!workflowExecutionInfo) return '--';
    if (workflowExecutionInfo.current_status === 'SUCCESS') {
      return 'Complete';
    }
    return startCaseToLower(workflowExecutionInfo.current_status);
  };

  const status = getFinalStatus();

  return (
    <div className="relative">
      {isFetching ? (
        <div className="absolute inset-0 flex w-full items-center justify-center">
          <SvgIcon name="LoadingDots" />
        </div>
      ) : workflowExecutionInfo ? (
        <div className={classNames('transition-opacity', {['opacity-30']: isFetching})}>
          <Tooltip
            placement="left"
            portal
            tooltipContent={
              <WorkflowTooltip
                name={workflowName}
                status={currentStatus as WorkflowExecutionInstanceStatusEnum}
                endDate={endTime}
                currentStep={currentStep}
                startTime={startTime}
                workflowExecutionId={workflowExecutionId}
              />
            }
          >
            <span className={classNames({['text-sw-error']: status === 'Failed'})}>{status}</span>
          </Tooltip>
        </div>
      ) : (
        '--'
      )}
    </div>
  );
};
