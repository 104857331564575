import {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, SubmissionError} from 'redux-form';
import {Form, Button} from 'react-bootstrap';
import {putCarrierRelationshipsCarrierRelationshipId} from 'App/actions/_vendors';
import RemitToFields from 'App/containers/Book/FormSections/RemitTo';
import {unpackErrors, validateEmail} from 'App/utils/globals';

class ShipmentRemitTo extends Component {
  async updateCarrierRelationship(values = {}) {
    const {dispatch, closeForm} = this.props;
    const relationshipId = values.id;

    try {
      const response = await dispatch(putCarrierRelationshipsCarrierRelationshipId(relationshipId, values));

      if (response.status === 200) {
        closeForm();
      } else {
        const errors = response.field_errors || [];
        const submissionError = unpackErrors(errors, {});
        submissionError._error = response.error_description;

        throw new SubmissionError(submissionError);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {handleSubmit, submitting, closeForm} = this.props;

    return (
      <div className="shipment__financials">
        <Form onSubmit={handleSubmit(this.updateCarrierRelationship.bind(this))} className="shipment-billto-form p-4">
          <RemitToFields />
          <div className="update-footer show-flex">
            <Button
              bsStyle="default"
              type="button"
              disabled={submitting}
              onClick={closeForm}
              className="cancel-edit"
              name="cancel-edit"
            >
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" bsStyle="primary" name="submit-billto">
              {submitting ? (
                <span>
                  <i className="icon icon-Restart rotate" /> Saving...
                </span>
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

ShipmentRemitTo = reduxForm({
  form: 'remitToForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  touchOnChange: true,
  validate: function (values) {
    const errors = {};

    if (values.vendor_factoring_company) {
      const factoringCompanyErrors = {};

      if (!values.vendor_factoring_company.company_name) {
        factoringCompanyErrors.company_name = 'Enter a company name';
      }
      if (values.vendor_factoring_company.email && !validateEmail(values.vendor_factoring_company.email)) {
        factoringCompanyErrors.email = 'Invalid email';
      }
      if (!values.vendor_factoring_company.address || !values.vendor_factoring_company.address.formatted_address) {
        factoringCompanyErrors.address = 'Enter a valid address';
      }
      if (Object.keys(factoringCompanyErrors).length) {
        errors.vendor_factoring_company = factoringCompanyErrors;
      }
    }
    return errors;
  }
})(ShipmentRemitTo);

ShipmentRemitTo = connect((state) => ({
  one: state.shipmentdetails.one
}))(ShipmentRemitTo);

export default ShipmentRemitTo;
