import {useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import WorkflowSummaryCard from '../WorkflowSummaryCard';
import ShipwellLoader from 'App/common/shipwellLoader';
import useWorkflowDetails from 'App/utils/hooks/useWorkflowDetails';
import WithStatusToasts from 'App/components/withStatusToasts';
import {initiateRoutingGuide} from 'App/api/shipment';

const ConflictingWorkflows = ({errorDetails, onSelectRoutingGuide, shipmentId, setError}) => {
  const [loadingId, setLoadingId] = useState('');
  // set is to insure each id is unique
  const workflowIds = [...new Set(errorDetails?.matched_workflows?.map((matchedWorkflow) => matchedWorkflow.id))];
  const matchedWorkflowDetails = useWorkflowDetails(workflowIds);

  const initiateRoutingGuideMutation = useMutation(
    (workflowDetailId) => initiateRoutingGuide(shipmentId, {workflow: workflowDetailId}),
    {
      onSuccess: () => onSelectRoutingGuide(shipmentId),
      onError: () => {
        setLoadingId('');
        setError('Error!', 'There was an error running the workflow.');
      }
    }
  );

  const handleSelectWorkflow = (selectedWorkflowId) => {
    setLoadingId(selectedWorkflowId);
    initiateRoutingGuideMutation.mutate(selectedWorkflowId);
  };

  const matchedWorkflowKeys = Object.keys(matchedWorkflowDetails);
  return (
    <>
      <div className={`flex items-center gap-2 rounded-md bg-sw-error-background p-2 pl-0 text-sw-error`}>
        <SvgIcon width="3rem" name="InfoFilled" />
        <span className="font-bold text-sw-text">
          There are conflicting workflows for this shipment. Please select the one you&apos;d like to run.
        </span>
      </div>
      {matchedWorkflowKeys?.length === 0 ? (
        <div className="flex items-center justify-center pt-2">
          <ShipwellLoader loading />
        </div>
      ) : null}
      {matchedWorkflowKeys?.map((workflowDetailId) => (
        <WorkflowSummaryCard
          key={workflowDetailId}
          summary={{
            workflow_id: workflowDetailId,
            id: matchedWorkflowDetails[workflowDetailId].id,
            version_number: matchedWorkflowDetails[workflowDetailId].version_number
          }}
          action={
            <DeprecatedButton
              onClick={() => handleSelectWorkflow(workflowDetailId)}
              className="whitespace-nowrap"
              loading={loadingId === workflowDetailId}
              disabled={loadingId !== ''}
            >
              Run Workflow
            </DeprecatedButton>
          }
          isMobileView
        />
      ))}
    </>
  );
};

ConflictingWorkflows.propTypes = {
  errorDetails: PropTypes.shape({
    matched_workflows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    )
  }),
  onSelectRoutingGuide: PropTypes.func,
  shipmentId: PropTypes.string,
  setError: PropTypes.func
};

export default compose(WithStatusToasts)(ConflictingWorkflows);
