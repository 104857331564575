import axios from 'axios';
import {browserHistory} from 'react-router';
import {
  REQ_DATA,
  SELECTED_DOCUMENT,
  DOCUMENT_ADD,
  NEW_CUSTOMER_DOCUMENT_ADD,
  FETCH_ALL_DOCUMENTS,
  FETCH_DOCUMENT,
  CREATE_DOCUMENT,
  ERROR_DOCUMENTS,
  ALERT_ERROR,
  FETCH_DOCUMENT_TYPES,
  UPDATE_DOCUMENT,
  FETCH_DOC_AUDIT_LOG,
  CREATE_CUSTOMER_DOCUMENT,
  UPDATE_CUSTOMER_DOCUMENT,
  FETCH_CARRIER_DOCUMENTS,
  UPDATE_CARRIER_DOCUMENT
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi, basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

// starts the Loading... spinner
export function requestData() {
  return {type: REQ_DATA};
}

export function selectDocEdit(doc) {
  return (dispatch) => {
    dispatch({type: SELECTED_DOCUMENT, payload: doc});
  };
}

export function selectDocAdd() {
  return (dispatch) => {
    dispatch({type: DOCUMENT_ADD});
  };
}

export function selectNewCustomerDocAdd() {
  return (dispatch) => {
    dispatch({type: NEW_CUSTOMER_DOCUMENT_ADD});
  };
}

/* ------------ documents GET ALL --------------- */
export function fetchDocuments(shipmentId, opts) {
  return (dispatch) => {
    return fetchDocumentsPromise(shipmentId, opts)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_DOCUMENTS,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function fetchDocumentsPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ document GET --------------- */
export function fetchDocument(shipmentId, documentId) {
  return (dispatch) => {
    return fetchDocumentPromise(shipmentId, documentId)
      .then((response) => {
        dispatch({
          type: FETCH_DOCUMENT,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function fetchDocumentPromise(shipmentId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdGet(shipmentId, documentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ document POST --------------- */
async function configDocumentPost(formProps, shipmentId) {
  const accessToken = await getAccessToken();
  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [
        function (data) {
          const isCarrierDocument = data.isCarrierDocument;
          const description = data.description;
          const type = data.type;
          const file = data.file[0] || data.file;

          const formData = new FormData();
          formData.append('is_carrier_documents', isCarrierDocument);
          formData.append('description', description);
          formData.append('type', type);
          formData.append('file', file);
          return formData;
        }
      ],
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.post(`${basePath}/shipments/${shipmentId}/documents/`, formProps, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

export function postDocument(formProps, shipmentId) {
  return (dispatch) => {
    dispatch(requestData());
    return configDocumentPost(formProps, shipmentId)
      .then((response) => {
        dispatch({type: CREATE_DOCUMENT, payload: response.data});
        browserHistory.push(`/shipments/${shipmentId}`);
      })
      .catch((response) => {
        dispatch({type: ERROR_DOCUMENTS, payload: response.data});
      });
  };
}

//NEW CREATE DOCUMENT

export function createDocument(shipmentId, formValues) {
  const isCarrierDocument = formValues.isCarrierDocument;
  const description = formValues.description;
  const type = formValues.type;
  const file = formValues.file[0] || formValues.file;
  return (dispatch) => {
    return createDocumentPromise(shipmentId, file, type, description, isCarrierDocument)
      .then((response) => {
        dispatch({type: CREATE_DOCUMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function createDocumentPromise(shipmentId, file, type, description, isCarrierDocument) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsPost(
      shipmentId,
      file,
      type,
      description,
      isCarrierDocument,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* ------------ document DELETE --------------- */
export function deleteDocument(shipmentId, documentId) {
  return (dispatch) => {
    return deleteDocumentPromise(shipmentId, documentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteDocumentPromise(shipmentId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdDelete(shipmentId, documentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ document types --------------- */
export function fetchDocumentTypes() {
  return (dispatch) => {
    return fetchDocumentTypesPromise()
      .then((response) => {
        dispatch({type: FETCH_DOCUMENT_TYPES, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function fetchDocumentTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.documentsDocumentTypesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export function putDocument(shipmentId, documentId, body) {
  return (dispatch) => {
    return putDocumentPromise(shipmentId, documentId, body)
      .then((response) => {
        dispatch({type: UPDATE_DOCUMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function putDocumentPromise(shipmentId, documentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsDocumentIdPut(shipmentId, documentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ generate_bol GET --------------- */
export function generateBOL(shipmentId) {
  return (dispatch) => {
    return generateBOLPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function generateBOLPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsGenerateBolGet(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ generate_bol GET --------------- */
export function generateRateConfirmation(shipmentId) {
  return (dispatch) => {
    return generateRateConfirmationPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function generateRateConfirmationPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsGenerateRateConfirmationGet(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ generate_bol GET --------------- */
export function shareDocument(shipmentId, body) {
  return (dispatch) => {
    return shareDocumentPromise(shipmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function shareDocumentPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsSendPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ Shipment Document Auditlog GET --------------- */
export function getDocAuditLog(shipmentId) {
  return (dispatch) => {
    return getDocAuditLogPromise(shipmentId)
      .then((response) => {
        dispatch({type: FETCH_DOC_AUDIT_LOG, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function getDocAuditLogPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdDocumentsAuditlogGet(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/* ------------ Customer Documents --------------- */

export function fetchCustomerDocuments(brokerageId, shipperRelationshipId, opts) {
  opts = opts || {};

  return (dispatch) => {
    return fetchCustomerDocumentsPromise(brokerageId, shipperRelationshipId, opts)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_DOCUMENTS,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function fetchCustomerDocumentsPromise(brokerageId, shipperRelationshipId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsGet(
      brokerageId,
      shipperRelationshipId,
      opts,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* customer docs POST */
async function configCustomerDocumentPost(formProps, brokerageId, shipperRelationshipId) {
  const accessToken = await getAccessToken();
  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [
        function (data) {
          const description = data.description;
          const file = data.file[0] || data.file;

          const formData = new FormData();
          formData.append('description', description);
          formData.append('file', file);
          return formData;
        }
      ],
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.post(
      `${basePath}/brokerages/${brokerageId}/shipper-relationships/${shipperRelationshipId}/documents/`,
      formProps,
      config
    );
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

export function postCustomerDocument(formProps, brokerageId, shipperRelationshipId) {
  return (dispatch) => {
    dispatch(requestData());
    return configCustomerDocumentPost(formProps, brokerageId, shipperRelationshipId)
      .then((response) => {
        dispatch({
          type: CREATE_CUSTOMER_DOCUMENT,
          payload: response.data
        });
      })
      .catch((response) => {
        dispatch({type: ERROR_DOCUMENTS, payload: response.data});
      });
  };
}

/* customer docs PUT */
export function putCustomerDocument(brokerageId, shipperRelationshipId, documentId, body) {
  return (dispatch) => {
    dispatch(requestData());
    return putCustomerDocumentPromise(brokerageId, shipperRelationshipId, documentId, body)
      .then((response) => {
        dispatch({type: UPDATE_CUSTOMER_DOCUMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function putCustomerDocumentPromise(brokerageId, shipperRelationshipId, documentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsDocumentIdPut(
      brokerageId,
      shipperRelationshipId,
      documentId,
      body,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* customer docs DELETE */
export function deleteCustomerDocument(brokerageId, shipperRelationshipId, documentId) {
  return (dispatch) => {
    return deleteCustomerDocumentPromise(brokerageId, shipperRelationshipId, documentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteCustomerDocumentPromise(brokerageId, shipperRelationshipId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsDocumentIdDelete(
      brokerageId,
      shipperRelationshipId,
      documentId,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* ---------- Carrier Documents ---------- */

export function fetchCarrierDocuments(carrierRelationshipId, opts) {
  opts = opts || {};

  return (dispatch) => {
    return fetchCarrierDocumentsPromise(carrierRelationshipId, opts)
      .then((response) => {
        dispatch({
          type: FETCH_CARRIER_DOCUMENTS,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function fetchCarrierDocumentsPromise(carrierRelationshipId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsGet(
      carrierRelationshipId,
      opts,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* carrier docs POST */
async function configCarrierDocumentPost(formProps, carrierRelationshipId) {
  const accessToken = await getAccessToken();

  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [
        function (data) {
          const description = data.description;
          const file = data.file[0] || data.file;

          const formData = new FormData();
          formData.append('description', description);
          formData.append('file', file);
          return formData;
        }
      ],
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.post(
      `${basePath}/carrier-relationships/${carrierRelationshipId}/documents/`,
      formProps,
      config
    );
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

export function postCarrierDocument(formProps, carrierRelationshipId) {
  return (dispatch) => {
    dispatch(requestData());
    return configCarrierDocumentPost(formProps, carrierRelationshipId)
      .then((response) => {
        dispatch({
          type: CREATE_CUSTOMER_DOCUMENT,
          payload: response.data
        });
      })
      .catch((response) => {
        dispatch({type: ERROR_DOCUMENTS, payload: response.data});
      });
  };
}

/* carier docs PUT */
export function putCarrierDocument(carrierRelationshipId, documentId, body) {
  return (dispatch) => {
    dispatch(requestData());
    return putCarrierDocumentPromise(carrierRelationshipId, documentId, body)
      .then((response) => {
        dispatch({type: UPDATE_CARRIER_DOCUMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function putCarrierDocumentPromise(carrierRelationshipId, documentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsDocumentIdPut(
      carrierRelationshipId,
      documentId,
      body,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

/* carrier docs DELETE */
export function deleteCarrierDocument(carrierRelationshipId, documentId) {
  return (dispatch) => {
    return deleteCarrierDocumentPromise(carrierRelationshipId, documentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteCarrierDocumentPromise(carrierRelationshipId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDocumentsDocumentIdDelete(
      carrierRelationshipId,
      documentId,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}
