import {ShipmentAuctionStats} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getAuctionStats} from 'App/api/shipment/typed';
import {AUCTION_STATS_KEY} from 'App/data-hooks/queryKeys';

export const useGetAuctionStats = ({
  shipmentIds,
  options
}: {
  shipmentIds: string[];
  options?: Omit<
    UseQueryOptions<ShipmentAuctionStats[], unknown, ShipmentAuctionStats[], (string | string[])[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const auctionStatsQuery = useQuery(
    [AUCTION_STATS_KEY, 'list', shipmentIds],
    async () => {
      const response = await getAuctionStats({shipmentId: shipmentIds});
      const responseData = response.data;
      return responseData.data || [];
    },
    {
      enabled: Boolean(shipmentIds.length),
      ...options
    }
  );

  const {data: auctionStatsData, status: auctionStatsStatus} = auctionStatsQuery;
  return {
    auctionStatsData,
    auctionStatsStatus,
    auctionStatsQuery
  };
};
