import {useFlags} from 'launchdarkly-react-client-sdk';
import {useUserMe} from 'App/data-hooks';

export const PERM_VIEW_APPOINTMENTS = 'dock_scheduling.view_appointments';
export const PERM_CREATE_APPOINTMENT = 'dock_scheduling.create_appointments';
export const PERM_UPDATE_APPOINTMENT = 'dock_scheduling.update_appointments';
export const PERM_DELETE_APPOINTMENT = 'dock_scheduling.delete_appointments';
export const PERM_VIEW_CALENDAR = 'dock_scheduling.view_calendar';
export const PERM_UPDATE_CALENDAR = 'dock_scheduling.update_calendar';
export const PERM_VIEW_ANALYTICS = 'dock_scheduling.view_analytics';
export const PERM_BOOK_APPOINTMENTS = 'dock_scheduling.book_appointments';
export const PERM_BOOK_SAME_DAY_APPOINTMENTS = 'dock_scheduling.book_same_day_appointments';
export const PERM_RESCHEDULE_APPOINTMENTS = 'dock_scheduling.reschedule_appointments';
export const PERM_REJECT_APPOINTMENTS = 'dock_scheduling.reject_appointments';
export const PERM_CANCEL_APPOINTMENTS = 'dock_scheduling.cancel_appointments';
export const PERM_CHECK_IN_APPOINTMENTS = 'dock_scheduling.check_in_appointments';
export const PERM_CHECK_OUT_APPOINTMENTS = 'dock_scheduling.check_out_appointments';
export const PERM_VIEW_DASHBOARD = 'dock_scheduling.view_dashboard';
export const PERM_UPDATE_STATUS_APPOINTMENTS = 'dock_scheduling.update_status_appointments';
export const PERM_ASSIGN_DOCKS = 'dock_scheduling.assign_docks';
export const PERM_OVERRIDE_RESTRICTIONS = 'dock_scheduling.override_restrictions';
export const PERM_UPDATE_ANALYTICS = 'dock_scheduling.update_analytics';

export type AppointmentPerms = {
  isLoading: boolean;
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canViewCalendar: boolean;
  canUpdateCalendar: boolean;
  canViewAnalytics: boolean;
  canBookAppointments: boolean;
  canBookSameDayAppointments: boolean;
  canRescheduleAppointments: boolean;
  canCancelAppointments: boolean;
  canRejectAppointments: boolean;
  canCheckInAppointments: boolean;
  canCheckOutAppointments: boolean;
  canViewDashboard: boolean;
  canUpdateStatusAppointments: boolean;
  canAssignDocks: boolean;
  canOverrideRestrictions: boolean;
  canUpdateAnalytics: boolean;
};

export function useAppointmentPerms(): AppointmentPerms {
  const userDetailsQuery = useUserMe();
  const userPermissions = userDetailsQuery.data?.user?.permissions || [];
  const hasFeatureFlag = useFlags<{fiDockScheduling: boolean}>().fiDockScheduling;
  return {
    isLoading: userDetailsQuery.isLoading || !userDetailsQuery.isFetched,
    canView: hasFeatureFlag && userPermissions.includes(PERM_VIEW_APPOINTMENTS),
    canCreate: hasFeatureFlag && userPermissions.includes(PERM_CREATE_APPOINTMENT),
    canUpdate: hasFeatureFlag && userPermissions.includes(PERM_UPDATE_APPOINTMENT),
    canDelete: hasFeatureFlag && userPermissions.includes(PERM_DELETE_APPOINTMENT),
    canViewCalendar: hasFeatureFlag && userPermissions.includes(PERM_VIEW_CALENDAR),
    canUpdateCalendar: hasFeatureFlag && userPermissions.includes(PERM_UPDATE_CALENDAR),
    canViewAnalytics: hasFeatureFlag && userPermissions.includes(PERM_VIEW_ANALYTICS),
    canBookAppointments: hasFeatureFlag && userPermissions.includes(PERM_BOOK_APPOINTMENTS),
    canBookSameDayAppointments: hasFeatureFlag && userPermissions.includes(PERM_BOOK_SAME_DAY_APPOINTMENTS),
    canRescheduleAppointments: hasFeatureFlag && userPermissions.includes(PERM_RESCHEDULE_APPOINTMENTS),
    canRejectAppointments: hasFeatureFlag && userPermissions.includes(PERM_REJECT_APPOINTMENTS),
    canCancelAppointments: hasFeatureFlag && userPermissions.includes(PERM_CANCEL_APPOINTMENTS),
    canCheckInAppointments: hasFeatureFlag && userPermissions.includes(PERM_CHECK_IN_APPOINTMENTS),
    canCheckOutAppointments: hasFeatureFlag && userPermissions.includes(PERM_CHECK_OUT_APPOINTMENTS),
    canViewDashboard: hasFeatureFlag && userPermissions.includes(PERM_VIEW_DASHBOARD),
    canUpdateStatusAppointments: hasFeatureFlag && userPermissions.includes(PERM_UPDATE_STATUS_APPOINTMENTS),
    canAssignDocks: hasFeatureFlag && userPermissions.includes(PERM_ASSIGN_DOCKS),
    canOverrideRestrictions: hasFeatureFlag && userPermissions.includes(PERM_OVERRIDE_RESTRICTIONS),
    canUpdateAnalytics: hasFeatureFlag && userPermissions.includes(PERM_UPDATE_ANALYTICS)
  };
}
