import {Field} from 'formik';
import {FormikSelect, FormikTextInput, FormikTextarea} from '@shipwell/shipwell-ui';
import {validateEmail} from 'App/utils/globals';

const EmailFields = (props) => {
  const {index} = props;

  return (
    <>
      <div className="grid-item-12">
        <Field
          label="To"
          name={`actions[${index}].recipients`}
          component={FormikSelect}
          isMulti
          required
          async
          loadOptions={() => {
            return new Promise((resolve) => {
              resolve();
            });
          }}
          noOptionsMessage={() => {
            return 'Enter a valid email';
          }}
          allowCreate
          formatCreateLabel={(input) => {
            return 'Send to ' + input;
          }}
          isValidNewOption={(val) => {
            return validateEmail(val);
          }}
        />
      </div>
      <div className="grid-item-12 subject-field">
        <Field label="Subject" required name={`actions[${index}].subject`} component={FormikTextInput} />
      </div>
      <div className="grid-item-12">
        <Field label="Message" required name={`actions[${index}].message`} component={FormikTextarea} />
      </div>
    </>
  );
};

export default EmailFields;
