import {InlineNotification, InlineNotificationVariantEnum, Title} from '@shipwell/shipwell-ui';
import {DeviceCard} from './deviceCard/deviceCard';
import {AttachedDevice} from '@shipwell/locus-sdk';
import ShipwellLoader from 'App/common/shipwellLoader';

export interface DevicesListProps {
  shipmentId: string;
  isFetching: boolean;
  isAttaching: boolean;
  devices?: AttachedDevice[];
  onDisconnected: () => void;
}

export function DevicesList(props: DevicesListProps) {
  return (
    <div className="flex flex-col gap-3">
      {props.isAttaching && (
        <InlineNotification title="Attaching a Tracking Device" variant={InlineNotificationVariantEnum.info}>
          The attachment process can take a few minutes to connect with a device. The device might not be turned on and
          returning data until the shipment has started to maximize battery life.
        </InlineNotification>
      )}

      {props.isFetching && (
        <div className="inset-0 flex items-center justify-center">
          <ShipwellLoader loading />
        </div>
      )}

      {!props.isFetching && !props.isAttaching && !props.devices?.length && (
        <Title variant="emptyStateHeader" className="mt-6 text-center">
          No devices are attached to this shipment.
        </Title>
      )}

      {!props.isFetching &&
        props.devices?.map((item) => (
          <DeviceCard
            isConnected
            key={item.device_id}
            shipmentId={props.shipmentId}
            id={item.device_id}
            device={item.device_name || 'unknown'}
            onDisconnected={props.onDisconnected}
          />
        ))}

      {!props.isFetching && !props.isAttaching && !!props.devices?.length && (
        <p className="text-gray-600 italic text-sm">
          Once a device has been disconnected you will no longer receive tracking information.
        </p>
      )}
    </div>
  );
}
