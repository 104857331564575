import classNames from 'classnames';
import {defaultColors} from './defaultColors';

export interface ColorsSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export function ColorsSelect(props: ColorsSelectProps) {
  const colorValue = props.value.toUpperCase();
  const defaultClassnames =
    'cursor-pointer flex rounded-full hover:outline outline-offset-2 outline-2 border-1 w-7 h-7 border-[#BBB] fill-sw-border-alternate';

  return (
    <div>
      <ul className={'grid grid-cols-10 gap-2'}>
        {defaultColors.map((color) => {
          const isSelected = colorValue === color.toUpperCase();

          return (
            <li
              key={color}
              title={color}
              style={{backgroundColor: color}}
              className={classNames({outline: isSelected}, defaultClassnames)}
              onClick={() => props.onChange(color)}
            />
          );
        })}
      </ul>
    </div>
  );
}
