import {useQueries} from '@tanstack/react-query';
import {getDrayageLegDocuments} from 'App/api/corrogo/typed';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {DRAYAGE_LEG_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useV3Shipment} from 'App/data-hooks';

const useShipmentLegsDocuments = (shipmentId?: string) => {
  const {context} = useV3Shipment(shipmentId);
  const legIds = context.legsTableValues.map((leg) => leg.id);

  const legDocumentsQueries = useQueries({
    queries: legIds.map((legId) => ({
      queryKey: [DRAYAGE_LEG_DOCUMENTS_QUERY_KEY, legId],

      queryFn: async () => {
        const response = await getDrayageLegDocuments(legId);
        const legDocuments = response.data.data || [];
        return legDocuments.map((document) => ({
          ...document,
          legId
        }));
      },

      staleTime: HOT_DATA_STALE_TIME,
      enabled: !!legId
    }))
  });

  const shipmentLegsDocuments = legDocumentsQueries.map((query) => query.data || []).flat();

  return {
    ...legDocumentsQueries,

    context: {
      shipmentLegsDocuments
    }
  };
};

export default useShipmentLegsDocuments;
