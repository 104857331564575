import {Configuration, ReferenceDataApi, ShipmentIds, ColumnNames} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createReferenceDataApi() {
  return new ReferenceDataApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

const referenceDataApi = createReferenceDataApi();

export const getServiceLevels = () => {
  return referenceDataApi.shipmentsServiceLevelsGet();
};

export const getShipmentsDownloadCsv = ({
  shipmentIds,
  columnNames
}: {
  shipmentIds: ShipmentIds;
  columnNames: ColumnNames;
}) =>
  createReferenceDataApi().shipmentsDownloadCsvPost({
    //the SDK is expecting an object with a property called 'inlineObject21',
    // that has a property called 'shipment_ids' and a property called 'column_names'
    inlineObject21: {shipment_ids: shipmentIds, column_names: columnNames}
  });

export const getCarrierRoles = async () => {
  const response = await referenceDataApi.vendorsVendorPocRolesGet();
  return response.data;
};

// UPS Package Types
export const fetchUpsPackageTypes = async () => (await referenceDataApi.quotingUpsPackageTypesGet()).data;

// USPS Package Types
export const fetchUspsPackageTypes = async () => (await referenceDataApi.quotingUspsPackageTypesGet()).data;

export const getAccessorials = async () => (await referenceDataApi.shipmentsAccessorialsGet()).data;
