import {useState, useEffect, useCallback} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader';
import ExternalFooter from 'App/common/externalFooter';
import {
  TENDER_ACCEPTED,
  TENDER_EXPIRED,
  TENDER_REJECTED,
  TENDER_REVOKED,
  handleTenderStatus
} from 'App/containers/tendering/create/utils/constants';
import {setAccessTokenFunctionOverride} from 'App/api/utils';
import {getExternalTender, acceptTender, rejectTender} from 'App/api/tenders';
import withStatusToasts from 'App/components/withStatusToasts';
import {getAbbreviatedStopString} from 'App/components/trimbleMap/utils';

const tenderActions = {
  accepted: {endpoint: acceptTender, pastTense: 'been accepted'},
  rejected: {endpoint: rejectTender, pastTense: 'been rejected'},
  revoked: {endpoint: null, pastTense: 'been revoked'},
  expired: {endpoint: null, pastTense: 'expired'}
};

const paramActionMap = {
  accept: 'accepted',
  reject: 'rejected'
};

const ExternalTenderRequest = ({router, setError, brokerLogos}) => {
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [tenderDetails, setTenderDetails] = useState({});

  useEffect(() => {
    const {tenderId} = router.params;
    const {token, action} = router.location.query;
    if (!token || !tenderId || !action) {
      setErrorState(true);
      return;
    }
    //set auth header for future API calls
    setAccessTokenFunctionOverride(() => {
      return new Promise((resolve) => resolve(`TenderAcceptReject ${token}`));
    });
    getTenderDetails(tenderId, paramActionMap[action.toLowerCase()]);
  }, [getTenderDetails, router]);

  const getTenderDetails = useCallback(
    async (tenderId, shouldPerformAction) => {
      setLoading(true);
      try {
        const response = await getExternalTender(tenderId);
        setTenderDetails(response.body);
        if (shouldPerformAction && !['revoked', 'expired'].includes(response.body.status)) {
          performTenderAction(tenderId, shouldPerformAction);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError('Error!', error.error_description);
        setErrorState(true);
        setLoading(false);
      }
    },
    [setError, performTenderAction]
  );

  const performTenderAction = useCallback(
    async (tenderId, action) => {
      try {
        const extraArgs = action === paramActionMap.reject ? {body: {rejection_code: 'EQU'}} : null;
        await tenderActions[action].endpoint.call(null, tenderId, extraArgs);
        //refresh tender details
        getTenderDetails(tenderId, false);
      } catch (error) {
        console.error(error);
        setError('Error!', error.error_description);
        setLoading(false);
      }
    },
    [setError, getTenderDetails]
  );

  const TenderStatus = ({tender}) => {
    const getIcon = (tender) => {
      switch (handleTenderStatus(tender)) {
        case TENDER_ACCEPTED:
          return <SvgIcon height={80} width={80} color="$sw-success" name="CheckCircleOutlined" />;
        case TENDER_REJECTED:
          return <SvgIcon height={80} width={80} color="$sw-error" name="Cancel" />;
        case TENDER_EXPIRED:
          return <SvgIcon height={80} width={80} color="$sw-warning" name="Time" />;
        case TENDER_REVOKED:
          return <SvgIcon height={80} width={80} color="$sw-warning" name="ErrorOutlined" />;
      }
    };

    return (
      <div>
        <div className="mb-8 flex justify-center">{getIcon(tender)}</div>
        <div className="mb-12 text-5xl font-bold">{`Tender ${upperFirst(tender.status)}!`}</div>
      </div>
    );
  };

  TenderStatus.propTypes = {
    tender: PropTypes.shape({
      status: PropTypes.string
    })
  };

  const TenderDetails = ({tender}) => {
    return (
      <div className="mb-12 rounded-lg bg-sw-active/10  px-20 py-8">
        <div className="mb-6">
          The tender request for load <span className="font-bold">{tender.load_id}</span>{' '}
          {tender.stops?.[0]?.display_planned_window ? `on ${tender.stops?.[0].display_planned_window}` : ''}
          at the rate of <span className="font-bold text-sw-success">{tender.rate}</span>
          {tenderActions[tender.status] ? ` has ${tenderActions[tender.status].pastTense}.` : ` is ${tender.status}.`}
        </div>
        {tender.stops?.map((stop, index) => {
          return (
            <div key={index} className="mb-4">
              <div>
                <span className="font-bold">
                  Stop {index + 1} {stop.is_pickup ? (stop.is_dropoff ? 'Pickup/Dropoff' : 'Pickup') : 'Dropoff'}:
                </span>
                {` ${getAbbreviatedStopString(stop)}`}
              </div>
              <div>
                <span className="font-bold">Planned For: </span>
                <span>{stop.display_planned_window}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  TenderDetails.propTypes = {
    tender: PropTypes.shape({
      load_id: PropTypes.string,
      stops: PropTypes.arrayOf(
        PropTypes.shape({
          display_planned_window: PropTypes.string,
          is_pickup: PropTypes.bool,
          is_dropoff: PropTypes.bool
        })
      ),
      status: PropTypes.string,
      rate: PropTypes.string
    })
  };

  const handleRedirect = () => {
    setAccessTokenFunctionOverride(null);
    router.push('/load-board');
  };

  return (
    <div className="flex min-h-screen flex-col bg-sw-background">
      {loading ? (
        <div className="flex h-screen items-center justify-center bg-sw-background">
          <ShipwellLoader loading />
        </div>
      ) : (
        <>
          <div className="bg-external-background bg-center bg-no-repeat">
            <div className="flex flex-col items-center p-24">
              <img
                src={brokerLogos?.find((logo) => logo.image_type === 'INLINE_COLOR')?.logo}
                className="mb-20 max-h-12 max-w-xs"
              />
              {errorState ? (
                <div className="mb-12 flex flex-col items-center text-center text-xl font-bold">
                  <SvgIcon height={80} width={80} color="$sw-error" className="mb-12" name="ErrorOutlined" />
                  <div>
                    There was an issue getting the details of this tender. Please check your URL or reach out to the
                    shipper if the problem persists.
                  </div>
                </div>
              ) : tenderDetails?.status ? (
                <>
                  <TenderStatus tender={tenderDetails} />
                  <TenderDetails tender={tenderDetails} />
                </>
              ) : null}

              <DeprecatedButton variant="primary" onClick={handleRedirect}>
                Find More Loads
              </DeprecatedButton>
            </div>
          </div>
          <ExternalFooter />
        </>
      )}
    </div>
  );
};

ExternalTenderRequest.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      query: PropTypes.object
    }),
    params: PropTypes.object
  }),
  setError: PropTypes.func,
  brokerLogos: PropTypes.array
};

export default compose(
  connect((state) => ({
    brokerLogos: state.brokers.brokerLogos
  })),
  withRouter,
  withStatusToasts
)(ExternalTenderRequest);
