import moment from 'moment';
import {CalendarModesEnum} from '@shipwell/shipwell-ui';
import {addDays} from 'App/utils/dateTimeGlobalsTyped';
import {tryGetDateFromRelativeDate} from 'App/containers/Dashboard/utils/date/dateUtils';

export enum DateOptionsEnums {
  yesterday = 'YESTERDAY',
  today = 'TODAY',
  tomorrow = 'TOMORROW',
  last7Days = 'LAST_7_DAYS',
  last30Days = 'LAST_30_DAYS',
  next7Days = 'NEXT_7_DAYS',
  next30Days = 'NEXT_30_DAYS'
}

export const getDate = (daysToAdd: number) => moment(addDays(new Date(), daysToAdd)).format('YYYY-MM-DD');
// returns the expected date for comparison in DateSelectFilter
export const defaultDates = {
  today: getDate(0),
  tomorrow: getDate(1),
  yesterday: getDate(-1),
  next7Days: getDate(7),
  last7Days: getDate(-7),
  next30Days: getDate(30),
  last30Days: getDate(-30)
};

// basic radio options with isFuture key added for filtering in the getRadioOptions func below
export const dateOptions = [
  {label: 'Yesterday', value: DateOptionsEnums.yesterday, isFuture: false},
  {label: 'Today', value: DateOptionsEnums.today, isFuture: false},
  {label: 'Tomorrow', value: DateOptionsEnums.tomorrow, isFuture: true},
  {label: 'Last 7 Days', value: DateOptionsEnums.last7Days, isFuture: false},
  {label: 'Last 30 Days', value: DateOptionsEnums.last30Days, isFuture: false},
  {label: 'Next 7 Days', value: DateOptionsEnums.next7Days, isFuture: true},
  {label: 'Next 30 Days', value: DateOptionsEnums.next30Days, isFuture: true}
];

// filters out future options if Filter should only show past options
export const getRadioOptions = (pastOnly?: boolean) => {
  if (!pastOnly) return dateOptions;
  return dateOptions.filter((dateOption) => !dateOption.isFuture);
};

// set defaultMode based on current selectedDates
export const getDefaultMode = ([start, stop]: [string, string]) => {
  // is before if no start, but stop date exists
  if (!start && stop) {
    return CalendarModesEnum.before;
  }
  // is after if no stop, but start date exists
  if (!stop && start) {
    return CalendarModesEnum.after;
  }
  if (start && stop) {
    // is between if both dates exist and are not the same date
    if (start !== stop) {
      return CalendarModesEnum.between;
    }
    // is on if both dates exist and are the same date
    return CalendarModesEnum.on;
  }
  // default to on if no start nor stop
  return CalendarModesEnum.on;
};

export const getRadioButtonValue = ([start, stop]: [string, string]) => {
  const isDefaultStartOption = [defaultDates.today, defaultDates.yesterday, defaultDates.tomorrow].includes(start);
  const isDefaultStopOption = [
    defaultDates.next7Days,
    defaultDates.last7Days,
    defaultDates.next30Days,
    defaultDates.last30Days
  ].includes(stop);
  const isSameDate = start === stop;
  if (isDefaultStartOption && isSameDate) {
    switch (start) {
      case defaultDates.yesterday:
        return DateOptionsEnums.yesterday;
      case defaultDates.today:
        return DateOptionsEnums.today;
      case defaultDates.tomorrow:
        return DateOptionsEnums.tomorrow;
      default:
        return '';
    }
  }

  if (isDefaultStopOption && start === defaultDates.today) {
    switch (stop) {
      case defaultDates.last7Days:
        return DateOptionsEnums.last7Days;
      case defaultDates.next7Days:
        return DateOptionsEnums.next7Days;
      case defaultDates.last30Days:
        return DateOptionsEnums.last30Days;
      case defaultDates.next30Days:
        return DateOptionsEnums.next30Days;
      default:
        return '';
    }
  }
};

export const getInputValue = (dates: [string, string], mode: 'ON' | 'BEFORE' | 'AFTER' | 'BETWEEN') => {
  const start = tryGetDateFromRelativeDate(dates[0]);
  const stop = tryGetDateFromRelativeDate(dates[1]);

  if (!start && !stop) {
    return '';
  }

  const formatDate = (dateString: string) => moment(dateString).format('MMM D, YYYY');

  switch (mode) {
    case CalendarModesEnum.on:
      return `On ${formatDate(start)}`;
    case CalendarModesEnum.before:
      return `Before ${formatDate(stop)}`;
    case CalendarModesEnum.after:
      return `After ${formatDate(start)}`;
    case CalendarModesEnum.between:
      return `Between ${formatDate(start)} - ${formatDate(stop)}`;
    default:
      return '';
  }
};
