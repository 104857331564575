import {useEffect, useCallback} from 'react';
import noop from 'lodash/noop';
import {useFormikContext} from 'formik';
import {withProps} from 'recompose';
import {StopTypeId, DisplayTypes, StopTypeType, ContactType, StopFormValues} from './constants';
import {getAddressEntry} from './utils';
import StopTypeContactsModalDisplay from './StopTypeContactsModalDisplay';
import StopTypeContactsCardDisplay from './StopTypeContactsCardDisplay';

const COMPONENTS_TO_DISPLAY = {
  [DisplayTypes.ModalDisplay]: StopTypeContactsModalDisplay,
  [DisplayTypes.CardDisplay]: StopTypeContactsCardDisplay
};

const StopTypeContactsFields = ({
  push = noop,
  remove = noop,
  stopType,
  display
}: {
  push: (contact: ContactType) => void;
  remove: (index: number) => void;
  stopType: StopTypeType;
  display: DisplayTypes;
}) => {
  const {values} = useFormikContext<StopFormValues>();

  const handleAddNewContact = useCallback(
    () => push({first_name: null, last_name: null, phone_number: null, email: null, preferences: {}}),
    [push]
  );

  const entry = getAddressEntry(values, stopType.id);
  const hasContacts = Boolean(entry?.point_of_contacts?.length);
  useEffect(() => {
    if (!hasContacts) {
      handleAddNewContact();
    }
  }, [handleAddNewContact, hasContacts]);

  const ComponentToDisplay = COMPONENTS_TO_DISPLAY[display];
  return <ComponentToDisplay stopType={stopType} handleAddNewContact={handleAddNewContact} remove={remove} />;
};

export default StopTypeContactsFields;

const PickupStopProps = {
  label: 'Pickup',
  id: StopTypeId.Pickup
};

const LoadLocationStopProps = {
  label: 'Load Location',
  id: StopTypeId.Pickup
};

const DeliveryStopProps = {
  label: 'Delivery',
  id: StopTypeId.Delivery
};

const ContainerPickupStopProps = {
  label: 'Container Pickup',
  id: StopTypeId.ContainerPickup
};

const ContainerReturnStopProps = {
  label: 'Container Return',
  id: StopTypeId.ContainerReturn
};

const PickupStopContacts = withProps({stopType: PickupStopProps})(StopTypeContactsFields);
const LoadLocationStopContacts = withProps({stopType: LoadLocationStopProps})(StopTypeContactsFields);
const DeliveryStopContacts = withProps({stopType: DeliveryStopProps})(StopTypeContactsFields);
const ContainerPickupStopContacts = withProps({stopType: ContainerPickupStopProps})(StopTypeContactsFields);
const ContainerReturnStopContacts = withProps({stopType: ContainerReturnStopProps})(StopTypeContactsFields);

export const PickupStopType = {...PickupStopProps, component: PickupStopContacts};
export const LoadLocationStopType = {...LoadLocationStopProps, component: LoadLocationStopContacts};
export const DeliveryStopType = {...DeliveryStopProps, component: DeliveryStopContacts};
export const ContainerPickupStopType = {...ContainerPickupStopProps, component: ContainerPickupStopContacts};
export const ContainerReturnStopType = {...ContainerReturnStopProps, component: ContainerReturnStopContacts};
