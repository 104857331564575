import {WithRouterProps, withRouter} from 'react-router';
import get from 'lodash/get';
import {useV3Shipment} from 'App/data-hooks';
import ShipwellLoader from 'App/common/shipwellLoader';
import {ShipmentDetailsController} from 'App/containers/shipments/details';
import ErrorPage from 'App/common/ErrorPage';
import usePageTitle from 'App/utils/hooks/usePageTitle';

const ShipmentDetails = ({location, params}: WithRouterProps<{shipment_id: string}>) => {
  const shipmentId = params.shipment_id;
  const {shipmentQueryInfo, data} = useV3Shipment(shipmentId, {refetchOnWindowFocus: false});

  usePageTitle(location, params, get(data, 'reference_id'));

  if (shipmentQueryInfo.isLoading) {
    return <ShipwellLoader loading={shipmentQueryInfo.isLoading} />;
  }

  if (shipmentQueryInfo.isError) {
    return (
      <ErrorPage
        primaryMessage="Sorry, we couldn't find this shipment."
        secondaryMessage="Please check your URL and try again, or contact your account admin for assistance."
      />
    );
  }

  return <ShipmentDetailsController shipmentId={shipmentId} />;
};

export default withRouter(ShipmentDetails);
