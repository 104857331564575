import {useEffect} from 'react';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {Field, useFormikContext} from 'formik';
import {FuelSurchargeTableRegionEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {regionOptions, getAddressValuesFromRegion} from './utils';
import {FuelSurchargesFormValues} from 'App/containers/userCompany/fuelSurcharges/validation';

export const RegionSelect = () => {
  const {setFieldValue, values} = useFormikContext<FuelSurchargesFormValues>();

  useEffect(() => {
    if (values.region && !values.locations?.length) {
      setFieldValue('locations', getAddressValuesFromRegion(values.region));
    } else if (!values.region) {
      setFieldValue('region', FuelSurchargeTableRegionEnum.Us);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.region]);

  return <Field component={FormikSelect} simpleValue options={regionOptions} label="Region" name="region" />;
};
