import axios from 'axios';
import {
  REQ_USER_DATA,
  USER_LOADBOARD_CONFIG_REQUEST_PENDING,
  USER_DASHBOARD_CONFIG_REQUEST_PENDING,
  USER_LOADBOARD_CONFIG_REQUEST_SUCCESS,
  USER_DASHBOARD_CONFIG_REQUEST_SUCCESS,
  ALERT_ERROR,
  USER_LOADBOARD_CONFIG_UPDATE_PENDING,
  USER_DASHBOARD_CONFIG_UPDATE_PENDING,
  USER_LOADBOARD_CONFIG_UPDATE_SUCCESS,
  USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
  GET_COMPANY_USERS,
  CREATE_COMPANY_USER,
  EDIT_COMPANY_USER,
  DELETE_COMPANY_USER,
  SELECT_USER,
  CLEAR_SELECTED_USER,
  EDIT_COMPANY,
  GET_COMPANY_PREFERENCES,
  GET_IDENTIFYING_CODES,
  GET_NOTIFICATION_PREFERENCES,
  GET_PERMISSIONS,
  RESET_PASSWORD,
  GET_CASES,
  LOADING_TASKS_FOR_CASE,
  GET_TASKS_BY_CASE,
  GET_TASKS,
  SELECT_TASK,
  SELECT_CASE,
  CLEAR_SELECTED_TASK,
  CLEAR_SELECTED_CASE,
  USER_ACCT_ERROR,
  RESET_ERROR,
  TOGGLE_SIDE_MENU
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi, basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';
import {getCompanyInternationalPreferences} from 'App/api/internationalPreferences';

// starts the Loading... spinner
export function requestData() {
  return {type: REQ_USER_DATA};
}

export async function getUserTableConfigurationPromise(opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsGet(opts, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

export async function updateUserTableConfigurationPromise(configId, payload) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsUserDashboardConfigurationIdPut(
      configId,
      payload,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response);
        }
      }
    );
  });
}

export async function createUserTableConfigurationPromise(payload) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsPost(payload, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

export async function deleteUserTableConfigurationPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.preferencesDashboardConfigurationsUserDashboardConfigurationIdDelete(id, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

export function getUserTableConfiguration(opts = {}) {
  const {tableType} = opts;
  return (dispatch) => {
    if (tableType === 'LOADBOARD') {
      dispatch({type: USER_LOADBOARD_CONFIG_REQUEST_PENDING});
    } else if (tableType === 'DASHBOARD') {
      dispatch({type: USER_DASHBOARD_CONFIG_REQUEST_PENDING});
    }

    return getUserTableConfigurationPromise(opts)
      .then((response) => {
        if (tableType === 'LOADBOARD') {
          dispatch({
            type: USER_LOADBOARD_CONFIG_REQUEST_SUCCESS,
            payload: response.body
          });
        } else if (tableType === 'DASHBOARD') {
          dispatch({
            type: USER_DASHBOARD_CONFIG_REQUEST_SUCCESS,
            payload: response.body
          });
        }
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

export function updateUserTableConfiguration(configId, payload) {
  const {table_type} = payload;
  return (dispatch) => {
    if (table_type === 'LOADBOARD') {
      dispatch({type: USER_LOADBOARD_CONFIG_UPDATE_PENDING});
    } else if (table_type === 'DASHBOARD') {
      dispatch({type: USER_DASHBOARD_CONFIG_UPDATE_PENDING});
    }
    if (
      payload.config.ordering &&
      (payload.config.ordering.includes('-match_score,-created_at') ||
        payload.config.ordering.includes('match_score,-created_at'))
    ) {
      payload.config.ordering[0] = payload.config.ordering[0].split(',')[0];
    }

    return updateUserTableConfigurationPromise(configId, payload)
      .then((response) => {
        if (table_type === 'LOADBOARD') {
          dispatch({
            type: USER_LOADBOARD_CONFIG_UPDATE_SUCCESS,
            payload: response.body
          });
        } else if (table_type === 'DASHBOARD') {
          dispatch({
            type: USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
            payload: response.body
          });
        }
        dispatch({
          type: USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
          payload: response.body
        });
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

export function createUserTableConfiguration(payload) {
  const {table_type} = payload;
  return (dispatch) => {
    if (table_type === 'LOADBOARD') {
      dispatch({type: USER_LOADBOARD_CONFIG_UPDATE_PENDING});
    } else if (table_type === 'DASHBOARD') {
      dispatch({type: USER_DASHBOARD_CONFIG_UPDATE_PENDING});
    }
    if (
      payload.config.ordering &&
      (payload.config.ordering.includes('-match_score,-created_at') ||
        payload.config.ordering.includes('match_score,-created_at'))
    ) {
      payload.config.ordering[0] = payload.config.ordering[0].split(',')[0];
    }

    return createUserTableConfigurationPromise(payload)
      .then((response) => {
        if (table_type === 'LOADBOARD') {
          dispatch({
            type: USER_LOADBOARD_CONFIG_UPDATE_SUCCESS,
            payload: response.body
          });
        } else if (table_type === 'DASHBOARD') {
          dispatch({
            type: USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
            payload: response.body
          });
        }
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

export function deleteUserTableConfiguration(configId) {
  return (dispatch) => {
    return deleteUserTableConfigurationPromise(configId)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/**
 * Returns a list of users associated to a company
 * @param {Integer} companyId The id of the company
 * @param {module:api/shipwellApi~companiesCompanyIdUsersGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/InlineResponse2006}
 */

export function getCompanyUsers(companyId, opts) {
  opts = opts || {};
  return (dispatch) => {
    dispatch(requestData());
    return getCompanyUsersPromise(companyId, opts)
      .then((response) => {
        dispatch({type: GET_COMPANY_USERS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function getCompanyUsersPromise(companyId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdUsersGet(companyId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Creates a new user
 * @param {Integer} companyId The id of the company
 * @param {module:model/User} body
 * @param {module:api/shipwellApi~companiesCompanyIdUsersPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/User}
 */
export function createCompanyUser(companyId, user) {
  return (dispatch) => {
    return createCompanyUserPromise(companyId, user)
      .then((response) => {
        dispatch({type: CREATE_COMPANY_USER, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function createCompanyUserPromise(companyId, body) {
  const accessToken = await getAccessToken();
  return new Promise(function (resolve, reject) {
    //handling for formdata
    const config = {
      transformRequest: [
        function (body) {
          const avatar = body.avatar;
          const first_name = body.first_name;
          const id = body.id;
          const last_name = body.last_name;
          const email = body.email;
          const phone_number = body.phone_number;
          const timezone = body.timezone;
          const permissions = body.permissions;

          const formData = new FormData();
          if (avatar) {
            formData.append('avatar', avatar, id + '.png');
          }
          if (avatar === '') {
            formData.append('avatar', '');
          }
          if (first_name) {
            formData.append('first_name', first_name);
          }
          if (id) {
            formData.append('id', id);
          }
          if (last_name) {
            formData.append('last_name', last_name);
          }
          if (email) {
            formData.append('email', email);
          }
          if (phone_number) {
            formData.append('phone_number', phone_number);
          }
          if (timezone) {
            formData.append('timezone', timezone);
          }
          if (permissions) {
            for (var i = 0; i < permissions.length; i++) {
              formData.append('permissions', permissions[i]);
            }
          }
          return formData;
        }
      ],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };
    const request = axios.post(basePath + '/companies/' + companyId + '/users/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error.response.data);
      });

    // shipwellApi.companiesCompanyIdUsersPost(companyId, user, function(err, data, response) {
    //   if (err) {
    //     reject(parseErrors(response));
    //   } else {
    //     resolve(data);
    //   }
    // });
  });
}

/**
 * Update a user at a company (not updating yourself)
 * @param {Integer} companyId The id of the company
 * @param {Integer} userId The id of the user
 * @param {module:model/User} body
 * @param {module:api/shipwellApi~companiesCompanyIdUsersUserIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/User}
 */

export function editCompanyUser(companyId, userId, user) {
  return (dispatch) => {
    return editCompanyUserPromise(companyId, userId, user)
      .then((response) => {
        dispatch({type: EDIT_COMPANY_USER, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function editCompanyUserPromise(companyId, userId, body) {
  const accessToken = await getAccessToken();

  return new Promise(function (resolve, reject) {
    //handling for formdata
    const config = {
      transformRequest: [
        function (body) {
          const first_name = body.first_name;
          const id = body.id;
          const last_name = body.last_name;
          const email = body.email;
          const phone_number = body.phone_number;
          const timezone = body.timezone;
          const permissions = body.permissions;

          const formData = new FormData();
          if (first_name) {
            formData.append('first_name', first_name);
          }
          if (id) {
            formData.append('id', id);
          }
          if (last_name) {
            formData.append('last_name', last_name);
          }
          if (email) {
            formData.append('email', email);
          }
          if (phone_number) {
            formData.append('phone_number', phone_number);
          }
          if (timezone) {
            formData.append('timezone', timezone);
          }
          if (permissions) {
            for (var i = 0; i < permissions.length; i++) {
              formData.append('permissions', permissions[i]);
            }
          }
          return formData;
        }
      ],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };
    const request = axios.put(basePath + '/companies/' + companyId + '/users/' + userId + '/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        }
      });

    // shipwellApi.companiesCompanyIdUsersUserIdPut(companyId, userId, user, function(err, data, response) {
    //   if (err) {
    //     reject(parseErrors(response));
    //   } else {
    //     resolve(data);
    //   }
    // });
  });
}

/**
 * Delete a user
 * @param {Integer} companyId The id of the company
 * @param {Integer} userId The id of the user
 * @param {module:api/shipwellApi~companiesCompanyIdUsersUserIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/InlineResponse404}
 */
export function deleteCompanyUser(companyId, userId) {
  return (dispatch) => {
    return deleteCompanyUserPromise(companyId, userId)
      .then((response) => {
        dispatch({type: DELETE_COMPANY_USER, payload: userId});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function deleteCompanyUserPromise(companyId, userId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdUsersUserIdDelete(companyId, userId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

//select a user for editing
export function selectUser(user) {
  return (dispatch) => {
    dispatch({type: SELECT_USER, payload: user});
  };
}

//clear selected user
export function clearSelectedUser() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_USER, payload: null});
  };
}

/**
 * Update the logged in user
 * @param {Integer} companyId The id of the company
 * @param {Integer} userId The id of the user
 * @param {module:model/User} body
 * @param {module:api/shipwellApi~companiesCompanyIdUsersUserIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/User}
 */

export function editMyUserProfile(companyId, userId, user) {
  return (dispatch) => {
    return editMyUserProfilePromise(companyId, userId, user)
      .then((response) => {
        //dispatch({type: types.EDIT_USER, payload: response.data});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function editMyUserProfilePromise(companyId, userId, body) {
  const accessToken = await getAccessToken();

  return new Promise(function (resolve, reject) {
    //handling for formdata
    const config = {
      transformRequest: [
        function (body) {
          const avatar = body.avatar;
          const first_name = body.first_name;
          const id = body.id;
          const last_name = body.last_name;
          const email = body.email;
          const phone_number = body.phone_number;
          const timezone = body.timezone;
          const permissions = body.permissions;

          const formData = new FormData();
          if (avatar) {
            formData.append('avatar', avatar, id + '.png');
          }
          if (avatar === '') {
            formData.append('avatar', '');
          }
          if (first_name) {
            formData.append('first_name', first_name);
          }
          if (id) {
            formData.append('id', id);
          }
          if (last_name) {
            formData.append('last_name', last_name);
          }
          if (email) {
            formData.append('email', email);
          }
          if (phone_number) {
            formData.append('phone_number', phone_number);
          }
          if (timezone) {
            formData.append('timezone', timezone);
          }
          if (permissions) {
            for (var i = 0; i < permissions.length; i++) {
              formData.append('permissions', permissions[i]);
            }
          }
          return formData;
        }
      ],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };
    const request = axios.put(basePath + '/companies/' + companyId + '/users/' + userId + '/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

/**
 * Update a company
 * @param {Integer} companyId The id of the company
 * @param {module:model/Company} body
 * @param {module:api/shipwellApi~companiesCompanyIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Company}
 */

export function editCompanyProfile(companyId, company) {
  return (dispatch) => {
    return editCompanyProfilePromise(companyId, company)
      .then((response) => {
        dispatch({type: EDIT_COMPANY, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function editCompanyProfilePromise(companyId, company) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdPut(companyId, company, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Get a company's details
 * @param {Integer} companyId The id of the company
 * @param {module:model/Company} body
 * @param {module:api/shipwellApi~companiesCompanyIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Company}
 */

export function getCompanyDetails(companyId) {
  return (dispatch) => {
    return getCompanyDetailsPromise(companyId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getCompanyDetailsPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdGet(companyId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Obtain the company&#39;s preferences
 * @param {String} companyId The id of the company
 * @param {module:api/shipwellApi~companiesCompanyIdPreferencesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/CompanyPreferences}
 */

export function getCompanyPreferences(companyId) {
  return async (dispatch) => {
    // currency comes from getCompanyInternationalPreferences
    const [companyPreferencesResponse, internationalPreferencesResponse] = await Promise.allSettled([
      getCompanyPreferencesPromise(companyId),
      getCompanyInternationalPreferences(companyId)
    ]);
    if (companyPreferencesResponse.value) {
      const payload = {
        ...companyPreferencesResponse.value,
        currency: internationalPreferencesResponse?.value?.data?.currency
      };
      dispatch({type: GET_COMPANY_PREFERENCES, payload});
      return {status: 200};
    } else {
      return companyPreferencesResponse.reason;
    }
  };
}

async function getCompanyPreferencesPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdPreferencesGet(companyId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Update the company&#39;s preferences
 * @param {String} companyId The id of the company
 * @param {module:model/CompanyPreferences} body
 * @param {module:api/shipwellApi~companiesCompanyIdPreferencesPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/CompanyPreferences}
 */

export function editCompanyPreferences(companyId, body) {
  return (dispatch) => {
    return editCompanyPreferencesPromise(companyId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function editCompanyPreferencesPromise(companyId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdPreferencesPut(companyId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * A list of all identifying codes supported by the API
 * @param {module:api/shipwellApi~companiesIdentifyingCodesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/IdentifyingCodeType>}
 */
async function fetchIdentifyingCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesIdentifyingCodesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export function fetchIdentifyingCodes() {
  return (dispatch) => {
    return fetchIdentifyingCodesPromise()
      .then((response) => {
        dispatch({type: GET_IDENTIFYING_CODES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting identifying codes.'
        });
      });
  };
}

/**
 * GET PERMISSIONS
 * Returns a dictionary of all permission categories and their underlying permissions.
 * @param {module:api/shipwellApi~permissionsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Object.<String, module:model/{'String': InlineResponse2007}>}
 */
export function getNotificationPreferences() {
  return (dispatch) => {
    return getNotificationPreferencesPromise()
      .then((response) => {
        dispatch({type: GET_NOTIFICATION_PREFERENCES, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getNotificationPreferencesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.notificationPreferencesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Update a list of a user&#39;s notification preferences
 * @param {Array.<module:model/NotificationPreference>} body
 * @param {module:api/shipwellApi~notificationPreferencesPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/NotificationPreference>}
 */
export function editNotificationPreferences(body) {
  return (dispatch) => {
    return editNotificationPreferencesPromise(body)
      .then((response) => {
        dispatch({type: GET_NOTIFICATION_PREFERENCES, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function editNotificationPreferencesPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.notificationPreferencesPut(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * GET PERMISSIONS
 * Returns a dictionary of all permission categories and their underlying permissions.
 * @param {module:api/shipwellApi~permissionsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Object.<String, module:model/{'String': InlineResponse2007}>}
 */
export function getPermissions() {
  return (dispatch) => {
    return getPermissionsPromise()
      .then((response) => {
        dispatch({type: GET_PERMISSIONS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getPermissionsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.permissionsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/*SET LAST LOGIN*/
export function setLastLogin() {
  return (dispatch) => {
    return setLastLoginPromise().catch((response) => {
      console.log(response);
    });
  };
}

async function setLastLoginPromise() {
  const shipwellApi = await getShipwellApi();

  var token = localStorage.getItem('msajnfhiua');
  if (!token) {
    token = localStorage.getItem('dsiajksdjk');
  }
  var authorization = 'Token ' + token;
  return new Promise(function (resolve, reject) {
    shipwellApi.updateLastLoginPut(authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

/* CHANGE PASSWORD WHILE AUTHENTICATED */

export function changePassword(currentPass, newPass) {
  return (dispatch) => {
    return changePasswordPromise(currentPass, newPass)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function changePasswordPromise(currentPass, newPass) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authChangePasswordPost(
      {old_password: currentPass, new_password: newPass},

      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response);
        }
      }
    );
  });
}

/*
   ========================================================================
       --- * *  Password Reset (RightSideBar - Shipper)  * * ---
       * Send a password reset link to email
       * @param {String} email
       * @param {module:api/shipwellApi~passwordResetPostCallback} callback
       The callback function, accepting three arguments: error, data, response
    ========================================================================
   */

async function resetPasswordPromise(email) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authRequestPasswordResetPost({email: email}, function (err, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

export function resetPassword(email) {
  return (dispatch) => {
    // "Loading..."
    // dispatch(requestData());

    return resetPasswordPromise(email)
      .then((response) => {
        dispatch({type: RESET_PASSWORD, payload: response});
      })
      .catch((response) => {
        dispatch(usersAccountError('Invalid email'));
      });
  };
}

// Set reset password
export function setResetPassword(body) {
  return (dispatch) => {
    return setResetPasswordPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function setResetPasswordPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authPasswordResetPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// Set password
export function setPassword(body) {
  return (dispatch) => {
    return setPasswordPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function setPasswordPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authSetPasswordPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// get cases by shipment ID
export function getCasesByShipmentId(shipmentId, opts) {
  opts = opts || {};
  return (dispatch) => {
    return getCasesByShipmentIdPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: GET_CASES, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function getCasesByShipmentIdPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCasesGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// get cases by shipment ID
export function getNotesByCaseId(shipmentCaseId, opts) {
  opts = opts || {};
  return (dispatch) => {
    return getNotesByCaseIdPromise(shipmentCaseId, opts)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function getNotesByCaseIdPromise(shipmentCaseId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesGet(shipmentCaseId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create note for a case
export function createNoteByCaseId(shipmentCaseId, body) {
  return (dispatch) => {
    return createNoteByCaseIdPromise(shipmentCaseId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function createNoteByCaseIdPromise(shipmentCaseId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesPost(shipmentCaseId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// edit note for a case
export function editCaseNote(shipmentCaseId, caseNoteId, body) {
  return (dispatch) => {
    return editCaseNotePromise(shipmentCaseId, caseNoteId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function editCaseNotePromise(shipmentCaseId, caseNoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesCaseNoteIdPut(
      shipmentCaseId,
      caseNoteId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

// edit case details
export function editCase(shipmentId, shipmentCaseId, body) {
  return (dispatch) => {
    return editCasePromise(shipmentId, shipmentCaseId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function editCasePromise(shipmentId, shipmentCaseId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCasesShipmentCaseIdPut(
      shipmentId,
      shipmentCaseId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

// delete note for a case
export function deleteNoteByCaseId(shipmentCaseId, noteId) {
  return (dispatch) => {
    return deleteNoteByCaseIdPromise(shipmentCaseId, noteId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function deleteNoteByCaseIdPromise(shipmentCaseId, noteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdNotesCaseNoteIdDelete(
      shipmentCaseId,
      noteId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

//Create case for shipment ID
export function createCaseForShipment(shipmentId, body) {
  return (dispatch) => {
    return createCaseForShipmentPromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function createCaseForShipmentPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCasesPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// get tasks
export function getTasksByCaseId(shipmentCaseId, opts) {
  return (dispatch) => {
    dispatch({type: LOADING_TASKS_FOR_CASE, payload: {id: shipmentCaseId, bool: true}});
    return getTasksByCaseIdPromise(shipmentCaseId, opts)
      .then((response) => {
        dispatch({type: GET_TASKS_BY_CASE, payload: response});
        dispatch({type: LOADING_TASKS_FOR_CASE, payload: {id: shipmentCaseId, bool: false}});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function getTasksByCaseIdPromise(shipmentCaseId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdTasksGet(shipmentCaseId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// get tasks
export function getTasks(opts) {
  return (dispatch) => {
    return getTasksPromise(opts)
      .then((response) => {
        dispatch({type: GET_TASKS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function getTasksPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.tasksGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create task
export function createTask(body) {
  return (dispatch) => {
    return createTaskPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function createTaskPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.tasksPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create task
export function editTask(taskId, body) {
  return (dispatch) => {
    return editTaskPromise(taskId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function editTaskPromise(taskId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.tasksTaskIdPut(taskId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create task
export function toggleCompleteTask(taskId, body) {
  return (dispatch) => {
    return toggleCompleteTaskPromise(taskId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function toggleCompleteTaskPromise(taskId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.tasksTaskIdPut(taskId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create task
export function createTaskInCase(caseId, body) {
  return (dispatch) => {
    return createTaskInCasePromise(caseId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function createTaskInCasePromise(caseId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsCasesShipmentCaseIdTasksPost(caseId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// delete task
export function deleteTask(taskId) {
  return (dispatch) => {
    return deleteTaskPromise(taskId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function deleteTaskPromise(taskId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.tasksTaskIdDelete(taskId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// delete case
export function deleteCase(shipmentId, caseId) {
  return (dispatch) => {
    return deleteCasePromise(shipmentId, caseId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function deleteCasePromise(shipmentId, caseId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCasesShipmentCaseIdDelete(shipmentId, caseId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export function selectTask(task) {
  return (dispatch) => {
    dispatch({type: SELECT_TASK, payload: task});
  };
}

export function selectCase(oneCase) {
  return (dispatch) => {
    dispatch({type: SELECT_CASE, payload: oneCase});
  };
}

export function clearSelectedTask(userId) {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_TASK, payload: userId});
  };
}

export function clearSelectedCase(userId, titleString) {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_CASE, payload: {user: userId, title: titleString}});
  };
}

export function usersAccountError(error) {
  return {type: USER_ACCT_ERROR, payload: error};
}

/* ------------ resetAccountError --------------- */
export function resetAccountError() {
  return {type: RESET_ERROR, error: false, details: null};
}

/* ------------ toggle side menu --------------- */
export function toggleSideMenu(toggle) {
  return {type: TOGGLE_SIDE_MENU, payload: toggle};
}
