import {TiveCredentials} from '@shipwell/locus-sdk';
import {trackingProviderCredentialsList} from 'App/api/locus/trackingCredentials';
import {listDevices} from 'App/api/locus/tracking';

export async function getTiveCredentials() {
  const response = await trackingProviderCredentialsList({
    provider: 'tive'
  });

  const credentials = response.data.find((item) => item.provider === 'tive') as TiveCredentials;
  const defaultAccount = credentials?.accounts?.at(0);

  return {
    credentials,
    defaultAccount
  };
}

export async function isTiveConnected() {
  const response = await getTiveCredentials();
  return !!response.credentials;
}

export async function tryGetDefaultTiveAccount() {
  const credentials = await getTiveCredentials();

  if (!credentials) throw new Error('Could not get Tive devices, because Tive is not connected.');

  if (!credentials.defaultAccount) throw new Error('Could not find a default Tive account.');

  return credentials.defaultAccount;
}

export async function getTiveDevices() {
  const defaultAccount = await tryGetDefaultTiveAccount();

  const response = await listDevices({provider: 'tive'}, defaultAccount.id);

  return response.data;
}
