import {useQuery} from '@tanstack/react-query';
import {getCurrentBillTo} from 'App/api/shipwellUI/typed';
import {toTitleCase} from 'App/utils/globals';
import Loader from 'App/common/shipwellLoader';
import {BILL_TO_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

const BillToView = ({shipmentId, quoteId}: {shipmentId: string; quoteId?: string}) => {
  const currentBillToQuery = useQuery(
    [BILL_TO_QUERY_KEY, shipmentId, quoteId],
    async () => {
      const response = await getCurrentBillTo({
        shipmentId,
        quote: quoteId
      });
      return response?.data;
    },
    {staleTime: STATIC_DATA_STALE_TIME, enabled: !!shipmentId}
  );

  if (currentBillToQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const billToInfo = currentBillToQuery.data;

  if (!billToInfo) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <span>Billing ID: {billToInfo.billing_id || '--'}</span>
      <span>Bill-To Option: {billToInfo.direction ? toTitleCase(billToInfo.direction) : '--'}</span>
      <span>Company: {billToInfo.company_name || '--'}</span>
      <span>Contact Email: {billToInfo.contact_email || '--'}</span>
      <span>Contact Phone: {billToInfo.phone || '--'}</span>
    </div>
  );
};

export default BillToView;
