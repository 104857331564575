import {Link} from 'react-router';
import {createColumnHelper} from '@tanstack/react-table';
import {ServiceProvider, ServiceProviderCapabilitiesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ServiceProviderTags, ServiceProvidersOverflowMenu} from './components';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

const capabilityLabels: Record<ServiceProviderCapabilitiesEnum, string> = {
  TRANSLOAD: 'Transload',
  TERMINAL_FEE: 'Terminal fee',
  STORAGE: 'Storage'
};

const columnHelper = createColumnHelper<ServiceProvider>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: ({row}) => <Link to={`/service-providers/${row.original.id}`}>{row.original.name}</Link>
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (props) => (props.getValue() === 'ACTIVE' ? 'Active' : 'Inactive')
  }),
  columnHelper.accessor('capabilities', {
    header: 'Capabilities',
    cell: (props) => {
      const capabilities = props.getValue();
      return capabilities?.length ? capabilities.map((capability) => capabilityLabels[capability]).join(', ') : '--';
    },
    enableSorting: false
  }),
  columnHelper.accessor('mailing_address.formatted_address', {
    header: 'Address',
    cell: (props) => props.getValue() || '--',
    enableSorting: false
  }),
  columnHelper.accessor('contacts', {
    id: 'email',
    header: 'Email',
    cell: (props) => {
      const contacts = props.getValue();
      const email = contacts?.[0]?.email;
      return email || '--';
    },
    enableSorting: false
  }),
  columnHelper.accessor('contacts', {
    id: 'phone',
    header: 'Phone',
    cell: (props) => {
      const contacts = props.getValue();
      const phone = contacts?.[0]?.phone_number;
      return phone ? getPhoneHyperlink(phone) : '--';
    },
    enableSorting: false
  }),
  columnHelper.accessor('tags', {
    header: 'Tags',
    cell: (props) => {
      const tags = props.getValue();
      return tags ? <ServiceProviderTags tagIds={tags} /> : '--';
    },
    enableSorting: false
  }),
  columnHelper.accessor('contacts', {
    header: 'Contacts',
    cell: (props) => {
      const contacts = props.getValue();
      return (
        contacts?.map(({first_name, last_name}) => first_name + (last_name ? ` ${last_name}` : '')).join(', ') || '--'
      );
    },
    enableSorting: false
  }),
  columnHelper.display({
    header: 'Actions',
    cell: (props) => {
      const serviceProvider = props.row.original;
      return <ServiceProvidersOverflowMenu serviceProvider={serviceProvider} />;
    }
  })
];
