/* eslint-disable react/display-name */
import _ from 'lodash';
import moment from 'moment';
import {Link} from 'react-router';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {StopsItineraryCell, StopsItineraryOverlay} from 'App/components/stopEta';
import {formatDateTime, numberWithCommas, truncateNumber, formatMileage} from 'App/utils/globals';
import {checkShipmentModes, calculateShipmentTotals} from 'App/utils/globalsTyped';
import {renderStopDate} from 'App/utils/shipmentTableHelpers';
import {
  formatCurrency,
  IMPERIAL,
  METRIC,
  POUNDS,
  convertMilesToKilometers,
  convertPoundsToKilograms
} from 'App/utils/internationalConstants';
import store from 'App/routes/store';
import {getMissingDataByStop, getEdiDataErrors, getWarningContent} from 'App/containers/LoadBoard/utils';

const columns = {
  load_board_id: {
    Header: 'Load ID',
    id: 'load_board_id',
    minWidth: 120,
    accessor: (d) => d.load_board_id,
    sortable: false,
    Cell: (row) => {
      const {original: load, value} = row;
      let ediDataErrors = null;
      const tenders = load?.tenders || [];
      // if the load has an EDI tender we want to check for EDI data errors
      if (tenders.some((t) => t.external_edi_system_shipment_id)) {
        ediDataErrors = getEdiDataErrors(getMissingDataByStop(load?.stops));
      }
      return (
        <>
          {(ediDataErrors?.missingData || ediDataErrors?.unmatchedLocation) && (
            <Tooltip
              tooltipContent={getWarningContent(ediDataErrors)}
              tooltipClassname="tender-warning__tooltip"
              portal
            >
              <SvgIcon
                name="ErrorOutlined"
                color="$sw-warning"
                width="16"
                height="16"
                className="tender-warning__icon"
              />
            </Tooltip>
          )}
          <Link className="shipmentLink" to={`/load-board/${value}`}>
            {value}
          </Link>
        </>
      );
    }
  },
  bidding_status: {
    Header: 'Status',
    id: 'bidding_status',
    accessor: (d) => d.bidding_status,
    minWidth: 120,
    sortable: false,
    Cell: (row) => {
      return renderStatus(row);
    }
  },
  lowest_bid_amount: {
    Header: 'Your Bid',
    id: 'lowest_bid_amount',
    accessor: (d) => d.lowest_bid_amount,
    sortable: false,
    Cell: (row) => {
      if (row.value) {
        return (
          <Link className="shipmentLink" to={`/load-board/${row.original.load_board_id}`}>
            {formatCurrency(row.value, row.original.preferred_currency)}
          </Link>
        );
      }
      if (row.original.bidding_status === 'closed' || (row.original.tenders && row.original.tenders.length)) {
        return '--';
      }
      return (
        <>
          {row.original.spot_negotiation_carrier_status !== 'DO_NOT_USE' ? (
            <Link className="shipmentLink" to={`/load-board/${row.original.load_board_id}?bid=true`}>
              <i className="flaticon-plus pad-right" /> Bid
            </Link>
          ) : (
            '--'
          )}
        </>
      );
    }
  },
  customer: {
    Header: 'Shipper',
    id: 'customer',
    minWidth: 150,
    accessor: (d) => d.customer,
    sortable: false,
    Cell: (row) => {
      return row.value && row.value.name;
    }
  },
  pickup_location: {
    Header: 'Pickup',
    id: 'pickup_location',
    minWidth: 125,
    accessor: (d) => d.stops,
    sortable: false,
    Cell: (row) => {
      let pickUpStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_pickup && !pickUpStop) {
          pickUpStop = sortedStops[i];
        }
      }
      return pickUpStop
        ? `${pickUpStop.location?.address?.city},  ${pickUpStop.location?.address?.state_province}`
        : '';
    }
  },
  delivery_location: {
    Header: 'Delivery',
    id: 'delivery_location',
    minWidth: 125,
    accessor: (d) => d.stops,
    sortable: false,
    Cell: (row) => {
      let dropOffStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_dropoff) {
          dropOffStop = sortedStops[i];
        }
      }
      return dropOffStop
        ? `${dropOffStop.location?.address?.city},  ${dropOffStop.location?.address?.state_province}`
        : '';
    }
  },
  stops: {
    Header: 'Stops',
    id: 'stops',
    accessor: (d) => d.stops,
    sortable: false,
    minWidth: 75,
    Cell: (row) => {
      const countPick = row?.value?.filter((s) => s.is_pickup).length;
      const countDrop = row?.value?.filter((s) => s.is_dropoff).length;
      const {hasLTL} = checkShipmentModes(row?.original?.mode);
      return (
        <StopsItineraryOverlay stops={row.value} isLoadBoard hasLTL={hasLTL}>
          <StopsItineraryCell countPick={countPick} countDrop={countDrop} hasLTL={hasLTL} />
        </StopsItineraryOverlay>
      );
    }
  },
  requested_shipment_modes: {
    Header: () => (
      <span>
        Mode
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'requested_shipment_modes',
    accessor: (d) =>
      d.modes && d.modes.length > 0
        ? d.modes.map((mo, i) => {
            return mo.code + (i === d.modes.length - 1 ? '' : ', ');
          })
        : '--'
  },
  requested_equipment_types: {
    Header: () => (
      <span>
        Equipment
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'requested_equipment_types',
    accessor: (d) =>
      d.equipment_types && d.equipment_types.length > 0
        ? d.equipment_types.map((eq, i) => {
            return eq.name + (i === d.equipment_types.length - 1 ? '' : ', ');
          })
        : '--'
  },
  pickup_date: {
    Header: () => (
      <span>
        Pickup Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'pickup_date',
    sortable: true,
    accessor: (d) => d.stops,
    minWidth: 140,
    Cell: (row) => {
      let pickUpStop;
      const stops = row.original && row.original && row.original.stops;

      const sortedStops = stops && stops.sort((a, b) => a.ordinal_index - b.ordinal_index);
      if (sortedStops) {
        for (let i = 0; i < sortedStops.length; i++) {
          if (sortedStops[i].is_pickup && !pickUpStop) {
            pickUpStop = sortedStops[i];
          }
        }
      }
      return renderStopDate(pickUpStop, true);
    }
  },
  delivery_date: {
    Header: () => (
      <span>
        Delivery Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'delivery_date',
    sortable: true,
    accessor: (d) => d.stops,
    minWidth: 140,

    Cell: (row) => {
      let dropOffStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);

      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_dropoff) {
          dropOffStop = sortedStops[i];
        }
      }
      return renderStopDate(dropOffStop, true);
    }
  },
  lowest_rate: {
    Header: 'Lowest Rate',
    sortable: false,
    id: 'rate',
    filterable: false,
    accessor: (d) => d.quotes,
    minWidth: 140,
    Cell: (row) => {
      let quotes = row.value;
      //find the quote with the lowest rate

      if (quotes && quotes.length > 0) {
        quotes = _.sortBy(quotes, 'total');
        //display quotes in preferred_currency
        return formatCurrency(quotes[0].total, row.original.preferred_currency);
      }
      return 'No quotes';
    }
  },
  total_miles: {
    Header: () => {
      const {unitPreferences} = store.getState().userCompany;
      return (
        <span>
          {unitPreferences.system === METRIC ? 'Kilometers' : 'Miles'}
          <span className="btn-sort" />
        </span>
      );
    },
    sortable: true,
    id: 'total_miles',
    accessor: (d) => d.total_miles,
    Cell: (row) => {
      const {unitPreferences} = store.getState().userCompany;
      let distance = '--';
      if (row.value && !isNaN(row.value)) {
        if (unitPreferences.system === METRIC) {
          distance = convertMilesToKilometers(row.value);
        } else if (unitPreferences.system === IMPERIAL) {
          distance = formatMileage(row.value);
        }
      }
      return distance;
    }
  },
  created_at: {
    Header: () => (
      <span>
        Created
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'created_at',
    filterable: false,
    accessor: (d) => d.created_at,
    minWidth: 150,
    Cell: (row) => {
      return formatDateTime(row.value);
    }
  },
  expiresAt: {
    Header: 'Tender Expires',
    sortable: false,
    id: 'expiresAt',
    filterable: false,
    accessor: (d) => d.expires_at,
    minWidth: 150,
    Cell: ({row, original}) => {
      if (original.tenders && original.tenders.length && original.tenders[0].expires_at) {
        return formatDateTime(original.tenders[0].expires_at);
      }
      if (!row.value || !moment(row.value).isValid()) {
        return '--';
      }
      return formatDateTime(row.value);
    }
  },
  weight: {
    Header: () => {
      const {unitPreferences} = store.getState().userCompany;
      return <span>{unitPreferences.system === METRIC ? 'Weight (kg)' : 'Weight (lbs)'}</span>;
    },
    id: 'weight',
    accessor: (d) => d.line_items,
    Cell: (row) => {
      const {unitPreferences} = store.getState().userCompany;
      //show in KG if metric preferred
      if (unitPreferences.system === METRIC) {
        if (row.original?.total_weight_override?.value && row.original?.total_weight_override?.unit === POUNDS) {
          return (
            <div>
              {numberWithCommas(truncateNumber(convertPoundsToKilograms(row.original.total_weight_override.value), 0))}
            </div>
          );
        }
        let totals = {};
        if (row.value && row.value.length) {
          totals = calculateShipmentTotals({
            line_items: row.value,
            unitPreferences,
            totalWeight: row.original?.total_weight_override
          });
        }
        const total_weight = totals.weight;
        return total_weight ? (
          <div>{numberWithCommas(truncateNumber(convertPoundsToKilograms(total_weight), 0))}</div>
        ) : (
          '--'
        );
      }
      if (row.original?.total_weight_override?.value) {
        return <div>{numberWithCommas(truncateNumber(row.original.total_weight_override.value, 0))}</div>;
      }
      let totals = {};
      if (row.value && row.value.length) {
        totals = calculateShipmentTotals({
          line_items: row.value,
          unitPreferences,
          totalWeight: row.original?.total_weight_override
        });
      }
      const total_weight = numberWithCommas(truncateNumber(totals.weight, 0));
      return total_weight ? <div>{total_weight}</div> : '--';
    }
  },
  match_score: {
    Header: () => (
      <span>
        Match
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'match_score',
    accessor: (d) => d.match_score,
    Cell: (row) => {
      if (row.value) {
        return `${parseInt(row.value * 100)} %`;
      }
      return '--';
    }
  }
};

function renderStatus(row) {
  const bidding_status = row.value;
  return (
    <div className="status">
      <span className={`loadBoard__${bidding_status}`}>
        {bidding_status === 'tendered' ? 'Tender Request' : _.upperFirst(bidding_status)}
      </span>
    </div>
  );
}

export default columns;
