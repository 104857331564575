import {
  DefaultApi,
  OptimizeOrderSchema,
  Configuration,
  LoadSchema,
  PlanSchema,
  CompanySchema,
  CreateShipmentRequestBody,
  CompanyIncludes
} from '@shipwell/shipment-assembly-sdk';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {Shipment} from '@shipwell/backend-core-sdk';
import {getAccessToken, parseV3ApiResponseErrors} from 'App/api/utils';
import {decimalVersionHeader} from 'App/api/consts';

const basePath = process.env.SHIPWELL_SHIPMENT_ASSEMBLY_API_BASE_PATH;
const axiosInstance = axios.create({
  baseURL: basePath
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const parsed = parseV3ApiResponseErrors(error.response || {});
    throw parsed;
  }
);
// Shipment Assembly API
function createShipmentAssemblyApi() {
  return new DefaultApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    }),
    basePath,
    axiosInstance
  );
}
export function createLoadPlan(plan: PlanSchema, isUsingDecimals = false, options = {}) {
  return createShipmentAssemblyApi().createPlanPost(plan, {
    ...options,
    ...(isUsingDecimals ? {headers: decimalVersionHeader} : {})
  });
}

export function getLoadPlan(planId: PlanSchema['id'] = '', options = {}) {
  return createShipmentAssemblyApi().getPlanGet(planId, options);
}

export const updatePlanName = (planId: string, plan: PlanSchema) =>
  createShipmentAssemblyApi().updatePlanNamePut(planId, plan);

export function addOrdersToLoad(
  loadId: LoadSchema['id'] = '',
  purchaseOrdersToAdd: Array<OptimizeOrderSchema>,
  options = {}
) {
  return createShipmentAssemblyApi().addOrdersLoadPost(loadId, purchaseOrdersToAdd, options);
}

export function removeOrdersFromLoad(
  loadId: LoadSchema['id'] = '',
  purchaseOrderIdsToRemove: Array<OptimizeOrderSchema['external_id']>,
  options = {}
) {
  return createShipmentAssemblyApi().deleteOrdersLoadPost(loadId, purchaseOrderIdsToRemove, options);
}

export function optimizeLoadPost(loadId: string, options = {}) {
  return createShipmentAssemblyApi().optimizeLoadPost(loadId, options);
}

export type LoadOptimizationsOptions = {
  includes?: CompanyIncludes[];
  page?: number;
  size?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  options?: unknown;
};

/**
Get a list of load plans that have been created
 */
export function getListPlans(opts: LoadOptimizationsOptions = {}) {
  return createShipmentAssemblyApi().listPlansGet(opts.page, opts.size, opts.sort, opts.direction);
}

/**
Get details on a load plan
 */
export function getLoadDetail(loadId: LoadSchema['id'] = '', opts = {}) {
  return createShipmentAssemblyApi().getLoadGet(loadId, opts);
}

/**
Create a shipment from a load
 */
export function createShipmentFromPlan(loadId: LoadSchema['id'] = '', opts = {}) {
  return createShipmentAssemblyApi().approveLoadPut(loadId, opts);
}
/**
Updates fields that must be set before shipment is created
 */
export function updateLoadShipmentFields(loadId: LoadSchema['id'] = '', loadSchema: LoadSchema, opts = {}) {
  return createShipmentAssemblyApi().updateLoadPut(loadId, loadSchema, opts);
}

/**
Get company settings by id
*/
export function getCompanySettingsById(companyId: CompanySchema['id'] = '') {
  return createShipmentAssemblyApi().getCompanyByIdGet(companyId);
}

/**
Get company settings list
*/
export function getCompanySettingsList(opts: LoadOptimizationsOptions = {}) {
  return createShipmentAssemblyApi().listCompaniesGet(opts.includes, opts.page, opts.size, opts.sort, opts.direction);
}

/**
Create new company settings
*/
export function createCompanySettings(company: CompanySchema = {}, opts = {}) {
  return createShipmentAssemblyApi().createCompanyPost(company, opts);
}

/**
Update company settings by id
*/
export function updateCompanySettingsById(companyId: CompanySchema['id'] = '', company: CompanySchema = {}, opts = {}) {
  return createShipmentAssemblyApi().updateCompanyByIdPut(companyId, company, opts);
}

/**
Archive company config settings
*/
export function archiveCompanySettings(companyId: CompanySchema['id'] = '', opts = {}) {
  return createShipmentAssemblyApi().archiveCompanyDelete(companyId, opts);
}

export function reorderStops(loadId: LoadSchema['id'] = '', reorderedStopIds: string[], opts = {}) {
  return createShipmentAssemblyApi().reorderStopsPost(loadId, reorderedStopIds, opts);
}
export function downloadPlanReport(planId: PlanSchema['id'] = '', opts = {}) {
  return createShipmentAssemblyApi().getPlanPdfGet(planId, opts);
}

export function acknowledgeErrors(loadId: LoadSchema['id'] = '', opts = {}) {
  return createShipmentAssemblyApi().acknowledgeErrorsPost(loadId, opts);
}

export function createShipment(
  createShipmentRequestBody: CreateShipmentRequestBody,
  isUsingDecimals: boolean,
  opts = {}
) {
  //the SDK indicates that a successful promise returns Promise<AxiosResponse<object>>, which does not
  //allow us to access the returned shipment object.
  return createShipmentAssemblyApi().createShipmentPost(createShipmentRequestBody, {
    opts,
    ...(isUsingDecimals ? {headers: decimalVersionHeader} : {})
  }) as Promise<AxiosResponse<Shipment>>;
}
