import {Formik, Form, FormikHelpers} from 'formik';
import {WithRouterProps, withRouter} from 'react-router';
import {
  CompanyModePreferences,
  FMCSACarrierPolicy,
  RMISCarrierPolicy,
  SaferWatchCarrierPolicy,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import invariant from 'tiny-invariant';
import {compose} from 'recompose';
import {useMemo} from 'react';
import {AxiosError} from 'axios';
import {
  FMCSACardSettings,
  FMCSABaseValues,
  saferwatchCardSettings,
  saferwatchBaseValues,
  validationSchema,
  massageFormValues,
  RMISCardSettings,
  RMISBaseValues,
  CarrierInsuranceCoverageRulesBaseValues
} from './carrierComplianceConstants.js';
import CarrierComplianceCard from './components/carrierComplianceCard';
import CarrierDeclaredValueAmounts from './components/CarrierDeclaredValueAmounts';
import {CompanyCarrierComplianceDetailsFormikValuesType} from './types.js';
import FormFooter from 'App/formComponents/formSections/formFooter';
import PageHeader from 'App/common/pageHeader';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {
  useRMISPolicyQuery,
  useFMCSAPolicyQuery,
  useSaferwatchPolicyQuery,
  useUpdateFMCSAPolicyMutation,
  useUpdateSaferwatchPolicyMutation,
  useUpdateRMISPolicyMutation,
  useUserMe,
  useCompanyPreferences,
  useUpdateCompanyPreferencesMutation
} from 'App/data-hooks';
import ShipwellLoader from 'App/common/shipwellLoader';
import withFlags from 'App/utils/withFlags';
import {convertShipwellAxiosError} from 'App/utils/errors';

export type CompanyCarrierComplianceDetailsProps = WithRouterProps &
  WithStatusToastProps & {
    fiCarrierComplianceInsurance?: boolean;
  };

const CompanyCarrierComplianceDetails = ({
  fiCarrierComplianceInsurance,
  setError,
  setSuccess,
  router
}: CompanyCarrierComplianceDetailsProps) => {
  const {data: user, isInitialLoading: isUserInitialLoading} = useUserMe();

  const isSaferWatchEnabled = Boolean(user?.company?.feature_flags?.saferwatch_enabled);
  const isRMISEnabled = Boolean(user?.company?.feature_flags?.rmis_enabled);

  const {rmisPolicy, isRMISInitialLoading} = useRMISPolicyQuery({
    enabled: isSaferWatchEnabled,
    onError: () =>
      setError('Something unexpected happened!', 'An error occurred while fetching RMIS policy, please try again.')
  });
  const {fmcsaPolicy, isFMCSAInitialLoading} = useFMCSAPolicyQuery({
    onError: () =>
      setError('Something unexpected happened!', 'An error occurred while fetching FMCSA policy, please try again.')
  });
  const {saferwatchPolicy, isSaferwatchInitialLoading} = useSaferwatchPolicyQuery({
    enabled: user?.company?.feature_flags?.saferwatch_enabled,
    onError: () =>
      setError(
        'Something unexpected happened!',
        'An error occurred while fetching SaferWatch policy, please try again.'
      )
  });
  const {data: companyPreferences, isInitialLoading: isCompanyPreferencesInitialLoading} = useCompanyPreferences(
    user?.company?.id,
    {
      onError: () =>
        setError(
          'Something unexpected happened!',
          'An error occurred while fetching company preferences, please try again.'
        )
    }
  );

  const {updateFMCSAPolicyAsync} = useUpdateFMCSAPolicyMutation();
  const {updateSaferwatchPolicyAsync} = useUpdateSaferwatchPolicyMutation();
  const {updateRMISPolicyAsync} = useUpdateRMISPolicyMutation();

  const {updateCompanyPreferencesAsync} = useUpdateCompanyPreferencesMutation();

  const handleCarrierComplianceSubmit = async (
    values: CompanyCarrierComplianceDetailsFormikValuesType,
    formikHelpers: FormikHelpers<CompanyCarrierComplianceDetailsFormikValuesType>
  ) => {
    formikHelpers.setSubmitting(true);
    let errors: string[] = [];
    try {
      if (fmcsaPolicy && values.FMCSAValues) {
        const cleanedValues = massageFormValues(FMCSABaseValues, values.FMCSAValues) as FMCSACarrierPolicy;
        await updateFMCSAPolicyAsync(
          {
            fMCSACarrierPolicy: cleanedValues
          },
          {
            onError: (error: AxiosError<ShipwellError>) => {
              const newAgeError = convertShipwellAxiosError(error);

              const errorMessage: string =
                newAgeError.response?.data.errors[0].detail ||
                'An error occurred while updating FMCSA policy, please try again.';
              errors = [...errors, errorMessage];
            }
          }
        );
      }
      if (isSaferWatchEnabled && saferwatchPolicy && values.saferwatchValues) {
        const cleanedValues = massageFormValues(
          saferwatchBaseValues,
          values.saferwatchValues
        ) as SaferWatchCarrierPolicy;
        await updateSaferwatchPolicyAsync(
          {
            saferWatchCarrierPolicy: cleanedValues
          },
          {
            onError: (error: AxiosError<ShipwellError>) => {
              const newAgeError = convertShipwellAxiosError(error);

              const errorMessage: string =
                newAgeError.response?.data.errors[0].detail ||
                'An error occurred while updating Saferwatch policy, please try again.';
              errors = [...errors, errorMessage];
            }
          }
        );
      }
      if (isRMISEnabled && rmisPolicy && values.RMISValues) {
        invariant(rmisPolicy?.id, 'RMIS policy id is required');
        const cleanedValues = massageFormValues(RMISBaseValues, values.RMISValues) as RMISCarrierPolicy;
        await updateRMISPolicyAsync(
          {
            rmisPolicyId: rmisPolicy?.id,
            rMISCarrierPolicy: cleanedValues
          },
          {
            onError: (error: AxiosError<ShipwellError>) => {
              const newAgeError = convertShipwellAxiosError(error);

              const errorMessage: string =
                newAgeError.response?.data.errors[0].detail ||
                'An error occurred while updating RMIS policy, please try again.';
              errors = [...errors, errorMessage];
            }
          }
        );
      }
      if (fiCarrierComplianceInsurance && user?.company?.id && values.CarrierInsuranceDefaultValues) {
        await updateCompanyPreferencesAsync(
          {
            companyId: user?.company?.id,
            companyPreferences: {
              ...companyPreferences, // keep all other preferences as-is
              mode_preferences: massageFormValues(
                CarrierInsuranceCoverageRulesBaseValues,
                values.CarrierInsuranceDefaultValues
              ) as CompanyModePreferences
            }
          },
          {
            onError: (error: AxiosError<ShipwellError>) => {
              const newAgeError = convertShipwellAxiosError(error);

              const errorMessage: string =
                newAgeError.response?.data.errors[0].detail ||
                'An error occurred while updating carrier insurance default values, please try again.';
              errors = [...errors, errorMessage];
            }
          }
        );
      }
    } finally {
      formikHelpers.setSubmitting(false);
      if (errors?.length) {
        setError('Something unexpected happened!', errors.join('\n'));
      } else {
        setSuccess('Success!', 'Carrier compliance settings have been updated.');
      }
    }
  };

  const integrationsRedirect = () => router.push('/company/integrations');

  const initialValues: CompanyCarrierComplianceDetailsFormikValuesType = useMemo(
    () => ({
      FMCSAValues: massageFormValues(FMCSABaseValues, fmcsaPolicy) as FMCSACarrierPolicy,
      saferwatchValues: massageFormValues(saferwatchBaseValues, saferwatchPolicy) as SaferWatchCarrierPolicy,
      RMISValues: massageFormValues(RMISBaseValues, rmisPolicy) as RMISCarrierPolicy,
      CarrierInsuranceDefaultValues: massageFormValues(
        CarrierInsuranceCoverageRulesBaseValues,
        companyPreferences?.mode_preferences
      ) as CompanyModePreferences
    }),
    [fmcsaPolicy, saferwatchPolicy, rmisPolicy, companyPreferences?.mode_preferences]
  );

  const isLoading =
    isFMCSAInitialLoading ||
    isSaferwatchInitialLoading ||
    isRMISInitialLoading ||
    isUserInitialLoading ||
    isCompanyPreferencesInitialLoading;

  return (
    <div className="w-full">
      <PageHeader title="Carrier Compliance" />
      {isLoading ? (
        <ShipwellLoader />
      ) : (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleCarrierComplianceSubmit}
        >
          {({resetForm, dirty, values}) => (
            <Form role="form">
              <div className={`p-6 ${dirty ? 'pb-24' : ''}`}>
                <div className="pb-4">
                  <CarrierDeclaredValueAmounts modePreferences={values.CarrierInsuranceDefaultValues} />
                </div>
                <div className="pb-4">
                  <CarrierComplianceCard cardSettings={FMCSACardSettings} carrierSettings={values.FMCSAValues} />
                </div>
                <div className="pb-4">
                  <CarrierComplianceCard
                    cardSettings={saferwatchCardSettings}
                    carrierSettings={values.saferwatchValues}
                    cardDisabled={!isSaferWatchEnabled}
                    redirect={integrationsRedirect}
                  />
                </div>
                <div className="pb-4">
                  <CarrierComplianceCard
                    cardSettings={RMISCardSettings}
                    carrierSettings={values.RMISValues}
                    cardDisabled={!isRMISEnabled}
                    redirect={integrationsRedirect}
                  />
                </div>
              </div>
              {dirty ? (
                <FormFooter role="region" primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} />
              ) : null}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
const CompanyCarrierComplianceDetailsWithStatusToastsAndRouter = compose<CompanyCarrierComplianceDetailsProps, unknown>(
  WithStatusToasts,
  withRouter,
  withFlags('fiCarrierComplianceInsurance')
)(CompanyCarrierComplianceDetails);

export default CompanyCarrierComplianceDetailsWithStatusToastsAndRouter;
