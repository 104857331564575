import {
  ACT_AS_COMPANY,
  FETCH_BROKER_SHIPPER_RELATIONSHIPS,
  FETCH_BROKER_SHIPPER_RELATIONSHIPS_FOR_HEADER,
  REQ_CUSTOMER_LIST,
  CREATE_BROKER_SHIPPER_RELATIONSHIP,
  EDIT_BROKER_SHIPPER_RELATIONSHIP,
  GET_SHIPPER_CARRIER_RELATIONSHIPS,
  CLEAR_SELECTED_SHIPPER_CARRIER,
  EDIT_SHIPPER_CARRIER_RELATIONSHIPS,
  SELECT_CUSTOMER,
  CLEAR_SELECTED_CUSTOMER,
  FETCH_CARRIERS,
  GET_BROKER_DASHBOARDS,
  REQ_BROKER_DATA,
  GET_BROKER_LOGOS,
  FETCH_BROKER_SHIPMENTS,
  FETCH_BROKER_AGENTS,
  CREATE_BROKER_AGENT,
  EDIT_BROKER_AGENT,
  DELETE_BROKER_AGENT,
  CREATE_BROKER_SHIPPER,
  EDIT_BROKER_SHIPPER,
  UNAUTH_BROKER_USER
} from '../actions/types';

const INITIAL_STATE = {
  shippers: [],
  agents: [],
  actingAsCompany: null,
  brokerShipments: [],
  brokerLogos: [
    {
      image_type: 'INLINE_WHITE',
      logo: '/images/svgs/shipwell-logo-white.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'INLINE_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_WHITE',
      logo: '/images/shipwell_logo_white.png',
      company_name: 'Shipwell'
    }
  ],
  selectedCustomer: {
    can_quote_with_rates: true,
    can_book_with_rates: true,
    markup: {
      ftl_markup_type: 1,
      ltl_markup_type: 1,
      ltl_markup_flat_fee: '0',
      ftl_markup_flat_fee: '0',
      ftl_markup_percentage: '0',
      ltl_markup_percentage: '0',
      ltl_markup_minimum: '0'
    },
    company: {},
    user: {}
  },
  shipperRelationships: [],
  shipperRelationshipsForHeader: [],
  selectedShipperCarrierRelationships: [],
  loadingCustomerRelationships: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACT_AS_COMPANY:
      return {
        ...state,
        actingAsCompany: action.payload
      };
    case FETCH_BROKER_SHIPPER_RELATIONSHIPS:
      return {
        ...state,
        shipperRelationships: action.payload,
        loadingCustomerRelationships: false
      };

    case FETCH_BROKER_SHIPPER_RELATIONSHIPS_FOR_HEADER:
      return {
        ...state,
        shipperRelationshipsForHeader: action.payload,
        loadingCustomerRelationships: false
      };

    case REQ_CUSTOMER_LIST:
      return {
        ...state,
        loadingCustomerRelationships: true
      };

    case CREATE_BROKER_SHIPPER_RELATIONSHIP:
      let currentList = state.shipperRelationships;
      currentList.results.push(action.payload);
      currentList.count++;
      return {
        ...state,
        shipperRelationships: currentList
      };
    case EDIT_BROKER_SHIPPER_RELATIONSHIP:
      currentList = state.shipperRelationships;
      if (currentList && currentList.results) {
        for (var i = 0; i < currentList.results.length; i++) {
          if (currentList.results[i].id === action.payload.id) {
            currentList.results[i] = action.payload;
          }
        }
      }
      return {
        ...state,
        shipperRelationships: currentList
      };

    case GET_SHIPPER_CARRIER_RELATIONSHIPS:
      // transform this data so it's easier to use in a form?
      const carriers = action.payload;
      const formattedCarrierList = [];
      for (let i = 0; i < carriers.length; i++) {
        let carrierObj = {};
        let subObj = {};
        subObj = {
          ltl_markup_type: carriers[i].markup.ltl_markup_type === 'flat_fee' ? 1 : 2,
          ltl_markup_flat_fee: carriers[i].markup.ltl_markup_flat_fee,
          ltl_markup_percentage: parseFloat(carriers[i].markup.ltl_markup_percentage * 100).toFixed(1),
          ftl_markup_type: carriers[i].markup.ftl_markup_type === 'flat_fee' ? 1 : 2,
          ftl_markup_percentage: parseFloat(carriers[i].markup.ftl_markup_percentage * 100).toFixed(1),
          ftl_markup_flat_fee: carriers[i].markup.ftl_markup_flat_fee,
          ltl_markup_minimum: !isNaN(carriers[i].markup.ltl_markup_minimum) ? carriers[i].markup.ltl_markup_minimum : 0
        };
        carrierObj = {
          id: carriers[i].id,
          enabled: !carriers[i].is_hidden,
          markup: subObj,
          name: carriers[i].company.name,
          company: carriers[i].company,
          shipper_relationship: carriers[i].shipper_relationship,
          is_modified: carriers[i].is_modified
        };
        formattedCarrierList.push(carrierObj);
      }
      return {
        ...state,
        selectedShipperCarrierRelationships: {
          customer_carrier: formattedCarrierList
        }
      };

    case CLEAR_SELECTED_SHIPPER_CARRIER:
      return {
        ...state,
        selectedShipperCarrierRelationships: []
      };

    case EDIT_SHIPPER_CARRIER_RELATIONSHIPS:
      //right now, this isn't visible immediately, so not doing any changes
      return {
        ...state
      };

    case SELECT_CUSTOMER:
      const customer = action.payload;
      if (!customer.markup) {
        customer.markup = {};
      }
      customer.markup.ltl_markup_type = customer.markup.ltl_markup_type === 'flat_fee' ? 1 : 2;
      customer.markup.ftl_markup_type = customer.markup.ftl_markup_type === 'flat_fee' ? 1 : 2;
      customer.markup.ltl_markup_percentage = +(customer.markup.ltl_markup_percentage * 100).toFixed(1);
      customer.markup.ftl_markup_percentage = +(customer.markup.ftl_markup_percentage * 100).toFixed(1);

      return {
        ...state,
        selectedCustomer: customer
      };
    case CLEAR_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: INITIAL_STATE.selectedCustomer
      };
    case FETCH_CARRIERS:
      return {
        ...state,
        carriers: action.payload
      };
    case GET_BROKER_DASHBOARDS:
      return {
        ...state,
        brokerDashboards: action.payload
      };
    case REQ_BROKER_DATA:
      return {
        ...state,
        isLoading: true
      };
    case GET_BROKER_LOGOS:
      return {
        ...state,
        brokerLogos: action.payload
      };
    case FETCH_BROKER_SHIPMENTS:
      return {
        ...state,
        brokerShipments: action.payload,
        isLoading: false
      };
    case FETCH_BROKER_AGENTS:
      return {
        ...state,
        agents: action.payload
      };
    case CREATE_BROKER_AGENT:
      let currentAgents = state.agents;
      currentAgents.push(action.payload);
      return {
        ...state,
        agents: currentAgents
      };
    case EDIT_BROKER_AGENT:
      currentAgents = state.agents;
      for (let i = 0; i < currentAgents.length; i++) {
        if (currentAgents[i].id === action.payload.id) {
          currentAgents[i] = action.payload;
        }
      }
      return {
        ...state,
        agents: currentAgents
      };
    case DELETE_BROKER_AGENT:
      currentAgents = state.agents;
      for (let i = 0; i < currentAgents.length; i++) {
        if (currentAgents[i].id === action.payload) {
          currentAgents.splice(i, 1);
        }
      }
      return {
        ...state,
        agents: currentAgents
      };
    case CREATE_BROKER_SHIPPER:
      const currentShippers = state.shippers;
      currentShippers.push(action.payload);
      return {
        ...state,
        shippers: currentShippers
      };
    case EDIT_BROKER_SHIPPER:
      const nowShippers = state.shippers;
      for (let i = 0; i < nowShippers.length; i++) {
        if (nowShippers[i].id === action.payload.id) {
          nowShippers[i] = action.payload;
        }
      }
      return {
        ...state,
        shippers: nowShippers
      };
    case UNAUTH_BROKER_USER:
      const brokerLogos = state.brokerLogos;
      state = undefined;
      return {
        ...state,
        brokerLogos: brokerLogos
      };
    default:
      return state;
  }
}
