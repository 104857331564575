/* eslint-disable react/jsx-props-no-spreading */
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {object, mixed, string} from 'yup';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import CustomDocumentViewLayout from './CustomDocumentViewLayout';
import DocumentFields from 'App/formComponents/formSections/documentFields';
import {DocumentDisplay, DocumentMetadata as DocumentMetadataComponent} from 'App/components/DocumentView';

/** Default values needed for Formik */
const defaultFormValues = {
  file: null,
  description: null
};

export const validationSchema = object().shape({
  file: mixed().required('File is required'),
  description: string().nullable().required('Description is required.')
});

/**
 * Document Form
 */
const DocumentForm = (props) => {
  const {edit, values, onSubmit, onCancel, isUpload = true} = props;

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit customer values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, isSubmitting, dirty, ...props}) => (
        <form noValidate="novalidate" className="h-full">
          {!isUpload ? (
            <CustomDocumentViewLayout>
              <div className="flex flex-col content-center justify-start gap-5">
                <DocumentMetadataComponent document={values} />
                <div className="mx-4">
                  <DocumentFields {...props} isUpload={isUpload} />
                </div>
              </div>
              <DocumentDisplay document={values} />
            </CustomDocumentViewLayout>
          ) : null}
          {isUpload && <DocumentFields {...props} isUpload={isUpload} />}
          {(!edit || dirty) && (
            <div className="page-footer mt-5 flex w-full flex-row-reverse gap-5">
              {onSubmit && (
                <DeprecatedButton
                  disabled={isSubmitting}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                  onClick={handleSubmit}
                >
                  Save
                </DeprecatedButton>
              )}
              {onCancel && (
                <DeprecatedButton variant="secondary" onClick={handleCancel}>
                  Cancel
                </DeprecatedButton>
              )}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

DocumentForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  isUpload: PropTypes.bool
};

export default DocumentForm;
