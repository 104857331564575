import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {Button, Pagination, Table, OverlayTrigger, Tooltip, Row, Col} from 'react-bootstrap';
import ReactTable from 'react-table-6';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DriverForm from '../forms/DriverForm';
import * as actions from 'App/actions/carriers';
import * as userActions from 'App/actions/users';
import './_manage-drivers.scss';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import 'react-table-6/react-table.css';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    isLoading: state.carriers.isLoading,
    drivers: state.carriers.drivers,
    hos: state.carriers.hos,
    companyUsers: state.users.companyUsers
  }),
  {...actions, ...userActions}
)
export default class ManageDrivers extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.getDrivers = this.getDrivers.bind(this);
    this.onDriverFormSubmit = this.onDriverFormSubmit.bind(this);

    this.state = {
      showAddDriverModal: false,
      showCreate: false,
      pullingFromProvider: false,
      pagination: {
        page: 1,
        pageSize: 10
      },
      isLoading: false
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getDrivers(this.props.company.id, this.props.company.carrier.id);
      this.setState(
        {isLoading: true},
        function () {
          this.props.getAllHOS(this.props.company.carrier.id).then((response) => {
            this.setState({isLoading: false});
          });
        }.bind(this)
      );
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.company && this.props.company !== nextProps.company) {
      this.getDrivers(nextProps.company.id, nextProps.company.carrier.id);
      this.setState(
        {isLoading: true},
        function () {
          this.props.getAllHOS(nextProps.company.carrier.id).then((response) => {
            this.setState({isLoading: false});
          });
        }.bind(this)
      );
    }
  }

  getDrivers(companyId, carrierId) {
    this.props.getDrivers(companyId, this.state.pagination);
  }

  convertMinToHourMin(min) {
    const h = Math.floor(min / 60);
    const m = min % 60;
    if (min === -1) {
      return '--';
    }
    return (h.toString().length < 2 ? '0' + h : h) + ' hr ' + (m.toString().length < 2 ? '0' + m : m) + ' min';
  }

  onDriverFormSubmit(attrs, pulling) {
    const body = {body: attrs};
    return this.props.postDriver(this.props.company.id, body, pulling).then((response) => {
      this.setState({pullingFromProvider: false});
    });
  }

  render() {
    return (
      <div className="driver content-wrapper">
        <section className="content">
          <Paper className="driver__card">
            <div className="driver__toolbar">
              <Button
                bsStyle="default"
                disabled={this.state.pullingFromProvider}
                onClick={() => {
                  this.setState({pullingFromProvider: true}, () => this.onDriverFormSubmit({}, true));
                }}
              >
                {this.state.pullingFromProvider ? (
                  <span>
                    <i className="icon icon-Restart rotate" />
                    &nbsp;Syncing
                  </span>
                ) : (
                  <span>
                    <i className="icon icon-Download pad-right" />
                    Sync from ELD Provider
                  </span>
                )}
              </Button>
              <Button bsStyle="link" onClick={() => this.context.router.push('/manage_drivers/new_driver')}>
                + Add Driver
              </Button>
            </div>
            <div className="driver__table">
              <ReactTable
                data={this.props.drivers ? this.props.drivers.results : []}
                pages={this.props.drivers ? this.props.drivers.total_pages : 1}
                loading={this.props.isLoading || this.state.isLoading}
                manual
                minRows={10}
                defaultPageSize={this.state.pagination.pageSize}
                pageSizeOptions={[10, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'first_name',
                    desc: false
                  }
                ]}
                onFetchData={function (state, instance) {
                  const ordering = [];
                  for (let i = 0; i < state.sorted.length; i++) {
                    ordering.push((state.sorted[i].desc ? '-' : '') + state.sorted[i].id);
                  }
                  if (!_.isEmpty(this.props.company)) {
                    this.props
                      .getDrivers(this.props.company.id, {
                        page: state.page + 1,
                        pageSize: state.pageSize,
                        ordering: ordering.join(',')
                      })
                      .then((response) => {
                        const pagination = this.state.pagination;
                        pagination.page = state.page + 1;
                        pagination.pageSize = state.pageSize;
                        pagination.ordering = ordering;
                        this.setState({pagination: pagination});
                      });
                  }
                }.bind(this)}
                onPageChange={(page) => {
                  document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
                }}
                getTrGroupProps={(state, rowInfo, column, instance) => {
                  if (!rowInfo) {
                    return {
                      className: 'no-content'
                    };
                  }
                  return '';
                }}
                columns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    sortable: false,
                    accessor: (d) => d.user,
                    Cell: (row) => {
                      return <span>{row.value.first_name + ' ' + row.value.last_name}</span>;
                    }
                  },
                  {
                    Header: 'Phone',
                    sortable: false,
                    id: 'phone_number',
                    accessor: (d) => d.phone_number,
                    Cell: (row) => {
                      return row.value ? <span>{getPhoneHyperlink(row.value)} </span> : '';
                    }
                  },
                  {
                    Header: 'Email',
                    sortable: false,
                    id: 'email',
                    accessor: (d) => d.user.email
                  },
                  {
                    Header: 'Status',
                    sortable: false,
                    id: 'status',
                    accessor: (d) => d.status,
                    Cell: (row) => {
                      let myStatus;
                      if (this.props.hos && this.props.hos.results && this.props.hos.results.length > 0) {
                        for (let i = 0; i < this.props.hos.results.length; i++) {
                          if (this.props.hos.results[i].driver_id === row.original.external_id) {
                            myStatus = this.props.hos.results[i].status;
                          }
                        }
                      }
                      return this.state.isLoading ? (
                        <div>--</div>
                      ) : myStatus ? (
                        <div>{myStatus.split('_').join(' ').toUpperCase()}</div>
                      ) : (
                        <div>--</div>
                      );
                    }
                  },

                  {
                    Header: () => (
                      <div>
                        HOS
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">
                              <Grid container>
                                <Grid item sm={4}>
                                  <strong>Cycle:</strong>
                                </Grid>
                                <Grid item sm={8}>
                                  On Duty and Driving Time available in this cycle
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item sm={4}>
                                  <strong>Drive:</strong>
                                </Grid>
                                <Grid item sm={8}>
                                  Driving time available in this shift
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item sm={4}>
                                  <strong>Shift:</strong>
                                </Grid>
                                <Grid item sm={8}>
                                  On Duty time available in this shift
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item sm={4}>
                                  <strong>Break:</strong>
                                </Grid>
                                <Grid item sm={8}>
                                  Time spent on break i.e. Off duty or in the sleeper berth
                                </Grid>
                              </Grid>
                            </Tooltip>
                          }
                        >
                          <a tabIndex="-1">
                            <i className="icon icon-Info pad-left" aria-hidden="true" />
                          </a>
                        </OverlayTrigger>
                      </div>
                    ),
                    sortable: false,
                    id: 'hos',
                    accessor: (d) => d.hos,
                    Cell: (row) => {
                      let myHOS;
                      if (this.props.hos && this.props.hos.results && this.props.hos.results.length > 0) {
                        for (let i = 0; i < this.props.hos.results.length; i++) {
                          if (this.props.hos.results[i].driver_id === row.original.external_id) {
                            myHOS = this.props.hos.results[i].hos;
                          }
                        }
                      }
                      return this.state.isLoading ? (
                        <div>--</div>
                      ) : myHOS ? (
                        <div>
                          <Row>
                            <Col sm={4}>Cycle:</Col>
                            <Col sm={8}>{this.convertMinToHourMin(myHOS.cycle)}</Col>
                          </Row>
                          <Row>
                            <Col sm={4}>Drive:</Col>
                            <Col sm={8}>{this.convertMinToHourMin(myHOS.drive)}</Col>
                          </Row>
                          <Row>
                            <Col sm={4}>Shift:</Col>
                            <Col sm={8}>{this.convertMinToHourMin(myHOS.shift)}</Col>
                          </Row>
                          <Row>
                            <Col sm={4}>Break:</Col>
                            <Col sm={8}>{this.convertMinToHourMin(myHOS._break)}</Col>
                          </Row>
                        </div>
                      ) : (
                        <div>--</div>
                      );
                    }
                  }
                ]}
                className="-highlight -striped"
                previousText={<i className="icon icon-Left" />}
                nextText={<i className="icon icon-Right" />}
                rowsText=""
                noDataText="No Drivers"
                LoadingComponent={ShipwellLoader}
              />
            </div>
          </Paper>
        </section>
      </div>
    );
  }
}
