import {useState} from 'react';
import {Field} from 'formik';
import {CarrierRelationshipsApiCarrierRelationshipsGetRequest} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikSelect, Pill} from '@shipwell/shipwell-ui';
import {toTitleCase} from 'App/utils/globals';
import {useCarrierRelationships} from 'App/data-hooks';

const statusTypes = {ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE', DO_NOT_USE: 'DO_NOT_USE'};

interface Carrier {
  label: string;
  carrierName: string;
  carrierStatus: 'ACTIVE' | 'INACTIVE' | 'DO_NOT_USE';
  carrierFAVRid: string;
}

export const formatCarrierSearchOptions = (carrier: Carrier, contextWrapper: {context: string}) => {
  /* upon selection, only show carrier + POC's names, not carrier status */
  if (contextWrapper.context === 'value') {
    return carrier.label;
  }
  return (
    <div className="flex items-center justify-between">
      <span>{carrier.label}</span>
      <Pill
        UNSAFEclassName="w-32"
        variant={
          [statusTypes.INACTIVE, statusTypes.DO_NOT_USE].includes(carrier?.carrierStatus) ? 'inactive' : 'primary'
        }
      >
        {carrier.carrierStatus ? toTitleCase(carrier.carrierStatus) : 'Missing Status'}
      </Pill>
    </div>
  );
};

const CarrierField = ({fieldName = 'carrier'}: {fieldName?: string}) => {
  const [carrierQueryOptions, setCarrierQueryOptions] = useState<CarrierRelationshipsApiCarrierRelationshipsGetRequest>(
    {}
  );

  const {
    isLoading,
    context: {formattedCarrierInformation}
  } = useCarrierRelationships(carrierQueryOptions);

  return (
    <Field
      required
      label="Carrier"
      name={fieldName}
      getOptionValue={(option: Carrier) => option.carrierFAVRid}
      onInputChange={(val: string) => setCarrierQueryOptions({q: val})}
      isLoading={isLoading}
      component={FormikSelect}
      options={formattedCarrierInformation}
      formatOptionLabel={formatCarrierSearchOptions}
      isOptionDisabled={(option: Carrier) => option.carrierStatus !== statusTypes.ACTIVE}
    />
  );
};

export default CarrierField;
