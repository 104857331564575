import {useQuery} from '@tanstack/react-query';
import {createColumnHelper, flexRender} from '@tanstack/react-table';
import {ShipmentLineItem} from '@shipwell/backend-core-sdk';
import {useGetCompanyPreferences} from '../../cells/useGetCompanyPreferences';
import {getFullShipmentDetails} from 'App/api/shipment/typed';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {numberWithCommas} from 'App/utils/globals';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {TableCell} from 'App/components/TypedTable/baseComponents';
import ShipwellLoader from 'App/common/shipwellLoader';

export const LineItemsTooltip = ({shipmentId}: {shipmentId: string}) => {
  const {unitPreferences} = useGetCompanyPreferences();

  const shipmentQuery = useQuery(
    [SHIPMENTS_QUERY_KEY, shipmentId],
    async () => {
      const response = await getFullShipmentDetails(shipmentId);
      return response.data;
    },
    {staleTime: 20000}
  );
  const lineItems = shipmentQuery.data?.line_items || [];

  const table = useTypedTable({
    data: lineItems,
    columns
  });

  const totals = calculateShipmentTotals({
    line_items: lineItems,
    unitPreferences,
    totalWeight: shipmentQuery.data?.total_weight_override
  });

  return (
    <div className="p-4">
      <h2 className="m-0 mb-2">Line Items</h2>
      {shipmentQuery.status === 'loading' ? (
        <ShipwellLoader loading />
      ) : shipmentQuery.status === 'success' ? (
        <table>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="whitespace-nowrap p-2" key={header.id} style={{width: header.getSize()}}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  <span className="whitespace-nowrap">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                </TableCell>
              ))}
            </tr>
          ))}
          <tr className="border-t font-bold">
            <TableCell>Totals</TableCell>
            <td />
            <TableCell>{totals.units}</TableCell>
            <td />
            <TableCell>
              <span className="whitespace-nowrap">
                {totals.volume ? numberWithCommas(totals.volume.toFixed(2)) : '--'} (
                {totals.system === 'METRIC' ? 'm' : 'ft'}
                <sup className="text-sw-text-reverse">3</sup>)
              </span>
            </TableCell>
            <TableCell>
              <span className="whitespace-nowrap">
                {totals.weight > 0 ? numberWithCommas(totals.weight.toFixed(2)) : '--'} (
                {totals.system === 'METRIC' ? 'kg' : 'lb'})
              </span>
            </TableCell>
          </tr>
        </table>
      ) : null}
    </div>
  );
};

const columnHelper = createColumnHelper<ShipmentLineItem>();

const columns = [
  columnHelper.accessor('id', {
    header: '#',
    cell: (info) => <>{info.row.index + 1}</>,
    size: 50,
    enableSorting: false
  }),
  columnHelper.accessor('description', {
    header: 'Item',
    cell: (info) => info.renderValue(),
    minSize: 50,
    maxSize: 100,
    enableSorting: false
  }),
  columnHelper.accessor('total_packages', {
    header: 'Pkg Qty',
    cell: (info) => info.renderValue(),
    size: 50,
    enableSorting: false
  }),
  columnHelper.accessor('total_pieces', {
    header: 'Piece Count',
    cell: (info) => (
      <>
        {info.renderValue() || '--'} {info.row.original.piece_type || '--'}
      </>
    ),
    size: 50,
    enableSorting: false
  }),
  columnHelper.accessor('height', {
    header: 'Dims',
    cell: (info) => {
      const {original} = info.row;
      const height = original.height;
      const length = original.length;
      const width = original.width;
      if (!(height && length && width)) {
        return <>--</>;
      }
      return (
        <>
          {original.height} x {original.length} x {original.width} {original.length_unit}
        </>
      );
    },
    minSize: 50,
    maxSize: 100,
    enableSorting: false
  }),
  columnHelper.accessor('weight_unit', {
    header: 'Weight',
    cell: (info) => {
      const {original} = info.row;
      const totalLineItemWeight =
        (original.package_weight || original.package_weight === 0) && original.total_packages
          ? original.package_weight * original.total_packages
          : null;
      return (
        <>
          {totalLineItemWeight
            ? `${numberWithCommas(totalLineItemWeight.toFixed(2))} ${original.weight_unit?.toLowerCase() || ''}`
            : totalLineItemWeight === 0
            ? `0 ${original.weight_unit?.toLowerCase() || ''}`
            : '--'}
        </>
      );
    },
    size: 50,
    enableSorting: false
  }),
  columnHelper.accessor('freight_class', {
    header: 'Class',
    cell: (info) => <>{info.renderValue() || '--'}</>,
    size: 50,
    enableSorting: false
  }),
  columnHelper.accessor('nmfc_item_code', {
    header: 'NMFC',
    cell: (info) => {
      const {original} = info.row;
      return (
        <>
          {original.nmfc_item_code ? original.nmfc_item_code : '--'}
          {original.nmfc_sub_code ? '-' + original.nmfc_sub_code : '--'}
        </>
      );
    },
    size: 50,
    enableSorting: false
  })
];
