import {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import {IconButton, Tab, Tabs, VerticalTabList, VerticalTabPanel} from '@shipwell/shipwell-ui';
import {
  AppointmentStatusEnum,
  Facility,
  FacilityAppointmentType,
  FacilityDock,
  LoadType,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';

import {AppointmentVerbContext} from '../../AppointmentVerbContext';
import {AppointmentDetails} from './AppointmentDetails';

import {AppointmentShipmentMessages} from './AppointmentShipmentMessages';
import {AppointmentShipmentDocuments} from './AppointmentShipmentDocuments';
import {AppointmentShipmentInternalNotes} from './AppointmentShipmentInternalNotes';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import {useShipment} from 'App/data-hooks/shipments';

export type AppointmentDetailsTabsProps = {
  selectedAppointment: AppointmentEntry | null;
  setSelectedAppointment: (appointment: AppointmentEntry | null) => unknown;
  facility: Facility | null;
  loadTypes: LoadType[];
  docks: FacilityDock[];
  onClose: () => unknown;
  drawerWidth?: string;
};

export function AppointmentDetailsTabs(props: AppointmentDetailsTabsProps) {
  const {
    selectedAppointment: selectedAppointmentIn,
    setSelectedAppointment,
    facility,
    loadTypes,
    docks,
    onClose,
    drawerWidth = '389px'
  } = props;
  const [appointmentDetailTabIndex, setAppointmentDetailTabIndex] = useState(0);
  const {setError} = useContext(AppointmentVerbContext);

  const [selectedAppointment, updateSelectedAppointment] = useState<AppointmentEntry>({
    id: '',
    status: AppointmentStatusEnum.NoShow,
    start: {timestamp: '2024-01-01T00:00:00.000Z', timezone: 'UTC'},
    end: {timestamp: '2024-01-01T00:00:00.000Z', timezone: 'UTC'},
    facilityId: '',
    dockId: '',
    allDay: false,
    scheduledResourceReferenceId: '',
    durationMs: 0,
    appointmentType: FacilityAppointmentType.ByAppointmentOnly
  });

  // This allows us to keep our content while the panel is closing.
  useEffect(() => {
    if (selectedAppointmentIn) {
      updateSelectedAppointment(selectedAppointmentIn);
    }
  }, [selectedAppointmentIn]);

  const shipmentId =
    selectedAppointment.scheduledResourceType === ScheduledResourceTypeEnum.Shipment
      ? selectedAppointment.scheduledResourceId ?? ''
      : undefined;

  const {shipment} = useShipment(shipmentId);

  return (
    <Tabs
      className={`w-[ flex h-full${drawerWidth}] h-full flex-row items-stretch`}
      selectedIndex={appointmentDetailTabIndex}
      onSelect={(index: number) => {
        if (index === appointmentDetailTabIndex) {
          setSelectedAppointment(null);
          setAppointmentDetailTabIndex(0);
        }
        setAppointmentDetailTabIndex(index);
      }}
    >
      <VerticalTabList
        className={classNames(
          'flex flex-0 flex-col w-10 border-r-1 border-sw-divider',
          'bg-sw-background-component rounded-l-lg',
          'justify-self-stretch '
        )}
      >
        <Tab className="border-b-0 p-2 before:h-0 after:border-0">
          <IconButton
            iconName="Details"
            color={appointmentDetailTabIndex === 0 ? 'sw-focused' : 'sw-icon'}
            aria-label="Appointment Details"
          />
        </Tab>
        <Tab
          className={classNames('border-b-0 p-2 before:h-0 after:border-0', {
            hidden: selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment
          })}
          disabled={selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment}
        >
          <IconButton
            iconName="Messages"
            color={appointmentDetailTabIndex === 1 ? 'sw-focused' : 'sw-icon'}
            aria-label="Shipment Messages"
          />
        </Tab>
        <Tab
          className={classNames('border-b-0 p-2 before:h-0 after:border-0', {
            hidden: selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment
          })}
          disabled={selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment}
        >
          <IconButton
            iconName="Clipboard"
            color={appointmentDetailTabIndex === 2 ? 'sw-focused' : 'sw-icon'}
            aria-label="Shipment Documents"
          />
        </Tab>
        <Tab
          className={classNames('border-b-0 p-2 before:h-0 after:border-0', {
            hidden: selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment
          })}
          disabled={selectedAppointment?.scheduledResourceType !== ScheduledResourceTypeEnum.Shipment}
        >
          <IconButton
            iconName="DocumentBlank"
            color={appointmentDetailTabIndex === 3 ? 'sw-focused' : 'sw-icon'}
            aria-label="Shipment Internal Notes"
          />
        </Tab>
      </VerticalTabList>
      <VerticalTabPanel className="bg-sw-background-component">
        <AppointmentDetails
          facility={facility}
          className="flex-1"
          appointment={selectedAppointment}
          shipment={shipment}
          docks={docks}
          loadTypes={loadTypes}
          setSelectedAppointment={setSelectedAppointment}
          onClose={onClose}
          shipmentId={shipmentId}
        />
      </VerticalTabPanel>
      <VerticalTabPanel className="bg-sw-background-component">
        <AppointmentShipmentMessages
          shipmentId={
            selectedAppointment?.scheduledResourceType === ScheduledResourceTypeEnum.Shipment
              ? selectedAppointment?.scheduledResourceId
              : undefined
          }
          setError={setError}
          setSelectedAppointment={setSelectedAppointment}
        />
      </VerticalTabPanel>
      <VerticalTabPanel className="bg-sw-background-component">
        <AppointmentShipmentDocuments
          shipment={shipment}
          setError={setError}
          setSelectedAppointment={setSelectedAppointment}
        />
      </VerticalTabPanel>
      <VerticalTabPanel className="bg-sw-background-component">
        <AppointmentShipmentInternalNotes
          shipmentId={shipment?.id}
          setError={setError}
          setSelectedAppointment={setSelectedAppointment}
        />
      </VerticalTabPanel>
    </Tabs>
  );
}
