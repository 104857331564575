import {ContractDistanceUnitEnum, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {milesToKmConversion} from 'App/utils/internationalConstants';

export const getRateTotal = ({
  shipment,
  rateType,
  baseRate,
  percentOfLineHaul,
  distance,
  distanceUnit = ContractDistanceUnitEnum.Mile
}: {
  shipment?: Shipment;
  rateType: string;
  baseRate: number;
  percentOfLineHaul?: number;
  distance?: number;
  distanceUnit: ContractDistanceUnitEnum;
}) => {
  // allow passing in distance directly if not using a shipment to calculate
  const totalMiles = distance || shipment?.total_miles || 0;
  const calculatedMiles =
    distanceUnit === ContractDistanceUnitEnum.Mile
      ? totalMiles
      : parseFloat((totalMiles * milesToKmConversion).toFixed(4));

  switch (rateType) {
    case 'FLAT_RATE':
      return baseRate;
    case 'PER_MILE':
      return baseRate * calculatedMiles;
    case 'PERCENT_OF_LINE_HAUL':
      return baseRate * (percentOfLineHaul || 0);
    default:
      return baseRate;
  }
};
