import {useState, useEffect, useLayoutEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import without from 'lodash/without';
import sortBy from 'lodash/sortBy';
import take from 'lodash/take';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import {compose} from 'recompose';
import pluralize from 'pluralize';
import {withRouter} from 'react-router';
import {DeprecatedButton, SvgIcon, Dropdown, TextInput, Modal} from '@shipwell/shipwell-ui';
import TagEdit from './Edit';
import {fetchTags, deleteTag} from 'App/actions/_shipments';
import withStatusToasts from 'App/components/withStatusToasts';
import {PROPTYPE_ROUTER, PROPTYPE_TAGS} from 'App/utils/propTypeConstants';
import {unpackShipmentErrors} from 'App/utils/globalsTyped';
import './styles.scss';

const RemoveTagAction = ({onRemove, disabled}) => (
  <DeprecatedButton
    variant="icon"
    className={classnames('tag-action-button', {'pointer-events-none cursor-default': disabled})}
    onClick={disabled ? undefined : onRemove}
  >
    <SvgIcon name={disabled ? 'LoadingDots' : 'Close'} />
  </DeprecatedButton>
);

RemoveTagAction.propTypes = {
  onRemove: PropTypes.func,
  disabled: PropTypes.bool
};

RemoveTagAction.defaultProps = {
  onRemove: () => {}
};

const ManageTagAction = ({tag, onDelete, onEdit, disabled}) => (
  <Dropdown
    variant="icon"
    className="tag-action-button"
    indicator={false}
    icon={<SvgIcon name={disabled ? 'LoadingDots' : 'Overflow'} />}
  >
    {({onClick}) => [
      <div
        key="edit"
        className="tag-action-menu-item"
        onClick={() => {
          onClick();
          onEdit(tag);
        }}
      >
        Edit
      </div>,
      <div
        key="delete"
        className={classnames('tag-action-menu-item', 'tag-action-menu-item-destroy')}
        onClick={() => {
          onClick();
          onDelete(tag);
        }}
      >
        Delete
      </div>
    ]}
  </Dropdown>
);

ManageTagAction.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool
};

ManageTagAction.defaultProps = {
  onDelete: () => {},
  onEdit: () => {}
};

export const Tag = ({tag, action, onSelect, hideTagMarginBottom, isMinified, disabled}) => (
  <div
    className={classnames('tag', 'tag-option', 'tags__tagList-tag', tag.color, {
      'margin-bottom': !hideTagMarginBottom,
      'tags__tagList-tag-minified': isMinified,
      'pointer-events-none cursor-default': disabled
    })}
  >
    <div className="tags__tagList-tag-label" onClick={disabled ? undefined : onSelect}>
      {tag.name}
    </div>
    {action}
  </div>
);

Tag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }).isRequired,
  action: PropTypes.node,
  onSelect: PropTypes.func,
  isMinified: PropTypes.bool,
  hideTagMarginBottom: PropTypes.bool,
  disabled: PropTypes.bool
};

Tag.defaultProps = {
  action: null,
  onSelect: () => {},
  isMinified: false
};

const TagSelector = ({tags, onItemClick, onDelete, onCreate, onEdit, showDefaultTagsLink, router, disabled}) => {
  const [filteredTags, setFilteredTags] = useState(tags);

  useEffect(() => setFilteredTags(tags), [tags]);

  const handleSearchChange = (e) => {
    const value = e.currentTarget.value;
    const newFilteredTags = tags.filter((tag) => tag.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredTags(newFilteredTags);
  };

  return (
    <div className="tags__tagList-selector">
      <TextInput
        className="tags__tagList-selector-search"
        label="Search for tags"
        name="filter-tags"
        onChange={handleSearchChange}
        prepend={<SvgIcon name="Search" />}
        type="search"
        autoFocus
        clearable
      />

      <div className="tags__tagList-selector-list">
        {filteredTags &&
          filteredTags.map((tag) => (
            <Tag
              key={tag.id}
              tag={tag}
              action={<ManageTagAction tag={tag} onDelete={onDelete} onEdit={onEdit} disabled={disabled} />}
              onSelect={() => onItemClick(tag.id)}
              disabled={disabled}
            >{`${tag.name}`}</Tag>
          ))}
      </div>

      <div className="tags__tagList-action-buttons">
        <DeprecatedButton
          variant="tertiary"
          icon={<SvgIcon name="AddCircleOutlined" />}
          onClick={onCreate}
          className="tags__tagList-action-button"
        >
          Create Tag
        </DeprecatedButton>
        {showDefaultTagsLink ? (
          <DeprecatedButton
            variant="tertiary"
            onClick={() => router.push(`/user/user-defaults?shipmentRedirect=${router.params.shipment_id}`)}
            icon={<SvgIcon name="Settings" />}
            className="tags__tagList-action-button"
          >
            Edit Default Tags
          </DeprecatedButton>
        ) : null}
      </div>
    </div>
  );
};

TagSelector.propTypes = {
  tags: PROPTYPE_TAGS,
  onItemClick: PropTypes.func,
  onDelete: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  showDefaultTagsLink: PropTypes.bool,
  router: PROPTYPE_ROUTER,
  disabled: PropTypes.bool
};

TagSelector.defaultProps = {
  tags: [],
  onItemClick: () => {},
  onDelete: () => {},
  onCreate: () => {},
  onEdit: () => {},
  showDefaultTagsLink: false
};

const DeleteTagConfirmation = ({tag, onCancel, onDelete}) => {
  const handleDelete = () => onDelete(tag.id);

  return (
    <Modal
      title="Delete Tag"
      show={tag}
      onClose={onCancel}
      variant="warning"
      primaryBtnName="Delete"
      onPrimaryAction={handleDelete}
    >
      <div className="tags__tagList-delete-confirmation">
        Are you sure you want to delete {get(tag, 'name', 'this tag')}?
      </div>
      <div>This will remove the tag from all shipments.</div>
    </Modal>
  );
};

DeleteTagConfirmation.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

DeleteTagConfirmation.defaultProps = {
  onCancel: () => {},
  onDelete: () => {}
};

const TagsOverflow = ({tags, onRemoveTag, disabled}) => (
  <Dropdown
    title={`+ ${tags.length}`}
    variant="tertiary"
    indicator={false}
    className="tags__tagList-overflowdropdown"
    drop="down"
    popperConfig={{modifiers: {offset: {offset: '-300, 8'}}}}
  >
    {() => (
      <div className="tags__tagList-overflow">
        <div className="tags__tagList-overflow-list">
          {tags.map((tag) => (
            <Tag
              key={tag.id}
              tag={tag}
              action={<RemoveTagAction onRemove={() => onRemoveTag(tag.id)} disabled={disabled} />}
            />
          ))}
        </div>
      </div>
    )}
  </Dropdown>
);

TagsOverflow.propTypes = {
  tags: PROPTYPE_TAGS,
  onRemoveTag: PropTypes.func,
  disabled: PropTypes.bool
};

const TagsDropdown = ({
  tagTitle,
  tagToUpdate,
  setTagToUpdate,
  availableTags,
  handleAddTag,
  setTagToDelete,
  showDefaultTagsLink,
  router,
  selectedTagIds,
  disabled,
  closeDropdownOnSelect
}) => (
  <Dropdown
    className="tag-add-button mb-[10px]"
    title={selectedTagIds.length === 0 && tagTitle}
    icon={<SvgIcon title="AddTag" name="AddCircleOutlined" />}
    variant={selectedTagIds.length === 0 ? 'tertiary' : 'activeIcon'}
    onToggle={() => setTagToUpdate(null)}
    indicator={false}
    disabled={disabled}
    drop="down"
    popperConfig={{modifiers: {offset: {offset: '-300, 0'}}}}
  >
    {({onClick}) =>
      tagToUpdate ? (
        <TagEdit tag={tagToUpdate} onCancel={() => setTagToUpdate(null)} onSubmit={() => setTagToUpdate(null)} />
      ) : (
        <TagSelector
          tags={availableTags}
          onItemClick={(tag) => {
            if (closeDropdownOnSelect) {
              onClick();
            }
            handleAddTag(tag);
          }}
          onDelete={(tag) => setTagToDelete(tag)}
          onCreate={() => setTagToUpdate({})}
          onEdit={(tag) => setTagToUpdate(tag)}
          showDefaultTagsLink={showDefaultTagsLink}
          router={router}
          disabled={disabled}
        />
      )
    }
  </Dropdown>
);

TagsDropdown.propTypes = {
  tagTitle: PropTypes.string,
  tagToUpdate: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }),
  setTagToUpdate: PropTypes.func,
  availableTags: PROPTYPE_TAGS,
  handleAddTag: PropTypes.func,
  setTagToDelete: PropTypes.func,
  showDefaultTagsLink: PropTypes.bool,
  router: PropTypes.object,
  selectedTagIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  closeDropdownOnSelect: PropTypes.bool
};

TagsDropdown.defaultProps = {
  tagTitle: 'Add Tag',
  tagToUpdate: {},
  setTagToUpdate: () => {},
  availableTags: [],
  handleAddTag: () => {},
  setTagToDelete: () => {},
  showDefaultTagsLink: false,
  router: {},
  selectedTagIds: [],
  closeDropdownOnSelect: false
};

const Tags = ({
  shipment,
  companyTags,
  fetchTags,
  deleteTag,
  addTag,
  removeTag,
  setError,
  overflowTagDisplay,
  showTagBorder,
  hideTagMarginBottom,
  userDefaults,
  tagTitle,
  showDefaultTagsLink,
  router,
  className,
  showLabel,
  closeDropdownOnSelect,
  initialTags
}) => {
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [tagToUpdate, setTagToUpdate] = useState(null);
  const [isModifyingTags, setIsModifyingTags] = useState(false);
  const [numberOfTagsToDisplay, setNumberOfTagsToDisplay] = useState(null);
  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  //if we get a shipment from shipment details, set the shipment tags
  useEffect(() => {
    setSelectedTagIds(get(shipment, 'metadata.tags', []));
  }, [shipment]);

  // if we get default shipment tags from user profile, set the shipment tags to default tags
  useEffect(() => {
    if (has(userDefaults, 'default_shipment_tags')) {
      setSelectedTagIds(get(userDefaults, 'default_shipment_tags', []));
    }
  }, [userDefaults]);

  // if we just want to specify initial tags, set those as selected
  useEffect(() => {
    if (!isEmpty(initialTags)) {
      setSelectedTagIds(initialTags);
    }
  }, [initialTags]);

  const tagListRef = useRef(null);
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    const updateTagListWidth = () => {
      // set the tag list to be temporarily hidden and take up no space so we know how much width
      // we're working with
      tagListRef.current.style.display = 'none';

      const clientRect = containerRef.current.getBoundingClientRect();
      if (clientRect.width === 0) {
        return;
      }

      // Note that this math will probably need to change if the minimum width of a tag changes, but
      // it's meant to have some wiggle room.
      // 140 is all the non-tag width: border, margin, label, buttons, etc. with some extra for padding
      // The divisor is width for a tag + 10px margin
      const numberOfTags = Math.floor((clientRect.width - 140) / 210);
      // The max width we have for tags is width per tag minus 10px margin, so we only show whole
      // tags in the space available
      const maxWidth = 200 * numberOfTags - 10;
      // Actually display the tag list
      tagListRef.current.style.maxWidth = `${maxWidth} px`;
      tagListRef.current.style.display = '';
      // Save the number of tags we're able to show in state so we can use it to determine overflow
      // during render
      setNumberOfTagsToDisplay(numberOfTags);
    };
    if (overflowTagDisplay) {
      // update all of this when the window resizes
      window.addEventListener('resize', updateTagListWidth);
      // and on initial render
      updateTagListWidth();
    }
    // stop doing all this when the component goes away
    return () => window.removeEventListener('resize', updateTagListWidth);
  }, [overflowTagDisplay, tagListRef, containerRef]);

  const availableTags = (companyTags || []).filter((ct) => selectedTagIds.indexOf(ct.id) === -1);

  const handleRemoveTag = async (tagId) => {
    setIsModifyingTags(true);
    const tagIds = without(selectedTagIds, tagId);
    setSelectedTagIds(tagIds);
    try {
      const response = await removeTag(tagId, tagIds);
      if (response?.status >= 400) {
        throw response;
      }
    } catch (e) {
      console.error(`Error removing tag ${tagId}`, e);
      const errors = unpackShipmentErrors(e, 'There was an error removing the tag', ['metadata.tags']);
      if (!Array.isArray(errors)) {
        setError('Error removing tag!', get(e, 'error_description', 'There was an error removing the tag'));
      } else if (errors.length > 0) {
        setError(
          'Error on Shipment!',
          <div className="flex flex-col gap-2 pb-4">
            {errors?.map((error, i) => (
              <div key={i}>
                <span>{error}</span>
              </div>
            ))}
            <span>
              {pluralize('This', errors.length)} {pluralize('error', errors.length)} must be corrected before any new
              changes can be saved.
            </span>
          </div>,
          'top-right',
          {
            delay: 10000
          }
        );
      }
      setSelectedTagIds([...tagIds, tagId]);
    } finally {
      setIsModifyingTags(false);
    }
  };

  const handleAddTag = async (tagId) => {
    setIsModifyingTags(true);
    const tagIds = [...selectedTagIds, tagId];
    setSelectedTagIds(tagIds);
    try {
      const response = await addTag(tagId, tagIds);
      if (response?.status >= 400) {
        throw response;
      }
    } catch (e) {
      console.error(`Error adding tag ${tagId}`, e);
      const errors = unpackShipmentErrors(e, 'There was an error adding the tag', ['metadata.tags']);
      if (!Array.isArray(errors)) {
        setError('Error adding tag!', get(e, 'error_description', 'There was an error adding the tag'));
      }
      if (errors.length > 0) {
        setError(
          'Error on Shipment!',
          <div className="flex flex-col gap-2 pb-4">
            {errors.map((error, i) => (
              <div key={i}>
                <span>{error}</span>
              </div>
            ))}
            <span>
              {pluralize('This', errors.length)} {pluralize('error', errors.length)} must be corrected before any new
              changes can be saved.
            </span>
          </div>,
          'top-right',
          {
            delay: 10000
          }
        );
      }
      setSelectedTagIds(without(selectedTagIds, tagId));
    } finally {
      setIsModifyingTags(false);
    }
  };

  const handleDeleteTag = async (id) => {
    setTagToDelete(null);
    try {
      await deleteTag(id);
      return fetchTags();
    } catch (e) {
      console.error(`Error deleting tag ${id}`, e);
      setError('Error deleting tag!', get(e, 'error_description', 'There was an error deleting the tag'));
    }
  };

  const sortedTags = sortBy(
    (companyTags || []).filter((ct) => selectedTagIds.indexOf(ct.id) > -1),
    (tag) => selectedTagIds.indexOf(tag.id)
  );

  const tagsToDisplay = numberOfTagsToDisplay === null ? sortedTags : take(sortedTags, numberOfTagsToDisplay);
  const tagsOverflow = without(sortedTags, ...tagsToDisplay);

  return (
    <div className={classnames('tags__tagList', className, {'left-border': showTagBorder})} ref={containerRef}>
      {showLabel ? <h5 className="tags__tagList-label">Tags:</h5> : null}
      <div className="tags__tagList-container" ref={tagListRef}>
        {tagsToDisplay.map((tag) => (
          <Tag
            key={tag.id}
            tag={tag}
            action={<RemoveTagAction onRemove={() => handleRemoveTag(tag.id)} disabled={isModifyingTags} />}
            hideTagMarginBottom={hideTagMarginBottom}
          />
        ))}
        {tagsOverflow.length > 0 ? (
          <TagsOverflow tags={tagsOverflow} onRemoveTag={handleRemoveTag} disabled={isModifyingTags} />
        ) : null}
        <TagsDropdown
          companyTags={companyTags}
          tagTitle={tagTitle}
          tagToUpdate={tagToUpdate}
          setTagToUpdate={setTagToUpdate}
          availableTags={availableTags}
          handleAddTag={handleAddTag}
          setTagToDelete={setTagToDelete}
          showDefaultTagsLink={showDefaultTagsLink}
          router={router}
          selectedTagIds={selectedTagIds}
          disabled={isModifyingTags}
          closeDropdownOnSelect={closeDropdownOnSelect}
        />
      </div>
      <DeleteTagConfirmation tag={tagToDelete} onCancel={() => setTagToDelete(null)} onDelete={handleDeleteTag} />
    </div>
  );
};

Tags.propTypes = {
  shipment: PropTypes.object,
  companyTags: PROPTYPE_TAGS,
  fetchTags: PropTypes.func,
  setError: PropTypes.func,
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  overflowTagDisplay: PropTypes.bool,
  showTagBorder: PropTypes.bool,
  hideTagMarginBottom: PropTypes.bool,
  userDefaults: PropTypes.object,
  tagTitle: PropTypes.string,
  showDefaultTagsLink: PropTypes.bool,
  router: PROPTYPE_ROUTER,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  closeDropdownOnSelect: PropTypes.bool,
  initialTags: PropTypes.arrayOf(PropTypes.string)
};

Tags.defaultProps = {
  shipment: {},
  companyTags: [],
  fetchTags: () => {},
  setError: () => {},
  overflowTagDisplay: true,
  showTagBorder: true,
  hideTagMarginBottom: true,
  userDefaults: {},
  tagTitle: 'Add Tag',
  showDefaultTagsLink: false,
  router: {},
  deleteTag: () => {},
  showLabel: true,
  closeDropdownOnSelect: false
};

export default compose(
  withStatusToasts,
  withRouter,
  connect(
    (state) => ({
      shipment: state.shipmentdetails.one,
      companyTags: state.shipments.tags
    }),
    {
      fetchTags,
      deleteTag
    }
  )
)(Tags);
