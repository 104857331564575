import {useEffect, useMemo, useState} from 'react';
import {Dropdown, DisplayValue, SvgIcon, Title, ToggleSwitch} from '@shipwell/shipwell-ui';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import moment from 'moment';
import get from 'lodash/get';
import classNames from 'classnames';

import {useFlags} from 'launchdarkly-react-client-sdk';
import useCreateLoadPost from 'App/api/loadboards/hooks/useCreateLoadpost';
import useDeleteLoadPost from 'App/api/loadboards/hooks/useDeleteLoadPost';
import useGetLoadpostRequestByShipmentId from 'App/api/loadboards/hooks/useGetLoadpostRequestByShipmentId';
import {
  getErrorDetail,
  LoadboardItemProps,
  PrivateLoadBoardItemProps
} from 'App/containers/Shipment/components/LoadBoards/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {formatCurrency} from 'App/utils/internationalConstants';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';
import useRefreshLoadpost from 'App/api/loadboards/hooks/useRefreshLoadpost';
import {useUserMe} from 'App/data-hooks';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {SHIPMENT_CREATE_SPOT_NEGOTIATIONS} from 'App/components/permissions/PermissionsFallback/constants';

export const getStatusCanBeAddedToLoadBoard = (shipment: Shipment) =>
  ![
    'tendered',
    'dispatched',
    'at_pickup',
    'in_transit',
    'delivered',
    'carrier_confirmed',
    'reconciled',
    'cancelled'
  ].includes(get(shipment, 'state', ''));

const PrivateLoadBoard = ({shipment, setSuccess, setError}: PrivateLoadBoardItemProps & WithStatusToastProps) => {
  const [checked, setChecked] = useState(false);
  const shipmentCurrency = shipment.preferred_currency;
  const {data: userData} = useUserMe();
  const {krknTenderingUserPermissions} = useFlags();
  const userPermissions = userData?.user?.permissions || [];
  const canTakeActionOnLoadBoards = krknTenderingUserPermissions
    ? userPermissions.includes(SHIPMENT_CREATE_SPOT_NEGOTIATIONS)
    : true;

  useEffect(() => {
    const isChecked = get(shipment, 'metadata.load_board_enabled', false);
    setChecked(isChecked);
  }, [shipment]);

  const {mutate: updateShipment, isLoading} = useUpdateShipment({
    onSuccess: (response) => {
      const isChecked = get(response, 'data.metadata.load_board_enabled', false);
      setChecked(isChecked);
      setSuccess('Success!', `Private load board ${isChecked ? 'added' : 'removed'}!`);
    },
    onError: () => {
      const message = `Error changing load board status for ${shipment.id}`;
      setError('Error!', message);
    }
  });

  const onUpdateShipment = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    canTakeActionOnLoadBoards &&
      updateShipment({
        shipmentId: shipment.id,
        shipment: {
          ...shipment,
          metadata: {
            ...shipment.metadata,
            load_board_enabled: !checked
          } as Shipment['metadata']
        }
      });
  };

  return (
    <div data-testid="private-loadboard-item">
      <div className="mb-2 flex w-full items-center justify-between">
        <Title variant="formTitle">
          <span
            role="button"
            className={classNames('inline-flex items-center', {
              'pointer-events-none': isLoading || !canTakeActionOnLoadBoards
            })}
            onClick={onUpdateShipment}
          >
            <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
              <span className="pointer-events-none mr-3">
                <ToggleSwitch name="Private" fixedHeight={false} isSmall checked={checked} disabled={isLoading} />
              </span>
            </TenderingUserPermissionFallback>
            <span data-testid="private-loadboard-name">Private</span>
            {isLoading ? <SvgIcon className="animate-slow-spin text-sw-primary" name="Running" /> : null}
          </span>
        </Title>
      </div>
      <div className="flex">
        <DisplayValue label="Offer all-in" className="w-[100px] flex-none">
          <span>{'--'}</span>
        </DisplayValue>
        <DisplayValue label="Book now/Tender" className="w-[120px] flex-none">
          <span>
            {shipment?.metadata?.buy_it_now_amount && shipmentCurrency && checked
              ? formatCurrency(shipment.metadata.buy_it_now_amount, shipmentCurrency)
              : '--'}
          </span>
        </DisplayValue>
        <DisplayValue label="Last posted" className="flex-1">
          <span>{'--'}</span>
        </DisplayValue>
      </div>
    </div>
  );
};

export const PrivateLoadBoardItem = WithStatusToasts(PrivateLoadBoard);

const LoadboardItem = ({
  loadboard,
  shipmentId,
  userId,
  toggleEditing,
  listLoadPosts,
  setLoadpostId,
  setLoadboardType,
  setLoadboardName,
  setSuccess,
  setError
}: LoadboardItemProps & WithStatusToastProps) => {
  const isUserActive = useMemo(
    () => !!loadboard.users?.find((user) => user.sw_user_id === userId) || false,
    [loadboard.users, userId]
  );
  const loadboardType = loadboard.loadboard_type;
  const loadboardName = loadboard.loadboard_name || '';
  const listLoadPostsByloadboardType = listLoadPosts.find((loadpost) => loadpost.loadboard_id === loadboardType);
  const loadpostId = listLoadPostsByloadboardType?.loadpost_id || '';
  const allInRateValue = listLoadPostsByloadboardType?.rate_attributes?.offer_all_in_rate?.value;
  const allInRateCode = listLoadPostsByloadboardType?.rate_attributes?.offer_all_in_rate?.currency_code;
  const tenderRateValue = listLoadPostsByloadboardType?.rate_attributes?.book_now_or_tender_rate?.value;
  const tenderRateCode = listLoadPostsByloadboardType?.rate_attributes?.book_now_or_tender_rate?.currency_code;
  const {data: loadPostRequest} = useGetLoadpostRequestByShipmentId(shipmentId, {
    enabled: !!shipmentId && isUserActive && !loadpostId
  });

  const {mutate: createLoadpost, isLoading: postingLoading} = useCreateLoadPost({
    onSuccess: () => {
      setSuccess('Success!', `${loadboardName} Load Post created!`);
    },
    onError: (error) => {
      setError(`Failed ${loadboardName} Load Post`, getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const {mutate: deleteLoadPost, isLoading: deletingLoading} = useDeleteLoadPost({
    onSuccess: () => {
      setSuccess('Success!', `${loadboardName} Load Post deleted!`);
    },
    onError: (error) => {
      setError(`Failed ${loadboardName} Load Post`, getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const {mutate: refreshLoadPost, isLoading: refreshingLoading} = useRefreshLoadpost({
    onSuccess: () => {
      setSuccess('Success!', `${loadboardName} Load Post refreshed!`);
    },
    onError: (error) => {
      const errorMessage = getErrorDetail(error, 'Something went wrong, please try again.');
      const isRefreshErrorMessage = errorMessage.includes('refreshed');
      setError(
        `Failed ${loadboardName} Load Refresh`,
        isRefreshErrorMessage ? 'You can only refresh a load post every 15 minutes.' : errorMessage
      );
    }
  });

  const handleClick = () => {
    if (loadpostId) {
      return deleteLoadPost({loadboardType, loadpostId});
    }
    if (loadPostRequest) {
      return createLoadpost({loadboardType, loadPostRequest});
    }
    return setError(`Failed ${loadboardName} Load Post`, 'Something went wrong, please try again.');
  };

  const onRefresh = () => {
    refreshLoadPost({loadboardType, loadpostId});
  };

  const onEdit = () => {
    toggleEditing();
    setLoadpostId(loadpostId);
    setLoadboardType(loadboardType);
    setLoadboardName(loadboardName);
  };

  const isLoading = postingLoading || deletingLoading || refreshingLoading;

  return (
    <div data-testid="loadboard-item" className={classNames({'pointer-events-none opacity-50': !isUserActive})}>
      <div className="mb-2 flex w-full items-center justify-between">
        <Title variant="formTitle">
          <span
            role="button"
            className={classNames('inline-flex items-center', {
              'pointer-events-none': isLoading
            })}
            onClick={handleClick}
            data-testid="toggle-button"
          >
            <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
              <span className="pointer-events-none mr-3">
                <ToggleSwitch
                  name={loadboardName}
                  fixedHeight={false}
                  isSmall
                  checked={!!loadpostId}
                  data-testid="toggle-switch"
                  disabled={isLoading}
                />
              </span>
            </TenderingUserPermissionFallback>
            <span data-testid="loadboard-name">{loadboardName}</span>
            {isLoading ? <SvgIcon className="animate-slow-spin text-sw-primary" name="Running" /> : null}
          </span>
        </Title>
        <Dropdown variant="icon" drop="down" indicator={false} icon={<SvgIcon name="Overflow" />} alignEnd>
          {({onClick}: {onClick(): void}) => (
            <>
              <li
                title="Edit"
                onClick={() => {
                  onClick();
                  onEdit();
                }}
                data-testid="edit-button"
              >
                Edit
              </li>
              <li
                title="Refresh"
                onClick={() => {
                  onClick();
                  onRefresh();
                }}
                data-testid="refresh-button"
                className={classNames({
                  'pointer-events-none text-sw-disabled': !loadpostId
                })}
              >
                Refresh
              </li>
            </>
          )}
        </Dropdown>
      </div>
      <div className="flex">
        <DisplayValue label="Offer all-in" className="w-[100px] flex-none">
          <span data-testid="offer-rate-all-in-value">
            {allInRateValue && allInRateCode ? formatCurrency(allInRateValue, allInRateCode) : '--'}
          </span>
        </DisplayValue>
        <DisplayValue label="Book now/Tender" className="w-[120px] flex-none">
          <span data-testid="book-now-value">
            {tenderRateValue && tenderRateCode ? formatCurrency(tenderRateValue, tenderRateCode) : '--'}
          </span>
        </DisplayValue>
        <DisplayValue label="Last posted" className="flex-1">
          <span data-testid="last-posted-value">
            {listLoadPostsByloadboardType?.updated_at
              ? moment.tz(listLoadPostsByloadboardType.updated_at, 'US/Central').format('MM/DD/YY H:mm zz')
              : '--'}
          </span>
        </DisplayValue>
      </div>
    </div>
  );
};

export default WithStatusToasts(LoadboardItem);
