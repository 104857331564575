import {
  REQUEST_USER_COMPANY_PREFERENCES_PENDING,
  REQUEST_USER_COMPANY_PREFERENCES_SUCCESS,
  REQUEST_USER_COMPANY_PREFERENCES_ERROR
} from '../actions';
import {countryDefaults} from 'App/utils/internationalConstants';

/** Initial state */
export const DEFAULT_STATE = {
  isFetching: false,
  error: false,
  unitPreferences: countryDefaults.find((e) => e.country === 'US').unitPreferences // default to US
};

export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_USER_COMPANY_PREFERENCES_PENDING:
      return {
        ...state,
        isFetching: true
      };

    case REQUEST_USER_COMPANY_PREFERENCES_SUCCESS:
      const {country, currency: internationalPreferencesCurrency} = payload;
      const {unitPreferences} = countryDefaults.find((e) => e.country === country);
      const {currency: defaultCurrency} = unitPreferences;
      const currency = internationalPreferencesCurrency || defaultCurrency;

      return {
        ...state,
        ...payload,
        isFetching: false,
        unitPreferences: {...unitPreferences, currency},
        currency
      };

    case REQUEST_USER_COMPANY_PREFERENCES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};
