import {Banner} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import {is404Error} from 'App/utils/errors';

export const SupplierErrorBanner = ({error}: {error: unknown}) => {
  if (is404Error(error)) {
    return (
      <Banner title={'Supplier not found'}>
        The supplier you&apos;re looking for doesn&apos;t exist. Go to{' '}
        <Link to="/suppliers">the suppliers list page</Link>
      </Banner>
    );
  }
  return <Banner title={'Error'}>There was an error. Try reloading the page.</Banner>;
};
