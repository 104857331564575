import get from 'lodash/get';
import set from 'lodash/set';
import axios from 'axios';
import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';
import {parseErrors} from 'App/utils/globals';

/**
 * Auth Token - API
 */
export async function getToken(body = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.authTokenPost(body, (error, data, response) => {
      if (error) {
        console.warn(response);
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

/**
 * Auth User - API
 */
export async function getUserMePromise() {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    /* Get a login token for signing future requests*/
    shipwellApi.authMeGet(function (err, data, response) {
      if (err) {
        console.warn(err, response);
        reject(parseErrors(response));
      } else {
        //save the user's email in localstorage (necessary for good flow in email tech)
        if (data.user && data.user.email) {
          localStorage.setItem('swuser', data.user.email);
        }
        //if user has an avatar, bust their cache so they always get the latest image here
        if (data.user && data.user.avatar) {
          data.user.avatar += '?cachebust=' + new Date().getTime().toString();
        }
        // coerce undefined custom_data to null if undefined
        set(data, 'user.custom_data', get(data, 'user.custom_data', null));

        //resolve with the user object and company object
        resolve(data);
      }
    });
  });
}

/**
 * Versions
 */
export async function getSystemVersion() {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }
  try {
    const response = await axios.get('/VERSION');
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return null;
  }
}

/**
 * Update Sign-up Info
 */
export async function updateSignUpInfoPromise(body = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.authUpdateSignupInfoPost(body, apiCallback(resolve, reject));
  });
}

/**
 * Verify Email
 */
export async function verifyEmailPromise(body) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.authVerifyEmailPost(body, apiCallback(resolve, reject));
  });
}
