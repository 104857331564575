import {useEffect, useMemo} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import {states, provinces} from 'App/utils/globals';
import {mexicoStates} from 'App/components/addressVerification/utils';
import {
  AllowedCountries,
  AllowedPrefixes,
  countryOptions
} from 'App/formComponents/forms/fedexRegistration/utils/constants';
import {FedexRegistrationFormValues} from 'App/formComponents/forms/fedexRegistration/utils/types';

export const FedexBillingAddress = ({namePrefix, disabled}: {namePrefix: AllowedPrefixes; disabled?: boolean}) => {
  const {values, setFieldValue, initialValues} = useFormikContext<FedexRegistrationFormValues>();

  const {country_code: initialCountryCode, state_or_province: initialStateOrProvince} = initialValues[namePrefix] || {};
  const {country_code: selectedCountryCode, state_or_province: selectedStateOrProvince} = values[namePrefix] || {};

  const stateProvinceOptions = useMemo(() => {
    let options: {
      name: string;
      id: string;
    }[];

    switch (selectedCountryCode) {
      case AllowedCountries.CA:
        options = provinces;
        break;
      case AllowedCountries.MX:
        options = mexicoStates;
        break;
      default:
        options = states;
    }

    return options.map(({name, id}) => ({
      label: name,
      value: id
    }));
  }, [selectedCountryCode]);

  const stateProvinceLabel = selectedCountryCode === AllowedCountries.CA ? 'Province' : 'State';

  useEffect(() => {
    if (initialCountryCode !== selectedCountryCode || initialStateOrProvince !== selectedStateOrProvince)
      setFieldValue(`${namePrefix}.state_or_province`, '');
    // we only want to change the state when the selected country changes
    // but need to check the initial state so that our initial values do not get cleared
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, selectedCountryCode]);

  return (
    <div className="grid grid-rows-3 gap-6">
      <Field
        required
        name={`${namePrefix}.line_1`}
        label="Street Address"
        component={FormikTextInput}
        disabled={disabled}
      />
      <div className="inline-grid grid-cols-2 gap-6">
        <Field required name={`${namePrefix}.city`} label="City" component={FormikTextInput} disabled={disabled} />
        <Field
          simpleValue
          required
          clearable={false}
          name={`${namePrefix}.state_or_province`}
          label={stateProvinceLabel}
          options={stateProvinceOptions}
          component={FormikSelect}
          disabled={disabled}
        />
      </div>
      <div className="inline-grid grid-cols-2 gap-6">
        <Field
          required
          name={`${namePrefix}.postal_code`}
          label="Postal Code"
          component={FormikTextInput}
          disabled={disabled}
        />
        <Field
          simpleValue
          required
          clearable={false}
          name={`${namePrefix}.country_code`}
          label="Country"
          options={countryOptions}
          component={FormikSelect}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
