import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {reduxForm, change, touch, formValueSelector} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import * as formActions from '../../actions/forms';
import * as actions from '../../actions/users';
import * as billingActions from '../../actions/billing';
import * as shipmentActions from '../../actions/shipments';
import * as authActions from '../../actions/auth';
import * as addressBookActions from '../../actions/addressBook';
import * as productActions from '../../actions/products';
import * as vendorActions from '../../actions/vendors';
import * as carrierActions from '../../actions/carriers';
// eslint-disable-next-line import/no-named-as-default
import LineItems from './FormSections/LineItems';
import Header from './FormSections/Header';
import References from './FormSections/References';
import {Financials} from './FormSections/Financials';
import EquipmentConfig from './FormSections/EquipmentConfig';
import ModeEquipment from './FormSections/ModeEquipment';
import BillTo from './FormSections/BillTo';
import Customer from './FormSections/Customer';
// eslint-disable-next-line import/no-named-as-default
import StopsExpanded from './FormSections/StopsExpanded';
import * as brokerActions from 'App/actions/brokers';
import ShipmentTagsCard from 'App/components/shipmentTagsCard';
import validate from 'App/utils/validateNewLoadForm';
import warn from 'App/utils/warningsQuotingForm';
import {
  formatAddressChange,
  generateRandomId,
  scrollToFirstErrorField,
  transformShipmentToForm
} from 'App/utils/globals';
import {loadProductIntoForm} from 'App/utils/shipmentFormHelpers';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';

@connect(
  (state) => ({
    newLoadForm: state.form.newLoadForm,
    goToStops: state.shipments.goToStops,
    company: state.auth.company,
    addressBook: state.addresses.addressbook,
    locationTypes: state.addresses.locationTypes,
    accessorials: state.shipments.accessorialCodes,
    user: state.auth.user,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    carrierRelationships: state.vendors.carrierRelationships,
    cloneTrigger: state.shipments.cloneTrigger,
    cloneShipment: state.shipments.cloneShipment,
    products: state.products.products,
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {
    ...formActions,
    ...actions,
    ...billingActions,
    ...shipmentActions,
    ...authActions,
    ...addressBookActions,
    ...vendorActions,
    ...productActions,
    ...carrierActions,
    ...brokerActions
  }
)
class NewLoadForm extends Component {
  constructor(props) {
    super(props);
    this.removeHazmatDetails = this.removeHazmatDetails.bind(this);
    this.dispatchAddressChange = this.dispatchAddressChange.bind(this);
    this.triggerNameChange = this.triggerNameChange.bind(this);
    this.setLinearFeet = this.setLinearFeet.bind(this);
    this.dispatchMarkupChange = this.dispatchMarkupChange.bind(this);
    this.ignoreValidation = this.ignoreValidation.bind(this);
    this.cloneShipment = this.cloneShipment.bind(this);
    this.triggerBillToEdit = this.triggerBillToEdit.bind(this);
    this.changeVendor = this.changeVendor.bind(this);
    this.loadProduct = this.loadProduct.bind(this);
    this.triggerLineItemTouch = this.triggerLineItemTouch.bind(this);
    this.initialize = this.initialize.bind(this);
    this.state = {
      isBroker: false,
      setCustomerDefault: false,
      setRefDefault: false,
      setMargin: '',
      cloning: false,
      setBillToDefault: false,
      showBillTo: false,
      showCustomerField: false
    };
  }

  componentDidMount() {
    // NOTE: fetch only when directly navigating, not reload
    // ApiKeyAuth.apiKey needs to exist
    // is valid if company is defined
    if (!_.isEmpty(this.props.company)) {
      this.initialize();
      if (
        this.props.user &&
        this.props.user.permissions &&
        this.props.user.permissions.includes('customer_relationships.view')
      ) {
        this.initialFetchCustomers(this.props.company);
      }
    }
    if (
      this.props.newLoadForm &&
      (!this.props.newLoadForm.values ||
        (this.props.newLoadForm.values && !this.props.newLoadForm.values.customer_reference_number))
    ) {
      const randomId = generateRandomId();
      this.setState({setRefDefault: true});
      this.props.dispatch(change('newLoadForm', 'customer_reference_number', randomId));
    }
    if (
      (!this.props.newLoadForm.values.bill_to_override ||
        (this.props.newLoadForm.values.bill_to_override &&
          (!this.props.newLoadForm.values.bill_to_override.company_name ||
            !this.props.newLoadForm.values.bill_to_override.company_address))) &&
      !this.state.setBillToDefault
    ) {
      if (
        !_.isEmpty(this.props.company) &&
        !_.isEmpty(this.props.user) &&
        this.props.company &&
        this.props.company.name &&
        this.props.company.billing_address
      ) {
        const defaultBillTo = {};

        defaultBillTo.company_name = this.props.company.name;
        if (this.props.company.billing_address) {
          defaultBillTo.company_address = {
            formatted_address: this.props.company.billing_address.formatted_address
          };
        }

        defaultBillTo.direction = 'PREPAID';
        defaultBillTo.contact_email = this.props.company.primary_email;
        /*defaultBillTo.contact_email = this.props.company.billing_email
          ? this.props.company.billing_email
          : this.props.company.primary_email;*/
        defaultBillTo.contact_phone = this.props.company.primary_phone_number;
        this.props.dispatch(change('newLoadForm', 'bill_to_override', defaultBillTo));
        this.setState({setBillToDefault: true});
      }
    }

    if (this.props.cloneTrigger === true) {
      this.cloneShipment(this.props.cloneShipment);
      this.props.setCloneShipmentTrigger(false, {});
    }

    if (
      this.props.company &&
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('customer_relationships.view')
    ) {
      this.setState({isBroker: true});
    }
    if (!this.props.newLoadForm.values.book_as_customer || this.props.newLoadForm.values.book_as_customer === null) {
      if (
        this.props.company &&
        this.props.user &&
        this.props.user.permissions &&
        this.props.user.permissions.includes('customer_relationships.view')
      ) {
        this.setState({isBroker: true, setCustomerDefault: true});
        if (
          this.props.shipperRelationships &&
          this.props.shipperRelationships.results &&
          this.props.shipperRelationships.results.length === 1
        ) {
          //only set customer if there is only the house account
          this.props.dispatch(change('newLoadForm', 'book_as_customer', this.props.company.id));
        }
      }
    }
    this.props.clearSelectedShipment();
  }

  componentWillReceiveProps(nextProps) {
    // NOTE: fetch only on reload or account change
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.initialize();
      if (fetch.user && fetch.user.permissions && fetch.user.permissions.includes('customer_relationships.view')) {
        this.initialFetchCustomers(this.props.company);
      }
    }
    if (nextProps.newLoadForm && nextProps.newLoadForm.values) {
      if (!nextProps.newLoadForm.values.customer_reference_number && !this.state.setRefDefault) {
        const randomId = generateRandomId();
        this.props.dispatch(change('newLoadForm', 'customer_reference_number', randomId));
        this.setState({setRefDefault: true});
      }
      if (
        (!nextProps.newLoadForm.values.bill_to_override ||
          (nextProps.newLoadForm.values.bill_to_override &&
            (!nextProps.newLoadForm.values.bill_to_override.company_name ||
              !nextProps.newLoadForm.values.bill_to_override.company_address))) &&
        !this.state.setBillToDefault
      ) {
        if (nextProps.company && nextProps.company.name && nextProps.company.billing_address && nextProps.user) {
          const defaultBillTo = {};

          defaultBillTo.company_name = nextProps.company.name;
          if (nextProps.company.billing_address) {
            defaultBillTo.company_address = {
              formatted_address: nextProps.company.billing_address.formatted_address
            };
          }
          defaultBillTo.direction = 'PREPAID';
          defaultBillTo.contact_email = nextProps.company.primary_email;
          /*defaultBillTo.contact_email = nextProps.company.billing_email
            ? nextProps.company.billing_email
            : nextProps.company.primary_email;*/
          defaultBillTo.contact_phone = nextProps.company.primary_phone_number;

          this.props.dispatch(change('newLoadForm', 'bill_to_override', defaultBillTo));
          this.setState({setBillToDefault: true});
        }
      }
      if (!nextProps.newLoadForm.values.book_as_customer || nextProps.newLoadForm.values.book_as_customer === null) {
        if (
          nextProps.company &&
          nextProps.user &&
          nextProps.user.permissions &&
          nextProps.user.permissions.includes('customer_relationships.view') &&
          !this.state.setCustomerDefault
        ) {
          this.setState({isBroker: true, setCustomerDefault: true});
          if (
            nextProps.shipperRelationships &&
            nextProps.shipperRelationships.results &&
            nextProps.shipperRelationships.results.length === 1
          ) {
            this.props.dispatch(change('newLoadForm', 'book_as_customer', this.props.company.id));
          }
        }
      }
    }
    if (
      this.props.user &&
      nextProps.user.permissions &&
      nextProps.user.permissions !== this.props.user.permissions &&
      nextProps.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true});
    }
    if (
      nextProps.newLoadForm.values &&
      nextProps.newLoadForm.values.mode &&
      (!this.props.newLoadForm.values ||
        !this.props.newLoadForm.values.mode ||
        this.props.newLoadForm.values.mode !== nextProps.newLoadForm.values.mode)
    ) {
      if (
        nextProps.newLoadForm.values.mode &&
        (nextProps.newLoadForm.values.mode === '2' || nextProps.newLoadForm.values.mode === '4')
      ) {
        //mode was changed to LTL or VLTL, if there are no line items, dispatch one
        if (
          !nextProps.newLoadForm.values.line_items ||
          (nextProps.newLoadForm.values.line_items && nextProps.newLoadForm.values.line_items.length === 0)
        ) {
          this.props.dispatch(
            change('newLoadForm', 'line_items', [
              {
                length: '40',
                width: '48',
                package_type: 'PLT',
                length_unit: 'IN',
                weight_unit: 'LB',
                stackable: false
              }
            ])
          );
        }
      }
    }

    //if margin or markup changes, calculate the other

    if (this.props.markup !== nextProps.markup) {
      let carrierTotal = 0;
      let calculatedMargin = 0;
      if (nextProps.financialTotals && nextProps.financialTotals.length > 0) {
        carrierTotal = nextProps.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMargin = parseFloat((parseFloat(nextProps.markup) / carrierTotal) * 100).toFixed(2);
        if (!isNaN(calculatedMargin) && isFinite(calculatedMargin)) {
          this.setState({setMargin: calculatedMargin + '%'});
        }
      }
    }

    //defaulting the bill to information
    if (
      nextProps.newLoadForm.values &&
      this.props.newLoadForm.values &&
      nextProps.newLoadForm.values.bill_to_override &&
      this.props.newLoadForm.values.bill_to_override &&
      nextProps.newLoadForm.values.bill_to_override.direction !==
        this.props.newLoadForm.values.bill_to_override.direction
    ) {
      if (nextProps.newLoadForm.values.bill_to_override.direction === 'PREPAID') {
        //if prepaid, default to the current user's company information
        if (nextProps.company && nextProps.company.name && nextProps.company.billing_address) {
          const defaultBillTo = {};

          defaultBillTo.company_name = nextProps.company.name;
          if (nextProps.company.billing_address) {
            defaultBillTo.company_address = {
              formatted_address: nextProps.company.billing_address.formatted_address
            };
          }
          defaultBillTo.direction = 'PREPAID';
          defaultBillTo.contact_email = nextProps.company.primary_email;
          /*defaultBillTo.contact_email = nextProps.company.billing_email
            ? nextProps.company.billing_email
            : nextProps.company.primary_email;*/
          defaultBillTo.contact_phone = nextProps.company.primary_phone_number;

          this.props.dispatch(change('newLoadForm', 'bill_to_override', defaultBillTo));
        }
      }
      if (nextProps.newLoadForm.values.bill_to_override.direction === 'COLLECT') {
        //if COLLECT, default to the delivery stop's company information
        if (
          nextProps.newLoadForm &&
          nextProps.newLoadForm.values &&
          nextProps.newLoadForm.values.stops &&
          nextProps.newLoadForm.values.stops.length > 1
        ) {
          //get the final stop
          const stop = nextProps.newLoadForm.values.stops[nextProps.newLoadForm.values.stops.length - 1];
          const defaultBillTo = {};
          if (stop) {
            defaultBillTo.company_name = stop.company || '';
            if (stop.location) {
              defaultBillTo.company_address = {
                formatted_address: stop.location.formatted_address
              };
            }
            if (stop.point_of_contacts && stop.point_of_contacts.length > 0) {
              defaultBillTo.contact_email = stop.point_of_contacts[0].email || '';
              defaultBillTo.contact_phone = stop.point_of_contacts[0].phone_number || '';
            }
          }

          defaultBillTo.direction = 'COLLECT';

          this.props.dispatch(change('newLoadForm', 'bill_to_override', defaultBillTo));
        }
      }
      if (nextProps.newLoadForm.values.bill_to_override.direction === '3RD_PARTY') {
        //if third party, default empty object
        const defaultBillTo = {};
        defaultBillTo.direction = '3RD_PARTY';
        this.props.dispatch(change('newLoadForm', 'bill_to_override', defaultBillTo));
      }
    }
  }

  initialize() {
    this.props
      .addressBookGet({
        page: 1,
        pageSize: 1000,
        ordering: 'location_name',
        q: ''
      })
      .then((response) => {
        if (response.status === 200) {
          this.setDefaultOriginAddress();
        }
      });

    this.props.getCarrierRelationships({
      page: 1,
      pageSize: 1000000,
      ordering: 'company'
    });
    this.props.getProducts({
      page: 1,
      pageSize: 1000,
      ordering: 'product_ref',
      q: ''
    });
    this.props.locationTypesGet();
    this.props.fetchPackageTypes();
    this.props.fetchEquipmentTypes();
    this.props.fetchHazmatCodes();
    this.props.fetchChargeCategories();
    this.props.fetchNMFCCodes();
    this.props.fetchServiceLevels();
    this.props.fetchShipmentModes();
    this.props.fetchAccessorialCodes();
    this.props.fetchAppointmentTypes();
  }

  initialFetchCustomers(company) {
    this.props
      .fetchBrokerShipperRelationshipsForTypeaheads(company.brokerage.id, {
        page: 1,
        pageSize: 5,
        ordering: 'company',
        q: ''
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.details && response.details.results && response.details.results.length > 1) {
            this.setState({showCustomerField: true});
          }
        }
      });
  }

  // populate the default origin address if one exists
  setDefaultOriginAddress() {
    if (
      this.props.newLoadForm &&
      this.props.newLoadForm.values &&
      this.props.newLoadForm.values.stops[0] &&
      _.isEmpty(this.props.newLoadForm.values.stops[0].location)
    ) {
      const entry = this.props.addressBook.results.filter((e) => e.is_default_origin === true)[0];
      if (entry) {
        this.dispatchAddressChange(entry, 0);
      }
    }
  }

  //removing hazmat details when checkbox is cleared
  removeHazmatDetails(index) {
    this.props.dispatch(change('newLoadForm', 'line_items[' + index + '].hazmatDetails', null));
  }

  setLinearFeet(linearFeet) {
    this.props.dispatch(change('newLoadForm', 'total_linear_feet', linearFeet));
  }

  dispatchMarkupChange(margin) {
    if (parseFloat(margin)) {
      let carrierTotal = 0;
      let calculatedMarkup = 0;
      if (this.props.financialTotals && this.props.financialTotals.length > 0) {
        carrierTotal = this.props.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMarkup = parseFloat(parseFloat(margin / 100) * carrierTotal).toFixed(2);
        if (!isNaN(calculatedMarkup)) {
          this.props.dispatch(change('newLoadForm', 'markup', calculatedMarkup));
        }
      }
    }
  }

  dispatchAddressChange(entry, stopIndex) {
    //construct the address to use from whats in the form already and the address book details
    let addressToUse = {};
    if (entry) {
      addressToUse = formatAddressChange(
        entry,
        stopIndex,
        this.props.stopTimes.length,
        this.props.newLoadForm,
        this.props.accessorials
      );

      //dispatch the change
      this.props.dispatch(change('newLoadForm', 'stops[' + stopIndex + ']', addressToUse));
      //force a refresh by triggering something that isnt in an arrayfield  -- hack until redux form fixes this
      this.props.dispatch(change('newLoadForm', 'triggerUpdate', true));
      setTimeout(() => {
        this.props.dispatch(change('newLoadForm', 'triggerUpdate', false));
      }, 2000);
    }
  }

  triggerNameChange(string) {
    this.props.dispatch(change('newLoadForm', 'name', string));
  }

  ignoreValidation(bool) {
    this.props.dispatch(change('newLoadForm', 'ignoreValidation', bool));
  }

  changeVendor(vendor) {
    this.props.dispatch(change('newLoadForm', 'carrier', vendor.id));
  }

  loadProduct(productId, index) {
    loadProductIntoForm(this.props.dispatch, 'newLoadForm', productId, index, this.props.products.results);
  }

  triggerLineItemTouch(index, field) {
    this.props.dispatch(touch('newLoadForm', 'line_items[' + index + '].' + field));
  }

  cloneShipment(shipment) {
    let rfqs = [];
    if (shipment.rfqs) {
      rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    }
    let mode = '';
    let equipment_types = [];
    if (rfqs.length > 0) {
      //for LTL shipmentToClones, the parent rfq should be null on the parent rfq obj
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      if (lastRFQ.autoquote === true) {
        mode = '2';
      } else {
        mode = '1';
      }
      if (lastRFQ.shipment_modes && lastRFQ.shipment_modes.length > 0) {
        //just take the first one in the list
        mode = lastRFQ.shipment_modes[0].id;
      }
      if (lastRFQ.equipment_types) {
        equipment_types = lastRFQ.equipment_types;
      }
    }
    //if MODE or Equipment are on the shipment itself, use that as the data to clone
    if (shipment.mode) {
      mode = shipment.mode.id;
    }
    if (shipment.equipment_type) {
      equipment_types = [shipment.equipment_type];
    }

    const shipmentToClone = transformShipmentToForm(
      JSON.parse(JSON.stringify(shipment)),
      this.props.hazmatCodes,
      mode,
      equipment_types,
      false,
      true
    );

    if (shipmentToClone.carrier_assignment) {
      let carrierToAssign = null;
      //get the id of the CARRIER within the relationship selected
      for (let k = 0; k < this.props.carrierRelationships.length; k++) {
        if (
          this.props.carrierRelationships[k].shipwell_vendor.carrier.id == shipmentToClone.carrier_assignment.carrier.id
        ) {
          carrierToAssign = this.props.carrierRelationships[k].id;
        }
      }
      if (carrierToAssign) {
        shipmentToClone.carrier = carrierToAssign;
      }
    }
    this.setState({cloning: true}, () => {
      //first reset the current form
      this.props.reset();

      //metadata
      this.props.dispatch(change('newLoadForm', 'metadata', shipmentToClone.metadata));

      //dispatch the changes
      this.props.dispatch(change('newLoadForm', 'stops', shipmentToClone.stops));
      this.props.dispatch(change('newLoadForm', 'book_as_customer', shipmentToClone.book_as_customer));
      if (shipmentToClone.mode) {
        this.props.dispatch(change('newLoadForm', 'mode', shipmentToClone.mode));
      } else {
        //default to FTL
        this.props.dispatch(change('newLoadForm', 'mode', '1'));
      }
      this.props.dispatch(change('newLoadForm', 'accessorials', shipmentToClone.accessorials));
      this.props.dispatch(change('newLoadForm', 'equipment_type', shipmentToClone.equipment_type));
      this.props.dispatch(change('newLoadForm', 'service_level', shipmentToClone.service_level));
      this.props.dispatch(change('newLoadForm', 'bill_to_override', shipmentToClone.bill_to_override));
      this.props.dispatch(change('newLoadForm', 'carrier', shipmentToClone.carrier));
      this.props.dispatch(change('newLoadForm', 'name', shipmentToClone.name));
      this.props.dispatch(change('newLoadForm', 'name', shipmentToClone.name));
      this.props.dispatch(change('newLoadForm', 'financials', shipmentToClone.financials));
      this.props.dispatch(change('newLoadForm', 'markup', shipmentToClone.markup));
      this.props.dispatch(change('newLoadForm', 'notes_for_carrier', shipmentToClone.notes_for_carrier));

      this.props.dispatch(change('newLoadForm', 'temperature_lower_limit', shipmentToClone.temperature_lower_limit));
      this.props.dispatch(change('newLoadForm', 'temperature_upper_limit', shipmentToClone.temperature_upper_limit));
      this.props.dispatch(change('newLoadForm', 'temperature_unit', getShipmentTemperatureUnit(shipmentToClone)));

      this.props.dispatch(change('newLoadForm', 'line_items', shipmentToClone.line_items));
      this.setState({cloning: false});
    });
  }

  triggerBillToEdit() {
    this.setState({showBillTo: !this.state.showBillTo});
  }

  render() {
    const {error, submitting, handleSubmit, carrier} = this.props;

    return (
      <div className="newshipment">
        <Form onSubmit={this.props.handleSubmit} className="newLoadForm">
          <Header
            form="newLoadForm"
            showCloneLink
            cloneShipment={this.cloneShipment}
            cloning={this.state.cloning}
            formValues={this.props.newLoadForm}
            label={'New Load'}
            showField
            showCustomerField
            hideLabel
          />
          <Row>
            <Col md={12} xs={12}>
              <Row>
                <Col xs={12} sm={12}>
                  <ShipmentTagsCard
                    className="newshipment__card"
                    formValues={this.props.newLoadForm.values}
                    form="newLoadForm"
                    dispatch={this.props.dispatch}
                  />
                </Col>
              </Row>
              {this.state.isBroker ? (
                <Row>
                  <Col xs={12} sm={6}>
                    <Paper className="newshipment__card">
                      <Customer
                        showCustomerField={this.state.showCustomerField}
                        formValues={this.props.newLoadForm}
                        form="newLoadForm"
                      />

                      <EquipmentConfig form="newLoadForm" formValues={this.props.newLoadForm} />
                    </Paper>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Paper className="newshipment__card">
                      <ModeEquipment form="newLoadForm" formValues={this.props.newLoadForm} />
                    </Paper>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} sm={6}>
                    <Paper className="newshipment__card">
                      <ModeEquipment form="newLoadForm" formValues={this.props.newLoadForm} />
                    </Paper>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Paper className="newshipment__card">
                      <EquipmentConfig form="newLoadForm" formValues={this.props.newLoadForm} />
                    </Paper>
                  </Col>
                </Row>
              )}
              <Paper className="newshipment__card">
                <StopsExpanded
                  dispatchAddressChange={this.dispatchAddressChange}
                  formValues={this.props.newLoadForm}
                  form="newLoadForm"
                  showPickupDateField
                  allowZipOnly={false}
                  stopTimes={this.props.stopTimes}
                  scheduleToggles={this.props.scheduleToggles}
                  newLoad
                />
              </Paper>
              <Paper className="newshipment__card">
                <LineItems
                  setLinearFeet={this.setLinearFeet}
                  loadProduct={this.loadProduct}
                  formValues={this.props.newLoadForm}
                  form="newLoadForm"
                  removeHazmatDetails={this.removeHazmatDetails}
                  triggerLineItemTouch={this.triggerLineItemTouch}
                  newLoad
                />
              </Paper>
              <Row>
                <Col xs={12} sm={6}>
                  <Paper className="newshipment__card">
                    <References
                      form="newLoadForm"
                      formValues={this.props.newLoadForm}
                      triggerNameChange={this.triggerNameChange}
                    />
                  </Paper>
                </Col>
                <Col xs={12} sm={6}>
                  <Paper className="newshipment__card">
                    <Financials
                      dispatchMarkupChange={this.dispatchMarkupChange}
                      form="newLoadForm"
                      formValues={this.props.newLoadForm}
                      financialTotals={this.props.financialTotals}
                      markup={this.props.markup}
                      setMargin={this.state.setMargin}
                      triggerBillToEdit={this.triggerBillToEdit}
                    />
                    {this.state.showBillTo && (
                      <BillTo
                        triggerBillToEdit={this.triggerBillToEdit}
                        form="newLoadForm"
                        formValues={this.props.newLoadForm}
                      />
                    )}
                  </Paper>
                </Col>
              </Row>
              {this.props.errorOverride && (
                <p className="error-text-form-level pad-top">
                  <i className="icon icon-Delayed pad-right" />
                  {this.props.errorOverride}
                </p>
              )}
              <div className="quote-flow__submit text-right" style={{marginTop: '10px'}}>
                <Button
                  disabled={submitting}
                  onMouseDown={() => this.ignoreValidation(true)}
                  onClick={this.props.handleSubmit((values) => this.props.onSubmit({...values, option: 'save-only'}))}
                  bsStyle="default"
                >
                  Save for Later
                </Button>
                <Button
                  disabled={submitting}
                  onMouseDown={() => this.ignoreValidation(false)}
                  onClick={handleSubmit((values) => this.props.onSubmit({...values, option: 1}))}
                  bsStyle="default"
                >
                  {submitting ? (
                    <span>
                      <i className="icon icon-Restart rotate" /> Dispatching
                    </span>
                  ) : (
                    'Dispatch & Print BOL'
                  )}
                </Button>
                <Button
                  disabled={submitting}
                  onMouseDown={() => this.ignoreValidation(false)}
                  onClick={handleSubmit((values) => this.props.onSubmit({...values, option: 2}))}
                  bsStyle="primary"
                >
                  {submitting ? (
                    <span>
                      <i className="icon icon-Restart rotate" /> Dispatching
                    </span>
                  ) : (
                    'Dispatch'
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

NewLoadForm = reduxForm({
  form: 'newLoadForm',
  destroyOnUnmount: false,
  touchOnChange: true,
  ignoreValidation: false,
  enableReinitialize: false,
  validate,
  warn,
  onSubmitFail: (errors) => scrollToFirstErrorField(errors)
})(NewLoadForm);

NewLoadForm = connect((state) => ({
  initialValues: JSON.parse(JSON.stringify(state.shipments.newLoadFormInitial))
}))(NewLoadForm);

const selector = formValueSelector('newLoadForm');

NewLoadForm = connect((state) => {
  // can select values individually
  const stopData = selector(state, 'stops');
  const stopTimes = [];
  const scheduleToggles = [];
  if (stopData) {
    for (var i = 0; i < stopData.length; i++) {
      stopTimes.push({});
      if (stopData[i] && (stopData[i].planned_time_window_start || stopData[i].planned_time_window_end)) {
        stopTimes[i].start = stopData[i].planned_time_window_start;
        stopTimes[i].stop = stopData[i].planned_time_window_end;
      }
      if (stopData[i]) {
        scheduleToggles.push({
          date: stopData[i].schedule_date_toggle,
          time: stopData[i].schedule_time_toggle
        });
      }
    }
  }
  const financialData = selector(state, 'financials');
  const financialTotals = [];
  if (financialData) {
    for (var i = 0; i < financialData.length; i++) {
      if (financialData[i] && financialData[i].unit_amount && financialData[i].unit_quantity) {
        let value = financialData[i].unit_amount;
        if (value && typeof value === 'string' && value.startsWith('$')) {
          value = value.slice(1);
        }
        if (value && typeof value === 'string') {
          value = value.replace(/,/g, '');
        }

        const valToAdd = parseFloat(value * financialData[i].unit_quantity);

        if (!isNaN(valToAdd)) {
          financialTotals.push(valToAdd);
        } else {
          financialTotals.push(0);
        }
      } else {
        financialTotals.push(0);
      }
    }
  }
  let markup = selector(state, 'markup');

  if (markup && markup.startsWith('$')) {
    markup = markup.slice(1);
  }
  if (markup) {
    markup = markup.replace(/,/g, '');
  }

  const margin = selector(state, 'margin');

  const carrier = selector(state, 'carrier');

  return {
    stopTimes,
    financialTotals,
    markup,
    margin,
    scheduleToggles,
    carrier
  };
})(NewLoadForm);

export default NewLoadForm;
