import {
  Configuration,
  LocationsApi,
  LocationType,
  ShipmentsApi,
  ShipmentsApiShipmentsStopLocationsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createLocationApi() {
  return new LocationsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
const locationsApi = createLocationApi();

function createShipmentsApi() {
  return new ShipmentsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
const shipmentsApi = createShipmentsApi();

/**
 * Static data set that should be cached on the client for the available location types to a tenant.
 * @throws {import('axios').AxiosError<import('@shipwell/backend-core-sdk').ShipwellError>}
 */
export const getLocationTypes = async (): Promise<LocationType[]> => {
  const {data: locationTypes} = await locationsApi.locationTypesGet();

  return locationTypes;
};

export const validateLocation = (formattedAddress: string) => {
  return locationsApi.locationsAddressesValidatePost({formattedAddress: {formatted_address: formattedAddress}});
};

export const getShipmentStopLocations = (requestParams?: ShipmentsApiShipmentsStopLocationsGetRequest) => {
  return shipmentsApi.shipmentsStopLocationsGet(requestParams);
};
