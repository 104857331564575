import {CustomFieldAllowedValue} from '@shipwell/backend-core-singlerequestparam-sdk';
import snakeCase from 'lodash/snakeCase';

export const convertSemicolinDelimitedStringToAllowedValueObjects = (
  semicolonDelimitedString: string,
  /**
   * `initialAllowedValues` is used to grab the IDs of any existing CustomFieldAllowedValue known to the backend, so it should always
   * be the original CustomFieldAllowedValue from the server and not from the form values. Otherwise, deleting and retyping the
   * same value would not grab the id of that value already on the server.
   */
  initialAllowedValues: CustomFieldAllowedValue[] | undefined
): CustomFieldAllowedValue[] =>
  semicolonDelimitedString
    .split(';')
    .map((v) => v.trim())
    .filter((v) => v !== '')
    .map((value: string) => ({
      id: initialAllowedValues?.find((initialValue) => value === initialValue.label)?.id,
      label: value,
      value: snakeCase(value)
    }));
