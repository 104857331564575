import {useToastManager} from '@shipwell/shipwell-ui';
import {useMutation, useQuery} from '@tanstack/react-query';
import {trackingProviderCredentialsSave} from 'App/api/locus/trackingCredentials';
import {parseV3ApiError} from 'App/api/typedUtils';
import {getTiveCredentials} from 'App/utils/integrations/tive/tive';
import {TiveDetailsFormValues} from '../connectionFormCard/useTiveDetailsForm/tiveDetailsFormValues';

export function useTiveService() {
  const {enqueueToast} = useToastManager();

  const getQuery = useQuery({
    queryKey: ['useTiveService'],
    queryFn: async () => {
      return await getTiveCredentials();
    }
  });

  const connectMutaion = useMutation({
    mutationKey: ['connectMutaion'],
    mutationFn: async (params: TiveDetailsFormValues) => {
      await trackingProviderCredentialsSave({
        credentialsInput: {
          client_id: params.clientId,
          client_secret: params.password,
          provider: 'tive'
        }
      });
    },
    onSuccess: () => {
      enqueueToast({
        variant: 'success',
        title: 'Success',
        content: 'Credentials saved.'
      });
    },
    onError: (error) => {
      const {detail} = parseV3ApiError(error);
      enqueueToast({
        variant: 'error',
        title: 'Error',
        content: detail
      });
    }
  });

  return {
    isFetching: getQuery.isFetching,
    isSaving: connectMutaion.isLoading,
    data: getQuery.data,
    connect: connectMutaion.mutateAsync
  };
}
