import {useQuery, useMutation} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {addTagToShipment, getTagsFromShipment, removeTagFromShipment} from 'App/api/shipment';
import {TAGS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {transformTagsToFormValues} from 'App/containers/shipments/utils';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

function useShipmentTags(shipmentId) {
  const shipmentTagsQueryKey = [TAGS_QUERY_KEY, shipmentId];
  const createOptimisticUpdateHandlers = useOptimisticUpdate();

  const addTagToShipmentMutation = useMutation(
    ({shipmentId, tagId}) => addTagToShipment(shipmentId, tagId),
    createOptimisticUpdateHandlers(shipmentTagsQueryKey, {
      errorHandler: (error, {shipmentId, tagId}) =>
        console.error('Error adding tag', tagId, 'to shipment', shipmentId, error.message),
      mergeData: (previousTags, {tagId}) => ({results: [...(previousTags?.results || []), {tag_id: tagId}]})
    })
  );

  const removeTagFromShipmentMutation = useMutation(
    ({shipmentId, tagId}) => removeTagFromShipment(shipmentId, tagId),
    createOptimisticUpdateHandlers(shipmentTagsQueryKey, {
      errorHandler: (error, {shipmentId, tagId}) =>
        console.error('Error removing tag', tagId, 'from shipment', shipmentId, error.message),
      mergeData: (previousTags, {tagId}) => ({
        results: (previousTags?.results || []).filter((tag) => tag.tag_id !== tagId)
      })
    })
  );

  const getTagsFromShipmentQuery = useQuery(
    shipmentTagsQueryKey,
    async () => {
      const response = await getTagsFromShipment(shipmentId);
      return response?.data;
    },
    {
      enabled: !isEmpty(shipmentId)
    }
  );

  const initialTags = transformTagsToFormValues(get(getTagsFromShipmentQuery, 'data.results', []));

  return {
    isLoading:
      getTagsFromShipmentQuery.isInitialLoading ||
      addTagToShipmentMutation.isLoading ||
      removeTagFromShipmentMutation.isLoading,
    addTagToShipmentMutation,
    removeTagFromShipmentMutation,
    getTagsFromShipmentQuery,
    context: {initialTags}
  };
}

export default useShipmentTags;
