import {useState, useCallback, useEffect, useRef} from 'react';
import {useMutation} from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';
import queryString from 'query-string';
import moment from 'moment';
import {Link} from 'react-router';
import pluralize from 'pluralize';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {DeprecatedButton, Loader, Modal, Toast, SearchField, SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import PageHeader from 'App/common/pageHeader';
import SWTable from 'App/components/swTable';
import {
  getCarrierRelationshipsCarrierRelationshipIdPromise,
  deleteCarrierContract,
  updateCarrierContract
} from 'App/api/carriers';
import {getContracts} from 'App/api/contracts';
import {fetchEquipmentTypes} from 'App/actions/_shipments';
import {formatCurrency} from 'App/utils/internationalConstants';
import ContractCreateContainer from 'App/containers/contracts/create';
import {formatDate, cleanPayload} from 'App/utils/globals';
import {filters} from 'App/containers/contracts/list/filters';
import CreateImport from 'App/components/imports/create';
import {SelectedCount} from 'App/components/SelectedCount';
import {status, origins, destinations, modes, equipment} from 'App/containers/contracts/columns';
import bulkSelect from 'App/components/tableColumns/bulkSelect';
import RoutingGuideCreateContainer from 'App/containers/routingGuides/create';
import ContractsAttachSLAForm from 'App/containers/contracts/list/attachSLA';
import {formatCarrierPOCs} from 'App/utils/carrierHelpers';
import {useUserPageSize} from 'App/utils/hooks/useUserTableHooks';
import {defaultTableMeta} from 'App/utils/tableUtils';
import withFlags from 'App/utils/withFlags';
import './styles.scss';
import {startCaseToLower} from 'App/utils/startCaseToLower';

/** Debounced path update - keep outside of functional component */
const pathUpdateDebounced = debounce((router, path) => router.push(path), 300);

const ContractsList = (props) => {
  const {
    canCreateContracts,
    canDeleteContracts,
    location,
    dispatch,
    router,
    setError,
    setSuccess,
    setWarning,
    tableType = 'CONTRACTS_LIST',
    wfaBulkActions
  } = props;
  const {query} = location;

  const [deletingContracts, setDeletingContracts] = useState([]);
  const [cloningContract, setCloningContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [isFetchingContracts, setIsFetchingContracts] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [showImportContractModal, setShowImportContractModal] = useState(false);
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const [showCreateGuideModal, setShowCreateGuideModal] = useState(false);
  const [showAttachSLAModal, setShowAttachSLAModal] = useState(false);
  const [showDeleteContractsModal, setShowDeleteContractsModal] = useState(false);
  const [cloningGuide, setCloningGuide] = useState(false);
  const [consolidatedContracts, setConsolidatedContracts] = useState(false);
  const [consolidating, setConsolidating] = useState(false);
  const [useFullScreen, setUseFullScreen] = useState(false);
  const [meta, setMeta] = useState(defaultTableMeta);
  const [searchTerm, setSearchTerm] = useState(query.q);
  const [isDeletingContracts, setIsDeletingContracts] = useState(false);
  const userPageSize = useUserPageSize(tableType);
  const deleteActionText = `Delete ${pluralize('Contract', deletingContracts.length)}`;
  const prevShowDeleteContractsModal = useRef();

  useEffect(() => {
    if (prevShowDeleteContractsModal.current && !showDeleteContractsModal) {
      setDeletingContracts([]);
    }
    prevShowDeleteContractsModal.current = showDeleteContractsModal;
  }, [showDeleteContractsModal]);

  useEffect(() => {
    dispatch(fetchEquipmentTypes());
  }, [dispatch]);

  /** Fetch contracts on pagination changes */
  useEffect(() => {
    if (location.query?.showDeleteSuccess) {
      setSuccess('Contract Deleted!', `Contract for ${query.showDeleteSuccess} deleted.`);
      // clear out showDeleteSuccess
      router.push(router.location.pathname);
      return;
    }
    fetchContracts({...location.query});
  }, [userPageSize, location, query.showDeleteSuccess, fetchContracts, router, setSuccess]);

  /* Fetch contracts */
  const fetchContracts = useCallback(
    async (opts = {}) => {
      if (userPageSize === null) {
        return;
      }
      setIsFetchingContracts(true);
      if (Object.keys(opts).length) {
        Object.keys(opts).forEach((key) => {
          const matchingFilter = filters.find((filter) => filter.name === key || filter.secondaryName === key);
          if (matchingFilter && matchingFilter.useArray && !Array.isArray(opts[key])) {
            opts[key] = [opts[key]];
          }
        });
      }
      opts = {...meta, pageSize: userPageSize, ...opts};
      try {
        const response = await getContracts(opts);
        setContracts(response.body.results);
        setMeta({
          ...meta,
          page: query.page || 1,
          pages: response.body.total_pages,
          pageSize: response.body.page_size
        });
        setIsFetchingContracts(false);
      } catch (error) {
        console.error(error);
      }
      setIsFetchingContracts(false);
    },
    [meta, query, userPageSize]
  );

  /** Toggle contract form */
  const handleContractModalDisplay = () => {
    if (showCreateContractModal) {
      setCloningContract(null);
    }
    setShowCreateContractModal(!showCreateContractModal);
  };

  /**
   * Show modal to create a new contract with this carrier
   */
  const handleAddContract = () => {
    setShowCreateContractModal(true);
  };
  const handleImportContract = () => {
    setShowImportContractModal(true);
  };

  const deleteContractsMutation = useMutation(
    (contracts) => {
      return Promise.allSettled(
        contracts.map(async ({carrier_relationship: carrierRelationship, id: carrierId}) => {
          await deleteCarrierContract(carrierRelationship, carrierId);
        })
      );
    },
    {
      onMutate: () => setIsDeletingContracts(true),
      onSuccess: (results, contracts) => {
        const [successes, failures] = partition(results, {status: 'fulfilled'});
        if (failures.length > 0) {
          setError(
            `${pluralize('Contract', failures.length)} Not Deleted.`,
            failures.length === 1
              ? failures[0].reason.error_description
              : `${failures.length} contracts failed to delete.`,
            successes.length > 0 ? 'bottom-right' : 'top-right'
          );
        }
        if (successes.length > 0) {
          setSuccess(
            `${pluralize('Contract', successes.length)} Deleted.`,
            successes.length === 1
              ? `Contract for ${contracts[0].carrier_name} was removed.`
              : `${successes.length} contracts were removed.`
          );
          const updatedPath = buildPathParams(location, {...meta.pageSize, page: 1});
          pathUpdateDebounced(router, updatedPath);
          setSelectedContracts([]);
        }
      },
      onSettled: () => {
        setShowDeleteContractsModal(false);
        setIsDeletingContracts(false);
      }
    }
  );

  const handleDeleteContracts = () => {
    deleteContractsMutation.mutate(deletingContracts);
  };

  const handleCreateSuccess = (shouldClone) => {
    setShowCreateContractModal(false);
    setSuccess('Contract Created!', 'Contract successfully created.');
    const updatedPath = buildPathParams(location, {...meta.pageSize, page: 1});
    pathUpdateDebounced(router, updatedPath);
    if (shouldClone) {
      setCloningContract(shouldClone);
      setShowCreateContractModal(true);
    } else {
      setCloningContract(null);
    }
  };

  const handleCreateGuideSuccess = (shouldClone) => {
    setShowCreateGuideModal(false);
    setSuccess('Routing Guide Created!', 'Routing Guide successfully created.');
    if (shouldClone) {
      setCloningGuide(shouldClone);
      setConsolidatedContracts(null);
      setShowCreateGuideModal(true);
    } else {
      setConsolidatedContracts(null);
      setSelectedContracts([]);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    const updatedPath = buildPathParams(location, {q: term, page: 1});
    setSearchTerm(term);
    pathUpdateDebounced(router, updatedPath);
  };

  const buildPathParams = (location, params) => {
    const {pathname = '/', query} = location;
    return `${pathname}?${queryString.stringify({...query, ...params})}`;
  };

  const handleCreateGuide = async () => {
    setConsolidating(true);
    const policy = {end_date: null, name: '', start_date: moment().format('YYYY-MM-DD'), status: 'ACTIVE'};
    //get all unique origins/destinations/modes/equipment from these contracts
    policy.origins = uniqBy(
      selectedContracts.reduce((acc, cur) => {
        return acc.concat(cur.origins);
      }, []),
      'formatted_address'
    );
    policy.destinations = uniqBy(
      selectedContracts.reduce((acc, cur) => {
        return acc.concat(cur.destinations);
      }, []),
      'formatted_address'
    );
    policy.modes = uniqBy(
      selectedContracts.reduce((acc, cur) => {
        return acc.concat(cur.modes);
      }, []),
      'id'
    );
    policy.equipment_types = uniqBy(
      selectedContracts.reduce((acc, cur) => {
        return acc.concat(cur.equipment_types);
      }, []),
      'id'
    );

    const routingGuideActionsDefaults = get(
      props.company,
      'custom_data.shipwell_custom_data.company_default_values.routingGuideActions',
      null
    );

    const actions = [];
    await Promise.allSettled(
      selectedContracts.map(async (contract) => {
        try {
          const response = await getCarrierRelationshipsCarrierRelationshipIdPromise(contract.carrier_relationship);
          if (response && response.body) {
            const formattedPOCs = formatCarrierPOCs([response.body]);
            actions.push({
              rate: contract.rate,
              rate_currency: contract.rate_currency,
              rate_type: contract.rate_type,
              mode: contract.modes?.[0],
              equipment_type: contract.equipment_types?.[0],
              involved_tender_to_company_users: formattedPOCs,
              type: {value: 'TENDER', label: 'Tender'},
              expires_after_seconds: routingGuideActionsDefaults.expires_after_seconds || {
                label: '0 Hours 30 Minutes',
                value: 1800
              },
              contract_id: {name: contract.name, value: contract.id},
              info_message: routingGuideActionsDefaults.info_message || ''
            });
          }
        } catch (error) {
          console.error(error);
        }
      })
    );

    //sort actions by rate, from least to greatest
    actions.sort((a, b) => a.rate - b.rate);

    setConsolidating(false);
    setConsolidatedContracts({policy: policy, actions: actions});
    setShowCreateGuideModal(true);
  };

  const handleBulkAttachSLA = async (values) => {
    try {
      const selectedContractsPromises = selectedContracts.map((contract) => {
        const payload = {...contract, service_level_agreement: values.service_level_agreement.id};
        // Only include the notes if they contain data
        if (values.notes.length > 0) {
          payload.notes = values.notes;
        }
        return updateCarrierContract(contract.carrier_relationship, contract.id, cleanPayload(payload));
      });
      const contractResponses = await Promise.allSettled(selectedContractsPromises);
      const rejectedResponses = contractResponses.filter((response) => response.status === 'rejected');
      if (rejectedResponses.length === 0) {
        setSuccess(
          'SLA Attached!',
          `Your Service Level Agreement was attached to ${pluralize('contracts', selectedContracts.length, true)}.`,
          'top-right',
          {delay: null}
        );
      } else {
        setError(
          'SLA Not Attached!',
          <div>
            {`Your Service Level Agreement was attached to ${pluralize(
              'contracts',
              selectedContracts.length - rejectedResponses.length,
              true
            )}. It was not attached to the following:`}
            <div className="mt-2 flex flex-col">
              {rejectedResponses.map((response) => (
                <Link key={response.value.body.id} to={`/company/lane-management/contracts/${response.value.body.id}`}>
                  {response.value.body.name}
                </Link>
              ))}
            </div>
          </div>,
          'top-right',
          {delay: null}
        );
      }
    } catch (error) {
      console.error(error);
      setError(
        'SLA Not Attached!',
        `Your Service Level Agreement was not successfully attached to the selected ${pluralize(
          'contracts',
          selectedContracts.length
        )}.`
      );
    } finally {
      setShowAttachSLAModal(false);
      setSelectedContracts([]);
    }
  };

  return (
    <div className="contracts__list">
      <PageHeader
        title="Contracts"
        actions={
          selectedContracts.length > 0 ? (
            <Dropdown title="Contract Bulk Actions" indicator variant="primary">
              {({onClick}) => [
                wfaBulkActions ? (
                  <li
                    onClick={() => {
                      onClick();
                      setShowAttachSLAModal(true);
                    }}
                    key="attachSLA"
                  >
                    Attach SLA
                  </li>
                ) : null,
                <li
                  onClick={async () => {
                    await handleCreateGuide();
                    onClick();
                  }}
                  key="createGuide"
                >
                  Create Routing Guide
                </li>,
                canDeleteContracts ? (
                  <li
                    className="text-sw-destroy"
                    onClick={() => {
                      onClick();
                      setDeletingContracts(selectedContracts);
                      setShowDeleteContractsModal(true);
                    }}
                    key="deleteContracts"
                  >
                    Delete {pluralize('Contract', selectedContracts.length)}
                  </li>
                ) : null
              ]}
            </Dropdown>
          ) : (
            canCreateContracts && (
              <>
                <DeprecatedButton
                  variant="tertiary"
                  icon={<SvgIcon name="AddCircleOutlined" />}
                  onClick={handleImportContract}
                >
                  Import
                </DeprecatedButton>
                <DeprecatedButton
                  variant="tertiary"
                  icon={<SvgIcon name="AddCircleOutlined" />}
                  onClick={handleAddContract}
                >
                  Contract
                </DeprecatedButton>
              </>
            )
          )
        }
      >
        <SearchField label="Search" name="search-contract" value={searchTerm} onChange={handleSearch} />
      </PageHeader>
      <div className="contracts__list-table">
        <SWTable
          data={contracts}
          filters={filters}
          loading={isFetchingContracts}
          location={location}
          router={router}
          page={Number(meta.page) - 1}
          pages={Number(meta.pages)}
          pageSize={Number(meta.pageSize)}
          tableType={tableType}
          noDataText="No contracts to show."
          getTrProps={(state, rowInfo) => ({className: !rowInfo ? 'empty' : ''})}
          bulkSelectColumns={[
            bulkSelect({currentPage: contracts, selected: selectedContracts, onSelect: setSelectedContracts})
          ]}
          columns={[
            {
              Header: (
                <>
                  Carrier <i className="btn-sort" />
                </>
              ),
              id: 'carrier_name',
              accessor: 'carrier_name',
              label: 'Carrier',
              sortable: true,
              minWidth: 75
            },
            {
              Header: (
                <>
                  Contract Name <i className="btn-sort" />
                </>
              ),
              id: 'name',
              accessor: 'name',
              label: 'Contract Name',
              sortable: true,
              minWidth: 75,
              Cell: function formatName(row) {
                const {original} = row;
                return (
                  <DeprecatedButton
                    variant="tertiary"
                    onClick={() => {
                      router.push(`/company/lane-management/contracts/${original.id}`);
                    }}
                  >
                    {row.value}
                  </DeprecatedButton>
                );
              }
            },
            {...status, sortable: true},
            {...origins, sortable: true},
            {...destinations, sortable: true},
            {
              Header: (
                <>
                  Total Distance <i className="btn-sort" />
                </>
              ),
              id: 'distance',
              accessor: 'distance',
              label: 'Total Distance',
              sortable: true,
              Cell: (row) => {
                return row.value ? row.value + ' mi' : '--';
              },
              minWidth: 75
            },
            {...modes, sortable: true},
            {...equipment, sortable: true},
            {
              Header: (
                <>
                  Rate <i className="btn-sort" />
                </>
              ),
              id: 'rate',
              accessor: 'rate',
              label: 'Rate',
              sortable: true,
              Cell: (row) => {
                return row.value ? `${formatCurrency(row.value, row.original.rate_currency)}` : '--';
              },
              minWidth: 75
            },
            {
              Header: 'Rate Type',
              id: 'rate_type',
              accessor: 'rate_type',
              label: 'Rate Type',
              sortable: false,
              Cell: (row) => {
                return startCaseToLower(row.value);
              },
              minWidth: 75
            },
            {
              Header: 'Rate Table Rate Type',
              id: 'rate_table_rate_type',
              accessor: 'rate_table',
              label: 'Rate Table Rate Type',
              sortable: false,
              Cell: (row) => {
                return row.value?.rate_type ? startCaseToLower(row.value.rate_type) : '--';
              },
              minWidth: 75
            },
            {
              Header: 'Fuel Included',
              id: 'fuel_included',
              accessor: 'fuel_included',
              label: 'Fuel Included',
              sortable: false,
              Cell: (row) => {
                return row.value ? 'Yes' : 'No';
              },
              minWidth: 75
            },
            {
              Header: 'Start Date',
              id: 'start_date',
              accessor: 'start_date',
              label: 'Start Date',
              sortable: false,
              Cell: (row) => {
                return row.value ? formatDate(row.value) : '--';
              }
            },
            {
              Header: 'End Date',
              id: 'end_date',
              accessor: 'end_date',
              label: 'End Date',
              sortable: false,
              Cell: (row) => {
                return row.value ? formatDate(row.value) : '--';
              }
            },
            {
              Header: 'Actions',
              id: 'actions',
              accessor: '',
              label: 'Actions',
              className: 'overflow-visible',
              sortable: false,
              // eslint-disable-next-line react/display-name
              Cell: ({original}) => {
                return (
                  <>
                    {(canDeleteContracts || canCreateContracts) && (
                      <Dropdown
                        indicator={false}
                        drop="left"
                        variant="icon"
                        className="action-menu"
                        icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                      >
                        {({onClick}) => (
                          <>
                            {canCreateContracts && (
                              <li
                                onClick={() => {
                                  onClick();
                                  setCloningContract({...original, name: 'Copy of ' + original.name});
                                  setShowCreateContractModal(true);
                                }}
                              >
                                Clone Contract
                              </li>
                            )}
                            {canDeleteContracts && (
                              <li
                                className="text-danger"
                                onClick={() => {
                                  onClick();
                                  setDeletingContracts([original]);
                                  setShowDeleteContractsModal(true);
                                }}
                              >
                                Delete
                              </li>
                            )}
                          </>
                        )}
                      </Dropdown>
                    )}
                  </>
                );
              }
            }
          ]}
        />
        {selectedContracts.length > 0 && (
          <SelectedCount
            className="absolute bottom-12 left-1/2 -translate-x-2/4"
            count={selectedContracts.length}
            itemLabel="Contract"
            clickCta="Deselect All"
            onClick={() => setSelectedContracts([])}
          />
        )}
      </div>
      <Modal
        variant="warning"
        show={showDeleteContractsModal}
        title={deleteActionText}
        primaryBtnName={deleteActionText}
        PrimaryButtonProps={{loading: isDeletingContracts}}
        onClose={() => setShowDeleteContractsModal(false)}
        onPrimaryAction={handleDeleteContracts}
      >
        <p>{`Are you sure you want to delete ${
          deletingContracts.length > 1 ? `${deletingContracts.length} contracts` : deletingContracts[0]?.name
        }?`}</p>
      </Modal>
      <Modal
        show={showCreateContractModal}
        title={cloningContract ? 'Clone Contract' : 'Add a Contract'}
        footerComponent={null}
        onClose={handleContractModalDisplay}
      >
        <ContractCreateContainer
          {...props}
          cloningContract={cloningContract}
          onCancel={handleContractModalDisplay}
          onSubmitSuccess={handleCreateSuccess}
        />
      </Modal>
      <Modal
        fullScreen={useFullScreen}
        show={showImportContractModal}
        title="Import Contracts"
        footerComponent={null}
        onClose={() => {
          setShowImportContractModal(false);
          setUseFullScreen(false);
        }}
      >
        <CreateImport
          setUseFullScreen={setUseFullScreen}
          onCancel={() => {
            setShowImportContractModal(false);
            setUseFullScreen(false);
          }}
          type="CONTRACTS"
          setShowImportSuccess={(numRecords) => {
            setShowImportSuccess(numRecords);
            fetchContracts(location);
          }}
        />
      </Modal>
      <Modal
        show={showCreateGuideModal}
        title="Create Routing Guide"
        footerComponent={null}
        onClose={() => setShowCreateGuideModal(false)}
      >
        <RoutingGuideCreateContainer
          {...props}
          setError={setError}
          defaultValues={consolidatedContracts}
          cloningGuide={cloningGuide}
          onCancel={() => setShowCreateGuideModal(false)}
          onSubmitSuccess={handleCreateGuideSuccess}
        />
      </Modal>
      <Modal
        show={showAttachSLAModal}
        title="Attach Service Level Agreement"
        bodyVariant="disableOverflowScroll"
        footerComponent={null}
        onClose={() => setShowAttachSLAModal(false)}
      >
        <ContractsAttachSLAForm onSubmit={handleBulkAttachSLA} onCancel={() => setShowAttachSLAModal(false)} />
      </Modal>
      <Toast
        show={Boolean(showImportSuccess)}
        title="Success!"
        variant="success"
        anchor="top-right"
        onClose={() => setShowImportSuccess(false)}
      >
        {showImportSuccess} records imported.
      </Toast>
      <Loader show={consolidating}>Creating Routing Guide ...</Loader>
    </div>
  );
};

export default compose(
  withFlags('wfaBulkActions'),
  connect((state) => ({
    company: get(state, 'auth.company', null),
    canDeleteContracts: get(state, 'userProfile.user.permissions', []).includes('contracts.delete_contracts'),
    canEditContracts: get(state, 'userProfile.user.permissions', []).includes('contracts.update_contracts'),
    canCreateContracts: get(state, 'userProfile.user.permissions', []).includes('contracts.create_contracts')
  }))
)(ContractsList);
