import {CreateSpotNegotiationQuote, ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {createSpotNegotiationQuote} from '../typed';

type CreateSpotNegotiationQuotePayload = {
  spotNegotiationId: string;
  quote: CreateSpotNegotiationQuote;
};

const useCreateSpotNegotiationQuote = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createSpotNegotiationQuote>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationQuotePayload
  >
) => {
  return useMutation<
    Awaited<ReturnType<typeof createSpotNegotiationQuote>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationQuotePayload
  >(
    ({spotNegotiationId, quote}) => createSpotNegotiationQuote({spotNegotiationId, createSpotNegotiationQuote: quote}),
    mutationOptions
  );
};

export default useCreateSpotNegotiationQuote;
