import {MouseEvent, useState} from 'react';
import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import pluralize from 'pluralize';
import {IconButtonWithTooltip, Modal} from '@shipwell/shipwell-ui';
import Messages from 'App/components/Messages';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';
import {FlexBox} from 'App/components/Box';
import SendShipmentDocuments from 'App/formComponents/forms/sendShipmentDocuments';
import {EMAIL_CONTACTS, SEND_DOCUMENTS} from 'App/formComponents/forms/sendShipmentDocuments/constants';
import ShipmentNotes from 'App/components/shipmentNotes';
import {WatchShipmentToggle} from 'App/containers/Dashboard/cells/WatchShipmentToggle';

export const ActionsCell = ({shipment}: {shipment: ShippingDashboardRow}) => {
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const {documents_count: documentsCount, notes_count: notesCount, messages_count: messagesCount, version} = shipment;
  const isV3 = version === V3_VERSION;

  return (
    <FlexBox gap="s">
      <IconButtonWithTooltip
        iconName="Clipboard"
        aria-label={`${documentsCount || 'No'} ${pluralize('Document', documentsCount || 0)}`}
        disabled={isV3 || documentsCount === 0}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setShowDocumentsModal(true);
        }}
      />
      <Modal show={showDocumentsModal} title="Shipment Documents" onClose={() => setShowDocumentsModal(false)}>
        <SendShipmentDocuments mode={SEND_DOCUMENTS} shipmentId={shipment.id} />
      </Modal>

      <IconButtonWithTooltip
        iconName="Contacts"
        aria-label={!isV3 ? 'Shipment Contacts' : 'No Shipment Contacts'}
        disabled={isV3}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setShowContactsModal(true);
        }}
      />
      <Modal show={showContactsModal} title="Shipment Contacts" onClose={() => setShowContactsModal(false)}>
        <SendShipmentDocuments mode={EMAIL_CONTACTS} shipmentId={shipment.id} />
      </Modal>

      <IconButtonWithTooltip
        iconName="DocumentBlank"
        aria-label={!isV3 || (notesCount || 0) > 0 ? 'Internal Notes' : 'No Internal Notes'}
        disabled={isV3}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setShowNotesModal(true);
        }}
      />
      <Modal
        show={showNotesModal}
        title="Internal Notes"
        footerComponent={null}
        onClose={() => setShowNotesModal(false)}
      >
        <ShipmentNotes shipment={shipment} shouldPoll />
      </Modal>

      <IconButtonWithTooltip
        iconName="Messages"
        aria-label={!isV3 || (messagesCount || 0) > 0 ? 'Messages' : 'No Messages'}
        disabled={isV3}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setShowMessagesModal(true);
        }}
      />
      <Modal
        show={showMessagesModal}
        title="Messages"
        footerComponent={null}
        onClose={() => setShowMessagesModal(false)}
      >
        <Messages shipmentResource={shipment} resourceType="SHIPMENT" shouldPoll />
      </Modal>

      <WatchShipmentToggle
        shipmentId={shipment.id}
        isWatched={shipment.is_watched}
        isDisabled={!shipment.is_watchable}
      />
    </FlexBox>
  );
};
