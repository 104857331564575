import {ChangeEvent, useCallback, useContext} from 'react';
import {Form, Formik, Field, useFormikContext, FieldArray, FormikState} from 'formik';
import {Card, FormikSelect, FormikCheckbox, CollapsibleCardContent} from '@shipwell/shipwell-ui';

import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import {hourOptions} from 'App/utils/dateTimeGlobalsTyped';
import FacilityCreationModalFooter from 'App/containers/facilities/components/FacilityCreationModalFooter';
import {WEEKLY_DEFAULT_OPEN_TIME, WEEKLY_DEFAULT_CLOSE_TIME} from 'App/data-hooks/facilities/constants';
import {FacilityCreationForm, FacilityAttributesFormValues} from 'App/data-hooks/facilities/types';
import {facilityOperationCapacityValidationSchema} from 'App/containers/facilities/schema';

import AppointmentRulesFields from 'App/formComponents/formSections/facilityAppointmentRulesFields';
import FacilityHolidayRules from 'App/formComponents/formSections/facilityHolidayFields';

const FacilityAttributes = () => {
  const {setNextStep, facilityFormData, setFacilityFormData} = useContext(StepFormContext);

  const handleSubmitAttributes = useCallback(
    (values: FacilityAttributesFormValues) => {
      setFacilityFormData({
        ...facilityFormData,
        facilityOperationCapacity: values?.facilityOperationCapacity,
        appointmentRules: values?.appointmentRules,
        holidayRules: values?.holidayRules
      });
      setNextStep();
    },
    [facilityFormData, setFacilityFormData, setNextStep]
  );

  return <FacilityAttributesForm facilityFormData={facilityFormData} onSubmit={handleSubmitAttributes} />;
};

const FacilityAttributesForm = ({
  facilityFormData,
  onSubmit
}: {
  facilityFormData: FacilityCreationForm;
  onSubmit: (values: FacilityAttributesFormValues) => void;
}) => {
  return (
    <div className="my-10">
      <Formik
        validateOnMount
        initialValues={{
          facilityOperationCapacity: facilityFormData.facilityOperationCapacity,
          appointmentRules: facilityFormData.appointmentRules,
          holidayRules: facilityFormData.holidayRules || []
        }}
        validationSchema={facilityOperationCapacityValidationSchema}
        onSubmit={onSubmit}
      >
        {({isValid}) => (
          <Form noValidate>
            <FacilityAttributesFields />
            <FacilityCreationModalFooter isValid={isValid} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const FacilityAttributesFields = () => {
  return (
    <>
      <Card draggableProvided={null} title="Hours of Operation and Capacity" className="mb-2" isCollapsible>
        <CollapsibleCardContent>
          <div className="flex flex-col gap-y-2">
            <FacilityHoursList />
          </div>
        </CollapsibleCardContent>
      </Card>
      <div className="flex flex-col gap-y-2">
        <AppointmentRulesFields />
        <FacilityHolidayRules />
      </div>
    </>
  );
};

export default FacilityAttributes;

const FacilityHoursList = () => {
  return (
    <FieldArray
      name="facilityOperationCapacity"
      render={({form: {values, errors}}: {form: FormikState<FacilityAttributesFormValues>}) => {
        return (
          <>
            {values.facilityOperationCapacity.map((facilityOp, index) => (
              <FacilityOpenCloseCapacity
                key={'id' in facilityOp ? facilityOp.id : facilityOp.key}
                facilityIndex={index}
              />
            ))}
            {typeof errors?.facilityOperationCapacity === 'string' ? (
              <div className="my-2 text-sw-error">{errors?.facilityOperationCapacity}</div>
            ) : null}
          </>
        );
      }}
    />
  );
};

const FacilityOpenCloseCapacity = ({facilityIndex}: {facilityIndex: number}) => {
  const {setFieldValue, values} = useFormikContext<FacilityCreationForm>();
  const {facilityOperationCapacity} = values;
  const handleOpenCloseTimeChange = useCallback(
    (val: ChangeEvent<HTMLInputElement>) => {
      const isDayChecked = val.target.checked;
      if (!isDayChecked) {
        setFieldValue(`facilityOperationCapacity[${facilityIndex}].open_time`, null);
        setFieldValue(`facilityOperationCapacity[${facilityIndex}].close_time`, null);
      } else {
        setFieldValue(`facilityOperationCapacity[${facilityIndex}].open_time`, WEEKLY_DEFAULT_OPEN_TIME);
        setFieldValue(`facilityOperationCapacity[${facilityIndex}].close_time`, WEEKLY_DEFAULT_CLOSE_TIME);
      }
      setFieldValue(`facilityOperationCapacity[${facilityIndex}].is_facility_open`, val.target.checked);
    },
    [setFieldValue, facilityIndex]
  );
  return (
    <div className="flex gap-x-3">
      <div className="w-1/4">
        <Field
          label={facilityOperationCapacity[facilityIndex].day}
          name={`facilityOperationCapacity[${facilityIndex}].day`}
          component={FormikCheckbox}
          checked={facilityOperationCapacity[facilityIndex].is_facility_open}
          onChange={(val: ChangeEvent<HTMLInputElement>) => handleOpenCloseTimeChange(val)}
        />
      </div>
      <div className="w-1/2">
        <Field
          simpleValue
          name={`facilityOperationCapacity[${facilityIndex}].open_time`}
          label="Open time"
          options={hourOptions}
          component={FormikSelect}
          disabled={!facilityOperationCapacity[facilityIndex].is_facility_open}
          clearable={false}
          getOptionLabel={(option: {label: string; value: string}) => option.label}
        />
      </div>
      <div className="w-1/2">
        <Field
          simpleValue
          name={`facilityOperationCapacity[${facilityIndex}].close_time`}
          label="Close time"
          options={hourOptions}
          component={FormikSelect}
          disabled={!facilityOperationCapacity[facilityIndex].is_facility_open}
          clearable={false}
        />
      </div>
    </div>
  );
};
