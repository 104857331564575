const getItineraryAddressString = (stop = {}, isLoadBoard = false, isSummary = false) => {
  const {
    city = '',
    state_province = '',
    postal_code = '',
    country = '',
    formatted_address = ''
  } = stop.location?.address || {};
  const company = stop.location?.company_name || '';

  if (isLoadBoard) {
    return `${city && `${city}, `}${state_province} ${postal_code} ${country}`;
  }
  if (isSummary) {
    // display address on second line when there's a company name
    return `${company && `${company}\n`}${formatted_address}`;
  }
  return `${company && `${company} - `}${formatted_address}`;
};

export default getItineraryAddressString;
