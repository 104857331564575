import {useMemo} from 'react';
import {DeprecatedButton, Title, Tabs, Tab, TabList, TabPanel, SvgIcon} from '@shipwell/shipwell-ui';
import {LoadPostRequest} from '@shipwell/tabula-sdk';
import {Formik} from 'formik';

import {getErrorDetail} from '../utils';
import {EditProps, formatInitialValues, formatValues, InitialValues} from './utils';
import useGetLoadpost from 'App/api/loadboards/hooks/useGetLoadpost';
import useGetLoadPostRequestByShipmentId from 'App/api/loadboards/hooks/useGetLoadpostRequestByShipmentId';
import PostSettingsForm from 'App/containers/Shipment/components/LoadBoards/Edit/PostSettingsForm';
import ReferencesForm from 'App/containers/Shipment/components/LoadBoards/Edit/ReferencesForm';
import EquipmentForm from 'App/containers/Shipment/components/LoadBoards/Edit/EquipmentForm';
import ItineraryForm from 'App/containers/Shipment/components/LoadBoards/Edit/ItineraryForm';
import useUpdateLoadpost from 'App/api/loadboards/hooks/useUpdateLoadpost';
import useCreateLoadPost from 'App/api/loadboards/hooks/useCreateLoadpost';
import './styles.scss';

const Edit = ({
  toggleModal,
  shipmentId,
  loadboardType,
  loadboardName,
  loadpostId,
  setSuccess,
  setError,
  contactInfo,
  mode
}: EditProps) => {
  const {data: loadpostRequestData} = useGetLoadPostRequestByShipmentId(shipmentId);
  const {data: loadpostData} = useGetLoadpost(loadboardType, loadpostId);

  const {mutate: createLoadpost, isLoading: isCreateLoading} = useCreateLoadPost({
    onSuccess: () => {
      setSuccess('Success!', `${loadboardName} Load Post created!`);
      toggleModal();
    },
    onError: (error) => {
      setError(`Failed ${loadboardName} Load Post`, getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });
  const {mutate: updateLoadpost, isLoading: isUpadteLoading} = useUpdateLoadpost({
    onSuccess: () => {
      setSuccess('Success!', `${loadboardName} Load Post updated!`);
      toggleModal();
    },
    onError: (error) => {
      setError(`Failed ${loadboardName} Load Post`, getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const onSubmit = (values: InitialValues) => {
    const formattedValues = formatValues(values);

    if (loadpostData?.load_reference) {
      updateLoadpost({
        loadboardType,
        loadpostId,
        loadPostRequestUpdate: formattedValues as LoadPostRequest
      });
    } else {
      createLoadpost({
        loadboardType,
        loadPostRequest: formattedValues as LoadPostRequest
      });
    }
  };

  const initialValues = useMemo(
    () => (loadpostData?.load_reference ? loadpostData : loadpostRequestData),
    [loadpostData, loadpostRequestData]
  );

  const formatedInitialValues = useMemo(
    () => formatInitialValues(initialValues as LoadPostRequest, contactInfo),
    [initialValues, contactInfo]
  );

  return (
    <Formik onSubmit={onSubmit} initialValues={formatedInitialValues as unknown as InitialValues} enableReinitialize>
      {({handleSubmit, values, setFieldValue}) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <div>
              <Tabs selectedTabClassName="active">
                <div className="flex">
                  <div className="sw-side-nav flex-shirnk-0 max-w-[213px] border-r-0 bg-sw-background">
                    <div className="px-4 py-8">
                      <Title variant="sectionTitle" className="mb-0">
                        Load Post Details
                      </Title>
                    </div>
                    <TabList className="sw-side-nav-content h-auto flex-col p-0">
                      <Tab className="sw-side-nav-item border-b-0" data-testid="post-settings">
                        Post Settings
                      </Tab>
                      <Tab className="sw-side-nav-item border-b-0" data-testid="references">
                        References
                      </Tab>
                      <Tab className="sw-side-nav-item border-b-0" data-testid="equipment">
                        Equipment
                      </Tab>
                      <Tab className="sw-side-nav-item border-b-0" data-testid="itinerary">
                        Itinerary
                      </Tab>
                    </TabList>
                  </div>
                  <div className="flex-1 p-4">
                    <TabPanel>
                      <PostSettingsForm values={values} setFieldValue={setFieldValue} />
                    </TabPanel>
                    <TabPanel>
                      <ReferencesForm />
                    </TabPanel>
                    <TabPanel>
                      <EquipmentForm values={values} setFieldValue={setFieldValue} mode={mode} />
                    </TabPanel>
                    <TabPanel>
                      <ItineraryForm itinerary={values.itinerary || []} />
                    </TabPanel>
                  </div>
                </div>
              </Tabs>
              <div className="sticky bottom-0 z-10 flex justify-end gap-2 bg-sw-background-component p-4">
                <DeprecatedButton type="button" variant="secondary" onClick={toggleModal}>
                  Cancel
                </DeprecatedButton>
                <DeprecatedButton type="submit">
                  {isCreateLoading || isUpadteLoading ? <SvgIcon name="LoadingDots" /> : 'Save'}
                </DeprecatedButton>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Edit;
