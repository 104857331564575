import {QueryClient, useQueryClient} from '@tanstack/react-query';
import React from 'react';

export type WithQueryClientProps = {
  queryClient: QueryClient;
};

/**
 * Higher order component for class or functional components that may require the query client
 * but cannot for some reason use the `useQueryClient` hook.
 * @param {React.ComponentType<P>} WrappedComponent The component which will have all props forwarded plus a new property called `queryClient`
 * @example
 * ```tsx
 * import React from 'react';
 * import withQueryClient from 'App/components/withQueryClient';
 *
 *
 * class MyLegacyClassComponent {
 *
 *   constructor(props) {
 *     this.state = {};
 *     this.handleSubmit = this.handleSubmit.bind(this);
 *   }
 *
 *   handleSubmit() {
 *    return this.props.api.doWork(...)
 *      .then((response) => {
 *        this.props.dispatch({type: 'some-action-key', data: response});
 *        const queryKey: string[] = ['QUERY_KEY', 'SHIPMENT_ID', 'STOP_ID']
 *        this.props.queryClient.invalidateQueries(queryKey);
 *        this.props.setSuccess('Success', 'Successfully cleared the query client cache');
 *      }).catch((err) => this.props.setError('Error', err));
 *   }
 * }
 *
 * const MyLegacyClassComponentWithQueryClient = withQueryClient<{prop1: number, prop2: string, prop3: Date}>(MyLegacyClassComponent);
 *
 * export default MyLegacyClassComponentWithQueryClient;
 * ```
 * @returns {React.FC<Omit<P, 'queryClient'>>}
 */
const withQueryClient = <TComponentProps,>(
  WrappedComponent: React.ComponentType<TComponentProps & WithQueryClientProps>
): React.FC<TComponentProps> => {
  // Return a new component that wraps the provided component with QueryClientProvider
  const WithQueryClient: React.FC<TComponentProps> = (props: TComponentProps) => {
    const queryClient = useQueryClient();

    return <WrappedComponent {...props} queryClient={queryClient} />;
  };

  return WithQueryClient;
};

export default withQueryClient;
