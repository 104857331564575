import {Hazmat, Product, ShipmentLineItemHazmatPackingGroupEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {LineItemFormValues} from '../LineItemFields/types';

//hazmat coming from product
export type ProductHazmat = Pick<
  Product,
  'hazmat_hazard_class' | 'hazmat_identification_number' | 'hazmat_packing_group' | 'hazmat_proper_shipping_name'
> & {hazmat_required?: boolean};

//hazmat coming from line items
export type LineItemsHazmat = Record<string, LineItemFormValues[]>;

export type FormValues = LineItemsHazmat | ProductHazmat;

export const packingGroupOptions = Object.values(ShipmentLineItemHazmatPackingGroupEnum).reduce(
  (acc, value) => [...acc, {label: value, value}],
  [] as Array<{label: ShipmentLineItemHazmatPackingGroupEnum; value: ShipmentLineItemHazmatPackingGroupEnum}>
);

// determines if line item hazmat or saved product hazmat
export const isHazmat = (value: Hazmat | ProductHazmat): value is Hazmat => {
  return 'identification_number' in value;
};
