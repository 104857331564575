import {JSX, useMemo} from 'react';
import {Card, SvgIcon} from '@shipwell/shipwell-ui';

type SearchResultCardTitleProps = {
  referenceId?: string | null;
  purchaseOrderNumbers?: string[] | null;
  customerReferenceNumber?: string | null;
  lane?: string | null;
  onScheduleClick?: () => void;
};

const SearchResultCardTitle = ({
  customerReferenceNumber,
  purchaseOrderNumbers,
  referenceId,
  lane,
  onScheduleClick
}: SearchResultCardTitleProps): JSX.Element => {
  const shipmentReferenceDisplay = useMemo(() => {
    if (purchaseOrderNumbers?.length) {
      if (purchaseOrderNumbers.length === 1) {
        return purchaseOrderNumbers[0];
      }
      return `${purchaseOrderNumbers[0]} (+${purchaseOrderNumbers.length - 1})`;
    }
    return customerReferenceNumber || referenceId;
  }, [customerReferenceNumber, purchaseOrderNumbers, referenceId]);

  return (
    <div className="grid w-full grid-flow-col">
      <header className="flex flex-row items-center gap-1 text-sm font-normal">
        <SvgIcon name="Truck" />
        <p className="mb-0">
          <strong>Shipment {shipmentReferenceDisplay}</strong> • {lane}
        </p>
      </header>
      <main className="justify-self-end">
        <button
          className="rounded bg-sw-primary px-6 py-1 font-normal text-sw-text-reverse"
          onClick={onScheduleClick}
          type="button"
        >
          Schedule
        </button>
      </main>
    </div>
  );
};

export type SearchResultCardProps = {
  loadType?: string | null;
  totalAppointmentsRequired?: number | null;
  carrierName?: string | null;
  plannedDateRange?: string | null;
} & Pick<
  SearchResultCardTitleProps,
  'onScheduleClick' | 'referenceId' | 'customerReferenceNumber' | 'purchaseOrderNumbers' | 'lane'
>;

const SearchResultCard = ({
  referenceId,
  purchaseOrderNumbers,
  customerReferenceNumber,
  lane,
  loadType,
  totalAppointmentsRequired,
  carrierName,
  plannedDateRange,
  onScheduleClick
}: SearchResultCardProps) => {
  const mappedFields = [
    {
      label: 'load type',
      text: loadType
    },
    {
      label: 'appts required',
      text: totalAppointmentsRequired
    },
    {
      label: 'carrier',
      text: carrierName
    },
    {
      label: 'dates',
      text: plannedDateRange
    }
  ];
  return (
    <Card
      draggableProvided={null}
      title={
        <SearchResultCardTitle
          referenceId={referenceId}
          purchaseOrderNumbers={purchaseOrderNumbers}
          customerReferenceNumber={customerReferenceNumber}
          lane={lane}
          onScheduleClick={onScheduleClick}
        />
      }
    >
      <div className="grid grid-cols-4 gap-4" role="list">
        {mappedFields.map(({label, text}) => {
          const labelId = `${label.replaceAll(' ', '-')}-label`;
          return (
            <div key={label} className="flex flex-col" role="listitem">
              <span className="text-xxs uppercase" id={labelId}>
                {label}
              </span>
              <span className="text-sm" aria-labelledby={labelId}>
                {text ?? '--'}
              </span>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default SearchResultCard;
