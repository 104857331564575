import {ShipmentLineItem, ShipmentLineItemWeightUnitEnum} from '@shipwell/backend-core-sdk';
import {convertKilogramsToPounds} from 'App/utils/internationalConstants';

export const getTotalShipmentLineItemsWeightLbs = (lineItems?: ShipmentLineItem[]) => {
  return (
    lineItems?.reduce((totalWeight: number, lineItem) => {
      const lineItemWeightPounds =
        !lineItem.package_weight || !lineItem.total_packages
          ? 0
          : lineItem?.weight_unit === ShipmentLineItemWeightUnitEnum.Kg
          ? Number(convertKilogramsToPounds(lineItem?.package_weight * lineItem.total_packages))
          : lineItem?.package_weight * lineItem.total_packages;
      return totalWeight + lineItemWeightPounds;
    }, 0) || 0
  );
};
