import {Dropdown, Modal, SvgIcon, Toast} from '@shipwell/shipwell-ui';
import {TagStatus} from '@shipwell/corrogo-sdk';
import {useQueryClient} from '@tanstack/react-query';
import {TagModal} from '../../../../../components/tagModal/tagModal';
import {TAGS_SEARCH_KEY} from '../useTagsService/constants';
import {useTagsService} from './useTagsService/useTagsService';
import {ToastData} from './toastData';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';

export interface ActionsCellProps {
  tagId: string;
  status: TagStatus;
}

export function ActionsCell(props: ActionsCellProps) {
  const queryClient = useQueryClient();
  const tagsModal = useVisibility<string>();
  const tagsService = useTagsService();
  const toast = useVisibility<ToastData>();
  const confirmDeleteModal = useVisibility();

  function handleEditSave() {
    tagsModal.hideAndClearData();
    void queryClient.invalidateQueries({
      queryKey: [TAGS_SEARCH_KEY]
    });
  }

  function handleUpdateStatus() {
    const nextStatus = props.status === TagStatus.Active ? TagStatus.Inactive : TagStatus.Active;
    tagsService.updateStatus(
      {
        tagId: props.tagId,
        status: nextStatus
      },
      {
        onSuccess: () => {
          toast.showWithData({
            title: 'Success!',
            variant: 'success',
            content: 'Status updated.'
          });
        },
        onError: () => {
          toast.showWithData({
            title: 'Error!',
            variant: 'error',
            content: 'Status could not be saved.'
          });
        }
      }
    );
  }

  function handleDelete() {
    confirmDeleteModal.hideAndClearData();
    tagsService.delete(
      {
        tagId: props.tagId
      },
      {
        onSuccess: () => {
          toast.showWithData({
            title: 'Success!',
            variant: 'success',
            content: 'Tag deleted.'
          });
        },
        onError: () => {
          toast.showWithData({
            title: 'Error!',
            variant: 'error',
            content: 'Tag could not be deleted.'
          });
        }
      }
    );
  }

  return (
    <>
      <Dropdown
        variant="icon"
        size={'small'}
        className={'flex justify-center'}
        indicator={false}
        icon={<SvgIcon name={'Overflow'} aria-label="row actions" />}
      >
        {({onClick}: {onClick: () => void}) => [
          <div
            key="edit"
            className={'text-[#000]'}
            onClick={() => {
              onClick();
              tagsModal.showWithData(props.tagId);
            }}
          >
            Edit
          </div>,
          <div
            key="activate"
            className={'text-[#000]'}
            onClick={() => {
              onClick();
              handleUpdateStatus();
            }}
          >
            {props.status === TagStatus.Active ? 'Deactivate' : 'Activate'}
          </div>,
          <div
            key="remove"
            className={'text-sw-error'}
            onClick={() => {
              onClick();
              confirmDeleteModal.show();
            }}
          >
            Delete
          </div>
        ]}
      </Dropdown>

      <TagModal
        show={tagsModal.isVisible}
        tagId={tagsModal.data}
        onClose={tagsModal.hideAndClearData}
        onSave={handleEditSave}
      />

      <Modal
        show={confirmDeleteModal.isVisible}
        size={'small'}
        title={'Warning'}
        variant={'warning'}
        onPrimaryAction={handleDelete}
        onClose={confirmDeleteModal.hide}
      >
        Confirm delete?
      </Modal>

      <Toast
        show={toast.isVisible}
        variant={toast.data?.variant}
        title={toast.data?.title}
        onClose={toast.hideAndClearData}
      >
        {toast.data?.content}
      </Toast>
    </>
  );
}
