import Textarea from 'react-textarea-autosize';
import FormGroup from '../../formGroup';

/**
 * TextArea Field
 */
const TextareaField = (props) => {
  const {req, input, minRows, disabled, placeholder, ...rest} = props;
  const value = input.value || props.value;
  const name = input.name || props.name;
  const onChange = input.onChange || props.onChange;

  return (
    <FormGroup {...props}>
      {() => (
        <Textarea
          placeholder={placeholder}
          name={name}
          value={value}
          defaultValue={value}
          minRows={minRows}
          disabled={disabled}
          onChange={(value) => onChange && onChange(value)}
        />
      )}
    </FormGroup>
  );
};

TextareaField.defaultProps = {
  name: '',
  value: '',
  minRows: 1,
  input: {}
};

export default TextareaField;
