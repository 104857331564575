import {Formik, Field, useFormikContext, ArrayHelpers} from 'formik';
import {object, string, number, InferType} from 'yup';
import noop from 'lodash/noop';
import isString from 'lodash/isString';
import {FormikSelect, FormikTextInput, SplitDropdown, Checkbox, Title, DeprecatedButton} from '@shipwell/shipwell-ui';
import {ChargeCategory, DrayageBookingAccessorials} from '@shipwell/corrogo-sdk';
import type {TenderFormValues} from 'App/containers/shipments/components/TenderFields/validation';
import useListState from 'App/utils/hooks/useListState';
import {useShipmentLegs} from 'App/data-hooks';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

const accessorialOptions = Object.entries(DrayageBookingAccessorials).map(([label, value]) => ({label, value}));

const validationSchema = object().shape({
  accessorial: object().label('Accessorial').required().nullable().shape({
    label: string().required(),
    value: string().required()
  }),
  quantity: number().label('Quantity').required(),
  rate: number().label('Rate').required()
});

type AddAccessorialFormValues = InferType<typeof validationSchema>;

const AddAccessorialFields = ({
  containers,
  push,
  shipmentId
}: Pick<TenderFormValues, 'containers'> & Pick<ArrayHelpers, 'push'> & {shipmentId: string}) => {
  const {
    values: {accessorial, quantity, rate},
    resetForm
  } = useFormikContext<AddAccessorialFormValues>();
  const {setItemState, getItemState, filterByState, setAllItemStates} = useListState(containers);

  const shipmentLegsQuery = useShipmentLegs(shipmentId, {
    staleTime: HOT_DATA_STALE_TIME
  });

  if (shipmentLegsQuery.isInitialLoading) {
    return null;
  }

  return (
    <>
      <Field component={FormikSelect} name="accessorial" label="Accessorial" required options={accessorialOptions} />
      <Field component={FormikTextInput} name="quantity" label="Qty" required type="number" />
      <Field component={FormikTextInput} name="rate" label="Rate" required type="number" />
      <SplitDropdown
        buttonLabel="Add to all containers"
        disabled={!(accessorial && quantity && rate)}
        onButtonClick={() => {
          if (accessorial === null) {
            return;
          }
          containers.forEach((container) =>
            push({
              leg_item_id: shipmentLegsQuery.getItemFromLeg(container.value)?.id,
              name: accessorial.label,
              quantity: quantity,
              unit_amount: {value: rate},
              category: ChargeCategory.Accessorial
            })
          );
          resetForm();
        }}
        dropdownChildren={({onClick}: {onClick: () => void}) => (
          <>
            {containers.map((container, index) => (
              <li
                onClick={() => setItemState(container, !getItemState(container))}
                className="flex flex-row items-center gap-2"
                key={index}
              >
                <Checkbox checked={!!getItemState(container)} />
                {container.label}
              </li>
            ))}
            <div className="flex justify-center">
              <DeprecatedButton
                className="w-full"
                size="small"
                variant="primary"
                onClick={() => {
                  if (accessorial === null) {
                    return;
                  }
                  filterByState(true).forEach((selectedContainer) => {
                    if (!isString(selectedContainer.value)) {
                      return;
                    }
                    push({
                      leg_item_id: shipmentLegsQuery.getItemFromLeg(selectedContainer.value)?.id,
                      name: accessorial.label,
                      quantity: quantity,
                      unit_amount: {value: rate},
                      category: ChargeCategory.Accessorial
                    });
                  });
                  resetForm();
                  setAllItemStates(false);
                  onClick();
                }}
              >
                Add
              </DeprecatedButton>
            </div>
          </>
        )}
      />
    </>
  );
};
const AddAccessorialForm = ({push, shipmentId}: Pick<ArrayHelpers, 'push'> & {shipmentId: string}) => {
  const {
    values: {containers}
  } = useFormikContext<TenderFormValues>();

  return (
    <div className="rounded bg-sw-background p-4">
      <Title variant="sectionTitle">Accessorials</Title>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          {
            accessorial: null,
            quantity: 1
          } as AddAccessorialFormValues
        }
        onSubmit={noop}
      >
        {() => (
          <div className="grid grid-cols-[30%,8%,25%,37%] gap-x-4">
            <AddAccessorialFields containers={containers} push={push} shipmentId={shipmentId} />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddAccessorialForm;
