import {useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteSupplier} from 'App/api/suppliers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useDeleteSupplier = () => {
  const queryClient = useQueryClient();
  const {mutate, status} = useMutation(deleteSupplier, {
    onSettled: () => {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
    }
  });
  return {deleteSupplier: mutate, status};
};
