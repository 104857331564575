import {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import {SubmissionError} from 'redux-form';
import * as actions from '../../actions/users';
import * as authActions from '../../actions/auth';
import ProfileForm from './forms/ProfileForm';
import PasswordForm from './forms/PasswordForm';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {getUserMe} from 'App/containers/userProfile/actions/async';
import {unpackErrors, supportedTimezones} from 'App/utils/globals';
import './_updatePassword.scss';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company
  }),
  {...actions, ...authActions, getUserMe}
)
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setRef = this.setRef.bind(this);
    this.state = {
      currentPass: '',
      newPass: '',
      updated: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      showPasswordModal: false,
      viewPasswordNew: false,
      viewPasswordCurrent: false,
      requirements: {length: false, capital: false, special: false},
      success: false,
      done: false,
      imageRef: null
    };
  }

  componentDidMount() {
    this.setState({
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      phone_number: this.props.user.phone_number
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        first_name: nextProps.user.first_name,
        last_name: nextProps.user.last_name,
        email: nextProps.user.email,
        phone_number: nextProps.user.phone_number
      });
    }
  }

  renderAll(obj) {
    return this.props.editMyUserProfile(this.props.company.id, this.props.user.id, obj).then(
      function (response) {
        if (response.status === 200) {
          this.props.getUserMe();
          this.setState({updated: true});
          setTimeout(() => {
            this.setState({updated: false});
          }, 5000);
        } else {
          const errors = response.field_errors || [];
          let submissionError = {user: {}, company: {}};
          submissionError = unpackErrors(errors, submissionError);
          submissionError._error = response.error_description;
          //handle edge cases for errors here
          if (submissionError.user) {
            if (submissionError.user.phone_number) {
              submissionError.company.primary_phone_number = submissionError.user.phone_number;
            }
            //user emails are in the form as company email

            if (submissionError.user.email) {
              submissionError.company.primary_email = submissionError.user.email;
            }
          }

          throw new SubmissionError(submissionError);
        }
      }.bind(this)
    );
  }

  handlePasswordViewNew() {
    this.setState({viewPasswordNew: !this.state.viewPasswordNew});
  }
  handlePasswordViewCurrent() {
    this.setState({viewPasswordCurrent: !this.state.viewPasswordCurrent});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  deleteAvatar() {
    const userObj = JSON.parse(JSON.stringify(this.props.user));
    userObj.avatar = '';
    this.renderAll(userObj);
  }

  handleSaveAvatar() {
    const userObj = JSON.parse(JSON.stringify(this.props.user));
    const canvasScaled = this.editor.getImageScaledToCanvas();
    if (canvasScaled) {
      canvasScaled.toBlob((blob) => {
        const file = new File([blob], userObj.first_name + '.png');
        userObj.avatar = file;
        return this.renderAll(userObj);
      });
    }
  }

  onFormSubmit(attrs) {
    const canvasScaled = this.editor && this.editor.getImageScaledToCanvas();
    const attrCopy = JSON.parse(JSON.stringify(attrs));
    const userObj = {
      first_name: attrCopy.first_name,
      id: this.props.user.id,
      last_name: attrCopy.last_name,
      email: attrCopy.email,
      phone_number: attrCopy.phone_number,
      timezone: attrCopy.timezone,
      permissions: this.props.user.permissions
    };
    if (attrs.avatar && canvasScaled) {
      canvasScaled.toBlob((blob) => {
        const file = new File([blob], userObj.first_name + '.png');
        userObj.avatar = file;
        return this.renderAll(userObj);
      });
    } else {
      return this.renderAll(userObj);
    }
  }
  setRef(ref) {
    this.editor = ref;
  }

  render() {
    const timezones = [];
    for (var i = 0; i < supportedTimezones.length; i++) {
      timezones.push({id: supportedTimezones[i], name: supportedTimezones[i]});
    }

    return (
      <div className="settings-form">
        <ProfileForm
          timezones={timezones}
          saved={this.state.updated}
          onSubmit={this.onFormSubmit}
          user={this.props.user}
          setRef={this.setRef}
          deleteAvatar={this.deleteAvatar.bind(this)}
          handleSaveAvatar={this.handleSaveAvatar.bind(this)}
        />

        <InfoModalWrapper
          show={this.state.showError}
          onHide={() => this.setState({showError: false, errorDetails: ''})}
          title="Error"
          children={
            <h4
              style={{
                padding: '30px 10px',
                textAlign: 'center'
              }}
            >
              {' '}
              <i className="icon icon-Delayed" />
              &nbsp;
              {this.state.errorDetails}
            </h4>
          }
        />
      </div>
    );
  }
}
