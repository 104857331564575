import {CustomFieldAllowedValue} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikTextInput} from '@shipwell/shipwell-ui';
import {FieldProps} from 'formik';
import {ChangeEvent, useState} from 'react';
import {convertSemicolinDelimitedStringToAllowedValueObjects} from './utils';

export const CustomFieldAllowedValueInput = ({
  initialAllowedValues,
  ...props
}: {
  /**
   * Must be the original allowed values from the server and not from updated form values.
   */
  initialAllowedValues?: CustomFieldAllowedValue[];
} & FieldProps<CustomFieldAllowedValue[] | undefined>) => {
  const [valuesString, setValuesString] = useState(() => props.field.value?.map((v) => v.label).join('; ') || '');

  const fieldName = props.field.name;
  const {setFieldValue} = props.form;

  return (
    <FormikTextInput
      {...props}
      value={valuesString}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const newValuesString = event.target.value;
        setValuesString(newValuesString);

        const newAllowedValues: CustomFieldAllowedValue[] = convertSemicolinDelimitedStringToAllowedValueObjects(
          newValuesString,
          initialAllowedValues
        );

        setFieldValue(fieldName, newAllowedValues);
      }}
    />
  );
};
