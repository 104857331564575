import {object, string, array, mixed, number, bool} from 'yup';

import moment from 'moment';
import {StandardHolidaysList} from '../../data-hooks/facilities/constants';

import {validatePhoneNumber} from 'App/utils/globals';

export const facilityInfoValidationSchema = object().shape({
  name: string().required('Facility name is required.'),
  address: mixed()
    .required('Facility address is required')
    .test('address', 'A valid address is required.', (value) => !!value),
  location_type: string().nullable().required('Location type is required.'),
  facility_services: array()
    .of(string())
    .required('Facility Services is required.')
    .default(() => []),
  external_reference: string().nullable().required('External reference is required.'),
  notes: string().notRequired().nullable(),
  carrier_instructions: string().notRequired().nullable(),
  disclaimers: string().notRequired().nullable(),
  documents: array().of(
    object().shape({
      file: mixed().notRequired(),
      description: string().notRequired().nullable()
    })
  ),
  is_manual_timezone: bool().default(false),
  manual_timezone: string().when('is_manual_timezone', {
    is: true,
    then: string().nullable().required('Please select a timezone.')
  })
});

export const facilityContactsValidationSchema = object().shape({
  facilityContacts: array()
    .of(
      object().shape({
        person_name: string().required('Full name is required.'),
        phone_number: string()
          .nullable()
          .required('Phone number is required.')
          .test('phone_number', 'Invalid phone number.', (value) => {
            if (value) {
              return validatePhoneNumber(value);
            }
            return true;
          }),
        email: string().nullable().email('A valid email is required.').required('Email is required.'),
        facility_role: string().nullable().required('Facility role is required.'),
        is_default_for_facility: bool().default(false)
      })
    )
    .test({
      name: 'Primary Contact',
      message: 'At least one contact needs to be selected as primary.',
      test: (values = []) => values.some((value) => value.is_default_for_facility)
    })
});

export const facilityOperationCapacityValidationSchema = object().shape({
  facilityOperationCapacity: array()
    .of(
      object().shape({
        day: string().nullable().optional(),
        open_time: string().nullable().optional(),
        close_time: string()
          .nullable()
          .optional()
          .test({
            name: 'close_time',
            message: 'Close time can not be before opening time',
            test: function (value) {
              const {open_time} = this.parent as {open_time?: string};
              if (open_time && value) {
                const openTime = moment(open_time, 'HH:mm:ss');
                const closeTime = moment(value, 'HH:mm:ss');
                return closeTime.isAfter(openTime);
              }
              return true;
            }
          }),
        is_facility_open: bool()
      })
    )
    .test({
      name: 'Operation Capacity Days',
      message: 'At least one day must be selected.',
      test: (values = []) => values.some((value) => value.is_facility_open)
    }),
  appointmentRules: object().shape({
    appointment_lead_time_duration: string().nullable().required('Lead time is required.'),
    late_appointment_threshold_duration: string().nullable().required('Late threshold is required.'),
    first_appointment_start_time: string().nullable().required('First appointment start time is required.'),
    last_appointment_end_time: string().nullable().required('Last appointment end time time required.')
  }),

  holidayRules: array().of(
    object().shape({
      name: string().nullable().required('Holiday name is required.'),
      date: string().nullable().required('Holiday date is required.'),
      firstAppointmentStartTime: string()
        .nullable()
        .when('closedAllDay', {
          is: false,
          then: string().nullable().required('Open time is required.')
        }),
      lastAppointmentEndTime: string()
        .nullable()
        .when('closedAllDay', {
          is: false,
          then: string().nullable().required('Close time is required.')
        }),
      closedAllDay: bool(),
      isCustom: bool(),
      standard: string()
        .optional()
        .when('isCustom', {
          is: false,
          then: string().required().oneOf(StandardHolidaysList)
        })
    })
  )
});

export const facilityLoadTypesValidationSchema = object().shape({
  loadTypes: array()
    .of(
      object().shape({
        name: string().nullable().required('Name is required.'),
        stackable: bool().nullable(),
        is_hazmat: bool().nullable(),
        all_day_appointment: bool(),
        load_unload_duration: string()
          .nullable()
          .when('all_day_appointment', {
            is: true,
            then: (schema) => schema.optional(),
            otherwise: (schema) => schema.required('A loading/unloading time window is required.')
          }),
        appointment_duration: string()
          .nullable()
          .when('all_day_appointment', {
            is: true,
            then: (schema) => schema.optional(),
            otherwise: (schema) => schema.required('Appointment time window is required.')
          }),
        delivery_type: string().nullable().required('Delivery type is required.'),
        mode: string().nullable().optional(),
        equipment_type: string().nullable().optional(),
        product_reference: string().nullable().optional(),
        packaging_type: string().nullable().optional(),
        product_category: array().of(string()).nullable()
      })
    )
    .test({
      name: 'Load Types',
      message:
        'At least one of these fields must be different between two load types - Name, Delivery Type, Mode, Equipment, Product Reference, Packaing Type.',
      test: (values = []) => {
        const keysToCheckDuplicate = [
          'name',
          'delivery_type',
          'mode',
          'equipment_type',
          'product_reference',
          'packaging_type'
        ];
        const stringifiedValues = values.map((loadTypeEntry) =>
          JSON.stringify(keysToCheckDuplicate.map((key) => loadTypeEntry[key]))
        );
        return !(new Set(stringifiedValues).size < values.length);
      }
    })
});

export const facilityDocksValidationSchema = object().shape({
  docks: array()
    .of(
      object().shape({
        name: string().nullable().required('Dock name is required.'),
        dockRules: array().of(
          object().shape({
            appointment_type: string().nullable().required('Appointment type is required.'),
            loadTypeName: string().nullable().optional(),
            delivery_type: string().nullable().optional(),
            max_concurrent_appointments: number().nullable().optional(),
            mode: string().nullable().optional(),
            equipment_type: string().nullable().optional(),
            product_reference: string().nullable().optional(),
            packaging_type: string().nullable().optional(),
            product_category: array().of(string()).nullable(),
            dock_accessorials: array().of(string().nullable()),
            first_appointment_start_time: string().nullable().optional(),
            last_appointment_end_time: string().nullable().optional(),
            temperature: object().nullable().shape({
              minimum: string().nullable().optional(),
              maximum: string().nullable().optional()
            }),
            is_public: bool()
          })
        )
      })
    )
    .test({
      name: 'Docks',
      message: 'Docks must have unique names',
      test: (values = []) => {
        const uniqDockNames = new Set();
        const isValidDocksList = values.some((dock) => uniqDockNames.size === uniqDockNames.add(dock.name).size);
        return !isValidDocksList;
      }
    })
});
