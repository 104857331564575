import {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import './styles.scss';

class SuccessToast extends Component {
  static defaultProps = {
    title: 'Success!',
    autohide: 2000
  };

  render() {
    const {open, title, children, autohide, onClose} = this.props;

    return (
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={open}
        autoHideDuration={autohide}
        onClose={onClose}
      >
        <div className="toast-container">
          <div className="toast-header">
            <i className="flaticon-multiply" onClick={onClose} />
          </div>
          <div className="toast-body">
            <span>
              <i className="flaticon-check_filled text-success" />
            </span>
            <span className="toast-content">
              <p className="text-success">
                <b>{title}</b>
              </p>
              {children}
            </span>
          </div>
        </div>
      </Snackbar>
    );
  }
}

export default SuccessToast;
