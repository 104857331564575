import {uniq, set} from 'lodash';
import moment from 'moment';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import isNil from 'lodash/isNil';
import {validatePlannedTimeWindowBeforeNextStop} from './validatePlannedTimeWindow';
import {validateEmail, validatePhoneNumber, reeferTypes} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {makeValidationErrors} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';

const hasDuplicates = (value) => {
  return uniq(value).length !== value.length;
};

const validate = (values, {customFields = [], companyId}) => {
  const {hasParcel} = checkShipmentModes(values.mode);
  const errors = {};
  // check state (not required but it should not be blank)
  if (!values.state) {
    errors.state = 'A shipment status is required.';
  }
  // cancelled is not in the dropdown, enforce selecting one if cancelled
  if (values.state && values.state === 'cancelled' && !values.metadata.open && !values.delayed) {
    errors.state = 'A shipment status is required to change from cancelled.';
  }
  // vendor is required to add a driver phone
  if (
    (!values.vendor || (values.vendor && !values.vendor.value)) &&
    values.equipment_config &&
    values.equipment_config.driver &&
    validatePhoneNumber(values.equipment_config.driver.phone_number)
  ) {
    errors.vendor = 'A vendor is required to add a phone #.';
  }

  if (values.vendor && !values.vendor_point_of_contact) {
    errors.vendor_point_of_contact = 'A carrier point of contact is required for the shipment.';
  }

  // allow phone to be blank
  if (
    values.equipment_config &&
    values.equipment_config.driver &&
    values.equipment_config.driver.phone_number &&
    !validatePhoneNumber(values.equipment_config.driver.phone_number) &&
    values.equipment_config &&
    values.equipment_config.driver &&
    values.equipment_config.driver.phone_number &&
    values.equipment_config.driver.phone_number.length !== 0
  ) {
    errors.equipment_config = {};
    errors.equipment_config.driver = {};
    errors.equipment_config.driver.phone_number = {};
    errors.equipment_config.driver.phone_number = 'A valid phone is required.';
  }

  if (values.mode && !values.mode.id) {
    errors.mode = 'An equipment mode is required.';
  }

  if (values.service_level && !values.service_level.id) {
    errors.service_level = {};
    errors.service_level.id = 'A service level is required.';
  }

  // reefer
  if (values.equipment_type && reeferTypes.includes(values.equipment_type.id)) {
    if (isNaN(values.temperature_lower_limit) || isNil(values.temperature_lower_limit)) {
      errors.temperature_lower_limit = 'Invalid temperature.';
    }
    if (isNaN(values.temperature_upper_limit) || isNil(values.temperature_lower_limit)) {
      errors.temperature_upper_limit = 'Invalid temperature.';
    }
    if (parseFloat(values.temperature_upper_limit) < parseFloat(values.temperature_lower_limit)) {
      errors.temperature_upper_limit = 'Upper temperature must be greater than lower temperature.';
    }
  }

  //STOPS
  if (values.stops && values.stops.length > 0) {
    errors.stops = [];

    // check that ordinal indexes are unique
    const ordinalIndexes = [];
    const prevStopDates = [];
    const prevConfirmDates = [];
    values.stops.map((stop) => ordinalIndexes.push(Number(stop.ordinal_index)));

    for (var i = 0; i < values.stops.length; i++) {
      errors.stops[i] = {};
      errors.stops[i].location = {};
      if (!values.stops.filter((e) => e.is_pickup).length) {
        errors.stops[0].is_pickup = 'At least one pickup is required';
      }
      if (!values.stops.filter((e) => e.is_dropoff).length) {
        errors.stops[0].is_pickup = 'At least one delivery is required';
      }

      if (values.stops[i].planned_date && moment(values.stops[i].planned_date).isValid()) {
        prevStopDates.push(moment(values.stops[i].planned_date));
      }

      if (i > 0) {
        const currentStopDate = moment(values.stops[i].planned_date);

        if (
          currentStopDate.isValid() &&
          prevStopDates.some((date) => moment(values.stops[i].planned_date).isBefore(date, 'day'))
        ) {
          errors.stops[i].planned_date = 'Date must be before previous stop dates';
        }
      }

      /** Ensure completion date is after arrival date */
      if (
        moment(values.stops[i].confirmed_arrival_at).isValid() &&
        moment(values.stops[i].confirmed_departure_at).isValid()
      ) {
        if (moment(values.stops[i].confirmed_departure_at).isBefore(moment(values.stops[i].confirmed_arrival_at))) {
          errors.stops[i].confirmed_departure_at = 'Completed date must be after arrival date.';
        }
      }
      /** Ensure current completion date and arrival date are after previous dates */
      if (i > 0 && moment(values.stops[i].confirmed_arrival_at).isValid()) {
        if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_arrival_at).isBefore(prevDate))) {
          errors.stops[i].confirmed_arrival_at = 'Date must be after previous dates.';
        }
      }
      if (i > 0 && moment(values.stops[i].confirmed_departure_at).isValid()) {
        if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_departure_at).isBefore(prevDate))) {
          errors.stops[i].confirmed_departure_at = 'Date must be after previous dates.';
        }
      }

      if (moment(values.stops[i].confirmed_arrival_at).isValid()) {
        prevConfirmDates.push(moment(values.stops[i].confirmed_arrival_at));
      }
      if (moment(values.stops[i].confirmed_departure_at).isValid()) {
        prevConfirmDates.push(moment(values.stops[i].confirmed_departure_at));
      }

      if (values.stops[i].planned_time_window_start && values.stops[i].planned_time_window_end) {
        const startTime = moment(values.stops[i].planned_time_window_start, 'HH:mm');
        const stopTime = moment(values.stops[i].planned_time_window_end, 'HH:mm');
        //check if schedule selection is 'Open' before validating start and stop time
        const scheduleSelectOpen = values.stops[i]['schedule-select'] === 2;
        if (startTime.isValid() && stopTime.isValid() && moment(stopTime).isBefore(startTime) && scheduleSelectOpen) {
          errors.stops[i].planned_time_window_end = 'Stop time must be after start time';
        }
      }
      const isPlannedTimeWindowBeforeNextStop = validatePlannedTimeWindowBeforeNextStop(values, i);
      if (!isPlannedTimeWindowBeforeNextStop) {
        errors.stops[i].planned_time_window_start = `Appointment time must be before the next stop`;
      }
      if (hasDuplicates(ordinalIndexes)) {
        errors.stops[i].ordinal_index = 'Cannot have multiple stops with the same number';
      }

      // a stop must be at least one of is_dropoff or is_pickup
      if (!values.stops[i].is_pickup && !values.stops[i].is_dropoff) {
        errors.stops[i].is_pickup = 'A stop must be a pickup or delivery';
      }

      if (
        (values.stops[i] && Object.keys(values.stops[i]).length === 0) ||
        Object.keys(values.stops[i].location.address).length === 0
      ) {
        errors.stops[i].location.address = 'Enter a complete address';
      } else if (!values.stops[i].location.address) {
        errors.stops[i].location.address = 'Enter a complete address';
      } else if (values.stops[i] && !values.stops[i].location?.address?.country && !hasParcel) {
        errors.stops[i].location.address = 'Enter a complete address';
      }
      if (!values.stops[i].location || (!hasParcel && !values.stops[i].location.company_name)) {
        errors.stops[i].location.company_name = 'Company required';
      }
      if (!values.stops[i].location || !values.stops[i].location.location_name) {
        errors.stops[i].location.location_name = 'Location required';
      }
      if (
        values.stops[i].location &&
        values.stops[i].location.point_of_contacts &&
        values.stops[i].location.point_of_contacts.length > 0
      ) {
        errors.stops[i].location.point_of_contacts = [];
        for (var k = 0; k < values.stops[i].location.point_of_contacts.length; k++) {
          errors.stops[i].location.point_of_contacts[k] = {};
          if (!values.stops[i].location.point_of_contacts[k].first_name) {
            errors.stops[i].location.point_of_contacts[k].first_name = 'First name required';
          }
          if (!values.stops[i].location.point_of_contacts[k].email) {
            errors.stops[i].location.point_of_contacts[k].email = 'Email required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].email &&
            !validateEmail(values.stops[i].location.point_of_contacts[k].email)
          ) {
            errors.stops[i].location.point_of_contacts[k].email = 'Invalid email';
          }
          if (!values.stops[i].location.point_of_contacts[k].phone_number) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Phone number required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].phone_number &&
            !validatePhoneNumber(values.stops[i].location.point_of_contacts[k].phone_number)
          ) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Invalid phone number';
          }
        }
      } else if (!hasParcel) {
        errors.stops[i].location.point_of_contacts = {
          _error: 'At least one point of contact is required for each stop'
        };
      }

      if (customFields.length > 0) {
        set(
          errors.stops[i],
          `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}`,
          makeValidationErrors(values.stops[i], customFields, CustomFieldEntityTypesEnum.ShipmentStop, companyId)
        );
      }
    }
  }

  return errors;
};

export default validate;
