export const DispatchErrorModal = ({errorMessage}: {errorMessage: string}) => {
  return (
    <div className="modal-terms-error">
      <h3>
        <i className="icon icon-Delayed" /> Problem dispatching with carrier
      </h3>
      <p>
        If you want to proceed with this booking, please check the response from the carrier below. For same day
        bookings you may need to book one day ahead to attempt to remove the error.
      </p>
      <p>
        If you are still having trouble contact your customer support representative at support@shipwell.com or (512)
        333-0898.
      </p>

      <h4>Message from carrier:</h4>

      <div className="well">{errorMessage}</div>
    </div>
  );
};
