import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';

const mapParentValues = (primary = [], secondary = []) => {
  let parentValues = [];
  if (primary && primary.length) {
    if (Array.isArray(primary)) {
      primary = primary.map((address) => {
        return {formatted_address: address, validated: true};
      });
      parentValues = [...parentValues, ...primary];
    } else {
      parentValues = [{formatted_address: primary, validated: true}];
    }
  }

  if (secondary && secondary.length) {
    if (Array.isArray(secondary)) {
      secondary = secondary.map((address) => {
        return {
          formatted_address: address,
          validated: true,
          type: 'state_province',
          value: {formatted_address: address}
        };
      });
      parentValues = [...parentValues, ...secondary];
    } else {
      parentValues = [
        {
          formatted_address: secondary,
          validated: true,
          type: 'state_province',
          value: {formatted_address: secondary}
        }
      ];
    }
  }
  return parentValues;
};

/*
 * Filter Component for location search
 */
const LocationSearch = (props) => {
  const {
    label,
    name,
    secondaryName,
    onChange,
    parentValue = [],
    parentSecondaryValue = [],
    ignoreFieldWarnings
  } = props;
  const [value, setValue] = useState(mapParentValues(parentValue, parentSecondaryValue));
  /*
   * Update parent component with new values
   */
  const handleSelection = (values) => {
    setValue(values);
    if (onChange) {
      let newValue = {};
      const stateSelections = values.filter((address) => address.type && address.type === 'state_province');
      const addressSelections = values.filter((address) => !address.type);
      const mappedStates = stateSelections?.map((address) => address.value.formatted_address) || [];
      const mappedAddresses = addressSelections?.map((address) => address.formatted_address) || [];
      if (secondaryName) {
        newValue = {
          [name]: mappedAddresses,
          [secondaryName]: mappedStates
        };
      } else {
        newValue = {
          [name]: [...mappedAddresses, ...mappedStates]
        };
      }
      onChange(newValue);
    }
  };

  /*
   * Reset value when parent component sends signal
   */
  useEffect(() => {
    if (!parentValue?.length && !parentSecondaryValue?.length && value.length) {
      setValue([]);
    }
  }, [parentValue, parentSecondaryValue, value]);

  return (
    <div className="filter__locationSearch filter__option">
      <MultiAddressSearch
        {...props}
        value={value}
        searchAddressBook
        searchMaps
        searchStateProvince
        label={label}
        name={name}
        allowCreate
        onChange={handleSelection}
        useBaseComponent
        ignoreWarnings
        ignoreFieldWarnings={ignoreFieldWarnings}
      />
    </div>
  );
};

LocationSearch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default LocationSearch;
