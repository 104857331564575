import {useState} from 'react';
import {compose} from 'recompose';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {Field, useFormikContext} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {TIMELINE_EVENT_TEMPLATES} from 'App/data-hooks/queryKeys';
import WithStatusToasts from 'App/components/withStatusToasts';
import type {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getTimelineEventTemplates, deleteTimelineEventTemplate} from 'App/api/tracking/typed';

const TemplateDescription = ({setError}: WithStatusToastProps) => {
  const queryClient = useQueryClient();
  const {setFieldValue} = useFormikContext();
  const [templatesQueryOptions, setTemplatesQueryOptions] = useState({});
  const eventTemplatesQuery = useQuery([TIMELINE_EVENT_TEMPLATES, templatesQueryOptions], () =>
    getTimelineEventTemplates(templatesQueryOptions)
  );
  const eventTemplates = eventTemplatesQuery?.data?.data?.results?.map((eventTemplate) => {
    return {
      label: eventTemplate?.description,
      value: eventTemplate?.id
    };
  });

  const handleCustomDescription = (
    value: string,
    setFieldValue: (field: string, value: string | null | undefined) => void
  ) => {
    const targetTemplate = eventTemplates?.find((eventTemplate) => eventTemplate?.value === value);
    setFieldValue('description', targetTemplate?.label);
    setFieldValue('template_description', targetTemplate?.label);
  };

  const deleteTemplateMutation = useMutation((templateId: string) => deleteTimelineEventTemplate(templateId), {
    onSuccess: () => queryClient.invalidateQueries([TIMELINE_EVENT_TEMPLATES, templatesQueryOptions]),
    onError: (error: {error_description: string}) => {
      setError(
        'Error!',
        error?.error_description || 'There was an error while deleting the event template, please try again.'
      );
    }
  });

  const handleDeleteTemplate = (templateId: string) => {
    deleteTemplateMutation.mutate(templateId);
  };

  const formatTemplateOptions = (option: {label: string; value: string}, {context}: {context: string}) => {
    if (context === 'value') {
      return option?.label;
    }
    return (
      <div className="flex justify-between">
        <div>{option?.label}</div>
        <div
          className="text-sw-primary"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteTemplate(option?.value);
          }}
        >
          Remove
        </div>
      </div>
    );
  };

  return (
    <Field
      simpleValue
      label="Templated Timeline Description"
      name="template_description"
      component={FormikSelect}
      formatOptionLabel={formatTemplateOptions}
      options={eventTemplates}
      isLoading={eventTemplatesQuery?.isInitialLoading || deleteTemplateMutation?.isLoading}
      onInputChange={(val: string) => setTemplatesQueryOptions({search: val})}
      onChange={(val: string) => handleCustomDescription(val, setFieldValue)}
    />
  );
};

export default compose<WithStatusToastProps, Record<string, never>>(WithStatusToasts)(TemplateDescription);
