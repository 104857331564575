import {Card, FormikPhoneInput, FormikTextInput} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {object, string} from 'yup';
import {PermissionsCategory} from '../permissionsCategories';
import {UserPermissionsFields} from './components';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {validatePhoneNumber} from 'App/utils/globals';

export type UserWithPermissions = User & {permissions: string[]};

const companyUserValidationSchema = object().shape({
  first_name: string().required('First name is required.'),
  last_name: string().required('Last name is required.'),
  phone_number: string()
    .test('phone_number', 'A valid phone number is required.', (value) => (value ? validatePhoneNumber(value) : true))
    .required('A valid phone number is required.'),
  email: string().nullable().email('Enter a valid email.').required('Email is required.')
});

export const UserForm = ({
  user,
  permissionsCategories,
  onSubmit,
  isSubmitting
}: {
  user: User;
  permissionsCategories: PermissionsCategory[];
  onSubmit: (updatedUser: UserWithPermissions, formikHelpers: FormikHelpers<UserWithPermissions>) => void;
  isSubmitting: boolean;
}) => {
  // Sorting permissions keeps Formik's `dirty` flag accurate as permissions are removed and re-added to the array.
  const initialValues: UserWithPermissions = {...user, permissions: user.permissions?.sort() || []};
  return (
    <Formik
      key={user.updated_at} // mounts a fresh instance of the form when an updated user object is received
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={companyUserValidationSchema}
    >
      {({dirty, resetForm}) => {
        return (
          <Form noValidate className="mb-20 grid gap-4">
            <Card title="User Information" draggableProvided={false}>
              <div className="grid gap-4 md:grid-cols-2">
                <Field required name="first_name" label="First Name" component={FormikTextInput} />

                <Field required name="last_name" label="Last Name" component={FormikTextInput} />

                <Field required name="email" label="Email" component={FormikTextInput} />

                <Field required name="phone_number" label="Phone number" component={FormikPhoneInput} />
              </div>
            </Card>
            <Card title="User Permissions" draggableProvided={false}>
              <UserPermissionsFields permissionsCategories={permissionsCategories} />
            </Card>
            {dirty ? <FormFooter isSubmitting={isSubmitting} onCancel={() => resetForm()} /> : null}
          </Form>
        );
      }}
    </Formik>
  );
};
