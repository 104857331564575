import moment from 'moment';
import momentTimezone from 'moment-timezone';
import isNil from 'lodash/isNil';
/**
 * Test if date/time is valid
 * @param {String} date date/time to be validated
 *
 */
export const validateDateTime = (date) => {
  const dt = new Date(date);
  return !Number.isNaN(dt.valueOf()); // dt.valueOf will return Number.NaN if the date is invalid
};

/**
 * Test if date/time is after the current date/time
 * @param {String} dateTime date/time to be validated
 *
 */
export const validateDateTimeNotInPast = (date) => {
  if (validateDateTime(date)) {
    return moment(date).isSameOrAfter(moment());
  }
  return false;
};

/**
 * Test if date is after the current date
 * @param {String} date date to be validated
 *
 */
export const validateDateNotInPast = (date) => {
  if (validateDateTime(date)) {
    return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'));
  }
  return false;
};

/**
 * Test if date/time is before the current date/time
 * @param {String} dateTime date/time to be validated
 *
 */
export const validateDateTimeNotInFuture = (date) => {
  if (validateDateTime(date)) {
    return moment(date).isSameOrBefore(moment());
  }
  return false;
};

/**
 * Test if date is before the current date
 * @param {String} date date to be validated
 *
 */
export const validateDateNotInFuture = (date) => {
  if (validateDateTime(date)) {
    return moment(date).isSameOrBefore(moment().format('YYYY-MM-DD'));
  }
  return false;
};

/**
 * Test if date2 is after date1
 * @param {String} date date to be validated
 *
 */
export const validateDateSequence = (date1, date2) => {
  if (validateDateTime(date1) && validateDateTime(date2)) {
    return moment(date1).isSameOrBefore(moment(date2));
  }
  return false;
};

/**
 * Format a date time using the new patterning established by design
 * @param {String} dateTime date to be validated
 *
 */
export function formatDateTime(date, noSec = true, timezone = false) {
  const selectedDate = moment(date);
  if (selectedDate.isValid()) {
    if (timezone) {
      if (noSec) {
        const monthDayYear = momentTimezone.tz(selectedDate, timezone).format('ddd MMM D');
        const hoursAndMinutesWithTimezone = momentTimezone.tz(selectedDate, timezone).format('HH:mm zz');
        return `${monthDayYear} at ${hoursAndMinutesWithTimezone}`;
      }
      const monthDayYear = momentTimezone.tz(selectedDate, timezone).format('ddd MMM D');
      const hoursMinutesAndSecondsWithTimezone = momentTimezone.tz(selectedDate, timezone).format('HH:mm:ss zz');
      return `${monthDayYear} at ${hoursMinutesAndSecondsWithTimezone}`;
    }
    if (noSec) {
      const monthDayYear = momentTimezone.tz(selectedDate, timezone).format('ddd MMM D');
      const hoursAndMinutesWithoutTimezone = momentTimezone.tz(selectedDate, timezone).format('HH:mm');
      return `${monthDayYear} at ${hoursAndMinutesWithoutTimezone}`;
    }
    const monthDayYear = momentTimezone.tz(selectedDate, timezone).format('ddd MMM D');
    const hoursMinutesAndSecondsWithoutTimezone = momentTimezone.tz(selectedDate, timezone).format('HH:mm:ss');
    return `${monthDayYear} at ${hoursMinutesAndSecondsWithoutTimezone}`;
  }
}

/**
 * Format a date to MM/DD/YY
 * @param {String} date date to be validated
 *
 */
export function formatDateToMMDDYY(date) {
  return moment(date).format('MM/DD/YY');
}

/**
 * Forces date into UTC format
 * @param {*} date
 */
export const getUTCDate = (date, showTimeSelect, showTimeSelectOnly) => {
  const defaultTimeFormat = 'HH:mm:ss';
  const defaultDateFormat = 'YYYY-MM-DD';
  const defaultFormat = 'YYYY-MM-DDTHH:mm:ssz';
  const validFormats = [defaultTimeFormat, defaultDateFormat, defaultFormat];
  if (!date || !moment(date, validFormats).isValid()) {
    return null;
  }
  if (showTimeSelectOnly) {
    // time only
    return moment(date, defaultTimeFormat).toDate();
  }
  if (showTimeSelect) {
    // date time
    return moment(date, defaultFormat).toDate();
  }
  return moment(moment(date, validFormats), defaultFormat).toDate();
};

/**
 * Converts a date string to a native date object. Returns null if
 * date string is not in valid date time format
 * @param {String} dateString
 * @return {Date | null}
 */
export const getDateObjectFromDateString = (dateString) =>
  !isNil(dateString) && moment(dateString).isValid() ? moment(dateString).toDate() : null;
