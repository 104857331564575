import {useState} from 'react';
import {StopLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Select} from '@shipwell/shipwell-ui';
import {uniqBy} from 'lodash';
import {useGetShipmentStopLocations} from 'App/data-hooks';
import {useDebounce} from 'App/utils/hooks/useDebounce';

export const CompanyFilter = ({
  companies,
  onChange
}: {
  companies: string[];
  onChange: (newCompanies: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);
  const {getShipmentStopLocationsData, getShipmentStopLocationsIsFetching} = useGetShipmentStopLocations({
    params: {companyNameContains: debouncedSearchVal, pageSize: 100}
  });

  const options = uniqBy(
    getShipmentStopLocationsData?.filter((location) => Boolean(location.company_name)) || [],
    'company_name'
  );

  const defaultValues = companies.map((filter) => ({
    id: filter,
    company_name: filter
  }));

  return (
    <Select
      value={defaultValues}
      onInputChange={setSearchVal}
      onChange={(options: StopLocation[]) => {
        const selectedCarrierIds = options.map((option) => option.company_name || '');
        onChange(selectedCarrierIds);
      }}
      isLoading={getShipmentStopLocationsIsFetching}
      options={options}
      isMulti
      label="Search Companies"
      getOptionValue={(option: StopLocation) => option.id}
      getOptionLabel={(option: StopLocation) => option.company_name}
    />
  );
};
