import moment from 'moment-timezone';
import {defaultPOCFields} from '../../pointOfContactFields/utils/constants';

export const defaultStopFields = {
  location: {
    address: {},
    location_type: {id: 1, name: 'Business (with dock or forklift)'},
    location_name: '',
    point_of_contacts: [defaultPOCFields]
  },
  'schedule-select': 2,
  planned_date: moment(Date.now()).format('YYYY-MM-DD'),
  planned_time_window_start: moment('8:00:00', 'HH:mm:ss').format('HH:mm:ss'),
  planned_time_window_end: moment('18:00:00', 'HH:mm:ss').format('HH:mm:ss'),
  accessorials: []
};

export const defaultStopsFields = [
  {
    ...defaultStopFields,
    rKey: 1,
    is_pickup: true,
    is_dropoff: false,
    ordinal_index: 0,
    planning_window: {
      start: moment(Date.now()).hours(8).format('YYYY-MM-DD HH:mm'),
      end: moment(Date.now()).hours(18).format('YYYY-MM-DD HH:mm')
    }
  },
  {
    ...defaultStopFields,
    rKey: 2,
    is_pickup: false,
    is_dropoff: true,
    planned_date: moment(Date.now()).add(2, 'days').format('YYYY-MM-DD'),
    planning_window: {
      start: moment(Date.now()).add(2, 'days').hours(8).format('YYYY-MM-DD HH:mm'),
      end: moment(Date.now()).add(2, 'days').hours(18).format('YYYY-MM-DD HH:mm')
    },
    ordinal_index: 1
  }
];

export const defaultStopsFieldsLtlVltl = [
  {
    ...defaultStopFields,
    rKey: 1,
    is_pickup: true,
    is_dropoff: false,
    ordinal_index: 0,
    planning_window: {
      start: moment(Date.now()).hours(8).format('YYYY-MM-DD HH:mm'),
      end: moment(Date.now()).add(1, 'days').hours(18).format('YYYY-MM-DD HH:mm')
    }
  },
  {
    ...defaultStopFields,
    rKey: 2,
    is_pickup: false,
    is_dropoff: true,
    planned_date: '',
    ordinal_index: 1,
    planning_window: {
      start: moment(Date.now()).format('YYYY-MM-DD HH:mm'),
      end: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD HH:mm')
    }
  }
];
