import {Modal} from '@shipwell/shipwell-ui';
import {CreateProductLegItem, ProductLegItem, UpdateProductLegItem} from '@shipwell/corrogo-sdk';
import ShipmentLineItemsForm from 'App/formComponents/forms/ShipmentLineItemsForm';
import {
  transformLineItemsIntoLegItems,
  transformLegItemsIntoLineItems,
  LineItem,
  ModalState
} from 'App/containers/shipments/drayage/components/ProductLineItems/utils';

const LineItemsModal = ({
  showModal,
  onClose,
  onSubmit,
  legItems,
  modalState
}: {
  showModal: boolean;
  onClose: () => void;
  onSubmit: (values: CreateProductLegItem[] | UpdateProductLegItem[]) => void;
  legItems: ProductLegItem[];
  modalState: ModalState;
}) => {
  const handleSubmit = (values: {line_items: LineItem[]}) => {
    const lineItems = transformLineItemsIntoLegItems(values.line_items);
    onSubmit(lineItems);
    onClose();
  };

  return (
    <Modal title="Edit Line Items" show={showModal} onClose={onClose} size="large" footerComponent={null}>
      <ShipmentLineItemsForm
        lineItems={transformLegItemsIntoLineItems(legItems)}
        onSubmit={handleSubmit}
        modalState={modalState}
        onCancel={onClose}
        isWeightRequired
      />
    </Modal>
  );
};

export default LineItemsModal;
