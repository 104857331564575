import {useCallback, createContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import useHistoricalPricing from './useHistoricalPricing';
import {usePredictivePricing} from 'App/data-hooks';
import {
  PRICING_INTEL_STANDALONE_STOP_ADDRESS_KEY,
  PRICING_INTEL_STOP_ADDRESS_KEY,
  PRICING_INTEL_DATA_STATE_INVALID_LANE,
  PRICING_INTEL_DATA_STATE_EMPTY,
  PRICING_INTEL_DATA_STATE_DISABLED,
  DEFAULT_PICKUP_RADIUS,
  DEFAULT_DROPOFF_RADIUS,
  HISTORICAL_PRICING,
  pricingIntelVariantsBySource,
  DEFAULT_STOPS,
  PRICING_INTEL_RANGE_12_MONTHS,
  PRICING_INTEL_REQUIRED_ADDRESS_KEYS,
  PREDICTIVE_MARKET,
  PREDICTIVE_INTERNAL,
  allowedEquipmentTypes,
  PRICING_INTEL_SOURCE_STANDALONE
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {hasEmptyStops, getStringifiedStopsWithRequiredKeys} from 'App/components/pricingIntelChart/utils';
import usePrevious from 'App/utils/hooks/usePrevious';

/**
 * A hook for consuming predictive and historical pricing intelligence.
 * @param {string} pageSource
 * @param {array} equipmentType
 * @param {boolean} isShipwell
 * @param {number} pickupRadius
 * @param {number} dropOffRadius
 * @param {string} forecastRangeStartDate - defaults to today
 * @param {string} forecastRangeEndDate - default to 15 days from today
 * @param {array} stops
 * @param {array} pricingIntelDataOptions
 * @param {string} selectedFormRange
 */
const usePricingIntel = ({
  pageSource,
  equipmentType,
  isShipwell,
  pickupRadius,
  dropOffRadius,
  forecastRangeStartDate,
  forecastRangeEndDate,
  stops,
  pricingIntelDataOptions,
  selectedFormRange
}) => {
  const stopAddress = stops.map((stop) =>
    pageSource === PRICING_INTEL_SOURCE_STANDALONE
      ? get(stop, PRICING_INTEL_STANDALONE_STOP_ADDRESS_KEY, {})
      : get(stop, PRICING_INTEL_STOP_ADDRESS_KEY, {})
  );
  const prevProps = usePrevious({
    stopAddress,
    equipmentType,
    pickupRadius,
    dropOffRadius
  });
  const pricingIntelVariant = pricingIntelVariantsBySource[pageSource];
  const equipmentTypeMachineReadable = equipmentType[0]?.machine_readable;
  const stringifiedStops = getStringifiedStopsWithRequiredKeys(stops, PRICING_INTEL_REQUIRED_ADDRESS_KEYS);
  const {
    historicalPricingDataState,
    rawHistoricalPrices,
    aggregateData,
    totalMiles,
    getTruckloadHistoricalPricingEstimate,
    availableDataSources,
    setAvailableDataSources,
    selectedDataSources,
    setSelectedDataSources
  } = useHistoricalPricing({
    equipmentTypeMachineReadable,
    stringifiedStops,
    pickupRadius,
    dropOffRadius,
    selectedFormRange,
    isShipwell,
    pageSource
  });

  const {
    predictivePrices,
    setPredictivePrices,
    predictivePricingDataState,
    setPredictivePricingDataState,
    getPredictivePricingEstimates
  } = usePredictivePricing({
    equipmentType,
    stops,
    pricingIntelVariant,
    pricingIntelDataOptions,
    pageSource,
    forecastRangeStartDate,
    forecastRangeEndDate
  });

  //used when we want to update all data states in the case of missing form inputs
  const setAllDataStates = useCallback(
    (dataState) => {
      setPredictivePricingDataState(dataState);
    },
    [setPredictivePricingDataState]
  );

  const getPricingData = useCallback(() => {
    if (
      equipmentType &&
      equipmentType[0] &&
      allowedEquipmentTypes.some((type) => type.id === equipmentType[0].id) &&
      stringifiedStops.length === stops.length
    ) {
      // If stop address or equipment type changes, refresh all requested data sources
      if (
        !prevProps ||
        !isEqual(prevProps.stopAddress, stopAddress) ||
        !isEqual(prevProps.equipmentType[0], equipmentType[0]) ||
        historicalPricingDataState === PRICING_INTEL_DATA_STATE_DISABLED ||
        predictivePricingDataState === PRICING_INTEL_DATA_STATE_DISABLED
      ) {
        if (pricingIntelDataOptions.includes(HISTORICAL_PRICING)) {
          getTruckloadHistoricalPricingEstimate();
        }
        if (
          pricingIntelDataOptions.includes(PREDICTIVE_MARKET) ||
          pricingIntelDataOptions.includes(PREDICTIVE_INTERNAL)
        ) {
          getPredictivePricingEstimates();
        }
      }
    }
    // If all the stops are filled out but have some bad data, surface error:
    else if (!hasEmptyStops(stops) && stringifiedStops.length < stops.length) {
      setAllDataStates(PRICING_INTEL_DATA_STATE_INVALID_LANE);
      if (!isEmpty(predictivePrices)) {
        setPredictivePrices({});
      }
    }
    // If we get here, we must have some empty stop inputs
    else {
      setAllDataStates(PRICING_INTEL_DATA_STATE_EMPTY);
      if (!isEmpty(predictivePrices)) {
        setPredictivePrices({});
      }
    }
  }, [
    equipmentType,
    stringifiedStops,
    stops,
    pricingIntelDataOptions,
    prevProps,
    stopAddress,
    historicalPricingDataState,
    predictivePricingDataState,
    predictivePrices,
    getTruckloadHistoricalPricingEstimate,
    getPredictivePricingEstimates,
    setAllDataStates,
    setPredictivePrices
  ]);

  // Updates date state when pricing intel enabled
  useEffect(() => {
    if (
      historicalPricingDataState !== PRICING_INTEL_DATA_STATE_DISABLED &&
      predictivePricingDataState !== PRICING_INTEL_DATA_STATE_DISABLED
    ) {
      getPricingData();
    }
  }, [equipmentType, getPricingData, historicalPricingDataState, predictivePricingDataState]);

  return {
    rawHistoricalPrices,
    aggregateData,
    totalMiles,
    availableDataSources,
    setAvailableDataSources,
    selectedDataSources,
    setSelectedDataSources,
    predictivePrices,
    historicalPricingDataState,
    predictivePricingDataState,
    setPredictivePricingDataState,
    getPricingData
  };
};
// TODO: context should be moved to UI layer i.e. container or components
const PricingIntelContext = createContext({});
const PricingIntelContextProvider = ({
  pageSource,
  equipmentType,
  isShipwell,
  pickupRadius,
  dropOffRadius,
  forecastRangeStartDate,
  forecastRangeEndDate,
  stops,
  pricingIntelDataOptions,
  selectedFormRange,
  ...props
}) => {
  const pricingIntelContext = usePricingIntel({
    pageSource,
    equipmentType,
    isShipwell,
    pickupRadius,
    dropOffRadius,
    forecastRangeStartDate,
    forecastRangeEndDate,
    stops,
    pricingIntelDataOptions,
    selectedFormRange
  });
  return <PricingIntelContext.Provider value={pricingIntelContext} {...props} />;
};

const PricingIntelContextConsumer = PricingIntelContext.Consumer;

PricingIntelContextProvider.propTypes = {
  pageSource: PropTypes.string.isRequired,
  equipmentType: PropTypes.array,
  isShipwell: PropTypes.bool.isRequired,
  pickupRadius: PropTypes.number,
  dropOffRadius: PropTypes.number,
  forecastRangeStartDate: PropTypes.string,
  forecastRangeEndDate: PropTypes.string,
  stops: PropTypes.array,
  pricingIntelDataOptions: PropTypes.array.isRequired,
  selectedFormRange: PropTypes.string.isRequired
};

PricingIntelContextProvider.defaultProps = {
  pageSource: '',
  equipmentType: [],
  isShipwell: false,
  pickupRadius: DEFAULT_PICKUP_RADIUS,
  dropOffRadius: DEFAULT_DROPOFF_RADIUS,
  stops: DEFAULT_STOPS,
  pricingIntelDataOptions: [],
  selectedFormRange: PRICING_INTEL_RANGE_12_MONTHS
};

export {usePricingIntel, PricingIntelContext, PricingIntelContextProvider, PricingIntelContextConsumer};
