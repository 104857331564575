import {IntegrationCard} from '../../integrationCard/integrationCard';
import {useMacropointService} from './useMacropointService/useMacropointService';

export interface MacropointCardProps {
  onConnect: () => void;
}

export function MacropointCard(props: MacropointCardProps) {
  const service = useMacropointService();

  return (
    <IntegrationCard
      isConnected={service.isConnected}
      isLoading={service.isLoading}
      image={<img src="/images/macropoint_logo.png" alt="Descartes Macropoint Logo" />}
      name="Descartes Macropoint"
      onConnectClick={props.onConnect}
      onCardClick={props.onConnect}
    />
  );
}
