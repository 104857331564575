import {
  Template,
  TemplateBuilderApiUpdateTemplateRequest,
  ShipwellApiErrorResponse
} from '@shipwell/template-builder-sdk';
import {useMutation} from '@tanstack/react-query';
import {updateTemplate as updateTemplateApi} from 'App/api/templates/typed';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

export const useUpdateTemplate = () => {
  const update = async (params: TemplateBuilderApiUpdateTemplateRequest): Promise<Template> => {
    const response = await updateTemplateApi(omitEmptyKeysWithEmptyObjectsRemoved(params));
    return response.data;
  };

  const {mutateAsync} = useMutation<
    Awaited<ReturnType<typeof update>>,
    ShipwellApiErrorResponse,
    TemplateBuilderApiUpdateTemplateRequest
  >(async (params: TemplateBuilderApiUpdateTemplateRequest) => await update(params));

  return {
    updateTemplate: mutateAsync
  };
};
