import {ColumnOrderState} from '@tanstack/react-table';
import {isEqual} from 'lodash';
import {useMemo, useState} from 'react';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';

export function useTableColumns<T extends {id?: string}>(
  defaultColumns: T[],
  tableType: string,
  initialColumnVisibility?: Record<string, boolean>
) {
  //some of the table type keys are in snake case, and rather than changing those or updating
  //the format here, we will just transform them to use en dashes here.
  const standardizedTableTypeStringFormat = tableType.toLowerCase().replace(/_/g, '-');

  const [columns, setColumns] = useState(defaultColumns);
  const [columnOrder, setColumnOrder] = useLocalStorage<ColumnOrderState>({
    key: `${standardizedTableTypeStringFormat.toLowerCase()}-column-order`,
    initialValue: columns.map((column) => column.id || '')
  });
  const [columnVisibility, setColumnVisibility] = useLocalStorage({
    key: `${standardizedTableTypeStringFormat.toLowerCase()}-column-visibility`,
    initialValue: initialColumnVisibility || {}
  });
  //this memoization prevents the table from re-rendering every time the columns prop changes.
  //We only want to trigger a re-render when the columns prop changes length, like when new columns
  //are passed to the table.
  useMemo(() => {
    if (!isEqual(defaultColumns.length, columns.length)) setColumns(defaultColumns);
  }, [columns, defaultColumns]);
  return {
    columns,
    columnOrder,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility
  };
}
