import {JSX, useState} from 'react';
import classNames from 'classnames';
import {AppointmentAvailabilityWindow} from 'App/data-hooks/appointments/types';

export type AppointmentAvailabilityListProps = {
  windows?: AppointmentAvailabilityWindow[];
  onItemClick: (window: AppointmentAvailabilityWindow | null) => void;
};
/**
 * Subtracts the difference between the start date of the first parameter and the second parameter.
 * Dates should be ordered in ascending order meaning 2022-02-02T09:32:23.000000Z should be before
 * 2022-02-02T10:22:22.000000Z.
 */
const dateSort = (a: AppointmentAvailabilityWindow, b: AppointmentAvailabilityWindow): number => {
  return a.startDate.original.valueOf() - b.startDate.original.valueOf();
};
/**
 * Gets a component which displays all available times that can be chosen to make
 * an appointment for a shipment at a dock, or at a dock level.
 */
const AppointmentAvailabilityList = ({windows, onItemClick}: AppointmentAvailabilityListProps): JSX.Element | null => {
  const tailwindClasses = [
    'rounded-md',
    'border-1',
    'border-sw-border',
    'p-1',
    'text-center',
    'text-xs',
    'uppercase',
    'cursor-pointer'
  ];
  const [selectedWindow, setSelectedWindow] = useState<AppointmentAvailabilityWindow | null>(null);

  /**
   * Handles when a time slot is clicked for the availabilities. Sets the formik context
   * value 'time' and updates the avilability which is selected on the form.
   */
  const handleItemClick = (window: AppointmentAvailabilityWindow) => {
    if (window === selectedWindow) {
      setSelectedWindow(null);
      onItemClick(null);
      return;
    }
    setSelectedWindow(window);
    onItemClick(window);
  };

  return (
    <div className="flex flex-row gap-x-2" role="list">
      <div className="flex grow flex-col gap-y-2" role="listitem" aria-describedby="morning-list-label">
        <p id="morning-list-label" className="m-0 text-center text-xxs uppercase text-sw-label">
          Morning
        </p>
        <div className="flex flex-col gap-y-2 text-center" aria-labelledby="morning-list-label" role="list">
          {windows
            ?.filter(({startDate}) => startDate.time.match(/am/i))
            ?.sort(dateSort)
            ?.map((window) => (
              <div
                role="listitem"
                title={`${window.startDate.time} ${window.startDate.timezone.abbreviated} to ${window.endDate.time} ${window.endDate.timezone.abbreviated}`}
                key={`${window.dockId}-${window.startDate.original.toISOString()}`}
                className={classNames(
                  tailwindClasses,
                  {'bg-sw-active-light': window === selectedWindow},
                  {'hover:bg-sw-active-light': window !== selectedWindow}
                )}
                onClick={() => handleItemClick(window)}
              >
                {window.startDate.time} {window.startDate.timezone.abbreviated}
              </div>
            ))}
        </div>
      </div>
      <div className="flex grow flex-col gap-y-2" role="listitem" aria-describedby="evening-list-label">
        <p id="evening-list-label" className="m-0 text-center text-xxs uppercase text-sw-label">
          Afternoon
        </p>
        <div className="flex flex-col gap-y-2 text-center" aria-labelledby="evening-list-label" role="list">
          {windows
            ?.filter((apptWindow) => apptWindow.startDate.time.match(/pm/i))
            ?.sort(dateSort)
            ?.map((window) => (
              <div
                role="listitem"
                title={`${window.startDate.time} ${window.startDate.timezone.abbreviated} to ${window.endDate.time} ${window.endDate.timezone.abbreviated}`}
                key={`${window.dockId}-${window.startDate.original.toISOString()}`}
                className={classNames(
                  tailwindClasses,
                  {'bg-sw-active-light': window === selectedWindow},
                  {'hover:bg-sw-active-light': window !== selectedWindow}
                )}
                onClick={() => handleItemClick(window)}
              >
                {window.startDate.time} {window.startDate.timezone.abbreviated}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AppointmentAvailabilityList;
