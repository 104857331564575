import {
  CompaniesApi,
  CompaniesApiCompaniesCompanyIdCustomFieldsCustomFieldIdDeleteRequest,
  CompaniesApiCompaniesCompanyIdCustomFieldsCustomFieldIdPutRequest,
  CompaniesApiCompaniesCompanyIdCustomFieldsGetRequest,
  CompaniesApiCompaniesCompanyIdCustomFieldsPostRequest,
  CompaniesApiCompaniesCompanyIdDriversGetRequest,
  CompaniesApiCompaniesCompanyIdPreferencesPutRequest,
  CompaniesApiCompanyParcelConfigPutRequest,
  Company,
  Configuration,
  CompanyPreferences,
  CompaniesApiCompaniesCompanyIdDocumentsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from '../config';
import {getAccessToken} from '../utils';

const companyApi = new CompaniesApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const getCompanyLogo = (subdomain: string) => companyApi.companiesLogosGet({subdomain});

export const getCompanyPreferences = (companyId: string) => companyApi.companiesCompanyIdPreferencesGet({companyId});
export const updateCompanyPreferences = async (
  requestParameters: CompaniesApiCompaniesCompanyIdPreferencesPutRequest
): Promise<CompanyPreferences> => {
  const {data} = await companyApi.companiesCompanyIdPreferencesPut(requestParameters);
  return data;
};

export const getCompanyDrivers = (requestParameters: CompaniesApiCompaniesCompanyIdDriversGetRequest) =>
  companyApi.companiesCompanyIdDriversGet(requestParameters);

export const getCompanyDetails = async (companyId: string): Promise<Company> => {
  const axiosResponse = await companyApi.companiesCompanyIdGet({companyId});
  return axiosResponse.data;
};

export const getCompanyCustomFields = (requestParameters: CompaniesApiCompaniesCompanyIdCustomFieldsGetRequest) =>
  companyApi.companiesCompanyIdCustomFieldsGet(requestParameters);

export const updateCompanyCustomField = async (
  requestParameters: CompaniesApiCompaniesCompanyIdCustomFieldsCustomFieldIdPutRequest
) => {
  const axiosResponse = await companyApi.companiesCompanyIdCustomFieldsCustomFieldIdPut(requestParameters);
  return axiosResponse.data;
};

export const createCompanyCustomField = async (
  requestParameters: CompaniesApiCompaniesCompanyIdCustomFieldsPostRequest
) => {
  const axiosResponse = await companyApi.companiesCompanyIdCustomFieldsPost(requestParameters);
  return axiosResponse.data;
};

export const deleteCompanyCustomField = async (
  requestParameters: CompaniesApiCompaniesCompanyIdCustomFieldsCustomFieldIdDeleteRequest
) => {
  const axiosResponse = await companyApi.companiesCompanyIdCustomFieldsCustomFieldIdDelete(requestParameters);
  return axiosResponse.data;
};

export const getCompanyParcelConfig = () => companyApi.companyParcelConfigGet();

export const putCompanyParcelConfig = (requestParameters: CompaniesApiCompanyParcelConfigPutRequest) =>
  companyApi.companyParcelConfigPut(requestParameters);

export const getCompanyDocuments = async (requestParameters: CompaniesApiCompaniesCompanyIdDocumentsGetRequest) => {
  const axiosResponse = await companyApi.companiesCompanyIdDocumentsGet(requestParameters);
  return axiosResponse.data;
};
