import {registration} from 'App/api';

/**
 * Create USPS Account
 */
export function createUSPSRegistration(accountNumber, body = {}) {
  return (dispatch) => registration.createUSPSRegistration(accountNumber, body);
}

/**
 * Get USPS Accounts
 * @param {Object} opts
 */
export function getUSPSRegistrationAccounts(opts = {}) {
  return (dispatch) => registration.getUSPSRegistrationAccounts(opts);
}
