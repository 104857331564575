import {
  ServiceLevelAgreementsApi,
  ServiceLevelAgreementsApiServiceLevelAgreementsGetRequest,
  ServiceLevelAgreement,
  PaginatedServiceLevelAgreement
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {configuration} from 'App/api/carriers/configuration';

const serviceLevelAgreementsApi = new ServiceLevelAgreementsApi(configuration);

export type CorrectedServiceLevelAgreement = Omit<ServiceLevelAgreement, 'id'> & {id?: string};
export type CorrectedPaginatedServiceLevelAgreement = Omit<PaginatedServiceLevelAgreement, 'results'> & {
  results?: CorrectedServiceLevelAgreement[];
};
export const getSLADetails = async (serviceLevelAgreementId: string) => {
  const axiosResponse = await serviceLevelAgreementsApi.serviceLevelAgreementsServiceLevelAgreementIdGet({
    serviceLevelAgreementId
  });
  return axiosResponse.data as CorrectedServiceLevelAgreement;
};

export const getSLAs = async (requestParameters?: ServiceLevelAgreementsApiServiceLevelAgreementsGetRequest) => {
  const axiosResponse = await serviceLevelAgreementsApi.serviceLevelAgreementsGet(requestParameters);
  return axiosResponse.data as CorrectedPaginatedServiceLevelAgreement;
};
