import {compose} from 'recompose';
import {Shipment} from '@shipwell/backend-core-sdk';
import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {useMutation, useQuery} from '@tanstack/react-query';
import useToggle from 'App/utils/hooks/useToggle';
import {FlexBox} from 'App/components/Box';
import {HouseBolForm} from 'App/containers/Shipment/components/Actions/generateHouseBol';
import Loader from 'App/common/shipwellLoader';
import {generateDrayageHouseBol, deleteShipmentDocument, getCarrierDocument} from 'App/api/shipment/typed';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useDocumentTypes} from 'App/data-hooks';
import SendShipmentDocuments from 'App/formComponents/forms/sendShipmentDocuments';
import {EMAIL_CONTACTS} from 'App/formComponents/forms/sendShipmentDocuments/constants';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

export const HouseBol = ({
  shipment,
  setSuccess,
  setError
}: {
  shipment: Shipment;
  setSuccess: WithStatusToastProps['setSuccess'];
  setError: WithStatusToastProps['setError'];
}) => {
  const [showModal, toggleModal] = useToggle();
  const [showForm, toggleForm] = useToggle();
  const [showEmailModal, toggleEmailModal] = useToggle();

  const shipmentId = shipment.id;
  const deleteShipmentDocumentMutation = useMutation(deleteShipmentDocument);
  const generateDrayageHouseBolMutation = useMutation(generateDrayageHouseBol);
  const documentTypesQuery = useDocumentTypes();

  const generatedDocumentId = generateDrayageHouseBolMutation.data?.data.find(
    (document) => document.shipment === shipmentId
  )?.id;

  // after generating the doc, we fetch it so that if a user updates the doc, we can refetch without creating a new doc
  const documentQuery = useQuery(
    [SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId, generatedDocumentId],
    async () => {
      const response = await getCarrierDocument(shipmentId, generatedDocumentId || '');
      return response.data;
    },
    {
      enabled: Boolean(generatedDocumentId),
      onError: () => {
        toggleModal();
        setError('Error', 'There was an error finding your document');
      }
    }
  );
  const document = documentQuery.data;
  const docType = documentTypesQuery.data?.find((doc) => doc.id === document?.type)?.name || 'House Bill of Lading';

  const handleDeleteDocument = () => {
    if (!generatedDocumentId) {
      return;
    }
    deleteShipmentDocumentMutation.mutate(
      {
        shipmentId,
        documentId: generatedDocumentId
      },
      {
        onSettled: () => toggleModal(),
        onSuccess: () => {
          setSuccess('Success!', 'This document was successfully deleted.');
        }
      }
    );
  };

  const handleGenerate = () => {
    toggleModal();
    generateDrayageHouseBolMutation.mutate(shipmentId, {
      onError: () => {
        toggleModal();
        setError('Error!', 'There was an error generating your House BOL');
      }
    });
  };
  return (
    <>
      <DeprecatedButton variant="text" onClick={handleGenerate}>
        Generate House BOL
      </DeprecatedButton>
      <Modal
        className="h-full"
        size="large"
        show={showModal}
        onClose={toggleModal}
        title="House BOL - House Bill of Lading"
        footerComponent={() =>
          documentQuery.status === 'success' ? (
            <FlexBox items="center" justify="between" pad="m">
              <DeprecatedButton variant="warning" onClick={handleDeleteDocument}>
                Delete Document
              </DeprecatedButton>
              <FlexBox gap="m">
                <DeprecatedButton
                  onClick={() => {
                    toggleEmailModal();
                    toggleModal();
                  }}
                >
                  Email
                </DeprecatedButton>
                <DeprecatedButton onClick={toggleModal}>Close</DeprecatedButton>
              </FlexBox>
            </FlexBox>
          ) : null
        }
      >
        {documentQuery.status === 'loading' ? (
          <Loader loading />
        ) : (
          <div className="grid h-full grid-cols-1 gap-4 lg:grid-cols-4">
            <FlexBox direction="col" gap="m" items="start">
              <FlexBox direction="col">
                <span className="text-xs uppercase text-sw-text-section-title">Document type</span>
                <span className="flex gap-2">
                  {docType} {documentQuery.isFetching ? <SvgIcon name="LoadingDots" /> : null}
                </span>
              </FlexBox>
              <FlexBox direction="col">
                <span className="text-xs uppercase text-sw-text-section-title">Description</span>
                <span className="flex gap-2">
                  {document?.description || '--'} {documentQuery.isFetching ? <SvgIcon name="LoadingDots" /> : null}
                </span>
              </FlexBox>
              <FlexBox direction="col">
                <span className="text-xs uppercase text-sw-text-section-title">Created</span>
                <span>{document?.created_at ? formatDateTime(document.created_at) : '--'}</span>
              </FlexBox>
              <FlexBox direction="col">
                <span className="text-xs uppercase text-sw-text-section-title">Updated</span>
                <span>{document?.updated_at ? formatDateTime(document.updated_at) : '--'}</span>
              </FlexBox>
              {showForm ? (
                <HouseBolForm
                  shipmentId={shipmentId}
                  documentId={document?.id || ''}
                  onClose={toggleForm}
                  document={document}
                />
              ) : (
                <DeprecatedButton variant="tertiary" onClick={toggleForm}>
                  Edit info
                </DeprecatedButton>
              )}
            </FlexBox>
            <object
              className="border border-sw-border lg:col-span-3"
              type="application/pdf"
              data={document?.file || ''}
              width="100%"
              height="100%"
            />
          </div>
        )}
      </Modal>
      <Modal show={showEmailModal} title="Shipment Documents" onClose={toggleEmailModal}>
        <SendShipmentDocuments
          mode={EMAIL_CONTACTS}
          shipmentId={shipment.id}
          initialSelectedDocuments={[document?.id]}
          onSuccess={(title: string, message: string) => setSuccess(title, message)}
          onCancel={toggleEmailModal}
        />
      </Modal>
    </>
  );
};

export const GenerateHouseBol = compose<
  {shipment: Shipment; setSuccess: WithStatusToastProps['setSuccess']; setError: WithStatusToastProps['setError']},
  {shipment: Shipment}
>(WithStatusToasts)(HouseBol);
