import {useState} from 'react';
import isNil from 'lodash/isNil';
import {Formik, Form, Field} from 'formik';
import PropTypes from 'prop-types';
import {object, array, string} from 'yup';
import useCopyClipboard from 'react-use-clipboard';
import {DeprecatedButton, FormikSelect, FormikPhoneInput, FormikCheckbox, SvgIcon} from '@shipwell/shipwell-ui';
import {validateEmail, validatePhoneNumber} from 'App/utils/globals';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';

const defaultFormValues = {
  external_tracking_link: '',
  emails: [],
  include_map: true
};

export const validationSchema = object().shape(
  {
    emails: array().when('recipient_phone_number', {
      is: (value) => isNil(value),
      then: array().required('At least one email is required.'),
      otherwise: array().nullable()
    }),
    recipient_phone_number: string().when('emails', {
      is: (emails) => isNil(emails) || !emails.length,
      then: string()
        .required('A phone number is required.ß')
        .test('recipient_phone_number', 'Invalid phone number', validatePhoneNumber),
      otherwise: string().optional()
    })
  },
  [['emails', 'recipient_phone_number']]
);

const ShareLinkForm = ({values, onSubmit, onCancel}) => {
  const {include_map, external_tracking_link: externalTrackingLink} = {
    include_map: true,
    external_tracking_link: '',
    ...(values || {})
  };
  // the local state update is to force useCopyClipboard to update when the user checks or unchecks the include map box
  const [includeMap, setIncludeMap] = useState(include_map);
  const [copied, setCopied] = useCopyClipboard(`${externalTrackingLink}&include_map=${includeMap}`, {
    successDuration: 3000
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleIncludeMapClick = (e, setFieldValue) => {
    setFieldValue('include_map', e.currentTarget.checked);
    setIncludeMap(e.currentTarget.checked); // update for local state
  };

  const normalizeResults = (results) => {
    const emailSet = new Set();
    const emails = [];

    results.forEach((result) => {
      result.point_of_contacts.forEach(({email}) => {
        if (!emailSet.has(email)) {
          emailSet.add(email);
          emails.push({label: email, value: email});
        }
      });
    });
    return emails;
  };

  const searchAddressBook = async (input) => {
    setIsLoading(true);
    try {
      const response = await getAddressBookAddresses({pageSize: 10, q: input});
      if (response.data?.results?.length > 0) {
        return normalizeResults(response.data.results);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const formatEmailOptions = (option) => {
    return <span>{option.label}</span>;
  };

  const handleSubmit = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, dirty, edit, isValid, setFieldValue}) => (
        <Form className="shareLinkForm">
          <div className="field-grid grid-1-1">
            <Field
              label="Email Link"
              name="emails"
              component={FormikSelect}
              async
              loadOptions={searchAddressBook}
              isLoading={isLoading}
              formatOptionLabel={formatEmailOptions}
              isMulti
              allowCreate
              formatCreateLabel={(input) => {
                return 'Send to ' + input;
              }}
              isValidNewOption={(val) => {
                return validateEmail(val);
              }}
            />
          </div>
          <div className="field-grid grid-1-1">
            <Field label="Phone Number" name="recipient_phone_number" component={FormikPhoneInput} />
          </div>
          <div className="field-grid grid-1-1">
            <Field
              label="Show map in tracking link"
              name="include_map"
              component={FormikCheckbox}
              onClick={(e) => handleIncludeMapClick(e, setFieldValue)}
            />
          </div>
          <div className="page-footer">
            <div className="flex flex-row items-center">
              {copied ? (
                <>
                  <SvgIcon className="text-sw-success" name="Check" />
                  <span className="text-sw-success">Copied</span>
                </>
              ) : (
                <p className="text-sw-primary" onClick={() => setCopied()}>
                  Copy Shareable Tracking Link
                </p>
              )}
            </div>
            {!edit || dirty ? (
              <>
                <DeprecatedButton variant="secondary" onClick={handleCancel}>
                  Cancel
                </DeprecatedButton>
                <DeprecatedButton
                  disabled={isSubmitting || !isValid || !dirty}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                  type="submit"
                >
                  Share
                </DeprecatedButton>
              </>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

ShareLinkForm.defaultProps = {
  edit: false,
  onCancel: () => {},
  onSubmit: () => {}
};

ShareLinkForm.propTypes = {
  values: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ShareLinkForm;
