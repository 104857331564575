import {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import './_cases.scss';
import {bindActionCreators} from 'redux';
import CasesForm from './CasesForm';
import Case from './Case';
import * as actions from 'App/actions/users';

export class Cases extends Component {
  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.update = this.update.bind(this);
    this.toggleExpandCases = this.toggleExpandCases.bind(this);
    this.fetchCases = this.fetchCases.bind(this);
    this.deleteCase = this.deleteCase.bind(this);
    this.editCase = this.editCase.bind(this);
    this.state = {
      showTasksForm: false,
      collapsedNotes: [],
      collapsedTasks: [],
      collapsedCases: [],
      hasSetInitialExpand: false
    };
  }

  componentDidMount() {
    const {shipmentId} = this.props;
    if (shipmentId) {
      //get all the cases part of this shipment
      this.fetchCases();
    }

    if (
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('users.view_company_users')
    ) {
      this.props.getCompanyUsers(this.props.company.id, {
        page: 1,
        pageSize: 1000,
        ordering: 'freight_authority__company__name'
      });
    }
  }

  fetchCases() {
    const {shipmentId} = this.props;
    this.props.getCasesByShipmentId(shipmentId).then((response) => {
      if (response.status === 200 && response.details && response.details.results.length > 0) {
        const casesToCollapse = [];
        //proceed to get the individual tasks for each case
        response.details.results.forEach((oneCase) => {
          //if more than 1 case returned, collapse them all
          if (response.details.results.length > 1) {
            casesToCollapse.push(oneCase.id);
          }
          this.props.getTasksByCaseId(oneCase.id, {}).then(() => {
            this.forceUpdate();
          });
        });
        if (!this.state.hasSetInitialExpand) {
          this.setState({collapsedCases: casesToCollapse, hasSetInitialExpand: true});
        }
      }
    });
  }

  update() {
    this.fetchCases();
  }

  handleSubmit(attrs) {
    const newCase = JSON.parse(JSON.stringify(attrs));
    if (newCase.assignee) {
      newCase.assignee = this.props.companyUsers.results.filter((e) => e.id === newCase.assignee)[0];
    }

    if (newCase.id) {
      //do a put
      const {shipmentId} = this.props;
      return this.props.editCase(shipmentId, newCase.id, newCase).then((response) => {
        if (response.status === 200) {
          this.fetchCases();
          this.props.clearSelectedCase();
          this.setState({showCasesForm: false, editingCase: null});
        }
      });
    }
    this.props.createCaseForShipment(this.props.shipmentId, newCase).then((response) => {
      this.fetchCases();
      this.props.clearSelectedCase();
      this.setState({showCasesForm: false});
    });
  }

  toggleExpandNotes(id) {
    const current = JSON.parse(JSON.stringify(this.state.collapsedNotes));
    if (current.indexOf(id) >= 0) {
      current.splice(current.indexOf(id), 1);
    } else {
      current.push(id);
    }

    this.setState({collapsedNotes: current});
  }

  toggleExpandTasks(id) {
    const current = JSON.parse(JSON.stringify(this.state.collapsedTasks));
    if (current.indexOf(id) >= 0) {
      current.splice(current.indexOf(id), 1);
    } else {
      current.push(id);
    }

    this.setState({collapsedTasks: current});
  }

  toggleExpandCases(id) {
    const current = JSON.parse(JSON.stringify(this.state.collapsedCases));
    if (current.indexOf(id) >= 0) {
      current.splice(current.indexOf(id), 1);
    } else {
      current.push(id);
    }

    this.setState({collapsedCases: current}, () => {
      //scroll to bottom of notes
      const notesDiv = document.getElementsByClassName('notes__dummy');
      if (notesDiv.length) {
        for (let i = 0; i < notesDiv.length; i++) {
          notesDiv[i].scrollIntoView(false);
        }
      }
    });
  }

  editCase(oneCase) {
    if (this.state.collapsedCases.includes(oneCase.id)) {
      this.toggleExpandCases(oneCase.id);
    }
    this.setState({showCasesForm: false, editingCase: null}, () => {
      this.props.selectCase(oneCase);
      this.setState({editingCase: oneCase.id});
    });
  }

  deleteCase(oneCase) {
    const {shipmentId} = this.props;
    return this.props.deleteCase(shipmentId, oneCase.id).then((response) => {
      if (response.status === 200) {
        this.update();
      }
    });
  }

  render() {
    const {shipmentIdentifier, vendor, customer, user, cases, companyUsers} = this.props;

    return (
      <div className="cases">
        {cases && cases.results && cases.results.length > 0 ? (
          cases.results.map((oneCase) => {
            return (
              <Case
                key={oneCase.id}
                oneCase={oneCase}
                expanded={!this.state.collapsedCases.includes(oneCase.id)}
                updateCase={() => this.editCase(oneCase)}
                removeCase={() => this.deleteCase(oneCase)}
                toggleExpandCases={() => this.toggleExpandCases(oneCase.id)}
                user={user}
                editingCase={this.state.editingCase === oneCase.id}
                companyUsers={companyUsers}
                onSubmit={this.handleSubmit}
                cancel={() => {
                  this.setState({showCasesForm: false, editingCase: null});
                }}
                fetchCases={this.fetchCases}
                one={this.props.one}
              />
            );
          })
        ) : (
          <h4 className="text-center"> No cases for this shipment yet.</h4>
        )}
        <span className="cases__add">
          <Button
            bsStyle="link"
            onClick={() => {
              this.setState({editingCase: null}, () => {
                let titleString = '';
                if (customer) {
                  titleString += customer + ' / ';
                }
                if (shipmentIdentifier) {
                  titleString += shipmentIdentifier + (vendor ? ' / ' : '');
                }
                if (vendor) {
                  titleString += vendor;
                }
                this.props.clearSelectedCase(this.props.user.id, titleString);
                this.setState({showCasesForm: true});
              });
            }}
          >
            <i className="flaticon-plus" />
            &nbsp;New Case
          </Button>
        </span>
        {this.state.showCasesForm && (
          <CasesForm
            companyUsers={this.props.companyUsers}
            onSubmit={this.handleSubmit}
            cancel={() => {
              this.setState({showCasesForm: false, selectedTask: null, tasksTitle: 'Tasks'});
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.users.tasks,
    company: state.auth.company,
    user: state.auth.user,
    companyUsers: state.users.companyUsers,
    cases: state.users.cases,
    loadingCases: state.users.loadingCases,
    one: state.shipmentdetails.one
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
