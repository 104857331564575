import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Shipwell-UI vendor-relationships API
 * Carriers should be able to query their list of customers
 */
export async function fetchVendorRelationshipsPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiVendorRelationshipsGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * Email Notifications
 */
export async function getEmailNotificationTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiEmailNotificationsGet(apiCallback(resolve, reject));
  });
}
export async function sendEmailNotificationPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiEmailNotificationsPost(body, apiCallback(resolve, reject));
  });
}

/**
 * Shipwell-UI User Preferences
 * Gets user's table settings for page size
 */
export const getShipwellUiUserPreferencesPromise = async () => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiUserPreferencesGet(apiCallback(resolve, reject));
  });
};

/**
 * Shipwell-UI User Preferences
 * Sets user's table settings for page size
 */
export const editShipwellUiUserPreferencesPromise = async (body = {}) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiUserPreferencesPut(body, apiCallback(resolve, reject));
  });
};

/**
 * Compass Dashboard Alert Config
 * Get an alert dashboard config
 */
export const getAlertConfigById = async (alertsDashboardConfigurationId) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdGet(
      alertsDashboardConfigurationId,
      apiCallback(resolve, reject)
    );
  });
};

/**
 * Compass Dashboard Alert Counts
 * Get alert counts for Compass dashboard
 */
export const getAlertCountByConfigId = async (alertsDashboardConfigurationId) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdAlertCountGet(
      alertsDashboardConfigurationId,
      apiCallback(resolve, reject)
    );
  });
};

/**
 * Compass Dashboard Alert Shipments
 * Get list of shipments by supplied IDs
 */
export const getShipmentsByIds = async (opts) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardShipmentsGet(opts, apiCallback(resolve, reject));
  });
};

/**
 * Compass Configuration Alert Options
 * Get alert options for Compass configuration
 */
export const getAlertConfigurationOptions = async () => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationOptionsGet(apiCallback(resolve, reject));
  });
};

/**
 * Compass Configuration Alerts
 * Get alerts for Compass configuration
 */
export const getAlertsConfiguration = async (opts) => {
  // Sets default pagination to fetch all options from the backend. Currently, the backend
  // defaults to 20 items per page. We may consider changing this in the backend core to
  // override Django's default pagination in the future. For the moment, this approach addresses the issue.
  const defaultOpts = {page: 1, pageSize: 50, ...opts};

  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationGet(defaultOpts, apiCallback(resolve, reject));
  });
};

/**
 * Compass Configuration Alerts
 * Post alert for Compass configuration
 */
export const postAlertsConfiguration = async (alerts) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationPost(alerts, apiCallback(resolve, reject));
  });
};

/**
 * Compass Configuration Alerts
 * Delete alert for Compass configuration
 */
export const deleteAlertConfiguration = async (id) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdDelete(
      id,
      apiCallback(resolve, reject)
    );
  });
};

/**
 * Compass Configuration Alerts
 * Update alert for Compass configuration
 */
export const updateAlertConfiguration = async (id, alert) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdPut(
      id,
      alert,
      apiCallback(resolve, reject)
    );
  });
};

/**
 * Compass Configuration Alerts
 * Bulk update alerts for Compass configuration
 */
export const updateAlertConfigurations = async (alerts) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiAlertsDashboardConfigurationBulkUpdatePut(alerts, apiCallback(resolve, reject));
  });
};

/**
 * Compass Dashboard Watched Shipments
 * Get watched shipments for Compass dashboard
 */
export const getWatchedShipments = async (opts) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiUserWatchedShipmentsGet(opts, apiCallback(resolve, reject));
  });
};

/**
 * Compass Dashboard Watched Shipments
 * Start watching a shipment
 */
export const watchShipment = async (body) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiUserWatchedShipmentsPost(body, apiCallback(resolve, reject));
  });
};

/**
 * Compass Dashboard Watched Shipments
 * Stop watching a shipment
 */
export const stopWatchingShipment = async (body) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.shipwellUiUserWatchedShipmentsDelete(body, apiCallback(resolve, reject));
  });
};
