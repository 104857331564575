import {Card, Button, SvgIcon, Popover, Loader} from '@shipwell/shipwell-ui';
import {UseQueryResult, useQueryClient} from '@tanstack/react-query';
import {createColumnHelper, flexRender} from '@tanstack/react-table';
import {Link} from 'react-router';
import {SetStateAction, useState} from 'react';
import {UPSAccount, UPSOauthRefreshStatus} from '@shipwell/backend-core-singlerequestparam-sdk';
import isUndefined from 'lodash/isUndefined';
import ConfirmUpsAccountDelete from './components/confirmUpsAccountDelete';
import {RegistrationApiRegistrationUPSGetResponse} from 'App/api/registration/typed';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {Table, TableCell, TableHeader, TableHeaderRow, TableRow} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {TableLoadingBar} from 'App/components/TypedTable/complementaryComponents';
import {UPS_OAUTH_STATUS} from 'App/data-hooks/queryKeys';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {useGetUpsAuthStatus} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAuthStatus';

const UpsConnectedAccountsTable = ({
  upsQuery,
  setShouldOpenForm,
  setError,
  setWarning
}: {
  upsQuery: UseQueryResult<RegistrationApiRegistrationUPSGetResponse, unknown>;
  setShouldOpenForm: (value: boolean) => void;
} & Partial<WithStatusToastProps>) => {
  const queryClient = useQueryClient();
  const getAccountStatus = (id?: string | null) => {
    if (!id) return undefined;

    const {status} = queryClient.getQueryData<UPSOauthRefreshStatus | undefined>([UPS_OAUTH_STATUS, id]) || {};
    return status;
  };

  const [accountIdToDelete, setAccountToDelete] = useState<null | string>(null);
  const {data, isFetching, isLoading} = upsQuery;
  const isLoadingAccounts = isFetching || isLoading;

  const {verifyAccount, isFetchingVerifyLink} = useGetUpsAuthStatus({
    onError: () => {
      setWarning?.(
        'Unable to create OAuth URL',
        'Your account may already be authenticated, refreshing authentication status ...'
      );
      void queryClient.invalidateQueries([UPS_OAUTH_STATUS]);
    }
  });

  const columnHelper = createColumnHelper<UPSAccount>();

  const table = useTypedTable({
    data: data?.results || [],
    columns: [
      columnHelper.accessor('account_number', {
        header: 'Account Number',
        cell: (info) => info.getValue() as string,
        id: 'account_number',
        size: 500
      }),
      columnHelper.display({
        header: 'Status',
        id: 'status',
        cell: (info) => {
          const status = getAccountStatus(info.row.original.id);
          return isUndefined(status) ? '--' : status === 'SUCCESS' ? startCaseToLower(status) : 'Not Authenticated';
        }
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (info) => {
          const {id} = info.row.original;
          const status = getAccountStatus(id);
          const showVerifyButton = status !== 'SUCCESS';

          return (
            <Popover
              placement="left"
              trigger={
                <Popover.Button
                  variant="tertiary"
                  // @ts-expect-error props
                  className="p-0"
                >
                  <SvgIcon color="$sw-icon" name="Overflow" />
                </Popover.Button>
              }
            >
              {({setIsOpen}: {setIsOpen: (value: SetStateAction<boolean>) => void}) => (
                <Popover.MenuList>
                  <Popover.MenuListItemButton
                    onClick={() => {
                      setIsOpen(false);
                      if (id) setAccountToDelete(id);
                    }}
                  >
                    Delete this account
                  </Popover.MenuListItemButton>
                  {showVerifyButton && (
                    <Popover.MenuListItemButton
                      onClick={() => {
                        setIsOpen(false);
                        if (id) verifyAccount(id);
                      }}
                    >
                      Verify Account
                    </Popover.MenuListItemButton>
                  )}
                </Popover.MenuList>
              )}
            </Popover>
          );
        },
        id: 'actions'
      })
    ]
  });

  return (
    <div className="flex flex-col divide-solid rounded border border-sw-border bg-sw-background-component px-6 py-7">
      <div className="mb-4 flex items-center justify-between">
        <span>
          For carrier information, please visit the <Link to={`/carriers?page=1&q=ups`}>UPS carrier page</Link>.
        </span>
      </div>
      <Card
        title="Accounts"
        draggableProvided={'false'}
        actions={
          <Button variant="tertiary" onClick={() => setShouldOpenForm(true)} iconName="AddCircleOutlined">
            Add more accounts
          </Button>
        }
      >
        {isLoadingAccounts && <TableLoadingBar />}
        <div className="rounded border-1 border-sw-border">
          <Table
            head={table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader key={header.id} width={header.getSize()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
            body={table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          />
        </div>
      </Card>
      <ConfirmUpsAccountDelete
        onClose={() => setAccountToDelete(null)}
        accountIdToDelete={accountIdToDelete}
        setError={setError}
      />
      <Loader show={isFetchingVerifyLink}>Opening OAuth Window ...</Loader>
    </div>
  );
};

export default WithStatusToasts(UpsConnectedAccountsTable);
