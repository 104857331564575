import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Pagination, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {SubmissionError} from 'redux-form';
import ReactTable from 'react-table-6';
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import TrailerForm from '../forms/TrailerForm';
import './_manage-equipment.scss';
import PowerUnitForm from '../forms/PowerUnitForm';
import * as actions from 'App/actions/carriers';
import * as formActions from 'App/actions/forms';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {unpackErrors, numberWithCommas} from 'App/utils/globals';
import 'react-table-6/react-table.css';

@connect(
  (state) => ({
    company: state.auth.company,
    isLoading: state.carriers.isLoading,
    powerUnits: state.carriers.powerUnits,
    powerUnit: state.carriers.powerUnit,
    trailers: state.carriers.trailers,
    trailer: state.carriers.trailer
  }),
  {...actions, ...formActions}
)
export default class ManageEquipment extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.getEquipment = this.getEquipment.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.deletePowerUnit = this.deletePowerUnit.bind(this);
    this.deleteTrailer = this.deleteTrailer.bind(this);
    this.renderDeleteModal = this.renderDeleteModal.bind(this);
    this.state = {
      showAddEquipment: false,
      showEditEquipment: false,
      activeTab: localStorage.getItem('manageEquipmentTab') ? localStorage.getItem('manageEquipmentTab') : 'power_unit',
      showDeleteModal: false,
      pullingFromProvider: false,
      powerUnitPagination: {
        page: 1,
        pageSize: 10,
        ordering: 'name'
      },
      trailerPagination: {
        page: 1,
        pageSize: 10,
        ordering: 'name'
      }
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getEquipment(this.props.company.carrier.id);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.company && this.props.company !== nextProps.company) {
      this.getEquipment(nextProps.company.carrier.id);
    }
  }

  getEquipment(id) {
    this.props.getPowerUnits(id, this.state.powerUnitPagination);
    this.props.getTrailers(id, this.state.trailerPagination);
  }

  handleTabChange(equipment) {
    localStorage.setItem('manageEquipmentTab', equipment);
    this.setState({activeTab: equipment});
  }

  renderDeleteModal() {
    this.setState({showDeleteModal: true});
  }

  deleteTrailer() {
    return this.props.deleteTrailer(this.props.company.carrier.id, this.props.trailer.id).then((response) => {
      if (response.status === 200) {
        this.getEquipment(this.props.company.carrier.id);
        this.props.clearTrailer();
        this.setState({showDeleteModal: false});
      }
    });
  }

  deletePowerUnit() {
    return this.props.deletePowerUnit(this.props.company.carrier.id, this.props.powerUnit.id).then((response) => {
      if (response.status === 200) {
        this.getEquipment(this.props.company.carrier.id);
        this.props.clearPowerUnit();
        this.setState({showDeleteModal: false});
      }
    });
  }

  render() {
    return (
      <div className="equipment content-wrapper">
        <section className="content">
          {!this.state.showEditEquipment && (
            <nav className="equipment__tabs">
              <div
                onClick={() => {
                  this.handleTabChange('power_unit');
                }}
                className={'h2 ' + (this.state.activeTab === 'power_unit' ? 'active' : '')}
              >
                Power Unit
              </div>
              <div
                onClick={() => {
                  this.handleTabChange('trailer');
                }}
                className={'h2 ' + (this.state.activeTab === 'trailer' ? ' active' : '')}
              >
                Trailer
              </div>
            </nav>
          )}
          {this.state.showEditEquipment && <h2>power unit</h2>}
          <Paper className="equipment__card">
            {!this.state.showAddEquipment && !this.state.showEditEquipment && (
              <div className="equipment__toolbar">
                {this.state.activeTab === 'power_unit' && (
                  <Button
                    bsStyle="default"
                    disabled={this.state.pullingFromProvider}
                    onClick={() => {
                      this.setState(
                        {pullingFromProvider: true},
                        function () {
                          this.props.postPowerUnit(this.props.company.carrier.id, {}).then((repsonse) => {
                            this.setState({pullingFromProvider: false});
                            this.getEquipment(this.props.company.carrier.id);
                          });
                        }.bind(this)
                      );
                    }}
                  >
                    {this.state.pullingFromProvider ? (
                      <span>
                        <i className="icon icon-Restart rotate" />
                        &nbsp;Syncing
                      </span>
                    ) : (
                      <span>
                        <i className="icon icon-Download pad-right" />
                        Sync from ELD Provider
                      </span>
                    )}
                  </Button>
                )}
                <Button
                  bsStyle="link"
                  onClick={() => {
                    if (this.state.activeTab === 'trailer') {
                      this.context.router.push('/manage_equipment/trailer/new_equipment');
                    }
                    if (this.state.activeTab === 'power_unit') {
                      this.context.router.push('/manage_equipment/power_unit/new_equipment');
                    }
                  }}
                >
                  + Add Equipment
                </Button>
              </div>
            )}
            <div className="equipment__table">
              {!this.state.showAddEquipment && !this.state.showEditEquipment && this.state.activeTab === 'trailer' && (
                <ReactTable
                  data={this.props.trailers ? this.props.trailers.results : []}
                  pages={this.props.trailers ? this.props.trailers.total_pages : 1}
                  loading={this.props.isLoading}
                  manual
                  minRows={10}
                  defaultPageSize={this.state.trailerPagination.pageSize}
                  pageSizeOptions={[10, 25, 50, 100]}
                  defaultSorted={[
                    {
                      id: 'name',
                      desc: false
                    }
                  ]}
                  getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (!rowInfo) {
                      return {
                        className: 'no-content'
                      };
                    }
                    return '';
                  }}
                  onFetchData={function (state, instance) {
                    const ordering = [];
                    for (let i = 0; i < state.sorted.length; i++) {
                      ordering.push((state.sorted[i].desc ? '-' : '') + state.sorted[i].id);
                    }
                    if (!_.isEmpty(this.props.company)) {
                      this.props
                        .getTrailers(this.props.company.carrier.id, {
                          page: state.page + 1,
                          pageSize: state.pageSize,
                          ordering: ordering.join(',')
                        })
                        .then((response) => {
                          const pagination = this.state.trailerPagination;
                          pagination.page = state.page + 1;
                          pagination.pageSize = state.pageSize;
                          pagination.ordering = ordering;
                          this.setState({trailerPagination: pagination});
                        });
                    }
                  }.bind(this)}
                  onPageChange={(page) => {
                    document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
                  }}
                  columns={[
                    {
                      Header: () => (
                        <span>
                          Name
                          <i className="icon icon-Sort btn-sort" />
                        </span>
                      ),
                      id: 'name',
                      accessor: (d) => d.name,
                      Cell: (row) => {
                        return (
                          <div>
                            <Link to={`/manage_equipment/trailer/${row.original.id}`}>{row.value}</Link>
                          </div>
                        );
                      }
                    },
                    {
                      Header: 'License Plate',
                      id: 'license_plate_number',
                      accessor: (d) => d.license_plate_number,
                      sortable: false
                    },
                    {
                      Header: 'Max Haul Weight (lb)',
                      id: 'max_haul_weight_lbs',
                      accessor: (d) => d.max_haul_weight_lbs,
                      sortable: false
                    },
                    {
                      Header: () => {
                        return (
                          <span>
                            Max Haul Volume (ft
                            <sup>3</sup>)
                          </span>
                        );
                      },
                      id: 'max_haul_volume_ft3',
                      accessor: (d) => d.max_haul_volume_ft3,
                      sortable: false
                    },
                    {
                      Header: 'Odometer (miles)',
                      id: 'model',
                      accessor: (d) => d.distance_traveled_miles,
                      Cell: (row) => {
                        return row.value ? numberWithCommas(row.value) : '';
                      },
                      sortable: false
                    },
                    {
                      Header: 'Description',
                      id: 'description',
                      accessor: (d) => d.description,
                      sortable: false
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      Cell: (row) => {
                        return (
                          <span>
                            <DeprecatedButton
                              variant="icon"
                              className="manage-carriers__actions pad-left"
                              onClick={() =>
                                this.props
                                  .getTrailer(this.props.company.carrier.id, row.original.id)
                                  .then((response) => {
                                    if (response.status === 200) {
                                      this.renderDeleteModal();
                                    }
                                  })
                              }
                            >
                              <SvgIcon name="TrashOutlined" title="Delete carrier relationship" />
                            </DeprecatedButton>
                          </span>
                        );
                      }
                    }
                  ]}
                  className="-highlight -striped"
                  previousText={<i className="icon icon-Left" />}
                  nextText={<i className="icon icon-Right" />}
                  rowsText=""
                  noDataText="No Trailers"
                  LoadingComponent={ShipwellLoader}
                />
              )}
              {!this.state.showAddEquipment &&
                !this.state.showEditEquipment &&
                this.state.activeTab === 'power_unit' && (
                  <ReactTable
                    data={this.props.powerUnits ? this.props.powerUnits.results : []}
                    pages={this.props.powerUnits ? this.props.powerUnits.total_pages : 1}
                    loading={this.props.isLoading}
                    manual
                    minRows={10}
                    defaultPageSize={this.state.powerUnitPagination.pageSize}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultSorted={[
                      {
                        id: 'name',
                        desc: false
                      }
                    ]}
                    getTrGroupProps={(state, rowInfo, column, instance) => {
                      if (!rowInfo) {
                        return {
                          className: 'no-content'
                        };
                      }
                      return '';
                    }}
                    onFetchData={function (state, instance) {
                      const ordering = [];
                      for (let i = 0; i < state.sorted.length; i++) {
                        ordering.push((state.sorted[i].desc ? '-' : '') + state.sorted[i].id);
                      }
                      if (!_.isEmpty(this.props.company)) {
                        this.props
                          .getPowerUnits(this.props.company.carrier.id, {
                            page: state.page + 1,
                            pageSize: state.pageSize,
                            ordering: ordering.join(',')
                          })
                          .then((response) => {
                            const pagination = this.state.powerUnitPagination;
                            pagination.page = state.page + 1;
                            pagination.pageSize = state.pageSize;
                            pagination.ordering = ordering;
                            this.setState({powerUnitPagination: pagination});
                          });
                      }
                    }.bind(this)}
                    onPageChange={(page) => {
                      document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
                    }}
                    columns={[
                      {
                        Header: () => (
                          <span>
                            Name
                            <i className="icon icon-Sort btn-sort" />
                          </span>
                        ),
                        id: 'name',
                        accessor: (d) => d.name,
                        Cell: (row) => {
                          return (
                            <div>
                              <Link to={`/manage_equipment/power_unit/${row.original.id}`}>{row.value}</Link>
                            </div>
                          );
                        }
                      },
                      {
                        Header: 'VIN',
                        id: 'vin',
                        accessor: (d) => d.vin,
                        sortable: false
                      },
                      {
                        Header: 'Year',
                        id: 'year',
                        accessor: (d) => d.year,
                        sortable: false
                      },
                      {
                        Header: 'Make',
                        id: 'make',
                        accessor: (d) => d.make,
                        sortable: false
                      },
                      {
                        Header: 'Model',
                        id: 'model',
                        accessor: (d) => d.model,
                        sortable: false
                      },
                      {
                        Header: 'License Plate',
                        id: 'license_plate_number',
                        accessor: (d) => d.license_plate_number,
                        sortable: false
                      },
                      {
                        Header: 'Odometer (miles)',
                        id: 'distance_traveled_miles',
                        accessor: (d) => d.distance_traveled_miles,
                        Cell: (row) => {
                          return row.value ? numberWithCommas(row.value) : '';
                        },
                        sortable: false
                      },

                      {
                        Header: 'Actions',
                        accessor: 'actions',
                        Cell: (row) => {
                          return (
                            <span>
                              <DeprecatedButton
                                variant="icon"
                                className="manage-carriers__actions pad-left"
                                onClick={() =>
                                  this.props
                                    .getPowerUnit(this.props.company.carrier.id, row.original.id)
                                    .then((response) => {
                                      if (response.status === 200) {
                                        this.renderDeleteModal();
                                      }
                                    })
                                }
                              >
                                <SvgIcon title="Delete carrier relationship" name="TrashOutlined" />
                              </DeprecatedButton>
                            </span>
                          );
                        }
                      }
                    ]}
                    className="-highlight -striped"
                    previousText={<i className="icon icon-Left" />}
                    nextText={<i className="icon icon-Right" />}
                    rowsText=""
                    noDataText="No Power Units"
                    LoadingComponent={ShipwellLoader}
                  />
                )}
            </div>
          </Paper>
        </section>
        <InfoModalWrapper
          show={this.state.showDeleteModal}
          title={'Delete' + (this.state.activeTab === 'power_unit' ? ' Power Unit' : ' Trailer')}
          onHide={() => {
            this.setState({
              showDeleteModal: false
            });
          }}
          children={
            'Are you sure you want to delete ' +
            (this.state.activeTab === 'power_unit'
              ? this.props.powerUnit
                ? this.props.powerUnit.name
                : ''
              : this.props.trailer
              ? this.props.trailer.name
              : '')
          }
          primaryAction={{
            action: this.state.activeTab === 'power_unit' ? this.deletePowerUnit : this.deleteTrailer,
            label: 'Delete'
          }}
        />
      </div>
    );
  }
}
