export type DynamicField = {
  label: string;
  tooltip?: {
    title: string;
    description: string;
  };
  input: {
    type: 'email' | 'password' | 'text' | 'textarea' | 'string' | 'select' | 'address';
    pattern?: string;
    required?: boolean;
    minLength?: number;
    options?: {value: string; label: string}[];
  };
  data: {
    type: string;
    visibility: string;
  };
};

export type DynamicFields = Record<string, DynamicField>;

export type DynamicFieldsApiResponse = {fields: DynamicFields};

export const getTestFields = async (): Promise<DynamicFieldsApiResponse> => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return {
    fields: {
      email: {
        label: 'Account Email',
        tooltip: {
          title: 'What is account email?',
          description: 'This is the email address associated with your account.'
        },
        input: {
          type: 'email',
          pattern: '^\\S+@\\S+$'
        },
        data: {
          type: 'string',
          visibility: 'visible'
        }
      },
      password: {
        label: 'Password',
        input: {
          type: 'password',
          required: true, // use a "truthy" test for this as this may be strings of "true" or "false"
          minLength: 12 // notice how this is the DOM lowerCamelCasing and not the HTML5 element lowercase casing of minlength
        },
        data: {
          type: 'string',
          visibility: 'password'
        }
      },
      api_key: {
        label: 'Client API Key',
        input: {
          type: 'text',
          required: true, // use a "truthy" test for this as this may be strings of "true" or "false"
          minLength: 22 // notice how this is the DOM lowerCamelCasing and not the HTML5 element lowercase casing of minlength
        },
        data: {
          type: 'string',
          visibility: 'masked' // when rendered this value is partially or fully masked, i.e. "prod_6543********"
        }
      },
      us_postal_code: {
        label: 'Postal Code',
        input: {
          type: 'string',
          required: true,
          pattern: '(\\d{5}([-]\\d{4})?)'
        },
        data: {
          type: 'string',
          visibility: 'visible'
        }
      },
      image_type: {
        label: 'Image Type',
        input: {
          type: 'select',
          required: true,
          options: [
            {value: 'zpl', label: 'ZPL (default)'},
            {value: 'epl', label: 'EPL'},
            {value: 'pdf', label: 'PDF'},
            {value: 'png', label: 'PNG'}
          ]
        },
        data: {
          type: 'string',
          visibility: 'visible'
        }
      },
      label_size: {
        label: 'Label Size',
        input: {
          type: 'select',
          required: true,
          options: [
            {value: '4x6', label: '4x6 (default)'},
            {value: '8.5x11', label: '8.5x11'}
          ]
        },
        data: {
          type: 'string',
          visibility: 'visible'
        }
      },
      origin: {
        label: 'Origin',
        input: {
          type: 'address'
        },
        data: {
          type: 'string',
          visibility: 'visible'
        }
      }
    }
  };
};
