import {Formik} from 'formik';
import {object, string, mixed} from 'yup';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import SupplierShipmentRequestFields from '../../formSections/supplierShipmentRequestFields';

const SupplierShipmentRequestForm = (props) => {
  const {onCancel, onSubmit, customers, allowCustomerAssignment, getCustomers} = props;
  const defaultCustomer = customers.find((customer) => customer.is_own_brokerage);
  const defaultFormValues = {
    supplier_email_address: '',
    po_number: '',
    message_to_supplier: '',
    express_delivery: false,
    allow_autobook: true,
    customer: customers?.length > 1 && defaultCustomer ? defaultCustomer : null
  };

  const supplierShipmentsSchema = object().shape({
    supplier_email_address: string().email('A valid email required.').required('A valid email required.'),
    po_number: mixed().required('PO number is required.'),
    customer: object()
      .shape({
        id: string()
          .nullable()
          .test({
            name: 'customer',
            message: 'Assigned customer is required. Search and select a customer.',
            test: (value) => (allowCustomerAssignment && !value ? false : true)
          })
      })
      .nullable()
  });
  /** Submit shipment request form values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      const formattedValues = {...values, customer: values.customer?.id || null};
      onSubmit(formattedValues, actions);
    }
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={supplierShipmentsSchema}
      initialValues={{...defaultFormValues}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, isSubmitting, dirty, ...props}) => (
        <form className="supplier-shipment-request-form" noValidate="novalidate" onSubmit={handleSubmit}>
          <SupplierShipmentRequestFields getCustomers={getCustomers} customers={customers} {...props} />
          <div className="page-footer">
            {onCancel && (
              <DeprecatedButton variant="secondary" onClick={onCancel}>
                Cancel
              </DeprecatedButton>
            )}
            {onSubmit && (
              <DeprecatedButton loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </DeprecatedButton>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SupplierShipmentRequestForm;
