import TimelineIcon from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineIcon';
import {mapStopOrdinalWithIcon} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils';

const FutureStop = ({
  stopOrdinalNumber,
  isLastStop = false
}: {
  stopOrdinalNumber: number | null;
  isLastStop?: boolean;
}) => {
  return (
    <div className="flex h-10">
      <TimelineIcon
        iconToShow={mapStopOrdinalWithIcon(stopOrdinalNumber)}
        iconColor="sw-disabled"
        isLineHidden={isLastStop}
      />
      <div className="mb-4 flex items-center italic text-sw-disabled">Stop {stopOrdinalNumber}</div>
    </div>
  );
};

export default FutureStop;
