import {MovementListResponse} from '@shipwell/locus-sdk';

export const airMovementStatus = {
  ARRIVED_LATE: 'ARRIVED_LATE',
  ARRIVED: 'ARRIVED',
  DELAYED: 'DELAYED',
  ON_TIME: 'ON_TIME'
};

export const getCurrentLocationFromData = (data?: MovementListResponse) => {
  if (!data) {
    return null;
  }

  const movementDetailsData = data?.data?.map(({status, destination, origin}) => ({status, destination, origin})) || [];

  // remove all details that are already arrived if they are not the last elements in the list.
  const pendingOrLastMovements = movementDetailsData.filter(
    (movement, index) =>
      (movement.status !== airMovementStatus.ARRIVED && movement.status !== airMovementStatus.ARRIVED_LATE) ||
      index === movementDetailsData.length - 1
  );

  // return null if no items in the filtered list
  if (!pendingOrLastMovements.length) {
    return null;
  }

  // if there's only one element in the filtered array, return its location based on the status
  if (pendingOrLastMovements.length === 1) {
    return pendingOrLastMovements[0].status === airMovementStatus.ARRIVED ||
      pendingOrLastMovements[0].status === airMovementStatus.ARRIVED_LATE
      ? pendingOrLastMovements[0].destination
      : pendingOrLastMovements[0].origin;
  }

  // if there are more than one element in the filtered array, return the location based
  // in the first element origin (if it has arrived already, it would be the origin of the next element)
  return pendingOrLastMovements[0].origin;
};
