import {useMemo, useState} from 'react';
import classnames from 'classnames';
import {Select, SvgIcon} from '@shipwell/shipwell-ui';
import {SlimShipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import debounce from 'lodash/debounce';
import {getShipments} from 'App/api/shipment/typed';
import {getFirstAndLastStops, getShipmentLane} from 'App/utils/stops';
import {useShipmentSearchQuery} from 'App/data-hooks';

/**
 * Creates a nicely formatted label to display to users for when a shipment
 * is searched.
 * @param {SlimShipment} shipment
 * @returns string
 * @example ID JH7654 • AMARILLO, TX > DALLAS, TX • Carrier Name • Mon July 02, 2022 • Tue Jul 02, 2022
 */
function getOptionLabel(shipment: SlimShipment) {
  const {firstStop, lastStop} = getFirstAndLastStops(shipment);
  const referenceId = shipment.reference_id ?? '';
  const lane = getShipmentLane(firstStop, lastStop);

  const carrierName = shipment.current_carrier?.name ?? '';
  const parts = [`ID ${referenceId}`, lane, carrierName];
  if (firstStop?.planned_date) {
    const dt = new Date(firstStop.planned_date);
    if (!Number.isNaN(dt.valueOf())) {
      parts.push(dt.toDateString());
    }
  }
  if (lastStop?.planned_date) {
    const dt = new Date(lastStop.planned_date);
    if (!Number.isNaN(dt.valueOf())) {
      parts.push(dt.toDateString());
    }
  }
  // this will build a message that looks like
  // done this way so we exclude missing data
  return parts.reduce((left, right) => {
    if (left) {
      if (right) {
        return left + ' • ' + right;
      }
      return left;
    }
    return right;
  });
}

function getOptionValue(shipment: SlimShipment): string {
  const referenceFields = [
    shipment.id,
    shipment.bol_number,
    shipment.customer_reference_number,
    shipment.pro_number,
    shipment.pickup_number,
    shipment.reference_id
  ].filter(Boolean) as string[]; // typescript linter can't figure out that we are forcing truthy values only here

  return referenceFields.reduce((left, right) => left.concat(' ', right));
}

export type SelectShipmentProps = {
  name?: string;
  /**
   * Displayed in the search box
   */
  placeholder?: string;
  /**
   * Whether the user can clear their search selection.
   * @default undefined|false
   */
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  /**
   * Display label that will appear in search box.
   * @default "Search for Shipment"
   */
  label?: string;
  /**
   * Callback handler for when a option has been chosen.
   * @param {SlimShipment|null} shipment shipment the was selected in the dropdown. If the select
   * component is clearable null can be passed to indicate that no value is selected.
   */
  onSelectChanged?: (shipment: SlimShipment | null) => void;
  onSelectCreateAppt?: () => void;
};
/**
 * Creates a search input with a dropdown that displays search results for shipments
 * by reference id. The users's input will be ignored until they have typed at least four
 * characters into the input.
 *
 */
const SelectShipment = (
  {
    clearable,
    disabled,
    label,
    name,
    placeholder,
    required,
    onSelectChanged,
    onSelectCreateAppt
  }: SelectShipmentProps = {
    label: 'Search for Shipments'
  }
) => {
  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedOption, setSelectedOption] = useState<{label: string; value: string; shipmentId: string} | null>(null);
  const debouncedSearch = debounce((val: string) => setSearchText(val), 300);

  const {shipments} = useShipmentSearchQuery(
    {
      q: searchText
    },
    {
      enabled: !!searchText
    }
  );

  const handleChanged = (option: {label: string; value: string; shipmentId: string} | null) => {
    if (option?.value === 'create_new_appt') {
      onSelectCreateAppt?.();
      return;
    }
    if (option === null) {
      setSelectedOption(null);
      onSelectChanged?.(null);
      return;
    }
    const shipment = shipments.find((slimShipment) => slimShipment.id === option.shipmentId);
    setSelectedOption(option);
    onSelectChanged?.(shipment ?? null);
  };

  const formatShipmentSearchOptions = (
    option: {label: string; value: string; shipmentId: string},
    {context}: {context: string}
  ) => {
    if (context === 'value') {
      return option?.label;
    }
    return <div className={classnames({'text-sw-primary': option?.value === 'create_new_appt'})}>{option.label}</div>;
  };

  const optionValues = useMemo(() => {
    if (!shipments?.length) {
      return [];
    }

    return shipments.map((slimShipment) => ({
      shipmentId: slimShipment.id,
      label: getOptionLabel(slimShipment),
      value: getOptionValue(slimShipment)
    }));
  }, [shipments]);

  return (
    <Select
      async
      defaultOptions
      role="search"
      clearable={clearable}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      prepend={<SvgIcon name="Search" />}
      name={name ?? label?.replaceAll(' ', '-')}
      options={optionValues}
      required={required}
      value={selectedOption}
      onChange={handleChanged}
      onInputChange={(val: string) => debouncedSearch(val)}
      formatOptionLabel={formatShipmentSearchOptions}
      loadOptions={async (q: string) => {
        const shipmentSearchOptions = [{label: 'Create New Appointment', value: 'create_new_appt', shipmentId: ''}];
        if (q !== '') {
          const response = await getShipments({
            q: q
          });

          if (response) {
            response.results?.forEach((slimShipment) => {
              shipmentSearchOptions.push({
                label: getOptionLabel(slimShipment),
                value: getOptionValue(slimShipment),
                shipmentId: slimShipment.id
              });
            });
          }
        }
        return shipmentSearchOptions;
      }}
    />
  );
};

export default SelectShipment;
