import {ALERT_ERROR} from 'App/actions/types';

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

/**
 * Get Carrier Trailers List
 */
export const REQUEST_TRAILERS_SUCCESS = 'REQUEST_TRAILERS_SUCCESS';

export const requestTrailersSuccess = (response) => ({
  type: REQUEST_TRAILERS_SUCCESS,
  payload: response.body
});

/**
 * Get Carrier trailer
 */
export const REQUEST_TRAILER_SUCCESS = 'REQUEST_TRAILER_SUCCESS';

export const requestTrailerSuccess = (response) => ({
  type: REQUEST_TRAILER_SUCCESS,
  payload: response.body
});

/**
 * Update Carrier trailer
 */
export const UPDATE_TRAILER_SUCCESS = 'UPDATE_TRAILER_SUCCESS';

export const updateTrailerSuccess = (response) => ({
  type: UPDATE_TRAILER_SUCCESS,
  payload: response.body
});

/**
 * Create Carrier trailer
 */
export const CREATE_TRAILER_SUCCESS = 'CREATE_TRAILER_SUCCESS';

export const createTrailerSuccess = (response) => ({
  type: CREATE_TRAILER_SUCCESS,
  payload: response.body
});

/**
 * Delete Carrier trailer
 */
export const DELETE_TRAILER_SUCCESS = 'DELETE_TRAILER_SUCCESS';

export const deleteTrailersSuccess = (trailerId) => ({
  type: DELETE_TRAILER_SUCCESS,
  payload: trailerId
});
