import {
  ALERT_ERROR,
  FETCH_EXPENSE_ACCOUNTS,
  FETCH_EXPENSE_CLASSES,
  GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS,
  GET_SHIPMENT_INVOICES,
  SHIPMENT_BILLS_REQUEST_SUCCESS,
  GET_CONNECTED_FMS
} from './types';
import {integrations, billing} from 'App/api';

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

/**
 * QuickBooks Expense Accounts
 */
export const billingExpenseAccountRequestSuccess = (response) => ({
  type: FETCH_EXPENSE_ACCOUNTS,
  payload: response
});

export function populateExpenseAccounts() {
  return () => billing.createQuickbooksExpenseAccounts();
}

export function fetchExpenseAccounts(opts = {}) {
  return async (dispatch) => {
    try {
      const response = await billing.getQuickbooksExpenseAccounts(opts);
      dispatch(billingExpenseAccountRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

/**
 * QuickBooks Expense Classes
 */
export const billingExpenseClassesRequestSuccess = (response) => ({
  type: FETCH_EXPENSE_CLASSES,
  payload: response.body
});

export function populateExpenseClasses() {
  return async (dispatch) => {
    try {
      return await integrations.populateQuickBooksExpenseClasses();
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

export function fetchExpenseClasses(opts = {}) {
  return async (dispatch) => {
    try {
      const response = await integrations.getQuickBooksExpenseClasses(opts);
      dispatch(billingExpenseClassesRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

export const getTriumphPayAuthStatusSuccess = (response) => ({
  type: GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS,
  payload: response.body
});

export function checkTriumphPayAuth() {
  return async (dispatch) => {
    try {
      const response = await integrations.checkTriumphPayAuthentication();
      dispatch(getTriumphPayAuthStatusSuccess(response));
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}

/*
 * Invoicing
 */

export const shipmentInvoicesRequestSuccess = (response) => {
  return {
    type: GET_SHIPMENT_INVOICES,
    payload: response.body
  };
};

export function getShipmentInvoices(shipmentId) {
  return async (dispatch) => {
    try {
      const response = await integrations.getShipmentInvoices(shipmentId);
      dispatch(shipmentInvoicesRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError('There was an error getting invoices for this shipment.'));
      return error;
    }
  };
}

export function getCompanyInvoice(companyId, invoiceId) {
  return async (dispatch) => {
    try {
      const response = await integrations.getCompanyInvoice(companyId, invoiceId);
      return response;
    } catch (error) {
      dispatch(displayError('There was an error getting this invoice.'));
      return error;
    }
  };
}

export function createInvoice(shipmentId, body = {}) {
  return async () => {
    try {
      return await integrations.createShipmentInvoice(shipmentId, body);
    } catch (error) {
      return error;
    }
  };
}

export function sendInvoice(invoiceId, opts = {}) {
  return async (dispatch) => {
    try {
      return await integrations.sendShipmentInvoice(invoiceId, opts);
    } catch (error) {
      dispatch(displayError('There was an error emailing this invoice.'));
      return error;
    }
  };
}

/**
 * Billing
 */

export const shipmentBillingRequestSuccess = (response) => {
  return {
    type: SHIPMENT_BILLS_REQUEST_SUCCESS,
    payload: response.body
  };
};

export function getShipmentBills(shipmentId, opts = {}) {
  return async (dispatch) => {
    try {
      const response = await integrations.getShipmentBills(shipmentId, opts);
      dispatch(shipmentBillingRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError('There was an error getting bills for this shipment.'));
      return error;
    }
  };
}

export function getBill(billId) {
  return async (dispatch) => {
    try {
      const response = await integrations.getBill(billId);
      return response;
    } catch (error) {
      dispatch(displayError('There was an error getting this bill.'));
      return error;
    }
  };
}

export function createBill(shipmentId, body = {}) {
  return async () => {
    try {
      return await integrations.createShipmentBill(shipmentId, body);
    } catch (error) {
      return error;
    }
  };
}

/*
 * FMS
 */

export const financialManagementSystemsRequestSuccess = (response) => {
  return {
    type: GET_CONNECTED_FMS,
    payload: response.body
  };
};

export function getFinancialManagementSystems() {
  return async (dispatch) => {
    try {
      const response = await integrations.getFinancialManagementSystems();
      dispatch(financialManagementSystemsRequestSuccess(response));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
