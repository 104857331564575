import {Link} from 'react-router';
import {useQueryClient} from '@tanstack/react-query';
import {
  ShipmentAuctionStats,
  ShippingDashboardRow,
  ShippingDashboardVendorAssignmentCarrierStatus
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getIsLTL} from 'App/containers/Dashboard/columns';
import {AUCTION_STATS_KEY} from 'App/data-hooks/queryKeys';

export const CarrierCell = ({
  shipment,
  relationshipToVendor
}: {
  shipment: ShippingDashboardRow;
  relationshipToVendor?: ShippingDashboardVendorAssignmentCarrierStatus;
}) => {
  const queryClient = useQueryClient();
  // this function is reaching into the query cache and grabbing the proper data based on shipmentId
  // this allows us to make one call at the table level instead of a call for every one of these cells rendered
  const getAuctionStatsFromListQuery = () => {
    if (!shipment.id) {
      return;
    }
    const auctionStatsQueries = queryClient.getQueriesData([AUCTION_STATS_KEY, 'list']);
    const auctionStatsByShipmentId = auctionStatsQueries.reduce(
      (final: ShipmentAuctionStats | undefined, statsQuery) => {
        const [, queryData] = statsQuery;
        const typedQueryData = queryData as ShipmentAuctionStats[] | undefined;
        if (!typedQueryData?.length) {
          return;
        }
        const stats = typedQueryData.find((typedData) => typedData.shipment_id === shipment.id);
        if (stats) {
          return (final = stats);
        }
        return final;
      },
      {}
    );
    return auctionStatsByShipmentId;
  };

  const getAuctionStatsObj = () => {
    const stats = getAuctionStatsFromListQuery()?.auction_stats;
    return {
      visible_companies: stats?.visible_companies || 0,
      messages: stats?.messages || 0,
      quotes: stats?.quotes || 0,
      has_open_auction: stats?.has_open_auction
    };
  };

  const auctionStats = getAuctionStatsObj();
  const hasOpenAuction = auctionStats.has_open_auction;
  //check if the vendor is the assigned carrier. if not, then we show the carrier instead of this relationship
  const isAssignedCarrier = relationshipToVendor?.is_assigned_carrier;
  return (
    <div className="flex flex-col">
      {hasOpenAuction ? (
        <Link className="whitespace-nowrap" to={`/marketplace/${shipment.id}/bids`}>
          Auction Running
        </Link>
      ) : null}
      {
        //if there is a vendor at all, show it
        relationshipToVendor?.vendor ? (
          <div>
            {shipment.mode &&
            //if LTL, show the carrier assignment instead of the vendor
            getIsLTL(shipment.mode.code || '') &&
            !isAssignedCarrier ? (
              <span>
                {shipment.current_carrier && shipment.current_carrier.name}
                {!shipment.current_carrier && relationshipToVendor.vendor.name}
              </span>
            ) : (
              <span>{relationshipToVendor.vendor.name}</span>
            )}
          </div>
        ) : (
          '--'
        )
      }
    </div>
  );
};
