import {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {Field, reduxForm, Form} from 'redux-form';
import get from 'lodash/get';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import ShipmentInvoiceDocs from '../ShipmentInvoiceDocs';
import ShipmentCustomerLineItems from './ShipmentCustomerLineItems';
import renderField from 'App/formComponents/renderField';
import TextareaField from 'App/formComponents/fields/textArea';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';

class ShipmentInvoiceForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      financials,
      selectOne,
      selectAll,
      total,
      checkedAll,
      selectDoc,
      selectedDocs,
      selectList,
      customer,
      formValues,
      one
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="shipment__financials-invoiceModal">
          <div>
            <h3 className="shipment__financials-header">Customer: {customer.name}</h3>
            <div className="shipment__financials-customer">
              <Field
                name="customer"
                label="Customer"
                defaultValue={formValues?.values?.customer}
                format={(value) => value?.label}
                component={renderField}
                disabled
              />
            </div>
            <div className="shipment__financials-refs">
              <Field label="Message on Invoice" name="customer_memo" component={TextareaField} />
            </div>
          </div>
          <div>
            <ShipmentInvoiceDocs id={one?.id} onSelect={selectDoc} selected={selectedDocs} />
          </div>
        </div>
        <ShipmentCustomerLineItems
          financials={financials}
          selectAll={selectAll}
          selectOne={selectOne}
          total={total}
          checkedAll={checkedAll}
          selectList={selectList}
        />
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
        <div className="shipment__financials-footer">
          <Button bsStyle="primary" type="submit" disabled={submitting || financials.length === 0}>
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" /> Creating Invoice...
              </span>
            ) : (
              'Create Invoice'
            )}
          </Button>
        </div>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.customer) {
    errors.customer = 'Please select a customer';
  }
  return errors;
};

ShipmentInvoiceForm = reduxForm({
  form: 'shipmentInvoiceForm',
  validate: validate,
  enableReinitialize: true
})(ShipmentInvoiceForm);

ShipmentInvoiceForm = connect((state, ownProps) => {
  let memoString = '';
  const customer = {
    label: ownProps?.one?.relationship_to_customer?.customer?.name
  };

  if (ownProps?.one?.customer_reference_number) {
    memoString += `Customer Reference # ${ownProps.one.customer_reference_number}\n`;
  }
  if (ownProps.one && ownProps.one.stops && ownProps.one.stops.length > 0) {
    let originString = '';
    let destinationString = '';
    const originLocation = ownProps.one.stops[0].location;
    const destinationLocation = ownProps.one.stops[ownProps.one.stops.length - 1].location;

    if (originLocation && originLocation.company_name) {
      originString += `${originLocation.company_name}, `;
    }
    if (originLocation && originLocation.address && originLocation.address.formatted_address) {
      originString += originLocation.address.formatted_address;
    }
    if (destinationLocation && destinationLocation.company_name) {
      destinationString += `${destinationLocation.company_name}, `;
    }
    if (destinationLocation && destinationLocation.address && destinationLocation.address.formatted_address) {
      destinationString += destinationLocation.address.formatted_address;
    }
    memoString += `Origin: ${originString}\n`;
    memoString += `Destination: ${destinationString}\n`;
  }

  if (ownProps.one && ownProps.one.bol_number) {
    memoString += `BOL # ${ownProps.one.bol_number}\n`;
  }
  if (ownProps.one && ownProps.one.pro_number) {
    memoString += `PRO # ${ownProps.one.pro_number}\n`;
  }
  if (ownProps.one && ownProps.one.pickup_number) {
    memoString += `Pickup # ${ownProps.one.pickup_number}\n`;
  }
  if (ownProps.one && ownProps.one.purchase_order_number) {
    memoString += `PO # ${ownProps.one.purchase_order_number}\n`;
  }
  if (
    state.purchaseOrders.details.purchaseOrdersByShipment &&
    state.purchaseOrders.details.purchaseOrdersByShipment[ownProps.one.id]
  ) {
    const purchaseOrders = state.purchaseOrders.details.purchaseOrdersByShipment[ownProps.one.id];
    const purchaseOrderNumbers = purchaseOrders.map((order) => `#${order.order_number}`);
    memoString += `Order ${purchaseOrderNumbers.join(', ')}\n`;
  }
  if (ownProps.one && ownProps.one.mode && ownProps.one.mode.code === 'DRAYAGE') {
    if (ownProps.one.drayage_container_number) {
      memoString += `Container # ${ownProps.one.drayage_container_number}\n`;
    }
    if (ownProps.one.drayage_seal_number) {
      memoString += `Seal # ${ownProps.one.drayage_seal_number}\n`;
    }
    if (ownProps.one.drayage_chassis_number) {
      memoString += `Chassis # ${ownProps.one.drayage_chassis_number}\n`;
    }
  }
  if (ownProps?.customFields && ownProps?.customFields.length > 0) {
    ownProps.customFields.forEach((customField) => {
      const customFieldValue = get(
        ownProps?.one,
        `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${customField.id}`,
        null
      );
      if (customFieldValue) {
        memoString += `${customField.label} # ${customFieldValue}\n`;
      }
    });
  }

  return {
    initialValues: {
      customer: customer,
      line_items: [],
      attached_documents: [],
      customer_memo: memoString
    }
  };
})(ShipmentInvoiceForm);

export default withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment)(ShipmentInvoiceForm);
