import {JSX} from 'react';
import {SvgIcon} from '@shipwell/shipwell-ui';

const WeightCell = ({value}: {value: {overweight: boolean; formattedWeightWithUnit: string}}): JSX.Element | string => {
  const {overweight, formattedWeightWithUnit} = value;

  return overweight ? (
    <div className="flex items-center">
      <div className="pr-1">{formattedWeightWithUnit}</div>
      <SvgIcon name="ErrorOutlined" color="$sw-warning" />
    </div>
  ) : (
    formattedWeightWithUnit
  );
};

export default WeightCell;
