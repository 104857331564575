import {
  Configuration,
  AuthenticationApi,
  LoadPostApi,
  LoadboardType,
  CreateAuthRequest,
  LoadPostRequest,
  LoadPostRequestUpdate,
  LoadboardName,
  SettingsApi,
  GlobalSettingsRequest
} from '@shipwell/tabula-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_SHIPMENT_ASSEMBLY_API_BASE_PATH;

const loadboardsApi = () => {
  return new LoadPostApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
};

const authApi = () => {
  return new AuthenticationApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
};

const settingsApi = () => {
  return new SettingsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
};

// Create Auth
export function createLoadboard(loadboardType: LoadboardName, createAuthRequest: CreateAuthRequest) {
  return authApi().createAuthLoadboardsAuthLoadboardNamePost(loadboardType, createAuthRequest);
}

// Get External Auth Service
export function getExternalAuthService(loadboardType: LoadboardName) {
  return authApi().getExternalAuthServiceLoadboardsAuthLoadboardNameExternalGet(loadboardType);
}

// List Active Lb
export function getActiveLoadboards() {
  return authApi().listActiveLbLoadboardsAuthGet();
}

// Delete Auth
export function deleteLoadboard(loadboardType: LoadboardName, isUser?: boolean, userId?: string) {
  return authApi().deleteAuthLoadboardsAuthLoadboardNameDelete(loadboardType, isUser, userId);
}

// List Loadboards
export function getListLoadboards() {
  return loadboardsApi().listLoadboardsLoadboardsListLoadboardsGet();
}

// List Loadpost
export function getListLoadPosts(shipmentId?: string) {
  return loadboardsApi().listLoadpostLoadboardsLoadpostGet(shipmentId);
}

// Create Loadpost By Loadpost Request
export function createLoadPost(loadboardType: LoadboardType, loadPostRequest: LoadPostRequest) {
  return loadboardsApi().createLoadpostByLoadpostRequestLoadboardsLoadboardNameLoadpostPost(
    loadboardType,
    loadPostRequest
  );
}

// Delete Loadpost
export function deleteLoadPost(loadboardType: LoadboardType, loadpostId: string) {
  return loadboardsApi().deleteLoadpostLoadboardsLoadboardNameLoadpostLoadboardPostRequestIdDelete(
    loadboardType,
    loadpostId
  );
}

// Get Loadpost Request By Shipment Id
export function getLoadPostRequestByShipmentId(shipmentId: string) {
  return loadboardsApi().getLoadpostRequestByShipmentIdRequestLoadboardsLoadpostRequestsShipmentIdGet(shipmentId);
}

// Update Loadpost
export function updateLoadpost(
  loadboardType: LoadboardType,
  loadpostId: string,
  loadPostRequestUpdate: LoadPostRequestUpdate
) {
  return loadboardsApi().updateLoadpostLoadboardsLoadboardNameLoadpostLoadboardPostRequestIdPut(
    loadboardType,
    loadpostId,
    loadPostRequestUpdate
  );
}

// Get Loadpost
export function getLoadpost(loadboardType: LoadboardType, loadpostId: string) {
  return loadboardsApi().getLoadpostLoadboardsLoadboardNameLoadpostLoadboardPostRequestIdGet(loadboardType, loadpostId);
}

// Refresh Loadpost
export function refreshLoadpost(loadboardType: LoadboardType, loadpostId: string) {
  return loadboardsApi().refreshLoadpostLoadboardsLoadboardNameLoadpostLoadboardPostRequestIdRefreshPost(
    loadboardType,
    loadpostId
  );
}

// Get Global Settings
export function getGlobalSettings() {
  return settingsApi().getGlobalSettingsLoadboardsSettingsGet();
}

// Update Global Settings
export function updateGlobalSettings(globalSettings: GlobalSettingsRequest) {
  return settingsApi().updateGlobalSettingsLoadboardsSettingsPut(globalSettings);
}
