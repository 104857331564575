import {useMutation, useQueryClient} from '@tanstack/react-query';
import {createSupplier} from 'App/api/suppliers';
import {UseMutationOptionsUtil} from 'App/utils/queryHelpers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useCreateSupplier = (options?: UseMutationOptionsUtil<typeof createSupplier>) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(createSupplier, {
    ...options,
    onSettled: (...args) => {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
      options?.onSettled?.(...args);
    }
  });

  return {createSupplier: mutate, isCreatingSupplier: isLoading};
};
