export const PAGE_OPTION_DEFAULTS = {
  page: 1,
  pageSize: 20
};

export const RFP_ALPHA_TEMPLATE = 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/rfp_table.csv';
export const RFP_OPTIMIZED_TEMPLATE =
  'https://shipwell-static.s3.us-west-2.amazonaws.com/csv-import-samples/rfp_lanes.csv';
export const DISCOUNT_PERCENTAGES_TEMPLATE =
  'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/discount_percentages_table.csv';
export const RATE_MINIMUMS_TEMPLATE =
  'https://shipwell-static.s3.us-west-2.amazonaws.com/csv-import-samples/rate_minimums_table.csv';
export const CORROGO_PURCHASE_OR_SALES_ORDERS_TEMPLATE =
  'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/purchase_or_sales_orders.csv';
