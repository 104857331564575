import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {SubmissionError} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import {fetchBrokerShipperRelationshipPromise} from 'App/api/brokers';
import * as actions from 'App/actions/brokers';
import * as userActions from 'App/actions/users';
import * as documentActions from 'App/actions/documents';
import {unpackErrors} from 'App/utils/globals';
import CustomerForm from 'App/containers/Settings/forms/CustomerForm';
import CustomerCarrierForm from 'App/containers/Settings/forms/CustomerCarrierForm';
import 'react-table-6/react-table.css';
import ShipwellLoader from 'App/common/shipwellLoader/index';

@connect(
  (state) => ({
    agents: state.brokers.agents,
    carriers: state.brokers.carriers,
    carrierRelationships: state.brokers.carrierRelationships,
    customerDocForm: state.form.customerDocForm,
    newCustomerDocForm: state.form.newCustomerDocForm,
    customerForm: state.form.customerForm,
    customerCarrierForm: state.form.customerCarrierForm,
    documentIsLoading: state.documents.isLoading,
    documentError: state.documents.error,
    shipperRelationships: state.brokers.shipperRelationships,
    isShipperRelationshipsLoading: state.brokers.isShipperRelationshipsLoading,
    user: state.auth.user,
    brokerUser: state.auth.brokerUser,
    company: state.auth.company,
    companyUsers: state.users.companyUsers,
    isCompanyUsersLoading: state.users.isCompanyUsersLoading,
    selectedCustomer: state.brokers.selectedCustomer,
    selectedShipperCarrierRelationships: state.brokers.selectedShipperCarrierRelationships,
    preferences: state.userCompany.preferences
  }),
  {...actions, ...documentActions, ...userActions}
)
export default class EditCustomer extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.handleSaveCarrierList = this.handleSaveCarrierList.bind(this);
    this.onDocumentFormSubmit = this.onDocumentFormSubmit.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.state = {
      documents: [],
      cancel: false,
      companyId: null,
      shipperRelationshipId: null,
      submittedForm: false,
      loading: false,
      saving: false,
      height: 0
    };
  }

  componentDidMount() {
    if (this.props.company && this.props.company.brokerage) {
      // set ids and fetch
      this.setState(
        {
          companyId: this.props.company.id,
          brokerageId: this.props.company.brokerage.id,
          loading: true
        },
        () => {
          this.getCarrierRelationship(this.props.company.brokerage.id, this.props.params.customer_id);
          this.fetchDocuments(this.props.params.customer_id);
          this.props
            .getShipperCarrierRelationships(this.props.company.brokerage.id, this.props.params.customer_id)
            .then((response) => {
              if (response.status === 200) {
                this.setState({loading: false});
              }
            });
        }
      );
      this.props.getCompanyUsers(this.props.company.id, {pageSize: 1000});
    }
    if (this.props.company.brokerage && this.props.params.customer_id) {
      this.getCarrierRelationship(this.props.company.brokerage.id, this.props.params.customer_id);
    }
  }

  componentDidUpdate(prevProps) {
    const {company, params} = this.props;
    if (company.brokerage && company !== prevProps.company) {
      // set ids and fetch
      this.setState(
        {
          companyId: this.props.company.id,
          brokerageId: this.props.company.brokerage.id,
          loading: true
        },
        () => {
          this.getCarrierRelationship(this.props.company.brokerage.id, this.props.params.customer_id);
          this.fetchDocuments(this.props.params.customer_id);
          this.props
            .getShipperCarrierRelationships(this.props.company.brokerage.id, this.props.params.customer_id)
            .then((response) => {
              if (response.status === 200) {
                this.setState({loading: false});
              }
            });
        }
      );
      this.props.getCompanyUsers(this.props.company.id, {pageSize: 1000});
    }
    if (company.brokerage && params.customer_id !== prevProps.params.customer_id) {
      this.getCarrierRelationship(this.props.company.brokerage.id, this.props.params.customer_id);
    }
  }

  async getCarrierRelationship(brokerId, relationshipId) {
    try {
      this.setState({loading: true});
      const response = await fetchBrokerShipperRelationshipPromise(brokerId, relationshipId);

      if (response && response.body) {
        this.props.selectCustomer(JSON.parse(JSON.stringify(response.body)));
        this.setState({loading: false});
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchDocuments(shipperRelationshipId) {
    this.props.fetchCustomerDocuments(this.state.brokerageId, shipperRelationshipId).then((response) => {
      this.setState({documents: response.details});
    });
  }

  handleDeleteDoc() {
    const attrs = this.props.customerDocForm.values;

    const brokerageId = this.state.brokerageId;
    const shipperRelationshipId = this.props.selectedCustomer.id;
    const documentId = attrs.id;

    return this.props.deleteCustomerDocument(brokerageId, shipperRelationshipId, documentId).then((response) => {
      if (response.status === 200) {
        this.setState({cancel: _.uniqueId('delete_')}, () => this.fetchDocuments(shipperRelationshipId));
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  onDocumentFormSubmit() {
    const attrs = this.props.customerDocForm.values;
    const shipperRelationshipId = this.props.selectedCustomer.id;
    const docForm = {
      description: attrs.description || '',
      file: attrs.files
    };

    if (!attrs.id) {
      // post new document
      return this.props
        .postCustomerDocument(docForm, this.state.brokerageId, shipperRelationshipId)
        .then((response) => {
          // fetch images and display add form
          this.setState({cancel: _.uniqueId('post_')}, () => this.fetchDocuments(shipperRelationshipId));
        });
    }
    // update the document
    return this.props
      .putCustomerDocument(this.state.brokerageId, this.props.selectedCustomer.id, attrs.id, docForm)
      .then((response) => {
        if (response.status === 200) {
          this.setState({cancel: _.uniqueId('put_')}, () => this.fetchDocuments(shipperRelationshipId));
        } else {
          const errors = response.field_errors || [];
          let submissionError = {};
          submissionError = unpackErrors(errors, submissionError);
          submissionError._error = response.error_description;
          throw new SubmissionError(submissionError);
        }
      });
  }

  handleSaveCarrierList(attrs) {
    const carriers = JSON.parse(JSON.stringify(attrs.customer_carrier));
    //go through the array of carriers, pull form fields to proper place before PUT
    for (var i = 0; i < carriers.length; i++) {
      //reverse enabled into hidden
      carriers[i].is_hidden = !carriers[i].enabled;
      //convert markup percentage from integer to decimal
      carriers[i].markup.ltl_markup_type = Number(carriers[i].markup.ltl_markup_type) === 1 ? 'flat_fee' : 'percentage';
      carriers[i].markup.ftl_markup_type = Number(carriers[i].markup.ftl_markup_type) === 1 ? 'flat_fee' : 'percentage';
      carriers[i].markup.ltl_markup_percentage = parseFloat(carriers[i].markup.ltl_markup_percentage * 100) / 10000;
      carriers[i].markup.ftl_markup_percentage = parseFloat(carriers[i].markup.ftl_markup_percentage * 100) / 10000;
      carriers[i].markup.ltl_markup_flat_fee = parseFloat(carriers[i].markup.ltl_markup_flat_fee) || 0;
      carriers[i].markup.ltl_markup_minimum = parseFloat(carriers[i].markup.ltl_markup_minimum) || 0;
      carriers[i].markup.ftl_markup_flat_fee = parseFloat(carriers[i].markup.ftl_markup_flat_fee) || 0;
    }
    return this.props
      .editShipperCarrierRelationships(this.props.company.brokerage.id, carriers[0].shipper_relationship, carriers)
      .then(
        function (response) {
          if (response.status === 200) {
            this.setState({saving: false}, () => {
              this.props.clearSelectedShipperCarrierRelationship();
              this.props.clearSelectedCustomer();
              this.context.router.push('/manage_customers');
            });
          } else {
            //error handling
            this.setState({saving: false});
            const errors = response.field_errors || [];
            let submissionError = {};
            submissionError = unpackErrors(errors, submissionError);
            submissionError._error = response.error_description;
            //handle edge cases for errors here
            throw new SubmissionError(submissionError);
          }
        }.bind(this)
      );
  }

  onFormSubmit(attrs) {
    const brokerageId = this.props.company.brokerage.id;
    const customerObj = {};
    customerObj.company = {};
    //build company object
    customerObj.company.name = attrs.company.name;
    customerObj.company.primary_phone_number = attrs.company.primary_phone_number;
    customerObj.company.primary_email = attrs.company.primary_email;
    customerObj.company.billing_email = attrs.company.billing_email;
    customerObj.company.mailing_address = attrs.company.mailing_address;
    customerObj.company.billing_address = attrs.company.mailing_address;
    if (
      customerObj.company.mailing_address &&
      customerObj.company.mailing_address.phone_number &&
      (customerObj.company.mailing_address.phone_number === 'null' ||
        customerObj.company.mailing_address.phone_number === null)
    ) {
      delete customerObj.company.mailing_address.phone_number;
    }
    if (
      customerObj.company.billing_address &&
      customerObj.company.billing_address.phone_number &&
      (customerObj.company.billing_address.phone_number === 'null' ||
        customerObj.company.billing_address.phone_number === null)
    ) {
      delete customerObj.company.billing_address.phone_number;
    }
    //default to brokerage company
    customerObj.company.brokerage = {};
    //build user object
    if (!attrs.id) {
      //on a put, we aren't updating the user object
      customerObj.user = {};
      customerObj.user.email = attrs.company.primary_email;
      customerObj.user.first_name = attrs.user.first_name;
      customerObj.user.last_name = attrs.user.last_name;
      customerObj.user.phone_number = attrs.company.primary_phone_number;
      //temporary set password here
    }
    if (attrs.company.is_3pl === true) {
      customerObj.company.brokerage.is_3pl = true;
    }
    //build the markup object
    customerObj.markup = {};
    if (attrs.markup.ftl_markup_flat_fee) {
      customerObj.markup.ftl_markup_flat_fee = attrs.markup.ftl_markup_flat_fee;
    } else {
      customerObj.markup.ftl_markup_flat_fee = 0;
    }
    if (attrs.markup.ftl_markup_percentage) {
      customerObj.markup.ftl_markup_percentage = (parseFloat(attrs.markup.ftl_markup_percentage) * 100) / 10000;
    } else {
      customerObj.markup.ftl_markup_percentage = 0;
    }
    customerObj.markup.ftl_markup_type = Number(attrs.markup.ftl_markup_type) === 1 ? 'flat_fee' : 'percentage';

    if (attrs.markup.ltl_markup_flat_fee) {
      customerObj.markup.ltl_markup_flat_fee = attrs.markup.ltl_markup_flat_fee;
    } else {
      customerObj.markup.ltl_markup_flat_fee = 0;
    }
    if (attrs.markup.ltl_markup_percentage) {
      customerObj.markup.ltl_markup_percentage = (parseFloat(attrs.markup.ltl_markup_percentage) * 100) / 10000;
    } else {
      customerObj.markup.ltl_markup_percentage = 0;
    }
    if (attrs.markup.ltl_markup_minimum) {
      customerObj.markup.ltl_markup_minimum = attrs.markup.ltl_markup_minimum;
    } else {
      customerObj.markup.ltl_markup_minimum = 0;
    }
    customerObj.markup.ltl_markup_type = Number(attrs.markup.ltl_markup_type) === 1 ? 'flat_fee' : 'percentage';
    //build the mode permissions object

    customerObj.can_book_with_rates = attrs.can_book_with_rates;

    customerObj.can_quote_with_rates = attrs.can_quote_with_rates;
    //include the credit limit
    customerObj.broker_imposed_credit_limit = attrs.broker_imposed_credit_limit;
    if (typeof customerObj.broker_imposed_credit_limit === 'string') {
      customerObj.broker_imposed_credit_limit = customerObj.broker_imposed_credit_limit.replace(/,/g, '');
    }
    //include sales and account reps

    //no display name key, list has not been modified
    if (attrs.sales_reps && attrs.sales_reps.length > 0 && attrs.sales_reps[0] && !attrs.sales_reps[0].display_name) {
      customerObj.sales_reps = [attrs.sales_reps[0]];
      //display name key, map to only submit id
    } else if (attrs.sales_reps && attrs.sales_reps[0] && attrs.sales_reps[0].display_name) {
      customerObj.sales_reps = attrs.sales_reps.map((rep) => rep.id);
    } else {
      customerObj.sales_reps = [];
    }
    if (attrs.account_reps && attrs.account_reps.length > 0 && !attrs.account_reps[0].display_name) {
      customerObj.account_reps = attrs.account_reps;
    } else if (attrs.account_reps && attrs.account_reps[0] && attrs.account_reps[0].display_name) {
      customerObj.account_reps = attrs.account_reps.map((rep) => rep.id);
    } else {
      customerObj.account_reps = [];
    }
    if (attrs.custom_customer_field_values && attrs.custom_customer_field_values.length > 0) {
      customerObj.custom_customer_field_values = [];

      for (let i = 0; i < this.props.preferences.custom_customer_fields.length; i++) {
        if (!_.isEmpty(attrs.custom_customer_field_values[i])) {
          customerObj.custom_customer_field_values.push({
            custom_customer_field: this.props.preferences.custom_customer_fields[i],
            value: attrs.custom_customer_field_values[i].value,
            id: attrs.custom_customer_field_values[i].id
          });
        }
      }
    }

    return this.props.editBrokerShipperRelationship(brokerageId, attrs.id, customerObj).then((response) => {
      if (response.status === 200) {
        //great job. go submit customer carrier stuff if there's a change to carriers
        //check for carriers that change and save the changed values
        let changedCarriers = [],
          newValues,
          oldValues,
          customerCarrierForm = this.props.customerCarrierForm;

        if (customerCarrierForm) {
          if (customerCarrierForm.values) {
            newValues = customerCarrierForm.values.customer_carrier;
          }
          if (customerCarrierForm.initial) {
            oldValues = customerCarrierForm.initial.customer_carrier;
          }
        }
        for (let i = 0; i < newValues.length; i++) {
          if (!_.isEqual(newValues[i], oldValues[i])) {
            changedCarriers.push({idx: i, value: newValues[i]});
          }
        }
        if (changedCarriers.length) {
          //get up to date carrier info, just in case changes like min markup was made
          this.props
            .getShipperCarrierRelationships(this.props.company.brokerage.id, this.props.params.customer_id)
            .then((response) => {
              if (response.status === 200) {
                //submit new up to date values + changes
                const formValues = JSON.parse(JSON.stringify(this.props.customerCarrierForm.values));
                for (let i = 0; i < changedCarriers.length; i++) {
                  formValues.customer_carrier[changedCarriers[i].idx] = changedCarriers[i].value;
                }
                this.customerCarrierForm.props.onSubmit(formValues);
              }
            });
        } else {
          this.props.clearSelectedShipperCarrierRelationship();
          this.props.clearSelectedCustomer();
          this.context.router.push('/manage_customers');
        }
      } else {
        this.setState({saving: false});
        const errors = response.field_errors || [];
        let submissionError = {user: {}, company: {}, markup: {}};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        if (submissionError.user) {
          if (submissionError.user.phone_number) {
            submissionError.company.primary_phone_number = submissionError.user.phone_number;
          }
          //user emails are in the form as company email

          if (submissionError.user.email) {
            submissionError.company.primary_email = submissionError.user.email;
          }
        }

        throw new SubmissionError(submissionError);
      }
    });
  }

  setHeight(height) {
    if (this.state.documents.results && this.state.documents.results.length) {
      this.setState({height: height + 'px'});
    } else {
      this.setState({height: height - 144 + 'px'});
    }
  }

  render() {
    return (
      <div className="content-wrapper manage-customers">
        <section className="content">
          <div className="manage-customers__editCustomerForm">
            <div className="manage-customers__content">
              <Paper className="manage-customers__card-form">
                <CustomerForm
                  repList={this.props.companyUsers.results}
                  documentError={this.props.documentError}
                  documents={this.state.documents}
                  handleDelete={this.handleDeleteDoc}
                  isLoading={this.props.documentIsLoading}
                  onDocumentFormSubmit={this.onDocumentFormSubmit}
                  onSubmit={this.onFormSubmit}
                  cancel={this.state.cancel}
                  isEdit
                  customFields={this.props.preferences ? this.props.preferences.custom_customer_fields : []}
                  ref={(customerForm) => {
                    this.customerForm = customerForm;
                  }}
                  setHeight={this.setHeight}
                />
              </Paper>
              <Paper className={'manage-customers__card-form' + (this.state.loading ? '-loading' : '')}>
                {this.state.loading ? (
                  <ShipwellLoader loading={this.state.loading} loadingText="Loading Carriers..." />
                ) : (
                  <div>
                    <CustomerCarrierForm
                      carriers={this.props.selectedShipperCarrierRelationships}
                      onSubmit={this.handleSaveCarrierList}
                      isEdit
                      ref={(customerCarrierForm) => {
                        this.customerCarrierForm = customerCarrierForm;
                      }}
                      selectedCustomer={this.props.selectedCustomer}
                      height={this.state.height}
                    />
                  </div>
                )}
              </Paper>
            </div>
            <div className="manage-customers__footer-btns">
              <Button
                onClick={() => {
                  this.props.clearSelectedShipperCarrierRelationship();
                  this.props.clearSelectedCustomer();
                  this.context.router.push('/manage_customers');
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.saving || this.state.loading}
                bsStyle="primary"
                onClick={() => {
                  this.setState({saving: true});
                  this.customerForm.props.onSubmit(this.props.customerForm.values);
                }}
              >
                {this.state.saving ? (
                  <span>
                    <i className="icon icon-Restart rotate" /> Saving...
                  </span>
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
