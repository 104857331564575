import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Panel, Button, ListGroup} from 'react-bootstrap';
import {placeholder} from 'App/utils/placeholders';
import * as documentActions from 'App/actions/documents';
import renderDropZone from 'App/formComponents/renderDropzone';
import validate from 'App/containers/Shipment/validateShipmentDocForm';
import DocumentsArray from 'App/components/DocumentsArray/DocumentsArray';
import renderTextArea from 'App/formComponents/renderTextArea';

@connect(
  (state) => ({
    newCustomerDocForm: state.form.newCustomerDocForm,
    documents: state.documents.documents
  }),
  {...documentActions}
)
class NewCustomerDocForm extends Component {
  constructor(props) {
    super(props);
    // clear documents
    this.props.selectNewCustomerDocAdd();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadDoc = this.handleUploadDoc.bind(this);
    this.setSelectedDoc = this.setSelectedDoc.bind(this);

    this.state = {
      showAddForm: false,
      documentsList: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.submittedForm &&
      nextProps.shipperRelationshipId &&
      nextProps.brokerageId &&
      this.props.submittedForm !== nextProps.submittedForm
    ) {
      // save documents
      this.saveDocuments(nextProps.brokerageId, nextProps.shipperRelationshipId);
    }
  }

  saveDocuments(brokerageId, shipperRelationshipId) {
    const documentsList = this.state.documentsList;

    documentsList.forEach((doc) => {
      const docForm = {
        description: doc.description,
        file: doc.fileComplete
      };

      this.props.postCustomerDocument(docForm, brokerageId, shipperRelationshipId);
    });
  }

  showAddForm() {
    this.setState({showAddForm: true});
  }

  handleUploadDoc() {
    this.showAddForm();
    this.props.selectDocAdd();
    this.setSelectedDoc();
  }

  handleCancel() {
    this.props.reset();
    this.setState({showAddForm: false});
  }

  formatName(name) {
    return name.replace(/ /g, '_');
  }

  handleSubmit() {
    const attrs = this.props.newCustomerDocForm.values;
    // save all documents until the new customer is saved
    const documentsList = this.state.documentsList;
    documentsList.push({
      description: attrs.description,
      file: attrs.files[0].preview,
      fileComplete: attrs.files[0],
      filename: this.formatName(attrs.files[0].name),
      id: _.uniqueId()
    });
    // reset the form
    this.props.reset();
  }

  removeImage(doc) {
    const documentsList = this.state.documentsList;
    const selectedDoc = documentsList.indexOf(doc);
    documentsList.splice(selectedDoc, 1);
    this.setState({documentsList: documentsList});
  }

  setSelectedDoc() {
    const scrollTo = document.querySelector('.js-scrollto-form');

    if (scrollTo) {
      _.defer(() => {
        scrollTo.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  render() {
    const {handleSubmit, pristine, error, isLoading, submitting} = this.props;
    const documentsList = this.state.documentsList;

    const footerButtons = (
      <div className="document-edit__footer">
        <Button
          bsStyle="default"
          type="button"
          disabled={submitting}
          onClick={this.handleCancel}
          className="cancel-edit"
          name="cancel-edit"
        >
          Cancel
        </Button>

        <Button className="btn btn-primary" disabled={submitting || pristine} type="submit">
          {isLoading ? (
            <span>
              <i className="icon icon-Restart rotate" />
              &nbsp;&nbsp; Uploading
            </span>
          ) : (
            'Upload'
          )}
        </Button>
      </div>
    );

    const renderShipperDocImages = () => {
      if (!documentsList) {
        return;
      }
      return (
        <DocumentsArray
          documents={documentsList}
          showTooltip
          tooltipContent="details"
          highlightBOL={false}
          handleDocumentClick={(id, e) => {
            const doc = documentsList.filter((doc) => doc.id === id)[0];
            this.removeImage(doc);
          }}
        />
      );
    };

    const uploadButton = (
      <Button disabled={this.state.showAddForm} bsStyle="link" className="btn-tertiary" onClick={this.handleUploadDoc}>
        <i className="flaticon-upload pad-right" />
        Upload a Document
      </Button>
    );

    return (
      <div className="document-edit document-edit__new clearfix">
        <h3 className="border-bottom">Documents</h3>

        <div className="doc-container">
          <p className="document-edit__message">{uploadButton}</p>
          {documentsList && documentsList.length > 0 && <ListGroup>{renderShipperDocImages()}</ListGroup>}
        </div>

        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          encType="multipart/form-data"
          className={`document-edit__form ${this.state.showAddForm ? 'show-flex' : 'hidden'}`}
        >
          <div className="document-edit__details">
            <Field
              name="description"
              type="text"
              minRows={3}
              component={renderTextArea}
              placeholder={placeholder.docDescription}
              req
              label="Description"
            />

            {footerButtons}

            {error && <p className="error-text text-center">{error}</p>}
          </div>

          {this.state.showAddForm && (
            <Panel className="document-edit__dropzone">
              <Field name="files" component={renderDropZone} id="dropzone-doc" />
            </Panel>
          )}
        </Form>
        <span className="js-scrollto-form" />
      </div>
    );
  }
}

NewCustomerDocForm = reduxForm({
  form: 'newCustomerDocForm',
  touchOnChange: true, // react-dropzone doesn't blur
  enableReinitialize: true,
  validate: validate
})(NewCustomerDocForm);

NewCustomerDocForm = connect((state) => ({
  initialValues: state.documents.selectedDocument
}))(NewCustomerDocForm);

export default NewCustomerDocForm;
