import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';
import {
  AvailabilityRequest,
  FacilityDock,
  FacilityDockAppointmentRule,
  LoadType,
  AppointmentStatusEnum
} from '@shipwell/tempus-sdk';
import {AxiosError} from 'axios';
import isNil from 'lodash/isNil';
import {noop} from 'lodash';

import {
  useFacilityDocksQuery,
  useFacilityQuery,
  useGetFacilityDockRules,
  useGetLoadTypes,
  useMutableFacilityHoursOfOperation
} from '../facilities';

import {computeAvailabilityWindows} from './utils/computeAvailabilityWindows';

import {getAvailability} from 'App/api/facilities';
import {FACILITY_DOCK_APPOINTMENT_AVAILABILITY_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  AppointmentAvailability,
  RankedAvailabilityWindow,
  RankedMappedDockType
} from 'App/data-hooks/appointments/types';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';
import {MappedDockType} from 'App/data-hooks/facilities/types';
import {ShipwellApiErrorResponse} from 'App/utils/errors';

const getAvailabilityRequest = ({
  isSupplierAppointment,
  startDateTime,
  endDateTime,
  supplierAppointmentFormData,
  appointmentId,
  dockId,
  shipmentId,
  stopId,
  appointmentStatus
}: GetAvailabilityRequest): AvailabilityRequest => {
  if (isSupplierAppointment) {
    return omitEmptyKeysWithEmptyObjectsRemoved({
      request_criteria_type: 'FREIGHT_GENERAL',
      dock_ids: !isNil(dockId) ? [dockId] : undefined,
      start_datetime: startDateTime || '',
      end_datetime: endDateTime || '',
      is_hazmat: supplierAppointmentFormData?.is_hazmat,
      stackable: supplierAppointmentFormData?.stackable,
      packaging_type: supplierAppointmentFormData?.packaging_type ?? undefined,
      product_reference: supplierAppointmentFormData?.product_reference ?? undefined,
      equipment_type: supplierAppointmentFormData?.equipment_type,
      mode: supplierAppointmentFormData?.mode,
      delivery_type: supplierAppointmentFormData?.delivery_type,
      rescheduling_for_appointment_id: appointmentId ?? undefined
    });
  }

  return omitEmptyKeysWithEmptyObjectsRemoved({
    request_criteria_type: 'SHIPMENT',
    start_datetime: startDateTime || '',
    end_datetime: endDateTime || '',
    dock_ids: !isNil(dockId) ? [dockId] : undefined,
    rescheduling_for_appointment_id:
      !appointmentId || appointmentStatus === AppointmentStatusEnum.Unscheduled ? undefined : appointmentId,
    shipment_id: shipmentId || '',
    stop_id: stopId || ''
  });
};

export type GetAvailabilityRequest = {
  appointmentId?: string | null;
  facilityId?: string | null;
  dockId?: string | null;
  shipmentId?: string | null;
  stopId?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  isSupplierAppointment?: boolean;
  supplierAppointmentFormData?: SupplierAppointmentCreationFormType;
  appointmentStatus?: AppointmentStatusEnum;
};
/**
 * Creates an availability request directly to a Dock or a Facility based on the presence of the dockId.
 * The windows will be sorted from descending to ascending order in output by startDate
 */
export type UseAvailabilityQueryOptions = Omit<
  UseQueryOptions<
    AppointmentAvailability,
    AxiosError<ShipwellApiErrorResponse>,
    AppointmentAvailability,
    (string | null | undefined)[]
  >,
  'queryFn' | 'queryKey'
>;

const EmptyLoadTypes: LoadType[] = [];
const EmptyDocks: FacilityDock[] = [];
const EmptyDockRules: FacilityDockAppointmentRule[] = [];

export const useAvailabilityQuery = (
  {
    facilityId,
    dockId,
    shipmentId,
    stopId,
    startDateTime,
    endDateTime,
    appointmentId,
    isSupplierAppointment,
    supplierAppointmentFormData,
    appointmentStatus
  }: GetAvailabilityRequest,
  options?: UseAvailabilityQueryOptions
): UseQueryResult<AppointmentAvailability> & {
  isAvailabilityInitialLoading: boolean;
} => {
  const facilityQuery = useFacilityQuery({facilityId});
  const facility = facilityQuery.facility;
  const loadTypesQuery = useGetLoadTypes(facilityId ?? '');
  const loadTypes = loadTypesQuery.data ?? EmptyLoadTypes;
  const docksQuery = useFacilityDocksQuery(facilityId ?? '');
  const facilityDocks = docksQuery.data ?? EmptyDocks;
  const dockRulesQuery = useGetFacilityDockRules(facilityDocks, facilityId ?? '');
  const dockRules = dockRulesQuery.data ?? EmptyDockRules;
  const hoursQuery = useMutableFacilityHoursOfOperation(facilityId ?? '', {onError: noop});
  const hours = hoursQuery.hours;
  const isInitialLoading =
    hoursQuery.isLoading ||
    dockRulesQuery.isRulesQueriesLoading ||
    docksQuery.isLoading ||
    loadTypesQuery.isLoading ||
    facilityQuery.isFacilityLoading;

  let facilityOpens = facility?.first_appointment_start_time ?? '24:00:00';
  let facilityCloses = facility?.last_appointment_end_time ?? '00:00:00';
  for (const h of hours) {
    if (h.open_time && h.open_time < facilityOpens) {
      facilityOpens = h.open_time;
    }
    if (h.close_time && h.close_time > facilityCloses) {
      facilityCloses = h.close_time;
    }
  }

  const enabled =
    Boolean(facility) &&
    Boolean(facilityId) &&
    Boolean(startDateTime) &&
    Boolean(endDateTime) &&
    (options?.enabled ?? true) &&
    !isInitialLoading;

  const formattedDocksWithDockRules: MappedDockType[] = facilityDocks.map((dock) => {
    const filteredDockRules = dockRules.filter((rule) => rule.dock_id === dock.id);
    return {
      ...dock,
      dockRules: filteredDockRules
    };
  });

  const query = useQuery(
    [
      FACILITY_DOCK_APPOINTMENT_AVAILABILITY_QUERY_KEY,
      facilityId,
      dockId,
      shipmentId,
      stopId,
      startDateTime,
      endDateTime
    ],
    async () => {
      if (isNil(facilityId)) {
        throw new Error('Cannot get availability without facility id');
      }
      if (isNil(startDateTime) || isNil(endDateTime)) {
        throw new Error('Cannot get availability without date range.');
      }

      const requestPayload = getAvailabilityRequest({
        appointmentId,
        dockId,
        shipmentId,
        stopId,
        startDateTime,
        endDateTime,
        isSupplierAppointment,
        supplierAppointmentFormData,
        appointmentStatus
      });
      const {available_windows, load_type_dock_rule_match_results} = await getAvailability(facilityId, requestPayload);

      if (isNil(available_windows) || !available_windows.length) {
        return {
          stillLoading: false,
          timezone: '',
          windows: [] as RankedAvailabilityWindow[],
          docks: [] as RankedMappedDockType[],
          bestLoadTypeId: undefined,
          isAllDay: false
        } as AppointmentAvailability;
      }

      /** Note that loadTypeIds might be nullish for supplier appointments or other appointment types in future. */
      const loadTypeIds = load_type_dock_rule_match_results?.matched_load_type_ids ?? [];
      const matchedDocks = load_type_dock_rule_match_results?.matched_docks ?? [];

      const {windows, docks, isAllDay} = computeAvailabilityWindows({
        timezone: facility?.address?.timezone || 'UTC',
        facilityOpens,
        facilityCloses,
        loadTypes,
        docks: formattedDocksWithDockRules,
        matchedLoadTypeIds: loadTypeIds,
        matchedDockIds: matchedDocks.map(({dock_id}) => dock_id),
        requiredDockId: dockId ?? undefined,
        rawWindows: available_windows,
        supplierAppointmentDuration: supplierAppointmentFormData?.appointment_duration,
        now: new Date()
      });

      return {
        stillLoading: false,
        timezone: facility?.address?.timezone || 'UTC',
        docks,
        windows,
        isAllDay
      };
    },
    {
      ...options,
      enabled
    }
  );

  return {
    ...query,
    isAvailabilityInitialLoading: isInitialLoading || query.isInitialLoading
  };
};
