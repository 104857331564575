import {DeprecatedButton, DisplayValue, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useRef, ReactNode} from 'react';
import {Stop} from '@shipwell/backend-core-sdk';
import classNames from 'classnames';
import {useOverflow} from 'use-overflow';
import pluralize from 'pluralize';
import {FreightInvoiceFormProps} from './FreightInvoiceForm';
import {generateShipmentLineItemRow} from './utils';
import {usePackageTypes} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import {getNumOutlinedSvgIconName} from 'App/common';

//this is a vertical line that connects the stop icons
const StopConnectorLine = () => <div className="ml-3 h-[39px] w-px bg-sw-border" />;

const SectionDividerBorder = () => <div className="my-4 w-full border-b-1 border-sw-divider" />;

const StopIcon = ({
  stopIndex,
  isLastStop,
  isFirstStop
}: {
  stopIndex: number;
  isLastStop?: boolean;
  isFirstStop: boolean;
}) => {
  const numOutlinedSvgIconName = getNumOutlinedSvgIconName(stopIndex);
  return (
    <div className="flex flex-col justify-center">
      <SvgIcon name={numOutlinedSvgIconName} className={classNames({'mb-3': !isFirstStop})} />
      {!isLastStop ? <StopConnectorLine /> : null}
    </div>
  );
};
const StopAddress = ({stop, expanded}: {stop: Stop; expanded?: boolean}) => {
  const truncatedStopDisplayValues = [
    `${stop.location?.address?.city ? `${stop.location?.address?.city},` : ''}`,
    stop.location?.address?.state_province,
    stop.location?.address?.postal_code
  ]
    //filter out empty address parts to prevent empty join spaces
    .filter((addressPart) => !!addressPart)
    .join(' ')
    .trim();
  return (
    <div className="ml-2 flex flex-col items-start">
      <DisplayValue
        className="mb-0"
        label={stop.is_pickup ? 'Pickup' : stop.is_dropoff ? 'Delivery' : '--'}
      ></DisplayValue>
      <div>{expanded ? stop.location?.address?.formatted_address : truncatedStopDisplayValues}</div>
    </div>
  );
};

const LINE_ITEM_HEADERS = ['QTY', 'DESCRIPTION', 'PACKAGING', 'WEIGHT'];

const ShipmentLineItemsEmptyState = () => (
  <div className="flex min-h-40 items-center justify-center">
    <div className="text-lg text-sw-form-helper-text">No Line Items</div>
  </div>
);

type ShipmentSummaryProps = Pick<FreightInvoiceFormProps, 'stops' | 'shipmentLineItems' | 'customerReferenceNumber'> & {
  customerName?: string;
};
const LineItemDisplayCell = ({children}: {children: ReactNode}) => {
  const horizontalRef = useRef(null);
  const {refXOverflowing} = useOverflow(horizontalRef);
  return refXOverflowing ? (
    <Tooltip placement="top" wrapperClassname="w-full" tooltipContent={children}>
      <div ref={horizontalRef} className="w-full truncate">
        {children}
      </div>
    </Tooltip>
  ) : (
    <div ref={horizontalRef}>{children}</div>
  );
};
const ShipmentLegInfo = ({stops, shipmentLineItems, customerName, customerReferenceNumber}: ShipmentSummaryProps) => {
  const {data: packageTypes} = usePackageTypes();
  const [stopsExpanded, toggleStopsExpanded] = useToggle(false);
  const [lineItemsExpanded, toggleLineItemsExpanded] = useToggle(false);

  const showExpandStopsBtn = stops && stops?.length > 2;
  const additionalStopsCount = showExpandStopsBtn ? stops.length - 2 : 0;
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <DisplayValue label="Customer">{customerName || '--'}</DisplayValue>
        <DisplayValue label="Customer Reference #">{customerReferenceNumber || '--'}</DisplayValue>
      </div>
      <SectionDividerBorder />
      <div className="flex items-center">
        <div className="text-md font-bold">Stops ({stops?.length || 0})</div>
        {showExpandStopsBtn ? (
          <DeprecatedButton onClick={() => toggleStopsExpanded()} variant="tertiary" size="small" className="text-bold">
            {stopsExpanded ? 'Collapse Stops' : `+${pluralize('Stop', additionalStopsCount, true)}`}
          </DeprecatedButton>
        ) : null}
      </div>
      <div className="mt-2">
        {stops?.map((stop, stopIndex, stops) => {
          const lastStopIndex = stops.length - 1;
          const isLastStop = stopIndex === lastStopIndex;
          const isFirstStop = stopIndex === 0;

          if (!stopsExpanded && !isFirstStop && !isLastStop) {
            return null;
          }

          return (
            <div key={stop.id} className="flex flex-row">
              <StopIcon stopIndex={stopIndex} isLastStop={isLastStop} isFirstStop={isFirstStop} />
              <StopAddress stop={stop} expanded={stopsExpanded} />
            </div>
          );
        })}
        <SectionDividerBorder />
        <div className="flex flex-row">
          <DeprecatedButton
            onClick={() => toggleLineItemsExpanded()}
            icon={<SvgIcon name={lineItemsExpanded ? 'ExpandLess' : 'ExpandMore'} color="$sw-primary" />}
            variant="tertiary"
            size="small"
          >
            {lineItemsExpanded ? 'Hide Items' : 'Show Items'}
          </DeprecatedButton>
        </div>
        {lineItemsExpanded ? (
          <>
            <SectionDividerBorder />
            <div className="text-md font-bold">Items</div>
            {shipmentLineItems?.length && shipmentLineItems.length > 0 ? (
              <div className="grid grid-cols-[10%,30%,30%,30%] items-center justify-items-start gap-x-1">
                {LINE_ITEM_HEADERS.map((header: string) => (
                  <div key={header} className="pt-2 text-xxs">
                    {header}
                  </div>
                ))}
                {shipmentLineItems?.map((lineItem) => {
                  const shipmentLineItemDisplayValues = generateShipmentLineItemRow(lineItem, packageTypes);
                  return shipmentLineItemDisplayValues.map((dataToDisplay) => (
                    <div className="w-full truncate text-left" key={lineItem.id}>
                      <LineItemDisplayCell>{dataToDisplay}</LineItemDisplayCell>
                    </div>
                  ));
                })}
              </div>
            ) : (
              <ShipmentLineItemsEmptyState />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ShipmentLegInfo;
