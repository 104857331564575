import {Field, useFormikContext} from 'formik';
import {SvgIcon, FormikTextInput} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

export const UserRateTableFormFields = () => {
  const {values} = useFormikContext<{
    calculation_rows: {fuel_surcharge?: number}[];
  }>();
  const calculationRows = values.calculation_rows;

  return (
    <FlexBox direction="col" gap="s">
      {calculationRows.map((_, i) => (
        <FlexBox items="center" gap="s" key={i}>
          <SvgIcon name="ArrowForward" className="min-w-[24px]" color="$sw-icon" width="24" />
          <Field
            name={`calculation_rows[${i}].fuel_surcharge`}
            label="Rate"
            component={FormikTextInput}
            required
            className="w-full"
          />
        </FlexBox>
      ))}
    </FlexBox>
  );
};
