import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Button, Row, Col} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import validate from 'App/utils/validatePowerUnitForm';
import {states} from 'App/utils/globals';

let PowerUnitForm = (props) => {
  const {handleSubmit, submitting, cancel} = props;
  return (
    <Form onSubmit={handleSubmit} className="formstyle-light power-unit-form">
      <Paper className="power-unit-form-card">
        <Row>
          <Col sm={6}>
            <Field
              label="power unit name"
              name="name"
              component={renderField}
              type="text"
              placeholder="e.g. Mac-123456"
              req
            />
          </Col>
          <Col sm={6}>
            <Field label="vin#" name="vin" component={renderField} type="text" placeholder="VIN#" />
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Field label="Vehicle" name="year" component={renderField} placeholder="Year" />
          </Col>
          <Col sm={5}>
            <Field name="make" component={renderField} type="text" placeholder="Make" />
          </Col>
          <Col sm={5}>
            <Field name="model" component={renderField} type="text" placeholder="Model" />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              label="description"
              name="description"
              component={renderField}
              type="text"
              placeholder="Description"
            />
          </Col>
          <Col sm={6}>
            <Field
              label="Odometer (miles)"
              name="distance_traveled_miles"
              component={renderField}
              type="text"
              placeholder="Distance"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              label="License"
              name="license_plate_state_province"
              component={renderDropDownSelector}
              type="text"
              placeholder="Issuing State/Province"
              data={states}
            />
          </Col>
          <Col sm={6}>
            <Field name="license_plate_number" component={renderField} type="text" placeholder="License plate number" />
          </Col>
        </Row>
      </Paper>
      <div className="equipment__footer-btns">
        <Button bsStyle="default" disabled={submitting} onClick={cancel}>
          Cancel
        </Button>
        <Button bsStyle="primary" disabled={submitting} type="submit">
          {submitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

PowerUnitForm = reduxForm({
  form: 'powerUnitForm',
  validate: validate,
  enableReinitialize: true
})(PowerUnitForm);

PowerUnitForm = connect((state) => ({
  initialValues: state.carriers.powerUnit
}))(PowerUnitForm);

export default PowerUnitForm;
