import {useState, useEffect} from 'react';
import ReactTable from 'react-table-6';
import PropTypes from 'prop-types';
import {TextInput, DeprecatedButton, Tooltip} from '@shipwell/shipwell-ui';
import {updateImportValue} from 'App/api/imports';
import ShipwellLoader from 'App/common/shipwellLoader';
import {cleanPayload} from 'App/utils/globals';

const RowDataTable = (props) => {
  const {
    importId,
    columns,
    rows,
    onCancel,
    createJob,
    updateColumnWidths,
    readyToValidate,
    validationErrors,
    fetchRowData,
    totalPages,
    skipValidation
  } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateValue = async (value, cellDetails) => {
    const newValue = {...cellDetails, value};
    try {
      await updateImportValue(importId, newValue.id, cleanPayload(newValue));
      //clear errors when updating value
      if (errors && errors.find((e) => e.value && e.value.id === cellDetails.id)) {
        const newErrors = [...errors];
        const ind = errors.findIndex((e) => e.value && e.value.id === cellDetails.id);
        newErrors.splice(ind, 1);
        setErrors(newErrors);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
    }
  }, [validationErrors]);

  useEffect(() => {
    setLoading(false);
  }, [rows]);

  useEffect(() => {
    setLoading(true);
    //refetch data from the import /rows/ endpoint when the pagination changes
    fetchRowData(importId, {page: page + 1, pageSize});
  }, [page]);

  useEffect(() => {
    setLoading(true);
    setPage(0);
    //refetch data from the import /rows/ endpoint when the pagination changes
    //when pageSize changes, always go back to page 1
    fetchRowData(importId, {page: 1, pageSize});
  }, [pageSize]);

  //use a standard column template for all columns
  const tableColumns = columns.map((column) => {
    return {
      Header: column.sheet_column_name,
      id: column.id,
      width: 190,
      accessor: (d) => d.values,
      // eslint-disable-next-line react/display-name
      Cell: (row) => {
        const cellDetails = row.value.find((e) => e.column_mapping === column.id);
        const hasError = errors && errors.find((e) => e.value && e.value.id === cellDetails.id);
        const errorMessage = hasError ? hasError.errors : null;
        return (
          <TextInput
            name={cellDetails.id}
            onBlur={(e) => {
              if (cellDetails.value !== e.target.value) {
                updateValue(e.target.value, cellDetails);
              }
            }}
            value={cellDetails.value}
            error={hasError ? errorMessage : null}
          />
        );
      }
    };
  });
  tableColumns.unshift({
    Header: '',
    headerClassName: 'imports__rowDataTable-errorHeader',
    id: 'errors',
    width: 30,
    accessor: (d) => d.values,
    className: 'imports__rowDataTable-error',
    Cell: (row) => {
      const rowDetails = row.original;
      const hasRowError = errors && errors.find((e) => e.row?.id === rowDetails.id && !e.value);
      if (hasRowError) {
        return (
          <Tooltip arrow={false} placement="right" tooltipContent={hasRowError.errors.replace(/_/g, ' ')}>
            <i className="material-icons text-danger">error_outline</i>
          </Tooltip>
        );
      }
      return '';
    }
  });

  return (
    <div className="imports__rowDataTable">
      <h4>
        <b>Data to Upload</b>
      </h4>

      <ReactTable
        manual
        className="sw-table -highlight -striped"
        pageSizeOptions={[20, 50, 100]}
        LoadingComponent={ShipwellLoader}
        loading={loading}
        previousText={<i className="icon icon-Left" />}
        nextText={<i className="icon icon-Right" />}
        page={page}
        pageSize={pageSize}
        pages={totalPages}
        sortable={false}
        onPageChange={(pageIndex) => setPage(pageIndex)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        data={rows}
        columns={tableColumns}
        minResizeWidth={190}
        onResizedChange={(newResized) => {
          updateColumnWidths(newResized);
        }}
      />
      <div className="imports__form-footer fullscreen">
        <DeprecatedButton variant="secondary" onClick={onCancel}>
          Cancel
        </DeprecatedButton>
        <DeprecatedButton
          disabled={!readyToValidate || isSubmitting}
          loading={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            try {
              await fetchRowData(importId, {page: page + 1, pageSize});
              createJob(importId, !skipValidation);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Validate
        </DeprecatedButton>
      </div>
    </div>
  );
};

RowDataTable.propTypes = {
  importId: PropTypes.string,
  columns: PropTypes.arrayOf({id: PropTypes.string, sheet_column_name: PropTypes.string}),
  rows: PropTypes.arrayOf({
    id: PropTypes.string,
    values: PropTypes.arrayOf({
      id: PropTypes.string,
      column_mapping: PropTypes.string,
      row: PropTypes.string,
      value: PropTypes.string
    })
  }),
  onCancel: PropTypes.func,
  createJob: PropTypes.func,
  updateColumnWidths: PropTypes.func,
  readyToValidate: PropTypes.bool,
  validationErrors: PropTypes.arrayOf({
    id: PropTypes.string,
    row: PropTypes.string,
    errors: PropTypes.string,
    value: PropTypes.shape({
      id: PropTypes.string,
      column_mapping: PropTypes.string,
      row: PropTypes.string,
      value: PropTypes.string
    })
  }),
  fetchRowData: PropTypes.func,
  totalPages: PropTypes.number,
  skipValidation: PropTypes.bool
};

export default RowDataTable;
