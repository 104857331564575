import {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Toast} from '@shipwell/shipwell-ui';
import {quoting} from 'App/api';
import CarrierBidForm from 'App/formComponents/forms/carrierBid';
import {cleanPayload, unpackErrors} from 'App/utils/globals';
import './styles.scss';

/**
 * Contract Creation
 * @param {*} props
 */
const CarrierBidCreateContainer = (props) => {
  const {onCancel, onSubmitSuccess, values, isEdit, shipment, readOnly, isConfirmation} = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  /**
   * Create contract
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSave = async (values, {setSubmitting, setErrors}) => {
    setSubmitting(true);
    try {
      values = cleanPayload(values);
      values.notes = values.notes || '';
      values.available_date = moment(values.available_date).isValid()
        ? moment(values.available_date).format('YYYY-MM-DD')
        : null;
      values.usdot_number = values.carrier.usdot || null;
      values.mc_number = values.carrier.mc_number || null;
      values.carrier_name = values.carrier.name;

      values.shipment = shipment.id;
      if (values.id && isEdit) {
        await quoting.updateCarrierBid(values.id, values);
      } else {
        await quoting.createCarrierBid(values);
      }
      if (onSubmitSuccess) {
        onSubmitSuccess(values?.preventBook);
      }
      if (!isConfirmation) {
        setShowSuccess(true);
        setSubmitting(false);
      }
    } catch (error) {
      setShowError(error.error_description);

      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}, []));
      }
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <CarrierBidForm
        values={{
          equipment_type: shipment.equipment_type ? shipment.equipment_type.id : null,
          ...values
        }}
        readOnly={readOnly}
        edit={isEdit}
        isConfirmation={isConfirmation}
        onCancel={handleCancel}
        onSubmit={handleSave}
      />
      <Toast show={showSuccess} title="Success!" anchor="bottom-right" onClose={() => setShowSuccess(false)}>
        Carrier bid successfully created.
      </Toast>
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </>
  );
};

CarrierBidCreateContainer.propTypes = {
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  values: PropTypes.object,
  shipment: PropTypes.object,
  isEdit: PropTypes.bool,
  isConfirmation: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default connect((state) => ({
  user: state.auth.user,
  company: state.auth.company
}))(CarrierBidCreateContainer);
