import {AxiosError} from 'axios';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useQueries,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import {
  FacilityDock,
  LoadType,
  FacilityDockAppointmentRule,
  ShipwellApiErrorResponse,
  DeliveryTypeEnum,
  DockSchedulingModeEnum,
  DockSchedulingEquipmentTypeEnum,
  CreateFacilityDockAppointmentRule,
  UpdateFacilityDock,
  CreateFacilityDock,
  UpdateFacilityDockAppointmentRule,
  PackagingTypes
} from '@shipwell/tempus-sdk';
import {isEmpty, omit} from 'lodash';

import {useGetLoadTypes} from './useLoadTypes';
import {DockRuleDraft, DockTypeDraft, MappedDockType} from 'App/data-hooks/facilities/types';

import {
  getFacilityDocks,
  getFacilityDockRules,
  updateFacilityDockName,
  updateFacilityDockRule,
  createDock,
  createDockRules,
  deleteFacilityDock,
  deleteFacilityDockRule
} from 'App/api/facilities';
import {FACILITY_DOCKS_QUERY_KEY, FACILITY_DOCK_RULES} from 'App/data-hooks/queryKeys';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

export declare type FacilityDocksQueryOptions = UseQueryOptions<FacilityDock[], AxiosError<ShipwellApiErrorResponse>>;

type FacilityDockRulesOptions = UseQueryOptions<FacilityDockAppointmentRule[], AxiosError<ShipwellApiErrorResponse>>;
type FacilityLoadTypeQueryOptions = UseQueryOptions<LoadType[], AxiosError<ShipwellApiErrorResponse>>;

export const useFacilityDocksQuery = (facilityId?: string, queryOptions?: FacilityDocksQueryOptions) => {
  const getFacilityDocksQuery = useQuery(
    [FACILITY_DOCKS_QUERY_KEY, facilityId] as QueryKey,
    async () => {
      if (!facilityId) {
        return [];
      }
      const docks = await getFacilityDocks(facilityId);
      return docks;
    },
    {
      enabled: !!facilityId,
      ...queryOptions
    }
  );
  return getFacilityDocksQuery;
};

export const useGetFacilityDockRules = (
  docks: FacilityDock[],
  facilityId: string,
  queryOptions?: FacilityDockRulesOptions[]
) => {
  const facilityDockRulesQueries = useQueries({
    queries: docks.map((dock) => ({
      queryKey: [FACILITY_DOCK_RULES, dock.id],
      queryFn: async () => {
        if (!dock.id) {
          return;
        }
        const res = await getFacilityDockRules(facilityId, dock.id);
        return res;
      },
      enabled: !isEmpty(docks),
      ...queryOptions
    }))
  });
  const rulesQueriesData = facilityDockRulesQueries.map((query) => (query.data ? query.data : [])).flat();
  const isRulesQueriesLoading = facilityDockRulesQueries.some((query) => query.isLoading);
  return {data: rulesQueriesData, isRulesQueriesLoading};
};

export const useGetDockDetails = (
  facilityId: string,
  queryOptions?: UseQueryOptions<
    FacilityDock[] | LoadType[] | FacilityDockAppointmentRule[],
    AxiosError<ShipwellApiErrorResponse>
  >
) => {
  // fetch LT's
  const {data: fetchedLoadTypes, isLoading: isLoadTypesLoading} = useGetLoadTypes(facilityId, {
    ...(queryOptions as FacilityLoadTypeQueryOptions)
  });

  // fetch plain docks
  const {data: fetchedDocks, isLoading: isDocksLoading} = useFacilityDocksQuery(facilityId, {
    ...(queryOptions as FacilityDocksQueryOptions)
  });
  // fetch dock rules
  const {data: fetchedDockRules, isRulesQueriesLoading} = useGetFacilityDockRules(fetchedDocks || [], facilityId);
  const mappedDocks: MappedDockType[] = [];
  // traverse through each fetchedDock and construct the list needed for the form component
  fetchedDocks?.forEach((dock) => {
    const formattedDockRulesWithLoadTypesMapped: FacilityDockAppointmentRule[] = [];
    const targetDockRules = fetchedDockRules?.filter((fetchedDockRule) => fetchedDockRule?.dock_id === dock.id) || [];
    targetDockRules.forEach((targetDockRule) => {
      const targetLoadType = fetchedLoadTypes?.find(
        (fetchedLoadType) => fetchedLoadType.id === targetDockRule.load_type_id
      );
      const hasLoadTypeId = !!targetDockRule.load_type_id;
      formattedDockRulesWithLoadTypesMapped.push({
        ...targetDockRule,
        id: targetDockRule.id,
        load_type_id: targetDockRule.load_type_id ?? '',
        delivery_type: hasLoadTypeId
          ? (targetLoadType?.delivery_type as DeliveryTypeEnum)
          : (targetDockRule.delivery_type as DeliveryTypeEnum),
        mode: hasLoadTypeId
          ? (targetLoadType?.mode as DockSchedulingModeEnum)
          : (targetDockRule?.mode as DockSchedulingModeEnum),
        equipment_type: hasLoadTypeId
          ? (targetLoadType?.equipment_type as DockSchedulingEquipmentTypeEnum)
          : (targetDockRule?.equipment_type as DockSchedulingEquipmentTypeEnum),
        product_reference: hasLoadTypeId ? targetLoadType?.product_reference : targetDockRule?.product_reference,
        packaging_type: hasLoadTypeId ? targetLoadType?.packaging_type : targetDockRule?.packaging_type,
        product_category: hasLoadTypeId ? targetLoadType?.product_category : []
      });
    });
    mappedDocks.push({
      id: dock.id,
      name: dock.name,
      color: dock.color,
      dockRules: formattedDockRulesWithLoadTypesMapped
    });
  });

  const isGetDockDetailsLoading = isRulesQueriesLoading || isLoadTypesLoading || isDocksLoading;
  return {formattedDocksWithDockRules: mappedDocks, isGetDockDetailsLoading, fetchedLoadTypes};
};

/**
 * A function that takes the fetched data and submitted data for everything docks+dockRules
 * then decouples them and returns docks and dockRules that need to be created/updated/deleted
 */
export const formatDocksDetailsPayload = ({
  fetchedDocksWithDockRules,
  submittedDocksWithDockRules
}: {
  fetchedDocksWithDockRules: MappedDockType[];
  submittedDocksWithDockRules: (MappedDockType | DockTypeDraft)[];
}) => {
  /**
   * Find docks whose names need to be updated
   */
  const toBeUpdatedDocks = submittedDocksWithDockRules
    .filter((submittedDockWithDockRules) => {
      if ('id' in submittedDockWithDockRules) {
        // find the corresponding dockWithDockRules from the fetched list
        const matchingFetchedDockWithDockRules = fetchedDocksWithDockRules?.find(
          (fetchedDockWithDockRules) => fetchedDockWithDockRules.id === submittedDockWithDockRules.id
        );
        // found a match, check whether it was updated
        if (matchingFetchedDockWithDockRules) {
          return (
            JSON.stringify(matchingFetchedDockWithDockRules.name) !== JSON.stringify(submittedDockWithDockRules.name)
          );
        }
      }
      return false;
    })
    .map((item) => {
      if ('id' in item) {
        return {id: item.id, name: item.name, color: item.color};
      }
    }) as MappedDockType[];
  /**
   * Find docks that need to be created
   */
  const toBeCreatedDocks = submittedDocksWithDockRules?.filter(
    (submittedDockWithDockRules) => !('id' in submittedDockWithDockRules)
  );

  /**
   * Find docks that need to be deleted
   */
  const submittedDocksWithIds = submittedDocksWithDockRules?.filter(
    (submittedDockWithDockRules) => 'id' in submittedDockWithDockRules
  ) as MappedDockType[];
  const submittedDocksIds = submittedDocksWithIds.map((submittedDock) => submittedDock.id);
  const toBeDeletedDocks = fetchedDocksWithDockRules?.filter(
    (fetchedDockWithDockRules) => !submittedDocksIds.includes(fetchedDockWithDockRules.id)
  );

  /**
   * Dock Rules
   */

  // extract + flatten the dock rules list for simplicity, since they're nested within docks.
  const fetchedDockRules = fetchedDocksWithDockRules
    .map((fetchedDockWithDockRules) => fetchedDockWithDockRules.dockRules)
    .flat();

  const submittedDockRules = submittedDocksWithDockRules
    .map((submittedDockWithDockRules) => submittedDockWithDockRules.dockRules)
    .flat();

  const fetchedDockRulesFormatted = fetchedDockRules.map((fetchedDockRule) =>
    omitEmptyKeysWithEmptyObjectsRemoved(formatDockRuleToOriginalStructure(fetchedDockRule))
  ) as FacilityDockAppointmentRule[];

  const submittedDockRulesFormatted = submittedDockRules.map((submittedDockRule) =>
    omitEmptyKeysWithEmptyObjectsRemoved(formatDockRuleToOriginalStructure(submittedDockRule))
  );

  /**
   * Find dock rules that need to be updated
   */

  const toBeUpdatedDockRules = submittedDockRulesFormatted?.filter((submittedDockRule) => {
    if ('id' in submittedDockRule) {
      const matchingFetchedDockRule = fetchedDockRulesFormatted.find(
        (fetchedDockRule) => fetchedDockRule.id === submittedDockRule.id
      );
      if (matchingFetchedDockRule) {
        return filterAndStringifyRule(matchingFetchedDockRule) !== filterAndStringifyRule(submittedDockRule);
      }
      return false;
    }
  }) as FacilityDockAppointmentRule[];

  /**
   * Find dock rules that need to be created
   */

  const toBeCreatedDockRules = submittedDockRulesFormatted
    .filter((submittedDockRule) => !('id' in submittedDockRule))
    .map((dockRule) => {
      if ('dock_id' in dockRule) {
        return {...dockRule, dock_id: dockRule.dock_id};
      }

      return {...dockRule};
    }) as DockRuleDraft[];

  /**
   * Find dock rules that need to be deleted
   */
  const submittedDockRulesWithIds = submittedDockRules?.filter(
    (submittedDockRule) => 'id' in submittedDockRule
  ) as FacilityDockAppointmentRule[];
  const submittedDockRuleIds = submittedDockRulesWithIds.map((submittedDockRule) => submittedDockRule.id);
  const toBeDeletedDockRules = fetchedDockRules?.filter(
    (fetchedDockRule) => !submittedDockRuleIds.includes(fetchedDockRule.id)
  );
  return {
    toBeUpdatedDocks,
    toBeCreatedDocks,
    toBeDeletedDocks,
    toBeUpdatedDockRules,
    toBeCreatedDockRules,
    toBeDeletedDockRules
  };
};

/* format the submitted dock rules so that the ones with a non-null load_type_id 
    makes the load_type fields null, as required by the API */
const formatDockRuleToOriginalStructure = (
  formattedDockRule: FacilityDockAppointmentRule | DockRuleDraft
): FacilityDockAppointmentRule | CreateFacilityDockAppointmentRule => {
  return formattedDockRule?.load_type_id
    ? {
        ...formattedDockRule,
        delivery_type: '' as DeliveryTypeEnum,
        mode: '' as DockSchedulingModeEnum,
        equipment_type: '' as DockSchedulingEquipmentTypeEnum,
        product_reference: '',
        packaging_type: '' as PackagingTypes,
        product_category: []
      }
    : ({...formattedDockRule} as FacilityDockAppointmentRule | CreateFacilityDockAppointmentRule);
};

export const filterAndStringifyRule = (dockRule: FacilityDockAppointmentRule) => {
  const filteredDockRule = omit(dockRule, ['created_at', 'updated_at']);
  const stringifiedDockRuleIsPublic = {...filteredDockRule, is_public: filteredDockRule.is_public.toString()};
  const stringifiedDockRule = JSON.stringify(stringifiedDockRuleIsPublic);
  return stringifiedDockRule;
};

export const useUpdateDock = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFacilityDockName>>,
    AxiosError<ShipwellApiErrorResponse>,
    {dockId: string; facilityId: string; updateFacilityDock: UpdateFacilityDock}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof updateFacilityDockName>>,
    AxiosError<ShipwellApiErrorResponse>,
    {dockId: string; facilityId: string; updateFacilityDock: UpdateFacilityDock}
  >(
    async ({facilityId, dockId, updateFacilityDock}) =>
      await updateFacilityDockName(dockId, facilityId, updateFacilityDock),
    {
      onSettled: () => queryClient.invalidateQueries([FACILITY_DOCKS_QUERY_KEY]),
      ...mutationOptions
    }
  );
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useCreateDock = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createDock>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateFacilityDock}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof createDock>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateFacilityDock}
  >(async ({facilityId, body}) => await createDock(facilityId, body), {
    onSettled: () => {
      void queryClient.invalidateQueries([FACILITY_DOCKS_QUERY_KEY]);
    },
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useDeleteDock = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFacilityDock>>,
    AxiosError<ShipwellApiErrorResponse>,
    {dockId: string; facilityId: string}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof deleteFacilityDock>>,
    AxiosError<ShipwellApiErrorResponse>,
    {dockId: string; facilityId: string}
  >(async ({dockId, facilityId}) => await deleteFacilityDock(dockId, facilityId), {
    onSettled: () => {
      void queryClient.invalidateQueries([FACILITY_DOCK_RULES]);
      void queryClient.invalidateQueries([FACILITY_DOCKS_QUERY_KEY]);
    },
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useUpdateDockRule = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFacilityDockRule>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      ruleId: string;
      facilityId: string;
      dockId: string;
      updateFacilityDockAppointmentRule: UpdateFacilityDockAppointmentRule;
    }
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof updateFacilityDockRule>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      ruleId: string;
      facilityId: string;
      dockId: string;
      updateFacilityDockAppointmentRule: UpdateFacilityDockAppointmentRule;
    }
  >(
    async ({ruleId, facilityId, dockId, updateFacilityDockAppointmentRule}) =>
      await updateFacilityDockRule(ruleId, facilityId, dockId, updateFacilityDockAppointmentRule),
    {
      onSettled: () => queryClient.invalidateQueries([FACILITY_DOCK_RULES]),
      ...mutationOptions
    }
  );
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useCreateDockRule = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createDockRules>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      facilityId: string;
      dockId: string;
      body: CreateFacilityDockAppointmentRule;
    }
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof createDockRules>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      facilityId: string;
      dockId: string;
      body: CreateFacilityDockAppointmentRule;
    }
  >(async ({facilityId, dockId, body}) => await createDockRules(facilityId, dockId, body), {
    onSettled: () => {
      void queryClient.invalidateQueries([FACILITY_DOCK_RULES]);
      void queryClient.invalidateQueries([FACILITY_DOCKS_QUERY_KEY]);
    },
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useDeleteDockRule = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFacilityDockRule>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      ruleId: string;
      facilityId: string;
      dockId: string;
    }
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof deleteFacilityDockRule>>,
    AxiosError<ShipwellApiErrorResponse>,
    {
      ruleId: string;
      facilityId: string;
      dockId: string;
    }
  >(async ({ruleId, facilityId, dockId}) => await deleteFacilityDockRule(ruleId, facilityId, dockId), {
    onSettled: () => queryClient.invalidateQueries([FACILITY_DOCK_RULES]),
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const assignDockIdToDockRule = (docksWithDockRules: (DockTypeDraft | MappedDockType)[]) => {
  const dockRulesWithAddedDockIds = docksWithDockRules.map((dockWithDockRule) => {
    const dockRulesWithDockIds = dockWithDockRule.dockRules.map((dockRule) => {
      if (!('dock_id' in dockRule)) {
        return {...dockRule, dock_id: 'id' in dockWithDockRule ? dockWithDockRule.id : dockWithDockRule.name};
      }
      return {...dockRule};
    });
    return {...dockWithDockRule, dockRules: dockRulesWithDockIds};
  }) as (DockTypeDraft | MappedDockType)[];
  return dockRulesWithAddedDockIds;
};
