import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Documents - API
 */
export async function getBulkShipmentLabels(shipments) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.documentsGenerateLabelsPost(shipments, apiCallback(resolve, reject));
  });
}
