import moment from 'moment';

export const validatePlannedTimeWindowBeforeNextStop = (values, i) => {
  //compare dates and times with timezones, accounts for when the day is the same
  //and appointment times must be sequential
  if (
    values.stops[i]?.planned_date &&
    values.stops[i]?.location?.address?.timezone &&
    values.stops[i + 1]?.planned_date &&
    values.stops[i + 1]?.location?.address?.timezone
  ) {
    //the form initially loads with time as a simple string, dateTimePicker
    //creates date objects once the user interacts with it
    const getTimeString = (time) => {
      return moment(time).isValid() ? moment(time).format('H:mm') : time;
    };
    //format depends on whether we are reading from the form or backend
    const allowedDateFormats = ['MM/DD/YYYY H:mm', 'YYYY-MM-DD H:mm'];
    //in our forms if schedule type is unselected/null/undefined/NaN then treat it as type 2 (open)
    const initialScheduleType = parseInt(values.stops[i]['schedule-select']) || 2;
    const finalScheduleType = parseInt(values.stops[i + 1]['schedule-select']) || 2;
    const startDate = values.stops[i].planned_date;
    const endDate = values.stops[i + 1].planned_date;

    const initialStartTime = moment.tz(
      `${startDate} ${getTimeString(values.stops[i].planned_time_window_start)}`,
      allowedDateFormats,
      values.stops[i].location.address.timezone
    );
    const finalStartTime = moment.tz(
      `${endDate} ${getTimeString(values.stops[i + 1].planned_time_window_start)}`,
      allowedDateFormats,
      values.stops[i + 1].location.address.timezone
    );
    const finalEndTime = moment.tz(
      `${endDate} ${getTimeString(values.stops[i + 1].planned_time_window_end)}`,
      allowedDateFormats,
      values.stops[i + 1].location.address.timezone
    );

    if ([1, 2, 4].includes(initialScheduleType)) {
      if (
        (finalScheduleType == 1 && initialStartTime.isSameOrBefore(finalStartTime)) ||
        ([2, 3].includes(finalScheduleType) && initialStartTime.isSameOrBefore(finalEndTime)) ||
        finalScheduleType == 4
      ) {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};
