import PropTypes from 'prop-types';
import {Card, Title} from '@shipwell/shipwell-ui';
import OrderCard from './orderCard';
import './styles.scss';

const OrderSection = (props) => {
  const {name, orders = [], title, removeAction, onChange, isCollapsed = false, emptyState} = props;

  const OrderSectionContent = ({expanded}) => (
    <>
      {orders.length > 0 ? (
        expanded && (
          <>
            {orders.map((order, index) => (
              <OrderCard
                name={name}
                order={order}
                key={order.id}
                index={index}
                removeAction={removeAction}
                onChange={onChange}
              />
            ))}
          </>
        )
      ) : (
        <Title className="order-section__empty-state" variant="emptyStateHeader">
          {emptyState}
        </Title>
      )}
    </>
  );

  return (
    <Card
      title={title}
      isCollapsible
      isCollapsed={isCollapsed}
      className="order-section"
      headerClassName="order-section__header"
      bodyClassName="order-section__body"
    >
      <OrderSectionContent orders={orders} onChange={onChange} />
    </Card>
  );
};

OrderSection.propTypes = {
  name: PropTypes.string,
  orders: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  removeAction: PropTypes.func,
  onChange: PropTypes.func,
  isCollapsed: PropTypes.bool,
  emptyState: PropTypes.string
};

export default OrderSection;
