import {Link} from 'react-router';
import {validatePhoneNumber} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import './_styles.scss';

const CarrierDetailSidebar = ({selectedRelationship, featureFlags}) => (
  <div className="carrierDetailSidebar">
    <div className="carrierDetailSidebar__info">
      <div className="mx-2">
        <h4>
          <Link to={`/carriers/${selectedRelationship.id}`}>{selectedRelationship.shipwell_vendor.name}</Link>
        </h4>
        <div>
          {selectedRelationship.shipwell_vendor.mailing_address &&
            selectedRelationship.shipwell_vendor.mailing_address.address_1 +
              ' ' +
              (selectedRelationship.shipwell_vendor.mailing_address.address_2 || '')}
        </div>
        <div>
          {selectedRelationship.shipwell_vendor.mailing_address &&
            selectedRelationship.shipwell_vendor.mailing_address.city}
          ,{' '}
          {selectedRelationship.shipwell_vendor.mailing_address &&
            selectedRelationship.shipwell_vendor.mailing_address.state_province}
        </div>
        {selectedRelationship.shipwell_vendor.primary_phone_number &&
          validatePhoneNumber(selectedRelationship.shipwell_vendor.primary_phone_number) && (
            <div>{getPhoneHyperlink(selectedRelationship.shipwell_vendor.primary_phone_number)}</div>
          )}
      </div>
    </div>
    <div className="carrierDetailSidebar__contacts">
      <div className="carrierDetailSidebar__contacts-header">
        <span>Contacts</span>
        {featureFlags && featureFlags.load_board_enabled && <span>Load Board Access</span>}
      </div>
      <div className="carrierDetailSidebar__contacts-body">
        {selectedRelationship.point_of_contacts &&
          selectedRelationship.point_of_contacts.length > 0 &&
          selectedRelationship.point_of_contacts.map((contact, i) => {
            return (
              <div key={i} className="carrierDetailSidebar__contacts-row">
                <div className="carrierDetailSidebar__contacts-info">
                  <span>{`${contact.first_name} ${contact.last_name}`}</span>
                  {contact.phone_number && <span>{getPhoneHyperlink(contact.phone_number)}</span>}
                </div>
                {featureFlags && featureFlags.load_board_enabled && (
                  <div className="carrierDetailSidebar__contacts-loadBoardEnabled">
                    {contact.load_board_enabled ? 'Yes' : 'No'}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  </div>
);

export default CarrierDetailSidebar;
