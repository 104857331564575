import {
  Product,
  ProductWeightUnitEnum,
  ProductLengthUnitEnum,
  ProductValuePerPieceCurrencyEnum,
  ProductTempUnitEnum,
  ShipmentPreferredCurrencyEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMemo, useState} from 'react';
import {ArrayHelpers} from 'formik';
import debounce from 'debounce-promise';
import {createDefaultLineItem} from 'App/formComponents/formSections/LineItemFields/utils/createDefaultLineItem';
import {getProducts, GetProductsNamedParameters} from 'App/api/products/typed';
import {useCustomFields} from 'App/data-hooks';
import {useCompanyUnitPreferences} from 'App/utils/hooks/useCompanyUnitPreferences';

interface ProductWithHazmatDetailsField extends Product {
  hazmat_details?: Record<string, unknown>;
  hazmat_required?: boolean;
}

export const useProductFieldHelpers = ({
  preferredCurrency
}: {preferredCurrency?: ShipmentPreferredCurrencyEnum} = {}) => {
  const {data: unitPreferences} = useCompanyUnitPreferences();
  const [filteredProducts, setFilteredProducts] = useState<ProductWithHazmatDetailsField[]>([]);
  const {customFields: lineItemCustomFields} = useCustomFields('shipment_line_item');

  const filterProducts = async (input: string): Promise<Product[] | undefined> => {
    const pagination: GetProductsNamedParameters = {
      page: 1,
      pageSize: 25,
      ordering: 'company',
      q: ''
    };
    if (input) {
      pagination.q = input;
      pagination.pageSize = undefined;
    }

    try {
      const response = await getProducts(pagination);
      if (response.data.results) {
        //store a reference to product fields in state, so that we can set
        //order fields via the 'product' dropdown.
        setFilteredProducts(response.data?.results);
        return response.data.results;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterProductsDebounced = useMemo(() => {
    return debounce(filterProducts, 300);
  }, []);

  const setProductValues = ({
    productId,
    index,
    replace,
    lineItemId
  }: {
    productId: string;
    index: number;
    replace: ArrayHelpers['replace'];
    lineItemId: string;
  }) => {
    const defaultLineItem = createDefaultLineItem(lineItemCustomFields, {
      weightUnit: (unitPreferences?.weightUnit as ProductWeightUnitEnum) || ProductWeightUnitEnum.Lb,
      lengthUnit: (unitPreferences?.lengthUnit as ProductLengthUnitEnum) || ProductLengthUnitEnum.In,
      currency: (preferredCurrency ||
        unitPreferences?.currency ||
        ProductValuePerPieceCurrencyEnum.Usd) as ProductValuePerPieceCurrencyEnum,
      temperatureUnit: (unitPreferences?.temperatureUnit as ProductTempUnitEnum) || ProductTempUnitEnum.F
    });
    const productValues = filteredProducts.find((product) => product?.id === productId);
    //check hazmat checkbox field if product is hazmat and set hazmat lookup field value
    if (productValues && productValues.hazmat_identification_number) {
      productValues.hazmat_details = {
        hazmat_hazard_class: productValues.hazmat_hazard_class,
        hazmat_packing_group: productValues.hazmat_packing_group,
        hazmat_proper_shipping_name: productValues.hazmat_proper_shipping_name,
        hazmat_identification_number: productValues.hazmat_identification_number
      };
      productValues.hazmat_required = true;
    }
    //if a product is selected and it is an existing shipment line item, preserve line item id
    if (productValues && lineItemId) {
      replace(index, {...productValues, id: lineItemId, productId});
    }
    //otherwise if there is no product selected (field cleared), reset line item values to default
    else if (lineItemId) {
      replace(index, {...defaultLineItem, id: lineItemId});
    } else {
      //if the line item has no id, just copy over the product values
      const {custom_data} = defaultLineItem;
      const selectedProductInstance = {custom_data, ...(productValues || {}), productId, id: null};
      replace(index, selectedProductInstance);
    }
  };

  return {filterProductsDebounced, setProductValues, filteredProducts};
};
