import {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import pluralize from 'pluralize';
import {AxiosError} from 'axios';
import {SHIPMENTS_ROUND_TRIP_JOB, SHIPMENTS_DASHBOARD_QUERY_KEY} from '../queryKeys';
import {
  createShipmentsRoundTripJob,
  getShipmentsRoundTripJob,
  GetShipmentsRoundTripResponse
} from 'App/api/shipment/typed';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

export const useShipmentsRoundTrips = ({
  setSuccess,
  setError,
  setWarning,
  setLoadingMessage,
  onSuccess,
  legacyGetShipments
}: Partial<WithStatusToastProps> & {
  setLoadingMessage?: (message: string) => void;
  onSuccess?: () => void;
  legacyGetShipments?: () => void;
}) => {
  const queryClient = useQueryClient();
  const [roundTripJobId, setRoundTripJobId] = useState<string>();
  const roundTripsMutation = useMutation({
    mutationFn: (shipmentIds: string[]) => createShipmentsRoundTripJob(shipmentIds),
    onMutate: (shipmentIds) =>
      setLoadingMessage?.(`Updating ${shipmentIds.length} ${pluralize('shipment', shipmentIds.length)} ...`),
    onSuccess: (data) => setRoundTripJobId(data.round_trip_job_id),
    onError: (error, shipmentIds) => {
      console.error(error);
      setError?.('Error', `Unable to update ${shipmentIds.length} ${pluralize('shipment', shipmentIds.length)}.`);
    }
  });

  useQuery<GetShipmentsRoundTripResponse | undefined, AxiosError>(
    [SHIPMENTS_ROUND_TRIP_JOB, roundTripJobId],
    async () => {
      if (roundTripJobId) {
        const roundTripJob = await getShipmentsRoundTripJob(roundTripJobId);
        return roundTripJob;
      }
    },
    {
      enabled: !!roundTripJobId,
      onSuccess: (data) => {
        if (!!data && 'updated_shipments' in data) {
          // from the new dashboard, onSuccess clears selected shipments
          // only clear if there were no errors
          if (!data.not_updated_shipments.length) onSuccess?.();
          if (data.updated_shipments.length) {
            // for the old dashboard, which does not use useQuery
            legacyGetShipments?.();
            void queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
          }

          const totalNumberOfShipments = data.updated_shipments.length + data.not_updated_shipments.length;
          (!data.updated_shipments.length ? setError : data.not_updated_shipments.length ? setWarning : setSuccess)?.(
            'Create Round Trips',
            `${data.updated_shipments.length} of ${totalNumberOfShipments} ${pluralize(
              'shipment',
              totalNumberOfShipments
            )} updated.`
          );
        }
      },
      onError: (error) => {
        console.error(error);
        setError?.('Error', 'Unable to retrieve round trip job status.');
      },
      onSettled: () => setLoadingMessage?.(''),
      refetchInterval: (data, query) => {
        const isUpdatingShipments = !!data && !('updated_shipments' in data);
        //once created, poll the new shipment every 3 seconds until the updated shipments are available
        if (isUpdatingShipments && !query.state.error) {
          return 3000;
        }
        return 0;
      },
      //backend returns a 409 until the job is done
      retry: (failureCount, error) => error.response?.status === 409
    }
  );

  return {roundTripsMutation};
};
