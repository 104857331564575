import {Link} from 'react-router';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';

export const ContainerNumberWithLink = ({url, containerId}: {url: string; containerId: string | null}) => {
  return containerId ? (
    <Link to={url}>{containerId}</Link>
  ) : (
    <>
      --
      <Link className="pl-1" to={url}>
        View
      </Link>
    </>
  );
};

const ContainerNumberCell = (row: {original: {version: number; id: string}; value: string}) => {
  const isV3 = row.original.version === V3_VERSION;
  const url = `/leg/${row.original.id}`;

  return isV3 ? <ContainerNumberWithLink url={url} containerId={row.value} /> : row.value || '--';
};

export default ContainerNumberCell;
