import {Button} from '@shipwell/shipwell-ui';
import {DateRangePicker} from '@shipwell/shipwell-ui';

const DateRangeSelect = (props) => {
  const {parentValue, name, label, onChange} = props;

  const handleSelection = (selection) => {
    if (onChange && selection.length > 0) {
      //format as gte/ lte
      const newValue = {};
      newValue[`${name}Gte`] = selection[0] || undefined;
      if (selection.length === 1) {
        //when "on" is selected, we only send a single date
        newValue[`${name}Lte`] = selection[0];
      } else {
        newValue[`${name}Lte`] = selection[1] || undefined;
      }
      onChange(newValue);
    }
  };
  return (
    <div className="filter__option">
      <DateRangePicker defaultValue={parentValue?.flat()} name={name} label={label} onChange={handleSelection} />
    </div>
  );
};

export default DateRangeSelect;
