import {isUndefined, round} from 'lodash';
import {ShipmentPreferredCurrencyEnum, Stop, TenderGet} from '@shipwell/backend-core-singlerequestparam-sdk';
import {numberWithCommas} from 'App/utils/globals';
import {Price} from 'App/containers/pricingIntel/create/mockData';
import {CarrierToDisplay} from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayCarriers';
import {Rates} from 'App/data-hooks/pricing/useDatPricing';
import {ApplicableContractWithCharges} from 'App/data-hooks/contracts/useGetApplicableContracts';

export const getFormattedPrice = ({
  rate,
  perMileRate,
  averageFuelSurchargePerTripUsd,
  averageFuelSurchargePerMileUsd,
  isDATRate,
  totalDistance,
  showDPM
}: {
  rate?: number;
  perMileRate?: number;
  averageFuelSurchargePerTripUsd?: number;
  averageFuelSurchargePerMileUsd?: number;
  isDATRate?: boolean;
  totalDistance: number;
  showDPM: boolean;
}) => {
  let totalRateValue = 0;
  let perMileValue = 0;
  if (!isUndefined(rate) && rate >= 0) {
    if (isDATRate) {
      totalRateValue = rate > 0 ? rate + (averageFuelSurchargePerTripUsd || 0) : 0;
      perMileValue =
        rate > 0 || (!isUndefined(perMileRate) && perMileRate > 0)
          ? (perMileRate || rate / totalDistance) +
            (averageFuelSurchargePerMileUsd || (averageFuelSurchargePerTripUsd || 0) / totalDistance)
          : 0;
    } else {
      totalRateValue = rate > 0 ? rate : 0;
      perMileValue = rate > 0 ? rate / totalDistance : 0;
    }
  }
  const formattedPrice = isDATRate
    ? round(showDPM ? perMileValue : totalRateValue, showDPM ? 2 : 0)
    : showDPM
    ? round(perMileValue, 2)
    : totalRateValue;
  return formattedPrice && formattedPrice > 0 && isFinite(formattedPrice) ? numberWithCommas(formattedPrice, 2) : '--';
};

export const getStopAddress = (stopsArr: Stop[], idx: number) => {
  return (
    stopsArr[idx].location.address &&
    [stopsArr[idx].location.address.city, stopsArr[idx].location.address.state_province].filter(Boolean).join(', ')
  );
};

export const getIsCarrierTendered = (carrier: CarrierToDisplay, tendersList?: TenderGet[]) => {
  return Boolean(
    tendersList?.find(
      (tender) => tender?.contract === carrier?.contractId || tender?.tender_to_company?.id === carrier.id
    )
  );
};

export const mapContracts = (applicableContracts?: ApplicableContractWithCharges[]) => {
  return (
    applicableContracts?.map((applicableContract) => {
      const {contract, charges} = applicableContract;
      return {
        id: contract?.carrier_relationship || '',
        name: contract?.carrier_name || '',
        rateCurrency: contract?.rate_currency || '',
        weightUnit: contract?.weight_unit,
        contractId: contract?.id || '',
        charges
      };
    }) || []
  );
};

export const mapHistoricalPrices = (prices: Price[]) => {
  return prices.map((price) => {
    return {
      id: price?.carrier_id || '',
      name: price?.carrier_name || '',
      rateCurrency: ShipmentPreferredCurrencyEnum.Usd,
      rate: price?.last_rate || 0
    };
  });
};

export const getExternalDATRatesList = ({
  ratesPerTrip,
  ratesPerMile,
  isDatIntegrated
}: {
  ratesPerTrip?: Rates;
  ratesPerMile?: Rates;
  isDatIntegrated: boolean;
}) => {
  const {lowUsd = 0, rateUsd = 0, highUsd = 0} = ratesPerTrip || {};
  const {lowUsd: perMileLow, rateUsd: perMileAvg, highUsd: perMileHigh} = ratesPerMile || {};
  return [
    {
      label: 'DAT Low',
      rate: lowUsd,
      currency: ShipmentPreferredCurrencyEnum.Usd,
      perMileRate: perMileLow,
      isProviderIntegrated: isDatIntegrated,
      isDATRate: true
    },
    {
      label: 'DAT Avg',
      rate: rateUsd,
      currency: ShipmentPreferredCurrencyEnum.Usd,
      perMileRate: perMileAvg,
      isProviderIntegrated: isDatIntegrated,
      isDATRate: true
    },
    {
      label: 'DAT High',
      rate: highUsd,
      currency: ShipmentPreferredCurrencyEnum.Usd,
      perMileRate: perMileHigh,
      isProviderIntegrated: isDatIntegrated,
      isDATRate: true
    }
  ];
};
