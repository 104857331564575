import {useQuery} from '@tanstack/react-query';
import {FACILITIES_QUERY_KEY, FACILITIES_SHIPMENT_MATCHING} from '../queryKeys';
import {getFacilitiesShipmentMatching} from 'App/api/facilities';

export const useFacilitiesShipmentMatching = ({
  facilityId,
  shipmentId,
  stopId
}: {
  facilityId: string;
  shipmentId: string;
  stopId: string;
}) => {
  const facilitiesShipmentMatchingQuery = useQuery(
    [FACILITIES_QUERY_KEY, FACILITIES_SHIPMENT_MATCHING, facilityId, shipmentId, stopId],
    async () => {
      const response = await getFacilitiesShipmentMatching({facilityId, shipmentId, stopId});
      return response.data;
    },
    {
      enabled: Boolean(facilityId && shipmentId && stopId)
    }
  );

  const {data: facilitiesShipmentMatchingData, status: facilitiesShipmentMatchingStatus} =
    facilitiesShipmentMatchingQuery;
  return {
    facilitiesShipmentMatchingData,
    facilitiesShipmentMatchingStatus,
    facilitiesShipmentMatchingQuery
  };
};
