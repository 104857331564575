import {useCallback} from 'react';
import {connect} from 'react-redux';
import {Field} from 'formik';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {FormikTextInput, FormikSelect, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';

/**
 * Get Customers
 * @param {Number} brokerageId
 * @param {Object} opts
 */

function makeCarrierOption(carrierDetail) {
  return {
    id: carrierDetail.id,
    label: get(carrierDetail, 'shipwell_vendor.name', '')
  };
}

function makeCustomerOption(c) {
  return {
    id: c.company?.id,
    label: c.company?.name
  };
}

const AccessorialCriteriaFields = ({company}) => {
  const getCarrierRelationships = async (input) => {
    try {
      const response = await getCarrierRelationshipsPromise({q: input});
      if (response?.body?.results) {
        return get(response, 'body.results', []).map((carrier) => makeCarrierOption(carrier));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomers = useCallback(
    async (opts = {}) => {
      try {
        const {brokerage} = company;
        if (brokerage?.id) {
          const response = await fetchBrokerShipperRelationshipsPromise(brokerage.id, opts);
          return response;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [company]
  );

  return (
    <div className="grid gap-4">
      <Field required label="Accessorial Charge table name" name="name" component={FormikTextInput} />
      <Field required label="Reference" name="external_reference" component={FormikTextInput} />
      <Field
        searchAddressBook
        searchMaps
        searchStateProvince
        searchCountry
        label="Locations"
        name="locations"
        allowCreate
        component={MultiAddressSearch}
        ignoreFieldWarnings={['postal_code']}
      />
      <ModesField name="modes" simpleValue simpleOptionValue="code" />
      <EquipmentTypesField name="equipment_types" simpleValue simpleOptionValue="machine_readable" />
      <div className="flex items-center gap-4">
        <div className="grow">
          <Field
            async
            isMulti
            label="Carriers"
            name="carrier_relationships"
            component={FormikSelect}
            loadOptions={getCarrierRelationships}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
          />
        </div>
        <Tooltip
          placement="top"
          tooltipClassname="companyAccessorialRates__tooltipContent"
          tooltipContent="If a shipment matches carriers you add here, then your rate table will be automatically added to the carrier side financials."
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
      <div className="flex items-center gap-4">
        <div className="grow">
          <Field
            async
            isMulti
            name="customers"
            label="Customers"
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.id}
            loadOptions={async (input) => {
              const response = await getCustomers({companyContains: input});
              return response ? response.body?.results.map((customer) => makeCustomerOption(customer)) : response;
            }}
            component={FormikSelect}
          />
        </div>
        <Tooltip
          placement="top"
          tooltipClassname="companyAccessorialRates__tooltipContent"
          tooltipContent="
            If a shipment matches customers you add here, then your rate table will be automatically added to the
            customer side financials."
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
    </div>
  );
};

AccessorialCriteriaFields.propTypes = {
  company: PropTypes.shape({
    brokerage: PropTypes.object
  })
};

export default connect((state) => ({
  company: state.userCompany.company
}))(AccessorialCriteriaFields);
