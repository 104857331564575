/* global $, google */
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FieldArray} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import ShipmentItemsFields from 'App/formComponents/formSections/shipmentItemsFields';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import isInternationalShipment from 'App/utils/isInternationalShipment';
import './_lineItems.scss';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

export class LineItems extends Component {
  render() {
    const {form, newLoad, formValues = {}, externalForm = false, modalState} = this.props;
    const {values = {}} = formValues;

    let hasFTL = false;
    let hasLTL = false;
    let hasVLTL = false;
    let hasDrayage = false;
    let hasParcel = false;

    if (values.mode) {
      ({hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel} = checkShipmentModes(values.mode));
    }

    const ftlItemsRequired = (!values.line_items || !values.line_items.length) && hasFTL && !hasLTL;
    const isIntlParcel = hasParcel && isInternationalShipment(values?.stops);

    return (
      <div id="lineItems">
        <Row>
          <Col xs={12}>
            <h2 className="border-bottom">Shipping Items</h2>
            {ftlItemsRequired && (
              <Row>
                <Col xs={12}>
                  <Element name={`position-ftl_estimated_weight`} />
                  <Element name={`position-ftl_description`} />
                  <div className="quote-flow__lineItems-ftlEstimate">
                    <Field
                      req={ftlItemsRequired && !newLoad}
                      name="ftl_estimated_weight"
                      label="Estimated Total Weight"
                      placeholder="Total Weight"
                      component={renderField}
                    />
                    <Field
                      name="ftl_estimated_weight_unit"
                      data={[
                        {id: 'LB', name: 'LB'},
                        {id: 'KG', name: 'KG'}
                      ]}
                      component={renderDropDownSelector}
                    />
                  </div>
                  <Field
                    req={ftlItemsRequired}
                    name="ftl_description"
                    label="Description of commodity"
                    placeholder="Description"
                    component={renderField}
                  />
                </Col>
              </Row>
            )}
            <div>
              <div className="quote-flow__lineItems">
                <FieldArray
                  form={form}
                  externalForm={externalForm}
                  name="line_items"
                  component={ShipmentItemsFields}
                  props={{
                    hasLTL: hasLTL || hasVLTL,
                    hasFTL: hasFTL,
                    hasDrayage,
                    hasParcel,
                    isIntlParcel,
                    modalState
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect((state) => ({
  products: state.products.products,
  selectedProduct: state.products.selectedProduct
}))(LineItems);
