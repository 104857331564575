import {QueryKey, useMutation} from '@tanstack/react-query';
import {
  CreateShipmentTimelineEvent,
  ShipwellError,
  GroupedShipmentTimelineEvents
} from '@shipwell/backend-core-singlerequestparam-sdk';
import invariant from 'tiny-invariant';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {createNewTimelineEvent} from 'App/api/shipment/typed';
import {useUserMe} from 'App/data-hooks';

export const useCreateGroupedTimelineEvent = (shipmentId: string, queryKey: QueryKey, queryOptions = {}) => {
  const {data: user} = useUserMe();
  const createOptimisticUpdateHandlers = useOptimisticUpdate<
    GroupedShipmentTimelineEvents[],
    ShipwellError,
    CreateShipmentTimelineEvent
  >();
  const createEventMutation = useMutation(
    (payload: CreateShipmentTimelineEvent) =>
      createNewTimelineEvent({shipmentId, createShipmentTimelineEvent: payload}),
    createOptimisticUpdateHandlers(queryKey, {
      ...queryOptions,
      mergeData: (prevGroupedTimeline = [], variables) => {
        invariant(variables);
        const {description, event_type = 'INFO', occurred_at} = variables;
        const formattedTimelineEvent = {
          actor: user?.user,
          manually_created: true,
          description,
          event_type,
          occurred_at
        };
        const nonStopGroup = prevGroupedTimeline?.find((group) => !group.stop_id);
        const nonStopGroupIndex = prevGroupedTimeline?.findIndex((group) => !group.stop_id);
        if (nonStopGroup) {
          prevGroupedTimeline[nonStopGroupIndex].timeline_events = [
            formattedTimelineEvent,
            ...(prevGroupedTimeline[nonStopGroupIndex].timeline_events || [])
          ];
        } else {
          prevGroupedTimeline.push({
            is_future_stop: false,
            stop: {},
            stop_id: null,
            stop_ordinal: null,
            timeline_events: [formattedTimelineEvent]
          });
        }
        return prevGroupedTimeline;
      }
    })
  );
  return createEventMutation;
};
