import PropTypes from 'prop-types';
import {Fragment} from 'react';
import moment from 'moment';
import './styles.scss';

const PricingIntelSeriesCrosshair = ({seriesCrosshairValues}) => {
  if (!seriesCrosshairValues || !seriesCrosshairValues[0]) {
    return null;
  }

  const {x, ...valuesByDataSource} = seriesCrosshairValues[0];

  return (
    <div className="crosshairContainer">
      <span className="crosshairDate">{moment(x).format('MMM DD, YYYY')}</span>
      {Object.keys(valuesByDataSource).map((dataSource) => {
        return (
          <Fragment key={`seriesCrosshairValues-${dataSource}`}>
            <span className={`crosshairTitle ${dataSource}`}>{valuesByDataSource[dataSource].label}</span>
            <div className="seriesCrosshairValues">
              <div className="crosshairValue">
                <span className="icon low" />
                <span className="value">${valuesByDataSource[dataSource].yLow}</span>
              </div>
              <div className="crosshairValue">
                <span className="icon average" />
                <span className="value">${valuesByDataSource[dataSource].y}</span>
              </div>
              <div className="crosshairValue">
                <span className="icon high" />
                <span className="value">${valuesByDataSource[dataSource].yHigh}</span>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

PricingIntelSeriesCrosshair.propTypes = {
  seriesCrosshairValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      yLow: PropTypes.number,
      y: PropTypes.number,
      yHigh: PropTypes.number,
      x: PropTypes.string
    })
  )
};

export default PricingIntelSeriesCrosshair;
