import {Button, Checkbox, FormikCheckbox, FormikTextInput} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {useState, ChangeEvent} from 'react';

import {isDefined} from 'App/utils/guard';

const initialValues = {
  alreadyPaidInvoice: false,
  invoiceDoesNotApplyToShipment: false,
  otherReason: ''
};

type FormValues = typeof initialValues;

export const RejectReasonForm = ({
  invoiceNumber,
  onReject,
  onCancel,
  isLoading
}: {
  invoiceNumber?: string;
  onReject: (rejectReason: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleReject = (values: FormValues) => {
    const messageBase = `Invoice${invoiceNumber ? ` #${invoiceNumber} ` : ' '}was rejected`;
    const standardReasons = [
      values.alreadyPaidInvoice ? 'already paid invoice' : null,
      values.invoiceDoesNotApplyToShipment ? 'invoice does not apply to shipment' : null
    ].filter(isDefined);

    const message = `${messageBase}${standardReasons.length ? ` because: ${standardReasons.join(', ')}.` : '.'}${
      values.otherReason ? ` ${values.otherReason}` : ''
    }`;

    onReject(message);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleReject}>
      <div className="p-1">
        <div className="cursor-auto whitespace-nowrap bg-transparent px-5 py-1 text-left">
          <div className="font-bold">Rejection Reason</div>
          <Form>
            <Field name="alreadyPaidInvoice" label="Already paid invoice" component={FormikCheckbox} />
            <Field
              name="invoiceDoesNotApplyToShipment"
              label="Invoice doesn't apply to shipment"
              component={FormikCheckbox}
            />

            <div className="flex min-w-72 gap-2">
              <Checkbox
                checked={showOtherInput}
                onChange={(value: ChangeEvent<HTMLInputElement>) => setShowOtherInput(value.target.checked)}
                label="Other"
                name="other"
              />
              {showOtherInput ? (
                <Field className="grow" name="otherReason" label="Rejection reason" component={FormikTextInput} />
              ) : null}
            </div>

            <div className="flex justify-end gap-2 pt-4">
              <Button variant="secondary" size="sm" type="submit" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="secondary" color="warning" size="sm" type="submit" isLoading={isLoading}>
                Reject
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  );
};
