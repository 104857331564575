import QuickbooksDetails from 'App/containers/integrations/details/components/quickbooksDetails';
import TriumphPayDetails from 'App/containers/integrations/details/components/triumphPayDetails';
import NetSuiteDetails from 'App/containers/integrations/details/components/netSuiteDetails';
import RMISDetails from 'App/containers/integrations/details/components/RMISDetails';
import SaferwatchDetails from 'App/containers/integrations/details/components/SaferwatchDetails';
import FedexDetails from 'App/containers/integrations/details/components/fedexDetails';
import UberFreightDetails from 'App/containers/integrations/details/components/uberFreightDetails';
import UpsRegistration from 'App/containers/ups/registration';
import {deleteNetSuiteConfig} from 'App/api/integrations/typed';
import AmazonFreightDetails from 'App/containers/integrations/details/components/amazonFreightDetails';
import DatDetails from 'App/containers/integrations/details/components/datDetails';
import Smc3Details from 'App/containers/integrations/details/components/smc3';
import TruckstopDetails from 'App/containers/integrations/details/components/truckstopDetails';

export const integrationsConstants = {
  QUICKBOOKS: {
    label: 'QuickBooks',
    slug: 'quickbooks',
    logo: '/images/integration-quickbooks.png',
    detailsComponent: QuickbooksDetails,
    handleDisconnect: null
  },
  TRIUMPHPAY: {
    label: 'Triumph Pay',
    slug: 'triumphpay',
    logo: '/images/integration-triumphpay.png',
    detailsComponent: TriumphPayDetails,
    handleDisconnect: null
  },
  NETSUITE: {
    label: 'NetSuite',
    slug: 'netsuite',
    authenticatedSlug: 'netsuite/data-mapping',
    suiteAppAuthenticatedSlug: 'netsuite/configure-ns-app',
    logo: '/images/integration-netsuite.png',
    detailsComponent: NetSuiteDetails,
    handleDisconnect: deleteNetSuiteConfig
  },
  RMIS: {
    label: 'RMIS',
    slug: 'rmis',
    detailsCardTitle: 'RMIS Configuration',
    logo: '/images/RMIS.png',
    logo_small: '/images/RMIS_icon.svg',
    cardExplainerText: ` To connect with and import data from your company's Registry Monitoring Insurance Services Inc. (RMIS)
              account into Shipwell, please provide the following credentials (available from RMIS). The FTP connection
              needs to be configured before you can configure the API connection.`,
    detailsComponent: RMISDetails,
    hoverDetail: ` Pre-built connector to RMIS for integrating and managing carrier compliance data within Shipwell. Requires
            an active customer account with RMIS.`
  },
  saferwatch: {
    label: 'SaferWatch',
    slug: 'saferwatch',
    detailsCardTitle: 'SaferWatch Configuration',
    logo: '/images/saferwatch_logo.png',
    logo_small: '/images/saferwatch_logo_small.png',
    cardExplainerText: ` To synchronize carrier data from your company's SaferWatch List with Shipwell, please provide your company's
            SaferWatch Customer Key.`,
    hoverDetail:
      'Pre-built connector to SaferWatch for integrating and managing carrier compliance data within Shipwell. Requires an active customer account with SaferWatch.',
    detailsComponent: SaferwatchDetails
  },
  fedex: {
    label: 'Fedex',
    slug: 'fedex',
    detailsCardTitle: 'FedEx Configuration',
    logo: '/images/fedex_logo.svg',
    cardExplainerText: 'FedEx Integration',
    detailsComponent: FedexDetails
  },
  ups: {
    label: 'UPS',
    slug: 'ups',
    detailsCardTitle: 'UPS Configuration',
    logo: '/images/ups_logo.svg',
    cardExplainerText: 'UPS Integration',
    detailsComponent: UpsRegistration
  },
  uber: {
    label: 'Uber Freight',
    slug: 'uber',
    detailsCardTitle: 'Uber Freight Configuration',
    logo: '/images/uber_freight.png',
    cardExplainerText: 'Uber Freight Integration',
    detailsComponent: UberFreightDetails
  },
  amazon: {
    label: 'Amazon Freight',
    slug: 'amazon',
    detailsCardTitle: 'Amazon Freight Configuration',
    logo: '/images/amazon_freight.png',
    cardExplainerText: 'Amazon Freight Integration',
    detailsComponent: AmazonFreightDetails
  },
  dat: {
    label: 'DAT',
    slug: 'dat',
    logo: '/images/dat-freight-and-analytics-logo.svg',
    detailsComponent: DatDetails,
    hoverDetail:
      'DAT Loadboard integration will enable you to post loads, search for capacity, post capacity, and search for loads.'
  },
  smc3: {
    label: 'SMC3',
    slug: 'smc3',
    logo: '/images/svgs/smc3_logo.svg',
    detailsComponent: Smc3Details,
    cardExplainerText: 'SMC3 Integration',
    hoverDetail:
      'SMC3 integration will enable you to store rates and discounts for specific carriers and service types on the carrier profile page. These rates will be applied in the LTL rating portion of a shipment when they match the carrier and service type.'
  },
  truckstop: {
    label: 'Truckstop',
    slug: 'truckstop',
    logo: '/images/truckstop_logo.svg',
    detailsComponent: TruckstopDetails,
    hoverDetail: (
      <>
        The Truckstop Loadboard integration will enable you to post loads, search for capacity, post capacity, and
        search for loads. To enable this integration you will need relevant credentials for your Truckstop account.
        Please email <a href="mailto:tsi@truckstop.com">tsi@truckstop.com</a> with questions.
      </>
    )
  }
};

export const FEDEX_ACCOUNTS = 'FEDEX_ACCOUNTS';
