import PropTypes from 'prop-types';
import classnames from 'classnames';
import snakeCase from 'lodash/snakeCase';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {pricingIntelConfidenceScoreItems} from 'App/components/pricingIntelChart/pricingIntelConstants';
import 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingPredictivePricing/pricingIntelConfidenceScore/styles.scss';

const PricingIntelConfidenceScore = ({myPredictedPrice = {}}) => {
  const {confidence_category: confidenceCategory = '', confidence_description: confidenceDescription = ''} =
    myPredictedPrice;
  const confidenceCategorySnakeCase = snakeCase(confidenceCategory);
  const {icon, color} = pricingIntelConfidenceScoreItems[confidenceCategorySnakeCase] || {};
  return (
    <div className="confidenceScore__container">
      <SvgIcon name={icon || 'ConfidenceLow'} color={color || '$sw-disabled-text'} />
      <div className="confidenceScore__summary-container">
        <span className="confidenceScore__summary">
          Confidence:&nbsp;<div className="confidenceScore__summary-category">{confidenceCategory || '--'}</div>
        </span>
        {confidenceDescription ? (
          <span className={classnames('confidenceScore__description', confidenceCategorySnakeCase)}>
            {confidenceDescription}
          </span>
        ) : null}
      </div>
    </div>
  );
};

PricingIntelConfidenceScore.propTypes = {
  myPredictedPrice: PropTypes.shape({
    confidence_category: PropTypes.string,
    confidence_description: PropTypes.string
  }),
  setPredictiveConfidenceScore: PropTypes.func
};

export default PricingIntelConfidenceScore;
