import {useQueryClient} from '@tanstack/react-query';
import {Field, useFormikContext} from 'formik';
import {
  CarrierTag,
  CreateServiceProvider,
  ServiceProviderCapabilitiesEnum,
  ServiceProviderStatusEnum
} from '@shipwell/backend-core-sdk';
import {Card, FormikTextInput, FormikSelect, FormikTextarea, SvgIcon} from '@shipwell/shipwell-ui';
import {postCarrierTags} from 'App/api/carriers/typed';
import {CARRIER_TAGS_KEY} from 'App/data-hooks/queryKeys';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import useServiceProviders from 'App/api/serviceProviders/useServiceProviders';

const statusOptions = Object.entries(ServiceProviderStatusEnum).map(([label, value]) => ({label, value}));

const ServiceProviderDetialsFields = () => {
  const queryClient = useQueryClient();
  const {values, setFieldValue} = useFormikContext<CreateServiceProvider>();
  const {providerTags} = useServiceProviders();

  const capabilitiesOptions = Object.entries(ServiceProviderCapabilitiesEnum)
    .map(([label, value]) => ({label, value}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleCreateTag = async (name: string) => {
    try {
      const response = await postCarrierTags({name});
      const newTag = response.data;
      setFieldValue('tags', [...(values.tags || []), newTag]);
      await queryClient.invalidateQueries([CARRIER_TAGS_KEY]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card title="Provider Information" draggableProvided={false}>
      <div className="field-grid">
        <div className="grid-item-1-2">
          <Field required label="Provider Name" name="name" component={FormikTextInput} />
        </div>
        <div className="grid-item-1-2">
          <Field
            required
            searchAddressBook={false}
            label="Mailing Address"
            name="mailing_address"
            component={AddressSearch}
          />
        </div>
        <div className="grid-item-1-2">
          <Field searchAddressBook={false} label="Billing Address" name="billing_address" component={AddressSearch} />
        </div>
        <div className="grid-item-1-2">
          <Field
            simpleValue
            clearable={false}
            label="Status"
            name="status"
            options={statusOptions}
            component={FormikSelect}
            getOptionValue={({value}: {value: string}) => value}
          />
        </div>
        <div className="grid-item-1-2">
          <Field
            simpleValue
            isMulti
            label="Capabilities"
            name="capabilities"
            options={capabilitiesOptions}
            component={FormikSelect}
            getOptionValue={({value}: {value: string}) => value}
          />
        </div>
        <div className="grid-item-1-2">
          <Field
            label="Tags"
            name="tags"
            component={FormikSelect}
            isMulti
            async
            allowCreate
            prepend={<SvgIcon name="TFilled" color="$sw-icon" />}
            getOptionLabel={(tag: CarrierTag) => tag.name}
            getOptionValue={(tag: CarrierTag) => tag.id}
            defaultOptions={providerTags}
            formatCreateLabel={(input: string) => `Add new tag '${input}'`}
            getNewOptionData={(input: unknown, label: string) => ({name: label})}
            onCreateOption={handleCreateTag}
          />
        </div>
        <div className="grid-item-1-2 grid-auto-field">
          <Field label="Provider Notes" name="notes" component={FormikTextarea} />
        </div>
      </div>
    </Card>
  );
};

export default ServiceProviderDetialsFields;
