import {ReactNode} from 'react';
import pluralize from 'pluralize';
import classNames from 'classnames';
import {EmptyTableSprinkles} from 'App/components/Table/components/EmptyListView/EmptyTableSprinkles';

const EmptyListView = ({
  children,
  itemLabel,
  position // optional prop used to center the list view when the table is not in the center of the screen
}: {
  children?: ReactNode;
  itemLabel: string;
  position?: {top?: string; left?: string};
}) => {
  const topClassName = position?.top ?? 'top-1/2';
  const leftClassName = position?.left ?? 'left-1/2';

  return (
    <div
      className={`fixed ${topClassName} ${leftClassName} flex -translate-x-1/2 -translate-y-1/2 flex-col items-center`}
    >
      <EmptyTableSprinkles />
      <div className={classNames(`font-medium text-lg`, {'mb-3': Boolean(children)})}>No {pluralize(itemLabel)}</div>
      {children}
    </div>
  );
};

export default EmptyListView;
export * from './EmptyTableSprinkles';
