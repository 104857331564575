import {formatCurrency} from 'App/utils/internationalConstants';

export const CurrencyCell = ({
  value,
  preferredCurrency
}: {
  value?: number | null;
  preferredCurrency?: string | null;
}) => {
  if (typeof value !== 'number') {
    return <>--</>;
  }
  return <>{formatCurrency(value, preferredCurrency ?? undefined)}</>;
};
