import {useQuery} from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import {SHIPMENTS_STAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getShipmentStages} from 'App/api/corrogo/typed';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
export const useShipmentStagesQuery = (shipmentId?: string) => {
  const shipmentStagesQuery = useQuery(
    [SHIPMENTS_STAGES_QUERY_KEY, shipmentId],
    async () => {
      if (!shipmentId) {
        invariant(shipmentId, 'Invalid shipment ID');
      }
      const response = await getShipmentStages(shipmentId);
      return response.data.data;
    },
    {enabled: !!shipmentId, staleTime: HOT_DATA_STALE_TIME}
  );
  return shipmentStagesQuery;
};
