import {JSX} from 'react';
import {Modal} from '@shipwell/shipwell-ui';
import {ExternalDocument} from '@shipwell/corrogo-sdk';
import {getDocumentTypeLabel} from './documentTypes';
import DocumentView from './DocumentView';

type PropTypes = {
  document?: ExternalDocument;
  closeModal: () => void;
  onUpdateDocument: (values: {description: string; type: string}) => void;
  onDeleteDocument: (documentId: string) => void;
};

const DocumentViewModal = ({document, closeModal, onUpdateDocument, onDeleteDocument}: PropTypes): JSX.Element => {
  return (
    <Modal
      className="h-screen-85"
      show={!!document}
      title={`${getDocumentTypeLabel(document?.type)} • ${document?.description || ''}`}
      onClose={closeModal}
      footerComponent={null}
      size="large"
    >
      {document ? (
        <DocumentView
          document={document}
          onClose={closeModal}
          onUpdateDocument={onUpdateDocument}
          onDeleteDocument={onDeleteDocument}
        />
      ) : null}
    </Modal>
  );
};

export default DocumentViewModal;
