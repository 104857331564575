import {createTheme} from '@material-ui/core/styles';

const transparentBackgroundHover = {
  '&:hover': {
    backgroundColor: 'transparent'
  }
};

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      // root: transparentBackgroundHover,
      // colorSecondary: {
      //   '&$checked': transparentBackgroundHover
      // },
      // checked: transparentBackgroundHover
      root: {
        '&$checked': transparentBackgroundHover
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial'
      }
    }
  },
  props: {
    MuiCheckbox: {
      disableRipple: true
    },
    MuiGrid: {
      spacing: 2
    }
  }
});

export default theme;
