import {FreightInvoiceSource} from '@shipwell/settlements-sdk';
import {GeneratedBy, SettlementsDashboardRow, SettlementsDashboardSubStatus} from '@shipwell/backend-core-sdk';
import pluralize from 'pluralize';
import {DashboardNameEnum, dashboards, DashboardView} from '../DashboardsSelector';
import {useDocumentShortNames} from 'App/data-hooks';
import {isDefined} from 'App/utils/guard';

export const getDashboardViewColumnVisibility = (selectedDashboard?: DashboardView) => {
  if (!selectedDashboard || selectedDashboard?.columnsToHide.length === 0) {
    return {};
  }
  return selectedDashboard?.columnsToHide.reduce<{[key: string]: boolean}>((columnVisibility, columnToHide) => {
    return {
      ...columnVisibility,
      [columnToHide]: false
    };
  }, {});
};

export const getActiveDashboard = (dashboardName?: DashboardNameEnum) =>
  dashboards.find((filter) => filter.name === dashboardName);

export const getActiveFilterStatusesString = (activeDashboard?: DashboardView) =>
  activeDashboard?.statuses?.join() || '';

export const getActiveFilterDeliveredString = (activeDashboard?: DashboardView) => activeDashboard?.delivered || '';

export const getActiveFilterStatusesArray = (statusInString?: string) => statusInString?.split(',') || [];

export const getInvoiceSourceValue = (invoice: SettlementsDashboardRow) => {
  switch (invoice.invoice_source) {
    case FreightInvoiceSource.ShipwellWeb:
      return invoice.generated_by === GeneratedBy.BookingParty ? 'On Behalf of Carrier' : 'Carrier-Generated Invoice';
    case FreightInvoiceSource.Edi210:
      return 'EDI 210';
    case FreightInvoiceSource.Dfm:
      return 'DFM';
    case FreightInvoiceSource.UploadedInvoice:
      return 'Uploaded Invoice';
    default:
      return '';
  }
};

export const useGetSubStatusDescriptions = (subStatuses: SettlementsDashboardSubStatus[]) => {
  const {data: shortNamesMap} = useDocumentShortNames();

  const subStatusDescriptions = subStatuses
    .map((substatus) => {
      if (substatus.sub_status === 'MISSING_DOC') {
        const missingDocShortName = shortNamesMap?.[substatus.description];
        return missingDocShortName ? `Missing ${missingDocShortName}` : undefined;
      }
      if (substatus.sub_status === 'WRONG_PARTY') {
        return 'Invoice carrier does not match shipment carrier';
      }
      return substatus.description;
    })
    .filter(isDefined);

  return [...new Set(subStatusDescriptions)];
};

export const getSubStatusesAriaDescription = (subStatusDescriptions: string[] | undefined) => {
  return subStatusDescriptions?.length
    ? `This invoice has ${pluralize('exception', subStatusDescriptions.length, true)}: ${subStatusDescriptions
        .map((description, index) => `${index + 1}) ${description}`)
        .join('; ')}`
    : undefined;
};

export const settlementsDashboardLabelMap = {
  actions: 'Actions',
  invoiceable_reference_number: 'ID',
  invoice_number: 'Invoice #',
  status: 'Status',
  communications: 'Comunications',
  remit_to: 'Remit To',
  service_provider_name: 'Carrier',
  total_amount: 'Invoiced',
  shipment_total: 'Ship. Total',
  difference: 'Difference',
  total_passed: 'Total Passed',
  sub_statuses: 'Missing Doc(s)',
  due_date: 'Carrier Due Date',
  invoice_date: 'Invoiced Date',
  delivered_date: 'Delivered Date',
  updated_at: 'Last Update',
  generated_by: 'Source'
};
