import {JSX} from 'react';
import {DeliveryType} from 'App/utils/globalsTyped';

export type AppointmentDetailsPaperProps = {
  /**
   * Time of the arrival in the timezone of the location.
   * @example "HH:MM"
   */
  startTime?: string | null;
  /**
   * Date of the arrival in the timezone of the location.
   * @example "DD-MMM-YYYY"
   */
  endTime?: string | null;
  /**
   * Is the delivery type being loaded or unloading for the freight.
   */
  deliveryType?: DeliveryType | null;
  /**
   * Name of the dock the appointment is being scheduled for.
   */
  dockName?: string | null;
  /**
   * Whether the appointment is first come first serve or by appointment
   */
  isFirstComeFirstServe?: boolean;
  /**
   * formatted date for the appointment, e.g., Thu Jun 22
   */
  appointmentDate?: string | null;
};

/**
 * Appointment component for Dock Scheduling.
 */
const AppointmentDetailsPaper = ({
  startTime,
  endTime,
  deliveryType,
  dockName,
  isFirstComeFirstServe,
  appointmentDate
}: AppointmentDetailsPaperProps): JSX.Element => {
  const mappedFields = [
    {label: 'Appointment Date', value: appointmentDate},
    {
      label: 'start',
      value: startTime
    },
    {
      label: 'end',
      value: endTime
    },
    {
      label: 'dock',
      value: dockName
    },
    {
      label: 'delivery type',
      value: deliveryType
    },
    {
      label: 'appointment type',
      value: isFirstComeFirstServe ? 'First Come First Serve' : 'By Appointment'
    }
  ];

  return (
    <div className="flex flex-row gap-4" role="list">
      {mappedFields.map(({value, label}) => {
        const labelId = label.replace(' ', '-');
        return (
          <div key={labelId} className="flex grow flex-col" role="listitem">
            <span className="text-xxs uppercase" id={labelId}>
              {label}
            </span>
            <span className="text-sm" aria-labelledby={labelId}>
              {value ?? '--'}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default AppointmentDetailsPaper;
