import {UserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {createUserDashboardConfig} from 'App/api/tableConfig/typed';
import {USER_DASHBOARDS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const useCreateUserDashboardConfig = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserDashboardConfig>>,
    unknown,
    UserDashboardConfiguration
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof createUserDashboardConfig>>, unknown, UserDashboardConfiguration>(
    (userDashboardConfiguration) => createUserDashboardConfig(userDashboardConfiguration),
    {
      onSettled: () => queryClient.invalidateQueries([USER_DASHBOARDS_QUERY_KEY]),
      ...mutationOptions
    }
  );
};

export default useCreateUserDashboardConfig;
