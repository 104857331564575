import {useState, useEffect, createContext} from 'react';
import {isEqual, debounce} from 'lodash';
import buildPathParams from '../buildPathParams';
import {
  checkFilterObj,
  formatTableConfig,
  cleanFilterPayload,
  unnestTableView,
  updateFilterObj
} from 'App/components/_tableFiltersWithSavedViews/utils';
import {tableConfig} from 'App/api';

/** Debounced path update */
const pathUpdateDebounced = debounce((router, path) => router.push(path), 300);

const useTableFilters = (router, tableType, location, columns) => {
  const [activeFilters, setActiveFilters] = useState({});
  const [selectedTableView, setSelectedTableView] = useState({});
  const [defaultValues] = useState(location.query);

  const onChange = (newFilterValues, viewId) => {
    const cleanedFilterValues = cleanFilterPayload(newFilterValues);
    let debouncePathUpdate = false;
    //debounce path update for text search filters
    const textSearchFilters = ['customerNameContains', 'supplierNameContains', 'purchaseOrderNumberContains'];
    if (Object.keys(cleanedFilterValues).some((filter) => textSearchFilters.includes(filter))) {
      debouncePathUpdate = true;
    }
    //flatten values down to just IDs
    Object.keys(cleanedFilterValues).forEach((key) => {
      if (Array.isArray(cleanedFilterValues[key])) {
        cleanedFilterValues[key] = cleanedFilterValues[key].map((value) => (value ? value.id || value : null));
      }
    });
    const newSelections = updateFilterObj(activeFilters, cleanedFilterValues, viewId);
    //if table view only has columns, remove filters params and add view id
    if (Object.keys(newSelections).length === 0 && viewId) {
      const params = {viewId};
      const updatedPath = buildPathParams({...location, query: {}}, params);
      setActiveFilters({});
      router.push(updatedPath);
      //otherwise update params if filters change or view is removed
    } else if (!isEqual(newSelections, activeFilters) || Object.keys(newSelections.length === 0 && !viewId)) {
      if (newSelections.purchaseOrderOverallStatus) {
        newSelections['overallStatus'] = newSelections.purchaseOrderOverallStatus;
        delete newSelections.purchaseOrderOverallStatus;
      }
      //reset page to 1 whenever filters change (unless filter obj is empty)
      const setPagination = checkFilterObj(cleanedFilterValues);
      const params = setPagination ? {...newSelections, viewId, page: 1} : {...newSelections, viewId};
      const updatedPath = buildPathParams({...location, query: {}}, params);
      setActiveFilters(newSelections);
      if (debouncePathUpdate) {
        pathUpdateDebounced(router, updatedPath);
      } else {
        router.push(updatedPath);
      }
    }
  };

  const clearFilter = (e, filter, secondaryFilter, useRange, filterValue) => {
    e.stopPropagation();
    const newFilter = {};
    //remove the filter value from the array of values
    if (filterValue?.id && Object.keys(filterValue.id).length > 1 && Object.keys(activeFilters).length > 0) {
      newFilter[filter] = activeFilters?.[filter]?.filter((val) => {
        return val !== filterValue.id;
      });
    } else {
      newFilter[filter] = [];
      if (secondaryFilter) {
        newFilter[secondaryFilter] = [];
      }
    }
    if (secondaryFilter && activeFilters[secondaryFilter] && filterValue?.label) {
      newFilter[secondaryFilter] = activeFilters[secondaryFilter].filter((val) => val !== filterValue.label);
    }
    if (useRange) {
      newFilter[`${filter}Lte`] = undefined;
      newFilter[`${filter}Gte`] = undefined;
    }
    onChange(newFilter);
    //if we remove filters, unset the selected table view
    setSelectedTableView({});
  };

  const clearAllFilters = (e) => {
    //allows caller to be an async function
    if (e) {
      e.stopPropagation();
    }
    const newFilters = Object.keys(activeFilters).reduce((filterObj, filter) => {
      filterObj[filter] = [];
      if (filter.secondaryFilter) {
        filterObj[filter.secondaryFilter] = [];
      }
      if (filter.useRange) {
        filterObj[`${filter}Lte`] = undefined;
        filterObj[`${filter}Gte`] = undefined;
      }
      return filterObj;
    }, {});
    onChange(newFilters);
    setSelectedTableView({});
  };

  const getTableViewById = async (viewId) => {
    try {
      const response = await tableConfig.getUserTableByIdConfigurationPromise(viewId);
      setSelectedTableView(unnestTableView(response.body, columns));
    } catch (error) {
      console.error('There was an error getting selected table view.');
      //to do: figure out what we wanna wrap in errors/success HOC
      // setError('Error getting table view', error.error_description);
      throw error;
    }
  };

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues).length > 0) {
      if (defaultValues.viewId) {
        getTableViewById(defaultValues.viewId);
        onChange(formatTableConfig(defaultValues), defaultValues.viewId);
      } else {
        setSelectedTableView({});
        onChange(formatTableConfig(defaultValues));
      }
    }
  }, [defaultValues]);

  return [activeFilters, onChange, clearFilter, clearAllFilters, selectedTableView, setSelectedTableView];
};

const TableFiltersContext = createContext({});

const TableFiltersContextProvider = ({router, tableType, location, columns, ...props}) => {
  const [activeFilters, onChange, clearFilter, clearAllFilters, selectedTableView, setSelectedTableView] =
    useTableFilters(router, tableType, location, columns);

  return (
    <TableFiltersContext.Provider
      value={{activeFilters, onChange, clearFilter, clearAllFilters, selectedTableView, setSelectedTableView}}
      {...props}
    />
  );
};

const TableFiltersContextConsumer = TableFiltersContext.Consumer;

export {TableFiltersContextProvider, TableFiltersContextConsumer};
