import {Field} from 'redux-form';
import renderReadOnlyField from 'App/formComponents/renderReadOnlyField';
import {formatCurrencyValue} from 'App/utils/globals';

const renderMultipleFinancialsReadonly = ({fields, financialTotals, meta: {touched, error}, ...rest}) => (
  <div>
    {fields.map((financial, index) => (
      <div key={index} className="quote-flow__financialsLineItem">
        <span className="quote-flow__financialsLineItem-readonly">
          <Field
            component={renderReadOnlyField}
            name={`${financial}.unit_name`}
            placeholder="e.g., Line Haul"
            disabled
          />
        </span>
        <span className="quote-flow__financialsLineItem-readonly">
          <Field component={renderReadOnlyField} name={`${financial}.unit_amount`} placeholder="$" disabled />
        </span>
        <span className="quote-flow__financialsLineItem-readonly">
          <Field
            component={renderReadOnlyField}
            name={`${financial}.prepaid_amount`}
            placeholder="Enter amount"
            disabled
          />
        </span>
        <span className="quote-flow__financialsLineItem-readonly">
          <Field component={renderReadOnlyField} name={`${financial}.unit_quantity`} placeholder="e.g., 1" disabled />
        </span>
        <span className="quote-flow__financialsLineItem-readonly">
          <span className="pad-right">${formatCurrencyValue(financialTotals[index])}</span>
        </span>
      </div>
    ))}
    {error && (
      <p className="error-text-form-level pad-top">
        <i className="icon icon-Delayed pad-right" />
        {error}
      </p>
    )}
  </div>
);

export default renderMultipleFinancialsReadonly;
