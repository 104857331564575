import {FuelSurchargeTableRegionEnum} from '@shipwell/backend-core-singlerequestparam-sdk';

export const regionOptions = [
  {
    label: 'U.S.',
    value: FuelSurchargeTableRegionEnum.Us
  },
  {
    label: 'East Coast (PADD1)',
    value: FuelSurchargeTableRegionEnum.EastCoast
  },
  {
    label: 'New England (PADD1A)',
    value: FuelSurchargeTableRegionEnum.NewEngland
  },
  {
    label: 'Central Atlantic (PADD1B)',
    value: FuelSurchargeTableRegionEnum.CentralAtlantic
  },
  {
    label: 'Lower Atlantic (PADD1C)',
    value: FuelSurchargeTableRegionEnum.LowerAtlantic
  },
  {
    label: 'Midwest (PADD2)',
    value: FuelSurchargeTableRegionEnum.Midwest
  },
  {
    label: 'Gulf Coast (PADD3)',
    value: FuelSurchargeTableRegionEnum.GulfCoast
  },
  {
    label: 'Rocky Mountain (PADD4)',
    value: FuelSurchargeTableRegionEnum.RockyMountain
  },
  {
    label: 'West Coast (PADD5)',
    value: FuelSurchargeTableRegionEnum.WestCoast
  },
  {
    label: 'West Coast Less California',
    value: FuelSurchargeTableRegionEnum.WestCoastLessCalifornia
  },
  {
    label: 'California',
    value: FuelSurchargeTableRegionEnum.California
  }
];

const regionToStatesMapping = {
  //Connecticut, Maine, Massachusetts, New Hampshire, Rhode Island, and Vermont,
  //Delaware, District of Columbia, Maryland, New Jersey, New York, and
  //Pennsylvania, Florida, Georgia, North Carolina, South Carolina, Virginia,
  //and West Virginia
  [FuelSurchargeTableRegionEnum.EastCoast]: [
    'CT',
    'ME',
    'MA',
    'NH',
    'RI',
    'VT',
    'DE',
    'DC',
    'MD',
    'NJ',
    'NY',
    'PN',
    'FL',
    'GA',
    'NC',
    'SC',
    'VA',
    'WV'
  ],
  //Connecticut, Maine, Massachusetts, New Hampshire, Rhode Island, and Vermont
  [FuelSurchargeTableRegionEnum.NewEngland]: ['CT', 'ME', 'MA', 'NH', 'RI', 'VT'],
  //PADD 1B (Central Atlantic): Delaware, District of Columbia, Maryland, New
  //Jersey, New York, and Pennsylvania.
  [FuelSurchargeTableRegionEnum.CentralAtlantic]: ['DE', 'DC', 'MD', 'NJ', 'NY', 'PA'],
  //PADD 1C (Lower Atlantic): Florida, Georgia, North Carolina, South Carolina,
  //Virginia, and West Virginia.
  [FuelSurchargeTableRegionEnum.LowerAtlantic]: ['FL', 'GA', 'NC', 'SC', 'VA', 'WV'],
  //PADD 2 (Midwest): Illinois, Indiana, Iowa, Kansas, Kentucky, Michigan,
  //Minnesota, Missouri, Nebraska, North Dakota, Ohio, Oklahoma, South Dakota,
  //Tennessee, and Wisconsin.
  [FuelSurchargeTableRegionEnum.Midwest]: [
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'MI',
    'MN',
    'MO',
    'NE',
    'ND',
    'OH',
    'OK',
    'SD',
    'TN',
    'WI'
  ],
  //PADD 3 (Gulf Coast): Alabama, Arkansas, Louisiana, Mississippi, New Mexico, and Texas.
  [FuelSurchargeTableRegionEnum.GulfCoast]: ['AL', 'AR', 'LA', 'MS', 'NM', 'TX'],
  //PADD 4 (Rocky Mountain): Colorado, Idaho, Montana, Utah, and Wyoming.
  [FuelSurchargeTableRegionEnum.RockyMountain]: ['CO', 'ID', 'MT', 'UT', 'WY'],
  //PADD 5 (West Coast): Alaska, Arizona, California, Hawaii, Nevada, Oregon, and Washington.
  [FuelSurchargeTableRegionEnum.WestCoast]: ['AK', 'AZ', 'CA', 'HI', 'NV', 'OR', 'WA'],
  //(West Coast Less California): Alaska, Arizona, Hawaii, Nevada, Oregon, and Washington.
  [FuelSurchargeTableRegionEnum.WestCoastLessCalifornia]: ['AK', 'AZ', 'HI', 'NV', 'OR', 'WA'],
  //(California): California
  [FuelSurchargeTableRegionEnum.California]: ['CA']
};

const formatAddress = (state: string) => ({
  formatted_address: `${state}, US`,
  type: 'state_province',
  validated: true,
  value: {
    country: 'US',
    formatted_address: `${state}, US`,
    state_province: state
  }
});

export const getAddressValuesFromRegion = (region: FuelSurchargeTableRegionEnum) =>
  regionToStatesMapping[region as keyof typeof regionToStatesMapping]?.map((state) => formatAddress(state)) || [];
