import {FedexRegistrationFormValues} from 'App/formComponents/forms/fedexRegistration/utils/types';

/** Default FedEx Registration values */
export const defaultValues: FedexRegistrationFormValues = {
  account_number: '',
  account_nickname: '',
  billing_address: {
    line_1: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country_code: 'US'
  },
  contact: {
    first_name: '',
    last_name: '',
    company_name: '',
    email_address: '',
    phone_number: ''
  },
  secondary_email: '',
  smart_post_hub_id: '',
  accept_terms: false,
  has_freight_account: false
};

/** Default FedEx Freight Registration values */
export const defaultFreightValues: Pick<FedexRegistrationFormValues, 'freight_account' | 'has_freight_account'> = {
  has_freight_account: true,
  freight_account: {
    freight_account_number: '',
    line_1: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country_code: 'US'
  }
};
