import {useState} from 'react';
import moment from 'moment';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {
  Shipment,
  ShipmentsApiShipmentsPickupsGetRequest,
  ShipmentPickup,
  ShippingDashboardRow
} from '@shipwell/backend-core-singlerequestparam-sdk';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useGetShipmentPickups} from 'App/data-hooks/parcel/useGetShipmentPickups';
import './styles.scss';

type SchedulePickupProps = {
  shipment?: Shipment | ShippingDashboardRow;
  filters?: Partial<ShipmentsApiShipmentsPickupsGetRequest>;
  currentPickup?: ShipmentPickup;
  onCancel?: () => void;
  onCreatePickup?: () => void;
  onUpdatePickup?: (shipmentPickup: ShipmentPickup) => Promise<void>;
};

const SchedulePickup = ({
  shipment,
  filters,
  onCancel,
  onCreatePickup,
  onUpdatePickup,
  currentPickup
}: SchedulePickupProps) => {
  const [updatingPickup, setUpdatingPickup] = useState<ShipmentPickup | null>(null);

  const pickupAddress = shipment?.stops?.[0]?.location?.address?.formatted_address;

  const defaultFilters = {
    scheduledDateGte: moment().format('YYYY-MM-DD'),
    pageSize: 1000,
    cancelled: false,
    ordering: 'scheduled_date'
  };

  const {data: shipmentPickups, isFetching: fetchingPickups} = useGetShipmentPickups({...defaultFilters, ...filters});
  const pickups =
    shipmentPickups?.results?.filter(
      (pickup) =>
        pickup.id !== currentPickup?.id &&
        pickup.address &&
        pickup.address.formatted_address &&
        pickupAddress &&
        pickupAddress === pickup.address.formatted_address
    ) || [];

  const handleSelectPickup = async (selectedPickup: ShipmentPickup) => {
    if (onUpdatePickup && selectedPickup) {
      setUpdatingPickup(selectedPickup);
      await onUpdatePickup(selectedPickup);
      setUpdatingPickup(null);
    }
  };

  const handleCreatePickup = () => onCreatePickup?.();

  return (
    <div className="schedule-pickup">
      <div className="schedule-pickup__header">
        <DeprecatedButton variant="tertiary" disabled={!!updatingPickup} onClick={() => handleCreatePickup()}>
          Add New Pickup
        </DeprecatedButton>
      </div>
      {fetchingPickups ? <ShipwellLoader loading={fetchingPickups} /> : null}
      {!fetchingPickups ? (
        <ul className="schedule-pickup__options">
          {pickups.map((pickup) => (
            <li key={pickup.id}>
              <div>
                <p>
                  <strong>{pickup.address && pickup.address.formatted_address}</strong>
                </p>
                <strong>{moment(pickup.scheduled_date).format('ddd, MMM D')}</strong>
                <p>
                  Between {moment(pickup.time_window_start, 'HH:mm:ss').format('HH:mm')} -{' '}
                  {moment(pickup.time_window_end, 'HH:mm:ss').format('HH:mm')}
                </p>
              </div>
              <DeprecatedButton
                variant="tertiary"
                disabled={!!updatingPickup}
                icon={updatingPickup && updatingPickup.id === pickup.id && <i className="icon icon-Restart rotate" />}
                onClick={() => void handleSelectPickup(pickup)}
              >
                Use This Pickup
              </DeprecatedButton>
            </li>
          ))}
        </ul>
      ) : null}
      {!fetchingPickups && pickups.length < 1 ? <p className="empty-pickup">No future pickups scheduled.</p> : null}
      <div className="schedule-pickup__footer">
        <DeprecatedButton variant="secondary" onClick={onCancel}>
          Cancel
        </DeprecatedButton>
      </div>
    </div>
  );
};

export default SchedulePickup;
