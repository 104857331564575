import {Dispatch as ReactDispatch, SetStateAction, useState} from 'react';
import {connect} from 'react-redux';
import {Button, FormikDateTimePicker, Popover, SvgIcon} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {useCreateStopEvent} from 'App/data-hooks/stops/useCreateStopEvent';
import {
  StopEvent,
  StopEventStopEventTypeEnum,
  StopEventStopEventReasonCodeEnum,
  Stop,
  StopStatusEnum,
  Shipment
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Action, Dispatch} from 'redux';
import {getShipmentTimeline, shipmentsShipmentIdGet} from 'App/actions/_shipmentDetails';
import {compose} from 'recompose';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type SimplifiedStopActionType = {
  shipment: Shipment;
  stop: Stop;
  stopEvent: StopEvent;
  dispatch: Dispatch<Action<typeof shipmentsShipmentIdGet | typeof getShipmentTimeline>>;
} & WithStatusToastProps;

const SimplifiedStopActionBase = ({shipment, stop, dispatch, setSuccess}: SimplifiedStopActionType) => {
  const {mutateAsync: createStopEvent, isLoading: isCreatingStopEvent} = useCreateStopEvent();
  const [isReFetchingShipment, setIsRefetchingShipment] = useState<boolean>(false);

  const initialValues = {
    dispatched_at: null,
    arrived_at: null,
    departed_at: null
  };

  const getCurrentDateTz = () => `${new Date().toISOString()}`;
  const refetchShipment = async () => {
    await Promise.all([
      dispatch(shipmentsShipmentIdGet(shipment.id) as unknown as Action),
      dispatch(getShipmentTimeline(shipment.id) as unknown as Action)
    ]);
    setIsRefetchingShipment(false);
  };

  const sendEventToNextStop = async () => {
    const nextStop = shipment.stops?.find((shipmentStop) => shipmentStop.ordinal_index === stop.ordinal_index + 1);
    if (nextStop) {
      await createStopEvent({
        shipmentId: shipment.id,
        stopId: nextStop.id,
        stopEvent: {
          occurred_at: getCurrentDateTz(),
          stop_event_type: StopEventStopEventTypeEnum.EnRouteToStop,
          stop_event_reason_code: StopEventStopEventReasonCodeEnum.NormalStatus
        }
      });
    }
  };

  const handleSubmit = async (
    {dispatched_at, arrived_at, departed_at}: typeof initialValues,
    setIsOpen: ReactDispatch<SetStateAction<boolean>>
  ) => {
    setIsRefetchingShipment(true);
    if (dispatched_at) {
      await createStopEvent({
        shipmentId: shipment.id,
        stopId: stop.id,
        stopEvent: {
          occurred_at: dispatched_at,
          stop_event_type: StopEventStopEventTypeEnum.EnRouteToStop,
          stop_event_reason_code: StopEventStopEventReasonCodeEnum.NormalStatus
        }
      });
    }
    if (arrived_at) {
      await createStopEvent({
        shipmentId: shipment.id,
        stopId: stop.id,
        stopEvent: {
          occurred_at: arrived_at,
          stop_event_type: StopEventStopEventTypeEnum.ArrivedAtStop,
          stop_event_reason_code: StopEventStopEventReasonCodeEnum.NormalStatus
        }
      });
    }
    if (departed_at) {
      await createStopEvent({
        shipmentId: shipment.id,
        stopId: stop.id,
        stopEvent: {
          occurred_at: departed_at,
          stop_event_type: StopEventStopEventTypeEnum.DepartedStop,
          stop_event_reason_code: StopEventStopEventReasonCodeEnum.NormalStatus
        }
      });
      await sendEventToNextStop();
    }
    setIsOpen(false);
    await refetchShipment();
    setSuccess('Stop Event Added!', 'Your event(s) were added to the timeline');
  };

  const handleMarkStopAsComplete = async () => {
    setIsRefetchingShipment(true);
    const stopEvent = {
      occurred_at: getCurrentDateTz(),
      stop_event_type: StopEventStopEventTypeEnum.DepartedStop,
      stop_event_reason_code: StopEventStopEventReasonCodeEnum.NormalStatus
    };
    await createStopEvent({shipmentId: shipment.id, stopId: stop.id, stopEvent});
    await sendEventToNextStop();
    await refetchShipment();
  };

  return (
    <div className="flex items-center gap-0.5">
      <Button
        isLoading={isCreatingStopEvent && isReFetchingShipment}
        disabled={isCreatingStopEvent && isReFetchingShipment}
        variant="secondary"
        size="sm"
        onClick={handleMarkStopAsComplete}
      >
        Mark Stop as Complete
      </Button>
      <Popover
        placement="bottom-end"
        portal
        theme="light"
        trigger={({
          isOpen,
          setIsOpen,
          setTriggerElement
        }: {
          isOpen: boolean;
          setIsOpen: ReactDispatch<SetStateAction<boolean>>;
          setTriggerElement: (el: HTMLElement | null) => void;
        }) => (
          <div
            ref={setTriggerElement}
            onClick={() => setIsOpen(!isOpen)}
            className={`cursor-pointer border border-sw-border rounded h-[24px] w-[24px] hover:bg-sw-active-light ${
              isOpen ? 'bg-sw-active-light' : 'bg-transparent'
            }`}
          >
            <SvgIcon name={isOpen ? 'CarrotUp' : 'CarrotDown'} />
          </div>
        )}
      >
        {({setIsOpen}: {setIsOpen: ReactDispatch<SetStateAction<boolean>>}) => (
          <div>
            <Formik
              onSubmit={(values: typeof initialValues) => handleSubmit(values, setIsOpen)}
              initialValues={initialValues}
              initialTouched={{
                dispatched_at: true,
                arrived_at: true,
                departed_at: true
              }}
            >
              {({isSubmitting, dirty, isValid /* errors, status, setStatus, setFieldValue, validateField */}) => (
                <Form>
                  <div className="p-4 w-[350px]">
                    {/* This field is a workaround because the PopOver component propagates the click, making the first field focussed. Normally, this means no harm, but in this case, it is invasive for the user to get the calendar open at first. */}
                    <Field type="text" name="hiddenHackyField" className="h-0 w-0" />
                    {!stop.status ? (
                      <Field
                        name="dispatched_at"
                        label="Dispatched Date/Time"
                        component={FormikDateTimePicker}
                        prepend={<SvgIcon name="Calendar" />}
                        timezone={stop.location.address.timezone}
                        timeIntervals={15}
                        className={'mt-[-20px] mb-1'}
                      />
                    ) : null}
                    {stop.status !== StopStatusEnum.AtStop ? (
                      <Field
                        name="arrived_at"
                        label="Arrival Date/Time"
                        component={FormikDateTimePicker}
                        prepend={<SvgIcon name="Calendar" />}
                        timezone={stop.location.address.timezone}
                        timeIntervals={15}
                        className={'mb-1'}
                      />
                    ) : null}
                    <Field
                      name="departed_at"
                      label="Departure Date/Time"
                      component={FormikDateTimePicker}
                      prepend={<SvgIcon name="Calendar" />}
                      timezone={stop.location.address.timezone}
                      timeIntervals={15}
                    />
                  </div>
                  <div className="flex gap-1.5 justify-end border-t border-t-sw-border py-2 px-1">
                    <Button size="sm" variant="tertiary" disabled={isSubmitting} onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      isLoading={isCreatingStopEvent && isReFetchingShipment}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Popover>
    </div>
  );
};
export const SimplifiedStopAction = compose<SimplifiedStopActionType, SimplifiedStopActionType>(
  WithStatusToasts,
  connect()
)(SimplifiedStopActionBase);
