import {getCarrierRelationshipsPromise} from 'App/api/carriers';

export const formatCarrierPOCs = (carriers) => {
  const POCResults = [];

  carriers.forEach((result) => {
    result?.point_of_contacts.forEach((poc) => {
      const label = `${result.name || result.shipwell_vendor.name} - ${poc.first_name} ${poc.last_name || ''} (${
        poc.email
      })`;
      POCResults.push({
        label: label,
        id: poc.user,
        pocId: poc.id,
        carrier: result.shipwell_vendor.id,
        carrierName: result.name || result.shipwell_vendor.name,
        carrierStatus: result.carrier_status,
        carrierFAVRid: result.id,
        carrierRelationship: result
      });
    });
  });
  return POCResults;
};

export const getCarrierRelationships = async (opts, index = null, values = {}) => {
  try {
    const response = await getCarrierRelationshipsPromise(opts);
    if (response?.body) {
      let POCResults = formatCarrierPOCs(response.body.results);
      // if we have any POCs selected already, filter down the POCs to ones with matching carrier
      if (
        index &&
        values?.actions[index]?.involved_tender_to_company_users &&
        values.actions[index].involved_tender_to_company_users.length > 0
      ) {
        POCResults = POCResults.filter(
          (e) => e.carrier === values.actions[index].involved_tender_to_company_users[0].carrier
        );
      }
      return POCResults;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getCarrierRelationshipByCarrierId = async (vendorId) => {
  try {
    const response = await getCarrierRelationshipsPromise({vendorId});
    if (response?.body?.results) {
      return response.body.results;
    }
  } catch (error) {
    console.error(error);
  }
};
