import {Link} from 'react-router';
import {compose} from 'recompose';
import withFlagsFallback from 'App/components/withFlagsFallback';
import {withUserPermissionsFallback} from 'App/data-hooks';
import {
  VIEW_FREIGHT_INVOICES_PERMISSION,
  VIEW_SETTLEMENTS_DASHBOARD_PERMISSION
} from 'App/containers/settlements/freightInvoices/permissionsConstants';

const SettlementsDashboardLink = () => (
  <Link to="/settlements" className="menu-title" activeClassName="active">
    Settlements
  </Link>
);

export default compose(
  withFlagsFallback(['stmCarrierInvoicing', 'stmSettlementsDashboard']),
  withUserPermissionsFallback([VIEW_FREIGHT_INVOICES_PERMISSION, VIEW_SETTLEMENTS_DASHBOARD_PERMISSION])
)(SettlementsDashboardLink);
