import {Tooltip} from '@shipwell/shipwell-ui';

export const PoNumberCell = ({poNumber}: {poNumber: string}) => {
  const purchaseOrderNumberList = poNumber?.split(',') || [];

  return purchaseOrderNumberList?.length > 1 ? (
    <div className="flex flex-row">
      {purchaseOrderNumberList[0]}
      <div className="pl-0.5 text-sw-primary">
        <Tooltip
          portal
          placement={'right'}
          tooltipContent={
            <>
              <div className="pb-2 font-bold">PO #</div>
              {purchaseOrderNumberList.slice(1).map((value, index) => {
                return <div key={index}>{value}</div>;
              })}
            </>
          }
        >
          +{purchaseOrderNumberList.length - 1}
        </Tooltip>
      </div>
    </div>
  ) : poNumber ? (
    <>{poNumber}</>
  ) : (
    <>--</>
  );
};
