import ListSelect from 'App/components/_tableFiltersWithSavedViews/filterComponents/listSelect';

export const filters = [
  {
    name: 'onlyMyShipments',
    type: ListSelect,
    label: 'Shipment Requests',
    options: [
      {label: 'My Shipments', value: true},
      {label: 'All Shipments', value: false}
    ]
  },
  {
    name: 'status',
    type: ListSelect,
    label: 'Status',
    options: [
      {label: 'Requested', value: 'REQUESTED'},
      {label: 'Booked', value: 'BOOKED'},
      {label: 'Unable to Book', value: 'UNABLE_TO_BOOK'},
      {label: 'Cancelled', value: 'CANCELLED'}
    ],
    allowMultiSelect: true,
    useArray: true
  }
];
