import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, SubmissionError} from 'redux-form';
import {Panel, Button, Image, ListGroup} from 'react-bootstrap';
import {placeholder} from 'App/utils/placeholders';
import * as documentActions from 'App/actions/documents';
import renderDropZone from 'App/formComponents/renderDropzone';
import validate from 'App/utils/validateVendorDocForm';
import NewDocumentsArray from 'App/components/DocumentsArray/NewDocumentsArray';
import renderTextArea from 'App/formComponents/renderTextArea';
import {unpackErrors} from 'App/utils/globals';

@connect(
  (state) => ({
    vendorDocForm: state.form.vendorDocForm,
    selectedRelationship: state.vendors.selectedRelationship,
    carrierDocuments: state.documents.carrierDocuments
  }),
  {...documentActions}
)
class VendorDocumentForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.onDocumentFormSubmit = this.onDocumentFormSubmit.bind(this);
    this.handleUploadDoc = this.handleUploadDoc.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.setSelectedDoc = this.setSelectedDoc.bind(this);
    this.activeClass = 'active';

    this.state = {
      showAddForm: false,
      showUpdateForm: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cancel && this.props.cancel !== nextProps.cancel) {
      this.handleCancel();
    }
  }

  fetchDocuments() {
    this.props.fetchCarrierDocuments(this.props.selectedRelationship.id).then(this.handleCancel());
  }

  showAddForm() {
    this.setState({
      showAddForm: true,
      showUpdateForm: false
    });
  }

  showUpdateForm(e) {
    this.setState({
      showAddForm: false,
      showUpdateForm: true
    });
    this.setSelectedDoc(e);
  }

  handleUploadDoc(e) {
    this.showAddForm();
    this.props.selectDocAdd();
    this.setSelectedDoc(e);
  }

  handleCancel() {
    this.props.reset();
    this.clearSelectedDoc();
    this.setState({
      showAddForm: false,
      showUpdateForm: false
    });
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) {}
    return el;
  }

  clearSelectedDoc() {
    const docs = document.querySelectorAll('.doc-container .active');
    docs.forEach((doc) => doc.classList.remove(this.activeClass));
  }

  setSelectedDoc(e) {
    const scrollTo = document.querySelector('.js-scrollto-form');

    this.clearSelectedDoc();
    if (scrollTo) {
      _.defer(() => {
        scrollTo.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  handleDeleteDoc(id) {
    const relationshipId = this.props.relationshipId;

    return this.props.deleteCarrierDocument(relationshipId, id).then((response) => {
      if (response.status === 200) {
        this.fetchDocuments();
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  selectDocEdit(doc) {
    this.setState({showAddForm: false, showUpdateForm: true});
    this.props.selectDocEdit(doc);
  }

  onDocumentFormSubmit() {
    const attrs = this.props.vendorDocForm.values;
    const documentId = attrs.id;
    const relationshipId = this.props.relationshipId;
    const docForm = {
      description: attrs.description || '',
      file: attrs.files
    };

    if (!documentId) {
      // post new document
      return this.props.postCarrierDocument(docForm, relationshipId).then((response) => {
        this.fetchDocuments();
      });
    }
    // update the document - carrierRelationshipId, documentId, body
    return this.props.putCarrierDocument(relationshipId, documentId, docForm).then((response) => {
      if (response.status === 200) {
        this.fetchDocuments();
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  printDocument(doc) {
    const mywindow = window.open('', 'PRINT', 'height=400px,width=600px');
    if (!mywindow) {
      return false;
    }
    mywindow.document.write('<html height="100%"><head><title>' + doc.filename + '</title>');
    mywindow.document.write('</head><body height="100%">');
    mywindow.document.write('<img style={{"maxHeight":"100%"}} width="100%" src="' + doc.file + '"/>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return;
  }

  render() {
    const {carrierDocuments, handleSubmit, pristine, error, isLoading, submitting} = this.props;

    const formValues = this.props.vendorDocForm.values;
    const isExisting = formValues && formValues.id ? true : false;
    const textSaving = isExisting ? 'Saving' : 'Uploading';
    const textSave = isExisting ? 'Save' : 'Upload';

    const footerButtons = (
      <div className="document-edit__footer">
        {formValues && formValues.filename && formValues.filename.indexOf('.pdf') === -1 && (
          <Button
            onClick={() => {
              this.printDocument(formValues);
            }}
            bsStyle="default"
          >
            <i className="icon icon-File pad-right" />
            Print
          </Button>
        )}

        <Button
          bsStyle="default"
          type="button"
          disabled={submitting}
          onClick={this.handleCancel}
          className="cancel-edit"
          name="cancel-edit"
        >
          Cancel
        </Button>

        <Button className="btn btn-primary" disabled={submitting || pristine} type="submit">
          {isLoading ? (
            <span>
              <i className="icon icon-Restart rotate" />
              &nbsp;&nbsp; {textSaving}
            </span>
          ) : (
            textSave
          )}
        </Button>
      </div>
    );

    const renderVendorDocImages = () => {
      if (!carrierDocuments) {
        return;
      }
      const sortedArray = carrierDocuments.results.sort((a, b) => {
        const keyA = new Date(a.updated_at);
        const keyB = new Date(b.updated_at);

        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
        return 0;
      });
      return (
        <NewDocumentsArray
          handleDocumentClick={(id, e) => {
            const doc = carrierDocuments.results.filter((doc) => doc.id === id)[0];
            this.props.selectDocEdit(doc);
            this.handleDeleteDoc(id);
          }}
          documents={sortedArray}
          selectDocEdit={(id, e) => {
            const doc = carrierDocuments.results.filter((doc) => doc.id === id)[0];
            this.setState({showAddForm: true});
            this.setSelectedDoc(e);
            this.selectDocEdit(doc, e);
          }}
        />
      );
    };

    const uploadButton = (
      <Button
        disabled={this.state.showAddForm}
        bsStyle="link"
        className="btn-tertiary"
        onClick={(e) => this.handleUploadDoc(e)}
      >
        <i className="flaticon-upload pad-right" />
        Upload Document
      </Button>
    );

    return (
      <div className="document-edit clearfix">
        {(!carrierDocuments || (carrierDocuments && carrierDocuments.total_count === 0)) && (
          <div className="document-edit__message">
            <p>No vendor documents to display.</p> <p>{uploadButton}</p>
          </div>
        )}

        {carrierDocuments && carrierDocuments.total_count > 0 && (
          <div className="doc-container">
            <ListGroup>{renderVendorDocImages()}</ListGroup>
            <p className="document-edit__message">{uploadButton}</p>
          </div>
        )}

        <Form
          onSubmit={handleSubmit(this.onDocumentFormSubmit)}
          encType="multipart/form-data"
          className={`document-edit__form ${
            this.state.showAddForm || this.state.showUpdateForm ? 'show-flex' : 'hidden'
          }`}
        >
          <div className="document-edit__details">
            <Field
              name="description"
              type="text"
              minRows={3}
              component={renderTextArea}
              placeholder={placeholder.docDescription}
              req
              label="Description"
            />

            {error && <p className="error-text text-center">{error}</p>}
          </div>
          {this.state.showAddForm && (
            <Panel className="document-edit__dropzone">
              <Field name="files" component={renderDropZone} id="dropzone-doc" />
            </Panel>
          )}
          {this.state.showUpdateForm && (
            <div className="document-edit__details">
              {formValues && formValues.file && formValues.file.indexOf('.pdf') === -1 && (
                <Image width="100%" height="auto" src={formValues.file} alt={formValues.filename} />
              )}
              {formValues && formValues.file && formValues.file.indexOf('.pdf') !== -1 && (
                <Panel className="movie-detail">
                  <object
                    aria-label={`${formValues.filename}`}
                    style={{margin: '0px auto'}}
                    type="application/pdf"
                    data={formValues.file}
                    width="100%"
                    height="400px"
                  />
                </Panel>
              )}
              <p>
                If the image above doesn't load, you can access it{' '}
                <a href={formValues && formValues.file} rel="noopener noreferrer" target="_blank">
                  here
                </a>
              </p>
            </div>
          )}
          {footerButtons}
        </Form>
        <span className="js-scrollto-form" />
      </div>
    );
  }
}

VendorDocumentForm = reduxForm({
  form: 'vendorDocForm',
  touchOnChange: true, // react-dropzone doesn't blur
  enableReinitialize: true,
  validate: validate
})(VendorDocumentForm);

VendorDocumentForm = connect((state) => ({
  initialValues: state.documents.selectedDocument
}))(VendorDocumentForm);

export default VendorDocumentForm;
