import {Stop} from '@shipwell/backend-core-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ServiceReferenceType} from '@shipwell/corrogo-sdk';
import pick from 'lodash/pick';
import {
  isServiceStageType,
  StageType,
  LEGACY_SHIPMENT_MODES_CONSTANTS
} from 'App/containers/shipments/components/DashboardSummary/utils';

// resource is the resource we are copying from, stageType is the type of the resource we are copying to
export const getInvisibleDataFromLegacyShipment = (
  resource: Shipment,
  stageType: StageType,
  cloneContainer = false
) => {
  if (!stageType) {
    return {};
  }
  const firstStop = resource?.stops?.sort((stopOne, stopTwo) => stopOne.ordinal_index - stopTwo.ordinal_index)[0];
  const lastStop = resource?.stops?.sort((stopOne, stopTwo) => stopTwo.ordinal_index - stopOne.ordinal_index)[0];
  // get mode of shipment we're copying from
  const resourceMode = resource.mode?.code;

  // separated these out into sections for organization
  const baseReferences = pick(
    resource,
    ['name', 'customer_reference_number', 'custom_data', 'purchase_order_number'],
    cloneContainer ? ['bol_number', 'pickup_number', 'pro_number'] : []
  );

  const drayageReferences: Partial<Shipment> =
    resourceMode === LEGACY_SHIPMENT_MODES_CONSTANTS.DRAYAGE
      ? cloneContainer
        ? pick(resource, 'drayage_booking_number', 'drayage_carrier_scac_code', 'drayage_house_bol_number')
        : pick(resource, 'drayage_container_number', 'drayage_seal_number', 'drayage_house_bol_number')
      : {};
  // ui for editing line items, visible in clone container flow,
  // trumps the 'invisible' migration operation.
  const lineItems = !cloneContainer ? pick(resource, 'line_items') : {};

  const serviceLevel =
    resourceMode !== LEGACY_SHIPMENT_MODES_CONSTANTS.DRAYAGE && stageType !== LEGACY_SHIPMENT_MODES_CONSTANTS.DRAYAGE
      ? pick(resource, 'service_level')
      : {};

  const customer = pick(resource, 'customer');

  const tags = pick(resource.metadata, 'tags');

  const StopFieldsToCopy = [
    'custom_data',
    'contact_information',
    'instructions',
    'accessorials',
    'planned_date',
    'planned_time_window_start',
    'appointment_type'
  ];
  //when we clone a container, pickup stop gets mapped to new pickup stop. When adding a new stage,
  //we map the pickup stop to the dropoff stop.
  const pickupStop =
    cloneContainer && firstStop ? pick(firstStop, StopFieldsToCopy) : lastStop ? pick(lastStop, StopFieldsToCopy) : {};

  //we only copy delivery stop data for the cloning flow.
  const deliveryStop = cloneContainer && lastStop ? pick(lastStop, StopFieldsToCopy) : {};

  const getStopLocationData = (stop: Stop) => {
    return {
      ...stop.location,
      location_name: `${stop.location.address?.city || ''}, ${stop.location.address?.country}, ${
        stop.location.address?.postal_code || ''
      }`.slice(0, 30)
    };
  };
  const pickupStopLocation =
    cloneContainer && firstStop ? getStopLocationData(firstStop) : lastStop ? getStopLocationData(lastStop) : {};

  const deliveryStopLocation = cloneContainer && lastStop ? getStopLocationData(lastStop) : {};

  // LTL/VLTL shipments should not have or receive bill to data.
  const billToOverride =
    stageType !== LEGACY_SHIPMENT_MODES_CONSTANTS.LTL &&
    resourceMode !== LEGACY_SHIPMENT_MODES_CONSTANTS.LTL &&
    stageType !== LEGACY_SHIPMENT_MODES_CONSTANTS.VLTL &&
    resourceMode !== LEGACY_SHIPMENT_MODES_CONSTANTS.VLTL
      ? pick(resource.metadata, 'bill_to_override')
      : {};

  return isServiceStageType(stageType)
    ? {
        references: [
          // v3 service objects expect a qualifier/value format
          ...(baseReferences.customer_reference_number // we only want to conditionally add these references if they are a non-empty string
            ? [
                {
                  qualifier: ServiceReferenceType.CustomerReferenceNumber,
                  value: baseReferences.customer_reference_number
                }
              ]
            : []),
          ...(baseReferences.purchase_order_number
            ? [{qualifier: ServiceReferenceType.PoNumber, value: baseReferences.purchase_order_number}]
            : []),
          ...(drayageReferences.drayage_container_number
            ? [
                {
                  qualifier: ServiceReferenceType.ContainerNumber,
                  value: drayageReferences.drayage_container_number
                }
              ]
            : [])
        ],
        custom_data: {
          ...baseReferences.custom_data,
          ...(resource.customer?.id ? {shipwell_custom_data: {customer_tenant_id: resource.customer?.id}} : {})
        }
      }
    : {
        topLevelFields: {...baseReferences, ...drayageReferences, ...lineItems, ...customer, ...serviceLevel},
        metadata: {...tags, ...billToOverride},
        pickupStop: pickupStop,
        pickupStopLocation: pickupStopLocation,
        deliveryStop: deliveryStop,
        deliveryStopLocation: deliveryStopLocation
      };
};
