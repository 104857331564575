import _ from 'lodash';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {Link} from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Form, FormGroup, FormControl} from 'react-bootstrap';
import {SubmissionError} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as actions from 'App/actions/vendors';
import * as userActions from 'App/actions/users';
import * as documentActions from 'App/actions/documents';
import {unpackErrors} from 'App/utils/globals';
import './_manage-vendors.scss';
import ShipwellLoader from 'App/common/shipwellLoader/index';

export class SearchVendor extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.selectCarrier = this.selectCarrier.bind(this);
    this.searchDataTable = this.searchDataTable.bind(this);
    this.searchForCarrier = _.debounce(this.searchForCarrier.bind(this), 500);
    this.goToManual = this.goToManual.bind(this);
    this.state = {
      searching: false,
      vendorFound: false,
      pagination: {
        page: 1,
        pageSize: 30,
        q: ''
      }
    };
    this.timer = null;
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  goToManual() {
    this.props.clearSelectedCarrier();
    this.props.clearSelectedRelationship();
    if (!this.props.showInModal) {
      this.context.router.push('/manual_carrier');
    } else {
      this.props.switchScreen('manual');
    }
  }

  searchDataTable(e) {
    e.preventDefault();
    const pagination = this.state.pagination;
    pagination.q = e.target.value;
    pagination.page = 1;
    this.setState(
      {
        pagination: pagination,
        searching: true
      },

      function () {
        this.props.clearCarriers();
        if (pagination.q) {
          this.searchForCarrier();
        } else {
          this.setState({searching: false});
        }
      }.bind(this)
    );
  }

  searchForCarrier() {
    return this.props.searchForCarrier(this.state.pagination).then((response) => {
      if (response.status === 200) {
        this.setState({
          searching: false
        });
      }
    });
  }

  selectCarrier(usdot) {
    this.props.clearSelectedRelationship();
    this.props.clearSelectedCarrier();

    this.props.getCarrier({usdot: usdot}).then((response) => {
      if (response.status === 200) {
        if (response.details.existing_vendor_relationship) {
          this.context.router.push('carriers/' + response.details.existing_vendor_relationship);
        } else if (this.props.showInModal) {
          this.props.switchScreen('new');
        } else {
          this.context.router.push('/new_carrier');
        }
      } else {
        const errors = response.field_errors || [];
        let myError = {};
        const submissionError = {};
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;
        //handle edge cases for errors here
        //user emails are in the form as company email

        throw new SubmissionError(submissionError);
      }
    });
  }

  render() {
    const {showInModal, carriersSearchResult, isFedExEnabled, isManageIntegrationsPermitted} = this.props;
    return (
      <div className={showInModal ? '' : 'content-wrapper manage-carriers'}>
        <section className="content">
          <div className="vendors">
            <div className="vendors__searchForm">
              <Form className="manage-shippers__search-form">
                <FormGroup>
                  <div className="vendors__search-header">
                    <p>Search Carrier by name, USDOT, or MC #</p>
                    <a role="button" id="manually-enter-vendor-details" onClick={this.goToManual}>
                      <b>Manually Enter Carrier Details</b>
                    </a>
                  </div>
                  <FormControl
                    name="search"
                    type="text"
                    placeholder="e.g., 123456"
                    onChange={this.searchDataTable}
                    inputRef={(ref) => {
                      this.input = ref;
                    }}
                  />
                </FormGroup>
              </Form>
            </div>

            <div className="vendors__searchResult">
              {this.state.searching ? (
                <ShipwellLoader loading={this.state.searching} />
              ) : carriersSearchResult && carriersSearchResult.results.length > 0 ? (
                <Grid container className="vendors__searchItems">
                  {carriersSearchResult.results.map((el, idx) => {
                    const address = [];
                    if (el.physical_street) {
                      address.push(el.physical_street);
                    }
                    if (el.physical_city) {
                      address.push(el.physical_city);
                    }
                    if (el.physical_state && el.physical_postal_code) {
                      address.push(el.physical_state + ' ' + el.physical_postal_code);
                    } else if (el.physical_state) {
                      address.push(el.physical_state);
                    } else if (el.physical_postal_code) {
                      address.push(el.physical_postal_code);
                    }
                    return (
                      <Grid
                        item
                        xs={12}
                        key={el.usdot}
                        onClick={() => {
                          this.selectCarrier(el.usdot);
                        }}
                      >
                        <div>
                          <Grid container className="vendor-container" justify="space-between">
                            {el.name && el.dba_name ? (
                              <Grid item md={2} sm={6} xs={12}>
                                <div className="vendor-item-title">Name</div>
                                <p>
                                  {el.dba_name} ({el.name})
                                </p>
                              </Grid>
                            ) : (
                              <Grid item md={2} sm={6} xs={12}>
                                <div className="vendor-item-title">Name</div>
                                <p>{el.name}</p>
                              </Grid>
                            )}

                            <Grid item md={2} sm={6} xs={12}>
                              <div className="vendor-item-title">USDOT</div>
                              <p>{el.usdot ? el.usdot : '-'}</p>
                            </Grid>
                            <Grid item md={2} sm={6} xs={12}>
                              <div className="vendor-item-title">MC Number</div>
                              <p>{el.mc_number ? el.mc_number : '-'}</p>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <div className="vendor-item-title">Address</div>
                              <p>{address.join(', ')}</p>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : carriersSearchResult ? (
                <Grid item xs={12}>
                  <Paper>
                    <Grid container className="no-vendor">
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="vendor-item-title">
                          Carrier not found. Please try again or manually enter details.
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ) : (
                ''
              )}
              {isFedExEnabled && isManageIntegrationsPermitted && (
                <div className="fexex-registration-link">
                  <p>Connect a carrier partner to view custom rates.</p>
                  <Link to="/fedex">
                    <img src="/images/fedex_logo.svg" width="130" />
                  </Link>
                  <Link to="/ups">
                    <img src="/images/ups_logo.svg" width="80" />
                  </Link>
                  <Link to="/amazon">
                    <img src="/images/amazon_logo.svg" width="80" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carrierTags: state.vendors.carrierTags,
    carrierRelationships: state.vendors.carrierRelationships,
    isLoading: state.vendors.isLoading,
    company: state.auth.company,
    companyId: state.auth.company.id,
    selectedCarrier: state.vendors.selectedCarrier,
    selectedRelationship: state.vendors.selectedRelationship,
    identifyingCodes: state.users.identifyingCodes,
    carriersSearchResult: state.vendors.carriersSearchResult,
    isFedExEnabled: state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.fedex_enabled,
    isManageIntegrationsPermitted:
      state.auth.user &&
      state.auth.user.permissions &&
      state.auth.user.permissions.includes('integrations.manage_integrations')
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions, ...documentActions, ...userActions}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchVendor);
