import {useState} from 'react';
import {object, string} from 'yup';
import {Formik, Field, Form} from 'formik';
import {DeprecatedButton, Card, FormikTextInput, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {NetsuiteSuiteAppConfig, NetsuiteSuiteAppConfigAuthTypeEnum} from '@shipwell/backend-core-sdk';
import pick from 'lodash/pick';
import {useQueryClient} from '@tanstack/react-query';
import {FlexBox, GridBox} from 'App/components/Box';
import {CertificateAndKeySection} from 'App/formComponents/forms/suiteAppConfiguration/CertificateAndKeySection';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';
import Loader from 'App/common/shipwellLoader';
import useToggle from 'App/utils/hooks/useToggle';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {
  useConnectSuiteApp,
  useCreateSuiteAppConfig,
  useDisconnectSuiteApp,
  useGetSuiteAppConfig,
  useRefreshSuiteAppConfig,
  useValidateSuiteAppConnection
} from 'App/formComponents/forms/suiteAppConfiguration/hooks/index';

export const initialValues = {
  account_id: '',
  certificate_id: ''
};

export const validationSchema = object().shape({
  account_id: string().required('Account number is required.'),
  certificate_id: string().required('Certificate ID is required.')
});

const SuiteAppConfigurationForm = ({setSuccess, setError}: WithStatusToastProps) => {
  const [showDisconnectModal, toggleShowDisconnectModal] = useToggle(false);
  const [showGenerateNewKeyModal, toggleShowGenerateNewKeyModal] = useToggle(false);
  const [suiteAppCertId, setSuiteAppCertId] = useState<NetsuiteSuiteAppConfig['certificate_id']>();
  const queryClient = useQueryClient();
  const {suiteAppConfig, isSuiteAppConfigLoading} = useGetSuiteAppConfig();
  const {createSuiteAppConfig, isCreateSuiteAppConfigLoading} = useCreateSuiteAppConfig();
  const {connectSuiteApp, isConnectSuiteAppLoading} = useConnectSuiteApp();
  const {disconnectSuiteApp, isDisconnectSuiteAppLoading} = useDisconnectSuiteApp();
  const {generateNewKey, isGenerateNewKeyLoading} = useRefreshSuiteAppConfig();
  const {isValidateSuiteAppConnectionLoading} = useValidateSuiteAppConnection(suiteAppCertId, {
    onSuccess: () => {
      void queryClient.invalidateQueries([SUITE_APP_CONFIG_QUERY]);
      setSuccess('Success', 'You’ve successfully connected to SuiteApp.');
      setSuiteAppCertId('');
    },
    onError: (error) => {
      disconnectSuiteApp(
        {},
        {
          onSuccess: () => {
            setSuiteAppCertId('');
          }
        }
      );
      setError('Error', error.response?.data?.non_field_errors?.join('\r\n'));
    }
  });

  const handleSubmit = ({
    account_id,
    certificate_id
  }: Pick<NetsuiteSuiteAppConfig, 'account_id' | 'certificate_id'>) => {
    if (!account_id || !certificate_id) return;
    connectSuiteApp(
      {
        account_id,
        auth_type: NetsuiteSuiteAppConfigAuthTypeEnum.Oauth2M2M,
        certificate_id
      },
      {
        onSuccess: (data) => setSuiteAppCertId(data.data?.certificate_id)
      }
    );
  };

  const handleGenerateKey = ({account_id}: Pick<NetsuiteSuiteAppConfig, 'account_id' | 'certificate_id'>) => {
    if (!account_id) return;
    createSuiteAppConfig({
      account_id,
      auth_type: NetsuiteSuiteAppConfigAuthTypeEnum.Oauth2M2M
    });
  };

  const shouldDisableGenerateKey =
    !!(suiteAppConfig?.public_certificate && suiteAppConfig?.public_key) || isCreateSuiteAppConfigLoading;

  const shouldDisableConnect = !(suiteAppConfig?.public_certificate && suiteAppConfig?.public_key);

  if (isSuiteAppConfigLoading) {
    return <Loader loading={isSuiteAppConfigLoading} />;
  }
  return (
    <>
      <Card
        title={
          <div className="flex w-full items-center justify-between">
            <img alt="integration-logo" className="mr-2 max-h-6" src="/images/integration-netsuite.png" />
            <span>NetSuite Configuration</span>
          </div>
        }
        draggableProvided={false}
      >
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={{...initialValues, ...pick(suiteAppConfig, 'account_id', 'certificate_id')}}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values}) => (
            <Form>
              <GridBox cols={2} gap="m">
                <Card title="Account Details" draggableProvided={false} bodyClassName="p-4">
                  <div className="grid gap-4">
                    <Field name="account_id" label="NetSuite Account Number" component={FormikTextInput} required />
                    <Field name="certificate_id" label="NetSuite Cert ID" component={FormikTextInput} required />
                    <FlexBox direction="row" justify="end" gap="m">
                      <DeprecatedButton
                        size="small"
                        className="h-7 px-6"
                        disabled={shouldDisableGenerateKey || !values.account_id}
                        onClick={() => handleGenerateKey(values)}
                      >
                        {isCreateSuiteAppConfigLoading ? <SvgIcon name="LoadingDots" /> : 'Generate Key'}
                      </DeprecatedButton>
                    </FlexBox>
                  </div>
                </Card>
                <CertificateAndKeySection
                  suiteAppConfig={suiteAppConfig}
                  toggleShowGenerateNewKeyModal={toggleShowGenerateNewKeyModal}
                  isGenerateNewKeyLoading={isGenerateNewKeyLoading}
                />
              </GridBox>
              <div className="mt-6 flex flex-row items-center justify-end">
                {suiteAppConfig?.certificate_id ? (
                  <DeprecatedButton
                    variant="warning"
                    disabled={isDisconnectSuiteAppLoading}
                    onClick={() => toggleShowDisconnectModal()}
                  >
                    {isDisconnectSuiteAppLoading ? <SvgIcon name="LoadingDots" /> : 'Disconnect'}
                  </DeprecatedButton>
                ) : (
                  <DeprecatedButton
                    variant="primary"
                    type="submit"
                    disabled={
                      shouldDisableConnect ||
                      !values.certificate_id ||
                      isValidateSuiteAppConnectionLoading ||
                      isConnectSuiteAppLoading
                    }
                  >
                    {isValidateSuiteAppConnectionLoading || isConnectSuiteAppLoading ? (
                      <SvgIcon name="LoadingDots" />
                    ) : (
                      'Connect'
                    )}
                  </DeprecatedButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <Modal
        show={showDisconnectModal}
        title="Disconnect Netsuite SuiteApp"
        variant="warning"
        size="small"
        onClose={() => toggleShowDisconnectModal()}
        secondaryBtnName="No, Go Back"
        primaryBtnName="Yes, Disconnect"
        onPrimaryAction={() => {
          toggleShowDisconnectModal();
          disconnectSuiteApp(
            {},
            {
              onSuccess: () => {
                setSuccess('Success', 'You’ve successfully disconnected from SuiteApp.');
                setSuiteAppCertId('');
              }
            }
          );
        }}
      >
        <p className="font-bold">Are you sure you want to disconnect?</p>
        This will remove the SuiteApp connection from your Shipwell account
      </Modal>
      <Modal
        show={showGenerateNewKeyModal}
        title="Generate New Key"
        variant="warning"
        size="small"
        onClose={() => toggleShowGenerateNewKeyModal()}
        secondaryBtnName="No, Go Back"
        primaryBtnName="Yes, Generate Key"
        onPrimaryAction={() => {
          toggleShowGenerateNewKeyModal();
          generateNewKey(
            {},
            {
              onSuccess: () => setSuccess('Success', 'A new SuiteApp Key has been generated!')
            }
          );
        }}
      >
        <p className="font-bold">Are you sure you want to generate a new key?</p>
        This will disconnect SuiteApp from your Shipwell account and require you to reconnect
      </Modal>
    </>
  );
};

export default WithStatusToasts(SuiteAppConfigurationForm);
