import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Get contracts
 */
export async function getContracts(opts = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.contractsGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * Get contract by id
 */
export async function getContract(contractId) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.contractsContractIdGet(contractId, apiCallback(resolve, reject));
  });
}
