/* global google */
import {Component} from 'react';
import {options} from '../utils/map_options';
import {bind} from 'App/utils/camelize';
import {loadGoogleMapsAPI} from 'App/utils/globals';

const localMarkerArray = [];

export default class RenderMap extends Component {
  constructor(props) {
    super(props);

    bind(this, ['putMarkersOnMap', 'resetMap', 'initialize', 'triggerRefresh']);
  }

  componentDidMount() {
    //make sure google maps API is loaded then proceed
    if (typeof google !== 'undefined') {
      this.initialize(this.triggerRefresh);
    } else {
      loadGoogleMapsAPI(() => {
        this.initialize(this.triggerRefresh);
      });
    }
  }

  initialize(callback) {
    if (this.props.maxZoom) {
      //in some cases, we want to prevent zooming in too far, like if the lat/lng won't be exact. so we override maxZoom
      options.maxZoom = this.props.maxZoom;
    }
    this.map = new google.maps.Map(this.refs.map, options);

    window.infowindow = new google.maps.InfoWindow(options.infoWindow);

    callback();
  }

  triggerRefresh() {
    //still cant seem to prevent google map from rendering gray, but at least this is only called once
    setTimeout(
      function () {
        google.maps.event.trigger(this.map, 'resize');
        if (this.props.markerArray && this.props.markerArray.length > 0) {
          this.resetMap();
          //put the markers on the map
          this.putMarkersOnMap(this.props.markerArray);
        } else {
          this.resetMap();
        }
      }.bind(this),
      1500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (typeof google !== 'undefined') {
      if (this.props.markerArray !== nextProps.markerArray) {
        if (nextProps.markerArray.length > 0) {
          this.putMarkersOnMap(nextProps.markerArray);
        } else {
          this.resetMap();
        }
      }
    }
  }

  resetMap() {
    //reset the map to the default zoom and center if no markers
    this.map.setCenter(options.center);
    this.map.setZoom(options.zoom);
    for (var i = 0; i < localMarkerArray.length; i++) {
      localMarkerArray[i].setMap(null);
    }
    localMarkerArray.length = 0;
  }

  putMarkersOnMap(markerArray) {
    for (var i = 0; i < localMarkerArray.length; i++) {
      localMarkerArray[i].setMap(null);
    }
    //put the markers on the map
    const mapBounds = new google.maps.LatLngBounds();

    for (var i = 0; i < markerArray.length; i++) {
      var icon;
      if (markerArray[i].markerNumber) {
        icon = {
          url: '/images/map/marker-' + markerArray[i].markerNumber + '.png'
        };
      }

      const marker = new google.maps.Marker({
        position: markerArray[i].position,
        map: this.map,
        label: markerArray[i].label,
        icon: icon
      });
      if (markerArray[i].labelContent) {
        marker.set('labelContent', markerArray[i].labelContent);
      }
      localMarkerArray.push(marker);
      const string = markerArray[i].info;
      //show infowindow on hover on marker
      if (string) {
        marker.addListener('mouseover', () => {
          window.infowindow.setContent(string);
          window.infowindow.open(this.map, marker);
        });
        marker.addListener('mouseout', () => {
          window.infowindow.close(this.map, marker);
        });
      }
      mapBounds.extend(marker.position);
    }
    if (this.map) {
      this.map.fitBounds(mapBounds);
    }
  }

  render() {
    return <div id={this.props.mapID} ref="map" />;
  }
}
