import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {connect} from 'react-redux';
import {Button, Modal} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import PageHeader from 'App/common/pageHeader';
import ContractCreateContainer from 'App/containers/contracts/create';
import {deleteCarrierContract} from 'App/api/carriers';
import './styles.scss';
import {CONTRACTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useGetContract} from 'App/data-hooks/contracts/useGetContract';
import {RATE_TYPE_RATE_TABLE} from 'App/containers/userCompany/rateTables/constants';

const ContractDetails = (props) => {
  const [cloningContract, setCloningContract] = useState(null);
  const [deletingContract, setDeletingContract] = useState(false);

  const {params, router, setError, setSuccess} = props;

  const queryClient = useQueryClient();

  const {contractId} = params;
  const {contractData, contractStatus} = useGetContract({contractId});

  const deleteContract = async (contract) => {
    try {
      const response = await deleteCarrierContract(contract.carrier_relationship, contract.id);
      if (response && response.statusCode === 204) {
        router.push(`/company/lane-management/contracts?showDeleteSuccess=${contract.carrier_name}`);
      }
    } catch (error) {
      setError('Contract Not Deleted.', error.error_description);
    }
  };

  /** Cancel update */
  const handleCancel = () => {
    router.push('/company/lane-management/contracts');
  };

  const handleSaveSuccess = () => {
    queryClient.invalidateQueries([CONTRACTS_QUERY_KEY, contractId]);
    setSuccess('Contract Updated!', 'Contract successfully updated.');
  };

  const handleCloneSuccess = (shouldClone = false) => {
    setCloningContract(null);
    setSuccess('Contracts Saved!', 'Your contracts were saved to your contract list.');
    if (shouldClone) {
      setCloningContract(shouldClone);
    }
  };

  const {carrierRelationship, canDeleteContracts, canEditContracts} = props;
  return (
    <div className="contracts__wrapper">
      <div className="sticky top-0 z-10 bg-sw-background">
        <PageHeader
          title={contractData ? contractData.name : 'Contract'}
          backArrow
          actions={
            contractData && contractData.rate_type !== RATE_TYPE_RATE_TABLE ? (
              <Button
                onClick={() => setCloningContract({...contractData, name: 'Copy of ' + contractData.name})}
                variant="tertiary"
                isCompact
                iconName="Copy"
              >
                Clone Contract
              </Button>
            ) : null
          }
        />
      </div>
      {contractStatus === 'loading' ? (
        <ShipwellLoader loading />
      ) : (
        <div className="contracts__details">
          <ContractCreateContainer
            onCancel={handleCancel}
            onSubmitSuccess={handleSaveSuccess}
            values={{
              ...contractData,
              carrier: carrierRelationship
                ? {shipwell_vendor: {name: carrierRelationship.label}, id: carrierRelationship.value}
                : null
            }}
            setError={setError}
            router={router}
            readOnly={!canEditContracts}
            isEdit
            carrierRelationship={carrierRelationship}
          />
          {canDeleteContracts && (
            <div className="contracts__details-footer">
              <Button onClick={() => setDeletingContract(contractData)} color="warning">
                Delete Contract
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        show={cloningContract}
        title={cloningContract ? 'Clone Contract' : 'Add a Contract'}
        footerComponent={null}
        onClose={() => setCloningContract(null)}
      >
        <ContractCreateContainer
          {...props}
          cloningContract={cloningContract}
          onCancel={() => setCloningContract(null)}
          onSubmitSuccess={handleCloneSuccess}
          setError={setError}
        />
      </Modal>
      <Modal
        variant="warning"
        show={Boolean(deletingContract)}
        title="Delete Contract"
        primaryBtnName="Delete Contract"
        onClose={() => setDeletingContract(null)}
        onPrimaryAction={() => deleteContract(deletingContract)}
      >
        <p>{`Are you sure you want to delete ${deletingContract?.name}?`}</p>
      </Modal>
    </div>
  );
};
export default connect((state) => ({
  canDeleteContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.delete_contracts')
}))(ContractDetails);
