import {useFormikContext, ArrayHelpers} from 'formik';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';
import {CreateChargeLegItem, Charge} from '@shipwell/corrogo-sdk';
import {Title, Card, Pill, DeprecatedButton, SvgIcon, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';
import useRateCalculationsFromValues from 'App/containers/shipments/components/TenderFields/useRateCalculationsFromValues';
import {useShipmentLegs} from 'App/data-hooks';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {checkIsOverweight} from 'App/containers/shipments/utils/typed';
import type {TenderFormValues} from 'App/containers/shipments/components/TenderFields/validation';
import {calculateTotalCharge} from 'App/containers/shipments/utils/tenderRateCalculations';

const AccessorialDisplayRow = ({
  hasEmphasis = false,
  title,
  value,
  onRemove
}: {
  title: string;
  value: string;
  hasEmphasis?: boolean;
  onRemove?(): void;
}) => (
  <div className="flex items-center justify-between">
    <div
      className={classnames({
        'font-bold': hasEmphasis
      })}
    >
      {title}
    </div>
    <div
      className={classnames({
        'font-bold': hasEmphasis
      })}
    >
      <div className={classnames('flex flex-row items-center', {'mr-2': !onRemove})}>
        <div className="pr-2">{value}</div>
        {onRemove ? (
          <div className="-mr-6">
            <DeprecatedButton variant="icon" onClick={onRemove}>
              <SvgIcon name="RemoveCircleOutlined" />
            </DeprecatedButton>
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

const ContainerCardTitle = ({
  label,
  isOverweight,
  isHazmat
}: {
  label: string;
  isOverweight?: boolean;
  isHazmat?: boolean;
}) => (
  <>
    <div className={classnames({'border-black border-r-2 pr-2': isOverweight || isHazmat})}>{label}</div>
    <div className="flex flex-row gap-2 font-normal">
      {isOverweight ? <Pill variant="default">Overweight</Pill> : null}
      {isHazmat ? <Pill variant="default">Hazmat</Pill> : null}
    </div>
  </>
);

export const ContainerCard = ({
  label,
  isHazmat,
  isOverweight,
  lineHaulAndFuelSurchargeTotal,
  containerSpecificDebits,
  containerSpecificTotal,
  onRemove
}: {
  label: string;
  isHazmat?: boolean;
  isOverweight?: boolean;
  lineHaulAndFuelSurchargeTotal: number;
  containerSpecificDebits: CreateChargeLegItem[] | Charge[];
  containerSpecificTotal: number;
  onRemove?: (debitToRemove: CreateChargeLegItem) => void;
}) => (
  <Card
    draggableProvided={null}
    title={<ContainerCardTitle label={label} isHazmat={isHazmat} isOverweight={isOverweight} />}
    isCollapsible
  >
    <CollapsibleCardContent>
      <div className="grid grid-auto-rows-8">
        <AccessorialDisplayRow
          title="Line Haul and Fuel Surcharge"
          value={formatCurrency(lineHaulAndFuelSurchargeTotal)}
        />
        {containerSpecificDebits?.map((debit, index) => {
          return (
            <AccessorialDisplayRow
              key={index}
              title={`${debit.name || ''}${debit.quantity > 1 ? ` (x ${debit.quantity})` : ''}`}
              value={formatCurrency(debit.unit_amount.value)}
              onRemove={onRemove ? () => onRemove(debit) : undefined}
            />
          );
        })}
        <AccessorialDisplayRow hasEmphasis title="Container Total" value={formatCurrency(containerSpecificTotal)} />
      </div>
    </CollapsibleCardContent>
  </Card>
);

const ContainersView = ({shipmentId, remove}: {shipmentId: string} & Pick<ArrayHelpers, 'remove'>) => {
  const {
    values: {containers, debits}
  } = useFormikContext<TenderFormValues>();

  const {singleContainerRate} = useRateCalculationsFromValues();

  const shipmentLegsQuery = useShipmentLegs(shipmentId, {
    staleTime: HOT_DATA_STALE_TIME
  });

  if (shipmentLegsQuery.isInitialLoading) {
    return null;
  }

  const overweightLegIds = shipmentLegsQuery.data
    ?.filter((container) =>
      checkIsOverweight(container?.leg_items?.[0].gross_weight.value, container?.leg_items?.[0].gross_weight.unit)
    )
    .map((leg) => leg.id);

  const hazmatLegIds = shipmentLegsQuery.data
    ?.filter((container) => container?.leg_items?.[0].hazmat)
    .map((leg) => leg.id);

  return (
    <div className="h-80 overflow-auto pb-10">
      <Title variant="sectionTitle"> Containers ({containers.length})</Title>
      <div className="grid gap-y-4">
        {containers.map((container) => {
          const containerSpecificDebits = debits.filter(
            (debit) => debit.leg_item_id === shipmentLegsQuery.getItemFromLeg(container.value)?.id
          );
          const containerSpecificTotal = singleContainerRate + calculateTotalCharge(containerSpecificDebits);

          const isOverweight = overweightLegIds?.includes(container.value);
          const isHazmat = hazmatLegIds?.includes(container.value);

          return (
            <ContainerCard
              key={container.value}
              label={container.label}
              isHazmat={isHazmat}
              isOverweight={isOverweight}
              lineHaulAndFuelSurchargeTotal={singleContainerRate}
              containerSpecificDebits={containerSpecificDebits}
              containerSpecificTotal={containerSpecificTotal}
              onRemove={(debitToRemove: CreateChargeLegItem) =>
                remove(debits.findIndex((currentDebit) => isEqual(currentDebit, debitToRemove)))
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ContainersView;
