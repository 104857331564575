import {useState, useCallback} from 'react';
import classNames from 'classnames';
import {Field, useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {Link} from 'react-router';
import {
  Card,
  CollapsibleCardContent,
  FormikToggleSwitch,
  SvgIcon,
  Tooltip,
  DeprecatedButton,
  Title,
  Dropdown
} from '@shipwell/shipwell-ui';
import {headers, alertTypeConstants} from '../../compassAlertsConstants';
import CompassAlertsModal from '../compassAlertsModal';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';
import {postAlertsConfiguration, deleteAlertConfiguration, updateAlertConfiguration} from 'App/api/shipwellUI';

const AlertHeader = () => {
  return (
    <div className="col-span-full grid grid-cols-[0.5fr,1fr,2fr,0.5fr,0.5fr,0.3fr] pb-1 pt-3">
      <span className="flex text-xxs">
        {headers.statusHeader}
        <Tooltip tooltipContent="For users at your company to view this Alert Tile, the status must be ‘ON’.">
          <SvgIcon name="InfoOutlined" className="-mt-1.5 ml-1" />
        </Tooltip>
      </span>
      {headers.remainingHeaders.map((header) => (
        <span key={header} className="text-xxs">
          {header}
        </span>
      ))}
    </div>
  );
};

const AlertRow = ({row, alertType, fetchAlerts, tagsToFilter, onSuccess, onError, duplicateNames}) => {
  const {values} = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const updateAlert = useCallback(
    async (values) => {
      let threshold = null;
      if (values.threshold) {
        if (row?.threshold?.unit) {
          threshold = {value: values.threshold, unit: row.threshold.unit};
        } else {
          threshold = {value: values.threshold};
        }
      }
      try {
        await updateAlertConfiguration(row.id, {
          type: row.type,
          name: values.name,
          manual_resolve: values.manualResolve,
          threshold: threshold
        });
        onSuccess({title: 'Changes Saved!', message: 'Your alert tile configuration was saved successfully.'});

        await fetchAlerts();
      } catch (error) {
        console.error('Error in updateAlert()', error);
        onError({
          title: 'Something Went Wrong',
          message: 'Your alert tile configuration was not saved successfully. Please try again.'
        });
      }
      setShowModal(false);
    },
    [fetchAlerts, row, onSuccess, onError]
  );

  const deleteAlert = useCallback(async () => {
    try {
      await deleteAlertConfiguration(row.id);
      await fetchAlerts();
    } catch (error) {
      console.error('Error in deleteAlert()', error);
    }
  }, [fetchAlerts, row.id]);

  return (
    <div className="col-span-full grid grid-cols-[0.5fr,1fr,2fr,0.5fr,0.5fr,0.3fr] border-t border-sw-border pb-1 pt-3">
      <div className="-mt-3">
        <Field checkedLabel="ON" uncheckedLabel="OFF" name={row.id} component={FormikToggleSwitch} />
      </div>
      <span
        className={classNames({
          'text-sw-form-helper-text': !values[row.id]
        })}
      >
        {row.name}
      </span>
      <span
        className={classNames({
          'text-sw-form-helper-text': !values[row.id]
        })}
      >
        {alertType?.short_description}
      </span>
      <span
        className={classNames({
          'text-sw-form-helper-text': !values[row.id]
        })}
      >
        {row.threshold?.value
          ? `${row.threshold.value} ${
              row.threshold?.unit ? `${pluralize(row.threshold.unit, row.threshold.value)}` : ''
            }`
          : '--'}
      </span>
      <div
        className={classNames('flex justify-end -mt-1', {
          'rowContainer-dropdown': !alertType?.unique
        })}
      >
        {alertType?.unique ? (
          <DeprecatedButton
            icon={<SvgIcon name="Edit" title="editIcon" color="$sw-icon" />}
            variant="icon"
            className="rowContainer-button"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <Dropdown
            variant="icon"
            drop="left"
            indicator={false}
            icon={<SvgIcon name="Overflow" title="overflowIcon" />}
          >
            {({onClick}) => (
              <>
                <li
                  onClick={() => {
                    setShowModal(true);
                    onClick();
                  }}
                >
                  Edit
                </li>
                <DeleteWarningTooltip
                  title="Delete Alert Configuration"
                  primaryActionText="Delete"
                  onDelete={deleteAlert}
                  message={<span className="w-60">{`Are you sure you want to delete the alert ${row.name}?`}</span>}
                >
                  <li>Delete</li>
                </DeleteWarningTooltip>
              </>
            )}
          </Dropdown>
        )}
      </div>
      <CompassAlertsModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        alert={row}
        alertType={alertType}
        submitAlert={updateAlert}
        tagsToFilter={tagsToFilter}
        duplicateNames={duplicateNames}
      />
    </div>
  );
};

AlertRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    threshold: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string
    }),
    manual_resolve: PropTypes.bool
  }).isRequired,
  alertType: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    resolve_description: PropTypes.string,
    short_description: PropTypes.string,
    threshold_definition: PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number
    }),
    unique: PropTypes.bool
  }).isRequired,
  fetchAlerts: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  tagsToFilter: PropTypes.arrayOf(PropTypes.string),
  duplicateNames: PropTypes.arrayOf(PropTypes.string)
};

AlertRow.defaultProps = {
  onSuccess: () => {},
  onError: () => {}
};

const CompassAlertsCardError = () => (
  <div className="flex flex-col justify-center py-36">
    <div className="m-auto flex items-center">
      <SvgIcon name="ErrorFilled" color="$sw-error" />
      <Title className="pl-1 text-sw-error" variant="emptyStateHeader">
        Error in Fetching Alerts
      </Title>
    </div>
    <div className="m-auto text-sw-form-helper-text">
      <Link onClick={window.location.reload} className={'hover:cursor-pointer'}>
        {`Refresh`}
      </Link>
      {` the page or if the issue persists then contact a Shipwell Administrator.`}
    </div>
  </div>
);

const CompassAlertsCard = ({alerts, alertTypes, fetchAlerts, onSuccess, onError}) => {
  const [showModal, setShowModal] = useState(false);

  const submitAlert = useCallback(
    async (values, alert) => {
      try {
        await postAlertsConfiguration({
          type: alert.type,
          name: values.name,
          manual_resolve: values.manualResolve,
          threshold: {value: values.threshold},
          enabled: true
        });
        onSuccess({title: 'Changes Saved!', message: 'Your alert tile configuration was saved successfully.'});

        await fetchAlerts();
      } catch (error) {
        console.error('Error in submitAlert()', error);
        onError({title: 'Changes Saved!', message: 'Your alert tile configuration was saved successfully.'});
      }
      setShowModal(false);
    },
    [fetchAlerts, onSuccess, onError]
  );

  return (
    <div>
      <Card
        title={
          <div className="flex w-full items-center justify-between">
            <strong>Active Shipment Alerts</strong>
            {alerts?.length > 0 && alertTypes?.length > 0 && (
              <DeprecatedButton variant="tertiary" onClick={() => setShowModal(true)}>
                <SvgIcon name="AddCircleOutlined" color="sw-primary" />
                <span className="font-normal">Add Alert Tile</span>
              </DeprecatedButton>
            )}
          </div>
        }
      >
        <CollapsibleCardContent>
          {alerts?.length > 0 && alertTypes?.length > 0 ? (
            <div className="grid grid-cols-6">
              <AlertHeader />
              {alerts
                .filter((alert) => alert.type !== alertTypeConstants.WATCHED_SHIPMENT)
                .map((alert) => (
                  <AlertRow
                    key={alert.id}
                    row={alert}
                    alertType={alertTypes.find((alertType) => alertType?.type === alert?.type)}
                    tagsToFilter={alerts
                      .filter(
                        (alertToFilter) =>
                          alertToFilter.type === alertTypeConstants.TAGGED_SHIPMENT &&
                          alertToFilter.threshold?.value !== alert.threshold?.value
                      )
                      .map((filteredAlert) => filteredAlert.threshold?.value)}
                    fetchAlerts={fetchAlerts}
                    onSuccess={onSuccess}
                    onError={onError}
                    duplicateNames={alerts.map((alert) => alert.name).filter((alertName) => alertName !== alert.name)}
                  />
                ))}
              <CompassAlertsModal
                showModal={showModal}
                onClose={() => setShowModal(false)}
                alertType={alertTypes.find((alertType) => alertType?.type === alertTypeConstants.TAGGED_SHIPMENT)}
                alert={{
                  enabled: true,
                  manual_resolve: false,
                  name: 'New Tagged Shipment Alert',
                  threshold: {value: ''},
                  type: alertTypeConstants.TAGGED_SHIPMENT
                }}
                tagsToFilter={alerts
                  .filter((alert) => alert.type === alertTypeConstants.TAGGED_SHIPMENT)
                  .map((alert) => alert.threshold?.value)}
                submitAlert={submitAlert}
                duplicateNames={alerts.map((alert) => alert.name)}
              />
            </div>
          ) : (
            <CompassAlertsCardError />
          )}
        </CollapsibleCardContent>
      </Card>
    </div>
  );
};

CompassAlertsCard.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      enabled: PropTypes.bool,
      type: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      threshold: PropTypes.shape({
        value: PropTypes.number
      }),
      manual_resolve: PropTypes.bool
    })
  ).isRequired,
  alertTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      resolve_description: PropTypes.string,
      short_description: PropTypes.string,
      threshold_definition: PropTypes.shape({
        type: PropTypes.string,
        description: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number
      })
    })
  ).isRequired,
  fetchAlerts: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

CompassAlertsCard.defaultProps = {
  onSuccess: () => {},
  onError: () => {}
};

export default CompassAlertsCard;
