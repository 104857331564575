import {SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {ReactNode} from 'react';

export const BidStep = ({
  number,
  label,
  children,
  isDisabled = true
}: {
  number: number;
  label: string;
  children?: ReactNode;
  isDisabled?: boolean;
}) => (
  <div
    className={classNames('flex flex-col gap-2 rounded bg-sw-background-component p-4 size-full', {
      'text-sw-disabled pointer-events-none': isDisabled
    })}
  >
    <div className="flex gap-2">
      <SvgIcon height="40" width="40" name={`Num${number}Outlined`} />
      <div className="flex flex-col">
        <span>Step {number}</span>
        <span className="text-lg font-bold">{label}</span>
      </div>
    </div>
    {children}
  </div>
);
