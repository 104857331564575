import {TemplateBuilderApiListTemplatesRequest} from '@shipwell/template-builder-sdk';
import {useQuery} from '@tanstack/react-query';
import {LIST_TEMPLATES_QUERY_KEY} from '../queryKeys';
import {listTemplates} from 'App/api/templates/typed';

export const useGetListTemplates = (params?: TemplateBuilderApiListTemplatesRequest) => {
  const {data, isLoading, error} = useQuery({
    queryKey: [LIST_TEMPLATES_QUERY_KEY],
    queryFn: () => listTemplates(params ?? {})
  });

  return {
    listTemplates: data,
    isLoadingListTemplates: isLoading,
    listTemplatesError: error
  };
};
