import {useEffect, useState} from 'react';
import {object, array, lazy, string} from 'yup';
import {Formik, Form, Field} from 'formik';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import {SvgIcon, Tooltip, FormikCheckbox, Title} from '@shipwell/shipwell-ui';
import RoutingGuideActionsFields from 'App/formComponents/formSections/routingGuideActionsFields';
import {fetchShipmentModes, fetchEquipmentTypes} from 'App/actions/_shipments';
import {validateDollarValue} from 'App/utils/globals';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import ScrollToError from 'App/formComponents/formSections/scrollToError';
import {selectOptionValidationSchema} from 'App/utils/yupHelpers';
import './styles.scss';

export const validationSchema = object().shape({
  actions: array().of(
    lazy((value) => {
      switch (value.type.value) {
        case 'TENDER':
          return object().shape({
            involved_tender_to_company_users: array().of(object().nullable()).required('Carrier is required.'),
            mode: object().nullable().required('Mode is required.'),
            equipment_type: object().nullable().required('Equipment is required.'),
            expires_after_seconds: selectOptionValidationSchema.label('Expiration'),
            rate: string()
              .nullable()
              .when('contract_id', {
                is: (val) => !val,
                then: string()
                  .nullable()
                  .test('rate', 'A valid dollar value is required.', (value) => {
                    if (value) {
                      return validateDollarValue(value);
                    }
                    return true;
                  })
                  .required('Rate is required.')
              }),
            rate_type: string().nullable().required('Rate Type is required.')
          });
        case 'SEND_EMAIL':
          return object().shape({
            recipients: array().of(object().nullable()).required('Select at least one recipient.'),
            subject: string().nullable().required('Subject is required.'),
            message: string().nullable().required('Message is required.')
          });
        case 'POST_TO_LOADBOARD':
          return object().shape({
            buy_it_now_amount: string()
              .nullable()
              .test('rate', 'A valid dollar value is required.', (value) => {
                if (value) {
                  return validateDollarValue(value, 2);
                }
                return true;
              })
          });
        case 'CREATE_SPOT_NEGOTIATIONS':
          return object().shape({
            buy_it_now_amount: string()
              .nullable()
              .test('rate', 'A valid dollar value is required.', (value) => {
                if (value) {
                  return validateDollarValue(value, 2);
                }
                return true;
              }),
            carriers: array().of(object().nullable()).required('At least one carrier is required.')
          });
        case 'QUOTE_AND_TENDER_LTL':
          return object().shape({
            allowed_carrier_company_ids: array().of(object().nullable()).min(1, 'Carrier is required.')
          });
        default:
          return null;
      }
    })
  )
});

/**
 * Routing Guide Actions Form
 */
const RoutingGuideActions = (props) => {
  const {
    isEdit,
    dispatch,
    values,
    onSubmit,
    onCancel,
    modes,
    equipmentTypes,
    onBack,
    defaultValues,
    defaultMode,
    defaultEquipment,
    selectedPolicy,
    cloningGuide
  } = props;

  const [expanded, setExpanded] = useState(times(values.actions.length));

  const toggleExpanded = (index) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((e) => e !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit routing guide action values */
  const handleRoutingGuideSubmit = (values, props) => {
    //need to delete rate if a contract is used
    values?.actions?.forEach((action) => {
      if (action.type?.value === 'TENDER' && action.contract_id?.value) {
        delete action.rate;
        delete action.rate_currency;
        delete action.rate_type;
      }
    });
    //expand all actions to show errors if needed
    setExpanded(times(values.actions.length));
    if (onSubmit) {
      onSubmit(values, props);
    }
  };

  useEffect(() => {
    if (modes.length < 1) {
      dispatch(fetchShipmentModes());
    }
    if (equipmentTypes.length < 1) {
      dispatch(fetchEquipmentTypes());
    }
  }, []);

  /** Default values needed for Formik */
  const defaultFormValues = {
    TENDER: {
      type: {label: 'Tender', value: 'TENDER'},
      involved_tender_to_company_users: [],
      rate: '',
      rate_type: defaultValues?.rate_type || 'FLAT_RATE',
      info_message: defaultValues?.info_message || '',
      step_timer: defaultValues?.step_timer || '',
      expires_after_seconds: defaultValues?.expires_after_seconds || '',
      mode: defaultValues?.mode || '',
      equipment_type: defaultValues?.equipment_type || ''
    },
    POST_TO_LOADBOARD: {
      step_timer: defaultValues?.step_timer || ''
    },
    CREATE_SPOT_NEGOTIATIONS: {
      step_timer: defaultValues?.step_timer || '',
      special_instructions: defaultValues?.info_message || ''
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleRoutingGuideSubmit}
    >
      {({isSubmitting, dirty, values, errors, ...props}) => (
        <Form>
          {!cloningGuide ? (
            <Title variant="formTitle" className="stepExplainer">
              Add steps to run when your routing guide is executed.
            </Title>
          ) : null}
          <ScrollToError errors={errors} isSubmitting={isSubmitting} isModal />
          <RoutingGuideActionsFields
            toggleExpanded={toggleExpanded}
            setExpanded={setExpanded}
            defaultFormValues={defaultFormValues}
            expanded={expanded}
            values={values}
            selectedPolicy={selectedPolicy}
            errors={errors}
            {...props}
          />

          <div className="routingGuides__form-footer">
            {!isEdit && (
              <ModalFormFooter
                options={
                  <>
                    {onBack && (
                      <span className="routingGuides__form-footer-back clickable" onClick={() => onBack()}>
                        <SvgIcon name="ArrowBack" />
                      </span>
                    )}
                    <Field component={FormikCheckbox} name="clone_on_save" label={'Clone Routing Guide after saving'} />
                    <Tooltip
                      placement="top"
                      tooltipClassname="routingGuides__form-tooltips"
                      tooltipContent={
                        <span>
                          This allows you to create a copy of an existing Routing Guide to use as a starting point for a
                          new Routing Guide.
                        </span>
                      }
                    >
                      <SvgIcon name="InfoOutlined" />
                    </Tooltip>
                  </>
                }
                isValid={values.actions.length}
                isSubmitting={isSubmitting}
                onCancel={handleCancel}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

RoutingGuideActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect((state) => ({
  modes: state.shipments.shipmentModes,
  equipmentTypes: state.shipments.equipmentTypes
}))(RoutingGuideActions);
