import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {ShipwellError, UserPreferences} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {USER_PREFERENCES_KEY} from 'App/data-hooks/queryKeys';
import {getUserPreferences} from 'App/api/shipwellUI/typed';

export type UseUserPreferencesQueryOptions = Omit<
  UseQueryOptions<UserPreferences, AxiosError<ShipwellError>, UserPreferences, string[]>,
  'queryFn' | 'queryKey'
>;
/**
 * Fetches the preferences for the currently authenticated user.
 */
const useUserPreferencesQuery = (options?: UseUserPreferencesQueryOptions) => {
  const userPreferencesQuery = useQuery(
    [USER_PREFERENCES_KEY],
    async () => {
      const {data} = await getUserPreferences(); // TODO: this should eventually just return the function since the API layer should deconstruct the data from axios.
      return data;
    },
    options
  );
  return userPreferencesQuery;
};

export default useUserPreferencesQuery;
