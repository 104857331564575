import {ProductLegItem} from '@shipwell/corrogo-sdk';
import {DeprecatedButton, Card, DisplayValue, SvgIcon, CollapsibleCardContent, Dropdown} from '@shipwell/shipwell-ui';
import ShipmentItemTotals from 'App/containers/quotes/create/components/shipmentItemTotals';
import {
  transformLegItemsIntoLineItems,
  transformLegLineItemIntoDisplayValues
} from 'App/containers/shipments/drayage/components/ProductLineItems/utils';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';

const LineItemView = ({
  legItem,
  itemNumber,
  onClickLineItem,
  onDelete
}: {
  legItem: ProductLegItem;
  itemNumber: number;
  onClickLineItem: (index?: number) => void;
  onDelete: (index: number) => void;
}) => {
  const lineItemDisplayValues = transformLegLineItemIntoDisplayValues(legItem);
  return (
    <Card
      draggableProvided={false}
      className="mb-4"
      isCollapsible
      title={
        <div className="flex w-full items-center justify-between">
          <div>{`Item ${itemNumber} - ${lineItemDisplayValues.description}`}</div>
          <div className="font-normal">
            <Dropdown indicator={false} drop="left" variant="icon" icon={<SvgIcon color="$sw-icon" name="Overflow" />}>
              {({onClick}: {onClick: () => void}) => (
                <>
                  <li
                    onClick={() => {
                      onClickLineItem(itemNumber - 1);
                      onClick();
                    }}
                  >
                    Edit Item
                  </li>
                  <DeleteWarningTooltip
                    title="Remove Item"
                    itemLabel="item"
                    primaryActionText="Delete"
                    onDelete={() => {
                      onDelete(itemNumber - 1);
                      onClick();
                    }}
                    message="Are you sure you want to remove this item?"
                  >
                    <li>Remove Item</li>
                  </DeleteWarningTooltip>
                </>
              )}
            </Dropdown>
          </div>
        </div>
      }
    >
      <CollapsibleCardContent>
        <div className="grid grid-cols-3 gap-4">
          <div className="grid gap-2">
            <DisplayValue label="Description">{lineItemDisplayValues.description}</DisplayValue>
            <DisplayValue label="Quantity">{lineItemDisplayValues.quantity}</DisplayValue>
            <DisplayValue label="Packaging">{lineItemDisplayValues.packageType}</DisplayValue>
            <DisplayValue label="Package Weight">{lineItemDisplayValues.packageWeight}</DisplayValue>
            <DisplayValue label="Hazmat">{lineItemDisplayValues.hazmat}</DisplayValue>
          </div>
          <div className="grid gap-2">
            <DisplayValue label="Value Per Piece">{lineItemDisplayValues.valuePerPiece}</DisplayValue>
            <DisplayValue label="Dimensions">{lineItemDisplayValues.dimensions}</DisplayValue>
            <DisplayValue label="Freight Class">{lineItemDisplayValues.freightClass}</DisplayValue>
            <DisplayValue label="Country of Manufacture">{lineItemDisplayValues.countryOfManufacture}</DisplayValue>
            <DisplayValue label="Refrigerated">{lineItemDisplayValues.temperatureRange}</DisplayValue>
          </div>
          <div className="grid gap-2">
            <DisplayValue label="Total Weight">{lineItemDisplayValues.totalWeight}</DisplayValue>
            <DisplayValue label="Number Of Pieces">{lineItemDisplayValues.quantity}</DisplayValue>
            <DisplayValue label="NMFC Code">{lineItemDisplayValues.nmfcCode}</DisplayValue>
            <DisplayValue label="NMFC Subcode">{lineItemDisplayValues.nmfcSubcode}</DisplayValue>
            <DisplayValue label="Stackable">{lineItemDisplayValues.stackable}</DisplayValue>
          </div>
        </div>
      </CollapsibleCardContent>
    </Card>
  );
};

const LineItemsView = ({
  legItems,
  onClickLineItem,
  onDelete
}: {
  legItems: ProductLegItem[];
  onClickLineItem: (index?: number) => void;
  onDelete: (index: number) => void;
}) => {
  const lineItems = transformLegItemsIntoLineItems(legItems);

  return (
    <div className="grid grid-cols-1 gap-4">
      <Card draggableProvided={false} title={`Shipping Items - ${legItems.length}`}>
        {legItems.map((legItem, index) => (
          <LineItemView
            key={`container-leg-item-${index}`}
            legItem={legItem}
            itemNumber={index + 1}
            onClickLineItem={onClickLineItem}
            onDelete={onDelete}
          />
        ))}
      </Card>
      <Card
        draggableProvided={false}
        title={
          <DeprecatedButton variant="tertiary" onClick={() => onClickLineItem()}>
            <SvgIcon name="AddCircleOutlined" />
            <span>Add Item</span>
          </DeprecatedButton>
        }
      />
      <ShipmentItemTotals valueCurrency="USD" lineItems={lineItems} />
    </div>
  );
};

export default LineItemsView;
