import {
  PaginatedShipmentTimelineEvent,
  ShipmentsApiShipmentsShipmentIdTimelineEventsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {QueryKey, UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getShipmentTimelineEvents} from 'App/api/shipment/typed';
import {TIMELINE_EVENT_TEMPLATES} from 'App/data-hooks/queryKeys';

export const useGetShipmentTimelineEvents = ({
  requestBody,
  options
}: {
  requestBody: ShipmentsApiShipmentsShipmentIdTimelineEventsGetRequest;
  options?: Omit<
    UseQueryOptions<PaginatedShipmentTimelineEvent, unknown, PaginatedShipmentTimelineEvent, QueryKey>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const query = useQuery(
    [TIMELINE_EVENT_TEMPLATES, requestBody],
    async () => {
      const response = await getShipmentTimelineEvents(requestBody);
      return response.data;
    },
    options
  );

  const {data: timelineEvents, status: timelineEventsStatus} = query;

  return {
    timelineEvents,
    timelineEventsStatus,
    query
  };
};
