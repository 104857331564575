import {DateTimePicker} from 'react-widgets';
import {FormGroup, ControlLabel} from 'react-bootstrap';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import {formatHrMin} from 'App/utils/globals';

moment.locale('en');
momentLocalizer();

const renderDateField = ({
  autoFocus,
  input,
  value,
  dropUp,
  defaultValue,
  placeholder,
  label,
  req,
  showTime,
  sronly,
  meta: {touched, error, warning},
  ...rest
}) => (
  <FormGroup
    controlId={input.name}
    validationState={touched && error ? 'error' : touched && warning ? 'warning' : null}
  >
    {label && (
      <ControlLabel className={sronly && 'sr-only'}>
        {label}
        {req && <span className="requiredField">*</span>}
      </ControlLabel>
    )}

    <DateTimePicker
      {...input}
      format={'MMM D, YYYY' + (showTime ? ' ' + formatHrMin : '')}
      timeFormat={formatHrMin}
      autoFocus={autoFocus ? true : false}
      defaultValue={value ? value : defaultValue}
      value={value ? value : defaultValue}
      placeholder={placeholder}
      min={moment().toDate()}
      dropUp={dropUp ? true : false}
      time={showTime ? true : false}
      {...rest}
    />
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
    {touched && warning && (
      <p className="warning-text">
        <i className="icon icon-Flag" />
        {warning}
      </p>
    )}
  </FormGroup>
);

export default renderDateField;
