import {AxiosError} from 'axios';
import {DeserializedTiveDetailsRequestError, TiveRequestError} from './tiveRequestError';
import {ParsedTiveError} from './parsedTiveError';

export function getTiveError(error: unknown) {
  const axiosError = error as AxiosError<TiveRequestError>;
  const data = axiosError.response?.data;

  const mainError = data?.error;
  const details = JSON.parse(data?.details || '') as DeserializedTiveDetailsRequestError;

  const parsedError: ParsedTiveError = {
    error: mainError || 'unknown',
    details
  };

  return parsedError;
}
