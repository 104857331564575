import {Field, useFormikContext} from 'formik';
import moment from 'moment';
import pluralize from 'pluralize';
import {FormikDateTimePicker, FormikRadioGroup, SvgIcon} from '@shipwell/shipwell-ui';
import {FlexBox, GridBox} from 'App/components/Box';
import AddressSearchField from 'App/formComponents/fields/_addressSearch';
import {SubmitValuesType} from 'App/containers/orders/views/AddOrdersToShipmentListView/AddOrdersToShipmentListView';

export enum SharedTypeEnum {
  Start = 'start',
  End = 'end'
}

const SHARED_POINT_OPTIONS = [
  {label: 'Starting Point', value: SharedTypeEnum.Start},
  {label: 'Ending Point', value: SharedTypeEnum.End}
];

export const AddOrdersToShipmentSharedStartEndPoint = ({numberOfOrders}: {numberOfOrders: number}) => {
  const {values} = useFormikContext<SubmitValuesType>();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center bg-sw-active-light p-2.5">
        <SvgIcon name="InfoFilled" color="#0A6FDB" />
        <span className="ml-1 font-bold text-sw-info">{`${pluralize(
          'order',
          numberOfOrders,
          true
        )} being modified`}</span>
      </div>
      <b>Shared Start or End Point</b>
      <p className="confirmational-modal-paragraph m-0 italic">
        Choose a shared starting point or end point. All orders that are a part of this shipment will use the same
        shared address. The address on the order will not change
      </p>
      <FlexBox direction="col" gap="m">
        <Field name="sharedType" options={SHARED_POINT_OPTIONS} direction="row" component={FormikRadioGroup} />
        <Field
          name="shared_address"
          label={`${values.sharedType === 'start' ? 'Starting' : 'Ending'} Point Address`}
          prepend={<SvgIcon name="Location" color="$sw-icon" />}
          portal
          component={AddressSearchField}
        />
        <GridBox gap="m" cols={2}>
          <Field
            label="Date/Time Start"
            name="start_time"
            component={FormikDateTimePicker}
            prepend={<SvgIcon name="Calendar" />}
            timezone={values.shared_address?.timezone || moment.tz.guess()}
          />
          <Field
            label="Date/Time End"
            name="end_time"
            component={FormikDateTimePicker}
            prepend={<SvgIcon name="Calendar" />}
            timezone={values.shared_address?.timezone || moment.tz.guess()}
          />
        </GridBox>
      </FlexBox>
    </div>
  );
};
