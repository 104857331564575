import {ChangeEvent, KeyboardEvent, useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';

import {Button, IconButton, Textarea} from '@shipwell/shipwell-ui';

import {parseV3ApiError} from 'App/api/typedUtils';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useAllShipmenInternalNotes} from 'App/data-hooks/shipments/useShipmentInternalNotes';
import {NoteDisplayEntry} from 'App/components/Notes/NoteDisplayEntry';

export type AppointmentShipmentInternalNotesProps = {
  shipmentId?: string;
  setError: (title: string, details: string) => unknown;
  setSelectedAppointment: (appointment: null) => unknown;
};

export function AppointmentShipmentInternalNotes({
  shipmentId,
  setError,
  setSelectedAppointment
}: AppointmentShipmentInternalNotesProps) {
  const {isLoading, notes, error, isWorking, postNote} = useAllShipmenInternalNotes(shipmentId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorText = (error && `${(error as any)?.message ?? error}`) as string | undefined;

  return (
    <div className="appointment-shipment-messages flex h-full w-[347px] flex-col">
      <div className="flex grow-0 flex-row">
        <h5 className="flex-1 px-2 pt-1 font-bold">Appointment Notes</h5>
        <div className="p-1">
          <IconButton iconName="Close" aria-label="Close" size="lg" onClick={() => setSelectedAppointment(null)} />
        </div>
      </div>
      <div
        className={classNames(
          'flex grow flex-col border-y-1 border-sw-border p-2',
          !errorText && notes.length === 0 ? 'items-center justify-center' : ''
        )}
      >
        {errorText ? (
          <div className="bg-sw-error-background text-sw-error-light">{errorText}</div>
        ) : isLoading ? (
          <ShipwellLoader />
        ) : notes.length === 0 ? (
          <div className="text-center text-sw-disabled">
            <div className="bold text-lg">No Internal Notes</div>
            <div className="text-sm">Add a new internal note in the field below.</div>
          </div>
        ) : (
          notes.map((note) => <NoteDisplayEntry key={note.id} note={note} />)
        )}
      </div>
      <div className="flex w-full grow-0 p-2">
        <NewInternalNotePanel isWorking={isWorking} postNote={postNote} setError={setError} />
      </div>
    </div>
  );
}

function NewInternalNotePanel({
  isWorking,
  postNote,
  setError
}: {
  isWorking: boolean;
  postNote: (message: string) => Promise<void>;
  setError: (title: string, detail: string) => void;
}) {
  const [message, setMessage] = useState('');

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    },
    [setMessage]
  );

  const asyncSubmit = useCallback(async () => {
    try {
      await postNote(message);
      setMessage('');
    } catch (error) {
      if (error) {
        const {isError, title, detail} = parseV3ApiError(error);
        if (isError) {
          setError(title, detail);
        }
      }
    }
  }, [postNote, message, setError]);
  // This is to satisfy the linter that I'm not forgetting to await a promise.
  const submit = useMemo(() => asyncSubmit as () => void, [asyncSubmit]);

  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          return;
        }
        event.preventDefault();
        submit();
      }
    },
    [submit]
  );

  return (
    <div className="create-shipment-internal-note-panel w-full">
      <Textarea
        name="Message"
        label="MESSAGE"
        value={message}
        disabled={isWorking}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <div className="mb-1 text-[9px]">
        <span className="font-medium">SHIFT+ENTER</span> &nbsp; TO ADD A NEW LINE
      </div>
      <Button onClick={submit} disabled={isWorking || !message} width="full">
        Send
      </Button>
    </div>
  );
}
