import {ReactNode} from 'react';
import {NoFilterResultsSprinkles} from './NoFilterResultsSprinkles';

const NoFilterResultsView = ({children}: {children?: ReactNode}) => (
  <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-4">
    <NoFilterResultsSprinkles />
    <span className="text-lg font-medium">No Filter Results</span>
    {children}
  </div>
);

export default NoFilterResultsView;
