import {useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import get from 'lodash/get';
import classnames from 'classnames';
import {DeprecatedButton, SvgIcon, Tooltip, Title} from '@shipwell/shipwell-ui';
import withStatusToasts from 'App/components/withStatusToasts';
import {removeStopAlert} from 'App/api/shipment';
import {stopFlagPropType, stopFlagAlertTypeMap} from 'App/utils/stopFlag';
import {getStopStatusReasonCodeMap} from 'App/utils/stopStatusReasonCodes';
import makeLabelForOptionObject from 'App/utils/makeLabelForOptionObject';
import useGroupedStopStatusReasonCodes from 'App/api/shipment/useGroupedStopStatusReasonCodes';
import {getShipmentTimeline} from 'App/actions/_shipmentDetails';
import './styles.scss';

const StopFlagDetails = ({stop, alert, onRemoveFlag, displayStopNumber}) => {
  const groupedStopStatusReasonCodes = useGroupedStopStatusReasonCodes();

  const alertType = makeLabelForOptionObject(
    get(stopFlagAlertTypeMap, alert.id.toLowerCase(), {id: alert.alert_type.toLowerCase()})
  );
  const reasonCode = makeLabelForOptionObject(
    get(getStopStatusReasonCodeMap(groupedStopStatusReasonCodes), alert.reason_code.toLowerCase(), {
      id: alert.reason_code.toLowerCase()
    })
  );

  const stopNumber = get(stop, 'ordinal_index', 0);
  const AlertType = displayStopNumber ? 'span' : Title;
  return (
    <div
      className={classnames('shipment__stopFlags-flagDetails', {
        'shipment__stopFlags-flagDetails-displayStopNumber': displayStopNumber
      })}
    >
      {displayStopNumber ? <Title>Stop #{stopNumber + 1}: </Title> : null}
      <AlertType variant="sectionTitle" className="alert-type">
        {alertType}
      </AlertType>
      {displayStopNumber ? <span> - </span> : null}
      <span>{reasonCode}</span>
      <span>{alert.description}</span>
      <span>{alert.location?.formatted_address}</span>
      {onRemoveFlag ? (
        <DeprecatedButton
          title="Remove Flag"
          variant="tertiary"
          onClick={() => onRemoveFlag(alert.id)}
          className="remove-flag"
        >
          Remove Flag
        </DeprecatedButton>
      ) : null}
    </div>
  );
};

StopFlagDetails.propTypes = {
  alert: stopFlagPropType.isRequired,
  onRemoveFlag: PropTypes.func,
  displayStopNumber: PropTypes.bool
};

StopFlagDetails.defaultProps = {
  onRemoveFlag: null,
  displayStopNumber: false
};

const StopFlag = ({stop, alert, onRemoveFlag, disabled, displayStopNumber}) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Tooltip
      tooltipContent={
        <StopFlagDetails stop={stop} alert={alert} onRemoveFlag={onRemoveFlag} displayStopNumber={displayStopNumber} />
      }
      trigger="click"
    >
      <ClickAwayListener onClickAway={() => setShowDetails(false)}>
        <DeprecatedButton
          variant="activeIcon"
          active={showDetails}
          onClick={() => setShowDetails(!showDetails)}
          className="shipment__stopFlags-flagButton"
          disabled={disabled}
        >
          <SvgIcon name="FlagFilled" color="sw-warning" />
        </DeprecatedButton>
      </ClickAwayListener>
    </Tooltip>
  );
};

StopFlag.propTypes = {
  alert: stopFlagPropType,
  onRemoveFlag: PropTypes.func,
  disabled: PropTypes.bool,
  displayStopNumber: PropTypes.bool
};

StopFlag.defaultProps = {
  onRemoveFlag: null,
  disabled: false,
  displayStopNumber: false
};

const StopFlags = ({shipment, stop, alerts, onRemoveFlag, setError, displayStopNumber, dispatch}) => {
  const [removingFlag, setRemovingFlag] = useState(false);

  const handleRemoveFlag = async (id) => {
    setRemovingFlag(true);
    try {
      await removeStopAlert(shipment.id, stop.id, id);
      dispatch(getShipmentTimeline(shipment.id));
      setRemovingFlag(false);
      onRemoveFlag();
    } catch (e) {
      console.error('Error deleting stop flag', e);
      setError('Error removing stop flag!', get(e, 'error_description', 'There was an error removing the stop flag.'));
      setRemovingFlag(false);
    }
  };

  return (
    <div className="shipment__stopFlags">
      {alerts.map((alert) => (
        <StopFlag
          key={alert.id}
          stop={stop}
          alert={alert}
          onRemoveFlag={onRemoveFlag ? handleRemoveFlag : undefined}
          disabled={removingFlag}
          displayStopNumber={displayStopNumber}
        />
      ))}
    </div>
  );
};

StopFlags.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  alerts: PropTypes.arrayOf(stopFlagPropType),
  onRemoveFlag: PropTypes.func,
  setError: PropTypes.func,
  displayStopNumber: PropTypes.bool
};

StopFlags.defaultProps = {
  alerts: [],
  onRemoveFlag: null,
  setError: () => {},
  displayStopNumber: false
};

export default compose(
  connect((state) => ({
    shipment: state.shipmentdetails.one
  })),
  withStatusToasts
)(StopFlags);
