import PropTypes from 'prop-types';
import {Link} from 'react-router';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'App/containers/Shipment/components/stopEta/styles.scss';

const StopEta = ({stop = {}, stops = [], shipmentState = '', isLTL, hasCarrier, departureTimeToShow}) => {
  const determineDeliveredToFrom = (item) =>
    item.is_dropoff && item.is_pickup
      ? 'PICKED UP / DELIVERED ON'
      : item.is_pickup && !item.is_dropoff
      ? 'PICKED UP ON'
      : 'DELIVERED ON';
  const stopETA = stop.display_eta_window || null;
  let showLastStopETA = true;
  let etaLabel = 'ETA';
  let etaNote;
  // is LTL checks LTL & VLTL
  if (isLTL && hasCarrier) {
    etaNote = 'ETA is provided by the Carrier.';
    etaLabel = 'Carrier ETA';
  }
  if (isLTL && !hasCarrier) {
    etaNote = 'Requested Pickup Date';
    etaLabel = 'Ship date';
    // hide last stop eta
    if (isEqual(stop, last(stops))) {
      showLastStopETA = false;
      etaNote = `Click "View Quotes" to view carrier ETAs`;
    }
  }
  if (stopETA && (!departureTimeToShow || departureTimeToShow === '--') && shipmentState !== 'delivered') {
    return (
      <div className="stop-eta__container">
        <small className="stop-eta__body">
          <b className="stop-eta__header">
            {showLastStopETA && etaLabel}
            {etaNote && (
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{etaNote}</Tooltip>}>
                <Link tabIndex="-1">
                  <i className="icon icon-Info" aria-hidden="true" />
                </Link>
              </OverlayTrigger>
            )}
          </b>
          {showLastStopETA ? <span>{stopETA}</span> : null}
        </small>
      </div>
    );
  }
  if (departureTimeToShow && departureTimeToShow !== '--') {
    return (
      <div className="stop-eta__container">
        <small className="stop-eta__body">
          <b className={classNames('stop-eta__header', 'planned')}>{determineDeliveredToFrom(stop)}</b>
          <span>{departureTimeToShow}</span>
        </small>
      </div>
    );
  }
  if (shipmentState === 'delivered' && !departureTimeToShow) {
    return (
      <div className="stop-eta__container">
        <small className="stop-eta__body">
          <b className={classNames('stop-eta__header', 'planned')}>{determineDeliveredToFrom(stop)}</b>
          <span>No time reported</span>
        </small>
      </div>
    );
  }
  return null;
};

StopEta.propTypes = {
  departureTimeToShow: PropTypes.string,
  hasCarrier: PropTypes.bool,
  isLTL: PropTypes.bool,
  shipmentState: PropTypes.string,
  stop: PropTypes.shape({
    display_eta_window: PropTypes.string,
    trip_management_eta: PropTypes.string
  }),
  stops: PropTypes.array,
  timezone: PropTypes.string
};

export default StopEta;
