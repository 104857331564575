import {NetsuiteSuiteAppConfig} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {updateSuiteAppConfig} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useConnectSuiteApp = (
  options?: UseMutationOptions<Awaited<ReturnType<typeof updateSuiteAppConfig>>, AxiosError, NetsuiteSuiteAppConfig>
) => {
  const connectSuiteAppMutation = useMutation([SUITE_APP_CONFIG_QUERY], (body) => updateSuiteAppConfig(body), options);
  return {
    connectSuiteApp: connectSuiteAppMutation.mutate,
    isConnectSuiteAppLoading: connectSuiteAppMutation.isLoading
  };
};
