import {useMemo} from 'react';
import {ToggleSwitch} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import {EquipmentType, Stop, StopLocation} from '@shipwell/backend-core-sdk';
import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import {PRICING_INTEL_DAT_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  DAT_RATE_TYPE_SPOT,
  DAT_RATE_TYPE_CONTRACT,
  DAT_REQUIRED_ADDRESS_KEYS
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {getThreeDigitPostalStringifiedStops} from 'App/components/pricingIntelChart/utils';
import {getCustomerDatRates} from 'App/api/pricing';
import useToggle from 'App/utils/hooks/useToggle';
import {DAT} from 'App/containers/integrations/details/components/datDetails/utils';
import {useUserMe} from 'App/data-hooks';

interface ResponseBody {
  body: {data: DatResponse};
}

export interface DatResponse {
  rateResponses: RateResponse[];
}

interface RateResponse {
  response: {
    escalation: {
      destination: ResponseStop;
      origin: ResponseStop;
      timeframe: string;
    };
    rate: {
      averageFuelSurchargePerTripUsd: number;
      averageFuelSurchargePerMileUsd?: number;
      companies: number;
      mileage: number;
      perTrip: Rates;
      perMile?: Rates;
      reports: number;
      standardDeviation: number;
    };
  };
}

export interface ResponseStop {
  name: string;
  type: string;
}

export interface Rates {
  highUsd: number;
  lowUsd: number;
  rateUsd: number;
}

const DPMToggle = ({showDPM, toggleShowDPM}: {showDPM: boolean; toggleShowDPM: () => void}) => {
  return (
    <ToggleSwitch
      label="All in"
      secondaryLabel="DPM"
      name="rateTypeToggle"
      isSmall
      checked={showDPM}
      onChange={toggleShowDPM}
      variant="alwaysBlue"
    />
  );
};

const useDatPricing = ({
  equipmentType,
  rateType = DAT_RATE_TYPE_SPOT,
  stops
}: {
  equipmentType: EquipmentType;
  rateType: typeof DAT_RATE_TYPE_SPOT | typeof DAT_RATE_TYPE_CONTRACT;
  stops: (Stop | StopLocation)[];
}) => {
  const [showDPM, toggleShowDPM] = useToggle();

  const {data: userMeData} = useUserMe();
  const {
    data: activeLoadboardsData,
    error: activeLoadboardsError,
    isFetched: activeLoadboardsIsFetched
  } = useGetActiveLoadboards({
    retry: false
  });

  const loadboards = useMemo(() => activeLoadboardsData || [], [activeLoadboardsData]);
  const datLoadboardDetails = loadboards.find((item) => item.loadboard_type === DAT);

  const isDatIntegrated = activeLoadboardsIsFetched
    ? !activeLoadboardsError &&
      Boolean(datLoadboardDetails?.users?.some((user) => user.sw_user_id === userMeData?.user?.id))
    : undefined;

  const queryOptions = {
    enabled: false,
    retry: false,
    onError: (error: Error) => {
      console.error(error);
    },
    cacheTime: 0
  };

  const stringifiedStopsWithRequiredKeys = getThreeDigitPostalStringifiedStops(stops, DAT_REQUIRED_ADDRESS_KEYS);
  const equipmentTypeMachineReadable = equipmentType?.machine_readable;

  const {
    data,
    refetch,
    isInitialLoading: isLoading,
    isFetching,
    remove
  } = useQuery(
    [PRICING_INTEL_DAT_QUERY_KEY],
    async () => {
      const {body} = (await getCustomerDatRates(
        equipmentTypeMachineReadable as string,
        rateType,
        stringifiedStopsWithRequiredKeys
      )) as ResponseBody;
      if (body.data) {
        return body.data;
      }
    },
    queryOptions
  );

  const DpmToggle = <DPMToggle showDPM={showDPM} toggleShowDPM={toggleShowDPM} />;

  return {
    DpmToggle,
    showDPM,
    toggleShowDPM,
    DATResponse: data,
    fetchDatRates: refetch,
    isLoadingDatRates: isLoading || isFetching,
    clearDatRates: remove,
    isDatIntegrated
  };
};

export default useDatPricing;
