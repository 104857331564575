import {SvgIcon} from '@shipwell/shipwell-ui';
import {createRef, RefObject, useLayoutEffect, useRef, useState, ReactNode} from 'react';

export type ProgressStep = {
  id: string;
  label: string;
  isActive?: boolean;
  isComplete: boolean;
  renderIcon?: (step: ProgressStep) => ReactNode;
  renderLabel?: (step: ProgressStep) => ReactNode;
};

interface ProgressIndicatorProps {
  steps: ProgressStep[];
  activeBorderClass?: string;
  disabledBorderClass?: string;
}

export function ProgressIndicator({
  steps,
  activeBorderClass = 'border-sw-active',
  disabledBorderClass = 'border-sw-disabled'
}: ProgressIndicatorProps) {
  // creates an array of refs so we can track the position of the icons
  const iconRefs = useRef<RefObject<HTMLDivElement>[]>(steps.map(() => createRef()));
  // icon ref's DOMRect is then saved to state and updated on resize via the useLayoutEffect below
  const [boundingBoxes, setBoundingBoxes] = useState<(DOMRect | undefined)[]>([]);

  useLayoutEffect(() => {
    const setBoxes = () => {
      setBoundingBoxes(iconRefs.current.map((ref) => ref.current?.getBoundingClientRect()));
    };
    setBoxes();
    window.addEventListener('resize', setBoxes);
    return () => window.removeEventListener('resize', setBoxes);
  }, [iconRefs]);

  const icon = (step: ProgressStep) => {
    if (step.isActive) {
      return <SvgIcon className="animate-slow-spin" color="sw-active" name="Running" />;
    }
    if (step.isComplete) {
      return <SvgIcon color="sw-active" name="CheckCircleFilled" />;
    }
    return <SvgIcon color="sw-disabled" name="CheckCircleOutlined" />;
  };

  // the lines between the icons are positioned absolutely.
  // this func sets their position
  const getLinePosition = (i: number) => {
    const rightBox = boundingBoxes[i];
    const leftBox = boundingBoxes[i - 1];
    if (!(rightBox && leftBox)) {
      return;
    }
    const buffer = 4;
    const width = rightBox.left - leftBox.right;

    return {
      width: width - buffer * 2,
      right: `calc(100% + ${buffer}px)`,
      // subtracting 1px to offset the 2px height of the border
      top: leftBox.height / 2 - 1
    };
  };

  return (
    <div className="flex w-full items-center justify-between gap-2">
      {steps.map((step, i) => (
        <div key={step.id}>
          <div className="flex flex-col items-center gap-1 text-sw-text-section-title">
            <div ref={iconRefs.current[i]} className="relative">
              {step.renderIcon ? step.renderIcon(step) : icon(step)}
              {i > 0 ? (
                <div
                  style={getLinePosition(i)}
                  className={`w-full ${
                    step.isComplete || step.isActive ? activeBorderClass : disabledBorderClass
                  } absolute border-t-2`}
                />
              ) : null}
            </div>
            {step.renderLabel ? (
              step.renderLabel(step)
            ) : (
              <span className="top-full text-center uppercase">{step.label}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
