import {createContext} from 'react';
import {Facility, FacilityDock, FacilityDockAppointmentRule, LoadType} from '@shipwell/tempus-sdk';
import {FacilityHoursEntry} from 'App/data-hooks/facilities/types';

/**
 * This proved too fragile in practice. Contexts can't trigger updates, so
 * making sure hooks ran when `facility` was populated was impossible.
 */
export const AppointmentBookingContext = createContext<{
  facility?: Facility;
  hours: FacilityHoursEntry[];
  loadTypes: LoadType[];
  docks: FacilityDock[];
  dockRules: FacilityDockAppointmentRule[];
  isInitialLoading?: boolean;
}>({
  loadTypes: [],
  docks: [],
  dockRules: [],
  hours: []
});

export const AppointmentBookingContextProvider = AppointmentBookingContext.Provider;
export const AppointmentBookingContextConsumer = AppointmentBookingContext.Consumer;
