import {useState, useCallback, useEffect} from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import {connect} from 'react-redux';
import pluralize from 'pluralize';
import {DeprecatedButton, Modal, SearchField, Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import PageHeader from 'App/common/pageHeader';
import SWTable from 'App/components/swTable';
import './styles.scss';
import {serviceLevelAgreements} from 'App/api';
import {fetchEquipmentTypes} from 'App/actions/_shipments';
import ServiceLevelAgreementCreateContainer from 'App/containers/serviceLevelAgreements/create';
import {formatDate} from 'App/utils/globals';
import {filters} from 'App/containers/serviceLevelAgreements/list/filters';
import CreateImport from 'App/components/imports/create';
import {useUserPageSize} from 'App/utils/hooks/useUserTableHooks';
import {defaultTableMeta} from 'App/utils/tableUtils';

/** Debounced path update - keep outside of functional component */
const pathUpdateDebounced = _.debounce((router, path) => router.push(path), 300);

const ServiceLevelAgreementsList = (props) => {
  const {
    canCreateContracts,
    canDeleteContracts,
    canViewContracts,
    location,
    dispatch,
    router,
    setSuccess,
    setError,
    tableType = 'SERVICE_LEVEL_AGREEMENTS_LIST'
  } = props;
  const {query} = location;

  const [deletingSLA, setDeletingSLA] = useState(null);
  const [SLAs, setSLAs] = useState([]);
  const [isFetchingSLAs, setIsFetchingSLAs] = useState(false);
  const [showCreateSLAModal, setShowCreateSLAModal] = useState(false);
  const [cloningSLA, setCloningSLA] = useState(null);
  const [meta, setMeta] = useState(defaultTableMeta);
  const [searchTerm, setSearchTerm] = useState(query.q);
  const [showImportSLAModal, setShowImportSLAModal] = useState(false);
  const [useFullScreen, setUseFullScreen] = useState(false);
  const userPageSize = useUserPageSize(tableType);

  useEffect(() => {
    dispatch(fetchEquipmentTypes());
  }, [dispatch]);

  /** Fetch contracts on pagination changes */
  useEffect(() => {
    if (location.query?.showDeleteSuccess) {
      setSuccess(
        'SLA Deleted!',
        `Any contracts leveraging ${location.query.showDeleteSuccess} will no longer include performance metrics.`
      );
      // clear out showDeleteSuccess
      router.push(router.location.pathname);
      return;
    }
    fetchSLAs(location.query);
  }, [location.query, userPageSize, router, setSuccess, fetchSLAs]);

  /* Fetch contracts */
  const fetchSLAs = useCallback(
    async (opts = {}) => {
      if (userPageSize === null) {
        return;
      }
      setIsFetchingSLAs(true);
      opts = {
        ...meta,
        pageSize: userPageSize,
        ...opts
      };
      try {
        const response = await serviceLevelAgreements.getSLAs(opts);
        setSLAs(response.body.results);
        setMeta({
          ...meta,
          page: query.page || 1,
          pages: response.body.total_pages,
          pageSize: response.body.page_size
        });
        setIsFetchingSLAs(false);
      } catch (error) {
        // @todo Handle error
        console.error(error);
      }
      setIsFetchingSLAs(false);
    },
    [userPageSize, query.page, meta]
  );

  /** Toggle contract form */
  const handleSLAModalDisplay = () => {
    if (showCreateSLAModal) {
      setCloningSLA(null);
    }
    setShowCreateSLAModal(!showCreateSLAModal);
  };

  /**
   * Show modal to create a new contract with this carrier
   */
  const handleAddSLA = () => {
    setShowCreateSLAModal(true);
  };
  const handleImportSLA = () => {
    setShowImportSLAModal(true);
  };

  const handleDeleteSLA = async (SLA) => {
    try {
      const response = await serviceLevelAgreements.deleteSLA(SLA.id);
      if (response && response.statusCode === 204) {
        setSuccess('SLA Deleted!', `Any contracts leveraging ${SLA.name} will no longer include performance metrics.`);
        const updatedPath = buildPathParams(location, {...meta.pageSize, page: 1});
        pathUpdateDebounced(router, updatedPath);
        setDeletingSLA(null);
      }
    } catch (error) {
      setError('SLA Not Deleted.', error.error_description);
    }
  };

  const handleCreateSuccess = (shouldClone) => {
    setShowCreateSLAModal(false);
    setSuccess('SLA Created!', 'You can now reference this SLA on contracts to track carrier performance.');
    const updatedPath = buildPathParams(location, {...meta.pageSize, page: 1});
    pathUpdateDebounced(router, updatedPath);
    if (shouldClone) {
      setCloningSLA(shouldClone);
      setShowCreateSLAModal(true);
    } else {
      setCloningSLA(null);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    const updatedPath = buildPathParams(location, {q: term, page: 1});

    setSearchTerm(term);
    pathUpdateDebounced(router, updatedPath);
  };

  const buildPathParams = (location, params) => {
    const {pathname = '/', query} = location;
    return `${pathname}?${queryString.stringify({...query, ...params})}`;
  };

  return (
    <div className="slas__list">
      <PageHeader
        title="Service Level Agreements"
        actions={
          <>
            {canCreateContracts && canViewContracts && (
              <>
                <DeprecatedButton
                  variant="tertiary"
                  icon={<SvgIcon name="AddCircleOutlined" />}
                  onClick={handleImportSLA}
                >
                  Import
                </DeprecatedButton>
                <DeprecatedButton variant="tertiary" icon={<SvgIcon name="AddCircleOutlined" />} onClick={handleAddSLA}>
                  SLA
                </DeprecatedButton>
              </>
            )}
          </>
        }
      >
        <SearchField label="Search" name="search-sla" value={searchTerm} onChange={handleSearch} />
      </PageHeader>
      <div className="slas__list-table">
        <SWTable
          data={SLAs}
          filters={filters}
          loading={isFetchingSLAs}
          location={location}
          router={router}
          page={Number(meta.page) - 1}
          pages={Number(meta.pages)}
          pageSize={Number(meta.pageSize)}
          tableType={tableType}
          noDataText="No service level agreements to show."
          getTrProps={(state, rowInfo) => ({className: !rowInfo ? 'empty' : ''})}
          columns={[
            {
              Header: (
                <>
                  Name <i className="btn-sort" />
                </>
              ),
              id: 'name',
              accessor: 'name',
              label: 'Name',
              sortable: true,
              Cell: (row) => {
                const {original} = row;
                return (
                  <DeprecatedButton
                    variant="tertiary"
                    onClick={() => {
                      router.push(`/company/lane-management/service-level-agreements/${original.id}`);
                    }}
                  >
                    {row.value}
                  </DeprecatedButton>
                );
              }
            },
            {
              Header: (
                <>
                  Total Capacity <i className="btn-sort" />
                </>
              ),
              id: 'capacity_per_day',
              accessor: 'capacity',
              label: 'Total Capacity',
              sortable: true,
              Cell: (row) => {
                const {original} = row;
                if (!row.value) {
                  return '--';
                }
                return `${row.value} per ${original.capacity_interval.toLowerCase()}`;
              }
            },
            {
              Header: (
                <>
                  Start Date <i className="btn-sort" />
                </>
              ),
              id: 'start_date',
              accessor: 'start_date',
              label: 'Start Date',
              sortable: true,
              Cell: (row) => {
                return row.value ? formatDate(row.value) : '--';
              }
            },
            {
              Header: (
                <>
                  End Date <i className="btn-sort" />
                </>
              ),
              id: 'end_date',
              accessor: 'end_date',
              label: 'End Date',
              sortable: true,
              Cell: (row) => {
                return row.value ? formatDate(row.value) : '--';
              }
            },
            {
              Header: (
                <>
                  Status <i className="btn-sort" />
                </>
              ),
              id: 'status',
              accessor: 'status',
              label: 'Status',
              sortable: true,
              minWidth: 75,
              Cell: (row) => {
                if (!row.value) {
                  return '--';
                }
                return `${_.upperFirst(row.value.toLowerCase())}`;
              }
            },
            {
              Header: 'Actions',
              id: 'actions',
              accessor: '',
              label: 'Actions',
              className: 'overflow-visible',
              sortable: false,
              // eslint-disable-next-line react/display-name
              Cell: ({original}) => {
                return (
                  <>
                    {(canDeleteContracts || canCreateContracts) && (
                      <Dropdown
                        indicator={false}
                        drop="left"
                        variant="icon"
                        className="action-menu"
                        icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                      >
                        {({onClick}) => (
                          <>
                            {canCreateContracts && (
                              <li
                                onClick={() => {
                                  onClick();
                                  setCloningSLA({...original, name: 'Copy of ' + original.name});
                                  setShowCreateSLAModal(true);
                                }}
                              >
                                Clone SLA
                              </li>
                            )}
                            {canDeleteContracts && (
                              <li
                                className="text-danger"
                                onClick={() => {
                                  onClick();
                                  setDeletingSLA(original);
                                }}
                              >
                                Delete
                              </li>
                            )}
                          </>
                        )}
                      </Dropdown>
                    )}
                  </>
                );
              }
            }
          ]}
        />
      </div>

      <Modal
        variant="warning"
        show={Boolean(deletingSLA)}
        title="Delete SLA"
        primaryBtnName="Delete SLA"
        onClose={() => setDeletingSLA(null)}
        onPrimaryAction={() => handleDeleteSLA(deletingSLA)}
      >
        <p>Are you sure you want to delete this SLA?</p>
      </Modal>
      <Modal
        show={showCreateSLAModal}
        title={cloningSLA ? 'Clone Service Level Agreement' : 'Add a Service Level Agreement'}
        footerComponent={null}
        onClose={handleSLAModalDisplay}
      >
        <ServiceLevelAgreementCreateContainer
          {...props}
          onCancel={handleSLAModalDisplay}
          onSubmitSuccess={handleCreateSuccess}
          cloningSLA={cloningSLA}
          setError={setError}
        />
      </Modal>
      <Modal
        fullScreen={useFullScreen}
        show={showImportSLAModal}
        title="Import SLAs"
        footerComponent={null}
        onClose={() => {
          setShowImportSLAModal(false);
          setUseFullScreen(false);
        }}
      >
        <CreateImport
          setUseFullScreen={setUseFullScreen}
          onCancel={() => {
            setShowImportSLAModal(false);
            setUseFullScreen(false);
          }}
          type="SERVICE_LEVEL_AGREEMENTS"
          setShowImportSuccess={(numRecords) => {
            setSuccess('Success!', `${numRecords} ${pluralize('record', numRecords)} imported.`);
            fetchSLAs({location});
          }}
        />
      </Modal>
    </div>
  );
};

export default connect((state) => ({
  canDeleteContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.delete_contracts'),
  canEditContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.update_contracts'),
  canCreateContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.create_contracts'),
  canViewContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts')
}))(ServiceLevelAgreementsList);
