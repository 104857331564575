import {Rule} from '@shipwell/shipwell-ui';
import Stop from './Stop';
import {LoadLocationStopType, PickupStopType, DeliveryStopType} from './StopTypeContactsFields';
import ContainerPickup from './ContainerPickup';
import ContainerReturn from './ContainerReturn';

const Stops = () => (
  <>
    <ContainerPickup />
    <Stop stopType={LoadLocationStopType} />
    <Stop stopType={PickupStopType} />
    <Rule />
    <Stop stopType={DeliveryStopType} />
    <ContainerReturn />
  </>
);

export default Stops;
