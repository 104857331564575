/* global google */
import {useState, useEffect} from 'react';
import get from 'lodash/get';
import {Select, FormikSelect, SvgIcon} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import {stateProvinceOptions, countryOptions} from './constants';
import {getAddressBookPromise} from 'App/actions/addressBook';
import AddressVerification from 'App/components/_addressVerification';
import {loadGoogleMapsAPI} from 'App/utils/globals';
import {getFormattedThreeDigitPostalCodes} from 'App/utils/addressSearchUtils';

let placeService;
let autocomplete;
/**
 * Address Search Field
 * @description Search from address book, google maps or both
 */
const MultiAddressSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [addressBookSelection, setAddressBookSelection] = useState(null);

  useEffect(() => {
    if (window.google) {
      initGoogleMaps();
    } else {
      loadGoogleMapsAPI(() => initGoogleMaps());
    }
  }, []);

  const initGoogleMaps = () => {
    autocomplete = new google.maps.places.AutocompleteService();
    placeService = new google.maps.places.PlacesService(document.createElement('div'));
  };

  /**
   * Get formatted address is selected address is from Googles predicted places
   * @param  {Object} place.value Selected place
   */
  const handlePlaceSelection = async (place) => {
    if (place?.value?.place_id) {
      const placeDetails = await new Promise((resolve, reject) => {
        placeService.getDetails({placeId: place.value.place_id}, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      return {
        label: placeDetails.formatted_address,
        value: placeDetails
      };
    }
    return place;
  };

  /**
   * Request place predictions from Google Mapa
   * @param  {String} value Place search input
   * @return {Promise}
   */
  const getPlacePredictions = (value, types = ['geocode', 'establishment']) => {
    return new Promise((resolve, reject) => {
      autocomplete.getPlacePredictions({input: value, types: types}, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  };

  /**
   * Request AddressBook entried
   * @param  {String} value    Place search input
   * @param  {Number} pageSize Number of results
   * @return {Promise}
   */
  const getAddressBookResults = (value, pageSize) => {
    return getAddressBookPromise({q: value, pageSize: pageSize});
  };

  /**
   * Request AddressBook entried
   * @param  {String} value    Place search input
   * @return {Promise}
   */
  const getStateProvinceResults = (value) => {
    return new Promise((resolve) => {
      const results = stateProvinceOptions.filter((state) =>
        state.formatted_address.toLowerCase().includes(value.toLowerCase())
      );
      resolve(results);
    });
  };

  /**
   * Request AddressBook entried
   * @param  {String} value    Place search input
   * @return {Promise}
   */
  const getCountryResults = (value) => {
    return new Promise((resolve) => {
      const results = countryOptions.filter((country) =>
        country.formatted_address.toLowerCase().includes(value.toLowerCase())
      );
      resolve(results);
    });
  };

  /**
   * Search address from both Google and address book
   * @param  {String} value Address search value
   * @return {Promise}
   */
  const searchAddresses = async (value, fieldValues = []) => {
    const {
      searchMaps,
      searchAddressBook,
      searchStateProvince,
      searchCountry,
      searchTypes,
      searchThreeDigitPostalCode,
      threeDigitPostalCodeOptionalValues
    } = props;

    if (value) {
      try {
        setLoading(true);
        const maxResults = 50;
        const response = {options: []};

        const [
          formattedThreeDigitPostalCodeResult,
          googleResults,
          addressBookResults,
          stateProvinceResults,
          countryResults
        ] = await Promise.all([
          (searchThreeDigitPostalCode &&
            getFormattedThreeDigitPostalCodes(value, threeDigitPostalCodeOptionalValues)) ||
            [],
          searchMaps && getPlacePredictions(value, searchTypes),
          searchAddressBook && getAddressBookResults(value, maxResults),
          (searchStateProvince && getStateProvinceResults(value)) || [],
          (searchCountry && getCountryResults(value)) || []
        ]);

        formattedThreeDigitPostalCodeResult.length &&
          formattedThreeDigitPostalCodeResult
            //filter out 3 digit zip results that are already selected
            ?.filter(
              (result) => !fieldValues?.map((fieldValue) => fieldValue.postal_code)?.includes(result.postal_code)
            )
            ?.forEach((result) => response.options.push(result));

        stateProvinceResults?.forEach((state) => response.options.push(state));

        countryResults?.forEach((country) => response.options.push(country));

        /** Format address book options */
        addressBookResults?.results?.forEach((company) => {
          response.options.push({
            ...company.address,
            value: company,
            validated: true,
            formatted_address: company.address.formatted_address,
            name: company.company_name,
            icon: true
          });
        });

        /** Fill in remaining options with Google auto suggestions */
        while (googleResults?.length && response.options.length < maxResults) {
          const googleLocation = googleResults.shift();

          // Exclude administrative area level 1 (state in US, province in Canada) if we are already
          // searching states and provinces
          // In a similar fashion, exclude countries if we are searching for them
          const types = get(googleLocation, 'types', []);
          if (
            (searchStateProvince && types.includes('administrative_area_level_1')) ||
            (searchCountry && types.includes('country'))
          ) {
            continue;
          }

          response.options.push({
            value: googleLocation,
            formatted_address: googleLocation.description
          });
        }
        setLoading(false);
        return response.options;
      } catch (error) {
        console.error(error);
      }
    }
  };
  const {
    field,
    form,
    label,
    prepend,
    required,
    disabled,
    allowZipOnly,
    useBaseComponent,
    allowCreate,
    onChange,
    value,
    ignoreWarnings,
    ignoreFieldWarnings,
    searchCountry,
    searchThreeDigitPostalCode,
    threeDigitPostalCodeOptionalValues,
    isMulti
  } = props;

  const SelectComponent = useBaseComponent ? Select : FormikSelect;

  return (
    <AddressVerification
      allowZipOnly={allowZipOnly}
      initialValue={useBaseComponent ? value : field?.value}
      onChange={(addresses) => {
        useBaseComponent ? onChange?.(addresses) : form?.setFieldValue(field.name, addresses);
      }}
      ignoreWarnings={ignoreWarnings}
      ignoreFieldWarnings={ignoreFieldWarnings}
    >
      {({value, onChange, ...props}) => {
        const handleChange = async (selection) => {
          isMulti ? handleChangeMulti(selection) : handleChangeSingle(selection);
        };

        const handleChangeMulti = (selection) => {
          const needsValidation = selection.filter((e) => !e.validated);
          if (!needsValidation.length > 0) {
            selection.map((option) => {
              if (option.icon || option.zipIcon) {
                option.icon ? delete option.icon : delete option.zipIcon;
              }
            });
            onChange(selection);
          } else {
            needsValidation.forEach(async (newAddress) => {
              if (newAddress?.icon) {
                setAddressBookSelection(...addressBookSelection, newAddress);
                field.onChange({...newAddress.value, company: newAddress.value});
              } else {
                try {
                  const address = await handlePlaceSelection(newAddress);
                  if (address?.value) {
                    onChange(address.value, searchThreeDigitPostalCode, threeDigitPostalCodeOptionalValues);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            });
          }
        };

        const handleChangeSingle = async (selection) => {
          const selectedValue = selection; // get the single selected value
          if (selectedValue) {
            try {
              const address = await handlePlaceSelection(selectedValue);
              if (address?.value) {
                onChange(address.value, searchThreeDigitPostalCode, threeDigitPostalCodeOptionalValues);
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            onChange([]);
          }
        };

        return (
          <SelectComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            async
            isMulti={isMulti}
            allowCreate={allowCreate}
            field={field}
            form={form}
            label={label}
            value={value}
            required={required}
            disabled={disabled}
            prepend={prepend}
            isLoading={loading}
            cacheOptions={false}
            onCreateOption={onChange}
            formatCreateLabel={(value) => <div>{`Search for "${value}"`}</div>}
            formatOptionLabel={(option) => {
              if (option.__isNew__) {
                //hack because formatCreateLabel doesnt seem to work when formatOptionLabel is specified
                return <span className="text-primary">{`Search for "${option.value}"`}</span>;
              }
              let formattedAddress = option.formatted_address;

              // String manipulation for country labels, which return from the backend like ", US"
              if (searchCountry && formattedAddress.split('')[0] === ',') {
                formattedAddress = formattedAddress.slice(1).trim();
              }
              return (
                <span className={`async-option flex space-x-1 ${option.icon ? 'addr-option' : ''}`}>
                  {option.icon || option.zipIcon ? (
                    <span className="option-icon">
                      {<SvgIcon name={option.icon ? 'IdCard' : 'Zipcode'} color="$sw-icon" height="20" width="20" />}
                    </span>
                  ) : null}
                  <span className="option-label">
                    {`${option.name ? ' ' + option.name + ' - ' : ''}`}
                    {formattedAddress}
                  </span>
                </span>
              );
            }}
            loadOptions={(userValue) => searchAddresses(userValue, value)}
            getOptionLabel={(option) => option.formatted_address}
            getOptionValue={(option) => option.formatted_address}
            onChange={handleChange}
          />
        );
      }}
    </AddressVerification>
  );
};

MultiAddressSearch.defaultProps = {
  searchMaps: true,
  searchAddressBook: true,
  searchStateProvince: false,
  searchCountry: false,
  placeholder: 'Addresses',
  icon: 'icon-Pointer',
  searchThreeDigitPostalCode: false,
  isMulti: true
};

MultiAddressSearch.propTypes = {
  searchMaps: PropTypes.bool,
  searchAddressBook: PropTypes.bool,
  searchStateProvince: PropTypes.bool,
  searchCountry: PropTypes.bool,
  searchTypes: PropTypes.arrayOf(PropTypes.string),
  searchThreeDigitPostalCode: PropTypes.bool,
  isMulti: PropTypes.bool,
  threeDigitPostalCodeOptionalValues: PropTypes.arrayOf(PropTypes.string),
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  prepend: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  allowZipOnly: PropTypes.bool,
  useBaseComponent: PropTypes.bool,
  allowCreate: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array,
  ignoreWarnings: PropTypes.bool,
  ignoreFieldWarnings: PropTypes.arrayOf(PropTypes.string)
};

export default MultiAddressSearch;
