import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {CapacitySearchResponseMultiplePaginated} from '@shipwell/tabula-sdk';
import {CapacitySearchGetNamedParameters, getCapacitySearch} from 'App/api/capacitySearch';
import {CAPACITY_SEARCH_KEY} from 'App/data-hooks/queryKeys';
import {MINUTE, STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const LAST_SHIPMENT_SOURCE_CAPACITY_QUERY_ID = 'lastQueryId';

const useGetCapacitySearch = (
  queryId: string,
  shipmentId: string,
  params: {page: number; pageSize: number},
  filters: Omit<CapacitySearchGetNamedParameters, 'queryId' | 'page' | 'pageSize'>,
  options?: UseQueryOptions<CapacitySearchResponseMultiplePaginated>
) => {
  const id = queryId || localStorage.getItem(LAST_SHIPMENT_SOURCE_CAPACITY_QUERY_ID);

  const response = useQuery(
    [CAPACITY_SEARCH_KEY, shipmentId, id, params] as QueryKey,
    async () => {
      const response = await getCapacitySearch({queryId, ...params, ...filters});
      return response.data;
    },
    {
      enabled: !!queryId,
      staleTime: STATIC_DATA_STALE_TIME * 3, // 30 minutes
      cacheTime: 30 * MINUTE, // 30 minutes
      keepPreviousData: true,
      ...options
    }
  );

  return response;
};

export default useGetCapacitySearch;
