const FTL_MODE = {id: 1, description: 'Full Truckload', code: 'FTL'};
const LTL_MODE = {id: 2, description: 'Less Than Truckload', code: 'LTL'};
const DRY_VAN_EQUIPMENT_TYPE = {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'};
export const DEFAULT_MODE = FTL_MODE;
export const DEFAULT_EQUIPMENT_TYPE = DRY_VAN_EQUIPMENT_TYPE;
export const LOAD_OPTIMIZATION_MODES = [FTL_MODE, LTL_MODE];
//the status of the most recently attempted load optimization job
export const SHIPMENT_ASSEMBLY_JOB_STATUS_KEY = 'job_status';
//the status of the load itself. This is distinct from the load optimization job status,
//because it refers to whether the load is able to be retrieved, i.e., that the load
//is not in a bad or corrupted state.
export const SHIPMENT_ASSEMBLY_LOAD_STATUS_KEY = 'load_status';
export const MAP_FTL_COLOR = '#1B7B3B';
export const MAP_LTL_COLOR = '#006BA2';
export const MAP_LINE_POINT_CIRCLE_RADIUS = 3;
export const MAP_LINE_WIDTH = 2;
