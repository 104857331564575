import classNames from 'classnames';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import {DeprecatedButton, SvgIcon, FormikTextInput} from '@shipwell/shipwell-ui';
import './styles.scss';

const validationSchema = object().shape({
  customer_key: string().required('Please enter a customer key')
});

const SaferwatchConfig = (props) => {
  const {values = {}, handleSubmit, isConnected, handleRefresh, isBtnLoading} = props;
  const defaultFormValues = {
    customer_key: isConnected ? '******' : ''
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{...defaultFormValues, ...values}}
    >
      {({handleSubmit, isSubmitting}) => (
        <>
          <Form className="field-grid">
            <div className="saferwatch__config-field">
              <Field label="Customer Key" name="customer_key" disabled={isConnected} component={FormikTextInput} />
              {isConnected ? (
                <DeprecatedButton onClick={handleRefresh} loading={isBtnLoading}>
                  Sync to Watch list
                </DeprecatedButton>
              ) : (
                <DeprecatedButton type="submit" onClick={handleSubmit} loading={isSubmitting}>
                  Connect
                </DeprecatedButton>
              )}
            </div>
          </Form>
          <div className={classNames('grid-1-1', {connected: isConnected})}>
            {isConnected && (
              <div className="saferwatch__config-connected">
                <SvgIcon name="CheckCircleFilled" />
                <span className="saferwatch__config-connected-text">Connected</span>
              </div>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default SaferwatchConfig;
