import reduce from 'lodash/reduce';
import identity from 'lodash/identity';

/**
 * Lodash has a `partition`, but it only passes `value` to the predicate.
 *
 * This is an implementation of `partition` that passes other collection attributes to the
 * predicate, letting us do things like partition by index.
 *
 * The predicate is expected to be a function and doesn't have any of the nifty shorthand that
 * lodash provides, like property or matches.
 *
 * It probably doesn't have all of the functionality of lodash/partition, so only use this if you
 * need to partition on index or some general attribute of the collection.
 */
export default (collection, predicate = identity) =>
  reduce(
    collection,
    ([truthy, falsy], value, index, coll) =>
      predicate(value, index, coll) ? [[...truthy, value], falsy] : [truthy, [...falsy, value]],
    [[], []]
  );
