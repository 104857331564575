import {IconButton, Modal, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useState} from 'react';
import {Shipment, TendersApiTendersPostRequest} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikHelpers} from 'formik';
import {useQueryClient} from '@tanstack/react-query';
import useToggle from 'App/utils/hooks/useToggle';
import {useGetApplicableContracts} from 'App/data-hooks/contracts/useGetApplicableContracts';
import TenderToSpecificContractForm, {
  TenderToSpecificCarrierFormValues
} from 'App/formComponents/forms/tenderToSpecificCarrier';
import {useCreateTender} from 'App/data-hooks/tendering/useCreateTender';
import {transformAxiosError} from 'App/utils/globalsTyped';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {TENDERS_KEY} from 'App/data-hooks/queryKeys';
import {mapContracts} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import DisplayCarriers from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayCarriers';

const MAX_AMOUNT_OF_CONTRACTS_TO_DISPLAY = 20;

const ContractRatesTooltipText = () => {
  return (
    <div>
      <div className="text-bold">Contract Rates</div>
      <div>
        This shows up to {MAX_AMOUNT_OF_CONTRACTS_TO_DISPLAY} contract rates, actions are to tender directly to a
        carrier or assign the carrier to the shipment.
      </div>
    </div>
  );
};

const ContractRates = ({
  shipment,
  setSuccess,
  setError,
  isFTLShipment,
  showDPM,
  totalMiles
}: {
  shipment: Shipment;
  isFTLShipment: boolean;
  showDPM?: boolean;
  totalMiles?: number | null;
} & WithStatusToastProps) => {
  const [contractRatesExpanded, toggleContractRatesExpanded] = useToggle(true);
  const [showTenderModal, toggleShowTenderModal] = useToggle(false);
  const [selectedContractId, setSelectedContractId] = useState<string>();
  const queryClient = useQueryClient();
  const {id: shipmentId} = shipment;

  const {applicableContracts, isFetchingApplicableContractsWithCharges} = useGetApplicableContracts({
    shipmentId
  });
  const {createTender} = useCreateTender();

  const handleSelectContract = (contractId?: string) => {
    setSelectedContractId(contractId);
    toggleShowTenderModal();
  };

  const handleTenderToCarrier = (
    payload: TendersApiTendersPostRequest,
    setSubmitting: FormikHelpers<TenderToSpecificCarrierFormValues>['setSubmitting']
  ) => {
    return createTender(payload, {
      onSuccess: () => {
        toggleShowTenderModal();
        setSubmitting(false);
        void queryClient.invalidateQueries([TENDERS_KEY]);
        setSuccess('Success', 'Tender request sent to carrier.');
      },
      onError: (error) => {
        setSubmitting(false);
        const {title, message} = transformAxiosError(error);
        setError(title, message);
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-between border-y-1 border-sw-border px-3 py-2">
        <div className="flex items-center">
          <div className="text-bold mr-1">Contract Rates</div>
          <Tooltip
            placement="top"
            tooltipClassname="w-72"
            tooltipContent={<ContractRatesTooltipText />}
            trigger="hover"
            portal
          >
            <SvgIcon color="$sw-icon" name="InfoOutlined" />
          </Tooltip>
        </div>
        <IconButton
          iconName={contractRatesExpanded ? 'ExpandLess' : 'ExpandMore'}
          aria-label={contractRatesExpanded ? 'Hide contract rates' : 'Expand contract rates'}
          onClick={() => toggleContractRatesExpanded()}
        />
      </div>
      <DisplayCarriers
        isLoading={isFetchingApplicableContractsWithCharges}
        shipment={shipment}
        isSectionExpanded={contractRatesExpanded}
        handleSelectCarrier={handleSelectContract}
        carriers={mapContracts(applicableContracts)}
        isFTLShipment={isFTLShipment}
        isContractRates
        showDPM={showDPM}
        totalMiles={totalMiles}
      />
      <Modal
        show={showTenderModal}
        title="Tender To Carrier"
        footerComponent={null}
        onClose={() => toggleShowTenderModal()}
      >
        <TenderToSpecificContractForm
          shipment={shipment}
          applicableContract={applicableContracts?.find(
            (applicableContract) => applicableContract.contract?.id === selectedContractId
          )}
          onClose={() => toggleShowTenderModal()}
          handleTenderToCarrier={handleTenderToCarrier}
        />
      </Modal>
    </>
  );
};

export default WithStatusToasts(ContractRates);
