import {Accessorial} from '@shipwell/backend-core-sdk';

const workingAccessorialCodes = [
  'LGDEL',
  'RESDEL',
  'LGPU',
  'RESPU',
  'APPTDEL',
  'LTDDEL',
  'CONDEL',
  'SORTDEL',
  'LTDPU',
  'INDEL',
  'INPU',
  'CNVPU',
  'CNVDEL',
  'CROSSDEL',
  'CROSSPU',
  'TRANSLOADPU',
  'TRANSLOADDEL',
  'WHITEGLOVE',
  'HDAYPU',
  'HDAYDEL',
  'SUNDEL',
  'SUNPU',
  'ROOMOFCHOICE'
];

export function filterAccessorials(accessorials: Accessorial[]) {
  accessorials = accessorials || [];
  //filter down to pickup, dropoff, and shipment level accessorials. shipment-level is a specific list of acc that we know work
  const accessorialObj = {
    drayagePickup: [] as Accessorial[],
    drayageDropoff: [] as Accessorial[],
    drayageShipment: [] as Accessorial[],
    parcelShipment: [] as Accessorial[],
    pickup: [] as Accessorial[],
    dropoff: [] as Accessorial[],
    pickupDropoff: [] as Accessorial[],
    shipment: [] as Accessorial[]
  };
  const drayageAccessorialPickupIds = new Set([113, 253, 258, 256, 135, 232, 125, 259, 260]);
  const drayageAccessorialDropoffIds = new Set([261, 262, 263, 264, 257, 125]);
  const drayageAccessorialShipmentIds = new Set([162, 205, 206, 207, 265, 127, 266, 267, 228, 197, 179]);
  const saturdayDeliveryAccessorialId = 87;

  accessorialObj.drayagePickup = accessorials.filter((accessorial) => drayageAccessorialPickupIds.has(accessorial.id));
  accessorialObj.drayageDropoff = accessorials.filter((accessorial) =>
    drayageAccessorialDropoffIds.has(accessorial.id)
  );
  accessorialObj.drayageShipment = accessorials.filter((accessorial) =>
    drayageAccessorialShipmentIds.has(accessorial.id)
  );

  accessorialObj.parcelShipment = accessorials.filter((a) => a?.id === saturdayDeliveryAccessorialId);

  accessorialObj.pickup = accessorials.filter((e) => e.type === 'pickup' && workingAccessorialCodes.includes(e.code));
  accessorialObj.dropoff = accessorials.filter(
    (e) => (e.type === 'dropoff' || e.type === 'inside-delivery') && workingAccessorialCodes.includes(e.code)
  );
  accessorialObj.pickupDropoff = accessorials.filter(
    (e) =>
      (e.type === 'dropoff' || e.type === 'pickup' || e.type === 'inside-delivery') &&
      workingAccessorialCodes.includes(e.code)
  );
  accessorialObj.shipment = accessorials.filter(
    (item) =>
      item.type !== 'dropoff' &&
      item.type !== 'pickup' &&
      (item.id === 176 ||
        item.id === 104 ||
        item.id === 121 ||
        item.id === 153 ||
        item.id === 163 ||
        item.id === 178 ||
        item.id === 179 ||
        item.id === 201 ||
        item.id === 205 ||
        item.id === 209 ||
        item.id === 225 ||
        item.id === 67 ||
        item.id === 69 ||
        item.id === 70 ||
        item.id === 68 ||
        item.id === 136 ||
        item.id === 138 ||
        item.id === 139 ||
        item.id === 140 ||
        item.id === 142 ||
        item.id === 143 ||
        item.id === 145 ||
        item.id === 147 ||
        item.id === 137 ||
        item.id === 141 ||
        item.id === 144 ||
        item.id === 146 ||
        item.id === 148 ||
        item.id === 149 ||
        item.id === 150 ||
        item.id === 151 ||
        item.id === 152 ||
        item.id === 113 ||
        item.id === 197 ||
        item.id === 253 ||
        item.id === 254 ||
        item.id === 254 ||
        item.id === 255 ||
        item.id === 256 ||
        item.id === 257 ||
        item.id === 228 ||
        item.id === 206 ||
        item.id === 258 ||
        item.id === 268 ||
        item.id === 232)
  );

  accessorialObj.dropoff = accessorialObj.dropoff.sort((a, b) => {
    return a.description < b.description ? -1 : 1;
  });

  return accessorialObj;
}
