import {useState} from 'react';
import {WithRouterProps} from 'react-router';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import debounce from 'lodash/debounce';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import Drawer from '@material-ui/core/Drawer';

import {
  RFPBidOptResponse,
  RfpLaneOptResponse,
  RFPBidOptResponseStateEnum,
  RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum,
  RFPBidOptContractWorkflowCreationRecordResponseWorkflowStatusEnum,
  RFPBidOptContractWorkflowCreationRecordResponse
} from '@shipwell/backend-core-sdk';
import {SvgIconName, IconButton} from '@shipwell/shipwell-ui';
import {Row} from 'react-table';
import TableFiltersWithSavedViews from 'App/components/_tableFiltersWithSavedViews';
import {
  RfpHeader,
  columns as rfpColumns,
  filters,
  RfpDetailTabs,
  TableActionButton
} from 'App/containers/userCompany/rfps/RfpTableRoute';
import ListViewLoader from 'App/containers/purchaseOrders/list/components/ListViewLoader';
import {
  getOptimizedRfpDetails,
  getOptimizedRfpLanes,
  getRfpCsv,
  getContractsWorkflowCreationProgress
} from 'App/api/rfpOptimized/typed';
import {
  RFP_CSV_QUERY,
  RFP_OPT_QUERY,
  RFP_OPT_ROWS_QUERY,
  RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY
} from 'App/data-hooks/queryKeys';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {TableFiltersContextConsumer, TableFiltersContextProvider} from 'App/utils/hooks/useTableFilters';
import buildPathParams from 'App/utils/buildPathParams';
import Table from 'App/components/Table';
import {PAGE_OPTION_DEFAULTS} from 'App/containers/userCompany/constants';
import LaneBids from 'App/containers/userCompany/rfps/LaneBids';
import OriginAndDestinationBanner from 'App/common/OriginAndDestinationBanner';

const TABS: {key: string; icon: SvgIconName}[] = [
  {key: 'Lane Bids', icon: 'DollarFilled'}
  // {key: 'Lane Stats', icon: 'BarFilled'},
  // {key: 'Lane Workflows', icon: 'Workflow'}
];

const RfpTableRoute = ({params, location, router}: WithRouterProps) => {
  const id = params.rfpId;
  const [tab, setTab] = useState(TABS[0].key);
  const [pageOptions, setPageOptions] = useState(PAGE_OPTION_DEFAULTS);
  const [selectedRow, setSelectedRow] = useState<RfpLaneOptResponse>();
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearch = debounce((val: string) => setSearchVal(val), 300);
  const initialSortKey = 'origin_city';

  const [columns, setColumns] = useState(rfpColumns);

  const handlePageChange = (opts: {pageSize: number; pageIndex: number}) => {
    const {pageSize, pageIndex} = opts;
    setPageOptions({pageSize: pageSize || 20, page: pageIndex ? (pageIndex || 0) + 1 : 1});
  };

  const rfpDetailsQuery = useQuery(
    [RFP_OPT_QUERY, id],
    async () => {
      const response = await getOptimizedRfpDetails(id);
      return response.data;
    },
    {
      enabled: !!id
    }
  );

  const query = {
    //product request to initially order by city
    ordering: initialSortKey,
    ...omitBy(omit(location.query, 'viewId'), isNil),
    ...pageOptions,
    ...(searchVal && {q: searchVal})
  };

  const rfpRowsQuery = useQuery(
    [RFP_OPT_ROWS_QUERY, id, query],
    async () => {
      const response = await getOptimizedRfpLanes(id, query);
      return response.data;
    },
    {
      enabled: !!id,
      keepPreviousData: true
    }
  );

  const queryClient = useQueryClient();

  const isCreationRecordInProgress = (creationRecordResponse: RFPBidOptContractWorkflowCreationRecordResponse[]) =>
    creationRecordResponse?.some(
      (response) =>
        response.contracts_status === RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum.InProgress
    ) ||
    creationRecordResponse?.some(
      (response) =>
        response.workflow_status === RFPBidOptContractWorkflowCreationRecordResponseWorkflowStatusEnum.InProgress
    );

  useQuery(
    [RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY, id],
    async () => {
      const response = await getContractsWorkflowCreationProgress(id);
      return response.data;
    },
    {
      enabled: [
        RFPBidOptResponseStateEnum.ContractsCreateRequested,
        RFPBidOptResponseStateEnum.ContractsRgCreateRequested
      ].some((status) => status === rfpDetailsQuery.data?.state),
      refetchInterval: (data) => (data && isCreationRecordInProgress(data) ? 2000 : false),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && !isCreationRecordInProgress(data)) {
          void (async () => {
            await queryClient.invalidateQueries([RFP_OPT_QUERY, id]);
            await queryClient.invalidateQueries([RFP_OPT_ROWS_QUERY, id]);
          })();
        }
      }
    }
  );

  const rfpRowData = (rfpRowsQuery.data?.results || []) as (RFPBidOptResponse & {unique_bids_received?: number})[];
  const pageCount = rfpRowsQuery.data?.total_pages || 1;

  const rfpCsvQuery = useQuery(
    [RFP_CSV_QUERY, id],
    async () => {
      const response = await getRfpCsv(id);
      return response.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const csvHeader = 'data:text/csv;charset=uft-8,';
        const csvContent = `${csvHeader}${data}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `rfp_lanes.csv`);
        document.body.appendChild(link);
        link.click();
      }
    }
  );

  if (rfpDetailsQuery.isInitialLoading || rfpRowsQuery.isInitialLoading) {
    return <ListViewLoader />;
  }

  const handleRowClick = (row: Row<RfpLaneOptResponse>) => {
    const currentRow = row.original;
    setSelectedRow((prevRow) => {
      if (prevRow?.id === currentRow.id) {
        return undefined;
      }
      return currentRow;
    });
  };

  const handleDownload = () => {
    void rfpCsvQuery.refetch();
  };

  const hasRows = rfpRowData?.length > 0;

  return (
    <TableFiltersContextProvider tableType="RFP" router={router} location={location} columns={rfpColumns}>
      <TableFiltersContextConsumer>
        {() => (
          <div className="RfpTableRoute isolate flex size-full flex-col overflow-hidden">
            <RfpHeader name={rfpDetailsQuery.data?.name || ''} onSearch={debouncedSearch}>
              <IconButton
                color="primary"
                onClick={handleDownload}
                iconName="Download"
                aria-label="Download RFP csv file"
              />
              <TableActionButton rfp={rfpDetailsQuery.data || {}} lanes={rfpRowsQuery.data?.results || []} />
            </RfpHeader>
            <div className="flex h-full overflow-hidden">
              <TableFiltersWithSavedViews
                router={router}
                location={location}
                buildPathParams={buildPathParams}
                filters={filters}
                tableType="RFP"
                tableColumns={columns}
                setTableColumns={setColumns}
              />
              <Table
                columns={columns}
                data={rfpRowData}
                allowSort
                showPaginationFooter
                pageCount={pageCount}
                onFetchData={handlePageChange}
                initialPageSize={20}
                onRowClick={handleRowClick}
                selectedRowId={selectedRow?.id}
                initialSortKey={initialSortKey}
              />
            </div>
            {!hasRows ? <EmptyListView itemLabel="Table Data" /> : null}
            <Drawer
              variant="persistent"
              anchor="right"
              open={!!selectedRow}
              onClose={() => setSelectedRow(undefined)}
              PaperProps={{style: {height: 'calc(100vh - 64px', maxWidth: '100vw', top: 64}}}
            >
              <div className="flex h-full">
                <RfpDetailTabs tabs={TABS} activeTabKey={tab} onChange={setTab} />
                <div className="flex w-full flex-col overflow-hidden">
                  <div className="flex justify-between gap-4 p-2">
                    <span className="text-lg font-bold">{tab}</span>
                    <IconButton iconName="Close" aria-label="Close details" onClick={() => setSelectedRow(undefined)} />
                  </div>
                  <OriginAndDestinationBanner
                    origin={`${selectedRow?.origin_city || ''}, ${selectedRow?.origin_state || ''}`}
                    destination={`${selectedRow?.destination_city || ''}, ${selectedRow?.destination_state || ''}`}
                  />
                  {tab === TABS[0].key && selectedRow ? (
                    <LaneBids
                      row={selectedRow}
                      showEmptyBids={rfpDetailsQuery.data?.state === RFPBidOptResponseStateEnum.BidRequested}
                    />
                  ) : null}
                </div>
              </div>
            </Drawer>
          </div>
        )}
      </TableFiltersContextConsumer>
    </TableFiltersContextProvider>
  );
};

export default RfpTableRoute;
