import {SvgIcon, ToggleSwitch} from '@shipwell/shipwell-ui';

import classNames from 'classnames';
import {useMemo} from 'react';
import {Original, UserData} from '../utils';

export type SwitchProps = {
  original: Original;
  value: string;
  handleChange: (checked: boolean, userData: UserData) => void;
  isConnected: boolean;
  isLoading: boolean;
};

const Switch = ({original, value, handleChange, isConnected, isLoading}: SwitchProps) => {
  const checked = useMemo(() => Boolean(original.truckstop?.is_active), [original]);

  const userData = {
    id: original.id,
    first_name: original.first_name,
    last_name: original.last_name,
    email: original.email
  };

  const onClick = () => handleChange(checked, userData);

  if (isConnected) {
    return (
      <span
        role="button"
        className={classNames('flex items-center gap-2', {'text-sw-disabled': !checked})}
        onClick={onClick}
      >
        <span className="pointer-events-none">
          <ToggleSwitch name={value} fixedHeight={false} isSmall checked={checked} />
        </span>
        <span>{value}</span>
        {isLoading ? (
          <SvgIcon height="20" width="20" className="animate-slow-spin text-sw-primary" name="Running" />
        ) : null}
      </span>
    );
  }

  return (
    <span className="flex items-center gap-2 text-sw-disabled">
      <ToggleSwitch name={value} fixedHeight={false} isSmall checked={false} disabled />
      {value}
    </span>
  );
};

export default Switch;
