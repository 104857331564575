import {Modal} from '@shipwell/shipwell-ui';
import {ReactNodeLike} from 'prop-types';
import {Children, ComponentPropsWithoutRef, cloneElement, isValidElement} from 'react';
import {FreightInvoiceForm} from '../FreightInvoiceForm';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

export interface FreightInvoiceModalProps {
  show: boolean;
  freightInvoiceId?: string;
  onClose: () => void;
  children: ReactNodeLike;
}
const FreightInvoiceModal = ({
  show,
  freightInvoiceId,
  onClose,
  children,
  setSuccess,
  setError
}: FreightInvoiceModalProps & WithStatusToastProps) => {
  return (
    <Modal
      title={`${freightInvoiceId ? 'Edit' : 'Create New'} Invoice`}
      show={show}
      onClose={onClose}
      footerComponent={null}
    >
      {Children.map(children, (child) =>
        isValidElement<ComponentPropsWithoutRef<typeof FreightInvoiceForm>>(child)
          ? cloneElement<ComponentPropsWithoutRef<typeof FreightInvoiceForm>>(child, {setSuccess, setError})
          : child
      )}
    </Modal>
  );
};
export default WithStatusToasts(FreightInvoiceModal);
