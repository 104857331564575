import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ensurePluginOrder} from 'react-table';
import {Checkbox} from '@shipwell/shipwell-ui';
import {SelectedCount} from 'App/components/SelectedCount';

export default function useSelectionColumn(hooks) {
  hooks.getHeaderProps.push(getSelectionCellStyles);
  hooks.getCellProps.push(getSelectionCellStyles);
  hooks.visibleColumns.push(visibleColumns);
  hooks.useInstance.push(useInstance);
}

useSelectionColumn.pluginName = 'useSelectionColumn';

const SelectionHeader = ({getToggleAllRowsSelectedProps}) => (
  <div>
    <Checkbox {...getToggleAllRowsSelectedProps()} />
  </div>
);

SelectionHeader.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func
};

const SelectionCell = ({row}) => (
  <div>
    <Checkbox {...row.getToggleRowSelectedProps()} />
  </div>
);

SelectionCell.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func
  })
};

const RowSelectionSummary = ({selectedFlatRows, toggleAllRowsSelected, itemLabel}) =>
  selectedFlatRows?.length > 0 ? (
    <div className="flex justify-center py-2">
      <SelectedCount
        count={selectedFlatRows?.length}
        clickCta="Deselect All"
        onClick={() => toggleAllRowsSelected(false)}
        itemLabel={itemLabel}
      />
    </div>
  ) : null;

RowSelectionSummary.propTypes = {
  selectedFlatRows: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.object
    })
  ),
  toggleAllRowsSelected: PropTypes.func,
  itemLabel: PropTypes.string
};

function visibleColumns(
  columns,
  {
    instance: {
      state: {showRowSelect}
    }
  }
) {
  return showRowSelect
    ? [
        {
          id: 'selection',
          Header: SelectionHeader,
          Cell: SelectionCell,
          maxWidth: 40
        },
        ...columns
      ]
    : columns;
}

function useInstance(instance) {
  const {
    plugins,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: {itemLabel, onSelection, showRowSelect}
  } = instance;

  ensurePluginOrder(plugins, ['useRowSelect'], 'useSelectionColumn');

  useEffect(() => {
    onSelection(selectedFlatRows.map((row) => row.original));
  }, [onSelection, selectedFlatRows]);

  Object.assign(instance, {
    renderRowSelectionSummary: () =>
      showRowSelect ? (
        <RowSelectionSummary
          itemLabel={itemLabel}
          selectedFlatRows={selectedFlatRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
      ) : null
  });
}

function getSelectionCellStyles(props, {column, cell}) {
  return [
    props,
    {
      style: {
        alignItems:
          column?.id === 'selection' || cell?.column?.id === 'selection' || (column && 'Header' in column)
            ? 'center'
            : 'start'
      }
    }
  ];
}
