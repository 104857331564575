import {useMutation, useQueryClient} from '@tanstack/react-query';
import {addCorrogoOrdersToShipment} from 'App/api/shipment/typed';
import {ORDERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useAddOrdersToShipment = () => {
  const queryClient = useQueryClient();
  const {mutate, isLoading, mutateAsync} = useMutation(addCorrogoOrdersToShipment, {
    onSettled: async () => await queryClient.invalidateQueries([ORDERS_QUERY_KEY])
  });
  return {
    addOrdersToShipment: mutate,
    addOrdersToShipmentAsync: mutateAsync,
    isAddingOrdersToShipment: isLoading
  };
};
