import {Configuration} from '@shipwell/backend-core-sdk';
import {
  VendorRelationshipsApi,
  VendorRelationshipsApiShipwellUiVendorRelationshipsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const vendorRelationshipsApi = new VendorRelationshipsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const getVendorRelationships = async (
  requestParams?: VendorRelationshipsApiShipwellUiVendorRelationshipsGetRequest
) => {
  return vendorRelationshipsApi.shipwellUiVendorRelationshipsGet(requestParams);
};
