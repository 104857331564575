import {IWorkflowAction} from './IWorkflowAction';
import {IWorkflowEvent} from './IWorkflowEvent';
import {IEventInfo} from './iEventInfo';

/**
 * In first glance, the objective of this function is just to fill
 * out informations that covers the envents that ends with 'End'.
 * Cause the actual structure of the events info grabbing is not
 * very good. And, the component should prabably be re-written.
 */
export function getStepsExtraEventInfos(events: IWorkflowEvent[], actions: IWorkflowAction[]) {
  const eventsInfos = actions.map<IEventInfo>((action) => {
    const stepEvents = events.filter((event) => event.step_id === action.step_id);
    return getStepExtraEventInfo(action.step_id, stepEvents);
  });

  return eventsInfos;
}

function getStepExtraEventInfo(step: string, stepEvents: IWorkflowEvent[]): IEventInfo {
  return verifyIfWarning(step, stepEvents) || verifyIfSuccess(step, stepEvents) || getUnknownEventInfo(step);
}

function verifyIfWarning(step: string, stepEvents: IWorkflowEvent[]) {
  const lastStepIsEnd = stepEvents.at(-1)?.workflow_execution_event_type === 'END';
  const stepBeforeLastStepIsWarning = stepEvents.at(-2)?.workflow_execution_event_type === 'WARNING';

  if (lastStepIsEnd && stepBeforeLastStepIsWarning) {
    return {
      step,
      type: 'warning',
      message: stepEvents.at(-2)?.message
    } as IEventInfo;
  }
}

function verifyIfSuccess(step: string, stepEvents: IWorkflowEvent[]) {
  const lastStepIsEnd = stepEvents.at(-1)?.workflow_execution_event_type === 'END';
  const stepBeforeLastStepIsInfo = stepEvents.at(-2)?.workflow_execution_event_type === 'INFO';

  if (lastStepIsEnd && stepBeforeLastStepIsInfo) {
    return {
      step,
      message: stepEvents.at(-2)?.message,
      type: 'success'
    } as IEventInfo;
  }
}

function getUnknownEventInfo(step: string) {
  return {
    step,
    type: 'unknown',
    message: 'unknown'
  } as IEventInfo;
}
