import {CompaniesApi, CompanyInternationalPreferences, Configuration} from '@shipwell/backend-core-sdk';
import {getAccessToken} from 'App/api/utils';

//these routes are exposed via backend-core, and use the backend-core base path.
const basePath = process.env.SHIPWELL_API_BASE_PATH;

function createCompaniesApi() {
  return new CompaniesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
const companiesApi = createCompaniesApi();
export const getCompanyInternationalPreferences = async (companyId: string) =>
  companiesApi.companiesCompanyIdInternationalPreferencesGet(companyId);

export const updateCompanyInternationalPreferences = async (
  companyId: string,
  companyInternationalPreferences: CompanyInternationalPreferences
) => companiesApi.companiesCompanyIdInternationalPreferencesPut(companyId, companyInternationalPreferences);
