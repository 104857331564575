import {TagStatus} from '@shipwell/corrogo-sdk';

export interface StatusCellProps {
  status: string;
}

export function StatusCell(props: StatusCellProps) {
  if (props.status === TagStatus.Active) return <>Active</>;

  if (props.status === TagStatus.Inactive) return <span className="text-sw-disabled-text">Inactive</span>;

  return <>Unknown</>;
}
