import {compose} from 'recompose';
import {useMemo} from 'react';
import {
  Card,
  CollapsibleCardContent,
  FormikTextInput,
  SvgIcon,
  FormikToggleSwitch,
  FormikSelect,
  Tooltip
} from '@shipwell/shipwell-ui';
import {CompanyModePreferences} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Field} from 'formik';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {ShipmentModeEnum, getShipmentModeText} from 'App/utils/globalsTyped';
import withFlags from 'App/utils/withFlags';

export type CarrierDeclaredValueAmountsProps = {
  modePreferences?: CompanyModePreferences | null;
};

const DeclaredValueExplanation =
  "If a declared value for a shipment in either the Total Declared Value Override field or the sum of the individual item values is not provided at the time of carrier assignment, or tendering, the default shipment values (shown below by mode) will be used to compare with the carrier's insurance coverage limits.";

const CarrierDeclaredValueAmounts = ({modePreferences}: CarrierDeclaredValueAmountsProps) => {
  const modeValueAmounts = useMemo(() => {
    if (!modePreferences) {
      return [];
    }
    // the API for `modePreferences` is a flat representation of all modes and their default declared values and statuses.
    // for sanity's sake we'll convert it to an array of objects that are easier to work with.
    return [
      {
        mode: ShipmentModeEnum.FTL,
        defaultDeclaredValueInputFieldName: 'CarrierInsuranceDefaultValues.ftl_default_declared_value',
        defaultDeclaredValueSwitchName: 'CarrierInsuranceDefaultValues.is_ftl_default_declared_value_enabled',
        defaultDeclaredValueCurrencyFieldName: 'CarrierInsuranceDefaultValues.ftl_default_declared_value_currency',
        defaultDeclaredAmount: modePreferences?.ftl_default_declared_value,
        defaultDeclaredCurrency: modePreferences?.ftl_default_declared_value_currency,
        isEnabled: modePreferences?.is_ftl_default_declared_value_enabled
      },
      {
        mode: ShipmentModeEnum.LTL,
        defaultDeclaredValueInputFieldName: 'CarrierInsuranceDefaultValues.ltl_default_declared_value',
        defaultDeclaredValueSwitchName: 'CarrierInsuranceDefaultValues.is_ltl_default_declared_value_enabled',
        defaultDeclaredValueCurrencyFieldName: 'CarrierInsuranceDefaultValues.ltl_default_declared_value_currency',
        defaultDeclaredAmount: modePreferences?.ltl_default_declared_value,
        defaultDeclaredCurrency: modePreferences?.ltl_default_declared_value_currency,
        isEnabled: modePreferences?.is_ltl_default_declared_value_enabled
      },
      {
        mode: ShipmentModeEnum.RAIL,
        defaultDeclaredValueInputFieldName: 'CarrierInsuranceDefaultValues.rail_default_declared_value',
        defaultDeclaredValueSwitchName: 'CarrierInsuranceDefaultValues.is_rail_default_declared_value_enabled',
        defaultDeclaredValueCurrencyFieldName: 'CarrierInsuranceDefaultValues.rail_default_declared_value_currency',
        defaultDeclaredAmount: modePreferences?.rail_default_declared_value,
        defaultDeclaredCurrency: modePreferences?.rail_default_declared_value_currency,
        isEnabled: modePreferences?.is_rail_default_declared_value_enabled
      },
      {
        mode: ShipmentModeEnum.VLTL,
        defaultDeclaredValueInputFieldName: 'CarrierInsuranceDefaultValues.vltl_default_declared_value',
        defaultDeclaredValueSwitchName: 'CarrierInsuranceDefaultValues.is_vltl_default_declared_value_enabled',
        defaultDeclaredValueCurrencyFieldName: 'CarrierInsuranceDefaultValues.vltl_default_declared_value_currency',
        defaultDeclaredAmount: modePreferences?.vltl_default_declared_value,
        defaultDeclaredCurrency: modePreferences?.vltl_default_declared_value_currency,
        isEnabled: modePreferences?.is_vltl_default_declared_value_enabled
      },
      {
        mode: ShipmentModeEnum.DRAYAGE,
        defaultDeclaredValueInputFieldName: 'CarrierInsuranceDefaultValues.drayage_default_declared_value',
        defaultDeclaredValueSwitchName: 'CarrierInsuranceDefaultValues.is_drayage_default_declared_value_enabled',
        defaultDeclaredValueCurrencyFieldName: 'CarrierInsuranceDefaultValues.drayage_default_declared_value_currency',
        defaultDeclaredAmount: modePreferences?.drayage_default_declared_value,
        defaultDeclaredCurrency: modePreferences?.drayage_default_declared_value_currency,
        isEnabled: modePreferences?.is_drayage_default_declared_value_enabled
      }
    ];
  }, [modePreferences]);

  return (
    <Card isCollapsible draggableProvided={false} title="Carrier Insurance Coverage Rules">
      <CollapsibleCardContent>
        <div role="table" className="table w-full border-spacing-y-2">
          <div className="table-header-group">
            <div role="row" className="table-row">
              <div className="table-cell text-xs" role="columnheader">
                Status
              </div>
              <div className="table-cell text-xs" role="columnheader">
                Mode
              </div>
              <div className="table-cell text-xs" role="columnheader">
                <span className="inline-flex">
                  Default Declared Value
                  <div className="ml-1">
                    <Tooltip tooltipContent={<div className="w-60">{DeclaredValueExplanation}</div>}>
                      <SvgIcon name="InfoOutlined" width="16" height="16" />
                    </Tooltip>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="table-row-group">
            {modeValueAmounts.map((modeValueAmount) => (
              <div role="row" className="table-row border-b-1 border-b-sw-divider" key={modeValueAmount.mode}>
                <div role="cell" className="table-cell">
                  <Field
                    component={FormikToggleSwitch}
                    checkedLabel="ON"
                    uncheckedLabel="OFF"
                    name={modeValueAmount.defaultDeclaredValueSwitchName}
                  />
                </div>
                <span role="cell" className="table-cell">
                  {getShipmentModeText(modeValueAmount.mode)}
                </span>
                <div className="table-cell" role="cell">
                  <div className="inline-flex">
                    <Field
                      disabled={!modeValueAmount.isEnabled}
                      label="currency"
                      clearable={false}
                      required
                      controlClass
                      simpleValue
                      name={modeValueAmount.defaultDeclaredValueCurrencyFieldName}
                      component={FormikSelect}
                      options={[{label: 'USD', value: 'USD'}]}
                    />
                    <Field
                      disabled={!modeValueAmount.isEnabled}
                      label="Default Declared Value"
                      type="number"
                      component={FormikTextInput}
                      name={modeValueAmount.defaultDeclaredValueInputFieldName}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleCardContent>
    </Card>
  );
};

const CarrierDeclaredValueAmountsComposed = compose<CarrierDeclaredValueAmountsProps, CarrierDeclaredValueAmountsProps>(
  withFlags('fiCarrierComplianceInsurance'),
  withConditionalFallback(
    ({fiCarrierComplianceInsurance}: {fiCarrierComplianceInsurance?: boolean}) => !fiCarrierComplianceInsurance,
    null
  )
)(CarrierDeclaredValueAmounts);

export default CarrierDeclaredValueAmountsComposed;
