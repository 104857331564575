import {object, string} from 'yup';
import {AddressSchema} from 'App/utils/yupHelpersTyped';

export const CREATE_SUPPLIER_FORM_SCHEMA = object().shape({
  name: string().required('Supplier name is required.'),
  mailing_address: AddressSchema.test(
    'mailing_address',
    'A valid mailing address is required.',
    (value) => !!value?.address_1
  ),
  billing_address: AddressSchema.test(
    'billing_address',
    'A valid billing address is required.',
    (value) => !!value?.address_1
  ),
  billing_email: string().nullable().email('A valid email is required.'),
  notes: string().nullable()
});
