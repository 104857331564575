import {Field, useFormikContext} from 'formik';
import {FormikTextInput, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import useResponsiveModesAndEquipmentTypes from 'App/utils/useResponsiveModesAndEquipmentTypes';

export const RoutingGuideDefaultFields = () => {
  const {values, setFieldValue} = useFormikContext();
  const {equipmentTypesOptions, modesBlackList} = useResponsiveModesAndEquipmentTypes({
    equipmentTypesFieldName: 'routingGuides.equipment_types',
    modesFieldName: 'routingGuides.modes',
    values,
    setFieldValue
  });

  return (
    <div className="field-grid">
      <div className="grid-item-12">
        <ModesField name="routingGuides.modes" optionsBlackList={modesBlackList} />
      </div>
      <div className="grid-item-12">
        <EquipmentTypesField name="routingGuides.equipment_types" options={equipmentTypesOptions} />
      </div>
      <div className="grid-item-1-11">
        <Field name="routingGuides.email" label="Email" component={FormikTextInput} />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent="This email is used to notify someone when certain events occur with Routing Guides not associated with shipments or actions with a direct contact."
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
    </div>
  );
};

/**
 * Routing Guide Policy Fields
 * @param {*} props
 */
const RoutingGuideFields = () => {
  const {values, setFieldValue} = useFormikContext();
  const {equipmentTypesOptions, modesBlackList} = useResponsiveModesAndEquipmentTypes({
    equipmentTypesFieldName: 'equipment_types',
    modesFieldName: 'modes',
    values,
    setFieldValue
  });

  return (
    <>
      <div className="field-grid">
        <div className="grid-item-1-2">
          <Field required label="Routing Guide Name" name="name" component={FormikTextInput} />
        </div>
        <div className="grid-item-1-2">
          <Field
            searchAddressBook
            searchMaps
            searchStateProvince
            searchCountry
            searchThreeDigitPostalCode
            threeDigitPostalCodeOptionalValues={[]}
            label="Origins"
            name="origins"
            allowCreate
            component={MultiAddressSearch}
            ignoreFieldWarnings={['postal_code']}
          />
        </div>
        <div className="grid-item-1-2">
          <Field
            searchAddressBook
            searchMaps
            searchStateProvince
            searchCountry
            searchThreeDigitPostalCode
            threeDigitPostalCodeOptionalValues={[]}
            label="Destinations"
            name="destinations"
            allowCreate
            component={MultiAddressSearch}
            ignoreFieldWarnings={['postal_code']}
          />
        </div>
        <div className="grid-item-1-2">
          <ModesField name="modes" optionsBlackList={modesBlackList} required />
        </div>
        <div className="grid-item-1-2">
          <EquipmentTypesField name="equipment_types" options={equipmentTypesOptions} required />
        </div>
      </div>
    </>
  );
};

export default RoutingGuideFields;
