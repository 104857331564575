import {Formik, Form, Field} from 'formik';
import set from 'lodash/set';
import {object, string, date} from 'yup';
import type {InferType} from 'yup';
import moment from 'moment-timezone';
import {DrayageBooking, RunningStop} from '@shipwell/corrogo-sdk';
import {FormikTextInput, Title, FormikDateTimePicker} from '@shipwell/shipwell-ui';
import {stopReferencesQualifier} from 'App/containers/shipments/utils/constants';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useDrayageLeg, useCreateOrUpdateLeg} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {DefaultStopFormValues, StopTypeId} from 'App/containers/shipments/components/StopsFields/constants';
import {PickupStopType} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';

function DrayageLegStopsForm({
  legId,
  sequenceNumber,
  onClose
}: {
  legId: string;
  sequenceNumber: number;
  onClose: () => void;
}) {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {getStopType}
  } = drayageLegQuery;
  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;
  const isContainerPickupStop = stopType.id === StopTypeId.ContainerPickup;
  const isContainerReturnStop = stopType.id === StopTypeId.ContainerReturn;
  const isContainerStop = isContainerPickupStop || isContainerReturnStop;

  return isContainerStop ? (
    <DrayageLegContainerStopForm legId={legId} sequenceNumber={sequenceNumber} onClose={onClose} />
  ) : (
    <DrayageLegPickupDeliveryStopForm legId={legId} sequenceNumber={sequenceNumber} onClose={onClose} />
  );
}

export default DrayageLegStopsForm;

const legStopValidationSchema = object({
  pickup_number: object({
    value: string().nullable(),
    qualifier: string().default('PICKUP_NUMBER')
  })
    .notRequired()
    .default(undefined),
  appointment_begin: string().nullable().notRequired(),
  plan_window_ready: string().nullable().notRequired(),
  actual_arrival_time: string().nullable().notRequired(),
  actual_departure_time: string().nullable().notRequired()
});

type LegStopValues = InferType<typeof legStopValidationSchema>;

const DrayageLegPickupDeliveryStopForm = ({
  legId,
  sequenceNumber,
  onClose
}: {
  legId: string;
  sequenceNumber: number;
  onClose: () => void;
}) => {
  const drayageLegQuery = useDrayageLeg(legId);
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DrayageBooking, unknown, {data: RunningStop}>();

  const {updateDrayageLegStopsDetailMutation} = useCreateOrUpdateLeg(
    legId,
    createOptimisticUpdateHandlers([DRAYAGE_QUERY_KEY, legId], {
      errorHandler: (error) => {
        const message = (error as {message: string}).message;
        console.error('Error updating stop details information', message);
      },
      mergeData: (leg, variables) => {
        if (!leg || !variables) {
          return leg;
        }
        const {data} = variables;
        return {
          ...leg,
          stops: leg.stops.map((stop) => (stop.sequence_number === data.sequence_number ? data : stop))
        };
      }
    })
  );

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsFormValues, getStopType}
  } = drayageLegQuery;
  const stopTypeFormValues =
    stopsFormValues?.find((stopFormValues) => stopFormValues.sequenceNumber === sequenceNumber) ||
    DefaultStopFormValues;
  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;
  const isPickup = stopType.id === StopTypeId.Pickup;

  const handleSubmit = (values: LegStopValues) => {
    if (!values) {
      return;
    }
    const currentStop = drayageLegQuery.data?.stops?.find((stop) => stop.sequence_number === sequenceNumber);
    if (!currentStop) {
      return;
    }
    const {appointment_begin, plan_window_ready, actual_arrival_time, actual_departure_time, pickup_number} = values;

    // Filter out the pickup number reference so we don't save multiple
    const filteredPickupReferences =
      currentStop?.references?.filter((reference) => reference.qualifier !== stopReferencesQualifier.PICKUP_NUMBER) ||
      [];

    const pickupReferences = pickup_number?.value
      ? [...filteredPickupReferences, pickup_number]
      : filteredPickupReferences;
    const references = isPickup ? pickupReferences : currentStop?.references;

    const data = {
      ...currentStop,
      appointment: {
        begin: appointment_begin
      },
      plan_window: {
        ready: plan_window_ready
      },
      actual_arrival_time,
      actual_departure_time,
      references
    };

    updateDrayageLegStopsDetailMutation.mutate({legId, sequenceId: sequenceNumber, data});

    onClose();
  };

  return (
    <Formik initialValues={stopTypeFormValues} validationSchema={legStopValidationSchema} onSubmit={handleSubmit}>
      <Form>
        {isPickup ? (
          <div className="mb-4 grid grid-cols-2 gap-4">
            <Field name="pickup_number.value" label="Pickup #" component={FormikTextInput} />
          </div>
        ) : null}

        <Title className="pb-2" variant="sectionTitle">
          Stop {sequenceNumber} Dates
        </Title>

        <div className="mb-4 grid grid-cols-2 gap-4">
          <Field name="appointment_begin" label="Appointment Time" component={FormikDateTimePicker} />
        </div>

        <div className="mb-4 grid grid-cols-2 gap-4">
          <Field
            name="plan_window_ready"
            label={`${isPickup ? 'Pickup' : 'Delivery'} Date`}
            component={FormikDateTimePicker}
          />
        </div>

        <div className="mb-10 grid grid-cols-2 gap-4">
          <Field name="actual_arrival_time" label="Actual Arrival" component={FormikDateTimePicker} />
          <Field name="actual_departure_time" label="Completed At" component={FormikDateTimePicker} />
        </div>
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
};

const legContainerStopValidationSchema = object({
  gateDate: date().nullable()
});

type LegContainerStopValues = InferType<typeof legContainerStopValidationSchema>;

function DrayageLegContainerStopForm({
  legId,
  sequenceNumber,
  onClose
}: {
  legId: string;
  sequenceNumber: number;
  onClose: () => void;
}) {
  const drayageLegQuery = useDrayageLeg(legId);

  const {updateLegSummaryMutation} = useCreateOrUpdateLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {getStopType}
  } = drayageLegQuery;

  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;
  const isContainerPickupStop = stopType.id === StopTypeId.ContainerPickup;
  const isContainerReturnStop = stopType.id === StopTypeId.ContainerReturn;
  const gateString = isContainerPickupStop
    ? drayageLegQuery.data?.out_gate?.processing?.end
    : isContainerReturnStop
    ? drayageLegQuery.data?.in_gate?.processing?.end
    : undefined;

  const gateDate = gateString ? moment.tz(gateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSZ').toDate() : undefined;

  const handleSubmit = (values: LegContainerStopValues) => {
    const prop = isContainerPickupStop ? 'out_gate' : isContainerReturnStop ? 'in_gate' : undefined;

    if (!prop) {
      return;
    }

    const currentLeg = {...drayageLegQuery.data};
    const data = set(currentLeg, `${prop}.processing.end`, values.gateDate);

    updateLegSummaryMutation.mutate({legId, data});

    onClose();
  };

  return (
    <Formik initialValues={{gateDate}} validationSchema={legContainerStopValidationSchema} onSubmit={handleSubmit}>
      <Form>
        <div className="mb-4 grid h-16 grid-cols-2 gap-4">
          <Field
            name="gateDate"
            label={isContainerPickupStop ? 'Out Gate' : 'In Gate'}
            component={FormikDateTimePicker}
          />
        </div>
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
}
