import {
  ServiceProvidersApi,
  Configuration,
  CreateServiceProvider,
  UpdateServiceProvider,
  AddServiceProviderTag,
  CreateUpdateServiceProviderContact
} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const serviceProvidersApi = new ServiceProvidersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

type GetServiceProvidersParameters = Parameters<typeof ServiceProvidersApi.prototype.serviceProvidersGet>;
export type ServiceProviderOptions = {
  page?: GetServiceProvidersParameters[0];
  pageSize?: GetServiceProvidersParameters[1];
  ordering?: GetServiceProvidersParameters[2];
  q?: GetServiceProvidersParameters[3];
  tagsContains?: GetServiceProvidersParameters[4];
  contactsContain?: GetServiceProvidersParameters[5];
  providerName?: GetServiceProvidersParameters[6];
  providerNameContains?: GetServiceProvidersParameters[7];
  mailingAddressContains?: GetServiceProvidersParameters[8];
  billingAddressContains?: GetServiceProvidersParameters[9];
  capabilities?: GetServiceProvidersParameters[10];
  capabilitiesContains?: GetServiceProvidersParameters[11];
  options?: GetServiceProvidersParameters[12];
};
export function getServiceProviders(opts: ServiceProviderOptions) {
  const {
    page,
    pageSize,
    ordering,
    q,
    tagsContains,
    contactsContain,
    providerName,
    providerNameContains,
    mailingAddressContains,
    billingAddressContains,
    capabilities,
    capabilitiesContains,
    options
  } = opts;
  return serviceProvidersApi.serviceProvidersGet(
    page,
    pageSize,
    ordering,
    q,
    tagsContains,
    contactsContain,
    providerName,
    providerNameContains,
    mailingAddressContains,
    billingAddressContains,
    capabilities,
    capabilitiesContains,
    options
  );
}

export function getServiceProviderPromise(serviceProviderId: string) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdGet(serviceProviderId);
}

export function createServiceProvider(serviceProvider: CreateServiceProvider) {
  return serviceProvidersApi.serviceProvidersPost(serviceProvider);
}

export function addServiceProviderTag(serviceProviderId: string, addServiceProviderTag: AddServiceProviderTag) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdTagsPost(serviceProviderId, addServiceProviderTag);
}

export function deleteServiceProviderTag(serviceProviderId: string, tagId: string) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdTagsServiceProviderTagIdDelete(serviceProviderId, tagId);
}

export function updateServiceProvider(serviceProviderId: string, serviceProvider: UpdateServiceProvider) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdPut(serviceProviderId, serviceProvider);
}

export async function deleteServiceProvider(serviceProviderId: string) {
  const axiosResponse = await serviceProvidersApi.serviceProvidersServiceProviderIdDelete(serviceProviderId);
  return axiosResponse.data;
}

export function createServiceProviderContact(serviceProviderId: string, contact: CreateUpdateServiceProviderContact) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdContactsPost(serviceProviderId, contact);
}

export function updateServiceProviderContact(
  serviceProviderId: string,
  contactId: string,
  contact: CreateUpdateServiceProviderContact
) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdContactsServiceProviderContactIdPut(
    serviceProviderId,
    contactId,
    contact
  );
}

export function deleteServiceProviderContact(serviceProviderId: string, contactId: string) {
  return serviceProvidersApi.serviceProvidersServiceProviderIdContactsServiceProviderContactIdDelete(
    serviceProviderId,
    contactId
  );
}
