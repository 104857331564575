import {CalendarApi, EventApi, EventInput} from '@fullcalendar/core';
import isEqual from 'lodash/isEqual';
import {Facility, FacilityAppointmentType, LoadType} from '@shipwell/tempus-sdk';
import moment from 'moment';

import {FacilityAppointmentResourceId, DefaultAppointmentDurationMinutes} from '../../constants';
import {AppointmentEntry, CalendarAppointmentEvent, ViewMode} from 'App/data-hooks/appointments/types';

import {compareIds} from 'App/utils/cmp';
import {convertISO8601ToMinutes, yearDashMonthDashDate} from 'App/utils/dateTimeGlobalsTyped';
import {formatParts} from 'App/data-hooks/appointments/utils';

/**
 * A helper because FullCalendar doesn't even let you programmatically update an
 * event the the user can not edit.
 */
export function fcSetStaticEventDates(event: EventApi, start: Date, end: Date, allDay = false) {
  // See https://github.com/fullcalendar/fullcalendar/issues/6321 for explanation of 'editable' trick.
  event.setProp('editable', true);
  event.setDates(start, end, {allDay});
  event.setProp('editable', false);
}

export const MinimumCalendarAppointmentDurationMs = 15 * 60 * 1000;

export function appointmentToEvent(
  loadTypes: LoadType[],
  viewMode: ViewMode,
  appointment: AppointmentEntry
): CalendarAppointmentEvent | null {
  if (!appointment.start || !appointment.end) {
    console.error('ERROR bad appointment', {appointment});
  }

  const loadType = loadTypes.find(({id}) => id === appointment.matchedLoadTypeId);
  let start: Date | undefined;
  let end: Date | undefined;
  if (appointment.appointmentType === FacilityAppointmentType.FirstComeFirstServe) {
    if (appointment.checkedInAt) {
      start = new Date(appointment.checkedInAt.timestamp);
      if (appointment.rejectedAt) {
        end = new Date(appointment.rejectedAt.timestamp);
      } else if (appointment.checkedOutAt) {
        end = new Date(appointment.checkedOutAt.timestamp);
      } else if (loadType?.appointment_duration) {
        end = new Date(+start + 60000 * convertISO8601ToMinutes(loadType.appointment_duration));
      } else {
        end = new Date(+start + DefaultAppointmentDurationMinutes * 60 * 1000);
      }
    } else {
      start = new Date(appointment.start.timestamp);
      // intentionally duplicating start time because these are just filler
      end = new Date(appointment.start.timestamp);
    }
  } else {
    start = new Date(appointment.start.timestamp);
    end = new Date(appointment.end.timestamp);
  }

  if (+end - +start < MinimumCalendarAppointmentDurationMs) {
    end = new Date(+start + MinimumCalendarAppointmentDurationMs);
  }
  return {
    id: appointment.id,
    resourceId: appointment.dockId || FacilityAppointmentResourceId,
    start,
    end,
    allDay: appointment.allDay,
    /** Note that field is never rendered. */
    title:
      `TITLE: ${appointment.name ?? appointment.id}` +
      (appointment.carrierName ? ` - ${appointment.carrierName ?? ''}` : ''),
    classNames: ['sw-appointment-event'],
    startEditable: appointment.appointmentType === FacilityAppointmentType.ByAppointmentOnly,
    extendedProps: {appointment: {...appointment}, viewMode}
  };
}

/**
 * HACK HACK HACK
 * Because FullCalendar inappropriately subtracts timezone offsets from Date objects, we must add
 * those offsets before giving dates to full calendar to compensate.
 */
export function fixTZOffsetForFCForward(input: CalendarAppointmentEvent): CalendarAppointmentEvent {
  const startTZOffsetMS = moment.tz(input.extendedProps.appointment.start.timezone).utcOffset() * 60 * 1000;
  const endTZOffsetMS = moment.tz(input.extendedProps.appointment.end.timezone).utcOffset() * 60 * 1000;

  return {
    ...input,
    start: new Date((input.start as Date).getTime() + startTZOffsetMS),
    end: new Date((input.end as Date).getTime() + endTZOffsetMS)
  };
}

/**
 * HACK HACK HACK
 * Because FullCalendar inappropriately subtracts timezone offsets from Date objects, we must add
 * those offsets before giving dates to full calendar to compensate. Consequently, we must subtract
 * those offsets when Full Calendar gives us back date objects.
 */
export function fixTZOffsetForFCBackward(input: CalendarAppointmentEvent): CalendarAppointmentEvent {
  const startTZOffsetMS = moment.tz(input.extendedProps.appointment.start.timezone).utcOffset() * 60 * 1000;
  const endTZOffsetMS = moment.tz(input.extendedProps.appointment.end.timezone).utcOffset() * 60 * 1000;
  return {
    ...input,
    start: new Date((input.start as Date).getTime() - startTZOffsetMS),
    end: new Date((input.end as Date).getTime() - endTZOffsetMS)
  };
}

/**
 * HACK HACK HACK
 * Because FullCalendar inappropriately subtracts timezone offsets from Date objects, we must add
 * those offsets before giving dates to full calendar to compensate. Consequently, we must subtract
 * those offsets when Full Calendar gives us back date objects.
 */
export function fixTZOffsetOfClickedTime(facility: Facility | null | undefined, clickedTime: Date) {
  if (!facility) {
    return new Date(clickedTime);
  }
  const tZOffsetMS = moment.tz(facility.address.timezone).utcOffset() * 60 * 1000;
  return new Date(clickedTime.getTime() - tZOffsetMS);
}

/**
 * HACK HACK HACK
 * Because FullCalendar inappropriately subtracts timezone offsets from Date objects, we must add
 * those offsets before giving dates to full calendar to compensate.
 */
export function fixTZOffsetOfInputTime(facility: Facility | null | undefined, clickedTime: Date) {
  if (!facility) {
    return new Date(clickedTime);
  }
  const tZOffsetMS = moment.tz(facility.address.timezone).utcOffset() * 60 * 1000;
  return new Date(clickedTime.getTime() + tZOffsetMS);
}

export function fAppointmentPartitionKey(viewMode: ViewMode): (a: AppointmentEntry) => string {
  return viewMode === ViewMode.Week ? (a) => yearDashMonthDashDate(new Date(a.start.timestamp)) : (a) => a.dockId;
}

export function fCalendarAppointmentPartitionKey(
  viewMode: ViewMode
): (a: CalendarAppointmentEvent | EventInput) => string {
  return viewMode === ViewMode.Week
    ? (event) =>
        formatParts(
          new Date((event?.extendedProps?.appointment as AppointmentEntry)?.start?.timestamp ?? event.start),
          (event?.extendedProps?.appointment as AppointmentEntry)?.start?.timezone
        ).date
    : (event) => event.resourceId ?? '';
}

const TraceUpdateEvents = false;

/**
 * Accepts the calendar API, and the `newEvents`, pre-sorted by `.id`, and
 * updates the calendar.
 */
export function updateEvents(api: CalendarApi, newEvents: CalendarAppointmentEvent[]) {
  const oldEvents = api.getEvents();
  oldEvents.sort(compareIds);
  const nOld = oldEvents.length,
    nNew = newEvents.length;
  let iOld = 0,
    iNew = 0;
  const counts = {added: 0, removed: 0, updated: 0, meta: 0};
  while (iOld < nOld && iNew < nNew) {
    const left = oldEvents[iOld];
    const right = newEvents[iNew];

    if (left.id < right.id) {
      // this old event was removed
      left.remove();
      iOld++;
      counts.removed++;
      continue;
    }
    if (left.id > right.id) {
      // we've got a new event
      api.addEvent(right);
      iNew++;
      counts.added++;
      continue;
    }
    iOld++;
    iNew++;
    if (!right.extendedProps?.appointment) {
      updateMetaEvent(api, left, right);
      counts.meta++;
      continue;
    }
    if (!isEqual(left, right)) {
      // This event has been updated
      updateEventFromAppointment(api, left, right);
      counts.updated++;
    }
  }
  while (iNew < nNew) {
    const right = newEvents[iNew++];
    api.addEvent(right);
    counts.added++;
  }
  while (iOld < nOld) {
    oldEvents[iOld++].remove();
    counts.removed++;
  }
  if (TraceUpdateEvents) {
    console.log('TRACE updateEvents:119', {...counts, nOld, nNew});
  }
}

function updateMetaEvent(api: CalendarApi, oldEvent: EventApi, newEvent: CalendarAppointmentEvent) {
  const [start, end] = [newEvent.start, newEvent.end].map((date) =>
    date instanceof Date ? date : new Date(date as string)
  );
  fcSetStaticEventDates(oldEvent, start, end, newEvent.allDay);
}

function updateEventFromAppointment(api: CalendarApi, oldEvent: EventApi, newEvent: CalendarAppointmentEvent) {
  // For now we'll make it really easy
  oldEvent.remove();
  if (newEvent) {
    api.addEvent(newEvent);
  }
}
