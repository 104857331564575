import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {LoadboardStatus} from '@shipwell/tabula-sdk';
import {getActiveLoadboards} from 'App/api/loadboards';
import {LOADBOARDS_KEY} from 'App/data-hooks/queryKeys';

const useGetActiveLoadboards = (options?: UseQueryOptions<Array<LoadboardStatus>>) => {
  const response = useQuery({
    queryKey: [LOADBOARDS_KEY] as QueryKey,
    queryFn: async () => {
      const response = await getActiveLoadboards();
      return response.data.loadboards || [];
    },
    ...options
  });

  return response;
};

export default useGetActiveLoadboards;
