import {Field} from 'formik';
import {FormikSelect, FormikDateTimePicker} from '@shipwell/shipwell-ui';
import {getDaysOfWeekOptions} from 'App/containers/userCompany/fuelSurcharges/validation/';

const FuelSurchargeSettingsFormFields = () => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
      <div className="col-span-1 md:col-span-4">
        <Field
          required
          label="Status"
          name="status"
          simpleValue
          clearable={false}
          component={FormikSelect}
          options={[
            {value: 'ACTIVE', label: 'Active'},
            {value: 'INACTIVE', label: 'Inactive'}
          ]}
        />
      </div>
      <div className="col-span-1 md:col-span-2">
        <Field
          label="Start Date"
          name="start_date"
          showTimeSelect={false}
          popperPlacement="top-start"
          component={FormikDateTimePicker}
        />
      </div>
      <div className="col-span-1 md:col-span-2">
        <Field
          label="End Date"
          name="end_date"
          showTimeSelect={false}
          popperPlacement="top-start"
          component={FormikDateTimePicker}
        />
      </div>
      <div className="col-span-1 md:col-span-2">
        <Field
          required
          label="Rate Update Frequency"
          name="rates_update_frequency"
          simpleValue
          clearable={false}
          component={FormikSelect}
          options={[
            {value: 'WEEKLY', label: 'Weekly'},
            {value: 'MONTHLY', label: 'Monthly'}
          ]}
        />
      </div>
      <Field
        label="Day Rates Are Updated"
        name="rates_updated_day_of_week"
        required
        component={FormikSelect}
        simpleValue
        options={getDaysOfWeekOptions()}
      />
      <div className="col-span-1 text-xs italic text-sw-form-helper-text md:col-span-4">
        *The U.S. Energy Information Administration (EIA) updates diesel fuel prices on a weekly basis. The updates are
        released every Monday around 5:00 p.m. Eastern Time, reflecting the average retail prices for diesel fuel across
        different regions in the United States for the previous week. The system will update the fuel tables at 10:00
        p.m. Eastern Time on the date specified.
      </div>
    </div>
  );
};

export default FuelSurchargeSettingsFormFields;
