import {Field} from 'formik';
import {FormikSelect, Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {generateTimeIntervalOptions} from 'App/data-hooks/facilities/facilityUtils';
import {hourOptions} from 'App/utils/dateTimeGlobalsTyped';

const leadTimeOptions = generateTimeIntervalOptions({totalHours: 24, interval: 60});

const AppointmentRulesFields = () => {
  return (
    <Card draggableProvided={null} title="Appointment Rules" isCollapsible>
      <CollapsibleCardContent>
        <div className="grid gap-y-2">
          <div className="grid grid-cols-2 gap-x-4"></div>
          <div className="grid grid-cols-4 gap-x-4">
            <Field
              simpleValue
              required
              label="Appointment Lead Time"
              component={FormikSelect}
              name="appointmentRules.appointment_lead_time_duration"
              options={leadTimeOptions}
            />

            <Field
              simpleValue
              required
              label="Late Appointment Threshold"
              component={FormikSelect}
              name="appointmentRules.late_appointment_threshold_duration"
              options={generateTimeIntervalOptions({totalHours: 2, interval: 5})}
            />

            <Field
              simpleValue
              required
              label="First Appointment Start Time"
              component={FormikSelect}
              name="appointmentRules.first_appointment_start_time"
              options={hourOptions}
            />

            <Field
              simpleValue
              required
              label="Last Appointment End Time"
              component={FormikSelect}
              name="appointmentRules.last_appointment_end_time"
              options={hourOptions}
            />
          </div>
        </div>
      </CollapsibleCardContent>
    </Card>
  );
};

export default AppointmentRulesFields;
