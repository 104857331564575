export const FreightClassWarning = () => (
  <div className="grid-item-1-4">
    <div className="freightclass-warning">
      <span>
        <strong className="error-text">Disclaimer:</strong> Your freight class selection is more than two classes away
        from the density based{' '}
        <strong>
          <em>estimate</em>
        </strong>
        . The freight class estimate is only intended to be guidance, and the carrier may determine a freight class
        based on NMFC guidelines, which can affect your rate. If you are uncertain about the freight class you should
        choose, contact <a href="mailto:support@shipwell.com">support@shipwell.com</a> for assistance.
      </span>
    </div>
  </div>
);
