export const fedexServiceOptions = [
  // should be consistent with FedexServiceCodes Enum on BE
  // https://github.com/shipwell/fedex-client/blob/master/fedex_client/common/enums.py#L18
  {id: 'FEDEX_1_DAY_FREIGHT', name: 'FedEx 1Day® Freight'},
  {id: 'FEDEX_2_DAY', name: 'FedEx 2Day®'},
  {id: 'FEDEX_2_DAY_AM', name: 'FedEx 2Day® A.M.'},
  {id: 'FEDEX_2_DAY_FREIGHT', name: 'FedEx 2Day® Freight'},
  {id: 'FEDEX_3_DAY_FREIGHT', name: 'FedEx 3Day® Freight'},
  {id: 'FEDEX_EXPRESS_SAVER', name: 'FedEx Express Saver®'},
  {id: 'FEDEX_FIRST_FREIGHT', name: 'FedEx First® Freight'},
  {id: 'FEDEX_GROUND', name: 'FedEx Ground®'},
  {id: 'FIRST_OVERNIGHT', name: 'FedEx First Overnight®'},
  // {id: 'GROUND_HOME_DELIVERY', name: 'FedEx Home Delivery®'},
  {id: 'INTERNATIONAL_ECONOMY', name: 'FedEx International Economy®'},
  {id: 'INTERNATIONAL_ECONOMY_FREIGHT', name: 'FedEx International Economy® Freight'},
  {id: 'INTERNATIONAL_FIRST', name: 'FedEx International First®'},
  {id: 'INTERNATIONAL_PRIORITY', name: 'FedEx International Priority®'},
  {id: 'INTERNATIONAL_PRIORITY_EXPRESS', name: 'FedEx International Priority Express®'},
  {id: 'INTERNATIONAL_PRIORITY_FREIGHT', name: 'FedEx International Priority® Freight'},
  {id: 'PRIORITY_OVERNIGHT', name: 'FedEx Priority Overnight®'},
  {id: 'SMART_POST', name: 'FedEx SmartPost®'},
  {id: 'STANDARD_OVERNIGHT', name: 'FedEx Standard Overnight'},
  {id: 'FEDEX_DISTANCE_DEFERRED', name: 'FedEx Distance Deferred®'},
  {id: 'FEDEX_NEXT_DAY_AFTERNOON', name: 'FedEx Next Day Afternoon®'},
  {id: 'FEDEX_NEXT_DAY_EARLY_MORNING', name: 'FedEx Next Day Early Morning®'},
  {id: 'FEDEX_NEXT_DAY_END_OF_DAY', name: 'FedEx Next Day End of Day®'},
  {id: 'FEDEX_NEXT_DAY_FREIGHT', name: 'FedEx Next Day® Freight'},
  {id: 'FEDEX_NEXT_DAY_MID_MORNING', name: 'FedEx Next Day Mid Morning®'},
  {id: 'SAME_DAY', name: 'FedEx Same Day®'},
  {id: 'SAME_DAY_CITY', name: 'FedEx Same Day® City'},
  {id: 'FEDEX_INTERNATIONAL_PRIORITY', name: 'FedEx International Priority®'},
  {id: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS', name: 'FedEx International Priority Express®'}
];

export const upsServiceOptions = [
  {id: 'GROUND', name: 'UPS Ground'},
  {id: 'UPS_STANDARD', name: 'UPS Standard'},
  {id: 'UPS_SAVER', name: 'UPS Saver'},
  {id: 'EXPRESS', name: 'UPS Express'},
  {id: 'EXPRESS_PLUS', name: 'UPS Express Plus'},
  {id: 'EXPEDITED', name: 'UPS Expedited'},
  {id: 'NEXT_DAY_AIR', name: 'UPS Next Day Air'},
  {id: 'NEXT_DAY_AIR_SAVER', name: 'UPS Next Day Air Saver'},
  {id: 'NEXT_DAY_AIR_EARLY_AM', name: 'UPS Next Day Air Early AM'},
  {id: 'SECOND_DAY_AIR', name: 'UPS Second Day Air'},
  {id: 'SECOND_DAY_AIR_AM', name: 'UPS Second Day Air AM'},
  {id: 'THREE_DAY_SELECT', name: 'UPS Three Day Select'}
];

export const uspsServiceOptions = [
  {id: 'FIRST', name: 'First Class'},
  {id: 'PRIORITY', name: 'Priority'},
  {id: 'EXPRESS', name: 'Express'},
  {id: 'PARCEL_SELECT', name: 'Parcel Select'},
  {id: 'LIBRARY MAIL', name: 'Library Mail'},
  {id: 'MEDIA_MAIL', name: 'Media Mail'},
  {id: 'FIRST_CLASS_MAIL_INTERNATIONAL', name: 'First Class Mail International'},
  {id: 'FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE', name: 'First Class Package International Service'},
  {id: 'PRIORITY_MAIL_INTERNATIONAL', name: 'Priority Mail International'},
  {id: 'EXPRESS_MAIL_INTERNATIONAL', name: 'Express Mail International'}
];

export const parcelPickupStatuses = [
  {
    label: 'Quoting',
    className: 'status-dispatched',
    dashboardFilter: 'quoting',
    parcel_pickup_status: 'NOT_SCHEDULED'
  },
  {
    label: 'Scheduled',
    className: 'status-appointment',
    dashboardFilter: 'scheduled',
    parcel_pickup_status: 'SCHEDULED'
  },
  {
    label: 'In Transit',
    className: 'status-intransit',
    dashboardFilter: 'in_transit',
    parcel_pickup_status: 'IN_TRANSIT'
  },
  {
    label: 'Delivered',
    className: 'status-delivered',
    dashboardFilter: 'delivered',
    parcel_pickup_status: 'DELIVERED'
  },
  {
    label: 'Delayed',
    className: 'status-delayed',
    dashboardFilter: 'delayed',
    parcel_pickup_status: 'DELAYED'
  },
  {
    label: 'Cancelled',
    className: 'status-cancelled',
    dashboardFilter: 'cancelled',
    parcel_pickup_status: 'CANCELLED'
  }
];

export const parcelPickupStatusMap = parcelPickupStatuses.reduce((statuses, status) => {
  const {parcel_pickup_status} = status;
  return {...statuses, [parcel_pickup_status]: status};
}, {});

export const getPickupValues = (shipment = {}) => {
  const pickupLocation = shipment?.stops?.[0]?.location || {};
  const values = {};

  if (shipment?.fedex_specific_options) {
    values.fedex_account = shipment.fedex_specific_options.account;
    values.carrier_code = shipment.fedex_specific_options.carrier_code;
  } else if (shipment?.ups_specific_options) {
    values.ups_account = shipment.ups_specific_options.account;
  }

  if (pickupLocation.address) {
    values.address = pickupLocation.address;
  }

  if (pickupLocation.point_of_contacts?.[0]) {
    const {first_name, last_name, email, phone_number} = pickupLocation.point_of_contacts[0];

    values.point_of_contact = {
      first_name,
      last_name,
      email,
      phone_number
    };
  }

  return values;
};

export const getProviderCode = (shipment = {}) => {
  if (shipment?.fedex_specific_options) {
    return 'FEDEX';
  }
  if (shipment?.ups_specific_options) {
    return 'UPS';
  }
  if (shipment?.usps_specific_options) {
    return 'USPS';
  }
  return '';
};

export const getParcelPackageType = (lineItems = []) => {
  let packageType = lineItems[0]?.provider_specific_packaging || null;

  // if there are multiple line items
  if (lineItems?.length > 1) {
    // check whether they all have the same package type as the first one
    for (let i = 1; i < lineItems.length; i++) {
      if (lineItems[i].provider_specific_packaging !== packageType) {
        // if package types don't match, set package type to custom string
        packageType = 'MIXED_TYPES';
        // and stop checking for match
        break;
      }
    }
  }
  return packageType;
};
