import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {getShipment} from 'App/api/shipment/typed';
import {CORE_SHIPMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';

export default function useShipment(
  shipmentId?: string,
  options?: Omit<
    UseQueryOptions<Shipment, unknown, Shipment, (string | undefined)[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
): {
  isLoading: boolean;
  isInitialLoading: boolean;
  shipment?: Shipment;
  error?: unknown;
  isFetching: boolean;
} {
  const {
    isLoading,
    data: shipment,
    isInitialLoading,
    error,
    isFetching
  } = useQuery(
    [CORE_SHIPMENT_QUERY_KEY, shipmentId],
    async () => {
      return await getShipment(shipmentId || '');
    },
    {
      enabled: Boolean(shipmentId),
      ...options
    }
  );

  return {isLoading, shipment, isInitialLoading, error, isFetching};
}
