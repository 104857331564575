import {
  REQUEST_POWER_UNITS_SUCCESS,
  REQUEST_POWER_UNIT_SUCCESS,
  CREATE_POWER_UNIT_SUCCESS,
  UPDATE_POWER_UNIT_SUCCESS,
  DELETE_POWER_UNIT_SUCCESS
} from '../actions';

/** Initial reducer state */
export const INITIAL_STATE = {
  powerUnits: [],
  powerUnitsById: {}
};

/**
 * Carrier power units reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_POWER_UNITS_SUCCESS:
      return {
        ...state,
        ...setPowerUnit(state, payload.results)
      };

    case REQUEST_POWER_UNIT_SUCCESS:
    case CREATE_POWER_UNIT_SUCCESS:
    case UPDATE_POWER_UNIT_SUCCESS:
      return {
        ...state,
        ...setPowerUnit(state, [payload])
      };

    case DELETE_POWER_UNIT_SUCCESS:
      return {
        ...state,
        ...removePowerUnits(state, payload)
      };

    default:
      return state;
  }
};

/**
 * Normalize and set power units trailers
 * @param {Object} state
 * @param {Array}  powerUnitsList
 */
function setPowerUnit(state, powerUnitsList = []) {
  const powerUnits = new Set(state.powerUnits);
  const powerUnitsById = {...state.powerUnitsById};

  powerUnitsList.forEach((powerUnit) => {
    powerUnits.add(powerUnit.id);
    powerUnitsById[powerUnit.id] = powerUnit;
  });

  return {powerUnits: [...powerUnits], powerUnitsById};
}

/**
 * Remove carrier power units
 * @param {Object} state
 * @param {Number} powerUnitId
 */
function removePowerUnits(state, powerUnitId) {
  const powerUnits = new Set(state.powerUnits);
  const powerUnitsById = {...state.powerUnitsById};

  powerUnits.delete(powerUnitId);
  delete powerUnitsById[powerUnitId];

  return {powerUnits: [...powerUnits], powerUnitsById};
}
