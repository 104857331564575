import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Field, useFormikContext} from 'formik';
import isEqual from 'lodash/isEqual';
import {FormikSelect, FormikPhoneInput} from '@shipwell/shipwell-ui';
import usePrevious from 'App/utils/hooks/usePrevious';

/**
 * This is a diverging driver workflow for platNewDriverWorkflow flag users
 * only, and is only used by one company that has a compatible workflow. Do not
 * use for other companies as it will break other existing workflows.
 */

const EditDriver = ({lookupPhoneNumber, cellValidationStatus, driverOptions, carrierConfig}) => {
  const [canEditPhoneNumber, setCanEditPhoneNumber] = useState(!values?.driver?.full_name);
  const {setFieldValue, values, setTouched, errors, setErrors} = useFormikContext();
  const previousCarrierId = usePrevious(values?.vendor?.id);

  useEffect(() => {
    if (carrierConfig.driver?.phone_number && driverOptions?.length > 0) {
      const driverMatch = driverOptions.find(
        (d) => d.phone_number === carrierConfig.driver.phone_number && d.full_name === carrierConfig.driver.full_name
      );
      if (driverMatch) {
        setFieldValue('driver', driverMatch);
        setCanEditPhoneNumber(false);
      } else {
        setCanEditPhoneNumber(true);
      }
    }
  }, [carrierConfig.driver, driverOptions, setFieldValue]);

  useEffect(() => {
    if (!values?.vendor?.id) {
      setCanEditPhoneNumber(false);
      // if carrier changed or was just assigned, clear driver value and enable phone number field
    } else if (
      (previousCarrierId && !isEqual(values?.vendor?.id, previousCarrierId)) ||
      (!previousCarrierId && values?.vendor?.id && !values?.driver?.phone_number)
    ) {
      setFieldValue('driver', null);
      setCanEditPhoneNumber(true);
    }
  }, [previousCarrierId, values?.vendor?.id, values?.driver?.phone_number, setFieldValue]);

  return (
    <>
      <div className="mb-4 w-full">
        <Field
          component={FormikSelect}
          name="driver"
          label="Driver Full Name"
          error={errors?.driver?.full_name || null}
          clearable={Boolean(values.driver?.full_name)}
          disabled={!values.vendor}
          options={driverOptions}
          getOptionLabel={(option) => option.full_name}
          onChange={(option) => {
            if (!option) {
              setFieldValue('driver', null);
              setCanEditPhoneNumber(true);
            } else {
              setFieldValue('driver', option);
              setCanEditPhoneNumber(false);
            }
          }}
          allowCreate
          getNewOptionData={(inputValue) => ({
            full_name: inputValue,
            phone_number: null
          })}
          onCreateOption={(inputValue) => {
            const customOption = {
              full_name: inputValue,
              phone_number: null
            };
            setCanEditPhoneNumber(true);
            setFieldValue('driver', customOption);
          }}
        />
      </div>
      <div className="mb-4 w-full">
        <Field
          component={FormikPhoneInput}
          required={values.driver?.full_name}
          disabled={!canEditPhoneNumber}
          successMessage={!errors?.driver?.phone_number && cellValidationStatus ? cellValidationStatus : ''}
          name="driver.phone_number"
          label="Driver Cell Number"
          onChange={(e) => {
            setFieldValue('driver.phone_number', e.target.value);
            lookupPhoneNumber(e, {setErrors});
          }}
          onBlur={(e) => {
            setTouched({'driver.phone_number': true});
            lookupPhoneNumber(e, {setErrors});
          }}
        />
      </div>
    </>
  );
};

EditDriver.defaultProps = {
  lookupPhoneNumber: () => {},
  driverOptions: []
};

EditDriver.propTypes = {
  lookupPhoneNumber: PropTypes.func,
  cellValidationStatus: PropTypes.string,
  driverOptions: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string,
      phone_number: PropTypes.string
    })
  ),
  carrierConfig: PropTypes.shape({
    driver: PropTypes.shape({
      phone_number: PropTypes.string,
      full_name: PropTypes.string
    })
  })
};

export default EditDriver;
