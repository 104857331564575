export const SHIPMENTS_QUERY_KEY = 'shipments'; // corrogo shipments route
export const CORE_SHIPMENT_QUERY_KEY = 'shipment'; // backend-core shipment route
export const CORE_SHIPMENTS_QUERY_KEY = 'shipments'; // backend-core shipments route
export const SHIPMENT_STOPS_QUERY_KEY = 'shipment-stops';
/**
 * Query key for validating what types of load types, docks, dock rules, and facilities
 * can accommodate a shipment stop. This should be used in combination with other query criteria
 * like the facility id, shipment id, stop id
 * @example [SHIPMENT_STOP_MATCH_QUERY_KEY, {facilityId}, {shipmentId}, {stopId}]
 */
export const SHIPMENT_STOP_MATCH_QUERY_KEY = 'shipment-stop-match';
/**
 * Key used to get the appointment which belongs to a shipment stop. This should be used with
 * @example [SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY, {shipmentId}, {stopId}] returns Appointment
 */
export const SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY = 'shipment-stops-appointment';
export const LEGACY_SHIPMENTS_QUERY_KEY = 'legacyShipments'; // be-core shipments route
export const DRAYAGE_QUERY_KEY = 'drayage';
export const BROKERAGES_QUERY_KEY = 'brokerages';
export const ACCESSORIALS_QUERY_KEY = 'accessorials';
export const FUEL_SURCHARGE_QUERY_KEY = 'fuelSurcharge';
export const ACCESSORIAL_CHARGE_QUERY_KEY = 'accessorialCharge';
export const HAZMAT_CODES_QUERY_KEY = 'hazmatCodes';
export const TAGS_QUERY_KEY = 'tags';
export const ORDER_TAGS_QUERY_KEY = 'orderTags';
export const SERVICE_DOCUMENTS_QUERY_KEY = 'serviceDocuments';
export const SHIPMENT_DOCUMENTS_QUERY_KEY = 'shipmentDocuments';
export const SHIPMENT_MESSAGES_QUERY_KEY = 'shipmentMessages';
export const SHIPMENT_AUDITLOG_QUERY_KEY = 'shipmentAuditlog';
export const SHIPMENT_INTERNAL_NOTES_QUERY_KEY = 'shipmentsInternalNotes';
export const DRAYAGE_LEG_DOCUMENTS_QUERY_KEY = 'drayageLegDocuments';
export const EQUIPMENT_TYPES_QUERY_KEY = 'equipmentTypes';
export const MODES_QUERY_KEY = 'modes';
export const SERVICES_QUERY_KEY = 'services';
export const COMPANY_USER = 'companyUser';
export const COMPANY_USERS = 'companyUsers';
export const SHIPMENT_ASSEMBLY_QUERY_KEY = 'shipmentAssembly';
export const LOAD_PLAN_DETAILS_QUERY_KEY = 'loadPlanDetails';
export const LOAD_DETAILS_QUERY = 'loadDetails';
export const SHIPMENT_ASSEMBLIES_QUERY_KEY = 'shipmentAssemblies';
export const COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY = 'companyLoadOptimizationConfiguration';
export const ALERT_CONFIG_QUERY_KEY = 'alertConfig';
export const PURCHASE_ORDER_QUERY_KEY = 'purchaseOrder';
export const PURCHASE_ORDERS_QUERY_KEY = 'purchaseOrders';
export const SHIPMENT_DOCS_QUERY_KEY = 'shipmentDocuments';
export const VERSIONS_QUERY_KEY = 'versions';
export const WORKFLOW_DETAILS_QUERY_KEY = 'workflowDetails';
export const WORKFLOW_EXECUTION_QUERY_KEY = 'workflowExecutionInstance';
export const WORKFLOW_EXECUTIONS_LIST_QUERY_KEY = 'workflowExecutionsInstace';
export const WORKFLOW_TRIGGERING_EVENTS_KEY = 'workflowTriggeringEvents';
export const POLICY_QUERY_KEY = 'policy';
export const POLICIES_QUERY_KEY = 'policies';
export const WORKFLOW_FORM_VALUES_QUERY_KEY = 'workflowFormValues';
export const TRACKING_BREADCRUMBS_QUERY_KEY = 'trackingBreadcrumbs';
export const GROUPED_TIMELINE_EVENTS_QUERY_KEY = 'groupedTimelineEvents';
export const TIMELINE_FILTER_PREFERENCES = 'timelineFilterPreferences';
export const TIMELINE_EVENT_TEMPLATES = 'timelineEventTemplates';
export const TIMELINE_EVENT_DETAILS = 'timelineEventDetails';
export const SHIPMENT_LEGS_QUERY_KEY = 'legs';
export const FTL_RATING_QUERY_KEY = 'ftl-rating';
export const RATING_QUERY_KEY = 'rating';
export const CARRIER_CAPACITY_AVAILABILITY_QUERY_KEY = 'carrierCapacityAvailability';
export const CARRIER_QUERY_KEY = 'carrier';
export const FUEL_SURCHARGE_TABLE_QUERY_KEY = 'fuelSurchargeTable';
export const CARRIER_RELATIONSHIPS_QUERY_KEY = 'carrierRelationships';
export const CARRIER_EXCLUSION_RULES_QUERY_KEY = 'carrierExclusionRules';
export const CONTRACTS_QUERY_KEY = 'contracts';
export const TRANSPORTATION_ORDER_QUERY_KEY = 'transportationOrder';
export const TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY = 'transportationOrderDocuments';
export const PACKAGE_TYPES_QUERY_KEY = 'packageTypes';
export const CUSTOM_FIELDS_QUERY_KEY = 'customFields';
export const TRIMBLE_MAPS_GEOCODE = 'trimble-maps-geocode';
export const SHIPMENT_TIMELINE_SUMMARY = 'shipment-timeline-events-summary';
export const LEG_TIMELINE = 'leg-timeline-events';
export const COMPANY_INTEGRATION_UBER = 'companyIntegrationCarrierUber';
export const COMPANY_INTEGRATION_UPS = 'companyIntegrationCarrierUps';
export const COMPANY_INTEGRATION_USPS = 'companyIntegrationCarrierUsps';
export const COMPANY_INTEGRATION_FEDEX = 'companyIntegrationCarrierFedEx';
export const COMPANY_INTEGRATION_AMAZON = 'companyIntegrationCarrierAmazon';
export const COMPANY_INTEGRATION_CHROBINSON = 'companyIntegrationCarrierCHRobinson';
export const COMPANY_INTEGRATION_AMAZON_DISCONNECT = 'companyIntegrationCarrierAmazonDisconnect';
export const COMPANY_INTEGRATION_CHR_DISCONNECT = 'companyIntegrationCarrierAmazonDisconnect';
export const COMPANY_INTEGRATION_AMAZON_DELETE_ACCOUNT = 'companyIntegrationCarrierAmazonDeleteAccount';
export const COMPANY_INTEGRATION_CARRIERS = 'companyIntegrationCarrierAll';
export const COMPANY_INTEGRATION_TRIUMPH_PAY_APPROVED_DATE = 'companyIntegrationTriumphPayApprovedDate';
export const COMPANY_INTEGRATION_TRIUMPH_PAY_DETAILS = 'companyIntegrationTriumphPayDetails';
export const COMPANY_INTEGRATION_SMC3 = 'companyIntegrationCarrierSmc3';
export const LEG_TRACKING = 'leg-tracking';
export const SHIPMENT_TRACKING_SUMMARY = 'shipment-tracking-summary';
export const TRANSPORTATION_ORDER_SERVICES_QUERY_KEY = 'transportationOrderServices';
export const USER_TABLE_CONFIGURATION_QUERY_KEY = 'userTableConfig';
export const ALERTS_DASHBOARD_CONFIGS_QUERY_KEY = 'alertsDashboardConfigs';
export const ALERT_COUNT_QUERY_KEY = 'alertCount';
export const RFP_DETAILS_QUERY = 'rfpDetails';
export const RFP_ROWS_QUERY = 'rfpRows';
export const RFP_CSV_QUERY = 'rfpCsv';
export const RFP_IMPORT_RESULTS = 'rfpImportResults';
export const RFP_BIDS_ALPHA_QUERY = 'rfpBidsAlpha';
export const RFP_BIDS_QUERY = 'rfpBids';
export const RFP_OPT_QUERY = 'rfpOpts';
export const RFP_OPT_ROWS_QUERY = 'rfpOptRows';
export const RFP_ATTACHMENTS_QUERY_KEY = 'rfpAttachments';
export const RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY = 'rfpContractRoutingGuideProgress';
export const ALERT_EVENTS_QUERY_KEY = 'alertEvents';
export const ALERTS_DASHBOARD_SHIPMENT_QUERY_KEY = 'alertsDashboardShipment';
export const ALERT_WATCHED_SHIPMENTS_QUERY_KEY = 'alertWatchedShipments';
export const FULL_SHIPMENT_DETAILS_QUERY_KEY = 'fullShipmentDetails';
export const USER_ME_QUERY_KEY = 'user-me';
export const IMPERSONATION_QUERY_KEY = 'impersonation';
export const IMPERSONATION_TENANTS_QUERY_KEY = 'impersonation-tenants';
export const IMPERSONATION_TOKEN_QUERY_KEY = 'impersonation-token';
export const BILL_TO_QUERY_KEY = 'bill-to';
export const ALERTS_DASHBOARD_CARRIER_QUERY_KEY = 'alertsDashboardCarrier';
export const FEDEX_ACCOUNTS = 'registration-fedex';
export const FULL_CARRIER_DETAILS_QUERY_KEY = 'fullCarrierDetails';
export const CARRIER_TAGS_KEY = 'carrierTags';
export const CARRIER_ROLES_KEY = 'carrierRoles';
export const CARRIER_TAG_KEY = 'carrierTag';
export const SLIM_CARRIER_RELATIONSHIP_QUERY_KEY = 'slimCarrierRelationship';
export const CARRIER_RELATIONSHIP_DOCUMENTS_QUERY_KEY = 'carrierDocuments';
export const SHIPPING_LABELS_QUERY_KEY = 'shippingLabels';
export const COMPANY_LOGO_QUERY = 'companyLogo';
export const CARRIER_CONFIG_QUERY_KEY = 'carrierConfig';
export const SHIPPING_LABEL_QUERY_KEY = 'shipping-label';
export const EXTERNAL_TRACKING_QUERY_KEY = 'externalTracking';
export const SERVICE_PROVIDER_QUERY_KEY = 'serviceProvider';
export const SERVICE_PROVIDERS_QUERY_KEY = 'serviceProviders';
export const CHARGE_CATEGORY_QUERY_KEY = 'chargeCategory';
export const CHARGE_CODES_QUERY_KEY = 'chargeCodes';
export const RFP_BIDS_QUERY_KEY = 'rfpBids';
export const FACILITIES_QUERY_KEY = 'facilities';
export const FACILITIES_SHIPMENT_MATCHING = 'facilities-shipment-matching';
export const FACILITY_DOCKS_QUERY_KEY = 'facility-docks';
export const SHIPMENT_SMS_TRACKING = 'shipment-sms-tracking';
export const SHIPMENT_STATUS_QUERY_KEY = 'shipmentStatus';
/**
 * An appointment which exist in the cache that can be retrieved by ID
 * @example [APPOINTMENT_QUERY_KEY, {appointmentId}]
 */
export const APPOINTMENT_QUERY_KEY = 'appointment';
/**
 * Query key to be used for ALL Appointments in combination with other query criteria
 * @example [APPOINTMENTS_QUERY_KEY, {facilityId}, {startDate}, {endDate}]
 */
export const APPOINTMENTS_QUERY_KEY = 'appointments';
export const FACILITY_APPOINTMENTS_AND_ALL = 'facilityAppointmentsAndAll';
export const FACILITY_APPOINTMENT_AVAILABILITY_QUERY_KEY = 'facilityAvailability';
export const FACILITY_DOCK_APPOINTMENT_AVAILABILITY_QUERY_KEY = 'facilityDockAvailability';
export const FACILITY_ADDRESS_BOOK_QUERY_KEY = 'facilityAddressBookEntry';
export const FACILITIES_CONTACTS_QUERY_KEY = 'facilitiesContacts';
export const FACILITIES_HOURS_QUERY_KEY = 'facilitiesHours';
export const FACILITIES_HOLIDAYS_QUERY_KEY = 'facilitiesHolidays';
export const SHIPMENT_DOCUMENT_TYPES_QUERY_KEY = 'documentTypes';
export const DOCUMENT_TYPE_SHORT_NAMES = 'documentTypeShortNames';
export const SHIPMENTS_STAGES_QUERY_KEY = 'shipments-stages';
export const RESOURCE_STAGES_QUERY_KEY = 'resource-stages';
export const ADDRESS_BOOK_QUERY_KEY = 'address-book';
export const PRICING_INTEL_QUERY_KEY = 'pricingIntel';
export const FREIGHT_INVOICES_QUERY_KEY = 'freightInvoices';
export const PRICING_INTEL_DAT_QUERY_KEY = 'pricingIntelDATRates';
export const BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY = 'brokerShipperRelationship';
export const PRODUCTS_QUERY = 'products';
export const LOCATION_TYPES_QUERY_KEY = 'locationTypes';
export const APPOINTMENT_TYPES_QUERY_KEY = 'appointmentTypes';
export const INVOICE_RULE_CONFIGURATIONS_QUERY_KEY = 'invoiceRuleConfigurations';
export const FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY = 'freightInvoiceConfigurations';
export const USER_PREFERENCES_KEY = 'userPreferences';
export const COMPANY_PREFERENCES_KEY = 'companyPreferences';
export const USER_DASHBOARDS_QUERY_KEY = 'userDashboards';
export const HISTORICAL_PRICING_MARKET_COMPOSITE = 'historical-market';
export const HISTORICAL_PRICING_INTERNAL = 'historical-internal';
export const HISTORICAL_PRICING_PRIVATE = 'historical-private';
export const HISTORICAL_DAT_SPOT = 'historical-datSpot';
export const HISTORICAL_DAT_CONTRACT = 'historical-datContract';
export const PRICING_INTEL_SAVED_LANE = 'pricingSavedLane';
export const INBOX_MESSAGES = 'inboxMessages';
export const LOADBOARDS_KEY = 'loadboards';
export const LOADBOARDS_SETTINGS_KEY = 'loadboardsSettings';
export const LOADBOARDS_ACTIVE_KEY = 'loadboardsACtive';
export const LOADPOST_KEY = 'loadpost';
export const LOADPOST_REQUEST_KEY = 'loadpostRequest';
export const STANDARD_HOLIDAYS = 'standardHolidays';
export const DRIVERS_QUERY_KEY = 'drivers';
export const CARRIER_ASSIGNMENT_QUERY_KEY = 'carrierAssignment';
export const TENDERS_KEY = 'tenders';
export const INVOICE_DOCUMENTS_KEY = 'invoiceDocuments';
export const FACILITY_QUERY_KEY = 'facilities';
export const FACILITY_LOAD_TYPES = 'facilityLoadTypes';
export const FACILITY_DOCK_RULES = 'facilityDockRules';
export const SERVICE_LEVELS_KEY = 'serviceLevels';
export const RATE_TABLE_QUERY = 'rateTable';
export const CARRIER_RELATIONSHIP_BY_VENDOR_ID = 'slimCarrierRelationshipByVendorId';
export const CUSTOM_FIELDS_KEY = 'customFields';
export const EXTERNAL_AUTH_SERVICE = 'externalAuthService';
export const RATE_TABLE_ROWS_QUERY = 'rateTableRows';
export const FACILITY_DOCUMENTS = 'facilityDocuments';
export const COMPANY_PARCEL_CONFIG_KEY = 'companyParcelConfig';
export const CARRIER_TARIFFS_QUERY = 'carrierTariffs';
export const FAK_TABLE_DATA_QUERY = 'fakTableData';
export const SETTLEMENTS_DELIVERED_DASHBOARD_QUERY_KEY = 'settlementsDeliveredDashboard';
export const CARRIERS_PRICE_HISTORY_QUERY = 'carriersPriceHistory';
export const SUITE_APP_CONFIG_QUERY = 'suiteAppConfig';
export const SPOT_NEGOTIATION_QUOTE_DOCUMENT = 'spotNegotiationQuoteDocument';
export const CAPACITY_SEARCH_KEY = 'capacitySearch';
export const CAPACITY_SEARCH_DETAILS_KEY = 'capacitySearchDetails';
export const INTERNATIONAL_CONFIGS = 'internationalConfigs';
export const DYNAMIC_APPOINTMENT_AVAILABILITY = 'dynamicAppointmentAvailability';
export const SHIPMENTS_DASHBOARD_QUERY_KEY = 'shipmentDashboard';
export const PIECE_TYPES_QUERY_KEY = 'pieceTypes';
export const PRODUCT_CATEGORIES_QUERY_KEY = 'productCategories';
export const SUPPLIERS_QUERY_KEY = 'suppliers';
export const SPOT_NEGOTIATIONS_EMAIL_PREVIEW_KEY = 'spotNegotiationsEmailPreview';
export const SPOT_NEGOTIATIONS_KEY = 'spotNegotiations';
export const SHIPPER_RELATIONSHIP_CARRIERS = 'shipperRelationshipsCarriers';
export const CARRIER_ACCOUNTS_KEY = 'carrierAccounts';
export const SHIPMENT_SPOT_NEGOTIATIONS = 'shipmentSpotNegotiations';
export const SHIPMENT_INVOICES_KEY = 'shipmentInvoices';
export const CAPACITY_PROVIDERS_QUERY_KEY = 'capacityProviders';
export const TENDER_TO_CARRIER_KEY = 'tenderToCarrier';
export const CARRIER_WITH_DATA_METRICS = 'carrierWithDataMetrics';
export const PERMISSIONS = 'permissions';
export const AUCTION_STATS_KEY = 'auctionStats';
export const PRICING_FORECAST_QUERY_KEY = 'pricingForecast';
export const SALES_ORDERS_QUERY_KEY = 'salesOrders';
export const PURCHASE_ORDERS_CORROGO_QUERY_KEY = 'purchaseOrdersCorrogo';
export const PURCHASE_ORDER_DOCUMENTS_QUERY_KEY = 'purchaseOrderDocuments';
export const SALES_ORDER_DOCUMENTS_QUERY_KEY = 'salesOrderDocuments';
export const ORDERS_QUERY_KEY = 'orders';
export const RMIS_STATUS_QUERY_KEY = 'rmisStatus';
export const RMIS_COMPLIANCE_POLICY = 'rmisCompliancePolicy';
export const FMCSA_COMPLIANCE_POLICY = 'fmcsaCompliancePolicy';
export const SAFERWATCH_COMPLIANCE_POLICY = 'saferwatchCompliancePolicy';
export const QUICKBOOKS_QUERY_KEY = 'quickbooks';
export const CONTRACT_CHARGE_ITEMS = 'contractChargeItems';
export const SALES_ORDER_RELEASES_QUERY_KEY = 'salesOrderReleases';
export const CONTRACT_RATE_TABLE_LANES_KEY = 'contractRateTableLanes';
export const SHIPMENT_METADATA_ALERTS_KEY = 'shipmentMetadataAlerts';
export const SHIPMENT_STOP_LOCATIONS = 'shipmentStopLocations';
export const VENDOR_RELATIONSHIPS = 'vendorRelationships';
export const APPLICABLE_CONTRACTS_KEY = 'contractsApplicableForShipment';
export const COMPANY_UNIT_PREFERENCES = 'companyUnitPreferences';
export const SHIPMENTS_ROUND_TRIP_JOB = 'shipmentsRoundTripJob';
export const WORKFLOW_EXECUTIONS = 'workflowExecutions';
export const WORKFLOW_ACTION = 'action';
export const SHIPMENT_PICKUPS = 'shipmentPickups';
export const SHIPMENT_PICKUP = 'shipmentPickup';
export const FEDEX_PACKAGE_TYPES = 'fedexPackageTypes';
export const UPS_PACKAGE_TYPES = 'upsPackageTypes';
export const USPS_PACKAGE_TYPES = 'uspsPackageTypes';
export const CAPACITY_PROVIDER_ACCOUNTS = 'capacityProviderAccounts';
export const DELETE_CAPACITY_PROVIDER_ACCOUNT = 'deleteCapacityProviderAccount';
export const CAPACITY_PROVIDER_DYNAMIC_FIELDS = 'capacityProviderDynamicFields';
export const LIST_TEMPLATES_QUERY_KEY = 'listTemplates';
export const TEMPLATE_EDITOR_QUERY_KEY = 'templateEditor';
export const UPS_OAUTH_STATUS = 'upsOauthStatus';
export const SERVICE_LEVEL_AGREEMENT_KEY = 'serviceLevelAgreement';
