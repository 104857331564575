import {Company} from '@shipwell/backend-core-sdk';
import {useGetBrokerShipperRelationshipCarriers} from 'App/data-hooks/brokers/useGetShipperRelationshipsCarriers';
import {useBrokerShipperRelationship} from 'App/data-hooks/brokers/useBrokerShipperRelationship';

//use this hook to get a list of your company's LTL carriers
export const useGetLTLCarriers = (company: Company) => {
  //to get a list of LTL carriers we must first get the house account
  const {
    brokerShipperQuery: {data: customers, isInitialLoading: isLoadingBrokerShippers}
  } = useBrokerShipperRelationship();
  const houseCustomer = customers?.results?.find((result) => result.is_own_brokerage);
  //then we can use the house account id as shipperRelationshipId to get the list of LTL carriers
  const {
    brokerShipperRelationshipCarriers,
    getBrokerShipperRelationshipCarriersQuery: {isInitialLoading: isLoadingShipperCarriers}
  } = useGetBrokerShipperRelationshipCarriers({
    brokerageId: company.brokerage?.id || '',
    shipperRelationshipId: houseCustomer?.id || '',
    options: {enabled: !!houseCustomer?.id && !!company.brokerage?.id}
  });

  const isLoadingLTLCarriers = isLoadingBrokerShippers || isLoadingShipperCarriers;

  return {LTLCarriers: brokerShipperRelationshipCarriers, isLoadingLTLCarriers};
};
