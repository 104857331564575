import {Component} from 'react';
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {SvgIcon} from '@shipwell/shipwell-ui';
import FormGroup from '../../formGroup';
import AddressVerification from 'App/components/addressVerification';
import {loadGoogleMapsAPI} from 'App/utils/globals';
import './styles.scss';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';

/**
 * Address Search Field
 * @description Search from address book, google maps or both
 */
class AddressSearchField extends Component {
  static defaultProps = {
    multi: false,
    disabled: false,
    cache: false,
    searchMaps: true,
    searchAddressBook: true,
    placeholder: 'Address',
    icon: 'icon-Pointer'
  };

  constructor() {
    super();

    this.state = {
      isLoading: false,
      options: [],
      showManualAddressModal: false
    };
  }

  componentDidMount() {
    /** Need bound GMaps object */
    if (window.google) {
      this.initGoogleMaps();
    } else {
      loadGoogleMapsAPI(() => this.initGoogleMaps());
    }
  }

  initGoogleMaps() {
    this.autocomplete = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  /**
   * Get formatted address is selected address is from Googles predicted places
   * @param  {Object} place.value Selected place
   */
  async handlePlaceSelection(place) {
    if (place && place.value && place.value.place_id) {
      const placeDetails = await new Promise((resolve, reject) => {
        this.placeService.getDetails({placeId: place.value.place_id}, (response, status) => {
          if (status === 'OK' || status === 'ZERO_RESULTS') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      return {
        label: placeDetails.formatted_address,
        value: placeDetails
      };
    }
    return place;
  }

  /**
   * Request place predictions from Google Mapa
   * @param  {String} value Place search input
   * @return {Promise}
   */
  getPlacePredictions(value, types = ['geocode', 'establishment']) {
    return new Promise((resolve, reject) => {
      this.autocomplete.getPlacePredictions({input: value, types: types}, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }

  /**
   * Request AddressBook entried
   * @param  {String} value    Place search input
   * @param  {Number} pageSize Number of results
   * @return {Promise}
   */
  getAddressBookPromise(value, pageSize) {
    return getAddressBookAddresses({q: value, pageSize: pageSize});
  }

  /**
   * Search address from both Google and address book
   * @param  {String} value Address search value
   * @return {Promise}
   */
  async searchAddresses(value, callback) {
    const {searchMaps, searchAddressBook} = this.props;

    if (value) {
      try {
        this.setState({isLoading: true});
        const maxResults = 5;
        const response = {options: []};
        const [googleResults, addressBookResults] = await Promise.allSettled([
          searchMaps && this.getPlacePredictions(value),
          searchAddressBook && this.getAddressBookPromise(value, maxResults)
        ]);
        /** Format address book options */
        addressBookResults?.value?.data?.results?.length > 0 &&
          addressBookResults.value.data.results.forEach((company) => {
            response.options.push({
              value: {...company.address, ...company},
              label: company.address.formatted_address,
              name: company.company_name,
              icon: true
            });
          });

        /** Fill in remaining options with Google auto suggestions */
        while (
          googleResults &&
          googleResults.value &&
          googleResults.value.length > 0 &&
          response.options.length < maxResults
        ) {
          const googleLocation = googleResults.value.shift();
          response.options.push({
            value: googleLocation,
            label: googleLocation.description
          });
        }

        this.setState({isLoading: false, options: response.options});

        return response;
      } catch (error) {
        console.error(error);
      }
    }
  }

  toggleShowManualAddressModal(show) {
    this.setState({showManualAddressModal: show});
  }

  render() {
    const {
      input,
      name,
      disabled,
      placeholder,
      value,
      sronly,
      allowZipOnly,
      meta: {touched, error}
    } = this.props;

    return (
      <FormGroup {...this.props} className="address-search">
        {() => (
          <AddressVerification
            allowZipOnly={allowZipOnly}
            addr={input.value || ''}
            onChange={(address, formatted_address, entry = {}) => {
              input.onChange(Object.assign({}, address, {company: entry.value}));
            }}
            showManualAddressModal={this.state.showManualAddressModal}
            toggleShowManualAddressModal={this.toggleShowManualAddressModal.bind(this)}
          >
            {({value, onChange, ...props}) => (
              <AsyncTypeahead
                {...props}
                multiple={false}
                allowNew={false}
                isLoading={false}
                selected={value ? [value] : []}
                placeholder={placeholder}
                filterBy={(value) => value}
                onSearch={this.searchAddresses.bind(this)}
                options={this.state.options}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    <>
                      <div
                        className="flex cursor-pointer p-2 text-sw-primary hover:bg-[#f3f9fd]"
                        onClick={() => this.toggleShowManualAddressModal(true)}
                      >
                        <div className="flex-1 p-1">Manually Enter an Address</div>
                      </div>
                      {results.map((result, index) => {
                        return (
                          <MenuItem option={result} position={index} key={index}>
                            <div className={`async-option ${result.icon ? 'addr-option' : ''}`}>
                              {result?.icon ? (
                                <span className="pr-3">
                                  <SvgIcon
                                    name={result?.value?.facility_id ? 'Business' : result?.icon ? 'IdCard' : 'Zipcode'}
                                    color="$sw-primary"
                                  />
                                </span>
                              ) : null}
                              <span className="option-label">
                                {result.name}
                                {result.name && <br />}
                                {result.label}
                              </span>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </>
                  </Menu>
                )}
                onChange={([selection]) => {
                  const VALID_PST_TIMEZONE = 'America/Los_Angeles';
                  if (selection && selection.icon) {
                    // It is possible to create an Address Book entry via the API with the timezone "PST"
                    // However, the BE does not consider it a valid timezone for shipment creation
                    // Moment also does not consider PST a valid timezone so converting to America/Los_Angeles
                    const isPstAddress = selection?.value?.address?.timezone === 'PST';
                    if (isPstAddress) {
                      selection.value.address.timezone = VALID_PST_TIMEZONE;
                    }
                    const isPstValue = selection?.value?.timezone === 'PST';
                    if (isPstValue) {
                      selection.value.timezone = VALID_PST_TIMEZONE;
                    }
                    this.addressBookSelection = selection;
                    input.onChange(Object.assign({}, this.addressBookSelection.value, {company: selection.value}));
                  } else {
                    this.addressBookSelection = null;
                    this.handlePlaceSelection(selection)
                      .then((value) => {
                        onChange(value, selection);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                }}
                {...props}
              />
            )}
          </AddressVerification>
        )}
      </FormGroup>
    );
  }
}

export default AddressSearchField;
