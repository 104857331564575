import {ComponentPropsWithoutRef, ComponentType, ReactNode, JSX} from 'react';
import partialRight from 'lodash/partialRight';
import {wrapDisplayName} from 'recompose';
import RollbarErrorBoundary from './RollbarErrorBoundary';

function withRollbarErrorBoundary<P extends JSX.IntrinsicAttributes>(
  Component: ComponentType<P>,
  errorBoundaryProps: ComponentPropsWithoutRef<typeof RollbarErrorBoundary>
): ComponentType<P> {
  const Wrapped: ComponentType<P> = (props) => (
    <RollbarErrorBoundary {...errorBoundaryProps}>
      <Component {...props} />
    </RollbarErrorBoundary>
  );

  Wrapped.displayName = wrapDisplayName(Component, 'withErrorBoundary');

  return Wrapped;
}

export function withRollbarErrorBoundaryWithMessage(errorMessage?: ReactNode) {
  return partialRight(withRollbarErrorBoundary, {
    errorMessage
  });
}

export default withRollbarErrorBoundaryWithMessage();
