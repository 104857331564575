import {FormikSelect, FormikTextInput, IconButton} from '@shipwell/shipwell-ui';
import {Field, FieldArray, useFormikContext} from 'formik';
import {ContractFormValues} from '../../../constants';
import {useGetAccessorials} from 'App/containers/contracts/create/hooks/useGetAccessorials';

export const ContractDefaultAccessorialsFields = () => {
  const {values} = useFormikContext<ContractFormValues>();

  const {accessorials} = useGetAccessorials();

  return (
    <div className="mt-4 flex flex-col rounded-md bg-sw-text-reverse-alternate px-4 pt-4">
      <span className="text-sm font-semibold">Additional Accessorials</span>
      <FieldArray
        name="defaultAccessorials"
        render={(formikArrayHelpers) => (
          <div className="flex w-full flex-col gap-2">
            {values.defaultAccessorials && values.defaultAccessorials.length > 0
              ? values.defaultAccessorials.map((_, index) => {
                  return (
                    <div key={index} className="mt-4 grid w-full grid-cols-4 items-start gap-2">
                      <div className="col-span-2  h-16">
                        <Field
                          name={`defaultAccessorials[${index}].accessorial`}
                          component={FormikSelect}
                          label="Accessorial"
                          getOptionLabel={({description}: {description: string}) => description}
                          options={accessorials}
                          value={accessorials?.find(
                            (option) => option.code === values?.defaultAccessorials?.[index]?.accessorial?.code
                          )}
                          required
                        />
                      </div>
                      <div className="col-span-1 h-16">
                        <Field
                          name={`defaultAccessorials[${index}].rate`}
                          label="Rate"
                          component={FormikTextInput}
                          required
                          prepend={values?.rate_currency}
                          type="number"
                          className="w-[calc(100%_-_45px)]"
                        />
                      </div>
                      <div className="col-span-1 flex h-16 w-full items-start">
                        <div className="flex w-full items-center gap-2">
                          <Field
                            name={`defaultAccessorials[${index}].description`}
                            label="Description"
                            component={FormikTextInput}
                            required
                            className="w-3/4"
                          />
                          <div>
                            <IconButton
                              aria-label="Remove accessorial"
                              iconName="TrashOutlined"
                              onClick={() => formikArrayHelpers.remove(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      />
    </div>
  );
};
