export const VIEW_SHIPMENTS_USER_PERMISSION = 'shipments.view_shipments';
export const UPDATE_SHIPMENTS_USER_PERMISSION = 'shipments.update_shipments';
export const DELETE_SHIPMENTS_USER_PERMISSION = 'shipments.delete_shipments';
export const CREATE_SHIPMENTS_USER_PERMISSION = 'shipments.create_shipments';
export const VIEW_MY_SHIPMENTS_USER_PERMISSION = 'shipments.view_my_shipments';
export const UPDATE_MY_SHIPMENTS_USER_PERMISSION = 'shipments.update_my_shipments';
export const DELETE_MY_SHIPMENTS_USER_PERMISSION = 'shipments.delete_my_shipments';
export const CREATE_ORDERS_USER_PERMISSION = 'orders.create_orders';
export const CREATE_QUOTES_USER_PERMISSION = 'quotes.create_quotes';
export const VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION = 'shipments.view_financials';
export const CREATE_SHIPMENT_DIRECT_TENDER = 'tenders.create_tender';
export const SHIPMENT_INITIATE_ROUTING_GUIDE = 'shipments.initiate_routing_guide';
export const SHIPMENT_CREATE_SPOT_NEGOTIATIONS = 'shipments.create_spot_negotiations';
export const SHIPMENT_ASSIGN_CARRIER = 'shipments.assign_carrier';
export const VIEW_ADDRESS_BOOK_PERMISSION = 'locations.view_address_book_entries';
export const CREATE_ADDRESS_BOOK_PERMISSION = 'locations.create_address_book_entries';
export const UPDATE_ADDRESS_BOOK_PERMISSION = 'locations.update_address_book_entries';
export const DELETE_ADDRESS_BOOK_PERMISSION = 'locations.delete_address_book_entries';
