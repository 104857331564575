import {TemplateAutoGenerateRule} from '@shipwell/template-builder-sdk';
import {camelToSentence} from 'App/utils/string';

interface Option {
  label: string;
  value: string | string[];
}

export const createOptionsFromSDK = (
  obj: Record<string, string>,
  upcase = false,
  extraOptionLabel: string | null = null
): Option[] => {
  const options: Option[] = Object.entries(obj).map(([key, value]) => ({
    label: upcase ? camelToSentence(key, true).toUpperCase() : camelToSentence(key, true),
    value
  }));

  if (extraOptionLabel) {
    options.unshift({
      label: extraOptionLabel,
      value: extraOptionLabel
    });
  }

  return options;
};

export const dotSnakeCaseToTileCase = (value: string) =>
  value
    .split('.')
    .map((val) => val.split('_').join(' '))
    .join(' ')
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });

export const parseAutoGenerateRules = (autoGenerateRules: TemplateAutoGenerateRule[]) =>
  autoGenerateRules.map((agv) => dotSnakeCaseToTileCase(agv.event_name));
