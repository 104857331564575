import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {MovementDetailsTitle} from './MovementDetailsTitle';
import {AirMovementDetailsCard} from './AirMovementDetailsCard';
import {DrayageMovementDetailsCard} from './DrayageMovementDetailsCard';
import {TerminalSchedule} from './TerminalSchedule';
import {useGetLocusMovementDetailsByShipmentId} from './useGetLocusMovementDetailsByShipmentId';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';
import Loader from 'App/common/shipwellLoader';

export const MovementDetails = ({shipment}: {shipment: Shipment}) => {
  const {movementDetailsStatus, movementDetails} = useGetLocusMovementDetailsByShipmentId({
    shipmentId: shipment.id
  });

  return (
    <div className="p-2">
      <Card
        title={<MovementDetailsTitle shipment={shipment} movementDetails={movementDetails} />}
        isCollapsible={!!movementDetails?.length}
        draggableProvided={undefined}
      >
        <CollapsibleCardContent>
          {/* TODO: Implement the logic for Terminal schedule when we have DRAYAGE details */}
          {
            // eslint-disable-next-line no-constant-binary-expression
            false && <TerminalSchedule />
          }
          {movementDetailsStatus === 'loading' && <Loader loading />}
          <RollbarErrorBoundary errorMessage="There was an error displaying the movement details">
            {movementDetails.map((movementDetail) => {
              return (
                movementDetail.movement_type === 'FLIGHT' && (
                  <AirMovementDetailsCard movementDetail={movementDetail} shipment={shipment} />
                )
              );
              // implement DRAYAGE details when we have DRAYAGE details
              {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-constant-binary-expression
                false && <DrayageMovementDetailsCard />;
              }
            })}
          </RollbarErrorBoundary>
        </CollapsibleCardContent>
      </Card>
    </div>
  );
};
