import PropTypes from 'prop-types';
import {useState, useEffect, useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {DeprecatedButton, Card} from '@shipwell/shipwell-ui';
import Table from 'App/components/Table';
import {ACCESSORIAL_TABLE} from 'App/containers/userCompany/rateTables/constants';
import {selectedRateTableDetails} from 'App/actions/_shipmentDetails';
import {formatCurrencyValue} from 'App/utils/globals';
import {useGetAccessorials} from 'App/containers/contracts/create/hooks/useGetAccessorials';
import ShipwellLoader from 'App/common/shipwellLoader';

const RateTableDetailsAccessorial = ({values, dispatch, accessorialCustomer, accessorialVendor, isEditMode}) => {
  const {accessorials} = useGetAccessorials();
  const [buttonId, setButtonId] = useState(null);
  const currentCustomer = accessorialCustomer?.calculation_rows?.[buttonId];
  const currentVendor = accessorialVendor?.calculation_rows?.[buttonId];

  const fetchRateTableLineItems = useCallback(() => {
    if (buttonId === null || buttonId < 0) {
      return;
    }
    if (currentCustomer || currentVendor) {
      const {accessorial, calculation_description, rate} = currentCustomer || currentVendor;

      dispatch(
        selectedRateTableDetails({
          customer: currentCustomer ? 'customer' : 'vendor',
          category: 'ACC',
          unit_name: accessorial,
          unit_quantity: 1,
          unit_amount: rate,
          description: calculation_description,
          prepaid_amount: 0
        })
      );
      setButtonId(null);
    }
  }, [buttonId, dispatch, currentVendor, currentCustomer]);

  useEffect(() => {
    fetchRateTableLineItems();
  }, [fetchRateTableLineItems]);

  const columns = useMemo(() => {
    const initialColumns = [
      {
        Header: 'Accessorial',
        accessor: 'accessorial'
      },
      {
        Header: 'Rate',
        accessor: 'rate'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ];

    if (accessorialVendor || accessorialCustomer) {
      initialColumns.push({
        Header: 'Action',
        accessor: 'action'
      });
    }

    return initialColumns;
  }, [accessorialVendor, accessorialCustomer]);

  const data = useMemo(() => {
    const tableData = accessorialVendor
      ? accessorialVendor
      : accessorialCustomer
      ? accessorialCustomer
      : values?.accessorial
      ? values.accessorial
      : null;

    return tableData?.calculation_rows.map((row, index) => ({
      accessorial: accessorials?.find((acc) => acc.code === row.accessorial)?.description || '--',
      rate: row.rate && row.rate_currency ? `${row.rate_currency} ${formatCurrencyValue(row.rate)}` : '--',
      description: row.calculation_description ?? '--',
      action:
        accessorialVendor || accessorialCustomer ? (
          <DeprecatedButton
            id={index}
            size="small"
            variant="primary"
            disabled={!isEditMode}
            onClick={() => {
              setButtonId(index);
            }}
          >
            Add as Line Item
          </DeprecatedButton>
        ) : (
          '--'
        )
    }));
  }, [values, accessorialCustomer, accessorialVendor, isEditMode, accessorials]);

  return (
    <Card title={ACCESSORIAL_TABLE} bodyClassName="p-0">
      {!accessorials ? <ShipwellLoader loading={!accessorials} /> : <Table columns={columns} data={data} />}
    </Card>
  );
};

RateTableDetailsAccessorial.propTypes = {
  accessorialCustomer: PropTypes.object,
  accessorialVendor: PropTypes.object,
  dispatch: PropTypes.func,
  values: PropTypes.shape({
    accessorial: PropTypes.object
  }),
  isEditMode: PropTypes.bool
};

export default connect((state) => ({
  selectedRateTable: state.shipmentdetails.selectedRateTable
}))(RateTableDetailsAccessorial);
