import {EventInput} from '@fullcalendar/core';
import {SlimCarrierRelationship} from '@shipwell/backend-core-sdk';
import {
  AppointmentReason,
  AppointmentStatusEnum,
  DateTimeWithTimezone,
  DeliveryTypeEnum,
  FacilityAddress,
  FacilityAppointmentType,
  ReferenceQualifierEnum,
  ScheduledResourceMetadata,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';
import {MappedDockType} from '../facilities/types';

export type TimezoneAwareDateTime = {
  original: Date;
  timezone: {
    name: string;
    abbreviated: string;
  };
  date: string;
  time: string;
  full: string;
};
export type AppointmentAvailabilityWindow = {
  startDate: TimezoneAwareDateTime;
  endDate: TimezoneAwareDateTime;
  dockId: string;
  loadTypeId?: string;
  isAllDay: boolean;
};

export type RankedMappedDockType = {
  /**
   * The rank number for all docks for which the dock is the best to worst match.
   * The Lower number is the better match.
   */
  rank: number;
} & MappedDockType;

export type RankedAvailabilityWindow = {
  startDate: TimezoneAwareDateTime;
  endDate: TimezoneAwareDateTime;
  dockId: string;
  loadTypeId?: string;
  rank: number;
  isAllDay: boolean;
};

export type AppointmentAvailability = {
  /**
   * TimeZone the facility is in
   */
  timezone: string;
  /**
   * Actual docks that can be used for the appointment.
   * @comment This is a subset of the docks that exist at the facility. The Map indicates the ranked order in which the docks should be a best match.
   */
  docks: RankedMappedDockType[];
  /**
   * Ranked set of mapped docks and windows. The Key is the dockIdd
   * and the values are the assigned availability windows.
   * @comment the order in which the map returns the results indicates the ranked best match for docks.
   */
  windows: RankedAvailabilityWindow[];
  /**
   * True if and only if the appointment should be all day.
   */
  isAllDay: boolean;
};

export type AppointmentAvailabilityRestriction = {
  startDate: TimezoneAwareDateTime;
  endDate: TimezoneAwareDateTime;
  dockId?: string;
  reason: string;
};

export enum ViewMode {
  Week = 'Week',
  Day = 'Day'
}

export type CarrierEntry = SlimCarrierRelationship & {vendorId: string};
export type AppointmentReference = {
  qualifier: ReferenceQualifierEnum;
  value: string;
};

export type AppointmentEntry = {
  id: string;
  facilityId: string;
  description?: string;
  dockId: string; // facility level appointments have `dockId===''`
  allDay: boolean;
  carrierName?: string;
  carrierTenantId?: string;
  scheduledResourceReferenceId: string;
  durationMs: number;
  start: DateTimeWithTimezone;
  end: DateTimeWithTimezone;
  appointmentType: FacilityAppointmentType;
  matchedLoadTypeId?: string;
  status: AppointmentStatusEnum;
  reason?: AppointmentReason;
  name?: string;
  stopId?: string;
  scheduledResourceId?: string;
  scheduledResourceType?: ScheduledResourceTypeEnum;
  scheduledResourceMetadata?: ScheduledResourceMetadata;
  deliveryType?: DeliveryTypeEnum;
  checkedOutAt?: DateTimeWithTimezone;
  checkedInAt?: DateTimeWithTimezone;
  references?: AppointmentReference[];
  rejectedAt?: DateTimeWithTimezone;
  rejectedReasons?: string;
};
export type PaginatedAppointmentEntries = {
  page: number;
  pageSize: number;
  total: number;
  entries: AppointmentEntry[];
};

export type CalendarAppointmentEvent = EventInput & {
  id: string;
  resourceId: string;
  start: Date | string;
  end: Date | string;
  extendedProps: {
    appointment: AppointmentEntry;
    viewMode: ViewMode;
  };
};

export type Filters = {
  docks: Record<string, boolean>;
  deliveryTypes: Record<DeliveryTypeEnum, boolean>;
  loadTypes: Record<string, boolean>;
  preferredReference: Record<string, boolean>;
  hideClosedDays: boolean;
};

export type AppointmentCallback = (appointmentId: string, time?: Date, reason?: string) => Promise<void>;
export type SchedulingCallback = (
  event: CalendarAppointmentEvent | AppointmentEntry,
  revert: () => void,
  newResourceId?: string,
  override?: boolean
) => Promise<void>;
export type ResourceStub = {
  title: string;
  id: string;
};

export type CarrierAppointment = {
  id: string | null;
  start: TimezoneAwareDateTime | null;
  end: TimezoneAwareDateTime | null;
  status: AppointmentStatusEnum;
  shipmentId?: string | null;
  stopId?: string | null;
  /**
   * Planned Date property from the `import('@shipwell/backend-core-sdk').Stop['planned_date])`
   * format is YYYY-MM-dd
   */
  plannedDate?: string | null;
  facility: {
    id: string;
    name: string | null;
    address: FacilityAddress | null;
  };
  references: AppointmentReference[];
  deliveryType?: DeliveryTypeEnum | null;
  type?: FacilityAppointmentType | null;
};
