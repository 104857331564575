import {PRIVACY_POLICY, TERMS_CONDITIONS, TERMS_ERROR} from './types';
import {getShipwellApi} from 'App/api/config';

/*---------------Privacy Policy-----------------*/
const fetchPrivacyPolicyPromise = async () => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    /**
     * HTML document of Carrier privacy policy
     * @param {module:api/DefaultApi~staticCarriersPrivacyPolicyHtmlGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {'String'}
     */
    shipwellApi.staticCarriersPrivacyPolicyHtmlGet((err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
};
export const fetchPrivacyPolicy = () => {
  return (dispatch) => {
    fetchPrivacyPolicyPromise()
      .then((response) => {
        dispatch({type: PRIVACY_POLICY, payload: response});
      })
      .catch((response) => {
        if (response.data) {
          dispatch(termsError(response.data.error));
        }
      });
  };
};

/*-----------------Terms & Conditions--------------------*/
const fetchTermsConditionsPromise = async () => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    /**
         * HTML document of the critical part of Terms and Conditions that\n
            Carrier must agree to right before accepting a contract\n

         * @param {module:api/DefaultApi~staticCarriersAbbreviatedTermsAndConditionsHtmlGetCallback} callback The callback function, accepting three arguments: error, data, response
         * data is of type: {'String'}
         */
    shipwellApi.staticCarriersFullTermsAndConditionsHtmlGet((err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
};
export const fetchTermsConditions = () => {
  return (dispatch) => {
    fetchTermsConditionsPromise()
      .then((response) => {
        dispatch({type: TERMS_CONDITIONS, payload: response});
      })
      .catch((response) => {
        dispatch(termsError(response.data.error));
      });
  };
};

/* ------------ termsError --------------- */

export function termsError(error) {
  return {type: TERMS_ERROR, payload: error};
}
