import {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Card} from '@shipwell/shipwell-ui';
import {formatDateTime} from 'App/utils/globals';
import './_shipment-auction.scss';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
//import * as actions from 'App/actions/shipments';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    one: state.shipmentdetails.one,
    selectedRFQ: state.shipmentdetails.selectedRFQ,
    selectedQuote: state.shipmentdetails.selectedQuote,
    shipmentBillToForm: state.form.shipmentBillToForm
  }),
  {...shipmentDetailsActions}
)
export default class ShipmentAuction extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {error, handleSubmit, submitting, one} = this.props;
    let auctionStats = {};

    if (one) {
      auctionStats = one.auction_stats;
    }
    const {visible_companies = null} = auctionStats;
    const {most_recent_quote_created_at = null} = auctionStats;
    const {quotes = null} = auctionStats;
    const duration = null;

    return (
      <Card
        title={<span>Load # {this.props.one.metadata && this.props.one.metadata.load_board_id}</span>}
        actions={
          <span className="shipment__auction-running font-bold">
            Auction Running <Link to={'/marketplace/' + this.props.one.id + '/bids'}>View Details</Link>{' '}
          </span>
        }
      >
        <RollbarErrorBoundary>
          <div className="shipment__auction-container">
            <div className="marketplace__financials">
              <span className="marketplace__financials-column">
                <span className="marketplace__financials-header">Visibility</span>
                <span className="marketplace__financials-body">
                  {visible_companies && visible_companies !== null ? visible_companies + ' Carriers' : '--'}
                </span>
              </span>
              <span className="marketplace__financials-column">
                <span className="marketplace__financials-header">Duration</span>
                <span className="marketplace__financials-body">
                  {duration && duration !== null ? duration + ' days' : '--'}
                </span>
              </span>
              <span className="marketplace__financials-column">
                <span className="marketplace__financials-header">Bids</span>
                <span className="marketplace__financials-body">{quotes && quotes !== null ? quotes : '--'}</span>
              </span>
              <span className="marketplace__financials-column">
                <span className="marketplace__financials-header">Last Bid</span>
                <span className="marketplace__financials-body">
                  {most_recent_quote_created_at && moment(most_recent_quote_created_at).isValid()
                    ? formatDateTime(most_recent_quote_created_at, true)
                    : '--'}
                </span>
              </span>
            </div>
          </div>
        </RollbarErrorBoundary>
      </Card>
    );
  }
}
