import {Button} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {TagModal} from '../../../components/tagModal/tagModal';
import {SearchForm} from './searchForm/searchForm';
import {SearchFormValues} from './searchForm/searchFormValues';
import {SearchTable} from './searchTable/searchTable';
import {TAGS_SEARCH_KEY} from './searchTable/useTagsService/constants';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';
import PageHeader from 'App/common/pageHeader';

export function TagsPage() {
  const queryClient = useQueryClient();
  const [lastFilters, setLastFilters] = useState<SearchFormValues>();
  const tagsModal = useVisibility<string>();

  function handleTagSave() {
    tagsModal.hideAndClearData();
    void queryClient.invalidateQueries({
      queryKey: [TAGS_SEARCH_KEY]
    });
  }

  return (
    <>
      <div className="flex flex-1	flex-col">
        <PageHeader
          title="Tags"
          className="py-0 [&>h2]:text-base"
          actions={
            <Button size="sm" onClick={tagsModal.show}>
              Create New Tag
            </Button>
          }
        >
          <SearchForm onSearch={setLastFilters} />
        </PageHeader>

        <SearchTable filters={lastFilters} />
      </div>

      <TagModal
        show={tagsModal.isVisible}
        tagId={tagsModal.data}
        onClose={tagsModal.hideAndClearData}
        onSave={handleTagSave}
      />
    </>
  );
}
