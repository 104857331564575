import {Title, Rule, DisplayValue} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useGetShipmentContainerDetails} from 'App/data-hooks';
import {formatCurrencyValue} from 'App/utils/globals';

export const ShipmentContainerDetails = ({shipmentId}: {shipmentId: string}) => {
  const {containerDetailsData, containerDetailsStatus} = useGetShipmentContainerDetails({shipmentId});

  if (containerDetailsStatus === 'loading') {
    return <ShipwellLoader loading additionalClassNames={['h-full']} />;
  }

  if (!containerDetailsData) {
    return <p>Unable to load container details</p>;
  }

  return (
    <dl className="flex flex-col gap-4">
      <Title variant="sectionTitle">Ocean</Title>
      <div className="grid grid-cols-2 gap-4">
        <DisplayValue label="Customer Reference #">{containerDetailsData?.port_of_loading || '--'}</DisplayValue>
        <DisplayValue label="Port of Discharge">{containerDetailsData?.port_of_discharge || '--'}</DisplayValue>
        <DisplayValue label="Estimated Port of Loading Departure">
          {containerDetailsData?.origin_etd_date || '--'}
        </DisplayValue>
        <DisplayValue label="Port of Discharge Terminal">{containerDetailsData?.pod_terminal || '--'}</DisplayValue>
        {/* spacer for grid layout */}
        <div aria-hidden />
        <DisplayValue label="POD Terminal Address">{containerDetailsData?.pod_terminal_address || '--'}</DisplayValue>
      </div>
      <Rule />
      <Title variant="sectionTitle">Vessel</Title>
      <div className="grid grid-cols-2 gap-4">
        <DisplayValue label="Vessel Name">{containerDetailsData?.vessel_name || '--'}</DisplayValue>
        <DisplayValue label="Vessel IMO">{containerDetailsData?.vessel_imo || '--'}</DisplayValue>
        <DisplayValue label="Voyage Number">{containerDetailsData?.voyage_number || '--'}</DisplayValue>
        <DisplayValue label="Vessel MMSI">{containerDetailsData?.vessel_mmsi || '--'}</DisplayValue>
        <DisplayValue label="Container Type">{containerDetailsData?.container_type || '--'}</DisplayValue>
      </div>
      <Rule />
      <Title variant="sectionTitle">Customs</Title>
      <div className="grid grid-cols-2 gap-4">
        <DisplayValue label="Customs Hold Type">{containerDetailsData?.customs_hold_type || '--'}</DisplayValue>
        <DisplayValue label="Customs Release Date">{containerDetailsData?.customs_release_date || '--'}</DisplayValue>
        <DisplayValue label="Customs Hold Release Date">
          {containerDetailsData?.customs_hold_resolve_date || '--'}
        </DisplayValue>
      </div>
      <Rule />
      <Title variant="sectionTitle">Demurrage</Title>
      <div className="grid grid-cols-2 gap-4">
        <DisplayValue label="Dumurrage Incurred">
          {containerDetailsData?.demurrage_incurred_currency}{' '}
          {formatCurrencyValue(containerDetailsData?.demurrage_incurred) || '--'}
        </DisplayValue>
      </div>
      <Rule />
      <Title variant="sectionTitle">Rail</Title>
      <div className="grid grid-cols-2 gap-4">
        <DisplayValue label="Rail Carrier">{containerDetailsData?.rail_carrier || '--'}</DisplayValue>
        <DisplayValue label="LFD At Rail Terminal">{containerDetailsData?.lfd_rail_terminal || '--'}</DisplayValue>
        <DisplayValue label="Final Terminal">{containerDetailsData?.final_rail_terminal || '--'}</DisplayValue>
      </div>
    </dl>
  );
};
