import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Field} from 'formik';
import {FormikCheckbox, Card} from '@shipwell/shipwell-ui';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';

const CompanyMessagingPreferences = () => {
  return (
    <Card title="Messaging Preferences" className="mb-6">
      <div className="font-bold">SMS messages</div>
      <div className="mt-2">Always send the driver an SMS text message when a shipment message is posted.</div>
      <Field component={FormikCheckbox} name="drivers_sms_shipment_messages" label="Enable Driver SMS Notifications" />
    </Card>
  );
};

CompanyMessagingPreferences.propTypes = {
  msSmsShipmentMessages: PropTypes.bool
};

export default compose(
  withFlags('msSmsShipmentMessages'),
  withConditionalFallback(({msSmsShipmentMessages}) => !msSmsShipmentMessages)
)(CompanyMessagingPreferences);
