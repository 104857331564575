import {FormEvent, useMemo, useState, MouseEvent} from 'react';

import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import isNil from 'lodash/isNil';
import {object, string, date} from 'yup';
import {
  AppointmentStatusEnum,
  Facility,
  FacilityDock,
  FacilityDockAppointmentRule,
  LoadType
} from '@shipwell/tempus-sdk';
import {noop} from 'lodash';
import {AppointmentBookingContextProvider} from './AppointmentBookingContext';

import AppointmentStopCard from 'App/containers/appointments/components/cards/AppointmentStopCard';
import {SelectStopAppointment, Sidebar} from 'App/containers/appointments/components/forms/AppointmentForm/components';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import ShipwellLoader from 'App/common/shipwellLoader';
import {
  useShipmentsStopAppointmentQuery,
  useAppointmentMutations,
  useGetLoadTypes,
  useFacilityDocksQuery,
  useGetFacilityDockRules,
  UseRescheduleAppointmentMutationOptions,
  UseCancelAppointmentMutationOptions,
  UseCreateAppointmentMutationOptions,
  useMutableFacilityHoursOfOperation,
  UseScheduleAppointmentMutationOptions
} from 'App/data-hooks';
import {Stop} from 'App/api/shipment/typed';
import {AppointmentAvailabilityWindow, AppointmentEntry} from 'App/data-hooks/appointments/types';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

const validationSchema = object({
  facilityId: string().required(),
  dockId: string().required(),
  stopId: string().required(),
  deliveryType: string().required(),
  shipment: object({
    id: string().required(),
    type: string().required()
  }).required(),
  appointment: object({
    startDate: date().required(),
    endDate: date().required(),
    timezone: string().required()
  }).required(),
  carrier: object({
    tenantId: string().optional().nullable(),
    name: string().optional().nullable()
  })
    .optional()
    .nullable()
});

const EmptyLoadTypes: LoadType[] = [];
const EmptyDocks: FacilityDock[] = [];
const EmptyDockRules: FacilityDockAppointmentRule[] = [];

export type AppointmentFormProps = {
  shipmentId?: string | null;
  /**
   * A selected stop that is being scheduled at facility or dock. If this field
   * is provided the screen will automatically move to the select appointment screen
   * rather than having to manually select one.
   */
  stopId?: string | null;
  /**
   * Callback handler for when cancel button is clicked
   * on the form
   */
  onCancelClick: (e: MouseEvent<HTMLButtonElement>) => void;
  clickedTime?: Date | null;
};

type AppointmentFormPropsWithStatusToastProps = AppointmentFormProps & Pick<WithStatusToastProps, 'setError'>;

const AppointmentForm = (props: AppointmentFormPropsWithStatusToastProps) => {
  const [stopId, setStopId] = useState<string | null | undefined>(props.stopId);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [window, setWindow] = useState<AppointmentAvailabilityWindow | null>(null);
  const [dock, setDock] = useState<FacilityDock | null>(null);
  const [facility, setFacility] = useState<Facility | null>(null);

  const {shipmentQuery} = useLegacyShipment(props.shipmentId, {
    enabled: Boolean(props.shipmentId)
  });
  const shipmentStopAppointmentQuery = useShipmentsStopAppointmentQuery(
    {
      shipmentId: props.shipmentId,
      stopId
    },
    {
      enabled: shipmentQuery.isSuccess
    }
  );

  const {
    id: shipmentId,
    current_carrier,
    stops: legacyStops
  } = shipmentQuery.data ?? {id: null, current_carrier: null, stops: []};

  const stops = useMemo(() => {
    return (
      legacyStops
        ?.sort((left, right) => left.ordinal_index - right.ordinal_index)
        .map<Stop>((s, i) => ({
          stopNumber: i + 1,
          ...s
        })) ?? []
    );
  }, [legacyStops]);

  const selectedStop = useMemo(() => stops.find((s) => s.id === stopId), [stops, stopId]);
  const facilityId = useMemo(() => {
    return facility?.id ?? shipmentStopAppointmentQuery.data?.facility?.id; // either take the facility that the user has later assigned or take the one that's assigned the appointment
  }, [facility, shipmentStopAppointmentQuery.data?.facility]);
  const dockId = useMemo(() => {
    return dock?.id ?? shipmentStopAppointmentQuery.data?.dock?.id; // either take the dock that the user has later assigned or take the one that's assigned the appointment
  }, [dock, shipmentStopAppointmentQuery.data?.dock]);
  const deliveryType = selectedStop?.is_pickup ? 'Shipping' : selectedStop?.is_dropoff ? 'Receiving' : null;
  const shipmentDisplayNumber =
    shipmentQuery.data?.purchase_order_number ||
    shipmentQuery.data?.customer_reference_number ||
    shipmentQuery.data?.pro_number ||
    shipmentQuery.data?.reference_id;

  const loadTypesQuery = useGetLoadTypes(facilityId);
  const loadTypes = loadTypesQuery.data ?? EmptyLoadTypes;
  const docksQuery = useFacilityDocksQuery(facilityId);
  const docks = docksQuery.data ?? EmptyDocks;
  const dockRulesQuery = useGetFacilityDockRules(docks, facilityId ?? '');
  const dockRules = dockRulesQuery.data ?? EmptyDockRules;
  const hoursQuery = useMutableFacilityHoursOfOperation(facilityId ?? '', {onError: noop});
  const hours = hoursQuery.hours;
  const appointmentBookingContextValue = {
    facility: facility ?? undefined,
    hours,
    loadTypes,
    docks,
    dockRules,
    isInitialLoading:
      dockRulesQuery.isRulesQueriesLoading || docksQuery.isInitialLoading || loadTypesQuery.isInitialLoading
  };

  const createAppointmentOptions: UseCreateAppointmentMutationOptions = {
    onSuccess: (appointment) => {
      handleReset();
      const scheduledStopNumber = stops.find((s) => s.id === appointment.stop_id)?.stopNumber ?? -1;
      setSuccessMessage(`Stop ${scheduledStopNumber} Appointment Booked!`); // needs to be set AFTER because handleReset will put this to null
    },
    onError: (error) => {
      setSuccessMessage(null);
      props.setError(
        'Error',
        <ul>
          {error.response?.data.errors.map((value, ix) => (
            <li key={ix}>{value.detail}</li>
          ))}
        </ul>
      );
    }
  };
  const rescheduleAppointmentOptions: UseRescheduleAppointmentMutationOptions = {
    onSuccess: (appointment) => {
      handleReset();
      const rescheduledStopNumber = stops.find((s) => s.id === appointment.stop_id)?.stopNumber ?? -1;
      setSuccessMessage(`Stop ${rescheduledStopNumber} has been Rescheduled!`); // needs to be set AFTER because handleReset will put this to null
    },
    onError: (error) => {
      setSuccessMessage(null);
      props.setError(
        'Error',
        <ul>
          {error.response?.data.errors.map((value, ix) => (
            <li key={ix}>{value.detail}</li>
          ))}
        </ul>
      );
    }
  };
  const scheduleAppointmentOptions: UseScheduleAppointmentMutationOptions = {
    onSuccess: (appointment) => {
      handleReset();
      const scheduledStopNumber = stops.find((s) => s.id === appointment.stop_id)?.stopNumber ?? -1;
      setSuccessMessage(`Stop ${scheduledStopNumber} has been Scheduled!`);
    },
    onError: (error) => {
      setSuccessMessage(null);
      props.setError(
        'Error',
        <ul>
          {error.response?.data.errors.map((value, ix) => (
            <li key={ix}>{value.detail}</li>
          ))}
        </ul>
      );
    }
  };
  const cancelAppointmentOptions: UseCancelAppointmentMutationOptions = {
    onSuccess: (appointment) => {
      handleReset();
      const canceledStopNumber = stops.find((s) => s.id === appointment.stop_id)?.stopNumber ?? -1;
      setSuccessMessage(`Stop ${canceledStopNumber} has been Canceled!`); // needs to be set AFTER because handleReset will put this to null
    },
    onError: (error) => {
      setSuccessMessage(null);
      props.setError(
        'Error',
        <ul>
          {error.response?.data.errors.map((value, ix) => (
            <li key={ix}>{value.detail}</li>
          ))}
        </ul>
      );
    }
  };

  const {isMutating, cancelAppointment, createAppointment, rescheduleAppointment, scheduleAppointment} =
    useAppointmentMutations({
      cancelOptions: cancelAppointmentOptions,
      rescheduleOptions: rescheduleAppointmentOptions,
      createOptions: createAppointmentOptions,
      scheduleOptions: scheduleAppointmentOptions
    });

  const isValid = useMemo(() => {
    const data = {
      facilityId: facilityId,
      dockId: dockId,
      stopId: selectedStop?.id,
      deliveryType: deliveryType,
      shipment: {
        id: shipmentId,
        type: 'SHIPMENT'
      },
      appointment: {
        startDate: window?.startDate.original,
        endDate: window?.endDate.original,
        timezone: window?.startDate.timezone.name
      },
      carrier: {
        tenantId: current_carrier?.id,
        name: current_carrier?.name
      }
    };
    const isValid = validationSchema.isValidSync(data);
    try {
      validationSchema.validateSync(data);
    } catch (err) {
      console.log('FORM IS NOT VALID:', err, {window});
    }
    return isValid;
  }, [
    facilityId,
    dockId,
    selectedStop?.id,
    deliveryType,
    shipmentId,
    window,
    current_carrier?.id,
    current_carrier?.name
  ]);

  /**
   * Sets the formik context values for a stop. If there initially was a stop selected and a facility loaded
   * those are not ignored as we use the values that are loaded from Formik.
   * @param {Stop} stop full stop object
   * @param {Facility} facility the facility associated with the stop
   * @returns {void}
   */
  const onScheduleStopClick = (stop: Stop, facility: Facility | null): void => {
    setSuccessMessage(null);
    setStopId(stop.id);
    setFacility(facility);
    setWindow(null);
    setDock(null);
  };

  const handleReset = () => {
    setSuccessMessage(null);
    setStopId(null);
    setFacility(null);
    setDock(null);
    setWindow(null);
  };

  const handleDockChanged = (dock: FacilityDock | null) => {
    setDock(dock);
    setWindow(null);
  };

  /**
   * Callback handler for when data has been validated and finalized in the form.
   */
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccessMessage(null);
    if (isNil(facilityId)) {
      props.setError('Error', 'No facility associated with stop location or address book entry');
      return;
    }
    if (isNil(dockId)) {
      props.setError('Error', 'Please select a dock');
      return;
    }
    if (isNil(window)) {
      props.setError('Error', 'Please select an appointment time');
      return;
    }
    const {appointment} = shipmentStopAppointmentQuery.data;
    if (!isNil(appointment) && !isNil(appointment.id) && appointment.status !== AppointmentStatusEnum.Cancelled) {
      // if we found an existing appointment using the shipmentStopAppointmentQuery then we need
      // to use all this data and do an update.
      if (appointment.status === AppointmentStatusEnum.Unscheduled) {
        scheduleAppointment({
          appointmentId: appointment.id,
          timezone: window.startDate.timezone.name,
          start: window.startDate.original,
          end: window.endDate.original,
          dockId
        });
      } else {
        rescheduleAppointment({
          id: appointment.id,
          appointment: {
            dockId: dockId,
            start: window.startDate.original,
            end: window.endDate.original
          }
        });
      }
      return;
    }

    createAppointment({
      facilityId: facilityId,
      dockId: dockId,
      appointment: {
        start: window.startDate.original,
        end: window.endDate.original,
        timezone: window.startDate.timezone.name,
        shipmentReferenceId: shipmentQuery.data?.reference_id,
        stopId: selectedStop?.id,
        carrierName: current_carrier?.name,
        carrierTenantId: current_carrier?.id,
        deliveryType: deliveryType,
        scheduledResourceId: shipmentQuery.data?.id,
        scheduledResourceType: 'SHIPMENT',
        loadType: window.loadTypeId,
        isAllDay: window.isAllDay
      }
    });
    return false; // prevents bubble up effect of form
  };

  const handleCancelAppointmentClick = (appointment: AppointmentEntry | null) => {
    if (isNil(appointment) || isNil(appointment.id)) {
      return;
    }
    cancelAppointment(appointment.id);
  };

  const handleCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    handleReset();
    props.onCancelClick?.(e);
  };

  const isLoading = Boolean(
    shipmentQuery.isInitialLoading ||
      shipmentQuery.isFetching ||
      shipmentStopAppointmentQuery.isAppointmentLoading ||
      isMutating
  );

  return (
    <form onSubmit={handleSubmit} noValidate className="grid grid-flow-row grid-cols-5">
      <div className="col-span-1 flex flex-col bg-sw-background px-8 py-6">
        <Sidebar shipmentId={props.shipmentId} />
        {!isNil(selectedStop?.id) && !isLoading ? (
          <button
            aria-label="back"
            type="button"
            role="navigation"
            className="mt-auto flex flex-row items-center gap-2 text-center"
            onClick={handleReset}
          >
            <SvgIcon name="ArrowBack" />
            Back
          </button>
        ) : null}
      </div>
      <div className="col-span-4 flex flex-col px-8 py-6">
        {isLoading ? (
          <div className="grow">
            <ShipwellLoader loading />
          </div>
        ) : isNil(selectedStop) ? (
          <div className="h-full">
            {successMessage ? (
              <p
                className="flex flex-row items-center gap-3 rounded bg-sw-success-background p-4"
                title={successMessage}
              >
                <SvgIcon name="CheckCircleFilled" color="darkgreen" />
                <strong>{successMessage}</strong>
              </p>
            ) : null}
            <h3>Schedule Appointments for Shipment {shipmentDisplayNumber}</h3>
            <ul className="list-none">
              {legacyStops?.length
                ? legacyStops.map((stop) => (
                    <li key={stop.id} className="mb-4">
                      <AppointmentStopCard
                        shipmentId={props.shipmentId}
                        stopId={stop.id}
                        onCancelClick={handleCancelAppointmentClick}
                        onScheduleClick={onScheduleStopClick}
                      />
                    </li>
                  ))
                : null}
            </ul>
          </div>
        ) : (
          <AppointmentBookingContextProvider value={appointmentBookingContextValue}>
            <SelectStopAppointment
              shipmentId={props.shipmentId}
              stopId={selectedStop?.id}
              onDockChanged={handleDockChanged}
              onSelectedDateChanged={(window) => setWindow(window)}
              onPaginate={() => setWindow(null)}
              clickedTime={props.clickedTime}
            />
            <div className="flex flex-row gap-x-1 self-end justify-self-end">
              <DeprecatedButton type="button" variant="secondary" onClick={handleCancelClick}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" variant="primary" disabled={!isValid} loading={isLoading}>
                Schedule
              </DeprecatedButton>
            </div>
          </AppointmentBookingContextProvider>
        )}
      </div>
    </form>
  );
};

export default WithStatusToasts<AppointmentFormProps>(AppointmentForm);
