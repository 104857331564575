import {Template, TemplateMode, TemplateStatus} from '@shipwell/template-builder-sdk';
import {useMemo} from 'react';
import {useToastManager} from '@shipwell/shipwell-ui';
import {useGetListTemplates} from 'App/data-hooks/templates/useGetTemplates';
import {useGenerateDocumentFromTemplate} from 'App/data-hooks/templates/useGenerateDocumentFromTemplate';

interface DocumentOptionsProps {
  shipmentMode: TemplateMode;
  shipmentId: string;
}

export const DocumentOptions = ({shipmentMode, shipmentId}: DocumentOptionsProps) => {
  const {listTemplates} = useGetListTemplates({allowedModesIn: shipmentMode, status: TemplateStatus.Active});
  const {enqueueToast} = useToastManager();

  const sortedTemplates: Template[] = useMemo(() => {
    const templates = listTemplates?.data.data || [];
    return [...templates].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [listTemplates?.data.data]);

  const {generateDocument} = useGenerateDocumentFromTemplate();

  const handleGenerateDocument = (template: Template) => {
    const templateId = template.id;

    enqueueToast({
      variant: 'success',
      title: 'Document Generation Requested',
      content: `Generate ${template.name} requested.`
    });

    generateDocument(
      {templateId, shipmentId},
      {
        onSuccess: () => {
          enqueueToast({
            variant: 'success',
            title: 'Document Generated',
            content: `Generate ${template.name} completed.`
          });
        },
        onError: () => {
          enqueueToast({
            variant: 'error',
            title: 'Error',
            content: `Failed to generate ${template.name}. Please try again.`
          });
        }
      }
    );
  };

  return (
    <div>
      {sortedTemplates &&
        sortedTemplates.map((template) => (
          <button className="mb-2 block" key={template.id} onClick={() => handleGenerateDocument(template)}>
            {template.name}
          </button>
        ))}
    </div>
  );
};
