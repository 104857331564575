import {Button, Modal} from '@shipwell/shipwell-ui';
import {useInvocationLogService} from './useInvocationLogService/useInvocationLogService';
import ShipwellLoader from 'App/common/shipwellLoader';

export interface LogModalProps {
  show: boolean;
  invocationId: string;
  onClose: () => void;
}

export function LogModal(props: LogModalProps) {
  const service = useInvocationLogService({
    active: props.show,
    invocationId: props.invocationId
  });

  return (
    <Modal
      title={'Script Log'}
      show={props.show}
      primaryActions={<Button onClick={props.onClose}>Close</Button>}
      onClose={props.onClose}
    >
      {service.isLoading && <ShipwellLoader loading />}

      {!service.isLoading && (
        <pre className="bg-sw-text text-xs text-sw-text-reverse max-h-screen-70">
          {JSON.stringify(service.data?.logs, null, 2)}
        </pre>
      )}
    </Modal>
  );
}
