import {Field, useFormikContext} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import {DeprecatedButton, FormikTextarea, FormikTextInput} from '@shipwell/shipwell-ui';
import {DisplayTypes, StopTypeType, StopTypeId, StopFormValues, AddressBookEntryType} from './constants';
import StopContactsFields from './StopContactsFields';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import WithStatusToasts from 'App/components/withStatusToasts';
import type {WithStatusToastProps} from 'App/components/withStatusToasts';
import {shipmentDirections} from 'App/containers/shipments/utils/constants';
import type {LegFormValues} from 'App/containers/shipments/drayage/create/components/LegForm/validation';
import {useCreateAddressBookEntryMutation, useUpdateAddressEntryMutation} from 'App/containers/addressBook/hooks';
import {getAddressEntry, transformAddressBookEntryType} from 'App/containers/shipments/components/StopsFields/utils';

function CopyContainerPickupDetailsButton({stopType}: {stopType: StopTypeType}) {
  const {values, setFieldValue} = useFormikContext<LegFormValues>();

  if (!values.containerPickupRequired || stopType.id !== StopTypeId.Delivery) {
    return null;
  }

  const handleClickCopy = () => {
    setFieldValue('delivery', cloneDeep(values.containerPickup));
  };

  return (
    <div className="mx-[-5px] mb-4">
      <DeprecatedButton variant="tertiary" onClick={handleClickCopy}>
        Copy Container Pickup Details
      </DeprecatedButton>
    </div>
  );
}

function CopyLoadLocationDetailsButton({stopType}: {stopType: StopTypeType}) {
  const {values, setFieldValue} = useFormikContext<LegFormValues>();

  if (!values.containerReturnRequired || stopType.id !== StopTypeId.ContainerReturn) {
    return null;
  }

  const handleClickCopy = () => {
    setFieldValue('containerReturn', cloneDeep(values.pickup));
  };

  return (
    <div className="mx-[-5px] mb-4">
      <DeprecatedButton variant="tertiary" onClick={handleClickCopy}>
        Copy Pickup Details
      </DeprecatedButton>
    </div>
  );
}
const Stop = ({stopType, setSuccess, setError}: {stopType: StopTypeType} & WithStatusToastProps) => {
  const {errors, values} = useFormikContext<LegFormValues>();
  const {values: stopFormValues} = useFormikContext<StopFormValues>();
  const entry: AddressBookEntryType | undefined = getAddressEntry(stopFormValues, stopType.id);

  const createAddressBookEntryMutation = useCreateAddressBookEntryMutation({
    onSuccess: () => setSuccess('Success!', 'Address is created in address book'),
    onError: (error) => setError('Error', error?.response?.data?.error_description)
  });
  const updateAddressBookEntryMutation = useUpdateAddressEntryMutation(entry?.id, {
    onSuccess: () => setSuccess('Success!', 'Address is updated in address book'),
    onError: (error) => setError('Error', error?.response?.data?.error_description)
  });

  const handleSaveClick = () => {
    if (!entry) {
      return;
    }
    const mutationData = transformAddressBookEntryType(entry);
    if (entry?.id) {
      updateAddressBookEntryMutation.mutate(mutationData);
      return;
    }
    createAddressBookEntryMutation.mutate(mutationData);
  };

  if ((!values.direction || values.direction === shipmentDirections.IMPORT) && stopType.label === 'Load Location') {
    return null;
  }

  if (values.direction === shipmentDirections.EXPORT && stopType.label === 'Pickup') {
    return null;
  }

  return (
    <>
      <h3 className="m-0 mt-4 pb-4 text-lg leading-none first:mt-0">{stopType.label}</h3>
      <CopyContainerPickupDetailsButton stopType={stopType} />
      <CopyLoadLocationDetailsButton stopType={stopType} />
      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex flex-col gap-4">
          <Field
            required
            name={stopType.id}
            label={`${stopType.label} Address`}
            useFormikValidation
            isFormikValid={!errors[stopType.id]}
            component={AddressSearch}
          />
          <Field name={`${stopType.id}.company_name`} label="Company Name" component={FormikTextInput} />
          <div className="row-span-2">
            <Field
              name={`${stopType.id}.instructions`}
              label={`${stopType.label} Instructions`}
              component={FormikTextarea}
            />
          </div>
          <div>
            <DeprecatedButton variant="primary" size="small" onClick={handleSaveClick}>
              Save to Address Book
            </DeprecatedButton>
          </div>
        </div>

        <StopContactsFields stopType={stopType} display={DisplayTypes.CardDisplay} />
      </div>
    </>
  );
};

export default WithStatusToasts(Stop);
