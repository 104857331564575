//auth
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_SHIPPER = 'FETCH_SHIPPER';
export const SHIPPER_ID = 'SHIPPER_ID';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_BROKER_USER = 'FETCH_BROKER_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const RESET_ERROR = 'RESET_ERROR';
export const USER_NAME = 'USER_NAME';
export const FETCH_BROKERAGE = 'FETCH_BROKERAGE';
export const TRIGGER_MARKETPLACE_LOGIN = 'TRIGGER_MARKETPLACE_LOGIN';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SYSTEM_VERSION = 'SYSTEM_VERSION';
export const SYSTEM_VERSION_OUT_OF_DATE = 'SYSTEM_VERSION_OUT_OF_DATE';

// addressbook
export const POST_ADDRESSBOOK = 'POST_ADDRESSBOOK';
export const PUT_ADDRESSBOOK = 'PUT_ADDRESSBOOK';
export const DELETE_ADDRESSBOOK = 'DELETE_ADDRESSBOOK';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const SELECT_ADDRESS_ADD = 'SELECT_ADDRESS_ADD';
export const GET_ADDRESSBOOK = 'GET_ADDRESSBOOK';
export const GET_LOCATION_TYPES = 'GET_LOCATION_TYPES';
export const CLEAR_ADDRESS_BOOK_FORM = 'CLEAR_ADDRESS_BOOK_FORM';

// billing
export const ADD_BILLINGSOURCE = 'ADD_BILLINGSOURCE';
export const FETCH_BILLINGSOURCE = 'FETCH_BILLINGSOURCE';
export const DELETE_BILLINGSOURCE = 'DELETE_BILLINGSOURCE';
export const FETCH_ALL_SUBSCRIPTION_PLANS = 'FETCH_ALL_SUBSCRIPTION_PLANS';
export const FETCH_CURRENT_SUBSCRIPTION = 'FETCH_CURRENT_SUBSCRIPTION';
export const SHIPMENT_BILL_CREATE_SUCCESS = 'SHIPMENT_BILL_CREATE_SUCCESS';
export const SHIPMENT_BILLS_REQUEST_SUCCESS = 'SHIPMENT_BILLS_REQUEST_SUCCESS';
export const SHIPMENT_BILLS_REQUEST_ERROR = 'SHIPMENT_BILLS_REQUEST_ERROR';

// brokers
export const FETCH_BROKER_SHIPPERS = 'FETCH_BROKER_SHIPPERS';
export const FETCH_BROKER_AGENTS = 'FETCH_BROKER_AGENTS';
export const CREATE_BROKER_SHIPPER = 'CREATE_BROKER_SHIPPER';
export const EDIT_BROKER_SHIPPER = 'EDIT_BROKER_SHIPPER';
export const CREATE_BROKER_AGENT = 'CREATE_BROKER_AGENT';
export const EDIT_BROKER_AGENT = 'EDIT_BROKER_AGENT';
export const DELETE_BROKER_AGENT = 'DELETE_BROKER_AGENT';
export const ACT_AS_COMPANY = 'ACT_AS_COMPANY';
export const FETCH_BROKER_SHIPMENTS = 'FETCH_BROKER_SHIPMENTS';
export const REQ_BROKER_DATA = 'REQ_BROKER_DATA';
export const GET_BROKER_LOGOS = 'GET_BROKER_LOGOS';
export const UNAUTH_BROKER_USER = 'UNAUTH_BROKER_USER';
export const FETCH_BROKER_SHIPPER_CARRIERS = 'FETCH_BROKER_SHIPPER_CARRIERS';
export const FETCH_CARRIERS = 'FETCH_CARRIERS';
export const FETCH_BROKER_FTL_CARRIERS = 'FETCH_BROKER_FTL_CARRIERS';
export const GET_BROKER_DASHBOARDS = 'GET_BROKER_DASHBOARDS';
export const REQ_CUSTOMER_LIST = 'REQ_CUSTOMER_LIST';

export const CLEAR_SELECTED_CUSTOMER = 'CLEAR_SELECTED_CUSTOMER';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const FETCH_BROKER_SHIPPER_RELATIONSHIPS = 'FETCH_BROKER_SHIPPER_RELATIONSHIPS';
export const FETCH_BROKER_SHIPPER_RELATIONSHIPS_FOR_HEADER = 'FETCH_BROKER_SHIPPER_RELATIONSHIPS_FOR_HEADER';
export const CREATE_BROKER_SHIPPER_RELATIONSHIP = 'CREATE_BROKER_SHIPPER_RELATIONSHIP';
export const EDIT_BROKER_SHIPPER_RELATIONSHIP = 'EDIT_BROKER_SHIPPER_RELATIONSHIP';

//carriers
export const SEARCH_FOR_CARRIERS = 'SEARCH_FOR_CARRIERS';
export const GET_CARRIER_TAGS = 'GET_CARRIER_TAGS';
export const GET_CARRIER_ROLES = 'GET_CARRIER_ROLES';
export const GET_CARRIER_RELATIONSHIPS = 'GET_CARRIER_RELATIONSHIPS';
export const GET_CARRIER_RELATIONSHIP = 'GET_CARRIER_RELATIONSHIP';
export const SELECT_CARRIER = 'SELECT_CARRIER';
export const GET_CARRIER = 'GET_CARRIER';
export const GET_CARRIER_BY_ID = 'GET_CARRIER_BY_ID';
export const CLEAR_CARRIER = 'CLEAR_CARRIER';
export const CLEAR_CARRIERS = 'CLEAR_CARRIERS';
export const POST_CARRIER_TAGS = 'POST_CARRIER_TAGS';
export const PUT_SHIPPER_CARRIER_GROUPS = 'PUT_SHIPPER_CARRIER_GROUPS';
export const GET_SHIPPER_CARRIER_RELATIONSHIPS = 'GET_SHIPPER_CARRIER_RELATIONSHIPS';
export const EDIT_SHIPPER_CARRIER_RELATIONSHIPS = 'EDIT_SHIPPER_CARRIER_RELATIONSHIPS';
export const CLEAR_SELECTED_SHIPPER_CARRIER = 'CLEAR_SELECTED_SHIPPER_CARRIER';
export const CLEAR_SELECTED_RELATIONSHIP = 'CLEAR_SELECTED_RELATIONSHIP';
export const GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS = 'GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS';
export const GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS = 'GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS';
export const GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS = 'GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS';
export const EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS = 'EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS';
export const CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS = 'CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS';
export const DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS = 'DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS';
export const SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS = 'SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS';
//carriers
export const GET_POWER_UNITS = 'GET_POWER_UNITS';
export const GET_POWER_UNIT = 'GET_POWER_UNIT';
export const CLEAR_POWER_UNIT = 'CLEAR_POWER_UNIT';
export const GET_POWER_UNIT_LOCATIONS = 'GET_POWER_UNIT_LOCATIONS';
export const GET_TRAILERS = 'GET_TRAILERS';
export const GET_TRAILER = 'GET_TRAILER';
export const CLEAR_TRAILER = 'CLEAR_TRAILER';
export const GET_DRIVERS = 'GET_DRIVERS';
export const CLEAR_TRAILER_FORM = 'CLEAR_TRAILER_FORM';
export const CLEAR_POWERUNIT_FORM = 'CLEAR_POWERUNIT_FORM';
export const FETCH_ALL_HOS = 'FETCH_ALL_HOS';

//documents
export const FETCH_ALL_DOCUMENTS = 'FETCH_ALL_DOCUMENTS';
export const FETCH_DOC_AUDIT_LOG = 'FETCH_DOC_AUDIT_LOG';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const ERROR_DOCUMENTS = 'ERROR_DOCUMENTS';
export const CLEAR_SEND_DOCUMENTS_FORM = 'CLEAR_SEND_DOCUMENTS_FORM';
export const FETCH_DOCUMENT_TYPES = 'FETCH_DOCUMENT_TYPES';
export const CREATE_CUSTOMER_DOCUMENT = 'CREATE_CUSTOMER_DOCUMENT';
export const SELECTED_DOCUMENT = 'SELECTED_DOCUMENT';
export const DOCUMENT_ADD = 'DOCUMENT_ADD';
export const UPDATE_CUSTOMER_DOCUMENT = 'UPDATE_CUSTOMER_DOCUMENT';
export const NEW_CUSTOMER_DOCUMENT_ADD = 'NEW_CUSTOMER_DOCUMENT_ADD';
export const FETCH_CARRIER_DOCUMENTS = 'FETCH_CARRIER_DOCUMENTS';
export const CREATE_CARRIER_DOCUMENT = 'CREATE_CARRIER_DOCUMENT';
export const UPDATE_CARRIER_DOCUMENT = 'UPDATE_CARRIER_DOCUMENT';
// inbox
export const GET_INBOX = 'GET_INBOX';
export const DELETEALL_INBOX = 'DELETEALL_INBOX';
export const READALL_INBOX = 'READALL_INBOX';
export const UNREADALL_INBOX = 'UNREADALL_INBOX';
export const UPDATE_INBOX_MESSAGE = 'UPDATE_INBOX_MESSAGE';
export const DELETE_INBOX_MESSAGE = 'DELETE_INBOX_MESSAGE';

//lanes
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_KPIS = 'FETCH_KPIS';
export const LANE_ERROR = 'LANE_ERROR';

//legal info
export const FETCH_UPLOADID = 'FETCH_UPLOADID';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TERMS_CONDITIONS = 'TERMS_CONDITIONS';
export const TERMS_ERROR = 'TERMS_ERROR';

//shipments
export const FETCH_ALLONDEMANDS = 'FETCH_ALLONDEMANDS';
export const FETCH_UNPAID_SHIPMENTS = 'FETCH_UNPAID_SHIPMENTS';
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const FETCH_SHIPMENTS = 'FETCH_SHIPMENTS';
export const FETCH_SHIPMENTS_AND_PERSIST = 'FETCH_SHIPMENTS_AND_PERSIST';
export const RESET_SHIPMENTS = 'RESET_SHIPMENTS';
export const FETCH_ONDEMAND = 'FETCH_ONDEMAND';
export const FETCH_SHIPMENT_QUOTES = 'FETCH_SHIPMENT_QUOTES';
export const DELETE_SHIPMENT_QUOTES = 'DELETE_SHIPMENT_QUOTES';
export const DISPATCH_SHIPMENT_QUOTE = 'DISPATCH_SHIPMENT_QUOTE';
export const CANCEL_ONDEMAND = 'CANCEL_ONDEMAND';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';
export const EDIT_SHIPMENT = 'EDIT_SHIPMENT';
export const SELECT_SHIPMENT = 'SELECT_SHIPMENT';
export const SELECT_SHIPMENT_FOR_FORM = 'SELECT_SHIPMENT_FOR_FORM';
export const CREATE_RFQ = 'CREATE_RFQ';
export const SELECT_RFQ = 'SELECT_RFQ';
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const SELECT_QUOTE = 'SELECT_QUOTE';
export const EDIT_QUOTE = 'EDIT_QUOTE';
export const AWARD_QUOTE = 'AWARD_QUOTE';
export const FETCH_ACCESSORIAL_CODES = 'FETCH_ACCESSORIAL_CODES';
export const FETCH_SHIPMENT_ACCESSORIAL_CODES = 'FETCH_SHIPMENT_ACCESSORIAL_CODES';
export const GET_PACKAGE_TYPES = 'GET_PACKAGE_TYPES';
export const GET_PIECE_TYPES = 'GET_PIECE_TYPES';
export const FETCH_PACKAGE_TYPES_SUCCESS = 'FETCH_PACKAGE_TYPES_SUCCESS';
export const FETCH_UPS_PACKAGE_TYPES_SUCCESS = 'FETCH_UPS_PACKAGE_TYPES_SUCCESS';
export const FETCH_USPS_PACKAGE_TYPES_SUCCESS = 'FETCH_USPS_PACKAGE_TYPES_SUCCESS';
export const CLEAR_NEWSHIPMENT_QUOTING_FORM = 'CLEAR_NEWSHIPMENT_QUOTING_FORM';
export const CLEAR_NEWSHIPMENT_FORM = 'CLEAR_NEWSHIPMENT_FORM';
export const CLEAR_NEWLOAD_FORM = 'CLEAR_NEWLOAD_FORM';
export const CLEAR_CONFIRM_SHIPMENT_FORM = 'CLEAR_CONFIRM_SHIPMENT_FORM';
export const CLEAR_EDIT_SHIPMENT_FORM = 'CLEAR_EDIT_SHIPMENT_FORM';
export const CLEAR_ADDRESS_LOOKUP_FORM = 'CLEAR_ADDRESS_LOOKUP_FORM';
export const SET_PAGE = 'SET_PAGE';
export const CLEAR_SELECTED_SHIPMENT = 'CLEAR_SELECTED_SHIPMENT';
export const CLEAR_SELECTED_QUOTE = 'CLEAR_SELECTED_QUOTE';
export const GET_NMFC_CODES = 'GET_NMFC_CODES';
export const GET_SHIPMENT_MODES = 'GET_SHIPMENT_MODES';
export const GET_SERVICE_LEVELS = 'GET_SERVICE_LEVELS';
export const GET_EQUIPMENT_TYPES = 'GET_EQUIPMENT_TYPES';
export const FETCH_BOL = 'FETCH_BOL';
export const FETCH_SHIPMENT_OFFERS = 'FETCH_SHIPMENT_OFFERS';
export const DELETE_OFFERS = 'DELETE_OFFERS';
export const GO_TO_STOPS = 'GO_TO_STOPS';
export const POST_SHIPMENT_MESSAGES = 'POST_SHIPMENT_MESSAGES';
export const CLEAR_SHIPMENT_MESSAGES = 'CLEAR_SHIPMENT_MESSAGES';
export const SET_HISTORY_FILTER = 'SET_HISTORY_FILTER';
export const GET_SHIPMENT_POST_OFFERS = 'GET_SHIPMENT_POST_OFFERS';
export const GET_SHIPPER_CREDIT = 'GET_SHIPPER_CREDIT';
export const UPDATE_BROKER_MARGIN = 'UPDATE_BROKER_MARGIN';
export const GET_HAZMAT_CODES = 'GET_HAZMAT_CODES';
export const TRIGGER_REBOOK = 'TRIGGER_REBOOK';
export const GET_SHIPPER_CARRIERS = 'GET_SHIPPER_CARRIERS';
export const FETCH_SHIPPER_LINE_ITEMS = 'FETCH_SHIPPER_LINE_ITEMS';
export const POST_SHIPPER_LINE_ITEM = 'POST_SHIPPER_LINE_ITEM';
export const PUT_SHIPPER_LINE_ITEM = 'PUT_SHIPPER_LINE_ITEM';
export const ALERT_ERROR = 'ALERT_ERROR';
export const GET_SHIPPER_DASHBOARDS = 'GET_SHIPPER_DASHBOARDS';
export const FETCH_SHIPPER_FTL_CARRIERS = 'FETCH_SHIPPER_FTL_CARRIERS';
export const FETCH_RATE_CON = 'FETCH_RATE_CON';
export const SELECT_CARRIER_RATE = 'SELECT_CARRIER_RATE';
export const CLEAR_SELECT_CARRIER_RATE = 'CLEAR_SELECT_CARRIER_RATE';
export const SELECT_BROKER_RFQ = 'SELECT_BROKER_RFQ';
export const POPULATE_RECIPIENT_EMAILS = 'POPULATE_RECIPIENT_EMAILS';
export const INITIAL_QUOTE_LOADING = 'INITIAL_QUOTE_LOADING';
export const TRIGGER_MARKETPLACE_REDIRECT = 'TRIGGER_MARKETPLACE_REDIRECT';
export const DISABLE_SIGNUP_SUBMIT = 'DISABLE_SIGNUP_SUBMIT';
export const ENABLE_SIGNUP_SUBMIT = 'ENABLE_SIGNUP_SUBMIT';
export const GET_APPOINTMENT_TYPES = 'GET_APPOINTMENT_TYPES';
export const INITIAL_QUOTE_VALUES = 'INITIAL_QUOTE_VALUES';
export const INITIAL_SHIPMENT_VALUES = 'INITIAL_SHIPMENT_VALUES';
export const SET_CLONE_TRIGGER = 'SET_CLONE_TRIGGER';
export const SUCCESSFUL_DISPATCH = 'SUCCESSFUL_DISPATCH';
export const GET_REP_ROLES = 'GET_REP_ROLES';
export const FETCH_REPS = 'FETCH_REPS';
export const POST_REPS = 'POST_REPS';
export const PUT_REPS = 'PUT_REPS';
export const GET_CHARGE_CATEGORIES = 'GET_CHARGE_CATEGORIES';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAG = 'FETCH_TAG';
export const CLEAR_TAG = 'CLEAR_TAG';
export const SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS = 'SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS';
export const NEW_TRUCK_LOCATION = 'NEW_TRUCK_LOCATION';
export const GET_AUTOMATED_EVENTS = 'GET_AUTOMATED_EVENTS';
export const SELECT_AUTOMATED_EVENT = 'SELECT_AUTOMATED_EVENT';
export const SHIPMENTS_ERROR = 'SHIPMENTS_ERROR';
export const GET_SHIPMENT_STATUSES = 'GET_SHIPMENT_STATUSES';

// shipmentdetails
export const GET_SHIPMENT_BY_ID = 'GET_SHIPMENT_BY_ID';
export const PUT_SHIPMENT_DETAILS = 'EDIT_SHIPMENT_DETAILS';
export const GET_SHIPMENT_MESSAGES = 'GET_SHIPMENT_MESSAGES';
export const POST_NEW_STOP = 'POST_NEW_STOP';
export const POST_NEW_LINEITEM = 'POST_NEW_LINEITEM';
export const DELETE_SHIPMENT_STOP = 'DELETE_SHIPMENT_STOP';
export const DELETE_SHIPMENT_LINEITEM = 'DELETE_SHIPMENT_LINEITEM';
export const CANCEL_SHIPMENT = 'CANCEL_SHIPMENT';
export const SHARE_LINK = 'SHARE_LINK';
export const CLEAR_SHIPMENT_STATE = 'CLEAR_SHIPMENT_STATE';
export const GET_NOTES = 'GET_NOTES';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const NOTES_LOADING = 'NOTES_LOADING';
export const FETCH_BREADCRUMBS = 'FETCH_BREADCRUMBS';
export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS';
export const MANUAL_RATE_FINANCIALS = 'MANUAL_RATE_FINANCIALS';
export const FETCH_STOPS = 'FETCH_STOPS';
export const STOPS_LOADING = 'STOPS_LOADING';
export const FETCH_STOP = 'FETCH_STOP';
export const CLEAR_STOP = 'CLEAR_STOP';
export const SELECT_FINANCIALS = 'SELECT_FINANCIALS';
export const SELECT_RATE_TABLE = 'SELECT_RATE_TABLE';
export const CLEAR_RATE_TABLE = 'CLEAR_RATE_TABLE';
export const GET_SHIPMENT_CARRIER = 'GET_SHIPMENT_CARRIER';

// timeline
export const NEW_SHIPMENT_TIMELINE_EVENT = 'NEW_SHIPMENT_TIMELINE_EVENT';
export const EDIT_SHIPMENT_TIMELINE_EVENT = 'EDIT_SHIPMENT_TIMELINE_EVENT';
export const POST_SHIPMENT_TIMELINE_EVENT = 'POST_SHIPMENT_TIMELINE_EVENT';
export const GET_SHIPMENT_TIMELINE = 'GET_SHIPMENT_TIMELINE';
export const DELETE_SHIPMENT_TIMELINE_EVENT = 'DELETE_SHIPMENT_TIMELINE_EVENT';
export const PUT_TIMELINE_EVENT = 'PUT_TIMELINE_EVENT';
export const CLEAR_TIMELINE_ADD_FORM = 'CLEAR_TIMELINE_ADD_FORM';

//tenders

export const CREATE_TENDER = 'CREATE_TENDER';
export const GET_TENDERS_SUCCESS = 'GET_TENDERS_SUCCESS';
export const REVOKE_TENDER_SUCCESS = 'REVOKE_TENDER_SUCCESS';

//settings
export const REQ_SETTING_DATA = 'REQ_SETTING_DATA';
export const RESET_SETTINGS_ERR = 'RESET_SETTINGS_ERR';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const CLEAR_PASSWORD_FORM = 'CLEAR_PASSWORD_FORM';

//spinner
export const REQ_DATA = 'REQ_DATA';
export const REQ_DATA_ADDRESS_BOOK = 'REQ_DATA_ADDRESS_BOOK';
export const REQ_CARRIER_DATA = 'REQ_CARRIER_DATA';

//users
export const DELETE_EMPLOYEE_ACCT = 'DELETE_EMPLOYEE_ACCT';
export const USER_ACCT_ERROR = 'USER_ACCT_ERROR';
export const FETCH_SHIPPER_USER = 'FETCH_SHIPPER_USER';
export const FETCH_SHIPPER_USERS = 'FETCH_SHIPPER_USERS';
export const REQ_USER_DATA = 'REQ_USER_DATA';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PUT_USER = 'PUT_USER';
export const PUT_USER_ERROR = 'PUT_USER_ERROR';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const GET_IDENTIFYING_CODES = 'GET_IDENTIFYING_CODES';
export const GET_COMPANY_PREFERENCES = 'GET_COMPANY_PREFERENCES';
export const GET_TASKS = 'GET_TASKS';
export const GET_CASES = 'GET_CASES';
export const GET_TASKS_BY_CASE = 'GET_TASKS_BY_CASE';
export const GET_CASE_NOTES = 'GET_CASE_NOTES';
export const LOADING_TASKS_FOR_CASE = 'LOADING_TASKS_FOR_CASE';
export const SELECT_TASK = 'SELECT_TASK';
export const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';
export const SELECT_CASE = 'SELECT_CASE';
export const CLEAR_SELECTED_CASE = 'CLEAR_SELECTED_CASE';

//V2 USERS
export const EDIT_USER = 'EDIT_USER';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const CREATE_COMPANY_USER = 'CREATE_COMPANY_USER';
export const EDIT_COMPANY_USER = 'EDIT_COMPANY_USER';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SELECT_USER = 'SELECT_USER';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const GET_NOTIFICATION_PREFERENCES = 'GET_NOTIFICATION_PREFERENCES';

//marketplace
export const SELECT_SPOT_NEGOTIATION_ARRAY = 'SELECT_SPOT_NEGOTIATION_ARRAY';
export const SELECT_SPOT_NEGOTIATION = 'SELECT_SPOT_NEGOTIATION';
export const SET_COMMUNICATION_TAB = 'SET_COMMUNICATION_TAB';
export const SET_QUOTES_TAB = 'SET_QUOTES_TAB';
export const FETCH_CUSTOMER_NEGOTIATION = 'FETCH_CUSTOMER_NEGOTIATION';
export const FETCH_SPOT_NEGOTIATION_QUOTES = 'FETCH_SPOT_NEGOTIATION_QUOTES';
export const GET_SPOT_NEGOTIATION_EMAIL_PREVIEW = 'GET_SPOT_NEGOTIATION_EMAIL_PREVIEW';
export const FETCH_SPOT_NEGOTIATION_MESSAGES = 'FETCH_SPOT_NEGOTIATION_MESSAGES';
export const GET_BIDDING_SHIPMENTS = 'GET_BIDDING_SHIPMENTS';
export const MARKETPLACE_LOADING = 'MARKETPLACE_LOADING';
export const GET_LOADBOARD_SHIPMENT = 'GET_LOADBOARD_SHIPMENT';

//products
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const REQ_PRODUCTS_DATA = 'REQ_PRODUCTS_DATA';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';

//integrations
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_AUTOMATED_VENDORS = 'FETCH_AUTOMATED_VENDORS';
export const FETCH_QUICKBOOKS_CUSTOMERS = 'FETCH_QUICKBOOKS_CUSTOMERS';
export const GET_SHIPMENT_INVOICES = 'GET_SHIPMENT_INVOICES';
export const FETCH_QUICKBOOKS_VENDORS = 'FETCH_QUICKBOOKS_VENDORS';
export const FETCH_EXPENSE_CLASSES = 'FETCH_EXPENSE_CLASSES';
export const FETCH_EXPENSE_ACCOUNTS = 'FETCH_EXPENSE_ACCOUNTS';
export const FETCH_PAYMENT_TERMS = 'FETCH_PAYMENT_TERMS';
export const FEDEX_REGISTRATION_SUCCESS = 'FEDEX_REGISTRATION_SUCCESS';
export const GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS = 'GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS';
export const GET_CONNECTED_FMS = 'GET_CONNECTED_FMS';

// User dashboard config
export const USER_DASHBOARD_CONFIG_REQUEST_PENDING = 'USER_DASHBOARD_CONFIG_REQUEST_PENDING';
export const USER_DASHBOARD_CONFIG_REQUEST_SUCCESS = 'USER_DASHBOARD_CONFIG_REQUEST_SUCCESS';
export const USER_DASHBOARD_CONFIG_UPDATE_PENDING = 'USER_DASHBOARD_CONFIG_UPDATE_PENDING';
export const USER_DASHBOARD_CONFIG_UPDATE_SUCCESS = 'USER_DASHBOARD_CONFIG_UPDATE_SUCCESS';

// User dashboard config
export const USER_LOADBOARD_CONFIG_REQUEST_PENDING = 'USER_LOADBOARD_CONFIG_REQUEST_PENDING';
export const USER_LOADBOARD_CONFIG_REQUEST_SUCCESS = 'USER_LOADBOARD_CONFIG_REQUEST_SUCCESS';
export const USER_LOADBOARD_CONFIG_UPDATE_PENDING = 'USER_LOADBOARD_CONFIG_UPDATE_PENDING';
export const USER_LOADBOARD_CONFIG_UPDATE_SUCCESS = 'USER_LOADBOARD_CONFIG_UPDATE_SUCCESS';
