import {Title} from '@shipwell/shipwell-ui';
import {OrderStatus} from '@shipwell/corrogo-sdk';
import {compose} from 'recompose';

import {useTransportationOrders} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import ContainerFinancialsCard from 'App/containers/shipments/components/FinancialsView/ContainerFinancialsCard';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';

const FinancialsComingSoon = () => (
  <div className="flex flex-col items-center justify-center pt-8 text-sw-disabled">
    <Title variant="emptyStateHeader">Financials Coming Soon!</Title>
  </div>
);

const FinancialsEmptyState = () => (
  <div className="flex flex-col items-center justify-center gap-4 p-8 text-sw-disabled">
    <Title variant="emptyStateHeader">No Carrier Financials</Title>
    Once a carrier accepts a tender the financials will display here for each container.
  </div>
);

const FinancialsContainer = ({shipmentId}: {shipmentId: string}) => {
  const {queryInfo, getTransportationOrdersOfStatus} = useTransportationOrders(shipmentId);

  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }

  const acceptedTransportationOrders = getTransportationOrdersOfStatus(OrderStatus.Accepted) || [];

  return acceptedTransportationOrders.length > 0 ? (
    <div className="px-4">
      {acceptedTransportationOrders.map((acceptedTransportationOrder) => (
        <div key={acceptedTransportationOrder.id} className="mb-4 rounded bg-sw-background px-4 py-2">
          <div className="bg-sw-background pb-2 font-bold">{acceptedTransportationOrder.service_provider.name}</div>
          {acceptedTransportationOrder.order_items.map((orderItem, index) => (
            <div key={orderItem.id} className="pb-2">
              <ContainerFinancialsCard
                shipmentId={shipmentId}
                transportationOrderId={acceptedTransportationOrder.id}
                orderItemId={orderItem.id}
                index={index}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : (
    <FinancialsEmptyState />
  );
};

export default compose<{shipmentId: string}, {shipmentId: string}>(
  withFlags('modeDrayageFinancials'),
  withConditionalFallback(
    ({modeDrayageFinancials}: {modeDrayageFinancials: boolean}) => !modeDrayageFinancials,
    FinancialsComingSoon
  )
)(FinancialsContainer);
