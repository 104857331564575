import {useMemo} from 'react';
import {createColumnHelper} from '@tanstack/react-table';
import {
  ContractRateTableBand,
  ContractRateTableBandCalculationMeasurementEnum,
  ContractRateTableDistanceUnitEnum,
  ContractRateTableBandRateApplicationTypeEnum,
  RateAssessmentMeasurement,
  ContractRateCurrencyEnum,
  ContractRateTableWeightUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {toTitleCase} from 'App/utils/string';
import {formatCurrency} from 'App/utils/internationalConstants';
import {useGetContractLanes} from 'App/data-hooks/contracts/useGetContractLanes';
import {CorrectedContractLane} from 'App/api/contracts/typed';

export type ShipwellContractLaneTable = CorrectedContractLane & {rateBand?: ContractRateTableBand};

const columnHelper = createColumnHelper<ShipwellContractLaneTable | undefined>();

enum PluralizedDistanceDisplayUnits {
  MILE = 'Miles',
  KILOMETER = 'KM'
}

enum DistanceDisplayUnits {
  MILE = 'Mile',
  KILOMETER = 'KM'
}

enum WeightDisplayUnits {
  TON = 'Ton',
  LB = 'LB',
  KG = 'KG',
  CWT = 'CWT'
}

const getPluralizedDistanceDisplayUnit = (distanceUnit: keyof typeof PluralizedDistanceDisplayUnits) =>
  PluralizedDistanceDisplayUnits[distanceUnit] || '--';

const getDistanceDisplayUnit = (distanceUnit: keyof typeof DistanceDisplayUnits) =>
  DistanceDisplayUnits[distanceUnit] || '--';

const getWeightDisplayUnit = (weightUnit: keyof typeof WeightDisplayUnits) => WeightDisplayUnits[weightUnit] || '--';

const getbandCalculationValue = ({
  bandCalculation,
  weightUnit,
  distanceUnit
}: {
  bandCalculation?: ContractRateTableBandCalculationMeasurementEnum;
  weightUnit?: ContractRateTableWeightUnitEnum;
  distanceUnit?: ContractRateTableDistanceUnitEnum;
}) => {
  if (!bandCalculation) return '--';

  const bandCalculationTitle = toTitleCase(bandCalculation);
  const pluralizedDistanceDisplayUnit = distanceUnit ? getPluralizedDistanceDisplayUnit(distanceUnit) : '--';

  switch (bandCalculation) {
    case ContractRateTableBandCalculationMeasurementEnum.Distance: {
      return `${bandCalculationTitle} (${pluralizedDistanceDisplayUnit})`;
    }
    case ContractRateTableBandCalculationMeasurementEnum.Weight: {
      return `${bandCalculationTitle} (${
        !weightUnit ? '--' : weightUnit === ContractRateTableWeightUnitEnum.Kg ? 'KG' : 'LB'
      })`;
    }
    default: {
      return bandCalculationTitle;
    }
  }
};

const getRateApplicationType = ({
  rateApplication,
  rateAssessmentMeasurement,
  weightUnit,
  distanceUnit
}: {
  rateApplication?: ContractRateTableBandRateApplicationTypeEnum;
  rateAssessmentMeasurement?: RateAssessmentMeasurement;
  weightUnit?: ContractRateTableWeightUnitEnum;
  distanceUnit?: ContractRateTableDistanceUnitEnum;
}) => {
  if (!rateApplication) return '--';

  const rateApplicationTitle = toTitleCase(rateApplication);
  const distanceDisplayUnit = distanceUnit ? getDistanceDisplayUnit(distanceUnit) : '--';
  const weightDisplayUnit = weightUnit ? getWeightDisplayUnit(weightUnit) : '--';

  if (rateApplication !== ContractRateTableBandRateApplicationTypeEnum.RatePerUnit) return rateApplicationTitle;

  switch (rateAssessmentMeasurement) {
    case RateAssessmentMeasurement.Distance: {
      return `Per ${distanceDisplayUnit}`;
    }
    case RateAssessmentMeasurement.Weight: {
      return `Per ${weightDisplayUnit}`;
    }
    // need to cast until sdk is updated
    case 'PER_HOUR' as RateAssessmentMeasurement: {
      return 'Per Hour';
    }
    default: {
      return 'Per Handling Unit';
    }
  }
};

export const useRateTableOverview = ({
  contractId,
  rateCurrency
}: {
  contractId?: string | null;
  rateCurrency?: ContractRateCurrencyEnum;
}) => {
  // this hook uses infinite scroll
  const {handleScroll, pages, isFetchingNextPage, isLoading} = useGetContractLanes({
    contractId
  });

  // flattened rate bands for display in react table
  const flatData = useMemo(
    () =>
      pages?.flatMap((page) =>
        page.results?.flatMap((result) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {rate_bands, ...rateInfo} = result.rate_info || {};
          return result.rate_info?.rate_bands?.length
            ? // if rate bands exist the previous flatMap will flatten the rate_bands array
              result.rate_info?.rate_bands?.map((rateBand) => ({...result, rate_info: rateInfo, rateBand}))
            : // if not then it's a non-band rate and we should use the rate from rate_info
              [{...result, rate_info: rateInfo}];
        })
      ) ?? [],
    [pages]
  );

  const defaultColumns = [
    columnHelper.accessor('origin.formatted_address', {
      id: 'origin',
      header: 'Origin',
      cell: (info) => info.renderValue() || '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('destination.formatted_address', {
      id: 'destination',
      header: 'Destination',
      cell: (info) => info.renderValue() || '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('is_round_trip', {
      id: 'is_round_trip',
      header: 'Round Trip',
      cell: (info) => (info.getValue() ? 'Y' : '--'),
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('modes', {
      id: 'modes',
      header: 'Mode',
      cell: (info) => {
        const modes = info.getValue();

        return modes?.length ? modes.map((mode) => mode.code).join(', ') : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('equipment_types', {
      id: 'equipment_types',
      header: 'Equipment',
      cell: (info) => {
        const equipmentTypes = info.getValue();

        return equipmentTypes?.length ? equipmentTypes.map((equipmentType) => equipmentType.name).join(', ') : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rate_info.distance', {
      id: 'distance',
      header: 'Distance',
      cell: (info) => info.renderValue() || '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rate_info.distance_unit', {
      id: 'distance_unit',
      header: 'Distance Unit',
      cell: (info) => info.renderValue() || '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rate_info.minimum_rate', {
      id: 'minimum_rate',
      header: 'Minimum',
      cell: (info) => {
        const minimumRate = info.getValue();

        return minimumRate ? formatCurrency(minimumRate, rateCurrency || 'USD') : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rate_info.band_calculation_measurement', {
      id: 'band_calculation_measurement',
      header: 'Band Calculation',
      cell: (info) => {
        const hasRateBand = !!info.row.original?.rateBand;
        const bandCalculation = info.getValue();
        const weightUnit = info.row.original?.rate_info?.weight_unit;
        const distanceUnit = info.row.original?.rate_info?.distance_unit;

        return hasRateBand ? getbandCalculationValue({bandCalculation, weightUnit, distanceUnit}) : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rateBand.range_start', {
      id: 'range_start',
      header: 'Start Range',
      cell: (info) => info.renderValue() ?? '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rateBand.range_end', {
      id: 'range_end',
      header: 'End Range',
      cell: (info) => info.renderValue() ?? '--',
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rateBand.rate', {
      id: 'rate',
      header: 'Rate',
      cell: (info) => {
        const bandRate = info.getValue();
        const infoRate = info.row.original?.rate_info?.rate;
        //if rateBand lacks rate pull rate info from the rate_info
        return bandRate || infoRate ? formatCurrency(bandRate || infoRate, rateCurrency || 'USD') : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    }),
    columnHelper.accessor('rateBand.rate_application_type', {
      id: 'rate_application_type',
      header: 'Rate Application',
      cell: (info) => {
        const rateApplication = info.getValue();
        const rateAssessmentMeasurement = info.row.original?.rate_info?.rate_assessment_measurement;
        const weightUnit = info.row.original?.rate_info?.weight_unit;
        const distanceUnit = info.row.original?.rate_info?.distance_unit;
        // the rate application could also be a simple type
        const infoRateType = info.row.original?.rate_info?.rate_type;

        return rateApplication
          ? getRateApplicationType({rateApplication, rateAssessmentMeasurement, weightUnit, distanceUnit})
          : infoRateType
          ? toTitleCase(infoRateType)
          : '--';
      },
      enableSorting: false,
      size: 'auto' as unknown as number
    })
  ];

  return {
    defaultColumns,
    contractLaneInfiniteScroll: {handleScroll, isFetchingNextPage, isLoading},
    flatData
  };
};
