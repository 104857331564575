import moment from 'moment-timezone';
import {
  ProductPackageType,
  ShipmentLineItemLengthUnitEnum,
  ShipmentLineItemWeightUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {truckFTLIcon, truckLTLIcon, truckVLTLIcon, truckVIcon, truckRIcon, truckFIcon} from '../assets/icons';
import {generateRandomId, formatHrMinSec, formatTime} from 'App/utils/globals';

export const shipmentModeIcons = {
  FTL: truckFTLIcon,
  LTL: truckLTLIcon,
  VLTL: truckVLTLIcon
};

export const shipmentEquipmentIcons = {
  1: truckVIcon,
  2: truckRIcon,
  3: truckFIcon,
  4: truckVIcon,
  5: truckVIcon,
  6: truckVIcon,
  7: truckVIcon,
  8: truckVIcon,
  9: truckVIcon,
  10: truckVIcon,
  11: truckVIcon,
  12: truckVIcon
};

export const defaultStopFields = {
  schedule_time_toggle: 1,
  description: '',
  location: {
    address: {},
    location_type: {id: 1, name: 'Business (with dock or forklift)'},
    location_name: ''
  },
  planned_date: moment().format('YYYY-MM-DD'),
  planned_time_window_start: formatTime('8:00:00', false, null, formatHrMinSec),
  planned_time_window_end: formatTime('18:00:00', false, null, formatHrMinSec),
  accessorials: []
};

export const defaultLineItemFields = {
  description: '',
  package_type: ProductPackageType.Plt,
  length_unit: ShipmentLineItemLengthUnitEnum.In,
  weight_unit: ShipmentLineItemWeightUnitEnum.Lb,
  length: 40,
  width: 48,
  stackable: false
};

export const initialFormValues = {
  customer_reference_number: generateRandomId(),
  customer: '',
  mode: [],
  equipment_type: [],
  stops: [
    {
      is_pickup: true,
      is_dropoff: false,
      rKey: 1,
      ...defaultStopFields
    },
    {
      is_pickup: false,
      is_dropoff: true,
      rKey: 2,
      ...defaultStopFields
    }
  ],
  line_items: [defaultLineItemFields],
  total_shipment_weight_unit: 'LB',
  manual_total_weight: false
};
