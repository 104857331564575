import capitalize from 'lodash/capitalize';
import {mixed, number, object, string} from 'yup';
import {validatePhoneNumber} from './globals';

export const validateMinMaxNumber = (min: number, max: number, fieldNameSnakeCase: string) => {
  const fieldNameCapitalized = capitalize(fieldNameSnakeCase.split('_').join(' '));
  return number()
    .min(min, `${fieldNameCapitalized} must be greater than ${(min - 1).toLocaleString()}.`)
    .max(max - 1, `${fieldNameCapitalized} must be less than ${max.toLocaleString()}.`)
    .typeError(`${fieldNameCapitalized} must be a number.`);
};

export const nullableEmailSchemaField = () => string().nullable().email('A valid email is required.');
export const nullablePhoneNumberSchemaField = (fieldId: string) =>
  string()
    .nullable()
    .test(fieldId, 'Invalid phone number.', (value) => {
      if (value) {
        return validatePhoneNumber(value);
      }
      return true;
    });

export const AddressSchema = object().nullable().shape({
  address_1: string().nullable(),
  address_2: string().nullable(),
  city: string().nullable(),
  state_province: mixed().nullable(),
  postal_code: mixed().nullable(),
  country: mixed().nullable(),
  latitude: mixed().nullable(),
  longitude: mixed().nullable()
});
