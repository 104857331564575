import {
  REQ_DATA,
  FETCH_ALL_DOCUMENTS,
  FETCH_DOC_AUDIT_LOG,
  FETCH_CARRIER_DOCUMENTS,
  FETCH_DOCUMENT,
  ERROR_DOCUMENTS,
  FETCH_DOCUMENT_TYPES,
  DOCUMENT_ADD,
  NEW_CUSTOMER_DOCUMENT_ADD,
  SELECTED_DOCUMENT,
  CREATE_DOCUMENT
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  documents: null,
  carrierDocuments: null,
  document: null,
  documentTypes: [],
  addDocument: {
    description: null,
    file: null,
    filename: null,
    id: null
  },
  auditLog: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQ_DATA:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case FETCH_ALL_DOCUMENTS:
      return {
        ...state,
        isLoading: false,
        error: false,
        documents: action.payload
      };

    case FETCH_DOC_AUDIT_LOG:
      return {
        ...state,
        auditLog: action.payload
      };

    case FETCH_CARRIER_DOCUMENTS:
      return {
        ...state,
        isLoading: false,
        error: false,
        carrierDocuments: action.payload
      };

    case FETCH_DOCUMENT:
      return {
        ...state,
        isLoading: false,
        error: false,
        document: action.payload
      };

    case ERROR_DOCUMENTS:
      return {
        ...state,
        error: true,
        isLoading: false
      };

    case FETCH_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload
      };

    case DOCUMENT_ADD:
      return {
        ...state,
        error: false,
        isLoading: false,
        selectedDocument: INITIAL_STATE.addDocument
      };

    case NEW_CUSTOMER_DOCUMENT_ADD:
      return {
        ...state,
        documents: null,
        selectedDocument: INITIAL_STATE.addDocument
      };

    case SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: action.payload
      };

    case CREATE_DOCUMENT:
      return {
        ...state,
        documents: null,
        isLoading: false,
        error: false
      };

    default:
      return state;
  }
}
