import {DeprecatedButton, FormikSelect, FormikTextInput, SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {Field, FieldArray} from 'formik';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {fetchIdentifyingCodes} from 'App/containers/userCompany/actions/async';
import './styles.scss';

/**
 * Company Identifying Codes
 * @param {*} props
 */
const CompanyIdentifyingCodeFields = (props) => {
  const {values, isEditable, dispatch, identifyingCodes} = props;

  /**
   * Request identifying codes
   */
  const getIdentifyingCodes = useCallback(() => {
    try {
      dispatch(fetchIdentifyingCodes());
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  /** Fetch identifying codes on mount */
  useEffect(() => {
    if (!identifyingCodes?.length) {
      getIdentifyingCodes();
    }
  }, [identifyingCodes, getIdentifyingCodes]);

  const codeOptions = useMemo(
    () => identifyingCodes.map((code) => ({value: code.id, label: code.name})),
    [identifyingCodes]
  );

  if (!isEditable) {
    codeOptions.reverse();
  }

  if (!values.identifying_codes?.length && !values?.shipwell_vendor?.identifying_codes?.length) {
    return <span>No identifying codes.</span>;
  }

  return (
    <div className="company-idc-fields-container">
      {values.identifying_codes?.length ? (
        <FieldArray
          name="identifying_codes"
          render={(arrayHelpers) =>
            values.identifying_codes.map((_, index) => (
              <div
                className={classNames('field-array-grid', {
                  'company-idc-field--disabled': !isEditable
                })}
                key={index}
              >
                <div className="field-grid">
                  <Field
                    simpleValue
                    disabled={!isEditable}
                    required
                    clearable={false}
                    name={`identifying_codes.${index}.type`}
                    label="Code Type"
                    isOptionDisabled={() => !isEditable}
                    options={codeOptions}
                    component={FormikSelect}
                  />
                  <div className="company-idc-fields__value">
                    <Field
                      disabled={!isEditable}
                      required
                      name={`identifying_codes.${index}.value`}
                      label="Code"
                      component={FormikTextInput}
                    />
                  </div>
                </div>
                {isEditable ? (
                  <DeprecatedButton
                    variant="icon"
                    className="trashOutlinedButton"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <SvgIcon name="TrashOutlined" />
                  </DeprecatedButton>
                ) : null}
              </div>
            ))
          }
        />
      ) : null}
      {values.shipwell_vendor?.identifying_codes?.length
        ? values.shipwell_vendor.identifying_codes
            .filter((code) => code.type === 'SCAC')
            .map((code, index) => (
              <div
                className={classNames('field-array-grid', {
                  'company-idc-field--disabled': true
                })}
                key={index}
              >
                <div className="field-grid">
                  <Field disabled value={code.type} label="Code Type" component={FormikTextInput} />
                  <div className="company-idc-fields__value">
                    <Field disabled value={code.value} label="Code" component={FormikTextInput} />
                  </div>
                </div>
              </div>
            ))
        : null}
    </div>
  );
};

CompanyIdentifyingCodeFields.defaultProps = {
  isEditable: true,
  identifyingCodes: [],
  values: {
    identifying_codes: []
  }
};

CompanyIdentifyingCodeFields.propTypes = {
  isEditable: PropTypes.bool,
  identifyingCodes: PropTypes.array,
  values: PropTypes.shape({
    identifying_codes: PropTypes.array,
    shipwell_vendor: PropTypes.shape({
      identifying_codes: PropTypes.array
    })
  }),
  dispatch: PropTypes.func
};

export default connect((state) => ({
  identifyingCodes: state.company?.constants?.identifyingCodes
}))(CompanyIdentifyingCodeFields);
