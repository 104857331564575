import type {Contact} from '@shipwell/corrogo-sdk';
import {DisplayValue} from '@shipwell/shipwell-ui';

const ContactDisplay = ({contact, index}: {contact?: Contact; index: number}) => (
  <DisplayValue label={`Contact #${index + 1}`} className="pb-2">
    <div>{contact?.person_name || '--'}</div>
    <div>{contact?.email || '--'}</div>
    <div>{contact?.phone || '--'}</div>
  </DisplayValue>
);

export default ContactDisplay;
