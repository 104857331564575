import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Tenders
 */
export async function getTenders(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersGet(opts, apiCallback(resolve, reject));
  });
}

export async function createTender(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersPost(body, apiCallback(resolve, reject));
  });
}

export async function acceptTender(tenderId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersTenderIdAcceptPost(tenderId, apiCallback(resolve, reject));
  });
}

export async function rejectTender(tenderId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersTenderIdRejectPost(tenderId, opts, apiCallback(resolve, reject));
  });
}

export async function revokeTender(tenderId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersTenderIdRevokePost(tenderId, apiCallback(resolve, reject));
  });
}

export async function getExternalTender(tenderId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.tendersTenderIdAcceptRejectDetailsGet(tenderId, apiCallback(resolve, reject));
  });
}
