import {Component} from 'react';
import {connect} from 'react-redux';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {Field, reduxForm, FormSection} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ReportInaccurateForm from './ReportInaccurateForm';
import renderField from 'App/formComponents/renderField';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import renderDateField from 'App/formComponents/renderDateField';
import * as actions from 'App/actions/vendors';
import {scrollToFirstErrorField} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect((state) => ({}), {...actions})
export class VendorsInsurance extends Component {
  constructor(props) {
    super(props);

    this.submitReport = this.submitReport.bind(this);
    this.state = {
      edit: false,
      showReportModal: false
    };
  }

  submitReport(attrs) {
    const body = attrs;
    let usdot;
    if (this.props.shipwellVendor) {
      for (let i = 0; i < this.props.shipwellVendor.identifying_codes.length; i++) {
        if (this.props.shipwellVendor.identifying_codes[i].type === 'USDOT') {
          usdot = this.props.shipwellVendor.identifying_codes[i].value;
        }
      }
    }
    body.usdot = usdot;

    this.props.reportInaccurateCarrierInfo(body).then((response) => {
      if (response.status === 200) {
        this.overlay.setState({show: false});
      }
    });
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }

  renderReadOnly() {
    const {isEditable, shipwellVendor} = this.props;

    return (
      <div className="vendors__shipwell-vendor">
        {isEditable && (
          <div className="vendors__edit">
            <a
              role="button"
              onClick={() => {
                this.setState({edit: true});
              }}
            >
              <i className="flaticon-edit action-link" />
            </a>
          </div>
        )}
        <Grid container spacing={8}>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Provider Name</b>
            </p>
            <p>
              {shipwellVendor && shipwellVendor.insurance_provider_name ? shipwellVendor.insurance_provider_name : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Provider Phone</b>
            </p>
            <p>
              {shipwellVendor &&
              shipwellVendor.insurance_provider_phone &&
              this.hasNumber(shipwellVendor.insurance_provider_phone)
                ? getPhoneHyperlink(shipwellVendor.insurance_provider_phone)
                : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Policy Number</b>
            </p>
            <p>
              {shipwellVendor && shipwellVendor.insurance_policy_number ? shipwellVendor.insurance_policy_number : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Expiration Date</b>
            </p>
            <p>
              {shipwellVendor &&
              shipwellVendor.insurance_expires_at &&
              moment(shipwellVendor.insurance_expires_at).isValid()
                ? moment(shipwellVendor.insurance_expires_at).format('ddd MMM D, YYYY')
                : '-'}
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderForm() {
    return (
      <FormSection name="vendor.shipwell_vendor">
        <div className="vendors__shipwell-vendor">
          <Grid container spacing={8}>
            <Grid item sm={6} xs={12} className="vendors__insurance">
              <Field label="Provider Name" name="insurance_provider_name" component={renderField} />
            </Grid>
            <Grid item sm={6} xs={12} className="vendors__insurance">
              <Field
                label="Provider Phone"
                name="insurance_provider_phone"
                component={renderPhoneNumberField}
                type="text"
                placeholder="Phone"
              />
            </Grid>
            <Grid item sm={6} xs={12} className="vendors__insurance">
              <Field label="Policy Number" name="insurance_policy_number" component={renderField} />
            </Grid>
            <Grid item sm={6} xs={12} className="vendors__insurance">
              <Field label="Expiration Date" name="insurance_expires_at" component={renderDateField} />
            </Grid>
          </Grid>
        </div>
      </FormSection>
    );
  }

  render() {
    const {manualEntry, isEditable} = this.props;
    const reportInaccurateInfo = (
      <Popover id="popover-cancel" className="popover-cancel-shipment">
        <ReportInaccurateForm onSubmit={this.submitReport} />
      </Popover>
    );
    return (
      <div>
        <Paper>
          <div className="vendors__header">
            <h3>
              <b>Insurance</b>
            </h3>
            {!manualEntry && !isEditable && (
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={reportInaccurateInfo}
                ref={(overlay) => (this.overlay = overlay)}
              >
                <a role="button" className="text-danger">
                  <b>Report Inaccurate Information</b>
                </a>
              </OverlayTrigger>
            )}
          </div>
          <Element name={`position-vendor.shipwell_vendor.name`} />
          {this.state.edit || this.props.manualEntry ? this.renderForm() : this.renderReadOnly()}
        </Paper>
      </div>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(VendorsInsurance);
