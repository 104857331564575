import {ConnectionFormCard} from './connectionFormCard/connectionCard';
import {DevicesListCard} from './devicesListCard/devicesListCard';
import PageHeader from 'App/common/pageHeader';
import {useTiveService} from './useTiveService/useTiveService';

export function TiveDetailsPage() {
  const service = useTiveService();

  return (
    <div className="w-full">
      <PageHeader title="Integrations" backRoute="/company/integrations" />

      <div className="flex flex-col items-center p-5">
        <div className="w-full max-w-[1600px]">
          <div className="flex flex-col gap-4">
            <ConnectionFormCard
              credentials={service.data?.credentials}
              isFetching={service.isFetching}
              isSaving={service.isSaving}
              onConnect={service.connect}
            />

            {!!service.data?.defaultAccount && <DevicesListCard accountId={service.data.defaultAccount.id} />}
          </div>
        </div>
      </div>
    </div>
  );
}
