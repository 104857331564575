import {IconButtonWithTooltip, SvgIconName} from '@shipwell/shipwell-ui';

export const RfpDetailTabs = ({
  tabs,
  activeTabKey,
  onChange
}: {
  tabs: {key: string; icon: SvgIconName}[];
  activeTabKey: string;
  onChange: (key: string) => void;
}) => {
  return (
    <div className="RfpDetailTabs flex h-full flex-col gap-2 border-r border-sw-border p-2">
      {tabs.map((tab) => (
        <IconButtonWithTooltip
          key={tab.key}
          size="lg"
          onClick={() => onChange(tab.key)}
          isActive={activeTabKey === tab.key}
          iconName={tab.icon}
          aria-label={tab.key}
        />
      ))}
    </div>
  );
};
