import {useQuery} from '@tanstack/react-query';
import {flexRender} from '@tanstack/react-table';
import {Shipment} from '@shipwell/backend-core-sdk';
import CreateFreightInvoiceButton from '../FreightInvoiceForm/CreateFreightInvoiceButton';
import {getColumnData} from './columns';
import Loader from 'App/common/shipwellLoader';
import {useTableColumns, useTypedTable} from 'App/components/TypedTable/hooks';
import {FREIGHT_INVOICES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  TableContainer,
  Table,
  TableHeaderRow,
  TableHeader,
  TableHeaderSortIcon,
  TableRow,
  TableCell
} from 'App/components/TypedTable/baseComponents';
import {getSettlementsDashboard} from 'App/api/shipwellUI/typed';
import {SETTLEMENTS_LIST_TABLE_KEY} from 'App/utils/tableTypeKeys';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';

export interface FreightInvoiceListProps {
  onCreateNewInvoiceClick: (freightInvoiceId?: string) => void;
  shipmentId: Shipment['id'];
  role: SettlementsDashboardGeneratedBy;
}
const InvoicesEmptyState = ({onCreateNewInvoiceClick, role, shipmentId}: FreightInvoiceListProps) => (
  <div className="flex min-h-40 flex-col items-center justify-center">
    <div className="text-base text-sw-form-helper-text">No Invoices</div>
    <CreateFreightInvoiceButton onClick={onCreateNewInvoiceClick} role={role} shipmentId={shipmentId} />
  </div>
);
const FreightInvoiceList = ({onCreateNewInvoiceClick, shipmentId, role}: FreightInvoiceListProps) => {
  const {columns} = useTableColumns(getColumnData(shipmentId, role), SETTLEMENTS_LIST_TABLE_KEY);
  const {data: freightInvoicesData, isLoading: freightInvoiceQueryLoading} = useQuery(
    [FREIGHT_INVOICES_QUERY_KEY, shipmentId, role],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment id'));
      }
      const response = await getSettlementsDashboard({
        role,
        invoiceableId: shipmentId,
        //We wouldn't expect more than 100 invoices per an invoiceable (shipment),
        //so there's no need to implement a pagination UI for this case.
        limit: 100
      });
      return response.data.data;
    },
    {enabled: !!shipmentId}
  );

  const table = useTypedTable({
    data: freightInvoicesData || [],
    columns
  });

  if (freightInvoicesData?.length === 0)
    return (
      <div>
        <InvoicesEmptyState role={role} onCreateNewInvoiceClick={onCreateNewInvoiceClick} shipmentId={shipmentId} />
      </div>
    );
  if (freightInvoiceQueryLoading) {
    return <Loader loading additionalClassNames={['w-full h-[5rem]']} />;
  }

  return (
    <TableContainer overflowAuto={false}>
      <Table
        head={table.getHeaderGroups().map((headerGroup) => (
          <TableHeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader
                key={header.id}
                width={header.getSize()}
                sortDirection={header.column.getIsSorted()}
                onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                onResize={header.getResizeHandler()}
              >
                <div className="flex items-center gap-1">
                  {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              </TableHeader>
            ))}
          </TableHeaderRow>
        ))}
        body={table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
        isStickyHeader={false}
      />
    </TableContainer>
  );
};

export default FreightInvoiceList;
