import {
  TotalWeightOverride,
  ShipmentLineItem,
  PurchaseOrderLineItem,
  ShippingDashboardShipmentLineItem,
  ShippingDashboardWeight
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CENTIMETERS, KILOGRAMS, METRIC, INCHES, POUNDS, IMPERIAL} from './internationalConstants';

export const getLineItemUnitSystem = (
  line_items: (ShipmentLineItem | PurchaseOrderLineItem | ShippingDashboardShipmentLineItem)[] = [],
  unitPreferences = {system: 'IMPERIAL'},
  totalWeight?: TotalWeightOverride | ShippingDashboardWeight | null
) => {
  let displaySystem = unitPreferences.system;
  //if every line item is the same system of measure, override company preferences for totals display
  if (
    line_items.every(({weight_unit, length_unit}) => length_unit === CENTIMETERS && weight_unit === KILOGRAMS) &&
    displaySystem !== METRIC
  ) {
    displaySystem = METRIC;
  }
  if (
    line_items.every(({weight_unit, length_unit}) => length_unit === INCHES && weight_unit === POUNDS) &&
    displaySystem !== IMPERIAL
  ) {
    displaySystem = IMPERIAL;
  }
  //if the user overrides weight in kilograms, display totals in kilograms
  if (totalWeight?.value && totalWeight?.unit === KILOGRAMS) {
    displaySystem = METRIC;
  }
  //if the user overrides weight in pounds, display totals in pounds
  if (totalWeight?.value && totalWeight?.unit === POUNDS) {
    displaySystem = IMPERIAL;
  }
  return displaySystem;
};
