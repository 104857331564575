import {useFormikContext} from 'formik';
import {Rule} from '@shipwell/shipwell-ui';
import Stop from './Stop';
import {ContainerReturnStopType} from './StopTypeContactsFields';
import type {LegFormValues} from 'App/containers/shipments/drayage/create/components/LegForm/validation';

function ContainerReturn() {
  const {values} = useFormikContext<LegFormValues>();
  if (!values.containerReturnRequired) {
    return null;
  }
  return (
    <>
      <Rule />
      <Stop stopType={ContainerReturnStopType} />
    </>
  );
}

export default ContainerReturn;
