import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Field, useFormikContext} from 'formik';
import {FormikTextInput, FormikSelect, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';
import {RegionSelect} from 'App/formComponents/fields/regionSelect';

function makeCustomerOption(c) {
  return {
    id: c.company.id,
    label: get(c, 'company.name')
  };
}

const FuelSurchargeCriteriaFormFields = ({fetchCarrierRelationships, company}) => {
  const getCustomers = useCallback(
    async (opts = {}) => {
      const {brokerage} = company;
      if (brokerage?.id) {
        const response = await fetchBrokerShipperRelationshipsPromise(brokerage.id, opts);
        return response;
      }
    },
    [company]
  );

  const {values, setFieldValue} = useFormikContext();

  return (
    <div className="field-grid">
      <div className="grid-item-1-12">
        <Field required label="Fuel Surcharge Table Name" name="name" component={FormikTextInput} />
      </div>
      <div className="grid-item-1-12">
        <Field required label="Fuel Surcharge Table Reference" name="external_reference" component={FormikTextInput} />
      </div>
      <div className="grid-item-1-12">
        <RegionSelect />
      </div>
      <div className="grid-item-1-12">
        <Field
          searchAddressBook
          searchMaps
          searchStateProvince
          searchCountry
          label="Locations"
          name="locations"
          allowCreate
          component={MultiAddressSearch}
          ignoreFieldWarnings={['postal_code']}
        />
      </div>
      <div className="grid-item-1-12">
        <ModesField name="modes" simpleValue simpleOptionValue="code" />
      </div>
      <div className="grid-item-1-12">
        <EquipmentTypesField name="equipment_types" simpleValue simpleOptionValue="machine_readable" />
      </div>

      <div className="grid-item-1-11">
        <Field
          name="carrier_relationships"
          label="Carriers"
          component={FormikSelect}
          async
          isMulti
          loadOptions={fetchCarrierRelationships}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
        />
      </div>
      <div className="grid-item-12-12 mt-2">
        <Tooltip
          placement="top"
          tooltipClassname="w-96"
          tooltipContent="If a shipment matches carriers you add here, then your rate table will be automatically added to the carrier side financials."
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
      <div className="grid-item-1-11">
        <Field
          async
          isMulti
          name="customers"
          label="Customers"
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.id}
          loadOptions={async (input) => {
            const response = await getCustomers({companyContains: input});
            return response ? response.body?.results.map((customer) => makeCustomerOption(customer)) : response;
          }}
          component={FormikSelect}
        />
      </div>
      <div className="grid-item-12-12 mt-2">
        <Tooltip
          placement="top"
          tooltipClassname="w-96"
          tooltipContent="
            If a shipment matches customers you add here, then your rate table will be automatically added to the
            customer side financials."
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
      <div className="field-grid grid-item-1-12">
        <div className="grid-item-1-1">
          <Field
            label="Base Fuel Price"
            name="base_fuel_price"
            component={FormikTextInput}
            type="currency"
            prepend="$"
            required
            value={values.base_fuel_price}
            onChange={(e) => {
              const baseFuelPrice = e.target.value;
              setFieldValue('base_fuel_price', baseFuelPrice);
              const updatedDoeTable = [
                {
                  fuel_price_lower_bound: 0,
                  fuel_price_upper_bound: Number(baseFuelPrice),
                  fuel_surcharge: 0
                }
              ];
              setFieldValue('calculation_rows', updatedDoeTable);
            }}
          />
        </div>
        <div className="grid-item-2-2">
          <Field label="Miles Per Gallon" name="miles_per_gallon" component={FormikTextInput} />
        </div>
      </div>
    </div>
  );
};

FuelSurchargeCriteriaFormFields.propTypes = {
  company: PropTypes.shape({
    brokerage: PropTypes.object
  }),
  fetchCarrierRelationships: PropTypes.func
};

export default connect((state) => ({
  company: state.userCompany.company
}))(FuelSurchargeCriteriaFormFields);
