/**
 * Changes the time of a date passed to the function and returns it in an ISO format.
 * @param date starting date to create and set time to. If one is not passed then the current date is used.
 * @param hour hour to the time to, or 0 as default.
 * @param minutes minutes to set time to or 0 as default.
 * @param seconds seconds to set the time to or 0 as default.
 * @param milliseconds milliseconds to set the time to or 0 as default.
 * @returns ISO datetime format to filter with against API
 */
export function setTime(
  date?: string | Date,
  hour?: number,
  minutes?: number,
  seconds?: number,
  milliseconds?: number
): string {
  const startDate = new Date(date ?? new Date());
  const dateWithHours = new Date(startDate.setHours(hour || 0));
  const dateWithMinutes = new Date(dateWithHours.setMinutes(minutes || 0));
  const dateWithSeconds = new Date(dateWithMinutes.setSeconds(seconds || 0));
  const dateWithMilliseconds = new Date(dateWithSeconds.setMilliseconds(milliseconds || 0));
  return dateWithMilliseconds.toISOString();
}

/**
 * Combines pickup and drop of lane details to create a display text format for
 * a "from" and "to" display. If no pickup lane is provided then just the dropoff lane is provided.
 * if neither lane is provided then an empty string is returned.
 * @example "Minneapolis, MN > Austin, TX"
 */
export const mapLane = (pickUpLane: string | null, dropOffLane: string | null): string => {
  let strBuilder = '';
  if (pickUpLane?.length) {
    strBuilder += pickUpLane;
  }
  if (dropOffLane?.length) {
    if (pickUpLane?.length) {
      strBuilder += ' > '; // only append this if the pickup lane is present
    }
    strBuilder += dropOffLane;
  }
  return strBuilder;
};
