import {CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, Card} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {useState} from 'react';
import {EntityTypeGroupType, entityTypeGroups} from '../config';
import {useCompanyIdContext} from '../context';
import {customFieldTooltips} from './tooltips';
import {CustomFieldForm} from './CustomFieldForm';
import {CustomFieldRow} from './CustomFieldRow';
import {useCreateCompanyCustomField} from 'App/data-hooks';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type CustomDataGroupCardProps = {groupType: EntityTypeGroupType; customFields: CustomField[]};

const CustomDataGroupCardBase = withStatusToasts(
  ({groupType, customFields, setError}: CustomDataGroupCardProps & Pick<WithStatusToastProps, 'setError'>) => {
    const [showAddNew, setShowAddNew] = useState(false);
    const {label} = entityTypeGroups[groupType];
    const tooltip = customFieldTooltips[groupType];

    const companyId = useCompanyIdContext();
    const {createCustomField, isCreating} = useCreateCompanyCustomField(companyId, {
      onError: () => setError('Error!', ' Failed to create new custom field.')
    });

    const addNewButton = (
      <Button variant="tertiary" isCompact iconName="AddCircleOutlined" onClick={() => setShowAddNew(true)}>
        {`Add ${label} Field`}
      </Button>
    );

    return (
      <Card
        draggableProvided={null}
        bodyClassName="p-4"
        title={
          <div className="flex items-center gap-3">
            {`${label} Fields`}
            {tooltip}
          </div>
        }
        actions={addNewButton}
      >
        <ul className="grid gap-3">
          {showAddNew ? (
            <li>
              <CustomFieldForm
                groupType={groupType}
                isSubmitting={isCreating}
                onSubmit={(newCustomField) =>
                  createCustomField({...newCustomField}, {onSuccess: () => setShowAddNew(false)})
                }
                onCancel={() => setShowAddNew(false)}
              />
            </li>
          ) : null}
          {customFields.length === 0 && !showAddNew ? (
            <li>
              <div className="flex flex-col items-center justify-center p-4">
                <div className="text-lg font-bold text-sw-disabled-text">{`No Custom ${label} Fields`}</div>
                {addNewButton}
              </div>
            </li>
          ) : (
            customFields.map((customField) => (
              <li key={customField.id}>
                <CustomFieldRow customField={customField} groupType={groupType} setError={setError} />
              </li>
            ))
          )}
        </ul>
      </Card>
    );
  }
);

export const CustomDataGroupCard = compose<
  CustomDataGroupCardProps & Pick<WithStatusToastProps, 'setError'>,
  CustomDataGroupCardProps
>(withStatusToasts)(CustomDataGroupCardBase);
