import {Component} from 'react';
import _ from 'lodash';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {change, Field, reduxForm, Form} from 'redux-form';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import {Option} from 'react-select';
import {bind} from 'App/utils/camelize';
import renderField from 'App/formComponents/renderField';
import renderAsyncSelect from 'App/formComponents/renderAsyncSelect';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import PhoneNumberField from 'App/formComponents/phoneNumberField';
import renderTextArea from 'App/formComponents/renderTextArea';
import * as actions from 'App/actions/shipments';
import * as carrierActions from 'App/actions/vendors';
import {getCarrierRelationshipsPromise, getCarrierPowerUnits} from 'App/api/carriers';
import validate from 'App/utils/validateShipmentDetails';
import 'App/containers/Shipment/_shipment-carrier.scss';
import {reeferTypes, toTitleCase} from 'App/utils/globals';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.userCompany.company,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    authenticated: state.auth.authenticated,
    carrierRelationships: state.vendors.carrierRelationships,
    equipmentTypes: state.shipmentdetails.equipmentTypes,
    serviceLevels: state.shipmentdetails.serviceLevels,
    shipmentModes: state.shipmentdetails.shipmentModes,
    unitPreferences: state.userCompany.unitPreferences
  }),
  {...actions, ...carrierActions}
)
class ShipmentVendorEquipmentUpdateForm extends Component {
  constructor(props) {
    super(props);
    bind(this, ['handleCancel', 'getVendors']);

    this.state = {
      showTempFields: false,
      carrier: this.props.isCurrentCarrier ? this.props.company.carrier : null,
      pagination: {
        page: 1,
        pageSize: 70,
        ordering: 'company',
        q: ''
      },
      loadingCarrierDetails: false
    };
  }

  componentDidMount() {
    if (
      this.props.initialValues &&
      this.props.initialValues.equipment_type &&
      (this.props.initialValues.equipment_type.id === 2 ||
        this.props.initialValues.equipment_type.id === 67 ||
        this.props.initialValues.equipment_type.id === 68)
    ) {
      this.setState({showTempFields: true});
    }
    if (this.props.user && this.props.user.is_quoting_limited_user) {
      this.setState({is_quoting_limited_user: true});
    }

    if (this.props.initialValues && this.props.initialValues.vendor && this.props.initialValues.vendor.label) {
      this.setState({loadingCarrierDetails: true});
      this.getCarrierByName(this.props.initialValues.vendor.label);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.formValues &&
        this.props.formValues.values &&
        nextProps.formValues &&
        nextProps.formValues.values &&
        nextProps.formValues.values.equipment_type &&
        nextProps.formValues.values.equipment_type !== this.props.formValues.values.equipment_type) ||
      (this.props.formValues &&
        !this.props.formValues.values &&
        nextProps.formValues.values &&
        nextProps.formValues.values.equipment_type)
    ) {
      //if reefer is selected as an equipment_type type, show the temperature fields
      //on new quote this is an array of types
      const equipment_type = nextProps.formValues.values.equipment_type.id;

      if (reeferTypes.includes(equipment_type)) {
        this.setState({showTempFields: true});
      } else {
        this.setState({showTempFields: false});
      }
    }
    if (
      (this.props.formValues &&
        this.props.formValues.values &&
        nextProps.formValues &&
        nextProps.formValues.values &&
        nextProps.formValues.values.vendor &&
        nextProps.formValues.values.vendor !== this.props.formValues.values.vendor) ||
      (this.props.formValues &&
        !this.props.formValues.values &&
        nextProps.formValues.values &&
        nextProps.formValues.values.vendor)
    ) {
      this.setState({loadingCarrierDetails: true});
      this.getCarrierByName(nextProps.formValues.values.vendor.label);
    }
    if (nextProps.isCurrentCarrier && !this.state.carrier) {
      this.setState({carrier: nextProps.company.carrier});
    }
  }

  handleCancel(event) {
    this.props.reset();
    this.props.cancelEdit();
  }

  getVendors(input) {
    if (this.state.is_quoting_limited_user || this.props.user.is_quoting_limited_user) {
      return new Promise((resolve, reject) => {
        resolve({results: []});
      });
    }

    const pagination = this.state.pagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = 70;
    }
    pagination.carrierStatus = ['ACTIVE', 'INACTIVE', 'DO_NOT_USE'];

    return this.props.getCarrierRelationships(pagination).then((response) => {
      if (response.status === 200) {
        // add a field to determine if an option should be disabled in the renderAsyncSelect component
        const {details} = response;
        const resultsWithDisabledField = details.results.map((carrier) => ({
          ...carrier,
          disabled: carrier?.carrier_status !== 'ACTIVE'
        }));
        details.results = resultsWithDisabledField;
        return details;
      }
      return [];
    });
  }

  async getCarrierByName(carrier) {
    const name = get(carrier, 'shipwell_vendor.name', '');
    try {
      const response = await getCarrierRelationshipsPromise({
        q: name,
        pageSize: 100,
        carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']
      });
      if (response?.body) {
        const carrierRelationship = response.body.results?.find((relation) => {
          return relation.shipwell_vendor && relation.shipwell_vendor.name === name;
        });
        if (
          carrierRelationship &&
          carrierRelationship.point_of_contacts &&
          carrierRelationship.point_of_contacts.length === 1 &&
          !this.props.formValues.values.vendor_point_of_contact
        ) {
          //save that POC immediately
          this.props.change('vendor_point_of_contact', {
            id: carrierRelationship.point_of_contacts[0].id,
            name:
              carrierRelationship.point_of_contacts[0].first_name +
              ' ' +
              (carrierRelationship.point_of_contacts[0].last_name
                ? carrierRelationship.point_of_contacts[0].last_name
                : '')
          });
        } else if (carrierRelationship && !this.props.formValues.values.vendor_point_of_contact) {
          //dispatch to clear it out
          this.props.change('vendor_point_of_contact', null);
        }
        if (carrierRelationship) {
          this.setState({carrier: carrierRelationship.shipwell_vendor.carrier, loadingCarrierDetails: false});
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getPowerUnits = async (q) => {
    const carrier = this.state.carrier?.id;
    if (carrier) {
      try {
        const response = await getCarrierPowerUnits(carrier, {powerUnitNameContains: q});
        return response.body;
      } catch (error) {
        console.error(error);
      }
    }
  };

  render() {
    const {carrier, loadingCarrierDetails} = this.state;
    const {
      handleSubmit,
      error,
      allowEditCarrier,
      canUpdateEquipmentConfig,
      isCurrentCarrier,
      showPOCField,
      vendorPOCs,
      formValues,
      canViewCarrier,
      loadBoardEnabled,
      saving
    } = this.props;
    const shipmentMode = formValues && formValues.values && formValues.values.mode;
    const temperatureUnit = getShipmentTemperatureUnit(formValues?.values || {});

    // service level: allow editing if a quote is accepted
    const serviceLevels = [];
    if (this.props.serviceLevels && this.props.serviceLevels.length > 0) {
      for (let k = 0; k < this.props.serviceLevels.length; k++) {
        serviceLevels.push({
          id: this.props.serviceLevels[k].id,
          name: this.props.serviceLevels[k].description
        });
      }
    }

    const shipmentModes = [];
    if (this.props.shipmentModes && this.props.shipmentModes.length > 0) {
      for (let j = 0; j < this.props.shipmentModes.length; j++) {
        shipmentModes.push({
          id: this.props.shipmentModes[j].id,
          name: this.props.shipmentModes[j].description
        });
      }
    }

    const equipmentTypes = [];
    if (this.props.equipmentTypes && this.props.equipmentTypes.length > 0) {
      for (let j = 0; j < this.props.equipmentTypes.length; j++) {
        equipmentTypes.push({
          id: this.props.equipmentTypes[j].id,
          name: this.props.equipmentTypes[j].name
        });
      }
    }

    const pocOptions = vendorPOCs.map((e) => {
      return {id: e.id, name: e.first_name + ' ' + (e.last_name ? e.last_name : '')};
    });

    //let defaultCarrier = this.props.formValues.values.carrier;
    return (
      <Form onSubmit={handleSubmit}>
        {allowEditCarrier === false && <p>Note: Some fields are disabled on automated LTL shipments.</p>}
        {this.props.authenticated && !this.props.is_quoting_limited_user && canViewCarrier && (
          <Field
            component={renderAsyncSelect}
            getOptions={this.getVendors}
            defaultValue={this.props.formValues && this.props.formValues.values && this.props.formValues.values.vendor}
            name="vendor"
            label="Carrier"
            valueField="shipwell_vendor.id"
            valueRenderer={(option) => option.label?.shipwell_vendor?.name}
            placeholder="Search Carrier by name, USDOT, or MC #"
            disabled={allowEditCarrier === false}
            optionComponent={CarrierOption}
            onChange={() => {
              //clear the power unit and driver field when the carrier changes
              this.props.change('equipment_config.power_unit_name', null);
              this.props.change('equipment_config.driver.phone_number', '');
            }}
          />
        )}
        {/* Only show warning when selecting a carrier on a shipment that's currently on the load board*/}
        {loadBoardEnabled &&
          this.props.formValues &&
          this.props.formValues.values &&
          this.props.formValues.values.vendor && (
            <div className="shipment-carrier-warning text-danger">
              <i className="icon icon-Delayed pad-right" />
              This shipment will be removed from the load board once a carrier is assigned.
            </div>
          )}
        {this.props.formValues?.values?.vendor?.value &&
          showPOCField &&
          !this.props.is_quoting_limited_user &&
          canViewCarrier && (
            <Field
              component={renderDropDownSelector}
              data={pocOptions}
              name="vendor_point_of_contact"
              label="Point of Contact"
              placeholder="Select a point of contact from the carrier"
            />
          )}
        {shipmentMode && allowEditCarrier !== false && canUpdateEquipmentConfig && (
          <Field
            verifyNumber
            component={PhoneNumberField}
            name="equipment_config.driver.phone_number"
            label="Driver Cell"
            placeholder="Enter Cell #"
          />
        )}
        {((get(this.props.formValues, 'values.vendor.value') && carrier) || isCurrentCarrier) && (
          <>
            <Field
              label="Power Unit"
              name="equipment_config.power_unit_name"
              valueField="name"
              defaultValue={{
                value: get(this.props.formValues, 'values.equipment_config.power_unit_name.value'),
                label: get(this.props.formValues, 'values.equipment_config.power_unit_name.label')
              }}
              disabled={loadingCarrierDetails}
              valueRenderer={(option) => option.value}
              tooltip="Tracking begins two hours before planned pickup. You may change this field if the power unit changes."
              component={renderAsyncSelect}
              getOptions={this.getPowerUnits}
              optionComponent={PowerUnitOption}
              loadingPlaceholder="Looking up power unit..."
              cache={false}
              allowCreate
              promptTextCreator={(option) => {
                return `Assign unrecognized power unit:  ${option}`;
              }}
            />
            <Field carrier={carrier} label="Trailer" name="equipment_config.trailer_name" component={renderField} />
          </>
        )}
        {!this.props.is_quoting_limited_user && (
          <Field
            component={renderDropDownSelector}
            data={shipmentModes}
            name="mode.id"
            label="Mode"
            placeholder="Select Mode"
            disabled={allowEditCarrier === false}
          />
        )}
        {!this.props.is_quoting_limited_user && (
          <Field
            component={renderDropDownSelector}
            data={equipmentTypes}
            name="equipment_type.id"
            label="Equipment"
            placeholder="Select Equipment"
            parse={(value) => {
              if (value) {
                return parseInt(value, 10);
              }
            }}
            disabled={allowEditCarrier === false}
          />
        )}
        {this.state.showTempFields && !this.props.is_quoting_limited_user && (
          <div className="quote-flow__mode-temp">
            <Field
              name="temperature_lower_limit"
              component={renderField}
              label={<>Minimum Temperature (&deg;{temperatureUnit})</>}
              placeholder={temperatureUnit === 'C' ? 'e.g., 0' : 'e.g., 35'}
              req
            />

            <Field
              name="temperature_upper_limit"
              component={renderField}
              label={<>Maximum Temperature (&deg;{temperatureUnit})</>}
              placeholder={temperatureUnit === 'C' ? 'e.g., 10' : 'e.g., 50'}
              req
            />
          </div>
        )}
        {!this.props.is_quoting_limited_user && shipmentMode && Number(shipmentMode.id) !== 5 && (
          <Field
            component={renderDropDownSelector}
            data={serviceLevels}
            name="service_level.id"
            label="Service Level"
            placeholder="Select Service Level"
            disabled={allowEditCarrier === false}
            parse={(value) => {
              if (value) {
                return parseInt(value, 10);
              }
            }}
          />
        )}
        {!this.props.is_quoting_limited_user && (
          <Field
            name="notes_for_carrier"
            component={renderTextArea}
            placeholder="Notes for Carrier"
            label="Notes for Carrier"
            minRows={2}
          />
        )}
        {this.props.formValues &&
          this.props.formValues.values &&
          this.props.formValues.values.vendor &&
          this.props.formValues.values.vendor.value &&
          carrier &&
          !carrier.eld_providers.length && (
            <p>
              Want to connect this carrier's fleet? Contact us{' '}
              <a
                href={`mailto:eld@shipwell.com?subject=Please connect carrier ${carrier.display_name}&body=Please connect carrier ${carrier.display_name}, phone ${carrier.display_primary_phone_number}.`}
              >
                eld@shipwell.com.
              </a>
            </p>
          )}
        <div className="update-footer">
          <Button
            bsStyle="default"
            type="button"
            disabled={saving}
            onClick={this.handleCancel}
            className="cancel-edit"
            name="cancel-edit"
          >
            Cancel
          </Button>
          <Button bsStyle="primary" type="submit" disabled={saving}>
            {saving ? (
              <span>
                <i className="icon icon-Restart rotate" /> Saving...
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </div>
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
      </Form>
    );
  }
}

ShipmentVendorEquipmentUpdateForm = reduxForm({
  form: 'shipmentVendorEquipmentUpdateForm',
  validate: validate,
  enableReinitialize: true,
  onChange(values, dispatch, props, previousValues) {
    if (previousValues.vendor && !_.isEqual(values.vendor, previousValues.vendor)) {
      //clear out the POC field
      dispatch(change('shipmentVendorEquipmentUpdateForm', 'vendor_point_of_contact', null));
    }
  }
})(ShipmentVendorEquipmentUpdateForm);

ShipmentVendorEquipmentUpdateForm = connect((state) => {
  const initialValues = JSON.parse(JSON.stringify(state.shipmentdetails.one));
  return {
    initialValues: initialValues,
    company: state.auth.company
  };
})(ShipmentVendorEquipmentUpdateForm);

export default ShipmentVendorEquipmentUpdateForm;

const PowerUnitOption = ({option, ...props}) => {
  return (
    <Option
      option={option}
      {...props}
      aria-label={option.label.name}
      className={classNames(
        'Select-option',
        'shipment-carrier__powerUnit',
        {'is-disabled': option.disabled},
        {'is-focused': props.isFocused}
      )}
    >
      {typeof option.label === 'string' && option.label.includes('Assign unrecognized power unit') && (
        <span className="assign-new-pu">{option.label}</span>
      )}
      <span>{option.label.name}</span>
      <span>{option.label.status}</span>
    </Option>
  );
};

const CarrierOption = ({option, ...props}) => {
  return (
    <Option
      option={option}
      {...props}
      aria-label={option.label.shipwell_vendor.name}
      className={classNames(
        'Select-option',
        'shipment-carrier__powerUnit',
        {'is-disabled': option.disabled},
        {'is-focused': props.isFocused}
      )}
    >
      <span>{option.label.shipwell_vendor.name}</span>
      <span>{toTitleCase(option.label.carrier_status)}</span>
    </Option>
  );
};
