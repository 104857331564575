import {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, formValueSelector, change} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import CheckboxField from 'App/formComponents/fields/checkbox';
import InputField from 'App/formComponents/fields/input';
import SelectField from 'App/formComponents/fields/select';
import {cleanNumber, truncateNumber} from 'App/utils/globals';
import {POUNDS} from 'App/utils/internationalConstants';
import './styles.scss';

/**
 * Shipment Total Weight Fields
 */
class ShipmentTotalWeightFields extends Component {
  constructor(props) {
    super(props);
    const {values = {}} = this.props;
    const {total_weight_override} = values;
    this.inputValue = total_weight_override?.value > 0 ? truncateNumber(total_weight_override.value) : '';
  }

  componentDidUpdate(prevProps) {
    const {values = {}} = this.props;
    const {total_weight_override, manual_total_weight} = values;
    if (manual_total_weight && total_weight_override?.value != prevProps.values?.total_weight_override?.value) {
      this.inputValue = total_weight_override?.value > 0 ? truncateNumber(total_weight_override.value) : '';
    }
  }

  handleWeightInputChange(e, value) {
    e.preventDefault();
    const {form} = this.props;
    this.inputValue = value;
    this.props.dispatch(change(form, 'total_weight_override.value', value > 0 ? truncateNumber(value) : null));
  }

  handleCheckboxSelection(e, value) {
    const {form, unitPreferences, values = {}} = this.props;
    const {total_weight_override} = values;
    if (!value) {
      this.inputValue = '';
      this.props.dispatch(change(form, 'total_weight_override.value', null));
    }
    if (!total_weight_override?.unit) {
      this.props.dispatch(change(form, 'total_weight_override.unit', unitPreferences.weightUnit || POUNDS));
    }
  }

  render() {
    const {values = {}} = this.props;
    const {manual_total_weight} = values;
    return (
      <Grid container spacing={2} className="shipment-total-weight">
        <Grid item xs={12} md={12} className="shipment-total-weight-field">
          <Field
            name="manual_total_weight"
            label="Enter Total Shipment Weight Override"
            component={CheckboxField}
            onChange={this.handleCheckboxSelection.bind(this)}
          />
        </Grid>
        {manual_total_weight && (
          <Fragment>
            <Grid item xs={12} md={6}>
              <Field
                req
                name="total_weight_override.value"
                label="Total Shipment Weight"
                placeholder="Enter Weight"
                format={(value) => truncateNumber(this.inputValue || value || '')}
                normalize={(value) => cleanNumber(value, {allowDecimals: true})}
                component={InputField}
                onChange={this.handleWeightInputChange.bind(this)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                simpleValue
                name="total_weight_override.unit"
                searchable={false}
                options={[
                  {id: 'LB', name: 'LB'},
                  {id: 'KG', name: 'KG'}
                ]}
                component={SelectField}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default connect((state, props) => ({
  values: getFormValues(props.form)(state),
  shipmentLineItems: formValueSelector(props.form)(state, 'line_items'),
  unitPreferences: state.userCompany.unitPreferences
}))(ShipmentTotalWeightFields);
