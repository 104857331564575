export function transformDocumentsToFormValues(documents) {
  return documents.map((document) => ({...document, file: document.url}));
}

export function transformTagsToFormValues(tags) {
  return tags.map((tag) => tag.tag_id);
}

export function transformShipmentToFormValues(shipment, getCustomerOptionFromId) {
  const id = shipment?.customer?.party_id || shipment?.created_by?.tenant_id;
  return getCustomerOptionFromId(id);
}
