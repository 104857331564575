import {useState} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {VendorRelationship} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {useGetVendorRelationships} from 'App/data-hooks/vendorRelationships/useGetVendorRelationships';

export const CustomerFilter = ({
  customerIds,
  customerLabels,
  onChange
}: {
  customerIds: string[];
  customerLabels: string[];
  onChange: (newIds: string[], newLabels: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);
  const {getVendorRelationshipsData, getVendorRelationshipsIsFetching} = useGetVendorRelationships({
    q: debouncedSearchVal,
    pageSize: 100
  });

  // follows the pattern seen in the carrier filter
  const defaultValues = customerIds.map((customerId, index) => ({
    company: {
      id: customerId,
      name: customerLabels[index]
    }
  }));

  return (
    <Select
      value={defaultValues}
      onInputChange={setSearchVal}
      onChange={(options: VendorRelationship[]) => {
        const selectedCustomerIds = options.map((option) => option.company?.id || '');
        const selectedCustomerNames = options.map((option) => option.company?.name || '');
        onChange(selectedCustomerIds, selectedCustomerNames);
      }}
      isLoading={getVendorRelationshipsIsFetching}
      options={getVendorRelationshipsData || []}
      isMulti
      label="Search Customers"
      getOptionValue={(option: VendorRelationship) => option.company?.id}
      getOptionLabel={(option: VendorRelationship) => option.company?.name}
    />
  );
};
