import {useQuery} from '@tanstack/react-query';
import {PERMISSIONS} from '../queryKeys';
import {getPermissions} from 'App/api/user/typed';

export const useGetPermissions = () => {
  return useQuery([PERMISSIONS], async () => {
    const response = await getPermissions();
    return response.data;
  });
};
