import {ChangeEvent} from 'react';
import {Field, FieldArray, Form, Formik} from 'formik';
import {v4 as uuid} from 'uuid';
import {DeprecatedButton, Checkbox, DisplayValue, FormikSelect, Modal, SvgIcon, Title} from '@shipwell/shipwell-ui';
import invariant from 'tiny-invariant';
import {DocumentsRequiredDetails, DocumentType, UpdateDocumentsRequiredDetails} from '@shipwell/settlements-sdk';
import {InvoiceRuleEditModal} from './InvoiceRuleRow';
import Loader from 'App/common/shipwellLoader';
import {isDocumentsRequiredDetails} from 'App/api/settlements/typeGuards';
import {useDocumentTypes} from 'App/data-hooks';
import {useUpdateDocumentsRequiredDetails} from 'App/api/settlements/queryHooks';
import {isSettlementsDocumentType} from 'App/api/settlements/typeGuards/FreightInvoiceDocumentsGuards';

type RequiredDocumentUIType = {
  documentType: DocumentType | undefined;
  isRequiredPerStop: boolean;
  id: string;
};

type OptionType = {
  label: string;
  value: string;
};

const getBlankRequiredDocument = (): RequiredDocumentUIType => ({
  documentType: undefined,
  isRequiredPerStop: false,
  id: uuid()
});

const getInitialValuesFromRuleDetails = (ruleDetails: DocumentsRequiredDetails, ruleId: string) => {
  const requiredDocuments: RequiredDocumentUIType[] = [...new Set(ruleDetails.document_types)].map((type, index) => ({
    documentType: type,
    isRequiredPerStop: !!ruleDetails.required_per_delivery_stop_document_types?.includes(type),
    id: `${ruleId}-${index}`
  }));

  return {requiredDocuments};
};

const EditRequiredDocsModal: InvoiceRuleEditModal = ({showModal, onClose, invoiceRule}) => {
  const ruleDetails = invoiceRule.details;
  invariant(
    isDocumentsRequiredDetails(ruleDetails),
    'DocumentsRequiredDetails were expected, but another type was received as InvoiceRuleConfiguration.details'
  );

  const {data: documentTypesList, isLoading: isLoadingDocumentTypesList} = useDocumentTypes();
  const {mutate} = useUpdateDocumentsRequiredDetails(invoiceRule.id);

  const handleSubmit = ({requiredDocuments}: {requiredDocuments: RequiredDocumentUIType[]}) => {
    const newRuleDetails: UpdateDocumentsRequiredDetails = {
      document_types: requiredDocuments.map((doc) => doc.documentType).filter(isSettlementsDocumentType),
      required_per_delivery_stop_document_types: requiredDocuments
        .filter((doc) => doc.isRequiredPerStop)
        .map((doc) => doc.documentType)
        .filter(isSettlementsDocumentType)
    };
    //@ts-expect-error Backend docs specify we can send any of the posible objects but the SDK only created 1 object with a bunch of required params that we send in diferent config invoice calls.
    mutate(newRuleDetails);
    onClose();
  };

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      title="Edit Required Documents"
      footerComponent={null}
      portal
      bodyVariant="disableOverflowScroll"
    >
      <div className="mb-4">
        Required documents must be attached to an invoice to pass the first pass match criteria. An exception will be
        triggered if required document is not attached
      </div>

      <Title className="pb-4">Exception Details</Title>
      <DisplayValue className="mb-6" label="Exception Type">
        Required Document
      </DisplayValue>

      <Title className="pb-2">Required Documents</Title>
      <Formik initialValues={getInitialValuesFromRuleDetails(ruleDetails, invoiceRule.id)} onSubmit={handleSubmit}>
        {({values, setFieldValue}) => (
          <Form>
            {isLoadingDocumentTypesList ? (
              <Loader loading />
            ) : (
              <FieldArray name="requiredDocuments">
                {({push, remove}) => {
                  return (
                    <ul>
                      {values.requiredDocuments.map((requiredDocument, index) => {
                        const currentSelectionLabel = documentTypesList?.find(
                          (type) => type.id === requiredDocument.documentType
                        )?.name;

                        const currentSelection = currentSelectionLabel
                          ? {
                              label: currentSelectionLabel,
                              value: requiredDocument.documentType
                            }
                          : undefined; // react-select needs it's value prop to be undefined in order to show the placeholder text

                        return (
                          <li key={requiredDocument.id}>
                            <div className="flex gap-1">
                              <div className="grow">
                                <Field
                                  name="document_type"
                                  label="Document Type"
                                  component={FormikSelect} // FormikSelect here is react-select under the hood
                                  value={currentSelection}
                                  placeholder="Document Type"
                                  onChange={(selectedOption: OptionType | null) => {
                                    if (selectedOption) {
                                      const newValue: RequiredDocumentUIType = {
                                        ...requiredDocument,
                                        isRequiredPerStop:
                                          selectedOption.value === 'DELIVERY_RECEIPT'
                                            ? requiredDocument.isRequiredPerStop
                                            : false,
                                        documentType: selectedOption.value as RequiredDocumentUIType['documentType']
                                      };
                                      setFieldValue(`requiredDocuments[${index}]`, newValue);
                                    } else {
                                      setFieldValue(`requiredDocuments[${index}]`, {
                                        ...getBlankRequiredDocument(),
                                        id: requiredDocument.id
                                      });
                                    }
                                  }}
                                  options={documentTypesList?.map((type) => ({
                                    label: type.name,
                                    value: type.id
                                  }))}
                                />
                              </div>
                              <button onClick={() => remove(index)}>
                                <SvgIcon name="TrashOutlined" />
                              </button>
                            </div>

                            {requiredDocument.documentType === 'DELIVERY_RECEIPT' ? (
                              <Checkbox
                                className="mt-4"
                                label="Require a POD per delivery stop"
                                name={`isRequiredPerStop-${requiredDocument.id}`}
                                checked={requiredDocument.isRequiredPerStop}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(`requiredDocuments[${index}]`, {
                                    ...requiredDocument,
                                    isRequiredPerStop: event.target.checked
                                  });
                                }}
                                fixedHeight={false}
                              />
                            ) : null}

                            <div className="my-4 border-t-1 border-t-sw-divider" />
                          </li>
                        );
                      })}

                      <DeprecatedButton
                        variant="tertiary"
                        icon={<SvgIcon name="AddCircleOutlined" />}
                        onClick={() => push(getBlankRequiredDocument())}
                      >
                        Add Required Document
                      </DeprecatedButton>
                    </ul>
                  );
                }}
              </FieldArray>
            )}
            <div className="flex justify-end pt-4">
              <DeprecatedButton variant="secondary" onClick={onClose} className="mr-4">
                Cancel
              </DeprecatedButton>
              <DeprecatedButton variant="primary" type="submit">
                Save
              </DeprecatedButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditRequiredDocsModal;
