import {useState} from 'react';
import {connect} from 'react-redux';
import {Toast} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import {updateCompanyPreferencesAndSettings} from 'App/containers/userCompany/actions/async';
import CompanyProfileForm from 'App/formComponents/forms/companyProfile';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {COMPANY_PREFERENCES_KEY, COMPANY_UNIT_PREFERENCES} from 'App/data-hooks/queryKeys';
import {getUnitPreferences} from 'App/utils/hooks/useCompanyUnitPreferences';
import './styles.scss';

/**
 * Company Settings
 * @param {*} props
 *
 * @todo Move Toasts to top level
 */
const CompanySettingContainer = (props) => {
  const {company, preferences, dispatch} = props;
  const {id: companyId} = company || {};
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const queryClient = useQueryClient();
  const currentUnitPreferences = queryClient.getQueryData([COMPANY_UNIT_PREFERENCES, companyId]);

  const isLoadingSettings = !preferences || !companyId;

  /**
   * Update company settings and preferences
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSave = async (values, {setSubmitting}) => {
    setSubmitting(true);
    //copy mailing address to billing address
    values.billing_address = values.mailing_address;

    try {
      const {companyPreferences} = await dispatch(updateCompanyPreferencesAndSettings(companyId, values));
      queryClient.setQueryData([COMPANY_PREFERENCES_KEY, companyId], companyPreferences);

      // if the country is updated then we need to update unit preferences app-wide
      if (currentUnitPreferences && companyPreferences?.country) {
        queryClient.setQueryData([COMPANY_UNIT_PREFERENCES, companyId], {
          ...getUnitPreferences(companyPreferences.country),
          // if there is already a currency it is from international settings, retain it
          ...(!!currentUnitPreferences.currency && {currency: currentUnitPreferences.currency})
        });
      }

      setShowSuccess(true);
    } catch (error) {
      console.error(error);
      setShowError(error.error_description);
    }
    setSubmitting(false);
  };

  return (
    <div className="company-settings-wrapper">
      {isLoadingSettings && <ShipwellLoader loading />}
      {!isLoadingSettings && <CompanyProfileForm edit values={{...company, ...preferences}} onSubmit={handleSave} />}
      <Toast show={showSuccess} title="Success!" anchor="bottom-right" onClose={() => setShowSuccess(false)}>
        Successfully updated.
      </Toast>
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </div>
  );
};

export default connect((state) => ({
  company: state.company.company,
  preferences: state.company.preferences
}))(CompanySettingContainer);
