import {Tag} from '@shipwell/shipwell-ui';

export interface TagNameCellProps {
  name: string;
  color: string;
}

export function TagNameCell(props: TagNameCellProps) {
  return (
    <div className="inline-block">
      <Tag label={props.name} color={props.color} size={'m'} />
    </div>
  );
}
