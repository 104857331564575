import {ServiceProvider} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Modal, OverflowMenu} from '@shipwell/shipwell-ui';
import {browserHistory} from 'react-router';
import {useState} from 'react';
import {useDeleteServiceProvider} from 'App/data-hooks/serviceProviders/useDeleteServiceProvider';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

export const ServiceProvidersOverflowMenu = withStatusToasts<{serviceProvider: ServiceProvider}>(
  ({
    serviceProvider,
    setError,
    setSuccess
  }: {
    serviceProvider: ServiceProvider;
    setError: WithStatusToastProps['setError'];
    setSuccess: WithStatusToastProps['setSuccess'];
  }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {deleteServiceProvider, isDeletingServiceProviding} = useDeleteServiceProvider();

    const handleEdit = () => browserHistory.push(`/service-providers/${serviceProvider.id}`);

    const handleDelete = () => {
      deleteServiceProvider(serviceProvider.id, {
        onError: () => setError('Error!', 'There was an error attempting to delete the service provider.'),
        onSuccess: () => {
          setSuccess('Success!', 'Service provider successfully deleted.');
          setShowDeleteModal(false);
        }
      });
    };

    return (
      <>
        <OverflowMenu placement="left-start">
          {({setIsOpen}: {setIsOpen: (isOpen: boolean) => void}) => (
            <>
              <OverflowMenu.Item onClick={handleEdit}>Edit</OverflowMenu.Item>
              <OverflowMenu.Item
                isDestructive
                isLoading={isDeletingServiceProviding}
                onClick={() => {
                  setShowDeleteModal(true);
                  setIsOpen(false);
                }}
              >
                Delete
              </OverflowMenu.Item>
            </>
          )}
        </OverflowMenu>
        <Modal
          variant="warning"
          bodyVariant="disableOverflowScroll"
          size="small"
          show={showDeleteModal}
          title="Delete Service Provider"
          primaryBtnName="Delete"
          onPrimaryAction={handleDelete}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        >
          <div className="mb-4 font-bold">Are you sure you want to delete {serviceProvider.name}?</div>
        </Modal>
      </>
    );
  }
);
