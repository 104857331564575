import {useState} from 'react';
import {
  Modal,
  StepIndicator,
  useStepIndicator,
  FormikTextInput,
  FormikDateRangeBetweenPicker,
  SvgIcon,
  Tooltip,
  DisplayValue
} from '@shipwell/shipwell-ui';
import {Field, Formik, Form} from 'formik';
import {useMutation} from '@tanstack/react-query';
import {PaginatedRfpBidOpt} from '@shipwell/backend-core-sdk';
import CreateImport from 'App/components/imports/create';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {validationSchema} from 'App/containers/userCompany/rfps/utils/criteriaValidation';
import {RFP_DATE_RANGE_INFO} from 'App/containers/userCompany/rfps/utils/constants';
import {createOptimizedRfpBid, deleteOptimizedRfpBid} from 'App/api/rfpOptimized/typed';
import {RFP_BIDS_QUERY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

interface CriteriaFormValues {
  rfp_date_range: string[];
  rfp_name: string;
}

const ImportModal = ({showModal, toggleModal}: {showModal: boolean; toggleModal: () => void}) => {
  const [showSteps, setShowSteps] = useState(true);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [formData, setFormData] = useState({
    rfp_date_range: [''],
    rfp_name: ''
  });
  const steps = ['DEFINE CRITERIA', 'IMPORT LANES'];
  const {activeStep, setNextStep, setPreviousStep} = useStepIndicator(steps);

  const createBidMutation = useMutation(createOptimizedRfpBid);

  const rfpBidOptId = createBidMutation.data?.data.id;

  const deleteBidOptimisticHandlers = useOptimisticUpdate<PaginatedRfpBidOpt, unknown, string>();

  const deleteBidMutation = useMutation(
    deleteOptimizedRfpBid,
    deleteBidOptimisticHandlers([RFP_BIDS_QUERY], {
      mergeData: (prevRfps, deletedId) => {
        const filteredRfps = prevRfps?.results?.filter((rfp) => rfp.id !== deletedId);
        const newRfps = {
          ...prevRfps,
          results: filteredRfps
        };
        return newRfps;
      }
    })
  );

  const handleCreateBid = (formValues: CriteriaFormValues) => {
    setNextStep();
    // save form data here to state so if user goes back to step one,
    // they will still have the original data in the form
    setFormData({
      rfp_date_range: formValues.rfp_date_range,
      rfp_name: formValues.rfp_name
    });
    // if a bid id already exists, then user has gone back to first step of creation process...
    // ...so we need to delete the previous rfpBid and create a new one incase they update the values in the inputs
    if (rfpBidOptId) {
      deleteBidMutation.mutate(rfpBidOptId);
    }

    createBidMutation.mutate({
      start_date: formValues.rfp_date_range[0],
      end_date: formValues.rfp_date_range[1],
      name: formValues.rfp_name
    });
  };

  const handleCancel = () => {
    toggleModal();
    // if a user cancels out of the modal after an rfp has been created
    if (rfpBidOptId) {
      deleteBidMutation.mutate(rfpBidOptId);
    }
  };

  return (
    <Modal
      fullScreen={showFullScreen}
      onClose={handleCancel}
      title="Add New RFP"
      show={showModal}
      bodyVariant={activeStep === 0 ? 'disableOverflowScroll' : null}
      footerComponent={null}
    >
      {showSteps ? (
        <div className="flex justify-center py-4">
          <div className="w-1/2">
            <StepIndicator steps={steps} activeStep={activeStep} alternativeLabel />
          </div>
        </div>
      ) : null}

      {activeStep === 0 ? (
        <RfpCriteriaForm onChange={handleCreateBid} onClose={handleCancel} initialValues={formData} />
      ) : (
        <CreateImport
          setUseFullScreen={(useFullScreen: boolean) => {
            setShowFullScreen(useFullScreen);
            setShowSteps(false);
          }}
          onCancel={handleCancel}
          type="RFP_LANE_OPTIMIZED"
          onPrevious={() => setPreviousStep()}
          opts={{rfpBidOptId}}
          onImportSuccess={() => toggleModal()}
          skipOnSuccessModalToggle
          validationMessage={null}
        />
      )}
    </Modal>
  );
};

const RfpCriteriaForm = ({
  initialValues,
  onClose,
  onChange
}: {
  initialValues: CriteriaFormValues;
  onClose: () => void;
  onChange: (values: CriteriaFormValues) => void;
}) => (
  <Formik onSubmit={onChange} initialValues={initialValues} validationSchema={validationSchema} validateOnMount>
    {({isSubmitting, isValid}) => (
      <Form>
        <div className="mb-4">
          <DisplayValue>Name the RFP and select a date range that the RFP will be active.</DisplayValue>
        </div>

        <Field className="mb-4" name="rfp_name" label="RFP Name" component={FormikTextInput} required />

        <div className="mb-10 grid grid-cols-[710px_auto] items-center">
          <Field
            isBetweenDefault
            label="Select Date Range"
            name="rfp_date_range"
            component={FormikDateRangeBetweenPicker}
            required
          />
          <Tooltip wrapperClassname="ml-4" placement="top" tooltipContent={<RfpDateRangeInfo />} trigger="hover">
            <SvgIcon name="InfoOutlined" color="$sw-icon" />
          </Tooltip>
        </div>

        <ModalFormFooter isValid={isValid} isSubmitting={isSubmitting} onCancel={onClose} primaryActionName="Next" />
      </Form>
    )}
  </Formik>
);

const RfpDateRangeInfo = () => (
  <div className="w-64">
    <div className="font-bold">RFP Date Range</div>
    {RFP_DATE_RANGE_INFO}
  </div>
);

export default ImportModal;
