import {Modal, DeprecatedButton} from '@shipwell/shipwell-ui';
import {OrderStatus} from '@shipwell/corrogo-sdk';
import {useTransportationOrder, useUpdateTransportationOrder} from 'App/data-hooks';

export const getActionConfirmationModalText = (carrier: string, status?: OrderStatus) =>
  status === OrderStatus.Requested
    ? {
        title: 'Revoke Tender?',
        primaryInfoText: `Are you sure you want to revoke the tender sent to ${carrier}?`,
        secondaryInfoText: 'The POC will receive an email and they will be unable to accecpt or reject the tender.',
        primaryButtonText: 'Revoke Tender',
        secondaryButtonText: 'Do Not Revoke Tender'
      }
    : {
        title: 'Remove Carrier?',
        primaryInfoText: `Are you sure you want to remove ${carrier} from this shipment?`,
        secondaryInfoText: 'The carrier will no longer be assigned to this shipment and the POC will receive an email.',
        primaryButtonText: 'Remove Carrier',
        secondaryButtonText: 'Do Not Remove Carrier'
      };

export const ActionConfirmationModal = ({
  show,
  onClose,
  shipmentId,
  transportationOrderId
}: {
  show: boolean;
  onClose(): void;
  shipmentId: string;
  transportationOrderId: string;
}) => {
  const {revokeTransportationOrderMutation} = useUpdateTransportationOrder(transportationOrderId, shipmentId);

  const {transportationOrderDisplayValues, queryInfo: transportationOrderQuery} = useTransportationOrder(
    transportationOrderId,
    shipmentId
  );
  const status = transportationOrderQuery.data?.status;

  const actionConfirmationModalText = getActionConfirmationModalText(transportationOrderDisplayValues.carrier, status);

  return (
    <Modal footerComponent={null} title={actionConfirmationModalText.title} size="small" onClose={onClose} show={show}>
      <>
        <div className="font-semibold">{actionConfirmationModalText.primaryInfoText}</div>
        <div className="pb-2">{actionConfirmationModalText.secondaryInfoText}</div>
        <div className="-mx-2 grid grid-cols-2 gap-2">
          <DeprecatedButton variant="secondary" onClick={onClose}>
            {actionConfirmationModalText.secondaryButtonText}
          </DeprecatedButton>
          <DeprecatedButton
            variant="warning"
            onClick={() => {
              revokeTransportationOrderMutation.mutate();
              onClose();
            }}
          >
            {actionConfirmationModalText.primaryButtonText}
          </DeprecatedButton>
        </div>
      </>
    </Modal>
  );
};
