import _ from 'lodash';
import {
  checkIsHoliday,
  phoneUtil,
  freightClasses,
  validateEmail,
  validatePhoneNumber,
  validateDollarValue,
  validateIntegerValue
} from 'App/utils/globals';

const validate = (values) => {
  const errors = {};
  if (values.financials && values.financials.length > 0) {
    //loop through financial line items;
    errors.financials = [];
    for (var i = 0; i < values.financials.length; i++) {
      errors.financials[i] = {};
      if (!values.financials[i].unit_name) {
        errors.financials[i].unit_name = 'Description required';
      }
      if (!values.financials[i].unit_amount && values.financials[i].unit_amount !== 0) {
        errors.financials[i].unit_amount = 'Rate required';
      }
      if (values.financials[i].unit_amount) {
        if (!validateDollarValue(values.financials[i].unit_amount)) {
          errors.financials[i].unit_amount = 'Rate must be a valid dollar amount';
        }
      }
      if (!values.financials[i].unit_quantity) {
        errors.financials[i].unit_quantity = 'Quantity required';
      }
      if (
        values.financials[i].unit_quantity &&
        (!validateIntegerValue(values.financials[i].unit_quantity) || parseInt(values.financials[i].unit_quantity) < 0)
      ) {
        errors.financials[i].unit_quantity = 'Quantity must be a positive whole number';
      }
    }
  } else {
    errors.financials = {_error: 'At least one line item is required'};
  }

  if (values.markup) {
    if (!validateDollarValue(values.markup)) {
      errors.markup = 'Markup must be a number';
    }
  }

  if (
    (values.emailRequired !== false && !values.customers) ||
    (values.emailRequired && values.customers && values.customers.length === 0)
  ) {
    errors.customers = 'Select at least one user';
  }

  if (!values.company_name) {
    errors.company_name = 'Company name is required';
  }

  if (!values.message || values.message === '') {
    errors.message = 'Message is required';
  }

  return errors;
};

export default validate;
