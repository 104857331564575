import {
  Configuration,
  CreateOrderItem,
  CreatePurchaseOrder,
  OrderStop,
  OrderSupplier,
  PurchaseOrdersApi,
  UpdateOrderItem,
  DocumentType,
  CreateTransportationRelease,
  CreatePurchaseOrderRelease,
  CreateDocumentsMetadataSchema,
  UpdateEntirePurchaseOrder
} from '@shipwell/corrogo-sdk';
import omit from 'lodash/omit';
import {PurchaseOrderWithCustomData} from 'App/api/corrogo/types';
import {decimalVersionHeader} from 'App/api/consts';
import {getAccessToken} from 'App/api/utils';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

const basePath = process.env.SHIPWELL_CORROGO_API_BASE_PATH;

const purchaseOrdersApi = new PurchaseOrdersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const createPurchaseOrder = (purchaseOrder: CreatePurchaseOrder, isUsingDecimals = false) => {
  return purchaseOrdersApi.createPurchaseOrder(
    {createPurchaseOrder: purchaseOrder},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
};

export async function getPurchaseOrder(orderId: string, isUsingDecimals = false) {
  const axiosResponse = await purchaseOrdersApi.getPurchaseOrder(
    {orderId},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data as PurchaseOrderWithCustomData;
}

export async function updatePurchaseOrder(
  {
    orderId,
    updatePurchaseOrder
  }: {
    orderId: string;
    updatePurchaseOrder: UpdateEntirePurchaseOrder;
  },
  isUsingDecimals = false
) {
  const axiosResponse = await purchaseOrdersApi.updatePurchaseOrder(
    {
      orderId,
      updateEntirePurchaseOrder: updatePurchaseOrder
    },
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
}

export const updatePurchaseOrderItem = async (
  {
    orderItemId,
    orderId,
    updateOrderItem
  }: {
    orderItemId: string;
    orderId: string;
    updateOrderItem: UpdateOrderItem;
  },
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.updatePurchaseOrderItem(
    {orderItemId, orderId, updateOrderItem},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const createPurchaseOrderItem = async (
  {
    orderId,
    createOrderItem
  }: {
    orderId: string;
    createOrderItem: CreateOrderItem;
  },
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.createPurchaseOrderItem(
    {orderId, createOrderItem},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const deletePurchaseOrderItem = async (
  {orderItemId, orderId}: {orderItemId: string; orderId: string},
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.deletePurchaseOrderItem(
    {orderItemId, orderId},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const cancelPurchaseOrder = (orderId: string, isUsingDecimals = false) => {
  return purchaseOrdersApi.cancelPurchaseOrder(
    {orderId},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
};

export const getPurchaseOrderDocumentsList = async (
  orderId: string,
  page?: number,
  limit?: number,
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.listPurchaseOrderDocuments(
    {orderId, page, limit},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const updatePurchaseOrderShipTo = async (
  {orderId, orderStop}: {orderId: string; orderStop: OrderStop},
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.updatePurchaseOrderShipTo(
    {orderId, orderStop},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const updatePurchaseOrderShipFrom = async (
  {orderId, orderStop}: {orderId: string; orderStop: OrderStop},
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.updatePurchaseOrderShipFrom(
    {orderId, orderStop},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const updatePurchaseOrderSupplier = async (
  {
    orderId,
    orderSupplier
  }: {
    orderId: string;
    orderSupplier: OrderSupplier;
  },
  isUsingDecimals = false
) => {
  const axiosResponse = await purchaseOrdersApi.updatePurchaseOrderSupplier(
    {orderId, orderSupplier},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const createPurchaseOrderDocument = async (
  {
    orderId,
    file,
    description,
    type
  }: {
    orderId: string;
    file: Blob;
    description?: string;
    type?: DocumentType;
  },
  isUsingDecimals = false
) => {
  const requestPayload = {
    orderId: orderId,
    file: file,
    description: description,
    type: type
  };

  const axiosResponse = await purchaseOrdersApi.createPurchaseOrderDocument(requestPayload, {
    ...(isUsingDecimals ? {headers: decimalVersionHeader} : {}),
    transformRequest: [
      () => {
        const formData = new FormData();
        formData.append('file', file);
        if (description) formData.append('description', description);
        if (type) formData.append('type', type);
        return formData;
      }
    ]
  });
  return axiosResponse.data;
};

export const createPurchaseOrderRelease = async (
  orderId: string,
  createPurchaseOrderRelease: CreatePurchaseOrderRelease | CreateTransportationRelease,
  documentsMetadata?: CreateDocumentsMetadataSchema,
  documents?: Array<File>,
  options = {},
  isUsingDecimals = false
) => {
  const requestPayload = {
    orderId: orderId,
    data: createPurchaseOrderRelease,
    documentsMetadata: undefined,
    documents: undefined
  };

  const axiosResponse = await purchaseOrdersApi.createPurchaseOrderRelease(requestPayload, {
    ...(isUsingDecimals ? {headers: decimalVersionHeader} : {}),
    transformRequest: [
      () => {
        // This route receives file(s), so the request is structured as formData
        const formData = new FormData();
        const requestPayloadWithEmptyKeysOmitted = omit(
          omitEmptyKeysWithEmptyObjectsRemoved(createPurchaseOrderRelease),
          'document_metadata'
        );
        formData.append('data', JSON.stringify(requestPayloadWithEmptyKeysOmitted));
        if (documentsMetadata?.document_metadata && documentsMetadata.document_metadata.length > 0) {
          formData.append('documents_metadata', JSON.stringify(documentsMetadata));
        }
        documents?.map((document) => formData.append('documents', document));
        return formData;
      }
    ],
    ...options
  });

  return axiosResponse.data;
};

export const uncancelPurchaseOrder = async ({orderId}: {orderId: string}, isUsingDecimals = false) => {
  const axiosResponse = await purchaseOrdersApi.uncancelPurchaseOrder(
    {orderId},
    {...(isUsingDecimals ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};
