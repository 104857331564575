import {useState} from 'react';
import {Field} from 'formik';
import PropTypes from 'prop-types';
import {FormikTextInput, SvgIcon} from '@shipwell/shipwell-ui';
import './styles.scss';

/**
 * Set Password Fields
 * @param {*} props
 */
const SetPasswordFields = (props) => {
  const {handleSubmit, passwordFieldName, isValidLength, isValidCase, isValidSpecialChar} = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="set-password field-grid">
      <div className="grid-item-2">
        <Field
          required
          name={passwordFieldName}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          append={
            <SvgIcon
              name={showPassword ? 'Visibility' : 'VisibilityOff'}
              onClick={() => setShowPassword(!showPassword)}
              color="$sw-icon"
            />
          }
          component={FormikTextInput}
        />
      </div>
      <div className="grid-item-2">
        <Field
          required
          name="confirm_password"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          append={
            <SvgIcon
              name={showConfirmPassword ? 'Visibility' : 'VisibilityOff'}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              color="$sw-icon"
            />
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter' && handleSubmit) {
              handleSubmit();
            }
          }}
          component={FormikTextInput}
        />
      </div>
      <div className="set-password__rules grid-item-2">
        <div className="set-password__rule">
          <SvgIcon
            name="CheckCircleFilled"
            color={isValidLength ? '$sw-success' : '$sw-icon-small'}
            width="16"
            height="16"
          />
          <span className="set-password__rule-text">Must be at least 8 characters.</span>
        </div>
        <div className="set-password__rule">
          <SvgIcon
            name="CheckCircleFilled"
            color={isValidCase ? '$sw-success' : '$sw-icon-small'}
            width="16"
            height="16"
          />
          <span className="set-password__rule-text">At least 1 uppercase letter.</span>
        </div>
        <div className="set-password__rule">
          <SvgIcon
            name="CheckCircleFilled"
            color={isValidSpecialChar ? '$sw-success' : '$sw-icon-small'}
            width="16"
            height="16"
          />
          <span className="set-password__rule-text">At least 1 special character (e.g. !@#$%...).</span>
        </div>
      </div>
    </div>
  );
};

SetPasswordFields.propTypes = {
  passwordFieldName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  isValidLength: PropTypes.bool,
  isValidCase: PropTypes.bool,
  isValidSpecialChar: PropTypes.bool
};

SetPasswordFields.defaultProps = {
  handleSubmit: () => {},
  passwordFieldName: 'password',
  isValidLength: false,
  isValidCase: false,
  isValidSpecialChar: false
};

export default SetPasswordFields;
