import {Component} from 'react';
import {get} from 'lodash';
import {AsyncTypeahead, tokenContainer} from 'react-bootstrap-typeahead';
import {TokenDisplay} from 'App/components/TableFiltersWithSavedViews/FilterComponents/TokenDisplay';

class TypeaheadLookup extends Component {
  constructor(props) {
    super(props);

    this.state = {selected: [], value: '', isLoading: false, pagination: {pageSize: 100, page: 1}};
  }

  componentDidMount() {
    //there may be default selections we need to populate the state initially
    if (this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
    if (this.props.searchLocalList && this.props.localOptions) {
      //prefill the local list options when appropriate
      const {localOptions} = this.props;
      const results = localOptions.map((opt) => {
        return this.parseObjectFields(opt);
      });
      this.setState({options: results});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValues !== this.props.defaultValues && this.props.defaultValues === null) {
      //we received a 'clear' signal from the parent
      this.setState({selected: []});
    } else if (prevProps.defaultValues !== this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
    if (prevProps.localOptions !== this.props.localOptions && this.props.searchLocalList) {
      //prefill the local list options when appropriate
      const {localOptions} = this.props;
      const results = localOptions.map((opt) => {
        return this.parseObjectFields(opt);
      });
      this.setState({options: results});
    }
  }

  determineLocation() {
    const element = document.querySelector('#' + this.props.fieldName);
    const spaceToBottom = window.innerHeight - element.offsetTop + element.offsetHeight;
    if (spaceToBottom < 200) {
      //scroll the container to the bottom to bring options into view
      const container = document.querySelector('.tableFilters__container');
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 100);
    }
  }

  parseDefaultValues(defaultValues) {
    let selections = [];
    if (this.props.searchLocalList) {
      //look through the local list to populate the default values with appropriate labels
      const {localOptions} = this.props;
      defaultValues.forEach((value) => {
        const matchingObj = localOptions.filter((opt) => opt[this.props.valueField] === value)[0];
        selections.push(this.parseObjectFields(matchingObj));
      });
    } else if (this.props.simpleValue) {
      selections = this.props.defaultValues;
    } else {
      selections = this.props.defaultValues.map((obj) => {
        return {value: obj.value, label: obj.label};
      });
    }
    this.setState({selected: selections});
  }

  onInputValueChange(value) {
    this.setState({
      value: value
    });
  }

  /*
   * Send the current selections up to the parent component for refresh of data
   */
  updateParent(selections) {
    const arrayToUpdate = this.props.searchLocalList ? selections.map((e) => e.value) : selections;
    const filterObj = {};
    filterObj[this.props.fieldName] = arrayToUpdate;
    this.props.updateActiveFilters(filterObj);
  }

  /*
   * Search the local list for the specified input value
   */
  searchLocalList(value) {
    const {localOptions = []} = this.props;
    return new Promise((resolve, reject) => {
      let results = localOptions.filter((opt) => {
        let valToCheck = '';
        if (typeof this.props.labelField === 'string') {
          valToCheck = get(opt, this.props.labelField);
        } else if (Array.isArray(this.props.labelField)) {
          //in some scenarios, we are checking multiple fields like first_name + last_name
          this.props.labelField.forEach((field) => {
            valToCheck += get(opt, field) + ' ';
          });
        }
        return valToCheck.toLowerCase().includes(value.toLowerCase());
      });
      results = results.map((result) => {
        const parsedResult = this.parseObjectFields(result);
        return parsedResult;
      });

      resolve(results);
    });
  }

  /*
   * Using the valueField and labelField in props, helper function to turn an object into a displayable token in the field
   */
  parseObjectFields(object) {
    if (typeof this.props.labelField === 'string') {
      return {value: get(object, this.props.valueField), label: get(object, this.props.labelField)};
    }
    if (Array.isArray(this.props.labelField)) {
      let labelString = '';
      this.props.labelField.forEach((field) => {
        labelString += get(object, field) + ' ';
      });
      return {value: get(object, this.props.valueField), label: labelString};
    }
  }

  /*
   * Perform a search for the input value
   */
  async performSearch(value) {
    const {pagination} = this.state;
    if (this.props.searchLocalList || (!this.props.searchLocalList && value)) {
      this.setState({isLoading: true}, () => {
        //if we have the full list to search from on the frontend, do that here instead of calling the API
        if (this.props.searchLocalList) {
          this.searchLocalList(value).then((response) => {
            this.setState({options: response, isLoading: false});
          });
        } else {
          pagination.q = value;
          this.props
            .performSearch(pagination)
            .then((response) => {
              const options = [];
              if (response && response.results) {
                response.results.forEach((result) => {
                  if (this.props.simpleValue) {
                    options.push(get(result, this.props.valueField));
                  } else {
                    options.push({
                      value: get(result, this.props.valueField),
                      label: get(result, this.props.labelField)
                    });
                  }
                });
              }

              this.setState({options: options, isLoading: false});
            })
            .catch((response) => {
              this.setState({isLoading: false, options: []});
            });
        }
      });
    }
  }

  render() {
    const {selected, isLoading, options} = this.state;
    const {placeholder, localOptions = [], simpleValue = false, delay} = this.props;

    let optionsToShow = [];

    if (options && options.length) {
      if (simpleValue) {
        // don't show options that are already selected
        optionsToShow = options.filter((value) => !selected.includes(value));
      } else {
        optionsToShow = options.filter((result) => {
          return result.value && this.state.selected.filter((d) => d.value === result.value).length === 0;
        });
      }
    }
    //if the input value is '' and we are using a local search, reset to the initial list
    if (this.state.value === '' && this.props.searchLocalList) {
      optionsToShow = localOptions
        .map((opt) => {
          return this.parseObjectFields(opt);
        })
        .filter((result) => {
          return result.value && this.state.selected.filter((d) => d.value === result.value).length === 0;
        });
    }

    return (
      <div className="tableFilters__typeahead" id={this.props.fieldName}>
        <AsyncTypeahead
          multiple
          isLoading={isLoading}
          selected={selected ? selected : []}
          placeholder={placeholder}
          ref={(typeahead) => (this._typeahead = typeahead)}
          autoFocus={this.props.preventAutoFocus ? false : true}
          useCache={false}
          minLength={this.props.searchLocalList ? 0 : 2}
          filterBy={(value) => value}
          onSearch={this.performSearch.bind(this)}
          options={optionsToShow}
          renderToken={(option, props, index) => (
            <TokenDisplay key={index} onRemove={props.onRemove} option={option} simpleValue={simpleValue} />
          )}
          onInputChange={this.onInputValueChange.bind(this)}
          onChange={(selection) => {
            this.setState({selected: selection, value: ''});
            this.updateParent(selection);
            this._typeahead.getInstance().blur();
            this._typeahead.getInstance().focus();
          }}
          onMenuToggle={(isOpen) => {
            if (isOpen) {
              this.determineLocation();
            }
          }}
          delay={delay}
        />
      </div>
    );
  }
}

export default TypeaheadLookup;
