import _ from 'lodash';
import {phoneUtil} from 'App/utils/globals';

const validate = (values, props) => {
  const errors = {};
  let number;
  if (values.driver) {
    if (values.driver.phone_number) {
      try {
        number = phoneUtil.parse(values.driver.phone_number);
      } catch (err) {
        errors.driver = 'Phone number is invalid.';
      }
    } else {
      try {
        number = phoneUtil.parse(values.driver);
      } catch (err) {
        errors.driver = 'Phone number is invalid.';
      }
    }
  }
  if (!values.driver) {
    errors.driver = 'Phone number is required.';
  } else if (values.driver && number && !phoneUtil.isValidNumber(number)) {
    errors.driver = 'Phone number is invalid.';
  }

  if (!values.power_unit) {
    errors.power_unit = 'Power unit is required.';
  }
  return errors;
};

export default validate;
