/* eslint-disable @typescript-eslint/no-unused-expressions */
import moment from 'moment';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import {formatShipmentModeCode} from '../globalsTyped';
import store from 'App/routes/store';
import {formatDate, formatTime} from 'App/utils/globals';
import {stateProvinceOptions} from 'App/components/TableFiltersWithSavedViews/FilterComponents/LocationLookup/constants';
import {FILTERABLE_DASHBOARD_COLUMNS} from 'App/reducers/userPreferences/dashboardConfig';
import filterMapping from 'App/containers/Dashboard/components/ColumnFilterMapping';
import {SOURCES} from 'App/utils/createdBy';
import {handleDateFilter} from 'App/utils/shipmentTableHelpers/typed';

//render the date for a stop
export function renderStopDate(stop, usePlannedWindow) {
  //if we have confirmed departure time, start with that
  if (stop && stop.confirmed_departure_at && moment(stop.confirmed_departure_at).isValid()) {
    //if the stop is completed, show that date and time
    return (
      <div>
        <div className="date">
          <span>{moment(stop.confirmed_departure_at).format('ddd MMM D,')}</span>
          <br />
          <span>
            {stop.location &&
              stop.location.address &&
              formatTime(stop.confirmed_departure_at, true, stop.location.address.timezone)}
          </span>
        </div>
      </div>
    );
  }

  //for LTL, fall back to planned window
  if (usePlannedWindow) {
    return (
      <div>
        {stop && stop.display_planned_window.split(', ').length === 2 ? (
          <div className="date">
            <span>{stop.display_planned_window.split(', ')[0] + ','}</span>
            <br />
            <span>{stop.display_planned_window.split(', ')[1]}</span>
          </div>
        ) : stop ? (
          <div className="date">
            <span>{stop.display_planned_window}</span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
  //for FTL, fall back to eta_window
  return (
    <div>
      {stop && stop.display_eta_window.split(', ').length === 2 ? (
        <div className="date">
          <span>{stop.display_eta_window.split(', ')[0] + ','}</span>
          <br />
          <span>{stop.display_eta_window.split(', ')[1]}</span>
        </div>
      ) : stop ? (
        <div className="date">
          <span>{stop.display_eta_window}</span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export function renderStopCompany(stop) {
  if (stop && stop.location && stop.location.company_name) {
    return (
      <div>
        <span>{stop.location.company_name}</span>
      </div>
    );
  }
  return '--';
}

export function parseFiltersForReadback(input) {
  const filters = JSON.parse(JSON.stringify(input));
  delete filters.ordering;
  delete filters.page;
  delete filters.pageSize;
  const filtersToDisplay = [];
  let filterArray = Object.keys(filters);
  // map filter name back to corresponding column name for sorting and display
  filterArray.forEach((filter, i) => {
    if (
      [
        'pickupStops',
        'deliveryStops',
        'pickup',
        'dropoff',
        'drayage_estimated_arrival_date',
        'drayage_release_date',
        'drayage_last_free_date',
        'drayage_container_return_date'
      ].includes(filter)
    ) {
      // find the corresponding column name
      const columnName = Object.keys(filterMapping).find((column) => filterMapping[column].id === filter);
      // update the filter name in the array
      filterArray[i] = columnName;
      // reassign the filter values to the updated filter name and remove the outdated assignment
      filters[columnName] = filters[filter];
      delete filters[filter];
    }
  });
  filterArray = filterArray.sort((a, b) => {
    return FILTERABLE_DASHBOARD_COLUMNS.indexOf(a) < FILTERABLE_DASHBOARD_COLUMNS.indexOf(b) ? -1 : 1;
  });
  filterArray.forEach((key) => {
    //parsing each type of key to ensure the display matches
    switch (key) {
      case 'mode':
      case 'requestedShipmentModes':
        if (Array.isArray(filters[key])) {
          filters[key].forEach((val) => {
            filtersToDisplay.push({
              [key]: formatShipmentModeCode(val)
            });
          });
        } else {
          filtersToDisplay.push({[key]: formatShipmentModeCode(filters[key])});
        }
        break;
      case 'requestedEquipmentTypes':
      case 'alerts':
      case 'loadBoardEnabled':
      case 'biddingStatus':
      case 'hasInvoice':
      case 'hasBill':
      case 'tenderStatus':
      case 'workflowExecutionStatus':
        if (Array.isArray(filters[key])) {
          filters[key].forEach((val) => {
            filtersToDisplay.push({[key]: val});
          });
        } else {
          filtersToDisplay.push({[key]: filters[key]});
        }
        break;
      case 'equipment':
      case 'status':
      case 'statusExclude': {
        let keyName = key === 'equipment' ? key : '(Or) Status';
        if (key === 'statusExclude') {
          keyName = '(Exclude) Status';
        }
        if (Array.isArray(filters[key])) {
          filters[key].forEach((val) => {
            filtersToDisplay.push({
              //need to account for the special open flag
              [val === 'open' && key === 'status' ? '(And) Status' : keyName]: startCase(camelCase(val))
            });
          });
        } else {
          filtersToDisplay.push({[keyName]: startCase(camelCase(filters[key]))});
        }
        break;
      }
      case 'customerContains':
        // change display key to shipper from customerContains
        filtersToDisplay.push({shipper: filters[key]});
        break;
      case 'archived':
        filtersToDisplay.push({archived: true});
        break;
      case 'accessorials': {
        filters[key].forEach((val) => {
          const allAccessorials = store.getState().shipments.accessorialCodes;
          if (allAccessorials?.length && allAccessorials?.find((acc) => acc.code === val)) {
            filtersToDisplay.push({[key]: allAccessorials.find((e) => e.code === val).description});
          }
        });
        break;
      }
      case 'tags':
      case 'tagsExclude': {
        let keyName = '(Or) Tag';
        if (key === 'tagsExclude') {
          keyName = '(Exclude) Tag';
        }
        filters[key].forEach((val) => {
          const allTags = store.getState().shipments.tags;
          if (allTags?.length && allTags?.find((tag) => tag.id === val)) {
            filtersToDisplay.push({[keyName]: allTags.find((e) => e.id === val).name});
          }
        });
        break;
      }
      case 'reps':
        filters[key].forEach((val) => {
          const allReps = store.getState().users.companyUsers.results;
          let matchingRep;
          if (allReps) {
            matchingRep = allReps.find((e) => e.id === val);
          }
          if (allReps && allReps.length && matchingRep) {
            filtersToDisplay.push({
              rep: matchingRep.first_name + ' ' + matchingRep.last_name
            });
          }
        });
        break;
      case 'service_level':
        filters[key].forEach((val) => {
          const allServiceLevels = store.getState().shipments.serviceLevels;
          if (allServiceLevels && allServiceLevels.length) {
            filtersToDisplay.push({service_level: allServiceLevels.filter((e) => e.code === val)[0].description});
          }
        });
        break;
      case 'delivery_location':
      case 'pickup_location':
        filters[key].forEach((val) => {
          if (typeof val === 'string') {
            filtersToDisplay.push({[key]: val});
          } else if (val?.type === 'state_province') {
            filtersToDisplay.push({
              [key]: stateProvinceOptions.filter((e) => e.value.formatted_address === val.label)[0].label
            });
          } else if (val?.radius) {
            filtersToDisplay.push({
              [key]: `${val.label} (+${val.radius[0]} mi)`
            });
          }
        });
        break;
      case 'vendor':
        filters[key].forEach((val) => {
          filtersToDisplay.push({Carrier: val.label});
        });
        break;
      case 'createdBy':
      case 'customer':
        filters[key].forEach((val) => {
          filtersToDisplay.push({[key]: val.label});
        });
        break;
      case 'pickupStopsCompanyNameContains':
        filters[key].forEach((val) => {
          filtersToDisplay.push({'Pickup Company': val});
        });
        break;
      case 'deliveryStopsCompanyNameContains':
        filters[key].forEach((val) => {
          filtersToDisplay.push({'Delivery Company': val});
        });
        break;
      case 'drayageContainerNumberContains':
        filters[key].forEach((val) => {
          filtersToDisplay.push({'Container Number': val});
        });
        break;
      case 'drayageSealNumberContains':
        filters[key].forEach((val) => {
          filtersToDisplay.push({'Seal Number': val});
        });
        break;
      case 'shipmentShipwellCustomData':
        filters[key].forEach((val) => {
          filtersToDisplay.push({'Reference Custom Data': val});
        });
        break;
      case 'pickup_date':
      case 'delivery_date':
      case 'estimated_arrival_date':
      case 'release_date':
      case 'last_free_date':
      case 'container_return_date':
        if (!filters[key]) {
          break;
        }
        if (filters[key].length === 1) {
          //first parse for yesterday/today/tomorrow
          if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')) {
            filtersToDisplay.push({[key]: 'Today'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().add(1, 'days'), 'day')) {
            filtersToDisplay.push({[key]: 'Tomorrow'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(1, 'days'), 'day')) {
            filtersToDisplay.push({[key]: 'Yesterday'});
          } else {
            //catch all for any dates other than the above dates
            filtersToDisplay.push({[key]: 'On ' + formatDate(filters[key][0])});
          }
        } else {
          //we have an array of 2 dates. one may be null signifying 'before' or 'after'
          if (filters[key][0] && filters[key][1]) {
            if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(7, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({[key]: 'Last 7 Days'});
            } else if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(30, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({[key]: 'Last 30 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(7, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({[key]: 'Next 7 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(30, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({[key]: 'Next 30 Days'});
            } else {
              //between case
              filtersToDisplay.push({
                [key]: 'Between ' + (formatDate(filters[key][0]) + ' and ' + formatDate(filters[key][1]))
              });
            }
          } else if (filters[key][0]) {
            //after case
            filtersToDisplay.push({[key]: 'After ' + formatDate(filters[key][0])});
          } else if (filters[key][1]) {
            //before case
            filtersToDisplay.push({[key]: 'Before ' + formatDate(filters[key][1])});
          }
        }
        break;
      case 'createdAt':
        if (filters[key].length === 1) {
          //first parse for yesterday/today/tomorrow
          if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')) {
            filtersToDisplay.push({created: 'Today'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().add(1, 'days'), 'day')) {
            filtersToDisplay.push({created: 'Tomorrow'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(1, 'days'), 'day')) {
            filtersToDisplay.push({created: 'Yesterday'});
          } else {
            //catch all for any dates other than the above 3
            filtersToDisplay.push({created: 'On ' + formatDate(filters[key][0])});
          }
        } else {
          //we have an array of 2 dates. one may be null signifying 'before' or 'after'
          if (filters[key][0] && filters[key][1]) {
            if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(7, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({created: 'Last 7 Days'});
            } else if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(30, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({created: 'Last 30 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(7, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({created: 'Next 7 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(30, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({created: 'Next 30 Days'});
            } else {
              //between case
              filtersToDisplay.push({
                created: 'Between ' + (formatDate(filters[key][0]) + ' and ' + formatDate(filters[key][1]))
              });
            }
          } else if (filters[key][0]) {
            //after case
            filtersToDisplay.push({created: 'After ' + formatDate(filters[key][0])});
          } else if (filters[key][1]) {
            //before case
            filtersToDisplay.push({created: 'Before ' + formatDate(filters[key][1])});
          }
        }
        break;
      case 'expiresAt':
        if (filters[key].length === 1) {
          //first parse for yesterday/today/tomorrow
          if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')) {
            filtersToDisplay.push({expires: 'Today'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().add(1, 'days'), 'day')) {
            filtersToDisplay.push({expires: 'Tomorrow'});
          } else if (moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(1, 'days'), 'day')) {
            filtersToDisplay.push({expires: 'Yesterday'});
          } else {
            //catch all for any dates other than the above 3
            filtersToDisplay.push({expires: 'On ' + formatDate(filters[key][0])});
          }
        } else {
          //we have an array of 2 dates. one may be null signifying 'before' or 'after'
          if (filters[key][0] && filters[key][1]) {
            if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(7, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({expires: 'Last 7 Days'});
            } else if (
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment().subtract(30, 'days'), 'day') &&
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({expires: 'Last 30 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(7, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({expires: 'Next 7 Days'});
            } else if (
              moment(filters[key][1], 'YYYY-MM-DD').isSame(moment().add(30, 'days'), 'day') &&
              moment(filters[key][0], 'YYYY-MM-DD').isSame(moment(), 'day')
            ) {
              filtersToDisplay.push({expires: 'Next 30 Days'});
            } else {
              //between case
              filtersToDisplay.push({
                expires: 'Between ' + (formatDate(filters[key][0]) + ' and ' + formatDate(filters[key][1]))
              });
            }
          } else if (filters[key][0]) {
            //after case
            filtersToDisplay.push({expires: 'After ' + formatDate(filters[key][0])});
          } else if (filters[key][1]) {
            //before case
            filtersToDisplay.push({expires: 'Before ' + formatDate(filters[key][1])});
          }
        }
        break;
    }
  });

  return filtersToDisplay;
}

export function parseTableFilters(filterObj, direction, isSaveAction) {
  let filterCopy = JSON.parse(JSON.stringify(filterObj));
  if (filterCopy.archived && filterCopy.archived.length) {
    //this is a boolean that lives in a list view, so parse it here
    filterCopy.archived = [true, false];
  }
  if (filterCopy.pickupStops) {
    if (filterCopy.pickupStops.filter((e) => Boolean(e)).length) {
      //then there are either state_province selections or radius in here;
      const states = filterCopy.pickupStops.filter((e) => e.type && e.type === 'state_province');
      const radius = filterCopy.pickupStops.find((e) => e.type === 'radius');
      if (radius) {
        if (parseInt(radius.radius[0]) === 0) {
          //when the radius is 0, treat it like a regular location lookup
          filterCopy.pickupStops = [radius.label];
          delete filterCopy.pickupRadius;
          delete filterCopy.pickupLat;
          delete filterCopy.pickupLon;
          delete filterCopy.pickupStopsLabel;
        } else {
          filterCopy.pickupRadius = radius.radius;
          filterCopy.pickupLat = [radius.latlon.lat];
          filterCopy.pickupLon = [radius.latlon.lon];
          filterCopy.pickupStopsLabel = [radius.label];
        }
      }

      filterCopy.pickupStops = filterCopy.pickupStops.filter((e) => typeof e !== 'object');
      filterCopy.pickupStopsStateProvince = states.map((f) => f.label);
    }
    //using the contains query for pickup stop address details
    filterCopy.pickupStopsContains = filterCopy.pickupStops;
  } else {
    delete filterCopy.pickupStopsContains;
    delete filterCopy.pickupStopsStateProvince;
  }
  if (filterCopy.deliveryStops) {
    if (filterCopy.deliveryStops.filter((e) => e.type).length) {
      //then there are state_province selections in here;
      const states = filterCopy.deliveryStops.filter((e) => e.type && e.type === 'state_province');
      const radius = filterCopy.deliveryStops.find((e) => e.type === 'radius');

      if (radius) {
        if (parseInt(radius.radius[0]) === 0) {
          //when the radius is 0, treat it like a regular location lookup
          filterCopy.deliveryStops = [radius.label];
          delete filterCopy.deliveryRadius;
          delete filterCopy.deliveryLat;
          delete filterCopy.deliveryLon;
          delete filterCopy.deliveryStopsLabel;
        } else {
          filterCopy.deliveryRadius = radius.radius;
          filterCopy.deliveryLat = [radius.latlon.lat];
          filterCopy.deliveryLon = [radius.latlon.lon];
          filterCopy.deliveryStopsLabel = [radius.label];
        }
      }
      //reset the original
      filterCopy.deliveryStops = filterCopy.deliveryStops.filter((e) => typeof e !== 'object');
      filterCopy.deliveryStopsStateProvince = states.map((f) => f.label);
    }
    //using the contains query for delivery stop address details
    filterCopy.deliveryStopsContains = filterCopy.deliveryStops;
  } else {
    delete filterCopy.deliveryStopsContains;
    delete filterCopy.deliveryStopsStateProvince;
  }
  if (filterCopy.vendor) {
    if (!isSaveAction) {
      // handles the case where old dashboard loads new dashboard carrier filters
      if (filterCopy.vendor.every((filter) => !!filter.value)) {
        filterCopy.vendorId = filterCopy.vendor.map((e) => e.value);
      }
    }
    //when saving, we send the whole object so the label can be used on load
  }
  if (filterCopy.customer) {
    if (!isSaveAction) {
      // handles the case where old dashboard loads new dashboard customer filters
      if (filterCopy.customer.every((filter) => !!filter.value)) {
        filterCopy.customerId = filterCopy.customer.map((e) => e.value);
      }
    }
    //when saving, we send the whole object so the label can be used on load
  }
  if (filterCopy.equipment) {
    filterCopy.equipmentType = filterCopy.equipment;
  }
  if (filterCopy.service_level) {
    filterCopy.serviceLevel = filterCopy.service_level;
  }

  //open status has a special boolean
  if (filterCopy.status?.includes('open')) {
    filterCopy.open = [true];
    filterCopy.status.splice(filterCopy.status.indexOf('open'), 1);
  }
  //need to manually set open to false if user wants to exclude it
  if (filterCopy.statusExclude?.includes('open')) {
    filterCopy.open = [false];
    filterCopy.statusExclude.splice(filterCopy.statusExclude.indexOf('open'), 1);
  }

  if (filterCopy.createdBy) {
    filterCopy.createdBySource = [];
    filterCopy.createdByUserId = [];
    filterCopy.createdBy.forEach((q) => {
      // determine if it's a source or a user, these use different filter params
      if (Object.keys(SOURCES).includes(q.value)) {
        filterCopy.createdBySource.push(q.value);
      } else {
        filterCopy.createdByUserId.push(q.value);
      }
    });
  }

  //when loading a filter from the parent, we do the opposite to ensure the selections show on the children
  if (direction && direction === 'fromParent') {
    if (filterCopy.open?.length > 0) {
      //if parsing URL params this comes through as a string
      [true, 'true'].includes(filterCopy.open[0])
        ? (filterCopy.status = [...(filterCopy.status || []), 'open'])
        : (filterCopy.statusExclude = [...(filterCopy.statusExclude || []), 'open']);
      delete filterCopy.open;
    }
    if (filterCopy.pickupStops?.length > 1) {
      delete filterCopy.pickupRadius;
      delete filterCopy.pickupStopsLabel;
      delete filterCopy.pickupLat;
      delete filterCopy.pickupLon;
    }
    if (filterCopy.pickupRadius) {
      //this is always a single value
      filterCopy.pickupStops = [
        {
          label: Array.isArray(filterCopy.pickupStopsLabel) ? filterCopy.pickupStopsLabel[0] || '' : '',
          radius: filterCopy.pickupRadius,
          latlon: {
            lat: Array.isArray(filterCopy.pickupStopsLabel) ? filterCopy.pickupLat[0] || '' : '',
            lon: Array.isArray(filterCopy.pickupStopsLabel) ? filterCopy.pickupLon[0] || '' : ''
          }
        }
      ];
      delete filterCopy.pickupStopsContains;
    }
    if (filterCopy.pickupStopsContains) {
      //using the contains query for origin address details
      filterCopy.pickupStops = filterCopy.pickupStopsContains;
      delete filterCopy.pickupStopsContains;
    }
    if (filterCopy.pickupStopsStateProvince) {
      if (filterCopy.pickupStops) {
        filterCopy.pickupStopsStateProvince.forEach((state) => {
          //use a special object for state_provinces
          filterCopy.pickupStops.push({label: state, type: 'state_province'});
        });
      } else {
        filterCopy.pickupStops = filterCopy.pickupStopsStateProvince.map((state) => {
          //use a special object for state_provinces
          return {label: state, type: 'state_province'};
        });
      }
    }
    if (filterCopy.deliveryStops?.length > 1) {
      delete filterCopy.deliveryRadius;
      delete filterCopy.deliveryStopsLabel;
      delete filterCopy.deliveryLat;
      delete filterCopy.deliveryLon;
    }
    if (filterCopy.deliveryRadius) {
      //this is always a single value
      filterCopy.deliveryStops = [
        {
          label: Array.isArray(filterCopy.deliveryStopsLabel) ? filterCopy.deliveryStopsLabel[0] || '' : '',
          radius: filterCopy.deliveryRadius,
          latlon: {
            lat: Array.isArray(filterCopy.deliveryLat) ? filterCopy.deliveryLat[0] || '' : '',
            lon: Array.isArray(filterCopy.deliveryLon) ? filterCopy.deliveryLon[0] || '' : ''
          }
        }
      ];
      delete filterCopy.deliveryStopsContains;
    }
    if (filterCopy.deliveryStopsContains) {
      //using the contains query for destination address details
      filterCopy.deliveryStops = filterCopy.deliveryStopsContains;
      delete filterCopy.deliveryStopsContains;
    }
    if (filterCopy.deliveryStopsStateProvince) {
      if (filterCopy.deliveryStops) {
        filterCopy.deliveryStopsStateProvince.forEach((state) => {
          //use a special object for state_provinces
          filterCopy.deliveryStops.push({label: state, type: 'state_province'});
        });
      } else {
        filterCopy.deliveryStops = filterCopy.deliveryStopsStateProvince.map((state) => {
          //use a special object for state_provinces
          return {label: state, type: 'state_province'};
        });
      }
    }
    if (filterCopy.equipmentType) {
      filterCopy.equipment = filterCopy.equipmentType;
      delete filterCopy.equipmentType;
    }
    if (filterCopy.serviceLevel) {
      filterCopy.service_level = filterCopy.serviceLevel;
      delete filterCopy.serviceLevel;
    }
  }

  filterCopy = handleDateFilter(
    filterCopy,
    'pickup',
    'pickupGte',
    'pickupLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'dropoff',
    'dropoffGte',
    'dropoffLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'createdAt',
    'createdAtGte',
    'createdAtLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'expiresAt',
    'expiresAtGte',
    'expiresAtLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'drayage_estimated_arrival_date',
    'drayageEstimatedArrivalDateGte',
    'drayageEstimatedArrivalDateLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'drayage_release_date',
    'drayageReleaseDateGte',
    'drayageReleaseDateLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'drayage_last_free_date',
    'drayageLastFreeDateGte',
    'drayageLastFreeDateLte',
    isSaveAction,
    direction === 'fromParent'
  );

  filterCopy = handleDateFilter(
    filterCopy,
    'drayage_container_return_date',
    'drayageContainerReturnDateGte',
    'drayageContainerReturnDateLte',
    isSaveAction,
    direction === 'fromParent'
  );

  //delete parcelPickupStatus as they are tied to the status filter
  if (filterCopy.parcelPickupStatus) {
    delete filterCopy.parcelPickupStatus;
  }

  return filterCopy;
}

export const shouldDisableShipmentSelection = (
  currentShipment,
  firstSelectedShipment,
  canSelectParcel,
  canSelectNonParcel
) => {
  if (currentShipment) {
    // if the first selected shipment is parcel, all subsequent shipments need to be parcel too
    if (firstSelectedShipment?.mode?.code === 'PARCEL' && currentShipment.mode?.code !== 'PARCEL') {
      return true;
    }
    //conversely, if a non-parcel shipment is selected, subsequent shipments must not be parcel
    if (
      firstSelectedShipment?.mode &&
      firstSelectedShipment?.mode?.code !== 'PARCEL' &&
      currentShipment.mode?.code === 'PARCEL'
    ) {
      return true;
    }
    //PARCEL-SPECIFIC LOGIC
    // if the shipment doesn't have parcel specific options (i.e. isn't booked)
    if (currentShipment.mode?.code === 'PARCEL') {
      if (!canSelectParcel) {
        return true;
      }
      if (
        !currentShipment.fedex_specific_options &&
        !currentShipment.ups_specific_options &&
        !currentShipment.usps_specific_options
      ) {
        return true;
      }
      // if the shipment is already scheduled
      if (currentShipment.parcel_pickup_status !== 'NOT_SCHEDULED') {
        return true;
      }

      //compare subsequent shipments with the first selection for parcel
      if (currentShipment && firstSelectedShipment && Object.keys(firstSelectedShipment).length > 0) {
        const currentShipmentPickup =
          currentShipment.stops &&
          currentShipment.stops[0] &&
          currentShipment.stops[0].location &&
          currentShipment.stops[0].location.address &&
          currentShipment.stops[0].location.address.formatted_address;

        const firstSelectedShipmentPickup =
          firstSelectedShipment.stops &&
          firstSelectedShipment.stops[0] &&
          firstSelectedShipment.stops[0].location &&
          firstSelectedShipment.stops[0].location.address &&
          firstSelectedShipment.stops[0].location.address.formatted_address;

        // if they don't have the same parcel provider
        if (
          (firstSelectedShipment.fedex_specific_options && !currentShipment.fedex_specific_options) ||
          (firstSelectedShipment.ups_specific_options && !currentShipment.ups_specific_options) ||
          (firstSelectedShipment.usps_specific_options && !currentShipment.usps_specific_options)
        ) {
          return true;
        }
        // if they don't have the same origin/pickup address
        if (currentShipmentPickup !== firstSelectedShipmentPickup) {
          return true;
        }
        // if the provider is FedEx
        if (firstSelectedShipment.fedex_specific_options && currentShipment.fedex_specific_options) {
          const firstSelectedShipmentCarrierCode = firstSelectedShipment.fedex_specific_options.carrier_code;
          const currentShipmentCarrierCode = currentShipment.fedex_specific_options.carrier_code;
          const firstSelectedShipmentDropoffType = firstSelectedShipment.fedex_specific_options.dropoff_type;
          const currentShipmentDropoffType = currentShipment.fedex_specific_options.dropoff_type;

          if (firstSelectedShipmentCarrierCode && currentShipmentCarrierCode) {
            // FedEx Express shipments can only be bundled for pickup with other Express shipments
            if (
              (firstSelectedShipmentCarrierCode === 'FDXE' && currentShipmentCarrierCode !== 'FDXE') ||
              (firstSelectedShipmentCarrierCode !== 'FDXE' && currentShipmentCarrierCode === 'FDXE')
            ) {
              return true;
            }
            // FedEx SmartPost & Ground shipments can only be bundled for pickup with other SmartPost or Ground shipments
            if (
              ((firstSelectedShipmentCarrierCode === 'FDXG' || firstSelectedShipmentCarrierCode === 'FXSP') &&
                currentShipmentCarrierCode !== 'FDXG' &&
                currentShipmentCarrierCode !== 'FXSP') ||
              (firstSelectedShipmentCarrierCode !== 'FDXG' &&
                firstSelectedShipmentCarrierCode !== 'FXSP' &&
                (currentShipmentCarrierCode === 'FDXG' || currentShipmentCarrierCode === 'FXSP'))
            ) {
              return true;
            }
            // only FedEx shipments with the same dropoff type can be bundled for pickup
            if (firstSelectedShipmentDropoffType !== currentShipmentDropoffType) {
              return true;
            }
          }
        }
      }
    } else if (!canSelectNonParcel) {
      return true;
    }
  }
  return false;
};
