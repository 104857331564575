import {useQuery} from '@tanstack/react-query';
import {CHARGE_CODES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {getChargeCodes} from 'App/api/shipment/typed';
export const useGetChargeCodes = (q?: string) => {
  const getChargeCodesQuery = useQuery(
    [CHARGE_CODES_QUERY_KEY, q],
    async () => {
      const response = await getChargeCodes({limit: 1000, q});
      return response.data;
    },
    {staleTime: STATIC_DATA_STALE_TIME}
  );
  return getChargeCodesQuery;
};
