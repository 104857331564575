/* global google */
import _ from 'lodash';
import {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {object, string, mixed} from 'yup';
import {Radio, FormControl, ControlLabel, FormGroup} from 'react-bootstrap';
import {DeprecatedButton, FormikTextInput, FormikSelect} from '@shipwell/shipwell-ui';
import * as actions from '../actions/shipments';
import {bind} from 'App/utils/camelize';
import FormSection from 'App/components/forms/formSection';
import {countries} from 'App/utils/countries';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import RenderMap from 'App/components/RenderMap';
import {loadGoogleMapsAPI, states, provinces} from 'App/utils/globals';

@connect((state) => state, actions)
export default class AddressLookup extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    types: PropTypes.array
  };

  static defaultProps = {
    onChange: () => {},
    types: ['geocode', 'establishment']
  };

  constructor(props) {
    super(props);
    // this.setLatLng = this.setLatLng.bind(this);
    var value = props.value;
    if ((value === '' || !value) && Boolean(props.addr)) {
      // use props.addr as a fallback for props.value
      if (props.addr && props.addr.formatted_address) {
        //when loading from state/database, the object is not within a nested obj
        value = props.addr.formatted_address;
      } else {
        value = '';
      }
    }

    this.state = {
      value: value,
      showWarningModal: false,
      showAddressComponentModal: false,
      warnings: [],
      geocoded_address: {},
      provided_formatted_address: '',
      markerArray: [],
      mapBounds: null,
      componentForm: {
        address_1: '',
        address_2: '',
        city: '',
        state_province: '',
        postal_code: '',
        country: ''
      },
      manualAddressError: null,
      errorFields: []
    };

    bind(this, [
      'onInputValueChange',
      'onInputFocus',
      'onPlaceChange',
      'onPosition',
      'handleError',
      'handleWarning',
      'handleAddressSelection',
      'initialize',
      'handleInputChange',
      'handleComponentEntry',
      'handlePostalCodeLookup'
    ]);
  }

  initialize() {
    this.geocoder = new google.maps.Geocoder();
    if (this.refs.input) {
      this.autocomplete = new google.maps.places.Autocomplete(this.refs.input, {
        types: this.props.types
      });
      this.autocomplete.addListener('place_changed', this.onPlaceChange);
      google.maps.event.addDomListener(this.refs.input, 'blur', function () {
        const pacItem = document.querySelectorAll('.pac-item:hover');
        // trigger address modal if no address is selected
        if (pacItem.length === 0) {
          google.maps.event.trigger(this, 'focus', {});
          google.maps.event.trigger(this, 'keydown', {
            keyCode: 13
          });
        }
      });
    }
  }

  componentDidMount() {
    //make sure google maps API is loaded then proceed
    if (typeof google !== 'undefined') {
      this.initialize();
    } else {
      loadGoogleMapsAPI(() => {
        this.initialize();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.addr !== nextProps.addr && Object.keys(nextProps.addr).length !== 0) {
      if (nextProps.addr) {
        this.setState({
          value: nextProps.addr.formatted_address
        });
        this.forceUpdate();
      }
    } else if (this.props.addr !== nextProps.addr && Object.keys(nextProps.addr).length === 0) {
      this.setState({value: ''});
    }
    if (this.props.value !== nextProps.value) {
      this.setState({value: nextProps.value});
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.value !== this.state.value) {
      if (nextState.value === '') {
        this.props.onChange({}, '');
      }
    }
  }

  onInputValueChange({target}) {
    this.setState({
      value: target.value
    });
  }

  onInputFocus() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.onPosition);
    }
  }

  onPosition({coords}) {
    const circle = new google.maps.Circle({
      radius: coords.accuracy,
      center: {
        lat: coords.latitude,
        lng: coords.longitude
      }
    });
    this.autocomplete.setBounds(circle.getBounds());
  }

  handleWarning(addressObj) {
    const pinDropLocation = {
      lat: addressObj.geocoded_address.latitude,
      lng: addressObj.geocoded_address.longitude
    };
    if (
      (this.props.allowZipOnly === true &&
        addressObj.geocoded_address &&
        addressObj.geocoded_address.address_1 !== null) ||
      !this.props.allowZipOnly
    ) {
      //don't show the warning if zip Only is allowed, since in most cases it isn't really a problem, just set the values
      //if an address1 is sent in, however, chances are we do want to show the warning
      this.setState({
        showWarningModal: true,
        warnings: addressObj.warnings,
        provided_formatted_address: addressObj.provided_formatted_address,
        geocoded_address: addressObj.geocoded_address,
        selectedAddressOption: 'new',
        markerArray: [
          {
            show: true,
            position: pinDropLocation,
            info: '<p>' + addressObj.geocoded_address.formatted_address + '</p>',
            label: null
          }
        ]
      });
    }
  }

  handleError() {
    //show the component modal with no info prefilled

    this.setState({showAddressComponentModal: true});
  }

  handleAddressSelection() {
    const {selectedAddressOption, geocoded_address} = this.state;

    const statesAndProvinces = states
      .map((f) => {
        return {label: f.name, value: f.id};
      })
      .concat(
        provinces.map((f) => {
          return {label: f.name, value: f.id};
        })
      );

    if (selectedAddressOption === 'original') {
      // @todo: fill in any components that are available from the previous lookup, including the lat/lng
      if (geocoded_address) {
        this.setState({
          markerArray: [],
          componentForm: {
            address_1: geocoded_address.address_1,
            address_2: geocoded_address.address_2,
            postal_code: geocoded_address.postal_code,
            city: geocoded_address.city,
            state_province: statesAndProvinces.filter((e) => e.value === geocoded_address.state_province)[0],
            country: countries.filter((e) => e.value === geocoded_address.country)[0]
          },
          showWarningModal: false,
          showAddressComponentModal: true
        });

        if (geocoded_address.postal_code) {
          // Drop the pin on the zip that was used
          this.handlePostalCodeLookup(geocoded_address.postal_code);
        }
      } else {
        this.setState({
          markerArray: [],
          showWarningModal: false,
          showAddressComponentModal: true
        });
      }
    } else {
      // They used the suggested address - close the modal
      this.handleShowWarningModal(false);
    }
  }

  handleShowWarningModal(visible = true) {
    this.setState({showWarningModal: visible});
  }

  handleComponentEntry() {
    //create a formatted address from the components
    this.setState({manualAddressError: null, errorFields: []});
    let subpremise = this.state.componentForm.address_2 ? ' ' + this.state.componentForm.address_2 + ', ' : ', ';
    if (this.state.componentForm.address_2 && this.state.componentForm.address_2.match(/^\d+$/)) {
      subpremise = ' #' + subpremise.trim() + ' ';
    }
    if (
      ((this.state.componentForm.address_1 && !this.props.allowZipOnly) || this.props.allowZipOnly) &&
      this.state.componentForm.city &&
      this.state.componentForm.state_province &&
      this.state.componentForm.postal_code &&
      ((this.state.markerArray.length > 0 && !this.props.allowZipOnly) || this.props.allowZipOnly)
    ) {
      const formattedAddress =
        this.state.componentForm.address_1 +
        subpremise +
        this.state.componentForm.city +
        ', ' +
        this.state.componentForm.state_province +
        ' ' +
        this.state.componentForm.postal_code +
        ', ' +
        'US';
      const componentForm = {};
      componentForm.geocoded_address = this.state.componentForm;
      componentForm.geocoded_address.country = 'US';
      componentForm.geocoded_address.formatted_address = formattedAddress;
      componentForm.formatted_address = formattedAddress;
      if (this.state.markerArray.length > 0) {
        componentForm.geocoded_address.latitude = this.state.markerArray[0].position.lat;
        componentForm.geocoded_address.longitude = this.state.markerArray[0].position.lng;
      }
      this.setState({
        value: formattedAddress
      });
      //save all those values to the modal
      this.props.onChange(componentForm.geocoded_address, formattedAddress);
      this.setState({showAddressComponentModal: false});
    } else {
      //TODO - field level validation when a field errors out
      if (!this.state.componentForm.address_1 && !this.props.allowZipOnly) {
        this.setState({
          manualAddressError: 'Street number and name is required',
          errorFields: ['address_1']
        });
      } else if (!this.state.componentForm.city) {
        this.setState({
          manualAddressError: 'City is required',
          errorFields: ['city']
        });
      } else if (!this.state.componentForm.state_province) {
        this.setState({
          manualAddressError: 'State is required',
          errorFields: ['state_province']
        });
      } else if (!this.state.componentForm.postal_code) {
        this.setState({
          manualAddressError: 'Postal code is required',
          errorFields: ['postal_code']
        });
      } else if (this.state.markerArray.length === 0) {
        /*else if (!this.state.componentForm.country) {
        this.setState({manualAddressError: 'Country is required'});
      }*/ this.setState({
          manualAddressError: 'Please enter a valid postal code to get an approximate location.'
        });
      }
    }
  }

  onPlaceChange() {
    const place = this.autocomplete.getPlace();
    if (place.formatted_address) {
      //this was a selection from the autocomplete dropdown
      this.setState({value: place.formatted_address});
      this.props.validateAddress(place.formatted_address).then(
        function (response) {
          if (response.status === 200) {
            if (response.details.warnings && response.details.warnings.length > 0) {
              this.setState({
                value: response.details.geocoded_address.formatted_address
              });
              //still save this value even with a warning

              this.props.onChange(
                response.details.geocoded_address,
                response.details.geocoded_address.formatted_address
              );
              this.handleWarning(response.details);
            } else {
              this.setState({
                value: response.details.geocoded_address.formatted_address
              });
              this.props.onChange(
                response.details.geocoded_address,
                response.details.geocoded_address.formatted_address
              );
            }
          } else {
            if (response.details) {
              this.setState({value: 'Address not found.'});
              this.handleError();
            } else {
              this.setState({value: 'Address not found.'});
              this.handleError();
            }
          }
        }.bind(this)
      );
    } else {
      //long list of conditions to prevent accidentally searching when we shouldnt
      if (
        place.name !== 'Address not found.' &&
        place.name !== '' &&
        !this.props.preventAddressSearch &&
        (!this.props.addr ||
          _.isEmpty(this.props.addr) ||
          this.props.addr.error === true ||
          (this.props.addr && !this.props.addr.formatted_address && this.props.addr !== place.name) ||
          (this.props.addr && this.props.addr.formatted_address && place.name !== this.props.addr.formatted_address))
      ) {
        //autocomplete was not selected, user tabbed off or hit enter
        this.props.validateAddress(place.name).then(
          function (response) {
            if (response.status === 200) {
              if (response.details.warnings && response.details.warnings.length > 0) {
                this.setState({
                  value: response.details.geocoded_address.formatted_address
                });
                //still save this value even with a warning
                this.props.onChange(
                  response.details.geocoded_address,
                  response.details.geocoded_address.formatted_address
                );
                this.handleWarning(response.details);
              } else {
                this.setState({
                  value: response.details.geocoded_address.formatted_address
                });
                this.props.onChange(
                  response.details.geocoded_address,
                  response.details.geocoded_address.formatted_address
                );
              }
            } else {
              //error
              if (response.details) {
                this.setState({value: 'Address not found.'});
                this.handleError();
              } else {
                this.setState({value: 'Address not found.'});
                this.handleError();
              }
            }
          }.bind(this)
        );
      }
    }
  }

  render() {
    return (
      <div>
        <InfoModalWrapper
          show={this.state.showWarningModal}
          title="Confirm Address Details"
          extraClass="addressLookup__overrideZIndex"
          children={this.renderWarningModal()}
          onHide={() => {
            this.setState({showWarningModal: false});
          }}
          primaryAction={{
            label: 'Use Selected Address',
            action: this.handleAddressSelection
          }}
        />
        <InfoModalWrapper
          show={this.state.showAddressComponentModal}
          title="Confirm Address Details"
          extraClass="addressLookup__overrideZIndex"
          children={this.renderAddressComponentModal()}
          onHide={() => {
            this.setState({showAddressComponentModal: false});
          }}
        />

        <input
          tabIndex={this.props.tabIndex}
          type="text"
          ref="input"
          disabled={this.props.disabled}
          className={this.props.className}
          placeholder={'Enter a location'}
          onChange={this.onInputValueChange}
          onFocus={this.onInputFocus}
          onBlur={this.props.onBlur}
          value={this.state.value || ''}
          id="location"
        />
      </div>
    );
  }

  renderWarningModal() {
    return (
      <div>
        <p className="error-text-form-level text-left">
          <i className="icon icon-Delayed pad-right" />
          {this.state.warnings[0]}
        </p>
        <div className="addressLookup__option">
          <p> You entered: </p>
          <Radio
            name="select_address"
            value="original"
            onChange={() => this.setState({selectedAddressOption: 'original'})}
            checked={this.state.selectedAddressOption === 'original'}
          >
            {this.state.provided_formatted_address}{' '}
          </Radio>
        </div>
        <div className="addressLookup__option">
          <p> We found: </p>
          <Radio
            name="select_address"
            value="new"
            onChange={() => this.setState({selectedAddressOption: 'new'})}
            checked={this.state.selectedAddressOption === 'new'}
          >
            {this.state.geocoded_address.formatted_address}{' '}
          </Radio>
        </div>
        <div className="addressLookup__mapContainer">
          <RenderMap
            //send in the marker and the initial bounds for the map
            markerArray={this.state.markerArray}
            mapBounds={this.state.mapBounds}
            //send custom ID for setting styles
            mapID={'mapLookup'}
          />
        </div>
      </div>
    );
  }
  renderAddressComponentModal() {
    const AddressSchema = object().shape({
      address_1: string().required('Address line 1 is required'),
      address_2: string().nullable(),
      city: string().required('City is required'),
      state_province: mixed().required('State is required'),
      postal_code: mixed()
        .test({
          name: 'valid-postal-code',
          message: 'Enter a valid postal code',
          test: (value) => {
            const test = RegExp(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/);
            return test.test(value);
          }
        })
        .required('Postal code is required'),
      country: mixed().required('Country is required')
    });
    const {markerArray} = this.state;

    return (
      <div>
        <p>Please confirm the components of this address in the form below.</p>
        <Formik
          validationSchema={AddressSchema}
          onSubmit={(values, actions) => {
            const {allowZipOnly} = this.props;
            const {address_1 = '', address_2, city, state_province, postal_code, country} = values;

            const zipOrAddress1 = (address_1 && !allowZipOnly) || allowZipOnly;
            const zipOrMarkers = (markerArray.length && !allowZipOnly) || allowZipOnly;

            const subpremise = address_2 ? ` ${address_2}, ` : ', ';

            const formattedAddress = `${address_1}${subpremise}${city}, ${state_province.value} ${postal_code}, ${country.value}`;
            const addressToSave = {
              formatted_address: formattedAddress,
              geocoded_address: {
                country: country.value,
                formatted_address: formattedAddress,
                address_1,
                address_2,
                city,
                state_province: state_province.value,
                postal_code
              }
            };

            if (markerArray.length) {
              addressToSave.geocoded_address.latitude = markerArray[0].position.lat;
              addressToSave.geocoded_address.longitude = markerArray[0].position.lng;
            }

            this.setState({
              value: formattedAddress
            });
            //save all those values to the modal
            this.props.onChange(addressToSave.geocoded_address, formattedAddress);
            this.setState({showAddressComponentModal: false});
          }}
          initialValues={this.state.componentForm}
          render={({errors = {}, handleBlur, handleSubmit, values, status, touched, isSubmitting}) => (
            <Form onSubmit={handleSubmit} className="addressLookup__componentForm">
              <div className="formSection__row">
                <Field
                  error={errors.address_1}
                  errorMessage={errors.address_1}
                  component={FormikTextInput}
                  name="address_1"
                  label="Street Address"
                />
                <Field
                  error={errors.address_2}
                  errorMessage={errors.address_2}
                  component={FormikTextInput}
                  name="address_2"
                  label="Suite, Apt., Dock, etc."
                />
              </div>
              <div className="formSection__row">
                <Field
                  error={errors.city}
                  errorMessage={errors.city}
                  component={FormikTextInput}
                  name="city"
                  label="City"
                />
                <div>
                  <Field
                    error={errors.state_province}
                    errorMessage={errors.state_province}
                    component={FormikSelect}
                    clearable
                    options={
                      values.country && values.country.value === 'US'
                        ? states.map((e) => {
                            return {label: e.name, value: e.id};
                          })
                        : values.country && values.country.value === 'CA'
                        ? provinces.map((e) => {
                            return {label: e.name, value: e.id};
                          })
                        : states
                            .map((e) => {
                              return {label: e.name, value: e.id};
                            })
                            .concat(
                              provinces.map((e) => {
                                return {label: e.name, value: e.id};
                              })
                            )
                    }
                    name="state_province"
                    label={values.country && values.country.value === 'CA' ? 'Province' : 'State'}
                  />
                </div>
              </div>
              <div className="formSection__row">
                <Field
                  error={errors.postal_code}
                  errorMessage={errors.postal_code}
                  component={FormikTextInput}
                  name="postal_code"
                  label="Postal Code"
                  onBlur={(e) => {
                    handleBlur(e);
                    this.handlePostalCodeLookup.bind(this);
                  }}
                />

                <Field
                  error={errors.country}
                  errorMessage={errors.country}
                  component={FormikSelect}
                  clearable
                  options={countries}
                  name="country"
                  label="Country"
                />
              </div>
              <p>The map below will show an approximate location based on the postal code entered.</p>
              <div className="addressLookup__mapContainer">
                <RenderMap
                  //send in the marker and the initial bounds for the map
                  markerArray={markerArray}
                  mapBounds={this.state.mapBounds}
                  //send custom ID for setting styles
                  mapID={'mapLookup'}
                  maxZoom={7}
                />
              </div>
              <div className="formSection__footer">
                <DeprecatedButton onClick={handleSubmit} disabled={isSubmitting || !markerArray.length}>
                  Use This Address
                </DeprecatedButton>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }

  handleInputChange(event) {
    const currentAddress = this.state.componentForm;
    const target = event.target;
    const value = target.value;
    const name = target.id;

    //look up the postal code if it's 5 digits long
    if (name === 'postal_code' && value.length === 5) {
      this.handlePostalCodeLookup(value);
    }

    currentAddress[name] = value;
    this.setState({componentForm: currentAddress});
  }

  handlePostalCodeLookup(code) {
    if (typeof code === 'string') {
    } else {
      code = code.target.value;
    }
    //on blur of the postal code field, we want to do a latlng lookup to try to get a location
    this.props.getLatLngByPostalCode(code).then(
      function (response) {
        if (response.status === 200) {
          //we have a latlng
          this.setState({
            markerArray: [
              {
                show: true,
                position: {
                  lat: response.details.geocoded_address.latitude,
                  lng: response.details.geocoded_address.longitude
                },
                label: null
              }
            ]
          });
        } else {
          this.setState({markerArray: []});
        }
      }.bind(this)
    );
  }
}
