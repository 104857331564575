import {LineSeries, LineMarkSeries, AreaSeries} from 'react-vis';
import {formatAreaSeriesData, formatLineSeriesData} from '../../utils.js';

const LineMarkAreaSeries = (props) => {
  const {data, color, handleSeriesHover} = props;

  return [
    <LineSeries data={formatLineSeriesData(data, 'high')} color={color} strokeWidth={0.5} opacity={1} />,
    <AreaSeries data={formatAreaSeriesData(data)} color={color} opacity={0.1} />,
    <LineMarkSeries data={formatLineSeriesData(data, 'average')} color={color} size={1.5} strokeWidth={2} />,
    <LineSeries data={formatLineSeriesData(data, 'low')} color={color} strokeWidth={0.5} opacity={1} />
  ];
};

export default LineMarkAreaSeries;
