import {IconButton} from '@shipwell/shipwell-ui';
import {TableTabEnums} from '../tableUtils';

const tabStyles = (isSelected: boolean) =>
  `p-2 border-b-2 border-b-transparent ${
    isSelected ? 'bg-sw-active-light text-sw-primary border-b-sw-primary font-bold' : ''
  }`;

type Tab = {label: string; key: string};

const defaultTabs: Tab[] = [
  {label: 'Filters', key: TableTabEnums.filters},
  {label: 'Columns', key: TableTabEnums.columns},
  {label: 'Saved', key: TableTabEnums.saved}
];

export const TableSidebarHeader = ({
  tabs,
  selectedTab,
  setSelectedTab,
  onClose
}: {
  tabs?: Tab[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  onClose: () => void;
}) => {
  return (
    <div className="flex items-center justify-between gap-4 border-b border-sw-border pr-2">
      <div className="flex items-center">
        {(tabs || defaultTabs).map((tab) => (
          <button
            type="button"
            key={tab.key}
            onClick={() => setSelectedTab(tab.key)}
            className={tabStyles(selectedTab === tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <IconButton onClick={onClose} iconName="Close" aria-label="Close menu" />
    </div>
  );
};
