import PropTypes from 'prop-types';
import get from 'lodash/get';
import {DisplayValue, Card, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import Loader from 'App/common/shipwellLoader';
import {useCompanyUser, useV3Shipment} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import RepsModal from 'App/containers/shipments/components/RepsFields';
import {toTitleCase} from 'App/utils/globals';
import withEmptyFallback from 'App/components/withEmptyFallback';
import getNil from 'App/utils/getNil';
import {PROPTYPE_DRAGGABLE_PROVIDED} from 'App/utils/propTypeConstants';

const RepAvatar = withEmptyFallback('name')(({name}) => (
  <div className="mr-2.5 mt-0.5 flex size-5 items-center justify-center rounded-xl bg-sw-icon p-0 text-center text-sw-text-reverse">
    {name.substring(0, 1)}
  </div>
));

export const RepsContainer = ({reps, expanded}) => {
  if (expanded) {
    return reps.map((rep) => (
      <div className="flex flex-row" key={rep.system_id}>
        <RepAvatar name={rep.person_name} />
        <DisplayValue variant="sectionTitle" label={rep.contact_type ? toTitleCase(rep.contact_type) : ''}>
          {rep.person_name}
        </DisplayValue>
      </div>
    ));
  }

  return null;
};

RepsContainer.propTypes = {
  reps: PropTypes.arrayOf(
    PropTypes.shape({
      person_name: PropTypes.string.isRequired,
      contact_type: PropTypes.string.isRequired,
      system_id: PropTypes.string.isRequired
    })
  ).isRequired,
  expanded: PropTypes.bool
};

RepsContainer.defaultProps = {
  expanded: true
};

const RepsCard = ({shipmentId, onOpen, draggableProvided}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const createdBy = shipmentQuery.data?.created_by;

  const tenantId = createdBy?.tenant_id;
  const userId = createdBy?.user_id;

  const companyUserQuery = useCompanyUser(tenantId, userId);

  if (shipmentQuery.isInitialLoading || companyUserQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const contacts = getNil(shipmentQuery, 'data.contacts', []);

  const name = get(companyUserQuery, 'context.userDisplayValues.name');

  const reps = [{person_name: name, contact_type: 'CREATED_BY', system_id: userId}, ...contacts];

  return (
    <Card
      title="Reps"
      className="mb-2"
      isCollapsible
      actions={
        <DeprecatedButton variant="icon" onClick={onOpen}>
          <SvgIcon name="Edit" color="$sw-icon" />
        </DeprecatedButton>
      }
      draggableProvided={draggableProvided}
    >
      <RepsContainer reps={reps} />
    </Card>
  );
};

RepsCard.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

const RepsView = ({shipmentId, draggableProvided}) => {
  const [showEditState, toggleShowEditState] = useToggle();

  return (
    <>
      <RepsCard shipmentId={shipmentId} onOpen={toggleShowEditState} draggableProvided={draggableProvided} />
      <RepsModal showModal={showEditState} shipmentId={shipmentId} onClose={toggleShowEditState} />
    </>
  );
};

RepsView.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

export default RepsView;
