import {PurchaseOrder, SalesOrder} from '@shipwell/corrogo-sdk';
import {createColumnHelper, Table} from '@tanstack/table-core';
import {Checkbox} from '@shipwell/shipwell-ui';
import {Row} from '@tanstack/react-table';
import {getFormattedAddressFromAddressFields} from 'App/utils/corrogo/mappers';
import {FlexBox} from 'App/components/Box';
import {OrderNumberCell} from 'App/containers/orders/views/OrdersListView/components/OrdersListTable/columns/cells/OrderNumberCell';
import {getTotalOrderWeightLbs} from 'App/containers/orders/utils';
import {getRange} from 'App/containers/orders/views/OrdersListView/components/OrdersListTable/columns/utils';

const columnHelper = createColumnHelper<PurchaseOrder | SalesOrder>();
export const columns = [
  columnHelper.accessor('order_number', {
    header: ({table}: {table: Table<PurchaseOrder | SalesOrder>}) => {
      const {getIsAllRowsSelected, getToggleAllPageRowsSelectedHandler, getState} = table;
      const {rowSelection} = getState();
      const isSomeSelected = Boolean(Object.keys(rowSelection).length);
      return (
        <FlexBox gap="s" items="center">
          <Checkbox
            aria-label="select all rows"
            checked={getIsAllRowsSelected()}
            indeterminate={isSomeSelected && !getIsAllRowsSelected()}
            fixedHeight={false}
            onChange={getToggleAllPageRowsSelectedHandler()}
          />
          <span>Order #</span>
        </FlexBox>
      );
    },
    //this id value is tied to the isFixed prop in the AddOrdersToShipmentListTable
    id: 'order_number',
    cell: ({row}: {row: Row<PurchaseOrder | SalesOrder>}) => {
      const {original, getCanSelect, getIsSelected, getToggleSelectedHandler} = row;
      return (
        <OrderNumberCell
          order={original}
          isSelected={getIsSelected()}
          disabled={!getCanSelect()}
          onSelect={getToggleSelectedHandler()}
        />
      );
    },
    enableSorting: false
  }),
  columnHelper.accessor('ship_from', {
    id: 'pickup',
    header: 'Pickup',
    cell: (info) => getFormattedAddressFromAddressFields(info.getValue())?.formatted_address || '--',
    enableSorting: false
  }),
  columnHelper.accessor('ship_from', {
    id: 'pickup-range',
    header: 'Pickup Range',
    cell: (info) => getRange(info.getValue()?.shipping_requirements?.plan_window, info.getValue()?.timezone),
    minSize: 250,
    enableSorting: false
  }),
  columnHelper.accessor('ship_to', {
    id: 'delivery',
    header: 'Delivery',
    cell: (info) => getFormattedAddressFromAddressFields(info.getValue())?.formatted_address || '--',
    enableSorting: false
  }),
  columnHelper.accessor('ship_to', {
    id: 'delivery-range',
    header: 'Delivery Range',
    cell: (info) => getRange(info.getValue()?.shipping_requirements?.plan_window, info.getValue()?.timezone),
    minSize: 250,
    enableSorting: false
  }),
  columnHelper.accessor('ship_to.company_name', {
    id: 'customer',
    header: 'Customer',
    cell: (info) => info.getValue() || '--',
    enableSorting: false
  }),
  columnHelper.accessor('supplier.name', {
    id: 'supplier',
    header: 'Supplier',
    cell: (info) => info.getValue() || '--',
    enableSorting: false
  }),
  columnHelper.accessor('items', {
    id: 'weight)',
    header: 'Weight (lbs)',
    cell: (info) => {
      const items = info.getValue();
      if (items?.length) {
        return getTotalOrderWeightLbs(items);
      }
      return '--';
    },
    enableSorting: false
  })
];
