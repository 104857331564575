import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ShipwellApiError, AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {APPOINTMENTS_QUERY_KEY} from '../queryKeys';
import {PaginatedAppointmentEntries} from './types';
import {getAppointments} from 'App/api/appointments';
import {makeEntry} from 'App/containers/appointments/utils';
import {isDefined} from 'App/utils/guard';

export type UseAppointmentsOptions = Omit<
  UseQueryOptions<PaginatedAppointmentEntries, AxiosError<ShipwellApiError>, PaginatedAppointmentEntries, string[]>,
  'queryFn' | 'queryKey'
>;
export type UseAppointmentsRequest = {
  /**
   * Optional parameter for filtering appointments down by facilityId
   */
  facilityId?: string;
  /**
   * Optional parameter for filtering appointments down by dockId. If you provide this
   * as a filter you should also provide the facilityId as well when possible.
   */
  dockId?: string;
  /**
   * Optional parameter for filtering appointments down by status. If no statuses are included
   * then no filters are applied and all appointments will be considered eligible.
   */
  status?: AppointmentStatusEnum;
  /**
   * Optional parameter for filtering appointments down by reference values such as Purchase Order Number,
   * Bill of Lading, Pickup Number, or Shipwell Reference ID.
   * Search works as a 'contains` or `exact` match.
   */
  searchText?: string;
  /**
   * Inclusive date the appointment was made including the time. This will filter the appointments
   * with a start date greater to or equal to the date time being provided.
   */
  startDate?: Date;
  /**
   * Inclusive date the appointment was made including the time. This will filter the appointments
   * with a start date less than or equal to the date time being provided.
   */
  endDate?: Date;
  /**
   * Optional parameter to skip to specific set of records. Changing the page size or the sort
   * effect the result of the data returns on that specific page.
   * @default 1
   */
  page?: number;
  /**
   * Optional parameter to retrieve a number of records within a paged result. Changing the page number
   * or the sort order will effect the results are returned.
   * @default 10
   */
  pageSize?: number;
};

const useAppointmentsQuery = (
  {facilityId, dockId, searchText, status, startDate, endDate, page = 1, pageSize = 10}: UseAppointmentsRequest = {
    page: 1,
    pageSize: 10
  },
  options?: UseAppointmentsOptions
) => {
  const queryKey = [
    APPOINTMENTS_QUERY_KEY,
    facilityId,
    dockId,
    searchText,
    startDate?.toISOString(),
    endDate?.toISOString(),
    status
  ].filter(isDefined);
  const query = useQuery(
    queryKey,
    async () => {
      const {total_count = 0, data = []} = await getAppointments({
        q: searchText,
        facilityId,
        dockId,
        status,
        page,
        limit: pageSize
      });

      const appointmentEntries = data.map((appointment) => makeEntry(appointment));
      const paginatedEntries: PaginatedAppointmentEntries = {
        total: total_count,
        page: page,
        pageSize: pageSize,
        entries: appointmentEntries
      };

      return paginatedEntries;
    },
    options
  );

  return {
    isAppointmentsInitialLoading: query.isInitialLoading,
    isAppointmentsError: query.isError,
    isAppointmentsSuccess: query.isSuccess,
    appointmentsError: query.error || null,
    appointments: query.data || {
      total: 0,
      page: page,
      pageSize: pageSize,
      entries: []
    }
  };
};

export default useAppointmentsQuery;
