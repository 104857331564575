import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {QuotingApiQuotingRfqsPostRequest, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {createRfq} from '../typed';

export const useCreateRfq = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createRfq>>,
    AxiosError<ShipwellError>,
    QuotingApiQuotingRfqsPostRequest
  >
) => {
  return useMutation(createRfq, mutationOptions);
};
