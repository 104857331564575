import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ShipmentStatesValues} from '@shipwell/backend-core-sdk';
import classNames from 'classnames';
import {startCaseToLower} from 'App/utils/startCaseToLower';

const secondaryStatuses = [
  ShipmentStatesValues.Draft,
  ShipmentStatesValues.Quoting,
  ShipmentStatesValues.QuoteAccepted,
  ShipmentStatesValues.CarrierConfirmed,
  ShipmentStatesValues.Dispatched,
  ShipmentStatesValues.InTransit,
  ShipmentStatesValues.AtPickup,
  ShipmentStatesValues.AtDelivery
];

export const StatusCell = ({shippingDashboardRow}: {shippingDashboardRow: ShippingDashboardRow}) => {
  const state = shippingDashboardRow.state;
  const parcelState = shippingDashboardRow.parcel_pickup_status;
  const isOnLoadBoard = Boolean(shippingDashboardRow.metadata?.load_board_enabled);
  const isParcel = shippingDashboardRow.mode?.code === 'PARCEL';
  const isArchived = Boolean(shippingDashboardRow.metadata?.archived);
  if (!state) {
    return <>--</>;
  }

  return (
    <div className={`flex flex-wrap items-center gap-2`}>
      {isOnLoadBoard ? (
        <Tooltip portal placement="top" tooltipContent="This shipment is on your load board.">
          <SvgIcon color="sw-icon" name="Loadboard" />
        </Tooltip>
      ) : null}
      {isParcel ? (
        <span
          className={classNames('whitespace-nowrap capitalize', {
            'text-sw-secondary': ['NOT_SCHEDULED', 'IN_TRANSIT'].includes(parcelState || ''),
            'text-sw-success': 'DELIVERED' === parcelState,
            'text-sw-error': 'DELAYED' === parcelState
          })}
        >
          {parcelState ? startCaseToLower(parcelState) : '--'}
        </span>
      ) : (
        <div className="flex items-center">
          {isArchived ? (
            <Tooltip portal placement="top" tooltipContent="This shipment has been archived.">
              <SvgIcon color="sw-icon" name="Archive" />
            </Tooltip>
          ) : null}
          <span
            className={classNames('whitespace-nowrap capitalize', {
              'text-sw-secondary': secondaryStatuses.some((e) => e === state),
              'text-sw-warning': [ShipmentStatesValues.AppointmentPending, ShipmentStatesValues.Reconciled].some(
                (e) => e === state
              ),
              'text-sw-success': [ShipmentStatesValues.Tendered, ShipmentStatesValues.Delivered].some(
                (e) => e === state
              )
            })}
          >
            {startCaseToLower(state)}
          </span>
        </div>
      )}
      {shippingDashboardRow.metadata?.open ? (
        <div className="flex items-center gap-2 text-sw-secondary">
          <span>•</span>
          <span>Open</span>
        </div>
      ) : null}
    </div>
  );
};
