import {getShipwellApi} from 'App/api/config';
import {parseErrors} from 'App/utils/globals';
import {apiCallback} from 'App/api/utils';

/**
 * User Preferences - API
 */
export async function getNotificationPreferencesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.notificationPreferencesGet(apiCallback(resolve, reject));
  });
}

export async function editNotificationPreferencesPromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.notificationPreferencesPut(body, apiCallback(resolve, reject));
  });
}

/**
 * User Permissions - API
 */
export async function getPermissionsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.permissionsGet(apiCallback(resolve, reject));
  });
}

/**
 * User Password - API
 */
export async function changePasswordPromise(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.authChangePasswordPost(body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

export async function setPasswordPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.authSetPasswordPost(body, apiCallback(resolve, reject));
  });
}

export async function setResetPasswordPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.authPasswordResetPost(body, apiCallback(resolve, reject));
  });
}

export async function resetPasswordPromise(email) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.authRequestPasswordResetPost({email: email}, apiCallback(resolve, reject));
  });
}
