import {Component} from 'react';
import {connect} from 'react-redux';
import {FieldArray, change, Field} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {uniqBy} from 'lodash';
import PropTypes from 'prop-types';
import RecentShipments from '../recentShipments';
import {cleanPayload} from '../../utils/createQuote';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import ShipmentStopsFields from 'App/formComponents/formSections/shipmentStopsFields';
import TerminalScheduleFields from 'App/formComponents/formSections/terminalScheduleFields';
import ShipmentPurchaseOrderConsolidationForm from 'App/formComponents/forms/shipmentPurchaseOrders';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import textArea from 'App/formComponents/fields/textArea';
import {userHasDefaultShipmentTags} from 'App/utils/shipmentTags';
import './styles.scss';

/**
 * Quick Quote - Shipment Stop creation
 * @description Handles creating and importing shipment stop fields
 */
class ShipmentStops extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRecentQuotesModal: false,
      showPurchaseOrdersModal: false
    };
  }
  /**
   * Toggle modal for importing `Recent Shipments`
   */
  handleToggleRecentQuotesModal(e) {
    e && e.preventDefault();

    this.setState({showRecentQuotesModal: !this.state.showRecentQuotesModal});
  }

  /**
   * Sets fields from `Recent Shipment`
   * @param  {Object} shipment Shipment details
   */
  handleUpdateFromRecentShipment(shipment) {
    const {mode} = this.props.formValues;
    const {companyTags = [], defaultShipmentTags = []} = this.props;
    const hasFTL = (Array.isArray(mode) && mode.find((mode) => mode.id === 1)) || mode === '1';
    const {
      customer,
      stops,
      accessorials,
      line_items,
      total_linear_feet,
      total_weight_override,
      total_declared_value,
      total_quantity_override,
      metadata
    } = cleanPayload(shipment, {
      oldValue: [null],
      nextValue: ''
    });
    const lineItems = line_items.map((lineItem) =>
      Object.assign(
        {},
        {
          weight_unit: 'LB',
          hazmat: Boolean(lineItem.hazmat_identification_number)
        },
        lineItem
      )
    );

    if (!hasFTL && stops.length > 1) {
      stops.splice(2);
      stops[1].planned_date = '';
    }

    const newStops = stops.map((stop) => ({
      ...stop,
      unconfirmed_arrival_at: null,
      unconfirmed_departure_at: null,
      confirmed_arrival_at: null,
      confirmed_departure_at: null,
      trip_management_eta: null,
      trip_managent_eta_last_updated: null,
      status: null
    }));

    // map shipment tag ids and user default tag ids to full tag objects and dispatch the changes to form values
    const userDefaultShipmentTags = userHasDefaultShipmentTags({companyTags, defaultShipmentTags})
      ? companyTags.filter(({id}) => defaultShipmentTags.includes(id))
      : [];
    const shipmentTags =
      metadata.tags?.length > 0
        ? metadata.tags.map((tag) => (tag.id ? tag : companyTags.find(({id}) => id === tag)))
        : [];

    /** Must manually update redux-form fields */
    this.props.dispatch(change('newQuoteForm', 'customer', {label: customer.name, value: customer.id}));
    this.props.dispatch(change('newQuoteForm', 'line_items', lineItems));
    this.props.dispatch(change('newQuoteForm', 'stops', newStops));
    this.props.dispatch(change('newQuoteForm', 'accessorials', accessorials));
    this.props.dispatch(change('newQuoteForm', 'total_linear_feet', total_linear_feet));
    this.props.dispatch(change('newQuoteForm', 'products', ''));
    this.props.dispatch(change('newQuoteForm', 'delayed', false));
    //update manual override checkboxes
    this.props.dispatch(
      change('newQuoteForm', 'manual_total_weight', total_weight_override.value && total_weight_override.value > 0)
    );
    this.props.dispatch(change('newQuoteForm', 'manual_total_value', total_declared_value && total_declared_value > 0));
    this.props.dispatch(
      change('newQuoteForm', 'manual_quantity', total_quantity_override && total_quantity_override > 0)
    );
    //update override fields
    this.props.dispatch(change('newQuoteForm', 'total_weight_override', total_weight_override));
    this.props.dispatch(change('newQuoteForm', 'total_declared_value', total_declared_value));
    this.props.dispatch(change('newQuoteForm', 'total_quantity_override', total_quantity_override));

    this.props.dispatch(
      change('newQuoteForm', 'metadata.tags', uniqBy([...shipmentTags, ...userDefaultShipmentTags], 'id'))
    );

    this.setState({showRecentQuotesModal: !this.state.showRecentQuotesModal});
  }

  handlePurchaseOrdersModal() {
    this.setState({showPurchaseOrdersModal: !this.state.showPurchaseOrdersModal});
  }

  handleAddPurchaseOrderSuccess(values = {}) {
    this.props.dispatch(change('newQuoteForm', 'stops', values.stops));
    this.props.dispatch(change('newQuoteForm', 'line_items', values.line_items));
  }

  render() {
    const {showRecentQuotesModal, showPurchaseOrdersModal} = this.state;
    const {classes, formValues, defaultOrigin, hasPOUpdatePermission, customFields} = this.props;
    const {mode} = formValues;
    const {hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal} = checkShipmentModes(mode);

    return (
      <div className="quote-stops">
        <Paper square elevation={1} classes={{root: classes.root}}>
          <div className="quote-tile-header">
            <h2>Pickup & Delivery</h2>
            <div>
              {formValues.id && hasPOUpdatePermission && (
                <a href="#" onClick={this.handlePurchaseOrdersModal.bind(this)}>
                  <i className="icon icon-Plus" /> Add Orders
                </a>
              )}
              <a href="#" onClick={this.handleToggleRecentQuotesModal.bind(this)}>
                <i className="icon-Files" /> Clone from Shipment
              </a>
            </div>
          </div>
          {hasDrayage && (
            <div className="terminal-schedule">
              <div className="shipment-stop-header">Terminal Schedule</div>
              <TerminalScheduleFields slimForm form="newQuoteForm" />
            </div>
          )}
          <FieldArray
            form="newQuoteForm"
            name="stops"
            defaultOrigin={defaultOrigin}
            component={ShipmentStopsFields}
            props={{hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal, slimForm: true, customFields}}
          />
          {!hasParcel && (
            <Grid item xs={12}>
              <Field
                name="notes_for_carrier"
                label="Notes for Carrier"
                component={textArea}
                className="shipment-stop notes-for-carrier"
                minRows={4}
                placeholder="E.g., go to dock #4"
              />
            </Grid>
          )}
        </Paper>

        <InfoModalWrapper
          show={showRecentQuotesModal}
          title="Clone Shipment"
          extraClass="quotingHeader__cloneModal"
          bsSize="large"
          onHide={this.handleToggleRecentQuotesModal.bind(this)}
        >
          <RecentShipments onSelectShipment={this.handleUpdateFromRecentShipment.bind(this)} />
        </InfoModalWrapper>

        {formValues.id && (
          <ShipmentPurchaseOrderConsolidationForm
            show={showPurchaseOrdersModal}
            title="Add Orders"
            onHide={this.handlePurchaseOrdersModal.bind(this)}
            shipment={formValues}
            onUpdateSuccess={this.handleAddPurchaseOrderSuccess.bind(this)}
            dispatch={this.props.dispatch}
          />
        )}
      </div>
    );
  }
}

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.069)'
  }
};

ShipmentStops.propTypes = {
  dispatch: PropTypes.func,
  formValues: PropTypes.object,
  hasPOUpdatePermission: PropTypes.bool,
  companyTags: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string})),
  defaultShipmentTags: PropTypes.arrayOf(PropTypes.string)
};

export default connect((state) => ({
  user: state.auth.user,
  hasPOUpdatePermission: state.auth.user.permissions && state.auth.user.permissions.includes('orders.update_orders'),
  companyTags: state.shipments.tags,
  defaultShipmentTags: state.auth.user.default_shipment_tags
}))(withStyles(styles)(ShipmentStops));
