import {RatingApi} from '@shipwell/genesis-sdk';
import axios from 'axios';
import {getAccessToken, parseV3ApiResponseErrors} from 'App/api/utils';

const basePath = process.env.SHIPWELL_GENESIS_API_BASE_PATH;

const axiosInstance = axios.create({
  baseURL: basePath
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const parsed = parseV3ApiResponseErrors(error.response);
    throw parsed;
  }
);

function createGenesisApi() {
  return new RatingApi(
    {
      basePath,
      apiKey: getAccessToken
    },
    basePath,
    axiosInstance
  );
}

export function createFtlRates(options = {}) {
  return createGenesisApi().createFtlRateRequestRatesFtlPost(options);
}

/**
 * Gets an individaul rate from the rating and quoting system. If Non exists the
 * system raises an exception.
 * @public
 * @method getRate
 * @param {string} rateRequestId the original rate request identifier returned after a request was created.
 * @param {string} rateId the original identifier returned from all rates
 * @returns {Promise<import('axios').AxiosResponse<import('@shipwell/genesis-sdk').FtlRate>>>}
 */
export function getRate(rateRequestId, rateId) {
  return createGenesisApi().getRateRatesRateRequestIdRateIdGet({rateRequestId, rateId});
}

/**
 * Update an existing FTL instant rate. If no rate exists or there is an error
 * the system raises an exception.
 * @public
 * @method updateRate
 * @param {string} rateRequestId the rate request id that belongs to the rate
 * @param {string} rateId the original identifier of the rate
 * @param {import('@shipwell/genesis-sdk').FtlRate} rate rate data to update
 * @returns {Promise<import('axios').AxiosResponse<import('@shipwell/genesis-sdk').FtlRate>>}
 */
export function updateRate(rateRequestId, rateId, rate) {
  return createGenesisApi().putRateRatesRateRequestIdIdPut({
    rateRequestId: rateRequestId,
    id: rateId,
    ftlRate: rate,
    rateV2: rate
  });
}
