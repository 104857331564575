import {useQuery} from '@tanstack/react-query';
import {isNil} from 'lodash';
import {getAccessorialChargeTable} from 'App/api/rateTables/typed';
import {ACCESSORIAL_CHARGE_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetAccessorialTable = ({accessorialChargeTableId}: {accessorialChargeTableId: string}) => {
  const {data, ...getAccessorialChargeTableQuery} = useQuery({
    queryKey: [ACCESSORIAL_CHARGE_QUERY_KEY, accessorialChargeTableId],
    queryFn: () =>
      getAccessorialChargeTable({
        accessorialChargeTableId: accessorialChargeTableId
      }),
    enabled: !isNil(accessorialChargeTableId)
  });

  return {
    accessorialTable: data,
    getAccessorialChargeTableQuery
  };
};
