import {Component} from 'react';
import {connect} from 'react-redux';
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {SvgIcon} from '@shipwell/shipwell-ui';
import CasesForm from './CasesForm';
import Tasks from 'App/components/Tasks/Tasks';
import Notes from 'App/components/Notes';
import {leftArrow} from 'App/common/Assets';
import * as actions from 'App/actions/users';

class Case extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      newNote: '',
      totalNoteCount: null,
      notesPagination: {
        page: 1,
        pageSize: 20
      },
      selectedTask: null,
      tasksTitle: 'Tasks'
    };
    this.editNote = this.editNote.bind(this);
    this.submitEditNote = this.submitEditNote.bind(this);
    this.onNoteSubmit = this.onNoteSubmit.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.getMoreNotes = this.getMoreNotes.bind(this);
    this.clearTask = this.clearTask.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.handleNoteInputChange = this.handleNoteInputChange.bind(this);
  }

  componentDidMount() {
    const {id} = this.props.oneCase;
    const notesPagination = this.state;

    this.props
      .getNotesByCaseId(id, notesPagination)
      .then((response) => {
        this.setState({
          notes: response.details.results,
          totalNoteCount: response.details.total_count
        });
      })
      .catch((error) => this.setState({error}));
  }

  editNote(note) {
    this.setState({
      selectedNoteIdx: note.id,
      editNote: note.note
    });
  }

  submitEditNote(caseId, noteId) {
    if (this.state.editNote) {
      const body = {note: this.state.editNote};

      this.props
        .editCaseNote(caseId, noteId, body)
        .then((response) => {
          if (response.status === 200) {
            this.setState({editNote: '', selectedNoteIdx: null});
            this.props.getNotesByCaseId(caseId, {}).then((response) => {
              this.setState({notes: response.details.results});
            });
          } else {
            this.setState({errorDetails: response});
          }
        })
        .catch((error) => this.setState({error}));
    }
  }

  handleNoteInputChange(event) {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  onNoteSubmit(caseId) {
    const newNote = this.state.newNote;
    if (newNote.length >= 1 && newNote.replace(/\s/g, '').length) {
      this.props
        .createNoteByCaseId(caseId, {note: newNote})
        .then((response) => {
          if (response.status === 200) {
            this.setState({newNote: ''});
            this.props.getNotesByCaseId(caseId, {}).then((response) => {
              this.setState({
                notes: response.details.results,
                totalNoteCount: response.details.total_count
              });
            });
          } else {
            this.setState({errorDetails: response, newNote: ''});
          }
        })
        .catch((error) => this.setState({error}));
    } else {
      return false;
    }
  }

  deleteNote(caseId, noteId) {
    this.props
      .deleteNoteByCaseId(caseId, noteId)
      .then((response) => {
        if (response.status === 200) {
          this.props.getNotesByCaseId(caseId, {}).then((response) => {
            this.setState({
              notes: response.details.results,
              totalNoteCount: response.details.total_count
            });
          });
        } else {
          this.setState({errorDetails: response});
        }
      })
      .catch((error) => this.setState({error}));
  }

  getMoreNotes(caseId) {
    const {totalNoteCount, notes} = this.state;

    if (!totalNoteCount || notes.length < totalNoteCount) {
      const {notesPagination} = this.state;
      notesPagination.pageSize += 10;

      this.setState(
        {
          notesPagination
        },
        () => {
          this.props
            .getNotesByCaseId(caseId, this.state.notesPagination)
            .then((response) => {
              this.setState({notes: response.details.results});
            })
            .catch((error) => this.setState({error}));
        }
      );
    }
  }

  clearTask() {
    this.setState({selectedTask: null, tasksTitle: 'Tasks'});
  }

  selectTask(task) {
    this.setState({selectedTask: task, tasksTitle: task.description});
  }

  render() {
    const {
      oneCase,
      expanded,
      updateCase,
      removeCase,
      toggleExpandCases,
      user,
      editingCase,
      companyUsers,
      onSubmit,
      cancel,
      fetchCases,
      one
    } = this.props;
    const {notes} = this.state || [];

    return (
      <div className={`cases__case ${expanded ? 'cases__case-expanded' : ''}`}>
        <div className="cases__case-title">
          <h2>
            <span>
              {oneCase.title}
              <span className="cases__case-link">
                <i onClick={updateCase} className="flaticon-edit action-link" />
              </span>
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                ref={(ref) => (this['overlay'] = ref)}
                overlay={
                  <Popover id="case-popover-delete" className="popover-delete-lineitem">
                    <h4>Are you sure you want to delete this case?</h4>
                    <div className="btns">
                      <Button onClick={() => this['overlay'].hide()} className="btn-secondary">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          removeCase();
                          this['overlay'].hide();
                        }}
                        className="btn-primary btn-small"
                      >
                        Yes
                      </Button>
                    </div>
                  </Popover>
                }
              >
                <span className="cases__case-link">
                  <SvgIcon name="TrashOutlined" />
                </span>
              </OverlayTrigger>
            </span>
          </h2>
          <span className="cases__case-expander" onClick={toggleExpandCases}>
            {expanded ? <i className="icon icon-Up action-link" /> : <i className="icon icon-Down action-link" />}
          </span>
        </div>
        {expanded && (
          <>
            <div className="cases__case-description">
              <p>{oneCase.description}</p>

              <span className="cases__case-assignee">
                Assigned to{' '}
                {oneCase.assignee
                  ? oneCase.assignee.id === user.id
                    ? 'Me'
                    : oneCase.assignee.first_name + ' ' + oneCase.assignee.last_name
                  : 'nobody'}
              </span>
            </div>
            {editingCase && <CasesForm companyUsers={companyUsers} onSubmit={onSubmit} cancel={cancel} />}
            <div className="cases__case-notes">
              {expanded && (
                <div className="cases__notes">
                  <Notes
                    title={'Notes'}
                    currentUser={user}
                    handleKeyPressNew={(e) => {
                      if (e.key === 'Enter') {
                        this.onNoteSubmit(oneCase.id);
                      }
                    }}
                    handleKeyPressEdit={(e, noteId) => {
                      if (e.key === 'Enter') {
                        this.submitEditNote(oneCase.id, noteId);
                      }
                    }}
                    mode="case"
                    inputChange={this.handleNoteInputChange}
                    inputPlaceholder={'Add a note'}
                    inputValue={this.state.newNote}
                    noteClass=""
                    notes={{results: notes}}
                    primaryButton={{
                      action: () => this.onNoteSubmit(oneCase.id),
                      label: 'Save'
                    }}
                    editNote={this.editNote}
                    deleteNote={(noteId) => this.deleteNote(oneCase.id, noteId)}
                    submitEdit={(noteId) => this.submitEditNote(oneCase.id, noteId)}
                    editValue={this.state.editNote}
                    getMoreNotes={() => this.getMoreNotes(oneCase.id)}
                    selectedNoteIdx={this.state.selectedNoteIdx}
                    cancelEdit={() => {
                      this.setState({selectedNoteIdx: null});
                    }}
                  />
                </div>
              )}
            </div>
            <div className="cases__case-tasks">
              <h3>
                {this.state.tasksTitle === 'Tasks' ? (
                  <div className="task__title">{this.state.tasksTitle}</div>
                ) : (
                  <div className="task__title">
                    <button className="close" onClick={this.clearTask}>
                      {leftArrow()}
                    </button>
                    <span>{this.state.tasksTitle}</span>
                  </div>
                )}
              </h3>
              {expanded && (
                <Tasks
                  caseTitle={oneCase.title}
                  refreshCase={fetchCases}
                  caseId={oneCase.id}
                  caseOverride
                  shouldShowOwner
                  caseTasks={{results: oneCase.tasks}}
                  showTask={this.selectTask}
                  selectedTask={this.state.selectedTask}
                  clearTask={this.clearTask}
                  one={one}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(null, mapDispatchToProps)(Case);
