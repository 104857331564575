import {useQuery} from '@tanstack/react-query';
import {getQuickbooksItems} from 'App/api/invoicing/typed';
import {QUICKBOOKS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetQuickbooksItems = (params = {pageSize: 500, page: 1}) => {
  const {data, isLoading, error} = useQuery({
    queryKey: [QUICKBOOKS_QUERY_KEY, params],
    queryFn: async () => {
      const axiosResponse = await getQuickbooksItems(params);
      return axiosResponse.data;
    }
  });

  return {
    paginatedQuickbooksItems: data,
    isLoadingQuickbooksItems: isLoading,
    quickbooksItemsError: error
  };
};
