import {Select} from '@shipwell/shipwell-ui';
import {EquipmentType, EquipmentTypeValues} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {toTitleCase} from 'App/utils/globals';

export const EquipmentFilter = ({
  defaultEquipment,
  onChange
}: {
  defaultEquipment: string[];
  onChange: (newEquipment: string[]) => void;
}) => {
  const equipmentTypesQuery = useEquipmentTypesQuery();

  const defaultValues = defaultEquipment.reduce((acc: EquipmentType[], equipment) => {
    // if the query hasn't resolved yet, we can fill the select with temp values
    if (!equipmentTypesQuery.data?.length) {
      const tempName = toTitleCase(equipment).replaceAll('_', ' ');
      acc.push({
        id: Math.random(),
        machine_readable: equipment as unknown as EquipmentTypeValues,
        name: tempName
      });
      return acc;
    }
    // once the query resolves, we an replace with the real deal
    const match = equipmentTypesQuery.data.find((datum) => datum.machine_readable === equipment);
    if (match) {
      acc.push(match);
    }
    return acc;
  }, []);
  return (
    <Select
      value={defaultValues}
      onChange={(options: EquipmentType[]) => {
        const equipment = options.map((option) => option.machine_readable || '');
        onChange(equipment);
      }}
      isLoading={equipmentTypesQuery.isFetching}
      options={equipmentTypesQuery.data}
      isMulti
      label="Search Equipment"
      getOptionValue={(option: EquipmentType) => option.machine_readable}
      getOptionLabel={(option: EquipmentType) => option.name}
    />
  );
};
