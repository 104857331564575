import {useMutation, useQueryClient} from '@tanstack/react-query';
import {TAGS_SEARCH_KEY} from '../../useTagsService/constants';
import {DeleteTagParams} from './deleteTagParams';
import {UpdateTagParams} from './updateTagParams';
import {deleteTag, getTag, updateTag} from 'App/api/corrogo/tags';

export function useTagsService() {
  const queryClient = useQueryClient();

  const updateStatus = useMutation({
    mutationFn: async (params: UpdateTagParams) => {
      const tagResponse = await getTag(params.tagId);
      const tag = tagResponse.data;
      tag.status = params.status;
      await updateTag(params.tagId, tag);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [TAGS_SEARCH_KEY],
        exact: false
      });
    }
  });

  const deleteMutation = useMutation({
    mutationFn: async (params: DeleteTagParams) => {
      await deleteTag(params.tagId);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [TAGS_SEARCH_KEY],
        exact: false
      });
    }
  });

  const loading = updateStatus.isLoading || deleteMutation.isLoading;

  return {
    loading,
    delete: deleteMutation.mutate,
    updateStatus: updateStatus.mutate
  };
}
