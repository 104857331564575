import {useState} from 'react';
import {
  FreightClass,
  ProductPackageType,
  ShipmentLineItemLengthUnitEnum,
  ShipmentLineItemWeightUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  Button,
  Card,
  CollapsibleCardContent,
  FormikSelect,
  FormikTextInput,
  IconButton,
  InlineNotification
} from '@shipwell/shipwell-ui';
import {Field, FieldArray, useFormikContext} from 'formik';
import {v4} from 'uuid';
import {LineItemsTable} from './LineItemsTable';
import {
  AssembleHandlingUnitsFormValues,
  getLineItemsThatMatchStop,
  handleAddAllLineItems,
  handleRemoveAllFromHandlingUnit,
  packagingTypeOptions
} from './utils';
import {FlexBox} from 'App/components/Box';
import {Divider} from 'App/containers/shipments/details/components/SidebarActions';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';

const freightClassOptions = enumToOptionConverter(FreightClass);

export const AssembleHandlingUnitsForm = ({values}: {values: AssembleHandlingUnitsFormValues}) => {
  const [collapsedStops, setCollapsedStops] = useState<string[]>([]);
  const {setFieldValue} = useFormikContext<AssembleHandlingUnitsFormValues>();

  const handleAddAll = () => {
    const updatedFieldValues = handleAddAllLineItems({
      activeHandlingUnitId: values.active_handling_unit_id,
      stops: values.stops,
      lineItems: values.line_items
    });
    if (!updatedFieldValues) {
      return;
    }
    Object.entries(updatedFieldValues).forEach((entry) => {
      setFieldValue(entry[0], entry[1]);
    });
  };

  const handleRemoveAll = (handlingUnitIndex: number, handlingUnitId: string) => {
    const updatedFieldValues = handleRemoveAllFromHandlingUnit({
      handlingUnitId,
      handlingUnitIndex,
      stops: values.stops,
      lineItems: values.line_items
    });
    if (!updatedFieldValues) {
      return;
    }
    Object.entries(updatedFieldValues).forEach((entry) => {
      setFieldValue(entry[0], entry[1]);
    });
  };

  const handleDeleteHandlingUnit = (handlingUnitIndex: number, handlingUnitId: string) => {
    const stopIndex = values.stops.findIndex((stop) =>
      stop.handling_units.some((handlingUnit) => handlingUnit.id === handlingUnitId)
    );
    const handlingUnit = values.stops[stopIndex].handling_units[handlingUnitIndex];
    const hasItemsToRemove = Boolean(handlingUnit.pieces.length);
    if (hasItemsToRemove) {
      handleRemoveAll(handlingUnitIndex, handlingUnitId);
    }
    // if deleted handling unit is active, set the new active unit to the first available id
    if (handlingUnitId === values?.active_handling_unit_id) {
      const nextActive = values.stops.find((stop) => stop.handling_units.length)?.handling_units[0].id || '';
      // if there is only one handling unit left, set to falsy value
      setFieldValue('active_handling_unit_id', nextActive === handlingUnitId ? '' : nextActive);
    }
  };

  const allLineItemsInHandlingUnits = values.stops
    .map((stop) => stop.handling_units.map((handlingUnit) => handlingUnit.pieces))
    .flat(2);

  // should only look for quantity availability on line items that match stop for active handling unit
  const matchingLineItems = getLineItemsThatMatchStop(values.line_items, values.stops, values.active_handling_unit_id);
  const hasAvailableQuantity = matchingLineItems.some((lineItem) => {
    const hasPieces = lineItem.total_pieces;
    const foundInHandlingUnit = allLineItemsInHandlingUnits?.some((handlingUnit) => handlingUnit.id === lineItem.id);
    if (!foundInHandlingUnit) {
      return true;
    }
    return hasPieces;
  });

  const handlingUnitTotals = ({stopIndex, handlingUnitIndex}: {stopIndex: number; handlingUnitIndex: number}) => {
    const handlingUnit = values.stops[stopIndex].handling_units[handlingUnitIndex];
    const totalPieces = handlingUnit.pieces.reduce((total, piece) => (total += piece.total_pieces || 0), 0);
    return {
      serialNumber: handlingUnit.serial_number || '--',
      units: handlingUnit.pieces.length,
      pieces: totalPieces,
      weight: handlingUnit.package_weight || 0,
      weightUnit: handlingUnit.weight_unit
    };
  };

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden lg:flex-row">
      {/* Left column of available shipping items */}
      <div className="flex size-full flex-col gap-4 overflow-auto p-4">
        <h5 className="m-0 font-bold">Shipping Items</h5>
        {!values.active_handling_unit_id ? (
          <InlineNotification title="Add a Handling Unit to Get Started">
            <span>Add handling units on the right to start adding line item pieces.</span>
          </InlineNotification>
        ) : null}
        <div className="flex flex-col gap-4 rounded bg-sw-background p-4">
          <div className="flex items-center justify-between">
            <h5 className="m-0 font-bold">Remaining Available Shipping Items with Quantity of 1</h5>
            <Button
              disabled={!values.active_handling_unit_id || !hasAvailableQuantity}
              size="sm"
              onClick={handleAddAll}
            >
              Add All Items
            </Button>
          </div>
          <span className="text-xs italic text-sw-disabled-text">
            Select shipping items to add to the selected handling unit on the right.
          </span>
          <LineItemsTable
            lineItems={values.line_items}
            // sending all handling units' pieces (line items) to table
            handlingUnitLineItems={allLineItemsInHandlingUnits}
            isDisabled={!values.active_handling_unit_id}
          />
        </div>
      </div>

      {/* Middle seperator */}
      <div
        aria-hidden
        className="w-full border-b border-sw-border px-2 lg:absolute lg:bottom-20 lg:left-1/2 lg:top-4 lg:w-auto lg:border-b-0 lg:border-l lg:p-0"
      />

      {/* Right column of stops and handling units */}
      <div className="flex size-full flex-col gap-4 overflow-auto p-4">
        <h5 className="m-0 font-bold">Handling Units</h5>
        {values.stops.map((stop, stopIndex) => (
          <Card
            key={stop.id}
            title={`${stop.pickup.location.address.formatted_address || '--'} > ${
              stop.dropoff.location.address.formatted_address || '--'
            }`}
            draggableProvided={{}}
            isCollapsed={collapsedStops.includes(stop.id)}
            onCollapse={() =>
              setCollapsedStops((prev) => {
                if (prev.includes(stop.id)) {
                  return prev.filter((s) => s !== stop.id);
                }
                return [...prev, stop.id];
              })
            }
            isCollapsible
          >
            <CollapsibleCardContent>
              <FieldArray
                name={`stops[${stopIndex}].handling_units`}
                render={(arrayHelpers) => (
                  <FlexBox direction="col" gap="m">
                    {stop.handling_units?.map((handlingUnit, i) => (
                      <Card
                        key={handlingUnit.id}
                        title={
                          <button
                            type="button"
                            name="active_handling_unit_id"
                            onClick={() => setFieldValue('active_handling_unit_id', handlingUnit.id)}
                          >
                            <FlexBox gap="s" items="center">
                              {/* this is meant to mimic the look of a radio button, but is not functional (has no click listener) */}
                              <div
                                aria-hidden
                                className={`flex size-4 items-center justify-center rounded-full border-2 ${
                                  handlingUnit.id === values.active_handling_unit_id
                                    ? 'border-sw-active'
                                    : 'border-sw-border'
                                }`}
                              >
                                {handlingUnit.id === values.active_handling_unit_id ? (
                                  <div className="size-2 rounded-full bg-sw-active" />
                                ) : null}
                              </div>
                              <span>Handling Unit {i + 1}</span>
                              <span className="text-xs font-normal text-sw-disabled-text">
                                • {handlingUnitTotals({stopIndex, handlingUnitIndex: i}).serialNumber} •{' '}
                                {handlingUnitTotals({stopIndex, handlingUnitIndex: i}).units} Units •{' '}
                                {handlingUnitTotals({stopIndex, handlingUnitIndex: i}).pieces} Pieces •{' '}
                                {handlingUnitTotals({stopIndex, handlingUnitIndex: i}).weight}{' '}
                                {handlingUnitTotals({stopIndex, handlingUnitIndex: i}).weightUnit}
                              </span>
                            </FlexBox>
                          </button>
                        }
                        draggableProvided={{}}
                        isCollapsible
                        isCollapsed={handlingUnit.id !== values.active_handling_unit_id}
                        onCollapse={() => {
                          if (handlingUnit.id === values.active_handling_unit_id) {
                            setFieldValue('active_handling_unit_id', '');
                          }
                          setFieldValue('active_handling_unit_id', handlingUnit.id);
                        }}
                        actions={
                          <IconButton
                            aria-label="Remove handling unit"
                            onClick={() => {
                              handleDeleteHandlingUnit(i, handlingUnit.id);
                              arrayHelpers.remove(i);
                            }}
                            iconName="TrashOutlined"
                          />
                        }
                        className={
                          values.active_handling_unit_id === handlingUnit.id ? 'border-2 border-sw-active' : ''
                        }
                      >
                        <CollapsibleCardContent>
                          <FlexBox direction="col" gap="m">
                            {/* Form inputs */}
                            <div className="@container">
                              <div className="grid grid-cols-1 gap-3 @sm:grid-cols-2 @2xl:grid-cols-4">
                                <Field
                                  simpleValue
                                  component={FormikSelect}
                                  name={`stops[${stopIndex}].handling_units[${i}].package_type`}
                                  label="Handling type"
                                  options={packagingTypeOptions}
                                />
                                <Field
                                  component={FormikTextInput}
                                  name={`stops[${stopIndex}].handling_units[${i}].package_weight`}
                                  label="Net Weight"
                                />
                                <Field
                                  isClearable={false}
                                  simpleValue
                                  component={FormikSelect}
                                  name={`stops[${stopIndex}].handling_units[${i}].weight_unit`}
                                  label="Weight Unit"
                                  options={[
                                    {label: 'LBS', value: ShipmentLineItemWeightUnitEnum.Lb},
                                    {label: 'KGS', value: ShipmentLineItemWeightUnitEnum.Kg}
                                  ]}
                                />
                                <Field
                                  simpleValue
                                  component={FormikSelect}
                                  name={`stops[${stopIndex}].handling_units[${i}].freight_class`}
                                  label="Freight Class"
                                  options={freightClassOptions}
                                />
                                <Field
                                  component={FormikTextInput}
                                  name={`stops[${stopIndex}].handling_units[${i}].length`}
                                  label="Length"
                                />
                                <Field
                                  component={FormikTextInput}
                                  name={`stops[${stopIndex}].handling_units[${i}].width`}
                                  label="Width"
                                />
                                <Field
                                  simpleValue
                                  component={FormikTextInput}
                                  name={`stops[${stopIndex}].handling_units[${i}].height`}
                                  label="Height"
                                />
                                <Field
                                  isClearable={false}
                                  simpleValue
                                  component={FormikSelect}
                                  name={`stops[${stopIndex}].handling_units[${i}].length_unit`}
                                  label="Dimension Unit"
                                  options={[
                                    {label: 'CM', value: ShipmentLineItemLengthUnitEnum.Cm},
                                    {label: 'IN', value: ShipmentLineItemLengthUnitEnum.In},
                                    {label: 'M', value: ShipmentLineItemLengthUnitEnum.M},
                                    {label: 'FT', value: ShipmentLineItemLengthUnitEnum.Ft}
                                  ]}
                                />
                              </div>
                            </div>
                            <Divider />
                            <FlexBox items="center" justify="between">
                              <h5 className="m-0 font-bold">Shipping Items</h5>
                              <Button
                                onClick={() => handleRemoveAll(i, handlingUnit.id)}
                                variant="tertiary"
                                disabled={!handlingUnit.pieces.length}
                                isCompact
                              >
                                Remove All
                              </Button>
                            </FlexBox>
                            {!handlingUnit.pieces.length ? (
                              <span className="text-center text-lg font-bold text-sw-disabled-text">
                                No Shipping Items Added
                              </span>
                            ) : (
                              <LineItemsTable lineItems={handlingUnit.pieces} isDisabled={false} isHandlingUnit />
                            )}
                          </FlexBox>
                        </CollapsibleCardContent>
                      </Card>
                    ))}
                    <Button
                      width="full"
                      onClick={() => {
                        // new handling units are provided with a temp id (for react key mapping)
                        const tempId = `TEMP-${v4()}`;
                        // pushing default values to create a new handling unit
                        arrayHelpers.push({
                          id: tempId,
                          width: 48,
                          length: 40,
                          height: 60,
                          length_unit: ShipmentLineItemLengthUnitEnum.In,
                          package_type: ProductPackageType.Plt,
                          weight_unit: ShipmentLineItemWeightUnitEnum.Lb,
                          pieces: []
                        });
                        setFieldValue('active_handling_unit_id', tempId);
                      }}
                      color="primary"
                      variant="secondary"
                      iconName="AddCircleOutlined"
                    >
                      Add Handling Unit
                    </Button>
                  </FlexBox>
                )}
              />
            </CollapsibleCardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
