import moment from 'moment';
import PropTypes from 'prop-types';
import {FileDisplay} from '@shipwell/shipwell-ui';
import {FormGroup, InputGroup, Checkbox} from 'react-bootstrap';
import {useQuery} from '@tanstack/react-query';
import {getShipmentDocuments} from '../../api/shipment';
import ShipwellLoader from 'App/common/shipwellLoader';
import {SHIPMENT_DOCS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const ShipmentInvoiceDocs = ({onSelect, selected, id}) => {
  const docsQuery = useQuery([SHIPMENT_DOCS_QUERY_KEY, id], () => getShipmentDocuments(id));
  const loadedWithResults = docsQuery.isSuccess && docsQuery.data?.body?.results?.length > 0;

  return (
    <div className="relative flex flex-col px-4">
      <h3>Documents</h3>
      {loadedWithResults ? (
        docsQuery.data.body.results.map((el) => {
          const isChecked = selected.some((doc) => doc.id === el.id);
          return (
            <FormGroup key={el.id}>
              <InputGroup>
                <Checkbox
                  checked={isChecked}
                  onChange={() => {
                    onSelect(el);
                  }}
                >
                  <div className="mx-2 w-10">
                    <FileDisplay
                      maxHeight={40}
                      maxWidth={40}
                      fileURL={el.file}
                      fileName={el.filename}
                      isThumbnail
                      altText={el.description}
                    />
                  </div>
                  <div className="w-full">
                    <div className="grid grid-cols-3 gap-2">
                      <span className="truncate">{el.filename}</span>
                      <span className="truncate">{el.type.replace(/_/g, ' ')}</span>
                      <span>{moment(el.created_at).format('MM/DD/YY')}</span>
                    </div>
                    <em>Description: {el.description}</em>
                  </div>
                </Checkbox>
              </InputGroup>
            </FormGroup>
          );
        })
      ) : (
        <div className="none">No documents</div>
      )}
      {docsQuery.isInitialLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <ShipwellLoader loading />
        </div>
      ) : null}
    </div>
  );
};

ShipmentInvoiceDocs.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
};

export default ShipmentInvoiceDocs;
