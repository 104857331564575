import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ShipmentTags from 'App/containers/Book/FormSections/ShipmentTags';

const ShipmentTagsCard = ({formValues, form, dispatch, className, isEditForm}) => {
  return (
    <Paper className={className}>
      <ShipmentTags formValues={formValues} form={form} dispatch={dispatch} isEditForm={isEditForm} />
    </Paper>
  );
};
ShipmentTagsCard.propTypes = {
  formValues: PropTypes.object,
  form: PropTypes.string,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  isEditForm: PropTypes.bool
};
ShipmentTagsCard.defaultProps = {
  formValues: {},
  form: '',
  dispatch: () => {},
  className: '',
  isEditForm: false
};
export default ShipmentTagsCard;
