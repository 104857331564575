import {useState} from 'react';
import {flexRender} from '@tanstack/react-table';
import {Toast} from '@shipwell/shipwell-ui';
import {Shipment, EquipmentType} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {getColumns} from './marketPlaceTendersColumns';
import {revokeTender} from 'App/api/tenders/typed';
import {
  TableContainer,
  Table,
  TableHeaderRow,
  TableHeader,
  TableHeaderSortIcon,
  TableRow,
  TableCell
} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {useGetTenders, useUserMe} from 'App/data-hooks';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {TENDERS_KEY} from 'App/data-hooks/queryKeys';

export const MarketplaceTenders = ({
  selectedShipment,
  compactView = false
}: {
  selectedShipment: Shipment;
  compactView?: boolean;
}) => {
  const [tenderRevokeSuccess, setTenderRevokeSuccess] = useState(false);
  const {data: {company} = {}} = useUserMe();
  const {data: equipmentTypes} = useEquipmentTypesQuery();
  const {data: {results: tendersList} = {}} = useGetTenders(
    {shipmentId: selectedShipment.id, createdByCompanyId: company?.id as string},
    {enabled: !!company?.id}
  );
  const queryClient = useQueryClient();

  const tenders = equipmentTypes
    ? tendersList?.map((tender) => ({
        ...tender,
        equipment_type: equipmentTypes.find(
          (equipmentType) => equipmentType.machine_readable === tender.equipment_type
        ) as EquipmentType
      }))
    : [];

  const {mutate: revokeTenderById} = useMutation((tenderId: string) => revokeTender({tenderId}), {
    onSuccess: () => {
      setTenderRevokeSuccess(true);
      void queryClient.invalidateQueries([TENDERS_KEY]);
    },
    onError: (error) => console.error(error)
  });

  const table = useTypedTable({
    data: tenders || [],
    columns: getColumns({compactView, revokeTenderById})
  });

  return (
    <div className="marketplace__bids">
      {tenders && tenders.length > 0 ? (
        <div className="table__container h-auto">
          <TableContainer>
            <Table
              head={table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeader
                      key={header.id}
                      width={header.getSize()}
                      sortDirection={header.column.getIsSorted()}
                      onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                      onResize={header.getResizeHandler()}
                    >
                      <div className="flex items-center gap-1">
                        {header.column.getCanSort() ? (
                          <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                        ) : null}
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </TableHeader>
                  ))}
                </TableHeaderRow>
              ))}
              body={table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))}
            />
          </TableContainer>
        </div>
      ) : (
        <div className="marketplace__bids-noBids text-center">
          <>
            <h3>No tenders on this shipment</h3>
          </>
        </div>
      )}
      <Toast
        show={tenderRevokeSuccess}
        variant="success"
        title="Tender Revoked"
        onClose={() => setTenderRevokeSuccess(false)}
      >
        Tender Revoked Successfully!
      </Toast>
    </div>
  );
};
