import {useToastManager} from '@shipwell/shipwell-ui';
import {useMutation, useQuery} from '@tanstack/react-query';
import {trackingProviderCredentialsList, trackingProviderCredentialsSave} from 'App/api/locus/trackingCredentials';
import {parseV3ApiError} from 'App/api/typedUtils';
import {MacropointDetailsFormValues} from '../useMacropointDetailsForm/macropointDetailsFormValues';

export function useMacropointService() {
  const {enqueueToast} = useToastManager();

  const getQuery = useQuery<MacropointDetailsFormValues>({
    queryKey: ['useMacropointService'],
    queryFn: async () => {
      const response = await trackingProviderCredentialsList({
        provider: 'MACROPOINT'
      });

      const provider = response.data.find((item) => item.provider === 'MACROPOINT');

      return {
        partnerId: provider?.client_id || '',
        password: provider?.client_secret || ''
      };
    }
  });

  const connectMutaion = useMutation({
    mutationFn: async (params: MacropointDetailsFormValues) => {
      const response = await trackingProviderCredentialsSave({
        credentialsInput: {
          client_id: params.partnerId,
          client_secret: params.password,
          provider: 'MACROPOINT'
        }
      });

      return response;
    },
    onSuccess: () => {
      enqueueToast({
        variant: 'success',
        title: 'Success',
        content: 'Credentials saved.'
      });
    },
    onError: (error) => {
      const {detail} = parseV3ApiError(error);
      enqueueToast({
        variant: 'error',
        title: 'Error',
        content: detail
      });
    }
  });

  return {
    isFetching: getQuery.isFetching,
    isSaving: connectMutaion.isLoading,
    data: getQuery.data,
    connect: connectMutaion.mutateAsync
  };
}
