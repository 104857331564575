import {number, string} from 'yup';

export const nmfcCodeSchema = number()
  .nullable()
  .typeError('The NMFC code must be a number between two and six digits long.')
  .integer('The NMFC code must be a number between two and six digits long.')
  .min(10, 'The NMFC code must be at least two digits long.')
  .max(999999, 'The NMFC code must be at most six digits long.');

export const nmfcSubCodeSchema = string()
  .nullable()
  .typeError('The NMFC sub code must be a two digit number.')
  .length(2, 'The NMFC sub code must be a two digit number.')
  .matches(/^\d\d$/, {message: 'The NMFC sub code must be a two digit number.', excludeEmptyString: true});

/**
 * Validate an NMFC code using the yup schema. Throws a ValidationError if the given code is not
 * valid.
 */
export default function validateNMFCCode(code: string | number) {
  return nmfcCodeSchema.validateSync(code);
}

/**
 * Validate an NMFC sub code using the yup schema. Throws a ValidationError if the given sub code is
 * not valid.
 */
export function validateNMFCSubCode(subCode: string | number) {
  return nmfcSubCodeSchema.validateSync(subCode);
}
