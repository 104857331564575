import {InitialValuesOrder, InitialValuesOrderDates} from '../initialValues';
import {SharedTypeEnum} from '../../AddOrdersToShipmentSharedStartEndPoint';
import {mapAddressToBaseAddressSchema} from 'App/containers/purchaseOrders/list/components/ChangeCustomerModalContent/mappers';

export const hasBothPlanWindows = (orders: InitialValuesOrder): boolean => {
  if (!orders) return false;
  const {ship_from, ship_to} = orders;

  const hasShipFromPlanWindow =
    ship_from?.shipping_requirements?.plan_window?.start && ship_from?.shipping_requirements?.plan_window?.end;

  const hasShipToPlanWindow =
    ship_to?.shipping_requirements?.plan_window?.start && ship_to?.shipping_requirements?.plan_window?.end;

  return !!hasShipFromPlanWindow && !!hasShipToPlanWindow;
};

interface AddOrdersToShipmentPayloadAdapterParams {
  initialValues: InitialValuesOrderDates;
  selectedOrderIds: string[];
  shipmentId: string;
}

export const addOrdersToShipmentPayloadAdapter = ({
  initialValues,
  selectedOrderIds,
  shipmentId
}: AddOrdersToShipmentPayloadAdapterParams) => {
  return {
    orderIds: selectedOrderIds,
    shipmentId,
    sharedOrigin:
      initialValues.shared_address &&
      initialValues.sharedType === SharedTypeEnum.Start &&
      initialValues.shared_address.country !== '' &&
      initialValues.shared_address.country
        ? {
            ...mapAddressToBaseAddressSchema(initialValues.shared_address),
            start_time: initialValues.start_time ?? undefined,
            end_time: initialValues.end_time ?? undefined,
            address_book_entry: initialValues.shared_address.id ?? undefined
          }
        : undefined,
    sharedDestination:
      initialValues.shared_address &&
      initialValues.sharedType === SharedTypeEnum.End &&
      initialValues.shared_address.country !== '' &&
      initialValues.shared_address.country
        ? {
            ...mapAddressToBaseAddressSchema(initialValues.shared_address),
            start_time: initialValues.start_time ?? undefined,
            end_time: initialValues.end_time ?? undefined,
            address_book_entry: initialValues.shared_address.id ?? undefined
          }
        : undefined
  };
};
