import {Field} from 'formik';
import {FormikTextInput, FormikSelect, FormikCheckbox} from '@shipwell/shipwell-ui';
import {
  ShipmentLineItem,
  TotalWeightOverride,
  BaseShipmentTotalDeclaredValueCurrencyEnum,
  Shipment,
  TotalWeightOverrideUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {calculateShipmentTotals, checkShipmentModes} from 'App/utils/globalsTyped';
import {formatCurrency, METRIC} from 'App/utils/internationalConstants';
import './styles.scss';
import {UnitPreferences} from 'App/reducers/types';
import {useCarrierRelationships, useUserPermissions} from 'App/data-hooks';

interface ShipmentItemTotalsProps {
  lineItems: Array<ShipmentLineItem>;
  totalWeight?: TotalWeightOverride;
  totalValue?: number | null | string;
  totalQuantity?: number | null;
  totalValueCurrency: BaseShipmentTotalDeclaredValueCurrencyEnum;
  unitPreferences?: UnitPreferences;
  shipmentDetails?: Shipment;
  values: {
    manual_total_weight?: boolean;
    manual_total_value?: boolean;
    manual_quantity?: boolean;
    bypass_carrier_insurance_coverage?: boolean;
  };
  showManualOverrideFields: boolean;
}

/**
 * Shipment Items Totals Fields
 * @param {*} props
 */
const ShipmentItemTotals = ({
  lineItems,
  totalWeight,
  totalValue,
  totalValueCurrency,
  totalQuantity,
  unitPreferences,
  values,
  showManualOverrideFields,
  shipmentDetails
}: ShipmentItemTotalsProps) => {
  const {
    units,
    volume,
    weight: calculatedWeight,
    density: calculatedDensity,
    value: calculatedValue,
    system,
    currency: calculatedValueCurrency
  } = calculateShipmentTotals({line_items: lineItems, unitPreferences});
  //check if total weight is a valid number
  const weight =
    values?.manual_total_weight && Number(totalWeight?.value) ? Number(totalWeight?.value) : calculatedWeight;
  const isTotalValueOverriden = values?.manual_total_value && Number(totalValue);
  const value = isTotalValueOverriden ? totalValue : calculatedValue;
  const currency = isTotalValueOverriden ? totalValueCurrency || unitPreferences?.currency : calculatedValueCurrency;
  const density = Number(totalWeight?.value) && volume ? Number(weight / volume) : Number(calculatedDensity);
  const quantity = values?.manual_quantity && totalQuantity && Number(totalQuantity) ? totalQuantity : units;

  const companyManagementPermissions = useUserPermissions([
    'company.administrator',
    'company.view_company_details',
    'company.update_company_details',
    'carrier_compliance.override_insurance_limit_check'
  ]);

  const hasManageCompanyPermission =
    companyManagementPermissions['company.administrator'] ||
    companyManagementPermissions['company.view_company_details'] ||
    companyManagementPermissions['company.update_company_details'];

  const carrierTenantId = shipmentDetails?.current_carrier?.id || undefined;
  const {carrierRelationshipsQuery} = useCarrierRelationships(
    {vendorId: carrierTenantId},
    {enabled: !!carrierTenantId}
  );
  const carrierRelationship = carrierRelationshipsQuery.data?.results?.[0];

  const hasOverrideInsurancePermission =
    companyManagementPermissions['carrier_compliance.override_insurance_limit_check'] &&
    carrierRelationship?.is_carrier_insurance_compliance_enabled;

  const {hasFTL} = checkShipmentModes(shipmentDetails?.mode);
  const showOverrideInsuranceCheckbox = hasFTL && hasManageCompanyPermission;

  return (
    <div className="quote-lineitem-totals-v2">
      {showManualOverrideFields ? (
        <div className="mb-4 grid">
          <Field
            component={FormikCheckbox}
            checked={values?.manual_total_weight}
            name="manual_total_weight"
            label="Enter Total Shipment Weight Override"
          />
          {values?.manual_total_weight ? (
            <div className="mb-4 grid grid-cols-2 gap-4">
              <Field
                name="total_weight_override_value"
                label="Total Shipment Weight"
                placeholder="Enter Weight"
                component={FormikTextInput}
              />
              <Field
                clearable={false}
                label="Unit"
                simpleValue
                name="total_weight_override_unit"
                options={[
                  {value: 'LB', label: 'LB'},
                  {value: 'KG', label: 'KG'}
                ]}
                component={FormikSelect}
              />
            </div>
          ) : null}
          <Field
            component={FormikCheckbox}
            checked={values?.manual_total_value}
            name="manual_total_value"
            label="Enter Declared Value Override Amount"
          />
          {values?.manual_total_value ? (
            <div className="mb-4 grid grid-cols-2 gap-4">
              <Field
                name="total_declared_value"
                label={`Total Shipment Value (${
                  currency ||
                  shipmentDetails?.preferred_currency ||
                  unitPreferences?.currency ||
                  BaseShipmentTotalDeclaredValueCurrencyEnum.Usd
                })`}
                placeholder="Enter Value"
                component={FormikTextInput}
              />
            </div>
          ) : null}
          <Field
            component={FormikCheckbox}
            checked={values?.manual_quantity}
            name="manual_quantity"
            label="Enter Total Shipment Quantity Override"
          />
          {values?.manual_quantity ? (
            <div className="mb-4 grid grid-cols-2 gap-4">
              <Field
                name="total_quantity_override"
                label="Total Shipment Quantity"
                placeholder="Enter Quantity"
                component={FormikTextInput}
              />
            </div>
          ) : null}
          {showOverrideInsuranceCheckbox ? (
            <Field
              component={FormikCheckbox}
              checked={values?.bypass_carrier_insurance_coverage}
              name="bypass_carrier_insurance_coverage"
              label="Override Carrier Insurance Coverage"
              disabled={!hasOverrideInsurancePermission}
            />
          ) : null}
        </div>
      ) : null}
      <div className="quote-lineitem-totals-body">
        <span>
          <strong>Declared Value:</strong>
          {currency ? formatCurrency(value, currency) : '--'}
        </span>
        <span>
          <strong>Quantity:</strong>
          {quantity > 0 ? quantity : <strong>--</strong>}
        </span>
        <span>
          {(!Number(totalWeight?.value) && system === METRIC) ||
          (totalWeight?.value &&
            Number(totalWeight.value) > 0 &&
            totalWeight.unit === TotalWeightOverrideUnitEnum.Kg) ? (
            <strong>Weight (kg):</strong>
          ) : (
            <strong>Weight (lbs):</strong>
          )}
          {weight > 0 ? (
            parseFloat(weight.toFixed(2)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          ) : (
            <strong>--</strong>
          )}
        </span>
        <span>
          <strong>
            Volume ({system === METRIC ? 'm' : 'ft'}
            <sup>3</sup>):
          </strong>
          {volume > 0 ? volume.toFixed(2) : <strong>--</strong>}
        </span>
        <span>
          <strong>
            Density ({system === METRIC ? 'kg/m' : 'lbs/ft'}
            <sup>3</sup>):
          </strong>
          {density > 0 ? density.toFixed(2) : <strong>--</strong>}
        </span>
      </div>
    </div>
  );
};
export default ShipmentItemTotals;
