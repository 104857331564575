import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Modal, Title, FormikTextInput, FormikDateTimePicker, FormikSelect, FormikCheckbox} from '@shipwell/shipwell-ui';
import {Field, Formik, Form, useFormikContext} from 'formik';
import {LegStageStageTypeEnum} from '@shipwell/corrogo-sdk';
import first from 'lodash/first';
import PropTypes from 'prop-types';
import HazmatCodesField from 'App/formComponents/formSections/hazmatCodesField';
import getNil from 'App/utils/getNil';
import Loader from 'App/common/shipwellLoader';
import {
  grossWeightUnitTypeOptions,
  baseOverweightMessage,
  ContainerTypeSpecialFields,
  containerValidationSchema,
  buildContainerPayload
} from 'App/containers/shipments/drayage/components/ContainersForm';
import {useDrayageLeg, useCreateOrUpdateLeg, useV3Shipment, useShipmentFromLeg} from 'App/data-hooks';
import {updateDrayageLegStopsDetail} from 'App/api/corrogo';
import {checkIsOverweight, containerTypeOptions} from 'App/containers/shipments/utils/typed';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';

const ContainerFields = () => {
  const {values} = useFormikContext();
  const isOverweight = checkIsOverweight(values.gross_weight, values.gross_weight_unit);
  const overweightMessage = isOverweight ? baseOverweightMessage : null;
  const containerType = values.container_type;

  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        IDs
      </Title>
      <div className="mb-4 grid grid-cols-2 gap-4 border-b border-sw-border pb-4">
        <Field name="container_number" label="Container #" component={FormikTextInput} />
        <Field name="seal_number" label="Seal #" component={FormikTextInput} />
      </div>
      <Title className="pb-2" variant="sectionTitle">
        Container Items
      </Title>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <Field
            name="container_type"
            label="Container Type"
            simpleValue
            component={FormikSelect}
            options={containerTypeOptions}
          />
          <ContainerTypeSpecialFields buildFieldName={(name) => name} requiredFields containerType={containerType} />
        </div>
        <Field name="commodity" label="Commodity" component={FormikTextInput} />
      </div>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div className="grid grid-cols-5">
          <Field
            className="col-span-4"
            textInputClassname="rounded-r-none"
            name="gross_weight"
            component={FormikTextInput}
            label="Total Weight"
            required
            type="number"
            isWarning={isOverweight}
            message={overweightMessage}
          />
          <Field
            className="min-w-0"
            controlClassName="rounded-l-none"
            name="gross_weight_unit"
            label="Unit"
            required
            simpleValue
            clearable={false}
            component={FormikSelect}
            options={grossWeightUnitTypeOptions}
          />
        </div>
      </div>

      <div className="mb-4 grid grid-cols-2 gap-4 border-b border-sw-border pb-4">
        {values.hazmat ? <HazmatCodesField name="hazmat_code" label="Hazmat Code" required /> : null}

        <Field name="hazmat" component={FormikCheckbox} label="Hazmat" />
      </div>
      <Title className="pb-2" variant="sectionTitle">
        Dates
      </Title>
      <div className="mb-10 grid grid-cols-2 gap-4">
        <Field
          name="container_return_date"
          label="Container Return Date"
          showTimeSelect={false}
          component={FormikDateTimePicker}
        />
      </div>
    </>
  );
};

export const ContainerPickupDeliveryDateModal = ({showModal, shipmentId, onClose, selectedContainers}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {
    context: {pickupStopTitle}
  } = shipmentQuery;
  const queryClient = useQueryClient();

  const updateDrayageLegStopsDetailMutation = useMutation(({legId, sequenceId, data}) =>
    updateDrayageLegStopsDetail(legId, sequenceId, data)
  );

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading={shipmentQuery.isInitialLoading} />;
  }

  const handleSubmit = async (values) => {
    const {pickup_date: pickupDate, delivery_date: deliveryDate} = values;

    const selectedContainerIds = selectedContainers.map((selectedContainer) => selectedContainer.id);

    const stagesToUpdate = shipmentQuery.data?.stages.filter(
      (stage) => stage.stage_type === LegStageStageTypeEnum.Leg && selectedContainerIds.includes(stage.leg.id)
    );

    const pickupMutations = pickupDate
      ? stagesToUpdate.map((stageToUpdate) => {
          const pickupStop = stageToUpdate.leg.stops[0];

          return updateDrayageLegStopsDetailMutation.mutateAsync({
            legId: stageToUpdate.leg.id,
            sequenceId: pickupStop.sequence_number,
            data: {...pickupStop, plan_window: {...pickupStop.plan_window, ready: pickupDate}}
          });
        })
      : [];

    const deliveryMutations = deliveryDate
      ? stagesToUpdate.map((stageToUpdate) => {
          const deliveryStop = stageToUpdate.leg.stops[1];

          return updateDrayageLegStopsDetailMutation.mutateAsync({
            legId: stageToUpdate.leg.id,
            sequenceId: deliveryStop.sequence_number,
            data: {...deliveryStop, plan_window: {...deliveryStop.plan_window, ready: deliveryDate}}
          });
        })
      : [];

    await Promise.allSettled([...pickupMutations, ...deliveryMutations]);

    queryClient.invalidateQueries([SHIPMENTS_QUERY_KEY, shipmentId]);

    onClose();
  };

  return (
    <Modal
      title={`Update ${pickupStopTitle} & Delivery Dates`}
      onClose={onClose}
      show={showModal}
      footerComponent={null}
      bodyVariant="disableOverflowScroll"
    >
      <Formik initialValues={{pickup_date: null, delivery_date: null}} onSubmit={handleSubmit}>
        <Form>
          <div className="mb-10 grid grid-cols-2 gap-4">
            <Field name="pickup_date" label={`${pickupStopTitle} Date`} component={FormikDateTimePicker} />
            <Field name="delivery_date" label="Delivery Date" component={FormikDateTimePicker} />
          </div>
          <ModalFormFooter onCancel={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};

ContainerPickupDeliveryDateModal.propTypes = {
  showModal: PropTypes.bool,
  shipmentId: PropTypes.string,
  onClose: PropTypes.func,
  selectedContainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};

const ContainerModal = ({legId, onClose, showModal}) => {
  const drayageLegQuery = useDrayageLeg(legId);
  const shipmentQuery = useShipmentFromLeg(drayageLegQuery.data);

  const orderId = first(getNil(shipmentQuery, 'data.customer.order_ids', []));

  const {updateLegItemMutation} = useCreateOrUpdateLeg(legId);

  if (drayageLegQuery.isLoading || shipmentQuery.isLoading) {
    return <Loader loading />;
  }

  const {context} = drayageLegQuery;
  const {containerFormValues} = context;
  const initialValues = getNil(containerFormValues, 'containers[0]', {});

  const handleSubmit = (values) => {
    const payload = buildContainerPayload(orderId, values);

    updateLegItemMutation.mutateAsync({
      legId: legId,
      legItemId: values.id,
      legItemData: payload
    });

    onClose();
  };

  return (
    <Modal
      title="Edit Container Details"
      onClose={onClose}
      show={showModal}
      footerComponent={null}
      bodyVariant="disableOverflowScroll"
    >
      <Formik initialValues={initialValues} validationSchema={containerValidationSchema} onSubmit={handleSubmit}>
        <Form>
          <ContainerFields />
          <ModalFormFooter onCancel={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};

ContainerModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.func,
  legId: PropTypes.string
};

ContainerModal.defaultProps = {
  showModal: true,
  onClose: () => {}
};

export default ContainerModal;
