export interface Status {
  id: string;
  label: string;
  restrict_to_modes: string[];
}

export function getPermittedStatusForShipmentMode(
  statusList: Status[] | undefined,
  shipmentModeCode: string | undefined
) {
  if (!shipmentModeCode) {
    return statusList;
  }

  const result = statusList?.filter((status) => status.restrict_to_modes.includes(shipmentModeCode));
  return result || [];
}
