import {PointOfContactNotificationPreferences} from '@shipwell/backend-core-sdk';
import {ComponentClass} from 'react';

export enum StopTypeId {
  Pickup = 'pickup',
  Delivery = 'delivery',
  ContainerPickup = 'containerPickup',
  ContainerReturn = 'containerReturn'
}

export enum DisplayTypes {
  ModalDisplay = 'Modal',
  CardDisplay = 'Card'
}

export type ContactType = {
  id?: string;
  first_name: string | null;
  last_name: string | null;
  phone_number: string | null;
  email: string | null;
  preferences: unknown;
};

export type StopTypeType = {
  id: StopTypeId;
  label: string;
  component: ComponentClass<{
    push: (contact: ContactType) => void;
    remove: (index: number) => void;
    stopType?: StopTypeType;
    display: DisplayTypes;
  }>;
};

export type AddressBookEntryType = {
  id: string;
  city: string;
  country: string;
  postal_code: string;
  location_name: string;
  external_reference: string;
  point_of_contacts?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    preferences: PointOfContactNotificationPreferences;
  }[];
  pickup_number?: {
    value: string;
    qualifier: string;
  };
  address_1: string;
  address_2: string;
  state_province: string;
  company_name: string;
  instructions: string;
  formatted_address?: string;
  dock_hours_end?: string | null;
  dock_hours_start?: string | null;
};

export type StopAddressBookEntry = AddressBookEntryType & {
  sequenceNumber: number;
  appointment_begin: string;
  plan_window_ready: string;
  actual_arrival_time: string;
  actual_departure_time: string;
  pickup_number: {
    value: string;
    qualifier: string;
  };
};

export type StopFormValues =
  | {pickup: AddressBookEntryType}
  | {delivery: AddressBookEntryType}
  | {containerPickup: AddressBookEntryType}
  | {containerReturn: AddressBookEntryType};

export const DefaultStopFormValues = {
  id: '',
  company_name: '',
  address_1: '',
  address_2: '',
  city: '',
  state_province: '',
  country: '',
  postal_code: '',
  formatted_address: '',
  instructions: '',
  point_of_contacts: [],
  pickup_number: {
    value: '',
    qualifier: 'PICKUP_NUMBER'
  },
  appointment_begin: '',
  plan_window_ready: '',
  actual_arrival_time: '',
  actual_departure_time: '',
  sequenceNumber: -1,
  gate: '',
  location_name: '',
  external_reference: ''
} as StopAddressBookEntry;
