import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ShipmentMode} from '@shipwell/backend-core-sdk';
import {MODES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {fetchShipmentModesPromise} from 'App/api/shipment/typed';

export const useModesQuery = (queryOptions?: Omit<UseQueryOptions<ShipmentMode[]>, 'queryKey' | 'queryFn'>) => {
  const getShipmentModesQuery = useQuery(
    [MODES_QUERY_KEY],
    async () => {
      const response = await fetchShipmentModesPromise();
      return response.data;
    },
    queryOptions
  );
  return getShipmentModesQuery;
};
