import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withRouter} from 'react-router';
import get from 'lodash/get';
import {SvgIcon, Select} from '@shipwell/shipwell-ui';
import {Tag} from '../index';
import {fetchTags} from 'App/actions/_shipments';
import withStatusToasts from 'App/components/withStatusToasts';
import './styles.scss';
import {PROPTYPE_TAGS} from 'App/utils/propTypeConstants';

// This component allows the user to choose a single tag from a select. The onChange provides access to the chosen tag's name.
const TagSelect = ({companyTags: tags, fetchTags, onChange, error, value, tagsToFilter}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(() => {
    setOptions(
      tags
        .map((tag) => ({
          value: tag.name,
          label: (
            <div className="tagContainer">
              <Tag key={tag.id} tag={tag} isMinified>{`${tag.name}`}</Tag>
            </div>
          )
        }))
        .filter((tag) => !tagsToFilter.includes(tag.value))
    );
  }, [tags, tagsToFilter]);

  return (
    <Select
      label="Search for tags"
      onChange={(e) => onChange(e?.value)}
      prepend={<SvgIcon name="Search" />}
      type="search"
      error={error}
      clearable
      value={value && options.find((option) => option.value === value)}
      options={options}
    />
  );
};

TagSelect.propTypes = {
  companyTags: PROPTYPE_TAGS,
  fetchTags: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.string,
  tagsToFilter: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string
};

TagSelect.defaultProps = {
  onChange: () => {},
  error: undefined,
  value: undefined,
  tagsToFilter: []
};

export const FormikTagSelect = compose(
  withStatusToasts,
  withRouter,
  connect(
    (state) => ({
      companyTags: state.shipments.tags
    }),
    {
      fetchTags
    }
  )
)(({companyTags, fetchTags, tagsToFilter, onChange, field: {value, name}, form: {setFieldValue, errors}}) => {
  const fieldError = get(errors, name);
  const onChangeProp = onChange;
  return (
    <TagSelect
      value={value}
      companyTags={companyTags}
      fetchTags={fetchTags}
      tagsToFilter={tagsToFilter}
      error={fieldError}
      onChange={async (value) => {
        await setFieldValue(name, value);
        if (onChangeProp) {
          onChangeProp(value);
        }
      }}
    />
  );
});

FormikTagSelect.propTypes = {
  companyTags: PROPTYPE_TAGS,
  fetchTags: PropTypes.func,
  onChange: PropTypes.func,
  field: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, name: PropTypes.string})),
  form: PropTypes.arrayOf(
    PropTypes.shape({setFieldValue: PropTypes.func, errors: PropTypes.objectOf(PropTypes.string)})
  ),
  tagsToFilter: PropTypes.arrayOf(PropTypes.string)
};

export default compose(
  withStatusToasts,
  withRouter,
  connect(
    (state) => ({
      companyTags: state.shipments.tags
    }),
    {
      fetchTags
    }
  )
)(TagSelect);
