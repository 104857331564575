import {
  REQ_CARRIER_DATA,
  ALERT_ERROR,
  GET_POWER_UNITS,
  GET_POWER_UNIT,
  CLEAR_POWER_UNIT,
  GET_TRAILERS,
  GET_TRAILER,
  CLEAR_TRAILER,
  GET_POWER_UNIT_LOCATIONS,
  GET_DRIVERS,
  FETCH_ALL_HOS,
  GET_CARRIER_ROLES
} from '../actions/types';

const INITIAL_STATE = {
  alertError: null,
  isLoading: false,
  powerUnits: null,
  powerUnit: null,
  powerUnitLocations: null,
  trailers: null,
  trailer: null,
  drivers: null,
  hos: null,
  roles: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQ_CARRIER_DATA:
      return {
        ...state,
        isLoading: true
      };

    case ALERT_ERROR:
      return {
        ...state,
        alertError: action.payload
      };

    case GET_POWER_UNITS:
      return {
        ...state,
        powerUnits: action.payload,
        isLoading: false
      };

    case GET_POWER_UNIT:
      return {
        ...state,
        powerUnit: action.payload
      };

    case CLEAR_POWER_UNIT:
      return {
        ...state,
        powerUnit: null
      };

    case GET_TRAILERS:
      return {
        ...state,
        isLoading: false,
        trailers: action.payload
      };

    case GET_TRAILER:
      return {
        ...state,
        trailer: action.payload
      };

    case CLEAR_TRAILER:
      return {
        ...state,
        trailer: null
      };

    case GET_POWER_UNIT_LOCATIONS:
      return {
        ...state,
        powerUnitLocations: action.payload,
        isLoading: false
      };

    case GET_DRIVERS:
      return {
        ...state,
        isLoading: false,
        drivers: action.payload
      };

    case FETCH_ALL_HOS:
      return {
        ...state,
        hos: action.payload
      };

    case GET_CARRIER_ROLES:
      return {
        ...state,
        roles: action.payload
      };

    default:
      return state;
  }
}
