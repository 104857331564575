import {ChangeEvent, ReactNode, useState} from 'react';
import {FreightSettlementsConfiguration} from '@shipwell/settlements-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {FormikToggleSwitch, ToggleSwitchBase} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers, useFormikContext} from 'formik';
import invariant from 'tiny-invariant';
import {ConfigurationsCard} from '../ConfigurationsCard';
import {ConfigurationRow} from '../ConfigurationRow';
import {DaysToDeliveredDashboardEditModal, AutoGenerateInvoiceEditModal} from './components';
import {groupConfigsByType} from './utils';
import {
  useBulkUpdateSettlementsConfigurationsStatus,
  useGetSettlementsConfigurations,
  useUpdateAutoGenerateInvoiceConfiguration
} from 'App/api/settlements/queryHooks';
import Loader from 'App/common/shipwellLoader';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {
  AddToDeliveredDashboardConfiguration,
  AutoDisputeInvoiceConfiguration,
  AutoGenerateInvoiceConfiguration
} from 'App/api/settlements/typeGuards';
import {AutoDisputeInvoiceEditModal} from 'App/containers/settlements/configurations/components/FreightSettlementsConfigurationsCard/components/AutoDisputeInvoiceEditModal';

const Layout = ({children}: {children: ReactNode}) => (
  <ConfigurationsCard title="Settlements Configurations">{children}</ConfigurationsCard>
);

type InitialValues = Record<string, boolean>;

const getInitialValues = (configs: FreightSettlementsConfiguration[]): InitialValues => {
  const {addToDeliveredDashboardConfigurations, autoGenerateInvoiceConfigurations, autoDisputeInvoiceConfigurations} =
    groupConfigsByType(configs);
  const autoGenerateInvoiceConfiguration = getAutoGenerateInvoiceConfiguration(autoGenerateInvoiceConfigurations);
  const autoDisputeInvoiceConfiguration = getAutoDisputeInvoiceConfiguration(autoDisputeInvoiceConfigurations);
  return {
    consolidatedAddToDeliveredDashboardConfiguration: addToDeliveredDashboardConfigurations[0]?.enabled || false,
    ...(autoGenerateInvoiceConfiguration
      ? {[autoGenerateInvoiceConfiguration.id]: autoGenerateInvoiceConfiguration.enabled}
      : {}),
    ...(autoDisputeInvoiceConfiguration
      ? {[autoDisputeInvoiceConfiguration.id]: autoDisputeInvoiceConfiguration.enabled}
      : {})
  };
};

export const FreightSettlementsConfigurationsCard = () => {
  const {data: configs, error} = useGetSettlementsConfigurations();
  const {mutate: bulkUpdateStatuses} = useBulkUpdateSettlementsConfigurationsStatus();
  const {mutate: updateAutoGenerateInvoiceConfig} = useUpdateAutoGenerateInvoiceConfiguration();

  if (configs) {
    const {addToDeliveredDashboardConfigurations, autoGenerateInvoiceConfigurations, autoDisputeInvoiceConfigurations} =
      groupConfigsByType(configs);
    const autoGenerateInvoiceConfiguration = getAutoGenerateInvoiceConfiguration(autoGenerateInvoiceConfigurations);
    const autoDisputeInvoiceConfiguration = getAutoDisputeInvoiceConfiguration(autoDisputeInvoiceConfigurations);
    const addToDeliveredDashboardIds = addToDeliveredDashboardConfigurations?.map((config) => config.id);

    const handleSubmit = (values: InitialValues, {setSubmitting, resetForm}: FormikHelpers<InitialValues>) => {
      const {consolidatedAddToDeliveredDashboardConfiguration, ...rest} = values;
      const isTogglingOffTheAutoGenerateInvoiceConfiguration =
        autoGenerateInvoiceConfiguration &&
        autoGenerateInvoiceConfiguration.id in rest &&
        rest[autoGenerateInvoiceConfiguration.id] === false;

      const shouldSetStartDateToUndefined = isTogglingOffTheAutoGenerateInvoiceConfiguration;

      bulkUpdateStatuses(
        [
          ...addToDeliveredDashboardIds.map((id) => ({id, enabled: consolidatedAddToDeliveredDashboardConfiguration})),
          ...Object.entries(rest).map(([id, enabled]) => ({id, enabled}))
        ],
        {
          onSettled: () => setSubmitting(false),
          onSuccess: (configs) => {
            if (shouldSetStartDateToUndefined) {
              updateAutoGenerateInvoiceConfig(
                {
                  id: autoGenerateInvoiceConfiguration.id,
                  business_days_after_delivery: autoGenerateInvoiceConfiguration.details.business_days_after_delivery,
                  start_date: undefined
                },
                {onSettled: () => resetForm({values: getInitialValues(configs)})}
              );
            }
          }
        }
      );
    };

    return (
      <Layout>
        <Formik enableReinitialize initialValues={getInitialValues(configs)} onSubmit={handleSubmit}>
          {({dirty, resetForm}) => (
            <Form>
              <>
                {/*
                  Unfortunately, there is an AddToDeliveredDashboardConfiguration for each mode, but they need to be
                  treated as one and should toggle together. That's why this particular Row component takes multiple
                  configurations and Row components for other configuration types do not.
                */}
                <AddToDeliveredDashboardConfigurationsRow configurations={addToDeliveredDashboardConfigurations} />

                {autoGenerateInvoiceConfiguration ? (
                  <AutoGenerateInvoiceConfigurationRow configuration={autoGenerateInvoiceConfiguration} />
                ) : null}
                {autoDisputeInvoiceConfiguration ? (
                  <AutoDisputeInvoiceConfigurationRow configuration={autoDisputeInvoiceConfiguration} />
                ) : null}
                {dirty ? (
                  <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} />
                ) : null}
              </>
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }

  if (error) {
    return <Layout>An error has occurred.</Layout>;
  }

  return (
    <Layout>
      <Loader loading />
    </Layout>
  );
};

const AddToDeliveredDashboardConfigurationsRow = ({
  configurations
}: {
  configurations: AddToDeliveredDashboardConfiguration[];
}) => {
  return (
    <ConfigurationRow
      toggle={
        <Field
          aria-label="Enable Add delivered shipment to 'Delivered' dashboard"
          checkedLabel="ON"
          uncheckedLabel="OFF"
          name="consolidatedAddToDeliveredDashboardConfiguration"
          component={FormikToggleSwitch}
        />
      }
      type="Add delivered shipment to 'Delivered' dashboard"
      description="Define how many business days until uninvoiced shipment is added to dashboard"
      editModal={({isModalOpen, setIsModalOpen}) => (
        <DaysToDeliveredDashboardEditModal
          title="Edit Delivered Shipment Configuration"
          showModal={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          configurations={configurations}
        />
      )}
    />
  );
};

const AutoGenerateInvoiceConfigurationRow = ({configuration}: {configuration: AutoGenerateInvoiceConfiguration}) => {
  const [isForcePreEnableModalOpen, setIsForcePreEnableModalOpen] = useState(false);

  const {values, setFieldValue} = useFormikContext<InitialValues>();

  const {mutate: bulkUpdateStatuses} = useBulkUpdateSettlementsConfigurationsStatus();

  const {stmAutoCreateInvoiceOnBehalfCarrier} = useFlags();
  if (!stmAutoCreateInvoiceOnBehalfCarrier) return null;

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const openEditModal = () => setIsForcePreEnableModalOpen(true);
    const isTogglingToTrue = !configuration.enabled && event.target.checked;
    const isTogglingToFalse = configuration.enabled && !event.target.checked;

    // 'Cancelling a toggle' happens when the configuration is enabled and the user unchecks the checkbox, but then
    // rechecks the checkbox without saving first. This "cancels" the change because they're just setting the checkbox
    // back to was the configuration already is, so we don't need to open the modal again.
    const isCancellingToggle = event.target.checked && configuration.enabled;

    if (isTogglingToTrue) {
      openEditModal();
    }

    if (isTogglingToFalse) {
      setFieldValue(configuration.id, false);
    }

    if (isCancellingToggle) {
      setFieldValue(configuration.id, true);
    }
  };

  const handleEditSuccess = () => {
    bulkUpdateStatuses([{id: configuration.id, enabled: true}], {
      onSuccess: () => setIsForcePreEnableModalOpen(false)
    });
  };

  return (
    <ConfigurationRow
      toggle={
        <ToggleSwitchBase
          aria-label="Auto-generate invoice on behalf of carrier"
          checkedLabel="ON"
          uncheckedLabel="OFF"
          checked={!!values[configuration.id]}
          onChange={handleToggleChange}
        />
      }
      type="Auto-generate invoice on behalf of carrier"
      description="Generate invoice on behalf of carrier a set amount of time after delivery"
      editModal={
        configuration.enabled || isForcePreEnableModalOpen
          ? ({isModalOpen, setIsModalOpen}) => (
              <AutoGenerateInvoiceEditModal
                title="Edit Auto-Generate Invoice Configuration"
                showModal={isModalOpen || isForcePreEnableModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                  setIsForcePreEnableModalOpen(false);
                }}
                configuration={configuration}
                onEditSuccess={() => void handleEditSuccess()}
              />
            )
          : null
      }
    />
  );
};

const AutoDisputeInvoiceConfigurationRow = ({configuration}: {configuration: AutoDisputeInvoiceConfiguration}) => {
  const {setFieldValue} = useFormikContext<InitialValues>();
  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(configuration.id, event.target.checked);
  };
  return (
    <ConfigurationRow
      toggle={
        <Field
          aria-label="Enable Auto-dispute invoices"
          checkedLabel="ON"
          uncheckedLabel="OFF"
          name={configuration.id}
          component={FormikToggleSwitch}
          onChange={handleToggleChange}
        />
      }
      type="Auto-dispute invoices"
      description="Invoice status will change to 'Disputed' based on selected exceptions"
      editModal={({isModalOpen, setIsModalOpen}) => (
        <AutoDisputeInvoiceEditModal
          title="Auto-Dispute Invoices"
          showModal={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          configuration={configuration}
        />
      )}
    />
  );
};

/**
 *  There should only ever be one autoGenerateInvoiceConfiguration. It just ends up in an array so the code for
    sorting out all the addToDeliveredDashboardConfigurations can stay pretty clean. This utility is just for asserting 
    that there's only one (or none) and then returning that one. 
 * @param autoGenerateInvoiceConfigurations 
 * @returns 
 */
const getAutoGenerateInvoiceConfiguration = (autoGenerateInvoiceConfigurations: AutoGenerateInvoiceConfiguration[]) => {
  invariant(
    autoGenerateInvoiceConfigurations.length <= 1,
    `Expect no more than one AutoGenerateInvoiceConfiguration, but received ${autoGenerateInvoiceConfigurations.length}`
  );
  return autoGenerateInvoiceConfigurations[0] as AutoGenerateInvoiceConfiguration | undefined; // `undefined` because this could be an empty array
};

/**
 *  There should only ever be one autoDisputeConfiguration. It just ends up in an array so the code for
    sorting out all the addToDeliveredDashboardConfigurations can stay pretty clean. This utility is just for asserting 
    that there's only one (or none) and then returning that one. 
 * @param autoDisputeConfiguration 
 * @returns 
 */
const getAutoDisputeInvoiceConfiguration = (autoDisputeInvoiceConfigurations: AutoDisputeInvoiceConfiguration[]) => {
  invariant(
    autoDisputeInvoiceConfigurations.length <= 1,
    `Expect no more than one autoDisputeConfiguration, but received ${autoDisputeInvoiceConfigurations.length}`
  );
  return autoDisputeInvoiceConfigurations[0] as AutoDisputeInvoiceConfiguration | undefined; // `undefined` because this could be an empty array
};
