import {ReactNode} from 'react';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {EntityTypeGroupType} from '../config';

const CustomFieldTooltip = ({children}: {children: ReactNode}) => (
  <Tooltip placement="right" tooltipClassname="customData__section-tooltip" tooltipContent={children}>
    <SvgIcon name="InfoOutlined" />
  </Tooltip>
);

export const customFieldTooltips: Record<EntityTypeGroupType, ReactNode> = {
  customer: (
    <CustomFieldTooltip>
      <p className="font-bold">Customer</p>
      <p>
        The customer fields will display on all customer profile pages. They can be used to display any custom
        information about a customer. When creating a new custom field enter the title of the field and input the data
        when creating a new customer or editing an existing one.
      </p>
    </CustomFieldTooltip>
  ),
  reference: (
    <CustomFieldTooltip>
      <p className="font-bold">Reference</p>
      <p>
        A custom reference field is a text field that will be displayed in the ‘References’ card on shipments and
        quotes. When creating a new custom reference enter the title of the field and input the data when creating a
        quote, shipment or order.
      </p>
    </CustomFieldTooltip>
  ),
  lineItem: (
    <CustomFieldTooltip>
      <p className="font-bold">Line Item</p>
      <p>
        Custom line item fields will display on each item when creating a new quote, shipment or order. When creating a
        new custom line item field enter the title of the field and input the data when creating a quote, shipment or
        order.
      </p>
    </CustomFieldTooltip>
  ),
  chargeLineItem: null,
  stop: (
    <CustomFieldTooltip>
      <p className="font-bold">Stop</p>
      <p>
        A custom stop field is a text field that will be displayed under each pickup and delivery stop for shipments,
        quotes and orders.
      </p>
      <p>
        When creating a new custom stop field enter the title of the field and input the data when creating a shipment,
        quote or order.
      </p>
    </CustomFieldTooltip>
  )
};
