import {
  Configuration,
  RfpAutomationApi,
  RfpAutomationApiRfpAutomationRfpBidsGetRequest,
  RfpAutomationApiRfpAutomationRfpBidsRfpBidIdRfpRowsGetRequest,
  RfpBidRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRfpApi() {
  return new RfpAutomationApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export function createRfpBid(rfpBidRequest: RfpBidRequest) {
  return createRfpApi().rfpAutomationRfpBidsPost({rfpBidRequest});
}

export function getRfpImportResults(rfpBidId: string) {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdPostImportResultsGet({rfpBidId});
}

export const getRfpDetails = (rfpBidId: string) => {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdGet({rfpBidId});
};

export type RfpRowNamedParameters = Omit<RfpAutomationApiRfpAutomationRfpBidsRfpBidIdRfpRowsGetRequest, 'rfpBidId'>;

export const getRfpRows = (rfpBidId: string, queryParams: RfpRowNamedParameters) => {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdRfpRowsGet({rfpBidId, ...queryParams});
};

export const getRfpBids = async (opts: RfpAutomationApiRfpAutomationRfpBidsGetRequest = {page: 1, pageSize: 1000}) => {
  const axiosResponse = await createRfpApi().rfpAutomationRfpBidsGet({...opts});
  return axiosResponse.data;
};

export const updateRfpBid = (rfpBidId: string, rfpBidRequest: RfpBidRequest) => {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdPut({rfpBidId, rfpBidRequest});
};

export const getRfpCsv = (rfpBidId: string) => {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdExportCsvGet({rfpBidId});
};

export const deleteRfpBid = (rfpBidId: string) => {
  return createRfpApi().rfpAutomationRfpBidsRfpBidIdDelete({rfpBidId});
};
