import {useState, useEffect} from 'react';
import {object, mixed} from 'yup';
import {Formik, Field} from 'formik';
import {Modal, DeprecatedButton, FormikTextInput, FormikSelect} from '@shipwell/shipwell-ui';
import {states, provinces, mexicoStates} from './utils';
import {validateAddressPromise} from 'App/api/locations';
import './styles.scss';
import {isCanadianThreeDigitPostalCode, formatCanadianThreeDigitPostalCode} from 'App/utils/addressSearchUtils';

/*
 * AddressVerification - wrapper component for validating addresses in an input
 */

const AddressVerification = (props) => {
  const {onChange, initialValue, field, ignoreWarnings, ignoreFieldWarnings} = props;
  const [value, setValue] = useState(initialValue || []);
  const [showWarnings, setShowWarnings] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [showComponentForm, setShowComponentForm] = useState(false);

  useEffect(() => {
    if (value && onChange) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      //preset validated to true for loaded values
      initialValue.forEach((address) => {
        address.value = {...address};
        address.validated = true;
      });
      setValue(initialValue);
    }
  }, []);

  useEffect(() => {
    if (initialValue && !initialValue.length && value.length) {
      //clear current value
      setValue([]);
    } else if (initialValue && initialValue.length) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (address, searchThreeDigitPostalCode = false, threeDigitPostalCodeOptionalValues) => {
    if (Array.isArray(address)) {
      //this is a pre-validated address, just save it
      setValue(address);
    } else {
      validateAddress(address, searchThreeDigitPostalCode, threeDigitPostalCodeOptionalValues);
    }
  };

  const handleCloseWarningModal = () => {
    setShowComponentForm(false);
    setShowWarnings(false);
  };

  const formatAddressString = (address) => {
    const {address_1 = '', address_2, city, state_province = '', postal_code, country = ''} = address;

    let formattedAddress = '';
    if (address_1) {
      formattedAddress += `${address_1}, `;
    }
    if (address_2) {
      formattedAddress += `${address_2}, `;
    }
    if (city) {
      formattedAddress += `${city}, `;
    }
    if (state_province) {
      formattedAddress += `${state_province.value || state_province}, `;
    }
    if (postal_code) {
      formattedAddress += `${postal_code}, `;
    }
    if (country) {
      formattedAddress += `${country.value || country}`;
    }
    return formattedAddress;
  };

  const validateAddress = async (address, searchThreeDigitPostalCode = false, threeDigitPostalCodeOptionalValues) => {
    //if we want to return three digit postal codes and the google place result is a canadian address
    //bypass backend validation and use what google returns
    if (searchThreeDigitPostalCode && isCanadianThreeDigitPostalCode(address)) {
      setValue([
        ...value,
        {
          ...formatCanadianThreeDigitPostalCode(address, threeDigitPostalCodeOptionalValues),
          formatted_address: address.formatted_address,
          validated: true
        }
      ]);
    } else {
      //address could be an object or a string, string must be passed to validateAddressPromise
      const formattedAddress = address.formatted_address || address;
      try {
        const validatedAddress = await validateAddressPromise(formattedAddress);
        if (validatedAddress) {
          if (Object.keys(validatedAddress.field_warnings).length > 0 && ignoreFieldWarnings?.length > 0) {
            //if there are field-specific warnings, ignore those that we don't care about and proceed
            const warningsToShow = Object.keys(validatedAddress.field_warnings).filter(
              (e) => !ignoreFieldWarnings.includes(e)
            );
            if (warningsToShow.length > 0) {
              setShowWarnings(warningsToShow);
              setSuggestedAddress(validatedAddress.geocoded_address);
            } else {
              ignoreFieldWarnings.forEach((field) => {
                delete validatedAddress.geocoded_address[field];
              });
              const formattedAddress = formatAddressString(validatedAddress.geocoded_address);
              setValue([
                ...value,
                {...validatedAddress.geocoded_address, formatted_address: formattedAddress, validated: true}
              ]);
            }
          } else if (validatedAddress.warnings.length > 0 && !ignoreWarnings) {
            setShowWarnings(validatedAddress);
            setSuggestedAddress(validatedAddress.geocoded_address);
          } else {
            setValue([
              ...value,
              {
                ...validatedAddress.geocoded_address,
                formatted_address: validatedAddress.geocoded_address.formatted_address,
                validated: true
              }
            ]);
          }
        }
      } catch (error) {
        console.error(error);
        setShowWarnings({error});
        setShowComponentForm(true);
      }
    }
  };

  const handleSelectAddress = (values) => {
    if (!showComponentForm) {
      //use the validated value
      setValue([...value, {...suggestedAddress, validated: true}]);
      setShowWarnings(false);
      setSuggestedAddress(null);
    } else {
      const {address_1 = '', address_2, city, state_province = '', postal_code, country = ''} = values;
      const addressToSave = {
        country: country.value,
        formatted_address: formatAddressString(values),
        address_1,
        address_2,
        city,
        state_province: state_province.value,
        postal_code
      };
      setValue([...value, {...addressToSave, validated: true}]);
      setShowWarnings(false);
      setSuggestedAddress(null);
      setShowComponentForm(false);
    }
  };

  const getStateOptions = (country) => {
    if (country && country === 'US') {
      return states.map((s) => {
        return {label: s.name, value: s.id};
      });
    }
    if (country && country === 'CA') {
      return provinces.map((p) => {
        return {label: p.name, value: p.id};
      });
    }
    if (country && country === 'MX') {
      return mexicoStates.map((ms) => {
        return {label: ms.name, value: ms.id};
      });
    }
    return states
      .map((s) => {
        return {label: s.name, value: s.id};
      })
      .concat(
        provinces.map((p) => {
          return {label: p.name, value: p.id};
        }),
        mexicoStates.map((ms) => {
          return {label: ms.name, value: ms.id};
        })
      );
  };

  const AddressSchema = object().shape({
    country: mixed().required('Country is required')
  });

  const renderWarningModal = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          country: {value: 'US', label: 'US'},
          address_1: '',
          address_2: '',
          city: '',
          state_province: '',
          postal_code: ''
        }}
        validationSchema={AddressSchema}
        onSubmit={handleSelectAddress}
      >
        {({handleSubmit, isSubmitting, dirty, values, ...props}) => (
          <form className="addressVerification__form">
            {showComponentForm ? (
              <>
                {showWarnings.error && <p>{showWarnings.error}</p>}
                <div className="field-grid">
                  <div className="grid-item-1-1">
                    <Field name="address_1" component={FormikTextInput} label="Street" />
                  </div>
                  <div className="grid-item-2-2">
                    <Field name="address_2" component={FormikTextInput} label="Suite/Apt/Unit" />
                  </div>
                  <div className="grid-item-1-1">
                    <Field name="city" component={FormikTextInput} label="City" />
                  </div>
                  <div className="grid-item-2-2">
                    <Field
                      name="state_province"
                      component={FormikSelect}
                      options={
                        values.country && values.country.value
                          ? getStateOptions(values.country.value)
                          : getStateOptions()
                      }
                      label="State/Province"
                    />
                  </div>
                  <div className="grid-item-1-1">
                    <Field name="postal_code" component={FormikTextInput} label="Postal Code" />
                  </div>
                  <div className="grid-item-2-2">
                    <Field
                      name="country"
                      component={FormikSelect}
                      options={[
                        {value: 'US', label: 'US'},
                        {value: 'CA', label: 'CA'},
                        {value: 'MX', label: 'MX'}
                      ]}
                      label="Country"
                    />
                  </div>
                </div>
              </>
            ) : (
              suggestedAddress && (
                <div className="addressVerification__form-suggestedAddresses">
                  <p>You entered:</p>
                  <p>{showWarnings.provided_formatted_address}</p>

                  <p>We found this match:</p>
                  <p>{showWarnings.geocoded_address.formatted_address}</p>
                </div>
              )
            )}
            <div className="addressVerification__form-footer">
              {!showComponentForm && (
                <DeprecatedButton variant="tertiary" onClick={() => setShowComponentForm(true)}>
                  Manually Add Address
                </DeprecatedButton>
              )}
              <DeprecatedButton variant="secondary" onClick={handleCloseWarningModal}>
                Cancel
              </DeprecatedButton>

              <DeprecatedButton
                disabled={isSubmitting}
                type="submit"
                icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                onClick={handleSubmit}
              >
                Use This Address
              </DeprecatedButton>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <>
      {props.children({
        onChange: handleChange,
        value,
        field
      })}
      <Modal
        show={showWarnings}
        footerComponent={null}
        onClose={handleCloseWarningModal}
        title={'Confirm Address Details'}
      >
        {showWarnings && renderWarningModal()}
      </Modal>
    </>
  );
};

export default AddressVerification;
