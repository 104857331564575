import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Card, Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import ShipmentReferences from '../index';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ActionsDropdown = ({onEdit}) => (
  <Dropdown variant="icon" drop="down" indicator={false} icon={<SvgIcon name="Overflow" />} alignEnd>
    {() => (
      <>
        <li title="Edit" onClick={onEdit} className="min-w-[80px]">
          Edit
        </li>
      </>
    )}
  </Dropdown>
);

const ShipmentCardReferences = ({
  ordinalIndex,
  isCollapsed,
  onCollapse,
  draggableProvided,
  shipment,
  user,
  isQuotingLimitedUser,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  const canEdit =
    get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);
  const customerName = get(shipment, 'relationship_to_customer.customer.name');

  const handleEdit = (isEditing) => () => setEditing(isEditing);

  return (
    <Card
      title={
        <div className="flex items-center py-4">
          <span className="mr-3">
            <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
          </span>
          <div className="flex items-baseline">
            <span>References</span>
            <span className="text-grey-5 max-w-tag truncate text-xxs font-normal">
              <span className="mx-1">•</span>
              {customerName || 'CUSTOMER'}
            </span>
          </div>
        </div>
      }
      className="shipmentReferencesCard"
      tabOrder={ordinalIndex}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      draggableProvided={draggableProvided}
      actions={canEdit && <ActionsDropdown onEdit={handleEdit(true)} />}
      bodyClassName="p-4 empty:p-0"
      {...props}
    >
      <ShipmentReferences
        shipment={shipment}
        editing={editing}
        onCancelEdit={handleEdit(false)}
        onSuccessEdit={handleEdit(false)}
        {...props}
      />
    </Card>
  );
};

ShipmentCardReferences.defaultProps = {
  ordinalIndex: 0,
  isCollapsed: false,
  onCollapse: () => {},
  draggableProvided: {
    draggableProps: {},
    dragHandleProps: {},
    innerRef: null
  },
  shipment: {}
};

ShipmentCardReferences.propTypes = {
  ordinalIndex: PropTypes.number,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  draggableProvided: PropTypes.shape({
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.elementType})])
  }),
  shipment: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string
    }),
    customer_reference_number: PropTypes.string,
    bol_number: PropTypes.string,
    pro_number: PropTypes.string,
    pickup_number: PropTypes.string,
    purchase_order_number: PropTypes.string,
    metadata: PropTypes.shape({
      custom_reference_values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  })
};

const mapStateToProps = (state) => {
  return {
    user: state.userProfile.user,
    isQuotingLimitedUser: state.userProfile.user.is_quoting_limited_user
  };
};

export default connect(mapStateToProps)(ShipmentCardReferences);
