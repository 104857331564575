import {useCallback, useState} from 'react';

export default function useSemaphore(init?: number) {
  const [count, setCount] = useState(init ?? 0);
  const increment = useCallback(() => {
    setCount(count + 1);
  }, [count]);
  const decrement = useCallback(() => {
    setCount(count - 1);
  }, [count]);
  return {count, increment, decrement};
}
