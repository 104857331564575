export const REQUEST_USER_PROFILE_PENDING = 'REQUEST_USER_PROFILE_PENDING';
export const REQUEST_USER_PROFILE_SUCCESS = 'REQUEST_USER_PROFILE_SUCCESS';
export const REQUEST_USER_PROFILE_ERROR = 'REQUEST_USER_PROFILE_ERROR';

export const requestUserProfilePending = () => {
  return {
    type: REQUEST_USER_PROFILE_PENDING
  };
};

export const requestUserProfileSuccess = (payload) => {
  return {
    type: REQUEST_USER_PROFILE_SUCCESS,
    payload: payload
  };
};

export const requestUserProfileError = (payload) => {
  return {
    type: REQUEST_USER_PROFILE_ERROR,
    payload: payload
  };
};
