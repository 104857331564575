import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

export async function getPolicies(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesGet(opts, apiCallback(resolve, reject));
  });
}

export async function createPolicy(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesPost(body, apiCallback(resolve, reject));
  });
}

export async function updatePolicy(policyId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesPolicyIdPut(policyId, body, apiCallback(resolve, reject));
  });
}

export async function deletePolicy(policyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesPolicyIdDelete(policyId, apiCallback(resolve, reject));
  });
}

export async function getPolicy(policyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesPolicyIdGet(policyId, apiCallback(resolve, reject));
  });
}

export async function getPolicyConditionalTrigger(policyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.routingGuidePoliciesPolicyIdConditionalTriggerGet(policyId, apiCallback(resolve, reject));
  });
}
