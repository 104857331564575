import {Link} from 'react-router';
import {compose} from 'recompose';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {
  DOC_TYPE_RATE_CONFIRMATION,
  DOC_TYPE_BILL_OF_LADING,
  DOC_TYPE_SHIPPING_LABEL
} from 'App/containers/templateManagement/constants';

const bolPath = `/templates/${DOC_TYPE_BILL_OF_LADING}`;
const shippingLabelsPath = `/templates/${DOC_TYPE_SHIPPING_LABEL}`;
const rateConfirmationPath = `/templates/${DOC_TYPE_RATE_CONFIRMATION}`;

const ShippingLabelsLinkBase = () => (
  <Link to={shippingLabelsPath} activeClassName="active">
    Label
  </Link>
);

const ShippingLabelsLink = compose(
  withFlags('modeShipmentLabels'),
  withConditionalFallback(({modeShipmentLabels}: {modeShipmentLabels: boolean}) => !modeShipmentLabels, null)
)(ShippingLabelsLinkBase);

const BolLinkBase = () => (
  <Link to={bolPath} activeClassName="active">
    BOL
  </Link>
);
const BolLink = compose(
  withFlags('platCustomBol'),
  withConditionalFallback(({platCustomBol}: {platCustomBol: boolean}) => !platCustomBol, null)
)(BolLinkBase);

const RateConLinkBase = () => (
  <Link to={rateConfirmationPath} activeClassName="active">
    Rate Confirmation
  </Link>
);
const RateConLink = compose(
  withFlags('platCustomRateConfirmation'),
  withConditionalFallback(
    ({platCustomRateConfirmation}: {platCustomRateConfirmation: boolean}) => !platCustomRateConfirmation,
    null
  )
)(RateConLinkBase);

export const DocumentTypesLinks = () => (
  <>
    <BolLink />
    <ShippingLabelsLink />
    <RateConLink />
  </>
);

const TemplateManagementLinkBase = ({
  platCustomBol,
  platCustomRateConfirmation,
  modeShipmentLabels
}: {
  platCustomBol: boolean;
  platCustomRateConfirmation: boolean;
  modeShipmentLabels: boolean;
}) => (
  <Link
    to={`/templates/${
      platCustomBol
        ? 'bill-of-lading'
        : platCustomRateConfirmation
        ? 'rate-confirmation'
        : modeShipmentLabels
        ? 'shipping-label'
        : ''
    }`}
    activeClassName="active"
  >
    Templates
  </Link>
);

export const TemplateManagementLink = compose<
  {platCustomBol: boolean; platCustomRateConfirmation: boolean; modeShipmentLabels: boolean},
  unknown
>(
  withFlags('platCustomBol', 'platCustomRateConfirmation', 'modeShipmentLabels'),
  withConditionalFallback(
    ({
      platCustomBol,
      platCustomRateConfirmation,
      modeShipmentLabels
    }: {
      platCustomBol: boolean;
      platCustomRateConfirmation: boolean;
      modeShipmentLabels: boolean;
    }) => !platCustomBol && !platCustomRateConfirmation && !modeShipmentLabels,
    null
  )
)(TemplateManagementLinkBase);
