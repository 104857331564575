import {Link} from 'react-router';

export const getMissingDataByStop = (stops = []) => {
  return stops.map((stop, i) => {
    const stopData = {
      title: `Stop ${i + 1}`,
      missingData: []
    };
    const {planned_date, location = {}} = stop;
    const {point_of_contacts = [], address, identification_code: location_reference_id} = location;

    stopData.address = address?.formatted_address;

    if (!planned_date) {
      stopData.missingData.push('Date');
    }

    if (location_reference_id) {
      if (location_reference_id === 'NOT_PROVIDED') {
        stopData.missingData.push('Location ID');
      } else {
        // a location ref id only exists if a matching address record couldn't be found
        stopData.locationRefId = location_reference_id;
      }
    }

    if (point_of_contacts.length === 0) {
      stopData.missingData.push('Contact First Name', 'Contact Email', 'Contact Phone Number');
    } else {
      // check for required data for each point of contact provided
      const numberOfContacts = point_of_contacts.length;

      point_of_contacts.forEach((poc, i) => {
        const {first_name, email, phone_number} = poc;

        if (!first_name) {
          stopData.missingData.push(`Contact ${numberOfContacts > 1 ? `${i + 1} ` : ''}First Name`);
        }
        if (!email) {
          stopData.missingData.push(`Contact ${numberOfContacts > 1 ? `${i + 1} ` : ''}Email`);
        }
        if (!phone_number) {
          stopData.missingData.push(`Contact ${numberOfContacts > 1 ? `${i + 1} ` : ''}Phone Number`);
        }
      });
    }

    return stopData;
  });
};

export const getEdiDataErrors = (stopData = []) => {
  const errors = {};
  let i = 0;

  // only check for errors if none have been found yet and we haven't checked all the stops
  while ((!errors.missingData || !errors.unmatchedLocation) && (i < stopData.length || i === 0)) {
    if (stopData[i].missingData?.length > 0) {
      errors.missingData = missingDataErrorText;
    }
    if (stopData[i].locationRefId) {
      errors.unmatchedLocation = unmatchedLocationErrorText;
    }
    i++;
  }
  return errors;
};

export const getWarningContent = (errors = {}, loadBoardId) => {
  return (
    <div className="tender-warning__tooltip-content">
      <p>Tender Request Warning</p>
      <ul>
        {errors.missingData && <li>{errors.missingData}</li>}
        {errors.unmatchedLocation && <li>{errors.unmatchedLocation}</li>}
      </ul>
      {loadBoardId && <Link to={`/load-board/${loadBoardId}`}>View Details</Link>}
    </div>
  );
};

export const missingDataErrorText =
  'Missing Shipment Information: You may accept the tender request but there is missing shipment information that is required for a valid shipment.';
export const unmatchedLocationErrorText =
  'Unmatched Location ID: The address and location ID do not match an address book entry. This could result in incorrect contact information on the shipment.';
