type RenderFieldProps = {
  label: string;
  value: string | number | undefined;
};

const RenderField = ({label, value}: RenderFieldProps) => {
  return (
    <div className="mb-4">
      <label className="text-sw-label" style={{fontSize: '10px', lineHeight: '11.72px'}}>
        {label.toUpperCase()}
      </label>
      <span className="block" style={{fontSize: '14px', color: '#26282B', lineHeight: '16.41px'}}>
        {value}
      </span>
    </div>
  );
};

export default RenderField;
