import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import {
  StopTypeId,
  StopFormValues,
  AddressBookEntryType
} from 'App/containers/shipments/components/StopsFields/constants';

export const getAddressEntry = (values: StopFormValues, stopTypeId: StopTypeId): AddressBookEntryType | undefined => {
  if (stopTypeId === StopTypeId.Pickup && 'pickup' in values) {
    return values.pickup;
  }
  if (stopTypeId === StopTypeId.Delivery && 'delivery' in values) {
    return values.delivery;
  }
  if (stopTypeId === StopTypeId.ContainerPickup && 'containerPickup' in values) {
    return values.containerPickup;
  }
  if (stopTypeId === StopTypeId.ContainerReturn && 'containerReturn' in values) {
    return values.containerReturn;
  }
  return undefined;
};

export const transformAddressBookEntryType = (entry: AddressBookEntryType): AddressBookEntry => {
  const {city, country, postal_code: postalCode} = entry;
  const pointOfContacts = entry.point_of_contacts?.filter((poc) => poc.first_name !== null) || [];
  const locationName = `${city}, ${country}, ${postalCode}`.slice(0, 30);
  const externalReference = entry.external_reference ?? locationName;
  return {
    ...entry,
    id: entry.id,
    address: entry,
    location_name: locationName,
    point_of_contacts: pointOfContacts ?? [],
    is_default_origin: false,
    external_reference: externalReference,
    dock_hours_end: entry.dock_hours_end,
    dock_hours_start: entry.dock_hours_start
  };
};
