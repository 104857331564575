import {Field, reduxForm} from 'redux-form';

const Add_User_Form = (props) => {
  const {handleSubmit, reset, submitting, updated} = props;

  if (updated) {
    reset('addUserForm');
  }

  return (
    <form className="form-horizontal pad-top" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="first_name" className="col-sm-2 control-label">
          First Name
        </label>
        <div className="col-sm-10">
          <Field name="first_name" component="input" type="text" placeholder="First Name" className="form-control" />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="last_name" className="col-sm-2 control-label">
          Last Name
        </label>
        <div className="col-sm-10">
          <Field name="last_name" component="input" type="text" placeholder="Last Name" className="form-control" />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email" className="col-sm-2 control-label">
          Email
        </label>
        <div className="col-sm-10">
          <Field
            name="email"
            autoComplete="new-password"
            component="input"
            type="email"
            placeholder="Email"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="password1" className="col-sm-2 control-label">
          Password
        </label>
        <div className="col-sm-10">
          <Field
            name="password1"
            autoComplete="new-password"
            component="input"
            type="password"
            placeholder="Password"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-offset-2 col-sm-10">
          <button type="submit" disabled={submitting} className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

const AddUserForm = reduxForm({
  form: 'addUserForm'
  // validate,
})(Add_User_Form);

export default AddUserForm;
