import {ProductLegItem, ContainerLegItem, CreateProductLegItem, UpdateProductLegItem} from '@shipwell/corrogo-sdk';
import {LineItemsContainer} from 'App/containers/shipments/drayage/components/ProductLineItems';
import {useCreateOrUpdateLeg, useDrayageLeg} from 'App/data-hooks';
import {isContainerLegItem} from 'App/containers/shipments/utils/typed';

function isProductLegItem(legItem: ProductLegItem | ContainerLegItem): legItem is ProductLegItem {
  return 'piece_type' in legItem;
}

const LegLineItemsView = ({legId}: {legId: string}) => {
  const {
    data,
    isLoading,
    context: {containerDisplayValues}
  } = useDrayageLeg(legId);
  const {updateLegItemMutation} = useCreateOrUpdateLeg(legId);

  if (isLoading) {
    return null;
  }

  const handleUpdateLegItems = (childUnits: ProductLegItem[] | CreateProductLegItem[] | UpdateProductLegItem[]) => {
    const legItem = data?.leg_items?.[0];
    const legItemId = legItem?.id;

    if (!legItemId || !isContainerLegItem(legItem)) {
      return;
    }

    const payload = {...legItem, child_units: childUnits};
    updateLegItemMutation.mutate({legId, legItemId, legItemData: payload});
  };

  const handleDeleteLegItem = (childUnitIndex: number) => {
    const legItem = data?.leg_items?.[0];
    const filteredChildUnits = legItem?.child_units
      ?.filter(isProductLegItem)
      .filter((_, index) => childUnitIndex !== index);
    if (!filteredChildUnits) {
      return;
    }

    return handleUpdateLegItems(filteredChildUnits);
  };

  return (
    <div className="m-4">
      <LineItemsContainer
        legItems={containerDisplayValues[0]?.childUnits as ProductLegItem[]}
        onSubmit={handleUpdateLegItems}
        onDelete={handleDeleteLegItem}
      />
    </div>
  );
};

export default LegLineItemsView;
