import {useState} from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {Formik, Form, Field} from 'formik';
import {object, mixed} from 'yup';
import {FormikDateTimePicker, SvgIcon} from '@shipwell/shipwell-ui';
import {createBulkOperation} from 'App/api/bulkOperations';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {WithStatusToastsPropTypes, WithStatusToastsDefaultProps} from 'App/components/withStatusToasts';

const EditShipmentDates = ({selectedShipmentIds, onSubmitSuccess, onCancel, setError}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    const payloadParams = [];
    for (const key in values) {
      if (values[key]) {
        payloadParams.push({
          name: key,
          value: values[key]
        });
      }
    }

    setIsSubmitting(true);
    try {
      if (selectedShipmentIds.length > 0) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'MODIFY_SHIPMENT_STOP_DATES',
                step_id: 'STEP_2',
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: payloadParams
              }
            ]
          },
          criteria: {
            id: {
              $in: selectedShipmentIds
            }
          }
        };
        const response = await createBulkOperation(bulkOperationBody);
        onSubmitSuccess(response.data.id);
      }
    } catch (error) {
      console.error(`Error editing ${pluralize('shipment', selectedShipmentIds.length)} dates.`, error);
      setError(
        'Changes Not Saved',
        `Your changes could not be applied to the ${pluralize('shipment', selectedShipmentIds.length)} you selected.`
      );
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        pickup_date: null,
        dropoff_date: null
      }}
      validationSchema={object().shape({
        pickup_date: mixed().nullable(),
        dropoff_date: mixed()
          .nullable()
          .test('dropoffDate', 'Delivery Date must be after Pickup Date.', function (dropoffDate) {
            const pickupDate = this.parent.pickup_date;
            if (dropoffDate) {
              return dropoffDate >= pickupDate;
            }
            return true;
          })
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({values}) => (
        <Form noValidate>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <Field label="Pickup Date" name="pickup_date" showTimeSelect={false} component={FormikDateTimePicker} />
            <Field label="Delivery Date" name="dropoff_date" showTimeSelect={false} component={FormikDateTimePicker} />
          </div>
          <div className="-mt-1 mb-3 flex flex-row items-center rounded bg-sw-warning-background p-2">
            <div className="flex flex-row items-center font-bold">
              <SvgIcon name="ErrorOutlined" color="sw-warning" />
              <div className="ml-2">
                Appointment times and windows will be retained if they currently exist on the shipments.
              </div>
            </div>
          </div>
          <ModalFormFooter
            primaryActionName="Apply"
            onCancel={onCancel}
            className="static m-0 p-0"
            isValid={!isSubmitting && (values.pickup_date || values.dropoff_date)}
          />
        </Form>
      )}
    </Formik>
  );
};

EditShipmentDates.propTypes = {
  selectedShipmentIds: PropTypes.arrayOf(PropTypes.string),
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  ...WithStatusToastsPropTypes
};

EditShipmentDates.defaultProps = {
  selectedShipmentIds: [],
  onSubmitSuccess: () => {},
  onCancel: () => {},
  ...WithStatusToastsDefaultProps
};

export default EditShipmentDates;
