// import fetch from 'isomorphic-fetch';
import {
  VERIFY_USER,
  TRIGGER_MARKETPLACE_LOGIN,
  SYSTEM_VERSION_OUT_OF_DATE,
  SYSTEM_VERSION,
  SIGNIN_ERROR,
  RESET_ERROR,
  UNAUTH_USER,
  VERIFY_EMAIL
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getSystemVersion as getSystemVersionPromise} from 'App/api/auth';
import {getShipwellApi} from 'App/api/config';

//starts the Verifying... spinner
function verifyUser() {
  return {type: VERIFY_USER};
}

// export function transitionToAccount(token, redirect) {
//   //we have a token already in local storage, save it in the api auth
//   ApiKeyAuth.apiKey = token.key;
//   return dispatch => {
//     //get user and company info
//     return dispatch(getUserMe())
//       .then(() => {
//         if (window.location.pathname === '/' && !redirect) {
//           browserHistory.push('/dashboard/');
//         } else if (redirect) {
//           if (!redirect.startsWith('/')) {
//             redirect = '/' + redirect;
//           }
//           browserHistory.push(redirect);
//         }
//       })
//       .catch(response => {
//         if (redirect) {
//           console.warn(response);
//           //special handling when magic link login
//           return {status: 400};
//         } else {
//           signoutUser();
//           browserHistory.push('/');
//         }
//       });
//   };
// }

export function triggerMarketplaceLogin(bool, email) {
  return (dispatch) => {
    return dispatch({
      type: TRIGGER_MARKETPLACE_LOGIN,
      payload: {bool: bool, email: email}
    });
  };
}

//get latest server version to determine if current resources are out of date
export function getSystemVersion(currentVersion) {
  return async (dispatch) => {
    try {
      const newVersion = await getSystemVersionPromise();
      if (newVersion) {
        //if we have a current version (i.e., we're in the loop, compare it here)
        if (currentVersion) {
          if (currentVersion !== newVersion) {
            //we need to warn user to upgrade to a new version
            dispatch({type: SYSTEM_VERSION_OUT_OF_DATE, payload: true});
          } else {
            dispatch({type: SYSTEM_VERSION, payload: newVersion});
          }
        } else {
          dispatch({type: SYSTEM_VERSION, payload: newVersion});
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}

/* ------------ signInError --------------- */

export function signInError(error) {
  return {type: SIGNIN_ERROR, payload: error};
}

/* ------------ signInError --------------- */

export function resetError() {
  return {type: RESET_ERROR, error: false, details: null};
}

/* ------------ signoutUser --------------- */

export function signoutUser() {
  //ApiKeyAuth.apiKey = '';
  localStorage.setItem('swuser', '');
  localStorage.setItem('swmasq', '');
  return {type: UNAUTH_USER};
}

// Verify Email
export function verifyEmail(body) {
  return (dispatch) => {
    return verifyEmailPromise(body)
      .then((response) => {
        dispatch({type: VERIFY_EMAIL, payload: response});

        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function verifyEmailPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authVerifyEmailPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// Verify Email
export function updateSignUpInfo(body) {
  return (dispatch) => {
    return updateSignUpInfoPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}
async function updateSignUpInfoPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.authUpdateSignupInfoPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}
