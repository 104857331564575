import {connect} from 'react-redux';
import get from 'lodash/get';
import {compose} from 'recompose';
import ContractForm from 'App/formComponents/forms/contract';
import {cleanPayload} from 'App/utils/globals';
import {unpackShipwellErrorForFormik} from 'App/utils/globalsTyped';
import withStatusToasts from 'App/components/withStatusToasts';
import {formatContractValuesBeforeSubmit} from 'App/containers/contracts/create/utils';
import {useCreateUpdateContract} from 'App/data-hooks/contracts/useCreateUpdateContract';

/**
 * Contract Creation
 * @param {*} props
 */
const ContractCreateContainer = (props) => {
  const {
    onSubmitSuccess,
    values,
    isEdit,
    carrierRelationship,
    canEditContracts,
    canCreateContracts,
    canViewContracts,
    cloningContract,
    setError,
    onCancel,
    company
  } = props;

  /**
   * Create contract
   * @param {*} values
   * @param {*} Formik props
   */

  const {createContractAsync, updateContractAsync} = useCreateUpdateContract();

  const handleSave = async (values, {setErrors}) => {
    const formattedValues = formatContractValuesBeforeSubmit(values);
    const contractPayload = cleanPayload(formattedValues);

    const onError = (error) => {
      if (error) {
        setError('Contract Not Saved!', error.error_description);
        setErrors(unpackShipwellErrorForFormik(error));
      }
    };

    const onSuccess = (contract) =>
      onSubmitSuccess?.(
        formattedValues.clone_on_save ? {...contract, id: null, name: 'Copy of ' + contract.name} : null
      );

    if (formattedValues.id && isEdit) {
      //do a PUT
      await updateContractAsync({
        carrierRelationshipId: formattedValues.carrier_relationship,
        contractId: formattedValues.id,
        contract: contractPayload,
        onError,
        onSuccess
      });
    } else {
      await createContractAsync({
        carrierRelationshipId: formattedValues.carrier.id,
        contract: contractPayload,
        onError,
        onSuccess
      });
    }
  };

  //get initial values for form
  const valuesBase = cloningContract || {
    ...get(company, 'custom_data.shipwell_custom_data.company_default_values.contracts', null),
    ...values
  };

  const formValues = {
    ...valuesBase,
    defaultAccessorials: [],
    carrier:
      valuesBase && valuesBase.carrier_relationship
        ? {shipwell_vendor: {name: valuesBase.carrier_name}, id: valuesBase.carrier_relationship}
        : null
  };

  return (
    <>
      {cloningContract && (
        <p>This is a clone of an existing contract. Please update your contract name and details accordingly.</p>
      )}
      <ContractForm
        cloningContract={cloningContract}
        values={formValues}
        isEdit={isEdit}
        canViewContracts={canViewContracts}
        canCreateContracts={canCreateContracts}
        canEditContracts={canEditContracts}
        carrierRelationship={carrierRelationship}
        onCancel={onCancel}
        onSubmit={handleSave}
      />
    </>
  );
};

export default compose(
  connect((state) => ({
    company: state.userCompany.company,
    canEditContracts:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.update_contracts'),
    canViewContracts:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts'),
    canCreateContracts:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.create_contracts')
  })),
  withStatusToasts
)(ContractCreateContainer);
