import {ChangeEvent, ReactNode} from 'react';
import {SearchBar, SvgIcon} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import debounce from 'lodash/debounce';
import {FlexBox} from 'App/components/Box';

export const RfpHeader = ({
  name,
  children,
  onSearch
}: {
  name: string;
  children?: ReactNode;
  onSearch: (query: string) => void;
}) => {
  const debouncedSearch = debounce((val: string) => onSearch(val), 300);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <div className="border-b border-b-sw-border">
      <FlexBox
        pad={[
          ['x', 'm'],
          ['y', 's']
        ]}
        justify="between"
      >
        <FlexBox gap="m" items="center">
          <FlexBox gap="s" items="center">
            <Link className="text-lg text-sw-text no-underline" to="/company/lane-management/rfps">
              RFPs
            </Link>
            <SvgIcon name="ArrowLargeRight" />
            <span className="text-lg font-bold">{name}</span>
          </FlexBox>
          <SearchBar label="Search" name="rfp-search" onChange={handleSearch} />
        </FlexBox>
        <FlexBox gap="s" items="center">
          {children}
        </FlexBox>
      </FlexBox>
    </div>
  );
};
