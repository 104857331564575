import {useCallback, useRef, useState} from 'react';

import {ShipmentNote} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {isEqual} from 'lodash';
import {SHIPMENT_INTERNAL_NOTES_QUERY_KEY} from '../queryKeys';

import {getShipmentInternalNotes, postShipmentInternalNote} from 'App/api/shipment/typed';

type UseAllShipmentInternalMessagesResult = {
  isLoading: boolean;
  notes: ShipmentNote[];
  error?: unknown;
  isWorking: boolean;
  postNote: (message: string) => Promise<void>;
};

const pNoop = () => Promise.resolve();

export function useAllShipmenInternalNotes(shipmentId?: string): UseAllShipmentInternalMessagesResult {
  const resultRef = useRef<UseAllShipmentInternalMessagesResult>({
    isLoading: true,
    notes: [],
    error: undefined,
    isWorking: false,
    postNote: pNoop
  });

  const {
    isLoading,
    data: notes,
    error
  } = useQuery([SHIPMENT_INTERNAL_NOTES_QUERY_KEY, shipmentId ?? ''], async () => {
    if (!shipmentId) {
      return [];
    }
    return getShipmentInternalNotes(shipmentId);
  });

  const client = useQueryClient();

  const [isWorking, setIsWorking] = useState(false);

  const postNote = useCallback(
    async (message: string) => {
      if (!shipmentId) {
        return Promise.resolve();
      }
      if (isWorking) {
        return Promise.reject(new Error('Concurrent Message Posts are an error.'));
      }
      setIsWorking(true);
      try {
        const note = await postShipmentInternalNote(shipmentId, message);
        let notes = client.getQueryData<ShipmentNote[]>([SHIPMENT_INTERNAL_NOTES_QUERY_KEY, shipmentId ?? '']);
        if (!notes) {
          return;
        }
        notes = [note, ...notes];
        client.setQueryData([SHIPMENT_INTERNAL_NOTES_QUERY_KEY, shipmentId ?? ''], notes);
      } finally {
        setIsWorking(false);
      }
    },
    [shipmentId, isWorking, client]
  );

  if (resultRef.current.isLoading !== isLoading) {
    resultRef.current = {...resultRef.current, isLoading};
  }
  if (!isEqual(resultRef.current.notes, notes)) {
    resultRef.current = {...resultRef.current, notes: notes ?? []};
  }
  if (resultRef.current.error !== error) {
    resultRef.current = {...resultRef.current, error};
  }
  if (resultRef.current.isWorking !== isWorking) {
    resultRef.current = {...resultRef.current, isWorking};
  }
  if (resultRef.current.postNote !== postNote) {
    resultRef.current = {...resultRef.current, postNote};
  }

  return resultRef.current;
}
