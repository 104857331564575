import {Modal} from '@shipwell/shipwell-ui';
import {CreateSupplierSteps} from './CreateSupplierSteps';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type CreateSupplierStepsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateSupplierStepsModal = withStatusToasts<CreateSupplierStepsModalProps>(
  ({isOpen, onClose, setError}: CreateSupplierStepsModalProps & WithStatusToastProps) => (
    <Modal bodyClassName="p-0" footerComponent={null} title="New Supplier" show={isOpen} onClose={onClose} size="large">
      <CreateSupplierSteps onClose={onClose} setError={setError} />
    </Modal>
  )
);
