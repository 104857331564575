import {Button, TextInput, Textarea, useToastManager} from '@shipwell/shipwell-ui';
import {useFormik} from 'formik';
import {ChangeEvent, useCallback} from 'react';
import {FormValues} from './formValues';

export function FormikWithHook() {
  const {enqueueToast} = useToastManager();

  const form = useFormik<FormValues>({
    initialValues: {
      name: '',
      description: ''
    },
    onSubmit: (values) => {
      console.log(values);
      enqueueToast({
        variant: 'success',
        title: 'title',
        content: 'content'
      });
    }
  });

  const handleClick = useCallback(() => {
    enqueueToast({
      variant: 'success',
      title: 'title',
      content: 'content'
    });
  }, [enqueueToast]);

  return (
    <form className="m-4 flex flex-col gap-2" onSubmit={form.handleSubmit}>
      <input type="radio" onClick={(evt) => console.log(evt)} />

      <TextInput
        label={'Name'}
        value={form.values.name}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          void form.setFieldValue('name', evt.target.value);
        }}
      />

      <TextInput label={'Name v2'} {...form.getFieldProps('name')} />

      <Textarea
        label={'Description'}
        value={form.values.description}
        onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
          void form.setFieldValue('description', evt.target.value);
        }}
      />

      <Textarea label={'Description'} {...form.getFieldProps('description')} />

      <Button type="submit">Submit</Button>

      <Button onClick={handleClick}>Click</Button>

      <pre>
        Values
        {JSON.stringify(form.values, null, 2)}
      </pre>

      <pre>
        Errors
        {JSON.stringify(form.errors, null, 2)}
      </pre>
    </form>
  );
}
