import {Button, SvgIcon} from '@shipwell/shipwell-ui';
import {RFPBidOptResponseStateEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import ImportModal from 'App/containers/userCompany/rfps/ImportModal';
import PageHeader from 'App/common/pageHeader';
import useToggle from 'App/utils/hooks/useToggle';
import RfpTile from 'App/containers/userCompany/rfps/RfpTile';
import Loader from 'App/common/shipwellLoader';
import {RFP_BIDS_QUERY} from 'App/data-hooks/queryKeys';
import {getOptimizedRfpBids} from 'App/api/rfpOptimized/typed';
import {RFP_OPTIMIZED_TEMPLATE} from 'App/containers/userCompany/constants';
import useInfiniteScroll from 'App/utils/hooks/useInfiniteScroll';

const RfpBase = () => {
  const [showImportModal, toggleShowImportModal] = useToggle();
  const {
    handleScroll,
    pages: rfpBidPages,
    isFetchingNextPage,
    isLoading
  } = useInfiniteScroll([RFP_BIDS_QUERY], getOptimizedRfpBids, {
    refetchInterval: (data, query) => {
      const missingImportedLanes = data?.pages.some((page) =>
        page.results?.some((result) => result.state === RFPBidOptResponseStateEnum.LanesNotImported)
      );
      if (missingImportedLanes && query.state.status !== 'error' && !showImportModal) {
        return 2000;
      }
      return 0;
    },
    refetchIntervalInBackground: true,
    retry: 0
  });

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <div className="RfpBase flex w-full flex-col">
      <PageHeader
        title="RFPs"
        actions={
          <a href={RFP_OPTIMIZED_TEMPLATE}>
            <Button variant="tertiary" isCompact iconName="Download">
              Download CSV Template
            </Button>
          </a>
        }
      />
      <div className="flex p-4">
        <button className="w-full" onClick={toggleShowImportModal}>
          <div className="flex w-full items-center gap-2 rounded border border-sw-border bg-sw-background-component p-4 text-sw-primary hover:bg-sw-active-light">
            <SvgIcon name="AddCircleOutlined" />
            Add New RFP
          </div>
        </button>
      </div>
      <div onScroll={handleScroll} className="flex h-full flex-col gap-4 overflow-auto p-4 pt-0">
        {rfpBidPages.map((page) => {
          return page.results?.map((rfp) => <RfpTile key={rfp.id} rfp={rfp} />);
        })}
        {isFetchingNextPage ? (
          <div className="flex flex-col items-center justify-center">
            <Loader loading />
            <span>Loading next page...</span>
          </div>
        ) : null}
      </div>
      {showImportModal ? <ImportModal showModal={showImportModal} toggleModal={toggleShowImportModal} /> : null}
    </div>
  );
};

export default RfpBase;
