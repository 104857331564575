import {SvgIcon, SvgIconName} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import {WorkflowExecutionInstanceStatusEnum} from '@shipwell/opus-sdk';
import {ActionStepLabel} from 'App/containers/WorkflowSummaryCard/ActionStepLabel';
import {ActionWithParams, getActionParams} from 'App/containers/WorkflowSummaryCard/utils';
import {getWorkflowExecutionShipmentInstance} from 'App/api/workflows/typed';
import {WORKFLOW_ACTION, WORKFLOW_EXECUTION_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {formatTimestamp} from 'App/utils/dateTimeGlobalsTyped';

export const WorkflowTooltip = ({
  name,
  status,
  endDate,
  currentStep,
  startTime,
  workflowExecutionId
}: {
  name?: string;
  status?: WorkflowExecutionInstanceStatusEnum;
  endDate?: string | null;
  currentStep?: string | null;
  startTime?: string | null;
  workflowExecutionId?: string;
}) => {
  const isRunning = status === WorkflowExecutionInstanceStatusEnum.Running;

  const executionQuery = useQuery(
    [WORKFLOW_EXECUTION_QUERY_KEY, workflowExecutionId],
    async () => {
      if (!workflowExecutionId) throw new Error('workflowExecutionId required.');
      const {data} = await getWorkflowExecutionShipmentInstance(workflowExecutionId);
      return data;
    },
    {
      enabled: !!workflowExecutionId && isRunning
    }
  );

  const {data: executionData} = executionQuery;

  const getCurrentStep = () => {
    const currentAction = executionData?.workflow?.actions?.find(
      (action) => action.step_id === currentStep
    ) as unknown as ActionWithParams;
    return currentAction;
  };

  const query = useQuery([WORKFLOW_ACTION, workflowExecutionId, currentStep], () => getActionParams(getCurrentStep()), {
    enabled: executionQuery.isSuccess && !!getCurrentStep()
  });

  const getStatustheme = (): {color: string; icon: SvgIconName; label: string} => {
    switch (status) {
      case WorkflowExecutionInstanceStatusEnum.Failed:
        return {
          color: 'sw-error',
          icon: 'ErrorFilled',
          label: 'Failed'
        };
      case WorkflowExecutionInstanceStatusEnum.Running:
        return {
          color: 'sw-primary',
          icon: 'Running',
          label: 'Running'
        };
      case WorkflowExecutionInstanceStatusEnum.Success:
        return {
          color: 'sw-success',
          icon: 'CheckCircleFilled',
          label: 'Complete'
        };
      case WorkflowExecutionInstanceStatusEnum.Cancelled:
        return {
          color: 'sw-icon',
          icon: 'Cancel',
          label: 'Cancelled'
        };
      default:
        return {
          color: 'sw-primary',
          icon: 'Running',
          label: '--'
        };
    }
  };

  const {color, icon, label} = getStatustheme();

  const iconContainerBackgroundColor = isRunning ? color : 'sw-background-component';
  const iconContainerBorder = isRunning ? 'border-white border-2' : '';
  const iconTextColor = isRunning ? 'sw-background-component' : color;
  const stepNumber = currentStep?.replace(/\D/g, '');

  const actionId = query.data && 'action_id' in query.data ? query.data.action_id : undefined;

  const isLoading = query.isInitialLoading || executionQuery.isInitialLoading;
  const isSuccess = query.isSuccess && executionQuery.isSuccess;

  return (
    <div className="flex items-center gap-2">
      <div className={`bg-${iconContainerBackgroundColor} rounded-full ${iconContainerBorder}`}>
        <SvgIcon className={`text-${iconTextColor}`} name={icon} />
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-bold">
          {name} ({label})
        </span>
        {isRunning ? (
          isLoading ? (
            <SvgIcon name="LoadingDots" />
          ) : isSuccess ? (
            <span>
              On Step {stepNumber}: <ActionStepLabel action={query.data} id={actionId} startTime={startTime} />
            </span>
          ) : null
        ) : endDate ? (
          <span>
            {label} {formatTimestamp(endDate)}
          </span>
        ) : null}
      </div>
    </div>
  );
};
