import {countries} from 'App/utils/countries';

/** SmartPost Hub ID */
export const smartPostHubIds = [
  {value: 5185, label: 'ALPA Allentown'},
  {value: 5303, label: 'ATGA Atlanta'},
  {value: 5213, label: 'BAMD Baltimore'},
  {value: 5281, label: 'CHNC Charlotte'},
  {value: 5929, label: 'COCA Chino'},
  {value: 5751, label: 'DLTX Dallas'},
  {value: 5802, label: 'DNCO Denver'},
  {value: 5481, label: 'DTMI Detroit'},
  {value: 5087, label: 'EDNJ Edison'},
  {value: 5431, label: 'GCOH Grove City'},
  {value: 5771, label: 'HOTX Houston'},
  {value: 5436, label: 'GPOH Groveport Ohio'},
  {value: 5902, label: 'LACA Los Angeles'},
  {value: 5465, label: 'ININ Indianapolis'},
  {value: 5648, label: 'KCKS Kansas City'},
  {value: 5254, label: 'MAWV Martinsburg'},
  {value: 5183, label: 'MAPA Macungie'},
  {value: 5379, label: 'METN Memphis'},
  {value: 5552, label: 'MPMN Minneapolis'},
  {value: 5531, label: 'NBWI New Berlin'},
  {value: 5110, label: 'NENY Newburgh'},
  {value: 5095, label: 'NENJ Newark'},
  {value: 5015, label: 'NOMA Northborough'},
  {value: 5327, label: 'ORFL Orlando'},
  {value: 5194, label: 'PHPA Philadelphia'},
  {value: 5854, label: 'PHAZ Phoenix'},
  {value: 5150, label: 'PTPA Pittsburgh'},
  {value: 5958, label: 'SACA Sacramento'},
  {value: 5097, label: 'SBNJ South Brunswick'},
  {value: 5186, label: 'SCPA Scranton'},
  {value: 5843, label: 'SCUT Salt Lake City'},
  {value: 5983, label: 'SEWA Seattle'},
  {value: 5631, label: 'STMO St. Louis'},
  {value: 5893, label: 'RENV Reno'},
  {value: 5345, label: 'TAFL Tampa'},
  {value: 5602, label: 'WHIL Wheeling'},
  {value: 5061, label: 'WICT Windsor'}
];

export type AllowedPrefixes = 'billing_address' | 'freight_account';
export enum AllowedCountries {
  US = 'US',
  CA = 'CA',
  MX = 'MX'
}
export const countryOptions = countries.filter(({value}) =>
  Object.values(AllowedCountries).includes(value as AllowedCountries)
);
