import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Link} from 'react-router';
import moment from 'moment-timezone';
import EmbeddedPricingTile from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingTile';
import getNil from 'App/utils/getNil';
import 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingHistoricalLaneInfo/styles.scss';
import {filterOutlierData} from 'App/components/pricingIntelChart/utils';
import {
  embeddedPricingVariantsBySource,
  INTERNAL_SHIPPER,
  PRICING_INTEL_DATA_STATE_LOADING
} from 'App/components/pricingIntelChart/pricingIntelConstants';

const EmbeddedHistoricalLaneInfo = ({historicalInternalPrices = {}, historicalPricingDataState}) => {
  const filteredHistoricalPrices = filterOutlierData(historicalInternalPrices, [INTERNAL_SHIPPER]);
  //sort shipments descending by pickup date
  const sortedFilteredInternalShipments = getNil(filteredHistoricalPrices, INTERNAL_SHIPPER, []).sort((a, b) => {
    a = new Date(a.pickup_date);
    b = new Date(b.pickup_date);
    return b - a;
  });
  //get three most recent internal shipments
  const mostRecentInternalShipments = sortedFilteredInternalShipments.slice(0, 3);
  //always show three rows of internal shipments if there are any rows
  while (mostRecentInternalShipments.length > 0 && mostRecentInternalShipments.length < 3) {
    mostRecentInternalShipments.push({});
  }

  return (
    <EmbeddedPricingTile
      title={embeddedPricingVariantsBySource.HISTORICAL_LANE_INFO.contentTitle}
      tooltipContent={embeddedPricingVariantsBySource.HISTORICAL_LANE_INFO.tooltipContent}
      loading={historicalPricingDataState === PRICING_INTEL_DATA_STATE_LOADING}
    >
      <div role="table" title="embeddedPricingHistoricalLaneInfo" className="embeddedPricing__historicalLaneTable">
        <div title="embeddedPricingTableHeader" className="embeddedPricing__historicalLaneTable-header-row">
          <div className="embeddedPricing__historicalLaneTable-header-row-column-header">
            <span>Carrier</span>
          </div>
          <div className="embeddedPricing__historicalLaneTable-header-row-column-header">
            <span>Date</span>
          </div>
          <div className="embeddedPricing__historicalLaneTable-header-row-column-header">
            <span>Carrier Cost</span>
          </div>
        </div>
        {mostRecentInternalShipments.length === 0 ? (
          <div className="embeddedPricing__historicalLaneTable-row-container empty">
            <div title="embeddedPricingTableRowEmpty" className="embeddedPricing__historicalLaneTable-row empty">
              <span className="embeddedPricing__historicalLaneTable-row-empty-message">
                No Lane Information Available
              </span>
            </div>
          </div>
        ) : (
          <div title="embeddedPricingTableRowContainer" className="embeddedPricing__historicalLaneTable-row-container">
            {mostRecentInternalShipments.map(
              ({
                id,
                freight_authority_vendor_relationship_id: freightAuthorityVendorRelationshipId,
                carrier_name: carrierName,
                pickup_date: pickupDate,
                carrier_cost: carrierCost
              }) => {
                return (
                  <div title="embeddedPricingTableRow" className="embeddedPricing__historicalLaneTable-row" key={id}>
                    <div>
                      {freightAuthorityVendorRelationshipId ? (
                        <Link target="_blank" to={`/carriers/${freightAuthorityVendorRelationshipId}`}>
                          {carrierName}
                        </Link>
                      ) : (
                        <span>{carrierName || '--'}</span>
                      )}
                    </div>
                    <div>
                      {pickupDate && moment(pickupDate).isValid() ? moment(pickupDate).format('MM/DD/YY') : '--'}
                    </div>
                    <div
                      className={classnames({
                        'valid-carrier-cost': carrierCost > 0
                      })}
                    >
                      {carrierCost ? `$${Math.round(carrierCost)}` : `--`}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </EmbeddedPricingTile>
  );
};

EmbeddedHistoricalLaneInfo.propTypes = {
  historicalInternalPrices: PropTypes.object,
  historicalPricingDataState: PropTypes.string
};

export default EmbeddedHistoricalLaneInfo;
