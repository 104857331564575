import {Formik} from 'formik';
import {object, string, mixed} from 'yup';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import LetterOfReleaseFields from '../../formSections/TriumphLetterOfReleaseFields';
import './styles.scss';

const defaultFormValues = {
  letter_of_release_description: 'Letter of Release',
  letter_of_release_file: ''
};

const letterOfReleaseSchema = object().shape({
  letter_of_release_description: string().required('Description is required'),
  letter_of_release_file: mixed().required('Letter of release document is required')
});
const LetterOfReleaseForm = (props) => {
  const {onCancel, onSubmit, handleSubmit} = props;

  return (
    <Formik
      enableReinitialize
      validationSchema={letterOfReleaseSchema}
      initialValues={{...defaultFormValues}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, isSubmitting, dirty, values, ...props}) => (
        <form className="letter-of-release-form" noValidate="novalidate" onSubmit={handleSubmit}>
          <LetterOfReleaseFields {...props} />
          <div className="page-footer">
            {onCancel && (
              <DeprecatedButton variant="secondary" onClick={onCancel}>
                Cancel
              </DeprecatedButton>
            )}
            {onSubmit && (
              <DeprecatedButton
                disabled={isSubmitting}
                icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                onClick={handleSubmit}
              >
                Save
              </DeprecatedButton>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LetterOfReleaseForm;
