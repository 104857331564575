import {WithRouterProps} from 'react-router';
import {parse} from 'query-string';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useShipmentLocations} from 'App/containers/alertsDashboard/utils/hooks/useShipmentLocations';
import Map from 'App/components/trimbleMap';
import TrackingItems from 'App/containers/ExternalTracking/TrackingItems';
import TrackingHeader from 'App/containers/ExternalTracking/TrackingHeader';
import ShipwellLoader from 'App/common/shipwellLoader';
import TrackingSteps from 'App/containers/ExternalTracking/TrackingSteps';
import TrackingLogo from 'App/containers/ExternalTracking/TrackingLogo';
import TrackingBanner from 'App/containers/ExternalTracking/TrackingBanner';
import ErrorPage from 'App/common/ErrorPage';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import {formatMileage} from 'App/utils/globals';

type TrackingProps = Pick<WithRouterProps, 'location' | 'params'>;
interface ExternalShipmentMapProps {
  shipment: Shipment;
  queryKey: string;
  filterDistance?: string;
  resizeMap?: boolean;
}

const ExternalShipmentMap = ({shipment, queryKey, filterDistance, resizeMap}: ExternalShipmentMapProps) => {
  const {shipmentLocations, route, queryInfo, trackingLinePoints} = useShipmentLocations({
    shipment,
    filterDistance,
    isUnauth: true,
    key: queryKey
  });

  return (
    <div className="relative h-full">
      {queryInfo.isInitialLoading ? (
        <>Loading Map...</>
      ) : (
        <Map
          additionalClassNames="absolute inset-0"
          elementId="externalTracking__map"
          locations={shipmentLocations}
          resizeMap={resizeMap}
          route={route}
          address={shipment?.current_address}
          totalDistance={formatMileage(shipment?.total_miles)}
          trackingLinePoints={trackingLinePoints}
        />
      )}
    </div>
  );
};

export default function Tracking(props: TrackingProps) {
  const shipmentId = props?.params?.id;
  const key = get(parse(props.location.search), 'key') as string;
  const includeMap = parse(props.location.search)?.include_map?.toString()?.toLowerCase() === 'true'; // capitalized in python so we need to validate any type here
  const {shipmentQuery} = useLegacyShipment(shipmentId, {}, true, key);

  if (shipmentQuery.isLoading) {
    return <ShipwellLoader loading />;
  }

  if (!shipmentQuery.isLoading && isEmpty(shipmentQuery.data)) {
    return render404();
  }

  function render404() {
    return (
      <ErrorPage
        primaryMessage="Sorry, we couldn't find this shipment."
        secondaryMessage="Please check your URL and try again, or contact Shipwell for assistance."
      ></ErrorPage>
    );
  }

  return (
    <div className="h-screen">
      <div className="p-6">
        <TrackingLogo />
        <TrackingBanner status={shipmentQuery.data?.state} />
        <TrackingHeader shipment={shipmentQuery.data} />
        <TrackingSteps status={shipmentQuery.data?.state} />
        <TrackingItems items={shipmentQuery.data?.line_items} />
      </div>
      {shipmentQuery.data && includeMap && <ExternalShipmentMap queryKey={key} shipment={shipmentQuery.data} />}
    </div>
  );
}
