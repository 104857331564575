import {useQuery} from '@tanstack/react-query';

export const fetchZplFile = async (file: string, filename: string) => {
  const responseZplByUrl = await fetch(file);
  const zplBlob = await responseZplByUrl.blob();
  const zplFile = new File([zplBlob], filename);
  const formdata = new FormData();

  formdata.append('file', zplFile);
  const requestOptions: RequestInit = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  // api docs: http://labelary.com/service.html
  // In the future, we should be able to GET the details of the label so we can pass in accurate dimensions...
  // ...instead of the hardcoded 4x7 below
  const response = await fetch('https://api.labelary.com/v1/printers/8dpmm/labels/4x7/0/', requestOptions);
  const arrayBuffer = await response.arrayBuffer();
  const blob = new Blob([arrayBuffer], {type: 'image/png'});
  return URL.createObjectURL(blob);
};

export const useViewZpl = ({
  document,
  isEnabled
}: {
  document?: {id: string; file: string; filename: string};
  isEnabled: boolean;
}) => {
  const enabled = Boolean(isEnabled && document?.id && document?.file && document?.filename);
  const generateZplImageQuery = useQuery(
    ['generate-zpl', document?.id],
    async () => await fetchZplFile(document?.file || '', document?.filename || ''),
    {enabled}
  );

  return generateZplImageQuery;
};
