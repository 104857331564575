import {useCallback, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {FacilityDocument, ShipwellApiErrorResponse, Facility} from '@shipwell/tempus-sdk';
import {Modal, Card, SvgIcon, DeprecatedButton, FileDisplay} from '@shipwell/shipwell-ui';
import {FacilityDocumentPostType} from 'App/data-hooks/facilities/types';
import {useDeleteFacilityDocument, useCreateFacilityDocument} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {parseV3ApiError} from 'App/api/typedUtils';
import {FACILITY_DOCUMENT_UPLOAD_LIMIT} from 'App/data-hooks/facilities/constants';

type FacilityDocumentsListProps = {
  facility?: Facility | null;
  documents?: FacilityDocument[];
} & WithStatusToastProps;

const FacilityDocumentsListBase = ({facility, documents, setError, setSuccess}: FacilityDocumentsListProps) => {
  const facilityId = facility?.id || '';
  const documentsLength = documents?.length || 0;

  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [deleteDocumentId, setDeleteDocumentId] = useState<string>('');
  const [showUploadModal, toggleUploadModal] = useToggle();
  const [showViewModal, toggleViewModal] = useToggle();
  const [showDeleteModal, toggleDeleteModal] = useToggle();

  const onError = useCallback(
    (error: AxiosError<ShipwellApiErrorResponse>) => {
      const formattedError = parseV3ApiError(error);
      if (formattedError.title && formattedError.detail) {
        setError(formattedError.title, formattedError.detail);
      } else if (formattedError.isError) {
        setError('Document update failed', 'Unrecognized internal error.');
      }
    },
    [setError]
  );

  const onSuccess = useCallback(() => {
    setSuccess('Update successful!', `Successfully updated facility ${facility?.name ?? ''}`);
  }, [setSuccess, facility]);
  const {mutateAsync: deleteDocumentMutation} = useDeleteFacilityDocument({onError, onSuccess});
  const {mutateAsync: createFacilityDocumentMutation} = useCreateFacilityDocument({
    onSuccess,
    onError
  });

  const handleUploadDocument = async ({file, description}: FacilityDocumentPostType) => {
    toggleUploadModal();
    await createFacilityDocumentMutation({
      facilityId,
      file: file,
      description: description
    });
  };

  const onDocumentViewClick = useCallback(
    (documentId: string | null | undefined) => setViewDocumentId(documentId || ''),
    []
  );
  const onDocumentDeleteClick = useCallback(
    (documentId: string | null | undefined) => setDeleteDocumentId(documentId || ''),
    []
  );
  const selectedViewDocument = useMemo(
    () => (viewDocumentId ? documents?.find((doc) => doc.id === viewDocumentId) : null),
    [viewDocumentId, documents]
  );
  const FacilityDocumentViewer = () => {
    return (
      <div className="flex-h-full flex-row">
        <FileDisplay
          fileURL={selectedViewDocument?.url}
          fileName={selectedViewDocument?.filename}
          altText={selectedViewDocument?.description}
        />
      </div>
    );
  };

  const handleDeleteFacilityDocument = async () => {
    toggleDeleteModal();
    await deleteDocumentMutation({documentId: deleteDocumentId, facilityId});
  };

  return (
    <Card
      className="mt-4 w-1/2"
      draggableProvided={null}
      title="Facility Map"
      actions={
        <DeprecatedButton
          variant="tertiary"
          icon={<SvgIcon name="Upload" />}
          onClick={toggleUploadModal}
          disabled={documentsLength >= FACILITY_DOCUMENT_UPLOAD_LIMIT}
        >
          Upload Facility Map
        </DeprecatedButton>
      }
    >
      <div>
        {documentsLength > 0 ? (
          <>
            {documents?.map((fetchedDocument) => (
              <div key={fetchedDocument.id} className="flex items-center border-b-sw-border-light">
                <div
                  className="flex size-[50px] items-center overflow-hidden"
                  onClick={() => {
                    toggleViewModal();
                    onDocumentViewClick(fetchedDocument.id);
                  }}
                >
                  <FileDisplay
                    maxHeight={40}
                    maxWidth={40}
                    isThumbnail
                    fileName={fetchedDocument.filename}
                    fileURL={fetchedDocument.url || '/images/clipboard.png'}
                  />
                </div>
                <div className="flex-1 pr-5">{fetchedDocument.filename}</div>
                <DeprecatedButton
                  variant="icon"
                  onClick={() => {
                    toggleDeleteModal();
                    onDocumentDeleteClick(fetchedDocument.id);
                  }}
                >
                  <SvgIcon name="TrashOutlined" />
                </DeprecatedButton>
              </div>
            ))}
          </>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-y-2">
            <div className="text-sm text-sw-disabled-text">There are no documents to preview for this Facility.</div>
          </div>
        )}
        <Modal
          show={showUploadModal}
          portal
          title="Upload Facility Map"
          footerComponent={null}
          onClose={toggleUploadModal}
        >
          <ShipmentDocumentForm
            values={documents}
            onCancel={toggleUploadModal}
            onSubmit={handleUploadDocument}
            hideTypeField
          />
        </Modal>
        <Modal show={showViewModal} portal title="View Facility Map" footerComponent={null} onClose={toggleViewModal}>
          <FacilityDocumentViewer />
        </Modal>
        <Modal
          show={showDeleteModal}
          title="Confirm Delete"
          primaryBtnName="Delete"
          onClose={toggleDeleteModal}
          onPrimaryAction={handleDeleteFacilityDocument}
        >
          Are you sure you want to delete this file?
        </Modal>
      </div>
    </Card>
  );
};

const FacilityDocumentsList = WithStatusToasts(FacilityDocumentsListBase);
export default FacilityDocumentsList;
