import {GET_BROKER_LOGOS} from './types';
import {brokers} from 'App/api';

// log in as a shipper
export function getCompanyLogo(subdomain) {
  const nonWhiteLabel = [
    {
      image_type: 'INLINE_WHITE',
      logo: '/images/svgs/shipwell-logo-white.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'INLINE_COLOR',
      logo: '/images/svgs/shipwell-logo.svg',
      company_name: 'Shipwell'
    },
    {
      image_type: 'LOGO_WHITE',
      logo: '/images/shipwell_logo_white.png',
      company_name: 'Shipwell'
    }
  ];
  return (dispatch) => {
    return brokers
      .getCompanyLogoPromise(subdomain)
      .then((response) => {
        dispatch({type: GET_BROKER_LOGOS, payload: response.body});
        return {status: 200, details: response.body};
      })
      .catch((response) => {
        dispatch({
          type: GET_BROKER_LOGOS,
          payload: nonWhiteLabel
        });
        return {
          status: 400,
          details: nonWhiteLabel
        };
      });
  };
}
