import Icon from './components/icon';
import Card from './components/card';
import Step from './components/step';
import './styles.scss';

const iconList = [
  'loadboard',
  'action',
  'broadcast',
  'call',
  'email',
  'notify',
  'refresh',
  'sms',
  'tender',
  'trigger',
  'verify',
  'workflow'
];

const AutomationUIElements = (props) => {
  return (
    <div className="automation">
      <h3>Icons</h3>
      <div>
        {iconList.map((icon, i) => {
          return <Icon type={icon} />;
        })}
      </div>

      <h3>Cards</h3>
      <div>
        <Card
          title="Trigger"
          body={
            <div>
              <p>Shipment matches the following criteria: </p>
              <div>stuff</div>
            </div>
          }
        />
      </div>

      <h3>Steps</h3>
      <div>
        <Step
          title="Trigger"
          type="trigger"
          body={
            <div>
              <p>Shipment matches the following criteria: </p>
              <div>stuff</div>
            </div>
          }
        />
        <Step
          title="Broadcast"
          type="broadcast"
          body={
            <div>
              <p>Broadcast form fields here </p>
              <div>stuff</div>
            </div>
          }
          onDeleteStep={() => {
            console.log('delete');
          }}
        />
        <Step
          type="add"
          title={null}
          body={
            <>
              <Icon type="tender" />
              <Icon type="broadcast" />
              <Icon type="loadboard" />
            </>
          }
        />
      </div>
    </div>
  );
};

export default AutomationUIElements;
