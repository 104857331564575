import {Component} from 'react';
import {ControlLabel, FormGroup, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {get, isEqual, uniq} from 'lodash';
import {Async, AsyncCreatable} from 'react-select';

class renderAsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.state = {
      selection: null
    };
  }

  componentWillReceiveProps(nextProps) {
    // set the default value if passed in

    if (!isEqual(nextProps.defaultValue, this.props.defaultValue)) {
      this.setState({
        selection: nextProps.defaultValue
      });
    }
  }

  getOptions(input, callback) {
    return this.props.getOptions(input).then((response) => {
      const options = [];
      if (response.results) {
        for (var i = 0; i < response.results.length; i++) {
          const valueObj = get(response.results[i], this.props.valueField, response.results[i]);
          const labelObj = get(response.results[i], this.props.labelField, response.results[i]);
          const disabled = get(response.results[i], 'disabled', false);
          options.push({
            value: valueObj,
            label: labelObj,
            disabled
          });
        }
      }
      return {options: options};
    });
  }

  render() {
    const {selection} = this.state;
    const {
      input,
      meta: {touched, error},
      icon,
      label,
      data,
      req,
      isMulti,
      disabled,
      cache,
      sronly,
      loadOptions,
      tooltip,
      allowCreate,
      promptTextCreator,
      loadingPlaceholder,
      ...rest
    } = this.props;

    const Component = allowCreate ? AsyncCreatable : Async;
    return (
      <FormGroup validationState={touched && error ? 'error' : null}>
        {label && (
          <ControlLabel className={sronly ? 'sr-only' : ''}>
            {label}
            {req && <span className="requiredField">*</span>}
            {tooltip && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <div
                      style={{
                        display: 'block',
                        textAlign: 'left'
                      }}
                    >
                      {tooltip}
                    </div>
                  </Tooltip>
                }
              >
                <a tabIndex="-1">
                  <i className="icon icon-Info" aria-hidden="true" />
                </a>
              </OverlayTrigger>
            )}
          </ControlLabel>
        )}
        <InputGroup className="input-group-inline" style={{width: '100%'}}>
          {icon && (
            <InputGroup.Addon>
              <i className={icon} />
            </InputGroup.Addon>
          )}
          <Component
            {...input}
            multi={isMulti ? isMulti : false}
            disabled={disabled}
            onChange={(value) => {
              this.setState({selection: value});
              input.onChange(value);
            }}
            cache={cache}
            className={!icon ? 'no-icon' : ''}
            placeholder={this.props.placeholder || ''}
            value={selection ? selection : this.props.defaultValue}
            onBlur={() => input.onBlur(selection)}
            loadOptions={loadOptions || this.getOptions}
            filterOptions={(options, filter, currentValues) => {
              // Do no filtering, just return all options
              //unlesss this is a createable
              if (allowCreate) {
                return uniq(options, 'value');
              }
              return options;
            }}
            loadingPlaceholder={loadingPlaceholder}
            promptTextCreator={promptTextCreator}
            {...rest}
          />
        </InputGroup>
        {touched && error && (
          <p className="error-text">
            <i className="icon icon-Delayed" />
            {error}
          </p>
        )}
      </FormGroup>
    );
  }
}

export default renderAsyncSelect;
