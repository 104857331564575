import {useState} from 'react';
import {TableTabEnums} from '../tableUtils';
import useToggle from 'App/utils/hooks/useToggle';

export function useTableSidebar(defaultSelectedTab?: string, defaultOpen?: boolean) {
  const [isOpen, toggleIsOpen] = useToggle(Boolean(defaultOpen));
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab || TableTabEnums.filters);

  return {
    isOpen,
    toggleIsOpen,
    selectedTab,
    setSelectedTab
  };
}
