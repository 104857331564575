import moment from 'moment';
import {ItemWithStepsCardProps, MetaInfo, StepInfo} from '../../itemWithStepsCard/itemWithStepsCard';
import {StepStatusEnum} from '../../itemWithStepsCard/stepStatusEnum';
import {InvocationData} from '../useScriptsService/invocationData';

export function getCardPropsFromInvocationData(data: InvocationData) {
  const status = getStatus(data);

  const metaInfo: MetaInfo[] = [
    {title: 'Trigger', content: data.triggerEvent},
    {title: 'Start date', content: getFormattedDate(data.startDate)},
    {title: 'End date', content: getFormattedDate(data.endDate)}
  ];

  const stepsInfos: StepInfo[] = [
    {
      status,
      content: data.message
    }
  ];

  const result: ItemWithStepsCardProps = {
    title: data.name || 'Unknown',
    metaInfos: metaInfo,
    stepsInfo: stepsInfos,
    status
  };

  return result;
}

function getStatus(data: InvocationData) {
  switch (data.didSucceed) {
    case true:
      return StepStatusEnum.SUCCESS;
    case false:
      return StepStatusEnum.ERROR;
    default:
      return StepStatusEnum.RUNNING;
  }
}

function getFormattedDate(date: string | undefined) {
  return date ? moment(date).format('MM/DD/YYYY') : '...';
}
