import {
  useQueryClient,
  QueryKey,
  MutationOptions,
  UseQueryResult,
  useQuery,
  QueryFunction,
  UseQueryOptions,
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query';
import get from 'lodash/get';
import set from 'lodash/set';
import noop from 'lodash/noop';

type PreviousDataProp<TData, TContext> = ((context?: TContext) => TData) | string | undefined;
type BodyResultsData = {body: {results: Record<string, unknown>}};
type NestedData = {data: {data: Record<string, unknown>}};
type MergeDataFn<TData, TVariables> = (previousData?: TData, variables?: TVariables) => TData | undefined;

function isNestedData(data?: unknown): data is NestedData {
  return (data as NestedData)?.data !== undefined;
}

function isBodyResultsData(data?: unknown): data is BodyResultsData {
  return (data as BodyResultsData)?.body?.results !== undefined;
}

export function useInvalidateOnSettled() {
  const queryClient = useQueryClient();
  return function createInvalidateOnSettled(queryKey: QueryKey) {
    return function onSettled() {
      return queryClient.invalidateQueries(queryKey);
    };
  };
}

export function useRefetchOnSettled() {
  const queryClient = useQueryClient();
  return function createRefetchOnSettled(queryKey: QueryKey) {
    return function onSettled() {
      return queryClient.refetchQueries(queryKey);
    };
  };
}

export function useRollbackErrorHandler<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>() {
  const queryClient = useQueryClient();
  return function createRollbackErrorHandler(
    queryKey: QueryKey,
    errorHandler: MutationOptions<TData, TError, TVariables, TContext>['onError'] = noop,
    previousDataProp: PreviousDataProp<TData, TContext> = 'previousData'
  ) {
    return async function onError(error: TError, variables: TVariables, context?: TContext) {
      await errorHandler(error, variables, context);
      const previousDataPropIsString = typeof previousDataProp === 'string';
      let previousData: TData;
      if (previousDataPropIsString) {
        previousData = get(context, previousDataProp) as TData;
      } else {
        previousData = previousDataProp(context);
      }
      if (previousData) {
        queryClient.setQueryData<TData>(queryKey, previousData);
      }
    };
  };
}

export function useOptimisticMutation<TData = unknown, TVariables = void>() {
  const queryClient = useQueryClient();
  return function createOptimisticMutationHandler(
    queryKey: QueryKey,
    mergeData?: MergeDataFn<TData, TVariables>,
    previousDataProp = 'previousData'
  ) {
    return async function onMutate(variables?: TVariables) {
      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData<TData>(queryKey);
      if (previousData) {
        const mergedData = mergeData
          ? mergeData(previousData, variables)
          : {...previousData, ...getNestedVariableData(previousData, variables)};
        queryClient.setQueryData(queryKey, mergedData);
      }
      return {[previousDataProp]: previousData};
    };
  };
}

export function useOptimisticUpdate<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>() {
  const createInvalidateOnSettled = useInvalidateOnSettled();
  const createRollbackErrorHandler = useRollbackErrorHandler<TData, TError, TVariables, TContext>();
  const createOptimisticMutationHandler = useOptimisticMutation<TData, TVariables>();
  return function createOptimisticUpdateHandlers(
    queryKey: QueryKey,
    {
      errorHandler,
      mergeData,
      previousDataProp
    }: {
      errorHandler?: MutationOptions<TData, TError, TVariables, TContext>['onError'];
      mergeData?: MergeDataFn<TData, TVariables>;
      previousDataProp?: string;
    } = {}
  ) {
    return {
      onSettled: createInvalidateOnSettled(queryKey),
      onError: createRollbackErrorHandler(queryKey, errorHandler, previousDataProp),
      onMutate: createOptimisticMutationHandler(queryKey, mergeData, previousDataProp)
    };
  };
}

export function mergeDataBodyResults<TData = unknown, TVariables = void>(getMutationData = noop) {
  return function mergeData(previousData: TData, variables: TVariables) {
    if (!isBodyResultsData(previousData)) {
      return {};
    }
    const mutationData = getMutationData(get(previousData, 'body.results', []), variables);
    return set(previousData, 'body.results', mutationData);
  };
}

function getNestedVariableData<TVariables = void>(previousData: unknown, variables: TVariables) {
  if (!isNestedData(variables)) {
    return {};
  }
  return variables.data;
}

export function mergeDataNestedData<TData = unknown, TVariables = void>(getMutationData = getNestedVariableData) {
  return function mergeData(previousData?: TData, variables?: TVariables) {
    if (!isNestedData(previousData)) {
      return {};
    }
    const nestedData = previousData.data.data || {};
    const mutationData = getMutationData(nestedData, variables);
    return set(previousData, 'data.data', {...nestedData, ...mutationData});
  };
}

// returns an object representing the loading, fetching, error, and success statuses
// of all the nested queries. also returns the data under a top level results key.
export function getQueriesStatus<TData>(queries: UseQueryResult<TData, unknown>[]) {
  return {
    ...queries.reduce(
      (
        statusObject: {
          isLoading: boolean;
          isFetching: boolean;
          isError: boolean;
          isSuccess: boolean;
          isInitialLoading: boolean;
        },
        query
      ) => {
        return {
          isLoading: !statusObject.isLoading ? statusObject.isLoading : query.isLoading,
          isFetching: !statusObject.isFetching ? statusObject.isFetching : query.isFetching,
          isError: !statusObject.isError ? statusObject.isError : query.isError,
          isSuccess: !statusObject.isSuccess ? statusObject.isSuccess : query.isSuccess,
          isInitialLoading: !statusObject.isInitialLoading ? statusObject.isInitialLoading : query.isInitialLoading
        };
      },
      {isLoading: true, isFetching: true, isError: true, isSuccess: true, isInitialLoading: true}
    ),
    queries: queries,
    data: queries.map((query) => query.data)
  };
}
//https://tkdodo.eu/blog/react-query-and-type-script#the-four-generics
type QueryProps<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = {
  queryKey: TQueryKey;
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
  children: (params: UseQueryResult<TData, TError>) => JSX.Element;
};

/**
 * Utility component for using React-Query in class components
 */
export const Query = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryKey,
  queryFn,
  options = {},
  children
}: QueryProps<TQueryFnData, TError, TData, TQueryKey>) => {
  const query = useQuery(queryKey, queryFn, options);
  return children(query);
};

/**
 * Produces the type of the options argument for useMutation based on the type of the mutation function. This is useful
 * for creating a custom hook that wraps useMutation. This will only work if you are passing the mutation function as-is
 * and not wrapping it in another function when passing it to useMutation.
 *
 * @example const useUpdateFoo = (options?: UseMutationOptionsUtil<typeof useUpdateFoo>) => useMutation(useUpdateFoo, options)
 */
export type UseMutationOptionsUtil<
  TMutationFunction extends MutationFunction<Awaited<ReturnType<TMutationFunction>>, Parameters<TMutationFunction>[0]>
> = Omit<
  UseMutationOptions<Awaited<ReturnType<TMutationFunction>>, unknown, Parameters<TMutationFunction>[0], unknown>,
  'mutationFn'
>;
