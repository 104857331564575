import {ChangeEvent} from 'react';
import {flexRender} from '@tanstack/react-table';
import {useQuery} from '@tanstack/react-query';
import {Checkbox, RadioGroup, SearchField, SvgIcon, IconButton} from '@shipwell/shipwell-ui';
import {
  Table,
  TableContainer,
  TableCell,
  TableHeaderRow,
  TableHeader,
  TableRow,
  TableHeaderSortIcon,
  TableFooter
} from '../baseComponents';
import {
  useTypedTable,
  useTableSort,
  useTableSidebar,
  useTableColumns,
  useTablePagination,
  useTableFilters,
  useSavedTable
} from '../hooks';
import {
  TableSidebar,
  TableSidebarHeader,
  TableFilter,
  TableFiltersTab,
  TableColumnsTab,
  SidebarFooterForm,
  TableSavedTab,
  DashboardItem,
  TableSidebarFooter,
  ReadbackContainer,
  ReadbackTag,
  TableLoadingBar
} from '../complementaryComponents';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS, TableTabEnums, getHasFiltersApplied} from '../tableUtils';
import {fetchData} from './playgroundUtils';
import {columns as columnData} from './columns';
import {PLAYGROUND_TABLE_KEY} from 'App/utils/tableTypeKeys';

const initialFilterState = {q: '', firstName: '', lastName: '', status: '', age: []};
const initialColumnVisibility = {firstName: false};
const initialSortState = {id: 'lastName', desc: false};

export const TablePlayground = ({shouldUseRouter = true}: {shouldUseRouter?: boolean}) => {
  const {columns, columnOrder, setColumnOrder, columnVisibility, setColumnVisibility} = useTableColumns(
    columnData,
    PLAYGROUND_TABLE_KEY,
    initialColumnVisibility
  );

  const {
    filters,
    onFilterChange,
    openFilters,
    onToggleFilter,
    onExpandAllFilters,
    onCollapseAllFilters,
    onDashboardChange
  } = useTableFilters(initialFilterState, shouldUseRouter);
  const {q, firstName, lastName, status, age} = filters;
  const [sorting, setSorting, sortString] = useTableSort({defaultSort: initialSortState});

  const {
    dashboardsQuery,
    onCreateDashboard,
    onUpdateDashboard,
    onDeleteDashboard,
    onSelectDashboard,
    selectedDashboardId,
    isNewDashboard,
    createDashboardSuccess
  } = useSavedTable({
    tableType: PLAYGROUND_TABLE_KEY,
    initialFilterState,
    filterState: filters,
    initialColumnOrder: columnData.map((col) => col.id || ''),
    columnOrder,
    onColumnOrderChange: setColumnOrder,
    initialColumnVisibility,
    columnVisibility,
    onColumnVisibilityChange: setColumnVisibility,
    initialSortState,
    sortString,
    onSort: setSorting,
    shouldUseRouter,
    onFilterChange,
    onDashboardChange
  });

  const {isOpen, toggleIsOpen, selectedTab, setSelectedTab} = useTableSidebar();
  const [pagination, setPagination] = useTablePagination({tableType: PLAYGROUND_TABLE_KEY, shouldUseRouter});
  const params = {
    sortBy: sortString,
    ...filters,
    ...pagination
  };

  const dataQuery = useQuery(['test-data', params], () => fetchData(params), {keepPreviousData: true});

  const table = useTypedTable({
    data: dataQuery.data?.results || [],
    columns,
    pageCount: dataQuery.data?.total_pages || -1,
    state: {
      sorting,
      pagination,
      columnOrder,
      columnVisibility
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility
  });

  const isCheckedAgeOption = (option: string) => age.some((val) => val === option);
  const updateAgeFilter = (option: string) => {
    onFilterChange('age', isCheckedAgeOption(option) ? [...age.filter((val) => val !== option)] : [...age, option]);
  };
  const selectedDashboardName =
    dashboardsQuery.data?.results?.find((dashboard) => dashboard.id === selectedDashboardId)?.name || undefined;

  const hasFiltersApplied = getHasFiltersApplied(filters);

  return (
    <>
      {dataQuery.isFetching ? (
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0">
            <TableLoadingBar />
          </div>
        </div>
      ) : null}
      <div className="flex w-full h-screen-minus-16">
        <TableSidebar isOpen={isOpen} onClick={toggleIsOpen} hasFiltersApplied={hasFiltersApplied}>
          <TableSidebarHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={toggleIsOpen} />
          {selectedTab === TableTabEnums.filters ? (
            <TableFiltersTab
              footer={
                createDashboardSuccess ? (
                  <TableSidebarFooter>
                    <div className="flex items-start gap-2 bg-sw-background-component p-2">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-bold">Dashboard Saved</span>
                          <SvgIcon color="sw-success" name="CheckCircleFilled" />
                        </div>
                        <span>
                          To view your dashboards, select <span className="font-bold text-sw-primary">Saved</span> at
                          the top of the column panel.
                        </span>
                      </div>
                      <IconButton iconName="Close" aria-label="dismiss success message" />
                    </div>
                  </TableSidebarFooter>
                ) : (
                  <SidebarFooterForm
                    onCreate={onCreateDashboard}
                    isNewDashboard={isNewDashboard}
                    onUpdate={onUpdateDashboard}
                  >
                    <div className="flex gap-2">
                      <IconButton iconName="ExpandAll" aria-label="Show all filters" onClick={onExpandAllFilters} />
                      <IconButton iconName="CollapseAll" aria-label="Hide all filters" onClick={onCollapseAllFilters} />
                    </div>
                  </SidebarFooterForm>
                )
              }
            >
              <TableFilter
                isOpen={openFilters.firstName}
                onToggleOpen={() => onToggleFilter('firstName')}
                label="First Name"
                isClearable={!!firstName}
                onClear={() => onFilterChange('firstName', '')}
              >
                <SearchField
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChange('firstName', e.target.value)}
                />
              </TableFilter>
              <TableFilter
                isOpen={openFilters.lastName}
                onToggleOpen={() => onToggleFilter('lastName')}
                label="Last Name"
                isClearable={!!lastName}
                onClear={() => onFilterChange('lastName', '')}
              >
                <SearchField
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChange('lastName', e.target.value)}
                />
              </TableFilter>
              <TableFilter
                isOpen={openFilters.age}
                onToggleOpen={() => onToggleFilter('age')}
                label="Age"
                isClearable={!!age.length}
                onClear={() => onFilterChange('age', [])}
              >
                <div className="flex flex-col gap-2">
                  <Checkbox
                    label="18 and under"
                    name="18_and_under"
                    checked={isCheckedAgeOption('18_and_under')}
                    onChange={() => updateAgeFilter('18_and_under')}
                    fixedHeight={false}
                  />
                  <Checkbox
                    label="19 to 25"
                    name="19_to_25"
                    checked={isCheckedAgeOption('19_to_25')}
                    onChange={() => updateAgeFilter('19_to_25')}
                    fixedHeight={false}
                  />
                  <Checkbox
                    label="26 to 35"
                    name="26_to_35"
                    checked={isCheckedAgeOption('26_to_35')}
                    onChange={() => updateAgeFilter('26_to_35')}
                    fixedHeight={false}
                  />
                  <Checkbox
                    label="36 to 50"
                    name="36_to_50"
                    checked={isCheckedAgeOption('36_to_50')}
                    onChange={() => updateAgeFilter('36_to_50')}
                    fixedHeight={false}
                  />
                  <Checkbox
                    label="51 and up"
                    name="51 _to_up"
                    checked={isCheckedAgeOption('51_and_up')}
                    onChange={() => updateAgeFilter('51_and_up')}
                    fixedHeight={false}
                  />
                </div>
              </TableFilter>
              <TableFilter
                isOpen={openFilters.status}
                onToggleOpen={() => onToggleFilter('status')}
                label="Status"
                isClearable={!!status}
                onClear={() => onFilterChange('status', '')}
              >
                <RadioGroup
                  name="status"
                  options={[
                    {label: 'Online', value: 'online'},
                    {label: 'Offline', value: 'offline'},
                    {label: 'Absent', value: 'absent'}
                  ]}
                  value={status}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChange('status', e.target.value)}
                />
              </TableFilter>
            </TableFiltersTab>
          ) : null}
          {selectedTab === TableTabEnums.columns ? (
            <TableColumnsTab
              onColumnOrderChange={setColumnOrder}
              columns={table.getAllLeafColumns()}
              footer={
                createDashboardSuccess ? (
                  <TableSidebarFooter>
                    <div className="flex items-start gap-2 bg-sw-background-component p-2">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-bold">Dashboard Saved</span>
                          <SvgIcon color="sw-success" name="CheckCircleFilled" />
                        </div>
                        <span>
                          To view your dashboards, select <span className="font-bold text-sw-primary">Saved</span> at
                          the top of the column panel.
                        </span>
                      </div>
                      <IconButton iconName="Close" aria-label="dismiss success message" />
                    </div>
                  </TableSidebarFooter>
                ) : isNewDashboard ? (
                  <SidebarFooterForm
                    onCreate={onCreateDashboard}
                    isNewDashboard={isNewDashboard}
                    onUpdate={onUpdateDashboard}
                  />
                ) : null
              }
            />
          ) : null}
          {selectedTab === TableTabEnums.saved ? (
            <TableSavedTab isLoading={dashboardsQuery.isLoading}>
              {dashboardsQuery.data?.results?.map((dashboard) => (
                <DashboardItem
                  key={dashboard.id}
                  dashboard={dashboard}
                  onUpdate={onUpdateDashboard}
                  onDelete={onDeleteDashboard}
                  onSelect={onSelectDashboard}
                  isSelected={selectedDashboardId === dashboard.id}
                />
              ))}
            </TableSavedTab>
          ) : null}
        </TableSidebar>
        <TableContainer>
          <div className="sticky top-0 p-1">
            <SearchField
              name="query"
              label="Search the table"
              value={q}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChange('q', e.target.value)}
            />
          </div>
          {/* readback should only be shown if a filter is applied */}
          {hasFiltersApplied ? (
            <ReadbackContainer label={selectedDashboardName} onClearAll={() => onDashboardChange(initialFilterState)}>
              {firstName ? (
                <ReadbackTag label={`First Name: ${firstName}`} onClick={() => onFilterChange('firstName', '')} />
              ) : null}
              {lastName ? (
                <ReadbackTag label={`Last Name: ${lastName}`} onClick={() => onFilterChange('lastName', '')} />
              ) : null}
              {status ? <ReadbackTag label={`Status: ${status}`} onClick={() => onFilterChange('status', '')} /> : null}
              {age.length > 0 ? (
                <ReadbackTag label={`Age: ${age.join(', ')}`} onClick={() => onFilterChange('age', [])} />
              ) : null}
            </ReadbackContainer>
          ) : null}
          <TableContainer>
            <Table
              head={table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, i) => (
                    <TableHeader
                      key={header.id}
                      isFixed={i === 0}
                      width={header.getSize()}
                      sortDirection={header.column.getIsSorted()}
                      onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                      onResize={header.getResizeHandler()}
                    >
                      <div className="flex items-center gap-1">
                        {header.column.getCanSort() ? (
                          <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                        ) : null}
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </TableHeader>
                  ))}
                </TableHeaderRow>
              ))}
              body={table.getRowModel().rows.map((row, rowIndex) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell isFixed={cell.column.id === 'firstName'} key={cell.id} rowIndex={rowIndex}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            />
          </TableContainer>
          <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
        </TableContainer>
      </div>
    </>
  );
};
