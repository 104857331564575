import {User} from '@shipwell/backend-core-sdk';
import {LoadboardUser} from '@shipwell/tabula-sdk';

type TooltipContentProps = {
  permissions: string[];
};

export type Original = {
  id: string;
  company_id: string;
  last_login: string;
  timezone: string;
  first_name: string;
  last_name: string;
  email: string;
  truckstop: {
    is_active: boolean;
    created_at: string;
  };
};

export type UserData = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export const EmptyState = () => (
  <div className="flex flex-col">
    <span className="text-lg font-bold">No Users</span>
    <span className="text-grey-4 text-sm">Enable the integration to begin adding users</span>
  </div>
);

export const TooltipContent = ({permissions}: TooltipContentProps) => {
  return (
    <div>
      <p className="mb-2 text-sm font-bold">Permissions</p>
      {permissions.map((permission, index) => (
        <p key={index} className="text-capitalize mb-0.5 text-sm">
          {permission}
        </p>
      ))}
    </div>
  );
};

export const mergeUsersInfo = (users: User[], truckstopUsers: LoadboardUser[]) => {
  const mergedUsers = (users || []).map((user) => {
    const truckstopUser = truckstopUsers?.find((truckstopUser) => user?.id === truckstopUser?.sw_user_id);

    if (truckstopUser) {
      return {
        ...user,
        truckstop: truckstopUser
      };
    }

    return user;
  });

  return mergedUsers;
};
