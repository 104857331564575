import _ from 'lodash';
import moment from 'moment';
import {checkIsHoliday} from 'App/utils/globals';

const warn = (values) => {
  const warnings = {};
  if (values.stops) {
    if (values.stops[0] && values.stops[0].planned_date) {
      //show a warning if the date is a holiday and LTL is selected
      if (values.mode && values.mode.ltl === true) {
        if (checkIsHoliday(moment(values.stops[0].planned_date, 'MM/DD/YYYY').toDate())) {
          warnings.stops = [];
          warnings.stops[0] = {};
          warnings.stops[0].planned_date =
            'This pickup is scheduled for a national holiday. Please confirm carrier availability before booking.';
        }
      }
    }
  }

  return warnings;
};

export default warn;
