import {Field, reduxForm, Form} from 'redux-form';
import {connect} from 'react-redux';
import {Button, FormControl, ControlLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderDateTimeField from 'App/formComponents/renderDateTimeField';
import {addressBookGet} from 'App/actions/addressBook';

let CasesForm = (props) => {
  const {handleSubmit, submitting, error, shipment, companyUsers, cancel} = props;
  const showCompanyUsers = [];
  if (companyUsers && companyUsers.results) {
    for (let i = 0; i < companyUsers.results.length; i++) {
      showCompanyUsers.push({
        name: companyUsers.results[i].first_name + ' ' + companyUsers.results[i].last_name,
        id: companyUsers.results[i].id
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="formstyle-dark cases__form">
      <Field req label="Title/Description" name="title" component={renderField} />
      {/*<Field req={true} label="Description" name="description" component={renderField} />*/}
      <Field
        name="assignee"
        labelKey="display_name"
        component={renderDropDownSelector}
        label="Assigned To"
        req
        data={showCompanyUsers}
        placeholder="Select a user"
      />
      {error && (
        <p className="error-text-form-level">
          <i className="icon icon-Delayed pad-right" />
          {error}
        </p>
      )}

      <div className="text-right">
        <Button bsStyle="default" disabled={submitting} onClick={cancel}>
          Cancel
        </Button>
        <Button bsStyle="primary" type="submit" disabled={submitting}>
          {submitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Enter a title';
  }

  if (!values.assignee) {
    errors.assignee = 'Assign a user';
  }

  return errors;
};

CasesForm = reduxForm({
  form: 'CasesForm',
  validate: validate,
  enableReinitialize: true
})(CasesForm);

CasesForm = connect((state) => ({
  initialValues: JSON.parse(JSON.stringify(state.users.selectedCase))
}))(CasesForm);

export default CasesForm;
