import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {UpdateInvoiceRuleConfigurationStatus, ConfigDetails} from '@shipwell/settlements-sdk';
import {
  getInvoiceRuleConfigurations,
  updateAmountToleranceLimitInvoiceRuleConfiguration,
  updateInvoiceRuleConfigurationsActivationStatus
} from 'App/api/settlements';
import {INVOICE_RULE_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export default function useInvoiceRuleActions() {
  const queryClient = useQueryClient();

  const updateAmountToleranceLimitInvoiceRuleConfigurationMutation = useMutation<
    Awaited<ReturnType<typeof updateAmountToleranceLimitInvoiceRuleConfiguration>>,
    unknown,
    {ruleId: string; details: ConfigDetails}
  >(({ruleId, details}) => updateAmountToleranceLimitInvoiceRuleConfiguration(ruleId, details), {
    onSettled: () => queryClient.invalidateQueries([INVOICE_RULE_CONFIGURATIONS_QUERY_KEY])
  });

  const updateInvoiceConfigurationStatusesMutation = useMutation<
    Awaited<ReturnType<typeof updateInvoiceRuleConfigurationsActivationStatus>>,
    unknown,
    {statuses: UpdateInvoiceRuleConfigurationStatus[]}
  >(
    ({statuses}: {statuses: UpdateInvoiceRuleConfigurationStatus[]}) =>
      updateInvoiceRuleConfigurationsActivationStatus(statuses),
    {
      onSettled: () => queryClient.invalidateQueries([INVOICE_RULE_CONFIGURATIONS_QUERY_KEY])
    }
  );

  return {
    updateAmountToleranceLimitInvoiceRuleConfigurationMutation,
    updateInvoiceConfigurationStatusesMutation
  };
}

export const useInvoiceRuleConfigurationsQuery = () => {
  return useQuery(
    [INVOICE_RULE_CONFIGURATIONS_QUERY_KEY],
    async () => {
      const response = await getInvoiceRuleConfigurations();
      return response.data;
    },
    {
      staleTime: STATIC_DATA_STALE_TIME
    }
  );
};
