import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ExternalDocumentsListResponse} from '@shipwell/corrogo-sdk';
import {
  DocumentType,
  UploadDocumentVariablesType,
  UpdateDocumentVariablesType,
  DeleteDocumentVariablesType,
  mergeUploadDocument,
  mergeUpdateDocument,
  mergeDeleteDocument
} from 'App/api/corrogo/utils/mergeDataFunctions';
import {deleteShipmentDocument, uploadShipmentDocument, updateShipmentDocument} from 'App/api/corrogo/typed';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

type ErrorHandlerType = (error: unknown) => void;
type VariablesType = DeleteDocumentVariablesType | UploadDocumentVariablesType | UpdateDocumentVariablesType;
type MergeDataType = (shipmentDocuments?: DocumentType[], variables?: VariablesType) => DocumentType[];

export default function useShipmentDocuments(shipmentId: string) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DocumentType[], Error, VariablesType>();

  const createShipmentDocumentsOptimisticUpdateHandlers = ({
    errorHandler,
    mergeData
  }: {
    errorHandler: ErrorHandlerType;
    mergeData: MergeDataType;
  }) => createOptimisticUpdateHandlers([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId], {errorHandler, mergeData});

  const deleteShipmentDocumentMutation = useMutation<
    Awaited<ReturnType<typeof deleteShipmentDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    DeleteDocumentVariablesType & {shipmentId: string}
  >(
    ({shipmentId, documentId}) => deleteShipmentDocument(shipmentId, documentId),
    createShipmentDocumentsOptimisticUpdateHandlers({
      errorHandler: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error deleting a shipment document', message);
      },
      mergeData: mergeDeleteDocument
    })
  );

  const uploadShipmentDocumentMutation = useMutation<
    Awaited<ReturnType<typeof uploadShipmentDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    UploadDocumentVariablesType & {shipmentId: string}
  >(
    ({shipmentId, file, description, type}) => uploadShipmentDocument(shipmentId, file, description, type),
    createShipmentDocumentsOptimisticUpdateHandlers({
      errorHandler: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error creating a shipment document', message);
      },
      mergeData: mergeUploadDocument
    })
  );

  const updateShipmentDocumentMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    UpdateDocumentVariablesType & {shipmentId: string}
  >(
    ({shipmentId, documentId, description, type}) => updateShipmentDocument(shipmentId, documentId, description, type),
    createShipmentDocumentsOptimisticUpdateHandlers({
      errorHandler: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error creating a shipment document', message);
      },
      mergeData: mergeUpdateDocument
    })
  );

  return {
    deleteShipmentDocumentMutation,
    uploadShipmentDocumentMutation,
    updateShipmentDocumentMutation,

    isLoading:
      deleteShipmentDocumentMutation.isLoading ||
      uploadShipmentDocumentMutation.isLoading ||
      updateShipmentDocumentMutation.isLoading
  };
}
