import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';

/**
 * Purchase Orders - Build Shipment
 * API for managing purchase orders
 */

export async function createPurchaseOrdersShipment(options = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersBuildShipmentPost(options, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function createPurchaseOrder(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function updatePurchaseOrderById(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdPut(id, body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Purchase Orders
 * API for managing purchase orders
 */

export async function getPurchaseOrdersList(params = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersGet(params, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function createPurchaseOrders(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function getPurchaseOrderById(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdGet(id, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Purchase Orders Line Items
 * API for managing purchase order line items
 */

/**
 * GET / Request purchase order line items
 * @param  {String} id   Purchase order id
 * @return {Promise}
 */
export async function getPurchaseOrderLineItemList(id, options = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsGet(id, options, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * POST / Create purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function createPurchaseOrderLineItem(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPost(id, body, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * PUT / Update purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function updatePurchaseOrderLineItem(orderId, lineItemId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdPut(
      orderId,
      lineItemId,
      body,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * DELETE / Delete purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function deletePurchaseOrderLineItem(orderId, lineItemId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDelete(
      orderId,
      lineItemId,
      (err, data, response) => {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}
