import {Field, useFormikContext} from 'formik';
import {FormikTextInput, FormikCheckbox, Title, FormikSelect, DisplayValue} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';
import useRateCalculationsFromValues from 'App/containers/shipments/components/TenderFields/useRateCalculationsFromValues';
import type {TenderFormValues} from 'App/containers/shipments/components/TenderFields/validation';

const RateSummary = () => {
  const {singleContainerRate, totalContainerRate} = useRateCalculationsFromValues();
  return (
    <div className="flex flex-col gap-2">
      <DisplayValue label="Single Container Rate">{formatCurrency(singleContainerRate)}</DisplayValue>
      <DisplayValue label="Total Container Rate">{formatCurrency(totalContainerRate)}</DisplayValue>
    </div>
  );
};

const RateDetails = () => {
  const {values} = useFormikContext<TenderFormValues>();

  return (
    <>
      <Title variant="sectionTitle">Rate Details (Per Container)</Title>
      <div className="flex w-full gap-2">
        <Field
          name="lineHaul"
          label="Line Haul (Per Container)"
          className="grow"
          component={FormikTextInput}
          required
        />
        <div className="flex justify-end gap-0">
          <Field
            name="fuelSurcharge"
            label="Fuel Surcharge"
            className="grow"
            component={FormikTextInput}
            disabled={values.fuelIncludedInLineHaul}
          />
          <Field
            name="fuelSurchargeUnit"
            component={FormikSelect}
            options={[{label: '%', value: '%'}]}
            simpleValue
            clearable={false}
            disabled={values.fuelIncludedInLineHaul}
          />
        </div>
      </div>
      <div className="flex items-start justify-between">
        <Field name="fuelIncludedInLineHaul" label="Fuel Included in Line Haul" component={FormikCheckbox} />
        <RateSummary />
      </div>
    </>
  );
};

export default RateDetails;
