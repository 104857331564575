/* eslint-disable camelcase */
import axios from 'axios';
import {getShipwellApi, basePath} from 'App/api/config';
import {apiCallback, getAccessToken} from 'App/api/utils';

/**
 * Product payload - creation
 */
export const buildProductCreationPayload = (body) => {
  const image = body.image;
  const description = body.description;
  const product_ref = body.product_ref;
  const value_per_piece = body.value_per_piece;
  const freight_class = body.freight_class;
  const hazmat_hazard_class = body.hazmat_hazard_class;
  const hazmat_identification_number = body.hazmat_identification_number;
  const hazmat_packing_group = body.hazmat_packing_group;
  const hazmat_proper_shipping_name = body.hazmat_proper_shipping_name;
  const height = body.height;
  const length = body.length;
  const length_unit = body.length_unit;
  const nmfc_item_code = body.nmfc_item_code;
  const nmfc_sub_code = body.nmfc_sub_code;
  const package_type = body.package_type;
  const piece_type = body.piece_type;
  const stackable = body.stackable;
  const package_weight = body.package_weight;
  const weight_unit = body.weight_unit;
  const width = body.width;
  const refrigeration_required = body.refrigeration_required;
  const refrigeration_min_temp = body.refrigeration_min_temp;
  const refrigeration_max_temp = body.refrigeration_max_temp;

  const formData = new FormData();
  if (image) {
    formData.append('image', image, product_ref + '.png');
  }
  if (description) {
    formData.append('description', description);
  }
  if (product_ref) {
    formData.append('product_ref', product_ref);
  }
  if (value_per_piece) {
    formData.append('value_per_piece', value_per_piece);
  }
  if (freight_class) {
    formData.append('freight_class', freight_class);
  }
  if (hazmat_hazard_class) {
    formData.append('hazmat_hazard_class', hazmat_hazard_class);
  }
  if (hazmat_identification_number) {
    formData.append('hazmat_identification_number', hazmat_identification_number);
  }
  if (hazmat_packing_group) {
    formData.append('hazmat_packing_group', hazmat_packing_group);
  }
  if (hazmat_proper_shipping_name) {
    formData.append('hazmat_proper_shipping_name', hazmat_proper_shipping_name);
  }
  if (height) {
    formData.append('height', height);
  }
  if (length) {
    formData.append('length', length);
  }
  if (length_unit) {
    formData.append('length_unit', length_unit);
  }
  if (nmfc_item_code) {
    formData.append('nmfc_item_code', nmfc_item_code);
  }
  if (nmfc_sub_code) {
    formData.append('nmfc_sub_code', nmfc_sub_code);
  }
  if (package_type) {
    formData.append('package_type', package_type);
  }
  if (piece_type) {
    formData.append('piece_type', piece_type);
  }
  if (stackable) {
    formData.append('stackable', stackable);
  }
  if (package_weight) {
    formData.append('package_weight', package_weight);
  }
  if (weight_unit) {
    formData.append('weight_unit', weight_unit);
  }
  if (width) {
    formData.append('width', width);
  }
  if (refrigeration_required) {
    formData.append('refrigeration_required', refrigeration_required);
  }
  if (refrigeration_min_temp) {
    formData.append('refrigeration_min_temp', refrigeration_min_temp);
  }
  if (refrigeration_max_temp) {
    formData.append('refrigeration_max_temp', refrigeration_max_temp);
  }
  if (!refrigeration_required) {
    formData.append('refrigeration_required', false);
    formData.append('refrigeration_min_temp', '');
    formData.append('refrigeration_max_temp', '');
  }
  return formData;
};

/**
 * Product payload - update
 */
export const buildProductUpdatePayload = (body) => {
  const image = body.image;
  const description = body.description;
  const product_ref = body.product_ref;
  const value_per_piece = body.value_per_piece;
  const value_per_piece_currency = body.value_per_piece_currency;
  const freight_class = body.freight_class;
  const hazmat_hazard_class = body.hazmat_hazard_class;
  const hazmat_identification_number = body.hazmat_identification_number;
  const hazmat_packing_group = body.hazmat_packing_group;
  const hazmat_proper_shipping_name = body.hazmat_proper_shipping_name;
  const height = body.height;
  const length = body.length;
  const length_unit = body.length_unit;
  const nmfc_item_code = body.nmfc_item_code;
  const nmfc_sub_code = body.nmfc_sub_code;
  const package_type = body.package_type;
  const piece_type = body.piece_type;
  const stackable = body.stackable;
  const package_weight = body.package_weight;
  const weight_unit = body.weight_unit;
  const width = body.width;
  const external_can_quote = body.external_can_quote;
  const external_pickup_address = body.external_pickup_address;
  const max_pieces_per_package = body.max_pieces_per_package;
  const refrigeration_required = body.refrigeration_required;
  const refrigeration_min_temp = body.refrigeration_min_temp;
  const refrigeration_max_temp = body.refrigeration_max_temp;
  const temp_unit = body.temp_unit;
  const country_of_manufacture = body.country_of_manufacture;
  const product_category = body.product_category;
  const formData = new FormData();

  if (image && typeof image !== 'string') {
    formData.append('image', image, product_ref + '.png');
  } else if (!image) {
    formData.append('image', '');
  }
  if (product_ref) {
    formData.append('product_ref', product_ref);
  }
  if (description) {
    formData.append('description', description);
  }
  formData.append('piece_type', piece_type || '');
  formData.append('value_per_piece', value_per_piece || '');
  formData.append('value_per_piece_currency', value_per_piece_currency || 'USD');
  formData.append('package_type', package_type || '');
  formData.append('package_weight', package_weight || '');
  if (weight_unit) {
    formData.append('weight_unit', weight_unit);
  }
  formData.append('length', length || '');
  formData.append('width', width || '');
  formData.append('height', height || '');
  if (length_unit) {
    formData.append('length_unit', length_unit);
  }
  formData.append('freight_class', freight_class || '');
  formData.append('nmfc_item_code', nmfc_item_code || '');
  formData.append('nmfc_sub_code', nmfc_sub_code || '');
  if (stackable) {
    formData.append('stackable', stackable);
  }
  if (hazmat_identification_number) {
    formData.append('hazmat_identification_number', hazmat_identification_number);
  }
  if (hazmat_hazard_class) {
    formData.append('hazmat_hazard_class', hazmat_hazard_class);
  }
  if (hazmat_packing_group) {
    formData.append('hazmat_packing_group', hazmat_packing_group);
  }
  if (hazmat_proper_shipping_name) {
    formData.append('hazmat_proper_shipping_name', hazmat_proper_shipping_name);
  }
  if (hazmat_identification_number === null) {
    formData.append('hazmat_hazard_class', '');
    formData.append('hazmat_identification_number', '');
    formData.append('hazmat_packing_group', '');
    formData.append('hazmat_proper_shipping_name', '');
  }
  if (refrigeration_required) {
    formData.append('refrigeration_required', refrigeration_required);
  }
  if (refrigeration_min_temp) {
    formData.append('refrigeration_min_temp', refrigeration_min_temp);
  }
  if (refrigeration_max_temp) {
    formData.append('refrigeration_max_temp', refrigeration_max_temp);
  }
  if (temp_unit) {
    formData.append('temp_unit', temp_unit);
  }
  if (!refrigeration_required) {
    formData.append('refrigeration_required', false);
    formData.append('refrigeration_min_temp', '');
    formData.append('refrigeration_max_temp', '');
  }
  if (external_can_quote) {
    formData.append('external_can_quote', external_can_quote);
  }
  if (max_pieces_per_package) {
    formData.append('max_pieces_per_package', max_pieces_per_package);
  }
  if (external_pickup_address) {
    formData.append('external_pickup_address', external_pickup_address);
  } else {
    formData.append('external_pickup_address', '');
  }
  formData.append('country_of_manufacture', country_of_manufacture || '');
  formData.append('product_category', product_category || '');
  return formData;
};

/**
 * Products - api
 */
export async function getProductsPromise(opts = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.productsGet(opts, apiCallback(resolve, reject));
  });
}

export async function createProductPromise(body) {
  const accessToken = await getAccessToken();
  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [(body) => buildProductCreationPayload(body)],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };

    const request = axios.post(basePath + '/products/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

export async function getProduct(productId) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.productsProductIdGet(productId, apiCallback(resolve, reject));
  });
}

export async function editProductPromise(productId, body) {
  const accessToken = await getAccessToken();
  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [(body) => buildProductUpdatePayload(body)],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };

    const request = axios.put(basePath + '/products/' + productId + '/', body, config);

    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response.data});
      });
  });
}

export async function deleteProductPromise(id) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.productsProductIdDelete(id, apiCallback(resolve, reject));
  });
}
