import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {OrderStop} from '@shipwell/corrogo-sdk';
import {useUpdatePurchaseOrderShipFrom, useUpdatePurchaseOrderShipTo} from 'App/data-hooks';
import {InitialValuesOrder} from 'App/containers/orders/views/AddOrdersToShipmentListView/components/UpdateOrderDates/initialValues';

interface UpdateOrderStopParams {
  orderId: string;
  orderStop: OrderStop;
}

const updateOrderStops = async (
  orders: InitialValuesOrder[],
  updatePurchaseOrderShipFromAsync: (params: UpdateOrderStopParams) => Promise<OrderStop>,
  updatePurchaseOrderShipToAsync: (params: UpdateOrderStopParams) => Promise<OrderStop>
): Promise<OrderStop[][]> => {
  const promises = orders.map((order) => {
    const updatePromises: Promise<OrderStop>[] = [];

    if (order.ship_from) {
      updatePromises.push(updatePurchaseOrderShipFromAsync({orderId: order.orderId, orderStop: order.ship_from}));
    }

    if (order.ship_to) {
      updatePromises.push(updatePurchaseOrderShipToAsync({orderId: order.orderId, orderStop: order.ship_to}));
    }

    return Promise.all(updatePromises);
  });

  return Promise.all(promises);
};

export const useUpdatesMultipleOrderStops = () => {
  const {updatePurchaseOrderShipFromAsync} = useUpdatePurchaseOrderShipFrom();
  const {updatePurchaseOrderShipToAsync} = useUpdatePurchaseOrderShipTo();

  const mutation: UseMutationResult<OrderStop[][], unknown, {orders: InitialValuesOrder[]}, unknown> = useMutation(
    async ({orders}: {orders: InitialValuesOrder[]}) => {
      return updateOrderStops(orders, updatePurchaseOrderShipFromAsync, updatePurchaseOrderShipToAsync);
    }
  );

  return {
    UpdateOrdersDates: mutation.mutateAsync,
    isLoadingOrdersDates: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess
  };
};
