import {ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {createSpotNegotiationQuoteDocument} from '../typed';

type CreateSpotNegotiationQuoteDocumentPayload = {
  spotNegotiationId: string;
  spotNegotiationQuoteId: string;
  description: string;
  file: unknown;
  isCarrierDocument?: boolean;
  type: string;
};

const useCreateSpotNegotiationQuoteDocument = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createSpotNegotiationQuoteDocument>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationQuoteDocumentPayload
  >
) => {
  return useMutation<
    Awaited<ReturnType<typeof createSpotNegotiationQuoteDocument>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationQuoteDocumentPayload
  >(
    ({spotNegotiationId, spotNegotiationQuoteId, description, file, isCarrierDocument = true, type}) =>
      createSpotNegotiationQuoteDocument({
        spotNegotiationId,
        spotNegotiationQuoteId,
        description,
        file,
        isCarrierDocument,
        type
      }),
    mutationOptions
  );
};

export default useCreateSpotNegotiationQuoteDocument;
