import invert from 'lodash/invert';
import {
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  DrayageBookingAccessorials,
  Shipment,
  DrayageBooking
} from '@shipwell/corrogo-sdk';
import {object, string, array, number} from 'yup';

export const portTypes = {OCEAN: 'OCEAN', RAIL: 'INLAND'};
export const portTypeValues = {OCEAN: 'ocean port', RAIL: 'inland port'};
export const shipmentDirections = {IMPORT: 'IMPORT', EXPORT: 'EXPORT'};
export const weightUnits = {POUNDS: 'LB', KILOGRAMS: 'KG'};
export const weightLimits = {[weightUnits.POUNDS]: 45000, [weightUnits.KILOGRAMS]: 20411.657};

export const contacts = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  AFTER_HOURS_CONTACT: 'AFTER_HOURS_CONTACT',
  APPOINTMENT_SCHEDULER: 'APPOINTMENT_SCHEDULER',
  AGENT: 'AGENT',
  BROKER: 'BROKER',
  OTHER: 'OTHER',
  FORWARDER_CONTACT: 'FORWARDER_CONTACT',
  RECEIVING_CONTACT: 'RECEIVING_CONTACT',
  SALES_REP: 'SALES_REP',
  SHIPPER_CONTACT: 'SHIPPER_CONTACT',
  ULTIMATE_RECEIVER: 'ULTIMATE_RECEIVER'
};

export const stopReferencesQualifier = {
  PICKUP_NUMBER: 'PICKUP_NUMBER'
};

export const referenceTypes = {
  BOL_NUMBER: 'BOL_NUMBER',
  HOUSE_BOL_NUMBER: 'HOUSE_BOL_NUMBER',
  CUSTOMER_REFERENCE_NUMBER: 'CUSTOMER_REFERENCE_NUMBER',
  PO_NUMBER: 'PO_NUMBER',
  BOOKING_NUMBER: 'BOOKING_NUMBER',
  SCAC: 'SCAC'
};

export const trackingServiceTypeToPortType = {
  [OceanTrackingServiceTypeEnum.OceanTracking]: portTypeValues.OCEAN,
  [RailTrackingServiceTypeEnum.RailTracking]: portTypeValues.RAIL
};

export const accessorialsCodesMap = {
  CROSS: DrayageBookingAccessorials.Cross,
  FRIDGE: DrayageBookingAccessorials.Fridge,
  HAZM: DrayageBookingAccessorials.Hazmat,
  LYOVR: DrayageBookingAccessorials.Layover,
  OVDIM: DrayageBookingAccessorials.Overdim,
  OVERWGHT: DrayageBookingAccessorials.Overweight,
  PALLET: DrayageBookingAccessorials.Pallet,
  STORAGE: DrayageBookingAccessorials.Storage,
  SCALE: DrayageBookingAccessorials.Scale,
  TRANSLOAD: DrayageBookingAccessorials.Transload,
  WAREHOUSE: DrayageBookingAccessorials.Warehouse
};

export const accessorialsEnumMap = invert(accessorialsCodesMap);

export const drayageStopValidationSchema = object({
  company_name: string().nullable(),
  address_1: string().nullable(),
  address_2: string().nullable(),
  city: string().nullable(),
  country: string().nullable(),
  formatted_address: string().nullable(),
  latitude: number().nullable(),
  longitude: number().nullable(),
  phone_number: string().nullable(),
  state_province: string().nullable(),
  timezone: string().nullable(),
  point_of_contacts: array()
    .nullable()
    .of(
      object().shape({
        first_name: string().nullable(),
        last_name: string().nullable(),
        phone_number: string().nullable(),
        email: string().nullable().email('Enter a valid email')
      })
    ),
  instructions: string().nullable()
});

export const getReferenceValue = (referenceType: string, entity?: Shipment | DrayageBooking) => {
  return entity?.references?.find((reference) => reference.qualifier === referenceType)?.value || '';
};

export const transformReferencesToDisplayValues = (entity?: Shipment | DrayageBooking) => {
  const customerReferenceNumber = getReferenceValue(referenceTypes.CUSTOMER_REFERENCE_NUMBER, entity) || '--';
  const poNumber = getReferenceValue(referenceTypes.PO_NUMBER, entity) || '--';
  const bookingNumber = getReferenceValue(referenceTypes.BOOKING_NUMBER, entity) || '--';
  const bolNumber = getReferenceValue(referenceTypes.BOL_NUMBER, entity) || '--';
  const houseBolNumber = getReferenceValue(referenceTypes.HOUSE_BOL_NUMBER, entity) || '--';

  return {customerReferenceNumber, poNumber, bookingNumber, bolNumber, houseBolNumber};
};
