import {AllowedColumnNamesEnum} from './constants';

export const getFilteredColumnNames = (columnNames: Array<string>) => {
  // 'stops' represents 2 column names in 1
  const output = columnNames.includes('stops') ? ['number_of_pickups', 'number_of_deliveries'] : [];
  for (const columnName of columnNames) {
    const index = Object.keys(AllowedColumnNamesEnum).indexOf(columnName);
    if (index !== -1) output.push(Object.values(AllowedColumnNamesEnum)[index]);
  }
  return output;
};
