import _ from 'lodash';
import {validateEmail, validatePhoneNumber, isBillToFormEmpty} from 'App/utils/globals';

const validate = (values = {}) => {
  let errors = {};
  const {bill_to_override} = values;
  const isEmptyForm = isBillToFormEmpty(bill_to_override);

  if (bill_to_override && !isEmptyForm && Object.keys(bill_to_override).length > 0) {
    errors = {bill_to_override: {}};
    if (
      !bill_to_override.company_address ||
      (bill_to_override.company_address && Object.keys(bill_to_override.company_address).length < 1)
    ) {
      errors.bill_to_override.company_address = 'Address is required';
    }
    if (!values.bill_to_override.company_name) {
      errors.bill_to_override.company_name = 'Company name is required';
    }
    if (!values.bill_to_override.contact_email) {
      errors.bill_to_override.contact_email = 'Email is required';
    }
    if (values.bill_to_override.contact_email && !validateEmail(values.bill_to_override.contact_email)) {
      errors.bill_to_override.contact_email = 'Invalid email';
    }
    if (!values.bill_to_override.contact_phone) {
      errors.bill_to_override.contact_phone = 'Phone is required';
    }
    if (values.bill_to_override.contact_phone && !validatePhoneNumber(values.bill_to_override.contact_phone)) {
      errors.bill_to_override.contact_phone = 'Invalid phone number';
    }
    if (!values.bill_to_override.direction || values.bill_to_override.direction === 'select') {
      errors.bill_to_override.direction = 'Select an option';
    }
  }
  return errors;
};

export default validate;
