/* eslint-disable max-len */
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Formik, Field} from 'formik';
import {Card, Toast, FormikSelect, DeprecatedButton} from '@shipwell/shipwell-ui';
import {
  getQuickbooksBillingExpenseCategoriesMapping,
  createQuickbooksBillingExpenseCategoriesMapping,
  updateQuickbooksBillingExpenseCategoriesMapping,
  deleteQuickbooksBillingExpenseCategoriesMapping
} from 'App/api/billing';
import {fetchChargeCategoriesPromise} from 'App/api/shipment';
import {
  createQuickbooksItems,
  getQuickbooksItemCategoryMapping,
  createQuickbooksItemCategoryMapping,
  updateQuickbooksItemCategoryMapping,
  deleteQuickbooksItemCategoryMapping
} from 'App/api/invoicing';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {
  fetchExpenseAccounts,
  fetchExpenseClasses,
  populateExpenseAccounts,
  populateExpenseClasses
} from 'App/actions/_integrations';
import './styles.scss';
import {useGetQuickbooksItems} from 'App/data-hooks';

const getMappingsByCategory = (mappings) => {
  return mappings.reduce((values, mapping) => ({...values, [mapping.category]: mapping}), {});
};

/**
 * Quickbooks Mapping Fields for Billing and Invoicing
 * @todo Separate billing and invoicing forms into own components
 */
const QuickbooksMappingForm = (props) => {
  const {company, expenseAccounts, expenseClasses, dispatch} = props;
  const [chargeLineItemCategories, setChargeLineItemCategories] = useState([]);
  const {paginatedQuickbooksItems, isLoadingQuickbooksItems} = useGetQuickbooksItems();
  const [invoiceFormValues, setInvoiceFormValues] = useState({});
  const [billingFormValues, setBillingFormValues] = useState({});
  const [billingFormSubmitSuccess, setBillingFormSubmitSuccess] = useState(false);
  const [billingFormSubmitError, setBillingFormSubmitError] = useState(null);
  const [invoicingFormSubmitSuccess, setInvoicingFormSubmitSuccess] = useState(false);
  const [invoicingFormSubmitError, setInvoicingFormSubmitError] = useState(null);
  /**
   * Get Charge Categories
   */
  const getChargeCategories = async () => {
    try {
      const response = await fetchChargeCategoriesPromise();

      if (response && response.body) {
        setChargeLineItemCategories(response.body);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Get Quickbooks Billing Expense Category Mappings
   */
  const getQuickbooksBillingExpenseCategoriesMappingRequest = async () => {
    try {
      const {results} = await getQuickbooksBillingExpenseCategoriesMapping({page: 1, pageSize: 200});

      if (results) {
        setBillingFormValues(getMappingsByCategory(results));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Get Quickbooks Invoice Item Category Mappings
   */
  const getQuickbooksInvoiceItemCategoriesMapping = async () => {
    try {
      const response = await getQuickbooksItemCategoryMapping();

      if (response && response.body) {
        setInvoiceFormValues(getMappingsByCategory(response.body.results));
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Submit Billing Account Mappings
   * @param {Object} values
   * @param {Object} formikActions
   */
  const handleBillingMappingSubmit = async (values, {setSubmitting}) => {
    try {
      const categories = Object.keys(values);

      setSubmitting(true);
      setBillingFormSubmitSuccess(false);
      setBillingFormSubmitError(null);

      const response = await Promise.all(
        categories.map((category) => {
          const payload = {
            category: category,
            expense_account: values[category].expense_account,
            expense_class: values[category].expense_class
          };
          const hasMapping = payload.expense_account && payload.expense_class;

          if (values[category].id && hasMapping) {
            return updateQuickbooksBillingExpenseCategoriesMapping(values[category].id, payload);
          }
          if (hasMapping) {
            return createQuickbooksBillingExpenseCategoriesMapping(payload);
          }
          return deleteQuickbooksBillingExpenseCategoriesMapping(values[category].id);
        })
      );

      getQuickbooksBillingExpenseCategoriesMappingRequest();
      setSubmitting(false);
      setBillingFormSubmitSuccess(true);

      return response;
    } catch (error) {
      if (error && error.error_description) {
        setBillingFormSubmitSuccess(false);
        setBillingFormSubmitError(error.error_description);
      }
      setSubmitting(false);
    }
  };

  /**
   * Submit Invoice Account Mappings
   * @param {Object} values
   * @param {Object} formikActions
   */
  const handleInvoiceMappingSubmit = async (values, {setSubmitting}) => {
    try {
      const categories = Object.keys(values);

      setSubmitting(true);
      setInvoicingFormSubmitSuccess(false);
      setInvoicingFormSubmitError(null);

      const response = await Promise.all(
        categories.map((category) => {
          const payload = {
            category: category,
            quickbooks_item: values[category].quickbooks_item
          };

          if (values[category].id && values[category].quickbooks_item) {
            return updateQuickbooksItemCategoryMapping(values[category].id, payload);
          }
          if (values[category].quickbooks_item) {
            return createQuickbooksItemCategoryMapping(payload);
          }
          if (values[category].id) {
            return deleteQuickbooksItemCategoryMapping(values[category].id);
          }
        })
      );

      getQuickbooksInvoiceItemCategoriesMapping();
      setSubmitting(false);
      setInvoicingFormSubmitSuccess(true);

      return response;
    } catch (error) {
      if (error && error.error_description) {
        setInvoicingFormSubmitSuccess(false);
        setInvoicingFormSubmitError(error.error_description);
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (company.id) {
      createQuickbooksItems();

      // post billing expense accounts and classes
      dispatch(populateExpenseAccounts());
      dispatch(populateExpenseClasses());

      // get categories
      getChargeCategories();

      // get current billing expense categories mapping
      getQuickbooksBillingExpenseCategoriesMappingRequest();
      getQuickbooksInvoiceItemCategoriesMapping();

      // get billing expense account and classes for mapping
      dispatch(fetchExpenseClasses());
      dispatch(fetchExpenseAccounts({accountType: 'expense', pageSize: 1000}));
    }
  }, [company, dispatch]);

  if (
    (!chargeLineItemCategories.length &&
      !Object.keys(invoiceFormValues).length &&
      !Object.keys(billingFormValues).length) ||
    isLoadingQuickbooksItems
  ) {
    return <ShipwellLoader loading />;
  }

  return (
    <div className="integrations-quickbook-mappings grid-1-2">
      <Card title="Billing">
        <Formik
          enableReinitialize
          initialValues={billingFormValues}
          validate={(values) => {
            const errors = {};
            for (const field in values) {
              const fieldError = {};
              if (values[field] && !values[field].expense_account && values[field].expense_class) {
                fieldError.expense_account = 'Expense account is required';
              }
              if (values[field] && !values[field].expense_class && values[field].expense_account) {
                fieldError.expense_class = 'Expense class is required';
              }
              if (Object.keys(fieldError).length) {
                errors[field] = fieldError;
              }
            }
            return errors;
          }}
          onSubmit={handleBillingMappingSubmit.bind(this)}
        >
          {({handleSubmit, isSubmitting, submitCount}) => (
            <form className="billing-mapping-form" noValidate="novalidate" onSubmit={handleSubmit}>
              {chargeLineItemCategories.map((category, index) => (
                <div className="field-grid grid-1-3" key={index}>
                  <strong className="mapping-category">{category.name}</strong>
                  <Field
                    name={`${category.id}.expense_account`}
                    label="Expense Account"
                    component={FormikSelect}
                    getOptionLabel={({name}) => name}
                    options={expenseAccounts}
                  />
                  <Field
                    name={`${category.id}.expense_class`}
                    label="Expense Class"
                    component={FormikSelect}
                    getOptionLabel={({name}) => name}
                    options={expenseClasses}
                  />
                </div>
              ))}
              <div className="mappings-footer">
                {submitCount > 0 && <span className="error-text">{billingFormSubmitError}</span>}
                <DeprecatedButton
                  type="submit"
                  disabled={isSubmitting}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                >
                  Save
                </DeprecatedButton>
              </div>
            </form>
          )}
        </Formik>
      </Card>
      <Card title="Invoicing">
        <Formik enableReinitialize initialValues={invoiceFormValues} onSubmit={handleInvoiceMappingSubmit.bind(this)}>
          {({handleSubmit, isSubmitting, submitCount}) => (
            <form className="invoice-mapping-form" noValidate="novalidate" onSubmit={handleSubmit}>
              {chargeLineItemCategories.map((category, index) => (
                <div className="field-grid" key={index}>
                  <strong>{category.name}</strong>
                  <Field
                    name={`${category.id}.quickbooks_item`}
                    label="Product & Services"
                    getOptionLabel={({name}) => name}
                    options={paginatedQuickbooksItems?.results || []}
                    component={FormikSelect}
                  />
                </div>
              ))}
              <div className="mappings-footer">
                {submitCount > 0 && <span className="error-text">{invoicingFormSubmitError}</span>}
                <DeprecatedButton
                  type="submit"
                  disabled={isSubmitting}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                >
                  Save
                </DeprecatedButton>
              </div>
            </form>
          )}
        </Formik>
      </Card>

      <Toast
        show={Boolean(billingFormSubmitSuccess)}
        title="Success"
        variant="success"
        anchor="bottom-right"
        onClose={() => setBillingFormSubmitSuccess(false)}
      >
        Billing categories mapped!
      </Toast>
      <Toast
        show={Boolean(invoicingFormSubmitSuccess)}
        title="Success"
        variant="success"
        anchor="bottom-right"
        onClose={() => setInvoicingFormSubmitSuccess(false)}
      >
        Invoicing categories mapped!
      </Toast>
    </div>
  );
};

export default connect((state) => ({
  company: state.company.company,
  expenseAccounts: (state.integrations.expenseAccounts && state.integrations.expenseAccounts.results) || [],
  expenseClasses: (state.integrations.expenseClasses && state.integrations.expenseClasses.results) || []
}))(QuickbooksMappingForm);
