import {
  BillingApi,
  Configuration,
  InlineObject2,
  InlineResponse2002,
  PaginatedQuickbooksGLAccount,
  QuickbooksExpensesCategoryMapping
} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function getShipwellApi() {
  return new BillingApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type GetQuickbooksBillingExpenseCategoriesMappingParameters = Parameters<
  typeof BillingApi.prototype.billingIntegrationsQuickbooksExpensesCategoriesMappingGet
>;
export type GetQuickbooksBillingExpenseCategoriesMappingOptions = {
  page?: GetQuickbooksBillingExpenseCategoriesMappingParameters[0];
  pageSize?: GetQuickbooksBillingExpenseCategoriesMappingParameters[1];
};

/**
 * Billing Expense Category Mapping
 */
export async function getQuickbooksBillingExpenseCategoriesMapping({
  page,
  pageSize
}: GetQuickbooksBillingExpenseCategoriesMappingOptions) {
  const shipwellApi = getShipwellApi();

  const {data} = await shipwellApi.billingIntegrationsQuickbooksExpensesCategoriesMappingGet(page, pageSize);

  return data;
}

export async function createQuickbooksBillingExpenseCategoriesMapping(
  body: InlineObject2
): Promise<QuickbooksExpensesCategoryMapping> {
  const shipwellApi = getShipwellApi();

  const {data} = await shipwellApi.billingIntegrationsQuickbooksExpensesCategoriesMappingPost(body);

  return data;
}

export async function updateQuickbooksBillingExpenseCategoriesMapping(
  id: string,
  body: QuickbooksExpensesCategoryMapping
): Promise<QuickbooksExpensesCategoryMapping> {
  const shipwellApi = getShipwellApi();

  const {data} =
    await shipwellApi.billingIntegrationsQuickbooksExpensesCategoriesMappingQuickbooksExpensesCategoryMappingIdPut(
      id,
      body
    );
  return data;
}

export async function deleteQuickbooksBillingExpenseCategoriesMapping(id: string) {
  const shipwellApi = getShipwellApi();

  const {data} =
    await shipwellApi.billingIntegrationsQuickbooksExpensesCategoriesMappingQuickbooksExpensesCategoryMappingIdDelete(
      id
    );

  return data;
}

type GetQuickbooksExpenseAccountParameters = Parameters<
  typeof BillingApi.prototype.billingIntegrationsQuickbooksAccountsGet
>;
export type GetQuickbooksExpenseAccountsOptions = {
  accountType?: GetQuickbooksExpenseAccountParameters[0];
  page?: GetQuickbooksExpenseAccountParameters[1];
  pageSize?: GetQuickbooksExpenseAccountParameters[2];
};

/**
 * Billing Expense Accounts
 */
export async function getQuickbooksExpenseAccounts({
  accountType,
  page,
  pageSize
}: GetQuickbooksExpenseAccountsOptions): Promise<PaginatedQuickbooksGLAccount> {
  const shipwellApi = getShipwellApi();

  const {data} = await shipwellApi.billingIntegrationsQuickbooksAccountsGet(accountType, page, pageSize);

  return data;
}

export async function createQuickbooksExpenseAccounts(): Promise<InlineResponse2002> {
  const shipwellApi = getShipwellApi();

  const result = await shipwellApi.billingIntegrationsQuickbooksAccountsPost();

  return result.data;
}
