import {Button} from '@shipwell/shipwell-ui';
import {RFPBidOptResponse, RFPBidOptResponseStateEnum} from '@shipwell/backend-core-sdk';
import SendRfpBidRequest from './SendRfpBidRequest';
import {isPastDate} from 'App/utils/dateTimeGlobalsTyped';

const ActionButton = ({rfp}: {rfp: RFPBidOptResponse}) => {
  if (
    [RFPBidOptResponseStateEnum.Created, RFPBidOptResponseStateEnum.BidRequested].some(
      (state) => state === rfp.state
    ) &&
    !isPastDate(rfp.end_date || '')
  ) {
    return <SendRfpBidRequest rfp={rfp} />;
  }
  if (rfp.state === RFPBidOptResponseStateEnum.BidComplete && rfp.number_of_created_routing_guides) {
    return null;
  }
  if (rfp.state === RFPBidOptResponseStateEnum.BidComplete) {
    return <Button variant="primary">Review Bids</Button>;
  }
  return null;
};

export default ActionButton;
