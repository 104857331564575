import {connect} from 'react-redux';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {getMissingDataByStop} from 'App/containers/LoadBoard/utils';
import './styles.scss';

const EdiTenderData = ({load = {}}) => {
  const {customer_reference_number, stops = []} = load;
  const stopData = getMissingDataByStop(stops);
  const unmatchedLocationTooltipContent = (
    <div className="unmatched-location__tooltip-content">
      <p>Unmatched Location ID</p>
      <p>
        The location ID and address that were provided for this load do not match an address book entry. This could lead
        to incorrect information being assigned to the shipment.
      </p>
      <p>Update the address book entry or contact the shipper for more information.</p>
    </div>
  );

  return (
    <div className="edi-data">
      <div className="edi-data__header">
        <div className="edi-data__title">EDI Tender Data</div>
        <div className="edi-data__subtitle">Customer Reference #</div>
        {customer_reference_number && <span>{customer_reference_number}</span>}
      </div>
      <div className="edi-data__stops">
        {stopData.map((stop) => {
          return (
            <div className="edi-data__stop grid-1-2" key={stop.id}>
              <div className="edi-data__stop-data">
                <div className="edi-data__title">{stop.title}</div>
                <div className="edi-data__subtitle">Address</div>
                <div>{stop.address}</div>
              </div>
              <div className="edi-data__missing-data">
                {stop.locationRefId && (
                  <div className="edi-data__missing-location">
                    <div className="edi-data__subtitle">
                      Unmatched Location ID
                      <Tooltip
                        tooltipContent={unmatchedLocationTooltipContent}
                        tooltipClassname="unmatched-location__tooltip"
                      >
                        <SvgIcon name="InfoOutlined" color="$sw-icon-small" width="20" height="20" />
                      </Tooltip>
                    </div>

                    <div className="edi-data__missing-location-id">{stop.locationRefId}</div>
                  </div>
                )}
                {stop.missingData.length > 0 && (
                  <div className="edi-data__missing-shipment">
                    <div className="edi-data__subtitle">Missing Shipment Information</div>
                    {stop.missingData.map((data) => {
                      return (
                        <div className="edi-data__missing-shipment-field" key={data}>
                          {data}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default connect((state) => ({load: state.marketplace.loadBoardShipmentDetails}))(EdiTenderData);
