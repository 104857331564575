import {useQuery} from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import {fetchHazmatCodesPromise} from 'App/api/shipment/typed';
import {HAZMAT_CODES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export default function useHazmatCodes() {
  const hazmatCodesQuery = useQuery([HAZMAT_CODES_QUERY_KEY], () => fetchHazmatCodesPromise(), {
    staleTime: STATIC_DATA_STALE_TIME
  });

  const hazmatOptions = hazmatCodesQuery.data?.data;

  const getHazmatOptionFromId = (id: string) =>
    hazmatOptions?.find((option) => isEqual(option.identification_number, id));

  return {...hazmatCodesQuery, hazmatOptions, getHazmatOptionFromId};
}
