import {verifyPhoneNumber} from 'App/api/carriers';
import {validatePhoneNumber} from 'App/components/forms/utils';

const getCellPhoneServiceProvider = async (phoneNumber) => {
  let provider;
  if (validatePhoneNumber(phoneNumber)) {
    try {
      const response = await verifyPhoneNumber({phone_number: phoneNumber});
      if (response?.body?.type === 'mobile') {
        provider = response.body;
      }
    } catch (e) {
      console.error(e);
    }
    if (provider) {
      return provider;
    }
    throw new Error('Not a valid cell phone number.');
  } else {
    throw new Error('Not a valid phone number.');
  }
};

export default getCellPhoneServiceProvider;
