import {
  PieceDetail,
  ProductPackageType,
  Shipment,
  ShipmentLineItem,
  Stop
} from '@shipwell/backend-core-singlerequestparam-sdk';

export type StopPairs = {
  id: string;
  pickup: Stop;
  dropoff: Stop;
  // because the form needs a weight value, we have to update the pieces type found on ShipmentLineItem
  handling_units: (Omit<ShipmentLineItem, 'pieces'> & {
    pieces: (PieceDetail & {weight?: number; used_pieces?: number})[];
  })[];
};

export type AssembleHandlingUnitsFormValues = {
  active_handling_unit_id?: string;
  // quanitity is what is listed in add pieces input
  // weight is derived from package_weight / total_pieces
  line_items: (ShipmentLineItem & {quantity?: number; weight?: number; used_pieces?: number})[];
  stops: StopPairs[];
};

const roundToThousandths = (val: number) => Math.round(val * 100) / 100;

// Type checker func
export const isLineItem = (
  item?:
    | AssembleHandlingUnitsFormValues['line_items'][number]
    | AssembleHandlingUnitsFormValues['stops'][number]['handling_units'][number]['pieces'][number]
): item is AssembleHandlingUnitsFormValues['line_items'][number] => {
  return item ? 'quantity' in item : false;
};

// A helper getter used in most of the below funcs
export const getStopAndHandlingUnitIndexes = ({
  activeHandlingUnitId,
  stops
}: {
  activeHandlingUnitId?: string;
  stops: StopPairs[];
}) => {
  if (!activeHandlingUnitId) {
    return;
  }
  const stopIndex = stops.findIndex((stop) =>
    stop.handling_units.some((handlingUnit) => handlingUnit?.id === activeHandlingUnitId)
  );
  // if no stop index is found, bail
  if (stopIndex < 0) {
    return;
  }
  const handlingUnitIndex = stops[stopIndex]?.handling_units.findIndex(
    (handlingUnit) => handlingUnit?.id === activeHandlingUnitId
  );
  // if no handling unit index is found, bail
  if (handlingUnitIndex < 0) {
    return;
  }
  return {
    stopIndex,
    handlingUnitIndex
  };
};

const getFreightClass = (classes: (string | undefined)[]) => {
  const validClasses = classes.reduce((all: number[], cur) => {
    if (typeof cur === 'string') {
      all.push(Number(cur));
    }
    return all;
  }, []);
  if (!validClasses.length) {
    return undefined;
  }
  const maxClass = Math.max(...validClasses);
  // if max class is 0, BE will be upset, instead return undefined
  if (!maxClass || isNaN(maxClass)) {
    return undefined;
  }
  return maxClass.toString();
};

export const getLineItemsThatMatchStop = (
  lineItems: AssembleHandlingUnitsFormValues['line_items'],
  stops: StopPairs[],
  activeHandlingUnitId?: string
) => {
  return lineItems.filter((lineItem) => {
    // line items not associated with stops are available for all handling units
    const notAssociated = !(lineItem.destination_stop && lineItem.origin_stop);
    if (notAssociated) return true;
    const currentStop = stops.find((stop) =>
      stop.handling_units.some((handlingUnit) => handlingUnit.id === activeHandlingUnitId)
    );
    if (!currentStop) return true;
    const isMatchingOrigin = lineItem.origin_stop === currentStop.pickup.id;
    const isMatchingDestination = lineItem.destination_stop === currentStop.dropoff.id;
    return isMatchingDestination && isMatchingOrigin;
  });
};

export const handleAddToHandlingUnit = ({
  activeHandlingUnitId,
  lineItems,
  lineItemIndex,
  stops
}: {
  activeHandlingUnitId?: string;
  lineItems?:
    | AssembleHandlingUnitsFormValues['line_items']
    | AssembleHandlingUnitsFormValues['stops'][number]['handling_units'][number]['pieces'];
  lineItemIndex: number;
  stops: StopPairs[];
}) => {
  const lineItem = lineItems?.[lineItemIndex];
  if (!isLineItem(lineItem)) {
    return;
  }
  const indexes = getStopAndHandlingUnitIndexes({activeHandlingUnitId, stops});
  if (!indexes) {
    return;
  }
  const {handlingUnitIndex, stopIndex} = indexes;
  // check if lineitem with matching ID already exists on handling unit
  const pieceIndex = stops[stopIndex]?.handling_units[handlingUnitIndex].pieces.findIndex(
    (piece) => piece.id === lineItem.id
  );
  const currentPiece = stops[stopIndex]?.handling_units[handlingUnitIndex].pieces[pieceIndex];

  // update existing handling unit line item to new quantity
  const totalPieces = {
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].pieces[${pieceIndex}].total_pieces`]:
      (currentPiece?.total_pieces || 0) + Number(lineItem.quantity)
  };
  // add lineitem to handling unit
  const createdLineItem: ShipmentLineItem = {
    ...lineItem,
    // associate line item with stop
    origin_stop: stops[stopIndex].pickup.id,
    destination_stop: stops[stopIndex].dropoff.id,
    total_pieces: Number(lineItem.quantity)
  };
  const currentPieces = stops[stopIndex]?.handling_units[handlingUnitIndex].pieces;
  const updatedPieces = {
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].pieces`]: [...currentPieces, createdLineItem]
  };

  // update handling unit's Net Weight
  const currentHandlingUnitWeight = stops[stopIndex]?.handling_units[handlingUnitIndex].package_weight || 0;
  const netWeightOfCreatedLineItem = (lineItem.weight || 0) * (lineItem.quantity || 1);
  const newWeight = roundToThousandths(currentHandlingUnitWeight + netWeightOfCreatedLineItem);

  // Update lineitem with highest freight class of pieces and new lineItem's frieght class
  const highestFreightClass = getFreightClass([
    ...stops[stopIndex].handling_units[handlingUnitIndex].pieces.map((piece) => piece.freight_class),
    lineItem.freight_class
  ]);

  // update lineitem with removed quanity
  lineItem.total_pieces = (lineItem?.total_pieces || 0) - (lineItem.quantity || 0);
  lineItem.quantity = lineItem?.total_pieces || 0;
  // associate line item with stop
  lineItem.origin_stop = stops[stopIndex].pickup.id;
  lineItem.destination_stop = stops[stopIndex].dropoff.id;
  return {
    // check if lineitem with matching ID already exists on handling unit
    ...(pieceIndex >= 0 ? totalPieces : updatedPieces),
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].package_weight`]: newWeight,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].weight_unit`]: lineItem.weight_unit,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].freight_class`]: highestFreightClass,
    [`line_items[${lineItemIndex}]`]: lineItem
  };
};

export const handleAddAllLineItems = ({
  activeHandlingUnitId,
  stops,
  lineItems
}: {
  activeHandlingUnitId?: string;
  stops: StopPairs[];
  lineItems: AssembleHandlingUnitsFormValues['line_items'];
}) => {
  const indexes = getStopAndHandlingUnitIndexes({activeHandlingUnitId, stops});
  if (!indexes) {
    return;
  }
  const {handlingUnitIndex, stopIndex} = indexes;
  // add to active handling unit
  const prevHandlingUnitPieces = stops[stopIndex].handling_units[handlingUnitIndex].pieces || [];
  // add only valid lineItems
  // * either 1)line items with no stop associated or 2)the stop matches
  const matchingLineItems = getLineItemsThatMatchStop(lineItems, stops, activeHandlingUnitId);
  const allLineItemsInHandlingUnits = stops
    .map((stop) => stop.handling_units.map((handlingUnit) => handlingUnit.pieces))
    .flat(2);

  const lineItemsWithQuantityRemaining = matchingLineItems.filter((lineItem) => {
    const foundInHandlingUnit = allLineItemsInHandlingUnits?.some((handlingUnit) => handlingUnit.id === lineItem.id);
    // if not found in a handling unit, we are allowed to add line items with 0 total_pieces
    return !foundInHandlingUnit;
  });

  // update prev to include new totals if remaining line items in
  const updatedPrevHandlingUnitPieces = prevHandlingUnitPieces.map((piece) => {
    const foundInPieces = matchingLineItems.find((lineItem) => lineItem.id === piece.id);
    // if found in pieces, we need to update the piece's total_pieces
    if (foundInPieces) {
      piece.total_pieces = (piece.total_pieces || 0) + (foundInPieces.quantity || 0);
    }
    return piece;
  });

  const newPieces = [...updatedPrevHandlingUnitPieces, ...lineItemsWithQuantityRemaining];

  // update weight
  const netWeightOfAllPieces = newPieces.reduce((total, piece) => {
    const itemNetWeight = (piece.weight || 0) * (piece.total_pieces || 1);
    return (total += itemNetWeight);
  }, 0);
  const newWeight = roundToThousandths(netWeightOfAllPieces);

  // remove quantity from line items that got added to a handling unit
  const updatedLineItems = lineItems.map((lineItem) => ({
    ...lineItem,
    quantity: matchingLineItems.find((item) => lineItem.id === item.id) ? 0 : lineItem.quantity,
    total_pieces: matchingLineItems.find((item) => lineItem.id === item.id) ? 0 : lineItem.total_pieces
  }));

  const highestFreightClass = getFreightClass(newPieces.map((piece) => piece.freight_class));

  // keys are the proper formik field names
  return {
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].pieces`]: newPieces,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].package_weight`]: newWeight,
    // to guess at a prefered weight unit, we grab the first weight unit off the newly added lineitems
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].weight_unit`]:
      lineItemsWithQuantityRemaining[0]?.weight_unit,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].freight_class`]: highestFreightClass,
    line_items: updatedLineItems
  };
};

export const handleRemoveFromHandlingUnit = ({
  activeHandlingUnitId,
  stops,
  lineItems,
  itemIndex,
  itemId
}: {
  activeHandlingUnitId?: string;
  stops: StopPairs[];
  lineItems: AssembleHandlingUnitsFormValues['line_items'];
  itemIndex: number;
  itemId: string;
}) => {
  const indexes = getStopAndHandlingUnitIndexes({activeHandlingUnitId, stops});
  if (!indexes) {
    return;
  }
  const {handlingUnitIndex, stopIndex} = indexes;
  const removedQuantity = stops[stopIndex].handling_units[handlingUnitIndex].pieces[itemIndex].total_pieces || 0;
  const lineItemIndex = lineItems.findIndex((lineItem) => lineItem.id === itemId);
  if (lineItemIndex < 0) {
    return;
  }
  const lineItem = lineItems[lineItemIndex];
  // update original line item
  const updatedLineItem = {
    ...lineItem,
    total_pieces: (lineItem.total_pieces || 0) + Number(removedQuantity),
    quantity: (lineItem.total_pieces || 0) + Number(removedQuantity)
  };
  // remove from handling unit
  const updatedPieces = stops[stopIndex].handling_units[handlingUnitIndex].pieces.filter(
    (piece) => piece.id !== itemId
  );
  // update handling unit net weight
  const updatedWeight = updatedPieces.reduce((total, piece) => {
    return (total += (piece.weight || 0) * (piece.total_pieces || 1));
  }, 0);

  const highestFreightClass = getFreightClass(updatedPieces.map((piece) => piece.freight_class));

  return {
    [`line_items[${lineItemIndex}]`]: updatedLineItem,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].pieces`]: updatedPieces,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].package_weight`]: roundToThousandths(updatedWeight),
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].freight_class`]: highestFreightClass
  };
};

export const handleRemoveAllFromHandlingUnit = ({
  handlingUnitId,
  handlingUnitIndex,
  stops,
  lineItems
}: {
  handlingUnitIndex: number;
  handlingUnitId: string;
  stops: StopPairs[];
  lineItems: AssembleHandlingUnitsFormValues['line_items'];
}) => {
  const stopIndex = stops.findIndex((stop) =>
    stop.handling_units?.some((handlingUnit) => handlingUnit.id === handlingUnitId)
  );
  if (stopIndex < 0) {
    return;
  }

  // add total_pieces back to original line items
  const updatedLineItems =
    lineItems.map((lineItem) => {
      const handlingUnitPieces =
        stops[stopIndex].handling_units.find((handlingUnit) => handlingUnit.id === handlingUnitId)?.pieces || [];
      const totalPieces = handlingUnitPieces.find((piece) => piece.id === lineItem.id)?.total_pieces || 0;
      lineItem.total_pieces = (lineItem.total_pieces || 0) + totalPieces;
      lineItem.quantity = lineItem.total_pieces || 0;
      return lineItem;
    }) || [];

  // keys are the proper formik field names
  return {
    line_items: updatedLineItems,
    // clear out current handling unit's pieces
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].pieces`]: [],
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].package_weight`]: undefined,
    [`stops[${stopIndex}].handling_units[${handlingUnitIndex}].freight_class`]: ''
  };
};

// if total_pieces is falsy, we should just show the package weight
const getPerUnitWeight = (item: ShipmentLineItem | PieceDetail) => {
  if (!item.total_pieces) {
    return item.package_weight || 0;
  }
  if (!item.package_weight) {
    return 0;
  }
  return item.package_weight / item.total_pieces;
};

export const createInitialValues = ({
  lineItems,
  stops
}: {
  lineItems: Shipment['line_items'];
  stops: Shipment['stops'];
}): AssembleHandlingUnitsFormValues => {
  // line items with pieces.length should be converted into hanlding units
  const initialHandlingUnits = lineItems?.filter((lineItem) => lineItem.pieces?.length);
  // pairs all pickup stops with all dropoff stops that come after it
  const pickupDropoffPairs = stops?.reduce((stopPairs: StopPairs[], currentStop, index) => {
    if (currentStop.is_pickup) {
      // only map with dropoff stops that come after the current pickup index
      const dropoffStops = stops.slice(index).filter((stop) => stop.is_dropoff);
      const dropoffPairs: StopPairs[] = dropoffStops.map((dropoffStop) => {
        const handlingUnits: StopPairs['handling_units'] =
          initialHandlingUnits
            ?.filter((handlingUnit) => {
              const matchingOrigin = handlingUnit.origin_stop === currentStop.id;
              const matchingDestination = handlingUnit.destination_stop === dropoffStop.id;
              return matchingOrigin && matchingDestination;
            })
            .map((handlingUnit) => {
              return {
                ...handlingUnit,
                pieces:
                  handlingUnit.pieces?.map((piece) => ({
                    ...piece,
                    // the form needs the weight value
                    weight: getPerUnitWeight(piece)
                  })) || []
              };
            }) || [];
        return {
          // id is a FE only combo of pickup and dropoff ids
          id: `${currentStop.id}${dropoffStop.id}`,
          pickup: currentStop,
          dropoff: dropoffStop,
          handling_units: handlingUnits
        };
      });
      return [...stopPairs, ...dropoffPairs];
    }
    return stopPairs;
  }, []);

  const lineItemsFromPieces =
    initialHandlingUnits
      ?.reduce((all: PieceDetail[], handlingUnit) => {
        const pieces = handlingUnit.pieces || [];
        return [...all, ...pieces];
      }, [])
      .map((item) => ({
        ...item,
        id: item?.id || '',
        description: item.description,
        piece_type: item.piece_type,
        hazmat_hazard_class: item.hazmat_hazard_class,
        package_weight: item.package_weight,
        weight_unit: item.weight_unit,
        total_pieces: 0,
        used_pieces: item.total_pieces || 0,
        quantity: 0,
        // rounding to thousandth decimal
        weight: getPerUnitWeight(item)
      })) || [];

  // adds used_pieces and quantity to the line_item which is used in the form
  const initialLineItems = (lineItems || [])
    .filter((item) => !item.pieces?.length)
    .map((item) => ({
      ...item,
      id: item.id,
      description: item.description,
      piece_type: item.piece_type,
      hazmat_hazard_class: item.hazmat_hazard_class,
      package_weight: item.package_weight,
      weight_unit: item.weight_unit,
      total_pieces: item.total_pieces || 0,
      used_pieces: item.total_pieces || 0,
      quantity: item.total_pieces || 0,
      // rounding to thousandth decimal
      weight: getPerUnitWeight(item)
    }));

  // get first handling unit from first stop and set as the default active handling unit
  const activeHandlingUnitId = pickupDropoffPairs?.[0]?.handling_units?.[0]?.id;

  return {
    active_handling_unit_id: activeHandlingUnitId,
    line_items: [...initialLineItems, ...lineItemsFromPieces],
    stops: pickupDropoffPairs || []
  };
};

export const packagingTypeOptions = [
  {label: 'Bag', value: ProductPackageType.Bag},
  {label: 'Bale', value: ProductPackageType.Bale},
  {label: 'Bin', value: ProductPackageType.Bin},
  {label: 'Box', value: ProductPackageType.Box},
  {label: 'Bucket', value: ProductPackageType.Bucket},
  {label: 'Bundle', value: ProductPackageType.Bundle},
  {label: 'Can', value: ProductPackageType.Can},
  {label: 'Carton', value: ProductPackageType.Carton},
  {label: 'Case', value: ProductPackageType.Case},
  {label: 'Coil', value: ProductPackageType.Coil},
  {label: 'Crate', value: ProductPackageType.Crate},
  {label: 'Cylinder', value: ProductPackageType.Cylinder},
  {label: 'Drum', value: ProductPackageType.Drum},
  {label: 'Floor Loaded', value: ProductPackageType.FloorLoaded},
  {label: 'Other', value: ProductPackageType.Other},
  {label: 'Package', value: ProductPackageType.Pkg},
  {label: 'Pallet', value: ProductPackageType.Plt},
  {label: 'Pail', value: ProductPackageType.Pail},
  {label: 'Pieces', value: ProductPackageType.Pieces},
  {label: 'Reel', value: ProductPackageType.Reel},
  {label: 'Rool', value: ProductPackageType.Roll},
  {label: 'Skid', value: ProductPackageType.Skid},
  {label: 'Tote Bin', value: ProductPackageType.ToteBin},
  {label: 'Tote Can', value: ProductPackageType.ToteCan},
  {label: 'Tube', value: ProductPackageType.Tube},
  {label: 'Unit', value: ProductPackageType.Unit}
];
