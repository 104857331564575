import {
  ShipmentsApiShipmentsStopLocationsGetRequest,
  StopLocation
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {SHIPMENT_STOP_LOCATIONS} from '../queryKeys';
import {getShipmentStopLocations} from 'App/api/locations/typed';

export const useGetShipmentStopLocations = ({
  params,
  options
}: {
  params?: ShipmentsApiShipmentsStopLocationsGetRequest;
  options?: Omit<
    UseQueryOptions<
      StopLocation[] | undefined,
      unknown,
      StopLocation[],
      (string | undefined | ShipmentsApiShipmentsStopLocationsGetRequest)[]
    >,
    'queryKey' | 'queryFn'
  >;
}) => {
  const getShipmentStopLocationsQuery = useQuery(
    [SHIPMENT_STOP_LOCATIONS, 'list', params],
    async () => {
      const response = await getShipmentStopLocations(params);
      return response.data.results;
    },
    options
  );

  const {
    data: getShipmentStopLocationsData,
    isFetching: getShipmentStopLocationsIsFetching,
    status: getShipmentStopLocationsStatus
  } = getShipmentStopLocationsQuery;
  return {
    getShipmentStopLocationsData,
    getShipmentStopLocationsIsFetching,
    getShipmentStopLocationsStatus,
    getShipmentStopLocationsQuery
  };
};
