import {useQuery} from '@tanstack/react-query';

import {SHIPMENT_AUDITLOG_QUERY_KEY} from '../queryKeys';

import {getShipmentDocumentsAuditlog} from 'App/api/shipment/typed';

export type ShipmentDocumentAuditlog = {
  emails: {
    replyto_email: string;
    status: string;
    to_email: string;
    updated_at: string;
  }[];
  file: string;
  filename: string;
  id: string;
}[];

export default function useShipmentAuditlog(shipmentId?: string): {
  isLoading: boolean;
  auditlog?: ShipmentDocumentAuditlog;
  error?: unknown;
} {
  const {
    isLoading,
    data: auditlog,
    error
  } = useQuery([SHIPMENT_AUDITLOG_QUERY_KEY, shipmentId ?? ''], async () => {
    if (!shipmentId) {
      return undefined;
    }
    return getShipmentDocumentsAuditlog(shipmentId);
  });
  return {isLoading, auditlog: auditlog as ShipmentDocumentAuditlog | undefined, error};
}
