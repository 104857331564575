import {FormGroup, ControlLabel, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import AddressLookup from 'App/utils/AddressLookup';

const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  }
};

const renderAddress = ({
  handleWarning,
  input,
  tabIndex,
  defaultValue,
  value,
  addr,
  label,
  icon,
  iconRight,
  tooltip,
  placeholder,
  type,
  req,
  mapButton,
  showMap,
  meta: {touched, error},
  ...rest
}) => (
  <FormGroup controlId={input.name} validationState={touched && error ? 'error' : null}>
    {label ? (
      <ControlLabel>
        {label}
        {req && <span className="requiredField">*</span>}
      </ControlLabel>
    ) : (
      <ControlLabel>&nbsp;</ControlLabel>
    )}
    <InputGroup className="addressField">
      {icon && (
        <InputGroup.Addon style={style.inputs}>
          <i className={icon} style={style.cursor} />
        </InputGroup.Addon>
      )}
      <AddressLookup
        {...input}
        type={type}
        handleWarning={handleWarning}
        tabIndex={tabIndex}
        className={
          touched && error ? 'lookup-field form-control lookup-field-error' : 'lookup-field form-control lookup-field'
        }
        placeholder={placeholder}
        value={value ? value : ''}
        addr={input.value || ''}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        {...rest}
      />
      {mapButton && (
        <InputGroup.Addon
          onClick={() => {
            showMap();
          }}
        >
          <i className={'flaticon-worldwide'} />
        </InputGroup.Addon>
      )}
      {iconRight && (
        <InputGroup.Addon style={style.inputs}>
          <i className={iconRight} style={style.cursor} />
        </InputGroup.Addon>
      )}
    </InputGroup>
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderAddress;
