import {UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {RMISCarrierPolicy, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {RMIS_COMPLIANCE_POLICY} from '../queryKeys';
import {getRMISCarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseRMISPolicyQueryOptions = Omit<
  UseQueryOptions<RMISCarrierPolicy, AxiosError<ShipwellError>, RMISCarrierPolicy, string[]>,
  'queryKey' | 'queryFn'
>;

const useRMISPolicyQuery = (options?: UseRMISPolicyQueryOptions) => {
  const queryClient = useQueryClient();
  const query = useQuery([RMIS_COMPLIANCE_POLICY], getRMISCarrierCompliancePolicy, options);

  return {
    isRMISInitialLoading: query.isInitialLoading,
    isRMISLoading: query.isLoading,
    isRMISFetching: query.isFetching,
    isRMISFetched: query.isFetched,
    isRMISSuccess: query.isSuccess,
    isRMISError: query.isError,
    rmisError: query.error || null,
    rmisPolicy: query.data || null,
    refetchRMISPolicy: query.refetch,
    invalidateRMISPolicyQuery: () => queryClient.invalidateQueries([RMIS_COMPLIANCE_POLICY])
  };
};

export default useRMISPolicyQuery;
