import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {Toast} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {
  importFile,
  getImport,
  getImportableFields,
  getImportColumnMappings,
  getImportRows,
  getImportJob,
  getImportJobErrors
} from 'App/api/imports';
import {createImportJob} from 'App/api/imports/typed';
import useInterval from 'App/utils/hooks/useInterval';
import SelectFileForm from 'App/components/imports/create/selectFileForm';
import MapFieldsForm from 'App/components/imports/create/mapFieldsForm';
import RowDataTable from 'App/components/imports/create/rowDataTable';
import './styles.scss';

const STEPS = {
  SELECT_FILE: 'SELECT_FILE',
  UPLOADING_FILE: 'UPLOADING_FILE',
  MAP_FIELDS: 'MAP_FIELDS',
  VALIDATING_DATA: 'VALIDATING_DATA',
  FIX_ERRORS: 'FIX_ERRORS',
  SAVING_DATA: 'SAVING_DATA'
};

const CreateImport = ({
  type,
  onCancel,
  setUseFullScreen,
  setShowImportSuccess,
  onImportSuccess,
  onPrevious,
  opts,
  skipValidation,
  skipOnSuccessModalToggle,
  validationMessage
}) => {
  const [step, setStep] = useState(STEPS.SELECT_FILE);
  const [importId, setImportId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [columnMappings, setColumnMappings] = useState([]);
  const [isImportPolling, setIsImportPolling] = useState(false);
  const [isJobPolling, setIsJobPolling] = useState(false);
  const [importableFields, setImportableFields] = useState({});
  const [showError, setShowError] = useState(false);
  const [readyToValidate, setReadyToValidate] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(-1);
  const [columnWidths, setColumnWidths] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);

  // Used to enforce a single real import (validateOnly=false) at a time
  const isImportingRef = useRef(false);

  useEffect(() => {
    if (!Object.keys(importableFields).length) {
      getImportableFields()
        .then((response) => {
          if (response.body[type]) {
            setImportableFields(response.body[type]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const renderUploading = () => {
    return (
      <>
        <ShipwellLoader loading />
        <h4 className="text-center">Reading file...</h4>
      </>
    );
  };

  const renderValidating = () => {
    return (
      <>
        <ShipwellLoader loading />
        <h4 className="text-center">Validating data...</h4>
      </>
    );
  };

  const renderSavingData = () => {
    return (
      <>
        {skipValidation ? null : (
          <h3 className="imports__savingData-header">
            <i className="material-icons text-success">check_circle</i> Validation Complete
          </h3>
        )}
        <ShipwellLoader loading />
        <h4 className="text-center">Importing data...</h4>
        {validationMessage ? (
          <p className="text-center">{validationMessage}</p>
        ) : validationMessage === null ? null : (
          <p className="text-center">
            You can close this modal and check the Imports page later to see the status of this import.
          </p>
        )}
      </>
    );
  };

  const handleFileUpload = async (values, {setSubmitting, setErrors}) => {
    setSubmitting(true);
    const fileToSubmit = {};
    if (values.google_sheet_id) {
      fileToSubmit.google_sheet_id = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(values.google_sheet_id)[1];
    }
    if (values.csv_file) {
      fileToSubmit.csv_file = values.csv_file;
    }
    fileToSubmit.type = type;
    try {
      const response = await importFile(fileToSubmit);
      if (response && response.status === 202) {
        setStep(STEPS.UPLOADING_FILE);
        setImportId(response.data.id);
        setIsImportPolling(true);
      }
    } catch (error) {
      setSubmitting(false);
      setErrors(error.body.field_errors);
      setShowError(error.body.error_description);
    }
  };

  /*
   * Interval - polling for import status
   */
  useInterval(
    () => {
      getImport(importId).then((response) => {
        if (response.body && response.body.is_complete) {
          setIsImportPolling(false);
          if (response.body.error) {
            setShowError(response.body.error);
            setStep(STEPS.SELECT_FILE);
            return;
          }
          getColumnMappings(response.body.id);
          getRows(response.body.id);
        }
      });
    },
    isImportPolling ? 2000 : null
  );

  /*
   * Interval - polling for job status
   */
  useInterval(
    async () => {
      const response = await getImportJob(importId, jobId);
      if (response.body && response.body.is_complete) {
        setIsJobPolling(false);

        if (response.body.error_rows && !skipValidation) {
          getJobErrors(response.body.id);
          isImportingRef.current = false;
        } else if (response.body.errors) {
          //these errors are not specific to row, more like a 500 in the backend
          setShowError(response.body.errors);
          isImportingRef.current = false;
          //this is probably the best place to take user back to with the overall error
          setStep(STEPS.MAP_FIELDS);
        } else {
          // start the import since there are no errors
          // we only want to start one at a time, though, so use a ref to enforce this in case
          // multiple intervals trigger this condition while polling – e.g. on a slow network
          if (step === STEPS.VALIDATING_DATA && isImportingRef.current === false) {
            isImportingRef.current = true;
            createJob(importId, false);
          } else if (step === STEPS.SAVING_DATA) {
            setShowImportSuccess(response.body.success_rows);
            onImportSuccess('Success!', `${pluralize('records', response.body.success_rows, true)} imported.`);

            if (!skipOnSuccessModalToggle) {
              onCancel();
            }

            isImportingRef.current = false;
          }
        }
      }
    },
    isJobPolling ? 2000 : null
  );

  const getColumnMappings = async (importId) => {
    try {
      const response = await getImportColumnMappings(importId);
      if (response && response.status === 200) {
        setColumnMappings(response.body);
        const initialColumnWidths = {};
        response.body.forEach((col) => {
          initialColumnWidths[col.id] = 190;
        });
        setColumnWidths(initialColumnWidths);
        setUseFullScreen(true);
        setStep(STEPS.MAP_FIELDS);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateColumnWidths = (columns) => {
    const newColumn = {};
    columns.forEach((col) => {
      newColumn[col.id] = col.value;
    });
    const newColumnWidths = Object.assign({}, columnWidths, newColumn);
    setColumnWidths(newColumnWidths);
  };

  const getRows = async (importId, opts = {page: 1, pageSize: 20}) => {
    try {
      const response = await getImportRows(importId, opts);
      if (response && response.status === 200) {
        setRows(response.body.results);
        setTotalPages(response.body.total_pages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createJob = async (importId, validateOnly) => {
    try {
      const response = await createImportJob(importId, validateOnly, opts);
      if (response && response.status === 202) {
        setJobId(response.data.id);
        if (validateOnly) {
          setStep(STEPS.VALIDATING_DATA);
          setIsJobPolling(true);
        } else {
          //we're doing the real import
          setStep(STEPS.SAVING_DATA);
          setIsJobPolling(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getJobErrors = async (jobId) => {
    try {
      //refresh column mappings and rows
      await getColumnMappings(importId);
      await getRows(importId);
      const opts = {page: 1, pageSize: 100000};
      const response = await getImportJobErrors(jobId, opts);
      if (response && response.status === 200) {
        setValidationErrors(response.body.results);
        setStep(STEPS.FIX_ERRORS);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uniqueRowErrors = validationErrors.reduce((arr, element) => {
    if (!arr.find((e) => e.row === element.row)) {
      arr.push(element);
    }
    return arr;
  }, []);

  const rowsWithErrors = rows.filter((row) =>
    uniqueRowErrors.find((errorRow) =>
      // the typing says that errorRow.row should always be a string but for orders import this is not the case
      typeof errorRow.row === 'string' ? errorRow.row === row.id : errorRow.row?.id === row.id
    )
  );

  return (
    <div className="imports">
      {step === STEPS.SELECT_FILE && (
        <SelectFileForm onPrevious={onPrevious} type={type} handleFileUpload={handleFileUpload} onCancel={onCancel} />
      )}
      {step === STEPS.UPLOADING_FILE && renderUploading()}
      {step === STEPS.VALIDATING_DATA && renderValidating()}
      {(step === STEPS.MAP_FIELDS || step === STEPS.FIX_ERRORS) && (
        <>
          {uniqueRowErrors.length > 0 && (
            <p className="text-danger">
              There are errors on {`${uniqueRowErrors.length} row${uniqueRowErrors.length > 1 ? 's' : ''}`}. Please fix
              all errors in the rows below to continue.
            </p>
          )}
          <MapFieldsForm
            importId={importId}
            importableFields={importableFields}
            type={type}
            columnMappings={columnMappings}
            columnWidths={columnWidths}
            allFieldsMapped={setReadyToValidate}
            validationErrors={validationErrors}
          />
          <RowDataTable
            updateColumnWidths={updateColumnWidths}
            importId={importId}
            rows={step === STEPS.FIX_ERRORS ? rowsWithErrors : rows}
            type={type}
            columns={columnMappings}
            createJob={createJob}
            readyToValidate={readyToValidate}
            onCancel={onCancel}
            validationErrors={validationErrors}
            fetchRowData={getRows}
            totalPages={totalPages}
            skipValidation={skipValidation}
          />
        </>
      )}
      {step === STEPS.SAVING_DATA && renderSavingData()}
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </div>
  );
};

CreateImport.propTypes = {
  type: PropTypes.string,
  onCancel: PropTypes.func,
  setUseFullScreen: PropTypes.func,
  setShowImportSuccess: PropTypes.func,
  onImportSuccess: PropTypes.func,
  onPrevious: PropTypes.func,
  opts: PropTypes.object,
  skipValidation: PropTypes.bool,
  skipOnSuccessModalToggle: PropTypes.bool,
  validationMessage: PropTypes.string
};

CreateImport.defaultProps = {
  onCancel: () => {},
  setUseFullScreen: () => {},
  setShowImportSuccess: () => {},
  onImportSuccess: () => {}
};

export default CreateImport;
