import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {CarrierAssignment, ShipwellError} from '@shipwell/backend-core-sdk';
import {updateCarrierAssignment} from 'App/api/invoicing/typed';

type UpdateCarrierAssignmentParams = {
  carrierAssignmentId: string;
  carrierAssignment: CarrierAssignment;
};

const useUpdateCarrierAssignment = (
  shipmentId: string,
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCarrierAssignment>>,
    ShipwellError,
    UpdateCarrierAssignmentParams
  >
) =>
  useMutation(
    async ({carrierAssignmentId, carrierAssignment}) =>
      await updateCarrierAssignment(shipmentId, carrierAssignmentId, carrierAssignment),
    {...mutationOptions}
  );

export default useUpdateCarrierAssignment;
