import {useFlags} from 'launchdarkly-react-client-sdk';
import {OverflowMenu} from '@shipwell/shipwell-ui';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';

export const CreateFreightInvoiceLineItemButton = ({
  role,
  onClick,
  shipmentId
}: {
  role: SettlementsDashboardGeneratedBy;
  onClick: () => void;
  shipmentId: string;
}) => {
  const {stmGenerateInvoiceOnBehalfOfCarrier} = useFlags();
  const {prefetch: prefetchShipment} = useGetFullShipmentDetails(shipmentId);
  if (role === SettlementsDashboardGeneratedBy.BookingParty && !stmGenerateInvoiceOnBehalfOfCarrier) {
    return null;
  }
  return (
    <OverflowMenu.Item onClick={onClick} onMouseEnter={() => void prefetchShipment()}>{`Create ${
      role === SettlementsDashboardGeneratedBy.BookingParty ? 'Carrier' : ''
    } Invoice`}</OverflowMenu.Item>
  );
};
