import {CREATE_TENDER, GET_TENDERS_SUCCESS} from '../actions/types';
export const INITIAL_STATE = {
  tenderRequest: null,
  tenderRequests: [],
  isLoading: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_TENDER:
      return {
        ...state,
        tenderRequest: action.payload,
        isLoading: false
      };
    case GET_TENDERS_SUCCESS:
      return {
        ...state,
        tenderRequests: action.payload.results,
        isLoading: false
      };
    default:
      return state;
  }
}
