import {IconButton, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Shipment, Stop} from '@shipwell/backend-core-singlerequestparam-sdk';
import useToggle from 'App/utils/hooks/useToggle';
import {getStringifiedStopsWithRequiredKeys} from 'App/components/pricingIntelChart/utils';
import {PRICING_INTEL_REQUIRED_ADDRESS_KEYS} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {useCarriersPriceHistory} from 'App/data-hooks';
import TenderRequestForm from 'App/containers/tendering/create';
import DisplayCarriers from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayCarriers';
import {mapHistoricalPrices} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {TENDERS_KEY} from 'App/data-hooks/queryKeys';

const MAX_AMOUNT_OF_LANES_TO_DISPLAY = 20;

const LaneHistoryTooltipText = () => {
  return (
    <div>
      <div className="text-bold">Lane History</div>
      <div>This shows up to the last {MAX_AMOUNT_OF_LANES_TO_DISPLAY} lane runs, to see the full history select</div>
      <div>View Pricing Details.</div>
    </div>
  );
};

const LaneHistory = ({
  shipment,
  stops,
  setSuccess,
  isFTLShipment,
  showDPM,
  totalMiles
}: {
  shipment: Shipment;
  stops: Stop[];
  isFTLShipment: boolean;
  showDPM?: boolean;
  totalMiles?: number | null;
} & WithStatusToastProps) => {
  const [showTenderModal, toggleShowTenderModal] = useToggle(false);
  const [laneHistoryExpanded, toggleLaneHistoryExpanded] = useToggle(true);
  const [selectedCarrierId, setSelectedCarrierId] = useState<string>();
  const queryClient = useQueryClient();
  const equipmentType = shipment?.equipment_type?.machine_readable;
  const stopsPayload = stops.map((s) => {
    return {address: s.location.address};
  });
  const stringifiedStops = getStringifiedStopsWithRequiredKeys(stopsPayload, PRICING_INTEL_REQUIRED_ADDRESS_KEYS);
  const params = {
    page: 1,
    limit: MAX_AMOUNT_OF_LANES_TO_DISPLAY
  };

  const {data: historicalPriceData, isInitialLoading: isLoadingPrices} = useCarriersPriceHistory({
    equipmentType,
    stringifiedStops,
    params
  });

  const priceData = historicalPriceData?.data.prices || [];

  const handleSelectCarrier = (carrierId?: string) => {
    setSelectedCarrierId(carrierId);
    toggleShowTenderModal();
  };

  return (
    <>
      <div className="flex items-center justify-between border-y-1 border-sw-border px-3 py-2">
        <div className="flex items-center">
          <div className="text-bold mr-1">Lane History</div>
          {isFTLShipment ? (
            <Tooltip
              placement="top"
              tooltipClassname="w-64"
              tooltipContent={<LaneHistoryTooltipText />}
              trigger="hover"
              portal
            >
              <SvgIcon color="$sw-icon" name="InfoOutlined" />
            </Tooltip>
          ) : null}
        </div>
        <IconButton
          iconName={laneHistoryExpanded ? 'ExpandLess' : 'ExpandMore'}
          aria-label={laneHistoryExpanded ? 'Hide lane history' : 'Expand lane history'}
          onClick={() => toggleLaneHistoryExpanded()}
        />
      </div>
      <DisplayCarriers
        isLoading={isLoadingPrices}
        shipment={shipment}
        isSectionExpanded={laneHistoryExpanded}
        handleSelectCarrier={handleSelectCarrier}
        carriers={mapHistoricalPrices(priceData)}
        isFTLShipment={isFTLShipment}
        showDPM={showDPM}
        totalMiles={totalMiles}
      />
      <TenderRequestForm
        show={showTenderModal}
        onClose={() => toggleShowTenderModal()}
        onSuccess={() => {
          void queryClient.invalidateQueries([TENDERS_KEY]);
          setSuccess('Success!', 'Tender request sent to carrier.');
          toggleShowTenderModal();
        }}
        submitSucceeded
        selectedCarrier={{id: selectedCarrierId}}
        shipmentDetailData={shipment}
      />
    </>
  );
};

export default WithStatusToasts(LaneHistory);
