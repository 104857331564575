import {SvgIcon} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';
import {startCaseToLower} from 'App/utils/startCaseToLower';

export const CarrierStatusCell = ({status}: {status?: string | null}) => {
  const textColorClass = () => {
    switch (status) {
      case 'INACTIVE':
        return 'text-sw-disabled-text';
      case 'DO_NOT_USE':
        return 'text-sw-error';
      default:
        return 'text-sw-text';
    }
  };
  if (!status) {
    return <>--</>;
  }
  return (
    <FlexBox items="center" gap="s">
      {status === 'DO_NOT_USE' ? <SvgIcon name="ErrorOutlined" color="sw-error" /> : null}
      <span className={textColorClass()}>{startCaseToLower(status)}</span>
    </FlexBox>
  );
};
