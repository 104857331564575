import {Banner} from '@shipwell/shipwell-ui';
import {CustomDataGroupCard} from './components';
import {EntityTypeGroupType, entityTypeGroups} from './config';
import {CompanyIdProvider} from './context';
import useCustomFields from 'App/data-hooks/customFields/useCustomFields';
import Loader from 'App/common/shipwellLoader';

const entityTypeGroupsDisplayOrder: EntityTypeGroupType[] = [
  'reference',
  'customer',
  'lineItem',
  'chargeLineItem',
  'stop'
];

const CustomDataBase = () => {
  const {customFields, isLoading, isError} = useCustomFields();

  if (isLoading) return <Loader loading />;

  return (
    <div className="mx-auto w-full max-w-5xl  p-4">
      {isError ? <Banner title="Error">There was an error getting the custom fields.</Banner> : null}

      <div className="grid gap-4 pb-12 ">
        {entityTypeGroupsDisplayOrder.map((groupType) => (
          <CustomDataGroupCard
            key={groupType}
            groupType={groupType}
            customFields={customFields.filter((field) =>
              field.entity_types?.some((fieldType) => entityTypeGroups[groupType].entityTypes.includes(fieldType))
            )}
          />
        ))}
      </div>
    </div>
  );
};

export const CustomData = () => (
  <CompanyIdProvider>
    <CustomDataBase />
  </CompanyIdProvider>
);
