import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router';
import get from 'lodash/get';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {generateBOL, generateRateConfirmation} from 'App/actions/documents';
import withStatusToasts from 'App/components/withStatusToasts';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {useGenerateBolPdf} from 'App/data-hooks/shipments/useGenerateBolPdf';
import {downloadFile} from 'App/utils/fileUtilsTyped';

export const GENERATE_BOL = 'BOL';
export const GENERATE_BOL_PDF = 'BOL (PDF Generator)';
export const GENERATE_RATE_CONFIRMATION = 'Rate Confirmation';

const GenerateDocAction = ({
  shipment,
  toGenerate,
  generateBOL,
  generateRateConfirmation,
  router,
  canEdit,
  setError
}) => {
  const [generatingDoc, setGeneratingDoc] = useState(false);
  const {generateBolPdf} = useGenerateBolPdf(shipment);

  const handleGenerateDocClick = async () => {
    setGeneratingDoc(true);
    try {
      if (toGenerate === GENERATE_BOL_PDF) {
        const generatedPdfResponse = await generateBolPdf();
        //Response base 64 comes from the external API as a string, so we have to convert it back to bytes so we can create the file with new Blob
        const binaryString = window.atob(generatedPdfResponse.response);
        const pdfBytes = new Uint8Array(binaryString.length).map((byte, i) => binaryString.charCodeAt(i));
        const blob = new Blob([pdfBytes], {type: 'application/pdf'});
        downloadFile({blob, escapedFileName: generatedPdfResponse.meta.name});
      } else {
        const generateDocMethod = toGenerate === GENERATE_BOL ? generateBOL : generateRateConfirmation;
        const response = await generateDocMethod(shipment.id);
        router.push(`/shipments/${shipment.id}/documents/${response.details.id}`);
      }
    } catch (e) {
      const message = `Error generating ${toGenerate} for ${shipment.id}`;
      console.error(message, e);
      setError(message);
      setGeneratingDoc(false);
    }
  };

  return (
    <DeprecatedButton disabled={generatingDoc || !canEdit} variant="text" onClick={handleGenerateDocClick}>
      {`Generate ${toGenerate}`}
    </DeprecatedButton>
  );
};

GenerateDocAction.propTypes = {
  shipment: PropTypes.object,
  toGenerate: PropTypes.oneOf([GENERATE_BOL, GENERATE_RATE_CONFIRMATION]).isRequired,
  generateBOL: PropTypes.func,
  generateRateConfirmation: PropTypes.func,
  router: PropTypes.object,
  canEdit: PropTypes.bool,
  setError: PropTypes.func
};

GenerateDocAction.defaultProps = {
  shipment: {},
  generateBOL: () => {},
  generateRateConfirmation: () => {},
  router: {},
  canEdit: false
};

export default compose(
  withStatusToasts,
  withRouter,
  connect(
    (state) => ({
      canEdit:
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)
    }),
    (dispatch) => bindActionCreators({generateBOL, generateRateConfirmation}, dispatch)
  )
)(GenerateDocAction);
