import {Modal} from '@shipwell/shipwell-ui';
import {ShipperTenderDetailsController} from 'App/containers/shipments/details/TenderDetails';
import {ActionConfirmationModal} from 'App/containers/shipments/components/TenderView';
import useToggle from 'App/utils/hooks/useToggle';

export const ShipperTenderDetailsModal = ({
  showModal,
  onClose,
  shipmentId,
  transportationOrderId
}: {
  showModal: boolean;
  onClose(): void;
  shipmentId: string;
  transportationOrderId: string;
}) => {
  const [showActionConfirmationModal, toggleShowActionConfirmationModal] = useToggle();

  return (
    <>
      <Modal
        show={showModal}
        onClose={onClose}
        title="Tender Request"
        size="large"
        footerComponent={null}
        bodyVariant="disableOverflowScroll"
      >
        <ShipperTenderDetailsController
          onActionSelect={() => {
            onClose();
            toggleShowActionConfirmationModal();
          }}
          shipmentId={shipmentId}
          transportationOrderId={transportationOrderId}
        />
      </Modal>
      <ActionConfirmationModal
        shipmentId={shipmentId}
        transportationOrderId={transportationOrderId}
        show={showActionConfirmationModal}
        onClose={toggleShowActionConfirmationModal}
      />
    </>
  );
};
