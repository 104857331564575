import {FormEventHandler, JSX} from 'react';
import {Formik, FormikValues, Field, FormikHelpers} from 'formik';
import {object, string, ObjectSchema} from 'yup';
import {FormikTextInput, Card} from '@shipwell/shipwell-ui';
import {Assign, ObjectShape} from 'yup/lib/object';
import FormFooter from 'App/formComponents/formSections/formFooter';
import './styles.scss';

/** FedEx Registration validation rules */
export const validationSchema = object().shape({
  account_number: string()
    .test('acount_number', 'account number is requred', (val) => Boolean(val && val?.length >= 0 && val?.length <= 10))
    .required('Account ID is required.')
});

// for useMutation only one argument can be passed as type TVariables.
// this is the only way to support getting all the data we need
// in the callback.
export interface FormikSubmitEventData {
  values: FormikValues;
  helpers: FormikHelpers<FormikValues>;
}

export interface FormikFormSubmitHandler {
  (formData: FormikSubmitEventData): Promise<void> | void;
}

export interface UberFreightRegistrationFormPropTypes {
  values: FormikValues;
  defaultValues?: FormikValues;
  validationSchema?: ObjectSchema<Assign<ObjectShape, FormikValues>>;
  isEditing?: boolean;
  onCancel: () => void;
  onSubmit: FormikFormSubmitHandler;
}

/** Default FedEx Registration values */
export const defaultValues = {
  account_number: ''
};

/**
 * Fedex Account Registration Form
 * @param {*} props
 */
const UberFreightRegistrationForm = (props: UberFreightRegistrationFormPropTypes): JSX.Element => {
  const {onSubmit, onCancel, values, defaultValues} = props;

  const handleSubmit = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    await onSubmit({
      values,
      helpers
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={props.validationSchema}
      onSubmit={handleSubmit}
      initialValues={{...defaultValues, ...values}}
    >
      {(innerProps: {
        dirty?: boolean;
        isValid?: boolean;
        isSubmitting?: boolean;
        handleSubmit: FormEventHandler<HTMLFormElement>;
      }) => {
        return (
          <form className="uber-registration-form" noValidate onSubmit={innerProps.handleSubmit}>
            <Card title="Account" draggableProvided={null}>
              <div className="field-grid">
                <Field required name="account_number" label="Account Number" component={FormikTextInput} />
              </div>
            </Card>
            {innerProps.dirty && (
              <FormFooter
                primaryActionName={props.isEditing ? 'Update Uber Freight Account' : 'Connect Uber Freight'}
                isValid={innerProps.isValid}
                isSubmitting={innerProps.isSubmitting}
                onCancel={() => onCancel()}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default UberFreightRegistrationForm;
