import {Field, Formik} from 'formik';
import {Button, FormikCheckbox, FormikSelect, FormikTextInput, Rule, SvgIcon, Title} from '@shipwell/shipwell-ui';
import {GlobalSettingsRequest} from '@shipwell/tabula-sdk';
import {equipmentTypesByModeFilter} from 'App/utils/filterEquipmentTypes';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {preferredContactMethod, weightUnit} from 'App/containers/Shipment/components/LoadBoards/Edit/utils';
import useGetGlobalSettings from 'App/api/loadboards/hooks/useGetGlobalSettings';
import useUpdateGlobalSettings from 'App/api/loadboards/hooks/useUpdateGlobalSettings';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';

/**
 * Dat Settings Form
 */
const DatSettingsForm = ({setSuccess, setError}: WithStatusToastProps) => {
  const {data} = useGetGlobalSettings();

  const {mutate: updateSettings, isLoading} = useUpdateGlobalSettings({
    onSuccess: () => {
      setSuccess('Success!', 'Settings updated.');
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const initialValues = {
    enable_book_now: data?.enable_book_now || false,
    loadposts: {
      equipment_type: data?.loadposts?.equipment_type || '',
      primary_contact: data?.loadposts?.primary_contact || '',
      gross_weight: {
        value: data?.loadposts?.gross_weight?.value || '',
        unit: data?.loadposts?.gross_weight?.unit || ''
      }
    },
    rating: {
      enable_spot: data?.rating?.enable_spot || false,
      enable_forecast: data?.rating?.enable_forecast || false,
      enable_contract: data?.rating?.enable_contract || false
    }
  } as GlobalSettingsRequest;

  const equipmentQuery = useEquipmentTypesQuery();

  const equipmentTypeOptions = (equipmentQuery?.data || []).filter(equipmentTypesByModeFilter()).map((item) => ({
    value: item.machine_readable,
    label: item.name
  }));

  const onSubmit = (values: GlobalSettingsRequest) => {
    updateSettings(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({handleSubmit}) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className="py-6">
            <Title variant="formTitle" className="mb-6">
              Load Post Defaults
            </Title>
            <div className="grid grid-cols-2 gap-4">
              <Field
                simpleValue
                searchable={false}
                label="Equipment"
                name="loadposts.equipment_type"
                component={FormikSelect}
                options={equipmentTypeOptions}
              />
              <div className="one-row-fields">
                <Field label="Weight" name="loadposts.gross_weight.value" component={FormikTextInput} />
                <div className="max-w-[80px]">
                  <Field
                    simpleValue
                    searchable={false}
                    name="loadposts.gross_weight.unit"
                    options={weightUnit}
                    component={FormikSelect}
                    className="min-w-0"
                    clearable={false}
                  />
                </div>
              </div>
              <Field
                simpleValue
                searchable={false}
                clearable={false}
                label="Primary Contact"
                name="loadposts.primary_contact"
                component={FormikSelect}
                options={preferredContactMethod}
              />
            </div>
          </div>
          <Rule />
          <div className="py-4">
            <Title variant="formTitle" className="mb-5">
              Load Board Settings
            </Title>
            <Field label="Enable Book Now" name="enable_book_now" component={FormikCheckbox} />
          </div>
          <Rule />
          <div className="py-4">
            <Title variant="formTitle" className="mb-5">
              Rating Settings
            </Title>
            <div className="grid-1-12 grid">
              <Field label="Spot" name="rating.enable_spot" component={FormikCheckbox} />
              <Field label="Forecast" name="rating.enable_forecast" component={FormikCheckbox} />
              <Field label="Contract" name="rating.enable_contract" component={FormikCheckbox} />
            </div>
          </div>
          <div className="mb-5 flex justify-end">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <SvgIcon name="LoadingDots" /> : 'Save'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default WithStatusToasts(DatSettingsForm);
