import {
  PurchaseOrdersApiPurchaseOrdersGetRequest,
  PurchaseOrdersApi,
  Configuration,
  PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdPutRequest,
  PurchaseOrdersApiPurchaseOrdersPostRequest,
  PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdGetRequest,
  PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDeleteRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const purchaseOrdersApi = new PurchaseOrdersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export function deletePurchaseOrderLineItem(
  purchaseOrderId: PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDeleteRequest['purchaseOrderId'],
  purchaseOrderLineItemId: PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDeleteRequest['purchaseOrderLineItemId'],
  options = {}
) {
  return purchaseOrdersApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDelete(
    {
      purchaseOrderId,
      purchaseOrderLineItemId
    },
    options
  );
}

export function getPurchaseOrderById(
  purchaseOrderId: PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdGetRequest['purchaseOrderId']
) {
  return purchaseOrdersApi.purchaseOrdersPurchaseOrderIdGet({purchaseOrderId});
}

export function createPurchaseOrder(purchaseOrder: PurchaseOrdersApiPurchaseOrdersPostRequest['purchaseOrder']) {
  return purchaseOrdersApi.purchaseOrdersPost({purchaseOrder});
}

export function updatePurchaseOrderById({
  purchaseOrderId,
  purchaseOrder
}: PurchaseOrdersApiPurchaseOrdersPurchaseOrderIdPutRequest) {
  return purchaseOrdersApi.purchaseOrdersPurchaseOrderIdPut({purchaseOrderId, purchaseOrder});
}

export function getPurchaseOrders(requestParameters?: PurchaseOrdersApiPurchaseOrdersGetRequest) {
  return purchaseOrdersApi.purchaseOrdersGet(requestParameters);
}
