/* global $, google */
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, change, FormSection} from 'redux-form';
import {Form, Button, Row, Col} from 'react-bootstrap';
import renderMultiSelectList from 'App/formComponents/renderMultiSelectList';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderField from 'App/formComponents/renderField';
import renderErrorField from 'App/formComponents/renderErrorField';
import './_mode.scss';
import * as actions from 'App/actions/shipments';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

export default class Multiplier extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="mode">
        <h2 className="border-bottom">Options</h2>
        <div className="formstyle-light">
          <div className="quote-flow__mode">
            <Field
              name="multiplier"
              component={renderField}
              label="Total # of Shipments"
              req={false}
              placeholder="Enter total number to create"
            />
          </div>
        </div>
      </div>
    );
  }
}
