import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import {compose} from 'recompose';
import snakeCase from 'lodash/snakeCase';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {
  PREDICTIVE_INTERNAL,
  PREDICTIVE_MARKET,
  PRICING_INTEL_DATA_STATE_LOADING,
  embeddedPricingVariantsBySource,
  BOOK_NOW_BUTTON_NAME,
  PRICING_INTEL_SOURCE_NEW_SHIPMENT,
  PRICING_INTEL_SOURCE_NEW_QUOTE,
  METADATA_BUY_IT_NOW_FIELD_KEY,
  METADATA_MAX_BUY_FIELD_KEY,
  MAX_BUY_BUTTON_NAME
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import EmbeddedPricingTile from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingTile';
import ClickToCopy from 'App/components/clickToCopy';
import PricingIntelConfidenceScore from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingPredictivePricing/pricingIntelConfidenceScore';
import withFlags from 'App/utils/withFlags';
import './styles.scss';

const EmbeddedPredictivePricing = ({
  predictivePrices,
  predictivePricingVariant,
  handleSelectPriceSuggestion,
  predictivePricingDataState,
  pricingIntelConfidenceScore,
  pageSource
}) => {
  const [predictiveConfidenceScore, setPredictiveConfidenceScore] = useState('');
  const {shipwellInternalShipper = {}, shipwellMarketCarrier = []} = predictivePrices;
  const predictiveVariantAttributes = embeddedPricingVariantsBySource[predictivePricingVariant];
  const variantPriceValue =
    predictivePricingVariant === PREDICTIVE_MARKET
      ? get(shipwellMarketCarrier, predictiveVariantAttributes.accessor)
      : predictivePricingVariant === PREDICTIVE_INTERNAL
      ? get(shipwellInternalShipper, predictiveVariantAttributes.accessor)
      : 0;
  //check if we have valid pricing estimates to enable display
  const isValidPriceEstimate = variantPriceValue > 0;

  useEffect(() => {
    if (shipwellInternalShipper.confidence_category) {
      return setPredictiveConfidenceScore(snakeCase(shipwellInternalShipper.confidence_category));
    }
    setPredictiveConfidenceScore('');
  }, [shipwellInternalShipper]);

  return (
    <EmbeddedPricingTile
      title={predictiveVariantAttributes.contentTitle}
      tooltipContent={predictiveVariantAttributes.tooltipContent}
      loading={predictivePricingDataState === PRICING_INTEL_DATA_STATE_LOADING}
      containerClassNames={[
        pricingIntelConfidenceScore && predictivePricingVariant === PREDICTIVE_INTERNAL && predictiveConfidenceScore
          ? predictiveConfidenceScore
          : null
      ]}
    >
      {isValidPriceEstimate ? (
        <div className="embeddedPredictive__container">
          <div className="flex items-center">
            <span className="embeddedPredictive__reference">${Math.round(variantPriceValue)}</span>
            <div className="ml-1 flex">
              {/*Display apply to book now/max buy on new quote and shipment */}
              {[PRICING_INTEL_SOURCE_NEW_QUOTE, PRICING_INTEL_SOURCE_NEW_SHIPMENT].includes(pageSource) &&
              handleSelectPriceSuggestion ? (
                <>
                  <DeprecatedButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      return handleSelectPriceSuggestion(METADATA_BUY_IT_NOW_FIELD_KEY, Math.round(variantPriceValue));
                    }}
                  >
                    {BOOK_NOW_BUTTON_NAME}
                  </DeprecatedButton>
                  <DeprecatedButton
                    variant="secondary"
                    size="small"
                    onClick={() =>
                      handleSelectPriceSuggestion(METADATA_MAX_BUY_FIELD_KEY, Math.round(variantPriceValue))
                    }
                    className="ml-1"
                  >
                    {MAX_BUY_BUTTON_NAME}
                  </DeprecatedButton>
                </>
              ) : (
                /* Otherwise display click to copy */
                <ClickToCopy
                  value={Math.round(variantPriceValue).toString()}
                  onClick={() => {
                    /* No Op since we removed analytics */
                  }}
                />
              )}
            </div>
          </div>
          {pricingIntelConfidenceScore &&
            predictivePricingVariant === PREDICTIVE_INTERNAL &&
            predictivePrices.shipwellInternalShipper && (
              <PricingIntelConfidenceScore
                myPredictedPrice={predictivePrices.shipwellInternalShipper}
                setPredictiveConfidenceScore={setPredictiveConfidenceScore}
              />
            )}
        </div>
      ) : (
        <span className={classnames('embeddedPredictive__reference', 'embeddedPricing__disabled')}>$--</span>
      )}
    </EmbeddedPricingTile>
  );
};

EmbeddedPredictivePricing.propTypes = {
  handleSelectPriceSuggestion: PropTypes.func,
  predictivePrices: PropTypes.shape({
    shipwellInternalShipper: PropTypes.object,
    shipwellMarketCarrier: PropTypes.array
  }),
  predictivePricingDataState: PropTypes.string,
  predictivePricingVariant: PropTypes.oneOf([PREDICTIVE_INTERNAL, PREDICTIVE_MARKET]).isRequired,
  pricingIntelConfidenceScore: PropTypes.bool,
  pageSource: PropTypes.string.isRequired
};

EmbeddedPredictivePricing.defaultProps = {
  handleSelectPriceSuggestion: () => {},
  predictivePrices: {},
  predictivePricingVariant: '',
  priceSuggestionButtonContent: '',
  pricingIntelConfidenceScore: false,
  pageSource: ''
};

export default compose(withFlags('pricingIntelConfidenceScore'))(EmbeddedPredictivePricing);
