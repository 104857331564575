import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {LoadPostResponse} from '@shipwell/tabula-sdk';
import {getListLoadPosts} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

const useGetListLoadPosts = (shipmentId?: string, options?: UseQueryOptions<LoadPostResponse[]>) => {
  const response = useQuery(
    [LOADPOST_KEY] as QueryKey,
    async () => {
      const response = await getListLoadPosts(shipmentId);
      return response.data;
    },
    options
  );

  return response;
};

export default useGetListLoadPosts;
