import {User} from '@shipwell/backend-core-sdk';
import {SvgIcon, ToggleSwitch} from '@shipwell/shipwell-ui';
import {LoadboardUser} from '@shipwell/tabula-sdk';
import classNames from 'classnames';
import {useMemo} from 'react';

export const DAT = 'dat';

type TooltipContentProps = {
  permissions: string[];
};

export type Original = {
  id: string;
  company_id: string;
  last_login: string;
  timezone: string;
  first_name: string;
  last_name: string;
  email: string;
  dat: {
    is_active: boolean;
    created_at: string;
  };
};

export type UserData = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export const EmptyState = () => (
  <div className="flex flex-col">
    <span className="text-lg font-bold">No Users</span>
    <span className="text-grey-4 text-sm">Enable the integration to begin adding users</span>
  </div>
);

export const TooltipContent = ({permissions}: TooltipContentProps) => {
  return (
    <div>
      <p className="mb-2 text-sm font-bold">Permissions</p>
      {permissions.map((permission, index) => (
        <p key={index} className="text-capitalize mb-0.5 text-sm">
          {permission}
        </p>
      ))}
    </div>
  );
};

export const mergeUsersInfo = (users: User[], datUsers: LoadboardUser[]) => {
  const mergedUsers = (users || []).map((user) => {
    const datUser = datUsers.find((datUser) => user?.id === datUser?.sw_user_id);

    if (datUser) {
      return {
        ...user,
        dat: datUser
      };
    }

    return user;
  });

  return mergedUsers;
};

export type SwitchProps = {
  original: Original;
  value: string;
  handleChange: (checked: boolean, userData: UserData) => void;
  isConnected: boolean;
  isLoading: boolean;
};

export const Switch = ({original, value, handleChange, isConnected, isLoading}: SwitchProps) => {
  const checked = useMemo(() => Boolean(original.dat?.is_active), [original]);

  const userData = {
    id: original.id,
    first_name: original.first_name,
    last_name: original.last_name,
    email: original.email
  };

  const onClick = () => handleChange(checked, userData);

  if (isConnected) {
    return (
      <span
        role="button"
        className={classNames('flex items-center gap-2', {'text-sw-disabled': !checked})}
        onClick={onClick}
      >
        <span className="pointer-events-none">
          <ToggleSwitch name={value} fixedHeight={false} isSmall checked={checked} />
        </span>
        <span>{value}</span>
        {isLoading ? (
          <SvgIcon height="20" width="20" className="animate-slow-spin text-sw-primary" name="Running" />
        ) : null}
      </span>
    );
  }

  return (
    <span className="flex items-center gap-2 text-sw-disabled">
      <ToggleSwitch name={value} fixedHeight={false} isSmall checked={false} disabled />
      {value}
    </span>
  );
};
