import {
  USER_LOADBOARD_CONFIG_REQUEST_PENDING,
  USER_LOADBOARD_CONFIG_REQUEST_SUCCESS,
  USER_LOADBOARD_CONFIG_UPDATE_PENDING,
  USER_LOADBOARD_CONFIG_UPDATE_SUCCESS
} from '../../../actions/types';

export const ALL_LOADBOARD_COLUMNS = [
  'buy_it_now_amount',
  'lowest_bid_amount',
  'bidding_status',
  'load_board_id',
  'customer',
  'pickup_location',
  'pickup_date',
  'delivery_location',
  'delivery_date',
  'stops',
  'requested_shipment_modes',
  'requested_equipment_types',
  'weight',
  'created_at',
  //'rate_per_mile', NOT IMPLEMENTED
  'total_miles',
  //'deadhead_miles', NOT IMPLEMENTED
  'match_score',
  'expiresAt',
  'actions'
];

const DEFAULT_COLUMNS = [
  'buy_it_now_amount',
  'lowest_bid_amount',
  'bidding_status',
  'load_board_id',
  'match_score',
  'pickup_location',
  'pickup_date',
  'delivery_location',
  'delivery_date',
  'stops',
  'total_miles',
  'customer',
  'requested_shipment_modes',
  'requested_equipment_types',
  'weight',
  'created_at',
  'expiresAt',
  'actions'
  //'rate_per_mile'
  //'deadhead_miles',
];

export const LOADBOARD_COLUMN_NAMES = {
  load_board_id: 'Load ID',
  lowest_bid_amount: 'Your Bid',
  bidding_status: 'Status',
  customer: 'Shipper',
  pickup_location: 'Pickup',
  pickup_date: 'Pickup Date',
  delivery_location: 'Delivery',
  delivery_date: 'Delivery Date',
  stops: 'Stops',
  requested_shipment_modes: 'Mode',
  requested_equipment_types: 'Equipment',
  line_items: 'Items',
  weight: 'Weight',
  total_miles: 'Miles',
  rate_per_mile: '$/Mile',
  created_at: 'Created',
  buy_it_now_amount: 'Book Now',
  match_score: 'Match',
  expiresAt: 'Tender Expires',
  actions: 'actions'
};

export const FILTERABLE_LOADBOARD_COLUMNS = [
  'bidding_status',
  'pickup_location',
  'delivery_location',
  'customer',
  'pickup_date',
  'delivery_date',
  'expiresAt',
  'requested_shipment_modes',
  'requested_equipment_types'
];

const INITIAL_STATE = {
  isFetching: false,
  isSaving: false,
  allColumns: ALL_LOADBOARD_COLUMNS,
  defaultColumns: DEFAULT_COLUMNS,
  defaultConfig: null,
  configs: [],
  configsById: {},
  columnNamesById: LOADBOARD_COLUMN_NAMES
};

export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    /**
     * User LOADBOARD request actions
     */
    case USER_LOADBOARD_CONFIG_REQUEST_PENDING:
      return Object.assign({}, state, {
        isFetching: true
      });

    case USER_LOADBOARD_CONFIG_REQUEST_SUCCESS:
      const results = payload && payload.results;
      const defaultConfig = results.filter((e) => e.is_default)[0];
      return Object.assign({}, state, {
        isFetching: false,
        configs: results,
        defaultConfig: defaultConfig,
        configsById: results.reduce((map, result) => {
          result.config.columns = !result.config.columns.length ? ALL_LOADBOARD_COLUMNS : result.config.columns;
          return Object.assign({}, map, {[result.id]: result});
        }, {})
      });

    /**
     * User LOADBOARD update actions
     */
    case USER_LOADBOARD_CONFIG_REQUEST_PENDING:
      return Object.assign({}, state, {
        isSaving: true
      });

    case USER_LOADBOARD_CONFIG_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        configsById: Object.assign({}, state.configsById, {
          [payload.id]: payload
        })
      });

    default:
      return state;
  }
};
