import {MouseEvent, useEffect, useState} from 'react';
import {Formik} from 'formik';
import {object, string} from 'yup';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';

import DatFields from 'App/formComponents/formSections/datFields';
import useCreateLoadboard from 'App/api/loadboards/hooks/useCreateLoadboard';
import {DAT} from 'App/containers/integrations/details/components/datDetails/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';

type ValuesProps = {
  key: string;
  secret: string;
};

type DatFormProps = {
  values: ValuesProps;
  isActive: boolean;
  setIsActive: (val: boolean) => void;
};

/** Default values needed for Formik */
const defaultFormValues = {
  key: '',
  secret: ''
};

export const validationSchema = object().shape({
  key: string().required('Username or email is required.').nullable(),
  secret: string().required('Password is required.').nullable()
});

/**
 * Dat Form
 */
const DatForm = ({values, isActive, setIsActive, setSuccess, setError}: DatFormProps & WithStatusToastProps) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    setConnected(isActive);
  }, [isActive]);

  const {mutate: createLoadboard, isLoading} = useCreateLoadboard({
    onSuccess: () => {
      setIsActive(true);
      setSuccess('Success!', 'You are now connected to DAT.');
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const initialValues = {
    ...defaultFormValues,
    ...values
  };

  const handleSubmit = (values: ValuesProps) => {
    return createLoadboard({
      loadboardType: DAT,
      createAuthRequest: {
        credentials: values,
        is_user: false
      }
    });
  };

  const onEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConnected(false);
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({handleSubmit}) => (
        <form noValidate onSubmit={handleSubmit}>
          <DatFields disabled={connected} />
          <div className="flex justify-end">
            {connected ? (
              <DeprecatedButton type="button" onClick={onEdit}>
                Edit
              </DeprecatedButton>
            ) : (
              <DeprecatedButton type="submit" variant="primary">
                {isLoading ? <SvgIcon name="LoadingDots" /> : 'Enable'}
              </DeprecatedButton>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default WithStatusToasts(DatForm);
