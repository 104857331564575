/* global $, google */
import _ from 'lodash';
import {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, change, SubmissionError} from 'redux-form';
import {Row, Col, Form, Button, Modal, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import {bind} from 'App/utils/camelize';
import creatable from 'App/formComponents/fields/creatable';
import {validatePhoneNumber} from 'App/utils/globals';
import select from 'App/formComponents/fields/select';
import * as actions from 'App/actions/shipments';
import * as carrierActions from 'App/actions/carriers';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect(
  (state) => ({
    company: state.auth.company,
    powerUnits: state.carriers.powerUnits,
    trailers: state.carriers.trailers,
    drivers: state.carriers.drivers,
    newLoadForm: state.form.newLoadForm
  }),
  {...actions, ...carrierActions}
)
export default class EquipmentConfig extends Component {
  constructor(props) {
    super(props);

    bind(this, ['getAssets']);

    this.state = {
      driverList: [],
      powerUnitPagination: {
        page: 1,
        pageSize: 200,
        ordering: 'name'
      },
      trailerPagination: {
        page: 1,
        pageSize: 200,
        ordering: 'name'
      },
      driverPagination: {
        page: 1,
        pageSize: 200
      }
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getAssets();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company && !_.isEmpty(this.props.company)) {
      this.getAssets();
    }
  }

  getAssets() {
    this.props.getPowerUnits(this.props.company.carrier.id, this.state.powerUnitPagination);
    this.props.getTrailers(this.props.company.carrier.id, this.state.trailerPagination);
    this.props.getDrivers(this.props.company.id, this.state.driverPagination).then((response) => {
      if (response.status === 200) {
        const driverList = [];

        if (this.props.drivers) {
          for (let i = 0; i < this.props.drivers.results.length; i++) {
            if (this.props.drivers.results[i].phone_number) {
              driverList.push({
                phone_number: this.props.drivers.results[i].phone_number,
                name: this.props.drivers.results[i].user.first_name + ' ' + this.props.drivers.results[i].user.last_name
              });
            }
          }
        }
        this.setState({driverList: driverList});
      }
    });
  }

  render() {
    const {powerUnits, trailers, drivers, newLoadForm} = this.props;

    return (
      <div className="quote-flow__carrier">
        <h2 className="border-bottom">Driver & Assets</h2>
        <div className="quote-flow__carrierEquipment">
          <Element name={`position-equipment_config.driver.phone_number`} />
          <Field
            component={creatable}
            options={this.state.driverList}
            name="equipment_config.driver.phone_number"
            labelKey="name"
            valueKey="phone_number"
            label="Driver"
            placeholder="Select a driver or enter a number"
            simpleValue
            onNewOptionClick={(value) => {
              let phoneNum;
              const cleaned = ('' + value.phone_number).replace(/\D/g, '');
              const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
              if (match) {
                phoneNum = ['+1', match[2], match[3], match[4]].join('');
              }
              if (validatePhoneNumber(phoneNum)) {
                const obj = {name: phoneNum, phone_number: phoneNum};
                let inArray = false;
                const driverList = this.state.driverList;
                for (let i = 0; i < driverList.length; i++) {
                  if (driverList[i] === obj) {
                    inArray = true;
                  }
                }
                if (!inArray) {
                  driverList.push(obj);
                  this.setState({driverList});
                  return phoneNum;
                }
              }
              return null;
            }}
          />
          {newLoadForm && newLoadForm.values && !newLoadForm.values.isManual && (
            <Fragment>
              <Element name={`position-equipment_config.power_unit`} />
              <Field
                component={select}
                options={powerUnits && powerUnits.results && powerUnits.results.length > 0 ? powerUnits.results : []}
                name="power_unit"
                labelKey="name"
                valueKey="id"
                label="Power Unit"
                placeholder="Select a Power Unit"
                simpleValue
              />
              <Element name={`position-equipment_config.trailer`} />

              <Field
                component={select}
                options={trailers && trailers.results && trailers.results.length > 0 ? trailers.results : []}
                name="trailer"
                labelKey="name"
                valueKey="id"
                label="Trailer"
                placeholder="Select a Trailer"
                simpleValue
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
