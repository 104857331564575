import {
  WeightUnit,
  LengthUnit,
  ProductLegItem,
  TemperatureUnit,
  HazmatPackingGroup,
  FreightClass,
  PieceType,
  ProductPackagingType
} from '@shipwell/corrogo-sdk';
import pluralize from 'pluralize';
import {ShipmentLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import {numberWithCommas} from 'App/utils/globals';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {formatCurrency} from 'App/utils/internationalConstants';

export interface ModalState {
  mode: 'edit' | 'create';
  index: number | null;
}

export interface LineItem {
  width: number | null;
  length: number | null;
  height: number | null;
  length_unit: LengthUnit;
  refrigeration_max_temp: number | null;
  refrigeration_min_temp: number | null;
  temp_unit: TemperatureUnit;
  package_type: ProductPackagingType;
  package_weight: number | null;
  weight_unit: WeightUnit;
  description: string;
  total_pieces: string;
  total_packages: string;
  value_per_piece?: string;
  piece_type?: PieceType | null;
  hazmat_hazard_class?: string;
  hazmat_identification_number?: string;
  hazmat_packing_group?: HazmatPackingGroup;
  hazmat_proper_shipping_name?: string;
  country_of_manufacture?: string;
  freight_class?: FreightClass;
  nmfc_item_code?: number;
  nmfc_sub_code?: string;
  stackable?: boolean;
}

export const transformLineItemsIntoLegItems = (lineItems: LineItem[]) =>
  lineItems.map((lineItem) => ({
    dimensions: {
      width: lineItem.width ? lineItem.width.toString() : undefined,
      length: lineItem.length ? lineItem.length.toString() : undefined,
      height: lineItem.height ? lineItem.height.toString() : undefined,
      unit: lineItem.length_unit
    },
    hazmat:
      lineItem.hazmat_hazard_class &&
      lineItem.hazmat_identification_number &&
      lineItem.hazmat_packing_group &&
      lineItem.hazmat_proper_shipping_name
        ? {
            hazard_class: lineItem.hazmat_hazard_class,
            identification_number: lineItem.hazmat_identification_number,
            packing_group: lineItem.hazmat_packing_group,
            proper_shipping_name: lineItem.hazmat_proper_shipping_name
          }
        : undefined,
    description: lineItem.description,
    packaging_type: transformV2PackageTypeToV3PackageType(lineItem.package_type) || ProductPackagingType.Package,
    quantity: lineItem.total_packages ? Number.parseInt(lineItem.total_packages) : 1,
    gross_weight: {
      unit: lineItem.weight_unit || 'LB',
      value: lineItem.package_weight ? lineItem.package_weight.toString() : '0'
    },
    temperature:
      lineItem.refrigeration_min_temp && lineItem.refrigeration_max_temp
        ? {
            unit: lineItem.temp_unit || TemperatureUnit.F,
            minimum: lineItem.refrigeration_min_temp.toString(),
            maximum: lineItem.refrigeration_max_temp.toString()
          }
        : undefined,
    country_of_manufacture: lineItem.country_of_manufacture,
    stackable: !!lineItem.stackable,
    nmfc_item_code: lineItem.nmfc_item_code?.toString(),
    nmfc_sub_code: lineItem.nmfc_sub_code,
    value_per_piece: lineItem.value_per_piece ? {value: lineItem.value_per_piece} : undefined,
    freight_class: lineItem.freight_class,
    piece_type: lineItem.piece_type || undefined,
    total_pieces: lineItem.total_pieces ? Number(lineItem.total_pieces) : undefined
  }));

export const transformLegItemsIntoLineItems = (legItems: ProductLegItem[]) =>
  legItems.map((legItem) => ({
    width: legItem.dimensions?.width,
    height: legItem.dimensions?.height,
    length: legItem.dimensions?.length,
    length_unit: legItem.dimensions?.unit || 'IN',
    package_type: transformV3PackageTypeToV2PackageType(legItem.packaging_type),
    package_weight: Number(legItem.gross_weight.value),
    weight_unit: legItem.gross_weight.unit,
    description: legItem.description,
    total_packages: legItem.quantity,
    hazmat_hazard_class: legItem.hazmat?.hazard_class,
    hazmat_identification_number: legItem.hazmat?.identification_number,
    hazmat_packing_group: legItem.hazmat?.packing_group,
    hazmat_proper_shipping_name: legItem.hazmat?.proper_shipping_name,
    refrigeration_min_temp: legItem.temperature?.minimum,
    refrigeration_max_temp: legItem.temperature?.maximum,
    refrigeration_required: legItem.temperature?.minimum && legItem.temperature?.maximum,
    stackable: !!legItem.stackable,
    nmfc_item_code: legItem.nmfc_item_code ? Number(legItem.nmfc_item_code) : undefined,
    freight_class: legItem.freight_class,
    value_per_piece: legItem.value_per_piece ? legItem.value_per_piece.value : undefined,
    nmfc_sub_code: legItem.nmfc_sub_code,
    piece_type: legItem.piece_type,
    country_of_manufacture: legItem.country_of_manufacture,
    total_pieces: legItem.total_pieces ? legItem.total_pieces.toString() : undefined
  })) as Partial<ShipmentLineItem>[];

export const transformLegLineItemIntoDisplayValues = (legItem: ProductLegItem) => ({
  description: legItem.description || '--',
  quantity: legItem.quantity ? numberWithCommas(legItem.quantity) : '--',
  packageType: legItem.packaging_type ? startCaseToLower(legItem.packaging_type) : '--',
  packageWeight:
    legItem.gross_weight?.value && Number.parseInt(legItem.gross_weight?.value)
      ? `${numberWithCommas(Number.parseInt(legItem.gross_weight.value) * legItem.quantity)} ${pluralize(
          legItem.gross_weight.unit.toLowerCase()
        )}`
      : '--',
  hazmat: legItem.hazmat?.proper_shipping_name || 'No',
  valuePerPiece: legItem.value_per_piece?.value ? formatCurrency(legItem.value_per_piece.value) : '--',
  dimensions: `${legItem.dimensions?.length || '--'} x ${legItem.dimensions?.width || '--'} x ${
    legItem.dimensions?.height || '--'
  } ${legItem.dimensions?.unit.toLowerCase() || ''}`,
  freightClass: legItem.freight_class || '--',
  countryOfManufacture: legItem.country_of_manufacture || '--',
  totalWeight:
    legItem.gross_weight?.value && Number.parseInt(legItem.gross_weight?.value)
      ? `${numberWithCommas(legItem.gross_weight.value)} ${pluralize(legItem.gross_weight.unit.toLowerCase())}`
      : '--',
  nmfcCode: legItem.nmfc_item_code || '--',
  nmfcSubcode: legItem.nmfc_sub_code || '--',
  stackable: legItem.stackable ? 'Yes' : 'No',
  temperatureRange:
    legItem.temperature?.maximum && legItem.temperature.minimum && legItem.temperature.unit
      ? ` ${legItem.temperature.minimum}${legItem.temperature.unit} - ${legItem.temperature.maximum}${legItem.temperature.unit}`
      : 'No'
});

const transformV2PackageTypeToV3PackageType = (packageType: ProductPackagingType | 'PLT' | 'PIECES' | 'PKG') => {
  switch (packageType) {
    case 'PLT':
      return ProductPackagingType.Pallet;
    case 'PIECES':
      return ProductPackagingType.Piece;
    case 'PKG':
      return ProductPackagingType.Package;
    default:
      return packageType;
  }
};

const transformV3PackageTypeToV2PackageType = (packageType: ProductPackagingType) => {
  switch (packageType) {
    case ProductPackagingType.Pallet:
      return 'PLT';
    case ProductPackagingType.Piece:
      return 'PIECES';
    case ProductPackagingType.Package:
      return 'PKG';
    default:
      return packageType;
  }
};
