import {ActionRefActionIdEnum} from '@shipwell/opus-sdk';
import {ActionParams} from './utils';
import {getSecondsRemaining} from 'App/utils/momentHelpers';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {getReadableTime} from 'App/utils/getTimeOptions';

type TenderActionParams = {to: string; exp: string};
type SendEmailParams = {to: string};
type PostToLoadboardParams = {amount: string; exp: string; currency: string};
type CreateSpotNegotiationsParams = {to: string; exp: string};

export const ActionStepLabel = ({
  action,
  id,
  startTime
}: {
  action?: ActionParams;
  id?: ActionRefActionIdEnum;
  startTime?: string | null;
}) => {
  const actionParams = action?.actionParams;
  const messageWithExp = () => {
    switch (id) {
      case ActionRefActionIdEnum.Tender: {
        const typedActionParams = actionParams as TenderActionParams;
        return {
          message: `to ${typedActionParams?.to}`,
          exp: typedActionParams?.exp
        };
      }
      case ActionRefActionIdEnum.SendEmail: {
        const typedActionParams = actionParams as SendEmailParams;
        return {
          message: `to ${typedActionParams?.to}`
        };
      }
      case ActionRefActionIdEnum.PostToLoadboard: {
        const typedActionParams = actionParams as PostToLoadboardParams;
        // if no amount, dont show currency either
        if (typedActionParams?.amount === '--') {
          return {
            message: '',
            exp: typedActionParams?.exp
          };
        }
        return {
          message: `$${typedActionParams?.amount} ${typedActionParams?.currency}`,
          exp: typedActionParams?.exp
        };
      }
      case ActionRefActionIdEnum.CreateSpotNegotiations: {
        const typedActionParams = actionParams as CreateSpotNegotiationsParams;
        return {
          message: `to ${typedActionParams?.to}`,
          exp: typedActionParams?.exp
        };
      }
      case ActionRefActionIdEnum.QuoteAndTenderLtl:
        return {
          message: '',
          name: 'Quote & Tender LTL'
        };
      default:
        return {
          message: '--'
        };
    }
  };

  const duration = messageWithExp()?.exp;

  const secondsRemaining = getSecondsRemaining(startTime, duration);
  const readableTimeRemaining = getReadableTime(getSecondsRemaining(startTime, duration));
  // its possible for there not to be an expiration time...
  // ...which means the timeRemaining const will be negative and useless to the user
  const validSecondsRemaining = secondsRemaining >= 0;
  const showTimeRemaining = validSecondsRemaining ? `(TIME REMAINING: ${readableTimeRemaining})` : '(no expiration)';
  const exp = duration ? getReadableTime(duration) : undefined;
  const showTime = startTime ? showTimeRemaining : messageWithExp()?.exp && exp ? `(exp: ${exp})` : '';
  return (
    <span>
      {messageWithExp()?.name || startCaseToLower(id?.replace(/_/g, ' '))} {messageWithExp().message} {showTime}
    </span>
  );
};
