import {Component} from 'react';
import {Field, FormSection} from 'redux-form';
import InputField from 'App/formComponents/fields/input';
import AddressSearchField from 'App/formComponents/fields/addressSearch';
import './_mode.scss';

class RemitToFields extends Component {
  render() {
    return (
      <div className="formstyle-light">
        <FormSection name="vendor_factoring_company">
          <Field req label="Company" name="company_name" placeholder="Company" component={InputField} />
          <Field req label="Address" name="address" placeholder="Address" component={AddressSearchField} />
          <Field label="Email" name="email" placeholder="Email" component={InputField} />
        </FormSection>
      </div>
    );
  }
}

export default RemitToFields;
