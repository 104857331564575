import {
  SEARCH_FOR_CARRIERS,
  CLEAR_CARRIERS,
  GET_CARRIER,
  SELECT_CARRIER,
  CLEAR_CARRIER,
  CLEAR_SELECTED_RELATIONSHIP,
  GET_CARRIER_RELATIONSHIPS,
  ALERT_ERROR,
  GET_CARRIER_BY_ID,
  GET_CARRIER_RELATIONSHIP,
  GET_CARRIER_TAGS,
  POST_CARRIER_TAGS,
  REQ_CARRIER_DATA
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';

export async function verifyPhoneNumber(opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.smsLookupPost(opts, (err, data, response) => {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// search for a carrier
export function searchForCarrier(opts) {
  return (dispatch) => {
    return searchForCarrierPromise(opts)
      .then((response) => {
        dispatch({type: SEARCH_FOR_CARRIERS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function searchForCarrierPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersSearchGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function checkForUSDOT(opts) {
  return (dispatch) => {
    return searchForCarrierPromise(opts)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function clearCarriers() {
  return (dispatch) => {
    dispatch({type: CLEAR_CARRIERS});
  };
}
//get a carrier
export function getCarrier(opts) {
  return (dispatch) => {
    return getCarrierPromise(opts)
      .then((response) => {
        dispatch({type: GET_CARRIER, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getCarrierPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// report inaccurate carrier
export function reportInaccurateCarrierInfo(body) {
  return (dispatch) => {
    return reportInaccurateCarrierInfoPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function reportInaccurateCarrierInfoPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersReportInaccuratePost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function selectCarrier(carrier) {
  return (dispatch) => {
    dispatch({type: SELECT_CARRIER, payload: carrier});
  };
}

export function clearSelectedCarrier(opts) {
  return (dispatch) => {
    dispatch({type: CLEAR_CARRIER});
  };
}

export function clearSelectedRelationship() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_RELATIONSHIP});
  };
}

// Get Carrier
export function getCarrierRelationships(opts) {
  return (dispatch) => {
    dispatch(requestData());

    return getCarrierRelationshipsPromise(opts)
      .then((response) => {
        dispatch({type: GET_CARRIER_RELATIONSHIPS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

export function searchForCarrierByID(id) {
  const opts = {vendorId: id};
  return (dispatch) => {
    return getCarrierRelationshipsPromise(opts)
      .then((response) => {
        dispatch({
          type: GET_CARRIER_BY_ID,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

export async function getCarrierRelationshipsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// create carrier relationship
export function postCarrierRelationships(manualEntry, body) {
  return (dispatch) => {
    return postCarrierRelationshipsPromise(manualEntry, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error adding this carrier.'
        });

        return response;
      });
  };
}

async function postCarrierRelationshipsPromise(manualEntry, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsPost(manualEntry, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// edit carrier relationship
export function putCarrierRelationshipsCarrierRelationshipId(id, body) {
  return (dispatch) => {
    return putCarrierRelationshipsCarrierRelationshipIdPromise(id, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error editing this carrier.'
        });
        return response;
      });
  };
}

async function putCarrierRelationshipsCarrierRelationshipIdPromise(id, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdPut(id, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// delete carrier relationship
export function deleteCarrierRelationshipsCarrierRelationshipId(id) {
  return (dispatch) => {
    return deleteCarrierRelationshipsCarrierRelationshipIdPromise(id)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteCarrierRelationshipsCarrierRelationshipIdPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdDelete(id, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// get a specific carrier relationship
export function getCarrierRelationshipsCarrierRelationshipId(id) {
  return (dispatch) => {
    dispatch(requestData());

    return getCarrierRelationshipsCarrierRelationshipIdPromise(id)
      .then((response) => {
        dispatch({type: GET_CARRIER_RELATIONSHIP, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getCarrierRelationshipsCarrierRelationshipIdPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdGet(id, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

// get carrier tag
export function getCarrierTags(opts) {
  opts = opts || {};
  return (dispatch) => {
    return getCarrierTagsPromise(opts)
      .then((response) => {
        dispatch({type: GET_CARRIER_TAGS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error fetching tags'
        });
        return {status: 400, details: response};
      });
  };
}

async function getCarrierTagsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierTagsGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data.results);
      }
    });
  });
}

// create carrier tag
export function postCarrierTags(body) {
  return (dispatch) => {
    return postCarrierTagsPromise(body)
      .then((response) => {
        dispatch({type: POST_CARRIER_TAGS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function postCarrierTagsPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierTagsPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

// create carrier tag
export function resendLoadboardInvite(carrierId, pocId) {
  return (dispatch) => {
    return resendLoadboardInvitePromise(carrierId, pocId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

async function resendLoadboardInvitePromise(carrierId, pocId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carrierRelationshipsCarrierRelationshipIdPointOfContactsCarrierRelationshipPointOfContactIdSendLoadBoardInvitePost(
      carrierId,
      pocId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(data);
        }
      }
    );
  });
}

//---------- Spinner Stuff ----------//
// starts the Loading... spinner
function requestData() {
  return {type: REQ_CARRIER_DATA};
}
