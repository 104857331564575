import {FormikSelect, FormikTextInput, Button, FormikTextarea} from '@shipwell/shipwell-ui';
import {Field, useFormikContext} from 'formik';
import {FieldWrapper} from './components/FieldWrapper';
import {DynamicField, DynamicFields} from 'App/components/dynamicForms/testFields';
import AddressSearchField from 'App/formComponents/fields/_addressSearch';

// An example of how to use dyanamic forms
export const DynamicFormFields = ({fields}: {fields: DynamicFields}) => {
  const {isSubmitting} = useFormikContext();

  const fieldsArray = Object.entries(fields).map(getFormField);
  // sandwich the last field and submit button into a grid cell if even number of cells
  const lastItem = (
    <div className="flex justify-end gap-x-4">
      <div className="w-full">{fieldsArray.length % 2 ? null : fieldsArray.pop()}</div>
      <Button type="submit" isLoading={isSubmitting}>
        Connect
      </Button>
    </div>
  );

  return <>{[...fieldsArray, lastItem]}</>;
};

export const getFormField = ([
  name,
  {
    label,
    tooltip,
    input: {type, options, required}
  }
]: [string, DynamicField]) => {
  switch (type) {
    case 'select': {
      return (
        <FieldWrapper TooltipProps={tooltip}>
          <Field
            key={name}
            name={name}
            label={label}
            component={FormikSelect}
            options={options}
            simpleValue
            required={required}
          />
        </FieldWrapper>
      );
    }
    case 'password': {
      return (
        <FieldWrapper TooltipProps={tooltip}>
          <Field key={name} name={name} label={label} component={FormikTextInput} required={required} />
        </FieldWrapper>
      );
    }
    case 'address': {
      return (
        <FieldWrapper TooltipProps={tooltip}>
          <Field
            key={name}
            name={name}
            label={label}
            component={AddressSearchField}
            required={required}
            searchThreeDigitPostalCode
          />
        </FieldWrapper>
      );
    }
    case 'textarea': {
      return (
        <FieldWrapper TooltipProps={tooltip}>
          <Field key={name} name={name} label={label} component={FormikTextarea} required={required} minRows={2} />
        </FieldWrapper>
      );
    }
    default: {
      return (
        <FieldWrapper TooltipProps={tooltip}>
          <Field key={name} name={name} label={label} component={FormikTextInput} required={required} />
        </FieldWrapper>
      );
    }
  }
};
