import {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, change} from 'redux-form';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import PropTypes from 'prop-types';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import {transformShipmentMode} from 'App/utils/globals';
import {checkShipmentModes, formatShipmentModeCode} from 'App/utils/globalsTyped';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {isSingleSelectionList} from 'App/containers/orders/utils';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect((state) => ({
  shipmentModes: state.shipments.shipmentModes
}))
export default class References extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    const {values: currentValues = {}} = this.props.formValues || {};
    const {values: nextValues = {}, fields = {}} = nextProps?.formValues || {};
    const {name: shipmentNameField} = fields;
    const {stops: currentStops = []} = currentValues;
    const {stops: nextStops = []} = nextValues;
    if (
      !this.props.externalForm &&
      // user has not manually edited the name field
      !shipmentNameField?.touched &&
      // relevant values have changed
      (currentStops[0]?.location?.company_name !== nextStops[0]?.location?.company_name ||
        currentStops[currentStops.length - 1]?.location?.company_name !==
          nextStops[nextStops.length - 1]?.location?.company_name ||
        currentValues.mode !== nextValues.mode ||
        currentValues.customer_reference_number !== nextValues.customer_reference_number)
    ) {
      this.updateShipmentName(nextProps);
    }
  }

  // format shipment name: <customer ref> - <mode> - <origin> to <dest>
  updateShipmentName = (props) => {
    const {values = {}} = props?.formValues || {};
    const {mode: modeValue, stops = [], customer_reference_number: customerRef} = values;
    const pickupCompany = stops[0]?.location?.company_name || '';
    const deliveryCompany = stops[stops.length - 1]?.location?.company_name || '';
    let mode = '';
    if (Array.isArray(modeValue)) {
      mode = modeValue[0].code || '';
    } else if (modeValue && this.props.shipmentModes?.length > 0) {
      mode = transformShipmentMode(modeValue, this.props.shipmentModes)?.code || '';
    }

    if (mode && pickupCompany && deliveryCompany) {
      let shipmentName = `${customerRef ? `${customerRef} - ` : ''}${formatShipmentModeCode(
        mode
      )} - ${pickupCompany} to ${deliveryCompany}`;
      if (shipmentName.length > 200) {
        shipmentName = shipmentName.substring(0, 200);
      }
      this.props.dispatch(change(props.form, 'name', shipmentName));
    }
  };

  render() {
    const {values = {}} = this.props.formValues;
    const {mode = {}} = values;
    const {hasDrayage, hasFTL, hasIntermodal, hasRail} = checkShipmentModes(mode);
    const {externalForm, customFields} = this.props;

    return (
      <div className="quote-flow__references">
        <Element name={`position-name`} />

        <h2 className="border-bottom">References & Instructions</h2>
        <div className="formstyle-light">
          {!externalForm && (
            <Field label="Shipment Name" name="name" component={renderField} placeholder="Enter shipment name" />
          )}
          {this.props.form === 'newShipmentForm' && !externalForm && (
            <Field label="BOL #" name="bol_number" component={renderField} placeholder="BOL #" />
          )}
          {(this.props.form === 'newShipmentForm' || externalForm) && (
            <Field label="PRO #" name="pro_number" component={renderField} placeholder="PRO #" />
          )}
          {this.props.form === 'newShipmentForm' && !externalForm && (
            <Field label="PICKUP #" name="pickup_number" component={renderField} placeholder="Pickup #" />
          )}
          <Field
            label="PO #"
            name="purchase_order_number"
            component={renderField}
            type="text"
            placeholder="PO #"
            disabled={externalForm ? true : false}
          />
          {(hasDrayage || hasIntermodal || hasRail) && !externalForm ? (
            <Fragment>
              <Field
                label="Booking #"
                name="drayage_booking_number"
                component={renderField}
                type="text"
                placeholder="Booking #"
              />
              <Field
                label="Container #"
                name="drayage_container_number"
                component={renderField}
                type="text"
                placeholder="Container #"
              />
              <Field
                label="Seal #"
                name="drayage_seal_number"
                component={renderField}
                type="text"
                placeholder="Seal #"
              />
              <Field
                label="House BOL #"
                name="drayage_house_bol_number"
                component={renderField}
                type="text"
                placeholder="House BOL #"
              />
              <Field
                label="Chassis #"
                name="drayage_chassis_number"
                component={renderField}
                type="text"
                placeholder="Chassis #"
              />
              {hasDrayage || hasIntermodal ? (
                <Field
                  label="Ocean Carrier SCAC"
                  name="drayage_carrier_scac_code"
                  component={renderField}
                  type="text"
                  placeholder="SCAC"
                />
              ) : (
                <Field
                  label="Railcar #"
                  name="rail_car_number"
                  component={renderField}
                  type="text"
                  placeholder="Railcar #"
                />
              )}
            </Fragment>
          ) : null}
          {hasFTL && !externalForm ? (
            <Field label="Seal #" name="drayage_seal_number" component={renderField} type="text" placeholder="Seal #" />
          ) : null}
          {!externalForm && (
            <>
              <Field
                label="Customer Reference #"
                name="customer_reference_number"
                component={renderField}
                placeholder="e.g., 5H1PW3LL"
              />
            </>
          )}
          {customFields.length > 0 &&
            customFields.map((cf) => {
              return (
                <Field
                  key={cf.id}
                  label={cf.label}
                  data={cf.allowed_values.map((allowedValue) => ({id: allowedValue.label, name: allowedValue.label}))}
                  name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`}
                  req={cf.required}
                  component={isSingleSelectionList(cf.field_type) ? renderDropDownSelector : renderField}
                  placeholder={isSingleSelectionList(cf.field_type) ? '--' : null}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

References.propTypes = {
  form: PropTypes.string,
  formValues: PropTypes.shape({values: PropTypes.object}),
  externalForm: PropTypes.bool,
  customFields: PropTypes.arrayOf({id: PropTypes.string, label: PropTypes.string}),
  shipmentModes: PropTypes.arrayOf({id: PropTypes.string, code: PropTypes.string}),
  dispatch: PropTypes.func
};

References.defaultProps = {
  customFields: [],
  shipmentModes: []
};
