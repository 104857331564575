import {JSX} from 'react';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import {toTitleCase} from 'App/utils/globals';
import {mapLane} from 'App/containers/appointments/components/forms/AppointmentForm/utils';

export interface ShipmentInformationSidebarProps {
  shipmentId?: string | null;
}

const ShipmentInformationSidebar = ({shipmentId}: ShipmentInformationSidebarProps): JSX.Element => {
  const {shipmentQuery, lane} = useLegacyShipment(shipmentId);

  const informationItemMapping = [
    {
      label: 'lane',
      text: mapLane(lane.pickupLane, lane.deliveryLane) ?? '--'
    },
    {
      label: 'mode',
      text: shipmentQuery.data?.mode?.description
    },
    {
      label: 'equipment',
      text: shipmentQuery?.data?.equipment_type?.name
    },
    {
      label: 'power unit',
      text: shipmentQuery?.data?.equipment_config?.power_unit?.name
    },
    {
      label: 'trailer',
      text: shipmentQuery?.data?.equipment_config?.trailer?.name
    },
    {
      label: 'packaging type',
      text: shipmentQuery?.data?.line_items?.map((lineItem) => toTitleCase(lineItem.package_type))?.join(', ')
    },
    {
      label: 'product reference',
      text: shipmentQuery?.data?.line_items?.map((lineItem) => lineItem.product_ref)?.join(', ')
    },
    {
      label: 'accessorials',
      text: shipmentQuery?.data?.accessorials?.map((accessorial) => toTitleCase(accessorial?.description)).join(', ')
    }
  ];

  return (
    <>
      <p className="text-sm font-bold">Shipment Information</p>
      <p className="text-xxs uppercase">SHIPMENT REFERENCES</p>
      <ul className="list-none">
        <li className="break-words uppercase">SHIPMENT ID#: {shipmentQuery?.data?.reference_id || '--'}</li>
        <li className="break-words uppercase">BOL#: {shipmentQuery?.data?.bol_number || '--'}</li>
        <li className="break-words uppercase">PO#: {shipmentQuery?.data?.purchase_order_number || '--'}</li>
        <li className="break-words uppercase">PRO#: {shipmentQuery?.data?.pro_number || '--'}</li>
        <li className="break-words uppercase">Customer#: {shipmentQuery?.data?.customer_reference_number || '--'}</li>
      </ul>
      <ul className="list-none">
        {informationItemMapping.map(({label, text}) => (
          <li key={label} className="grid grid-flow-row break-words pb-3" title={label}>
            <span aria-label={`${label.replaceAll(' ', '-')}-label`} className="text-xxs uppercase">
              {label}
            </span>
            <span aria-labelledby={`${label.replaceAll(' ', '-')}-label`} className="text-sm">
              {text?.length ? text : '--'}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ShipmentInformationSidebar;
