import {MeResponse} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {PermissionsCategory} from '../../permissionsCategories';
import {ColumnHeader, ExpansiveRowLayout, RowLayout} from './layouts';
import {useUserMeContext} from 'App/data-hooks';

export const UserPermissionsFields = ({permissionsCategories}: {permissionsCategories: PermissionsCategory[]}) => {
  const auth = useUserMeContext();
  const {stmFinancialTenderingUserPermission} = useFlags<{stmFinancialTenderingUserPermission: boolean}>();

  return (
    <div>
      <RowLayout
        label={<ColumnHeader>Permission Type</ColumnHeader>}
        checkboxColumns={
          <>
            <ColumnHeader>All</ColumnHeader>
            <ColumnHeader>View</ColumnHeader>
            <ColumnHeader>Edit</ColumnHeader>
            <ColumnHeader>Create</ColumnHeader>
            <ColumnHeader>Delete</ColumnHeader>
          </>
        }
      />

      {permissionsCategories
        //sort permissions categories alphabetically
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((permission) => {
          if (isHidden(permission.name, auth, {stmFinancialTenderingUserPermission})) return null;

          return <ExpansiveRowLayout key={permission.name} permission={permission} />;
        })}
    </div>
  );
};

const isHidden = (
  permissionGroupName: string,
  auth: MeResponse,
  ldFlags: {stmFinancialTenderingUserPermission: boolean}
) => {
  const isBroker = !!auth.company?.brokerage;
  const isCarrier = !!auth.company?.carrier;
  const isIntegrationsManager = auth.user?.permissions?.includes('integrations.manage_integrations');
  const companyFeatureFlags = auth.company?.feature_flags;
  const analyticsEnabled = !!companyFeatureFlags?.analytics_enabled;
  const purchaseOrdersEnabled = !!companyFeatureFlags?.purchase_orders_enabled;
  const contractsEnabled = !!companyFeatureFlags?.contracts_enabled;
  const integrationsEnabled = !!companyFeatureFlags?.integrations_enabled;
  const {stmFinancialTenderingUserPermission} = ldFlags;
  const conditionsToHide = [
    permissionGroupName === 'analytics' && !analyticsEnabled,
    permissionGroupName === 'orders' && !purchaseOrdersEnabled,
    permissionGroupName === 'customer_relationships' && !isBroker,
    permissionGroupName === 'carrier' && !isCarrier,
    permissionGroupName === 'contracts' && !contractsEnabled,
    permissionGroupName === 'third_party_app_integrations' && (!isIntegrationsManager || !integrationsEnabled),
    permissionGroupName === 'shipment_financials' && !stmFinancialTenderingUserPermission
  ];

  if (conditionsToHide.some(Boolean)) return true;
  return false;
};
