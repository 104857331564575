import {useState} from 'react';
import {Card} from '@shipwell/shipwell-ui';
import useToggle from 'App/utils/hooks/useToggle';
import TenderListTable from 'App/containers/shipments/components/TendersView/TenderListTable';
import TenderCreationModal from 'App/containers/shipments/components/TenderFields';
import {ShipperTenderDetailsModal} from 'App/containers/shipments/details/TenderDetails';

const TenderListCard = ({shipmentId}: {shipmentId: string}) => {
  const [showTenderCreationModal, toggleShowTenderCreationModal] = useToggle();
  const [showTenderDetailsModal, toggleShowTenderDetailsModal] = useToggle();
  const [selectedTender, setSelectedTender] = useState('');

  return (
    <div className="p-8">
      <Card title="Tenders" bodyClassName="p-0" draggableProvided={null}>
        <TenderListTable
          toggleShowTenderDetailsModal={toggleShowTenderDetailsModal}
          toggleShowTenderCreationModal={toggleShowTenderCreationModal}
          setSelectedTender={setSelectedTender}
          shipmentId={shipmentId}
        />
      </Card>
      <TenderCreationModal
        showModal={showTenderCreationModal}
        onClose={toggleShowTenderCreationModal}
        shipmentId={shipmentId}
      />
      <ShipperTenderDetailsModal
        shipmentId={shipmentId}
        transportationOrderId={selectedTender}
        showModal={showTenderDetailsModal}
        onClose={toggleShowTenderDetailsModal}
      />
    </div>
  );
};

export default TenderListCard;
