import {SvgIcon} from '@shipwell/shipwell-ui';
import {ContractChargeItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import round from 'lodash/round';
import {formatCurrency} from 'App/utils/internationalConstants';
import {getChargeItemsTotals} from 'App/data-hooks/contracts/utils';

export const ContractTotalRate = ({
  charges,
  className,
  showLaneStatsDPM,
  totalDistance,
  isLoadingChargeItems
}: {
  charges?: ContractChargeItem[];
  className?: string;
  showLaneStatsDPM?: boolean;
  totalDistance?: number | null;
  isLoadingChargeItems?: boolean;
}) => {
  const {total, lineHaulCharge, currency} = getChargeItemsTotals(charges);
  const isPerHour = lineHaulCharge?.unit_quantity === 0;
  const dpmRate = showLaneStatsDPM && totalDistance ? total / round(totalDistance) : undefined;

  return (
    <div className={className}>
      {isLoadingChargeItems ? (
        <SvgIcon name="LoadingDots" />
      ) : isPerHour ? (
        `${formatCurrency(lineHaulCharge.unit_amount, currency)}/hour`
      ) : total > 0 ? (
        formatCurrency(showLaneStatsDPM ? dpmRate : total, currency)
      ) : (
        '--'
      )}
    </div>
  );
};
