import _ from 'lodash';
import {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {Button, Form, FormGroup, InputGroup, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import Paper from '@material-ui/core/Paper';
import * as actions from 'App/actions/brokers';
import * as documentActions from 'App/actions/documents';
import * as userActions from 'App/actions/users';
import {formatCurrencyValue} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import './_manage-customers.scss';
import 'react-table-6/react-table.css';

@connect(
  (state) => ({
    agents: state.brokers.agents,
    carriers: state.brokers.carriers,
    carrierRelationships: state.brokers.carrierRelationships,
    customerDocForm: state.form.customerDocForm,
    newCustomerDocForm: state.form.newCustomerDocForm,
    documentIsLoading: state.documents.isLoading,
    documentError: state.documents.error,
    shipperRelationships: state.brokers.shipperRelationships,
    isShipperRelationshipsLoading: state.brokers.isShipperRelationshipsLoading,
    loadingCustomerRelationships: state.brokers.loadingCustomerRelationships,
    user: state.auth.user,
    brokerUser: state.auth.brokerUser,
    company: state.auth.company,
    companyUsers: state.users.companyUsers,
    isCompanyUsersLoading: state.users.isCompanyUsersLoading,
    selectedCustomer: state.brokers.selectedCustomer,
    selectedShipperCarrierRelationships: state.brokers.selectedShipperCarrierRelationships,
    preferences: state.userCompany.preferences
  }),
  {...actions, ...documentActions, ...userActions}
)
export default class ManageCustomers extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);

    this.deleteRelationship = this.deleteRelationship.bind(this);
    this.getCompanyUsers = this.getCompanyUsers.bind(this);
    this.searchDataTable = this.searchDataTable.bind(this);
    this.newUser = this.newUser.bind(this);
    this.state = {
      brokerageId: null,
      companyId: null,
      documents: [],
      cancel: false,
      shipperRelationshipId: null,
      showDeleteUserModal: false,
      submittedForm: false,
      pagination: {
        page: 1,
        pageSize: 10,
        ordering:
          localStorage.getItem('customersSort') && Array.isArray(JSON.parse(localStorage.getItem('customersSort')))
            ? JSON.parse(localStorage.getItem('customersSort'))
                .map((el) => {
                  return (el.desc ? '-' : '') + el.id;
                })
                .join(', ')
            : 'company',
        q: ''
      }
    };
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.company && this.props.company.brokerage) {
      // set ids and fetch
      this.setState(
        {
          companyId: this.props.company.id,
          brokerageId: this.props.company.brokerage.id
        },
        () => {
          this.getCompanyUsers();
          this.getBrokerShipperRelationships();
        }
      );
    }
    //make sure we get the default values to start
    this.props.clearSelectedCustomer();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company && nextProps.company.brokerage) {
      // set ids and fetch
      this.setState(
        {
          companyId: nextProps.company.id,
          brokerageId: nextProps.company.brokerage.id
        },
        () => {
          this.getCompanyUsers();
          this.getBrokerShipperRelationships();
        }
      );
    }
  }

  getCompanyUsers() {
    this.props.getCompanyUsers(this.state.companyId, {
      page: this.state.pagination.page,
      pageSize: 1000,
      ordering: this.state.pagination.ordering
    });
  }

  getBrokerShipperRelationships() {
    this.props.fetchBrokerShipperRelationships(this.state.brokerageId, this.state.pagination);
  }

  fetchDocuments(shipperRelationshipId) {
    this.props.fetchCustomerDocuments(this.state.brokerageId, shipperRelationshipId).then((response) => {
      this.setState({documents: response.details});
    });
  }

  editCustomer(customer) {
    //create a copy so that we don't have flashes of changes to the object behind the modal
    const customerCopy = JSON.parse(JSON.stringify(customer));
    for (let i = 0; customerCopy.account_reps && i < customerCopy.account_reps.length; i++) {
      //grab the entire user object for the sales reps
      for (let j = 0; this.props.companyUsers.results && j < this.props.companyUsers.results.length; j++) {
        if (customerCopy.account_reps[i] === this.props.companyUsers.results[j].id) {
          customerCopy.account_reps[i] = this.props.companyUsers.results[j];
        }
      }
    }
    // grab the entire user object for the account reps. No need to nest a for loop currently because this always returns one
    if (this.props.companyUsers.results) {
      this.props.companyUsers.results.find((rep) => {
        if (customerCopy.sales_reps[0] === rep.id) {
          customerCopy.sales_reps[0] = rep;
          return customerCopy;
        }
      });
    }

    this.props.selectCustomer(customerCopy);
  }

  newUser() {
    this.context.router.push('/new_customer');
  }

  deleteRelationship() {
    this.props
      .deleteBrokerShipperRelationship(this.props.company.brokerage.id, this.state.selectedUser.id)
      .then((response) => {
        if (response.status === 200) {
          this.props.clearSelectedCustomer();
          this.setState({
            showDeleteUserModal: false
          });
        }
      });
  }

  searchDataTable(e) {
    e.preventDefault();
    clearTimeout(this.timer);
    const pagination = this.state.pagination;
    pagination.q = e.target.value;
    pagination.page = 1;
    this.setState({pagination: pagination}, () => {
      this.timer = setTimeout(() => {
        this.getBrokerShipperRelationships();
      }, 250);
    });
  }

  render() {
    const columns = [
      {
        Header: () => (
          <span>
            Company <i className="icon icon-Sort btn-sort" />
          </span>
        ),
        id: 'company',
        minWidth: 120,
        accessor: (d) => d.company.name,
        Cell: (row) => {
          return (
            <div className="company">
              <Link onClick={() => this.editCustomer(row.original)} to={`/manage_customers/${row.original.id}`}>
                <span>{row.value}</span>
                <br />
                <span>{row.original.is_own_brokerage ? '(House Account)' : ''}</span>
              </Link>
            </div>
          );
        }
      },
      {
        Header: 'Email',
        id: 'primary_email',
        accessor: (d) => d.company.primary_email,
        sortable: false
      },
      {
        Header: 'Phone',
        id: 'primary_phone_number',
        accessor: (d) => d.company.primary_phone_number,
        sortable: false,
        Cell: (row) => {
          return <span>{row.value ? getPhoneHyperlink(row.value) : ''}</span>;
        }
      },
      {
        Header: 'Sales Rep',
        id: 'sales_reps',
        accessor: (d) => d.sales_reps,
        sortable: false,
        Cell: (row) => {
          let selectedSalesRep = {};
          if (this.props.companyUsers.results && row.value) {
            this.props.companyUsers.results.find((rep) => {
              if (rep.id === row.value[0]) {
                selectedSalesRep = rep;
              }
            });
          }
          return (
            <span>
              {'first_name' && 'last_name' in selectedSalesRep
                ? selectedSalesRep.first_name + ' ' + selectedSalesRep.last_name
                : 'None assigned'}
            </span>
          );
        }
      },
      {
        Header: 'Account Rep(s)',
        id: 'account_reps',
        accessor: (d) => d.account_reps,
        sortable: false,
        Cell: (row) => {
          const selectedAccountReps = [];
          if (this.props.companyUsers.results && row.value) {
            if (this.props.companyUsers && this.props.companyUsers.results) {
              for (var k = 0; k < this.props.companyUsers.results.length; k++) {
                if (row.value.includes(this.props.companyUsers.results[k].id)) {
                  selectedAccountReps.push(this.props.companyUsers.results[k]);
                }
              }
            }
          }
          return (
            <span>
              {selectedAccountReps.length > 0
                ? selectedAccountReps.map((name, i) => {
                    if (i !== selectedAccountReps.length - 1) {
                      return name.first_name + ' ' + name.last_name + ', ';
                    }
                    return name.first_name + ' ' + name.last_name;
                  })
                : 'None assigned'}
            </span>
          );
        }
      },
      {
        Header: 'Share Rates',
        id: 'share_rates',
        accessor: (d) => d.can_book_with_rates,
        sortable: false,
        Cell: (row) => {
          let shareRates = false;
          if (row.value || row.original.can_quote_with_rates) {
            shareRates = true;
          }
          return (
            <span>
              {shareRates ? (
                <i className="icon icon-Check text-success" />
              ) : (
                <i className="icon icon-x-small text-danger" />
              )}
            </span>
          );
        }
      },
      {
        Header: 'FTL Markup',
        id: 'ftl_markup',
        accessor: (d) => d.markup,
        sortable: false,
        Cell: (row) => {
          return (
            <span>
              {row.value.ftl_markup_type === 'flat_fee'
                ? '$' + formatCurrencyValue(row.value.ftl_markup_flat_fee)
                : '' + parseFloat(row.value.ftl_markup_percentage * 100).toFixed(1) + '%'}
            </span>
          );
        }
      },
      {
        Header: 'LTL Markup',
        id: 'ltl_markup',
        accessor: (d) => d.markup,
        sortable: false,
        Cell: (row) => {
          return (
            <span>
              {row.value.ltl_markup_type === 'flat_fee'
                ? '$' + formatCurrencyValue(row.value.ltl_markup_flat_fee)
                : '' + parseFloat(row.value.ltl_markup_percentage * 100).toFixed(1) + '%'}
              {row.value.ltl_markup_type === 'percentage' && !isNaN(row.value.ltl_markup_minimum)
                ? ', $' + formatCurrencyValue(row.value.ltl_markup_minimum) + ' min'
                : ''}
            </span>
          );
        }
      },
      {
        Header: () => (
          <span>
            Credit Limit
            <i className="icon icon-Sort btn-sort" />
          </span>
        ),
        id: 'broker_imposed_credit_limit',
        accessor: (d) => d.broker_imposed_credit_limit,
        Cell: (row) => {
          return <span>${formatCurrencyValue(row.value)}</span>;
        }
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        sortable: false,
        accessor: (d) => d.created_at,
        width: 110,
        Cell: (row) => {
          return <span>{moment(row.value).format('ddd, MMM D, YYYY')}</span>;
        }
      }
    ];
    return (
      <div className="manage-customers content-wrapper">
        <section className="content">
          <Paper className="manage-customers__search">
            <FormGroup>
              <InputGroup>
                <InputGroup.Button>
                  <Button
                    onClick={() => {
                      this.getBrokerShipperRelationships();
                    }}
                  >
                    <i className="flaticon-search pad-left" />
                  </Button>
                </InputGroup.Button>
                <FormControl
                  name="search"
                  type="text"
                  placeholder="Search for a customer..."
                  onChange={this.searchDataTable}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.getBrokerShipperRelationships();
                    }
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Paper>
          <Paper className="manage-customers__card">
            <div className="manage-customers__toolbar">
              <Button
                className="manage-customers__add"
                disabled={this.props.isCompanyUsersLoading ? true : false}
                onClick={this.newUser}
                bsStyle="link"
              >
                <strong>+</strong> Add Customer
              </Button>
            </div>
            <div className="manage-customers__table">
              <ReactTable
                data={this.props.shipperRelationships ? this.props.shipperRelationships.results : []}
                pages={this.props.shipperRelationships ? this.props.shipperRelationships.total_pages : 1}
                loading={this.props.loadingCustomerRelationships}
                manual
                minRows={10}
                defaultPageSize={this.state.pagination.pageSize}
                pageSizeOptions={[10, 25, 50, 100]}
                defaultSorted={
                  localStorage.getItem('customersSort')
                    ? JSON.parse(localStorage.getItem('customersSort'))
                    : [
                        {
                          id: 'company',
                          desc: false
                        }
                      ]
                }
                getTrGroupProps={(state, rowInfo, column, instance) => {
                  if (!rowInfo) {
                    return {
                      className: 'no-content'
                    };
                  }
                  return '';
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  if (rowInfo && rowInfo.original.is_own_brokerage) {
                    return {
                      className: 'manage-customers__houseAcct'
                    };
                  }
                  return {className: ''};
                }}
                onFetchData={function (state, instance) {
                  if (state.sorted && state.sorted.length > 0) {
                    localStorage.setItem('customersSort', JSON.stringify(state.sorted));
                  }
                  const ordering = [];
                  for (let i = 0; i < state.sorted.length; i++) {
                    ordering.push((state.sorted[i].desc ? '-' : '') + state.sorted[i].id);
                  }
                  if (!_.isEmpty(this.props.company)) {
                    this.props
                      .fetchBrokerShipperRelationships(this.props.company.brokerage.id, {
                        page: state.page + 1,
                        pageSize: state.pageSize,
                        ordering: ordering.join(',')
                      })
                      .then((response) => {
                        const pagination = this.state.pagination;
                        pagination.page = state.page + 1;
                        pagination.pageSize = state.pageSize;
                        pagination.ordering = ordering;
                        this.setState({pagination: pagination});
                      });
                  }
                }.bind(this)}
                onPageChange={(page) => {
                  document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
                }}
                columns={columns}
                className="-highlight -striped"
                previousText={<i className="icon icon-Left" />}
                nextText={<i className="icon icon-Right" />}
                rowsText=""
                noDataText="No Customers"
                LoadingComponent={ShipwellLoader}
              />
            </div>
          </Paper>
        </section>
      </div>
    );
  }
}
