import {withRouter, RouteComponentProps} from 'react-router';
import {useAuth0} from '@auth0/auth0-react';
import LoginErrorPage from 'App/containers/authentication/components/loginErrorPage';

type PathParams = {
  error_description: string;
};

const AccessDeniedError = ({location}: RouteComponentProps<unknown, unknown, unknown, PathParams>) => {
  const {logout} = useAuth0();

  const defaultMessage = `We had trouble connecting you through our secure login portal.
    Please click the button below to try again. If the issue persists, contact your administrator.`;
  return (
    <LoginErrorPage
      companyName={localStorage.getItem('whiteLabelTitle')}
      callback={() => logout({returnTo: window.location.origin})}
      message={location.query.error_description || defaultMessage}
      buttonLabel="Sign Out"
    />
  );
};

export default withRouter(AccessDeniedError);
