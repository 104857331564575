import {useQuery} from '@tanstack/react-query';
import {getImpersonationToken} from 'App/api/user/typed';
import {IMPERSONATION_TOKEN_QUERY_KEY} from 'App/data-hooks/queryKeys';

function useStartImpersonation(userId: string | undefined) {
  return useQuery(
    [IMPERSONATION_TOKEN_QUERY_KEY, userId],
    async () => {
      if (!userId) {
        return Promise.reject(new Error('Invalid impersonation user id'));
      }
      const response = await getImpersonationToken(userId);
      return response.data;
    },
    {
      enabled: !!userId,
      onSuccess(data) {
        if (!data.impersonation_token) {
          return;
        }
        window.location.href = `/impersonate?impersonation_token=${data.impersonation_token}`;
      }
    }
  );
}

export default useStartImpersonation;
