import {useMutation} from '@tanstack/react-query';
import {
  ScheduleShipmentPickupRequest,
  ShipmentPickup,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {
  createShipmentPickup,
  updateShipmentPickup,
  CorrectedShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest,
  ShipmentPickupPutRequest
} from 'App/api/shipment/typed';
import {cleanPayload} from 'App/utils/globals';

export const useCreateUpdateShipmentPickup = () => {
  const {mutate: mutateCreate} = useMutation<ShipmentPickup, AxiosError<ShipwellError>, ScheduleShipmentPickupRequest>(
    (scheduleShipmentPickupRequest: ScheduleShipmentPickupRequest) =>
      createShipmentPickup({
        scheduleShipmentPickupRequest: cleanPayload(scheduleShipmentPickupRequest) as ScheduleShipmentPickupRequest
      })
  );

  const {mutateAsync: mutateAsyncUpdate} = useMutation<
    ShipmentPickup,
    AxiosError<ShipwellError>,
    CorrectedShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest
  >(({shipmentPickupId, shipmentPickup}: {shipmentPickupId: string; shipmentPickup: ShipmentPickupPutRequest}) =>
    updateShipmentPickup({shipmentPickupId, shipmentPickup})
  );

  return {createShipmentPickup: mutateCreate, updateShipmentPickupAsync: mutateAsyncUpdate};
};
