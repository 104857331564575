/* eslint-disable no-constant-binary-expression */
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {AppointmentStatusEnum, Facility, ReferenceQualifierEnum} from '@shipwell/tempus-sdk';
import {
  ShipmentStatesValues,
  PurchaseOrder,
  SlimShipment,
  Stop,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatParts, getMostRecentAppointment} from './utils';
import {AppointmentReference, CarrierAppointment} from './types';
import {ShipwellApiErrorResponse, convertShipwellAxiosError} from 'App/utils/errors';
import {getFacilityAppointments} from 'App/api/appointments';
import {APPOINTMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getFacility} from 'App/api/facilities';
import {getShipments as getApiShipments} from 'App/api/shipment/typed';
import {useUserMe} from 'App/data-hooks';
import {getPurchaseOrders} from 'App/api/purchaseOrders/typed';
import {makeEntry} from 'App/containers/appointments/utils';

type StopWithReducedProps = {
  id: string;
  shipmentId: string;
  facilityId: string;
  references: AppointmentReference[];
  plannedDate?: string | null;
  isPickup: boolean;
  isDropOff: boolean;
};

/**
 * Gets the purchase order references associated with a single stop If no references are found then an empty array is returned.
 */
const getStopPurchaseOrderReferences = (
  stopId: Stop['id'],
  purchaseOrderNumbers?: PurchaseOrder[]
): AppointmentReference[] => {
  if (!stopId) {
    return [];
  }

  if (!purchaseOrderNumbers?.length) {
    return [];
  }

  const references =
    purchaseOrderNumbers
      ?.filter((po) => (po.origin_stop === stopId || po.destination_stop === stopId) && !!po.purchase_order_number)
      ?.map<AppointmentReference>((po) => {
        return {
          qualifier: ReferenceQualifierEnum.PoNumber,
          value: po.purchase_order_number as string
        };
      }) || [];

  return references;
};
/**
 * Gets all stops which have a pickup date greater than or equal to the start date, and less than or equal to the the end date
 * and assigned ot the passed vendor id. If no dates are provided they are omitted and no filtering is applied.
 */
const getShipmentStops = async ({
  startDate,
  endDate,
  vendorId,
  q
}: {
  startDate?: string;
  endDate?: string;
  vendorId?: string;
  q?: string;
}) => {
  // sadly we only need stops that are for the carrier, but there is no way to get stops which are associated with the carrier
  // we have to do two separate API calls to filter with pickups and drop off times.
  const getShipmentsResponse = await getApiShipments({
    page: 1,
    pageSize: 1000, // arbitrary size to get as many shipments as possible that belong to the carrier
    vendorId: vendorId,
    archived: false,
    statusExclude: [ShipmentStatesValues.Cancelled], // we don't want shipments that have been cancelled.
    q: q,
    pickupGte: startDate,
    dropoffLte: endDate
  });
  const shipmentIds: string[] = getShipmentsResponse.results?.length
    ? getShipmentsResponse.results
        .filter((shipment) => shipment.state !== ShipmentStatesValues.Cancelled)
        .map(({id}) => id)
    : [];
  const purchaseOrderPromises = shipmentIds.map((id) =>
    getPurchaseOrders({
      shipmentId: id,
      page: 1,
      pageSize: 1000
    })
  );
  const purchaseOrderResults = await Promise.allSettled(purchaseOrderPromises || []);
  const purchaseOrders =
    purchaseOrderResults.flatMap((result) => {
      if (result.status === 'fulfilled') {
        return result.value.data.results || [];
      }
      return [];
    }) || [];

  let slimStops: StopWithReducedProps[] = [];
  if (getShipmentsResponse.results?.length) {
    for (let i = 0; i < getShipmentsResponse.results.length; ++i) {
      const {
        id: shipmentId,
        stops,
        bol_number,
        pro_number,
        customer_reference_number,
        reference_id
      } = getShipmentsResponse.results[i];
      if (!stops?.length) {
        continue; // no stops to handle
      }
      for (let j = 0; j < stops.length; ++j) {
        const {is_dropoff, is_pickup, location, planned_date, id} = stops[j];
        if (!location.facility_id) {
          continue; // not usable in dock scheduling
        }
        const references = getShipmentReferences(
          {reference_id, customer_reference_number, pro_number, bol_number, stops},
          purchaseOrders
        );
        const stop: StopWithReducedProps = {
          id: id,
          shipmentId: shipmentId,
          facilityId: location?.facility_id,
          references: references,
          plannedDate: planned_date,
          isPickup: is_pickup,
          isDropOff: is_dropoff
        };

        slimStops = [...slimStops, stop];
      }
    }
  }

  return slimStops;
};

export type UseCarrierAppointmentRequest = {
  /**
   * Any search term or text to filter the appointments by
   */
  q?: string;
  /**
   * Required start date for the appointment
   */
  startDate: Date;
  /**
   * Required end date for the appointment
   */
  endDate: Date;
  /**
   * Appointment Statuses to filter by
   */
  statuses?: AppointmentStatusEnum[];
};

const getShipmentReferences = (
  {
    stops,
    bol_number,
    pro_number,
    customer_reference_number,
    reference_id
  }: {
    stops: SlimShipment['stops'];
    bol_number: SlimShipment['bol_number'];
    pro_number: SlimShipment['pro_number'];
    customer_reference_number: SlimShipment['customer_reference_number'];
    reference_id: SlimShipment['reference_id'];
  },
  purchaseOrders: PurchaseOrder[]
): AppointmentReference[] => {
  let references: AppointmentReference[] = [];
  if (stops?.length) {
    const stopReferences = stops.flatMap(({id: stopId}) => getStopPurchaseOrderReferences(stopId, purchaseOrders));
    if (stopReferences?.length) {
      references = [...stopReferences, ...references];
    }
  }
  if (bol_number) {
    references = [
      ...references,
      {
        qualifier: ReferenceQualifierEnum.BolNumber,
        value: bol_number
      }
    ];
  }
  if (pro_number) {
    references = [
      ...references,
      {
        qualifier: ReferenceQualifierEnum.ProNumber,
        value: pro_number
      }
    ];
  }
  if (customer_reference_number) {
    references = [
      ...references,
      {
        qualifier: ReferenceQualifierEnum.CustomerReferenceNumber,
        value: customer_reference_number
      }
    ];
  }
  if (reference_id) {
    references = [
      ...references,
      {
        qualifier: ReferenceQualifierEnum.ShipmentReferenceId,
        value: reference_id
      }
    ];
  }

  return references;
};

export type UseCarrierAppointmentOptions = Omit<
  UseQueryOptions<CarrierAppointment[], AxiosError<ShipwellApiErrorResponse>, CarrierAppointment[], string[]>,
  'queryKey' | 'queryFn'
>;

/**
 * Returns a query hook to get all appointments for a supplier across all facilities and docks.
 * Gets all stops filtered down by search term `q` and planned_date within the range of `startDate` and `endDate`.
 */
const useCarrierAppointments = (
  {q, startDate, endDate, statuses}: UseCarrierAppointmentRequest,
  options?: UseCarrierAppointmentOptions
) => {
  const queryKey = [
    APPOINTMENTS_QUERY_KEY,
    startDate.toISOString(),
    endDate.toISOString(),
    statuses?.join(','),
    q
  ].filter((value) => !!value) as string[]; // typescript can't figure out that we are omitting the falsy strings with the filter so we cast it as such here.
  const {
    data: authData,
    isInitialLoading,
    isLoading,
    isError,
    isSuccess,
    error,
    isFetching,
    isRefetching
  } = useUserMe({
    enabled: options?.enabled ?? true
  });
  const query = useQuery(
    queryKey,
    async () => {
      // create adhoc type here for assignment later
      const stops: StopWithReducedProps[] = await getShipmentStops({
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        q: q,
        vendorId: authData?.company?.id ?? undefined // for some reason the linter can't see that this is a truthy value even with the boolean cast check
      });
      const facilityMap = new Map<string, Facility>();
      let appointments: CarrierAppointment[] = [];
      for (let i = 0; i < stops.length; ++i) {
        const {
          facilityId,
          id: stopId,
          shipmentId,
          isDropOff,
          isPickup,
          plannedDate,
          references: stopReferences
        } = stops[i];
        if (!facilityId) {
          continue; // not eligible for dock scheduling
        }
        // do not filter down the start and end dates of the appointments or there will be strange results.
        const stopAppointments = await getFacilityAppointments(
          facilityId,
          null,
          null,
          null,
          q,
          '' as AppointmentStatusEnum,
          null,
          null,
          null,
          {
            shipmentId: shipmentId,
            stopId: stopId
          }
        );

        let stopFacility: Facility | undefined = facilityMap.get(facilityId);
        if (!stopFacility) {
          stopFacility = await getFacility(facilityId);
          facilityMap.set(facilityId, stopFacility);
        }
        const stopAppointmentsEntries = stopAppointments
          ?.filter((value) => value.status !== AppointmentStatusEnum.Unscheduled) // do not include appointments which are unscheduled
          ?.map((appointment) => makeEntry(appointment, stopFacility?.address.timezone));

        const timezone: string = stopFacility?.address.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        const mostRecentAppointment = getMostRecentAppointment(stopAppointmentsEntries);
        const carrierAppointment: CarrierAppointment = {
          id: mostRecentAppointment?.id ?? null,
          start: mostRecentAppointment?.start
            ? formatParts(new Date(mostRecentAppointment.start.timestamp), timezone)
            : null,
          end: mostRecentAppointment?.end ? formatParts(new Date(mostRecentAppointment.end.timestamp), timezone) : null,
          status: mostRecentAppointment?.status || 'UNSCHEDULED',
          shipmentId: shipmentId ?? mostRecentAppointment?.scheduledResourceId,
          stopId: stopId ?? null,
          plannedDate: plannedDate,
          facility: {
            id: stopFacility?.id,
            name: stopFacility?.name,
            address: stopFacility?.address
          },
          references:
            (mostRecentAppointment?.references?.length ? mostRecentAppointment.references : stopReferences) ?? [],
          deliveryType: isPickup ? 'SHIPPING' : isDropOff ? 'RECEIVING' : null,
          type: mostRecentAppointment?.appointmentType || 'BY_APPOINTMENT_ONLY'
        };

        appointments = [...appointments, carrierAppointment];
      }

      const appointmentEntries = appointments.filter(({facility, status}) => {
        if (!facility.id) {
          return false; // in some cases we may have failed to get a facility. In that case we can't schedule and we don't want to allow the consumer to do anything with the data for now.
        }
        if (!statuses?.length) {
          return true; // good to go
        }
        return statuses.includes(status); // only get the result if it's within the asked for status
      });
      return appointmentEntries;
    },
    {
      ...options,
      enabled: (options?.enabled ?? true) && Boolean(authData?.company?.id)
    }
  );

  return {
    carrierAppointmentQueryKey: queryKey,
    isCarrierAppointmentInitialLoading: isInitialLoading || query.isInitialLoading,
    isCarrierAppointmentLoading: isLoading || query.isLoading,
    isCarrierAppointmentError: isError || query.isError,
    isCarrierAppointmentSuccess: isSuccess && query.isSuccess,
    // need to convert the legacy style error from useUserMe
    carrierAppointmentError: error
      ? convertShipwellAxiosError(error as AxiosError<ShipwellError>)
      : null || query.error || null,
    appointments: query.data || [],
    refetchAppointments: query.refetch,
    isCarrierAppointmentsFetching: isFetching || query.isFetching,
    isCarrierAppointmentsRefetching: isRefetching || query.isRefetching
  };
};

export default useCarrierAppointments;
