import moment from 'moment';
import {Component} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {change, FormName, getFormValues, formValueSelector} from 'redux-form';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import MaterialUiTooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from '@material-ui/core/styles';
import {SvgIcon, Tooltip, Button} from '@shipwell/shipwell-ui';
import isEqual from 'lodash/isEqual';
import {defaultStopsFields, defaultStopFields} from './utils/constants';
import {getPurchaseOrderByShipment} from 'App/containers/purchaseOrders/actions/async';
import ShipmentStopFields from 'App/formComponents/formSections/shipmentStopFields';
import {getDistanceBetweenStops} from 'App/api/locations';
import './styles.scss';

// Create DOM element for portal
const portal = document.createElement('div');
portal.classList.add('mini-stop-draggable');
document.body.appendChild(portal);

const StopTooltip = withStyles({
  tooltip: {
    background: '#26282b',
    padding: '10px',
    maxWidth: '100%',
    margin: 0
  }
})(MaterialUiTooltip);

/**
 * Tooltip Draggable Item -
 * Needs to be in portal to avoid positioning inaccuracies from tranform
 * @todo Abstract out into components
 */
class TooltipDraggableItem extends Component {
  render() {
    const {stop, index, isDraggable, provided, snapshot} = this.props;
    const usePortal = snapshot.isDragging;

    const child = (
      <div
        {...provided.draggableProps}
        {...Object.assign({}, provided.dragHandleProps, {draggable: isDraggable})}
        ref={provided.innerRef}
        style={{
          ...provided.draggableProps.style,
          color: snapshot.isDragging ? '#fff' : 'inherit',
          backgroundColor: '#26282b',
          zIndex: '99999'
        }}
        className="shipment-stop-mini draggable-option"
      >
        <i className="drag-icon">{isDraggable && <span>&#9776;</span>}</i>
        <span>
          Stop {index + 1}:{' '}
          {[stop.location.company_name, stop.location.address.formatted_address].filter((field) => field).join(' - ')}
        </span>
      </div>
    );

    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

/**
 * Shipment Stop Fields
 */
class ShipmentStopsFields extends Component {
  constructor() {
    super();

    this.state = {
      stopDeleteConfirmation: null,
      stopOrderTooltip: null,
      showPurchaseOrdersModal: false,
      isSAddingPO: false,
      stopDistances: {}
    };
  }

  static defaultProps = {
    hasFTL: false,
    slimForm: false,
    detailsForm: false,
    shipmentStops: []
  };

  componentDidMount() {
    const {values, shipmentStops, fields} = this.props;

    if (values && values.id) {
      this.props.dispatch(getPurchaseOrderByShipment(values.id));
    }
    if (shipmentStops && !shipmentStops.length) {
      const [firstStop, secondStop] = defaultStopsFields;

      fields.push(firstStop);
      fields.push(secondStop);
    }
  }

  hasAddressChanged(values, prevProps) {
    const currentLocationHistory = values.stops.map((stop) => stop.location?.address);
    const previousLocationHistory = prevProps.values.stops.map((stop) => stop.location?.address);

    return isEqual(currentLocationHistory, previousLocationHistory);
  }

  async componentDidUpdate(prevProps) {
    const {user, company, values, externalForm} = this.props;

    if (values && values.id && prevProps.values && prevProps.values.id !== values.id) {
      this.props.dispatch(getPurchaseOrderByShipment(values.id));
    }

    if (
      !externalForm &&
      values &&
      values.stops &&
      values.stops.length > 1 &&
      prevProps.values &&
      prevProps.values.stops &&
      !this.hasAddressChanged(values, prevProps)
    ) {
      const requests = new Array(values.stops.length);
      //never show a distance on the first stop
      requests[0] = null;
      for (let i = 0; i < values.stops.length - 1; i++) {
        if (
          values.stops[i] &&
          values.stops[i + 1] &&
          prevProps.values.stops[i] &&
          prevProps.values.stops[i + 1] &&
          (!_.isEqual(values.stops[i].location, prevProps.values.stops[i].location) ||
            !_.isEqual(values.stops[i + 1].location, prevProps.values.stops[i + 1].location))
        ) {
          if (
            values.stops[i].location &&
            values.stops[i].location.address &&
            values.stops[i].location.address.formatted_address &&
            values.stops[i + 1].location &&
            values.stops[i + 1].location.address &&
            values.stops[i + 1].location.address.formatted_address
          ) {
            const body = {};
            body.origin = values.stops[i].location.address;
            body.destination = values.stops[i + 1].location.address;
            requests[i + 1] = body;
          } else if (
            (values.stops[i].location &&
              values.stops[i].location.address &&
              !values.stops[i].location.address.formatted_address) ||
            (values.stops[i + 1].location &&
              values.stops[i + 1].location.address &&
              !values.stops[i + 1].location.address.formatted_address)
          ) {
            requests[i + 1] = null;
          }
        } else {
          requests[i + 1] = this.state.stopDistances[i + 1] || null;
        }
      }
      this.getDistances(requests);
    }
  }

  async getDistances(stops) {
    try {
      const distancesBetweenStopsResponses = await Promise.all(
        stops.map((stop) => {
          return stop?.origin && stop?.destination ? getDistanceBetweenStops(stop) : null;
        })
      );
      this.setState({
        stopDistances: distancesBetweenStopsResponses.map((stopDistance) =>
          stopDistance ? stopDistance.body?.distance_miles : null
        )
      });
    } catch (error) {
      console.error(error);
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentWillUnmount() {
    // @todo - set polling once DataDocks increases usage rates
    // clearInterval(this.dataDockPolling)
  }

  /**
   * Add new stop field to list
   * @param  {Object} e Click event
   */
  handleAddField(e) {
    e.preventDefault();
    const {slimForm, shipmentStops} = this.props;
    let latestDate = defaultStopFields.planned_date;

    if (shipmentStops) {
      shipmentStops.forEach(({planned_date}) => {
        if (planned_date && planned_date > latestDate) {
          latestDate = planned_date;
        }
      });
    }

    this.props.fields.push({
      ...defaultStopFields,
      is_pickup: false,
      is_dropoff: true,
      rKey: this.props.fields.length + 1,
      planned_date: latestDate,
      ordinal_index: this.props.fields.length,
      planning_window: {
        start: moment(Date.now())
          .add(this.props.fields.length * 2, 'days')
          .hours(8)
          .format('YYYY-MM-DD HH:mm'),
        end: moment(Date.now())
          .add(this.props.fields.length * 2, 'days')
          .hours(18)
          .format('YYYY-MM-DD HH:mm')
      },
      location: {
        ...defaultStopFields.location,
        point_of_contacts: slimForm ? [] : defaultStopFields.location.point_of_contacts
      }
    });
  }

  /**f
   * Remove stop from field
   */
  handleRemoveStop(index, e) {
    e.preventDefault();
    const {shipmentStops, purchaseOrderStop, fields} = this.props;
    const stopId = shipmentStops[index].id;

    if (purchaseOrderStop[stopId] && purchaseOrderStop[stopId].length) {
      this.handleToggleDeleteConfirmation(stopId);
    } else {
      fields.remove(index);
    }
  }

  handleDragEnd(position) {
    this.handleStopReordering(position);
  }

  /**
   * Reposition and set ordinal index on shipment stops. First stop and last stop
   * cannot be reordered unless replaced by another stop with property is_pickup or is_dropoff status.
   * @param  {Object} position   Includes destination and source index
   */
  handleStopReordering(position) {
    const {destination, source} = position;
    const {shipmentStops, form} = this.props;
    const isPickupOnly = shipmentStops[source.index].is_pickup && !shipmentStops[source.index].is_dropoff;
    const isDropoffOnly = !shipmentStops[source.index].is_pickup && shipmentStops[source.index].is_dropoff;
    const isFirstStopEnabled = destination && destination.index === 0 && isPickupOnly;
    const isLastStopEnabled = destination && destination.index === shipmentStops.length - 1 && isDropoffOnly;
    const isFirstOrLastStop =
      destination && (destination.index === 0 || destination.index === shipmentStops.length - 1);

    /** first and last stop cannot be reordered */
    if (!isFirstOrLastStop || isFirstStopEnabled || isLastStopEnabled) {
      let stops = [...shipmentStops];

      if (destination) {
        const [movedItem] = stops.splice(source.index, 1);

        stops.splice(destination.index, 0, movedItem);
        stops = stops.map((stop, index) => Object.assign({}, stop, {ordinal_index: index}));

        this.props.dispatch(change(form, 'stops', stops));
      }
    }
  }

  handleToggleDeleteConfirmation(stopId) {
    this.setState({stopDeleteConfirmation: stopId || null});
  }

  handleShowStopOrderTooltip(index) {
    this.setState({stopOrderTooltip: index});
  }

  handleHideStopOrderTooltip(event) {
    this.setState({stopOrderTooltip: null});
  }

  render() {
    const {stopDeleteConfirmation, stopOrderTooltip, stopDistances} = this.state;
    const {
      hasFTL,
      hasLTL,
      hasVLTL,
      hasDrayage,
      hasParcel,
      hasIntermodal,
      fields,
      shipmentStops,
      slimForm,
      detailsForm,
      isConfirmationForm,
      externalForm,
      quote,
      meta: {error},
      customFields
    } = this.props;
    const allowMultiStop = hasFTL || hasDrayage || (!slimForm && hasVLTL);

    return (
      <div className="shipment-stops">
        <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
          <Droppable droppableId="stop-droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={`draggable-item ${snapshot.isDraggingOver ? 'dragover' : ''}`}>
                {fields.map((stop, index) => {
                  const isPickupOnly = shipmentStops[index].is_pickup && !shipmentStops[index].is_dropoff;
                  const isDropoffOnly = !shipmentStops[index].is_pickup && shipmentStops[index].is_dropoff;
                  const isFirstPickupStop = index === 0 && isPickupOnly;
                  const isLastDropoffStop = index === shipmentStops.length - 1 && isDropoffOnly;
                  const isDragDisabled = Boolean(isFirstPickupStop || isLastDropoffStop);
                  const stopDistanceInMiles =
                    stopDistances?.[index] && !isNaN(stopDistances[index]) ? stopDistances[index] : null;
                  return (
                    <Draggable
                      key={index}
                      index={index}
                      draggableId={shipmentStops[index]?.id || shipmentStops[index]?.rKey}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...Object.assign({}, provided.dragHandleProps, {draggable: !isDragDisabled})}
                          ref={provided.innerRef}
                          style={{
                            boxShadow: snapshot.isDragging ? '3px 3px 3px rgba(0, 0, 0, 0.069)' : 'none',
                            ...provided.draggableProps.style
                          }}
                          id={`stop-${index + 1}`}
                          className={`shipment-stop ${hasParcel && 'parcel'} draggable-option`}
                        >
                          {index !== 0 && index !== shipmentStops.length - 1 && <i className="drag-icon">&#9776;</i>}
                          <div className="shipment-stop-header">
                            <span>
                              <i className={`icon-marker-${index + 1} pad-right text-primary`} /> Stop {index + 1}
                              {fields.length > 2 && (
                                <StopTooltip
                                  disableTouchListener
                                  disableHoverListener
                                  open={stopOrderTooltip === index}
                                  placement="bottom-start"
                                  title={
                                    <ClickAwayListener onClickAway={this.handleHideStopOrderTooltip.bind(this)}>
                                      <div className="order-tooltip">
                                        <Droppable
                                          type="mini-stop"
                                          droppableId={`stop-mini-droppable-${
                                            shipmentStops[index]?.id || shipmentStops[index]?.rKey
                                          }`}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              className={`shipment-stops-mini ${
                                                snapshot.isDraggingOver ? 'dragover' : ''
                                              }`}
                                            >
                                              {shipmentStops?.map((shipmentStop, stopIndex) => {
                                                const isPickupOnly = shipmentStop.is_pickup && !shipmentStop.is_dropoff;
                                                const isDropoffOnly =
                                                  !shipmentStop.is_pickup && shipmentStop.is_dropoff;
                                                const isFirstPickupStop = stopIndex === 0 && isPickupOnly;
                                                const isLastDropoffStop =
                                                  stopIndex === shipmentStops.length - 1 && isDropoffOnly;
                                                const isDragDisabled = Boolean(isFirstPickupStop || isLastDropoffStop);
                                                return (
                                                  <Draggable
                                                    key={`${shipmentStop?.id || shipmentStop?.rKey}-tooltip`}
                                                    index={stopIndex}
                                                    draggableId={`${shipmentStop?.id || shipmentStop?.rKey}-tooltip`}
                                                    isDragDisabled={isDragDisabled}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <TooltipDraggableItem
                                                        index={stopIndex}
                                                        stop={shipmentStop}
                                                        isDraggable={!isDragDisabled}
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                      />
                                                    )}
                                                  </Draggable>
                                                );
                                              })}
                                            </div>
                                          )}
                                        </Droppable>
                                      </div>
                                    </ClickAwayListener>
                                  }
                                  PopperProps={{className: 'stop-index-tooltip'}}
                                  TransitionProps={{timeout: {enter: 0, exit: 0}}}
                                  TransitionComponent={Zoom}
                                  onClose={this.handleHideStopOrderTooltip.bind(this)}
                                >
                                  <i
                                    className="dropdown-arrow"
                                    onClick={this.handleShowStopOrderTooltip.bind(this, index)}
                                  />
                                </StopTooltip>
                              )}
                              {stopDistanceInMiles ? (
                                <Tooltip
                                  trigger="hover"
                                  tooltipContent="Mileage may fluctuate based on customer details."
                                >
                                  <span className="shipment-stop__distance">{`${stopDistanceInMiles} miles* from stop ${index}`}</span>
                                </Tooltip>
                              ) : null}
                            </span>

                            <div>
                              {shipmentStops.length > 2 && (
                                <StopTooltip
                                  disableHoverListener
                                  open={stopDeleteConfirmation === shipmentStops[index].id}
                                  placement="top"
                                  title={
                                    <div className="order-tooltip">
                                      <h3>Delete Stop</h3>
                                      <p>Please move or remove all orders assocated with this stop before deleting.</p>
                                      <div className="stop-tooltip-footer">
                                        <button
                                          className="btn btn-primary"
                                          onClick={this.handleToggleDeleteConfirmation.bind(this)}
                                        >
                                          Ok
                                        </button>
                                      </div>
                                    </div>
                                  }
                                  PopperProps={{className: 'md-tooltip'}}
                                  TransitionProps={{timeout: {enter: 100, exit: 0}}}
                                  TransitionComponent={Zoom}
                                >
                                  <a href="#" className="trashButton" onClick={this.handleRemoveStop.bind(this, index)}>
                                    <SvgIcon name="TrashOutlined" />
                                  </a>
                                </StopTooltip>
                              )}
                            </div>
                          </div>
                          <FormName>
                            {({form}) => (
                              <ShipmentStopFields
                                externalForm={externalForm}
                                form={form}
                                name={stop}
                                index={index}
                                modes={{hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal}}
                                slimForm={slimForm}
                                detailsForm={detailsForm}
                                quote={quote}
                                isConfirmationForm={isConfirmationForm}
                                customFields={customFields}
                              />
                            )}
                          </FormName>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {allowMultiStop && (
          <div className="lineitem-footer">
            <Button onClick={this.handleAddField.bind(this)} variant="tertiary" iconName="Plus">
              Add stop
            </Button>
          </div>
        )}
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed" />
            {error}
          </p>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({
  company: state.auth.company,
  shipmentStops: formValueSelector(props.form)(state, 'stops'),
  purchaseOrder: state.purchaseOrders.details.purchaseOrdersById,
  purchaseOrderStop: state.purchaseOrders.details.purchaseOrdersByStop,
  values: getFormValues(props.form)(state),
  selectedPOs: getFormValues('purchaseOrderSearchForm')(state)
}))(ShipmentStopsFields);
