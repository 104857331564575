import {CarrierRelationship} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getCarrierRelationshipsByIdPromise} from 'App/api/carriers/typed';
import {CARRIER_RELATIONSHIPS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetCarrierRelationshipsById = ({
  vendorId,
  options
}: {
  vendorId: string;
  options?: Omit<
    UseQueryOptions<CarrierRelationship, unknown, CarrierRelationship, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const query = useQuery(
    [CARRIER_RELATIONSHIPS_QUERY_KEY, vendorId],
    async () => {
      const response = await getCarrierRelationshipsByIdPromise(vendorId);
      return response.data;
    },
    options
  );

  const {data: carrierRelationships, status: carrierRelationshipStatus} = query;

  return {
    carrierRelationships,
    carrierRelationshipStatus,
    query
  };
};
