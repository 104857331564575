import {MacropointDetailsFormValues} from './macropointDetailsFormValues';
import {initialValues} from './initialValues';
import {validationSchema} from './validationSchema';
import {useEnhancedFormik} from 'App/hooks/useEnhacedFormik/useEnhacedFormik';

export interface UseTiveDetailsFormProps {
  initialValues?: MacropointDetailsFormValues;
  onSubmit: (values: MacropointDetailsFormValues) => void;
}

export function useMacropointDetailsForm(props: UseTiveDetailsFormProps) {
  const form = useEnhancedFormik<MacropointDetailsFormValues>({
    initialValues: props.initialValues || initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: props.onSubmit
  });

  return form;
}
