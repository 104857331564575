import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';
import {OverlayTrigger, Tooltip, Button, Row, Col, Form} from 'react-bootstrap';
import {Link} from 'react-router';
import * as actions from '../../actions/shipments';
import * as brokerActions from '../../actions/brokers';
import renderField from 'App/formComponents/renderField';
import renderAsyncSelect from 'App/formComponents/renderAsyncSelect';
import renderEmailMultiselect from 'App/formComponents/renderEmailMultiselect';
import renderTextArea from 'App/formComponents/renderTextArea';
import './_send-customer-quote.scss';
import validate from 'App/utils/validateSendCustomerQuote';

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    company: state.auth.company,
    authenticated: state.auth.authenticated
  }),
  {...actions, ...brokerActions}
)
class SendCustomerQuote extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.getCustomerOptions = this.getCustomerOptions.bind(this);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 25,
        ordering: 'company',
        q: '',
        expandUsers: true
      }
    };
  }

  getCustomerOptions(input) {
    const pagination = this.state.pagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = null;
    }
    return this.props
      .fetchBrokerShipperRelationshipsForTypeaheads(this.props.company.brokerage.id, pagination)
      .then((response) => {
        if (response.status === 200) {
          const POCs = [];
          response.details.results.forEach((item) => {
            if (item.company && item.company.id !== this.props.company.id && item.company.users.length > 0) {
              item.company.users.forEach((poc) => {
                const nameStr =
                  item.company.name +
                  ' - ' +
                  poc.first_name +
                  (poc.last_name ? ' ' + poc.last_name : '') +
                  ' (' +
                  poc.email +
                  ')';
                POCs.push({id: poc.id, name: nameStr});
              });
            }
          });
          return {results: POCs};
        }
      });
  }

  render() {
    const {selectedShipment, handleSubmit, defaultEmails} = this.props;
    return (
      <div className="send-customer-quote">
        {selectedShipment && (
          <div>
            <h5>{'Send Quote #' + selectedShipment.reference_id}</h5>
            <Form onSubmit={handleSubmit}>
              {this.props.authenticated && (
                <Field
                  component={renderAsyncSelect}
                  getOptions={this.getCustomerOptions}
                  name="customers"
                  label="Customers"
                  isMulti
                  valueField="id"
                  labelField="name"
                  placeholder="Search for a customer user"
                />
              )}
              <Field
                label="Message"
                name="message"
                minRows={2}
                component={renderTextArea}
                placeholder="Enter message here"
              />
              <div className="quote-financials-footer">
                <Button onClick={() => this.props.showPreview()} bsStyle="default">
                  <i className="icon icon-Mail pad-right" />
                  Preview Email
                </Button>
                <Button type="submit" bsStyle="primary">
                  <i className="icon icon-Plane pad-right" />
                  Send Quote
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

SendCustomerQuote = reduxForm({
  form: 'sendCustomerQuote',
  enableReinitialize: true,
  validate: validate
})(SendCustomerQuote);

SendCustomerQuote = connect((state) => ({
  initialValues: {
    recipient_emails: state.shipments.sendCustomerQuote && state.shipments.sendCustomerQuote.recipient_emails,
    message: state.marketplace.spotNegotiationEmailPreview && state.marketplace.spotNegotiationEmailPreview.message
  }
}))(SendCustomerQuote);

export default SendCustomerQuote;
