import {WithRouterProps} from 'react-router';
import ServiceProviderForm from 'App/formComponents/forms/serviceProvider';
import PageHeader from 'App/common/pageHeader';

const ServiceProviderCreateContainer = ({router}: WithRouterProps) => {
  const redirectToList = () => {
    router.push('/service-providers');
  };

  return (
    <>
      <PageHeader title="Add Service Provider" backRoute={`/service-providers`} />
      <div className="mx-auto mb-[100px] mt-0 flex w-full max-w-[1600px] flex-col items-center px-5 py-8">
        <ServiceProviderForm onSubmit={redirectToList} onCancel={redirectToList} />
      </div>
    </>
  );
};

export default ServiceProviderCreateContainer;
