import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {object, string} from 'yup';
import {Formik, Form} from 'formik';
import {DeprecatedButton, Title, SvgIcon} from '@shipwell/shipwell-ui';
import CreateRateTable from 'App/containers/userCompany/rateTables';
import {cleanPayload} from 'App/utils/globals';

const intialValues = {
  applies_to: '',
  rate_type: '',
  accessorial: null,
  fuelsurcharge: null
};

export const validationSchema = object().shape({
  applies_to: string().required('Financial side is required.'),
  rate_type: string().required('Rate Type is required.'),
  accessorial: object().when('rate_type', {
    is: (val) => val === 'accessorial',
    then: object().required('Table is required.').typeError('Table selection is required.'),
    otherwise: object().nullable()
  }),
  fuelsurcharge: object().when('rate_type', {
    is: (val) => val === 'fuelsurcharge',
    then: object().required('Table is required.').typeError('Table selection is required.'),
    otherwise: object().nullable()
  })
});

const ShipmentRateTableForm = ({customer, vendor, shipment, onSubmit}) => {
  const handleSubmit = (values) => {
    if (!values) {
      return;
    }
    const {applies_to: financialSide, rate_type: rateType} = values;

    const payload = {
      ...shipment
    };

    if (financialSide === 'CUSTOMER_SIDE' && rateType === 'accessorial') {
      payload.metadata.customer_side_accessorial_charge_table = values.accessorial?.id;
    } else if (financialSide === 'CUSTOMER_SIDE' && rateType === 'fuelsurcharge') {
      payload.metadata.customer_side_fuel_surcharge_table = values.fuelsurcharge?.id;
    } else if (financialSide === 'CARRIER_SIDE' && rateType === 'accessorial') {
      payload.metadata.vendor_side_accessorial_charge_table = values.accessorial?.id;
    } else if (financialSide === 'CARRIER_SIDE' && rateType === 'fuelsurcharge') {
      payload.metadata.vendor_side_fuel_surcharge_table = values.fuelsurcharge?.id;
    }

    if (onSubmit) {
      const cleanObj = cleanPayload(payload);
      onSubmit(cleanObj);
    }
  };

  return (
    <div className="rateTable__financial">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{...intialValues}}
        onSubmit={handleSubmit}
      >
        {({dirty, isSubmitting, values, setFieldValue}) => (
          <Form className="ShipmentRateTableForm" noValidate="novalidate">
            <Title variant="formTitle" className="p-2">
              Select a rate table to apply to your shipment financials.
            </Title>
            <CreateRateTable
              financialSide="customer"
              values={values}
              setFieldValue={setFieldValue}
              shipment={shipment}
              customer={customer ? customer.customer?.name : null}
              vendor={vendor ? vendor.vendor?.name : null}
            />
            <div className="rateTable__financial-footer mt-2">
              <div className="rateTable__financial-footer-actions">
                <DeprecatedButton type="submit" disabled={isSubmitting || !dirty}>
                  {isSubmitting ? (
                    <>
                      <SvgIcon name="Refresh" className="rotate" /> Saving...
                    </>
                  ) : (
                    ' Add Table'
                  )}
                </DeprecatedButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ShipmentRateTableForm.propTypes = {
  customer: PropTypes.object,
  vendor: PropTypes.object,
  onSubmit: PropTypes.func,
  shipment: PropTypes.object
};

ShipmentRateTableForm.defaultProps = {
  shipment: {},
  onSubmit: () => {}
};

export default connect((state) => ({
  shipment: state.shipmentdetails.one
}))(ShipmentRateTableForm);
