/**
 * Wrap FileReader#readAsDataURL, an async file reader method that converts Blob to base64-encoded
 * data URLs, in a promisified version.
 *
 * @param {Blob} blob The Blob to encode
 *
 * @return {Promise} Resolves with the base64-encoded data or rejects with an error.
 */

const base64EncodeBlob = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onerror = () => reject(reader.error);
    reader.onload = (e) => resolve((e.target?.result as string) ?? '');
  });
};

export default base64EncodeBlob;
