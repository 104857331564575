import {
  ALERT_ERROR,
  REQ_DATA,
  FETCH_ALL_DOCUMENTS,
  FETCH_DOCUMENT,
  CREATE_DOCUMENT,
  ERROR_DOCUMENTS,
  FETCH_DOCUMENT_TYPES,
  CREATE_CUSTOMER_DOCUMENT,
  UPDATE_DOCUMENT,
  SELECTED_DOCUMENT,
  DOCUMENT_ADD,
  FETCH_DOC_AUDIT_LOG,
  FETCH_CARRIER_DOCUMENTS,
  UPDATE_CARRIER_DOCUMENT
} from './types';
import {shipment} from 'App/api';

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

export const requestData = () => ({
  type: REQ_DATA
});

/**
 * Shipment Documents
 */
export const shipmentDocumentsRequestSuccess = (response) => ({
  type: FETCH_ALL_DOCUMENTS,
  payload: response.body
});

export const shipmentDocumentRequestSuccess = (response) => ({
  type: FETCH_DOCUMENT,
  payload: response.body
});

export const shipmentDocumentCreateSuccess = (response) => ({
  type: CREATE_DOCUMENT,
  payload: response.body
});

export const shipmentDocumentCreateError = (response) => ({
  type: ERROR_DOCUMENTS,
  payload: response.body
});

export const documentTypesRequestSuccess = (response) => ({
  type: FETCH_DOCUMENT_TYPES,
  payload: response.body
});

export const documentCreateSuccess = (response) => ({
  type: CREATE_CUSTOMER_DOCUMENT,
  payload: response.data
});

export const documentUpdateSuccess = (response) => ({
  type: UPDATE_DOCUMENT,
  payload: response.body
});

export const documentCreateError = (response) => ({
  type: ERROR_DOCUMENTS,
  payload: response.body
});

export function selectDocEdit(doc) {
  return (dispatch) => {
    dispatch({
      type: SELECTED_DOCUMENT,
      payload: doc
    });
  };
}

export function selectDocAdd() {
  return (dispatch) => {
    dispatch({type: DOCUMENT_ADD});
  };
}

export function fetchDocuments(shipmentId, opts = {pageSize: 1000}) {
  return async (dispatch) => {
    dispatch(requestData());
    try {
      const response = await shipment.getShipmentDocuments(shipmentId, opts);
      dispatch(shipmentDocumentsRequestSuccess(response));
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function fetchDocument(shipmentId, documentId) {
  return async (dispatch) => {
    dispatch(requestData());
    try {
      const response = await shipment.getShipmentDocument(shipmentId, documentId);
      dispatch(shipmentDocumentRequestSuccess(response));
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function postDocument(shipmentId, body = {}) {
  return async (dispatch) => {
    dispatch(requestData());
    try {
      const response = await shipment.createShipmentDocument(shipmentId, body);
      dispatch(shipmentDocumentCreateSuccess(response));
      return response;
    } catch (error) {
      dispatch(shipmentDocumentCreateError(error));
      return error;
    }
  };
}

export function deleteDocument(shipmentId, documentId) {
  return async () => {
    try {
      return await shipment.deleteShipmentDocument(shipmentId, documentId);
    } catch (error) {
      return error;
    }
  };
}

export function fetchDocumentTypes() {
  return async (dispatch) => {
    try {
      const response = await shipment.getDocumentTypes();
      dispatch(documentTypesRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

export function putDocument(shipmentId, documentId, body = {}) {
  return async (dispatch) => {
    try {
      const response = await shipment.updateShipmentDocument(shipmentId, documentId, body);
      dispatch(documentUpdateSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

/**
 * Shipment Audit Log
 */
export const auditLogRequestSuccess = (response) => ({
  type: FETCH_DOC_AUDIT_LOG,
  payload: response.body
});

export function getDocAuditLog(shipmentId) {
  return async (dispatch) => {
    try {
      const response = await shipment.getShipmentDocumentsAuditlog(shipmentId);
      dispatch(auditLogRequestSuccess(response));
      return response;
    } catch (error) {
      dispatch(displayError(error));
      return error;
    }
  };
}

/**
 * Carrier Documents
 */
export const carrierDocumentRequestSuccess = (response) => ({
  type: FETCH_CARRIER_DOCUMENTS,
  payload: response.body
});

export const carrierDocumentUpdateSuccess = (response) => ({
  type: UPDATE_CARRIER_DOCUMENT,
  payload: response.body
});

export function fetchCarrierDocuments(carrierRelationshipId, opts = {}) {
  return async (dispatch) => {
    try {
      const response = await shipment.getCarrierDocuments(carrierRelationshipId, opts);
      dispatch(carrierDocumentRequestSuccess(response));
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function postCarrierDocument(formProps, carrierRelationshipId) {
  return async (dispatch) => {
    dispatch(requestData());
    try {
      const response = await shipment.configCarrierDocumentPost(formProps, carrierRelationshipId);
      dispatch(documentCreateSuccess(response));
      return response;
    } catch (error) {
      dispatch(shipmentDocumentCreateError(error));
      return error;
    }
  };
}
