/* eslint-disable react/jsx-key */
import {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  useRowSelect,
  useSortBy,
  usePagination,
  useAsyncDebounce
} from 'react-table';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import classnames from 'classnames';
import {withRouter} from 'react-router';
import {SvgIcon} from '@shipwell/shipwell-ui';
import useSelectionColumn from 'App/components/Table/plugins/useSelectionColumn';
import usePaginationFooter from 'App/components/Table/plugins/usePaginationFooter';
import {PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import buildPathParams from 'App/utils/buildPathParams';
import Loader from 'App/common/shipwellLoader';
import {useUpdateUserTableRowDefaults} from 'App/utils/hooks/useUserTableHooks';
import usePrevious from 'App/utils/hooks/usePrevious';

/**
 * Per React Table documentation, `columns` and `data` _must_ be memoized or otherwise static.
 * https://react-table.tanstack.com/docs/api/overview#option-memoization
 *
 * Likewise, `onSelection` should be memoized with `useCallback` when used. It is called by an
 * effect whenever the row selection updates, and its definition needs to remain static so that it
 * doesn't also fire whenever `onSelection` is re-defined and passed as a prop.
 */
function Table({
  columns,
  data,
  showRowSelect,
  itemLabel,
  onSelection,
  footerGroupsEnabled,
  isTransparent,
  onFetchData,
  allowSort,
  manualSort,
  showPaginationFooter,
  pageCount: controlledPageCount,
  shouldResetPageRef,
  initialPageSize,
  router,
  dataIsLoading,
  onRowClick,
  selectedRowId,
  isDraggable,
  onDragEnd,
  initialSortKey,
  tableType,
  footerContent
}) {
  const memoizedInitialSortKey = useMemo(() => {
    return initialSortKey ? [{id: initialSortKey, desc: false}] : [];
  }, [initialSortKey]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    renderRowSelectionSummary,
    renderPaginationFooter,
    footerGroups,
    state: {sortBy, pageIndex, pageSize},
    setSortBy
  } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSort,
      manualPagination: true,
      shouldResetPageRef,
      autoResetPage: false,
      autoResetExpanded: false,
      disableSortBy: !allowSort,
      initialState: {
        showRowSelect,
        showPaginationFooter,
        itemLabel,
        onSelection,
        pageIndex: 0,
        pageSize: initialPageSize,
        sortBy: memoizedInitialSortKey
      },
      pageCount: controlledPageCount,
      useControlledState: (state) =>
        useMemo(() => {
          if (shouldResetPageRef) {
            const newState = shouldResetPageRef.current ? {...state, pageIndex: 0} : state;
            shouldResetPageRef.current = false;
            return newState;
          }
          return state;
        }, [state])
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSelectionColumn,
    usePaginationFooter
  );

  const cellStyles = ['relative', 'm-0', 'py-2', 'px-2', 'flex', 'gap-4', 'justify-start', 'items-center'];
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 300);

  useEffect(() => {
    if (manualSort && allowSort) {
      if (sortBy.length > 0) {
        const {id, desc} = sortBy[0];
        const ordering = `${desc ? '-' : ''}${id}`;
        const updatedPath = buildPathParams(location, {ordering: ordering});
        router.push(updatedPath);
      } else if (initialSortKey) {
        setSortBy([{id: initialSortKey, desc: false}]);
        router.push(buildPathParams(location, {ordering: initialSortKey}));
      } else {
        router.push(buildPathParams(location, {}));
      }
    }
  }, [sortBy, router, manualSort, allowSort, initialSortKey, setSortBy]);

  useEffect(() => {
    onFetchDataDebounced({sortBy, pageIndex, pageSize});
  }, [sortBy, pageIndex, pageSize, onFetchDataDebounced]);

  const updateUserTableRowDefaults = useUpdateUserTableRowDefaults(tableType);

  const previousPageSize = usePrevious(pageSize);

  useEffect(() => {
    if (previousPageSize && previousPageSize !== pageSize && tableType) {
      updateUserTableRowDefaults(pageSize);
    }
  }, [pageSize, updateUserTableRowDefaults, previousPageSize, tableType]);

  return (
    <div className="flex size-full grow flex-col justify-between overflow-auto">
      <div
        {...getTableProps()}
        className={classnames('relative table h-full', {
          'bg-sw-background-component': !isTransparent
        })}
      >
        <div
          key="table"
          className={classnames('sticky top-0 z-10 overflow-hidden bg-sw-background-component', {
            'border-b-1 border-sw-border': !isTransparent
          })}
        >
          {headerGroups.map((headerGroup, index) => (
            <div key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <>
                  <div
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames('last:border-r-0', ...cellStyles, {
                      'border-r-1 border-sw-border text-sw-text': !isTransparent
                    })}
                  >
                    <div className="flex items-center gap-2">
                      {allowSort && column.canSort ? (
                        <div
                          className={classnames('flex items-center justify-center rounded-full', {
                            'bg-sw-active-light': column.isSorted
                          })}
                        >
                          <SvgIcon
                            color={column.isSorted ? 'sw-primary' : ''}
                            name={column.isSortedDesc ? 'CarrotUp' : 'CarrotDown'}
                          />
                        </div>
                      ) : null}
                      {column.render('Header')}
                    </div>
                    {column.canResize && (
                      <div
                        /* prevents from scrolling while dragging on touch devices */
                        style={{touchAction: 'none'}}
                        {...column.getResizerProps()}
                        className="absolute -right-1 top-0 z-10 h-full w-2"
                      />
                    )}
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
        {!dataIsLoading ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table-rows">
              {(provided) => (
                <div ref={provided.innerRef} {...getTableBodyProps()} {...provided.droppableProps}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    const isSelectedRow = row.original?.id && row.original.id === selectedRowId;
                    return (
                      <Draggable key={index} index={index} draggableId={row.id} isDragDisabled={!isDraggable}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classnames('last:rounded-b', {
                              'text-sw-text hover:outline hover:outline-sw-primary': !isTransparent,
                              'odd:bg-sw-background': !isTransparent && !isSelectedRow,
                              'bg-sw-active-light-hex outline outline-1 outline-sw-active': isSelectedRow
                            })}
                          >
                            <div
                              {...row.getRowProps({
                                onClick: () => onRowClick(row)
                              })}
                            >
                              {row.cells.map((cell, i) => {
                                return (
                                  <div {...cell.getCellProps()} className={classnames('flex flex-col ', ...cellStyles)}>
                                    {i === 0 && isDraggable ? (
                                      <div {...provided.dragHandleProps}>
                                        <SvgIcon width="9" name="Drag" />
                                      </div>
                                    ) : null}
                                    {cell.render('Cell')}
                                    {cell.column.id === 'rate.amount' && row.isExpanded ? (
                                      <div className="flex w-full flex-row justify-end">
                                        {row.original.expandedContent}
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Loader loading />
        )}
        {footerGroupsEnabled ? (
          <div className="overflow-hidden">
            {footerGroups.map((footerGroup) => (
              <div {...footerGroup.getFooterGroupProps({})}>
                {footerGroup.headers.map((column) => (
                  <div
                    {...column.getFooterProps()}
                    className={classnames(
                      'flex flex-col items-start justify-center px-4 py-2 font-bold text-sw-text-reverse',
                      {'bg-sw-primary': !isTransparent}
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {renderRowSelectionSummary()}
      {renderPaginationFooter(footerContent)}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.array.isRequired,
  showRowSelect: PropTypes.bool,
  itemLabel: PropTypes.string,
  onSelection: PropTypes.func,
  footerGroupsEnabled: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onFetchData: PropTypes.func,
  allowSort: PropTypes.bool,
  manualSort: PropTypes.bool,
  showPaginationFooter: PropTypes.bool,
  pageCount: PropTypes.number,
  shouldResetPageRef: PropTypes.shape({current: PropTypes.bool}),
  initialPageSize: PropTypes.number,
  router: PROPTYPE_ROUTER,
  dataIsLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  selectedRowId: PropTypes.string,
  isDraggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  initialSortKey: PropTypes.string,
  tableType: PropTypes.string,
  footerContent: PropTypes.element
};

Table.defaultProps = {
  columns: [],
  data: [],
  showRowSelect: false,
  onSelection: () => {},
  onFetchData: () => {},
  allowSort: false,
  manualSort: true,
  showPaginationFooter: false,
  initialPageSize: 10,
  router: {push: () => {}},
  dataIsLoading: false,
  onRowClick: () => undefined,
  isDraggable: false,
  tableType: null
};

export default withRouter(Table);
