import {SpotNegotiation, ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {createSpotNegotiationFromLoadboard} from '../typed';

type CreateSpotNegotiationFromLoadboardPayload = {
  loadBoardShipmentId: string;
  spotNegotiation: SpotNegotiation;
};

const useCreateSpotNegotiationFromLoadboard = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createSpotNegotiationFromLoadboard>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationFromLoadboardPayload
  >
) => {
  return useMutation<
    Awaited<ReturnType<typeof createSpotNegotiationFromLoadboard>>,
    AxiosError<ShipwellError>,
    CreateSpotNegotiationFromLoadboardPayload
  >(
    ({loadBoardShipmentId, spotNegotiation}) =>
      createSpotNegotiationFromLoadboard({loadBoardShipmentId, spotNegotiation}),
    mutationOptions
  );
};

export default useCreateSpotNegotiationFromLoadboard;
