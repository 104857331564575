import {PaginatedAddressBookEntry} from '@shipwell/backend-core-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';
import {ShipwellApiErrorResponse} from 'App/utils/errors';
import {ADDRESS_BOOK_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type UseSearchAddressBookEntryQueryOptions = Omit<
  UseQueryOptions<
    PaginatedAddressBookEntry | null,
    AxiosError<ShipwellApiErrorResponse>,
    PaginatedAddressBookEntry | null,
    (string | null | undefined)[]
  >,
  'queryKey' | 'queryFn'
>;

/**
 * Returns a query that can be used to search for an address book entry. If the search text is null or empty, then
 * the query will be disabled and return `null`. You may also combine the enabled logic with your own using the
 * options.enabled property.
 * Supported Fields:
 *  - address__address_1
 *  - address__city
 *  - state_province
 *  - address__postal_code
 *  - location_name
 *  - company_name
 *  - notes
 *  - location_type__name
 *  - point_of_contacts__email
 *  - point_of_contacts__first_name
 *  - point_of_contacts__last_name
 *  - external_reference
 *  - facility_id
 */
const useSearchAddressBookEntryQuery = (
  searchText?: string | null,
  options?: UseSearchAddressBookEntryQueryOptions
) => {
  const enabled = !!searchText && options?.enabled !== false;
  const query = useQuery(
    [ADDRESS_BOOK_QUERY_KEY, searchText],
    async () => {
      if (!searchText) {
        return null;
      }
      const {data} = await getAddressBookAddresses({
        q: searchText
      });

      return data;
    },
    {
      ...options,
      enabled
    }
  );

  return {
    addresses: query.data || ({results: [], page_count: 0, page_size: 0, total_pages: 0} as PaginatedAddressBookEntry),
    isAddressSearchLoading: query.isInitialLoading || query.isRefetching || query.isFetching,
    isAddressSearchError: query.isError,
    addressSearchError: query.error,
    isAddressSearchSuccess: query.isSuccess
  };
};

export default useSearchAddressBookEntryQuery;
