import {Button, Modal} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {ReactNode, useState} from 'react';
import {AddOrdersToShipmentListView} from 'App/containers/orders/views/AddOrdersToShipmentListView';
import useToggle from 'App/utils/hooks/useToggle';

export type ContainerType = 'addOrder' | 'modifyStops' | 'updateOrderDates';

export const AddOrdersButton = ({children, onClick}: {children: ReactNode; onClick: () => void}) => {
  const {stmV3OrdersTable} = useFlags();
  const [showAddOrdersToShipmentModal, toggleShowAddOrdersToShipmentModal] = useToggle();
  const [activeContainer, setActiveContainer] = useState<ContainerType>('addOrder');

  const handleCloseModal = () => {
    toggleShowAddOrdersToShipmentModal();
    setActiveContainer('addOrder');
  };
  const getTitle = () => {
    switch (activeContainer) {
      case 'addOrder':
        return 'Add Orders';
      case 'modifyStops':
        return 'Modify Stops';
      case 'updateOrderDates':
        return 'Update Order Dates';
      default:
        return 'Add Orders';
    }
  };
  if (!stmV3OrdersTable) {
    return (
      <Button iconName="Plus" onClick={onClick} variant="tertiary">
        {children}
      </Button>
    );
  }
  return (
    <>
      <Button iconName="Plus" onClick={handleCloseModal} variant="tertiary">
        {children}
      </Button>
      <Modal
        onClose={handleCloseModal}
        show={showAddOrdersToShipmentModal}
        title={getTitle()}
        size="large"
        footerComponent={null}
      >
        <AddOrdersToShipmentListView
          activeContainer={activeContainer}
          setActiveContainer={setActiveContainer}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};
