import {AppointmentStatusEnum, Facility, FacilityDock, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import {AxiosError} from 'axios';
import {AppointmentEntry} from './types';
import {getFacilityDocks} from 'App/api/facilities';
import {SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useShipmentStopFacilityQuery} from 'App/data-hooks';
import {getFacilityAppointments} from 'App/api/appointments';
import {useShipmentStopQuery} from 'App/data-hooks/stops/useShipmentStops';
import {makeEntry} from 'App/containers/appointments/utils';
import {getMostRecentAppointment} from 'App/data-hooks/appointments/utils';

export type UseShipmentsStopAppointmentQueryRequest = {
  shipmentId?: string | null;
  stopId?: string | null;
};
export type UseShipmentsStopAppointmentQueryResult = {
  facility: Facility | null;
  dock: FacilityDock | null;
  appointment: AppointmentEntry | null;
};
export type UseShipmentsStopAppointmentQueryOptions = {
  shipmentId?: string | null;
  stopId?: string | null;
} & Omit<
  UseQueryOptions<
    UseShipmentsStopAppointmentQueryResult,
    AxiosError<ShipwellApiErrorResponse>,
    UseShipmentsStopAppointmentQueryResult,
    (string | null | undefined)[]
  >,
  'queryKey' | 'queryFn'
>;
/**
 * Fetches the facility associated with the stop and filters down the appointments by the stop id.
 * If the appointments do not exist at the facility level the method will also look at the dock
 * level before returning `null`
 *
 * If the most recent appointment for a shipment is in the status cancelled then it is ignored.
 */
const useShipmentsStopAppointmentQuery = (
  {shipmentId, stopId}: UseShipmentsStopAppointmentQueryRequest,
  options?: UseShipmentsStopAppointmentQueryOptions
) => {
  const defaultResult = {facility: null, dock: null, appointment: null};
  // chain consumer enabled logic with ours so that
  // we can ensure this query is only ran when we want it to
  const isEnabled = Boolean(shipmentId) && Boolean(stopId) && (options?.enabled ?? true);

  const {stop, isStopsFetching, isStopsInitialLoading} = useShipmentStopQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: isEnabled
    }
  );

  const isFirstComeFirstServe = stop?.appointment_type?.code === 'OPEN';

  const {facility, isFacilityError, isFacilitySuccess, facilityError} = useShipmentStopFacilityQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: isEnabled && Boolean(stop)
    }
  );

  const query = useQuery(
    [SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY, shipmentId, stopId],
    async () => {
      if (isNil(shipmentId) || isNil(stopId) || isNil(facility) || isNil(facility?.id)) {
        return defaultResult;
      }

      const facilityAppointments = await getFacilityAppointments(
        facility.id,
        null,
        null,
        null,
        null,
        '' as AppointmentStatusEnum,
        null,
        null,
        null,
        {
          shipmentId,
          stopId
        }
      );
      if (facilityAppointments?.length) {
        const appointmentEntries = facilityAppointments.map((appointment) =>
          makeEntry(appointment, facility.address.timezone)
        );
        const mostRecentAppointment = getMostRecentAppointment(appointmentEntries);
        let dock: FacilityDock | null = null;
        if (mostRecentAppointment?.dockId) {
          const docks = await getFacilityDocks(facility.id);
          dock = docks?.find((d) => d.id === mostRecentAppointment.dockId) ?? null;
        }
        const result = {
          facility,
          dock,
          appointment: mostRecentAppointment
        };
        return result;
      }
      const result = {
        facility,
        dock: null,
        appointment: null
      };
      return result;
    },
    {
      ...options,
      enabled: isEnabled && Boolean(stop) && Boolean(facility)
    }
  );

  const queryResult = {
    isAppointmentSuccess: isFacilitySuccess && query.isSuccess,
    isAppointmentError: isFacilityError || query.isError || query.isRefetchError || query.isLoadingError,
    appointmentError: facilityError || query.error,
    isAppointmentLoading: query.isInitialLoading || isStopsFetching || isStopsInitialLoading,
    data: {
      isFirstComeFirstServe,
      appointment: null,
      dock: null,
      facility: facility ?? null,
      ...query.data
    }
  };

  return queryResult;
};

export {useShipmentsStopAppointmentQuery};
