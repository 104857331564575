import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {reduxForm, change, SubmissionError} from 'redux-form';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {fetchPackageTypesPromise, createShipmentPromise, autoBookShipmentPromise} from 'App/api/shipment';
import {fetchAccessorialCodes} from 'App/actions/shipments';
import {locationTypesGet} from 'App/actions/addressBook';

import References from 'App/containers/Book/FormSections/References';
import LineItems from 'App/containers/Book/FormSections/LineItems';
import StopsExpanded from 'App/containers/Book/FormSections/StopsExpanded';
import {validate} from 'App/containers/supplierShipments/utils';
import {createRFQPromise, getRFQDetailsPromise} from 'App/api/quoting';
import {unpackErrors, scrollToFirstErrorField} from 'App/utils/globals';
import ShipwellLoader from 'App/common/shipwellLoader';

let shipmentId;

let CreateSupplierShipment = (props) => {
  const {
    form,
    dispatch,
    router,
    location,
    submitting,
    handleSubmit,
    formValues,
    po_number,
    supplier_email_address,
    express_delivery,
    allow_autobook,
    setDispatchError,
    setSuccessMessage
  } = props;
  const [accessorials, setAccessorials] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [shipment, setShipment] = useState({});
  const [booking, setBooking] = useState(false);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (!accessorials.length) {
      fetchAccessorials();
    }
    if (!locationTypes.length) {
      fetchLocationTypes();
    }
    if (!packageTypes.length) {
      fetchPackageTypes();
    }
    if (po_number) {
      dispatch(change(form, 'purchase_order_number', po_number));
    }
    //include the supplier in the additional bol recipients
    if (supplier_email_address) {
      dispatch(change(form, 'additional_bol_recipients', [supplier_email_address]));
    }
    dispatch(change(form, 'mode', {id: 2, code: 'LTL', description: 'Less Than Truckload'}));
    dispatch(change(form, 'equipment_type', {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}));
    dispatch(change(form, 'metadata', {open: false, archived: false, tags: []}));
  }, []);

  const fetchAccessorials = () => {
    try {
      dispatch(fetchAccessorialCodes());
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLocationTypes = () => {
    try {
      dispatch(locationTypesGet());
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPackageTypes = () => {
    try {
      fetchPackageTypesPromise().then((response) => {
        setPackageTypes(response.body);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const submitData = (attrs) => {
    //set booking flag on the parent
    setCreating(true);
    //if pickup is today and current time is after 4pm, push it to tomorrow
    if (attrs.stops[0] && moment(attrs.stops[0].planned_date).isSame(moment(), 'day')) {
      if (moment().tz(attrs.stops[0].location.address.timezone).hour() > 15) {
        attrs.stops[0].planned_date = moment(attrs.stops[0].planned_date).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    //always set the pickup time to 2 hours ahead of now, if it's today
    if (attrs.stops[0] && moment(attrs.stops[0].planned_date).isSameOrBefore(moment())) {
      //set date to today
      attrs.stops[0].planned_date = moment().format('YYYY-MM-DD');
      if (moment().tz(attrs.stops[0].location.address.timezone).hour() > 13) {
        //push to tomorrow if it's past 2pm at the pickup, since we cant move the time forward enough (2hrs) today
        attrs.stops[0].planned_date = moment().add(1, 'days').format('YYYY-MM-DD');
      } else {
        //set pickup time to 2 hours, 15 minutes from now in the stop timezone to avoid common booking errors
        attrs.stops[0].planned_time_window_start = moment()
          .tz(attrs.stops[0].location.address.timezone)
          .add(2, 'hours')
          .add(15, 'minutes')
          .format('HH:mm:ss');
      }
      if (moment(attrs.stops[0].planned_time_window_end).isBefore(moment(attrs.stops[0].planned_time_window_start))) {
        //push end time back manually if it's before start time
        attrs.stops[0].planned_time_window_end = moment()
          .tz(attrs.stops[0].location.address.timezone)
          .add(3, 'hours')
          .format('HH:mm:ss');
      }
    }

    if (attrs.stops[1] && moment(attrs.stops[1].planned_date).isBefore(moment(attrs.stops[0].planned_date))) {
      attrs.stops[1].planned_date = moment(attrs.stops[0].planned_date).add(2, 'days').format('YYYY-MM-DD');
    }

    attrs.stops = attrs.stops.map((shipmentStop, i) => {
      const stop = {...shipmentStop, ordinal_index: i};
      const scheduleSelect = stop['schedule-select'];
      if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
        stop.planned_time_window_start = '8:00';
      } else {
        stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
          ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
          : moment(stop.planned_time_window_start).isValid()
          ? moment(stop.planned_time_window_start).format('HH:mm:ss')
          : null;
      }
      if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
        stop.planned_time_window_end = '18:00';
      } else {
        stop.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
          ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
          : moment(stop.planned_time_window_end).isValid()
          ? moment(stop.planned_time_window_end).format('HH:mm:ss')
          : null;
      }

      return stop;
    });

    //create a shipment
    return createShipment(attrs);
  };

  const createShipment = async (body) => {
    try {
      const shipmentResponse = await createShipmentPromise(body);
      setShipment(shipmentResponse.body);
      shipmentId = shipmentResponse.body.id;
      const RFQ = {
        autoquote: true,
        equipment_types: [shipmentResponse.body.equipment_type],
        parent_rfq: null,
        shipment: shipmentResponse.body.id,
        shipment_modes: [shipmentResponse.body.mode]
      };
      return createRFQ(RFQ);
    } catch (error) {
      setCreating(false);
      console.error(error);
      //throw a submissionerror for redux form
      let submissionError = {};
      //show field-level errors
      submissionError = unpackErrors(error.field_errors, submissionError, []);
      throw new SubmissionError(submissionError);
    }
  };

  const createRFQ = async (body) => {
    try {
      const RFQResponse = await createRFQPromise(body);
      setCreating(false);
      if (allow_autobook) {
        setBooking(true);
        pollForQuotes(RFQResponse.body);
      } else {
        setSuccessMessage('This shipment has been created.');
        router.push('/supplier-shipments/' + shipmentId + '?token=' + location.query.token);
      }
    } catch (error) {
      setCreating(false);
      console.error(error);
      //throw a submissionerror for redux form
      let submissionError = {};
      //show field-level errors
      submissionError = unpackErrors(error.field_errors, submissionError, []);
      throw new SubmissionError(submissionError);
    }
  };

  const pollForQuotes = async (RFQ) => {
    try {
      await getRFQDetailsPromise(RFQ.id).then((response) => {
        if (!response.body.did_finish_autoquote) {
          setTimeout(() => {
            pollForQuotes(response.body);
          }, 1000);
        } else {
          //attempt to dispatch quotes
          if (!response.body.quotes.length) {
            setBooking(false);
            setDispatchError('We were unable to get quotes for this shipment.');
            //push to the detail page where the error will be shown
            router.push('/supplier-shipments/' + shipmentId + '?token=' + location.query.token);
          } else {
            return evaluateQuotes(response.body.quotes);
          }
        }
      });
    } catch (error) {
      setBooking(false);
      console.error(error);
    }
  };

  const evaluateQuotes = (quotes) => {
    //if we need express delivery, first find the quote with the shortest amount of transit time
    if (express_delivery) {
      quotes = quotes.sort((a, b) => {
        if (isNaN(a.transit_days) || a.transit_days === null || a.transit_days === 0 || !parseInt(a.transit_days, 10)) {
          return 1;
        }
        if (isNaN(b.transit_days) || b.transit_days === null || b.transit_days === 0 || !parseInt(b.transit_days, 10)) {
          return -1;
        }
        return a.transit_days > b.transit_days ? 1 : -1;
      });
      //select only the quotes that have the minimal amount of transit_days
      const shortestTransit = quotes[0].transit_days;
      quotes = quotes.filter((e) => e.transit_days === shortestTransit);
    }

    //sort quotes by price
    quotes = quotes.sort((a, b) => {
      if (!a.total) {
        return 1;
      }
      if (!b.total) {
        return -1;
      }
      return a.total > b.total ? 1 : -1;
    });

    const quoteToBook = quotes[0];
    return bookQuote(quoteToBook);
  };

  const bookQuote = async (quote) => {
    try {
      const body = {quote: quote.id};
      await autoBookShipmentPromise(shipmentId, body).then((response) => {
        setBooking(false);
        setSuccessMessage('This shipment has been booked.');
        router.push('/supplier-shipments/' + shipmentId + '?token=' + location.query.token);
      });
    } catch (error) {
      console.error(error);
      setBooking(false);
      //dont show the dispatch error to this unauth user
      //push to the detail page where the error will be shown
      router.push('/supplier-shipments/' + shipmentId + '?token=' + location.query.token);
    }
  };

  if (booking || creating) {
    return (
      <div className="supplierShipment__loading">
        <ShipwellLoader loading />
        <p>{`${booking ? 'Booking' : 'Creating'} this shipment...`}</p>
      </div>
    );
  }

  return (
    <div className="supplierShipment__create">
      <h3>Enter Shipment Details</h3>
      <form>
        <div className="supplierShipment__grid">
          <div className="supplierShipment__grid-item">
            <Paper className="newshipment__card">
              <StopsExpanded
                form={form}
                externalForm
                canViewOrders={false}
                formValues={formValues}
                showPickupDateField
              />
            </Paper>
          </div>
          <div className="supplierShipment__grid-item">
            <div className="supplierShipment__grid-vertical">
              <div className="supplierShipment__grid-item">
                <Paper className="newshipment__card">
                  <References form={form} formValues={formValues} externalForm />
                </Paper>
              </div>
              <div className="supplierShipment__grid-item">
                <Paper className="newshipment__card">
                  <LineItems form={form} externalForm formValues={formValues} />
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <div className="supplierShipment__actions">
          <DeprecatedButton
            disabled={submitting}
            type="submit"
            variant="primary"
            onClick={handleSubmit((data) => {
              submitData(data);
            })}
          >
            Submit
          </DeprecatedButton>
        </div>
      </form>
    </div>
  );
};

CreateSupplierShipment = reduxForm({
  form: 'supplierShipment',
  validate,
  enableReinitialize: true,
  touchOnChange: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(CreateSupplierShipment);

CreateSupplierShipment = connect((state) => ({
  formValues: state.form.supplierShipment || {}
}))(CreateSupplierShipment);

export default CreateSupplierShipment;
