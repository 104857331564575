import {connect} from 'react-redux';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import {object, string, bool, array, number} from 'yup';
import {Card, Title} from '@shipwell/shipwell-ui';
import WorkflowSettings from 'App/formComponents/formSections/workflowSettings';
import RoutingGuideFields from 'App/formComponents/formSections/routingGuideFields';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {POLICY_TYPE_ROUTING_GUIDE} from 'App/containers/workflows/workflowConstants';
import './styles.scss';

/** Default values needed for Formik */
const defaultFormValues = {
  end_date: null,
  modes: [{description: 'Full Truckload', id: 1}],
  name: '',
  start_date: moment().format('YYYY-MM-DD'),
  status: 'ACTIVE',
  shipment_created: false
};

const ROUTING_GUIDE_NAME_REQUIRED = 'Routing Guide name is required.';

export const validationSchema = object().shape({
  name: string().trim().min(1, ROUTING_GUIDE_NAME_REQUIRED).required(ROUTING_GUIDE_NAME_REQUIRED),
  status: string().nullable().required('Status is required.'),
  shipment_created: bool().oneOf([true, false]),
  start_date: string().nullable().required('Start date is required.'),
  end_date: string().nullable(),
  modes: array()
    .of(
      object().shape({
        id: number(),
        code: string(),
        description: string()
      })
    )
    .test({
      test: (arr) => arr?.length > 0,
      message: 'At least one Mode is required'
    }),
  equipment_types: array()
    .of(
      object().shape({
        id: number(),
        name: string(),
        machine_readable: string()
      })
    )
    .test({
      test: (arr) => arr?.length > 0,
      message: 'At least one Equipment type is required'
    })
});

/**
 * Policy Form
 */
const PolicyForm = (props) => {
  const {isEdit, values, onSubmit, onCancel, modes, equipmentTypes, cloningGuide} = props;

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit policy values */
  const handlePolicySubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handlePolicySubmit}
    >
      {({isSubmitting}) => (
        <Form noValidate="novalidate">
          {!cloningGuide ? (
            <Title variant="formTitle" className="stepExplainer">
              Set your policy criteria to define the type of shipments for this routing guide.
            </Title>
          ) : null}
          <Card title="Routing Guide Criteria" className="card-criteria">
            <RoutingGuideFields modes={modes} equipmentTypes={equipmentTypes} />
          </Card>
          <WorkflowSettings policyType={POLICY_TYPE_ROUTING_GUIDE} />
          {!isEdit && <ModalFormFooter isSubmitting={isSubmitting} onCancel={handleCancel} primaryActionName="Next" />}
        </Form>
      )}
    </Formik>
  );
};

PolicyForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect((state) => ({
  company: state.userCompany.company
}))(PolicyForm);
