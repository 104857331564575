import PropTypes from 'prop-types';
import {Tooltip} from '@shipwell/shipwell-ui';

const DeleteWarningTooltip = ({
  title,
  placement,
  itemLabel,
  onDelete,
  children,
  message,
  primaryActionText = 'Delete'
}) => {
  const tooltipContent = (
    <>
      {title && <div className="warning-tooltip__content-title">{title}</div>}
      <div className="warning-tooltip__content-message whitespace-normal">
        {message ? message : `Are you sure you want to delete ${itemLabel}?`}
      </div>
    </>
  );

  return (
    <Tooltip
      trigger="click"
      placement={placement}
      primaryAction={primaryActionText}
      primaryActionVariant="warning"
      onPrimaryAction={onDelete}
      secondaryAction="Cancel"
      tooltipContent={tooltipContent}
      tooltipClassname="warning-tooltip"
    >
      {children}
    </Tooltip>
  );
};

DeleteWarningTooltip.defaultProps = {
  placement: 'top'
};

DeleteWarningTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  placement: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  primaryActionText: PropTypes.string,
  itemLabel: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default DeleteWarningTooltip;
