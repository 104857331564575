import {OrderItemAmount, LengthUnit, PackagingType, VolumeUnit, WeightUnit, AreaUnit} from '@shipwell/corrogo-sdk';
import {volumeLabels} from 'App/containers/orders/constants';

type UnitOption = {
  label: string;
  value: OrderItemAmount['unit'];
};

const LENGTH_OPTIONS = Object.values(LengthUnit).map((value) => ({
  label: `Length (${value})`,
  value
}));
const VOLUME_OPTIONS = Object.values(VolumeUnit).map((value) => ({
  label: `Volume (${volumeLabels[value]})`,
  value
}));
const WEIGHT_OPTIONS = Object.values(WeightUnit).map((value) => ({
  label: `Weight (${value})`,
  value
}));
const AREA_OPTIONS = Object.values(AreaUnit).map((value) => {
  const labelMap: Record<AreaUnit, string> = {
    [AreaUnit.Ft]: 'SQUARE FOOT',
    [AreaUnit.Yd]: 'SQUARE YARD'
  };
  return {
    label: labelMap[value],
    value
  };
});
const PACKAGING_TYPES = Object.values(PackagingType).map((value) => ({
  label: value,
  value
}));

export const UNIT_OPTIONS: UnitOption[] = [
  ...LENGTH_OPTIONS,
  ...VOLUME_OPTIONS,
  ...WEIGHT_OPTIONS,
  ...AREA_OPTIONS,
  ...PACKAGING_TYPES
].sort((a, b) => a.label.localeCompare(b.label));
