import {Fragment, useEffect, useState, useMemo} from 'react';
import {withRouter} from 'react-router';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {cleanReadbackAndSavePayload, checkFilterObj} from '../utils';
import {transformFiltersForReadback} from './utils';
import {TableFiltersContextConsumer} from 'App/utils/hooks/useTableFilters';
import './styles.scss';

const TableReadback = ({filters, activeFilters, clearFilter, clearAllFilters, selectedTableView}) => {
  const modifiedActiveFilters = useMemo(() => transformFiltersForReadback(activeFilters), [activeFilters]);
  const [readbackFilters, setReadbackFilters] = useState({});
  /**
   * Render the display key and value for filter readback
   * @param {Obj} filter filter from query params
   * @param {Obj} tableFilterOptions filter display key/values passed in to react table
   */
  const renderFilterDisplay = (selectedFilter) => {
    const filterReadback = {
      filterParam: '',
      filterParamId: '',
      filterValue: [],
      secondaryName: '',
      useRange: false
    };
    const [filterParam, filterValue] = selectedFilter;
    const filterObj = filters?.find((options) => options.name === filterParam);
    if (!filterObj) {
      // if no matching filter object was found, this is a secondaryName collection and not a filter,
      // we don't want to display anything in this case
      return;
    }
    filterReadback.filterParamId = filterParam;
    filterReadback.filterParam = filterObj.label;
    filterReadback.secondaryName = filterObj.secondaryName;

    const useRange = filterObj.useRange;
    filterReadback.useRange = useRange;

    if (filterObj.options?.length > 0) {
      // represent value with option label from filters
      filterValue.map((filter) => {
        filterReadback.filterValue.push({
          label: filterObj.options.find((option) => option.value === filter)?.label,
          id: filterValue
        });
      });
    } else if (filterObj.secondaryName && modifiedActiveFilters[filterObj.secondaryName]) {
      filterValue.map((value) => {
        // represent value with display name from filters
        const index = modifiedActiveFilters[filterObj.name].indexOf(value);
        filterReadback.filterValue.push({
          label: modifiedActiveFilters[filterObj.secondaryName][index],
          id: value
        });
      });
    } else {
      filterValue.map((filter) => {
        filterReadback.filterValue.push({label: filter || '', id: filter || ''});
      });
    }
    return filterReadback;
  };

  useEffect(() => {
    setReadbackFilters(cleanReadbackAndSavePayload(modifiedActiveFilters));
  }, [modifiedActiveFilters]);

  //display if table view selected or active filters
  return Object.keys(selectedTableView).length > 0 || checkFilterObj(readbackFilters) ? (
    <div className="tableReadback">
      <div className="_tableReadback__readbackHeader">
        {selectedTableView?.id && (
          <div className="_tableReadback__nameContainer">
            {selectedTableView.is_default && <span className="_tableReadback__default">Default</span>}
            <span className="_tableReadback__filterName">{selectedTableView.name}:</span>
          </div>
        )}
        <div className="_tableReadback__clearContainer">
          <span title="ClearAll" onClick={(e) => clearAllFilters(e)} className="_tableReadback__clear">
            Clear All Filters
          </span>
        </div>
      </div>

      <div className="_tableReadback__filterContainer">
        {checkFilterObj(readbackFilters) &&
          Object.entries(readbackFilters).map((filter) => {
            //parse key/value pairs from entries
            const {filterParam, filterValue, filterParamId, secondaryName, useRange} =
              renderFilterDisplay(filter) || {};
            return filterValue?.map((value, valueIndex) => {
              return (
                <Fragment key={valueIndex}>
                  <span className="_tableReadback__filter">
                    {`${filterParam}: ${value.label}`}
                    <SvgIcon
                      className="_tableReadback__closeIcon"
                      title="Close"
                      name="Close"
                      color="$sw-text-reverse"
                      onClick={(e) => {
                        clearFilter(e, filterParamId, secondaryName, useRange, value);
                      }}
                    />
                  </span>
                </Fragment>
              );
            });
          })}
      </div>
    </div>
  ) : (
    ''
  );
};
const TableReadbackWithRouter = withRouter(TableReadback);
// eslint-disable-next-line react/display-name
export default (props) => (
  <TableFiltersContextConsumer>
    {(values) => <TableReadbackWithRouter {...props} {...values} />}
  </TableFiltersContextConsumer>
);
