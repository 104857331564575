import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {object, array, date} from 'yup';
import {Modal, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import ShipmentSection from './components/shipmentSection';
import OrderSection from './components/orderSection';
import './styles.scss';

const defaultFormValues = {
  shipment: {},
  undatedOrders: [],
  validOrders: []
};

const validationSchema = object().shape({
  shipment: object(),
  undatedOrders: array().of(
    object().shape({
      planned_delivery_end_datetime: date('Select a delivery date.')
        .required('Select a delivery date.')
        .typeError('Select a delivery date.'),
      planned_delivery_start_datetime: date('Select a delivery date.')
        .required('Select a delivery date.')
        .typeError('Select a delivery date.'),
      planned_pickup_end_datetime: date('Select a delivery date.')
        .required('Select a pickup date.')
        .typeError('Select a pickup date.'),
      planned_pickup_start_datetime: date('Select a delivery date.')
        .required('Select a pickup date.')
        .typeError('Select a pickup date.')
    })
  ),

  validOrders: array().of(
    object().shape({
      planned_delivery_end_datetime: date().required('Select a delivery date.').typeError('Select a delivery date.'),
      planned_delivery_start_datetime: date().required('Select a delivery date.').typeError('Select a delivery date.'),
      planned_pickup_end_datetime: date().required('Select a pickup date.').typeError('Select a pickup date.'),
      planned_pickup_start_datetime: date().required('Select a pickup date.').typeError('Select a pickup date.')
    })
  )
});

const validate = (values) => {
  const {undatedOrders: undatedOrderValues, validOrders: validOrderValues} = values;
  const errors = {};

  undatedOrderValues.forEach((order, index) => {
    if (order.planned_pickup_start_datetime > order.planned_delivery_start_datetime) {
      if (!('undatedOrders' in errors)) {
        errors.undatedOrders = [];
      }
      errors.undatedOrders[index] = {
        planned_pickup_start_datetime: 'Cannot set the Pickup date after the Delivery date',
        planned_delivery_start_datetime: 'Cannot set the Delivery date time earlier than the Pickup date'
      };
    }
  });
  validOrderValues.forEach((order, index) => {
    if (order.planned_pickup_start_datetime > order.planned_delivery_start_datetime) {
      if (!('validOrders' in errors)) {
        errors.validOrders = [];
      }
      errors.validOrders[index] = {
        planned_pickup_start_datetime: 'Cannot set the Pickup date after the Delivery date',
        planned_delivery_start_datetime: 'Cannot set the Delivery date time earlier than the Pickup date'
      };
    }
  });

  return errors;
};

const ConfirmOrderDatesForm = (props) => {
  const {
    shipment,
    setSelectedPurchaseOrders,
    undatedPurchaseOrders,
    setUndatedSelectedPurchaseOrders,
    undatedIndexUpdated,
    setUndatedIndexUpdated,
    validPurchaseOrders,
    setValidSelectedPurchaseOrders,
    validIndexUpdated,
    setValidIndexsUpdated,
    setDatesChecked,
    onBack,
    show,
    handleFormSubmit,
    PrimaryButtonProps,
    onClose
  } = props;

  const initialValues = {shipment, undatedOrders: undatedPurchaseOrders, validOrders: validPurchaseOrders};

  //
  // return to UnassignedPurchaseOrdersList view if all orders get removed
  useEffect(() => {
    if (!undatedPurchaseOrders.length && !validPurchaseOrders.length) {
      setSelectedPurchaseOrders([]);
    }
  }, [undatedPurchaseOrders, validPurchaseOrders, setSelectedPurchaseOrders]);
  //

  // Order Section - Card actions
  const removeUndatedOrder = (index) => {
    if (undatedPurchaseOrders.length === 1) {
      if (validPurchaseOrders.length < 1) {
        setDatesChecked(false);
      }
      setUndatedSelectedPurchaseOrders([]);
      setSelectedPurchaseOrders(validPurchaseOrders);
    } else {
      setUndatedSelectedPurchaseOrders([
        ...undatedPurchaseOrders.slice(0, index),
        ...undatedPurchaseOrders.slice(index + 1, undatedPurchaseOrders.length)
      ]);
      const updateUndatedIndex = undatedIndexUpdated.filter((updatedIndex) => updatedIndex !== index);
      setUndatedIndexUpdated(updateUndatedIndex);
    }
  };
  const removeValidOrder = (index) => {
    if (validPurchaseOrders.length === 1) {
      if (undatedPurchaseOrders.length < 1) {
        setDatesChecked(false);
      }
      setValidSelectedPurchaseOrders([]);
      setSelectedPurchaseOrders(undatedPurchaseOrders);
    } else {
      setValidSelectedPurchaseOrders([
        ...validPurchaseOrders.slice(0, index),
        ...validPurchaseOrders.slice(index + 1, validPurchaseOrders.length)
      ]);

      const updateValidIndex = validIndexUpdated.filter((updatedIndex) => updatedIndex !== index);
      setValidIndexsUpdated(updateValidIndex);
    }
  };

  return (
    <Formik
      initialValues={{...defaultFormValues, ...initialValues}}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={handleFormSubmit}
      validateOnMount
      enableReinitialize
    >
      {({handleSubmit}) => (
        <Modal
          className="shipment-purchase-orders-modal"
          title="Confirm Order Dates"
          size="large"
          show={show}
          primaryBtnName="Add Orders"
          onPrimaryAction={handleSubmit}
          PrimaryButtonProps={PrimaryButtonProps}
          onClose={onClose}
        >
          <div className="confirm-order-dates-form">
            <>
              <p className="confirm-order-dates-form__note">
                All stops of an order are required to have a date before being consolidated into a shipment. 1 or more
                of the orders you selected does not have dates. Select a date for all stops to continue.
              </p>
              <ShipmentSection shipment={shipment} undatedPurchaseOrders={undatedPurchaseOrders} />
              <OrderSection
                name="undatedOrders"
                orders={undatedPurchaseOrders}
                removeAction={removeUndatedOrder}
                isCollapsed={!undatedPurchaseOrders.length}
                title={
                  <>
                    Selected Orders Without Dates ({undatedPurchaseOrders.length ?? '0'})
                    <span>
                      Dates are required to add an order to a shipment. You can quickly apply dates to all unscheduled
                      orders using the buttons in the shiment summary section above.
                    </span>
                  </>
                }
                emptyState={'No Orders Without Dates Selected'}
              />
              <OrderSection
                name="validOrders"
                orders={validPurchaseOrders}
                removeAction={removeValidOrder}
                isCollapsed={undatedPurchaseOrders.length ? true : false}
                title={
                  <>
                    Selected Orders With Dates ({validPurchaseOrders.length ?? '0'})
                    <span>
                      Please confirm the orders and associated dates being added to the shipment. To make changes click
                      the field to change the date.
                    </span>
                  </>
                }
                emptyState={'No Orders With Dates Selected'}
              />
            </>
            <DeprecatedButton
              className="confirm-order-dates-form__back-button"
              variant="tertiary"
              onClick={onBack}
              icon={<SvgIcon name="ArrowBack" />}
            >
              Back
            </DeprecatedButton>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

ConfirmOrderDatesForm.propTypes = {
  shipment: PropTypes.object,
  setSelectedPurchaseOrders: PropTypes.func,
  undatedPurchaseOrders: PropTypes.array,
  setUndatedSelectedPurchaseOrders: PropTypes.func,
  undatedIndexUpdated: PropTypes.array,
  setUndatedIndexUpdated: PropTypes.func,
  validPurchaseOrders: PropTypes.array,
  setValidSelectedPurchaseOrders: PropTypes.func,
  validIndexUpdated: PropTypes.array,
  setValidIndexsUpdated: PropTypes.func,
  onBack: PropTypes.func
};

export default ConfirmOrderDatesForm;
