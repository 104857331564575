import {object, string, array, boolean, number, InferType, BaseSchema} from 'yup';
import {parseISO, isFuture} from 'date-fns';

export const validationSchema = object().shape({
  carrier: object()
    .label('Carrier')
    .nullable()
    .required()
    .shape({
      label: string().required(),
      carrierFAVRid: string().required(),
      carrierRelationship: object().shape({
        id: string().required(),
        shipwell_vendor: object().shape({
          identifying_codes: array().of(
            object().shape({
              type: string().required(),
              value: string().required()
            })
          ),
          name: string().nullable(),
          dba_name: string().nullable()
        }),
        point_of_contacts: array().of(
          object().shape({
            id: string().required(),
            first_name: string().nullable(),
            last_name: string().nullable(),
            email: string().nullable(),
            phone_number: string().nullable()
          })
        )
      })
    }),
  containers: array()
    .label('Containers')
    .of(
      object().shape({
        label: string().required(),
        value: string().required()
      })
    )
    .min(1)
    .required(),
  lineHaul: string().label('Line Haul').required(),
  fuelSurcharge: string().when('fuelIncludedInLineHaul', ([fuelIncludedInLineHaul], schema: BaseSchema) =>
    fuelIncludedInLineHaul ? (schema.required() as BaseSchema) : schema
  ),
  fuelSurchargeUnit: string().required(),
  fuelIncludedInLineHaul: boolean(),
  expiration: string()
    .test('future-expiration', 'Expiration date and time must be in the future.', (expiration) => {
      if (!expiration) {
        return true;
      }
      return isFuture(parseISO(expiration));
    })
    .nullable(),
  message: string(),
  debits: array()
    .of(
      object().shape({
        quantity: number().required(),
        unit_amount: object().required().shape({
          value: string().required()
        }),
        leg_item_id: string().required()
      })
    )
    .required()
});

export type TenderFormValues = InferType<typeof validationSchema>;
