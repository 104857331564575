import {omit, mapValues} from 'lodash';
/**
 * These filter will not be saved as part of a table view
 *
 */
export const excludedFilterParams = ['page', 'pageSize', 'ordering', 'q', 'undefined'];
/**
 * These url parameters will not be included in table readback or filter save
 *
 */
export const excludedReadbackAndSaveParams = ['page', 'pageSize', 'ordering', 'q', 'undefined', 'viewId'];
/**
 * Constants for tab selection
 *
 */
export const FILTERS = 'filters';
export const COLUMNS = 'columns';
export const SAVED = 'saved';
/**
 * Check filter object for keys with non-empty arrays
 * @param {Array} filterObj
 */
export const checkFilterObj = (filterObj) => {
  return (
    Object.values(filterObj).some((val) => Array.isArray(val) && val.length > 0) ||
    Object.values(filterObj).some((val) => typeof val === 'string' && val.length > 0)
  );
};

/**
 * Remove excluded params from filter display payload
 * @param {Obj} filterObj
 */
export const cleanFilterPayload = (filterObj) => {
  return omit(filterObj, excludedFilterParams);
};

/**
 * Remove excluded params from table readback and filter creation payload
 * @param {Obj} filterObj
 */
export const cleanReadbackAndSavePayload = (filterObj) => {
  return omit(filterObj, excludedReadbackAndSaveParams);
};

/**
 * Function to map string keys to arrays- table configuration model uses arrays to store values
 * @param {Obj} filterParams
 */

export const formatTableConfig = (filterObj) =>
  mapValues(filterObj, (value) => (Array.isArray(value) ? value : [value]));

/**
 * Unnest data from table config response
 * @param {Object} tableView
 * @param {Object} defaultColumns default table columns applied if saved view has no column selections
 */
export const unnestTableView = (tableView = {}, defaultColumns = []) => {
  const hasTableColumns = tableView.config?.columns && tableView.config?.columns[0] !== '';
  return {
    ...tableView,
    filters: tableView.config.filters || {},
    columns: hasTableColumns ? getTableColumnContent(tableView.config.columns, defaultColumns) : defaultColumns
  };
};

/**
 * Remove the filters that are not part of the new filter object from the active filters object
 * @param {Object} activeFilters
 * @param {Object} newSelections
 * @param {String} viewId
 */
export const updateFilterObj = (activeFilters, newSelections, viewId = '') => {
  //if there's a new view being applied, only update filters with what is in view
  if (viewId) {
    return newSelections;
    //otherwise assign new selections to activeFilters
  }
  return Object.assign({}, activeFilters, newSelections);
};

/**
 * We only save column IDs, replace the saved IDs with the column content
 * @param {Object} columnIds
 * @param {Object} columns
 */
export const getTableColumnContent = (columnIds, columns = ['']) => {
  return columnIds.map((columnId) => columns.find((column) => column.id === columnId));
};

/**
 * Remove draggableProvided and DraggableId from an inactive column so react DND can reassign them
 * @param {Object} column
 */
export const removeDndKeys = (column) => {
  return omit(column, 'draggableProvided', 'draggableId');
};
