import {useMutation} from '@tanstack/react-query';
import {TimelineEventTemplate} from '@shipwell/backend-core-sdk';
import {createNewEventTemplate} from 'App/api/tracking/typed';

export const useCreateEventTemplate = (queryOptions = {}) => {
  const createTemplateMutation = useMutation(
    (payload: TimelineEventTemplate) => createNewEventTemplate(payload),
    queryOptions
  );

  return createTemplateMutation;
};
