import PropTypes from 'prop-types';
import get from 'lodash/get';
import {SvgIcon} from '@shipwell/shipwell-ui';
import withEmptyFallback from 'App/components/withEmptyFallback';

const EditErrorDisplay = withEmptyFallback('errors._error')(({errors}) => (
  <div className="shipment__edit-error">
    <SvgIcon name="ErrorOutlined" color="sw-error" className="shipment__edit-error-icon" />
    {get(errors, '_error')}
  </div>
));

EditErrorDisplay.propTypes = {
  errors: PropTypes.shape({
    _error: PropTypes.string
  })
};

EditErrorDisplay.defaultProps = {
  isSubmitting: false,
  dirty: false,
  onCancelEdit: () => {}
};

export default EditErrorDisplay;
