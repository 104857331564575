import {Dispatch, SetStateAction} from 'react';
import {Button} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';
import {ContainerType} from 'App/containers/orders/components/AddOrdersButton';

export const AddOrdersToShipmentFooter = ({
  onClose,
  isAddingOrdersToShipment,
  selectedOrderIds,
  setActiveContainer,
  handleAddOrdersToShipment,
  activeContainer
}: {
  onClose: () => void;
  isAddingOrdersToShipment: boolean;
  selectedOrderIds: string[];
  setActiveContainer: Dispatch<SetStateAction<ContainerType>>;
  handleAddOrdersToShipment: () => void;
  activeContainer: ContainerType;
}) => {
  const handleBack = () => {
    switch (activeContainer) {
      case 'modifyStops':
        return setActiveContainer('updateOrderDates');
      case 'updateOrderDates':
        return setActiveContainer('addOrder');
    }
  };

  return (
    <FlexBox justify="between" gap="m">
      <div>
        {activeContainer !== 'addOrder' && (
          <Button variant="tertiary" iconName="ArrowBack" onClick={handleBack}>
            Back
          </Button>
        )}
      </div>

      <FlexBox justify="end" gap="m">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>

        {activeContainer == 'addOrder' && (
          <Button
            variant="primary"
            isLoading={isAddingOrdersToShipment}
            disabled={!selectedOrderIds.length}
            onClick={() => setActiveContainer('updateOrderDates')}
          >
            Modify Orders
          </Button>
        )}
        {activeContainer === 'updateOrderDates' && (
          <Button
            variant="primary"
            isLoading={isAddingOrdersToShipment}
            disabled={!selectedOrderIds.length}
            onClick={() => setActiveContainer('modifyStops')}
          >
            Modify Stops
          </Button>
        )}

        <Button
          variant="primary"
          isLoading={isAddingOrdersToShipment}
          disabled={!selectedOrderIds.length}
          onClick={() => handleAddOrdersToShipment()}
        >
          Add to Shipment
        </Button>
      </FlexBox>
    </FlexBox>
  );
};
