import {JSX} from 'react';
import {ChangeEvent} from 'react';
import classNames from 'classnames';
import {Checkbox, FileDisplay} from '@shipwell/shipwell-ui';
import {ExternalDocument} from '@shipwell/corrogo-sdk';
import type {User} from '@shipwell/backend-core-sdk';
import get from 'lodash/get';
import {getDocumentTypeLabel} from './documentTypes';
import getNil from 'App/utils/getNil';
import {formatDateTime} from 'App/utils/globals';

type PropTypes = {
  document: ExternalDocument;
  user?: User;
  isSelected: boolean;
  previewing: boolean;
  onDocumentClick: (documentId: string) => void;
  onDocumentSelect: (isChecked: boolean, documentId: string) => void;
};

const DocumentItem = ({
  document,
  user,
  isSelected,
  previewing,
  onDocumentClick,
  onDocumentSelect
}: PropTypes): JSX.Element => {
  const hidePreviewCta = true;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDocumentSelect(e.target.checked, document.id);
  };
  const authorName = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();

  return (
    <div key={document.id} className="flex flex-row items-center justify-start border-b border-sw-divider p-2">
      <div className="mr-2">
        <Checkbox checked={isSelected} onChange={onChange} disabled />
      </div>
      <div className="flex justify-between hover:cursor-pointer" onClick={() => onDocumentClick(document.id)}>
        <div
          className={classNames('flex justify-center items-center min-w-48 hover:cursor-pointer pointer-events-none', {
            'border-sw-primary': previewing
          })}
        >
          <FileDisplay
            maxHeight={40}
            maxWidth={40}
            fileURL={document.url || '/images/clipboard.png'}
            fileName={document.filename}
            isThumbnail
            altText={document.description}
          />
        </div>
        <div className={classNames('flex flex-col w-232 pl-2 overflow-hidden', {'max-w-196': hidePreviewCta})}>
          <span
            className={classNames('overflow-hidden text-[12px] text-ellipsis whitespace-nowrap', {
              'font-bold': previewing
            })}
          >
            {getDocumentTypeLabel(document.type)}
          </span>
          <span
            className={classNames('overflow-hidden text-[12px] text-ellipsis whitespace-nowrap', {
              'font-bold': previewing
            })}
          >
            {getNil(document, 'description', '')}
          </span>
          <span className="text-[10px] text-sw-disabled-text">
            {formatDateTime(get(document, 'created_at', ''))} {authorName ? `by ${authorName}` : ''}
          </span>
        </div>
        {!hidePreviewCta ? <span className="px-0 py-4 text-[10px] text-sw-primary">PREVIEW</span> : null}
      </div>
    </div>
  );
};

export default DocumentItem;
