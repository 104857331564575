import {SvgIcon} from '@shipwell/shipwell-ui';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import {PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import {useDrayageLeg, useShipmentLegs} from 'App/data-hooks';

export const ContainerSelectionFooter = ({legId, router, location}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  const getLegsFromShipmentQuery = useShipmentLegs(first(drayageLegQuery.data?.shipment_ids));

  const legs = getLegsFromShipmentQuery?.data;

  const currentLegIndex = legs?.findIndex((leg) => leg.id === legId);
  const totalLegCount = legs?.length;
  const prevLegId = legs?.[currentLegIndex - 1]?.id;
  const nextLegId = legs?.[currentLegIndex + 1]?.id;

  const queriesLoading = drayageLegQuery.isInitialLoading || getLegsFromShipmentQuery.isInitialLoading;
  const prevButtonDisabled = queriesLoading || currentLegIndex === 0;
  const nextButtonDisabled = queriesLoading || currentLegIndex === totalLegCount - 1;

  const navigateToContainer = (containerId) => {
    const {pathname} = location;
    const pathnameParts = pathname.split('/');
    pathnameParts[2] = containerId;
    router.push(pathnameParts.join('/'));
  };

  return (
    <div className="fixed bottom-10 left-1/2 h-8 w-60 rounded-2xl border-sw-border bg-sw-background-header opacity-75">
      <div className="flex h-full flex-row items-center justify-between text-sw-text-reverse">
        <button disabled={prevButtonDisabled} onClick={() => navigateToContainer(prevLegId)}>
          <SvgIcon name="ArrowSmallLeft" color={prevButtonDisabled ? 'sw-disabled' : 'sw-background-component'} />
        </button>
        {queriesLoading ? (
          <SvgIcon name="LoadingDots" />
        ) : (
          <div className="text-l text-sw-text-reverse">{`Container ${currentLegIndex + 1}/${totalLegCount}`}</div>
        )}
        <button disabled={nextButtonDisabled} onClick={() => navigateToContainer(nextLegId)}>
          <SvgIcon name="ArrowSmallRight" color={nextButtonDisabled ? 'sw-disabled' : 'sw-background-component'} />
        </button>
      </div>
    </div>
  );
};

ContainerSelectionFooter.propTypes = {
  legId: PropTypes.string,
  router: PROPTYPE_ROUTER,
  location: PropTypes.object
};

export const ContainerSelectionFooterWithRouter = withRouter(ContainerSelectionFooter);
