import {EquipmentType, Stop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery} from '@tanstack/react-query';
import {getMyPriceForecastPricing} from 'App/api/pricing';
import {PRICING_INTEL_REQUIRED_ADDRESS_KEYS} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {getStringifiedStopsWithRequiredKeys} from 'App/components/pricingIntelChart/utils';
import {PRICING_FORECAST_QUERY_KEY} from 'App/data-hooks/queryKeys';

type ResponseBody = {
  body: {
    data: {
      price?: number;
      date?: string;
      confidence_category?: string;
      confidence_description?: string;
      distance_miles?: number;
    };
  };
};

const useGetPricingForecast = ({
  stops,
  selectedEquipmentType
}: {
  stops: Stop[];
  selectedEquipmentType: EquipmentType;
}) => {
  const stringifiedStops = getStringifiedStopsWithRequiredKeys(stops, PRICING_INTEL_REQUIRED_ADDRESS_KEYS);

  const pricingForecastQuery = useQuery(
    [PRICING_FORECAST_QUERY_KEY, stringifiedStops, selectedEquipmentType],
    async () => {
      const {body} = (await getMyPriceForecastPricing({
        equipmentType: selectedEquipmentType?.machine_readable,
        stops: stringifiedStops
      })) as ResponseBody;
      if (body.data) {
        return body.data;
      }
    },
    {enabled: stops.length > 0 && !!selectedEquipmentType}
  );

  return pricingForecastQuery;
};

export default useGetPricingForecast;
