import {useState, useEffect} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, change} from 'redux-form';
import PhoneNumberField from 'App/formComponents/phoneNumberField';
import renderTypeahead from 'App/formComponents/renderTypeahead';
import './DriverFields.scss';

const DriverFields = ({carrier, oldDriverField, selectedRelationship, dispatch}) => {
  const {drivers = []} = selectedRelationship || {};
  const {platNewDriverWorkflow} = useFlags() || {};
  const [selectedDriver, setSelectedDriver] = useState({});
  const [selectedDriverHasPhone, setSelectedDriverHasPhone] = useState(false);

  useEffect(() => {
    setSelectedDriver({});
    dispatch(change('newShipmentForm', 'driver_cell', ''));
  }, [carrier, dispatch]);

  return platNewDriverWorkflow ? (
    selectedRelationship && carrier ? (
      <div className="driverFields__fieldsContainer">
        <div className="driverFields__driverField">
          <Field
            name="driver"
            label="Driver"
            options={drivers}
            component={renderTypeahead}
            allowNew
            newSelectionPrefix={false}
            clearButton
            labelKey="full_name"
            clearOnFocus={false}
            onSelect={(driver) => {
              const newSelectedDriver = driver[0]?.customOption ? [{full_name: driver[0].label}] : driver;
              setSelectedDriver(newSelectedDriver);
              dispatch(change('newShipmentForm', 'driver', newSelectedDriver[0]));
              dispatch(change('newShipmentForm', 'driver_cell', driver[0]?.phone_number || ''));
              setSelectedDriverHasPhone(driver[0]?.phone_number);
            }}
            selected={selectedDriver}
          />
        </div>
        <div className={`driverFields__cellField ${selectedDriverHasPhone ? 'disabled' : ''}`}>
          <Field
            name="driver_cell"
            label="Driver Cell Phone (for shipment tracking)"
            verifyNumber
            req={!selectedDriverHasPhone}
            disabled={selectedDriverHasPhone}
            component={PhoneNumberField}
            onChangePhone={(phone) => {
              if (typeof phone === 'undefined') {
                dispatch(change('newShipmentForm', 'driver_cell', ' '));
              } else {
                dispatch(change('newShipmentForm', 'driver_cell', phone));
              }
            }}
            placeholder="Phone Number"
          />
        </div>
      </div>
    ) : null
  ) : (
    oldDriverField
  );
};
DriverFields.defaultProps = {
  carrier: null,
  oldDriverField: {},
  selectedRelationship: {
    drivers: []
  }
};
DriverFields.propTypes = {
  carrier: PropTypes.object,
  oldDriverField: PropTypes.object,
  selectedRelationship: PropTypes.object,
  dispatch: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    selectedRelationship: state.vendors.selectedRelationship
  };
};
export default connect(mapStateToProps)(DriverFields);
