import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * FedEx Registration
 */
export async function getFedExRegistrationAccounts(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationFEDEXGet(opts, apiCallback(resolve, reject));
  });
}

export async function updateFedExRegistrationAccount(fedexAccountId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationFEDEXFedexAccountIdPut(fedexAccountId, body, apiCallback(resolve, reject));
  });
}

/**
 * UPS Registration
 */
export async function createUPSRegistration(account_number, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationUPSPost(body, {account_number}, apiCallback(resolve, reject));
  });
}

export async function getUPSRegistrationAccounts(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationUPSGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * USPS Registration
 */
export async function createUSPSRegistration(account_number, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationUSPSPost(body, {account_number}, apiCallback(resolve, reject));
  });
}

export async function getUSPSRegistrationAccounts(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationUSPSGet(opts, apiCallback(resolve, reject));
  });
}
