import {JSX, useState, useMemo, useEffect, useCallback, useContext} from 'react';
import isNil from 'lodash/isNil';
import {Select, SvgIcon} from '@shipwell/shipwell-ui';
import {FacilityDock, ScheduledResourceTypeEnum} from '@shipwell/tempus-sdk';
import classNames from 'classnames';
import moment from 'moment';
import {AppointmentBookingContext} from '../AppointmentBookingContext';

import {Stop} from 'App/api/shipment/typed';
import {AppointmentAvailabilityList} from 'App/components/appointments/list';
import {addDays, humanizeDate} from 'App/utils/dateTimeGlobalsTyped';
import {AppointmentAvailability, AppointmentAvailabilityWindow} from 'App/data-hooks/appointments/types';
import {
  useAvailabilityQuery,
  useShipmentsStopAppointmentQuery,
  useFacilityPointsOfContactQuery,
  useShipmentStopQuery
} from 'App/data-hooks';
import {setTime} from 'App/containers/appointments/components/forms/AppointmentForm/utils';
import ShipwellLoader from 'App/common/shipwellLoader';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {uniqueAvailabilityWindows} from 'App/data-hooks/appointments/utils/computeAvailabilityWindows';
import {getAvailability, parseStopPlannedWindowCustomField} from 'App/api/facilities';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

/**
 * Parses the stop planned date in the expected format of `YYYY-MM-dd` and manually sets the Date
 * object's date. Time is set to the `Date.now()` time on the returned `Date` object. If the `planned_date`
 * is in the past `null` is returned.
 * @param {Stop} stop
 * @returns {Date|null} parsed planned date in the current user's locale or null if the planned date could not be parsed or is in the past.
 */
const getStopPlannedDate = (stop: Stop | null): Date | null => {
  if (!stop?.planned_date) {
    return null;
  }

  const [year, month, day] = stop.planned_date.split('-').map(Number);
  const plannedDate = new Date(year, month - 1, day);

  if (Number.isNaN(plannedDate.valueOf())) {
    // planned date is messed up and cannot be used
    return null;
  }
  if (plannedDate.valueOf() < new Date().valueOf()) {
    return null; // planned date is in the past and cannot be scheduled. Use current date.
  }
  return plannedDate;
};

const isSearchDateSameAsPlannedDate = (searchDate: string, stopPlannedDate: string) => {
  return moment(searchDate).isSame(moment(stopPlannedDate), 'date');
};

export type AvailabilityProps = {
  /**
   * Facility at which the freight will be dropped off
   * or picked up.
   */
  facilityId: string;
  /**
   * Optional shipment ID to filter down availability for scheduling a shipment.
   */
  shipmentId?: string | null;
  /**
   * Optional stop ID on the shipment to filter down availability. If stop ID is provided
   * Load Types are looked at for viability.
   */
  stopId?: string | null;
  /**
   * Callback handler for when the date has been updated to view a different window.
   */
  onSelectedDateChanged: (date: AppointmentAvailabilityWindow | null) => void;
  /**
   * Callback handler for when a user changes their dock selection.
   */
  onDockChanged: (dock: FacilityDock | null) => void;
  /**
   * Callback when a date is paginated to a new range
   * @param {Date} date the new date that will be displayed to the user
   * @returns {void}
   */
  onPaginate?: (date: Date) => void;
  /**
   * The time clicked on the calendar, if that was how the modal was activated.
   */
  clickedTime?: Date | null;
  /**
   * The form data for the Supplier Appointment flow
   */
  supplierAppointmentFormData?: SupplierAppointmentCreationFormType;
};

/**
 * Displays all available time slots that can be booked by a shipper or carrier. By default the
 * component will load the facility availability times. If a user selected an option for a dock
 * then the dock availabilities are selected.
 */
const Availability = ({
  facilityId,
  shipmentId,
  stopId,
  clickedTime,
  onDockChanged,
  onSelectedDateChanged,
  onPaginate,
  supplierAppointmentFormData
}: AvailabilityProps): JSX.Element | null => {
  const [isSelectDockInitialized, setIsSelectDockInitialized] = useState<boolean>(false); // flag for the first time around we need to select the dock for the user if there is already a dock assigned to the appointment.
  // user when they click on an availability window.
  const [selectedDockId, setSelectedDockId] = useState<string | null | undefined>();
  const [searchDate, setSearchDate] = useState<Date | null>(null);
  const [isDockUserSelected, setIsDockUserSelected] = useState(false);

  const startDateTime = searchDate ? setTime(searchDate, 0, 0, 0, 0) : null;
  const endDateTime = searchDate ? setTime(addDays(searchDate, 1), 0, 0, 0, 0) : null; // add 1 day because the midnight time of the next day
  const isSupplierAppointment = !shipmentId && !stopId;

  const {
    data: {appointment, facility},
    isAppointmentLoading
  } = useShipmentsStopAppointmentQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: Boolean(facilityId) && !isSupplierAppointment
    }
  );

  const {stop, isStopsInitialLoading, isStopsFetching} = useShipmentStopQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: Boolean(facility) && !appointment && !isSupplierAppointment // don't enable this if we have an appointment since that will set the current Date
    }
  );

  const isAvailabilityQueryEnabled = isSupplierAppointment ? true : !isStopsInitialLoading && Boolean(searchDate);
  const {
    data: availability,
    isAvailabilityInitialLoading: isAvailabilityInitialLoading,
    isFetching: isAvailabilityFetching
  } = useAvailabilityQuery(
    {
      facilityId: facility?.id ?? facilityId,
      stopId,
      shipmentId,
      startDateTime,
      endDateTime,
      appointmentId: appointment?.id,
      isSupplierAppointment: isSupplierAppointment,
      supplierAppointmentFormData: supplierAppointmentFormData,
      appointmentStatus: appointment?.status
    },
    {
      enabled: isAvailabilityQueryEnabled,
      select: ({timezone, windows, docks, isAllDay}: AppointmentAvailability) => {
        return {
          timezone: timezone,
          docks: docks,
          windows: windows.filter((window) => {
            if (!selectedDockId) {
              return true;
            }
            if (!isDockUserSelected) {
              return true; // the last dock change was triggered programmatically, so we don't want to filter down the windows.
            }
            return window.dockId === selectedDockId;
          }),
          isAllDay
        };
      }
    }
  );

  const {loadTypes} = useContext(AppointmentBookingContext);

  const loadTypeId = availability?.docks.find((dock) => dock.id === selectedDockId)?.dockRules?.[0]?.load_type_id;
  const loadType = loadTypes.find((loadType) => loadType.id === loadTypeId);

  /**
   * Sets current component dock to track changes for getting availability
   * and bubbles up dock changed to parent. If the user has selected the dock then the availability
   * windows will be filtered down to what is available to that dock.
   *
   * If the user did not select the dock and we are programmatically doing it for them then
   * the windows will remain as-is.
   * @param {string | null} dockId the dock ID to set
   * @param {boolean} isUserSelected whether or not the user selected the dock
   * @returns {void}
   */
  const handleDockChange = useCallback(
    (dockId: string | null, isUserSelected: boolean) => {
      setSelectedDockId(dockId);
      setIsDockUserSelected(isUserSelected ?? false);
      if (!dockId) {
        onDockChanged(null);
        return;
      }
      const dock = availability?.docks?.find((d) => d.id === dockId);
      if (isNil(dock)) {
        onDockChanged(null);
        return;
      }

      // manually map here for backwards compatibility.
      onDockChanged({
        id: dock.id,
        facility_id: facilityId,
        name: dock.name,
        color: dock.color
      });
    },
    [facilityId, availability?.docks, onDockChanged]
  );

  /**
   * Updates the current date for viewing availability, triggering an API call
   * and then bubbles up to the parent composer.
   */
  const handlePaginateDateClick = (daysToAdd: number) => {
    if (!searchDate) {
      return;
    }
    const newDate = addDays(searchDate, daysToAdd);
    setSearchDate(newDate);
    onPaginate?.(newDate);
  };

  const handleWindowSelect = useCallback(
    (window: AppointmentAvailabilityWindow | null) => {
      // the scenario this black magic handles for isUserSelected
      // 1. select a window
      // 2. a dock is automatically selected
      // 3. a user selects a different dock
      // 4. the user clicks a window
      // 5. don't refresh the data because the user last selected a window
      const isUserSelected = Boolean(isDockUserSelected) && Boolean(selectedDockId);
      if (window?.dockId !== selectedDockId) {
        handleDockChange(window?.dockId ?? null, isUserSelected);
      }
      onSelectedDateChanged(window);
    },
    [handleDockChange, isDockUserSelected, onSelectedDateChanged, selectedDockId]
  );

  useEffect(() => {
    if (
      (supplierAppointmentFormData?.is_all_day || loadType?.all_day_appointment) &&
      searchDate &&
      availability?.windows
    ) {
      const w = availability.windows.find((w) => w.startDate.original >= searchDate);
      handleWindowSelect(w ?? null);
    }
  }, [
    supplierAppointmentFormData?.is_all_day,
    loadType?.all_day_appointment,
    availability?.windows,
    handleWindowSelect,
    searchDate
  ]);

  const initializeSearchDate = async () => {
    const searchDateConditionalForShipperAppointments = !searchDate && !isStopsInitialLoading && facility;
    if (!searchDateConditionalForShipperAppointments && !isSupplierAppointment) {
      return;
    }

    // Order matters. We only first want to look at the appointment, if that's not provided then we can
    // fall back to trying to look at the stop.
    if (appointment?.start?.timestamp && Date.parse(appointment.start.timestamp) >= Date.now()) {
      // only set this date if the appointment is today or in the future
      setSearchDate(new Date(appointment.start.timestamp));
    } else if (clickedTime) {
      setSearchDate(clickedTime);
    } else if (stop) {
      if (stop.custom_data && stop.planned_date && stopId && shipmentId) {
        const res = await parseStopPlannedWindowCustomField(stop.custom_data, stop.planned_date);

        if (!facility || !res) return setSearchDate(new Date());

        const {available_windows} = await getAvailability(
          facility.id,
          omitEmptyKeysWithEmptyObjectsRemoved({
            request_criteria_type: ScheduledResourceTypeEnum.Shipment,
            shipment_id: shipmentId,
            stop_id: stopId,
            start_datetime: new Date(res.planning_window.start).toISOString() || '',
            end_datetime: addDays(res.planning_window.end, 1).toISOString() || '',
            rescheduling_for_appointment_id: appointment?.id
          })
        );

        if (!available_windows || !available_windows.length) return setSearchDate(new Date());

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (const item of available_windows) {
          const startDate = new Date(item.start.timestamp);
          startDate.setHours(0, 0, 0, 0);

          if (startDate >= today) {
            setSearchDate(new Date(startDate));
            break;
          } else {
            setSearchDate(new Date());
          }
        }
      } else {
        setSearchDate(getStopPlannedDate(stop) ?? new Date());
      }
    } else {
      setSearchDate(new Date());
    }
  };

  const {isFacilityPointOfContactsLoading, facilityPointOfContacts} = useFacilityPointsOfContactQuery(facilityId);

  const isLoading = Boolean(
    isStopsInitialLoading ||
      isStopsFetching ||
      isAppointmentLoading ||
      isAvailabilityInitialLoading ||
      isAvailabilityFetching ||
      !searchDate
  );

  const isPreviousButtonDisabled =
    !isLoading &&
    searchDate &&
    stop?.is_pickup &&
    stop?.planned_date &&
    isSearchDateSameAsPlannedDate(searchDate.toISOString(), stop?.planned_date);

  const isNextButtonDisabled =
    !isLoading &&
    searchDate &&
    stop?.is_dropoff &&
    stop?.planned_date &&
    isSearchDateSameAsPlannedDate(searchDate.toISOString(), stop?.planned_date);

  const pointOfContact = useMemo(() => {
    return facilityPointOfContacts?.find((value) => value.is_default_for_facility);
  }, [facilityPointOfContacts]);

  if (!isLoading && !isSelectDockInitialized) {
    // there is a dock available for the user to select, so we need to select it for the user.
    const bestMatchDock = availability?.docks
      .filter((rankedDock) => {
        // it is possible that there is a dock with availability however it does not have the full window
        // necessary to accommodate the load/unload window. In this case we want to filter out the dock.
        return availability.windows.some((window) => window.dockId === rankedDock.id);
      })
      .sort((a, b) => a.rank - b.rank)[0];
    if (bestMatchDock) {
      handleDockChange(bestMatchDock.id, true); // this is the one lie where we want to pair down the windows to the dock.
    }
    setIsSelectDockInitialized(true);
  }

  const uniqueWindows = useMemo(() => uniqueAvailabilityWindows(availability?.windows ?? []), [availability?.windows]);

  useEffect(() => {
    // apparently it isn't valid to call a setState from a parent component while rendering....
    if (!searchDate) {
      void initializeSearchDate();
    }
  });
  const dockOptions =
    availability?.docks.map((dock) => ({
      label: dock.name,
      value: dock.id
    })) || [];

  return (
    <div className="flex h-full flex-col gap-y-2">
      <p className="w-text-section-title font-bold">Select Appointment</p>
      <div className="flex flex-row">
        <button
          type="button"
          disabled={!!isPreviousButtonDisabled}
          className={classNames('flex flex-row items-center gap-1', {
            'text-sw-label': isPreviousButtonDisabled,
            'text-sw-primary': !isPreviousButtonDisabled
          })}
          role="button"
          onClick={() => handlePaginateDateClick(-1)}
        >
          <SvgIcon name="ArrowSmallLeft" />
          Previous
        </button>
        {isLoading ? (
          <div className="mx-auto block">
            <SvgIcon name="LoadingDots" />
          </div>
        ) : (
          <p className="m-0 grow text-center font-bold">{searchDate ? humanizeDate(searchDate) : ''}</p>
        )}
        <button
          type="button"
          disabled={!!isNextButtonDisabled}
          className={classNames('flex flex-row items-center gap-1', {
            'text-sw-label': isNextButtonDisabled,
            'text-sw-primary': !isNextButtonDisabled
          })}
          role="button"
          onClick={() => handlePaginateDateClick(1)}
        >
          Next
          <SvgIcon name="ArrowSmallRight" />
        </button>
      </div>
      <div className="border-b-1 border-sw-border"></div>
      <div className="max-h-80 grow scroll-pb-14 overflow-y-auto">
        {isLoading ? (
          <ShipwellLoader loading />
        ) : !uniqueWindows.length ? (
          <div className="flex h-full flex-row items-center">
            <div className="flex grow flex-col items-center">
              <p className="text-lg font-bold text-sw-disabled-text">No Appointment Times</p>
            </div>
          </div>
        ) : availability?.isAllDay ? (
          <p className="grid h-[106px] place-content-center rounded border-1 border-sw-disabled-alternate bg-sw-active-light">
            <div className="text-base text-sw-text">All Day</div>
          </p>
        ) : (
          <>
            <AppointmentAvailabilityList windows={uniqueWindows} onItemClick={handleWindowSelect} />
            <div className="sticky bottom-0 left-0 z-40 h-14 bg-gradient-to-t from-sw-background-component"></div>
          </>
        )}
      </div>
      <Select
        required
        disabled={isLoading}
        name="dock_select"
        label="Select Dock"
        value={selectedDockId}
        menuPortalTarget={document.body}
        styles={{menuPortal: (base: {[prop: string]: string | number}) => ({...base, zIndex: 9999})}}
        options={dockOptions}
        simpleValue
        onChange={(dockId: string) => handleDockChange(dockId, true)}
      />
      <p className="sw-text-section-title mt-4 text-sm">
        {isFacilityPointOfContactsLoading ? (
          <SvgIcon name="LoadingDots" />
        ) : (
          <em>
            *Can&apos;t find an available time? Please contact {pointOfContact?.person_name ?? 'your logistics manager'}
            .
          </em>
        )}
      </p>
    </div>
  );
};

export default Availability;
