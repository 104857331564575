import {createElement, useState, useEffect, useCallback} from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import {
  SvgIcon,
  Dropdown,
  Tooltip,
  Toast,
  DragDropContainer,
  ToggleSwitch,
  Button,
  IconButton,
  IconButtonWithTooltip
} from '@shipwell/shipwell-ui';
import {
  checkFilterObj,
  cleanFilterPayload,
  unnestTableView,
  cleanReadbackAndSavePayload,
  FILTERS,
  COLUMNS,
  SAVED,
  removeDndKeys
} from './utils';
import {tableConfig} from 'App/api';
import TableViewForm from 'App/formComponents/forms/tableView';
import usePrevious from 'App/utils/hooks/usePrevious';
import './styles.scss';

import {TableFiltersContextConsumer} from 'App/utils/hooks/useTableFilters';

//get filters for orders table
const TableFiltersWithSavedViews = (props) => {
  const {
    filters,
    tableType,
    activeFilters,
    onChange,
    clearFilter,
    clearAllFilters,
    selectedTableView,
    setSelectedTableView,
    tableColumns,
    setTableColumns,
    setUserUpdatedColumns
  } = props;
  const [open, setOpen] = useState(false);
  const [expandedFilters, setExpandedFilters] = useState([]);
  const [activeTab, setActiveTab] = useState(FILTERS);
  const [tableViews, setTableViews] = useState([]);
  const [renameExistingView, setRenameExistingView] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [successfullyUpdatedView, setSuccessfullyUpdatedView] = useState({});
  const [showViewForm, setShowViewForm] = useState(false);
  const [displayError, setDisplayError] = useState(null);
  //Used to track updates to initial column array
  const [defaultColumns] = useState(tableColumns);
  const [inactiveColumns, setInactiveColumns] = useState([]);
  const previousSelectedView = usePrevious(selectedTableView);
  /**
   * Apply a default table view if one exists
   * @param {Array} tableView
   */
  const handleSetDefaultTableView = useCallback(
    (tableViews) => {
      const defaultTableView = tableViews.find((view) => view.is_default);
      if (defaultTableView) {
        setUserUpdatedColumns?.(true);
        setSelectedTableView(defaultTableView);
        onChange(defaultTableView.filters, defaultTableView.id);
      }
    },
    [onChange, setSelectedTableView, setUserUpdatedColumns]
  );

  /**
   * Get list of table views
   * @param {Boolean} setDefault whether or not to apply a default filter if exists
   *
   */
  const handleGetTableViews = useCallback(
    async (setDefault = false) => {
      try {
        const response = await tableConfig.getUserTableConfigurationPromise({tableType});
        //get the nested table view from the saved config
        const savedTableViews = response.body?.results?.map((view) => unnestTableView(view, defaultColumns));
        setTableViews(savedTableViews);
        if (setDefault) {
          handleSetDefaultTableView(savedTableViews);
        }
      } catch (error) {
        console.error('There was an error getting table views');
        setDisplayError(error.error_description);
        throw error;
      }
    },
    [defaultColumns, handleSetDefaultTableView, tableType]
  );

  useEffect(() => {
    //only get the saved table views if table type is specified in swTable and columns exist
    if (tableType && defaultColumns) {
      handleGetTableViews(true);
    }
  }, []);

  //set the table columns specified in the selected table view
  useEffect(() => {
    if (
      Object.keys(selectedTableView).length > 0 &&
      !isEqual(tableColumns, selectedTableView.columns) &&
      !isEqual(previousSelectedView, selectedTableView)
    ) {
      setUserUpdatedColumns?.(true);
      const selectedTableViewColumns = selectedTableView.columns.filter((column) => column);
      //set inactive columns not in the selected view
      setInactiveColumns([
        ...defaultColumns.filter(
          (column) =>
            !selectedTableViewColumns
              .filter((column) => !!column)
              .some((selectedColumn) => column.id === selectedColumn.id)
        )
      ]);
      setTableColumns(selectedTableViewColumns);
    }
  }, [
    selectedTableView,
    tableColumns,
    defaultColumns,
    tableType,
    handleGetTableViews,
    setTableColumns,
    previousSelectedView,
    setUserUpdatedColumns
  ]);

  const handleSetUpdateSuccess = () => {
    setUpdateSuccess(true);
    setTimeout(() => setUpdateSuccess(false), 3000);
  };

  const handleSetCreateSuccess = () => {
    setCreateSuccess(true);
    setTimeout(() => setCreateSuccess(false), 10000);
  };

  const resetTableView = () => {
    setSelectedTableView({});
    clearAllFilters();
    setTableColumns(defaultColumns);
    setInactiveColumns([]);
    handleGetTableViews();
  };

  /**
   * Submit a table view as new default (will override previous default)
   * @param {Array} tableView
   */
  const handleSubmitDefaultTableView = async (tableView) => {
    //remove filters and columns keys since we want to nest them properly in config
    const cleanedTableView = omit(tableView, [FILTERS, COLUMNS]);
    try {
      const response = await tableConfig.updateUserTableConfigurationPromise(tableView.id, {
        ...cleanedTableView,
        is_default: !tableView.is_default
      });
      setSuccessfullyUpdatedView(unnestTableView(response.body, defaultColumns));
      setSelectedTableView(unnestTableView(response.body, defaultColumns));
      setUserUpdatedColumns?.(true);
      handleSetUpdateSuccess();
      handleGetTableViews();
    } catch (error) {
      console.error('There was an error setting default table view');
      setDisplayError(error.error_description);
      throw error;
    }
  };

  const handleDeleteTableView = async (tableViewId) => {
    try {
      await tableConfig.deleteUserTableConfigurationPromise(tableViewId);
      resetTableView();
    } catch (error) {
      console.error('There was an error deleting table view');
      setDisplayError(error.error_description);
      throw error;
    }
  };

  /**
   * Submit a table view
   * @param {Array} tableView
   * @param {Func} setSubmitting
   * @param {Boolean} renameExistingView optional argument if user clicks 'create' button
   */
  const handleSubmitTableView = async (values, {setSubmitting}) => {
    //remove filters and columns keys since we want to nest them properly in config
    const cleanedValues = omit(values, [FILTERS, COLUMNS]);
    const filters = transformFilterValuessToArrays(cleanReadbackAndSavePayload(activeFilters));
    setSubmitting(true);
    if (selectedTableView.id && renameExistingView) {
      try {
        const response = await tableConfig.updateUserTableConfigurationPromise(selectedTableView.id, {
          ...cleanedValues,
          table_type: tableType,
          config: {
            filters,
            ordering: ['-planned_pickup_start_datetime'],
            columns: [...tableColumns.map((column) => column.id)]
          }
        });
        setSuccessfullyUpdatedView(unnestTableView(response.body, defaultColumns));
        setSelectedTableView(unnestTableView(response.body, defaultColumns));
        handleSetUpdateSuccess();
        handleGetTableViews();
      } catch (error) {
        console.error('There was an error updating table view');
        setDisplayError(error.error_description);
      }
    } else {
      try {
        const response = await tableConfig.createUserTableConfigurationPromise({
          ...cleanedValues,
          table_type: tableType,
          config: {
            filters,
            ordering: ['-planned_pickup_start_datetime'],
            columns: [...tableColumns.map((column) => column.id)]
          }
        });
        //we are creating a new table view, apply if create is successful
        setSelectedTableView(unnestTableView(response.body, defaultColumns));
        handleSetCreateSuccess();
        handleGetTableViews();
      } catch (error) {
        console.error('There was an error creating table view');
        setDisplayError(error.error_description);
        setSubmitting(false);
        throw error;
      }
    }
    setRenameExistingView(false);
    setSubmitting(false);
    setShowViewForm(false);
    setUserUpdatedColumns?.(true);
  };
  const handleUpdateTableView = async () => {
    //remove filters and columns keys since we want to nest them properly in config
    const cleanedValues = omit(selectedTableView, [FILTERS, COLUMNS]);
    const filters = transformFilterValuessToArrays(cleanReadbackAndSavePayload(activeFilters));

    if (selectedTableView.id) {
      try {
        const response = await tableConfig.updateUserTableConfigurationPromise(selectedTableView.id, {
          ...cleanedValues,
          table_type: tableType,
          config: {
            filters,
            ordering: ['-planned_pickup_start_datetime'],
            columns: [...tableColumns.map((column) => column.id)]
          }
        });
        setSuccessfullyUpdatedView(unnestTableView(response.body, defaultColumns));
        setSelectedTableView(unnestTableView(response.body, defaultColumns));
        handleSetUpdateSuccess();
        handleGetTableViews();
        setUserUpdatedColumns?.(true);
      } catch (error) {
        console.error('There was an error updating table view');
        setDisplayError(error.error_description);
      }
    }
  };

  const handleToggle = (filter) => {
    if (expandedFilters.includes(filter)) {
      setExpandedFilters(expandedFilters.filter((e) => e !== filter));
    } else {
      setExpandedFilters([...expandedFilters, filter]);
    }
  };

  const checkFilterStatus = (filter) => {
    if (activeFilters[filter.name] && activeFilters[filter.name].length > 0) {
      return true;
    }
    if (activeFilters[filter.secondaryName] && activeFilters[filter.secondaryName].length > 0) {
      return true;
    }
    if (
      filter.useRange &&
      ((activeFilters[`${filter.name}Gte`] && activeFilters[`${filter.name}Gte`].length > 0) ||
        (activeFilters[`${filter.name}Lte`] && activeFilters[`${filter.name}Lte`].length > 0))
    ) {
      return true;
    }
    return false;
  };

  const getParentValue = (filter) => {
    if (filter.useRange) {
      if (activeFilters[`${filter.name}Gte`] || activeFilters[`${filter.name}Lte`]) {
        return [activeFilters[`${filter.name}Gte`], activeFilters[`${filter.name}Lte`]];
      }
      return null;
    }

    return activeFilters[filter.name];
  };

  /**
   * Set active filter drawer tab
   * @param {Array} tab
   */
  const handleSetActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  /**
   * Helper function to reset tab state when switching tabs
   * @param {Array} tab tab the user clicked on
   */
  const resetTabState = (tab) => {
    handleSetActiveTab(tab);
    setShowViewForm(false);
    setCreateSuccess(false);
  };

  /**
   * Handle conditional rendering of dropdown or success
   * @param {Obj} tableView tableView
   */
  const handleMenuDisplay = (tableView) => {
    if (successfullyUpdatedView.id === tableView.id && updateSuccess) {
      return <SvgIcon color="$sw-success" name="CheckCircleFilled" />;
    }
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          variant="icon"
          indicator={false}
          drop="left"
          icon={<SvgIcon name="Overflow" />}
          className="filters__view-dropdown"
        >
          {({onClick}) => {
            return (
              <>
                <li
                  onClick={(e) => {
                    //prevent click from triggering table view deselect
                    e.stopPropagation();
                    onClick();
                    handleSubmitDefaultTableView(tableView);
                  }}
                >
                  {tableView.is_default ? 'Remove as Default' : 'Set as Default'}
                </li>
                <li
                  onClick={(e) => {
                    //prevent click from triggering table view deselect
                    e.stopPropagation();
                    onClick();
                    setSelectedTableView(tableView);
                    setRenameExistingView(true);
                    setShowViewForm(true);
                  }}
                >
                  Rename
                </li>
                <Tooltip
                  tooltipContent={`Are you sure you want to delete ${tableView.name}?`}
                  trigger="click"
                  placement="top"
                  primaryAction="Delete"
                  primaryActionVariant="warning"
                  secondaryAction="Cancel"
                  tooltipClassname="filters__view-delete-tooltip-wrapper"
                  wrapperClassname="filters__view-delete-tooltip"
                  onPrimaryAction={() => {
                    handleDeleteTableView(tableView.id);
                    onClick();
                  }}
                >
                  <li
                    onClick={() => {
                      setSelectedTableView(tableView);
                    }}
                  >
                    Delete
                  </li>
                </Tooltip>
              </>
            );
          }}
        </Dropdown>
      </div>
    );
  };

  const showFooterActionButtons = () => {
    //dont show footer actions if table view form is open
    //or there is no saved views for the table
    if (showViewForm || !tableType || activeTab === SAVED) {
      return false;
    }
    //filters have been updated
    if (
      checkFilterObj(activeFilters) &&
      !isEqual(
        //remove empty array keys in comparison
        pickBy(cleanReadbackAndSavePayload(activeFilters), (value) => value?.length > 0),
        pickBy(cleanFilterPayload(selectedTableView?.filters), (value) => value?.length > 0)
      )
    ) {
      return true;
      //columns have been updated from default
    }
    if (
      !Object.keys(selectedTableView).length > 0 &&
      !isEqual(
        tableColumns.map((column) => column.id),
        defaultColumns.map((column) => column.id)
      )
    ) {
      return true;
    }
    //columns have been updated from selected table view
    if (
      Object.keys(selectedTableView).length > 0 &&
      !isEqual(
        tableColumns.map((column) => column.id),
        selectedTableView?.columns.filter((column) => column).map((column) => column.id)
      )
    ) {
      return true;
    }
    return false;
  };

  const draggableTableColumn = (column) => {
    const {id, draggableProvided, draggableId, label} = column;
    return (
      <div
        key={draggableId}
        ref={draggableProvided.innerRef}
        className="filters__column"
        {...draggableProvided.dragHandleProps}
        {...draggableProvided.draggableProps}
      >
        <div className="filters__column-label-container overflow-hidden">
          <SvgIcon name="Drag" />
          <div className="flex w-full overflow-hidden">
            <span className="filters__column-label-description block w-full overflow-hidden text-ellipsis">
              {label}
            </span>
          </div>
        </div>
        <div className="filters__column-toggle">
          <ToggleSwitch
            id={id}
            name={id}
            onChange={() => {
              setInactiveColumns((prevInactive) => [...prevInactive, removeDndKeys(column)]);
              setTableColumns((prevCols) => prevCols.filter((prevCol) => prevCol.id !== id));
              setUserUpdatedColumns?.(true);
            }}
            checked
          />
        </div>
      </div>
    );
  };

  const inactiveTableColumn = (column) => {
    const {label, id} = column;
    return (
      <div key={id} className="filters__column inactive">
        <div className="filters__column-label-container">
          <SvgIcon name="Drag" />
          <div>
            <span className="filters__column-label-description">{label}</span>
          </div>
        </div>
        <div className="filters__column-toggle">
          <ToggleSwitch
            id={id}
            name={id}
            onChange={() => {
              setInactiveColumns(inactiveColumns.filter((inactiveColumn) => inactiveColumn.id !== id));

              setTableColumns(
                sortBy([...tableColumns, column], (c) => {
                  //find the column the user is activating and place in original index
                  if (c.id === id) {
                    return defaultColumns.findIndex((orderedColumn) => orderedColumn.id === c.id) - 1;
                  }
                  return tableColumns.findIndex((tableColumn) => tableColumn.id === c.id);
                })
              );
              setUserUpdatedColumns?.(true);
            }}
            checked={false}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${open ? 'open' : ''} filters`}>
        {open ? (
          <>
            <div
              className={classNames('filters__header', {
                'first-row-active':
                  //if first table view selected, add class to remove border
                  activeTab === SAVED &&
                  Object.keys(selectedTableView).length > 0 &&
                  tableViews.length > 0 &&
                  selectedTableView.id === tableViews[0].id
              })}
            >
              <div className="filters__header-tab-container">
                <div
                  onClick={() => {
                    resetTabState(FILTERS);
                  }}
                  className={classNames('filters__header-tab', {active: activeTab === FILTERS})}
                >
                  <span>Filters</span>
                </div>
                {tableType && (
                  <>
                    <div
                      onClick={() => {
                        resetTabState(COLUMNS);
                      }}
                      className={classNames('filters__header-tab', {active: activeTab === COLUMNS})}
                    >
                      <span>Columns</span>
                    </div>
                    <div
                      onClick={() => {
                        resetTabState(SAVED);
                      }}
                      className={classNames(
                        'filters__header-tab',
                        {active: activeTab === SAVED},
                        {saved: createSuccess}
                      )}
                    >
                      <span>Saved</span>
                    </div>
                  </>
                )}
              </div>
              <div className="mr-2">
                <IconButton onClick={() => setOpen(false)} iconName="Close" aria-label="Close side panel" />
              </div>
            </div>
            {filters?.length && activeTab === FILTERS && (
              <div
                className={classNames(
                  'filters__body',
                  {expandedFooter: (showViewForm || updateSuccess) && activeTab === FILTERS},
                  {successFooter: createSuccess || updateSuccess}
                )}
              >
                {filters.map((filter) => {
                  return (
                    <div
                      key={filter.name}
                      className={classNames('filters__filter', {expanded: expandedFilters.includes(filter.name)})}
                    >
                      <div className="filters__filter-header" onClick={() => handleToggle(filter.name)}>
                        <span className="filters__filter-label">{filter.label}</span>
                        <span className="filters__filter-actions">
                          {checkFilterStatus(filter) && (
                            <span
                              onClick={(e) => clearFilter(e, filter.name, filter.secondaryName, filter.useRange)}
                              className="filters__filter-clear"
                            >
                              Clear
                            </span>
                          )}
                          <IconButton
                            aria-label="toggle filter visibility"
                            iconName={expandedFilters.includes(filter.name) ? 'ExpandLess' : 'ExpandMore'}
                            isActive={expandedFilters.includes(filter.name)}
                          />
                        </span>
                      </div>
                      {expandedFilters.includes(filter.name) &&
                        createElement(filter.type, {
                          ...filter,
                          onChange,
                          parentValue: getParentValue(filter),
                          parentSecondaryValue: activeFilters[filter.secondaryName] || null
                        })}
                    </div>
                  );
                })}
              </div>
            )}
            {activeTab === COLUMNS && (
              <div
                className={classNames(
                  'filters__body',
                  {'expandedFooter-columns': showViewForm && activeTab === COLUMNS},
                  {successFooter: createSuccess || updateSuccess}
                )}
              >
                <div className="filters__columns-info-message-container">
                  <span className="filters__columns-info-message">Drag to reorder columns</span>
                </div>
                <DragDropContainer
                  items={tableColumns}
                  Component={draggableTableColumn}
                  onDragEnd={(result) => {
                    setTableColumns(result);
                    setUserUpdatedColumns?.(true);
                  }}
                />
                {inactiveColumns.length > 0 &&
                  inactiveColumns.map((column) => {
                    return inactiveTableColumn(column);
                  })}
              </div>
            )}
            {filters?.length &&
              activeTab === SAVED &&
              (tableViews.length > 0 ? (
                <div
                  className={classNames(
                    'filters__views',
                    {expandedFooter: showViewForm && [SAVED].includes(activeTab)},
                    {successFooter: createSuccess || updateSuccess}
                  )}
                >
                  {tableViews
                    //show the default filter at the top
                    .sort((view) => (view.is_default ? -1 : 1))
                    .map((view) => {
                      return (
                        <div
                          key={view.name}
                          className={classNames(
                            'filters__view',
                            {selected: selectedTableView.id === view.id && !showViewForm},
                            {expanded: selectedTableView.id === view.id && showViewForm}
                          )}
                          onClick={(e) => {
                            if (!showViewForm) {
                              if (!isEqual(selectedTableView, view)) {
                                onChange(view.filters, view.id);
                                setSelectedTableView(view);
                                setUserUpdatedColumns?.(true);
                              } else {
                                resetTableView();
                              }
                            }
                          }}
                        >
                          <div className="filters__view-header">
                            <div className="filters__view-label-container">
                              <span className="filters__view-label">{view.name}</span>
                            </div>
                            {view.is_default && <span className="filters__view-default">Default</span>}
                            {handleMenuDisplay(view)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="filters__views emptyState">
                  <h4>No Saved Filters</h4>
                </div>
              ))}
            {!(createSuccess || updateSuccess) ? (
              <div
                className={classNames('filters__footer', {
                  expandedFooter: showViewForm && [FILTERS, COLUMNS, SAVED].includes(activeTab)
                })}
              >
                {showViewForm && (
                  <TableViewForm
                    values={selectedTableView}
                    renameExistingView={renameExistingView}
                    onSubmit={handleSubmitTableView}
                    onCancel={() => {
                      setShowViewForm(false);
                      setRenameExistingView(false);
                    }}
                  />
                )}
                <div className={classNames('filters__footer-actions-container', {viewFormDisplayed: showViewForm})}>
                  <div className="filters__footer-tooltip-buttons">
                    {activeTab === FILTERS && (
                      <>
                        <IconButtonWithTooltip
                          onClick={() => setExpandedFilters(filters.map((filter) => filter.name))}
                          iconName="ExpandAll"
                          aria-label="Expand all filters"
                        />
                        <IconButtonWithTooltip
                          onClick={() => setExpandedFilters([])}
                          iconName="CollapseAll"
                          aria-label="Collapse all filters"
                        />
                      </>
                    )}
                  </div>

                  <div className="filters__footer-action-buttons">
                    {showFooterActionButtons() && (
                      <>
                        {Object.keys(selectedTableView).length > 0 && (
                          <Button
                            variant="tertiary"
                            disabled={Object.keys(selectedTableView).length === 0}
                            onClick={() => {
                              handleUpdateTableView();
                            }}
                          >
                            Save Changes
                          </Button>
                        )}

                        <Button
                          onClick={() => {
                            setShowViewForm(true);
                          }}
                        >
                          Create
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="filters__footer-success">
                <div className="filters__footer-success-container">
                  <div className="filters__footer-success-icon-container">
                    <div className="filters__footer-success-icons">
                      <span className="filters__footer-success-header">
                        Dashboard {createSuccess ? 'Saved' : 'Updated'}
                      </span>
                      <SvgIcon color="$sw-success" name="CheckCircleFilled" />
                    </div>
                    <div>
                      <div className="filters__footer-success-container">
                        <SvgIcon
                          name="Close"
                          className="action"
                          onClick={() => {
                            setCreateSuccess(false);
                            setUpdateSuccess(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <span>
                    To view your dashboards, select{' '}
                    <a className="action" onClick={() => resetTabState('saved')}>
                      Saved
                    </a>{' '}
                    at the top.
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="filters__header-closed">
            <i
              onClick={() => setOpen(true)}
              className={classNames('material-icons action filter-list', {
                activeFilters: Object.keys(activeFilters).length > 0
              })}
            >
              filter_list
            </i>
          </div>
        )}
      </div>
      {open && <div className="filters__border-right" />}
      <Toast
        show={Boolean(displayError)}
        title="Error"
        variant="error"
        anchor="bottom-right"
        onClose={() => setDisplayError(null)}
      >
        {displayError}
      </Toast>
    </>
  );
};
// eslint-disable-next-line react/display-name
export default (props) => (
  <TableFiltersContextConsumer>
    {(values) => <TableFiltersWithSavedViews {...props} {...values} />}
  </TableFiltersContextConsumer>
);

// UI needs all values as arrays, this ensures a date string can't slip through
const transformFilterValuessToArrays = (filters) =>
  Object.keys(filters).reduce((allFilters, filterKey) => {
    allFilters[filterKey] = typeof filters[filterKey] === 'string' ? [filters[filterKey]] : filters[filterKey];
    return allFilters;
  }, {});
