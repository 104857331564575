import {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import {SvgIcon, SearchField, Modal} from '@shipwell/shipwell-ui';
import {products, purchaseOrders, shipment, addressBook, carriers, brokers, quoting} from 'App/api';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import './styles.scss';

/**
 * Search Catgories Configs
 */
export const categories = {
  shipment: {
    name: 'Shipments',
    path: (id) => `/shipments/${id}`,
    api: shipment.getShipmentsPromise,
    fields: ['id', 'reference_id', 'customer', 'stops']
  },
  loadboard: {
    name: 'Load Board',
    path: (loadboardId) => `/load-board/${loadboardId}`,
    api: quoting.getLoadboardShipments,
    fields: ['id', 'load_board_id', 'customer', 'stops']
  },
  purchaseOrders: {
    name: 'Purchase Orders',
    path: (id) => `/purchase-orders/${id}`,
    api: purchaseOrders.getPurchaseOrdersList,
    fields: ['id', 'order_number', 'description', 'customer_name', 'destination_address', 'origin_address']
  },
  products: {
    name: 'Products',
    path: (id) => `/products/${id}`,
    api: products.getProductsPromise,
    fields: ['id', 'image', 'product_ref', 'description']
  },
  customers: {
    name: 'Customers',
    path: (id) => `/manage_customers/${id}`,
    api: brokers.fetchBrokerShipperRelationshipsPromise,
    fields: ['id', 'company']
  },
  addressBook: {
    name: 'Address Book',
    path: (id) => `/addressbook/${id}`,
    api: addressBook.getAddressBookPromise,
    fields: ['id', 'location_name', 'address']
  },
  carriers: {
    name: 'Carriers',
    path: (id) => `/carriers/${id}`,
    api: carriers.getCarrierRelationshipsPromise,
    fields: ['id', 'shipwell_vendor']
  }
};

/**
 * Global Search Field
 */
const GlobalSearchField = (props) => {
  const {sections, count, company, router} = props;
  // Data State
  const [results, setResults] = useState([]);
  // UI State
  const [expandSearch, setExpandSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  /**
   * Search it all
   */
  const searchByQuery = async (value) => {
    setIsSearching(true);

    try {
      const result = {options: []};
      const responses = await Promise.allSettled(
        sections.map((section) => {
          if (section === 'customers') {
            return categories[section].api(company.brokerage.id, {q: value, pageSize: count});
          }
          if (section === 'loadboard') {
            return categories[section].api({
              q: value,
              pageSize: count,
              biddingStatus: ['won', 'bidding', 'open', 'closed', 'tendered', 'accepted', 'rejected', 'expired']
            });
          }
          return categories[section].api({q: value, pageSize: count});
        })
      );

      if (responses) {
        responses
          .filter((response) => response.status === 'fulfilled')
          .forEach((response, index) => {
            response.value?.body?.results.forEach((sectionResult, itemIndex) => {
              result.options.push({
                init: !itemIndex,
                type: sections[index],
                label: value,
                category: categories[sections[index]].name,
                ...categories[sections[index]].fields.reduce(
                  (values, key) => ({
                    ...values,
                    [key]: sectionResult[key]
                  }),
                  {}
                )
              });
            });
          });
      }

      setResults(result.options);
      setIsSearching(false);

      return result.options;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelection = (selection = {}) => {
    let path = null;
    if (selection && categories[selection.type]) {
      path = categories[selection.type].path(selection.id);
    }
    if (selection && categories[selection.type] && selection.type === 'loadboard') {
      path = categories[selection.type].path(selection.load_board_id);
    }
    if (path) {
      setExpandSearch(false);
      router.push(path);
    }
  };

  const handleToggleSearch = () => {
    setExpandSearch(!expandSearch);
  };

  const renderProductOption = (option) => {
    return (
      <div className="search-option">
        {option.image && <span className="search-icon" style={{backgroundImage: `url(${option.image})`}}></span>}
        {!option.image && (
          <span className="search-icon">
            <i className="flaticon-products" />
          </span>
        )}
        <span>
          <p>
            <strong className="option-label">{option.product_ref}</strong>
          </p>
          <Truncate>{option.description}</Truncate>
        </span>
      </div>
    );
  };

  const renderShipmentOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <i className="flaticon-vendors" />
        </span>
        <span>
          <p>
            <strong className="option-label">{option.reference_id}</strong> {option.customer.name}
          </p>
          {option.stops && option.stops.length > 1 && (
            <Truncate>
              {option.stops[0].location && option.stops[0].location.address.formatted_address} to{' '}
              {option.stops[1].location && option.stops[1].location.address.formatted_address}
            </Truncate>
          )}
        </span>
      </div>
    );
  };

  const renderLoadBoardOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <SvgIcon color="sw-icon" name="Loadboard" />
        </span>
        <span>
          <p>
            <strong className="option-label">{option.load_board_id}</strong> {option.customer.name}
          </p>
          {option.stops && option.stops.length > 1 && (
            <Truncate>
              {option.stops[0].location && option.stops[0].location.address.formatted_address} to{' '}
              {option.stops[1].location && option.stops[1].location.address.formatted_address}
            </Truncate>
          )}
        </span>
      </div>
    );
  };

  const renderPurchaseOrderOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <i className="flaticon-list" />
        </span>
        <span>
          <p>
            <strong className="option-label">{option.order_number}</strong> {option.description}
          </p>
          <p>Customer: {option.customer_name}</p>
        </span>
      </div>
    );
  };

  const renderCustomerOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <i className="flaticon-customers" />
        </span>
        <span>
          <p>
            <strong className="option-label">{option.company.name}</strong>
          </p>
          <p>{option.company.mailing_address && option.company.mailing_address.formatted_address}</p>
          {option.company.primary_phone_number && <p>Phone: {option.company.primary_phone_number}</p>}
        </span>
      </div>
    );
  };

  const renderAddressBookOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <i className="flaticon-address_book" />
        </span>
        <span>
          <p>
            <strong className="option-label">{option.location_name}</strong>
          </p>
          <p>{option.address && option.address.formatted_address}</p>
        </span>
      </div>
    );
  };

  const renderCarrierOption = (option) => {
    return (
      <div className="search-option">
        <span className="search-icon">
          <i className="flaticon-vendors" />
        </span>
        <span>
          {option.shipwell_vendor && (
            <p>
              <strong className="option-label">{option.shipwell_vendor.name}</strong>
            </p>
          )}
          {option.shipwell_vendor.identifying_codes &&
            option.shipwell_vendor.identifying_codes.map((code) => (
              <Fragment>
                {code.type === 'USDOT' && <p>USDOT: {code.value}</p>}
                {code.type === 'MC_NUMBER' && <p>MC NUMBER: {code.value}</p>}
              </Fragment>
            ))}
        </span>
      </div>
    );
  };

  const renderSearchResults = ({option, onClick}) => (
    <>
      {option.init && <li className="section-header">{option.category}</li>}
      <li onClick={onClick}>
        {option.type === 'products' && renderProductOption(option)}
        {option.type === 'loadboard' && renderLoadBoardOption(option)}
        {option.type === 'shipment' && renderShipmentOption(option)}
        {option.type === 'purchaseOrders' && renderPurchaseOrderOption(option)}
        {option.type === 'customers' && renderCustomerOption(option)}
        {option.type === 'addressBook' && renderAddressBookOption(option)}
        {option.type === 'carriers' && renderCarrierOption(option)}
      </li>
    </>
  );

  return (
    <div className="global-search">
      <div className={`expandSearch ${expandSearch ? 'open' : ''}`}>
        <SearchField
          typeahead
          label="Search..."
          name="global-search"
          menuItemComponent={renderSearchResults}
          onSearch={searchByQuery}
          onChange={handleSelection}
        />
      </div>
      <SvgIcon name="Search" color="$sw-primary" onClick={handleToggleSearch}>
        search
      </SvgIcon>
      {expandSearch && (
        <Modal
          show={expandSearch}
          title="Search"
          subHeaderComponent={
            <SearchField
              typeahead
              hideMenu
              label="Search..."
              maxResults={35}
              menuItemComponent={renderSearchResults}
              onSearch={searchByQuery}
              onChange={handleSelection}
            />
          }
          footerComponent={null}
          onClose={handleToggleSearch}
        >
          {!isSearching && results.length > 0 && (
            <ul role="menu">
              {results.map((result) => renderSearchResults({option: result, onClick: () => handleSelection(result)}))}
            </ul>
          )}
          {isSearching && <ShipwellLoader loading />}
        </Modal>
      )}
    </div>
  );
};

GlobalSearchField.defaultProps = {
  count: 5,
  sections: ['shipment', 'loadboard', 'purchaseOrders', 'carriers', 'products', 'customers', 'addressBook']
};

export default connect((state) => ({
  company: state.auth.company
}))(GlobalSearchField);
