import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';

const RoutingGuideActionSummary = ({values, index, capacityAvailable, showCarrierCapacityError}) => {
  const actionType = values?.[index]?.type?.label ?? null;
  const tenderToCompany =
    values?.[index]?.involved_tender_to_company_users?.length > 0
      ? values[index].involved_tender_to_company_users[0].carrierName
      : null;
  const subject = values?.[index]?.subject;
  const bookNowRate = values?.[index]?.buy_it_now_amount
    ? formatCurrency(values[index].buy_it_now_amount, values[index].buy_it_now_amount_currency)
    : null;
  const numCarriers = values?.[index]?.carriers ? uniqBy(values[index].carriers, 'carrier').length : null;
  return (
    <span className="routingGuides__form-action-header">
      <span>
        {`Step ${index + 1} ${actionType ? ` - ${actionType}` : ``} ${tenderToCompany ? ` - ${tenderToCompany}` : ``} ${
          subject ? ` - ${subject.replace(/(.{25})..+/, '$1…')}` : ''
        } ${numCarriers ? ` - ${numCarriers} Carrier${numCarriers > 1 ? 's' : ''}` : ''} ${
          bookNowRate ? ` - ${bookNowRate}` : ''
        }`}
      </span>
      {!capacityAvailable ? (
        <Tooltip wrapperClassname="ml-1" tooltipContent="Carrier Capacity Exceeded">
          <SvgIcon name="ErrorOutlined" color={showCarrierCapacityError ? 'sw-error' : 'sw-warning'} />
        </Tooltip>
      ) : null}
    </span>
  );
};

RoutingGuideActionSummary.propTypes = {
  index: PropTypes.number,
  values: PropTypes.array,
  capacityAvailable: PropTypes.bool,
  showCarrierCapacityError: PropTypes.bool
};
RoutingGuideActionSummary.defaultProps = {
  capacityAvailable: true,
  showCarrierCapacityError: false
};

export default RoutingGuideActionSummary;
