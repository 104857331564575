import {Component} from 'react';
import {connect} from 'react-redux';
import {getLookerDashboards} from 'App/actions/integrations';
import './styles.scss';

class AnalyticsDashboard extends Component {
  constructor() {
    super();

    this.state = {
      menuOpen: false,
      defaultDashboard: null
    };
  }

  componentDidMount() {
    const {permissions} = this.props;
    const analyticsViewPermission = permissions && permissions.includes('analytics.view');

    if (permissions && !analyticsViewPermission) {
      this.props.router.push('/');
    } else if (analyticsViewPermission) {
      this.getLookerDashboards();
    }
  }

  componentDidUpdate(prevProps) {
    const {permissions} = this.props;
    const analyticsViewPermission = permissions && permissions.includes('analytics.view');

    if (permissions && !analyticsViewPermission) {
      this.props.router.push('/');
    } else if (analyticsViewPermission && permissions !== prevProps.permissions) {
      this.getLookerDashboards();
    }
  }

  async getLookerDashboards() {
    try {
      const response = await getLookerDashboards();

      if (response && response.managed_content) {
        let dashboardOptions = new Set();

        if (response) {
          for (const section in response) {
            if (response[section].dashboards) {
              dashboardOptions = new Set([...response[section].dashboards]);
            }
          }
        }

        this.setState({
          defaultDashboard: [...dashboardOptions][0]
        });

        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleOptionSelect(option) {
    this.setState({selectedOption: option});
    this.handleToggle();
  }

  handleToggle() {
    this.setState((state) => ({menuOpen: !state.menuOpen}));
  }

  render() {
    const {defaultDashboard} = this.state;
    const {permissions} = this.props;
    const analyticsViewPermission = permissions && permissions.includes('analytics.view');

    return (
      <div className="content-wrapper analytics-container">
        {analyticsViewPermission && defaultDashboard && <iframe src={defaultDashboard.embed_url} />}
      </div>
    );
  }
}

export default connect((state) => ({
  permissions: state.auth.user && state.auth.user.permissions
}))(AnalyticsDashboard);
