import PropTypes from 'prop-types';
import {Field, Formik, Form} from 'formik';
import {FormikTextInput, Modal, Title} from '@shipwell/shipwell-ui';
import pick from 'lodash/pick';
import {referenceTypes} from 'App/containers/shipments/utils/constants';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import Loader from 'App/common/shipwellLoader';
import {useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import {transformIdentificationCodeScac} from 'App/containers/shipments/utils/typed';

export const ReferencesModal = ({showModal, shipmentId, onClose}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const {updateShipmentReferencesMutation} = useCreateOrUpdateShipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }
  const {shipmentInfoFormValues, modeSpecificDisplayValues} = shipmentQuery.context;

  const initialValues = pick(
    shipmentInfoFormValues,
    'customer_reference_number',
    'po_number',
    'bol_number',
    'house_bol_number',
    'booking_number',
    'scac'
  );

  const {isImport} = modeSpecificDisplayValues;

  const handleSubmit = (values) => {
    const {
      booking_number: bookingNumber,
      bol_number: bolNumber,
      house_bol_number: houseBolNumber,
      po_number: poNumber,
      customer_reference_number: customerReferenceNumber,
      identification_codes: identificationCodes
    } = values;

    updateShipmentReferencesMutation.mutate({
      shipmentId,
      data: {
        references: [
          bookingNumber ? {qualifier: referenceTypes.BOOKING_NUMBER, value: bookingNumber || undefined} : null,
          bolNumber ? {qualifier: referenceTypes.BOL_NUMBER, value: bolNumber || undefined} : null,
          houseBolNumber ? {qualifier: referenceTypes.HOUSE_BOL_NUMBER, value: houseBolNumber || undefined} : null,
          poNumber ? {qualifier: referenceTypes.PO_NUMBER, value: poNumber || undefined} : null,
          customerReferenceNumber
            ? {qualifier: referenceTypes.CUSTOMER_REFERENCE_NUMBER, value: customerReferenceNumber || undefined}
            : null,
          transformIdentificationCodeScac(identificationCodes)
        ].filter((reference) => !!reference)
      }
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} title="References" show={showModal} footerComponent={null}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <Title className="pb-2" variant="sectionTitle">
            Customer
          </Title>
          <Field
            className="mb-4 border-b border-sw-border pb-4"
            name="customer_reference_number"
            label="Customer Reference #"
            component={FormikTextInput}
          />
          <Title className="pb-2" variant="sectionTitle">
            Reference Numbers
          </Title>
          <div className="mb-10 grid grid-cols-2 gap-4">
            {isImport ? (
              <>
                <Field name="bol_number" label="BOL #" component={FormikTextInput} />
                <Field name="house_bol_number" label="House BOL #" component={FormikTextInput} />
              </>
            ) : (
              <Field name="booking_number" label="Booking #" component={FormikTextInput} />
            )}
            <Field name="po_number" label="PO #" component={FormikTextInput} />
          </div>
          <ModalFormFooter onCancel={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};

ReferencesModal.propTypes = {
  showModal: PropTypes.bool,
  shipmentId: PropTypes.string,
  onClose: PropTypes.func
};

ReferencesModal.defaultProps = {
  showModal: false,
  onClose: () => {}
};

const ReferenceFields = () => {
  return (
    <div className="mb-4 grid gap-4">
      <Field name="name" label="Shipment Name" component={FormikTextInput} />
      <Field name="customer_reference_number" label="Customer Reference #" component={FormikTextInput} />
      <Field name="po_number" label="PO #" component={FormikTextInput} />
    </div>
  );
};

export default ReferenceFields;
