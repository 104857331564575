import axios from 'axios';
import {getShipwellApi, basePath} from 'App/api/config';
import {apiCallback, getAccessToken} from 'App/api/utils';
import {SOURCES} from 'App/utils/createdBy';

/**
 * Company - API
 */
export async function getCompanyDetailsPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdGet(companyId, apiCallback(resolve, reject));
  });
}

export async function createCompany(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesPost(body, apiCallback(resolve, reject));
  });
}

export async function editCompanyProfilePromise(companyId, company) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdPut(companyId, company, apiCallback(resolve, reject));
  });
}

/**
 * Company Users - API
 * @deprecated Use `import('App/api/users/typed').getCompanyUsers` instead
 */
export async function getCompanyUsersPromise(companyId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdUsersGet(companyId, opts, apiCallback(resolve, reject));
  });
}

export async function getCompanyUserPromise(companyId, userId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdUsersUserIdGet(companyId, userId, apiCallback(resolve, reject));
  });
}

export const getCreatorFilterOptionsPromise = async (companyId, opts, tableType) => {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdUsersGet(companyId, opts, (error, response) => {
      if (error) {
        console.error(error);
        reject(error);
      } else {
        const sourcesToInclude =
          tableType === 'ORDERS'
            ? Object.keys(SOURCES).filter((source) => source === 'IMPORT' || source === 'TENDER')
            : Object.keys(SOURCES).filter((source) => source !== 'SHIPWELL_WEB');
        const formattedSources = sourcesToInclude
          .filter((source) => {
            // get sources that match query string
            return opts?.q && SOURCES[source].toLowerCase().includes(opts.q.toLowerCase());
          })
          .map((source) => {
            return {id: source, label: SOURCES[source]};
          });
        const formattedUsers = response.results.map((user) => {
          return {id: user.id, label: `${user.first_name} ${user.last_name}`};
        });
        // filter options should include matches in both users and sources
        resolve({results: [...formattedUsers, ...formattedSources]});
      }
    });
  });
};

export async function createCompanyUserPromise(companyId, body = {}) {
  const accessToken = await getAccessToken();

  return new Promise((resolve, reject) => {
    const transformRequest = (body) => {
      const formData = new FormData();
      const fields = Object.keys(body);

      fields.forEach((field) => {
        if (field === 'permissions') {
          body[field].forEach((permission) => {
            formData.append(field, permission);
          });
        } else if (field !== 'avatar') {
          formData.append(field, body[field]);
        }
      });

      return formData;
    };

    const request = axios.post(`${basePath}/companies/${companyId}/users/`, body, {
      transformRequest: [transformRequest],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    });

    request.then((response) => resolve(response)).catch((error) => reject(error.response.data));
  });
}

export async function editCompanyUserPromise(companyId, userId, body = {}) {
  const accessToken = await getAccessToken();

  return new Promise((resolve, reject) => {
    const transformRequest = (body) => {
      const formData = new FormData();
      const fields = Object.keys(body);

      fields.forEach((field) => {
        if (['permissions', 'default_shipment_tags'].includes(field)) {
          body[field].forEach((value) => {
            formData.append(field, value);
          });
        } else if (field !== 'avatar') {
          formData.append(field, body[field]);
          // append the image value only if the value exists
          // if no image value is sent, backend will set default avatar
        } else if (field === 'avatar' && body[field]) {
          formData.append(field, body[field]);
        }
      });
      return formData;
    };

    const request = axios.put(`${basePath}/companies/${companyId}/users/${userId}/`, body, {
      transformRequest: [transformRequest],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    });

    request.then((response) => resolve(response)).catch((error) => reject(error.response.data));
  });
}

export async function deleteCompanyUserPromise(companyId, userId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdUsersUserIdDelete(companyId, userId, apiCallback(resolve, reject));
  });
}

/**
 * Company Drivers - API
 */
export async function getCompanyDrivers(companyId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDriversGet(companyId, opts, apiCallback(resolve, reject));
  });
}

export async function getCompanyDriver(companyId, driverId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDriversDriverIdGet(companyId, driverId, apiCallback(resolve, reject));
  });
}

export async function createCompanyDriver(companyId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDriversPost(companyId, body, apiCallback(resolve, reject));
  });
}

export async function updateCompanyDriver(companyId, driverId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDriversDriverIdPut(companyId, driverId, body, apiCallback(resolve, reject));
  });
}

/**
 * Company Preferences - API
 */
export async function getCompanyPreferencesPromise(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdPreferencesGet(companyId, apiCallback(resolve, reject));
  });
}

export async function editCompanyPreferencesPromise(companyId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdPreferencesPut(companyId, body, apiCallback(resolve, reject));
  });
}

/**
 * Company Identifying Codes - API
 */
export async function fetchIdentifyingCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesIdentifyingCodesGet(apiCallback(resolve, reject));
  });
}
/**
 * Text Message Templates - API
 */
export async function fetchDriverTextMessageTemplates() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.automationDriverTextsMessageTemplatesGet(apiCallback(resolve, reject));
  });
}

export async function updateDriverTextMessageTemplates(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.automationDriverTextsMessageTemplatesPut(opts, apiCallback(resolve, reject));
  });
}

/**
 * Company Documents - API
 */

export async function createCompanyDocument(companyId, body = {}) {
  const accessToken = await getAccessToken();

  const transformRequest = (body) => {
    const formData = new FormData();
    const fields = Object.keys(body);

    fields.forEach((field) => {
      formData.append(field, body[field]);
    });

    return formData;
  };

  return axios.post(`${basePath}/companies/${companyId}/documents/`, body, {
    transformRequest: [transformRequest],
    headers: {
      authorization: accessToken,
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function getCompanyDocuments(companyId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDocumentsGet(companyId, opts, apiCallback(resolve, reject));
  });
}

export async function deleteCompanyDocument(companyId, documentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdDocumentsDocumentIdDelete(companyId, documentId, apiCallback(resolve, reject));
  });
}

export async function updateCompanyDocument(companyId, documentId, body = {}) {
  const accessToken = await getAccessToken();

  const transformRequest = (body) => {
    const formData = new FormData();
    const fields = Object.keys(body);

    fields.forEach((field) => {
      formData.append(field, body[field]);
    });

    return formData;
  };

  return axios.put(`${basePath}/companies/${companyId}/documents/${documentId}`, body, {
    transformRequest: [transformRequest],
    headers: {
      authorization: accessToken,
      'Content-Type': 'multipart/form-data'
    }
  });
}

/**
 * Company Custom Fields - API
 */
export async function getCompanyCustomFields(companyId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdCustomFieldsGet(companyId, {pageSize: 1000, ...opts}, apiCallback(resolve, reject));
  });
}

export async function createCompanyCustomField(companyId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdCustomFieldsPost(companyId, body, apiCallback(resolve, reject));
  });
}

export async function updateCompanyCustomField(companyId, customFieldId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdCustomFieldsCustomFieldIdPut(
      companyId,
      customFieldId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteCompanyCustomField(companyId, customFieldId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesCompanyIdCustomFieldsCustomFieldIdDelete(
      companyId,
      customFieldId,
      apiCallback(resolve, reject)
    );
  });
}
