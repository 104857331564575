import {Link} from 'react-router';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import Loader from 'App/common/shipwellLoader';
import StageCard from 'App/containers/shipments/components/DashboardSummary/StageCard';
import InlineLoading from 'App/containers/shipments/components/DashboardSummary/InlineLoading';
import SequenceNumber from 'App/containers/shipments/components/DashboardSummary/SequenceNumber';
import StageActions from 'App/containers/shipments/components/DashboardSummary/StageActions';
import {LegacyShipmentStatusPill} from 'App/utils/shipmentStatus';
import {getCustomerTotalFinancials, getVendorTotalFinancials} from 'App/utils/shipmentTableHelpers/typed';
import {formatCurrency} from 'App/utils/internationalConstants';
import {FinancialTenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/FinancialTenderingUserPermissionFallback';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';

function LegacyShipmentView({
  shipmentId,
  isActive,
  sequenceNumber,
  legacyShipmentId
}: {
  shipmentId?: string;
  isActive?: boolean;
  sequenceNumber?: number;
  legacyShipmentId: string;
}) {
  const {shipmentQuery, customerName, carrier, lane} = useLegacyShipment(legacyShipmentId);
  if (shipmentQuery.isLoading && !shipmentQuery.isFetching) {
    return (
      <StageCard isActive={isActive}>
        <Loader loading />
      </StageCard>
    );
  }

  return (
    <StageCard isActive={isActive}>
      <div className="flex flex-col gap-2 whitespace-normal">
        <div className="flex w-full items-center">
          <SequenceNumber isActive={isActive}>{sequenceNumber}</SequenceNumber>
          <div className="flex text-sm">
            <span className="mx-2 font-bold">
              {shipmentQuery.isLoading ? (
                <InlineLoading />
              ) : (
                <>
                  {shipmentQuery.data?.mode?.description} &middot; {shipmentQuery.data?.reference_id}
                </>
              )}
            </span>
            {shipmentQuery.isLoading ? (
              <InlineLoading />
            ) : (
              <LegacyShipmentStatusPill status={shipmentQuery.data?.state} />
            )}
          </div>
          <div className="ml-auto flex items-center">
            <StageActions
              shipmentId={shipmentId}
              resourceId={legacyShipmentId}
              linkTo={`/shipments/${legacyShipmentId}`}
            />
          </div>
        </div>
        <div className="flex flex-col gap-0 text-xs">
          <div>
            <span className="font-bold">Customer: </span> {customerName}
          </div>
          <div>
            <span className="font-bold">Carrier: </span>
            <>
              {carrier.id ? <Link to={`/carriers/${carrier.id}`}>{carrier.name}</Link> : carrier.name}
              {carrier.status ? <> ({carrier.status})</> : null}
            </>
          </div>
          <div>
            <span className="font-bold">Lane: </span>
            {lane.pickupLane && lane.deliveryLane ? (
              <>
                {lane.pickupLane}
                <> &rsaquo; </>
                {lane.deliveryLane}
              </>
            ) : (
              '--'
            )}
          </div>
          <div>
            <span className="font-bold">Dates: </span>
            {lane.pickupDate && lane.deliveryDate ? (
              <>
                {lane.pickupDate}
                <> &ndash; </>
                {lane.deliveryDate}
              </>
            ) : (
              '--'
            )}
          </div>
          <FinancialTenderingUserPermissionFallback permissions={[VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]}>
            <div>
              <span className="font-bold">Carrier Financials: </span>
              {shipmentQuery.data
                ? formatCurrency(
                    getVendorTotalFinancials(shipmentQuery.data).toFixed(2).toString(),
                    shipmentQuery.data.preferred_currency
                  )
                : '--'}
            </div>
            <div>
              <span className="font-bold">Customer Financials: </span>
              {shipmentQuery.data
                ? formatCurrency(
                    getCustomerTotalFinancials(shipmentQuery.data).toFixed(2).toString(),
                    shipmentQuery.data.preferred_currency
                  )
                : '--'}{' '}
            </div>
          </FinancialTenderingUserPermissionFallback>
        </div>
        <div className="text-xs">
          <Link target="_blank" to={`/shipments/${legacyShipmentId}`}>
            View Details
          </Link>
        </div>
      </div>
    </StageCard>
  );
}

export default LegacyShipmentView;
