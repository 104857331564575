export const DOC_TYPE_BILL_OF_LADING = 'bill-of-lading';
export const DOC_TYPE_SHIPPING_LABEL = 'shipping-label';
export const DOC_TYPE_RATE_CONFIRMATION = 'rate-confirmation';

export const templateConstants = {
  [DOC_TYPE_BILL_OF_LADING]: {
    readableName: 'BOL'
  },
  [DOC_TYPE_SHIPPING_LABEL]: {
    readableName: 'Shipping Label'
  },
  [DOC_TYPE_RATE_CONFIRMATION]: {
    readableName: 'Rate Confirmation'
  }
};

export const SYSTEM_GENERATED_UUID = '00000000-0000-0000-0000-000000000000';
