import {ReactNode} from 'react';

export const CreateSupplierStepsModalFooter = ({
  backButton,
  children
}: {
  backButton?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="flex gap-4">
      {backButton ? <div className="flex gap-4">{backButton}</div> : null}
      <div className="ml-auto flex gap-4">{children}</div>
    </div>
  );
};
