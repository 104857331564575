import {useFlags} from 'launchdarkly-react-client-sdk';
const purchaseOrderWithoutSupplierAccessCta = {
  label: 'Purchase Order',
  truncatedLabel: 'Purchase Order',
  value: '/purchase-orders/create-no-supplier-access',
  ariaLabel: 'select purchase order no supplier'
};
const purchaseOrderWithSupplierAccessCta = {
  label: 'Purchase Order - Supplier Access',
  truncatedLabel: 'Purchase Order Supplier Acc.',
  value: '/purchase-orders/create-supplier-access',
  ariaLabel: 'select purchase order supplier'
};
const salesOrderCta = {
  label: 'Sales Order',
  //this allows us to provide backwards compatibility for the nav menu, where
  //this item is called orders
  truncatedLabel: 'Order',
  value: '/purchase-orders/create',
  ariaLabel: 'select sales order'
};
/**
 * Get the appropriate order creation options.
 **/
export const useOrderOptions = (): {label: string; value: string; ariaLabel: string; truncatedLabel: string}[] => {
  const {stmSupplierPortal, stmV3OrdersTable} = useFlags();
  //if user has supplier portal and associated v3 table and order flags, show
  //both purchase order nad purchase order supplier access options.
  if (stmSupplierPortal) {
    return [purchaseOrderWithoutSupplierAccessCta, purchaseOrderWithSupplierAccessCta];
  }
  //if user has flag enabling access to both v3 orders and the table to access v3 orders,
  //but not supplier portal, show purchase order option.
  if (stmV3OrdersTable && !stmSupplierPortal) {
    return [purchaseOrderWithoutSupplierAccessCta];
  }
  //otherwise, show the legacy (v2) orders create option.
  return [salesOrderCta];
};
