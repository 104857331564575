import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {
  FacilityHoursOfOperation,
  CreateFacilityHoursOfOperation,
  FacilityHoliday,
  CreateFacilityHoliday,
  UpdateFacilityHoliday,
  Facility
} from '@shipwell/tempus-sdk';

import {computeFacilityHolidayUpdates, facilityHolidayToEntry, updateFetchedFacilityHolidays} from './holidayUtils';
import {toAppointmentRulesType, withAppointmentRules} from './facilityUtils';

import {useMutableFacility, useStandardHolidays} from './useFacilities';
import {FacilityHolidayEntry, FacilityHoursEntry, AppointmentRulesType} from 'App/data-hooks/facilities/types';
import {facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';

import {
  bulkUpdateHoursOfOperation,
  createFacilityHoliday,
  deleteFacilityHoliday,
  getFacilityHolidays,
  getFacilityHoursOfOperation,
  updateFacilityHoliday
} from 'App/api/facilities';
import {FACILITIES_HOLIDAYS_QUERY_KEY, FACILITIES_HOURS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import useConsistentReference from 'App/utils/hooks/useConsistentReference';
import {parseV3ApiError} from 'App/api/typedUtils';

export default function useMutableFacilityAttributes(
  facilityId: string,
  {
    onSuccess,
    onError
  }: {
    onError: (error: Error) => unknown;
    onSuccess?: () => unknown;
  }
): {
  isLoading: boolean;
  values: {
    facilityOperationCapacity: FacilityHoursEntry[];
    appointmentRules: AppointmentRulesType;
    holidayRules: FacilityHolidayEntry[];
  };
  error: Error | null;
  mutate: (values: {
    facilityOperationCapacity: FacilityHoursEntry[];
    appointmentRules: AppointmentRulesType;
    holidayRules: FacilityHolidayEntry[];
  }) => void;
  isMutating: boolean;
  facility?: Facility | null;
} {
  const callbacksRef = useRef([
    /* eslint-disable @typescript-eslint/no-unused-vars */
    [() => undefined, (_error: Error) => undefined],
    [() => undefined, (_error: Error) => undefined],
    [() => undefined, (_error: Error) => undefined]
    /* eslint-enable @typescript-eslint/no-unused-vars */
  ] as [() => void, (error: Error) => void][]);
  const hoursCallbacks = {
    onSuccess: useCallback(() => callbacksRef.current[0][0](), [callbacksRef]),
    onError: useCallback((error: Error) => callbacksRef.current[0][1](error), [callbacksRef])
  };
  const rulesCallbacks = {
    onSuccess: useCallback(() => callbacksRef.current[1][0](), [callbacksRef]),
    onError: useCallback((error: Error) => callbacksRef.current[1][1](error), [callbacksRef])
  };
  const holidaysCallbacks = {
    onSuccess: useCallback(() => callbacksRef.current[2][0](), [callbacksRef]),
    onError: useCallback((error: Error) => callbacksRef.current[2][1](error), [callbacksRef])
  };
  const hoursBag = useMutableFacilityHoursOfOperation(facilityId, hoursCallbacks);
  const rulesBag = useMutableFacilityAppointmentRules(facilityId, rulesCallbacks);
  const holidaysBag = useMutableFacilityHolidays(facilityId, holidaysCallbacks);
  const bags = [hoursBag, rulesBag, holidaysBag];

  const isLoading = bags.some((bag) => bag.isLoading);
  const isMutating = bags.some((bag) => bag.isMutating);

  const values = useConsistentReference({
    facilityOperationCapacity: hoursBag.hours,
    appointmentRules: rulesBag.appointmentRules ?? facilityFormDataDefaultValues.appointmentRules,
    holidayRules: holidaysBag.holidays
  });

  const error = bags.map((bag) => bag.error).find(Boolean) ?? null;

  const mutateHours = hoursBag.mutate;
  const mutateRules = rulesBag.mutate;
  const mutateHolidays = holidaysBag.mutate;

  const mutate = useCallback(
    (values: {
      facilityOperationCapacity: FacilityHoursEntry[];
      appointmentRules: AppointmentRulesType;
      holidayRules: FacilityHolidayEntry[];
    }) => {
      const promises: Promise<void>[] = [];
      for (let i = 0; i < 3; i++) {
        promises.push(
          new Promise<void>((resolve, reject) => {
            callbacksRef.current[i][0] = resolve;
            callbacksRef.current[i][1] = reject;
          })
        );
      }
      void Promise.all(promises).then(onSuccess, onError);
      mutateHours(values.facilityOperationCapacity);
      mutateRules(values.appointmentRules);
      mutateHolidays(values.holidayRules);
    },
    [callbacksRef, mutateHours, mutateRules, mutateHolidays, onError, onSuccess]
  );

  return {
    isLoading,
    isMutating,
    values,
    error,
    mutate,
    facility: rulesBag.facility ?? null
  };
}

function entriesToCreateHours(entries: FacilityHoursEntry[]): CreateFacilityHoursOfOperation[] {
  return entries.map(
    ({day, is_facility_open, open_time, close_time}: FacilityHoursEntry) =>
      ({
        day,
        is_facility_open,
        open_time,
        close_time
      } as CreateFacilityHoursOfOperation)
  );
}

export function useMutableFacilityHoursOfOperation(
  facilityId: string,
  {
    onError,
    onSuccess
  }: {
    onError: (error: Error) => unknown;
    onSuccess?: () => unknown;
  }
): {
  isLoading: boolean;
  hours: FacilityHoursEntry[];
  error: Error | null;
  mutate: (hours: FacilityHoursEntry[]) => void;
  isMutating: boolean;
} {
  const client = useQueryClient();
  const fetcher = useCallback(() => getFacilityHoursOfOperation(facilityId), [facilityId]);
  const query = useQuery<FacilityHoursOfOperation[], Error>([FACILITIES_HOURS_QUERY_KEY, facilityId], fetcher);
  const {isLoading: queryIsLoading, error, isFetched} = query;
  const {data: hoursData} = query as {data: FacilityHoursEntry[]};

  const fetchedHours = useConsistentReference(hoursData ?? []);
  const hoursRef = useRef<FacilityHoursEntry[]>(fetchedHours);

  const mutation = useMutation<FacilityHoursEntry[], Error, FacilityHoursEntry[]>({
    mutationFn: useCallback(
      async (updates: FacilityHoursEntry[]): Promise<FacilityHoursOfOperation[]> => {
        hoursRef.current = updates;
        client.setQueryData([FACILITIES_HOURS_QUERY_KEY, facilityId], updates);
        const response = await bulkUpdateHoursOfOperation(facilityId, {
          hours_of_operation: entriesToCreateHours(updates)
        });

        const {total_count} = response.data;
        let {data: hours} = response.data;
        if (hours.length < total_count) {
          hours = await getFacilityHoursOfOperation(facilityId);
        }
        hoursRef.current = hours;
        client.setQueryData([FACILITIES_HOURS_QUERY_KEY, facilityId], hours);
        return hours;
      },
      [facilityId, client]
    ),

    onError: useCallback(
      (error: Error) => {
        void client.invalidateQueries([FACILITIES_HOURS_QUERY_KEY, facilityId]);
        onError(error);
      },
      [client, facilityId, onError]
    ),

    onSuccess: useCallback(() => {
      if (onSuccess) onSuccess();
    }, [onSuccess]),

    retry: useCallback((failureCount: number, error: Error) => {
      if (failureCount >= 3) {
        return false;
      }
      const {is4xx} = parseV3ApiError(error);
      return !is4xx;
    }, [])
  });

  const {mutate} = mutation;
  const isMutating = mutation.status === 'loading';

  if (!isMutating && isFetched && hoursRef.current !== fetchedHours) {
    hoursRef.current = fetchedHours;
  }

  const isLoading = queryIsLoading;

  return {isLoading, hours: hoursRef.current, error, mutate, isMutating};
}

export function useMutableFacilityAppointmentRules(
  facilityId: string,
  {onError, onSuccess}: {onError: (error: Error) => unknown; onSuccess?: () => unknown}
): {
  isLoading: boolean;
  appointmentRules?: AppointmentRulesType;
  error: Error | null;
  mutate: (appointmentRules: AppointmentRulesType) => void;
  isMutating: boolean;
  facility?: Facility | null;
} {
  const {isLoading, facility, updateFacility, error, isMutating} = useMutableFacility(facilityId, {onError, onSuccess});
  const appointmentRules = useConsistentReference(facility ? toAppointmentRulesType(facility) : undefined);

  const mutate = useCallback(
    (rules: AppointmentRulesType) => {
      if (!facility) return;
      updateFacility(withAppointmentRules(facility, rules));
    },
    [facility, updateFacility]
  );
  return {
    isLoading,
    appointmentRules,
    mutate,
    error,
    isMutating,
    facility
  };
}

async function executeFacilityHolidaysUpdates(
  facilityId: string,
  {
    toCreate,
    toUpdate,
    toDelete
  }: {
    toCreate: CreateFacilityHoliday[];
    toUpdate: {id: string; update: UpdateFacilityHoliday}[];
    toDelete: string[];
  }
): Promise<{
  created: FacilityHoliday[];
  updated: FacilityHoliday[];
}> {
  const pCreated = Promise.all(toCreate.map((body) => createFacilityHoliday(facilityId, body)));
  const pUpdated = Promise.all(toUpdate.map(({id, update}) => updateFacilityHoliday(facilityId, id, update)));
  const pDeleted = Promise.all(toDelete.map((id) => deleteFacilityHoliday(facilityId, id)));
  const [created, updated] = await Promise.all([pCreated, pUpdated, pDeleted]);
  return {created, updated};
}

export function useMutableFacilityHolidays(
  facilityId: string,
  {
    onError,
    onSuccess
  }: {
    onError: (error: Error) => unknown;
    onSuccess?: () => unknown;
  }
): {
  isLoading: boolean;
  holidays: FacilityHolidayEntry[];
  error: Error | null;
  mutate: (hours: FacilityHolidayEntry[]) => void;
  isMutating: boolean;
} {
  const client = useQueryClient();

  let {isLoading: standardsAreLoading, holidays: options} = useStandardHolidays();
  if (!options) options = [];
  if (!options.length) standardsAreLoading = true;

  const fetcher = useCallback(() => {
    return getFacilityHolidays(facilityId);
  }, [facilityId]);
  const query = useQuery<FacilityHoliday[], Error>([FACILITIES_HOLIDAYS_QUERY_KEY, facilityId], fetcher);
  const {isLoading: queryIsLoading, error, isFetched} = query;
  let {data: holidaysData} = query;
  holidaysData = holidaysData?.filter(
    (h) => h.standard_holiday_name || (h.custom_holiday_name && h.custom_holiday_date)
  );

  const fetchedHolidays = useConsistentReference(holidaysData ?? []);
  const toEntry = useMemo(() => facilityHolidayToEntry(options), [options]);

  const holidaysRef = useRef<{
    fetched: FacilityHoliday[];
    entries: FacilityHolidayEntry[];
  }>({
    fetched: fetchedHolidays,
    entries: fetchedHolidays.map(toEntry)
  });

  useEffect(() => {
    holidaysRef.current.entries = holidaysRef.current.fetched.map(toEntry);
  }, [toEntry]);

  const mutation = useMutation<FacilityHolidayEntry[], Error, FacilityHolidayEntry[]>({
    mutationFn: useCallback(
      async (updates: FacilityHolidayEntry[]): Promise<FacilityHolidayEntry[]> => {
        const updateOps = computeFacilityHolidayUpdates(updates, fetchedHolidays);
        const {created, updated} = await executeFacilityHolidaysUpdates(facilityId, updateOps);
        const holidays = updateFetchedFacilityHolidays(fetchedHolidays, created, updated, updateOps.toDelete);
        client.setQueryData([FACILITIES_HOLIDAYS_QUERY_KEY, facilityId], holidays);
        holidaysRef.current = {
          fetched: holidays,
          entries: holidays.map(toEntry)
        };
        return holidaysRef.current.entries;
      },
      [facilityId, fetchedHolidays, client, toEntry]
    ),
    onError: useCallback(
      (error: Error) => {
        void client.invalidateQueries([FACILITIES_HOLIDAYS_QUERY_KEY, facilityId]);
        onError(error);
      },
      [client, facilityId, onError]
    ),

    onSuccess: useCallback(() => {
      if (onSuccess) onSuccess();
    }, [onSuccess]),

    retry: useCallback((failureCount: number, error: Error) => {
      if (failureCount >= 3) {
        return false;
      }
      const {is4xx} = parseV3ApiError(error);
      return !is4xx;
    }, [])
  });

  const {mutate} = mutation;
  const isMutating = mutation.status === 'loading';

  if (
    !isMutating &&
    !standardsAreLoading &&
    !queryIsLoading &&
    isFetched &&
    holidaysRef.current.fetched !== fetchedHolidays
  ) {
    holidaysRef.current.fetched = fetchedHolidays;
    holidaysRef.current.entries = fetchedHolidays.map(toEntry);
  }
  const everLoadedState = useState(false);
  let everLoaded = everLoadedState[0];
  const setEverLoaded = everLoadedState[1];

  const isLoading = queryIsLoading || standardsAreLoading || holidaysRef.current.fetched !== fetchedHolidays;
  if (!isLoading && !everLoaded) {
    setEverLoaded(true);
    everLoaded = true;
  }

  return {
    isLoading: isLoading || !everLoaded,
    holidays: everLoaded ? holidaysRef.current.entries : [],
    error,
    mutate,
    isMutating
  };
}
