import {useState, ReactNode} from 'react';
import {flexRender} from '@tanstack/react-table';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {
  Company,
  FeatureFlags,
  Shipment,
  SpotNegotiation,
  Quote,
  PaginatedSpotNegotiation
} from '@shipwell/backend-core-singlerequestparam-sdk';
import classnames from 'classnames';
import {getColumns} from './marketPlaceBidsColumns';
import {QuoteDocumentModal} from 'App/containers/LoadBoard/components/QuoteDocument';
import {
  TableContainer,
  Table,
  TableHeaderRow,
  TableHeader,
  TableHeaderSortIcon,
  TableCell
} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {useUserMe} from 'App/data-hooks';
import {SHIPMENT_CREATE_SPOT_NEGOTIATIONS} from 'App/components/permissions/PermissionsFallback/constants';

export const MarketplaceBids = ({
  selectedShipment,
  shipmentSpotNegotiations,
  handleSelect,
  handleSelectSpotNegotiation,
  featureFlags = {},
  company,
  compactView = false
}: {
  selectedShipment?: Shipment;
  shipmentSpotNegotiations?: PaginatedSpotNegotiation;
  handleSelect: (quote: Quote) => void;
  handleSelectSpotNegotiation: (spotNegotiation: SpotNegotiation) => void;
  featureFlags?: FeatureFlags;
  company: Company;
  compactView?: boolean;
}) => {
  const [documentPreviewQuote, setDocumentPreviewQuote] = useState<Quote | null>(null);
  const {data: userData} = useUserMe();
  const {krknTenderingUserPermissions} = useFlags();
  const userPermissions = userData?.user?.permissions || [];
  const canTakeActionOnBid = krknTenderingUserPermissions
    ? userPermissions.includes(SHIPMENT_CREATE_SPOT_NEGOTIATIONS)
    : true;

  const loadBoardEnabled = selectedShipment?.metadata?.load_board_enabled || null;

  const spotNegsToShow =
    shipmentSpotNegotiations?.results &&
    shipmentSpotNegotiations?.results?.length > 0 &&
    shipmentSpotNegotiations?.results.filter((e) => e.vendor?.id !== company.id);

  const table = useTypedTable({
    data: spotNegsToShow || [],
    columns: getColumns({
      handleSelect,
      handleSelectSpotNegotiation,
      setDocumentPreviewQuote,
      compactView,
      canTakeActionOnBid
    }),
    initialState: {
      sorting: [
        {
          id: 'bid',
          desc: false
        }
      ]
    },
    enableSortingRemoval: false
  });

  return (
    <div className="marketplace__bids">
      <QuoteDocumentModal
        spotNegotiationId={documentPreviewQuote?.spot_negotiation_quote?.spot_negotiation}
        spotNegotiationQuoteId={documentPreviewQuote?.id}
        spotNegotiationQuoteDocumentId={documentPreviewQuote?.spot_negotiation_quote?.documents?.[0]?.id}
        onClose={() => setDocumentPreviewQuote(null)}
        shipperView
      />
      {spotNegsToShow && spotNegsToShow.length ? (
        <TableContainer>
          <Table
            head={table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    width={header.getSize()}
                    sortDirection={header.column.getIsSorted()}
                    onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                    onResize={header.getResizeHandler()}
                  >
                    <div className="flex items-center gap-1">
                      {header.column.getCanSort() ? (
                        <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                      ) : null}
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
            body={table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} isAwarded={row.original.is_most_recent_winner}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          />
        </TableContainer>
      ) : (
        <div className="marketplace__bids-noBids text-center">
          {loadBoardEnabled ? (
            <h3>No bids on this shipment.</h3>
          ) : (
            <>
              <h3>No bids on this shipment.</h3>
              <p>
                {featureFlags?.load_board_enabled
                  ? 'Add to your load board or request bids from carriers.'
                  : 'Request bids from your carriers.'}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

function TableRow({children, isAwarded = false}: {children?: ReactNode; isAwarded?: boolean}) {
  return (
    <tr
      className={classnames('text-sw-text hover:outline hover:outline-sw-primary', {
        'bg-[#8bc34a26]': isAwarded,
        'bg-sw-background-component odd:bg-sw-background': !isAwarded
      })}
    >
      {children}
    </tr>
  );
}
