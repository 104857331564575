import isEmpty from 'lodash/isEmpty';
import {useQuery} from '@tanstack/react-query';
import {connect} from 'react-redux';
import uniq from 'lodash/uniq';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {Company} from '@shipwell/backend-core-sdk';
import TimelineIcon from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineIcon';
import TimelineCard from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineCard';
import {mapEventTypeWithIcon} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils';
import {getLegTimeline} from 'App/api/corrogo/typed';
import Loader from 'App/common/shipwellLoader';
import {useUserDetails} from 'App/data-hooks';
import type {State} from 'App/reducers/types';
import {LEG_TIMELINE} from 'App/data-hooks/queryKeys';
import type {CreateEventValidation} from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import type {ShipmentTimelineSummaryEvent} from 'App/api/corrogo/typed';

function useLegTimeline(legId: string, companyId: string) {
  const legTimelineQuery = useQuery(
    [LEG_TIMELINE, legId],
    async () => {
      if (!legId) {
        return Promise.reject(new Error('Invalid leg id'));
      }
      const response = await getLegTimeline(legId);
      return response.data.data;
    },
    {
      enabled: !!legId
    }
  );

  const userIds = uniq(
    legTimelineQuery.data?.reduce<string[]>((userIds, event) => {
      return [...userIds, event.created_by_user_id || ''].filter((userId) => !!userId);
    }, []) || []
  );

  const userDetails = useUserDetails(companyId, userIds);

  const legTimeline = legTimelineQuery.data?.map((event) => ({
    ...event,
    actor: userDetails[event.created_by_user_id || '']
  }));

  return {legTimeline, queryInfo: legTimelineQuery};
}

function LegTimeline({
  legId,
  toggleShowEventModal,
  company,
  onDeleteEvent
}: {
  legId: string;
  toggleShowEventModal(): void;
  onUpdateEvent?(event: ShipmentTimelineSummaryEvent, values: CreateEventValidation): void;
  onDeleteEvent?(event: ShipmentTimelineSummaryEvent): void;
} & {company: Partial<Company>}) {
  const {legTimeline, queryInfo} = useLegTimeline(legId, company.id ?? '');
  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }
  if (isEmpty(legTimeline)) {
    return (
      <div className="flex h-5/6 flex-col items-center justify-center">
        <div className="mb-1">No Timeline Events</div>
        <div className="flex cursor-pointer items-center text-sw-primary" onClick={toggleShowEventModal}>
          <SvgIcon name="AddCircleOutlined" />
          Add Event
        </div>
      </div>
    );
  }

  return (
    <>
      {legTimeline?.map((event, eventIndex) => (
        <div key={event.id} className="flex">
          <TimelineIcon
            iconToShow={mapEventTypeWithIcon(event.event_type)}
            isLineHidden={legTimeline?.length !== undefined && eventIndex === legTimeline?.length - 1}
          />
          <TimelineCard timelineEvents={[event]} onDeleteEvent={onDeleteEvent} />
        </div>
      ))}
    </>
  );
}

export default connect((state: State) => ({company: state.userCompany.company}))(LegTimeline);
