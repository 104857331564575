import {useQuery, UseQueryOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getFullShipmentDetails} from 'App/api/shipment/typed';
import {FULL_SHIPMENT_DETAILS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

const getShipment = async (shipmentId?: string | null) => {
  if (!shipmentId) {
    return Promise.reject('Invalid Shipment ID');
  }
  const response = await getFullShipmentDetails(shipmentId);
  return response?.data;
};

export const useGetFullShipmentDetails = (
  shipmentId?: string | null,
  queryOptions?: UseQueryOptions<Shipment, AxiosError, Shipment, string[]>
) => {
  const queryClient = useQueryClient();
  return {
    queryInfo: useQuery([FULL_SHIPMENT_DETAILS_QUERY_KEY, shipmentId || ''], () => getShipment(shipmentId), {
      enabled: !!shipmentId,
      ...queryOptions
    }),
    invalidate: async (id: string) => {
      await queryClient.invalidateQueries([FULL_SHIPMENT_DETAILS_QUERY_KEY, id]);
    },
    prefetch: () =>
      queryClient.prefetchQuery({
        queryKey: [FULL_SHIPMENT_DETAILS_QUERY_KEY, shipmentId],
        queryFn: () => getShipment(shipmentId),
        staleTime: HOT_DATA_STALE_TIME // only prefetch if data is older than 3 seconds
      })
  };
};
