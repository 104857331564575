import {
  Address,
  Contract,
  ContractPerHourStartFieldEnum,
  ContractPerHourEndFieldEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CorrectedServiceLevelAgreement} from 'App/api/serviceLevelAgreements/typed';
import {CorrectedAccessorialChargeTable, CorrectedFuelSurchargeTable} from 'App/api/rateTables/typed';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';

export type ContractFormValues = Partial<
  Omit<
    Contract,
    | 'additional_stops'
    | 'service_level_agreement'
    | 'fuel_surcharge_table'
    | 'per_hour_start_field'
    | 'per_hour_end_field'
    | 'accessorial_charge_table'
    | 'default_accessorials'
  > & {
    additional_stops?: Array<Address & {order_index: number; additional_stop_charge?: number | null}>;
    service_level_agreement?: string | CorrectedServiceLevelAgreement;
    fuel_surcharge_table?: string | CorrectedFuelSurchargeTable;
    accessorial_charge_table?: string | CorrectedAccessorialChargeTable;
    per_hour_start_field?: ContractPerHourStartFieldEnum | null;
    per_hour_end_field?: ContractPerHourEndFieldEnum | null;
    defaultAccessorials: Array<{
      accessorial: {
        code: string;
        type: string;
        description: string;
      };
      rate: string;
      description: string;
    }>;
  }
>;

export const contractsModeOptionsWhiteList = [
  ShipmentModeEnum.FTL,
  ShipmentModeEnum.DRAYAGE,
  ShipmentModeEnum.INTERMODAL,
  ShipmentModeEnum.LTL
];

export const contractsTooltips = {
  ADDITIONAL_STOP_CHARGE_PER_MILE: (
    <span>Additional stop rate per mile is used when you have additional stop charges.</span>
  ),
  SERVICE_LEVEL_AGREEMENT: (
    <span>
      Service Level Agreements are linked to contracts to communicate your service level requirements. Add SLAs in the
      SLA section to link them here.
    </span>
  ),
  DOCUMENTS: <span>Documents that have been uploaded to the Company &gt; Profile will display in this list.</span>,
  DISTANCE_SYSTEM: (
    <span>Your distance system and version is used to communicate the system you use to calculate mileage.</span>
  )
};
