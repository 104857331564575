import {
  ItemConsolidationLineItems,
  ItemConsolidationDeleteLineItems,
  ItemConsolidationAllocatePiecesWeightUnitEnum,
  ItemConsolidation,
  ShipmentLineItemDetail,
  ItemConsolidationUnallocatePieces
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AssembleHandlingUnitsFormValues} from 'App/containers/Shipment/components/ShipmentItems/AssembleHandlingUnits/utils';

export const createConsolidatedLineItems = (
  values: AssembleHandlingUnitsFormValues,
  originalShipmentLineItems: ShipmentLineItemDetail[]
): ItemConsolidation => {
  const allHandlingUnits = values.stops
    .map((stop) => {
      return stop.handling_units.map((handlingUnit) => ({
        ...handlingUnit,
        origin_stop: stop.pickup.id,
        destination_stop: stop.dropoff.id
      }));
    })
    .flat();

  const lineItems: ItemConsolidationLineItems[] = allHandlingUnits.map((handlingUnit) => {
    const originalHandlingUnit = originalShipmentLineItems.find((lineItem) => lineItem.id === handlingUnit.id);
    const allPieces: ItemConsolidationLineItems['allocate_pieces'] =
      handlingUnit.pieces?.map((piece) => {
        const packageWeight = () => {
          if (!piece.package_weight || !piece.total_pieces) {
            return 0;
          }
          return Math.min(piece.package_weight, (piece.weight || 0) * piece.total_pieces);
        };
        return {
          original_shipment_line_item_id: piece.id || '',
          package_weight: packageWeight(),
          total_pieces: (piece.total_pieces || 0).toString(),
          weight_unit: piece.weight_unit || ItemConsolidationAllocatePiecesWeightUnitEnum.Lb
        };
      }) || [];

    // unallocated if piece id is not found in handling unit
    const unallocatedPieces: ItemConsolidationUnallocatePieces[] =
      originalHandlingUnit?.pieces
        ?.filter((piece) => {
          return allPieces.every((p) => p.original_shipment_line_item_id !== piece.id);
        })
        .map((piece) => ({id: piece.id || ''})) || [];

    let allocatedPieces: ItemConsolidationLineItems['allocate_pieces'] = allPieces.filter((piece) => {
      return unallocatedPieces.every((p) => p.id !== piece.original_shipment_line_item_id);
    });

    allocatedPieces = allocatedPieces?.filter((piece) => {
      //remove any pieces that were previously allocated to this handling unit, since we don't have the true original_shipment_line_item_id
      return !originalHandlingUnit?.pieces?.find(
        (handlingUnitPiece) => handlingUnitPiece.id === piece.original_shipment_line_item_id
      );
    });

    // the "TEMP-" string is only present on handling units made in the UI
    // if this test returns false, we know we have an ID from the BE
    const isTempId = /^TEMP-/.test(handlingUnit.id);

    return {
      ...(allocatedPieces.length ? {allocate_pieces: allocatedPieces} : {}),
      ...(!isTempId ? {id: handlingUnit.id} : {}),
      destination_stop: handlingUnit.destination_stop,
      // the BE would raher not have this key than it to be an empty string
      ...(handlingUnit.freight_class ? {freight_class: handlingUnit.freight_class} : {}),
      height: handlingUnit.height,
      length: handlingUnit.length,
      length_unit: handlingUnit.length_unit,
      origin_stop: handlingUnit.origin_stop,
      package_type: handlingUnit.package_type,
      package_weight: handlingUnit.package_weight,
      ...(unallocatedPieces.length ? {unallocate_pieces: unallocatedPieces} : {}),
      weight_unit: handlingUnit.weight_unit,
      width: handlingUnit.width
    };
  });

  // deleted line items are line items with pieces in the originalShipmentLineItems whose ids no longer exist in the handlingUnits list
  const deletedLineItems: ItemConsolidationDeleteLineItems[] = originalShipmentLineItems
    .filter((lineItem) => {
      return lineItem.pieces?.length && allHandlingUnits.every((handlingUnit) => handlingUnit.id !== lineItem.id);
    })
    .map((lineItem) => ({id: lineItem.id}));

  return {
    delete_line_items: deletedLineItems,
    line_items: lineItems
  };
};
