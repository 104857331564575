import get from 'lodash/get';
import moment from 'moment-timezone';
import {createStopEvent, createStopAlert} from 'App/api/shipment';

export const DISPATCH_CARRIER = 'dispatch-carrier';
export const MARK_AS_ARRIVED = 'mark-as-arrived';
export const MARK_AS_DEPARTED = 'mark-as-departed';
export const MARK_AS_COMPLETE = 'mark-as-complete';

/**
 * Make a function that, when invoked, will create an event of either of the given types, any given
 * additional events, and any late flags if necessary. The returned function takes a shipment, a
 * stop on that shipment, and the values from an event creation form, which include the time the
 * event occurred and a reason code. Which event type is used is determined by the presence of a
 * reason code in the provided values.
 *
 * @param {String} eventType The event type to create if the event is on time
 * @param {String} lateEventType The event type to create if the event is late, which is determined
 * by the presence of a reason code
 * @param {Array.<Function>} additionalEventCreators A list of functions that return promises. These
 * promises should create any additional events, e.g. an en route event for a subsequent stop.
 *
 * @return {Function}
 */
function makeEventCreator({eventType, lateEventType, additionalEventCreators = []}) {
  return async function (shipment, stop, values) {
    const reason = get(values, 'stop_event_reason_code');
    const isLate = reason !== '';
    const event = {
      ...values,
      stop_event_type: isLate && lateEventType ? lateEventType : eventType,
      stop_event_reason_code: isLate ? reason : 'NORMAL_STATUS'
    };
    // Create the main event first, then any provided additional events, then any flags last.
    // Events are created serially because order matters and the `created_at` timestamp is set when
    // a request is _completed_. Sending a flag request after an event request does not guarantee
    // that the flag will be created after the event, so we need to wait for the initial event, any
    // additional events, and then finally the flag.
    await createStopEvent(shipment.id, stop.id, event);
    await Promise.all(additionalEventCreators.map((eventCreator) => eventCreator()));
    if (isLate) {
      const flag = {
        alert_type: 'LATE',
        reason_code: reason,
        occurred_at: values.occurred_at
      };
      await createStopAlert(shipment.id, stop.id, flag);
    }
  };
}

export const dispatchCarrierEvent = {
  id: DISPATCH_CARRIER,
  label: 'Dispatch Carrier',
  createEvents: makeEventCreator({eventType: 'EN_ROUTE_TO_STOP'})
};

export const markAsArrivedEvent = {
  id: MARK_AS_ARRIVED,
  label: 'Mark As Arrived',
  createEvents: makeEventCreator({eventType: 'ARRIVED_AT_STOP', lateEventType: 'ARRIVED_AT_STOP_LATE'})
};

// mark as departed also creates an event to signify en route to the next stop
// it creates this event first but since they both occur at the same time that doesn't end up
// mattering in terms of user experience
export const markAsDepartedEvent = {
  id: MARK_AS_DEPARTED,
  label: 'Mark As Departed',
  createEvents: (shipment, stop, values) => {
    const additionalEventCreators = [];
    const nextStop = shipment.stops.find((shipmentStop) => shipmentStop.ordinal_index === stop.ordinal_index + 1);
    if (nextStop) {
      const reason = get(values, 'stop_event_reason_code');
      const isLate = reason !== '';
      const enRouteEvent = {
        ...values,
        stop_event_type: 'EN_ROUTE_TO_STOP',
        stop_event_reason_code: isLate ? reason : 'NORMAL_STATUS'
      };
      additionalEventCreators.push(() => createStopEvent(shipment.id, nextStop.id, enRouteEvent));
    }
    const eventCreator = makeEventCreator({eventType: 'DEPARTED_STOP', additionalEventCreators});
    return eventCreator(shipment, stop, values);
  }
};

// Mark As Complete is meant to be used for the last stop
// it creates the same event as Mark as Departed
// with the only difference being the label
export const markAsCompleteEvent = {
  ...markAsDepartedEvent,
  id: MARK_AS_COMPLETE,
  label: 'Mark As Complete'
};

const ARRIVAL_EVENT_EXCEPTIONS = ['CARRIER_SPOTTED_AT_STOP', 'ACTUAL_ARRIVED_AT_TIME_UPDATED'];
const DEPARTURE_EVENT_EXCEPTIONS = ['COMPLETED_TIME_UPDATED'];

/**
 * Get all events in the arrival group, but filter out the ids that are explicit exceptions.
 *
 * @param {Array} groupedStopEventTypes The grouped event types from the API
 *
 * @return {Array}
 */
export function getArrivalEvents(groupedStopEventTypes) {
  return get(
    groupedStopEventTypes.find((group) => group.label === 'Arrived'),
    'options',
    []
  ).filter((event) => ARRIVAL_EVENT_EXCEPTIONS.indexOf(event.id) === -1);
}

/**
 * Get all events in the departure group, but filter out the ids that are explicit exceptions.
 *
 * @param {Array} groupedStopEventTypes The grouped event types from the API
 *
 * @return {Array}
 */
export function getDepartureEvents(groupedStopEventTypes) {
  return get(
    groupedStopEventTypes.find((group) => group.label === 'Departed'),
    'options',
    []
  ).filter((event) => DEPARTURE_EVENT_EXCEPTIONS.indexOf(event.id) === -1);
}

/**
 * Returns true if the given event type is part of the arrival group
 *
 * @param {String} eventType The event type to check
 * @param {Array} groupedStopEventTypes The grouped event types from the API
 *
 * @return {Boolean}
 */
export function isArrivalEventType(eventType, groupedStopEventTypes) {
  return getArrivalEvents(groupedStopEventTypes).some((event) => event.id === eventType);
}

/**
 * Returns true if the given event type is part of the departure group
 *
 * @param {String} eventType The event type to check
 * @param {Array} groupedStopEventTypes The grouped event types from the API
 *
 * @return {Boolean}
 */
export function isDepartureEventType(eventType, groupedStopEventTypes) {
  return getDepartureEvents(groupedStopEventTypes).some((event) => event.id === eventType);
}

/**
 * Returns true if the given simple event object is an arrival.
 *
 * @param {Object} event
 *
 * @return {Boolean}
 */
export function isArrivalSimpleEvent(event) {
  return event.id === MARK_AS_ARRIVED;
}

/**
 * Returns true if the given simple event object is an arrival.
 *
 * @param {Object} event
 *
 * @return {Boolean}
 */
export function isDepartureSimpleEvent(event) {
  return event.id === MARK_AS_DEPARTED || event.id === MARK_AS_COMPLETE;
}

/**
 * Returns true if the given date string is the same or after the given stop's planned window end. Returns true
 * if there is no planned window end so we can use this to determine if a date is "late" for a stop.
 *
 * @param {String} date The date string to compare
 * @param {Object} stop The stop whose planned window end is being compared
 *
 * @return {Boolean}
 */
export function isDateAfterStopPlannedWindowEnd(date, stop) {
  // planned pickup after a specific time - which has no window end time - cannot be after the window end
  const plannedWindowTimeEnd = get(stop, 'planned_time_window_end');
  if (!plannedWindowTimeEnd) {
    return false;
  }
  const plannedWindowDate = get(stop, 'planned_date');
  const timezone = get(stop, 'location.address.timezone', moment.tz.guess());
  const plannedWindowDateTime = moment.tz(
    `${plannedWindowDate} ${plannedWindowTimeEnd}`,
    'YYYY-MM-DD HH:mm:ss',
    timezone
  );
  return plannedWindowDateTime.isValid() && moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').isAfter(plannedWindowDateTime);
}

/**
 * Returns amount of time that given date string is after the given stop's planned window end,
 * returns null if there is no planned window end or if the date is not after the planned window end
 *
 * @param {String} date The date string to compare
 * @param {Object} stop The stop whose planned window end is being compared
 * @param {Number} duration The duration to return the delay time in- e.g., minutes, hours
 * @return {Number}
 */
export function getPlannedWindowEndDelay(date, stop, duration = 'hours') {
  const plannedWindowTimeEnd = get(stop, 'planned_time_window_end');
  if (isDateAfterStopPlannedWindowEnd(date, stop) && plannedWindowTimeEnd) {
    const plannedWindowDate = get(stop, 'planned_date');
    const timezone = get(stop, 'location.address.timezone', moment.tz.guess());
    const plannedWindowDateTime = moment.tz(
      `${plannedWindowDate} ${plannedWindowTimeEnd}`,
      'YYYY-MM-DD HH:mm:ss',
      timezone
    );
    return (
      plannedWindowDateTime.isValid() &&
      moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').diff(plannedWindowDateTime, duration)
    );
  }
  return null;
}

/**
 * Returns true if the given date string is after a given property on a given stop.
 *
 * @param {String} date The date string to compare
 * @param {Object} stop The stop to use to retrieve the property
 * @param {String} property The stop's date property to compare
 *
 * @return {Boolean}
 */
function isDateAfterStopDateProperty(date, stop, property) {
  const dateProperty = get(stop, property);
  if (!dateProperty) {
    return true;
  }
  const timezone = get(stop, 'location.address.timezone', moment.tz.guess());
  const parsedDate = moment.tz(dateProperty, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ', timezone);
  return parsedDate.isValid() && moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').isAfter(parsedDate);
}

/**
 * Returns true if the given date string is the same or after to the given stop's arrival time.
 *
 * @param {String} date The date string to compare
 * @param {Object} stop The stop whose arrival time is being compared
 *
 * @return {Boolean}
 */
export function isDateAfterStopArrival(date, stop) {
  return isDateAfterStopDateProperty(date, stop, 'confirmed_arrival_at');
}

/**
 * Returns true if the given date string is the same or after to the given stop's departure time.
 *
 * @param {String} date The date string to compare
 * @param {Object} stop The stop whose departure time is being compared
 *
 * @return {Boolean}
 */
export function isDateAfterStopDeparture(date, stop) {
  return isDateAfterStopDateProperty(date, stop, 'confirmed_departure_at');
}

/**
 * Returns true if the given event is a simple arrival event and if the date is after the planned
 * window end. This is a helper composed of other helpers defined in this utility to prevent the
 * need for duplicate validation in multiple places.
 *
 * @param {Object} event
 * @param {Date} date
 * @param {Object} stop
 *
 * @return {Bolean}
 */
export function isArrivalSimpleEventAndLate(event, date, stop) {
  return isArrivalSimpleEvent(event) && isDateAfterStopPlannedWindowEnd(date, stop);
}
