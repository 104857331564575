import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {NetsuiteCompanyOptions, NetsuiteFieldOption} from '@shipwell/backend-core-sdk';

const defaultMappingFields = [
  {
    id: 'default_ns_subsidiary_customer',
    label: 'Default Subsidiary',
    optionsKey: 'subsidiaries'
  },
  {
    id: 'default_ns_sales_rep',
    label: 'Default Sales Rep',
    optionsKey: 'sales_reps'
  },
  {
    id: 'default_ns_accounts_payable',
    label: 'Default Accounts Payable',
    optionsKey: 'accounts_payable'
  },
  {
    id: 'default_ns_vendor_payment_terms',
    label: 'Default Vendor Payment Terms',
    optionsKey: 'vendor_terms'
  },
  {
    id: 'default_ns_filecabinet_folder_id',
    label: 'Default File Cabinet Folder',
    optionsKey: 'file_cabinet_folders'
  }
];

const DefaultMappingFormFields = ({options}: {options: NetsuiteCompanyOptions}) => (
  <div className="grid grid-cols-2 items-start justify-items-stretch gap-x-6 gap-y-4">
    {defaultMappingFields.map((field) => (
      <Field
        simpleValue
        name={`custom_fields.${field.id}`}
        label={field.label}
        key={field.id}
        component={FormikSelect}
        options={options?.[field.optionsKey as keyof NetsuiteCompanyOptions] || []}
        getOptionLabel={(option: NetsuiteFieldOption) => option.name}
        getOptionValue={(option: NetsuiteFieldOption) => option.id}
      />
    ))}
  </div>
);

export default DefaultMappingFormFields;
