import {Field} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';

const LegacyDrayageShipmentStageFields = () => {
  return (
    <div className="grid grid-cols-2 gap-4 pt-4">
      <Field component={FormikTextInput} name="drayage_container_number" label="Container #" />
      <Field component={FormikTextInput} name="drayage_seal_number" label="Seal #" />
      <Field component={FormikTextInput} name="drayage_chassis_number" label="Chassis #" />
    </div>
  );
};

export const ConditionalLegacyDrayageShipmentStageFields = ({isCloningContainer}: {isCloningContainer: boolean}) =>
  isCloningContainer ? <LegacyDrayageShipmentStageFields /> : null;
