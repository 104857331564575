import {Link} from 'react-router';
import first from 'lodash/first';
import {OrderStatus} from '@shipwell/corrogo-sdk';
import Loader from 'App/common/shipwellLoader';
import {LegStatusPill} from 'App/containers/shipments/components/Status/LegStatusPill';
import {useCarrierRelationships, useDrayageLeg, useTransportationOrders} from 'App/data-hooks';
import StageCard from 'App/containers/shipments/components/DashboardSummary/StageCard';
import InlineLoading from 'App/containers/shipments/components/DashboardSummary/InlineLoading';
import SequenceNumber from 'App/containers/shipments/components/DashboardSummary/SequenceNumber';
import StageActions from 'App/containers/shipments/components/DashboardSummary/StageActions';
import CustomerDisplay from 'App/containers/shipments/components/DashboardSummary/CustomerDisplay';
import {FinancialTenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/FinancialTenderingUserPermissionFallback';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';

function CarrierDetails({carrierId}: {carrierId: string}) {
  const {
    isLoading,
    context: {carrierDisplayValues}
  } = useCarrierRelationships({id: [carrierId]});
  const carrier = first(carrierDisplayValues);

  return isLoading ? (
    <InlineLoading />
  ) : !carrier ? (
    <>--</>
  ) : (
    <>
      {carrier.carrierId ? <Link to={`/carriers/${carrier.carrierId}`}>{carrier.name}</Link> : carrier.name}
      {carrier.carrierStatus ? <> ({carrier.carrierStatus})</> : null}
    </>
  );
}

function CarrierDisplay({shipmentId, legId}: {shipmentId: string; legId: string}) {
  const {getTransportationOrdersOfStatus, isLoading} = useTransportationOrders(shipmentId);
  const assignedTender = (getTransportationOrdersOfStatus(OrderStatus.Accepted) || []).find((tender) =>
    tender.leg_ids?.includes(legId)
  );

  return (
    <div>
      <span className="font-bold">Carrier: </span>
      {isLoading ? (
        <InlineLoading />
      ) : assignedTender ? (
        <CarrierDetails carrierId={assignedTender.service_provider.provider_id} />
      ) : (
        '--'
      )}
    </div>
  );
}

function LaneDisplay({legId}: {legId: string}) {
  const {
    isLoading,
    context: {pickupStopInfo, deliveryStopInfo}
  } = useDrayageLeg(legId);

  const pickupLane = pickupStopInfo?.stopDisplayValues.laneDescription;
  const deliveryLane = deliveryStopInfo?.stopDisplayValues.laneDescription;

  const pickupDate = pickupStopInfo?.stopDisplayValues.formattedPickupDate;
  const deliveryDate = deliveryStopInfo?.stopDisplayValues.formattedPickupDate;

  return (
    <>
      <div>
        <span className="font-bold">Lane: </span>
        {isLoading ? (
          <InlineLoading />
        ) : pickupLane && deliveryLane ? (
          <>
            {pickupLane}
            <> &rsaquo; </>
            {deliveryLane}
          </>
        ) : (
          '--'
        )}
      </div>
      <div>
        <span className="font-bold">Dates: </span>
        {isLoading ? (
          <InlineLoading />
        ) : pickupDate !== '--' && deliveryDate !== '--' ? (
          <>
            {pickupDate}
            <> &ndash; </>
            {deliveryDate}
          </>
        ) : (
          '--'
        )}
      </div>
    </>
  );
}

function LegView({
  shipmentId,
  legId,
  isActive = false,
  sequenceNumber = 1
}: {
  shipmentId?: string;
  legId: string;
  isActive?: boolean;
  sequenceNumber?: number;
}) {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return (
      <StageCard isActive={isActive}>
        <Loader loading />
      </StageCard>
    );
  }

  if (!shipmentId) {
    return null;
  }

  return (
    <StageCard isActive={isActive}>
      <div className="flex flex-col gap-2 whitespace-normal">
        <div className="flex w-full items-center">
          <SequenceNumber isActive={isActive}>{sequenceNumber}</SequenceNumber>
          <div className="flex text-sm">
            <span className="mx-2 font-bold">
              Drayage &middot;{' '}
              {drayageLegQuery.isInitialLoading ? <InlineLoading /> : drayageLegQuery.data?.reference_id}
            </span>
            {drayageLegQuery.isInitialLoading ? <InlineLoading /> : <LegStatusPill legId={legId} size="xs" />}
          </div>
        </div>
        <div className="ml-auto flex items-center">
          <StageActions shipmentId={shipmentId} resourceId={legId} linkTo={`/leg/${legId}`} />
        </div>
        <div className="flex flex-col gap-0 text-xs">
          <CustomerDisplay shipmentId={shipmentId} />
          <CarrierDisplay shipmentId={shipmentId} legId={legId} />
          <LaneDisplay legId={legId} />
          <FinancialTenderingUserPermissionFallback permissions={[VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]}>
            <div>
              <span className="font-bold">Carrier Financials: </span>--
            </div>
            <div>
              <span className="font-bold">Customer Financials: </span>--
            </div>
          </FinancialTenderingUserPermissionFallback>
        </div>
        <div className="text-xs">
          <Link target="_blank" to={`/leg/${legId}`}>
            View Details
          </Link>
        </div>
      </div>
    </StageCard>
  );
}

export default LegView;
