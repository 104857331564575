import {useState, useEffect} from 'react';
import {Formik, Field} from 'formik';
import {Select} from '@shipwell/shipwell-ui';
import {updateImportColumnMapping} from 'App/api/imports';

const MapFieldsForm = (props) => {
  const {
    type,
    importId,
    importableFields,
    columnMappings = [],
    columnWidths,
    allFieldsMapped,
    validationErrors
  } = props;
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState([]);
  const [remainingRequired, setRemainingRequired] = useState([]);

  const updateMapping = async (body) => {
    try {
      await updateImportColumnMapping(importId, body.id, body);
      if (body.shipwell_column_name) {
        const newValues = values.filter((e) => {
          return e && body.id !== e.column;
        });
        setValues([...newValues, {column: body.id, value: body.shipwell_column_name, ...body}]);
      } else {
        const newValues = values.filter((e) => {
          return e && body.id !== e.column;
        });
        setValues(newValues);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (columnMappings.some((e) => e.shipwell_column_name)) {
      //set initialvalues
      const newValues = columnMappings.map((e) => {
        if (importableFields[e.shipwell_column_name]) {
          return {
            column: e.id,
            value: e.shipwell_column_name,
            description: importableFields[e.shipwell_column_name].description
          };
        }
        return null;
      });
      setValues(newValues);
    }
  }, [columnMappings]);

  useEffect(() => {
    const remaining = fieldsArray
      .filter((e) => e.required)
      .filter((e) => !values.find((col) => col && col.value === e.value));

    setRemainingRequired(remaining);
  }, [values]);

  useEffect(() => {
    const fieldsArray = Object.keys(importableFields).map((e) => {
      return {value: e, ...importableFields[e]};
    });
    setRemainingRequired(fieldsArray.filter((e) => e.required));
  }, [importableFields]);

  useEffect(() => {
    allFieldsMapped(remainingRequired.length === 0);
  }, [remainingRequired]);

  const fieldsArray = Object.keys(importableFields).map((e) => {
    return {value: e, ...importableFields[e]};
  });

  fieldsArray.sort((a, b) => {
    return a.required ? -1 : 1;
  });

  return (
    <div className="imports__columnMapping">
      {!validationErrors.length && (
        <p>
          Please map your spreadsheet columns to Shipwell's columns below. You must map all required columns for the
          data to be saved correctly.
        </p>
      )}
      <div className="imports__columnMapping-container">
        <h4>
          <b>Shipwell's Columns</b>
        </h4>
        <p>
          {remainingRequired.length > 0 ? (
            <>
              The remaining required columns are{' '}
              <span className="error-text">
                {remainingRequired
                  .filter((e) => !values.find((col) => col && col.value === e.value))
                  .map((e) => e.description)
                  .join(', ')}
              </span>
              .
            </>
          ) : (
            <>All required columns have been mapped.</>
          )}
        </p>
        <div className="imports__columnMapping-fields">
          {columnMappings.map((column) => {
            return (
              <div key={column.id} style={{width: columnWidths[column.id]}} className="imports__columnMapping-field">
                <Select
                  name={column.id}
                  label="Column"
                  options={fieldsArray.filter((e) => !values.some((val) => val && val.value === e.value))}
                  getOptionLabel={(e) => `${e.description}${e.required ? '*' : ''}`}
                  getOptionValue={(e) => e.value}
                  value={values.find((e) => e && e.column === column.id)}
                  onChange={(e) => {
                    updateMapping({
                      ...column,
                      value: e ? e.value : null,
                      description: e ? e.description : null,
                      shipwell_column_name: e ? e.value : null
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MapFieldsForm;
