import {useQuery} from '@tanstack/react-query';
import {getProducts, GetProductsNamedParameters} from 'App/api/products/typed';
import {PRODUCTS_QUERY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const useProducts = (queryParams: GetProductsNamedParameters = {}) => {
  return useQuery(
    [PRODUCTS_QUERY],
    async () => {
      const response = await getProducts(queryParams);
      return response.data.results;
    },
    {
      staleTime: HOT_DATA_STALE_TIME
    }
  );
};
