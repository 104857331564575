import {useState} from 'react';
import {connect} from 'react-redux';
import {Formik, Field} from 'formik';
import {object, array} from 'yup';
import {Card, DeprecatedButton, FormikCheckbox} from '@shipwell/shipwell-ui';
import GetSpotQuotesFields from 'App/formComponents/formSections/spotQuotesFields';
import {carriersValidationSchema} from 'App/utils/yupHelpers';

const GetSpotQuotesForm = (props) => {
  const {
    onSubmit,
    showShipwellNetwork,
    message,
    showPreview,
    values = {},
    handleSpecialInstructions,
    special_instructions
  } = props;
  // need to keep a state on isChecked for validation purposes
  const [isChecked, setIsChecked] = useState(showShipwellNetwork);
  const defaultFormValues = {
    recipient_groups: '',
    vendors: [],
    message,
    send_to_carrier_network: showShipwellNetwork,
    special_instructions: ''
  };

  const handleSubmit = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const spotQuotesSchema = object().shape({
    vendors: carriersValidationSchema
  });

  const handleCheckboxClick = (e, setFieldValue) => {
    const checked = e.target.checked;
    setFieldValue('send_to_carrier_network', checked);
    setIsChecked(checked);
  };

  return (
    <Formik
      enableReinitialize
      // run validation only when the checkbox is unchecked
      validationSchema={!isChecked ? spotQuotesSchema : null}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({setFieldValue, handleSubmit, isSubmitting, dirty, values, ...props}) => (
        <form noValidate="novalidate" onSubmit={handleSubmit}>
          <Card title="Carrier">
            <GetSpotQuotesFields
              values={values}
              setFieldValue={setFieldValue}
              handleSpecialInstructions={handleSpecialInstructions}
              special_instructions={special_instructions}
            />
          </Card>
          {/* modal footer section */}
          <div className="get-spot-quotes__inline">
            {showShipwellNetwork && (
              <Field
                name="send_to_carrier_network"
                component={FormikCheckbox}
                label="Send to Shipwell Carrier Network"
                onChange={(e) => handleCheckboxClick(e, setFieldValue)}
              />
            )}
            <span className="get-spot-quotes__buttons">
              <DeprecatedButton
                onClick={() => showPreview()}
                variant="secondary"
                className="get-spot-quotes-preview-btn"
              >
                <i className="icon icon-Mail pad-right" />
                Preview Email
              </DeprecatedButton>
              <DeprecatedButton onClick={() => handleSubmit(values)} disabled={isSubmitting} loading={isSubmitting}>
                Send
              </DeprecatedButton>
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default connect((state) => ({
  showShipwellNetwork:
    state.auth.company &&
    state.auth.company.feature_flags &&
    state.auth.company.feature_flags.send_shipwell_network_enabled,
  message: state.marketplace.spotNegotiationEmailPreview && state.marketplace.spotNegotiationEmailPreview.message
}))(GetSpotQuotesForm);
