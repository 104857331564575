import {AddressBookEntry, ShipwellError} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient, useQuery, UseMutationOptions, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import isNil from 'lodash/isNil';
import {getAddressBookAddressById, updateAddressBookAddressById, createAddressBook} from 'App/api/addressBook/typed';
import {ADDRESS_BOOK_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {cleanPayload} from 'App/utils/globals';

export type UseGetAddressEntryQueryOptions = Omit<
  UseQueryOptions<
    AddressBookEntry,
    AxiosError<ShipwellError, unknown>,
    AddressBookEntry,
    (string | null | undefined)[]
  >,
  'queryFn' | 'queryKey'
>;

/**
 * Gets an address book entry by it's identifier. If no entry exists an AxiosError<ShipwellError> is returned in the async
 * callback onError(...)
 *
 * If the addressBookId param is not provided or is falsy then the query will be disabled.
 */
export const useGetAddressEntryQuery = (addressBookId?: string | null, options?: UseGetAddressEntryQueryOptions) => {
  return useQuery(
    [ADDRESS_BOOK_QUERY_KEY, addressBookId],
    async () => {
      if (isNil(addressBookId)) {
        throw new Error('Cannot get address book entry without ID');
      }
      const {data} = await getAddressBookAddressById(addressBookId);
      return data;
    },
    options
  );
};

export type UseUpdateAddressEntryMutationOptions = Omit<
  UseMutationOptions<AddressBookEntry, AxiosError<ShipwellError, unknown>, AddressBookEntry, AddressBookEntry>,
  'mutationFn'
>;

/**
 * Updates an existing address book entry. If one does not exist an AxiosError<ShipwellError> will be returned in the async
 * callback onError(...).
 *
 * onSuccess the query cache is invalidated using the unique ADDRESS_BOOK_QUERY_KEY and the address book entry id.
 */
export const useUpdateAddressEntryMutation = (
  addressBookId?: string | null,
  options?: UseUpdateAddressEntryMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (entry: AddressBookEntry) => {
      if (!addressBookId) {
        throw new Error('Cannot update address without an identifier');
      }
      const payload = {
        ...entry,
        external_reference: entry.external_reference ?? entry.location_name
      };
      const resp = await updateAddressBookAddressById({
        addressBookId: addressBookId,
        addressBookEntry: payload
      });
      return resp.data;
    },
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        void queryClient.invalidateQueries([ADDRESS_BOOK_QUERY_KEY, addressBookId]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );
};

export type UseCreateAddressBookEntryMutationOptions = Omit<
  UseMutationOptions<AddressBookEntry, AxiosError<ShipwellError, unknown>, AddressBookEntry, AddressBookEntry>,
  'mutationFn'
>;

export const useCreateAddressBookEntryMutation = (options?: UseCreateAddressBookEntryMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<AddressBookEntry, AxiosError<ShipwellError>, AddressBookEntry, AddressBookEntry>(
    async (entry: AddressBookEntry) => {
      const values = cleanPayload(entry) as AddressBookEntry;

      if (!values.is_default_origin) {
        values.is_default_origin = false;
      }
      if (!values.external_reference) {
        values.external_reference = values.location_name;
      }

      const resp = await createAddressBook(values);
      //this isn't accurate, because setQueryData can return undefined. Typecasting here to
      //resolve typing error, but not a long-term solution.
      return queryClient.setQueryData([ADDRESS_BOOK_QUERY_KEY, resp.data.id], resp.data) as AddressBookEntry;
    },
    {
      ...options
    }
  );
};
