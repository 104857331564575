import {
  CompanyPreferences,
  ShipwellError,
  CompaniesApiCompaniesCompanyIdPreferencesPutRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {COMPANY_PREFERENCES_KEY} from 'App/data-hooks/queryKeys';
import {updateCompanyPreferences} from 'App/api/company/typed';

export type UseUpdateCompanyPreferencesMutationOptions = Omit<
  UseMutationOptions<
    CompanyPreferences,
    AxiosError<ShipwellError>,
    CompaniesApiCompaniesCompanyIdPreferencesPutRequest,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
const useUpdateCompanyPreferencesMutation = (options?: UseUpdateCompanyPreferencesMutationOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (request: CompaniesApiCompaniesCompanyIdPreferencesPutRequest) => updateCompanyPreferences(request),
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        void queryClient.invalidateQueries([COMPANY_PREFERENCES_KEY, variables?.companyId]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {
    isCompanyPreferencesUpdating: mutation.isLoading,
    updateCompanyPreferences: mutation.mutate,
    updateCompanyPreferencesAsync: mutation.mutateAsync,
    companyPreferences: mutation.data || null,
    updateCompanyPreferencesError: mutation.error || null,
    isCompanyPreferencesUpdated: mutation.isSuccess,
    isCompanyPreferencesUpdateError: mutation.isError
  };
};

export default useUpdateCompanyPreferencesMutation;
