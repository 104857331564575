import {useMemo} from 'react';
import {Title, DeprecatedButton} from '@shipwell/shipwell-ui';
import noop from 'lodash/noop';
import Loader from 'App/common/shipwellLoader';
import {useTransportationOrders} from 'App/data-hooks';
import Table from 'App/components/Table';

const TenderListTable = ({
  shipmentId,
  toggleShowTenderCreationModal,
  toggleShowTenderDetailsModal,
  setSelectedTender
}: {
  shipmentId: string;
  toggleShowTenderCreationModal(): void;
  toggleShowTenderDetailsModal(): void;
  setSelectedTender(tenderId: string): void;
}) => {
  const {transportationOrderDisplayValues, queryInfo} = useTransportationOrders(shipmentId);

  const columns = useMemo(
    () => [
      {
        Header: 'Carrier',
        accessor: 'carrier'
      },
      {
        Header: 'Status',
        maxWidth: 100,
        accessor: 'status'
      },
      {
        Header: 'Expiration',
        accessor: 'expiration'
      },
      {
        Header: 'Containers',
        maxWidth: 75,
        accessor: 'numberOfContainers'
      },
      {
        Header: 'Rate (Per Container)',
        accessor: 'ratePerContainer',
        Cell: ({value}: {value: string}) => (
          <div>
            <strong className="text-sw-success">{value}</strong> (Excludes Accesorials)
          </div>
        )
      },
      {
        Header: 'Actions',
        maxWidth: 75,
        accessor: 'id',
        Cell: ({value}: {value: string}) => (
          <DeprecatedButton
            variant="tertiary"
            onClick={() => {
              setSelectedTender(value);
              toggleShowTenderDetailsModal();
            }}
          >
            View
          </DeprecatedButton>
        )
      }
    ],
    [toggleShowTenderDetailsModal, setSelectedTender]
  );

  if (queryInfo.isLoading) {
    return <Loader loading />;
  }

  return transportationOrderDisplayValues.length > 0 ? (
    <Table columns={columns} data={transportationOrderDisplayValues} />
  ) : (
    <div className="flex flex-col items-center justify-center gap-4 p-8">
      <Title variant="emptyStateHeader">No Tender Requests</Title>
      <div className="cursor-pointer text-sw-primary" onClick={toggleShowTenderCreationModal}>
        Tender to Carrier
      </div>
    </div>
  );
};

TenderListTable.defaultProps = {
  toggleShowTenderDetailsModal: noop,
  toggleShowTenderCreationModal: noop,
  setSelectedTender: noop
};

export default TenderListTable;
