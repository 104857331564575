import {Children, ReactNode, cloneElement, isValidElement, useEffect, useRef, useState} from 'react';
import {Button, IconButton} from '@shipwell/shipwell-ui';

export const ReadbackContainer = ({
  children,
  label,
  onClearAll
}: {
  children: ReactNode;
  label?: string;
  onClearAll: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isObscuredIndexes, setIsObscuredIndexes] = useState<number[]>([]);
  const [lastVisibleChildPos, setLastVisibleChildPos] = useState<number>();
  const [countSpaceWidth, setCountSpaceWidth] = useState<number | undefined>(14);
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRefs = useRef<HTMLElement[]>([]);
  const countRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const handleResize = () => {
      if (!containerRef.current) {
        return;
      }
      // set countRef width so that we hold space for it in the UI when it is absolutely positioned
      setCountSpaceWidth(countRef.current?.clientWidth);
      // get container's right side px value and compare it to child ride side.
      const {right: containerRightSide} = containerRef.current.getBoundingClientRect();

      const getChildrenRightSide = (index: number): number | undefined =>
        childrenRefs.current?.[index]?.getBoundingClientRect()?.right;

      const currentlyObscuredIndexes: number[] = [];
      // check each child's right side and see if it is greater than the container's right side, meaning it is at least partially obscured
      childrenRefs.current.forEach((child, i) => {
        const childRect: DOMRect | undefined = child?.getBoundingClientRect();
        // if no childRect, child was removed
        if (!childRect) {
          childrenRefs.current = childrenRefs.current.filter((_, index) => index !== i);
        }
        const childRightSide = childRect?.right;
        const isObscured = childRightSide > containerRightSide;
        if (isObscured) {
          currentlyObscuredIndexes.push(i);
        } else {
          currentlyObscuredIndexes.filter((index) => index !== i);
        }
      });

      // get the greatest visible index by looking for one lower than the min value in list of currently obscured indexes
      if (currentlyObscuredIndexes.length) {
        const greatestVisibleIndex = Math.min(...currentlyObscuredIndexes) - 1;
        setLastVisibleChildPos(getChildrenRightSide(greatestVisibleIndex));
      }
      setIsObscuredIndexes(currentlyObscuredIndexes);
    };
    handleResize();
    const observer = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        handleResize();
      });
    });
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [isOpen, children]);

  const hasChildTags = Boolean(Children.count(children));

  const handleClearAll = () => {
    onClearAll();
    setIsOpen(false);
  };

  return (
    <div className="flex items-start gap-4 border-y-1 border-sw-border bg-sw-background p-2 px-4">
      <div className={`flex grow ${isOpen ? 'flex-wrap' : ''} h-full items-center gap-2 overflow-hidden`}>
        {isOpen ? (
          <>
            {label ? <span className="whitespace-nowrap text-xs uppercase">{label}:</span> : null}
            {children}
            {hasChildTags ? (
              <Button size="sm" variant="tertiary" width="compact" onClick={handleClearAll}>
                CLEAR ALL
              </Button>
            ) : null}
          </>
        ) : (
          <>
            {label ? <span className="whitespace-nowrap text-xs uppercase">{label}:</span> : null}
            <div className="flex items-center gap-2 overflow-hidden" ref={containerRef}>
              {/* mapping over children and adding ref to childredRefs array */}
              {/* this allows up to track children width to know when and which children to hide */}
              {/* Any child passed must have a ref prop available */}
              {/* If passing a component, make sure the component is wrapped in forwardRef and has an isHidden prop to set opacity to 0 */}
              {Children?.map(children, (child, i) => {
                if (!isValidElement(child)) return null;

                const childWithRef = cloneElement(child, {
                  ref: (el: HTMLElement) => (childrenRefs.current[i] = el),
                  isHidden: isObscuredIndexes.includes(i)
                } as Partial<unknown>);

                return childWithRef;
              })}
            </div>

            {!isOpen && isObscuredIndexes.length ? (
              <>
                {/* this first span should be absolutely positioned next to the last visible child */}
                {/* Adding .5rem to account for flex gap */}
                <span
                  ref={countRef}
                  style={
                    typeof lastVisibleChildPos === 'number'
                      ? {position: 'absolute', left: `calc(${lastVisibleChildPos}px + .5rem)`}
                      : {}
                  }
                  className="whitespace-nowrap text-xs font-bold text-sw-primary"
                >
                  +{isObscuredIndexes.length}
                </span>
                {/* this aria-hidden div is a placeholder to account for the above span's width when calculating available space since the above space is absolutely positioned */}
                <div
                  aria-hidden
                  style={typeof countSpaceWidth === 'number' ? {width: `calc(${countSpaceWidth}px + .5rem)`} : {}}
                />
              </>
            ) : null}
            {hasChildTags ? (
              <Button size="sm" variant="tertiary" width="compact" onClick={handleClearAll}>
                CLEAR ALL
              </Button>
            ) : null}
          </>
        )}
      </div>
      <IconButton
        disabled={!hasChildTags}
        iconName={isOpen ? 'ExpandLess' : 'ExpandMore'}
        aria-label={`${isOpen ? 'Collapse' : 'Expand'} filter readback`}
        onClick={() => setIsOpen((open) => !open)}
        isActive={isOpen}
      />
    </div>
  );
};
