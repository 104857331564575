import {useMutation} from '@tanstack/react-query';
import {Formik, Form, Field, useFormikContext} from 'formik';
import {AxiosError} from 'axios';
import {object} from 'yup';
import type {InferType} from 'yup';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import cloneDeep from 'lodash/cloneDeep';
import {FormikTextInput, FormikTextarea, DeprecatedButton} from '@shipwell/shipwell-ui';
import StopContactsFields from './StopContactsFields';
import {drayageStopValidationSchema} from 'App/containers/shipments/utils/constants';
import {useInvalidateOnSettled} from 'App/utils/queryHelpers';
import {useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import {updateShipmentLegsStops} from 'App/api/corrogo/typed';
import Loader from 'App/common/shipwellLoader';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {
  DefaultStopFormValues,
  DisplayTypes,
  StopTypeId,
  StopTypeType
} from 'App/containers/shipments/components/StopsFields/constants';
import {PickupStopType} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';
import {createStops} from 'App/containers/shipments/drayage/create/components/LegForm';

function createValidationSchema(stopType: StopTypeType) {
  return object().shape({
    [stopType.id]: drayageStopValidationSchema.test(
      'valid-stop',
      `A valid ${stopType.label} address is required.`,
      (value) => !isEmpty(value.address_1)
    )
  });
}

type StopFormValues = InferType<ReturnType<typeof createValidationSchema>>;

function DrayageShipmentStopsFields({stopType}: {stopType: StopTypeType}) {
  return (
    <>
      <Field required name={stopType.id} label={`${stopType.label} Address`} component={AddressSearch} />
      <Field className="py-4" name={`${stopType.id}.company_name`} label="Company Name" component={FormikTextInput} />
      <Field
        className="pb-4"
        name={`${stopType.id}.instructions`}
        label={`${stopType.label} Instructions`}
        component={FormikTextarea}
      />
      <StopContactsFields stopType={stopType} display={DisplayTypes.ModalDisplay} />
    </>
  );
}

function CopyDeliveryDetailsButton({shipmentId, stopType}: {shipmentId: string; stopType: StopTypeType}) {
  const {
    context: {stopsFormValues}
  } = useV3Shipment(shipmentId);

  const {setFieldValue} = useFormikContext<StopFormValues>();

  const deliveryStopFormValues = stopsFormValues?.find((stopsValues) => stopsValues.sequenceNumber === 2);
  if (!deliveryStopFormValues || stopType.id !== StopTypeId.ContainerPickup) {
    return null;
  }

  const handleClickCopy = () => {
    setFieldValue('containerPickup', cloneDeep(deliveryStopFormValues));
  };

  return (
    <div className="mx-[-5px] mb-4">
      <DeprecatedButton variant="tertiary" onClick={handleClickCopy}>
        Use Delivery Stop Details
      </DeprecatedButton>
    </div>
  );
}

function CopyPickupDetailsButton({shipmentId, stopType}: {shipmentId: string; stopType: StopTypeType}) {
  const {
    context: {stopsFormValues}
  } = useV3Shipment(shipmentId);

  const {setFieldValue} = useFormikContext<StopFormValues>();

  const pickupStopFormValues = stopsFormValues?.find((stopsValues) => stopsValues.sequenceNumber === 1);
  if (!pickupStopFormValues || stopType.id !== StopTypeId.ContainerReturn) {
    return null;
  }

  const handleClickCopy = () => {
    setFieldValue('containerReturn', cloneDeep(pickupStopFormValues));
  };

  return (
    <div className="mx-[-5px] mb-4">
      <DeprecatedButton variant="tertiary" onClick={handleClickCopy}>
        Use Pickup Stop Details
      </DeprecatedButton>
    </div>
  );
}

export function AddDrayageShipmentStopsForm({
  shipmentId,
  stopType,
  onClose
}: {
  shipmentId: string;
  stopType: StopTypeType;
  onClose: () => void;
}) {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {updateShipmentStopsMutation} = useCreateOrUpdateShipment(shipmentId);
  const createInvalidateOnSettled = useInvalidateOnSettled();
  const updateShipmentLegsStopsMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentLegsStops>>,
    unknown,
    {shipmentId: string}
  >(({shipmentId}) => updateShipmentLegsStops(shipmentId), {
    onSettled: createInvalidateOnSettled([SHIPMENTS_QUERY_KEY, shipmentId])
  });

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const handleSubmit = (values: {[key in typeof stopType.id]?: typeof DefaultStopFormValues}) => {
    async function submitIfValid() {
      const isContainerPickup = stopType.id === StopTypeId.ContainerPickup;
      const containerStops = createStops({
        ...values,
        containerPickupRequired: isContainerPickup,
        containerReturnRequired: !isContainerPickup
      });
      const containerStop = first(containerStops);
      if (!containerStop) {
        return;
      }
      containerStop.sequence_number = isContainerPickup ? 1 : 3;
      const currentStops = shipmentQuery.data?.stops || [];
      const payload = isContainerPickup
        ? [containerStop, {...currentStops[0], sequence_number: 2}, {...currentStops[1], sequence_number: 3}]
        : [...currentStops, containerStop];
      await updateShipmentStopsMutation.mutateAsync({shipmentId, data: {stops: payload}});
      updateShipmentLegsStopsMutation.mutate({shipmentId});
    }
    void submitIfValid();
    onClose();
  };

  return (
    <Formik
      validationSchema={createValidationSchema(stopType)}
      onSubmit={handleSubmit}
      initialValues={{[stopType.id]: DefaultStopFormValues}}
    >
      <Form>
        <CopyDeliveryDetailsButton shipmentId={shipmentId} stopType={stopType} />
        <CopyPickupDetailsButton shipmentId={shipmentId} stopType={stopType} />
        <DrayageShipmentStopsFields stopType={stopType} />
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
}

const EditDrayageShipmentStopsForm = ({
  shipmentId,
  sequenceNumber,
  onClose
}: {
  shipmentId: string;
  sequenceNumber: number;
  onClose: () => void;
}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const {updateShipmentStopMutation, deleteShipmentStopMutation} = useCreateOrUpdateShipment(shipmentId);
  const createInvalidateOnSettled = useInvalidateOnSettled();
  const updateShipmentLegsStopsMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentLegsStops>>,
    AxiosError,
    {shipmentId: string}
  >(({shipmentId}) => updateShipmentLegsStops(shipmentId), {
    onSettled: createInvalidateOnSettled([SHIPMENTS_QUERY_KEY, shipmentId])
  });

  if (shipmentQuery.isLoading) {
    return <Loader loading />;
  }

  const {
    context: {stopsFormValues, getStopType}
  } = shipmentQuery;

  const currentStopValues =
    stopsFormValues?.find((stop) => stop.sequenceNumber === sequenceNumber) || DefaultStopFormValues;
  const stopType = getStopType ? getStopType(sequenceNumber) : PickupStopType;
  const isContainerPickupStop = stopType.id === StopTypeId.ContainerPickup;
  const isContainerReturnStop = stopType.id === StopTypeId.ContainerReturn;
  const isContainerStop = isContainerPickupStop || isContainerReturnStop;

  const handleSubmit = async (values: {[key in typeof stopType.id]?: typeof currentStopValues}) => {
    const currentStop = shipmentQuery?.data?.stops?.find((stop) => stop.sequence_number === sequenceNumber);
    const stopTypeValues = values[stopType.id] || ({} as typeof currentStopValues);
    const payload = {
      ...currentStop,
      sequence_number: sequenceNumber,
      location: {
        ...currentStop?.location,
        address: {
          ...currentStop?.location.address,
          country: stopTypeValues.country,
          postal_code: stopTypeValues.postal_code,
          line_1: stopTypeValues.address_1,
          line_2: stopTypeValues.address_2 || undefined,
          region: stopTypeValues.state_province,
          locality: stopTypeValues.city
        },
        company_name: stopTypeValues.company_name
      },
      instructions: stopTypeValues.instructions,
      contacts: stopTypeValues.point_of_contacts?.map((contact) => {
        return {
          person_name: `${contact?.first_name || ''} ${contact?.last_name || ''}`.trim() || undefined,
          email: contact?.email,
          phone: contact?.phone_number,
          id: contact?.id
        };
      })
    };

    await updateShipmentStopMutation.mutateAsync({shipmentId, sequenceId: sequenceNumber, data: payload});
    updateShipmentLegsStopsMutation.mutate({shipmentId});
    onClose();
  };

  const handleDeleteContainerStop = () => {
    const handleDeleteContainerStopAsync = async () => {
      await deleteShipmentStopMutation.mutateAsync({shipmentId, sequenceId: sequenceNumber});
      updateShipmentLegsStopsMutation.mutate({shipmentId});
    };
    void handleDeleteContainerStopAsync();
    onClose();
  };

  return (
    <Formik
      validationSchema={createValidationSchema(stopType)}
      onSubmit={handleSubmit}
      initialValues={{[stopType.id]: currentStopValues}}
    >
      <Form>
        <CopyDeliveryDetailsButton shipmentId={shipmentId} stopType={stopType} />
        <CopyPickupDetailsButton shipmentId={shipmentId} stopType={stopType} />
        <DrayageShipmentStopsFields stopType={stopType} />
        <ModalFormFooter
          options={
            isContainerStop ? (
              <DeprecatedButton className="text-sw-destroy" variant="tertiary" onClick={handleDeleteContainerStop}>
                {`Delete ${stopType.label}`}
              </DeprecatedButton>
            ) : null
          }
          onCancel={onClose}
        />
      </Form>
    </Formik>
  );
};

export default EditDrayageShipmentStopsForm;
