import {useQuery} from '@tanstack/react-query';
import {getDocumentShortNames} from 'App/api/shipwellUI/typed';
import {DOCUMENT_TYPE_SHORT_NAMES} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

const getDocumentShortNamesMap = async () => {
  const {data: shortNamesArray} = await getDocumentShortNames();
  if (!shortNamesArray?.length) {
    return {};
  }
  const shortNamesMap = shortNamesArray.reduce<Record<string, string>>((map, shortName) => {
    map[shortName.id] = shortName.name;
    return map;
  }, {});

  return shortNamesMap;
};

export const useDocumentShortNames = () =>
  useQuery([DOCUMENT_TYPE_SHORT_NAMES], getDocumentShortNamesMap, {
    staleTime: STATIC_DATA_STALE_TIME
  });
