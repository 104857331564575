import {useQuery} from '@tanstack/react-query';
import {getCapacitySearchCarrierDetails} from 'App/api/capacitySearch';
import {CAPACITY_SEARCH_DETAILS_KEY} from 'App/data-hooks/queryKeys';

const useGetCapacitySearchCarrierDetails = (matchId?: string) => {
  const response = useQuery(
    [CAPACITY_SEARCH_DETAILS_KEY, matchId],
    async () => {
      const response = await getCapacitySearchCarrierDetails(matchId || '');
      return response.data;
    },
    {
      enabled: !!matchId
    }
  );

  return response;
};

export default useGetCapacitySearchCarrierDetails;
