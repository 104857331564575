import axios from 'axios';
import {getShipwellApi, basePath} from 'App/api/config';
import {apiCallback, getAccessToken} from 'App/api/utils';

const transformRequest = (body) => {
  const formData = new FormData();
  const fields = Object.keys(body);

  fields.forEach((field) => {
    formData.append(field, body[field]);
  });

  return formData;
};

/**
 * Import file
 */
export async function importFile(body) {
  const accessToken = await getAccessToken();
  return new Promise((resolve, reject) => {
    const config = {
      transformRequest: [(body) => transformRequest(body)],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };

    const request = axios.post(basePath + '/imports/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

export async function getImport(importId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdGet(importId, apiCallback(resolve, reject));
  });
}

export async function getImportableFields() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportableFieldsGet(apiCallback(resolve, reject));
  });
}

export async function getImportColumnMappings(importId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdColumnMappingsGet(importId, apiCallback(resolve, reject));
  });
}

export async function updateImportColumnMapping(importId, columnMappingId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdColumnMappingsImportColumnMappingIdPut(
      importId,
      columnMappingId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

export async function getImportRows(importId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdRowsGet(importId, opts, apiCallback(resolve, reject));
  });
}

export async function updateImportRow(importId, rowId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdRowsImportRowIdPut(importId, rowId, body, apiCallback(resolve, reject));
  });
}

export async function updateImportValue(importId, importValueId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdValuesImportValueIdPut(importId, importValueId, body, apiCallback(resolve, reject));
  });
}

export async function createImportJob(importId, validateOnly, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdJobsPost(importId, validateOnly, opts, apiCallback(resolve, reject));
  });
}

export async function getImportJob(importId, jobId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importsImportIdJobsImportJobIdGet(importId, jobId, apiCallback(resolve, reject));
  });
}

export async function getImportJobErrors(jobId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.importJobsImportJobIdErrorsGet(jobId, opts, apiCallback(resolve, reject));
  });
}
