import {
  Configuration,
  CreateSupplier,
  CreateSupplierUser,
  SuppliersApi,
  UpdateSupplier
} from '@shipwell/backend-core-sdk';
import {LocationsApiAddressBookGetRequest} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const suppliersApi = new SuppliersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

type GetSuppliersParameters = Parameters<typeof SuppliersApi.prototype.suppliersGet>;
export type SuppliersOptions = {
  page?: GetSuppliersParameters[0];
  pageSize?: GetSuppliersParameters[1];
  ordering?: GetSuppliersParameters[2];
  q?: GetSuppliersParameters[3];
  name?: GetSuppliersParameters[4];
  supplierUserFullName?: GetSuppliersParameters[5];
  options?: GetSuppliersParameters[6];
};
export const getSuppliers = async (opts: SuppliersOptions) => {
  const {page, pageSize, ordering, q, name, supplierUserFullName, options} = opts;
  const axiosResponse = await suppliersApi.suppliersGet(
    page,
    pageSize,
    ordering,
    q,
    name,
    supplierUserFullName,
    options
  );
  return axiosResponse.data;
};

export const getSupplier = async (supplierId: string) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdGet(supplierId);
  return axiosResponse.data;
};

export const createSupplier = async (createSupplier?: CreateSupplier) => {
  const axiosResponse = await suppliersApi.suppliersPost(createSupplier);
  return axiosResponse.data;
};

export const updateSupplier = async ({
  supplierId,
  updatedSupplier
}: {
  supplierId: string;
  updatedSupplier: UpdateSupplier;
}) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdPut(supplierId, updatedSupplier);
  return axiosResponse.data;
};

export const deleteSupplier = async (supplierId: string) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdDelete(supplierId);
  return axiosResponse.data;
};

export const createSupplierUser = async ({supplierId, user}: {supplierId: string; user: CreateSupplierUser}) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdUsersPost(supplierId, user);
  return axiosResponse.data;
};

export const deleteSupplierUser = async ({supplierId, userId}: {supplierId: string; userId: string}) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdUsersSupplierUserIdDelete(supplierId, userId);
  return axiosResponse.data;
};

export const updateSupplierUser = async ({
  supplierId,
  supplierUserId,
  createSupplierUser
}: {
  supplierId: string;
  supplierUserId: string;
  createSupplierUser: CreateSupplierUser | undefined;
}) => {
  const axiosResponse = await suppliersApi.suppliersSupplierIdUsersSupplierUserIdPut(
    supplierId,
    supplierUserId,
    createSupplierUser
  );
  return axiosResponse.data;
};

export const getSupplierAddresses = async (options: LocationsApiAddressBookGetRequest & {supplierId: string}) => {
  const axiosResponse = await suppliersApi.suppliersAddressesGet(
    options.page,
    options.pageSize,
    options.ordering,
    options.q,
    options.supplierId
  );
  return axiosResponse;
};
