import {Configuration, ImportsApi} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createImportsApi() {
  return new ImportsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type ImportJobsPostParameters = Parameters<typeof ImportsApi.prototype.importsImportIdJobsPost>;
type ImportJobsOptions = {
  fuelSurchargeTableId?: ImportJobsPostParameters[2];
  rfpBidId?: ImportJobsPostParameters[3];
  rfpBidOptId?: ImportJobsPostParameters[4];
  rfpCarrierBidRequestId?: ImportJobsPostParameters[5];
  rateTableId?: ImportJobsPostParameters[6];
  options?: ImportJobsPostParameters[7];
};

export const createImportJob = (
  importId: ImportJobsPostParameters[0],
  validateOnly: ImportJobsPostParameters[1],
  opts?: ImportJobsOptions
) => {
  return createImportsApi().importsImportIdJobsPost(
    importId,
    validateOnly,
    opts?.fuelSurchargeTableId,
    opts?.rfpBidId,
    opts?.rfpBidOptId,
    opts?.rfpCarrierBidRequestId,
    opts?.rateTableId,
    opts?.options
  );
};
