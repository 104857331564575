import {connect} from 'react-redux';
import {submit} from 'redux-form';
import {Button} from 'react-bootstrap';

const SubmitButton = ({dispatch, handleSubmit, submitting}) => (
  <Button type="button" disabled={submitting} bsStyle="primary" onClick={() => handleSubmit()}>
    {submitting ? (
      <span>
        <i className="icon icon-Restart rotate" />
        &nbsp;&nbsp;Saving...
      </span>
    ) : (
      'Save'
    )}
  </Button>
);

export default connect()(SubmitButton);
