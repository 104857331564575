import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {CapacityProviderSchema, ProviderCode} from '@shipwell/genesis-sdk';
import {AxiosError} from 'axios';
import {getCarrierIntegrationApi} from 'App/api/integrations';
import {FormikSubmitEventData} from 'App/formComponents/forms/uberFreightRegistration';
import {COMPANY_INTEGRATION_UBER} from 'App/data-hooks/queryKeys';

/**
 * React-Query mutation hook for creating a POST request to the integration API
 * for connecting Uber Freight as a possible Carrier.
 *
 * The Mutation hook as a dependency on formik and getting the values through a
 * form submit callback.
 */
const useUberFreightMutation = (
  options: UseMutationOptions<CapacityProviderSchema, AxiosError, FormikSubmitEventData, unknown>
) =>
  useMutation(
    [COMPANY_INTEGRATION_UBER],
    async (formData: FormikSubmitEventData) => {
      formData.helpers.setSubmitting(true);
      try {
        const {data} = await getCarrierIntegrationApi().createProviderProvidersPost({
          createCapacityProviderSchema: {
            provider_code: ProviderCode.Uber,
            accounts: [
              {
                provider_code: ProviderCode.Uber,

                account_number: formData.values['account_number']
              }
            ]
          }
        });
        return data;
      } finally {
        formData.helpers.setSubmitting(false);
      }
    },
    options
  );

export default useUberFreightMutation;
