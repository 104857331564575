import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import classnames from 'classnames';
import pluralize from 'pluralize';
import {SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import StatusEdit from './Edit';
import {unpackShipmentErrors, checkShipmentModes} from 'App/utils/globalsTyped';
import withStatusToasts from 'App/components/withStatusToasts';
import {shipmentStatusMap, STATUS_OPEN, STATUS_DELAYED, StatusPill} from 'App/utils/shipmentStatus';
import {parcelPickupStatusMap} from 'App/utils/parcelConstants';
import './styles.scss';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const Status = ({shipment, user, setError, className}) => {
  const {hasParcel} = checkShipmentModes(shipment.mode);
  const canEdit =
    (get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
    !hasParcel;

  const isOpen = get(shipment, 'metadata.open');
  const isDelayed = get(shipment, 'delayed');

  const handleError = (error) => {
    const errorMessages = unpackShipmentErrors(error, 'There was an error updating the shipment status.', [
      'state',
      'delayed',
      'metadata.open'
    ]);
    if (Array.isArray(errorMessages)) {
      const errorBlock = (
        <>
          <div className="flex flex-col gap-2 pb-4">
            {errorMessages.map((err, i) => (
              <div key={i}>
                <span>{err}</span>
              </div>
            ))}
          </div>
          <span>
            {pluralize('This', errorMessages.length)} {pluralize('error', errorMessages.length)} must be corrected
            before any new changes can be saved.
          </span>
        </>
      );
      setError('Error on shipment!', errorBlock, 'top-right', {delay: 10000});
    } else {
      setError('Error updating shipment status!', errorMessages, 'top-right');
    }
  };

  const getParcelStatus = (statusId) => {
    const hasCarrierSpecificOptions =
      !!shipment.fedex_specific_options || !!shipment.ups_specific_options || !!shipment.usps_specific_options;

    const hasParcelDispatchingWithoutQuote =
      hasCarrierSpecificOptions &&
      statusId === 'tendered' &&
      !!shipment?.tracking_number &&
      !!shipment?.capacity_provider_customer_reference_number;

    return statusId === 'carrier_confirmed' || hasParcelDispatchingWithoutQuote
      ? {label: 'Carrier Confirmed', className: 'status-dispatched'}
      : get(parcelPickupStatusMap, get(shipment, 'parcel_pickup_status'));
  };

  // get shipment status objects, which have status name plus other props for display
  const statusId = get(shipment, 'state');
  const shipmentStatusProps = hasParcel ? getParcelStatus(statusId) : get(shipmentStatusMap, statusId, {id: statusId});

  return (
    <div className={classnames('shipment__statusSelector', className)}>
      <div className="shipment__statusSelector-display">
        <StatusPill className="shipment__statusSelector-display-status" {...shipmentStatusProps} />
        {isOpen && <StatusPill className="shipment__statusSelector-display-status" {...STATUS_OPEN} />}
        {isDelayed && <StatusPill className="shipment__statusSelector-display-status" {...STATUS_DELAYED} />}
      </div>
      {canEdit && (
        <Dropdown
          className="status-edit"
          title=""
          icon={<SvgIcon name="Edit" />}
          variant="activeIcon"
          indicator={false}
        >
          {({onClick}) => <StatusEdit onCancel={onClick} onSubmit={onClick} onError={handleError} />}
        </Dropdown>
      )}
    </div>
  );
};

Status.propTypes = {
  shipment: PropTypes.shape({
    metadata: PropTypes.shape({
      open: PropTypes.bool
    }),
    delayed: PropTypes.bool,
    state: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  className: PropTypes.string
};

export default compose(
  withStatusToasts,
  connect((state) => ({
    shipment: state.shipmentdetails.one,
    user: state.userProfile.user
  }))
)(Status);
