import {Tooltip} from '@shipwell/shipwell-ui';
import moment from 'moment';

export const CreatedAtCell = ({createdAt, updatedAt}: {createdAt: string; updatedAt: string}) => (
  <Tooltip
    tooltipContent={
      <div>
        <div className="p-1 text-left text-sm">
          <div>Last Updated: {moment(updatedAt).format('ll')}</div>
          <div>Created: {moment(createdAt).format('ll')}</div>
        </div>
      </div>
    }
  >
    {moment(updatedAt).format('ll')}
  </Tooltip>
);
