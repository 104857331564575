import {
  ServicesApi,
  CreateStageTimelineEvent,
  CreateTrackingPoint,
  CreateTransload,
  CreateTerminalFee,
  CreateStorage,
  UpdateTransload,
  UpdateTerminalFee,
  UpdateStorage
} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';

// Services Api

const servicesApi = new ServicesApi(configuration);

export function createTransloadService(transloadPayload: CreateTransload) {
  return servicesApi.createTransloadServiceServicesTransloadPost({createTransload: transloadPayload});
}

export function createTerminalFeeService(terminalFeePayload: CreateTerminalFee) {
  return servicesApi.createTerminalFeeServiceServicesTerminalFeePost({createTerminalFee: terminalFeePayload});
}

export function createStorageService(storagePayload: CreateStorage) {
  return servicesApi.createStorageServiceServicesStoragePost({createStorage: storagePayload});
}

export function updateTransloadService({
  serviceId,
  servicePayload
}: {
  serviceId: string;
  servicePayload: UpdateTransload;
}) {
  return servicesApi.updateTransloadServiceServicesTransloadTransloadIdPut({
    transloadId: serviceId,
    updateTransload: servicePayload
  });
}

export function updateTerminalFeeService({
  serviceId,
  servicePayload
}: {
  serviceId: string;
  servicePayload: UpdateTerminalFee;
}) {
  return servicesApi.updateTerminalFeeServiceServicesTerminalFeeTerminalFeeIdPut({
    terminalFeeId: serviceId,
    updateTerminalFee: servicePayload
  });
}

export function updateStorageService({serviceId, servicePayload}: {serviceId: string; servicePayload: UpdateStorage}) {
  return servicesApi.updateStorageServiceServicesStorageStorageIdPut({
    storageId: serviceId,
    updateStorage: servicePayload
  });
}

export function getOceanTrackingServiceDocuments(serviceId: string) {
  return servicesApi.getOceanTrackingDocumentsServicesOceanTrackingOceanTrackingIdDocumentsGet({
    oceanTrackingId: serviceId
  });
}

export function getRailTrackingServiceDocuments(serviceId: string) {
  return servicesApi.getRailTrackingDocumentsServicesRailTrackingRailTrackingIdDocumentsGet({
    railTrackingId: serviceId
  });
}

export function createServiceTimelineEvent(serviceId: string, timelineEvent: CreateStageTimelineEvent) {
  return servicesApi.createServiceTimelineEventServicesServiceIdTimelineEventsPost({
    serviceId,
    createStageTimelineEvent: timelineEvent
  });
}

export function deleteServiceTimelineEvent(serviceId: string, eventId: string) {
  return servicesApi.deleteServiceTimelineEventServicesServiceIdTimelineEventsTimelineEventIdDelete({
    serviceId: serviceId,
    timelineEventId: eventId
  });
}

export function deleteOceanTrackingDocument(serviceId: string, documentId: string) {
  return servicesApi.deleteOceanTrackingDocumentServicesOceanTrackingOceanTrackingIdDocumentsDocumentIdDelete({
    oceanTrackingId: serviceId,
    documentId
  });
}

export function deleteRailTrackingDocument(serviceId: string, documentId: string) {
  return servicesApi.deleteRailTrackingDocumentServicesRailTrackingRailTrackingIdDocumentsDocumentIdDelete({
    railTrackingId: serviceId,
    documentId
  });
}

export function uploadOceanTrackingServiceDocument(serviceId: string, file: File, description: string, type: string) {
  return servicesApi.uploadOceanTrackingDocumentsServicesOceanTrackingOceanTrackingIdDocumentsPost({
    oceanTrackingId: serviceId,
    file,
    description,
    type
  });
}

export function uploadRailTrackingServiceDocument(serviceId: string, file: File, description: string, type: string) {
  return servicesApi.uploadRailTrackingDocumentsServicesRailTrackingRailTrackingIdDocumentsPost({
    railTrackingId: serviceId,
    file,
    description,
    type
  });
}

export function updateOceanTrackingServiceDocument(
  serviceId: string,
  documentId: string,
  description: string,
  type: string
) {
  return servicesApi.updateOceanTrackingDocumentServicesOceanTrackingOceanTrackingIdDocumentsDocumentIdPut({
    oceanTrackingId: serviceId,
    documentId,
    description,
    type
  });
}

export function updateRailTrackingServiceDocument(
  serviceId: string,
  documentId: string,
  description: string,
  type: string
) {
  return servicesApi.updateRailTrackingDocumentServicesRailTrackingRailTrackingIdDocumentsDocumentIdPut({
    railTrackingId: serviceId,
    documentId,
    description,
    type
  });
}

export function createServiceTrackingPoint(serviceId: string, createTrackingPoint: CreateTrackingPoint) {
  return servicesApi.createServiceTrackingPointServicesServiceIdTrackingPost({
    serviceId,
    createTrackingPoint: createTrackingPoint
  });
}

export function getTransloadService(serviceId: string) {
  return servicesApi.retrieveTransloadServiceServicesTransloadTransloadIdGet({transloadId: serviceId});
}

export function getStorageService(serviceId: string) {
  return servicesApi.retrieveStorageServiceServicesStorageStorageIdGet({storageId: serviceId});
}

export function getTerminalFeeService(serviceId: string) {
  return servicesApi.retrieveTerminalFeeServiceServicesTerminalFeeTerminalFeeIdGet({terminalFeeId: serviceId});
}
