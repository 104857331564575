import {
  Configuration,
  FreightInvoiceStatus,
  FreightInvoicesApi,
  FPMInvoiceRuleConfigurationsApi,
  FreightSettlementsConfigurationsApi,
  UpdateInvoiceRuleConfigurationStatus,
  UpdateFreightSettlementsConfigurationStatus,
  UpdateAddToDeliveredDashboardActionDetails,
  DocumentMetadata,
  FreightInvoicesApiListFreightInvoicesRequest,
  FreightInvoicesApiCreateFreightInvoiceDocumentRequest,
  ConfigDetails
} from '@shipwell/settlements-sdk';
import {getAccessToken} from 'App/api/utils';

//these are publicly available routes in the settlements service.
const basePath = process.env.SHIPWELL_SETTLEMENTS_API_BASE_PATH;

const freightInvoicesApi = new FreightInvoicesApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export function updateFreightInvoiceStatus(invoiceId: string, status: FreightInvoiceStatus) {
  return freightInvoicesApi.updateFreightInvoiceStatus({invoiceId, updateFreightInvoiceStatus: {status}});
}

export function getFreightInvoices(requestParameters: FreightInvoicesApiListFreightInvoicesRequest) {
  return freightInvoicesApi.listFreightInvoices(requestParameters);
}

export async function getFreightInvoiceDocuments(invoiceId: string) {
  return (await freightInvoicesApi.getFreightInvoiceDocuments({invoiceId})).data;
}

const invoiceRuleConfigurationsApi = new FPMInvoiceRuleConfigurationsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export async function getInvoiceRuleConfigurations() {
  return (await invoiceRuleConfigurationsApi.getInvoiceRuleConfigurations()).data;
}

export function updateInvoiceRuleConfigurationsActivationStatus(
  updateInvoiceRuleConfigurationStatus: UpdateInvoiceRuleConfigurationStatus[]
) {
  return invoiceRuleConfigurationsApi.updateInvoiceRuleConfigurationsActivationStatus({
    updateInvoiceRuleConfigurationStatus
  });
}

export function updateAmountToleranceLimitInvoiceRuleConfiguration(
  ruleConfigurationId: string,
  updateAmountToleranceLimitDetails: ConfigDetails
) {
  return invoiceRuleConfigurationsApi.updateInvoiceRuleConfiguration({
    ruleConfigurationId,
    configDetails: {...updateAmountToleranceLimitDetails}
  });
}

export function updateRequiredDocumentInvoiceRuleConfiguration(
  ruleConfigurationId: string,
  documentsRequiredDetails: ConfigDetails
) {
  return invoiceRuleConfigurationsApi.updateInvoiceRuleConfiguration({
    ruleConfigurationId,
    configDetails: {...documentsRequiredDetails}
  });
}

const freightSettlementsConfigurationsApi = new FreightSettlementsConfigurationsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const getSettlementsConfigurations = async ({
  bookingPartyId,
  page,
  limit
}: {
  bookingPartyId?: string | undefined;
  page?: number | undefined;
  limit?: number | undefined;
} = {}) => {
  return (await freightSettlementsConfigurationsApi.listFreightConfigurations({bookingPartyId, page, limit})).data;
};

export const bulkUpdateSettlementsConfigurationsStatus = async (
  updateFreightSettlementsConfigurationStatus: UpdateFreightSettlementsConfigurationStatus[]
) => {
  return (
    await freightSettlementsConfigurationsApi.bulkUpdateFreightConfigurationsStatus({
      updateFreightSettlementsConfigurationStatus
    })
  ).data;
};

export const updateAddToDeliveredDashboardConfiguration = (
  freightConfigurationId: string,
  updateSettlementsConfig: UpdateAddToDeliveredDashboardActionDetails
) => {
  return freightSettlementsConfigurationsApi.updateFreightConfiguration({
    freightConfigurationId,
    updateSettlementsConfig
  });
};

type Options = Parameters<FreightInvoicesApi['createFreightInvoiceDocument']>[1];

export async function createFreightInvoiceDocument({
  invoiceId,
  documents,
  documentsMetadata,
  options = {}
}: FreightInvoicesApiCreateFreightInvoiceDocumentRequest & {options?: Options}) {
  return freightInvoicesApi.createFreightInvoiceDocument(
    {invoiceId},
    //documents get added in the transformRequest transformer below
    //document metadata gets added in the transformRequest transformer below
    {
      transformRequest: [
        () => {
          //this route receive file(s), so the request is structured as formData
          const formData = new FormData();
          documents?.map((document) => formData.append('documents', document));
          if (documentsMetadata?.document_metadata && documentsMetadata.document_metadata.length > 0) {
            formData.append('documents_metadata', JSON.stringify(documentsMetadata));
          }
          return formData;
        }
      ],
      ...options
    }
  );
}

export const updateFreightInvoiceDocument = ({
  invoiceId,
  documentId,
  documentMetadata
}: {
  invoiceId: string;
  documentId: string;
  documentMetadata: DocumentMetadata;
}) => freightInvoicesApi.updateFreightInvoiceDocument({invoiceId, documentId, documentMetadata});

export const updateAutoGenerateInvoiceConfiguration = ({
  id,
  business_days_after_delivery,
  start_date
}: {
  id: string;
  business_days_after_delivery: number;
  start_date: string | undefined;
}) => {
  return freightSettlementsConfigurationsApi.updateFreightConfiguration({
    freightConfigurationId: id,
    updateSettlementsConfig: {
      rule_type: 'AUTO_GENERATE_INVOICE',
      business_days_after_delivery,
      start_date
    }
  });
};

export const updateAutoDisputeInvoiceConfiguration = ({
  id,
  invoice_amount,
  missing_required_document,
  multiple_invoices_per_invoiceable,
  service_provider_name_mismatch,
  shipment_not_delivered
}: {
  id: string;
  invoice_amount: boolean;
  multiple_invoices_per_invoiceable: boolean;
  service_provider_name_mismatch: boolean;
  shipment_not_delivered: boolean;
  missing_required_document: boolean;
}) => {
  return freightSettlementsConfigurationsApi.updateFreightConfiguration({
    freightConfigurationId: id,
    updateSettlementsConfig: {
      rule_type: 'AUTO_DISPUTE_INVOICE',
      invoice_amount,
      missing_required_document,
      multiple_invoices_per_invoiceable,
      service_provider_name_mismatch,
      shipment_not_delivered
    }
  });
};
