import {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone';
import {Field, change, getFormValues} from 'redux-form';
import {get} from 'lodash';
import Grid from '@material-ui/core/Grid';
import {scheduleTimeOptions, startTimeWindowLabels, endTimeWindowLabels} from './utils/constants';
import SelectField from 'App/formComponents/fields/select';
import TimePickerField from 'App/formComponents/fields/timePicker';
import DateTimeField from 'App/formComponents/fields/dateTimePicker';
import {fetchAppointmentTypes} from 'App/actions/shipments';
import {hasPlanningWindownFlag} from 'App/utils/globals';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {compose} from 'recompose';

/**
 * Field for setting appointment time on shipment stop
 */
class ShipmentStopAppointment extends Component {
  constructor() {
    super();

    this.state = {
      scheduleType: 2,
      shipmentStop: {}
    };
  }

  static defaultProps = {
    modes: {
      hasFTL: false
    },
    slimForm: false,
    detailsForm: false,
    timezone: moment.tz.guess(true)
  };

  componentDidMount() {
    const {appointmentTypes, company} = this.props;

    if (!appointmentTypes.length && company.id) {
      this.getAppointmentTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const {company, appointmentTypes} = this.props;

    if (!appointmentTypes.length && company.id && company !== prevProps.company) {
      this.getAppointmentTypes();
    }
  }

  /**
   * Request appointment types
   * @return {Promise}
   */
  async getAppointmentTypes() {
    try {
      return await this.props.dispatch(fetchAppointmentTypes());
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Update schedule time toggle field depending on appointment type selection
   * @param  {String} stopItem       Select field name
   * @param  {Number} event          Value of selected item
   */
  handleAppointmentTypeChange(e, value, prevValue, field) {
    const {form, name} = this.props;
    const isByAppointmentType = value?.code === 'BY_APPT';
    const scheduleToggleValue = isByAppointmentType ? 1 : 2;

    this.setState({scheduleType: scheduleToggleValue});
    this.props.dispatch(change(form, `${name}.schedule-select`, scheduleToggleValue));
  }

  /**
   * Update schedule time value
   * @param  {Object} options.id Select value id
   */
  handleScheduledTimeChange(e, value) {
    const {form, name, formValues} = this.props;
    const startTime = '8:00';
    const endTime = '18:00';
    const schedType = parseInt(value);

    this.setState({scheduleType: value});
    //if newly selected scheduledType lacks time then use default values
    if ([1, 2, 4].includes(schedType) && !get(formValues, name)?.planned_time_window_start) {
      this.props.dispatch(change(form, `${name}.planned_time_window_start`, startTime));
    }
    if (
      [2, 3].includes(schedType) &&
      (!get(formValues, name)?.planned_time_window_end ||
        get(formValues, name)?.planned_time_window_start == get(formValues, name)?.planned_time_window_end)
    ) {
      this.props.dispatch(change(form, `${name}.planned_time_window_end`, endTime));
    }
  }

  render() {
    const scheduleType = this.props.toggle || 2;
    const {appointmentTypes, slimForm, modes} = this.props;
    const {hasFTL, hasLTL, hasVLTL, hasDrayage} = modes;

    const showWindowStart = new Set([1, 2, 4, '1', '2', '4']).has(scheduleType);
    const showWindowEnd = new Set([2, 3, '2', '3']).has(scheduleType);

    return (
      <Grid container spacing={2}>
        {(hasFTL || hasLTL || hasVLTL || hasDrayage) && (
          <>
            <div className="shipment-stop-subheader">
              <span>Appointment</span>
            </div>
            <Grid item xs={12}>
              <Field
                name="appointment_type"
                component={SelectField}
                label="Appointment Type"
                placeholder="Select Appointment Type"
                options={appointmentTypes}
                onChange={this.handleAppointmentTypeChange.bind(this)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                simpleValue
                label="Scheduled for Time"
                name="schedule-select"
                options={scheduleTimeOptions}
                component={SelectField}
                onChange={this.handleScheduledTimeChange.bind(this)}
              />
            </Grid>
            {!hasPlanningWindownFlag(this.props.flags) && (
              <Grid item xs={12} md={6}>
                {showWindowStart && (
                  <Field
                    req={!slimForm && !hasDrayage}
                    name="planned_time_window_start"
                    label={startTimeWindowLabels[scheduleType]}
                    component={TimePickerField}
                  />
                )}
                {showWindowEnd && (
                  <Field
                    req={!slimForm && !hasDrayage}
                    name="planned_time_window_end"
                    label={endTimeWindowLabels[scheduleType]}
                    component={TimePickerField}
                  />
                )}
              </Grid>
            )}
            {hasPlanningWindownFlag(this.props.flags) && (
              <Grid item xs={12} md={6}>
                {showWindowStart && (
                  <Field
                    req={!slimForm && !hasDrayage}
                    name="planning_window.start"
                    label={startTimeWindowLabels[scheduleType]}
                    time
                    component={DateTimeField}
                  />
                )}
                {showWindowEnd && (
                  <Field
                    req={!slimForm && !hasDrayage}
                    name="planning_window.end"
                    label={endTimeWindowLabels[scheduleType]}
                    time
                    component={DateTimeField}
                  />
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }
}

export default compose(
  withLDConsumer(),
  connect((state, props) => ({
    company: state.auth.company,
    appointmentTypes: state.shipments.appointmentTypes,
    formValues: getFormValues(props.form)(state)
  }))
)(ShipmentStopAppointment);
