import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {
  ShippingDashboardApiShipwellUiShippingDashboardGetRequest,
  PaginatedShippingDashboardRow,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {fetchShippingDashboard} from 'App/api/shipment/typed';
import {SHIPMENTS_DASHBOARD_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

export type UseShipmentDashboardQueryOptions = Omit<
  UseQueryOptions<PaginatedShippingDashboardRow, AxiosError<ShipwellError>, PaginatedShippingDashboardRow, string[]>,
  'queryFn' | 'queryKey'
>;

const useShipmentDashboardQuery = (
  shipmentDashBoardRequest: ShippingDashboardApiShipwellUiShippingDashboardGetRequest,
  options?: UseShipmentDashboardQueryOptions
) => {
  const cleanedRequest = omitEmptyKeysWithEmptyObjectsRemoved(shipmentDashBoardRequest);
  const objectValues: string[] = Object.entries(cleanedRequest).map(
    ([key, value]: [string, string | string[]]) => `${key}: ${value.toString()}`
  );
  const query = useQuery(
    [SHIPMENTS_DASHBOARD_QUERY_KEY, ...objectValues],
    async () => {
      const {data} = await fetchShippingDashboard(cleanedRequest);
      return data;
    },
    options
  );

  return query;
};

export default useShipmentDashboardQuery;
