import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {OverlayTrigger, Tooltip, Button, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router';
import * as actions from '../../actions/shipments';
import {removeCommasAndDollarSign} from 'App/utils/globals';
import './_message-preview.scss';

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    company: state.auth.company,
    spotNegotiationEmailPreview: state.marketplace.spotNegotiationEmailPreview
  }),
  actions
)
export default class MessagePreview extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.state = {};
  }

  render() {
    const {spotNegotiationEmailPreview, message, total, loadId, specialInstructions} = this.props;
    let emailToShow = '';
    if (message && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
      emailToShow = spotNegotiationEmailPreview.template.replace('{{ message }}', message);
    } else if (spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
      emailToShow = spotNegotiationEmailPreview.template;
    }

    if (total && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
      emailToShow = emailToShow.replace('{{ total_amount }}', '$' + total);
    }

    if (loadId && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
      emailToShow = emailToShow.replace('{{ load_id }}', loadId);
    }

    if (specialInstructions && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
      emailToShow = emailToShow.replace('{{ special_instructions }}', specialInstructions);
    } else {
      emailToShow = emailToShow.replace('{{ special_instructions }}', 'None');
    }

    //remove links from buttons
    emailToShow = emailToShow.replace(/{{ url }}/g, '#');
    emailToShow = emailToShow.replace(/target="_blank"/g, '');
    return (
      <div className="message-preview">
        {spotNegotiationEmailPreview && (
          <div
            dangerouslySetInnerHTML={{
              __html: emailToShow
            }}
          />
        )}
      </div>
    );
  }
}
