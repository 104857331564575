import _, {debounce} from 'lodash';
import get from 'lodash/get';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import {InputGroup, Button as BootstrapButton, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import {SubmissionError} from 'redux-form';
import ReactTable from 'react-table-6';
import Textarea from 'react-textarea-autosize';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from '@material-ui/core/styles';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import {compose, renameProp} from 'recompose';
import pluralize from 'pluralize';
import queryString from 'query-string';
import {Modal, Button, Toast, SvgIcon, Dropdown, Tooltip, Loader} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum, ShipmentDocumentMetadataTypeEnum} from '@shipwell/backend-core-sdk';
import columns from './components/Columns';
import {getDocumentTitle} from './components/ShipmentDocument/utils/utils';
import {ShipmentsDownloadCsv} from './components/ShipmentsCsvDownload';
import BulkTenderRequestForm from 'App/containers/Dashboard/components/BulkActions/bulkTenderRequest';
import withFlags from 'App/utils/withFlags';
import {ALERT_ERROR} from 'App/actions/types';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import * as carrierActions from 'App/actions/vendors';
import * as userActions from 'App/actions/users';
import * as integrationActions from 'App/actions/integrations';
import {fetchShipmentStatusesPromise, createShipmentPickup, updateShipmentPickup} from 'App/api/shipment';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import TableFilters from 'App/components/TableFiltersWithSavedViews';
import Notes from 'App/components/Notes';
import {unpackErrors, permViewInvoice, formatDateTime, permViewCarriers, cleanPayload} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {parseTableFilters, parseFiltersForReadback} from 'App/utils/shipmentTableHelpers';
import ShareLinkForm from 'App/containers/Shipment/forms/ShareLinkForm';
import LegacyDashboardSummary from 'App/components/dashboardSummary';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {FILTERABLE_DASHBOARD_COLUMNS} from 'App/reducers/userPreferences/dashboardConfig';
import filterMapping, {dashboardStatuses} from 'App/containers/Dashboard/components/ColumnFilterMapping';
import SchedulePickupForm from 'App/formComponents/forms/schedulePickup';
import EditShipmentDates from 'App/formComponents/forms/editShipmentDates';
import ChangeShipmentStatus from 'App/formComponents/forms/changeShipmentStatus';
import TenderRequestForm from 'App/containers/tendering/create';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import CarrierBidCreateContainer from 'App/containers/carrierBids/create';
import SchedulePickup from 'App/components/schedulePickup';
import {getPickupValues, parcelPickupStatuses} from 'App/utils/parcelConstants';
import {SOURCES} from 'App/utils/createdBy';
import {getShipwellUiUserPreferencesPromise, editShipwellUiUserPreferencesPromise} from 'App/api/shipwellUI';
import buildPathParams from 'App/utils/buildPathParams';
import CreateImport from 'App/components/imports/create';
import {ROUTING_GUIDE_SUCCESS_ON_EXECUTION} from 'App/containers/workflows/workflowConstants';
import {SelectedCount} from 'App/components/SelectedCount';
import {getBulkOperation, createBulkOperation} from 'App/api/bulkOperations';
import WithStatusToasts from 'App/components/withStatusToasts';
import {
  bulkOperationTypeMessages,
  BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE,
  BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES,
  BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS,
  BULK_OPERATION_TYPE_CHECK_CALL,
  BULK_OPERATION_TYPE_TENDER_TO_CARRIER,
  BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT,
  V3_VERSION,
  PRINT_DOCUMENT,
  EMAIL_DOCUMENT,
  LOCALSTORAGE_DASHBOARD_COLUMNS
} from 'App/containers/Dashboard/utils/constants';
import ShipmentDocument from 'App/containers/Dashboard/components/ShipmentDocument';
import CheckCall from 'App/containers/Dashboard/components/checkCall';
import 'react-table-6/react-table.css';
import './_dashboard.scss';
import {CARRIERS, DETAILS, TIMELINE, WORKFLOWS, STAGES, CARRIER_BIDS} from 'App/utils/dashboardConstants';
import {INTERMODAL_FILTER_OPTION} from 'App/utils/shipmentModeFilterOptions';
import bulkSelect from 'App/components/tableColumns/bulkSelect';
import DashboardSummary from 'App/containers/shipments/components/DashboardSummary';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {
  mapCustomReferenceFieldsToDashboardColumnNames,
  mapCustomReferenceFieldsToDashboardColumns
} from 'App/containers/Dashboard/utils/DashboardUtils';
import {BulkActionLineItemButton} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton';
import {
  CurrencyOfRecordTooltipContent,
  NonParcelShipmentsTooltipContent,
  ParcelShipmentsTooltipContent
} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton/TooltipContent';
import {isSamePreferredCurrency} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton/utils';
import {TryItNowTag} from 'App/containers/Dashboard/components/TryItNowTag';
import {FlexBox} from 'App/components/Box';
import {getShipment} from 'App/api/shipment/typed';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {
  CREATE_SHIPMENTS_USER_PERMISSION,
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_INITIATE_ROUTING_GUIDE,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {useShipmentsRoundTrips} from 'App/data-hooks/shipments/useShipmentsRoundTrips';
import {DASHBOARD_LOCAL_STORAGE_KEY} from 'App/components/TypedTable/tableUtils';

//can use this component to use more hooks with the bulk actions menu
const BulkActionsHookProviderBase = ({
  setSuccess,
  setError,
  setWarning,
  setLoadingMessage,
  legacyGetShipments,
  children
}) => {
  const {roundTripsMutation} = useShipmentsRoundTrips({
    setSuccess,
    setError,
    setWarning,
    setLoadingMessage,
    legacyGetShipments
  });
  return children({roundTripsMutation});
};
const BulkActionsHookProvider = WithStatusToasts(BulkActionsHookProviderBase);

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const drawerWidth = '500px';
const styles = (theme) => ({
  legacyDrawer: {
    flexShrink: 0,
    zIndex: 1
  },
  legacyDrawerContent: {
    position: 'absolute',
    maxWidth: drawerWidth,
    width: '100%',
    border: 0,
    top: '0px',
    height: 'calc(100vh - 67px)',
    background: 'transparent',
    transition: 'transform 0.1s'
  },
  dashboardOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `calc(100% - ${drawerWidth})`
  },
  dashboardClosed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: '100%'
  }
});

const initialPagination = {
  page: 1,
  pageSize: 10,
  ordering: ['-pickup']
};

const columnSortingMapping = {
  equipment_type: 'equipment',
  created_at: 'createdAt',
  pickup: 'pickup_date',
  dropoff: 'delivery_date',
  drayage_container_number: 'container_number',
  drayage_seal_number: 'seal_number',
  drayage_estimated_arrival_date: 'estimated_arrival_date',
  drayage_release_date: 'release_date',
  drayage_last_free_date: 'last_free_date',
  drayage_container_return_date: 'container_return_date',
  workflow_execution_status: 'workflow_execution_info'
};

const parcelFilterMapping = parcelPickupStatuses.reduce((parcelFilters, status) => {
  const {dashboardFilter, parcel_pickup_status} = status;
  return {...parcelFilters, [dashboardFilter]: parcel_pickup_status};
}, {});

let bulkOperationInterval;
const multiStageIdColumn = {
  Header: 'Multi-Stage ID',
  id: 'multi_stage_id',
  accessor: (d) => d.v3_shipment_reference_id || '--'
};
const numberOfStagesColumn = {
  Header: 'Stages',
  id: 'number_of_stages',
  accessor: (d) => d.number_of_stages || '--'
};

export class Dashboard extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    //parse localStorage for user's previous column settings
    this.localStorageColumns = JSON.parse(localStorage.getItem(LOCALSTORAGE_DASHBOARD_COLUMNS))?.map(
      (column) => column.id
    );
    //use localStorage columns if available
    this.defaultColumns = this.localStorageColumns?.length > 0 ? this.localStorageColumns : props.defaultColumns;
    this.getShipments = this.getShipments.bind(this);
    this.goToManualQuote = this.goToManualQuote.bind(this);
    this.onNoteSubmit = this.onNoteSubmit.bind(this);
    this.handleNoteInputChange = this.handleNoteInputChange.bind(this);
    this.editNote = this.editNote.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
    this.shareLink = this.shareLink.bind(this);
    this.renderShareLinkModal = this.renderShareLinkModal.bind(this);
    this.handleShareLink = this.handleShareLink.bind(this);
    this.renderCancelModal = this.renderCancelModal.bind(this);
    this.handleCancelReasonChange = this.handleCancelReasonChange.bind(this);
    this.cancelShipment = this.cancelShipment.bind(this);
    this.loadMoreShipments = this.loadMoreShipments.bind(this);
    this.fetchData = _.debounce(this.fetchData.bind(this), 300, {
      trailing: true,
      leading: false
    });
    this.searchDataTable = this.searchDataTable.bind(this);
    this.getMoreNotes = this.getMoreNotes.bind(this);
    this.setParentState = this.setParentState.bind(this);
    this.parseFilters = this.parseFilters.bind(this);
    this.cancelBulkShipments = this.cancelBulkShipments.bind(this);
    this.archiveBulkShipments = this.archiveBulkShipments.bind(this);

    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    //this column is tightly coupled with container state, so we declare it here 🥴
    this.notesColumn = {
      Header: 'Notes',
      sortable: false,
      minWidth: 75,
      id: 'notes',
      accessor: (d) => d.notes_count,
      Cell: (row) => {
        const isV3 = row.original.version === V3_VERSION;
        const myNote = localStorage.getItem(row.original.id)
          ? JSON.parse(localStorage.getItem(row.original.id))
          : this.props.notes;
        return !isV3 ? (
          <div
            onMouseEnter={function () {
              if (!localStorage.getItem(row.original.id)) {
                this.props.getNotes(row.original.id).then((response) => {
                  if (response.status === 200) {
                    localStorage.setItem(row.original.id, JSON.stringify(this.props.notes));
                    const cacheArray = localStorage.getItem('cacheArray')
                      ? JSON.parse(localStorage.getItem('cacheArray'))
                      : [];
                    cacheArray.push(row.original.id);
                    localStorage.setItem('cacheArray', JSON.stringify(cacheArray));
                  }
                });
              }
            }.bind(this)}
            className="notes-cell"
          >
            <Tooltip
              placement="top"
              portal
              tooltipContent={
                this.props.areNotesLoading ? (
                  <div style={{textAlign: 'center'}}>Loading...</div>
                ) : !this.props.areNotesLoading && myNote && myNote.results.length > 0 ? (
                  <div className="notes-container">
                    <h2>Internal Notes</h2>
                    {myNote &&
                      myNote.results.map((el, i) => {
                        if (i < 5) {
                          return (
                            <div key={el.id} className={'note' + (el.is_pinned ? ' pinned' : '')}>
                              <div className="message">
                                <span className="creator">
                                  <strong>{el.creator_full_name} </strong>
                                </span>
                                <span className="date">
                                  {formatDateTime(el.updated_at, true, moment.tz.guess())}
                                  &nbsp;&nbsp;
                                </span>
                                <span>{el.message.length > 50 ? el.message.slice(0, 49) + '...' : el.message}</span>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                ) : (
                  <div>Click to Add Note</div>
                )
              }
            >
              <a
                onClick={() => {
                  this.props.getNotes(row.original.id, this.state.notesPagination).then((response) => {
                    if (response.status === 200) {
                      this.setState({
                        showNotesModal: true,
                        selectedShipment: row.original
                      });
                    }
                  });
                }}
              >
                <SvgIcon name={row.value ? 'DocumentBlank' : 'AddCircleOutlined'} color="$grey-5" />
              </a>
            </Tooltip>
          </div>
        ) : (
          <SvgIcon name="AddCircleOutlined" color="sw-disabled" />
        );
      }
    };
    this.columns = {
      reference_id: columns.reference_id,
      status: columns.status,
      reps: columns.reps,
      bol: columns.bol,
      pickup_number: columns.pickup_number,
      pro: columns.pro,
      po: columns.po,
      container_number: columns.container_number,
      service_level: columns.service_level,
      origin_company: columns.origin_company,
      destination_company: columns.destination_company,
      timeline_last_updated_at: columns.timeline_last_updated_at,
      total_miles: columns.total_miles,
      vendor: columns.vendor,
      customer: columns.customer,
      customer_reference_number: columns.customer_reference_number,
      markup: columns.markup,
      max_buy_amount: columns.max_buy_amount,
      pickup_location: columns.pickup_location,
      pickup_date: columns.pickup_date,
      delivery_location: columns.delivery_location,
      delivery_date: columns.delivery_date,
      stops: columns.stops,
      account_id: columns.account_id,
      mode: columns.mode,
      equipment: columns.equipment,
      line_items: columns.line_items,
      weight: columns.weight,
      financials: columns.financials,
      customer_financials: columns.customer_financials,
      provider_financials: columns.provider_financials,
      net_financials: columns.net_financials,
      load_board_id: columns.load_board_id,
      buy_it_now_amount: columns.buy_it_now_amount,
      target_rate_amount: columns.target_rate_amount,
      notes: this.notesColumn,
      createdAt: columns.createdAt,
      name: columns.name,
      carrier_status: columns.carrier_status,
      created_by: columns.created_by,
      accessorials: columns.accessorials,
      actions: columns.actions,
      trailer_name: columns.trailer_name,
      upcoming_etas: columns.upcoming_etas,
      workflow_execution_info: columns.workflow_execution_info,
      seal_number: columns.seal_number,
      house_bol_number: columns.house_bol_number,
      estimated_arrival_date: columns.estimated_arrival_date,
      release_date: columns.release_date,
      last_free_date: columns.last_free_date,
      container_return_date: columns.container_return_date,
      rail_car_status: columns.rail_car_status,
      rail_car_number: columns.rail_car_number,
      power_unit_name: columns.power_unit_name
    };

    this.state = {
      temporaryColumnOverride: null,
      pagination: {
        page: 1,
        pageSize: props.location.query.pageSize || 10, // SHIP-11980 override default page size metadata temporarily
        ordering: ['-pickup']
      },
      notesPagination: {
        page: 1,
        pageSize: 20
      },
      newNote: '',
      editNote: '',
      showNotesModal: false,
      selectedShipment: null,
      selectedNoteIdx: null,
      currentStatusRadio: 'all',
      filters: [],
      showSharelinkModal: false,
      showSharelinkTitle: '',
      emailList: [],
      showArchiveModal: false,
      showCancelModal: false,
      cancellationReason: '',
      disableCancel: true,
      selectedReference: 'BOL',
      searchValue: queryString.parse(window.location.search)?.q || '',
      refValue: '',
      activeView: 'list',
      equipmentConfig: {},
      isBroker: false,
      canViewCustomers: false,
      showTagsFilter: false,
      showDashboardSettings: false,
      initialTags: [],
      is_quoting_limited_user: false,
      shipmentDetail: null,
      showShipmentDetail: false,
      shipmentDetailLoading: false,
      shipmentPOC: null,
      gettingShipments: false,
      defaultSelectedFilter: null,
      shouldLoadView: this.props.location?.query.view,
      awaitingInitialLoad: true,
      filterMappingLoading: false,
      filterMapping,
      showSchedulePickupModal: false,
      showCreatePickupModal: false,
      selectedShipments: [],
      selectedShipmentsById: {},
      isTender: false,
      showRoutingGuideModal: false,
      showEditShipmentDatesModal: false,
      showChangeShipmentStatusModal: false,
      showRoutingGuideInitiatedModal: false,
      showCarrierBidForm: false,
      showCarrierBidSuccess: false,
      tableRowDefaults: {},
      showImportShipmentsModal: false,
      showShipmentsImportSuccessToast: false,
      showImportFullScreen: false,
      performingBulkAction: false,
      dashboardDocumentModal: {showModal: false, mode: '', documentType: ''},
      showV3ShipmentDetail: false,
      selectedV3Shipment: null,
      loadingMessage: null
    };
    this._count = 1;
  }

  loadMoreShipments() {
    const {pagination} = this.state;
    pagination.page += 1;
    if (pagination.page * pagination.pageSize < this.props.shipments.total_count) {
      this.setState({gettingShipments: true}, () => {
        this.getShipmentsResource(pagination, null, true).then((response) => {
          this.setState({gettingShipments: false});
        });
      });
    }
  }

  componentDidMount() {
    localStorage.setItem(DASHBOARD_LOCAL_STORAGE_KEY, window.location.search.substring(1));
    this.props.resetShipments();
    this.handleAsyncFilterMapping();
    this.getUserPageSize().then((pageSize) => {
      if (!_.isEmpty(this.props.company)) {
        if (this.props.company.shipper && !this.props.company.brokerage && !this.props.company.carrier) {
          this.setState({isShipper: true});
        } else if (this.props.company.brokerage) {
          this.setState(
            {isBroker: true},
            function () {
              if (
                this.props.user &&
                this.props.user.permissions &&
                this.props.user.permissions.includes('customer_relationships.view')
              ) {
                this.setState({canViewCustomers: true});
              }
            }.bind(this)
          );
        }

        this.props.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'});
        if (
          this.props.user &&
          this.props.user.permissions &&
          this.props.user.permissions.includes('users.view_company_users')
        ) {
          this.props.getCompanyUsers(this.props.company.id, {pageSize: 1000}); //used in filters
        }
        this.props.fetchTags(); //used in filters
        this.props.fetchEquipmentTypes(); //used in filters
        this.props.fetchServiceLevels(); //used in filters
      }

      this.props.clearSelectedShipment();

      if (this.props.user && this.props.user.is_quoting_limited_user) {
        this.setState({is_quoting_limited_user: true});
      }
      if (
        this.props.dashboardConfig &&
        this.props.dashboardConfig.configs &&
        this.props.dashboardConfig.configs.length
      ) {
        if (this.state.shouldLoadView) {
          //when the URL contains a view id in it
          this.handleFilterSelection(this.state.shouldLoadView);
          this.setState({shouldLoadView: null, hasLoadedInitial: true});
        }
      }
      //fetch accessorial codes for accessorials typeahead filter
      if (get(this.props, 'shipmentAccessorials', []).length === 0) {
        this.props.fetchAccessorialCodes();
      }
    });
    this.addConditionalColumns();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && this.props.company !== nextProps.company) {
      if (nextProps.company.shipper && !nextProps.company.brokerage && !nextProps.company.carrier) {
        this.setState({isShipper: true});
      } else if (nextProps.company.brokerage) {
        this.setState(
          {isBroker: true},
          function () {
            if (
              nextProps.user &&
              nextProps.user.permissions &&
              nextProps.user.permissions.includes('customer_relationships.view')
            ) {
              this.setState({canViewCustomers: true});
            }
          }.bind(this)
        );
      }

      this.props.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'});
      if (
        nextProps.user &&
        nextProps.user.permissions &&
        nextProps.user.permissions.includes('users.view_company_users')
      ) {
        this.props.getCompanyUsers(nextProps.company.id, {pageSize: 1000}); //used in filters
      }
      this.props.fetchTags(); //used in filters
      this.props.fetchEquipmentTypes(); //used in filters
      this.props.fetchServiceLevels(); //used in filtersthis.

      if (nextProps.user && nextProps.user.is_quoting_limited_user) {
        this.setState({is_quoting_limited_user: true});
      }
    }
  }

  componentWillMount() {
    this.timer = null;
  }

  componentWillUnmount() {
    this.props.clearShipmentState();

    this._count = 0;
    const cacheArray = localStorage.getItem('cacheArray') ? JSON.parse(localStorage.getItem('cacheArray')) : [];
    for (let i = 0; i < cacheArray.length; i++) {
      localStorage.removeItem(cacheArray[i]);
    }
    localStorage.removeItem('cacheArray');

    clearInterval(bulkOperationInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    const {dashboardConfig, defaultConfig} = this.props;
    if (
      prevProps.dashboardConfig !== this.props.dashboardConfig &&
      this.props.dashboardConfig.configs.length &&
      (!this.state.hasLoadedInitial || !this.state.shouldLoadView)
    ) {
      if (this.state.shouldLoadView) {
        if (
          this.state.shouldLoadView &&
          this.props.dashboardConfig.configs.filter((e) => e.id === this.state.shouldLoadView).length > 0
        ) {
          //there is a view in the URL and it matches one of the user's configs, load that
          this.handleFilterSelection(this.state.shouldLoadView);
          this.setState({shouldLoadView: null, hasLoadedInitial: true});
        } else {
          //no user view to match this, but check for other query params
          if (!_.isEmpty(this.props.location.query)) {
            this.handleLoadQueryParams();
          }
        }
      } else if (!this.state.shouldLoadView && !defaultConfig) {
        if (!_.isEmpty(this.props.location.query)) {
          this.handleLoadQueryParams();
        } else {
          //no default to load, just load the shipwell default
          this.handleFilterSelection(null);
        }
      } else if (defaultConfig && _.isEmpty(this.props.location.query)) {
        this.handleFilterSelection(defaultConfig.id);
        this.setState({hasLoadedInitial: true});
      } else if (defaultConfig && !_.isEmpty(this.props.location.query)) {
        this.handleLoadQueryParams();
      }
    } else if (
      prevProps.dashboardConfig !== this.props.dashboardConfig &&
      !this.props.dashboardConfig.configs.length &&
      !this.props.dashboardConfig.isFetching &&
      !this.state.shouldLoadView &&
      !defaultConfig
    ) {
      if (!this.state.hasLoadedInitial && !_.isEmpty(this.props.location.query)) {
        this.handleLoadQueryParams();
      } else {
        //just load the shipwell default since there are no configs to load
        this.handleFilterSelection(null);
      }
    }
    if (
      defaultConfig &&
      defaultConfig !== prevProps.defaultConfig &&
      !this.state.shouldLoadView &&
      !this.state.hasLoadedInitial
    ) {
      if (!_.isEmpty(this.props.location.query)) {
        this.handleLoadQueryParams();
      } else {
        //load the default config immediately if it changes
        this.handleFilterSelection(defaultConfig.id);
        this.setState({hasLoadedInitial: true});
      }
    }

    //if the selected shipment changes, fetch the timeline
    if (prevState.selectedShipment !== this.state.selectedShipment && this.state.selectedShipment) {
      this.props.getShipmentTimeline(this.state.selectedShipment.id, {
        page: 1,
        pageSize: 1000000
      });
    }

    //refetch shipments if the reload query appears in the URL
    if (!prevProps.location?.query?.reload && this.props.location?.query.reload) {
      this.getShipments();
      this.context.router.push(buildPathParams(this.props.location, {reload: undefined}));
    }

    this.addConditionalColumns();
  }

  addConditionalColumns = () => {
    const customFieldColumns = mapCustomReferenceFieldsToDashboardColumns(this.props.referencesCustomFields);
    this.columns = {
      ...this.columns,
      ...(this.props.stmDashboardColumnsCustomReferenceFields ? customFieldColumns : {}),
      ...(this.props.modeMultiModeDashboard
        ? {multi_stage_id: multiStageIdColumn, number_of_stages: numberOfStagesColumn}
        : {}),
      ...(this.props.modeShippingDashboard ? {tender_status: columns.tender_status} : {}),
      ...(this.props.ciAuctionStats ? {auction_stats: columns.auction_stats, lowest_bid: columns.lowest_bid} : null)
    };
  };

  handleLoadQueryParams() {
    //we have parameters to search with but not a selected filter
    const queryParams = this.props.location.query;
    Object.keys(queryParams).forEach((key) => {
      if (typeof queryParams[key] === 'string' && key !== 'view') {
        queryParams[key] = [queryParams[key]];
      }
      //special handling for customer and vendor which use IDs
      if (key === 'customer') {
        const ids = queryParams.customerId;
        queryParams.customer = queryParams.customer.map((e, i) => {
          return {label: e, value: ids[i]};
        });
      }
      if (key === 'vendor') {
        queryParams.vendor = [{label: queryParams.vendor, value: queryParams.vendorId}];
      }
    });

    // map labels with their respective ids
    if (queryParams.createdBy) {
      queryParams.createdBy = queryParams.createdBy.map((userLabel, i) => ({
        label: userLabel,
        value: queryParams.createdByUserId[i]
      }));
    }
    if (queryParams.createdBySource) {
      const mappedSources = queryParams.createdBySource.map((source) => ({label: SOURCES[source], value: source}));
      queryParams.createdBy = queryParams.createdBy ? [...queryParams.createdBy, ...mappedSources] : mappedSources;
    }

    const pagination = {
      ...initialPagination,
      pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize,
      ...queryParams
    };
    this.setState({pagination, allFiltersSelected: queryParams, awaitingInitialLoad: false}, () => {
      // normally, `handleLoadQueryParams' is called because there's no filter to load
      // however, in the case of coming back to this component e.g. from the details page, there is a
      // chance that there is a view that is loaded. In that case, don't revert to the default
      // columns because the view's columns should already be in place.
      if (!queryParams.view) {
        this.updateColumns(this.defaultColumns);
      }
      this.getShipments();
      this.handleUpdateQueryString(pagination);
    });
  }

  getUserTableConfiguration() {
    return userActions.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'});
  }

  handleNoteInputChange(event) {
    const target = event.target;
    const value = target.value;
    const obj = {};
    obj[target.name] = value;
    this.setState(obj);
  }

  getMoreNotes() {
    const notesPagination = this.state.notesPagination;
    notesPagination.pageSize += 10;
    this.setState(
      {notesPagination},
      function () {
        this.props.getNotes(this.state.selectedShipment.id, this.state.notesPagination);
      }.bind(this)
    );
  }

  onNoteSubmit() {
    const newNote = this.state.newNote;
    if (newNote.length >= 1 && newNote.replace(/\s/g, '').length) {
      this.props
        .postNote(this.state.selectedShipment.id, {
          message: this.state.newNote
        })
        .then(
          function (response) {
            if (response.status === 200) {
              this.props.getNotes(this.state.selectedShipment.id, this.state.notesPagination);
              this.setState({newNote: ''});
              this.getShipmentsResource(this.state.pagination, this._count);
              this._count++;
            } else {
              this.setState({errorDetails: response, newNote: ''});
            }
          }.bind(this)
        );
    } else {
      return false;
    }
  }

  deleteNote(noteId) {
    this.props.deleteNote(this.state.selectedShipment.id, noteId).then((response) => {
      if (response.status === 200) {
        this.props.getNotes(this.state.selectedShipment.id, this.state.notesPagination);
      }
    });
  }

  editNote(note) {
    this.setState({selectedNoteIdx: note.id, editNote: note.message});
  }

  submitEdit(noteId) {
    if (this.state.editNote) {
      this.props
        .putNote(this.state.selectedShipment.id, noteId, {
          message: this.state.editNote
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState({editNote: '', selectedNoteIdx: null});
            this.props.getNotes(this.state.selectedShipment.id, this.state.notesPagination);
          }
        });
    }
  }

  getShipments() {
    const pagination = {
      ...this.state.pagination,
      pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize
    };
    this.getShipmentsResource(pagination, this._count).then((response) => {
      this._count++;
      this.setState({hasloadedInitial: true, awaitingInitialLoad: false});
    });
  }

  getShipmentsResource = (opts, count, persistExistingShipments) => {
    if (this.props.modeShippingDashboard) {
      return this.props.getShippingDashboard(opts, count, persistExistingShipments);
    }
    return this.props.getShipments(opts, count, persistExistingShipments);
  };

  goToManualQuote(shipment) {
    this.context.router.push('/shipments/' + shipment.id + '/manual-rate');
  }

  shareLink(shareLink) {
    this.setState({
      showSharelinkModal: true,
      showSharelinkTitle: shareLink
    });
  }

  renderShareLinkModal() {
    return (
      <FormGroup className="share-link" controlId="share-link">
        <ShareLinkForm
          values={this.state.selectedShipment}
          onSubmit={this.handleShareLink}
          onCancel={() => this.setState({showSharelinkModal: false})}
        />
      </FormGroup>
    );
  }

  handleShareLink(values) {
    const shipmentId = this.state.selectedShipment?.id;
    const emailList = [];
    if (!values) {
      return;
    }
    for (let i = 0; i < values.emails.length; i++) {
      emailList.push(values.emails[i].value);
    }
    const body = {
      recipient_emails: emailList
    };
    if (shipmentId) {
      return this.props.shareLink(shipmentId, body).then((response) => {
        if (response.status === 200) {
        } else {
          const errors = response.field_errors || [];
          let submissionError = {};
          submissionError = unpackErrors(errors, submissionError);
          submissionError._error = response.error_description;
          throw new SubmissionError(submissionError);
        }
        this.setState({
          showSharelinkModal: null,
          selectedShipment: null
        });
      });
    }
  }

  updateShipment(attrs, e) {
    const shipmentId = this.state.selectedShipment?.id;
    const payload = Object.assign(this.state.selectedShipment, attrs);
    e.target.disabled = true;
    if (shipmentId) {
      return this.props.shipmentsShipmentIdPut(shipmentId, payload, {}).then((response) => {
        if (response.status === 200) {
          this.getShipments(this.props.company.id);
          this.setState({selectedShipment: null, showArchiveModal: false});
        }
      });
    }
  }

  handleCancelReasonChange(e) {
    // cancellation reason is required
    this.setState({cancellationReason: e.target.value});
    if (e.target.value.length > 5) {
      this.setState({disableCancel: false});
    } else {
      this.setState({disableCancel: true});
    }
  }

  cancelShipment() {
    const shipmentId = this.state.selectedShipment?.id;
    const body = {
      cancellation_reason: this.state.cancellationReason
    };
    if (shipmentId) {
      return this.props.cancelShipment(shipmentId, body).then((response) => {
        if (response.status === 200) {
          this.getShipmentsResource(this.state.pagination, this._count);
          this._count++;
        }
        // hide the popover
        this.setState({
          showCancelModal: false,
          cancellationReason: '',
          selectedShipment: null
        });
      });
    }
  }

  renderCancelModal() {
    let isCancelled = false;
    const shipmentName = this.state.selectedShipment
      ? this.state.selectedShipment.name
        ? this.state.selectedShipment.name
        : this.state.selectedShipment.reference_id
      : '';
    const selectedShipment = this.state.selectedShipment;
    if (selectedShipment && selectedShipment.state === 'cancelled') {
      isCancelled = true;
    }
    let vendorName, vendorPhone;
    if (selectedShipment && selectedShipment.relationship_to_vendor) {
      const vendor = selectedShipment.relationship_to_vendor.vendor;
      if (vendor) {
        vendorName = vendor.name;
        if (vendor.primary_phone_number) {
          vendorPhone = getPhoneHyperlink(vendor.primary_phone_number);
        }
      }
    }

    return isCancelled ? (
      <div className="dashboard__cancel">
        <p>{shipmentName} has already been cancelled.</p>
      </div>
    ) : (
      <div className="dashboard__cancel">
        <p>
          This will cancel your shipment ({shipmentName}) on the platform and send a cancellation email to the carrier,
          however, cancellation fees may still apply. In order to confirm cancellation please call the carrier{' '}
          {vendorName} {vendorPhone && ` at ${vendorPhone}`}
        </p>

        <FormGroup controlId="cancel-shipment">
          <ControlLabel>Cancellation reason</ControlLabel>
          <Textarea
            value={this.state.cancellationReason}
            placeholder="Cancellation reason..."
            onChange={this.handleCancelReasonChange}
            minRows={7}
          />
          <BootstrapButton
            onClick={() => this.cancelShipment()}
            disabled={this.state.disableCancel}
            className="btn-primary btn-small"
          >
            Cancel this shipment
          </BootstrapButton>
          <small className="cancel-shipment-disclaimer">
            By cancelling shipment, you are indicating that you have read and understood the cancellation terms listed
            in Terms and Conditions.
          </small>
        </FormGroup>
      </div>
    );
  }

  /*
   * Search for shipments using the searchbar
   */
  searchDataTable(e) {
    //clear out any filters in the URL
    this.handleUpdateURL(null);
    clearTimeout(this.timer);
    const str = e.target.value;
    //set the searchvalue and clear any selected filter
    this.setState({searchValue: str, defaultSelectedFilter: null}, () => {
      if (str.length >= 3 || str === '') {
        this.timer = setTimeout(() => {
          const archivedOption = str === '' ? {} : {archived: [true, false]};
          const pagination = {
            ...initialPagination,
            pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize,
            ...archivedOption,
            q: str
          };
          this.handleUpdateQueryString(pagination);
          this.getShipmentsResource(pagination, this._count).then((response) => {
            this.setState({pagination});
          });
          this._count++;
        }, 500);
      }
    });
  }

  setParentState(obj, cb) {
    this.setState(obj, () => {
      if (cb) {
        cb();
      }
    });
  }

  updateColumns(selectedColumns) {
    const {dashboardConfig} = this.props;
    if (dashboardConfig.configsById) {
      if (!selectedColumns.includes('reference_id')) {
        selectedColumns.unshift('reference_id', 'tags', 'archived');
      }
      const columnsToShow = selectedColumns.map((columnId) => this.columns[columnId]).filter((column) => column); // ugh...
      this.setState({temporaryColumnOverride: columnsToShow});
      localStorage.setItem(LOCALSTORAGE_DASHBOARD_COLUMNS, JSON.stringify(columnsToShow));
    }
  }

  applyFilters(newFilters) {
    const {defaultSelectedFilter} = this.state;
    const newFilterCopy = this.parseFilters(JSON.parse(JSON.stringify(newFilters)));
    let newURL = '';
    if (newFilterCopy.customer && Array.isArray(newFilterCopy.customer)) {
      newFilterCopy.customer = newFilterCopy.customer.map((e) => e.label);
    }
    if (newFilterCopy.vendor && Array.isArray(newFilterCopy.vendor)) {
      newFilterCopy.vendor = newFilterCopy.vendor.map((e) => e.label);
    }
    if (newFilterCopy.createdBy && Array.isArray(newFilterCopy.createdBy)) {
      // exclude sources here since we're just saving labels in the url to map back to user ids later
      newFilterCopy.createdBy = newFilterCopy.createdBy.filter((e) => !SOURCES[e.value]).map((e) => e.label);
    }
    if (newFilters.status && Array.isArray(newFilters.status)) {
      const parcelFilters = newFilters.status.map((s) => parcelFilterMapping[s]).filter((f) => f);
      //Filtering out 'NOT_SCHEDULED' status since it's the same for quoting & carrier confirm states
      //So in this case FE sending only status field which also will be applied for Parcels
      const filteredParcelFilters = parcelFilters.filter((f) => f !== 'NOT_SCHEDULED');

      if (filteredParcelFilters.length > 0) {
        newFilterCopy.parcelPickupStatus = filteredParcelFilters;
      }
    }
    if (defaultSelectedFilter) {
      newURL = queryString.stringify(Object.assign({}, {view: defaultSelectedFilter}, newFilterCopy));
    } else {
      newURL = queryString.stringify(newFilterCopy);
    }

    //when this is clearing out filters, reset the columns too
    if (Object.keys(newFilters).length === 0) {
      this.updateColumns(this.defaultColumns);
      newURL = null;
    }
    //clear out the URL params if they exist
    this.handleUpdateURL(newURL);
    const allFiltersSelected = Object.assign({}, newFilterCopy);
    //ensure we are passing each key value in as an array to the GET call
    Object.keys(allFiltersSelected).forEach((key) => {
      if (typeof allFiltersSelected[key] === 'string' && key !== 'view') {
        allFiltersSelected[key] = [allFiltersSelected[key]];
      }
    });

    //always reset pagination to initial here
    //add query and pageSize if they exist
    const pagination = {
      ...initialPagination,
      q: this.state.pagination?.q,
      pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize,
      ...allFiltersSelected
    };
    this.setState(
      {pagination},
      () => {
        this.getShipmentsResource(pagination, this._count);
        this._count++;
      },
      () => this.handleUpdateQueryString(pagination)
    );
  }

  /*
   *  Table fetch data function -- used primarily for page and page size changes
   */

  fetchData(state, instance) {
    if (!_.isEmpty(this.props.company) && !this.state.awaitingInitialLoad) {
      const pagination = {
        ...this.state.pagination,
        pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize
      };
      this.getShipmentsResource(pagination, this._count).then((response) => {
        this.setState({pagination});
      });
      this._count++;
    }
  }

  /*
   * Adjust the pagination and selectedFilter when child component selects a filter
   */
  handleFilterSelection(id, clearExisting) {
    //trigger this change first to ensure that children are rerendered appropriately
    this.setState({defaultSelectedFilter: null}, () => {
      let {pagination} = this.state;
      let allFiltersSelected = {};
      if (
        id === null ||
        (this.props.dashboardConfig.configs &&
          this.props.dashboardConfig.configs.filter((e) => e.id === id).length === 0)
      ) {
        //TODO - decide if this should go back to the user's default here or not (currently does not)
        //reset to intial pagination settings when a filter is cleared
        pagination = {
          ...initialPagination,
          pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize
        };
        //reset url to no filter selected
        this.handleUpdateURL(null);
        //reset columns
        this.updateColumns(this.defaultColumns);
        //reset search term
        this.setState({searchValue: ''});
      } else {
        let newFilters = {};
        if (
          this.props.dashboardConfig.configs &&
          this.props.dashboardConfig.configs.filter((e) => e.id === id).length
        ) {
          newFilters = this.parseFilters(
            this.props.dashboardConfig.configs.filter((e) => e.id === id)[0].config.filters
          );
          //update the visible columns to match the selection
          this.localStorageColumns?.length && !clearExisting
            ? this.updateColumns(this.defaultColumns)
            : this.updateColumns(this.props.dashboardConfig.configs.filter((e) => e.id === id)[0].config.columns);
        }

        if (clearExisting) {
          //when a new filter is selected, we don't want to persist the other items in the URL
          allFiltersSelected = Object.assign({}, newFilters);
        } else {
          allFiltersSelected = Object.assign({}, this.props.location.query, newFilters);
        }
        Object.keys(allFiltersSelected).forEach((key) => {
          if (typeof allFiltersSelected[key] === 'string' && key !== 'view') {
            allFiltersSelected[key] = [allFiltersSelected[key]];
          }
          //special handling for customer and vendor which use IDs
          if (key === 'customer' && typeof allFiltersSelected.customer === 'string') {
            allFiltersSelected.customer = [{label: allFiltersSelected.customer, value: allFiltersSelected.customerId}];
          } else if (key === 'customer') {
            if (Array.isArray(allFiltersSelected.customer)) {
              allFiltersSelected.customer = allFiltersSelected.customer.map((e, i) => {
                if (typeof e === 'string') {
                  return {label: e, value: allFiltersSelected.customerId[i]};
                }
                return {label: e.label, value: e.value};
              });
            }
          }
          if (key === 'vendor' && typeof allFiltersSelected.vendor === 'string') {
            allFiltersSelected.vendor = [{label: allFiltersSelected.vendor, value: allFiltersSelected.vendorId}];
          } else if (key === 'vendor') {
            if (Array.isArray(allFiltersSelected.vendor)) {
              allFiltersSelected.vendor = allFiltersSelected.vendor.map((e, i) => {
                if (typeof e === 'string') {
                  return {label: e, value: allFiltersSelected.vendorId[i]};
                }
                return {label: e.label, value: e.value};
              });
            }
          }
        });

        let parcelFilters = [];
        if (allFiltersSelected.status) {
          parcelFilters = allFiltersSelected.status.map((s) => parcelFilterMapping[s]).filter((f) => f);
        }

        if (parcelFilters.length > 0) {
          allFiltersSelected.parcelPickupStatus = parcelFilters;
        }

        pagination = {
          ...initialPagination,
          ...allFiltersSelected,
          pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize
        };

        //get the default ordering
        if (
          this.props.dashboardConfig.configs.filter((e) => e.id === id).length &&
          this.props.dashboardConfig.configs.filter((e) => e.id === id)[0].config.ordering.length
        ) {
          pagination.ordering = this.props.dashboardConfig.configs.filter((e) => e.id === id)[0].config.ordering;
        }
        //if query string params contains ordering use it
        if (this.props.location.query.ordering) {
          const {ordering} = this.props.location.query;
          pagination.ordering = Array.isArray(ordering) ? ordering : [ordering];
        }
        //add query
        if (this.state.pagination && this.state.pagination.q) {
          pagination.q = this.state.pagination.q;
        }

        const urlToSave = JSON.parse(JSON.stringify(newFilters));
        if (urlToSave.customer) {
          urlToSave.customer = urlToSave.customer.map((e) => e.label);
        }
        if (urlToSave.vendor) {
          urlToSave.vendor = urlToSave.vendor.map((e) => e.label);
        }

        if (urlToSave.createdBy) {
          // exclude sources here since we're just saving labels in the url to map back to user ids later
          urlToSave.createdBy = urlToSave.createdBy.filter((e) => !SOURCES[e.value]).map((e) => e.label);
        }

        //save the viewId in the url
        const newURL = queryString.stringify(
          Object.assign({}, {view: id}, urlToSave, clearExisting ? '' : this.props.location.query)
        );

        this.handleUpdateURL(newURL);
      }
      this.setState(
        {
          defaultSelectedFilter: id,
          allFiltersSelected,
          hasLoadedInitial: true,
          pagination
        },
        () => {
          this.getShipmentsResource(pagination, this._count).then((response) => {
            this.setState({
              awaitingInitialLoad: false
            });
          });
          this._count++;
        }
      );
    });
  }

  /*
   * When necessary, parse the filter object to ensure the selections are applied correctly before render/saving
   */
  parseFilters(filterObj, direction, isSaveAction) {
    return parseTableFilters(filterObj, direction, isSaveAction);
  }

  /**
   * Save the current view as a filter
   */
  saveView(filterName, activeFilters, columns, setAsDefault, renameOnly) {
    const {dashboardConfig} = this.props;
    //first check if the name on any of these filters string-matches the filtername we got here
    //if so we are doing a PUT
    let id = null;
    if (dashboardConfig.configs.filter((e) => e.name === filterName).length > 0) {
      id = dashboardConfig.configs.filter((e) => e.name === filterName)[0].id;
    }
    if (renameOnly) {
      id = renameOnly;
    }
    //make sure we always send reference_id at the beginning of the column list
    const requiredColumns = ['reference_id'];
    requiredColumns.forEach((col) => {
      if (columns.indexOf(col) < 0) {
        columns.unshift(col);
      }
    });

    const newConfig = {config: {}};
    newConfig.is_default = setAsDefault;
    newConfig.table_type = 'DASHBOARD';
    newConfig.config.columns = columns;
    newConfig.config.ordering = this.state.pagination.ordering; //potentially overwriting ordering when rename only
    if (activeFilters.view) {
      delete activeFilters.view;
    }
    newConfig.config.filters = this.parseFilters(JSON.parse(JSON.stringify(activeFilters)), null, true);
    //pass 3rd parameter for special handling of dates
    newConfig.name = filterName;

    if (id) {
      //PUT
      this.props.updateUserTableConfiguration(id, newConfig).then((response) => {
        if (response && response.statusCode === 200) {
          this.props.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'}).then((getResponse) => {
            //making sure the full list is in state before selecting the filter
            this.handleFilterSelection(response.body.id, true);
            this.setState({successfulSaveTrigger: true});
            setTimeout(() => {
              this.setState({successfulSaveTrigger: false});
            }, 3000);
          });
        }
      });
    } else {
      this.props.createUserTableConfiguration(newConfig).then((response) => {
        if (response && response.statusCode === 201) {
          //get ALL of the user's dashboard configs
          this.props.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'}).then((getResponse) => {
            this.handleFilterSelection(response.body.id, true);
            //making sure the full list is in state before selecting the filter
            this.setState({successfulSaveTrigger: true});
            setTimeout(() => {
              this.setState({successfulSaveTrigger: false});
            }, 3000);
          });
        }
      });
    }
  }

  deleteView(view) {
    this.props.deleteUserTableConfiguration(view.id).then((response) => {
      if (response.statusCode === 204) {
        //clear out the URL params and the view
        this.handleUpdateURL(null);
        this.setState({shouldLoadView: false});

        this.props.getUserTableConfiguration({pageSize: 100, tableType: 'DASHBOARD'});
      }
    });
  }

  debouncedUrl = debounce((url) => {
    if (!url) {
      localStorage.setItem(DASHBOARD_LOCAL_STORAGE_KEY, '');
      window.history.pushState({}, '', window.location.origin + window.location.pathname);
    } else if (url) {
      localStorage.setItem(DASHBOARD_LOCAL_STORAGE_KEY, url);
      window.history.pushState({}, '', window.location.origin + window.location.pathname + `?${url}`);
    }
  }, 300);

  handleUpdateURL(url) {
    this.debouncedUrl(url);
  }

  handleUpdateQueryString(qs) {
    const currentParams = queryString.parse(window.location.search);
    this.handleUpdateURL(queryString.stringify({...currentParams, ...qs}));
  }

  handlePageChange(pageIndex) {
    const pagination = {
      ...this.state.pagination,
      pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize,
      page: pageIndex + 1
    };
    this.setState({pagination}, () => {
      this.fetchData();
      this.handleUpdateQueryString(pagination);
    });

    document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
  }

  handlePageSizeChange(pageSize) {
    this.setUserPageSize(pageSize);
    const pagination = {
      ...this.state.pagination,
      pageSize,
      //reset page to 1
      page: 1
    };
    this.setState({pagination}, () => {
      this.fetchData();
      this.handleUpdateQueryString(pagination);
    });
  }

  handleSortedChange(newSorted) {
    const pagination = {
      ...this.state.pagination,
      pageSize: this.state.tableRowDefaults?.DASHBOARD ?? this.state.pagination.pageSize
    };
    if (newSorted && newSorted.length) {
      //some parsing is needed when column id's don't match the exact model name the backend requires
      const sortValue = this.parseColumnSorting(JSON.parse(JSON.stringify(newSorted[0].id)), newSorted[0].desc);
      //we only allow one sort at a time
      pagination.ordering = [sortValue];
    }
    this.setState({pagination}, () => {
      this.fetchData();
      this.handleUpdateQueryString(pagination);
    });
  }

  parseColumnSorting(column, desc) {
    const sortingKey = Object.keys(columnSortingMapping).find((key) => columnSortingMapping[key] === column) || column;
    return `${desc ? '-' : ''}${sortingKey}`;
  }

  filterColumnsByPermissions(columnsObject = {}, columnsArray = [], filterableColumns = []) {
    const parsedColumnsObject = JSON.parse(JSON.stringify(columnsObject));
    const parsedColumnsArray = JSON.parse(JSON.stringify(columnsArray));
    const parsedFiltersArray = JSON.parse(JSON.stringify(filterableColumns));

    //remove load board id and buy now column if you don't have the feature flag for it
    if (
      this.props.company &&
      this.props.company.feature_flags &&
      !this.props.company.feature_flags.load_board_enabled
    ) {
      delete parsedColumnsObject.load_board_id;
      parsedColumnsArray.splice(parsedColumnsArray.indexOf('load_board_id'), 1);

      delete parsedColumnsObject.buy_it_now_amount;
      parsedColumnsArray.splice(parsedColumnsArray.indexOf('buy_it_now_amount'), 1);

      delete parsedColumnsObject.target_rate_amount;
      parsedColumnsArray.splice(parsedColumnsArray.indexOf('target_rate_amount'), 1);

      parsedFiltersArray.splice(parsedFiltersArray.indexOf('load_board_enabled'), 1);
    }

    // remove stages column if you don't have the feature flag for it
    if (!this.props.modeMultiModeDashboard) {
      delete parsedColumnsObject.number_of_stages;
      parsedColumnsArray.splice(parsedColumnsArray.indexOf('number_of_stages'), 1);

      delete parsedColumnsObject.multi_stage_id;
      parsedColumnsArray.splice(parsedColumnsArray.indexOf('multi_stage_id'), 1);
    }

    if (
      this.props.user &&
      this.props.user.permissions &&
      !this.props.user.permissions.includes('users.view_company_users')
    ) {
      //reps will not be available
      parsedFiltersArray.splice(parsedFiltersArray.indexOf('reps'), 1);
    }

    return {parsedColumnsObject, parsedColumnsArray, parsedFiltersArray};
  }

  handleCloseDrawer() {
    this.setState({
      showShipmentDetail: false,
      shipmentPOC: null,
      selectedShipment: null,
      showV3ShipmentDetail: false,
      selectedV3Shipment: null
    });
  }
  async handleAsyncFilterMapping() {
    const {fmNewCreateIntermodal} = this.props;
    this.setState({filterMappingLoading: true});
    try {
      //get shipment statuses from endpoint
      const response = await fetchShipmentStatusesPromise();
      if (response) {
        let shipmentFilters = response.body.map((result, index) => ({
          value: result.id,
          label: result.label,
          index: index + 1
        }));
        shipmentFilters = [...dashboardStatuses, ...shipmentFilters];
        const sortedShipmentFilters = shipmentFilters
          .sort((a, b) => a.index - b.index)
          .map((shipmentFilters, index, array) => shipmentFilters);
        const modeOptionsWithIntermodal = [...filterMapping.mode?.options, INTERMODAL_FILTER_OPTION];
        if (!this.props.modeShippingDashboard) {
          delete filterMapping.tender_status;
        }
        //set state of filter mapping with options from endpoint, add dashboard-specific statuses
        //and feature flagged mode option(s)
        this.setState({
          filterMapping: {
            ...filterMapping,
            status: {...filterMapping.status, options: [...sortedShipmentFilters]},
            mode: {
              ...filterMapping.mode,
              //show intermodal in mode options list if feature flag is on
              options: fmNewCreateIntermodal ? modeOptionsWithIntermodal : filterMapping?.mode?.options
            },
            filterMappingLoading: false
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getUserPageSize() {
    try {
      const res = await getShipwellUiUserPreferencesPromise();
      const newPagination = {
        ...this.state.pagination,
        pageSize: res.body.table_row_defaults?.DASHBOARD ?? this.state.pagination.pageSize
      };
      this.setState({pagination: newPagination}, () => {
        this.setState({tableRowDefaults: res.body.table_row_defaults});
        this.handleUpdateQueryString(newPagination);
      });
      return Promise.resolve(newPagination.pageSize);
    } catch (error) {
      console.error(error);
    }
  }

  async setUserPageSize(pageSize) {
    try {
      const newTableSize = {DASHBOARD: pageSize};
      const body = {table_row_defaults: {...this.state.tableRowDefaults, ...newTableSize}};
      const res = await editShipwellUiUserPreferencesPromise(body);
      this.setState({tableRowDefaults: res.body.table_row_defaults});
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Update selected list of parcel shipments
   * @param  {String} shipment
   */

  handleSelectShipments = (shipments) => {
    const parcelShipmentsSelected = shipments?.some((shipment) => shipment?.mode?.code === 'PARCEL');
    const checkForSelectAll = shipments?.some((shipment) => shipment?.mode?.code !== 'PARCEL');

    const removeParcelShipments = shipments.filter((shipment) => shipment?.mode?.code !== 'PARCEL');
    const isParcelSelected = parcelShipmentsSelected && !checkForSelectAll ? shipments : removeParcelShipments;

    const shipmentIdsObject = isParcelSelected.reduce((shipmentObject, shipment) => {
      shipmentObject[shipment.id] = shipment;
      return shipmentObject;
    }, {});

    this.setState({selectedShipments: isParcelSelected, selectedShipmentsById: shipmentIdsObject});
  };

  async cancelBulkShipments(setSuccess, setError) {
    const shipmentIds = this.state.selectedShipments?.map((shipment) => shipment.id);

    try {
      if (shipmentIds) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'MODIFY_SHIPMENT_STATUS',
                step_id: 'STEP_2',
                attached_triggers: [
                  {
                    trigger: {
                      trigger_type: 'NONE'
                    },
                    next_step_id: 'STEP_END'
                  }
                ],
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: [
                  {
                    name: 'new_status',
                    value: 'cancelled'
                  }
                ]
              }
            ]
          },
          criteria: {
            id: {
              $in: shipmentIds
            }
          }
        };
        const response = await createBulkOperation(bulkOperationBody);
        setSuccess(
          'Bulk Action Started!',
          `Cancellation has been submitted for ${pluralize('shipment', shipmentIds.length, true)}.`
        );
        this.pollBulkOperation(response.data.id, BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS);
      }
    } catch (error) {
      console.error(`Error cancelling ${pluralize('shipment', shipmentIds.length)}.`, error);
      setError('Changes Not Saved', `Unable to cancel the ${pluralize('shipment', shipmentIds.length)} you selected.`);
    }
  }

  async archiveBulkShipments(setSuccess, setError) {
    const shipmentIds = this.state.selectedShipments?.map((shipment) => shipment.id);

    try {
      if (shipmentIds) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'ARCHIVE_SHIPMENT',
                step_id: 'STEP_2',
                attached_triggers: [
                  {
                    trigger: {
                      trigger_type: 'NONE'
                    },
                    next_step_id: 'STEP_END'
                  }
                ],
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: []
              }
            ]
          },
          criteria: {
            id: {
              $in: shipmentIds
            }
          }
        };
        const response = await createBulkOperation(bulkOperationBody);
        setSuccess('Bulk Action Started!', `Archiving ${pluralize('shipment', shipmentIds.length, true)}.`);
        this.pollBulkOperation(response.data.id, BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT);
      }
    } catch (error) {
      console.error(`Error archiving ${pluralize('shipment', shipmentIds.length)} status.`, error);
      setError(
        'Changes Not Saved',
        `Your changes could not be applied to the ${pluralize('shipment', shipmentIds.length)} you selected.`
      );
    }
  }

  async handleCreatePickup(values, {setSubmitting, setErrors}) {
    const {selectedShipments, selectedShipmentsById} = this.state;
    const shipment = selectedShipmentsById[selectedShipments[0]?.id];
    const shipmentIds = selectedShipments?.map((shipment) => shipment?.id);

    try {
      /** Create shipment pickup */
      let carrierCode;
      if (shipment.fedex_specific_options) {
        carrierCode = shipment.fedex_specific_options.carrier_code;
      }
      if (shipment.ups_specific_options) {
        carrierCode = shipment.ups_specific_options.carrier_code;
      }

      const response = await createShipmentPickup(
        cleanPayload({
          ...values,
          provider_carrier_code: carrierCode,
          shipmentIds
        })
      );

      if (response && response.body) {
        this.context.router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      console.error(error);
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }

    this.setState({showCreatePickupModal: false});
    setSubmitting(false);
  }

  async handleUpdatePickup(pickup) {
    const {selectedShipments, selectedShipmentsById} = this.state;
    const shipment = selectedShipmentsById[selectedShipments[0]?.id];
    const shipmentIds = selectedShipments?.map((shipment) => shipment?.id);

    const pickupShipmentIds = pickup.shipments.map((s) => s.id);
    let carrierCode;

    if (shipment.fedex_specific_options) {
      carrierCode = shipment.fedex_specific_options.carrier_code;
    }
    if (shipment.ups_specific_options) {
      carrierCode = shipment.ups_specific_options.carrier_code;
    }

    try {
      /** Update shipment pickup */
      const response = await updateShipmentPickup(
        pickup.id,
        cleanPayload({
          ...pickup,
          provider_carrier_code: carrierCode,
          shipments: [...pickupShipmentIds, ...shipmentIds]
        })
      );

      if (response && response.body) {
        this.context.router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      if (error && error.error_description) {
        const errorDescription = JSON.parse(error.error_description);

        if (errorDescription && errorDescription.message) {
          this.props.showErrorToast(errorDescription.message);
        }
      }
    }

    this.setState({showSchedulePickupModal: false});
  }

  pollBulkOperation(bulkOperationId, bulkOperationType) {
    const {setError, setSuccess} = this.props;
    const {selectedShipments} = this.state;
    let pollCount = 0;
    let hasShownSuccess = false;
    bulkOperationInterval = setInterval(async () => {
      try {
        pollCount++;
        const response = await getBulkOperation(bulkOperationId);
        if (
          response.data.status === 'FINISHED' ||
          (response.data.status === 'PROCESSING' &&
            [BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE, BULK_OPERATION_TYPE_TENDER_TO_CARRIER].includes(
              bulkOperationType
            ))
        ) {
          //routing guides may stay in processing for a long time because of timers, so we count that as complete after 30 seconds
          if (response.data.status === 'FINISHED' || pollCount > 15) {
            clearInterval(bulkOperationInterval);
            if (
              [
                BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES,
                BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS,
                BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT
              ].includes(bulkOperationType)
            ) {
              this.getShipments();
            }
          }
          if (response.data.failed > 0) {
            const failedShipments = response.data.resource_statuses
              .filter((resource) => resource.status === 'ERROR')
              .map((resource) => {
                return {
                  id: resource.resource_id,
                  error_message: resource.error_message,
                  reference_id: selectedShipments.find((shipmentId) => shipmentId?.id === resource.resource_id)
                    .reference_id
                };
              });
            const {errorTitle, errorDetails} = bulkOperationTypeMessages[bulkOperationType];
            setError(
              errorTitle,
              <div>
                <div>{`${errorDetails} ${pluralize('shipment', failedShipments.length)}.`}</div>
                <div>
                  {failedShipments.map((shipment) => {
                    return (
                      <div key={shipment.id}>
                        <a href={`/shipments/${shipment.id}`} target="_blank" rel="noreferrer">
                          {shipment.reference_id}
                        </a>
                        <br />
                        <span>
                          Message:&#160;
                          <span className="text-sw-error">{shipment.error_message}</span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>,
              'top-right',
              {delay: null}
            );
          } else {
            if (!hasShownSuccess) {
              hasShownSuccess = true;
              const {successTitle, successDetails} = bulkOperationTypeMessages[bulkOperationType];
              setSuccess(successTitle, `${successDetails} ${pluralize('shipment', response.data.total, true)}.`);
            }
          }
        }
      } catch (error) {
        console.error(error.error_description);
      }
    }, 3000);
  }

  render() {
    const {
      isFedExEnabled,
      dashboardConfig,
      classes,
      isBidManagerEnabled,
      referencesCustomFields,
      stmDashboardColumnsCustomReferenceFields,
      onUpdateDashboardFlag
    } = this.props;
    const referencesCustomFieldsColumnNames = mapCustomReferenceFieldsToDashboardColumnNames(referencesCustomFields);
    const {
      temporaryColumnOverride,
      selectedShipments,
      selectedShipmentsById,
      showImportFullScreen,
      performingBulkAction
    } = this.state;
    const shipment = selectedShipments.length && selectedShipmentsById[selectedShipments[0]?.id];
    const providerCode = shipment ? shipment.current_carrier?.name.toUpperCase() : '';

    const SidebarDropdownContent = ({onClick}) => (
      <>
        {this.state.shipmentDetail?.state === 'quote_accepted' &&
          this.state.shipmentDetail.metadata.open &&
          isBidManagerEnabled && (
            <li
              onClick={() => {
                onClick();
                this.setState({showCarrierBidForm: true});
              }}
            >
              New Bid
            </li>
          )}
        {this.state.shipmentDetail &&
        this.state.shipmentDetail.mode &&
        [1, 2, 4, 5, 7, 8].includes(this.state.shipmentDetail.mode.id) ? (
          <>
            <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
              <li
                onClick={() => {
                  onClick();
                  this.setState({isTender: true});
                }}
              >
                Tender to Carrier(s)
              </li>
            </TenderingUserPermissionFallback>

            {[1, 8].includes(this.state.shipmentDetail.mode.id) ? (
              <>
                {this.props.hasContractsViewPermission &&
                  this.props.isContractsEnabled &&
                  this.props.isRoutingGuidesEnabled && (
                    <TenderingUserPermissionFallback permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]}>
                      <li
                        id="pushToRoutingGuide"
                        bsStyle="link"
                        onClick={() => {
                          onClick();
                          this.setState({showRoutingGuideModal: true});
                        }}
                      >
                        Push to Routing Guide
                      </li>
                    </TenderingUserPermissionFallback>
                  )}
                <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                  <li
                    id="requestBids"
                    bsStyle="link"
                    onClick={() => {
                      onClick();
                      this.context.router.push(
                        '/marketplace/' + this.state.shipmentDetail.id + '/bids/?requestRates=true'
                      );
                    }}
                  >
                    Request Bids
                  </li>
                </TenderingUserPermissionFallback>
              </>
            ) : null}
          </>
        ) : null}
        <li
          onClick={function () {
            onClick();
            this.setState(
              {selectedShipment: this.state.shipmentDetail},
              function () {
                this.setState({showArchiveModal: true});
              }.bind(this)
            );
          }.bind(this)}
        >
          Archive
        </li>
        <li
          bsStyle="link"
          onClick={function () {
            onClick();
            this.setState(
              {selectedShipment: this.state.shipmentDetail},
              function () {
                this.setState({showCancelModal: true});
              }.bind(this)
            );
          }.bind(this)}
        >
          Cancel
        </li>
        <li
          onClick={function () {
            onClick();
            this.setState(
              {
                selectedShipment: this.state.shipmentDetail
              },
              function () {
                this.shareLink(
                  'Share ' +
                    (this.state.shipmentDetail.name
                      ? this.state.shipmentDetail.name
                      : this.state.shipmentDetail.reference_id)
                );
              }.bind(this)
            );
          }.bind(this)}
        >
          Share
        </li>
      </>
    );

    /**
     * @todo  Gross! Clean up below
     */
    const {
      defaultColumns,
      allColumns,
      featureFlags = {},
      setSuccess,
      wfaBulkActions,
      wfaBulkActionShipmentStatusChange,
      setError
    } = this.props;
    const {loadingMessage, pagination} = this.state;
    const columnIdArray = Object.keys(this.columns);
    const columnNamesById =
      dashboardConfig && stmDashboardColumnsCustomReferenceFields
        ? {...dashboardConfig.columnNamesById, ...referencesCustomFieldsColumnNames}
        : dashboardConfig
        ? dashboardConfig.columnNamesById
        : undefined;
    const {parsedColumnsObject, parsedColumnsArray, parsedFiltersArray} = this.filterColumnsByPermissions(
      this.columns,
      columnIdArray,
      FILTERABLE_DASHBOARD_COLUMNS
    );
    const columns =
      dashboardConfig &&
      dashboardConfig.configs &&
      dashboardConfig.configs.length &&
      this.state.defaultSelectedFilter &&
      dashboardConfig.configsById[this.state.defaultSelectedFilter]
        ? dashboardConfig.configsById[this.state.defaultSelectedFilter].config.columns
            .filter((column) => !column.startsWith('-'))
            .map((columnId) => parsedColumnsObject[columnId])
            .filter((column) => column) // ugh...
        : defaultColumns && defaultColumns.map((columnId) => parsedColumnsObject[columnId]).filter((column) => column); //load the shipwell default
    const selectedColumnNames = temporaryColumnOverride
      ? temporaryColumnOverride.map((e) => e.id)
      : columns && columns.map((e) => e.id);

    const all = this.props.shipments ? this.props.shipments.results : [];

    const columnsToRender = [
      bulkSelect({
        currentPage: all,
        selected: this.state.selectedShipments,
        onSelect: this.handleSelectShipments,
        isDashboard: true,
        shipment
      }),
      ...(temporaryColumnOverride ? temporaryColumnOverride : columns)
    ];

    const hasReadback =
      this.state.pagination &&
      Object.keys(this.state.pagination).filter((e) => e !== 'pageSize' && e !== 'page' && e !== 'ordering').length > 0;

    const tableComponent = (
      <div className="dashboard__table">
        <ReactTableFixedColumns
          showPagination
          data={this.props.shipments ? this.props.shipments.results : []}
          pages={this.props.shipments ? this.props.shipments.total_pages : 1}
          loading={this.props.isLoading}
          sortable={false}
          manual
          defaultPageSize={
            localStorage.getItem('dashboardPageSize')
              ? Number(localStorage.getItem('dashboardPageSize'))
              : this.state.pagination.pageSize
          }
          page={this.state.pagination.page - 1}
          pageSize={this.state.pagination.pageSize}
          onPageChange={(pageIndex) => {
            this.handlePageChange(pageIndex);
          }}
          onPageSizeChange={(pageSize, pageIndex) => {
            this.handlePageSizeChange(pageSize, pageIndex);
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.handleSortedChange(newSorted, column, shiftKey);
          }}
          sorted={this.state.pagination.ordering.map((e) => {
            //parse when columns dont match e.g., equipment_type
            if (columnSortingMapping[e] || columnSortingMapping[e.slice(1)]) {
              return e.startsWith('-')
                ? {id: columnSortingMapping[e.slice(1)], desc: true}
                : {id: columnSortingMapping[e], desc: false};
            }

            return e.startsWith('-') ? {id: e.slice(1), desc: true} : {id: e, desc: false};
          })}
          minRows={10}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultSorted={
            localStorage.getItem('dashboardSort')
              ? JSON.parse(localStorage.getItem('dashboardSort'))
              : [
                  {
                    id: 'pickup',
                    desc: true
                  }
                ]
          }
          id="shipmentsTable"
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              className:
                (this.state.selectedShipment && rowInfo && this.state.selectedShipment.id === rowInfo.original.id) ||
                (this.state.selectedV3Shipment !== null && this.state.selectedV3Shipment?.id === rowInfo?.original.id)
                  ? 'selectedShipment'
                  : '',
              onClick: function (e) {
                this.handleCloseDrawer();
                const shipmentId = rowInfo.original.id;
                const {shipmentDetail} = this.state;

                if (rowInfo.original.version === V3_VERSION) {
                  if (this.state.selectedV3Shipment !== rowInfo.original) {
                    this.setState({showV3ShipmentDetail: true, selectedV3Shipment: rowInfo.original});
                  }
                  return;
                }

                if (!shipmentDetail || (shipmentDetail && shipmentDetail.id !== rowInfo.original.id)) {
                  this.setState({
                    shipmentDetailLoading: true,
                    selectedShipment: rowInfo.original,
                    showShipmentDetail: true
                  });

                  getShipment(shipmentId)
                    .then((response) => {
                      this.setState({
                        selectedShipment: response,
                        shipmentDetail: response,
                        shipmentDetailLoading: false
                      });
                    })
                    .catch((err) => {
                      this.setState({
                        selectedShipment: null,
                        shipmentDetail: null,
                        shipmentDetailLoading: false
                      });
                      console.error(err);
                    });
                  if (
                    this.props.user &&
                    this.props.user.permissions &&
                    this.props.user.permissions.includes(permViewInvoice)
                  ) {
                    if (
                      this.props.company &&
                      this.props.company.feature_flags &&
                      this.props.company.feature_flags.invoicing_enabled
                    ) {
                      this.props.checkAuthentication().then((response) => {
                        if (response.status === 200 && response.details && response.details.is_authenticated) {
                          this.props.getShipmentInvoices(shipmentId);
                        }
                      });
                    }
                  }
                }

                if (
                  rowInfo.original &&
                  rowInfo.original.relationship_to_vendor &&
                  rowInfo.original.relationship_to_vendor.vendor &&
                  rowInfo.original.relationship_to_vendor.vendor.id
                ) {
                  this.props
                    .searchForCarrierByID(rowInfo.original.relationship_to_vendor.vendor.id)
                    .then((response) => {
                      if (response.status === 200) {
                        if (
                          response.details &&
                          response.details.results &&
                          response.details.results[0] &&
                          response.details.results[0].point_of_contacts &&
                          response.details.results[0].point_of_contacts.length
                        ) {
                          this.setState({shipmentPOC: response.details.results[0].point_of_contacts[0]});
                        }
                      }
                    });
                }
              }.bind(this)
            };
          }}
          getTrGroupProps={(state, rowInfo, column, instance) => {
            if (!rowInfo) {
              return {
                className: 'no-content'
              };
            }
            return '';
          }}
          getTheadFilterThProps={() => {
            return {
              style: {
                overflow: 'inherit'
              }
            };
          }}
          //onFetchData={this.fetchData} removed because this is done manually when page changes
          columns={columnsToRender}
          className="-highlight -striped"
          previousText={<i className="icon icon-Left" />}
          nextText={<i className="icon icon-Right" />}
          rowsText=""
          noDataText={this.props.isLoading ? '' : 'No Shipments'}
          LoadingComponent={ShipwellLoader}
          ref={(ref) => (this._reacttable = ref)}
        />
      </div>
    );

    const tableSearch = (
      <div className="table__search shipmentsTable__search">
        <FormGroup>
          <InputGroup>
            <InputGroup.Button>
              <BootstrapButton
                onClick={() => {
                  const filters = [];
                  filters.push({
                    id: 'q',
                    value: this.state.searchValue + '*'
                  });
                  this.setState({filters: filters});
                }}
              >
                <i className="flaticon-search pad-left" />
              </BootstrapButton>
            </InputGroup.Button>
            <FormControl
              name="search"
              type="text"
              value={this.state.searchValue}
              placeholder="Search for a shipment"
              onChange={this.searchDataTable}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  const filters = [];
                  filters.push({
                    id: 'q',
                    value: this.state.searchValue + '*'
                  });
                  this.setState({filters: filters});
                }
              }}
            />
          </InputGroup>
        </FormGroup>
      </div>
    );

    const parcelShipmentsSelected = selectedShipments?.some(
      (shipment) => selectedShipmentsById[shipment?.id]?.mode?.code === 'PARCEL'
    );

    const documentTitle = getDocumentTitle(this.state.dashboardDocumentModal.documentType);
    const selectedShipmentIds = selectedShipments.map((shipment) => shipment.id);
    return (
      <div className="content-wrapper dashboard-wrapper">
        <section
          className={`table__section ${
            this.state.showShipmentDetail ? classes.dashboardOpen : classes.dashboardClosed
          }`}
        >
          <header className="border-b border-sw-border px-4 py-2">
            <div className="flex flex-col gap-2 md:flex-row">
              <FlexBox items="center" gap="m" justify="between" grow={1}>
                <FlexBox items="center" gap="m">
                  <h2 className="m-0">Shipments</h2>
                  {tableSearch}
                </FlexBox>
                {selectedShipments.length > 0 ? (
                  <BulkActionsHookProvider
                    setLoadingMessage={(loadingMessage) => this.setState({loadingMessage})}
                    legacyGetShipments={this.getShipments}
                  >
                    {({roundTripsMutation}) => (
                      <Dropdown title="Shipment Bulk Actions" indicator variant="primary">
                        {({onClick}) => [
                          <BulkActionLineItemButton
                            key="changeShipmentStatus"
                            isDisabled={parcelShipmentsSelected}
                            isVisible={wfaBulkActionShipmentStatusChange}
                            onClick={() => {
                              onClick();
                              this.setState({showChangeShipmentStatusModal: true, performingBulkAction: true});
                            }}
                            disabledReason={NonParcelShipmentsTooltipContent('changeShipmentStatus')}
                          >
                            Change Shipment Status
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="createRoundTrips"
                            onClick={() => {
                              onClick();
                              roundTripsMutation.mutate(selectedShipmentIds);
                            }}
                            isDisabled={parcelShipmentsSelected}
                            disabledReason={NonParcelShipmentsTooltipContent('createRoundTrips')}
                            isVisible
                          >
                            Create Round Trips
                          </BulkActionLineItemButton>,
                          <ShipmentsDownloadCsv
                            key="downloadShipments"
                            options={{
                              onMutate: () => this.setState({loadingMessage: 'Generating CSV ...'}),
                              onSettled: () => this.setState({loadingMessage: null}),
                              setError
                            }}
                          >
                            {({mutate: downloadShipments}) => {
                              return (
                                <BulkActionLineItemButton
                                  onClick={() =>
                                    downloadShipments({
                                      shipmentIds: Object.keys(selectedShipmentsById),
                                      columnNames: selectedColumnNames
                                    })
                                  }
                                  isDisabled={false}
                                  isVisible
                                >
                                  Download Shipments
                                </BulkActionLineItemButton>
                              );
                            }}
                          </ShipmentsDownloadCsv>,
                          <BulkActionLineItemButton
                            key="editShipmentDates"
                            isDisabled={parcelShipmentsSelected}
                            isVisible={wfaBulkActions}
                            onClick={() => {
                              onClick();
                              this.setState({showEditShipmentDatesModal: true, performingBulkAction: true});
                            }}
                            disabledReason={NonParcelShipmentsTooltipContent('editShipmentDates')}
                          >
                            Edit Shipment Dates
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="emailBol"
                            isVisible={wfaBulkActions}
                            isDisabled={false}
                            onClick={() => {
                              onClick();
                              this.setState({
                                dashboardDocumentModal: {
                                  showModal: true,
                                  mode: EMAIL_DOCUMENT,
                                  documentType: ShipmentDocumentMetadataTypeEnum.Bol
                                }
                              });
                            }}
                          >
                            Email BOL
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="printBol"
                            isVisible={wfaBulkActions}
                            isDisabled={false}
                            onClick={() => {
                              onClick();
                              this.setState({
                                dashboardDocumentModal: {
                                  showModal: true,
                                  mode: PRINT_DOCUMENT,
                                  documentType: ShipmentDocumentMetadataTypeEnum.Bol
                                }
                              });
                            }}
                          >
                            Print BOL
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="printShippingLabel"
                            isVisible={wfaBulkActions}
                            isDisabled={false}
                            onClick={() => {
                              onClick();
                              this.setState({
                                dashboardDocumentModal: {
                                  showModal: true,
                                  mode: PRINT_DOCUMENT,
                                  documentType: ShipmentDocumentMetadataTypeEnum.ShippingLabel
                                }
                              });
                            }}
                          >
                            Print Shipping Label
                          </BulkActionLineItemButton>,
                          <TenderingUserPermissionFallback
                            permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]}
                            key="pushToRoutingGuide"
                          >
                            <BulkActionLineItemButton
                              key="pushToRoutingGuide"
                              isVisible={wfaBulkActions}
                              isDisabled={parcelShipmentsSelected}
                              onClick={() => {
                                onClick();
                                this.setState({showRoutingGuideModal: true, performingBulkAction: true});
                              }}
                              disabledReason={NonParcelShipmentsTooltipContent('pushToRoutingGuide')}
                            >
                              Push to Routing Guide
                            </BulkActionLineItemButton>
                          </TenderingUserPermissionFallback>,
                          <TenderingUserPermissionFallback
                            permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}
                            key="tenderToCarrier"
                          >
                            <BulkActionLineItemButton
                              isVisible={wfaBulkActions}
                              isDisabled={parcelShipmentsSelected || !isSamePreferredCurrency(selectedShipments)}
                              onClick={() => {
                                onClick();
                                this.setState({showTenderToCarrierModal: true, performingBulkAction: true});
                              }}
                              key="tenderToCarrier"
                              disabledReason={
                                !isSamePreferredCurrency(selectedShipments) ? (
                                  <CurrencyOfRecordTooltipContent />
                                ) : parcelShipmentsSelected ? (
                                  NonParcelShipmentsTooltipContent('tenderToCarrier')
                                ) : undefined
                              }
                            >
                              Tender to Carrier
                            </BulkActionLineItemButton>
                          </TenderingUserPermissionFallback>,
                          <BulkActionLineItemButton
                            isVisible={isFedExEnabled}
                            isDisabled={!parcelShipmentsSelected}
                            onClick={() => {
                              onClick();
                              this.setState({showSchedulePickupModal: true});
                            }}
                            key="schedulePickup"
                            disabledReason={ParcelShipmentsTooltipContent('schedulePickup')}
                          >
                            Schedule Pickup
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="archiveShipments"
                            isVisible
                            onClick={() => {
                              onClick();
                              this.archiveBulkShipments(this.props.setSuccess, this.props.setError);
                              this.setState({performingBulkAction: true});
                            }}
                          >
                            {`Archive ${pluralize('Shipment', this.state.selectedShipments.length)}`}
                          </BulkActionLineItemButton>,
                          <BulkActionLineItemButton
                            key="cancelShipments"
                            isVisible
                            onClick={() => {
                              onClick();
                              this.cancelBulkShipments(this.props.setSuccess, this.props.setError);
                              this.setState({performingBulkAction: true});
                            }}
                          >
                            <span className="text-sw-destroy">
                              Cancel {pluralize('Shipment', this.state.selectedShipments.length)}
                            </span>
                          </BulkActionLineItemButton>
                        ]}
                      </Dropdown>
                    )}
                  </BulkActionsHookProvider>
                ) : (
                  <PermissionsFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
                    <Button
                      variant="tertiary"
                      isCompact
                      iconName="AddCircleOutlined"
                      onClick={() => {
                        this.setState({showImportShipmentsModal: true});
                      }}
                    >
                      Import
                    </Button>
                  </PermissionsFallback>
                )}
              </FlexBox>
              <TryItNowTag defaultIsOpen>
                <FlexBox items="center" gap="s">
                  <span className="font-bold">New Shipment Dashboard Available!</span>
                  <a
                    className="text-xs underline"
                    href="https://docs.google.com/document/d/15iJpaGG15kpR574JPZ5NOuKLKAiEe_HgAFcLcKQ5nZA/edit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                  <Button size="sm" isCompact onClick={onUpdateDashboardFlag}>
                    Try it Today
                  </Button>
                </FlexBox>
              </TryItNowTag>
            </div>
          </header>
          <div className={hasReadback ? 'table__container hasReadback' : 'table__container'}>
            <TableFilters
              filterMapping={this.state.filterMapping}
              lockedColumns={['reference_id', 'tags', 'archived']}
              columns={parsedColumnsArray}
              defaultColumns={defaultColumns.filter(
                (defaultColumn) =>
                  !(defaultColumn === 'multi_stage_id' || defaultColumn === 'number_of_stages') ||
                  this.props.modeMultiModeDashboard
              )}
              selectedColumns={
                Array.isArray(selectedColumnNames)
                  ? selectedColumnNames.filter(
                      (defaultColumn) =>
                        !(defaultColumn === 'multi_stage_id' || defaultColumn === 'number_of_stages') ||
                        this.props.modeMultiModeDashboard
                    )
                  : []
              }
              parseFiltersForReadback={parseFiltersForReadback}
              filterableColumns={parsedFiltersArray}
              correspondingFilters={{
                //we use this list to clear out corresponding filters when a specified one is cleared.
                pickupStops: [
                  'pickupStopsContains',
                  'pickupStopsStateProvince',
                  'pickupLat',
                  'pickupLon',
                  'pickupRadius',
                  'pickupStopsLabel'
                ],
                deliveryStops: [
                  'deliveryStopsContains',
                  'deliveryStopsStateProvince',
                  'deliveryLat',
                  'deliveryLon',
                  'deliveryRadius',
                  'deliveryStopsLabel'
                ],
                pickup: ['pickupLte', 'pickupGte'],
                dropoff: ['dropoffLte', 'dropoffGte'],
                createdAt: ['createdAtLte', 'createdAtGte'],
                vendor: ['vendorId'],
                customer: ['customerId'],
                status: ['open'],
                createdBy: ['createdBySource', 'createdByUserId'],
                drayage_estimated_arrival_date: ['drayageEstimatedArrivalDateLte', 'drayageEstimatedArrivalDateGte'],
                drayage_release_date: ['drayageReleaseDateLte', 'drayageReleaseDateGte'],
                drayage_last_free_date: ['drayageLastFreeDateLte', 'drayageLastFreeDateGte'],
                drayage_container_return_date: ['drayageContainerReturnDateLte', 'drayageContainerReturnDateGte']
              }}
              applyFiltersToParent={this.applyFilters.bind(this)}
              columnNamesById={columnNamesById}
              updateParentView={this.updateColumns.bind(this)}
              saveView={this.saveView.bind(this)}
              myViews={dashboardConfig && dashboardConfig.configs}
              deleteView={this.deleteView.bind(this)}
              viewLabel="My Dashboards"
              selectParentFilter={(id) => this.handleFilterSelection(id, true)}
              defaultSelectedFilter={this.state.defaultSelectedFilter}
              allFiltersSelected={this.state.allFiltersSelected}
              successfulSaveTrigger={this.state.successfulSaveTrigger}
              parseFilters={this.parseFilters}
              pagination={this.state.pagination}
              tableComponent={tableComponent}
            />
          </div>
        </section>
        <Drawer
          className={`${classes.legacyDrawer} table-details-drawer ${
            this.state.showShipmentDetail ? 'open' : 'closed'
          }`}
          anchor="right"
          variant="persistent"
          open={Boolean(this.state.showShipmentDetail)}
          classes={{paper: classes.legacyDrawerContent}}
        >
          <div className="shipment-drawer-content overflow-hidden">
            <LegacyDashboardSummary
              shipmentId={this.state.shipmentDetail?.v3_shipment_id}
              shipmentLoaded={!this.state.shipmentDetailLoading && this.state.shipmentDetail}
              shipmentDetails={this.state.shipmentDetail}
              renderSidebarDropdownContent={SidebarDropdownContent}
              timelineEvents={this.props.timelineEvents}
              isLoading={this.state.shipmentDetailLoading}
              shipmentInvoices={this.props.shipmentInvoices}
              shipmentPOC={this.state.shipmentPOC}
              company={this.props.company}
              onCloseSidebarDropdown={() =>
                this.setState({
                  showShipmentDetail: false,
                  shipmentPOC: null,
                  selectedShipment: null,
                  shipmentDetail: null
                })
              }
              canViewCarrier={
                this.props.user && this.props.user.permissions && this.props.user.permissions.includes(permViewCarriers)
              }
              canEditShipment={
                this.props.user?.permissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
                this.props.user?.permissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)
              }
              tabOptions={[DETAILS, TIMELINE, CARRIERS, WORKFLOWS, STAGES, CARRIER_BIDS]}
              onShipmentUpdate={() => {
                this.getShipments();
                getShipment(this.state.shipmentDetail.id)
                  .then((response) => {
                    this.setState({
                      selectedShipment: response,
                      shipmentDetail: response,
                      shipmentDetailLoading: false
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      selectedShipment: null,
                      shipmentDetail: null,
                      shipmentDetailLoading: false
                    });
                    console.error(err);
                  });
              }}
              allowStopAction
              showEditStopDropdown
            />
          </div>
        </Drawer>
        {this.state.showV3ShipmentDetail && this.props.modeMultiModeDashboard ? (
          <Drawer
            className={classNames(
              {open: this.state.showV3ShipmentDetail, closed: !this.state.showV3ShipmentDetail},
              'z-[900]'
            )}
            anchor="right"
            variant="persistent"
            open={Boolean(this.state.showV3ShipmentDetail)}
            onClose={this.handleCloseDrawer}
          >
            <div className="h-full w-[100vw] md:w-[50vw] lg:w-[33vw]">
              {this.state.selectedV3Shipment?.v3_shipment_id && this.state.selectedV3Shipment?.id ? (
                <DashboardSummary
                  shipmentId={this.state.selectedV3Shipment.v3_shipment_id}
                  resourceId={this.state.selectedV3Shipment.id}
                  onClose={this.handleCloseDrawer}
                />
              ) : null}
            </div>
          </Drawer>
        ) : null}
        {selectedShipments.length > 0 ? (
          <SelectedCount
            count={selectedShipments.length}
            itemLabel="Shipment"
            className="selected-count__table-overlay"
            clickCta="Deselect All"
            onClick={() => this.setState({selectedShipments: []})}
          />
        ) : null}
        <InfoModalWrapper
          show={this.state.showSharelinkModal}
          bsSize="large"
          extraClass="share-link-modal"
          onHide={() => {
            this.setState({
              showSharelinkModal: false,
              selectedShipment: null
            });
          }}
          title={this.state.showSharelinkTitle}
          error={this.state.showError ? this.state.errors : null}
          children={this.renderShareLinkModal()}
        />
        <InfoModalWrapper
          show={this.state.showArchiveModal}
          onHide={() => {
            this.setState({
              showArchiveModal: false,
              selectedShipment: null
            });
          }}
          title={'Archive Shipment'}
          children={
            <p>
              Are you sure you want to archive:{' '}
              <span>
                {this.state.selectedShipment
                  ? this.state.selectedShipment.name
                    ? this.state.selectedShipment.name
                    : this.state.selectedShipment.reference_id
                  : ''}
              </span>
              ?
            </p>
          }
          primaryAction={{
            label: 'Archive',
            action: (event) => {
              this.updateShipment(
                {
                  metadata: {
                    archived: true,
                    tags: this.state.selectedShipment.metadata.tags,
                    open: this.state.selectedShipment.metadata.open
                  },
                  state: this.state.selectedShipment.state
                },
                event
              );
            }
          }}
        />
        <InfoModalWrapper
          show={this.state.showCancelModal}
          onHide={() => {
            this.setState({
              showCancelModal: false,
              selectedShipment: null
            });
          }}
          title={'Cancel'}
          children={this.renderCancelModal()}
        />
        <InfoModalWrapper
          show={this.state.showNotesModal}
          onHide={() => {
            if (this.state.selectedShipment) {
              localStorage.removeItem(this.state.selectedShipment.id);
            }
            this.setState(
              {
                showNotesModal: false,
                newNote: '',
                selectedShipment: null,
                selectedNoteIdx: null,
                notesPagination: {
                  page: 1,
                  pageSize: 20
                }
              },
              function () {
                this.getShipmentsResource(this.state.pagination, this._count);
                this.count++;
              }.bind(this)
            );
          }}
          title="Internal Notes"
          children={
            <Notes
              currentUser={this.props.user}
              handleKeyPressNew={(e) => {
                if (e.key === 'Enter') {
                  this.onNoteSubmit();
                }
              }}
              handleKeyPressEdit={(e, noteId) => {
                if (e.key === 'Enter') {
                  this.submitEdit(noteId);
                }
              }}
              inputChange={this.handleNoteInputChange}
              inputPlaceholder={'Add a new note here'}
              inputValue={this.state.newNote}
              noteClass=""
              notes={this.props.notes}
              primaryButton={{
                action: this.onNoteSubmit,
                label: 'Save'
              }}
              editNote={this.editNote}
              getMoreNotes={this.getMoreNotes}
              deleteNote={this.deleteNote}
              submitEdit={this.submitEdit}
              editValue={this.state.editNote}
              selectedNoteIdx={this.state.selectedNoteIdx}
              cancelEdit={() => {
                this.setState({selectedNoteIdx: null});
              }}
              pinNote={(note) => {
                this.props
                  .putNote(this.state.selectedShipment.id, note.id, {
                    message: note.message,
                    is_pinned: !note.is_pinned
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      this.props.getNotes(this.state.selectedShipment.id, this.state.notesPagination);
                    }
                  });
              }}
            />
          }
        />
        <Modal
          show={Boolean(this.state.showSchedulePickupModal)}
          title="Schedule Pickup"
          className="schedule-pickup-modal"
          onClose={() => this.setState({showSchedulePickupModal: false})}
          footerComponent={null}
        >
          <SchedulePickup
            shipment={shipment}
            filters={{providerCode}}
            onCancel={() => this.setState({showSchedulePickupModal: false})}
            onCreatePickup={() => {
              this.setState({
                showSchedulePickupModal: false,
                showCreatePickupModal: true
              });
            }}
            onUpdatePickup={this.handleUpdatePickup.bind(this)}
          />
        </Modal>
        <Modal
          show={this.state.showCheckCallModal}
          title="Check Call"
          size="small"
          onClose={() => this.setState({showCheckCallModal: false})}
          footerComponent={null}
        >
          <CheckCall
            shipments={selectedShipments?.map((shipment) => shipment?.id)}
            onClose={() => this.setState({showCheckCallModal: false})}
            onSuccess={(bulkOperationId) => {
              this.setState({showCheckCallModal: false});
              setSuccess(
                'Check Calls Submitted!',
                `Your check calls have been submitted for ${pluralize('shipment', selectedShipments.length, true)}.`
              );
              this.pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_CHECK_CALL);
            }}
          />
        </Modal>
        <Modal
          show={Boolean(this.state.showCreatePickupModal)}
          title="Add Scheduled Pickup"
          footerComponent={null}
          onClose={() => this.setState({showCreatePickupModal: false})}
        >
          <SchedulePickupForm
            onCancel={() => this.setState({showCreatePickupModal: false})}
            onSubmit={this.handleCreatePickup.bind(this)}
            providerCode={providerCode}
            isReadOnlyAddress
            values={getPickupValues(shipment)}
          />
        </Modal>
        <TenderRequestForm
          show={this.state.isTender}
          onClose={() => this.setState({isTender: false})}
          router={this.context.router}
          //this attribute is not needed on shipment details, return true
          submitSucceeded
          shipmentDetailData={this.state.shipmentDetail}
        />
        <Modal
          show={this.state.showRoutingGuideModal}
          title="Push to Routing Guide"
          footerComponent={null}
          onClose={() => this.setState({showRoutingGuideModal: false, performingBulkAction: false})}
        >
          <ExecuteRoutingGuide
            selectedShipment={this.state.shipmentDetail}
            selectedShipments={performingBulkAction ? selectedShipmentIds : null}
            onCancel={() => this.setState({showRoutingGuideModal: false, performingBulkAction: false})}
            onSubmitSuccess={(bulkOperationId) => {
              if (performingBulkAction) {
                setSuccess(
                  'Shipment Bulk Actions Submitted!',
                  `Routing guides have been submitted for ${pluralize('shipment', selectedShipments.length, true)}.`
                );
                this.pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE);
              } else {
                this.setState({
                  showRoutingGuideInitiatedModal: true
                });
              }
              this.setState({
                showRoutingGuideModal: false,
                performingBulkAction: false
              });
            }}
          />
        </Modal>
        <Modal
          show={this.state.showEditShipmentDatesModal}
          title="Edit Shipment Dates"
          footerComponent={null}
          onClose={() => this.setState({showEditShipmentDatesModal: false, performingBulkAction: false})}
          bodyVariant="disableOverflowScroll"
        >
          <EditShipmentDates
            selectedShipmentIds={selectedShipmentIds}
            onCancel={() => this.setState({showEditShipmentDatesModal: false, performingBulkAction: false})}
            onSubmitSuccess={(bulkOperationId) => {
              setSuccess(
                'Shipment Bulk Actions Submitted!',
                `Shipping date changes have been submitted for ${pluralize(
                  'shipment',
                  selectedShipments.length,
                  true
                )}.`
              );
              this.pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES);
              this.setState({
                showEditShipmentDatesModal: false,
                performingBulkAction: false
              });
            }}
            setError={this.props.setError}
          />
        </Modal>
        <Modal
          show={this.state.showChangeShipmentStatusModal}
          title="Change Shipment Status"
          footerComponent={null}
          onClose={() => this.setState({showChangeShipmentStatusModal: false, performingBulkAction: false})}
          bodyVariant="disableOverflowScroll"
        >
          <ChangeShipmentStatus
            selectedShipmentIds={selectedShipmentIds}
            onCancel={() => this.setState({showChangeShipmentStatusModal: false, performingBulkAction: false})}
            onSubmitSuccess={(bulkOperationId) => {
              setSuccess(
                'Shipment Bulk Actions Submitted!',
                `Shipping status changes have been submitted for ${pluralize(
                  'shipment',
                  selectedShipments.length,
                  true
                )}.`
              );
              this.pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS);
              this.setState({
                showChangeShipmentStatusModal: false,
                performingBulkAction: false
              });
            }}
            setError={this.props.setError}
          />
        </Modal>
        <Modal
          show={this.state.showTenderToCarrierModal}
          title="Tender to Carrier"
          footerComponent={null}
          onClose={() => this.setState({showTenderToCarrierModal: false, performingBulkAction: false})}
        >
          <BulkTenderRequestForm
            selectedShipmentsById={selectedShipmentsById}
            onClose={() => this.setState({showTenderToCarrierModal: false, performingBulkAction: false})}
            onSuccess={() => {
              setSuccess(
                'Tender Request Sent!',
                `Tender requests have been sent for ${pluralize('shipment', selectedShipments.length, true)}.`
              );
            }}
            onBulkSuccess={(bulkOperationId) => {
              this.pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_TENDER_TO_CARRIER);
              this.setState({
                showTenderToCarrierModal: false,
                performingBulkAction: false
              });
            }}
          />
        </Modal>
        <Toast
          show={this.state.showRoutingGuideInitiatedModal}
          title="Your Routing Guide is Running!"
          anchor="bottom-right"
          onClose={() => this.setState({showRoutingGuideInitiatedModal: false})}
        >
          {ROUTING_GUIDE_SUCCESS_ON_EXECUTION}
        </Toast>
        <Modal
          show={this.state.showCarrierBidForm}
          title="New Bid"
          footerComponent={null}
          onClose={() => this.setState({showCarrierBidForm: false})}
        >
          <CarrierBidCreateContainer
            shipment={this.state.shipmentDetail}
            onCancel={() => this.setState({showCarrierBidForm: false})}
            onSubmitSuccess={() => {
              this.setState({showCarrierBidForm: false, showCarrierBidSuccess: true});
            }}
          />
        </Modal>
        <Modal
          fullScreen={showImportFullScreen}
          show={this.state.showImportShipmentsModal}
          title="Import Shipments"
          footerComponent={null}
          onClose={() => {
            this.setState({
              showImportShipmentsModal: false,
              showImportFullScreen: false
            });
          }}
        >
          <CreateImport
            setUseFullScreen={(useFullScreen) => this.setState({showImportFullScreen: useFullScreen})}
            onCancel={() => {
              this.setState({
                showImportShipmentsModal: false,
                showImportFullScreen: false
              });
            }}
            type="SHIPMENTS"
            setShowImportSuccess={(numberOfSuccessImports) =>
              this.setState({showShipmentsImportSuccessToast: numberOfSuccessImports})
            }
          />
        </Modal>
        <Modal
          show={this.state.dashboardDocumentModal.showModal}
          title={
            this.state.dashboardDocumentModal.mode === PRINT_DOCUMENT
              ? `Print ${documentTitle}s`
              : `Email ${documentTitle}s`
          }
          footerComponent={null}
          onClose={() => this.setState({dashboardDocumentModal: {showModal: false, mode: '', documentType: ''}})}
        >
          <ShipmentDocument
            mode={this.state.dashboardDocumentModal.mode}
            onCancel={() => this.setState({dashboardDocumentModal: {showModal: false, mode: '', documentType: ''}})}
            shipments={this.state.selectedShipments}
            documentType={this.state.dashboardDocumentModal.documentType}
          />
        </Modal>
        <Toast
          show={this.state.showShipmentsImportSuccessToast}
          title="Success!"
          variant="success"
          anchor="bottom-right"
          onClose={() => this.setState({showShipmentsImportSuccessToast: false})}
        >
          {this.state.showShipmentsImportSuccessToast} records imported.
        </Toast>
        <Toast
          show={this.state.showCarrierBidSuccess}
          title="Bid Submitted!"
          anchor="bottom-right"
          onClose={() => this.setState({showCarrierBidSuccess: false})}
        >
          Carrier bid submitted successfully.
        </Toast>
        <Loader show={loadingMessage}>{loadingMessage}</Loader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.auth.company,
    shipments: state.shipments.all,
    isLoading: state.shipments.isLoading,
    user: state.auth.user,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    dashboard: state.shipments.dashboard,
    customers: state.brokers.shipperRelationships,
    notes: state.shipmentdetails.notes,
    areNotesLoading: state.shipmentdetails.areNotesLoading,
    loadingCustomerRelationships: state.brokers.loadingCustomerRelationships,
    shareLinkForm: state.form.shareLinkForm,
    companyUsers: state.users.companyUsers,
    carrierRelationships: state.vendors.carrierRelationships,
    stop: state.shipmentdetails.stop,
    isStopsLoading: state.shipmentdetails.isStopsLoading,
    tags: state.shipments.tags,
    dashboardConfig: state.userPreferences.dashboardConfig,
    defaultConfig: state.userPreferences.dashboardConfig.defaultConfig,
    allColumns: state.userPreferences.dashboardConfig.allColumns,
    defaultColumns: state.userPreferences.dashboardConfig.defaultColumns,
    one: state.shipments.one,
    timelineEvents: state.shipmentdetails.timelineEvents,
    shipmentInvoices: state.integrations.shipmentInvoices,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    isFedExEnabled: state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.fedex_enabled,
    hasContractsViewPermission:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts'),
    isContractsEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.contracts_enabled,
    isRoutingGuidesEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.policies_enabled,
    isBidManagerEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.bid_manager_enabled,
    shipmentAccessorials: state.shipments.shipmentAccessorialCodes
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      ...brokerActions,
      ...shipmentDetailsActions,
      ...carrierActions,
      ...userActions,
      ...integrationActions,
      showErrorToast: (message) => dispatch({type: ALERT_ERROR, payload: message})
    },
    dispatch
  );
};

export default compose(
  withFlags(
    'wfaBulkActions',
    'wfaBulkActionShipmentStatusChange',
    'fmNewCreateIntermodal',
    'modeShippingDashboard',
    'modeMultiModeDashboard',
    'stmDashboardColumnsCustomReferenceFields',
    'ciAuctionStats'
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
  WithStatusToasts,
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment),
  renameProp('customFields', 'referencesCustomFields')
)(Dashboard);
