import {ChangeEvent, useState} from 'react';
import {Button, RadioGroup, SvgIcon, DatePicker, Textarea} from '@shipwell/shipwell-ui';
import invariant from 'tiny-invariant';

const RejectReasonsList = ['Dirty', 'Temperature', 'Damaged', 'Broken Seal', 'Faulty Trailer', 'Other'] as const;
export type RejectReason = (typeof RejectReasonsList)[number];
const RejectReasonRadioOptions = RejectReasonsList.map((reason) => ({
  label: reason,
  value: reason
}));

export function AppointmentVerbConfirmation(props: {
  timezone?: string;
  verb: 'checkIn' | 'checkOut' | 'reject';
  onCancel: () => unknown;
  onSave: (checkInTime: Date, reason?: string) => unknown;
}) {
  const {timezone, verb, onCancel, onSave} = props;
  const [timeValue, setTimeValue] = useState<Date>(new Date());
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const timeHeading = {
    checkIn: 'Check In Time',
    checkOut: 'Check Out Time',
    reject: undefined
  }[verb];
  const saveEnabled = verb !== 'reject' || reason;
  return (
    <div className="flex size-full flex-col border-t-1 border-sw-divider p-2">
      {timeHeading ? (
        <>
          <h5 className="flex-0 flex">{timeHeading}</h5>
          <DatePicker
            timezone={timezone}
            value={timeValue}
            onChange={(value: string | null) => {
              invariant(value, 'confirmingVerb should be set');
              setTimeValue(new Date(value));
            }}
            label={timeHeading}
            required
          />
        </>
      ) : null}
      {verb === 'reject' ? (
        <>
          <div className="my-2 flex flex-row rounded bg-sw-error-background">
            <SvgIcon name="ErrorFilled" className="m-2" width="24" height="24" color="red-alert" />
            <div className="my-1 text-xs">
              <strong className="my-1">Reject Appointment</strong>
              <p>Select the reason for rejection</p>
            </div>
          </div>
          <RadioGroup
            options={RejectReasonRadioOptions}
            value={reason}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setReason(event.target.value)}
          />
          <Textarea
            name="rejection-reason"
            label="REJECTION REASON"
            disabled={reason !== 'Other'}
            value={reason === 'Other' ? otherReason : ''}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setOtherReason(event.target.value);
            }}
          />
        </>
      ) : null}
      <div className="flex flex-1">&nbsp;</div>
      <div className="flex-0 grid grid-cols-2 gap-2 p-2">
        <Button onClick={onCancel} color="warning" variant="secondary">
          Go Back
        </Button>

        <Button
          color="primary"
          disabled={!saveEnabled}
          onClick={() => {
            const theReason = reason === 'Other' ? otherReason : reason;
            onSave(timeValue, theReason);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
