import {useReducer} from 'react';

/*
 * Hook for encapsulating the much repeated logic around boolean values in state (modals f.ex.).
 * Returns an array of the current boolean value, and a function to call when it is necessary reverse that value.
 *
 * @param {Boolean} initialValue
 *
 */

const useToggle = (initialValue = false) => useReducer((booleanState) => !booleanState, initialValue);

export default useToggle;
