import {useMemo} from 'react';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {StepStatusEnum} from '../stepStatusEnum';
import {getIconForStatus} from './helpers';

export interface GeneralStatusProps {
  status: StepStatusEnum;
}

export function GeneralStatus(props: GeneralStatusProps) {
  const status = useMemo(() => getIconForStatus(props.status), [props.status]);

  return (
    <div className={`flex items-center gap-1 font-bold ${status.className}`}>
      {status.text}
      <SvgIcon name={status.iconName} aria-label={props.status} />
    </div>
  );
}
