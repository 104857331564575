import isNil from 'lodash/isNil';
import threeDigitZipCodes from './threeDigitZipCodes';

interface ThreeDigitPostalCodeResult {
  id: string;
  city: string;
  state: string;
}

// currently very US-centric and does not account for spelled out states
export function getThreeDigitPostalCodes(value: string) {
  const splitValue = splitInput(value);
  //only entries with a state are valid
  const validThreeDigitPostalCodes = Object.values(threeDigitZipCodes).filter(
    (zip): zip is typeof zip & {state: string} => !isNil(zip.state)
  );

  const results = validThreeDigitPostalCodes.filter((zip) => {
    const splitThreeDigitZip = [zip.id].concat(splitInput(zip.city), [zip.state.toLowerCase()]).filter(Boolean);
    return splitValue.every((val) => splitThreeDigitZip.includes(val));
  });
  //prevents returning results for an entire state!
  return results.every((result) => result.city === results[0].city) ? results : [];
}

const splitInput = (input: string) => {
  return input
    .trim()
    .toLowerCase()
    .split(/\.|,| /)
    .filter((value) => !['us', 'usa'].includes(value))
    .filter(Boolean);
};

type AllowedThreeDigitPostalCodeOptionalValues = 'city' | 'state_province';
type ThreeDigitPostalCodeOptionalValues = Array<AllowedThreeDigitPostalCodeOptionalValues>;

//formats results for multiAddressSearch
const formatThreeDigitPostalCodeResults = (
  results: ThreeDigitPostalCodeResult[],
  threeDigitPostalCodeOptionalValues?: ThreeDigitPostalCodeOptionalValues
) => {
  return results.map((result) => {
    const includeCity =
      isNil(threeDigitPostalCodeOptionalValues) || threeDigitPostalCodeOptionalValues?.includes('city');
    const includeStateProvince =
      isNil(threeDigitPostalCodeOptionalValues) || threeDigitPostalCodeOptionalValues?.includes('state_province');
    const value = {
      ...(includeCity ? {city: result.city} : {}),
      ...(includeStateProvince ? {state_province: result.state} : {}),
      postal_code: result.id,
      country: 'US',
      formatted_address: `${result.id} - ${result.city}, ${result.state}, US`
    };
    return {value, ...value, validated: true, zipIcon: true};
  });
};

//specifically for multiAddressSearch
//postalCodeOnly returns values without city and state, needed for backend lat/long geocode feature
export function getFormattedThreeDigitPostalCodes(
  value: string,
  threeDigitPostalCodeOptionalValues?: ThreeDigitPostalCodeOptionalValues
) {
  return formatThreeDigitPostalCodeResults(getThreeDigitPostalCodes(value), threeDigitPostalCodeOptionalValues);
}

type GoogleGeocoderResult = {
  address_components?: Array<{
    types?: Array<string>;
    long_name?: string;
    short_name?: string;
  }>;
};

//determines if the google place result is a canadian three digit postal code
export const isCanadianThreeDigitPostalCode = (address?: GoogleGeocoderResult) => {
  const {address_components: addressComponents} = address || {};
  const isCanadianAddress =
    addressComponents?.find((addressComponent) => addressComponent.types?.includes('country'))?.long_name === 'Canada';
  const isThreeDigitPostalCode =
    addressComponents?.find((addressComponent) => addressComponent.types?.includes('postal_code_prefix'))?.long_name
      ?.length === 3;
  return isCanadianAddress && isThreeDigitPostalCode;
};

//returns a formatted canadian three digit postal code from a google place result
export const formatCanadianThreeDigitPostalCode = (
  address?: GoogleGeocoderResult,
  threeDigitPostalCodeOptionalValues?: ThreeDigitPostalCodeOptionalValues
) => {
  const includeCity = isNil(threeDigitPostalCodeOptionalValues) || threeDigitPostalCodeOptionalValues?.includes('city');
  const includeStateProvince =
    isNil(threeDigitPostalCodeOptionalValues) || threeDigitPostalCodeOptionalValues?.includes('state_province');
  const {address_components: addressComponents} = address || {};
  const postalCode = addressComponents?.find((addressComponent) =>
    addressComponent.types?.includes('postal_code_prefix')
  )?.long_name;
  const stateProvince = addressComponents?.find((addressComponent) =>
    addressComponent.types?.includes('administrative_area_level_1')
  )?.short_name;
  const city = addressComponents?.find((addressComponent) =>
    addressComponent.types?.includes('administrative_area_level_2')
  )?.long_name;

  return {
    postal_code: postalCode,
    ...(includeCity ? {city} : {}),
    ...(includeStateProvince ? {state_province: stateProvince} : {}),
    country: 'CA'
  };
};
