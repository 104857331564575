import {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import get from 'lodash/get';
import {getShipmentDetailsPromise} from 'App/api/shipment';
import ShipmentStops from 'App/containers/Shipment/ShipmentStops';
import ShipmentReferences from 'App/containers/Shipment/ShipmentReferences';
import ShipmentLineItems from 'App/containers/Shipment/ShipmentLineItems';
import ShipmentVendorEquipment from 'App/containers/Shipment/ShipmentVendorEquipment';
import ShipwellLoader from 'App/common/shipwellLoader';

const SupplierShipmentDetails = (props) => {
  const {dispatchError, setDispatchError, allow_autobook} = props;
  const shipmentId = get(props, 'shipment.id') || get(props, 'params.shipment_id');
  const [shipment, setShipment] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!shipment.id) {
      fetchShipment();
    }
  }, []);

  const fetchShipment = async () => {
    setLoading(true);
    try {
      const response = await getShipmentDetailsPromise(shipmentId);
      setShipment(response.body);
      if (response.body.id && allow_autobook && !response.body.most_recently_awarded_quote_id) {
        //we weren't able to dispatch this shipment successfully, show an error
        setDispatchError(
          'Sorry, this shipment was created but we were unable to book a quote. Please contact your customer to complete booking.'
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //additional error handling here, since the parent component simply checks if the auth token is valid
      //this handles errors when fetching the shipment
      setError('Unable to load the shipment details. Please check your link and try again.');
    }
  };

  if (loading) {
    return (
      <div className="supplierShipment__loading">
        <ShipwellLoader loading />
      </div>
    );
  }

  return (
    <div className="supplierShipment__details shipment">
      {error && (
        <h3 className="supplierShipment__error">
          <i className="icon icon-Delayed pad-right" />
          {error}
        </h3>
      )}
      {shipment.id && !error && (
        <>
          <h3>Shipment Details</h3>
          <div className="supplierShipment__grid">
            <div className="supplierShipment__grid-item">
              <div className="supplierShipment__grid-vertical">
                <div className="supplierShipment__grid-item">
                  <Paper className="shipment__card">
                    <ShipmentStops
                      one={shipment}
                      isFTL={shipment.mode && shipment.mode.id === 1}
                      isLTL={shipment && shipment.mode && (shipment.mode.id === 2 || shipment.mode.id === 4)}
                      canViewOrders={false}
                      shipmentAccessorials={shipment.accessorials}
                      shipmentId={shipment.id}
                      stops={shipment.stops}
                      fetchDetails={fetchShipment}
                    />
                  </Paper>
                </div>
                <div className="supplierShipment__grid-item">
                  <div className="shipment__items">
                    <ShipmentLineItems
                      isFTL={shipment && shipment.mode && shipment.mode.id === 1}
                      isLTL={shipment && shipment.mode && (shipment.mode.id === 2 || shipment.mode.id === 4)}
                      canViewOrders={false}
                      shipmentId={shipment.id}
                      lineitems={shipment.line_items}
                      fetchDetails={fetchShipment}
                      packageTypes={[]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="supplierShipment__grid-item">
              <div className="supplierShipment__grid-vertical">
                <div className="supplierShipment__grid-item">
                  <Paper className="shipment__card">
                    <ShipmentReferences
                      canViewOrders={false}
                      showCustomerField={false}
                      showShipmentReps={false}
                      shipmentId={shipment.id}
                      fetchDetails={fetchShipment}
                      one={shipment}
                      purchaseOrders={[]}
                    />
                  </Paper>
                </div>
                <div className="supplierShipment__grid-item">
                  <div className="shipment__items">
                    <ShipmentVendorEquipment
                      awardedQuote={null}
                      externalForm
                      vendorAssignment={shipment.relationship_to_vendor}
                      carrierNotes={shipment.notes_for_carrier}
                      carrierRelationships={[]}
                      company={{}}
                      equipmentConfig={shipment.equipment_config}
                      fetchDetails={fetchShipment}
                      isLTL={shipment && shipment.mode && (shipment.mode.id === 2 || shipment.mode.id === 4)}
                      selectedEquipmentType={shipment.equipment_type}
                      selectedMode={shipment.mode}
                      selectedServiceLevel={shipment.service_level || {}}
                      serviceLevels={[]}
                      shipment={shipment}
                      shipmentModes={[]}
                      isManualBook={!allow_autobook}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupplierShipmentDetails;
