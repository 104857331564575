/* eslint-disable camelcase */
/* eslint-disable import/no-namespace */
import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import {Button, FormControl, FormGroup, InputGroup, OverlayTrigger, Tooltip, Popover, Overlay} from 'react-bootstrap';
import ReactTable, {ReactTableDefaults} from 'react-table-6';
import Drawer from '@material-ui/core/Drawer';
import {Link} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Snackbar from '@material-ui/core/Snackbar';
import queryString from 'query-string';
import moment from 'moment';
import {Toast, SvgIcon, IconButton} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {FILTERABLE_LOADBOARD_COLUMNS} from '../../reducers/userPreferences/loadBoardConfig';
import columns from './components/Columns';
import PlaceBidModal from './components/PlaceBidModal';
import {acceptTender, rejectTender} from 'App/api/tenders';
import TableFilters from 'App/components/TableFiltersWithSavedViews';
import * as shipmentActions from 'App/actions/shipments';
import * as marketplaceActions from 'App/actions/marketplace';
import * as brokerActions from 'App/actions/brokers';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import * as userActions from 'App/actions/users';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import DashboardSummary from 'App/components/dashboardSummary';
import {unpackErrors} from 'App/utils/globals';
import {parseTableFilters, parseFiltersForReadback} from 'App/utils/shipmentTableHelpers';
import 'react-table-6/react-table.css';
import './_loadBoard.scss';
import filterMapping from 'App/containers/LoadBoard/components/ColumnFilterMapping';
import BookNowConfirmation from 'App/containers/LoadBoard/components/BookNowConfirmation';
import TenderRejectForm from 'App/containers/LoadBoard/components/TenderRejectForm';
import {formatCurrency} from 'App/utils/internationalConstants';
import {tenderStatuses} from 'App/containers/tendering/create/utils/constants';
import {getShipwellUiUserPreferencesPromise, editShipwellUiUserPreferencesPromise} from 'App/api/shipwellUI';
import {INTERMODAL_FILTER_OPTION} from 'App/utils/shipmentModeFilterOptions';
import withFlags from 'App/utils/withFlags';
import WithStatusToasts from 'App/components/withStatusToasts';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const hoveredLoadId = null;
const drawerWidth = '400px';
const styles = (theme) => ({
  drawer: {
    flexShrink: 0,
    zIndex: 1
  },
  drawerContent: {
    position: 'absolute',
    maxWidth: drawerWidth,
    width: '100%',
    border: 0,
    top: '0px',
    height: 'calc(100vh - 67px)',
    background: 'transparent',
    transition: 'transform 0.1s'
  },
  dashboardOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `calc(100% - ${drawerWidth})`
  },
  dashboardClosed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: '100%'
  }
});

//to address loadboard slowness, we are sending these bidding statuses by default
const defaultBiddingStatuses = ['open', 'bidding', 'won', 'tendered', 'accepted'];

const initialPagination = {
  biddingStatus: defaultBiddingStatuses,
  page: 1,
  pageSize: 50,
  ordering: ['-match_score']
};

const initialFilters = {
  pickupGte: Array.of(moment().format('YYYY-MM-DD')),
  pickupLte: Array.of(moment().add(7, 'days').format('YYYY-MM-DD'))
};

const columnSortingMapping = {
  equipment_type: 'equipment',
  'match_score,-created_at': 'match_score',
  pickup: 'pickup_date',
  dropoff: 'delivery_date'
};

export class LoadBoard extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.searchDataTable = this.searchDataTable.bind(this);
    this.goToShipment = this.goToShipment.bind(this);
    this.getLoadBoardShipments = this.getLoadBoardShipments.bind(this);
    this.setParentState = this.setParentState.bind(this);
    this.openBidForm = this.openBidForm.bind(this);
    this.closeBidForm = this.closeBidForm.bind(this);
    this.handleDeleteQuote = this.handleDeleteQuote.bind(this);
    this.interval;
    this.fetchData = _.debounce(this.fetchData.bind(this), 300, {
      trailing: true,
      leading: false
    });
    this.columns = {
      load_board_id: columns.load_board_id,
      lowest_bid_amount: columns.lowest_bid_amount,
      bidding_status: columns.bidding_status,
      total_miles: columns.total_miles,
      customer: columns.customer,
      pickup_location: columns.pickup_location,
      pickup_date: columns.pickup_date,
      delivery_location: columns.delivery_location,
      delivery_date: columns.delivery_date,
      stops: columns.stops,
      requested_shipment_modes: columns.requested_shipment_modes,
      requested_equipment_types: columns.requested_equipment_types,
      weight: columns.weight,
      lowest_rate: columns.lowest_rate,
      created_at: columns.created_at,
      expiresAt: columns.expiresAt,
      valid_until: columns.valid_until,
      match_score: columns.match_score,
      buy_it_now_amount: {
        Header: () => (
          <span>
            Rate
            <span className="btn-sort" />
          </span>
        ),
        fixed: 'left',
        id: 'buy_it_now_amount',
        accessor: (d) => d.buy_it_now_amount,
        minWidth: 192,
        sortable: true,
        Cell: (row) => {
          const text = (
            <span className="loadBoard__rate-text">
              {row.original.tenders && row.original.tenders.length
                ? 'Tendered: '
                : row.value && row.original.bidding_status !== 'closed' && !row.original.tenders.length
                ? 'Book Now: '
                : row.value && (row.original.bidding_status === 'won' || row.original.bidding_status === 'accepted')
                ? 'Booked: '
                : 'Auction '}
            </span>
          );
          let price =
            row.value && parseFloat(row.value) > 0 ? (
              <span className={`${row.original.tenders.length ? '' : 'text-success'}`}>
                {formatCurrency(row.value, row.original.preferred_currency)}
              </span>
            ) : (
              <span>--</span>
            );
          const canBookNow =
            row.value &&
            parseFloat(row.value) > 0 &&
            row.original.bidding_status !== 'closed' &&
            !row.original.tenders.length;

          if (row.original.tenders && row.original.tenders.length > 0) {
            const openTenderAmount = this.getMostRecentOpenTender(row.original.tenders)?.charge_line_items.reduce(
              (total, item) => total + item.unit_amount * item.unit_quantity,
              0
            );

            price = (
              <span className={`${row.original.tenders.length ? '' : 'text-success'}`}>
                {formatCurrency(openTenderAmount, row.original.preferred_currency)}
              </span>
            );
          }

          return (
            <div
              onClick={
                canBookNow && !['INACTIVE', 'DO_NOT_USE'].includes(row.original?.spot_negotiation_carrier_status)
                  ? (e) => this.showBuyItNow(row.original, e)
                  : () => {}
              }
              className="loadBoard__rateContainer"
            >
              {text}
              {price}
            </div>
          );
        }
      },
      actions: {
        Header: '',
        resizable: false,
        headerClassName: 'hidden',
        className: 'loadBoard__actions',
        width: 0,
        id: 'actions',
        accessor: (d) => d,
        Cell: (row) => {
          return this.renderActions(row);
        }
      }
    };

    this.attachRef = (target) => this.setState({target: target});
    this.attachBidRef = (bidTarget) => this.setState({bidTarget: bidTarget});

    this.state = {
      temporaryColumnOverride: null,
      pagination: initialPagination,
      filters: [],
      searchValue: '',
      showBuyItNowModal: false,
      selectedShipment: null,
      showBookNowToast: false,
      justBookedShipment: null,
      showBidForm: false,
      defaultSelectedFilter: null,
      shouldLoadView: this.props.location?.query.view,
      awaitingInitialLoad: true,
      rejectReason: null,
      customRejectReason: '',
      showTenderAcceptError: false,
      tenderAcceptErrorMessage: '',
      submittingBookNow: false,
      performingTenderAction: false,
      biddingOnShipment: {},
      showBidPopover: false,
      useInitialFilters: true,
      tableRowDefaults: {},
      filterMapping: filterMapping,
      loadingSpotNegotationDetails: false,
      isDeletingQuote: false
    };
  }

  async componentDidMount() {
    this.handleFilterMapping();
    await this.getUserPageSize();
    if (!_.isEmpty(this.props.company)) {
      this.props.getUserTableConfiguration({pageSize: 100, tableType: 'LOADBOARD'});
      this.props.fetchEquipmentTypes();
      this.props.fetchShipmentModes();
    }
    if (this.props.loadboardConfig?.configs?.length && this.state.shouldLoadView) {
      //when the URL contains a view id in it
      this.handleFilterSelection(this.state.shouldLoadView);
      this.setState({shouldLoadView: null, hasLoadedInitial: true});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.props.getUserTableConfiguration({pageSize: 100, tableType: 'LOADBOARD'});
      this.props.fetchEquipmentTypes();
      this.props.fetchShipmentModes();
    }
    if (this.props.selectedSpotNegotiation?.id !== nextProps.selectedSpotNegotiation?.id) {
      clearInterval(this.interval);
      this.setState({showBidForm: false});
    }
  }

  componentDidUpdate(prevProps) {
    const {defaultConfig} = this.props;
    if (
      prevProps.loadboardConfig !== this.props.loadboardConfig &&
      this.props.loadboardConfig.configs.length &&
      (!this.state.hasLoadedInitial || !this.state.shouldLoadView) &&
      this.state.pagination.pageSize // todo check this effect
    ) {
      if (this.state.shouldLoadView) {
        if (
          this.state.shouldLoadView &&
          this.props.loadboardConfig.configs.filter((e) => e.id === this.state.shouldLoadView).length > 0
        ) {
          //there is a view in the URL and it matches one of the user's configs, load that
          this.handleFilterSelection(this.state.shouldLoadView);
          this.setState({shouldLoadView: null, hasLoadedInitial: true});
        } else {
          //no user view to match this, but check for other query params
          if (!_.isEmpty(this.props.location.query)) {
            this.handleLoadQueryParams();
          }
        }
      } else if (!this.state.shouldLoadView && !defaultConfig) {
        if (!_.isEmpty(this.props.location.query)) {
          this.handleLoadQueryParams();
        } else {
          //no default to load, just load the shipwell default
          this.handleFilterSelection(null);
        }
      } else if (defaultConfig && _.isEmpty(this.props.location.query)) {
        this.handleFilterSelection(defaultConfig.id);
        this.setState({hasLoadedInitial: true});
      } else if (defaultConfig && !_.isEmpty(this.props.location.query)) {
        this.handleLoadQueryParams();
      }
    } else if (
      prevProps.loadboardConfig !== this.props.loadboardConfig &&
      !this.props.loadboardConfig.configs.length &&
      !this.props.loadboardConfig.isFetching &&
      !this.state.shouldLoadView &&
      !defaultConfig &&
      this.state.pagination.pageSize // todo check this effect
    ) {
      if (!_.isEmpty(this.props.location.query) && !this.state.hasLoadedInitial) {
        this.handleLoadQueryParams();
      } else {
        //just load the shipwell default since there are no configs to load
        this.handleFilterSelection(null);
      }
    }
    if (
      defaultConfig &&
      defaultConfig !== prevProps.defaultConfig &&
      _.isEmpty(this.props.location.query) &&
      !this.state.shouldLoadView &&
      !this.state.hasLoadedInitial
    ) {
      if (!_.isEmpty(this.props.location.query)) {
        this.handleLoadQueryParams();
      } else {
        //load the default config immediately if it changes
        this.handleFilterSelection(defaultConfig.id);
        this.setState({hasLoadedInitial: true});
      }
    }
  }

  handleLoadQueryParams() {
    //we have parameters to search with but not a selected filter
    const queryParams = this.props.location.query;
    Object.keys(queryParams).forEach((key) => {
      if (typeof queryParams[key] === 'string' && key !== 'view') {
        queryParams[key] = [queryParams[key]];
      }
      //special handling for customer and vendor which use IDs
      if (key === 'customer') {
        const ids = queryParams.customerId;

        queryParams.customer = queryParams.customer.map((e, i) => {
          return {label: e, value: ids[i]};
        });
      }
    });
    const pagination = Object.assign({}, initialPagination, queryParams, {
      pageSize: this.state.tableRowDefaults?.LOADBOARD
    });
    this.setState({pagination, allFiltersSelected: queryParams, awaitingInitialLoad: false}, () => {
      this.updateColumns(this.props.defaultColumns);
      this.getLoadBoardShipments();
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getMostRecentOpenTender(tenders) {
    const {EXPIRED, REJECTED, REVOKED} = tenderStatuses;
    //use the tender amount from the most recently created open tender
    let openTenders = tenders.filter((tender) => ![EXPIRED, REJECTED, REVOKED].includes(tender.status));
    openTenders = openTenders.sort((a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    });
    return openTenders[0];
  }

  async getLoadBoardShipments() {
    const pagination = {...this.state.pagination, pageSize: this.state.tableRowDefaults?.LOADBOARD};
    const response = await this.props.getLoadBoardShipments(pagination, this._count, 'getLoadBoardShipments');
    if (response) {
      this._count++;
    }
  }

  async getUserPageSize() {
    try {
      const res = await getShipwellUiUserPreferencesPromise();
      const newPagination = {
        ...this.state.pagination,
        pageSize: res.body.table_row_defaults?.LOADBOARD ?? this.state.pagination.pageSize
      };
      this.setState({pagination: newPagination}, () => {
        this.setState({tableRowDefaults: res.body.table_row_defaults});
      });
      return Promise.resolve(res.body.table_row_defaults?.LOADBOARD);
    } catch (error) {
      console.error(error);
    }
  }

  async setUserPageSize(pageSize) {
    try {
      const newTableSize = {LOADBOARD: pageSize};
      const body = {table_row_defaults: {...this.state.tableRowDefaults, ...newTableSize}};
      const res = await editShipwellUiUserPreferencesPromise(body);
      this.setState({tableRowDefaults: res.body.table_row_defaults});
    } catch (error) {
      console.error(error);
    }
  }

  //bidform
  async openBidForm() {
    this.setState({loadingSpotNegotationDetails: true});
    /* fetch the correct spot negotiation details, meaning the one that's shown on the side panel,
       as opposed to fetching the spot negotiation details for a shipment from another row */
    await this.props.getSpotNegotiationDetails(this.state.shipmentDetail?.spot_negotiation);
    this.setState({showBidForm: true, loadingSpotNegotationDetails: false});
  }
  closeBidForm() {
    this.setState({showBidForm: false});
  }

  handlePlaceBidSuccess = async (spotNegotiationId) => {
    await this.props.getSpotNegotiationQuotes(spotNegotiationId, {
      page: 1,
      pageSize: 25
    });
    this.props.clearSelectedCarrierRate();
    this.props.getLoadBoardShipments(this.state.pagination, this._count);
    if (this.state.selectedShipment) {
      this.fetchShipmentDetails(this.state.selectedShipment.load_board_id);
    }
    this._count++;
    this.setState({showBidPopover: false, biddingOnShipment: {}});
  };

  async handleDeleteQuote(quote) {
    this.setState({isDeletingQuote: true});
    const response = await this.props.deleteSpotNegotiationQuote(this.props.selectedSpotNegotiation.id, quote.id);
    if (response) {
      if (response.status === 200) {
        await this.props.getSpotNegotiationQuotes(this.props.selectedSpotNegotiation.id);
        if (this.state.selectedShipment) {
          this.fetchShipmentDetails(this.state.selectedShipment.load_board_id, {fetchQuotes: false});
        }
        this._count++;
        this.props.getLoadBoardShipments(this.state.pagination, this._count);
      }
      this.setState({isDeletingQuote: false});
    }
  }

  renderActions(row) {
    const {original} = row;
    const {tenders, bidding_status, buy_it_now_amount} = original;
    const showTender =
      tenders.length > 0 &&
      (bidding_status === 'tendered' || bidding_status === 'accepted' || bidding_status === 'bidding');
    const isClosed = bidding_status === 'closed';
    const showBid = !tenders.length && (bidding_status === 'open' || bidding_status === 'bidding');
    const buyItNowPrice = buy_it_now_amount;

    const {showReject, showBidPopover, performingTenderAction, rejectingShipment} = this.state;
    const placement = row.index < 2 ? 'bottom' : 'top';
    if (isClosed) {
      return '';
    }
    if (!showTender && !showBid) {
      return '';
    }

    return (
      <div className="loadBoard__actions-content gap-x-2">
        {showTender && (
          <>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="loadBoard__actionsTooltip">
                  <span>Reject Tender</span>
                </Tooltip>
              }
            >
              <IconButton
                disabled={performingTenderAction}
                id={`reject-${original.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    showReject: !showReject,
                    rejectingShipment: original,
                    popoverPosition: placement
                  });
                }}
                color="warning"
                iconName="CloseCircleOutlined"
              />
            </OverlayTrigger>
            <Overlay
              target={() => {
                return document.getElementById(`reject-${original.id}`);
              }}
              rootClose
              onHide={() => this.setState({showReject: false, rejectingShipment: null})}
              placement={placement}
              show={showReject && rejectingShipment.id === original.id}
            >
              <Popover onClick={(e) => e.stopPropagation()} id="loadBoard__reject" title="Reject Tender">
                <TenderRejectForm
                  rejectingShipment={rejectingShipment}
                  performingTenderAction={performingTenderAction}
                  rejectTender={this.rejectTender.bind(this)}
                  handleCancel={() => this.setState({showReject: false, rejectingShipment: null})}
                />
              </Popover>
            </Overlay>
            {row.original?.bidding_status !== 'accepted' &&
              [null, 'ACTIVE'].includes(row.original?.spot_negotiation_carrier_status) && (
                <OverlayTrigger
                  placement="bottom"
                  shouldUpdatePosition
                  overlay={
                    <Tooltip id="loadBoard__actionsTooltip">
                      <span>Accept Tender</span>
                    </Tooltip>
                  }
                >
                  <IconButton
                    disabled={performingTenderAction}
                    onClick={(e) => !performingTenderAction && this.acceptTender(e, row.original)}
                    color="success"
                    iconName="CheckCircleFilled"
                  />
                </OverlayTrigger>
              )}
          </>
        )}

        {showBid && (
          <>
            {row.original?.spot_negotiation_carrier_status !== 'DO_NOT_USE' && (
              <OverlayTrigger
                placement="bottom"
                shouldUpdatePosition
                overlay={
                  <Tooltip id="loadBoard__actionsTooltip">
                    <span>Place Bid</span>
                  </Tooltip>
                }
              >
                <IconButton
                  id={`placeBid-${row.original.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (row.original.spot_negotiation) {
                      //get SN details
                      this.props.getSpotNegotiationDetails(row.original.spot_negotiation);
                    }
                    this.setState({showBidPopover: true, biddingOnShipment: row.original});
                  }}
                  color="primary"
                  iconName="AddCircleOutlined"
                />
              </OverlayTrigger>
            )}
            <PlaceBidModal
              loadboardShipment={row.original}
              handleClose={() => this.setState({showBidPopover: false, biddingOnShipment: {}})}
              showPlaceBidModal={showBidPopover && this.state.biddingOnShipment.id === row.original.id}
              handlePlaceBidSuccess={this.handlePlaceBidSuccess.bind(this)}
              setError={this.props.setError}
            />
            {buyItNowPrice > 0 && [null, 'ACTIVE'].includes(row.original?.spot_negotiation_carrier_status) && (
              <OverlayTrigger
                placement="bottom"
                shouldUpdatePosition
                overlay={
                  <Tooltip id="loadBoard__actionsTooltip">
                    <span>Book Now</span>
                  </Tooltip>
                }
              >
                <IconButton
                  onClick={(e) => this.showBuyItNow(row.original, e)}
                  iconName="CheckCircleFilled"
                  color="success"
                />
              </OverlayTrigger>
            )}
          </>
        )}
      </div>
    );
  }

  /*
   * Search for shipments using the searchbar
   */
  searchDataTable(e) {
    //clear out any filters in the URL
    this.handleUpdateURL(null);
    clearTimeout(this.timer);
    const str = e.target.value;
    //set the searchvalue and clear any selected filter
    this.setState({searchValue: str, defaultSelectedFilter: null}, () => {
      if (str.length >= 3 || !str) {
        this.timer = setTimeout(async () => {
          //include all bidding statuses when searching
          const closedOption = !str
            ? {}
            : {biddingStatus: ['won', 'bidding', 'open', 'closed', 'tendered', 'accepted', 'rejected', 'expired']};
          const pagination = Object.assign(
            {},
            initialPagination,
            closedOption,
            {q: str},
            {pageSize: this.state.tableRowDefaults?.LOADBOARD}
          );
          const response = await this.props.getLoadBoardShipments(pagination, this._count);
          if (response) {
            this.setState({
              pagination: pagination
            });
          }
          this._count++;
        }, 500);
      }
    });
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  goToShipment(loadId) {
    this.context.router.push(`/load-board/${loadId}?bid=true`);
  }

  setParentState(obj, cb) {
    this.setState(obj, () => {
      if (cb) {
        cb();
      }
    });
  }

  async rejectTender(shipment, rejectReason, customReason) {
    this.setState({performingTenderAction: true});
    const tenderId = this.getMostRecentOpenTender(shipment.tenders)?.id;
    const opts = {
      body: {
        rejection_code: rejectReason
      }
    };

    if (customReason) {
      opts.body.rejection_reason = customReason;
    }

    try {
      const response = await rejectTender(tenderId, opts);
      if (response) {
        this.setState({
          rejectingShipment: null,
          showReject: false,
          showRejectedToast: true,
          justRejectedShipment: shipment,
          showShipmentDetail: false,
          selectedShipment: null,
          shipmentDetail: null,
          performingTenderAction: false
        });
        this.getLoadBoardShipments();
      }
    } catch (error) {
      console.error(error);
      this.setState({performingTenderAction: false});
    }
  }

  async acceptTender(e, shipment) {
    e.stopPropagation();
    this.setState({performingTenderAction: true});
    const tenderId = this.getMostRecentOpenTender(shipment.tenders)?.id;

    try {
      const response = await acceptTender(tenderId);
      if (response) {
        this.setState({
          showBuyItNowModal: false,
          showBookNowToast: true,
          justBookedShipment: shipment,
          showShipmentDetail: false,
          selectedShipment: null,
          shipmentDetail: null,
          performingTenderAction: false
        });
        this.getLoadBoardShipments();
      }
    } catch (error) {
      this.setState({
        showTenderAcceptError: true,
        tenderAcceptErrorMessage: error.error_description || '',
        performingTenderAction: false
      });
      console.error(error);
    }
  }

  showBuyItNow(shipment, e) {
    e.stopPropagation();
    this.setState({selectedShipment: shipment, showBuyItNowModal: true});
  }

  /*User accepts a Book Now price*/
  async acceptBookNowPrice(obj) {
    this.setState({submittingBookNow: true});
    const {selectedShipment} = this.state;
    const hasSpotNegotiation = selectedShipment.spot_negotiation;
    if (hasSpotNegotiation) {
      return this.handleBookNow(hasSpotNegotiation, obj);
    }
    //need to create a spot negotiation for this shipment first
    const body = {};
    //add current user as the only vendor user
    body.involved_vendor_users = [{id: this.props.user.id}];
    //empty array for customer users (determined by backend)
    body.involved_customer_users = [];

    const response = await this.props.createSpotNegotiationFromLoadboard(selectedShipment.load_board_id, body);
    if (response) {
      if (response.statusCode === 201) {
        //proceed to create the quote with the SN id
        return this.handleBookNow(response.body.id, obj);
      }
      const errors = response.details.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError);
      submissionError._error = response.details.error_description;
      //handle edge cases for errors here
      if (submissionError.charge_line_items) {
        //put that on the total field
        if (submissionError.charge_line_items.unit_amount) {
          submissionError.total = submissionError.charge_line_items.unit_amount[0];
        }
      }
      this.setState({submittingBookNow: false});
      throw new SubmissionError(submissionError);
    }
  }
  /*Book a specified Spot Negotation now*/
  async handleBookNow(spotNegotiationId, obj, shipment) {
    const {selectedShipment} = this.state;
    const response = await this.props.bookNow(spotNegotiationId, obj);
    if (response) {
      if (response.status === 200) {
        this.setState({
          showBuyItNowModal: false,
          showBookNowToast: true,
          justBookedShipment: shipment || selectedShipment,
          showShipmentDetail: false,
          selectedShipment: null,
          shipmentDetail: null
        });
        this.getLoadBoardShipments();
      }
      this.setState({submittingBookNow: false});
    }
  }

  handleCloseBookNowToast() {
    this.setState({showBookNowToast: false, justBookedShipment: null});
  }

  handleCloseRejectedToast() {
    this.setState({showRejectedToast: false, justRejectedShipment: null});
  }

  handleCloseTenderAcceptError = () => {
    this.setState({showTenderAcceptError: false});
  };

  /*Remove columns as needed when user doesn't have permissions*/
  filterColumnsByPermissions(columnsObject = {}, columnsArray = [], filterableColumns = []) {
    const parsedColumnsObject = JSON.parse(JSON.stringify(columnsObject));
    const parsedColumnsArray = JSON.parse(JSON.stringify(columnsArray));
    const parsedFiltersArray = JSON.parse(JSON.stringify(filterableColumns));
    return {parsedColumnsObject, parsedColumnsArray, parsedFiltersArray};
  }

  /*
   * Adjust the pagination and selectedFilter when child component selects a filter
   */
  handleFilterSelection(id, clearExisting) {
    //trigger this change first to ensure that children are rerendered appropriately
    this.setState({defaultSelectedFilter: null}, () => {
      let {pagination} = this.state;
      let allFiltersSelected = {};

      if (id === null) {
        // If the user manually removed their filters, we want all table data to be retrieved. If the component has just mounted,
        // we add the initial filters to the pagination object and only retrieve the data from the API that corresponds to our filters.
        if (this.state.useInitialFilters) {
          pagination = Object.assign({}, initialPagination, initialFilters, {
            pageSize: this.state.tableRowDefaults?.LOADBOARD
          });
          allFiltersSelected = initialFilters;
          this.handleUpdateURL(queryString.stringify(initialFilters));
        } else {
          //TODO - decide if this should go back to the user's default here or not (currently does not)
          //reset to intial pagination settings when a filter is cleared
          pagination = Object.assign({}, initialPagination, {
            pageSize: this.state.tableRowDefaults?.LOADBOARD ?? initialPagination.pageSize
          });
          //reset url to no filter selected
          this.handleUpdateURL(null);
        }
        //reset columns
        this.updateColumns(this.props.defaultColumns);
      } else {
        let newFilters = {};
        if (this.props.loadboardConfig.configs?.filter((e) => e.id === id).length) {
          newFilters = this.parseFilters(
            this.props.loadboardConfig.configs.filter((e) => e.id === id)[0].config.filters
          );
          //update the visible columns to match the selection
          this.updateColumns(this.props.loadboardConfig.configs.filter((e) => e.id === id)[0].config.columns);
        }

        if (clearExisting) {
          //when a new filter is selected, we don't want to persist the other items in the URL
          allFiltersSelected = Object.assign({}, newFilters);
        } else {
          allFiltersSelected = Object.assign({}, newFilters, this.props.location.query);
        }
        Object.keys(allFiltersSelected).forEach((key) => {
          if (typeof allFiltersSelected[key] === 'string' && key !== 'view') {
            allFiltersSelected[key] = [allFiltersSelected[key]];
          }
          //special handling for customer and vendor which use IDs
          if (key === 'customer' && typeof allFiltersSelected.customer === 'string') {
            allFiltersSelected.customer = [{label: allFiltersSelected.customer, value: allFiltersSelected.customerId}];
          } else if (key === 'customer') {
            if (Array.isArray(allFiltersSelected.customer)) {
              allFiltersSelected.customer = allFiltersSelected.customer.map((e, i) => {
                if (typeof e === 'string') {
                  return {label: e, value: allFiltersSelected.customerId[i]};
                }
                return {label: e.label, value: e.value};
              });
            }
          }
        });
        pagination = Object.assign({}, initialPagination, allFiltersSelected, {
          pageSize: this.state.tableRowDefaults?.LOADBOARD
        });
        //add query if it exists
        if (this.state.pagination && this.state.pagination.q) {
          pagination.q = this.state.pagination.q;
        }
        //get the default ordering
        if (this.props.loadboardConfig.configs.filter((e) => e.id === id)[0].config.ordering.length) {
          pagination.ordering = this.props.loadboardConfig.configs.filter((e) => e.id === id)[0].config.ordering;
        }
        const urlToSave = JSON.parse(JSON.stringify(newFilters));
        if (urlToSave.customer) {
          urlToSave.customer = urlToSave.customer.map((e) => e.label);
        }
        if (urlToSave.vendor) {
          urlToSave.vendor = urlToSave.vendor.map((e) => e.label);
        }

        //save the viewId in the url
        const newURL = queryString.stringify(
          Object.assign({}, {view: id}, urlToSave, clearExisting ? '' : this.props.location.query)
        );

        this.handleUpdateURL(newURL);
      }
      this.setState(
        {
          defaultSelectedFilter: id,
          hasLoadedInitial: true,
          pagination: pagination,
          allFiltersSelected: allFiltersSelected
        },
        async () => {
          const response = await this.props.getLoadBoardShipments(pagination, this._count, 'handleFilterSelection');
          if (response) {
            this.setState({
              awaitingInitialLoad: false
            });
          }
          this._count++;
        }
      );
    });
  }

  /*
   * When necessary, parse the filter object to ensure the selections are applied correctly before render/saving
   */
  parseFilters(filterObj, direction, isSaveAction) {
    return parseTableFilters(filterObj, direction, isSaveAction);
  }

  /*
   *  Table fetch data function -- used primarily for page and page size changes
   */

  async fetchData() {
    if (!_.isEmpty(this.props.company) && !this.state.awaitingInitialLoad) {
      const pagination = Object.assign({}, this.state.pagination, {pageSize: this.state.tableRowDefaults?.LOADBOARD});
      const response = await this.props.getLoadBoardShipments(pagination, this._count, 'fetchData');
      if (response) {
        this.setState({
          pagination: pagination
        });
      }
      this._count++;
    }
  }

  applyFilters(newFilters) {
    const {defaultSelectedFilter} = this.state;
    const newFilterCopy = this.parseFilters(JSON.parse(JSON.stringify(newFilters)));
    let newURL = '';
    if (newFilterCopy.customer) {
      newFilterCopy.customer = newFilterCopy.customer.map((e) => e.label);
    }
    if (defaultSelectedFilter) {
      newURL = queryString.stringify(Object.assign({}, {view: defaultSelectedFilter}, newFilterCopy));
    } else {
      newURL = queryString.stringify(newFilterCopy);
    }
    //when this is clearing out filters, reset the columns too
    if (Object.keys(newFilters).length === 0) {
      this.updateColumns(this.props.defaultColumns);
      newURL = null;
    }
    this.handleUpdateURL(newURL);
    const allFiltersSelected = Object.assign({}, newFilterCopy);
    //ensure we are passing each key value in as an array to the GET call
    Object.keys(allFiltersSelected).forEach((key) => {
      if (typeof allFiltersSelected[key] === 'string' && key !== 'view') {
        allFiltersSelected[key] = [allFiltersSelected[key]];
      }
    });

    const pagination = Object.assign({}, initialPagination, allFiltersSelected, {
      pageSize: this.state.tableRowDefaults?.LOADBOARD
    });
    //add query if it exists
    if (this.state.pagination && this.state.pagination.q) {
      pagination.q = this.state.pagination.q;
    }
    this.setState(
      {
        pagination: pagination
      },
      () => {
        this.props.getLoadBoardShipments(pagination, this._count);
        this._count++;
      }
    );
  }

  updateColumns(selectedColumns) {
    const {loadboardConfig} = this.props;
    if (loadboardConfig.configsById) {
      if (!selectedColumns.includes('buy_it_now_amount')) {
        selectedColumns.unshift('buy_it_now_amount');
      }
      const columnsToShow = selectedColumns.map((columnId) => this.columns[columnId]).filter((column) => column); // ugh...
      this.setState({temporaryColumnOverride: columnsToShow});
    }
  }

  /**
   * Save the current view as a filter
   */
  async saveView(filterName, activeFilters, columns, setAsDefault, renameOnly) {
    const {loadboardConfig} = this.props;
    //first check if the name on any of these filters string-matches the filtername we got here
    //if so we are doing a PUT
    let id = null;
    if (loadboardConfig.configs.filter((e) => e.name === filterName).length > 0) {
      id = loadboardConfig.configs.filter((e) => e.name === filterName)[0].id;
    }
    if (renameOnly) {
      id = renameOnly;
    }
    //make sure we always send reference_id, tags, and Archived at the beginning of the column list
    const requiredColumns = ['buy_it_now_amount'];
    requiredColumns.forEach((col) => {
      if (columns.indexOf(col) < 0) {
        columns.unshift(col);
      }
    });
    //add actions to the end
    if (columns.indexOf('actions') < 0) {
      columns.push('actions');
    }

    const newConfig = {config: {}};
    newConfig.is_default = setAsDefault;
    newConfig.table_type = 'LOADBOARD';
    newConfig.config.columns = columns;
    newConfig.config.ordering = this.state.pagination.ordering; //potentially overwriting ordering when rename only
    if (activeFilters.view) {
      delete activeFilters.view;
    }
    newConfig.config.filters = this.parseFilters(JSON.parse(JSON.stringify(activeFilters)), null, true);
    //pass 3rd parameter for special handling of dates
    newConfig.name = filterName;

    if (id) {
      //PUT
      const response = await this.props.updateUserTableConfiguration(id, newConfig);
      if (response) {
        if (response.statusCode === 200) {
          const getResponse = await this.props.getUserTableConfiguration({pageSize: 100, tableType: 'LOADBOARD'});
          if (getResponse) {
            //making sure the full list is in state before selecting the filter
            this.handleFilterSelection(response.body.id, true);
            this.setState({successfulSaveTrigger: true});
            setTimeout(() => {
              this.setState({successfulSaveTrigger: false});
            }, 3000);
          }
        }
      }
    } else {
      const response = await this.props.createUserTableConfiguration(newConfig);
      if (response) {
        if (response.statusCode === 201) {
          //get ALL of the user's dashboard configs
          const getResponse = await this.props.getUserTableConfiguration({pageSize: 100, tableType: 'LOADBOARD'});
          if (getResponse) {
            this.handleFilterSelection(response.body.id, true);
            //making sure the full list is in state before selecting the filter
            this.setState({successfulSaveTrigger: true});
            setTimeout(() => {
              this.setState({successfulSaveTrigger: false});
            }, 3000);
          }
        }
      }
    }
  }

  async deleteView(view) {
    const response = await this.props.deleteUserTableConfiguration(view.id);
    if (response) {
      if (response.statusCode === 204) {
        //clear out the URL params and the view
        this.handleUpdateURL(null);
        this.setState({shouldLoadView: false});

        this.props.getUserTableConfiguration({pageSize: 100, tableType: 'LOADBOARD'});
      }
    }
  }

  handleUpdateURL(url) {
    if (!url) {
      window.history.pushState({}, '', window.location.origin + window.location.pathname);
    } else if (url) {
      window.history.pushState({}, '', window.location.origin + window.location.pathname + `?${url}`);
    }
  }

  handlePageChange(pageIndex) {
    const pagination = JSON.parse(JSON.stringify(this.state.pagination));
    pagination.page = pageIndex + 1;
    this.setState({pagination}, () => {
      this.fetchData();
    });

    document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
  }

  handlePageSizeChange(pageSize) {
    this.setUserPageSize(pageSize);
    const pagination = {
      ...this.state.pagination,
      pageSize: this.state.tableRowDefaults?.LOADBOARD ?? this.state.pagination.pageSize
    };
    pagination.pageSize = pageSize;
    //reset page to 1
    pagination.page = 1;
    this.setState({pagination}, () => {
      this.fetchData();
    });
  }
  handleSortedChange(newSorted) {
    const pagination = Object.assign({}, this.state.pagination);
    if (newSorted && newSorted.length) {
      //some parsing is needed when column id's don't match the exact model name the backend requires
      const sortValue = this.parseColumnSorting(JSON.parse(JSON.stringify(newSorted[0].id)), newSorted[0].desc);
      //we only allow one sort at a time
      pagination.ordering = [sortValue];
    }
    this.setState({pagination}, () => {
      this.fetchData();
    });
  }

  parseColumnSorting(column, desc) {
    if (column === 'equipment') {
      return desc ? '-equipment_type' : 'equipment_type';
    }
    if (column === 'pickup_date') {
      return desc ? '-pickup' : 'pickup';
    }
    if (column === 'delivery_date') {
      return desc ? '-dropoff' : 'dropoff';
    }
    return desc ? '-' + column : column;
  }

  getRowClassNames(rowInfo = {}) {
    let classNames = '';
    if (
      this.state.selectedShipment &&
      rowInfo &&
      rowInfo.original &&
      this.state.selectedShipment.load_board_id === rowInfo.original.load_board_id
    ) {
      classNames += ' selectedShipment';
    }
    if (rowInfo && rowInfo.original && rowInfo.original.bidding_status === 'won') {
      classNames += ' wonAuction';
    }
    if (rowInfo && rowInfo.id === hoveredLoadId) {
      classNames += ' hoveredRow';
    }
    if (this.state.rejectingShipment && rowInfo.original && this.state.rejectingShipment.id === rowInfo.original.id) {
      classNames += ' rejectingShipment';
    }
    if (
      Object.keys(this.state.biddingOnShipment).length > 0 &&
      rowInfo.original &&
      this.state.biddingOnShipment.id === rowInfo.original.id
    ) {
      classNames += ' biddingOnShipment';
    }
    return classNames;
  }

  renderBookNowToast() {
    const {justBookedShipment} = this.state;
    return (
      <div className="shipmentDetails__loadboardToast">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseBookNowToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="flaticon-check_filled text-success" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-success">
              <b>Shipment Booked!</b>
            </p>
            {justBookedShipment && (
              <>
                <p>{`Shipment ${justBookedShipment.load_board_id} is now on your dashboard.`}</p>
                <Link to={`/shipments/${justBookedShipment.id}`}>View Shipment Details</Link>
              </>
            )}
          </span>
        </div>
      </div>
    );
  }

  renderRejectedToast() {
    const {justRejectedShipment} = this.state;
    return (
      <div className="shipmentDetails__loadboardToast-rejected">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseRejectedToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="icon icon-Delayed text-danger" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-danger">
              <b>You rejected tendered load {justRejectedShipment && justRejectedShipment.load_board_id}</b>
            </p>
            <p>Rejected and expired loads are hidden from your Load Board, but are accessible through filters.</p>
          </span>
        </div>
      </div>
    );
  }

  fetchShipmentDetails = async (shipmentId, {fetchQuotes = true} = {}) => {
    try {
      const response = await this.props.getLoadBoardShipmentDetails(shipmentId);
      if (response) {
        this.setState({
          selectedShipment: response.details,
          shipmentDetail: response.details,
          shipmentDetailLoading: false
        });
        if (response.status === 200) {
          await Promise.all([
            this.props.getSpotNegotiationDetails(response.details.spot_negotiation),
            fetchQuotes && this.props.getSpotNegotiationQuotes(response.details.spot_negotiation)
          ]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleFilterMapping = () => {
    const {fmNewCreateIntermodal} = this.props;
    const modeOptionsWithIntermodal = [...filterMapping.requested_shipment_modes?.options, INTERMODAL_FILTER_OPTION];
    this.setState({
      filterMapping: {
        ...filterMapping,
        requested_shipment_modes: {
          ...filterMapping.requested_shipment_modes,
          //show intermodal in mode options list if feature flag is on
          options: fmNewCreateIntermodal ? modeOptionsWithIntermodal : filterMapping?.requested_shipment_modes?.options
        }
      }
    });
  };

  render() {
    const {
      temporaryColumnOverride,
      showBuyItNowModal,
      submittingBookNow,
      performingTenderAction,
      useInitialFilters,
      loadingSpotNegotationDetails
    } = this.state;
    const {loadboardConfig, defaultColumns, allColumns, classes, loadBoardShipments} = this.props;
    const {parsedColumnsObject, parsedColumnsArray, parsedFiltersArray} = this.filterColumnsByPermissions(
      this.columns,
      allColumns,
      FILTERABLE_LOADBOARD_COLUMNS
    );

    const columnsToShow =
      loadboardConfig?.configs?.length &&
      this.state.defaultSelectedFilter &&
      loadboardConfig.configsById[this.state.defaultSelectedFilter]
        ? loadboardConfig.configsById[this.state.defaultSelectedFilter].config.columns
            .filter((column) => !column.startsWith('-'))
            .map((columnId) => parsedColumnsObject[columnId])
            .filter((column) => column) // ugh...
        : defaultColumns && defaultColumns.map((columnId) => parsedColumnsObject[columnId]).filter((column) => column); //load the shipwell default
    const selectedColumnNames = temporaryColumnOverride
      ? temporaryColumnOverride.map((e) => e.id)
      : columnsToShow && columnsToShow.map((e) => e.id);
    const hasReadback =
      this.state.pagination &&
      Object.keys(this.state.pagination).filter((e) => e !== 'pageSize' && e !== 'page' && e !== 'ordering').length > 0;
    const tableComponent = (
      <div className="loadBoard__table">
        <ReactTableFixedColumns
          showPagination
          data={loadBoardShipments ? loadBoardShipments.results : []}
          pages={loadBoardShipments ? loadBoardShipments.total_pages : 1}
          loading={this.props.isLoading}
          sortable={false}
          manual
          column={{...ReactTableDefaults.column, headerClassName: 'shown'}}
          page={this.state.pagination.page - 1}
          pageSize={this.state.pagination.pageSize}
          onPageChange={(pageIndex) => {
            this.handlePageChange(pageIndex);
          }}
          onPageSizeChange={(pageSize, pageIndex) => {
            this.handlePageSizeChange(pageSize, pageIndex);
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.handleSortedChange(newSorted, column, shiftKey);
          }}
          sorted={this.state.pagination.ordering.map((e) => {
            //parse when columns dont match e.g., equipment_type
            if (columnSortingMapping[e] || columnSortingMapping[e.slice(1)]) {
              return e.startsWith('-')
                ? {id: columnSortingMapping[e.slice(1)], desc: true}
                : {id: columnSortingMapping[e], desc: false};
            }
            return e.startsWith('-') ? {id: e.slice(1), desc: true} : {id: e, desc: false};
          })}
          minRows={10}
          pageSizeOptions={[10, 20, 50, 100]}
          id="loadBoardTable"
          getTrProps={(state, rowInfo) => {
            return {
              className: this.getRowClassNames(rowInfo),

              onClick: function () {
                const shipmentId = rowInfo.original.load_board_id;
                const {shipmentDetail} = this.state;
                clearInterval(this.interval);
                if (
                  !shipmentDetail ||
                  (shipmentDetail && shipmentDetail.load_board_id !== rowInfo.original.load_board_id)
                ) {
                  this.setState({
                    shipmentDetailLoading: true,
                    selectedShipment: rowInfo.original,
                    showShipmentDetail: true
                  });
                  this.fetchShipmentDetails(shipmentId);
                } else {
                  //close the sidebar if the selected row is clicked again
                  this.setState({
                    showShipmentDetail: false,
                    selectedShipment: null,
                    shipmentDetail: null
                  });
                }
              }.bind(this)
            };
          }}
          getTrGroupProps={(state, rowInfo) => {
            if (!rowInfo) {
              return {
                className: 'no-content'
              };
            }
            return '';
          }}
          getTheadFilterThProps={() => {
            return {
              style: {
                overflow: 'inherit'
              }
            };
          }}
          columns={temporaryColumnOverride ? temporaryColumnOverride : columnsToShow}
          className="-highlight -striped"
          previousText={<i className="icon icon-Left" />}
          nextText={<i className="icon icon-Right" />}
          rowsText=""
          noDataText={this.props.isLoading ? '' : 'No Loads'}
          LoadingComponent={ShipwellLoader}
          ref={(ref) => (this._reacttable = ref)}
        />
      </div>
    );

    return (
      <div className="loadBoard-wrapper content-wrapper">
        <section
          className={`table__section ${
            this.state.showShipmentDetail ? classes.dashboardOpen : classes.dashboardClosed
          }`}
        >
          <div className="table__search">
            <FormGroup>
              <InputGroup>
                <InputGroup.Button>
                  <Button
                    onClick={() => {
                      const filters = [];
                      filters.push({
                        id: 'q',
                        value: this.state.searchValue + '*'
                      });
                      this.setState({filters: filters});
                    }}
                  >
                    <i className="flaticon-search pad-left" />
                  </Button>
                </InputGroup.Button>
                <FormControl
                  name="search"
                  type="text"
                  value={this.state.searchValue}
                  placeholder="Search for a shipment"
                  onChange={this.searchDataTable}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      const filters = [];
                      filters.push({
                        id: 'q',
                        value: this.state.searchValue + '*'
                      });
                      this.setState({filters: filters});
                    }
                  }}
                />
              </InputGroup>
            </FormGroup>
          </div>
          <div className={hasReadback ? 'table__container hasReadback' : 'table__container'}>
            <TableFilters
              filterMapping={this.state.filterMapping}
              lockedColumns={['buy_it_now_amount', 'actions']}
              columns={parsedColumnsArray}
              defaultColumns={defaultColumns}
              selectedColumns={Array.isArray(selectedColumnNames) ? selectedColumnNames : []}
              parseFiltersForReadback={parseFiltersForReadback}
              filterableColumns={parsedFiltersArray}
              correspondingFilters={{
                //we use this list to clear out corresponding filters when a specified one is cleared.
                pickupStops: [
                  'pickupStopsContains',
                  'pickupStopsStateProvince',
                  'pickupLat',
                  'pickupLon',
                  'pickupRadius',
                  'pickupStopsLabel'
                ],
                deliveryStops: [
                  'deliveryStopsContains',
                  'deliveryStopsStateProvince',
                  'deliveryLat',
                  'deliveryLon',
                  'deliveryRadius',
                  'deliveryStopsLabel'
                ],
                pickup: ['pickupLte', 'pickupGte'],
                dropoff: ['dropoffLte', 'dropoffGte'],
                createdAt: ['createdAtLte', 'createdAtGte'],
                vendor: ['vendorId'],
                customer: ['customerId'],
                status: ['open']
              }}
              applyFiltersToParent={this.applyFilters.bind(this)}
              columnNamesById={loadboardConfig && loadboardConfig.columnNamesById}
              updateParentView={this.updateColumns.bind(this)}
              saveView={this.saveView.bind(this)}
              myViews={loadboardConfig && loadboardConfig.configs}
              deleteView={this.deleteView.bind(this)}
              viewLabel="My Load Boards"
              selectParentFilter={(id) => {
                if (useInitialFilters) {
                  this.setState({useInitialFilters: false});
                }
                this.handleFilterSelection(id, true);
              }}
              allFiltersSelected={this.state.allFiltersSelected}
              defaultSelectedFilter={this.state.defaultSelectedFilter}
              successfulSaveTrigger={this.state.successfulSaveTrigger}
              parseFilters={this.parseFilters}
              pagination={this.state.pagination}
              tableComponent={tableComponent}
            />
          </div>
        </section>

        <Drawer
          className={`${classes.drawer} table-details-drawer ${this.state.showShipmentDetail ? 'open' : 'closed'}`}
          anchor="right"
          variant="persistent"
          open={Boolean(this.state.showShipmentDetail)}
          classes={{paper: classes.drawerContent}}
        >
          <div className="shipment-drawer-content">
            <div className="shipment-drawer-body">
              <DashboardSummary
                drawerHeader={
                  !this.state.shipmentDetailLoading && this.state.shipmentDetail ? (
                    <div className="drawer-header">
                      <span>
                        <div className="text-black text-lg font-bold">{this.state.shipmentDetail?.load_board_id}</div>
                      </span>
                      <span>
                        <Link
                          to={`/load-board/${this.state.shipmentDetail?.load_board_id}`}
                          className="mr-2 whitespace-nowrap"
                        >
                          View Load Details
                        </Link>
                        <SvgIcon
                          name="Close"
                          color="$sw-icon"
                          className="h-6 w-6 overflow-visible"
                          onClick={() =>
                            this.setState({showShipmentDetail: false, shipmentPOC: null, selectedShipment: null})
                          }
                        />
                      </span>
                    </div>
                  ) : null
                }
                performingTenderAction={performingTenderAction}
                timelineEvents={this.props.timelineEvents}
                isLoading={this.state.shipmentDetailLoading}
                shipment={this.state.shipmentDetail}
                shipmentInvoices={this.props.shipmentInvoices}
                shipmentPOC={this.state.shipmentPOC}
                user={this.props.user}
                company={this.props.company}
                spotNegotiationQuotes={this.props.spotNegotiationQuotes}
                loadBoardShipmentDetails={this.props.loadBoardShipmentDetails}
                canViewCarrier={false}
                triggerReload={this.fetchShipmentDetails}
                isLoadBoard
                acceptTender={this.acceptTender.bind(this)}
                rejectTender={this.rejectTender.bind(this)}
                showRejectedToast={this.state.showRejectedToast}
                goToShipment={this.goToShipment.bind(this)}
                showBuyItNow={this.showBuyItNow.bind(this)}
                tabOptions={['details', 'myBids', 'messages']}
                showBidForm={this.state.showBidForm}
                closeBidForm={this.closeBidForm}
                openBidForm={this.openBidForm}
                handleDeleteQuote={this.handleDeleteQuote}
                router={this.props.router}
                handlePlaceBidSuccess={this.handlePlaceBidSuccess.bind(this)}
                loadingSpotNegotationDetails={loadingSpotNegotationDetails}
                isDeletingQuote={this.state.isDeletingQuote}
              />
            </div>
          </div>
        </Drawer>
        {showBuyItNowModal && (
          <BookNowConfirmation
            open
            shipment={this.state.selectedShipment}
            accept={this.acceptBookNowPrice.bind(this)}
            cancel={() => this.setState({showBuyItNowModal: false, selectedShipment: null})}
            submittingBookNow={submittingBookNow}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showBookNowToast}
          autoHideDuration={null}
          onClose={this.handleCloseBookNowToast.bind(this)}
        >
          {this.renderBookNowToast()}
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showRejectedToast}
          autoHideDuration={null}
          onClose={this.handleCloseRejectedToast.bind(this)}
        >
          {this.renderRejectedToast()}
        </Snackbar>
        <Toast
          title="Error"
          anchor="top-right"
          show={this.state.showTenderAcceptError}
          variant="error"
          onClose={this.handleCloseTenderAcceptError}
        >
          {this.state.tenderAcceptErrorMessage}
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.marketplace.isLoading,
    loadBoardShipments: state.marketplace.loadBoardShipments,
    loadBoardShipmentDetails: state.marketplace.loadBoardShipmentDetails,
    selectedSpotNegotiation: state.marketplace.selectedSpotNegotiation,
    spotNegotiationQuotes: state.marketplace.spotNegotiationQuotes,
    company: state.auth.company,
    loadboardConfig: state.userPreferences.loadboardConfig,
    defaultConfig: state.userPreferences.loadboardConfig.defaultConfig,
    allColumns: state.userPreferences.loadboardConfig.allColumns,
    defaultColumns: state.userPreferences.loadboardConfig.defaultColumns,
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...marketplaceActions,
      ...shipmentActions,
      ...brokerActions,
      ...shipmentDetailsActions,
      ...userActions
    },
    dispatch
  );
};

export default compose(
  withFlags('fmNewCreateIntermodal'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true})
)(WithStatusToasts(LoadBoard));
