import {useCallback, useState} from 'react';

export interface UseVisibilityProps<TData = object> {
  initialValues: {
    isVisible?: boolean;
    data?: TData;
  };
}

export function useVisibility<TData = object>(props?: UseVisibilityProps<TData>) {
  const [data, setData] = useState<TData | undefined>(props?.initialValues.data);
  const [isVisible, setIsVisible] = useState(!!props?.initialValues.isVisible);

  const toggle = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const showWithData = useCallback((data: TData) => {
    setIsVisible(true);
    setData(data);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  const hideAndClearData = useCallback(() => {
    setIsVisible(false);
    setData(undefined);
  }, []);

  return {
    data,
    isVisible,
    show,
    showWithData,
    hide,
    hideAndClearData,
    toggle
  };
}
