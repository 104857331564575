const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a name';
  }
  if (values.year) {
    if (!Number.isInteger(Number(values.year))) {
      errors.year = 'Please enter a number';
    }
  }

  if (values.distance_traveled_miles) {
    if (!Number.isInteger(Number(values.distance_traveled_miles))) {
      errors.distance_traveled_miles = 'Please enter a whole number';
    }
  }

  if (values.license_plate_number && values.license_plate_number.length > 16) {
    errors.license_plate_number = 'Please enter a license plate number that has no more than 16 characters.';
  }

  return errors;
};

export default validate;
