import {ReactNode} from 'react';
import {Tooltip} from '@shipwell/shipwell-ui';
import {SettlementsDashboardInvoiceableChargeLineItem, CurrencyCode} from '@shipwell/backend-core-sdk';
import {toCurrency} from 'App/utils/grammar';

const SubtotalsRow = ({label, value}: {label: string | undefined; value: string}) => (
  <tr>
    <th className="py-0.5 pr-2 text-right text-sm font-normal" scope="row">
      {label}
    </th>
    <td className="whitespace-nowrap py-0.5 text-right text-sm">{value}</td>
  </tr>
);

const LineItemRow = ({label, value}: {label: string | undefined; value: string}) => (
  <tr>
    <th className="py-2 pr-8 text-sm font-normal" scope="row">
      {label}
    </th>
    <td className="whitespace-nowrap py-2 text-right text-sm">{value}</td>
  </tr>
);

export const InvoiceFinancialsTooltip = ({
  invoiceTotal,
  shipmentTotal = 0,
  currency,
  chargeLineItems,
  children
}: {
  invoiceTotal: number;
  shipmentTotal: number | undefined;
  currency?: CurrencyCode;
  chargeLineItems:
    | Pick<SettlementsDashboardInvoiceableChargeLineItem, 'id' | 'category' | 'total_amount'>[]
    | undefined;
  children: ReactNode;
}) => {
  const difference = Math.abs(invoiceTotal - (shipmentTotal ? +shipmentTotal : 0));

  return (
    <Tooltip
      tooltipContent={
        <div className="min-w-80 p-2">
          <table>
            <caption className="sr-only">Invoice financials</caption>
            <colgroup>
              <col style={{width: '90%', whiteSpace: 'nowrap'}} />
              <col style={{width: '10%', whiteSpace: 'nowrap'}} />
            </colgroup>
            <thead className="border-b-1 after:block after:h-2">
              <tr>
                <th className="pr-8 text-xxs font-normal" scope="col">
                  LINE ITEM
                </th>
                <th className="whitespace-nowrap text-xxs font-normal" scope="col">
                  SHIP. TOTAL
                </th>
              </tr>
            </thead>
            <tbody className="before:block before:h-2 after:block after:h-1">
              {chargeLineItems?.map((item) => (
                <LineItemRow
                  key={item.id}
                  label={item.category}
                  value={item.total_amount ? toCurrency(+item.total_amount.value, item.total_amount.currency) : '--'}
                />
              ))}
            </tbody>
            <tfoot className="border-t-1 before:block before:h-2">
              <SubtotalsRow label="Ship. Total:" value={toCurrency(shipmentTotal, currency)} />
              <SubtotalsRow label="Invoiced:" value={toCurrency(invoiceTotal, currency)} />
              <SubtotalsRow label="Difference:" value={toCurrency(difference, currency)} />
            </tfoot>
          </table>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
