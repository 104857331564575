import {Field, useFormikContext} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';
import {initializeDoeTable} from 'App/containers/userCompany/fuelSurcharges/utils/utils';

const DoeRatesCriteriaFormFields = () => {
  const {values, setFieldValue} = useFormikContext();

  const handleChange = (value, fieldName) => {
    const valAsNumber = Number(value);
    setFieldValue(fieldName, value);
    const updatedDoeTable = initializeDoeTable({
      ...values,
      [fieldName]: valAsNumber
    });
    setFieldValue('calculation_rows', updatedDoeTable);
  };

  return (
    <div className="field-grid fuelSurcharge__rateGrid">
      <div className="fuelSurcharge__rateGrid-label">My DOE base rate starts at</div>
      <Field
        label="Base Rate"
        name="base_fuel_price"
        component={FormikTextInput}
        value={values.base_fuel_price}
        onChange={(e) => handleChange(e.target.value, 'base_fuel_price')}
      />
      <div className="fuelSurcharge__rateGrid-label">My DOE ranges go up in increments of</div>
      <Field
        value={values.doe_rate_increment}
        label="Rate Increments (e.g. 0.005)"
        name="doe_rate_increment"
        component={FormikTextInput}
        required
        onChange={(e) => handleChange(e.target.value, 'doe_rate_increment')}
      />
      <div className="fuelSurcharge__rateGrid-label">My DOE max rate is</div>
      <Field
        label="Max Rate (e.g 5.000)"
        name="doe_max_rate"
        component={FormikTextInput}
        required
        value={values.doe_max_rate}
        onChange={(e) => handleChange(e.target.value, 'doe_max_rate')}
      />
    </div>
  );
};

export default DoeRatesCriteriaFormFields;
