import {ReactNode} from 'react';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';

const ConfigurationRowHeader = () => (
  <div className="col-span-full grid grid-cols-[0.5fr,2fr,2.5fr,0.3fr] pb-1 pt-3">
    <span className="text-xxs">STATUS</span>
    <span className="text-xxs">CONFIG TYPE</span>
    <span className="text-xxs">CONFIG DESCRIPTION</span>
  </div>
);

export const ConfigurationsCard = ({title, children}: {title: string; children: ReactNode}) => {
  return (
    <Card className="w-full" title={title} draggableProvided={false} isCollapsible>
      <CollapsibleCardContent>
        <ConfigurationRowHeader />
        {children}
      </CollapsibleCardContent>
    </Card>
  );
};
