import {Fragment, useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import Classnames from 'classnames';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {object, string} from 'yup';
import find from 'lodash/find';
import get from 'lodash/get';
import {Field, Formik} from 'formik';
import {Title, Textarea, DeprecatedButton, Dropdown, SvgIcon, Rule, FormikTextarea} from '@shipwell/shipwell-ui';
import {getNotesPromise, postNotesPromise, deleteNotePromise, putNotePromise} from 'App/api/shipment';
import WithStatusToasts from 'App/components/withStatusToasts';
import {formatDateTime} from 'App/utils/globals';
import useInterval from 'App/utils/hooks/useInterval';
import './styles.scss';
import {getMessageUserAvatarURL} from 'App/utils/userAvatar';

const pagination = {page: 1, pageSize: 1000}; // this query is very fast on backend, so not implementing true pagination at this time, just fetching 1000

const ShipmentNotes = ({shipment, setError, user, onSubmit, title, shouldPoll, className, fixedWidthContainer}) => {
  const [input, setInput] = useState('');
  const [editingNote, setEditingNote] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    getNotes(pagination);
  }, [getNotes]);

  const getNotes = useCallback(
    async (opts) => {
      try {
        const response = await getNotesPromise(shipment.id, opts);
        setNotes(response.body.results);
      } catch (error) {
        console.error(error);
      }
    },
    [shipment]
  );

  useInterval(
    () => {
      getNotes(pagination);
    },
    shouldPoll ? 30000 : null
  );

  const handleSubmit = async () => {
    if (!input.trim().length || submitting) {
      return;
    }
    setSubmitting(true);
    try {
      await postNotesPromise(shipment.id, {message: input});
      setInput('');
      getNotes(pagination);
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
    setSubmitting(false);
  };

  const handleKeyPress = (e, submitEdit) => {
    if (e.key === 'Enter') {
      //submit on enter, unless shift+enter
      if (!e.shiftKey) {
        e.preventDefault();
        if (submitEdit) {
          submitEdit();
        } else {
          handleSubmit();
        }
      }
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deleteNotePromise(shipment.id, noteId);
      getNotes(pagination);
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const handlePinNote = async (noteId) => {
    const body = {...find(notes, {id: noteId})};
    body.is_pinned = !body.is_pinned;
    try {
      await putNotePromise(shipment.id, noteId, body);
      getNotes(pagination);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const handleEditNote = async ({id, message}, {setSubmitting}) => {
    setSubmitting(true);
    const body = {...find(notes, {id: id})};
    body.message = message;
    try {
      await putNotePromise(shipment.id, id, body);
      setEditingNote(null);
      getNotes(pagination);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
    setSubmitting(false);
  };

  const Note = ({id, message, is_pinned, creator_full_name, creator_avatar, updated_at, creator_id}) => {
    return (
      <div className="mb-3 flex flex-col whitespace-pre-line">
        <div className="">
          <span className="mr-2 font-bold">{creator_full_name}</span>
          <span className="text-sw-disabled-text">{formatDateTime(updated_at, true, moment.tz.guess())}</span>
        </div>
        <div className="flex flex-row items-center justify-start">
          <span className="mr-2 shrink-0 py-2">
            {is_pinned ? (
              <SvgIcon width={20} height={20} name="Pin" color="$sw-icon" />
            ) : (
              <img
                className="size-5 rounded-full"
                src={getMessageUserAvatarURL(creator_full_name, creator_avatar)}
                alt="userAvatar"
              />
            )}
          </span>
          {editingNote === id ? (
            <EditNote id={id} message={message} />
          ) : (
            <>
              <span
                className={Classnames('flex-1 break-words p-3 bg-sw-background rounded', {
                  'bg-sw-warning-opacity': is_pinned,
                  'max-w-xxs': fixedWidthContainer,
                  hyphens: !fixedWidthContainer
                })}
              >
                {message}
              </span>
              <span className="w-8 py-1">
                {creator_id === user.id && (
                  <Dropdown
                    indicator={false}
                    drop="left"
                    variant="icon"
                    className="action-menu"
                    icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                  >
                    {({onClick}) => (
                      <>
                        <li
                          onClick={() => {
                            onClick();
                            handlePinNote(id);
                          }}
                        >
                          {is_pinned ? `Unpin` : `Pin`}
                        </li>

                        <li
                          onClick={() => {
                            onClick();
                            setEditingNote(id);
                          }}
                        >
                          Edit
                        </li>

                        <li
                          className="text-danger"
                          onClick={() => {
                            onClick();
                            handleDeleteNote(id);
                          }}
                        >
                          Delete
                        </li>
                      </>
                    )}
                  </Dropdown>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const EditNote = ({id, message}) => {
    return (
      <Formik
        initialValues={{id: id, message: message}}
        validationSchema={object().shape({
          message: string().nullable().required('Note text is required.')
        })}
        onSubmit={handleEditNote}
      >
        {({handleSubmit, submitting}) => (
          <div className="messages__editNote flex flex-row items-center justify-start">
            <Field
              label="Note Text"
              name="message"
              component={FormikTextarea}
              minRows={0}
              maxRows={3}
              onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
            />
            <DeprecatedButton variant="icon" onClick={() => setEditingNote(null)}>
              <SvgIcon name="CloseCircleOutlined" color="$sw-icon" />
            </DeprecatedButton>
            <DeprecatedButton variant="icon" disabled={submitting} onClick={handleSubmit}>
              <SvgIcon name="CheckCircleFilled" color="$sw-primary" />
            </DeprecatedButton>
          </div>
        )}
      </Formik>
    );
  };

  return (
    <div className={`shipmentNotes messages ${className}`}>
      {title && <h2>{title}</h2>}
      {notes.length === 0 ? (
        <div className="flex shrink-0 grow flex-col items-center justify-center text-center text-sw-disabled">
          <div>
            <Title variant="emptyStateHeader">No Internal Notes</Title>
            <div>Add a new note in the field below.</div>
          </div>
        </div>
      ) : (
        <div className="scroll h-80 shrink-0 grow overflow-y-auto overflow-x-hidden p-2">
          {notes.map((note, i) => {
            const isLastPin = note.is_pinned && notes[i + 1] && !notes[i + 1].is_pinned;
            return (
              <Fragment key={note.id}>
                <Note {...note} />
                {isLastPin && <Rule />}
              </Fragment>
            );
          })}
        </div>
      )}
      <div className="messages__inputContainer relative flex justify-between p-4 sw-border-t">
        <Textarea
          className="messages__input mr-2"
          minRows={0}
          maxRows={3}
          value={input}
          name="newNote"
          label="Add new note here"
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {input.length > 0 && (
          <span className="messages__inputContainer-helperText absolute -bottom-4 text-xxs uppercase text-sw-disabled-text">
            Shift + Return to add a new line
          </span>
        )}
        <DeprecatedButton variant="primary" onClick={handleSubmit} disabled={submitting || !input.trim().length}>
          Save
        </DeprecatedButton>
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    user: get(state, 'userProfile.user')
  })),
  WithStatusToasts
)(ShipmentNotes);
