import {Formik, Form, Field, useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import {Modal, FormikTextInput, FormikCounter, DeprecatedButton, Title, DisplayValue} from '@shipwell/shipwell-ui';
import {
  createValidationSchema,
  alertTypeConstants
} from 'App/containers/userCompany/defaults/compassAlerts/compassAlertsConstants';
import {detentionStatusConstants} from 'App/containers/alertsDashboard/components/correctiveActions/detention/utils';
import {FormikTagSelect} from 'App/components/Tags/Select';

const CounterThreshold = ({min, max, description, alertType}) => {
  const showHelperText = () => {
    switch (alertType?.type) {
      case alertTypeConstants.RESOLVED_ALERTS:
      case alertTypeConstants.CARRIER_INSURANCE_EXPIRED:
        return `The maximum time is set to ${max} days`;
      case alertTypeConstants.DETENTION:
        return `Detention will be in progress after ${detentionStatusConstants.IN_PROGRESS_THRESHOLD} minutes.`;
      default:
        return `* The maximum time is set to ${max / 24} days (${max} hrs).`;
    }
  };
  return (
    <div className="flex pb-6">
      <Field minValue={min} maxValue={max} name="threshold" component={FormikCounter} />
      <div className="mt-6 grid self-center pl-2">
        <div>{description}</div>
        <div className="text-sw-form-helper-text">{showHelperText()}</div>
      </div>
    </div>
  );
};

CounterThreshold.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  alertType: PropTypes.shape({
    type: PropTypes.string
  })
};

CounterThreshold.defaultProps = {
  min: 0,
  max: 24,
  description: ''
};

const TagThreshold = ({tagsToFilter}) => {
  const {setFieldValue} = useFormikContext();
  return (
    <div className="pt-2">
      <Field
        name="threshold"
        tagsToFilter={tagsToFilter}
        onChange={(tagName) => setFieldValue('name', tagName ? truncate(tagName, {length: 28, omission: ''}) : '')}
        component={FormikTagSelect}
      />
      <div className="pb-8 pt-1 text-sw-form-helper-text">
        * Only one tag can be assigned per ‘Tagged Shipments’ Alert Tiles at a time. You can have multiple ‘Tagged
        Shipments’ Alert Tiles and assign different tags to each one.
      </div>
    </div>
  );
};

TagThreshold.propTypes = {
  tagsToFilter: PropTypes.array
};

TagThreshold.defaultProps = {
  tagsToFilter: []
};

const CompassAlertsModal = ({alert, alertType, showModal, onClose, submitAlert, tagsToFilter, duplicateNames}) => {
  return (
    <Modal show={showModal} onClose={onClose} title={alert?.name} footerComponent={null}>
      <Formik
        initialValues={{
          name: alert?.name ?? 'Tagged Shipments',
          threshold: alert?.threshold?.value ?? 0,
          manualResolve: alert?.manual_resolve || false
        }}
        onSubmit={(values) => submitAlert(values, alert)}
        validationSchema={createValidationSchema(duplicateNames)}
      >
        <Form>
          <Title className="pb-2">Define Alert</Title>
          <Field
            component={FormikTextInput}
            name="name"
            label="Alert Name *"
            characterLimit={28}
            disabled={alertType?.type === alertTypeConstants.RESOLVED_ALERTS}
          />
          <DisplayValue className="m-0 pt-6" label={'Alert Description'} />
          <div className="pb-8">{alertType?.description}</div>
          {alertType?.threshold_definition && (
            <div>
              <Title>Set Alert Threshold</Title>
              {alertType?.threshold_definition.type === 'counter' && (
                <CounterThreshold
                  min={alertType?.threshold_definition.min}
                  max={alertType?.threshold_definition.max}
                  description={alertType?.threshold_definition.description}
                  alertType={alertType}
                />
              )}
              {alertType?.threshold_definition.type === 'tag' && <TagThreshold tagsToFilter={tagsToFilter} />}
            </div>
          )}
          <Title className="pb-2">Resolving the Alert</Title>
          <span>{alertType?.resolve_description}</span>
          <div className="flex justify-end pt-4">
            <DeprecatedButton variant="secondary" onClick={onClose} className="mr-4">
              Cancel
            </DeprecatedButton>
            <DeprecatedButton variant="primary" type="submit">
              Save
            </DeprecatedButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

CompassAlertsModal.propTypes = {
  alert: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    threshold: PropTypes.shape({
      value: PropTypes.number
    }),
    manual_resolve: PropTypes.bool
  }),
  alertType: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    resolve_description: PropTypes.string,
    threshold_definition: PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number
    })
  }),
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  submitAlert: PropTypes.func,
  tagsToFilter: PropTypes.arrayOf(PropTypes.string),
  duplicateNames: PropTypes.arrayOf(PropTypes.string)
};

CompassAlertsModal.defaultProps = {
  submitAlert: () => {},
  tagsToFilter: [],
  duplicateNames: []
};

export default CompassAlertsModal;
