import {TransportationOrdersApi} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';

const transportationOrdersApi = new TransportationOrdersApi(configuration);

export function getDrayageTransportationOrder(transportationOrderId: string) {
  return transportationOrdersApi.getDrayageTransportationOrderDetailTransportationOrdersDrayageTransportationOrderIdGet(
    {transportationOrderId: transportationOrderId}
  );
}

export function revokeTransportationOrder(transportationOrderId: string) {
  return transportationOrdersApi.revokeDrayageTransportationOrderDetailTransportationOrdersDrayageTransportationOrderIdDelete(
    {transportationOrderId: transportationOrderId}
  );
}

export function rejectDrayageTransportationOrder(transportationOrderId: string) {
  return transportationOrdersApi.rejectDrayageTransportationOrderTransportationOrdersDrayageTransportationOrderIdResponsesDelete(
    {transportationOrderId: transportationOrderId}
  );
}

export function acceptDrayageTransportationOrder(transportationOrderId: string) {
  const requestParameters = {
    transportationOrderId: transportationOrderId,
    acceptTransportationOrderRequest: {
      create_shipment: true
    }
  };

  return transportationOrdersApi.acceptDrayageTransportationOrderTransportationOrdersDrayageTransportationOrderIdResponsesPost(
    requestParameters
  );
}

export function generateShipmentRateConfirmation(transportationOrderId: string) {
  return transportationOrdersApi.generateRateConFromDrayageTransportationOrderTransportationOrdersDrayageTransportationOrderIdDocumentsGenerateRateConfirmationPost(
    {transportationOrderId: transportationOrderId}
  );
}

export function getTransportationOrderDocuments(transportationOrderId: string) {
  return transportationOrdersApi.getTransportationOrderDocumentsTransportationOrdersDrayageTransportationOrderIdDocumentsGet(
    {transportationOrderId: transportationOrderId}
  );
}

export function updateTransportationOrderDocument(
  transportationOrderId: string,
  documentId: string,
  description: string,
  type: string
) {
  const requestParameters = {
    transportationOrderId: transportationOrderId,
    documentId: documentId,
    description: description,
    type: type
  };

  return transportationOrdersApi.updateTransportationOrderDocumentTransportationOrdersDrayageTransportationOrderIdDocumentsDocumentIdPut(
    requestParameters
  );
}

export function deleteTransportationOrderDocument(transportationOrderId: string, documentId: string) {
  return transportationOrdersApi.deleteTransportationOrderDocumentTransportationOrdersDrayageTransportationOrderIdDocumentsDocumentIdDelete(
    {
      transportationOrderId,
      documentId
    }
  );
}

export function getDrayageTransportationOrderServices(transportationOrderId: string) {
  return transportationOrdersApi.getDrayageTransportationOrderServicesTransportationOrdersDrayageTransportationOrderIdServicesGet(
    {transportationOrderId: transportationOrderId}
  );
}
