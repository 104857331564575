import {Configuration, Product, ProductsApi, ShipwellUiApi} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {buildProductPayload} from 'App/api/products/productsApiUtils';
import {getAccessToken} from 'App/api/utils';

function createProductsApi() {
  return new ProductsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

function createShipwellUIAPI() {
  return new ShipwellUiApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type GetProductsParameters = Parameters<typeof ProductsApi.prototype.productsGet>;

export type GetProductsNamedParameters = {
  page?: GetProductsParameters[0];
  pageSize?: GetProductsParameters[1];
  ordering?: GetProductsParameters[2];
  q?: GetProductsParameters[3];
  descriptionContains?: GetProductsParameters[4];
  productRefContains?: GetProductsParameters[5];
  packageTypeContains?: GetProductsParameters[6];
};

export function getProducts(opts: GetProductsNamedParameters) {
  return createProductsApi().productsGet(
    opts.page,
    opts.pageSize,
    opts.ordering,
    opts.q,
    opts.descriptionContains,
    opts.productRefContains,
    opts.packageTypeContains
  );
}

export function deleteProduct(productId: string, options = {}) {
  return createProductsApi().productsProductIdDelete(productId, options);
}

export function createProduct(product: Product, options = {}) {
  return createProductsApi().productsPost(undefined, {
    transformRequest: [() => buildProductPayload(product)],
    ...options
  });
}
export function editProduct(productId: string, product: Product, options = {}) {
  return createProductsApi().productsProductIdPut(productId, undefined, {
    transformRequest: [() => buildProductPayload(product)],
    ...options
  });
}

export type ProductCategory = {
  product_category: string;
};
export function getProductCategories() {
  return createShipwellUIAPI().shipwellUiFilteredProductsGet();
}
