import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import {
  DeprecatedButton,
  Card,
  CollapsibleCardContent,
  Select,
  SvgIcon,
  Toast,
  Tooltip,
  RadioGroup
} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import classNames from 'classnames';
import {getWorkflow} from 'App/api/workflows';
import {createBulkOperation} from 'App/api/bulkOperations';
import {getPolicies} from 'App/api/policies';
import {initiateRoutingGuide} from 'App/api/shipment';
import {getCarrierLocationCapacityAvailability, getCarrierCapacityAvailability} from 'App/api/carriers';
import {getWorkflowFormValues} from 'App/containers/workflows/utils';
import {fetchShipmentModes, fetchEquipmentTypes} from 'App/actions/_shipments';
import RoutingGuidePolicyDetails from 'App/containers/routingGuides/components/policyDetails';
import RoutingGuideActionDetails from 'App/containers/routingGuides/components/actionDetails';
import RoutingGuideActionSummary from 'App/containers/routingGuides/components/actionSummary';
import {
  POLICY_TYPE_ROUTING_GUIDE,
  WORKFLOW_WARNING_OVER_CAPACITY_ON_EXECUTION
} from 'App/containers/workflows/workflowConstants';
import withFlags from 'App/utils/withFlags';
import {
  CARRIER_CAPACITY_AVAILABILITY_QUERY_KEY,
  POLICIES_QUERY_KEY,
  WORKFLOW_DETAILS_QUERY_KEY,
  WORKFLOW_FORM_VALUES_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import ShipwellLoader from 'App/common/shipwellLoader';

const ExecuteRoutingGuide = ({
  modes,
  equipmentTypes,
  dispatch,
  onCancel,
  selectedShipment,
  selectedShipments,
  onSubmitSuccess,
  wfaLocationCapacity,
  wfaCarrierCapacity
}) => {
  //selectedShipments means coming from bulk actions
  const showAutoMatchRoutingGuide = selectedShipments?.length > 0;

  const [selectedRoutingGuide, setSelectedRoutingGuide] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLocationCapacityWarning, setShowLocationCapacityWarning] = useState(false);
  const [showCarrierCapacityError, setShowCarrierCapacityError] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [autoMatchRoutingGuide, setAutoMatchRoutingGuide] = useState(showAutoMatchRoutingGuide);

  const {stops} = selectedShipment || {};
  const origin = stops?.length > 1 ? stops[0].location?.address : undefined;
  const destination = stops?.length > 1 ? stops.at(-1).location?.address : undefined;

  useEffect(() => {
    async function fetchLocationCapacityAvailability(shipmentId) {
      const capacityResponse = await getCarrierLocationCapacityAvailability(shipmentId);
      setShowLocationCapacityWarning(!capacityResponse?.body?.capacity_available);
    }
    if (selectedShipment?.id) {
      fetchLocationCapacityAvailability(selectedShipment.id);
    }
  }, [selectedShipment]);

  useEffect(() => {
    if (modes.length < 1) {
      dispatch(fetchShipmentModes());
    }
  }, [dispatch, modes]);

  useEffect(() => {
    if (equipmentTypes.length < 1) {
      dispatch(fetchEquipmentTypes());
    }
  }, [dispatch, equipmentTypes]);

  const policiesQuery = useQuery({
    queryKey: [POLICIES_QUERY_KEY, searchString, origin?.formatted_address, destination?.formatted_address],
    queryFn: async () => {
      const opts = {
        ...(searchString ? {q: searchString} : null),
        policyType: POLICY_TYPE_ROUTING_GUIDE,
        status: 'ACTIVE',
        ...(selectedShipment
          ? {
              modes: [get(selectedShipment, 'mode.code')],
              equipmentTypes: [get(selectedShipment, 'equipment_type.machine_readable')],
              ...(origin && !searchString
                ? {
                    originsContains: [
                      origin.address_1,
                      origin.city,
                      origin.postal_code,
                      origin.state_province,
                      origin.postal_code?.slice(0, 3)
                    ]
                  }
                : null),
              ...(destination && !searchString
                ? {
                    destinationsContains: [
                      destination.address_1,
                      destination.city,
                      destination.postal_code,
                      destination.state_province,
                      destination.postal_code?.slice(0, 3)
                    ]
                  }
                : null)
            }
          : null),
        pageSize: 1000
      };
      const response = await getPolicies(opts);
      return response.body?.results;
    }
  });
  //only allow the user to select policies that have an active workflow_id
  const allowedPolicies = policiesQuery.data?.filter((policy) => !!policy.workflow_id);

  const workflowQuery = useQuery(
    [WORKFLOW_DETAILS_QUERY_KEY, selectedRoutingGuide?.workflow_id],
    async () => {
      const response = await getWorkflow(selectedRoutingGuide?.workflow_id);
      return response.data;
    },
    {
      enabled: !!selectedRoutingGuide?.workflow_id
    }
  );
  const {data: workflow} = workflowQuery;

  const workflowFormValuesQuery = useQuery(
    [WORKFLOW_FORM_VALUES_QUERY_KEY, workflow?.id],
    () =>
      getWorkflowFormValues({
        workflow: workflow,
        policyType: POLICY_TYPE_ROUTING_GUIDE
      }),
    {
      enabled: !!workflow?.id
    }
  );
  const {data: workflowFormValues} = workflowFormValuesQuery;

  const carrierCapacityAvailabilityQuery = useQuery(
    [CARRIER_CAPACITY_AVAILABILITY_QUERY_KEY, workflowFormValues?.workflow_id],
    async () => {
      if (wfaCarrierCapacity && selectedShipment?.id) {
        const transformedActionsContractCapacityPromises = workflowFormValues?.actions?.map(async (action) => {
          let capacityAvailable = true;
          if (action.contract_id?.value) {
            const {body: carrierCapacityResponse} = await getCarrierCapacityAvailability(
              selectedShipment.id,
              action.contract_id.value
            );
            capacityAvailable = carrierCapacityResponse?.capacity_available;
          }
          return {
            ...action,
            capacityAvailable
          };
        });
        const transformedActionsWithContractCapacity = await Promise.all(transformedActionsContractCapacityPromises);
        setShowCarrierCapacityError(
          transformedActionsWithContractCapacity.every((action) => !action.capacityAvailable)
        );
        return transformedActionsWithContractCapacity;
      }
      return workflowFormValues?.actions;
    },
    {enabled: !!workflowFormValues?.workflow_id}
  );
  const {data: carrierCapacityAvailability} = carrierCapacityAvailabilityQuery;

  const runRoutingGuide = async (guide, selectedShipment) => {
    setIsSubmitting(true);
    try {
      if (selectedShipments) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          auto_match_routing_guides_workflows: autoMatchRoutingGuide,
          workflow_reference: autoMatchRoutingGuide
            ? null
            : {
                id: guide.workflow_id,
                version: workflow?.version_number
              },
          criteria: {
            id: {
              $in: selectedShipments
            }
          }
        };
        const response = await createBulkOperation(bulkOperationBody);
        onSubmitSuccess(response.data.id);
      } else {
        await initiateRoutingGuide(selectedShipment.id, {workflow: guide.workflow_id});
        onSubmitSuccess({
          showWarning:
            (wfaCarrierCapacity && carrierCapacityAvailability?.some((action) => !action.capacityAvailable)) ||
            (wfaLocationCapacity && showLocationCapacityWarning)
        });
      }
    } catch (error) {
      console.error('Error running routing guide', error);
      const errorDescription = get(error, 'error_description');
      const errorMessage = get(error, 'message');
      setShowError(errorDescription || errorMessage || 'There was an error running the routing guide.');
    }
    setIsSubmitting(false);
  };

  const noMatchingPolicies = searchString === '' && policiesQuery.isSuccess && allowedPolicies?.length === 0;
  const isLoading = [workflowQuery, workflowFormValuesQuery, carrierCapacityAvailabilityQuery].some(
    (query) => query.isInitialLoading || query.isLoading || query.isFetching || query.isRefetching
  );

  return (
    <div className={classNames('flex', 'flex-col', 'gap-4', {'py-8': noMatchingPolicies})}>
      {noMatchingPolicies ? (
        <span className="text-center font-bold text-sw-disabled">No matching routing guides for this shipment</span>
      ) : (
        <>
          {showAutoMatchRoutingGuide ? (
            <>
              <span>
                Select either the best matching Routing Guide and it will chose the closest matching Routing Guide for
                each individual shipment, or manually select a single Routing Guide that will be applied to all
                shipments selected.
              </span>
              <RadioGroup
                name="routingGuide-autoMatch"
                options={[
                  {label: 'Select Best Matching Routing Guide', value: true},
                  {label: 'Manually Select Routing Guide', value: false}
                ]}
                value={autoMatchRoutingGuide}
                onChange={(e) => setAutoMatchRoutingGuide(e.target.value === 'true')}
                disabled={isSubmitting}
              />
            </>
          ) : null}
        </>
      )}
      {!autoMatchRoutingGuide ? (
        <>
          <Select
            label="Routing Guide"
            clearable
            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
            menuPortalTarget={document.body}
            inputValue={searchString}
            onInputChange={(search) => setSearchString(search)}
            value={selectedRoutingGuide}
            onChange={(val) => setSelectedRoutingGuide(val)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isLoading={policiesQuery.isLoading}
            options={allowedPolicies}
            disabled={autoMatchRoutingGuide}
          />
          {selectedRoutingGuide ? (
            <>
              <RoutingGuidePolicyDetails policy={selectedRoutingGuide} />
              <Card
                title={
                  <div className="flex flex-row items-center">
                    <span>Actions</span>
                    {wfaCarrierCapacity && showCarrierCapacityError ? (
                      <Tooltip
                        wrapperClassname="ml-1"
                        tooltipContent="All carriers at capacity and routing guide can't run."
                      >
                        <SvgIcon name="ErrorOutlined" color="sw-error" />
                      </Tooltip>
                    ) : wfaLocationCapacity && showLocationCapacityWarning ? (
                      <Tooltip wrapperClassname="ml-1" tooltipContent={WORKFLOW_WARNING_OVER_CAPACITY_ON_EXECUTION}>
                        <SvgIcon name="ErrorOutlined" color="sw-warning" />
                      </Tooltip>
                    ) : null}
                  </div>
                }
                isCollapsible={false}
              >
                {isLoading ? (
                  <ShipwellLoader loading />
                ) : (
                  <CollapsibleCardContent>
                    <div className="flex flex-col gap-4">
                      {carrierCapacityAvailabilityQuery.isSuccess
                        ? carrierCapacityAvailability?.map((action, index) => (
                            <Card
                              key={action.id}
                              title={
                                <RoutingGuideActionSummary
                                  index={index}
                                  values={carrierCapacityAvailability}
                                  capacityAvailable={action.capacityAvailable}
                                  showCarrierCapacityError={showCarrierCapacityError}
                                />
                              }
                              isCollapsible
                              isCollapsed
                            >
                              <CollapsibleCardContent>
                                <RoutingGuideActionDetails action={action} selectedShipment={selectedShipment} />
                              </CollapsibleCardContent>
                            </Card>
                          ))
                        : null}
                    </div>
                  </CollapsibleCardContent>
                )}
              </Card>
            </>
          ) : null}
        </>
      ) : null}
      {noMatchingPolicies ? (
        <div className="flex justify-center">
          <DeprecatedButton variant="tertiary" onClick={() => window.open('/automation/routing-guides', '_blank')}>
            Create New Routing Guide
          </DeprecatedButton>
        </div>
      ) : (
        <div className="flex justify-end gap-4">
          <DeprecatedButton variant="secondary" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </DeprecatedButton>
          <DeprecatedButton
            disabled={
              !autoMatchRoutingGuide &&
              (isSubmitting ||
                !selectedRoutingGuide ||
                !carrierCapacityAvailability ||
                carrierCapacityAvailability?.length === 0 ||
                showCarrierCapacityError ||
                isLoading)
            }
            loading={isSubmitting}
            onClick={() => runRoutingGuide(selectedRoutingGuide, selectedShipment)}
          >
            Run Guide
          </DeprecatedButton>
        </div>
      )}
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </div>
  );
};

ExecuteRoutingGuide.propTypes = {
  dispatch: PropTypes.func,
  equipmentTypes: PropTypes.array,
  modes: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  selectedShipment: PropTypes.shape({
    id: PropTypes.string,
    stops: PropTypes.array
  }),
  selectedShipments: PropTypes.arrayOf(PropTypes.string),
  wfaLocationCapacity: PropTypes.bool,
  wfaCarrierCapacity: PropTypes.bool
};

ExecuteRoutingGuide.defaultProps = {
  dispatch: () => {},
  onCancel: () => {},
  onSubmitSuccess: () => {}
};

export default compose(
  withFlags('wfaLocationCapacity', 'wfaCarrierCapacity'),
  connect((state) => ({
    modes: state.shipments.shipmentModes,
    equipmentTypes: state.shipments.equipmentTypes
  }))
)(ExecuteRoutingGuide);
