import {
  Configuration,
  UserDashboardConfiguration,
  UsersApi,
  UsersApiPreferencesDashboardConfigurationsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createTableConfigApi() {
  return new UsersApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export const getUserDashboardConfig = (userDashboardConfigurationId: string) => {
  return createTableConfigApi().preferencesDashboardConfigurationsUserDashboardConfigurationIdGet({
    userDashboardConfigurationId
  });
};

export const getUserDashboardConfigs = ({
  page,
  pageSize,
  ordering,
  tableType
}: UsersApiPreferencesDashboardConfigurationsGetRequest) => {
  return createTableConfigApi().preferencesDashboardConfigurationsGet({page, pageSize, ordering, tableType});
};

export const createUserDashboardConfig = (userDashboardConfiguration: UserDashboardConfiguration) => {
  return createTableConfigApi().preferencesDashboardConfigurationsPost({userDashboardConfiguration});
};

export const updateUserDashboardConfig = ({
  userDashboardConfigurationId,
  userDashboardConfiguration
}: {
  userDashboardConfigurationId: string;
  userDashboardConfiguration: UserDashboardConfiguration;
}) => {
  return createTableConfigApi().preferencesDashboardConfigurationsUserDashboardConfigurationIdPut({
    userDashboardConfigurationId,
    userDashboardConfiguration
  });
};

export const deleteUserDashboardConfig = (userDashboardConfigurationId: string) => {
  return createTableConfigApi().preferencesDashboardConfigurationsUserDashboardConfigurationIdDelete({
    userDashboardConfigurationId
  });
};
