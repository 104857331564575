import {useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Formik, Form} from 'formik';
import {object, string, number, array} from 'yup';
import isEmpty from 'lodash/isEmpty';
import {
  CreateServiceProvider,
  CarrierTag,
  ServiceProvider,
  ServiceProviderStatusEnum,
  UpdateServiceProvider,
  AddServiceProviderTag,
  CreateUpdateServiceProviderContact
} from '@shipwell/backend-core-sdk';
import ServiceProviderDetailsFieldsCard from 'App/formComponents/formSections/serviceProviderFields/details';
import ServiceProviderContactsFieldsCard from 'App/formComponents/formSections/serviceProviderFields/contacts';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {
  createServiceProvider,
  updateServiceProvider,
  addServiceProviderTag,
  deleteServiceProviderTag,
  createServiceProviderContact,
  deleteServiceProviderContact,
  updateServiceProviderContact
} from 'App/api/serviceProviders/typed';
import {SERVICE_PROVIDER_QUERY_KEY} from 'App/data-hooks/queryKeys';

const addressValidationSchema = object().shape({
  company_name: string().nullable(),
  address_1: string().nullable(),
  address_2: string().nullable(),
  city: string().nullable(),
  country: string().nullable(),
  formatted_address: string().nullable(),
  latitude: number().nullable(),
  longitude: number().nullable(),
  phone_number: string().nullable(),
  state_province: string().nullable(),
  timezone: string().nullable()
});

const createValidationSchema = (shallValidateContacts: boolean) => {
  return object().shape({
    name: string().required('Provider name is required.'),
    mailing_address: addressValidationSchema.test(
      'mailing_address',
      'A valid address is required.',
      (value) => !isEmpty(value.address_1)
    ),
    billing_address: addressValidationSchema,
    tags: array()
      .of(
        object().shape({
          id: string().required(),
          name: string().required(),
          created_at: string().required(),
          updated_at: string().required()
        })
      )
      .required(),
    notes: string().nullable(),
    contacts: array().of(
      object().shape({
        first_name: shallValidateContacts ? string().required('A name is required.') : string().optional(),
        last_name: string().optional(),
        phone_number: shallValidateContacts ? string().required('A phone number is required.') : string().optional(),
        email: shallValidateContacts
          ? string().email('A valid email required.').required('A valid email required.')
          : string().email('A valid email required.').optional()
      })
    )
  });
};

type FormValues = Omit<CreateServiceProvider, 'tags' | 'contacts'> & {
  tags: CarrierTag[];
  contacts: (CreateUpdateServiceProviderContact & {id?: string})[];
};

const ServiceProviderForm = ({
  defaultValues,
  onSubmit,
  onCancel
}: {
  defaultValues?: Omit<ServiceProvider, 'tags'> & {tags: CarrierTag[]};
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const [hasEmptyContactsForm, setHasEmptyContactsForm] = useState(false);
  const queryClient = useQueryClient();
  const defaultFormValues: FormValues = {
    name: defaultValues?.name || '',
    mailing_address: defaultValues?.mailing_address || {country: ''},
    billing_address: defaultValues?.billing_address || {country: ''},
    status: defaultValues?.status || ServiceProviderStatusEnum.Active,
    capabilities: defaultValues?.capabilities || [],
    tags: defaultValues?.tags || [],
    notes: defaultValues?.notes || '',
    contacts:
      defaultValues?.contacts && defaultValues.contacts.length > 0
        ? defaultValues.contacts
        : [
            {
              first_name: '',
              last_name: '',
              phone_number: '',
              email: ''
            }
          ]
  };

  const createServiceProviderMustation = useMutation<
    Awaited<ReturnType<typeof createServiceProvider>>,
    unknown,
    {serviceProvider: UpdateServiceProvider}
  >(({serviceProvider}) => createServiceProvider(serviceProvider));
  const updateServiceProviderMutation = useMutation<
    Awaited<ReturnType<typeof updateServiceProvider>>,
    unknown,
    {serviceProviderId: string; serviceProvider: UpdateServiceProvider}
  >(({serviceProviderId, serviceProvider}) => updateServiceProvider(serviceProviderId, serviceProvider));
  const addServiceProviderTagMutation = useMutation<
    Awaited<ReturnType<typeof addServiceProviderTag>>,
    unknown,
    {serviceProviderId: string; tag: AddServiceProviderTag}
  >(({serviceProviderId, tag}) => addServiceProviderTag(serviceProviderId, tag));
  const deleteServiceProviderTagMutation = useMutation<
    Awaited<ReturnType<typeof deleteServiceProviderTag>>,
    unknown,
    {serviceProviderId: string; tagId: string}
  >(({serviceProviderId, tagId}) => deleteServiceProviderTag(serviceProviderId, tagId));
  const createServiceProviderContactMutation = useMutation<
    Awaited<ReturnType<typeof createServiceProviderContact>>,
    unknown,
    {serviceProviderId: string; contact: CreateUpdateServiceProviderContact}
  >(({serviceProviderId, contact}) => createServiceProviderContact(serviceProviderId, contact));
  const updateServiceProviderContactMutation = useMutation<
    Awaited<ReturnType<typeof updateServiceProviderContact>>,
    unknown,
    {serviceProviderId: string; contactId: string; contact: CreateUpdateServiceProviderContact}
  >(({serviceProviderId, contactId, contact}) => updateServiceProviderContact(serviceProviderId, contactId, contact));
  const deleteServiceProviderContactMutation = useMutation<
    Awaited<ReturnType<typeof deleteServiceProviderContact>>,
    unknown,
    {serviceProviderId: string; contactId: string}
  >(({serviceProviderId, contactId}) => deleteServiceProviderContact(serviceProviderId, contactId));

  const handleSubmit = async (values: FormValues) => {
    if (defaultValues) {
      const serviceProviderId = defaultValues.id;
      const {tags, contacts, ...serviceProvider} = values;
      const serviceProviderPromise = updateServiceProviderMutation.mutateAsync({
        serviceProviderId,
        serviceProvider: {
          ...serviceProvider,
          billing_address: serviceProvider.billing_address?.formatted_address
            ? serviceProvider.billing_address
            : undefined,
          notes: values.notes || null
        }
      });

      const defaultTagIds = defaultValues.tags.map((tag) => tag.id);
      const tagIds = tags.map((tag) => tag.id);
      const addTagsPromise = tagIds
        .filter((tagId) => !defaultTagIds.includes(tagId))
        .map((tagId) => addServiceProviderTagMutation.mutateAsync({serviceProviderId, tag: {tag: tagId || undefined}}));
      const removeTagsPromise = defaultTagIds
        .filter((defaultTagId) => !tagIds.includes(defaultTagId))
        .map((tagId) => deleteServiceProviderTagMutation.mutateAsync({serviceProviderId, tagId: tagId || ''}));
      const addContactsPromise = contacts
        .filter(() => !hasEmptyContactsForm)
        .filter((contact) => !contact.id)
        .map((contact) => createServiceProviderContactMutation.mutateAsync({serviceProviderId, contact}));
      const updateContactsPromise = contacts
        .filter((contact) => contact.id)
        .filter((contact) => {
          const defaultContact = defaultValues.contacts?.find(({id}) => id === contact.id);
          const firstNameChanged = defaultContact?.first_name !== contact.first_name;
          const lastNameChanged = defaultContact?.last_name !== contact.last_name;
          const phoneChanged = defaultContact?.phone_number !== contact.phone_number;
          const emailChanged = defaultContact?.email !== contact.email;
          return firstNameChanged || lastNameChanged || phoneChanged || emailChanged;
        })
        .map((contact) => {
          const {id, first_name, last_name, phone_number, email} = contact;
          return updateServiceProviderContactMutation.mutateAsync({
            serviceProviderId,
            contactId: id || '',
            contact: {first_name, last_name, phone_number, email}
          });
        });
      const deleteContactsPromise = defaultValues.contacts
        ?.filter((defaultContact) => !contacts?.some((contact) => contact.id === defaultContact.id))
        .map((defaultContact) =>
          deleteServiceProviderContactMutation.mutate({
            serviceProviderId,
            contactId: defaultContact.id
          })
        );

      await Promise.allSettled([
        serviceProviderPromise,
        addTagsPromise,
        removeTagsPromise,
        addContactsPromise,
        updateContactsPromise,
        deleteContactsPromise
      ]);
      await queryClient.invalidateQueries([SERVICE_PROVIDER_QUERY_KEY, serviceProviderId]);
      onSubmit();
    } else {
      const serviceProvider = {
        ...values,
        billing_address: values.billing_address?.formatted_address ? values.billing_address : undefined,
        tags: values.tags?.map((tag: CarrierTag) => tag.id || '') || [],
        notes: values.notes || null,
        contacts: hasEmptyContactsForm ? [] : values.contacts
      };
      createServiceProviderMustation.mutate({serviceProvider}, {onSettled: onSubmit});
    }
  };

  return (
    <Formik
      initialValues={defaultFormValues}
      validationSchema={createValidationSchema(!hasEmptyContactsForm)}
      onSubmit={handleSubmit}
    >
      {({isSubmitting}) => {
        return (
          <Form className="grid-1-2 w-full gap-2.5" noValidate>
            <ServiceProviderDetailsFieldsCard />
            <ServiceProviderContactsFieldsCard setHasEmptyContactsForm={setHasEmptyContactsForm} />
            <FormFooter onCancel={onCancel} isSubmitting={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ServiceProviderForm;
