import {ReactNode, useState} from 'react';
import {IconButton, SvgIcon} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

// design wants to not let this pink anywhere else in the app.
// So instead of adding it to our tailwind config, I'm hardcoding it here
const mrPink = '#FF00A2';
const mrPinkHover = '#FF00A280';
const mrPinkAlpha = '#FF00A210';

export const TryItNowTag = ({children, defaultIsOpen = false}: {children: ReactNode; defaultIsOpen?: boolean}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [backgroundColor, setBackgroundColor] = useState(mrPink);

  if (!isOpen) {
    return (
      <button
        className="h-10 w-min"
        onClick={() => setIsOpen(true)}
        onMouseEnter={() => setBackgroundColor(mrPinkHover)}
        onMouseLeave={() => setBackgroundColor(mrPink)}
        aria-label="show feature option"
      >
        <SvgIcon className="transition-all" name="StarCircle" color={backgroundColor} />
      </button>
    );
  }
  return (
    <div className="rounded p-2" style={{backgroundColor: mrPinkAlpha}}>
      <FlexBox items="center" justify="between" gap="s">
        <SvgIcon name="StarCircle" color={mrPink} />
        <div>{children}</div>
        <IconButton
          iconName="Close"
          size="xs"
          aria-label="Close tag"
          onClick={() => {
            setIsOpen(false);
            setBackgroundColor(mrPink);
          }}
        />
      </FlexBox>
    </div>
  );
};
