import {cloneElement} from 'react';
import PropTypes from 'prop-types';
import {Card} from '@shipwell/shipwell-ui';
import './styles.scss';

const AutomationCard = (props) => {
  if (props.title && props.body) {
    return (
      <Card title={props.title} className={`automation__card`}>
        {props.body && (
          <div className="automation__card-body">
            {
              //clone children so we can pass along functions from this parent
              cloneElement(props.body, {...props})
            }
          </div>
        )}
      </Card>
    );
  }
  return (
    <div className={`automation__card`}>
      {props.title && <div className="automation__card-header">{props.title}</div>}
      {props.body && (
        <div className="automation__card-body-padding">
          {
            //clone children so we can pass along functions from this parent
            cloneElement(props.body, {...props})
          }
        </div>
      )}
    </div>
  );
};

AutomationCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  body: PropTypes.node
};

export default AutomationCard;
