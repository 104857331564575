import {useQuery} from '@tanstack/react-query';
import {checkRMISAuthentication} from 'App/api/integrations/typed';
import {RMIS_STATUS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const useRMISStatus = () => {
  const getRMISStatusQuery = useQuery([RMIS_STATUS_QUERY_KEY], async () => {
    const response = await checkRMISAuthentication();
    return response.data;
  });
  return {
    isRMISIntegrated: getRMISStatusQuery.data?.is_authenticated,
    isRMISStatusLoading: getRMISStatusQuery.isInitialLoading
  };
};

export default useRMISStatus;
