import {useMutation, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {FormikValues} from 'formik';
import {TriumphPayApprovedDateSource, TriumphPayAuthSettings} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {
  COMPANY_INTEGRATION_TRIUMPH_PAY_APPROVED_DATE,
  COMPANY_INTEGRATION_TRIUMPH_PAY_DETAILS
} from 'App/data-hooks/queryKeys';
import {
  getTriumphPaySettings,
  getTriumphPaySettingsApprovedDateSourceOptions,
  updateTriumphPaySettings
} from 'App/api/integrations/typed';
import {HOT_DATA_STALE_TIME, STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const useGetTriumphPaySettingsApprovedDateOptions = (
  queryOptions: UseQueryOptions<
    TriumphPayApprovedDateSource[],
    AxiosError,
    TriumphPayApprovedDateSource[],
    string[]
  > = {}
) =>
  useQuery(
    [COMPANY_INTEGRATION_TRIUMPH_PAY_APPROVED_DATE],
    async () => {
      const response = await getTriumphPaySettingsApprovedDateSourceOptions();
      return response.data;
    },
    {staleTime: STATIC_DATA_STALE_TIME, ...queryOptions}
  );
export const useGetTriumphPaySettings = (
  queryOptions: UseQueryOptions<TriumphPayAuthSettings, AxiosError, TriumphPayAuthSettings, string[]> = {}
) =>
  useQuery(
    [COMPANY_INTEGRATION_TRIUMPH_PAY_DETAILS],
    async () => {
      const response = await getTriumphPaySettings();
      return response.data;
    },
    {staleTime: HOT_DATA_STALE_TIME, ...queryOptions}
  );

export const useTriumphPaySettingsMutation = (mutationOptions = {}) =>
  useMutation((formData: FormikValues) => updateTriumphPaySettings(formData), mutationOptions);
