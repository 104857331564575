import {useQuery} from '@tanstack/react-query';
import {isTiveConnected} from 'App/utils/integrations/tive/tive';

export function useActiveTrackingDevices() {
  const listQuery = useQuery({
    queryKey: ['useActiveTrackingDevices'],
    queryFn: async () => {
      const tive = await isTiveConnected();
      return tive;
    }
  });

  return {
    isServicesConnected: listQuery.data,
    isFetching: listQuery.isFetching
  };
}
