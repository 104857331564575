import {
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_ASSIGN_CARRIER,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  SHIPMENT_INITIATE_ROUTING_GUIDE
} from 'App/components/permissions/PermissionsFallback/constants';

export type PermissionsByActionType = {
  view: string[];
  update: string[];
  create: string[];
  delete: string[];
};

export type PermissionsCategory = {
  name: string;
  label: string;
  description: string;
  permissions: PermissionsByActionType;
  type?: string;
  nested?: PermissionsCategory[];
  // There is an edge case based off of a new permission category, my shipments, which is an exception
  // that proves the rule. For this ONE permission category, there is not a 'create my shipments' permission
  // (because that doesn't make sense), the user who has 'my shipments' would use the 'create shipments'
  // permission, and otherwise the user is EITHER granted 'my shipments' OR 'all shipments' view, edit, and delete permissions.
  // However, if we could not uncheck the 'view' permission, the user would always be granted 'view all shipments'.
  enableReadCheckboxIfWriteGranted?: boolean;
};

export const permissionsCategories: PermissionsCategory[] = [
  {
    name: 'analytics',
    label: 'Analytics',
    description:
      'Analytics permission gives user the ability to view dashboard and generate reports for their assigned accounts.',
    permissions: {
      view: ['analytics.view'],
      update: [],
      create: [],
      delete: []
    }
  },
  {
    name: 'address_book',
    label: 'Address Book',
    description: 'Allow users to view, create, edit and remove address book entries.',
    permissions: {
      view: ['locations.view_address_book_entries'],
      update: ['locations.update_address_book_entries'],
      create: ['locations.create_address_book_entries'],
      delete: ['locations.delete_address_book_entries']
    }
  },
  {
    name: 'billing',
    label: 'Billing',
    description:
      'Billing permission gives user the ability to create payments, initiate refunds, add reconciliations, and send invoices.',
    permissions: {
      view: ['billing.view_billing_info', 'billing.view_invoices'],
      update: ['billing.update_billing_info', 'billing.update_invoices'],
      create: ['billing.create_billing_info', 'billing.create_invoices'],
      delete: ['billing.delete_invoices']
    }
  },
  {
    name: 'carrier',
    label: 'Carrier',
    description: 'Carrier permissions give the user the ability to create, view, and delete drivers.',
    permissions: {
      view: ['carrier.view_driver_relationships'],
      update: [],
      create: ['carrier.create_driver_relationships'],
      delete: ['carrier.remove_driver_relationships']
    }
  },
  {
    name: 'carrier_relationships',
    label: 'Carrier Relationships',
    description: 'Allow users to view, create, and remove relationships with carriers',
    permissions: {
      view: ['carrier_relationships.view_carrier_relationships'],
      update: [],
      create: ['carrier_relationships.create_carrier_relationships'],
      delete: ['carrier_relationships.remove_carrier_relationships']
    }
  },
  {
    name: 'general_dock_scheduling',
    label: 'General Dock Scheduling',
    description: 'Manage core resource permissions for dock scheduling.',
    permissions: {
      view: [
        'dock_scheduling.view_analytics',
        'dock_scheduling.view_appointments',
        'dock_scheduling.view_calendar',
        'dock_scheduling.view_dashboard',
        'dock_scheduling.view_facilities'
      ],
      update: [
        'dock_scheduling.update_analytics',
        'dock_scheduling.update_appointments',
        'dock_scheduling.update_calendar',
        'dock_scheduling.update_facilities'
      ],
      create: ['dock_scheduling.create_appointments', 'dock_scheduling.create_facilities'],
      delete: ['dock_scheduling.delete_appointments', 'dock_scheduling.delete_facilities']
    },
    type: 'nested',
    nested: [
      {
        name: 'dock_scheduling.analytics',
        label: 'Analytics',
        description: 'Allows users to view and edit the dock scheduling analytics.',
        permissions: {
          view: ['dock_scheduling.view_analytics'],
          update: ['dock_scheduling.update_analytics'],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.appointments',
        label: 'Appointments',
        description: 'Allows users to view, edit, create and delete appointments.',
        permissions: {
          view: ['dock_scheduling.view_appointments'],
          update: ['dock_scheduling.update_appointments'],
          create: ['dock_scheduling.create_appointments'],
          delete: ['dock_scheduling.delete_appointments']
        }
      },
      {
        name: 'dock_scheduling.calendar',
        label: 'Calendar',
        description: 'Allows users to view and edit the dock scheduling calendar.',
        permissions: {
          view: ['dock_scheduling.view_calendar'],
          update: ['dock_scheduling.update_calendar'],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.dashboard',
        label: 'Dashboard',
        description: 'Allows users to view and edit the dock scheduling dashboard.',
        permissions: {
          view: ['dock_scheduling.view_dashboard'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.facilities',
        label: 'Facilities',
        description: 'Allows users to view, edit, create and delete facilities.',
        permissions: {
          view: ['dock_scheduling.view_facilities'],
          update: ['dock_scheduling.update_facilities'],
          create: ['dock_scheduling.create_facilities'],
          delete: ['dock_scheduling.delete_facilities']
        }
      }
    ]
  },
  {
    name: 'enablements_dock_scheduling',
    label: 'Dock Scheduling Enablements',
    description: 'Manage permissions for dock scheduling workflows.',
    permissions: {
      view: [
        'dock_scheduling.book_appointments',
        'dock_scheduling.book_same_day_appointments',
        'dock_scheduling.reschedule_appointments',
        'dock_scheduling.reject_appointments',
        'dock_scheduling.cancel_appointments',
        'dock_scheduling.check_in_appointments',
        'dock_scheduling.check_out_appointments',
        'dock_scheduling.update_status_appointments'
      ],
      update: [],
      create: [],
      delete: []
    },
    type: 'toggle-nested',
    nested: [
      {
        name: 'dock_scheduling.book_appointments',
        label: 'Book Appointments',
        description: 'Allows users to book appointments.',
        permissions: {
          view: ['dock_scheduling.book_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.book_same_day_appointments',
        label: 'Book Same Day Appointments',
        description: 'Allows users to book same day appointments.',
        permissions: {
          view: ['dock_scheduling.book_same_day_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.reschedule_appointments',
        label: 'Reschedule Appointments',
        description: 'Allows users to reschedule existing appointments.',
        permissions: {
          view: ['dock_scheduling.reschedule_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.reject_appointments',
        label: 'Reject Appointments',
        description: 'Allows users to reject appointments.',
        permissions: {
          view: ['dock_scheduling.reject_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.cancel_appointments',
        label: 'Cancel Appointments',
        description: 'Allows users to cancel appointments.',
        permissions: {
          view: ['dock_scheduling.cancel_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.check_in_appointments',
        label: 'Check In Appointments',
        description: 'Allows users to check in appointments.',
        permissions: {
          view: ['dock_scheduling.check_in_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.check_out_appointments',
        label: 'Check Out Appointments',
        description: 'Allows users to check out appointments.',
        permissions: {
          view: ['dock_scheduling.check_out_appointments'],
          update: [],
          create: [],
          delete: []
        }
      },
      {
        name: 'dock_scheduling.update_status_appointments',
        label: 'Update Status Appointments',
        description: 'Allows users to update the status of appointments.',
        permissions: {
          view: ['dock_scheduling.update_status_appointments'],
          update: [],
          create: [],
          delete: []
        }
      }
    ]
  },
  {
    name: 'company',
    label: 'Company',
    description:
      'Company permissions give the user the ability to change company settings such as company name and addresses.',
    permissions: {
      view: ['company.view_company_details'],
      update: ['company.update_company_details', 'company.administrator', 'settlements.admin'],
      create: [],
      delete: []
    }
  },
  {
    name: 'contracts',
    label: 'Contracts',
    description: 'Add, update, or delete contracts with your Shipwell platform.',
    permissions: {
      view: ['contracts.view_contracts'],
      update: ['contracts.update_contracts'],
      create: ['contracts.create_contracts'],
      delete: ['contracts.delete_contracts']
    }
  },
  {
    name: 'customer_relationships',
    label: 'Customer Relationships',
    description:
      'Customer relationship permissions give the user the ability to create, view, and delete relationships with customers.',
    permissions: {
      view: ['customer_relationships.view'],
      update: [],
      create: ['customer_relationships.create'],
      delete: ['customer_relationships.remove']
    }
  },
  {
    name: 'invoicing',
    label: 'Invoicing',
    description: 'Allow users to setup invoicing, email, view, create, update, and delete invoices',
    permissions: {
      view: [
        'invoicing.view_invoices',
        'invoicing.view_freight_invoices',
        'invoicing.email_invoices',
        'settlements.view_dashboard'
      ],
      update: ['invoicing.update_invoices', 'invoicing.update_freight_invoices'],
      create: ['invoicing.create_freight_invoices', 'invoicing.setup_invoicing'],
      delete: []
    }
  },
  {
    name: 'my_shipments',
    label: 'My Shipments',
    description: `Allow users to only view, update, and/or delete shipments where they are 'Created by' user or a 'Rep'.`,
    permissions: {
      view: ['shipments.view_my_shipments'],
      update: ['shipments.update_my_shipments'],
      create: [],
      delete: ['shipments.delete_my_shipments']
    }
  },
  {
    name: 'orders',
    label: 'Orders',
    description: 'Allow users to view, create, update, and delete orders',
    permissions: {
      view: ['orders.view_orders'],
      update: ['orders.update_orders'],
      create: ['orders.create_orders'],
      delete: ['orders.delete_orders']
    }
  },
  {
    name: 'quotes',
    label: 'Quotes',
    description: 'Quote permissions give the user the ability to generate quotes.',
    permissions: {
      view: ['quotes.view_quotes'],
      update: ['quotes.update_quotes'],
      create: ['quotes.create_quotes'],
      delete: ['quotes.delete_quotes']
    }
  },
  {
    name: 'shipments',
    label: 'Shipments',
    description:
      'Shipment administrators can create, cancel, update, track, and manage shipments. They will be able to create customer communications and send emails with the associated shipment.',
    permissions: {
      view: ['shipments.view_shipments'],
      update: ['shipments.update_shipments'],
      create: ['shipments.create_shipments'],
      delete: ['shipments.delete_shipments']
    },
    enableReadCheckboxIfWriteGranted: true
  },
  {
    name: 'shipment_financials',
    label: 'Shipment Financials',
    description:
      'Allow users to view customers, providers, and net shipment financials; shipment bids, rates, and pricing intel; allows users to edit financial line items.',
    permissions: {
      view: ['shipments.view_financials'],
      update: [],
      create: [],
      delete: []
    }
  },
  {
    name: 'shipment_tendering',
    label: 'Shipment Tendering',
    description:
      'Allows users to assign carriers to shipments, create direct tenders and bid requests, and initiate routing guides.',
    permissions: {
      view: [],
      update: [],
      create: [
        SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
        SHIPMENT_ASSIGN_CARRIER,
        SHIPMENT_INITIATE_ROUTING_GUIDE,
        CREATE_SHIPMENT_DIRECT_TENDER
      ],
      delete: []
    },
    type: 'toggle-nested',
    nested: [
      {
        name: SHIPMENT_ASSIGN_CARRIER,
        label: 'Assign',
        description: 'Allows users to assign carriers to shipments.',
        permissions: {
          view: [],
          update: [],
          create: [SHIPMENT_ASSIGN_CARRIER],
          delete: []
        }
      },
      {
        name: SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
        label: 'Bid',
        description: 'Allows users to create bid requests.',
        permissions: {
          view: [],
          update: [],
          create: [SHIPMENT_CREATE_SPOT_NEGOTIATIONS],
          delete: []
        }
      },

      {
        name: SHIPMENT_INITIATE_ROUTING_GUIDE,
        label: 'Route',
        description: 'Allows users to initiate routing guides.',
        permissions: {
          view: [],
          update: [],
          create: [SHIPMENT_INITIATE_ROUTING_GUIDE],
          delete: []
        }
      },
      {
        name: CREATE_SHIPMENT_DIRECT_TENDER,
        label: 'Tender',
        description: 'Allows users to create direct tenders.',
        permissions: {
          view: [],
          update: [],
          create: [CREATE_SHIPMENT_DIRECT_TENDER],
          delete: []
        }
      }
    ]
  },
  {
    name: 'third_party_app_integrations',
    label: 'Third Party App Integrations',
    description: 'Add, manage or remove 3rd party application integrations with your Shipwell platform.',
    permissions: {
      view: ['integrations.view_integrations'],
      update: ['integrations.manage_integrations'],
      create: ['integrations.add_integrations'],
      delete: ['integrations.remove_integrations']
    }
  },
  {
    name: 'users',
    label: 'Users',
    description: 'User permissions give the user the ability to add, edit, and delete users within the company.',
    permissions: {
      view: ['users.view_company_users'],
      update: [
        'users.update_company_users',
        'admin.update_permission_categories',
        'admin.update_permissions',
        'admin.update_users'
      ],
      create: ['users.create_company_users', 'admin.create_users'],
      delete: ['users.delete_company_users', 'admin.delete_users']
    }
  },
  {
    name: 'override_insurance_limit',
    label: 'Override Insurance Limit',
    description: 'Allow user to override the insurance limit check.',
    permissions: {
      view: [],
      update: ['carrier_compliance.override_insurance_limit_check'],
      create: [],
      delete: []
    }
  }
];
