import pluralize from 'pluralize';
import classnames from 'classnames';
import {PropsWithChildren} from 'react';

export const SelectedCount = ({
  count,
  itemLabel = 'Item',
  className,
  onClick,
  clickCta,
  children
}: PropsWithChildren<{
  count: number;
  itemLabel: string;
  className?: string;
  onClick?: (event?: unknown) => void;
  clickCta?: string;
}>) => {
  const contentString = `${pluralize(itemLabel, count, true)} Selected`;

  return (
    <div
      className={classnames(
        'flex items-center justify-center gap-x-4 rounded bg-sw-active px-4 py-2 shadow-md',
        {'divide-x divide-sw-text-reverse [&>*:not(:first-child)]:pl-4': !children},
        className
      )}
    >
      <span className="text-lg font-bold text-sw-text-reverse">{contentString}</span>
      {children ? <div className="border-x border-x-sw-text-reverse px-4">{children}</div> : null}
      <button className="text-sw-text-reverse" onClick={onClick}>
        {clickCta}
      </button>
    </div>
  );
};
