import {useContext, useMemo} from 'react';
import {FacilityDock} from '@shipwell/tempus-sdk';
import {SupplierAppointmentContext} from 'App/containers/appointments/components/forms/SupplierAppointment';
import Availability from 'App/containers/appointments/components/forms/AppointmentForm/components/availability';

const SupplierAvailabilityTimeslots = () => {
  const {facility, supplierAppointmentFormData, clickedTime, setAvailabilityDock, setAvailabilityWindow} =
    useContext(SupplierAppointmentContext);
  const selectedDate = useMemo(() => {
    const [year, month, day] = supplierAppointmentFormData.appointment_date.split('-').map(Number);
    return new Date(year, month - 1, day);
  }, [supplierAppointmentFormData.appointment_date]);

  return (
    <div>
      <Availability
        facilityId={facility?.id || ''}
        clickedTime={clickedTime ? clickedTime : selectedDate}
        onSelectedDateChanged={(availWindow) => setAvailabilityWindow(availWindow)}
        onDockChanged={(dock: FacilityDock | null) => {
          setAvailabilityDock(dock);
          setAvailabilityWindow(null);
        }}
        onPaginate={() => setAvailabilityWindow(null)}
        supplierAppointmentFormData={supplierAppointmentFormData}
      />
    </div>
  );
};
export default SupplierAvailabilityTimeslots;
