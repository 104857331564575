import {getOptimizedRfpCarrierBidRequests} from 'App/api/rfpOptimized/typed';
import PageHeader from 'App/common/pageHeader';
import RfpTile from 'App/containers/userCompany/rfps/RfpTile';
import Loader from 'App/common/shipwellLoader';
import {RFP_ROWS_QUERY} from 'App/data-hooks/queryKeys';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import useInfiniteScroll from 'App/utils/hooks/useInfiniteScroll';

const RfpCarrierList = () => {
  const {
    handleScroll,
    pages: rfpBidRequestsPages,
    isFetchingNextPage,
    isLoading
  } = useInfiniteScroll([RFP_ROWS_QUERY], getOptimizedRfpCarrierBidRequests);

  const hasResults = rfpBidRequestsPages[0]?.results?.length && rfpBidRequestsPages[0].results.length > 0;

  return (
    <div className="RfpCarrierList flex w-full flex-col gap-4 overflow-hidden h-screen-minus-16">
      <PageHeader title="RFPs" />
      {isLoading ? (
        <Loader loading />
      ) : hasResults ? (
        <div onScroll={handleScroll} className="flex h-full flex-col gap-4 overflow-auto p-4 pt-0">
          {rfpBidRequestsPages.map((page) => {
            return page.results?.map((rfp) => <RfpTile key={rfp.id} rfp={rfp} />);
          })}
          {isFetchingNextPage ? (
            <div className="flex flex-col items-center justify-center">
              <Loader loading />
              <span>Loading next page...</span>
            </div>
          ) : null}
        </div>
      ) : (
        <EmptyListView itemLabel="RFP Requests" />
      )}
    </div>
  );
};

export default RfpCarrierList;
