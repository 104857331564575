import {useState, useEffect} from 'react';
import './styles.scss';

const SupplierShipment = (props) => {
  const {location, router, children} = props;

  return <div className="supplierShipment">{children}</div>;
};

export default SupplierShipment;
