import {LocationType, ShipwellError} from '@shipwell/backend-core-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {LOCATION_TYPES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getLocationTypes} from 'App/api/locations/typed';

export type UseLocationTypesQueryOptions = Omit<
  UseQueryOptions<LocationType[], AxiosError<ShipwellError>, LocationType[], string[]>,
  'queryFn' | 'queryKey'
>;

const useLocationTypesQuery = (options?: UseLocationTypesQueryOptions) => {
  const query = useQuery([LOCATION_TYPES_QUERY_KEY], getLocationTypes, options);

  return {
    locationTypes: query.data || null,
    isLocationTypesLoading: query.status === 'success',
    isLocationTypesError: query.status === 'error',
    isLocationTypesSuccess: query.status === 'success',
    locationTypesError: query.error || null
  };
};

export {useLocationTypesQuery};
