export const eventTypes = {
  BOOKED: 'BOOKED',
  CARRIER_MATCHED: 'CARRIER_MATCHED',
  ETA_CHANGED: 'ETA_CHANGED',
  LOCATION_UPDATED: 'LOCATION_UPDATED',
  DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
  DISPATCHED: 'DISPATCHED',
  INFO: 'INFO',
  STOP_EVENT: 'STOP_EVENT',
  LOCATION_UPDATE: 'LOCATION_UPDATE',
  CONTAINER: 'CONTAINER'
} as const;

export const STOP_ORDINAL_THRESHOLD = 19;

export const timelineFilterTypes = {
  ROLLUP_EVENTS: 'rollup',
  INDIVIDUAL_EVENTS: 'individual',
  ALL_EVENT_UPDATES: 'all',
  TRACKING_EVENT_UPDATES: 'location',
  HIDE_FUTURE_STOPS: 'true',
  SHOW_FUTURE_STOPS: 'false'
} as const;
