import {Button} from '@shipwell/shipwell-ui';
import {AvailableDeviceSelect} from './availableDeviceSelect/availableDeviceSelect';
import {ProviderSelect} from './providerSelect/providerSelect';
import {useDevicesForm} from './useDevicesForm/useDevicesForm';
import {UseDevicesFormValues} from './useDevicesForm/useDevicesFormValues';

export interface DevicesFormProps {
  isLoading: boolean;
  onSubmit: (data: UseDevicesFormValues) => void;
}

export function DevicesForm(props: DevicesFormProps) {
  const form = useDevicesForm({
    onSubmit: props.onSubmit
  });

  return (
    <form>
      <div className="grid grid-cols-2 gap-3">
        <div className="col-span-2 font-bold">Select Device</div>
        <div className="col-span-1">
          <ProviderSelect disable {...form.getBasicProps('provider')} />
        </div>
        <div className=" col-span-1">
          <div className="flex gap-3">
            <div className="flex-1">
              <AvailableDeviceSelect {...form.getBasicProps('deviceId')} />
            </div>
            <div>
              <Button
                isLoading={props.isLoading}
                disabled={props.isLoading}
                variant="secondary"
                onClick={form.submitForm}
              >
                Attach
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
