import {WithRouterProps, withRouter} from 'react-router';
import {Form, Formik} from 'formik';
import {Card} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {UpdateSupplier} from '@shipwell/backend-core-sdk';
import {SupplierFields} from '../fields';
import {CREATE_SUPPLIER_FORM_SCHEMA} from '../schemas';
import {SupplierErrorBanner} from '../components';
import {pickUpdateSupplierFields} from './utils';
import Loader from 'App/common/shipwellLoader';
import {useGetSupplier, useUpdateSupplier} from 'App/data-hooks/suppliers';
import FormFooter from 'App/formComponents/formSections/formFooter';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type SupplierEditFormProps = Pick<WithRouterProps<{supplier_id: string}>, 'params'> &
  Pick<WithStatusToastProps, 'setError' | 'setSuccess'>;

// I tried using emptyStringToNull from yupHelpers in the schema, but couldn't get it to work
const toNull = (value: string | undefined | null) =>
  typeof value === 'undefined' || value === null || value === '' ? null : value;

export const SupplierEditFormBase = ({params, setError, setSuccess}: SupplierEditFormProps) => {
  const {supplier, error} = useGetSupplier(params.supplier_id);
  const {updateSupplier, isUpdatingSupplier} = useUpdateSupplier();

  if (supplier) {
    const supplierId = supplier.id;
    const handleSubmit = (updatedSupplier: UpdateSupplier) => {
      updateSupplier(
        {
          supplierId,
          updatedSupplier: {
            ...updatedSupplier,
            billing_email: toNull(updatedSupplier.billing_email),
            notes: toNull(updatedSupplier.notes)
          }
        },
        {
          onError: () => {
            setError('Error', 'There was an error updating this supplier. Please try again.');
          },
          onSuccess: () => {
            setSuccess('Success!', 'Supplier details updated successfully.');
          }
        }
      );
    };

    return (
      <Formik
        initialValues={pickUpdateSupplierFields(supplier)}
        onSubmit={handleSubmit}
        validationSchema={CREATE_SUPPLIER_FORM_SCHEMA}
        validateOnMount
        enableReinitialize
      >
        {({dirty, resetForm}) => (
          <Card draggableProvided={false} title="Supplier Information">
            <Form className="grid gap-4" noValidate>
              <SupplierFields />
              {dirty ? (
                <FormFooter
                  primaryActionName="Save"
                  secondaryActionName="Cancel"
                  isSubmitting={isUpdatingSupplier}
                  onCancel={resetForm}
                />
              ) : null}
            </Form>
          </Card>
        )}
      </Formik>
    );
  }

  if (error) return <SupplierErrorBanner error={error} />;

  return <Loader loading />;
};

export const SupplierEditForm = compose<SupplierEditFormProps, Record<string, never>>(
  withRouter,
  withStatusToasts
)(SupplierEditFormBase);
