import {useState} from 'react';
import {RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Select} from '@shipwell/shipwell-ui';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';

export const SelectUpsRatesAccount = ({
  rfq,
  onAccountChange
}: {
  rfq?: RFQ;
  onAccountChange?: (selectedAccount: string, provider: string) => Promise<void>;
}) => {
  const [account, setAccount] = useState(rfq?.ups_specific_options?.account || null);

  const {upsAccounts, upsQuery} = useGetUpsAccountsQuery();
  const accounts = upsAccounts?.map(({id, account_number}) => ({id: id, label: account_number})) || [];

  const handleAccountChange = async (selectedAccount?: string) => {
    if (selectedAccount) {
      setAccount(selectedAccount);
      await onAccountChange?.(selectedAccount, 'ups');
    }
  };

  return (
    <Select
      simpleValue
      value={account}
      label="Account"
      options={accounts}
      onChange={handleAccountChange}
      clearable={false}
      isLoading={upsQuery.isInitialLoading}
    />
  );
};
