import React from 'react';
import {Field} from 'formik';
import {FormikTextInput, Rule, Title} from '@shipwell/shipwell-ui';
import EditReferenceFields from '../References/Edit/EditReferenceFields';

import CustomerAccountField from 'App/formComponents/formSections/customerAccountField';
interface AirShipmentFieldsProps {
  isEdit?: boolean;
}

const AirShipmentFields: React.FC<AirShipmentFieldsProps> = ({isEdit}) => {
  return (
    <>
      <Title variant="sectionTitle">Customers</Title>
      <div className="field-grid">
        <CustomerAccountField name="customer" label="Customer Account" required disabled={isEdit} />
        <Field
          label="Customer Reference #"
          name="customer_reference_number"
          component={FormikTextInput}
          type="text"
          placeholder="Customer Reference #"
          normalize={(value: string) => value || null}
        />
      </div>
      <Rule />
      <Title variant="sectionTitle">Reference Numbers</Title>
      {/* check the Correct value in the enum once it exists */}
      <EditReferenceFields mode={{code: 'AIR', id: 9, description: 'Air'}} />
    </>
  );
};

export default AirShipmentFields;
