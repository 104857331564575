import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';
import Paper from '@material-ui/core/Paper';
import * as actions from 'App/actions/carriers';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import {loadGoogleMapsAPI, formatDateTime} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {options} from 'App/utils/map_options';
import './_track-shipment.scss';
const breadcrumbMarkerArray = [];

@connect(
  (state) => ({
    isLoading: state.carriers.isLoading,
    powerUnitLocations: state.carriers.powerUnitLocations,
    company: state.auth.company,
    powerUnits: state.carriers.powerUnits,
    shipment: state.shipmentdetails.one,
    breadcrumbs: state.shipmentdetails.breadcrumbs
  }),
  {...actions, ...shipmentdetailsActions}
)
export default class TrackShipment extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.getLocations = this.getLocations.bind(this);
    this.triggerRefresh = this.triggerRefresh.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.putMarkersOnMap = this.putMarkersOnMap.bind(this);
    this.initialize = this.initialize.bind(this);
    this.clickMarker = this.clickMarker.bind(this);
    this.goToShipment = this.goToShipment.bind(this);
    this.getFilterDistance = this.getFilterDistance.bind(this);
    this.resetBreadcrumbs = this.resetBreadcrumbs.bind(this);
    this.putBreadcrumbsOnMap = this.putBreadcrumbsOnMap.bind(this);
    this.state = {
      searchValue: '',
      address: '',
      markerArray: [],
      displayMarkerArray: [],
      refreshMap: false,
      intervalId: null,
      showSideMenu: true,
      totalDistance: 0,
      totalTime: 0,
      noData: false,
      puLocation: undefined
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.props.shipmentsShipmentIdGet(this.props.params.shipment_id).then((response) => {
        if (response.status === 200) {
          this.getPULocation();
          this.getLocations();
          this.props.getBreadcrumbs(this.props.params.shipment_id, {});
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company && !_.isEmpty(this.props.company)) {
      this.props.shipmentsShipmentIdGet(this.props.params.shipment_id).then((response) => {
        if (response.status === 200) {
          this.getPULocation();
          this.getLocations();
          this.props.getBreadcrumbs(this.props.params.shipment_id, {
            filterDistance: this.getFilterDistance()
          });
        }
      });
    }
    if (this.props.breadcrumbs !== prevProps.breadcrumbs) {
      if (this.props.breadcrumbs.length > 0) {
        this.putBreadcrumbsOnMap(this.props.breadcrumbs);
      } else {
        this.resetBreadcrumbs();
      }
    }
  }

  resetBreadcrumbs() {
    if (this.map) {
      for (let i = 0; i < breadcrumbMarkerArray.length; i++) {
        breadcrumbMarkerArray[i].setMap(null);
      }
      breadcrumbMarkerArray.length = 0;
    }
  }

  putBreadcrumbsOnMap(breadcrumbs) {
    const bounds = new google.maps.LatLngBounds();

    const showMarkerDetails = (infowindowContent, crumbMarker) => {
      if (window.infowindow.getPosition() !== crumbMarker.getPosition()) {
        window.infowindow.setContent(infowindowContent);
        window.infowindow.setPosition(crumbMarker.getPosition());
        window.infowindow.open(this.map);
      }
    };

    this.resetBreadcrumbs();
    // render the breadcrumbs if there are any
    if (breadcrumbs && breadcrumbs.length) {
      for (let i = 0; i < breadcrumbs.length; i++) {
        const breadcrumb = breadcrumbs[i];
        let infowindowContent;
        // make little circles for each breadcrumb LatLng
        const crumbPoint = new google.maps.LatLng(breadcrumb.lat, breadcrumb.lon);
        const crumbMarker = new google.maps.Marker({
          position: crumbPoint,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#d43b3b',
            fillOpacity: 0.8,
            strokeColor: '#d43b3b',
            strokeOpacity: 0.4,
            scale: 5
          },
          map: this.map
        });
        bounds.extend(crumbPoint);
        breadcrumbMarkerArray.push(crumbMarker);

        infowindowContent = `<div className="infowindow">
          <h5 className="infowindow-time">${moment(breadcrumb.update_time).format('MMM Do h:mm a')}</h5>
          ${typeof breadcrumb.event_description === 'string' ? breadcrumb.event_description.split('_').join(' ') : ''}
        </div>`;

        crumbMarker.addListener('mouseover', (e) => {
          showMarkerDetails(infowindowContent, crumbMarker);
        });

        crumbMarker.addListener('click', (e) => {
          showMarkerDetails(infowindowContent, crumbMarker);
        });
      }
    }
  }

  getFilterDistance() {
    // determine filterDistance based on zoomLevel
    // return fewer points at lower zoom levels
    // initial/min zoom = 3, max zoom = 15
    let zoom;
    if (this.map) {
      zoom = this.map.getZoom();
    }
    let filterDistance = 1; // km

    switch (zoom) {
      case 1:
      case 2:
      case 3:
        return 120;
      case 4:
        return (filterDistance = 60);
      case 5:
        return (filterDistance = 50);
      case 6:
        return (filterDistance = 40);
      case 7:
        return (filterDistance = 30);
      case 8:
        return (filterDistance = 20);
      case 9:
        return (filterDistance = 10);
      case 10:
        return (filterDistance = 8);
      case 11:
        return (filterDistance = 5);
      case 12:
        return (filterDistance = 3);
      case 13:
        return (filterDistance = 1);
      case 14:
        return (filterDistance = 0.8);
      case 15:
        return (filterDistance = 0.5);
      default:
        return filterDistance;
    }
  }

  getPULocation() {
    if (
      this.props.shipment &&
      this.props.shipment.equipment_config &&
      this.props.shipment.equipment_config.power_unit
    ) {
      this.props
        .getPowerUnit(this.props.company.carrier.id, this.props.shipment.equipment_config.power_unit.id)
        .then((getPowerUnitResponse) => {
          if (getPowerUnitResponse.status === 200) {
            this.props
              .getPowerUnitLocation(this.props.company.carrier.id, this.props.shipment.equipment_config.power_unit.id)
              .then((puLocationResponse) => {
                if (puLocationResponse.status === 200) {
                  if (puLocationResponse.details) {
                    this.setState({
                      puLocation: puLocationResponse.details.location_name
                    });
                    let marker = {};
                    marker = puLocationResponse.details;
                    marker.i = -1;
                    marker.shipment = this.props.shipment.reference_id;
                    marker.driver =
                      this.props.shipment.equipment_config &&
                      this.props.shipment.equipment_config.driver &&
                      this.props.shipment.equipment_config.driver.user
                        ? this.props.shipment.equipment_config.driver.user.first_name +
                          ' ' +
                          this.props.shipment.equipment_config.driver.user.last_name
                        : '';
                    marker.driver_phone =
                      this.props.shipment.equipment_config &&
                      this.props.shipment.equipment_config.driver &&
                      this.props.shipment.equipment_config.driver.phone_number
                        ? getPhoneHyperlink(this.props.shipment.equipment_config.driver.phone_number)
                        : '';
                    marker.power_unit =
                      this.props.shipment.equipment_config && this.props.shipment.equipment_config.power_unit
                        ? this.props.shipment.equipment_config.power_unit.name
                        : '';
                    marker.trailer =
                      this.props.shipment.equipment_config && this.props.shipment.equipment_config.trailer
                        ? this.props.shipment.equipment_config.trailer.name
                        : '';

                    const markerArray = JSON.parse(JSON.stringify(this.state.markerArray));
                    markerArray.push(marker);
                    this.setState(
                      {markerArray: markerArray},
                      function () {
                        this.props.clearPowerUnit();
                        this.putMarkersOnMap(this.state.markerArray);
                      }.bind(this)
                    );
                  }
                }
              });
          }
        });
    }
  }

  goToShipment(shipmentId) {
    this.context.router.push('/shipments/' + shipmentId);
  }

  getLocations() {
    if (this.props.shipment) {
      const sortedStops = this.props.shipment.stops.sort((a, b) => a.ordinal_index - b.ordinal_index);
      const markerArray = [];
      for (let i = 0; i < sortedStops.length; i++) {
        const addressLineOne = [];
        const addressLineTwo = [];
        if (sortedStops[i].location && sortedStops[i].location.address) {
          if (sortedStops[i].location.address.address_1) {
            addressLineOne.push(sortedStops[i].location.address.address_1);
          }
          if (sortedStops[i].location.address.address_2) {
            addressLineOne.push(sortedStops[i].location.address.address_2);
          }
          if (sortedStops[i].location.address.city) {
            addressLineTwo.push(sortedStops[i].location.address.city);
          }
          if (sortedStops[i].location.address.state_province) {
            addressLineTwo.push(
              sortedStops[i].location.address.state_province + ' ' + sortedStops[i].location.address.postal_code
            );
          }
          if (sortedStops[i].location.address.country) {
            addressLineTwo.push(sortedStops[i].location.address.country);
          }
        }
        const marker = {};
        marker.lat = sortedStops[i].location.address.latitude;
        marker.lon = sortedStops[i].location.address.longitude;
        marker.i = i;
        marker.addressLineOne = addressLineOne;
        marker.addressLineTwo = addressLineTwo;
        marker.formatted_address = sortedStops[i].location.company_name;
        marker.is_pickup = sortedStops[i].is_pickup;
        marker.confirmed_departure_at = sortedStops[i].confirmed_departure_at;
        marker.display_eta_window = sortedStops[i].display_eta_window;
        markerArray.push(marker);
      }

      this.setState(
        {markerArray: markerArray},
        function () {
          if (typeof google !== 'undefined') {
            this.initialize(this.triggerRefresh);
          } else {
            loadGoogleMapsAPI(() => {
              this.initialize(this.triggerRefresh);
            });
          }
        }.bind(this)
      );
    }
  }

  initialize(callback) {
    if (this.refs.mapTrackShipment) {
      this.map = new google.maps.Map(this.refs.mapTrackShipment, options);
    }
    window.geocoder = new google.maps.Geocoder();
    window.infowindow = new google.maps.InfoWindow(options.infoWindow);
    window.directionsService = new google.maps.DirectionsService();
    window.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true
    });
    google.maps.event.addListener(this.map, 'click', function (event) {
      window.infowindow.close();
    });
    window.directionsDisplay.setMap(this.map);
    this.map.addListener(
      'zoom_changed',
      function () {
        this.props.getBreadcrumbs(this.props.params.shipment_id, {
          filterDistance: this.getFilterDistance()
        });
      }.bind(this)
    );
    callback();
  }

  triggerRefresh() {
    setTimeout(
      function () {
        google.maps.event.trigger(this.map, 'resize');
        if (this.state.markerArray && this.state.markerArray.length > 0) {
          this.resetMap();
          this.calculateAndDisplayRoute(window.directionsService, window.directionsDisplay, this.state.markerArray);
          this.putMarkersOnMap(this.state.markerArray);
        } else {
          this.resetMap();
        }
        if (this.props.breadcrumbs && this.props.breadcrumbs.length > 0) {
          if (this.props.breadcrumbs.length > 0) {
            this.resetBreadcrumbs();
            this.putBreadcrumbsOnMap(this.props.breadcrumbs);
          } else {
            this.resetBreadcrumbs();
          }
        }
        this.setState({refreshMap: false});
      }.bind(this),
      1500
    );
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay, markers) {
    const markerArray = [];
    for (let j = 0; j < markers.length; j++) {
      if (markers[j].i !== -1) {
        markerArray.push(markers[j]);
      }
    }
    if (markerArray.length > 0) {
      const waypointsArray = [];
      for (let i = 0; i < markerArray.length; i++) {
        if (i !== 0 && i !== markerArray.length - 1) {
          waypointsArray.push({
            location: new google.maps.LatLng(markerArray[i].lat, markerArray[i].lon),
            stopover: true
          });
        }
      }

      directionsService.route(
        {
          origin: new google.maps.LatLng(markerArray[0].lat, markerArray[0].lon),
          destination: new google.maps.LatLng(
            markerArray[markerArray.length - 1].lat,
            markerArray[markerArray.length - 1].lon
          ),
          waypoints: waypointsArray,
          travelMode: 'DRIVING'
        },
        function (response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
            const route = response.routes[0];
            let totalDistance = 0;
            let totalTime = 0;
            for (let k = 0; k < route.legs.length; k++) {
              totalDistance += route.legs[k].distance.value;
              totalTime += route.legs[k].duration.value;
            }
            this.setState({totalDistance: totalDistance, totalTime: totalTime});
          } else {
            this.setState({noData: true});
          }
        }.bind(this)
      );
    }
  }

  putMarkersOnMap(markerArray) {
    for (let i = 0; i < this.state.displayMarkerArray.length; i++) {
      this.state.displayMarkerArray[i].setMap(null);
    }
    //put the markers on the map
    const mapBounds = new google.maps.LatLngBounds();
    const markers = this.state.displayMarkerArray;

    for (let i = 0; i < markerArray.length; i++) {
      const icon =
        markerArray[i].i !== -1
          ? {url: '/images/map/marker-' + (markerArray[i].i + 1) + '.png'}
          : {
              url: '/images/img/truck_map_icons/truck-2.png'
            };

      const marker = new google.maps.Marker({
        position: {lat: markerArray[i].lat, lng: markerArray[i].lon},
        map: this.map,
        icon: icon,
        i: markerArray[i].i
      });
      marker.addListener(
        'click',
        function () {
          this.clickMarker(marker.i);
        }.bind(this)
      );
      if (markerArray[i].labelContent) {
        marker.set('labelContent', markerArray[i].labelContent);
      }
      markers.push(marker);

      mapBounds.extend(marker.position);
    }
    this.setState({displayMarkerArray: markers});
    if (this.map) {
      this.map.fitBounds(mapBounds);
    }
  }

  clickMarker(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    const markerArray = this.state.markerArray;
    let myIndex;
    for (let i = 0; i < markerArray.length; i++) {
      if (markerArray[i].i === index) {
        myIndex = i;
      }
    }
    let marker;
    for (let i = 0; i < displayMarkerArray.length; i++) {
      if (displayMarkerArray[i].i === index) {
        marker = displayMarkerArray[i];
      }
    }
    if (marker) {
      const myMap = window.infowindow.getMap();
      if (myMap) {
        window.infowindow.close(this.map, marker);
      }
      if (markerArray[myIndex].i === -1) {
        let string =
          '<div>' +
          markerArray[myIndex].power_unit +
          '</div><div>' +
          markerArray[myIndex].driver +
          '</div><div>' +
          markerArray[myIndex].driver_phone +
          '</div><div>' +
          markerArray[myIndex].trailer +
          '</div><div>' +
          (markerArray[myIndex].event_description
            ? markerArray[myIndex].event_description.split('_').join(' ')
            : 'No events') +
          '</div><div>' +
          (markerArray[myIndex].speed ? (markerArray[myIndex].speed * 0.621371).toFixed(2) : (0).toFixed(2)) +
          ' mph</div>';
        if (markerArray[myIndex].location_name) {
          string += '<div>' + markerArray[myIndex].location_name;
          window.infowindow.setContent(string);
          setTimeout(
            function () {
              window.infowindow.open(this.map, marker);
            }.bind(this)
          );
        } else {
          window.geocoder.geocode(
            {
              location: {
                lat: markerArray[myIndex].lat,
                lng: markerArray[myIndex].lon
              }
            },
            function (results, status) {
              if (status === 'OK') {
                if (results[0]) {
                  string += '<div>' + results[0].formatted_address + '</div>';
                  //show infowindow on hover on marker

                  window.infowindow.setContent(string);
                  setTimeout(
                    function () {
                      window.infowindow.open(this.map, marker);
                    }.bind(this)
                  );
                }
              }
            }.bind(this)
          );
        }
      } else {
        const string =
          '<div><strong>' +
          (markerArray[myIndex].company_name ? markerArray[myIndex].company_name : '') +
          '</strong></div><div>' +
          (markerArray[myIndex].addressLineOne.length > 0
            ? '<div>' + markerArray[myIndex].addressLineOne.join(' ') + '</div>'
            : '') +
          (markerArray[myIndex].addressLineTwo.length > 0
            ? '<div>' + markerArray[myIndex].addressLineTwo.join(', ') + '</div>'
            : '') +
          '</div><div>' +
          (markerArray[myIndex].is_pickup ? 'Pickup' : 'Drop off') +
          '</div><div>' +
          (markerArray[myIndex].confirmed_departure_at
            ? 'Completed on ' + formatDateTime(markerArray[myIndex].confirmed_departure_at, true, moment.tz.guess())
            : 'ETA ' + markerArray[myIndex].display_eta_window) +
          '</div>';
        window.infowindow.setContent(string);
        setTimeout(
          function () {
            window.infowindow.open(this.map, marker);
          }.bind(this)
        );
      }
    }
  }

  resetMap() {
    if (this.map) {
      this.map.setCenter(options.center);
      this.map.setZoom(options.zoom);
      for (var i = 0; i < this.state.displayMarkerArray.length; i++) {
        this.state.displayMarkerArray[i].setMap(null);
      }
      this.setState({displayMarkerArray: []});
    }
  }

  convertSectoDay(sec) {
    let t = sec;
    const day = Math.floor(t / (24 * 3600));
    t = t % (24 * 3600);
    const hour = Math.floor(t / 3600);
    t = t % 3600;
    const minutes = Math.floor(t / 60);
    t = t % 60;
    const seconds = t;
    return day + 'd ' + hour + 'h ' + minutes + 'm ' + seconds + 's';
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="track-shipment">
            <Paper className="track-shipment__side-menu">
              <h4>Info</h4>
              {this.state.noData && <div className="track-shipment__side-menu-item">No route information.</div>}
              {!this.state.noData && (
                <div className="track-shipment__side-menu-item">
                  <div>
                    <i className="icon icon-Road pad-right" />
                  </div>{' '}
                  <div>{(this.state.totalDistance / 1609.34).toFixed(0)} miles</div>
                </div>
              )}
              {!this.state.noData && (
                <div className="track-shipment__side-menu-item">
                  <div>
                    <i className="icon icon-Time pad-right" />
                  </div>
                  <div>{this.convertSectoDay(this.state.totalTime)}</div>
                </div>
              )}
              {this.props.shipment &&
                this.props.shipment.stops &&
                this.props.shipment.stops.map((el, idx) => {
                  return (
                    <div key={el.id} className="track-shipment__side-menu-item">
                      <div>
                        <i className={'pad-right text-primary icon icon-marker-' + (el.ordinal_index + 1)} />
                      </div>
                      <div>{el.location && el.location.address && el.location.address.formatted_address}</div>
                    </div>
                  );
                })}
              {this.props.shipment &&
                this.props.shipment.equipment_config &&
                this.props.shipment.equipment_config.power_unit && (
                  <div className="track-shipment__side-menu-item">
                    <div>
                      <img src="/images/img/truck_map_icons/truck-2.png" alt="truck icon" />
                    </div>
                    <div>{this.state.puLocation ? this.state.puLocation : 'Unavailable'}</div>
                  </div>
                )}
            </Paper>
            <div className="track-shipment__map">
              <Paper className="track-shipment__map-card">
                <div id="mapTrackShipment" ref="mapTrackShipment" />
              </Paper>
              <div className="disclaimer">
                <i className="icon icon-Delayed pad-right" />
                Route displayed is not guaranteed to be truck or hazmat safe. Please check route for height, weight, and
                hazmat restrictions.
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
