import {SearchField} from '@shipwell/shipwell-ui';
import {ChangeEvent, useState} from 'react';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {useQueryClient} from '@tanstack/react-query';
import {debounce} from 'lodash';
import {useCarrierAppointments, useCancelAppointmentMutation, useGetLoadTypes, useFacilityQuery} from 'App/data-hooks';
import {addDays, configureDate, today} from 'App/utils/dateTimeGlobalsTyped';
import ErrorPage from 'App/common/ErrorPage';
import {CarrierAppointmentTable} from 'App/components/appointments/tables';
import {PopoverDateRangePicker} from 'App/components/Calendars';
import {AppointmentStatusSelect} from 'App/components/appointments/select';
import AppointmentCreationModal from 'App/containers/appointments/components/modals/appointmentCreationModal';
import {CarrierAppointment} from 'App/data-hooks/appointments/types';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type InternalCarrierPortalProps = WithStatusToastProps;
const CarrierPortal = ({setError, setSuccess}: InternalCarrierPortalProps) => {
  const queryClient = useQueryClient();
  const [startDate, setStartDate] = useState<Date | null>(today());
  const [endDate, setEndDate] = useState<Date | null>(addDays(today(), 7));
  const [searchText, setSearchText] = useState<string>();
  const [statuses, setStatuses] = useState<AppointmentStatusEnum[] | undefined>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<CarrierAppointment | null | undefined>(null);

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleAppointmentStatusSelectedChanged = debounce((values?: AppointmentStatusEnum[]) => {
    setStatuses(values);
  }, 700);

  const {
    carrierAppointmentQueryKey,
    isCarrierAppointmentInitialLoading,
    isCarrierAppointmentLoading,
    isCarrierAppointmentsFetching,
    isCarrierAppointmentsRefetching,
    isCarrierAppointmentError,
    carrierAppointmentError,
    appointments,
    refetchAppointments
  } = useCarrierAppointments(
    {
      startDate: startDate as Date,
      endDate: endDate as Date,
      q: searchText,
      statuses: statuses
    },
    {
      enabled: Boolean(startDate && endDate)
    }
  );
  const {cancelAppointmentAsync} = useCancelAppointmentMutation({
    onSuccess: ({id: cancelledAppointmentId}) => {
      setSuccess('Appointment Cancelled');
      setSelectedAppointment(null);
      // rather than force all of the data to refetch
      const currentCarrierAppointments = queryClient.getQueryData<CarrierAppointment[]>(carrierAppointmentQueryKey);
      const updatedData = currentCarrierAppointments?.map((value) => {
        if (value.id === cancelledAppointmentId) {
          return {
            ...value,
            status: 'CANCELLED',
            start: null,
            end: null
          };
        }
        return value;
      });
      queryClient.setQueryData(carrierAppointmentQueryKey, updatedData);
    },
    onError: ({response}) => {
      setError(
        'Error',
        <ul>
          {response?.data?.errors?.map((error, ix) => (
            <li key={ix}>{error.detail || error.title}</li>
          ))}
        </ul>
      );
    }
  });
  const {facility} = useFacilityQuery({
    facilityId: selectedAppointment?.facility?.id
  });
  const {data: loadTypes = []} = useGetLoadTypes(selectedAppointment?.facility.id);

  /**
   * Callback for handling when the date range has changed and updating the start and end dates. If the values
   * passed in are not an array, the function will return early.
   * @param {[Date|null, Date|null]} dates first element in the array is the start date, second is the end date
   * @returns {void}
   */
  const handleDateRangeChanged = (dates: [Date | null, Date | null]): void => {
    if (!Array.isArray(dates)) {
      // completely ignore single date selections
      return;
    }
    const [startDate, endDate] = dates;
    if (startDate) {
      setStartDate(configureDate(startDate, {hours: 0, minutes: 0, seconds: 0}));
    } else {
      setStartDate(null);
    }
    if (endDate) {
      setEndDate(configureDate(endDate, {hours: 0, minutes: 0, seconds: 0})); // the date range picker gives back the exclusive end date meaning the date picked plus one day.
    } else {
      setEndDate(null);
    }
  };

  const handleAppointmentModalClose = () => {
    setShowModal(false);
    setSelectedAppointment(null);
    void refetchAppointments();
  };

  const primaryErrorMessage =
    carrierAppointmentError?.response?.data?.errors[0]?.title ||
    carrierAppointmentError?.response?.statusText ||
    'Error';
  const secondaryErrorMessage =
    carrierAppointmentError?.response?.data?.errors[1]?.detail ||
    carrierAppointmentError?.response?.data?.errors[0]?.detail ||
    'An error occurred while fetching your appointments. Please try again later.';

  const isLoading = Boolean(
    isCarrierAppointmentInitialLoading ||
      isCarrierAppointmentLoading ||
      isCarrierAppointmentsRefetching ||
      isCarrierAppointmentsFetching
  );

  const handleActionClicked = (appointment?: CarrierAppointment | null) => {
    setSelectedAppointment(appointment);
    setShowModal(true);
  };

  return (
    <div title="Carrier Appointments">
      <div className="grid grid-flow-col bg-sw-background px-2 py-3">
        <div className="grid grid-flow-col items-center gap-x-4 justify-self-start">
          <h3 className="m-0">Appointments</h3>
          <SearchField
            disabled={isCarrierAppointmentInitialLoading}
            label="Search Appointments"
            value={searchText}
            placeholder="Search"
            name="appointment-search"
            onChange={handleSearchChanged}
          />
        </div>
        <div className="grid grid-flow-col items-start gap-x-4 justify-self-end">
          <PopoverDateRangePicker start={startDate} end={endDate} onChange={handleDateRangeChanged} />
          <AppointmentStatusSelect
            disabled={isCarrierAppointmentInitialLoading}
            onChange={handleAppointmentStatusSelectedChanged}
          />
        </div>
      </div>
      <div>
        {isCarrierAppointmentError ? (
          <ErrorPage primaryMessage={primaryErrorMessage} secondaryMessage={secondaryErrorMessage} />
        ) : null}
        {isCarrierAppointmentError ? null : (
          <CarrierAppointmentTable
            appointments={appointments}
            isLoading={isLoading}
            onCancelClick={({id}) => cancelAppointmentAsync(id as string)} // safely can cast here as we for sure will have an appointment ID
            onScheduleClick={handleActionClicked}
            onRescheduleClick={handleActionClicked}
          />
        )}
      </div>
      <AppointmentCreationModal
        loadTypes={loadTypes}
        facilityId={facility?.id}
        showModal={showModal}
        shipmentId={selectedAppointment?.shipmentId}
        stopId={selectedAppointment?.stopId}
        onClose={handleAppointmentModalClose}
      />
    </div>
  );
};

const CarrierPortalWithStatusToasts = WithStatusToasts(CarrierPortal);
export default CarrierPortalWithStatusToasts;
