import {compose} from 'recompose';
import {Link, WithRouterProps, withRouter} from 'react-router';
import {DeprecatedButton, Modal} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import {UberCapacityProviderAccountSchema} from '@shipwell/genesis-sdk';
import noop from 'lodash/noop';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import UberFreightRegistrationForm from 'App/formComponents/forms/uberFreightRegistration';
import Loader from 'App/common/shipwellLoader';
import useToggle from 'App/utils/hooks/useToggle';
import {
  useDisconnectUberFreightMutation,
  useGetUberFreightQuery,
  useUpdateUberFreightAccountMutation
} from 'App/containers/integrations/details/components/uberFreightDetails/hooks/mutations';
import {COMPANY_INTEGRATION_UBER} from 'App/data-hooks/queryKeys';

export interface UberFreightDetailsPropsTypes extends WithStatusToastProps, WithRouterProps {}

const UberFreightDetails = (props: UberFreightDetailsPropsTypes) => {
  const queryClient = useQueryClient();
  const [showDisconnectModal, toggleDisconnectModal] = useToggle();
  const disconnectMutation = useDisconnectUberFreightMutation({
    onSuccess: () => {
      props.router.goBack();
    },
    onError: (error) => {
      props.setError('Error!', error?.message);
    }
  });
  const {data, isLoading} = useGetUberFreightQuery({
    onError: (error) => {
      props.setError('Error!', error.message);
    }
  });

  const {mutate} = useUpdateUberFreightAccountMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(
        [COMPANY_INTEGRATION_UBER],
        data.accounts && (data.accounts[0] as UberCapacityProviderAccountSchema)
      );
      props.setSuccess('Success', 'Updated account information');
    },
    onError: (error) => {
      props.setError('Error!', error.message);
    }
  });

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <div>
      <div className="flex justify-end pb-6">
        <DeprecatedButton variant="secondary" onClick={() => toggleDisconnectModal()}>
          Disconnect
        </DeprecatedButton>
      </div>
      <div className="flex flex-col divide-solid rounded border border-sw-border bg-sw-background-component px-6 py-7">
        <div className="mb-4 flex items-center justify-between">
          <span>
            For carrier information, please visit the{' '}
            <Link to={`/carriers?page=1&q=uber`}>Uber Freight carrier page</Link>.
          </span>
        </div>
      </div>
      <div>
        <UberFreightRegistrationForm
          isEditing
          values={{account_number: data?.account_number}}
          onCancel={noop}
          onSubmit={mutate}
        />
      </div>
      <Modal
        show={showDisconnectModal}
        title="Disconnect"
        variant="warning"
        onClose={() => toggleDisconnectModal()}
        primaryBtnName="Disconnect"
        onPrimaryAction={disconnectMutation.mutate}
      >
        You will lose all connected account information if you choose to disconnect from Uber Freight. Are you sure
        about that?
      </Modal>
    </div>
  );
};

export default compose<UberFreightDetailsPropsTypes, unknown>(withRouter, WithStatusToasts)(UberFreightDetails);
