import {useState} from 'react';
import {UPSAccount} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Select} from '@shipwell/shipwell-ui';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';
import SchedulePickups from 'App/components/parcelPickups';
import ShipwellLoader from 'App/common/shipwellLoader/index';

const UPSAccountDetails = ({isEditable}: {isEditable?: boolean}) => {
  const [selectedUpsAccount, setUPSAccount] = useState<UPSAccount | null>(null);

  const {upsQuery} = useGetUpsAccountsQuery();

  const handleUPSAccountSelection = (selectedAccount: UPSAccount) => {
    setUPSAccount(selectedAccount);
  };

  if ([upsQuery.isLoading, upsQuery.isFetching].some(Boolean)) {
    return <ShipwellLoader loading />;
  }

  const upsAccounts = upsQuery?.data?.results;
  const upsAccount = selectedUpsAccount || upsAccounts?.at(0);

  return (
    <div>
      {upsAccount ? (
        <div className="ups-account-container field-grid">
          <div className="grid-item-1">
            <Select
              clearable={false}
              label="UPS Account ID"
              name="ups_account"
              getOptionLabel={({account_number}: {account_number: string}) => account_number}
              getOptionValue={({id}: {id: string}) => id}
              value={upsAccount}
              options={upsAccounts}
              onChange={handleUPSAccountSelection}
              disabled={!isEditable}
            />
          </div>
          <div className="grid-item-2 ups-account-details">
            <SchedulePickups account={upsAccount} providerCode="UPS" />
          </div>
        </div>
      ) : null}
      {upsAccounts && upsAccounts.length === 0 ? <p>No UPS Account Connected</p> : null}
    </div>
  );
};

export default UPSAccountDetails;
