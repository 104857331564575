let seqNo = 0;
let lastNow = 0;
let nowStr = '';
/**
 * Returns a guaranteed unique string.
 *
 * Use it to generate keys to tag the items in arrays to be rendered, so that
 * you've always got a key when rendering. Do not call it inline when rendering
 * or else React will replace the whole DOM every render. - Joe
 */
export function generateKey() {
  const now = Math.floor(0.001 * Date.now());
  if (now > lastNow) {
    seqNo = 1;
    lastNow = now;
    nowStr = now.toString(36);
    nowStr = nowStr.substring(nowStr.length - 5);
  }
  const seqStr = seqNo.toString(36).padStart(5, '0');
  seqNo++;
  return ['key_', nowStr, seqStr].join('');
}
