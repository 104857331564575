import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
import {Pill, Modal} from '@shipwell/shipwell-ui';
import {stopStatusMap, stopStatusPropType, generateStopStatusErrorMessage} from 'App/utils/stopStatus';
import {shipmentsShipmentIdPut} from 'App/actions/_shipmentDetails';
import './styles.scss';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const RemoveStopStatusButton = ({loading, onClick, children, variant}) => {
  const [confirmRemoveStopStatus, setConfirmRemoveStopStatus] = useState(false);

  return (
    <>
      <Pill
        variant={variant || 'active'}
        UNSAFEclassName="uppercase"
        iconName={onClick ? (loading ? 'LoadingDots' : 'Close') : ''}
        onClick={() => setConfirmRemoveStopStatus(true)}
      >
        {children}
      </Pill>
      <Modal
        show={confirmRemoveStopStatus}
        variant="warning"
        title={<span className="shipment__stop-status-pill__confirm-title">Remove Stop Status</span>}
        onClose={() => setConfirmRemoveStopStatus(false)}
        primaryBtnName="Remove"
        PrimaryButtonProps={{loading, disabled: loading}}
        onPrimaryAction={onClick}
      >
        <div className="shipment__stop-status-pill__confirm-text">
          Are you sure you want to remove this stop status? You will need to add a stop event to apply a status to this
          stop later.
        </div>
      </Modal>
    </>
  );
};

RemoveStopStatusButton.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
};

RemoveStopStatusButton.defaultProps = {
  loading: false
};

const StopStatus = ({stop, shipment, dispatch, onError, user, allowRemove}) => {
  const [isRemovingStatus, setIsRemovingStatus] = useState(false);

  const handleRemoveStopStatus = async () => {
    setIsRemovingStatus(true);
    const payload = {
      ...shipment,
      stops: shipment.stops.map((shipmentStop) => ({
        ...shipmentStop,
        status: shipmentStop.id === stop.id ? null : shipmentStop.status
      }))
    };
    try {
      const response = await dispatch(shipmentsShipmentIdPut(shipment.id, payload));
      if (response.status >= 400) {
        throw response;
      }
    } catch (e) {
      console.error('Error updating stop status', e);
      // let the parent component know about errors so it can properly display them
      onError({title: 'Status Cannot Be Updated', error: generateStopStatusErrorMessage(e)});
    } finally {
      setIsRemovingStatus(false);
    }
  };

  const canEdit =
    get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);

  // get shipment status objects, which have status name plus other props for display
  const statusId = (get(stop, 'status') || '').toLowerCase();
  const stopStatusProps = get(stopStatusMap, statusId, {id: statusId});

  return (
    <div className="shipment__stop-status">
      <div className="shipment__stop-status-display">
        {statusId ? (
          <RemoveStopStatusButton
            loading={isRemovingStatus}
            variant={stopStatusProps.variant}
            onClick={() => {
              if (canEdit && allowRemove) {
                return handleRemoveStopStatus();
              }
            }}
          >
            {stopStatusProps.label || lowerCase(stopStatusProps.id)}
          </RemoveStopStatusButton>
        ) : null}
      </div>
    </div>
  );
};

StopStatus.propTypes = {
  stop: stopStatusPropType.isRequired,
  shipment: PropTypes.shape({
    stops: PropTypes.arrayOf(stopStatusPropType)
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  onError: PropTypes.func,
  user: PropTypes.shape({
    permissions: PropTypes.array
  }),
  allowRemove: PropTypes.bool
};

StopStatus.defaultProps = {
  onError: () => {},
  allowRemove: true
};

export default connect((state) => ({
  user: state.userProfile.user,
  shipment: state.shipmentdetails.one
}))(StopStatus);
