import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Field} from 'formik';
import {Card, Title, FormikToggleSwitch, FormikDateTimePicker, FormikSelect, Rule} from '@shipwell/shipwell-ui';
import {workflowToggles, workflowStatus, workflowStart, workflowEnd} from 'App/containers/workflows/workflowConstants';
import './styles.scss';

const WorkflowSettings = ({policyType, automatedExecutionDisabled}) => {
  return (
    <Card title="Settings" className="workflowSettings">
      <div className="field-grid">
        <div className="grid-item-1-12 workflowSettings__status">
          {get(workflowStatus, `${policyType}.fields`, []).map((field) => (
            <Field
              required
              key={field.name}
              name={field.name}
              label={field.label}
              simpleValue
              clearable={false}
              component={FormikSelect}
              options={[
                {value: 'ACTIVE', label: 'Active'},
                {value: 'INACTIVE', label: 'Inactive'}
              ]}
            />
          ))}
        </div>
      </div>
      <Rule />
      <div className="workflowSettings__toggle">
        <Title variant="formTitle" className="workflowSettings__formTitle">
          Triggers
        </Title>

        <Title variant="formSubtitle" className="workflowSettings__formSubtitle">
          These triggers determine when your workflow will run.
        </Title>

        {get(workflowToggles, `${policyType}.fields`, []).map((field) => (
          <Field
            key={field.name}
            name={field.name}
            label={field.label}
            component={FormikToggleSwitch}
            disabled={automatedExecutionDisabled}
            checkedLabel="ON"
            uncheckedLabel="OFF"
          />
        ))}
      </div>
      <Rule />
      <div className="field-grid">
        <Title variant="formTitle" className="workflowSettings__dateTitle">
          Workflow Dates
        </Title>
        <div className="grid-item-1-1">
          {get(workflowStart, `${policyType}.fields`, []).map((field) => (
            <Field
              required
              key={field.name}
              name={field.name}
              label={field.label}
              clearable={false}
              showTimeSelect={false}
              popperPlacement="top-start"
              component={FormikDateTimePicker}
            />
          ))}
        </div>
        <div className="grid-item-2-1">
          {get(workflowEnd, `${policyType}.fields`, []).map((field) => (
            <Field
              key={field.name}
              name={field.name}
              label={field.label}
              showTimeSelect={false}
              popperPlacement="top-start"
              component={FormikDateTimePicker}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};
WorkflowSettings.propTypes = {
  policyType: PropTypes.string,
  automatedExecutionDisabled: PropTypes.bool
};

export default WorkflowSettings;
