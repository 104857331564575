import {ReactNode, Ref, forwardRef} from 'react';
import classNames from 'classnames';

interface TableContainerProps {
  children?: ReactNode;
  overflowAuto?: boolean;
}

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  ({children, overflowAuto = true}, ref: Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      className={classNames('flex size-full grow flex-col justify-between', {
        'overflow-auto': overflowAuto
      })}
    >
      {children}
    </div>
  )
);

TableContainer.displayName = 'TableContainer';
