import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {string, object, ref} from 'yup';
import queryString from 'query-string';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import SetPasswordFields from 'App/formComponents/formSections/SetPasswordFields';
import './styles.scss';

const uppercaseChars = /(?=.*[A-Z])/;
const specialChars = /(?=.*\W)/;
const uppercaseCharSchema = string().matches(uppercaseChars);
const specialCharSchema = string().matches(specialChars);
const lengthSchema = string().min(8);

/** Password reset form validation */
export const validationSchema = object().shape({
  password: string().required('Please enter a valid password.'),
  confirm_password: string()
    .matches(uppercaseChars, 'At least one uppercase character required.')
    .matches(specialChars, 'At least one special character required.')
    .min(8, 'Minimum 8 characters required.')
    .oneOf([ref('password'), null], 'Passwords must match.')
    .required('Please confirm your password.')
});

/** Default values needed for Formik */
export const defaultFormValues = {
  password: '',
  confirm_password: ''
};

/**
 * Password Reset Form
 */
const NewAuthResetForm = (props) => {
  const {values, onSubmit, submitErrorMessage} = props;

  /** Submit password values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };
  const params = queryString.parse(window.location.search);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, values, isSubmitting}) => {
        const isValidLength = lengthSchema.isValidSync(values.password);
        const isValidCase = uppercaseCharSchema.isValidSync(values.password);
        const isValidSpecialChar = specialCharSchema.isValidSync(values.password);

        return (
          <form noValidate="novalidate" onSubmit={handleSubmit}>
            <div>
              <div className="new-auth-pw-reset-text">
                <strong>Set your password below</strong>
              </div>
              {params.email && <div className="new-auth-pw-reset-email">Email: {params.email}</div>}
              <SetPasswordFields
                handleSubmit={handleSubmit}
                passwordFieldName="password"
                isValidLength={isValidLength}
                isValidCase={isValidCase}
                isValidSpecialChar={isValidSpecialChar}
              />
              {submitErrorMessage && (
                <div className="new-auth-pw-reset-error">
                  <strong>{submitErrorMessage.error}</strong>
                </div>
              )}
              <DeprecatedButton
                disabled={isSubmitting}
                className="new-auth-pw-reset-save-btn"
                style={{backgroundColor: '#006BA2', border: '#006ba2'}}
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? (
                  <span>
                    <i className="icon icon-Restart rotate" /> Saving
                  </span>
                ) : (
                  'Save'
                )}
              </DeprecatedButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

NewAuthResetForm.propTypes = {
  onSubmit: PropTypes.func
};

export default NewAuthResetForm;
