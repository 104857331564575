import {FieldProps} from 'formik';
import {ColorsSelect} from './colorsSelect';

export function ColorsSelectFormik(props: FieldProps<string>) {
  const error = props.form.errors[props.field.name]?.toString();

  return (
    <div>
      <ColorsSelect value={props.field.value} onChange={props.field.onChange(props.field.name)} />
      {!!error && <div className={'text-xs leading-normal text-sw-error'}>{error}</div>}
    </div>
  );
}
