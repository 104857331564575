import {
  Configuration,
  RegisterUPS,
  RegistrationApi,
  UPSAccount,
  FedexAccount,
  DefaultApi
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosResponse} from 'axios';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRegistrationApi() {
  return new RegistrationApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

const defaultApi = () => new DefaultApi(new Configuration({basePath, apiKey: getAccessToken}));

type PaginatedResponse<K> = {
  results: Array<K>;
  page_size: number;
  total_count: number;
  total_pages: number;
};

export type RegistrationApiRegistrationUPSGetResponse = PaginatedResponse<UPSAccount>;
export type RegistrationApiRegistrationFedExGetResponse = PaginatedResponse<FedexAccount & {is_freight?: boolean}>;

// UPS
export const getUPSRegistrationAccounts = async (accountNumber?: string) =>
  (await createRegistrationApi().registrationUPSGet({
    accountNumber
  })) as AxiosResponse<RegistrationApiRegistrationUPSGetResponse>;

export const registrationUPSUpsAccountIdDelete = async (upsAccountId: string) =>
  await createRegistrationApi().registrationUPSUpsAccountIdDelete({upsAccountId});

export const registrationUPSPost = async (registerUPS: RegisterUPS) =>
  await createRegistrationApi().registrationUPSPost({registerUPS});

export const reverifyUPSPost = async (upsAccountId: string) =>
  (await createRegistrationApi().registrationUPSUpsAccountIdReverifyPost({upsAccountId})).data;

export const getUPSOauthStatus = async (upsAccountId: string) =>
  (await defaultApi().registrationUPSUpsAccountIdRefreshOauthStatusPost({upsAccountId})).data;

// FedEx
export const getFedexAccounts = async (accountNumber?: string) => {
  const axiosResponse = await createRegistrationApi().registrationFEDEXGet({accountNumber});
  return axiosResponse.data as RegistrationApiRegistrationFedExGetResponse;
};
