import PropTypes from 'prop-types';
import {routerShape, locationShape} from 'react-router/lib/PropTypes';

export const PROPTYPE_MODE = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  description: PropTypes.string
});

export const PROPTYPE_EQUIPMENT_TYPE = PropTypes.shape({
  id: PropTypes.number,
  machine_readable: PropTypes.string,
  name: PropTypes.string
});

export const PROPTYPE_ADDRESS = PropTypes.shape({
  country: PropTypes.string,
  formatted_address: PropTypes.string,
  state_province: PropTypes.string
});

export const PROPTYPE_COMPANY = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  brokerage: PropTypes.shape({
    id: PropTypes.string
  }),
  custom_data: PropTypes.object
});
export const PROPTYPE_ROUTER = routerShape;
export const PROPTYPE_LOCATION = locationShape;

/* node describes anything that can be rendered */
export const PROPTYPE_CHILDREN = PropTypes.node;

export const PROPTYPE_FORMIK_FIELD_ARRAY = {
  push: PropTypes.func,
  swap: PropTypes.func,
  move: PropTypes.func,
  insert: PropTypes.func,
  unshift: PropTypes.func,
  remove: PropTypes.func,
  pop: PropTypes.func,
  replace: PropTypes.func
};

export const PROPTYPE_TABLE_CELL = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node, PropTypes.element]),
  row: PropTypes.object
};

export const PROPTYPE_TAG = PropTypes.shape({
  id: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string
});

export const PROPTYPE_TAGS = PropTypes.arrayOf(PROPTYPE_TAG);

export const PROPTYPE_ACCESSORIAL = PropTypes.shape({
  can_be_dispatched: PropTypes.bool,
  code: PropTypes.string,
  currency_unit: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  is_charge_code: PropTypes.bool,
  long_description: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string
});

export const PROPTYPE_DRAGGABLE_PROVIDED = PropTypes.shape({
  draggableProps: PropTypes.shape({
    draggableId: PropTypes.string,
    index: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
  }),
  dragHandleProps: PropTypes.shape({
    'data-rbd-drag-handle-draggable-id': PropTypes.string,
    'data-rbd-drag-handle-context-id': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    tabIndex: PropTypes.number,
    draggable: PropTypes.bool,
    onDragStart: PropTypes.func
  }),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.elementType})])
});
