import {createContext, Dispatch, SetStateAction} from 'react';
import {noop} from 'lodash';

import {InitAppointmentCallback} from './constants';
import {AppointmentCallback, AppointmentEntry, SchedulingCallback} from 'App/data-hooks/appointments/types';

const AppointmentVerbContext = createContext<{
  setError: (message: string, detail: string) => unknown;
  setSuccess: (message: string, detail: string) => unknown;
  checkIn: AppointmentCallback;
  checkOut: AppointmentCallback;
  reject: AppointmentCallback;
  reschedule: SchedulingCallback;
  cancel: (appointmentId: string) => void;
  prefetchAvailability: (request: AppointmentEntry) => void;
  invalidateAvailability: () => void;
  setSelectedTab: Dispatch<SetStateAction<string | null>>;
}>({
  setError: InitAppointmentCallback,
  setSuccess: InitAppointmentCallback,
  checkIn: InitAppointmentCallback,
  checkOut: InitAppointmentCallback,
  reject: InitAppointmentCallback,
  reschedule: InitAppointmentCallback,
  cancel: noop,
  prefetchAvailability: () => undefined,
  invalidateAvailability: () => undefined,
  setSelectedTab: noop
});

const {Provider: AppointmentVerbContextProvider, Consumer: AppointmentVerbContextConsumer} = AppointmentVerbContext;

export {AppointmentVerbContext, AppointmentVerbContextProvider, AppointmentVerbContextConsumer};
