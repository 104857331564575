import {KeyboardEvent, ReactNode} from 'react';
import classNames from 'classnames';

export function TableRow({
  children,
  isSelected = false,
  onClick
}: {
  children?: ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
}) {
  const handleKeyDown = (e: KeyboardEvent<HTMLTableRowElement>) => {
    if (!onClick) {
      return;
    }
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <tr
      tabIndex={typeof onClick === 'function' ? 0 : undefined}
      onClick={onClick}
      className={classNames('text-sw-text outline-1 hover:outline hover:outline-sw-primary', {
        'bg-sw-active-light': isSelected,
        'bg-sw-background-component odd:bg-sw-background': !isSelected,
        'cursor-pointer': typeof onClick === 'function'
      })}
      onKeyDown={handleKeyDown}
    >
      {children}
    </tr>
  );
}
