import {UserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {updateUserDashboardConfig} from 'App/api/tableConfig/typed';
import {USER_DASHBOARDS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type UpdateUserDashboardPayload = {
  userDashboardConfigurationId: string;
  userDashboardConfiguration: UserDashboardConfiguration;
};

const useUpdateUserDashboardConfig = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserDashboardConfig>>,
    unknown,
    UpdateUserDashboardPayload
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof updateUserDashboardConfig>>, unknown, UpdateUserDashboardPayload>(
    ({userDashboardConfigurationId, userDashboardConfiguration}) =>
      updateUserDashboardConfig({userDashboardConfigurationId, userDashboardConfiguration}),
    {
      onSettled: () => queryClient.invalidateQueries([USER_DASHBOARDS_QUERY_KEY]),
      ...mutationOptions
    }
  );
};

export default useUpdateUserDashboardConfig;
