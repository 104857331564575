import {
  requestUserCompanyPreferencesPending,
  requestUserCompanyPreferencesSuccess,
  requestUserCompanyPreferencesError,
  requestPermissionsSuccess,
  requestIdentifyingCodesSuccess,
  updateUserCompanySuccess
} from './index';
import {company, user} from 'App/api';
import {GET_COMPANY_PREFERENCES} from 'App/actions/types';
import {getCompanyInternationalPreferences} from 'App/api/internationalPreferences';
import store from 'App/routes/store';

/**
 * Update Company
 * @param {Number} companyId
 * @param {Object} body
 */
export function updateCompanySetting(companyId, body = {}) {
  return async (dispatch) => {
    const response = await company.editCompanyProfilePromise(companyId, body);
    if (response) {
      dispatch(updateUserCompanySuccess(response.body));
      return response;
    }
  };
}

/**
 * Obtain the company's preferences
 * @param {String} companyId The id of the company
 */
export function getCompanyPreferences(companyId) {
  return async (dispatch) => {
    dispatch(requestUserCompanyPreferencesPending());
    // currency comes from getCompanyInternationalPreferences
    const [companyPreferencesResponse, internationalPreferencesResponse] = await Promise.allSettled([
      company.getCompanyPreferencesPromise(companyId),
      getCompanyInternationalPreferences(companyId)
    ]);
    if (companyPreferencesResponse.value) {
      const currency = internationalPreferencesResponse?.value?.data?.currency;
      const payload = {...companyPreferencesResponse.value.body, currency};
      //temporary
      dispatch({type: GET_COMPANY_PREFERENCES, payload});
      dispatch(requestUserCompanyPreferencesSuccess(payload));
      return {status: 200};
    } else {
      dispatch(requestUserCompanyPreferencesError(companyPreferencesResponse.reason));
      return companyPreferencesResponse.reason;
    }
  };
}

/**
 * Update the company's preferences
 * @param {String} companyId The id of the company
 */
export function editCompanyPreferences(companyId, body) {
  return async (dispatch) => {
    try {
      const response = await company.editCompanyPreferencesPromise(companyId, body);
      if (response) {
        const currency = store.getStore()?.userCompany?.currency;
        const responseWithCurrency = {...response.body, currency};
        dispatch(requestUserCompanyPreferencesSuccess(responseWithCurrency));
        return {status: 200};
      }
    } catch (error) {
      return error;
    }
  };
}

/**
 * Get permissions
 */
export function getPermissions() {
  return async (dispatch) => {
    const response = await user.getPermissionsPromise();
    if (response) {
      dispatch(requestPermissionsSuccess(response));
    }
  };
}

/**
 * Get identifying codes
 */
export function fetchIdentifyingCodes() {
  return async (dispatch) => {
    const response = await company.fetchIdentifyingCodesPromise();
    if (response) {
      dispatch(requestIdentifyingCodesSuccess(response));
    }
  };
}

/**
 *
 * Single function to update company preferences and settings
 * Required because async calls that update redux will cause the form to reinitialize early
 */
export function updateCompanyPreferencesAndSettings(companyId, body) {
  return async (dispatch) => {
    const [companyPreferencesResponse, companyProfileResponse] = await Promise.all([
      company.editCompanyPreferencesPromise(companyId, body),
      company.editCompanyProfilePromise(companyId, body)
    ]);
    if (companyPreferencesResponse && companyProfileResponse) {
      const currency = store.getState()?.userCompany?.currency;
      const responseWithCurrency = {...companyPreferencesResponse.body, currency};
      dispatch(requestUserCompanyPreferencesSuccess(responseWithCurrency));
      dispatch(updateUserCompanySuccess(companyProfileResponse.body));
      return {
        status: 200,
        companyPreferences: companyPreferencesResponse.body,
        companyProfile: companyProfileResponse.body
      };
    }
  };
}
