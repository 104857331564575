import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form} from 'redux-form';
import {Panel, Button, Image, ListGroup} from 'react-bootstrap';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {placeholder} from 'App/utils/placeholders';
import * as documentActions from 'App/actions/documents';
import renderDropZone from 'App/formComponents/renderDropzone';
import validate from 'App/containers/Shipment/validateShipmentDocForm';
import DocumentsArray from 'App/components/DocumentsArray/DocumentsArray';
import renderTextArea from 'App/formComponents/renderTextArea';

@connect(
  (state) => ({
    customerDocForm: state.form.customerDocForm,
    documents: state.documents.documents
  }),
  {...documentActions}
)
class CustomerDocForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleUploadDoc = this.handleUploadDoc.bind(this);
    this.printDocument = this.printDocument.bind(this);

    this.activeClass = 'active';

    this.state = {
      showAddForm: false,
      showUpdateForm: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cancel && this.props.cancel !== nextProps.cancel) {
      this.handleCancel();
    }
  }

  showAddForm() {
    this.setState({
      showAddForm: true,
      showUpdateForm: false
    });
  }

  showUpdateForm(e) {
    this.setState({
      showAddForm: false,
      showUpdateForm: true
    });
    this.setSelectedDoc(e);
  }

  handleUploadDoc(e) {
    this.showAddForm();
    this.props.selectDocAdd();
    this.setSelectedDoc(e);
  }

  handleCancel() {
    this.props.reset();
    this.clearSelectedDoc();
    this.setState({
      showAddForm: false,
      showUpdateForm: false
    });
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) {}
    return el;
  }

  clearSelectedDoc() {
    const docs = document.querySelectorAll('.doc-container .active');
    docs.forEach((doc) => doc.classList.remove(this.activeClass));
  }

  setSelectedDoc(e) {
    const scrollTo = document.querySelector('.js-scrollto-form');
    const selected = this.findAncestor(e.target, 'movie-layout');

    if (selected) {
      this.clearSelectedDoc();
      selected.classList.add(this.activeClass);
    }

    if (scrollTo) {
      _.defer(() => {
        scrollTo.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  printDocument(doc) {
    const mywindow = window.open('', 'PRINT', 'height=400px,width=600px');
    if (!mywindow) {
      return false;
    }
    mywindow.document.write('<html height="100%"><head><title>' + doc.filename + '</title>');
    mywindow.document.write('</head><body height="100%">');
    mywindow.document.write('<img style={{"maxHeight":"100%"}} width="100%" src="' + doc.file + '"/>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return;
  }

  render() {
    const {documents, handleSubmit, handleDelete, pristine, error, isLoading, submitting} = this.props;

    const formValues = this.props.customerDocForm.values;
    const isExisting = formValues && formValues.id ? true : false;
    const textSaving = isExisting ? 'Saving' : 'Uploading';
    const textSave = isExisting ? 'Save' : 'Upload';

    const footerButtons = (
      <div className="document-edit__footer">
        {isExisting && (
          <DeprecatedButton variant="icon" bsStyle="default" disabled={submitting} onClick={handleDelete}>
            <SvgIcon name="TrashOutlined" className="pad-right" />
            Delete
          </DeprecatedButton>
        )}

        {formValues && formValues.filename && formValues.filename.indexOf('.pdf') === -1 && (
          <Button
            onClick={() => {
              this.printDocument(formValues);
            }}
            bsStyle="default"
          >
            <i className="icon icon-File pad-right" />
            Print
          </Button>
        )}

        <Button
          bsStyle="default"
          type="button"
          disabled={submitting}
          onClick={this.handleCancel}
          className="cancel-edit"
          name="cancel-edit"
        >
          Cancel
        </Button>

        <Button className="btn btn-primary" disabled={submitting || pristine} type="submit">
          {isLoading ? (
            <span>
              <i className="icon icon-Restart rotate" />
              &nbsp;&nbsp; {textSaving}
            </span>
          ) : (
            textSave
          )}
        </Button>
      </div>
    );

    const renderShipperDocImages = () => {
      if (!documents) {
        return;
      }
      const sortedArray = documents.results.sort((a, b) => {
        const keyA = new Date(a.updated_at);
        const keyB = new Date(b.updated_at);

        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
        return 0;
      });
      return (
        <DocumentsArray
          showTooltip
          tooltipContent="details"
          highlightBOL={false}
          handleDocumentClick={(id, e) => {
            const doc = documents.results.filter((doc) => doc.id === id)[0];
            this.showUpdateForm(e);
            this.props.selectDocEdit(doc);
          }}
          documents={sortedArray}
        />
      );
    };

    const uploadButton = (
      <Button
        disabled={this.state.showAddForm}
        bsStyle="link"
        className="btn-tertiary"
        onClick={(e) => this.handleUploadDoc(e)}
      >
        <i className="flaticon-upload pad-right" />
        Upload a Document
      </Button>
    );

    return (
      <div className="document-edit clearfix">
        <h3 className="border-bottom">Documents</h3>

        {documents && documents.total_count === 0 && (
          <p className="document-edit__message">No customer documents to display. &nbsp; {uploadButton}</p>
        )}

        {documents && documents.total_count > 0 && (
          <div className="doc-container">
            <p className="document-edit__message">{uploadButton} or select a document to edit or delete</p>

            <ListGroup>{renderShipperDocImages()}</ListGroup>
          </div>
        )}

        <Form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className={`document-edit__form ${
            this.state.showAddForm || this.state.showUpdateForm ? 'show-flex' : 'hidden'
          }`}
        >
          <div className="document-edit__details">
            {isExisting && (
              <div>
                <strong>
                  <i className="icon icon-Time pad-right" />
                  Created
                </strong>
                <p className="text-muted">{_.toString(formValues.created_at)}</p>
                <strong>
                  <i className="icon icon-Time pad-right" />
                  Updated
                </strong>
                <p className="text-muted">{_.toString(formValues.updated_at)}</p>
              </div>
            )}

            <Field
              name="description"
              type="text"
              minRows={3}
              component={renderTextArea}
              placeholder={placeholder.docDescription}
              req
              label="Description"
            />

            {footerButtons}

            {error && <p className="error-text text-center">{error}</p>}
          </div>
          {this.state.showAddForm && (
            <Panel className="document-edit__dropzone">
              <Field name="files" component={renderDropZone} id="dropzone-doc" />
            </Panel>
          )}
          {this.state.showUpdateForm && (
            <div className="document-image">
              {formValues && formValues.file && formValues.file.indexOf('.pdf') === -1 && (
                <Image width="100%" height="auto" src={formValues.file} alt={formValues.filename} />
              )}
              {formValues && formValues.file && formValues.file.indexOf('.pdf') !== -1 && (
                <Panel className="movie-detail">
                  <object
                    aria-label={`${formValues.filename}`}
                    style={{margin: '0px auto'}}
                    type="application/pdf"
                    data={formValues.file}
                    width="100%"
                    height="400px"
                  />
                </Panel>
              )}
              <p>
                If the image above doesn't load, you can access it{' '}
                <a href={formValues && formValues.file} rel="noopener noreferrer" target="_blank">
                  here
                </a>
              </p>
            </div>
          )}
        </Form>
        <span className="js-scrollto-form" />
      </div>
    );
  }
}

CustomerDocForm = reduxForm({
  form: 'customerDocForm',
  touchOnChange: true, // react-dropzone doesn't blur
  enableReinitialize: true,
  validate: validate
})(CustomerDocForm);

CustomerDocForm = connect((state) => ({
  initialValues: state.documents.selectedDocument
}))(CustomerDocForm);

export default CustomerDocForm;
