import PropTypes from 'prop-types';
import {Fragment, useState} from 'react';
import {Tooltip, Pill, SvgIcon, Modal, Button} from '@shipwell/shipwell-ui';
import moment from 'moment';
import classNames from 'classnames';
import clone from 'lodash/clone';
import {PROPTYPE_CHILDREN} from 'App/utils/propTypeConstants';
import {getPlannedWindowEndDelay} from 'App/utils/stopEvent';
import withFlags from 'App/utils/withFlags';
import getItineraryAddressString from 'App/utils/getItineraryAddressString';
import {formatDayOfWeekDateTime} from 'App/utils/globals';
import TimelineEventForm from 'App/formComponents/forms/timelineEvent';
import WithStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
const DELAY_HOURS_ALERT_THRESHOLD = 4;
const DELAY_HOURS_LAST_TRACKING_UPDATE_THRESHOLD = 2;
const DELAY_MINUTES_ALERT_THRESHOLD = 240;
const durationAbbreviations = {
  hours: 'hr',
  minutes: 'min'
};

const getEtaVariant = (delayAmount, stopConfirmedDeparture, duration = 'hours') => {
  if (stopConfirmedDeparture) {
    return 'default';
  }
  if (!delayAmount) {
    return 'default';
  }
  if (
    (duration === 'hours' && delayAmount >= DELAY_HOURS_ALERT_THRESHOLD) ||
    (duration === 'minutes' && delayAmount >= DELAY_MINUTES_ALERT_THRESHOLD)
  ) {
    return 'alert';
  }
  return 'warning';
};

const EtaPillContent = ({pillVariant, delay, duration}) => {
  return (
    <div className="font-bold">
      {pillVariant.includes('alert')
        ? `+${delay}${durationAbbreviations[duration]} Delay`
        : pillVariant.includes('warning')
        ? 'At Risk'
        : ['success', 'default'].includes(pillVariant)
        ? 'On Time'
        : null}
    </div>
  );
};

EtaPillContent.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.string,
  pillVariant: PropTypes.string
};

const StopEtaPillBase = ({stop = {}, size = 'sm', duration = 'hours'}) => {
  const getTripManagementEtaPlannedWindowDelay = (stop) => {
    if (stop.planned_time_window_start && !stop.planned_time_window_end) {
      /**
       * Stop window has been marked for 'after' the pickup start time. For planned stop delay,
       * use 11:59 as time window end, as we would assume the shipment is late if it's not delivered that day.
       * Clone the stop to prevent form value mutation
       */
      const stopWithWindowEnd = clone({...stop, planned_time_window_end: '23:59:59'});
      return getPlannedWindowEndDelay(stop.trip_management_eta, stopWithWindowEnd, duration);
    }
    return getPlannedWindowEndDelay(stop.trip_management_eta, stop, duration);
  };
  const tripManagementEtaPlannedWindowDelay = getTripManagementEtaPlannedWindowDelay(stop);
  const pillVariant = getEtaVariant(tripManagementEtaPlannedWindowDelay, stop?.confirmed_departure_at, duration);
  return stop?.trip_management_eta ? (
    <Pill variant={pillVariant} size={size}>
      <EtaPillContent pillVariant={pillVariant} delay={tripManagementEtaPlannedWindowDelay} duration={duration} />
    </Pill>
  ) : null;
};

StopEtaPillBase.propTypes = {
  stop: PropTypes.shape({
    trip_management_eta: PropTypes.string,
    confirmed_departure_at: PropTypes.string
  }),
  duration: PropTypes.oneOf(['hours', 'minutes']),
  size: PropTypes.oneOf(['xs', 'sm', 'default']),
  tripManagementPredictedEta: PropTypes.bool
};
/**
 * @component StopEtaPill - returns a pill with stop eta
 * @param {object} stop
 * @param {string} duration hours, minutes
 * @param {string} size extra small, small, large
 * @param {string} tripManagmentPredictedEta feature flag value
 */
export const StopEtaPill = withFlags('tripManagementPredictedEta')(StopEtaPillBase);

const StopEtaLastUpdatedBase = ({
  stop,
  warningIconHeight,
  warningIconWidth,
  isExpandedLastUpdateDisplay,
  shipment,
  setSuccess,
  fetchDetails
}) => {
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  const handleEventSuccess = () => {
    setSuccess('Success!', 'Location event was added to the timeline.');
    //set a three second timeout before getting updated shipment
    //to allow trip management to calculate new eta and send that back to backend core
    setTimeout(() => {
      fetchDetails(shipment.id);
    }, 3000);
    setShowNewEventModal(false);
  };
  const isLastTrackingPastDelayThreshold =
    moment(stop?.trip_management_eta_last_updated).isValid() &&
    moment().diff(moment(stop.trip_management_eta_last_updated), 'hours') >= DELAY_HOURS_LAST_TRACKING_UPDATE_THRESHOLD;
  return moment(stop.trip_management_eta_last_updated).isValid() ? (
    <div className="flex items-center">
      {isLastTrackingPastDelayThreshold && !stop?.confirmed_departure_at ? (
        <SvgIcon name="WarningFilled" className="mx-1" width={warningIconWidth} height={warningIconHeight} />
      ) : null}
      <div className="text-xs italic">
        {stop?.confirmed_departure_at
          ? 'Completed'
          : `${isExpandedLastUpdateDisplay ? 'updated ' : ''}${moment(
              stop.trip_management_eta_last_updated
            ).fromNow()}`}
      </div>
      {isExpandedLastUpdateDisplay && isLastTrackingPastDelayThreshold ? (
        <div className="ml-2">
          <Button variant="tertiary" size="sm" isCompact onClick={() => setShowNewEventModal(true)}>
            Add update
          </Button>
        </div>
      ) : null}
      <Modal
        show={showNewEventModal}
        title="Location Event on Timeline"
        footerComponent={null}
        onClose={() => setShowNewEventModal(false)}
      >
        <TimelineEventForm
          onSuccess={handleEventSuccess}
          handleCancel={() => setShowNewEventModal(false)}
          shipment={shipment}
          initialValues={{event_type: 'LOCATION_UPDATED'}}
        />
      </Modal>
    </div>
  ) : null;
};

StopEtaLastUpdatedBase.propTypes = {
  isExpandedLastUpdateDisplay: PropTypes.bool,
  stop: PropTypes.shape({
    tripManagementEtaLastUpdated: PropTypes.string,
    confirmed_departure_at: PropTypes.string
  }),
  warningIconHeight: PropTypes.string,
  warningIconWidth: PropTypes.string,
  shipment: PropTypes.object,
  fetchDetails: PropTypes.func,
  ...WithStatusToastsPropTypes
};
StopEtaLastUpdatedBase.defaultProps = {
  tripManagementEtaLastUpdated: '',
  warningIconHeight: '24',
  warningIconWidth: '24',
  isExpandedLastUpdateDisplay: true,
  shipment: {},
  setSuccess: () => {},
  fetchDetails: () => {}
};
/**
 * @component StopEtaLastUpdated
 * @param {object} stop
 * @param {string} warningIconHeight
 * @param {string} warningIconWidth
 * @param {string} isExpandedLastUpdateDisplay
 * @param {object} shipment
 * @param {func} setSuccess
 * @param {func} fetchDetails
 */
export const StopEtaLastUpdated = WithStatusToasts(StopEtaLastUpdatedBase);

/**
 * @component StopEtaConsequentStops returns all stops
 * with trip management eta after current stop
 * @param {array} stops
 */
export const StopEtaConsequentStops = ({stops = []}) => {
  const truncatedConsequentStops = stops.slice(2);
  return (
    <>
      {stops.map((stop, index) => {
        const tripManagementEtaPlannedWindowDelay = getPlannedWindowEndDelay(stop?.trip_management_eta, stop, 'hours');
        const stopEtaVariant = getEtaVariant(
          tripManagementEtaPlannedWindowDelay,
          stop?.confirmed_departure_at,
          'hours'
        );
        //show dots for the first stops
        return index < 2 ? (
          <Fragment key={stop.id}>
            <div
              title={stopEtaVariant}
              className={classNames('size-2 rounded-2xl ml-1', {
                'bg-sw-success': stopEtaVariant === 'success',
                'bg-sw-error': stopEtaVariant === 'alert',
                'bg-sw-warning': stopEtaVariant === 'warning'
              })}
            ></div>
          </Fragment>
        ) : null;
      })}
      {truncatedConsequentStops.length > 0 ? (
        //represent additional stops numerically
        <div className="ml-1 text-xs text-sw-label">+{truncatedConsequentStops.length}</div>
      ) : null}
    </>
  );
};

StopEtaConsequentStops.propTypes = {
  stops: PropTypes.array
};

export const StopsItineraryOverlayBase = ({stops, isLoadBoard, tripManagementPredictedEta, hasLTL, children}) => {
  return (
    <Tooltip
      portal
      tooltipClassname="p-2"
      tooltipContent={
        <div className="p-2">
          <h2 className="m-0 pb-2">ETAs</h2>
          <table>
            <thead>
              <tr className="p-2">
                <th>Stop</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {stops.length > 0
                ? stops.map((stop) => {
                    const timezone = stop?.location?.address?.timezone ?? moment.tz.guess();
                    const formattedTripManagementETA = moment(stop.trip_management_eta).isValid()
                      ? formatDayOfWeekDateTime(stop.trip_management_eta, true, timezone)
                      : null;
                    return (
                      <tr key={stop.id}>
                        <td className="stops max-w-md p-2">
                          {stop.is_pickup && <span className="mr-1">&#8593;</span>}
                          {stop.is_dropoff && <span className="mr-1">&#8595;</span>}
                          {getItineraryAddressString(stop, isLoadBoard)}
                        </td>
                        <td className="w-80 p-2">
                          {tripManagementPredictedEta && formattedTripManagementETA && !hasLTL ? (
                            <div className="flex">
                              <div>{formattedTripManagementETA}</div>
                              <div className="flex flex-col items-center">
                                <div className="w-36 px-4">
                                  <StopEtaPill stop={stop} duration="hours" />
                                </div>
                                <StopEtaLastUpdated
                                  isExpandedLastUpdateDisplay={false}
                                  stop={stop}
                                  warningIconWidth="16"
                                  warningIconHeight="16"
                                />
                              </div>
                            </div>
                          ) : (
                            stop.display_planned_window
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

StopsItineraryOverlayBase.propTypes = {
  children: PROPTYPE_CHILDREN,
  isLoadBoard: PropTypes.bool,
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      location: PropTypes.shape({
        address: PropTypes.shape({
          timezone: PropTypes.string
        })
      }),
      display_planned_window: PropTypes.string,
      map: PropTypes.func
    })
  ),
  hasLTL: PropTypes.bool,
  tripManagementPredictedEta: PropTypes.bool
};
StopsItineraryOverlayBase.defaultProps = {
  isLoadBoard: false,
  stops: [],
  hasLTL: false,
  tripManagementPredictedEta: false
};

/**
 * @component StopsItineraryOverlay
 * @param {array} stops
 * @param {boolean} isLoadBoard
 * @param {boolean} tripManagementPredictedEta This is passed in automatically by useFlags
 * @param {boolean} hasLTL
 * @param {object} children
 */
export const StopsItineraryOverlay = withFlags('tripManagementPredictedEta')(StopsItineraryOverlayBase);

export const StopsItineraryCell = ({countPick, countDrop, ...props}) => {
  return (
    //pass the event handler props down to the dom element
    <div {...props} className="stops">
      <div className="pickups">
        <span className="mr-1 text-sw-success">&#8593;</span>
        <span>{countPick}</span>
      </div>
      <div className="dropoffs">
        <span className="mr-1 text-sw-warning">&#8595;</span>
        <span>{countDrop}</span>
      </div>
    </div>
  );
};

StopsItineraryCell.propTypes = {
  countDrop: PropTypes.number,
  countPick: PropTypes.number
};
