import {Address, Stop, Contract, ContractLane, ShipmentMode} from '@shipwell/backend-core-singlerequestparam-sdk';

export const getStopOriginDestinationAndAdditionalAddresses = (
  stops: Pick<Stop, 'location'>[]
): {
  origin: Address | undefined;
  destination: Address | undefined;
  additionalStops: Address[] | undefined;
} => {
  if (!stops.length) {
    return {
      origin: undefined,
      destination: undefined,
      additionalStops: undefined
    };
  }
  const [origin, ...nonOriginStops] = stops;
  const destination = nonOriginStops.pop();
  const additionalStops = nonOriginStops.map((stop) => stop.location.address);
  return {
    origin: origin.location.address,
    destination: destination?.location.address,
    additionalStops
  };
};

//returns values for getContracts originContains or destinationContains
export const formatAddressContains = (address?: Address) =>
  address
    ? [address.formatted_address, address.postal_code, address.postal_code?.slice(0, 3), address.city].filter(
        (e): e is string => !!e
      )
    : [];

//returns values based on contract or contract lane
export const getContractParamsFromContractOrLane = ({contract, lane}: {contract?: Contract; lane?: ContractLane}) => ({
  origins: lane ? [lane.origin] : contract?.origins || [],
  destinations: lane ? [lane.destination] : contract?.destinations,
  rateType: lane ? lane.rate_info?.rate_type : contract?.rate_type,
  rate: lane ? lane.rate_info?.rate : contract?.rate,
  modes: lane ? (lane.modes as unknown as ShipmentMode[]) : contract?.modes,
  equipmentTypes: lane ? lane.equipment_types : contract?.equipment_types
});
