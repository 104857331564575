import {useQuery, UseQueryOptions, useQueryClient} from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import {
  ShipmentLocationResponse,
  Shipment,
  ShipwellError,
  LoadBoardShipment
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useLocusAirShipmentsCurrentLocationByShipment} from './useLocusAirShipmentsCurrentLocationByShipment';
import {getShipmentRoute, getShipmentMarkersForMap} from 'App/components/trimbleMap/utils';
import {getBreadcrumbs, getBreadcrumbsUnauth} from 'App/api/shipment/typed';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import {TRACKING_BREADCRUMBS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getTrackingLineCoordinates} from 'App/components/trimbleMap/utils/typed';
import {useGetShipmentTimelineEvents, useUserMe} from 'App/data-hooks';
import {useCustomersTyped} from 'App/data-hooks/brokers';

const DEFAULT_FILTER_DISTANCE = '5';

type QueryOptions = UseQueryOptions<ShipmentLocationResponse, ShipwellError, ShipmentLocationResponse, string[]>;

interface LocationProps {
  shipment?: Shipment | LoadBoardShipment;
  filterDistance?: string;
  queryOptions?: QueryOptions;
}

interface AuthLocationProps extends LocationProps {
  isUnauth?: false;
  key?: string;
}

interface UnauthLocationProps extends LocationProps {
  isUnauth: true;
  key: string;
}

export const useShipmentLocations = ({
  shipment,
  filterDistance = DEFAULT_FILTER_DISTANCE,
  queryOptions,
  isUnauth,
  key
}: AuthLocationProps | UnauthLocationProps) => {
  if (isUnauth && isNil(key)) {
    throw Error('Cannot use Unauthorized endpoint without shipment key');
  }
  const isIntermodal = shipment && 'mode' in shipment && shipment?.mode?.code === ShipmentModeEnum.INTERMODAL;
  const {id: shipmentId} = shipment || {};

  const queryClient = useQueryClient();
  const locusLocationData = useLocusAirShipmentsCurrentLocationByShipment({shipmentId: shipmentId || ''});

  const shipmentLocationsQuery = useQuery(
    [TRACKING_BREADCRUMBS_QUERY_KEY, shipmentId || '', filterDistance || ''],
    async () => {
      if (!shipmentId) {
        return Promise.reject('Invalid Shipment ID');
      }
      const fetchBreadcrumbsPromise = isUnauth
        ? getBreadcrumbsUnauth({
            shipmentId,
            key,
            beginDatetime: undefined,
            endDatetime: undefined,
            filterDistance
          })
        : getBreadcrumbs({shipmentId, filterDistance});
      const response = await fetchBreadcrumbsPromise;
      return response?.data;
    },
    {
      enabled: Boolean(shipmentId),
      ...queryOptions
    }
  );
  const formattedLocations =
    shipmentLocationsQuery?.data?.shipment_locations?.map((location) => ({
      ...location,
      tracking_source: shipmentLocationsQuery?.data?.tracking_source
    })) || [];

  const {timelineEvents} = useGetShipmentTimelineEvents({
    requestBody: {shipmentId: shipment?.id || ''},
    options: {enabled: !!shipment?.id}
  });

  const shipmentLocations =
    (shipment &&
      getShipmentMarkersForMap({
        shipment,
        trackingPoints: formattedLocations,
        timelineEvents: timelineEvents?.results,
        locusApiLocation: locusLocationData?.location || null
      })) ||
    [];

  const userQuery = useUserMe();
  const {getCustomerOptionFromId} = useCustomersTyped(userQuery.data?.company, {
    customerId: shipment?.customer?.id || undefined
  });
  const customer = getCustomerOptionFromId(shipment?.customer?.id || '');
  const maxDrivingElevationFeet = customer?.maximum_driving_elevation_ft || undefined;

  // ensure there are stops on the shipment _before_ we attempt to get route
  const route = shipment?.stops
    ? getShipmentRoute(shipment, formattedLocations?.length === 0, !isIntermodal, maxDrivingElevationFeet)
    : {};
  const trackingLinePoints = formattedLocations.length ? getTrackingLineCoordinates(formattedLocations) : [];

  const invalidateShipmentLocationsQuery = async () =>
    await queryClient.invalidateQueries([TRACKING_BREADCRUMBS_QUERY_KEY, filterDistance || '']);

  return {
    queryInfo: shipmentLocationsQuery,
    route,
    shipmentLocations,
    invalidateShipmentLocationsQuery,
    formattedLocations,
    trackingLinePoints
  };
};
