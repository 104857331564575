import {IconButton} from '@shipwell/shipwell-ui';
import {useCallback} from 'react';
import DatePicker from 'react-datepicker';

import './LittleCalendar.scss';

import {monthAndYear} from 'App/utils/dateTimeGlobalsTyped';

export type LittleCalendarProps = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

function formatWeekDay(weekDayName: string) {
  return <span title={weekDayName}>{weekDayName.toString().substring(0, 1)}</span>;
}

/**
 * Implements the "Little Calendar" view of the Appointments page.
 */
const LittleCalendar = ({selectedDate, setSelectedDate}: LittleCalendarProps) => {
  const onChange = useCallback(
    (value: Date) => {
      setSelectedDate(value);
    },
    [setSelectedDate]
  );

  const onClickPreviousMonth = useCallback(() => {
    const date = new Date(selectedDate);
    const monthWas = date.getMonth();
    date.setMonth(date.getMonth() - 1);
    while (date.getMonth() === monthWas) {
      date.setDate(date.getDate() - 1);
    }
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  const onClickNextMonth = useCallback(() => {
    const date = new Date(selectedDate);
    date.setMonth(date.getMonth() + 1);
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  return (
    <div className="flex-0 sw-little-calendar bg-white sticky top-0 flex shrink flex-col pt-2">
      <div className="flex-0 flex flex-row items-center">
        <span className="text-base">{monthAndYear(selectedDate)}</span>
        <div className="flex flex-1 flex-row items-center justify-end">
          <IconButton
            name="ArrowSmallLeft"
            onClick={onClickPreviousMonth}
            iconName="ArrowSmallLeft"
            aria-label="Go To Previous Month"
          />
          <IconButton
            name="ArrowSmallRight"
            onClick={onClickNextMonth}
            iconName="ArrowSmallRight"
            aria-label="Go To Next Month"
          />
        </div>
      </div>
      <div className="flex-0 flex h-44 flex-col">
        <DatePicker
          inline
          disabledKeyboardNavigation
          calendarStartDay={0}
          selected={selectedDate}
          onChange={onChange}
          formatWeekDay={formatWeekDay}
        />
      </div>
    </div>
  );
};

export default LittleCalendar;
