import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {object, string} from 'yup';
import {DeprecatedButton, Title} from '@shipwell/shipwell-ui';
import TableViewFields from 'App/formComponents/formSections/tableViewFields';
import './styles.scss';
export const validationSchema = object().shape({
  name: string().required('Dashboard name is required')
});

/**
 * Filter Update/Creation Form
 */
const TableViewForm = (props) => {
  const {edit, values, onSubmit, onCancel, renameExistingView} = props;
  const {name, is_default = null, ...valuesSansName} = values;
  /** Default values needed for Formik */
  const defaultFormValues = {
    name: '',
    is_default
  };

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit customer values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...valuesSansName}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, setSubmitting, isSubmitting, dirty, ...props}) => (
        <form noValidate="novalidate" className="tableViewForm">
          <Title className="table-view-title">{renameExistingView ? 'Rename' : 'New'} Dashboard</Title>
          <TableViewFields {...props} />
          {(!edit || dirty) && (
            <div className="tableViewForm__buttonContainer">
              {onCancel && (
                <DeprecatedButton variant="secondary" onClick={handleCancel}>
                  Cancel
                </DeprecatedButton>
              )}
              {onSubmit && (
                <DeprecatedButton
                  disabled={isSubmitting || !dirty}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                  onClick={handleSubmit}
                >
                  {renameExistingView ? 'Rename' : 'Create'}
                </DeprecatedButton>
              )}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

TableViewForm.defaultProps = {
  edit: false,
  onCancel: () => {},
  onSubmit: () => {},
  values: {}
};

TableViewForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object
};

export default TableViewForm;
