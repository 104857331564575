import {JSX, useCallback, useEffect, useState} from 'react';
import {Button, DatePicker, IconButton, Toast} from '@shipwell/shipwell-ui';

import {AppointmentStatusEnum, FacilityDockAppointmentRule, FacilityHoursOfOperation} from '@shipwell/tempus-sdk';
import moment from 'moment';
import {getFacilityHoursOfOperation, getFacilityDockRules} from 'App/api/facilities';
import {rescheduleAppointment, scheduleAppointment} from 'App/api/appointments';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import './styles.scss';

export type RescheduleFCFSProps = {
  appointment: AppointmentEntry | null;
  showAsButton?: boolean;
  refetch: () => Promise<void>;
};

const RescheduleFCFS = ({appointment, showAsButton, refetch}: RescheduleFCFSProps): JSX.Element | null => {
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const [hoursOfOperation, setHoursOfOperation] = useState<FacilityHoursOfOperation[]>();
  const [dockRules, setDockRules] = useState<FacilityDockAppointmentRule[]>([]);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

  const isAppointmentUnscheduled = appointment?.status === AppointmentStatusEnum.Unscheduled;

  const fetchFacilityHours = useCallback(async () => {
    if (!appointment) return;
    const hours = await getFacilityHoursOfOperation(appointment.facilityId);
    if (appointment.dockId) {
      const dockRules = await getFacilityDockRules(appointment.facilityId, appointment.dockId);
      setDockRules(dockRules.filter((dock) => dock.appointment_type === appointment.appointmentType));
    }
    setHoursOfOperation(hours);
  }, [appointment]);

  useEffect(() => {
    void fetchFacilityHours();
  }, [fetchFacilityHours]);

  const findDockTimingBoundaries = () => {
    const dockEarliestStartTime = dockRules.reduce((earliest: string | undefined, dock) => {
      return !earliest || (dock.first_appointment_start_time && dock.first_appointment_start_time < earliest)
        ? dock.first_appointment_start_time
        : earliest;
    }, '');

    const dockLatestEndTime = dockRules.reduce((latest: string | undefined, dock) => {
      return !latest || (dock.last_appointment_end_time && dock.last_appointment_end_time > latest)
        ? dock.last_appointment_end_time
        : latest;
    }, '');

    return {dockEarliestStartTime, dockLatestEndTime};
  };

  const handleDateChange = (date: string | null) => {
    if (!date || !hoursOfOperation) return;

    const facilityTimezone = appointment?.start.timezone || '';

    const today = moment.tz(facilityTimezone);
    const selectedDate = moment.tz(date, facilityTimezone);
    const dayName = selectedDate.format('dddd');

    const isFacilityOpen = hoursOfOperation
      .filter((hourOfOperation) => hourOfOperation.day.toUpperCase() === dayName.toUpperCase())
      .some((hourOfOperation) => hourOfOperation.is_facility_open);

    if (selectedDate < today || !isFacilityOpen) {
      setIsInvalidDate(true);
    } else {
      setIsInvalidDate(false);
    }

    const facilityDay = hoursOfOperation.filter((item) => item.day === dayName.toUpperCase())[0];
    const {dockEarliestStartTime, dockLatestEndTime} = findDockTimingBoundaries();

    const openTime = dockEarliestStartTime || facilityDay?.open_time || '00:00:00';
    const closeTime = dockLatestEndTime || facilityDay?.close_time || '00:00:00';
    const formattedStartDate = moment.tz(`${date}T${openTime}`, appointment?.start.timezone || '').toDate();
    const formattedEndDate = moment.tz(`${date}T${closeTime}`, appointment?.end.timezone || '').toDate();

    setSelectedStartDate(formattedStartDate);
    setSelectedEndDate(formattedEndDate);
  };

  const closeDatePicker = (): void => {
    const event = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    document.body.dispatchEvent(event);
  };

  const handleReschedule = async () => {
    if (isInvalidDate || !appointment || !selectedStartDate || !selectedEndDate) return;

    const action = isAppointmentUnscheduled ? scheduleAppointment : rescheduleAppointment;

    await action(appointment.id, appointment.start.timezone, selectedStartDate, selectedEndDate);

    setShowSuccessToast(true);
    void refetch();
    closeDatePicker();
  };

  return (
    <div>
      <div
        className={`rescheduleFCFSWrapper ${
          showAsButton ? 'showAsButton' : ''
        } relative flex h-[24px] cursor-pointer items-center justify-center bg-sw-background-component text-xs hover:bg-sw-background ${
          showAsButton ? 'w-[112px] rounded border border-sw-border' : 'w-[24px] rounded-full'
        }`}
      >
        <DatePicker
          onChange={handleDateChange}
          showTimeSelect={false}
          shouldCloseOnSelect={false}
          actions={
            <div className="calendarFooter absolute right-0 top-[228px] z-[1055] flex h-[30px] w-[240.06px] items-center justify-end gap-1 rounded-b border-t border-sw-border bg-sw-background-component p-1 shadow-md">
              <Button
                variant="primary"
                size="sm"
                width="normal"
                disabled={isInvalidDate || !hoursOfOperation}
                onClick={() => {
                  void handleReschedule();
                }}
              >
                Save
              </Button>
            </div>
          }
        />
        {showAsButton ? (
          isAppointmentUnscheduled ? (
            'Schedule'
          ) : (
            'Reschedule'
          )
        ) : (
          <IconButton color="#58595b" iconName="CalendarEdit" aria-label="Reschedule appointment" />
        )}
      </div>

      <Toast show={showSuccessToast} title="Success!" anchor="top-right" onClose={() => setShowSuccessToast(false)}>
        {`The appointment was ${isAppointmentUnscheduled ? 'scheduled' : 'rescheduled'} with success.`}
      </Toast>
    </div>
  );
};

export default RescheduleFCFS;
