import {CapacitySearchRequest} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {createCapacitySearch} from 'App/api/capacitySearch';
import {CAPACITY_SEARCH_KEY} from 'App/data-hooks/queryKeys';

const useCreateCapacitySearch = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof createCapacitySearch>>, unknown, CapacitySearchRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof createCapacitySearch>>, unknown, CapacitySearchRequest>(
    (capacitySearchRequest) => createCapacitySearch(capacitySearchRequest),
    {
      onSettled: () => queryClient.invalidateQueries([CAPACITY_SEARCH_KEY]),
      ...mutationOptions
    }
  );
};

export default useCreateCapacitySearch;
