import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {PaginatedTenders1} from '@shipwell/backend-core-sdk';
import {TENDERS_KEY} from 'App/data-hooks/queryKeys';
import {getTenders, GetTendersProps} from 'App/api/shipment/typed';

const useGetTenders = (data: GetTendersProps, options?: UseQueryOptions<PaginatedTenders1>) => {
  const response = useQuery(
    [TENDERS_KEY] as QueryKey,
    async () => {
      const response = await getTenders(data);
      return response.data;
    },
    options
  );

  return response;
};

export default useGetTenders;
