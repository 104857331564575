import {parseErrors} from 'App/utils/globals';

/**
 * Callback for Swagger requests
 * @param {*} resolve
 * @param {*} reject
 */
export const apiCallback = (resolve, reject) => (error, data, response) => {
  if (error || response.status >= 400) {
    reject(parseErrors(response));
  } else {
    resolve(response);
  }
};

/**
 * Gets user access token to send in the Authorization Header
 * to request and update remote resources.
 */
export async function getAccessToken(context = 'unspecified') {
  const adminToken = localStorage.getItem('swimpersonator');
  if (adminToken) {
    return adminToken;
  }
  try {
    return resolveAccessTokenOverride ? await resolveAccessTokenOverride() : await resolveAccessToken();
  } catch (e) {
    console.error(`Error getting access token for context ${context}`, e);
  }
}

let resolveAccessToken = () => {};

/**
 * Sets the access token function
 * @param {*} func
 */
export const setAccessTokenFunction = (func) => {
  resolveAccessToken = func;
};

export let resolveAccessTokenOverride;

/**
 * Sets the access token function
 * @param {*} func
 */
export const setAccessTokenFunctionOverride = (func) => {
  resolveAccessTokenOverride = func;
};

/**
 * Parse errors that are returned from a route that adheres to the v3
 * error object specification: https://shipwell.atlassian.net/wiki/spaces/DEV/pages/397672506/V3+API+Guidelines
 * @param {Object} errorResponse
 * @return {Object} parsed error to be returned to the parseError caller
 */
export function parseV3ApiResponseErrors(errorResponse) {
  if (errorResponse?.data?.errors?.length > 0) {
    return errorResponse.data;
  }
  return errorResponse;
}
