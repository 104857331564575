import {useEnhancedFormik} from 'App/hooks/useEnhacedFormik/useEnhacedFormik';
import {UseDevicesFormValues} from './useDevicesFormValues';
import {validationSchema} from './validationSchema';

export interface UseDevicesFormProps {
  onSubmit: (values: UseDevicesFormValues) => void;
}

export function useDevicesForm(props: UseDevicesFormProps) {
  const form = useEnhancedFormik<UseDevicesFormValues>({
    initialValues: {
      provider: 'TIVE',
      deviceId: ''
    },
    validationSchema,
    onSubmit: (values) => {
      props.onSubmit(values);
      form.resetForm();
    }
  });

  return form;
}
