import React from 'react';

type ActionButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

const TemplateActionButton: React.FC<ActionButtonProps> = ({onClick, children}) => {
  return (
    <div className="text-[#000]" onClick={onClick}>
      {children}
    </div>
  );
};

export default TemplateActionButton;
