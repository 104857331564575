import {array, object, date, string} from 'yup';
import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import {AddressSchema} from 'App/utils/yupHelpersTyped';

export const validationSchema = object().shape({
  orders: array().of(
    object().shape({
      ship_from: object().shape({
        shipping_requirements: object().shape({
          plan_window: object().shape({
            start: date().nullable().required('Select a Pickup Date'),
            end: date()
              .nullable()
              .required('Select a Delivery Date')
              .test(
                'is-greater-or-equal',
                'Delivery Date must be equal to or later than Pickup Date',
                function (value) {
                  const {start} = this.parent as {start: Date | null};
                  return !start || !value || value >= start;
                }
              )
          })
        })
      }),
      ship_to: object().shape({
        shipping_requirements: object().shape({
          plan_window: object().shape({
            start: date().nullable().required('Select a Pickup Date'),
            end: date()
              .nullable()
              .required('Select a Delivery Date')
              .test(
                'is-greater-or-equal',
                'Delivery Date must be equal to or later than Pickup Date',
                function (value) {
                  const {start} = this.parent as {start: Date | null};
                  return !start || !value || value >= start;
                }
              )
          })
        })
      })
    })
  ),
  shared_address: AddressSchema,
  start_time: string()
    .nullable()
    .when('shared_address', {
      is: (sharedAddress: AddressBookEntry['address']) => !!sharedAddress?.country,
      then: string().nullable().required('A valid start time is required.'),
      otherwise: string().nullable()
    }),
  end_time: string().nullable()
});
