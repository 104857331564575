import PropTypes from 'prop-types';
import {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {compose} from 'recompose';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import {DeprecatedButton, SvgIcon, SearchField, Modal} from '@shipwell/shipwell-ui';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import PageHeader from 'App/common/pageHeader';
import ShipwellTable from 'App/components/swTable';
import {fetchAccessorialChargeTablesPromise} from 'App/api/shipment';
import {toTitleCase, formatDate} from 'App/utils/globals';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import buildPathParams from 'App/utils/buildPathParams';
import withConditionalFallback from 'App/components/withConditionalFallback';
import CompanyAccessorialRatesTablesCreate from 'App/containers/userCompany/accessorialRates/create';
import {useCarrierDetails} from 'App/data-hooks';
import formatCountryAddress from 'App/utils/formatCountryAddress';
import Error404Page from 'App/common/Error404Page2';
import CreateImport from 'App/components/imports/create';
import 'App/containers/userCompany/accessorialRates/styles.scss';

const pathUpdateDebounced = debounce((router, path) => router.push(path), 300);

const CompanyAccessorialRatesTablesList = ({location, router, setSuccess, setError}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [accessorialRatesTables, setAccessorialRatesTables] = useState([]);
  const [searchTerm, setSearchTerm] = useState(location.query.q);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [useFullScreen, setUseFullScreen] = useState(false);
  const [meta, setMeta] = useState({
    page: 1,
    pages: 1,
    pageSize: 20,
    ordering: 'name'
  });

  const carrierDetails = useCarrierDetails(
    uniq(
      accessorialRatesTables.reduce(
        (ids, accessorialRatesTable) => [...ids, ...accessorialRatesTable.carrier_relationships],
        []
      )
    )
  );

  const fetchAccessorialRatesTables = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await fetchAccessorialChargeTablesPromise({
        page: meta.page,
        pageSize: meta.pageSize,
        ordering: meta.ordering,
        ...location.query
      });
      setAccessorialRatesTables(get(response, 'body.results', []));
      setMeta((prevMeta) => ({
        ...location.query,
        ...prevMeta,
        page: location.query.page || 1,
        pages: response.body.total_pages,
        pageSize: response.body.page_size
      }));
    } catch (error) {
      console.error('Error fetching accessorial tables', error);
      setError('Error!', `Error retrieving accessorial tables! ${get(error, 'error_description')}`);
    } finally {
      setIsFetching(false);
    }
  }, [meta.page, meta.pageSize, meta.ordering, location.query, setError]);

  useEffect(() => {
    fetchAccessorialRatesTables();
  }, [fetchAccessorialRatesTables]);

  const handleSearch = (e) => {
    const term = e.target.value;
    const updatedPath = buildPathParams(location, {q: term, page: 1});
    setSearchTerm(term);
    pathUpdateDebounced(router, updatedPath);
  };

  const onSubmitAccessorialRateSuccess = (response) => {
    const {body} = response;
    setSuccess(
      'Accessorial Table Updated! ',
      <span>
        Your accessorial rate table is now active{' '}
        <Link to={`/company/lane-management/accessorial-rates/${body.id}`}>here</Link>.
      </span>
    );
    setAccessorialRatesTables([...accessorialRatesTables, body]);
    setShowAddModal(false);
  };

  const handleClickAddAccessorialRate = () => {
    setShowAddModal(true);
  };

  return (
    <div className="companyAccessorialRates__list">
      <PageHeader
        title="Accessorial Rates"
        actions={
          <>
            <DeprecatedButton
              variant="tertiary"
              icon={<SvgIcon name="AddCircleOutlined" />}
              onClick={() => setShowImportModal(true)}
            >
              Import Table
            </DeprecatedButton>
            <DeprecatedButton
              icon={<SvgIcon name="AddCircleOutlined" />}
              variant="tertiary"
              onClick={handleClickAddAccessorialRate}
            >
              Accessorial Table
            </DeprecatedButton>
          </>
        }
      >
        <SearchField label="Search" name="search-accessorial-tables" value={searchTerm} onChange={handleSearch} />
      </PageHeader>
      <ShipwellTable
        data={accessorialRatesTables}
        router={router}
        location={location}
        loading={isFetching}
        page={Number(meta.page) - 1}
        pages={Number(meta.pages)}
        pageSize={Number(meta.pageSize)}
        columns={[
          {
            Header: (
              <>
                Name <i className="btn-sort" />
              </>
            ),
            id: 'name',
            accessor: (row) =>
              row.name ? (
                row.id ? (
                  <Link to={`/company/lane-management/accessorial-rates/${row.id}`}>{row.name}</Link>
                ) : (
                  row.name
                )
              ) : (
                '--'
              ),
            sortable: true,
            minWidth: 125
          },
          {
            Header: (
              <>
                Reference <i className="btn-sort" />
              </>
            ),
            id: 'external_reference',
            accessor: (row) => row.external_reference ?? '--',
            sortable: true,
            minWidth: 125
          },

          {
            Header: (
              <>
                Status <i className="btn-sort" />
              </>
            ),
            id: 'status',
            accessor: (row) => (row.status ? startCaseToLower(row.status) : '--'),
            sortable: true,
            minWidth: 125
          },
          {
            Header: (
              <>
                Carriers <i className="btn-sort" />
              </>
            ),
            id: 'carrier_relationships',
            accessor: 'carrier_relationships',
            minWidth: 120,
            sortable: true,
            className: 'companyAccessorialRates__list-multiline',
            Cell: ({value}) => {
              const carriers = value || [];
              return carriers.length > 0
                ? carriers.map((carrierRelationshipId) => (
                    <span key={carrierRelationshipId}>
                      {get(carrierDetails, `${carrierRelationshipId}.shipwell_vendor.name`, '--')}
                    </span>
                  ))
                : '--';
            }
          },
          {
            Header: (
              <>
                Locations <i className="btn-sort" />
              </>
            ),
            id: 'locations',
            accessor: 'locations',
            minWidth: 120,
            sortable: true,
            className: 'companyAccessorialRates__list-multiline',
            Cell: (row) => {
              const locations = get(row, 'value') || [];
              return locations.length > 0
                ? locations.map((address) => (
                    <div key={address.id}>{formatCountryAddress(address.formatted_address)}</div>
                  ))
                : '--';
            }
          },
          {
            Header: (
              <>
                Modes <i className="btn-sort" />
              </>
            ),
            id: 'modes',
            accessor: (row) => (get(row, 'modes') || []).join(', ') || '--',
            sortable: true,
            minWidth: 125
          },
          {
            Header: (
              <>
                Equipment <i className="btn-sort" />
              </>
            ),
            id: 'equipment_types',
            accessor: (row) => (get(row, 'equipment_types') || []).map(toTitleCase).join(', ') || '--',
            sortable: true,
            minWidth: 150
          },
          {
            Header: (
              <>
                Start Date <i className="btn-sort" />
              </>
            ),
            id: 'start_date',
            accessor: (row) => (row.start_date ? formatDate(row.start_date) : '--'),
            sortable: true,
            minWidth: 150
          },
          {
            Header: (
              <>
                End Date <i className="btn-sort" />
              </>
            ),
            id: 'end_date',
            accessor: (row) => (row.end_date ? formatDate(row.end_date) : '--'),
            sortable: true,
            minWidth: 150
          }
        ]}
      />
      <Modal
        show={showAddModal}
        title="Create Accessorial Rate Table"
        onClose={() => setShowAddModal(false)}
        footerComponent={null}
      >
        <div className="companyAccessorialRates-modal-body">
          <CompanyAccessorialRatesTablesCreate
            onSubmit={onSubmitAccessorialRateSuccess}
            onCancel={() => setShowAddModal(false)}
            setError={setError}
          />
        </div>
      </Modal>
      <Modal
        fullScreen={useFullScreen}
        show={showImportModal}
        title="Import Table"
        footerComponent={null}
        onClose={() => {
          setShowImportModal(false);
          setUseFullScreen(false);
        }}
      >
        <CreateImport
          setUseFullScreen={setUseFullScreen}
          onCancel={() => {
            setShowImportModal(false);
            setUseFullScreen(false);
          }}
          type="ACCESSORIAL_CHARGE_TABLES"
          onImportSuccess={setSuccess}
        />
      </Modal>
    </div>
  );
};

CompanyAccessorialRatesTablesList.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      page: PropTypes.number,
      q: PropTypes.string
    }),
    value: PropTypes.shape({
      address: PropTypes.any
    })
  }),
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  ...WithStatusToastsPropTypes
};

CompanyAccessorialRatesTablesList.defaultProps = {
  ...WithStatusToastsDefaultProps
};

export default compose(
  connect((state) => ({
    rateTablesEnabled: get(state, 'userCompany.company.feature_flags.rate_tables_enabled', false)
  })),
  withConditionalFallback(({rateTablesEnabled}) => !rateTablesEnabled, Error404Page),
  withStatusToasts
)(CompanyAccessorialRatesTablesList);
