import {useFlags} from 'launchdarkly-react-client-sdk';
import {useState} from 'react';
import {
  DEFAULT_FORECAST_RANGE,
  PREDICTIVE_INTERNAL,
  PREDICTIVE_MARKET,
  pricingIntelVariantsBySource,
  PRICING_INTEL_DATA_STATE_EMPTY,
  PRICING_INTEL_DATA_STATE_LOADING,
  PRICING_INTEL_DATA_STATE_MISSING_DATA,
  PRICING_INTEL_DATA_STATE_VALID,
  PRICING_INTEL_REQUIRED_ADDRESS_KEYS,
  PRICING_INTEL_SOURCE_STANDALONE
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {getPickupStopPlannedDate} from 'App/containers/pricingIntel/utils';
import {getStringifiedStopsWithRequiredKeys} from 'App/components/pricingIntelChart/utils';
import {getMarketForecastPricing, getMyPriceForecastPricing} from 'App/api/pricing';

const usePredictivePricing = ({equipmentType, stops, pageSource, pricingIntelDataOptions}) => {
  const pricingIntelVariant = pricingIntelVariantsBySource[pageSource];
  const {automaticPricingIntel} = useFlags() || {};
  const flaggedInitialDataState = //show pricing by default if automatic flag enabled
    automaticPricingIntel ? PRICING_INTEL_DATA_STATE_EMPTY : pricingIntelVariant.initialDataState;
  const [predictivePrices, setPredictivePrices] = useState({});
  const [predictivePricingDataState, setPredictivePricingDataState] = useState(flaggedInitialDataState);
  const stringifiedStops = getStringifiedStopsWithRequiredKeys(stops, PRICING_INTEL_REQUIRED_ADDRESS_KEYS);

  const getMarketPriceForecastResponse = async (basePredictivePricingParams) =>
    await getMarketForecastPricing({
      ...basePredictivePricingParams,
      forecastRange: DEFAULT_FORECAST_RANGE
    });

  const getInternalPriceForecastResponse = async (basePredictivePricingParams) => {
    const internalPriceForecastParams = {
      ...basePredictivePricingParams
    };
    const pickupStopPlannedDate = getPickupStopPlannedDate(stops);
    if (pickupStopPlannedDate.length > 0) {
      internalPriceForecastParams.opts = {
        pickupDate: pickupStopPlannedDate[0]
      };
    }
    return await getMyPriceForecastPricing(internalPriceForecastParams);
  };

  const getPredictivePricingEstimates = async () => {
    setPredictivePricingDataState(PRICING_INTEL_DATA_STATE_LOADING);
    setPredictivePrices({});
    try {
      const basePredictivePricingParams = {
        equipmentType: equipmentType[0].machine_readable,
        stops: stringifiedStops
      };
      const [marketPriceForecastResponse, internalPriceForecastResponse] = await Promise.allSettled([
        pageSource !== PRICING_INTEL_SOURCE_STANDALONE &&
          pricingIntelDataOptions.includes(PREDICTIVE_MARKET) &&
          getMarketPriceForecastResponse(basePredictivePricingParams),
        pricingIntelDataOptions.includes(PREDICTIVE_INTERNAL) &&
          getInternalPriceForecastResponse(basePredictivePricingParams)
      ]);
      setPredictivePrices({
        shipwellInternalShipper: internalPriceForecastResponse?.value?.body?.data || {},
        shipwellMarketCarrier: marketPriceForecastResponse?.value?.body?.data || []
      });
      setPredictivePricingDataState(PRICING_INTEL_DATA_STATE_VALID);
    } catch (error) {
      console.error('There was an error getting predictive pricing estimates.');
      setPredictivePricingDataState(PRICING_INTEL_DATA_STATE_MISSING_DATA);
      throw error;
    }
  };
  const resetPredictivePricingData = () => {
    setPredictivePrices({});
    setPredictivePricingDataState(flaggedInitialDataState);
  };
  return {
    predictivePrices,
    setPredictivePrices,
    predictivePricingDataState,
    setPredictivePricingDataState,
    getPredictivePricingEstimates,
    resetPredictivePricingData
  };
};
export default usePredictivePricing;
