import {LegsApi, CreateStageTimelineEvent, CreateTrackingPoint} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';

const legsApi = new LegsApi(configuration);

export function createLegTimelineEvent(legId: string, createStageTimelineEvent: CreateStageTimelineEvent) {
  return legsApi.createLegTimelineEventLegsLegIdTimelineEventsPost({legId, createStageTimelineEvent});
}

export function getLegTimeline(legId: string) {
  return legsApi.getLegTimelineEventsLegsLegIdTimelineEventsGet({legId});
}

export function deleteLegTimelineEvent(legId: string, eventId: string) {
  return legsApi.deleteTimelineEventLegsLegIdTimelineEventsTimelineEventIdDelete({legId, timelineEventId: eventId});
}

export function getLegTracking(legId: string) {
  return legsApi.listTrackingPointsLegsLegIdTrackingGet({legId});
}

export function createLegTrackingPoint(legId: string, createTrackingPoint: CreateTrackingPoint) {
  return legsApi.createTrackingPointLegsLegIdTrackingPost({legId, createTrackingPoint});
}
