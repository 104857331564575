import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import * as actions from '../../../actions/shipments';
import {renderLineItemString} from 'App/utils/globals';
import withFlags from 'App/utils/withFlags';

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    company: state.auth.company,
    selectedQuote: state.shipments.selectedQuote,
    formData: state.form.confirmShipmentForm
  }),
  actions
)
class ConfirmShipmentHeader extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.state = {};
  }

  render() {
    const formData = this.props.formData && this.props.formData.values;
    const selectedQuote = this.props.selectedQuote;
    const {selectedShipment, titanDevDoNotUpdateDelDateOnLtlDispatch} = this.props;
    let allAcc = [];
    if (selectedShipment && selectedShipment.accessorials.length > 0) {
      allAcc = allAcc.concat(selectedShipment.accessorials);
    }

    // TITAN-4011
    const deliveryDate = titanDevDoNotUpdateDelDateOnLtlDispatch
      ? formData?.stops?.at(-1)?.planned_date || selectedQuote?.delivery_date
      : selectedQuote?.delivery_date;

    return (
      <div>
        {formData && selectedQuote && (
          <div className="shipment-quoting-header confirm-shipment-header">
            {selectedQuote && selectedQuote.carrier && selectedQuote.carrier.display_name && (
              <div className="shipment-quoting-header__item">
                <i className="flaticon-vendors pad-right" />
                <div>
                  <h5>Carrier</h5>
                  <p>{selectedQuote.carrier.display_name}</p>
                </div>
              </div>
            )}
            <div className="shipment-quoting-header__item">
              <i className="flaticon-calendar-5 pad-right" />
              <div>
                <h5>Pickup</h5>
                {formData.stops?.[0]?.planned_date ? (
                  <p>{moment(formData.stops[0].planned_date).format('ddd MMM D')}</p>
                ) : null}
                {selectedQuote.transit_days && !isNaN(selectedQuote.transit_days)
                  ? selectedQuote.transit_days + (selectedQuote.transit_days < 2 ? ' day transit' : ' days transit')
                  : ''}
              </div>
            </div>

            <div className="shipment-quoting-header__item">
              <i className="flaticon-vendors pad-right" />
              <div>
                <h5>Delivery Details</h5>
                {deliveryDate && <p>{moment(deliveryDate).format('ddd MMM D')}</p>}
                <p>
                  {selectedQuote.mode && selectedQuote.mode.description + ', '}
                  {selectedQuote.mode && selectedQuote.mode.code === 'LTL' && 'Dry Van, '}
                  {selectedQuote.service_level && selectedQuote.service_level.description + ' '}
                  {selectedQuote.lane_type &&
                    selectedQuote.lane_type !== 'UNSPECIFIED' &&
                    _.upperFirst(selectedQuote.lane_type.toLowerCase())}
                </p>
              </div>
            </div>

            {formData.line_items && formData.line_items.length > 0 && (
              <div className="shipment-quoting-header__item-wide">
                <i className="flaticon-products pad-right" />
                <div>
                  <h5>Items</h5>
                  {formData.line_items.map((item, i) => {
                    return <p key={i}>{renderLineItemString(item, i)}</p>;
                  })}
                </div>
              </div>
            )}
            <div className="shipment-quoting-header__item">
              <i className="flaticon-notepad pad-right" />
              <div>
                <h5>Services</h5>
                {allAcc.length > 0 ? (
                  allAcc.map((item, i) => {
                    return <p key={i}>{item.description}</p>;
                  })
                ) : (
                  <p>None</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFlags('titanDevDoNotUpdateDelDateOnLtlDispatch')(ConfirmShipmentHeader);
