import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteCompanyCustomField} from 'App/api/company/typed';
import {CUSTOM_FIELDS_KEY} from 'App/data-hooks/queryKeys';

type Options = Omit<
  UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyCustomField>>, unknown, string, unknown>,
  'mutationFn'
>;

export const useDeleteCompanyCustomField = (companyId: string, options?: Options) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    (customFieldId: string) => deleteCompanyCustomField({companyId, customFieldId}),
    {
      ...options,
      onSettled(...args) {
        void queryClient.invalidateQueries([CUSTOM_FIELDS_KEY]);
        options?.onSettled?.(...args);
      }
    }
  );
  return {deleteCustomField: mutate, isDeleting: isLoading};
};
