import {Link, RouterProps} from 'react-router';
import PageHeader from 'App/common/pageHeader';

export function PlaygroundPage(props: RouterProps) {
  return (
    <>
      <PageHeader title="Playground" />

      <div className="flex gap-2 px-5 py-2">
        <Link to="/playground/formik">Formik</Link>
        <Link to="/playground/multiselect">Multiselect</Link>
      </div>

      <div className="mt-5 px-5">{props.children}</div>
    </>
  );
}
