import classNames from 'classnames';
import {Pill, SvgIcon} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum, ReferenceQualifierEnum} from '@shipwell/tempus-sdk';
import {deliveryTypeName, equipmentTypeName} from '../../utils';
import {AppointmentListBlob} from './types';
import {interleave} from 'App/utils/globalsTyped';
import {convertDuration, formatHrsMins} from 'App/utils/dateTimeGlobalsTyped';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';

export type AppointmentListEntryProps = {
  appointment: AppointmentListBlob;
  onAppointmentClick: (appointment: AppointmentEntry) => unknown;
};

export function AppointmentListEntry({appointment: blob, onAppointmentClick: onClick}: AppointmentListEntryProps) {
  const {appointment, loadType, dock, durationMs} = blob;
  const minutes = Math.round(durationMs / (60 * 1000));
  const short = !appointment.allDay && minutes < 46;

  const loadTypeDurString = loadType?.all_day_appointment
    ? 'All Day'
    : loadType?.appointment_duration
    ? formatHrsMins(convertDuration(loadType?.appointment_duration, 'milliseconds'))
    : '';
  const durStr = loadTypeDurString || (appointment.allDay ? 'All Day' : formatHrsMins(durationMs));

  const {reason, name, carrierName: carrierName, status, scheduledResourceReferenceId, references} = appointment;
  const poNumber = references?.find((ref) => ref.qualifier === ReferenceQualifierEnum.PoNumber)?.value;

  const idPart =
    (scheduledResourceReferenceId && (
      <div key="appointment_id_key" className="sw-appointment-shipment-id inline-block text-[8px]">
        {scheduledResourceReferenceId}
      </div>
    )) ||
    null;
  const nameParts = [
    name && !idPart && <div className="sw-appointment-name">{name}</div>,
    poNumber && <div className="sw-appointment-po-number">{poNumber}</div>,
    carrierName && <div className="sw-appointment-carrier">{carrierName}</div>,
    reason && !scheduledResourceReferenceId && <div className="sw-appointment-reason">{reason}</div>,
    deliveryTypeName(appointment.deliveryType),
    equipmentTypeName(loadType?.equipment_type),
    durStr
  ]
    .filter(Boolean)
    .map((text, i) => (
      <div key={`appointment_name_part_key_${i}`} className="sw-appointment-part inline-block">
        {text}
      </div>
    ));

  if (short && idPart) {
    nameParts.unshift(idPart);
  }

  return (
    <button
      onClick={() => onClick(appointment)}
      className={classNames(
        'sw-appointment-list-entry',
        'rounded-md drop-shadow-md',
        'text-left',
        'flex items-center gap-1',
        'bg-sw-background-component',
        'border border-sw-border-alternate',
        'pl-1',
        {'sw-appointment-short': short},
        short ? 'px-2 py-1 text-[8px]' : 'p-2 text-xxs',
        status === AppointmentStatusEnum.Unscheduled ? 'sw-appointment-list-draggable-entry' : ''
      )}
      data-appointment={JSON.stringify(appointment)}
    >
      <SvgIcon name="Drag" color="sw-disabled" className="cursor-move" />
      <div className="w-full">
        <div className="flex items-center justify-between">
          {short || !idPart ? null : idPart}
          <Pill variant="white" hasBorder size="xs">
            {!!dock && status !== AppointmentStatusEnum.Cancelled ? dock.name : '--'}
          </Pill>
        </div>
        {interleave(nameParts, () => (
          <span> • </span>
        ))}
      </div>
    </button>
  );
}
