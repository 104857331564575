import {useMutation} from '@tanstack/react-query';

import {autoBookShipment} from 'App/api/shipment/typed';

export const useAutoBookShipmentMutation = () => {
  const {mutateAsync, ...autoBookMutationOptions} = useMutation({
    mutationFn: autoBookShipment
  });

  return {
    autoBookShipment: mutateAsync,
    autoBookMutationOptions
  };
};
