import get from 'lodash/get';
import uniq from 'lodash/uniq';
import set from 'lodash/set';
import {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, FieldArray} from 'redux-form';
import {Button} from 'react-bootstrap';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {bind} from 'App/utils/camelize';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderMultipleShipmentReps from 'App/formComponents/renderMultipleShipmentReps';
import {makeValidationErrors, isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';

class ShipmentReferencesUpdateForm extends Component {
  constructor(props) {
    super(props);
    bind(this, ['handleCancel']);

    this.state = {
      carrierReferenceCodes: null
    };
  }

  handleCancel(event) {
    this.props.reset();
    this.props.cancelEdit();
  }

  componentDidMount() {
    if (this.props.carrier && !this.state.carrierReferenceCodes) {
      //fetch the carrier info to get the reference codes to display
      this.getCarrierDetails(this.props.carrier.id);
    }
  }

  async getCarrierDetails(companyId) {
    try {
      const response = await getCarrierRelationshipsPromise({vendorId: companyId});
      const codes = get(response.body.results[0], 'carrier_reference_codes', []);
      this.setState({
        carrierReferenceCodes: codes.map((code) => {
          return {name: `${code.value}${code.is_default ? ' (Default)' : ''}`, id: code.value};
        })
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      deleteRep,
      error,
      handleSubmit,
      reps,
      submitting,
      hasDrayage,
      customFields = [],
      createdBy,
      companyId,
      carrier
    } = this.props;

    const companyUsers = [];
    if (this.props.companyUsers && this.props.companyUsers.results) {
      for (let i = 0; i < this.props.companyUsers.results.length; i++) {
        companyUsers.push({
          display_name:
            this.props.companyUsers.results[i].first_name + ' ' + this.props.companyUsers.results[i].last_name,
          id: this.props.companyUsers.results[i].id
        });
      }
    }
    const selected = [];
    if (
      this.props.shipmentReferencesUpdateForm &&
      this.props.shipmentReferencesUpdateForm.values &&
      this.props.shipmentReferencesUpdateForm.values.reps &&
      this.props.shipmentReferencesUpdateForm.values.reps.length > 0
    ) {
      for (let j = 0; j < this.props.shipmentReferencesUpdateForm.values.reps.length; j++) {
        if (
          companyUsers.filter((e) => e.id === this.props.shipmentReferencesUpdateForm.values.reps[j].user).length > 0
        ) {
          selected.push(
            companyUsers.filter((e) => e.id === this.props.shipmentReferencesUpdateForm.values.reps[j].user)[0]
          );
        }
      }
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          label="BOL#"
          name="bol_number"
          className="input-bol"
          component={renderField}
          type="text"
          placeholder="BOL#"
        />
        <Field label="PRO#" name="pro_number" component={renderField} type="text" placeholder="PRO#" />
        <Field label="Pickup#" name="pickup_number" component={renderField} type="text" placeholder="Pickup#" />
        <Field label="PO#" name="purchase_order_number" component={renderField} type="text" placeholder="PO#" />
        {hasDrayage && (
          <Fragment>
            <Field
              label="Booking#"
              name="drayage_booking_number"
              component={renderField}
              type="text"
              placeholder="Booking#"
              normalize={(value) => value || null}
            />
            <Field
              label="Container#"
              name="drayage_container_number"
              component={renderField}
              type="text"
              placeholder="Container#"
              normalize={(value) => value || null}
            />
            <Field
              label="Seal#"
              name="drayage_seal_number"
              component={renderField}
              type="text"
              placeholder="Seal#"
              normalize={(value) => value || null}
            />
            <Field
              label="Chassis#"
              name="drayage_chassis_number"
              component={renderField}
              type="text"
              placeholder="Chassis#"
              normalize={(value) => value || null}
            />
          </Fragment>
        )}

        <Field
          label="Customer Reference#"
          name="customer_reference_number"
          component={renderField}
          type="text"
          placeholder="Customer Reference#"
        />
        {carrier && (
          <Field
            label="Carrier Reference Code #"
            name="carrier_reference_code"
            component={renderDropDownSelector}
            type="text"
            placeholder="Carrier Reference Code #"
            data={this.state.carrierReferenceCodes}
          />
        )}

        {customFields.length > 0 &&
          customFields.map((cf) => {
            const isOwner = isCustomFieldOwner(cf.company, companyId);
            return (
              <Field
                disabled={!isOwner}
                key={cf.id}
                label={cf.label}
                name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`}
                req={cf.required && isOwner}
                component={renderField}
              />
            );
          })}

        {createdBy && (
          <Field
            label="Created By"
            name="created_by"
            component={renderField}
            disabled // this value is always read-only
          />
        )}

        <div className="formstyle-dark">
          <h4>Shipment Reps</h4>
          <FieldArray
            label="Shipment Reps"
            name="reps"
            component={renderMultipleShipmentReps}
            props={{
              companyUsers: companyUsers,
              deleteRep: deleteRep,
              reps: reps,
              shipmentRepRoles: this.props.shipmentRepRoles,
              selected: selected
            }}
          />
        </div>

        <div className="pull-right">
          <Button
            bsStyle="default"
            type="button"
            disabled={submitting}
            onClick={this.handleCancel}
            className="cancel-edit"
            name="cancel-edit"
          >
            Cancel
          </Button>
          <Button bsStyle="primary" type="submit" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" /> Saving...
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </div>

        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
      </Form>
    );
  }
}

const hasDuplicates = (value) => {
  return uniq(value).length !== value.length;
};

const validate = (values, {customFields = [], companyId}) => {
  const errors = {};
  if (values.reps) {
    errors.reps = [];
    // check that user roles are unique
    const userRoles = [];
    values.reps.map((rep) => userRoles.push(`${rep.role} ${rep.user}`));

    values.reps.forEach((rep, i) => {
      errors.reps[i] = {};

      if (!rep.role) {
        errors.reps[i].role = 'Please select a role';
      }
      if (!rep.user) {
        errors.reps[i].user = 'Please select a rep';
      }
      // do not allow rep to be assigned to same role
      if (hasDuplicates(userRoles)) {
        errors.reps[i].role = 'A rep cannot be assigned to same role';
      }
    });
  }

  if (customFields.length > 0) {
    set(
      errors,
      `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}`,
      makeValidationErrors(values, customFields, CustomFieldEntityTypesEnum.Shipment, companyId)
    );
  }

  return errors;
};

ShipmentReferencesUpdateForm = reduxForm({
  form: 'shipmentReferencesUpdateForm',
  validate: validate,
  enableReinitialize: true
})(ShipmentReferencesUpdateForm);

ShipmentReferencesUpdateForm = connect((state, ownProps) => ({
  initialValues: {
    reps: state.shipmentdetails.reps && state.shipmentdetails.reps.results,
    custom_data: ownProps.shipmentCustomData,
    created_by: ownProps.createdBy,
    ...state.shipmentdetails.shipment_references
  },
  companyUsers: state.users.companyUsers,
  reps: state.shipmentdetails.reps,
  shipmentReferencesUpdateForm: state.form.shipmentReferencesUpdateForm,
  shipmentRepRoles: state.shipmentdetails.shipmentRepRoles,
  companyId: state.auth.company.id
}))(ShipmentReferencesUpdateForm);

export default ShipmentReferencesUpdateForm;
