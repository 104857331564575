import {
  Button,
  Card,
  FormikCheckbox,
  FormikPhoneInput,
  FormikTextInput,
  IconButtonWithTooltip,
  SvgIcon
} from '@shipwell/shipwell-ui';
import {Field, FieldArray, Form, Formik} from 'formik';
import {array, boolean, object, string} from 'yup';
import {CreateSupplierUser} from '@shipwell/backend-core-sdk';
import {useEffect, useState} from 'react';
import {CreateSupplierStepsModalFooter} from '../../components';
import {FieldArraySideNav} from 'App/components/FieldArray/FieldArraySideNav';
import {nullablePhoneNumberSchemaField} from 'App/utils/yupHelpersTyped';

const emptyContact: CreateSupplierUser = {email: '', full_name: '', should_send_notifications: true};
const initialValues: {contacts: CreateSupplierUser[]} = {contacts: [emptyContact]};

const validationChema = object({
  contacts: array().of(
    object({
      full_name: string().required('Contact name is required'),
      email: string().email('Invalid email').required('Email is required'),
      phone_number: nullablePhoneNumberSchemaField('contact_phone'),
      should_send_notifications: boolean()
    })
  )
});

export const NewSupplierContactsForm = ({
  onSubmit,
  onCancel,
  onBack,
  isSubmitting
}: {
  onSubmit: (formValues: CreateSupplierUser[]) => void;
  onCancel: () => void;
  onBack: () => void;
  isSubmitting: boolean;
}) => {
  const [index, setIndex] = useState<number>(0);

  const getLabelsForContacts = (values: {contacts: CreateSupplierUser[]}) =>
    values?.contacts?.map(
      (contact, index) => `Contact ${index + 1} ${contact.full_name ? '-' : ''} ${contact.full_name}`
    ) ?? [];

  useEffect(() => {
    const element = document.querySelector('input[data-new-supplier-contact-name]');
    if (element instanceof HTMLElement) element.focus();
  }, [index]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values.contacts)}
      validationSchema={validationChema}
      validateOnMount
    >
      {({isValid, values}) => (
        <Form className="mt-8 grid gap-4" noValidate>
          <FieldArray name="contacts">
            {({push, remove}) => (
              <div className="grid grid-cols-[15rem_auto] gap-4">
                <div className="bg-sw-background">
                  <FieldArraySideNav
                    title="Contact"
                    labels={getLabelsForContacts(values)}
                    activeIndex={index}
                    onActiveIndexChange={setIndex}
                    onAddNew={() => {
                      push(emptyContact);
                      setIndex(values.contacts.length);
                    }}
                    isAddNewDisabled={!isValid}
                  />
                </div>
                <Card
                  draggableProvided={false}
                  title={`Contact ${index + 1}`}
                  actions={
                    <>
                      {index > 0 ? (
                        <IconButtonWithTooltip
                          iconName="TrashOutlined"
                          onClick={() => {
                            remove(index);
                            setIndex(index - 1);
                          }}
                          aria-label="Remove this contact"
                        />
                      ) : null}
                    </>
                  }
                >
                  <div className="grid gap-4">
                    <Field
                      name={`contacts[${index}].full_name`}
                      data-new-supplier-contact-name
                      label="Contact Name"
                      component={FormikTextInput}
                      required
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <Field
                        name={`contacts[${index}].phone_number`}
                        label="Phone Number"
                        component={FormikPhoneInput}
                      />
                      <Field
                        prepend={<SvgIcon name="EmailOutlined" />}
                        name={`contacts[${index}].email`}
                        label="Email"
                        component={FormikTextInput}
                        required
                      />
                    </div>
                    <Field
                      name={`contacts[${index}].should_send_notifications`}
                      label="Receive order-related emails/notifications"
                      component={FormikCheckbox}
                    />
                  </div>
                </Card>
              </div>
            )}
          </FieldArray>
          <CreateSupplierStepsModalFooter
            backButton={
              <div className="pl-6">
                <Button isCompact iconName="ArrowBack" variant="tertiary" onClick={onBack}>
                  Back
                </Button>
              </div>
            }
          >
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </CreateSupplierStepsModalFooter>
        </Form>
      )}
    </Formik>
  );
};
