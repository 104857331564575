import {phoneUtil, validateEmail} from 'App/utils/globals';

const validate = (values) => {
  const errors = {user: {}};
  if (!values.user) {
    errors.user.first_name = 'Please enter a first name';
    errors.user.last_name = 'Please enter a last name';
    errors.user.email = 'Please enter an email';
  }

  if (values.user && !values.user.first_name) {
    errors.user.first_name = 'Please enter a first name';
  }

  if (values.user && !values.user.last_name) {
    errors.user.last_name = 'Please enter a last name';
  }
  /*
	if (values.user && !values.user.email) {
		errors.user.email = 'Please enter an email';
	}
	if (values.user && values.user.email) {
		if (!validateEmail(values.user.email)) {
			errors.user.email = 'Please enter a valid email';
		}
	}

	let number;
	if (values.phone_number) {
		try {
			number = phoneUtil.parse(values.phone_number);
		} catch (err) {
			errors.phone_number = 'Phone number is invalid.';
		}
	}
	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required.';
	} else if (
		values.phone_number &&
		number &&
		!phoneUtil.isValidNumber(number)
	) {
		errors.phone_number = 'Phone number is invalid.';
	}*/
  return errors;
};

export default validate;
