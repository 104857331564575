/* eslint-disable react/prop-types */
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import renderCheckBox from 'App/formComponents/renderCheckBox';
import renderField from 'App/formComponents/renderField';
import * as actions from 'App/actions/shipments';
import {formatCurrency} from 'App/utils/internationalConstants';
import '../_financials.scss';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company
  }),
  actions
)
export class FinancialsReadOnly extends Component {
  render() {
    let customerTotal = 0;
    let lineItems = [];
    const className = this.props.className || null;
    if (this.props.selectedQuote) {
      customerTotal = formatCurrency(
        this.props.selectedQuote.total,
        this.props.selectedQuote.charge_line_items?.[0]?.unit_amount_currency || this.props.selectedQuote.currency
      );
      if (this.props.selectedQuote.charge_line_items) {
        lineItems = this.props.selectedQuote.charge_line_items.sort((a, b) => {
          return a.amount < b.amount;
        });
      }
    }
    let showCustomerMarkup = false;
    let customerMarkupValue = 0;
    if (
      this.props.selectedQuote &&
      ((this.props.selectedQuote.customer_markup && this.props.selectedQuote.customer_markup !== 0) ||
        this.props.selectedQuote.charge_line_items?.filter((e) => e.is_provider_markup === true).length > 0)
    ) {
      showCustomerMarkup = true;
      if (this.props.selectedQuote.customer_markup) {
        customerMarkupValue = this.props.selectedQuote.customer_markup;
      } else {
        customerMarkupValue = this.props.selectedQuote.charge_line_items?.filter(
          (e) => e.is_provider_markup === true
        )[0].amount;
      }
    }
    let carrierTotal = 0;
    let currency = 'USD';

    if (showCustomerMarkup) {
      //show the carrier total as well
      if (this.props.selectedQuote && this.props.selectedQuote.charge_line_items) {
        for (var i = 0; i < this.props.selectedQuote.charge_line_items.length; i++) {
          currency = this.props.selectedQuote.charge_line_items[i].unit_amount_currency;
          if (this.props.selectedQuote.charge_line_items[i].is_provider_markup === false) {
            carrierTotal += this.props.selectedQuote.charge_line_items[i].amount;
          }
        }
      }
    }

    return (
      <div className={`${className ? className : ''}`}>
        <h2 className="border-bottom">Financials</h2>
        <div className="quote-flow__financialsReadOnly">
          <div className="quote-flow__financials-maxBuy">
            <h5>Internal</h5>
            <Field
              name="metadata.buy_it_now_amount"
              label="Book Now"
              placeholder="Enter a price"
              component={renderField}
            />
            <Field name="metadata.max_buy_amount" label="Max Buy" placeholder="Enter a price" component={renderField} />
          </div>
          <div className="quote-flow__financialsReadOnlyForm">
            {lineItems.map((item, index) => {
              if (item.is_provider_markup === false) {
                return (
                  <div key={index} className="quote-flow__financialsReadOnlyLineItem">
                    <span className="quote-flow__financialsReadOnlyLineItem-field">
                      <h5>{item.unit_name}</h5>
                    </span>
                    <span className="quote-flow__financialsReadOnlyLineItem-field">
                      {formatCurrency(item.amount, item.unit_amount_currency)}
                    </span>
                  </div>
                );
              }
            })}
          </div>
          {showCustomerMarkup && (
            <div className="quote-flow__financialsReadOnlyTotalRow">
              <h5>Carrier Total</h5>
              <p className="quote-flow__financialsReadOnlyTotalRow-carrierTotal">
                {formatCurrency(carrierTotal, currency)}
              </p>
            </div>
          )}
          {showCustomerMarkup && (
            <div className="quote-flow__financialsReadOnlyTotalRow">
              <h5>Markup</h5>
              <span className="quote-flow__financialsReadOnlyTotalRow-total">
                {formatCurrency(customerMarkupValue, currency)}
              </span>
            </div>
          )}

          <div className="quote-flow__financialsReadOnlyTotalRow-grandTotal">
            <h5>{showCustomerMarkup ? 'Customer Total' : 'Total'}</h5>
            <h3 className="quote-flow__financialsReadOnlyTotalRow-total">{customerTotal}</h3>
          </div>
        </div>
        {this.props.showBillToCheckbox && (
          <Field
            name="override_bill_to"
            component={renderCheckBox}
            label="Override Bill-To Information"
            normalize={(v) => !!v}
          />
        )}
      </div>
    );
  }
}
