export function arrayIncludes<T>(array: T[], item: unknown): boolean {
  const n = array.length;
  for (let i = 0; i < n; i++) {
    if (array[i] === item) return true;
  }
  return false;
}

export function arrayIndexOf<T>(array: T[], item: unknown): number {
  const n = array.length;
  for (let i = 0; i < n; i++) {
    if (array[i] === item) {
      return i;
    }
  }
  return -1;
}

export function objectKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectValues<V>(obj: Record<any, V>): V[];
export function objectValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}

export function objectEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

/**
 * A group by utility that returns `Record<K, V[]>`. The LoDash version at best
 * requires a cast.
 */
export function groupBy<K extends string | number | symbol, V>(fKey: (value: V) => K, array: V[]): Record<K, V[]> {
  const groups = {} as Record<K, V[]>;
  const n = array.length;
  for (let i = 0; i < n; i++) {
    const value = array[i];
    const key = fKey(value);
    const list = groups[key] ?? (groups[key] = []);
    list.push(value);
  }
  return groups;
}

export function isNotNull<T extends unknown | null | undefined>(x: T): x is Exclude<T, null | undefined> {
  return x != null;
}
