import {useEffect} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {getFuelSurchargeTables} from 'App/api/rateTables/typed';
import debounce from 'debounce-promise';

// keep this outside of the component
const debouncedGetFuelSurchargeDetails = debounce(async (q?: string) => {
  const response = await getFuelSurchargeTables({q});
  return response.results || [];
}, 300);
const FuelSurchargeField = ({disabled}: {disabled?: boolean}) => {
  const {values, setFieldValue} = useFormikContext<{fuel_included?: boolean}>();
  const {fuel_included: fuelIncluded} = values;

  useEffect(() => {
    if (fuelIncluded) {
      setFieldValue('fuel_surcharge_table', null);
    }
  }, [fuelIncluded, setFieldValue]);

  return (
    <Field
      async
      name="fuel_surcharge_table"
      label="Fuel Surcharge Table"
      clearable
      loadOptions={debouncedGetFuelSurchargeDetails}
      getOptionLabel={(opts?: {name: string}) => opts?.name}
      getOptionValue={(opts?: {id: string}) => opts?.id}
      disabled={fuelIncluded || disabled}
      component={FormikSelect}
      defaultOptions
    />
  );
};

export default FuelSurchargeField;
