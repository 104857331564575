import invariant from 'tiny-invariant';
import {browserHistory} from 'react-router';
import {FormikHelpers} from 'formik';
import {UserForm, UserWithPermissions} from '../userForm';
import {permissionsCategories} from '../permissionsCategories';
import PageHeader from 'App/common/pageHeader';
import {useUserMeContext} from 'App/data-hooks/users/useUserMe';
import {useCreateUser} from 'App/data-hooks';
import withStatusToasts from 'App/components/withStatusToasts';
import {unpackShipwellErrorForFormik} from 'App/utils/globalsTyped';

const newUserTemplate: UserWithPermissions = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  permissions: []
};

export const CreateUser = withStatusToasts(({setError}) => {
  const {company} = useUserMeContext();
  invariant(company?.id, 'No company id found for user');

  const {createUser, isCreatingUser} = useCreateUser(company.id);

  const handleSubmit = (updatedUser: UserWithPermissions, {setErrors}: FormikHelpers<UserWithPermissions>) => {
    createUser(
      {
        userDetails: {
          ...updatedUser,
          avatar: undefined // avatar field is required, but is of type `any`?!! ¯\_(ツ)_/¯. Remove this after STM-2567 is completed.
        }
      },
      {
        onError: (error) => {
          // This block is able to capture the "A user with this email address already exists" error and show it in the UI
          if (error) setErrors(unpackShipwellErrorForFormik(error));
          setError('Error', 'There was a problem creating the user. Please try again');
        },
        onSuccess: (newUser) => {
          invariant(newUser.id, 'No id found for user');
          browserHistory.push(`/company/users/${newUser.id}`);
        }
      }
    );
  };

  return (
    <div className="w-full">
      <PageHeader title="Add User" backArrow backRoute="/company/users" />
      <div className="mx-auto max-w-[1600px] p-5">
        <UserForm
          user={newUserTemplate}
          onSubmit={handleSubmit}
          isSubmitting={isCreatingUser}
          permissionsCategories={permissionsCategories}
        />
      </div>
    </div>
  );
});
