import {
  OrderReference,
  OrderReferenceQualifier,
  OrderStopReference,
  ProductOrderStopReferenceQualifier
} from '@shipwell/corrogo-sdk';

export type OrderReferences = {customerReferenceNumber?: string; purchaseOrderNumber?: string};
export const getOrderReferencesArray = (orderReferences: OrderReferences | undefined): OrderReference[] | undefined => {
  const references: OrderReference[] = [
    {
      qualifier: OrderReferenceQualifier.CustomerReferenceNumber,
      value: orderReferences?.customerReferenceNumber || ''
    },
    {
      qualifier: OrderReferenceQualifier.PurchaseOrderNumber,
      value: orderReferences?.purchaseOrderNumber || ''
    }
  ].filter((ref) => !!ref.value);

  return references.length ? references : undefined;
};
export const getOrderReferencesObject = (orderReferences: OrderReference[] | undefined): OrderReferences => {
  const references: OrderReferences = {
    customerReferenceNumber: orderReferences?.find(
      (reference) => reference.qualifier === OrderReferenceQualifier.CustomerReferenceNumber
    )?.value,

    purchaseOrderNumber: orderReferences?.find(
      (reference) => reference.qualifier === OrderReferenceQualifier.PurchaseOrderNumber
    )?.value
  };
  return references;
};

export type OrderStopReferencesObject = {
  companyExternalId?: string;
  stopId?: string;
  dockExternalId?: string;
  addressBookEntryId?: string;
  addressBookEntryReferenceId?: string;
};
export const getOrderStopReferencesArray = (
  orderStopReferencesObject: OrderStopReferencesObject | undefined
): OrderStopReference[] | undefined => {
  const references: OrderStopReference[] = [
    {
      qualifier: ProductOrderStopReferenceQualifier.CompanyExternalId,
      value: orderStopReferencesObject?.companyExternalId || ''
    },
    {
      qualifier: ProductOrderStopReferenceQualifier.AddressBookEntryId,
      value: orderStopReferencesObject?.addressBookEntryId || ''
    },
    {
      qualifier: ProductOrderStopReferenceQualifier.AddressBookEntryReferenceId,
      value: orderStopReferencesObject?.addressBookEntryReferenceId || ''
    }
  ].filter((ref) => !!ref.value);

  return references.length ? references : undefined;
};
export const getOrderStopReferencesObject = (
  orderStopReferencesObject: OrderStopReference[] | undefined
): OrderStopReferencesObject => {
  const references: OrderStopReferencesObject = {
    companyExternalId: orderStopReferencesObject?.find(
      (reference) => reference.qualifier === ProductOrderStopReferenceQualifier.CompanyExternalId
    )?.value,
    stopId: orderStopReferencesObject?.find(
      (reference) => reference.qualifier === ProductOrderStopReferenceQualifier.StopId
    )?.value,
    dockExternalId: orderStopReferencesObject?.find(
      (reference) => reference.qualifier === ProductOrderStopReferenceQualifier.DockExternalId
    )?.value,
    addressBookEntryId: orderStopReferencesObject?.find(
      (reference) => reference.qualifier === ProductOrderStopReferenceQualifier.AddressBookEntryId
    )?.value,
    addressBookEntryReferenceId: orderStopReferencesObject?.find(
      (reference) => reference.qualifier === ProductOrderStopReferenceQualifier.AddressBookEntryReferenceId
    )?.value
  };

  return references;
};
