import {CompanySchema} from '@shipwell/shipment-assembly-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {updateCompanySettingsById} from 'App/api/loadOptimization';
import {COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ShipmentAssemblyBaseErrorResponse} from 'App/containers/loadOptimization/types';

export const useUpdateCompanySettings = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompanySettingsById>>,
    ShipmentAssemblyBaseErrorResponse,
    CompanySchema
  >
) => {
  const queryClient = useQueryClient();
  const invalidateConfigListQuery = async () =>
    await queryClient.invalidateQueries([COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY]);
  const updateCompanySettingsMutation = useMutation<
    Awaited<ReturnType<typeof updateCompanySettingsById>>,
    ShipmentAssemblyBaseErrorResponse,
    CompanySchema
  >(
    (newCompanyDetails: CompanySchema) => updateCompanySettingsById(newCompanyDetails.id, newCompanyDetails),
    mutationOptions
  );

  return {updateCompanySettingsMutation, invalidateConfigListQuery};
};
