import moment from 'moment';
import {AlertDashboardSlimShipment, AlertEventSecondaryResourceTypeEnum, AlertEvent} from '@shipwell/backend-core-sdk';

export const detentionStatusConstants = {
  APPROACHING: 'approaching',
  IN_PROGRESS: 'in progress',
  IN_PROGRESS_THRESHOLD: '120'
};

type AlertEventType = AlertEvent & {
  custom_data: {
    detention_start_time: string;
    detention_type: string;
  };
};
function hasCustomData(event?: AlertEvent): event is AlertEventType {
  return event?.custom_data?.detention_start_time !== undefined || event?.custom_data?.detention_type !== undefined;
}

export const getDetentionAlertAndStop = (shipment: AlertDashboardSlimShipment, detentionAlertConfig: AlertEvent) => {
  // find the detention alert from the active alert events
  const detentionAlertEvent = shipment?.active_alert_events?.find((alertEvent) => {
    return (
      alertEvent.secondary_resource_type === AlertEventSecondaryResourceTypeEnum.Stop &&
      alertEvent.configuration === detentionAlertConfig.configuration
    );
  });
  // find the stop that has the detention alert
  const detentionStop = shipment?.stops?.find((stop) => stop.id === detentionAlertEvent?.secondary_resource_id);
  return {detentionAlertEvent, detentionStop};
};

const formatDetentionTime = (time?: string | null) => {
  return `${moment(time).format('HH:mm')} ${moment.tz(moment.tz.guess()).format('z')}`;
};

export const detentionFeeStatus = (shipment: AlertDashboardSlimShipment, detentionAlertConfig: AlertEvent) => {
  //find the right alert and stop from the alert events list
  const {detentionAlertEvent, detentionStop} = getDetentionAlertAndStop(shipment, detentionAlertConfig);

  const checkInTime = formatDetentionTime(detentionStop?.confirmed_arrival_at);

  const eventHasCustomData = hasCustomData(detentionAlertEvent);

  if (!eventHasCustomData) {
    return {
      detentionType: 'Invalid',
      checkInTime,
      startTime: 'Invalid'
    };
  }
  const startTime = formatDetentionTime(detentionAlertEvent?.custom_data?.detention_start_time);

  const detentionStartTime = moment(detentionAlertEvent?.custom_data?.detention_start_time);
  // find out if detention is approaching
  if (moment().utc().isBefore(detentionStartTime)) {
    return {
      detentionType: detentionStatusConstants.APPROACHING,
      checkInTime,
      startTime
    };
  }
  // detention is in progress otherwise
  return {
    detentionType: detentionStatusConstants.IN_PROGRESS,
    checkInTime,
    startTime
  };
};
