import {UPSOauthRefreshStatus} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery, useQueries} from '@tanstack/react-query';
import {getUPSRegistrationAccounts, getUPSOauthStatus} from 'App/api/registration/typed';
import {COMPANY_INTEGRATION_UPS, UPS_OAUTH_STATUS} from 'App/data-hooks/queryKeys';
import {useUserMe} from 'App/data-hooks/users';

type UseGetUpsAccountsQueryParams = UseQueryOptions<ReturnType<typeof getUPSRegistrationAccounts>>;

export const useGetUpsAccountsQuery = ({
  accountNumber,
  enabled: isQueryEnabled = true,
  pollAuthStatus = false
}: {accountNumber?: string; pollAuthStatus?: boolean} & UseGetUpsAccountsQueryParams = {}) => {
  const {company} = useUserMe();
  const isUpsEnabled = company?.feature_flags?.ups_enabled;

  const upsQuery = useQuery(
    [COMPANY_INTEGRATION_UPS, ...(accountNumber ? [accountNumber] : [])],
    async () => {
      const response = await getUPSRegistrationAccounts(accountNumber);
      return response.data;
    },
    {
      onError: (error) => {
        console.error(error);
      },
      enabled: isUpsEnabled && isQueryEnabled
    }
  );

  const isUpsAuthenticated = upsQuery?.data?.results?.find((result) => result.company === company?.id);

  // used to get oauth status
  useQueries<UPSOauthRefreshStatus[]>({
    queries: (upsQuery?.data?.results || [])?.map(({id}) => ({
      queryKey: [UPS_OAUTH_STATUS, id],
      queryFn: () => {
        if (!id) return;

        return getUPSOauthStatus(id);
      },
      enabled: !!upsQuery?.data?.results?.length,
      refetchInterval: (data) => {
        if (!pollAuthStatus) return false;

        const {status} = (data as UPSOauthRefreshStatus | undefined) || {};
        return status === 'PROCESSING' ? 3000 : false;
      },
      refetchOnWindowFocus: pollAuthStatus,
      notifyOnChangeProps: ['data']
    }))
  });

  return {
    isUpsAuthenticated,
    upsQuery,
    isUpsEnabled,
    upsAccounts: upsQuery.data?.results
  };
};
