import curry from 'lodash/curry';
import pipe from 'lodash/fp/pipe';
import {formatDate} from 'App/utils/globals';
import getNil from 'App/utils/getNil';

export const defaultTableMeta = {
  page: 1,
  pages: 1,
  pageSize: 50
};

//width for an address cell in new tables
export const ADDRESS_CELL_WIDTH = 224;

export const DEFAULT_TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const PURCHASE_ORDER_TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 200];

/**
 * This util formats all filterable columns into the v3 filter param spec
 * e.g.,filter=carrier_name=~Vandelay,reference_id=~Vandelay
 * @param  {Array} filterableTableColumnIds Column ids to check for search query
 * @param  {String} searchTerm
 *
 */
export const formatFilterParam = (filterableTableColumnIds = [], searchTerm = '') => {
  return filterableTableColumnIds.map((columnId) => `${columnId}=~${searchTerm}`).join(',');
};

/**
 * A helper that creates a function that can be used to access row properties with a fallback
 * placeholder value. E.g.
 *
 * {
 *   …
 *   id: 'row_id',
 *   accessor: placeholderAccessor('property_to_retrieve'),
 *   …
 * }
 *
 * would render `row.property_to_retrieve` if it exists and is not null and '--' otherwise.
 *
 * @param {String} property The property to retrieve from the row
 * @param {String} placeholder The placeholder to use if the property is nil; defaults to '--'
 * @return {()=>:void} A function that takes a row object, like `accessor` expects
 */
export function placeholderAccessor(property, placeholder = '--') {
  return curry(getNil)(curry.placeholder, property, placeholder);
}

/**
 * A helper that creates a function that can be used to access row properties with a fallback
 * placeholder value. The row property is then passed to `formatDate`. Note that the placeholder
 * will _also_ be passed to `formatDate`, but moment usually does the right thing™ with non-date
 * strings.
 *
 * This is built on `placeholderAccessor`; see above for more details.
 *
 * @param {String} property The date property to retrieve from the row and format
 * @param {String} placeholder The placeholder to use if the property is nil; defaults to '--'
 * @return {Function} A function that takes a row object, like `accessor` expects
 */
export function placeholderDateAccessor(property, placeholder = '--') {
  return pipe(placeholderAccessor(property, placeholder), formatDate);
}
