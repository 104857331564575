type AddressValues = {country?: string | null; state_province?: string | null; value: undefined};
type NestedAddressValues = {value: AddressValues};

export type ContractAddress = AddressValues | NestedAddressValues;

const isNestedAddress = (address: ContractAddress): address is NestedAddressValues => !!address.value?.country;

/**
 * Ensures that each address on a contract contains state_province & country WFA-2047
 */
export const validateContractAddress = (addresses?: Array<ContractAddress>) =>
  addresses?.every((address) =>
    isNestedAddress(address)
      ? address.value.country && address.value.state_province
      : address.country && address.state_province
  );
