import PropTypes from 'prop-types';
import {OceanTrackingReferenceType, OceanTrackingServiceTypeEnum} from '@shipwell/corrogo-sdk';
import {DisplayValue, Title, Card, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {LegFieldsModal} from 'App/containers/shipments/drayage/components/LegDetailsFields';
import useToggle from 'App/utils/hooks/useToggle';
import Loader from 'App/common/shipwellLoader';
import getNil from 'App/utils/getNil';
import {useBrokerCustomers, useV3Shipment} from 'App/data-hooks';
import {PROPTYPE_COMPANY, PROPTYPE_DRAGGABLE_PROVIDED} from 'App/utils/propTypeConstants';

const THIRD_PARTY_CONTACT_PROPTYPE = PropTypes.shape({
  person_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
});

const ServiceFields = ({steamshipLine, serviceType, vesselName, voyageNumber, identificationCodes}) => (
  <>
    <div className="flex justify-between">
      {steamshipLine ? (
        <DisplayValue className="flex-1" label="Steamship Line">
          {steamshipLine}
        </DisplayValue>
      ) : null}
      <DisplayValue className="flex-1" label="Ocean Carrier SCAC">
        {identificationCodes?.length
          ? identificationCodes.find((code) => code.qualifier === OceanTrackingReferenceType.Scac)?.value
          : '--'}
      </DisplayValue>
    </div>
    <div className="flex justify-between">
      {serviceType === OceanTrackingServiceTypeEnum.OceanTracking ? (
        <>
          <DisplayValue className="flex-1" label="Vessel Name">
            {vesselName}
          </DisplayValue>
          <DisplayValue className="flex-1" label="Voyage Number">
            {voyageNumber}
          </DisplayValue>
        </>
      ) : null}
    </div>
  </>
);

ServiceFields.propTypes = {
  steamshipLine: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  vesselName: PropTypes.string.isRequired,
  voyageNumber: PropTypes.string.isRequired,
  identificationCodes: PropTypes.arrayOf(
    PropTypes.shape({
      qualifier: PropTypes.string,
      value: PropTypes.string
    })
  )
};

const ThirdPartyContactsSection = ({thirdPartyContact}) => (
  <>
    <DisplayValue label="3rd Party Contact">
      <div>{getNil(thirdPartyContact, 'person_name', '--')}</div>
      <div>{getNil(thirdPartyContact, 'email', '--')}</div>
      <div>{getNil(thirdPartyContact, 'phone', '--')}</div>
    </DisplayValue>
  </>
);

ThirdPartyContactsSection.propTypes = {
  thirdPartyContact: THIRD_PARTY_CONTACT_PROPTYPE.isRequired
};

export const LegView = ({shipmentId, draggableProvided}) => {
  const [showEditState, toggleShowEditState] = useToggle();

  return (
    <>
      <LegViewCard shipmentId={shipmentId} onEdit={toggleShowEditState} draggableProvided={draggableProvided} />
      {showEditState ? (
        <LegFieldsModal shipmentId={shipmentId} showEditState={showEditState} onClose={toggleShowEditState} />
      ) : null}
    </>
  );
};

LegView.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

export const LegViewCardBody = ({shipmentId, expanded}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const service = get(shipmentQuery, 'context.serviceDisplayValues');

  const {
    serviceType,
    portType,
    steamshipLine,
    vesselName,
    voyageNumber,
    formattedEstimatedArrival,
    formattedActualArrival,
    thirdPartyContact,
    identificationCodes
  } = service;
  const {
    context: {modeSpecificDisplayValues}
  } = shipmentQuery;
  const {isImport, cutDate, lastFreeDay, releaseDate, returnDate} = modeSpecificDisplayValues;

  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        {isImport ? 'Import' : 'Export'}
      </Title>
      <div className="grid grid-cols-2 gap-4 pb-2">
        {isImport ? (
          <>
            <DisplayValue label="Estimated Time Of Arrival">{formattedEstimatedArrival}</DisplayValue>
            <DisplayValue label="Release Date">{releaseDate}</DisplayValue>
            <DisplayValue label="Last Free Date">{lastFreeDay}</DisplayValue>
            <DisplayValue label="Actual Arrival Date">{formattedActualArrival}</DisplayValue>
          </>
        ) : (
          <>
            <DisplayValue label="Estimated Time Of Departure">{formattedEstimatedArrival}</DisplayValue>
            <DisplayValue label="Cut Date">{cutDate}</DisplayValue>
            <DisplayValue label="Early Return Date">{returnDate}</DisplayValue>
          </>
        )}
      </div>
      {expanded ? (
        <div className="border-t border-sw-border pt-4">
          <div className="mb-4 border-b border-sw-border">
            <Title variant="sectionTitle" className="pb-2">
              {portType}
            </Title>
            <div className="grid grid-cols-2 gap-4 pb-2">
              <ServiceFields
                steamshipLine={steamshipLine}
                serviceType={serviceType}
                vesselName={vesselName}
                voyageNumber={voyageNumber}
                identificationCodes={identificationCodes}
              />
            </div>
          </div>
          <ThirdPartyContactsSection thirdPartyContact={thirdPartyContact} />
        </div>
      ) : null}
    </>
  );
};

LegViewCardBody.propTypes = {
  expanded: PropTypes.bool,
  shipmentId: PropTypes.string
};

LegViewCardBody.defaultProps = {
  expanded: true
};

const LegViewCard = ({shipmentId, onEdit, draggableProvided}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {modeSpecificDisplayValues}
  } = shipmentQuery;

  const {isImport} = modeSpecificDisplayValues;

  return (
    <Card
      title={isImport ? 'Inbound Leg' : 'Outbound Leg'}
      className="mb-2"
      isCollapsible
      draggableProvided={draggableProvided}
      actions={
        <DeprecatedButton variant="icon" onClick={onEdit}>
          <SvgIcon name="Edit" color="$sw-icon" />
        </DeprecatedButton>
      }
    >
      <LegViewCardBody shipmentId={shipmentId} />
    </Card>
  );
};

LegViewCard.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

const LegDetailsView = ({company, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const {
    context: {getCustomerOptionFromId},
    isLoading: customersIsLoading
  } = useBrokerCustomers(company);

  if (shipmentQuery.isInitialLoading || customersIsLoading) {
    return <Loader loading />;
  }

  const service = get(shipmentQuery, 'context.serviceDisplayValues');
  const {
    serviceType,
    portType,
    steamshipLine,
    vesselName,
    voyageNumber,
    formattedEstimatedArrival,
    thirdPartyContact,
    identificationCodes
  } = service;

  const {
    context: {modeSpecificDisplayValues}
  } = shipmentQuery;
  const {isImport, cutDate, returnDate} = modeSpecificDisplayValues;

  const {referenceDisplayValues} = shipmentQuery.context;
  const {bookingNumber, bolNumber, houseBolNumber} = referenceDisplayValues;

  const customerId = get(shipmentQuery, 'context.customerId');
  const customerObject = getCustomerOptionFromId(customerId);

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col">
        <DisplayValue label="Customer">{getNil(customerObject, 'company.name', '--')}</DisplayValue>
        <Title className="pb-2">{isImport ? 'Import' : 'Export'}</Title>
        <div className="flex justify-between">
          {isImport ? (
            <>
              <DisplayValue className="flex-1" label="Bol #">
                {bolNumber}
              </DisplayValue>
              <DisplayValue className="flex-1" label="House Bol #">
                {houseBolNumber}
              </DisplayValue>
            </>
          ) : (
            <>
              <DisplayValue className="flex-1" label="Booking #">
                {bookingNumber}
              </DisplayValue>
              <DisplayValue className="flex-1" label="Early Return Date">
                {returnDate}
              </DisplayValue>
            </>
          )}
        </div>
        {isImport ? (
          <DisplayValue label="Estimated Time Of Arrival">{formattedEstimatedArrival}</DisplayValue>
        ) : (
          <DisplayValue label="Cut Date">{cutDate}</DisplayValue>
        )}
        <ThirdPartyContactsSection thirdPartyContact={thirdPartyContact} />
      </div>
      <div className="flex flex-1 content-center">
        <div className="flex w-full flex-col justify-center border-l-1 border-sw-border pl-4">
          <Title className="grow-0 pb-2">{portType}</Title>
          <div className="flex flex-col">
            <ServiceFields
              steamshipLine={steamshipLine}
              serviceType={serviceType}
              vesselName={vesselName}
              voyageNumber={voyageNumber}
              identificationCodes={identificationCodes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

LegDetailsView.propTypes = {
  shipmentId: PropTypes.string,
  company: PROPTYPE_COMPANY
};

export default connect((state) => ({
  company: state.userCompany.company
}))(LegDetailsView);
