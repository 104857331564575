import {useQuery} from '@tanstack/react-query';
import {FEDEX_PACKAGE_TYPES} from 'App/data-hooks/queryKeys';
import {fetchFedexPackageTypes} from 'App/api/parcel/typed';

export const useGetFedExPackageTypes = () => {
  const {data, ...getFedexPackageTypesQuery} = useQuery([FEDEX_PACKAGE_TYPES], () => fetchFedexPackageTypes(), {
    staleTime: Infinity,
    cacheTime: Infinity
  });

  const fedexPackageTypes = data?.reduce(
    (types, type) => ({...types, [type.id as string]: `FedEx ${type.label as string}`}),
    {}
  );

  return {fedexPackageTypes, getFedexPackageTypesQuery};
};
