import {useQueryClient} from '@tanstack/react-query';
import {IconButtonWithTooltip, Modal, useToastManager, Loader as FullPageLoader} from '@shipwell/shipwell-ui';
import {Facility} from '@shipwell/tempus-sdk';
import useToggle from 'App/utils/hooks/useToggle';
import {deleteFacilityById} from 'App/api/facilities';
import {parseV3ApiError} from 'App/api/typedUtils';
import {FACILITIES_QUERY_KEY} from 'App/data-hooks/queryKeys';

interface ActionsCellProps {
  facility: Facility;
}

export const ActionsCell = ({facility}: ActionsCellProps) => {
  const [showModal, toggleShowModal] = useToggle();
  const [isLoading, toggleIsLoading] = useToggle();
  const modalText = 'Are you sure you want to delete this facility?';
  const queryClient = useQueryClient();

  const {enqueueToast} = useToastManager();

  const handleRemove = async () => {
    toggleShowModal();
    toggleIsLoading();
    try {
      await deleteFacilityById(facility.id);
      enqueueToast({
        variant: 'success',
        title: 'Facility Delete',
        content: 'The facility has been successfully deleted.'
      });
      void queryClient.invalidateQueries([FACILITIES_QUERY_KEY]);
    } catch (error) {
      const {detail, title} = parseV3ApiError(error);
      const updatedDetail = detail.includes('is currently in use by')
        ? 'Facility cannot be deleted. One or more appointments are currently scheduled.'
        : detail;

      enqueueToast({
        variant: 'error',
        title: title,
        content: updatedDetail
      });
    } finally {
      toggleIsLoading();
    }
  };

  return (
    <>
      <IconButtonWithTooltip iconName="RemoveCircleOutlined" aria-label="Delete Facility" onClick={toggleShowModal} />
      <Modal
        show={showModal}
        title={`Delete Facility ${facility.name}?`}
        primaryBtnName={'Yes'}
        secondaryBtnName={'No'}
        onClose={toggleShowModal}
        onPrimaryAction={handleRemove}
      >
        <div>{modalText}</div>
      </Modal>
      <FullPageLoader show={isLoading}>Deleting facility {facility.name}, please wait...</FullPageLoader>
    </>
  );
};
