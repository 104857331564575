import {useQuery} from '@tanstack/react-query';
import {USPS_PACKAGE_TYPES} from 'App/data-hooks/queryKeys';
import {fetchUspsPackageTypes} from 'App/api/referenceData/typed';

export const useGetUspsPackageTypes = () => {
  const {data, ...getUpsPackageTypesQuery} = useQuery([USPS_PACKAGE_TYPES], () => fetchUspsPackageTypes(), {
    staleTime: Infinity,
    cacheTime: Infinity
  });

  const uspsPackageTypes = data?.reduce(
    (types, type) => ({...types, [type.id as string]: `USPS ${type.label as string}`}),
    {}
  );

  return {uspsPackageTypes, getUpsPackageTypesQuery};
};
