import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import * as actions from 'App/actions/auth';
import * as userActions from 'App/actions/users';
import * as brokerActions from 'App/actions/brokers';
import NewAuthResetForm from 'App/formComponents/forms/newAuthReset';
import './styles.scss';

@connect(
  (state) => ({
    brokerLogos: state.brokers.brokerLogos,
    error: state.users.error
  }),
  {...brokerActions, ...actions, ...userActions}
)
export default class AuthReset extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state = {
      error: {}
    };
  }

  handleSuccess() {
    if (!this.props.error) {
      setTimeout(
        function () {
          this.context.router.push('/');
        }.bind(this),
        2000
      );
    }
  }

  onFormSubmit(values, {setSubmitting}) {
    setSubmitting(true);
    this.setState({error: {}});
    this.props
      .setResetPassword({
        token: this.props.location.query.token,
        password: values.password
      })
      .then((response) => {
        if (response.status === 200) {
          this.handleSuccess();
        } else {
          setSubmitting(false);
          const errorMessage = {};
          if (response.field_errors) {
            errorMessage.error = response.field_errors[0].field_name + ': ' + response.field_errors[0].field_errors[0];
          } else {
            errorMessage.password = response.error_description[0];
          }
          this.setState({
            error: errorMessage
          });
        }
      });
  }

  render() {
    let stackedColorLogo = '';
    if (this.props.brokerLogos && this.props.brokerLogos.length > 0) {
      for (var i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'INLINE_COLOR') {
          stackedColorLogo = this.props.brokerLogos[i];
        }
      }
    }

    return (
      <div className="new-auth-reset-container">
        <div className="new-auth-reset-wrapper">
          <div className="new-auth-reset-logo">
            {this.props.brokerLogos.length > 0 && (
              <img
                src={stackedColorLogo.logo + '?cachebust=' + new Date().getTime()}
                height="50%"
                width="50%"
                style={{marginBottom: '32px'}}
              />
            )}
          </div>
          <div className="new-auth-reset-body">
            <NewAuthResetForm onSubmit={this.onFormSubmit} submitErrorMessage={this.state.error} />
            <div className="new-auth-reset-sign-in-wrapper">
              <Link to="/">
                <span className="new-auth-reset-sign-in-text">Sign In</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="new-pw-footer">2016-{new Date().getFullYear()} Shipwell</div>
      </div>
    );
  }
}
