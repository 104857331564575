import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Address Book - API
 * @deprecated use `./typed/getAddressBookAddresses`
 */
export async function getAddressBookPromise(opts = {}) {
  // this cannot be removed until src/app/formComponents/fields/globalSearch/index.js is refactored
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.addressBookGet(opts, apiCallback(resolve, reject));
  });
}
