import {useState, useEffect} from 'react';
import {getShipwellUiUserPreferencesPromise, editShipwellUiUserPreferencesPromise} from 'App/api/shipwellUI';
import {defaultTableMeta} from 'App/utils/tableUtils';

/**
 * The useUserPageSize hook is used to fetch and track of the prefered user page size of the current table
 * @param {*} tableType
 */

export const useUserPageSize = (tableType) => {
  const [userPageSize, setUserPageSize] = useState(null);

  useEffect(() => {
    const fetchUserPageSize = async () => {
      try {
        const res = await getShipwellUiUserPreferencesPromise();
        setUserPageSize(res.body.table_row_defaults?.[tableType] ?? defaultTableMeta.pageSize);
        return res.body.table_row_defaults?.[tableType];
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserPageSize();
  }, [tableType]);

  return userPageSize;
};

export const useUpdateUserTableRowDefaults = (tableType) => {
  const [userTableRowDefaults, setUserTableRowDefaults] = useState({});

  useEffect(() => {
    const fetchUserTableRowDefaults = async () => {
      try {
        const res = await getShipwellUiUserPreferencesPromise();
        setUserTableRowDefaults(res.body.table_row_defaults);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserTableRowDefaults();
  }, []);

  const updateUserTableRowDefaults = async (pageSize) => {
    try {
      const newTableSize = {[tableType]: pageSize};
      const body = {table_row_defaults: {...userTableRowDefaults, ...newTableSize}};
      const res = await editShipwellUiUserPreferencesPromise(body);
      setUserTableRowDefaults(res.body.table_row_defaults);
    } catch (error) {
      console.error(error);
    }
  };

  return updateUserTableRowDefaults;
};
