import {useQuery} from '@tanstack/react-query';
import {fetchShipmentServiceLevels} from 'App/api/shipment/typed';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {SERVICES_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useShipmentServiceLevels = () => {
  const getShipmentServiceLevelsQuery = useQuery(
    [SERVICES_QUERY_KEY],
    async () => {
      const response = await fetchShipmentServiceLevels();
      return response.data;
    },
    {staleTime: STATIC_DATA_STALE_TIME}
  );
  return getShipmentServiceLevelsQuery;
};
