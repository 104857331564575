import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AirMovement} from '@shipwell/locus-sdk';
import React from 'react';

interface MovementDetailsTitleProps {
  shipment: Shipment;
  movementDetails: AirMovement[];
}

const airMovementTypeToLabelMap: Record<string, string> = {
  DOOR_TO_DOOR: 'Door to Door',
  PORT_TO_PORT: 'Airport to Airport',
  DOOR_TO_PORT: 'Door to Airport',
  PORT_TO_DOOR: 'Airport to Door',
  '': 'Unknown'
};

export const MovementDetailsTitle: React.FC<MovementDetailsTitleProps> = ({shipment, movementDetails}) => {
  const isAir = shipment?.mode?.code === 'AIR';

  const airMovementTypeLabel = airMovementTypeToLabelMap[shipment?.movement_type || ''];
  const oceanMovementTypeLabel = 'ocean';

  if (!isAir) {
    return (
      <div className="flex items-center py-4">
        <div className="flex items-baseline">
          <span>Movement Details</span>
          <span className="text-grey-5 max-w-tag truncate text-xxs font-normal uppercase">
            <span className="mx-1">•</span>
            {oceanMovementTypeLabel}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center py-4">
      <div className="flex items-baseline">
        <span>Movement Details</span>
        <span className="text-grey-5 truncate text-xxs font-normal uppercase">
          <span className="mx-1">•</span>
          {shipment?.mode?.description}
          <span className="mx-1">•</span>
          {airMovementTypeLabel}
          {!!movementDetails.length && (
            <>
              <span className="mx-1">•</span>
              {`${movementDetails.length} flights`}
            </>
          )}
        </span>
      </div>
    </div>
  );
};
