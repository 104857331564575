import {Configuration, CapacitySearchApi, CapacitySearchRequest} from '@shipwell/tabula-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_SHIPMENT_ASSEMBLY_API_BASE_PATH;

const capacitySearchApi = () => {
  return new CapacitySearchApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
};

// Capacity Search
export function createCapacitySearch(capacitySearchRequest: CapacitySearchRequest) {
  return capacitySearchApi().capacitySearchMultipleLoadboardsCapacitySearchPost(capacitySearchRequest);
}

type CapacitySearchGetParameters = Parameters<
  typeof CapacitySearchApi.prototype.capacitySearchMultipleRetrieveLoadboardsCapacitySearchInternalQueryIdGet
>;
export type CapacitySearchGetNamedParameters = {
  queryId: CapacitySearchGetParameters[0];
  page?: CapacitySearchGetParameters[1];
  pageSize?: CapacitySearchGetParameters[2];
  inNetwork?: CapacitySearchGetParameters[3];
  outNetwork?: CapacitySearchGetParameters[4];
  sources?: CapacitySearchGetParameters[5];
  originDistance?: CapacitySearchGetParameters[6];
  destinationDistance?: CapacitySearchGetParameters[7];
  equipmentType?: CapacitySearchGetParameters[8];
  age?: CapacitySearchGetParameters[9];
  earliest?: CapacitySearchGetParameters[10];
  latest?: CapacitySearchGetParameters[11];
};

// Capacity Search Retrieve
export function getCapacitySearch(opts: CapacitySearchGetNamedParameters) {
  return capacitySearchApi().capacitySearchMultipleRetrieveLoadboardsCapacitySearchInternalQueryIdGet(
    opts.queryId,
    opts?.page,
    opts?.pageSize,
    opts?.inNetwork,
    opts?.outNetwork,
    opts?.sources,
    opts?.originDistance,
    opts?.destinationDistance,
    opts?.equipmentType,
    opts?.age,
    opts?.earliest,
    opts?.latest
  );
}

export function getCapacitySearchCarrierDetails(matchId: string) {
  return capacitySearchApi().capacitySearchDetailsLoadboardsCapacitySearchDetailsMatchIdGet(matchId);
}
