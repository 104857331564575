import {useState} from 'react';
import {FreightInvoiceDocument} from '@shipwell/settlements-sdk';
import {Button, Modal, TypedFileDisplay} from '@shipwell/shipwell-ui';
import {FormikHelpers} from 'formik';
import classNames from 'classnames';
import {isFile} from '../../../FreightInvoiceForm/utils';
import {sortDisplayDocuments} from './utils';
import Loader from 'App/common/shipwellLoader';
import {getDocumentTypesMap, useDocumentTypes, useUserPermissions} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import NewDocumentForm from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/NewDocumentForm';
import {FreightInvoiceShipmentDocument} from 'App/containers/settlements/freightInvoices/types';
import {useCreateFreightInvoiceDocument, useUpdateFreightInvoiceDocument} from 'App/api/settlements/queryHooks';
import {UPDATE_FREIGHT_INVOICES_PERMISSION} from 'App/containers/settlements/freightInvoices/permissionsConstants';
import {
  DocumentSelectEditFormValues,
  TypedDocumentSelect
} from 'App/formComponents/fields/documentSelect/TypedDocumentSelect';
import {getLastUrlSegment} from 'App/utils/fileUtilsTyped';
import {isSettlementsDocumentType} from 'App/api/settlements/typeGuards/FreightInvoiceDocumentsGuards';
import {parseV3ApiError} from 'App/api/typedUtils';
import withStatusToasts from 'App/components/withStatusToasts';

type SettlementDocumentsModalProps = {
  documents: FreightInvoiceDocument[] | undefined;
  isLoading?: boolean;
  show: boolean;
  onClose: () => void;
  invoiceId: string;
};

export const SettlementDocumentsModal = withStatusToasts<SettlementDocumentsModalProps>(
  ({documents, isLoading = false, show, onClose, invoiceId, setError}) => {
    const documentTypes = getDocumentTypesMap(useDocumentTypes().data);
    const sortedDocuments = sortDisplayDocuments(documents, documentTypes);
    const [selectedDocuments, setSelectedDocuments] = useState<FreightInvoiceDocument[] | undefined>();

    const [previewSelection, setPreviewSelection] = useState<FreightInvoiceDocument>();
    const previewDoc = previewSelection || sortedDocuments?.[0];
    const [showUploadDocumentModal, toggleShowUploadDocumentModal] = useToggle();
    const hasUpdateInvoicePermission = useUserPermissions([UPDATE_FREIGHT_INVOICES_PERMISSION]);
    const [editId, setEditId] = useState<string | null>(null);

    const {mutate: createFreightInvoice} = useCreateFreightInvoiceDocument(invoiceId);
    const handleSubmitNewDocument = (
      newDocument: FreightInvoiceShipmentDocument,
      {setSubmitting}: FormikHelpers<FreightInvoiceShipmentDocument>
    ) => {
      if (!newDocument.file || !isFile(newDocument.file) || !newDocument.document_type || !newDocument.description) {
        throw new Error('Missing required document submission properties');
      }
      createFreightInvoice(
        {
          documents: [newDocument.file],
          documentsMetadata: {
            document_metadata: [{document_type: newDocument.document_type, description: newDocument.description}]
          }
        },
        {
          onSettled: () => {
            setSubmitting(false);
            toggleShowUploadDocumentModal();
          }
        }
      );
    };

    const {mutate: updateDocument, isLoading: isUpdatingDocument} = useUpdateFreightInvoiceDocument(invoiceId);
    const handleUpdateDocument = ({id, document_type, description}: DocumentSelectEditFormValues) => {
      if (isSettlementsDocumentType(document_type))
        updateDocument(
          {documentId: id, documentMetadata: {document_type, description}},
          {onSuccess: () => setEditId(null), onError: (error) => setError('Error!', parseV3ApiError(error).detail)}
        );
    };

    return (
      <Modal
        title="Settlement Documents"
        className="h-screen-85"
        show={show}
        onClose={onClose}
        size="large"
        footerComponent={null}
      >
        <div className="flex h-full flex-col">
          <div className="mb-6 grow gap-6">
            {isLoading ? (
              <Loader loading />
            ) : sortedDocuments === undefined ? (
              <div className="flex size-full items-center justify-center text-center text-xl text-sw-disabled">
                No documents found
              </div>
            ) : (
              <div className="grid min-h-full grid-cols-[33%_auto] gap-8">
                <aside>
                  <TypedDocumentSelect
                    documents={sortedDocuments}
                    selectedDocuments={selectedDocuments}
                    onSelectedDocumentsChange={setSelectedDocuments}
                    editId={editId}
                    activeDocumentId={previewDoc?.id}
                    onDocumentClick={(document) => setPreviewSelection(document)}
                    onEdit={(id) => {
                      setPreviewSelection(sortedDocuments.find((doc) => doc.id === id));
                      setEditId(id);
                    }}
                    onEditCancel={() => setEditId(null)}
                    onEditSave={handleUpdateDocument}
                    isSaving={isUpdatingDocument}
                  />
                </aside>
                <main>
                  {previewDoc ? (
                    <div className={classNames('h-full', {'pointer-events-none': showUploadDocumentModal})}>
                      <TypedFileDisplay
                        fileURL={previewDoc.public_url}
                        fileName={previewDoc.public_url ? getLastUrlSegment(previewDoc.public_url) : undefined}
                        enableToolbar
                        altText={previewDoc.document_type ? documentTypes?.[previewDoc.document_type] : undefined}
                      />
                    </div>
                  ) : null}
                </main>
              </div>
            )}
          </div>
          <div className="-m-6 flex justify-between p-6">
            {hasUpdateInvoicePermission ? (
              <Button variant="tertiary" onClick={toggleShowUploadDocumentModal}>
                Upload Document
              </Button>
            ) : null}
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
        <Modal
          title="Upload Document"
          show={showUploadDocumentModal}
          onClose={toggleShowUploadDocumentModal}
          footerComponent={null}
        >
          <NewDocumentForm onCancel={toggleShowUploadDocumentModal} onSubmit={handleSubmitNewDocument} />
        </Modal>
      </Modal>
    );
  }
);
