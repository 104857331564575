import {
  DrayageLegOverallStatus,
  StopExecutionStatus,
  UpdateDrayageBookingSummary,
  LegsDrayageApi
} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';

// Legs Drayage API
const legsDrayageApi = new LegsDrayageApi(configuration);

export function getDrayageLeg(legId: string) {
  return legsDrayageApi.getDrayageBookingDetailLegsDrayageDrayageBookingIdGet({drayageBookingId: legId});
}

export function getDrayageLegDocuments(legId: string) {
  return legsDrayageApi.getDrayageLegDocumentsLegsDrayageDrayageBookingIdDocumentsGet({drayageBookingId: legId});
}

export function uploadDrayageLegDocument(legId: string, file: File, description: string, type: string) {
  return legsDrayageApi.uploadDrayageLegDocumentsLegsDrayageDrayageBookingIdDocumentsPost({
    drayageBookingId: legId,
    file,
    description,
    type
  });
}

export function updateDrayageLegDocument(legId: string, documentId: string, description: string, type: string) {
  return legsDrayageApi.updateDrayageLegDocumentLegsDrayageDrayageBookingIdDocumentsDocumentIdPut({
    drayageBookingId: legId,
    documentId,
    description,
    type
  });
}

export function deleteDrayageLegDocument(legId: string, documentId: string) {
  return legsDrayageApi.deleteDrayageLegDocumentLegsDrayageDrayageBookingIdDocumentsDocumentIdDelete({
    drayageBookingId: legId,
    documentId
  });
}

function updateLegExecutionStatusAtDelivery(legId: string) {
  return legsDrayageApi.legExecutionStatusAtDeliveryLegsDrayageDrayageBookingIdExecutionStatusesAtDeliveryPost({
    drayageBookingId: legId
  });
}

function updateLegExecutionStatusAtPickup(legId: string) {
  return legsDrayageApi.legExecutionStatusAtPickupLegsDrayageDrayageBookingIdExecutionStatusesAtPickupPost({
    drayageBookingId: legId
  });
}

function updateLegExecutionStatusDelivered(legId: string) {
  return legsDrayageApi.legExecutionStatusDeliveredLegsDrayageDrayageBookingIdExecutionStatusesDeliveredPost({
    drayageBookingId: legId
  });
}

function updateLegExecutionStatusDispatched(legId: string) {
  return legsDrayageApi.legExecutionStatusDispatchedLegsDrayageDrayageBookingIdExecutionStatusesDispatchedPost({
    drayageBookingId: legId
  });
}

function updateLegExecutionStatusInTransit(legId: string) {
  return legsDrayageApi.legExecutionStatusInTransitLegsDrayageDrayageBookingIdExecutionStatusesInTransitPost({
    drayageBookingId: legId
  });
}

function updateLegExecutionStatusContainerReturned(legId: string, inGate?: Date) {
  const payload = {
    in_gate: {
      processing: {
        end: inGate ? inGate.toISOString() : new Date().toISOString()
      }
    }
  };
  return legsDrayageApi.legExecutionStatusContainerReturnedLegsDrayageDrayageBookingIdExecutionStatusesContainerReturnedPost(
    {drayageBookingId: legId, containerReturnRequest: payload}
  );
}

function resetLegExecutionStatus(legId: string) {
  return legsDrayageApi.legExecutionStatusResetLegsDrayageDrayageBookingIdExecutionStatusesResetPost({
    drayageBookingId: legId
  });
}

export function setLegExecutionStatus(legId: string, status: DrayageLegOverallStatus, inGate?: Date) {
  switch (status) {
    case DrayageLegOverallStatus.CarrierConfirmed:
      return resetLegExecutionStatus(legId);
    case DrayageLegOverallStatus.AtDelivery:
      return updateLegExecutionStatusAtDelivery(legId);
    case DrayageLegOverallStatus.AtPickup:
      return updateLegExecutionStatusAtPickup(legId);
    case DrayageLegOverallStatus.InTransit:
      return updateLegExecutionStatusInTransit(legId);
    case DrayageLegOverallStatus.Dispatched:
      return updateLegExecutionStatusDispatched(legId);
    case DrayageLegOverallStatus.Delivered:
      return updateLegExecutionStatusDelivered(legId);
    case DrayageLegOverallStatus.ContainerReturned:
      return updateLegExecutionStatusContainerReturned(legId, inGate);
    default:
      return;
  }
}

export function cancelDrayageLeg(legId: string) {
  return legsDrayageApi.cancelDrayageBookingLegsDrayageDrayageBookingIdCancelPost({drayageBookingId: legId});
}

function updateStopExecutionStatusArrive(legId: string, stopNumber: number) {
  return legsDrayageApi.stopExecutionStatusArriveLegsDrayageDrayageBookingIdStopsSequenceIdExecutionStatusesArrivePost({
    drayageBookingId: legId,
    sequenceId: stopNumber,
    stopArriveRequest: {arrived_at: undefined}
  });
}

function updateStopExecutionStatusDepart(legId: string, stopNumber: number) {
  return legsDrayageApi.stopExecutionStatusDepartLegsDrayageDrayageBookingIdStopsSequenceIdExecutionStatusesDepartPost({
    drayageBookingId: legId,
    sequenceId: stopNumber,
    stopDepartRequest: {departed_at: undefined}
  });
}

function updateStopExecutionStatusEnRoute(legId: string, stopNumber: number) {
  return legsDrayageApi.stopExecutionStatusEnRouteLegsDrayageDrayageBookingIdStopsSequenceIdExecutionStatusesEnRoutePost(
    {drayageBookingId: legId, sequenceId: stopNumber}
  );
}

function updateStopExecutionStatusPending(legId: string, stopNumber: number) {
  return legsDrayageApi.stopExecutionStatusResetLegsDrayageDrayageBookingIdStopsSequenceIdExecutionStatusesResetPost({
    drayageBookingId: legId,
    sequenceId: stopNumber
  });
}

export function setStopExecutionStatus(legId: string, stopNumber: number, status: StopExecutionStatus) {
  switch (status) {
    case StopExecutionStatus.AtStop:
      return updateStopExecutionStatusArrive(legId, stopNumber);
    case StopExecutionStatus.Departed:
      return updateStopExecutionStatusDepart(legId, stopNumber);
    case StopExecutionStatus.EnRoute:
      return updateStopExecutionStatusEnRoute(legId, stopNumber);
    case StopExecutionStatus.Pending:
    default:
      return updateStopExecutionStatusPending(legId, stopNumber);
  }
}

export function updateDrayageLegSummary(legId: string, data: UpdateDrayageBookingSummary) {
  return legsDrayageApi.updateDrayageBookingSummaryLegsDrayageDrayageBookingIdSummaryPut({
    drayageBookingId: legId,
    updateDrayageBookingSummary: data
  });
}
