import {useMutation, MutationOptions} from '@tanstack/react-query';
import {Contract, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {createCarrierContract, updateCarrierContract} from 'App/api/contracts/typed';

export const useCreateUpdateContract = () => {
  const {mutateAsync: createContractAsync} = useMutation(
    ({
      contract,
      carrierRelationshipId
    }: {carrierRelationshipId: string; contract: Contract} & MutationOptions<
      Contract,
      ShipwellError | undefined,
      {contract: Contract}
    >) => createCarrierContract({carrierRelationshipId, contract}),
    {
      onError: ({response}: AxiosError<ShipwellError>, variables, context) =>
        variables.onError?.(response?.data, variables, context),
      onSuccess: (data, variables, context) => variables.onSuccess?.(data, variables, context)
    }
  );

  const {mutateAsync: updateContractAsync} = useMutation(
    ({
      contractId,
      contract,
      carrierRelationshipId
    }: {contractId: string; contract: Contract; carrierRelationshipId: string} & MutationOptions<
      Contract,
      ShipwellError | undefined,
      {contractId: string; contract: Contract}
    >) => updateCarrierContract({carrierRelationshipId, contractId, contract}),
    {
      onError: ({response}: AxiosError<ShipwellError>, variables, context) =>
        variables.onError?.(response?.data, variables, context),
      onSuccess: (data, variables, context) => variables.onSuccess?.(data, variables, context)
    }
  );

  return {createContractAsync, updateContractAsync};
};
