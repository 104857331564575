import PropTypes from 'prop-types';
import {useState, useCallback, useEffect} from 'react';
import {Formik, Form} from 'formik';
import {Loader} from '@shipwell/shipwell-ui';
import {sortAlerts} from './compassAlertsConstants';
import CompassAlertsCard from './components/compassAlertsCard/index';
import FormFooter from 'App/formComponents/formSections/formFooter';
import PageHeader from 'App/common/pageHeader';
import withStatusToasts from 'App/components/withStatusToasts';
import {getAlertConfigurationOptions, getAlertsConfiguration, updateAlertConfigurations} from 'App/api/shipwellUI';

const CompanyCompassAlertsConfiguration = ({setSuccess, setError}) => {
  const [alerts, setAlerts] = useState([]);
  const [alertTypes, setAlertTypes] = useState([]);
  const [isLoadingAlertTypes, setIsLoadingAlertTypes] = useState(false);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);

  useEffect(() => {
    fetchAlertTypes();
  }, [fetchAlertTypes]);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const fetchAlertTypes = useCallback(async () => {
    setIsLoadingAlertTypes(true);
    try {
      const alertConfigurationResponse = await getAlertConfigurationOptions();
      setAlertTypes(alertConfigurationResponse.body);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingAlertTypes(false);
  }, []);

  const fetchAlerts = useCallback(async () => {
    setIsLoadingAlerts(true);
    try {
      const alertsResponse = await getAlertsConfiguration();
      setAlerts(sortAlerts(alertsResponse.body?.results));
    } catch (error) {
      console.error(error);
    }
    setIsLoadingAlerts(false);
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const modifiedAlerts = alerts
          .filter((alert) => values[alert.id] !== alert.enabled)
          .map((alert) => ({...alert, enabled: !alert.enabled}));
        await updateAlertConfigurations(modifiedAlerts);

        await fetchAlerts();
        setSuccess('Changes Saved!', 'Your alert tile configuration was saved successfully.');
      } catch (error) {
        console.error(error);
        setError('Something Went Wrong', 'Your alert tile configuration was not saved successfully. Try Again.');
      }
    },
    [fetchAlerts, alerts, setSuccess, setError]
  );

  const handleSuccess = ({title, message}) => {
    setSuccess(title, message);
  };

  const handleError = ({title, message}) => {
    setError(title, message);
  };

  return (
    <div className="w-full">
      {!isLoadingAlertTypes && !isLoadingAlerts && (
        <Formik
          initialValues={Object.fromEntries(alerts.map((alert) => [alert.id, alert.enabled]))}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({dirty, resetForm}) => (
            <Form>
              <div>
                <PageHeader title="Compass Alerts Configuration" />
              </div>
              <div className="p-6">
                <CompassAlertsCard
                  alerts={alerts}
                  alertTypes={alertTypes}
                  fetchAlerts={fetchAlerts}
                  onSuccess={handleSuccess}
                  onError={handleError}
                />
              </div>
              {dirty && <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} />}
            </Form>
          )}
        </Formik>
      )}
      <Loader loading={isLoadingAlertTypes || isLoadingAlerts} />
    </div>
  );
};

CompanyCompassAlertsConfiguration.propTypes = {
  setError: PropTypes.func,
  setSuccess: PropTypes.func
};

export default withStatusToasts(CompanyCompassAlertsConfiguration);
