import {
  AUTH_USER,
  SYSTEM_VERSION_OUT_OF_DATE,
  SYSTEM_VERSION,
  EDIT_USER,
  EDIT_COMPANY,
  TRIGGER_MARKETPLACE_LOGIN,
  FETCH_MESSAGE,
  FETCH_USER,
  RESET_ERROR,
  SIGNIN_ERROR,
  UNAUTH_USER,
  USER_NAME,
  VERIFY_USER,
  VERIFY_EMAIL
} from '../actions/types';

export const INITIAL_STATE = {
  details: null,
  email: null,
  phone: null,
  is_active: false,
  is_verified: false,
  error: false,
  isVerifying: false,
  name: null,
  user: {},
  company: {},
  marketplaceLogin: false,
  marketplaceUser: null,
  systemVersion: null,
  systemVersionOutOfDate: false,
  authenticated: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        error: false,
        authenticated: true,
        isVerifying: false,
        user: action.payload.user,
        company: action.payload.company,
        email: action.payload.primary_email,
        phone: action.payload.primary_phone_number,
        permissions: action.payload.user && action.payload.user.permissions,
        is_quoting_limited_user: action.payload.user && action.payload.user.is_quoting_limited_user
      };

    case SYSTEM_VERSION_OUT_OF_DATE:
      return {
        ...state,
        systemVersionOutOfDate: action.payload
      };

    case SYSTEM_VERSION:
      return {
        ...state,
        systemVersion: action.payload
      };

    case EDIT_USER:
      return {
        ...state,
        user: action.payload
      };

    case EDIT_COMPANY:
      return {
        ...state,
        company: action.payload
      };

    case TRIGGER_MARKETPLACE_LOGIN:
      return {
        ...state,
        marketplaceLogin: action.payload.bool,
        marketplaceUser: action.payload.email
      };

    case FETCH_MESSAGE:
      return {
        ...state,
        message: action.payload
      };

    case FETCH_USER:
      return {
        ...state,
        user: action.payload.user
      };

    case RESET_ERROR:
      return {
        ...state,
        error: false,
        details: null
      };

    case SIGNIN_ERROR:
      return {
        ...state,
        error: true,
        isVerifying: false,
        // only show error title for now
        // details: action.payload.response.body.errors[0],
        details: action.payload.error_description
      };

    case UNAUTH_USER:
      //added this line to remove info from local storage when user logs out;
      state = undefined;
      return {
        ...state,
        authenticated: false
      };

    case USER_NAME:
      return {
        ...state,
        name: action.meta
      };

    case VERIFY_USER:
      return {
        ...state,
        isVerifying: true,
        error: false
      };

    case VERIFY_EMAIL:
      return {
        ...state,
        email: action.payload.email,
        phone: action.payload.phone_number,
        is_active: action.payload.is_active,
        is_verified: true
      };
  }
  return state;
}
