import {useState, useEffect, useRef} from 'react';
import {getShipmentStopStatusReasonCodes} from 'App/api/shipment';
import formatGroupedOptions from 'App/utils/formatGroupedOptions';

const defaultLabelOverrides = {NORMAL_STATUSES: 'Normal'};

function useGroupedStopStatusReasonCodes(filterGroups = [], labelOverridesMap = defaultLabelOverrides) {
  const [groupedStopStatusReasonCodes, setGroupedStopStatusReasonCodes] = useState([]);

  const filterGroupsRef = useRef(filterGroups);
  const labelOverridesMapRef = useRef(labelOverridesMap);

  useEffect(() => {
    const fetchStopStatusReasonCodes = async () => {
      try {
        const response = await getShipmentStopStatusReasonCodes();
        const grouped = formatGroupedOptions(response.body, filterGroupsRef.current, labelOverridesMapRef.current);
        setGroupedStopStatusReasonCodes(grouped);
      } catch (error) {
        console.error('Error retrieving stop status reason codes', error);
      }
    };
    fetchStopStatusReasonCodes();
  }, [filterGroupsRef, labelOverridesMapRef]);

  return groupedStopStatusReasonCodes;
}

export default useGroupedStopStatusReasonCodes;
