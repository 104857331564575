export const calculateGrandTotal = (items = []) => {
  const rawTotal = items.reduce((total, item) => {
    return total + (item.unit_amount * item.unit_quantity - item.prepaid_amount) || total;
  }, 0);

  if (rawTotal) {
    return parseFloat(rawTotal).toFixed(2);
  }
  return 0;
};
