import PropTypes from 'prop-types';
import {Field, useFormikContext} from 'formik';
import {Card, DeprecatedButton, Title, DisplayValue, Flex, SvgIcon, FormikDateTimePicker} from '@shipwell/shipwell-ui';
import './styles.scss';

const CardContentRow = (props) => {
  const {name, order, index, stopType = '', address = {}} = props;
  const plannedStartKey = `planned_${stopType.toLowerCase()}_start_datetime`;
  const plannedEndKey = `planned_${stopType.toLowerCase()}_end_datetime`;
  const {setFieldValue} = useFormikContext();
  return (
    <Flex flexDirection="column">
      <Title variant="sectionTitle">{stopType}</Title>
      <Flex className="order-card__row" justifyContent="space-between">
        <DisplayValue variant="sectionTitle" label="Address">
          <Flex flexDirection="column">
            <div>{address.address_1 ?? ''}</div>
            <div>{`${address.city ? `${address.city},` : ''} ${address.state_province ?? ''} ${
              address.postal_code ?? ''
            }`}</div>
          </Flex>
        </DisplayValue>

        <Flex className="order-card__icon-date-wrapper">
          <Flex className="icon-wrapper">
            <SvgIcon name="Calendar" />
          </Flex>

          <Field
            required
            name={`${name}[${index}].${plannedStartKey}`}
            component={FormikDateTimePicker}
            label={`${stopType} Date`}
            onChange={(date) => {
              setFieldValue(`${name}[${index}].${plannedStartKey}`, date);
              setFieldValue(`${name}[${index}].${plannedEndKey}`, date);
            }}
            defaultValue={order[plannedStartKey]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

CardContentRow.propTypes = {
  name: PropTypes.string,
  order: PropTypes.object,
  index: PropTypes.number,
  stopType: PropTypes.string,
  address: PropTypes.object
};

const OrderCard = (props) => {
  const {name, order = {}, index, removeAction = () => {}} = props;

  return (
    <Card
      className="order-card"
      bodyClassName="order-card__body"
      title={`Order ${order?.order_number}${order?.name ? ` - ${order?.name}` : ''}`}
      actions={
        <DeprecatedButton variant="tertiary" onClick={() => removeAction(index)}>
          Remove Order
        </DeprecatedButton>
      }
    >
      <Flex className="order-card__row-wrapper" flexDirection="column">
        <CardContentRow name={name} order={order} index={index} stopType="Pickup" address={order?.origin_address} />
        <CardContentRow
          name={name}
          order={order}
          index={index}
          stopType="Delivery"
          address={order?.destination_address}
        />
      </Flex>
    </Card>
  );
};

OrderCard.propTypes = {
  name: PropTypes.string,
  order: PropTypes.object,
  index: PropTypes.number,
  removeAction: PropTypes.func
};

export default OrderCard;
