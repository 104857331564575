import {User} from '@shipwell/backend-core-sdk';

export const getReadableCreatedByString = (createdByUser?: Partial<User> | null, createdBySource?: string | null) => {
  const {first_name = '', last_name = ''} = createdByUser || {};

  return first_name && last_name
    ? `${first_name} ${last_name}`
    : createdBySource && SOURCES[createdBySource]
    ? SOURCES[createdBySource]
    : '';
};

export const SOURCES: Record<string, string> = {
  MAGENTO: 'Magento',
  SHIPWELL_WEB: 'Shipwell Web',
  IMPORT: 'Import',
  TENDER: 'EDI',
  SHIPWELL_WEB_UNAUTH: 'Shipment Request'
};

export const getCreatorFilterQueryForOrders = (filterValues: string | string[] = []) => {
  filterValues = typeof filterValues === 'string' ? [filterValues] : filterValues;

  return filterValues.reduce<{source: string[]; createdByUserId: string[]}>(
    (queryParams, filterVal) => {
      if (SOURCES[filterVal]) {
        queryParams.source = [...queryParams.source, filterVal];
      } else {
        queryParams.createdByUserId = [...queryParams.createdByUserId, filterVal];
      }
      return queryParams;
    },
    {source: [], createdByUserId: []}
  );
};
