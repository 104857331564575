import queryString from 'query-string';

/**
 * Build path params to be added to react router
 * @param {Obj} location route information
 * @param {Obj} params params to be added
 */
export default function buildPathParams(location, params) {
  const {pathname = '/', query, search} = location;
  const queryObject = query || queryString.parse(search);
  return `${pathname}?${queryString.stringify({...queryObject, ...params})}`;
}
