import PropTypes from 'prop-types';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import './styles.scss';

const LoginErrorPage = ({companyName, callback, message, buttonLabel}) => {
  return (
    <div className="auth0signin__container">
      <div className="auth0signin__wrapper">
        <div className="header">
          <strong>{`Welcome to ${companyName}!`}</strong>
        </div>
        <div className="body">{message}</div>
        <DeprecatedButton className="button" variant="primary" onClick={callback}>
          {buttonLabel}
        </DeprecatedButton>
      </div>
    </div>
  );
};

LoginErrorPage.propTypes = {
  companyName: PropTypes.string,
  callback: PropTypes.func,
  message: PropTypes.string,
  buttonLabel: PropTypes.string
};

LoginErrorPage.defaultProps = {
  companyName: 'Shipwell',
  callback: () => {},
  message: 'We had trouble connecting you through our secure login portal. Please refresh the page to try again.',
  buttonLabel: 'Refresh'
};

export default LoginErrorPage;
