import {Component} from 'react';
import {connect} from 'react-redux';
import Profile from './Profile';
import Exports from './Exports';
import Integrations from './Integrations';
import Billing from './Billing';
import Security from './Security';
import Notifications from './Notifications';
import PasswordForm from './forms/PasswordForm';
import {basePath} from 'App/api/config';
import './_settings.scss';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company
  }),
  {}
)
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'profile',
      currentPass: '',
      newPass: '',
      updated: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      showPasswordModal: false,
      viewPasswordNew: false,
      viewPasswordCurrent: false,
      requirements: {length: false, capital: false, special: false},
      success: false,
      done: false
    };
  }

  componentWillMount() {}

  render() {
    let isProd = false;
    if (basePath.startsWith('https://api.shipwell.com/')) {
      isProd = true;
    }

    let is_quoting_limited_user = false;
    if (this.props.user && this.props.user.is_quoting_limited_user) {
      is_quoting_limited_user = true;
    }
    return (
      <div className="settings content-wrapper">
        <section className="content">
          <div className="quote-flow__results-toggleBar">
            <span
              className={this.state.tab === 'profile' ? 'h2 activeTab' : 'h2'}
              onClick={() => {
                this.setState({tab: 'profile'});
              }}
            >
              Profile
            </span>
            {!is_quoting_limited_user && (
              <span
                className={this.state.tab === 'notifications' ? 'h2 activeTab' : 'h2'}
                onClick={() => {
                  this.setState({tab: 'notifications'});
                }}
              >
                Notifications
              </span>
            )}
            <span
              className={this.state.tab === 'security' ? 'h2 activeTab' : 'h2'}
              onClick={() => {
                this.setState({tab: 'security'});
              }}
            >
              Security
            </span>
          </div>
          {this.state.tab === 'profile' && <Profile />}
          {this.state.tab === 'security' && <Security />}
          {this.state.tab === 'notifications' && <Notifications />}
          {/*{!isProd && <Integrations />}
                    {!isProd && <Exports />}*/}
        </section>
      </div>
    );
  }
}
