import {useQuery} from '@tanstack/react-query';
import {Quote, RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useState} from 'react';
import {getRfqDetails} from '../typed';
import {useUserMe} from 'App/data-hooks';

export const useGetRfqDetails = ({rfq, enabled}: {rfq: RFQ; enabled?: boolean}) => {
  const {company} = useUserMe();

  const [quotes, setQuotes] = useState<Record<string, Quote[]>>({});

  const sortParcelQuotes = (quotes: Quote[] = []) => {
    const withKeysQuotes: Record<string, Quote[]> = {
      fedex: [],
      ups: [],
      usps: []
    };
    // Here we need additional check for display_name, since for failed quotes [provider]_direct_quote will be always null
    // and without this check FE will not show quotes with errors
    quotes.forEach((q) => {
      if (q.fedex_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'fedex') {
        withKeysQuotes.fedex.push(q);
      }
      if (q.ups_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'ups') {
        withKeysQuotes.ups.push(q);
      }
      if (q.usps_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'usps') {
        withKeysQuotes.usps.push(q);
      }
    });

    const sortedQuotes = Object.keys(withKeysQuotes)
      .map((carrier) => {
        return {
          [carrier]: withKeysQuotes[carrier].length
            ? withKeysQuotes[carrier].map((quote) => ({
                ...quote,
                // setting this to undefined since the failed quotes should be at the end.
                // the backend is returning 0 for failed quotes, so it's not possible to sort them correctly
                total: quote.is_failure ? undefined : quote.total
              }))
            : []
        };
      })
      .reduce((quotesObj, quotesByCarrier) => {
        return {...quotesObj, ...quotesByCarrier};
      }, {});

    return sortedQuotes;
  };

  const getRfqDetailsQuery = useQuery({
    queryKey: ['getRfqDetails', rfq?.id],
    queryFn: async () => {
      const xCompanyId = rfq.company_owner_id !== company?.id ? rfq.company_owner_id : null;

      const response = await getRfqDetails({
        rfqId: rfq.id as string,
        includeFailures: true,
        xCompanyId: xCompanyId as string
      });

      return response.data;
    },
    enabled,
    refetchInterval: (data) => {
      if (data?.did_finish_autoquote) {
        return false;
      }

      return 1000; // 1 second
    },
    onSuccess(data) {
      if (data?.did_finish_autoquote) {
        setQuotes(sortParcelQuotes(data?.quotes));
      }
    }
  });

  const hasRfqSpecificOptions = [
    getRfqDetailsQuery?.data?.ups_specific_options,
    getRfqDetailsQuery?.data?.fedex_specific_options,
    getRfqDetailsQuery?.data?.usps_specific_options
  ].filter(Boolean).length;

  return {
    getRfqDetailsQuery,
    quotes,
    noRfqOnShipment:
      !getRfqDetailsQuery.isLoading &&
      getRfqDetailsQuery.isFetched &&
      (!getRfqDetailsQuery.data?.id || !hasRfqSpecificOptions)
  };
};
