import _ from 'lodash';
import {formatDate} from 'App/utils/globals';
import './styles.scss';

const ServiceLevelAgreementReadOnlyDetails = ({sla}) => {
  return (
    <div className="sla__readOnlyDetails grid">
      <div className="grid-1-1">
        <div>
          <p>
            <b>Loads Awarded</b>
          </p>
          <p>
            {sla.awarded_loads
              ? `${sla.awarded_loads} Per ${_.upperFirst(sla.awarded_loads_interval.toLowerCase())}`
              : '--'}
          </p>
        </div>
      </div>
      <div className="grid-1-2">
        <div>
          <p>
            <b>Start Date</b>
          </p>
          <p>{sla.start_date ? formatDate(sla.start_date) : '--'}</p>
        </div>
        <div>
          <p>
            <b>End Date</b>
          </p>
          <p>{sla.end_date ? formatDate(sla.end_date) : '--'}</p>
        </div>
      </div>
      <div className="grid-1-2">
        <div>
          <p>
            <b>Service Level Rank</b>
          </p>
          <p>{sla.position ? _.upperFirst(sla.position.toLowerCase()) : '--'}</p>
        </div>
        <div>
          <p>
            <b>Minimum Acceptance Rate</b>
          </p>
          <p>{sla.minimum_acceptance_percent ? `${sla.minimum_acceptance_percent * 100}%` : '--'}</p>
        </div>
      </div>
      <div className="grid-1-2">
        <div>
          <p>
            <b>On Time Pickup Rate</b>
          </p>
          <p>{sla.minimum_on_time_pickup_percent ? `${sla.minimum_on_time_pickup_percent * 100}%` : '--'}</p>
        </div>
        <div>
          <p>
            <b>On Time Delivery Rate</b>
          </p>
          <p>{sla.minimum_on_time_delivery_percent ? `${sla.minimum_on_time_delivery_percent * 100}%` : '--'}</p>
        </div>
      </div>
      <div className="grid-1-1">
        <div>
          <p>
            <b>Notes</b>
          </p>
          <p>{sla.notes ? sla.notes : '--'}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceLevelAgreementReadOnlyDetails;
