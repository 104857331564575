import {Formik, Form, FormikHelpers} from 'formik';
import {InvoiceRuleConfiguration} from '@shipwell/settlements-sdk';
import {ConfigurationsCard} from '../ConfigurationsCard';
import {sortInvoiceRules} from './utils';
import {InvoiceRuleRow} from './components/InvoiceRuleRow';
import useInvoiceRuleConfiguration, {useInvoiceRuleConfigurationsQuery} from 'App/api/settlements/useInvoiceRule';
import Loader from 'App/common/shipwellLoader';
import FormFooter from 'App/formComponents/formSections/formFooter';

type InitialValues = Record<string, boolean>;

const getInitialValues = (invoiceRules: InvoiceRuleConfiguration[]): InitialValues =>
  Object.fromEntries(invoiceRules.map((invoiceRule) => [invoiceRule.id, invoiceRule.enabled || false]));

export const InvoiceRuleConfigurationsCard = () => {
  const invoiceRuleConfigurationsQuery = useInvoiceRuleConfigurationsQuery();
  const {updateInvoiceConfigurationStatusesMutation} = useInvoiceRuleConfiguration();

  const invoiceRules = invoiceRuleConfigurationsQuery.data || [];

  if (invoiceRuleConfigurationsQuery.isLoading) {
    return <Loader loading />;
  }

  const onSubmit = (values: InitialValues, {setSubmitting, resetForm}: FormikHelpers<InitialValues>) => {
    updateInvoiceConfigurationStatusesMutation.mutate(
      {
        statuses: Object.keys(values).map((key) => ({
          id: key,
          enabled: values[key]
        }))
      },
      {
        onSettled: () => setSubmitting(false),
        onSuccess: ({data}) => resetForm({values: getInitialValues(data)})
      }
    );
  };

  return (
    <ConfigurationsCard title="Invoice Exception Criteria">
      <Formik initialValues={getInitialValues(invoiceRules)} onSubmit={onSubmit}>
        {({dirty, resetForm}) => (
          <Form>
            {sortInvoiceRules(invoiceRules).map((rule) => (
              <InvoiceRuleRow key={rule.id} invoiceRule={rule} />
            ))}

            {dirty ? <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} /> : null}
          </Form>
        )}
      </Formik>
    </ConfigurationsCard>
  );
};
