/* eslint-disable camelcase */
import {Component} from 'react';
import moment from 'moment';
import {DisplayValue} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import {formatDateTime} from 'App/utils/globals';

export default class MarketplaceStats extends Component {
  constructor(props) {
    super(props);
    this.state = {showForm: false};
  }

  render() {
    const {selectedShipment = {}, numCarrierBids} = this.props;
    let auctionStats = {};
    if (selectedShipment) {
      auctionStats = selectedShipment.auction_stats || {};
    }
    const {visible_companies = null} = auctionStats;
    const {most_recent_quote_created_at = null} = auctionStats;
    const quotes = auctionStats.quotes + numCarrierBids;
    const duration = null;
    return (
      <div className="marketplace__financials">
        <DisplayValue label="Visibility">
          {visible_companies && visible_companies !== null ? visible_companies + ' Carriers' : '--'}
        </DisplayValue>
        <DisplayValue label="Duration">{duration && duration !== null ? duration + ' days' : '--'}</DisplayValue>
        <DisplayValue label="Bids">{quotes && quotes !== null ? quotes : '--'}</DisplayValue>
        <DisplayValue label="Last Bid">
          {most_recent_quote_created_at && moment(most_recent_quote_created_at).isValid()
            ? formatDateTime(most_recent_quote_created_at, true)
            : '--'}
        </DisplayValue>
      </div>
    );
  }
}

MarketplaceStats.propTypes = {
  selectedShipment: PropTypes.object,
  numCarrierBids: PropTypes.number
};
