import {SvgIcon} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import {AuctionStatsPopover} from './AuctionStatsPopover';

export const AuctionStatsCell = ({
  shipmentId,
  hasOpenAuction,
  auctionStats
}: {
  shipmentId: string;
  hasOpenAuction?: boolean;
  auctionStats?: {visible_companies: number; messages: number; quotes: number};
}) => {
  return (
    <AuctionStatsPopover shipmentId={shipmentId}>
      <div className="flex flex-col">
        {hasOpenAuction ? <Link to={`/marketplace/${shipmentId}/bids`}>Auction Running</Link> : null}
        <div className="flex flex-row px-2 text-sw-icon">
          <span className="mr-2 flex flex-row items-center">
            <SvgIcon name="Visibility" color="sw-icon" />
            <span className="whitespace-nowrap">{auctionStats?.visible_companies || '--'}</span>
          </span>
          <span className="mr-2 flex flex-row items-center">
            <SvgIcon name="Messages" color="sw-icon" />
            <span className="whitespace-nowrap">{auctionStats?.messages || '--'}</span>
          </span>
          <span className="flex flex-row items-center">
            <SvgIcon name="DollarFilled" color="sw-icon" />
            <span className="whitespace-nowrap">{auctionStats?.quotes || '--'}</span>
          </span>
        </div>
      </div>
    </AuctionStatsPopover>
  );
};
