const isInternationalShipment = (stops = []) => {
  // first stop with a country is considered country of origin
  const countryOfOrigin = stops.find((stop) => stop.location?.address?.country)?.location.address.country;
  if (!countryOfOrigin) {
    return false;
  }

  // if a stop has a country and it doesn't match the origin country, this is an international shipment
  return stops.some((stop) => stop.location?.address?.country && stop.location.address.country !== countryOfOrigin);
};

export default isInternationalShipment;
