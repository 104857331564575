import {Select} from '@shipwell/shipwell-ui';
import {ViewMode} from 'App/data-hooks/appointments/types';

const ViewModeOptions = [
  {label: 'Week', value: ViewMode.Week},
  {label: 'Day', value: ViewMode.Day}
];

export type WeekDaySelectorProps = {
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
};

export function WeekDaySelector({viewMode, setViewMode}: WeekDaySelectorProps) {
  return (
    <Select
      simpleValue
      label="Viewing"
      clearable={false}
      value={viewMode}
      options={ViewModeOptions}
      onChange={setViewMode}
    />
  );
}
