import {Fragment, useEffect, useState} from 'react';
import {Card, SvgIcon, Rule, Title, ToggleSwitch, Dropdown, Modal, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {LoadboardType} from '@shipwell/tabula-sdk';
import get from 'lodash/get';

import useToggle from 'App/utils/hooks/useToggle';
import LoadboardItem, {
  getStatusCanBeAddedToLoadBoard,
  PrivateLoadBoardItem
} from 'App/containers/Shipment/components/LoadBoards/Card/item';
import Edit from 'App/containers/Shipment/components/LoadBoards/Edit';
import {ShipmentCardLoadBoardsProps} from 'App/containers/Shipment/components/LoadBoards/utils';
import useGetListLoadPosts from 'App/api/loadboards/hooks/useGetListLoadPosts';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {useUserMe} from 'App/data-hooks';
import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ShipmentCardLoadBoards = ({
  ordinalIndex = 0,
  isCollapsed = false,
  onCollapse,
  draggableProvided,
  shipmentId,
  setSuccess,
  setError,
  shipment,
  user
}: ShipmentCardLoadBoardsProps & WithStatusToastProps) => {
  const [loadpostId, setLoadpostId] = useState('');
  const [loadboardType, setLoadboardType] = useState<LoadboardType>();
  const [loadboardName, setLoadboardName] = useState<string>('');
  const [editing, toggleEditing] = useToggle(false);
  const {data: loadboards} = useGetActiveLoadboards();
  const {data: listLoadPosts = [], refetch} = useGetListLoadPosts(shipmentId);
  const {data} = useUserMe();
  const contactInfo = {email: data?.user?.email || undefined, phone: data?.user?.phone_number || undefined};
  const userPermissions: string[] = get(data, 'user.permissions', []);
  const featureFlagPrivateLoadBoardEnabled = get(data, 'company.feature_flags.load_board_enabled', false);
  const statusCanBeAddedToLoadBoard = getStatusCanBeAddedToLoadBoard(shipment);
  const showPrivateLoadboard =
    (userPermissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      userPermissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
    featureFlagPrivateLoadBoardEnabled &&
    statusCanBeAddedToLoadBoard;
  const onRefreshLoadposts = async () => await refetch().then(() => setSuccess('Success!', 'All Posts were Refreshed'));

  useEffect(() => {
    void refetch();
  }, [refetch, shipment.updated_at]);

  //TODO: remove when Post to all load boards is implemented
  const IMPLENTED = false;

  return (
    <>
      <Card
        title={
          <div className="flex items-center py-4">
            <span className="mr-3">
              <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
            </span>
            <div className="flex items-baseline">
              <span>Load Boards</span>
              <span className="text-grey-5 text-xxs font-normal">
                <span className="mx-1">•</span>
                {listLoadPosts.length} Active posts
              </span>
            </div>
          </div>
        }
        tabOrder={ordinalIndex}
        isCollapsible
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        draggableProvided={draggableProvided}
        actions={
          <Dropdown variant="icon" drop="down" indicator={false} icon={<SvgIcon name="Overflow" />} alignEnd>
            {({onClick}: {onClick(): void}) => (
              <>
                <li
                  title="Refresh All Posts"
                  onClick={() => {
                    onClick();
                    void onRefreshLoadposts();
                  }}
                >
                  Refresh All Posts
                </li>
              </>
            )}
          </Dropdown>
        }
        bodyClassName="p-4 empty:p-0"
      >
        <CollapsibleCardContent>
          {IMPLENTED ? (
            <>
              <div>
                <Title variant="formTitle">
                  <div className="flex items-center pb-4">
                    <ToggleSwitch name="all" fixedHeight={false} isSmall />
                    <span className="ml-3">Post to all load boards</span>
                  </div>
                </Title>
              </div>
              <Rule />
            </>
          ) : null}
          {showPrivateLoadboard ? <PrivateLoadBoardItem shipment={shipment} /> : null}
          {loadboards?.map((loadboard) => (
            <Fragment key={loadboard.loadboard_type}>
              <LoadboardItem
                loadboard={loadboard}
                shipmentId={shipmentId}
                listLoadPosts={listLoadPosts}
                toggleEditing={toggleEditing}
                setLoadpostId={setLoadpostId}
                setLoadboardType={setLoadboardType}
                setLoadboardName={setLoadboardName}
                userId={user.id}
              />
            </Fragment>
          ))}
        </CollapsibleCardContent>
      </Card>
      {loadboardType ? (
        <Modal
          show={editing}
          title="Edit Load Posts"
          onClose={toggleEditing}
          footerComponent={null}
          bodyClassName="p-0"
        >
          <Edit
            toggleModal={toggleEditing}
            shipmentId={shipmentId}
            loadboardType={loadboardType}
            loadboardName={loadboardName}
            loadpostId={loadpostId}
            setSuccess={setSuccess}
            setError={setError}
            contactInfo={contactInfo}
            mode={shipment.mode}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default WithStatusToasts(ShipmentCardLoadBoards);
