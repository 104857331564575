import {useEffect} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import {object, string, mixed} from 'yup';
import {DeprecatedButton, Card} from '@shipwell/shipwell-ui';
import {fetchEquipmentTypes} from 'App/actions/_shipments';
import './styles.scss';
import CarrierBidFields from 'App/formComponents/formSections/carrierBidFields';
import {validateDollarValue, validatePhoneNumber} from 'App/utils/globals';

/** Default values needed for Formik */
const defaultFormValues = {
  carrier: null,
  contact_first_name: '',
  contact_phone_number: '',
  bid_amount: '',
  bid_amount_currency: 'USD',
  available_date: moment().toDate(),
  current_location: ''
};

export const validationSchema = object().shape({
  bid_amount: string()
    .nullable()
    .test('rate', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value);
      }
      return true;
    })
    .required('Rate is required'),
  carrier: object().nullable().required('Carrier is required.'),
  contact_first_name: string().nullable().required('First name is required.'),
  contact_last_name: string().nullable(),
  contact_phone_number: string()
    .required('A valid phone number is required.')
    .test('phone_number', 'A valid phone number is required.', (value) => {
      if (value) {
        return validatePhoneNumber(value);
      }
      return true;
    }),
  contact_email: string().email('A valid email is required.').nullable(),
  available_date: string().nullable(),
  available_time: string().nullable(),
  equipment_type: string().nullable(),
  current_location: mixed()
    .test('address', 'Available Location is required.', (value) => (value ? value.country : true))
    .required('Available Location is required.')
});

/**
 * Contract Form
 */
const CarrierBidForm = (props) => {
  const {edit, dispatch, values, onSubmit, onCancel, equipmentTypes, currency, readOnly, isConfirmation} = props;

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit bid values */
  const handleBidSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  useEffect(() => {
    if (equipmentTypes.length < 1) {
      dispatch(fetchEquipmentTypes());
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleBidSubmit}
    >
      {({handleSubmit, isSubmitting, dirty, setFieldValue, ...props}) => (
        <form className="carrierBid__form">
          <CarrierBidFields readOnly={readOnly} {...props} equipmentTypes={equipmentTypes} currency={currency} />
          {(!edit || dirty || isConfirmation) && !readOnly && (
            <div className="carrierBid__form-footer">
              {onCancel && (
                <DeprecatedButton variant="secondary" onClick={handleCancel}>
                  Cancel
                </DeprecatedButton>
              )}
              {isConfirmation && (
                <DeprecatedButton
                  variant="secondary"
                  onClick={() => {
                    setFieldValue('preventBook', true);
                    handleSubmit();
                  }}
                >
                  Update rate
                </DeprecatedButton>
              )}
              {onSubmit && (
                <DeprecatedButton loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit}>
                  {isConfirmation ? 'Confirm Bid & Select Carrier' : edit ? 'Save' : 'Create'}
                </DeprecatedButton>
              )}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

CarrierBidForm.propTypes = {
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  isConfirmation: PropTypes.bool,
  equipmentTypes: PropTypes.array,
  currency: PropTypes.string,
  readOnly: PropTypes.bool
};

export default connect((state) => ({
  equipmentTypes: state.shipments.equipmentTypes,
  company: state.userCompany.company,
  currency: state.userCompany.currency
}))(CarrierBidForm);
