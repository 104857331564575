export const formatPlacesAddressComponents = (addressComponents = []) => {
  let formattedAddress = '';
  const hasLocalityComponent = addressComponents.find(({types}) => types.includes('locality'));
  // format the address specific to backend req, dont use the pre-formatted address returned by the Places API
  // i.e. 'Key West, FL, US' instead of 'Key West, FL 33040, USA'
  addressComponents.forEach((ac) => {
    if (ac.types) {
      // if the address component is a locality, use that for city
      if (ac.types.includes('locality') && ac.long_name) {
        formattedAddress += `${ac.long_name}, `;
        // otherwise if the address component is adminstrative_level_3 and there isn't a locality, use that for the city
      } else if (!hasLocalityComponent && ac.types.includes('administrative_area_level_3') && ac.long_name) {
        formattedAddress += `${ac.long_name}, `;
      }
      // 'administrative_area_level_1' is the state or province level in the Places API
      if (ac.types.includes('administrative_area_level_1') && ac.short_name) {
        formattedAddress += `${ac.short_name}, `;
      }
      if (ac.types.includes('country') && ac.short_name) {
        formattedAddress += ac.short_name;
      }
    }
  });
  return formattedAddress;
};
