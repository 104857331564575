export const TENDER_REQUESTED = 'Tender Requested';
export const TENDER_ACCEPTED = 'Tender Accepted';
export const TENDER_REJECTED = 'Tender Rejected';
export const TENDER_REVOKED = 'Tender Revoked';
export const TENDER_EXPIRED = 'Tender Expired';

export const rejectionCodes = {
  standard: [
    {value: 'EQU', description: 'EQU - No equipment available'},
    {value: 'CPU', description: 'CPU - Agreed-upon capacity exceeded'},
    {value: 'LL1', description: 'LL1 - Load does not belong to us'},
    {value: 'LL2', description: 'LL2 - Unable to complete the load'}
  ],
  postAcceptance: [
    {value: 'CNA', description: 'CNA - Capacity no longer available'},
    {value: 'ASE', description: 'ASE - Accepted Shipment in Error'}
  ],
  other: [{value: 'A13', description: 'A13 - Other reasons'}]
};

//format tender status
export const handleTenderStatus = (tender) => {
  if (tender.status === 'open') {
    return TENDER_REQUESTED;
  }
  if (tender.status === 'accepted') {
    return TENDER_ACCEPTED;
  }
  if (tender.status === 'rejected') {
    return TENDER_REJECTED;
  }
  if (tender.status === 'revoked') {
    return TENDER_REVOKED;
  }
  if (tender.status === 'expired') {
    return TENDER_EXPIRED;
  }
};

export const tenderStatuses = {
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  REVOKED: 'revoked',
  ACCEPTED: 'accepted'
};
