import {useQuery} from '@tanstack/react-query';
import {getCarrierWithDataMetrics} from 'App/api/rfpOptimized/typed';
import {CARRIER_WITH_DATA_METRICS} from 'App/data-hooks/queryKeys';

const useCarriersWithDataMetrics = ({pageSize, q}: {pageSize: number; q: string}) => {
  return useQuery(
    [CARRIER_WITH_DATA_METRICS, q],
    async () => {
      const response = await getCarrierWithDataMetrics({pageSize, q});
      return response.data;
    },
    {
      enabled: !!q
    }
  );
};

export default useCarriersWithDataMetrics;
