import {ShipmentTimelineEvent} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Dropdown, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import useToggle from 'App/utils/hooks/useToggle';

const DeleteEventModal = ({
  show,
  onClose,
  handleDeleteEvent
}: {
  show: boolean;
  onClose: () => void;
  handleDeleteEvent: () => void;
}) => {
  return (
    <Modal
      title="Delete Timeline Event"
      className="font-normal"
      size="small"
      show={show}
      onClose={onClose}
      variant="warning"
      primaryBtnName="Delete"
      onPrimaryAction={handleDeleteEvent}
    >
      <div>Are you sure you want to delete this event?</div>
    </Modal>
  );
};

const CardMenu = ({
  timelineEvent,
  onUpdateEvent,
  onDeleteEvent
}: {
  timelineEvent?: ShipmentTimelineEvent;
  onUpdateEvent?: (timelineEvent: ShipmentTimelineEvent) => void;
  onDeleteEvent?: (timelineEvent: ShipmentTimelineEvent) => void;
}) => {
  const [showDeleteModal, toggleShowDeleteModal] = useToggle();
  const handleUpdateEvent = () => {
    if (onUpdateEvent && timelineEvent) {
      onUpdateEvent(timelineEvent);
    }
  };

  const handleDeleteEvent = () => {
    if (onDeleteEvent && timelineEvent) {
      onDeleteEvent(timelineEvent);
    }
    toggleShowDeleteModal();
  };

  return onUpdateEvent || onDeleteEvent ? (
    <>
      <div>
        <Dropdown
          variant="icon"
          indicator={false}
          drop="left"
          icon={<SvgIcon name="Overflow" />}
          className="font-normal"
        >
          {({onClick}: {onClick: () => void}) => {
            return (
              <>
                {onUpdateEvent ? (
                  <div
                    onClick={() => {
                      onClick();
                      handleUpdateEvent();
                    }}
                  >
                    Edit
                  </div>
                ) : null}
                {onDeleteEvent ? (
                  <div
                    onClick={() => {
                      onClick();
                      toggleShowDeleteModal();
                    }}
                  >
                    Delete
                  </div>
                ) : null}
              </>
            );
          }}
        </Dropdown>
      </div>
      <DeleteEventModal show={showDeleteModal} onClose={toggleShowDeleteModal} handleDeleteEvent={handleDeleteEvent} />
    </>
  ) : null;
};

export default CardMenu;
