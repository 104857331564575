import {ReactNode} from 'react';
import {Button} from '@shipwell/shipwell-ui';
import {SaveDashboardForm} from './SaveDashboardForm';
import {TableSidebarFooter} from './TableSidebarFooter';
import useToggle from 'App/utils/hooks/useToggle';

export const SidebarFooterForm = ({
  onCreate,
  onUpdate,
  children,
  isNewDashboard
}: {
  onCreate: (isDefault: boolean, name: string) => void;
  onUpdate: () => void;
  children?: ReactNode;
  isNewDashboard: boolean;
}) => {
  const [showForm, toggleForm] = useToggle();

  return (
    <TableSidebarFooter>
      {showForm ? (
        <SaveDashboardForm label="New dashboard" onSave={onCreate} onCancel={toggleForm} actions={children} />
      ) : (
        <div className={`flex w-full items-center ${children ? 'justify-between' : 'justify-center'} p-2`}>
          {children}
          {isNewDashboard ? (
            <div className="flex gap-2">
              <Button size="sm" isCompact variant="tertiary" onClick={onUpdate}>
                Save Changes
              </Button>
              <Button size="sm" onClick={toggleForm}>
                Create New
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </TableSidebarFooter>
  );
};
