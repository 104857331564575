import classNames from 'classnames';
import useClipboard from 'react-use-clipboard';
import PropTypes from 'prop-types';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import './styles.scss';

const iconSizeMap = {
  small: {
    width: '16px',
    height: '16px'
  },
  medium: {
    width: '24px',
    height: '24px'
  }
};

const ClickToCopy = ({value, label, placement, iconSize, showOnHover, onClick}) => {
  const [isCopied, setCopied] = useClipboard(value);
  return (
    <Tooltip
      tooltipContent={
        <span className="successLabel">
          Copied <SvgIcon name="Check" color="$sw-success" />
        </span>
      }
      placement={placement}
      trigger="click"
      duration={1000}
    >
      <span
        className={classNames('copyLabel', iconSize, {
          showOnHover: showOnHover
        })}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          setCopied();
        }}
      >
        {label}
        <SvgIcon
          name="Copy"
          color="$sw-icon"
          width={iconSize && iconSizeMap[iconSize].width}
          height={iconSize && iconSizeMap[iconSize].height}
        />
      </span>
    </Tooltip>
  );
};

ClickToCopy.defaultProps = {
  value: ''
};

ClickToCopy.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placement: PropTypes.string,
  iconSize: PropTypes.string,
  showOnHover: PropTypes.bool
};

ClickToCopy.displayName = 'ClickToCopy';

export default ClickToCopy;
