import {Component} from 'react';

import {Async} from 'react-select';
import FormGroup from '../../formGroup';
import {fetchBrokerShipperRelationshipsPromise} from 'App/actions/brokers';

/**
 * Customer Search Field
 */
class CustomerSearchField extends Component {
  static defaultProps = {
    value: '',
    name: '',
    input: {},
    multi: false,
    disabled: false,
    autoload: true,
    placeholder: 'Customer account',
    icon: 'icon-Rolodex'
  };

  /**
   * @param  {String} value Search field input value
   */
  async searchCustomerAccounts(value) {
    const {brokerageId} = this.props;

    try {
      const result = {options: []};
      const response = await fetchBrokerShipperRelationshipsPromise(brokerageId, {q: value});

      if (response && response.results) {
        result.options = response.results.map((result) => ({
          label: result.company.name,
          value: result.company.id
        }));
      }
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {input, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const onChange = input.onChange || rest.onChange;

    return (
      <FormGroup {...this.props}>
        {() => (
          <Async
            {...rest}
            name={name}
            value={value}
            loadOptions={this.searchCustomerAccounts.bind(this)}
            filterOptions={(options) => options}
            onChange={(value) => onChange && onChange(value)}
          />
        )}
      </FormGroup>
    );
  }
}

export default CustomerSearchField;
