import {Field, reduxForm, Form} from 'redux-form';
import {connect} from 'react-redux';
import {Button, FormControl, ControlLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import DateTimeField from 'App/formComponents/fields/dateTimePicker/index';
import {addressBookGet} from 'App/actions/addressBook';

let TasksForm = (props) => {
  const {handleSubmit, submitting, error, shipment, companyUsers, cancel, formValues} = props;
  const showCompanyUsers = [];
  if (companyUsers && companyUsers.results) {
    for (let i = 0; i < companyUsers.results.length; i++) {
      showCompanyUsers.push({
        name: companyUsers.results[i].first_name + ' ' + companyUsers.results[i].last_name,
        id: companyUsers.results[i].id
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="formstyle-light tasks__form">
      <Field req autoFocus label="Task" name="description" component={renderField} />
      <Field
        name="assignee"
        labelKey="display_name"
        component={renderDropDownSelector}
        label="Assign To"
        req
        data={showCompanyUsers}
        placeholder="Select a user"
      />
      <Field
        name="due_date"
        component={DateTimeField}
        label="Due"
        time
        normalize={(value) => value || null}
        placeholder=""
      />
      {error && (
        <p className="error-text-form-level">
          <i className="icon icon-Delayed pad-right" />
          {error}
        </p>
      )}

      <div className="text-right">
        <Button bsStyle="default" disabled={submitting} onClick={cancel}>
          Cancel
        </Button>
        <Button bsStyle="primary" type="submit" disabled={submitting}>
          {submitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.description) {
    errors.description = 'Enter a description';
  }
  if (!values.assignee) {
    errors.assignee = 'Select a user';
  }

  return errors;
};

TasksForm = reduxForm({
  form: 'TasksForm',
  validate: validate,
  enableReinitialize: true
})(TasksForm);

TasksForm = connect((state) => ({
  initialValues: state.users.selectedTask
}))(TasksForm);

export default TasksForm;
