import PropTypes from 'prop-types';
import {Card, CollapsibleCardContent, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import InstantRatesTable from 'App/containers/InstantRates/components/InstantRatesTable';

const InstantRatesModal = ({show, onClose, newShipmentInformation}) => {
  return (
    <Modal title="Select An Instant Rate" show={show} onClose={onClose} footerComponent={null}>
      <Card title="Instant Rates" bodyClassName="p-0" isCollapsible>
        <CollapsibleCardContent>
          <InstantRatesTable expandedColumnSet newShipmentInformation={newShipmentInformation} />
        </CollapsibleCardContent>
      </Card>
      <div className="mt-4 flex flex-row items-center rounded bg-sw-warning-background p-2">
        <div className="flex flex-row items-center font-bold">
          <SvgIcon name="ErrorOutlined" color="sw-warning" />
          <div className="ml-2">Select a carrier to take your load and the instant rate will be applied.</div>
        </div>
      </div>
    </Modal>
  );
};

InstantRatesModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  isNewShipmentForm: PropTypes.bool,
  selectedRowId: PropTypes.string,
  setSelectedRowId: PropTypes.func,
  newShipmentInformation: PropTypes.object,
  hasRequiredShipmentInfo: PropTypes.bool
};

InstantRatesModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default InstantRatesModal;
