import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Button, Image} from 'react-bootstrap';
import * as actions from '../actions/auth';
import * as brokerActions from '../actions/brokers';
import './_landing-pages.scss';

@connect(
  (state) => ({
    brokerLogos: state.brokers.brokerLogos,
    isVerifying: state.auth.isVerifying,
    error: state.users.error,
    details: state.users.details,
    is_active: state.auth.is_active
  }),
  {...brokerActions, ...actions}
)
export default class ResetPassword extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      done: false
    };
  }
  componentDidMount() {
    this.props
      .verifyEmail({
        token: this.props.location.query.token
      })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(
            function () {
              this.setState({done: true});
            }.bind(this),
            2000
          );
        } else {
          const errorMessage = response.field_errors
            ? response.field_errors[0].field_name + ': ' + response.field_errors[0].field_errors[0]
            : response.error_description[0];
          this.setState({
            showError: true,
            error: errorMessage
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.done) {
      setTimeout(
        function () {
          this.context.router.push('/');
        }.bind(this),
        2000
      );
    }
  }

  render() {
    let stackedColorLogo = '';
    if (this.props.brokerLogos && this.props.brokerLogos.length > 0) {
      for (var i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'INLINE_COLOR') {
          stackedColorLogo = this.props.brokerLogos[i];
        }
      }
    }

    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div
              style={{
                textAlign: 'center'
              }}
            >
              {this.props.brokerLogos.length > 0 && (
                <Image
                  src={stackedColorLogo.logo + '?cachebust=' + new Date().getTime()}
                  height="80%"
                  width="80%"
                  style={{marginBottom: '15px'}}
                />
              )}
              <div className="login-box-body">
                {this.state.showError && (
                  <p className="login-box-msg">
                    <i className="icon icon-Delayed" /> &nbsp;
                    {this.state.error ? this.state.error.charAt(0).toUpperCase() + this.state.error.slice(1) : ''}
                  </p>
                )}

                {!this.state.showError && (
                  <div className="login-box-msg">
                    <div className={this.state.done ? 'load-complete circle-loader' : 'circle-loader'}>
                      <div className="checkmark draw" />
                    </div>
                  </div>
                )}
                {!this.state.showError && (
                  <div className="login-box-msg">
                    <h4>Your email has been successfully verified. Redirecting</h4>
                  </div>
                )}
                {this.state.showError && (
                  <Row style={{paddingTop: '20px'}}>
                    <Button
                      onClick={() => {
                        this.context.router.push('/');
                      }}
                      bsStyle="primary"
                    >
                      Back to Sign In
                    </Button>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
