import {Company, DocumentMetadata} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Field, FormikHelpers} from 'formik';
import {useEffect, useState} from 'react';
import {FormikTextInput, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import {
  ContractFormValues,
  contractsModeOptionsWhiteList,
  contractsTooltips
} from 'App/formComponents/formSections/contractFields/constants';
import {getCompanyDocuments} from 'App/api/company/typed';
import {getSLADetails} from 'App/api/serviceLevelAgreements/typed';
import {
  AdditionalStopChargeField,
  DistanceSystemField,
  DocumentsField,
  FuelIncludedField,
  NotesField,
  RateTypeField,
  ServiceLevelAgreementField
} from 'App/formComponents/formSections/contractFields/fields';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import type {State} from 'App/reducers/types';

/**
 * ContractDefaultsFields - component for use in Company Defaults page
 *
 */
const ContractDefaultsFieldsBase = ({
  company,
  setFieldValue,
  values,
  isSubmitting
}: {
  company?: Partial<Company>;
  setFieldValue: FormikHelpers<ContractFormValues>['setFieldValue'];
  values: ContractFormValues;
  isSubmitting?: boolean;
}) => {
  const [companyDocuments, setCompanyDocuments] = useState<DocumentMetadata[]>([]);

  /**
   * Get company documents
   * @param {*} companyId
   */
  const getCompanyDocumentsList = async (companyId: string) => {
    try {
      const response = await getCompanyDocuments({companyId, pageSize: 50});

      if (response?.results) {
        setCompanyDocuments(response.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceLevelAgreementDetails = async (slaID: string) => {
    try {
      const response = await getSLADetails(slaID);
      setFieldValue('service_level_agreement', response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (values.service_level_agreement && typeof values.service_level_agreement === 'string') {
      //this will fetch only on the initial load from the api
      void getServiceLevelAgreementDetails(values.service_level_agreement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isSubmitting]);

  useEffect(() => {
    if (company?.id) {
      void getCompanyDocumentsList(company.id);
    }
  }, [company]);

  return (
    <div className="field-grid">
      <div className="grid-item-1-11">
        <DistanceSystemField />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent={contractsTooltips.DISTANCE_SYSTEM}
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-12">
        <ModesField name="modes" optionsWhiteList={contractsModeOptionsWhiteList} />
      </div>
      <div className="grid-item-1-12">
        <EquipmentTypesField name="equipment_types" />
      </div>
      <div className="grid-item-1-12 grid-checkbox-field">
        <FuelIncludedField />
      </div>
      <div className="grid-item-1-12">
        <RateTypeField name="rate_type" />
      </div>

      <div className="grid-item-1-12 grid-checkbox-field">
        <AdditionalStopChargeField />
      </div>

      <div className="grid-item-1-11">
        <ServiceLevelAgreementField name="service_level_agreement" />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent={contractsTooltips.SERVICE_LEVEL_AGREEMENT}
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-12">
        <NotesField name="notes" />
      </div>
      <div className="grid-item-1-11">
        <DocumentsField documents={companyDocuments} name="documents" />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent={contractsTooltips.DOCUMENTS}
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-11">
        <Field name="email" label="Email" component={FormikTextInput} />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent="This email is used to notify someone when certain events occur with Contracts not associated with shipments or actions with a direct contact."
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
    </div>
  );
};

export const ContractDefaultsFields = connect((state: State) => ({
  company: state.userCompany.company
}))(ContractDefaultsFieldsBase);
