import {useQuery} from '@tanstack/react-query';
import {UPS_PACKAGE_TYPES} from 'App/data-hooks/queryKeys';
import {fetchUpsPackageTypes} from 'App/api/referenceData/typed';

export const useGetUpsPackageTypes = () => {
  const {data, ...getUpsPackageTypesQuery} = useQuery([UPS_PACKAGE_TYPES], () => fetchUpsPackageTypes(), {
    staleTime: Infinity,
    cacheTime: Infinity
  });

  const upsPackageTypes = data?.reduce(
    (types, type) => ({...types, [type.id as string]: `UPS ${type.label as string}`}),
    {}
  );

  return {upsPackageTypes, getUpsPackageTypesQuery};
};
