import {useCallback} from 'react';
import {useQuery} from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';
import {BROKERAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';

export default function useCustomers(company, queryOptions = {}, fetchOptions = {}) {
  const brokerageId = get(company, 'brokerage.id');
  const getCustomers = useCallback(
    (opts = {}) => {
      if (brokerageId) {
        return fetchBrokerShipperRelationshipsPromise(brokerageId, isEmpty(opts) ? fetchOptions : opts);
      }
    },
    [brokerageId, fetchOptions]
  );

  const getCustomersQuery = useQuery([BROKERAGES_QUERY_KEY, brokerageId], () => getCustomers(), {
    enabled: !!brokerageId,
    ...queryOptions
  });

  const customers = getCustomersQuery.data?.body?.results;
  const getCustomerOptionFromId = (id) =>
    customers?.find((customer) => isEqual(customer.id, id) || isEqual(customer?.company?.id, id));

  return {...getCustomersQuery, context: {customers, getCustomers, getCustomerOptionFromId}};
}
