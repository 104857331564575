import _ from 'lodash';
import {phoneUtil, validateEmail} from 'App/utils/globals';

const validate = (values, props) => {
  const errors = {
    point_of_contacts: [],
    vendor: {shipwell_vendor: {}},
    triumphPay: {triumph_payment_accounts: []}
  };
  let number = null;
  if (!values.vendor || _.isEmpty(values.vendor)) {
    errors.vendor.shipwell_vendor = {
      name: 'Company name is required',
      primary_email: 'E-mail is required',
      mailing_address: 'Mailing Address is required',
      primary_phone_number: 'Phone is required'
    };
  }
  if (values.vendor && values.vendor.shipwell_vendor) {
    if (!values.vendor.shipwell_vendor.name) {
      errors.vendor.shipwell_vendor = {
        name: 'Company name is required'
      };
    }
    if (!values.vendor.shipwell_vendor.primary_email) {
      errors.vendor.shipwell_vendor = {
        primary_email: 'E-mail is required'
      };
    }
    if (values.vendor.shipwell_vendor.primary_email && !validate(values.vendor.shipwell_vendor.primary_email)) {
      errors.vendor.shipwell_vendor = {
        primary_email: 'Enter a valid E-mail'
      };
    }
    if (!values.vendor.shipwell_vendor.mailing_address || _.isEmpty(values.vendor.shipwell_vendor.mailing_address)) {
      errors.vendor.shipwell_vendor = {
        mailing_address: 'Mailing Address is required'
      };
    }
    if (!values.vendor.shipwell_vendor.primary_phone_number) {
      errors.vendor.shipwell_vendor = {
        primary_phone_number: 'Phone is required'
      };
    }
    if (values.vendor.shipwell_vendor.primary_phone_number) {
      try {
        number = phoneUtil.parse(values.vendor.shipwell_vendor.primary_phone_number);
      } catch (err) {
        errors.vendor.shipwell_vendor = {primary_phone_number: 'Phone number is invalid'};
      }
    }
    if (!values.vendor.shipwell_vendor.primary_phone_number) {
      errors.vendor.shipwell_vendor = {primary_phone_number: 'Phone number is required'};
    } else if (values.vendor.shipwell_vendor.primary_phone_number && number && !phoneUtil.isValidNumber(number)) {
      errors.vendor.shipwell_vendor = {primary_phone_number: 'Phone number is invalid'};
    }
    //identifying codes
    if (values.vendor.shipwell_vendor.identifying_codes && values.vendor.shipwell_vendor.identifying_codes.length > 0) {
      errors.vendor.shipwell_vendor.identifying_codes = [];
      for (let i = 0; i < values.vendor.shipwell_vendor.identifying_codes.length; i++) {
        errors.vendor.shipwell_vendor.identifying_codes[i] = {};
        if (!values.vendor.shipwell_vendor.identifying_codes[i].value) {
          errors.vendor.shipwell_vendor.identifying_codes[i].value = 'Please input a value';
        }
        if (!values.vendor.shipwell_vendor.identifying_codes[i].type) {
          errors.vendor.shipwell_vendor.identifying_codes[i].type = 'Please select a type';
        }
      }
    }
  }

  // POC
  if (values.point_of_contacts && values.point_of_contacts.length > 0) {
    for (let i = 0; i < values.point_of_contacts.length; i++) {
      errors.point_of_contacts[i] = {};
      if (values.point_of_contacts[i] && !values.point_of_contacts[i].first_name) {
        errors.point_of_contacts[i].first_name = 'First name is required.';
      }
      if (
        values.point_of_contacts[i] &&
        values.point_of_contacts[i].first_name &&
        values.point_of_contacts[i].first_name.length > 45
      ) {
        errors.point_of_contacts[i].first_name = "First name can't be longer than 45 characters.";
      }
      if (
        values.point_of_contacts[i] &&
        values.point_of_contacts[i].last_name &&
        values.point_of_contacts[i].last_name.length > 45
      ) {
        errors.point_of_contacts[i].last_name = "Last name can't be longer than 45 characters.";
      }
      if (values.point_of_contacts[i] && !values.point_of_contacts[i].email) {
        errors.point_of_contacts[i].email = 'Email is required.';
      }
      if (values.point_of_contacts[i] && !values.point_of_contacts[i].job_title) {
        errors.point_of_contacts[i].job_title = 'Role is required.';
      }

      if (values.point_of_contacts[i] && !values.point_of_contacts[i].phone_number) {
        errors.point_of_contacts[i].phone_number = 'Phone is required.';
      }

      if (
        values.point_of_contacts[i] &&
        values.point_of_contacts[i].email &&
        !validateEmail(values.point_of_contacts[i].email)
      ) {
        errors.point_of_contacts[i].email = 'Contact email is invalid.';
      }

      if (values.point_of_contacts[i].phone_number) {
        try {
          number = phoneUtil.parse(values.point_of_contacts[i].phone_number);
        } catch (err) {
          errors.point_of_contacts[i].phone_number = 'Phone number is invalid.';
        }
      }

      if (values.point_of_contacts[i].phone_number && number && !phoneUtil.isValidNumber(number)) {
        errors.point_of_contacts[i].phone_number = 'Phone number is invalid.';
      }
    }
  }
  if (
    values.triumphPay &&
    values.triumphPay.triumph_payment_accounts &&
    values.triumphPay.triumph_payment_accounts.length > 0
  ) {
    //triumph payment account errors
    values.triumphPay.triumph_payment_accounts.forEach((account, i) => {
      errors.triumphPay.triumph_payment_accounts[i] = {bank_account_data: {}, vendor_factor_company: {}};
      if (account && !account.payment_type) {
        errors.triumphPay.triumph_payment_accounts[i].payment_type = 'Account type is required';
      }
      // bank account payment type error validation

      if (
        account.bank_account_data &&
        account.bank_account_data.bank_routing_number &&
        account.bank_account_data.bank_routing_number.length !== 9 &&
        (account.payment_type === 'ACH_BANK' || account.payment_type.id === 'ACH_BANK')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].bank_account_data.bank_routing_number =
          'Routing number must be 9 digits';
      }

      if (!account.bank_account_data) {
        errors.triumphPay.triumph_payment_accounts[i].bank_account_data.bank_account_number =
          'Account number is required';
        errors.triumphPay.triumph_payment_accounts[i].bank_account_data.bank_routing_number =
          'Routing number is required';
      }
      if (
        account.bank_account_data &&
        !account.bank_account_data.bank_account_number &&
        (account.payment_type === 'ACH_BANK' || account.payment_type.id === 'ACH_BANK')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].bank_account_data.bank_account_number =
          'Account number is required';
      }
      if (
        account.bank_account_data &&
        !account.bank_account_data.bank_routing_number &&
        account.payment_type &&
        (account.payment_type === 'ACH_BANK' || account.payment_type.id === 'ACH_BANK')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].bank_account_data.bank_routing_number =
          'Routing number is required';
      }
      if (
        !account.account_name &&
        account.payment_type &&
        (account.payment_type === 'ACH_BANK' || account.payment_type.id === 'ACH_BANK')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].account_name = 'Account name is required';
      }
      // factor payment type error validation
      if (
        account.vendor_factor_company &&
        !account.vendor_factor_company.company_name &&
        account.payment_type &&
        (account.payment_type === 'FACTOR' || account.payment_type.id === 'FACTOR')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].vendor_factor_company.company_name =
          'Factor Company Name is required';
      }
      if (
        account &&
        !account.notice_of_assignment_document &&
        account.payment_type &&
        (account.payment_type === 'FACTOR' || account.payment_type.id === 'FACTOR')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].notice_of_assignment_document =
          'Notice of Assignment is required';
      }

      if (
        account.vendor_factor_company &&
        account.vendor_factor_company.address &&
        !account.vendor_factor_company.address.formatted_address &&
        account.payment_type &&
        (account.payment_type === 'FACTOR' || account.payment_type.id === 'FACTOR')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].vendor_factor_company.address =
          'Factor Company Address is required';
      }

      //mail check payment type error validation
      if (
        account.vendor_billing_address &&
        !account.vendor_billing_address.formatted_address &&
        account.payment_type &&
        (account.payment_type === 'MAIL_CHECK' || account.payment_type.id === 'MAIL_CHECK')
      ) {
        errors.triumphPay.triumph_payment_accounts[i].vendor_billing_address = 'Mail To Address is required';
      }
    });
  }

  return errors;
};

export default validate;
