import {Shipment, ShipmentOverallStatus, DrayageLegOverallStatus, LegStageStageTypeEnum} from '@shipwell/corrogo-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {cancelDrayageLeg} from 'App/api/corrogo/typed';
import {useV3Shipment} from 'App/data-hooks';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

function useCancelShipment(shipmentId: string) {
  const queryClient = useQueryClient();

  const {
    context: {drayageLegIds}
  } = useV3Shipment(shipmentId);

  const cancelLegMutation = useMutation<Awaited<ReturnType<typeof cancelDrayageLeg>>, unknown, {legId: string}>(
    ({legId}: {legId: string}) => cancelDrayageLeg(legId)
  );

  const cancelShipment = async () => {
    const queryKey = [SHIPMENTS_QUERY_KEY, shipmentId];
    const shipment = queryClient.getQueryData<Shipment>(queryKey);
    if (shipment) {
      queryClient.setQueryData<Shipment>(queryKey, {
        ...shipment,
        stages: shipment.stages?.map((stage) =>
          stage.stage_type === LegStageStageTypeEnum.Leg
            ? {...stage, leg: {...stage.leg, overall_status: DrayageLegOverallStatus.Cancelled}}
            : stage
        ),
        overall_status: ShipmentOverallStatus.Cancelled
      });
    }

    const legCancellationPromises = (drayageLegIds || []).map((legId) => cancelLegMutation.mutateAsync({legId}));
    await Promise.allSettled(legCancellationPromises);

    await queryClient.invalidateQueries([SHIPMENTS_QUERY_KEY, shipmentId]);
  };

  return {cancelShipment, cancelLegMutation};
}

export default useCancelShipment;
