import {
  DocumentType,
  ShipmentDocumentMetadata,
  FreightInvoiceDocumentMetadataDocumentTypeEnum
} from '@shipwell/backend-core-sdk';
import {DeprecatedButton, FormikSelect, FormikTextarea} from '@shipwell/shipwell-ui';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Field, Form, Formik} from 'formik';
import {object, string} from 'yup';
import {FlexBox} from 'App/components/Box';
import {useDocumentTypes} from 'App/data-hooks';
import {updateShipmentDocument} from 'App/api/shipment/typed';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const validationSchema = object({
  type: string().required('A document type is required.'),
  description: string().required('A description is required.')
}).required();

type FormValues = {
  type: FreightInvoiceDocumentMetadataDocumentTypeEnum;
  description: string;
};

export const HouseBolForm = ({
  shipmentId,
  documentId,
  onClose,
  document
}: {
  shipmentId: string;
  documentId: string;
  onClose: () => void;
  document?: ShipmentDocumentMetadata;
}) => {
  const documentTypesQuery = useDocumentTypes();
  const queryClient = useQueryClient();

  const updateDocumentMutation = useMutation(updateShipmentDocument);

  const handleSubmit = (values: FormValues) => {
    updateDocumentMutation.mutate(
      {
        shipmentId,
        documentId,
        shipmentDocumentMetadata: {
          is_carrier_document: document?.is_carrier_document,
          description: values.description,
          // typecasting due to a SDK error
          type: values.type as ShipmentDocumentMetadata['type']
        }
      },
      {
        onSettled: () => {
          void queryClient.invalidateQueries([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId, documentId]);
          onClose();
        }
      }
    );
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        type: document?.type || FreightInvoiceDocumentMetadataDocumentTypeEnum.HouseBillOfLading,
        description: document?.description || ''
      }}
      validationSchema={validationSchema}
    >
      {({isValid, isSubmitting}) => (
        <Form className="w-full" noValidate>
          <FlexBox direction="col" gap="m">
            <Field
              simpleValue
              name="type"
              label="Type"
              required
              isLoading={documentTypesQuery.isLoading}
              component={FormikSelect}
              options={documentTypesQuery.data}
              getOptionLabel={(option: DocumentType) => option.name}
              getOptionValue={(option: DocumentType) => option.id}
            />
            <Field name="description" label="Description" required component={FormikTextarea} />
            <FlexBox gap="m" justify="end">
              <DeprecatedButton variant="secondary" onClick={onClose}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" loading={isSubmitting} disabled={!isValid || isSubmitting}>
                Save
              </DeprecatedButton>
            </FlexBox>
          </FlexBox>
        </Form>
      )}
    </Formik>
  );
};
