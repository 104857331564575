import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Form, Button} from 'react-bootstrap';
import {placeholder} from 'App/utils/placeholders';
import validate from 'App/containers/Shipment/validateShipmentDocUpdateForm';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderTextArea from 'App/formComponents/renderTextArea';

let ShipmentDocUpdateForm = (props) => {
  const {handleSubmit, documentTypes, handleCancel, submitting} = props;
  const footerButtons = (
    <div className="btn-footer-buttons">
      <Button
        bsStyle="default"
        disabled={submitting}
        onClick={() => {
          handleCancel();
        }}
      >
        Cancel
      </Button>
      <Button bsStyle="primary" disabled={submitting} type="submit">
        {submitting ? (
          <span>
            <i className="icon icon-Restart rotate" />
            &nbsp;&nbsp;Saving
          </span>
        ) : (
          'Save'
        )}
      </Button>
    </div>
  );

  return (
    <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <Field name="type" component={renderDropDownSelector} data={documentTypes} label="Type" />

      <Field
        name="description"
        type="text"
        minRows={3}
        component={renderTextArea}
        placeholder={placeholder.docDescription}
        req
        label="Description"
      />

      <div>{footerButtons}</div>
    </Form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ShipmentDocUpdateForm = reduxForm({
  form: 'shipmentDocUpdateForm',
  touchOnChange: true, // react-dropzone doesn't blur
  validate
})(ShipmentDocUpdateForm);

// You have to connect() to any reducers that you wish to connect to yourself
ShipmentDocUpdateForm = connect(
  (state) => ({
    initialValues: state.documents.document
  })
  // pull initial values from account reducer
)(ShipmentDocUpdateForm);

export default ShipmentDocUpdateForm;
