import {Field, FieldArray} from 'formik';
import {v4} from 'uuid';
import {
  FormikTextInput,
  FormikSelect,
  Tooltip,
  SvgIcon,
  Title,
  Card,
  DeprecatedButton,
  CollapsibleCardContent,
  FormikRadioGroup
} from '@shipwell/shipwell-ui';
import {
  DeliveryTypeEnum,
  DockSchedulingModeEnum,
  DockSchedulingEquipmentTypeEnum,
  PackagingTypes,
  DockSchedulingAccessorialTypeEnum,
  TemperatureUnitEnum,
  LoadType,
  CreateFacilityDockAppointmentRule
} from '@shipwell/tempus-sdk';
import {DockRuleDraft, LoadTypeFormData, DockDetailsFormValuesType} from 'App/data-hooks/facilities/types';
import {
  deliveryTypeOptions,
  modeOptions,
  equipmentOptions,
  dockAccessorialOptions,
  appointmentTypeOptions
} from 'App/data-hooks/facilities/constants';
import {quarterHourOptions} from 'App/utils/dateTimeGlobalsTyped';
import {useProducts, usePackageTypes} from 'App/data-hooks';

const PrivateDockTooltipInfo = () => {
  return (
    <div>
      <Title>Private Docks</Title>
      <div>Docks that are private will not be available for appointments.</div>
    </div>
  );
};

const FacilityDocksFields = ({
  currentFormIndex,
  values,
  loadTypesList,
  loadTypeOptions,
  isDetails = false
}: {
  currentFormIndex: number;
  values: DockDetailsFormValuesType;
  loadTypesList: (LoadTypeFormData | LoadType)[];
  loadTypeOptions: {label: string; value: string}[];
  isDetails?: boolean;
}) => {
  const {data: products} = useProducts({page: 1, pageSize: 1000, q: ''});
  const {data: packageTypes} = usePackageTypes();
  const formattedProducts = products?.map((product) => ({label: product.product_ref, value: product.product_ref}));
  const formattedPackageTypes = packageTypes?.map((packageType) => ({
    label: packageType.description,
    value: packageType.code
  }));
  const handleLoadTypeSelect = (
    loadTypeName: string,
    index: number,
    replace: (index: number, value: CreateFacilityDockAppointmentRule) => void
  ) => {
    const currentRuleObject = values?.docks[currentFormIndex].dockRules?.[index];
    /**
     * handle use-case when user clears out the load type field
     * the fields below are all tied to the load type name field, so we'll need to clear them out
     */
    if (!loadTypeName) {
      replace(index, {
        ...currentRuleObject,
        delivery_type: '' as DeliveryTypeEnum,
        load_type_id: '',
        mode: '' as DockSchedulingModeEnum,
        equipment_type: '' as DockSchedulingEquipmentTypeEnum,
        product_reference: '',
        packaging_type: '' as PackagingTypes,
        product_category: []
      });
      return;
    }
    const targetLoadType = isDetails
      ? loadTypesList.find((loadType) => {
          if ('id' in loadType) {
            return loadType.id === loadTypeName;
          }
        })
      : loadTypesList.find(
          (loadType, loadTypeIndex) =>
            loadType.name === loadTypeName.slice(0, -1) && loadTypeIndex === Number(loadTypeName.slice(-1))
        );
    const loadTypeIdValue =
      isDetails && loadTypeName
        ? loadTypeName
        : `${targetLoadType?.name || ''}${loadTypeName[loadTypeName?.length - 1]}`;
    replace(index, {
      ...currentRuleObject,
      delivery_type: targetLoadType?.delivery_type || ('' as DeliveryTypeEnum),
      load_type_id: loadTypeIdValue,
      mode: targetLoadType?.mode || ('' as DockSchedulingModeEnum),
      equipment_type: targetLoadType?.equipment_type || ('' as DockSchedulingEquipmentTypeEnum),
      product_reference: targetLoadType?.product_reference || '',
      packaging_type: targetLoadType?.packaging_type || ('' as PackagingTypes),
      product_category: targetLoadType?.product_category
    });
  };

  const handleAddNewRule = (push: (dockRule: DockRuleDraft) => void) => {
    push({
      load_type_id: '',
      delivery_type: '' as DeliveryTypeEnum,
      mode: '' as DockSchedulingModeEnum,
      equipment_type: '' as DockSchedulingEquipmentTypeEnum,
      product_reference: '',
      packaging_type: '' as PackagingTypes,
      product_category: [],
      dock_accessorials: [] as DockSchedulingAccessorialTypeEnum[],
      first_appointment_start_time: '',
      last_appointment_end_time: '',
      temperature: {
        unit: TemperatureUnitEnum.F,
        minimum: '',
        maximum: ''
      },
      is_public: true,
      key: v4()
    });
  };

  const handleSelectProduct = ({
    productReference,
    index,
    replace
  }: {
    productReference: string;
    index: number;
    replace: (index: number, value: CreateFacilityDockAppointmentRule) => void;
  }) => {
    const targetProduct = products?.find((product) => product.product_ref === productReference);
    const currentRuleObject = values?.docks[currentFormIndex].dockRules?.[index];
    replace(index, {
      ...currentRuleObject,
      product_reference: productReference,
      packaging_type: targetProduct?.package_type || ('' as PackagingTypes),
      product_category: targetProduct?.product_category ? [targetProduct?.product_category] : []
    });
  };

  const MaxConLoadTooltipInfo = () => {
    return (
      <div>
        <Title>Max Concurrent Loads</Title>
        <div>Total number of shipments that can be handled at a single time.</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Field required label="Dock Name" component={FormikTextInput} name={`docks[${currentFormIndex}].name`} />
      <FieldArray
        name={`docks[${currentFormIndex}].dockRules`}
        render={({push, remove, replace}) => {
          return (
            <div>
              {values?.docks?.[currentFormIndex]?.dockRules?.map((dockRule, index) => {
                const currentRule = values?.docks?.[currentFormIndex]?.dockRules?.[index];
                const loadTypeForCurrentRule = currentRule?.load_type_id;

                return (
                  <Card
                    className="mb-2"
                    draggableProvided={false}
                    title={`Rule ${index + 1}`}
                    key={'key' in dockRule ? dockRule.key : dockRule.id}
                    actions={
                      <>
                        {values?.docks?.[currentFormIndex]?.dockRules?.length > 1 ? (
                          <SvgIcon name="TrashOutlined" onClick={() => remove(index)} />
                        ) : null}
                      </>
                    }
                    isCollapsible
                    isCollapsed={'id' in currentRule}
                  >
                    <CollapsibleCardContent>
                      <div className="grid gap-y-2">
                        <div className="grid grid-cols-2 gap-x-2">
                          <Field
                            simpleValue
                            required
                            label="Appointment Type"
                            component={FormikSelect}
                            name={`docks[${currentFormIndex}].dockRules[${index}].appointment_type`}
                            options={appointmentTypeOptions}
                          />
                          <Field
                            simpleValue
                            label="Load Type"
                            component={FormikSelect}
                            name={`docks[${currentFormIndex}].dockRules[${index}].load_type_id`}
                            options={loadTypeOptions}
                            onChange={(loadTypeName: string) => handleLoadTypeSelect(loadTypeName, index, replace)}
                            clearable
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-2">
                          <Field
                            simpleValue
                            label="Delivery Type"
                            component={FormikSelect}
                            name={`docks[${currentFormIndex}].dockRules[${index}].delivery_type`}
                            options={deliveryTypeOptions}
                            disabled={loadTypeForCurrentRule}
                          />
                          <div className="flex items-center">
                            <div className="flex-1">
                              <Field
                                label="Max Concurrent Loads"
                                component={FormikTextInput}
                                type="number"
                                name={`docks[${currentFormIndex}].dockRules[${index}].max_concurrent_appointments`}
                              />
                            </div>
                            <Tooltip
                              wrapperClassname="ml-2"
                              placement="top"
                              tooltipContent={<MaxConLoadTooltipInfo />}
                              trigger="hover"
                            >
                              <SvgIcon name="InfoOutlined" color="$sw-icon" />
                            </Tooltip>
                          </div>
                        </div>
                        <Field
                          simpleValue
                          label="Mode"
                          component={FormikSelect}
                          name={`docks[${currentFormIndex}].dockRules[${index}].mode`}
                          options={modeOptions}
                          disabled={loadTypeForCurrentRule}
                        />
                        <Field
                          simpleValue
                          label="Equipment"
                          component={FormikSelect}
                          name={`docks[${currentFormIndex}].dockRules[${index}].equipment_type`}
                          options={equipmentOptions}
                          disabled={loadTypeForCurrentRule}
                        />
                        <div className="grid grid-cols-2 gap-x-2">
                          <Field
                            simpleValue
                            label="Product Reference"
                            component={FormikSelect}
                            name={`docks[${currentFormIndex}].dockRules[${index}].product_reference`}
                            options={formattedProducts}
                            onChange={(productReference: string) =>
                              handleSelectProduct({productReference, index, replace})
                            }
                            clearable
                            disabled={loadTypeForCurrentRule}
                          />
                          <Field
                            simpleValue
                            label="Packaging Type"
                            component={FormikSelect}
                            name={`docks[${currentFormIndex}].dockRules[${index}].packaging_type`}
                            options={formattedPackageTypes}
                            disabled={
                              loadTypeForCurrentRule ||
                              values?.docks?.[currentFormIndex]?.dockRules?.[index]?.product_reference
                            }
                          />
                        </div>
                        <Field
                          simpleValue
                          label="Dock Accessorials"
                          component={FormikSelect}
                          name={`docks[${currentFormIndex}].dockRules[${index}].dock_accessorials`}
                          options={dockAccessorialOptions}
                          isMulti
                        />
                        <div className="grid grid-cols-2 gap-x-2">
                          <Field
                            simpleValue
                            name={`docks[${currentFormIndex}].dockRules[${index}].first_appointment_start_time`}
                            label="First Appointment Start Time"
                            options={quarterHourOptions}
                            component={FormikSelect}
                          />
                          <Field
                            simpleValue
                            name={`docks[${currentFormIndex}].dockRules[${index}].last_appointment_end_time`}
                            label="Last Appointment End Time"
                            options={quarterHourOptions}
                            component={FormikSelect}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-x-2">
                          <div className="grid grid-cols-2 gap-x-2">
                            <Field
                              label="Minimum Temp"
                              component={FormikTextInput}
                              type="number"
                              name={`docks[${currentFormIndex}].dockRules[${index}].temperature.minimum`}
                            />
                            <Field
                              label="Maximum Temp"
                              component={FormikTextInput}
                              type="number"
                              name={`docks[${currentFormIndex}].dockRules[${index}].temperature.maximum`}
                            />
                          </div>
                          <div className="grid grid-cols-2">
                            <Field
                              name={`docks[${currentFormIndex}].dockRules[${index}].is_public`}
                              className="mt-3 flex items-center"
                              direction="row"
                              options={[
                                {label: 'Public Dock', value: true},
                                {
                                  label: 'Private Dock',
                                  value: false
                                }
                              ]}
                              component={FormikRadioGroup}
                            />
                            <Tooltip
                              wrapperClassname="items-center mr-auto"
                              placement="top"
                              tooltipContent={<PrivateDockTooltipInfo />}
                              trigger="hover"
                            >
                              <SvgIcon name="InfoOutlined" color="$sw-icon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </CollapsibleCardContent>
                  </Card>
                );
              })}
              <div className="rounded border border-sw-border p-4  ">
                <div className="flex items-center text-sw-primary">
                  <SvgIcon name="AddCircleOutlined" color="sw-primary" />
                  <DeprecatedButton variant="tertiary" onClick={() => handleAddNewRule(push)}>
                    Add Rule
                  </DeprecatedButton>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default FacilityDocksFields;
