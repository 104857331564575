import {useQuery} from '@tanstack/react-query';
import {StopLocation} from '@shipwell/corrogo-sdk';
import {Geocoder, Common} from '@trimblemaps/trimblemaps-js';
import {TRIMBLE_MAPS_GEOCODE} from 'App/data-hooks/queryKeys';
import {GeocodeResult} from 'App/components/trimbleMap/utils/typed';

function geocodeLocation(location: StopLocation): Promise<GeocodeResult> {
  const address = location.address;
  return new Promise((resolve, reject) =>
    Geocoder.geocode({
      address: {
        addr: address.line_1,
        city: address.locality,
        state: address.region,
        zip: address.postal_code,
        region: Common.Region.NA
      },
      listSize: 1,
      success: resolve,
      failure: reject
    })
  );
}

function useGeocodeLocation(location?: StopLocation) {
  return useQuery<GeocodeResult>(
    [TRIMBLE_MAPS_GEOCODE, location],
    () => {
      if (!location) {
        return Promise.reject('Invalid location');
      }
      return geocodeLocation(location);
    },
    {
      enabled: !!location
    }
  );
}

export default useGeocodeLocation;
