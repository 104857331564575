import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import DocumentSelect, {NoDocuments} from 'App/formComponents/fields/documentSelect';
import {useLegacyShipmentDocuments} from 'App/data-hooks/documents/useLegacyShipmentDocuments';
import Loader from 'App/common/shipwellLoader';
import {ShipmentBillingFormData} from 'App/containers/Shipment/forms/ShipmentBillingForm';

const BillingDocuments = ({shipmentId}: {shipmentId: string}) => {
  const {values, setFieldValue} = useFormikContext<ShipmentBillingFormData>();
  const {data: paginatedShipmentDocumentData, isLoading: isLoadingLegacyShipmentDocuments} =
    useLegacyShipmentDocuments(shipmentId);

  useEffect(() => {
    const shipmentDocuments = paginatedShipmentDocumentData?.results;
    if (!shipmentDocuments || shipmentDocuments?.length < 1) {
      return;
    }
    if (!values.documents || values.documents.length === 0) {
      const documents = shipmentDocuments.map((document) => ({
        ...document,
        add_to_bill: false
      }));

      setFieldValue('documents', documents);
    }
  }, [paginatedShipmentDocumentData, setFieldValue, values.documents]);

  const setSelectedDocumentIdsAndUpdateFormState = (selectedDocumentIds: string[]) => {
    setFieldValue(
      'documents',
      values.documents?.map((document) => {
        return {...document, add_to_bill: selectedDocumentIds.includes(document.id || '')};
      })
    );
  };

  if (isLoadingLegacyShipmentDocuments) {
    return <Loader loading additionalClassNames={['w-full h-[10rem]']} />;
  }
  const hasDocuments = values.documents?.length && values.documents?.length > 0;

  const selectedDocumentIds = values.documents
    ?.filter((document) => document.add_to_bill)
    .map((document) => document.id);

  if (hasDocuments)
    return (
      <DocumentSelect
        documents={values.documents || []}
        selectedDocuments={selectedDocumentIds}
        setSelectedDocuments={setSelectedDocumentIdsAndUpdateFormState}
        hidePreviewCta
        onClickDocument={() => undefined}
      />
    );
  return (
    <div className="mb-20">
      <NoDocuments />
    </div>
  );
};
export default BillingDocuments;
