import {useQuery} from '@tanstack/react-query';
import {getInvocationLogsScriptsInvocationsInvocationIdLogsGet} from 'App/api/scriptlab/scripts';

export interface UseInvocationLogServiceProps {
  active: boolean;
  invocationId: string;
}

export function useInvocationLogService(props: UseInvocationLogServiceProps) {
  const logQuery = useQuery({
    queryKey: ['useInvocationLogService', props.invocationId],
    queryFn: async () => {
      const result = await getInvocationLogsScriptsInvocationsInvocationIdLogsGet({invocationId: props.invocationId});
      return result.data;
    },
    enabled: props.active
  });

  return {
    data: logQuery.data,
    isLoading: logQuery.isFetching
  };
}
