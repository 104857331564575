import {Component, Fragment} from 'react';
import {Button, ProgressBar, InputGroup, Checkbox} from 'react-bootstrap';
import {DateTimePicker} from 'react-widgets';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactTable from 'react-table-6';
import Slide from '@material-ui/core/Slide';
import classnames from 'classnames';
import moment from 'moment';
import {connect} from 'react-redux';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import {Link} from 'react-router';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  scheduleTimeOptions,
  ScheduleTimeEnum
} from 'App/formComponents/formSections/shipmentStopFields/appointmentFields/utils/constants';
import ShipmentStatuses from 'App/components/shipmentStatuses';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {pickupIcon, deliveryIcon, numberWithCommas, truncateNumber, formatDate} from 'App/utils/globals';
import {getScheduleForTime, calculateShipmentTotals} from 'App/utils/globalsTyped';
import * as actions from 'App/actions/shipments';
import * as shipmentActions from 'App/actions/_shipments';
import * as documentActions from 'App/actions/documents';
import {
  getTimeErrorRows,
  getDateErrorRows,
  getRequiredDataErrorRows
} from 'App/containers/Book/components/CreateMultipleShipments/utils';
import {cleanPayload} from 'App/containers/quotes/create/utils/createQuote';
import {getDistanceBetweenStops} from 'App/api/locations';

import {withCustomFieldsProvider} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';
import SelectField from 'App/formComponents/fields/select';
import TimePicker from 'App/formComponents/fields/timePicker';
import './style.scss';
import withFlags from 'App/utils/withFlags';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const shallRenderStartTime = (scheduleTime) =>
  [ScheduleTimeEnum.At, ScheduleTimeEnum.After, ScheduleTimeEnum.Open].includes(scheduleTime);
const shallRenderEndTime = (scheduleTime) => [ScheduleTimeEnum.Before, ScheduleTimeEnum.Open].includes(scheduleTime);

@connect(
  (state) => ({
    shipmentStatuses: state.shipments.shipmentStatuses,
    unitPreferences: state.userCompany.unitPreferences,
    shipmentModes: state.shipments.shipmentModes,
    equipmentTypes: state.shipments.equipmentTypes
  }),
  {
    ...actions,
    ...documentActions,
    ...shipmentActions
  }
)
class CreateMultipleShipments extends Component {
  state = {
    shipmentData: [],
    savingShipments: false,
    showReadOnlyTable: false,
    savingErrors: [],
    errorCells: [],
    shipmentUpdates: [],
    totalDistance: null,
    timeErrorRows: [],
    dateErrorRows: [],
    requiredDataErrorRows: []
  };

  get defaultState() {
    return this.props?.shipmentToMultiply?.attrs?.carrier?.value ? 'tendered' : 'quoting';
  }

  async componentDidMount() {
    this.props.fetchShipmentStatuses();
    if (!this.state.totalDistance) {
      this.props.totalDistance
        ? this.setState({totalDistance: this.props.totalDistance})
        : await this.calculateTotalDistance(this.props.shipmentToMultiply.shipmentObj.stops);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.shipmentData !== prevState.shipmentData) {
      const {customFields} = this.props;
      const {shipmentData} = this.state;
      const requiredFieldIds = customFields.filter((cf) => cf.required).map((cf) => cf.id);
      this.setState({
        timeErrorRows: getTimeErrorRows(shipmentData),
        dateErrorRows: getDateErrorRows(shipmentData),
        requiredDataErrorRows: getRequiredDataErrorRows(shipmentData, requiredFieldIds)
      });
    }
    if (
      this.state.shipmentData.length === 0 &&
      this.props.createdShipments?.length === 0 &&
      this.props.shipmentToMultiply?.shipmentObj
    ) {
      const data = Array(Number(this.props.totalToCreate))
        .fill(this.props.shipmentToMultiply.shipmentObj)
        .map((shipment, index) => ({
          ...shipment,
          stops: [...shipment.stops.map((stop) => ({...stop}))],
          metadata: {...shipment.metadata},
          index: index + 1,
          ...(shipment.custom_data?.shipwell_custom_data?.shipment
            ? {
                custom_data: {
                  ...shipment.custom_data,
                  shipwell_custom_data: {
                    ...shipment.custom_data.shipwell_custom_data,
                    shipment: {...shipment.custom_data.shipwell_custom_data.shipment}
                  }
                }
              }
            : {})
        }));
      this.setState({preCreatingShipments: true, shipmentData: data});
    }
    if (prevProps.creatingShipments !== this.props.creatingShipments && this.props.createdShipments) {
      this.setState({shipmentData: this.props.createdShipments});
    }
    if (this.props.createdShipments !== prevProps.createdShipments) {
      this.setState({shipmentData: this.props.createdShipments});
    }
  }

  renderEditable = (cellInfo) => {
    const {value} = cellInfo;
    const hasError = this.cellHasError(cellInfo);

    return (
      <input
        name={cellInfo.column.id}
        type="text"
        value={value}
        className={classnames('multipleShipments__editable h-9 px-3.5', {
          multipleShipments__error: hasError
        })}
        onChange={(e) => {
          const shipmentData = [...this.state.shipmentData];
          set(shipmentData[cellInfo.index], cellInfo.column.id, e.target.value);
          this.setState({shipmentData});
        }}
      />
    );
  };

  renderEditableDropdown(cellInfo) {
    const value = this.state.shipmentData[cellInfo.index][cellInfo.column.id] || this.defaultState;
    return (
      <ShipmentStatuses
        simpleValue
        placeholder="Status"
        labelKey="name"
        valueKey="id"
        name="state"
        value={value}
        onChange={(value) => {
          const data = [...this.state.shipmentData];
          data[cellInfo.index].state = value;
          this.setState({shipmentData: data});
        }}
      />
    );
  }

  renderEditableCheckbox(cellInfo) {
    const hasError = this.cellHasError(cellInfo);
    return (
      <div
        className={
          hasError
            ? 'multipleShipments__editable-enabled multipleShipments__error'
            : 'multipleShipments__editable-enabled'
        }
      >
        <InputGroup>
          <Checkbox
            checked={this.state.shipmentData[cellInfo.index]?.metadata?.open}
            onChange={(e) => {
              const data = [...this.state.shipmentData];
              data[cellInfo.index].metadata.open = e.target.checked;
              this.setState({shipmentData: data});
            }}
          />
        </InputGroup>
      </div>
    );
  }

  renderEditableDatePicker(cellInfo) {
    const {dateErrorRows} = this.state;
    const hasError =
      cellInfo.column.id === 'pickup' && dateErrorRows.filter((c) => c - 1 === cellInfo.index).length > 0;

    const index = cellInfo.column.id === 'pickup' ? 0 : 1;
    const value = moment(this.state.shipmentData[cellInfo.index].stops[index].planned_date).toDate();
    return (
      <div
        className={
          hasError
            ? 'multipleShipments__editable-enabled multipleShipments__error'
            : 'multipleShipments__editable-enabled'
        }
      >
        <DateTimePicker
          format={'MMM D, YYYY'}
          value={value}
          onChange={(value) => {
            const data = [...this.state.shipmentData];
            data[cellInfo.index].stops[index].planned_date = moment(value).format('YYYY-MM-DD');
            this.setState({shipmentData: data});
          }}
          min={moment().toDate()}
          time={false}
        />
      </div>
    );
  }

  renderScheduleForTime = (cellInfo) =>
    this.state.showReadOnlyTable ? (
      scheduleTimeOptions.find((option) => option.id === cellInfo.value)?.name || ''
    ) : (
      <SelectField
        name={cellInfo.column.id}
        options={scheduleTimeOptions}
        value={cellInfo.value}
        onChange={(value) => {
          const data = [...this.state.shipmentData];
          set(data, `${cellInfo.index}.${cellInfo.column.id}`, value.id);
          this.setState({shipmentData: data});
        }}
      />
    );

  renderTime = (cellInfo) =>
    this.state.showReadOnlyTable ? (
      cellInfo.value || ''
    ) : (
      <TimePicker
        name={cellInfo.column.id}
        value={cellInfo.value}
        onChange={(value) => {
          const data = [...this.state.shipmentData];
          set(data, `${cellInfo.index}.${cellInfo.column.id}`, moment(value).format('HH:mm:ss'));
          this.setState({shipmentData: data});
        }}
      />
    );

  renderStartTime = (cellInfo) => {
    const stopIndex = cellInfo.column.id.split('.')[1];
    const scheduleSelect = this.state.shipmentData[cellInfo.index].stops[stopIndex]['schedule-select'];
    if (shallRenderStartTime(scheduleSelect)) {
      return this.renderTime(cellInfo);
    }
    return null;
  };

  renderEndTime = (cellInfo) => {
    const stopIndex = cellInfo.column.id.split('.')[1];
    const scheduleSelect = this.state.shipmentData[cellInfo.index].stops[stopIndex]['schedule-select'];
    if (shallRenderEndTime(scheduleSelect)) {
      return this.renderTime(cellInfo);
    }
    return null;
  };

  renderNormalCell = (cellInfo) => cellInfo.value;

  cellHasError(cellInfo) {
    if (this.state.errorCells.length > 0) {
      if (this.state.errorCells.filter((c) => c.index === cellInfo.index).length > 0) {
        const cellToFind = this.state.errorCells.filter((c) => c.index === cellInfo.index)[0];
        return cellToFind.errors && cellToFind.errors.find((col) => col.id === cellInfo.column.id);
      }
    }
    const {requiredDataErrorRows} = this.state;
    return requiredDataErrorRows.some((c) => c.rowIndex === cellInfo.index && cellInfo.column.id.includes(c.fieldId));
  }

  async handleCreateShipments() {
    const {errorCells, timeErrorRows, dateErrorRows, requiredDataErrorRows} = this.state;
    if ([errorCells, dateErrorRows, timeErrorRows, requiredDataErrorRows].some((array) => array.length > 0)) {
      return;
    }
    const shipmentData = this.state.shipmentData.map((shipment) => ({
      ...shipment,
      stops: shipment.stops.map((stop) => ({
        ...stop,
        planned_time_window_start: shallRenderStartTime(stop['schedule-select'])
          ? stop.planned_time_window_start
          : undefined,
        planned_time_window_end: shallRenderEndTime(stop['schedule-select'])
          ? stop.planned_time_window_end
          : stop['schedule-select'] === ScheduleTimeEnum.At
          ? stop.planned_time_window_start
          : undefined
      }))
    }));
    this.setState({
      shipmentUpdates: this.state.shipmentData.map((shipment) => ({
        //if user did not interact with these fields they are undefined so set default values
        state: shipment.state || this.defaultState,
        metadata: {open: shipment.metadata.open || false}
      })),
      shipmentData
    });
    const response = await this.props.onSubmit(shipmentData, this.props.shipmentToMultiply?.opts);
    if (response) {
      this.setState(
        {
          preCreatingShipments: false,
          showReadOnlyTable: true
        },
        () => {
          this.handleSaveShipments();
        }
      );
    }
  }

  async handleSaveShipments() {
    this.setState({savingShipments: true, saved: 0, savingErrors: [], errorCells: []});
    const shipmentsToSave = this.state.shipmentData.map((shipment) => {
      return cleanPayload(shipment, {oldValue: ['', null, 'null', NaN], hardDelete: false});
    });
    const savedShipments = await this.performAllSaves(shipmentsToSave, this.state.shipmentUpdates);
    if (savedShipments) {
      if (this.state.savingErrors.length === 0) {
        const newResults = await this.fetchAllShipments(savedShipments);
        const shipmentData = newResults?.map((shipment) => ({
          ...shipment,
          stops: shipment.stops.map((stop) => ({
            ...stop,
            'schedule-select': getScheduleForTime(stop)
          }))
        }));
        this.setState({shipmentData, savingShipments: false, showReadOnlyTable: true});
      } else {
        this.setState({savingShipments: false});
      }
    }
  }

  async performAllSaves(shipmentsToSave, shipmentUpdates) {
    //add the xShipperId
    const opts = shipmentsToSave[0].customer.id ? {xCompanyId: shipmentsToSave[0].customer.id} : {};
    const results = [];
    for (const [index, payload] of shipmentsToSave.entries()) {
      const {state: ogState, metadata: ogMetadata} = payload;
      const {state: newState, metadata: newMetadata} = shipmentUpdates[index];
      //if no changes are required then we will skip PUT shipments but handle BOL generation
      if (ogState === newState && ogMetadata.open === newMetadata.open) {
        results.push(await this.handleShipmentPut(null, payload));
      } else {
        const request = this.props.editShipment(payload.id, payload, opts, shipmentUpdates[index]);
        results.push(await this.handleShipmentPut(request, payload));
      }
    }
    return results;
  }

  /* get shipment details for a list of shipments */
  async fetchAllShipments(results) {
    const newResults = [];
    for (const result of results) {
      const request = this.props.getShipmentDetails(result?.id, {}, true);
      const response = await this.handleShipmentGet(request);
      newResults.push(response.body);
    }
    return newResults;
  }
  /* shipment get by id */
  async handleShipmentGet(request) {
    const response = await request;
    if (response) {
      return response.details;
    }
  }

  strip(html) {
    if (html) {
      return html.replace(/<(?:.|\n)*?>/gm, '');
    }
    return html;
  }

  async handleShipmentPut(request, payload) {
    const {shipmentData} = this.state;
    if (!request) {
      return await this.handleGenerateBOL(payload);
    }
    const response = await request;
    if (response?.status === 200) {
      //generate the BOL now with all of the relevant details
      return await this.handleGenerateBOL(response);
    }
    const errorsFoundOnShipments = JSON.parse(JSON.stringify(this.state.savingErrors));
    errorsFoundOnShipments.push(payload.id);
    const currentErrors = JSON.parse(JSON.stringify(this.state.errorCells));
    if (response?.err?.field_errors_condensed) {
      //set specific cell errors
      const errorFields = response.err.field_errors_condensed.map((error) => {
        //get the list of fields that errored
        return {id: error.field_name};
      });
      //log out the errors here in case they belong to fields that aren't on display here.
      const cellInfo = {
        index: shipmentData.indexOf(shipmentData.filter((shipment) => shipment.id === payload.id)[0])
      };
      if (currentErrors.filter((e) => e.index === cellInfo.index).length > 0) {
        //overwrite the errors in state for this row
        currentErrors[currentErrors.findIndex((ind) => ind.index === cellInfo.index)].errors = errorFields;
      } else {
        //create the errors in state for this row
        currentErrors.push({index: cellInfo.index, errors: errorFields});
      }
      //show an error
    }
    this.setState({savingErrors: errorsFoundOnShipments, errorCells: currentErrors});
  }

  async handleGenerateBOL(response) {
    if (!this.props.pdfGeneratorApiForDocumentGeneration) {
      const responseBody = response.details?.body || response;
      const BOLResponse = await this.props.generateBOL(responseBody.id);

      if (BOLResponse.status === 200) {
        this.setState({saved: this.state.saved + 1});
        return responseBody;
      }
      //show errors
      console.error(BOLResponse);
    }
    return response;
  }

  async calculateTotalDistance(shipmentStops) {
    try {
      const results = await Promise.all(
        shipmentStops.slice(0, -1).map((stop, index) =>
          getDistanceBetweenStops({
            origin: stop.location.address,
            destination: shipmentStops[index + 1].location.address
          })
        )
      );
      if (results) {
        const totalDistance = results.reduce((sum, distance) => sum + distance.body.distance_miles, 0);
        this.setState({totalDistance: totalDistance});
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      open,
      creatingShipments,
      createdShipments,
      created,
      totalToCreate,
      infoOnly,
      numberToCreate,
      shipmentStatuses,
      customFields = [],
      unitPreferences,
      shipmentToMultiply,
      equipmentTypes,
      shipmentModes,
      cancel,
      close
    } = this.props;
    const {shipmentData, savingShipments, showReadOnlyTable, saved, preCreatingShipments, totalDistance} = this.state;
    const progress = creatingShipments
      ? Math.round((created / totalToCreate) * 100)
      : savingShipments
      ? Math.round((saved / shipmentData.length) * 100)
      : 100;
    const shipment =
      (createdShipments && createdShipments.length > 0 && createdShipments[0]) || shipmentToMultiply?.attrs;

    let pickupLocationString = '';
    let pickupTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length > 0 && shipment.stops[0]) {
      pickupTimeString += shipment.stops[0].display_planned_window || '';
      if (shipment.stops[0].location && shipment.stops[0].location.address) {
        pickupLocationString +=
          shipment.stops[0].location.address.city + ', ' + shipment.stops[0].location.address.state_province;
      }
    }

    let deliveryLocationString = '';
    let deliveryTimeString = '';
    if (shipment && shipment.stops && shipment.stops.length > 1) {
      deliveryTimeString = shipment.stops[shipment.stops.length - 1].display_planned_window || '';
      if (
        shipment.stops[shipment.stops.length - 1].location &&
        shipment.stops[shipment.stops.length - 1].location.address
      ) {
        deliveryLocationString =
          shipment.stops[shipment.stops.length - 1].location.address.city +
          ', ' +
          shipment.stops[shipment.stops.length - 1].location.address.state_province;
      }
    }

    const totals =
      Array.isArray(shipment?.line_items) &&
      shipment.line_items.length > 0 &&
      calculateShipmentTotals({
        line_items: shipment.line_items,
        unitPreferences,
        totalWeight: shipment.total_weight_override || {}
      });
    const totalMiles = numberWithCommas(shipment?.total_miles || totalDistance);
    const totalWeight =
      shipment?.total_weight_override?.value || totals?.weight
        ? numberWithCommas(truncateNumber(shipment?.total_weight_override?.value || totals?.weight, 0))
        : '--';

    const columns = [
      preCreatingShipments && !showReadOnlyTable
        ? {
            Header: 'Index',
            accessor: 'index',
            width: 75,
            Cell: this.renderNormalCell
          }
        : {
            Header: 'ID',
            accessor: 'reference_id',
            width: 75,
            Cell: (row) => (
              <Link to={`/shipments/${row.original.id}`} target="_blank">
                {row.value}
              </Link>
            )
          },
      {
        Header: 'Status',
        accessor: 'state',
        width: 105,
        Cell: showReadOnlyTable
          ? (row) => shipmentStatuses.find((e) => e.id === row.value)?.label
          : this.renderEditableDropdown.bind(this)
      },
      {
        Header: 'Open',
        width: 50,
        id: 'open',
        accessor: (d) => d.metadata && d.metadata.open,
        Cell: showReadOnlyTable ? (row) => (row.value ? 'Open' : '') : this.renderEditableCheckbox.bind(this)
      },
      {
        Header: 'Pickup',
        ...(!showReadOnlyTable && {width: 150}),
        accessor: 'pickup',
        Cell: showReadOnlyTable
          ? (row) => {
              if (row.original.stops && row.original.stops[0]) {
                return formatDate(row.original.stops[0].planned_date);
              }
            }
          : this.renderEditableDatePicker.bind(this)
      },
      {
        Header: 'Scheduled for Time',
        accessor: 'stops.0.schedule-select',
        Cell: this.renderScheduleForTime
      },
      {
        Header: 'Start Time',
        accessor: 'stops.0.planned_time_window_start',
        Cell: this.renderStartTime
      },
      {
        Header: 'Stop Time',
        accessor: 'stops.0.planned_time_window_end',
        Cell: this.renderEndTime
      },
      {
        Header: 'Delivery',
        ...(!showReadOnlyTable && {width: 150}),
        accessor: 'delivery',
        Cell: showReadOnlyTable
          ? (row) => {
              if (row.original.stops && row.original.stops[1]) {
                return formatDate(row.original.stops[1].planned_date);
              }
            }
          : this.renderEditableDatePicker.bind(this)
      },
      {
        Header: 'Scheduled for Time',
        accessor: 'stops.1.schedule-select',
        Cell: this.renderScheduleForTime
      },
      {
        Header: 'Start Time',
        accessor: 'stops.1.planned_time_window_start',
        Cell: this.renderStartTime
      },
      {
        Header: 'Stop Time',
        accessor: 'stops.1.planned_time_window_end',
        Cell: this.renderEndTime
      },
      {
        Header: 'Pickup #',
        accessor: 'pickup_number',
        Cell: showReadOnlyTable ? this.renderNormalCell : this.renderEditable
      },
      {
        Header: 'PO #',
        accessor: 'purchase_order_number',
        Cell: showReadOnlyTable ? this.renderNormalCell : this.renderEditable
      },
      {
        Header: 'Customer Ref #',
        accessor: 'customer_reference_number',
        Cell: showReadOnlyTable ? this.renderNormalCell : this.renderEditable
      }
    ];

    const requiredCustomFields = customFields.filter((cf) => cf.required);
    if (requiredCustomFields.length > 0) {
      //find company required custom_refs to add to multiple shipments table
      columns.push(
        ...requiredCustomFields.map((cf) => ({
          Header: cf.label,
          accessor: `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`,
          Cell: showReadOnlyTable ? this.renderNormalCell : this.renderEditable
        }))
      );
    }

    const showLoading = !totalMiles || shipmentData.length === 0;
    const showTable = preCreatingShipments || createdShipments?.length > 0;
    const showActionButtons =
      (preCreatingShipments || !createdShipments?.length > 0) &&
      shipmentData.length > 0 &&
      !showReadOnlyTable &&
      !creatingShipments &&
      totalMiles;
    const showDoneButton = showReadOnlyTable && !savingShipments && shipmentData.length > 0 && totalMiles;
    return (
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="multipleShipments__title">
          {infoOnly ? (
            <span>Are you sure you want to build {numberToCreate} shipments?</span>
          ) : creatingShipments ? (
            <span>Creating your shipments...</span>
          ) : savingShipments ? (
            <span>Setting shipment statuses...</span>
          ) : showReadOnlyTable ? (
            <span>
              <i className="text-success flaticon-check_filled" /> {shipmentData.length} shipments built!
            </span>
          ) : showLoading ? (
            <span>Preparing shipments</span>
          ) : (
            <span>Creating {totalToCreate} shipments</span>
          )}
        </DialogTitle>
        <DialogContent className="multipleShipments__content">
          {creatingShipments ? (
            <div className="multipleShipments__loader">
              <ShipwellLoader loading />
              <ProgressBar now={progress} label={`${progress}%`} />
            </div>
          ) : savingShipments ? (
            <div className="multipleShipments__loader">
              <ShipwellLoader loading />
              <ProgressBar now={progress} label={`${progress}%`} />
            </div>
          ) : !totalMiles || shipmentData.length === 0 ? (
            <div className="multipleShipments__loader">
              <ShipwellLoader loading />
            </div>
          ) : (
            <div className="multipleShipments">
              {showReadOnlyTable && <p>Click the shipment ID below or find your shipments on your dashboard.</p>}
              <div className="acceptNow__content">
                <div className="acceptNow__content-stops">
                  <span className="acceptNow__content-stop">
                    <span className="acceptNow__content-icon">{pickupIcon}</span>
                    <span className="acceptNow__content-text">
                      <span className="acceptNow__content-address">{pickupLocationString}</span>
                      <span className="acceptNow__content-time">{pickupTimeString}</span>
                    </span>
                  </span>
                  <span className="acceptNow__content-stop">
                    <span className="acceptNow__content-icon">{deliveryIcon}</span>
                    <span className="acceptNow__content-text">
                      <span className="acceptNow__content-address">{deliveryLocationString}</span>
                      <span className="acceptNow__content-time">{deliveryTimeString}</span>
                    </span>
                  </span>
                </div>
                <div className="acceptNow__content-details">
                  <div className="acceptNow__content-columns multipleShipments__details">
                    <span className="acceptNow__content-column">
                      <b>Customer: </b>
                      {shipment?.customer?.name || shipment?.book_as_customer?.label}
                    </span>
                    <span className="acceptNow__content-column">
                      <b>Mode: </b>
                      {shipment?.mode?.code ||
                        shipmentModes.filter((e) => e.id === Number(shipment.mode))[0].description}{' '}
                    </span>
                    <span className="acceptNow__content-column">
                      <b>Equipment: </b>
                      {shipment?.equipment_type?.name ||
                        equipmentTypes.filter((e) => e.id === Number(shipment.equipment_type))[0].name}
                    </span>
                    <span className="acceptNow__content-column">
                      <span>
                        <b>Weight: </b>
                        {totalWeight} lbs
                      </span>
                    </span>
                    <span className="acceptNow__content-column">
                      <span>
                        <b>Distance: </b>
                        {totalMiles} mi
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {this.state.savingErrors && this.state.savingErrors.length > 0 && (
                <Fragment>
                  <p className="text-danger text-center font-bold">Errors found when saving the following shipments:</p>
                  {this.state.savingErrors.map((err, i) => {
                    return (
                      <p key={i} className="text-danger text-center">
                        {shipmentData &&
                          shipmentData.length > 0 &&
                          shipmentData.filter((e) => e && e.id === err).length > 0 &&
                          shipmentData.filter((e) => e && e.id === err)[0].reference_id}
                      </p>
                    );
                  })}
                </Fragment>
              )}
              {this.state.dateErrorRows.length > 0 ? (
                <div className="text-danger text-center">
                  <span className="font-bold">The following rows contain nonsequential dates:&nbsp;</span>
                  {this.state.dateErrorRows.join(', ')}
                </div>
              ) : null}
              {this.state.timeErrorRows.length > 0 ? (
                <div className="text-danger text-center">
                  <span className="font-bold">The following rows contain nonsequential time:&nbsp;</span>
                  {this.state.timeErrorRows.join(', ')}
                </div>
              ) : null}
              {this.state.requiredDataErrorRows.length > 0 ? (
                <div className="text-danger text-center">
                  <span className="font-bold">The following rows contain missing required fields:&nbsp;</span>
                  {uniq(this.state.requiredDataErrorRows.map((row) => row.rowIndex + 1)).join(', ')}
                </div>
              ) : null}

              {showTable && <ReactTable data={shipmentData} showPagination minRows={0} columns={columns} />}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {showActionButtons && (
            <>
              <Button onClick={cancel} bsStyle="default">
                Cancel
              </Button>
              <Button onClick={this.handleCreateShipments.bind(this)} bsStyle="primary">
                Save
              </Button>
            </>
          )}
          {showDoneButton && (
            <Button onClick={close} bsStyle="primary">
              Done
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

CreateMultipleShipments = withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment)(
  withFlags('pdfGeneratorApiForDocumentGeneration')(CreateMultipleShipments)
);

export default CreateMultipleShipments;
