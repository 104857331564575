import {FormGroup, OverlayTrigger, Tooltip, InputGroup, Checkbox} from 'react-bootstrap';

const renderCheckBox = ({extraClass, input, checked, disabled, tooltip, label, meta: {touched, error}}) => (
  <FormGroup className={extraClass && extraClass} validationState={touched && error ? 'error' : null}>
    <InputGroup>
      <Checkbox
        checked={input.value}
        disabled={disabled}
        onChange={(e) => {
          input.onChange(e.target.checked);
        }}
      >
        {label}
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <div>{tooltip}</div>
              </Tooltip>
            }
          >
            <a tabIndex="-1">
              <i className="icon icon-Info" aria-hidden="true" />
            </a>
          </OverlayTrigger>
        )}
      </Checkbox>
      {touched && error && (
        <p className="error-text">
          <i className="icon icon-Delayed" />
          {error}
        </p>
      )}
    </InputGroup>
  </FormGroup>
);

export default renderCheckBox;
