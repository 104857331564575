import {SvgIcon} from '@shipwell/shipwell-ui';
import {useGetCarrierTags} from 'App/data-hooks';
import {isDefined} from 'App/utils/guard';

export const ServiceProviderTags = ({tagIds}: {tagIds: string[]}) => {
  const {paginatedCarrierTags, isLoading} = useGetCarrierTags({pageSize: 1000, ordering: 'name'});

  if (paginatedCarrierTags) {
    const tagNames = tagIds
      .map((tagId) => {
        return paginatedCarrierTags?.results?.find((tag) => tag.id === tagId)?.name;
      })
      .filter(isDefined);

    return <span>{tagNames.length ? tagNames.join(', ') : '--'}</span>;
  }
  if (isLoading) return <SvgIcon aria-hidden className="m-auto" name="LoadingDots" height="9" />;

  return null;
};
