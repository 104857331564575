import {TrackingApiStopResourceTrackingRequest} from '@shipwell/locus-sdk';

export function getStopResourceTrackingFromFormData(shipmentId: string, deviceId: string) {
  const data: TrackingApiStopResourceTrackingRequest = {
    stopResourceTrackingConfigBody: {
      provider: 'tive',
      resource_id: shipmentId,
      resource_type: 'SHIPMENT',
      custom_data: {
        device_id: deviceId
      }
    }
  };

  return data;
}
