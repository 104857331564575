import {UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {SaferWatchCarrierPolicy, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SAFERWATCH_COMPLIANCE_POLICY} from '../queryKeys';
import {getSaferwatchCarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseSaferwatchPolicyQueryOptions = Omit<
  UseQueryOptions<SaferWatchCarrierPolicy, AxiosError<ShipwellError>, SaferWatchCarrierPolicy, string[]>,
  'queryKey' | 'queryFn'
>;

const useSaferwatchPolicyQuery = (options?: UseSaferwatchPolicyQueryOptions) => {
  const queryClient = useQueryClient();
  const query = useQuery([SAFERWATCH_COMPLIANCE_POLICY], getSaferwatchCarrierCompliancePolicy, options);

  return {
    isSaferwatchInitialLoading: query.isInitialLoading,
    isSaferwatchLoading: query.isLoading,
    isSaferwatchFetching: query.isFetching,
    isSaferwatchFetched: query.isFetched,
    isSaferwatchSuccess: query.isSuccess,
    isSaferwatchError: query.isError,
    saferwatchError: query.error || null,
    saferwatchPolicy: query.data || null,
    refetchSaferwatchPolicy: query.refetch,
    invalidateSaferwatchPolicyQuery: () => queryClient.invalidateQueries([SAFERWATCH_COMPLIANCE_POLICY])
  };
};

export default useSaferwatchPolicyQuery;
