import {ReactNode} from 'react';
import classnames from 'classnames';

function StageCard({children, isActive = false}: {children: ReactNode; isActive?: boolean}) {
  return (
    <div
      className={classnames(
        'my-4',
        'rounded',
        'border',
        'border-l-8',
        {'border-sw-active': isActive},
        {'border-sw-disabled': !isActive},
        'p-2',
        'shadow-md'
      )}
    >
      {children}
    </div>
  );
}

export default StageCard;
