import {useCallback} from 'react';
import {WithRouterProps} from 'react-router';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {FieldArray, Form, Formik} from 'formik';
import {v4} from 'uuid';
import {AxiosError} from 'axios';
import {
  DeliveryTypeEnum,
  DockSchedulingAccessorialTypeEnum,
  DockSchedulingEquipmentTypeEnum,
  DockSchedulingModeEnum,
  PackagingTypes,
  ShipwellApiErrorResponse,
  TemperatureUnitEnum
} from '@shipwell/tempus-sdk';
import {SvgIcon, Card, DeprecatedButton} from '@shipwell/shipwell-ui';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import Error404Page from 'App/common/Error404Page';
import Loader from 'App/common/shipwellLoader';
import {
  useGetDockDetails,
  formatDocksDetailsPayload,
  useUpdateDock,
  useCreateDock,
  useDeleteDock,
  useUpdateDockRule,
  useCreateDockRule,
  useDeleteDockRule,
  assignDockIdToDockRule,
  useFacilityQuery
} from 'App/data-hooks';
import {facilityDocksValidationSchema} from 'App/containers/facilities/schema';
import FacilityDocksFields from 'App/formComponents/formSections/facilityDocksFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {DockDetailsFormValuesType} from 'App/data-hooks/facilities/types';
import {parseV3ApiError} from 'App/api/typedUtils';

type FacilityDocksProps = Omit<WithRouterProps<{id: string}>, 'location' | 'router' | 'routes'> & WithStatusToastProps;

const NewDockType = {
  name: '',
  dockRules: [
    {
      load_type_id: '',
      delivery_type: '' as DeliveryTypeEnum,
      mode: '' as DockSchedulingModeEnum,
      equipment_type: '' as DockSchedulingEquipmentTypeEnum,
      product_reference: '',
      packaging_type: '' as PackagingTypes,
      dock_accessorials: [] as DockSchedulingAccessorialTypeEnum[],
      first_appointment_start_time: '',
      last_appointment_end_time: '',
      temperature: {
        unit: TemperatureUnitEnum.F,
        minimum: '',
        maximum: ''
      },
      is_public: true,
      key: v4()
    }
  ]
};

const FacilityDocksBase = ({params: {id: facilityId}, setError, setSuccess}: FacilityDocksProps) => {
  const {fiDockScheduling} = useFlags();
  const {facility, isFacilityLoading} = useFacilityQuery({
    facilityId
  });
  const onError = useCallback(
    (error: AxiosError<ShipwellApiErrorResponse>) => {
      const formattedError = parseV3ApiError(error);
      if (formattedError.title && formattedError.detail) {
        setError(formattedError.title, formattedError.detail);
      } else if (formattedError.isError) {
        setError('Load Types update failed', 'Unrecognized internal error.');
      }
    },
    [setError]
  );
  const onSuccess = useCallback(() => {
    setSuccess('Update successful!', `Successfully updated facility ${facility?.name ?? ''}.`);
  }, [setSuccess, facility]);

  const {formattedDocksWithDockRules, isGetDockDetailsLoading, fetchedLoadTypes} = useGetDockDetails(facilityId, {
    onError
  });
  const {mutateAsync: updateDockMutation} = useUpdateDock({onSuccess, onError});
  const {mutateAsync: createDockMutation} = useCreateDock({onSuccess, onError});
  const {mutateAsync: deleteDockMutation} = useDeleteDock({onSuccess, onError});

  const {mutateAsync: updateDockRuleMutation} = useUpdateDockRule({onSuccess, onError});
  const {mutateAsync: createDockRuleMutation} = useCreateDockRule({onSuccess, onError});
  const {mutateAsync: deleteDockRuleMutation} = useDeleteDockRule({onSuccess, onError});

  const handleDockDetailsSubmit = async (values: DockDetailsFormValuesType) => {
    const {docks} = values;
    const docksWithAddedDockIds = assignDockIdToDockRule(docks);

    const {
      toBeUpdatedDocks,
      toBeCreatedDocks,
      toBeDeletedDocks,
      toBeUpdatedDockRules,
      toBeCreatedDockRules,
      toBeDeletedDockRules
    } = formatDocksDetailsPayload({
      fetchedDocksWithDockRules: formattedDocksWithDockRules,
      submittedDocksWithDockRules: docksWithAddedDockIds
    });
    // update docks
    if (toBeUpdatedDocks.length) {
      const updateFacilityDocksMutations = toBeUpdatedDocks.map((toBeUpdateDock) =>
        updateDockMutation({
          dockId: toBeUpdateDock.id,
          facilityId,
          updateFacilityDock: {...toBeUpdateDock, name: toBeUpdateDock.name}
        })
      );
      await Promise.allSettled(updateFacilityDocksMutations);
    }
    let newlyCreatedDocks;
    // create docks
    if (toBeCreatedDocks.length) {
      const createFacilityDocksMutations = toBeCreatedDocks.map(({name}) =>
        createDockMutation({
          facilityId,
          body: {
            name: name,
            color: '#FFFFFF'
          }
        })
      );
      newlyCreatedDocks = await Promise.all(createFacilityDocksMutations);
    }
    // create dock rules followed by the docks
    if (toBeCreatedDockRules.length) {
      const flattenedNewlyCreatedDockIds = newlyCreatedDocks
        ?.map((newlyCreatedDock) => newlyCreatedDock.data)
        .map((newlyCreatedDock) => ({name: newlyCreatedDock.name, id: newlyCreatedDock.id}));

      const allDockIds = [...(flattenedNewlyCreatedDockIds || []), ...formattedDocksWithDockRules];
      const createdDocksList = allDockIds?.map((dockWithDockRules) => ({
        name: dockWithDockRules.name,
        id: dockWithDockRules.id
      }));
      const dockIds = createdDocksList?.map((dock) => dock.id);
      toBeCreatedDockRules.map(
        ({
          dock_accessorials,
          equipment_type,
          dock_id,
          delivery_type,
          mode,
          product_category,
          product_reference,
          packaging_type,
          stackable,
          is_public,
          is_hazmat,
          temperature,
          load_type_id,
          first_appointment_start_time,
          last_appointment_end_time,
          appointment_type,
          max_concurrent_appointments
        }) => {
          const dockIdMatchedById = dockIds?.find((dockId) => dockId === dock_id) || '';
          const dockIdMatchedByName = createdDocksList?.find((dock) => dock.name === dock_id)?.id || '';
          const dockId = dockIds?.includes(dock_id || '') ? dockIdMatchedById : dockIdMatchedByName;

          return createDockRuleMutation({
            facilityId,
            dockId,
            body: {
              equipment_type,
              delivery_type,
              mode,
              product_category,
              product_reference,
              packaging_type,
              stackable,
              is_public,
              is_hazmat,
              temperature,
              load_type_id,
              first_appointment_start_time,
              last_appointment_end_time,
              appointment_type,
              max_concurrent_appointments,
              dock_accessorials: dock_accessorials || []
            }
          });
        }
      );
    }
    // delete docks
    if (toBeDeletedDocks.length) {
      const deleteFacilityDocksMutations = toBeDeletedDocks.map((toBeDeletedDock) =>
        deleteDockMutation({dockId: toBeDeletedDock.id, facilityId})
      );
      await Promise.allSettled(deleteFacilityDocksMutations);
    }

    // update dock rules
    if (toBeUpdatedDockRules.length) {
      const updateFacilityDockRulesMutations = toBeUpdatedDockRules.map((toBeUpdatedDockRule) =>
        updateDockRuleMutation({
          ruleId: toBeUpdatedDockRule.id,
          facilityId,
          dockId: toBeUpdatedDockRule.dock_id,
          updateFacilityDockAppointmentRule: {
            ...toBeUpdatedDockRule,
            dock_accessorials: toBeUpdatedDockRule.dock_accessorials || [],
            temperature: {...toBeUpdatedDockRule.temperature, unit: TemperatureUnitEnum.F}
          }
        })
      );
      await Promise.allSettled(updateFacilityDockRulesMutations);
    }
    // delete dock rules
    if (toBeDeletedDockRules.length) {
      const deleteDockRulesMutations = toBeDeletedDockRules.map((toBeDeletedDockRule) =>
        deleteDockRuleMutation({ruleId: toBeDeletedDockRule.id, facilityId, dockId: toBeDeletedDockRule.dock_id})
      );
      await Promise.allSettled(deleteDockRulesMutations);
    }
  };
  if (!fiDockScheduling) {
    return <Error404Page />;
  }

  if (isGetDockDetailsLoading || isFacilityLoading) return <Loader loading />;
  return (
    <Formik
      enableReinitialize
      initialValues={{docks: formattedDocksWithDockRules || []} as DockDetailsFormValuesType}
      validationSchema={facilityDocksValidationSchema}
      onSubmit={handleDockDetailsSubmit}
    >
      {({isValid, values, dirty, resetForm, errors}) => (
        <>
          <Form noValidate role="form">
            <FieldArray name="docks">
              {({push, remove}) => (
                <>
                  <div className="grid h-full grid-flow-row">
                    <h2 className="sw-page-header border-b border-sw-border pb-3">{facility?.name}</h2>
                    <Card
                      draggableProvided={false}
                      title="Docks"
                      actions={
                        <div className="flex min-h-4 min-w-max">
                          <DeprecatedButton
                            variant="tertiary"
                            icon={<SvgIcon name="AddCircleOutlined" />}
                            onClick={() => push({...NewDockType, key: v4()})}
                          >
                            Add Dock
                          </DeprecatedButton>
                        </div>
                      }
                    >
                      {values?.docks?.map((dock, index) => (
                        <Card
                          draggableProvided={false}
                          title={`Dock ${index + 1}`}
                          className="my-4"
                          key={'key' in dock ? dock.key : dock.id}
                          actions={
                            <>
                              {values?.docks?.length > 1 ? (
                                <SvgIcon name="TrashOutlined" onClick={() => remove(index)} />
                              ) : null}
                            </>
                          }
                        >
                          <FacilityDocksFields
                            isDetails
                            currentFormIndex={index}
                            loadTypesList={fetchedLoadTypes || []}
                            values={values}
                            loadTypeOptions={
                              fetchedLoadTypes?.map((fetchedLoadType) => ({
                                label: fetchedLoadType.name,
                                value: fetchedLoadType.id
                              })) || []
                            }
                          />
                          {!Array.isArray(errors.docks) ? (
                            <div className="my-2 text-sw-error">{errors?.docks}</div>
                          ) : null}
                        </Card>
                      ))}
                    </Card>
                    <div className={dirty ? 'h-24' : 'h-4'}></div>
                    {dirty ? (
                      <FormFooter secondaryActionName="Reset" onCancel={resetForm} dirty={dirty && isValid} />
                    ) : null}
                  </div>
                </>
              )}
            </FieldArray>
          </Form>
        </>
      )}
    </Formik>
  );
};
const FacilityDocks = WithStatusToasts(FacilityDocksBase);
export default FacilityDocks;
