const threeDigitZipCodes: {[key: string]: {id: string; city: string; state?: string}} = {
  '000': {id: '000', city: 'Placeholders for areas with no proper zip code or addresses'},
  '001': {id: '001', city: 'Destinations outside U.S.'},
  '002': {id: '002', city: 'Not in use'},
  '003': {id: '003', city: 'Not in use'},
  '004': {id: '004', city: 'Not in use'},
  '005': {id: '005', city: 'Holtsville', state: 'NY'},
  '006': {id: '006', city: 'San Juan', state: 'PR'},
  '007': {id: '007', city: 'San Juan', state: 'PR'},
  '008': {id: '008', city: 'Virgin Islands', state: 'VI'},
  '009': {id: '009', city: 'San Juan', state: 'PR'},
  '010': {id: '010', city: 'Springfield', state: 'MA'},
  '011': {id: '011', city: 'Springfield', state: 'MA'},
  '012': {id: '012', city: 'Pittsfield', state: 'MA'},
  '013': {id: '013', city: 'Greenfield', state: 'MA'},
  '014': {id: '014', city: 'Fitchburg', state: 'MA'},
  '015': {id: '015', city: 'Worcester', state: 'MA'},
  '016': {id: '016', city: 'Worcester', state: 'MA'},
  '017': {id: '017', city: 'Framingham', state: 'MA'},
  '018': {id: '018', city: 'Woburn', state: 'MA'},
  '019': {id: '019', city: 'Lynn', state: 'MA'},
  '020': {id: '020', city: 'Brockton', state: 'MA'},
  '021': {id: '021', city: 'Boston', state: 'MA'},
  '022': {id: '022', city: 'Boston', state: 'MA'},
  '023': {id: '023', city: 'Brockton', state: 'MA'},
  '024': {id: '024', city: 'Lexington', state: 'MA'},
  '025': {id: '025', city: 'Cape Cod', state: 'MA'},
  '026': {id: '026', city: 'Cape Cod', state: 'MA'},
  '027': {id: '027', city: 'Providence', state: 'RI'},
  '028': {id: '028', city: 'Providence', state: 'RI'},
  '029': {id: '029', city: 'Providence', state: 'RI'},
  '030': {id: '030', city: 'Manchester', state: 'NH'},
  '031': {id: '031', city: 'Manchester', state: 'NH'},
  '032': {id: '032', city: 'Waterville Valley', state: 'NH'},
  '033': {id: '033', city: 'Concord', state: 'NH'},
  '034': {id: '034', city: 'Keene', state: 'NH'},
  '035': {id: '035', city: 'Littleton', state: 'NH'},
  '036': {id: '036', city: 'Bellows Falls', state: 'VT'},
  '037': {id: '037', city: 'White River Junction', state: 'VT'},
  '038': {id: '038', city: 'Portsmouth', state: 'NH'},
  '039': {id: '039', city: 'Portsmouth', state: 'NH'},
  '040': {id: '040', city: 'Portland', state: 'ME'},
  '041': {id: '041', city: 'Portland', state: 'ME'},
  '042': {id: '042', city: 'Auburn', state: 'ME'},
  '043': {id: '043', city: 'Augusta', state: 'ME'},
  '044': {id: '044', city: 'Bangor', state: 'ME'},
  '045': {id: '045', city: 'Bath', state: 'ME'},
  '046': {id: '046', city: 'Ellsworth', state: 'ME'},
  '047': {id: '047', city: 'Houlton', state: 'ME'},
  '048': {id: '048', city: 'Rockland', state: 'ME'},
  '049': {id: '049', city: 'Waterville', state: 'ME'},
  '050': {id: '050', city: 'White River Junction', state: 'VT'},
  '051': {id: '051', city: 'Bellows Falls', state: 'VT'},
  '052': {id: '052', city: 'Bennington', state: 'VT'},
  '053': {id: '053', city: 'Brattleboro', state: 'VT'},
  '054': {id: '054', city: 'Burlington', state: 'VT'},
  '055': {id: '055', city: 'Andover', state: 'MA'},
  '056': {id: '056', city: 'Montpelier', state: 'VT'},
  '057': {id: '057', city: 'Rutland', state: 'VT'},
  '058': {id: '058', city: 'St. Johnsbury', state: 'VT'},
  '059': {id: '059', city: 'St. Johnsbury', state: 'VT'},
  '060': {id: '060', city: 'Hartford', state: 'CT'},
  '061': {id: '061', city: 'Hartford', state: 'CT'},
  '062': {id: '062', city: 'Willimantic', state: 'CT'},
  '063': {id: '063', city: 'New London', state: 'CT'},
  '064': {id: '064', city: 'New Haven', state: 'CT'},
  '065': {id: '065', city: 'New Haven', state: 'CT'},
  '066': {id: '066', city: 'Bridgeport', state: 'CT'},
  '067': {id: '067', city: 'Waterbury', state: 'CT'},
  '068': {id: '068', city: 'Stamford', state: 'CT'},
  '069': {id: '069', city: 'Stamford', state: 'CT'},
  '070': {id: '070', city: 'Newark', state: 'NJ'},
  '071': {id: '071', city: 'Newark', state: 'NJ'},
  '072': {id: '072', city: 'Elizabeth', state: 'NJ'},
  '073': {id: '073', city: 'Jersey City', state: 'NJ'},
  '074': {id: '074', city: 'Paterson', state: 'NJ'},
  '075': {id: '075', city: 'Paterson', state: 'NJ'},
  '076': {id: '076', city: 'Hackensack', state: 'NJ'},
  '077': {id: '077', city: 'Red Bank', state: 'NJ'},
  '078': {id: '078', city: 'Dover', state: 'NJ'},
  '079': {id: '079', city: 'Summit', state: 'NJ'},
  '080': {id: '080', city: 'Cherry Hill', state: 'NJ'},
  '081': {id: '081', city: 'Camden', state: 'NJ'},
  '082': {id: '082', city: 'Atlantic City', state: 'NJ'},
  '083': {id: '083', city: 'Bridgeton', state: 'NJ'},
  '084': {id: '084', city: 'Atlantic City', state: 'NJ'},
  '085': {id: '085', city: 'Trenton', state: 'NJ'},
  '086': {id: '086', city: 'Trenton', state: 'NJ'},
  '087': {id: '087', city: 'Lakewood', state: 'NJ'},
  '088': {id: '088', city: 'New Brunswick', state: 'NJ'},
  '089': {id: '089', city: 'New Brunswick', state: 'NJ'},
  '090': {id: '090', city: 'Military', state: 'AE'},
  '091': {id: '091', city: 'Military', state: 'AE'},
  '092': {id: '092', city: 'Military', state: 'AE'},
  '093': {id: '093', city: 'Military', state: 'AE'},
  '094': {id: '094', city: 'Military', state: 'AE'},
  '095': {id: '095', city: 'Military', state: 'AE'},
  '096': {id: '096', city: 'Military', state: 'AE'},
  '097': {id: '097', city: 'Military', state: 'AE'},
  '098': {id: '098', city: 'Military', state: 'AE'},
  '099': {id: '099', city: 'Military', state: 'AE'},
  '100': {id: '100', city: 'New York', state: 'NY'},
  '101': {id: '101', city: 'New York', state: 'NY'},
  '102': {id: '102', city: 'New York', state: 'NY'},
  '103': {id: '103', city: 'Staten Island', state: 'NY'},
  '104': {id: '104', city: 'The Bronx', state: 'NY'},
  '105': {id: '105', city: 'White Plains', state: 'NY'},
  '106': {id: '106', city: 'White Plains', state: 'NY'},
  '107': {id: '107', city: 'Yonkers', state: 'NY'},
  '108': {id: '108', city: 'New Rochelle', state: 'NY'},
  '109': {id: '109', city: 'Suffern', state: 'NY'},
  '110': {id: '110', city: 'Floral Park', state: 'NY'},
  '111': {id: '111', city: 'Long Island City', state: 'NY'},
  '112': {id: '112', city: 'Brooklyn', state: 'NY'},
  '113': {id: '113', city: 'Flushing', state: 'NY'},
  '114': {id: '114', city: 'Jamaica', state: 'NY'},
  '115': {id: '115', city: 'Mineola', state: 'NY'},
  '116': {id: '116', city: 'Far Rockaway', state: 'NY'},
  '117': {id: '117', city: 'Amityville', state: 'NY'},
  '118': {id: '118', city: 'Hicksville', state: 'NY'},
  '119': {id: '119', city: 'Riverhead', state: 'NY'},
  '120': {id: '120', city: 'Albany', state: 'NY'},
  '121': {id: '121', city: 'Albany', state: 'NY'},
  '122': {id: '122', city: 'Albany', state: 'NY'},
  '123': {id: '123', city: 'Schenectady', state: 'NY'},
  '124': {id: '124', city: 'Kingston', state: 'NY'},
  '125': {id: '125', city: 'Poughkeepsie', state: 'NY'},
  '126': {id: '126', city: 'Poughkeepsie', state: 'NY'},
  '127': {id: '127', city: 'Monticello', state: 'NY'},
  '128': {id: '128', city: 'Glens Falls', state: 'NY'},
  '129': {id: '129', city: 'Plattsburgh', state: 'NY'},
  '130': {id: '130', city: 'Syracuse', state: 'NY'},
  '131': {id: '131', city: 'Syracuse', state: 'NY'},
  '132': {id: '132', city: 'Syracuse', state: 'NY'},
  '133': {id: '133', city: 'Utica', state: 'NY'},
  '134': {id: '134', city: 'Utica', state: 'NY'},
  '135': {id: '135', city: 'Utica', state: 'NY'},
  '136': {id: '136', city: 'Watertown', state: 'NY'},
  '137': {id: '137', city: 'Binghamton', state: 'NY'},
  '138': {id: '138', city: 'Binghamton', state: 'NY'},
  '139': {id: '139', city: 'Binghamton', state: 'NY'},
  '140': {id: '140', city: 'Buffalo', state: 'NY'},
  '141': {id: '141', city: 'Buffalo', state: 'NY'},
  '142': {id: '142', city: 'Buffalo', state: 'NY'},
  '143': {id: '143', city: 'Niagara Falls', state: 'NY'},
  '144': {id: '144', city: 'Rochester', state: 'NY'},
  '145': {id: '145', city: 'Rochester', state: 'NY'},
  '146': {id: '146', city: 'Rochester', state: 'NY'},
  '147': {id: '147', city: 'Jamestown', state: 'NY'},
  '148': {id: '148', city: 'Elmira', state: 'NY'},
  '149': {id: '149', city: 'Elmira', state: 'NY'},
  '150': {id: '150', city: 'Pittsburgh', state: 'PA'},
  '151': {id: '151', city: 'Pittsburgh', state: 'PA'},
  '152': {id: '152', city: 'Pittsburgh', state: 'PA'},
  '153': {id: '153', city: 'Washington', state: 'PA'},
  '154': {id: '154', city: 'Uniontown', state: 'PA'},
  '155': {id: '155', city: 'Somerset', state: 'PA'},
  '156': {id: '156', city: 'Greensburg', state: 'PA'},
  '157': {id: '157', city: 'Indiana', state: 'PA'},
  '158': {id: '158', city: 'DuBois', state: 'PA'},
  '159': {id: '159', city: 'Johnstown', state: 'PA'},
  '160': {id: '160', city: 'Butler', state: 'PA'},
  '161': {id: '161', city: 'New Castle', state: 'PA'},
  '162': {id: '162', city: 'Kittanning', state: 'PA'},
  '163': {id: '163', city: 'Oil City', state: 'PA'},
  '164': {id: '164', city: 'Erie', state: 'PA'},
  '165': {id: '165', city: 'Erie', state: 'PA'},
  '166': {id: '166', city: 'Altoona', state: 'PA'},
  '167': {id: '167', city: 'Bradford', state: 'PA'},
  '168': {id: '168', city: 'State College', state: 'PA'},
  '169': {id: '169', city: 'Wellsboro', state: 'PA'},
  '170': {id: '170', city: 'Harrisburg', state: 'PA'},
  '171': {id: '171', city: 'Harrisburg', state: 'PA'},
  '172': {id: '172', city: 'Chambersburg', state: 'PA'},
  '173': {id: '173', city: 'York', state: 'PA'},
  '174': {id: '174', city: 'York', state: 'PA'},
  '175': {id: '175', city: 'Lancaster', state: 'PA'},
  '176': {id: '176', city: 'Lancaster', state: 'PA'},
  '177': {id: '177', city: 'Williamsport', state: 'PA'},
  '178': {id: '178', city: 'Sunbury', state: 'PA'},
  '179': {id: '179', city: 'Pottsville', state: 'PA'},
  '180': {id: '180', city: 'Lehigh Valley', state: 'PA'},
  '181': {id: '181', city: 'Allentown', state: 'PA'},
  '182': {id: '182', city: 'Hazleton', state: 'PA'},
  '183': {id: '183', city: 'East Stroudsburg', state: 'PA'},
  '184': {id: '184', city: 'Scranton', state: 'PA'},
  '185': {id: '185', city: 'Scranton', state: 'PA'},
  '186': {id: '186', city: 'Wilkes-Barre', state: 'PA'},
  '187': {id: '187', city: 'Wilkes-Barre', state: 'PA'},
  '188': {id: '188', city: 'Montrose', state: 'PA'},
  '189': {id: '189', city: 'Doylestown', state: 'PA'},
  '190': {id: '190', city: 'Philadelphia', state: 'PA'},
  '191': {id: '191', city: 'Philadelphia', state: 'PA'},
  '192': {id: '192', city: 'Philadelphia', state: 'PA'},
  '193': {id: '193', city: 'Paoli', state: 'PA'},
  '194': {id: '194', city: 'Norristown', state: 'PA'},
  '195': {id: '195', city: 'Reading', state: 'PA'},
  '196': {id: '196', city: 'Reading', state: 'PA'},
  '197': {id: '197', city: 'Wilmington', state: 'DE'},
  '198': {id: '198', city: 'Wilmington', state: 'DE'},
  '199': {id: '199', city: 'Dover', state: 'DE'},
  '200': {id: '200', city: 'Washington', state: 'DC'},
  '201': {id: '201', city: 'Dulles', state: 'VA'},
  '202': {id: '202', city: 'Washington', state: 'DC'},
  '203': {id: '203', city: 'Washington', state: 'DC'},
  '204': {id: '204', city: 'Washington', state: 'DC'},
  '205': {id: '205', city: 'Washington', state: 'DC'},
  '206': {id: '206', city: 'Waldorf', state: 'MD'},
  '207': {id: '207', city: 'Annapolis Junction', state: 'MD'},
  '208': {id: '208', city: 'Bethesda', state: 'MD'},
  '209': {id: '209', city: 'Silver Spring', state: 'MD'},
  '210': {id: '210', city: 'Baltimore', state: 'MD'},
  '211': {id: '211', city: 'Baltimore', state: 'MD'},
  '212': {id: '212', city: 'Baltimore', state: 'MD'},
  '213': {id: '213', city: 'Not in use'},
  '214': {id: '214', city: 'Annapolis', state: 'MD'},
  '215': {id: '215', city: 'Cumberland', state: 'MD'},
  '216': {id: '216', city: 'Easton', state: 'MD'},
  '217': {id: '217', city: 'Frederick', state: 'MD'},
  '218': {id: '218', city: 'Salisbury', state: 'MD'},
  '219': {id: '219', city: 'Cecil County', state: 'MD'},
  '220': {id: '220', city: 'Washington', state: 'DC'},
  '221': {id: '221', city: 'Washington', state: 'DC'},
  '222': {id: '222', city: 'Arlington', state: 'VA'},
  '223': {id: '223', city: 'Alexandria', state: 'VA'},
  '224': {id: '224', city: 'Fredericksburg', state: 'VA'},
  '225': {id: '225', city: 'Fredericksburg', state: 'VA'},
  '226': {id: '226', city: 'Winchester', state: 'VA'},
  '227': {id: '227', city: 'Culpeper', state: 'VA'},
  '228': {id: '228', city: 'Harrisonburg', state: 'VA'},
  '229': {id: '229', city: 'Charlottesville', state: 'VA'},
  '230': {id: '230', city: 'Richmond', state: 'VA'},
  '231': {id: '231', city: 'Richmond', state: 'VA'},
  '232': {id: '232', city: 'Richmond', state: 'VA'},
  '233': {id: '233', city: 'Norfolk', state: 'VA'},
  '234': {id: '234', city: 'Norfolk', state: 'VA'},
  '235': {id: '235', city: 'Norfolk', state: 'VA'},
  '236': {id: '236', city: 'Newport News', state: 'VA'},
  '237': {id: '237', city: 'Portsmouth', state: 'VA'},
  '238': {id: '238', city: 'Petersburg', state: 'VA'},
  '239': {id: '239', city: 'Farmville', state: 'VA'},
  '240': {id: '240', city: 'Roanoke', state: 'VA'},
  '241': {id: '241', city: 'Roanoke', state: 'VA'},
  '242': {id: '242', city: 'Bristol', state: 'VA'},
  '243': {id: '243', city: 'Pulaski', state: 'VA'},
  '244': {id: '244', city: 'Staunton', state: 'VA'},
  '245': {id: '245', city: 'Lynchburg', state: 'VA'},
  '246': {id: '246', city: 'Bluefield', state: 'WV'},
  '247': {id: '247', city: 'Bluefield', state: 'WV'},
  '248': {id: '248', city: 'Welch', state: 'WV'},
  '249': {id: '249', city: 'Lewisburg', state: 'WV'},
  '250': {id: '250', city: 'Charleston', state: 'WV'},
  '251': {id: '251', city: 'Charleston', state: 'WV'},
  '252': {id: '252', city: 'Charleston', state: 'WV'},
  '253': {id: '253', city: 'Charleston', state: 'WV'},
  '254': {id: '254', city: 'Martinsburg', state: 'WV'},
  '255': {id: '255', city: 'Huntington', state: 'WV'},
  '256': {id: '256', city: 'Logan', state: 'WV'},
  '257': {id: '257', city: 'Huntington', state: 'WV'},
  '258': {id: '258', city: 'Beckley', state: 'WV'},
  '259': {id: '259', city: 'Beckley', state: 'WV'},
  '260': {id: '260', city: 'Wheeling', state: 'WV'},
  '261': {id: '261', city: 'Parkersburg', state: 'WV'},
  '262': {id: '262', city: 'Clarksburg', state: 'WV'},
  '263': {id: '263', city: 'Clarksburg', state: 'WV'},
  '264': {id: '264', city: 'Clarksburg', state: 'WV'},
  '265': {id: '265', city: 'Morgantown', state: 'WV'},
  '266': {id: '266', city: 'Sutton', state: 'WV'},
  '267': {id: '267', city: 'Cumberland', state: 'MD'},
  '268': {id: '268', city: 'Petersburg', state: 'WV'},
  '269': {id: '269', city: 'Not in use'},
  '270': {id: '270', city: 'Winston-Salem', state: 'NC'},
  '271': {id: '271', city: 'Winston-Salem', state: 'NC'},
  '272': {id: '272', city: 'Greensboro', state: 'NC'},
  '273': {id: '273', city: 'Greensboro', state: 'NC'},
  '274': {id: '274', city: 'Greensboro', state: 'NC'},
  '275': {id: '275', city: 'Raleigh', state: 'NC'},
  '276': {id: '276', city: 'Raleigh', state: 'NC'},
  '277': {id: '277', city: 'Durham', state: 'NC'},
  '278': {id: '278', city: 'Rocky Mount', state: 'NC'},
  '279': {id: '279', city: 'Elizabeth City', state: 'NC'},
  '280': {id: '280', city: 'Charlotte', state: 'NC'},
  '281': {id: '281', city: 'Charlotte', state: 'NC'},
  '282': {id: '282', city: 'Charlotte', state: 'NC'},
  '283': {id: '283', city: 'Fayetteville', state: 'NC'},
  '284': {id: '284', city: 'Wilmington', state: 'NC'},
  '285': {id: '285', city: 'Kinston', state: 'NC'},
  '286': {id: '286', city: 'Hickory', state: 'NC'},
  '287': {id: '287', city: 'Asheville', state: 'NC'},
  '288': {id: '288', city: 'Asheville', state: 'NC'},
  '289': {id: '289', city: 'Andrews', state: 'NC'},
  '290': {id: '290', city: 'Columbia', state: 'SC'},
  '291': {id: '291', city: 'Columbia', state: 'SC'},
  '292': {id: '292', city: 'Columbia', state: 'SC'},
  '293': {id: '293', city: 'Spartanburg', state: 'SC'},
  '294': {id: '294', city: 'Charleston', state: 'SC'},
  '295': {id: '295', city: 'Florence', state: 'SC'},
  '296': {id: '296', city: 'Greenville', state: 'SC'},
  '297': {id: '297', city: 'Charlotte', state: 'NC'},
  '298': {id: '298', city: 'Augusta', state: 'GA'},
  '299': {id: '299', city: 'Beaufort', state: 'SC'},
  '300': {id: '300', city: 'Atlanta', state: 'GA'},
  '301': {id: '301', city: 'Atlanta', state: 'GA'},
  '302': {id: '302', city: 'Atlanta', state: 'GA'},
  '303': {id: '303', city: 'Atlanta', state: 'GA'},
  '304': {id: '304', city: 'Swainsboro', state: 'GA'},
  '305': {id: '305', city: 'Gainesville', state: 'GA'},
  '306': {id: '306', city: 'Athens', state: 'GA'},
  '307': {id: '307', city: 'Chattanooga', state: 'TN'},
  '308': {id: '308', city: 'Augusta', state: 'GA'},
  '309': {id: '309', city: 'Augusta', state: 'GA'},
  '310': {id: '310', city: 'Macon', state: 'GA'},
  '311': {id: '311', city: 'Atlanta', state: 'GA'},
  '312': {id: '312', city: 'Macon', state: 'GA'},
  '313': {id: '313', city: 'Savannah', state: 'GA'},
  '314': {id: '314', city: 'Savannah', state: 'GA'},
  '315': {id: '315', city: 'Waycross', state: 'GA'},
  '316': {id: '316', city: 'Valdosta', state: 'GA'},
  '317': {id: '317', city: 'Albany', state: 'GA'},
  '318': {id: '318', city: 'Columbus', state: 'GA'},
  '319': {id: '319', city: 'Columbus', state: 'GA'},
  '320': {id: '320', city: 'Jacksonville', state: 'FL'},
  '321': {id: '321', city: 'Daytona Beach', state: 'FL'},
  '322': {id: '322', city: 'Jacksonville', state: 'FL'},
  '323': {id: '323', city: 'Tallahassee', state: 'FL'},
  '324': {id: '324', city: 'Panama City', state: 'FL'},
  '325': {id: '325', city: 'Pensacola', state: 'FL'},
  '326': {id: '326', city: 'Gainesville', state: 'FL'},
  '327': {id: '327', city: 'Orlando', state: 'FL'},
  '328': {id: '328', city: 'Orlando', state: 'FL'},
  '329': {id: '329', city: 'Melbourne', state: 'FL'},
  '330': {id: '330', city: 'Miami', state: 'FL'},
  '331': {id: '331', city: 'Miami', state: 'FL'},
  '332': {id: '332', city: 'Miami', state: 'FL'},
  '333': {id: '333', city: 'Fort Lauderdale', state: 'FL'},
  '334': {id: '334', city: 'West Palm Beach', state: 'FL'},
  '335': {id: '335', city: 'Tampa', state: 'FL'},
  '336': {id: '336', city: 'Tampa', state: 'FL'},
  '337': {id: '337', city: 'St. Petersburg', state: 'FL'},
  '338': {id: '338', city: 'Lakeland', state: 'FL'},
  '339': {id: '339', city: 'Fort Myers', state: 'FL'},
  '340': {id: '340', city: 'Military', state: 'AA'},
  '341': {id: '341', city: 'Naples', state: 'FL'},
  '342': {id: '342', city: 'Bradenton', state: 'FL'},
  '343': {id: '343', city: 'Not in use'},
  '344': {id: '344', city: 'Ocala', state: 'FL'},
  '345': {id: '345', city: 'Not in use'},
  '346': {id: '346', city: 'Brooksville', state: 'FL'},
  '347': {id: '347', city: 'Orlando', state: 'FL'},
  '348': {id: '348', city: 'Not in use'},
  '349': {id: '349', city: 'Fort Pierce', state: 'FL'},
  '350': {id: '350', city: 'Birmingham', state: 'AL'},
  '351': {id: '351', city: 'Birmingham', state: 'AL'},
  '352': {id: '352', city: 'Birmingham', state: 'AL'},
  '353': {id: '353', city: 'Not in use'},
  '354': {id: '354', city: 'Tuscaloosa', state: 'AL'},
  '355': {id: '355', city: 'Jasper', state: 'AL'},
  '356': {id: '356', city: 'Decatur', state: 'AL'},
  '357': {id: '357', city: 'Huntsville', state: 'AL'},
  '358': {id: '358', city: 'Huntsville', state: 'AL'},
  '359': {id: '359', city: 'Gadsden', state: 'AL'},
  '360': {id: '360', city: 'Montgomery', state: 'AL'},
  '361': {id: '361', city: 'Montgomery', state: 'AL'},
  '362': {id: '362', city: 'Anniston', state: 'AL'},
  '363': {id: '363', city: 'Dothan', state: 'AL'},
  '364': {id: '364', city: 'Evergreen', state: 'AL'},
  '365': {id: '365', city: 'Mobile', state: 'AL'},
  '366': {id: '366', city: 'Mobile', state: 'AL'},
  '367': {id: '367', city: 'Selma', state: 'AL'},
  '368': {id: '368', city: 'Opelika', state: 'AL'},
  '369': {id: '369', city: 'Meridian', state: 'MS'},
  '370': {id: '370', city: 'Nashville', state: 'TN'},
  '371': {id: '371', city: 'Nashville', state: 'TN'},
  '372': {id: '372', city: 'Nashville', state: 'TN'},
  '373': {id: '373', city: 'Chattanooga', state: 'TN'},
  '374': {id: '374', city: 'Chattanooga', state: 'TN'},
  '375': {id: '375', city: 'Memphis', state: 'TN'},
  '376': {id: '376', city: 'Johnson City', state: 'TN'},
  '377': {id: '377', city: 'Knoxville', state: 'TN'},
  '378': {id: '378', city: 'Knoxville', state: 'TN'},
  '379': {id: '379', city: 'Knoxville', state: 'TN'},
  '380': {id: '380', city: 'Memphis', state: 'TN'},
  '381': {id: '381', city: 'Memphis', state: 'TN'},
  '382': {id: '382', city: 'McKenzie', state: 'TN'},
  '383': {id: '383', city: 'Jackson', state: 'TN'},
  '384': {id: '384', city: 'Columbia', state: 'TN'},
  '385': {id: '385', city: 'Cookeville', state: 'TN'},
  '386': {id: '386', city: 'Memphis', state: 'TN'},
  '387': {id: '387', city: 'Greenville', state: 'MS'},
  '388': {id: '388', city: 'Tupelo', state: 'MS'},
  '389': {id: '389', city: 'Grenada', state: 'MS'},
  '390': {id: '390', city: 'Jackson', state: 'MS'},
  '391': {id: '391', city: 'Jackson', state: 'MS'},
  '392': {id: '392', city: 'Jackson', state: 'MS'},
  '393': {id: '393', city: 'Meridian', state: 'MS'},
  '394': {id: '394', city: 'Hattiesburg', state: 'MS'},
  '395': {id: '395', city: 'Gulfport', state: 'MS'},
  '396': {id: '396', city: 'Brookhaven', state: 'MS'},
  '397': {id: '397', city: 'Columbus', state: 'MS'},
  '398': {id: '398', city: 'Albany', state: 'GA'},
  '399': {id: '399', city: 'Atlanta', state: 'GA'},
  '400': {id: '400', city: 'Louisville', state: 'KY'},
  '401': {id: '401', city: 'Louisville', state: 'KY'},
  '402': {id: '402', city: 'Louisville', state: 'KY'},
  '403': {id: '403', city: 'Lexington', state: 'KY'},
  '404': {id: '404', city: 'Lexington', state: 'KY'},
  '405': {id: '405', city: 'Lexington', state: 'KY'},
  '406': {id: '406', city: 'Frankfort', state: 'KY'},
  '407': {id: '407', city: 'Corbin', state: 'KY'},
  '408': {id: '408', city: 'Corbin', state: 'KY'},
  '409': {id: '409', city: 'Corbin', state: 'KY'},
  '410': {id: '410', city: 'Cincinnati', state: 'OH'},
  '411': {id: '411', city: 'Ashland', state: 'KY'},
  '412': {id: '412', city: 'Ashland', state: 'KY'},
  '413': {id: '413', city: 'Campton', state: 'KY'},
  '414': {id: '414', city: 'Campton', state: 'KY'},
  '415': {id: '415', city: 'Pikeville', state: 'KY'},
  '416': {id: '416', city: 'Pikeville', state: 'KY'},
  '417': {id: '417', city: 'Hazard', state: 'KY'},
  '418': {id: '418', city: 'Hazard', state: 'KY'},
  '419': {id: '419', city: 'Not in use'},
  '420': {id: '420', city: 'Paducah', state: 'KY'},
  '421': {id: '421', city: 'Bowling Green', state: 'KY'},
  '422': {id: '422', city: 'Bowling Green', state: 'KY'},
  '423': {id: '423', city: 'Owensboro', state: 'KY'},
  '424': {id: '424', city: 'Evansville', state: 'IN'},
  '425': {id: '425', city: 'Somerset', state: 'KY'},
  '426': {id: '426', city: 'Albany', state: 'KY'},
  '427': {id: '427', city: 'Elizabethtown', state: 'KY'},
  '428': {id: '428', city: 'Not in use'},
  '429': {id: '429', city: 'Not in use'},
  '430': {id: '430', city: 'Columbus', state: 'OH'},
  '431': {id: '431', city: 'Columbus', state: 'OH'},
  '432': {id: '432', city: 'Columbus', state: 'OH'},
  '433': {id: '433', city: 'Marion', state: 'OH'},
  '434': {id: '434', city: 'Toledo', state: 'OH'},
  '435': {id: '435', city: 'Toledo', state: 'OH'},
  '436': {id: '436', city: 'Toledo', state: 'OH'},
  '437': {id: '437', city: 'Zanesville', state: 'OH'},
  '438': {id: '438', city: 'Zanesville', state: 'OH'},
  '439': {id: '439', city: 'Steubenville', state: 'OH'},
  '440': {id: '440', city: 'Cleveland', state: 'OH'},
  '441': {id: '441', city: 'Cleveland', state: 'OH'},
  '442': {id: '442', city: 'Akron', state: 'OH'},
  '443': {id: '443', city: 'Akron', state: 'OH'},
  '444': {id: '444', city: 'Youngstown', state: 'OH'},
  '445': {id: '445', city: 'Youngstown', state: 'OH'},
  '446': {id: '446', city: 'Canton', state: 'OH'},
  '447': {id: '447', city: 'Canton', state: 'OH'},
  '448': {id: '448', city: 'Mansfield', state: 'OH'},
  '449': {id: '449', city: 'Mansfield', state: 'OH'},
  '450': {id: '450', city: 'Cincinnati', state: 'OH'},
  '451': {id: '451', city: 'Cincinnati', state: 'OH'},
  '452': {id: '452', city: 'Cincinnati', state: 'OH'},
  '453': {id: '453', city: 'Dayton', state: 'OH'},
  '454': {id: '454', city: 'Dayton', state: 'OH'},
  '455': {id: '455', city: 'Springfield', state: 'OH'},
  '456': {id: '456', city: 'Chillicothe', state: 'OH'},
  '457': {id: '457', city: 'Athens', state: 'OH'},
  '458': {id: '458', city: 'Lima', state: 'OH'},
  '459': {id: '459', city: 'Cincinnati', state: 'OH'},
  '460': {id: '460', city: 'Indianapolis', state: 'IN'},
  '461': {id: '461', city: 'Indianapolis', state: 'IN'},
  '462': {id: '462', city: 'Indianapolis', state: 'IN'},
  '463': {id: '463', city: 'Gary', state: 'IN'},
  '464': {id: '464', city: 'Gary', state: 'IN'},
  '465': {id: '465', city: 'South Bend', state: 'IN'},
  '466': {id: '466', city: 'South Bend', state: 'IN'},
  '467': {id: '467', city: 'Fort Wayne', state: 'IN'},
  '468': {id: '468', city: 'Fort Wayne', state: 'IN'},
  '469': {id: '469', city: 'Kokomo', state: 'IN'},
  '470': {id: '470', city: 'Cincinnati', state: 'OH'},
  '471': {id: '471', city: 'Louisville', state: 'KY'},
  '472': {id: '472', city: 'Columbus', state: 'IN'},
  '473': {id: '473', city: 'Muncie', state: 'IN'},
  '474': {id: '474', city: 'Bloomington', state: 'IN'},
  '475': {id: '475', city: 'Washington', state: 'IN'},
  '476': {id: '476', city: 'Evansville', state: 'IN'},
  '477': {id: '477', city: 'Evansville', state: 'IN'},
  '478': {id: '478', city: 'Terre Haute', state: 'IN'},
  '479': {id: '479', city: 'Lafayette', state: 'IN'},
  '480': {id: '480', city: 'Detroit', state: 'MI'},
  '481': {id: '481', city: 'Detroit', state: 'MI'},
  '482': {id: '482', city: 'Detroit', state: 'MI'},
  '483': {id: '483', city: 'Detroit', state: 'MI'},
  '484': {id: '484', city: 'Flint', state: 'MI'},
  '485': {id: '485', city: 'Flint', state: 'MI'},
  '486': {id: '486', city: 'Saginaw', state: 'MI'},
  '487': {id: '487', city: 'Saginaw', state: 'MI'},
  '488': {id: '488', city: 'Lansing', state: 'MI'},
  '489': {id: '489', city: 'Lansing', state: 'MI'},
  '490': {id: '490', city: 'Kalamazoo', state: 'MI'},
  '491': {id: '491', city: 'South Bend', state: 'IN'},
  '492': {id: '492', city: 'Jackson', state: 'MI'},
  '493': {id: '493', city: 'Grand Rapids', state: 'MI'},
  '494': {id: '494', city: 'Grand Rapids', state: 'MI'},
  '495': {id: '495', city: 'Grand Rapids', state: 'MI'},
  '496': {id: '496', city: 'Cadillac', state: 'MI'},
  '497': {id: '497', city: 'Mackinaw City', state: 'MI'},
  '498': {id: '498', city: 'Iron Mountain', state: 'MI'},
  '499': {id: '499', city: 'Iron Mountain', state: 'MI'},
  '500': {id: '500', city: 'Des Moines', state: 'IA'},
  '501': {id: '501', city: 'Des Moines', state: 'IA'},
  '502': {id: '502', city: 'Des Moines', state: 'IA'},
  '503': {id: '503', city: 'Des Moines', state: 'IA'},
  '504': {id: '504', city: 'Mason City', state: 'IA'},
  '505': {id: '505', city: 'Fort Dodge', state: 'IA'},
  '506': {id: '506', city: 'Waterloo', state: 'IA'},
  '507': {id: '507', city: 'Waterloo', state: 'IA'},
  '508': {id: '508', city: 'Creston', state: 'IA'},
  '509': {id: '509', city: 'Des Moines', state: 'IA'},
  '510': {id: '510', city: 'Sioux City', state: 'IA'},
  '511': {id: '511', city: 'Sioux City', state: 'IA'},
  '512': {id: '512', city: 'Sheldon', state: 'IA'},
  '513': {id: '513', city: 'Spencer', state: 'IA'},
  '514': {id: '514', city: 'Carroll', state: 'IA'},
  '515': {id: '515', city: 'Council Bluffs', state: 'IA'},
  '516': {id: '516', city: 'Shenandoah', state: 'IA'},
  '517': {id: '517', city: 'Not in use'},
  '518': {id: '518', city: 'Not in use'},
  '519': {id: '519', city: 'Not in use'},
  '520': {id: '520', city: 'Dubuque', state: 'IA'},
  '521': {id: '521', city: 'Decorah', state: 'IA'},
  '522': {id: '522', city: 'Cedar Rapids', state: 'IA'},
  '523': {id: '523', city: 'Cedar Rapids', state: 'IA'},
  '524': {id: '524', city: 'Cedar Rapids', state: 'IA'},
  '525': {id: '525', city: 'Ottumwa', state: 'IA'},
  '526': {id: '526', city: 'Burlington', state: 'IA'},
  '527': {id: '527', city: 'Davenport', state: 'IA'},
  '528': {id: '528', city: 'Davenport', state: 'IA'},
  '529': {id: '529', city: 'Not in use'},
  '530': {id: '530', city: 'Milwaukee', state: 'WI'},
  '531': {id: '531', city: 'Milwaukee', state: 'WI'},
  '532': {id: '532', city: 'Milwaukee', state: 'WI'},
  '533': {id: '533', city: 'Not in use'},
  '534': {id: '534', city: 'Racine', state: 'WI'},
  '535': {id: '535', city: 'Madison', state: 'WI'},
  '536': {id: '536', city: 'Not in use'},
  '537': {id: '537', city: 'Madison', state: 'WI'},
  '538': {id: '538', city: 'Dubuque', state: 'IA'},
  '539': {id: '539', city: 'Portage', state: 'WI'},
  '540': {id: '540', city: 'St. Paul', state: 'MN'},
  '541': {id: '541', city: 'Green Bay', state: 'WI'},
  '542': {id: '542', city: 'Green Bay', state: 'WI'},
  '543': {id: '543', city: 'Green Bay', state: 'WI'},
  '544': {id: '544', city: 'Wausau', state: 'WI'},
  '545': {id: '545', city: 'Rhinelander', state: 'WI'},
  '546': {id: '546', city: 'La Crosse', state: 'WI'},
  '547': {id: '547', city: 'Eau Claire', state: 'WI'},
  '548': {id: '548', city: 'Spooner', state: 'WI'},
  '549': {id: '549', city: 'Oshkosh', state: 'WI'},
  '550': {id: '550', city: 'St. Paul', state: 'MN'},
  '551': {id: '551', city: 'St. Paul', state: 'MN'},
  '552': {id: '552', city: 'Not in use'},
  '553': {id: '553', city: 'Minneapolis', state: 'MN'},
  '554': {id: '554', city: 'Minneapolis', state: 'MN'},
  '555': {id: '555', city: 'Minneapolis', state: 'MN'},
  '556': {id: '556', city: 'Duluth', state: 'MN'},
  '557': {id: '557', city: 'Duluth', state: 'MN'},
  '558': {id: '558', city: 'Duluth', state: 'MN'},
  '559': {id: '559', city: 'Rochester', state: 'MN'},
  '560': {id: '560', city: 'Mankato', state: 'MN'},
  '561': {id: '561', city: 'Windom', state: 'MN'},
  '562': {id: '562', city: 'Willmar', state: 'MN'},
  '563': {id: '563', city: 'St. Cloud', state: 'MN'},
  '564': {id: '564', city: 'Brainerd', state: 'MN'},
  '565': {id: '565', city: 'Detroit Lakes', state: 'MN'},
  '566': {id: '566', city: 'Bemidji', state: 'MN'},
  '567': {id: '567', city: 'Thief River Falls', state: 'MN'},
  '568': {id: '568', city: 'Not in use'},
  '569': {id: '569', city: 'Washington', state: 'DC'},
  '570': {id: '570', city: 'Sioux Falls', state: 'SD'},
  '571': {id: '571', city: 'Sioux Falls', state: 'SD'},
  '572': {id: '572', city: 'Watertown', state: 'SD'},
  '573': {id: '573', city: 'Mitchell', state: 'SD'},
  '574': {id: '574', city: 'Aberdeen', state: 'SD'},
  '575': {id: '575', city: 'Pierre', state: 'SD'},
  '576': {id: '576', city: 'Mobridge', state: 'SD'},
  '577': {id: '577', city: 'Rapid City', state: 'SD'},
  '578': {id: '578', city: 'Not in use'},
  '579': {id: '579', city: 'Not in use'},
  '580': {id: '580', city: 'Fargo', state: 'ND'},
  '581': {id: '581', city: 'Fargo', state: 'ND'},
  '582': {id: '582', city: 'Grand Forks', state: 'ND'},
  '583': {id: '583', city: 'Devils Lake', state: 'ND'},
  '584': {id: '584', city: 'Jamestown', state: 'ND'},
  '585': {id: '585', city: 'Bismarck', state: 'ND'},
  '586': {id: '586', city: 'Dickinson', state: 'ND'},
  '587': {id: '587', city: 'Minot', state: 'ND'},
  '588': {id: '588', city: 'Williston', state: 'ND'},
  '589': {id: '589', city: 'Not in use'},
  '590': {id: '590', city: 'Billings', state: 'MT'},
  '591': {id: '591', city: 'Billings', state: 'MT'},
  '592': {id: '592', city: 'Wolf Point', state: 'MT'},
  '593': {id: '593', city: 'Miles City', state: 'MT'},
  '594': {id: '594', city: 'Great Falls', state: 'MT'},
  '595': {id: '595', city: 'Havre', state: 'MT'},
  '596': {id: '596', city: 'Helena', state: 'MT'},
  '597': {id: '597', city: 'Butte', state: 'MT'},
  '598': {id: '598', city: 'Missoula', state: 'MT'},
  '599': {id: '599', city: 'Kalispell', state: 'MT'},
  '600': {id: '600', city: 'Chicago', state: 'IL'},
  '601': {id: '601', city: 'Chicago', state: 'IL'},
  '602': {id: '602', city: 'Evanston', state: 'IL'},
  '603': {id: '603', city: 'Oak Park', state: 'IL'},
  '604': {id: '604', city: 'Chicago', state: 'IL'},
  '605': {id: '605', city: 'Chicago', state: 'IL'},
  '606': {id: '606', city: 'Chicago', state: 'IL'},
  '607': {id: '607', city: 'Chicago', state: 'IL'},
  '608': {id: '608', city: 'Chicago', state: 'IL'},
  '609': {id: '609', city: 'Kankakee', state: 'IL'},
  '610': {id: '610', city: 'Rockford', state: 'IL'},
  '611': {id: '611', city: 'Rockford', state: 'IL'},
  '612': {id: '612', city: 'Rock Island', state: 'IL'},
  '613': {id: '613', city: 'LaSalle', state: 'IL'},
  '614': {id: '614', city: 'Galesburg', state: 'IL'},
  '615': {id: '615', city: 'Peoria', state: 'IL'},
  '616': {id: '616', city: 'Peoria', state: 'IL'},
  '617': {id: '617', city: 'Bloomington', state: 'IL'},
  '618': {id: '618', city: 'Urbana', state: 'IL'},
  '619': {id: '619', city: 'Urbana', state: 'IL'},
  '620': {id: '620', city: 'St. Louis', state: 'MO'},
  '621': {id: '621', city: 'Not in use'},
  '622': {id: '622', city: 'East St. Louis', state: 'IL'},
  '623': {id: '623', city: 'Quincy', state: 'IL'},
  '624': {id: '624', city: 'Effingham', state: 'IL'},
  '625': {id: '625', city: 'Springfield', state: 'IL'},
  '626': {id: '626', city: 'Springfield', state: 'IL'},
  '627': {id: '627', city: 'Springfield', state: 'IL'},
  '628': {id: '628', city: 'Centralia', state: 'IL'},
  '629': {id: '629', city: 'Carbondale', state: 'IL'},
  '630': {id: '630', city: 'St. Louis', state: 'MO'},
  '631': {id: '631', city: 'St. Louis', state: 'MO'},
  '632': {id: '632', city: 'Not in use'},
  '633': {id: '633', city: 'St. Charles', state: 'MO'},
  '634': {id: '634', city: 'Hannibal', state: 'MO'},
  '635': {id: '635', city: 'Kirksville', state: 'MO'},
  '636': {id: '636', city: 'Park Hills', state: 'MO'},
  '637': {id: '637', city: 'Cape Girardeau', state: 'MO'},
  '638': {id: '638', city: 'Sikeston', state: 'MO'},
  '639': {id: '639', city: 'Poplar Bluff', state: 'MO'},
  '640': {id: '640', city: 'Kansas City', state: 'MO'},
  '641': {id: '641', city: 'Kansas City', state: 'MO'},
  '642': {id: '642', city: 'Not in use'},
  '643': {id: '643', city: 'Not in use'},
  '644': {id: '644', city: 'St. Joseph', state: 'MO'},
  '645': {id: '645', city: 'St. Joseph', state: 'MO'},
  '646': {id: '646', city: 'Chillicothe', state: 'MO'},
  '647': {id: '647', city: 'Harrisonville', state: 'MO'},
  '648': {id: '648', city: 'Joplin', state: 'MO'},
  '649': {id: '649', city: 'Kansas City', state: 'MO'},
  '650': {id: '650', city: 'Jefferson City', state: 'MO'},
  '651': {id: '651', city: 'Jefferson City', state: 'MO'},
  '652': {id: '652', city: 'Columbia', state: 'MO'},
  '653': {id: '653', city: 'Sedalia', state: 'MO'},
  '654': {id: '654', city: 'Rolla', state: 'MO'},
  '655': {id: '655', city: 'Rolla', state: 'MO'},
  '656': {id: '656', city: 'Springfield', state: 'MO'},
  '657': {id: '657', city: 'Springfield', state: 'MO'},
  '658': {id: '658', city: 'Springfield', state: 'MO'},
  '659': {id: '659', city: 'Not in use'},
  '660': {id: '660', city: 'Kansas City', state: 'KS'},
  '661': {id: '661', city: 'Kansas City', state: 'KS'},
  '662': {id: '662', city: 'Overland Park', state: 'KS'},
  '663': {id: '663', city: 'Not in use'},
  '664': {id: '664', city: 'Topeka', state: 'KS'},
  '665': {id: '665', city: 'Topeka', state: 'KS'},
  '666': {id: '666', city: 'Topeka', state: 'KS'},
  '667': {id: '667', city: 'Fort Scott', state: 'KS'},
  '668': {id: '668', city: 'Emporia', state: 'KS'},
  '669': {id: '669', city: 'Concordia', state: 'KS'},
  '670': {id: '670', city: 'Wichita', state: 'KS'},
  '671': {id: '671', city: 'Wichita', state: 'KS'},
  '672': {id: '672', city: 'Wichita', state: 'KS'},
  '673': {id: '673', city: 'Independence', state: 'KS'},
  '674': {id: '674', city: 'Salina', state: 'KS'},
  '675': {id: '675', city: 'Hutchinson', state: 'KS'},
  '676': {id: '676', city: 'Hays', state: 'KS'},
  '677': {id: '677', city: 'Colby', state: 'KS'},
  '678': {id: '678', city: 'Dodge City', state: 'KS'},
  '679': {id: '679', city: 'Liberal', state: 'KS'},
  '680': {id: '680', city: 'Omaha', state: 'NE'},
  '681': {id: '681', city: 'Omaha', state: 'NE'},
  '682': {id: '682', city: 'Not in use'},
  '683': {id: '683', city: 'Omaha', state: 'NE'},
  '684': {id: '684', city: 'Omaha', state: 'NE'},
  '685': {id: '685', city: 'Lincoln', state: 'NE'},
  '686': {id: '686', city: 'Columbus', state: 'NE'},
  '687': {id: '687', city: 'Norfolk', state: 'NE'},
  '688': {id: '688', city: 'Grand Island', state: 'NE'},
  '689': {id: '689', city: 'Hastings', state: 'NE'},
  '690': {id: '690', city: 'McCook', state: 'NE'},
  '691': {id: '691', city: 'North Platte', state: 'NE'},
  '692': {id: '692', city: 'Valentine', state: 'NE'},
  '693': {id: '693', city: 'Alliance', state: 'NE'},
  '694': {id: '694', city: 'Not in use'},
  '695': {id: '695', city: 'Not in use'},
  '696': {id: '696', city: 'Not in use'},
  '697': {id: '697', city: 'Not in use'},
  '698': {id: '698', city: 'Not in use'},
  '699': {id: '699', city: 'Not in use'},
  '700': {id: '700', city: 'Metairie', state: 'LA'},
  '701': {id: '701', city: 'New Orleans', state: 'LA'},
  '702': {id: '702', city: 'Not in use'},
  '703': {id: '703', city: 'Thibodaux', state: 'LA'},
  '704': {id: '704', city: 'Hammond', state: 'LA'},
  '705': {id: '705', city: 'Lafayette', state: 'LA'},
  '706': {id: '706', city: 'Lake Charles', state: 'LA'},
  '707': {id: '707', city: 'Baton Rouge', state: 'LA'},
  '708': {id: '708', city: 'Baton Rouge', state: 'LA'},
  '709': {id: '709', city: 'Not in use'},
  '710': {id: '710', city: 'Shreveport', state: 'LA'},
  '711': {id: '711', city: 'Shreveport', state: 'LA'},
  '712': {id: '712', city: 'Monroe', state: 'LA'},
  '713': {id: '713', city: 'Alexandria', state: 'LA'},
  '714': {id: '714', city: 'Alexandria', state: 'LA'},
  '715': {id: '715', city: 'Not in use'},
  '716': {id: '716', city: 'Pine Bluff', state: 'AR'},
  '717': {id: '717', city: 'Camden', state: 'AR'},
  '718': {id: '718', city: 'Texarkana', state: 'AR'},
  '719': {id: '719', city: 'Hot Springs', state: 'AR'},
  '720': {id: '720', city: 'Little Rock', state: 'AR'},
  '721': {id: '721', city: 'Little Rock', state: 'AR'},
  '722': {id: '722', city: 'Little Rock', state: 'AR'},
  '723': {id: '723', city: 'West Memphis', state: 'AR'},
  '724': {id: '724', city: 'Jonesboro', state: 'AR'},
  '725': {id: '725', city: 'Batesville', state: 'AR'},
  '726': {id: '726', city: 'Harrison', state: 'AR'},
  '727': {id: '727', city: 'Fayetteville', state: 'AR'},
  '728': {id: '728', city: 'Russellville', state: 'AR'},
  '729': {id: '729', city: 'Fort Smith', state: 'AR'},
  '730': {id: '730', city: 'Oklahoma City', state: 'OK'},
  '731': {id: '731', city: 'Oklahoma City', state: 'OK'},
  '732': {id: '732', city: 'Not in use'},
  '733': {id: '733', city: 'Austin', state: 'TX'},
  '734': {id: '734', city: 'Ardmore', state: 'OK'},
  '735': {id: '735', city: 'Lawton', state: 'OK'},
  '736': {id: '736', city: 'Clinton', state: 'OK'},
  '737': {id: '737', city: 'Enid', state: 'OK'},
  '738': {id: '738', city: 'Woodward', state: 'OK'},
  '739': {id: '739', city: 'Liberal', state: 'KS'},
  '740': {id: '740', city: 'Tulsa', state: 'OK'},
  '741': {id: '741', city: 'Tulsa', state: 'OK'},
  '742': {id: '742', city: 'Not in use'},
  '743': {id: '743', city: 'Vinita', state: 'OK'},
  '744': {id: '744', city: 'Muskogee', state: 'OK'},
  '745': {id: '745', city: 'McAlester', state: 'OK'},
  '746': {id: '746', city: 'Ponca City', state: 'OK'},
  '747': {id: '747', city: 'Durant', state: 'OK'},
  '748': {id: '748', city: 'Shawnee', state: 'OK'},
  '749': {id: '749', city: 'Fort Smith', state: 'AR'},
  '750': {id: '750', city: 'Dallas', state: 'TX'},
  '751': {id: '751', city: 'Dallas', state: 'TX'},
  '752': {id: '752', city: 'Dallas', state: 'TX'},
  '753': {id: '753', city: 'Dallas', state: 'TX'},
  '754': {id: '754', city: 'Greenville', state: 'TX'},
  '755': {id: '755', city: 'Texarkana', state: 'TX'},
  '756': {id: '756', city: 'Longview', state: 'TX'},
  '757': {id: '757', city: 'Tyler', state: 'TX'},
  '758': {id: '758', city: 'Palestine', state: 'TX'},
  '759': {id: '759', city: 'Lufkin', state: 'TX'},
  '760': {id: '760', city: 'Fort Worth', state: 'TX'},
  '761': {id: '761', city: 'Fort Worth', state: 'TX'},
  '762': {id: '762', city: 'Denton', state: 'TX'},
  '763': {id: '763', city: 'Wichita Falls', state: 'TX'},
  '764': {id: '764', city: 'Stephenville', state: 'TX'},
  '765': {id: '765', city: 'Temple', state: 'TX'},
  '766': {id: '766', city: 'Waco', state: 'TX'},
  '767': {id: '767', city: 'Waco', state: 'TX'},
  '768': {id: '768', city: 'Brownwood', state: 'TX'},
  '769': {id: '769', city: 'San Angelo', state: 'TX'},
  '770': {id: '770', city: 'Houston', state: 'TX'},
  '771': {id: '771', city: 'Houston', state: 'TX'},
  '772': {id: '772', city: 'Houston', state: 'TX'},
  '773': {id: '773', city: 'Conroe', state: 'TX'},
  '774': {id: '774', city: 'Richmond', state: 'TX'},
  '775': {id: '775', city: 'Pasadena', state: 'TX'},
  '776': {id: '776', city: 'Beaumont', state: 'TX'},
  '777': {id: '777', city: 'Beaumont', state: 'TX'},
  '778': {id: '778', city: 'Bryan', state: 'TX'},
  '779': {id: '779', city: 'Victoria', state: 'TX'},
  '780': {id: '780', city: 'San Antonio', state: 'TX'},
  '781': {id: '781', city: 'San Antonio', state: 'TX'},
  '782': {id: '782', city: 'San Antonio', state: 'TX'},
  '783': {id: '783', city: 'Corpus Christi', state: 'TX'},
  '784': {id: '784', city: 'Corpus Christi', state: 'TX'},
  '785': {id: '785', city: 'McAllen', state: 'TX'},
  '786': {id: '786', city: 'Austin', state: 'TX'},
  '787': {id: '787', city: 'Austin', state: 'TX'},
  '788': {id: '788', city: 'Uvalde', state: 'TX'},
  '789': {id: '789', city: 'La Grange', state: 'TX'},
  '790': {id: '790', city: 'Amarillo', state: 'TX'},
  '791': {id: '791', city: 'Amarillo', state: 'TX'},
  '792': {id: '792', city: 'Childress', state: 'TX'},
  '793': {id: '793', city: 'Lubbock', state: 'TX'},
  '794': {id: '794', city: 'Lubbock', state: 'TX'},
  '795': {id: '795', city: 'Abilene', state: 'TX'},
  '796': {id: '796', city: 'Abilene', state: 'TX'},
  '797': {id: '797', city: 'Midland', state: 'TX'},
  '798': {id: '798', city: 'El Paso', state: 'TX'},
  '799': {id: '799', city: 'El Paso', state: 'TX'},
  '800': {id: '800', city: 'Denver', state: 'CO'},
  '801': {id: '801', city: 'Denver', state: 'CO'},
  '802': {id: '802', city: 'Denver', state: 'CO'},
  '803': {id: '803', city: 'Boulder', state: 'CO'},
  '804': {id: '804', city: 'Golden', state: 'CO'},
  '805': {id: '805', city: 'Longmont', state: 'CO'},
  '806': {id: '806', city: 'Brighton', state: 'CO'},
  '807': {id: '807', city: 'Fort Morgan', state: 'CO'},
  '808': {id: '808', city: 'Colorado Springs', state: 'CO'},
  '809': {id: '809', city: 'Colorado Springs', state: 'CO'},
  '810': {id: '810', city: 'Pueblo', state: 'CO'},
  '811': {id: '811', city: 'Alamosa', state: 'CO'},
  '812': {id: '812', city: 'Salida', state: 'CO'},
  '813': {id: '813', city: 'Durango', state: 'CO'},
  '814': {id: '814', city: 'Montrose', state: 'CO'},
  '815': {id: '815', city: 'Grand Junction', state: 'CO'},
  '816': {id: '816', city: 'Glenwood Springs', state: 'CO'},
  '817': {id: '817', city: 'Not in use'},
  '818': {id: '818', city: 'Not in use'},
  '819': {id: '819', city: 'Not in use'},
  '820': {id: '820', city: 'Cheyenne', state: 'WY'},
  '821': {id: '821', city: 'Yellowstone', state: 'WY'},
  '822': {id: '822', city: 'Wheatland', state: 'WY'},
  '823': {id: '823', city: 'Rawlins', state: 'WY'},
  '824': {id: '824', city: 'Worland', state: 'WY'},
  '825': {id: '825', city: 'Riverton', state: 'WY'},
  '826': {id: '826', city: 'Casper', state: 'WY'},
  '827': {id: '827', city: 'Newcastle', state: 'WY'},
  '828': {id: '828', city: 'Sheridan', state: 'WY'},
  '829': {id: '829', city: 'Rock Springs', state: 'WY'},
  '830': {id: '830', city: 'Jackson', state: 'WY'},
  '831': {id: '831', city: 'Kemmerer', state: 'WY'},
  '832': {id: '832', city: 'Pocatello', state: 'ID'},
  '833': {id: '833', city: 'Twin Falls', state: 'ID'},
  '834': {id: '834', city: 'Idaho Falls', state: 'ID'},
  '835': {id: '835', city: 'Lewiston', state: 'ID'},
  '836': {id: '836', city: 'Boise', state: 'ID'},
  '837': {id: '837', city: 'Boise', state: 'ID'},
  '838': {id: '838', city: 'Spokane', state: 'WA'},
  '839': {id: '839', city: 'Not in use'},
  '840': {id: '840', city: 'Salt Lake City', state: 'UT'},
  '841': {id: '841', city: 'Salt Lake City', state: 'UT'},
  '842': {id: '842', city: 'Ogden', state: 'UT'},
  '843': {id: '843', city: 'Ogden', state: 'UT'},
  '844': {id: '844', city: 'Ogden', state: 'UT'},
  '845': {id: '845', city: 'Price', state: 'UT'},
  '846': {id: '846', city: 'Provo', state: 'UT'},
  '847': {id: '847', city: 'Richfield', state: 'UT'},
  '848': {id: '848', city: 'Not in use'},
  '849': {id: '849', city: 'Not in use'},
  '850': {id: '850', city: 'Phoenix', state: 'AZ'},
  '851': {id: '851', city: 'Phoenix', state: 'AZ'},
  '852': {id: '852', city: 'Mesa', state: 'AZ'},
  '853': {id: '853', city: 'Glendale', state: 'AZ'},
  '854': {id: '854', city: 'Not in use'},
  '855': {id: '855', city: 'Globe', state: 'AZ'},
  '856': {id: '856', city: 'Tucson', state: 'AZ'},
  '857': {id: '857', city: 'Tucson', state: 'AZ'},
  '858': {id: '858', city: 'Not in use'},
  '859': {id: '859', city: 'Show Low', state: 'AZ'},
  '860': {id: '860', city: 'Flagstaff', state: 'AZ'},
  '861': {id: '861', city: 'Not in use'},
  '862': {id: '862', city: 'Not in use'},
  '863': {id: '863', city: 'Prescott', state: 'AZ'},
  '864': {id: '864', city: 'Kingman', state: 'AZ'},
  '865': {id: '865', city: 'Gallup', state: 'NM'},
  '866': {id: '866', city: 'Not in use'},
  '867': {id: '867', city: 'Not in use'},
  '868': {id: '868', city: 'Not in use'},
  '869': {id: '869', city: 'Not in use'},
  '870': {id: '870', city: 'Albuquerque', state: 'NM'},
  '871': {id: '871', city: 'Albuquerque', state: 'NM'},
  '872': {id: '872', city: 'Not in use'},
  '873': {id: '873', city: 'Gallup', state: 'NM'},
  '874': {id: '874', city: 'Farmington', state: 'NM'},
  '875': {id: '875', city: 'Santa Fe', state: 'NM'},
  '876': {id: '876', city: 'Not in use'},
  '877': {id: '877', city: 'Las Vegas', state: 'NM'},
  '878': {id: '878', city: 'Socorro', state: 'NM'},
  '879': {id: '879', city: 'Truth or Consequences', state: 'NM'},
  '880': {id: '880', city: 'Las Cruces', state: 'NM'},
  '881': {id: '881', city: 'Clovis', state: 'NM'},
  '882': {id: '882', city: 'Roswell', state: 'NM'},
  '883': {id: '883', city: 'Carrizozo', state: 'NM'},
  '884': {id: '884', city: 'Tucumcari', state: 'NM'},
  '885': {id: '885', city: 'El Paso', state: 'TX'},
  '886': {id: '886', city: 'Not in use'},
  '887': {id: '887', city: 'Not in use'},
  '888': {id: '888', city: 'Not in use'},
  '889': {id: '889', city: 'The Lakes', state: 'NV'},
  '890': {id: '890', city: 'Las Vegas', state: 'NV'},
  '891': {id: '891', city: 'Las Vegas', state: 'NV'},
  '892': {id: '892', city: 'Not in use'},
  '893': {id: '893', city: 'Ely', state: 'NV'},
  '894': {id: '894', city: 'Reno', state: 'NV'},
  '895': {id: '895', city: 'Reno', state: 'NV'},
  '896': {id: '896', city: 'Not in use'},
  '897': {id: '897', city: 'Carson City', state: 'NV'},
  '898': {id: '898', city: 'Elko', state: 'NV'},
  '899': {id: '899', city: 'Not in use'},
  '900': {id: '900', city: 'Los Angeles', state: 'CA'},
  '901': {id: '901', city: 'Los Angeles', state: 'CA'},
  '902': {id: '902', city: 'Los Angeles', state: 'CA'},
  '903': {id: '903', city: 'Inglewood', state: 'CA'},
  '904': {id: '904', city: 'Santa Monica', state: 'CA'},
  '905': {id: '905', city: 'Torrance', state: 'CA'},
  '906': {id: '906', city: 'Whittier', state: 'CA'},
  '907': {id: '907', city: 'Long Beach', state: 'CA'},
  '908': {id: '908', city: 'Long Beach', state: 'CA'},
  '909': {id: '909', city: 'Not in use'},
  '910': {id: '910', city: 'Pasadena', state: 'CA'},
  '911': {id: '911', city: 'Pasadena', state: 'CA'},
  '912': {id: '912', city: 'Glendale', state: 'CA'},
  '913': {id: '913', city: 'Thousand Oaks', state: 'CA'},
  '914': {id: '914', city: 'Van Nuys', state: 'CA'},
  '915': {id: '915', city: 'Burbank', state: 'CA'},
  '916': {id: '916', city: 'North Hollywood', state: 'CA'},
  '917': {id: '917', city: 'Rancho Cucamonga', state: 'CA'},
  '918': {id: '918', city: 'Alhambra', state: 'CA'},
  '919': {id: '919', city: 'San Diego', state: 'CA'},
  '920': {id: '920', city: 'San Diego', state: 'CA'},
  '921': {id: '921', city: 'San Diego', state: 'CA'},
  '922': {id: '922', city: 'Indio', state: 'CA'},
  '923': {id: '923', city: 'San Bernardino', state: 'CA'},
  '924': {id: '924', city: 'San Bernardino', state: 'CA'},
  '925': {id: '925', city: 'Riverside', state: 'CA'},
  '926': {id: '926', city: 'Irvine', state: 'CA'},
  '927': {id: '927', city: 'Santa Ana', state: 'CA'},
  '928': {id: '928', city: 'Anaheim', state: 'CA'},
  '929': {id: '929', city: 'Not in use'},
  '930': {id: '930', city: 'Ventura', state: 'CA'},
  '931': {id: '931', city: 'Santa Barbara', state: 'CA'},
  '932': {id: '932', city: 'Bakersfield', state: 'CA'},
  '933': {id: '933', city: 'Bakersfield', state: 'CA'},
  '934': {id: '934', city: 'San Luis Obispo', state: 'CA'},
  '935': {id: '935', city: 'Mojave', state: 'CA'},
  '936': {id: '936', city: 'Fresno', state: 'CA'},
  '937': {id: '937', city: 'Fresno', state: 'CA'},
  '938': {id: '938', city: 'Fresno', state: 'CA'},
  '939': {id: '939', city: 'Salinas', state: 'CA'},
  '940': {id: '940', city: 'San Francisco', state: 'CA'},
  '941': {id: '941', city: 'San Francisco', state: 'CA'},
  '942': {id: '942', city: 'Sacramento', state: 'CA'},
  '943': {id: '943', city: 'Palo Alto', state: 'CA'},
  '944': {id: '944', city: 'San Mateo', state: 'CA'},
  '945': {id: '945', city: 'Oakland', state: 'CA'},
  '946': {id: '946', city: 'Oakland', state: 'CA'},
  '947': {id: '947', city: 'Berkeley', state: 'CA'},
  '948': {id: '948', city: 'Richmond', state: 'CA'},
  '949': {id: '949', city: 'San Rafael', state: 'CA'},
  '950': {id: '950', city: 'San Jose', state: 'CA'},
  '951': {id: '951', city: 'San Jose', state: 'CA'},
  '952': {id: '952', city: 'Stockton', state: 'CA'},
  '953': {id: '953', city: 'Stockton', state: 'CA'},
  '954': {id: '954', city: 'Santa Rosa', state: 'CA'},
  '955': {id: '955', city: 'Eureka', state: 'CA'},
  '956': {id: '956', city: 'Sacramento', state: 'CA'},
  '957': {id: '957', city: 'Sacramento', state: 'CA'},
  '958': {id: '958', city: 'Sacramento', state: 'CA'},
  '959': {id: '959', city: 'Marysville', state: 'CA'},
  '960': {id: '960', city: 'Redding', state: 'CA'},
  '961': {id: '961', city: 'Reno', state: 'NV'},
  '962': {id: '962', city: 'Military', state: 'AP'},
  '963': {id: '963', city: 'Military', state: 'AP'},
  '964': {id: '964', city: 'Military', state: 'AP'},
  '965': {id: '965', city: 'Military', state: 'AP'},
  '966': {id: '966', city: 'Military', state: 'AP'},
  '967': {id: '967', city: 'Honolulu', state: 'HI'},
  '968': {id: '968', city: 'Honolulu', state: 'HI'},
  '969': {id: '969', city: 'Barrigada', state: 'GU'},
  '970': {id: '970', city: 'Portland', state: 'OR'},
  '971': {id: '971', city: 'Portland', state: 'OR'},
  '972': {id: '972', city: 'Portland', state: 'OR'},
  '973': {id: '973', city: 'Salem', state: 'OR'},
  '974': {id: '974', city: 'Eugene', state: 'OR'},
  '975': {id: '975', city: 'Medford', state: 'OR'},
  '976': {id: '976', city: 'Klamath Falls', state: 'OR'},
  '977': {id: '977', city: 'Bend', state: 'OR'},
  '978': {id: '978', city: 'Pendleton', state: 'OR'},
  '979': {id: '979', city: 'Boise', state: 'ID'},
  '980': {id: '980', city: 'Seattle', state: 'WA'},
  '981': {id: '981', city: 'Seattle', state: 'WA'},
  '982': {id: '982', city: 'Everett', state: 'WA'},
  '983': {id: '983', city: 'Tacoma', state: 'WA'},
  '984': {id: '984', city: 'Tacoma', state: 'WA'},
  '985': {id: '985', city: 'Olympia', state: 'WA'},
  '986': {id: '986', city: 'Portland', state: 'OR'},
  '987': {id: '987', city: 'Not in use'},
  '988': {id: '988', city: 'Wenatchee', state: 'WA'},
  '989': {id: '989', city: 'Yakima', state: 'WA'},
  '990': {id: '990', city: 'Spokane', state: 'WA'},
  '991': {id: '991', city: 'Spokane', state: 'WA'},
  '992': {id: '992', city: 'Spokane', state: 'WA'},
  '993': {id: '993', city: 'Pasco', state: 'WA'},
  '994': {id: '994', city: 'Lewiston', state: 'ID'},
  '995': {id: '995', city: 'Anchorage', state: 'AK'},
  '996': {id: '996', city: 'Anchorage', state: 'AK'},
  '997': {id: '997', city: 'Fairbanks', state: 'AK'},
  '998': {id: '998', city: 'Juneau', state: 'AK'},
  '999': {id: '999', city: 'Ketchikan', state: 'AK'}
};

export default threeDigitZipCodes;
