import {Select} from '@shipwell/shipwell-ui';
import {useGetServiceLevels} from 'App/containers/smc3/hooks';

export const ServiceLevelFilter = ({
  onChange,
  defaultLevels,
  defaultLabels
}: {
  onChange: (services: string[], labels: string[]) => void;
  defaultLevels: string[];
  defaultLabels: string[];
}) => {
  const {serviceLevels, getServiceLevelsQuery} = useGetServiceLevels();

  const options =
    serviceLevels?.map((serviceLevel) => {
      return {
        label: serviceLevel.description || '',
        value: serviceLevel.code || ''
      };
    }) || [];
  const defaultValues = defaultLevels.map((level, i) => ({
    value: level,
    label: defaultLabels[i]
  }));
  return (
    <Select
      value={defaultValues}
      onChange={(options: {label: string; value: string}[]) => {
        const serviceLevels = options.map((option) => option.value);
        const serviceLabels = options.map((option) => option.label);
        onChange(serviceLevels, serviceLabels);
      }}
      isLoading={getServiceLevelsQuery.isInitialLoading}
      options={options}
      isMulti
      label="Search Service Levels"
    />
  );
};
