import {Fragment, Component} from 'react';
import {Field, FieldArray, FormSection, FormName} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import {DeprecatedButton, SvgIcon, Button} from '@shipwell/shipwell-ui';
import NotificationFields from './notificationFields';
import InputField from 'App/formComponents/fields/input';
import PhoneNumberField from 'App/formComponents/phoneNumberField';
import CheckboxField from 'App/formComponents/fields/checkbox';
import './styles.scss';

/**
 * Point of Contact Fields
 */
class PointOfContactFields extends Component {
  static defaultProps = {
    name: 'point_of_contacts'
  };

  render() {
    const {name} = this.props;
    const {externalForm} = this.props;

    return (
      <FormSection name={name}>
        <Grid container spacing={2} className="poc-fields">
          <Grid item xs={12} md={externalForm ? 12 : 6}>
            <Field name="first_name" component={InputField} placeholder="First Name" label="First Name" req />
            <Field name="last_name" component={InputField} placeholder="Last Name" label="Last Name" />
            <Field name="email" component={InputField} placeholder="Email" label="Email" req />
            <Field name="phone_number" component={PhoneNumberField} placeholder="Phone" label="Phone" req />
            {/**<Field name="job_title" component={InputField} placeholder="Role" label="Role" />*/}
            {!externalForm && (
              <Field
                name="preferences.receive_bol_on_shipment_booked"
                component={CheckboxField}
                label="Send copy of bill of lading to this contact"
              />
            )}
          </Grid>
          {!externalForm && (
            <Grid item xs={12} md={6}>
              <FormName>{({form}) => <NotificationFields form={form} />}</FormName>
            </Grid>
          )}
        </Grid>
      </FormSection>
    );
  }
}

/*
 * Point of Contacts Fields
 */
class PointOfContactsFields extends Component {
  static defaultProps = {
    name: 'point_of_contacts',
    externalForm: false
  };

  renderContactFields({fields, externalForm}) {
    const handleRemoveContact = (index, e) => {
      e.preventDefault();
      fields.remove(index);
    };

    if (!fields.length) {
      fields.push({});
    }
    return (
      <Fragment>
        {fields.map((field, index) => (
          <Fragment key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!externalForm && (
                  <div className="shipment-stop-header">
                    <span>Contact {index + 1}</span>
                    {fields.length > 1 && (
                      <DeprecatedButton
                        variant="icon"
                        className="trashOutlinedButton"
                        onClick={handleRemoveContact.bind(this, index)}
                      >
                        <SvgIcon name="TrashOutlined" />
                      </DeprecatedButton>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
            <FormName key={index}>
              {({form}) => <PointOfContactFields externalForm={externalForm} form={form} name={field} />}
            </FormName>
          </Fragment>
        ))}
        {!externalForm && (
          <div className="poc-fields-footer">
            <div>
              <span>Want to notify additional people to this stop?</span>
              <Button variant="tertiary" iconName="Plus" onClick={() => fields.push({})}>
                Add Point of Contact
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {name, externalForm} = this.props;

    return <FieldArray name={name} props={{externalForm: externalForm}} component={this.renderContactFields} />;
  }
}

export {PointOfContactsFields};

export default PointOfContactFields;
