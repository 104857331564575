import {DrayageLegOverallStatus} from '@shipwell/corrogo-sdk';
import {Pill, SvgIcon} from '@shipwell/shipwell-ui';
import {useDrayageLeg} from 'App/data-hooks';
import {startCaseToLower} from 'App/utils/startCaseToLower';

export function statusToVariant(status?: DrayageLegOverallStatus) {
  switch (status) {
    case DrayageLegOverallStatus.Tendered:
    case DrayageLegOverallStatus.CarrierConfirmed:
      return 'active';
    case DrayageLegOverallStatus.Draft:
      return 'default';
    case DrayageLegOverallStatus.Cancelled:
      return 'default';
    default:
      return 'primary';
  }
}

export const LegStatusPillBase = ({
  status,
  fixedSize = false,
  size = 'default'
}: {
  status: DrayageLegOverallStatus;
  fixedSize?: boolean;
  size?: 'default' | 'sm' | 'xs';
}) => {
  return (
    <Pill UNSAFEclassName={fixedSize ? 'w-48' : 'max-w-max'} size={size} variant={statusToVariant(status)}>
      <span className="font-bold">{startCaseToLower(status).toUpperCase()}</span>
    </Pill>
  );
};

export const LegStatusPill = ({legId, size}: {legId: string; size?: 'default' | 'sm' | 'xs'}) => {
  const {isLoading, data} = useDrayageLeg(legId);

  const status = data?.overall_status;

  if (isLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  if (!status) {
    return null;
  }

  return <LegStatusPillBase status={status} size={size} />;
};
