import {useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import {
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  DrayageShipmentModeSpecificDataModeEnum,
  DrayageBooking,
  OceanTracking,
  RailTracking,
  DrayageShipmentModeSpecificData,
  Shipment,
  ContainerShipmentItem,
  ProductShipmentItem,
  ExternalDocument,
  ShipmentOverallStatus,
  Stage,
  DrayageReference
} from '@shipwell/corrogo-sdk';
import {SERVICE_DOCUMENTS_QUERY_KEY, SHIPMENTS_QUERY_KEY, SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  getShipment,
  getOceanTrackingServiceDocuments,
  getRailTrackingServiceDocuments,
  getShipmentDocuments
} from 'App/api/corrogo/typed';
import {
  transformContainerInfoToDisplayValues,
  transformServiceToDisplayValues,
  isOceanTracking,
  isService,
  isLegStage,
  transformStopToDisplayValues,
  transformDrayageLegItemsToFormValues,
  isContainerItem,
  transformStopsToDisplayValues,
  transformStopsToFormValues,
  getPickupAndDeliveryStops,
  getStopInfo,
  createSequenceNumberStopTypeAccessor,
  isOceanOrRailTracking,
  transformIdentificationCodeScac
} from 'App/containers/shipments/utils/typed';
import {formatDateToMMDDYY} from 'App/utils/dateTimeGlobals';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';
import {
  portTypes,
  contacts as contactsConstants,
  transformReferencesToDisplayValues,
  getReferenceValue,
  referenceTypes
} from 'App/containers/shipments/utils/constants';
import {HOT_DATA_STALE_TIME, STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {convertKilometersToMiles} from 'App/utils/internationalConstants';
import {startCaseToLower} from 'App/utils/startCaseToLower';

function transformServiceToFormValues(service?: OceanTracking | RailTracking) {
  if (!service) {
    return {};
  }

  const isOceanTrackingService = isOceanTracking(service);

  const serviceType = service?.service_type;
  const portType =
    serviceType === OceanTrackingServiceTypeEnum.OceanTracking
      ? portTypes.OCEAN
      : serviceType === RailTrackingServiceTypeEnum.RailTracking
      ? portTypes.RAIL
      : null;

  const thirdPartyContact = service?.contacts?.find((contact) => contact.contact_type === contactsConstants.OTHER);
  const [firstName = null, lastName = null] = thirdPartyContact?.person_name?.split(' ', 2) || [];
  const references: DrayageReference[] = [];
  const scacIdentificationCode: DrayageReference | null = transformIdentificationCodeScac(
    service?.identification_codes
  );
  if (!isNil(scacIdentificationCode)) {
    references.push(scacIdentificationCode);
  }

  return {
    port_type: portType,
    steamship_line: isOceanTrackingService ? service.steamship_line : service.rail_company,
    identification_codes: service?.identification_codes,
    vessel_name: isOceanTrackingService ? service?.vessel_name : '',
    voyage_number: isOceanTrackingService ? service?.voyage_number : '',
    estimated_arrival: service?.planned_arrival,
    actual_arrival: service?.actual_arrival,
    trackingServiceId: service?.id,
    third_party_contact: {
      first_name: firstName,
      last_name: lastName,
      phone_number: thirdPartyContact?.phone || null,
      email: thirdPartyContact?.email || null,
      id: thirdPartyContact?.id
    },
    references: references
  };
}

const transformServiceToExportFormValues = (
  modeSpecificData: DrayageShipmentModeSpecificData[],
  service?: OceanTracking | RailTracking
) => {
  if (!service) {
    return {};
  }

  const isOceanTrackingService = isOceanTracking(service);

  const serviceType = service?.service_type;
  const portType =
    serviceType === OceanTrackingServiceTypeEnum.OceanTracking
      ? portTypes.OCEAN
      : serviceType === RailTrackingServiceTypeEnum.RailTracking
      ? portTypes.RAIL
      : null;

  const drayageData = modeSpecificData?.find(
    (modeSpecificData) => modeSpecificData.mode === DrayageShipmentModeSpecificDataModeEnum.Drayage
  );

  const exportInfo = drayageData?.export_info;
  const returnDate = exportInfo?.return_date;
  const cutDate = exportInfo?.cut_date;

  const thirdPartyContact = service?.contacts?.find((contact) => contact.contact_type === contactsConstants.OTHER);
  const [firstName = null, lastName = null] = thirdPartyContact?.person_name?.split(' ', 2) || [];
  const references: DrayageReference[] = [];
  const scacIdentificationCode: DrayageReference | null = transformIdentificationCodeScac(
    service?.identification_codes
  );
  if (!isNil(scacIdentificationCode)) {
    references.push(scacIdentificationCode);
  }

  return {
    trackingServiceId: service?.id,
    port_type: portType,
    steamship_line: isOceanTrackingService ? service.steamship_line : service.rail_company,
    identification_codes: service?.identification_codes,
    vessel_name: isOceanTrackingService ? service?.vessel_name : '',
    voyage_number: isOceanTrackingService ? service?.voyage_number : '',
    estimated_arrival: service?.planned_arrival,
    actual_arrival: service?.actual_arrival,
    return_date: returnDate,
    cut_date: cutDate,
    third_party_contact: {
      first_name: firstName,
      last_name: lastName,
      phone_number: thirdPartyContact?.phone || null,
      email: thirdPartyContact?.email || null,
      id: thirdPartyContact?.id
    },
    references: references
  };
};

const transformServiceToImportFormValues = (
  modeSpecificData: DrayageShipmentModeSpecificData[],
  service?: OceanTracking | RailTracking
) => {
  if (!service) {
    return {};
  }

  const isOceanTrackingService = isOceanTracking(service);

  const serviceType = service?.service_type;
  const portType =
    serviceType === OceanTrackingServiceTypeEnum.OceanTracking
      ? portTypes.OCEAN
      : serviceType === RailTrackingServiceTypeEnum.RailTracking
      ? portTypes.RAIL
      : null;

  const drayageData = modeSpecificData?.find(
    (modeSpecificData) => modeSpecificData.mode === DrayageShipmentModeSpecificDataModeEnum.Drayage
  );

  const importInfo = drayageData?.import_info;
  const lastFreeDay = importInfo?.last_free_day;
  const releaseDate = importInfo?.release_date;

  const thirdPartyContact = service?.contacts?.find((contact) => contact.contact_type === contactsConstants.OTHER);
  const [firstName = null, lastName = null] = thirdPartyContact?.person_name?.split(' ', 2) || [];

  const references: DrayageReference[] = [];
  const scacIdentificationCode: DrayageReference | null = transformIdentificationCodeScac(
    service?.identification_codes
  );
  if (!isNil(scacIdentificationCode)) {
    references.push(scacIdentificationCode);
  }

  return {
    trackingServiceId: service?.id,
    port_type: portType,
    steamship_line: isOceanTrackingService ? service.steamship_line : service.rail_company,
    identification_codes: service?.identification_codes,
    vessel_name: isOceanTrackingService ? service?.vessel_name : '',
    voyage_number: isOceanTrackingService ? service?.voyage_number : '',
    estimated_arrival: service?.planned_arrival,
    actual_arrival: service?.actual_arrival,
    third_party_contact: {
      first_name: firstName,
      last_name: lastName,
      phone_number: thirdPartyContact?.phone || null,
      email: thirdPartyContact?.email || null,
      id: thirdPartyContact?.id
    },
    last_free_day: lastFreeDay,
    release_date: releaseDate,
    references: references
  };
};

function transformLegsToTableValues(legs: Stage[], pickupStopTitle: 'Pickup' | 'Load Location') {
  return legs.filter(isLegStage).map((leg) => {
    const pickupStopDisplayValues = transformStopToDisplayValues(leg.leg.stops[0], pickupStopTitle);
    const deliveryStopDisplayValues = transformStopToDisplayValues(leg.leg.stops[1], 'Delivery');
    const container = first(
      transformContainerInfoToDisplayValues(get(leg, 'leg.leg_items', []) as ContainerShipmentItem[])
    );
    const status = isLegStage(leg) ? leg.leg.overall_status : null;

    return {
      pickupDate: pickupStopDisplayValues.formattedPickupDate,
      deliveryDate: deliveryStopDisplayValues.formattedPickupDate,
      status,
      containerId: container?.rawContainerId,
      id: leg.leg.id
    };
  });
}

const transformShipmentInfoToFormValues = (shipment?: Shipment) => {
  const bolNumber = getReferenceValue(referenceTypes.BOL_NUMBER, shipment);
  const houseBolNumber = getReferenceValue(referenceTypes.HOUSE_BOL_NUMBER, shipment);
  const bookingNumber = getReferenceValue(referenceTypes.BOOKING_NUMBER, shipment);
  const customerReferenceNumber = getReferenceValue(referenceTypes.CUSTOMER_REFERENCE_NUMBER, shipment);
  const poNumber = getReferenceValue(referenceTypes.PO_NUMBER, shipment);
  const name = shipment?.name;

  return {
    bol_number: bolNumber,
    house_bol_number: houseBolNumber,
    booking_number: bookingNumber,
    customer_reference_number: customerReferenceNumber,
    po_number: poNumber,
    name
  };
};

const transformModeSpecificInfoToDisplayValues = (modeSpecificData: DrayageShipmentModeSpecificData[]) => {
  const drayageData = modeSpecificData?.find(
    (modeSpecificData) => modeSpecificData.mode === DrayageShipmentModeSpecificDataModeEnum.Drayage
  );
  const exportInfo = drayageData?.export_info;
  const isImport = isNil(exportInfo);

  const baseCutDate = exportInfo?.cut_date;
  const cutDate = baseCutDate ? formatDateToMMDDYY(baseCutDate) : '--';

  const importInfo = drayageData?.import_info;
  const baseLastFreeDay = importInfo?.last_free_day;
  const lastFreeDay = baseLastFreeDay ? formatDateToMMDDYY(baseLastFreeDay) : '--';

  const baseReleaseDate = importInfo?.release_date;
  const releaseDate = baseReleaseDate ? formatDateToMMDDYY(baseReleaseDate) : '--';

  const baseReturnDate = exportInfo?.return_date;
  const returnDate = baseReturnDate ? formatDateToMMDDYY(baseReturnDate) : '--';

  return {
    isImport,
    cutDate,
    lastFreeDay,
    releaseDate,
    returnDate
  };
};

const transformModeSpecificInfoToFormValues = (modeSpecificData: DrayageShipmentModeSpecificData[]) => {
  const drayageData = modeSpecificData?.find(
    (modeSpecificData) => modeSpecificData.mode === DrayageShipmentModeSpecificDataModeEnum.Drayage
  );
  const exportInfo = drayageData?.export_info;

  const returnDate = exportInfo?.return_date;
  const cutDate = exportInfo?.cut_date;

  return {
    early_return_date: returnDate,
    cut_date: cutDate
  };
};

/**
 * Query a shipment by id.
 * This helper also "lifts" any associated service to the top level of the returned object as a
 * convenience.
 */
export default function useShipment(shipmentId?: string, queryConfig = {}) {
  const shipmentQuery = useQuery(
    [SHIPMENTS_QUERY_KEY, shipmentId],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment ID'));
      }
      const response = await getShipment(shipmentId);
      return response.data;
    },
    {
      enabled: !isNil(shipmentId),
      staleTime: HOT_DATA_STALE_TIME,
      ...queryConfig
    }
  );
  const shipment = shipmentQuery.data;

  const {getHazmatOptionFromId} = useHazmatCodes();
  const containerFormValues = transformDrayageLegItemsToFormValues(
    (shipment?.items || []).filter(isContainerItem),
    getHazmatOptionFromId
  );

  const modeSpecificData = shipment?.mode_specific_data || [];
  const modeSpecificDisplayValues = transformModeSpecificInfoToDisplayValues(modeSpecificData);
  const modeSpecificFormValues = transformModeSpecificInfoToFormValues(modeSpecificData);

  const pickupStopTitle = modeSpecificDisplayValues.isImport ? 'Pickup' : 'Load Location';

  const containerDisplayValues = useMemo(() => {
    const containers = shipment?.items || ([] as (ContainerShipmentItem | ProductShipmentItem)[]);
    return transformContainerInfoToDisplayValues(containers.filter(isContainerItem));
  }, [shipment?.items]);

  const legsTableValues = useMemo(() => {
    const legs = shipment?.stages || ([] as Stage[]);
    return transformLegsToTableValues(legs, pickupStopTitle);
  }, [shipment?.stages, pickupStopTitle]);

  const service = shipment?.stages?.find(isService);
  const isCancelled = shipment?.overall_status === ShipmentOverallStatus.Cancelled;

  const drayageLegIds = shipment?.stages?.filter(isLegStage).map((leg) => leg.leg.id);

  const serviceDisplayValues =
    isService(service) && isOceanOrRailTracking(service.service)
      ? transformServiceToDisplayValues(service.service)
      : {};
  const serviceFormValues =
    isService(service) && isOceanOrRailTracking(service.service) ? transformServiceToFormValues(service.service) : {};

  const serviceId = service?.service.id;
  const serviceType = service?.service.service_type;

  const getDocumentsFromServiceQuery = useQuery(
    [SERVICE_DOCUMENTS_QUERY_KEY, serviceId],
    async () => {
      if (!serviceId) {
        return Promise.reject(new Error('Invalid service ID'));
      }
      const response =
        serviceType === OceanTrackingServiceTypeEnum.OceanTracking
          ? await getOceanTrackingServiceDocuments(serviceId)
          : await getRailTrackingServiceDocuments(serviceId);
      return response?.data.data;
    },
    {
      enabled: !isEmpty(serviceId),
      staleTime: STATIC_DATA_STALE_TIME
    }
  );
  const serviceDocuments = getDocumentsFromServiceQuery.data || [];

  const getShipmentDocumentsQuery = useQuery<ExternalDocument[]>(
    [SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment ID'));
      }
      const response = await getShipmentDocuments(shipmentId);
      return response.data.data || [];
    },
    queryConfig
  );
  const shipmentDocuments = getShipmentDocumentsQuery.data || [];

  const exportLegFormValues =
    isService(service) && isOceanOrRailTracking(service.service)
      ? transformServiceToExportFormValues(modeSpecificData, service.service)
      : {};
  const importLegFormValues =
    isService(service) && isOceanOrRailTracking(service.service)
      ? transformServiceToImportFormValues(modeSpecificData, service.service)
      : {};

  const createdBy = shipmentQuery.data?.created_by;
  const customerId = shipmentQuery.data?.customer?.party_id || createdBy?.tenant_id;

  const referenceDisplayValues = transformReferencesToDisplayValues(shipment);
  const shipmentInfoFormValues = transformShipmentInfoToFormValues(shipment);
  const shipmentInfoDisplayValues = {
    shipmentName: shipmentQuery?.data?.name || '--',
    totalMiles: convertKilometersToMiles(
      shipmentQuery.data?.stages?.find(isLegStage)?.leg.total_kilometers || 0,
      2
    ) as string,
    status: startCaseToLower(shipmentQuery.data?.overall_status)
  };

  const stops = shipmentQuery?.data?.stops || [];

  const pickupAndDeliveryStops = getPickupAndDeliveryStops(stops, modeSpecificDisplayValues.isImport);
  const pickupStopInfo = getStopInfo(pickupAndDeliveryStops[0], pickupStopTitle);
  const deliveryStopInfo = getStopInfo(pickupAndDeliveryStops[1], 'Delivery');

  const stopsDisplayValues = transformStopsToDisplayValues(
    shipmentQuery?.data?.stops,
    modeSpecificDisplayValues.isImport
  );

  const stopsFormValues = transformStopsToFormValues(shipmentQuery?.data?.stops);

  const getStopType = createSequenceNumberStopTypeAccessor(
    shipmentQuery?.data?.stops,
    modeSpecificDisplayValues.isImport
  );

  return {
    ...shipmentQuery,
    isLoading: shipmentQuery.isInitialLoading || getDocumentsFromServiceQuery.isInitialLoading,
    isSuccess: shipmentQuery.isSuccess && getDocumentsFromServiceQuery.isSuccess,
    isError: shipmentQuery.isError || getDocumentsFromServiceQuery.isError,
    isFetching: shipmentQuery.isFetching || getDocumentsFromServiceQuery.isFetching,
    shipmentQueryInfo: shipmentQuery,
    getServiceDocumentsQueryInfo: getDocumentsFromServiceQuery,
    getShipmentDocumentsQueryInfo: getShipmentDocumentsQuery,

    context: {
      isCancelled,
      containerFormValues,
      containerDisplayValues,
      service: service?.service,
      serviceDocuments,
      serviceDisplayValues,
      serviceFormValues,
      customerId,
      createdBy,
      shipmentId,
      shipmentDocuments,
      exportLegFormValues,
      importLegFormValues,
      referenceDisplayValues,
      legsTableValues,
      shipmentInfoFormValues,
      shipmentInfoDisplayValues,
      pickupStopTitle,
      modeSpecificDisplayValues,
      modeSpecificFormValues,
      drayageLegIds,
      stopsDisplayValues,
      pickupStopInfo,
      deliveryStopInfo,
      stopsFormValues,
      getStopType
    }
  };
}

/**
 * Query the first associated shipment given a leg that has shipment_ids.
 */
export function useShipmentFromLeg(leg?: DrayageBooking) {
  const shipmentId = first(leg?.shipment_ids);
  return useShipment(shipmentId);
}
