import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {deleteFuelSurchargeTable} from 'App/api/rateTables/typed';

const useDeleteFuelSurchargeTableMutation = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFuelSurchargeTable>>,
    AxiosError<ShipwellError>,
    unknown
  >
) => {
  const deleteFuelSurchargeTableMutation = useMutation(
    (fuelSurchargeTableId: string) => deleteFuelSurchargeTable(fuelSurchargeTableId),
    {...mutationOptions}
  );
  return deleteFuelSurchargeTableMutation;
};

export default useDeleteFuelSurchargeTableMutation;
