import {Cell, CellProps, Row} from 'react-table';
import {DeprecatedButton, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {validateDateTime} from 'App/utils/dateTimeGlobals';
import {formatDate} from 'App/utils/dateTimeGlobalsTyped';
import {Rate} from 'src/@types/quotingTypes';
import {formatCurrency} from 'App/utils/internationalConstants';
import {HTMLAttributes, MouseEvent} from 'react';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {OverlayTrigger, Tooltip as ReactToltip} from 'react-bootstrap';
import {getIsLTL} from 'App/containers/Dashboard/columns';

export interface OnRateSelectChangeEvent {
  (rate: Rate, selected?: boolean): void;
}

type ExpandedRow<T extends object> = Row<T> & {
  getToggleRowExpandedProps: () => HTMLAttributes<HTMLSpanElement>;
  isExpanded: boolean;
};

type ExpandedCellProps<T extends object> = CellProps<T> & {
  row: ExpandedRow<T>;
};

export type OnRateStopsUpdateChangeEvent = (rate: Rate) => void;

export type OnRateStopsAcceptChangeEvent = (rate: Rate) => void;

export interface InstantRatesButtonSelectionPropTypes {
  isSelected?: boolean;
  onClick: () => void;
}

export interface InstantRatesUpdateButtonStopsProps {
  onClick: () => void;
}

export type RateIdType = string | null | undefined;

const InstantRatesButtonSelection = (props: InstantRatesButtonSelectionPropTypes) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <DeprecatedButton size="small" disabled={!props.isSelected} onClick={onClickHandler}>
      {!props.isSelected ? 'Selected' : 'Select'}
    </DeprecatedButton>
  );
};

const ChangeHereButtonSelection = (props: InstantRatesUpdateButtonStopsProps) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <DeprecatedButton size="small" className="float-right" onClick={onClickHandler}>
      Change here
    </DeprecatedButton>
  );
};

const ChangeAcceptRecommendationButtonSelection = (props: InstantRatesUpdateButtonStopsProps) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <DeprecatedButton size="small" className="float-right ml-2" onClick={onClickHandler}>
      Accept
    </DeprecatedButton>
  );
};

const sortByLowPrice = (rowA: Row<Rate>, rowB: Row<Rate>, columnId: string) => {
  const getValue = (row: Row<Rate>) => {
    const value = parseFloat(row.values[columnId] as string);
    return isNaN(value) ? Infinity : value;
  };

  const a = getValue(rowA);
  const b = getValue(rowB);

  const hasErrorA = rowA.original.errors && rowA.original.errors.length > 0;
  const hasErrorB = rowB.original.errors && rowB.original.errors.length > 0;

  if (!hasErrorA && !hasErrorB) {
    return a - b;
  }

  if (hasErrorA && !hasErrorB) {
    return 1;
  }

  if (hasErrorB && !hasErrorA) {
    return -1;
  }

  return 0;
};

interface ServiceLevel {
  row: Row<Rate>;
  company_name: string;
  company_id: string;
}

const ServiceLevelColumn = ({row, company_name, company_id}: ServiceLevel) => {
  const quote = row.original;
  const dispatchError =
    quote && !quote.can_dispatch ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <ReactToltip placement="bottom">
            <div>{quote?.error_message ? quote?.error_message : '--'}</div>
          </ReactToltip>
        }
      >
        <i className="icon icon-Delayed text-danger" />
      </OverlayTrigger>
    ) : (
      <></>
    );
  const isVLTL =
    quote && quote.mode && quote.mode.code === 'VLTL' ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <ReactToltip placement="bottom">
            <div>Volume LTL quote</div>
          </ReactToltip>
        }
      >
        <i className="icon icon-Adjust" />
      </OverlayTrigger>
    ) : (
      <></>
    );
  const isCSP =
    quote && quote.is_csp_rate ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <ReactToltip placement="bottom">
            <div>Customer-specific rate</div>
          </ReactToltip>
        }
      >
        <i className="icon icon-Handshake" />
      </OverlayTrigger>
    ) : (
      <></>
    );

  const providerLogo = quote.provider_logo_url ? <img src={quote.provider_logo_url} height="14" alt="Echo" /> : '';

  const quoteOwner =
    quote?.created_by_company?.is_shipwell === true ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <ReactToltip placement="bottom">
            <span>CloudTrucks rate</span>
          </ReactToltip>
        }
      >
        <img className="quoteItem__belongsToCloudTrucks" src="/images/cloudtrucks_logo.png" alt="CloudTrucks" />
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={
          <ReactToltip placement="bottom">
            <span>
              {quote.created_by_company?.id === company_id ? company_name : quote.created_by_company?.name} rate
            </span>
          </ReactToltip>
        }
      >
        <span className="quoteItem__belongsTo">
          {quote?.created_by_company?.id === company_id
            ? company_name.split('')[0].toUpperCase()
            : quote?.created_by_company?.name.split('')[0].toUpperCase()}
        </span>
      </OverlayTrigger>
    );
  return (
    <div className="instant-rates__columns-details">
      <span>{quote.service_level?.description}</span>
      <span>{quote.lane_type !== 'UNSPECIFIED' ? startCaseToLower(quote.lane_type) : ''}</span>
      <span className="instant-rates__columns-details-icons">
        {isVLTL}
        {isCSP}
        {providerLogo}
        {quoteOwner}
        {dispatchError}
      </span>
    </div>
  );
};

/**
 * Creates table header definitions and behaviors for each row.
 * @param onSelect callback function for when the "select" button is clicked.
 * @param selectedRateId the row to select by rate id
 * @param expandedColumnSet true to show all additional columns, default is false.
 */
const createInstantRatesTableColumns = (
  canTakeActionOnRates: boolean,
  isSelected: boolean,
  mode: string,
  company_id: string,
  company_name: string,
  onSelect: OnRateSelectChangeEvent,
  onShowUpdateStops: OnRateStopsUpdateChangeEvent,
  acceptRecommendation: OnRateStopsAcceptChangeEvent,
  selectedRateId?: RateIdType,
  expandedColumnSet?: boolean,
  shouldHideRates = false
) => {
  const FailedMessageTootipComponent = () => (
    <div className="flex w-full items-center justify-end gap-2 text-sw-error">
      Failed to Quote
      <SvgIcon width="20px" height="16px" name="ErrorFilled" color="sw-error" />
    </div>
  );

  const MessageTootipComponent = () => <SvgIcon width="20px" height="20px" name="ErrorOutlined" color="sw-info" />;

  const columns = [
    {
      Header: 'Carrier',
      accessor: 'carrier.displayName',
      width: 100,
      Cell: ({row}: Cell<Rate>) => {
        const isFedex = row.original?.carrier?.displayName?.toLowerCase() === 'fedex';
        const isLTL = getIsLTL(row.original.mode?.code || '');
        return (
          <div className="flex flex-col">
            {mode === 'LTL' ? (
              <>
                <div className="mb-2">
                  {row.original.carrier
                    ? row.original.carrier.displayName
                    : row.original.created_by_company
                    ? row.original.created_by_company.name
                    : '--'}
                  {isFedex && !!row.original?.carrier.carrier_code ? ` (${row.original.carrier.carrier_code})` : null}
                </div>
                {isLTL && row.original.carrier?.account_code ? <div>{row.original.carrier.account_code}</div> : null}
              </>
            ) : (
              <> {row?.original?.carrier?.displayName} </>
            )}
          </div>
        );
      }
    },
    ...(mode === 'FTL'
      ? [
          {
            Header: 'Pickup',
            accessor: 'pickupDate',
            width: 100,
            Cell: ({row, value}: Cell<Rate, string>) =>
              row.original?.pickupDate && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              )
          }
        ]
      : []),
    ...(mode === 'LTL'
      ? [
          {
            Header: 'Service',
            accessor: 'service',
            width: 150,
            Cell: ({row}: Cell<Rate>) => (
              <ServiceLevelColumn row={row} company_id={company_id} company_name={company_name} />
            )
          }
        ]
      : []),
    ...(expandedColumnSet
      ? [
          {
            Header: 'Delivery',
            accessor: 'deliveryDate',
            width: 100,
            Cell: ({row, value}: Cell<Rate, string>) =>
              row.original?.deliveryDate && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              )
          },
          {
            Header: 'Transit Time',
            accessor: 'transitDays',
            width: 100,
            Cell: ({row, value}: Cell<Rate, string>) =>
              row.original?.transitDays ? (
                <div className="flex w-full justify-center"> {value} days </div>
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              )
          }
        ]
      : []),
    ...(mode === 'FTL'
      ? [
          {
            Header: 'Expires',
            accessor: 'expiry',
            width: 100,
            Cell: ({row, value}: Cell<Rate, string>) =>
              row.original?.expirationDate && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              )
          }
        ]
      : []),
    {
      Header: 'Rate',
      accessor: 'rate.amount',
      sortable: true,
      width: 200,
      sortType: sortByLowPrice,
      Cell: function displayRate({row}: ExpandedCellProps<Rate>) {
        return row.original?.rate?.amount ? (
          <div className="flex w-full flex-row justify-end">
            <div className="flex flex-row">
              <div className="text-black pr-2 font-bold">
                {shouldHideRates ? '--' : formatCurrency(row.original?.rate?.amount, row.original?.rate?.currency)}
              </div>
              <div>
                {!shouldHideRates && row.original.recommendations && row.original.recommendations?.length > 0 ? (
                  <Tooltip
                    portal
                    tooltipClassname="w-64"
                    tooltipContent={
                      <div className="font-bold">
                        <ul>
                          {row.original.recommendations &&
                            row.original.recommendations?.map((recommendation, index) => (
                              <li key={index}>- {recommendation.message}</li>
                            ))}
                        </ul>
                        <br />
                        <ChangeAcceptRecommendationButtonSelection onClick={() => acceptRecommendation(row.original)} />
                        <ChangeHereButtonSelection onClick={() => onShowUpdateStops(row.original)} />
                      </div>
                    }
                  >
                    <MessageTootipComponent />
                  </Tooltip>
                ) : null}
              </div>
            </div>

            <span {...row.getToggleRowExpandedProps()} className="float-right">
              {row.isExpanded ? (
                <span className="material-icons">expand_less</span>
              ) : (
                <span className="material-icons">expand_more</span>
              )}
            </span>
          </div>
        ) : (
          <div className="flex w-full flex-row justify-end">
            <Tooltip
              portal
              tooltipContent={
                <div className="font-bold">
                  <ul>
                    {row.original.errors &&
                      row.original.errors?.map((error, index) => (
                        <li key={index}>
                          <div className="w-64">{error}</div>
                        </li>
                      ))}
                  </ul>
                </div>
              }
            >
              <FailedMessageTootipComponent />
            </Tooltip>
          </div>
        );
      }
    },
    ...(canTakeActionOnRates
      ? [
          {
            Header: 'Actions',
            accessor: 'actions',
            width: 100,
            Cell: function displayActions({row}: Cell<Rate, string>) {
              return row.original?.rate?.amount ? (
                <InstantRatesButtonSelection
                  isSelected={isSelected}
                  onClick={() => onSelect(row.original, !(row.original.rateId === selectedRateId))}
                />
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              );
            }
          }
        ]
      : [])
  ];

  return Object.values(columns);
};

export {createInstantRatesTableColumns};
