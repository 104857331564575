import {SidebarContent, SvgIcon} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import ShareLinkAction from '../ShareLinkAction';
import GenerateDocAction, {GENERATE_BOL, GENERATE_RATE_CONFIRMATION, GENERATE_BOL_PDF} from '../GenerateDocAction';
import AddToLoadBoardAction from '../AddToLoadBoardAction';
import TenderToCarrierAction from '../TenderToCarrierAction';
import RequestBidsAction from '../RequestBidsAction';
import CloseAuctionAction from '../CloseAuctionAction';
import PushToRoutingGuideAction from '../PushToRoutingGuideAction';
import CloneAsAction, {CLONE_AS_QUOTE, CLONE_AS_SHIPMENT} from '../CloneAsAction';
import ArchiveAction from '../ArchiveAction';
import CancelAction from '../CancelAction';
import {PermissionsWithQuotingLimitedUserFallback} from 'App/components/permissions/PermissionsFallback';
import GenerateShippingLabelLink from 'App/containers/Shipment/components/GenerateShippingLabelAction';
import {ContentWrapper, Divider} from 'App/containers/shipments/details/components/SidebarActions';
import {GenerateHouseBol} from 'App/containers/Shipment/components/Actions/generateHouseBol';
import {
  CREATE_SHIPMENTS_USER_PERMISSION,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_INITIATE_ROUTING_GUIDE
} from 'App/components/permissions/PermissionsFallback/constants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import MenuToggle from 'App/common/mainHeader/mobileNavigation/MenuToggle';
import {DocumentOptions} from 'App/containers/Shipment/components/Actions/documentOptions';
import {ManageTrackingDevicesAction} from '../ManageTrackingDevicesAction/manageTrackingDevicesAction';

const SidebarActions = (props) => {
  const {shipment} = props;
  const isDrayage = shipment?.mode?.code === 'DRAYAGE';
  const hasV3ShipmentId = !!shipment?.metadata?.v3_shipment_reference_id;
  const showGenerateHouseBolAction = isDrayage && hasV3ShipmentId;
  const {pdfGeneratorApiForDocumentGeneration} = useFlags();

  return (
    <SidebarContent className="shipment__action-container" title="Actions" {...props}>
      <ContentWrapper>
        <ShareLinkAction {...props} />
        {pdfGeneratorApiForDocumentGeneration ? (
          <div className="w-full cursor-pointer">
            <MenuToggle defaultOpen name="Generate Document">
              <DocumentOptions shipmentId={shipment.id} shipmentMode={shipment?.mode?.code} />
            </MenuToggle>
          </div>
        ) : (
          <>
            <GenerateDocAction toGenerate={GENERATE_BOL} {...props} />
            {pdfGeneratorApiForDocumentGeneration ? (
              <GenerateDocAction toGenerate={GENERATE_BOL_PDF} {...props} />
            ) : null}
            {showGenerateHouseBolAction ? <GenerateHouseBol shipment={shipment} /> : null}
            <GenerateDocAction toGenerate={GENERATE_RATE_CONFIRMATION} {...props} />
            <GenerateShippingLabelLink {...props} />
          </>
        )}
        <Divider />

        <ManageTrackingDevicesAction
          shipmentId={shipment?.id}
          onUpdate={() => {
            console.log('updated');
          }}
        />

        <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
          <AddToLoadBoardAction {...props} />
        </TenderingUserPermissionFallback>
        <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
          <TenderToCarrierAction {...props} />
        </TenderingUserPermissionFallback>
        <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
          <RequestBidsAction {...props} />
        </TenderingUserPermissionFallback>
        <CloseAuctionAction {...props} />
        <TenderingUserPermissionFallback permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]}>
          <PushToRoutingGuideAction {...props} />
        </TenderingUserPermissionFallback>
        <Divider />
        <PermissionsWithQuotingLimitedUserFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
          <CloneAsAction cloneAs={CLONE_AS_QUOTE} {...props} />
          <CloneAsAction cloneAs={CLONE_AS_SHIPMENT} {...props} />
          <Divider />
        </PermissionsWithQuotingLimitedUserFallback>
        <ArchiveAction {...props} />
        <CancelAction {...props} />
      </ContentWrapper>
    </SidebarContent>
  );
};

SidebarActions.tabsRole = 'TabPanel';

export default SidebarActions;
