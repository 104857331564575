import {useQuery} from '@tanstack/react-query';
import {CARRIER_TAGS_KEY} from '../queryKeys';
import {getCarrierTags} from 'App/api/carriers/typed';

export const useGetCarrierTags = ({
  page,
  pageSize,
  ordering,
  name
}: {page?: number; pageSize?: number; ordering?: string; name?: string} = {}) => {
  const {data, isLoading, error} = useQuery([CARRIER_TAGS_KEY, page, pageSize, ordering, name], () =>
    getCarrierTags({page, pageSize, ordering, name})
  );

  return {paginatedCarrierTags: data, isLoading, error};
};
