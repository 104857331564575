import PropTypes from 'prop-types';
import {Title} from '@shipwell/shipwell-ui';
import isEmpty from 'lodash/isEmpty';
import Loader from 'App/common/shipwellLoader';
import {Tag} from 'App/components/Tags';
import useShipmentTags from 'App/data-hooks/shipments/useShipmentTags';

const TagsView = ({shipmentId}) => {
  const {getTagsFromShipmentQuery, isLoading} = useShipmentTags(shipmentId);
  const tags = getTagsFromShipmentQuery.data?.results;
  if (isLoading) {
    return <Loader loading />;
  }
  return isEmpty(tags) ? (
    <Title className="flex justify-center" variant="emptyStateHeader">
      No Tags Added
    </Title>
  ) : (
    tags.map((tag) => (
      <div className="max-w-tag" key={tag.id}>
        <Tag tag={tag} />
      </div>
    ))
  );
};

TagsView.propTypes = {
  shipmentId: PropTypes.string
};

export default TagsView;
