import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {EntityTag} from '../entityTag';

export interface TagPillsForTableCellProps {
  tags?: EntityTag[];
  limit: number;
}

export function TagPillsForTableCell(props: TagPillsForTableCellProps) {
  const visibleTags = props.tags?.slice(0, props.limit);
  const invisibleTags = props.tags?.slice(props.limit);

  return (
    <div className="flex gap-2">
      {visibleTags?.map((tag) => (
        <Tooltip key={tag.id} tooltipContent={tag.label} portal>
          <div style={{backgroundColor: tag.color}} className={`size-4 rounded-full`} />
        </Tooltip>
      ))}

      {!!invisibleTags?.length && (
        <Tooltip
          portal
          tooltipContent={invisibleTags.map((tag) => (
            <div key={tag.id}>- {tag.label}</div>
          ))}
        >
          <SvgIcon name="Plus" width="16" height="16" />
        </Tooltip>
      )}
    </div>
  );
}
