import {Field} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';

const DatFields = ({disabled}: {disabled: boolean}) => {
  return (
    <div className="field-grid">
      <Field
        label="Service Account Username/Email"
        required
        name="key"
        component={FormikTextInput}
        disabled={disabled}
      />
      <Field
        label="Service Account Password"
        required
        name="secret"
        type="password"
        component={FormikTextInput}
        disabled={disabled}
      />
    </div>
  );
};

export default DatFields;
