const validate = (values) => {
  const errors = {};
  //documents
  if (!values.description) {
    errors.description = 'Enter a description';
  } else if (values.description && values.description.length > 1000) {
    errors.description = "Description can't be longer than 1000 characters";
  }
  if (!values.files) {
    errors.files = 'Attach a file here';
  }

  return errors;
};

export default validate;
