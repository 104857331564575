import {RangeCalendar, CalendarModesEnum, SvgIcon, Popover, TextInput, useRangeCalendar} from '@shipwell/shipwell-ui';
import {addDays} from 'App/utils/dateTimeGlobalsTyped';
import {JSX, Dispatch, SetStateAction} from 'react';

const formatDate = (date: Date | null, timeZone?: string) => {
  if (!date) {
    return '';
  }
  const formattedDate = Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    timeZone
  }).format(date);

  return formattedDate;
};

export type DateRangePickerProps = {
  timeZone?: string;
  /**
   * Initial Start Date to select in the calendar
   */
  start: Date | null;
  /**
   * Initial End Date to select in the calendar.
   */
  end: Date | null;
  /**
   * Callback for when the date range is changed.
   * The first element in the array is the start date, the second is the end date.
   */
  onChange: (dates: [Date | null, Date | null]) => void;
};

export type PopoverDateRangePickerTriggerProps = {
  /**
   * Callback to set the open state of the popover.
   */
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  /**
   * Callback to set the trigger element for the popover.
   */
  setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
};

export type PopoverDateRangePickerProps = {
  /**
   * Text to display in the control to the user.
   */
  dateRangeText: string;
} & Pick<PopoverDateRangePickerTriggerProps, 'setIsOpen' | 'setTriggerElement'>;

/**
 * Control for clicking on a an `<input />` "looking" control and opening a calendar to select a date range. Other modes
 * for the calendar are not supported.
 * @param {DateRangePickerProps} params
 * @returns {JSX.Element}
 */
const PopoverDateRangePicker = ({timeZone, start, end, onChange}: DateRangePickerProps): JSX.Element => {
  const minDate = addDays(new Date(), -30);
  const maxDate = addDays(new Date(), 30);

  const startDate = (start || new Date()).toISOString();
  const endDate = (end || new Date()).toISOString();
  const displayText = `${formatDate(start, timeZone)} - ${formatDate(end, timeZone)}`;

  /**
   * Callback handler form the shipwell-ui RangeCalendar component. If the date range selected is not a range
   * the function will return early.
   * @param {Date | null | [Date | null, Date | null]} dates
   * @returns {void}
   */
  const handleCalendarChange = (dates: [string, string]): void => {
    if (!Array.isArray(dates)) {
      // completely ignore single date selections
      return;
    }
    const [startDateStr, endDateStr] = dates;
    const startDate = startDateStr ? new Date(startDateStr) : minDate;
    const endDate = endDateStr ? new Date(endDateStr) : maxDate;

    onChange([startDate, endDate]);
  };

  const {mode, onModeSelect, onDateChange} = useRangeCalendar({
    defaultMode: CalendarModesEnum.after,
    dateStrings: [startDate, endDate],
    onChange: handleCalendarChange
  });

  return (
    <Popover
      placement="auto-start"
      portal
      trigger={({setIsOpen, setTriggerElement}: PopoverDateRangePickerTriggerProps) => (
        <TextInput
          ref={setTriggerElement}
          label="Dates"
          prepend={<SvgIcon name="Calendar" />}
          onClick={() => setIsOpen((prev) => !prev)}
          value={displayText}
        />
      )}
    >
      <RangeCalendar
        mode={mode}
        timeZone={timeZone}
        dates={[startDate, endDate]}
        onChange={onDateChange}
        onModeSelect={onModeSelect}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Popover>
  );
};

export default PopoverDateRangePicker;
