import {useState, useEffect, Dispatch, ReactNode} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Card, Tabs, TabList, Tab, TabPanel, ThemeProvider, theme, Modal, DeprecatedButton} from '@shipwell/shipwell-ui';
import {WithRouterProps} from 'react-router';
import {Action} from 'redux';
import {
  FeatureFlags,
  NetsuiteCategoryOptions,
  NetsuiteCompanyOptions,
  NetsuiteConfig,
  NetsuiteConfigCategoryMappings,
  NetsuiteConfigFieldMappings,
  NetsuiteCustomField
} from '@shipwell/backend-core-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import NetSuiteConfigurationForm from 'App/formComponents/forms/netSuiteConfiguration';
import NetSuiteDataMappingForm from 'App/formComponents/forms/netSuiteDataMapping';
import WithStatusToasts from 'App/components/withStatusToasts';
import {getFinancialManagementSystems} from 'App/actions/_integrations';
import {
  getNetSuiteConfig,
  postNetSuiteConfig,
  updateNetSuiteConfig,
  getNetSuiteCategoryOptions,
  getNetSuiteCustomOptions,
  getNetSuiteCompanyOptions,
  getNetSuiteCategoryMappings,
  updateNetSuiteCategoryMappings,
  getNetSuiteFieldMappings,
  updateNetSuiteFieldMappings,
  deleteNetSuiteConfig
} from 'App/api/integrations/typed';
import packageCategoryMappingRequest from 'App/containers/integrations/details/components/netSuiteDetails/utils/packageCategoryMappingRequest';
import parseCategoryMappingResponseBody, {
  CategoryMappingsType
} from 'App/containers/integrations/details/components/netSuiteDetails/utils/parseCategoryMappingResponseBody';
import type {WithStatusToastProps} from 'App/components/withStatusToasts';

import {State} from 'App/reducers/types';
import checkIfResponseIsOk from 'App/containers/integrations/details/components/netSuiteDetails/utils/checkIfResponseIsOk';
import {integrationsConstants} from 'App/containers/integrations/constants';
import SuiteAppConfigurationForm from 'App/formComponents/forms/suiteAppConfiguration';

/**
 * NetSuite Details
 * @param {*} props
 */

const netSuiteDetailsTabs = ['configure-ns-app', 'configure', 'data-mapping'];

export type DataMappingFormValuesType = {
  category_fields: CategoryMappingsType;
  custom_fields: NetsuiteConfigFieldMappings;
};

type NetSuiteIntegrationDetailsType = {
  label: string;
  slug: string;
  logo: string;
  detailsComponent: ReactNode;
  authenticatedSlug: string;
  isConnectedFms: boolean;
  handleDisconnect?: ((opts?: object) => Promise<object>) | null;
};

type NetSuiteDetailsProps = {
  dispatch: (dispatch: () => Promise<object>) => Dispatch<Action>;
  tab: string;
  loading: boolean;
  connectedFms: string[];
  featureFlags?: FeatureFlags;
  hasRemoveIntegrationsPermission: boolean;
} & WithStatusToastProps &
  Pick<WithRouterProps, 'router'>;

const NetSuiteDetails = ({
  dispatch,
  setError,
  setSuccess,
  tab = 'configure',
  loading = false,
  router,
  connectedFms,
  featureFlags,
  hasRemoveIntegrationsPermission
}: NetSuiteDetailsProps) => {
  const [isConfigured, setIsConfigured] = useState<boolean>(false);
  const [configurationValues, setConfigurationValues] = useState<NetsuiteConfig | Record<string, never>>({});
  const [configurationError, setConfigurationError] = useState<string | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<NetsuiteCategoryOptions>({});
  const [customOptions, setCustomOptions] = useState<NetsuiteCustomField[]>([]);
  const [companyOptions, setCompanyOptions] = useState<NetsuiteCompanyOptions>({});
  const [categoryFieldMappingValues, setCategoryFieldMappingValues] = useState<CategoryMappingsType>({
    invoicing: {},
    billing: {}
  });
  const [customFieldMappingValues, setCustomFieldMappingValues] = useState<
    NetsuiteConfigCategoryMappings | NetsuiteConfigFieldMappings
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  const [showDisconnectModal, setShowDisconnectModal] = useState<boolean>(false);
  const [netSuiteIntegrationDetails, setNetsuiteIntegrationDetails] = useState<NetSuiteIntegrationDetailsType | null>(
    null
  );
  const {intNetsuiteSuiteApp} = useFlags();

  useEffect(() => {
    const fetchNetSuiteConfig = async () => {
      setIsLoading(true);
      try {
        const response = await getNetSuiteConfig();
        if (response.data) {
          setConfigurationValues(response.data);
          setIsConfigured(true);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    setNetsuiteIntegrationDetails({
      ...integrationsConstants['NETSUITE'],
      isConnectedFms: connectedFms.includes('NETSUITE')
      // typecasting here because `integrationsConstants.NETSUITE` returns a component...
      // ...wrapped in `compose` from `recompose` which React 17 has well documented disdain for
    } as unknown as NetSuiteIntegrationDetailsType);
    void fetchNetSuiteConfig();
  }, [dispatch, connectedFms]);

  useEffect(() => {
    const fetchNetSuiteCategoryMappings = async () => {
      setIsLoading(true);
      try {
        const categoryMappingsResponse = await getNetSuiteCategoryMappings();
        if (categoryMappingsResponse?.data) {
          setCategoryFieldMappingValues(parseCategoryMappingResponseBody(categoryMappingsResponse.data));
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const fetchNetSuiteFieldMappings = async () => {
      setIsLoading(true);
      try {
        const fieldMappingsResponse = await getNetSuiteFieldMappings();
        if (fieldMappingsResponse?.data) {
          setCustomFieldMappingValues(fieldMappingsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const fetchNetSuiteCategoryOptions = async () => {
      setIsLoading(true);
      try {
        const categoryOptionsResponse = await getNetSuiteCategoryOptions();
        if (categoryOptionsResponse?.data) {
          setCategoryOptions(categoryOptionsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const fetchNetSuiteCustomOptions = async () => {
      setIsLoading(true);
      try {
        const customOptionsResponse = await getNetSuiteCustomOptions();
        if (customOptionsResponse?.data?.fields) {
          setCustomOptions(customOptionsResponse.data.fields);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const fetchNetSuiteCompanyOptions = async () => {
      setIsLoading(true);
      try {
        const companyOptionsResponse = await getNetSuiteCompanyOptions();
        if (companyOptionsResponse?.data) {
          setCompanyOptions(companyOptionsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    if (isConfigured) {
      void fetchNetSuiteCategoryMappings();
      void fetchNetSuiteFieldMappings();
      void fetchNetSuiteCategoryOptions();
      void fetchNetSuiteCustomOptions();
      void fetchNetSuiteCompanyOptions();
    }
  }, [isConfigured]);

  const handleConfigSubmit = async (values: NetsuiteConfig) => {
    try {
      setConfigurationValues(values);
      let response;
      if (!isConfigured) {
        response = await postNetSuiteConfig(values);
      } else {
        response = await updateNetSuiteConfig(values);
      }
      if (checkIfResponseIsOk(response.status)) {
        setSuccess('Success!', 'You have successfully connected to the NetSuite integration.');
        dispatch(getFinancialManagementSystems());
        setConfigurationValues(response.data);
        setIsConfigured(true);
        setConfigurationError(null);
      } else if (response?.status === 400) {
        setIsConfigured(false);
        setConfigurationError('The configuration combination is not recognized. Please try again.');
        setError('Error', 'Failure connecting to the NetSuite configuration. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setIsConfigured(false);
      setConfigurationError('The configuration combination is not recognized. Please try again.');
      setError('Error', 'Failure connecting to the NetSuite configuration. Please try again.');
    }
  };

  const onMappingUpdate = async (values: DataMappingFormValuesType) => {
    setCategoryFieldMappingValues(values.category_fields);
    setCustomFieldMappingValues(values.custom_fields);
    Object.keys(values.custom_fields).forEach((customField) => {
      if (values.custom_fields[customField as keyof typeof values.custom_fields] === '') {
        values.custom_fields[customField as keyof typeof values.custom_fields] = null;
      }
    });
    const submissionPromises = [
      updateNetSuiteCategoryMappings(packageCategoryMappingRequest(values.category_fields)),
      updateNetSuiteFieldMappings(values.custom_fields)
    ];
    try {
      const [categoryFieldsResponse, customFieldsResponse] = await Promise.all(submissionPromises);
      if (checkIfResponseIsOk(categoryFieldsResponse.status) && categoryFieldsResponse.data) {
        setCategoryFieldMappingValues(
          parseCategoryMappingResponseBody(categoryFieldsResponse.data as NetsuiteConfigCategoryMappings)
        );
      }
      if (checkIfResponseIsOk(customFieldsResponse.status) && customFieldsResponse.data) {
        setCustomFieldMappingValues(customFieldsResponse.data);
      }
      setSuccess('Changes Saved!', 'Your changes to the NetSuite integration have been successfully saved.');
    } catch (error) {
      console.error(error);
      setError(
        'Error',
        <div>
          Your changes to the NetSuite integration could not be saved.
          <span className="font-bold" onClick={() => void onMappingUpdate(values)}>
            Retry
          </span>
          .
        </div>,
        'top-right',
        {delay: null}
      );
    }
  };
  const filteredNetSuiteDetailsTabs = intNetsuiteSuiteApp ? netSuiteDetailsTabs : netSuiteDetailsTabs.slice(1);

  const onNextTab = () => {
    router.push(`/company/integrations/netsuite/data-mapping`);
  };

  const onSetTab = (index: number) => {
    router.push(`/company/integrations/netsuite/${filteredNetSuiteDetailsTabs[index]}`);
  };

  const ThemedTabs = ({
    selectedIndex,
    onSelect,
    children
  }: {
    selectedIndex: number;
    onSelect: (index: number) => void;
    children: ReactNode;
  }) => (
    <ThemeProvider theme={theme}>
      <Tabs selectedIndex={selectedIndex} onSelect={onSelect}>
        {children}
      </Tabs>
    </ThemeProvider>
  );

  const handleDisconnect = async () => {
    const result = await deleteNetSuiteConfig();
    if (checkIfResponseIsOk(result.status)) {
      setSuccess('Success!', 'You have successfully disconnected from Netsuite.');
      router.push(`/company/integrations`);
    }
  };

  const shouldShowDisconnect =
    netSuiteIntegrationDetails?.isConnectedFms &&
    netSuiteIntegrationDetails.handleDisconnect &&
    featureFlags?.integrations_enabled &&
    hasRemoveIntegrationsPermission;

  return (
    <div className="w-full">
      {intNetsuiteSuiteApp && filteredNetSuiteDetailsTabs.indexOf(tab) === 0 ? null : (
        <div className="flex justify-end pb-6">
          {shouldShowDisconnect ? (
            <DeprecatedButton variant="secondary" onClick={() => setShowDisconnectModal(true)}>
              Disconnect
            </DeprecatedButton>
          ) : null}
        </div>
      )}
      <Card headerClassName="hidden" bodyClassName="border-t-0" draggableProvided={false}>
        <ThemedTabs
          selectedIndex={filteredNetSuiteDetailsTabs.indexOf(tab)}
          onSelect={(index: number) => onSetTab(index)}
        >
          <TabList className="pb-4">
            {intNetsuiteSuiteApp ? <Tab>Configure with SuiteApp</Tab> : null}
            <Tab>{`${intNetsuiteSuiteApp ? 'Configure with NetSuite Financials' : 'Configure'}`}</Tab>
            <Tab disabled={!isConfigured}>Data Mapping</Tab>
          </TabList>
          {intNetsuiteSuiteApp ? (
            <TabPanel>
              <SuiteAppConfigurationForm />
            </TabPanel>
          ) : null}
          <TabPanel>
            <NetSuiteConfigurationForm
              onNext={onNextTab}
              onSubmit={handleConfigSubmit}
              isConfigured={isConfigured}
              values={configurationValues}
              configurationError={configurationError}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel>
            <NetSuiteDataMappingForm
              values={{
                category_fields: categoryFieldMappingValues,
                custom_fields: customFieldMappingValues as NetsuiteConfigFieldMappings
              }}
              onSubmit={onMappingUpdate}
              categoryOptions={categoryOptions}
              customOptions={customOptions}
              companyOptions={companyOptions}
              router={router}
            />
          </TabPanel>
        </ThemedTabs>
      </Card>
      <Modal
        show={Boolean(showDisconnectModal)}
        title="Disconnect"
        variant="warning"
        onClose={() => setShowDisconnectModal(false)}
        primaryBtnName="Continue"
        onPrimaryAction={handleDisconnect}
      >
        Are you sure you want to disconnect from Netsuite? All mapping data will be lost if you disconnect.
      </Modal>
    </div>
  );
};

export default compose<NetSuiteDetailsProps, unknown>(
  connect((state: State) => ({
    connectedFms: state.integrations.connectedFms as string[],
    featureFlags: state.auth.company.feature_flags,
    hasRemoveIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.remove_integrations')
  })),
  WithStatusToasts
)(NetSuiteDetails);
