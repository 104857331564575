import PropTypes from 'prop-types';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import Icon from '../icon';
import Card from '../card';
import './styles.scss';

const Step = (props) => {
  const {onDeleteStep, type, stepNum} = props;
  return (
    <div className={`automation__step ${type === 'add' ? 'add' : ''}`}>
      <span className="automation__step-line" />
      <Icon {...props} />
      <Card {...props} />
      <span className="automation__step-delete">
        {type !== 'trigger' && stepNum !== 0 && onDeleteStep && (
          <DeprecatedButton variant="icon" onClick={onDeleteStep}>
            <SvgIcon name="TrashOutlined" />
          </DeprecatedButton>
        )}
      </span>
    </div>
  );
};

Step.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  type: PropTypes.string,
  onDeleteStep: PropTypes.func
};

export default Step;
