import {ReactNode} from 'react';

const SupplierAppointmentSidebar = ({title, children}: {title: string; children: ReactNode}) => {
  return (
    <>
      <p>
        <strong>{title}</strong>
      </p>
      {children ? <p className="w-48 break-words">{children}</p> : null}
    </>
  );
};
export default SupplierAppointmentSidebar;
