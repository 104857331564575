import {Formik, Field} from 'formik';
import {object, string, mixed} from 'yup';
import {FormikFileSelect, FormikTextInput, DeprecatedButton, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {
  RFP_ALPHA_TEMPLATE,
  RFP_OPTIMIZED_TEMPLATE,
  DISCOUNT_PERCENTAGES_TEMPLATE,
  RATE_MINIMUMS_TEMPLATE,
  CORROGO_PURCHASE_OR_SALES_ORDERS_TEMPLATE
} from 'App/containers/userCompany/constants';

const uploadSchema = object().shape({
  google_sheet_id: string()
    .url('Enter a valid URL')
    .test('google-sheets', 'Enter a valid Sheets URL', (value) => {
      if (value) {
        const testExp = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)');
        //validate against the standard Google Sheets URL schema (https://docs.google.com/spreadsheets/d/xxxxxxx)
        return testExp.test(value);
      }
      return true;
    }),
  csv_file: mixed().test('csv_file', 'Select a file or enter a URL', function (value) {
    const {google_sheet_id} = this.parent;
    if (!google_sheet_id) {
      return Boolean(value);
    }
    return true;
  })
});

const CSV_TEMPLATES = {
  CONTRACTS: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/contracts.csv',
  ORDERS: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/orders.csv',
  PRODUCTS: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/products.csv',
  ADDRESS_BOOK: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/address_book.csv',
  CARRIERS: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/carriers.csv',
  SERVICE_LEVEL_AGREEMENTS:
    'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/service_level_agreements.csv',
  SHIPMENTS: 'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/shipments.csv',
  FUEL_SURCHARGE_TABLES:
    'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/fuel_surcharge_table.csv',
  ACCESSORIAL_CHARGE_TABLES:
    'https://shipwell-static.s3-us-west-2.amazonaws.com/csv-import-samples/accessorial_charge_table.csv',
  RFP_ROWS: RFP_ALPHA_TEMPLATE,
  RFP_LANE_OPTIMIZED: RFP_OPTIMIZED_TEMPLATE,
  DISCOUNT_PERCENTAGES: DISCOUNT_PERCENTAGES_TEMPLATE,
  RATE_MINIMUMS: RATE_MINIMUMS_TEMPLATE,
  PURCHASE_OR_SALES_ORDER: CORROGO_PURCHASE_OR_SALES_ORDERS_TEMPLATE
};

const SelectFileForm = (props) => {
  const {type, handleFileUpload, onCancel, onPrevious} = props;
  return (
    <Formik
      initialValues={{csv_file: '', google_sheet_id: '', type}}
      validationSchema={uploadSchema}
      onSubmit={(values, actions) => handleFileUpload(values, actions)}
    >
      {({handleSubmit, isSubmitting, values}) => (
        <form className="imports__form" onSubmit={handleSubmit}>
          <p>Upload a document (.csv) or enter a Google Sheets URL.</p>
          <Field
            component={FormikFileSelect}
            name="csv_file"
            disabled={values.google_sheet_id}
            acceptedMimeTypes={['text/csv']}
          />
          <Field
            component={FormikTextInput}
            name="google_sheet_id"
            label="Google Sheets URL"
            disabled={values.csv_file}
          />
          <small>Link must be publicly available.</small>
          {CSV_TEMPLATES[type] && (
            <p className="imports__form-download">
              <a href={CSV_TEMPLATES[type]}>
                <i className="material-icons">vertical_align_bottom</i> Download CSV Template
              </a>
              <Tooltip
                tooltipContent={
                  <span>
                    Please fill out all required columns marked with * before uploading. Optional columns can be left
                    blank or removed if they don't apply.
                  </span>
                }
                trigger="hover"
                wrapperClassname=""
                tooltipClassname="imports__form-tooltip"
              >
                <i className="material-icons">info_outline</i>
              </Tooltip>
            </p>
          )}
          <div className="mt-12">
            <ModalFormFooter
              options={
                onPrevious ? (
                  <DeprecatedButton
                    variant="icon"
                    onClick={onPrevious}
                    icon={<SvgIcon className="ml-0" name="ArrowBack" />}
                  >
                    Back
                  </DeprecatedButton>
                ) : null
              }
              onCancel={onCancel}
              secondaryActionName="Cancel"
              onPrimaryActionClick={() => handleSubmit(values)}
              isSubmitting={isSubmitting}
              primaryActionName="Next"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SelectFileForm;
