import {Formik, Form, Field} from 'formik';
import PropTypes from 'prop-types';
import {object, string} from 'yup';
import {FormikTextInput, Title, Note, DeprecatedButton} from '@shipwell/shipwell-ui';

const defaultFormValues = {
  cancellation_reason: ''
};

export const validationSchema = object().shape({
  cancellation_reason: string().required('A reason is required.')
});

const ShipmentCancelForm = ({values, onSubmit, onCancel}) => {
  const handleSubmit = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultFormValues, ...values}}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, dirty, edit, isValid}) => (
        <Form className="ShipmentCancelForm">
          <Title variant="formTitle">Are you sure you want to cancel this shipment?</Title>
          <div className="confirmational-modal-paragraph">
            This will cancel your shipment on the platform and send a cancellation email to the carrier, however,
            cancellation fees may still apply. In order to confirm the cancellation please call the carrier.
          </div>
          <div className="field-grid grid-1-1">
            <Field component={FormikTextInput} label="Cancellation Reason" name="cancellation_reason" required />
          </div>
          <Note>
            By cancelling shipment, you are indicating that you have read and understand the cancellation terms listed
            in the{' '}
            <a href="https://shipwell.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
            .
          </Note>
          {(!edit || dirty) && (
            <div className="page-footer">
              <DeprecatedButton variant="secondary" onClick={handleCancel}>
                No, keep shipment
              </DeprecatedButton>
              <DeprecatedButton
                disabled={isSubmitting || !isValid || !dirty}
                icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                type="submit"
                variant="warning"
              >
                Yes, cancel shipment
              </DeprecatedButton>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

ShipmentCancelForm.defaultProps = {
  cancellation_reason: '',
  onCancel: () => {},
  onSubmit: () => {}
};

ShipmentCancelForm.propTypes = {
  cancellation_reason: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ShipmentCancelForm;
