import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {integrationsConstants} from 'App/containers/integrations/constants';
import './styles.scss';

/**
 * Company Integration Card
 * @param {*} props
 */
const CompanyIntegrationCard = (props) => {
  const {
    integration,
    isAuthenticating,
    isAuthenticated,
    handleAuthenticate,
    disabled,
    router,
    canShowCard,
    isSuiteAppAuthenticated
  } = props;

  const handleCardClick = (e) => {
    e.preventDefault();
    if (isSuiteAppAuthenticated && !disabled && integrationsConstants?.[integration]) {
      router.push(`/company/integrations/${integrationsConstants[integration].suiteAppAuthenticatedSlug}`);
    }
    if (isAuthenticated && !disabled && integrationsConstants?.[integration]) {
      if (integrationsConstants?.[integration]?.authenticatedSlug) {
        router.push(`/company/integrations/${integrationsConstants[integration].authenticatedSlug}`);
      } else {
        router.push(`/company/integrations/${integrationsConstants[integration].slug}`);
      }
    }
  };

  return canShowCard ? (
    <div
      onClick={(e) => handleCardClick(e)}
      className={classNames('company-integration-card', {
        isAuthenticated: isAuthenticated || isSuiteAppAuthenticated,
        disabled
      })}
    >
      {(isAuthenticated || isSuiteAppAuthenticated) && (
        <span className="icon-connected">
          <SvgIcon name="CheckCircleFilled" color="$sw-success" />
        </span>
      )}
      {integrationsConstants?.[integration]?.hoverDetail && (
        <div className="logo-hover-box">
          <div className="px-6 py-3.5 text-xs leading-tight">{integrationsConstants[integration].hoverDetail}</div>
        </div>
      )}
      <div className="company-integration-card-top">
        <div className="company-integration-card-logo-wrapper">
          <img
            alt={`integration-logo-${integrationsConstants?.[integration]?.slug}`}
            className="integration-logo"
            src={integrationsConstants?.[integration]?.logo}
          />
        </div>
        <div className="company-integration-card-name">{integrationsConstants?.[integration]?.label}</div>
      </div>
      <DeprecatedButton
        disabled={disabled || isAuthenticating || isAuthenticated || isSuiteAppAuthenticated}
        onClick={(e) => {
          e.stopPropagation();
          handleAuthenticate();
        }}
        variant="primary"
      >
        {isAuthenticated || isSuiteAppAuthenticated ? 'Connected' : 'Connect'}
      </DeprecatedButton>
    </div>
  ) : null;
};

export default compose(
  connect((state) => ({
    netSuiteEnabled: get(state, 'userCompany.company.feature_flags.netsuite_enabled', false)
  })),
  withConditionalFallback(({netSuiteEnabled, integration}) => !netSuiteEnabled && integration === 'NETSUITE')
)(CompanyIntegrationCard);
