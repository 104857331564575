import {useQuery} from '@tanstack/react-query';
import {SPOT_NEGOTIATIONS_EMAIL_PREVIEW_KEY} from 'App/data-hooks/queryKeys';
import {getSpotNegotiationsEmailPreview} from 'App/api/quoting/typed';

const useGetSpotNegotiationsEmailPreview = (shipmentId = '') => {
  const getSpotNegotiationsEmailPreviewQuery = useQuery(
    [SPOT_NEGOTIATIONS_EMAIL_PREVIEW_KEY, shipmentId],
    async () => {
      const response = await getSpotNegotiationsEmailPreview({shipmentId});
      return response.data;
    },
    {enabled: !!shipmentId}
  );
  return {
    spotNegotiationsEmailPreview: getSpotNegotiationsEmailPreviewQuery?.data
  };
};

export default useGetSpotNegotiationsEmailPreview;
