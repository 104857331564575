import {
  ScriptsApi,
  ScriptsApiGetInvocationLogsScriptsInvocationsInvocationIdLogsGetRequest,
  ScriptsApiListInvocationsScriptsInvocationsGetRequest
} from '@shipwell/scriptlab-sdk';
import {configuration} from './configuration';

const scriptsApi = new ScriptsApi(configuration);

export function listInvocationsScriptsInvocationsGet(params: ScriptsApiListInvocationsScriptsInvocationsGetRequest) {
  return scriptsApi.listInvocationsScriptsInvocationsGet(params);
}

export function getInvocationLogsScriptsInvocationsInvocationIdLogsGet(
  params: ScriptsApiGetInvocationLogsScriptsInvocationsInvocationIdLogsGetRequest
) {
  return scriptsApi.getInvocationLogsScriptsInvocationsInvocationIdLogsGet(params);
}
