import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export const stopStatuses = [
  {id: 'en_route', variant: 'default'},
  {id: 'at_stop', variant: 'default'},
  {id: 'departed', variant: 'default'},
  {id: 'cancelled', variant: 'default'}
];

export const stopStatusMap = keyBy(stopStatuses, (status) => status.id);

const stopStatusApi = stopStatuses.map((stopStatus) => stopStatus.id.toUpperCase());

export const stopStatusPropType = PropTypes.shape({
  status: PropTypes.oneOf(stopStatusApi)
});

export function generateStopStatusErrorMessage(error) {
  const stopsFieldError = get(error, 'field_errors', []).some(
    (field_error) => get(field_error, 'field_name') === 'stops'
  )
    ? 'A carrier cannot be en route to or at more than one stop at the same time. You may need to update another stop’s status first.'
    : '';
  return `
${get(error, 'error_description', 'There was an error setting the stop status.')}

${stopsFieldError}
`;
}
