import {FormikSelect} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {usePackageTypes} from 'App/data-hooks';
import {getPackageTypesReactSelectOptions} from 'App/data-hooks/mdm/usePackageTypes';

export const LineItemPackagingField = ({index}: {index: number}) => {
  const {data: packageTypes} = usePackageTypes();
  const formattedPackageTypes = getPackageTypesReactSelectOptions(packageTypes || []);
  return (
    <Field
      label="Packaging"
      name={`line_items[${index}].package_type`}
      component={FormikSelect}
      options={formattedPackageTypes}
      simpleValue
    />
  );
};
