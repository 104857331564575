import {Modal, FormikTextInput} from '@shipwell/shipwell-ui';
import {Field, Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import getNil from 'App/utils/getNil';
import {useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';

export const NotesModal = ({showModal, onClose, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const shipmentModel = getNil(shipmentQuery, 'data', {});
  const initialValues = pick(shipmentModel, 'instructions');

  const {updateShipmentSummaryMutation} = useCreateOrUpdateShipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const handleSubmit = (values) => {
    const {instructions} = values;

    const payload = {
      ...shipmentModel,
      instructions: instructions
    };
    updateShipmentSummaryMutation.mutate({shipmentId, data: payload});

    onClose();
  };

  return (
    <Modal onClose={onClose} title="Edit Notes For Carrier" show={showModal} footerComponent={null}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <Field
            className="mb-4 border-b border-sw-border pb-6"
            name="instructions"
            label="Notes for Carrier"
            component={FormikTextInput}
          />
          <ModalFormFooter onCancel={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};

NotesModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  shipmentId: PropTypes.string
};
