const validate = (values) => {
  const errors = {};
  //documents
  if (!values.description) {
    errors.description = 'Enter a description';
  } else if (values.description && values.description.length > 1000) {
    errors.description = 'Must be shorter';
  }
  if (!values.files) {
    errors.files = 'Attach a file here';
  }
  if (Array.isArray(values.files) && !values.files.length) {
    errors.files = 'Attach a valid file type here';
  }
  if (!values.type) {
    errors.type = 'Select a document type';
  }
  return errors;
};

export default validate;
