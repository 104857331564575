import get from 'lodash/get';
import {isIE11} from 'App/utils/detection';

/**
 * Transform a user object to a shape that LaunchDarkly expects.
 *
 * @param {Object} user
 *
 * @return {Object}
 */
export function userToLDUser(user, company, isUsingOptimizedShipmentDashboardUi) {
  return {
    // Give all users the same nil uuid if they don't have a key.
    // This means all anonymous users have the same key, and this will affect percentage rollouts.
    key: get(user, 'id', '00000000-0000-0000-0000-000000000000'),
    anonymous: get(user, 'anonymous'),
    firstName: get(user, 'first_name'),
    lastName: get(user, 'last_name'),
    email: get(user, 'email'),
    avatar: get(user, 'avatar'),
    custom: {
      ...user,
      company_name: company?.name,
      isIE11: isIE11(),
      isUsingOptimizedShipmentDashboardUi
    }
  };
}

/**
 * A Promise-ified version of ldClient.identify
 *
 * @param {Object} ldClient
 * @param {Object} user
 *
 * @return {Promise}
 */
export function ldIdentifyAsync(ldClient, user) {
  return new Promise((resolve) => ldClient.identify(user, null, resolve));
}
