import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import {OverlayTrigger, Tooltip, Button, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router';
import * as actions from '../../actions/shipments';
import {formatCurrencyValue} from 'App/utils/globals';

export class RateQuote extends Component {
  static propTypes = {
    handleBookQuote: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.selectQuoteFromList = this.selectQuoteFromList.bind(this);
    this.state = {
      showError: false,
      expanded: false,
      expandedFinancials: []
    };
  }

  componentWillMount() {
    //console.log(this.props.quote);
  }

  selectQuoteFromList(quote) {
    this.props.selectQuoteFromList(quote);
  }

  toggleFinancials(quoteId) {
    const current = this.state.expandedFinancials;
    if (current.includes(quoteId)) {
      current.splice(current.indexOf(quoteId), 1);
    } else {
      current.push(quoteId);
    }
    this.setState({expandedFinancials: current});
  }

  render() {
    let {quote, buttonLabel} = this.props;
    let achFee = 5; //quote.total*.008;
    if (achFee > 5) {
      achFee = 5;
    }
    let quoteList = [];
    if (this.props.filter === 'price') {
      quoteList = quote.quotes.slice();
      quoteList = quoteList.sort(function (a, b) {
        const x = a.total;
        const y = b.total;
        if (a.is_failure) {
          return 1;
        }
        if (b.is_failure) {
          return -1;
        }

        return x < y ? -1 : 1;
      });
    } else if (this.props.filter === 'speed') {
      quoteList = quote.quotes.slice();
      quoteList = quoteList.sort(function (a, b) {
        const x = a.delivery_date;
        const y = b.delivery_date;
        if (a.is_failure) {
          return 1;
        }
        if (b.is_failure) {
          return -1;
        }
        if (!moment(x).isValid()) {
          return 1;
        }
        if (!moment(y).isValid()) {
          return -1;
        }
        return x < y ? -1 : 1;
      });
    } else if (this.props.filter === 'guaranteed') {
      quoteList = quote.quotes.slice();
      quoteList = quoteList.sort(function (a, b) {
        if (a.is_failure) {
          return 1;
        }
        if (b.is_failure) {
          return -1;
        }
        if (
          (a.service_level?.description === 'Guaranteed' ||
            a.service_level?.description === 'Guaranteed Single-Hour Window' ||
            a.service_level?.description === 'Guaranteed by AM (Noon)') &&
          b.service_level?.description !== 'Guaranteed' &&
          b.service_level?.description !== 'Guaranteed Single-Hour Window' &&
          b.service_level?.description !== 'Guaranteed by AM (Noon)'
        ) {
          return -1;
        }
        if (
          (b.service_level?.description === 'Guaranteed' ||
            b.service_level?.description === 'Guaranteed Single-Hour Window' ||
            b.service_level?.description === 'Guaranteed by AM (Noon)') &&
          a.service_level?.description !== 'Guaranteed' &&
          a.service_level?.description !== 'Guaranteed Single-Hour Window' &&
          a.service_level?.description !== 'Guaranteed by AM (Noon)'
        ) {
          return 1;
        }
        return 0;
      });
    }
    const quoteToShow = quoteList.length > 0 ? quoteList[0] : {};
    quoteList.shift();

    if (quote && quote.quotes && quote.quotes.length > 1) {
      //we have multiple quotes from this carrier to aggregate

      let numDays = quoteToShow.transit_days;
      if (isNaN(numDays) || numDays === null) {
        numDays = 'Not provided';
      } else {
        if (numDays === 0) {
          numDays = 'Not provided';
        } else {
          if (numDays === 1) {
            numDays = numDays + ' day';
          } else if (!parseInt(numDays, 10)) {
            numDays = 'Not provided';
          } else {
            numDays = numDays + ' days';
          }
        }
      }
      const priceFormatted =
        '$' +
        parseFloat(Math.round(quoteToShow.total * 100) / 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

      // figure out if we want to display a special icon for CSP or GlobalTranz
      var specialRateIcon = null;
      var handshakeIcon = null;
      // we have a non-standard quote
      if (quoteToShow.provider_logo_url) {
        specialRateIcon = <img src={quoteToShow.provider_logo_url} height="14" alt="Echo" />;
      }
      if (quoteToShow.is_csp_rate) {
        //this is CSP
        handshakeIcon = (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip placement="bottom" id="tooltip">
                <div>Customer-specific rate</div>
              </Tooltip>
            }
          >
            <i className="icon icon-Handshake" />
          </OverlayTrigger>
        );
      }

      const vLTLIcon = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <div>Volume LTL quote</div>
            </Tooltip>
          }
        >
          <i className="icon icon-Adjust" />
        </OverlayTrigger>
      );

      if (quoteToShow.charge_line_items && quoteToShow.charge_line_items.length > 0) {
        quoteToShow.charge_line_items = quoteToShow.charge_line_items.sort((a, b) => {
          if (a.is_provider_markup) {
            return 1;
          }
          if (b.is_provider_markup) {
            return -1;
          }
          return a.unit_amount >= b.unit_amount ? -1 : 1;
        });
      }

      return (
        <div>
          <Row
            className="quoteItem"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTop: '1px solid #ddd',
              borderBottom: '1px solid #ddd'
            }}
          >
            <Col xs={2} md={2}>
              {quoteToShow && quoteToShow.carrier && quoteToShow.carrier.display_name}
              &nbsp;
            </Col>
            <Col style={{fontSize: '1.8em', fontWeight: 'bold', textAlign: 'left'}} xsHidden md={2}>
              {quoteToShow && !quoteToShow.is_failure && priceFormatted}
            </Col>
            <Col style={{fontSize: '1.0em', fontWeight: 'bold', textAlign: 'left'}} xs={2} mdHidden smHidden lgHidden>
              {quoteToShow && !quoteToShow.is_failure && priceFormatted}
            </Col>
            <Col smHidden xsHidden md={2}>
              {quoteToShow && !quoteToShow.is_failure ? (
                <div className="quoteItem__financials">
                  {!this.state.expandedFinancials.includes(quoteToShow.id) && (
                    <span
                      onClick={() => this.toggleFinancials(quoteToShow.id)}
                      className="quoteItem__financials-expand showAsLink"
                    >
                      <i className="icon icon-Down pad-right" />
                      Expand
                    </span>
                  )}
                  {this.state.expandedFinancials.includes(quoteToShow.id) &&
                    quoteToShow.charge_line_items &&
                    quoteToShow.charge_line_items.map((charge, i) => {
                      return (
                        <div key={i}>
                          <span className="quoteItem__financials-name">
                            {charge.is_provider_markup
                              ? charge.unit_name +
                                ' (' +
                                formatCurrencyValue(
                                  parseFloat((charge.unit_amount * 100) / (quoteToShow.total - charge.unit_amount))
                                ) +
                                '%):'
                              : charge.unit_name + ': '}
                          </span>
                          <span className="quoteItem__financials-amount">
                            {'$' + formatCurrencyValue(charge.unit_amount)}
                          </span>
                        </div>
                      );
                    })}
                  {this.state.expandedFinancials.includes(quoteToShow.id) &&
                  quoteToShow.customer_markup &&
                  quoteToShow.customer_markup !== 0 ? (
                    <div>
                      <span className="quoteItem__financials-name">
                        Markup
                        {' (' +
                          formatCurrencyValue(
                            parseFloat(
                              (quoteToShow.customer_markup * 100) / (quoteToShow.total - quoteToShow.customer_markup)
                            )
                          ) +
                          '%):'}
                      </span>
                      <span className="quoteItem__financials-amount">
                        {'$' + formatCurrencyValue(quoteToShow.customer_markup)}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.expandedFinancials.includes(quoteToShow.id) && quoteToShow.total && (
                    <div>
                      <span className="quoteItem__financials-name">
                        <b>Total</b>
                      </span>
                      <span className="quoteItem__financials-amount">
                        <b>{'$' + formatCurrencyValue(quoteToShow.total)}</b>
                      </span>
                    </div>
                  )}
                  {this.state.expandedFinancials.includes(quoteToShow.id) && (
                    <span
                      onClick={() => this.toggleFinancials(quoteToShow.id)}
                      className="quoteItem__financials-expand showAsLink "
                    >
                      <i className="icon icon-Up pad-right" />
                      Collapse
                    </span>
                  )}
                </div>
              ) : (
                <div className="quoteItem__financials">
                  <h5>Failed to quote</h5>
                </div>
              )}
            </Col>
            <Col style={{fontSize: '1em', textAlign: 'left'}} xsHidden sm={4} md={3}>
              {moment(quoteToShow.delivery_date).isValid()
                ? moment(quoteToShow.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
                : 'Not provided by carrier'}
              <br />
              {quoteToShow && quoteToShow.service_level && quoteToShow.service_level?.description}
              {numDays !== 'Not provided' && (
                <span>
                  <br />
                  {numDays} transit
                </span>
              )}
              {quoteToShow.lane_type !== 'UNSPECIFIED' && (
                <span>
                  <br />
                  {quoteToShow && quoteToShow.lane_type && _.upperFirst(quoteToShow.lane_type.toLowerCase())}
                </span>
              )}
              <br />
              {quoteToShow &&
                (!quoteToShow.is_failure &&
                quoteToShow.created_by_company &&
                quoteToShow.created_by_company.is_shipwell === true ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="bottom" id="tooltip">
                        <span>CloudTrucks rate</span>
                      </Tooltip>
                    }
                  >
                    <img
                      className="quoteItem__belongsToCloudTrucks"
                      src="/images/cloudtrucks_logo.png"
                      alt="CloudTrucks"
                    />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="bottom" id="tooltip">
                        <span>{this.props.rateBelongsTo} rate</span>
                      </Tooltip>
                    }
                  >
                    <span className="quoteItem__belongsTo">
                      {this.props.rateBelongsTo && this.props.rateBelongsTo.split('')[0].toUpperCase()}
                    </span>
                  </OverlayTrigger>
                ))}
              &nbsp;
              {(!quoteToShow.can_dispatch || quoteToShow.is_failure) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="bottom" id="tooltip">
                      <div>{quoteToShow.error_message}</div>
                    </Tooltip>
                  }
                >
                  <i className="icon icon-Delayed text-danger" />
                </OverlayTrigger>
              )}
              &nbsp;
              {quoteToShow && quoteToShow.mode && quoteToShow.mode.code === 'VLTL' && (
                <span>
                  {vLTLIcon}
                  &nbsp;
                </span>
              )}
              {specialRateIcon && (
                <span>
                  {specialRateIcon}
                  &nbsp;&nbsp;
                </span>
              )}
              {handshakeIcon && (
                <span>
                  {handshakeIcon}
                  &nbsp;
                </span>
              )}
            </Col>
            <Col style={{fontSize: '0.9em', textAlign: 'left'}} xs={4} mdHidden smHidden lgHidden>
              {moment(quoteToShow.delivery_date).isValid()
                ? moment(quoteToShow.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
                : 'Not provided by carrier'}
              <br />
              {quoteToShow && quoteToShow.service_level && quoteToShow.service_level?.description}
              {numDays !== 'Not provided' && (
                <span>
                  <br />
                  {numDays} transit
                </span>
              )}
              {quoteToShow.lane_type !== 'UNSPECIFIED' && (
                <span>
                  <br />
                  {quoteToShow && quoteToShow.lane_type && _.upperFirst(quoteToShow.lane_type.toLowerCase())}
                </span>
              )}
              {quoteToShow &&
                quoteToShow.mode &&
                (quoteToShow.mode.code === 'VLTL' || specialRateIcon || handshakeIcon) && <br />}
              {quoteToShow && quoteToShow.mode && quoteToShow.mode.code === 'VLTL' && (
                <span>
                  {vLTLIcon}
                  &nbsp;
                </span>
              )}
              {specialRateIcon && (
                <span>
                  {specialRateIcon}
                  &nbsp;&nbsp;
                </span>
              )}
              {handshakeIcon && (
                <span>
                  {handshakeIcon}
                  &nbsp;
                </span>
              )}
            </Col>
            <Col style={{fontSize: '1.2em'}} xs={2} md={2}>
              <Link className="showAsLink " onClick={() => this.setState({expanded: !this.state.expanded})}>
                +{quoteList.length}
                &nbsp;
                {this.state.expanded ? <i className="icon icon-Up" /> : <i className="icon icon-Down" />}
              </Link>
            </Col>
            <Col xs={3} md={1}>
              <Button
                disabled={this.props.disableButtons || quoteToShow.is_failure}
                style={{float: 'right'}}
                bsStyle="primary"
                onClick={() => this.selectQuoteFromList(quoteToShow)}
              >
                {buttonLabel ? buttonLabel : 'Select'}
              </Button>
            </Col>
          </Row>
          {this.state.expanded && quoteList.length > 0 && (
            <div>
              {quoteList.map(
                function (eachQuote, i) {
                  //get the right info for each quote in this aggregation
                  let numDays = eachQuote.transit_days;
                  if (isNaN(numDays) || numDays === null) {
                    numDays = 'Not provided';
                  } else {
                    if (numDays === 0) {
                      numDays = 'Not provided';
                    } else {
                      if (numDays === 1) {
                        numDays = numDays + ' day';
                      } else if (!parseInt(numDays, 10)) {
                        numDays = 'Not provided';
                      } else {
                        numDays = numDays + ' days';
                      }
                    }
                  }
                  const priceFormatted =
                    '$' +
                    parseFloat(Math.round(eachQuote.total * 100) / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    });

                  // figure out if we want to display a special icon for CSP or GlobalTranz
                  var specialRateIcon = null;
                  var handshakeIcon = null;
                  // we have a non-standard quote
                  if (eachQuote.provider_logo_url) {
                    specialRateIcon = <img src={eachQuote.provider_logo_url} height="14" alt="Echo" />;
                  }
                  if (eachQuote.is_csp_rate) {
                    //this is CSP
                    handshakeIcon = (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip placement="bottom" id="tooltip">
                            <div>Customer-specific rate</div>
                          </Tooltip>
                        }
                      >
                        <i className="icon icon-Handshake" />
                      </OverlayTrigger>
                    );
                  }

                  const vLTLIcon = (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip placement="bottom" id="tooltip">
                          <div>Volume LTL quote</div>
                        </Tooltip>
                      }
                    >
                      <i className="icon icon-Adjust" />
                    </OverlayTrigger>
                  );

                  if (eachQuote.charge_line_items && eachQuote.charge_line_items.length > 0) {
                    eachQuote.charge_line_items = eachQuote.charge_line_items.sort((a, b) => {
                      if (a.is_provider_markup) {
                        return 1;
                      }
                      if (b.is_provider_markup) {
                        return -1;
                      }
                      return a.unit_amount >= b.unit_amount ? -1 : 1;
                    });
                  }

                  return (
                    //display aggregated quotes in a row each
                    <Row
                      key={i}
                      className="quoteItem"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd'
                      }}
                    >
                      <Col xs={2} md={2}>
                        <img
                          style={{
                            height: '17px',
                            paddingLeft: '20px',
                            opacity: '0.75'
                          }}
                          alt="right_arrow"
                          src="/images/right_arrow.png"
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: '1.8em',
                          fontWeight: 'bold',
                          textAlign: 'left'
                        }}
                        xsHidden
                        md={2}
                      >
                        {eachQuote && !eachQuote.is_failure && priceFormatted}
                      </Col>
                      <Col
                        style={{
                          fontSize: '1.0em',
                          fontWeight: 'bold',
                          textAlign: 'left'
                        }}
                        xs={2}
                        smHidden
                        mdHidden
                        lgHidden
                      >
                        {eachQuote && !eachQuote.is_failure && priceFormatted}
                      </Col>
                      <Col smHidden xsHidden md={2}>
                        {eachQuote && !eachQuote.is_failure ? (
                          <div className="quoteItem__financials">
                            {!this.state.expandedFinancials.includes(eachQuote.id) && (
                              <span
                                onClick={() => this.toggleFinancials(eachQuote.id)}
                                className="quoteItem__financials-expand showAsLink "
                              >
                                <i className="icon icon-Down pad-right" />
                                Expand
                              </span>
                            )}
                            {this.state.expandedFinancials.includes(eachQuote.id) &&
                              eachQuote.charge_line_items &&
                              eachQuote.charge_line_items.map((charge, i) => {
                                return (
                                  <div key={i}>
                                    <span className="quoteItem__financials-name">
                                      {charge.is_provider_markup
                                        ? charge.unit_name +
                                          ' (' +
                                          formatCurrencyValue(
                                            parseFloat(
                                              (charge.unit_amount * 100) / (eachQuote.total - charge.unit_amount)
                                            )
                                          ) +
                                          '%):'
                                        : charge.unit_name + ': '}
                                    </span>
                                    <span className="quoteItem__financials-amount">
                                      {'$' + formatCurrencyValue(charge.unit_amount)}
                                    </span>
                                  </div>
                                );
                              })}
                            {this.state.expandedFinancials.includes(eachQuote.id) &&
                            eachQuote.customer_markup &&
                            eachQuote.customer_markup !== 0 ? (
                              <div>
                                <span className="quoteItem__financials-name">
                                  Markup
                                  {' (' +
                                    formatCurrencyValue(
                                      parseFloat(
                                        (eachQuote.customer_markup * 100) /
                                          (eachQuote.total - eachQuote.customer_markup)
                                      )
                                    ) +
                                    '%):'}
                                </span>
                                <span className="quoteItem__financials-amount">
                                  {'$' + formatCurrencyValue(eachQuote.customer_markup)}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            {this.state.expandedFinancials.includes(eachQuote.id) && eachQuote.total && (
                              <div>
                                <span className="quoteItem__financials-name">
                                  <b>Total</b>
                                </span>
                                <span className="quoteItem__financials-amount">
                                  <b>{'$' + formatCurrencyValue(eachQuote.total)}</b>
                                </span>
                              </div>
                            )}
                            {this.state.expandedFinancials.includes(eachQuote.id) && (
                              <span
                                onClick={() => this.toggleFinancials(eachQuote.id)}
                                className="quoteItem__financials-expand showAsLink  "
                              >
                                <i className="icon icon-Up pad-right" />
                                Collapse
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="quoteItem__financials">
                            <h5>Failed to quote</h5>
                          </div>
                        )}
                      </Col>
                      <Col style={{fontSize: '1em', textAlign: 'left'}} xsHidden md={3} sm={4}>
                        {moment(eachQuote.delivery_date).isValid()
                          ? moment(eachQuote.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
                          : 'Not provided by carrier'}
                        <br />
                        {eachQuote.service_level && eachQuote.service_level?.description}
                        {numDays !== 'Not provided' && (
                          <span>
                            <br />
                            {numDays} transit
                          </span>
                        )}
                        {eachQuote.lane_type !== 'UNSPECIFIED' && (
                          <span>
                            <br />
                            {_.upperFirst(eachQuote.lane_type.toLowerCase())}
                          </span>
                        )}
                        <br />
                        {!eachQuote.is_failure &&
                          (eachQuote.created_by_company.is_shipwell === true ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip placement="bottom" id="tooltip">
                                  <span>CloudTrucks rate</span>
                                </Tooltip>
                              }
                            >
                              <img
                                className="quoteItem__belongsToCloudTrucks"
                                src="/images/cloudtrucks_logo.png"
                                alt="CloudTrucks"
                              />
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip placement="bottom" id="tooltip">
                                  <span>
                                    {eachQuote.created_by_company.id === this.props.company.id
                                      ? this.props.company.name
                                      : eachQuote.created_by_company.name}{' '}
                                    rate
                                  </span>
                                </Tooltip>
                              }
                            >
                              <span className="quoteItem__belongsTo">
                                {eachQuote.created_by_company.id === this.props.company.id
                                  ? this.props.company.name.split('')[0].toUpperCase()
                                  : eachQuote.created_by_company.name.split('')[0].toUpperCase()}
                              </span>
                            </OverlayTrigger>
                          ))}
                        &nbsp;
                        {(!eachQuote.can_dispatch || eachQuote.is_failure) && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip placement="bottom" id="tooltip">
                                <div>{eachQuote.error_message}</div>
                              </Tooltip>
                            }
                          >
                            <i className="icon icon-Delayed text-danger" />
                          </OverlayTrigger>
                        )}
                        &nbsp;
                        {eachQuote?.mode?.code === 'VLTL' && (
                          <span>
                            {vLTLIcon}
                            &nbsp;
                          </span>
                        )}
                        {specialRateIcon && (
                          <span>
                            {specialRateIcon}
                            &nbsp;&nbsp;
                          </span>
                        )}
                        {handshakeIcon && (
                          <span>
                            {handshakeIcon}
                            &nbsp;
                          </span>
                        )}
                      </Col>
                      <Col style={{fontSize: '0.9em', textAlign: 'left'}} xs={4} smHidden mdHidden lgHidden>
                        {moment(eachQuote.delivery_date).isValid()
                          ? moment(eachQuote.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
                          : 'Not provided by carrier'}
                        <br />
                        {eachQuote.service_level && eachQuote.service_level?.description}
                        {numDays !== 'Not provided' && (
                          <span>
                            <br />
                            {numDays} transit
                          </span>
                        )}
                        {eachQuote.lane_type !== 'UNSPECIFIED' && (
                          <span>
                            <br />
                            {_.upperFirst(eachQuote.lane_type.toLowerCase())}
                          </span>
                        )}
                        {(eachQuote?.mode?.code === 'VLTL' || specialRateIcon || handshakeIcon) && <br />}
                        {eachQuote?.mode?.code === 'VLTL' && (
                          <span>
                            {vLTLIcon}
                            &nbsp;
                          </span>
                        )}
                        {specialRateIcon && (
                          <span>
                            {specialRateIcon}
                            &nbsp;&nbsp;
                          </span>
                        )}
                        {handshakeIcon && (
                          <span>
                            {handshakeIcon}
                            &nbsp;
                          </span>
                        )}
                      </Col>
                      <Col xs={2} md={2} />
                      <Col xs={3} md={1}>
                        <Button
                          disabled={this.props.disableButtons || eachQuote.is_failure}
                          style={{float: 'right'}}
                          bsStyle="primary"
                          onClick={() => this.selectQuoteFromList(eachQuote)}
                        >
                          {buttonLabel ? buttonLabel : 'Select'}
                        </Button>
                      </Col>
                    </Row>
                  );
                }.bind(this)
              )}
            </div>
          )}
        </div>
      );
    }
    quote = quote && quote.quotes && quote.quotes.length > 0 ? quote.quotes[0] : {};
    let numDays = quote.transit_days;
    if (isNaN(numDays) || numDays === null) {
      numDays = 'Not provided';
    } else {
      if (numDays === 0) {
        numDays = 'Not provided';
      } else {
        if (numDays === 1) {
          numDays = numDays + ' day';
        } else if (!parseInt(numDays, 10)) {
          numDays = 'Not provided';
        } else {
          numDays = numDays + ' days';
        }
      }
    }
    const priceFormatted =
      '$' +
      parseFloat(Math.round(quote.total * 100) / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

    // we have a non-standard quote
    if (quote.provider_logo_url) {
      specialRateIcon = <img src={quote.provider_logo_url} height="14" alt="Echo" />;
    }
    if (quote.is_csp_rate) {
      //this is CSP
      handshakeIcon = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <div>Customer-specific rate</div>
            </Tooltip>
          }
        >
          <i className="icon icon-Handshake" />
        </OverlayTrigger>
      );
    }

    const vLTLIcon = (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip placement="bottom" id="tooltip">
            <div>Volume LTL quote</div>
          </Tooltip>
        }
      >
        <i className="icon icon-Adjust" />
      </OverlayTrigger>
    );
    if (quote.charge_line_items && quote.charge_line_items.length > 0) {
      quote.charge_line_items = quote.charge_line_items.sort((a, b) => {
        if (a.is_provider_markup) {
          return 1;
        }
        if (b.is_provider_markup) {
          return -1;
        }
        return a.unit_amount >= b.unit_amount ? -1 : 1;
      });
    }
    return (
      <Row
        className="quoteItem"
        style={{
          borderBottom: '1px solid #ddd',
          borderTop: '1px solid #ddd',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Col md={2} xs={2}>
          {quote.carrier && quote.carrier.display_name}
          &nbsp;
        </Col>
        <Col style={{fontSize: '1.8em', fontWeight: 'bold', textAlign: 'left'}} xsHidden md={2}>
          {quote && !quote.is_failure && priceFormatted}
        </Col>
        <Col style={{fontSize: '1.0em', fontWeight: 'bold', textAlign: 'left'}} xs={2} smHidden mdHidden lgHidden>
          {quote && !quote.is_failure && priceFormatted}
        </Col>
        <Col smHidden xsHidden md={2}>
          {quote && !quote.is_failure ? (
            <div className="quoteItem__financials">
              {!this.state.expandedFinancials.includes(quote.id) && (
                <span
                  onClick={() => this.toggleFinancials(quote.id)}
                  className="quoteItem__financials-expand showAsLink "
                >
                  <i className="icon icon-Down pad-right" />
                  Expand
                </span>
              )}
              {this.state.expandedFinancials.includes(quote.id) &&
                quote.charge_line_items &&
                quote.charge_line_items.map((charge, i) => {
                  return (
                    <div key={i}>
                      <span className="quoteItem__financials-name">
                        {charge.is_provider_markup === true
                          ? charge.unit_name +
                            ' (' +
                            formatCurrencyValue(
                              parseFloat((charge.unit_amount * 100) / (quote.total - charge.unit_amount))
                            ) +
                            '%):'
                          : charge.unit_name + ': '}
                      </span>
                      <span className="quoteItem__financials-amount">
                        {'$' + formatCurrencyValue(charge.unit_amount)}
                      </span>
                    </div>
                  );
                })}
              {this.state.expandedFinancials.includes(quote.id) &&
              quote.customer_markup &&
              quote.customer_markup !== 0 ? (
                <div>
                  <span className="quoteItem__financials-name">
                    Markup
                    {' (' +
                      formatCurrencyValue(
                        parseFloat((quote.customer_markup * 100) / (quote.total - quote.customer_markup))
                      ) +
                      '%):'}
                  </span>
                  <span className="quoteItem__financials-amount">
                    {'$' + formatCurrencyValue(quote.customer_markup)}
                  </span>
                </div>
              ) : (
                ''
              )}
              {this.state.expandedFinancials.includes(quote.id) && quote.total && (
                <div>
                  <span className="quoteItem__financials-name">
                    <b>Total</b>
                  </span>
                  <span className="quoteItem__financials-amount">
                    <b>{'$' + formatCurrencyValue(quote.total)}</b>
                  </span>
                </div>
              )}
              {this.state.expandedFinancials.includes(quote.id) && (
                <span
                  onClick={() => this.toggleFinancials(quote.id)}
                  className="quoteItem__financials-expand showAsLink  "
                >
                  <i className="icon icon-Up pad-right" />
                  Collapse
                </span>
              )}
            </div>
          ) : (
            <div className="quoteItem__financials">
              <h5>Failed to quote</h5>
            </div>
          )}
        </Col>
        <Col style={{fontSize: '1em', textAlign: 'left'}} xsHidden sm={4} md={3}>
          {moment(quote.delivery_date).isValid()
            ? moment(quote.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
            : 'Not provided by carrier'}
          <br />
          {quote && quote.service_level && quote.service_level?.description}
          {numDays !== 'Not provided' && (
            <span>
              <br />
              {numDays} transit
            </span>
          )}
          {quote.lane_type !== 'UNSPECIFIED' && (
            <span>
              <br />
              {quote.lane_type && _.upperFirst(quote.lane_type.toLowerCase())}
            </span>
          )}
          <br />
          {quote &&
            !quote.is_failure &&
            (quote.created_by_company && quote.created_by_company.is_shipwell === true ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="bottom" id="tooltip">
                    <span>CloudTrucks rate</span>
                  </Tooltip>
                }
              >
                <img className="quoteItem__belongsToCloudTrucks" src="/images/cloudtrucks_logo.png" alt="CloudTrucks" />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="bottom" id="tooltip">
                    <span>{this.props.rateBelongsTo} rate</span>
                  </Tooltip>
                }
              >
                <span className="quoteItem__belongsTo">
                  {this.props.rateBelongsTo && this.props.rateBelongsTo.split('')[0].toUpperCase()}
                </span>
              </OverlayTrigger>
            ))}
          &nbsp;
          {(!quote.can_dispatch || quote.is_failure) && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <div>{quote.error_message}</div>
                </Tooltip>
              }
            >
              <i className="icon icon-Delayed text-danger" />
            </OverlayTrigger>
          )}
          &nbsp;
          {quote && quote.mode && quote.mode.code === 'VLTL' && (
            <span>
              {vLTLIcon}
              &nbsp;
            </span>
          )}
          {specialRateIcon && (
            <span>
              {specialRateIcon}
              &nbsp;&nbsp;
            </span>
          )}
          {handshakeIcon && (
            <span>
              {handshakeIcon}
              &nbsp;
            </span>
          )}
        </Col>
        <Col style={{fontSize: '0.9em', textAlign: 'left'}} xs={4} smHidden mdHidden lgHidden>
          {moment(quote.delivery_date).isValid()
            ? moment(quote.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
            : 'Not provided by carrier'}
          <br />
          {quote && quote.service_level && quote.service_level?.description}
          {numDays !== 'Not provided' && (
            <span>
              <br />
              {numDays} transit
            </span>
          )}
          {quote.lane_type !== 'UNSPECIFIED' && (
            <span>
              <br />
              {quote.lane_type && _.upperFirst(quote.lane_type.toLowerCase())}
            </span>
          )}
          {quote && quote.mode && (quote.mode.code === 'VLTL' || specialRateIcon || handshakeIcon) && <br />}
          {quote && quote.mode && quote.mode.code === 'VLTL' && (
            <span>
              {vLTLIcon}
              &nbsp;
            </span>
          )}
          {specialRateIcon && (
            <span>
              {specialRateIcon}
              &nbsp;&nbsp;
            </span>
          )}
          {handshakeIcon && (
            <span>
              {handshakeIcon}
              &nbsp;
            </span>
          )}
        </Col>
        <Col xs={2} md={2}>
          &nbsp;
        </Col>
        <Col xs={3} md={1}>
          <Button
            style={{float: 'right'}}
            bsStyle="primary"
            disabled={this.props.disableButtons || quote.is_failure}
            onClick={() => this.selectQuoteFromList(quote)}
          >
            {buttonLabel ? buttonLabel : 'Select'}
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    one: state.shipments.selectedShipment,
    company: state.auth.company
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RateQuote);
