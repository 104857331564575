import {RFQ} from '@shipwell/backend-core-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';

export const getShipmentParentRFQ = (shipment: Shipment) => {
  const rfqs = (shipment?.rfqs || []) as RFQ[];

  if (rfqs.length > 0) {
    return rfqs
      .filter((rfq) => !rfq.has_parent_rfq)
      .sort((a, b) => {
        if (!a.updated_at || !b.updated_at) return 0;

        return +b.updated_at - +a.updated_at;
      })[0];
  }
  return null;
};
