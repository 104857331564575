import {ReactNode} from 'react';
import {TableContainer} from '../baseComponents';
import Loader from 'App/common/shipwellLoader';

export const TableSavedTab = ({
  children,
  isLoading,
  emptyText
}: {
  children?: ReactNode;
  isLoading: boolean;
  emptyText?: string;
}) => {
  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center text-lg text-sw-disabled-text">
        <Loader loading />
      </div>
    );
  }
  return (
    <TableContainer>
      {children ? (
        <div>{children}</div>
      ) : (
        <div className="flex size-full items-center justify-center text-sw-disabled-text">
          <h4>{emptyText || 'No Saved Dashboards'}</h4>
        </div>
      )}
    </TableContainer>
  );
};
