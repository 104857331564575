import {Field, FieldArray, useFormikContext} from 'formik';
import {DeprecatedButton, FormikTextInput, FormikPhoneInput, SvgIcon, Card} from '@shipwell/shipwell-ui';
import {CarrierPointOfContact} from '@shipwell/backend-core-sdk';

const ServiceProviderContactsFields = ({
  name,
  setHasEmptyContactsForm
}: {
  name: string;
  setHasEmptyContactsForm: (validate: boolean) => void;
}) => {
  const {values}: {values: {contacts: CarrierPointOfContact[]}} = useFormikContext();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <Card
          title="Contacts"
          actions={
            <DeprecatedButton
              variant="tertiary"
              icon={<SvgIcon name="AddCircleOutlined" />}
              onClick={() =>
                arrayHelpers.push({
                  first_name: '',
                  last_name: '',
                  phone_number: '',
                  email: ''
                })
              }
            >
              Add Contact
            </DeprecatedButton>
          }
          draggableProvided={false}
        >
          <>
            {values.contacts.map((contact, index) => {
              const {first_name, last_name, phone_number, email} = contact;
              const isEmptyForm = !first_name && !last_name && !phone_number && !email;
              const required = values.contacts.length > 1 || !isEmptyForm;
              setHasEmptyContactsForm(values.contacts.length === 1 && isEmptyForm);
              return (
                <div
                  key={`poc-${index}`}
                  className={`mt-5 flex items-start border-b border-sw-border first:mt-0 last:border-none`}
                >
                  <div className="grid w-full auto-rows-[minmax(60px,auto)] grid-cols-[repeat(2,1fr)] gap-x-4">
                    <Field
                      required={required}
                      label="First Name"
                      name={`${name}.${index}.first_name`}
                      component={FormikTextInput}
                    />
                    <Field label="Last Name" name={`${name}.${index}.last_name`} component={FormikTextInput} />
                    <Field
                      required={required}
                      label="Phone"
                      name={`${name}.${index}.phone_number`}
                      component={FormikPhoneInput}
                    />
                    <Field
                      required={required}
                      label="Email"
                      name={`${name}.${index}.email`}
                      component={FormikTextInput}
                    />
                  </div>
                  {values.contacts.length > 1 ? (
                    <DeprecatedButton className="ml-2 p-0" variant="icon" onClick={() => arrayHelpers.remove(index)}>
                      <SvgIcon name="TrashOutlined" />
                    </DeprecatedButton>
                  ) : null}
                </div>
              );
            })}
          </>
        </Card>
      )}
    />
  );
};

ServiceProviderContactsFields.defaultProps = {
  name: 'contacts'
};

export default ServiceProviderContactsFields;
