import {
  SELECT_SPOT_NEGOTIATION_ARRAY,
  SELECT_SPOT_NEGOTIATION,
  FETCH_SPOT_NEGOTIATION_MESSAGES,
  FETCH_SPOT_NEGOTIATION_QUOTES,
  FETCH_CUSTOMER_NEGOTIATION,
  GET_SPOT_NEGOTIATION_EMAIL_PREVIEW,
  SET_COMMUNICATION_TAB,
  SET_QUOTES_TAB,
  GET_BIDDING_SHIPMENTS,
  MARKETPLACE_LOADING,
  GET_LOADBOARD_SHIPMENT
} from '../actions/types';

const INITIAL_STATE = {
  shipmentSpotNegotiations: [],
  selectedSpotNegotiation: null,
  spotNegotiationMessages: [],
  spotNegotiationQuotes: [],
  customerNegotiation: null,
  spotNegotiationEmailPreview: null,
  defaultCommunicationTab: null,
  defaultQuotesTab: null,
  loadBoardShipments: {results: []},
  isLoading: false,
  loadBoardShipmentDetails: {},
  shipmentCount: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SELECT_SPOT_NEGOTIATION_ARRAY:
      return {
        ...state,
        shipmentSpotNegotiations: action.payload
      };
    case SELECT_SPOT_NEGOTIATION:
      return {
        ...state,
        selectedSpotNegotiation: action.payload
      };
    case FETCH_SPOT_NEGOTIATION_MESSAGES:
      return {
        ...state,
        spotNegotiationMessages: action.payload
      };
    case FETCH_SPOT_NEGOTIATION_QUOTES:
      return {
        ...state,
        spotNegotiationQuotes: action.payload
      };
    case FETCH_CUSTOMER_NEGOTIATION:
      return {
        ...state,
        customerNegotiation: action.payload
      };
    case GET_SPOT_NEGOTIATION_EMAIL_PREVIEW:
      return {
        ...state,
        spotNegotiationEmailPreview: action.payload,
        isLoading: false
      };
    case SET_COMMUNICATION_TAB:
      return {
        ...state,
        defaultCommunicationTab: action.payload
      };
    case SET_QUOTES_TAB:
      return {
        ...state,
        defaultQuotesTab: action.payload
      };
    case GET_BIDDING_SHIPMENTS:
      if (action.meta.key < state.shipmentCount) {
        return {
          ...state,
          error: false,
          isLoading: false
        };
      }
      return {
        ...state,
        loadBoardShipments: action.payload,
        isLoading: false,
        shipmentCount: action.meta.key
      };

    case MARKETPLACE_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case GET_LOADBOARD_SHIPMENT:
      return {
        ...state,
        loadBoardShipmentDetails: action.payload
      };
    default:
      return state;
  }
}
