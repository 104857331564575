import {Popover, SvgIcon} from '@shipwell/shipwell-ui';
import {Dispatch, PropsWithChildren, SetStateAction} from 'react';
import {useGetShipmentSpotNegotiations} from 'App/data-hooks/spotNegotiations/useGetShipmentSpotNegotiations';
import {getLowestBid} from 'App/utils/globalsTyped';
import ShipwellLoader from 'App/common/shipwellLoader';

export const AuctionStatsPopover = ({
  shipmentId,
  children
}: PropsWithChildren<{
  shipmentId: string;
}>) => (
  <Popover
    showArrow
    portal
    theme="dark"
    placement="top"
    trigger={({
      setTriggerElement,
      setIsOpen
    }: {
      setIsOpen: Dispatch<SetStateAction<boolean>>;
      setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
    }) => (
      <div ref={setTriggerElement} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
        {children}
      </div>
    )}
    lockFocus={false}
  >
    <AuctionStatsDetails shipmentId={shipmentId} />
  </Popover>
);

const AuctionStatsDetails = ({shipmentId}: {shipmentId: string}) => {
  const {spotNegotiations: {results: shipmentSpotNegotiations} = {}, isLoadingSpotNegotiations} =
    useGetShipmentSpotNegotiations({
      shipmentId,
      refetchOnWindowFocus: true,
      omitOwnSpotNegotiations: true,
      orderByLowestQuote: true
    });

  return (
    <div className="w-max p-2">
      {isLoadingSpotNegotiations ? (
        <ShipwellLoader loading />
      ) : shipmentSpotNegotiations?.length ? (
        <table className="table-auto border-separate border-spacing-2">
          <tr>
            <th>Carrier</th>
            <th></th>
            <th>Bid</th>
          </tr>
          {shipmentSpotNegotiations?.map((shipmentSpotNegotiation) => {
            const lowestBid = getLowestBid({shipmentSpotNegotiation});
            return (
              <tr key={shipmentSpotNegotiation.id}>
                <td>{shipmentSpotNegotiation.vendor?.name}</td>
                <td className="grid grid-cols-2">
                  <SvgIcon name="Messages" />
                  <span className="self-center">{shipmentSpotNegotiation.message_count}</span>
                </td>
                <td>{lowestBid?.currency && lowestBid.total ? `${lowestBid.currency} ${lowestBid.total}` : '--'}</td>
              </tr>
            );
          })}
        </table>
      ) : (
        <span>No bids or messages</span>
      )}
    </div>
  );
};
