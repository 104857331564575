import {flexRender} from '@tanstack/react-table';
import {useEffect, useState} from 'react';
import {IframeContainer} from 'App/containers/userCompany/templates/IframeContainer';
import {
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
  TableContainer,
  TableHeader,
  TableHeaderSortIcon
} from 'App/components/TypedTable/baseComponents';
import {useTypedTable, useTableColumns, useTableSort} from 'App/components/TypedTable/hooks';
import {templateColumns} from 'App/containers/userCompany/templates/columns';
import PageHeader from 'App/common/pageHeader';
import {TableLoadingBar} from 'App/components/TypedTable/complementaryComponents';
import {useGetListTemplates} from 'App/data-hooks/templates/useGetTemplates';
import {useGetEditTemplateUrl} from 'App/data-hooks/templates/useGetEditTemplateUrl';

export const CompanyTemplates = () => {
  const [templateId, setTemplateId] = useState<string>('');
  const [templateName, setTemplateName] = useState<string>('');
  const [isIframeOpen, setIsIframeOpen] = useState<boolean>(false);
  const {columns} = useTableColumns(templateColumns(setTemplateId, setTemplateName), 'Templates');
  const {listTemplates, isLoadingListTemplates} = useGetListTemplates();
  const {templateEditorUrl, isLoadingTemplateEditorUrl} = useGetEditTemplateUrl({templateId});

  useEffect(() => {
    if (templateId !== '') {
      setIsIframeOpen(true);
    }
  }, [templateId]);

  const [sorting, setSorting] = useTableSort({
    defaultSort: {id: 'name', desc: false},
    shouldUseRouter: true
  });

  const table = useTypedTable({
    data: listTemplates?.data.data ?? [],
    columns,
    getRowId: (row) => row.id,
    state: {
      sorting
    },
    onSortingChange: setSorting
  });
  return (
    <TableContainer>
      <div className="sticky top-0 p-1">
        <PageHeader title="Templates"></PageHeader>
      </div>
      {isLoadingListTemplates ? (
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0">
            <TableLoadingBar />
          </div>
        </div>
      ) : null}
      <TableContainer>
        <Table
          head={table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  width={header.getSize()}
                  sortDirection={header.column.getIsSorted()}
                  onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                  onResize={header.getResizeHandler()}
                >
                  <div className="flex items-center gap-1">
                    {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </TableHeader>
              ))}
            </TableHeaderRow>
          ))}
          body={table.getRowModel().rows.map((row, rowIndex) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} rowIndex={rowIndex}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        />
      </TableContainer>
      {isIframeOpen ? (
        <IframeContainer
          url={templateEditorUrl?.data.url}
          name={templateName}
          setIsIframeOpen={setIsIframeOpen}
          setTemplateId={setTemplateId}
          isLoading={isLoadingTemplateEditorUrl}
        />
      ) : null}
    </TableContainer>
  );
};
