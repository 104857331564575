import {useEffect, useState} from 'react';
import {compose} from 'redux';
import {isEmpty, get} from 'lodash';
import {Card, DeprecatedButton, Modal} from '@shipwell/shipwell-ui';
import SaferwatchConfig from 'App/formComponents/forms/saferwatchConfig';
import WithStatusToasts from 'App/components/withStatusToasts';
import {
  getSaferwatchData,
  postSaferwatchRegistration,
  disconnectSaferwatch,
  refreshSaferwatchConnection
} from 'App/api/integrations';
import useInterval from 'App/utils/hooks/useInterval';
import {formatDateTime} from 'App/utils/globals';
import {integrationsConstants} from 'App/containers/integrations/constants';
import './styles.scss';

const SaferwatchDetails = (props) => {
  const {setSuccess, setError} = props;
  const [isConnected, setIsConnected] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [saferwatchData, setSaferwatchData] = useState({});
  const [isPolling, setIsPolling] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  useEffect(() => {
    getSaferwatchCredentials();
  }, []);

  const getSaferwatchCredentials = async () => {
    try {
      const response = await getSaferwatchData();
      if (response?.body?.results[0]) {
        setSaferwatchData(response.body.results[0]);
        // job is not running, stop polling
        if (!get(response, 'body.results[0].job_status.is_running', false)) {
          setIsPolling(false);
        }
      } else {
        //show the instruction modal whenever we aren't authed
        setShowInstructionModal(true);
      }
    } catch (error) {
      console.error(error);
      setError(error?.error_description);
    }
  };

  useEffect(() => {
    if (!isEmpty(saferwatchData)) {
      setIsConnected(true);
      // job is running, keep polling until is_running is false
      if (saferwatchData?.job_status.is_running) {
        setIsPolling(true);
      }
    } else {
      setIsConnected(false);
    }
  }, [saferwatchData]);

  const handleDisconnect = async () => {
    try {
      if (isConnected) {
        const response = await disconnectSaferwatch(saferwatchData.id);
        if (response?.ok) {
          setSuccess('Success!', 'You have successfully disconnected from the SaferWatch integration.');
        }
        setSaferwatchData({});
      }
      setShowDisconnectModal(false);
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
    }
  };

  const handleConnect = async (values, actions) => {
    try {
      const response = await postSaferwatchRegistration(values);
      if (response?.ok) {
        setSuccess('Success!', 'You have successfully connected to the Customer Key for SaferWatch.');
        setSaferwatchData(response?.body);
      }
    } catch (error) {
      actions.setErrors({
        customer_key: error?.error_description || 'Error, please try again.'
      });
      setError('Error!', error?.error_description);
    }
  };

  // polling to check if job is running
  useInterval(getSaferwatchCredentials, isPolling ? 5000 : null);

  const handleRefresh = async () => {
    // show loading icon regardless of whether we're polling or not
    try {
      const response = await refreshSaferwatchConnection();
      // refresh endpoint returned success, trigger polling
      if (response.ok) {
        setIsPolling(true);
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
      setSaferwatchData({});
    }
  };

  /**
   * renders title along with the last update date -
   * the latter is conditionally rendered given that there is a date
   */
  const renderCardTitle = () => {
    let lastUpdatedTime = null;
    if (saferwatchData?.job_status?.last_updated_at) {
      lastUpdatedTime = formatDateTime(saferwatchData.job_status.last_updated_at);
    }
    return (
      <div className="integration-details-card-title">
        <div className="flex items-center">
          <img
            alt="integration-logo"
            className="integration-logo"
            src={integrationsConstants['saferwatch'].logo_small}
          />
          <span>SaferWatch Configuration</span>
        </div>
        {lastUpdatedTime && (
          <div className="saferwatch-title-date">
            Last Updated: <span>{lastUpdatedTime}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="integration-details-content">
      <div className="disconnect">
        <DeprecatedButton variant="secondary" onClick={() => setShowDisconnectModal(true)} disabled={!isConnected}>
          <span>Disconnect</span>
        </DeprecatedButton>
      </div>
      <Card title={renderCardTitle()}>
        <div className="integration-details-card">
          <div className="integration-details-card-explainer">
            {integrationsConstants['saferwatch'].cardExplainerText}
          </div>
          <SaferwatchConfig
            saferwatchData={saferwatchData}
            handleSubmit={handleConnect}
            handleRefresh={handleRefresh}
            isConnected={isConnected}
            isBtnLoading={isPolling}
          />
        </div>
      </Card>
      <Modal
        onClose={() => setShowInstructionModal(false)}
        footerComponent={() => (
          <div className="saferwatch-details__instructions">
            <DeprecatedButton variant="primary" onClick={() => setShowInstructionModal(false)}>
              Ok
            </DeprecatedButton>
          </div>
        )}
        show={showInstructionModal}
        title="SaferWatch Integration"
      >
        <>
          <span>
            To synchronize data from your company's SaferWatch Watch List with Shipwell, please contact the SaferWatch
            Support team to pair your company's SaferWatch Customer Key with Shipwell, and then enter your SaferWatch
            Customer Key on the configuration page.
          </span>
        </>
      </Modal>
      <Modal
        show={Boolean(showDisconnectModal)}
        title="Disconnect"
        variant="warning"
        onClose={() => setShowDisconnectModal(false)}
        primaryBtnName="Continue"
        onPrimaryAction={handleDisconnect}
      >
        Disconnecting will disconnect the SaferWatch connection. Are you sure?
      </Modal>
    </div>
  );
};

export default compose(WithStatusToasts)(SaferwatchDetails);
