import {useContext} from 'react';
import {AxiosError} from 'axios';
import first from 'lodash/first';
import {ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import isNil from 'lodash/isNil';
import {useQueryClient} from '@tanstack/react-query';
import {Button, DisplayValue} from '@shipwell/shipwell-ui';
import {SupplierAppointmentContext} from 'App/containers/appointments/components/forms/SupplierAppointment';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {FacilityServiceIcon} from 'App/components/Icons';
import SupplierAvailabilityTimeslots from 'App/containers/appointments/components/forms/SupplierAppointment/SupplierAvailabilityTimeslots';
import {useCreateSupplierAppointment} from 'App/data-hooks/appointments/useCreateSupplierAppointment';
import {FACILITY_APPOINTMENTS_AND_ALL} from 'App/data-hooks/queryKeys';
import {AppointmentVerbContext} from 'App/containers/appointments/AppointmentVerbContext';

const SupplierAppointmentAvailability = () => {
  const queryClient = useQueryClient();

  const {handlePrevStep, onCancel, facility, supplierAppointmentFormData, availabilityDock, availabilityWindow} =
    useContext(SupplierAppointmentContext);
  const {setSuccess, setError} = useContext(AppointmentVerbContext);
  const canSubmitAppointmentData = Boolean(
    availabilityDock?.id && availabilityWindow?.startDate && availabilityWindow.endDate
  );

  const {createSupplierAppointment, isCreateSupplierAppointmentLoading} = useCreateSupplierAppointment();

  const handleSubmitSupplierAppointmentCreation = () => {
    if (isNil(availabilityWindow) || isNil(availabilityDock) || isNil(facility)) {
      setError('Error!', 'Appointment window, dock or facility cannot be null.');
      return;
    }
    createSupplierAppointment(
      {supplierAppointmentFormData, availabilityDock, availabilityWindow, facility},
      {
        onSuccess: () => {
          setSuccess('Success!', 'The appointment was successfully created!');
          onCancel();
        },
        onError: (error?: AxiosError<ShipwellApiErrorResponse>) => {
          const firstError = first(error?.response?.data?.errors);
          setError(firstError?.title || 'Error!', firstError?.detail || 'An unknown error occurred.');
        },
        onSettled: () => {
          void queryClient.invalidateQueries([FACILITY_APPOINTMENTS_AND_ALL, facility.id]);
        }
      }
    );
  };

  return (
    <div className="mb-10 flex">
      <div className="flex w-1/3 flex-col">
        <div className=" flex flex-col gap-y-2">
          <div className="text-xl font-bold">Freight Appointment</div>
          <DisplayValue label="Facility Location">{facility?.address?.formatted_address || '--'}</DisplayValue>
          <DisplayValue label="Delivery Type">{supplierAppointmentFormData.delivery_type}</DisplayValue>
          <DisplayValue label="Carrier Instruction">{facility?.carrier_instructions || '--'}</DisplayValue>
          <DisplayValue label="Disclaimers">{facility?.disclaimers || '--'}</DisplayValue>
          <DisplayValue label="Packaging Type">{supplierAppointmentFormData.packaging_type || '--'}</DisplayValue>
          <DisplayValue label="Notes">{facility?.notes || '--'}</DisplayValue>
          <div>
            <div className="uppercase"> FACILITY AMENITIES</div>
            <div className="flex">
              {facility?.facility_services?.length
                ? facility?.facility_services?.map((service) => (
                    <FacilityServiceIcon key={service} service={service} color="sw-icon" />
                  ))
                : '--'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <SupplierAvailabilityTimeslots />
      </div>
      <ModalFormFooter
        isValid={canSubmitAppointmentData}
        isSubmitting={isCreateSupplierAppointmentLoading}
        onCancel={onCancel}
        onPrimaryActionClick={handleSubmitSupplierAppointmentCreation}
        primaryActionName="Submit"
        options={
          <Button variant="tertiary" iconName="ArrowBack" onClick={handlePrevStep}>
            Back
          </Button>
        }
      />
    </div>
  );
};
export default SupplierAppointmentAvailability;
