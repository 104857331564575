import {StandardChargeCode} from '@shipwell/backend-core-singlerequestparam-sdk';

type ChargeCodesGroupedInCategoriesType = {
  [key: string]: (StandardChargeCode | {label: string; value: string})[];
};

type OptionsType = {
  label: string;
  options: OptionsSubCategoryType[] | undefined;
}[];

export type OptionsSubCategoryType = {
  category: string;
  chargeCode: string;
  categoryDisplay: string;
  id: string;
  label: string;
  value: string;
};

export type OptionMetadataType = {
  label: string;
  value: string;
  category: string;
  chargeCode: string;
  id: string;
  categoryDisplay: string;
};

export const groupChargeCodesInCategories = (chargeCodes?: StandardChargeCode[]) =>
  chargeCodes?.reduce<ChargeCodesGroupedInCategoriesType>(
    (acc, chargeCode) => ({...acc, [chargeCode.category]: [...(acc[chargeCode.category] ?? []), chargeCode]}),
    {}
  );

export const groupChargeCodesInlineGroupSelect = (chargeCodes?: StandardChargeCode[]) => {
  const categoryArray = [...new Set(chargeCodes?.map((chargeCode) => chargeCode.category_display))];
  return categoryArray.map((category) => ({
    label: category,
    options: chargeCodes
      ?.filter((chargeCode) => chargeCode.category_display === category)
      .map((option) => ({
        label: `${option.code} - ${option.name}`,
        value: option.id,
        category: option.category,
        chargeCode: option.code,
        id: option.id,
        categoryDisplay: option.category_display
      }))
  }));
};

export const chargeCodesWithNoGroup = (chargeCodes?: StandardChargeCode[]) =>
  chargeCodes?.map((chargeCode) => ({
    label: `${chargeCode.code} - ${chargeCode.name} (${chargeCode.category_display})`,
    value: chargeCode.id,
    category: chargeCode.category,
    chargeCode: chargeCode.code,
    id: chargeCode.id,
    categoryDisplay: chargeCode.category_display
  }));

export const categoryDropdownValue = (options: OptionsType, chargeCode: string, chargeName: string) => {
  const findingValueArray = options
    .map((option) => option.options?.find((opt) => opt.chargeCode === chargeCode))
    .find((value) => value?.label);
  return findingValueArray
    ? findingValueArray
    : // This is a fallback while customers migrate to the new charge codes and I am trying to avoid doing the second map.find.find if we have a chargeCode
      options
        .map((option) => option.options?.find((opt) => opt.label.toLowerCase().includes(chargeName?.toLowerCase())))
        .find((value) => value?.label);
};
