import get from 'lodash/get';

export function formatCarrierRelationships(carrierRelationships) {
  return (carrierRelationships || []).map((carrierRelationship) => carrierRelationship.id);
}

export function makeCarrierOption(carrierDetail) {
  return {
    id: carrierDetail.id,
    label: get(carrierDetail, 'shipwell_vendor.name', '')
  };
}

export function makeFuelSurchargeRateTableRows(fuelSurchargeTable) {
  return get(fuelSurchargeTable, 'calculation_rows', []).map((row, index) => ({
    doe_from: row.fuel_price_lower_bound.toFixed(3),
    doe_to: fuelSurchargeTable.calculation_rows[index + 1]?.fuel_price_lower_bound
      ? (fuelSurchargeTable.calculation_rows[index + 1]?.fuel_price_lower_bound - 0.001).toFixed(3)
      : 'and above',
    surcharge: row.fuel_surcharge.toFixed(3)
  }));
}
