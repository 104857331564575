import {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import {connect} from 'react-redux';
import {Field, Form} from 'formik';
import {
  FormikTextInput,
  FormikSelect,
  FormikDateTimePicker,
  FormikPhoneInput,
  FormikTextarea
} from '@shipwell/shipwell-ui';
import {US_DOLLARS} from 'App/utils/internationalConstants';
import {carriers} from 'App/api';
import AddressSearchField from 'App/formComponents/fields/_addressSearch';

/**
 * Carrier Bid Fields
 * @param {*} props
 */
const CarrierBidFields = (props) => {
  const {values, equipmentTypes, currency, setValues, readOnly} = props;

  const [isLoading, setIsLoading] = useState(false);

  const searchForCarriers = async (input) => {
    setIsLoading(true);
    try {
      const response = await carriers.searchForCarrierPromise({pageSize: 10, q: input});
      if (response.body.results.length) {
        return response.body.results;
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const formatCarrierSearchOptions = (carrier, {context}) => {
    let codesString = '';
    if (carrier.usdot) {
      codesString += `USDOT: ${carrier.usdot}`;
      if (carrier.mc_number) {
        codesString += ` / MC: ${carrier.mc_number}`;
      }
    } else if (carrier.mc_number) {
      codesString += `MC: ${carrier.mc_number}`;
    }
    if (context === 'value') {
      return codesString;
    }
    return (
      <div className="carrierBid__form-carrierSearchOption">
        <span>{codesString}</span>
        <span>{carrier.name}</span>
      </div>
    );
  };

  const handleCarrierSelection = async (carrier) => {
    if (!carrier) {
      setValues({...values, carrier: null, carrier_relationship: null});
    } else {
      const valuesToSave = {carrier};
      //attempt to look up this carrier by usdot to find existing relationship
      const carrierLookup = await getCarrierByUSDOT(carrier.usdot);
      if (carrierLookup) {
        if (carrierLookup.existing_vendor_relationship) {
          //look up the relationship to get the POCs
          const relationshipDetails = await getCarrierRelationshipDetails(carrierLookup.existing_vendor_relationship);
          if (relationshipDetails && relationshipDetails.point_of_contacts.length > 0) {
            valuesToSave.contact_first_name = relationshipDetails.point_of_contacts[0].first_name;
            valuesToSave.contact_last_name = relationshipDetails.point_of_contacts[0].last_name;
            valuesToSave.contact_email = relationshipDetails.point_of_contacts[0].email;
            valuesToSave.contact_phone_number = relationshipDetails.point_of_contacts[0].phone_number;
            valuesToSave.carrier_relationship = relationshipDetails.id;
          }
        }
      }
      setValues({...values, ...valuesToSave});
    }
  };

  /**
   * Request carrier details
   * @param {Number} carrierId
   */
  const getCarrierByUSDOT = async (usdot) => {
    try {
      const response = await carriers.getCarrierPromise({usdot});

      if (response && response.body) {
        return response.body;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCarrierRelationshipDetails = async (carrierRelId) => {
    try {
      const response = await carriers.getCarrierRelationshipsCarrierRelationshipIdPromise(carrierRelId);
      if (response.body) {
        return response.body;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <>
      <p>
        <b>Carrier Search</b>
      </p>
      <div className="field-grid">
        <div className="grid-item-1-2">
          <Field
            required
            label="Carrier USDOT or MC#"
            name="carrier"
            component={FormikSelect}
            async
            loadOptions={searchForCarriers}
            isLoading={isLoading}
            formatOptionLabel={formatCarrierSearchOptions}
            onChange={handleCarrierSelection}
            disabled={readOnly}
          />
        </div>

        {values.carrier && (
          <div className="grid-item-1-2 carrierBid__form-carrierDetails">
            <span className="carrierBid__form-carrierDetails-label">{`Carrier Name ${
              values.carrier.physical_street ? `and Address` : ``
            }`}</span>
            <span>
              {`${values.carrier.name}${
                values.carrier.physical_street
                  ? `; ${values.carrier.physical_street}, ${values.carrier.physical_city} ${values.carrier.physical_state} ${values.carrier.physical_postal_code}, ${values.carrier.physical_country}`
                  : ``
              }`}
            </span>
          </div>
        )}

        <div className="grid-item-1-1">
          <Field
            required
            label="Contact First Name"
            name="contact_first_name"
            component={FormikTextInput}
            disabled={readOnly}
          />
        </div>
        <div className="grid-item-2-2">
          <Field label="Contact Last Name" name="contact_last_name" component={FormikTextInput} disabled={readOnly} />
        </div>

        <div className="grid-item-1-1">
          <Field label="Email" name="contact_email" component={FormikTextInput} disabled={readOnly} />
        </div>
        <div className="grid-item-2-2">
          <Field required label="Phone" name="contact_phone_number" component={FormikPhoneInput} disabled={readOnly} />
        </div>
      </div>

      <hr className="carrierBid__form-divider" />
      <p>
        <b>Bid Details</b>
      </p>
      <div className="field-grid">
        <div className="grid-item-1-2">
          <Field
            label="Available Location"
            name="current_location"
            searchAddressBook={false}
            component={AddressSearchField}
            disabled={readOnly}
            required
            ignoreFieldWarnings={['postal_code']}
          />
        </div>
        <div className="grid-item-1-1">
          <Field
            required
            label={`Bid Amount (All-in  ${currency === US_DOLLARS ? '$' : 'CAD'} Rate)`}
            name="bid_amount"
            component={FormikTextInput}
            disabled={readOnly}
          />
        </div>

        <div className="grid-item-2-2">
          <Field
            simpleValue
            clearable={false}
            options={equipmentTypes}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            label="Equipment"
            name="equipment_type"
            component={FormikSelect}
            disabled={readOnly}
          />
        </div>
        <div className="grid-item-1-1">
          <Field
            label="Available Date"
            name="available_date"
            showTimeSelect={false}
            component={FormikDateTimePicker}
            popperPlacement="top-start"
            disabled={readOnly}
          />
        </div>
        <div className="grid-item-2-2">
          <Field
            label={`Available Time ${
              values.current_location && values.current_location.timezone
                ? `(${moment.tz(values.current_location.timezone).zoneAbbr()})`
                : ''
            }`}
            name="available_time"
            showTimeSelectOnly
            timezone={values.current_location && values.current_location.timezone}
            component={FormikDateTimePicker}
            popperPlacement="top-start"
            disabled={readOnly}
            ignoreTimezone
          />
        </div>
        <div className="grid-item-1-2">
          <Field label="Notes" name="notes" component={FormikTextarea} disabled={readOnly} />
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  company: state.userCompany.company
}))(CarrierBidFields);
