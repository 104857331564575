import {LoadboardType} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {refreshLoadpost} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

type RefreshLoadpostPayload = {
  loadboardType: LoadboardType;
  loadpostId: string;
};

const useRefreshLoadpost = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof refreshLoadpost>>, unknown, RefreshLoadpostPayload>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof refreshLoadpost>>, unknown, RefreshLoadpostPayload>(
    ({loadboardType, loadpostId}) => refreshLoadpost(loadboardType, loadpostId),
    {
      onSettled: () => queryClient.invalidateQueries([LOADPOST_KEY]),
      ...mutationOptions
    }
  );
};

export default useRefreshLoadpost;
