import {useState, useEffect, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router';
import classNames from 'classnames';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import PageHeader from 'App/common/pageHeader';
import SideNav from 'App/common/sideNav';
import {
  ConditionalIntegrationsLink,
  ConditionalFuelSurchargesLink,
  ConditionalAccessorialRatesLink,
  CarrierComplianceRoute,
  ConditionalCompassAlertsLink,
  ConditionalCustomerSettingsLink,
  ConditionalLoadOptimizationLink,
  ConditionalRfpLink,
  ConditionalRfpLinkAlpha,
  ConditionalSettlementsConfigsLink,
  ConditionalInternationalConfigsLink
} from 'App/containers/userCompany/routes';
import './styles.scss';
import {State} from 'App/reducers/types';

/**
 * Company paths
 * @todo Worth creating a manifest of route paths?
 */
const companyProfilePath = '/company/profile';
const companyTemplates = '/company/templates';
const companyUsersPath = '/company/users';
const companyTermsPath = '/company/carrier-instructions';
const companyTagsPath = '/company/tags';
const companyCustomDataPath = '/company/custom-data';
export const companyContractDefaultsPath = '/company/defaults/contracts';
export const companyRoutingGuideDefaultsPath = '/company/defaults/routing-guides';
const parcelLabelsPath = '/company/parcel-labels';
const carrierCompliancePath = '/company/defaults/carrier-compliance';
const compassAlertsPath = '/company/defaults/compass-alerts';
const customerSettingsPath = '/company/defaults/customer-settings';
const loadOptimizationsPath = '/company/defaults/load-optimization-configurations';

const rfpsPath = '/company/lane-management/rfps';
const rfpsAlphaPath = '/company/lane-management/rfps_import';
const contractsPath = '/company/lane-management/contracts';
const slasPath = '/company/lane-management/service-level-agreements';
const accessorialsPath = '/company/lane-management/accessorial-rates';
const fuelSurchargePath = '/company/lane-management/fuel-surcharges';
const internationalConfigsPath = '/company/international-configurations';
const settlementsConfigsPath = '/company/settlements-configurations';

/**
 * Company Container
 * @param {*} props
 */
const CompanyContainer = ({
  children,
  location,
  hasContractsCompanyFlagEnabled,
  hasContractsViewPermission,
  hasContractsCreatePermission,
  hasContractsUpdatePermission,
  hasContractsDeletePermission,
  hasUsersViewPermission,
  hasUsersCreatePermission,
  hasUsersDeletePermission,
  hasUsersUpdatePermission,
  hasCompanyAdministratorPermission,
  hasCompanyDetailsViewPermission,
  hasCompanyDetailsUpdatePermission
}: {
  children: ReactNode;
  location: RouteComponentProps<unknown, unknown>['location'];
  hasContractsCompanyFlagEnabled?: boolean;
  hasContractsViewPermission?: boolean;
  hasContractsCreatePermission?: boolean;
  hasContractsUpdatePermission?: boolean;
  hasContractsDeletePermission?: boolean;
  hasUsersViewPermission?: boolean;
  hasUsersCreatePermission?: boolean;
  hasUsersUpdatePermission?: boolean;
  hasUsersDeletePermission?: boolean;
  hasCompanyAdministratorPermission?: boolean;
  hasCompanyDetailsViewPermission?: boolean;
  hasCompanyDetailsUpdatePermission?: boolean;
}) => {
  const {devTagManagement, pdfGeneratorApiForDocumentGeneration} = useFlags();

  const [defaultsExpanded, setDefaultsExpanded] = useState(false);
  const [laneManagementExpanded, setLaneManagementExpanded] = useState(false);

  useEffect(() => {
    setDefaultsExpanded(
      [
        companyContractDefaultsPath,
        companyRoutingGuideDefaultsPath,
        carrierCompliancePath,
        compassAlertsPath,
        customerSettingsPath,
        loadOptimizationsPath,
        parcelLabelsPath,
        internationalConfigsPath,
        settlementsConfigsPath
      ].includes(location.pathname)
    );

    setLaneManagementExpanded(
      [rfpsPath, rfpsAlphaPath, contractsPath, slasPath, accessorialsPath, fuelSurchargePath].includes(
        location.pathname
      )
    );
  }, [location.pathname]);
  const hasManageCompanyPermission =
    hasCompanyDetailsViewPermission || hasCompanyAdministratorPermission || hasCompanyDetailsUpdatePermission;
  const hasManageUsersPermission =
    hasUsersViewPermission || hasUsersCreatePermission || hasUsersDeletePermission || hasUsersUpdatePermission;
  const hasManageContractsPermission =
    hasContractsCompanyFlagEnabled &&
    (hasContractsViewPermission ||
      hasContractsCreatePermission ||
      hasContractsDeletePermission ||
      hasContractsUpdatePermission);

  return (
    <div className="company-wrapper">
      <PageHeader title="Company" />
      <div className="company-container">
        <SideNav>
          {hasManageCompanyPermission ? (
            <Link to={companyProfilePath} activeClassName="active">
              Profile
            </Link>
          ) : null}
          {pdfGeneratorApiForDocumentGeneration ? (
            <Link to={companyTemplates} activeClassName="active">
              Templates
            </Link>
          ) : null}
          <Link to={companyCustomDataPath} activeClassName="active">
            Custom Data
          </Link>
          {hasManageUsersPermission ? (
            <Link to={companyUsersPath} activeClassName="active">
              Users
            </Link>
          ) : null}

          <Link to={companyTermsPath} activeClassName="active">
            Carrier Instructions
          </Link>

          <ConditionalIntegrationsLink />

          {devTagManagement && (
            <Link to={companyTagsPath} activeClassName="active">
              Tag Management
            </Link>
          )}

          <div
            className={classNames('linkToggle', {
              active: laneManagementExpanded
            })}
            onClick={() => setLaneManagementExpanded(!laneManagementExpanded)}
          >
            <span>Lane Management</span>
            <SvgIcon name={laneManagementExpanded ? 'ExpandLess' : 'ExpandMore'} />
          </div>

          {laneManagementExpanded && (
            <>
              <ConditionalRfpLink />
              <ConditionalRfpLinkAlpha />
              {hasManageContractsPermission ? (
                <Link className="subLink" to={contractsPath} activeClassName="active">
                  Contracts
                </Link>
              ) : null}
              {hasManageContractsPermission ? (
                <Link className="pl-8 text-xs" to={slasPath} activeClassName="active">
                  SLAs
                </Link>
              ) : null}
              <ConditionalAccessorialRatesLink />
              <ConditionalFuelSurchargesLink />
            </>
          )}

          <div
            className={classNames('linkToggle', {
              active: defaultsExpanded
            })}
            onClick={() => setDefaultsExpanded(!defaultsExpanded)}
          >
            <span>Company Defaults</span>
            <SvgIcon name={defaultsExpanded ? 'ExpandLess' : 'ExpandMore'} />
          </div>

          {defaultsExpanded ? (
            <>
              <CarrierComplianceRoute />
              <ConditionalCompassAlertsLink />
              <Link to={companyContractDefaultsPath} className="subLink" activeClassName="active">
                Contracts
              </Link>
              <ConditionalCustomerSettingsLink />
              <ConditionalInternationalConfigsLink />
              <ConditionalLoadOptimizationLink />
              <Link to={parcelLabelsPath} className="subLink" activeClassName="active">
                Parcel Labels
              </Link>
              <Link to={companyRoutingGuideDefaultsPath} className="subLink" activeClassName="active">
                Routing Guides
              </Link>
              <ConditionalSettlementsConfigsLink />
            </>
          ) : null}
        </SideNav>
        {children}
      </div>
    </div>
  );
};

export default connect((state: State) => ({
  hasContractsCompanyFlagEnabled: state.company.company.feature_flags?.contracts_enabled,
  hasContractsViewPermission: state.userProfile.user.permissions?.includes('contracts.view_contracts'),
  hasContractsCreatePermission: state.userProfile.user.permissions?.includes('contracts.create_contracts'),
  hasContractsDeletePermission: state.userProfile.user.permissions?.includes('contracts.delete_contracts'),
  hasContractsUpdatePermission: state.userProfile.user.permissions?.includes('contracts.update_contracts'),
  hasUsersViewPermission: state.userProfile.user.permissions?.includes('contracts.view_company_users'),
  hasUsersCreatePermission: state.userProfile.user.permissions?.includes('users.create_company_users'),
  hasUsersDeletePermission: state.userProfile.user.permissions?.includes('users.delete_company_users'),
  hasUsersUpdatePermission: state.userProfile.user.permissions?.includes('users.update_company_users'),
  hasCompanyAdministratorPermission: state.userProfile.user.permissions?.includes('company.administrator'),
  hasCompanyDetailsViewPermission: state.userProfile.user.permissions?.includes('company.view_company_details'),
  hasCompanyDetailsUpdatePermission: state.userProfile.user.permissions?.includes('company.update_company_details')
}))(CompanyContainer);
