import {SvgIcon} from '@shipwell/shipwell-ui';
import {useGetCompanyPreferences} from './useGetCompanyPreferences';
import {formatMileage} from 'App/utils/globals';
import {IMPERIAL, METRIC, convertMilesToKilometers} from 'App/utils/internationalConstants';

export const DistanceCell = ({miles}: {miles: number}) => {
  const {isFetching, unitPreferences} = useGetCompanyPreferences();
  const system = unitPreferences?.system;
  let distance = '--';
  const unit = system === METRIC ? 'km' : 'mi';
  if (miles && !isNaN(miles)) {
    if (system === METRIC) {
      distance = convertMilesToKilometers(miles) as string;
    } else if (system === IMPERIAL) {
      distance = formatMileage(miles) as string;
    }
  }
  if (isFetching) {
    return <SvgIcon name="LoadingDots" />;
  }
  return (
    <>
      {distance} {unit}
    </>
  );
};
