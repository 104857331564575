import invariant from 'tiny-invariant';
import {Formik, Field, Form} from 'formik';
import {object, string} from 'yup';
import {DeprecatedButton, DisplayValue, FormikTextInput, FormikSelect, Modal, Title} from '@shipwell/shipwell-ui';
import {AmountToleranceBoundaryType, AmountToleranceLimitType} from '@shipwell/settlements-sdk';
import {InvoiceRuleEditModal} from './InvoiceRuleRow';
import useInvoiceRuleConfiguration from 'App/api/settlements/useInvoiceRule';
import {isAmountToleranceLimitDetails, isMoneyRequest} from 'App/api/settlements/typeGuards';

type OptionType = {
  value: AmountToleranceLimitType;
  label: string;
};

const Titles = {
  [AmountToleranceBoundaryType.Ceiling]: 'Edit Upper Threshold',
  [AmountToleranceBoundaryType.Floor]: 'Edit Lower Threshold'
};

const SubTitles = {
  [AmountToleranceBoundaryType.Ceiling]: 'Financials Upper Threshold',
  [AmountToleranceBoundaryType.Floor]: 'Financials Lower Threshold'
};

const Descriptions = {
  [AmountToleranceBoundaryType.Ceiling]: `If the invoice total minus the shipment total
    is greater than the upper threshold this will trigger an exception.`,
  [AmountToleranceBoundaryType.Floor]: `If the shipment total minus the invoiced total
    is greater than the lower threshold it will trigger an exception`
};

const InputLabels = {
  [AmountToleranceBoundaryType.Ceiling]: 'Upper Threshold Amount',
  [AmountToleranceBoundaryType.Floor]: 'Lower Threshold Amount'
};

const LimitTypeOptions: OptionType[] = [
  {value: AmountToleranceLimitType.MonetaryAmount, label: '$'},
  {value: AmountToleranceLimitType.Percentage, label: '%'}
];

const EditThresholdModal: InvoiceRuleEditModal = ({showModal, onClose, invoiceRule}) => {
  const ruleDetails = invoiceRule.details;
  invariant(isAmountToleranceLimitDetails(ruleDetails), 'FinancialsThreshold rule was expected');
  const {updateAmountToleranceLimitInvoiceRuleConfigurationMutation} = useInvoiceRuleConfiguration();

  const initialValues = {
    limit_type: LimitTypeOptions.find((option) => option.value === ruleDetails.limit_type) || LimitTypeOptions[0],
    limit_value: isMoneyRequest(ruleDetails.limit_value) ? ruleDetails.limit_value.value : ruleDetails.limit_value
  };

  const validationSchema = object().shape({
    limit_value: string().required(`${SubTitles[ruleDetails.boundary_type]} is required.`)
  });

  const onSubmit = (values: {limit_type: OptionType; limit_value: string}) => {
    updateAmountToleranceLimitInvoiceRuleConfigurationMutation.mutate({
      ruleId: invoiceRule.id,
      //@ts-expect-error Backend docs specify we can send any of the posible objects but the SDK only created 1 object with a bunch of required params that we send in diferent config invoice calls.
      details: {
        limit_type: values.limit_type.value,
        limit_value: values.limit_value
      }
    });
    onClose();
  };

  return (
    <Modal show={showModal} onClose={onClose} title={Titles[ruleDetails.boundary_type]} footerComponent={null} portal>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form noValidate>
          <div className="pb-8">{Descriptions[ruleDetails.boundary_type]}</div>
          <Title className="pb-2">Exception Details</Title>
          <DisplayValue className="m-0 pt-6" label="Exception Type" />
          <div className="pb-8">{SubTitles[ruleDetails.boundary_type]}</div>
          <div className="flex">
            <Field
              className="min-w-0 text-lg"
              controlClassName="rounded-r-none cursor-pointer border-r-0"
              menuClassName="min-w-min"
              name="limit_type"
              clearable={false}
              component={FormikSelect}
              options={LimitTypeOptions}
              hideSelectedOptions
              hideDropdownIndicator
            />
            <Field
              className="w-80"
              textInputClassname="rounded-l-none"
              name="limit_value"
              component={FormikTextInput}
              label={InputLabels[ruleDetails.boundary_type]}
              required
              type="number"
            />
          </div>
          <div className="flex justify-end pt-4">
            <DeprecatedButton variant="secondary" onClick={onClose} className="mr-4">
              Cancel
            </DeprecatedButton>
            <DeprecatedButton variant="primary" type="submit">
              Save
            </DeprecatedButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default EditThresholdModal;
