import {useQuery} from '@tanstack/react-query';
import {getCapacityProviders} from 'App/api/integrations/typed';
import {CAPACITY_PROVIDERS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ComponentType} from 'react';
import {wrapDisplayName} from 'recompose';

const useCapacityProviders = ({getActiveParcelOnly = false}: {getActiveParcelOnly?: boolean} = {}) => {
  const capacityProvidersQuery = useQuery([CAPACITY_PROVIDERS_QUERY_KEY], async () => {
    const response = await getCapacityProviders();
    return response.filter((currentCarrier) => !currentCarrier?.modes?.includes('LTL'));
  });

  // seems like Genesis does not have an endpoint to get capacity by shipment mode.
  const parcelCapacityProviders = capacityProvidersQuery.data?.filter((currentCarrier) => {
    return getActiveParcelOnly
      ? currentCarrier?.modes?.includes('PARCEL') && currentCarrier?.accounts?.length
      : currentCarrier?.modes?.includes('PARCEL');
  });

  return {capacityProvidersQuery, parcelCapacityProviders};
};

export default useCapacityProviders;

// HOC for use with class components
export const withCapcityProviders = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithCapacityProvidersQuery = (props: PropsT) => {
    const {parcelCapacityProviders} = useCapacityProviders({getActiveParcelOnly: true});

    return <Component {...props} parcelCapacityProviders={parcelCapacityProviders} />;
  };

  ComponentWithCapacityProvidersQuery.displayName = wrapDisplayName(Component, 'withCapacityProviders');
  return ComponentWithCapacityProvidersQuery;
};
