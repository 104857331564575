import classNames from 'classnames';
import {DeprecatedButton, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useTransportationOrder} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import {isContainerOrderItem} from 'App/containers/shipments/utils/typed';

const LINE_ITEM_HEADERS = ['LINE ITEM', 'DESCRIPTION', 'QTY', 'PREPAID', 'CARRIER RATE', ''];

const LINE_ITEM_INDICES = {LINE_ITEM: 0, DESCRIPTION: 1, QUANTITY: 2, PREPAID: 3, CARRIER_RATE: 4, ACTIONS: 5};

const TotalsSection = ({
  shipmentId,
  transportationOrderId,
  orderItemId
}: {
  shipmentId: string;
  transportationOrderId: string;
  orderItemId: string;
}) => {
  const {getServiceProviderFinancialDisplayValuesFromOrderItem, getOrderItemById} = useTransportationOrder(
    transportationOrderId,
    shipmentId
  );

  const orderItem = getOrderItemById(orderItemId);

  if (!orderItem || !isContainerOrderItem(orderItem)) {
    return null;
  }

  const {totalCharge, totalMinusCredits} = getServiceProviderFinancialDisplayValuesFromOrderItem(orderItem);

  return (
    <div className="flex items-start justify-between px-4 py-1">
      <DeprecatedButton variant="tertiary" icon={<SvgIcon name="AddCircleOutlined" />}>
        Add Line Item
      </DeprecatedButton>
      <div className="text-xxs">
        <div className="bg-sw-success-background p-2">
          <span className="pr-1 text-sm font-bold text-sw-success">{totalCharge}</span> TOTAL DUE TO CARRIER
        </div>
        <div className="p-2">
          <span className="pr-1 text-sm font-bold text-sw-success">{totalMinusCredits}</span> SHIPMENT TOTAL
        </div>
      </div>
    </div>
  );
};

const LineItemRowDisplay = ({lineItemRowData}: {lineItemRowData: (string | number)[]}) => {
  return (
    <div className="group grid grid-cols-[1.5fr,1.5fr,0.5fr,0.5fr,0.5fr,0.5fr] items-center py-1 hover:bg-sw-active-light">
      {lineItemRowData.map((dataToDisplay, index) => (
        <div
          className={classNames(
            {'text-bold': index === LINE_ITEM_INDICES.CARRIER_RATE},
            {'overflow-hidden': index === LINE_ITEM_INDICES.DESCRIPTION}
          )}
          key={`${dataToDisplay}${index}`}
        >
          {index === LINE_ITEM_INDICES.DESCRIPTION && dataToDisplay !== '--' ? (
            <Tooltip placement="left" wrapperClassname="w-full" tooltipContent={dataToDisplay}>
              <div className="truncate"> {dataToDisplay}</div>
            </Tooltip>
          ) : (
            dataToDisplay
          )}
        </div>
      ))}
      <div className="invisible flex flex-row group-hover:visible">
        <DeprecatedButton variant="icon" icon={<SvgIcon name="Edit" />} />
        <DeprecatedButton variant="icon" icon={<SvgIcon name="TrashFilled" />} />
      </div>
    </div>
  );
};

const LineItemsListView = ({
  shipmentId,
  transportationOrderId,
  orderItemId
}: {
  shipmentId: string;
  transportationOrderId: string;
  orderItemId: string;
}) => {
  const {queryInfo, getServiceProviderFinancialDisplayValuesFromOrderItem, getOrderItemById} = useTransportationOrder(
    transportationOrderId,
    shipmentId
  );

  const orderItem = getOrderItemById(orderItemId);

  if (!orderItem || !isContainerOrderItem(orderItem)) {
    return null;
  }

  const {lineItemDisplayValues} = getServiceProviderFinancialDisplayValuesFromOrderItem(orderItem);

  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <>
      <div className="grid grid-cols-[1.5fr,1.5fr,0.5fr,0.5fr,0.5fr,0.5fr] bg-sw-background px-4">
        {LINE_ITEM_HEADERS.map((header: string) => (
          <div className="py-2 text-xxs font-bold" key={header}>
            {header}
          </div>
        ))}
      </div>
      <div className="mx-4 border-b border-b-sw-border">
        <div className="pt-4 text-xxs italic text-sw-text-section-title">TENDERED</div>
        {lineItemDisplayValues?.map((lineItemRow, index) => (
          <LineItemRowDisplay key={`${lineItemRow[index]}${index}`} lineItemRowData={lineItemRow} />
        ))}
      </div>
      <TotalsSection shipmentId={shipmentId} transportationOrderId={transportationOrderId} orderItemId={orderItemId} />
    </>
  );
};

export default LineItemsListView;
