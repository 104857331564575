import {Field} from 'formik';
import {FormikTextarea, Tooltip, SvgIcon, FormikRadioGroup} from '@shipwell/shipwell-ui';

const CompanyRateConfirmation = (props) => {
  const {values, setFieldValue, email} = props;
  const renderTooltipContent = () => {
    return (
      <>
        <b>Carrier Invoicing Instructions</b>
        <p className="tooltip-content">
          These instructions are displayed next to the carrier
          <br />
          signature field on the Rate Confirmation document.
          <br />
          You can use the default instructions or create your
          <br />
          own custom instructions.
        </p>
      </>
    );
  };

  const defaultInstructions = (
    <p className="rate-confirmation__default-text">
      {`All invoices, receipts, PODs, and other documents related to this shipment must be uploaded by the web or mobile
      app or emailed to ${email}.`}
    </p>
  );

  const handleCustomInstructionsToggle = (value) => {
    setFieldValue('use_custom_carrier_invoicing_instructions', value);
  };

  const showCustomText = values?.use_custom_carrier_invoicing_instructions === 'custom';

  return (
    <>
      <div className="rate-confirmation__section">
        <div className="rate-confirmation__section-title">
          <b>Rate Terms</b>
        </div>
        <Field name="rate_confirmation_terms" label="Rate Terms" minRows={3} maxRows={3} component={FormikTextarea} />
      </div>
      <div className="rate-confirmation-section">
        <div className="rate-confirmation__section-title">
          <b>Carrier Invoicing Instructions</b>
          <Tooltip tooltipContent={renderTooltipContent()}>
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
        <Field
          name="use_custom_carrier_invoicing_instructions"
          onChange={(e) => handleCustomInstructionsToggle(e.target.value)}
          options={[
            {
              label: 'Default',
              value: 'default',
              customContent: defaultInstructions
            },
            {
              label: 'Custom',
              value: 'custom',
              customContent: showCustomText && (
                <Field
                  name="custom_carrier_invoicing_instructions"
                  label="Carrier Invoicing Instructions"
                  minRows={3}
                  maxRows={3}
                  style={{marginRight: '16px'}}
                  component={FormikTextarea}
                />
              )
            }
          ]}
          component={FormikRadioGroup}
        />
      </div>
    </>
  );
};

export default CompanyRateConfirmation;
