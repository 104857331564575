import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Classnames from 'classnames';
import {useQuery} from '@tanstack/react-query';
import {compose} from 'recompose';
import {toggleSideMenu} from 'App/actions/users';
import {clearAlertError} from 'App/actions/shipments';
import {getSystemVersion} from 'App/actions/auth';
import MainHeader from 'App/common/mainHeader';
import {TitleContextConsumer, TitleContextProvider} from 'App/utils/hooks/usePageTitle';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';
import {PROPTYPE_LOCATION, PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import {VERSIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {basePath} from 'App/api/config';

function withSystemVersionPolling(Component) {
  function SystemVersionPoller({systemVersion, dispatch, ...props}) {
    useQuery([VERSIONS_QUERY_KEY], () => dispatch(getSystemVersion(systemVersion)), {
      staleTime: Infinity,
      refetchInterval: 900000 // 15 minutes
    });

    return <Component {...props} />;
  }

  SystemVersionPoller.propTypes = {
    dispatch: PropTypes.func,
    systemVersion: PropTypes.string
  };

  return connect((state) => ({
    systemVersion: state.auth.systemVersion
  }))(SystemVersionPoller);
}

class Account extends Component {
  static contextTypes = {
    router: PROPTYPE_ROUTER
  };
  constructor(props) {
    super(props);
    this.state = {
      showWarning: false
    };
  }

  componentDidMount() {
    //start listening for a logout event from other tabs
    window.addEventListener(
      'storage',
      function (event) {
        if (
          event.key === 'swlogout' &&
          window.location.pathname !== '/' &&
          window.location.pathname !== '/signout' &&
          event.newValue !== null &&
          event.newValue !== ''
        ) {
          this.context.router.push('/signout');
        }
      }.bind(this)
    );

    //check if old version of IE (compatibility issues)
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      this.setState({showWarning: true});
      setTimeout(
        function () {
          this.setState({showWarning: false});
        }.bind(this),
        8000
      );
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      this.setState({showWarning: true});
      setTimeout(
        function () {
          this.setState({showWarning: false});
        }.bind(this),
        8000
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // hide menu on navigation
    if (this.props.openMenu && this.props.location.pathname !== nextProps.location.pathname) {
      this.props.toggleSideMenu(false);
    }
    if (nextProps.alertError) {
      setTimeout(
        function () {
          this.props.clearAlertError();
        }.bind(this),
        8000
      );
    }
  }

  render() {
    const {router} = this.props;
    const style = {
      position: 'fixed',
      bottom: '30px',
      right: '10px',
      opacity: '0.7',
      pointerEvents: 'none',
      zIndex: '99',
      color: 'gray',
      fontSize: '2.0em'
    };
    /* TODO: move alert styles */

    const isDev = !basePath.startsWith('https://api.shipwell.com/');

    const toggleClasses = Classnames({
      wrapper: true,
      openmenu: this.props.openMenu
    });

    const companyTitle = localStorage.getItem('whiteLabelTitle');
    return (
      <TitleContextProvider location={router.location} params={router.params}>
        <div className={toggleClasses}>
          <TitleContextConsumer>
            {({title}) => (
              <Helmet defaultTitle={companyTitle} titleTemplate={`%s - ${companyTitle}`}>
                <title>{title}</title>
              </Helmet>
            )}
          </TitleContextConsumer>

          <MainHeader router={this.context.router} />
          <RollbarErrorBoundary>{this.props.children}</RollbarErrorBoundary>
          {this.state.showWarning && (
            <Alert className="error-alert" onDismiss={() => this.setState({showWarning: false})}>
              <p>
                Warning: This platform is optimized for modern browsers.
                <br />
                Consider using Chrome or Firefox instead of Internet Explorer.
              </p>
            </Alert>
          )}
          {this.props.alertError && (
            <div className="error-alert error-text-form-level">
              <span className="error-alert-left">
                <i className="icon icon-Delayed" />
              </span>
              <span className="error-alert-right">{this.props.alertError}</span>
              <span className="error-alert-close">
                <i className="icon icon-x-small showAsLink" onClick={this.props.clearAlertError} />
              </span>
            </div>
          )}
          {isDev ? <div style={style}>DEMO</div> : null}
        </div>
      </TitleContextProvider>
    );
  }
}

Account.propTypes = {
  location: PROPTYPE_LOCATION,
  router: PROPTYPE_ROUTER,
  alertError: PropTypes.string,
  openMenu: PropTypes.bool,
  clearAlertError: PropTypes.func,
  getSystemVersion: PropTypes.func,
  toggleSideMenu: PropTypes.func,
  children: PropTypes.node
};

export default compose(
  connect(
    (state) => ({
      alertError: state.shipments.alertError,
      openMenu: state.users.openMenu
    }),
    {
      clearAlertError,
      toggleSideMenu
    }
  ),
  withSystemVersionPolling
)(Account);
