import {
  REQUEST_PURCHASE_ORDER_LINEITEMS_PENDING,
  REQUEST_PURCHASE_ORDER_LINEITEMS_SUCCESS,
  REQUEST_SHIPMENT_PURCHASE_ORDERS_SUCCESS,
  UPDATE_PURCHASE_ORDER_PENDING,
  UPDATE_PURCHASE_ORDER_SUCCESS
} from '../actions';

const normalizeOrdersByStop = (order, state) => {
  const nextState = {...state};

  /** cleanup and re-insert order instances */
  for (const stop in nextState) {
    nextState[stop] = nextState[stop].filter((v) => v.id !== order.id);
  }
  if (!nextState[order.origin_stop]) {
    nextState[order.origin_stop] = [];
  }
  if (!nextState[order.destination_stop]) {
    nextState[order.destination_stop] = [];
  }

  nextState[order.destination_stop].push(order);
  nextState[order.origin_stop].push(order);

  return nextState;
};

const normalizeOrdersByShipment = (order, state) => {
  const nextState = {...state};

  if (!order.shipment) {
    for (const shipment in nextState) {
      nextState[shipment] = nextState[shipment].filter((v) => v.id !== order.id);
    }
  }
  if (order.shipment?.id) {
    if (!nextState[order.shipment.id]) {
      nextState[order.shipment.id] = [];
    }
    if (nextState[order.shipment.id] && !nextState[order.shipment.id].find((v) => v.id === order.id)) {
      nextState[order.shipment.id].push(order);
    } else if (nextState[order.shipment.id]) {
      nextState[order.shipment.id] = nextState[order.shipment.id].map((v) => (v.id === order.id ? order : v));
    }
  }
  return nextState;
};

/** Initial state */
export const DEFAULT_STATE = {
  prevState: null,
  isFetching: false,
  isSaving: false,
  lineItems: [],
  lineItemsById: {},
  lineItemsByPurchaseOrder: {},
  purchaseOrdersByShipment: {},
  purchaseOrdersByStop: {},
  purchaseOrdersById: {}
};

/**
 * Purchase order details reducer
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object} state
 */
export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;

  const lineItems = [...state.lineItems];
  const lineItemsById = {...state.lineItemsById};
  const lineItemsByPurchaseOrder = {...state.lineItemsByPurchaseOrder};
  let purchaseOrdersByShipment = {...state.purchaseOrdersByShipment};
  let purchaseOrdersByStop = {...state.purchaseOrdersByStop};
  const purchaseOrdersById = {...state.purchaseOrdersById};

  switch (type) {
    case REQUEST_PURCHASE_ORDER_LINEITEMS_PENDING:
      return Object.assign({}, state, {
        isFetching: true
      });

    case REQUEST_PURCHASE_ORDER_LINEITEMS_SUCCESS:
      payload.results.forEach((lineItem) => {
        lineItemsById[lineItem.id] = lineItem;

        if (!lineItems.find((l) => l.id === lineItem.id)) {
          lineItems.push(lineItem);
        }
        if (!lineItemsByPurchaseOrder[lineItem.purchase_order]) {
          lineItemsByPurchaseOrder[lineItem.purchase_order] = [];
        }
        if (!lineItemsByPurchaseOrder[lineItem.purchase_order].find((l) => l.id === lineItem.id)) {
          lineItemsByPurchaseOrder[lineItem.purchase_order].push(lineItem);
        }
      });

      return Object.assign({}, state, {
        isFetching: false,
        lineItems: lineItems,
        lineItemsById: lineItemsById,
        lineItemsByPurchaseOrder: lineItemsByPurchaseOrder
      });

    case REQUEST_SHIPMENT_PURCHASE_ORDERS_SUCCESS:
      payload.results.forEach((order) => {
        purchaseOrdersById[order.id] = order;
        purchaseOrdersByStop = normalizeOrdersByStop(order, purchaseOrdersByStop);
        purchaseOrdersByShipment = normalizeOrdersByShipment(order, purchaseOrdersByShipment);
      });

      return Object.assign({}, state, {
        prevState: state,
        purchaseOrdersByShipment: purchaseOrdersByShipment,
        purchaseOrdersByStop: purchaseOrdersByStop,
        purchaseOrdersById: purchaseOrdersById
      });

    /**
     * Purchase Orders - Update
     */

    case UPDATE_PURCHASE_ORDER_PENDING:
      return Object.assign({}, state, {
        isSaving: true
      });

    case UPDATE_PURCHASE_ORDER_SUCCESS:
      purchaseOrdersById[payload.id] = payload;
      purchaseOrdersByStop = normalizeOrdersByStop(payload, purchaseOrdersByStop);
      purchaseOrdersByShipment = normalizeOrdersByShipment(payload, purchaseOrdersByShipment);

      return Object.assign({}, state, {
        isSaving: false,
        prevState: state,
        purchaseOrdersById: purchaseOrdersById,
        purchaseOrdersByStop: purchaseOrdersByStop,
        purchaseOrdersByShipment: purchaseOrdersByShipment
      });

    default:
      return state;
  }
};
