import {useState} from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {Field, Form, Formik} from 'formik';
import {compose} from 'recompose';
import {object, string, array, mixed} from 'yup';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CompanyPreferencesCountryEnum, ContractDistanceUnitEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon, FormikSelect, FormikTextarea} from '@shipwell/shipwell-ui';
import {getMatchingParams} from './utils/contractSelectUtils';
import {createBulkOperation} from 'App/api/bulkOperations';
import TenderRequestFields from 'App/formComponents/formSections/tenderRequest';
import {chargeLineItemDefaults, validateDollarValue} from 'App/utils/globals';
import {carriersValidationSchema, selectOptionValidationSchema} from 'App/utils/yupHelpers';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import WithStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import {getTenderBulkOperationBody} from 'App/containers/Dashboard/components/BulkActions/bulkTenderRequest/utils';
import {PROPTYPE_MODE, PROPTYPE_EQUIPMENT_TYPE} from 'App/utils/propTypeConstants';
import {ContractSelect} from 'App/formComponents/formSections/tenderRequest/ContractSelect';
import {useTenderContract} from 'App/data-hooks/tendering/useTenderContract';
import getTimeOptions from 'App/utils/getTimeOptions';
import {roundNumberToSpecificDecimals} from 'App/utils/mathHelpers';
import {useModesQuery, useCompanyPreferences} from 'App/data-hooks';
import {useCreateTender} from 'App/data-hooks/tendering/useCreateTender';
import {SHIPMENTS_DASHBOARD_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ContractsDetails} from 'App/formComponents/forms/tendering/ContractsDetails';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {isLineHaulChargeCategory} from 'App/utils/globalsTyped';
import {getRateTotal} from 'App/utils/tenderUtils';

const BulkTenderRequestForm = ({selectedShipmentsById, onClose, onBulkSuccess, setError, onSuccess}) => {
  const {data: companyPreferences} = useCompanyPreferences();
  const tenderFieldsDistanceUnit =
    companyPreferences?.country === CompanyPreferencesCountryEnum.Ca
      ? ContractDistanceUnitEnum.Kilometer
      : ContractDistanceUnitEnum.Mile;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nonMatchingMessage, setNonMatchingMessage] = useState({
    background: 'bg-sw-active-light',
    color: 'text-sw-active',
    icon: 'InfoFilled'
  });
  const selectedShipmentIds = Object.keys(selectedShipmentsById);

  const queryClient = useQueryClient();
  const {data: equipmentTypes} = useEquipmentTypesQuery();
  const {data: shipmentModes} = useModesQuery();

  // Reduces our selected shipments to an array of unique mode values
  const selectedShipmentsModes = uniqWith(
    Object.values(selectedShipmentsById).reduce((modesArray, shipment) => {
      modesArray.push(shipment.mode);
      return modesArray;
    }, []),
    isEqual
  );

  // Reduces our selected shipments to an array of unique equipment_type values
  const selectedShipmentsEquipments = uniqWith(
    Object.values(selectedShipmentsById).reduce((equipmentsArray, shipment) => {
      const equipmentType = shipment.equipment_type?.machine_readable
        ? shipment.equipment_type
        : // the shipping-dashboard endpoint only returns the name property
          equipmentTypes?.find((eq) => eq.name === shipment.equipment_type?.name);

      equipmentsArray.push(equipmentType);
      return equipmentsArray;
    }, []),
    isEqual
  );

  // Our selected shipments do not match if they do not all share a singular mode and equipment tpe.
  const hasNonMatchingSelectedShipments = selectedShipmentsModes.length > 1 || selectedShipmentsEquipments.length > 1;
  const nonMatchingTypes = () => {
    if (selectedShipmentsModes.length > 1 && selectedShipmentsEquipments.length > 1) {
      return 'mode and equipment ';
    }
    if (selectedShipmentsModes.length > 1) {
      return 'mode ';
    }
    if (selectedShipmentsEquipments.length > 1) {
      return 'equipment ';
    }
    return '';
  };

  // Displaying the bulk tender form means all shipments have the same preferred_currency, safe to use the first one
  const currencyOfRecord = Object.values(selectedShipmentsById).find(
    (shipment) => !!shipment.preferred_currency
  )?.preferred_currency;

  const defaultFormValues = {
    equipment_type: selectedShipmentsEquipments[0]?.machine_readable,
    mode: selectedShipmentsModes[0]?.code,
    expires_after_seconds: null,
    message: '',
    charge_line_items: [
      {
        unit_name: chargeLineItemDefaults.unit_name,
        unit_amount: '',
        unit_amount_currency: currencyOfRecord,
        unit_quantity: chargeLineItemDefaults.unit_quantity,
        category: chargeLineItemDefaults.category,
        charge_code: chargeLineItemDefaults.charge_code
      }
    ],
    rate_type: '',
    info_message: '',
    tender_to_company: null,
    contract: null,
    distanceUnit: tenderFieldsDistanceUnit
  };

  const tenderRequestSchema = object().shape({
    mode: string().required('Mode is required.'),
    tender_to_company: carriersValidationSchema,
    charge_line_items: array().of(
      object().shape({
        unit_amount: mixed()
          .test({
            name: 'valid dollar value',
            message: 'Enter a dollar value',
            test: (value) => validateDollarValue(value)
          })
          .required('Rate is required.')
      })
    ),
    rate_type: string().required('Rate type is required.').nullable(),
    expires_after_seconds: selectOptionValidationSchema.label('Expiration')
  });

  const contractTenderRequestSchema = object().shape({
    expires_after_seconds: selectOptionValidationSchema
      .label('Expiration')
      .required('Expiration is required.')
      .nullable()
  });

  const selectedShipmentsArray = selectedShipmentIds.map((id) => selectedShipmentsById[id]);

  const {
    selectedApplicableContract,
    selectedMultiApplicableContracts,
    handleContractChange,
    carrierCapacityIsAvailable,
    getContractValues,
    getMultiContractsValues,
    isLoading: isLoadingTenderContract,
    hasSelectedContracts,
    perHourMessage
  } = useTenderContract({shipments: selectedShipmentsArray});

  const {createTender, createTenderAsync} = useCreateTender();
  const createBulkOperationMutation = useMutation(createBulkOperation);

  const getSingleShipmentTenderValues = (values) => {
    const involvedTenderToCompanyUsers = values.tender_to_company?.[0]?.id
      ? [values.tender_to_company?.[0]?.id]
      : values.tender_to_company?.[0]?.carrierRelationship?.point_of_contacts?.map((contact) => contact.user);

    return {
      ...values,
      // info_message cannot be an empty string
      info_message: values.info_message || undefined,
      shipment: selectedShipmentIds[0],
      expires_at: moment().add(values.expires_after_seconds.value, 'seconds'),
      ...(involvedTenderToCompanyUsers ? {involved_tender_to_company_users: involvedTenderToCompanyUsers} : {}),
      tender_to_company: values.tender_to_company?.[0].carrier,
      // using name here for this to work on both old and new dashboards
      equipment_type: equipmentTypes?.find(
        (eq) => eq.name === selectedShipmentsById[selectedShipmentIds[0]].equipment_type?.name
      )?.machine_readable,
      mode: selectedShipmentsById[selectedShipmentIds[0]].mode?.id
    };
  };

  const handleSubmit = async (values) => {
    if (hasNonMatchingSelectedShipments) {
      setNonMatchingMessage({
        background: 'bg-sw-error-background',
        color: 'text-sw-error',
        icon: 'ErrorFilled'
      });
      return;
    }
    setNonMatchingMessage({
      background: 'bg-sw-active-light',
      color: 'text-sw-active',
      icon: 'InfoFilled'
    });
    setIsSubmitting(true);
    const updatedValues = selectedApplicableContract
      ? getContractValues(values)
      : selectedMultiApplicableContracts?.length
      ? getMultiContractsValues(values)
      : {
          ...values,
          charge_line_items: values.charge_line_items.map((lineItem) => {
            if (isLineHaulChargeCategory(lineItem.category)) {
              return {
                ...lineItem,
                unit_amount: roundNumberToSpecificDecimals(lineItem.unit_amount, 4)
              };
            }
            return lineItem;
          })
        };

    // create single tender
    if (selectedShipmentIds.length === 1) {
      const tenderValues = !Array.isArray(updatedValues)
        ? getSingleShipmentTenderValues(updatedValues)
        : updatedValues.map((values) => getSingleShipmentTenderValues(values));

      if (!Array.isArray(tenderValues)) {
        delete tenderValues.expires_after_seconds;
      } else {
        tenderValues.map((value) => delete value.expires_after_seconds);
      }

      // if no contract selected and tendering a single shipment, we need to adjust the lineItem rate manually
      if (!selectedApplicableContract && !selectedMultiApplicableContracts?.length) {
        const lineItems = values.charge_line_items.map((lineItem) => {
          if (isLineHaulChargeCategory(lineItem.category)) {
            const [shipment] = Object.values(selectedShipmentsById);
            const rateTotal = getRateTotal({
              shipment,
              rateType: values.rate_type,
              baseRate: lineItem.unit_amount,
              distanceUnit: values.distanceUnit
            });
            return {
              ...lineItem,
              unit_amount: roundNumberToSpecificDecimals(rateTotal, 4)
            };
          }
          return lineItem;
        });
        // assign the new line items with corrent unit amount
        tenderValues.charge_line_items = lineItems;
      }
      // if not an array of tenders then only a single request needs to be made
      if (!Array.isArray(tenderValues)) {
        createTender(
          {tenderCreate: tenderValues},
          {
            onSuccess: () => {
              onSuccess();
              onClose();
              queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
            },
            onError: (err) => {
              console.error(err);
              const errors = err.response.data.non_field_errors;
              setError(
                'Tender Request Failed!',
                errors.length ? (
                  <ul>
                    {errors.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                ) : (
                  'The tender request could not be sent for the selected shipment'
                )
              );
            },
            onSettled: () => {
              setIsSubmitting(false);
            }
          }
        );
      } else {
        // if we reach this point then we are tendering multiple contracts and need to handle the array of promises
        const responses = await Promise.allSettled(
          tenderValues.map(async (value) => {
            await createTenderAsync({tenderCreate: value}, {onError: (error) => console.error(error)});
          })
        );
        setIsSubmitting(false);
        if (responses.some((response) => response?.status === 'rejected')) {
          // TO-DO: display meaningful errors
          const errors = responses
            .filter((response) => response.status === 'rejected')
            ?.map((response) =>
              response.reason?.response?.data?.field_errors_condensed?.map(
                (condensedError) => condensedError.field_errors
              )
            )
            ?.flat(Infinity);
          console.error(errors);
          setError(
            'Tender Requests Failed!',
            errors.length ? (
              <ul>
                {errors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            ) : (
              'Some tender requests could not be sent for the selected shipment'
            )
          );
        } else {
          onSuccess();
          onClose();
          queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
        }
      }
      return;
    }

    // if more than one shipment is selected, preform bulk operation below
    const bulkOperationBody = getTenderBulkOperationBody(
      {
        ...updatedValues,
        // manually adding in equipment types because the new dashboard returns different data
        // this should account both old and new dashboards
        // the form prevents sending differing equipment types so it is safe to look at the first entry
        equipment_type: equipmentTypes?.find(
          (eq) => eq.name === selectedShipmentsById[selectedShipmentIds[0]].equipment_type?.name
        )?.machine_readable
      },
      selectedShipmentIds,
      shipmentModes
    );
    return createBulkOperationMutation.mutateAsync(bulkOperationBody, {
      onSuccess: (data) => {
        onSuccess();
        onBulkSuccess(data.data?.id);
        onClose();
      },
      onError: (err) => {
        const errorMessage = `The tender request could not be sent for the selected ${pluralize(
          'shipment',
          selectedShipmentIds.length
        )}.`;
        console.error(errorMessage, err);
        setError('Tender Request Failed!', errorMessage);
      },
      onSettled: () => {
        setIsSubmitting(false);
      }
    });
  };

  const apiReadableModes = [...new Set(selectedShipmentsModes?.map((mode) => mode?.code))];
  const modeCodes = [...new Set(selectedShipmentsModes?.map((mode) => mode?.code))];
  const apiReadableEquipment = [
    ...new Set(selectedShipmentsEquipments?.map((equipment) => equipment?.machine_readable))
  ];
  const humanReadableEquipment = [...new Set(selectedShipmentsEquipments?.map((equipment) => equipment?.name))];
  // if there is only one shipment selected, we can get the stop info and get more precise contract results
  const stopAddresses =
    selectedShipmentIds.length === 1
      ? selectedShipmentsById[selectedShipmentIds[0]]?.stops?.map((stop) => stop.location.address)
      : undefined;

  const {matchedAddresses} = getMatchingParams({
    modes: selectedShipmentsModes,
    equipmentTypes: selectedShipmentsEquipments,
    shipmentsStopsAddresses: Object.values(selectedShipmentsById)?.map((shipment) =>
      shipment.stops.map((stop) => stop.location.address)
    )
  });

  return (
    <div className="flex flex-col gap-4">
      {hasNonMatchingSelectedShipments ? (
        <div
          className={`flex items-center gap-2 rounded-md ${nonMatchingMessage.background} p-2 pl-0 ${nonMatchingMessage.color}`}
        >
          <SvgIcon width="3rem" name={nonMatchingMessage.icon} />
          <span className="font-bold text-sw-text">
            Cannot perform Tender to Carrier bulk action on these shipments because of conflicting {nonMatchingTypes()}
            types.
          </span>
        </div>
      ) : null}
      <ContractSelect
        stopAddresses={stopAddresses || matchedAddresses}
        isBulkShipments={selectedShipmentIds.length > 1}
        contract={selectedApplicableContract?.contract}
        onChange={handleContractChange}
        modes={apiReadableModes}
        equipment={apiReadableEquipment}
        isMulti={selectedShipmentIds.length === 1}
        shipmentId={selectedShipmentIds.length === 1 ? selectedShipmentIds[0] : undefined}
        currencyOfRecord={currencyOfRecord}
      />
      {hasSelectedContracts ? (
        <Formik
          enableReinitialize
          validationSchema={contractTenderRequestSchema}
          onSubmit={handleSubmit}
          initialValues={{expires_after_seconds: null, info_message: perHourMessage}}
        >
          {({handleSubmit, isSubmitting}) => (
            <Form onSubmit={handleSubmit} className="mb-12 flex flex-col gap-4">
              <ContractsDetails
                showFinancialInfo={selectedShipmentsArray.length === 1}
                carrierCapacityIsAvailable={carrierCapacityIsAvailable}
                selectedApplicableContract={selectedApplicableContract}
                selectedMultiApplicableContracts={selectedMultiApplicableContracts}
                modes={modeCodes}
                equipment={humanReadableEquipment}
                currencyOfRecord={currencyOfRecord}
                shipmentId={selectedShipmentsArray.length === 1 ? selectedShipmentsArray[0].id : undefined}
              />
              <Field
                label="Expiration"
                options={getTimeOptions().filter((e) => e.value !== 0)}
                name="expires_after_seconds"
                component={FormikSelect}
                required
              />
              <Field label="Special Instructions" name="info_message" component={FormikTextarea} />
              <ModalFormFooter
                onCancel={onClose}
                isSubmitting={isSubmitting}
                isValid={carrierCapacityIsAvailable || !isLoadingTenderContract}
              />
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          enableReinitialize
          validationSchema={tenderRequestSchema}
          onSubmit={handleSubmit}
          initialValues={defaultFormValues}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit} className="tender-request-form" noValidate="novalidate">
              <div className="mb-10">
                <TenderRequestFields
                  shipmentDetailData={selectedShipmentsArray.length === 1 ? selectedShipmentsArray[0] : {}}
                  showOptions={{equipment_type: false, mode: false}}
                  filteredOptionsList={{
                    showFilteredEquipTypes: false,
                    showFilteredModes: false,
                    equipment_type: [],
                    mode: []
                  }}
                  selectedShipmentIds={selectedShipmentIds}
                  expirationType="expires_after_seconds"
                  singleCarrier
                  currencyOfRecord={currencyOfRecord}
                />
              </div>
              <ModalFormFooter onCancel={onClose} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

BulkTenderRequestForm.propTypes = {
  selectedShipmentsById: PropTypes.shape({
    mode: PROPTYPE_MODE,
    equipment_type: PROPTYPE_EQUIPMENT_TYPE
  }),
  shipmentModes: PropTypes.arrayOf(PROPTYPE_MODE),
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  ...WithStatusToastsPropTypes
};

export default compose(WithStatusToasts)(BulkTenderRequestForm);
