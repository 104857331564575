import {AxiosError} from 'axios';
import {ShipwellApiError} from '@shipwell/genesis-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {PaginatedUserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import {getUserDashboardConfigs} from 'App/api/tableConfig/typed';
import {USER_DASHBOARDS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type UseGetUserDashboardConfigs = UseQueryOptions<
  PaginatedUserDashboardConfiguration,
  AxiosError<ShipwellApiError>,
  PaginatedUserDashboardConfiguration,
  string[]
>;

const useGetUserDashboardConfigs = (tableType: string, options?: UseGetUserDashboardConfigs) => {
  const response = useQuery(
    [USER_DASHBOARDS_QUERY_KEY, tableType],
    async () => {
      const response = await getUserDashboardConfigs({page: 1, pageSize: 10, tableType});
      return response.data;
    },
    options
  );

  return response;
};

export default useGetUserDashboardConfigs;
