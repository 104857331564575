import {Fragment, Component} from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import {Button} from 'react-bootstrap';
import './styles.scss';

export class ImageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scale: 1.0,
      updateImage: false,
      originalImage: null,
      imageReadError: null
    };
  }

  /**
   * Update image from file selection
   */
  handleSelectImage([image]) {
    if (image && /(png|jpg|jpeg)/.test(image.type)) {
      this.setState({
        updateImage: true,
        imageReadError: null,
        originalImage: image
      });
    } else {
      this.setState({imageReadError: 'Please upload the image in either PNG or JPEG format.'});
    }
  }

  handleImageScale(event) {
    this.setState({scale: event.target.value});
    this.handleImageChange();
  }

  /**
   * Update redux form with updated/edited version of image
   */
  handleImageChange(a) {
    const {input} = this.props;
    const onChange = input.onChange || this.props.onChange;

    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();

      canvasScaled.toBlob((blob) => {
        onChange &&
          onChange({
            blob: blob,
            dataUrl: canvasScaled.toDataURL()
          });
      });
    }
  }

  handleUpdateImage() {
    this.setState({updateImage: true}, () => {
      this.dropArea.open();
    });
  }

  render() {
    const {originalImage, scale, updateImage, imageReadError} = this.state;
    const {input} = this.props;
    const image = input.value || this.props.value;
    let imagePath = '';

    if (originalImage) {
      imagePath = originalImage;
    } else if (image && image.dataUrl) {
      imagePath = image.dataUrl;
    } else if (image) {
      imagePath = image;
    }

    return (
      <div className="products__imageUpload">
        <Dropzone
          ref={(dropArea) => (this.dropArea = dropArea)}
          accept="image/jpeg, image/png"
          style={{width: '300px', height: '300px'}}
          disableClick={Boolean(imagePath)}
          onDrop={this.handleSelectImage.bind(this)}
        >
          <ReactAvatarEditor
            width={250}
            height={250}
            ref={(editor) => (this.editor = editor)}
            image={imagePath}
            scale={parseFloat(scale)}
            onLoadSuccess={this.handleImageChange.bind(this)}
            onImageChange={this.handleImageChange.bind(this)}
          />
        </Dropzone>
        {imageReadError && <p>{imageReadError}</p>}
        {updateImage && (
          <Fragment>
            <input
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              className="slider"
              value={scale}
              onChange={this.handleImageScale.bind(this)}
            />
            <p className="showAsLink btn-tertiary" onClick={this.handleUpdateImage.bind(this)}>
              Select new image
            </p>
          </Fragment>
        )}
        {!updateImage && (
          <Button bsStyle="link" bsClass="btn btn-tertiary" onClick={() => this.dropArea.open()}>
            Select new image
          </Button>
        )}
      </div>
    );
  }
}

export default ImageSelector;
