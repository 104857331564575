import {Dispatch, SetStateAction} from 'react';
import {IconButton} from '@shipwell/shipwell-ui';
import {Facility, FacilityDock, LoadType} from '@shipwell/tempus-sdk';
import {AppointmentList} from './AppointmentsList/AppointmentList';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';

export type UnscheduledAppointmentsListProps = {
  facility: Facility | null;
  appointments: AppointmentEntry[];
  loadTypes: LoadType[];
  docks: FacilityDock[];
  close: () => unknown;
  setSelectedAppointment: (appointment: AppointmentEntry) => unknown;
  setDraggingAppointment: (appointment: AppointmentEntry) => unknown;
  setSelectedTab: Dispatch<SetStateAction<string | null>>;
};

export function UnscheduledAppointmentsList({
  appointments,
  loadTypes,
  docks,
  close,
  setSelectedAppointment,
  setDraggingAppointment,
  setSelectedTab
}: UnscheduledAppointmentsListProps) {
  return (
    <div className="sw-panel-contents flex w-full flex-col">
      <div className="unscheduled-header flex-0 flex flex-row justify-between border-b-1 border-sw-divider ">
        <h5 className="justify-self-start p-1">Unscheduled Appointments</h5>
        <div className="cursor-pointer justify-self-end p-3">
          <IconButton iconName="Close" onClick={close} aria-label="Close" />
        </div>
      </div>
      <span className="p-2 pb-0 italic text-sw-disabled-text">
        Drag and drop unscheduled appointments onto the calendar to schedule.
      </span>
      <AppointmentList
        appointments={appointments}
        loadTypes={loadTypes}
        docks={docks}
        setSelectedAppointment={setSelectedAppointment}
        setDraggingAppointment={setDraggingAppointment}
        setSelectedTab={setSelectedTab}
      />
    </div>
  );
}
