import {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import renderTextArea from 'App/formComponents/renderTextArea';

@connect(
  (state) => ({
    ReportInaccurateForm: state.form.ReportInaccurateForm
  }),
  {}
)
class ReportInaccurateForm extends Component {
  render() {
    const {handleSubmit, submitting, pristine} = this.props;
    return (
      <div className="vendors__report">
        <Field
          label="Details"
          name="notes"
          component={renderTextArea}
          placeholder="Please enter details on the inaccurate information for this carrier. This will be sent to the Shipwell team for review."
          minRows={4}
        />
        <div className="manage-carriers__footer pad-top-double">
          <Button type="button" disabled={submitting || pristine} bsStyle="primary" onClick={handleSubmit}>
            {submitting ? (
              <span>
                <i className="icon icon-Restart rotate" />
                &nbsp;&nbsp;Submitting...
              </span>
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </div>
    );
  }
}

ReportInaccurateForm = reduxForm({
  form: 'ReportInaccurateForm',
  enableReinitialize: true
})(ReportInaccurateForm);

export default ReportInaccurateForm;
