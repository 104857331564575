import {IconButton} from '@shipwell/shipwell-ui';
import {Facility, FacilityDock, LoadType} from '@shipwell/tempus-sdk';

import {AppointmentList} from './AppointmentsList/AppointmentList';
import AppointmentsDownloadButton from './AppointmentsList/DownloadButton';

import {AppointmentEntry} from 'App/data-hooks/appointments/types';

export type FirstComeFirstServeListProps = {
  facility: Facility | null;
  appointments: AppointmentEntry[];
  loadTypes: LoadType[];
  docks: FacilityDock[];
  close: () => unknown;
  setSelectedAppointment: (appointment: AppointmentEntry) => unknown;
};

export function FirstComeFirstServeList({
  facility,
  appointments,
  loadTypes,
  docks,
  close,
  setSelectedAppointment
}: FirstComeFirstServeListProps) {
  return (
    <div className="sw-panel-contents flex size-full flex-col">
      <div className="flex-0 fcfs-header flex flex-row justify-between border-b-1 border-sw-divider ">
        <h5 className="justify-self-start p-1">First Come First Serve Appointments</h5>
        <AppointmentsDownloadButton
          filename="first-come-first-serve.csv"
          appointments={appointments}
          facilities={[facility].filter(Boolean) as Facility[]}
          loadTypes={loadTypes}
          docks={docks}
        />
        <div className="cursor-pointer justify-self-end p-3">
          <IconButton iconName="Close" onClick={close} aria-label="Close" />
        </div>
      </div>
      <span className="p-2 pb-0 italic text-sw-disabled-text">To complete appointment select shipment card below.</span>
      <AppointmentList
        appointments={appointments}
        loadTypes={loadTypes}
        docks={docks}
        setSelectedAppointment={setSelectedAppointment}
      />
    </div>
  );
}
