import {FedexShipmentOptionsAllOfServiceCodeEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import snakeCase from 'lodash/snakeCase';

// adapted from https://github.com/shipwell/fedex-client/blob/9e20dc9a46cbeaf220673527f8d5b19ef599156e/fedex_client/common/enums.py#L122C1-L137C20
const FDXE_CODE_VALUES = [
  //express
  FedexShipmentOptionsAllOfServiceCodeEnum.Fedex2Day,
  FedexShipmentOptionsAllOfServiceCodeEnum.Fedex2DayAm,
  FedexShipmentOptionsAllOfServiceCodeEnum.FedexExpressSaver,
  FedexShipmentOptionsAllOfServiceCodeEnum.FirstOvernight,
  FedexShipmentOptionsAllOfServiceCodeEnum.PriorityOvernight,
  FedexShipmentOptionsAllOfServiceCodeEnum.StandardOvernight,
  FedexShipmentOptionsAllOfServiceCodeEnum.SameDay,
  FedexShipmentOptionsAllOfServiceCodeEnum.SameDayCity,
  //international
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalFirst,
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalPriority,
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalEconomy,
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalPriorityExpress,
  FedexShipmentOptionsAllOfServiceCodeEnum.EuropeFirstInternationalPriority,
  //other express
  'FEDEX_STANDARD_OVERNIGHT_EXTRA_HOURS',
  'FEDEX_PRIORITY_OVERNIGHT_EXTRA_HOURS',
  'FEDEX_FIRST_OVERNIGHT_EXTRA_HOURS',
  'FEDEX_INTERNATIONAL_CONNECT_PLUS',
  FedexShipmentOptionsAllOfServiceCodeEnum.FedexInternationalPriority,
  'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS'
];

const FDXG_CODE_VALUES = [
  FedexShipmentOptionsAllOfServiceCodeEnum.FedexGround,
  FedexShipmentOptionsAllOfServiceCodeEnum.GroundHomeDelivery
];

const FXSP_COEE_VALUES = [FedexShipmentOptionsAllOfServiceCodeEnum.SmartPost];

const FXFR_CODE_VALUES = [
  FedexShipmentOptionsAllOfServiceCodeEnum.FedexFreightEconomy,
  FedexShipmentOptionsAllOfServiceCodeEnum.FedexFreightPriority,
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalEconomyFreight,
  FedexShipmentOptionsAllOfServiceCodeEnum.InternationalPriorityFreight
];

export enum FedexCarrierCodes {
  FDXE = 'FDXE',
  FDXG = 'FDXG',
  FXSP = 'FXSP',
  FXFR = 'FXFR'
}

const fedexCodeMapping = {
  [FedexCarrierCodes.FDXE]: FDXE_CODE_VALUES,
  [FedexCarrierCodes.FDXG]: FDXG_CODE_VALUES,
  [FedexCarrierCodes.FXSP]: FXSP_COEE_VALUES,
  [FedexCarrierCodes.FXFR]: FXFR_CODE_VALUES
};

const matchFedexCarrierCode = (serviceLevel: string) =>
  Object.keys(fedexCodeMapping).find((key) =>
    fedexCodeMapping[key as keyof typeof fedexCodeMapping].some((value) => value === serviceLevel)
  );

// Fedex is the onlly carrier who uses carrier code
export const getFedexCarrierCodeFromServiceLevel = (serviceLevel: string) =>
  matchFedexCarrierCode(snakeCase(serviceLevel.toLowerCase()).toUpperCase());
