import {
  ShipmentsApi,
  CreateShipmentTimelineEvent,
  CreateShipment,
  UpdateShipmentSummary,
  CreateContact,
  UpdateContactDetail,
  UpdateShipmentReferences,
  UpdateStop,
  DrayageShipmentModeSpecificData,
  CreateLegacyShipmentStage,
  AssociateLegToShipmentRequest,
  AssociateServiceToShipmentRequest,
  ShipmentTimelineEvent,
  StageTimelineEvent,
  UpdateShipmentStageDependenciesRequest
} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';

const shipmentsApi = new ShipmentsApi(configuration);
export function getShipmentTransportationOrders(shipmentId: string) {
  return shipmentsApi.getDrayageTransportationOrdersAssociatedToShipmentShipmentsShipmentIdTransportationOrdersDrayageGet(
    {shipmentId}
  );
}

export function createShipmentTimelineEvent(shipmentId: string, timelineEvent: CreateShipmentTimelineEvent) {
  return shipmentsApi.createTimelineEventShipmentsShipmentIdTimelineEventsPost({
    shipmentId,
    createShipmentTimelineEvent: timelineEvent
  });
}

export function getShipment(shipmentId: string) {
  return shipmentsApi.getShipmentShipmentsShipmentIdGet({shipmentId});
}

export function getShipmentTimeline(shipmentId: string) {
  return shipmentsApi.getShipmentTimelineEventsShipmentsShipmentIdTimelineEventsSummaryGet({shipmentId});
}

export function getShipmentDocuments(shipmentId: string) {
  return shipmentsApi.getShipmentDocumentsShipmentsShipmentIdDocumentsGet({shipmentId});
}

export function getLegsFromShipment(shipmentId: string) {
  return shipmentsApi.getLegsAssociatedToShipmentShipmentsShipmentIdLegsGet({shipmentId});
}

export function deleteShipmentDocument(shipmentId: string, documentId: string) {
  return shipmentsApi.deleteShipmentDocumentShipmentsShipmentIdDocumentsDocumentIdDelete({shipmentId, documentId});
}

export function uploadShipmentDocument(shipmentId: string, file: File, description: string, type: string) {
  return shipmentsApi.uploadShipmentDocumentsShipmentsShipmentIdDocumentsPost({shipmentId, file, description, type});
}

export function updateShipmentDocument(shipmentId: string, documentId: string, description: string, type: string) {
  return shipmentsApi.updateShipmentDocumentShipmentsShipmentIdDocumentsDocumentIdPut({
    shipmentId,
    documentId,
    description,
    type
  });
}

export function generateShipmentBOL(shipmentId: string) {
  return shipmentsApi.generateBolFromShipmentShipmentsShipmentIdDocumentsGenerateBolPost({shipmentId});
}

export function deleteShipmentTimelineEvent(shipmentId: string, eventId: string) {
  return shipmentsApi.deleteTimelineEventShipmentsShipmentIdTimelineEventsTimelineEventIdDelete({
    shipmentId,
    timelineEventId: eventId
  });
}

export function getShipmentTrackingSummary(shipmentId: string) {
  return shipmentsApi.getTrackingSummaryShipmentsShipmentIdTrackingSummaryGet({shipmentId});
}

export function updateShipmentLegsStops(shipmentId: string) {
  return shipmentsApi.updateLegStopsShipmentsShipmentIdUpdateLegStopsPut({shipmentId});
}

export function createShipment(data: CreateShipment) {
  return shipmentsApi.createShipmentShipmentsPost({createShipment: data});
}

export function createLegacyShipmentStage(
  shipmentId: string,
  createLegacyShipmentStagePayload: CreateLegacyShipmentStage
) {
  return shipmentsApi.createLegacyShipmentStageShipmentsShipmentIdLegacyShipmentStagesPost({
    shipmentId,
    createLegacyShipmentStage: createLegacyShipmentStagePayload
  });
}

export function assignServiceToShipment(
  shipmentId: string,
  serviceId: string,
  associateServiceToShipmentRequest: AssociateServiceToShipmentRequest
) {
  return shipmentsApi.assignServiceToShipmentShipmentsShipmentIdServicesServiceIdPost({
    shipmentId,
    serviceId,
    associateServiceToShipmentRequest
  });
}

export function updateShipmentSummary(shipmentId: string, data: UpdateShipmentSummary) {
  return shipmentsApi.updateShipmentSummaryShipmentsShipmentIdSummaryPut({shipmentId, updateShipmentSummary: data});
}

export function createShipmentContact(shipmentId: string, contact: CreateContact) {
  return shipmentsApi.createContactsShipmentsShipmentIdContactsPost({shipmentId, createContact: contact});
}

export function updateShipmentContact(shipmentId: string, contactId: string, contact: UpdateContactDetail) {
  return shipmentsApi.updateShipmentContactDetailShipmentsShipmentIdContactsContactIdPut({
    shipmentId,
    contactId,
    updateContactDetail: contact
  });
}

export function deleteShipmentContact(shipmentId: string, contactId: string) {
  return shipmentsApi.deleteShipmentContactShipmentsShipmentIdContactsContactIdDelete({shipmentId, contactId});
}

export function updateShipmentReferences(shipmentId: string, data: UpdateShipmentReferences) {
  return shipmentsApi.updateShipmentReferencesShipmentsShipmentIdReferencesPut({
    shipmentId,
    updateShipmentReferences: data
  });
}

export function updateShipmentStop(shipmentId: string, sequenceId: number, data: UpdateStop) {
  return shipmentsApi.updateShipmentStopsDetailShipmentsShipmentIdStopsSequenceIdPut({
    shipmentId,
    sequenceId,
    updateStop: data
  });
}

export function updateShipmentStops(shipmentId: string, data: {stops: UpdateStop[]}) {
  return shipmentsApi.updateShipmentStopsShipmentsShipmentIdStopsPut({shipmentId, updateStopsRequest: data});
}

export function updateDrayageSpecificData(shipmentId: string, data: DrayageShipmentModeSpecificData) {
  return shipmentsApi.updateModeSpecificDataDrayageShipmentsShipmentIdModeSpecificDataDrayagePut({
    shipmentId,
    drayageShipmentModeSpecificData: data
  });
}

export function deleteShipmentStop(shipmentId: string, sequenceId: number) {
  return shipmentsApi.deleteShipmentStopDetailShipmentsShipmentIdStopsSequenceIdDelete({shipmentId, sequenceId});
}

export function getShipmentStages(shipmentId: string) {
  return shipmentsApi.listShipmentStagesShipmentsShipmentIdStagesGet({shipmentId});
}

export function getStagesAssociatedToResources(resourceIds: string[]) {
  return shipmentsApi.getStagesShipmentsStagesGet({transportationDetailIds: resourceIds});
}

export function assignLegToShipment(shipmentId: string, legId: string, data: AssociateLegToShipmentRequest) {
  return shipmentsApi.associateLegToShipmentShipmentsShipmentIdLegsLegIdPost({
    shipmentId,
    legId,
    associateLegToShipmentRequest: data
  });
}

// We augment the nested leg and service events (StageTimelineEventType) with their respective ids,
// so define a type to describe a single event in the timeline summary.
export type ShipmentTimelineSummaryEvent =
  | (ShipmentTimelineEvent & {leg_id?: null; service_id?: null})
  | (StageTimelineEvent & {leg_id: string; service_id: string});

export function deleteServiceStage(shipmentId: string, stageId: string) {
  return shipmentsApi.deleteShipmentStageShipmentsShipmentIdStagesStageIdDelete({shipmentId, stageId});
}

export function updateShipmentStageDependencies(
  shipmentId: string,
  newStageDependenciesSequence: UpdateShipmentStageDependenciesRequest
) {
  return shipmentsApi.updateShipmentStageDependenciesShipmentsShipmentIdStagesDependenciesPut({
    shipmentId,
    updateShipmentStageDependenciesRequest: newStageDependenciesSequence
  });
}
