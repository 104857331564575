import {Card, FormikTextInput, FormikSelect, FormikTextarea, FormikPhoneInput} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {preferredContactMethod} from './utils';

const ReferencesEquipmentForm = () => {
  return (
    <Card title="References" draggableProvided={false} bodyClassName="p-4 empty:p-0" data-testid="references-content">
      <div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <Field label="Freight Partner" name="freight_partner" component={FormikTextInput} />
          <Field label="Shipper" name="shipper" component={FormikTextInput} />
        </div>
        <div className="mb-4">
          <Field label="Carrier Notes" name="notes" component={FormikTextarea} />
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <Field label="Contact Email" name="contact.email" component={FormikTextInput} />
          <Field label="Contact Number" name="contact.phone" component={FormikPhoneInput} />
          <Field
            simpleValue
            searchable={false}
            clearable={false}
            label="Preferred Contact Method"
            name="contact.preferred_contact_method"
            component={FormikSelect}
            options={preferredContactMethod}
          />
        </div>
      </div>
    </Card>
  );
};

export default ReferencesEquipmentForm;
