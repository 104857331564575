import {DeprecatedButton, SvgIcon, Title} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {RfpBidResponse} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Link} from 'react-router';
import moment from 'moment';
import ImportModalAlpha from './ImportModalAlpha';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getRfpBids} from 'App/api/rfp/typed';
import Loader from 'App/common/shipwellLoader';
import useToggle from 'App/utils/hooks/useToggle';
import {RFP_BIDS_ALPHA_QUERY} from 'App/data-hooks/queryKeys';
import PageHeader from 'App/common/pageHeader';
import {EmptyTableSprinkles} from 'App/components/Table/components/EmptyListView';
import {FlexBox} from 'App/components/Box';
import {RFP_ALPHA_TEMPLATE} from 'App/containers/userCompany/constants';
import useInfiniteScroll from 'App/utils/hooks/useInfiniteScroll';

const CompanyRfpsBaseAlpha = ({setError}: Pick<WithStatusToastProps, 'setError'>) => {
  const [showImportModal, toggleShowImportModal] = useToggle();

  const {
    handleScroll,
    pages: rfpBidPages,
    isFetchingNextPage,
    isLoading
  } = useInfiniteScroll([RFP_BIDS_ALPHA_QUERY], getRfpBids);

  if (isLoading) {
    return <Loader loading />;
  }

  const isEmptyRfpBids = rfpBidPages[0].results?.length === 0;

  return (
    <div className="flex w-full flex-col">
      <PageHeader
        title="RFPs"
        actions={
          <FlexBox gap="m">
            <a href={RFP_ALPHA_TEMPLATE}>
              <DeprecatedButton variant="tertiary" icon={<SvgIcon name="Download" />}>
                Download CSV Template
              </DeprecatedButton>
            </a>
            <DeprecatedButton onClick={toggleShowImportModal} variant="tertiary" icon={<SvgIcon name="Upload" />}>
              Import
            </DeprecatedButton>
          </FlexBox>
        }
      ></PageHeader>
      {isEmptyRfpBids ? (
        <EmptyRfpsAlpha toggleModal={toggleShowImportModal} />
      ) : (
        <div onScroll={handleScroll} className="flex flex-col gap-4 overflow-auto p-4">
          {rfpBidPages.map((page) => page.results?.map((rfp) => <RfpBidAlpha key={rfp.id} rfp={rfp} />))}
          {isFetchingNextPage ? (
            <div className="flex flex-col items-center justify-center">
              <Loader loading />
              <span>Loading next page...</span>
            </div>
          ) : null}
        </div>
      )}
      {showImportModal ? (
        <ImportModalAlpha setError={setError} showModal={showImportModal} toggleModal={toggleShowImportModal} />
      ) : null}
    </div>
  );
};

const EmptyRfpsAlpha = ({toggleModal}: {toggleModal: () => void}) => {
  return (
    <div className="fixed left-[calc(50vw-200px)] top-[calc(30%-10px)] flex flex-col items-center">
      <EmptyTableSprinkles />
      <div className="mb-3 text-lg font-medium">No Data to Display</div>
      <div>
        <DeprecatedButton onClick={toggleModal} className="h-6" variant="primary">
          Import
        </DeprecatedButton>
      </div>
    </div>
  );
};

const RfpBidAlpha = ({rfp}: {rfp: RfpBidResponse}) => {
  return (
    <Link to={`/company/lane-management/rfps_import/${rfp.id || ''}`} key={rfp.id}>
      <Title
        variant="cardTitle"
        className="items-center justify-between rounded-md border border-sw-disabled bg-sw-background-component p-4 text-sm"
      >
        <div className="flex items-center">
          <img src="/images/attachment.svg" width="24px" height="24px" />
          <div className="ml-2">
            {rfp.name} ({moment(rfp.start_date).format('l')}-{moment(rfp.end_date).format('l')})
          </div>
        </div>
        <div>
          <SvgIcon name="ArrowLargeRight" />
        </div>
      </Title>
    </Link>
  );
};

export default compose<WithStatusToastProps, Record<string, never>>(withStatusToasts)(CompanyRfpsBaseAlpha);
