import {integrations, shipment, registration, documents} from 'App/api';

/**
 * Create FedEx Account
 */
export function createFedexRegistration(accountNumber, body = {}) {
  return (dispatch) => integrations.postFedexRegistration(accountNumber, body);
}

/**
 * Update FedEx Accounts
 * @param {Object} opts
 */
export function updateFedExRegistrationAccount(fedexAccountId, body = {}) {
  return (dispatch) => registration.updateFedExRegistrationAccount(fedexAccountId, body);
}

/**
 * Get FedEx Accounts
 * @param {Object} opts
 */
export function getFedExRegistrationAccounts(opts = {}) {
  return (dispatch) => registration.getFedExRegistrationAccounts(opts);
}

/**
 * Get Shipment Pickups
 * @param {Object} opts
 */
export function getShipmentPickups(opts = {}) {
  return (dispatch) => shipment.getShipmentPickups(opts);
}

/**
 * Get Shipment Pickup Details
 * @param {Number} pickupId
 */
export function getShipmentPickup(pickupId) {
  return (dispatch) => shipment.getShipmentPickup(pickupId);
}

/**
 * Create Shipment Pickup
 * @param {Object} opts
 */
export function createShipmentPickup(body = {}) {
  return (dispatch) => shipment.createShipmentPickup(body);
}

/**
 * Create Shipment Pickup
 * @param {Object} opts
 */
export function cancelShipmentPickup(pickupId) {
  return (dispatch) => shipment.cancelShipmentPickup(pickupId);
}

/**
 * Update Shipment Pickup
 * @param {Object} opts
 */
export function updateShipmentPickup(pickupId, body = {}) {
  return (dispatch) => shipment.updateShipmentPickup(pickupId, body);
}

/**
 * Fetch bulk labels for printing
 * @param {Object} opts
 */
export function getBulkShipmentLabels(shipments) {
  return (dispatch) => documents.getBulkShipmentLabels(shipments);
}
