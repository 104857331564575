import {Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import {DeprecatedButton, SvgIcon, Checkbox} from '@shipwell/shipwell-ui';
import SelectField from 'App/formComponents/fields/select';
import renderField from 'App/formComponents/renderField';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import AddressSearchField from 'App/formComponents/fields/addressSearch';

const renderMultipleTriumphPayAccounts = ({
  deleteTriumphPayAccount,
  fields,
  triumphPayAccount,
  triumphPay,
  meta: {error},
  handleShowPrivacyPolicy,
  formValues,
  initial,
  carrierDocuments,
  vendorName,
  handleSetDefaultAccount,
  setDefaultTriumphAccount,
  handleTriumphDisplay,
  paymentAccounts,
  setShowDefaultSuccessToast,
  setShowDeleteSuccessToast,
  displayValues,
  setDisplayValues,
  handleClearSelectedAccount,
  relationshipTriumphId,
  isEditMode,
  updateAccountEditArray,
  paymentProcessorUpdateEnabled,
  paymentProcessorCreateEnabled,
  ...rest
}) => (
  <>
    {fields.map(
      (account, index) =>
        formValues &&
        ((formValues.selected_account && paymentAccounts[index].id === formValues.selected_account.id) ||
          !paymentAccounts[index].id) && (
          <div className="vendors__triumph-container">
            <div className="vendors__triumph-header">
              {!isEditMode[index] && paymentProcessorUpdateEnabled && (
                <i
                  className={'flaticon-edit action-link margin-right pencil'}
                  onClick={() => updateAccountEditArray(index)}
                  title={'Edit this TriumphPay Account'}
                />
              )}
              {paymentProcessorUpdateEnabled && (
                <DeprecatedButton
                  variant="icon"
                  className="action-link showAsLink"
                  onClick={() => {
                    // delete TriumphPay account if saved
                    if (paymentAccounts[index].id) {
                      const newDisplayValues = displayValues.filter((acct) => acct.id !== paymentAccounts[index].id);
                      setDisplayValues(newDisplayValues);
                      deleteTriumphPayAccount(triumphPay.id, paymentAccounts[index].id).then((response) => {
                        if (response.status === 204) {
                          setShowDeleteSuccessToast(true);
                          handleClearSelectedAccount();
                        } else {
                          return error;
                        }
                      });
                    } else {
                      fields.remove(index);
                    }
                  }}
                  title={'Remove this TriumphPay Account'}
                >
                  <SvgIcon name="TrashOutlined" />
                </DeprecatedButton>
              )}
            </div>
            <Grid container spacing={8}>
              <Grid item md={3} sm={6} xs={12} key={index} className="vendors__triumph-pay">
                {formValues && paymentAccounts[index] && !paymentAccounts[index].id ? (
                  <Field
                    label="Payment Type"
                    inline
                    name={`triumphPay.triumph_payment_accounts[${index}].payment_type`}
                    component={SelectField}
                    clearable
                    options={[
                      {name: 'Bank Account', id: 'ACH_BANK'},
                      {name: 'Factoring Company', id: 'FACTOR'},
                      {name: 'Check', id: 'MAIL_CHECK'}
                    ]}
                    req
                  >
                    Payment Type
                  </Field>
                ) : (
                  <div className="vendors__triumph-pay_payment-type">
                    <b>Payment Type</b>
                    <p>{paymentAccounts[index].payment_type}</p>
                  </div>
                )}
              </Grid>
              {(paymentAccounts[index].payment_type === 'ACH_BANK' ||
                (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'ACH_BANK')) && (
                <>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].account_name`}
                        component={renderField}
                        placeholder="e.g., Wells Fargo Checking Account"
                        label="Account Name"
                        req={
                          paymentAccounts[index].payment_type === 'ACH_BANK' ||
                          (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'ACH_BANK')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Payment Type</b>
                        <p>{paymentAccounts[index].account_name}</p>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].bank_account_data.bank_routing_number`}
                        component={renderField}
                        placeholder="Routing #"
                        label="Routing #"
                        req={
                          paymentAccounts[index].payment_type === 'ACH_BANK' ||
                          (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'ACH_BANK')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Routing Number</b>
                        <p>--</p>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].bank_account_data.bank_account_number`}
                        component={renderField}
                        placeholder="Account #"
                        label="Account #"
                        req={
                          paymentAccounts[index].payment_type === 'ACH_BANK' ||
                          (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'ACH_BANK')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Account Number</b>
                        <p>--</p>
                      </div>
                    )}
                  </Grid>

                  <Grid item md={12} sm={12} xs={12} className="vendors__triumph_bank_info">
                    *We take precautions to protect your information. Your information is protected both online and
                    offline.
                    <a className="vendors__privacy_policy" onClick={() => handleShowPrivacyPolicy(true)}>
                      {' '}
                      Privacy Policy
                    </a>
                  </Grid>
                </>
              )}
              {paymentAccounts[index].payment_type === 'FACTOR' ||
              (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'FACTOR') ? (
                <>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].vendor_factor_company.company_name`}
                        component={renderField}
                        placeholder="Factoring Company Name"
                        label="Factoring Company Name"
                        req={
                          formValues &&
                          paymentAccounts[index] &&
                          (paymentAccounts[index].payment_type === 'FACTOR' ||
                            paymentAccounts[index].payment_type.id === 'FACTOR')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Factoring Company Name</b>
                        <p>
                          {paymentAccounts[factorCompanyIndex].vendor_factor_company &&
                            paymentAccounts[factorCompanyIndex].vendor_factor_company.company_name}
                        </p>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].vendor_factor_company.address`}
                        label="Factor Company Address"
                        component={AddressSearchField}
                        addr={
                          formValues &&
                          paymentAccounts[index].vendor_factor_company &&
                          paymentAccounts[index].vendor_factor_company.formatted_address
                        }
                        req={
                          paymentAccounts[index].payment_type === 'FACTOR' ||
                          (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'FACTOR')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Factoring Company Address</b>
                        <p>
                          {paymentAccounts[index].vendor_factor_company &&
                            paymentAccounts[factorCompanyIndex].vendor_factor_company.address &&
                            paymentAccounts[factorCompanyIndex].vendor_factor_company.address.formatted_address}
                        </p>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        clearable
                        labelKey="filename"
                        valueKey="id"
                        name={`triumphPay.triumph_payment_accounts[${index}].notice_of_assignment_document`}
                        component={SelectField}
                        placeholder="Notice of Assignment"
                        label="Notice of Assignment"
                        simpleValue
                        options={carrierDocuments.results}
                        req
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Notice of Assignment</b>
                        <p>--</p>
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {paymentAccounts[index].payment_type === 'MAIL_CHECK' ||
              (paymentAccounts[index].payment_type && paymentAccounts[index].payment_type.id === 'MAIL_CHECK') ? (
                <>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name="vendor.shipwell_vendor.name"
                        disabled
                        component={renderField}
                        placeholder="Mail To Name"
                        label="Mail To Name"
                        value={vendorName}
                        req={
                          paymentAccounts[index].payment_type === 'MAIL_CHECK' ||
                          (paymentAccounts[index].payment_type &&
                            paymentAccounts[index].payment_type.id === 'MAIL_CHECK')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Mail To Name</b>
                        <p>{vendorName}</p>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                    {isEditMode[index] || !paymentAccounts[index].id ? (
                      <Field
                        name={`triumphPay.triumph_payment_accounts[${index}].vendor_billing_address`}
                        label="Mail To Address"
                        component={AddressSearchField}
                        addr={
                          formValues &&
                          paymentAccounts[index].vendor_billing_address &&
                          paymentAccounts[index].vendor_billing_address.formatted_address
                        }
                        req={
                          paymentAccounts[index].payment_type === 'MAIL_CHECK' ||
                          (paymentAccounts[index].payment_type &&
                            paymentAccounts[index].payment_type.id === 'MAIL_CHECK')
                        }
                      />
                    ) : (
                      <div className="vendors__triumph-pay_payment-type">
                        <b>Mail To Address</b>
                        <p>
                          {paymentAccounts[index].vendor_billing_address &&
                            paymentAccounts[index].vendor_billing_address.formatted_address}
                        </p>
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {isEditMode[index] && paymentAccounts[index].id ? (
                <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                  <Field
                    className="vendors__triumph_bank_info__default"
                    req={false}
                    name={`triumphPay.triumph_payment_accounts[${index}].is_default`}
                    label="Default Account"
                    component={Checkbox}
                    checked={paymentAccounts[index].is_default}
                    onClick={() => {
                      setDefaultTriumphAccount(triumphPay.id, paymentAccounts[index].id).then((response) => {
                        if (response.status === 200) {
                          setShowDefaultSuccessToast(true);
                          handleSetDefaultAccount([index]);
                        } else {
                          return error;
                        }
                      });
                    }}
                  />
                </Grid>
              ) : paymentAccounts[index].id ? (
                <div className="vendors__triumph-pay_payment-type margin-top-default-acct">
                  <b>Is Default</b>
                  <p>{paymentAccounts[index].is_default ? 'Yes' : 'No'}</p>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </div>
        )
    )}

    {error && (
      <p className="error-text-form-level pad-top">
        <i className="icon icon-Delayed pad-right" />
        {error}
      </p>
    )}

    {((paymentAccounts &&
      !paymentAccounts.find((acct) => {
        return acct.payment_type === 'FACTOR';
      })) ||
      (!paymentAccounts && relationshipTriumphId)) &&
      paymentProcessorCreateEnabled && (
        <div className="vendors__add_triumph_account-wrapper">
          <a role="button" className="vendors__add_triumph_account" onClick={() => fields.push({})}>
            <i className="flaticon-plus pad-right" /> Add TriumphPay Account
          </a>
        </div>
      )}
  </>
);

export default renderMultipleTriumphPayAccounts;
