import {Tag} from '@shipwell/corrogo-sdk';
import {createColumnHelper} from '@tanstack/react-table';
import moment from 'moment';
import {ActionsCell} from './actionsCell/actionsCell';
import {StatusCell} from './statusCell/statusCell';
import {TagNameCell} from './tagNameCell/tagNameCell';

const columnHelper = createColumnHelper<Tag>();

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Tag Name',
    cell: (info) => <TagNameCell name={info.getValue()} color={info.row.getValue<string>('color')} />,
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('color', {
    id: 'color',
    header: 'Color Code',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('created_at', {
    id: 'created_by',
    header: 'Created',
    cell: (info) => moment(info.getValue()).format('MM/DD/YYYY hh:mm A zz'),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: (info) => <StatusCell status={info.getValue()} />,
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Actions',
    size: 40,
    cell: (info) => <ActionsCell tagId={info.cell.getValue()} status={info.row.getValue('status')} />,
    footer: (info) => info.column.id,
    enableSorting: false
  })
];
