import invariant from 'tiny-invariant';
import {Banner} from '@shipwell/shipwell-ui';
import {ReactNode, createContext, useContext} from 'react';
import Loader from 'App/common/shipwellLoader';
import {useUserMe} from 'App/data-hooks';

const CompanyIdContext = createContext<string | null>(null);

export const CompanyIdProvider = ({children}: {children: ReactNode}) => {
  const {data, error} = useUserMe();

  if (data) {
    const companyId = data.company?.id;
    invariant(companyId, 'No company id found');
    return <CompanyIdContext.Provider value={companyId}>{children}</CompanyIdContext.Provider>;
  }

  if (error) {
    <Banner title="Error">There was an error getting your company information.</Banner>;
  }

  return <Loader loading />;
};

export const useCompanyIdContext = () => {
  const companyId = useContext(CompanyIdContext);
  invariant(companyId, 'No company id found');
  return companyId;
};
