import {DisplayValue, Modal, Title, FormikCounter, Button} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {AddToDeliveredDashboardDetails} from '@shipwell/settlements-sdk';
import {useUpdateManyDaysToDeliveredDashboardConfiguration} from 'App/api/settlements/queryHooks';
import {AddToDeliveredDashboardConfiguration} from 'App/api/settlements/typeGuards';

type InitialValues = {days_to_wait: number};

export const DaysToDeliveredDashboardEditModal = ({
  showModal,
  onClose,
  title,
  configurations
}: {
  showModal: boolean;
  onClose: () => void;
  title: string;
  configurations: AddToDeliveredDashboardConfiguration[];
}) => {
  // regarding [0] access below: all these configs will be updated together,
  // so they should all stay in sync, but I don't like making this assumption.
  const details: AddToDeliveredDashboardDetails | undefined = configurations[0]?.details; // it might be an empty array
  const initialValues = {days_to_wait: details?.days_to_wait || 0};

  const {mutate: updateManyDaysToDelivered, isLoading} = useUpdateManyDaysToDeliveredDashboardConfiguration();
  const ids = configurations.map((config) => config.id);

  const handleSubmit = ({days_to_wait}: InitialValues) => {
    updateManyDaysToDelivered(
      {ids, rule_type: 'ADD_TO_DELIVERED_DASHBOARD', days_to_wait},
      {
        onSuccess() {
          onClose();
        }
      }
    );
  };

  return (
    <Modal show={showModal} onClose={onClose} title={title} footerComponent={null} portal>
      <p className="mb-4">
        Once a shipment has been marked as delivered, you can configure how many business days until it is displayed on
        the settlements &lsquo;Delivered&rsquo; dashboard.
      </p>

      <Title className="pb-4">Delivered Shipment Details</Title>

      <DisplayValue className="mb-6" label="Trigger">
        Shipment status is delivered but no invoice has been received.
      </DisplayValue>

      <DisplayValue className="mb-6" label="Action">
        Shipment will be added to the Settlements ‘Delivered’ dashboard until invoice is received or generated.
      </DisplayValue>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <DisplayValue className="mb-6" label="Threshold">
            <div className="flex items-center gap-2">
              <Field name="days_to_wait" component={FormikCounter} />
              <span>Business days after delivery</span>
            </div>
          </DisplayValue>
          <div className="flex justify-end gap-4 pt-4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button isLoading={isLoading} variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
