import classNames from 'classnames';
import {ReactNode} from 'react';

type FixedTableCellProps = {
  children?: ReactNode;
  isFixed: true;
  rowIndex: number;
  isSelected?: boolean;
};

type StandardTableCellProps = {
  children?: ReactNode;
  isFixed?: false;
  rowIndex?: number;
  isSelected?: boolean;
};
export const TableCell = ({
  children,
  isFixed = false,
  rowIndex,
  isSelected = false
}: FixedTableCellProps | StandardTableCellProps) => {
  const isOdd = typeof rowIndex === 'number' ? rowIndex % 2 === 1 : false;
  return (
    <td
      className={classNames('p-2 z-0', {
        'sticky left-0 border-r-2 border-sw-border-light': isFixed,
        'bg-sw-background': isFixed && !isOdd && !isSelected,
        'bg-sw-background-component': isFixed && isOdd && !isSelected,
        'bg-sw-active-light-hex': isSelected
      })}
    >
      {children}
    </td>
  );
};
