import {
  CarrierRelationship,
  CarrierRelationshipsApi,
  CarrierRelationshipsApiCarrierRelationshipsGetRequest,
  FMCSACarrierPolicy,
  RMISCarrierPolicy,
  SaferWatchCarrierPolicy,
  CarrierRelationshipsApiCarrierComplianceIntegrationsFmcsaPolicyPutRequest,
  CarrierRelationshipsApiCarrierComplianceIntegrationsRmisPolicyRmisPolicyIdPutRequest,
  CarrierRelationshipsApiCarrierComplianceIntegrationsSaferwatchPolicyPutRequest,
  ShipwellUiApi,
  CheckCarrierInsuranceResult,
  CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdExclusionRulesCarrierExclusionRuleIdPutRequest,
  CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdExclusionRulesPostRequest,
  CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdDocumentsPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {configuration} from './configuration';

const carrierRelationshipApi = new CarrierRelationshipsApi(configuration);
const shipwellUiApi = new ShipwellUiApi(configuration);

export function getCarrierRelationshipsPromise(request: CarrierRelationshipsApiCarrierRelationshipsGetRequest = {}) {
  return carrierRelationshipApi.carrierRelationshipsGet(request);
}

export function getCarrierRelationshipsByIdPromise(carrierRelationshipId: string) {
  return carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdGet({carrierRelationshipId});
}

export async function getCarrierExclusionRulesByIdPromise(carrierRelationshipId: string) {
  const axiosResponse = await carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdExclusionRulesGet({
    carrierRelationshipId
  });

  return axiosResponse.data;
}

export async function updateCarrierExclusionRulesByIdPromise({
  carrierRelationshipId,
  carrierExclusionRuleId,
  carrierExclusionRule
}: CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdExclusionRulesCarrierExclusionRuleIdPutRequest) {
  const axiosResponse =
    await carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdExclusionRulesCarrierExclusionRuleIdPut({
      carrierRelationshipId,
      carrierExclusionRuleId,
      carrierExclusionRule
    });

  return axiosResponse.data;
}

export async function createCarrierExclusionRulesByIdPromise({
  carrierRelationshipId,
  carrierExclusionRule
}: CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdExclusionRulesPostRequest) {
  const axiosResponse = await carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdExclusionRulesPost({
    carrierRelationshipId,
    carrierExclusionRule
  });

  return axiosResponse.data;
}

export function updateCarrierRelationship(carrierRelationshipId: string, carrierRelationship: CarrierRelationship) {
  return carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdPut({
    carrierRelationshipId,
    carrierRelationship
  });
}

export function getCarrierRelationshipsDocuments(carrierRelationshipId: string) {
  return carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdDocumentsGet({carrierRelationshipId});
}
/**
 * Gets the RMIS carrier compliance policy applied to all carriers in the authenticated user's organization.
 * @returns {RMISCarrierPolicy}
 * @throws {ShipwellError}
 */
export async function getRMISCarrierCompliancePolicy(): Promise<RMISCarrierPolicy> {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsRmisPolicyGet();
  return data;
}

/**
 * Gest the FMCSA carrier compliance policy applied to all carriers in the authenticated user's organization.
 * @returns {FMCSACarrierPolicy}
 * @throws {ShipwellError}
 */
export async function getFMCSACarrierCompliancePolicy(): Promise<FMCSACarrierPolicy> {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsFmcsaPolicyGet();
  return data;
}

/**
 * Gets the SaferWatch carrier compliance policy applied to all carriers in the authenticated user's organization.
 * @returns {SaferWatchCarrierPolicy}
 * @throws {ShipwellError}
 */
export async function getSaferwatchCarrierCompliancePolicy(): Promise<SaferWatchCarrierPolicy> {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsSaferwatchPolicyGet();
  return data;
}

export async function setFMCSACarrierCompliancePolicy(
  policy: CarrierRelationshipsApiCarrierComplianceIntegrationsFmcsaPolicyPutRequest
): Promise<FMCSACarrierPolicy> {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsFmcsaPolicyPut(policy);
  return data;
}

export async function setRMISCarrierCompliancePolicy(
  policy: CarrierRelationshipsApiCarrierComplianceIntegrationsRmisPolicyRmisPolicyIdPutRequest
) {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsRmisPolicyRmisPolicyIdPut(policy);
  return data;
}

export async function setSaferwatchCarrierCompliancePolicy(
  policy: CarrierRelationshipsApiCarrierComplianceIntegrationsSaferwatchPolicyPutRequest
) {
  const {data} = await carrierRelationshipApi.carrierComplianceIntegrationsSaferwatchPolicyPut(policy);
  return data;
}

export async function checkCarrierInsurance(shipmentId: string, carrierRelationshipId: string) {
  const {data} =
    (await shipwellUiApi.shipwellUiShipmentShipmentIdCarrierRelationshipCarrierRelationshipIdCheckCarrierInsuranceGet({
      carrierRelationshipId,
      shipmentId
      // Typecasting because the response is just one object and not an array of them.
    })) as {data: CheckCarrierInsuranceResult};
  return data;
}

export function carrierRelationshipsCarrierRelationshipIdDocumentsPost(
  params: CarrierRelationshipsApiCarrierRelationshipsCarrierRelationshipIdDocumentsPostRequest
) {
  const transformRequest = (data: {description: string; file: File[]}) => {
    const formData = new FormData();
    formData.append('description', data.description);
    formData.append('file', data.file[0] || data.file);
    return formData;
  };

  return carrierRelationshipApi.carrierRelationshipsCarrierRelationshipIdDocumentsPost(params, {
    transformRequest: [transformRequest]
  });
}
