import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {
  ShipmentsApiShipmentsPickupsGetRequest,
  PaginatedShipmentPickup,
  ShipmentPickup,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SHIPMENT_PICKUPS, SHIPMENT_PICKUP} from '../queryKeys';
import {getShipmentPickups, getShipmentPickup} from 'App/api/shipment/typed';

// get a list of shipment pickups
export const useGetShipmentPickups = (
  requestParameters: ShipmentsApiShipmentsPickupsGetRequest,
  queryOptions?: UseQueryOptions<
    PaginatedShipmentPickup,
    ShipwellError,
    PaginatedShipmentPickup,
    Array<string | ShipmentsApiShipmentsPickupsGetRequest>
  >
) => {
  const query = useQuery([SHIPMENT_PICKUPS, requestParameters], () => getShipmentPickups(requestParameters), {
    ...queryOptions
  });

  return query;
};

// get shipment pickup by id
export const useGetShipmentPickup = (
  shipmentPickupId: string,
  queryOptions?: UseQueryOptions<ShipmentPickup, ShipwellError, ShipmentPickup, string[]>
) => {
  const query = useQuery([SHIPMENT_PICKUP, shipmentPickupId], () => getShipmentPickup(shipmentPickupId), {
    ...queryOptions
  });

  return query;
};
