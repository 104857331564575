import {useState} from 'react';
import {Button, Dropdown, Loader, Modal} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import {ShipmentDocumentMetadataTypeEnum, ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useBulkActionsParcelPickup} from './hooks/useBulkActionsParcelPickup';
import {useOpusBulkActions} from './hooks/useOpusBulkActions';
import {
  BulkActionLineItemButton,
  BulkActionDivider
} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton';
import {
  CurrencyOfRecordTooltipContent,
  NonParcelShipmentsTooltipContent,
  ParcelMismatchedShipmentsTooltipContent
} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton/TooltipContent';
import ChangeShipmentStatus from 'App/formComponents/forms/changeShipmentStatus';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {
  BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS,
  BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES,
  BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE,
  BULK_OPERATION_TYPE_TENDER_TO_CARRIER,
  EMAIL_DOCUMENT,
  PRINT_DOCUMENT
} from 'App/containers/Dashboard/utils/constants';
import {useGetShipmentsDownloadCsvMutation} from 'App/containers/Dashboard/components/ShipmentsCsvDownload/hooks/useGetShipmentsDownloadCsvMutation';
import useToggle from 'App/utils/hooks/useToggle';
import EditShipmentDates from 'App/formComponents/forms/editShipmentDates';
import ShipmentDocument from 'App/containers/Dashboard/components/ShipmentDocument';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import {ROUTING_GUIDE_SUCCESS_ON_EXECUTION} from 'App/containers/workflows/workflowConstants';
import {isSamePreferredCurrency} from 'App/containers/Dashboard/components/BulkActions/Dropdown/DropdownLineItemButton/utils';
import BulkTenderRequestForm from 'App/containers/Dashboard/components/BulkActions/bulkTenderRequest';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {
  CREATE_SHIPMENTS_USER_PERMISSION,
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_INITIATE_ROUTING_GUIDE
} from 'App/components/permissions/PermissionsFallback/constants';
import {useShipmentsRoundTrips} from 'App/data-hooks/shipments/useShipmentsRoundTrips';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import SchedulePickup from 'App/components/schedulePickup';
import SchedulePickupForm from 'App/formComponents/forms/schedulePickup';
import {useUserMe} from 'App/data-hooks';

enum ModalKindEnums {
  changeShipmentStatus = 'CHANGE_SHIPMENT_STATUS',
  downloadShipments = 'DOWNLOAD_SHIPMENTS',
  editShipmentDates = 'EDIT_SHIPMENT_DATES',
  pushToRoutingGuide = 'PUSH_TO_ROUTING_GUIDE',
  tenderToCarrier = 'TENDER_TO_CARRIER',
  emailBol = 'EMAIL_BOL',
  printBol = 'PRINT_BOL',
  printShippingLabel = 'PRINT_SHIPPING_LABEL',
  cancelShipment = 'CANCEL_SHIPMENT',
  schedulePickup = 'SCHEDULE_PICKUP',
  createPickup = 'CREATE_PICKUP'
}

const modalTitles = {
  [ModalKindEnums.changeShipmentStatus]: 'Change Shipment Status',
  // downloadShipments does not get a modal, but needs a value here to silence TS
  [ModalKindEnums.downloadShipments]: '',
  [ModalKindEnums.editShipmentDates]: 'Edit Shipment Dates',
  [ModalKindEnums.pushToRoutingGuide]: 'Push to Routing Guide',
  [ModalKindEnums.tenderToCarrier]: 'Tender to Carrier',
  [ModalKindEnums.emailBol]: 'Email BOLs',
  [ModalKindEnums.printBol]: 'Print BOLs',
  [ModalKindEnums.printShippingLabel]: 'Print Shipping Labels',
  [ModalKindEnums.cancelShipment]: 'Cancel Shipment',
  [ModalKindEnums.schedulePickup]: 'Schedule Pickup',
  [ModalKindEnums.createPickup]: 'Create Pickup'
};

export const ShipmentBulkActionsDropdown = WithStatusToasts(
  ({
    selectedShipments,
    onSuccess,
    setSuccess,
    setError,
    setWarning,
    toggleShowImportShipmentModal,
    columnExportIds
  }: {
    selectedShipments: ShippingDashboardRow[];
    onSuccess?: () => void;
    toggleShowImportShipmentModal: () => void;
    columnExportIds: string[];
  } & WithStatusToastProps) => {
    const selectedShipmentIds = selectedShipments.map((shipment) => shipment.id);
    const [modalKind, setModalKind] = useState<ModalKindEnums>();
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [performingBulkAction, togglePerformingBulkAction] = useToggle();
    const {wfaBulkActionShipmentStatusChange, wfaBulkActions} = useFlags<{
      wfaBulkActions: boolean;
      wfaBulkActionShipmentStatusChange: boolean;
    }>();
    const {data: {company: {feature_flags: featureFlags = {}} = {}} = {}} = useUserMe();
    const {fedex_enabled: fedexEnabled, ups_enabled: upsEnabled, usps_enabled: uspsEnabled} = featureFlags;
    const isParcelEnabled = !!(fedexEnabled || upsEnabled || uspsEnabled);

    const handleSuccess = () => {
      setModalKind(undefined);
      onSuccess?.();
    };

    // Shipment round trips
    const {roundTripsMutation} = useShipmentsRoundTrips({
      setSuccess,
      setError,
      setWarning,
      setLoadingMessage,
      onSuccess
    });

    // Download shipments
    const {mutate: downloadShipments} = useGetShipmentsDownloadCsvMutation({
      onMutate: () => setLoadingMessage('Downloading CSV...'),
      onSettled: () => setLoadingMessage(undefined),
      handleSuccess: onSuccess,
      setError
    });

    // Opus bulk actions
    const {pollBulkOperation, cancelBulkShipments, archiveBulkShipments} = useOpusBulkActions({
      setSuccess,
      setError,
      selectedShipments
    });

    // Parcel schedule pickups
    const {
      handleCreateShipmentPickup,
      handleUpdateShipmentPickup,
      providerCode,
      containsParcel,
      isSchedulePickupDisabled,
      createPickupFormValues
    } = useBulkActionsParcelPickup({selectedShipmentIds, selectedShipments, setError});

    if (selectedShipments.length === 0) {
      return (
        <PermissionsFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
          <Button
            isCompact
            variant="tertiary"
            iconName="AddCircleOutlined"
            onClick={() => toggleShowImportShipmentModal()}
          >
            Import
          </Button>
        </PermissionsFallback>
      );
    }

    return (
      <>
        <Dropdown title="Shipment Bulk Actions" indicator variant="primary">
          {({onClick}: {onClick: () => void}) => [
            <BulkActionLineItemButton
              key={ModalKindEnums.changeShipmentStatus}
              isDisabled={containsParcel}
              isVisible={wfaBulkActionShipmentStatusChange}
              onClick={() => {
                onClick();
                setModalKind(ModalKindEnums.changeShipmentStatus);
              }}
              disabledReason={NonParcelShipmentsTooltipContent('changeShipmentStatus')}
            >
              Change Shipment Status
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="createRoundTrips"
              onClick={() => {
                onClick();
                roundTripsMutation.mutate(selectedShipmentIds);
              }}
              isDisabled={containsParcel}
              disabledReason={NonParcelShipmentsTooltipContent('createRoundTrips')}
              isVisible
            >
              Create Round Trips
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key={ModalKindEnums.downloadShipments}
              onClick={() => {
                downloadShipments({shipmentIds: selectedShipmentIds, columnNames: columnExportIds});
                setModalKind(undefined);
              }}
              isDisabled={false}
              isVisible
            >
              Download Shipments
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="editShipmentDates"
              isDisabled={containsParcel}
              isVisible={wfaBulkActions}
              onClick={() => {
                setModalKind(ModalKindEnums.editShipmentDates);
                togglePerformingBulkAction();
                onClick();
              }}
              disabledReason={NonParcelShipmentsTooltipContent('editShipmentDates')}
            >
              Edit Shipment Dates
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="emailBol"
              isVisible={wfaBulkActions}
              isDisabled={false}
              onClick={() => {
                setModalKind(ModalKindEnums.emailBol);
                onClick();
              }}
            >
              Email BOL
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="printBol"
              isVisible={wfaBulkActions}
              isDisabled={false}
              onClick={() => {
                setModalKind(ModalKindEnums.printBol);
                onClick();
              }}
            >
              Print BOL
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="printShippingLabel"
              isVisible={wfaBulkActions}
              isDisabled={false}
              onClick={() => {
                setModalKind(ModalKindEnums.printShippingLabel);
                onClick();
              }}
            >
              Print Shipping Label
            </BulkActionLineItemButton>,
            <TenderingUserPermissionFallback permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]} key="pushToRoutingGuide">
              <BulkActionLineItemButton
                key="pushToRoutingGuide"
                isVisible={wfaBulkActions}
                isDisabled={containsParcel}
                onClick={() => {
                  setModalKind(ModalKindEnums.pushToRoutingGuide);
                  togglePerformingBulkAction();
                  onClick();
                }}
                disabledReason={NonParcelShipmentsTooltipContent('pushToRoutingGuide')}
              >
                Push to Routing Guide
              </BulkActionLineItemButton>
            </TenderingUserPermissionFallback>,
            <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]} key="tenderToCarrier">
              <BulkActionLineItemButton
                isVisible={wfaBulkActions}
                isDisabled={!isSamePreferredCurrency(selectedShipments) || containsParcel}
                onClick={() => {
                  setModalKind(ModalKindEnums.tenderToCarrier);
                  togglePerformingBulkAction();
                  onClick();
                }}
                key="tenderToCarrier"
                disabledReason={
                  !isSamePreferredCurrency(selectedShipments)
                    ? CurrencyOfRecordTooltipContent()
                    : containsParcel
                    ? NonParcelShipmentsTooltipContent('tenderToCarrier')
                    : undefined
                }
              >
                Tender to Carrier
              </BulkActionLineItemButton>
            </TenderingUserPermissionFallback>,
            <BulkActionLineItemButton
              isVisible={isParcelEnabled}
              isDisabled={isSchedulePickupDisabled}
              onClick={() => {
                setModalKind(ModalKindEnums.schedulePickup);
                onClick();
              }}
              key="schedulePickup"
              disabledReason={ParcelMismatchedShipmentsTooltipContent('schedulePickup')}
            >
              Schedule Pickup
            </BulkActionLineItemButton>,
            <BulkActionDivider key="divider" />,
            <BulkActionLineItemButton
              isVisible={wfaBulkActions}
              isDisabled={false}
              onClick={() => {
                togglePerformingBulkAction();
                void archiveBulkShipments(selectedShipmentIds);
                onClick();
              }}
              key="archiveShipment"
            >
              Archive {pluralize('Shipment', selectedShipmentIds.length)}
            </BulkActionLineItemButton>,
            <BulkActionLineItemButton
              key="cancelShipment"
              isVisible
              isDisabled={false}
              onClick={() => {
                togglePerformingBulkAction();
                void cancelBulkShipments(selectedShipmentIds);
                onClick();
              }}
            >
              <span className="text-sw-destroy">Cancel {pluralize('Shipment', selectedShipmentIds.length)}</span>
            </BulkActionLineItemButton>
          ]}
        </Dropdown>
        <Modal
          show={Boolean(modalKind)}
          title={modalKind ? modalTitles[modalKind] : null}
          footerComponent={null}
          onClose={() => {
            togglePerformingBulkAction();
            setModalKind(undefined);
          }}
          bodyClassName="overflow-auto"
          bodyVariant="disableOverflowScroll"
        >
          {modalKind === ModalKindEnums.changeShipmentStatus ? (
            <ChangeShipmentStatus
              selectedShipmentIds={selectedShipmentIds}
              onCancel={() => setModalKind(undefined)}
              onSubmitSuccess={(bulkOperationId: string) => {
                setSuccess(
                  'Shipment Bulk Actions Submitted!',
                  `Shipping status changes have been submitted for ${pluralize(
                    'shipment',
                    selectedShipments.length,
                    true
                  )}.`
                );
                pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS);
                handleSuccess();
              }}
              setError={setError}
            />
          ) : modalKind === ModalKindEnums.editShipmentDates ? (
            <EditShipmentDates
              selectedShipmentIds={selectedShipmentIds}
              onCancel={() => {
                setModalKind(undefined);
                togglePerformingBulkAction();
              }}
              onSubmitSuccess={(bulkOperationId: string) => {
                setSuccess(
                  'Shipment Bulk Actions Submitted!',
                  `Shipping date changes have been submitted for ${pluralize(
                    'shipment',
                    selectedShipments.length,
                    true
                  )}.`
                );
                pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES);
                togglePerformingBulkAction();
                handleSuccess();
              }}
              setError={setError}
            />
          ) : modalKind === ModalKindEnums.emailBol ? (
            <ShipmentDocument
              mode={EMAIL_DOCUMENT}
              shipments={selectedShipments}
              onCancel={() => setModalKind(undefined)}
              onSuccess={handleSuccess}
              documentType={ShipmentDocumentMetadataTypeEnum.Bol}
            />
          ) : modalKind === ModalKindEnums.printBol ? (
            <ShipmentDocument
              mode={PRINT_DOCUMENT}
              shipments={selectedShipments}
              onCancel={() => setModalKind(undefined)}
              onSuccess={handleSuccess}
              documentType={ShipmentDocumentMetadataTypeEnum.Bol}
            />
          ) : modalKind === ModalKindEnums.printShippingLabel ? (
            <ShipmentDocument
              mode={PRINT_DOCUMENT}
              shipments={selectedShipments}
              onCancel={() => setModalKind(undefined)}
              onSuccess={handleSuccess}
              documentType={ShipmentDocumentMetadataTypeEnum.ShippingLabel}
            />
          ) : modalKind === ModalKindEnums.pushToRoutingGuide ? (
            <ExecuteRoutingGuide
              selectedShipments={performingBulkAction ? selectedShipmentIds : null}
              onCancel={() => {
                setModalKind(undefined);
                togglePerformingBulkAction();
              }}
              onSubmitSuccess={(bulkOperationId: string) => {
                if (performingBulkAction) {
                  setSuccess(
                    'Shipment Bulk Actions Submitted!',
                    `Routing guides have been submitted for ${pluralize('shipment', selectedShipments.length, true)}.`
                  );
                  pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE);
                } else {
                  setSuccess('Your routing guide is running!', ROUTING_GUIDE_SUCCESS_ON_EXECUTION);
                }
                togglePerformingBulkAction();
                handleSuccess();
              }}
            />
          ) : modalKind === ModalKindEnums.tenderToCarrier ? (
            <BulkTenderRequestForm
              selectedShipmentsById={selectedShipments.reduce<Record<string, unknown>>((shipmentObj, shipment) => {
                const shipmentId = shipment.id;
                shipmentObj[shipmentId] = shipment;
                return shipmentObj;
              }, {})}
              onClose={() => {
                setModalKind(undefined);
                togglePerformingBulkAction();
              }}
              onSuccess={() => {
                setSuccess(
                  'Tender Request Sent!',
                  `Tender requests have been sent for ${pluralize('shipment', selectedShipments.length, true)}.`
                );
                handleSuccess();
              }}
              onBulkSuccess={(bulkOperationId: string) => {
                pollBulkOperation(bulkOperationId, BULK_OPERATION_TYPE_TENDER_TO_CARRIER);
                onSuccess?.();
              }}
            />
          ) : modalKind === ModalKindEnums.schedulePickup ? (
            <SchedulePickup
              shipment={selectedShipments[0]}
              filters={{providerCode}}
              onCancel={() => setModalKind(undefined)}
              onCreatePickup={() => setModalKind(ModalKindEnums.createPickup)}
              onUpdatePickup={handleUpdateShipmentPickup}
            />
          ) : modalKind === ModalKindEnums.createPickup ? (
            <SchedulePickupForm
              onCancel={() => setModalKind(undefined)}
              onSubmit={handleCreateShipmentPickup}
              providerCode={providerCode}
              isReadOnlyAddress
              values={createPickupFormValues}
            />
          ) : null}
        </Modal>

        <Loader show={!!loadingMessage}>{loadingMessage}</Loader>
      </>
    );
  }
);
