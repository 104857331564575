import {useCallback, useMemo} from 'react';
import {IconButton} from '@shipwell/shipwell-ui';
import {colloquialDate} from 'App/utils/dateTimeGlobalsTyped';

export type DayPaginatorProps = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

const DayPaginator = ({selectedDate, setSelectedDate}: DayPaginatorProps) => {
  const onClickPreviousDay = useCallback(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() - 1);
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  const onClickNextDay = useCallback(() => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + 1);
    setSelectedDate(date);
  }, [selectedDate, setSelectedDate]);

  const day: string = useMemo(() => colloquialDate(selectedDate), [selectedDate]);

  return (
    <div className="flex-0 sw-week-paginator flex w-40 flex-row flex-wrap content-center justify-between">
      {/* NOTE: adding `active:translate-x-0.5 active:translate-y-0.5` to className makes a nice click effect, consider adding it to <SVGIcon /> */}
      <IconButton iconName="ArrowSmallLeft" onClick={onClickPreviousDay} aria-label={'Go To Previous Day'} />
      <div className="flex-0 mt-1.5 flex select-none uppercase">{day}</div>
      <IconButton iconName="ArrowSmallRight" onClick={onClickNextDay} aria-label={'Go To Next Day'} />
    </div>
  );
};

export default DayPaginator;
