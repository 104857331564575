export const placeholder = {
  weight: 'Estimated Weight (lbs.)',
  note: 'Notes & additional info for the carrier',
  pickUp: 'Pick-up Address',
  dropOff: 'Drop-off Address',
  name: 'Shipment Name',
  description: 'Shipment Description',
  contact: 'Contact Name',
  phone: 'Contact Phone Number',
  loadNumber: 'Alpha-numeric load number',
  docTitle: 'Document Title',
  docDescription: 'Document Description',
  instructions: '(e.g. Pickup or Dropoff 1 or 2 materials)',
  business: 'Enter business address'
};
