import PropTypes from 'prop-types';
import moment from 'moment';

// Values represent range in weeks.
export const PRICING_INTEL_RANGE_3_MONTHS = 'PRICING_INTEL_RANGE_3_MONTHS';
export const PRICING_INTEL_RANGE_6_MONTHS = 'PRICING_INTEL_RANGE_6_MONTHS';
export const PRICING_INTEL_RANGE_12_MONTHS = 'PRICING_INTEL_RANGE_12_MONTHS';

export const rangeOptionItems = [
  {
    value: PRICING_INTEL_RANGE_3_MONTHS,
    label: 'Last 3 Months',
    aggregateLabel: '3 Month',
    aggregateDataKey: 'three_month',
    numWeeks: 12
  },
  {
    value: PRICING_INTEL_RANGE_6_MONTHS,
    label: 'Last 6 Months',
    aggregateLabel: '6 Month',
    aggregateDataKey: 'six_month',
    numWeeks: 26
  },
  {
    value: PRICING_INTEL_RANGE_12_MONTHS,
    label: 'Last Year',
    aggregateLabel: 'Yearly',
    aggregateDataKey: 'twelve_month',
    numWeeks: 52
  }
];

// Equipment types supported by pricing intelligence
export const allowedEquipmentTypes = [
  {
    id: 1,
    machine_readable: 'DRY_VAN',
    name: 'Dry Van'
  },
  {
    id: 2,
    machine_readable: 'REEFER',
    name: 'Reefer'
  },
  {
    id: 3,
    machine_readable: 'FLATBED',
    name: 'Flatbed'
  }
];

export const MARKET_CARRIER = 'shipwell_market';
export const INTERNAL_SHIPPER = 'shipwell_internal';
export const STANDALONE_MARKET_CARRIER = 'shipwell_market_carrier';
export const STANDALONE_INTERNAL_SHIPPER = 'shipwell_internal_shipper';

export const dataSourceOptionItems = {
  chainalytics_spot: {label: 'Chainalytics', value: 'chainalytics_spot', color: '#d02139'},
  dat_spot: {label: 'DAT', value: 'dat_spot', color: '#fcbf49'},
  shipwell_internal: {label: 'My Data', value: INTERNAL_SHIPPER, color: '#870058'},
  shipwell_market: {label: 'SW Market Composite', value: MARKET_CARRIER, color: '#B41D13'},
  customerDatHistoricalSpot: {label: 'DAT Broker-to-Spot', value: 'customerDatHistoricalSpot', color: '#0A6FDB'},
  customerDatHistoricalContract: {
    label: 'DAT Shipper-to-Carrier',
    value: 'customerDatHistoricalContract',
    color: '#DF9100'
  }
};

export const crosshairStyles = {
  line: {
    background: '#26282b',
    width: '1px',
    marginTop: '1px'
  },
  box: {
    borderRadius: '3px',
    color: '#ffffff',
    backgroundColor: 'rgba(38, 40, 43, 0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
};

export const tooltipContent = (
  <div>
    Pricing Intel data is based
    <br />
    off of the pickup location, delivery
    <br />
    location, and equipment type. <br />
    Prices include line haul and fuel.
  </div>
);

export const ErrorMessageContainer = ({errorMessage}) => {
  return (
    <div title="errorContainer" className="errorContainer flex size-full items-center justify-center">
      <div className="flex h-24 items-center justify-center font-bold">{errorMessage}</div>
    </div>
  );
};

ErrorMessageContainer.propTypes = {
  errorMessage: PropTypes.string
};

export const DisabledContainer = ({children, getPricingData}) => {
  return (
    <div className="flex size-full items-center justify-center">
      <a className="flex h-24 cursor-pointer items-center justify-center font-bold" onClick={getPricingData}>
        {children}
      </a>
    </div>
  );
};

DisabledContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  getPricingData: PropTypes.func
};

// Pricing Intel display variants
export const PRICING_INTEL_VARIANT_FULL = 'full';
export const PRICING_INTEL_VARIANT_HALF = 'half';
export const PRICING_INTEL_VARIANT_BASIC = 'basic';

// Pricing Intel Graph states
export const PRICING_INTEL_DATA_STATE_EMPTY = 'PRICING_INTEL_DATA_STATE_EMPTY';
export const PRICING_INTEL_DATA_STATE_MISSING_DATA = 'PRICING_INTEL_DATA_STATE_MISSING_DATA';
export const PRICING_INTEL_DATA_STATE_PROVIDER_TIMEOUT = 'PRICING_INTEL_DATA_STATE_PROVIDER_TIMEOUT';
export const PRICING_INTEL_DATA_STATE_INVALID_LANE = 'PRICING_INTEL_DATA_STATE_INVALID_LANE';
export const PRICING_INTEL_DATA_STATE_VALID = 'PRICING_INTEL_DATA_STATE_VALID';
export const PRICING_INTEL_DATA_STATE_LOADING = 'PRICING_INTEL_DATA_STATE_LOADING';
export const PRICING_INTEL_DATA_STATE_DISABLED = 'PRICING_INTEL_DATA_STATE_DISABLED';
// This is used for loading and updating graph display using form inputs
export const PRICING_INTEL_GRAPH_STATE_LOADING = 'PRICING_INTEL_GRAPH_STATE_LOADING';
export const PRICING_INTEL_GRAPH_STATE_EMPTY = 'PRICING_INTEL_GRAPH_STATE_EMPTY';
export const PRICING_INTEL_GRAPH_STATE_MISSING_DATA = 'PRICING_INTEL_GRAPH_STATE_MISSING_DATA';
export const PRICING_INTEL_GRAPH_STATE_VALID = 'PRICING_INTEL_GRAPH_STATE_VALID';
export const PRICING_INTEL_GRAPH_STATE_NO_SOURCE_DATA = 'PRICING_INTEL_GRAPH_STATE_NO_SOURCE_DATA';

// Pricing Intel page sources
export const PRICING_INTEL_SOURCE_SHIPMENT_DETAILS = 'PRICING_INTEL_SOURCE_SHIPMENT_DETAILS';
export const PRICING_INTEL_SOURCE_NEW_QUOTE = 'PRICING_INTEL_SOURCE_NEW_QUOTE';
export const PRICING_INTEL_SOURCE_STANDALONE = 'PRICING_INTEL_SOURCE_STANDALONE';
export const PRICING_INTEL_SOURCE_NEW_SHIPMENT = 'PRICING_INTEL_SOURCE_NEW_SHIPMENT';
export const PRICING_INTEL_SOURCE_MARKETPLACE = 'PRICING_INTEL_SOURCE_MARKETPLACE';
export const PRICING_INTEL_SOURCE_COMPASS = 'PRICING_INTEL_SOURCE_COMPASS';

export const pricingIntelDataStates = {
  PRICING_INTEL_DATA_STATE_EMPTY: {
    error_message: 'Please specify a lane to retrieve data'
  },
  PRICING_INTEL_DATA_STATE_MISSING_DATA: {
    error_message: 'Data unavailable for specified lane and equipment type'
  },
  PRICING_INTEL_DATA_STATE_PROVIDER_TIMEOUT: {
    error_message: 'External data provider is unreachable'
  },
  PRICING_INTEL_DATA_STATE_INVALID_LANE: {
    error_message: 'Unrecognized address entered'
  }
};

export const pricingIntelGraphStates = {
  PRICING_INTEL_GRAPH_STATE_MISSING_DATA: {
    error_message: 'Data unavailable for specified lane and equipment type'
  },
  PRICING_INTEL_GRAPH_STATE_NO_SOURCE_DATA: {
    error_message: `'My Data' unavailable for selected stops`
  }
};

export const pricingIntelVariantsBySource = {
  PRICING_INTEL_SOURCE_SHIPMENT_DETAILS: {
    variant: PRICING_INTEL_VARIANT_FULL,
    initialDataState: PRICING_INTEL_DATA_STATE_DISABLED
  },
  PRICING_INTEL_SOURCE_NEW_QUOTE: {
    variant: PRICING_INTEL_VARIANT_HALF,
    initialDataState: PRICING_INTEL_DATA_STATE_DISABLED
  },
  PRICING_INTEL_SOURCE_NEW_SHIPMENT: {
    variant: PRICING_INTEL_VARIANT_HALF,
    initialDataState: PRICING_INTEL_DATA_STATE_DISABLED
  },
  PRICING_INTEL_SOURCE_STANDALONE: {
    variant: PRICING_INTEL_VARIANT_BASIC,
    initialGraphState: PRICING_INTEL_DATA_STATE_EMPTY
  },
  PRICING_INTEL_SOURCE_MARKETPLACE: {
    variant: PRICING_INTEL_VARIANT_FULL,
    initialGraphState: PRICING_INTEL_DATA_STATE_DISABLED
  },
  PRICING_INTEL_SOURCE_COMPASS: {
    //we don't use the standard pricing variants in compass, just use default
    variant: PRICING_INTEL_VARIANT_FULL,
    initialGraphState: PRICING_INTEL_DATA_STATE_EMPTY
  }
};

export const filterableTableColumnIds = ['carrier_name', 'reference_id'];
//Pricing Intel Default Form Values
export const DEFAULT_PICKUP_RADIUS = 0;
export const DEFAULT_DROPOFF_RADIUS = 0;
export const DEFAULT_MODE = {description: 'Full Truckload', id: 1};
export const DEFAULT_EQUIPMENT_TYPE = {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'};
export const DEFAULT_STOPS = [{}, {}];
export const DEFAULT_FORECAST_RANGE = 1;
//this range allows us to get today's SW market composite and two weeks prior
export const EMBEDDED_HISTORICAL_MARKET_RANGE_DAYS = 15;

//Pricing Intel Categories
export const HISTORICAL_PRICING = 'HISTORICAL_PRICING';
export const PREDICTIVE_PRICING = 'PREDICTIVE_PRICING';

//Predictive Pricing Constants and Variants
export const shipwellMarketCarrier = 'shipwellMarketCarrier';
export const shipwellInternalShipper = 'shipwellInternalShipper';
export const embeddedPricingVariantsBySource = {
  PREDICTIVE_MARKET: {
    contentTitle: `Market Predicted Price`,
    tooltipContent: `Market Predicted Price is a benchmark prediction of the middle-of-the-market rate paid to all carriers across the industry to source capacity for this lane and equipment type.`,
    pricingObject: shipwellMarketCarrier,
    accessor: '[0].average'
  },
  PREDICTIVE_INTERNAL: {
    contentTitle: `My Predicted Price`,
    tooltipContent: `My Predicted Price is a personalized prediction of the rate I expect to pay today to source capacity for this lane and equipment type. It is based on historically paid rates with any past rates of mine receiving increased weighting.`,
    pricingObject: shipwellInternalShipper,
    accessor: 'price'
  },
  HISTORICAL_LANE_INFO: {
    contentTitle: `My Historical Lane Data`,
    tooltipContent: `My most recent shipments and rating data for this lane and equipment type.`,
    pricingObject: null,
    accessor: null
  },
  HISTORICAL_MARKET_TREND: {
    contentTitle: `Market Historical Trend`,
    tooltipContent: ` Industry benchmark rates for this lane and equipment type.`,
    pricingObject: null,
    accessor: null
  }
};
export const PREDICTIVE_MARKET = 'PREDICTIVE_MARKET';
export const PREDICTIVE_INTERNAL = 'PREDICTIVE_INTERNAL';
export const BOOK_NOW_BUTTON_NAME = 'Apply to Book Now';
export const MAX_BUY_BUTTON_NAME = 'Apply to Max Buy';
export const CLICK_TO_COPY_ICON_NAME = 'Click to Copy';
export const METADATA_BUY_IT_NOW_FIELD_KEY = 'metadata.buy_it_now_amount';
export const METADATA_MAX_BUY_FIELD_KEY = 'metadata.max_buy_amount';
//required keys in stop address for sending pricing requests
export const PRICING_INTEL_REQUIRED_ADDRESS_KEYS = ['country', 'state_province', 'postal_code'];
export const DAT_REQUIRED_ADDRESS_KEYS = ['country', 'state_province'];
export const SHIPMENT_STOP_ADDRESS_PATH = 'location.address';
export const STANDALONE_PRICING_STOP_ADDRESS_PATH = 'address';

export const pricingIntelConfidenceScoreItems = {
  very_high: {color: '$sw-success', bgColor: '$sw-success-background', icon: 'ConfidenceHigh'},
  high: {color: '$sw-success', bgColor: '$sw-success-background', icon: 'ConfidenceHigh'},
  medium: {color: '$sw-warning', bgColor: '$sw-warning-background', icon: 'ConfidenceLow'},
  low: {color: '$sw-error', bgColor: '$sw-error-background', icon: 'ConfidenceLow'}
};

export const CONFIDENCE_SCORE_LOW_CONFIDENCE = 'low';
export const CONFIDENCE_SCORE_MEDIUM_CONFIDENCE = 'medium';
export const CONFIDENCE_SCORE_HIGH_CONFIDENCE = 'high';
export const CONFIDENCE_SCORE_VERY_HIGH_CONFIDENCE = 'very_high';

export const FORECAST_CALENDAR_LOW_PRICE = 'low';
export const FORECAST_CALENDAR_MEDIUM_PRICE = 'medium';
export const FORECAST_CALENDAR_HIGH_PRICE = 'high';
export const FORECAST_CALENDAR_VERY_HIGH_PRICE = 'very_high';

export const PRICING_INTEL_FORECAST_CALENDAR_START_DATE = moment().add(1, 'day').format('YYYY-MM-DD');
export const PRICING_INTEL_FORECAST_CALENDAR_END_DATE = moment().add(2, 'weeks').format('YYYY-MM-DD');

//react vis takes graph colors as prop
export const weeklyTrendGraphColors = {
  //blue man crew opacity
  negativeColor: 'rgba(10, 111, 219, 0.3)',
  //red alert opacity
  positiveColor: 'rgba(180, 29, 19, 0.1)'
};

export const holidayLabelConstants = {
  new_year: 'New Years Day',
  martin_luther_king_day: 'Martin Luther King Jr',
  valentine: 'Valentines Day',
  presidents_day: 'Presidents Day',
  mothers_day: 'Mothers Day',
  good_friday: 'Good Friday',
  memorial_day: 'Memorial Day',
  independence_day: 'Independence Day',
  labor_day: 'Labor Day',
  thanksgiving: 'Thanksgiving',
  christmas: 'Christmas'
};

export const PRICING_INTEL_STANDALONE_STOP_ADDRESS_KEY = 'address';
export const PRICING_INTEL_STOP_ADDRESS_KEY = 'location.address';

export const DAT_RATE_TYPE_SPOT = 'SPOT';
export const DAT_RATE_TYPE_CONTRACT = 'CONTRACT';
