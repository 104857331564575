import {InjectedRouter} from 'react-router';
import {TiveCard} from './tiveCard/tiveCard';
import {MacropointCard} from './macropointCard/macropointCard';
import {useFlags} from 'launchdarkly-react-client-sdk';

export interface VisibilityCardContainerProps {
  router: InjectedRouter;
}

export function VisibilityCardContainer(props: VisibilityCardContainerProps) {
  const flags = useFlags();

  return (
    <>
      {flags.apexTiveIntegration && <TiveCard onConnect={() => props.router.push('/company/integrations/tive')} />}

      {flags.apexMacropointIntegration && (
        <MacropointCard onConnect={() => props.router.push('/company/integrations/macropoint')} />
      )}
    </>
  );
}
