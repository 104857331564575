import {useFormikContext} from 'formik';
import {calculateLineHaulAndFuelSurchargeTotal} from 'App/containers/shipments/utils/tenderRateCalculations';
import type {TenderFormValues} from 'App/containers/shipments/components/TenderFields/validation';

function useRateCalculationsFromValues<
  T extends Pick<
    TenderFormValues,
    'lineHaul' | 'fuelSurcharge' | 'fuelIncludedInLineHaul' | 'containers'
  > = TenderFormValues
>() {
  const {values} = useFormikContext<T>();

  const lineHaul = values.lineHaul ? parseFloat(values.lineHaul) : 0;
  const fuelSurcharge = values.fuelSurcharge && !values.fuelIncludedInLineHaul ? parseFloat(values.fuelSurcharge) : 0;
  const numberOfContainers = values.containers.length;

  const singleContainerRate = calculateLineHaulAndFuelSurchargeTotal(lineHaul, fuelSurcharge);
  return {
    singleContainerRate,
    totalContainerRate: singleContainerRate * numberOfContainers
  };
}

export default useRateCalculationsFromValues;
