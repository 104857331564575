import {Configuration, ShipmentMasterListApi} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const configuration = new Configuration({
  basePath,
  apiKey: getAccessToken
});

const shipmentMasterListApi = new ShipmentMasterListApi(configuration);

export const fetchFedexPackageTypes = async () => (await shipmentMasterListApi.quotingFedexPackageTypesGet()).data;
