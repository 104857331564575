import {Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import renderField from 'App/formComponents/renderField';
import {billingOptions} from 'App/utils/globals';
import renderToggle from 'App/formComponents/renderToggle';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';

const renderMultipleCustomerCarriers = ({fields, carriers, formValues, meta: {touched, error}, ...rest}) => (
  <tbody>
    {carriers &&
      carriers.customer_carrier &&
      carriers.customer_carrier.length > 0 &&
      carriers.customer_carrier.map((carrier, ind) => {
        return (
          <tr key={ind} className="customerCarrierForm__carrierRow">
            <td>
              {carrier.is_modified && (
                <i title="Active relationship" style={{color: '#00A3E0'}} className="icon icon-Circle" />
              )}
            </td>
            <td>{carrier.name}</td>
            <td>
              <Field
                extraClass="customerCarrierForm__markupType"
                name={`customer_carrier[${ind}].markup.ltl_markup_type`}
                component={renderDropDownSelector}
                data={billingOptions}
                nolabel
              />
            </td>
            <td>
              {formValues &&
                formValues.customer_carrier &&
                formValues.customer_carrier.length > 0 &&
                formValues.customer_carrier[ind].markup &&
                formValues.customer_carrier[ind].markup.ltl_markup_type &&
                Number(formValues.customer_carrier[ind].markup.ltl_markup_type) === 1 && (
                  <Field
                    extraClass="customerCarrierForm__inlineField"
                    label="Flat Fee ($)"
                    name={`customer_carrier[${ind}].markup.ltl_markup_flat_fee`}
                    component={renderField}
                  />
                )}
              {formValues &&
                formValues.customer_carrier &&
                formValues.customer_carrier.length > 0 &&
                formValues.customer_carrier[ind].markup &&
                formValues.customer_carrier[ind].markup.ltl_markup_type &&
                Number(formValues.customer_carrier[ind].markup.ltl_markup_type) === 2 && (
                  <div>
                    <Field
                      extraClass="customerCarrierForm__inlineField"
                      label="Markup (%)"
                      name={`customer_carrier[${ind}].markup.ltl_markup_percentage`}
                      component={renderField}
                    />
                    <Field
                      extraClass="customerCarrierForm__inlineField"
                      label="Minimum ($)"
                      name={`customer_carrier[${ind}].markup.ltl_markup_minimum`}
                      component={renderField}
                    />
                  </div>
                )}
            </td>
            <td>
              <Field name={`customer_carrier[${ind}].enabled`} component={renderToggle} />
            </td>
          </tr>
        );
      })}
  </tbody>
);

export default renderMultipleCustomerCarriers;
