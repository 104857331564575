import {ReactNode} from 'react';
import {Button, IconButton} from '@shipwell/shipwell-ui';
import classNames from 'classnames';

type TableFilterProps = {
  label: ReactNode;
  children: ReactNode;
  isClearable: boolean;
  onClear: () => void;
  isOpen: boolean;
  onToggleOpen: () => void;
};

export const TableFilter = ({label, children, isClearable, onClear, isOpen, onToggleOpen}: TableFilterProps) => {
  return (
    <div
      className={classNames('flex flex-col border-b border-sw-border', {
        'bg-sw-background': isOpen,
        'bg-sw-background-component': !isOpen
      })}
    >
      <div className="flex items-center pr-2">
        <button
          onClick={onToggleOpen}
          className={classNames('w-full p-2 px-4 text-left', {'font-bold': isOpen})}
          type="button"
        >
          {label}
        </button>
        {isClearable ? (
          <Button isCompact variant="tertiary" size="sm" onClick={onClear}>
            CLEAR
          </Button>
        ) : null}
        <IconButton
          iconName={isOpen ? 'ExpandLess' : 'ExpandMore'}
          aria-label={isOpen ? 'hide filter' : 'show filter'}
          onClick={onToggleOpen}
          isActive={isOpen}
        />
      </div>
      {isOpen ? <div className="p-4 pt-0">{children}</div> : null}
    </div>
  );
};
