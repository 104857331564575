import {useEffect, useState} from 'react';
import ExternalFooter from 'App/common/externalFooter';
import './styles.scss';

const LinkError = (props) => {
  const {message, location, logo} = props;

  return (
    <div className="linkError">
      <div className="linkError__body">
        <img src={logo} alt="logo" className="linkError__logo" />
        <h3 className="linkError__message">{message}</h3>
      </div>
    </div>
  );
};

export default LinkError;
