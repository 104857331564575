/**
 * Compares any two JS values according to the `<` and `>` operators, just like
 * everyone who was ever disappointed by the native `sort` expected.
 */
export function cmp<T>(left: T, right: T) {
  if (left < right) return -1;
  if (left > right) return 1;
  return 0;
}

/**
 * Compares any two JS values according to the `<` and `>` operators, with the
 * enhancement that if both are arrays, their elements are compared
 * lexicographically, and recursively. If one array is a prefix of the other,
 * the prefix proceeds the other. Arrays are greater than non-arrays.
 */
export function lexicalComparator<T>(left: T, right: T): number {
  if (Array.isArray(left)) {
    if (Array.isArray(right)) {
      const leftN = left.length;
      const rightN = right.length;
      const n = Math.min(leftN, rightN);
      for (let i = 0; i < n; i++) {
        const c = lexicalComparator(left[i], right[i]);
        if (c) {
          return c;
        }
      }
      return cmp(leftN, rightN);
    }
    return 1;
  }
  if (Array.isArray(right)) {
    return -1;
  }
  return cmp(left, right);
}

/**
 * Compares two strings, but where the first difference is part of a string of
 * digits in both strings, then those parts are compared numerically.
 */
export function compareHumanReadable(a: string, b: string) {
  const [aParts, bParts] = [a, b].map((name) =>
    name
      .split(
        ''
        //commented out the below for a hotfix
        // splits numbers out from the rest of the name so we can compare numbers numerically
        // /(?<=\d)(?=\D)|(?<=\D)(?=\d)/
      )
      .map(
        // converts all the name parts that are strings of digits to numbers
        (part) => (/^\d+$/.test(part) ? +part : part)
      )
  );

  return lexicalComparator(aParts, bParts);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function compareIds<T extends {id?: any}>(a: T, b: T): number {
  return cmp(a.id ?? -Infinity, b.id ?? -Infinity);
}
/* eslint-enable @typescript-eslint/no-explicit-any */
