import TenderViewCard, {
  TenderHeader,
  TenderContainerDetails,
  TenderFooter
} from 'App/containers/shipments/components/TenderView';

export const entityTypes = {DRAYAGE_TENDER: 'DRAYAGE_TENDER'};

const shipperTenderCardSchema = {
  [entityTypes.DRAYAGE_TENDER]: {
    leftHandCards: [TenderViewCard],
    rightHandCards: [TenderContainerDetails],
    header: TenderHeader,
    footer: TenderFooter
  }
};

export const getSchemaByEntityType = (entityType: string) => shipperTenderCardSchema[entityType];
