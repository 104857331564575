import {useQuery} from '@tanstack/react-query';
import {getTiveCredentials} from 'App/utils/integrations/tive/tive';

export function useTiveService() {
  const getQuery = useQuery({
    queryKey: ['useTiveService'],
    queryFn: async () => {
      return await getTiveCredentials();
    }
  });

  const isConnected = !!getQuery.data;

  return {
    isLoading: getQuery.isFetching,
    isConnected
  };
}
