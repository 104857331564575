import {useFlags} from 'launchdarkly-react-client-sdk';
import {InvoiceRuleConfigurationsCard, FreightSettlementsConfigurationsCard} from './components';
import PageHeader from 'App/common/pageHeader';
import Error404Page from 'App/common/Error404Page';
const SettlementsConfigurationsContainer = () => {
  const {stmInvoiceRuleConfigurationsDashboard} = useFlags();
  if (!stmInvoiceRuleConfigurationsDashboard) {
    return <Error404Page />;
  }
  return (
    <div className="w-full">
      <PageHeader title="Settlements Configurations" className="bg-sw-background" />
      <main className="flex flex-col gap-6 p-6">
        <InvoiceRuleConfigurationsCard />
        <FreightSettlementsConfigurationsCard />
      </main>
    </div>
  );
};
export default SettlementsConfigurationsContainer;
