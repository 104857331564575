import ListSelect from 'App/components/_tableFiltersWithSavedViews/filterComponents/listSelect';
import DateRangeSelect from 'App/components/_tableFiltersWithSavedViews/filterComponents/dateRangeSelect';

export const filters = [
  {
    name: 'status',
    type: ListSelect,
    label: 'Status',
    options: [
      {label: 'Active', value: 'ACTIVE'},
      {label: 'Inactive', value: 'INACTIVE'}
    ],
    useArray: true,
    allowMultiSelect: true
  },
  {
    name: 'position',
    type: ListSelect,
    label: 'Service Level Rank',
    options: [
      {label: 'Primary', value: 'PRIMARY'},
      {label: 'Secondary', value: 'SECONDARY'},
      {label: 'Tertiary', value: 'TERTIARY'},
      {label: 'Backup', value: 'BACKUP'}
    ],
    useArray: true,
    allowMultiSelect: true
  },
  {
    name: 'startDate',
    type: DateRangeSelect,
    label: 'Start Date',
    useArray: false,
    useRange: true
  },
  {
    name: 'endDate',
    type: DateRangeSelect,
    label: 'End Date',
    useArray: false,
    useRange: true
  }
];
