import {AddressBookEntry, LocationType, ShipwellError} from '@shipwell/backend-core-sdk';
import {Facility, FacilityAddress, LocationTypes, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import invariant from 'tiny-invariant';
import isNil from 'lodash/isNil';
import {useLocationTypesQuery} from './useLocationTypes';
import {
  updateAddressBookAddressById,
  getAddressBookAddressById,
  getAddressBookAddresses
} from 'App/api/addressBook/typed';
import {useCreateAddressBookEntryMutation} from 'App/containers/addressBook/hooks';
import {FACILITY_ADDRESS_BOOK_QUERY_KEY} from 'App/data-hooks/queryKeys';

import {convertShipwellAxiosError} from 'App/utils/errors';

/**
 * Finds a matching location type between tempus and backend-core. If no type is found an error is thrown.
 * @throws {Error} No matching location type is/was found
 */
export function mapLocationTypes(
  tempusLocationType: LocationTypes,
  backendCoreLocationTypes: LocationType[]
): LocationType {
  const searchString = tempusLocationType.toLowerCase().replaceAll('_', ' ');
  const locationTypeMapped = backendCoreLocationTypes.find((value) => {
    const beCoreValue = value.name.toLowerCase().replaceAll('(', '').replaceAll('/', ' ').replaceAll(')', '');
    return beCoreValue === searchString;
  });

  if (isNil(locationTypeMapped)) {
    throw new Error(`No matching location type found for ${tempusLocationType}`);
  }

  return locationTypeMapped;
}

function mapAddressBookEntry(facility: UpsertFacility, locationTypes: LocationType[]): AddressBookEntry {
  if (!facility.address.country) {
    throw new Error('Country required to create address book entry');
  }
  return {
    is_default_origin: false,
    location_name: facility.name,
    external_reference: facility.external_reference,
    location_type: mapLocationTypes(facility.location_type, locationTypes),
    facility_id: facility.id,
    address: {
      address_1: facility.address.line_1,
      address_2: facility.address.line_2,
      country: facility.address.country,
      city: facility.address.locality,
      state_province: facility.address.region,
      longitude: facility.address.geolocation?.longitude,
      latitude: facility.address.geolocation?.latitude
    },
    company_name: facility.name,
    point_of_contacts: []
  };
}

export type UpsertFacility = {
  address: {
    id?: string | null;
  } & FacilityAddress;
} & Pick<Facility, 'id' | 'name' | 'external_reference' | 'location_type'>;

export type UseFacilityAddressBookEntryMutationVariables = {
  facility: UpsertFacility;
};

export type UseFacilityAddressBookEntryMutationOptions = Omit<
  UseMutationOptions<void, AxiosError<ShipwellApiErrorResponse>, UseFacilityAddressBookEntryMutationVariables, void>,
  'mutationFn' | 'mutationKey'
>;

/**
 * Specialized hook to connect and disconnect address book entries to/from a facility.
 * If the facility does not have an address book entry tied to it then it will tie one to it. If the facility already
 * has an AddressBookEntry tied that entry will be updated to set the `facility_id` to `null` and the new entry will
 * be tied to the facility.
 */
const useUpsertFacilityAddressBookEntryMutation = (options?: UseFacilityAddressBookEntryMutationOptions) => {
  const {mutateAsync: createAddressBookAsync} = useCreateAddressBookEntryMutation();

  const {locationTypes} = useLocationTypesQuery();
  const client = useQueryClient();

  const mutation = useMutation(async ({facility}: UseFacilityAddressBookEntryMutationVariables) => {
    try {
      // get these first so that we don't overwrite an update or a delete later on
      const currentAddressBookEntries = await getAddressBookAddresses({
        q: facility.id
      });
      // this has to execute first because we cannot have multiple address book entries linked to a facility at the same time.
      if (currentAddressBookEntries.data?.results?.length) {
        for (let i = 0; i < currentAddressBookEntries.data.results.length; i++) {
          const entry = currentAddressBookEntries.data.results[i];
          invariant(entry.id, 'Address book entry id is missing');
          await updateAddressBookAddressById({
            addressBookId: entry.id,
            addressBookEntry: {
              ...entry,
              facility_id: null
            }
          });
        }
      }
      if (!facility.address?.id) {
        // create the new entry
        try {
          await createAddressBookAsync(mapAddressBookEntry(facility, locationTypes || []));
        } catch (error) {
          // perform rollback
          if (currentAddressBookEntries.data?.results?.length) {
            for (let i = 0; i < currentAddressBookEntries.data.results.length; i++) {
              const entry = currentAddressBookEntries.data.results[i];
              invariant(entry.id, 'Address book entry id is missing');
              await updateAddressBookAddressById({
                addressBookId: entry.id,
                addressBookEntry: {
                  ...entry,
                  facility_id: facility.id
                }
              });
            }
          }
          const legacyError = error as AxiosError<ShipwellError>;
          throw convertShipwellAxiosError(legacyError);
        }
        return;
      }

      const {data: addressBookEntry} = await getAddressBookAddressById(facility.address.id);
      invariant(addressBookEntry.id, 'Address book entry id is missing');
      // update existing address book entry
      try {
        await updateAddressBookAddressById({
          addressBookId: addressBookEntry.id,
          addressBookEntry: {
            ...addressBookEntry,
            facility_id: facility.id
          }
        });
      } catch (error) {
        // perform rollback
        if (currentAddressBookEntries.data?.results?.length) {
          for (let i = 0; i < currentAddressBookEntries.data.results.length; i++) {
            const entry = currentAddressBookEntries.data.results[i];
            invariant(entry.id, 'Address book entry id is missing');
            await updateAddressBookAddressById({
              addressBookId: entry.id,
              addressBookEntry: {
                ...entry,
                facility_id: facility.id
              }
            });
          }
        }
        const legacyError = error as AxiosError<ShipwellError>;
        throw convertShipwellAxiosError(legacyError);
      }
    } finally {
      void client.invalidateQueries([FACILITY_ADDRESS_BOOK_QUERY_KEY, facility.id]);
    }
  }, options);

  return {
    upsertFacilityAddressBookEntry: mutation.mutate,
    upsertFacilityAddressBookEntryAsync: mutation.mutateAsync,
    isUpsertFacilityAddressBookEntryLoading: mutation.status === 'loading',
    isUpsertFacilityAddressBookEntryError: mutation.status === 'error',
    isUpsertFacilityAddressBookEntrySuccess: mutation.status === 'success',
    upsertFacilityAddressBookEntryError: mutation.error || null
  };
};

export {useUpsertFacilityAddressBookEntryMutation};
