import {ChangeEvent, MouseEvent, useMemo} from 'react';
import {Checkbox} from '@shipwell/shipwell-ui';
import {PurchaseOrder, SalesOrder} from '@shipwell/corrogo-sdk';
import {Link} from 'react-router';
import {FlexBox} from 'App/components/Box';
import {EntityTag} from 'App/components/genericEntityTags/entityTag';
import {TagPillsForTableCell} from 'App/components/genericEntityTags/tagPillsForTableCell/tagPillsForTableCell';

export const OrderNumberCell = ({
  order,
  isSelected,
  onSelect,
  disabled
}: {
  order: PurchaseOrder | SalesOrder;
  isSelected: boolean;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  const entityTags = useMemo(() => {
    return order.tags?.map<EntityTag>((tag) => ({
      id: tag.id,
      color: tag.color,
      label: tag.name
    }));
  }, [order.tags]);

  return (
    <FlexBox gap="s" items="start" wrap="nowrap">
      <Checkbox
        aria-label="select row"
        name={order.id}
        checked={isSelected}
        onChange={onSelect}
        onClick={(evt: MouseEvent) => evt.stopPropagation()}
        fixedHeight={false}
        disabled={disabled}
      />

      <div className="flex flex-col gap-2">
        <Link
          role="link"
          to={`${
            order.order_type === 'PURCHASE_ORDER' ? `/purchase-orders-corrogo/${order.id}` : `/sales-orders/${order.id}`
          }`}
        >
          <div className="whitespace-nowrap">{order.order_number}</div>
        </Link>

        <TagPillsForTableCell tags={entityTags} limit={3} />
      </div>
    </FlexBox>
  );
};
