import {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import {SubmissionError} from 'redux-form';
import * as actions from '../../actions/users';
import ProfileForm from './forms/ProfileForm';
import PasswordForm from './forms/PasswordForm';
import * as formActions from 'App/actions/forms';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {unpackErrors} from 'App/utils/globals';
import './_updatePassword.scss';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company
  }),
  {...actions, ...formActions}
)
export default class Security extends Component {
  constructor(props) {
    super(props);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePasswordViewNew = this.handlePasswordViewNew.bind(this);
    this.handlePasswordViewCurrent = this.handlePasswordViewCurrent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      currentPass: '',
      newPass: '',
      updated: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      showPasswordModal: false,
      viewPasswordNew: false,
      viewPasswordCurrent: false,
      requirements: {length: false, capital: false, special: false},
      success: false,
      done: false
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  handlePasswordViewNew() {
    this.setState({viewPasswordNew: !this.state.viewPasswordNew});
  }
  handlePasswordViewCurrent() {
    this.setState({viewPasswordCurrent: !this.state.viewPasswordCurrent});
  }

  handlePasswordChange(value) {
    const requirements = {length: false, capital: false, special: false};
    if (value.length >= 8) {
      requirements.length = true;
    }
    for (let i = 0; i < value.length; i++) {
      if (/[A-Z]/.test(value[i])) {
        requirements.capital = true;
      }
      if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@]/.test(value[i])) {
        requirements.special = true;
      }
    }
    this.setState({requirements: requirements});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(attrs) {
    return this.props.changePassword(attrs.currentPassword, attrs.newPassword).then((response) => {
      if (response.status === 200) {
        this.setState({success: true});
        setTimeout(
          function () {
            this.setState({done: true}, function () {
              setTimeout(
                function () {
                  this.props.resetPasswordForm();
                  this.setState({success: false, done: false});
                }.bind(this),
                1000
              );
            });
          }.bind(this),
          2000
        );
      } else {
        let submissionError = {};
        if (response.field_errors) {
          if (response.field_errors[0].field_name === 'old_password') {
            submissionError = {
              currentPassword: response.field_errors[0].field_errors[0]
            };
            submissionError._error = response.field_errors[0].field_errors[0];
          }
        }
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  render() {
    return (
      <div className="pad-top settings-form">
        <PasswordForm
          viewPasswordNew={this.state.viewPasswordNew}
          handlePasswordViewNew={this.handlePasswordViewNew}
          viewPasswordCurrent={this.state.viewPasswordCurrent}
          handlePasswordViewCurrent={this.handlePasswordViewCurrent}
          requirements={this.state.requirements}
          handlePasswordChange={this.handlePasswordChange}
          onSubmit={this.handleSubmit}
          success={this.state.success}
          done={this.state.done}
        />
      </div>
    );
  }
}
