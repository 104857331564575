import {FormControl, FormGroup, InputGroup, ControlLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';

const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  },
  cursor: {
    cursor: 'help'
  }
};
const renderTextArea = ({
  input,
  hasError,
  value,
  label,
  icon,
  tooltip,
  placeholder,
  type,
  minRows,
  req,
  extraClass,
  disabled,
  name,
  meta: {touched, error}
}) => (
  <FormGroup
    controlId={input.name}
    className={extraClass && extraClass}
    validationState={touched && error ? 'error' : null}
  >
    <ControlLabel>
      {label}
      {req && <span className="requiredField">*</span>}
    </ControlLabel>
    {tooltip && (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            <div style={style.tooltip}>{tooltip}</div>
          </Tooltip>
        }
      >
        <a tabIndex="-1">
          <i className="icon icon-Info" aria-hidden="true" />
        </a>
      </OverlayTrigger>
    )}

    <InputGroup className={hasError ? 'input-group-inline ' + hasError : 'input-group-inline'}>
      {icon && (
        <InputGroup.Addon>
          <i className={icon} />
        </InputGroup.Addon>
      )}
      <Textarea
        {...input}
        type={type}
        minRows={minRows}
        placeholder={touched && error ? error : placeholder}
        disabled={disabled}
      />
    </InputGroup>

    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderTextArea;
