import Search from 'App/components/_tableFiltersWithSavedViews/filterComponents/search';

export const filtersAlpha = [
  {
    name: 'rowIdentifierContains',
    label: 'Lane ID',
    type: Search,
    useArray: false
  },
  {
    name: 'originPostalCodeContains',
    label: 'Origin Zip',
    type: Search,
    useArray: false
  },
  {
    name: 'originCityContains',
    label: 'Origin City',
    type: Search,
    useArray: false
  },
  {
    name: 'originStateContains',
    label: 'Origin State',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationPostalCodeContains',
    label: 'Destination Zip',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationCityContains',
    label: 'Destination City',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationStateContains',
    label: 'Destination State',
    type: Search,
    useArray: false
  },
  {
    name: 'carrierReferenceContains',
    label: 'US DOT',
    type: Search,
    useArray: false
  },
  {
    name: 'carrierNameContains',
    label: 'Carrier Name',
    type: Search,
    useArray: false
  }
];
