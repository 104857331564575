import {useQuery} from '@tanstack/react-query';
import {listDevices} from 'App/api/locus/tracking';

export interface UseDevicesServiceProps {
  accountId: number;
}

export function useDevicesService(props: UseDevicesServiceProps) {
  const getQuery = useQuery({
    queryKey: ['useDevicesService'],
    queryFn: async () => {
      const response = await listDevices({provider: 'tive'}, props.accountId);
      return response.data;
    }
  });

  return {
    isLoading: getQuery.isFetching,
    data: getQuery.data
  };
}
