import {ReactNode} from 'react';

export interface CardTitleProps {
  title: ReactNode;
  onClick: () => void;
}

export function CardTitle(props: CardTitleProps) {
  return (
    <div className={'flex items-center gap-2'}>
      <div className="font-bold">{props.title}</div>
      <div role="button" className="cursor-pointer text-xs text-sw-primary" onClick={props.onClick}>
        View Log
      </div>
    </div>
  );
}
