import {useMemo, useState} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {Button, Card, Modal, Popover, SvgIcon} from '@shipwell/shipwell-ui';
import {BulkCreateSpotNegotiationRequest, Shipment} from '@shipwell/backend-core-sdk';
import {
  CapacitySearchRequest,
  CapacitySearchResponseMultiple,
  Destination,
  Equipment,
  LoadboardType,
  ModeEnum,
  Origin
} from '@shipwell/tabula-sdk';
import pluralize from 'pluralize';
import {differenceInMinutes} from 'date-fns';
import {object, ref, date} from 'yup';
import ShipmentSourceCapacityTable from './ShipmentSourceCapacityTable';
import {getErrorDetail} from './components/LoadBoards/utils';
import ShipmentSourceCapacityTableFilters from './ShipmentSourceCapacityTableFilters';
import {LAST_SHIPMENT_SOURCE_CAPACITY_QUERY_ID} from 'App/data-hooks/loadboard/useGetCapacitySearch';
import {formatV2AddressToFacilityAddress} from 'App/data-hooks/facilities/facilityUtils';
import {
  useCreateLoadboardCapacitySearch,
  useCreateSpotNegotiations,
  useDatIntegration,
  useGetUserDashboardConfigs,
  useUpdateUserDashboardConfig
} from 'App/data-hooks';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import useGetLoadPostRequestByShipmentId from 'App/api/loadboards/hooks/useGetLoadpostRequestByShipmentId';
import useCreateUserDashboardConfig from 'App/data-hooks/dashboards/useCreateUserDashboardConfig';
import {addDays} from 'App/utils/dateTimeGlobalsTyped';
import useToggle from 'App/utils/hooks/useToggle';
import SourceCapacityRequestBidsForm, {
  SourceCapacityRequestBidsFormType
} from 'App/containers/Shipment/forms/SourceCapacityRequestBidsForm';
import {transformAxiosError} from 'App/utils/globalsTyped';

export const DEFAULT_AGE_LIMIT_MINUTES = 60;
export const DEFAULT_RANGE_DISTANCE = '100';
export const DEFAULT_EARLIEST_AVAILABILITY = new Date().toISOString();
export const DEFAULT_LATEST_AVAILABILITY = addDays(new Date(), 7).toISOString();
export const DEFAULT_SOURCE = [LoadboardType.Internal];
const TABLE_TYPE = 'SOURCE_CAPACITY_TABLE';
const TABLE_NAME = 'sourceCapacityTable';
const DEFAULT_RANGE_DISTANCE_UNIT = 'MILE';
const DEFAULT_NETWORK = ['in_network', 'out_of_network'];

type FiltersState = {
  age_limit_minutes: string[];
  origin_range_distance: string[];
  origin_range_distance_units: string[];
  destination_range_distance: string[];
  destination_range_distance_units: string[];
  equipment: string[];
  source: string[];
  network: string[];
};

export type CapacitySearchFormValues = CapacitySearchRequest & {
  source_dat: boolean;
  status_in_network: boolean;
  status_out_network: boolean;
};

const ShipmentSourceCapacity = ({shipment, setSuccess, setError}: {shipment: Shipment} & WithStatusToastProps) => {
  const {data: loadPostRequest} = useGetLoadPostRequestByShipmentId(shipment.id);
  const {createSpotNegotiations} = useCreateSpotNegotiations();
  const [queryId, setQueryId] = useState<string>();
  const [lastRunAgo, setLastRunAgo] = useState<number>();
  const [selectedCarriers, setSelectedCarriers] = useState<CapacitySearchResponseMultiple[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<CapacitySearchFormValues>();
  const {data: filtersData} = useGetUserDashboardConfigs('SOURCE_CAPACITY_TABLE');
  const {mutate: saveTableConfig} = useCreateUserDashboardConfig();
  const {mutate: updateTableConfig} = useUpdateUserDashboardConfig();
  const [openRequestBidsModal, toggleOpenRequestBidsModal] = useToggle(false);
  const {isDatIntegrated} = useDatIntegration();
  const tableNameWithShipmentId = `${TABLE_NAME}-${shipment.id}`;

  const filtersDefault = useMemo(
    () => filtersData?.results?.find((resultItem) => resultItem.name === tableNameWithShipmentId),
    [filtersData, tableNameWithShipmentId]
  );

  const createOrUpdateTableFilters = (dataFilters: FiltersState) => {
    const data = {
      table_type: TABLE_TYPE,
      name: tableNameWithShipmentId,
      config: {
        filters: dataFilters,
        columns: [],
        ordering: []
      }
    };

    if (filtersDefault?.id) {
      updateTableConfig({
        userDashboardConfigurationId: filtersDefault.id,
        userDashboardConfiguration: data
      });
    } else {
      saveTableConfig(data);
    }
  };

  const {mutate, isLoading} = useCreateLoadboardCapacitySearch({
    onSuccess: ({data}) => {
      if (data.internal_query_id) {
        setQueryId(data.internal_query_id);
        localStorage.setItem(LAST_SHIPMENT_SOURCE_CAPACITY_QUERY_ID, data.internal_query_id);
      }
      setSuccess('Success!', 'Run completed');
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  const {stops, mode} = shipment;
  const origin = useMemo(() => stops?.[0]?.location?.address, [stops]);
  const destination = useMemo(() => stops?.[stops.length - 1]?.location?.address, [stops]);

  const equipmentDefault = useMemo(
    () =>
      filtersDefault?.config?.filters?.equipment?.[0]
        ? (JSON.parse(filtersDefault.config.filters.equipment[0]) as Equipment[])
        : undefined,
    [filtersDefault]
  );

  const equipment = useMemo(
    () => equipmentDefault || loadPostRequest?.equipment || [],
    [equipmentDefault, loadPostRequest?.equipment]
  );

  const ageLimitMinutes = useMemo(
    () =>
      filtersDefault?.config?.filters?.age_limit_minutes?.[0]
        ? Number(filtersDefault.config.filters.age_limit_minutes[0])
        : DEFAULT_AGE_LIMIT_MINUTES,
    [filtersDefault]
  );

  const modeCode = useMemo(() => mode?.code as ModeEnum, [mode]);

  const originRangeDistance = useMemo(
    () => filtersDefault?.config?.filters?.origin_range_distance?.[0] || DEFAULT_RANGE_DISTANCE,
    [filtersDefault]
  );

  const originRangeDistanceUnits = useMemo(
    () => filtersDefault?.config?.filters?.origin_range_distance_units?.[0] || DEFAULT_RANGE_DISTANCE_UNIT,
    [filtersDefault]
  );

  const destinationRangeDistance = useMemo(
    () => filtersDefault?.config?.filters?.destination_range_distance?.[0] || DEFAULT_RANGE_DISTANCE,
    [filtersDefault]
  );

  const destinationRangeDistanceUnits = useMemo(
    () => filtersDefault?.config?.filters?.destination_range_distance_units?.[0] || DEFAULT_RANGE_DISTANCE_UNIT,
    [filtersDefault]
  );

  const source = useMemo(() => {
    if (isDatIntegrated && filtersDefault?.config?.filters?.source) {
      return filtersDefault.config.filters.source;
    }

    if (isDatIntegrated) {
      return [...DEFAULT_SOURCE, LoadboardType.Dat];
    }

    return DEFAULT_SOURCE;
  }, [filtersDefault, isDatIntegrated]);

  const network = useMemo(() => filtersDefault?.config?.filters?.network || DEFAULT_NETWORK, [filtersDefault]);

  const initialValues = useMemo(
    () => ({
      origin: origin && (formatV2AddressToFacilityAddress(origin) as Origin),
      destination: destination && (formatV2AddressToFacilityAddress(destination) as Destination),
      equipment,
      age_limit_minutes: ageLimitMinutes,
      mode: modeCode,
      origin_range_distance: originRangeDistance,
      origin_range_distance_units: originRangeDistanceUnits,
      destination_range_distance: destinationRangeDistance,
      destination_range_distance_units: destinationRangeDistanceUnits,
      source_dat: source.includes(LoadboardType.Dat),
      status_in_network: network.includes('in_network'),
      status_out_network: network.includes('out_of_network'),
      earliest: DEFAULT_EARLIEST_AVAILABILITY,
      latest: DEFAULT_LATEST_AVAILABILITY
    }),
    [
      ageLimitMinutes,
      destination,
      destinationRangeDistance,
      destinationRangeDistanceUnits,
      equipment,
      modeCode,
      origin,
      originRangeDistance,
      originRangeDistanceUnits,
      source,
      network
    ]
  );

  const onRunSearch = (values: CapacitySearchFormValues) => {
    if (origin && destination && mode?.code) {
      setSelectedFilters(values);
      createOrUpdateTableFilters({
        age_limit_minutes: [String(values.age_limit_minutes)],
        origin_range_distance: values.origin_range_distance ? [values.origin_range_distance] : [],
        origin_range_distance_units: [values.origin_range_distance_units],
        destination_range_distance: values.destination_range_distance ? [values.destination_range_distance] : [],
        destination_range_distance_units: [values.destination_range_distance_units],
        equipment: [JSON.stringify(values.equipment)],
        source: values.source_dat ? [...DEFAULT_SOURCE, LoadboardType.Dat] : DEFAULT_SOURCE,
        network: [
          ...(values.status_in_network ? ['in_network'] : []),
          ...(values.status_out_network ? ['out_of_network'] : [])
        ]
      });
      return mutate(values);
    }
  };

  const handleRequestBids = (
    payload: {shipmentId: string; body: BulkCreateSpotNegotiationRequest},
    setSubmitting: FormikHelpers<SourceCapacityRequestBidsFormType>['setSubmitting']
  ) => {
    return createSpotNegotiations(payload, {
      onSuccess: () => {
        toggleOpenRequestBidsModal();
        setSubmitting(false);
        setSuccess('Success', 'Your bid request has been created successfully.');
      },
      onError: (error) => {
        setSubmitting(false);
        const {title, message} = transformAxiosError(error);
        setError(title, message);
      }
    });
  };

  const validationSchema = object().shape({
    earliest: date().nullable(),
    latest: date().nullable().min(ref('earliest'), 'Latest date must come after earliest date.')
  });

  return (
    <>
      <Card
        title={
          <div className="flex items-center gap-2">
            <span>Capacity Result</span>
            {lastRunAgo && (
              <span className="font-normal italic text-sw-disabled-text">
                Search last run {pluralize('min', differenceInMinutes(new Date(), new Date(lastRunAgo)), true)} ago
              </span>
            )}
          </div>
        }
        draggableProvided={null}
        className="h-full"
        bodyClassName="p-0 h-[calc(100%-48px)]"
        actions={
          <div className="flex h-full items-center gap-4">
            <Formik
              initialValues={initialValues as CapacitySearchFormValues}
              onSubmit={onRunSearch}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {({handleSubmit, values, setFieldValue}) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="flex items-center gap-4">
                      <ShipmentSourceCapacityTableFilters
                        equipments={values.equipment}
                        defaultEquipments={loadPostRequest?.equipment}
                        setFieldValue={setFieldValue}
                        filters={values}
                      />
                      {selectedCarriers.length > 0 ? (
                        <Popover
                          showArrow
                          trigger={({
                            isOpen,
                            setIsOpen,
                            setTriggerElement
                          }: {
                            isOpen: boolean;
                            setIsOpen: (open: boolean) => void;
                            setTriggerElement: (el: HTMLElement | null) => void;
                          }) => (
                            <Button ref={setTriggerElement} size="sm" onClick={() => setIsOpen(!isOpen)}>
                              <>
                                <span>Carrier Actions</span>
                                <SvgIcon name="CarrotDown" />
                              </>
                            </Button>
                          )}
                        >
                          {() => (
                            <Popover.MenuList>
                              <Popover.MenuListItemButton
                                aria-label="request bids"
                                onClick={() => toggleOpenRequestBidsModal()}
                              >
                                Request Bids
                              </Popover.MenuListItemButton>
                            </Popover.MenuList>
                          )}
                        </Popover>
                      ) : (
                        <Button size="sm" type="submit" disabled={isLoading}>
                          Run Search
                        </Button>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      >
        <ShipmentSourceCapacityTable
          queryId={queryId}
          setLastRunAgo={setLastRunAgo}
          shipmentId={shipment.id}
          isFetching={isLoading}
          setSelectedCarriers={setSelectedCarriers}
          filters={selectedFilters}
        />
      </Card>
      <Modal
        title="Request Bids"
        show={openRequestBidsModal}
        onClose={() => toggleOpenRequestBidsModal()}
        footerComponent={null}
        bodyClassName="p-4"
      >
        <SourceCapacityRequestBidsForm
          selectedCarriers={selectedCarriers}
          shipment={shipment}
          handleRequestBids={handleRequestBids}
        />
      </Modal>
    </>
  );
};

export default WithStatusToasts(ShipmentSourceCapacity);
