import {object, string, array, boolean} from 'yup';
import type {InferType, StringSchema} from 'yup';
import {OceanTrackingReference, OceanTrackingReferenceType} from '@shipwell/corrogo-sdk';
import {emptyStringToNull, requiredMessage} from 'App/utils/yupHelpers';
import {drayageStopValidationSchema} from 'App/containers/shipments/utils/constants';

export const validationSchema = object().shape({
  tags: array().of(string()),
  documents: array().of(
    object().shape({
      description: string(),
      type: string(),
      file: string(),
      filename: string()
    })
  ),
  name: string().nullable().max(32, 'The Shipment Name cannot be more than 32 characters.'),
  customer: object()
    .label('Customer Account')
    .required(requiredMessage)
    .nullable()
    .shape({
      id: string(),
      company: object()
        .nullable()
        .shape({
          billing_address: object().nullable().shape({
            country: string().nullable(),
            postal_code: string().nullable(),
            address_1: string().nullable(),
            address_2: string().nullable(),
            state_province: string().nullable(),
            city: string().nullable()
          }),
          identifying_codes: object().nullable().shape({
            type: string(),
            value: string()
          })
        })
    }),
  direction: string().label('Import/Export Direction').nullable().required(requiredMessage),
  bol_number: string().nullable(),
  customer_reference_number: string().nullable(),
  po_number: string().nullable(),
  booking_number: string()
    .label('Booking Number')
    .when('direction', (direction: string, schema: StringSchema) =>
      direction === 'EXPORT' ? schema.required(requiredMessage) : schema
    )
    .nullable(),
  estimated_arrival: string().nullable(),
  early_return_date: string().nullable(),
  cut_date: string().nullable(),
  house_bol_number: string().nullable(),
  port_type: string().label('Port Type').nullable().required(requiredMessage),
  steamship_line: string().nullable(),
  identification_codes: array().of(
    object().shape({
      qualifier: string(),
      value: string().nullable().transform(emptyStringToNull)
    })
  ),
  third_party_contact: object().shape({
    email: string().nullable().email('Enter a valid email'),
    first_name: string().nullable(),
    last_name: string().nullable(),
    phone_number: string().nullable()
  }),
  vessel_name: string().nullable(),
  voyage_number: string().nullable(),
  pickup: drayageStopValidationSchema.test(
    'pickup',
    'A valid pickup address is required.',
    (value) => !!value.address_1
  ),
  delivery: drayageStopValidationSchema.test(
    'delivery',
    'A valid delivery address is required.',
    (value) => !!value.address_1
  ),
  containerPickup: drayageStopValidationSchema.when(
    'containerPickupRequired',
    (containerPickupRequired: boolean, schema: typeof drayageStopValidationSchema) =>
      containerPickupRequired
        ? schema.test('containerPickup', 'A valid container pickup address is required.', (value) => !!value.address_1)
        : schema
  ),
  containerReturn: drayageStopValidationSchema.when(
    'containerReturnRequired',
    (containerReturnRequired: boolean, schema: typeof drayageStopValidationSchema) =>
      containerReturnRequired
        ? schema.test('containerReturn', 'A valid container return address is required.', (value) => !!value.address_1)
        : schema
  ),
  containerPickupRequired: boolean(),
  containerReturnRequired: boolean()
});

export type LegFormValues = InferType<typeof validationSchema>;
/**
 * Mutation function to pass undefined payload if no identification codes with nil value,
 * or filter out nil identification codes.
 */
export const getIdentificationCodesPayload = (identificationCodes: OceanTrackingReference[] | {value: string}[]) => {
  const OCEAN_CARRIER_SCAC_IDENTIFICATION_CODE_INDEX = 0;
  if (identificationCodes?.every((code) => !code?.value)) {
    return undefined;
  }
  return identificationCodes
    .map((code, index) => {
      if (index === OCEAN_CARRIER_SCAC_IDENTIFICATION_CODE_INDEX) {
        return {...code, qualifier: OceanTrackingReferenceType.Scac};
      }
    })
    .filter((code) => code?.value);
};
