import {useEffect} from 'react';
import usePrevious from 'App/utils/hooks/usePrevious';

const ScrollToError = (props) => {
  const {errors, isSubmitting, isModal} = props;
  const prevSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && prevSubmitting) {
      //submission just completed
      if (errors && Object.keys(errors).length > 0) {
        const firstError = document.querySelector('.fieldError');
        if (firstError) {
          const offset = 125;
          const elementRect = firstError.getBoundingClientRect().top;
          if (isModal) {
            //offset adjustment for the header size and extra padding to ensure the full field and error is shown
            //scroll the nearest scrollable div
            const scrollContainer = findScrollContainer(firstError);
            const bodyRect = scrollContainer.getBoundingClientRect().top;
            const elementPosition = bodyRect - elementRect;
            const offsetPosition = elementPosition + offset;
            scrollContainer.scrollBy({top: -offsetPosition, behavior: 'smooth'});
          } else {
            //offset adjustment for the header size and extra padding to ensure the full field and error is shown
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo && window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        } else {
          //no errors were displayed, log to rollbar
          if (window.Rollbar) {
            window.Rollbar.error('Uncaught Form Error', errors);
          }
        }
      }
    }
  }, [errors, isSubmitting]);

  return null;
};

/**
 * Function to find the nearest parent element that is scrollable (has overflow 'auto' or 'scroll')
 * @param {Element} element
 */
const findScrollContainer = (element) => {
  if (!element) {
    return undefined;
  }

  let parent = element.parentElement;
  while (parent) {
    const {overflow} = window.getComputedStyle(parent);
    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
      return parent;
    }
    parent = parent.parentElement;
  }
  // default to the document itself
  return document.documentElement;
};

export default ScrollToError;
