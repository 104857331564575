import {
  AmountToleranceLimitDetails,
  Details1,
  DocumentsRequiredDetails,
  InvoiceableStatusDetails,
  InvoiceRuleConfiguration,
  InvoiceRuleConfigurationType,
  ServiceProviderNameMatchDetails
} from '@shipwell/settlements-sdk';

export const isAmountToleranceLimitDetails = (
  details: InvoiceRuleConfiguration['details']
): details is AmountToleranceLimitDetails & Details1 =>
  !!details && 'limit_type' in details && 'limit_value' in details && 'boundary_type' in details;

export const isDocumentsRequiredDetails = (
  details: InvoiceRuleConfiguration['details']
): details is DocumentsRequiredDetails & Details1 =>
  !!details && 'document_types' in details && 'required_per_delivery_stop_document_types' in details;

export type InvoiceRule<TDetails, TRuleType extends InvoiceRuleConfigurationType> = Omit<
  InvoiceRuleConfiguration,
  'rule_type'
> & {
  details: TDetails;
  rule_type: TRuleType;
};

export const isDocumentRequiredRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<DocumentsRequiredDetails, 'DOCUMENT_REQUIRED'> =>
  invoiceRule.rule_type === 'DOCUMENT_REQUIRED';

export const isDocumentsRequiredRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<DocumentsRequiredDetails, 'DOCUMENTS_REQUIRED'> =>
  invoiceRule.rule_type === 'DOCUMENTS_REQUIRED';

export const isFinancialsThresholdRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<AmountToleranceLimitDetails, 'FINANCIALS_THRESHOLD'> =>
  invoiceRule.rule_type === 'FINANCIALS_THRESHOLD';

export const isServiceProviderNameMatchRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<ServiceProviderNameMatchDetails, 'SERVICE_PROVIDER_NAME_MATCH'> =>
  invoiceRule.rule_type === 'SERVICE_PROVIDER_NAME_MATCH';

export const isInvoiceableStatusRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<InvoiceableStatusDetails, 'INVOICEABLE_STATUS'> =>
  invoiceRule.rule_type === 'INVOICEABLE_STATUS';

export const isMultipleInvoicesPerInvoiceableRule = (
  invoiceRule: InvoiceRuleConfiguration
): invoiceRule is InvoiceRule<undefined, 'MULTIPLE_INVOICES_PER_INVOICEABLE'> =>
  invoiceRule.rule_type === 'MULTIPLE_INVOICES_PER_INVOICEABLE';
