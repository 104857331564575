import {Component} from 'react';
import {connect} from 'react-redux';
import SelectField from 'App/formComponents/fields/select';
import {fetchEquipmentTypes} from 'App/actions/shipments';

/**
 * Equipment Types Field
 */
class EquipmentTypesField extends Component {
  static defaultProps = {
    equipmentTypes: [],
    placeholder: '',
    multi: true,
    value: '',
    name: '',
    input: {}
  };

  componentDidMount() {
    const {equipmentTypes} = this.props;

    if (!equipmentTypes.length) {
      this.getShipmentEquipmentTypes();
    }
  }

  /**
   * Request available shipment equipment types
   */
  async getShipmentEquipmentTypes() {
    try {
      const results = {options: []};
      const response = await this.props.dispatch(fetchEquipmentTypes());

      if (response) {
        results.options = response;
      }
      return results;
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {input, placeholder, multi, equipmentTypes, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const options = this.props.options || equipmentTypes;
    const onChange = input.onChange || rest.onChange;

    return (
      <SelectField
        {...rest}
        multi={multi}
        name={name}
        value={value}
        placeholder={placeholder}
        options={options}
        onChange={(value) => onChange && onChange(value)}
      />
    );
  }
}

export default connect((state) => ({
  equipmentTypes: state.shipments.equipmentTypes
}))(EquipmentTypesField);
