import {useState} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {SlimCarrierRelationship} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useCarrierRelationships} from 'App/data-hooks';
import {useDebounce} from 'App/utils/hooks/useDebounce';

export const CarrierFilter = ({
  carrierIds,
  carrierLabels,
  onChange
}: {
  carrierIds: string[];
  carrierLabels: string[];
  onChange: (newIds: string[], newLabels: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);
  const {isLoading, carrierRelationshipsQuery} = useCarrierRelationships({q: debouncedSearchVal, pageSize: 100});
  const options = carrierRelationshipsQuery.data?.results || [];

  // this is probably not the best way to create the default values,
  // but without a way to lookup carrier relationships with multiple Ids,
  // this may be the best we can do
  const defaultValues = carrierIds.map((carrierId, index) => ({
    shipwell_vendor: {id: carrierId},
    name: carrierLabels[index]
  }));

  return (
    <Select
      value={defaultValues}
      onInputChange={setSearchVal}
      onChange={(options: SlimCarrierRelationship[]) => {
        const selectedCarrierIds = options.map((option) => option.shipwell_vendor?.id || '');
        const selectedCarrierNames = options.map((option) => option.name || '');
        onChange(selectedCarrierIds, selectedCarrierNames);
      }}
      isLoading={isLoading}
      options={options}
      isMulti
      label="Search Carriers"
      getOptionValue={(option: SlimCarrierRelationship) => option.shipwell_vendor?.id}
      getOptionLabel={(option: SlimCarrierRelationship) => option.name}
    />
  );
};
