import PropTypes from 'prop-types';
import {Button} from '@shipwell/shipwell-ui';

const EditActionButtons = ({isSubmitting, dirty, onCancelEdit}) => (
  <>
    <div className="flex flex-row-reverse gap-4">
      <Button disabled={isSubmitting || !dirty} isLoading={isSubmitting} type="submit">
        Save
      </Button>
      <Button variant="secondary" disabled={isSubmitting} onClick={onCancelEdit} name="cancel-edit">
        Cancel
      </Button>
    </div>
  </>
);

EditActionButtons.propTypes = {
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  onCancelEdit: PropTypes.func
};

EditActionButtons.defaultProps = {
  isSubmitting: false,
  dirty: false,
  onCancelEdit: () => {}
};

export default EditActionButtons;
