import {
  Weight,
  Volume,
  Distance,
  AirMovementStatusValues,
  DistanceUnitValues,
  VolumeUnitValues,
  WeightUnitValues,
  AirportLocation
} from '@shipwell/locus-sdk';

export const formatStatus = (status: AirMovementStatusValues | string) => {
  switch (status) {
    case AirMovementStatusValues.ArrivedLate:
      return 'Arrived Late';
    case AirMovementStatusValues.Arrived:
      return 'Arrived';
    case AirMovementStatusValues.Delayed:
      return 'Delayed';
    case AirMovementStatusValues.OnTime:
      return 'On Time';
    default:
      return status;
  }
};

export const formatDistance = (distance: Distance | undefined) => {
  if (!distance) {
    return null;
  }

  return `${distance.value} ${distance.units === DistanceUnitValues.Km ? 'km' : 'mi'}`;
};

export const formatWeight = (weight: Weight | undefined) => {
  if (!weight) {
    return null;
  }

  return `${weight.value.toString()} ${weight.units === WeightUnitValues.Lb ? 'lb' : 'kg'}`;
};

export const formatVolume = (volume: Volume | undefined) => {
  if (!volume) {
    return null;
  }

  return `${volume.value.toString()} ${volume.unit === VolumeUnitValues.Ft ? 'ft³' : 'm³'}`;
};

export const formatDateTime = (dateString?: string, timeZone?: string) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  // check if invalid date
  if (isNaN(date.getTime())) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    weekday: 'short',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone
  };

  try {
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  } catch (err: unknown) {
    console.log(err);

    // If the browser does not support the timeZone option, fallback to a simpler format using the Offset
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      weekday: 'short',
      minute: '2-digit',
      timeZoneName: 'short'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  }
};

// TODO: move this to a more general place
export const formatAirportLocation = (airportLocation?: AirportLocation | null) => {
  if (!airportLocation) {
    return null;
  }

  const isLocalityPresent = Boolean(airportLocation.locality);
  const isRegionPresent = Boolean(airportLocation.region);

  // If both locality and region are present, return the full location
  if (isLocalityPresent && isRegionPresent) {
    return `${airportLocation.name} (${airportLocation.code}), ${airportLocation.locality}, ${airportLocation.region}`;
  }

  // If only locality is present, return the location without the region
  if (isLocalityPresent) {
    return `${airportLocation.name} (${airportLocation.code}), ${airportLocation.locality}`;
  }

  // If only region is present, return the location without the locality
  if (isRegionPresent) {
    return `${airportLocation.name} (${airportLocation.code}), ${airportLocation.region}`;
  }

  // If neither locality nor region are present, return only the name and code
  return `${airportLocation.name} (${airportLocation.code})`;
};
