import {Component} from 'react';
import {Field} from 'redux-form';
import InputField from 'App/formComponents/fields/input';
import ImageSelector from 'App/formComponents/fields/imageSelector';

class MiniProductFields extends Component {
  static defaultProps = {
    slimForm: false
  };

  render() {
    const {error} = this.props;

    return (
      <div>
        <Field name="image" component={ImageSelector} />
        <Field
          req
          label="Reference"
          name="product_ref"
          placeholder="SKU or other primary product reference"
          component={InputField}
        />
        <Field label="Value Per Piece ($)" name="value_per_piece" placeholder="$0.00" component={InputField} />
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </p>
        )}
      </div>
    );
  }
}

export default MiniProductFields;
