import {MapContainer} from 'App/components/ShipmentTrackingOverview/MapContainer';
import {TimelineContainer} from 'App/components/ShipmentTrackingOverview/TimelineContainer';

export const ShipmentTrackingOverview = ({shipmentId}: {shipmentId: string}) => {
  return (
    <div className="grid min-h-full grid-cols-[25%,75%]">
      <div className="max-w-md overflow-y-auto rounded-md border-y-1 border-r-1 border-sw-disabled p-2 h-screen-minus-56">
        <TimelineContainer shipmentId={shipmentId} />
      </div>
      <div className="h-auto">
        <MapContainer shipmentId={shipmentId} />
      </div>
    </div>
  );
};
