import {Dispatch, SetStateAction, createContext, useState} from 'react';
import {noop} from 'lodash';
import {Modal, StepIndicator, useStepIndicator} from '@shipwell/shipwell-ui';
import {FacilityCreationForm} from 'App/data-hooks/facilities/types';
import {facilityCreationSteps, facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';

import type {WithStatusToastProps} from 'App/components/withStatusToasts';
import WithStatusToasts from 'App/components/withStatusToasts';
import StepForms from 'App/containers/facilities/FacilityCreationModal/StepForms';

export type StepFormContextType = {
  activeStep: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  onClose: () => void;
  facilityFormData: FacilityCreationForm;
  setFacilityFormData: Dispatch<SetStateAction<FacilityCreationForm>>;
  setFacilityCreationToast: ({title, message, isError}: {title: string; message: string; isError?: boolean}) => void;
};
export const StepFormContext = createContext<StepFormContextType>({
  activeStep: 0,
  setNextStep: noop,
  setPreviousStep: noop,
  onClose: noop,
  facilityFormData: facilityFormDataDefaultValues,
  setFacilityFormData: noop,
  setFacilityCreationToast: noop
});

const FacilityCreationModalBase = ({
  showModal,
  toggleModal,
  setError,
  setSuccess
}: {showModal: boolean; toggleModal: () => void} & WithStatusToastProps) => {
  const {activeStep, setNextStep, setPreviousStep, setActiveStep} = useStepIndicator(facilityCreationSteps);
  const [facilityFormData, setFacilityFormData] = useState(facilityFormDataDefaultValues);
  const hasSidebar = activeStep === 1 || activeStep === 3 || activeStep === 4;
  const hasStepScroll = activeStep === 0 || activeStep === 2 || activeStep === 4;
  const onClose = () => {
    setActiveStep(0);
    toggleModal();
  };

  const setFacilityCreationToast = ({title, message, isError}: {title: string; message: string; isError?: boolean}) => {
    if (isError) {
      setError(title, message);
      return;
    }
    setSuccess(title, message);
  };

  return (
    <Modal
      size="large"
      title="New Facility"
      show={showModal}
      bodyClassName={hasSidebar ? 'pl-0' : null}
      bodyVariant={hasStepScroll ? null : 'disableOverflowScroll'}
      onClose={onClose}
      footerComponent={null}
    >
      <div className="m-auto flex items-center justify-center md:w-1/2">
        <StepIndicator steps={facilityCreationSteps} activeStep={activeStep} alternativeLabel />
      </div>
      <StepFormContext.Provider
        value={{
          activeStep,
          setNextStep,
          setPreviousStep,
          onClose,
          facilityFormData,
          setFacilityFormData,
          setFacilityCreationToast
        }}
      >
        <StepForms />
      </StepFormContext.Provider>
    </Modal>
  );
};

const FacilityCreationModal = WithStatusToasts(FacilityCreationModalBase);
export default FacilityCreationModal;
