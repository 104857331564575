import {TenderChargeLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import {roundNumberToSpecificDecimals} from 'App/utils/mathHelpers';
import {formatCurrencyValue} from 'App/utils/globals';
import {createGrammaticList} from 'App/utils/grammar';
import {useGetContractChargeItems} from 'App/data-hooks/contracts/useGetContractChargeItems';
import {ApplicableContractWithCharges} from 'App/data-hooks/contracts/useGetApplicableContracts';
import ShipwellLoader from 'App/common/shipwellLoader';
import {RATE_TYPE_PER_HOUR, RATE_TYPE_PER_MILE} from 'App/containers/userCompany/rateTables/constants';
import {calculateChargeItemTotal, calculateTotalForCharges} from 'App/data-hooks/contracts/utils';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {formatShipmentModeCode} from 'App/utils/globalsTyped';

export const SelectedContract = ({
  applicableContractWithCharges,
  showFinancialInfo = true,
  modes,
  equipment,
  shipmentId,
  isLoading
}: {
  applicableContractWithCharges: ApplicableContractWithCharges;
  showFinancialInfo?: boolean;
  modes: string[];
  equipment: string[];
  shipmentId?: string;
  calculatedChargeItems?: TenderChargeLineItem[];
  isLoading?: boolean;
}) => {
  const {contract, contract_lane: contractLane, charges, chargeItemsTotals, total} = applicableContractWithCharges;
  const displayCurrency = contract?.rate_currency || chargeItemsTotals?.currency || 'USD';
  const contractId = contract?.id;
  const chargeItemsAccessorials = chargeItemsTotals?.accessorials;

  const {
    isLoadingChargeItems,
    lineHaulCharge,
    fuelCharge,
    additionalStopCharges,
    total: calculatedTotal
  } = useGetContractChargeItems({
    contractId,
    shipmentId,
    // only get charges if they don't exist on the applicableContract
    options: {enabled: !charges?.length}
  });

  const {lineHaulTotal, fuelChargeTotal, additionalStopChargeTotal} = chargeItemsTotals || {};

  const displayLineHaulCharge = lineHaulTotal || calculateChargeItemTotal(lineHaulCharge);
  const displayFuelCharge = fuelChargeTotal || calculateChargeItemTotal(fuelCharge);
  const displayAdditionalStopCharge = additionalStopChargeTotal || calculateTotalForCharges(additionalStopCharges);

  const displayTotal =
    total ||
    calculatedTotal ||
    (displayLineHaulCharge || 0) + (displayFuelCharge || 0) + (displayAdditionalStopCharge || 0);

  const rate = contractLane?.rate_info?.rate || contract?.rate;
  const rateType = contractLane?.rate_info?.rate_type || contract?.rate_type;

  const displayRate =
    typeof total === 'number' && ![RATE_TYPE_PER_HOUR, RATE_TYPE_PER_MILE].some((rate) => rate === rateType)
      ? isLoadingChargeItems
        ? '--'
        : formatCurrencyValue(total)
      : formatCurrencyValue(rate);

  const displayRateType = startCaseToLower(rateType) || '--';

  const minimumRate = contractLane?.rate_info?.minimum_rate || contract?.minimum_rate;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 px-1">
        <div>
          <div className="space-x-1">
            <span className="font-bold">Carrier:</span>
            <span>{contract?.carrier_name || '--'}</span>
          </div>
          <div className="space-x-1">
            <span className="font-bold">Rate:</span>
            <span>
              {displayCurrency} {displayRate} {displayRateType}
            </span>
          </div>
          {minimumRate ? (
            <div className="space-x-1">
              <span className="font-bold">Minimum Rate:</span>
              <span>
                {displayCurrency} {formatCurrencyValue(minimumRate)}
              </span>
            </div>
          ) : null}
        </div>
        <div>
          <div className="space-x-1">
            <span className="font-bold">Modes:</span>
            <span>{createGrammaticList(modes.map((mode) => formatShipmentModeCode(mode) || '')) || '--'}</span>
          </div>
          <div className="space-x-1">
            <span className="font-bold">Equipment:</span>
            <span>{createGrammaticList(equipment) || '--'}</span>
          </div>
        </div>
      </div>
      {showFinancialInfo ? (
        <div className="flex gap-4 rounded bg-sw-background p-4">
          {isLoadingChargeItems || isLoading ? (
            <ShipwellLoader loading />
          ) : (
            <div className="w-full">
              {!!chargeItemsAccessorials?.length &&
                chargeItemsAccessorials.map((charge, index) => {
                  if (!charge.name || !charge.total) return null;

                  return (
                    <ChargeItem
                      key={index}
                      label={charge.name}
                      amount={charge.total}
                      currencyOfRecord={displayCurrency}
                    />
                  );
                })}
              {displayLineHaulCharge && rateType !== RATE_TYPE_PER_HOUR ? (
                <ChargeItem label="Line Haul" amount={displayLineHaulCharge} currencyOfRecord={displayCurrency} />
              ) : (
                <ChargeItem label="Line Haul" notApplicable currencyOfRecord={displayCurrency} />
              )}
              {displayFuelCharge && displayFuelCharge > 0 ? (
                <ChargeItem label="Fuel Surcharge" amount={displayFuelCharge} currencyOfRecord={displayCurrency} />
              ) : (
                <ChargeItem label="Fuel Surcharge" notApplicable currencyOfRecord={displayCurrency} />
              )}
              {displayAdditionalStopCharge && displayAdditionalStopCharge > 0 ? (
                <ChargeItem
                  label="Stop Charges"
                  amount={displayAdditionalStopCharge}
                  currencyOfRecord={displayCurrency}
                />
              ) : (
                <ChargeItem label="Stop Charges" notApplicable currencyOfRecord={displayCurrency} />
              )}
              {rateType !== RATE_TYPE_PER_HOUR ? (
                <ChargeItem label="Estimated Total" amount={displayTotal} currencyOfRecord={displayCurrency} />
              ) : (
                <ChargeItem label="Estimated Total" notApplicable currencyOfRecord={displayCurrency} />
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

type ChargeItemType = {
  label: string;
  currencyOfRecord: string;
} & (
  | {
      amount: number;
      notApplicable?: undefined;
    }
  | {
      amount?: undefined;
      notApplicable: boolean;
    }
);

const ChargeItem = ({label, amount, notApplicable, currencyOfRecord}: ChargeItemType) => {
  return (
    <div className="box-border flex justify-between">
      <span className="whitespace-nowrap">{label}</span>
      <div className="m-1 w-full border-b-2 border-dotted border-sw-text" />
      {amount ? (
        <span className="whitespace-nowrap font-bold">
          {currencyOfRecord} {formatCurrencyValue(roundNumberToSpecificDecimals(amount, 2))}
        </span>
      ) : null}
      {notApplicable ? <span className="whitespace-nowrap font-bold">N/A</span> : null}
    </div>
  );
};
