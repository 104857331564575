/* eslint-disable max-len */
import {ProviderCode, ProvidersApi} from '@shipwell/genesis-sdk';
import {PaymentsApi} from '@shipwell/backend-core-sdk';
import axios from 'axios';
import {getShipwellApi, basePath, basePathWithOutV2} from 'App/api/config';
import {apiCallback, getAccessToken} from 'App/api/utils';

/**
 * HTTP Client for managing all registration for payments.
 * @public
 * @method getPaymentsApi
 * @returns {import('@shipwell/backend-core-sdk').PaymentsApi}
 */
export function getPaymentsApi() {
  const configuration = {
    basePath: basePath,
    apiKey: getAccessToken,
    isJsonMime: (mime) => ['application/json', 'application/json; charset=UTF8'].includes(mime)
  };

  return new PaymentsApi(configuration);
}

export const genesisBasePath = process.env.SHIPWELL_GENESIS_API_BASE_PATH;
/**
 * HTTP Client for managing all registration for integrations.
 * @public
 * @method getCarrierIntegrationApi
 * @returns {import('@shipwell/genesis-sdk').ProvidersApi}
 */
export function getCarrierIntegrationApi() {
  const configuration = {
    basePath: genesisBasePath,
    apiKey: getAccessToken,
    isJsonMime: (mime) => ['application/json', 'application/json; charset=UTF8'].includes(mime)
  };

  return new ProvidersApi(configuration);
}

/**
 * QuickBooks Authentication
 */
export async function authenticateQuickBooks(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksAuthGet(opts, apiCallback(resolve, reject));
  });
}

export async function checkQuickBooksAuthentication() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksAuthCheckGet(apiCallback(resolve, reject));
  });
}

/**
 * QuickBooks Invoicing Customers
 */
export async function getQuickBooksCustomers(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersGet(opts, apiCallback(resolve, reject));
  });
}

export async function populateQuickBooksCustomers() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersPopulatePost(apiCallback(resolve, reject));
  });
}

export async function createQuickBooksCustomerMapping(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersMappingPost(body, apiCallback(resolve, reject));
  });
}

export async function checkQuickBooksCustomerCompanyMapping(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksCustomersMappingCompanyIdGet(companyId, apiCallback(resolve, reject));
  });
}

/**
 * QuickBooks Invoicing
 */
export async function sendInvoice(invoiceId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksInvoicesInvoiceIdEmailPost(
      invoiceId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * QuickBooks Billing Vendors
 */
export async function getQuickBooksVendors(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsGet(opts, apiCallback(resolve, reject));
  });
}

export async function createQuickBooksVendor(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsPost(body, apiCallback(resolve, reject));
  });
}

export async function populateQuickBooksVendors() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsPopulatePost(apiCallback(resolve, reject));
  });
}

export async function createQuickBooksVendorMapping(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsMappingPost(body, apiCallback(resolve, reject));
  });
}

export async function checkQuickBooksVendorMapping(companyId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksVendorsMappingCompanyIdGet(companyId, apiCallback(resolve, reject));
  });
}

/**
 * QuickBooks Expense Classes
 */
export async function populateQuickBooksExpenseClasses() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseClassesPost(apiCallback(resolve, reject));
  });
}

export async function getQuickBooksExpenseClasses(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksExpenseClassesGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * QuickBooks Terms
 */
export async function getQuickBooksPaymentTerms() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksPaymentTermsGet(apiCallback(resolve, reject));
  });
}

export async function createQuickBooksPaymentTerms() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingIntegrationsQuickbooksPaymentTermsPost(apiCallback(resolve, reject));
  });
}

/**
 * QuickBooks Disconnect
 */

export async function disconnectQuickBooks() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingIntegrationsQuickbooksAuthRevokeAuthorizationPost(apiCallback(resolve, reject));
  });
}

/**
 * Looker
 */
export async function authenticateLooker(lookerResource) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.analyticsIntegrationsLookerSignurlGet(lookerResource, apiCallback(resolve, reject));
  });
}

export async function getLookerDashboards() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.analyticsIntegrationsLookerContentGet(apiCallback(resolve, reject));
  });
}

/**
 * Automated Vendors
 */
export async function getAutomatedVendors() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersIntegrationsAutomatedVendorsGet(apiCallback(resolve, reject));
  });
}

/**
 * FedEx Registration
 */
export async function postFedexRegistration(account_number, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationFEDEXPost(body, {account_number: account_number}, apiCallback(resolve, reject));
  });
}

/**
 * TriumphPay Authentication
 */
export async function authenticateTriumphPay(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayAuthGet(opts, apiCallback(resolve, reject));
  });
}

export async function checkTriumphPayAuthentication() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayAuthCheckGet(apiCallback(resolve, reject));
  });
}

export async function getTriumphPayAuthenticationDetails() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.paymentsIntegrationsTriumphPayAuthAuthorizationDetailsGet(apiCallback(resolve, reject));
  });
}

/**
 * RMIS Integration
 */
export async function postFTPRegistration(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthFtpRegistrationPost(body, apiCallback(resolve, reject));
  });
}

export async function getFTPCredentials() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthFtpRegistrationGet(apiCallback(resolve, reject));
  });
}

export async function postAPIRegistration(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthApiRegistrationPost(body, apiCallback(resolve, reject));
  });
}

export async function getAPICredentials() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthApiRegistrationGet(apiCallback(resolve, reject));
  });
}

export async function checkRMISAuthentication() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthCheckGet(apiCallback(resolve, reject));
  });
}
export async function disconnectFTPConnection(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthFtpRegistrationRmisFTPAccountIdDelete(
      id,
      apiCallback(resolve, reject)
    );
  });
}

export async function disconnectAPIConnection(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisAuthApiRegistrationRmisAPIAccountIdDelete(
      id,
      apiCallback(resolve, reject)
    );
  });
}

export async function refreshRMISFTPConnection() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsRmisSyncPost(apiCallback(resolve, reject));
  });
}

/**
 * Invoicing
 */

export async function getShipmentInvoices(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingShipmentsShipmentIdInvoicesGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function getCompanyInvoice(invoiceId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingInvoicesInvoiceIdGet(invoiceId, apiCallback(resolve, reject));
  });
}

export async function createShipmentInvoice(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingShipmentsShipmentIdInvoicesPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

export async function sendShipmentInvoice(invoiceId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.invoicingInvoicesInvoiceIdEmailPost(invoiceId, opts, apiCallback(resolve, reject));
  });
}

/**
 * Billing
 */

export async function getShipmentBills(shipmentId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingShipmentsShipmentIdBillsGet(shipmentId, opts, apiCallback(resolve, reject));
  });
}

export async function getBill(billId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingBillsBillIdGet(billId, apiCallback(resolve, reject));
  });
}

export async function createShipmentBill(shipmentId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.billingShipmentsShipmentIdBillsPost(shipmentId, body, apiCallback(resolve, reject));
  });
}

/**
 * FMS
 * Returns all Financial Management Systems that are configured
 */

export async function getFinancialManagementSystems() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.integrationsFmsGet(apiCallback(resolve, reject));
  });
}

/**
 * Saferwatch
 */

export async function getSaferwatchData() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsSaferwatchAuthApiRegistrationGet(apiCallback(resolve, reject));
  });
}

export async function postSaferwatchRegistration(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsSaferwatchAuthApiRegistrationPost(body, apiCallback(resolve, reject));
  });
}

export async function disconnectSaferwatch(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsSaferwatchAuthApiRegistrationSaferWatchAccountIdDelete(
      id,
      apiCallback(resolve, reject)
    );
  });
}

export async function refreshSaferwatchConnection() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsSaferwatchSyncPost(apiCallback(resolve, reject));
  });
}

export async function checkSaferwatchAuthentication() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsSaferwatchAuthCheckGet(apiCallback(resolve, reject));
  });
}

/**
 * FedEx
 */

export async function checkFedexAuthentication() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsFedexAuthCheckGet(apiCallback(resolve, reject));
  });
}

export async function getFedexAccounts(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsFedexAuthApiRegistrationGet(opts, apiCallback(resolve, reject));
  });
}

export async function deleteFedexAccount(accountNumber) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsFedexAuthApiRegistrationFedexAccountNumberDelete(
      accountNumber,
      apiCallback(resolve, reject)
    );
  });
}

export async function updateFedexAccount(fedexAccountId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.registrationFEDEXFedexAccountIdPut(fedexAccountId, body, apiCallback(resolve, reject));
  });
}

export async function getFedexAccountDetails(accountNumber) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsFedexAuthApiRegistrationFedexAccountNumberGet(
      accountNumber,
      apiCallback(resolve, reject)
    );
  });
}

export async function disconnectFedex() {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.carrierComplianceIntegrationsFedexDisconnectDelete(apiCallback(resolve, reject));
  });
}

export const deleteAmazonFreight = async (opts = {}) =>
  await getCarrierIntegrationApi().deleteProviderProvidersProviderCodeDelete(ProviderCode.Amazon, opts);

export const disconnectAmazonFreight = async (companyId) => {
  const accessToken = await getAccessToken();
  await axios.delete(`${basePathWithOutV2}/amazon-freight/disconnect/${companyId}`, {
    headers: {authorization: accessToken}
  });
  await deleteAmazonFreight();
};
