import {Button, Modal} from '@shipwell/shipwell-ui';
import {DevicesForm} from './devicesForm/devicesForm';
import {DevicesList} from './devicesList/devicesList';
import {useDeviceService} from './useDeviceService/useDeviceService';

export interface ManageTrackingDevicesModalProps {
  show: boolean;
  shipmentId: string;
  onClose: () => void;
}

export function ManageTrackingDevicesModal(props: ManageTrackingDevicesModalProps) {
  const service = useDeviceService({
    shipmentId: props.shipmentId,
    isEnabled: props.show
  });

  return (
    <Modal
      title={'Manage Tracking Devices'}
      show={props.show}
      size={'medium'}
      bodyClassName={'overflow-visible'}
      primaryActions={
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
      }
      onClose={props.onClose}
    >
      <div className="flex flex-col gap-3">
        <div>
          <DevicesForm isLoading={service.isAttaching} onSubmit={service.attach} />
        </div>
        <div>
          <DevicesList
            shipmentId={props.shipmentId}
            isFetching={service.isFetching}
            isAttaching={service.isAttaching}
            devices={service.attachedDevices}
            onDisconnected={service.refetch}
          />
        </div>
      </div>
    </Modal>
  );
}
