import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import * as actionsCarrier from 'App/actions/carriers';
import {InputGroup, Button, FormGroup, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DisplayLoad from './DisplayLoad';
import Track from './Track';
import './_dispatch.scss';
import Paper from '@material-ui/core/Paper';
import * as actions from 'App/actions/shipments';

@connect(
  (state) => ({
    company: state.auth.company,
    shipments: state.shipments.all,
    powerUnits: state.carriers.powerUnits,
    powerUnitLocations: state.carriers.powerUnitLocations,
    trailers: state.carriers.trailers,
    isLoading: state.carriers.isLoading
  }),
  {...actions, ...actionsCarrier}
)
export default class Dispatch extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.setActiveDisplayTab = this.setActiveDisplayTab.bind(this);
    this.getShipments = this.getShipments.bind(this);
    this.updateShipments = this.updateShipments.bind(this);
    this.filterPULocations = this.filterPULocations.bind(this);
    this.getPowerUnitLocations = this.getPowerUnitLocations.bind(this);
    this.searchDataTable = this.searchDataTable.bind(this);
    this.loadSearch = this.loadSearch.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.changePage = this.changePage.bind(this);
    this.state = {
      activeInfoTab: 'assets',
      assigned: [],
      assignedTotal: 0,
      assignedPages: 0,
      unassigned: [],
      unassignedTotal: 0,
      unassignedPages: 0,
      filteredPULocations: [],
      isLoading: false,
      searchValue: '',
      pagination: {
        page: 1,
        pageSize: 10,
        ordering: '-pickup',
        archived: false,
        statusExclude: ['delivered', 'cancelled', 'quoting', 'draft']
      },
      powerUnitPagination: {
        page: 1,
        pageSize: 200,
        ordering: 'name'
      },
      trailerPagination: {
        page: 1,
        pageSize: 200,
        ordering: 'name'
      },
      driverPagination: {
        page: 1,
        pageSize: 200
      }
    };
    this._count = 1;
  }

  componentDidMount() {
    this.props.resetShipments();

    if (!_.isEmpty(this.props.company)) {
      const pagination = this.state.pagination;
      if (this.props.company.carrier) {
        pagination.vendorId = this.props.company.id;
      }
      this.getPowerUnitLocations();
      this.setState(
        {pagination: pagination},
        function () {
          this.getShipments();
        }.bind(this)
      );
    }
    const infoTab = localStorage.getItem('dispatchInfoTab');
    if (infoTab) {
      this.setState({activeInfoTab: infoTab});
    }
    if (window.innerWidth < 640) {
      this.setState({smallScreen: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company && !_.isEmpty(this.props.company)) {
      this.getPowerUnitLocations();

      const pagination = this.state.pagination;
      if (this.props.company.carrier) {
        pagination.vendorId = this.props.company.id;
      }
      const infoTab = localStorage.getItem('dispatchInfoTab');
      if (infoTab) {
        this.setState({activeInfoTab: infoTab});
      }
      this.setState(
        {pagination: pagination},
        function () {
          this.getShipments();
        }.bind(this)
      );

      if (window.innerWidth < 640) {
        this.setState({smallScreen: true});
      }
    }
  }

  getPowerUnitLocations() {
    if (this.props.company?.carrier?.id) {
      this.props.getPowerUnitLocations(this.props.company.carrier.id).then((response) => {
        if (this.props.powerUnitLocations) {
          this.setState({
            filteredPULocations: this.props.powerUnitLocations.vehicle_locations
          });
        }
      });
    }
  }

  getShipments() {
    this.setState(
      {isLoading: true},
      function () {
        const assigned = [];
        let assignedTotal;
        const unassigned = [];
        let unassignedTotal;
        const pagination = JSON.parse(JSON.stringify(this.state.pagination));
        pagination.driverAssigned = true;
        this.props
          .getShipments(pagination, this._count)
          .then((response) => {
            if (response.status === 200) {
              const shipments = this.props.shipments ? this.props.shipments.results : [];
              for (let i = 0; i < shipments.length; i++) {
                assigned.push(shipments[i]);
              }
              if (this.props.shipments) {
                assignedTotal = this.props.shipments.total_count;
              }
            }
          })
          .then((response) => {
            this.setState({
              assigned: assigned,
              assignedTotal: assignedTotal,
              isLoading: false,
              assignedPages: this.props.shipments.total_pages
            });
          });
        pagination.driverAssigned = false;
        this.props
          .getShipments(pagination, this._count)
          .then((response) => {
            if (response.status === 200) {
              const shipments = this.props.shipments ? this.props.shipments.results : [];
              for (let i = 0; i < shipments.length; i++) {
                unassigned.push(shipments[i]);
              }
              if (this.props.shipments) {
                unassignedTotal = this.props.shipments.total_count;
              }
            }
          })
          .then((response) => {
            this.setState({
              unassigned: unassigned,
              unassignedTotal: unassignedTotal,
              isLoading: false,
              unassignedPages: this.props.shipments.total_pages
            });
          });

        this._count++;
        if (this.props.company?.carrier?.id) {
          this.props.getPowerUnits(this.props.company.carrier.id, this.state.powerUnitPagination);
          this.props.getTrailers(this.props.company.carrier.id, this.state.trailerPagination);
        }
        this.props.getDrivers(this.props.company.id, this.state.driverPagination);
      }.bind(this)
    );
  }

  updateShipments() {
    this.setState(
      {isLoading: true},
      function () {
        const pagination = JSON.parse(JSON.stringify(this.state.pagination));
        if (this.state.activeInfoTab === 'assigned') {
          const assigned = [];
          let assignedTotal;
          pagination.driverAssigned = true;
          this.props
            .getShipments(pagination, this._count)
            .then((response) => {
              if (response.status === 200) {
                const shipments = this.props.shipments ? this.props.shipments.results : [];
                for (let i = 0; i < shipments.length; i++) {
                  assigned.push(shipments[i]);
                }
                if (this.props.shipments) {
                  assignedTotal = this.props.shipments.total_count;
                }
              }
            })
            .then((response) => {
              this.setState({
                assigned: assigned,
                assignedTotal: assignedTotal,
                isLoading: false,
                assignedPages: this.props.shipments.total_pages
              });
            });
        } else if (this.state.activeInfoTab === 'unassigned') {
          const unassigned = [];
          let unassignedTotal;
          pagination.driverAssigned = false;
          this.props
            .getShipments(pagination, this._count)
            .then((response) => {
              if (response.status === 200) {
                const shipments = this.props.shipments ? this.props.shipments.results : [];
                for (let i = 0; i < shipments.length; i++) {
                  unassigned.push(shipments[i]);
                }
                if (this.props.shipments) {
                  unassignedTotal = this.props.shipments.total_count;
                }
              }
            })
            .then((response) => {
              this.setState({
                unassigned: unassigned,
                unassignedTotal: unassignedTotal,
                isLoading: false,
                unassignedPages: this.props.shipments.total_pages
              });
            });
        }
        this._count++;
      }.bind(this)
    );
  }

  filterPULocations() {
    const filteredPULocations = this.state.filteredPULocations.filter((el) => {
      let name;
      if (this.props.powerUnits) {
        for (let i = 0; i < this.props.powerUnits.results.length; i++) {
          if (this.props.powerUnits.results[i].external_id === el.vehicle_id) {
            name = this.props.powerUnits.results[i].name;
          }
        }
      }
      if (el.location_name && el.location_name.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
        return el;
      }
      if (el.vehicle_id && el.vehicle_id.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
        return el;
      }
      if (name && name.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
        return el;
      }
    });
    this.setState({filteredPULocations: filteredPULocations});
  }

  setActiveDisplayTab(tab) {
    localStorage.setItem('dispatchInfoTab', tab);
    const pagination = this.state.pagination;
    pagination.page = 1;
    if (tab === 'assigned' || tab === 'unassigned') {
      this.setState({
        activeInfoTab: tab,
        searchValue: '',
        pagination: pagination
      });
      this.updateShipments();
    } else {
      this.setState({
        activeInfoTab: tab,
        searchValue: '',
        pagination: pagination
      });
    }
  }

  searchDataTable(e) {
    clearTimeout(this.timer);
    const str = e.target.value;
    this.setState({searchValue: str});
    if (!str) {
      this.setState({searchValue: ''}, function () {
        if (this.state.activeInfoTab === 'assets') {
          if (this.props.powerUnitLocations) {
            this.setState({
              filteredPULocations: this.props.powerUnitLocations.vehicle_locations
            });
          }
        } else {
          const pagination = this.state.pagination;
          pagination.q = '';
          this.setState({pagination: pagination});
        }
      });
    }
  }

  loadSearch() {
    const pagination = this.state.pagination;
    pagination.q = this.state.searchValue + '*';
    pagination.page = 1;
    this.setState(
      {pagination: pagination},
      function () {
        this.updateShipments();
      }.bind(this)
    );
  }

  changePage(change) {
    const pagination = JSON.parse(JSON.stringify(this.state.pagination));
    if (change === 'next') {
      pagination.page++;
    } else {
      pagination.page--;
    }
    this.setState({pagination: pagination}, () => this.updateShipments());
  }

  renderPagination() {
    const pageArray = [];
    let length;
    if (this.state.activeInfoTab === 'assigned') {
      length = this.state.assignedPages;
    } else if (this.state.activeInfoTab === 'unassigned') {
      length = this.state.unassignedPages;
    }
    for (let i = 0; i < length; i++) {
      pageArray.push(i + 1);
    }
    return (
      <div className="dispatch-load__shipments-pagination">
        <Button
          onClick={() => {
            this.changePage('prev');
          }}
          disabled={this.state.pagination.page === 1}
        >
          <i className="icon icon-Left" />
        </Button>
        <FormControl
          componentClass="select"
          onChange={(event) => {
            const pagination = JSON.parse(JSON.stringify(this.state.pagination));
            pagination.page = event.target.value;
            this.updateShipments();
            this.setState({pagination: pagination});
          }}
          defaultValue={this.state.pagination.page}
        >
          {pageArray.map((el) => {
            return (
              <option key={el} value={el}>
                {el}
              </option>
            );
          })}
        </FormControl>
        <Button
          onClick={() => {
            this.changePage('next');
          }}
          disabled={this.state.pagination.page === length}
        >
          <i className="icon icon-Right" />
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="content-wrapper dispatch">
        <section className="content">
          <div className="dispatch__tabs">
            <div
              onClick={() => {
                this.setActiveDisplayTab('assets');
              }}
              className={'h2 ' + (this.state.activeInfoTab === 'assets' ? 'active' : '')}
            >
              <p>{this.props.isLoading ? '-' : this.state.filteredPULocations.length}</p>
              Assets
            </div>
            <div
              onClick={() => {
                this.setActiveDisplayTab('assigned');
              }}
              className={'h2 ' + (this.state.activeInfoTab === 'assigned' ? 'active' : '')}
            >
              <p>{this.state.isLoading ? '-' : this.state.assignedTotal}</p>
              Assigned Loads
            </div>
            <div
              onClick={() => {
                this.setActiveDisplayTab('unassigned');
              }}
              className={'h2 ' + (this.state.activeInfoTab === 'unassigned' ? 'active' : '')}
            >
              <p>{this.state.isLoading ? '-' : this.state.unassignedTotal}</p>
              Unassigned Loads
            </div>
          </div>

          {this.state.activeInfoTab === 'assets' && (
            <Track
              smallScreen={this.state.smallScreen}
              filteredPULocations={this.state.filteredPULocations}
              searchDataTable={this.searchDataTable}
              filterPULocations={this.filterPULocations}
              searchValue={this.state.searchValue}
            />
          )}
          {this.state.activeInfoTab === 'assigned' && (
            <DisplayLoad
              dispatchShipments={this.state.assigned}
              activeInfoTab={'assigned'}
              powerUnits={this.props.powerUnits}
              trailers={this.props.trailers}
              updateShipments={this.updateShipments}
              smallScreen={this.state.smallScreen}
              isLoading={this.state.isLoading}
              company={this.props.company}
              searchDataTable={this.searchDataTable}
              searchValue={this.state.searchValue}
              loadSearch={this.loadSearch}
              obtainShipments={this.getShipments}
              renderPagination={this.renderPagination}
            />
          )}
          {this.state.activeInfoTab === 'unassigned' && (
            <DisplayLoad
              dispatchShipments={this.state.unassigned}
              activeInfoTab={'unassigned'}
              powerUnits={this.props.powerUnits}
              trailers={this.props.trailers}
              updateShipments={this.updateShipments}
              smallScreen={this.state.smallScreen}
              isLoading={this.state.isLoading}
              company={this.props.company}
              searchDataTable={this.searchDataTable}
              searchValue={this.state.searchValue}
              loadSearch={this.loadSearch}
              obtainShipments={this.getShipments}
              renderPagination={this.renderPagination}
            />
          )}
        </section>
      </div>
    );
  }
}
