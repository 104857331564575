import {SvgIcon} from '@shipwell/shipwell-ui';
import {useAttachedDocumentTypes} from './hooks';
import {isDocumentRequiredRule} from './utils';
import Loader from 'App/common/shipwellLoader';
import {useInvoiceRuleConfigurations} from 'App/data-hooks/invoices/useInvoiceRuleConfigurations';
import {useDocumentShortNames} from 'App/data-hooks';

type RequiredDocsDisplayValues = {label: string; isAttached: boolean};

const RequiredDocuments = ({
  shipmentId,
  invoiceSource
}: {
  shipmentId: string;
  invoiceSource: 'carrier_generated' | 'shipper_generated';
}) => {
  const {data: shortNames, isLoading: isShortNamesLoading} = useDocumentShortNames();
  const {data: ruleConfigurations, isLoading: isRuleConfigurationsLoading} = useInvoiceRuleConfigurations(
    shipmentId,
    invoiceSource
  );
  const attachedDocumentTypes = useAttachedDocumentTypes();

  if (isRuleConfigurationsLoading || isShortNamesLoading)
    return <Loader loading additionalClassNames={['w-full h-[10rem]']} />;

  const documentRequiredRules = ruleConfigurations?.filter(isDocumentRequiredRule);

  const requiredDocuments = documentRequiredRules?.reduce<RequiredDocsDisplayValues[]>((requiredDocs, rule) => {
    const {document_types} = rule.details;
    const documentTypesOptionsList = document_types.map((documentType) => {
      //we want to show the 'short name' as the label for the document
      return {label: shortNames ? shortNames[documentType] : documentType, id: documentType};
    });
    //check if we've already required a document of the type defined in the rule
    const isDuplicate = requiredDocs.find((requiredDoc) =>
      documentTypesOptionsList.find((document) => document.label === requiredDoc.label)
    );
    documentTypesOptionsList.forEach((option) => {
      if (option.label && !isDuplicate) {
        requiredDocs.push({
          label: option.label,
          isAttached: attachedDocumentTypes.includes(option.id)
        });
      }
    });
    return requiredDocs;
  }, []);

  const isRuleEnabled = documentRequiredRules?.some((rule) => rule.enabled);
  if (!requiredDocuments?.length || !isRuleEnabled) return null;

  return (
    <div>
      <div className="text-xxs uppercase">Required Documents</div>

      <ul className="flex flex-col gap-1 p-2 pb-4">
        {requiredDocuments?.map(({label, isAttached}) => {
          return (
            <li
              key={label}
              className={`${isAttached ? 'text-success' : 'text-sw-disabled-text'} flex items-center gap-1 text-sm`}
              aria-label={`${label} (this required document ${isAttached ? 'is' : 'is not'} attached)`}
            >
              <SvgIcon
                aria-label={isAttached ? 'Checked checkmark' : 'Unchecked checkmark'}
                color={isAttached ? 'sw-success' : 'sw-disabled-text'}
                name={isAttached ? 'CheckCircleFilled' : 'CheckCircleOutlined'}
              />
              {label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RequiredDocuments;
