import classNames from 'classnames';
import Loader from 'App/common/shipwellLoader';
const ListViewLoader = ({className}: {className?: string}) => (
  <div
    className={classNames(
      'min-h-screen-minus-28 flex items-center backdrop-filter backdrop-blur-lg backdrop-opacity-1 absolute size-full z-10',
      className
    )}
  >
    <Loader loading />
  </div>
);

export default ListViewLoader;
