import {DrayageBooking, DrayageLegOverallStatus} from '@shipwell/corrogo-sdk';
import {useMutation} from '@tanstack/react-query';
import {setLegExecutionStatus} from 'App/api/corrogo/typed';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';

const useUpdateExecutionStatus = (legId: string) => {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<
    DrayageBooking,
    unknown,
    {status: DrayageLegOverallStatus}
  >();

  const updateLegExecutionStatusMutation = useMutation(
    async ({status}: {status: DrayageLegOverallStatus}) => setLegExecutionStatus(legId, status),
    createOptimisticUpdateHandlers([DRAYAGE_QUERY_KEY, legId], {
      mergeData: (leg, variables) => {
        if (!leg || !variables) {
          return leg;
        }
        return {...leg, overall_status: variables.status};
      }
    })
  );

  return {updateLegExecutionStatusMutation};
};

export default useUpdateExecutionStatus;
