/* eslint-disable camelcase */
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import {
  dataSourceOptionItems,
  rangeOptionItems,
  INTERNAL_SHIPPER,
  SHIPMENT_STOP_ADDRESS_PATH,
  STANDALONE_PRICING_STOP_ADDRESS_PATH
} from './pricingIntelConstants.js';

/**
 * Formats response data for external data sources.
 * @param {Array} externalPriceData List of daily prices for an external data source
 * @param {Integer} markupMultiplier
 */
export const formatExternalPriceData = (externalPriceData) => {
  return externalPriceData.map((daily_price) => {
    return {
      date: new Date(moment(daily_price.date).startOf('day')),
      average: daily_price.average,
      high: daily_price.max,
      low: daily_price.min
    };
  });
};

export const formatDatPriceData = (datPriceData) => {
  return datPriceData.map((monthlyData) => {
    const {
      perTrip: {rateUsd, highUsd, lowUsd},
      averageFuelSurchargePerTripUsd = 0
    } = monthlyData;
    return {
      date: new Date(moment({year: monthlyData.year, month: monthlyData.month - 1, day: 15}).startOf('day')),
      average: rateUsd + averageFuelSurchargePerTripUsd,
      high: highUsd + averageFuelSurchargePerTripUsd,
      low: lowUsd + averageFuelSurchargePerTripUsd,
      averageFuelSurchargePerTripUsd
    };
  });
};

/**
 * Formats response data for internal (Shipwell) data sources.
 * @param {Array} internalPriceData List of daily prices for an internal data source
 * @param {Integer} markupMultiplier
 */
export const formatInternalPriceData = (internalPriceData) => {
  return internalPriceData.map((daily_price) => {
    return {
      x: new Date(moment(daily_price.pickup_date).startOf('day')),
      y: daily_price.carrier_cost,
      ...daily_price
    };
  });
};

/**
 * Formats data for React-vis' AreaSeries component.
 * Uses a data source's high price for the top line,
 * and low price for the bottom line. Area is filled in
 * between the line automatically.
 * @param {Array} data List of price data for a given data source
 */
export const formatAreaSeriesData = (data) => {
  return data.map((daily_data) => {
    return {
      x: daily_data.date,
      y: daily_data.high,
      y0: daily_data.low
    };
  });
};

/**
 * Formats data for React-vis' LineSeries component.
 * @param {Array} data
 * @param {String} type
 */
export const formatLineSeriesData = (data, type) => {
  return data.map((daily_data) => {
    return {
      x: daily_data.date,
      y: daily_data[type]
    };
  });
};

/**
 * Formats series data for crosshair.
 * @param {Integer} index Indicates the index of the data that the date falls on
 * @param {Object} chartData Price data per data source
 * @param {Array} dataSources List of data sources to format data for
 */
export const formatLineSeriesCrosshairValues = (index, chartData = {}, dataSources = []) => {
  if (
    (dataSources || []).length > 0 &&
    (chartData[dataSources[0]] || []).length > 0 &&
    chartData[dataSources[0]][index]
  ) {
    const crosshairValues = {x: new Date(moment(chartData[dataSources[0]][index].date).startOf('day'))};
    dataSources.forEach((dataSource) => {
      if (chartData[dataSource] && chartData[dataSource].length > 0) {
        crosshairValues[dataSource] = {
          label: dataSourceOptionItems[dataSource].label,
          y: Math.ceil(chartData[dataSource][index].average),
          yHigh: Math.ceil(chartData[dataSource][index].high),
          yLow: Math.ceil(chartData[dataSource][index].low)
        };
      }
    });
    return [crosshairValues];
  }
  return;
};

/**
 * Returns the min and max for all selected data sources, to provide the graph with a proper Y domain.
 * @param {Object} chartData Price data per data source
 */
export const getChartYDomain = (chartData) => {
  let yMin, yMax;
  Object.keys(chartData).forEach((dataSource) => {
    const sourceValues = chartData[dataSource];
    if (sourceValues.length > 0) {
      const sourceMin = Math.min(
        ...sourceValues.map((o) => (o.low ? o.low : o.y)),
        sourceValues[0].low ? sourceValues[0].low : sourceValues[0].y
      );
      const sourceMax = Math.max(
        ...sourceValues.map((o) => (o.high ? o.high : o.y)),
        sourceValues[0].high ? sourceValues[0].high : sourceValues[0].y
      );
      yMin = !yMin || sourceMin < yMin ? sourceMin : yMin;
      yMax = !yMax || sourceMax > yMax ? sourceMax : yMax;
    }
  });
  return [yMin * 0.85 || 0, yMax * 1.15 || 0];
};

/**
 * Returns the first and last month start dates to generate x domain
 * @param {Object} chartData Price data per data source
 * @param {String} selectedFormRange
 */
export const getChartXDomain = (chartData, selectedFormRange) => {
  const formRangeMonths = getFormattedTimeTickValues(chartData, selectedFormRange);
  if (formRangeMonths.length > 1) {
    //return two indices with the first and last month start date to generate x domain
    return [formRangeMonths[0], formRangeMonths[formRangeMonths.length - 1]];
  }
};

/**
 * Returns an array with the first day of every month.
 * The resulting array includes the first day of every month that
 * comes after the specified startDate.
 * @param {Date} startDate Price data per data source
 * @param {Boolean} wrapBeginAndEndMonths Include the month before the start date
 * and the month after the end date
 */
export const getMonthStarts = ({startDate, wrapBeginAndEndMonths = false}) => {
  const monthStarts = [];
  let firstMonth = wrapBeginAndEndMonths
    ? //if wrapping months, include current month
      moment(startDate).startOf('month').format()
    : //otherwise start with the next month
      moment(startDate)
        .startOf('month')
        .add(moment.duration({M: 1}))
        .format();
  //if wrapping months, include month after current month- otherwise end with current month
  const endMonth = wrapBeginAndEndMonths ? moment().add(moment.duration({M: 1})) : moment();
  while (moment(firstMonth).isBefore(endMonth)) {
    const nextMonthDate = new Date(firstMonth);
    monthStarts.push(nextMonthDate);
    firstMonth = moment(firstMonth)
      .add(moment.duration({M: 1}))
      .format();
  }
  return monthStarts;
};

/**
 * Returns an array with three dates- the most recent chart estimate,
 * a week prior to the most recent chart estimate, and two weeks prior to the most recent chart estimate.
 * @param {Date} startDate Price data per data source
 */
export const getTwoWeekXAxisTicks = (chartData) => {
  //sort chart data by date, and return the most recent chart estimate
  const xAxisTickMostRecentPriceEstimateDate = Object.keys(chartData).map((dataSource) => {
    return chartData[dataSource].sort((a, b) => b.date - a.date)[0];
  })[0].date;
  const xAxisTickOneWeekBeforeMostRecentDate = moment(xAxisTickMostRecentPriceEstimateDate)
    .subtract(1, 'weeks')
    .toDate();
  const xAxisTickTwoWeeksBeforeMostRecentDate = moment(xAxisTickMostRecentPriceEstimateDate)
    .subtract(2, 'weeks')
    .toDate();
  return [
    xAxisTickMostRecentPriceEstimateDate,
    xAxisTickOneWeekBeforeMostRecentDate,
    xAxisTickTwoWeeksBeforeMostRecentDate
  ];
};

/**
 * Returns the formatted tick values for the x-axis
 * @param {Object} data Price data per data source
 * @param {Integer} rangeInWeeks
 */
export const getFormattedTimeTickValues = (chartData, selectedRange) => {
  const seriesData = chartData[Object.keys(chartData)[0]];
  const seriesDataStartDate = seriesData[0]?.date;
  const formRangeStartDate = getFirstRangeDate(selectedRange);
  if (isGraphInternalOnly(chartData)) {
    //use the formRangeStartDate to get x-axis start
    return getMonthStarts({startDate: formRangeStartDate, wrapBeginAndEndMonths: true});
  }
  //otherwise use the first date in the chart data to get x-axis start
  return getMonthStarts({startDate: seriesDataStartDate, wrapBeginAndEndMonths: false});
};

/**
 * Formats the price axis ticks.
 * "Shortens" the prices as they grow too big to display.
 * @param {Integer} price
 */
export const formatPriceAxis = (price) => {
  return price > 9999 ? `$${Number(price) / 1000}k` : `$${price}`;
};

/**
 * Formats the time axis ticks.
 * @param {Date} date
 */
export const formatTimeAxisMonthOnly = (date) => {
  return moment(date).format('MMM');
};

/**
 * Formats the time axis ticks.
 * @param {Date} date
 */
export const formatTimeAxisMonthDayYear = (date) => {
  return moment(date).format('MM/DD/YY');
};

/**
 * Truncates each data source's price array.
 * Returns any price value that falls within the provided range.
 * @param {Object} pricesByDataSource
 * @param {Integer} range the range to truncate
 * @param {String} durationInput the duration to use for truncating- e.g., 'days', 'weeks

 */
export const truncatePriceDataByRange = (pricesByDataSource, range, durationInput) => {
  const truncatedPrices = {};
  Object.keys(pricesByDataSource).forEach((dataSource) => {
    if (pricesByDataSource[dataSource] && pricesByDataSource[dataSource].length > 0) {
      truncatedPrices[dataSource] =
        range === null
          ? pricesByDataSource[dataSource]
          : pricesByDataSource[dataSource].filter((prices) => {
              // TODO: Remove when data sources share same date key.
              return prices.pickup_date
                ? moment(prices.pickup_date)
                    .startOf('day')
                    .isAfter(moment().subtract(range, durationInput).startOf('day'))
                : moment(prices.date).startOf('day').isAfter(moment().subtract(range, durationInput).startOf('day'));
            });
    }
  });
  //omit the sources that have no prices in selected range
  return omitBy(truncatedPrices, (value) => value.length === 0);
};

export const hasEmptyStops = (stops) => {
  if (!stops || stops.length < 2) {
    return true;
  }
  return stops
    .map((stop) => (stop.location ? stop.location.address : stop.address ? stop.address : stop))
    .some((stop) => isEmpty(stop));
};
/*
 **Subtract the form range from today's date to get the first date within the selected range
 */
export const getFirstRangeDate = (selectedFormRange) => {
  return selectedFormRange && rangeOptionItems.find((opt) => opt.value === selectedFormRange).numWeeks
    ? moment()
        .subtract(rangeOptionItems.find((opt) => opt.value === selectedFormRange).numWeeks, 'weeks')
        .format('YYYY-MM-DD')
    : null;
};

/**
 * Get the stops with sufficient location data and stringify them
 * @param {Object} stops
 * @return {String}
 */
export const getStringifiedStopsWithRequiredKeys = (stops = [], requiredKeys = []) => {
  //only pass the stop address
  const stopAddresses = Array.isArray(stops) && stops.map((stop) => getStopAddress(stop));
  return (
    stopAddresses
      //return stop addresses with all the required keys
      .filter((stopAddress) => requiredKeys.every((key) => has(stopAddress, key)))
      .map((stopAddress) => requiredKeys.map((key) => `${key}:${stopAddress[key]}`).join('|'))
  );
};

/**
 * Get the address obj from a stop
 * @param {Object} stop
 * @return {Object}
 */
export const getStopAddress = (stop) =>
  get(stop, SHIPMENT_STOP_ADDRESS_PATH) || get(stop, STANDALONE_PRICING_STOP_ADDRESS_PATH);

/**
 * Get the stops with sufficient location data, determines what to do with 3 digit postal codes, and stringify them
 * @param {Object} stops
 * @param {String[]} requiredKeys
 * @return {String[]}
 */
export const getThreeDigitPostalStringifiedStops = (stops = [], requiredKeys = []) =>
  stops.map((stop) =>
    getStringifiedStopsWithRequiredKeys(
      [stop],
      [...requiredKeys, getStopAddress(stop)?.postal_code?.length > 3 ? 'postal_code' : 'city']
    )
  );

/*
 **Exclude outlier rows from historical pricing data
 */
export const filterOutlierData = (pricingData, selectedDataSources) => {
  const internalPricingData = get(pricingData, 'shipwell_internal', []);
  if (
    //if user does not have shipwell_internal data source selected, don't show outliers
    selectedDataSources.includes(INTERNAL_SHIPPER) &&
    Array.isArray(internalPricingData) &&
    internalPricingData.length > 0
  ) {
    return {
      ...pricingData,
      shipwell_internal: [...internalPricingData.filter(({is_outlier}) => !is_outlier)]
    };
  }
  return pricingData;
};

/*
 **Get outlier rows from historical pricing data, and return those rows
 */
export const findOutlierData = (pricingData, selectedDataSources) => {
  const internalPricingData = get(pricingData, 'shipwell_internal', []);
  if (
    //if user does not have shipwell_internal data source selected, don't show outliers
    selectedDataSources.includes(INTERNAL_SHIPPER) &&
    Array.isArray(internalPricingData) &&
    internalPricingData.length > 0
  ) {
    return internalPricingData.filter(({is_outlier}) => is_outlier);
  }
  //return an empty array here so we can check array length
  return [];
};

/*
 **Combines historical pricing data from both public (shipwell market composite)
 ** and private sources (DAT, Chainalytics, Sonar) with shipwell internal data
 */
export const combineDataSources = ({
  internalPricingData,
  marketComposite,
  privateDataSources,
  customerDatHistoricalSpot,
  customerDatHistoricalContract
}) => {
  return {
    shipwell_internal: internalPricingData,
    //there's only one key for market pricing, so we don't need to specify
    shipwell_market: marketComposite,
    chainalytics_spot: !isEmpty(privateDataSources) ? privateDataSources.chainalytics_spot : [],
    dat_spot: !isEmpty(privateDataSources) ? privateDataSources.dat_spot : [],
    customerDatHistoricalSpot: customerDatHistoricalSpot?.rateResponse?.response?.rates || [],
    customerDatHistoricalContract: customerDatHistoricalContract?.rateResponse?.response?.rates || []
  };
};

/*
 **Combines aggregate pricing data from both public (shipwell market composite)
 ** and private sources (DAT, Chainalytics, Sonar) with shipwell internal data
 */
export const combineAggregateDataSources = ({
  internalPricingDataAggregate,
  marketCompositeAggregate,
  privateDataSourcesAggregate
}) => {
  return {
    shipwell_internal: internalPricingDataAggregate,
    shipwell_market: marketCompositeAggregate,
    chainalytics_spot: !isEmpty(privateDataSourcesAggregate)
      ? privateDataSourcesAggregate.chainalytics_spot_aggregates
      : {},
    dat_spot: !isEmpty(privateDataSourcesAggregate) ? privateDataSourcesAggregate.dat_spot_aggregates : {}
  };
};

export const isEmptyAggregateDataSource = (dataSourceAggregatePrice) => {
  return (
    //if there are some aggregate price estimates in any of the keys, return false
    Object.values(dataSourceAggregatePrice).some((aggregatePriceSource) => !isNil(aggregatePriceSource)).length === 0
  );
};

/*
 ** Checks if all pricing data is empty
 */
export const isEmptyPricingData = (rawHistoricalPrices, dataSourceAggregatePrices) => {
  //check if all historical prices are empty arrays
  return (
    Object.values(rawHistoricalPrices).every((dataSourceValue) => dataSourceValue.length === 0) &&
    //check if all aggregate price estimates are null
    Object.values(dataSourceAggregatePrices).filter((aggregatePrice) => !isEmptyAggregateDataSource(aggregatePrice))
  );
};

/**
 * Checks if chart data only contains internal historical data
 * @param {Object} pricesByDataSource
 */
export const isGraphInternalOnly = (graphData) => {
  const selectedDataSources = Object.keys(graphData);
  return selectedDataSources.length === 1 && selectedDataSources[0] === INTERNAL_SHIPPER;
};

/**
 * Color changing utility
 */
export const changeColor = (color, amount) => {
  // #FFF not supportet rather use #FFFFFF
  const clamp = (val) => Math.min(Math.max(val, 0), 0xff);
  const fill = (str) => ('00' + str).slice(-2);

  const num = parseInt(color.substr(1), 16);
  const red = clamp((num >> 16) + amount);
  const green = clamp(((num >> 8) & 0x00ff) + amount);
  const blue = clamp((num & 0x0000ff) + amount);
  return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16));
};
