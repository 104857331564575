import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getFedexAccounts} from 'App/api/registration/typed';
import {COMPANY_INTEGRATION_FEDEX} from 'App/data-hooks/queryKeys';
import {useUserMe} from 'App/data-hooks/users';
import {ComponentType} from 'react';
import {wrapDisplayName} from 'recompose';

type UseGetFedExAccountsQuery = UseQueryOptions<ReturnType<typeof getFedexAccounts>>;

export const useGetFedExAccountsQuery = ({
  accountNumber,
  enabled: isQueryEnabled = true
}: {accountNumber?: string} & UseGetFedExAccountsQuery = {}) => {
  const {company} = useUserMe();
  const isFedExEnabled = company?.feature_flags?.fedex_enabled;

  const fedExQuery = useQuery(
    [COMPANY_INTEGRATION_FEDEX, ...(accountNumber ? [accountNumber] : [])],
    async () => getFedexAccounts(accountNumber),
    {
      onError: (error) => {
        console.error(error);
      },
      enabled: isFedExEnabled && isQueryEnabled
    }
  );

  const isFedExAuthenticated = fedExQuery?.data?.results?.find((result) => result.company === company?.id);
  const fedexFreightAccounts = fedExQuery?.data?.results?.filter(
    (result) => result.is_freight && !!result.meter_number
  );

  return {
    isFedExAuthenticated,
    fedExQuery,
    isFedExEnabled,
    fedExAccounts: fedExQuery.data?.results,
    fedexFreightAccounts
  };
};

// HOC for use with class components
export const withGetFedExAccountsQuery = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithFedexAcountsQuery = (props: PropsT) => {
    const useGetFedexAccounts = useGetFedExAccountsQuery();

    return <Component {...props} {...useGetFedexAccounts} />;
  };

  ComponentWithFedexAcountsQuery.displayName = wrapDisplayName(Component, 'withGetFedExAccountsQuery');
  return ComponentWithFedexAcountsQuery;
};
