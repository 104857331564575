import {cloneElement, useState, useEffect} from 'react';
import get from 'lodash/get';
import {Toast} from '@shipwell/shipwell-ui';
import {getCompanyLogoPromise} from 'App/api/brokers';
import {getSupplierInviteByKey} from 'App/api/orders';
import ShipwellLoader from 'App/common/shipwellLoader';
import LinkError from 'App/common/linkError';
import ExternalFooter from 'App/common/externalFooter';
import {setAccessTokenFunctionOverride} from 'App/api/utils';
import {getSubdomain} from 'App/utils/location';
import '../styles.scss';

const SupplierShipmentExternalContainer = (props) => {
  const {location, router, children} = props;

  const [tokenStatus, setTokenStatus] = useState(null);
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const [shipmentParams, setShipmentParams] = useState({});
  const [logo, setLogo] = useState('/images/svgs/shipwell-logo.svg');
  const [dispatchError, setDispatchError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetchLogos(getSubdomain());
  }, []);

  useEffect(() => {
    const {token} = location.query;
    validateToken(token);
  }, []);

  const fetchLogos = async (subdomain) => {
    try {
      const link = document.querySelectorAll("link[rel*='icon']");
      getCompanyLogoPromise(subdomain).then((response) => {
        if (response.body && response.body.length) {
          setLogo(response.body.find((e) => e.image_type === 'INLINE_COLOR').logo);
          const logoImage = response.body.find((e) => e.image_type === 'LOGO_COLOR');
          if (logoImage.company_name && logoImage.logo) {
            document.title = logoImage.company_name;
            localStorage.setItem('whiteLabelTitle', logoImage.company_name);
            for (let i = 0; i < link.length; i++) {
              link[i].href = logoImage.logo;
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const validateToken = async (token) => {
    setValidating(true);
    //set auth header for future API calls
    setAccessTokenFunctionOverride(() => {
      return new Promise((resolve) => resolve(`SupplierInvite ${token}`));
    });

    try {
      //get details on the current token in use
      await getSupplierInviteByKey(token).then((response) => {
        const {body} = response;
        setTokenStatus(true);
        setValidating(false);
        setError(null);
        setShipmentParams(body);
        if (get(body, 'shipment.id')) {
          //push directly to the details page;
          router.push(`/supplier-shipments/${get(body, 'shipment.id')}?token=${token}`);
        } else {
          //go to the create page
          router.push(`/supplier-shipments/create?token=${token}`);
        }
      });
    } catch (error) {
      //token is invalid or expired
      setTokenStatus(false);
      setValidating(false);
      setError('This link has expired. Please contact your customer for a new shipment link.');
    }
  };

  return (
    <div className="supplierShipment supplierShipment__external">
      <div className="supplierShipment__header">
        <img src={logo} className="supplierShipment__logo" />
      </div>
      <div className="supplierShipment__body">
        {validating ? (
          <div className="supplierShipment__loading">
            <ShipwellLoader loading />
          </div>
        ) : !tokenStatus ? (
          <LinkError logo={logo} message={error} />
        ) : children ? (
          cloneElement(children, {
            ...props,
            ...shipmentParams,
            dispatchError,
            setDispatchError,
            successMessage,
            setSuccessMessage
          })
        ) : (
          ''
        )}
      </div>
      <Toast
        show={successMessage}
        title={`Shipment ${shipmentParams?.allow_autobook ? 'Booked' : 'Created'}!`}
        anchor="bottom-right"
        onClose={() => setSuccessMessage(false)}
      >
        {successMessage}
      </Toast>

      <Toast
        show={dispatchError}
        title="Booking Error!"
        variant="error"
        anchor="bottom-right"
        delay={null}
        onClose={() => setDispatchError(false)}
      >
        {dispatchError}
      </Toast>
      <div className="supplierShipment__footer">
        <ExternalFooter />
      </div>
    </div>
  );
};

export default SupplierShipmentExternalContainer;
