import {Fragment} from 'react';
import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {NetsuiteCustomField} from '@shipwell/backend-core-sdk';
import MappingFormFieldsHeader from 'App/formComponents/forms/netSuiteDataMapping/components/mappingFormFieldsHeader';

const fields = [
  {
    id: 'mc_number_ns_field_id',
    label: 'MC Number',
    type: 'entityCustomField',
    required: false
  },
  {
    id: 'dot_number_ns_field_id',
    label: 'DOT Number',
    type: 'entityCustomField',
    required: false
  },
  {
    id: 'locked_ns_field_id',
    label: 'Locked',
    type: 'entityCustomField',
    required: true
  },
  {
    id: 'bol_number_ns_field_id',
    label: 'BOL Number',
    type: 'transactionBodyCustomField',
    required: false
  },
  {
    id: 'pro_number_ns_field_id',
    label: 'PRO Number',
    type: 'transactionBodyCustomField',
    required: false
  },
  {
    id: 'pickup_number_ns_field_id',
    label: 'Pickup Number',
    type: 'transactionBodyCustomField',
    required: false
  },
  {
    id: 'po_number_ns_field_id',
    label: 'PO Number',
    type: 'transactionBodyCustomField',
    required: false
  },
  {
    id: 'customer_ref_number_ns_field_id',
    label: 'Customer Reference Number',
    type: 'transactionBodyCustomField',
    required: false
  }
];

const VendorCarrierMappingFormFields = ({options}: {options: NetsuiteCustomField[]}) => (
  <>
    <MappingFormFieldsHeader labelLeft="Identification Codes" labelRight="Custom Fields" />
    <div className="mt-3 grid grid-cols-2 items-start justify-items-stretch gap-x-[10px] gap-y-1">
      {fields.map((field) => (
        <Fragment key={field.id}>
          <div className="flex h-full flex-col justify-center">{field.label}</div>
          <Field
            simpleValue
            clearable={!field.required}
            name={`custom_fields.${field.id}`}
            label="Custom NetSuite Field"
            component={FormikSelect}
            options={Array.isArray(options) ? options.filter((option) => option.type === field.type) : []}
            getOptionLabel={(option: NetsuiteCustomField) => option.field_name}
            getOptionValue={(option: NetsuiteCustomField) => option.id}
            required={field.required}
          />
        </Fragment>
      ))}
    </div>
  </>
);

export default VendorCarrierMappingFormFields;
