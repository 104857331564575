import {useQuery} from '@tanstack/react-query';
import {getLegTracking} from 'App/api/corrogo/typed';
import {LEG_TRACKING} from 'App/data-hooks/queryKeys';
import {createTrackingPointLocation} from 'App/components/trimbleMap/utils/typed';

function useLegTracking(legId: string) {
  const legTrackingQuery = useQuery(
    [LEG_TRACKING, legId],
    async () => {
      if (!legId) {
        return Promise.reject(new Error('Invalid leg id'));
      }
      const response = await getLegTracking(legId);
      return response.data.data;
    },
    {
      enabled: !!legId
    }
  );

  const trackingLocations =
    legTrackingQuery.data?.map((tracking) => createTrackingPointLocation(tracking.lat, tracking.lon)) || [];

  return {queryInfo: legTrackingQuery, trackingLocations};
}

export default useLegTracking;
