import {FormikPhoneInput, FormikTextInput} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import AddressSearchField from 'App/formComponents/fields/_addressSearch';

const RemitToInfo = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <Field component={FormikTextInput} name="remit_to.name" label="Name" />
      <Field component={AddressSearchField} name="remit_to.address" label="Address" />
      <Field component={FormikPhoneInput} name="remit_to.contact_phone" label="Contact Phone #" />
      <Field component={FormikTextInput} name="remit_to.contact_email" label="Email" />
    </div>
  );
};
export default RemitToInfo;
