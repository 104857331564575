import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Broker Shipper - API
 */
export async function getBrokerShipmentsPromise(authorization, opts = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.brokersShippersShipmentsGet(authorization, opts, apiCallback(resolve, reject));
  });
}

export async function editBrokerShipperPromise(shipperId, body, authorization) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.brokersShippersShipperIdPut(shipperId, body, authorization, apiCallback(resolve, reject));
  });
}

/**
 * Brokerage - API
 */
export async function editBrokeragePromise(authorization, brokerageId, brokerage) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokersBrokerageIdPut(brokerageId, brokerage, authorization, apiCallback(resolve, reject));
  });
}

/**
 * Shipper Relationsips - API
 */
export async function fetchBrokerShipperRelationshipsPromise(brokerageId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsGet(brokerageId, opts, apiCallback(resolve, reject));
  });
}

export async function fetchBrokerShipperRelationshipPromise(brokerageId, shipperRelationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdGet(
      brokerageId,
      shipperRelationshipId,
      apiCallback(resolve, reject)
    );
  });
}

export async function createBrokerShipperRelationshipPromise(brokerageId, customerObj = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsPost(brokerageId, customerObj, apiCallback(resolve, reject));
  });
}

export async function editBrokerShipperRelationshipPromise(brokerageId, relationshipId, customerObj = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPut(
      brokerageId,
      relationshipId,
      customerObj,
      apiCallback(resolve, reject)
    );
  });
}

export async function deleteBrokerShipperRelationshipPromise(brokerageId, relationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDelete(
      brokerageId,
      relationshipId,
      apiCallback(resolve, reject)
    );
  });
}

export async function fetchBrokerShipperRelationshipsForTypeaheadsPromise(brokerageId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsGet(brokerageId, opts, apiCallback(resolve, reject));
  });
}

/**
 * Carriers - API
 */
export async function fetchCarriersPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.carriersGet(authorization, apiCallback(resolve, reject));
  });
}

/**
 * Companies - API
 */
export async function getCompanyLogoPromise(subdomain) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.companiesLogosGet(subdomain, {}, apiCallback(resolve, reject));
  });
}

/**
 * Shipper Carriers - API
 */
export async function getShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersGet(
      brokerageId,
      shipperRelationshipId,
      apiCallback(resolve, reject)
    );
  });
}

export async function createBrokerShipperPromise(brokerageId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsPost(brokerageId, body, apiCallback(resolve, reject));
  });
}

export async function editShipperCarrierRelationshipsPromise(brokerageId, shipperRelationshipId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPut(
      brokerageId,
      shipperRelationshipId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Broker shipment invoice - API
 */
export async function brokerMarkAsPaidPromise(lineItems, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokersShipmentsInvoiceLineItemsMarkAsPaidPost(lineItems, authorization, apiCallback(resolve, reject));
  });
}

export async function brokerMarkAsUnpaidPromise(lineItems, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokersShipmentsInvoiceLineItemsMarkAsUnpaidPost(
      lineItems,
      authorization,
      apiCallback(resolve, reject)
    );
  });
}

export async function getCustomerDocuments(brokerageId, shipperRelationshipId, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdDocumentsGet(
      brokerageId,
      shipperRelationshipId,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getCustomerMileageExceptions(brokerageId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.brokeragesBrokerageIdShipperRelationshipsMileageExceptionsGet(
      brokerageId,
      apiCallback(resolve, reject)
    );
  });
}
