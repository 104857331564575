import {useFormikContext} from 'formik';
import debounce from 'lodash/debounce';
import {useEffect, useMemo} from 'react';
import {SearchFormValues} from '../searchFormValues';

export function DebouncedAutoSubmitter() {
  const {submitForm, values} = useFormikContext<SearchFormValues>();

  const debouncedSearch = useMemo(() => {
    return debounce(() => {
      void submitForm();
    }, 300);
  }, [submitForm]);

  useEffect(() => {
    if (!values) return;
    debouncedSearch();
  }, [values, debouncedSearch]);

  return null;
}
