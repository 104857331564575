import {ReactNode} from 'react';
import {Pill} from '@shipwell/shipwell-ui';
import {PointOfContact} from './SendRfpBidRequestFields';

export const CarrierOption = (
  contact: PointOfContact,
  contextWrapper: {context: string},
  isOptionDisabled = false,
  children?: ReactNode
) => {
  if (!contact) {
    return '--';
  }
  /* upon selection, only show contact email */
  if (contextWrapper.context === 'value') {
    return contact.email || '--';
  }
  return (
    <div>
      <div className="flex items-center justify-between">
        <span>
          <span className="font-bold">{contact.company_name}</span> ({contact.first_name} {contact.last_name} -{' '}
          {contact.email}) {children}
        </span>
        <Pill size="xs" variant={isOptionDisabled ? 'inactive' : 'primary'}>
          {contact.carrier_status?.split('_').join(' ') || 'Missing Status'}
        </Pill>
      </div>
      <div className="grid grid-cols-3">
        <span>{contact.overall_on_time_delivery || '--'}% On Time Delivery</span>
        <span>{contact.overall_tender_acceptance || '--'}% Tender Acceptance</span>
        <span>{contact.overall_tracking_score || '--'}% Tracking Score</span>
      </div>
    </div>
  );
};
