import {CreateTag, Tag, TagStatus, UpdateTag} from '@shipwell/corrogo-sdk';
import {defaultColors} from '../colorsSelect/defaultColors';
import {TagFormValues} from '../formUtils/tagFormValues';

export function getFormDataFromResponse(data: Tag): TagFormValues {
  const defaultColor = isDefaultColor(data.color);

  return {
    name: data.name,
    status: data.status,
    description: data.description || '',
    color: defaultColor ? data.color : '',
    customColor: defaultColor ? '' : data.color
  };
}

export function getCreateDataFromFormData(values: TagFormValues): CreateTag {
  return getUpdateDataFromFormData(values);
}

export function getUpdateDataFromFormData(values: TagFormValues): UpdateTag {
  return {
    name: values.name,
    status: values.status || TagStatus.Active,
    color: values.color || values.customColor,
    description: values.description
  };
}

function isDefaultColor(color: string) {
  return defaultColors.some((dColor) => dColor === color);
}
