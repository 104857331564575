import {browserHistory} from 'react-router';
import {requestUserProfilePending, requestUserProfileSuccess} from './index';
import {AUTH_USER} from 'App/actions/types';
import {auth, company, user} from 'App/api';
import {getCompanyPreferences} from 'App/containers/userCompany/actions/async';
import {requestUserCompanyPending, requestUserCompanySuccess} from 'App/containers/userCompany/actions';

export const getUserMe = () => {
  return (dispatch) => {
    dispatchUserMePending(dispatch);
    return auth
      .getUserMePromise()
      .then((response) => {
        dispatchUserMeSuccess(dispatch, response);
        return response;
      })
      .catch((err) => {
        //if this call fails, push user to /signout page to clear their token and prompt sign in
        dispatchUserMeFailure(dispatch);
      });
  };
};

function dispatchUserMePending(dispatch) {
  dispatch(requestUserProfilePending);
  dispatch(requestUserCompanyPending);
}
export function createActionUserMePending() {
  return (dispatch) => {
    dispatchUserMePending(dispatch);
  };
}

function dispatchUserMeSuccess(dispatch, response) {
  dispatch(requestUserProfileSuccess(response.user));
  dispatch(requestUserCompanySuccess(response.company));
  //temporary -- include original AUTH action (until we reorganize the entire app)
  dispatch({type: AUTH_USER, payload: response});
  dispatch(getCompanyPreferences(response.company.id));
}
export function createActionUserMeSuccess(response) {
  return (dispatch) => {
    dispatchUserMeSuccess(dispatch, response);
  };
}

function dispatchUserMeFailure() {
  browserHistory.push('/signout');
}
export function createActionUserMeFailure() {
  return (dispatch) => {
    dispatchUserMeFailure(dispatch);
  };
}

export const editCompanyUserPromise = (companyId, userId, body = {}) => {
  return (dispatch) => {
    return company.editCompanyUserPromise(companyId, userId, body);
  };
};

/**
 * Get User Notifications
 */
export const getUserNotifications = () => {
  return (dispatch) => {
    return user.getNotificationPreferencesPromise();
  };
};

/**
 * Update User Notifications
 * @param {*} body
 */
export const updateUserNotifications = (body = {}) => {
  return (dispatch) => {
    return user.editNotificationPreferencesPromise(body);
  };
};

/**
 * Update User Password
 * @param {*} body
 */
export const updateUserPassword = (body = {}) => {
  return (dispatch) => {
    return user.changePasswordPromise(body);
  };
};
