import React from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Modal, useToastManager} from '@shipwell/shipwell-ui';
import {
  CreateTemplateAutoGenerateRule,
  Template,
  TemplateAutoGenerateRule,
  UpdateTemplate
} from '@shipwell/template-builder-sdk';
import {EditTemplateForm} from 'App/containers/userCompany/templates/forms/EditTemplateForm/EditTemplateForm';
import {useUpdateTemplate} from 'App/data-hooks/templates/useUpdateTemplates';
import {LIST_TEMPLATES_QUERY_KEY} from 'App/data-hooks/queryKeys';

type EditTemplateActionProps = {
  template: Template;
  open: boolean;
  onClose: () => void;
};

export const EditTemplateAction: React.FC<EditTemplateActionProps> = ({open, onClose, template}) => {
  const {updateTemplate} = useUpdateTemplate();
  const queryClient = useQueryClient();

  const {enqueueToast} = useToastManager();

  const handleSubmit = async (
    values: UpdateTemplate & {auto_generate_rules: CreateTemplateAutoGenerateRule[] | TemplateAutoGenerateRule[]}
  ) => {
    await updateTemplate(
      {
        templateId: template.id,
        updateTemplate: values
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries([LIST_TEMPLATES_QUERY_KEY]);
          enqueueToast({
            variant: 'success',
            title: 'Success!',
            content: 'The template was updated successfully.'
          });
          onClose();
        },
        onError: () => {
          enqueueToast({
            variant: 'error',
            title: 'Error updating template',
            content: 'An unknown error occurred'
          });
        }
      }
    );
  };

  return (
    <Modal show={open} onClose={onClose} title="Edit Settings" footerComponent={null}>
      <EditTemplateForm onClose={onClose} handleSubmit={handleSubmit} template={template} />
    </Modal>
  );
};
