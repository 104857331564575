import {connect} from 'react-redux';
import {serviceLevelAgreements} from 'App/api';
import ServiceLevelAgreementForm from 'App/formComponents/forms/serviceLevelAgreement';
import {cleanPayload, unpackErrors} from 'App/utils/globals';
import './styles.scss';

/**
 * Contract Creation
 * @param {*} props
 */
const ServiceLevelAgreementCreateContainer = (props) => {
  const {
    onCancel,
    onSubmitSuccess,
    values,
    isEdit,
    canEditContracts,
    canViewContracts,
    canCreateContracts,
    cloningSLA,
    setError
  } = props;

  /**
   * Create contract
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSave = async (values, {setSubmitting, setErrors}) => {
    setSubmitting(true);
    try {
      let response;

      if (values.id && isEdit) {
        //do a PUT
        response = await serviceLevelAgreements.updateSLA(values.id, cleanPayload(values));
      } else {
        response = await serviceLevelAgreements.createSLA(cleanPayload(values));
      }
      if (onSubmitSuccess) {
        onSubmitSuccess(
          values.clone_on_save ? {...response.body, id: null, name: 'Copy of ' + response.body.name} : null
        );
      }
    } catch (error) {
      console.error(error);
      setError('SLA not saved!', error.error_description);

      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }
    setSubmitting(false);
  };

  /** Cancel creation - go back to customers list */
  const handleCancel = () => {
    onCancel();
  };
  return (
    <>
      {cloningSLA && (
        <p>
          This is a clone of an existing service level agreement. Please update your SLA name and details accordingly.
        </p>
      )}
      <ServiceLevelAgreementForm
        isEdit={isEdit}
        values={cloningSLA || values}
        cloningSLA={cloningSLA}
        onCancel={handleCancel}
        onSubmit={handleSave}
        canViewContracts={canViewContracts}
        canEditContracts={canEditContracts}
        canCreateContracts={canCreateContracts}
      />
    </>
  );
};

export default connect((state) => ({
  user: state.userProfile.user,
  company: state.userCompany.company,
  canEditContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.update_contracts'),
  canViewContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts'),
  canCreateContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.create_contracts')
}))(ServiceLevelAgreementCreateContainer);
