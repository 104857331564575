/**
 * Helper functions to download file from API response
 * @param {Object} apiResponse API response
 * @param {String} defaultFileName default file name in case API doesn't provide one
 */

import {AxiosResponse} from 'axios';

// creating this type to handle differing Axios versions betweeen fe-web and be-core
type FileDownloadAxiosResponse = Pick<AxiosResponse, 'headers' | 'data'>;

export const prepareFile = (apiResponse: FileDownloadAxiosResponse, defaultFileName: string) => {
  //get the headers' content disposition if it exists
  //it may not exist for certain backend-core routes
  //https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
  //its main usage is to tell us whether to show content inline or download it for local viewing
  //but here we use it to get the file's name if it exists
  const cd = apiResponse.headers['content-disposition'];
  //get the file name with regex
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const match = cd ? regex.exec(cd) : null;
  //is there a file name?
  const fileName = match?.length && match.length > 1 ? match[1] : defaultFileName;
  //replace leading and trailing slashes that C# added to your file name
  const escapedFileName = fileName.replace(/"/g, '');
  //determine the content type from the header or default to octect stream
  const contentType = apiResponse.headers['content-type'] || 'application/octet-stream';
  if (!apiResponse?.data) {
    console.error(`Missing required 'data' key from apiResponse`);
  }
  const blob = new Blob([apiResponse?.data], {type: contentType});
  return {blob, escapedFileName};
};

export const downloadFile = ({blob, escapedFileName}: {blob: Blob; escapedFileName: string}) => {
  const exportUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = exportUrl;
  a.download = escapedFileName;
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
};

const prepareAndDownloadFile = (apiResponse: FileDownloadAxiosResponse, defaultFileName: string) => {
  const file = prepareFile(apiResponse, defaultFileName);
  downloadFile(file);
};

export const trimTrailingSlashes = (str: string) => {
  return str.replace(/\/+$/, '');
};

export const getLastUrlSegment = (url: string) => {
  const pathname = trimTrailingSlashes(new URL(url).pathname);
  return pathname.substring(pathname.lastIndexOf('/') + 1);
};

export default prepareAndDownloadFile;
