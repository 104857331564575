// NOTE: still imported my Ondemand/, Settings/forms, Shipment_Doc_Form,
// Shipment_Doc_Update_Form, SignIn_Form & SignUp_Form

// TODO: separate out what is used per page
import _ from 'lodash';
import {validateEmail, validatePhoneNumber} from 'App/utils/globals';

const validate = (values) => {
  const errors = {};
  //sign-in and sign-up
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!validateEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
  if (!values.phone_number) {
    errors.phone_number = 'Phone number is required.';
  }
  if (values.phone_number && !validatePhoneNumber(values.phone_number)) {
    errors.phone_number = 'Phone number is invalid.';
  }

  return errors;
};

export default validate;
