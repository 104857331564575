import {ChangeEvent} from 'react';
import {Button, SearchBar} from '@shipwell/shipwell-ui';
import {flexRender} from '@tanstack/react-table';
import {WithRouterProps} from 'react-router';
import {columns as columnData} from './columns';
import PageHeader from 'App/common/pageHeader';
import {
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow,
  Table
} from 'App/components/TypedTable/baseComponents';
import {useTableColumns, useTablePagination, useTableSort, useTypedTable} from 'App/components/TypedTable/hooks';
import {useCompanyUsers, useUserMe} from 'App/data-hooks';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/utils/tableUtils';
import {TableLoadingBar} from 'App/components/TypedTable/complementaryComponents';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {useGetPermissions} from 'App/data-hooks/permissions';
import {USERS_LIST_TABLE_KEY} from 'App/utils/tableTypeKeys';

export const UsersTable = ({router}: WithRouterProps) => {
  const searchParamURL = new URLSearchParams(location.search);
  const query = searchParamURL.get('q') || '';
  const debouncedQuery = useDebounce(query, 300);

  const handleQuery = (newQuery: string) => {
    searchParamURL.set('q', newQuery);
    router.replace({
      pathname: location.pathname,
      search: `?${searchParamURL.toString()}`
    });
  };

  const {columns} = useTableColumns(columnData, USERS_LIST_TABLE_KEY);
  const [sorting, setSorting, sortString] = useTableSort({
    defaultSort: {id: 'first_name', desc: false},
    shouldUseRouter: true
  });
  const [pagination, setPagination] = useTablePagination({tableType: 'company_users', shouldUseRouter: true});

  // The permissions cell needs this query data
  // seeding the cache here so each row doesn't have to make the call
  useGetPermissions();
  const {data: userData} = useUserMe();
  const {data, isFetching} = useCompanyUsers(userData?.company?.id || '', {
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    ordering: sortString,
    q: debouncedQuery
  });

  const table = useTypedTable({
    data: data?.results || [],
    columns,
    pageCount: data?.total_pages || 1,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination
  });

  const handleCreateUser = () => {
    const {pathname} = location;
    const updatedPath = `${pathname}/create`;
    router.push(updatedPath);
  };

  return (
    <TableContainer>
      <div className="sticky top-0 p-1">
        <PageHeader title="Users" actions={<Button onClick={handleCreateUser}>Add User</Button>}>
          <SearchBar
            name="search"
            label="Search for a user"
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuery(e.target.value)}
          />
        </PageHeader>
      </div>
      {isFetching ? (
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0">
            <TableLoadingBar />
          </div>
        </div>
      ) : null}
      <TableContainer>
        <Table
          head={table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  width={header.getSize()}
                  sortDirection={header.column.getIsSorted()}
                  onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                  onResize={header.getResizeHandler()}
                >
                  <div className="flex items-center gap-1">
                    {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </TableHeader>
              ))}
            </TableHeaderRow>
          ))}
          body={table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
        />
      </TableContainer>
      <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
    </TableContainer>
  );
};
