import {Field} from 'formik';
import {FormikTextInput, Tooltip} from '@shipwell/shipwell-ui';
import StepTimerField from 'App/formComponents/formSections/stepTimerField';

const LoadboardFields = (props) => {
  const {index} = props;

  return (
    <>
      <div className="grid-item-1-11">
        <Field label="Book Now Rate" name={`actions[${index}].buy_it_now_amount`} component={FormikTextInput} />
      </div>
      <div className="grid-item-12-12 routingGuides__form-action-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="routingGuides__form-action-tooltips"
          tooltipContent={
            <div>
              <p>
                <b>Book Now Rate</b>
              </p>
              <p>If you set a book now rate here it will override the book now rate on the shipment when accepted.</p>
            </div>
          }
        >
          <span className="material-icons">info_outline</span>
        </Tooltip>
      </div>
      <div className="grid-item-1-11">
        <StepTimerField name={`actions[${index}].step_timer`} />
      </div>
      <div className="grid-item-12-12 routingGuides__form-action-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="routingGuides__form-action-tooltips"
          tooltipContent={
            <div>
              <p>
                <b>Step Timer</b>
              </p>
              <p>This is the amount of time that will pass before your workflow moves on to the next step.</p>
            </div>
          }
        >
          <span className="material-icons">info_outline</span>
        </Tooltip>
      </div>
    </>
  );
};

export default LoadboardFields;
