import {Dispatch, SetStateAction} from 'react';
import pluralize from 'pluralize';
import classnames from 'classnames';
import {Button, Title} from '@shipwell/shipwell-ui';

export const FieldArraySideNav = ({
  title,
  labels,
  activeIndex,
  onActiveIndexChange,
  onAddNew,
  isAddNewDisabled
}: {
  title: string;
  labels: string[];
  activeIndex: number;
  onActiveIndexChange: Dispatch<SetStateAction<number>>;
  onAddNew?: () => void;
  isAddNewDisabled?: boolean;
}) => {
  return (
    <div>
      <Title className="my-2 pl-6">{pluralize(title)}</Title>
      <ul className="m-0">
        {labels?.map((label, index) => {
          const isActive = activeIndex === index;
          return (
            <li key={index}>
              <button
                type="button"
                aria-current={isActive}
                className={classnames('block w-full border-l-4 py-2 pl-5 pr-2 text-start', {
                  'border-sw-primary bg-sw-active-light text-sw-primary ': isActive,
                  'border-transparent': !isActive
                })}
                onClick={() => onActiveIndexChange(index)}
              >
                {label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="ml-3 py-1">
        <Button
          isCompact
          disabled={isAddNewDisabled}
          onClick={() => onAddNew?.()}
          variant="tertiary"
          iconName="AddCircleOutlined"
        >
          {`Add ${title}`}
        </Button>
      </div>
    </div>
  );
};
