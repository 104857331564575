import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {object, string, array, mixed, number} from 'yup';
import {DeprecatedButton, Card, Modal, SvgIcon, Tooltip, FileDisplay} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {validatePhoneNumber} from 'App/utils/globals';
import {
  createCompanyDocument,
  deleteCompanyDocument,
  updateCompanyDocument,
  getCompanyDocuments
} from 'App/api/company';
import CompanyProfileFields from 'App/formComponents/formSections/companyProfile';
import IdentifyingCodesFields from 'App/formComponents/formSections/identifyingCodesFields';
import ExternalTrackingConfigFields from 'App/formComponents/formSections/externalTrackingConfigFields';
import DocumentForm from 'App/formComponents/forms/document';
import FormFooter from 'App/formComponents/formSections/formFooter';
import './styles.scss';
/** Default values needed for Formik */
export const defaultFormValues = {
  name: null,
  primary_email: null,
  billing_emails: [],
  mailing_address: {},
  primary_phone_number: null,
  auto_archive_shipment_days: null,
  external_push_tracking_ids: [],
  country: null,
  identifying_codes: [],
  default_carrier_status: 'ACTIVE'
};

export const validationSchema = object().shape({
  name: string().required('Company name is required.'),
  primary_email: string().email('A valid email is required.').required('A valid email is required.'),
  billing_emails: array().of(string().email('A valid email is required.').required('A valid email is required.')),
  primary_phone_number: string()
    .required('A valid phone number is required.')
    .test('primary_phone_number', 'A valid phone number is required.', (value) => {
      if (value) {
        return validatePhoneNumber(value);
      }
      return true;
    }),
  mailing_address: mixed()
    .required()
    .test('mailing_address', 'A valid address is required.', (value) => value?.address_1),
  auto_archive_shipment_days: number().positive('A valid number is required.'),
  country: string(),
  identifying_codes: array().of(
    object().shape({
      value: string().required('Value is required.')
    })
  ),
  external_push_tracking_ids: array().of(
    object().shape({
      provider: string().nullable().required('Provider is required.'),
      external_id_qualifier: string().nullable().required('External ID is required.')
    })
  ),
  auto_archive_shipment_states: array().min(1, 'At least one status is required.')
});

/**
 * Company Profile Form
 */
const CompanyProfileForm = (props) => {
  const {edit, values, onSubmit} = props;

  const [documents, setDocuments] = useState([]);
  const [updateDocument, setUpdateDocument] = useState({});
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [documentSelectedForDeletion, setDocumentSelectedForDeletion] = useState(null);
  const {int3Pt} = useFlags();

  /** Submit form values */
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  /**
   * Get company documents
   * @param {*} companyId
   */
  const getCompanyDocumentsList = async (companyId) => {
    try {
      const response = await getCompanyDocuments(companyId, {pageSize: 50});

      if (response && response.body) {
        setDocuments(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Fetch document details */
  useEffect(() => {
    if (values.id) {
      getCompanyDocumentsList(values.id);
    }
  }, []);

  /**
   * Upload company document
   * @param {*} document
   * @param {*} Formik props
   */
  const handleUploadDocument = async (document, {setSubmitting}) => {
    setSubmitting(true);

    try {
      const response = await createCompanyDocument(values.id, document);
      if (response && response.data) {
        setDocuments([...documents, response.data]);
      }
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
    setShowDocumentUpload(false);
  };

  /**
   * Update company document
   * @param {*} document
   * @param {*} Formik props
   */
  const handleUpdateDocument = async (document, {setSubmitting}) => {
    const companyId = values.id;

    setSubmitting(true);

    try {
      await updateCompanyDocument(companyId, document.id, document);
      getCompanyDocumentsList(companyId);
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
    setShowDocumentUpload(false);
    setUpdateDocument({});
  };

  /**
   * Delete company document
   * @param {*} document
   * @param {*} Formik props
   */
  const handleDeleteDocument = async () => {
    const companyId = values.id;

    try {
      await deleteCompanyDocument(companyId, documentSelectedForDeletion.id);

      getCompanyDocumentsList(companyId);
    } catch (error) {
      console.error(error);
    }

    handleToggleDeleteDocumentModal(null);
  };

  /** Toggle document upload form */
  const handleDocumentModalDisplay = () => {
    setShowDocumentUpload(!showDocumentUpload);
  };

  /** Toggle confirmation of document delete modal */
  const handleToggleDeleteDocumentModal = (document) => {
    setDocumentSelectedForDeletion(document);
  };

  /**
   * Add another identifying code
   * @param {*} values
   * @param {*} setFieldValue
   */
  const handleAddCode = (values, setFieldValue) => {
    const identifyingCodes = [...(values?.identifying_codes || []), {type: 'USDOT', value: ''}];
    setFieldValue('identifying_codes', identifyingCodes);
  };

  const handleAddProvider = (values, setFieldValue) => {
    const externalPushTrackingIds = [
      ...(values?.external_push_tracking_ids || []),
      {provider: '', external_id_qualifier: ''}
    ];
    setFieldValue('external_push_tracking_ids', externalPushTrackingIds);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{...defaultFormValues, ...values}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({handleSubmit, isSubmitting, dirty, values, setFieldValue, ...props}) => (
          <form className="company-settings-form grid-1-2" noValidate="novalidate" onSubmit={handleSubmit}>
            <div className="grid-1-1">
              <Card title="Company Information">
                <CompanyProfileFields values={values} {...props} />
              </Card>
            </div>
            <div className="grid-1-1">
              <Card
                title="Company Identifying Codes"
                actions={
                  <DeprecatedButton
                    variant="tertiary"
                    icon={<SvgIcon name="AddCircleOutlined" />}
                    onClick={() => handleAddCode(values, setFieldValue)}
                  >
                    Add Code
                  </DeprecatedButton>
                }
              >
                <IdentifyingCodesFields values={values} />
              </Card>
              {int3Pt ? (
                <Card
                  title={
                    <div className="flex items-center">
                      <div className="mr-1">External Tracking Configuration</div>
                      <Tooltip
                        tooltipContent={
                          <>
                            <span>External Tracking Configuration</span>
                            <br />
                            <span className="font-normal">
                              This allows you to map a tracking provider with a Shipwell field.
                            </span>
                          </>
                        }
                        trigger="hover"
                        tooltipClassname="w-[230px]"
                      >
                        <SvgIcon name="InfoOutlined" color="$sw-icon" />
                      </Tooltip>
                    </div>
                  }
                  actions={
                    <DeprecatedButton
                      disabled={values?.external_push_tracking_ids?.length >= 3}
                      variant="tertiary"
                      icon={<SvgIcon name="AddCircleOutlined" />}
                      onClick={() => handleAddProvider(values, setFieldValue)}
                    >
                      Add Provider
                    </DeprecatedButton>
                  }
                >
                  <ExternalTrackingConfigFields />
                </Card>
              ) : null}
              <Card
                title="Documents"
                actions={
                  <DeprecatedButton
                    icon={<SvgIcon name="Upload" />}
                    variant="tertiary"
                    onClick={() => {
                      setUpdateDocument({});
                      handleDocumentModalDisplay();
                    }}
                  >
                    Upload Document
                  </DeprecatedButton>
                }
              >
                <div className="documents-list">
                  {documents.map((document) => (
                    <div key={document.id} className="document-thumnail">
                      <div
                        className="document-thumnail-preview"
                        onClick={() => {
                          setUpdateDocument(document);
                          handleDocumentModalDisplay();
                        }}
                      >
                        <div className="click-capture-box" />
                        <FileDisplay
                          maxHeight={48}
                          maxWidth={48}
                          fileURL={document.file}
                          fileName={document.filename}
                          isThumbnail
                          altText={document.description}
                        />
                      </div>
                      <p className="file-thumbnail-desc">
                        <span>{document.description}</span>
                        <p>{document.filename}</p>
                      </p>
                      <DeprecatedButton variant="icon" onClick={() => handleToggleDeleteDocumentModal(document)}>
                        <SvgIcon name="TrashOutlined" />
                      </DeprecatedButton>
                    </div>
                  ))}
                  {documents.length < 1 && <p className="tile-empty-message">No documents.</p>}
                </div>
              </Card>
            </div>
            {(!edit || dirty) && <FormFooter isSubmitting={isSubmitting} />}
          </form>
        )}
      </Formik>
      <Modal
        className={classNames({['h-full']: !!updateDocument?.id})}
        show={showDocumentUpload}
        title={`${updateDocument.id ? 'Update' : 'Upload'} Document`}
        footerComponent={null}
        onClose={handleDocumentModalDisplay}
        size={updateDocument?.id ? 'large' : 'medium'}
      >
        <DocumentForm
          isUpload={!updateDocument.id}
          values={updateDocument}
          onCancel={handleDocumentModalDisplay}
          onSubmit={updateDocument.id ? handleUpdateDocument : handleUploadDocument}
        />
      </Modal>
      <Modal
        show={Boolean(documentSelectedForDeletion)}
        title="Confirm Delete"
        primaryBtnName="Delete"
        onClose={handleToggleDeleteDocumentModal}
        onPrimaryAction={handleDeleteDocument}
      >
        Are you sure you want to delete{' '}
        {documentSelectedForDeletion && <strong>{documentSelectedForDeletion.filename}</strong>}?
      </Modal>
    </>
  );
};

CompanyProfileForm.propTypes = {
  edit: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default connect((state) => ({
  permissionsByType: state.company.permissions.permissionsByType
}))(CompanyProfileForm);
