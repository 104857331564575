import {useToastManager} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import {stopResourceTracking} from 'App/api/locus/tracking';
import {parseV3ApiError} from 'App/api/typedUtils';
import {getStopResourceTrackingFromFormData} from './helpers';

export interface UseDeviceServiceProps {
  deviceId: string;
  shipmentId: string;
}

export function useDeviceService(props: UseDeviceServiceProps) {
  const {enqueueToast} = useToastManager();

  const disconnectMutation = useMutation({
    mutationFn: async () => {
      const data = getStopResourceTrackingFromFormData(props.shipmentId, props.deviceId);
      await stopResourceTracking(data);
    },
    onError: (error) => {
      const {detail} = parseV3ApiError(error);
      enqueueToast({
        variant: 'error',
        title: 'Error!',
        content: detail
      });
    },
    onSuccess: () => {
      enqueueToast({
        variant: 'success',
        title: 'Success!',
        content: 'Device was successfully attached.'
      });
    }
  });

  return {
    isDisconnecting: disconnectMutation.isLoading,
    disconnect: disconnectMutation.mutate
  };
}
