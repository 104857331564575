import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {DocumentType} from '@shipwell/backend-core-sdk';
import {getDocumentTypes} from 'App/api/shipment/typed';
import {SHIPMENT_DOCUMENT_TYPES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

const getDocumentTypesData = async () => {
  const response = await getDocumentTypes();
  return response.data;
};

export const useDocumentTypes = (queryOptions?: UseQueryOptions<DocumentType[], unknown, DocumentType[], string[]>) => {
  const getDocumentTypesQuery = useQuery([SHIPMENT_DOCUMENT_TYPES_QUERY_KEY], getDocumentTypesData, {
    staleTime: STATIC_DATA_STALE_TIME,
    ...queryOptions
  });
  return getDocumentTypesQuery;
};

export const usePrefetchDocumentTypes = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.prefetchQuery({
      queryKey: [SHIPMENT_DOCUMENT_TYPES_QUERY_KEY],
      queryFn: getDocumentTypesData,
      staleTime: STATIC_DATA_STALE_TIME
    });
};

/**
 * Converts the array of documenet types returned by useDocumentTypes into an object with the document type IDs as keys.
 * This is useful for converting a document type ID like 'BOL' into a user friendly name like 'Bill of Lading (BOL)'.
 */
export const getDocumentTypesMap = (documentTypes: DocumentType[] | undefined) =>
  documentTypes?.reduce<Record<string, string>>((map, type) => {
    map[type.id] = type.name;
    return map;
  }, {});
