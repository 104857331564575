import {OrderStatus} from '@shipwell/corrogo-sdk';
import {Pill} from '@shipwell/shipwell-ui';
import Loader from 'App/common/shipwellLoader';
import {useTransportationOrder} from 'App/data-hooks';

function statusToVariant(status?: OrderStatus) {
  switch (status) {
    case OrderStatus.Accepted:
      return 'success';
    case OrderStatus.Rejected:
      return 'alert';
    case OrderStatus.Requested:
      return 'active';
    case OrderStatus.Revoked:
      return 'warning';
    case OrderStatus.Expired:
    default:
      return 'inactive';
  }
}

export const TenderStatusPill = ({
  shipmentId,
  transportationOrderId
}: {
  shipmentId?: string;
  transportationOrderId: string;
}) => {
  const {transportationOrderDisplayValues, queryInfo} = useTransportationOrder(transportationOrderId, shipmentId);

  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }

  return <Pill variant={statusToVariant(queryInfo.data?.status)}>{transportationOrderDisplayValues.statusHeader}</Pill>;
};
