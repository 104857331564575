import {ApiClient, PublicApi, PrivateApi, DefaultApi} from '@shipwell/pricing-intel-javascript-sdk';
import {apiCallback, getAccessToken} from 'App/api/utils';

const initializeClient = async () => {
  const defaultClient = new ApiClient();
  defaultClient.basePath = process.env.SHIPWELL_PRICING_API_BASE_PATH;
  const auth = defaultClient.authentications['APIKeyHeader'];
  auth.apiKey = await getAccessToken('pricing-api');

  return defaultClient;
};

const getPrivatePricingApi = async () => {
  const defaultClient = await initializeClient();
  return new PrivateApi(defaultClient);
};

const getPublicPricingApi = async () => {
  const defaultClient = await initializeClient();
  return new PublicApi(defaultClient);
};

const getDefaultPricingApi = async () => {
  const defaultClient = await initializeClient();
  return new DefaultApi(defaultClient);
};

/**
 * Shipments Historical Truck Load Pricing Get - Private API
 * (Paginated)
 */
export async function getPrivateStandaloneTruckloadHistoricalPriceEstimate(equipmentType, stops) {
  const shipwellPricingApiPrivate = await getPrivatePricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPrivate.getPrivateHistoricalMarketPricingPrivateHistoricalMarketGet(
      equipmentType,
      stops,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipments Historical Truck Load Pricing Get - API
 *
 */
export async function getPublicStandaloneTruckloadHistoricalPriceEstimate(equipmentType, stops) {
  const shipwellPricingApiPublic = await getPublicPricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getHistoricalMarketPricingHistoricalMarketGet(
      equipmentType,
      stops,
      apiCallback(resolve, reject)
    );
  });
}
/**
 * Shipments Historical Truck Load Pricing Post - API
 */
export async function getTruckloadHistoricalPriceEstimate(
  equipmentType,
  stops,
  pickupRadius,
  dropOffRadius,
  opts = {}
) {
  const shipwellPricingApiPublic = await getPublicPricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getMyPriceHistoricalGet(
      equipmentType,
      stops,
      pickupRadius,
      dropOffRadius,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipments Market Forecast Pricing Get - API
 * @param  {String} equipmentType e.g., DRY_VAN, REEFER
 * @param  {Array} stops
 * @param  {Number} forecastRange  Length of forecast, beginning on current day
 */
export async function getMarketForecastPricing({equipmentType, stops, forecastRange}) {
  const shipwellPricingApiPublic = await getPublicPricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getMarketForecastPricingForecastMarketGet(
      equipmentType,
      stops,
      forecastRange,
      apiCallback(resolve, reject)
    );
  });
}

// Shipments My Price Forecast Pricing Get - API
export async function getMyPriceForecastPricing({equipmentType, stops, opts = {}}) {
  const shipwellPricingApiPublic = await getPublicPricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getMyPriceForecastForecastGet(equipmentType, stops, opts, apiCallback(resolve, reject));
  });
}

/**
 * Shipments Historical Truck Load Pricing Export - API
 */
export async function getMyPriceHistoricalExport(equipmentType, stops, pickupRadius, dropOffRadius, opts = {}) {
  const shipwellPricingApiPublic = await getPublicPricingApi();

  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getMyPriceExportHistoricalExportGet(
      equipmentType,
      stops,
      pickupRadius,
      dropOffRadius,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Shipments Market Forecast Pricing Get - API
 * @param  {String} equipmentType e.g., DRY_VAN, REEFER
 * @param  {Array} stops
 * @param  {String} opts  start_date and end_date
 */
export async function getCompositeForecastPricing({equipmentType, stops, opts}) {
  const shipwellPricingApiPublic = await getPublicPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getCompositeForecastPricingForecastCompositeGet(
      equipmentType,
      stops,
      opts,
      apiCallback(resolve, reject)
    );
  });
}

export async function getMarketTrends(equipmentType, stops) {
  const shipwellPricingApiPublic = await getPublicPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiPublic.getMarketTrendsHistoricalMarketTrendsGet(
      equipmentType,
      stops,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Get Customer Dat Rates
 * @param {String} equipmentType
 * @param {String[]} stops
 */
export async function getCustomerDatRates(equipmentType, rateType, stops) {
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.getCustomerDatRatesDatRatesGet(
      equipmentType,
      rateType,
      stops,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Create Lane
 * @param {Object} opts Optional parameters
 * @param {module:model/SavedLaneSchema} opts.savedLaneSchema
 * data is of type: {@link module:model/SavedLaneSchema}
 */
export async function createSavedLane(opts) {
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.createLaneSavedLanesPost(opts, apiCallback(resolve, reject));
  });
}

/**
 * Update Lane
 * @param {Object} opts Optional parameters
 * @param {module:model/SavedLaneSchema} opts.savedLaneSchema
 * data is of type: {@link module:model/SavedLaneSchema}
 */
export async function updateSavedLane(savedLaneId, savedLaneSchema) {
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) =>
    shipwellPricingApiDefault.updateSavedLaneSavedLanesSavedLaneIdPut(
      savedLaneId,
      savedLaneSchema,
      apiCallback(resolve, reject)
    )
  );
}

/**
 * Get Saved Lanes
 * @param {Object} opts Optional parameters
 * @param {Number} opts.page  (default to 1)
 * @param {Number} opts.limit  (default to 50)
 */
export async function getSavedLanes(opts = {page: 1, limit: 50}) {
  const {page, limit} = opts;
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.getSavedLanesSavedLanesGet({page, limit}, apiCallback(resolve, reject));
  });
}

/**
 * Delete Saved Lane
 * @param {String} savedLaneId
 */
export async function deleteSavedLane(savedLaneId) {
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.deleteSavedLaneSavedLanesSavedLaneIdDelete(savedLaneId, apiCallback(resolve, reject));
  });
}

/**
 * Get Customer Dat Historical Rates
 * @param {module:model/EquipmentType} equipmentType
 * @param {module:model/RateType} rateType
 * @param {Array.<String>} stops List of stops in the format `country:US|state_province:TX|postal_code:78701`
 */
export async function getCustomerDatHistoricalRates(equipmentType, rateType, stops) {
  const shipwellPricingApiDefault = await getDefaultPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.getCustomerDatHistoricalRatesDatHistoricalGet(
      equipmentType,
      rateType,
      stops,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Get Carrier Price History
 * @param {String} equipmentType
 * @param {String} stops
 * @param {Number} pickupRadius
 * @param {Number} dropOffRadius
 * @param {Object} opts Optional parameters
 * @param {Number} [opts.page]  (default to 1)
 * @param {Number} [opts.limit]  (default to 50)
 * @param {String | undefined} [opts.filter]
 * @param {String | undefined} [opts.sort]
 */
export async function getMyCarriersPriceHistoricalCarrierGet(equipmentType, stops, pickupRadius, dropOffRadius, opts) {
  const shipwellPricingApiDefault = await getPublicPricingApi();
  return new Promise((resolve, reject) => {
    shipwellPricingApiDefault.getMyCarriersPriceHistoricalCarrierGet(
      equipmentType,
      stops,
      pickupRadius,
      dropOffRadius,
      opts,
      apiCallback(resolve, reject)
    );
  });
}
