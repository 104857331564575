import {
  UpdateTemplate,
  TemplateDocumentType,
  Template,
  CreateTemplateAutoGenerateRule,
  TemplateAutoGenerateRule
} from '@shipwell/template-builder-sdk';

export const generateTemplateInitialValues = (
  template: Template
): UpdateTemplate & {auto_generate_rules: CreateTemplateAutoGenerateRule[] | TemplateAutoGenerateRule[]} => {
  return {
    description: template.description || '',
    status: template.status || 'ACTIVE',
    document_type: template.document_type || TemplateDocumentType.Bol,
    allowed_modes: template.allowed_modes || [],
    auto_generate_rules: template.auto_generate_rules,
    configuration: template.configuration
  };
};
