import {combineReducers} from 'redux';
import CompanyProfileReducer from './profile';
import CompanyPermissionsReducer from './permissions';
import CompanyPreferencesReducer from './preferences';
import CompanyConstantsReducer from './constants';

export default combineReducers({
  company: CompanyProfileReducer,
  permissions: CompanyPermissionsReducer,
  preferences: CompanyPreferencesReducer,
  constants: CompanyConstantsReducer
});
