import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import uniq from 'lodash/uniq';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import {getFedexCarrierCodeFromServiceLevel, FedexCarrierCodes} from 'App/data-hooks/parcel/utils';

export const isSamePreferredCurrency = (shipments: Array<ShippingDashboardRow>) =>
  shipments?.every((shipment) => shipment.preferred_currency === shipments?.[0]?.preferred_currency);

export const containsParcelShipment = (shipments: Array<ShippingDashboardRow>) =>
  shipments.some((shipment) => shipment.mode?.code === ShipmentModeEnum.PARCEL);

const isAllParcelMode = (shipments: Array<ShippingDashboardRow>) =>
  shipments.every((shipment) => shipment.mode?.code === ShipmentModeEnum.PARCEL);

const isSameCarrier = (shipments: Array<ShippingDashboardRow>) =>
  shipments.every((shipment) => shipment.current_carrier?.name === shipments[0]?.current_carrier?.name);

const isSameOrigin = (shipments: Array<ShippingDashboardRow>) =>
  shipments.every(
    (shipment) =>
      shipment.stops[0].location.address.formatted_address === shipments[0]?.stops[0].location.address.formatted_address
  );

const isAllParcelUnscheduled = (shipments: Array<ShippingDashboardRow>) =>
  shipments.every((shipment) => shipment.parcel_pickup_status === 'NOT_SCHEDULED');

const isCompatibleFedexCarrierCode = (shipments: Array<ShippingDashboardRow>) => {
  // can ignore this function if not fedex parcel
  if (!shipments.every((shipment) => shipment.current_carrier?.name.toLowerCase() === 'fedex')) return true;
  // only consider labeled fedex
  if (
    shipments.every(
      (shipment): shipment is ShippingDashboardRow & {calculated_ui_service_level: string} =>
        !!shipment.calculated_ui_service_level
    )
  ) {
    const carrierCodesList = uniq(
      shipments.map((shipment) => getFedexCarrierCodeFromServiceLevel(shipment.calculated_ui_service_level))
    );
    return (
      // FXFR is not used for parcel
      !carrierCodesList.some((value) => value === FedexCarrierCodes.FXFR) &&
      // FDXE can only be scheduled with other FDXE
      (carrierCodesList.every((value) => value === FedexCarrierCodes.FDXE) ||
        // FDXG and FXSP can be scheduled together
        (!carrierCodesList.some((value) => value === FedexCarrierCodes.FDXE) &&
          carrierCodesList.every((value) =>
            [FedexCarrierCodes.FDXG, FedexCarrierCodes.FXSP].some((code) => code === value)
          )))
    );
  }
  return false;
};

const isAllParcelLabeled = (shipments: Array<ShippingDashboardRow>) =>
  shipments.every((shipment) => !!shipment.calculated_ui_service_level);

export const isCompatibleParcelShipments = (shipments: Array<ShippingDashboardRow>) =>
  isAllParcelMode(shipments) &&
  isSameCarrier(shipments) &&
  isSameOrigin(shipments) &&
  isAllParcelUnscheduled(shipments) &&
  isCompatibleFedexCarrierCode(shipments) &&
  isAllParcelLabeled(shipments);
