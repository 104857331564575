import lowerCase from 'lodash/lowerCase';
import {Pill} from '@shipwell/shipwell-ui';
import {startCaseToLower} from 'App/utils/startCaseToLower';

type ShipmentStatePlusArchived = string | 'archived';

// It would be excellent to use React.ComponentPropsWithoutRef<typeof Pill>['variant'] for the
// variant type here, but for some reason our CI environment can't handle `typeof` for any
// shipwell-ui component.
export const shipmentStatuses: {
  id: string;
  variant: 'primary' | 'default' | 'success' | 'active' | 'secondary' | 'alert';
}[] = [
  // PRE-TRANSIT STATUSES
  {id: 'draft', variant: 'success'},
  {id: 'quoting', variant: 'success'},
  {id: 'quote_accepted', variant: 'success'},
  {id: 'appointment_pending', variant: 'success'},
  {id: 'carrier_confirmed', variant: 'success'},
  {id: 'tendered', variant: 'success'},

  // IN TRANSIT STATUSES
  {id: 'dispatched', variant: 'active'},
  {id: 'at_pickup', variant: 'active'},
  {id: 'in_transit', variant: 'active'},
  {id: 'at_delivery', variant: 'active'},
  {id: 'at_origin_airport', variant: 'active'},
  {id: 'at_destination_airport', variant: 'active'},
  {id: 'at_origin_port', variant: 'active'},
  {id: 'at_destination_port', variant: 'active'},
  {id: 'available', variant: 'active'},
  {id: 'empty_outgated', variant: 'active'},
  {id: 'full_ingated', variant: 'active'},
  {id: 'loaded', variant: 'active'},
  {id: 'vessel_departed', variant: 'active'},
  {id: 'vessel_arrived', variant: 'active'},
  {id: 'cleared_customs', variant: 'active'},
  {id: 'discharged', variant: 'active'},
  {id: 'full_outgated', variant: 'active'},
  {id: 'empty_returned', variant: 'active'},
  {id: 'loaded_on_rail', variant: 'active'},
  {id: 'departed_on_rail', variant: 'active'},
  {id: 'arrived_at_rail_facility', variant: 'active'},
  {id: 'available_at_rail_facility', variant: 'active'},
  {id: 'full_outgated_from_rail_facility', variant: 'active'},

  // COMPLETED STATUSES
  {id: 'delivered', variant: 'default'},
  {id: 'reconciled', variant: 'default'},

  // ALERT STATUSES
  {id: 'exception', variant: 'alert'},
  {id: 'customs_hold', variant: 'alert'},
  {id: 'customs_review', variant: 'alert'},
  {id: 'cancelled', variant: 'alert'},

  // Statuses in the original array but not in the image
  {id: 'scheduled', variant: 'default'},
  {id: 'auction_running', variant: 'default'},
  {id: 'out_for_delivery', variant: 'default'},
  {id: 'archived', variant: 'default'}
];

type ShipmentStatusMap = {[key in ShipmentStatePlusArchived]: (typeof shipmentStatuses)[number]};

export const shipmentStatusMap = shipmentStatuses.reduce<ShipmentStatusMap>((statuses, status) => {
  const {id} = status;
  return {...statuses, [id]: status};
}, {} as ShipmentStatusMap);

function statusToVariant(status: string | undefined) {
  if (!status) {
    return 'primary';
  }
  return status in shipmentStatusMap ? shipmentStatusMap[status].variant : 'primary';
}

export const STATUS_OPEN = {id: 'open', variant: 'primary'};
export const STATUS_DELAYED = {id: 'delayed', variant: 'alert'};

export function formatStatus(status: ShipmentStatePlusArchived) {
  return lowerCase(status);
}

export const StatusPill = ({
  id,
  label,
  className,
  ...props
}: {
  id: ShipmentStatePlusArchived;
  label: string;
  className?: string;
}) => (
  <Pill UNSAFEclassName={className} {...props}>
    {label || formatStatus(id)}
  </Pill>
);

export function LegacyShipmentStatusPill({status}: {status: string | undefined}) {
  return (
    <Pill size="xs" variant={statusToVariant(status)}>
      <span className="font-bold">{startCaseToLower(status).toUpperCase()}</span>
    </Pill>
  );
}
