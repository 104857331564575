import {RFPBidOptResponse, RFPBidOptResponseStateEnum, RfpLaneOptResponse} from '@shipwell/backend-core-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import SendRfpBidRequest from '../ActionButton/SendRfpBidRequest';
import {CreateRoutingGuideRequest} from './CreateRoutingGuideRequest';
import {isPastDate} from 'App/utils/dateTimeGlobalsTyped';

export const TableActionButton = ({rfp, lanes}: {rfp: RFPBidOptResponse; lanes: RfpLaneOptResponse[]}) => {
  const {state, id, number_of_lanes_with_bids} = rfp;

  const lanesWithoutBids = lanes.reduce((sum: number, lane) => {
    return (sum += !lane.unique_bids_received ? 1 : 0);
  }, 0);

  const isDisabled =
    [RFPBidOptResponseStateEnum.ContractsCreateRequested, RFPBidOptResponseStateEnum.ContractsRgCreateRequested].some(
      (status) => status === state
    ) || number_of_lanes_with_bids === 0;

  const isArchived = rfp.archived || false;

  if (isArchived) {
    return null;
  }

  switch (state) {
    case RFPBidOptResponseStateEnum.Created:
    case RFPBidOptResponseStateEnum.BidRequested:
      return !isPastDate(rfp.end_date || '') ? <SendRfpBidRequest rfp={rfp} /> : null;
    case RFPBidOptResponseStateEnum.BidComplete:
    case RFPBidOptResponseStateEnum.ContractsCreateRequested:
    case RFPBidOptResponseStateEnum.ContractsRgCreateRequested:
      return (
        <CreateRoutingGuideRequest laneCount={lanes.length} isDisabled={isDisabled} rfpId={id || ''}>
          {lanesWithoutBids > 0 ? (
            <div className="flex items-center gap-2 rounded bg-sw-active-light p-4 font-bold">
              <SvgIcon color="sw-active" name="InfoFilled" />
              <span>
                {pluralize('lane', lanesWithoutBids, true)} received no bids, any lane without bids will not have a
                Routing Guide or Contract(s) associated.
              </span>
            </div>
          ) : null}
        </CreateRoutingGuideRequest>
      );
    default:
      return null;
  }
};
