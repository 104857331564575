import {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from '@shipwell/shipwell-ui';
import FuelSurchargeTableCreateForm from '../createForm';
import './styles.scss';
import useDeleteFuelSurchargeTableMutation from 'App/api/rateTables/hooks/useDeleteFuelSurchargeTableMutation';

const FuelSurchargeTableCreate = ({onClose, onSubmitSuccess, onError, show}) => {
  const [selectedFuelSurchargeTable, setSelectedFuelSurchargeTable] = useState(null);
  //this should be a silent operation but if an error occurs log it for debugging
  const {mutate: deleteFuelSurchargeTable} = useDeleteFuelSurchargeTableMutation({
    onError: (error) => console.error(error)
  });

  const handleClose = () => {
    //if an FSC was created but the user bails delete the created object
    if (selectedFuelSurchargeTable?.id) {
      deleteFuelSurchargeTable(selectedFuelSurchargeTable.id);
    }
    setSelectedFuelSurchargeTable(null);
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      show={show}
      className="fuelSurcharge__modal"
      title="Create Fuel Surcharge Pricing Table"
      size="large"
      footerComponent={null}
    >
      <FuelSurchargeTableCreateForm
        onCancel={handleClose}
        onSubmitSuccess={onSubmitSuccess}
        onError={onError}
        selectedFuelSurchargeTable={selectedFuelSurchargeTable}
        setSelectedFuelSurchargeTable={setSelectedFuelSurchargeTable}
        isModal
      />
    </Modal>
  );
};

FuelSurchargeTableCreate.propTypes = {
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onError: PropTypes.func,
  show: PropTypes.bool
};

FuelSurchargeTableCreate.defaultProps = {
  onClose: () => {},
  onSubmitSuccess: () => {},
  onError: () => {}
};

export default FuelSurchargeTableCreate;
