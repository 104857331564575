import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {EquipmentType} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';

const EquipmentTypesField = ({
  name = 'equipment_types',
  required,
  disabled,
  ...props
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const {data: EquipmentTypesQueryData} = useEquipmentTypesQuery();
  return (
    <Field
      required={required}
      options={EquipmentTypesQueryData}
      getOptionLabel={(option: EquipmentType) => option.name}
      getOptionValue={(option: EquipmentType) => option.id}
      label="Equipment"
      name={name || 'equipment_types'}
      isMulti
      component={FormikSelect}
      disabled={disabled}
      {...props}
    />
  );
};

export default EquipmentTypesField;
