/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import {ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import axios from 'axios';
import get from 'lodash/get';

export const getV3ApiErrorDetail = (error: unknown, fallbackErrorMessage: string) =>
  get(error, 'errors[0].detail', fallbackErrorMessage);

/**
 * A utility that intends to handle API exceptions in a way that produces viable
 * messaging for the user and distinguishes different cases we can and should handle.
 * It is currently type specific to `ShipwellApiErrorResponse` from the tempus sdk,
 * but presumably that type ought to be effectively identical in those different SDKs.
 */
export function parseV3ApiError(error: unknown): {
  isError: boolean;
  is404: boolean;
  is4xx: boolean;
  status: number;
  title: string;
  detail: string;
} {
  type HumaneErrorType = Error & {status?: number; response?: {status?: number}};
  let isError = false,
    is404 = false,
    is4xx = false;
  let status: number | string = 0;
  let title = '',
    detail = '';
  if (error && axios.isAxiosError(error)) {
    isError = true;
    const err = error as HumaneErrorType;
    status = err?.status ?? err?.response?.status ?? '0';
    status = typeof status === 'number' ? status : parseInt(`${status}`, 10);
    if (status == 404) {
      is404 = true;
    } else if (400 <= status && status < 500) {
      // Usually a noop 'cause we'll already have triggered toast and don't want to hide fields
      is4xx = true;
    }
    const errors = (error.response?.data as ShipwellApiErrorResponse)?.errors;
    if (errors?.length) {
      title = errors[0].title;
      detail = errors[0].detail ?? '';
      status = status || parseInt(errors[0].status || '0', 10);
      if (errors.length > 1) {
        detail +=
          '<br />Other errors: ' +
          errors
            .slice(1)
            .map(({title, detail = ''}) => `<br />${title}<br />${detail ?? ''}`)
            .join('<br />\n');
      }
    } else if (error.response?.data && typeof error.response.data === 'object' && `error` in error.response?.data) {
      const v2Error = error.response.data as {
        error: string;
        error_description: string;
        field_errors: Record<string, string[]>;
        field_errors_condensed: Array<{
          field_name: string;
          field_errors: string[];
        }>;
        non_field_errors: string[];
      };

      const {field_errors_condensed, non_field_errors, error_description} = v2Error;
      title = v2Error.error;
      detail =
        //show error details in order of error specificity (field errors, then non field errors,
        //then the generic error description)
        field_errors_condensed
          ?.map(({field_name, field_errors}) => {
            if (field_errors.length === 0) return '';
            if (field_errors.length === 1) {
              return `${field_name}: ${field_errors[0]}\n`;
            }
            return `${field_name}:\n${field_errors.map((e) => `  ${e}\n`).join('')}`;
          })
          .join('') ||
        non_field_errors?.join('\n') ||
        error_description ||
        '';
      if (title && !detail) {
        detail = title;
        title = 'Error!';
      }
    } else if (status == 401) {
      title = 'Unauthorized';

      try {
        detail = `You don't have permission to access this resource: ${JSON.stringify(error)}}`;
      } catch (error) {
        detail = `You don't have permission to access this resource.}`;
      }
    } else if (status == 403) {
      title = 'Forbidden';
      try {
        detail = `This resource is forbidden: ${JSON.stringify(error)}}`;
      } catch (error) {
        detail = `This resource is forbidden.}`;
      }
    } else {
      title = 'Unknown Error';
      try {
        detail = `Unknown error: ${JSON.stringify(error)}`;
      } catch (error) {
        detail = `Unknown error.`;
      }
    }
  } else if (error) {
    isError = true;
    title = 'Internal Client Error';
    try {
      detail = JSON.stringify(error);
    } catch (error) {
      detail = 'Unknown error.';
    }
  }
  return {isError, is404, is4xx, status: Number(status), title, detail};
}

export async function getV2ApiAllOfPaginated<T>(
  fetchPage: (page: number, pageSize: number) => Promise<{results?: T[]; total_count?: number}>,
  pageSize = 10
): Promise<T[]> {
  let nPages = 1;
  let data: T[] = [];
  for (let page = 1; page <= nPages; page++) {
    const {results, total_count = 0} = await fetchPage(page, pageSize);
    data = results ? data.concat(results) : data;
    nPages = Math.ceil(total_count / pageSize);
  }
  return data;
}

export async function getV3ApiAllOfPaginated<T>(
  fetchPage: (page: number, pageSize: number) => Promise<{data: T[]; total_count: number}>,
  pageSize = 10
): Promise<T[]> {
  let nPages = 1;
  let results: T[] = [];
  for (let page = 1; page <= nPages; page++) {
    const {data, total_count} = await fetchPage(page, pageSize);
    results = results.concat(data);
    nPages = Math.ceil(total_count / pageSize);
  }
  return results;
}

type PaginationLinks = {
  next?: string;
  prev?: string;
  first?: string;
  last?: string;
};

interface V3ApiSpecPaginationParameters {
  count?: number;
  total_count?: number;
  links?: {
    next?: string;
    prev?: string;
    first?: string;
    last?: string;
  };
}

export function getLinkPageIndex(
  paginatedData?: V3ApiSpecPaginationParameters,
  linkType: keyof PaginationLinks = 'next'
) {
  const linkByType = paginatedData?.links?.[linkType];
  if (!linkByType) {
    return null;
  }
  const linkPageParameter = new URLSearchParams(new URL(linkByType).search).get('page');
  return linkPageParameter ? parseFloat(linkPageParameter) : null;
}
