import moment from 'moment';
import {string, array, object, number} from 'yup';
import {validateDecimalPoints} from 'App/utils/yupHelpers';

export const defaultAccessorialRatesFormValues = {
  name: '',
  external_reference: '',
  customers: [],
  start_date: moment().format('YYYY-MM-DD'),
  end_date: null,
  status: 'ACTIVE',
  calculation_rows: [{accessorial: null, calculation_description: null, rate: null}]
};

export const accessorialCriteriaValidationSchemaFields = {
  name: string().nullable().required('Accessorial Charge table name is required.'),
  external_reference: string().nullable().required('Accessorial Charge table reference is required.'),
  status: string().nullable().required('Status is required.'),
  customers: array().of(
    object().shape({
      id: string().nullable()
    })
  )
};

export const accessorialRatesValidationSchemaFields = {
  calculation_rows: array()
    .of(
      object().shape({
        accessorial: string().nullable().required('Accessorial is required.'),
        calculation_description: string().nullable().required('Accessorial Charge is required.'),
        rate: number()
          .nullable()
          .min(0.001, 'Must be greater than 0.')
          .required('Rate is required.')
          .typeError('Rate must be a valid dollar amount.')
          .test({
            name: 'Maximum 3 decimals',
            message: 'Enter up to 3 decimals.',
            test: (value) => (value ? validateDecimalPoints(value, 3) : true)
          })
      })
    )
    .nullable()
};
