import {Field, reduxForm, Form} from 'redux-form';
import {Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderEmailMultiselect from 'App/formComponents/renderEmailMultiselect';
import renderTextArea from 'App/formComponents/renderTextArea';
import renderField from 'App/formComponents/renderField';
import validate from 'App/utils/validateSendDocumentForm';
import {getAddressBookPromise} from 'App/actions/addressBook';

let SendShipmentDocumentsForm = (props) => {
  const {addressBook, handleSubmit, submitting, selectedDocuments, handleDocumentClick, sent} = props;

  const normalizeResults = (resp) => {
    const emailSet = new Set();
    const emails = [];

    resp.results.forEach((result) => {
      result.point_of_contacts.forEach(({email}) => {
        if (!emailSet.has(email)) {
          emailSet.add(email);
          emails.push({label: email, value: email});
        }
      });
    });
    return emails;
  };

  const searchAddressBook = (input = '') => {
    if (input && input.trim().length) {
      return getAddressBookPromise({
        q: input,
        pageSize: 100
      }).then((resp) => {
        return {options: normalizeResults(resp)};
      });
    }
    return Promise.resolve({options: []});
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Paper className="shipment__document-card">
        <div className="shipment-documents__sendShipmentDocumentsForm">
          <Field
            type="email"
            name="recipient_emails"
            component={renderEmailMultiselect}
            loadOptions={searchAddressBook}
            label="Recipients"
            placeholder="To"
            autoload={false}
            cache={false}
            allowNew
            req
          />
          <Field name="subject" component={renderField} label="Subject" placeholder="Subject" req />
          <Field
            label="Message"
            name="message"
            req
            component={renderTextArea}
            placeholder="Enter message"
            minRows={2}
          />

          <div className="shipment-documents__sendShipmentDocumentsForm-footer">
            <div className="shipment-documents__sendShipmentDocumentsForm-attachments">
              {selectedDocuments &&
                selectedDocuments.map((doc, i) => {
                  return (
                    <div className="shipment-documents__sendShipmentDocumentsForm-attachmentLine" key={i}>
                      <i className="icon icon-PaperClip pad-left pad-right" />
                      <span className="pad-right">{doc.filename}</span>
                      {!props.isSendSingleDocModal && (
                        <i
                          title="Remove"
                          onClick={() => {
                            handleDocumentClick(doc.id);
                          }}
                          className="icon icon-x-small"
                        />
                      )}
                    </div>
                  );
                })}
            </div>
            <span>
              {selectedDocuments && selectedDocuments.length === 0 && (
                <span className="error-text pad-right">
                  <i className="icon icon-Delayed pad-right" />
                  Select a document to send
                </span>
              )}
              {sent && (
                <span className="form-saved pad-right">
                  <i className="icon icon-Check pad-right" />
                  Sent!
                </span>
              )}
            </span>
          </div>
        </div>
      </Paper>
      <div className="document-upload__footer">
        <Button className="btn btn-default" disabled={submitting} onClick={props.goBack}>
          Cancel
        </Button>

        <Button
          disabled={submitting || (selectedDocuments && selectedDocuments.length === 0)}
          type="submit"
          bsStyle="primary"
        >
          {submitting ? (
            <span>
              {' '}
              <i className="icon icon-Restart rotate" />
              &nbsp;Sending
            </span>
          ) : (
            <span>
              {' '}
              <i className="icon icon-Plane pad-right" /> Send
            </span>
          )}
        </Button>
      </div>
    </Form>
  );
};

SendShipmentDocumentsForm = reduxForm({
  form: 'SendShipmentDocumentsForm',
  touchOnChange: true, // react-dropzone doesn't blur
  validate
})(SendShipmentDocumentsForm);

export default SendShipmentDocumentsForm;
