import {useState} from 'react';
import {ShipmentTag} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Select} from '@shipwell/shipwell-ui';
import {FilterLabelWithFilterType, TableFilter} from 'App/components/TypedTable/complementaryComponents';
import {useGetShipmentTags} from 'App/data-hooks';
import {FILTER_TYPE, FilterType} from 'App/components/TypedTable/complementaryComponents/FilterLabelWithFilterType';
import {FlexBox} from 'App/components/Box';

export const TagsFilter = ({
  isOpen,
  onToggleOpen,
  isClearable,
  onClear,
  tags,
  tagsExclude,
  onChangeOr,
  onChangeExclude
}: {
  isOpen: boolean;
  onToggleOpen: () => void;
  isClearable: boolean;
  onClear: () => void;
  tags: string[];
  tagsExclude: string[];
  onChangeOr: (newTags: string[]) => void;
  onChangeExclude: (newTags: string[]) => void;
}) => {
  const defaultFilterType = tagsExclude.length ? FILTER_TYPE.EXCLUDE : FILTER_TYPE.OR;
  const [filterType, setFilterType] = useState<FilterType>(defaultFilterType);
  const [searchVal, setSearchVal] = useState('');

  const {shipmentTagsData, shipmentTagsStatus} = useGetShipmentTags({});
  const filteredTags =
    shipmentTagsData?.filter((tag) => tag.name?.toLowerCase().includes(searchVal.toLowerCase())) || [];

  const isOrFilterType = filterType === FILTER_TYPE.OR;
  const activeTags = isOrFilterType ? tags : tagsExclude;
  const defaultTags = activeTags.reduce((tags: ShipmentTag[], tagId) => {
    const tag = shipmentTagsData?.find((shipmentTag) => shipmentTag.id === tagId);
    return [...tags, ...(tag ? [tag] : [])];
  }, []);

  const handleSelectFilterType = (val: FilterType) => {
    setFilterType(val);
    if (val === FILTER_TYPE.OR) {
      onChangeOr(tagsExclude);
      onChangeExclude([]);
    }
    if (val === FILTER_TYPE.EXCLUDE) {
      onChangeExclude(tags);
      onChangeOr([]);
    }
  };

  return (
    <TableFilter
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      label={<FilterLabelWithFilterType label="Tags" filterType={filterType} onChange={handleSelectFilterType} />}
      isClearable={isClearable}
      onClear={onClear}
    >
      <Select
        value={defaultTags}
        onInputChange={setSearchVal}
        onChange={(options: ShipmentTag[]) => {
          const selectedTagIds = options.map((option) => option.id || '');
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isOrFilterType ? onChangeOr(selectedTagIds) : onChangeExclude(selectedTagIds);
        }}
        isLoading={shipmentTagsStatus === 'loading'}
        options={filteredTags}
        isMulti
        label="Search Tags"
        getOptionValue={(option: ShipmentTag) => option.id}
        getOptionLabel={(option: ShipmentTag) => option.name}
        formatOptionLabel={(option: ShipmentTag) => (
          <FlexBox key={option.id} items="center" gap="s">
            <div className={`tag-option size-4 rounded-full ${option?.color || ''}`} />
            <span>{option.name}</span>
          </FlexBox>
        )}
      />
    </TableFilter>
  );
};
