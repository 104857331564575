import {ComponentPropsWithoutRef} from 'react';
import {branch} from 'recompose';
import {SvgIcon, Tooltip, DeprecatedButton} from '@shipwell/shipwell-ui';
import type {SvgIconName} from '@shipwell/shipwell-ui';
import withFlagsFallback from 'App/components/withFlagsFallback';

const TABS: {icon: SvgIconName; tooltipInfo: string; name: string}[] = [
  {icon: 'Details', tooltipInfo: 'Details', name: 'details'},
  {icon: 'Timeline', tooltipInfo: 'Timeline', name: 'timeline'},
  {icon: 'Workflow', tooltipInfo: 'Workflows', name: 'workflows'},
  {icon: 'DollarFilled', tooltipInfo: 'Bids', name: 'myBids'},
  {icon: 'Messages', tooltipInfo: 'Messages', name: 'messages'},
  {icon: 'Stages', tooltipInfo: 'Stages', name: 'stages'},
  {icon: 'StoreBids', tooltipInfo: 'Bids', name: 'carrierBids'}
];

function DashboardSummaryTabButtonBase({
  tab,
  isActive,
  onClick
}: {
  tab: (typeof TABS)[number];
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <DeprecatedButton variant="activeIcon" className="cursor-pointer" active={isActive} onClick={onClick}>
      <Tooltip placement="left" tooltipContent={tab.tooltipInfo}>
        <SvgIcon color="$sw-icon" height="24" width="24" name={tab.icon} />
      </Tooltip>
    </DeprecatedButton>
  );
}

type DashboardSummaryTabButtonBaseProps = ComponentPropsWithoutRef<typeof DashboardSummaryTabButtonBase>;

const DashboardSummaryTabButton = branch(
  ({tab}: DashboardSummaryTabButtonBaseProps) => tab.name === 'stages',
  withFlagsFallback<DashboardSummaryTabButtonBaseProps>(['modeMultiModeDashboard'])
)(DashboardSummaryTabButtonBase);

interface DashboardSummaryTabsProps {
  onClick: (index: number) => void;
  selectedIndex: number;
  options: string[];
}

export function DashboardSummaryTabs({onClick, selectedIndex, options}: DashboardSummaryTabsProps) {
  return (
    <div className="border-r-1 border-sw-border">
      {TABS.filter(({name}) => options.includes(name)).map((tab, idx) => {
        return (
          <DashboardSummaryTabButton
            key={idx}
            tab={tab}
            isActive={selectedIndex === idx}
            onClick={() => onClick(idx)}
          />
        );
      })}
    </div>
  );
}
