import {isPlainObject, omitBy} from 'lodash';
import isNil from 'lodash/isNil';

type PlainObject = Record<string, unknown>;
export function isPlainObjectType(object: unknown): object is PlainObject {
  return isPlainObject(object);
}
/**
 * Recursively filter out keys of an object whose value is nil or an empty string.
 */
export function omitEmptyKeys(obj: unknown): PlainObject | undefined {
  if (isPlainObjectType(obj)) {
    return Object.keys(obj).reduce((filtered, key) => {
      const value = obj[key];
      //omit nil and empty string values
      if (isNil(value) || value === '') {
        return filtered;
      }
      if (isPlainObjectType(value)) {
        return {...filtered, [key]: omitEmptyKeys(value)};
      }
      if (Array.isArray(value)) {
        return {
          ...filtered,
          [key]: value.map((v: Record<string, unknown>) => (isPlainObject(v) ? omitEmptyKeys(v) : v))
        };
      }
      return {...filtered, [key]: value};
    }, {});
  }
  return {};
}
/**
 * helper function for omitEmptyKeysWithEmptyObjectsRemoved below
 */
export function isEmptyValue(value: unknown) {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}
/**
 * simple add-on to omitEmptyKeys, that takes the resultant object
 * and removes all of the empty object keys.
 */
export function omitEmptyKeysWithEmptyObjectsRemoved<T>(obj: T): T {
  return omitBy(omitEmptyKeys(obj), isEmptyValue) as T;
}
