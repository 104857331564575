import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikDateTimePicker, SvgIcon} from '@shipwell/shipwell-ui';
import {EquipmentType} from '@shipwell/backend-core-sdk';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import Loader from 'App/common/shipwellLoader';
import {equipmentTypesByModeFilter} from 'App/utils/filterEquipmentTypes';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';

const LegacyShipmentStageFields = () => {
  const equipmentQuery = useEquipmentTypesQuery();
  const {errors, values} = useFormikContext<NewStageFormValues>();
  const mode = {
    id: null,
    code: values?.stage_type?.toUpperCase()
  };

  if (equipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <div className="grid grid-cols-1 gap-4 pt-4">
      <Field
        component={FormikSelect}
        name="equipment"
        label="Equipment"
        required
        options={equipmentQuery.data?.filter(equipmentTypesByModeFilter(mode)) || []}
        getOptionValue={(option: EquipmentType) => option.id}
        getOptionLabel={(option: EquipmentType) => option.name}
      />
      <Field
        component={AddressSearch}
        name="pickup_location"
        label="Pickup Location"
        required
        useFormikValidation
        isFormikValid={!errors['pickup_location']}
        customMenuOptionType="ADDRESS_BOOK"
      />
      <Field
        component={FormikDateTimePicker}
        name="pickup_date"
        label="Pickup Date"
        required
        prepend={<SvgIcon name="Calendar" />}
      />
      <Field
        component={AddressSearch}
        name="delivery_location"
        label="Delivery Location"
        required
        useFormikValidation
        isFormikValid={!errors['delivery_location']}
        customMenuOptionType="ADDRESS_BOOK"
      />

      <Field
        component={FormikDateTimePicker}
        name="delivery_date"
        label="Delivery Date"
        required
        prepend={<SvgIcon name="Calendar" />}
      />
    </div>
  );
};

export default LegacyShipmentStageFields;
