import useInterval from 'App/utils/hooks/useInterval';

const PollingProcessor = ({callback}: {callback: () => void}) => {
  useInterval(() => {
    callback();
  }, 1000);

  return null;
};

export default PollingProcessor;
