import {object, string} from 'yup';
import {Formik, Field} from 'formik';
import {DeprecatedButton, Card, FormikTextInput, SvgIcon} from '@shipwell/shipwell-ui';
import {NetsuiteConfig} from '@shipwell/backend-core-sdk';

/** Default NetSuite Configuration values */
export const defaultValues = {
  account_id: '',
  token_id: '',
  token_secret: '',
  consumer_key: '',
  consumer_secret: ''
};

/** NetSuite Configuration validation rules */
export const validationSchema = object().shape({
  account_id: string().required('Account ID is required.'),
  token_id: string().required('Token ID is required.'),
  token_secret: string().required('Token Secret is required.'),
  consumer_key: string().required('Consumer Key or Client ID is required.'),
  consumer_secret: string().required('Consumer Secret or Client Secret is required.')
});

export type NetSuiteConfigurationFormPropsType = {
  values: NetsuiteConfig | Record<string, never>;
  onSubmit: (values: NetsuiteConfig) => Promise<void>;
  onNext: () => void;
  isConfigured: boolean;
  configurationError: string | null;
  isLoading: boolean;
};

/**
 * NetSuite Configuration Form
 * @param {*} props
 */
const NetSuiteConfigurationForm = ({
  values,
  onSubmit,
  onNext,
  isConfigured,
  configurationError,
  isLoading,
  ...formProps
}: NetSuiteConfigurationFormPropsType) => {
  const handleNext = () => {
    if (onNext) {
      onNext();
    }
  };

  const handleSubmit = (values: NetsuiteConfig) => {
    if (onSubmit) {
      void onSubmit(values);
    }
  };

  return (
    <Card
      title={
        <div className="flex w-full items-center justify-between">
          <img alt="integration-logo" className="mr-2 max-h-6" src="/images/integration-netsuite.png" />
          <span>NetSuite Configuration</span>
        </div>
      }
      draggableProvided={false}
    >
      <Formik
        {...formProps}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{...defaultValues, ...values}}
      >
        {({handleSubmit, isSubmitting, isValid, dirty}) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="grid auto-rows-[minmax(60px,auto)] grid-cols-[repeat(2,1fr)] items-start justify-items-stretch gap-x-[10px]">
              <Field name="token_id" label="Token ID" component={FormikTextInput} required />
              <Field name="token_secret" label="Token Secret" component={FormikTextInput} required />
              <Field name="consumer_key" label="Consumer Key or Client ID" component={FormikTextInput} required />
              <Field
                name="consumer_secret"
                label="Consumer Secret or Client Secret"
                component={FormikTextInput}
                required
              />
              <Field name="account_id" label="NetSuite Account ID" component={FormikTextInput} required />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center justify-start font-bold">
                {configurationError ? (
                  <>
                    <SvgIcon name="InfoOutlined" color="$sw-error" className="mr-1.5" />
                    <span className="text-sw-error"> {configurationError}</span>
                  </>
                ) : isConfigured ? (
                  <>
                    <SvgIcon name="CheckCircleFilled" color="$sw-success" className="mr-1.5" />
                    <span className="text-sw-success">Connected</span>
                  </>
                ) : null}
              </div>
              <div className="flex flex-row items-center justify-end">
                {(!isConfigured || dirty || isSubmitting) && (
                  <DeprecatedButton
                    variant="primary"
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting || isLoading}
                    loading={isSubmitting}
                  >
                    Connect
                  </DeprecatedButton>
                )}
                <DeprecatedButton variant="primary" onClick={handleNext} disabled={!isConfigured} className="m-0 ml-3">
                  Next
                </DeprecatedButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default NetSuiteConfigurationForm;
