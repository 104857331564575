import {Supplier} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteSupplierUser} from 'App/api/suppliers';
import {UseMutationOptionsUtil} from 'App/utils/queryHelpers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const deleteSupplierUsers = async ({supplierId, userIds}: {supplierId: string; userIds: string[]}) => {
  const results = await Promise.allSettled(userIds.map((userId) => deleteSupplierUser({supplierId, userId})));
  if (results.some((result) => result.status === 'rejected')) {
    throw new Error('There was an error deleting supplier contacts.');
  }
};

export const useDeleteSupplierUsers = (options?: UseMutationOptionsUtil<typeof deleteSupplierUsers>) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(deleteSupplierUsers, {
    ...options,
    async onMutate(variables) {
      const userIdsToDelete = variables.userIds;
      await queryClient.cancelQueries([SUPPLIERS_QUERY_KEY]);
      const previousSupplier = queryClient.getQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId]);
      queryClient.setQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId], (oldSupplier) => {
        if (oldSupplier)
          return {...oldSupplier, users: oldSupplier.users?.filter((oldUser) => !userIdsToDelete.includes(oldUser.id))};
      });

      options?.onMutate?.(variables);
      return {previousSupplier};
    },
    onError(error, variables, context) {
      queryClient.setQueryData([SUPPLIERS_QUERY_KEY, variables.supplierId], context?.previousSupplier);
      options?.onError?.(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
      options?.onSettled?.(data, error, variables, context);
    }
  });
  return {deleteSupplierUsers: mutate, isDeletingUsers: isLoading};
};
