import {array, object, string} from 'yup';
import {TemplateDocumentType, TemplateStatus, TemplateMode} from '@shipwell/template-builder-sdk';

export const templateValidationSchema = object().shape({
  description: string(),
  status: string()
    .nullable(true)
    .oneOf([...Object.values(TemplateStatus), null], 'Invalid status.')
    .required('Status is required.'),
  document_type: string()
    .oneOf(Object.values(TemplateDocumentType), 'Invalid document type.')
    .nullable(true)
    .required('Document type is required.'),
  allowed_modes: array()
    .of(string().oneOf(Object.values(TemplateMode), 'Invalid mode.'))
    .nullable(false)
});
