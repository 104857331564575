import {useMutation, useQuery} from '@tanstack/react-query';
import {
  StorageServiceTypeEnum,
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  TerminalFeeServiceTypeEnum,
  TransloadServiceTypeEnum
} from '@shipwell/corrogo-sdk';
import {
  getTransloadService,
  getTerminalFeeService,
  getStorageService,
  createTransloadService,
  createTerminalFeeService,
  createStorageService,
  updateTransloadService,
  updateTerminalFeeService,
  updateStorageService
} from 'App/api/corrogo/typed';
import {SERVICES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';

export const useRetrieveService = (
  serviceType:
    | TransloadServiceTypeEnum
    | TerminalFeeServiceTypeEnum
    | StorageServiceTypeEnum
    | OceanTrackingServiceTypeEnum
    | RailTrackingServiceTypeEnum
    | undefined,
  serviceId: string
) => {
  return useQuery(
    [SERVICES_QUERY_KEY, serviceType, serviceId],
    async () => {
      if (serviceType === TransloadServiceTypeEnum.Transload) {
        const response = await getTransloadService(serviceId);
        return response.data;
      }
      if (serviceType === StorageServiceTypeEnum.Storage) {
        const response = await getStorageService(serviceId);
        return response.data;
      }
      if (serviceType === TerminalFeeServiceTypeEnum.TerminalFee) {
        const response = await getTerminalFeeService(serviceId);
        return response.data;
      }
    },
    {enabled: !!(serviceId && serviceType)}
  );
};

export const useServiceCreation = () => {
  const createTransloadServiceMutation = useMutation(createTransloadService);
  const createTerminalFeeServiceMutation = useMutation(createTerminalFeeService);
  const createStorageServiceMutation = useMutation(createStorageService);

  const getCreationMutation = (stageType: NewStageFormValues['stage_type']) => {
    switch (stageType) {
      case TransloadServiceTypeEnum.Transload:
        return createTransloadServiceMutation;
      case TerminalFeeServiceTypeEnum.TerminalFee:
        return createTerminalFeeServiceMutation;
      default:
        return createStorageServiceMutation;
    }
  };

  return getCreationMutation;
};

export const useUpdateService = () => {
  const updateTransloadServiceMutation = useMutation(updateTransloadService);
  const updateTerminalFeeServiceMutation = useMutation(updateTerminalFeeService);
  const updateStorageServiceMutation = useMutation(updateStorageService);

  const getUpdateMutation = (stageType: NewStageFormValues['stage_type']) => {
    switch (stageType) {
      case TransloadServiceTypeEnum.Transload:
        return updateTransloadServiceMutation;
      case TerminalFeeServiceTypeEnum.TerminalFee:
        return updateTerminalFeeServiceMutation;
      default:
        return updateStorageServiceMutation;
    }
  };

  return getUpdateMutation;
};
