import {ALERT_ERROR} from 'App/actions/types';

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

/**
 * Get Power Units List
 */
export const REQUEST_POWER_UNITS_SUCCESS = 'REQUEST_POWER_UNITS_SUCCESS';

export const requestPowerUnitsSuccess = (response) => ({
  type: REQUEST_POWER_UNITS_SUCCESS,
  payload: response.body
});

/**
 * Get Power Unit
 */
export const REQUEST_POWER_UNIT_SUCCESS = 'REQUEST_POWER_UNIT_SUCCESS';

export const requestPowerUnitSuccess = (response) => ({
  type: REQUEST_POWER_UNIT_SUCCESS,
  payload: response.body
});

/**
 * Update Power Unit
 */
export const UPDATE_POWER_UNIT_SUCCESS = 'UPDATE_POWER_UNIT_SUCCESS';

export const updatePowerUnitSuccess = (response) => ({
  type: UPDATE_POWER_UNIT_SUCCESS,
  payload: response.body
});

/**
 * Create Power Unit
 */
export const CREATE_POWER_UNIT_SUCCESS = 'CREATE_POWER_UNIT_SUCCESS';

export const createPowerUnitSuccess = (response) => ({
  type: CREATE_POWER_UNIT_SUCCESS,
  payload: response.body
});

/**
 * Delete Power Unit
 */
export const DELETE_POWER_UNIT_SUCCESS = 'DELETE_POWER_UNIT_SUCCESS';

export const deletePowerUnitSuccess = (trailerId) => ({
  type: DELETE_POWER_UNIT_SUCCESS,
  payload: trailerId
});
