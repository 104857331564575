import {ShipmentLineItemWeightUnitEnum} from '@shipwell/backend-core-sdk';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';
export const LineItemWeightUnitField = ({index, disabled}: {index: number; disabled: boolean}) => {
  const weightUnitOptions = enumToOptionConverter(ShipmentLineItemWeightUnitEnum);
  return (
    <Field
      name={`line_items[${index}].weight_unit`}
      component={FormikSelect}
      className="min-w-0"
      disabled={disabled}
      options={weightUnitOptions}
      simpleValue
    />
  );
};
