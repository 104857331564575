import Loader from 'App/common/shipwellLoader';
import Map from 'App/components/trimbleMap';
import {convertGeocodeResultsToLocations, makeRouteConfig} from 'App/components/trimbleMap/utils/typed';
import useGeocodeLocation from 'App/components/trimbleMap/utils/useGeocodeLocation';
import {useShipmentTrackingSummary, useV3Shipment} from 'App/data-hooks';

export const MapContainer = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {queryInfo: shipmentTrackingSummaryQuery, trackingLocations} = useShipmentTrackingSummary(shipmentId);

  const {pickupStopInfo, deliveryStopInfo} = shipmentQuery.context;

  const pickupGeocodeQuery = useGeocodeLocation(pickupStopInfo.stop?.location);
  const deliveryGeocodeQuery = useGeocodeLocation(deliveryStopInfo.stop?.location);

  if (
    shipmentQuery.isInitialLoading ||
    pickupGeocodeQuery.isInitialLoading ||
    deliveryGeocodeQuery.isInitialLoading ||
    shipmentTrackingSummaryQuery.isInitialLoading
  ) {
    return <Loader loading />;
  }

  if (
    !pickupGeocodeQuery.data ||
    !deliveryGeocodeQuery.data ||
    !pickupStopInfo.stop ||
    !deliveryStopInfo.stop ||
    !pickupStopInfo.stopDisplayValues ||
    !deliveryStopInfo.stopDisplayValues
  ) {
    return <span>Error retrieving route</span>;
  }

  const route = makeRouteConfig([pickupGeocodeQuery.data, deliveryGeocodeQuery.data]);

  const stopLocations = convertGeocodeResultsToLocations(
    [pickupGeocodeQuery.data, deliveryGeocodeQuery.data],
    [pickupStopInfo.stopDisplayValues, deliveryStopInfo.stopDisplayValues]
  );

  return (
    <div className="relative h-full">
      <Map
        additionalClassNames="absolute inset-0"
        elementId="shipment-tracking-map"
        route={route}
        locations={[...stopLocations, ...trackingLocations]}
        totalDistance={shipmentQuery.context.shipmentInfoDisplayValues.totalMiles}
      />
    </div>
  );
};
