import {browserHistory} from 'react-router';
import {OnChangeFn, PaginationState, Updater} from '@tanstack/react-table';
import {UseQueryResult, useMutation} from '@tanstack/react-query';
import {useMemo, useState} from 'react';
import {UserPreferences} from '@shipwell/backend-core-sdk';
import {updateUserPreferences} from 'App/api/shipwellUI/typed';
import {useUserMe, useUserPreferencesQuery} from 'App/data-hooks';
import {DEFAULT_TABLE_PAGINATION_SIZE} from '../tableUtils';

type TablePagination = {
  defaultPageSize?: number;
  tableType?: string;
  shouldUseRouter?: boolean;
};

export function useTablePagination(tablePagination?: TablePagination): [
  {
    pageIndex: number;
    pageSize: number;
  },
  OnChangeFn<PaginationState>,
  UseQueryResult<UserPreferences, unknown>['status']
] {
  const tableType = tablePagination?.tableType;
  const defaultPageSize = tablePagination?.defaultPageSize;
  const shouldUseRouter = tablePagination?.shouldUseRouter;
  const {data} = useUserMe();
  const userId = data?.user?.id;
  const userPreferencesQuery = useUserPreferencesQuery({
    enabled: !!tableType,
    onSuccess: (data) => {
      const userPageSize = data.table_row_defaults?.[tableType || ''];
      if (!userPageSize) {
        return;
      }
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          pageSize: userPageSize
        };
      });
    }
  });

  const userPreferencesMutation = useMutation(updateUserPreferences);
  const initialPageSize = defaultPageSize || DEFAULT_TABLE_PAGINATION_SIZE;
  const [{pageIndex, pageSize}, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize
  });

  const searchParamURL = new URLSearchParams(location.search);
  const paramPageIndex = parseInt(searchParamURL.get('page_index') || '0');
  const paramPageSize = parseInt(searchParamURL.get('page_size') || `${initialPageSize}`);

  const pagination = useMemo(
    () => ({
      pageIndex: shouldUseRouter ? paramPageIndex : pageIndex,
      pageSize: shouldUseRouter ? paramPageSize : pageSize
    }),
    [pageIndex, pageSize, paramPageIndex, paramPageSize, shouldUseRouter]
  );

  const handleUpdateUserPreferences = (updatedPagination: PaginationState) => {
    // Should only update on page SIZE change, not page Index
    if (pagination.pageSize === updatedPagination.pageSize) {
      return;
    }
    if (!(tableType && userId)) {
      return;
    }
    userPreferencesMutation.mutate({table_row_defaults: {[tableType]: updatedPagination.pageSize}, user: userId});
  };

  // adds a side effect to update user preferences onchange
  function onPaginationChange(updater: Updater<PaginationState>, isInit?: boolean): void {
    const getState = (prevState: PaginationState) => (updater instanceof Function ? updater(prevState) : updater);
    if (!shouldUseRouter) {
      setPagination((prevPagination) => {
        const updatedPagination = getState(prevPagination);
        handleUpdateUserPreferences(updatedPagination);
        return updatedPagination;
      });
      return;
    }
    const updatedPagination = getState({pageIndex: paramPageIndex, pageSize: paramPageSize});
    searchParamURL.set('page_index', updatedPagination.pageIndex.toString());
    searchParamURL.set('page_size', updatedPagination.pageSize.toString());
    browserHistory.replace({
      pathname: window.location.pathname,
      search: `?${searchParamURL.toString()}`
    });
    if (isInit) {
      return;
    }
    handleUpdateUserPreferences(updatedPagination);
  }

  const userPreferencesStatus = userPreferencesQuery.status;

  return [pagination, onPaginationChange, userPreferencesStatus];
}
