import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Get SLAs
 */
export async function getSLAs(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.serviceLevelAgreementsGet(opts, apiCallback(resolve, reject));
  });
}

/**
 * Get SLA details by id
 */
export async function getSLADetails(slaId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.serviceLevelAgreementsServiceLevelAgreementIdGet(slaId, apiCallback(resolve, reject));
  });
}

/**
 * Create SLA
 */
export async function createSLA(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.serviceLevelAgreementsPost(body, apiCallback(resolve, reject));
  });
}

/**
 * Delete SLA
 */
export async function deleteSLA(slaId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.serviceLevelAgreementsServiceLevelAgreementIdDelete(slaId, apiCallback(resolve, reject));
  });
}

/**
 * Delete SLA
 */
export async function updateSLA(slaId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.serviceLevelAgreementsServiceLevelAgreementIdPut(slaId, body, apiCallback(resolve, reject));
  });
}
