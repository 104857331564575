import _ from 'lodash';
import moment from 'moment';
import isNil from 'lodash/isNil';
import {
  phoneUtil,
  validateEmail,
  validatePhoneNumber,
  validateDollarValue,
  validateIntegerValue,
  validateNumber,
  reeferTypes
} from 'App/utils/globals';

const validate = (values) => {
  const errors = {equipment_config: {driver: {}}};
  let isFTL = false;
  let isLTL = false;
  if (values.mode === '1' || (values.mode && values.mode.id === 1)) {
    isFTL = true;
  }
  if (
    values.mode === '2' ||
    (values.mode && values.mode.id === 2) ||
    values.mode === '4' ||
    (values.mode && values.mode.id === 4) ||
    !values.mode
  ) {
    isLTL = true;
  }

  if (values.ignoreValidation === true) {
    return errors;
  }

  if (!values.equipment_type) {
    errors.equipment_type = 'Select an equipment type';
  }

  //STOPS
  if (values.stops) {
    errors.stops = [];
    for (var i = 0; i < values.stops.length; i++) {
      errors.stops[i] = {};
      if (
        (values.stops[i] && Object.keys(values.stops[i]).length === 0) ||
        !values.stops[i].location ||
        Object.keys(values.stops[i].location).length === 0
      ) {
        errors.stops[i].location = 'Enter an address';
      } else if (!values.stops[i]) {
        errors.stops[i].location = 'Enter an address';
      }
      if (!values.stops[i].company) {
        errors.stops[i].company = 'Company required';
      }
      if (values.stops[i].point_of_contacts && values.stops[i].point_of_contacts.length > 0) {
        errors.stops[i].point_of_contacts = [];
        for (var k = 0; k < values.stops[i].point_of_contacts.length; k++) {
          errors.stops[i].point_of_contacts[k] = {};
          if (!values.stops[i].point_of_contacts[k].first_name) {
            errors.stops[i].point_of_contacts[k].first_name = 'First name required';
          }
          if (!values.stops[i].point_of_contacts[k].email) {
            errors.stops[i].point_of_contacts[k].email = 'Email required';
          }
          if (
            values.stops[i].point_of_contacts[k].email &&
            !validateEmail(values.stops[i].point_of_contacts[k].email)
          ) {
            errors.stops[i].point_of_contacts[k].email = 'Invalid email';
          }
          if (!values.stops[i].point_of_contacts[k].phone_number) {
            errors.stops[i].point_of_contacts[k].phone_number = 'Phone number required';
          }
          if (
            values.stops[i].point_of_contacts[k].phone_number &&
            !validatePhoneNumber(values.stops[i].point_of_contacts[k].phone_number)
          ) {
            errors.stops[i].point_of_contacts[k].phone_number = 'Invalid phone number';
          }
        }
      }
    }
  } else if (!values.stops) {
    errors.stops = [];
    errors.stops[0] = {};
    errors.stops[0].location = 'Enter a pickup location';
    errors.stops[1] = {};
    errors.stops[1].location = 'Enter a delivery location';
  }
  //LINE ITEMS
  if (isFTL && values.manual_total_weight) {
    if (!values.total_weight_override?.value || values.total_weight_override?.value <= 0) {
      errors.total_shipment_weight = 'Enter total shipment weight.';
    }
  }
  if (values.line_items && values.line_items.length > 0) {
    errors.line_items = [];
    for (let j = 0; j < values.line_items.length; j++) {
      errors.line_items[j] = {};
      if (!values.line_items[j].total_packages && isLTL) {
        errors.line_items[j].total_packages = 'Quantity required';
      } else if (values.line_items[j].total_packages && !validateIntegerValue(values.line_items[j].total_packages)) {
        errors.line_items[j].total_packages = 'Enter a valid whole number';
      }
      if (!values.line_items[j].height && isLTL) {
        errors.line_items[j].height = 'Height required';
      } else if (values.line_items[j].height && !validateIntegerValue(values.line_items[j].height)) {
        errors.line_items[j].height = 'Enter a valid whole number';
      }
      if (!values.line_items[j].width && isLTL) {
        errors.line_items[j].width = 'Width required';
      } else if (values.line_items[j].width && !validateIntegerValue(values.line_items[j].width)) {
        errors.line_items[j].width = 'Enter a valid whole number';
      }
      if (!values.line_items[j].length && isLTL) {
        errors.line_items[j].length = 'Length required';
      } else if (values.line_items[j].length && !validateIntegerValue(values.line_items[j].length)) {
        errors.line_items[j].length = 'Enter a valid whole number';
      }
      if (values.line_items[j].package_weight && !validateNumber(values.line_items[j].package_weight)) {
        errors.line_items[j].package_weight = 'Enter a valid number';
      }
      if (!values.line_items[j].description) {
        errors.line_items[j].description = 'Description required';
      }

      if (values.line_items[j].total_pieces && !validateIntegerValue(values.line_items[j].total_pieces)) {
        errors.line_items[j].total_pieces = 'Enter a valid whole number';
      }

      if (
        values.line_items[j].hazmat === true &&
        (!values.line_items[j].hazmatDetails || values.line_items[j].hazmatDetails === null)
      ) {
        errors.line_items[j].hazmatDetails = 'Enter Hazmat details';
      }
      //in FTL, we dont need a freight class
      if ((!values.line_items[j].freight_class || values.line_items[j].freight_class === 'select') && isLTL) {
        errors.line_items[j].freight_class = 'Freight class required';
      }
    }
  }
  //if no line items entered and mode selected includes LTL, error
  if (isLTL && (!values.line_items || values.line_items.length === 0)) {
    errors.line_items = {_error: 'At least one line item is required for LTL'};
  }

  if (values.financials && values.financials.length > 0) {
    //loop through financial line items;
    errors.financials = [];
    for (var i = 0; i < values.financials.length; i++) {
      errors.financials[i] = {};
      if (!values.financials[i].unit_name) {
        errors.financials[i].unit_name = 'Product description required';
      }
      if (!values.financials[i].unit_amount) {
        errors.financials[i].unit_amount = 'Rate required';
      }
      if (values.financials[i].unit_amount) {
        if (!validateDollarValue(values.financials[i].unit_amount)) {
          errors.financials[i].unit_amount = 'Enter a valid number';
        }
      }
      if (!values.financials[i].unit_quantity) {
        errors.financials[i].unit_quantity = 'Quantity required';
      }
      if (values.financials[i].unit_quantity && !validateIntegerValue(values.financials[i].unit_quantity)) {
        errors.financials[i].unit_quantity = 'Quantity must be a number';
      }
    }
  }

  if (values.markup) {
    if (!validateDollarValue(values.markup)) {
      errors.markup = 'Markup must be a number';
    }
  }

  //if a driver's number has been entered, we need a carrier to be able to assign it
  if (values.driver_cell) {
    if (values.carrier === 'select' || !values.carrier) {
      errors.carrier = 'Carrier required for driver assignment';
    }
    if (!validatePhoneNumber(values.driver_cell)) {
      errors.driver_cell = 'Invalid number';
    }
  }
  if (!values.book_as_customer || values.book_as_customer === null) {
    errors.book_as_customer = 'Select a customer account';
  }
  //if there are no line items and we're in FTL, then the total weight and commodity description are required
  if (isFTL && (!values.line_items || values.line_items.length === 0)) {
    if (!validateDollarValue(values.ftl_estimated_weight)) {
      errors.ftl_estimated_weight = 'Enter a valid number';
    }
    if (!values.ftl_description) {
      errors.ftl_description = 'Description is required if there are no line items';
    }
  }
  if (values.equipment_type && reeferTypes.includes(Number(values.equipment_type))) {
    if (isNil(values.temperature_lower_limit)) {
      errors.temperature_lower_limit = 'Enter a temperature';
    }
    if (isNil(values.temperature_upper_limit)) {
      errors.temperature_upper_limit = 'Enter a temperature';
    }
  }
  if (values.bill_to_override) {
    errors.bill_to_override = {};
    if (
      !values.bill_to_override.company_address ||
      (values.bill_to_override.company_address && Object.keys(values.bill_to_override.company_address).length === 0)
    ) {
      errors.bill_to_override.company_address = 'Address is required';
    }
    if (!values.bill_to_override.company_name) {
      errors.bill_to_override.company_name = 'Company name is required';
    }
    if (!values.bill_to_override.contact_email) {
      errors.bill_to_override.contact_email = 'Email is required';
    }
    if (values.bill_to_override.contact_email && !validateEmail(values.bill_to_override.contact_email)) {
      errors.bill_to_override.contact_email = 'Invalid email';
    }
    if (!values.bill_to_override.contact_phone) {
      errors.bill_to_override.contact_phone = 'Phone is required';
    }
    if (values.bill_to_override.contact_phone && !validatePhoneNumber(values.bill_to_override.contact_phone)) {
      errors.bill_to_override.contact_phone = 'Invalid phone number';
    }
    if (!values.bill_to_override.direction || values.bill_to_override.direction === 'select') {
      errors.bill_to_override.direction = 'Select an option';
    }
  }
  //Equipment Config
  let number;
  if (values.equipment_config && values.equipment_config.driver && values.equipment_config.driver.phone_number) {
    try {
      number = phoneUtil.parse(values.equipment_config.driver.phone_number);
    } catch (err) {
      errors.equipment_config.driver.phone_number = "Driver's phone number is not valid";
    }
  }
  if (values.equipment_config && values.equipment_config.driver && number && !phoneUtil.isValidNumber(number)) {
    errors.equipment_config.driver.phone_number = "Driver's phone number is not valid";
  }
  return errors;
};

export default validate;
