import {useState, useEffect, useCallback, useRef} from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import moment from 'moment';
import {Textarea, DeprecatedButton, Title} from '@shipwell/shipwell-ui';
import {getShipmentMessagesPromise, postShipmentMessagePromise} from 'App/api/shipment';
import {createSpotNegotiationFromLoadboard} from 'App/actions/marketplace';
import {getSpotNegotiationMessagesPromise, sendSpotNegotiationMessagePromise} from 'App/api/quoting';
import {formatDateTime} from 'App/utils/globals';
import useInterval from 'App/utils/hooks/useInterval';
import WithStatusToasts from 'App/components/withStatusToasts';
import './styles.scss';
import useToggle from 'App/utils/hooks/useToggle';
import {getUserAvatarUrl, getMessageUserAvatarURL} from 'App/utils/userAvatar';

const resourceTypes = {SPOT_NEGOTIATION: 'SPOT_NEGOTIATION', SHIPMENT: 'SHIPMENT'};

const Messages = ({
  shipmentResource,
  resourceType,
  onSubmit,
  setError,
  title,
  shouldPoll,
  user,
  dispatch,
  className,
  fixedWidthContainer
}) => {
  //shipmentResource may refer to a SHIPMENT or a SPOT_NEGOTIATION
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [gettingMessages, toggleGettingMessages] = useToggle();
  const [submitting, setSubmitting] = useState(false);
  const [pagination, setPagination] = useState({pageSize: 100, page: 1});
  const messageContainer = useRef();

  const createMessageFunction =
    resourceType === resourceTypes.SHIPMENT ? postShipmentMessagePromise : sendSpotNegotiationMessagePromise;

  useEffect(() => {
    getMessages(pagination);
  }, [getMessages, pagination, shipmentResource]);

  useInterval(
    () => {
      getMessages(pagination);
    },
    shouldPoll ? 30000 : null
  );

  const getMessages = useCallback(
    async (opts) => {
      const getMessagesFunction =
        resourceType === resourceTypes.SHIPMENT
          ? getShipmentMessagesPromise
          : shipmentResource?.spot_negotiation
          ? getSpotNegotiationMessagesPromise
          : null;
      try {
        if (getMessagesFunction) {
          toggleGettingMessages();
          const response = await getMessagesFunction(
            resourceType === resourceTypes.SHIPMENT ? shipmentResource?.id : shipmentResource.spot_negotiation,
            opts
          );
          setMessages(
            response.body.results.sort((a, b) => {
              return Date.parse(a.created_at) > Date.parse(b.created_at) ? 1 : -1;
            })
          );
          toggleGettingMessages();
        }
      } catch (error) {
        console.error(error);
        setError('Error!', error.error_description);
      }
    },
    [shipmentResource, resourceType, setError]
  );

  useEffect(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async () => {
    if (!input.trim().length || submitting) {
      return;
    }
    setSubmitting(true);
    try {
      let resourceId =
        resourceType === resourceTypes.SHIPMENT ? shipmentResource?.id : shipmentResource?.spot_negotiation;
      //load board shipments may not have a spot negotiation to send messages on at first, so create one if needed
      const needsSpotNegotiation =
        resourceType === resourceTypes.SPOT_NEGOTIATION && !shipmentResource?.spot_negotiation;

      if (needsSpotNegotiation) {
        const response = await createSpotNegotiation({
          involved_vendor_users: [{id: user.id}],
          involved_customer_users: []
        });
        resourceId = response.id;
      }
      await createMessageFunction(resourceId, {
        message: input
      });
      setInput('');
      await getMessages(pagination);
      if (onSubmit) {
        onSubmit({fetchQuotes: false});
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
    setSubmitting(false);
  };

  const createSpotNegotiation = async (body) => {
    try {
      const response = await dispatch(createSpotNegotiationFromLoadboard(shipmentResource.load_board_id, body));
      return response.body;
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const handleKeyPress = (e, submitEdit) => {
    if (e.key === 'Enter') {
      //submit on enter, unless shift+enter
      if (!e.shiftKey) {
        e.preventDefault();
        if (submitEdit) {
          submitEdit();
        } else {
          handleSubmit();
        }
      }
    }
  };

  const Message = ({message, sender, sender_name, sender_avatar, created_at}) => {
    const senderName = resourceType === 'SPOT_NEGOTIATION' ? sender.first_name + ' ' + sender.last_name : sender_name;
    const avatar =
      resourceType === 'SPOT_NEGOTIATION'
        ? getUserAvatarUrl(sender)
        : getMessageUserAvatarURL(sender_name, sender_avatar);
    return (
      <div className="mb-3 flex flex-col whitespace-pre-line">
        <div>
          <span className="mr-2 font-bold">{senderName}</span>
          <span className="text-sw-disabled-text">{formatDateTime(created_at, true, moment.tz.guess())}</span>
        </div>
        <div className="flex items-start">
          <span className="mr-2 py-2">
            <img className="size-5 rounded-full" src={avatar} alt="userAvatar" />
          </span>

          <span
            className={classNames('flex-1 break-words p-3 bg-sw-background rounded', {
              'max-w-xxs': fixedWidthContainer,
              hyphens: !fixedWidthContainer
            })}
          >
            {message}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className={`shipmentMessages messages ${className}`}>
      {title && <h2>{title}</h2>}

      {!gettingMessages && messages && messages.length === 0 ? (
        <div className="flex shrink-0 grow flex-col items-center justify-center text-center text-sw-disabled">
          <div>
            <Title variant="emptyStateHeader">No Messages</Title>
            <div>Add a new message in the field below.</div>
          </div>
        </div>
      ) : (
        <div ref={messageContainer} className="m-2 h-80  shrink-0 grow overflow-auto">
          {messages.map((message) => {
            return <Message key={message.id} {...message} />;
          })}
        </div>
      )}
      <div className="messages__inputContainer relative flex justify-between p-4 sw-border-t">
        <Textarea
          className="messages__input mr-2"
          minRows={0}
          maxRows={3}
          value={input}
          name="newMessage"
          label="Enter message here"
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        <DeprecatedButton variant="primary" onClick={handleSubmit} disabled={submitting || !input.trim().length}>
          Send
        </DeprecatedButton>
        {input.length > 0 && (
          <span className="messages__inputContainer-helperText absolute -bottom-4 text-xxs uppercase text-sw-disabled-text">
            Shift + Return to add a new line
          </span>
        )}
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    user: get(state, 'userProfile.user')
  })),
  WithStatusToasts
)(Messages);
