import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import isEmpty from 'lodash/isEmpty';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, Crosshair} from 'react-vis';
import {
  getChartYDomain,
  formatPriceAxis,
  formatTimeAxisMonthDayYear,
  formatLineSeriesCrosshairValues,
  formatExternalPriceData,
  truncatePriceDataByRange,
  getTwoWeekXAxisTicks
} from 'App/components/pricingIntelChart/utils';
import EmbeddedPricingTile from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingTile';
import LineMarkAreaSeries from 'App/components/pricingIntelChart/components/lineMarkAreaSeries';
import PricingIntelSeriesCrosshair from 'App/components/pricingIntelChart/components/pricingIntelSeriesCrosshair';
import 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingHistoricalMarketTrend/styles.scss';
import {
  embeddedPricingVariantsBySource,
  MARKET_CARRIER,
  dataSourceOptionItems,
  crosshairStyles,
  EMBEDDED_HISTORICAL_MARKET_RANGE_DAYS,
  PRICING_INTEL_DATA_STATE_LOADING,
  PRICING_INTEL_GRAPH_STATE_EMPTY,
  PRICING_INTEL_DATA_STATE_VALID,
  PRICING_INTEL_DATA_STATE_EMPTY
} from 'App/components/pricingIntelChart/pricingIntelConstants';

const EmbeddedHistoricalMarketTrend = ({shipwellMarketCompositePrices = {}, historicalPricingDataState}) => {
  const [chartYDomain, setChartYDomain] = useState([]);
  const [seriesCrosshairValues, setSeriesCrosshairValues] = useState([]);
  const [xAxisTickValues, setXAxisTickValues] = useState([]);
  const [formattedRangedShipwellMarketCompositePrices, setFormattedRangedShipwellMarketCompositePrices] = useState({});
  const [rangedShipwellMarketCompositePrices, setRangedShipwellMarketCompositePrices] = useState({});
  const [graphState, setGraphState] = useState(PRICING_INTEL_GRAPH_STATE_EMPTY);
  const handleSeriesHover = (value, {index}) => {
    const crosshairValues = formatLineSeriesCrosshairValues(index, formattedRangedShipwellMarketCompositePrices, [
      MARKET_CARRIER
    ]);
    if (crosshairValues) {
      return setSeriesCrosshairValues(crosshairValues);
    }
  };

  // Trims the raw prices depending on selected range.
  useEffect(() => {
    setGraphState(PRICING_INTEL_DATA_STATE_LOADING);
    if (!isEmpty(shipwellMarketCompositePrices)) {
      const rangedShipwellMarketCompositePrices = truncatePriceDataByRange(
        shipwellMarketCompositePrices,
        EMBEDDED_HISTORICAL_MARKET_RANGE_DAYS,
        'days'
      );
      setRangedShipwellMarketCompositePrices(rangedShipwellMarketCompositePrices);
    } else {
      setGraphState(PRICING_INTEL_DATA_STATE_EMPTY);
    }
  }, [shipwellMarketCompositePrices]);

  // Formats the data for the chart: replace date strings with objects for react-vis graph,
  // set the chart X and Y domain with the preformatted pricing data
  useEffect(() => {
    const formatPriceData = () => {
      const newChartData = {};
      newChartData[MARKET_CARRIER] = formatExternalPriceData(rangedShipwellMarketCompositePrices[MARKET_CARRIER]);
      setFormattedRangedShipwellMarketCompositePrices(newChartData);
      setChartYDomain(getChartYDomain(newChartData));
      setXAxisTickValues(getTwoWeekXAxisTicks(newChartData));
      setGraphState(PRICING_INTEL_DATA_STATE_VALID);
    };
    if (!isEmpty(rangedShipwellMarketCompositePrices)) {
      formatPriceData();
    } else {
      setGraphState(PRICING_INTEL_DATA_STATE_EMPTY);
    }
  }, [rangedShipwellMarketCompositePrices, shipwellMarketCompositePrices]);

  const handleMouseLeave = () => {
    setSeriesCrosshairValues([]);
  };
  const shouldRenderLineMarkAreaSeries =
    !isEmpty(formattedRangedShipwellMarketCompositePrices) && xAxisTickValues.length > 0 && chartYDomain.length > 0;
  return (
    <EmbeddedPricingTile
      title={embeddedPricingVariantsBySource.HISTORICAL_MARKET_TREND.contentTitle}
      tooltipContent={embeddedPricingVariantsBySource.HISTORICAL_MARKET_TREND.tooltipContent}
      loading={historicalPricingDataState === PRICING_INTEL_DATA_STATE_LOADING}
    >
      {graphState === PRICING_INTEL_DATA_STATE_EMPTY ? (
        <div className="embeddedPricing__historicalMarketTrendChart empty">
          <div
            title="embeddedPricingTableRowEmpty"
            className="embeddedPricing__historicalMarketTrendChart-empty-message-container"
          >
            <span className="embeddedPricing__historicalMarketTrendChart-empty-message">
              No Lane Information Available
            </span>
          </div>
        </div>
      ) : (
        <div title="embeddedPricingHistoricalMarketTrendChart" className="embeddedPricing__historicalMarketTrendChart">
          <AutoSizer>
            {({width, height}) => {
              return (
                <XYPlot
                  height={height}
                  width={width}
                  margin={{left: 48, right: 24, bottom: 24}}
                  xType={'time'}
                  yDomain={chartYDomain}
                  onMouseLeave={handleMouseLeave}
                >
                  <YAxis tickFormat={(price) => formatPriceAxis(price)} />
                  <VerticalGridLines />
                  <XAxis tickFormat={(date) => formatTimeAxisMonthDayYear(date)} tickValues={xAxisTickValues} />
                  <HorizontalGridLines />
                  {shouldRenderLineMarkAreaSeries &&
                    LineMarkAreaSeries({
                      data: formattedRangedShipwellMarketCompositePrices[MARKET_CARRIER] || [],
                      color: dataSourceOptionItems[MARKET_CARRIER].color,
                      handleSeriesHover
                    })}
                  <Crosshair values={seriesCrosshairValues} style={crosshairStyles}>
                    <PricingIntelSeriesCrosshair seriesCrosshairValues={seriesCrosshairValues} />
                  </Crosshair>
                </XYPlot>
              );
            }}
          </AutoSizer>
        </div>
      )}
    </EmbeddedPricingTile>
  );
};

EmbeddedHistoricalMarketTrend.propTypes = {
  shipwellMarketCompositePrices: PropTypes.object,
  historicalPricingDataState: PropTypes.string
};

export default EmbeddedHistoricalMarketTrend;
