import startCase from 'lodash/startCase';
import {UserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import {Modal, SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {SaveDashboardForm} from './SaveDashboardForm';
import useToggle from 'App/utils/hooks/useToggle';

interface DashboardConfiguration extends UserDashboardConfiguration {
  default?: boolean;
}

export const DashboardItem = ({
  dashboard,
  onUpdate,
  onDelete,
  onSelect,
  isSelected = false,
  itemName = 'dashboard',
  isLoading = false
}: {
  dashboard: DashboardConfiguration;
  onUpdate?: (id: string, config: Partial<DashboardConfiguration>) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
  isSelected?: boolean;
  itemName?: string;
  isLoading?: boolean;
}) => {
  const [showDelete, toggleDelete] = useToggle();
  const [showRename, toggleRename] = useToggle();

  const handleSetDashboardAsDefault = () => {
    onUpdate?.(dashboard.id || '', {
      is_default: true,
      name: dashboard.name,
      config: dashboard.config,
      table_type: dashboard.table_type
    });
  };

  const handleRename = (isDefault: boolean, name: string) => {
    onUpdate?.(dashboard.id || '', {
      is_default: isDefault,
      name: name,
      config: dashboard.config,
      table_type: dashboard.table_type
    });
    toggleRename();
  };
  return (
    <div
      className={`border-b border-sw-border pr-2 ${isSelected ? 'bg-sw-active-light outline outline-sw-active' : ''}`}
    >
      {showRename ? (
        <SaveDashboardForm
          label={`Update ${startCase(itemName)}`}
          dashboard={dashboard}
          onSave={handleRename}
          onCancel={toggleRename}
        />
      ) : (
        <div className="flex items-center justify-between gap-2">
          <button
            disabled={isLoading}
            className={classNames('w-full p-2 text-left', {'cursor-not-allowed': isLoading})}
            onClick={() => onSelect(dashboard.id || '')}
          >
            {dashboard.name}
          </button>
          <div className="flex items-center gap-2">
            {dashboard.is_default ? <span className="text-xxs text-sw-disabled-text">DEFAULT</span> : null}
            <Dropdown indicator={false} variant="icon" drop="left" icon={<SvgIcon color="$sw-icon" name="Overflow" />}>
              {({onClick}: {onClick: () => void}) => [
                !dashboard.is_default && onUpdate ? (
                  <button
                    key="setDefault"
                    className="w-full text-left"
                    onClick={() => {
                      onClick();
                      handleSetDashboardAsDefault();
                    }}
                  >
                    Set as default
                  </button>
                ) : null,
                onUpdate ? (
                  <button
                    key="rename"
                    className="w-full text-left"
                    onClick={() => {
                      onClick();
                      toggleRename();
                    }}
                  >
                    Rename
                  </button>
                ) : null,
                <button
                  key="delete"
                  className="w-full text-left text-sw-destroy"
                  onClick={() => {
                    onClick();
                    toggleDelete();
                  }}
                >
                  Delete
                </button>
              ]}
            </Dropdown>
          </div>
        </div>
      )}
      {showDelete ? (
        <Modal
          show
          size="small"
          title={`Delete ${startCase(itemName)}`}
          variant="warning"
          primaryBtnName={`Yes, Delete ${startCase(itemName)}`}
          secondaryBtnName="No, Go Back"
          onPrimaryAction={() => {
            onDelete(dashboard.id || '');
            toggleDelete();
          }}
          onClose={() => toggleDelete()}
        >
          <div className="flex flex-col">
            <span className="font-bold">{`Are you sure you want to delete this ${itemName}?`}</span>
            <span>{`If this ${itemName} is set as the default, then a new default ${itemName} will need to be selected.`}</span>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
