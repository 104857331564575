import {Field} from 'redux-form';
import renderField from 'App/formComponents/renderField';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

const renderCustomCustomerFieldValues = ({fields, readOnly, customFields, meta: {touched, error}, ...rest}) => (
  <div>
    {customFields &&
      customFields.length > 0 &&
      customFields.map((el, idx) => (
        <div key={idx}>
          <Element name={`custom_customer_field_values[${idx}].value`} />
          <Field
            label={el.name}
            name={`custom_customer_field_values[${idx}].value`}
            component={renderField}
            disabled={readOnly}
          />
        </div>
      ))}
  </div>
);

export default renderCustomCustomerFieldValues;
