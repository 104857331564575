import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementType,
  isValidElement,
  ReactChild,
  ReactNode,
  useState
} from 'react';
import {compose} from 'recompose';
import {withRouter, WithRouterProps} from 'react-router';

import {Modal, Tooltip} from '@shipwell/shipwell-ui';
import {AlertDashboardSlimCarrierRelationship, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';

import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import buildPathParams from 'App/utils/buildPathParams';

interface ActionLinkWithModalProps extends Partial<WithStatusToastProps>, Partial<WithRouterProps<unknown, unknown>> {
  children: ReactChild;
  modalTitle?: string | null;
  modalContent: ReactChild;
  tooltip?: ReactNode;
  disabled?: boolean;
  triggerReload?: boolean;
  shipment?: Shipment | AlertDashboardSlimCarrierRelationship;
  onShipmentActionsSubmit?: (shipmentId: string) => Promise<void>;
  modalBodyClassName?: string;
  modalSize?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  onClose?: () => void;
}
const ActionLinkWithModal = ({
  children,
  modalTitle,
  modalContent,
  tooltip,
  setSuccess,
  disabled,
  triggerReload,
  router,
  location,
  shipment,
  onShipmentActionsSubmit,
  modalBodyClassName,
  modalSize = 'medium',
  onClick,
  onClose
}: ActionLinkWithModalProps) => {
  const [showModal, setShowModal] = useState(false);

  const childrenWithProps = Children.map(children, (child: ReactChild) =>
    isValidElement<ComponentPropsWithoutRef<ElementType>>(child)
      ? cloneElement(child, {
          onClick: disabled
            ? (e: Event) => {
                e.stopPropagation();
              }
            : (e: Event) => {
                e.stopPropagation();
                setShowModal(true);
                if (onClick) {
                  onClick();
                }
              }
        })
      : child
  );
  const modalContentWithProps = Children.map(modalContent, (child: ReactChild) =>
    isValidElement<ComponentPropsWithoutRef<ElementType>>(child)
      ? cloneElement(child, {
          onSuccess: (title: string, message: string) => setSuccess && setSuccess(title, message),
          onCancel: () => setShowModal(false),
          onSubmit: triggerReload
            ? async () => {
                if (onShipmentActionsSubmit) {
                  await onShipmentActionsSubmit(shipment?.id || '');
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  router && router.push(buildPathParams(location, {reload: true}));
                }
              }
            : () => undefined
        })
      : child
  );

  return (
    <div>
      <Modal
        size={modalSize}
        className={modalBodyClassName}
        show={showModal}
        footerComponent={null}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          setShowModal(false);
        }}
        title={modalTitle}
      >
        {modalContentWithProps}
      </Modal>
      {tooltip ? (
        <Tooltip tooltipClassname="whitespace-nowrap" tooltipContent={tooltip} placement="top" portal>
          {childrenWithProps}
        </Tooltip>
      ) : (
        childrenWithProps
      )}
    </div>
  );
};

export default compose<ActionLinkWithModalProps, ActionLinkWithModalProps>(
  withRouter,
  withStatusToasts
)(ActionLinkWithModal);
