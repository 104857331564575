import {Button, SvgIcon} from '@shipwell/shipwell-ui';

interface AirShipmentActionButtonsProps {
  isSubmitting: boolean;
  dirty: boolean;
  onCancel: () => void;
}

const AirShipmentActionButtons = ({isSubmitting, dirty, onCancel}: AirShipmentActionButtonsProps) => {
  return (
    <div className="shipment__edit-form-buttons flex gap-3">
      <Button type="button" variant="secondary" disabled={isSubmitting} onClick={onCancel} name="cancel-edit">
        Cancel
      </Button>
      <Button type="submit" disabled={isSubmitting || !dirty}>
        {isSubmitting ? (
          <>
            <SvgIcon name="Refresh" className="shipment__edit-form-buttons-button-icon rotate" /> Saving...
          </>
        ) : (
          'Save'
        )}
      </Button>
    </div>
  );
};

export default AirShipmentActionButtons;
