import {Button} from 'react-bootstrap';

export const NewFinancialsExpandedFormFooter = ({
  isSubmitting,
  onCancel,
  error
}: {
  isSubmitting?: boolean;
  onCancel: () => void;
  error?: string;
}) => {
  return (
    <div>
      <div className="financialsExpanded__saveButtons">
        <Button
          bsStyle="default"
          type="button"
          disabled={isSubmitting}
          onClick={onCancel}
          className="mr-3"
          name="cancel-edit"
        >
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" bsStyle="primary" name="submit-financials">
          {isSubmitting ? (
            <span>
              <i className="icon icon-Restart rotate" /> Saving...
            </span>
          ) : (
            'Save'
          )}
        </Button>
      </div>

      {error && (
        <p className="error-text-form-level">
          <i className="icon icon-Delayed pad-right" />
          {error}
        </p>
      )}
    </div>
  );
};
