import moment from 'moment';
import pluralize from 'pluralize';
import {timeRemainingFromToday} from 'App/utils/dateTimeGlobalsTyped';

const dayAndDateFormat = (date: string) => moment(date).format('ddd, MMM D YYYY');
const timeFormat = (date: string, timezone = moment.tz.guess()) => moment(date).tz(timezone).format('HH:mm z');

export const BiddingCountdown = ({expiryDateTime}: {expiryDateTime: string}) => {
  const {days, hours} = timeRemainingFromToday(expiryDateTime);
  const lessThanOneHour = days === 0 && hours === 0;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 rounded bg-sw-background-component p-8 md:w-auto ">
      <div className="flex flex-col items-center gap-2">
        <span className="uppercase italic">Bidding Ends</span>
        <div className="w-14 border-b-4 border-sw-divider" />
      </div>
      <span className="whitespace-nowrap text-2xl font-bold">
        {lessThanOneHour ? 'Less than 1 hour' : `${pluralize('Day', days, true)} ${pluralize('Hour', hours, true)}`}
      </span>
      <div className="flex flex-col items-center">
        <span>{dayAndDateFormat(expiryDateTime)}</span>
        <span>{timeFormat(expiryDateTime)}</span>
      </div>
    </div>
  );
};
