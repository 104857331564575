import PropTypes from 'prop-types';
import classnames from 'classnames';
import {DeprecatedButton, SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
// Attach rate table links
export const AttachLinks = ({variant, icon, onClick, isEditMode, className = '', children}) => (
  <DeprecatedButton variant={variant} icon={icon} onClick={onClick} className={className} isEditMode={isEditMode}>
    {children}
  </DeprecatedButton>
);

// Remove rate table links
export const RemoveRateTables = ({children, onClick}) => (
  <Dropdown
    icon={<SvgIcon name="Overflow" className="icon" color="$sw-icon" />}
    className="justify-end pr-1"
    variant="activeIcon"
    indicator={false}
  >
    {() => <li onClick={onClick}>{children}</li>}
  </Dropdown>
);

export const RateTableStyledWrapper = ({children}) => <div className={'flex justify-between pl-4'}>{children}</div>;

export const RateTableContent = ({children, onClick, rateTableLink}) => {
  return (
    <RateTableStyledWrapper>
      <AttachLinks
        icon={<SvgIcon name="Attachment" className="icon rotate-90" color="$sw-icon" />}
        variant="tertiary"
        className="text-xs uppercase"
        onClick={onClick}
      >
        {rateTableLink}
      </AttachLinks>
      {children}
    </RateTableStyledWrapper>
  );
};

export const RateTablePreview = ({className, children}) => (
  <div className={classnames('overflow-y-auto', 'max-h-64', className)}>{children}</div>
);

RateTablePreview.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  className: PropTypes.string
};

RateTableContent.propTypes = {
  onClick: PropTypes.any,
  rateTableLink: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element])
};

RateTableStyledWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element])
};

RemoveRateTables.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element])
};

AttachLinks.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  isEditMode: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element])
};
