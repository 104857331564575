import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Purchase Orders - api
 */
export async function createPurchaseOrdersShipment(options = {}) {
  const shipwellApi = await getShipwellApi();
  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersBuildShipmentPost(options, apiCallback(resolve, reject));
  });
}

export async function updatePurchaseOrdersShipment(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersUpdateBuiltShipmentPut(body, apiCallback(resolve, reject));
  });
}

export async function createPurchaseOrder(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, apiCallback(resolve, reject));
  });
}

export async function updatePurchaseOrderById(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdPut(id, body, apiCallback(resolve, reject));
  });
}

/**
 * Purchase Orders
 * API for managing purchase orders
 * @deprecated prefer import('App/api/purchaseOrders/typed').getPurchaseOrders
 */
export async function getPurchaseOrdersList(params = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersGet(params, apiCallback(resolve, reject));
  });
}

export async function createPurchaseOrders(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, apiCallback(resolve, reject));
  });
}

export async function getPurchaseOrderById(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdGet(id, apiCallback(resolve, reject));
  });
}

/**
 * Purchase Orders Line Items
 * API for managing purchase order line items
 */
export async function getPurchaseOrderLineItemList(id, options = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsGet(id, options, apiCallback(resolve, reject));
  });
}

export async function createPurchaseOrderLineItem(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPost(id, body, apiCallback(resolve, reject));
  });
}

export async function updatePurchaseOrderLineItem(orderId, lineItemId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdPut(
      orderId,
      lineItemId,
      body,
      apiCallback(resolve, reject)
    );
  });
}
export async function deletePurchaseOrderLineItem(orderId, lineItemId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDelete(
      orderId,
      lineItemId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Purchase Orders Workflow Automation
 */
export async function initiatePurchaseOrderOrderConsolidationWorkflow(id, options = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdInitiateOrderConsolidationWorkflowPost(
      id,
      options,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * Purchase Order Reference Data
 */
export async function getPurchaseOrderOverallStatusOptions() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersOverallStatusesGet(apiCallback(resolve, reject));
  });
}
