const roundToThousandths = (float: number) => Math.round(float * 1000) / 1000;

type CalculationRow = {
  fuel_price_lower_bound: number;
  fuel_price_upper_bound?: number;
  fuel_surcharge?: number;
  rate_type?: string;
};

type ValuesType = {
  base_fuel_price?: number | string;
  doe_rate_increment?: number | string;
  doe_max_rate?: number | string;
  user_base_rate?: number | string;
  user_rate_increment?: number | string;
  rate_type?: string;
  calculation_rows?: CalculationRow[];
};

// used to create a fresh, stock table when updating the bounds
export const createDoeTable = (values: ValuesType) => {
  // return original rows if no base info
  if (!values?.base_fuel_price || !values?.doe_rate_increment || !values?.doe_max_rate) {
    return values?.calculation_rows || [];
  }

  const {base_fuel_price, doe_rate_increment, doe_max_rate} = values;
  const baseFuelPrice = Number(base_fuel_price);
  const doeRateIncrement = Number(doe_rate_increment);
  const doeMaxRate = Number(doe_max_rate);
  if (baseFuelPrice < 0 || doeRateIncrement < 0 || doeMaxRate < 0) {
    return values?.calculation_rows || [];
  }
  // create default calcuation rows based on params
  const tableRows: CalculationRow[] = [
    {
      fuel_price_lower_bound: 0,
      fuel_price_upper_bound: roundToThousandths(baseFuelPrice)
    }
  ];

  for (let i = baseFuelPrice; i + 0.001 < doeMaxRate; i += doeRateIncrement) {
    const ceiling = i + doeRateIncrement <= doeMaxRate ? i + doeRateIncrement : doeMaxRate;
    tableRows.push({
      fuel_price_lower_bound: roundToThousandths(i + 0.001),
      fuel_price_upper_bound: roundToThousandths(ceiling)
    });
  }

  tableRows.push({
    fuel_price_lower_bound: roundToThousandths(doeMaxRate + 0.001),
    fuel_price_upper_bound: undefined
  });

  // is the below inputs have not been filled, we can at least return the created table rows with default upper and lower bounds
  if (!values?.user_base_rate || !values?.user_rate_increment || !values?.rate_type) {
    return tableRows;
  }

  const {user_base_rate, user_rate_increment, rate_type} = values;
  const userBaseRate = Number(user_base_rate);
  const UserRateIncrement = Number(user_rate_increment);
  const doeWithRates = tableRows.map((row, i) => {
    return {
      ...row,
      fuel_surcharge: roundToThousandths(userBaseRate + i * UserRateIncrement),
      rate_type: rate_type
    };
  });

  return doeWithRates;
};

// Used to initialize the table when editing the FuelSurchargeEditRateTableForm
export const initializeDoeTable = (values: ValuesType) => {
  const calculationRows = values.calculation_rows || [];
  const defaultTable = createDoeTable(values);
  const updatedTable = defaultTable.map((row, index) => {
    // if the values passed in already have a specific fel surcharge, we should use it over the calculated one
    if (calculationRows[index] && calculationRows[index].fuel_surcharge) {
      return {
        ...row,
        fuel_surcharge: calculationRows[index].fuel_surcharge
      };
    }
    return row;
  });
  return updatedTable;
};
