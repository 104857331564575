import {useMemo, useState} from 'react';
import {withRouter, WithRouterProps} from 'react-router';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import columnData from './columns';
import PageHeader from 'App/common/pageHeader';
import {LoadOptimizationsOptions} from 'App/api/loadOptimization';
import {
  OPTIMIZATION_CONFIG_LIST_INITIAL_DIRECTION,
  OPTIMIZATION_CONFIG_LIST_INITIAL_PAGE_SIZE,
  OPTIMIZATION_CONFIG_LIST_INITIAL_SORT_FIELD
} from 'App/containers/loadOptimization/optimizationListView/constants';
import ListViewLoader from 'App/containers/purchaseOrders/list/components/ListViewLoader';
import Table from 'App/components/Table';
import useLoadOptimizationConfigListQuery from 'App/api/loadOptimization/hooks/useCompanyConfigList';

const LoadOptimizationConfigListPageHeaderActionsBase = ({router}: WithRouterProps) => {
  return (
    <div className="mr-6">
      <DeprecatedButton
        variant="tertiary"
        icon={<SvgIcon name="AddCircleOutlined" />}
        onClick={() => router.push('/company/defaults/load-optimization-configurations/create')}
      >
        Add Configuration Settings
      </DeprecatedButton>
    </div>
  );
};
const LoadOptimizationConfigListPageHeaderActions = withRouter(LoadOptimizationConfigListPageHeaderActionsBase);

const CompanyLoadOptimizationConfigList = () => {
  const columns = useMemo(() => columnData, []);
  const [options, setOptions] = useState<LoadOptimizationsOptions>({
    page: 1,
    size: OPTIMIZATION_CONFIG_LIST_INITIAL_PAGE_SIZE,
    sort: OPTIMIZATION_CONFIG_LIST_INITIAL_SORT_FIELD,
    direction: OPTIMIZATION_CONFIG_LIST_INITIAL_DIRECTION
  });
  const onFetchData = ({
    pageSize,
    pageIndex
  }: {
    pageSize: LoadOptimizationsOptions['page'];
    pageIndex: LoadOptimizationsOptions['size'];
  }) => {
    setOptions({...options, size: pageSize, page: pageIndex ? pageIndex + 1 : 1});
  };
  const OptimizationConfigListQuery = useLoadOptimizationConfigListQuery(options, {
    refetchOnWindowFocus: false,
    keepPreviousData: true
  });
  const {data, isLoading} = OptimizationConfigListQuery;
  return (
    <div className="h-screen-minus-64 flex w-full flex-col">
      <div className="bg-sw-background">
        <PageHeader
          title="Load Optimization Configurations"
          actions={<LoadOptimizationConfigListPageHeaderActions />}
        />
        {isLoading ? <ListViewLoader /> : null}
      </div>
      <Table
        columns={columns}
        data={data?.data}
        showPaginationFooter
        pageCount={data?.total_pages}
        onFetchData={onFetchData}
        initialPageSize={OPTIMIZATION_CONFIG_LIST_INITIAL_PAGE_SIZE}
      />
    </div>
  );
};

export default CompanyLoadOptimizationConfigList;
