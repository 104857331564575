import {REQ_PRODUCTS_DATA, GET_PRODUCTS, CREATE_PRODUCT, SELECT_PRODUCT} from 'App/actions/types';

const INITIAL_STATE = {
  products: [],
  selectedProduct: null,
  isLoading: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQ_PRODUCTS_DATA:
      return {
        ...state,
        isLoading: true
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        isLoading: false
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        isLoading: false
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      };
    default:
      return state;
  }
}
