import {Formik, Field} from 'formik';
import {SvgIcon, Dropdown, FormikRadioGroup} from '@shipwell/shipwell-ui';
import noop from 'lodash/noop';
import {timelineFilterTypes} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';
import {ChangeEvent} from 'react';

const TimelineFilter = ({
  handleFilterSelection,
  values
}: {
  handleFilterSelection: (filter: string, value: string) => void;
  values: object;
}) => {
  const FilterDropdownIcon = () => <SvgIcon name="Filter" />;
  return (
    <div className="mr-2 flex size-10 items-center justify-center rounded-sm border-1 border-sw-disabled p-1">
      <Formik initialValues={{...values}} onSubmit={noop}>
        {() => (
          <Dropdown
            portal
            variant="icon"
            drop="right"
            indicator={false}
            icon={<FilterDropdownIcon />}
            className="flex size-10 items-center justify-center rounded-sm border-1 border-sw-disabled p-4"
          >
            {({onClick}: {onClick: () => void}) => [
              <div key="timeline-filter" className="hover:bg-sw-background-component">
                <div>
                  <Field
                    key="timelineDisplayType"
                    name="timelineDisplayType"
                    options={[
                      {
                        label: 'Rollup Event Updates',
                        value: timelineFilterTypes.ROLLUP_EVENTS
                      },
                      {
                        label: 'Individual Event Updates',
                        value: timelineFilterTypes.INDIVIDUAL_EVENTS
                      }
                    ]}
                    component={FormikRadioGroup}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleFilterSelection('timelineDisplayType', e.target.value);
                      onClick();
                    }}
                  />
                </div>
                <hr className="mt-1 border-t border-solid border-sw-divider" />
                <div>
                  <Field
                    key="groupType"
                    name="groupType"
                    options={[
                      {
                        label: 'All Event Updates',
                        value: timelineFilterTypes.ALL_EVENT_UPDATES
                      },
                      {
                        label: 'Only Tracking Updates',
                        value: timelineFilterTypes.TRACKING_EVENT_UPDATES
                      }
                    ]}
                    component={FormikRadioGroup}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleFilterSelection('groupType', e.target.value);
                      onClick();
                    }}
                  />
                </div>
                <hr className="mt-1 border-t border-solid border-sw-divider" />
                <Field
                  key="filterFutureStops"
                  name="filterFutureStops"
                  options={[
                    {
                      label: 'Hide future stop events',
                      value: timelineFilterTypes.HIDE_FUTURE_STOPS
                    },
                    {
                      label: 'Show future stop events',
                      value: timelineFilterTypes.SHOW_FUTURE_STOPS
                    }
                  ]}
                  component={FormikRadioGroup}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleFilterSelection('filterFutureStops', e.target.value);
                    onClick();
                  }}
                />
              </div>
            ]}
          </Dropdown>
        )}
      </Formik>
    </div>
  );
};

export default TimelineFilter;
