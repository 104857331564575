import {useEffect} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {Facility} from '@shipwell/tempus-sdk';
import {useFacilitiesQuery} from 'App/data-hooks';

export default function FacilitySelector({
  facility,
  setFacility,
  pointOfContactUserId = undefined
}: {
  facility: Facility | null;
  setFacility: (facility: Facility) => void;
  pointOfContactUserId?: string | undefined;
}) {
  const {data: facilities, isFetching} = useFacilitiesQuery(undefined, pointOfContactUserId);

  useEffect(() => {
    if (!facility && facilities?.length) {
      setFacility(facilities[0]);
    }
  }, [facilities, facility, setFacility]);

  return (
    <Select
      isLoading={isFetching}
      label="Facility"
      clearable={false}
      value={facility || facilities?.[0]}
      options={facilities}
      getOptionValue={(option: Facility) => option.id}
      getOptionLabel={(option: Facility) => option.name}
      onChange={setFacility}
      className="w-[217px]"
    />
  );
}
