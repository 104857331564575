import {useState} from 'react';
import {Modal} from '@shipwell/shipwell-ui';
import CreateImport from 'App/components/imports/create';

export const ImportBidModal = ({
  rfpCarrierBidRequestId,
  onClose,
  onSuccess
}: {
  rfpCarrierBidRequestId: string;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <Modal fullScreen={showFullScreen} title="Import RFP Bids" show onClose={onClose} footerComponent={null}>
      <CreateImport
        setUseFullScreen={(useFullScreen: boolean) => {
          setShowFullScreen(useFullScreen);
        }}
        onCancel={onClose}
        type="RFP_LANE_OPTIMIZED_CARRIER"
        opts={{rfpCarrierBidRequestId}}
        onImportSuccess={onSuccess}
        skipOnSuccessModalToggle
      />
    </Modal>
  );
};
