import {useEffect, useMemo, useState} from 'react';
import {DeprecatedButton, Card} from '@shipwell/shipwell-ui';

import DatForm from 'App/formComponents/forms/dat';
import DatSettingsForm from 'App/formComponents/forms/datSettings';
import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import {DAT} from 'App/containers/integrations/details/components/datDetails/utils';
import DisconnectIntegrationModal from 'App/containers/integrations/details/components/datDetails/components/DisconnectIntegrationModal';
import CompanyUsersTable from 'App/containers/integrations/details/components/datDetails/components/CompanyUsersTable';
import useToggle from 'App/utils/hooks/useToggle';

const DatDetails = () => {
  const [showDisconnectModal, toggleShowDisconnectModal] = useToggle(false);
  const [isActive, setIsActive] = useState(false);
  const {data: loadboards} = useGetActiveLoadboards();
  const dat = loadboards?.find((item) => item.loadboard_type === DAT);

  useEffect(() => {
    setIsActive(Boolean(dat?.is_active));
  }, [dat]);

  const formValues = useMemo(
    () => ({
      key: dat?.org_key || '',
      secret: ''
    }),
    [dat]
  );

  return (
    <div className="integration-details-content">
      {isActive ? (
        <div className="disconnect">
          <DeprecatedButton variant="secondary" onClick={toggleShowDisconnectModal}>
            <span className="integration-container__rmis-disconnect-btn-text px-7">Disconnect</span>
          </DeprecatedButton>
        </div>
      ) : null}
      <div className="flex flex-col gap-4">
        <Card
          title={
            <div className="integration-details-card-title">
              <div className="flex items-center gap-1">
                <img
                  alt="integration-logo"
                  className="integration-logo"
                  src="/images/dat-freight-and-analytics-logo.svg"
                />
                <span>DAT</span>
              </div>
            </div>
          }
          draggableProvided={undefined}
        >
          <p>
            To enable the DAT integration, you will need to input the Service Account (organization) username or email
            and password. Each user must have their own DAT account in order to activate the integration. Each user
            account will be validated using the email address stored for the user in the Shipwell platform. If you do
            not have an account or would like to get setup, please go here or reach out to{' '}
            <a href="mailto:techsupportteamleads@dat.com">techsupportteamleads@dat.com</a>
          </p>
          <DatForm values={formValues} isActive={isActive} setIsActive={setIsActive} />
        </Card>
        <div className="flex flex-col gap-4">
          <Card title="Settings" draggableProvided={undefined} bodyClassName="py-0">
            <DatSettingsForm />
          </Card>
        </div>
        <CompanyUsersTable datUsers={dat?.users || []} isConnected={isActive} />
        <DisconnectIntegrationModal
          show={showDisconnectModal}
          toggleShow={toggleShowDisconnectModal}
          setIsActive={setIsActive}
        />
      </div>
    </div>
  );
};

export default DatDetails;
