import {branch, renderNothing, renderComponent} from 'recompose';

/**
 * A higher-order component to render a fallback component if the given condition function returns
 * true.
 *
 * // LoadingSpinner is a React component
 * const ComponentWithLoading = withConditionalFallback(({loading}) => !!loading, LoadingSpinner)(Component);
 *
 * @param {Function.<Object.<String, String>>} conditionFn A function that is passed all props. If this returns true, the given Fallback will be rendered.
 * @param {ReactElement} Fallback The fallback to render if the given conditionFn returns true
 *
 * @return {Function} A higher order component.
 */
export default (conditionFn, Fallback) => branch(conditionFn, Fallback ? renderComponent(Fallback) : renderNothing);
