import {UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {FMCSACarrierPolicy, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FMCSA_COMPLIANCE_POLICY} from '../queryKeys';
import {getFMCSACarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseFMCSAPolicyQueryOptions = Omit<
  UseQueryOptions<FMCSACarrierPolicy, AxiosError<ShipwellError>, FMCSACarrierPolicy, string[]>,
  'queryKey' | 'queryFn'
>;

const useFMCSAPolicyQuery = (options?: UseFMCSAPolicyQueryOptions) => {
  const queryClient = useQueryClient();
  const query = useQuery([FMCSA_COMPLIANCE_POLICY], getFMCSACarrierCompliancePolicy, options);

  return {
    isFMCSAInitialLoading: query.isInitialLoading,
    isFMCSALoading: query.isLoading,
    isFMCSAFetching: query.isFetching,
    isFMCSAFetched: query.isFetched,
    isFMCSASuccess: query.isSuccess,
    isFMCSAError: query.isError,
    fmcsaError: query.error || null,
    fmcsaPolicy: query.data || null,
    refetchFMCSAPolicy: query.refetch,
    invalidateFMCSAPolicyQuery: () => queryClient.invalidateQueries([FMCSA_COMPLIANCE_POLICY])
  };
};

export default useFMCSAPolicyQuery;
