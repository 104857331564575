import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {LoadType, CreateLoadType, UpdateLoadType, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {FACILITY_LOAD_TYPES} from 'App/data-hooks/queryKeys';
import {
  getFacilityLoadTypes,
  updateFacilityLoadType,
  createFacilityLoadType,
  deleteFacilityLoadType
} from 'App/api/facilities';
import {LoadTypeDraft} from 'App/data-hooks/facilities/types';

export const useGetLoadTypes = (
  facilityId?: string,
  queryOptions?: UseQueryOptions<LoadType[], AxiosError<ShipwellApiErrorResponse>>
) => {
  const getFacilityLoadsQuery = useQuery(
    [FACILITY_LOAD_TYPES, facilityId] as QueryKey,
    async () => {
      if (!facilityId) {
        return [];
      }
      const response = await getFacilityLoadTypes(facilityId);
      return response;
    },
    {enabled: !!facilityId, ...queryOptions}
  );
  return getFacilityLoadsQuery;
};

export const formatLoadTypesPayload = ({
  fetchedLoadTypes,
  submittedLoadTypes
}: {
  fetchedLoadTypes?: LoadType[];
  submittedLoadTypes?: (LoadType | LoadTypeDraft)[];
}) => {
  /**
   * Find the LT's that need to be updated
   */
  const toBeUpdatedLoadTypes = submittedLoadTypes?.filter((submittedLoadType) => {
    if ('id' in submittedLoadType) {
      // find the corresponding LT from the fetched list
      const matchingFetchedLoadType = fetchedLoadTypes?.find(
        (fetchedLoadType) => fetchedLoadType.id === submittedLoadType.id
      );
      // found a match, check if it was updated
      if (matchingFetchedLoadType) {
        return JSON.stringify(matchingFetchedLoadType) !== JSON.stringify(submittedLoadType);
      }
    }
    // id not present in submittedLoadType, skip this entry
    return false;
  }) as LoadType[];
  /**
   * Find the LT's that need to be created
   */
  const toBeCreatedLoadTypes = submittedLoadTypes?.filter((submittedLoadType) => !('id' in submittedLoadType));
  /**
   * Find the LT's that need to be deleted
   */
  const submittedLoadTypesWithIds = submittedLoadTypes?.filter(
    (submittedLoadType) => 'id' in submittedLoadType
  ) as LoadType[];
  const submittedLoadTypeIds = submittedLoadTypesWithIds.map((submittedLoadType) => submittedLoadType.id);
  const toBeDeletedLoadTypes = fetchedLoadTypes?.filter(
    (fetchedLoadType) => !submittedLoadTypeIds.includes(fetchedLoadType.id)
  );

  return {toBeUpdatedLoadTypes, toBeCreatedLoadTypes, toBeDeletedLoadTypes};
};

export const useUpdateLoadTypes = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {loadTypeId: string; facilityId: string; updateLoadType: UpdateLoadType}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof updateFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {loadTypeId: string; facilityId: string; updateLoadType: UpdateLoadType}
  >(
    async ({loadTypeId, facilityId, updateLoadType}) =>
      await updateFacilityLoadType(loadTypeId, facilityId, updateLoadType),
    {
      onSettled: () => queryClient.invalidateQueries([FACILITY_LOAD_TYPES]),
      ...mutationOptions
    }
  );
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useCreateLoadTypes = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateLoadType}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof createFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateLoadType}
  >(async ({facilityId, body}) => await createFacilityLoadType(facilityId, body), {
    onSettled: () => queryClient.invalidateQueries([FACILITY_LOAD_TYPES]),
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};

export const useDeleteLoadTypes = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {loadTypeId: string; facilityId: string}
  >
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Awaited<ReturnType<typeof deleteFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {loadTypeId: string; facilityId: string}
  >(async ({loadTypeId, facilityId}) => await deleteFacilityLoadType(loadTypeId, facilityId), {
    onSettled: () => queryClient.invalidateQueries([FACILITY_LOAD_TYPES]),
    ...mutationOptions
  });
  const {mutateAsync} = mutation;
  return {mutateAsync};
};
