import {ShipmentLineItem} from '@shipwell/backend-core-sdk';
import {
  ChargeCategory,
  ContractChargeItem,
  ContractCriteriaByValueRequestTotalWeightUnitEnum,
  TotalWeightOverride
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {convertWeight, isLineHaulChargeCategory} from 'App/utils/globalsTyped';

export const getChargeItemsTotals = (charges?: ContractChargeItem[]) => {
  const lineHaulCharge = charges?.find(({category}) => isLineHaulChargeCategory(category));
  const fuelCharge = charges?.find((charge) => charge.category === ChargeCategory.Fsc);
  const additionalStopCharges = charges?.filter(
    (charge) => charge.category === ChargeCategory.Other || charge.charge_code === 'SOC'
  );

  const accessorials = charges
    ?.filter(
      (charge) =>
        charge.category !== ChargeCategory.Fsc &&
        charge.category !== ChargeCategory.Other &&
        charge.charge_code !== 'SOC' &&
        !isLineHaulChargeCategory(charge.category)
    )
    .map((charge) => ({
      unit_amount: charge.amount,
      name: charge.unit_name,
      unit_quantity: charge.unit_quantity,
      total: calculateChargeItemTotal(charge)
    }));

  const currency = charges?.[0]?.unit_amount_currency;

  const totals = {
    lineHaulTotal: calculateChargeItemTotal(lineHaulCharge),
    fuelChargeTotal: calculateChargeItemTotal(fuelCharge),
    additionalStopChargeTotal: calculateTotalForCharges(additionalStopCharges),
    accessorialsTotal: calculateTotalForCharges(accessorials)
  };

  const total =
    totals.lineHaulTotal + totals.fuelChargeTotal + totals.additionalStopChargeTotal + totals.accessorialsTotal;

  return {
    lineHaulCharge,
    fuelCharge,
    additionalStopCharges,
    currency,
    total,
    accessorials,
    ...totals
  };
};

export const calculateChargeItemTotal = (charge?: ContractChargeItem) =>
  (charge?.unit_amount || 0) * (charge?.unit_quantity || 0);

export const calculateTotalForCharges = (charges?: ContractChargeItem[]) =>
  (charges || []).reduce((total, charge) => {
    return total + calculateChargeItemTotal(charge);
  }, 0);

export const getTotalPackagesAndWeight = ({
  contractWeightUnit,
  totalWeight,
  totalPackages,
  lineItems,
  totalWeightOverride
}: {
  contractWeightUnit?: ContractCriteriaByValueRequestTotalWeightUnitEnum;
  totalWeight?: number | null;
  totalPackages?: number | null;
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
}) => {
  // weight system to determine if weight conversion is required
  const weightSystem =
    contractWeightUnit === ContractCriteriaByValueRequestTotalWeightUnitEnum.Kg ? 'METRIC' : 'IMPERIAL';
  // either total packages were passed in or we need to calculate from lineItems
  const shipmentTotalPackages =
    totalPackages || lineItems?.reduce((totalPackages, lineItem) => totalPackages + Number(lineItem.total_packages), 0);
  // either passed in totalWeight
  // OR the overridden total weight
  // OR calculated from lineItems. Must convert values to match contract weight unit
  const shipmentTotalWeightSum =
    totalWeight ||
    (totalWeightOverride?.value
      ? convertWeight(Number(totalWeightOverride.value), totalWeightOverride.unit, weightSystem)
      : lineItems?.reduce(
          (totalWeight, lineItem) =>
            totalWeight +
            convertWeight(Number(lineItem.package_weight), lineItem.weight_unit, weightSystem) *
              Number(lineItem.total_packages),
          0
        ));

  return {shipmentTotalPackages, shipmentTotalWeightSum};
};
