import {
  TendersApi,
  Configuration,
  TendersApiTendersTenderIdRevokePostRequest,
  TendersApiTendersPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const configuration = new Configuration({
  basePath,
  apiKey: getAccessToken
});

const tendersApi = new TendersApi(configuration);

export const revokeTender = (opts: TendersApiTendersTenderIdRevokePostRequest) =>
  tendersApi.tendersTenderIdRevokePost(opts);

export const createTender = (body: TendersApiTendersPostRequest) => {
  return tendersApi.tendersPost(body);
};
