import React, {ReactNode} from 'react';

interface CustomDocumentViewLayoutProps {
  children: ReactNode;
}

const CustomDocumentViewLayout: React.FC<CustomDocumentViewLayoutProps> = ({children}) => {
  return <div className="documentView flex h-[calc(100%-60px)]">{children}</div>;
};

export default CustomDocumentViewLayout;
