import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {
  CLEAR_NEWSHIPMENT_QUOTING_FORM,
  CLEAR_NEWSHIPMENT_FORM,
  CLEAR_NEWLOAD_FORM,
  CLEAR_TRAILER_FORM,
  CLEAR_POWERUNIT_FORM,
  CLEAR_EDIT_SHIPMENT_FORM,
  CLEAR_CONFIRM_SHIPMENT_FORM,
  CLEAR_ADDRESS_LOOKUP_FORM,
  CLEAR_TIMELINE_ADD_FORM,
  CLEAR_SEND_DOCUMENTS_FORM,
  CLEAR_PASSWORD_FORM
} from '../actions/types';
import authReducer from './reducer_auth';
import addressBookReducer from './reducerAddressbook';
import carriersReducer from './reducer_carriers';
import documentsReducer from './reducer_documents';
import inboxReducer from './reducerInbox';
import lanesReducer from './reducer_lanes';
import shipmentdetailsReducer from './reducerShipmentdetails';
import policyReducer from './reducer_policy';
import usersReducer from './reducer_users';
import billingReducer from './reducer_billing';
import brokersReducer from './reducer_brokers';
import vendorsReducer from './reducer_vendors';
import marketplaceReducer from './reducer_marketplace';
import productsReducer from './reducer_products';
import integrationsReducer from './reducer_integrations';
import userPreferencesReducer from './userPreferences';
import tendersReducer from './reducer_tenders.js';
import shipmentsReducer from 'App/reducers/reducer_shipments';
import purchaseOrdersReducer from 'App/containers/purchaseOrders/reducers';
import userProfileReducer from 'App/containers/userProfile/reducer';
import userCompanyReducer from 'App/containers/userCompany/reducer';
import CarrierTrailersReducer from 'App/containers/equipment/trailers/reducers';
import CarrierPowerUnitsReducer from 'App/containers/equipment/powerUnits/reducers';
import companyReducer from 'App/containers/userCompany/reducers/index';

const rootReducer = combineReducers({
  auth: authReducer,
  addresses: addressBookReducer,
  billing: billingReducer,
  brokers: brokersReducer,
  carriers: carriersReducer,
  documents: documentsReducer,
  inbox: inboxReducer,
  integrations: integrationsReducer,
  lanes: lanesReducer,
  marketplace: marketplaceReducer,
  policy: policyReducer,
  products: productsReducer,
  shipments: shipmentsReducer,
  shipmentdetails: shipmentdetailsReducer,
  users: usersReducer,
  vendors: vendorsReducer,
  userPreferences: userPreferencesReducer,
  userProfile: userProfileReducer,
  userCompany: userCompanyReducer,
  purchaseOrders: purchaseOrdersReducer,
  carrierEquipment: combineReducers({
    trailers: CarrierTrailersReducer,
    powerUnits: CarrierPowerUnitsReducer
  }),
  tenders: tendersReducer,
  company: companyReducer,
  form: formReducer.plugin({
    onDemandRebook: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    onDemandMultiple: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    newQuoteForm: (state, action) => {
      switch (action.type) {
        case CLEAR_NEWSHIPMENT_QUOTING_FORM:
          return undefined;
        default:
          return state;
      }
    },
    newShipmentForm: (state, action) => {
      switch (action.type) {
        case CLEAR_NEWSHIPMENT_FORM:
          return undefined;
        default:
          return state;
      }
    },
    newLoadForm: (state, action) => {
      switch (action.type) {
        case CLEAR_NEWLOAD_FORM:
          return undefined;
        default:
          return state;
      }
    },
    trailerForm: (state, action) => {
      switch (action.type) {
        case CLEAR_TRAILER_FORM:
          return undefined;
        default:
          return state;
      }
    },
    powerUnitForm: (state, action) => {
      switch (action.type) {
        case CLEAR_POWERUNIT_FORM:
          return undefined;
        default:
          return state;
      }
    },
    editShipmentForm: (state, action) => {
      switch (action.type) {
        case CLEAR_EDIT_SHIPMENT_FORM:
          return undefined;
        default:
          return state;
      }
    },
    confirmShipmentForm: (state, action) => {
      switch (action.type) {
        case CLEAR_CONFIRM_SHIPMENT_FORM:
          return undefined;
        default:
          return state;
      }
    },
    addressLookupForm: (state, action) => {
      switch (action.type) {
        case CLEAR_ADDRESS_LOOKUP_FORM:
          return undefined;
        default:
          return state;
      }
    },
    shipmentTimelineForm: (state, action) => {
      switch (action.type) {
        case CLEAR_TIMELINE_ADD_FORM:
          return undefined;
        default:
          return state;
      }
    },
    SendShipmentDocumentsForm: (state, action) => {
      switch (action.type) {
        case CLEAR_SEND_DOCUMENTS_FORM:
          return undefined;
        default:
          return state;
      }
    },
    passwordForm: (state, action) => {
      switch (action.type) {
        case CLEAR_PASSWORD_FORM:
          return undefined;
        default:
          return state;
      }
    }
  })
});

export default rootReducer;
