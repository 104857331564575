import {playgroundData} from './data';

export type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  status: string;
  progress: number;
};

export const fetchData = async ({
  q,
  firstName,
  lastName,
  status,
  age,
  sortBy,
  pageSize = 20,
  pageIndex = 0
}: {
  q?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  age?: string[];
  sortBy?: string;
  pageSize?: number;
  pageIndex?: number;
}) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  // this is an extremely poor example of how to filter, but since this is just to show proof of concept I don't feel like optimizing
  const queriedData = q ? getFilteredData(playgroundData, q) : playgroundData;
  const ageFilter = age?.length ? filterByAge(queriedData, age) : queriedData;
  const firstNameFilter = firstName ? filterDataByCol(ageFilter, 'firstName', firstName) : ageFilter;
  const lastNameFilter = lastName ? filterDataByCol(firstNameFilter, 'lastName', lastName) : firstNameFilter;
  const statusFilter = status ? filterDataByCol(lastNameFilter, 'status', status) : lastNameFilter;
  const sortedData = sortBy ? getSortedData(statusFilter, sortBy) : statusFilter;
  const paginatedData = getPaginatedData(sortedData, pageSize, pageIndex);
  return paginatedData;
};

function getFilteredData<T extends Record<string, string | number | boolean>>(arr: T[], q: string): T[] {
  return arr.reduce((passing: T[], item) => {
    const valuesIncludeQuery = Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(q.toLowerCase());
      }
      return false;
    });
    if (valuesIncludeQuery) {
      return [...passing, item];
    }
    return passing;
  }, []);
}

function filterDataByCol<T extends Record<string, string | number | boolean>>(arr: T[], col: string, q: string): T[] {
  return arr.reduce((passing: T[], item) => {
    const valueInCol = item[col].toString().toLowerCase().includes(q.toLowerCase());
    return [...passing, ...(valueInCol ? [item] : [])];
  }, []);
}

function filterByAge<T extends Record<string, string | number | boolean>>(arr: T[], queries: string[]) {
  return arr.reduce((passing: T[], item) => {
    const ageCol = item.age as number;
    const isWithinBounds = queries.some((query) => {
      switch (query) {
        case '18_and_under':
          return ageCol < 18;
        case '26_to_35':
          return ageCol >= 26 && ageCol <= 35;
        case '36_to_50':
          return ageCol >= 36 && ageCol <= 50;
        case '51_and_up':
          return ageCol >= 51;
        default:
          return false;
      }
    });
    return [...passing, ...(isWithinBounds ? [item] : [])];
  }, []);
}

function getSortedData<T extends Record<string, string | number | boolean>>(arr: T[], sortBy: string): T[] {
  return arr.sort((a, b) => {
    const isDesc = sortBy.includes('-');
    const safeSort = sortBy.replace('-', '');
    const check = isDesc ? a[safeSort] < b[safeSort] : a[safeSort] > b[safeSort];

    if (check) {
      return -1;
    }
    if (!check) {
      return 1;
    }
    return 0;
  });
}

function getPaginatedData<T extends Record<string, string | number | boolean>>(
  arr: T[],
  pageSize: number,
  pageIndex: number
) {
  const res = [];
  for (let i = 0; i < arr.length; i += pageSize) res.push(arr.slice(i, i + pageSize));
  return {
    page_size: pageSize,
    results: res[pageIndex],
    total_count: arr.length,
    total_pages: res.length
  };
}
