import {Card} from '@shipwell/shipwell-ui';
import QuickbooksMappingForm from 'App/formComponents/forms/quickbooksMappings';

/**
 * Quickbooks Details
 */
const QuickbooksDetails = () => (
  <div className="integration-details-content">
    <Card
      title={
        <div className="integration-details-card-title">
          <img alt="integration-logo" className="integration-logo" src="/images/integration-quickbooks.png" />
          <span>QuickBooks Account Mapping</span>
        </div>
      }
    >
      <>
        <div className="integration-details-card-explainer">
          Select the mapping of Shipwell Line Items to QuickBook accounts.
        </div>
        <QuickbooksMappingForm />
      </>
    </Card>
  </div>
);

export default QuickbooksDetails;
