import {PaginatedWorkflowExecutions} from '@shipwell/opus-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {WORKFLOW_EXECUTIONS_LIST_QUERY_KEY} from '../queryKeys';
import {getWorkflowExecutions} from 'App/api/workflows/typed';

export const useGetWorkflowExecutions = ({
  shipmentId,
  options
}: {
  shipmentId: string;
  options?: Omit<
    UseQueryOptions<PaginatedWorkflowExecutions, unknown, PaginatedWorkflowExecutions, string[]>,
    'initialData' | 'queryKey' | 'queryFn'
  >;
}) => {
  const getWorkflowExecutionsQuery = useQuery(
    [WORKFLOW_EXECUTIONS_LIST_QUERY_KEY, shipmentId],
    async () => {
      const response = await getWorkflowExecutions({shipmentId});
      return response.data;
    },
    {
      enabled: Boolean(shipmentId),
      ...options
    }
  );
  const {data: workflowExecutionsData, status: workflowExecutionsStatus} = getWorkflowExecutionsQuery;
  return {workflowExecutionsData, workflowExecutionsStatus, getWorkflowExecutionsQuery};
};
