const validate = (values) => {
  const errors = {};

  //documents
  /*
  if (values.docTitle && values.docTitle.length > 100) {
    errors.docTitle = 'Must be shorter';
  }
  */
  if (!values.description) {
    errors.description = 'Please enter a description';
  } else if (values.description && values.description.length > 1000) {
    errors.description = 'Must be shorter';
  }
  if (!values.file) {
    errors.file = 'Please attach document file here';
  }
  if (!values.type) {
    errors.type = 'Please select the document type';
  }
  return errors;
};

export default validate;
