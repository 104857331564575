import _ from 'lodash';
import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {Row, Col, Panel, Image} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import {bindActionCreators} from 'redux';
import moment from 'moment-timezone';
import {Button, Modal} from '@shipwell/shipwell-ui';
import * as actions from '../../actions/documents';
import * as shipmentActions from '../../actions/shipments';
import ShipmentDocUpdateForm from './ShipmentDocUpdateForm';
import * as formActions from 'App/actions/forms';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import SendShipmentDocumentsForm from 'App/containers/Shipment/SendShipmentDocumentsForm';
import {unpackErrors, formatDayOfWeekDateTime} from 'App/utils/globals';
import PageHeader from 'App/common/pageHeader';
import './_shipment-documents.scss';

var angle = 0;

export class ShipmentDocDetail extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.handleUpdateDoc = this.handleUpdateDoc.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.fetchDocument = this.fetchDocument.bind(this);
    this.fetchAuditLog = this.fetchAuditLog.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.sendDocument = this.sendDocument.bind(this);
    this.state = {
      showDeleteDocModal: false,
      showSuccessfulDispatch: false,
      dispatchWasFTL: false,
      showDispatchModal: false,
      showEditForm: false,
      showSendDocumentModal: false,
      sent: false
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.fetchDocument();
      this.props.fetchDocumentTypes();
      this.fetchAuditLog();
    }
    if (this.props.successfulDispatch === true && !this.props.dispatchWasFTL) {
      this.setState({showSuccessfulDispatch: true, showDispatchModal: true});
      this.props.triggerSuccessfulDispatch(false);
    } else if (this.props.successfulDispatch === true && this.props.dispatchWasFTL === true) {
      this.setState({showSuccessfulDispatch: true, dispatchWasFTL: true});
      this.props.triggerSuccessfulDispatch(false, false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.fetchDocument();
      this.props.fetchDocumentTypes();
      this.fetchAuditLog();
    }
  }

  fetchDocument() {
    this.props.fetchDocument(this.props.params.shipment_id, this.props.params.doc_id).then((response) => {
      if (response.status !== 200) {
        //push back to the shipment details if this fails
        this.context.router.push('/shipments/' + this.props.params.shipment_id);
      }
    });
  }

  fetchAuditLog() {
    this.props.getDocAuditLog(this.props.params.shipment_id);
  }

  handleDeleteDoc() {
    const documentId = this.props.params.doc_id;
    const shipmentId = this.props.document.shipment;

    return new Promise((resolve, reject) => {
      if (this.props.error) {
        reject();
      } else {
        resolve(this.props.deleteDocument(shipmentId, documentId));
        this.setState({showDeleteDocModal: false}, this.context.router.push(`/shipments/${shipmentId}`));
      }
    });
  }

  showSendDocumentModal(document) {
    this.setState({showSendDocumentModal: true});
  }

  sendDocument(attrs) {
    const shipmentId = this.props.params.shipment_id;
    const body = attrs;
    body.documents = [this.props.document.id];

    const emails = body.recipient_emails.map((email) => {
      return email?.value;
    });

    body.recipient_emails = emails.filter(Boolean);

    //send the document;
    return this.props.shareDocument(shipmentId, body).then((response) => {
      if (response.status === 200) {
        this.setState({sent: true});
        setTimeout(() => {
          this.setState({sent: false, showSendDocumentModal: false}, () => this.props.resetSendDocumentsForm());
          this.fetchAuditLog();
        }, 3000);
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  renderSendDocumentModal() {
    return (
      <SendShipmentDocumentsForm
        selectedDocuments={[this.props.document]}
        isSendSingleDocModal
        handleDocumentClick={() => {}}
        goBack={() => this.setState({showSendDocumentModal: false})}
        addressBook={this.props.addressBook}
        onSubmit={this.sendDocument}
        sent={this.state.sent}
      />
    );
  }

  handleUpdateDoc() {
    this.setState({showEditForm: true});
  }

  printDocument(doc) {
    var mywindow = window.open('', 'PRINT', 'height=400px,width=600px');
    mywindow.document.write('<html height="100%"><head><title>' + doc.filename + '</title>');
    mywindow.document.write('</head><body height="100%">');
    mywindow.document.write('<img style={{"maxHeight":"100%"}} width="100%" src="' + doc.file + '"/>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return;
  }

  rotateImage() {
    angle += 90;
    if (angle === 360) {
      angle = 0;
    }
    var image = document.getElementById('docImg');
    image.className = 'rotate' + angle + ' img-responsive';

    //javascript to correctly display image after rotation
    const imageBorder = document.getElementsByClassName('document-details__image-border');
    const height = image.offsetHeight;
    const width = image.offsetWidth;
    if (angle === 90 || angle === 270) {
      //tall images
      if (height > width) {
        //make image fit in paper
        image.style.width = 'auto';
        image.style.height = width + 'px';
        //make sure paper isn't too big
        imageBorder[0].style.width = 'auto';
        imageBorder[0].style.height = height + 'px';
      }
      //make sure paper isn't too big
      imageBorder[0].style.width = 'auto';
      imageBorder[0].style.height = image.offsetWidth + 'px';
    } else {
      //reset to default
      image.style.width = 'unset';
      image.style.height = 'auto';
      imageBorder[0].style.width = 'unset';
      imageBorder[0].style.height = 'auto';
    }
  }

  onFormSubmit(attrs) {
    const body = {
      description: attrs.description || '',
      type: attrs.type,
      is_carrier_document: this.props.user.carrier ? true : false
    };

    return this.props.putDocument(attrs.shipment, attrs.id, body).then((response) => {
      if (response.status === 200) {
        //refetch doc
        this.fetchDocument();
        this.setState({showEditForm: false});
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  renderDocDetailInfo() {
    const detail = this.props.document;
    let shipmentId = '';
    let docAuditLog;
    if (this.props.document && this.props.document.shipment) {
      shipmentId = this.props.document.shipment;
    }
    // auditlog for this document
    if (this.props.auditLog && detail) {
      docAuditLog = [...this.props.auditLog].find((el) => el.id === detail.id);
    }

    if (detail) {
      return (
        <div className="document-details">
          <div className="shipmentDocument__details">
            {this.state.showEditForm ? (
              <ShipmentDocUpdateForm
                documentTypes={this.props.documentTypes}
                handleCancel={() => this.setState({showEditForm: false})}
                onSubmit={this.onFormSubmit}
                context={this.context}
                shipmentId={shipmentId}
                documentId={detail.id}
                isLoading={this.props.isLoading}
              />
            ) : (
              <Fragment>
                <div>
                  <h3>
                    {detail.type &&
                      this.props.documentTypes &&
                      this.props.documentTypes.length > 0 &&
                      this.props.documentTypes.filter((e) => e.id === detail.type).length > 0 &&
                      this.props.documentTypes
                        .filter((e) => e.id === detail.type)[0]
                        .name.replace('_', ' ')
                        .split(' ')
                        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                        .join(' ')}
                  </h3>
                  <strong>Description</strong>
                  <p className="text-muted">{detail.description}</p>
                  <strong>Created</strong>
                  <p className="text-muted">
                    {formatDayOfWeekDateTime(detail.created_at, true, moment.tz.guess())} by {detail.created_by_name}
                  </p>
                  <strong>Updated</strong>
                  <p className="text-muted">{formatDayOfWeekDateTime(detail.updated_at, true, moment.tz.guess())}</p>

                  {detail.file !== 'null' &&
                    detail.type === 'BOL' &&
                    detail.filename.slice(-4).toLowerCase() === '.pdf' && (
                      <p>You can print the BOL from the PDF image menu</p>
                    )}
                  {this.state.showSuccessfulDispatch && (
                    <Button
                      onClick={() => this.context.router.push(`/shipments/${shipmentId}`)}
                      bsStyle={this.state.showSuccessfulDispatch ? 'primary' : 'default'}
                    >
                      {this.state.showSuccessfulDispatch ? 'Continue' : 'Back'}
                    </Button>
                  )}
                </div>
                {docAuditLog && docAuditLog.emails && docAuditLog.emails.length > 0 && (
                  <div>
                    <strong>Audit Log</strong>
                    <div className="logs">
                      {docAuditLog.emails.map((el, i) => {
                        return (
                          <div key={i} className="log-container">
                            <div>
                              <strong>Date:</strong> {formatDayOfWeekDateTime(el.updated_at, true)}
                            </div>
                            <div>
                              <strong>Status:</strong> {el.status}
                            </div>
                            <div>
                              <strong>From:</strong> {el.replyto_email}
                            </div>
                            <div>
                              <strong>To:</strong> {el.to_email}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>

          <div className="shipmentDocument__doc">
            {detail.filename.slice(-4).toLowerCase() !== '.pdf' && (
              <div className="document-details__image-container">
                <div className="document-details__image-rotate">
                  <Button variant="tertiary" onClick={this.rotateImage}>
                    Rotate Image
                  </Button>
                </div>
                <div className="document-details__image-border">
                  <Image
                    id="docImg"
                    ref="docImg"
                    className="img-responsive"
                    src={detail.file}
                    height="100%"
                    width="100%"
                    alt="Photo"
                  />
                </div>
              </div>
            )}
            {detail.filename.slice(-4).toLowerCase() === '.pdf' && (
              <Panel className="movie-detail">
                <object
                  style={{margin: '0px auto'}}
                  type="application/pdf"
                  data={detail.file}
                  width="100%"
                  height="100%"
                />
              </Panel>
            )}

            <p>
              If the image above doesn't load, you can access it{' '}
              <a href={detail.file} rel="noopener noreferrer" target="_blank">
                here
              </a>
            </p>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const {isLoading} = this.props;
    const detail = this.props.document;
    let docOwner = '';

    if (this.props.document && this.props.document.created_by_id) {
      docOwner = this.props.document.created_by_id;
    }
    let docCheck = false;
    if (this.props.user) {
      docCheck = this.props.user.id === docOwner;
    }

    //only render footerButton on Shipper Docs

    return (
      <div className="shipment__document content-wrapper">
        {isLoading ? <ShipwellLoader loading={isLoading} /> : null}
        <PageHeader title="Document Details" backArrow backRoute={`/shipments/${detail?.shipment || ''}`} />
        <section className="content">
          <Paper className="shipment__document-card">{this.renderDocDetailInfo()}</Paper>
          {docCheck && !this.state.showSuccessfulDispatch ? (
            <div className="shipmentDocument__actions">
              {detail && detail.created_by_id === this.props.user.id && (
                <Button
                  variant="primary"
                  color="warning"
                  onClick={() => {
                    this.setState({showDeleteDocModal: true});
                  }}
                >
                  Delete Document
                </Button>
              )}
              {detail && detail.created_by_id === this.props.user.id && (
                <Button variant="secondary" onClick={this.handleUpdateDoc}>
                  Edit Details
                </Button>
              )}

              <Button
                onClick={() => {
                  this.showSendDocumentModal(detail);
                }}
                variant="secondary"
              >
                Send Document
              </Button>
              {detail && detail.filename.indexOf('.pdf') === -1 && (
                <Button
                  onClick={() => {
                    this.printDocument(detail);
                  }}
                  variant="primary"
                >
                  Print Document
                </Button>
              )}
            </div>
          ) : (
            <div className="shipmentDocument__actions">
              <Button
                onClick={() => {
                  this.showSendDocumentModal(detail);
                }}
                variant="secondary"
              >
                Send Document
              </Button>
              {detail && detail.filename.indexOf('.pdf') === -1 && (
                <Button
                  onClick={() => {
                    this.printDocument(detail);
                  }}
                  variant="secondary"
                >
                  Print Document
                </Button>
              )}
            </div>
          )}
        </section>
        <Modal
          show={this.state.showDispatchModal}
          onClose={() => {
            this.setState({showDispatchModal: false});
          }}
          title="Shipment Dispatched"
          footerComponent={null}
        >
          <div>
            <p>
              This shipment has been dispatched electronically to the carrier. We'll send you an email confirmation now
              with your shipment's details, and if you have questions about your shipment or need to cancel/change it,
              you can also find the carrier's contact information on the Bill of Lading.
            </p>
          </div>
        </Modal>
        <Modal
          variant="warning"
          show={Boolean(this.state.showDeleteDocModal)}
          title="Delete Document"
          primaryBtnName="Delete Document"
          onClose={() =>
            this.setState({
              showDeleteDocModal: false
            })
          }
          onPrimaryAction={this.handleDeleteDoc}
        >
          <p>Are you sure you want to delete this document?</p>
        </Modal>
        <Modal
          show={this.state.showSendDocumentModal}
          onClose={() =>
            this.setState({
              showSendDocumentModal: false
            })
          }
          title="Send Document"
          footerComponent={null}
        >
          {this.renderSendDocumentModal()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.documents.isLoading,
    error: state.documents.error,
    document: state.documents.document,
    documentTypes: state.documents.documentTypes,
    company: state.auth.company,
    user: state.auth.user,
    successfulDispatch: state.shipments.successfulDispatch,
    dispatchWasFTL: state.shipments.dispatchWasFTL,
    addressBook: state.addresses.addressbook,
    auditLog: state.documents.auditLog
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      ...shipmentActions,
      ...formActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDocDetail);
