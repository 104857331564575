import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {Button, Pagination, Table, OverlayTrigger, Tooltip, Row, Col} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import DriverForm from '../forms/DriverForm';
import * as actions from 'App/actions/carriers';
import * as userActions from 'App/actions/users';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {unpackErrors} from 'App/utils/globals';
import './_manage-drivers.scss';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    isLoading: state.carriers.isLoading,
    drivers: state.carriers.drivers,
    hos: state.carriers.hos,
    companyUsers: state.users.companyUsers
  }),
  {...actions, ...userActions}
)
export default class NewDriver extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.onDriverFormSubmit = this.onDriverFormSubmit.bind(this);
    this.getCompanyUsers = this.getCompanyUsers.bind(this);
    this.back = this.back.bind(this);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 10
      }
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getCompanyUsers(this.props.company.id);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.company && this.props.company !== nextProps.company) {
      this.getCompanyUsers(nextProps.company.id);
    }
  }

  getCompanyUsers(companyId) {
    this.props.getCompanyUsers(companyId, {
      page: 1,
      pageSize: 1000,
      ordering: 'first_name'
    });
  }

  back() {
    this.context.router.push('/manage_drivers');
  }

  onDriverFormSubmit(attrs, pulling) {
    const body = {body: attrs};
    return this.props.postDriver(this.props.company.id, body, pulling).then((response) => {
      if (response.status === 200) {
        this.back();
      } else {
        const errors = response.field_errors || [];
        let myError = {
          user: {}
        };
        myError = unpackErrors(errors, myError);
        myError._error = response.error_description;
        //handle edge cases for errors here
        if (
          response.error_description &&
          Array.isArray(response.error_description) &&
          response.error_description.length > 0 &&
          response.error_description[0] ===
            'This driver is associated with a Company and cannot establish another relationship'
        ) {
          myError.phone_number = response.error_description[0];
        }
        throw new SubmissionError(myError);
      }
    });
  }

  render() {
    return (
      <div className="driver content-wrapper">
        <section className="content">
          <DriverForm
            back={this.back}
            onSubmit={(el) => {
              return this.onDriverFormSubmit(el, false);
            }}
            users={this.props.companyUsers}
          />
        </section>
      </div>
    );
  }
}
