import {useState, ChangeEvent} from 'react';
import {browserHistory} from 'react-router';
import {CarrierTag} from '@shipwell/backend-core-sdk';
import {Select, SearchField, Button} from '@shipwell/shipwell-ui';
import useServiceProviders from 'App/api/serviceProviders/useServiceProviders';
import PageHeader from 'App/common/pageHeader';
import {ServiceProvidersListTable} from 'App/containers/serviceProviders/list/ServiceProvidersListTable';
import {TableContainer} from 'App/components/TypedTable/baseComponents';

const ServiceProvidersListContainer = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<CarrierTag[]>([]);

  const {providerTags} = useServiceProviders();

  return (
    <TableContainer>
      <PageHeader
        title="Service Providers"
        className="min-h-min flex-col items-start md:flex-row md:items-center"
        actions={
          <Button
            variant="tertiary"
            iconName="AddCircleOutlined"
            onClick={() => {
              browserHistory.push('/service-providers/create');
            }}
          >
            Provider
          </Button>
        }
      >
        <div className="my-4 flex min-w-[250px] flex-col md:my-0 md:flex-row">
          <SearchField
            label="Search providers"
            name="search_providers"
            value={searchTerm}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
          />
          <Select
            isMulti
            className="ml-0 mt-2 min-w-[250px] md:ml-[20px] md:mt-0"
            label="Filter Providers by Tag"
            value={selectedTags}
            getOptionValue={({id}: {id: string}) => id}
            getOptionLabel={({name}: {name: string}) => name}
            options={providerTags}
            onChange={(selectedTags: CarrierTag[] = []) => {
              setSelectedTags(selectedTags);
            }}
          />
        </div>
      </PageHeader>
      <ServiceProvidersListTable searchTerm={searchTerm} selectedTags={selectedTags} />
    </TableContainer>
  );
};

export default ServiceProvidersListContainer;
