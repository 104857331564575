import {flexRender} from '@tanstack/react-table';
import {CarrierTag} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useState} from 'react';
import {Banner} from '@shipwell/shipwell-ui';
import {columns} from './columns';
import useServiceProviders from 'App/api/serviceProviders/useServiceProviders';
import {
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import Loader from 'App/common/shipwellLoader';
import {useTablePagination, useTableSort, useTypedTable} from 'App/components/TypedTable/hooks';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/components/TypedTable/tableUtils';
import {SERVICE_PROVIDERS_LIST_KEY} from 'App/utils/tableTypeKeys';
import {ServiceProviderOptions} from 'App/api/serviceProviders/typed';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {useGetCarrierTags} from 'App/data-hooks';

export const ServiceProvidersListTable = ({
  searchTerm,
  selectedTags
}: {
  searchTerm: string;
  selectedTags: CarrierTag[];
}) => {
  useGetCarrierTags({pageSize: 1000, ordering: 'name'});
  const [sorting, setSorting, sortString] = useTableSort({shouldUseRouter: true});
  const [pagination, setPagination] = useTablePagination({
    tableType: SERVICE_PROVIDERS_LIST_KEY,
    shouldUseRouter: true
  });

  const [prevQuery, setPrevQuery] = useState(searchTerm);
  if (searchTerm !== prevQuery) {
    setPrevQuery(searchTerm);
    if (pagination.pageIndex !== 0) setPagination({...pagination, pageIndex: 0});
  }

  const [prevSelectedTags, setPrevSelectedTags] = useState(selectedTags);
  if (selectedTags !== prevSelectedTags) {
    setPrevSelectedTags(selectedTags);
    if (pagination.pageIndex !== 0) setPagination({...pagination, pageIndex: 0});
  }

  const opts: ServiceProviderOptions = {
    q: searchTerm,
    pageSize: pagination.pageSize,
    page: pagination.pageIndex + 1,
    ordering: sortString,
    tagsContains: selectedTags.map((tag) => tag.id).join(',')
  };

  const {
    serviceProvidersQuery: {data: paginatedServiceProviders, isLoading}
  } = useServiceProviders(opts);

  const pageCount = paginatedServiceProviders?.count
    ? Math.ceil(paginatedServiceProviders?.count / pagination.pageSize)
    : 1;

  const table = useTypedTable({
    data: paginatedServiceProviders?.data || [],
    columns,
    pageCount,
    state: {pagination, sorting},
    onPaginationChange: setPagination,
    onSortingChange: setSorting
  });

  if (paginatedServiceProviders) {
    return paginatedServiceProviders.data?.length ? (
      <TableContainer>
        <TableContainer>
          <Table
            head={table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    width={header.getSize()}
                    sortDirection={header.column.getIsSorted()}
                    onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                    onResize={header.getResizeHandler()}
                  >
                    <div className="flex items-center gap-1">
                      {header.column.getCanSort() ? (
                        <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                      ) : null}
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
            body={table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          />
        </TableContainer>
        <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
      </TableContainer>
    ) : (
      <EmptyListView itemLabel="Service Providers" />
    );
  }

  if (isLoading) {
    return <Loader loading />;
  }

  return <Banner title={'Error'}>There was an error. Try reloading the page.</Banner>;
};
