import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ScheduledResourceTypeEnum} from '@shipwell/tempus-sdk';
import {useQueries} from '@tanstack/react-query';
import {useMemo} from 'react';

import {AppointmentEntry} from './types';

import {getShipment} from 'App/api/shipment/typed';
import {CORE_SHIPMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';

export function useShipmentsForAppointments(
  appointments: AppointmentEntry[],
  enabled: boolean
): {
  isLoading: boolean;
  shipments: Shipment[];
  error?: Error;
} {
  const rawShipmentIds = Array.from(
    new Set(
      appointments
        .filter((a) => a.scheduledResourceType === ScheduledResourceTypeEnum.Shipment)
        .map((a) => a.scheduledResourceId as string)
    )
  ).sort();
  const shipmentIdsKey = rawShipmentIds.join(',');
  const queryBags = useMemo(() => {
    return {
      queries: rawShipmentIds.map((shipmentId) => ({
        queryKey: [CORE_SHIPMENT_QUERY_KEY, shipmentId],
        queryFn: getShipment.bind(null, shipmentId),
        enabled
      }))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentIdsKey, enabled]);

  const queries = useQueries(queryBags);
  const isLoading = enabled && queries.some((q) => q.isLoading || !q.isFetched);
  const shipments = queries.map((q) => q.data).filter((data: Shipment | undefined): data is Shipment => data !== null);
  const error = enabled ? queries.map((q) => q.error as Error | undefined).find(Boolean) : undefined;
  return {isLoading, shipments, error};
}
