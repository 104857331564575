import {JSX, useState} from 'react';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useQueryClient, useMutation, useQuery} from '@tanstack/react-query';
import {compose} from 'recompose';
import {
  ELDDeviceLocation,
  ShipmentTimelineEvent,
  ShipmentTimelineFilterPreferences,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import invariant from 'tiny-invariant';
import Timeline from './Timeline';
import {FilterType} from './Timeline/LegacyTimeline';
import useToggle from 'App/utils/hooks/useToggle';
import TimelineFilter from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineFilter';
import NewEventModal, {
  CreateEventValidation
} from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import {
  makeEventCreationPayload,
  makeTrackingPayload
} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils';
import {
  GROUPED_TIMELINE_EVENTS_QUERY_KEY,
  LEGACY_SHIPMENTS_QUERY_KEY,
  TRACKING_BREADCRUMBS_QUERY_KEY,
  TIMELINE_EVENT_DETAILS
} from 'App/data-hooks/queryKeys';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import WithStatusToasts from 'App/components/withStatusToasts';
import {getTimelineEventDetails, postBreadcrumbPromise} from 'App/api/shipment/typed';
import {
  useCreateEventTemplate,
  useDeleteTimelineEvent,
  useUpdateTimelineEvent,
  useGetGroupedTimelineEvents,
  useCreateGroupedTimelineEvent,
  useGetTimelineEventFilters,
  useUpdateTimelineFilters
} from 'App/data-hooks';
import {UseGetGroupedTimelineEventsOptions} from 'App/data-hooks/tracking/useGetGroupedTimelineEvents';

interface BaseTimelineContainerProps {
  shipmentId: string;
  loader?: JSX.Element;
}

interface TimelineContainerProps extends BaseTimelineContainerProps {
  setError: (title: string, message: string) => void;
}

const defaultLoader = <ShipwellLoader loading />;

const TimelineContainer = ({shipmentId, setError, loader = defaultLoader}: TimelineContainerProps) => {
  const queryClient = useQueryClient();
  const [showEventModal, toggleShowEventModal] = useToggle();
  const [showEditEventModal, toggleShowEditEventModal] = useToggle();
  const [selectedEventId, setSelectedEventId] = useState('');

  const {
    formattedFilters,
    context: {timelineFiltersQuery}
  } = useGetTimelineEventFilters({
    onError: (error: ShipwellError) => {
      setError(
        'Error!',
        error?.error_description || 'There was an error while fetching the timeline filters, please try again.'
      );
      console.error(error);
    }
  });

  const opts = {
    groupType: formattedFilters.groupType,
    filterFutureStops: formattedFilters.filterFutureStops
  } as unknown as UseGetGroupedTimelineEventsOptions;

  const createEventMutation = useCreateGroupedTimelineEvent(
    shipmentId,
    [GROUPED_TIMELINE_EVENTS_QUERY_KEY, shipmentId, opts],
    {
      errorHandler: (error: ShipwellError) =>
        setError(
          'Error!',
          error?.error_description || 'There was an error while fetching the timeline events, please try again.'
        )
    }
  );

  const groupedTimelineEventsQuery = useGetGroupedTimelineEvents(
    {shipmentId, ...opts},
    {
      enabled: !!shipmentId && timelineFiltersQuery?.isSuccess,
      refetchOnWindowFocus: false,
      onError: (error) => {
        setError(
          'Error!',
          error?.error_description || 'There was an error while fetching the timeline events, please try again.'
        );
        console.error(error);
      }
    }
  );
  const groupedTimelineStops = groupedTimelineEventsQuery?.data;

  const eventDetailsQuery = useQuery(
    [TIMELINE_EVENT_DETAILS, selectedEventId],
    () => getTimelineEventDetails({shipmentId, shipmentTimelineEventId: selectedEventId}),
    {
      enabled: !!selectedEventId
    }
  );

  const updateEventMutation = useUpdateTimelineEvent(shipmentId, {
    errorHandler: (error: ShipwellError) =>
      setError(
        'Error!',
        error?.error_description || 'There was an error while updating the timeline event, please try again.'
      )
  });

  const createTemplateMutation = useCreateEventTemplate({
    onError: (error: ShipwellError) => {
      setError(
        'Error!',
        error?.error_description || 'There was an error while creating the template, please try again.'
      );
    }
  });

  const deleteEventMutation = useDeleteTimelineEvent(shipmentId, {
    errorHandler: (error: ShipwellError) =>
      setError(
        'Error!',
        error?.error_description || 'There was an error while deleting the timeline event, please try again.'
      )
  });

  /**
   * when an event gets created with location data, we need to post it to /tracking
   * then get the updated shipment data so that
   * the truck icon gets updated on the map
   */
  const trackingMutation = useMutation(
    (payload: ELDDeviceLocation) =>
      postBreadcrumbPromise({shipmentId, eLDLocationUpdateRequest: {locations: [payload]}}),
    {
      onError: (error: ShipwellError) => {
        setError(
          'Error!',
          error?.error_description || 'There was an error while fetching the timeline events, please try again.'
        );
        console.error(error);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([GROUPED_TIMELINE_EVENTS_QUERY_KEY]);
        void queryClient.invalidateQueries([LEGACY_SHIPMENTS_QUERY_KEY, shipmentId]);
        void queryClient.invalidateQueries([TRACKING_BREADCRUMBS_QUERY_KEY, shipmentId]);
      }
    }
  );

  const updateTimelineFilters = useUpdateTimelineFilters({
    errorHandler: (error: ShipwellError) =>
      setError(
        'Error!',
        error?.error_description || 'There was an error while updating the timeline filters, please try again.'
      )
  });

  const handleEventCreation = (values: CreateEventValidation) => {
    const eventCreationPayload = makeEventCreationPayload(values);
    /**
     * call the create endpoint only when no location was entered
     * otherwise, call the POST tracking endpoint and refetch the timeline events.
     * the call to POST /tracking will add it to the events list, no need to make a separate call.
     */
    if (!values?.place_changed) {
      createEventMutation.mutate(eventCreationPayload);
    } else {
      const trackingPayload = makeTrackingPayload(values);
      trackingMutation.mutate(trackingPayload);
    }
    toggleShowEventModal();
  };

  const handleFilterSelection = (type: string, value: string) => {
    const updatedFilters = {
      ...formattedFilters,
      [type]: value
    };
    // required formatting of data until BE endpoint gets updated with values discussed for these fields.
    const body = {
      ...updatedFilters,
      rollup_stops: updatedFilters.timelineDisplayType === 'rollup' ? true : false,
      filter_future_stops: updatedFilters.filterFutureStops === 'true' ? true : false,
      group_type: updatedFilters.groupType
    } as unknown as ShipmentTimelineFilterPreferences & {
      filterFutureStops?: FilterType;
      groupType?: FilterType;
      timelineDisplayType?: FilterType;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    delete body.filterFutureStops, delete body.groupType, delete body.timelineDisplayType;
    updateTimelineFilters.mutate({data: body});
  };

  const handleShowUpdateEvent = (event: ShipmentTimelineEvent) => {
    invariant(event.id);
    setSelectedEventId(event.id);
    toggleShowEditEventModal();
  };

  const handleUpdateEvent = (values: ShipmentTimelineEvent & CreateEventValidation) => {
    const payload = {...values, actor: values?.actor?.id};
    updateEventMutation.mutate({eventId: selectedEventId, payload});
    if (values?.save_template) {
      const payload = {description: values?.description};
      createTemplateMutation.mutate(payload);
    }
    toggleShowEditEventModal();
  };

  const handleDeleteEvent = (event: ShipmentTimelineEvent) => {
    invariant(event.id);
    deleteEventMutation.mutate({eventId: event.id});
  };

  if (groupedTimelineEventsQuery?.isInitialLoading || timelineFiltersQuery?.isInitialLoading) {
    return loader;
  }

  return (
    <>
      <NewEventModal show={showEventModal} onClose={toggleShowEventModal} handleSubmit={handleEventCreation} />
      <div className="sticky top-0 z-10 mb-2 flex items-center bg-sw-background-component">
        <TimelineFilter values={formattedFilters} handleFilterSelection={handleFilterSelection} />
        <DeprecatedButton className="flex grow " onClick={toggleShowEventModal}>
          Add Event
        </DeprecatedButton>
      </div>
      <Timeline
        stops={groupedTimelineStops || []}
        shipmentId={shipmentId}
        selectedFilter={formattedFilters}
        toggleShowEventModal={toggleShowEventModal}
        onUpdateEvent={handleShowUpdateEvent}
        onDeleteEvent={handleDeleteEvent}
      />
      {eventDetailsQuery?.isSuccess && showEditEventModal ? (
        <NewEventModal
          show={showEditEventModal}
          onClose={toggleShowEditEventModal}
          handleSubmit={handleUpdateEvent}
          values={eventDetailsQuery?.data?.data}
        />
      ) : null}
    </>
  );
};

export default compose<TimelineContainerProps, BaseTimelineContainerProps>(WithStatusToasts)(TimelineContainer);
