import {LegMapContainer} from 'App/components/ShipmentTrackingOverview/MapContainer';
import {LegTimelineContainer} from 'App/components/ShipmentTrackingOverview/TimelineContainer';

export const LegTrackingOverview = ({legId}: {legId: string}) => {
  return (
    <div className="grid min-h-full grid-cols-[25%,75%]">
      <div className="max-w-md overflow-y-auto rounded-md border-y-1 border-r-1 border-sw-disabled p-2 h-screen-minus-56">
        <LegTimelineContainer legId={legId} />
      </div>
      <div className="h-auto">
        <LegMapContainer legId={legId} />
      </div>
    </div>
  );
};
