import {JSX, ReactNode, MouseEvent} from 'react';
import invariant from 'tiny-invariant';
import {OverlayTrigger, Tooltip, Button, Table} from 'react-bootstrap';
import {Document, Page} from 'react-pdf';
import {ShipmentDocumentMetadata} from '@shipwell/backend-core-sdk';
import {formatBytes, formatDayOfWeekDateTime} from 'App/utils/globals';
import './_documents-array.scss';

export type DocumentType = ShipmentDocumentMetadata & {
  fileComplete: {
    size: number;
  };
};

export type DocumentsArrayProps = {
  documents: DocumentType[];
  handleDocumentClick: (documentId: string, e?: MouseEvent<HTMLElement>) => unknown;
  showSelections?: boolean;
  selectedDocuments: string[];
  layout?: ReactNode;
  highlightBOL?: boolean;
  showTooltip?: boolean;
  tooltipContent?: ReactNode;
  handlePreviewClick: (documentId: string) => unknown;
};

const DocumentsArray = ({
  documents,
  handleDocumentClick,
  showSelections,
  selectedDocuments,
  layout,
  highlightBOL,
  showTooltip,
  tooltipContent,
  handlePreviewClick
}: DocumentsArrayProps): JSX.Element => (
  <div className={layout === 'vertical' ? 'documentsVertical documentsArray' : 'documentsHorizontal documentsArray'}>
    {documents.length === 0 && <h4 className="text-center"> No Documents</h4>}
    {documents.length
      ? documents.map(
          (
            {id, company, created_at, created_by_name, description, file, fileComplete, filename, type, updated_at},
            idx
          ) => {
            invariant(id, 'ID of document should never be undefined or null');
            invariant(filename, 'Filename of document should never be undefined or null');

            return (
              <div
                key={id}
                className={
                  'shipmentDocument__container' +
                  (layout === 'vertical' ? '' : ' movie-layout') +
                  (type === 'BOL' && highlightBOL && idx === 0 ? ' active' : '') +
                  (showSelections === true && selectedDocuments && selectedDocuments.includes(id)
                    ? ' shipmentDocument__container-selected'
                    : '')
                }
              >
                {showTooltip === true ? (
                  <OverlayTrigger
                    placement={tooltipContent === 'details' ? 'top' : 'right'}
                    overlay={
                      tooltipContent === 'details' ? (
                        <Tooltip id="tooltip" className="shipmentDocument__tooltip">
                          <Table>
                            <tbody>
                              {type && (
                                <tr>
                                  <th>
                                    <strong>TYPE</strong>
                                  </th>
                                  <td>{type.replace(/_/g, ' ')}</td>
                                </tr>
                              )}
                              <tr>
                                <th>
                                  <strong>FILE NAME</strong>
                                </th>
                                <td>{filename}</td>
                              </tr>
                              <tr>
                                <th>
                                  <strong>DESCRIPTION</strong>
                                </th>
                                <td>{description}</td>
                              </tr>
                              {created_at && (
                                <tr>
                                  <th>
                                    <strong>CREATED</strong>
                                  </th>
                                  <td>
                                    {formatDayOfWeekDateTime(created_at) +
                                      (company ? ' by ' + company + ' - ' : '') +
                                      (created_by_name ? created_by_name : '')}
                                  </td>
                                </tr>
                              )}
                              {updated_at ? (
                                <tr>
                                  <th>
                                    <strong>UPDATED</strong>
                                  </th>
                                  <td>{formatDayOfWeekDateTime(updated_at)}</td>
                                </tr>
                              ) : null}
                            </tbody>
                          </Table>
                        </Tooltip>
                      ) : null
                    }
                  >
                    <div
                      onClick={(e) => {
                        handleDocumentClick(id, e);
                      }}
                    >
                      {type && (
                        <div className="shipmentDocument__documentTitle">
                          <strong>{type.replace(/_/g, ' ')}</strong>
                        </div>
                      )}
                      <div>
                        {filename.slice(-4).toLowerCase() !== '.pdf' && file ? (
                          <img src={file} alt="shipment-document" className="shipmentDocument__documentImage" />
                        ) : null}
                        {filename.slice(-4).toLowerCase() === '.pdf' && (
                          <Document className="shipmentDocument__documentImage" file={file}>
                            <Page pageNumber={1} width={150} />
                          </Document>
                        )}
                      </div>
                      <div className="shipmentDocument__documentDescription">
                        <p>
                          <b>{description}</b>
                        </p>
                        <p>
                          <b>{filename}</b>
                        </p>
                        {fileComplete && fileComplete.size ? <p>{formatBytes(fileComplete.size)}</p> : null}
                      </div>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div>
                    <div
                      onClick={() => {
                        handleDocumentClick(id);
                      }}
                      className="shipmentDocument__documentTitle"
                    >
                      <strong>{type?.replace(/_/g, ' ')}</strong>
                    </div>
                    <div
                      className="shipmentDocument__imageContainer"
                      onClick={() => {
                        handleDocumentClick(id);
                      }}
                    >
                      {showSelections === true && selectedDocuments && selectedDocuments.includes(id) && (
                        <i className="icon icon-PaperClip shipmentDocument__selectedImage" />
                      )}
                      {file && filename.slice(-4).toLowerCase() !== '.pdf' && (
                        <img src={file} alt="shipment-document" className="shipmentDocument__documentImage" />
                      )}
                      {filename.slice(-4).toLowerCase() === '.pdf' && (
                        <Document className="shipmentDocument__documentImage" file={file}>
                          <Page width={150} pageNumber={1} />
                        </Document>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        handleDocumentClick(id);
                      }}
                      className="shipmentDocument__documentDescription"
                    >
                      <p>
                        <b>{description}</b>
                      </p>
                      <p>
                        <b>{filename}</b>
                      </p>
                      {fileComplete?.size ? <p>{formatBytes(fileComplete.size)}</p> : null}
                    </div>
                    <span>
                      <Button onClick={() => handlePreviewClick(id)} bsStyle="link" className="btn-tertiary">
                        <i className="flaticon-search pad-left pad-right" />
                        Preview
                      </Button>
                    </span>
                  </div>
                )}
              </div>
            );
          }
        )
      : null}
  </div>
);
export default DocumentsArray;
