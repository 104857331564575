import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Grid, Row, Col} from 'react-bootstrap';
import _ from 'lodash';
import ShipmentDocUpdateForm from './ShipmentDocUpdateForm';
import * as actions from 'App/actions/documents';

@connect(
  (state) => ({
    isLoading: state.documents.isLoading,
    document: state.documents.document,
    company: state.auth.company
  }),
  actions
)
export default class Shipment_Doc_Update extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.fetchDocument = this.fetchDocument.bind(this);
    this.state = {
      showError: false,
      errorMsg: null
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.fetchDocument();
    }
  }

  fetchDocument() {
    this.props.fetchDocument(this.props.params.shipment_id, this.props.params.doc_id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.fetchDocument();
    }
  }

  onFormSubmit(attrs) {
    const type =
      attrs.type.name === 'BOL'
        ? 'BOL'
        : attrs.type.name === 'FUEL'
        ? 'FUEL'
        : attrs.type.name === 'LUMPER'
        ? 'LUMPER'
        : attrs.type.name === 'HOS'
        ? 'HOS'
        : attrs.type.name === 'SHIPPER_SIGNATURE'
        ? 'SHIPPER_SIGNATURE'
        : attrs.type.name === 'RATE_CONFIRMATION'
        ? 'RATE_CONFIRMATION'
        : attrs.type.name === 'DELIVERY_RECEIPT'
        ? 'DELIVERY_RECEIPT'
        : attrs.type.name === 'INVOICE'
        ? 'INVOICE'
        : attrs.type.name === 'WEIGHT_CERTIFICATE'
        ? 'WEIGHT_CERTIFICATE'
        : 'OTHER';

    const docForm = {
      description: attrs.description || '',
      type: type
    };

    this.props.putDocument(attrs.shipment, attrs.id, docForm);
  }

  render() {
    const shipmentId = this.props.params.shipment_id;
    const documentId = this.props.params.doc_id;
    return (
      <div className="content-wrapper">
        <section className="content">
          <Row>
            <Col xs={12}>
              <div className="content">
                <ShipmentDocUpdateForm
                  onSubmit={this.onFormSubmit}
                  context={this.context}
                  shipmentId={shipmentId}
                  documentId={documentId}
                  isLoading={this.props.isLoading}
                />

                <Row
                  style={{
                    minHeight: 500
                  }}
                />
              </div>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}
