import moment from 'moment';
import PropTypes from 'prop-types';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';

export const columnHeaders = ['Pay To', 'Type', 'Amount', 'Payment Date', 'Status'];

const TriumphPayTable = ({triumphPayInvoices}) => {
  return (
    <div>
      <div className="mb-3 grid grid-cols-5 gap-x-4 gap-y-2 border-b border-sw-border pb-1">
        {columnHeaders.map((header) => {
          const toolTip =
            header === 'Payment Date' ? (
              <Tooltip
                tooltipContent={
                  <span>
                    If Payment date is in the future, payment is scheduled to pay on that date. If Payment date is in
                    the past, the invoice has been paid to the carrier.
                  </span>
                }
                wrapperClassname="pl-1 -mt-1"
                tooltipClassname="w-96"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            ) : null;

          return (
            <div className="flex" key={header}>
              <strong>{header}</strong>
              {toolTip}
            </div>
          );
        })}
      </div>
      {triumphPayInvoices.map((bill) => (
        <div className="mb-2 grid grid-cols-5 gap-x-4 gap-y-2" key={bill.external_key}>
          <span>{bill.pay_to}</span>
          <span>{bill.payment_type}</span>
          <span>{formatCurrency(bill.net_amount)}</span>
          <span>{moment(bill.payment_date).isValid() && moment(bill.payment_date).format('MM/DD/YY')}</span>
          <span>{bill.status}</span>
        </div>
      ))}
    </div>
  );
};

TriumphPayTable.propTypes = {
  triumphPayInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      pay_to: PropTypes.string,
      payment_type: PropTypes.string,
      net_amount: PropTypes.number,
      payment_date: PropTypes.string,
      status: PropTypes.string
    })
  ).isRequired
};

export default TriumphPayTable;
