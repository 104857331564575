import {Dispatch, SetStateAction} from 'react';
import {getName as getCountryName} from 'country-list';

import {Card, CollapsibleCardContent, DisplayValue, SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import {
  Shipment,
  PackageType,
  CustomField,
  ShipmentLineItem,
  PieceDetail
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CustomFields, getSelectedPackageType} from 'App/containers/Shipment/ShipmentLineItems';
import {fedexServiceOptions, upsServiceOptions, uspsServiceOptions} from 'App/utils/parcelConstants';
import {formatCurrency} from 'App/utils/internationalConstants';

type ModalState = {
  mode: string;
  index: number;
};

type ServicesOption = {
  id: string;
  name: string;
};

const getServiceOption = (servicesOptions: ServicesOption[], serviceCode: string) => {
  const result = servicesOptions.find((element) => element.id === serviceCode);
  return result?.name || '--';
};

const renderRefrigerationValues = (minTemp: number, maxTemp: number, tempUnit?: 'C' | 'F') => {
  if (!Number.isNaN(minTemp) && !Number.isNaN(maxTemp) && !!tempUnit)
    return `${minTemp}${tempUnit} to ${maxTemp}${tempUnit}`;
  if (!Number.isNaN(maxTemp) && !!tempUnit) return `${maxTemp}${tempUnit} max`;
  if (!Number.isNaN(minTemp) && !!tempUnit) return `${minTemp}${tempUnit} min`;
  return '--';
};

const isLineItem = (lineOrPiece: ShipmentLineItem | PieceDetail): lineOrPiece is ShipmentLineItem => {
  return 'total_packages' in lineOrPiece;
};

export const LineItemCard = ({
  index,
  lineItem,
  shipment,
  canEdit,
  packageTypes,
  setShowEditModal,
  setModalState,
  setLineItemIdToDelete,
  setShowLineItemDeleteModal,
  customFields
}: {
  index: number;
  lineItem: ShipmentLineItem | PieceDetail;
  shipment: Shipment;
  canEdit: boolean;
  packageTypes: PackageType[];
  setShowEditModal: (x: boolean) => void;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  setLineItemIdToDelete: Dispatch<SetStateAction<string>>;
  setShowLineItemDeleteModal: (x: boolean) => void;
  customFields?: CustomField[];
}) => {
  const totalWeight = () => {
    if (!isLineItem(lineItem)) {
      return lineItem.package_weight || '--';
    }
    return (
      parseFloat(String((lineItem.package_weight || 0) * (lineItem.total_packages || 0))).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '--'
    );
  };
  const packageWeight = () => {
    // if not line item, then we have a PieceDetail from a handling unit
    if (!isLineItem(lineItem)) {
      return lineItem.package_weight && lineItem.total_pieces ? lineItem.package_weight / lineItem.total_pieces : 0;
    }
    return lineItem?.package_weight && lineItem?.total_packages ? lineItem.package_weight * lineItem.total_packages : 0;
  };
  const packageWeightFormated = parseFloat(String(packageWeight())).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return (
    <Card
      title={
        <div className="flex min-w-0 items-baseline text-nowrap">
          <span>Item {index + 1}</span>
          <span className="text-grey-5 flex min-w-0 items-baseline text-xxs font-normal">
            <span className="mx-1">•</span>
            <span className="inline-block truncate">{lineItem.description}</span>
            <span className="mx-1">•</span>
            <span>
              {packageWeightFormated} {lineItem.weight_unit}
            </span>
          </span>
        </div>
      }
      actions={
        canEdit ? (
          <Dropdown
            icon={<SvgIcon name="Overflow" className="icon" color="$sw-icon" />}
            variant="activeIcon"
            indicator={false}
          >
            {() => (
              <>
                <li
                  onClick={() => {
                    setShowEditModal(true);
                    setModalState({
                      mode: 'edit',
                      index
                    });
                  }}
                >
                  Edit
                </li>
                {isLineItem(lineItem) ? (
                  <li
                    onClick={() => {
                      setLineItemIdToDelete(lineItem?.id || '');
                      setShowLineItemDeleteModal(true);
                    }}
                  >
                    Delete
                  </li>
                ) : null}
              </>
            )}
          </Dropdown>
        ) : null
      }
      draggableProvided={false}
      bodyClassName="p-4 empty:p-0"
      isCollapsed
      isCollapsible
    >
      <CollapsibleCardContent>
        <DisplayValue label="Description" className="mb-2">
          {lineItem.description}
        </DisplayValue>
        <div className="grid grid-cols-2">
          {isLineItem(lineItem) ? (
            <DisplayValue label="Quantity">{lineItem.total_packages ? lineItem.total_packages : '--'}</DisplayValue>
          ) : null}
          {'package_type' in lineItem ? (
            <DisplayValue label="Packaging">
              {getSelectedPackageType(lineItem.package_type, lineItem.provider_specific_packaging, packageTypes)}
            </DisplayValue>
          ) : null}
          <DisplayValue label="Package Weight">{`${Math.round(packageWeight() * 100) / 100}  ${
            lineItem.weight_unit || ''
          }`}</DisplayValue>
          <DisplayValue label="Total Weight">{`${totalWeight()}  ${lineItem.weight_unit || ''}`}</DisplayValue>
          <DisplayValue label="Dimensions">
            {`
                      ${lineItem.length || ''}
                      ${lineItem.length ? 'x' : ''}
                      ${lineItem.width || ''}
                      ${lineItem.width ? 'x' : ''}
                      ${lineItem.height || ''}
                      ${lineItem.length_unit || ''}
                    `}
          </DisplayValue>
          <DisplayValue label="Stackable">{lineItem.stackable ? 'Yes' : 'No'}</DisplayValue>
          <DisplayValue label="Freight Class">{lineItem.freight_class ? lineItem.freight_class : '--'}</DisplayValue>
          <DisplayValue label="Value per Piece">
            {lineItem.value_per_piece
              ? `${formatCurrency(lineItem.value_per_piece, lineItem.value_per_piece_currency)} ${
                  lineItem.value_per_piece_currency as string
                }`
              : '--'}
          </DisplayValue>
          <DisplayValue label="NMFC code">
            {lineItem.nmfc_item_code ? lineItem.nmfc_item_code : '--'}
            {lineItem.nmfc_sub_code ? `-${lineItem.nmfc_sub_code}` : ''}
          </DisplayValue>
          <DisplayValue label="Refrigeration">
            {lineItem.refrigeration_required
              ? renderRefrigerationValues(
                  lineItem.refrigeration_min_temp as number,
                  lineItem.refrigeration_max_temp as number,
                  lineItem.temp_unit
                )
              : '--'}
          </DisplayValue>
          <DisplayValue label="Number of pieces">
            {lineItem.total_pieces
              ? parseFloat(String(lineItem.total_pieces)).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              : '--'}
          </DisplayValue>
          <DisplayValue label="Piece Type">{lineItem.piece_type || '--'}</DisplayValue>
          <DisplayValue label="Country of Manufacture">
            {lineItem?.country_of_manufacture ? getCountryName(lineItem.country_of_manufacture) : '--'}
          </DisplayValue>
          {shipment && shipment.fedex_specific_options ? (
            <DisplayValue label="Shipping Method">
              {shipment.fedex_specific_options.service_code
                ? getServiceOption(fedexServiceOptions, shipment.fedex_specific_options.service_code)
                : '--'}
            </DisplayValue>
          ) : null}
          {shipment && shipment.ups_specific_options ? (
            <DisplayValue label="Shipping Method">
              {shipment.ups_specific_options.service_code
                ? getServiceOption(upsServiceOptions, shipment.ups_specific_options.service_code)
                : '--'}
            </DisplayValue>
          ) : null}
          {shipment && shipment.usps_specific_options ? (
            <DisplayValue label="Shipping Method">
              {shipment.usps_specific_options.service_code
                ? getServiceOption(uspsServiceOptions, shipment.usps_specific_options.service_code)
                : '--'}
            </DisplayValue>
          ) : null}
        </div>
        <DisplayValue label="Hazmat">
          {lineItem.hazmat_identification_number && lineItem.hazmat_hazard_class && lineItem.hazmat_proper_shipping_name
            ? `${lineItem.hazmat_identification_number} - ${lineItem.hazmat_hazard_class} - ${
                lineItem.hazmat_packing_group ? `${lineItem.hazmat_packing_group} - ` : ''
              } ${lineItem.hazmat_proper_shipping_name}`
            : '--'}
        </DisplayValue>
        {isLineItem(lineItem) ? (
          // @ts-expect-error wrong return type
          <CustomFields customFields={customFields} customData={lineItem.custom_data} />
        ) : null}
      </CollapsibleCardContent>
    </Card>
  );
};
