import {Column} from 'react-table';
import {RfpLaneCarrierOptResponse} from '@shipwell/backend-core-sdk';
import {toCurrency} from 'App/utils/grammar';

const possibleEmptyCell = (value?: string | number) => {
  if (typeof value === 'undefined') return '--';
  return value;
};

const percentCell = (value?: number | null) => {
  if (value === null || value === undefined) return '--';
  return `${value}%`;
};

const getRank = (value?: number | null) => {
  if (value === undefined || value === null) return '--';
  return value + 1;
};

export const columns: Column<RfpLaneCarrierOptResponse & {rank: number}>[] = [
  {
    id: 'rank',
    accessor: 'order_index',
    Header: 'Rank',
    Cell: ({value}) => <>{getRank(value)}</>,
    width: 75
  },
  {
    id: 'carrier',
    accessor: 'carrier_name',
    Header: 'Carrier'
  },
  {
    id: 'currentBid',
    accessor: 'current_bid',
    Header: 'Current Bid',
    Cell: ({value}) => <>{value ? toCurrency(value) : '--'}</>,
    width: 120
  },
  {
    id: 'bidBeforeCurrent',
    accessor: 'previous_bid',
    Header: 'Previous Bid',
    Cell: ({value}) => <>{value ? toCurrency(value) : '--'}</>,
    width: 140
  },
  {
    id: 'laneRuns',
    accessor: 'lane_runs',
    Header: 'Lane Runs',
    Cell: ({value}) => <>{possibleEmptyCell(value)}</>,
    width: 120
  },
  {
    id: 'onTime',
    accessor: 'on_time_delivery',
    Header: 'On Time %',
    Cell: ({value}) => <>{percentCell(value)}</>,
    width: 120
  },
  {
    id: 'acceptance',
    accessor: 'tender_acceptance',
    Header: 'Acceptance %',
    Cell: ({value}) => <>{percentCell(value)}</>,
    width: 140
  },
  {
    id: 'tracking',
    accessor: 'tracking_score',
    Header: 'Tracking %',
    Cell: ({value}) => <>{percentCell(value)}</>,
    width: 120
  }
];
