import {useState, useEffect, useCallback} from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {Formik, Field} from 'formik';
import {object, string} from 'yup';
import {find, get, uniq, without} from 'lodash';
import {
  Card,
  CollapsibleCardContent,
  Checkbox,
  DisplayValue,
  SvgIcon,
  DeprecatedButton,
  FormikTextarea,
  FormikSelect,
  Rule
} from '@shipwell/shipwell-ui';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import './styles.scss';
import {validateEmail} from 'App/utils/globals';
import {emailContexts} from 'App/formComponents/forms/sendShipmentDocuments/constants';

const contactTypes = {CARRIER: 'Carrier', STOP: 'Stop'};

const emailTemplateTypes = {
  UPDATE_DRIVER: 'UPDATE_DRIVER',
  UPDATE_POWER_UNIT: 'UPDATE_POWER_UNIT'
};
const emailTemplates = {
  [emailTemplateTypes.UPDATE_DRIVER]:
    'Shipment <shipmentId> is NOT TRACKING because the driver information is inaccurate or missing. Please update your driver mobile number to ensure tracking and on time delivery of the shipment.',
  [emailTemplateTypes.UPDATE_POWER_UNIT]:
    'Shipment <shipmentId> is NOT TRACKING because the Power Unit information is inaccurate or missing. Please update your Power Unit information to ensure tracking and on time delivery of the shipment.'
};

const EmailShipmentContacts = ({
  selectedDocuments,
  setSelectedDocuments,
  onChangeContacts,
  onChangeMessage,
  onChangeAdditionalEmails,
  shipment,
  addAttachment,
  selectedContacts,
  selectedAdditionalEmails,
  message,
  context
}) => {
  const [contactCards, setContactCards] = useState([]);
  const [selectedContactIds, setSelectedContactIds] = useState(selectedContacts.map((e) => e.id));
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    getAllShipmentContacts(shipment);
  }, [getAllShipmentContacts, shipment]);

  useEffect(() => {
    if (contactCards.length && !selectedContactIds.length && !initialLoaded) {
      //get contacts for the remaining stops
      getInitialSelectedContactIds(shipment, contactCards);
    }
  }, [shipment, contactCards, selectedContactIds, initialLoaded, getInitialSelectedContactIds]);

  useEffect(() => {
    if (contactCards.length) {
      //pull out users in each card that are selected and update parent with emails
      const allSelectedUsers = contactCards
        .map((card) => card.users)
        .reduce((prev, cur) => {
          return prev.concat(cur);
        })
        .filter((user) => selectedContactIds.includes(user.id));
      onChangeContacts(allSelectedUsers);
    }
  }, [selectedContactIds, contactCards, onChangeContacts]);

  const handleCardSelect = (e, card) => {
    if (e.target.checked) {
      //select all users on this card
      setSelectedContactIds(uniq([...selectedContactIds, ...card.users.map((e) => e.id)]));
    } else {
      setSelectedContactIds(without(selectedContactIds, ...card.users.map((e) => e.id)));
    }
  };

  const handleUserSelect = (e, user) => {
    if (e.target.checked) {
      setSelectedContactIds(uniq([...selectedContactIds, user.id]));
    } else {
      setSelectedContactIds(without(selectedContactIds, user.id));
    }
  };

  const checkAllContactsSelected = (card) => {
    return card.users?.length > 0 && card.users.every((user) => selectedContactIds.includes(user.id));
  };

  const getStopLocationTitle = (stop) => {
    let string = '';
    if (stop.location?.company_name) {
      string += `${stop.location?.company_name}, `;
    }
    if (stop.location?.address?.city) {
      string += `${stop.location?.address?.city}, `;
    }
    if (stop.location?.address?.state_province) {
      string += `${stop.location?.address?.state_province}`;
    }
    return string;
  };

  const getStopPOCs = (stop) => {
    const POCs = stop?.location?.point_of_contacts || [];
    return POCs;
  };

  const getAllShipmentContacts = useCallback(async (shipment) => {
    const contacts = [];
    if (shipment.relationship_to_vendor?.vendor_point_of_contact) {
      const response = await getCarrierRelationshipsPromise({
        vendorId: shipment.relationship_to_vendor.vendor.id,
        carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']
      });
      const carrier = get(response, 'body.results[0]');
      const poc = find(carrier?.point_of_contacts, {id: shipment.relationship_to_vendor.vendor_point_of_contact});
      if (poc) {
        contacts.push({
          id: shipment.relationship_to_vendor.vendor.id,
          type: contactTypes.CARRIER,
          title: (
            <span className="emailShipmentContacts__cardTitle">
              <span className="emailShipmentContacts__cardTitle-type">{contactTypes.CARRIER} • </span>
              <span className="emailShipmentContacts__cardTitle-details">
                {shipment.relationship_to_vendor.vendor.name}
              </span>
            </span>
          ),
          users: [poc]
        });
      }
    }
    shipment.stops.forEach((stop) => {
      contacts.push({
        id: stop.id,
        type: contactTypes.STOP,
        title: (
          <span className="emailShipmentContacts__cardTitle">
            <span className="emailShipmentContacts__cardTitle-type">{`${contactTypes.STOP} ${
              stop.ordinal_index + 1
            } • `}</span>
            <span className="emailShipmentContacts__cardTitle-details">{getStopLocationTitle(stop)}</span>
          </span>
        ),
        users: getStopPOCs(stop)
      });
    });

    setContactCards(contacts);
  }, []);

  const getInitialSelectedContactIds = useCallback(
    (shipment, contactsCards) => {
      const remainingStopIds = shipment.next_planned_stop_id
        ? shipment.stops
            .slice(shipment.stops.findIndex((stop) => stop.id === shipment.next_planned_stop_id))
            .map((e) => e.id)
        : [];
      const remainingStopContactIds =
        remainingStopIds.length > 0
          ? contactsCards
              .filter((contact) => remainingStopIds.includes(contact.id))
              .map((card) => card.users)
              .reduce((prev, cur) => {
                return prev.concat(cur);
              })
              .map((user) => user.id)
          : [];
      const carrierContactIds =
        contactsCards.find((card) => card.type === contactTypes.CARRIER)?.users.map((user) => user.id) || [];
      if (context === emailContexts.NOT_TRACKING_ACTION) {
        //only preselect carrier contacts for the not tracking action
        setSelectedContactIds([...carrierContactIds]);
      } else {
        setSelectedContactIds([...remainingStopContactIds, ...carrierContactIds]);
      }
      setInitialLoaded(true);
    },
    [context]
  );

  const removeDocument = (documentId) => {
    setSelectedDocuments(
      without(
        selectedDocuments.map((e) => e.id),
        documentId
      )
    );
  };

  const handleMessageChange = (value, setFieldValue) => {
    setFieldValue('message', value);
    if (onChangeMessage) {
      onChangeMessage(value);
    }
  };

  const handleAdditionalEmailChange = (value, setFieldValue) => {
    setFieldValue('additional_emails', value);
    if (onChangeAdditionalEmails) {
      onChangeAdditionalEmails(value);
    }
  };

  const handleMessageTemplateChange = (value, setFieldValue) => {
    setFieldValue('messageTemplate', value);
    handleMessageChange(
      emailTemplates[value] ? emailTemplates[value].replace('<shipmentId>', shipment.reference_id) : '',
      setFieldValue
    );
  };

  return (
    <>
      <div className="mb-4 font-bold">Shipment Contacts</div>
      {contactCards.map((card) => {
        return (
          <Card
            key={card.id}
            title={
              <>
                <div className="mr-2">
                  <Checkbox
                    disabled={!card.users.length}
                    checked={checkAllContactsSelected(card)}
                    onChange={(e) => handleCardSelect(e, card)}
                  />
                </div>
                {card.title}
              </>
            }
            className="emailShipmentContacts__card mb-2"
            isCollapsible={card.users?.length > 0}
            isCollapsed
            actions={
              <span className="emailShipmentContacts__cardTitle-numContacts">{`${card.users.length || 'No'} ${pluralize(
                'contact',
                card.users.length
              )}`}</span>
            }
          >
            <CollapsibleCardContent>
              {card.users.map((user) => {
                return (
                  <div key={user.id} className="emailShipmentContacts__card-user">
                    <div className="mr-2">
                      <Checkbox
                        checked={selectedContactIds.includes(user.id)}
                        onChange={(e) => handleUserSelect(e, user)}
                      />
                    </div>
                    <DisplayValue className="emailShipmentContacts__card-user-detail" label="Name">{`${
                      user.first_name
                    } ${user.last_name || ''}`}</DisplayValue>
                    <DisplayValue className="emailShipmentContacts__card-user-detail" label="Phone">
                      {user.phone_number ? getPhoneHyperlink(user.phone_number) : '--'}
                    </DisplayValue>
                    <DisplayValue className="emailShipmentContacts__card-user-detail" label="Email">
                      {user.email || '--'}
                    </DisplayValue>
                  </div>
                );
              })}
            </CollapsibleCardContent>
          </Card>
        );
      })}
      <div className="mb-2">
        <Formik
          initialValues={{
            message: message,
            additional_emails: selectedAdditionalEmails
          }}
          validationSchema={object().shape({message: string().required('Message is required.')})}
        >
          {({setFieldValue, values}) => (
            <>
              <Field
                component={FormikSelect}
                className="mb-6"
                label="Additional Email Contact(s)"
                isMulti
                isClearable
                simpleValue
                allowCreate
                noOptionsMessage={() => {
                  return 'Enter a valid email.';
                }}
                options={[]}
                formatOptionLabel={(option) => {
                  if (option?.__isNew__) {
                    return option.value;
                  }
                  return option;
                }}
                getOptionValue={(option) => option}
                isValidNewOption={(inputValue) => {
                  return validateEmail(inputValue) && !values.additional_emails?.includes(inputValue);
                }}
                name="additional_emails"
                onChange={(value) => handleAdditionalEmailChange(value, setFieldValue)}
              />
              <Rule />
              <div className="mb-4 mt-2 font-bold">Email Message</div>

              <Field
                component={FormikSelect}
                simpleValue
                className="mb-2"
                label="Select Email Message Template"
                name="messageTemplate"
                options={[
                  {label: 'Carrier request to update driver information', value: emailTemplateTypes.UPDATE_DRIVER},
                  {
                    label: 'Carrier request to update Power Unit information',
                    value: emailTemplateTypes.UPDATE_POWER_UNIT
                  }
                ]}
                onChange={(value) => handleMessageTemplateChange(value, setFieldValue)}
              />
              <Field
                component={FormikTextarea}
                label="Enter a custom message here"
                name="message"
                required
                onChange={(e) => handleMessageChange(e.target.value, setFieldValue)}
              />
            </>
          )}
        </Formik>
      </div>
      <div className="emailShipmentContacts__attachments">
        <div className="emailShipmentContacts__attachments-selected">
          {selectedDocuments.length > 0 &&
            selectedDocuments.map((document) => {
              return (
                <div className="emailShipmentContacts__attachments-document" key={document.id}>
                  <SvgIcon name="Attachment" color="$sw-icon" />
                  <span className="emailShipmentContacts__attachments-documentDescription">
                    {document.description || document.filename}
                  </span>
                  <DeprecatedButton
                    variant="icon"
                    onClick={() => removeDocument(document.id)}
                    icon={<SvgIcon name="CloseCircleOutlined" color="$sw-disabled" />}
                  />
                </div>
              );
            })}
        </div>
        <DeprecatedButton onClick={addAttachment} variant="tertiary" icon={<SvgIcon name="AddCircleOutlined" />}>
          Add Attachment
        </DeprecatedButton>
      </div>
    </>
  );
};

EmailShipmentContacts.propTypes = {
  selectedDocuments: PropTypes.array,
  setSelectedDocuments: PropTypes.func,
  onChangeContacts: PropTypes.func,
  onChangeMessage: PropTypes.func,
  onChangeAdditionalEmails: PropTypes.func,
  shipment: PropTypes.object,
  addAttachment: PropTypes.func,
  selectedContacts: PropTypes.array,
  selectedAdditionalEmails: PropTypes.array,
  message: PropTypes.string,
  context: PropTypes.string
};

export default EmailShipmentContacts;
