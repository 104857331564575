import isNil from 'lodash/isNil';
import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import {Hazmat} from '@shipwell/backend-core-singlerequestparam-sdk';
import classNames from 'classnames';
import {ProductHazmat, LineItemsHazmat, FormValues, packingGroupOptions, isHazmat} from './utils';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';

type HazmatCodesFieldsProps = {onChange: (value: Hazmat | null) => void} & (
  | {index?: undefined; prepend?: undefined}
  | {index: number; prepend: keyof LineItemsHazmat}
);

const isProductValues = (value: FormValues): value is ProductHazmat => 'hazmat_identification_number' in value;

const HazmatCodesFields = ({index, onChange, prepend}: HazmatCodesFieldsProps) => {
  const isLineItem = !isNil(index) && !isNil(prepend);
  const {values} = useFormikContext<FormValues>();

  const isProductHazmat = isProductValues(values);

  const lineItem = !isProductHazmat && isLineItem ? values[prepend][index] : null;

  const formattedValues = isProductHazmat
    ? {
        hazmat_identification_number: values.hazmat_identification_number,
        hazmat_proper_shipping_name: values.hazmat_proper_shipping_name,
        hazmat_hazard_class: values.hazmat_hazard_class,
        hazmat_packing_group: values.hazmat_packing_group,
        hazmat_required: values.hazmat_required
      }
    : lineItem
    ? {
        hazmat_identification_number: lineItem.hazmat_identification_number,
        hazmat_proper_shipping_name: lineItem.hazmat_proper_shipping_name,
        hazmat_hazard_class: lineItem.hazmat_hazard_class,
        hazmat_packing_group: lineItem.hazmat_packing_group,
        hazmat_required: lineItem.hazmat_required
      }
    : {};

  const valuesString = [
    formattedValues.hazmat_identification_number,
    formattedValues.hazmat_proper_shipping_name,
    formattedValues.hazmat_hazard_class,
    formattedValues.hazmat_packing_group
  ]
    .filter((val) => !!val)
    .join(' - ');

  const getOptionLabel = (option: Hazmat | ProductHazmat) => {
    const optionString = isHazmat(option)
      ? [option.identification_number, option.proper_shipping_name, option.hazard_class, option.packing_group]
      : [
          option.hazmat_identification_number,
          option.hazmat_proper_shipping_name,
          option.hazmat_hazard_class,
          option.hazmat_packing_group
        ];
    return optionString.filter((val) => !!val).join(' - ');
  };

  const formatOptionLabel = (option: Hazmat | ProductHazmat, {context}: {context: 'menu' | 'value'}) => {
    return context === 'value' ? valuesString : getOptionLabel(option);
  };

  const {hazmatOptions, isLoading} = useHazmatCodes();

  const namePrepend = isLineItem ? `${prepend}[${index}].` : '';

  const hazmatRequired = formattedValues.hazmat_required;

  return (
    <div className={classNames({'flex flex-col gap-y-[20px]': !isNil(index)})}>
      <Field
        component={FormikSelect}
        name={`${namePrepend}hazmat_details`}
        label="Hazmat Code"
        options={hazmatOptions}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        clearable
        onChange={onChange}
        disabled={isLoading}
      />
      <div className="grid grid-cols-3 gap-x-[10px]">
        <Field
          name={`${namePrepend}hazmat_identification_number`}
          label="Identification Number"
          component={FormikTextInput}
          required={hazmatRequired}
        />
        <Field
          name={`${namePrepend}hazmat_hazard_class`}
          label="Hazard Class"
          component={FormikTextInput}
          required={hazmatRequired}
        />
        <Field
          simpleValue
          name={`${namePrepend}hazmat_packing_group`}
          label="Packing Group"
          component={FormikSelect}
          options={packingGroupOptions}
          required={hazmatRequired}
        />
      </div>
      <Field
        name={`${namePrepend}hazmat_proper_shipping_name`}
        label="Proper Shipping Name"
        component={FormikTextInput}
        required={hazmatRequired}
      />
    </div>
  );
};

export default HazmatCodesFields;
