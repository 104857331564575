import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {DeprecatedButton, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import PageHeader from 'App/common/pageHeader';
import {serviceLevelAgreements} from 'App/api';
import ServiceLevelAgreementCreateContainer from 'App/containers/serviceLevelAgreements/create';
import './styles.scss';

const SLADetails = (props) => {
  const [editing, setEditing] = useState(true);
  const [SLA, setSLA] = useState(null);
  const [fetchingSLA, setFetchingSLA] = useState(false);
  const [cloningSLA, setCloningSLA] = useState(false);
  const [deletingSLA, setDeletingSLA] = useState(false);

  const {params, router, canDeleteContracts, setError, setSuccess} = props;

  useEffect(() => {
    if (params.slaId) {
      getSLADetails(params.slaId);
    }
  }, []);

  /**
   * Request SLA details
   * @param {Number} slaId
   */
  const getSLADetails = async (slaId) => {
    setFetchingSLA(true);
    try {
      const response = await serviceLevelAgreements.getSLADetails(slaId);
      if (response) {
        setSLA(response.body);
      }
      setFetchingSLA(false);
    } catch (error) {
      console.error(error);
    }
  };

  /** Cancel update */
  const handleCancel = () => {
    router.push('/company/lane-management/service-level-agreements');
  };

  const handleSaveSuccess = () => {
    getSLADetails(SLA.id);
    setSuccess('SLA Updated!', 'Your updates will be reflected on any contracts using this SLA.');
  };
  const handleCloneSuccess = (shouldClone = false) => {
    setCloningSLA(null);
    setSuccess('SLA Saved!', 'Your SLA was saved to your SLA list.');

    if (shouldClone) {
      setCloningSLA(shouldClone);
    }
  };

  const handleDeleteSLA = async (SLA) => {
    try {
      const response = await serviceLevelAgreements.deleteSLA(SLA.id);
      if (response && response.statusCode === 204) {
        router.push(`/company/lane-management/service-level-agreements/?showDeleteSuccess=${SLA.name}`);
      }
    } catch (error) {
      setError('SLA Not Deleted.', error.error_description);
    }
  };

  return (
    <>
      {editing ? (
        <div className="sla__wrapper">
          <PageHeader
            title={SLA ? SLA.name : 'Service Level Agreement'}
            backArrow
            actions={
              <DeprecatedButton
                onClick={() => setCloningSLA({...SLA, name: 'Copy of ' + SLA.name})}
                variant="tertiary"
                icon={<SvgIcon name="Copy" />}
              >
                Clone SLA
              </DeprecatedButton>
            }
          />
          {fetchingSLA ? (
            <ShipwellLoader loading />
          ) : (
            <div className="sla__details">
              <ServiceLevelAgreementCreateContainer
                onSubmitSuccess={handleSaveSuccess}
                cloningSLA={cloningSLA}
                isEdit
                values={{
                  ...SLA,
                  //formik can't handle a value of 0 which is needed for the percentage. so convert to string
                  minimum_acceptance_percent: String(SLA?.minimum_acceptance_percent),
                  minimum_on_time_pickup_percent: String(SLA?.minimum_on_time_pickup_percent),
                  minimum_on_time_delivery_percent: String(SLA?.minimum_on_time_delivery_percent)
                }}
                onCancel={handleCancel}
                setError={setError}
              />
              {canDeleteContracts && (
                <div className="sla__details-footer">
                  <DeprecatedButton onClick={() => setDeletingSLA(SLA)} variant="warning">
                    Delete SLA
                  </DeprecatedButton>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
      <Modal
        variant="warning"
        show={Boolean(deletingSLA)}
        title="Delete Service Level Agreement"
        primaryBtnName="Delete SLA"
        onClose={() => setDeletingSLA(null)}
        onPrimaryAction={() => handleDeleteSLA(deletingSLA)}
      >
        <p>{`Are you sure you want to delete ${deletingSLA?.name}?`}</p>
      </Modal>
      <Modal
        show={cloningSLA}
        title={cloningSLA ? 'Clone Service Level Agreement' : 'Add a Service Level Agreement'}
        footerComponent={null}
        onClose={() => setCloningSLA(null)}
      >
        <ServiceLevelAgreementCreateContainer
          {...props}
          cloningSLA={cloningSLA}
          onCancel={() => setCloningSLA(null)}
          onSubmitSuccess={handleCloneSuccess}
          setError={setError}
        />
      </Modal>
    </>
  );
};
export default connect((state) => ({
  canDeleteContracts:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.delete_contracts')
}))(SLADetails);
