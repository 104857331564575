import {NetsuiteSuiteAppConfig} from '@shipwell/backend-core-sdk';
import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {getSuiteAppConfig} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useGetSuiteAppConfig = (options?: UseQueryOptions<NetsuiteSuiteAppConfig>) => {
  const getSuiteAppConfigQuery = useQuery(
    [SUITE_APP_CONFIG_QUERY] as QueryKey,
    async () => {
      const response = await getSuiteAppConfig();
      return response.data;
    },
    {retry: false, ...options}
  );
  return {
    suiteAppConfig: getSuiteAppConfigQuery?.data,
    isSuiteAppConfigLoading: getSuiteAppConfigQuery.isInitialLoading || getSuiteAppConfigQuery.isFetching
  };
};
