import {useMutation} from '@tanstack/react-query';
import {
  CreateShipmentTimelineEvent,
  ShipmentTimelineEvent,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {updateTimelineEventDetails} from 'App/api/shipment/typed';
import {GROUPED_TIMELINE_EVENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useUpdateTimelineEvent = (shipmentId: string, queryOptions = {}) => {
  const queryKey = [GROUPED_TIMELINE_EVENTS_QUERY_KEY, shipmentId];
  const createOptimisticUpdateHandlers = useOptimisticUpdate<
    ShipmentTimelineEvent,
    ShipwellError,
    {eventId: string; payload: CreateShipmentTimelineEvent}
  >();
  const updateEventMutation = useMutation(
    ({eventId, payload}: {eventId: string; payload: CreateShipmentTimelineEvent}) =>
      updateTimelineEventDetails({shipmentId, shipmentTimelineEventId: eventId, createShipmentTimelineEvent: payload}),
    createOptimisticUpdateHandlers(queryKey, queryOptions)
  );
  return updateEventMutation;
};
