import {
  FreightInvoiceDocumentsMetadata,
  InvoicingApi,
  ShipmentsApi,
  Configuration,
  CarrierAssignment
} from '@shipwell/backend-core-singlerequestparam-sdk';
import axios from 'axios';
import omit from 'lodash/omit';
import {getAccessToken} from 'App/api/utils';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';
import {FreightInvoiceFormData} from 'App/containers/settlements/freightInvoices/types';

//these routes are exposed via backend-core, and use the backend-core base path.
const basePath = process.env.SHIPWELL_API_BASE_PATH;

function createInvoicingApi() {
  return new InvoicingApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

function createShipmentsApi() {
  return new ShipmentsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

const invoicingApi = createInvoicingApi();

export async function createFreightInvoicePost(
  shipmentId: string,
  data: FreightInvoiceFormData,
  documents?: Array<File>,
  documentsMetadata?: FreightInvoiceDocumentsMetadata,
  options = {}
) {
  //we have to use this plain js utility,
  //as auth0 sets resolveAccessToken for this instance of the function.
  const accessToken = (await getAccessToken()) as string;
  //this route can receive a file, so the request is structured as formData.
  //We call axios.post() directly, because the default API wrapper will submit the parameters as a binary string.
  return axios.post(`${basePath || ''}/invoicing/shipments/${shipmentId}/freight-invoices/`, options, {
    transformRequest: [
      () => {
        const formData = new FormData();
        const requestPayloadWithEmptyKeysOmitted = omit(
          omitEmptyKeysWithEmptyObjectsRemoved(data),
          'document_metadata'
        );
        formData.append('data', JSON.stringify(requestPayloadWithEmptyKeysOmitted));
        documents?.map((document) => formData.append('documents', document));
        if (documentsMetadata?.document_metadata && documentsMetadata.document_metadata.length > 0) {
          formData.append('documents_metadata', JSON.stringify(documentsMetadata));
        }
        return formData;
      }
    ],
    headers: {
      authorization: accessToken
    }
  });
}

export async function getCarrierAssignment(shipmentId: string): Promise<CarrierAssignment> {
  const api = createShipmentsApi();
  const {data} = await api.shipmentsShipmentIdCarrierAssignmentsGet({shipmentId});

  return data;
}

export function getCarrierAssignmentById(shipmentId: string, carrierAssignmentId: string) {
  return createShipmentsApi().shipmentsShipmentIdCarrierAssignmentsCarrierAssignmentIdGet({
    shipmentId,
    carrierAssignmentId
  });
}

export function updateCarrierAssignment(
  shipmentId: string,
  carrierAssignmentId: string,
  carrierAssignment: CarrierAssignment
) {
  return createShipmentsApi().shipmentsShipmentIdCarrierAssignmentsCarrierAssignmentIdPut({
    shipmentId,
    carrierAssignmentId,
    carrierAssignment
  });
}

export function getInvoiceRuleConfigurations(
  shipmentId: string,
  invoiceSource: 'carrier_generated' | 'shipper_generated'
) {
  return invoicingApi.invoicingShipmentsShipmentIdInvoiceRuleConfigurationsGet({shipmentId, invoiceSource});
}

export async function getQuickbooksItems(params = {}, opts = {}) {
  return invoicingApi.invoicingIntegrationsQuickbooksItemsGet(params, opts);
}
