import {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {browserHistory} from 'react-router';
import {compose} from 'recompose';
import {
  DeprecatedButton,
  Card,
  Modal,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  ThemeProvider,
  theme,
  SvgIcon
} from '@shipwell/shipwell-ui';
import ConfirmDelete from '../confirmDelete';
import FuelSurchargeEditCriteriaForm from 'App/containers/userCompany/fuelSurcharges/details/editCriteriaForm';
import FuelSurchargeEditRateTableForm from 'App/containers/userCompany/fuelSurcharges/details/editRateTableForm';
import DOERateHistory from 'App/containers/userCompany/fuelSurcharges/details/doeRateHistory';
import PageHeader from 'App/common/pageHeader';
import {getFuelSurchargeTable, updateFuelSurchargeTable} from 'App/api/rateTables';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import {formatAddresses} from 'App/containers/workflows/utils';
import {makeFuelSurchargeRateTableRows} from 'App/containers/userCompany/fuelSurcharges/utils';
import Loader from 'App/common/shipwellLoader';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Table from 'App/components/Table';
import Error404Page from 'App/common/Error404Page2';
import withFlags from 'App/utils/withFlags';
import {RATE_TYPE_PER_MILE} from 'App/containers/userCompany/rateTables/constants';
import './styles.scss';

const FuelSurchargeTableDetails = ({router, params, setSuccess, setError}) => {
  const tabs = ['Criteria', 'Rate Table'];
  const [fuelSurchargeTable, setFuelSurchargeTable] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditRateTable, setShowEditRateTable] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Criteria');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFuelSurchargeTable = async (id) => {
      setIsLoading(true);
      try {
        const response = await getFuelSurchargeTable(id);
        setFuelSurchargeTable({...response.body});
      } catch (error) {
        console.error('Error retrieving fuel surcharge table', error);
        setError('Error!', `Error retrieving fuel surcharge table. ${get(error, 'error_description')}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFuelSurchargeTable(params.fuelSurchargeTableId);
  }, [params.fuelSurchargeTableId, setError]);

  const handleCancel = () => browserHistory.goBack();

  const handleUpdateFuelSurchargeTable = (response) => {
    const newFuelSurchargeTable = response.body;
    setSuccess('Success!', `Fuel surcharge table '${newFuelSurchargeTable.name}' was updated`);

    setFuelSurchargeTable({...newFuelSurchargeTable});
  };

  const handleError = (error) => {
    setError(
      'Error!',
      `Error updating fuel surcharge table '${fuelSurchargeTable.name}' ${get(error, 'error_description')}`
    );
  };

  const handleDeleteSuccess = () => {
    setSuccess('Success!', `Fuel surcharge table "${fuelSurchargeTable.name}" was removed.`);
    setShowDeleteConfirmation(false);
    router.push('/company/lane-management/fuel-surcharges/');
  };

  const handleSubmitUpdateRateTable = async (values) => {
    const payload = {
      ...values,
      carrier_relationships: fuelSurchargeTable.carrier_relationships,
      locations: formatAddresses(fuelSurchargeTable.locations)
    };
    try {
      const response = await updateFuelSurchargeTable(values.id, payload);
      if (response.ok) {
        handleUpdateFuelSurchargeTable(response);
        setShowEditRateTable(false);
      }
    } catch (error) {
      console.error('Error updating fuel surcharge table', error);
      handleError(error);
    }
  };

  const ThemedTabs = (props) => (
    <ThemeProvider theme={theme}>
      <Tabs {...props} />
    </ThemeProvider>
  );

  const fuelSurchargeTableData = useMemo(
    () => [...makeFuelSurchargeRateTableRows(fuelSurchargeTable)],
    [fuelSurchargeTable]
  );

  const fuelSurchargeTableColumns = useMemo(
    () => [
      {
        Header: 'DOE Rate (from)',
        accessor: 'doe_from'
      },
      {
        Header: 'DOE Rate (to)',
        accessor: 'doe_to'
      },
      {
        Header: `Surcharge (${
          fuelSurchargeTable?.rate_type === RATE_TYPE_PER_MILE ? 'Per Mile' : 'Percent of Line Haul'
        })`,
        accessor: 'surcharge'
      }
    ],
    [fuelSurchargeTable]
  );

  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <>
      <div className="fuelSurchargeTable__details-container">
        <PageHeader title={get(fuelSurchargeTable, 'name', 'Fuel Surcharge Table')} backArrow />
        <div className="fuelSurchargeTable__details-wrapper m-auto">
          <ThemedTabs selectedIndex={tabs.indexOf(selectedTab)} onSelect={(index) => setSelectedTab(tabs[index])}>
            <TabList className="pb-4">
              {tabs.map((tab) => (
                <Tab key={tab}>{tab}</Tab>
              ))}
            </TabList>
            <TabPanel>
              <FuelSurchargeEditCriteriaForm
                onCancel={handleCancel}
                onSubmit={handleUpdateFuelSurchargeTable}
                onError={handleError}
                values={fuelSurchargeTable}
              />
              <div className="fuelSurchargeTable__details-delete">
                <DeprecatedButton
                  variant="warning"
                  onClick={() => setShowDeleteConfirmation(true)}
                  disabled={!get(fuelSurchargeTable, 'id')}
                >
                  Delete Fuel Surcharge Table
                </DeprecatedButton>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="col-end-auto grid grid-cols-1fr-24 gap-2">
                <Card
                  title="Fuel Surcharge Rates"
                  bodyClassName="p-0"
                  actions={
                    <DeprecatedButton variant="icon" onClick={() => setShowEditRateTable(true)}>
                      <SvgIcon name="Edit" />
                    </DeprecatedButton>
                  }
                >
                  <Table columns={fuelSurchargeTableColumns} data={fuelSurchargeTableData} />
                </Card>
                <div className="w-96">
                  <Card title="DOE Rate">
                    <DOERateHistory fuelSurchargeTable={fuelSurchargeTable} />
                  </Card>
                </div>
              </div>
            </TabPanel>
          </ThemedTabs>
        </div>
      </div>
      <Modal
        title={fuelSurchargeTable.name}
        show={showEditRateTable}
        onClose={() => setShowEditRateTable(false)}
        size="large"
      >
        <FuelSurchargeEditRateTableForm
          fuelSurchargeTable={fuelSurchargeTable}
          onSubmit={handleSubmitUpdateRateTable}
          onCancel={() => setShowEditRateTable(false)}
        />
      </Modal>
      <ConfirmDelete
        show={showDeleteConfirmation}
        fuelSurchargeTable={fuelSurchargeTable}
        onSuccess={handleDeleteSuccess}
        onClose={() => setShowDeleteConfirmation(false)}
      />
    </>
  );
};

FuelSurchargeTableDetails.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  params: PropTypes.shape({
    fuelSurchargeTableId: PropTypes.string.isRequired
  }).isRequired,
  ...WithStatusToastsPropTypes
};

FuelSurchargeTableDetails.defaultProps = {
  router: {
    push: () => {}
  },
  ...WithStatusToastsDefaultProps
};

export default compose(
  withFlags('fuelSurchargeRateTable'),
  withConditionalFallback(({fuelSurchargeRateTable}) => fuelSurchargeRateTable === undefined, Loader),
  withConditionalFallback(({fuelSurchargeRateTable}) => !fuelSurchargeRateTable, Error404Page),
  withStatusToasts
)(FuelSurchargeTableDetails);
