import {
  ScheduleShipmentPickupRequest,
  ShipmentPickup,
  ShippingDashboardRow,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikHelpers} from 'formik';
import {browserHistory} from 'react-router';
import noop from 'lodash/noop';
import {
  containsParcelShipment,
  isCompatibleParcelShipments
} from '../../components/BulkActions/Dropdown/DropdownLineItemButton/utils';
import {useCreateUpdateShipmentPickup} from 'App/data-hooks/parcel/useCreateUpdateShipmentPickup';
import {unpackShipwellErrorForFormik} from 'App/utils/globalsTyped';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getFedexCarrierCodeFromServiceLevel} from 'App/data-hooks/parcel/utils';
import {getPickupValues} from 'App/utils/parcelConstants';

export const useBulkActionsParcelPickup = ({
  selectedShipmentIds,
  selectedShipments,
  setError
}: {selectedShipmentIds: string[]; selectedShipments: ShippingDashboardRow[]} & Pick<
  WithStatusToastProps,
  'setError'
>) => {
  const handlePickupSuccess = (data: ShipmentPickup) => {
    if (data.id) {
      browserHistory.push(`/pickup/${data.id}/manifest`);
    }
  };

  const handleParcelError = (parcelError?: ShipwellError) => {
    if (parcelError && parcelError.error && parcelError.error_description) {
      try {
        // try to format fedex errors if present
        const {errors} = JSON.parse(parcelError.error_description) as {errors?: Array<{detail?: string}>};
        if (errors) {
          setError(
            parcelError.error,
            <ul>
              {errors.map((error, i) => (
                <li key={`error-${i}`}>{error.detail}</li>
              ))}
            </ul>
          );
        }
      } catch {
        // else show raw errors from backend
        setError(parcelError.error, parcelError.error_description);
      }
    }
  };

  const {createShipmentPickup, updateShipmentPickupAsync} = useCreateUpdateShipmentPickup();

  const handleCreateShipmentPickup = (
    values: ScheduleShipmentPickupRequest,
    {setSubmitting, setErrors}: FormikHelpers<ScheduleShipmentPickupRequest>
  ) =>
    createShipmentPickup(
      {
        ...values,
        // only fedex uses a provider_carrier_code
        ...(selectedShipments[0]?.current_carrier?.name?.toLowerCase() === 'fedex'
          ? {
              provider_carrier_code: getFedexCarrierCodeFromServiceLevel(
                selectedShipments[0]?.calculated_ui_service_level as string
              )
            }
          : {}),
        shipments: selectedShipmentIds
      },
      {
        onSuccess: handlePickupSuccess,
        onSettled: () => setSubmitting(false),
        onError: ({response: {data: error} = {}}) => {
          if (error) {
            console.error(error);
            setErrors(unpackShipwellErrorForFormik(error));
            handleParcelError(error);
          }
        }
      }
    );

  const handleUpdateShipmentPickup = async (pickup: ShipmentPickup) => {
    // there is a try/catch because this needs to use mutateAsync
    try {
      await updateShipmentPickupAsync(
        {
          shipmentPickupId: pickup.id,
          shipmentPickup: {...pickup, shipments: selectedShipmentIds}
        },
        {
          onSuccess: handlePickupSuccess,
          onError: ({response: {data} = {}}) => {
            console.error(data);
            handleParcelError(data);
          }
        }
      );
    } catch {
      // we already handle the error in the mutation
      noop();
    }
  };

  return {
    handleCreateShipmentPickup,
    handleUpdateShipmentPickup,
    providerCode: selectedShipments[0]?.current_carrier?.name.toUpperCase(),
    containsParcel: containsParcelShipment(selectedShipments),
    isSchedulePickupDisabled: !isCompatibleParcelShipments(selectedShipments),
    createPickupFormValues: getPickupValues(selectedShipments[0])
  };
};
