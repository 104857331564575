import {ReactNode} from 'react';
import classNames from 'classnames';
import {Link, browserHistory} from 'react-router';
import {SvgIcon} from '@shipwell/shipwell-ui';
import './styles.scss';

const PageHeader = ({
  title,
  actions,
  backRoute,
  children,
  backArrow = false,
  className
}: {
  title: ReactNode;
  actions?: ReactNode;
  backRoute?: string;
  children?: ReactNode;
  backArrow?: boolean;
  className?: string;
}) => (
  <header className={classNames('sw-page-header', className)}>
    {title && (
      <h2 className="flex flex-row">
        {(backRoute || backArrow) && (
          <Link to="" onClick={() => (backRoute ? browserHistory.push(backRoute) : browserHistory.goBack())}>
            <SvgIcon name="ArrowBack" />
          </Link>
        )}{' '}
        {title}
      </h2>
    )}
    <div className="sw-page-search">{children}</div>
    {actions && <div className="sw-page-actions">{actions}</div>}
  </header>
);

export default PageHeader;
