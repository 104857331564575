import {Button, FormikFileSelect, FormikSelect, FormikTextarea} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {DocumentType} from '@shipwell/backend-core-sdk';
import {FreightInvoiceShipmentDocument} from '../../types';
import {FreightInvoiceDocumentSchema} from './validationSchema';
import {useDocumentTypes} from 'App/data-hooks';

const FreightInvoiceDocumentDefaultValues = {
  document_type: null,
  description: null,
  file: null,
  add_to_invoice: true
};

interface NewDocumentFormProps {
  onCancel: () => void;
  onSubmit: (
    newDocument: FreightInvoiceShipmentDocument,
    {setSubmitting}: FormikHelpers<FreightInvoiceShipmentDocument>
  ) => void;
}

const NewDocumentForm = ({onCancel, onSubmit}: NewDocumentFormProps) => {
  const {data: documentTypeData} = useDocumentTypes();
  return (
    <Formik
      validationSchema={FreightInvoiceDocumentSchema}
      initialValues={FreightInvoiceDocumentDefaultValues}
      onSubmit={onSubmit}
    >
      {({isSubmitting}) => (
        <Form noValidate>
          <div className="mt-2 flex flex-col gap-4 p-2">
            <Field component={FormikFileSelect} name="file" role="input" />
            <Field
              component={FormikSelect}
              name="document_type"
              label="Document Type"
              clearable={false}
              options={documentTypeData}
              simpleValue
              getOptionValue={(option: DocumentType) => option.id}
              getOptionLabel={(option: DocumentType) => option.name}
              required
            />
            <Field label="Description" name="description" component={FormikTextarea} required />
            <div className="flex justify-end gap-2">
              <Button variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
                Upload
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default NewDocumentForm;
