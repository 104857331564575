import {ShipmentLineItemDetail} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation} from '@tanstack/react-query';
import {consolidateLineItems} from 'App/api/shipwellUI/typed';
import {AssembleHandlingUnitsFormValues} from 'App/containers/Shipment/components/ShipmentItems/AssembleHandlingUnits/utils';
import {createConsolidatedLineItems} from 'App/data-hooks/handlingUnits/useLineItemConsolidation';

export const useLineItemConsolidation = ({
  shipmentId,
  originalShipmentLineItems
}: {
  shipmentId: string;
  originalShipmentLineItems: ShipmentLineItemDetail[];
}) => {
  const lineItemConsolidationMutation = useMutation(consolidateLineItems);

  const handleConsolidation = ({
    values,
    onSuccess,
    onError
  }: {
    values: AssembleHandlingUnitsFormValues;
    onSuccess: () => void;
    onError: () => void;
  }) => {
    const itemConsolidation = createConsolidatedLineItems(values, originalShipmentLineItems);
    lineItemConsolidationMutation.mutate(
      {shipmentId, itemConsolidation},
      {
        onSuccess,
        onError
      }
    );
  };

  return {
    handleConsolidation
  };
};
