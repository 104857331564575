import {LineItemFormValues, LineItemWarning} from 'App/formComponents/formSections/LineItemFields/types';
import {freightClasses} from 'App/utils/globals';

export const getLineItemWarnings = (lineItems: LineItemFormValues[]) => {
  const warnings: LineItemWarning[] = [];
  lineItems.forEach((lineItem: LineItemFormValues, index: number) => {
    if (lineItem) {
      if (Number.isNaN(lineItem.calculatedFreightClass)) {
        return;
      }
      if (lineItem.freight_class && lineItem.calculatedFreightClass) {
        /** Create warning if selected freight class is more than 2 classes from suggested class */
        const indexOfSuggestedFreight = freightClasses.findIndex(
          (freightClass) => freightClass.id === Number(lineItem.calculatedFreightClass)
        );
        const indexOfSelectedFreight = freightClasses.findIndex(
          (freightClass) => freightClass.id === Number(lineItem.freight_class)
        );
        const threshhold = 2;
        const freightClassWarning = Math.abs(indexOfSuggestedFreight - indexOfSelectedFreight) > threshhold;

        if (freightClassWarning) {
          warnings[index] = {freightClassWarning: true};
        }
      }
    }
  });

  return warnings;
};
