import {Card, SvgIcon, DeprecatedButton, FormikDateTimePicker} from '@shipwell/shipwell-ui';
import {Stop} from '@shipwell/tabula-sdk';
import {Field, FieldArray} from 'formik';

import TimelineIcon from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineIcon';
import useToggle from 'App/utils/hooks/useToggle';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {getNumOutlinedSvgIconName} from 'App/common';

const AdditionalStop = ({index, isLastStop}: {index: number; isLastStop: boolean}) => {
  const [show, toggleShow] = useToggle(true);
  const isDisabled = !show;

  const getLabel = () => {
    if (index === 0) return 'Origin';
    if (isLastStop) return 'Destination';
    return 'Additional Stop';
  };
  const numOutlinedSvgIconName = getNumOutlinedSvgIconName(index);
  return (
    <div className="flex gap-x-5">
      <TimelineIcon iconToShow={numOutlinedSvgIconName} isLineHidden={isLastStop} />
      <div className="flex-1">
        <div className="mb-4">
          <Field
            label={getLabel()}
            name={`itinerary.${index}.location.address`}
            searchAddressBook={false}
            component={AddressSearch}
            disabled={isDisabled}
          />
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <Field
            label="Earliest"
            name={`itinerary.${index}.plan_window.arrival`}
            component={FormikDateTimePicker}
            disabled={isDisabled}
            portalId="body"
          />
          <Field
            label="Latest"
            name={`itinerary.${index}.plan_window.departure`}
            component={FormikDateTimePicker}
            disabled={isDisabled}
            portalId="body"
          />
        </div>
      </div>
      <div className="w-[30px]">
        {!(index == 0 || isLastStop) ? (
          <DeprecatedButton variant="icon" onClick={toggleShow}>
            <SvgIcon name={show ? 'Visibility' : 'VisibilityOff'} />
          </DeprecatedButton>
        ) : null}
      </div>
    </div>
  );
};

const ItineraryForm = ({itinerary}: {itinerary: Array<Stop>}) => (
  <Card title="Itinerary" draggableProvided={false} bodyClassName="p-4 empty:p-0" data-testid="itinerary-content">
    <FieldArray name="itinerary">
      {() => (
        <div>
          {itinerary?.map((stop, index) => (
            <AdditionalStop key={stop.sequence_number} index={index} isLastStop={index === itinerary.length - 1} />
          ))}
        </div>
      )}
    </FieldArray>
  </Card>
);

export default ItineraryForm;
