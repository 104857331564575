import {compose} from 'recompose';
import {withRouter, WithRouterProps} from 'react-router';
import {AxiosError} from 'axios';
import PageHeader from 'App/common/pageHeader';
import UberFreightRegistrationForm from 'App/formComponents/forms/uberFreightRegistration';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import useUberFreightMutation from 'App/containers/uber/hooks/useUberFreightMutation';
import './styles.scss';

interface UberFreightRegistrationPropTypes extends WithStatusToastProps, WithRouterProps {}

/**
 * Uber Freight Registration Container
 * @param {UberFreightRegistrationPropTypes} props
 */
const UberFreightRegistration = (props: UberFreightRegistrationPropTypes) => {
  const handleCancel = () => {
    props.router.goBack();
  };

  /**
   * Submit Uber Freight Account Form
   * @param {FormikValues} values
   */
  const {mutate} = useUberFreightMutation({
    onSuccess() {
      props.router.push('/carriers?page=1&q=uber&uberFreightRegistrationSuccess');
    },
    onError(error: AxiosError) {
      props.setError('Error!', error.message);
    }
  });

  return (
    <div className="uber-registration-wrapper">
      <PageHeader title="Uber Freight Registration" backArrow />
      <div className="uber-registration-container">
        <p>
          To activate Uber Freight in the Shipwell platform, please enter your Uber Freight Customer ID. If you
          don&#39;t know your Customer ID, please contact your Uber Freight account rep or visit their{' '}
          <a href="https://www.uber.com/us/en/freight/" target="_blank" rel="noreferer noreferrer">
            website
          </a>
          .
        </p>
        <UberFreightRegistrationForm
          onCancel={handleCancel}
          onSubmit={mutate}
          defaultValues={{account_number: ''}}
          values={{account_number: ''}}
        />
      </div>
    </div>
  );
};

export default compose<UberFreightRegistrationPropTypes, unknown>(
  withRouter,
  WithStatusToasts,
  withFlags('connDigitalFreightMarketplace'),
  withConditionalFallback(
    (props: {connDigitalFreightMarketplace?: boolean}) => !props.connDigitalFreightMarketplace,
    () => <p className="text-center">You don&#39;t have access to this integration.</p>
  )
)(UberFreightRegistration);
