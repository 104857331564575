import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import useResponsiveModesAndEquipmentTypes from 'App/utils/useResponsiveModesAndEquipmentTypes';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import ModesField from 'App/formComponents/formSections/modesField';
import usePrevious from 'App/utils/hooks/usePrevious';

const ModeEquipmentFields = ({disabled}) => {
  const {values, setFieldValue} = useFormikContext();
  const {equipmentTypesOptions} = useResponsiveModesAndEquipmentTypes({
    equipmentTypesFieldName: 'equipment_type',
    modesFieldName: 'mode',
    values,
    setFieldValue
  });
  const previousMode = usePrevious(get(values, 'mode', null));

  // clear the equipment type value when the mode changes
  useEffect(() => {
    const currentMode = get(values, 'mode', null);
    if (previousMode && !isEqual(previousMode, currentMode)) {
      setFieldValue('equipment_type', null);
    }
  }, [values, setFieldValue, previousMode]);

  return (
    <div className="mb-4 grid grid-cols-2 gap-4">
      <ModesField name="mode" label="Mode" isMulti={false} disabled={disabled} clearable={false} />
      <EquipmentTypesField
        name="equipment_type"
        isMulti={false}
        options={equipmentTypesOptions}
        disabled={disabled || get(values, 'mode', null)?.code === 'PARCEL'}
        clearable={false}
      />
    </div>
  );
};

ModeEquipmentFields.defaultProps = {
  disabled: false
};

ModeEquipmentFields.propTypes = {
  disabled: PropTypes.bool
};

export default ModeEquipmentFields;
