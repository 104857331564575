import {connect} from 'react-redux';
import get from 'lodash/get';
import {Title} from '@shipwell/shipwell-ui';
import {RepsContainer} from 'App/containers/shipments/components/RepsView';
import withEmptyFallback from 'App/components/withEmptyFallback';
import {getReadableCreatedByString} from 'App/utils/createdBy';

const EmptyView = () => <Title>No reps</Title>;

const View = withEmptyFallback('reps', EmptyView)(RepsContainer);

export const getRepsFromState = (state) => {
  const createdByUser = get(state, 'shipmentdetails.one.created_by_user');
  const createdBySource = get(state, 'shipmentdetails.one.created_by_source');

  const reps = get(state, 'shipmentdetails.reps.results', []).map((rep) => ({
    person_name: rep.display_name,
    contact_type: rep.role_name,
    system_id: rep.id
  }));

  const createdByRep = (createdByUser || createdBySource) && {
    contact_type: 'created by',
    person_name: getReadableCreatedByString(createdByUser, createdBySource),
    system_id: get(createdByUser, 'id') || createdBySource
  };

  return createdByRep ? [createdByRep, ...reps] : reps;
};

export default connect((state) => ({
  reps: getRepsFromState(state)
}))(View);
