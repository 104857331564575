import {CarrierAssignment, Shipment, ShipwellError} from '@shipwell/backend-core-sdk';
import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {CARRIER_ASSIGNMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getCarrierAssignmentById, getCarrierAssignment} from 'App/api/invoicing/typed';

export type UseCarrierAssignmentOptions = Omit<
  UseQueryOptions<CarrierAssignment, AxiosError<ShipwellError>, CarrierAssignment, (string | null | undefined)[]>,
  'queryKey' | 'queryFn'
>;

export const useCarrierAssignment = (shipmentId?: string | null, options?: UseCarrierAssignmentOptions) => {
  const query = useQuery(
    [CARRIER_ASSIGNMENT_QUERY_KEY, shipmentId],
    async () => {
      if (!shipmentId) {
        throw new Error('shipmentId required');
      }
      const carrier = await getCarrierAssignment(shipmentId);
      return carrier;
    },
    options
  );

  return {
    carrier: query.data || null,
    isCarrierAssignmentLoading: query.isInitialLoading || query.isLoading || query.isFetching,
    carrierAssignmentError: query.error
  };
};

export const useGetCarrierAssignmentById = (
  shipmentId: Shipment['id'] = '',
  carrierAssignmentId: CarrierAssignment['id'] = '',
  queryOptions: UseQueryOptions<CarrierAssignment> = {}
) =>
  useQuery(
    [CARRIER_ASSIGNMENT_QUERY_KEY, shipmentId, carrierAssignmentId] as QueryKey,
    async () => {
      if (!shipmentId) {
        return Promise.reject('Missing Shipment Id');
      }
      if (!carrierAssignmentId) {
        return Promise.reject('Missing Carrier Assignment Id');
      }
      const response = await getCarrierAssignmentById(shipmentId, carrierAssignmentId || '');
      return response?.data;
    },
    {
      enabled: !!shipmentId && !!carrierAssignmentId,
      staleTime: HOT_DATA_STALE_TIME,
      ...queryOptions
    }
  );
