import {ChangeEvent, MouseEvent, useRef, useState} from 'react';
import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {parseISO, isFuture} from 'date-fns';
import {Form, Formik} from 'formik';
import {compose} from 'recompose';
import {
  RFPBidOptResponse,
  RfpCarrierBidRequestOptRequestBidRequestStateEnum,
  RfpCarrierBidRequestOptResponse,
  RFPBidOptResponseStateEnum
} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {object, string, array} from 'yup';
import pluralize from 'pluralize';
import {AxiosError} from 'axios';
import type {BidRequestValues} from 'App/containers/userCompany/rfps/ActionButton';
import {SendRfpBidRequestFields} from 'App/containers/userCompany/rfps/ActionButton';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import useToggle from 'App/utils/hooks/useToggle';
import {createOptimizedBidRequest, createRfpDocument, updateOptimizedRfpBid} from 'App/api/rfpOptimized/typed';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {RFP_BIDS_QUERY, RFP_OPT_QUERY} from 'App/data-hooks/queryKeys';

const rfpBidValidationSchema = object().shape({
  points_of_contact: array().min(1, 'At least one carrier is required'),
  bid_expiry_date_time: string()
    .required('Expiration date is required')
    .test('future-expiration', 'Expiration date and time must be in the future.', (expiration) => {
      if (!expiration) {
        return true;
      }
      return isFuture(parseISO(expiration));
    }),
  notes: string()
});

const SendRfpBidRequest = ({rfp, setSuccess, setError}: WithStatusToastProps & {rfp: RFPBidOptResponse}) => {
  const [showBidRequestModal, toggleShowBidRequestModal] = useToggle(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const createRfpDocumentsMutation = useMutation(async (documents: {file: File; description: string}[]) => {
    const responses = documents.map(async (document) => {
      const response = await createRfpDocument({
        rfpBidOptId: rfp.id || '',
        description: document.description,
        file: document.file
      });
      return response.data;
    });
    return Promise.allSettled(responses);
  });

  const updateRfpMutation = useMutation(updateOptimizedRfpBid);

  const handleSuccess = async (data: RfpCarrierBidRequestOptResponse[]) => {
    toggleShowBidRequestModal();
    setSuccess(
      `RFP Bid ${pluralize('Request', data.length)} Sent`,
      `${data.length === 1 ? 'An ' : ''}${pluralize('Email', data.length)} to your requested ${pluralize(
        'carrier',
        data.length
      )} ${pluralize('has', data.length)} been sent.`
    );
    await queryClient.invalidateQueries([RFP_BIDS_QUERY]);
    await queryClient.invalidateQueries([RFP_OPT_QUERY, rfp.id]);
  };

  const sendRfpBidMutation = useMutation(
    async (rfpCarrierBids: {rfpBidOptId: string; carrierCompany: string; pocEmail: string}[]) => {
      const responses = rfpCarrierBids.map(async (bid) => {
        const response = await createOptimizedBidRequest({
          rfpBidOptId: bid.rfpBidOptId,
          bidRequestState: RfpCarrierBidRequestOptRequestBidRequestStateEnum.RequestNotDownloaded,
          carrierCompany: bid.carrierCompany,
          pocEmail: bid.pocEmail
        });
        return response.data;
      });
      return Promise.all(responses);
    },
    {
      onSuccess: async (data) => {
        if (files.length > 0) {
          createRfpDocumentsMutation.mutate(
            files.map((file) => ({
              file,
              description: file.name
            })),
            {
              onSuccess: () => void handleSuccess(data)
            }
          );
        } else {
          await handleSuccess(data);
        }
      },
      onError: (error: AxiosError<{non_field_errors: string[]}>) => {
        toggleShowBidRequestModal();
        setError('Error', error.response?.data.non_field_errors.join('\r\n'));
      }
    }
  );

  const handleBidRequestModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleShowBidRequestModal();
  };

  const handleSubmitBidRequest = (values: BidRequestValues) => {
    updateRfpMutation.mutate(
      {
        bidExpiryDateTime: values.bid_expiry_date_time,
        notes: values.notes,
        rfpBidOptId: rfp.id || '',
        startDate: rfp.start_date || '',
        endDate: rfp.end_date || '',
        name: rfp.name || ''
      },
      {
        onSuccess: (data) => {
          const carrierBids =
            values.points_of_contact?.map((poc) => ({
              rfpBidOptId: data.data.id || '',
              carrierCompany: poc?.carrier_company_id || '',
              pocEmail: poc?.email || ''
            })) || [];
          sendRfpBidMutation.mutate(carrierBids);
        }
      }
    );
  };

  const handleAddFiles = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files && e.target.files;
    if (!files) {
      return;
    }

    setFiles((prevFiles) => {
      return [...prevFiles, ...Array.from(files)];
    });
  };

  const handleRemoveFile = (name: string) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((file) => file.name !== name);
    });
  };

  return (
    <>
      <DeprecatedButton onClick={handleBidRequestModal}>
        {rfp.state === RFPBidOptResponseStateEnum.Created
          ? 'Select carriers for RFP Bid Request'
          : 'Add Carriers to RFP Bid Request'}
      </DeprecatedButton>
      <Modal
        bodyVariant="disableOverflowScroll"
        show={showBidRequestModal}
        onClose={toggleShowBidRequestModal}
        title="Send RFP Bid Request"
      >
        <Formik
          onSubmit={handleSubmitBidRequest}
          initialValues={{
            points_of_contact: [],
            company: '',
            bid_expiry_date_time: rfp.bid_expiry_date_time || '',
            carrier_tags: [],
            notes: rfp.notes || ''
          }}
          validationSchema={rfpBidValidationSchema}
        >
          {({isSubmitting}) => (
            <Form>
              <SendRfpBidRequestFields isDisabledDatePicker={rfp.state === RFPBidOptResponseStateEnum.BidRequested}>
                <div>
                  <input
                    accept="application/*, image/*, .csv"
                    multiple
                    style={{display: 'none'}}
                    type="file"
                    ref={fileRef}
                    onChange={handleFileChange}
                  />
                  <DeprecatedButton
                    icon={<SvgIcon name="AddCircleOutlined" />}
                    variant="tertiary"
                    onClick={handleAddFiles}
                  >
                    Add Attachment
                  </DeprecatedButton>
                </div>
                {files.map((file) => (
                  <div key={file.name} className="flex items-center gap-2 text-sw-label">
                    <SvgIcon name="Attachment" />
                    <span>{file.name}</span>
                    <DeprecatedButton
                      variant="text"
                      icon={<SvgIcon name="CloseCircleOutlined" />}
                      onClick={() => handleRemoveFile(file.name)}
                    />
                  </div>
                ))}
              </SendRfpBidRequestFields>
              <ModalFormFooter isSubmitting={isSubmitting} onCancel={handleBidRequestModal} primaryActionName="Send" />
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default compose<WithStatusToastProps & {rfp: RFPBidOptResponse}, {rfp: RFPBidOptResponse}>(WithStatusToasts)(
  SendRfpBidRequest
);
