import {ShipwellError} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosRequestConfig} from 'axios';
import {deleteSuiteAppConfig} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useDisconnectSuiteApp = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSuiteAppConfig>>,
    AxiosError<ShipwellError>,
    AxiosRequestConfig | undefined
  >
) => {
  const queryClient = useQueryClient();
  const disconnectSuiteAppMutation = useMutation([SUITE_APP_CONFIG_QUERY], (opts) => deleteSuiteAppConfig(opts), {
    onSuccess: () => {
      void queryClient.resetQueries([SUITE_APP_CONFIG_QUERY]);
      void queryClient.invalidateQueries([SUITE_APP_CONFIG_QUERY]);
    },
    ...options
  });
  return {
    disconnectSuiteApp: disconnectSuiteAppMutation.mutate,
    isDisconnectSuiteAppLoading: disconnectSuiteAppMutation.isLoading
  };
};
