import {useMutation} from '@tanstack/react-query';
import {noop} from 'lodash';
import {ShipmentTimelineFilterPreferences} from '@shipwell/backend-core-sdk';
import {updateTimelineFilterPreferences} from 'App/api/tracking/typed';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {TIMELINE_FILTER_PREFERENCES} from 'App/data-hooks/queryKeys';

export const useUpdateTimelineFilters = ({errorHandler = noop}) => {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<
    Awaited<ReturnType<typeof updateTimelineFilterPreferences>>,
    unknown,
    {data: ShipmentTimelineFilterPreferences}
  >();

  const updateTimelineFilters = useMutation(
    ({data}) => updateTimelineFilterPreferences(data),
    createOptimisticUpdateHandlers([TIMELINE_FILTER_PREFERENCES], {
      errorHandler
    })
  );
  return updateTimelineFilters;
};
