import {ChangeEvent} from 'react';
import {cloneDeep} from 'lodash';
import {Field, useFormikContext} from 'formik';
import {FormikTextInput, FormikPhoneInput, FormikSelect, SvgIcon, FormikCheckbox} from '@shipwell/shipwell-ui';
import {facilityContactRoleOptions} from 'App/data-hooks/facilities/constants';
import {FacilityCreationForm} from 'App/data-hooks/facilities/types';

const FacilityContactFields = ({
  currentFormIndex,
  tabIndex,
  isEditable = true
}: {
  currentFormIndex: number;
  tabIndex: number;
  isEditable: boolean;
}) => {
  const {values, setFieldValue} = useFormikContext<FacilityCreationForm>();
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="grid gap-y-4">
        <Field
          required
          label="Name"
          component={FormikTextInput}
          name={`facilityContacts[${currentFormIndex}].person_name`}
          disabled={!isEditable}
          tabIndex={tabIndex}
        />
        <Field
          required
          label="Phone Number"
          component={FormikPhoneInput}
          name={`facilityContacts[${currentFormIndex}].phone_number`}
          disabled={!isEditable}
          tabIndex={tabIndex}
        />
      </div>
      <div className="grid gap-y-4">
        <div className="flex justify-between gap-x-4">
          <div className="grow-[2]">
            <Field
              simpleValue
              required
              label="Facility Role"
              component={FormikSelect}
              name={`facilityContacts[${currentFormIndex}].facility_role`}
              options={facilityContactRoleOptions}
              disabled={!isEditable}
              tabIndex={tabIndex}
            />
          </div>
          <div>
            <Field
              label="Primary Contact"
              name={`facilityContacts[${currentFormIndex}].is_default_for_facility`}
              component={FormikCheckbox}
              onChange={(val: ChangeEvent<HTMLInputElement>) => {
                const currentContacts = cloneDeep(values.facilityContacts);
                currentContacts.forEach((_, index) => {
                  if (index !== currentFormIndex && val.target.checked) {
                    setFieldValue(`facilityContacts[${index}.is_default_for_facility]`, false);
                  }
                });
                setFieldValue(`facilityContacts[${currentFormIndex}.is_default_for_facility]`, val.target.checked);
              }}
            />
          </div>
        </div>
        <Field
          prepend={<SvgIcon name="EmailOutlined" color="$sw-icon" />}
          required
          label="Email"
          component={FormikTextInput}
          name={`facilityContacts[${currentFormIndex}].email`}
          disabled={!isEditable}
          tabIndex={tabIndex}
        />
      </div>
    </div>
  );
};

export default FacilityContactFields;
