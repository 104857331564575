import {useCallback, useRef, useState} from 'react';

import {ShipmentMessage} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {isEqual} from 'lodash';
import {SHIPMENT_MESSAGES_QUERY_KEY} from '../queryKeys';

import {createShipmentMessage, getShipmentMessages} from 'App/api/shipment/typed';

export default function useShipmentMessages(shipmentId?: string): {
  isLoading: boolean;
  messages: ShipmentMessage[];
  error?: unknown;
} {
  const {
    isLoading,
    data: messages,
    error
  } = useQuery([SHIPMENT_MESSAGES_QUERY_KEY, shipmentId ?? ''], async () => {
    if (!shipmentId) {
      return [];
    }
    return getShipmentMessages(shipmentId);
  });
  return {isLoading, messages: messages ?? [], error};
}

type UseAllShipmentMessagesResult = {
  isLoading: boolean;
  messages: ShipmentMessage[];
  error?: unknown;
  isWorking: boolean;
  postMessage: (message: string) => Promise<void>;
};

const pNoop = () => Promise.resolve();

export function useAllShipmentMessages(shipmentId?: string): UseAllShipmentMessagesResult {
  const resultRef = useRef<UseAllShipmentMessagesResult>({
    isLoading: true,
    messages: [],
    error: undefined,
    isWorking: false,
    postMessage: pNoop
  });

  const {
    isLoading,
    data: messages,
    error
  } = useQuery([SHIPMENT_MESSAGES_QUERY_KEY, shipmentId ?? ''], async () => {
    if (!shipmentId) {
      return [];
    }
    return getShipmentMessages(shipmentId);
  });

  const client = useQueryClient();

  const [isWorking, setIsWorking] = useState(false);

  const postMessage = useCallback(
    async (message: string) => {
      if (!shipmentId) {
        return Promise.resolve();
      }
      if (isWorking) {
        return Promise.reject(new Error('Concurrent Message Posts are an error.'));
      }
      setIsWorking(true);
      try {
        const response = await createShipmentMessage(shipmentId, {message, shipment: shipmentId});
        const shipmentMessage: ShipmentMessage = response.data;
        const messages: ShipmentMessage[] | undefined = client.getQueryData([
          SHIPMENT_MESSAGES_QUERY_KEY,
          shipmentId ?? ''
        ]);
        if (!messages) {
          return;
        }
        messages.push(shipmentMessage);
        client.setQueryData([SHIPMENT_MESSAGES_QUERY_KEY, shipmentId ?? ''], messages);
      } finally {
        setIsWorking(false);
      }
    },
    [shipmentId, isWorking, client]
  );

  if (resultRef.current.isLoading !== isLoading) {
    resultRef.current = {...resultRef.current, isLoading};
  }
  if (!isEqual(resultRef.current.messages, messages)) {
    resultRef.current = {...resultRef.current, messages: messages ?? []};
  }
  if (resultRef.current.error !== error) {
    resultRef.current = {...resultRef.current, error};
  }
  if (resultRef.current.isWorking !== isWorking) {
    resultRef.current = {...resultRef.current, isWorking};
  }
  if (resultRef.current.postMessage !== postMessage) {
    resultRef.current = {...resultRef.current, postMessage};
  }

  return resultRef.current;
}
