import {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {OverflowMenu} from '@shipwell/shipwell-ui';

export const ConfigurationRow = ({
  toggle,
  type,
  description,
  editModal
}: {
  toggle: (() => ReactNode) | ReactNode;
  type: ReactNode;
  description: ReactNode;
  editModal?:
    | ((renderProps: {isModalOpen: boolean; setIsModalOpen: Dispatch<SetStateAction<boolean>>}) => ReactNode)
    | null;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="col-span-full grid grid-cols-[0.5fr,2fr,2.5fr,0.3fr] gap-2 border-t border-sw-border pb-1 pt-3">
      <div className="-mt-3">{typeof toggle === 'function' ? toggle() : toggle}</div>

      <div>{type}</div>

      <div>{description}</div>

      <div className="ml-auto">
        {editModal ? (
          <>
            <OverflowMenu>
              {({setIsOpen}) => (
                <OverflowMenu.Item
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsOpen(false);
                  }}
                >
                  Edit
                </OverflowMenu.Item>
              )}
            </OverflowMenu>

            {editModal({isModalOpen, setIsModalOpen})}
          </>
        ) : null}
      </div>
    </div>
  );
};
