import {useFormikContext} from 'formik';
import {FreightInvoiceFormData} from 'App/containers/settlements/freightInvoices/types';
import {isDefined} from 'App/utils/guard';

/**
 * Returns a list of document types that are uploaded and selected to be attached to a new invoice.
 */
export const useAttachedDocumentTypes = (): string[] => {
  const uploadedDocuments = useFormikContext<FreightInvoiceFormData>().values.document_metadata;

  return (
    uploadedDocuments
      ?.filter((doc) => doc.add_to_invoice)
      .map((doc) => doc.document_type)
      .filter(isDefined) || []
  );
};
