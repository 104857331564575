import {useQuery} from '@tanstack/react-query';
import {getAccessorials} from 'App/api/referenceData/typed';

export const useGetAccessorials = () => {
  const {data: accessorials} = useQuery({
    queryKey: ['accessorials'],
    queryFn: getAccessorials
  });

  return {
    accessorials
  };
};
