import {useEffect, useState} from 'react';
import {
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  TerminalFeeServiceTypeEnum,
  TransloadServiceTypeEnum,
  StorageServiceTypeEnum
} from '@shipwell/corrogo-sdk';
import {Modal, Popover} from '@shipwell/shipwell-ui';
import {AddStageMenuItem, CloneStageMenuItem, DeleteStageMenuItem, EditStageMenuItem} from './components';
import useToggle from 'App/utils/hooks/useToggle';
import NewStageForm from 'App/containers/shipments/components/DashboardSummary/NewStageForm';
import WithStatusToasts from 'App/components/withStatusToasts';
import type {WithStatusToastProps} from 'App/components/withStatusToasts';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {
  CREATE_SHIPMENTS_USER_PERMISSION,
  DELETE_MY_SHIPMENTS_USER_PERMISSION,
  DELETE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION,
  UPDATE_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {useUserMe} from 'App/data-hooks';

function StageActions({
  shipmentId,
  resourceId,
  linkTo,
  serviceType,
  setSuccess,
  setError
}: {
  shipmentId?: string;
  resourceId: string;
  linkTo?: string;
  serviceType?:
    | TransloadServiceTypeEnum
    | TerminalFeeServiceTypeEnum
    | StorageServiceTypeEnum
    | OceanTrackingServiceTypeEnum
    | RailTrackingServiceTypeEnum
    | undefined;
} & WithStatusToastProps) {
  const [previousResourceIdCreatedInModal, setPreviousResourceIdCreatedInModal] = useState<string>();
  const [showNewStageModal, toggleShowNewStageModal] = useToggle();
  const [showEditStageModal, toggleShowEditStageModal] = useToggle();
  const [showCloneStageModal, toggleShowCloneStageModal] = useToggle();
  const {data: userData} = useUserMe();
  const userPermissions = userData?.user?.permissions || [];
  const canEditStage =
    userPermissions.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    userPermissions.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);
  const canDeleteStage =
    userPermissions.includes(DELETE_SHIPMENTS_USER_PERMISSION) ||
    userPermissions.includes(DELETE_MY_SHIPMENTS_USER_PERMISSION);

  const handleToggleNewStageModal = (shouldAddAnotherStage?: boolean, previousResourceId?: string) => {
    //close the modal after successful submission.
    toggleShowNewStageModal();
    //if add another stage is selected, set id in state
    if (shouldAddAnotherStage && previousResourceId) {
      setPreviousResourceIdCreatedInModal(previousResourceId);
    }
  };

  const handleSuccess = (title: string, message: string) => {
    setSuccess(title, message);
  };

  const handleError = (title: string, message: string) => {
    setError(title, message);
  };

  useEffect(() => {
    //once we've set the resource id that was created the last time the modal..
    //was used, reopen the modal with the previous resource id.
    if (previousResourceIdCreatedInModal) {
      toggleShowNewStageModal();
    }
  }, [previousResourceIdCreatedInModal, toggleShowNewStageModal]);
  return (
    <>
      <Popover trigger={<Popover.IconButton iconName="Overflow" aria-label="Stage actions" />}>
        {({setIsOpen}: {setIsOpen: (isOpen: boolean) => void}) => (
          <Popover.MenuList>
            <PermissionsFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
              <AddStageMenuItem
                onClick={() => {
                  setIsOpen(false);
                  toggleShowNewStageModal();
                }}
              />
            </PermissionsFallback>
            {canEditStage ? (
              <EditStageMenuItem
                onClick={
                  serviceType
                    ? () => {
                        setIsOpen(false);
                        toggleShowEditStageModal();
                      }
                    : () => {
                        window.open(linkTo, '_blank');
                      }
                }
              />
            ) : null}
            <CloneStageMenuItem
              onClick={() => {
                setIsOpen(false);
                toggleShowCloneStageModal();
              }}
              shipmentId={shipmentId}
              resourceId={resourceId}
            />
            {canDeleteStage ? (
              <DeleteStageMenuItem
                resourceId={resourceId}
                shipmentId={shipmentId}
                onDeleteCompleted={() => setIsOpen(false)}
                handleError={handleError}
              />
            ) : null}
          </Popover.MenuList>
        )}
      </Popover>
      {showNewStageModal ? (
        <Modal
          show={showNewStageModal}
          onClose={toggleShowNewStageModal}
          title="Create a New Stage"
          bodyVariant="disableOverflowScroll"
          footerComponent={null}
        >
          <NewStageForm
            shipmentId={shipmentId}
            resourceId={previousResourceIdCreatedInModal || resourceId}
            onClose={handleToggleNewStageModal}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        </Modal>
      ) : null}
      {showEditStageModal ? (
        <Modal
          show={showEditStageModal}
          onClose={toggleShowEditStageModal}
          title="Edit Stage"
          bodyVariant="disableOverflowScroll"
          footerComponent={null}
        >
          <NewStageForm
            shipmentId={shipmentId}
            resourceId={resourceId}
            onClose={toggleShowEditStageModal}
            serviceType={serviceType}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        </Modal>
      ) : null}
      {showCloneStageModal ? (
        <Modal
          show={showCloneStageModal}
          onClose={toggleShowCloneStageModal}
          title="Clone Stage"
          footerComponent={null}
        >
          <NewStageForm
            shipmentId={shipmentId}
            resourceId={resourceId}
            onClose={toggleShowCloneStageModal}
            handleSuccess={handleSuccess}
            handleError={handleError}
            isCloningContainer
          />
        </Modal>
      ) : null}
    </>
  );
}

export default WithStatusToasts(StageActions);
