import {useState} from 'react';
import {Field, useFormikContext} from 'formik';
import {Checkbox, FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import {FedexLabelDataHeaders} from '@shipwell/backend-core-sdk';
import {ParcelLabelType} from './ParcelLabelsRoute';
import {FlexBox} from 'App/components/Box';

const options = [
  {label: 'Customer Reference Number', value: FedexLabelDataHeaders.CustomerReferenceNumber},
  {label: 'Ship Date', value: FedexLabelDataHeaders.ShipDate},
  {label: 'Service Type', value: FedexLabelDataHeaders.ServiceType},
  {label: 'Ship Weight / Package Weight', value: FedexLabelDataHeaders.ShipWeight},
  {label: 'PO Number', value: FedexLabelDataHeaders.PoNumber},
  {label: 'Tracking Number', value: FedexLabelDataHeaders.TrackingNumber}
];

export const CustomParcelLabelHeader = ({
  id,
  onAdd,
  onRemove
}: {
  id: string;
  onAdd: (value: unknown) => void;
  onRemove: (index: number) => void;
}) => {
  const {values} = useFormikContext<ParcelLabelType>();
  const labelData = values.fedex_config?.label_data || [];
  const atIndex = labelData.findIndex((label) => label.id === id);
  const [isChecked, setIsChecked] = useState(atIndex >= 0);

  const handleChange = () => {
    setIsChecked((prevChecked) => {
      if (!prevChecked) {
        onAdd({id, label: '', value: ''});
      } else {
        onRemove(atIndex);
      }
      return !prevChecked;
    });
  };

  return (
    <FlexBox gap="m">
      <Checkbox name={`fedex_config.label_data[${atIndex}].id`} onChange={handleChange} checked={isChecked} />
      <div className="grid grow gap-4 md:grid-cols-2">
        <Field
          label="Custom Header"
          disabled={!isChecked}
          required={isChecked}
          name={`fedex_config.label_data[${atIndex}].label`}
          component={FormikTextInput}
        />
        <Field
          simpleValue
          label="Data Display"
          disabled={!isChecked}
          required={isChecked}
          name={`fedex_config.label_data[${atIndex}].value`}
          component={FormikSelect}
          options={options}
        />
      </div>
    </FlexBox>
  );
};
