import {useQuery} from '@tanstack/react-query';
import {
  CarrierRelationshipsApiCarrierRelationshipsGetRequest,
  SlimCarrierRelationship
} from '@shipwell/backend-core-singlerequestparam-sdk';

import {CARRIER_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {formatCarrierPOCs} from 'App/utils/carrierHelpers';
import {getCarrierRelationshipsPromise} from 'App/api/carriers/typed';

const identifyingCodeTypes = {
  USDOT: 'USDOT',
  MC_NUMBER: 'MC_NUMBER'
};

const formatCarrierInfoToDisplayValues = (carrierRelationships: SlimCarrierRelationship[]) => {
  return carrierRelationships.map((carrierRelationship) => {
    const contacts = carrierRelationship.point_of_contacts;
    const vendor = carrierRelationship.shipwell_vendor;

    const getIdentifyingCode = (codeType: string) =>
      vendor?.identifying_codes?.find((code) => code.type === codeType)?.value || '--';

    const carrier = vendor?.carrier;
    return {
      name: carrier?.display_name || '--',
      carrierStatus: carrierRelationship?.carrier_status,
      carrierId: carrierRelationship?.id,
      addressLineOne: carrier?.mailing_address?.address_1 || vendor?.mailing_address?.address_1 || '--',
      addressLineTwo: carrier?.mailing_address?.address_2 || vendor?.mailing_address?.address_2 || null,
      cityStateZip: `${carrier?.mailing_address?.city || vendor?.mailing_address?.city || '--'}, ${
        carrier?.mailing_address?.state_province || vendor?.mailing_address?.state_province || '--'
      } ${carrier?.mailing_address?.postal_code || vendor?.mailing_address?.postal_code || '--'}`,
      phone: carrier?.primary_phone_number || '--',
      usdotNumber: getIdentifyingCode(identifyingCodeTypes.USDOT),
      mcNumber: getIdentifyingCode(identifyingCodeTypes.MC_NUMBER),
      contacts: contacts?.map((contact) => ({
        name: `${contact.first_name} ${contact.last_name || '--'}`,
        phone: contact.phone_number || '--',
        email: contact.email || '--',
        loadBoardAccess: contact.load_board_enabled ? 'Yes' : 'No'
      })) || [{name: '--', phone: '', email: '', loadBoardAccess: '--'}]
    };
  });
};

const useCarrierRelationships = (opts: CarrierRelationshipsApiCarrierRelationshipsGetRequest, queryOptions = {}) => {
  const carrierRelationshipsQuery = useQuery(
    [CARRIER_QUERY_KEY, opts],
    async () => {
      const response = await getCarrierRelationshipsPromise(opts);
      return response.data;
    },
    queryOptions
  );

  const relationships = carrierRelationshipsQuery.data?.results || [];
  const formattedCarrierInformation = formatCarrierPOCs(relationships);

  const carrierDisplayValues = formatCarrierInfoToDisplayValues(relationships);

  return {
    isLoading: carrierRelationshipsQuery.isInitialLoading,
    carrierRelationshipsQuery,
    context: {
      formattedCarrierInformation,
      carrierDisplayValues
    }
  };
};

export default useCarrierRelationships;
