import React from 'react';
import {Field} from 'formik';
import {CustomField, CustomFieldEntityTypesEnum, MeResponse} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import {CustomFieldsContextConsumer} from 'App/data-hooks';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {isSingleSelectionList} from 'App/containers/orders/utils';

interface AirShipmentCustomFieldsProps {
  userMeData: MeResponse | undefined;
}

const AirShipmentCustomFields: React.FC<AirShipmentCustomFieldsProps> = ({userMeData}) => {
  return (
    <CustomFieldsContextConsumer>
      {(consumer) => {
        const {customFields} = consumer as unknown as {customFields: CustomField[]};
        const customFieldsArray: CustomField[] = customFields ?? [];

        return (
          <div className="field-grid grid-1-1">
            {customFieldsArray
              .filter(
                (cf) =>
                  isCustomFieldOwner(cf.company, userMeData?.company?.id) ||
                  cf.allow_carrier_view ||
                  userMeData?.company?.name === 'SYSTEM-WIDE SUPPLIER TENANT'
              )
              .map((cf) => {
                return (
                  <Field
                    simpleValue
                    simpleOptionValue="label"
                    key={cf.id}
                    label={cf.label}
                    name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id || ''}`}
                    required={cf.required}
                    options={cf.allowed_values}
                    component={isSingleSelectionList(cf.field_type) ? FormikSelect : FormikTextInput}
                    normalize={(value: string) => value || null}
                    disabled={!cf.allow_carrier_edit && !isCustomFieldOwner(cf.company, userMeData?.company?.id)}
                  />
                );
              })}
          </div>
        );
      }}
    </CustomFieldsContextConsumer>
  );
};

export default AirShipmentCustomFields;
