import moment from 'moment';
import {Address, ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIconName} from '@shipwell/shipwell-ui';
import {CreateEventValidation} from '../NewEventModal';
import {
  eventTypes,
  STOP_ORDINAL_THRESHOLD
} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';

export const mapEventTypeWithIcon = (eventType = '') => {
  switch (eventType) {
    case eventTypes.BOOKED:
      return 'CheckCircleFilled';
    case eventTypes.CARRIER_MATCHED:
      return 'TruckCircleFilled';
    case eventTypes.ETA_CHANGED:
      return 'Time';
    case eventTypes.LOCATION_UPDATE:
      return 'Location';
    case eventTypes.DRIVER_ASSIGNED:
      return 'TruckCircleFilled';
    case eventTypes.DISPATCHED:
      return 'Dispatcher';
    case eventTypes.INFO:
      return 'Timeline';
    case eventTypes.CONTAINER:
      return 'ContainerCircleFilled';
    default:
      return 'CheckCircleFilled';
  }
};

export const mapStopOrdinalWithIcon = (stopOrdinal: number | null): SvgIconName => {
  return stopOrdinal && Number.isInteger(stopOrdinal) && stopOrdinal < STOP_ORDINAL_THRESHOLD
    ? `Num${stopOrdinal}Outlined`
    : 'CheckCircleFilled';
};

export const makeEventCreationPayload = (values: CreateEventValidation) => {
  return {
    ...values,
    occurred_at: moment(values?.occurred_at).toISOString(),
    event_type: values?.place_changed ? eventTypes.LOCATION_UPDATED : eventTypes.INFO,
    manually_created: true
  };
};

export const makeTrackingPayload = (values: CreateEventValidation) => {
  return {
    lat: values?.place_changed?.latitude,
    lon: values?.place_changed?.longitude,
    event_description: `${values?.description} ${values?.place_changed?.formatted_address || ''}`,
    address: formatAddress(values.place_changed),
    update_time: new Date().toISOString()
  } as ELDDeviceLocation;
};

const formatAddress = (address: Partial<Address> | null) => {
  return {
    city: address?.city || null,
    country: address?.country || null,
    postal_code: address?.postal_code || null,
    state: address?.state_province || null
  };
};
