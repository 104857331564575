import {useEffect, useRef} from 'react';

// A custom hook that uses the useRef hook internally for storing the previous value.
// Useful for storing/comparing a components previous props.
export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
