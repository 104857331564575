import {useState} from 'react';
import {array, object, number, string} from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, Toast} from '@shipwell/shipwell-ui';
import {AssembleHandlingUnitsForm} from './AssembleHandlingUnitsForm';
import {AssembleHandlingUnitsFormValues, createInitialValues} from './utils';
import {useLineItemConsolidation} from 'App/data-hooks/handlingUnits';

const validationSchema = object().shape({
  active_handling_unit_id: string().nullable(),
  line_items: array().of(
    object().shape({
      id: string(),
      description: string().nullable(),
      piece_type: string().nullable(),
      hazmat_hazard_class: string().nullable(),
      package_weight: number().nullable(),
      total_pieces: number().nullable(),
      quantity: number()
        .nullable()
        .test({
          message: 'Quantity must be a positive number.',
          test: (val) => (val ? val >= 0 : true)
        })
        .test({
          message: 'Quantity must be a whole number.',
          test: (val) => (val ? val % 1 === 0 : true)
        })
        .test('is-less', 'Quantity must not be greater than available pieces.', function (value) {
          const parent = this.parent as {total_pieces: number};
          if (value && parent.total_pieces) {
            return value <= parent.total_pieces;
          }
          if (value && !parent.total_pieces) {
            return value === 0;
          }
          return true;
        }),
      weight: number().nullable()
    })
  ),
  stops: array().of(
    object().shape({
      id: string(),
      handling_units: array().of(
        object().shape({
          package_weight: number().nullable().min(0, 'Must be a positive number.'),
          length: number().nullable().min(0, 'Must be a positive number.'),
          width: number().nullable().min(0, 'Must be a positive number.'),
          height: number().nullable().min(0, 'Must be a positive number.'),
          pieces: array().of(
            object().shape({
              id: string(),
              description: string().nullable(),
              piece_type: string().nullable(),
              hazmat_hazard_class: string().nullable(),
              total_pieces: number().nullable(),
              package_type: string().nullable(),
              weight: string()
            })
          )
        })
      )
    })
  )
});

export const AssembleHandlingUnits = ({
  shipment,
  onClose,
  onSuccess
}: {
  shipment: Shipment;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const {stops, line_items: lineItems} = shipment;
  const lineItemsWithSingleQuantity: Shipment['line_items'] = lineItems?.filter((lineItem) => {
    return Number(lineItem?.total_packages) <= 1;
  });
  const initialValues = createInitialValues({lineItems: lineItemsWithSingleQuantity, stops});

  const [mutationError, setMutationError] = useState('');

  const {handleConsolidation} = useLineItemConsolidation({
    shipmentId: shipment.id,
    originalShipmentLineItems: lineItemsWithSingleQuantity || []
  });

  const handleSubmit = (
    values: AssembleHandlingUnitsFormValues,
    helpers: FormikHelpers<AssembleHandlingUnitsFormValues>
  ) => {
    helpers.setSubmitting(true);

    handleConsolidation({
      values,
      onSuccess: () => {
        onSuccess();
        onClose();
        helpers.setSubmitting(false);
      },
      onError: () => {
        setMutationError('An error occured, please try again.');
        helpers.setSubmitting(false);
      }
    });
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({values, isSubmitting}) => {
        return (
          <Form noValidate className="relative flex h-full flex-col overflow-hidden">
            <AssembleHandlingUnitsForm values={values} />
            <footer className="flex justify-end gap-2 border-t border-sw-border p-4">
              <Button onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button isLoading={isSubmitting} disabled={isSubmitting} type="submit">
                Save
              </Button>
            </footer>
            <Toast variant="error" show={Boolean(mutationError)}>
              {mutationError}
            </Toast>
          </Form>
        );
      }}
    </Formik>
  );
};
