import {Card, CollapsibleCardContent, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import InstantRatesTable from 'App/containers/InstantRatesV2/components/InstantRatesTable';
import {Shipment} from '@shipwell/backend-core-sdk';
import {NewShipmentFormValues} from 'App/containers/Book/FormSections/InstantRatesCardV2';

interface Props {
  newShipmentInformation?: NewShipmentFormValues;
  show: boolean;
  onClose: () => void;
  shipment: Shipment;
}

export const InstantRatesModal = ({show, onClose, newShipmentInformation, shipment}: Props) => {
  return (
    <Modal title="Select An Instant Rate" show={show} onClose={onClose} footerComponent={null}>
      <Card draggableProvided={false} title="Instant Rates" bodyClassName="p-0" isCollapsible>
        <CollapsibleCardContent>
          <InstantRatesTable shipment={shipment} expandedColumnSet newShipmentInformation={newShipmentInformation} />
        </CollapsibleCardContent>
      </Card>
      <div className="mt-4 flex flex-row items-center rounded bg-sw-warning-background p-2">
        <div className="flex flex-row items-center font-bold">
          <SvgIcon name="ErrorOutlined" color="sw-warning" />
          <div className="ml-2">Select a carrier to take your load and the instant rate will be applied.</div>
        </div>
      </div>
    </Modal>
  );
};
