import moment from 'moment';
import {EnumRelativeDates} from './RelativeDatesEnum';

export interface IUpdateActiveFiltersToRelativeProps {
  [key: string]: string[];
}

export function tryGetRelativeDateFromDate(date: string) {
  const daysDiff = moment(date).diff(moment().startOf('day'), 'days');
  let newDate = date;

  switch (daysDiff) {
    case -30:
      newDate = EnumRelativeDates.LAST30DAYS;
      break;
    case -7:
      newDate = EnumRelativeDates.LAST7DAYS;
      break;
    case -1:
      newDate = EnumRelativeDates.YESTERDAY;
      break;
    case 0:
      newDate = EnumRelativeDates.TODAY;
      break;
    case 1:
      newDate = EnumRelativeDates.TOMORROW;
      break;
    case 7:
      newDate = EnumRelativeDates.NEXT7DAYS;
      break;
    case 30:
      newDate = EnumRelativeDates.NEXT30DAYS;
      break;

    // As a date range may not match exclacly with the dates
    // in the enum, and, as the date picker can return any date
    // if no match is found, returns the orignal value.
    default:
      break;
  }

  return newDate;
}

export function tryGetDateFromRelativeDate(relativeDate: string): string {
  const possibleValue: EnumRelativeDates | string = relativeDate;
  let finalValue = '';

  switch (possibleValue) {
    case EnumRelativeDates.LAST30DAYS:
      finalValue = moment().subtract(30, 'days').format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.LAST7DAYS:
      finalValue = moment().subtract(7, 'days').format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.YESTERDAY:
      finalValue = moment().subtract(1, 'days').format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.TODAY:
      finalValue = moment().format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.TOMORROW:
      finalValue = moment().add(1, 'days').format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.NEXT7DAYS:
      finalValue = moment().add(7, 'days').format('YYYY-MM-DD');
      break;
    case EnumRelativeDates.NEXT30DAYS:
      finalValue = moment().add(30, 'days').format('YYYY-MM-DD');
      break;

    // If a new string is offered, that is not in the enum,
    // just will used it. Maybe in the future it could trigger
    // an error?
    default:
      finalValue = possibleValue;
      break;
  }

  return finalValue;
}
