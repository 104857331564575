import {useQuery} from '@tanstack/react-query';
import {filterAccessorials} from 'App/utils/accessorialCodes';
import {fetchAccessorialCodesPromise} from 'App/api/shipment/typed';
import {ACCESSORIALS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export default function useFilteredAccessorials() {
  const getFilteredAccessorialsQuery = useQuery([ACCESSORIALS_QUERY_KEY], () => fetchAccessorialCodesPromise(), {
    staleTime: STATIC_DATA_STALE_TIME
  });
  const accessorials = filterAccessorials(getFilteredAccessorialsQuery.data?.data || []);
  return {getFilteredAccessorialsQuery, accessorials};
}
