import {useEffect} from 'react';
import {DeprecatedButton, FormikTextInput, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {object, string} from 'yup';
import {Field, Formik} from 'formik';
import classNames from 'classnames';
import {LoadboardType} from '@shipwell/tabula-sdk';

import useCreateLoadboard from 'App/api/loadboards/hooks/useCreateLoadboard';
import {UserData} from 'App/containers/integrations/details/components/truckstopDetails/utils';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';
import {concatUsername} from 'App/utils/concatUsername';

export type ValuesProps = {
  key: string;
};

type ActivateUserModalProps = {
  show: boolean;
  toggleShow: () => void;
  userData: UserData;
  setShowSpinner: (x: boolean) => void;
};

const ActivateUserModal = ({
  show,
  toggleShow,
  userData,
  setSuccess,
  setError,
  setShowSpinner
}: ActivateUserModalProps & WithStatusToastProps) => {
  const userName = concatUsername(userData.first_name, userData.last_name);

  const {mutate: activateUser, isLoading} = useCreateLoadboard({
    onSuccess: () => {
      toggleShow();
      setSuccess('Success!', `You have activated ${userName} as a user for Truckstop.`);
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    }
  });

  useEffect(() => {
    setShowSpinner(isLoading);
  }, [isLoading, setShowSpinner]);

  const initialValues = {
    key: userData.email
  };

  const validationSchema = object().shape({
    key: string().required('Username or email is required.').nullable()
  });

  const onSubmit = (values: ValuesProps) => {
    activateUser({
      loadboardType: LoadboardType.Truckstop,
      createAuthRequest: {
        credentials: values,
        is_user: true,
        user_id: userData.id
      }
    });
  };

  return (
    <Modal
      show={show}
      portal
      className="top-1/2 mt-0 -translate-y-2/4"
      title="Authenticate User"
      onClose={toggleShow}
      size="small"
      footerComponent={null}
      bodyClassName={classNames({'pointer-events-none': isLoading})}
    >
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({handleSubmit}) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="mb-4">
              <Field label="Truckstop Username / Email" name="key" component={FormikTextInput} required />
            </div>
            <div className="flex justify-end gap-2">
              <DeprecatedButton variant="secondary" type="button" onClick={toggleShow}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" variant="primary">
                {isLoading ? <SvgIcon name="LoadingDots" /> : 'Add'}
              </DeprecatedButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default WithStatusToasts(ActivateUserModal);
