export const ReRatingModal = () => {
  return (
    <div className="modal-terms-error">
      <h3>
        <i className="icon icon-Delayed" /> Re-rating Required
      </h3>
      <p>
        Changing quote information such as locations and line items may result in a different rate. Please request
        re-rating to avoid unforeseen shipment variances.
      </p>
    </div>
  );
};
