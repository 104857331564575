import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikDateTimePicker, SvgIcon, FormikTextInput} from '@shipwell/shipwell-ui';
import {ServiceProvider} from '@shipwell/backend-core-sdk';
import {NewStageCurrencyOfRecordField} from './NewStageCurrencyOfRecordField';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {handleSearchServiceProviders} from 'App/api/serviceProviders/utils';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';

const ServiceStageFields = ({allowCurrencyChange}: {allowCurrencyChange: boolean}) => {
  const {values, errors} = useFormikContext<NewStageFormValues>();

  const formatServiceProviderOptions = (option: ServiceProvider, {context}: {context: string}) => {
    if (context === 'value') {
      return option?.name;
    }
    return <div>{`${option.name} • ${option.mailing_address?.formatted_address || '--'}`}</div>;
  };
  //order by name descending by default
  const DEFAULT_SERVICE_PROVIDER_ORDERING = 'name';
  return (
    <div className="grid grid-cols-1 gap-4 pt-4">
      <Field
        component={AddressSearch}
        name="location"
        label="Location"
        useFormikValidation
        isFormikValid={!errors['location']}
        required
        customMenuOptionType="ADDRESS_BOOK"
      />
      <Field
        component={FormikDateTimePicker}
        name="start_date"
        label="Start Date / Time"
        required
        prepend={<SvgIcon name="Calendar" />}
      />
      <Field
        component={FormikDateTimePicker}
        name="end_date"
        label="End Date / Time"
        required
        prepend={<SvgIcon name="Calendar" />}
      />
      <Field
        component={FormikSelect}
        name="service_provider"
        label="Service Provider"
        async
        required
        loadOptions={(q: string) => handleSearchServiceProviders({q, ordering: DEFAULT_SERVICE_PROVIDER_ORDERING})}
        getOptionValue={(option: ServiceProvider) => option.id}
        getOptionLabel={(option: ServiceProvider) => option.name}
        defaultOptions
        formatOptionLabel={(option: ServiceProvider, {context}: {context: string}) =>
          formatServiceProviderOptions(option, {context})
        }
      />
      {allowCurrencyChange ? <NewStageCurrencyOfRecordField /> : null}
      <div className="grid grid-cols-2 gap-4">
        <Field
          component={FormikTextInput}
          prepend={values.preferred_currency}
          name="provider_rate"
          label="Provider Rate"
        />
        <Field
          component={FormikTextInput}
          prepend={values.preferred_currency}
          name="customer_rate"
          label="Customer Rate"
        />
      </div>
    </div>
  );
};

export default ServiceStageFields;
