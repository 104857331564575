import {RegularSelect} from '@shipwell/shipwell-ui';
import {providerSelectOptions} from './providerSelectOptions';
import {useMemo} from 'react';

export interface ProviderSelectProps {
  value: string;
  disable: boolean;
  error?: string;
  onChange: (value: string | null) => void;
}

export function ProviderSelect(props: ProviderSelectProps) {
  const selectedValue = useMemo(() => {
    return providerSelectOptions.find((item) => item.value === props.value) || null;
  }, [props.value]);

  return (
    <RegularSelect
      disable={props.disable}
      label={'Tracking Type*'}
      value={selectedValue}
      error={props.error}
      options={providerSelectOptions}
      onChange={(newValue) => props.onChange(newValue?.value || null)}
    />
  );
}
