import {REQUEST_IDENTIFYING_CODES_SUCCESS} from '../actions';

/** Initial state */
export const DEFAULT_STATE = {
  identifyingCodes: []
};

export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_IDENTIFYING_CODES_SUCCESS:
      return {
        ...state,
        identifyingCodes: payload
      };

    default:
      return state;
  }
};
