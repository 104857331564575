import {Field} from 'formik';
import {FormikTextInput, FormikSelect, Tooltip, SvgIcon, Title, FormikCheckbox} from '@shipwell/shipwell-ui';
import {PackagingTypes} from '@shipwell/tempus-sdk';
import {getProducts, getProductCategories} from 'App/api/products/typed';
import {
  deliveryTypeOptions,
  modeOptions,
  equipmentOptions,
  stackableOptions,
  hazmatOptions
} from 'App/data-hooks/facilities/constants';
import {LoadTypeFormData} from 'App/data-hooks/facilities/types';
import {generateTimeIntervalOptions} from 'App/data-hooks/facilities/facilityUtils';
import {useGetProductCategories, usePackageTypes, useProducts} from 'App/data-hooks';

type ArrayHelperReplaceType = (index: number, value: LoadTypeFormData) => void;

const LoadingTooltipInfo = () => {
  return (
    <div>
      <Title>Loading/Unloading Window</Title>
      <div>Time required to unload or load the shipment.</div>
    </div>
  );
};

const AppointmentTooltipInfo = () => {
  return (
    <div>
      <Title>Appointment Window</Title>
      <div>Time alotted to the appointment.</div>
    </div>
  );
};

const FacilityLoadTypesFields = ({
  currentFormIndex,
  replace,
  values
}: {
  currentFormIndex: number;
  replace: ArrayHelperReplaceType;
  values: {loadTypes: LoadTypeFormData[]};
}) => {
  const {data: products} = useProducts({page: 1, pageSize: 1000, q: ''});
  const formattedProducts = products?.map((product) => ({label: product.product_ref, value: product.product_ref}));
  const {data: packageTypes} = usePackageTypes();
  const formattedPackageTypes = packageTypes?.map((packageType) => ({
    label: packageType.description,
    value: packageType.code
  }));
  const productCategories = useGetProductCategories();
  const formattedProductCategories = productCategories.data?.map((productCategory) => {
    return {
      label: productCategory.product_category || '',
      value: productCategory.product_category || ''
    };
  }) || [{label: '', value: ''}];
  const handleSelectProduct = (productReference: string) => {
    const targetProduct = products?.find((product) => product.product_ref === productReference);
    const currentLoadType = values?.loadTypes?.[currentFormIndex];
    replace(currentFormIndex, {
      ...currentLoadType,
      product_reference: targetProduct?.product_ref ?? '',
      packaging_type: (targetProduct?.package_type || '') as PackagingTypes,
      product_category: targetProduct?.product_category ? [targetProduct?.product_category] : []
    });
  };

  const handleStackableChange = (stackableValue: string) => {
    const currentLoadType = values?.loadTypes?.[currentFormIndex];
    replace(currentFormIndex, {
      ...currentLoadType,
      stackable: stackableValue === 'true' ? true : stackableValue === 'false' ? false : undefined
    });
  };
  const handleHazmatChange = (hazmatValue: string) => {
    const currentLoadType = values?.loadTypes?.[currentFormIndex];
    replace(currentFormIndex, {
      ...currentLoadType,
      is_hazmat: hazmatValue === 'true' ? true : hazmatValue === 'false' ? false : undefined
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="grid grid-cols-2 gap-x-4">
        <Field
          required
          label="Load Type Name"
          component={FormikTextInput}
          name={`loadTypes[${currentFormIndex}].name`}
        />
        <div className="grid grid-cols-2 gap-x-2">
          <Field
            clearable={false}
            simpleValue
            label="Stackable"
            component={FormikSelect}
            name={`loadTypes[${currentFormIndex}].stackable`}
            options={stackableOptions}
            value={`${values?.loadTypes?.[currentFormIndex]?.stackable}`}
            onChange={(stackableValue: string) => handleStackableChange(stackableValue)}
          />
          <Field
            clearable={false}
            simpleValue
            label="Hazmat"
            component={FormikSelect}
            name={`loadTypes[${currentFormIndex}].is_hazmat`}
            options={hazmatOptions}
            value={`${values?.loadTypes?.[currentFormIndex]?.is_hazmat}`}
            onChange={(hazmatValue: string) => handleHazmatChange(hazmatValue)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-2">
        <div className="col-span-2">
          <Field
            required
            label="All Day Appointment"
            component={FormikCheckbox}
            name={`loadTypes[${currentFormIndex}].all_day_appointment`}
          />
        </div>
        {values.loadTypes[currentFormIndex].all_day_appointment ? null : (
          <>
            <div className="flex items-center">
              <div className="flex-1">
                <Field
                  simpleValue
                  required
                  label="Loading/Unloading Time Window"
                  component={FormikSelect}
                  name={`loadTypes[${currentFormIndex}].load_unload_duration`}
                  options={generateTimeIntervalOptions({totalHours: 10, interval: 15})}
                />
              </div>
              <Tooltip wrapperClassname="ml-2" placement="top" tooltipContent={<LoadingTooltipInfo />} trigger="hover">
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </div>
            <div className="flex items-center">
              <div className="flex-1">
                <Field
                  simpleValue
                  required
                  label="Appointment Time Window"
                  component={FormikSelect}
                  name={`loadTypes[${currentFormIndex}].appointment_duration`}
                  options={generateTimeIntervalOptions({totalHours: 10, interval: 15})}
                />
              </div>
              <Tooltip
                wrapperClassname="ml-2"
                placement="top"
                tooltipContent={<AppointmentTooltipInfo />}
                trigger="hover"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </div>
          </>
        )}
      </div>

      <Field
        simpleValue
        required
        label="Delivery Type"
        component={FormikSelect}
        name={`loadTypes[${currentFormIndex}].delivery_type`}
        options={deliveryTypeOptions}
      />
      <div className="grid grid-cols-2 gap-x-2">
        <Field
          simpleValue
          label="Mode"
          component={FormikSelect}
          name={`loadTypes[${currentFormIndex}].mode`}
          options={modeOptions}
        />
        <Field
          simpleValue
          label="Equipment"
          component={FormikSelect}
          name={`loadTypes[${currentFormIndex}].equipment_type`}
          options={equipmentOptions}
        />
      </div>
      <Field
        async
        defaultOptions
        isMulti
        simpleValue
        label="Product Category"
        component={FormikSelect}
        name={`loadTypes[${currentFormIndex}].product_category`}
        clearable
        loadOptions={async () => {
          const response = await getProductCategories();
          return response.data?.map((productCategory) => {
            return {
              label: productCategory.product_category || '',
              value: productCategory.product_category || ''
            };
          });
        }}
        options={formattedProductCategories}
      />
      <div className="grid grid-cols-2 gap-x-2">
        <Field
          async
          defaultOptions
          simpleValue
          label="Product Reference"
          component={FormikSelect}
          name={`loadTypes[${currentFormIndex}].product_reference`}
          onChange={(productReference: string) => handleSelectProduct(productReference)}
          clearable
          loadOptions={async (q: string) => {
            const response = await getProducts({q, page: 1, pageSize: 1000});
            return response.data.results?.map((product) => ({label: product.product_ref, value: product.product_ref}));
          }}
          options={formattedProducts}
        />
        <Field
          simpleValue
          label="Packaging Type"
          component={FormikSelect}
          name={`loadTypes[${currentFormIndex}].packaging_type`}
          options={formattedPackageTypes}
          disabled={values?.loadTypes?.[currentFormIndex]?.product_reference}
        />
      </div>
    </div>
  );
};

export default FacilityLoadTypesFields;
