import {useQuery} from '@tanstack/react-query';
import {trackingProviderCredentialsList} from 'App/api/locus/trackingCredentials';

export function useMacropointService() {
  const getQuery = useQuery({
    queryKey: ['useMacropointService'],
    queryFn: async () => {
      const response = await trackingProviderCredentialsList({
        provider: 'MACROPOINT'
      });

      return response.data.find((item) => item.provider === 'MACROPOINT');
    }
  });

  const isConnected = !!getQuery.data;

  return {
    isLoading: getQuery.isFetching,
    isConnected
  };
}
