import {useState, useEffect} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {
  Address,
  Contract,
  ContractCriteriaByValueRequestCurrencyEnum,
  EquipmentTypeValues,
  ContractCriteriaByValueRequestModeEnum,
  ContractStatusEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getContractParamsFromContractOrLane} from './utils';
import {
  RATE_TYPE_PER_HOUR,
  RATE_TYPE_PER_MILE,
  RATE_TYPE_RATE_TABLE
} from 'App/containers/userCompany/rateTables/constants';
import {createGrammaticList} from 'App/utils/grammar';
import {isApplicableContract} from 'App/data-hooks/contracts/useSelectedContract';
import {
  ApplicableContractWithCharges,
  useGetApplicableContracts
} from 'App/data-hooks/contracts/useGetApplicableContracts';

type ContractSelectBase = {
  contract?: Contract;
  modes?: ContractCriteriaByValueRequestModeEnum[];
  equipment?: EquipmentTypeValues[];
  status?: ContractStatusEnum;
  stopAddresses?: Address[];
  currencyOfRecord: ContractCriteriaByValueRequestCurrencyEnum;
  label?: string;
  disabled?: boolean;
  shipmentId?: string;
};

type SingleContractSelect = ContractSelectBase & {
  isMulti?: false;
  onChange: (newContract?: ApplicableContractWithCharges | Contract) => void;
};
type MultiContractSelect = ContractSelectBase & {
  isMulti: true;
  onChange: (newContract?: (ApplicableContractWithCharges | Contract)[]) => void;
};

export const ContractSelect = ({
  contract,
  onChange,
  modes,
  equipment,
  status = ContractStatusEnum.Active,
  stopAddresses,
  currencyOfRecord,
  label = 'Tender from Contract',
  disabled = false,
  isMulti,
  shipmentId
}: SingleContractSelect | MultiContractSelect) => {
  const [input, setInput] = useState('');
  // use the first item in an arry of equipment/mode for backend matching
  const equipmentType = equipment?.[0];
  const mode = modes?.[0];

  useEffect(() => {
    return () => (isMulti ? onChange([]) : onChange(undefined));
    // clear the selected contract/contracts on umount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // uses backend matching when a shipmentId OR equipment/mode/stopAddresses are available
  const {applicableContracts, isFetchingApplicableContractsWithCharges} = useGetApplicableContracts({
    ...(shipmentId
      ? {shipmentId, status}
      : {
          currencyOfRecord,
          equipmentType,
          mode,
          stopAddresses,
          status
        })
  });

  const contractsResults = applicableContracts;

  const contractOption = (option: Contract | ApplicableContractWithCharges, {context}: {context: string}) => {
    const isApplicableContractOption = isApplicableContract(option);
    const contract = isApplicableContractOption ? option.contract : option;
    const {
      origins: contractOrigins,
      destinations: contractDestinations,
      rateType,
      rate,
      modes,
      equipmentTypes
    } = getContractParamsFromContractOrLane({
      contract,
      lane: isApplicableContractOption ? option.contract_lane : undefined
    });

    // if the Select is closed, only show the name
    if (context === 'value') {
      return contract?.name || '--';
    }
    // else show more info about contract
    const joiner = ' · ';
    const origins = contractOrigins?.reduce(
      (acc, origin) => (origin?.formatted_address ? [...acc, origin.formatted_address] : acc),
      [] as string[]
    );
    const destinations = contractDestinations?.reduce(
      (acc, destination) => (destination?.formatted_address ? [...acc, destination.formatted_address] : acc),
      [] as string[]
    );

    const totalRate = isApplicableContractOption ? option.total : undefined;

    const basicRate = rate
      ? `${rate.toLocaleString()}${
          rateType === RATE_TYPE_PER_MILE ? '/mile' : rateType === RATE_TYPE_PER_HOUR ? '/hour' : ''
        }`
      : '--';

    const displayRate = `${contract?.rate_currency || currencyOfRecord} ${
      totalRate ? totalRate.toLocaleString() : rateType === RATE_TYPE_RATE_TABLE ? 'Rate Table' : basicRate
    }`;

    return (
      <div className="flex flex-col">
        <span className="font-bold">{contract?.name || '--'}</span>
        <span>
          {displayRate}
          {joiner}
          {contract?.carrier_name || '--'}
          {joiner}
          {origins && destinations && origins.length > 0 && destinations.length > 0 ? (
            <span>
              {createGrammaticList(origins)} &gt; {createGrammaticList(destinations)}
              {joiner}
            </span>
          ) : null}
          {modes ? createGrammaticList(modes.map((mode) => mode.code || '--')) : null}
          {joiner}
          {equipmentTypes ? createGrammaticList(equipmentTypes.map((equipment) => equipment.name || '--')) : null}
          {joiner}
          {contract?.service_level_agreement_name}
        </span>
      </div>
    );
  };

  const handleOptionLabel = (option: Contract | ApplicableContractWithCharges) => {
    const contract = isApplicableContract(option) ? option.contract : option;
    // we want to be able to see contracts who's name OR carrier_name match the search string
    if (contract?.name.toLowerCase().includes(input.toLowerCase())) {
      return contract.name;
    }
    return contract?.carrier_name || '--';
  };

  return (
    <Select
      disabled={disabled}
      label={label}
      clearable
      onInputChange={(search: string) => setInput(search)}
      value={contract}
      onChange={onChange}
      formatOptionLabel={contractOption}
      getOptionLabel={handleOptionLabel}
      getOptionValue={(option: Contract | ApplicableContractWithCharges) =>
        isApplicableContract(option) ? option.contract?.id : option.id
      }
      isLoading={isFetchingApplicableContractsWithCharges}
      options={contractsResults}
      isMulti={isMulti}
    />
  );
};
