import {
  Button,
  DisplayValue,
  FormikCounter,
  Modal,
  Title,
  FormikDateTimePicker,
  SvgIcon,
  Tooltip
} from '@shipwell/shipwell-ui';
import {object, number, date} from 'yup';
import {Field, Form, Formik} from 'formik';
import {AutoGenerateInvoiceConfiguration} from 'App/api/settlements/typeGuards';
import {useUpdateAutoGenerateInvoiceConfiguration} from 'App/api/settlements/queryHooks';

type InitialValues = {
  business_days_after_delivery: number;
  start_date: string;
};

const today = () => new Date().toISOString().split('T')[0];

export const AutoGenerateInvoiceEditModal = ({
  showModal,
  onClose,
  title,
  configuration,
  onEditSuccess
}: {
  showModal: boolean;
  onClose: () => void;
  title: string;
  configuration: AutoGenerateInvoiceConfiguration;
  onEditSuccess: () => void;
}) => {
  const {
    id,
    details: {business_days_after_delivery, start_date}
  } = configuration;

  const {mutate: updateAutoGenerateInvoiceConfig, isLoading} = useUpdateAutoGenerateInvoiceConfiguration({
    onSuccess: onEditSuccess
  });

  const handleSubmit = ({business_days_after_delivery, start_date}: InitialValues) => {
    updateAutoGenerateInvoiceConfig(
      {id, business_days_after_delivery, start_date},
      {
        onSuccess() {
          onClose();
        }
      }
    );
  };

  return (
    <Modal show={showModal} onClose={onClose} title={title} footerComponent={null} portal>
      <p className="mb-4">
        An invoice will be generated on behalf of a carrier if no invoice has been received a certain number of business
        days after delivery. The carrier will be notified that an invoice was generated on their behalf. All required
        invoice documents that are attached to the shipment will be attached to the invoice.
      </p>

      <Title className="pb-4">Invoice Generation Details</Title>

      <DisplayValue className="mb-6" label="Trigger">
        Shipment status is delivered but no invoice has been received.
      </DisplayValue>

      <DisplayValue className="mb-6" label="Action">
        Generate invoice on behalf carrier. Send invoice to carrier and notify them via email and notification.
      </DisplayValue>

      <Formik
        initialValues={{
          business_days_after_delivery,
          start_date: start_date || today()
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({isValid}) => (
          <Form aria-label="Auto-generate invoice edit modal form" name="auto-generate-invoice-edit-modal-form">
            <DisplayValue className="mb-6" label="Threshold">
              <div className="flex items-center gap-2">
                <Field name="business_days_after_delivery" component={FormikCounter} />
                <span>Business days after delivery</span>
              </div>
            </DisplayValue>
            <div className="flex  gap-2">
              <div className="w-72">
                <Field
                  name="start_date"
                  showTimeSelect={false}
                  label="Start Date"
                  minDate={today()}
                  prepend={<SvgIcon name="Calendar" />}
                  component={FormikDateTimePicker}
                />
              </div>
              <Tooltip tooltipContent={tooltipContent} placement="top">
                <SvgIcon name="InfoOutlined" color="$sw-icon" className="mt-2" />
              </Tooltip>
            </div>

            <div className="flex justify-end gap-4 pt-4">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={!isValid} isLoading={isLoading} variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const schema = object().shape({
  business_days_after_delivery: number()
    .min(0, 'The threshold must not be less than zero.')
    .required('Threshold is required'),
  start_date: date().min(today(), 'Start date must not be in the past').required('Start date is required')
});

const tooltipContent = (
  <div className="w-32">
    <div className="font-bold">Auto-generate Invoice Start Date</div>
    The start date will be used to determine which delivered shipments will be run through the configuration rule.
  </div>
);
