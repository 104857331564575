import {
  ActionRef,
  ActionRefActionIdEnum,
  ProcessStartEvent,
  SignalTriggerTriggerTypeEnum,
  TimerTriggerTriggerTypeEnum,
  Workflow
} from '@shipwell/opus-sdk';
import {EquipmentType, ServiceLevel, ShipmentMode} from '@shipwell/backend-core-sdk';
import {keyBy} from 'lodash';
import {
  POLICY_TYPE_ROUTING_GUIDE,
  POLICY_TYPE_ORDER_CONSOLIDATION,
  SIGNAL_TYPE_SHIPMENT_CREATED,
  SIGNAL_TYPE_PURCHASE_ORDER_CREATED,
  WORKFLOW_ACTION_OPTIONS,
  WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS,
  WORKFLOW_ACTION_TENDER,
  WORKFLOW_ACTION_SEND_EMAIL,
  WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER,
  WORKFLOW_ACTION_POST_TO_LOADBOARD
} from 'App/containers/workflows/workflowConstants';
import {getContract} from 'App/api/contracts/typed';
import getTimeOptions from 'App/utils/getTimeOptions';
import {getCarrierRelationshipsPromise} from 'App/api/carriers/typed';

// ! This file in a TS conversion of getWorkflowFormValues
// The newly typed version (getWorkflowFormValuesTyped) takes in workflow related params...
// ...and returns a greatly augmented workflow. This is not my ideal solution, however,...
// ...it is being used in enough components that I am hesitant change the return format or remove the augmentations.

// this is not currently provided by the opus SDK. As such it may be incomplete/inaccurate
export type ActionParam =
  | {name: 'involved_tender_to_company_users'; value: string[]}
  | {name: 'rate'; value: number}
  | {name: 'rate_type'; value: string}
  | {name: 'info_message'; value: string}
  | {name: 'expires_after_seconds'; value: number}
  | {name: 'mode'; value: string}
  | {name: 'equipment_type'; value: string}
  | {name: 'tender_to_company'; value: string}
  | {name: 'rate_currency'; value: string}
  | {name: 'contract_id'; value: string}
  | {name: 'carriers'; value: {involved_carrier_users: string; company_id: string}[]}
  | {name: 'recipients'; value: string[]}
  | {name: 'service_level'; value: string};

export type TransformedParams = {
  involved_tender_to_company_users?: string[];
  rate?: number;
  rate_type?: string;
  info_message?: string;
  expires_after_seconds?: number;
  mode?: string;
  equipment_type?: string;
  tender_to_company?: string;
  rate_currency?: string;
  contract_id?: string;
  carriers?: {involved_carrier_users: string; company_id: string}[];
  recipients?: string[];
  service_level?: string;
};

const getActionRefType = (type: ActionRefActionIdEnum) => {
  switch (type) {
    case ActionRefActionIdEnum.Tender:
      return WORKFLOW_ACTION_OPTIONS.TENDER;
    case ActionRefActionIdEnum.SendEmail:
      return WORKFLOW_ACTION_OPTIONS.SEND_EMAIL;
    case ActionRefActionIdEnum.PostToLoadboard:
      return WORKFLOW_ACTION_OPTIONS.POST_TO_LOADBOARD;
    case ActionRefActionIdEnum.CreateSpotNegotiations:
      return WORKFLOW_ACTION_OPTIONS.CREATE_SPOT_NEGOTIATIONS;
    case ActionRefActionIdEnum.CreateShipmentFromPurchaseOrder:
      return WORKFLOW_ACTION_OPTIONS.CREATE_SHIPMENT_FROM_PURCHASE_ORDER;
  }
};

// returns an object where the keys are the param's name and values are the param's value
export function createActionFromParams(params: ActionParam[]): TransformedParams {
  return params.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: curr.value
    };
  }, {});
}

const getTimerTrigger = (action: ActionRef, expiresAfterSeconds?: number) => {
  const {action_id, attached_triggers} = action;

  const triggerNotTimer = attached_triggers?.find(
    (trigger) => trigger.trigger.trigger_type !== TimerTriggerTriggerTypeEnum.Timer
  );
  if (triggerNotTimer) {
    return;
  }
  if (action_id === ActionRefActionIdEnum.Tender) {
    if (getTimeOptions().find((e) => e.value === expiresAfterSeconds)) {
      return {
        expires_after_seconds: getTimeOptions().find((e) => e.value === expiresAfterSeconds)
      };
    }
    return {
      expires_after_seconds: {
        label: `${expiresAfterSeconds || 0} seconds`,
        value: expiresAfterSeconds
      }
    };
  }
  if ([WORKFLOW_ACTION_POST_TO_LOADBOARD, WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS].includes(action_id)) {
    const stepTimer = attached_triggers?.find(
      (trigger) => trigger.trigger.trigger_type === TimerTriggerTriggerTypeEnum.Timer
    );
    const trigger = stepTimer?.trigger;
    if (trigger && 'start_after_seconds' in trigger) {
      const startAfterSeconds = trigger.start_after_seconds;
      return {
        step_timer: getTimeOptions().find((e) => e.value === startAfterSeconds)
      };
    }
  }
};

// this is the first step of many to add formatted data to a workflow's action
const formatActions = (actions: (ActionRef & {params: ActionParam[]})[]) =>
  actions.map((action) => ({
    ...action,
    ...createActionFromParams(action.params),
    type: getActionRefType(action.action_id),
    STEP_ID: action.step_id,
    attached_triggers: action.attached_triggers
  }));

const checkCreated = (
  trigger: ProcessStartEvent['trigger'],
  signalType: typeof SIGNAL_TYPE_SHIPMENT_CREATED | typeof SIGNAL_TYPE_PURCHASE_ORDER_CREATED
) => trigger.trigger_type === SignalTriggerTriggerTypeEnum.Signal && trigger.signal_type === signalType;

const formatTenderAction = async (
  action: ActionRef & TransformedParams,
  modes: ShipmentMode[],
  equipment: EquipmentType[]
) => {
  const vendorQuery = await getCarrierRelationshipsPromise({vendorId: action.tender_to_company || ''});
  const vendor = vendorQuery?.data?.results?.[0];
  const contract = await getContract(action.contract_id || '');
  return {
    mode: modes.find((mode) => mode.code === action.mode),
    equipment_type: equipment.find((e) => e.machine_readable === action.equipment_type),
    ...(!!vendor && {
      tender_to_company: vendor,
      involved_tender_to_company_users: action.involved_tender_to_company_users?.map((user) => {
        const match = vendor.point_of_contacts?.find((contact) => contact.user === user);
        const label = `${vendor.shipwell_vendor?.name || ''} - ${
          match?.first_name ? match?.first_name : 'Unknown User'
        }${match?.last_name ? ` ${match?.last_name}` : ''} (${match?.email ? match?.email : 'Unknown Email'})`;
        return {
          id: match?.user,
          label: label,
          carrier: vendor.shipwell_vendor?.id,
          carrierName: vendor.shipwell_vendor?.name
        };
      })
    }),
    ...(action.contract_id && {
      contract_id: {
        name: contract?.name ?? null,
        value: contract?.id
      }
    })
  };
};

const formatSpotNegotiationsAction = async (action: ActionRef & TransformedParams) => {
  const carriers = action.carriers?.map(async (carrier) => {
    const carrierResponse = await getCarrierRelationshipsPromise({vendorId: carrier.company_id});
    const carrierDetails = carrierResponse.data.results?.[0];
    if (carrierDetails) {
      const matchingPOC = carrierDetails.point_of_contacts?.find((contact) =>
        carrier.involved_carrier_users.includes(contact.user || '')
      );
      if (matchingPOC) {
        const label = `${carrierDetails.shipwell_vendor?.name || ''} - ${matchingPOC.first_name} ${
          matchingPOC.last_name || ''
        } (${matchingPOC.email || ''})`;
        return {
          id: matchingPOC.user,
          label: label,
          carrier: carrierDetails.shipwell_vendor?.id,
          carrierName: carrierDetails.shipwell_vendor?.name
        };
      }
    }
  });
  if (carriers) {
    return {carriers: await Promise.all(carriers)};
  }
};

const formatEmailAction = (action: ActionRef & TransformedParams) => {
  return {
    recipients: action.recipients?.map((recipient) => ({
      label: recipient,
      value: recipient
    }))
  };
};

const formatShipmentFromPurchaseOrder = (
  action: ActionRef & TransformedParams,
  modes: ShipmentMode[],
  equipment: EquipmentType[],
  serviceLevels: ServiceLevel[]
) => {
  return {
    type: {value: WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER, label: 'Create Shipment'},
    service_level: serviceLevels.find((level) => level.code === action.service_level)?.code,
    mode: modes.find((mode) => mode.code === action.mode),
    equipment_type: equipment.find((e) => e.machine_readable === action.equipment_type)
  };
};

export const getWorkflowFormValuesTyped = async (
  workflow: Workflow,
  policyType: typeof POLICY_TYPE_ROUTING_GUIDE | typeof POLICY_TYPE_ORDER_CONSOLIDATION,
  modes: ShipmentMode[],
  equipment: EquipmentType[],
  serviceLevels: ServiceLevel[],
  skippedStepExplanations?: {
    skipped_step_id?: string;
    detail_message?: string;
  }[]
) => {
  // params are currently missing in Opus SDK, typecasting here to remedy
  const castedActions = workflow.actions as (ActionRef & {params: ActionParam[]})[];
  const actions = formatActions(castedActions);

  const actionErrors = keyBy(workflow.data_integrity_errors, (error) =>
    parseInt(error.source?.pointer?.split('/')?.[2] || '')
  );

  const shipmentCreated = checkCreated(workflow.start_events[0].trigger, SIGNAL_TYPE_SHIPMENT_CREATED);
  const orderCreated = checkCreated(workflow.start_events[0].trigger, SIGNAL_TYPE_PURCHASE_ORDER_CREATED);

  const created =
    policyType === POLICY_TYPE_ROUTING_GUIDE
      ? {shipment_created: workflow.automated_execution_enabled && shipmentCreated}
      : null;
  const order =
    policyType === POLICY_TYPE_ORDER_CONSOLIDATION
      ? {order_created: workflow.automated_execution_enabled && orderCreated}
      : null;

  const getContentByActionType = async (action: (typeof actions)[0]) => {
    switch (action.type?.value) {
      case WORKFLOW_ACTION_TENDER:
        return await formatTenderAction(action, modes, equipment);
      case WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS:
        return await formatSpotNegotiationsAction(action);
      case WORKFLOW_ACTION_SEND_EMAIL:
        return formatEmailAction(action);
      case WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER:
        return formatShipmentFromPurchaseOrder(action, modes, equipment, serviceLevels);
      default:
        return action;
    }
  };

  const getSkippedStep = (action: ActionRef) => {
    const skippedStep = skippedStepExplanations?.find((skipped) => skipped.skipped_step_id === action.step_id);
    if (skippedStep) {
      return {skippedStepExplanation: skippedStep.detail_message};
    }
  };

  // unlike the original js version of this func that only returned transformed data,
  // here we return the original action augmented by the transformed data
  const expandedActions = actions.map(async (action, i) => {
    const contentByActionType = await getContentByActionType(action);
    return {
      ...action,
      ...contentByActionType,
      ...getTimerTrigger(action, action?.expires_after_seconds),
      ...getSkippedStep(action),
      error: actionErrors?.[i] ?? null
    };
  });

  return {
    ...workflow,
    actions: await Promise.all(expandedActions),
    ...created,
    ...order
  };
};
