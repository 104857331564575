import {Popover} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import {
  ShippingDashboardShipmentLineItem,
  ShippingDashboardWeight
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useGetCompanyPreferences} from './useGetCompanyPreferences';
import {LineItemsTooltip} from 'App/containers/Dashboard/components/Columns/LineItemsTooltip';
import {numberWithCommas} from 'App/utils/globals';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {METRIC} from 'App/utils/internationalConstants';

export const ItemsCell = ({
  lineItems,
  totalWeight,
  shipmentId
}: {
  lineItems: ShippingDashboardShipmentLineItem[];
  totalWeight?: ShippingDashboardWeight;
  shipmentId: string;
}) => {
  const {unitPreferences} = useGetCompanyPreferences();
  const totals = calculateShipmentTotals({
    line_items: lineItems,
    unitPreferences,
    totalWeight
  });

  if (!lineItems.length) {
    return <>--</>;
  }

  return (
    <Popover
      showArrow
      theme="dark"
      trigger={({
        setIsOpen,
        setTriggerElement
      }: {
        setIsOpen: (open: boolean) => void;
        setTriggerElement: (el: HTMLElement | null) => void;
      }) => (
        <div
          className="flex items-center gap-1 whitespace-nowrap"
          ref={setTriggerElement}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {lineItems.length === 1 ? (
            <span className="max-w-[200px] truncate">{lineItems[0].description}</span>
          ) : (
            <span>{pluralize('item', totals.units, true)}</span>
          )}
          <span>
            {totals.volume ? numberWithCommas(totals.volume.toFixed(2)) : ''} {totals.system === METRIC ? 'm' : 'ft'}
            <sup className="text-sw-text">3</sup>
          </span>
        </div>
      )}
    >
      <LineItemsTooltip shipmentId={shipmentId} />
    </Popover>
  );
};
