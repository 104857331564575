import {useState, useEffect, useRef} from 'react';
import {getShipmentStopEventTypes} from 'App/api/shipment';
import formatGroupedOptions from 'App/utils/formatGroupedOptions';

function useGroupedStopEventTypes(filterGroups = [], labelOverridesMap = {}) {
  const [groupedStopEventTypes, setGroupedStopEventTypes] = useState([]);

  const filterGroupsRef = useRef(filterGroups);
  const labelOverridesMapRef = useRef(labelOverridesMap);

  useEffect(() => {
    const fetchStopEventTypes = async () => {
      try {
        const response = await getShipmentStopEventTypes();
        const grouped = formatGroupedOptions(response.body, filterGroupsRef.current, labelOverridesMapRef.current);
        setGroupedStopEventTypes(grouped);
      } catch (error) {
        console.error('Error retrieving stop event types', error);
      }
    };
    fetchStopEventTypes();
  }, [filterGroupsRef, labelOverridesMapRef]);

  return groupedStopEventTypes;
}

export default useGroupedStopEventTypes;
