import {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {reduxForm, change, SubmissionError} from 'redux-form';
import {Form, Button} from 'react-bootstrap';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import BillTo from 'App/containers/Book/FormSections/BillTo';
import {unpackErrors, isBillToFormEmpty} from 'App/utils/globals';
import validate from 'App/utils/validateBillToForm';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    one: state.shipmentdetails.one,
    selectedRFQ: state.shipmentdetails.selectedRFQ,
    selectedQuote: state.shipmentdetails.selectedQuote,
    shipmentBillToForm: state.form.shipmentBillToForm
  }),
  {...shipmentDetailsActions}
)
class ShipmentBillTo extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.onBillToSubmit = this.onBillToSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.shipmentBillToForm.values &&
      this.props.shipmentBillToForm.values &&
      nextProps.shipmentBillToForm.values.bill_to_override &&
      (!this.props.shipmentBillToForm.values.bill_to_override ||
        (this.props.shipmentBillToForm.values.bill_to_override &&
          (!this.props.shipmentBillToForm.values.bill_to_override.direction ||
            (this.props.shipmentBillToForm.values.bill_to_override.direction &&
              nextProps.shipmentBillToForm.values.bill_to_override.direction !==
                this.props.shipmentBillToForm.values.bill_to_override.direction))))
    ) {
      if (nextProps.shipmentBillToForm.values.bill_to_override.direction === 'PREPAID') {
        //if prepaid, default to the current user's company information
        if (nextProps.company && nextProps.company.name && nextProps.company.billing_address) {
          const defaultBillTo = {};

          defaultBillTo.company_name = nextProps.company.name;
          if (nextProps.company.billing_address) {
            defaultBillTo.company_address = {
              formatted_address: nextProps.company.billing_address.formatted_address
            };
          }
          defaultBillTo.direction = 'PREPAID';
          defaultBillTo.contact_email = nextProps.company.primary_email;
          defaultBillTo.contact_phone = nextProps.company.primary_phone_number;
          defaultBillTo.billing_id = nextProps.shipmentBillToForm.values.bill_to_override.billing_id;
          this.props.dispatch(change('shipmentBillToForm', 'bill_to_override', defaultBillTo));
        }
      }
      if (nextProps.shipmentBillToForm.values.bill_to_override.direction === 'COLLECT') {
        //if COLLECT, default to the delivery stop's company information
        if (nextProps.one && nextProps.one.stops && nextProps.one.stops.length > 1) {
          //get the final stop
          const stop = nextProps.one.stops[nextProps.one.stops.length - 1];
          const defaultBillTo = {};
          if (stop.location) {
            defaultBillTo.company_name = stop.location.company_name || '';
            if (stop.location.address) {
              defaultBillTo.company_address = {
                formatted_address: stop.location.address.formatted_address
              };
            }
            if (stop.location.point_of_contacts && stop.location.point_of_contacts.length > 0) {
              defaultBillTo.contact_email = stop.location.point_of_contacts[0].email || '';
              defaultBillTo.contact_phone = stop.location.point_of_contacts[0].phone_number || '';
            }
          }

          defaultBillTo.direction = 'COLLECT';
          defaultBillTo.billing_id = nextProps.shipmentBillToForm.values.bill_to_override.billing_id;
          this.props.dispatch(change('shipmentBillToForm', 'bill_to_override', defaultBillTo));
        }
      }
      if (nextProps.shipmentBillToForm.values.bill_to_override.direction === '3RD_PARTY') {
        //if third party, default empty object
        const defaultBillTo = {};
        defaultBillTo.direction = '3RD_PARTY';
        defaultBillTo.billing_id = nextProps.shipmentBillToForm.values.bill_to_override.billing_id;
        this.props.dispatch(change('shipmentBillToForm', 'bill_to_override', defaultBillTo));
      }
    }
  }

  onBillToSubmit() {
    const attrs = this.props.shipmentBillToForm.values;
    const shipmentId = this.props.one.id;
    if (
      attrs.bill_to_override &&
      attrs.bill_to_override.company_address &&
      attrs.bill_to_override.company_address.formatted_address
    ) {
      attrs.bill_to_override.company_address = attrs.bill_to_override.company_address.formatted_address;
    }

    const shipment = JSON.parse(JSON.stringify(this.props.one));

    shipment.metadata = {
      ...shipment.metadata,
      bill_to_override: attrs.bill_to_override
    };

    if (isBillToFormEmpty(attrs.bill_to_override)) {
      shipment.metadata.bill_to_override = null;
    }

    return this.props.shipmentsShipmentIdPut(shipmentId, shipment, {}).then((response) => {
      if (response.status === 200) {
        //close form
        this.props.closeForm();
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  handleClearBillToForm() {
    const {form} = this.props;

    this.props.dispatch(change(form, 'bill_to_override', null));
  }

  render() {
    const {shipmentBillToForm, shipmentFinancialsForm, handleSubmit, submitting} = this.props;

    return (
      <div className="shipment__financials">
        <Form onSubmit={handleSubmit(this.onBillToSubmit)} className="shipment-billto-form p-4">
          <BillTo formValues={shipmentFinancialsForm || shipmentBillToForm} />
          <div className="update-footer show-flex">
            <Button
              bsStyle="default"
              type="button"
              disabled={submitting}
              onClick={this.handleClearBillToForm.bind(this)}
              className="cancel-edit"
              name="cancel-edit"
            >
              Clear
            </Button>
            <Button
              bsStyle="default"
              type="button"
              disabled={submitting}
              onClick={() => this.props.closeForm()}
              className="cancel-edit"
              name="cancel-edit"
            >
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" bsStyle="primary" name="submit-billto">
              {submitting ? (
                <span>
                  <i className="icon icon-Restart rotate" /> Saving...
                </span>
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

ShipmentBillTo = reduxForm({
  form: 'shipmentBillToForm',
  validate: validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  touchOnChange: true
})(ShipmentBillTo);

ShipmentBillTo = connect((state) => ({
  initialValues: JSON.parse(JSON.stringify(state.shipmentdetails.bill_to_override))
}))(ShipmentBillTo);

export default ShipmentBillTo;
