import {useQuery} from '@tanstack/react-query';
import {COMPANY_USERS} from '../queryKeys';
import {getUser} from 'App/api/user/typed';

export const useGetUser = ({companyId, userId}: {companyId: string; userId: string}) => {
  const {
    data: user,
    isLoading: isLoadingUser,
    error
  } = useQuery({
    queryKey: [COMPANY_USERS, companyId, userId],
    queryFn: () => getUser({companyId, userId})
  });
  return {user, isLoadingUser, error};
};
