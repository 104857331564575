import ShipmentReferencesCard from 'App/containers/Shipment/components/References/Card';
import ShipmentCarrierEquipmentCard from 'App/containers/Shipment/components/CarrierEquipment/Card';
import ShipmentRepsCard from 'App/containers/Shipment/components/Reps/Card';
import ShipmentLoadBoardsCard from 'App/containers/Shipment/components/LoadBoards/Card';
import ShipmentItemsCard from 'App/containers/Shipment/components/ShipmentItems/Card';
import LoadCard from 'App/containers/Shipment/components/Load/Card';

/**
 * Since this schema is stored in local storage,
 * changing these constants will break any
 * existing saved layouts. If you need to change these,
 * please bring it up with the team to discuss
 * migration options.
 */
export const SHIPMENT_REFERENCES_CARD = 'SHIPMENT_CARD_REFERENCES';
export const SHIPMENT_CARRIER_AND_EQUIPMENT_CARD = 'SHIPMENT_CARD_CARRIER_AND_EQUIPMENT';
export const SHIPMENT_REPS_CARD = 'SHIPMENT_CARD_REPS';
export const SHIPMENT_LOAD_BOARDS_CARD = 'SHIPMENT_CARD_LOAD_BOARDS';
export const SHIPMENT_ITEMS_CARD = 'SHIPMENT_ITEMS_CARD';
export const LOAD_CARD = 'LOAD_CARD';

export const getShipmentDetailsLayoutSchemaDefault = (showLoadboardCard = false) => {
  const cardsUnderFF = showLoadboardCard ? [SHIPMENT_LOAD_BOARDS_CARD, LOAD_CARD] : [];

  const cards = [
    SHIPMENT_REFERENCES_CARD,
    SHIPMENT_CARRIER_AND_EQUIPMENT_CARD,
    SHIPMENT_REPS_CARD,
    SHIPMENT_ITEMS_CARD,
    ...cardsUnderFF
  ];

  const leftColumn = cards.map((item, index) => ({
    id: item,
    ordinalIndex: index,
    isCollapsed: false
  }));

  return {
    leftColumn,
    rightColumn: [],
    sidebar: {
      isOpen: true,
      selectedIndex: 0
    }
  };
};

export const shipmentDetailsComponentMap = {
  [SHIPMENT_REFERENCES_CARD]: {
    card: ShipmentReferencesCard
  },
  [SHIPMENT_CARRIER_AND_EQUIPMENT_CARD]: {
    card: ShipmentCarrierEquipmentCard
  },
  [SHIPMENT_REPS_CARD]: {
    card: ShipmentRepsCard
  },
  [SHIPMENT_ITEMS_CARD]: {
    card: ShipmentItemsCard
  },
  [SHIPMENT_LOAD_BOARDS_CARD]: {
    card: ShipmentLoadBoardsCard
  },
  [LOAD_CARD]: {
    card: LoadCard
  }
};
