import {Tooltip} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {ReactNode} from 'react';

interface BaseButtonProps {
  onClick: () => void;
  isVisible: boolean;
  isDisabled: boolean;
  children: ReactNode;
}
interface DisabledButtonProps extends BaseButtonProps {
  disabledReason: ReactNode;
}

interface EnabledButtonProps extends BaseButtonProps {
  disabledReason?: undefined;
}
function isDisabledButton(buttonProps: DisabledButtonProps | EnabledButtonProps): buttonProps is DisabledButtonProps {
  return !!buttonProps.isDisabled;
}
type BulkActionButtonProps = Pick<BaseButtonProps, 'onClick' | 'isDisabled' | 'children'>;

const BulkActionButton = ({onClick, isDisabled, children}: BulkActionButtonProps) => (
  <button
    className={classNames({
      'cursor-not-allowed text-sw-disabled': isDisabled
    })}
    onClick={() => {
      //allow for pointer events to work on tooltip, instead of disabling button entirely
      if (isDisabled) {
        return;
      }
      onClick();
    }}
  >
    {children}
  </button>
);

export const BulkActionLineItemButton = (buttonProps: DisabledButtonProps | EnabledButtonProps) => {
  const {isVisible, children, onClick} = buttonProps;
  if (!isVisible) {
    return null;
  }
  return isDisabledButton(buttonProps) ? (
    <li>
      <Tooltip
        placement="left"
        tooltipContent={buttonProps.disabledReason}
        wrapperClassname="w-full"
        tooltipClassname="w-60"
        portal
      >
        <BulkActionButton onClick={onClick} isDisabled>
          {children}
        </BulkActionButton>
      </Tooltip>
    </li>
  ) : (
    <li>
      <BulkActionButton onClick={onClick} isDisabled={false}>
        {children}
      </BulkActionButton>
    </li>
  );
};

export const BulkActionDivider = () => <li className="pointer-events-none -mt-2 h-px border-b border-sw-divider" />;
