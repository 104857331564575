import {useEffect} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikTextInput, FormikTextarea, FormikSelect} from '@shipwell/shipwell-ui';
import {
  facilityInfoLocationTypeOptions,
  facilityServicesOptions,
  timezoneOptions
} from 'App/data-hooks/facilities/constants';
import {FacilityInfo} from 'App/data-hooks/facilities/types';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {useSearchAddressBookEntryQuery} from 'App/data-hooks';

/**
 * Fields used for both create and edit forms.
 * @see dev/features/dockScheduling/Facilities for usage
 */
const FacilityInfoFields = () => {
  const {errors, values, setFieldValue, setFieldError} = useFormikContext<FacilityInfo>();
  const canShowConditionalTimezoneField = Boolean(values.address.country) && !values.address.timezone;
  const minRowHeight = !canShowConditionalTimezoneField ? 8 : 10;
  const maxRowHeight = !canShowConditionalTimezoneField ? 10 : 12;

  /**
   * Query for searching for an facility name as location reference on an address book entry. If the user is
   * has selected an existing address book entry then no need to validate the facility name.
   */
  const {addresses: facilityNameAddresses} = useSearchAddressBookEntryQuery(values.name, {
    enabled: Boolean(values.name) && Boolean(values.address),
    select: (data) => {
      if (!data?.results?.length) {
        return data;
      }
      // address existing on an address indicates that this is an address book entry
      if (values.address.address || !values.name) {
        // override the results because the user has selected an address book entry
        // which means we don't need to validate the facility name because we are not
        // creating a new address book entry.
        return {
          page_size: data?.page_size,
          total_count: 0,
          total_pages: 0,
          results: []
        };
      }
      const facilityName = values.name.toLowerCase();
      const filteredResults = data?.results?.filter((result) => {
        return result.location_name.toLowerCase() === facilityName;
      });
      return {
        ...data,
        results: filteredResults || []
      };
    }
  });

  /**
   * Query for searching for an existing external reference if the user is creating a new address book entry.
   * if the user has selected an existing address book entry then no need to validate the facility external reference.
   */
  const {addresses: facilityExternalReferenceAddresses} = useSearchAddressBookEntryQuery(values.external_reference, {
    enabled: Boolean(values.external_reference) && Boolean(values.address),
    select: (data) => {
      if (!data?.results?.length) {
        return data;
      }
      // existing address prop on an address indicates that this is an address book entry
      if (values.address.address || !values.external_reference) {
        // override the results because the user has selected an address book entry
        // which means we don't need to validate the facility external reference because we are not
        // creating a new address book entry.
        return {
          page_size: data?.page_size,
          total_count: 0,
          total_pages: 0,
          results: []
        };
      }
      const externalReference = values.external_reference.toLowerCase();
      const filteredResults = data?.results?.filter((result) => {
        if (!result.external_reference) {
          return false;
        }
        return result.external_reference.toLowerCase() === externalReference;
      });
      return {
        ...data,
        results: filteredResults || []
      };
    }
  });
  const hasDuplicateFacilityName = Boolean(facilityNameAddresses.results?.length);
  const hasDuplicateExternalReference = Boolean(facilityExternalReferenceAddresses.results?.length);

  useEffect(() => {
    if (canShowConditionalTimezoneField !== values.is_manual_timezone) {
      setFieldValue('is_manual_timezone', canShowConditionalTimezoneField);
    }
  }, [values.manual_timezone, setFieldValue, values.is_manual_timezone, canShowConditionalTimezoneField]);

  if (!errors.name && hasDuplicateFacilityName) {
    setFieldError('name', 'Address Book Entry with location reference already exists.');
  }

  if (!errors.external_reference && hasDuplicateExternalReference) {
    setFieldError('external_reference', 'Address Book Entry with external reference already exists.');
  }

  return (
    <div className="grid grid-cols-1 gap-y-3 md:grid md:grid-cols-2 md:gap-x-3">
      <div className="grid grid-rows-6 gap-y-3">
        <Field required label="Facility Name" component={FormikTextInput} name="name" />
        <Field required label="Facility Address" component={AddressSearch} name="address" />
        {canShowConditionalTimezoneField ? (
          <Field
            simpleValue
            required
            label="Timezone"
            component={FormikSelect}
            name="manual_timezone"
            options={timezoneOptions}
          />
        ) : null}
        <Field
          simpleValue
          required
          label="Location Type"
          component={FormikSelect}
          name="location_type"
          options={facilityInfoLocationTypeOptions}
        />
        <Field label="External Reference" required component={FormikTextInput} name="external_reference" />
        <Field
          simpleValue
          isMulti
          label="Facility Services"
          component={FormikSelect}
          name="facility_services"
          options={facilityServicesOptions}
        />
        <Field label="Location Notes" component={FormikTextarea} name="notes" minRows={1} maxRows={2} />
      </div>
      <div className="grid grid-rows-2 gap-y-3">
        <Field
          label="Carrier Instructions"
          component={FormikTextarea}
          name="carrier_instructions"
          minRows={minRowHeight}
          maxRows={maxRowHeight}
        />
        <Field
          label="Disclaimers"
          component={FormikTextarea}
          name="disclaimers"
          minRows={minRowHeight}
          maxRows={maxRowHeight}
        />
      </div>
    </div>
  );
};
export default FacilityInfoFields;
