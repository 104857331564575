import {ShipmentOverallStatus} from '@shipwell/corrogo-sdk';
import {Pill, SvgIcon} from '@shipwell/shipwell-ui';
import {useV3Shipment} from 'App/data-hooks';

function statusToVariant(status?: ShipmentOverallStatus) {
  switch (status) {
    case ShipmentOverallStatus.Cancelled:
      return 'active';
    case ShipmentOverallStatus.Draft:
      return 'default';
    case ShipmentOverallStatus.Complete:
    case ShipmentOverallStatus.InProgress:
    default:
      return 'primary';
  }
}

const ShipmentStatusPill = ({shipmentId}: {shipmentId: string}) => {
  const {
    isLoading,
    data,
    context: {shipmentInfoDisplayValues}
  } = useV3Shipment(shipmentId);

  if (isLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  if (!data?.overall_status) {
    return null;
  }

  return (
    <Pill variant={statusToVariant(data.overall_status)} UNSAFEclassName="uppercase">
      {shipmentInfoDisplayValues.status}
    </Pill>
  );
};

export default ShipmentStatusPill;
