// Bulk Operation constants
export const BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE = 'BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE';
export const BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES = 'BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES';
export const BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS = 'BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS';
export const BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT = 'BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT';
export const BULK_OPERATION_TYPE_CHECK_CALL = 'BULK_OPERATION_TYPE_CHECK_CALL';
export const BULK_OPERATION_TYPE_TENDER_TO_CARRIER = 'BULK_OPERATION_TYPE_TENDER_TO_CARRIER';
export const bulkOperationTypeMessages = {
  BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE: {
    successTitle: 'Routing Guide Running!',
    successDetails: 'Routing guide is running on',
    errorTitle: 'Routing Guide Failed!',
    errorDetails: 'Routing guides could not be run on the following'
  },
  BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES: {
    successTitle: 'Changes Saved!',
    successDetails: 'Your shipping dates have been updated for',
    errorTitle: 'Changes Not Saved!',
    errorDetails: 'Your changes could not be applied to the following'
  },
  BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS: {
    successTitle: 'Changes Saved!',
    successDetails: 'Your shipping status has been updated for',
    errorTitle: 'Changes Not Saved!',
    errorDetails: 'Your changes could not be applied to the following'
  },
  BULK_OPERATION_TYPE_CHECK_CALL: {
    successTitle: 'Check Calls Made!',
    successDetails: 'Your check calls have been made for',
    errorTitle: 'Check Calls Failed!',
    errorDetails: 'Check calls could not be completed on the following'
  },
  BULK_OPERATION_TYPE_TENDER_TO_CARRIER: {
    successTitle: 'Tender Request Sent!',
    successDetails: 'Your tender request has been sent for',
    errorTitle: 'Tender Request Failed!',
    errorDetails: 'Your tender request could not be sent for the following'
  },
  BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT: {
    successTitle: 'Changes Saved!',
    successDetails: 'Archived',
    errorTitle: 'Archiving Failed!',
    errorDetails: 'Unable to archive the following'
  }
};
export const BOL = 'BOL';
export const PRINT_DOCUMENT = 'printDocument';
export const EMAIL_DOCUMENT = 'emailDocument';
export const STOP_POC_MESSAGE = 'STOP_POC_MESSAGE';
export const BILL_OF_LADING_ATTACHED = 'Bill of Lading Attached';

export const V3_VERSION = 3;

export const LOCALSTORAGE_DASHBOARD_COLUMNS = 'dashboardColumns';
