import {ReactNode} from 'react';
import classnames from 'classnames';

type Size = 'xs' | 's' | 'm' | 'l' | 'xl';
type Orientation = 'x' | 'y' | 't' | 'b' | 'l' | 'r';

/** Example: 'm' for medium spacing on all sides.
 *  ['x', 'l'] for large spacing on the x-axis
 *  [ ['x', 'l'], ['y', 's'] ] for large spacing on the x-axis as small spacing on the y-axis */
type Spacing = Size | [Orientation, Size] | [Orientation, Size][];

type FlexBox = {
  children: ReactNode;
  display?: 'flex';
  gap?: Size;
  direction?: 'col' | 'row' | 'col-reverse' | 'row-reverse';
  self?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | 'auto';
  justifySelf?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | 'auto';
  items?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  content?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  rows?: never;
  cols?: never;
  pad?: Spacing;
  margin?: Spacing;
  grow?: 0 | 1;
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
};

type GridBox = {
  children: ReactNode;
  display?: 'grid';
  gap?: Size;
  rows?: 1 | 2 | 3 | 4 | 5 | 6 | 'auto' | 'min' | 'max' | 'fr';
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | 'min' | 'max' | 'fr';
  self?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | 'auto';
  justifySelf?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | 'auto';
  items?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  content?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  direction?: never;
  pad?: Spacing;
  margin?: Spacing;
  grow?: never;
  wrap?: never;
};

type BoxProps = FlexBox | GridBox;

const getTailwindSize = (size?: Size) => {
  switch (size) {
    case 'xs':
      return '1';
    case 's':
      return '2';
    case 'm':
      return '4';
    case 'l':
      return '6';
    case 'xl':
      return '8';
    default:
      return '0';
  }
};

const getTailwindSpace = (spacing: Spacing, type: 'm' | 'p'): string => {
  if (typeof spacing === 'string') {
    return `${type}-${getTailwindSize(spacing)}`;
  }
  if (typeof spacing[0] === 'string' && typeof spacing[1] === 'string') {
    return `${type}${spacing[0]}-${getTailwindSize(spacing[1])}`;
  }
  const spacings = spacing as [Orientation, Size][];
  return spacings.map((spacing) => getTailwindSpace(spacing, type)).join(' ');
};

export const Box = ({
  children,
  pad,
  margin,
  display,
  gap,
  direction,
  rows,
  cols,
  self,
  justifySelf,
  items,
  justifyItems,
  content,
  justify,
  grow,
  wrap
}: BoxProps) => {
  const selfClass = self ? `self-${self}` : '';
  const justifySelfClass = justifySelf ? `justify-self-${justifySelf}` : '';
  const itemsClass = items ? `items-${items}` : '';
  const justifyItemsClass = justifyItems ? `justify-items-${justifyItems}` : '';
  const contentClass = content ? `content-${content}` : '';
  const justifyClass = justify ? `justify-${justify}` : '';
  const displayClass = display ?? '';
  const directionClass = display === 'flex' ? `flex-${direction ?? 'row'}` : '';
  const gapClass = `gap-${getTailwindSize(gap)}`;

  const padClass = pad ? getTailwindSpace(pad, 'p') : '';
  const marginClass = margin ? getTailwindSpace(margin, 'm') : '';

  const rowsClass = rows ? `grid-rows-${rows}` : '';
  const colsClass = cols ? `grid-cols-${cols}` : '';

  const growClass = grow === 1 ? 'grow' : grow === 0 ? 'grow-0' : '';
  const wrapClass = wrap ? `flex-${wrap}` : '';
  return (
    <div
      className={classnames('Box', {
        [displayClass]: display,
        [directionClass]: display === 'flex',
        [selfClass]: self,
        [justifySelfClass]: justifySelf,
        [itemsClass]: items,
        [justifyItemsClass]: justifyItems,
        [justifyClass]: justify,
        [contentClass]: content,
        [padClass]: pad,
        [marginClass]: margin,
        [gapClass]: gap,
        [rowsClass]: display === 'grid',
        [colsClass]: display === 'grid',
        [growClass]: grow !== undefined,
        [wrapClass]: wrap
      })}
    >
      {children}
    </div>
  );
};

export const FlexBox = ({
  children,
  gap,
  direction,
  pad,
  margin,
  self,
  justifySelf,
  items,
  justifyItems,
  content,
  justify,
  grow,
  wrap
}: FlexBox) => (
  <Box
    display="flex"
    gap={gap}
    direction={direction}
    pad={pad}
    margin={margin}
    self={self}
    justifySelf={justifySelf}
    items={items}
    justifyItems={justifyItems}
    content={content}
    wrap={wrap}
    grow={grow}
    justify={justify}
  >
    {children}
  </Box>
);

export const GridBox = ({children, gap, pad, margin, cols, rows, grow, items, justify}: GridBox) => (
  <Box
    display="grid"
    gap={gap}
    pad={pad}
    margin={margin}
    cols={cols}
    rows={rows}
    grow={grow}
    items={items}
    justify={justify}
  >
    {children}
  </Box>
);
