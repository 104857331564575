import {TrackingApiQuickstartResourceTrackingConfigRequest} from '@shipwell/locus-sdk';
import {UseDevicesFormValues} from '../devicesForm/useDevicesForm/useDevicesFormValues';

export function getQuickstartResourceTrackingConfig(
  formValues: UseDevicesFormValues,
  accountId: number,
  shipmentId: string
) {
  const data: TrackingApiQuickstartResourceTrackingConfigRequest = {
    quickstartBody: {
      provider: 'tive',
      resource_type: 'SHIPMENT',
      resource_id: shipmentId,
      device: {
        device_id: formValues.deviceId,
        device_type: 'SENSOR',
        custom_data: {
          account_id: accountId.toString()
        }
      }
    }
  };

  return data;
}
