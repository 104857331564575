import {FormGroup, InputGroup, FormControl} from 'react-bootstrap';

const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  }
};

const renderNoLabelField = ({
  input,
  tabindex,
  icon,
  prepend,
  placeholder,
  type,
  disabled,
  extraClass,
  hasError,
  meta: {touched, error}
}) => {
  if (type === 'hidden') {
    return (
      <FormControl
        //additional classname used when no icon to ensure styling
        className={!(icon || prepend) && 'form-control-no-icon'}
        tabIndex={tabindex}
        disabled={disabled ? true : false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        type={type}
        style={style.inputs}
        placeholder={placeholder}
      />
    );
  }
  return (
    <FormGroup
      className={extraClass && extraClass}
      controlId={input.name}
      validationState={touched && error ? 'error' : null}
    >
      <InputGroup
        className={hasError ? 'input-group-inline ' + hasError : 'input-group-inline'}
        style={{width: '100%'}}
      >
        {icon && (
          <InputGroup.Addon>
            <i className={icon} />
          </InputGroup.Addon>
        )}
        {prepend && <InputGroup.Addon>{prepend}</InputGroup.Addon>}
        <FormControl
          //additional classname used when no icon to ensure styling
          className={!(icon || prepend) && 'form-control-no-icon'}
          tabIndex={tabindex}
          disabled={disabled ? true : false}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          type={type}
          style={style.inputs}
          placeholder={placeholder}
        />
      </InputGroup>
      {touched && error && (
        <p className="error-text">
          <i className="icon icon-Delayed" />
          {error}
        </p>
      )}
    </FormGroup>
  );
};

export default renderNoLabelField;
