import {useContext, useState} from 'react';
import {SvgIcon, Card} from '@shipwell/shipwell-ui';
import {
  DeliveryTypeEnum,
  DockSchedulingModeEnum,
  DockSchedulingEquipmentTypeEnum,
  PackagingTypes
} from '@shipwell/tempus-sdk';
import {FieldArray, Formik, Form} from 'formik';
import {FieldArraySideNav} from 'App/components/FieldArray/FieldArraySideNav';
import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import FacilityCreationModalFooter from 'App/containers/facilities/components/FacilityCreationModalFooter';
import {facilityLoadTypesValidationSchema} from 'App/containers/facilities/schema';
import {LoadTypeFormData} from 'App/data-hooks/facilities/types';
import FacilityLoadTypesFields from 'App/formComponents/formSections/facilityLoadTypesFields';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

const LoadTypeForm = () => {
  const {setNextStep, facilityFormData, setFacilityFormData} = useContext(StepFormContext);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  const handleLoadTypesSubmit = (values: {loadTypes: LoadTypeFormData[]}) => {
    const formattedLoadTypes = values?.loadTypes?.map((loadType) => omitEmptyKeysWithEmptyObjectsRemoved(loadType));
    setFacilityFormData({
      ...facilityFormData,
      loadTypes: [...formattedLoadTypes]
    });
    setNextStep();
  };

  const handleAddNew = (
    push: (facilityLoadType: LoadTypeFormData) => void,
    values: {loadTypes: LoadTypeFormData[]}
  ) => {
    setCurrentFormIndex(values?.loadTypes?.length);
    push({
      name: '',
      load_unload_duration: '',
      appointment_duration: '',
      delivery_type: '' as DeliveryTypeEnum,
      mode: '' as DockSchedulingModeEnum,
      equipment_type: '' as DockSchedulingEquipmentTypeEnum,
      product_reference: '',
      packaging_type: '' as PackagingTypes,
      product_category: []
    });
  };
  const handleRemove = (remove: (index: number) => void, values: {loadTypes: LoadTypeFormData[]}) => {
    setCurrentFormIndex(values?.loadTypes?.length - 2);
    remove(currentFormIndex);
    const filteredLoadTypes = values?.loadTypes?.filter((_, index) => index !== currentFormIndex);
    setFacilityFormData({
      ...facilityFormData,
      loadTypes: filteredLoadTypes
    });
  };
  const getLabelsForLoadTypes = (values: {loadTypes: LoadTypeFormData[]}) => {
    return values?.loadTypes?.map(
      (loadType, index) => `Load Type ${index + 1} ${loadType?.name && '\u00b7'} ${loadType?.name}`
    );
  };

  return (
    <div className="my-10">
      <Formik
        validateOnMount
        initialValues={{loadTypes: facilityFormData.loadTypes}}
        validationSchema={facilityLoadTypesValidationSchema}
        onSubmit={handleLoadTypesSubmit}
      >
        {({isValid, values, errors}) => (
          <>
            <Form className="h-[520px]" noValidate>
              <FieldArray
                name="loadTypes"
                render={({push, remove, replace}) => (
                  <div className="flex h-full">
                    <div className="mr-3 flex-[1] bg-sw-background pt-5">
                      <FieldArraySideNav
                        title="Load Type"
                        labels={getLabelsForLoadTypes(values)}
                        activeIndex={currentFormIndex}
                        onActiveIndexChange={setCurrentFormIndex}
                        onAddNew={() => handleAddNew(push, values)}
                        isAddNewDisabled={!isValid}
                      />
                    </div>
                    <div className="flex-[4]">
                      <Card
                        draggableProvided={false}
                        title={`Load Type ${currentFormIndex + 1}`}
                        actions={
                          <>
                            {values?.loadTypes?.length > 1 ? (
                              <SvgIcon name="TrashOutlined" onClick={() => handleRemove(remove, values)} />
                            ) : null}
                          </>
                        }
                      >
                        <FacilityLoadTypesFields
                          currentFormIndex={currentFormIndex}
                          replace={replace}
                          values={values}
                        />
                        {!Array.isArray(errors.loadTypes) ? (
                          <div className="my-2 text-sw-error">{errors?.loadTypes}</div>
                        ) : null}
                      </Card>
                    </div>
                  </div>
                )}
              />
              <FacilityCreationModalFooter isValid={isValid} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default LoadTypeForm;
