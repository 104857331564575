import {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Paper, Grid} from '@material-ui/core';
import AuctionCarrierBids from '../AuctionCarrierBids';
import {transformShipmentToForm} from 'App/utils/globals';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {getSpotNegotiationQuotes, getSpotNegotiationDetails} from 'App/actions/marketplace';
import {searchForCarrierByID} from 'App/actions/vendors';
import {selectQuote, selectFormShipment} from 'App/actions/shipments';
import Messages from 'App/components/Messages';

let quotesInterval;

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    user: state.auth.user,
    company: state.auth.company,
    selectedSpotNegotiation: state.marketplace.selectedSpotNegotiation,
    spotNegotiationQuotes: state.marketplace.spotNegotiationQuotes,
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {
    getSpotNegotiationQuotes,
    getSpotNegotiationDetails,
    selectQuote,
    selectFormShipment,
    searchForCarrierByID
  }
)
export default class AuctionDetails extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {showForm: false, loading: false, carrierDetails: {}};
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      //get the spot negotiation details
      this.initialize();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company && this.props.company) {
      this.initialize();
    }
  }

  initialize() {
    this.setState({loading: true});
    this.props.getSpotNegotiationDetails(this.props.params.bid_id).then((response) => {
      //get quotes
      if (response.status === 200) {
        this.props.getSpotNegotiationQuotes(this.props.params.bid_id).then(() => {
          this.setState({loading: false});
        });
        this.pollQuotes();
        //get the carrier's details to fill in the POCs that have delivered quotes
        this.props.searchForCarrierByID(response.details.vendor.id).then((response) => {
          if (response.status === 200) {
            this.setState({carrierDetails: response.details});
          }
        });
      }
    });
  }

  componentWillUnmount() {
    clearInterval(quotesInterval);
  }

  handleSelectQuote(quote) {
    this.props.selectQuote(quote);
    let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));
    const mode = quote.mode || {id: 1, code: 'FTL', description: 'Full Truckload'}; //default FTL
    const equipment_types = quote.equipment_type || {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}; //default dry van
    shipment = transformShipmentToForm(shipment, this.props.hazmatCodes, [mode], [equipment_types], true);
    this.props.selectFormShipment(shipment);
    //send to confirm page to complete the rest of the shipment details
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/confirm?mode=bids');
  }

  pollQuotes(ms = 15000) {
    quotesInterval = window.setInterval(() => {
      this.props.getSpotNegotiationQuotes(this.props.params.bid_id);
    }, ms);
  }

  render() {
    const {loading, carrierDetails} = this.state;
    const {selectedShipment = {}, selectedSpotNegotiation = {}, spotNegotiationQuotes = {}} = this.props;
    let vendorPOCs = [];
    if (carrierDetails && carrierDetails.results && carrierDetails.results.length) {
      vendorPOCs = carrierDetails.results[0].point_of_contacts;
    }
    return (
      <div className="marketplace__auctionDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <div className="paper__header">
                <h4>Bids</h4>
              </div>
              <div className="paper__content">
                {loading ? (
                  <ShipwellLoader loading={loading} />
                ) : (
                  <AuctionCarrierBids
                    selectedSpotNegotiation={selectedSpotNegotiation}
                    spotNegotiationQuotes={spotNegotiationQuotes}
                    handleSelect={this.handleSelectQuote.bind(this)}
                    vendorPOCs={vendorPOCs}
                  />
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <div className="paper__header">
                <h4>Messages</h4>
              </div>
              <div className="paper__content marketplace__auctionDetails-messages">
                <Messages
                  shipmentResource={{spot_negotiation: this.props.params.bid_id}}
                  resourceType="SPOT_NEGOTIATION"
                  shouldPoll
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
