import {AddressBookEntry, ShipwellError} from '@shipwell/backend-core-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {FACILITY_ADDRESS_BOOK_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';
import {ShipwellApiErrorResponse, convertShipwellAxiosError} from 'App/utils/errors';

export type UseFacilityAddressBookEntryOptions = Omit<
  UseQueryOptions<
    AddressBookEntry | null,
    AxiosError<ShipwellApiErrorResponse>,
    AddressBookEntry | null,
    (string | null | undefined)[]
  >,
  'queryKey' | 'queryFn'
>;

const useFacilityAddressBookEntry = (facilityId?: string | null, options?: UseFacilityAddressBookEntryOptions) => {
  const {data, status, error} = useQuery(
    [FACILITY_ADDRESS_BOOK_QUERY_KEY, facilityId],
    async () => {
      if (!facilityId) {
        throw {
          response: {
            status: 400,
            statusText: 'BadRequest',
            data: {
              errors: [
                {
                  title: 'Facility ID required',
                  detail: 'Facility ID is required to fetch a facility'
                }
              ]
            } as ShipwellApiErrorResponse
          }
        } as AxiosError<ShipwellApiErrorResponse>;
      }
      try {
        const {data} = await getAddressBookAddresses({
          q: facilityId
        });

        const {results} = data;
        if (!results?.length) {
          return null;
        }

        if (results.length > 1) {
          throw {
            response: {
              data: {
                errors: [
                  {
                    title: 'Multiple Address Book Entries found',
                    detail: `Multiple Address Book Entries belong to the Facility with ID ${facilityId}`
                  }
                ]
              } as ShipwellApiErrorResponse
            }
          } as AxiosError<ShipwellApiErrorResponse>;
        }

        const addressBookEntry: AddressBookEntry = results[0];

        return addressBookEntry;
      } catch (error) {
        const legacyError = error as AxiosError<ShipwellError>;
        throw convertShipwellAxiosError(legacyError);
      }
    },
    {
      ...options,
      enabled: Boolean(options?.enabled ?? true) && Boolean(facilityId)
    }
  );

  return {
    addressBookEntry: data || null,
    isAddressBookEntryLoading: status === 'loading',
    isAddressBookEntrySuccess: status === 'success',
    isAddressBookEntryError: status === 'error',
    addressBookEntryError: error
  };
};

export {useFacilityAddressBookEntry};
