import {compose} from 'recompose';
import {connect} from 'react-redux';
import {Shipment, LoadBoardShipment, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ReactNodeLike} from 'prop-types';
import Map from 'App/components/trimbleMap';
import {useShipmentLocations} from 'App/containers/alertsDashboard/utils/hooks/useShipmentLocations';
import WithStatusToasts from 'App/components/withStatusToasts';
import {formatMileage} from 'App/utils/globals';
import {useLegacyShipment} from 'App/data-hooks';
import ShipwellLoader from 'App/common/shipwellLoader';

interface BaseLegacyMapContainerProps {
  shipmentId: string;
  resizeMap: boolean;
}

interface ShipmentMapContainerProps extends BaseLegacyMapContainerProps {
  shipment: Shipment;
  isLoadBoard?: false;
  one?: LoadBoardShipment;
}

interface LoadBoardMapContainerProps extends BaseLegacyMapContainerProps {
  shipment?: Shipment;
  isLoadBoard: true;
  one: LoadBoardShipment;
}

type MapContainerProps = (ShipmentMapContainerProps | LoadBoardMapContainerProps) & {
  setError: (title?: string, message?: ReactNodeLike) => void;
};

const MapContainer = ({shipmentId, setError, resizeMap, isLoadBoard, one, shipment}: MapContainerProps) => {
  const {
    shipmentQuery: {data: shipmentData}
  } = useLegacyShipment(shipmentId, {
    refetchOnWindowFocus: false
  });

  const hasReduxShipment = Boolean(shipment?.id);
  const availableShipment = hasReduxShipment ? shipment : one || shipmentData;

  const queryOptions = {
    refetchOnWindowFocus: false,
    onError: (error: ShipwellError) => {
      setError('Error!', error?.error_description);
      console.error(error);
    },
    enabled: !isLoadBoard && Boolean(availableShipment?.id)
  };

  // if shipment is air, disable the filter for near and duplicated location breadcrumbs
  let filterDistance;
  if (availableShipment && 'mode' in availableShipment && availableShipment.mode?.code === 'AIR') {
    filterDistance = '-1';
  }

  const {
    shipmentLocations,
    route,
    trackingLinePoints,
    queryInfo: breadcrumbsQuery
  } = useShipmentLocations({shipment: availableShipment, queryOptions, filterDistance});

  if (breadcrumbsQuery.isInitialLoading || !availableShipment) {
    return (
      <div className="relative h-full">
        <ShipwellLoader loading />
      </div>
    );
  }

  const getAddress = () => {
    if ('current_address' in availableShipment) {
      return availableShipment.current_address;
    }
  };

  return (
    <div className="relative h-full">
      <Map
        additionalClassNames="absolute inset-0"
        elementId="timelineRedesign__map"
        locations={shipmentLocations}
        resizeMap={resizeMap}
        route={route}
        address={getAddress()}
        totalDistance={formatMileage(availableShipment?.total_miles) as string}
        trackingLinePoints={trackingLinePoints}
      />
    </div>
  );
};

export default compose<MapContainerProps, BaseLegacyMapContainerProps>(
  connect((state: {shipmentdetails: {one: Shipment}}) => ({shipment: state.shipmentdetails.one})),
  WithStatusToasts
)(MapContainer);
