import {useState} from 'react';
import {Pill, StepIndicator, useStepIndicator} from '@shipwell/shipwell-ui';
import PageHeader from 'App/common/pageHeader';
import LegForm from 'App/containers/shipments/drayage/create/components/LegForm';
import ContainersForm from 'App/containers/shipments/drayage/components/ContainersForm';
import ShipmentSummary from 'App/containers/shipments/drayage/create/components/ShipmentSummary';

const CreateDrayageLeg = () => {
  const steps = ['Shipment Information', 'Container Details', 'Shipment Review'];
  const {activeStep, setNextStep, setActiveStep, setPreviousStep} = useStepIndicator(steps);
  const [shipmentId, setShipmentId] = useState(null);

  const handleSubmit = ({shipmentId}) => {
    setShipmentId(shipmentId);
    setNextStep();
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <LegForm onSubmit={handleSubmit} shipmentId={shipmentId} />;
      case 1:
        return (
          <ContainersForm onSubmit={() => setNextStep()} onPrevious={() => setPreviousStep()} shipmentId={shipmentId} />
        );
      case 2:
        return <ShipmentSummary shipmentId={shipmentId} onEdit={(step) => setActiveStep(step)} />;
      default:
        return null;
    }
  };

  return (
    <div className="h-full bg-sw-background min-h-screen-minus-20">
      <PageHeader title="Add Shipment">
        <Pill variant="default" size="sm">
          DRAFT
        </Pill>
      </PageHeader>
      <div className="flex justify-center py-4">
        <div className="w-1/2">
          <StepIndicator steps={steps} activeStep={activeStep} alternativeLabel />
        </div>
      </div>
      {renderStep()}
    </div>
  );
};

export default CreateDrayageLeg;
