import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {Card} from '@shipwell/shipwell-ui';
import withFlags from 'App/utils/withFlags';
import CompanyBillingInstructions from 'App/formComponents/formSections/companyBillingInstructions';
import CompanyNotesForCarrier from 'App/formComponents/formSections/companyNotesForCarrier';
import CompanyRateConfirmation from 'App/formComponents/formSections/companyRateConfirmation';
import CompanyTenderEmail from 'App/formComponents/formSections/companyTenderEmail';
import CompanyMessagingPreferences from 'App/formComponents/formSections/companyMessagingPreferences';
import CompanyCarrierRatePreferences from 'App/formComponents/formSections/companyCarrierRatePreferences';
import FormFooter from 'App/formComponents/formSections/formFooter';
import './styles.scss';

/** Default values needed for Formik */
export const defaultFormValues = {
  billing_instructions: null,
  rate_confirmation_terms: null,
  custom_carrier_invoicing_instructions: null,
  use_custom_carrier_invoicing_instructions: 'default',
  default_carrier_notes: null
};

/**
 * Company Terms & Credit Form
 */
const CompanyTermsCreditForm = (props) => {
  const {edit, values, onSubmit, primaryEmail, platCustomRateConfirmation = false} = props;

  const formatUseCustomCarrierInvoicingIntructions = (useCustomCarrierInvoicingInstructions) => {
    return useCustomCarrierInvoicingInstructions === true
      ? 'custom'
      : useCustomCarrierInvoicingInstructions === false
      ? 'default'
      : useCustomCarrierInvoicingInstructions;
  };

  /** Submit company terms values */
  const handleSubmit = (values, actions) => {
    const payload = {...values};
    const useCustom = payload?.use_custom_carrier_invoicing_instructions;

    if (useCustom) {
      if (useCustom === 'custom' && values?.custom_carrier_invoicing_instructions) {
        payload.use_custom_carrier_invoicing_instructions = true;
      } else if (useCustom === 'default') {
        payload.use_custom_carrier_invoicing_instructions = false;
      }
    }

    if (onSubmit) {
      onSubmit(payload, actions);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...defaultFormValues,
        ...values,
        use_custom_carrier_invoicing_instructions: formatUseCustomCarrierInvoicingIntructions(
          values.use_custom_carrier_invoicing_instructions
        )
      }}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, isSubmitting, dirty, values, setFieldValue}) => (
        <form noValidate="novalidate" className="company-terms-form" onSubmit={handleSubmit}>
          <Card title="Billing" className="billing-instructions">
            <CompanyBillingInstructions />
          </Card>
          <Card title="Notes for Carrier" className="billing-instructions">
            <CompanyNotesForCarrier />
          </Card>
          {/* this functionality was moved to ratecon digital docs feature, only show this section if flag is off  */}
          {!platCustomRateConfirmation ? (
            <Card title="Rate Confirmation" className="rate-confirmation mb-6">
              <CompanyRateConfirmation values={values} setFieldValue={setFieldValue} email={primaryEmail} />
            </Card>
          ) : null}
          <Card title="Email Preferences" className="mb-6">
            <CompanyTenderEmail values={values} />
          </Card>
          <CompanyMessagingPreferences />
          <CompanyCarrierRatePreferences />
          {(!edit || dirty) && <FormFooter isSubmitting={isSubmitting} />}
        </form>
      )}
    </Formik>
  );
};

CompanyTermsCreditForm.propTypes = {
  edit: PropTypes.bool,
  onSubmit: PropTypes.func,
  platCustomRateConfirmation: PropTypes.bool,
  primaryEmail: PropTypes.string,
  values: PropTypes.shape({
    custom_carrier_invoicing_instructions: PropTypes.string,
    use_custom_carrier_invoicing_instructions: PropTypes.any
  })
};

export default withFlags('platCustomRateConfirmation')(CompanyTermsCreditForm);
