import {Field, useFormikContext} from 'formik';
import {FormikTextInput, SvgIcon} from '@shipwell/shipwell-ui';
import RateTypeField from 'App/formComponents/formSections/rateTypeField';
import {createDoeTable} from 'App/containers/userCompany/fuelSurcharges/utils/utils';

const UserRatesCriteriaFormFields = () => {
  const {values, setFieldValue} = useFormikContext();

  const handleChange = (value, fieldName) => {
    const valAsNumber = Number(value);
    setFieldValue(fieldName, value);
    const updatedDoeTable = createDoeTable({
      ...values,
      [fieldName]: valAsNumber
    });
    setFieldValue('calculation_rows', updatedDoeTable);
  };

  return (
    <div className="field-grid fuelSurcharge__rateGrid">
      <div className="fuelSurcharge__rateGrid-label">
        <SvgIcon name="ArrowForward" color="$sw-icon" />
        My starting fuel surcharge rate
      </div>
      <Field
        label="Fuel Rate (e.g. 0.005)"
        name="user_base_rate"
        component={FormikTextInput}
        required
        value={values.user_base_rate}
        onChange={(e) => handleChange(e.target.value, 'user_base_rate')}
      />
      <div className="fuelSurcharge__rateGrid-label">
        <SvgIcon name="ArrowForward" color="$sw-icon" />
        My fuel rate increases in increments of
      </div>
      <Field
        label="Rate Increments (e.g. 0.005)"
        name="user_rate_increment"
        component={FormikTextInput}
        required
        value={values.user_rate_increment}
        onChange={(e) => handleChange(e.target.value, 'user_rate_increment')}
      />
      <div className="fuelSurcharge__rateGrid-label">
        <SvgIcon name="ArrowForward" color="$sw-icon" />
        My Fuel surcharge rate is calculated
      </div>
      <RateTypeField name="rate_type" required />
    </div>
  );
};

export default UserRatesCriteriaFormFields;
