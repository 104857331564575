import {Dispatch, SetStateAction} from 'react';
import {RowSelectionState, Updater, flexRender} from '@tanstack/react-table';
import {PurchaseOrderStatus} from '@shipwell/corrogo-sdk';
import invariant from 'tiny-invariant';
import {columns} from './columns';
import {
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import {useTablePagination, useTableFilters, useTypedTable} from 'App/components/TypedTable/hooks';
import {useGetOrdersList} from 'App/data-hooks';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/components/TypedTable/tableUtils';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {OrderErrorBanner} from 'App/containers/orders/components';
import {ORDERS_LIST_KEY} from 'App/utils/tableTypeKeys';
import useDebouncedEffect from 'App/utils/hooks/useDebouncedEffect';
import {SelectedCount} from 'App/components/SelectedCount';
import {AddOrdersToShipmentContentLayout} from 'App/containers/orders/views/AddOrdersToShipmentListView/components/AddOrdersToShipmentListTable/Layout';
import Loader from 'App/common/shipwellLoader';
import {
  sixtyDaysAgoDateTimeString,
  sixtyDaysFromNowDateTimeString,
  todayDateTimeString
} from 'App/containers/orders/views/AddOrdersToShipmentListView/components/AddOrdersToShipmentListTable';

type Filters = {
  q: string;
  shipFromPlanWindowStartGte?: string;
  shipFromPlanWindowStartLte?: string;
  shipToPlanWindowStartGte?: string;
  shipToPlanWindowStartLte?: string;
  statusIn: string;
};

export const AddOrdersToShipmentListTable = ({
  searchTerm,
  rowSelection,
  setRowSelection,
  onRowSelectionChange,
  displayAllAvailableOrders,
  relatedOrdersIds = []
}: {
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
  searchTerm: string;
  onRowSelectionChange: Dispatch<SetStateAction<string[]>>;
  displayAllAvailableOrders: boolean;
  relatedOrdersIds: string[];
}) => {
  const initialFilterState: Filters = {
    q: searchTerm,
    shipFromPlanWindowStartGte: sixtyDaysAgoDateTimeString,
    shipFromPlanWindowStartLte: sixtyDaysFromNowDateTimeString,
    shipToPlanWindowStartGte: todayDateTimeString,
    shipToPlanWindowStartLte: sixtyDaysFromNowDateTimeString,
    //get all orders except for Not Ready and Cancelled
    statusIn: Object.values(PurchaseOrderStatus)
      .filter((status) => ![PurchaseOrderStatus.NotReady, PurchaseOrderStatus.Cancelled].includes(status))
      .join()
  };
  const {filters, setFilters} = useTableFilters(initialFilterState, false);
  const [pagination, setPagination] = useTablePagination({tableType: ORDERS_LIST_KEY});
  const resetPageIndex = () => (pagination.pageIndex !== 0 ? setPagination({...pagination, pageIndex: 0}) : undefined);

  const handleChangeParams = () => {
    const updatedFilters = {
      q: searchTerm,
      shipFromPlanWindowStartGte: displayAllAvailableOrders ? undefined : sixtyDaysAgoDateTimeString,
      shipFromPlanWindowStartLte: displayAllAvailableOrders ? undefined : sixtyDaysFromNowDateTimeString,
      shipToPlanWindowStartGte: displayAllAvailableOrders ? undefined : todayDateTimeString,
      shipToPlanWindowStartLte: displayAllAvailableOrders ? undefined : sixtyDaysFromNowDateTimeString,
      statusIn: Object.values(PurchaseOrderStatus)
        .filter((status) =>
          displayAllAvailableOrders
            ? ![
                PurchaseOrderStatus.NotReady,
                PurchaseOrderStatus.Cancelled,
                PurchaseOrderStatus.Filled,
                PurchaseOrderStatus.Delivered
              ].includes(status)
            : ![PurchaseOrderStatus.NotReady, PurchaseOrderStatus.Cancelled].includes(status)
        )
        .join()
    };

    return updatedFilters;
  };

  const params = {
    ...filters,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize
  };
  const {paginatedOrders, ordersError} = useGetOrdersList(params);
  const table = useTypedTable({
    data: paginatedOrders?.data?.filter((order) => !relatedOrdersIds.includes(order.id)) || [],
    columns,
    pageCount: Math.ceil((paginatedOrders?.total_count || 1) / pagination.pageSize),
    state: {
      pagination,
      rowSelection
    },
    getRowId: (row) => row.id,
    enableRowSelection: (row) =>
      ![PurchaseOrderStatus.NotReady, PurchaseOrderStatus.Cancelled].includes(
        row.original.status as PurchaseOrderStatus
      ),
    onPaginationChange: setPagination,
    onRowSelectionChange: (updater: Updater<RowSelectionState>) => {
      // This pattern of getting the state via react table's updater is seen in the useTablePagination hook
      const getState = (prevState: RowSelectionState) => (updater instanceof Function ? updater(prevState) : updater);
      setRowSelection((prev) => {
        // getting updated state
        const updated = getState(prev);
        const shipmentKeys = Object.keys(updated);
        // as a side effect we want to get the full objects of all selected rows
        // nesting setters allows us to track on user change as opposed to an effect
        onRowSelectionChange((prevSelected) => {
          invariant(Array.isArray(prevSelected), 'previous selected must be an array');
          //get the orders from this page that were not previously selected
          const thisPageUnselectedOrders =
            paginatedOrders?.data?.filter((order) => !prevSelected.includes(order.id)).map((order) => order.id) || [];
          const thisPageAndPreviouslySelectedShipments = [...prevSelected, ...thisPageUnselectedOrders];
          return thisPageAndPreviouslySelectedShipments.filter((selected) => shipmentKeys.includes(selected));
        });
        return updated;
      });
    },
    manualSorting: true
  });

  useDebouncedEffect(
    () => {
      const updatedFilters = handleChangeParams();
      setFilters(updatedFilters);
      resetPageIndex();
    },
    300,
    [searchTerm, displayAllAvailableOrders]
  );

  if (paginatedOrders) {
    if (paginatedOrders.data.length) {
      return (
        <AddOrdersToShipmentContentLayout>
          <TableContainer>
            <TableContainer>
              <Table
                head={table.getHeaderGroups().map((headerGroup) => (
                  <TableHeaderRow key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => (
                      <TableHeader
                        key={header.id}
                        isFixed={i === 0}
                        width={header.getSize()}
                        sortDirection={header.column.getIsSorted()}
                        onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                        onResize={header.getResizeHandler()}
                      >
                        <div className="flex items-center gap-1">
                          {header.column.getCanSort() ? (
                            <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                          ) : null}
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </TableHeader>
                    ))}
                  </TableHeaderRow>
                ))}
                body={table.getRowModel().rows.map((row, rowIndex) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell isFixed={cell.column.id === 'order_number'} key={cell.id} rowIndex={rowIndex}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              />
              {Object.keys(rowSelection).length ? (
                <SelectedCount
                  count={Object.keys(rowSelection).length}
                  itemLabel="Order"
                  className="absolute bottom-36 left-1/2 -translate-x-2/4 translate-y-1/4"
                  clickCta="Deselect All"
                  onClick={() => {
                    setRowSelection({});
                    onRowSelectionChange([]);
                  }}
                />
              ) : null}
            </TableContainer>
            <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
          </TableContainer>
        </AddOrdersToShipmentContentLayout>
      );
    }
    return (
      <AddOrdersToShipmentContentLayout>
        <EmptyListView itemLabel="orders" />
      </AddOrdersToShipmentContentLayout>
    );
  }
  if (ordersError) {
    return (
      <AddOrdersToShipmentContentLayout>
        <OrderErrorBanner error={ordersError} />
      </AddOrdersToShipmentContentLayout>
    );
  }
  return (
    <AddOrdersToShipmentContentLayout>
      <Loader loading />
    </AddOrdersToShipmentContentLayout>
  );
};
