import {NetsuiteConfigFieldMappings} from '@shipwell/backend-core-sdk';
import {object, string} from 'yup';
import {CategoryMappingsType} from 'App/containers/integrations/details/components/netSuiteDetails/utils/parseCategoryMappingResponseBody';
import {DataMappingFormValuesType} from 'App/containers/integrations/details/components/netSuiteDetails';

export const billingAndInvoicingModes = [
  {id: 'DEFAULT', label: 'Default', required: true},
  {id: 'FTL', label: 'Full Truckload', required: false},
  {id: 'LTL', label: 'Less Than Truckload', required: false},
  {id: 'VLTL', label: 'Volume Less Than Truckload', required: false},
  {id: 'DRAYAGE', label: 'Drayage', required: false}
];

type NSDataMappingDefaultValuesType = {
  category_fields: CategoryMappingsType;
  custom_fields: NetsuiteConfigFieldMappings;
};

export const defaultValues: NSDataMappingDefaultValuesType = {
  category_fields: {
    billing: {
      DEFAULT: {
        ACC: '',
        ADJUSTMENT: '',
        DETENTION: '',
        FUEL: '',
        FSC: '',
        INSURANCE: '',
        LH: '',
        OTHER: '',
        SF: '',
        TAX: '',
        TONU: '',
        VARIANCE: '',
        LAYOVER: '',
        TEAMDRIVERS: ''
      }
    },
    invoicing: {
      DEFAULT: {
        ACC: '',
        ADJUSTMENT: '',
        DETENTION: '',
        FUEL: '',
        FSC: '',
        INSURANCE: '',
        LH: '',
        OTHER: '',
        SF: '',
        TAX: '',
        TONU: '',
        VARIANCE: '',
        LAYOVER: '',
        TEAMDRIVERS: ''
      }
    }
  },
  custom_fields: {
    default_ns_accounts_payable: '',
    default_ns_filecabinet_folder_id: '',
    default_ns_sales_rep: '',
    default_ns_subsidiary_customer: '',
    default_ns_vendor_payment_terms: '',
    dot_number_ns_field_id: '',
    locked_ns_field_id: '',
    mc_number_ns_field_id: '',
    service_date_ns_field_id: '',
    shipment_id_ns_field_id: '',
    bol_number_ns_field_id: '',
    pro_number_ns_field_id: '',
    pickup_number_ns_field_id: '',
    po_number_ns_field_id: '',
    customer_ref_number_ns_field_id: ''
  }
};

export const validationSchema = object().shape({
  category_fields: object().shape({
    billing: object().shape({
      DEFAULT: object().shape({
        ADJUSTMENT: string(),
        DETENTION: string(),
        FUEL: string(),
        FSC: string(),
        INSURANCE: string(),
        LH: string(),
        OTHER: string(),
        SF: string(),
        TAX: string(),
        TONU: string(),
        VARIANCE: string(),
        LAYOVER: string(),
        TEAMDRIVERS: string()
      })
    }),
    invoicing: object().shape({
      DEFAULT: object().shape({
        ACC: string().nullable(),
        ADJUSTMENT: string(),
        DETENTION: string(),
        FUEL: string(),
        FSC: string(),
        INSURANCE: string(),
        LH: string(),
        OTHER: string(),
        SF: string(),
        TAX: string(),
        TONU: string(),
        VARIANCE: string(),
        LAYOVER: string(),
        TEAMDRIVERS: string()
      })
    })
  }),
  custom_fields: object().shape({
    default_ns_accounts_payable: string().nullable(),
    default_ns_filecabinet_folder_id: string().nullable(),
    default_ns_sales_rep: string().nullable(),
    default_ns_subsidiary_customer: string().nullable(),
    default_ns_vendor_payment_terms: string().nullable(),
    dot_number_ns_field_id: string().nullable(),
    locked_ns_field_id: string().required('This field is required.'),
    mc_number_ns_field_id: string().nullable(),
    service_date_ns_field_id: string().nullable(),
    shipment_id_ns_field_id: string().nullable(),
    bol_number_ns_field_id: string().nullable(),
    pro_number_ns_field_id: string().nullable(),
    pickup_number_ns_field_id: string().nullable(),
    po_number_ns_field_id: string().nullable(),
    customer_ref_number_ns_field_id: string().nullable()
  })
});

export const formatDataMappingInitialValues = (
  defaultValues: NSDataMappingDefaultValuesType,
  values: DataMappingFormValuesType
) => {
  const initialValues = {...defaultValues};

  if (values?.category_fields?.invoicing) {
    initialValues['category_fields']['invoicing'] = {
      ...values.category_fields.invoicing,
      DEFAULT: {
        ...defaultValues.category_fields.invoicing.DEFAULT,
        ...values.category_fields.invoicing.DEFAULT
      }
    };
  }

  if (values?.category_fields?.billing) {
    initialValues['category_fields']['billing'] = {
      ...values.category_fields.billing,
      DEFAULT: {
        ...defaultValues.category_fields.billing.DEFAULT,
        ...values.category_fields.billing.DEFAULT
      }
    };
  }

  if (values?.custom_fields) {
    (Object.keys(initialValues.custom_fields) as Array<keyof NetsuiteConfigFieldMappings>).forEach((customField) => {
      initialValues['custom_fields'][customField] =
        values.custom_fields[customField] || defaultValues.custom_fields[customField];
    });
  }

  return initialValues;
};
