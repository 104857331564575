import './EmptyTableSprinkles.scss';

export const EmptyTableSprinkles = () => {
  return (
    <div className="EmptyTableSprinkles__wrapper">
      <svg
        className="EmptyTableSprinkles"
        viewBox="0 0 457 180"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <g className="EmptyTableSprinkles__ornaments">
          <path
            className="EmptyTableSprinkles__ring EmptyTableSprinkles__ring--0"
            d="M452.464,47.744C455.148,47.744 457.358,45.535 457.358,42.85C457.358,40.166 455.148,37.957 452.464,37.957C449.78,37.957 447.571,40.166 447.571,42.85C447.571,45.535 449.78,47.744 452.464,47.744ZM452.464,45.298C453.806,45.298 454.911,44.193 454.911,42.851C454.911,41.509 453.806,40.404 452.464,40.404C451.122,40.404 450.017,41.509 450.017,42.851C450.017,44.193 451.122,45.298 452.464,45.298Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__ring EmptyTableSprinkles__ring--1"
            d="M52.937,122.128C57.769,122.128 61.745,118.152 61.745,113.32C61.745,108.488 57.769,104.512 52.937,104.512C48.105,104.512 44.129,108.488 44.129,113.32C44.129,118.152 48.105,122.128 52.937,122.128ZM52.937,117.724C55.353,117.724 57.341,115.736 57.341,113.32C57.341,110.903 55.353,108.915 52.937,108.915C50.521,108.915 48.533,110.903 48.533,113.32C48.533,115.736 50.521,117.724 52.937,117.724Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__ring EmptyTableSprinkles__ring--2"
            d="M411.146,178.128C415.978,178.128 419.954,174.152 419.954,169.32C419.954,164.488 415.978,160.512 411.146,160.512C406.314,160.512 402.338,164.488 402.338,169.32C402.338,174.152 406.314,178.128 411.146,178.128ZM411.146,173.725C413.562,173.725 415.551,171.737 415.551,169.32C415.551,166.904 413.562,164.916 411.146,164.916C408.73,164.916 406.742,166.904 406.742,169.32C406.742,171.737 408.73,173.725 411.146,173.725Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__ring EmptyTableSprinkles__ring--3"
            d="M71.81,27.68C75.837,27.68 79.15,24.367 79.15,20.34C79.15,16.313 75.837,13 71.81,13C67.783,13 64.47,16.313 64.47,20.34C64.47,24.367 67.783,27.68 71.81,27.68ZM71.81,24.011C73.823,24.011 75.48,22.354 75.48,20.341C75.48,18.328 73.823,16.671 71.81,16.671C69.797,16.671 68.14,18.328 68.14,20.341C68.14,22.354 69.797,24.011 71.81,24.011Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__cross EmptyTableSprinkles__cross--0"
            d="M9.45,43.617L7.491,43.617L7.491,50.468L0.64,50.468L0.64,52.426L7.49,52.426L7.49,59.276L9.45,59.276L9.45,52.426L16.3,52.426L16.3,50.468L9.45,50.468L9.45,43.617Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__cross EmptyTableSprinkles__cross--1"
            d="M54.96,172.254L53.98,172.254L53.98,175.678L50.555,175.678L50.555,176.657L53.98,176.657L53.98,180.084L54.959,180.084L54.959,176.657L58.384,176.657L58.384,175.678L54.96,175.678L54.96,172.254Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__cross EmptyTableSprinkles__cross--2"
            d="M410.594,0.344L408.636,0.344L408.636,7.194L401.785,7.194L401.785,9.152L408.636,9.152L408.636,16.003L410.594,16.003L410.594,9.153L417.445,9.153L417.445,7.194L410.594,7.194L410.594,0.344Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.344)"
          />
          <path
            className="EmptyTableSprinkles__cross EmptyTableSprinkles__cross--3"
            d="M434.814,95.703L433.346,95.703L433.346,100.841L428.209,100.841L428.209,102.309L433.346,102.309L433.346,107.448L434.814,107.448L434.814,102.309L439.954,102.309L439.954,100.841L434.814,100.841L434.814,95.703Z"
            fill="#0a6fdb"
            fillOpacity=".1"
            transform="translate(-.64 -.483)"
          />
        </g>
        <path
          d="M364.494,11C364.494,8.792 362.702,7 360.494,7L111.746,7C109.538,7 107.746,8.792 107.746,11L107.746,170C107.746,172.208 109.538,174 111.746,174L360.494,174C362.702,174 364.494,172.208 364.494,170L364.494,11Z"
          fill="#fff"
          transform="translate(-.64 -.344)"
        />
        <path
          d="M364.244,11C364.244,8.93 362.564,7.25 360.494,7.25L111.746,7.25C109.676,7.25 107.996,8.93 107.996,11L107.996,170C107.996,172.07 109.676,173.75 111.746,173.75L360.494,173.75C362.564,173.75 364.244,172.07 364.244,170L364.244,11Z"
          fill="#e7f1fb"
          stroke="#006ba2"
          strokeWidth=".5"
          transform="translate(-.64 -.344)"
        />
        <path
          d="M109.666,38.096L362.568,38.096L362.568,48.858L109.666,48.858L109.666,38.096ZM109.666,78.752L362.568,78.752L362.568,89.514L109.666,89.514L109.666,78.752ZM109.666,119.402L362.568,119.402L362.568,130.164L109.666,130.164L109.666,119.402ZM109.666,58.428L362.568,58.428L362.568,69.19L109.666,69.19L109.666,58.428ZM109.666,99.068L362.568,99.068L362.568,109.83L109.666,109.83L109.666,99.068ZM109.666,139.711L362.568,139.711L362.568,150.473L109.666,150.473L109.666,139.711ZM109.666,160.055L362.568,160.055L362.568,170.817L109.666,170.817L109.666,160.055Z"
          fill="#fff"
          fillRule="nonzero"
          transform="translate(-.64 -.344)"
        />
        <path
          d="M107.746,37.711L364.168,37.711"
          fill="none"
          stroke="#006ba2"
          strokeWidth=".5"
          transform="translate(-.64 -.344)"
        />
      </svg>
    </div>
  );
};
