import {NetsuiteSuiteAppConfig, NetsuiteSuiteAppToken, ShipwellError} from '@shipwell/backend-core-sdk';
import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {getSuiteAppToken} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useValidateSuiteAppConnection = (
  suiteAppCertId: NetsuiteSuiteAppConfig['certificate_id'],
  options?: UseQueryOptions<NetsuiteSuiteAppToken, AxiosError<ShipwellError>>
) => {
  const validateSuiteAppConfigQuery = useQuery<NetsuiteSuiteAppToken, AxiosError<ShipwellError>>(
    [SUITE_APP_CONFIG_QUERY, suiteAppCertId] as QueryKey,
    async () => {
      const response = await getSuiteAppToken();
      return response.data;
    },
    {enabled: !!suiteAppCertId, retry: false, ...options}
  );
  return {
    isValidateSuiteAppConnectionLoading:
      validateSuiteAppConfigQuery.isInitialLoading || validateSuiteAppConfigQuery.isFetching
  };
};
