import {Rate, RateStatus} from '@shipwell/genesis-sdk';
import {useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {groupBy} from 'lodash';

import {getRateRequestDetails, listRatesByRequestId} from 'App/api/genesis/typed';

export const useGetParcelRateRequest = ({
  requestParams,
  setAccountId,
  shouldClearAccount,
  selectedProviderCode
}: {
  requestParams: {
    rateRequestId: string;
    accountId?: string;
  };
  setAccountId?: (accountId: string | undefined) => void;
  shouldClearAccount: boolean;
  selectedProviderCode?: string;
}) => {
  const [rateRequestId, setRateRequestId] = useState<string | null>(null);

  const {data: rateRequest} = useQuery({
    queryKey: ['parcelRateRequest', requestParams.rateRequestId],
    queryFn: async () => {
      if (!requestParams?.rateRequestId) return null;

      const rateRequest = await getRateRequestDetails({
        rateRequestId: requestParams?.rateRequestId
      });

      return rateRequest;
    },
    refetchInterval: (rateRequest) => {
      if (!rateRequest) return false;
      const pollStatus = [RateStatus.Processing, RateStatus.Created] as Partial<RateStatus[]>;

      if (!pollStatus.includes(rateRequest?.status)) {
        setRateRequestId(rateRequest?.id);
        return false;
      }

      return 1000; // 1 second
    },
    enabled: !!requestParams?.rateRequestId
  });

  const ratesListQuery = useQuery({
    queryKey: ['parcelRateRequestRates', rateRequestId],
    queryFn: async () => {
      if (!rateRequestId) return null;

      const rates = await listRatesByRequestId(rateRequestId);

      return rates?.data?.map((rate) => ({
        ...rate,
        isGenesisRate: true
      }));
    },
    enabled: !!rateRequestId
  });

  const filterRatesByAccountId = (rates: Record<string, Rate[]>) => {
    return Object.entries(rates).reduce((acc, [providerCode, rates]) => {
      const filteredRates = rates.filter((rate) => rate.capacity_provider.account_id === requestParams?.accountId);

      if (filteredRates.length) {
        acc[providerCode] = filteredRates;
      } else {
        acc[providerCode] = [];
      }

      return acc;
    }, {} as Record<string, Rate[]>);
  };

  const groupRatesByProviderCode = (rates: Rate[]) => {
    return groupBy(rates, 'provider_code');
  };

  const ratesByProviderCode = groupRatesByProviderCode(ratesListQuery?.data as Rate[]);
  const ratesAccounts = ratesListQuery?.data
    ?.filter((rate) => rate.provider_code === selectedProviderCode)
    ?.map((rate) => ({
      label: rate.capacity_provider.account_number,
      value: rate.capacity_provider.account_id
    }))
    .filter((account, index, self) => self.findIndex((t) => t.value === account.value) === index);

  useEffect(() => {
    if (shouldClearAccount) {
      setAccountId?.(undefined);
      return;
    }

    setAccountId?.(ratesAccounts?.[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearAccount, selectedProviderCode]);

  return {
    parcelRates: requestParams?.accountId ? filterRatesByAccountId(ratesByProviderCode) : ratesByProviderCode,
    isLoadingParcelRates: ratesListQuery.isLoading,
    ratesAccounts,
    rateRequest
  };
};
