import {useEffect, useState, useCallback} from 'react';
import {Formik, Form, Field} from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import {object, array, string} from 'yup';
import moment from 'moment';
import {SvgIcon, FormikDateTimePicker, FormikSelect} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {fetchAppointmentTypesPromise, editShipmentPromise} from 'App/api/shipment';
import withStatusToasts from 'App/components/withStatusToasts';
import {toTitleCase, unpackErrors} from 'App/utils/globals';
import {CompleteStop} from 'App/containers/alertsDashboard/components/stopsDetails';
import {getNumOutlinedSvgIconName} from 'App/common';

const scheduledForTimeConstants = {
  AT: 'AT',
  OPEN: 'OPEN',
  BEFORE: 'BEFORE',
  AFTER: 'AFTER'
};

const ShipmentStopSchedulesForm = ({values, onSuccess, onCancel, setError}) => {
  const [appointmentTypes, setAppointmentTypes] = useState([]);

  const validationSchema = object().shape({
    stops: array().of(
      object().shape({
        planned_date: string()
          .nullable()
          .test('planned_date', 'Stop date must be before subsequent stops.', function (val) {
            const {from, parent} = this;
            const currentIndex = parent.ordinal_index;
            //from[1].value gives us the values of the entire shipment which we can use to validate against other stops
            return validatePlannedDate(val, currentIndex, from[1].value);
          })
          .required('A valid date is required.'),

        scheduled_for_time: string(),
        planned_time_window_start: string()
          .nullable()
          .when('scheduled_for_time', {
            is: (value) =>
              value === scheduledForTimeConstants.AT ||
              value === scheduledForTimeConstants.OPEN ||
              value === scheduledForTimeConstants.AFTER,
            then: string().required('Time is required.'),
            otherwise: string()
          }),
        planned_time_window_end: string()
          .nullable()
          .when('scheduled_for_time', {
            is: (value) => value === scheduledForTimeConstants.OPEN || value === scheduledForTimeConstants.BEFORE,
            then: string().required('Time is required.'),
            otherwise: string()
          })
      })
    )
  });

  useEffect(() => {
    fetchAppointmentTypes();
  }, [fetchAppointmentTypes]);

  const fetchAppointmentTypes = useCallback(async () => {
    try {
      const response = await fetchAppointmentTypesPromise();
      setAppointmentTypes(response.body);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  }, [setError]);

  const editShipmentMutation = useMutation(({shipmentId, values}) => editShipmentPromise(shipmentId, values), {
    onSuccess: (response) => onSuccess(response.body)
  });

  const handleSubmit = async (values, {setSubmitting, setErrors}) => {
    //use the scheduled_for_time to determine which window times to save
    values.stops.forEach((stop) => {
      setStopTimeWindows(stop);
    });
    await editShipmentMutation.mutateAsync(
      {shipmentId: values.id, values},
      {
        onMutate: () => setSubmitting(true),
        onError: (error) => {
          console.error(error);
          setErrors(unpackErrors(error.field_errors, {}, ['stops']));
          setError('Error!', error.error_description);
        },
        onSettled: () => setSubmitting(false)
      }
    );
  };

  const setStopTimeWindows = (stop) => {
    if (stop.scheduled_for_time === scheduledForTimeConstants.AT) {
      stop.planned_time_window_end = stop.planned_time_window_start;
    } else if (stop.scheduled_for_time === scheduledForTimeConstants.AFTER) {
      stop.planned_time_window_end = null;
    } else if (stop.scheduled_for_time === scheduledForTimeConstants.BEFORE) {
      stop.planned_time_window_start = null;
    }
  };

  const getStartTimeLabel = (scheduledForTime) => {
    switch (scheduledForTime) {
      case scheduledForTimeConstants.AT:
        return 'At Time';
      case scheduledForTimeConstants.AFTER:
        return 'After Time';
      case scheduledForTimeConstants.OPEN:
        return 'Start Time';
    }
  };

  const getEndTimeLabel = (scheduledForTime) => {
    switch (scheduledForTime) {
      case scheduledForTimeConstants.BEFORE:
        return 'Before Time';
      case scheduledForTimeConstants.OPEN:
        return 'End Time';
    }
  };

  const formatInitialValues = (values) => {
    values?.stops?.forEach((stop) => {
      if (stop.planned_time_window_start === stop.planned_time_window_end) {
        stop.scheduled_for_time = scheduledForTimeConstants.AT;
      } else if (stop.planned_time_window_start && !stop.planned_time_window_end) {
        stop.scheduled_for_time = scheduledForTimeConstants.AFTER;
      } else if (!stop.planned_time_window_start && stop.planned_time_window_end) {
        stop.scheduled_for_time = scheduledForTimeConstants.BEFORE;
      } else {
        stop.scheduled_for_time = scheduledForTimeConstants.OPEN;
      }
      if (stop.appointment_type) {
        stop.appointment_type = {
          label: stop.appointment_type.name,
          value: stop.appointment_type.code,
          ...stop.appointment_type
        };
      }
    });
    return values;
  };

  const validatePlannedDate = (stopValue, stopIndex, formValues) => {
    if (stopValue) {
      for (let i = parseInt(stopIndex) + 1; i < formValues.stops.length; i++) {
        if (formValues.stops[i]?.planned_date) {
          //all previous stops must have same or earlier date
          if (moment(stopValue, 'YYYY-MM-DD').isAfter(moment(formValues.stops[i]?.planned_date, 'YYYY-MM-DD'))) {
            return false;
          }
        }
      }
    }
    return true;
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formatInitialValues({...values})}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, values}) => (
        <Form className="mb-14 overflow-visible">
          {values.stops?.map((stop, index) => {
            const stopType = stop.is_pickup ? (stop.is_dropoff ? 'Stop' : 'Pickup') : 'Delivery';
            const isStopComplete = stop.confirmed_arrival_at || stop.unconfirmed_arrival_at;
            const numOutlinedSvgIconName = getNumOutlinedSvgIconName(index);
            return (
              <div
                className={classNames('flex flex-col', {
                  'border-b border-sw-border ': index < values.stops.length - 1,
                  'pt-4': index > 0,
                  'pb-4': !isStopComplete,
                  'pb-2': isStopComplete
                })}
                key={stop.id}
              >
                <div className="mb-3 flex items-center font-bold">
                  <SvgIcon name={numOutlinedSvgIconName} className="mr-2" color="$sw-icon" />
                  {`Stop ${index + 1}`}
                </div>
                {isStopComplete ? (
                  <CompleteStop stop={stop} index={index} />
                ) : (
                  <div className="grid grid-cols-2 gap-x-3 grid-auto-rows-15">
                    <Field
                      name={`stops[${index}].location.address`}
                      label={`${stopType} Location`}
                      component={AddressSearch}
                      disabled
                    />
                    <Field
                      name={`stops[${index}].planned_date`}
                      label={`${stopType} Date`}
                      component={FormikDateTimePicker}
                      required
                      showTimeSelect={false}
                    />
                    <Field
                      name={`stops[${index}].appointment_type`}
                      label={`Appointment Type`}
                      component={FormikSelect}
                      options={appointmentTypes.map((type) => {
                        return {value: type.code, label: type.name, ...type};
                      })}
                    />

                    <Field
                      name={`stops[${index}].scheduled_for_time`}
                      label={`Scheduled For Time`}
                      component={FormikSelect}
                      simpleValue
                      options={Object.keys(scheduledForTimeConstants).map((key) => {
                        return {value: key, label: toTitleCase(key)};
                      })}
                      clearable={false}
                    />
                    {[
                      scheduledForTimeConstants.AT,
                      scheduledForTimeConstants.AFTER,
                      scheduledForTimeConstants.OPEN
                    ].includes(get(values, `stops[${index}].scheduled_for_time`)) && (
                      <Field
                        name={`stops[${index}].planned_time_window_start`}
                        label={getStartTimeLabel(get(values, `stops[${index}].scheduled_for_time`))}
                        component={FormikDateTimePicker}
                        showTimeSelectOnly
                        timezone={get(values, `stops[${index}].location.address.timezone`)}
                        ignoreTimezone
                        required
                      />
                    )}
                    {[scheduledForTimeConstants.BEFORE, scheduledForTimeConstants.OPEN].includes(
                      get(values, `stops[${index}].scheduled_for_time`)
                    ) && (
                      <Field
                        name={`stops[${index}].planned_time_window_end`}
                        label={getEndTimeLabel(get(values, `stops[${index}].scheduled_for_time`))}
                        component={FormikDateTimePicker}
                        showTimeSelectOnly
                        timezone={get(values, `stops[${index}].location.address.timezone`)}
                        ignoreTimezone
                        required
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}

          <ModalFormFooter isSubmitting={isSubmitting} onCancel={onCancel} primaryActionName={'Save'} />
        </Form>
      )}
    </Formik>
  );
};

ShipmentStopSchedulesForm.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.string,
    stops: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        is_pickup: PropTypes.bool,
        is_dropoff: PropTypes.bool,
        confirmed_arrival_at: PropTypes.string,
        unconfirmed_arrival_at: PropTypes.string
      })
    )
  }),
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  setError: PropTypes.func,
  setSuccess: PropTypes.func
};

export default withStatusToasts(ShipmentStopSchedulesForm);
