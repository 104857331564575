import {JSX, MouseEvent} from 'react';
import {Button} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';

export type AppointmentButtonGroupProps = {
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  status?: AppointmentStatusEnum | null;
  onCancelClick: (event: MouseEvent<HTMLButtonElement>) => unknown;
  onRescheduleClick: (event: MouseEvent<HTMLButtonElement>) => unknown;
  onScheduleClick: (event: MouseEvent<HTMLButtonElement>) => unknown;
};
/**
 * Lightweight wrapper around the `import('@shipwell/shipwell-ui').Button` component(s) that provides logic for which buttons
 * to display based on the status of an appointment. All buttons are returned with the empty parent node `<></>`.
 */
const AppointmentButtonGroup = ({
  disabled,
  status,
  title,
  isLoading = false,
  onCancelClick,
  onRescheduleClick,
  onScheduleClick
}: AppointmentButtonGroupProps): JSX.Element | null => {
  if (!status) {
    return null; // could not determine the status of the appointment and we should not display anything.
  }
  const scheduledStatuses: string[] = [AppointmentStatusEnum.Scheduled, AppointmentStatusEnum.Rescheduled];
  const managedStatuses: string[] = [AppointmentStatusEnum.Cancelled, AppointmentStatusEnum.Rejected];
  if (scheduledStatuses.includes(status)) {
    return (
      <>
        <Button disabled={disabled} title={title} size="sm" variant="secondary" color="warning" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          disabled={disabled}
          title={title}
          size="sm"
          variant="secondary"
          color="primary"
          onClick={onRescheduleClick}
        >
          Reschedule
        </Button>
      </>
    );
  }
  if (status === AppointmentStatusEnum.Unscheduled) {
    return (
      <Button
        isLoading={isLoading}
        disabled={disabled}
        title={title}
        size="sm"
        variant="primary"
        color="primary"
        onClick={onScheduleClick}
      >
        Schedule
      </Button>
    );
  }
  if (status === AppointmentStatusEnum.NoShow) {
    return (
      <Button
        isLoading={isLoading}
        disabled={disabled}
        title={title}
        size="sm"
        variant="secondary"
        onClick={onRescheduleClick}
      >
        Reschedule
      </Button>
    );
  }
  if (managedStatuses.includes(status)) {
    return (
      <Button
        isLoading={isLoading}
        disabled={disabled}
        title={title}
        size="sm"
        variant="secondary"
        onClick={onRescheduleClick}
      >
        Manage
      </Button>
    );
  }
  return null;
};

export default AppointmentButtonGroup;
