import PropTypes from 'prop-types';
import './InlineNotification.scss';

const InlineNotification = ({type, title, message, onClose}) => {
  let bgColor = '';
  let iconColor = '';
  let icon = '';

  switch (type) {
    case 'success':
      bgColor = '#1B7B3B10';
      iconColor = '#1B7B3B';
      icon = '✓';
      break;
    case 'warning':
      bgColor = '#DF910010';
      iconColor = '#DF9100';
      icon = '!';
      break;
    case 'error':
      bgColor = '#B41D1310';
      iconColor = '#B41D13';
      icon = '!';
      break;
    case 'info':
      bgColor = '#2196f310';
      iconColor = '#2196f3';
      icon = 'i';
      break;
    default:
      bgColor = '#0A6FDB10';
      iconColor = '#0A6FDB';
      icon = '✓';
  }

  return (
    <div className="custom-alert" style={{backgroundColor: bgColor}}>
      <span className="custom-alert-icon" style={{color: '#FFFFFF', backgroundColor: iconColor}}>
        {icon}
      </span>
      <div className="custom-alert-content">
        <span className="custom-alert-title" style={{color: iconColor}}>
          {title}
        </span>
        <span className="custom-alert-message" style={{color: '#000000'}}>
          {message}
        </span>
      </div>
      {onClose && (
        <span className="custom-alert-close" onClick={onClose}>
          &times;
        </span>
      )}
    </div>
  );
};

InlineNotification.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default InlineNotification;
