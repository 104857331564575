import {LocationType} from '@shipwell/corrogo-sdk';
import {DeprecatedButton, Modal} from '@shipwell/shipwell-ui';
import {Divider} from 'App/containers/shipments/details/components/SidebarActions';
import {useV3Shipment} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import {AddDrayageShipmentStopsForm} from 'App/containers/shipments/components/StopsFields/DrayageShipmentStopsFields';
import {
  ContainerPickupStopType,
  ContainerReturnStopType
} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';

function AddContainerStop({shipmentId}: {shipmentId: string}) {
  const {
    isLoading,
    context: {modeSpecificDisplayValues, isCancelled, stopsDisplayValues}
  } = useV3Shipment(shipmentId);
  const [showAddStopModal, toggleShowAddStopModal] = useToggle(false);

  if (isLoading) {
    return null;
  }

  const actionDescription = modeSpecificDisplayValues.isImport ? 'Add Container Return' : 'Add Container Pickup';
  const hasContainerStop = stopsDisplayValues?.some((stop) => stop.locationType === LocationType.ContainerYard);

  const handleClickAddStop = () => {
    toggleShowAddStopModal();
  };

  return (
    <>
      <DeprecatedButton
        disabled={isLoading || isCancelled || hasContainerStop}
        variant="text"
        onClick={handleClickAddStop}
      >
        {actionDescription}
      </DeprecatedButton>
      <Divider />
      {showAddStopModal ? (
        <Modal show={showAddStopModal} title={actionDescription}>
          <AddDrayageShipmentStopsForm
            shipmentId={shipmentId}
            onClose={toggleShowAddStopModal}
            stopType={modeSpecificDisplayValues.isImport ? ContainerReturnStopType : ContainerPickupStopType}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default AddContainerStop;
