import {useState, useEffect, Fragment} from 'react';
import {get} from 'lodash';
import {Checkbox} from '@shipwell/shipwell-ui';
import store from 'App/routes/store';

/*
 * Filter Component for List Select
 */
const ListSelect = (props) => {
  const {onChange, options, name, parentValue, allowMultiSelect} = props;

  const [selected, setSelected] = useState(parentValue || []);

  const selectOption = (value) => {
    if (!allowMultiSelect) {
      if (selected && selected.includes(value)) {
        setSelected([]);
      } else {
        setSelected([value]);
      }
    } else if (selected && selected.includes(value)) {
      const newSelection = selected.slice();
      newSelection.splice(newSelection.indexOf(value), 1);
      setSelected(newSelection);
    } else {
      setSelected([...selected, value]);
    }
  };

  useEffect(() => {
    options.forEach((option) => {
      if (option.useStore) {
        option.value = get(store.getState(), option.useStore);
      }
    });
  }, []);

  /*
   * Reset value when parent component sends signal
   */
  useEffect(() => {
    if (parentValue && !parentValue.length && selected.length) {
      setSelected([]);
    }
  }, [parentValue]);

  /*
   * Trigger to update parent component
   */
  useEffect(() => {
    if (selected) {
      handleSelection(selected);
    }
  }, [selected]);

  const handleSelection = (values) => {
    if (onChange) {
      const newValue = {};
      newValue[name] = values;
      onChange(newValue);
    }
  };

  return (
    <div className="filter__listSelect filter__option">
      {options &&
        options.length &&
        options.map((option) => {
          return (
            <Checkbox
              label={option.label}
              key={option.label}
              name={option.label}
              checked={selected.includes(option.value)}
              onChange={() => selectOption(option.value)}
            />
          );
        })}
    </div>
  );
};

export default ListSelect;
