import {change} from 'redux-form';
import _ from 'lodash';
import store from 'App/routes/store';
import {getCarrierRelationshipsPromise} from 'App/actions/vendors';

//function to load product details
export function loadProductIntoForm(dispatch, form, productId, index, productsList) {
  const hazmatCodes = store.getState().shipments.hazmatCodes;
  const match = productsList.filter((e) => e.id == productId);
  if (match.length > 0) {
    //remove any NaNs
    if (match[0]) {
      const keys = Object.keys(match[0]);
      for (var i = 0; i < keys.length; i++) {
        if (typeof match[0][keys[i]] === 'number' && isNaN(match[0][keys[i]])) {
          match[0][keys[i]] = null;
        }
      }
    }
    if (match[0] && match[0].hazmat_identification_number !== null && hazmatCodes) {
      const matchingHazmat = hazmatCodes.filter(
        (e) => e.identification_number == match[0].hazmat_identification_number
      );
      if (matchingHazmat.length > 0) {
        match[0].hazmatDetails = matchingHazmat[0].id;
        match[0].hazmat = true;
      }
    }

    match[0].weight_unit = 'LB';
    //dispatch the changes
    dispatch(change(form, 'line_items[' + index + ']', match[0]));
  }
}

//function to update selected equipment when modes are selected
export function updateFormEquipment(dispatch, form, oldValues, newValues) {
  if (
    newValues.mode &&
    (newValues.mode.filter((e) => e.id === 2).length > 0 || newValues.mode.filter((e) => e.id === 4).length > 0)
  ) {
    //mode was changed to LTL or VLTL, if there are no line items, dispatch one
    if (!newValues.line_items || (newValues.line_items && newValues.line_items.length === 0)) {
      dispatch(
        change(form, 'line_items', [
          {
            length: '40',
            width: '48',
            package_type: 'PLT',
            length_unit: 'IN',
            weight_unit: 'LB',
            stackable: false
          }
        ])
      );
    }
    let currentEquip = [];
    if (newValues.equipment_types) {
      //also make sure dry van is in the list of selected equipment types
      currentEquip = JSON.parse(JSON.stringify(newValues.equipment_types));
    }
    if (Array.isArray(currentEquip) && currentEquip.filter((e) => e.id === 1).length === 0) {
      //we need to add dry van to the list
      currentEquip.push({id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'});

      dispatch(change(form, 'equipment_types', currentEquip));
    }
  }
}

export function updateFormBillTo(dispatch, form, oldValues, newValues, updateVendor) {
  const company = store.getState().auth.company;
  const selectedRelationship = store.getState().vendors.selectedRelationship;
  if (selectedRelationship && selectedRelationship.bill_to_vendor_by_default && updateVendor) {
    const defaultBillTo = {};

    defaultBillTo.direction = '3RD_PARTY';
    if (selectedRelationship.shipwell_vendor) {
      defaultBillTo.company_name = selectedRelationship.shipwell_vendor.name;
      defaultBillTo.company_address = selectedRelationship.shipwell_vendor.billing_address
        ? {
            formatted_address: selectedRelationship.shipwell_vendor.billing_address.formatted_address
          }
        : selectedRelationship.shipwell_vendor.mailing_address
        ? {formatted_address: selectedRelationship.shipwell_vendor.mailing_address.formatted_address}
        : {};
      defaultBillTo.contact_email =
        selectedRelationship.shipwell_vendor.billing_emails?.[0] || selectedRelationship.shipwell_vendor.primary_email;
      defaultBillTo.contact_phone =
        selectedRelationship.shipwell_vendor.primary_phone_number &&
        selectedRelationship.shipwell_vendor.primary_phone_number;
    }
    dispatch(change(form, 'bill_to_override', defaultBillTo));
  } else if (selectedRelationship && !selectedRelationship.bill_to_vendor_by_default && updateVendor) {
    if (company && company.name && company.billing_address) {
      const defaultBillTo = {};

      defaultBillTo.company_name = company.name;
      if (company.billing_address) {
        defaultBillTo.company_address = {
          formatted_address: company.billing_address.formatted_address
        };
      }
      defaultBillTo.direction = 'PREPAID';
      defaultBillTo.contact_email = company.billing_emails?.[0] || company.primary_email;
      defaultBillTo.contact_phone = company.primary_phone_number;

      dispatch(change(form, 'bill_to_override', defaultBillTo));
    }
  } else {
    if (newValues.bill_to_override && newValues.bill_to_override.direction === 'PREPAID') {
      //if prepaid, default to the current user's company information
      if (company && company.name && company.billing_address) {
        const defaultBillTo = {};

        defaultBillTo.company_name = company.name;
        if (company.billing_address) {
          defaultBillTo.company_address = {
            formatted_address: company.billing_address.formatted_address
          };
        }
        defaultBillTo.direction = 'PREPAID';
        defaultBillTo.contact_email = company.billing_emails?.[0] || company.primary_email;
        defaultBillTo.contact_phone = company.primary_phone_number;

        dispatch(change(form, 'bill_to_override', defaultBillTo));
      }
    }

    if (newValues.bill_to_override && newValues.bill_to_override.direction === 'COLLECT') {
      //if COLLECT, default to the delivery stop's company information
      if (newValues.stops && newValues.stops.length > 1) {
        //get the final stop
        const stop = newValues.stops[newValues.stops.length - 1];
        const defaultBillTo = {};
        if (stop) {
          defaultBillTo.company_name = stop.company || '';
          if (stop.location && stop.location.address) {
            defaultBillTo.company_address = {
              formatted_address: stop.location.address.formatted_address
            };
          }
          if (stop.point_of_contacts && stop.point_of_contacts.length > 0) {
            defaultBillTo.contact_email = stop.point_of_contacts[0].email || '';
            defaultBillTo.contact_phone = stop.point_of_contacts[0].phone_number || '';
          }
        }

        defaultBillTo.direction = 'COLLECT';

        dispatch(change(form, 'bill_to_override', defaultBillTo));
      }
    }

    if (newValues.bill_to_override && newValues.bill_to_override.direction === '3RD_PARTY') {
      //if third party, default empty object

      const defaultBillTo = {};
      defaultBillTo.direction = '3RD_PARTY';

      if (
        selectedRelationship &&
        selectedRelationship.bill_to_vendor_by_default &&
        selectedRelationship.shipwell_vendor
      ) {
        defaultBillTo.company_name = selectedRelationship.shipwell_vendor.name;
        defaultBillTo.company_address = selectedRelationship.shipwell_vendor.billing_address
          ? {
              formatted_address: selectedRelationship.shipwell_vendor.billing_address.formatted_address
            }
          : selectedRelationship.shipwell_vendor.mailing_address
          ? {formatted_address: selectedRelationship.shipwell_vendor.mailing_address.formatted_address}
          : {};
        defaultBillTo.contact_email =
          selectedRelationship.shipwell_vendor.billing_emails?.[0] ||
          selectedRelationship.shipwell_vendor.primary_email;
        defaultBillTo.contact_phone =
          selectedRelationship.shipwell_vendor.primary_phone_number &&
          selectedRelationship.shipwell_vendor.primary_phone_number;
      }
      dispatch(change(form, 'bill_to_override', defaultBillTo));
    }
  }
}

export function updateSelectedCarriers(values, dispatch, props, previousValues) {
  //look up the vendors with this tag as the filter
  if (values?.recipient_groups?.value) {
    getCarrierRelationshipsPromise({pageSize: 10000, tags: [values.recipient_groups.value]}).then((response) => {
      if (response && response.results) {
        const userObjs = previousValues.vendors ? [...previousValues.vendors] : [];
        response.results.forEach((vendor) => {
          vendor.point_of_contacts.forEach((poc) => {
            const nameStr =
              vendor.shipwell_vendor.name +
              ' - ' +
              poc.first_name +
              (poc.last_name ? ' ' + poc.last_name : '') +
              ' (' +
              poc.email +
              ')';
            if (userObjs.filter((e) => e.value === poc.user).length === 0) {
              userObjs.push({value: poc.user, label: nameStr});
            }
          });
        });
        dispatch(change('getSpotQuotes', 'vendors', userObjs));
      }
    });
  }
}
