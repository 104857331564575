const MappingFormFieldsHeader = ({labelLeft, labelRight}: {labelLeft: string; labelRight: string}) => (
  <div className="grid grid-cols-2 items-start justify-items-stretch gap-x-[10px] gap-y-[12px]">
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-start">
        <img alt="shipwell-logo" className="mr-1 max-h-[15px]" src="/images/shipwell_logo.png" />
        <span className="font-bold">Shipwell</span>
      </div>
      {labelLeft && <div className="mt-2.5 text-[10px] uppercase text-sw-form-helper-text">{labelLeft}</div>}
    </div>
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-start">
        <img alt="netSuite-logo" className="mr-1 max-h-[15px]" src="/images/integration-netsuite.png" />
        <span className="font-bold">NetSuite</span>
      </div>
      {labelRight && <div className="mt-2.5 text-[10px] uppercase text-sw-form-helper-text">{labelRight}</div>}
    </div>
  </div>
);

export default MappingFormFieldsHeader;
