import {JSX, ReactNode} from 'react';
import {useUserMe} from 'App/data-hooks';
import {useHasUserPermissions} from 'App/data-hooks/users/useUserPermissions';

export const PermissionsFallback = ({
  children,
  permissions,
  fallbackComponent = null
}: {
  children: ReactNode;
  permissions: string[];
  fallbackComponent?: ReactNode;
}): JSX.Element => {
  const hasPermissions = useHasUserPermissions(permissions);
  const fallback = fallbackComponent;
  if (hasPermissions) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export const QuotingLimitedUserFallback = ({
  children,
  fallbackComponent = null
}: {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}): JSX.Element => {
  const {data: userData} = useUserMe();
  const {user} = userData || {};
  const fallback = fallbackComponent;
  if (!user?.is_quoting_limited_user) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export const PermissionsWithQuotingLimitedUserFallback = ({
  children,
  permissions,
  permissionsFallbackComponent,
  quotingLimitedFallbackComponent
}: {
  children: ReactNode;
  permissions: string[];
  permissionsFallbackComponent?: JSX.Element;
  quotingLimitedFallbackComponent?: JSX.Element;
}): JSX.Element => {
  return (
    <>
      <PermissionsFallback permissions={permissions} fallbackComponent={permissionsFallbackComponent}>
        <QuotingLimitedUserFallback fallbackComponent={quotingLimitedFallbackComponent}>
          {children}
        </QuotingLimitedUserFallback>
      </PermissionsFallback>
    </>
  );
};
