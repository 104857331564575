import {ChargeCategory, NetsuiteConfigCategoryMappings} from '@shipwell/backend-core-sdk';

export type CategoryMappingsType = {
  invoicing: {
    [key: string]: {
      [key in ChargeCategory]: string;
    };
  };
  billing: {
    [key: string]: {
      [key in ChargeCategory]: string;
    };
  };
};
const parseCategoryMappingResponseBody = (responseBody: NetsuiteConfigCategoryMappings): CategoryMappingsType => {
  const categoryMappings: CategoryMappingsType = {
    invoicing: {},
    billing: {}
  };

  (Object.keys(categoryMappings) as Array<keyof NetsuiteConfigCategoryMappings>).forEach((service) => {
    responseBody[service]?.forEach((lineItem) => {
      const mode = lineItem.shipment_mode || 'DEFAULT';
      categoryMappings[service][mode] = {
        ...categoryMappings[service][mode],
        [lineItem.charge_category]: lineItem.netsuite_id
      };
    });
  });

  return categoryMappings;
};

export default parseCategoryMappingResponseBody;
