import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import moment from 'moment';

type Weekday = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

const openDays: Record<
  Weekday,
  keyof Pick<
    AddressBookEntry,
    | 'open_friday'
    | 'open_monday'
    | 'open_saturday'
    | 'open_sunday'
    | 'open_thursday'
    | 'open_tuesday'
    | 'open_wednesday'
  >
> = {
  sunday: 'open_sunday',
  monday: 'open_monday',
  tuesday: 'open_tuesday',
  wednesday: 'open_wednesday',
  thursday: 'open_thursday',
  friday: 'open_friday',
  saturday: 'open_saturday'
};
const weekDayNames: Weekday[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

/**
 * Finds first open day of address book entry's office hours
 * if all days are closed, return monday
 */
export function getOpenBusinessDay(address: AddressBookEntry, plannedDate?: string | moment.Moment): Weekday {
  for (let nDays = 0; nDays < 7; nDays++) {
    const date = moment(plannedDate).add(nDays, 'days');
    const weekDay = weekDayNames[date.day()];
    // if address if open on this day of the week, return week day name
    if (isOpenBusinessDay(address, date)) {
      return weekDay;
    }
  }
  // Our current UX in the app is to default to mondays if all days are closed
  return 'monday';
}

export function isOpenBusinessDay(address: AddressBookEntry, date?: string | moment.Moment): boolean {
  const dayNumber = moment(date).day();
  const weekDay = weekDayNames[dayNumber];
  const openDayOfTheWeek = openDays[weekDay];
  return !!address?.[openDayOfTheWeek];
}
