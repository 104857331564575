import {registration} from 'App/api';

/**
 * Create UPS Account
 */
export function createUPSRegistration(accountNumber, body = {}) {
  return (dispatch) => registration.createUPSRegistration(accountNumber, body);
}

/**
 * Get UPS Accounts
 * @param {Object} opts
 */
export function getUPSRegistrationAccounts(opts = {}) {
  return (dispatch) => registration.getUPSRegistrationAccounts(opts);
}
