import {
  Configuration,
  RfpBidOptRequest,
  RfpOptimizedApi,
  RfpLaneCarrierOptResponse,
  RfpOptimizedApiRfpOptimizedRfpBidOptGetRequest,
  RfpOptimizedApiRfpOptimizedRfpBidOptRfpBidOptIdRfpLaneOptsGetRequest,
  RfpOptimizedApiRfpOptimizedCarrierBidRequestsGetRequest,
  RfpCarrierBidRequestOptRequest,
  RfpCarrierBidRequestOptRequestBidRequestStateEnum,
  RfpOptimizedApiRfpOptimizedRfpBidOptRfpBidOptIdRfpBidOptDocumentsPostRequest,
  RfpOptimizedApiRfpOptimizedDataMetricsCarrierSelectionGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosResponse} from 'axios';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRfpOptimizedApi() {
  return new RfpOptimizedApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export const getOptimizedRfpBids = async (
  requestParameters: RfpOptimizedApiRfpOptimizedRfpBidOptGetRequest = {page: 1, pageSize: 1000}
) => {
  const axiosResponse = await createRfpOptimizedApi().rfpOptimizedRfpBidOptGet(requestParameters);
  return axiosResponse.data;
};

export const getOptimizedRfpDetails = (rfpBidOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdGet({rfpBidOptId});
};

export const getOptimizedRfpLanes = (
  rfpBidOptId: string,
  opts: Omit<RfpOptimizedApiRfpOptimizedRfpBidOptRfpBidOptIdRfpLaneOptsGetRequest, 'rfpBidOptId'> = {
    page: 1,
    pageSize: 20
  }
) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpLaneOptsGet({rfpBidOptId, ...opts});
};

export const createOptimizedRfpBid = (rfpBidOptRequest: RfpBidOptRequest) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptPost({rfpBidOptRequest});
};

export type UpdateRfpParams = {
  rfpBidOptId: string;
  bidExpiryDateTime?: string;
  endDate: string;
  startDate: string;
} & Omit<RfpBidOptRequest, 'bid_expiry_date_time' | 'end_date' | 'start_date'>;

export const updateOptimizedRfpBid = (rfp: UpdateRfpParams) => {
  const {rfpBidOptId, bidExpiryDateTime, endDate, startDate, name, notes, archived} = rfp;
  const rfpBidOptRequest: RfpBidOptRequest = {
    bid_expiry_date_time: bidExpiryDateTime,
    archived,
    end_date: endDate,
    start_date: startDate,
    name,
    notes
  };
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdPut({rfpBidOptId, rfpBidOptRequest});
};

export const deleteOptimizedRfpBid = (rfpBidOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdDelete({rfpBidOptId});
};

export const getOptimizedRfpCarrierBidRequests = async (
  requestParameters: RfpOptimizedApiRfpOptimizedCarrierBidRequestsGetRequest = {page: 1, pageSize: 1000}
) => {
  const axiosResopnse = await createRfpOptimizedApi().rfpOptimizedCarrierBidRequestsGet(requestParameters);
  return axiosResopnse.data;
};

export const getOptimizedRfpCarrierBidDetails = (rfpCarrierBidRequestOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedCarrierBidRequestsRfpCarrierBidRequestOptIdGet({
    rfpCarrierBidRequestOptId
  });
};

type BidRequest = {
  rfpBidOptId: string;
  bidRequestState: RfpCarrierBidRequestOptRequestBidRequestStateEnum;
  carrierCompany: string;
  pocEmail: string;
};

export const createOptimizedBidRequest = (bid: BidRequest) => {
  const {rfpBidOptId, bidRequestState, carrierCompany, pocEmail} = bid;
  const rfpCarrierBidRequestOptRequest: RfpCarrierBidRequestOptRequest = {
    bid_request_state: bidRequestState,
    carrier_company: carrierCompany,
    rfp_bid_opt: rfpBidOptId,
    poc_email: pocEmail
  };
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdCarrierBidRequestPost({
    rfpBidOptId,
    rfpCarrierBidRequestOptRequest
  });
};

export const getRfpDocuments = (rfpBidOptId: string, opts = {page: 1, pageSize: 20}) => {
  const {page, pageSize} = opts;

  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpBidOptDocumentsGet({rfpBidOptId, page, pageSize});
};

export const getRfpCarrierDocuments = (rfpCarrierBidRequestOptId: string, opts = {page: 1, pageSize: 20}) => {
  const {page, pageSize} = opts;

  return createRfpOptimizedApi().rfpOptimizedCarrierBidRequestsRfpCarrierBidRequestOptIdCarrierBidReqDocumentsGet({
    rfpCarrierBidRequestOptId,
    page,
    pageSize
  });
};

export const createRfpDocument = (
  requestParameters: RfpOptimizedApiRfpOptimizedRfpBidOptRfpBidOptIdRfpBidOptDocumentsPostRequest
) => {
  // SDK returns file as any

  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpBidOptDocumentsPost(requestParameters);
};

export const getRfpCarrierCsv = (rfpCarrierBidRequestOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedCarrierBidRequestsRfpCarrierBidRequestOptIdDownloadCsvGet({
    rfpCarrierBidRequestOptId
  });
};

export const getRfpCsv = (rfpBidOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdExportCsvGet({rfpBidOptId});
};

export const getCarrierWithDataMetrics = (
  requestParameters: RfpOptimizedApiRfpOptimizedDataMetricsCarrierSelectionGetRequest
) => {
  return createRfpOptimizedApi().rfpOptimizedDataMetricsCarrierSelectionGet(requestParameters);
};

// backend missing this, remove once they update type
export interface RfpLaneCarrierOptResponseExtended extends RfpLaneCarrierOptResponse {
  lane_carriers_without_bids?: string[];
}

export const getRfpLaneBids = (rfpLaneOptId: string, rfpBidOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpLaneOptsRfpLaneOptIdCarrierLanesGet({
    rfpLaneOptId,
    rfpBidOptId
  }) as Promise<AxiosResponse<RfpLaneCarrierOptResponseExtended[]>>;
};

type CreateRoutingGuidesParams = {
  rfpBidOptId: string;
  workflowTenderExpirySeconds: number;
  fuelSurchargeId?: string;
};
export const createRoutingGuides = ({
  rfpBidOptId,
  workflowTenderExpirySeconds,
  fuelSurchargeId
}: CreateRoutingGuidesParams) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdCreateContractsRgPost({
    rfpBidOptId,
    inlineObject18: {
      workflow_tender_expiry_seconds: workflowTenderExpirySeconds,
      fuel_surcharge_id: fuelSurchargeId
    }
  });
};

type CreateContractsParams = {
  rfpBidOptId: string;
  fuelSurchargeId?: string;
};
export const createContracts = ({rfpBidOptId, fuelSurchargeId}: CreateContractsParams) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdCreateOnlyContractsPost({
    rfpBidOptId,
    inlineObject19: {
      fuel_surcharge_id: fuelSurchargeId
    }
  });
};

export type UpdateRfpLaneBidParams = {
  rfpLaneOptId: string;
  rfpBidOptId: string;
  orderedBids: RfpLaneCarrierOptResponse[];
};

export const updateRfpLaneBid = (params: UpdateRfpLaneBidParams) => {
  const {rfpLaneOptId, rfpBidOptId, orderedBids} = params;
  const inlineObject = orderedBids.map((bid) => ({lane_carrier_id: bid.id, order_index: bid.order_index}));
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpLaneOptsRfpLaneOptIdCarrierLanesPut({
    rfpLaneOptId,
    rfpBidOptId,
    inlineObject
  });
};

export const getContractsWorkflowCreationProgress = (rfpBidOptId: string) => {
  return createRfpOptimizedApi().rfpOptimizedRfpBidOptRfpBidOptIdRfpLaneContractsWorkflowCreationProgressGet({
    rfpBidOptId
  });
};
