import {useState} from 'react';
import {FormikHelpers} from 'formik';
import {RegisterUPS} from '@shipwell/backend-core-singlerequestparam-sdk';
import UpsConnectedAccountsTable from './components/upsConnectAccountsTable';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';
import {useCreateUpsAccountMutation} from 'App/data-hooks/parcel/UPS/hooks/useCreateUpsAccountMutation';
import PageHeader from 'App/common/pageHeader';
import {UPSRegistrationForm, UPSRegistrationFormValues} from 'App/formComponents/forms/upsRegistration';
import {cleanPayload} from 'App/utils/globals';
import {unpackShipwellErrorForFormik, openPopupWindow} from 'App/utils/globalsTyped';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {upsPopupSizeOptions} from 'App/containers/ups/registration/constants';
import './styles.scss';

const UPSRegistration = ({setError}: WithStatusToastProps) => {
  const [shouldOpenForm, setShouldOpenForm] = useState(false);

  const {isUpsAuthenticated, upsQuery, isUpsEnabled} = useGetUpsAccountsQuery({pollAuthStatus: true});

  const {mutateAsync: createUpsAccount} = useCreateUpsAccountMutation({
    onSuccess: ({data: {oauth_url}}) => {
      setShouldOpenForm(false);
      if (oauth_url) {
        openPopupWindow({url: oauth_url, ...upsPopupSizeOptions});
      } else {
        setError('Error', 'Unable to create OAuth URL.');
      }
    }
  });

  const handleCancel = () => {
    setShouldOpenForm(false);
  };

  const handleSubmit = async (
    values: UPSRegistrationFormValues,
    {setErrors}: FormikHelpers<UPSRegistrationFormValues>
  ) => {
    const payload = cleanPayload(values as unknown as RegisterUPS) as RegisterUPS;

    await createUpsAccount(payload, {
      onError: ({response}) => {
        if (response) {
          const {error_description, field_errors, non_field_errors} = response.data;
          setError(error_description, non_field_errors?.join('. ') || 'Unknown error.');
          if (field_errors) setErrors(unpackShipwellErrorForFormik(field_errors));
        }
      }
    });
  };

  return (
    <div className="ups-registration-wrapper">
      <PageHeader title="UPS Registration" />
      <div className="ups-registration-container">
        {isUpsAuthenticated && !shouldOpenForm ? (
          <div className="my-2 w-full">
            <UpsConnectedAccountsTable upsQuery={upsQuery} setShouldOpenForm={setShouldOpenForm} />
          </div>
        ) : null}

        {(!isUpsAuthenticated && isUpsEnabled && !upsQuery.isFetching) || shouldOpenForm ? (
          <>
            <p>
              To add UPS parcel support in your Shipwell platform, enter the information below that you used to register
              your UPS account or sign up for an account at{' '}
              <a href="https://www.ups.com" target="_blank" rel="noreferrer">
                ups.com
              </a>
              .
            </p>
            <UPSRegistrationForm onCancel={handleCancel} onSubmit={handleSubmit} />
          </>
        ) : null}
        {!isUpsEnabled ? <p className="text-center">You don&apos;t have access to this integration.</p> : null}
      </div>
    </div>
  );
};

export default WithStatusToasts(UPSRegistration);
