import {FormGroup, InputGroup} from 'react-bootstrap';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ControlLabel = withStyles({
  label: {
    fontSize: '14px',
    fontFamily: `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
    lineHeight: 1.42857,
    color: '#2b282b'
  },
  root: {
    height: '20px'
  }
})(FormControlLabel);

const CheckBoxField = (props) => {
  const {
    classes,
    className,
    input,
    disabled,
    indeterminate,
    label,
    meta: {touched, error}
  } = props;
  const value = Boolean(input.value || props.value);
  const name = input.name || props.name;
  const onChange = input.onChange || props.onChange;

  return (
    <FormGroup className={className} validationState={touched && error ? 'error' : null}>
      <ControlLabel
        label={label}
        control={
          <Checkbox
            indeterminate={Boolean(indeterminate)}
            classes={classes}
            checked={value}
            disabled={disabled}
            onChange={(value) => onChange && onChange(value)}
          />
        }
      />
      {touched && error && (
        <p className="error-text">
          <i className="icon icon-Delayed" />
          {error}
        </p>
      )}
    </FormGroup>
  );
};

CheckBoxField.defaultProps = {
  meta: {},
  input: {}
};

const checkboxStyles = {
  root: {
    color: '#DADADA'
  },
  checked: {
    color: '#0C9AF9 !important'
  }
};

export default withStyles(checkboxStyles)(CheckBoxField);
