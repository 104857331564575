export const status = {
  Header: 'Status',
  id: 'status',
  width: 75,
  accessor: 'status',
  label: 'Status',
  sortable: false
};
export const origins = {
  Header: 'Origins',
  id: 'origins',
  accessor: 'origins',
  label: 'Origins',
  sortable: false,
  className: 'contracts__carrierDetail-multiline',
  Cell: (row) => {
    return row.value && row.value.length
      ? row.value.map((address, i) => {
          return <div key={i}>{`${address.formatted_address}`}</div>;
        })
      : '--';
  }
};
export const destinations = {
  Header: 'Destinations',
  id: 'destinations',
  accessor: 'destinations',
  label: 'Destinations',
  sortable: false,
  className: 'contracts__carrierDetail-multiline',
  Cell: (row) => {
    return row.value && row.value.length
      ? row.value.map((address, i) => {
          return <div key={i}>{`${address.formatted_address}`}</div>;
        })
      : '--';
  }
};

export const modes = {
  Header: 'Modes',
  id: 'modes',
  accessor: 'modes',
  label: 'Modes',
  width: 65,
  sortable: false,
  Cell: (row) => {
    return row.value && row.value.length > 0
      ? row.value.map((mode, i) => mode.code + (i < row.original.modes.length - 1 ? ', ' : ''))
      : '--';
  }
};

export const equipment = {
  Header: 'Equipment',
  id: 'equipment_types',
  accessor: 'equipment_types',
  label: 'Equipment Types',
  sortable: false,
  Cell: (row) => {
    return row.value && row.value.length > 0
      ? row.value.map((equipment, i) => equipment.name + (i < row.original.equipment_types.length - 1 ? ', ' : ''))
      : '--';
  }
};
