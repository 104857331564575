export const TableLoadingBar = () => {
  return (
    <div className="h-[6px] bg-sw-active" role="progressbar">
      <div
        className="mx-auto h-full w-3/4 animate-horizontal-bounce bg-gradient-to-r from-transparent via-sw-active-light-hex to-transparent"
        aria-hidden
      />
    </div>
  );
};
