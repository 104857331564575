import React, {createContext, useState} from 'react';
import {noop} from 'lodash';
import {LoadType, Facility, FacilityDock, FacilityDockAppointmentRule} from '@shipwell/tempus-sdk';

import {AppointmentBookingContextProvider} from '../AppointmentForm/AppointmentBookingContext';

import {useFacilityDocksQuery, useGetFacilityDockRules, useMutableFacilityHoursOfOperation} from 'App/data-hooks';
import SupplierAppointmentTiles from 'App/containers/appointments/components/forms/SupplierAppointment/SupplierAppointmentTiles';
import SupplierAppointmentForm from 'App/containers/appointments/components/forms/SupplierAppointment/SupplierAppointmentForm';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {
  supplierAppointmentFormDataDefaultValues,
  supplierAppointmentLoadTypeDefaultValues,
  supplierAppointmentFacilityDefaultValues
} from 'App/containers/appointments/components/forms/SupplierAppointment/constants';
import SupplierAppointmentAvailability from 'App/containers/appointments/components/forms/SupplierAppointment/SupplierAppointmentAvailability';
import BasicSidebar from 'App/containers/appointments/components/forms/SupplierAppointment/BasicSidebar';
import FacilityInformationSidebar from 'App/containers/appointments/components/forms/SupplierAppointment/FacilityInformationSidebar';
import {AppointmentAvailabilityWindow} from 'App/data-hooks/appointments/types';
import ShipwellLoader from 'App/common/shipwellLoader';

export type SupplierAppointmentContextType = {
  onCancel: () => void;
  supplierAppointmentFormData: SupplierAppointmentCreationFormType;
  setSupplierAppointmentFormData: React.Dispatch<React.SetStateAction<SupplierAppointmentCreationFormType>>;
  loadTypes: LoadType[];
  facility: Facility | null;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  currentStep: number;
  clickedTime?: Date | null;
  availabilityWindow: AppointmentAvailabilityWindow | null;
  setAvailabilityWindow: React.Dispatch<React.SetStateAction<AppointmentAvailabilityWindow | null>>;
  availabilityDock: FacilityDock | null;
  setAvailabilityDock: React.Dispatch<React.SetStateAction<FacilityDock | null>>;
};

export const SupplierAppointmentContext = createContext<SupplierAppointmentContextType>({
  onCancel: noop,
  supplierAppointmentFormData: supplierAppointmentFormDataDefaultValues,
  setSupplierAppointmentFormData: noop,
  loadTypes: [
    {
      ...supplierAppointmentLoadTypeDefaultValues
    }
  ],
  facility: {...supplierAppointmentFacilityDefaultValues},
  handleNextStep: noop,
  handlePrevStep: noop,
  currentStep: 1,
  clickedTime: null,
  availabilityWindow: null,
  setAvailabilityWindow: noop,
  availabilityDock: null,
  setAvailabilityDock: noop
});

const EmptyDocks: FacilityDock[] = [];
const EmptyDockRules: FacilityDockAppointmentRule[] = [];

const SupplierAppointment = ({
  onCancel,
  loadTypes,
  facility,
  clickedTime
}: {
  onCancel: () => void;
  loadTypes: LoadType[];
  facility: Facility | null;
  clickedTime?: Date | null;
}) => {
  /**
   * Step 2 being the first one is intentional; this a product desision
   * until we implement the misc. appointments flow. We're not removing that step
   * it'll be implemented in the coming weeks.
   */
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [supplierAppointmentFormData, setSupplierAppointmentFormData] = useState(
    supplierAppointmentFormDataDefaultValues
  );
  const [availabilityWindow, setAvailabilityWindow] = useState<AppointmentAvailabilityWindow | null>(null);
  const [availabilityDock, setAvailabilityDock] = useState<FacilityDock | null>(null);

  const handleNextStep = () => setCurrentStep(currentStep + 1);
  const handlePrevStep = () => setCurrentStep(currentStep - 1);

  const docksQuery = useFacilityDocksQuery(facility?.id ?? '');
  const docks = docksQuery.data ?? EmptyDocks;
  const dockRulesQuery = useGetFacilityDockRules(docks, facility?.id ?? '');
  const dockRules = dockRulesQuery.data ?? EmptyDockRules;
  const hoursQuery = useMutableFacilityHoursOfOperation(facility?.id ?? '', {onError: noop});

  return !facility ? (
    <ShipwellLoader />
  ) : (
    <>
      <SupplierAppointmentContext.Provider
        value={{
          onCancel,
          supplierAppointmentFormData,
          setSupplierAppointmentFormData,
          loadTypes,
          facility,
          handleNextStep,
          handlePrevStep,
          currentStep,
          clickedTime,
          availabilityWindow,
          setAvailabilityWindow,
          availabilityDock,
          setAvailabilityDock
        }}
      >
        <AppointmentBookingContextProvider
          value={{
            facility: facility ?? undefined,
            loadTypes,
            docks,
            dockRules,
            hours: hoursQuery.hours,
            isInitialLoading:
              !facility || docksQuery.isLoading || dockRulesQuery.isRulesQueriesLoading || hoursQuery.isLoading
          }}
        >
          <div className="flex shrink flex-col bg-sw-background px-8 py-6">
            {currentStep === 1 || currentStep === 2 ? <BasicSidebar /> : <FacilityInformationSidebar />}
          </div>
          <div className="flex grow flex-col px-8 py-6">
            {currentStep === 1 && <SupplierAppointmentTiles />}
            {currentStep === 2 && <SupplierAppointmentForm />}
            {currentStep === 3 && <SupplierAppointmentAvailability />}
          </div>
        </AppointmentBookingContextProvider>
      </SupplierAppointmentContext.Provider>
    </>
  );
};

export default SupplierAppointment;
