import partition from 'lodash/partition';
import {DropResult} from 'react-beautiful-dnd';
import {useQuery, useMutation} from '@tanstack/react-query';
import {RfpLaneCarrierOptResponse, RfpLaneOptResponse} from '@shipwell/backend-core-sdk';
import {columns} from 'App/containers/userCompany/rfps/LaneBids';
import {EmptyTableSprinkles} from 'App/components/Table/components/EmptyListView/EmptyTableSprinkles';
import Table from 'App/components/Table';
import {reorder} from 'App/utils/dragAndDrop';
import {getRfpLaneBids, updateRfpLaneBid, UpdateRfpLaneBidParams} from 'App/api/rfpOptimized/typed';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {RFP_BIDS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import Loader from 'App/common/shipwellLoader';

const LaneBids = ({row, showEmptyBids = true}: {row: RfpLaneOptResponse; showEmptyBids?: boolean}) => {
  const rfpLaneOptId = row.id || '';
  const rfpBidOptId = row.rfp_bid_opt || '';
  const laneId = row.lane_id;
  const hasPolicy = !!row.policy;

  const rfpBidsQuery = useQuery(
    [RFP_BIDS_QUERY_KEY, rfpLaneOptId, rfpBidOptId],
    async () => {
      const response = await getRfpLaneBids(rfpLaneOptId || '', rfpBidOptId || '');
      return response.data;
    },
    {
      enabled: !!(laneId && rfpBidOptId)
    }
  );
  const [rfpLaneBids, rfpLaneCarriersWithoutBids] = partition(
    rfpBidsQuery.data || [],
    (rfpLaneBid) => !rfpLaneBid.lane_carriers_without_bids
  );

  const laneCarriersWithoutBids =
    rfpLaneCarriersWithoutBids?.[0]?.lane_carriers_without_bids?.map((carrier: string) => ({carrier_name: carrier})) ||
    [];

  const combinedRfpLaneBids = rfpLaneBids.concat((showEmptyBids ? laneCarriersWithoutBids : []) || []);

  const createOptimisticUpdateHandlers = useOptimisticUpdate<
    RfpLaneCarrierOptResponse[],
    unknown,
    UpdateRfpLaneBidParams
  >();

  const createRfpLaneBidOptimisticUpdateHandlers = createOptimisticUpdateHandlers(
    [RFP_BIDS_QUERY_KEY, rfpLaneOptId, rfpBidOptId],
    {
      mergeData: (prevOrder, variables) => {
        return variables?.orderedBids || prevOrder;
      }
    }
  );

  const updateRfpLaneBidMutation = useMutation<
    Awaited<ReturnType<typeof updateRfpLaneBid>>,
    unknown,
    UpdateRfpLaneBidParams
  >(
    ({rfpLaneOptId, rfpBidOptId, orderedBids}) => updateRfpLaneBid({rfpLaneOptId, rfpBidOptId, orderedBids}),
    createRfpLaneBidOptimisticUpdateHandlers
  );

  const handleDragEnd = (result: DropResult) => {
    const draggedFromIndex = result?.source?.index;
    const draggedToIndex = result?.destination?.index;
    if (
      draggedFromIndex === undefined ||
      draggedToIndex === undefined ||
      draggedFromIndex === draggedToIndex ||
      //if there are empty bids their index will be beyond the rfpLaneBids list
      !rfpLaneBids[draggedFromIndex]
    ) {
      return;
    }
    const orderedBids = reorder(rfpLaneBids, draggedFromIndex, draggedToIndex).map((bid, index) => ({
      ...bid,
      order_index: index
    }));
    updateRfpLaneBidMutation.mutate({rfpLaneOptId, rfpBidOptId, orderedBids});
  };

  return (
    <div className="LaneBids flex h-full flex-col overflow-hidden">
      <Table
        columns={columns}
        data={combinedRfpLaneBids}
        allowSort
        manualSort={false}
        isDraggable={!hasPolicy && combinedRfpLaneBids.some((rfpLaneBid) => rfpLaneBid.id)}
        onDragEnd={handleDragEnd}
      />
      {rfpBidsQuery.isInitialLoading ? <Loader loading /> : null}
      {rfpBidsQuery.isSuccess && !rfpBidsQuery.data?.length ? (
        <div className="absolute inset-0 flex flex-col items-center p-12 text-lg font-medium">
          <EmptyTableSprinkles />
          <span>No Bids Received</span>
        </div>
      ) : null}
    </div>
  );
};

export default LaneBids;
