import {DeprecatedButton, SvgIcon, SvgIconName} from '@shipwell/shipwell-ui';
import {Table} from '@tanstack/react-table';
import classNames from 'classnames';

export function TableFooter<T>({table, pageSizes}: {table: Table<T>; pageSizes: number[]}) {
  return (
    <div className="sticky inset-x-0 bottom-0 flex w-full items-center justify-end gap-4 border-t border-sw-divider bg-sw-background p-2">
      <label className="m-0 font-normal" htmlFor="pageSize">
        <div className="flex items-center gap-2">
          <span>Rows Per Page:</span>
          <div className="relative flex h-8 items-center">
            <select
              id="pageSize"
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
              className="appearance-none bg-sw-background p-2 pr-6 text-sw-primary"
            >
              {pageSizes.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <SvgIcon
              className="pointer-events-none absolute inset-y-0 right-0 h-full text-sw-primary"
              name="CarrotDown"
            />
          </div>
        </div>
      </label>
      <div className="flex items-center gap-1">
        <span>Page</span>
        <span>{table.getState().pagination.pageIndex + 1}</span>
        <span>of {table.getPageCount()}</span>
      </div>
      <div className="flex items-center gap-1">
        <FooterButton
          ariaLabel="first page"
          iconName="FirstPage"
          onClick={() => table.setPageIndex(0)}
          isDisabled={!table.getCanPreviousPage()}
        />
        <FooterButton
          ariaLabel="previous page"
          iconName="ArrowLargeLeft"
          onClick={table.previousPage}
          isDisabled={!table.getCanPreviousPage()}
        />
        <FooterButton
          ariaLabel="next page"
          iconName="ArrowLargeRight"
          onClick={table.nextPage}
          isDisabled={!table.getCanNextPage()}
        />
        <FooterButton
          ariaLabel="last page"
          iconName="LastPage"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage()}
        />
      </div>
    </div>
  );
}

const FooterButton = ({
  iconName,
  onClick,
  ariaLabel,
  isDisabled
}: {
  iconName: SvgIconName;
  onClick: () => void;
  ariaLabel: string;
  isDisabled: boolean;
}) => {
  return (
    <DeprecatedButton aria-label={ariaLabel} variant="icon" onClick={onClick} disabled={isDisabled}>
      <div
        className={classNames('flex items-center justify-center rounded-full p-1', {
          'text-sw-disabled': isDisabled,
          'hover:bg-sw-active-light hover:text-sw-active': !isDisabled
        })}
      >
        <SvgIcon name={iconName} />
      </div>
    </DeprecatedButton>
  );
};
