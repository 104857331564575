import {useMutation} from '@tanstack/react-query';
import {
  DocumentType,
  DeleteDocumentVariablesType,
  UploadDocumentVariablesType,
  UpdateDocumentVariablesType,
  mergeUploadDocument,
  mergeUpdateDocument,
  mergeDeleteDocument
} from 'App/api/corrogo/utils/mergeDataFunctions';
import {uploadDrayageLegDocument, updateDrayageLegDocument, deleteDrayageLegDocument} from 'App/api/corrogo/typed';
import {DRAYAGE_LEG_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

type VariablesType = DeleteDocumentVariablesType | UploadDocumentVariablesType | UpdateDocumentVariablesType;

export default function useUpdateDrayageLegDocuments(legId: string) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DocumentType[], unknown, VariablesType>();

  const uploadDocumentOptimisticUpdateHandlers = createOptimisticUpdateHandlers(
    [DRAYAGE_LEG_DOCUMENTS_QUERY_KEY, legId],
    {
      errorHandler: () => {
        console.error('Error creating a drayage leg document');
      },
      mergeData: mergeUploadDocument
    }
  );

  const uploadDrayageLegDocumentMutation = useMutation<
    Awaited<ReturnType<typeof uploadDrayageLegDocument>>,
    unknown,
    {legId: string; file: File; description: string; type: string}
  >(
    ({legId, file, description, type}) => uploadDrayageLegDocument(legId, file, description, type),
    uploadDocumentOptimisticUpdateHandlers
  );

  const updateDocumentOptimisticUpdateHandlers = createOptimisticUpdateHandlers(
    [DRAYAGE_LEG_DOCUMENTS_QUERY_KEY, legId],
    {
      errorHandler: () => {
        console.error('Error updating a drayage leg document');
      },
      mergeData: mergeUpdateDocument
    }
  );

  const updateDrayageLegDocumentMutation = useMutation<
    Awaited<ReturnType<typeof updateDrayageLegDocument>>,
    unknown,
    {legId: string; documentId: string; description: string; type: string}
  >(
    ({legId, documentId, description, type}) => updateDrayageLegDocument(legId, documentId, description, type),
    updateDocumentOptimisticUpdateHandlers
  );

  const deleteDocumentOptimisticUpdateHandlers = createOptimisticUpdateHandlers(
    [DRAYAGE_LEG_DOCUMENTS_QUERY_KEY, legId],
    {
      errorHandler: () => {
        console.error('Error deleting a drayage leg document');
      },
      mergeData: mergeDeleteDocument
    }
  );

  const deleteDrayageLegDocumentMutation = useMutation<
    Awaited<ReturnType<typeof deleteDrayageLegDocument>>,
    unknown,
    {legId: string; documentId: string}
  >(({legId, documentId}) => deleteDrayageLegDocument(legId, documentId), deleteDocumentOptimisticUpdateHandlers);

  return {
    uploadDrayageLegDocumentMutation,
    updateDrayageLegDocumentMutation,
    deleteDrayageLegDocumentMutation,
    isLoading:
      uploadDrayageLegDocumentMutation.isLoading ||
      updateDrayageLegDocumentMutation.isLoading ||
      deleteDrayageLegDocumentMutation.isLoading
  };
}
