import {useQuery, useQueryClient} from '@tanstack/react-query';
import {getTemplatesList} from 'App/api/digitalDocuments/typed';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {SHIPPING_LABELS_QUERY_KEY, SHIPPING_LABEL_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {DOC_TYPE_SHIPPING_LABEL} from 'App/containers/templateManagement/constants';
import {TemplateListResponse} from 'App/containers/templateManagement/types';

export const useShippingLabels = (options = {}) => {
  const queryClient = useQueryClient();

  const getShippingLabelsQuery = useQuery(
    [SHIPPING_LABELS_QUERY_KEY, options],
    async () => {
      const response = (await getTemplatesList(DOC_TYPE_SHIPPING_LABEL, options)) as unknown as TemplateListResponse;
      return response?.data?.data;
    },
    {
      keepPreviousData: true,
      staleTime: STATIC_DATA_STALE_TIME,
      onSuccess: (data) =>
        data.forEach((template) => {
          queryClient.setQueryData([SHIPPING_LABEL_QUERY_KEY, template.id], {data: template});
        })
    }
  );

  const shippingLabels = getShippingLabelsQuery.data || [];

  return {getShippingLabelsQuery, shippingLabels};
};
