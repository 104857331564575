const LoadLinesLegend = () => {
  return (
    <div className="absolute left-12 top-[3px] z-[11] flex h-auto w-max flex-col rounded border-1 border-sw-disabled bg-sw-background-component px-2 py-1">
      <div className="flex items-center">
        <div className="mx-[2px] size-2 rounded-full bg-sw-text" />
        <div className="ml-2 text-sm">Stop</div>
      </div>
      <div className="flex items-center text-sw-success">
        <div className="w-3 border-b-2 border-sw-success"></div>
        <div className="ml-2 text-sm">FTL</div>
      </div>
      <div className="flex  items-center text-sw-primary">
        <div className="w-3 border-b-2 border-sw-primary"></div>
        <div className="ml-2 text-sm">LTL</div>
      </div>
    </div>
  );
};

export default LoadLinesLegend;
