import {OrderStatus} from '@shipwell/corrogo-sdk';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useTransportationOrder} from 'App/data-hooks';

export const TenderFooter = ({
  shipmentId,
  transportationOrderId,
  onActionSelect
}: {
  shipmentId: string;
  transportationOrderId: string;
  onActionSelect(): void;
}) => {
  const {queryInfo: transportationOrderQuery} = useTransportationOrder(transportationOrderId, shipmentId);

  const status = transportationOrderQuery.data?.status;

  const statusRequested = status === OrderStatus.Requested;
  const statusAccepted = status === OrderStatus.Accepted;

  if (!(statusRequested || statusAccepted)) {
    return null;
  }

  return (
    <div className="flex flex-row justify-end">
      <DeprecatedButton onClick={onActionSelect}>
        {statusRequested ? 'Revoke Tender' : 'Remove Carrier'}
      </DeprecatedButton>
    </div>
  );
};
