import _ from 'lodash';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Form, FormGroup, FormControl, OverlayTrigger, InputGroup, Tooltip, ControlLabel} from 'react-bootstrap';
import moment from 'moment';
import {SubmissionError} from 'redux-form';
import * as actions from 'App/actions/vendors';
import * as userActions from 'App/actions/users';
import * as documentActions from 'App/actions/documents';
import {putTriumphPayAccountDetails, postTriumphPayAccount} from 'App/actions/_vendors';
import {phoneUtil, unpackErrors, getIdentifyingCodeErrors} from 'App/utils/globals';
import ManageVendorsForm from 'App/containers/Settings/ManageVendors/ManageVendorsForm';
import './_manage-vendors.scss';
import ShipwellLoader from 'App/common/shipwellLoader/index';

export class Vendor extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.addTag = this.addTag.bind(this);
    this.containsObject = this.containsObject.bind(this);
    this.editCompany = this.editCompany.bind(this);
    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.fetchVendorRelationship = this.fetchVendorRelationship.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.getTag = this.getTag.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNewCarrierChange = this.handleNewCarrierChange.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsFocus = this.handleTagsFocus.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.tagsSearch = this.tagsSearch.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.saveVendorRelationship = this.saveVendorRelationship.bind(this);

    this.state = {
      carrierUser: null,
      cell: null,
      currentTags: [],
      currentPOC: [],
      currentInput: '',
      currentTag: '',
      errorDetails: '',
      groupToAddTo: [],
      keyboard: false,
      newCarrier: false,
      newRelationshipId: null,
      saving: false,
      searchError: null,
      searching: false,
      searchValue: '',
      showCarrierSearchModal: false,
      showDeleteCarrierModal: false,
      showEditCarrierModal: false,
      showError: false,
      showVendorForm: false,
      submittedForm: false,
      updated: false,
      usdotSearch: '',
      activeSort: 'company',
      vendorFound: false,
      manualEntry: false,
      isEdit: false,
      pagination: {
        page: 1,
        pageSize: 30,
        ordering: 'company',
        q: ''
      },
      tagsPagination: {
        page: 1,
        pageSize: 10000,
        ordering: 'name'
      },
      companyPagination: {
        page: 1,
        pageSize: 1000,
        ordering: 'freight_authority__company__name'
      }
    };
    this.timer = null;
  }

  componentDidMount() {
    let isEdit = false;
    let manualEntry = false;

    if (!_.isEmpty(this.props.company)) {
      this.getCarrierTags();
      this.props.fetchIdentifyingCodes();
      this.fetchUsers(this.props.company.id);
      if (this.props.params && this.props.params.carrier_id) {
        isEdit = true;
        this.fetchVendorRelationship();
        this.fetchDocuments();
      }
    }

    //set manualEntry state base of location
    if (this.props.location) {
      if (this.props.location.pathname === '/new_carrier') {
        manualEntry = false;
      } else if (this.props.location.pathname === '/manual_carrier') {
        manualEntry = true;
      }
    }

    if (this.props.isManual) {
      manualEntry = true;
    }
    this.setState({isEdit: isEdit, manualEntry: manualEntry});

    // if there's no selectedCarrier when user is on the new-vendor page, go back to list
    if (
      !this.props.selectedCarrier ||
      (this.props.selectedCarrier &&
        this.props.selectedCarrier.shipwell_vendor &&
        !this.props.selectedCarrier.shipwell_vendor.id)
    ) {
      if (this.props.location.pathname === '/new_carrier') {
        setTimeout(() => {
          this.context.router.push('/carriers');
        }, 100);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.getCarrierTags();
      this.props.fetchIdentifyingCodes();
      this.fetchUsers(nextProps.company.id);

      if (this.props.params && this.props.params.carrier_id) {
        this.setState({isEdit: true});
        this.fetchVendorRelationship();
        this.fetchDocuments();
      }
    }
    if (this.props.location && nextProps.location && this.props.location.pathname !== nextProps.location.pathname) {
      if (nextProps.location.pathname === '/new_carrier') {
        this.setState({manualEntry: false});
      } else if (nextProps.location.pathname === '/manual_carrier') {
        this.setState({manualEntry: true});
      }
    }
    if (
      this.props.selectedRelationship &&
      nextProps.selectedRelationship &&
      nextProps.selectedRelationship !== this.props.selectedRelationship &&
      this.props.selectedRelationship.external_payment_processor_id &&
      this.props.getTriumphPayVendorDetails
    ) {
      this.props.getTriumphPayVendorDetails(nextProps.selectedRelationship.external_payment_processor_id);
    }
  }

  addTag(tag) {
    if (!tag.id) {
      return this.props.postCarrierTags({name: tag}).then(
        function (response) {
          if (response.status === 200) {
            if (!this.state.currentTags) {
              this.setState({
                currentTags: [response.details]
              });
            } else if (!this.state.currentTags.includes(response.details)) {
              this.setState((prevState) => ({
                currentTags: [...prevState.currentTags, response.details]
              }));
            }
          }
        }.bind(this)
      );
    }
    if (!this.state.currentTags) {
      this.setState({
        currentTags: [tag]
      });
    } else if (!this.state.currentTags.includes(tag)) {
      this.setState((prevState) => ({
        currentTags: [...prevState.currentTags, tag]
      }));
    }
  }

  containsObject(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }
    return false;
  }
  editCompany(body, triumphPayPut, triumphPayPost) {
    //Always resolve carrierRelationship promise. If there is a triumphPayPut, resolve that promise also
    const promises = [
      this.props.putCarrierRelationshipsCarrierRelationshipId(this.props.selectedRelationship.id, body)
    ];

    if (triumphPayPut.length > 0) {
      triumphPayPut.forEach((acct, index, object) => {
        if (acct.vendor_factor_company === null) {
          // remove null factor_company key
          delete acct.vendor_factor_company;
        }
        // remove null billing_address key
        if (acct.vendor_billing_address === null) {
          delete acct.vendor_billing_address;
        }
        // don't put bank account items if not updating routing/account numbers
        if (!(acct.payment_type === 'ACH_BANK') || (acct.payment_type === 'ACH_BANK' && 'bank_account_data' in acct)) {
          promises.push(this.props.putTriumphPayAccountDetails(this.props.triumphPay.id, acct.id, acct));
        }
      });
    }
    if (triumphPayPost.length > 0) {
      triumphPayPost.forEach((acct) => {
        //send id key from dropdown selection on post
        acct.payment_type = acct.payment_type.id;
        promises.push(this.props.postTriumphPayAccount(this.props.triumphPay.id, acct));
      });
    }
    return Promise.all(promises)
      .then((response) => {
        response.forEach((res) => {
          if (res.status === 200) {
            this.context.router.push('/carriers');
          }
        });
      })
      .catch((error) => {
        return error;
      });
  }

  fetchDocuments() {
    this.props.fetchCarrierDocuments(this.props.params.carrier_id).then((response) => {
      this.setState({documents: response.details});
    });
  }

  fetchUsers(id) {
    this.props.getCompanyUsers(id, {
      page: 1,
      pageSize: 1000,
      ordering: 'freight_authority__company__name'
    });
  }

  async fetchVendorRelationship() {
    try {
      await this.props.getCarrierRelationshipsCarrierRelationshipId(this.props.params.carrier_id);
      if (this.props.selectedRelationship) {
        this.setState({currentTags: this.props.selectedRelationship.tags});
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCarrierRelationships() {
    this.props.getCarrierRelationships(this.state.pagination);
  }

  getCarrierTags() {
    this.props.getCarrierTags({
      page: this.state.tagsPagination.page,
      pageSize: this.state.tagsPagination.pageSize,
      ordering: this.state.tagsPagination.ordering
    });
  }

  getTag(name) {
    const myTags = this.props.carrierTags;
    let tag = name;
    for (let i = 0; i < myTags.length; i++) {
      if (myTags[i].name === name) {
        tag = myTags[i];
      }
    }
    return tag;
  }

  handleBlur(e) {
    const tags = document.getElementById('rw_2__listbox');
    let tag;
    const createTag = document.getElementById('rw_2__createlist_option');
    if (tags && tags.getElementsByClassName('rw-state-focus')[0] && tags.children.length <= 1) {
      tag = tags.getElementsByClassName('rw-state-focus')[0].innerHTML;
    }
    if (tag) {
      this.addTag(this.getTag(tag));
    } else if (createTag && createTag.classList.contains('rw-state-focus')) {
      this.addTag(createTag.children[0].innerHTML.replace(/"/g, ''));
    } else if (e.target.value) {
      this.addTag(this.getTag(e.target.value));
    }
    this.setState({currentTag: '', keyboard: false});
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleTagsFocus() {
    this.setState({keyboard: true});
  }

  handleNewCarrierChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const newCarrier = this.state.newCarrier;
    newCarrier[name] = value;
    this.setState({newCarrier: newCarrier});
  }

  handleTagsChange(e) {
    this.setState({currentTags: e});
  }

  renderErrors(response) {
    const errors = response.field_errors || [];
    let myError = {
      vendor: {shipwell_vendor: {billing_address: {}, identifying_codes: []}},
      point_of_contacts: [],
      triumphPay: {}
    };

    myError = unpackErrors(errors, myError);
    myError._error = response.error_description;
    if (myError.shipwell_vendor) {
      myError.vendor = {shipwell_vendor: myError.shipwell_vendor};
    }
    const submissionError = Object.assign(
      {},
      {
        vendor: {shipwell_vendor: {billing_address: {}, identifying_codes: []}},
        point_of_contacts: [],
        triumphPay: {}
      },
      myError
    );
    //handle edge cases for errors here
    //phone number if phone number was not imported correctly
    if (myError.shipwell_vendor) {
      if (myError.shipwell_vendor.primary_phone_number === 'Enter a valid phone number.') {
        submissionError._error = "Carrier company's phone number is not valid";
      } else {
        submissionError._error = myError.shipwell_vendor.primary_phone_number;
      }
    }

    //poc errors
    const pocError = errors.find((el) => el.field_name === 'point_of_contacts');
    if (Array.isArray(errors) && pocError && Array.isArray(pocError.field_errors)) {
      submissionError.point_of_contacts = pocError.field_errors;
    }
    //billing address errors
    if (myError.vendor.shipwell_vendor.billing_address && !_.isEmpty(myError.vendor.shipwell_vendor.billing_address)) {
      if (myError.vendor.shipwell_vendor.billing_address.city) {
        submissionError.vendor.shipwell_vendor.billing_address = myError.vendor.shipwell_vendor.billing_address.city;
      }
      if (myError.vendor.shipwell_vendor.billing_address.state_province) {
        submissionError.vendor.shipwell_vendor.billing_address =
          myError.vendor.shipwell_vendor.billing_address.state_province;
      }
      if (myError.vendor.shipwell_vendor.billing_address.country) {
        submissionError.vendor.shipwell_vendor.billing_address = myError.vendor.shipwell_vendor.billing_address.country;
      }
    } else if (myError.shipwell_vendor.billing_address && typeof myError.shipwell_vendor.billing_address === 'string') {
      submissionError.vendor.shipwell_vendor.billing_address = myError.shipwell_vendor.billing_address;
    } else {
      // need to delete or react gets pissy
      delete submissionError.vendor.shipwell_vendor.billing_address;
    }
    //identifying codes errors
    const icError = errors.find((el) => el.field_name === 'shipwell_vendor.identifying_codes');

    //catch errors if these fields arent in edit mode
    if (Array.isArray(errors) && icError) {
      submissionError._error = 'Invalid Identifying Code';
    }
    //catch errors if these fields are in edit mode
    const values = this.props.manageVendorsForm.values;
    let identifyingCodes = [];
    if (values && values.vendor && values.vendor.shipwell_vendor && values.vendor.shipwell_vendor.identifying_codes) {
      identifyingCodes = values.vendor.shipwell_vendor.identifying_codes;
    }

    submissionError.vendor.shipwell_vendor.identifying_codes = getIdentifyingCodeErrors(identifyingCodes, myError);

    // carrier owner
    if (myError.carrier_owner && myError.carrier_owner.id) {
      submissionError.carrier_owner = myError.carrier_owner.id;
    }

    if (myError._error && !submissionError._error) {
      submissionError._error = myError._error;
    }

    throw new SubmissionError(submissionError);
  }

  saveVendorRelationship(body) {
    return this.props.postCarrierRelationships(this.state.manualEntry, body).then((response) => {
      if (response.status === 200) {
        this.setState({
          submittedForm: true,
          newRelationshipId: response.details.id
        });
        this.props.clearSelectedCarrier();
        if (this.props.handleOnComplete) {
          this.props.handleOnComplete(response.details);
        } else {
          this.context.router.push('/carriers');
        }
      } else {
        return this.renderErrors(response);
      }
    });
  }

  onFormSubmit(attrs) {
    const body = {
      shipwell_vendor: attrs.vendor && attrs.vendor.shipwell_vendor ? attrs.vendor.shipwell_vendor : {},
      notes: attrs.notes,
      tags: this.state.currentTags,
      point_of_contacts: attrs.point_of_contacts,
      carrier_owner: {id: attrs.carrier_owner}
    };
    let triumphPayPut = [];
    let triumphPayPost = [];
    let triumphPayAccounts = [];
    attrs &&
      attrs.triumphPay &&
      attrs.triumphPay.triumph_payment_accounts.forEach((acct) => {
        triumphPayAccounts = [...triumphPayAccounts, acct];
      });
    triumphPayAccounts.forEach((acct) => {
      //if there's a routing number and an account number, send the fields pertaining to bank account
      if (
        acct.bank_account_data &&
        acct.bank_account_data.bank_routing_number &&
        acct.bank_account_data.bank_account_number &&
        (acct.payment_type.id === 'ACH_BANK' || acct.payment_type === 'ACH_BANK')
      ) {
        acct = {
          bank_account_data: acct.bank_account_data,
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      }
      //if there's a mail to name and address, send the fields pertaining to mail check
      else if (
        acct.vendor_billing_address &&
        (acct.payment_type.id === 'MAIL_CHECK' || acct.payment_type === 'MAIL_CHECK')
      ) {
        acct = {
          vendor_billing_address: acct.vendor_billing_address,
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      }
      //if there's a factoring company name, send the fields pertaining to factoring company
      else if (
        acct.vendor_factor_company &&
        acct.vendor_factor_company.company_name &&
        (acct.payment_type.id === 'FACTOR' || acct.payment_type === 'FACTOR')
      ) {
        acct = {
          vendor_factor_company: {company_name: acct.vendor_factor_company.company_name},
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      } else {
        //not a valid account type, do not send
        acct = {};
      }
    });

    triumphPayAccounts.forEach((acct) => {
      if ('id' in acct) {
        triumphPayPut = [...triumphPayPut, acct];
      } else {
        triumphPayPost = [...triumphPayPost, acct];
      }
    });
    if (!this.state.isEdit) {
      if (body.shipwell_vendor.is_3pl) {
        body.shipwell_vendor.brokerage = {is_3pl: true};
      } else {
        body.shipwell_vendor.brokerage = {};
      }

      if (!body.shipwell_vendor.carrier) {
        body.shipwell_vendor.carrier = {};
      }
    }
    //if empty billing address, delete it
    if (_.isEmpty(body.shipwell_vendor.billing_address)) {
      delete body.shipwell_vendor.billing_address;
    }

    //convert insurance date to right format
    if (body.shipwell_vendor.insurance_expires_at) {
      body.shipwell_vendor.insurance_expires_at = moment(body.shipwell_vendor.insurance_expires_at).format();
    } else if (body.shipwell_vendor) {
      body.shipwell_vendor.insurance_expires_at = null;
    }

    //don't submit identifying codes array with empty objects
    if (body.shipwell_vendor.identifying_codes && body.shipwell_vendor.identifying_codes.length > 0) {
      body.shipwell_vendor.identifying_codes = body.shipwell_vendor.identifying_codes.filter(
        (value) => !_.isEmpty(value)
      );
    }

    // if no carrier owner, delete it
    if (!body.carrier_owner.id) {
      body.carrier_owner = null;
    }
    if (this.state.isEdit) {
      return this.editCompany(body, triumphPayPut, triumphPayPost);
    }
    //dont submit id if its value is undefined or null
    if (!body.shipwell_vendor.id || body.shipwell_vendor.id === null) {
      delete body.shipwell_vendor.id;
    }
    return this.saveVendorRelationship(body, triumphPayPut, triumphPayPost);
  }

  tagsSearch(e) {
    this.setState({currentTag: e});
  }

  render() {
    const {showInModal, carriersSearchResult, selectedCarrier} = this.props;
    return (
      <div className={showInModal ? '' : 'content-wrapper manage-carriers'}>
        <section className="content">
          {this.props.isLoading ? (
            <ShipwellLoader loading={this.props.isLoading} />
          ) : (
            <div className="vendors">
              <ManageVendorsForm
                addTag={this.addTag}
                carrierTags={this.props.carrierTags}
                closeModal={this.props.closeModal}
                companyUsers={this.props.companyUsers}
                currentTag={this.state.currentTag}
                currentTags={this.state.currentTags}
                edit={this.state.isEdit}
                existingVendor={
                  this.state.isEdit
                    ? true
                    : selectedCarrier && selectedCarrier.vendor && !_.isEmpty(selectedCarrier.vendor.shipwell_vendor)
                }
                handleTagsBlur={this.handleBlur}
                handleTagsChange={this.handleTagsChange}
                handleTagsFocus={this.handleTagsFocus}
                identifyingCodes={this.props.identifyingCodes}
                manualEntry={this.state.manualEntry}
                newRelationshipId={this.state.newRelationshipId}
                onSubmit={this.onFormSubmit}
                selectedPOC={this.props.selectedRelationship ? this.props.selectedRelationship.point_of_contacts : []}
                selectedCarrier={this.props.selectedCarrier}
                selectedRelationship={this.props.selectedRelationship}
                showInModal={showInModal}
                submittedForm={this.state.submittedForm}
                tagsSearch={this.tagsSearch}
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carrierTags: state.vendors.carrierTags,
    carrierRelationships: state.vendors.carrierRelationships,
    isLoading: state.vendors.isLoading,
    company: state.auth.company,
    companyId: state.auth.company.id,
    selectedCarrier: state.vendors.selectedCarrier,
    selectedRelationship: state.vendors.selectedRelationship,
    identifyingCodes: state.users.identifyingCodes,
    carriersSearchResult: state.vendors.carriersSearchResult,
    manageVendorsForm: state.form.ManageVendorsForm,
    companyUsers: state.users.companyUsers,
    triumphPay: state.vendors.triumphPay
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {...actions, ...documentActions, ...userActions, putTriumphPayAccountDetails, postTriumphPayAccount},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
