import {
  RFPBidOptContractWorkflowCreationRecordResponse,
  RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum,
  RfpLaneOptResponse
} from '@shipwell/backend-core-sdk';
import {useQueryClient} from '@tanstack/react-query';
import {RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY} from 'App/data-hooks/queryKeys';

export const ContractsCell = ({lane}: {lane: RfpLaneOptResponse}) => {
  const queryClient = useQueryClient();
  const creationRecord: RFPBidOptContractWorkflowCreationRecordResponse[] | undefined = queryClient.getQueryData([
    RFP_CONTRACT_ROUTING_GUIDE_PROGRESS_QUERY,
    lane.rfp_bid_opt
  ]);
  const laneStatus = creationRecord?.find((record) => record.rfp_lane_opt === lane.id);

  if (lane.contracts && Object.keys(lane.contracts).length === 0) {
    switch (laneStatus?.contracts_status) {
      case RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum.InProgress:
        return <span>Creating Contracts...</span>;
      case RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum.NoContractsCreated:
        return <span className="text-sw-error">Error Creating Contracts</span>;
      default:
        return <span>--</span>;
    }
  }

  switch (laneStatus?.contracts_status) {
    case RFPBidOptContractWorkflowCreationRecordResponseContractsStatusEnum.SomeContractsCreated:
      return <span>Some Contracts Created</span>;
    default:
      return <span>Contracts Created</span>;
  }
};
