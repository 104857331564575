import {Address} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation} from '@tanstack/react-query';
import {useState} from 'react';
import {validateLocation} from 'App/api/locations/typed';

export type LocalVal = {
  formatted_address: string;
  value?: {formatted_address: string};
  type?: string;
  validated?: true;
};

const STATE_PROVINCE = 'state_province';
const isLocalVal = (address: Address | LocalVal | string): address is LocalVal => {
  // full addresses are returned with additional info like timezone, but our local values do not
  return typeof address !== 'string' && !('timezone' in address);
};
const getStates = (values: (Address | LocalVal)[]) =>
  values
    .filter((address) => isLocalVal(address) && address.type === STATE_PROVINCE)
    .reduce((acc: string[], address) => {
      return [...acc, ...(address?.formatted_address ? [address.formatted_address.slice(-2)] : [])];
    }, []);

// The necessity of this hook is in large part due to the current architecture of the MultiAddressSearch component
// We have to track state locally here to keep the MultiAddressSearch component from getting into an endless loop
// In the future, a rework of the MultiAddressSearch component would make this hook unneccesary...
//  ...and severally streamline the location filters as a whole
export const useLocationFilter = ({
  contains,
  stateProvinces,
  latLonLabel,
  radius,
  onChange,
  onRadiusChange,
  useLatLong
}: {
  contains: string[];
  stateProvinces: string[];
  latLonLabel?: string;
  radius?: string;
  onChange: ({
    addresses,
    states,
    lat,
    lon,
    label
  }: {
    addresses: string[];
    states: string[];
    lat?: string;
    lon?: string;
    label?: string;
  }) => void;
  onRadiusChange?: (newRadius: string) => void;
  useLatLong?: boolean;
}) => {
  const formattedContains = contains.map((contain) => ({
    formatted_address: contain,
    value: {formatted_address: contain}
  }));
  const formattedStateProvinces = stateProvinces.map((state) => ({
    formatted_address: state,
    value: {formatted_address: state},
    type: STATE_PROVINCE
  }));
  const initialValues: LocalVal[] = [
    ...formattedContains,
    ...formattedStateProvinces,
    ...(latLonLabel ? [{formatted_address: latLonLabel, value: {formatted_address: latLonLabel}}] : [])
  ];
  const [values, setValues] = useState(initialValues);
  const [showRadiusSelect, setShowRadiusSelect] = useState(values?.length === 1 && values[0].type !== STATE_PROVINCE);
  const [fetchingAddress, setFetchingAddress] = useState(false);

  const addressValidationMutation = useMutation(validateLocation);

  const getLatLon = async (addresses: (Address | LocalVal)[]) => {
    let longitude = 0;
    let latitude = 0;
    if (!addresses.length || !useLatLong) {
      return {longitude, latitude};
    }
    const [address] = addresses;
    // if the address does not have a latitude key, then we need to fetch the full address
    if (!('latitude' in address)) {
      setFetchingAddress(true);
      const {
        data: {geocoded_address}
      } = await addressValidationMutation.mutateAsync(address.formatted_address || '', {
        onSettled: () => {
          setFetchingAddress(false);
        }
      });
      longitude = geocoded_address?.longitude || 0;
      latitude = geocoded_address?.latitude || 0;
    } else {
      longitude = address.longitude || 0;
      latitude = address.latitude || 0;
    }
    return {longitude, latitude};
  };

  // this func runs when the MultiAddressSearch is mounted, so is doubling as a "hydrate" and onchange func
  const handleChange = async (addresses: (Address | LocalVal)[]) => {
    // this if block is meant to act as a "catch", the MultiAddressSearch is using a useEffect under the hood to set state
    // without this catch, its useEffect has a tendency to get caught in a loop when attempting to update state externally
    if (addresses.length === values.length) {
      return;
    }
    // convert all incoming addresses to LocalVal
    const localValAddresses: LocalVal[] =
      addresses.map((address) => ({
        formatted_address: address.formatted_address || '',
        value: {formatted_address: address.formatted_address || ''},
        // if address is a state, update the type locally
        ...(isLocalVal(address) && address.type ? {type: address.type} : {}),
        validated: true
      })) || [];
    setValues(localValAddresses);

    // if component returns no addresses
    if (!localValAddresses.length) {
      setShowRadiusSelect(false);
      onRadiusChange?.('');
      onChange({addresses: [], states: [], label: '', lat: '', lon: ''});
      return;
    }
    // if component returns a single address while using latlon as long as its not a state/province
    if (useLatLong && localValAddresses?.length === 1 && localValAddresses[0].type !== STATE_PROVINCE) {
      const {latitude, longitude} = await getLatLon(addresses);
      setShowRadiusSelect(true);
      onRadiusChange?.(radius || '100');
      onChange({
        addresses: [],
        states: [],
        label: localValAddresses[0].formatted_address,
        lat: latitude.toString(),
        lon: longitude.toString()
      });
      return;
    }

    const addressSelections = localValAddresses
      .filter((address) => !address.type)
      .map((address) => address.formatted_address);
    const stateSelections = getStates(localValAddresses);
    setShowRadiusSelect(false);
    onRadiusChange?.('');
    onChange({addresses: addressSelections, states: stateSelections, label: '', lat: '', lon: ''});
  };

  const handleRadiusChange = async (updatedRadius: string) => {
    if (!onRadiusChange) {
      return;
    }
    onRadiusChange(updatedRadius);
    // if none zero, we should update current address to latlon
    if (updatedRadius !== '0' && values.length === 1) {
      const {longitude, latitude} = await getLatLon(values);
      return onChange({
        addresses: [],
        states: [],
        lat: latitude.toString(),
        lon: longitude.toString(),
        label: values[0]?.formatted_address || ''
      });
    }
    // if updated radius is "0", we should remove exact lat lon and instead use the address
    // if a user is able to update the radius input, it means there is only one address or state currently listed in the address input
    const addresses = values.filter((address) => !address.type).map((address) => address.formatted_address);
    const states = getStates(values);
    return onChange({addresses, states, lat: '', lon: ''});
  };

  const handleClear = () => {
    setShowRadiusSelect(false);
    setValues([]);
  };

  return {
    showRadiusSelect,
    fetchingAddress,
    handleChange,
    handleRadiusChange,
    values,
    handleClear
  };
};
