import {PropTypes} from 'prop-types';
import {Tooltip} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import {WorkflowTooltip} from 'App/containers/Dashboard/components/Columns/WorkflowTooltip';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {WORKFLOW_EXECUTION_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getWorkflowExecutionShipmentInstance} from 'App/api/workflows/typed';

const WorkflowExecutionCell = ({value}) => {
  const mostRecentExecution = value?.[0];
  const getFinalStatus = () => {
    if (!value?.length) {
      return '--';
    }
    if (mostRecentExecution.current_status === 'SUCCESS') {
      return 'Complete';
    }
    return startCaseToLower(mostRecentExecution.current_status);
  };
  const showAdditionalCount = () => {
    if (value?.length > 1) {
      return `+${value.length - 1}`;
    }
    return '';
  };

  const id = mostRecentExecution?.workflow_execution_id;
  const isRunning = mostRecentExecution?.current_status === 'RUNNING';
  const executionQuery = useQuery([WORKFLOW_EXECUTION_QUERY_KEY, id], () => getWorkflowExecutionShipmentInstance(id), {
    retry: false,
    enabled: isRunning
  });

  const actions = executionQuery.data?.data?.workflow?.actions || [];
  const events = executionQuery.data?.data?.events || [];
  const currentStep = mostRecentExecution?.current_step;

  const getCurrentStep = () => {
    const currentAction = actions.find((action) => action.step_id === currentStep);
    return currentAction;
  };

  const getCurrentStartTime = () => {
    const actionId = getCurrentStep()?.step_id;
    const currentEvent = events.find((event) => event?.step_id === actionId && event.message === 'Start');
    return currentEvent?.event_created_at;
  };

  // no tooltip if no executions
  if (!mostRecentExecution) {
    return (
      <span className={`${getFinalStatus() === 'Failed' ? 'text-sw-error' : ''}`}>
        {getFinalStatus()} {showAdditionalCount()}
      </span>
    );
  }
  return (
    <Tooltip
      placement="left"
      portal
      tooltipContent={
        executionQuery.isInitialLoading ? (
          'Loading...'
        ) : (
          <WorkflowTooltip
            name={mostRecentExecution?.workflow_name}
            status={mostRecentExecution?.current_status}
            endDate={mostRecentExecution?.end_time}
            currentStep={mostRecentExecution?.current_step}
            startTime={getCurrentStartTime()}
            workflowExecutionId={id}
          />
        )
      }
    >
      <span className={`${getFinalStatus() === 'Failed' ? 'text-sw-error' : ''}`}>
        {getFinalStatus()} {showAdditionalCount()}
      </span>
    </Tooltip>
  );
};

WorkflowExecutionCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      current_status: PropTypes.string,
      policy_type: PropTypes.string,
      workflow_execution_id: PropTypes.string,
      workflow_id: PropTypes.string,
      workflow_version: PropTypes.number,
      current_step: PropTypes.string,
      current_action: PropTypes.string,
      triggering_resource_type: PropTypes.string,
      triggering_resource_id: PropTypes.string,
      workflow_name: PropTypes.string
    })
  )
};

export default WorkflowExecutionCell;
