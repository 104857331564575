import {useQuery} from '@tanstack/react-query';
import {useCompanyPreferences, useUserMe} from 'App/data-hooks';
import {COMPANY_UNIT_PREFERENCES} from 'App/data-hooks/queryKeys';
import {countryDefaults} from 'App/utils/internationalConstants';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';
import {UnitPreferences} from 'App/reducers/types';

export const getUnitPreferences = (country: string) =>
  countryDefaults.find((countryDefault) => countryDefault.country === country)?.unitPreferences;

export const useCompanyUnitPreferences = () => {
  const {data: userData} = useUserMe();
  const {data: companyPreferencesData} = useCompanyPreferences(userData?.company?.id || '');
  const {data: internationalPreferences, isFetched: isInternationalPreferencesFetched} =
    useGetInternationalPreferences();
  // currency now comes from its own setting
  const {currency} = internationalPreferences || {};

  const unitPreferencesQuery = useQuery(
    [COMPANY_UNIT_PREFERENCES, userData?.company?.id],
    () => {
      if (!companyPreferencesData?.country) return;

      return {...getUnitPreferences(companyPreferencesData?.country), ...(!!currency && {currency})} as UnitPreferences;
    },
    {
      enabled: !!companyPreferencesData?.country && isInternationalPreferencesFetched
    }
  );
  return unitPreferencesQuery;
};
