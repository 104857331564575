import {CustomField, CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {OverflowMenu} from '@shipwell/shipwell-ui';
import invariant from 'tiny-invariant';
import {ReactNode, useState} from 'react';
import {EntityTypeGroupType, entityTypeGroups} from '../config';
import {useCompanyIdContext} from '../context';
import {CustomFieldForm} from './CustomFieldForm';
import {useDeleteCompanyCustomField, useUpdateCompanyCustomField} from 'App/data-hooks';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

export const CustomFieldRow = ({
  customField,
  groupType,
  setError
}: {
  customField: CustomField;
  groupType: EntityTypeGroupType;
  setError: WithStatusToastProps['setError'];
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const companyId = useCompanyIdContext();
  const entityTypeGroup = entityTypeGroups[groupType];

  const {deleteCustomField, isDeleting} = useDeleteCompanyCustomField(companyId, {
    onError: () => setError('Error!', ' Failed to delete custom field')
  });

  const {updateCustomField, isUpdating} = useUpdateCompanyCustomField(companyId, {
    onError: () => setError('Error!', ' Failed to update custom field')
  });

  if (isEditing)
    return (
      <CustomFieldForm
        customField={customField}
        groupType={groupType}
        isSubmitting={isUpdating}
        onSubmit={(customField) => {
          const customFieldId = customField.id;
          invariant(customFieldId, 'No custom field ID found');
          updateCustomField({customFieldId, customField}, {onSuccess: () => setIsEditing(false)});
        }}
        onCancel={() => setIsEditing(false)}
      />
    );

  return (
    <div className="flex items-center gap-4">
      <div>{customField.label}</div>

      {entityTypeGroup.formFields.includes('ui_enabled') ? (
        <Tag>{customField.ui_enabled ? 'visible' : 'hidden'}</Tag>
      ) : null}

      {customField.allow_carrier_edit ? <Tag>carrier edit</Tag> : null}
      {customField.allow_carrier_view &&
      customField.entity_types?.includes(CustomFieldEntityTypesEnum.PurchaseOrder) ? (
        <Tag>carrier view</Tag>
      ) : null}

      {customField.required ? <Tag>required</Tag> : null}

      {customField.field_type === 'SELECTION' &&
      !!customField.allowed_values &&
      customField.allowed_values.length > 0 ? (
        <Tag>{`${customField.allowed_values.length} items`}</Tag>
      ) : null}

      <div className="ml-auto">
        <OverflowMenu placement="left-start">
          {({setIsOpen}) => (
            <>
              <OverflowMenu.Item
                onClick={() => {
                  setIsEditing(true);
                  setIsOpen(false);
                }}
              >
                Edit
              </OverflowMenu.Item>
              <OverflowMenu.Item
                isDestructive
                isLoading={isDeleting}
                onClick={() => {
                  invariant(customField.id, 'No custom field ID found');
                  deleteCustomField(customField.id, {onSettled: () => setIsOpen(false)});
                }}
              >
                Delete
              </OverflowMenu.Item>
            </>
          )}
        </OverflowMenu>
      </div>
    </div>
  );
};

const Tag = ({children}: {children: ReactNode}) => {
  return <div className="pt-0.5 text-xxs uppercase text-sw-tag-default">{children}</div>;
};
