import {Component} from 'react';
import {Field, reduxForm, FieldArray, FormSection} from 'redux-form';
import {ControlLabel, InputGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {Multiselect} from 'react-widgets';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReportInaccurateForm from './ReportInaccurateForm';
import renderField from 'App/formComponents/renderField';
import renderCheckBox from 'App/formComponents/renderCheckBox';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import renderAddress from 'App/formComponents/renderAddress';
import renderMultipleIdentifyingCodes from 'App/formComponents/renderMultipleIdentifyingCodes';
import renderTextArea from 'App/formComponents/renderTextArea';
import {scrollToFirstErrorField} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import SelectField from 'App/formComponents/fields/select';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

export class VendorsReportedData extends Component {
  constructor(props) {
    super(props);
    this.setCompanyUsers = this.setCompanyUsers.bind(this);
    this.submitReport = this.submitReport.bind(this);
    this.state = {
      edit: false,
      showReportModal: false,
      showCompanyUsers: []
    };
  }

  componentDidMount() {
    this.setCompanyUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyUsers && this.props.companyUsers && this.props.companyUsers !== prevProps.companyUsers) {
      this.setCompanyUsers();
    }
  }

  setCompanyUsers() {
    const {companyUsers} = this.props;
    const showCompanyUsers = [];
    if (companyUsers && companyUsers.results) {
      for (let i = 0; i < companyUsers.results.length; i++) {
        showCompanyUsers.push({
          name: companyUsers.results[i].first_name + ' ' + companyUsers.results[i].last_name,
          id: companyUsers.results[i].id
        });
      }
    }
    this.setState({showCompanyUsers: showCompanyUsers});
  }

  submitReport(attrs) {
    const body = attrs;
    let usdot;
    if (this.props.shipwellVendor) {
      for (let i = 0; i < this.props.shipwellVendor.identifying_codes.length; i++) {
        if (this.props.shipwellVendor.identifying_codes[i].type === 'USDOT') {
          usdot = this.props.shipwellVendor.identifying_codes[i].value;
        }
      }
    }
    body.usdot = usdot;

    this.props.reportInaccurateCarrierInfo(body).then((response) => {
      if (response.status === 200) {
        this.overlay.setState({show: false});
      }
    });
  }

  renderReadOnly() {
    const {
      isEditable,
      shipwellVendor,
      carrierTags,
      handleTagsChange,
      addTag,
      currentTags,
      handleTagsBlur,
      tagsSearch,
      currentTag,
      handleTagsFocus,
      edit
    } = this.props;

    return (
      <div className="vendors__shipwell-vendor">
        {isEditable && (
          <div className="vendors__edit">
            <a
              role="button"
              onClick={() => {
                this.setState({edit: true});
              }}
            >
              <i className="flaticon-edit action-link" />
            </a>
          </div>
        )}
        <Grid container spacing={8} className="vendors__info">
          <Grid item md={4} sm={12}>
            <p>
              <b>Company Name</b>
            </p>
            <p>
              {shipwellVendor
                ? shipwellVendor.dba_name
                  ? shipwellVendor.dba_name + ' (' + shipwellVendor.name + ')'
                  : shipwellVendor.name
                : '-'}
            </p>
          </Grid>
          <Grid item md={4} sm={12}>
            <Grid container direction="column">
              <Grid item md={12}>
                <p>
                  <b>Mailing Address</b>
                </p>
                <p>
                  {shipwellVendor && shipwellVendor.mailing_address && shipwellVendor.mailing_address.formatted_address
                    ? shipwellVendor.mailing_address.formatted_address
                    : '-'}
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  <b>Billing Address</b>
                </p>
                <p>
                  {shipwellVendor && shipwellVendor.billing_address && shipwellVendor.billing_address.formatted_address
                    ? shipwellVendor.billing_address.formatted_address
                    : '-'}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12}>
            <Grid container direction="column">
              <Grid item md={12}>
                <p>
                  <b>E-mail</b>
                </p>
                <p className="email">
                  {shipwellVendor && shipwellVendor.primary_email ? shipwellVendor.primary_email : '-'}
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  <b>Phone</b>
                </p>
                <p>
                  {shipwellVendor && shipwellVendor.primary_phone_number
                    ? getPhoneHyperlink(shipwellVendor.primary_phone_number)
                    : '-'}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {shipwellVendor && shipwellVendor.identifying_codes && shipwellVendor.identifying_codes.length > 0 && (
          <div className="vendors__identifyingCodes">
            <Grid container spacing={8}>
              {shipwellVendor.identifying_codes.map((el, idx) => {
                return (
                  <Grid item key={el.type + idx} md={4} sm={12}>
                    <div>
                      <b>{el.type.replace(/_/g, ' ')}</b>
                    </div>{' '}
                    <div>{el.value ? el.value : '-'}</div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        <div className="vendors__notes-and-tags">
          <div className="vendors__agent">
            <Field
              simpleValue
              clearable
              name="carrier_owner"
              label="Carrier Owner"
              placeholder="Select a Carrier Owner"
              labelKey="name"
              valueKey="id"
              options={this.state.showCompanyUsers}
              component={SelectField}
            />
          </div>
          <div className="vendors__tags">
            <ControlLabel bsClass="control-label poc__label">Tags</ControlLabel>
            <InputGroup className="input-group-inline">
              <InputGroup.Addon>
                <i className="icon icon-Tag" />
              </InputGroup.Addon>
              <Multiselect
                data={carrierTags}
                onChange={handleTagsChange}
                onCreate={addTag}
                value={currentTags}
                onBlur={handleTagsBlur}
                onSearch={tagsSearch}
                searchTerm={currentTag}
                messages={{
                  createNew: 'Create this tag',
                  emptyList: 'No tags found',
                  emptyFilter: 'No tags found'
                }}
                valueField="id"
                textField="name"
                placeholder='e.g. "Expedited West Coast"'
                onFocus={handleTagsFocus}
              />
            </InputGroup>
          </div>
          <div className="vendors__notes">
            <Field
              label="Notes"
              name="notes"
              component={renderTextArea}
              minRows={1}
              placeholder="Enter notes"
              extraClass="vendor-notes-container"
            />
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const {
      identifyingCodes,
      existingVendor,
      edit,
      carrierTags,
      handleTagsChange,
      addTag,
      currentTags,
      handleTagsBlur,
      tagsSearch,
      currentTag,
      handleTagsFocus
    } = this.props;

    return (
      <div className="vendors__shipwell-vendor">
        <Element name={`position-vendor.shipwell_vendor.name`} />
        <Element name={`position-vendor.shipwell_vendor.billing_address`} />
        <Element name={`position-vendor.shipwell_vendor.mailing_address`} />
        <Element name={`position-vendor.shipwell_vendor.primary_phone_number`} />
        <Element name={`position-vendor.shipwell_vendor.primary_email`} />

        <Grid container spacing={8}>
          <Grid item md={6} sm={6} xs={12}>
            <h4>&nbsp;</h4>
            <FormSection name="vendor.shipwell_vendor">
              <Field
                label="Company Name"
                name="name"
                component={renderField}
                type="text"
                placeholder="Company Name"
                req
              />

              {!existingVendor && !edit && (
                <div className="vendors__form-inline">
                  <Field name="is_3pl" component={renderCheckBox} label=" Is 3PL" normalize={(v) => !!v} />
                </div>
              )}

              <Field label="Mailing Address" name="mailing_address" component={renderAddress} req />
              <Field label="Billing Address" name="billing_address" component={renderAddress} />
              <Field
                label="E-mail"
                name="primary_email"
                component={renderField}
                type="text"
                placeholder="E-Mail"
                touch
                req
              />
              <Field
                label="Phone"
                name="primary_phone_number"
                component={renderPhoneNumberField}
                type="text"
                placeholder="Phone"
                req
              />
            </FormSection>

            <div className="vendors__agent">
              <Field
                simpleValue
                clearable
                name="carrier_owner"
                label="Carrier Owner"
                placeholder="Select a Carrier Owner"
                labelKey="name"
                valueKey="id"
                options={this.state.showCompanyUsers}
                component={SelectField}
              />
            </div>

            <div className="vendors__tags-form">
              <ControlLabel bsClass="control-label poc__label">Tags</ControlLabel>
              <InputGroup className="input-group-inline">
                <InputGroup.Addon>
                  <i className="icon icon-Tag" />
                </InputGroup.Addon>
                <Multiselect
                  data={carrierTags}
                  onChange={handleTagsChange}
                  onCreate={addTag}
                  value={currentTags}
                  onBlur={handleTagsBlur}
                  onSearch={tagsSearch}
                  searchTerm={currentTag}
                  messages={{
                    createNew: 'Create this tag',
                    emptyList: 'No tags found',
                    emptyFilter: 'No tags found'
                  }}
                  valueField="id"
                  textField="name"
                  placeholder='e.g. "Expedited West Coast"'
                  onFocus={handleTagsFocus}
                />
              </InputGroup>
            </div>

            <Field
              label="Notes"
              name="notes"
              component={renderTextArea}
              placeholder="Enter notes"
              extraClass="vendor-notes-container"
              minRows={1}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <FormSection name="vendor.shipwell_vendor">
              <div className="vendors__identifyingCodes-form">
                <h4>Identifying Codes</h4>
                <FieldArray
                  name="identifying_codes"
                  component={renderMultipleIdentifyingCodes}
                  props={{identifyingCodes: identifyingCodes}}
                />
                <Element name={`position-vendor.shipwell_vendor.identifying_codes`} />
                <Element name={`position-vendor.shipwell_vendor.identifying_codes[0].type`} />
                <Element name={`position-vendor.shipwell_vendor.identifying_codes[0].value`} />
                <Element name={`position-vendor.shipwell_vendor.identifying_codes[1].type`} />
                <Element name={`position-vendor.shipwell_vendor.identifying_codes[1].value`} />
              </div>
            </FormSection>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const {manualEntry, isEditable} = this.props;
    const reportInaccurateInfo = (
      <Popover id="popover-cancel" className="popover-cancel-shipment">
        <ReportInaccurateForm onSubmit={this.submitReport} />
      </Popover>
    );

    return (
      <div>
        <Paper>
          <div className="vendors__header">
            <h3>
              <b>Carrier-Reported Data</b>
            </h3>
            {!manualEntry && !isEditable && (
              <OverlayTrigger
                ref={(overlay) => (this.overlay = overlay)}
                rootClose
                trigger="click"
                placement="bottom"
                overlay={reportInaccurateInfo}
              >
                <a role="button" className="text-danger">
                  <b>Report Inaccurate Information</b>
                </a>
              </OverlayTrigger>
            )}
          </div>
          {this.state.edit || this.props.manualEntry ? this.renderForm() : this.renderReadOnly()}
        </Paper>
      </div>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(VendorsReportedData);
