import {useCallback, useMemo, useState} from 'react';
import {UseQueryResult, useQuery, useQueryClient} from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';

import {
  Appointment,
  AppointmentStatusEnum,
  Facility,
  FacilityAppointmentType,
  FacilityDock,
  LoadType,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';

import {compareDocks, makeEntry} from '../../containers/appointments/utils';
import {FacilityAppointmentResourceId, InitAppointmentCallback} from '../../containers/appointments/constants';
import {AppointmentCallback, AppointmentEntry, CalendarAppointmentEvent, SchedulingCallback} from './types';

import {FACILITY_APPOINTMENTS_AND_ALL} from 'App/data-hooks/queryKeys';
import {
  cancelAppointment,
  checkInAppointment,
  checkOutAppointment,
  getFacilityAppointments,
  rejectAppointment,
  rescheduleAppointment,
  scheduleAppointment
} from 'App/api/appointments';
import {useFacilityDocksQuery, useGetLoadTypes} from 'App/data-hooks/facilities';
import {compareIds} from 'App/utils/cmp';
import {parseV3ApiError} from 'App/api/typedUtils';
import useSemaphore from 'App/utils/hooks/useSemaphor';
import {createShipmentMessage, getShipment} from 'App/api/shipment/typed';
import useInterval from 'App/utils/hooks/useInterval';
import {MINUTE} from 'App/utils/queryConstants';

async function fetchEntries({
  facilityId,
  start,
  end,
  searchText,
  timezone,
  status,
  plannedDate,
  plannedDateGte,
  plannedDateLte
}: {
  facilityId: string;
  start: Date | null;
  end: Date | null;
  searchText?: string | null;
  timezone?: string;
  status?: AppointmentStatusEnum;
  plannedDate?: string | null;
  plannedDateGte?: string | null;
  plannedDateLte?: string | null;
}): Promise<AppointmentEntry[]> {
  if (!facilityId) {
    return [];
  }
  if (status === AppointmentStatusEnum.Unscheduled) {
    const appointments = await getFacilityAppointments(
      facilityId,
      null,
      start,
      end,
      searchText,
      status,
      plannedDate,
      plannedDateGte,
      plannedDateLte
    );
    return appointments.map((appointment) => makeEntry(appointment, timezone));
  }
  const appointments = await getFacilityAppointments(facilityId, null, start, end, searchText);
  return appointments.map((appointment) => makeEntry(appointment, timezone));
}

const EMPTY_APPOINTMENT_ARRAY: AppointmentEntry[] = [];
const EMPTY_LOAD_TYPE_ARRAY: LoadType[] = [];

type AppointmentVerb = 'checkIn' | 'checkOut' | 'reject';
const AppointmentVerbCallbacks: Record<
  AppointmentVerb,
  (appointment: AppointmentEntry, time?: Date, reason?: string) => Promise<AppointmentEntry>
> = {
  checkIn: checkInAppointment,
  checkOut: checkOutAppointment,
  reject: (appointment, time, reason) => rejectAppointment(appointment, reason)
};

type SchedulingVerb = 'reschedule' | 'schedule';
const VerbList = ['checkIn', 'checkOut', 'reject', 'reschedule'] as AppointmentVerb[];

const AppointmentVerbSuccessMsgs = {
  checkIn: 'Successfully checked into appointment.',
  checkOut: 'Successfully checked out of appointment.',
  reject: 'Successfully rejected appointment.'
} as Record<AppointmentVerb, string>;
const AppointmentVerbFailureMsgs = {
  checkIn: 'Failed to check into appointment.',
  checkOut: 'Failed to check out of appointment.',
  reject: 'Failed to reject appointment.'
} as Record<AppointmentVerb, string>;

export type UseFacilityAppointmentsResult = {
  isLoading: boolean;
  appointments: AppointmentEntry[];
  firstComeFirstServeAppointments: AppointmentEntry[];
  allDayAppointments: AppointmentEntry[];
  unscheduledAppointments: AppointmentEntry[];
  docks: FacilityDock[];
  loadTypes: LoadType[];
  checkIn: AppointmentCallback;
  checkOut: AppointmentCallback;
  reject: AppointmentCallback;
  reschedule: SchedulingCallback;
  cancel: (id: string) => unknown;
  successQueue: string[];
  errorQueue: {title: string; detail: string}[];
  appointmentsQuery: UseQueryResult<AppointmentEntry[], unknown>;
};

export function useFacilityAppointments(
  facility: Facility | null | undefined,
  start: Date,
  end: Date,
  searchText?: string | null
): UseFacilityAppointmentsResult {
  const facilityId = facility?.id ?? '';
  const timezone = facility?.address.timezone ?? 'UTC';

  const succeed = useCallback((msg: string) => {
    setResult((result) => ({...result, successQueue: [...result.successQueue, msg]}));
  }, []);
  const fail = useCallback((title: string, detail: string) => {
    setResult((result) => ({...result, errorQueue: [...result.errorQueue, {title, detail}]}));
  }, []);

  const startStr = start.toISOString();
  const endStr = end.toISOString();
  const endDateForUnscheduled = new Date(end.getTime());
  endDateForUnscheduled.setDate(endDateForUnscheduled.getDate() - 1);
  const endStrForUnscheduled = endDateForUnscheduled.toISOString();
  const appointmentsQuery = useQuery<AppointmentEntry[]>(
    [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
    () => fetchEntries({facilityId, start, end, searchText, timezone})
  );
  const unscheduledAppointmentsQuery = useQuery<AppointmentEntry[]>(
    [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText, AppointmentStatusEnum.Unscheduled],
    () =>
      fetchEntries({
        facilityId,
        start: null,
        end: null,
        searchText,
        timezone,
        status: AppointmentStatusEnum.Unscheduled,
        plannedDate: null,
        plannedDateGte: startStr.split('T')[0],
        plannedDateLte: endStrForUnscheduled.split('T')[0]
      })
  );

  const [result, setResult] = useState<UseFacilityAppointmentsResult>({
    isLoading: false,
    appointments: [],
    firstComeFirstServeAppointments: [],
    allDayAppointments: [],
    docks: [],
    loadTypes: [],
    unscheduledAppointments: [],
    checkIn: InitAppointmentCallback,
    checkOut: InitAppointmentCallback,
    reject: InitAppointmentCallback,
    reschedule: InitAppointmentCallback,
    cancel: InitAppointmentCallback,
    successQueue: [],
    errorQueue: [],
    appointmentsQuery
  });

  let resultDraft = result;

  const docksQuery = useFacilityDocksQuery(facilityId);
  if (docksQuery.isFetched && docksQuery.data) {
    const docks = docksQuery.data.slice().sort(compareDocks);
    if (!isEqual(docks, resultDraft.docks)) {
      resultDraft = {...resultDraft, docks};
    }
  }

  const loadTypesQuery = useGetLoadTypes(facilityId);

  const loadTypes = (loadTypesQuery.data ?? EMPTY_LOAD_TYPE_ARRAY)
    .sort(compareIds)
    .filter(({id}, i, array) => i <= 0 || id != array[i - 1].id);

  // Explicitly call refetch on an interval so it's very easy to find where the
  // auto-refresh behavior is implemented and controlled.
  useInterval(() => {
    void appointmentsQuery.refetch();
    void unscheduledAppointmentsQuery.refetch();
    void docksQuery.refetch();
    void loadTypesQuery.refetch();
  }, MINUTE);

  const {appointments, firstComeFirstServeAppointments, allDayAppointments, unscheduledAppointments} = useMemo(() => {
    const all = (appointmentsQuery.data ?? EMPTY_APPOINTMENT_ARRAY)
      .sort(compareIds)
      .filter(({id}, i, array) => i <= 0 || id != array[i - 1].id);

    // Uncomment the below code to hack in some appointments in these new states to develop against. - Joe
    // for (const appointment of all) {
    //   if (appointment.references?.some(r => r.value === 'POID')) {
    //     appointment.status = AppointmentStatusEnum.DockInUse;
    //   }
    //   if (appointment.references?.some(r => r.value === 'POIL')) {
    //     appointment.status = AppointmentStatusEnum.DockIsReady;
    //   }
    // }

    const appointments = all.filter(
      (a) =>
        a.appointmentType === FacilityAppointmentType.ByAppointmentOnly &&
        !a.allDay &&
        a.status !== AppointmentStatusEnum.Unscheduled
    );
    const firstComeFirstServeAppointments = all.filter(
      (a) => a.appointmentType === FacilityAppointmentType.FirstComeFirstServe
    );
    const allDayAppointments = all.filter((a) => a.allDay);
    const unscheduledAppointments = unscheduledAppointmentsQuery?.data ?? EMPTY_APPOINTMENT_ARRAY;
    return {appointments, firstComeFirstServeAppointments, allDayAppointments, unscheduledAppointments};
  }, [appointmentsQuery.data, unscheduledAppointmentsQuery.data]);

  if (!isEqual(appointments, resultDraft.appointments)) {
    resultDraft = {...resultDraft, appointments};
  }
  if (!isEqual(firstComeFirstServeAppointments, resultDraft.firstComeFirstServeAppointments)) {
    resultDraft = {...resultDraft, firstComeFirstServeAppointments};
  }
  if (!isEqual(allDayAppointments, resultDraft.allDayAppointments)) {
    resultDraft = {...resultDraft, allDayAppointments};
  }
  if (!isEqual(unscheduledAppointments, resultDraft.unscheduledAppointments)) {
    resultDraft = {...resultDraft, unscheduledAppointments};
  }
  if (!isEqual(loadTypes, resultDraft.loadTypes)) {
    resultDraft = {...resultDraft, loadTypes};
  }

  const queryClient = useQueryClient();

  const [verbsWorking, setVerbsWorking] = useState<Record<AppointmentVerb | SchedulingVerb, boolean>>(
    VerbList.reduce(
      (working, verb) => ({...working, [verb]: false}),
      {} as Record<AppointmentVerb | SchedulingVerb, boolean>
    )
  );

  const updateCachedAppointment = useCallback(
    (id: string, update: Partial<AppointmentEntry>) => {
      let scheduled = appointmentsQuery.data ?? EMPTY_APPOINTMENT_ARRAY;
      let unscheduled = unscheduledAppointmentsQuery.data ?? EMPTY_APPOINTMENT_ARRAY;
      const appointmentBefore = scheduled.find((a) => a.id === id) ?? unscheduled.find((a) => a.id === id);
      if (appointmentBefore == null) {
        return;
      }
      scheduled = scheduled.filter((a) => a !== appointmentBefore);
      unscheduled = unscheduled.filter((a) => a !== appointmentBefore);
      const appointmentAfter = {...appointmentBefore, ...update};
      if (appointmentAfter.status === AppointmentStatusEnum.Unscheduled) {
        unscheduled.push(appointmentAfter);
      } else {
        scheduled.push(appointmentAfter);
      }
      queryClient.setQueryData([FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText], scheduled);
      queryClient.setQueryData(
        [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText, AppointmentStatusEnum.Unscheduled],
        unscheduled
      );
    },
    [appointmentsQuery.data, endStr, facilityId, queryClient, startStr, unscheduledAppointmentsQuery.data, searchText]
  );

  const doAppointmentVerb = useCallback(
    async (verb: AppointmentVerb, appointmentId: string, time?: Date, reason?: string) => {
      const appointmentBefore = appointmentsQuery.data?.find((a) => a.id === appointmentId);
      if (!appointmentBefore) {
        throw new Error('Bad Appointment ID?');
      }
      setVerbsWorking({...verbsWorking, [verb]: true});
      let appointmentAfter: AppointmentEntry;
      try {
        appointmentAfter = await AppointmentVerbCallbacks[verb](appointmentBefore, time, reason);
        updateCachedAppointment(appointmentBefore.id, appointmentAfter);
        // this is unfortunate, but such is the price for using hooks :/
        // ^ RE the extra fetch here for rejection
        if (
          verb === 'reject' &&
          appointmentAfter.scheduledResourceType === ScheduledResourceTypeEnum.Shipment &&
          appointmentAfter.scheduledResourceId
        ) {
          const shipment = await getShipment(appointmentAfter.scheduledResourceId);
          shipment.stops?.sort((a, b) => a.ordinal_index - b.ordinal_index);
          const index = 1 + (shipment.stops?.findIndex((stop) => stop.id === appointmentAfter.stopId) ?? -1);
          if (index > 0) {
            await createShipmentMessage(shipment.id, {
              message: `The shipment appointment at stop #${index} was rejected`,
              shipment: appointmentAfter.scheduledResourceId
            });
          }
        }
        setVerbsWorking({...verbsWorking, [verb]: false});
        succeed(AppointmentVerbSuccessMsgs[verb]);
      } catch (error) {
        const {status, title, detail} = parseV3ApiError(error);
        const titleMsg = status >= 400 && status < 500 ? AppointmentVerbFailureMsgs[verb] : title;
        fail(titleMsg, detail);
        setVerbsWorking({...verbsWorking, [verb]: false});
      }
    },
    [appointmentsQuery.data, verbsWorking, updateCachedAppointment, succeed, fail]
  );

  const checkIn = useCallback(
    async (appointmentId: string, time?: Date) => {
      return doAppointmentVerb('checkIn', appointmentId, time);
    },
    [doAppointmentVerb]
  );

  if (checkIn !== resultDraft.checkIn) {
    resultDraft = {...resultDraft, checkIn};
  }

  const checkOut = useCallback(
    async (appointmentId: string, time?: Date) => {
      return doAppointmentVerb('checkOut', appointmentId, time);
    },
    [doAppointmentVerb]
  );

  if (checkOut !== resultDraft.checkOut) {
    resultDraft = {...resultDraft, checkOut};
  }

  const reject = useCallback(
    async (appointmentId: string, time?: Date, reason?: string) => {
      return doAppointmentVerb('reject', appointmentId, time, reason);
    },
    [doAppointmentVerb]
  );

  if (reject !== resultDraft.reject) {
    resultDraft = {...resultDraft, reject};
  }

  const {count: nCancelling, increment: incrementCancelling, decrement: decrementCancelling} = useSemaphore();

  const cancel = useCallback(
    (appointmentId: string) => {
      incrementCancelling();
      cancelAppointment(appointmentId).then(
        () => {
          decrementCancelling();
          updateCachedAppointment(appointmentId, {status: AppointmentStatusEnum.Unscheduled});
          succeed('Appointment cancelled.');
        },
        (error) => {
          decrementCancelling();
          const {status, title, detail} = parseV3ApiError(error);
          const titleMsg = status >= 400 && status < 500 ? 'Unable to cancel appointment.' : title;
          fail(titleMsg, detail);
        }
      );
    },
    [decrementCancelling, fail, incrementCancelling, succeed, updateCachedAppointment]
  );

  if (cancel !== resultDraft.cancel) {
    resultDraft = {...resultDraft, cancel};
  }

  const doSchedulingVerb = useCallback(
    async (
      verb: SchedulingVerb,
      event: CalendarAppointmentEvent | AppointmentEntry,
      revert: () => void,
      newResourceId?: string,
      override?: boolean
    ) => {
      if (newResourceId === FacilityAppointmentResourceId) {
        newResourceId = undefined;
      }
      try {
        let appointment: AppointmentEntry;
        let start: Date, end: Date;
        let matchedLoadTypeId: string | undefined;
        let isAllDay: boolean;
        if ('extendedProps' in event) {
          const {start: start0, end: end0} = event;
          start = start0 instanceof Date ? start0 : new Date(start0);
          end = end0 instanceof Date ? end0 : new Date(end0);
          ({appointment} = event.extendedProps);
          matchedLoadTypeId = appointment.matchedLoadTypeId;
          isAllDay = appointment.allDay;
        } else {
          appointment = event;
          start = new Date(appointment.start.timestamp);
          end = new Date(appointment.end.timestamp);
          matchedLoadTypeId = appointment.matchedLoadTypeId;
          isAllDay = appointment.allDay;
        }

        const isAppointmentUnscheduled = appointment.status === AppointmentStatusEnum.Unscheduled;

        const previous = {
          id: appointment.id,
          start: new Date(appointment.start.timestamp),
          end: new Date(appointment.end.timestamp),
          dockId: appointment.dockId,
          matchedLoadTypeId: appointment.matchedLoadTypeId,
          allDay: appointment.allDay
        };
        if (!isAppointmentUnscheduled) {
          const updatedData =
            appointmentsQuery.data?.map((a) =>
              a.id === appointment.id
                ? {
                    ...a,
                    start: {...a.start, timestamp: start.toISOString()},
                    end: {...a.end, timestamp: end.toISOString()},
                    dockId: appointment.dockId
                  }
                : a
            ) ?? [];
          queryClient.setQueryData(
            [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
            updatedData
          );
        } else {
          const updatedUnscheduledData = unscheduledAppointmentsQuery.data?.filter(
            (unscheduledAppt) => unscheduledAppt.id !== appointment.id
          );
          queryClient.setQueryData(
            [
              FACILITY_APPOINTMENTS_AND_ALL,
              facilityId,
              startStr,
              endStr,
              searchText,
              AppointmentStatusEnum.Unscheduled
            ],
            updatedUnscheduledData
          );
          const updatedData = appointmentsQuery?.data ?? [];
          updatedData.push({
            ...appointment,
            start: {...appointment.start, timestamp: start.toISOString()},
            end: {...appointment.end, timestamp: end.toISOString()},
            status: AppointmentStatusEnum.Scheduled,
            matchedLoadTypeId: matchedLoadTypeId,
            allDay: isAllDay
          });
          queryClient.setQueryData(
            [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
            [...updatedData]
          );
        }

        const {timezone} = appointment.start;
        const dockId = newResourceId || appointment.dockId;
        let promise: Promise<Appointment> | undefined;
        if (isAppointmentUnscheduled) {
          promise = scheduleAppointment(
            appointment.id,
            timezone,
            start,
            end,
            dockId,
            override,
            matchedLoadTypeId,
            isAllDay
          );
        } else {
          promise = rescheduleAppointment(appointment.id, timezone, start, end, dockId, override);
        }
        let updated: AppointmentEntry;
        try {
          updated = makeEntry(await promise, facility?.address.timezone);
          const updatedData = appointmentsQuery.data?.map((a) => (a.id === updated.id ? updated : a)) ?? [];
          queryClient.setQueryData(
            [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
            updatedData
          );
          const actionString = isAppointmentUnscheduled ? 'scheduled' : 'rescheduled';
          succeed(`Successfully ${actionString} appointment`);
        } catch (error) {
          if (isAppointmentUnscheduled) {
            const scheduledAppointments = appointmentsQuery.data?.filter((a) => a.id !== appointment.id) ?? [];
            queryClient.setQueryData(
              [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
              scheduledAppointments
            );
            const unscheduledAppointments = unscheduledAppointmentsQuery?.data ?? [];
            unscheduledAppointments.push(appointment);
            queryClient.setQueryData(
              [
                FACILITY_APPOINTMENTS_AND_ALL,
                facilityId,
                startStr,
                endStr,
                searchText,
                AppointmentStatusEnum.Unscheduled
              ],
              [...unscheduledAppointments]
            );
          } else {
            const updatedData =
              appointmentsQuery.data?.map((a) =>
                a.id === previous.id
                  ? {
                      ...a,
                      start: {...a.start, timestamp: previous.start.toISOString()},
                      end: {...a.end, timestamp: previous.end.toISOString()},
                      dockId: previous.dockId
                    }
                  : a
              ) ?? [];
            queryClient.setQueryData(
              [FACILITY_APPOINTMENTS_AND_ALL, facilityId, startStr, endStr, searchText],
              updatedData
            );
          }

          revert();
          const {status, title, detail} = parseV3ApiError(error);
          const titleMsg = status >= 400 && status < 500 ? `Failed to ${verb} appointment.` : title;
          fail(titleMsg, detail);
        }
      } finally {
        setVerbsWorking({...verbsWorking, [verb]: false});
      }
    },
    [
      succeed,
      fail,
      verbsWorking,
      queryClient,
      appointmentsQuery.data,
      facilityId,
      startStr,
      endStr,
      facility?.address.timezone,
      unscheduledAppointmentsQuery.data,
      searchText
    ]
  );

  const reschedule = useCallback(
    async (
      event: CalendarAppointmentEvent | AppointmentEntry,
      revert: () => void,
      newResourceId?: string,
      override?: boolean
    ) => {
      return doSchedulingVerb('reschedule', event, revert, newResourceId, override);
    },
    [doSchedulingVerb]
  );

  if (reschedule !== resultDraft.reschedule) {
    resultDraft = {...resultDraft, reschedule};
  }

  // Note: if the user hasn't selected a facility yet, there's nothing for us to load.
  const isLoading =
    !!facilityId &&
    (docksQuery.isLoading ||
      appointmentsQuery.isLoading ||
      loadTypesQuery.isLoading ||
      !docksQuery.isFetched ||
      !appointmentsQuery.isFetched ||
      !loadTypesQuery.isFetched ||
      verbsWorking.checkIn ||
      verbsWorking.checkOut ||
      verbsWorking.reject ||
      nCancelling > 0);
  if (isLoading !== resultDraft.isLoading) {
    resultDraft = {...resultDraft, isLoading};
  }

  if (resultDraft !== result) {
    setResult(resultDraft);
    return resultDraft;
  }

  return result;
}
