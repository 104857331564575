import {Field, reduxForm, Form} from 'redux-form';
import {Row, Col, Panel, Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import {placeholder} from 'App/utils/placeholders';
import validate from 'App/containers/Shipment/validateShipmentDocForm';
import renderDropZone from 'App/formComponents/renderDropzone';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderTextArea from 'App/formComponents/renderTextArea';

let ShipmentDocForm = (props) => {
  const {handleSubmit, pristine, error, isLoading, documentTypes} = props;

  const footerButtons = (
    <div className="document-upload__footer">
      <Button className="btn btn-default" disabled={isLoading} onClick={props.goBack}>
        Cancel
      </Button>
      <Button className="btn btn-primary" disabled={isLoading || pristine} type="submit">
        {isLoading ? (
          <span>
            <i className="icon icon-Restart rotate" />
            &nbsp;&nbsp;Uploading
          </span>
        ) : (
          'Upload'
        )}
      </Button>
    </div>
  );
  return (
    <Form onSubmit={handleSubmit} encType="multipart/form-data" className="document-upload">
      <Paper className="shipment__document-card">
        <div className="shipmentDocument__details">
          <Field
            name="type"
            req
            component={renderDropDownSelector}
            data={documentTypes}
            placeholder="Select a type"
            label="Type"
          />

          <Field
            name="description"
            type="text"
            minRows={3}
            component={renderTextArea}
            placeholder={placeholder.docDescription}
            req
            label="Description"
          />
        </div>
        <Panel className="movie-detail">
          <Field name="files" style={{}} component={renderDropZone} id="dropzone-doc" />
        </Panel>
      </Paper>
      <div>{footerButtons}</div>
      {error && <p className="error-text text-center">{error}</p>}
    </Form>
  );
};

ShipmentDocForm = reduxForm({
  form: 'shipmentDocForm',
  touchOnChange: true, // react-dropzone doesn't blur
  validate
})(ShipmentDocForm);

export default ShipmentDocForm;
