import {useLayoutEffect} from 'react';
import {useFormikContext} from 'formik';
import usePrevious from 'App/utils/hooks/usePrevious';

// height of our shipwell header, represents the extra scroll movement we need to do so the field isn't blocked by the header
const SHIPWELL_APP_HEADER_HEIGHT = 64;

// extra padding to put us lightly above the field we intend to scroll to
const SCROLL_PADDING = 24;

// element that spans the entire document
const ROOT_ELEMENT_ID = 'root';

// NOTE: Incompatible with FieldArray.
const useFormErrorScroll = () => {
  const {submitCount, isValid, errors} = useFormikContext();

  const previousSubmitCount = usePrevious(submitCount);

  useLayoutEffect(() => {
    if (!isValid && submitCount !== previousSubmitCount) {
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorElement = window.document.getElementsByName(firstErrorKey);

      const elementToScrollTo = firstErrorElement[0]?.parentElement;
      const rootElement = window.document.getElementById(ROOT_ELEMENT_ID);

      if (elementToScrollTo && rootElement) {
        // the position of the erroring field relative to the viewport
        const elementOffset = elementToScrollTo.getBoundingClientRect().y;

        // the position of the scrollable document relative to the viewport
        const documentScrollPosition = rootElement.getBoundingClientRect().y;

        window.scrollTo({
          top: elementOffset - documentScrollPosition - SHIPWELL_APP_HEADER_HEIGHT - SCROLL_PADDING,
          behavior: 'smooth'
        });
      }
    }
  }, [submitCount, isValid, errors, previousSubmitCount]);
};

export default useFormErrorScroll;
