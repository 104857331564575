import {FormikSelect, FormikTextInput, FormikTextarea, Modal, Toast} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useMemo, useRef} from 'react';
import {Tag} from '@shipwell/corrogo-sdk';
import {ColorsSelectFormik} from './colorsSelect/colorSelectFormik';
import {defaultValues} from './formUtils/defaultValues';
import {TagFormValues} from './formUtils/tagFormValues';
import {validationSchema} from './formUtils/validationSchema';
import {ToastParams} from './toastParams';
import {useTagService} from './useTagService/useTagService';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';
import Loader from 'App/common/shipwellLoader';

export interface TagModalProps {
  show: boolean;
  tagId?: string;
  onClose: () => void;
  onSave: (data: Tag) => void;
}

export function TagModal(props: TagModalProps) {
  const formikRef = useRef<FormikProps<TagFormValues>>(null);

  const toast = useVisibility<ToastParams>();

  const tagService = useTagService({
    tagId: props.tagId,
    onSave: (data) => {
      props.onSave(data);
      toast.showWithData({
        title: 'Success!',
        variant: 'success',
        message: 'Tag was saved.'
      });
    },
    onError: (message) => {
      toast.showWithData({
        title: 'Error!',
        variant: 'error',
        message: message
      });
    }
  });

  const initialValues = useMemo<TagFormValues>(() => {
    return {
      ...defaultValues,
      ...tagService.data
    };
  }, [tagService.data]);

  const modalTitle = props.tagId ? 'Editing Tag' : 'Create New Tag';

  return (
    <>
      <Modal
        show={props.show}
        size={'small'}
        title={modalTitle}
        className={'tagModal'}
        onClose={props.onClose}
        onPrimaryAction={() => formikRef.current?.submitForm()}
      >
        {tagService.loading && <Loader loading />}

        <Formik<TagFormValues>
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data) => tagService.save(data)}
        >
          {(formProps) => (
            <>
              {!tagService.loading && (
                <Form className="flex flex-col gap-2">
                  <Field
                    name="name"
                    label="Tag Name"
                    error={!!formProps.errors.name}
                    message={formProps.errors.name}
                    component={FormikTextInput}
                  />

                  <Field
                    simpleValue
                    clearable={false}
                    label="Status"
                    name="status"
                    options={[
                      {label: 'Active', value: 'ACTIVE'},
                      {label: 'Inactive', value: 'INACTIVE'}
                    ]}
                    component={FormikSelect}
                  />

                  <Field label="Description" name="description" minRows={4} maxRows={4} component={FormikTextarea} />

                  <Field name="color" component={ColorsSelectFormik} />
                </Form>
              )}
            </>
          )}
        </Formik>
      </Modal>

      <Toast
        show={toast.isVisible}
        title={toast.data?.title}
        variant={toast.data?.variant}
        onClose={toast.hideAndClearData}
      >
        {toast.data?.message}
      </Toast>
    </>
  );
}
