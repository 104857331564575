import {MouseEvent, TouchEvent} from 'react';

export const TableDragHandle = ({
  resizeHandler
}: {
  resizeHandler: (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      onMouseDown={resizeHandler}
      onTouchStart={resizeHandler}
      className="absolute right-0 top-0 h-full w-1 cursor-col-resize touch-none select-none hover:bg-sw-primary"
    />
  );
};
