import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';

type CustomDataByEntityType = {
  [K in CustomFieldEntityTypesEnum]?: {
    [customFieldId: string]: string;
  };
};
export type ShipwellCustomData = {
  shipwell_custom_data?: CustomDataByEntityType;
};

/**
 * Get the custom data path for the given entity type.
 * Enforces the correct data structure: nesting custom data under its entity type.
 * Ex: shipwell_custom_data: {
 *   customer: {
 *     [fieldId]: value
 *   }
 * }
 *
 * Since we treat order and shipment line items
 * the same, the custom data path for line items doesn't have an entity type component.
 */
export function getCustomDataPath(entityType: CustomFieldEntityTypesEnum) {
  const entityTypesWithoutEntityTypeInPath: CustomFieldEntityTypesEnum[] = [
    CustomFieldEntityTypesEnum.ShipmentLineItem,
    CustomFieldEntityTypesEnum.PurchaseOrderLineItem
  ];

  if (entityTypesWithoutEntityTypeInPath.includes(entityType)) {
    return 'shipwell_custom_data';
  }

  return `shipwell_custom_data.${entityType}`;
}
