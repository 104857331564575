const ExternalFooter = ({showLoginPolicy = true}: {showLoginPolicy?: boolean}) => {
  return (
    <div className="h-20 p-4 text-center">
      {showLoginPolicy ? (
        <div>
          By logging in, you agree to our <a href="https://shipwell.com/terms-conditions/">Terms & Conditions</a> and{' '}
          <a href="https://shipwell.com/privacy-policy/">Privacy Policy</a>
        </div>
      ) : null}
      <div>
        Need technical assistance? Reach out to our team at
        <a className="pl-1" href="mailto:support@shipwell.com">
          support@shipwell.com
        </a>
      </div>
      <div>&copy; 2016-{new Date().getFullYear()} Shipwell&reg;, All Rights Reserved.</div>
    </div>
  );
};

export default ExternalFooter;
