import {isEqual} from 'lodash';
import {useEffect, useState} from 'react';

import {ShipmentMessage} from '@shipwell/backend-core-singlerequestparam-sdk';

import {parseV3ApiError} from 'App/api/typedUtils';
import useShipmentMessages from 'App/data-hooks/shipments/useShipmentMessages';
import {formatDateTime, getUTCDate} from 'App/utils/dateTimeGlobals';
import {getMessageUserAvatarURL} from 'App/utils/userAvatar';
import ShipwellLoader from 'App/common/shipwellLoader';

export type ShipmentMessagesProps = {
  shipmentId?: string;
  setError: (title: string, details: string) => unknown;
};

export function ShipmentMessages(props: ShipmentMessagesProps) {
  const {shipmentId, setError} = props;
  const {isLoading, messages, error} = useShipmentMessages(shipmentId);

  const [lastError, setLastError] = useState<unknown>(null);
  useEffect(() => {
    if (error && !isEqual(error, lastError)) {
      setLastError(error);
      if (error) {
        const {title, detail} = parseV3ApiError(error);
        setError(title, detail);
      }
    }
  }, [error, setError, lastError]);
  return (
    <div className="shipment-messages">
      {isLoading ? <ShipwellLoader /> : messages.map((message) => <Message key={message.id} message={message} />)}
    </div>
  );
}

type MessageProps = {
  message: ShipmentMessage;
};
export function Message(props: MessageProps) {
  const {message, sender_name, sender_avatar, created_at} = props.message;
  const avatar = getMessageUserAvatarURL(sender_name ?? '', sender_avatar ?? '');
  return (
    <div className="my-1 flex flex-col whitespace-pre-line text-sm">
      <div className="w-full">
        <span className="mr-2 font-bold">{sender_name}</span>
        <span className="text-sw-disabled-text">
          {formatDateTime(getUTCDate(created_at, true, false), true, false)}
        </span>
      </div>
      <div className="flex w-full flex-row items-start">
        <div className="flex-0 mr-2 py-2">
          <img className="size-5 rounded-full" src={avatar} alt="userAvatar" />
        </div>

        <div className="flex-1 break-words rounded bg-sw-background p-3">{message}</div>
      </div>
    </div>
  );
}
