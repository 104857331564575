import {FormGroup, ControlLabel, OverlayTrigger, Tooltip, InputGroup, FormControl} from 'react-bootstrap';

const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  }
};

const renderField = ({
  input,
  tabindex,
  label,
  icon,
  tooltip,
  req,
  placeholder,
  type,
  disabled,
  extraClass,
  nolabel,
  sronly,
  autoFocus,
  meta: {touched, error},
  autoComplete = 'on',
  prepend
}) => (
  <FormGroup
    className={extraClass && extraClass}
    controlId={input.name}
    validationState={touched && error ? 'error' : null}
  >
    {label ? (
      <ControlLabel className={sronly && 'sr-only'}>
        {label}
        {req && <span className="requiredField">*</span>}
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <div style={style.tooltip}>{tooltip}</div>
              </Tooltip>
            }
          >
            <a tabIndex="-1">
              <i className="icon icon-Info" aria-hidden="true" />
            </a>
          </OverlayTrigger>
        )}
      </ControlLabel>
    ) : nolabel ? null : (
      <ControlLabel>&nbsp;</ControlLabel>
    )}

    <InputGroup className="input-group-inline">
      {icon && (
        <InputGroup.Addon>
          <i className={icon} />
        </InputGroup.Addon>
      )}
      {prepend && <InputGroup.Addon>{prepend}</InputGroup.Addon>}
      <FormControl
        //additional classname used when no icon to ensure styling
        className={!(icon || prepend) && 'form-control-no-icon'}
        tabIndex={tabindex}
        disabled={disabled ? true : false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        autoFocus={autoFocus}
        type={type}
        style={style.inputs}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </InputGroup>
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {Array.isArray(error) ? error.join(' ') : error}
      </p>
    )}
  </FormGroup>
);

export default renderField;
