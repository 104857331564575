import {ModalHeaderContainer} from 'App/containers/shipments/components/TenderView/ModalHeaderContainer';
import {TenderStatusPill} from 'App/containers/shipments/components/Status';

export const TenderHeader = ({
  shipmentId,
  transportationOrderId
}: {
  shipmentId: string;
  transportationOrderId: string;
}) => (
  <ModalHeaderContainer>
    <TenderStatusPill shipmentId={shipmentId} transportationOrderId={transportationOrderId} />
  </ModalHeaderContainer>
);
