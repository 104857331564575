import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {useFormikContext, FieldArray} from 'formik';
import {FileDisplay, Title, Rule, DeprecatedButton, SvgIcon, Modal, Loader} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import isEmpty from 'lodash/isEmpty';
import base64EncodeBlob from 'App/utils/base64EncodeBlob';
import {fetchDocumentTypes} from 'App/actions/_documents';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {DocumentViewLayout, DocumentMetadata, DocumentDisplay, DocumentActions} from 'App/components/DocumentView';
import {useV3Shipment} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';

const DocumentRow = ({document, index, documentTypes, handleDeleteDocument, handleUpdateDocument}) => {
  const [showPreview, toggleShowPreview] = useToggle();

  return (
    <>
      <div key={`${index}-${document.type}`} className="my-4 flex">
        <FileDisplay
          containerClass="mr-5"
          maxHeight={40}
          maxWidth={40}
          fileURL={document.file}
          fileName={document.filename}
          isThumbnail
          altText={document.description}
          emptyText=""
        />
        <div className="flex w-full justify-between">
          <div className="flex flex-col">
            <div>{find(documentTypes, {id: get(document, 'type')})?.name}</div>
            <div>{document.description}</div>
          </div>
          <div className="align-end flex items-center">
            <DeprecatedButton className="mr-6" variant="tertiary" onClick={() => toggleShowPreview()}>
              Preview
            </DeprecatedButton>
            {handleDeleteDocument ? (
              <DeleteWarningTooltip
                title={<div className="font-bold">Delete Document?</div>}
                primaryActionText="Delete"
                placement="left"
                message={<div className="w-60">Are you sure you want to delete this document?</div>}
                onDelete={() => handleDeleteDocument(index)}
              >
                <SvgIcon name="TrashOutlined" color="$sw-icon" />
              </DeleteWarningTooltip>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        className="h-screen-85"
        size="large"
        footerComponent={null}
        show={showPreview}
        title="Preview Document"
        onClose={() => toggleShowPreview()}
      >
        <DocumentPreview
          document={document}
          onSubmit={(values) => handleUpdateDocument(index, values)}
          onDelete={handleDeleteDocument ? () => handleDeleteDocument(index) : null}
          onClose={() => toggleShowPreview()}
          documentTypes={documentTypes}
          editable={handleUpdateDocument && handleDeleteDocument}
        />
      </Modal>
    </>
  );
};

DocumentRow.propTypes = {
  document: documentPropType,
  documentTypes: documentTypesPropType,
  handleDeleteDocument: PropTypes.func,
  handleUpdateDocument: PropTypes.func,
  index: PropTypes.number
};

const DocumentsList = ({documents, documentTypes, handleDeleteDocument, handleUpdateDocument, dispatch}) => {
  useEffect(() => {
    if (!documentTypes.length) {
      dispatch(fetchDocumentTypes());
    }
  }, [documentTypes, dispatch]);

  return documents.map((document, index) => (
    <>
      <DocumentRow
        key={document.url}
        document={document}
        index={index}
        handleDeleteDocument={handleDeleteDocument}
        documentTypes={documentTypes}
        handleUpdateDocument={handleUpdateDocument}
        numOfDocuments={documents.length}
      />
      {index < documents.length - 1 ? <Rule /> : null}
    </>
  ));
};

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(documentPropType),
  documentTypes: documentTypesPropType,
  handleDeleteDocument: PropTypes.func,
  handleUpdateDocument: PropTypes.func,
  dispatch: PropTypes.func
};

const NoDocuments = () => (
  <div className="flex h-40 items-center justify-center">
    <Title variant="emptyStateHeader" className="grow-0">
      No Documents Uploaded
    </Title>
  </div>
);

const ConnectedDocumentsList = compose(
  connect((state) => ({
    documentTypes: state.documents.documentTypes
  })),
  withConditionalFallback(({documents}) => isEmpty(documents), NoDocuments)
)(DocumentsList);

const FormikDocumentsList = ({replace, remove, documentTypes}) => {
  const {values} = useFormikContext();
  const {documents = []} = values || {};

  const handleUpdateDocument = (index, values) => {
    replace(index, values);
  };

  const handleDeleteDocument = (index) => {
    remove(index);
  };

  return (
    <ConnectedDocumentsList
      documents={documents}
      documentTypes={documentTypes}
      handleUpdateDocument={handleUpdateDocument}
      handleDeleteDocument={handleDeleteDocument}
    />
  );
};

const documentTypesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
);

FormikDocumentsList.propTypes = {
  replace: PropTypes.func,
  remove: PropTypes.func,
  documentTypes: documentTypesPropType,
  dispatch: PropTypes.func
};

const DocumentPreview = ({document, onSubmit, onDelete, onClose, documentTypes, editable}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <DocumentViewLayout>
        <DocumentMetadata document={document} documentType={find(documentTypes, {id: get(document, 'type')})?.name}>
          {editable ? (
            isEditing ? (
              <ShipmentDocumentForm
                edit
                values={document}
                onCancel={() => setIsEditing(false)}
                onSubmit={(values) => {
                  onSubmit(values);
                  setIsEditing(false);
                }}
              />
            ) : (
              <DeprecatedButton variant="tertiary" onClick={() => setIsEditing(true)}>
                <span className="-ml-2">Edit Details</span>
              </DeprecatedButton>
            )
          ) : null}
        </DocumentMetadata>
        <DocumentDisplay document={document} />
      </DocumentViewLayout>
      <DocumentActions
        document={document}
        onDeleteDocument={
          editable
            ? () => {
                onDelete();
                onClose();
              }
            : null
        }
        onPrintDocument={false}
      />
    </>
  );
};

const documentPropType = PropTypes.shape({
  file: PropTypes.string,
  description: PropTypes.string,
  filename: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string
});

DocumentPreview.propTypes = {
  document: documentPropType,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
  documentTypes: documentTypesPropType,
  editable: PropTypes.bool
};

export const ViewOnlyDocuments = ({shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {
    context: {serviceDocuments}
  } = shipmentQuery;

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return <ConnectedDocumentsList documents={serviceDocuments.map((document) => ({...document, file: document.url}))} />;
};

ViewOnlyDocuments.propTypes = {
  shipmentId: PropTypes.string
};

const Documents = ({showDocumentUpload, setShowDocumentUpload}) => {
  const {setFieldValue, values} = useFormikContext();
  const {documents = []} = values || {};

  const handleUploadDocument = async (document) => {
    const imgData = await base64EncodeBlob(document.file);
    setFieldValue('documents', [
      ...documents,
      {...document, filename: document.file.name, data: document.file, file: imgData, type: document.type}
    ]);
    setShowDocumentUpload(false);
  };

  return (
    <>
      <FieldArray name="documents" component={FormikDocumentsList} />
      <Modal
        show={showDocumentUpload}
        title="Upload Document"
        footerComponent={null}
        onClose={() => setShowDocumentUpload(false)}
      >
        <ShipmentDocumentForm onCancel={() => setShowDocumentUpload(false)} onSubmit={handleUploadDocument} />
      </Modal>
    </>
  );
};

Documents.defaultProps = {
  showDocumentUpload: false
};

Documents.propTypes = {
  showDocumentUpload: PropTypes.bool,
  setShowDocumentUpload: PropTypes.func
};

export default Documents;
