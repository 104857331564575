import _ from 'lodash';
import {OverlayTrigger, Tooltip, Button, Table} from 'react-bootstrap';
import {Document, Page} from 'react-pdf';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {formatBytes} from 'App/utils/globals';
import './_documents-array.scss';

const NewDocumentsArray = (props) => {
  const {
    documents,
    handleDocumentClick,
    showSelections,
    selectedDocuments,
    layout,
    highlightBOL,
    showTooltip,
    tooltipContent,
    handlePreviewClick,
    selectDocEdit
  } = props;

  return (
    <div className={layout === 'vertical' ? 'documentsVertical documentsArray' : 'documentsHorizontal documentsArray'}>
      {documents.length === 0 && <h4 className="text-center"> No Documents</h4>}
      {documents.length > 0 &&
        documents.map((docuOne, idx) => {
          return (
            <div
              key={docuOne.id}
              className={
                'shipmentDocument__container' +
                (layout === 'vertical' ? '' : ' movie-layout') +
                (docuOne.type === 'BOL' && highlightBOL && idx === 0 ? ' active' : '') +
                (showSelections === true && selectedDocuments && selectedDocuments.includes(docuOne.id)
                  ? ' shipmentDocument__container-selected'
                  : '')
              }
            >
              <div>
                {docuOne.type && (
                  <div className="shipmentDocument__documentTitle">
                    <strong>{docuOne.type.replace(/_/g, ' ')}</strong>
                  </div>
                )}
                <div>
                  {docuOne.filename.slice(-4).toLowerCase() !== '.pdf' && (
                    <img src={docuOne.file} alt="shipment-document" className="shipmentDocument__documentImage" />
                  )}
                  {docuOne.filename.slice(-4).toLowerCase() === '.pdf' && (
                    <Document className="shipmentDocument__documentImage" file={docuOne.file}>
                      <Page width={150} pageNumber={1} />
                    </Document>
                  )}
                </div>
                <div className="shipmentDocument__documentDescription">
                  <p>{docuOne.description}</p>
                  <p>{docuOne.filename}</p>
                  {docuOne.fileComplete && docuOne.fileComplete.size && (
                    <p className="size">{formatBytes(docuOne.fileComplete.size, 0)}</p>
                  )}
                </div>
                <div className="shipmentDocument__documentActions">
                  <p>
                    <a
                      role="button"
                      className=""
                      onClick={(e) => {
                        selectDocEdit(docuOne.id, idx, e);
                      }}
                    >
                      <i className="flaticon-edit action-link" />
                    </a>
                  </p>
                  <p>
                    <DeprecatedButton
                      variant="icon"
                      className=""
                      onClick={(e) => {
                        handleDocumentClick(docuOne.id, e);
                      }}
                    >
                      <SvgIcon name="TrashOutlined" />
                    </DeprecatedButton>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default NewDocumentsArray;
