import {
  DocumentResourcesType,
  GenerateDocumentResponse,
  ShipwellApiErrorResponse
} from '@shipwell/template-builder-sdk';
import {useMutation} from '@tanstack/react-query';
import {generateDocumentFromTemplate} from 'App/api/templates/typed';

interface GenerateDocumentProps {
  templateId: string;
  shipmentId: string;
}

export const useGenerateDocumentFromTemplate = () => {
  const generateDocument = async ({
    templateId,
    shipmentId
  }: GenerateDocumentProps): Promise<GenerateDocumentResponse> => {
    const generateDocumentData = {
      templateId,
      generateDocument: {
        resources: [
          {
            type: DocumentResourcesType.Shipment,
            resource_id: shipmentId
          }
        ],
        upload_to_resource: true
      }
    };

    const response = await generateDocumentFromTemplate(generateDocumentData);
    return response.data;
  };

  const {mutate, isLoading} = useMutation<
    Awaited<ReturnType<typeof generateDocument>>,
    ShipwellApiErrorResponse,
    GenerateDocumentProps,
    unknown
  >(({templateId, shipmentId}) => generateDocument({templateId, shipmentId}));

  return {
    generateDocument: mutate,
    isLoadingGeneratingDocument: isLoading
  };
};
