import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import getTimeOptions from 'App/utils/getTimeOptions';

const StepTimerField = ({...props}) => (
  <Field
    label="Step Timer"
    options={getTimeOptions()}
    name="step_timer"
    component={FormikSelect}
    clearable={false}
    {...props}
  />
);

export default StepTimerField;
