import {Address} from '@shipwell/backend-core-singlerequestparam-sdk';
import {BaseAddressSchema} from '@shipwell/shipment-assembly-sdk';

export const mapAddressToBaseAddressSchema = (address: Address): BaseAddressSchema => ({
  address_1: address.address_1 || undefined,
  address_2: address.address_2 || undefined,
  city: address.city || undefined,
  state_province: address.state_province || undefined,
  country: address.country,
  postal_code: address.postal_code || undefined,
  timezone: address.timezone || undefined,
  lat: address.latitude || 0,
  long: address.longitude || 0
});
