import classNames from 'classnames';
import {Formik} from 'formik';
import {object, string} from 'yup';
import {Card, DeprecatedButton, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import RMISFTPFields from 'App/formComponents/formSections/RMISFTPFields';
import RMISAPIFields from 'App/formComponents/formSections/RMISAPIFields';
import {formatDateTime} from 'App/utils/globals';
import './styles.scss';

const RMISConfig = (props) => {
  const {
    values = {},
    FTPCreds,
    APICreds,
    isFTPConnected,
    isAPIConnected,
    handleFTPSubmit,
    handleAPISubmit,
    handleRefresh,
    isBtnLoading
  } = props;

  /* the form may have default values if the user has configured RMIS
      and are now simply visiting the page */
  const defaultFTPFormValues = {
    username: FTPCreds?.username || '',
    password: isFTPConnected ? '******' : '',
    hostname: FTPCreds?.hostname || ''
  };

  const defaultAPIFormValues = {
    username: APICreds?.username || '',
    password: isAPIConnected ? '******' : ''
  };

  const FTPSchema = object().shape({
    username: string().required('Please enter a username'),
    password: string().required('Please enter a password'),
    hostname: string().required('Please enter a valid hostname')
  });

  const APISchema = object().shape({
    username: string().required('Please enter a username'),
    password: string().required('Please enter a password')
  });

  const Connected = () => (
    <div className="RMIS-connected">
      <SvgIcon name="CheckCircleFilled" />
      <span className="RMIS-connected__text">Connected</span>
    </div>
  );

  /**
   * renders title along with the last update date -
   * the latter is conditionally rendered given that there is a date
   */
  const renderCardTitle = (type) => {
    let lastUpdatedTime = null;
    if (type === 'FTP' && isFTPConnected && FTPCreds?.job_status?.last_updated_at) {
      lastUpdatedTime = formatDateTime(FTPCreds.job_status.last_updated_at, true);
    } else if (isAPIConnected && APICreds?.job_status?.last_updated_at) {
      lastUpdatedTime = formatDateTime(APICreds.job_status.last_updated_at, true);
    }

    return (
      <div className="integrations-RMIS-card-container__title">
        <span>{`${type} Connection`}</span>
        {lastUpdatedTime && (
          <div className="rmis-title-date">
            Last Updated: <span>{lastUpdatedTime}</span>
          </div>
        )}
      </div>
    );
  };

  const RefreshButton = () => (
    <DeprecatedButton loading={isBtnLoading} variant="secondary" size="small" onClick={handleRefresh}>
      Refresh Connection
    </DeprecatedButton>
  );

  return (
    <div className="integrations-RMIS-card-container grid-1-2">
      <Card title={renderCardTitle('FTP')}>
        <Formik
          enableReinitialize
          validationSchema={FTPSchema}
          onSubmit={handleFTPSubmit}
          initialValues={{...defaultFTPFormValues, ...values}}
        >
          {({handleSubmit, values}) => (
            <form noValidate="novalidate" onSubmit={handleSubmit}>
              <RMISFTPFields isFTPDisabled={isFTPConnected} {...props} values={values} />
              <div className={classNames('connection-card-submit-btn-container', {connected: isFTPConnected})}>
                {isFTPConnected && <Connected />}
                {!isFTPConnected ? (
                  <DeprecatedButton size="small" onClick={handleSubmit}>
                    Connect
                  </DeprecatedButton>
                ) : (
                  <>
                    {isBtnLoading ? (
                      <Tooltip
                        placement="top"
                        tooltipContent="Refresh of data in progress, this may take a few seconds to a few minutes."
                      >
                        <RefreshButton />
                      </Tooltip>
                    ) : (
                      <RefreshButton />
                    )}
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </Card>
      <Card title={renderCardTitle('API')}>
        <Formik
          enableReinitialize
          validationSchema={APISchema}
          onSubmit={handleAPISubmit}
          initialValues={{...defaultAPIFormValues, ...values}}
        >
          {({handleSubmit, values}) => (
            <form noValidate="novalidate" onSubmit={handleSubmit}>
              <RMISAPIFields isAPIDisabled={!isFTPConnected || isAPIConnected} values={values} />
              <div className={classNames('connection-card-submit-btn-container', {connected: isAPIConnected})}>
                {isAPIConnected && <Connected />}
                {!isAPIConnected && (
                  <DeprecatedButton size="small" disabled={!isFTPConnected} onClick={handleSubmit}>
                    Connect
                  </DeprecatedButton>
                )}
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default RMISConfig;
