import SupplierAppointmentSidebar from 'App/containers/appointments/components/forms/SupplierAppointment/SupplierAppointmentSidebar';
const BasicSidebar = () => {
  return (
    <>
      <SupplierAppointmentSidebar title="Create New Appointment">
        Create an appointment for freight or a generic appointment.
      </SupplierAppointmentSidebar>
    </>
  );
};
export default BasicSidebar;
