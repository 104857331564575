import {OrderStop, PurchaseOrder} from '@shipwell/corrogo-sdk';
import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import {SharedTypeEnum} from '../AddOrdersToShipmentSharedStartEndPoint';

export interface InitialValuesOrder {
  orderId: string;
  formIndex: number;
  orderNumber: string;
  ship_from: OrderStop | undefined;
  ship_to: OrderStop;
}

export interface InitialValuesOrderDates {
  orders: InitialValuesOrder[];
  sharedType: SharedTypeEnum;
  shared_address?: AddressBookEntry['address'];
  start_time?: string | null;
  end_time?: string | null;
}

export const getInitialValues = (orders: PurchaseOrder[]): InitialValuesOrderDates => {
  if (!orders || orders.length === 0) {
    return {
      orders: [],
      sharedType: SharedTypeEnum.Start,
      shared_address: {country: ''},
      start_time: null,
      end_time: null
    };
  }
  return {
    orders: orders.map((order, index) => ({
      formIndex: index,
      orderId: order.id,
      orderNumber: order.order_number,
      ship_from: {
        ...order.ship_from,
        country: order.ship_from?.country || '',
        shipping_requirements: {
          plan_window: {
            start: order.ship_from?.shipping_requirements?.plan_window?.start || '',
            end: order.ship_from?.shipping_requirements?.plan_window?.end || ''
          }
        }
      },
      ship_to: {
        ...order.ship_to,
        country: order.ship_from?.country || '',
        shipping_requirements: {
          plan_window: {
            start: order.ship_to?.shipping_requirements?.plan_window?.start || '',
            end: order.ship_to?.shipping_requirements?.plan_window?.end || ''
          }
        }
      }
    })),
    sharedType: SharedTypeEnum.Start,
    shared_address: {country: ''},
    start_time: null,
    end_time: null
  };
};
