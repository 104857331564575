export const tagIcon = (level) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" width="20px" height="20px">
      <path
        d="m229 14.644531c-9.699219-9.671875-22.933594-14.957031-36.628906-14.628906l-140.078125 
				3.570313c-26.621094.664062-48.046875 22.085937-48.710938 
				48.710937l-3.566406 140.074219c-.324219 13.695312 4.957031 
				26.929687 14.625 36.632812l193.367187 193.359375c19.539063 
				19.496094 51.167969 19.496094 70.707032 
				0l143.644531-143.644531c19.527344-19.523438 
				19.527344-51.183594 0-70.710938zm179.21875 249.933594-143.644531 
				143.640625c-11.722657 11.699219-30.703125 11.699219-42.425781 
				0l-193.363282-193.359375c-5.800781-5.824219-8.972656-13.761719-8.777344-21.980469l3.566407-140.074218c.402343-15.972657 
				13.253906-28.828126 29.226562-29.226563l140.074219-3.570313c.253906-.003906.5-.007812.753906-.007812 
				7.957032.011719 15.585938 3.171875 21.222656 8.789062l193.367188 
				193.363282c11.695312 11.722656 11.695312 30.703125 0 42.425781zm0 0"
      />
      <path
        d="m130.71875 82.574219c-26.589844 0-48.144531 21.554687-48.148438 
				48.144531 0 26.589844 21.558594 48.144531 48.144532 48.144531 
				26.589844 0 48.144531-21.554687 48.144531-48.144531-.03125-26.574219-21.566406-48.113281-48.140625-48.144531zm0 
				76.289062c-15.546875 0-28.144531-12.601562-28.148438-28.144531 
				0-15.542969 12.601563-28.144531 28.144532-28.144531 15.542968 0 
				28.144531 12.601562 28.144531 28.144531-.015625 15.535156-12.605469 28.125-28.140625 28.144531zm0 0"
      />
    </svg>
  );
};

export const leftArrow = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 31.494 31.494" width="32px" height="32px">
      <path
        d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
				c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
				c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
      />
    </svg>
  );
};
