import {ChangeEvent, FC, useCallback} from 'react';

import {Field, FieldArray, useFormikContext} from 'formik';
import {
  Card,
  FormikSelect,
  FormikCheckbox,
  SvgIcon,
  DeprecatedButton,
  FormikDateTimePicker,
  FormikTextInput,
  CollapsibleCardContent
} from '@shipwell/shipwell-ui';
import {StandardHolidays} from '@shipwell/tempus-sdk';

import {FacilityAttributesFormValues, FacilityHolidayEntry} from 'App/data-hooks/facilities/types';
import {OTHER, WEEKLY_DEFAULT_CLOSE_TIME, WEEKLY_DEFAULT_OPEN_TIME} from 'App/data-hooks/facilities/constants';
import {useStandardHolidays} from 'App/data-hooks';
import {hourOptions} from 'App/utils/dateTimeGlobalsTyped';
import {toTitleCase} from 'App/utils/string';
import {newFacilityHolidayEntry} from 'App/data-hooks/facilities/holidayUtils';

type AddNewHolidayButtonProps = {
  push: (facilityHolidayRule: FacilityHolidayEntry) => void;
};

const AddNewHolidayButton: FC<AddNewHolidayButtonProps> = ({push}) => {
  const onClickAddNewHoliday = useCallback(() => {
    push({
      ...newFacilityHolidayEntry(),
      isCustom: false,
      standard: '' as StandardHolidays // yes this is a hack to make the holiday field initially blank.
    });
  }, [push]);

  return (
    <DeprecatedButton variant="tertiary" icon={<SvgIcon name="AddCircleOutlined" />} onClick={onClickAddNewHoliday}>
      Add Holiday
    </DeprecatedButton>
  );
};

const FacilityHolidayRules = () => {
  return (
    <FieldArray
      name="holidayRules"
      validateOnChange
      render={({remove, replace, push}) => (
        <Card
          draggableProvided={null}
          title="Holiday Rules"
          actions={<AddNewHolidayButton push={push} />}
          isCollapsible
        >
          <CollapsibleCardContent>
            <FacilityHolidayRuleList remove={remove} replace={replace} />
          </CollapsibleCardContent>
        </Card>
      )}
    />
  );
};

function FacilityHolidayRuleList({
  remove,
  replace
}: {
  remove: <T>(index: number) => T | undefined;
  replace: (index: number, value: FacilityHolidayEntry) => void;
}) {
  const {holidays} = useStandardHolidays();

  const {
    values: {holidayRules},
    setFieldValue
  } = useFormikContext<FacilityAttributesFormValues>();

  const onSelectHoliday = useCallback(
    (index: number, value: string) => {
      const standardHoliday = holidays.find((option) => option.value === value)?.standardHoliday;
      if (!standardHoliday) {
        return;
      }
      const {human_readable_holiday_name: name, date, standard_holiday_name: standard} = standardHoliday;
      const currentHoliday = holidayRules[index];
      const updatedHoliday: FacilityHolidayEntry =
        standard === OTHER
          ? {...currentHoliday, isCustom: true, name, date, standard: OTHER}
          : {...currentHoliday, isCustom: false, name, date, standard};

      replace(index, updatedHoliday);
    },
    [holidays, holidayRules, replace]
  );

  return (
    <>
      {holidayRules.map((holidayRule, index) => (
        <FacilityHolidayRule
          key={'id' in holidayRule ? holidayRule.id : holidayRule.key}
          holidayRule={holidayRule}
          index={index}
          onSelectHoliday={onSelectHoliday}
          setFieldValue={setFieldValue}
          remove={remove}
        />
      ))}
    </>
  );
}

function FacilityHolidayRule({
  holidayRule,
  index,
  onSelectHoliday,
  setFieldValue,
  remove
}: {
  holidayRule: FacilityHolidayEntry;
  index: number;
  onSelectHoliday: (index: number, value: string) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
  remove: <T>(index: number) => T | undefined;
}) {
  const {holidays} = useStandardHolidays();

  return (
    <div className="mb-2 grid grid-cols-5 gap-x-5">
      <Field
        simpleValue
        required
        label="Holiday"
        name={`holidayRules[${index}].standard`}
        component={FormikSelect}
        options={holidays}
        formatOptionLabel={(option: {label: StandardHolidays; value: string}) => toTitleCase(option.label)}
        onChange={(value: string) => onSelectHoliday(index, value)}
        menuPlacement="top"
      />

      <Field
        showTimeSelect={false}
        label="Date"
        required
        name={`holidayRules[${index}].date`}
        component={FormikDateTimePicker}
        textInputClassname="w-full"
        dateFormat="eee MMM d, yyyy"
        onChange={(val: string) => {
          if (holidayRule.isCustom) {
            setFieldValue(`holidayRules[${index}].date`, val);
          }
        }}
        prepend={<SvgIcon name="Calendar" color="$sw-icon" />}
        disabled={!holidayRule.isCustom}
      />

      <Field
        simpleValue
        required
        label="Open Time"
        component={FormikSelect}
        name={`holidayRules[${index}].firstAppointmentStartTime`}
        options={hourOptions}
        disabled={holidayRule.closedAllDay}
        menuPlacement="top"
        prepend={<SvgIcon name="Time" color="$sw-icon" />}
      />

      <Field
        simpleValue
        required
        label="Close Time"
        component={FormikSelect}
        name={`holidayRules[${index}].lastAppointmentEndTime`}
        options={hourOptions}
        menuPlacement="top"
        disabled={holidayRule.closedAllDay}
        prepend={<SvgIcon name="Time" color="$sw-icon" />}
      />

      <div className="flex items-center justify-between">
        <Field
          label="Closed All Day"
          name={`holidayRules[${index}].closedAllDay`}
          onChange={(val: ChangeEvent<HTMLInputElement>) => {
            if (val.target.checked) {
              setFieldValue(`holidayRules[${index}].firstAppointmentStartTime`, '');
              setFieldValue(`holidayRules[${index}].lastAppointmentEndTime`, '');
            } else {
              setFieldValue(`holidayRules[${index}].firstAppointmentStartTime`, WEEKLY_DEFAULT_OPEN_TIME);
              setFieldValue(`holidayRules[${index}].lastAppointmentEndTime`, WEEKLY_DEFAULT_CLOSE_TIME);
            }
            setFieldValue(`holidayRules[${index}].closedAllDay`, val.target.checked);
          }}
          component={FormikCheckbox}
        />
        <DeprecatedButton variant="icon" className="trashOutlinedButton" onClick={() => remove(index)}>
          <SvgIcon name="TrashOutlined" />
        </DeprecatedButton>
      </div>

      {holidayRule.isCustom ? (
        <div className="mb-2">
          <Field
            required
            label="Custom Holiday Name"
            name={`holidayRules[${index}].name`}
            component={FormikTextInput}
            style={{'text-overflow': 'ellipsis'}}
          />
        </div>
      ) : null}
    </div>
  );
}

export default FacilityHolidayRules;
