import {RegularSelect, Tag} from '@shipwell/shipwell-ui';
import {useMemo, useState} from 'react';
import {TagOption} from './tagOption';

export function MultiSelectWithColors() {
  const [value, setValue] = useState<TagOption[]>([]);

  const options = useMemo<TagOption[]>(
    () => [
      {label: 'label 1', value: '1', color: '#7B68EE'},
      {label: 'label 2', value: '2', color: '#FF6347'},
      {label: 'label 3', value: '3', color: '#3CB371'}
    ],
    []
  );

  return (
    <>
      <h2>Multiselect with colors</h2>

      <RegularSelect
        label={'Tags'}
        isMulti
        options={options}
        value={value}
        onChange={(newValue) => {
          const fixedType = newValue as TagOption[];
          setValue(fixedType);
        }}
        components={{
          MultiValueLabel: (multiProps: unknown) => {
            const {data} = multiProps as {data: TagOption};
            return <Tag size="m" label={data.label} color={data.color} />;
          },
          Option: (optionProps: unknown) => {
            const {data, selectOption} = optionProps as {data: TagOption; selectOption: (data: TagOption) => void};
            return <Tag size="m" label={data.label} color={data.color} onClick={() => selectOption(data)} />;
          }
        }}
      />
    </>
  );
}
