/* global $, google */
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, change} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import ShipmentModeEquipmentFields from 'App/formComponents/formSections/shipmentModeEquipmentFields';
import * as actions from 'App/actions/shipments';
import {validateTemperature, reeferTypes} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';
import {POUNDS} from 'App/utils/internationalConstants';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect(
  (state) => ({
    user: state.auth.user,
    equipmentTypes: state.shipments.equipmentTypes,
    carrierRelationships: state.vendors.carrierRelationships,
    serviceLevels: state.shipments.serviceLevels,
    shipmentModes: state.shipments.shipmentModes,
    unitPreferences: state.userCompany.unitPreferences
  }),
  {...actions, change}
)
export default class ModeEquipment extends Component {
  constructor(props) {
    super(props);

    this.state = {showTempFields: false};
  }

  componentDidMount() {
    if (this.props.formValues && this.props.formValues.values && this.props.formValues.values.equipment_type) {
      const equipment_type = this.props.formValues.values.equipment_type;
      if (reeferTypes.includes(Number(equipment_type))) {
        this.setState({showTempFields: true});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {formValues, change, form, unitPreferences} = this.props;
    if (
      (formValues &&
        formValues.values &&
        nextProps.formValues &&
        nextProps.formValues.values &&
        nextProps.formValues.values.equipment_type &&
        nextProps.formValues.values.equipment_type !== formValues.values.equipment_type) ||
      (formValues && !formValues.values && nextProps.formValues.values && nextProps.formValues.values.equipment_type)
    ) {
      //if reefer is selected as an equipment_type type, show the temperature fields
      //on new quote this is an array of types
      const equipment_type = nextProps.formValues.values.equipment_type;

      if (reeferTypes.includes(Number(equipment_type))) {
        this.setState({showTempFields: true});
        if (!formValues.values.temperature_unit) {
          change(form, 'temperature_unit', getShipmentTemperatureUnit(formValues.values));
        }
      } else {
        this.setState({showTempFields: false});
        change(form, 'temperature_lower_limit', '');
        change(form, 'temperature_upper_limit', '');
      }
    }
    if (
      //User picked FTL and then changed mode
      formValues?.values?.mode === 1 &&
      nextProps?.formValues?.values?.mode !== formValues?.values?.mode
    ) {
      //resets weight override
      change(form, 'total_weight_override', {
        value: null,
        unit: unitPreferences.weightUnit || POUNDS
      });
      //indicates required field
      change(form, 'manual_total_weight', false);
    }
  }

  componentWillMount() {}

  render() {
    const {form, formValues = {}} = this.props;
    const {values = {}} = formValues;
    const {hasDrayage} = checkShipmentModes(values.mode);
    const serviceLevels = [];
    if (this.props.serviceLevels && this.props.serviceLevels.length > 0) {
      for (var k = 0; k < this.props.serviceLevels.length; k++) {
        serviceLevels.push({
          id: this.props.serviceLevels[k].id,
          name: this.props.serviceLevels[k].description
        });
      }
    }
    const shipmentModes = [];
    if (this.props.shipmentModes && this.props.shipmentModes.length > 0) {
      for (var j = 0; j < this.props.shipmentModes.length; j++) {
        shipmentModes.push({
          id: this.props.shipmentModes[j].id,
          name: this.props.shipmentModes[j].description
        });
      }
    }

    const temperatureUnit = getShipmentTemperatureUnit(values);

    return (
      <div id="pageFour" className="quote-flow__page-4">
        <h2 className="border-bottom">Mode & Equipment</h2>
        <Element name={`position-mode`} />
        <Element name={`position-equipment_type`} />
        <Element name={`position-temperature_lower_limit`} />
        <Element name={`position-temperature_upper_limit`} />

        <div className="quote-flow__carrierEquipment">
          <ShipmentModeEquipmentFields form={form} />
          {this.state.showTempFields && (
            <Row className="quote-flow__mode-temp">
              <Col xs={12} sm={6}>
                <Field
                  component={renderField}
                  req
                  label={`Minimum Temperature (\u00B0${temperatureUnit})`}
                  name="temperature_lower_limit"
                  placeholder={temperatureUnit === 'C' ? 'e.g., 0' : 'e.g., 35'}
                  format={(value) => validateTemperature(value)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  component={renderField}
                  req
                  label={`Maximum Temperature (\u00B0${temperatureUnit})`}
                  name="temperature_upper_limit"
                  placeholder={temperatureUnit === 'C' ? 'e.g., 10' : 'e.g., 50'}
                  format={(value) => validateTemperature(value)}
                />
              </Col>
            </Row>
          )}
          {!hasDrayage && (
            <Field
              component={renderDropDownSelector}
              data={serviceLevels}
              name="service_level"
              label="Service Level"
              req
              placeholder="Select Service Level"
            />
          )}
        </div>
      </div>
    );
  }
}
