import {FormikTextInput, FormikTextarea, SvgIcon} from '@shipwell/shipwell-ui';
import {Field, useFormikContext} from 'formik';
import {NewSupplierFormInitialValues} from '../forms/NewSupplierForm/NewSupplierForm';
import AddressSearch from 'App/formComponents/fields/_addressSearch';

export const SupplierFields = () => {
  const {errors} = useFormikContext<NewSupplierFormInitialValues>();

  return (
    <>
      <Field className="grow" name="name" label="Supplier Name" required component={FormikTextInput} />
      <Field className="grow" name="external_id" label="Supplier ID" component={FormikTextInput} />
      <Field
        component={AddressSearch}
        name="mailing_address"
        label="Mailing Address"
        required
        prepend={<SvgIcon name="Location" />}
        useFormikValidation
        isFormikValid={!errors['mailing_address']}
      />
      <Field
        component={AddressSearch}
        name="billing_address"
        label="Billing Address"
        required
        prepend={<SvgIcon name="Location" />}
        useFormikValidation
        isFormikValid={!errors['billing_address']}
      />
      <Field
        name="billing_email"
        label="Billing Email"
        component={FormikTextInput}
        prepend={<SvgIcon name="EmailOutlined" />}
      />
      <Field name="notes" label="Supplier notes" component={FormikTextarea} />
    </>
  );
};
