import PropTypes from 'prop-types';
import Tags from 'App/components/Tags';
import useShipmentTags from 'App/data-hooks/shipments/useShipmentTags';

const HeaderTags = ({shipmentId, className, showTagBorder}) => {
  const {
    addTagToShipmentMutation,
    removeTagFromShipmentMutation,
    context: {initialTags}
  } = useShipmentTags(shipmentId);
  return (
    <Tags
      initialTags={initialTags}
      addTag={async (tagId) => await addTagToShipmentMutation.mutateAsync({shipmentId, tagId})}
      removeTag={async (tagId) => await removeTagFromShipmentMutation.mutateAsync({shipmentId, tagId})}
      showDefaultTagsLink
      className={className}
      showTagBorder={showTagBorder}
    />
  );
};

HeaderTags.propTypes = {
  shipmentId: PropTypes.string,
  className: PropTypes.string,
  showTagBorder: PropTypes.bool
};

HeaderTags.defaultProps = {
  className: '',
  showTagBorder: true
};

export default HeaderTags;
