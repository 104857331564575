import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {autoRehydrate} from 'redux-persist';
import identity from 'lodash/identity';
import rootReducer from '../reducers/index';

const promise = promiseMiddleware(); // resolves promises

const middleware = [thunk, promise];

const composeWithEnhancers =
  process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeWithEnhancers(
    applyMiddleware(...middleware),
    process.env.NODE_ENV === 'production' ? autoRehydrate() : identity
  )
);

export default store;
