import {Creatable} from 'react-select';
import FormGroup from '../../formGroup';
import './styles.scss';

/**
 * Createable Field
 */
const CreateableField = (props) => {
  const {input, req, placeholder, options, labelKey, valueKey, clearable, ...rest} = props;
  const value = input.value || props.value;
  const name = input.name || props.name;
  const onChange = input.onChange || props.onChange;
  const onNewOptionClick = input.onNewOptionClick || props.onNewOptionClick;

  return (
    <FormGroup {...props} className="select-group">
      {() => (
        <Creatable
          {...rest}
          name={name}
          value={value}
          componentClass="select"
          labelKey={labelKey}
          valueKey={valueKey}
          placeholder={placeholder}
          options={options}
          clearable={clearable}
          onChange={(value) => onChange && onChange(value)}
          onNewOptionClick={(value) => onNewOptionClick && onChange(onNewOptionClick(value))}
        />
      )}
    </FormGroup>
  );
};

CreateableField.defaultProps = {
  name: '',
  value: '',
  input: {},
  labelKey: 'name',
  valueKey: 'id',
  clearable: false
};

export default CreateableField;
