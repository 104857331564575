import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Field} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';

const RMISAPIFields = ({isAPIDisabled}) => {
  return (
    <>
      <div className="field-grid grid-1-1">
        <Field required label="API Username" name="username" disabled={isAPIDisabled} component={FormikTextInput} />
        <Field
          required
          label="API Password"
          name="password"
          disabled={isAPIDisabled}
          component={FormikTextInput}
          type="password"
        />
      </div>
    </>
  );
};
export default connect((state) => ({}))(RMISAPIFields);
