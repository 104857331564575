import {PropsWithChildren} from 'react';
import {Link} from 'react-router';
import classNames from 'classnames';
import './styles.scss';

const SideNav = ({
  children,
  title,
  backRoute,
  className
}: PropsWithChildren<{title?: string; backRoute?: string; className?: string}>) => (
  <div className={classNames('sw-side-nav', className)}>
    <div className="sw-side-nav-content">
      {backRoute ? <Link to={backRoute}>&lt; Back</Link> : null}
      {title ? <h2>{title}</h2> : null}
      {children}
    </div>
  </div>
);

export default SideNav;
