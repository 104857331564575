import {Invocation} from '@shipwell/scriptlab-sdk';
import {InvocationData} from './invocationData';

export function getDataFromInvocations(data: Invocation[]) {
  return data.map<InvocationData>((inv) => ({
    id: inv.id,
    name: inv.script_name || '',
    didSucceed: inv.did_succeed,
    triggerEvent: transformWebhookEventNameIntoFriendlyFormat(inv.webhook_event_name),
    startDate: inv.created_at,
    endDate: inv.updated_at,
    message: getMessage(inv)
  }));
}

export function getMessage(inv: Invocation) {
  if (inv.did_succeed === true) return inv.outcome_message || 'Script Succeeded.';
  if (inv.did_succeed === false) return inv.outcome_message;
  return 'Script is running...';
}

/**
 * Transforms event names that come in formats like
 * `rfq.did_finish_autoquote` or `shipment.created` into
 * friendly formats like "Rfq Did Finish Autoquote",
 * since events could be dynamically created
 * and will not always match the SupportedEvents enum.
 */
export function transformWebhookEventNameIntoFriendlyFormat(evt?: string) {
  if (!evt) return 'Unknown';

  // Step 1: Replace '.' and '_' with spaces
  const spaced = evt.replace(/[._]/g, ' ');

  // Step 2: Capitalize each word
  const capitalized = spaced.replace(/\b\w/g, (char) => char.toUpperCase());

  return capitalized;
}
