import {Field} from 'formik';
import {FormikTextInput} from '@shipwell/shipwell-ui';
import './styles.scss';

const SearchUsersFields = () => {
  return (
    <Field
      className="sw-search-input"
      label="Search for Users"
      aria-label="Search for Users"
      name="q"
      component={FormikTextInput}
      prepend={<i className="flaticon-search pad-left" />}
    />
  );
};

export default SearchUsersFields;
