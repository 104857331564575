import {JSX, HTMLAttributes} from 'react';
import {FacilityServices} from '@shipwell/tempus-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';

export type FacilityServiceIconProps = {
  service: FacilityServices;
} & Pick<HTMLAttributes<SVGElement>, 'color' | 'className'>;

const FacilityServiceIcon = ({className, color, service}: FacilityServiceIconProps): JSX.Element | null => {
  switch (service) {
    case 'FOOD':
      return <SvgIcon className={className} color={color} name="Food" />;
    case 'EXTERIOR_PARKING':
      return <SvgIcon className={className} color={color} name="POutlined" />;
    case 'INTERIOR_PARKING':
      return <SvgIcon className={className} color={color} name="PFilled" />;
    case 'LOUNGE_AREA':
      return <SvgIcon className={className} color={color} name="WaitingRoom" />;
    case 'RESTROOMS':
      return <SvgIcon className={className} color={color} name="Reststop" />;
    case 'OVERNIGHT_PARKING':
      return <SvgIcon className={className} color={color} name="Night" />;
    case 'SECURITY':
      return <SvgIcon className={className} color={color} name="Security" />;
    case 'WIFI':
      return <SvgIcon className={className} color={color} name="Wifi" />;
    case 'SPANISH':
      return <SvgIcon className={className} color={color} name="Global" />;
    default:
      return null;
  }
};

export {FacilityServiceIcon};
