import {Modal} from '@shipwell/shipwell-ui';
import {useDeleteUpsAccountMutation} from 'App/data-hooks/parcel/UPS/hooks/useDeleteUpsAccountMutation';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

type ConfirmUpsAccountDeleteProps = {
  onClose: () => void;
  accountIdToDelete: string | null;
  setError?: WithStatusToastProps['setError'];
};

const ConfirmUpsAccountDelete = ({onClose, accountIdToDelete, setError}: ConfirmUpsAccountDeleteProps) => {
  const {mutateAsync: deleteUpsAccount} = useDeleteUpsAccountMutation({
    onError: (err) => {
      const {error = 'An unknown error occurred.', error_description = 'An unknown error occurred.'} =
        err.response?.data || {};
      setError?.(error, error_description);
    },
    onSuccess: onClose
  });

  const handleDeleteUpsAccount = async () => {
    if (accountIdToDelete) await deleteUpsAccount(accountIdToDelete);
  };

  return (
    <Modal
      show={!!accountIdToDelete}
      title="Delete UPS Account"
      variant="warning"
      onClose={onClose}
      primaryBtnName="Delete"
      onPrimaryAction={handleDeleteUpsAccount}
    >
      Are you sure you want to delete this UPS account?
    </Modal>
  );
};

export default ConfirmUpsAccountDelete;
