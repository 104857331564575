import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Toast} from '@shipwell/shipwell-ui';
import {getCompanyPreferences, editCompanyPreferences} from 'App/containers/userCompany/actions/async';
import CompanyTermsCreditForm from 'App/formComponents/forms/companyTerms';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import PageHeader from 'App/common/pageHeader';
import './styles.scss';

/**
 * Company Terms Details
 * @param {*} props
 *
 * @todo Move Toasts to top level
 */
const CompanyTermsDetailsContainer = (props) => {
  const {company, preferences, location, dispatch} = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const isLoadingPreferences = preferences.isFetching;

  /**
   * Request company preferences
   * @param {Number} companyId
   * @param {Number} userId
   */
  const fetchCompanyPreferences = async (companyId) => {
    try {
      await dispatch(getCompanyPreferences(companyId));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Update company preferences
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSave = async (values, {setSubmitting}) => {
    setSubmitting(true);

    try {
      await dispatch(editCompanyPreferences(company.id, values));

      setShowSuccess(true);
    } catch (error) {
      setShowError(error.error_description);
    }
    setSubmitting(false);
  };

  /** Fetch company preferences */
  useEffect(() => {
    if (company.id) {
      fetchCompanyPreferences(company.id);
    }
  }, [location, company]);

  return (
    <div className="company-terms-edit-wrapper">
      {isLoadingPreferences && <ShipwellLoader loading />}
      {!isLoadingPreferences && (
        <>
          <div className="border-b border-sw-border p-2 text-lg">Carrier Instructions</div>
          <CompanyTermsCreditForm
            edit
            values={preferences}
            onSubmit={handleSave}
            primaryEmail={company?.primary_email}
          />
        </>
      )}
      <Toast show={showSuccess} title="Success!" anchor="bottom-right" onClose={() => setShowSuccess(false)}>
        Successfully updated.
      </Toast>
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </div>
  );
};

export default connect((state) => ({
  company: state.company.company,
  preferences: state.company.preferences
}))(CompanyTermsDetailsContainer);
