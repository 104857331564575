import {
  TemplatingApi,
  Configuration,
  GenericContentTemplateCreateUpdateRequest,
  ShippingLabelContentTemplateCreateUpdateRequest,
  UpdateTemplateRequest,
  PaginatedContentTemplateList,
  ContentTemplateResponse,
  DocumentType as DocumentTypeObject
} from '@shipwell/digital-documents-sdk';
import {getAccessToken} from 'App/api/utils';
import base64EncodeBlob from 'App/utils/base64EncodeBlob';
import {DocumentType, ParameterValues, ScenarioContext} from 'App/containers/templateManagement/types';
import {DOC_TYPE_SHIPPING_LABEL} from 'App/containers/templateManagement/constants';

const basePath = process.env.SHIPWELL_DIGITAL_DOCUMENTS_API_BASE_PATH;

// Templating API
function createTemplatingApi() {
  return new TemplatingApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
const templatingApi = createTemplatingApi();
export async function createTemplatePreview({
  documentType,
  parameterValues,
  scenario,
  scenarioContext,
  format
}: {
  documentType: DocumentType;
  parameterValues: ParameterValues;
  scenario: string;
  scenarioContext: ScenarioContext;
  format?: 'pdf';
}) {
  const getEncodedLogo = async (parameterValues: ParameterValues) => {
    if (typeof parameterValues.logo?.image === 'string') {
      return parameterValues.logo.image;
    }
    if (
      (parameterValues.logo?.type === 'custom' && parameterValues.logo.image?.constructor.name === 'Blob') ||
      parameterValues.logo?.image?.constructor.name === 'File'
    ) {
      const imageData = await base64EncodeBlob(parameterValues.logo.image);
      return imageData;
    }
    return undefined;
  };

  const encodedLogo = await getEncodedLogo(parameterValues);
  const isShippingLabel = documentType === DOC_TYPE_SHIPPING_LABEL;

  return templatingApi.templatingTemplatesPreviewCreate(
    documentType,
    format,
    {
      scenario,
      parameter_values:
        !isShippingLabel && encodedLogo
          ? {...parameterValues, logo: {...parameterValues.logo, image: encodedLogo}}
          : parameterValues,
      scenario_context: scenarioContext
    },
    {responseType: 'blob'}
  );
}

export async function getTemplate(documentType: DocumentType, id: string) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesRetrieve(documentType, id);
}

export async function createTemplate({
  documentType,
  createTemplateRequest
}: {
  documentType: DocumentType;
  createTemplateRequest: GenericContentTemplateCreateUpdateRequest | ShippingLabelContentTemplateCreateUpdateRequest;
}) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesCreate(documentType, createTemplateRequest);
}

export async function updateTemplate({
  documentType,
  templateId,
  updateTemplateRequest
}: {
  documentType: DocumentType;
  templateId: string;
  updateTemplateRequest: UpdateTemplateRequest;
}) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesUpdate(documentType, templateId, updateTemplateRequest);
}

export async function activateTemplate(documentType: DocumentType, templateId: string) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesMakeDefaultCreate(documentType, templateId);
}

export async function deleteTemplate(documentType: DocumentType, templateId: string) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesDestroy(documentType, templateId);
}

interface GetTemplateListOptions {
  createdAtGt?: string;
  createdAtGte?: string;
  createdAtLt?: string;
  createdAtLte?: string;
  id?: string;
  idIn?: Array<string>;
  limit?: number;
  name?: string;
  nameContains?: string;
  nameIcontains?: string;
  nameIexact?: string;
  nameIn?: Array<string>;
  offset?: number;
  ordering?: string;
  search?: string;
  updatedAtGt?: string;
  updatedAtGte?: string;
  updatedAtLt?: string;
  updatedAtLte?: string;
}

export async function getTemplatesList(documentType: DocumentType, options?: GetTemplateListOptions) {
  const templatingApi = createTemplatingApi();

  return templatingApi.templatingTemplatesList(
    documentType,
    options?.createdAtGt,
    options?.createdAtGte,
    options?.createdAtLt,
    options?.createdAtLte,
    options?.id,
    options?.idIn,
    options?.limit,
    options?.name,
    options?.nameContains,
    options?.nameIcontains,
    options?.nameIexact,
    options?.nameIn,
    options?.offset,
    options?.ordering,
    options?.search,
    options?.updatedAtGt,
    options?.updatedAtGte,
    options?.updatedAtLt,
    options?.updatedAtLte
  );
}

/**
 * List templates for a document type
 */

export async function getTemplatesByDocType(
  documentType: DocumentType,
  options?: GetTemplateListOptions
): Promise<PaginatedContentTemplateList> {
  const templatingApi = createTemplatingApi();
  const axiosResponse = await templatingApi.templatingTemplatesList(
    documentType,
    options?.createdAtGt,
    options?.createdAtGte,
    options?.createdAtLt,
    options?.createdAtLte,
    options?.id,
    options?.idIn,
    options?.limit,
    options?.name,
    options?.nameContains,
    options?.nameIcontains,
    options?.nameIexact,
    options?.nameIn,
    options?.offset,
    options?.ordering,
    options?.search,
    options?.updatedAtGt,
    options?.updatedAtGte,
    options?.updatedAtLt,
    options?.updatedAtLte
  );
  return axiosResponse.data;
}

export async function getActiveTemplateId(documentType: DocumentType): Promise<DocumentTypeObject> {
  const axiosResponse = await templatingApi.templatingDocumentTypesRetrieve(documentType);
  return axiosResponse.data;
}

export async function getActiveTemplate(documentType: DocumentType): Promise<ContentTemplateResponse | unknown> {
  try {
    const templateId = (await getActiveTemplateId(documentType)).active_template;
    if (!templateId) throw new Error('Missing required template ID parameter');
    const axiosResponse = await templatingApi.templatingTemplatesRetrieve(documentType, templateId);
    return axiosResponse.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}
