import PropTypes from 'prop-types';
import {useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import pick from 'lodash/pick';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import EmbeddedPredictivePricing from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingPredictivePricing';
import {
  pricingIntelVariantsBySource,
  PRICING_INTEL_DATA_STATE_DISABLED,
  PREDICTIVE_MARKET,
  PREDICTIVE_INTERNAL,
  MARKET_CARRIER,
  INTERNAL_SHIPPER,
  pricingIntelDataStates,
  ErrorMessageContainer,
  DisabledContainer,
  PRICING_INTEL_DATA_STATE_VALID
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {PricingIntelContext} from 'App/data-hooks/pricing/usePricingIntel';
import './styles.scss';
import 'App/components/pricingIntelEmbeddedPricing/embeddedPricingGrid.styles.scss';
import EmbeddedHistoricalLaneInfo from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingHistoricalLaneInfo';
import EmbeddedHistoricalMarketTrend from 'App/components/pricingIntelEmbeddedPricing/components/embeddedPricingHistoricalMarketTrend';

const PricingIntelEmbeddedPricing = ({
  pageSource,
  handleSelectPriceSuggestion,
  cardClassName,
  initialPricingLoadSuccessAction
}) => {
  const variantClassName = pricingIntelVariantsBySource[pageSource].variant
    ? `variant-${pricingIntelVariantsBySource[pageSource].variant}`
    : '';
  const {
    historicalPricingDataState,
    predictivePricingDataState,
    getPricingData,
    predictivePrices,
    rawHistoricalPrices
  } = useContext(PricingIntelContext);
  const [executeInitialPricingLoadSuccessAction, setExecuteInitialPricingLoadSuccessAction] = useState(false);

  useEffect(() => {
    //if pricing is disabled, set execute success action to true so it's
    //only called if user enables pricing
    if (
      initialPricingLoadSuccessAction &&
      historicalPricingDataState === PRICING_INTEL_DATA_STATE_DISABLED &&
      predictivePricingDataState === PRICING_INTEL_DATA_STATE_DISABLED
    ) {
      setExecuteInitialPricingLoadSuccessAction(true);
    }
  }, [historicalPricingDataState, predictivePricingDataState, initialPricingLoadSuccessAction]);

  useEffect(() => {
    //execute success action when data is loaded
    if (
      initialPricingLoadSuccessAction &&
      historicalPricingDataState === PRICING_INTEL_DATA_STATE_VALID &&
      predictivePricingDataState === PRICING_INTEL_DATA_STATE_VALID &&
      executeInitialPricingLoadSuccessAction
    ) {
      //don't execute initial load success action again
      setExecuteInitialPricingLoadSuccessAction(false);
      return initialPricingLoadSuccessAction();
    }
  }, [
    historicalPricingDataState,
    predictivePricingDataState,
    initialPricingLoadSuccessAction,
    executeInitialPricingLoadSuccessAction
  ]);
  return (
    <Card
      isCollapsible
      isCollapsed={false}
      title="Embedded Pricing Intel"
      className={classnames(cardClassName)}
      bodyClassName={classnames('pricingCardContentWrapper', 'embeddedPricing', variantClassName)}
    >
      <CollapsibleCardContent>
        {historicalPricingDataState === PRICING_INTEL_DATA_STATE_DISABLED ? (
          <DisabledContainer getPricingData={getPricingData}>Enable Embedded Pricing</DisabledContainer>
        ) : pricingIntelDataStates[historicalPricingDataState]?.error_message ? (
          <ErrorMessageContainer errorMessage={pricingIntelDataStates[historicalPricingDataState].error_message} />
        ) : (
          <div className="embeddedPricing__field-grid">
            <EmbeddedPredictivePricing
              predictivePricingVariant={PREDICTIVE_INTERNAL}
              predictivePrices={predictivePrices}
              handleSelectPriceSuggestion={handleSelectPriceSuggestion}
              predictivePricingDataState={predictivePricingDataState}
              pageSource={pageSource}
            />
            <EmbeddedPredictivePricing
              predictivePricingVariant={PREDICTIVE_MARKET}
              predictivePrices={predictivePrices}
              handleSelectPriceSuggestion={handleSelectPriceSuggestion}
              predictivePricingDataState={predictivePricingDataState}
              pageSource={pageSource}
            />
            {/* pass prices object with only historical lane info */}
            <EmbeddedHistoricalLaneInfo
              historicalInternalPrices={pick(rawHistoricalPrices, INTERNAL_SHIPPER)}
              historicalPricingDataState={historicalPricingDataState}
            />
            {/* pass prices object with only shipwell market composite */}
            <EmbeddedHistoricalMarketTrend
              shipwellMarketCompositePrices={pick(rawHistoricalPrices, MARKET_CARRIER)}
              historicalPricingDataState={historicalPricingDataState}
            />
          </div>
        )}
      </CollapsibleCardContent>
    </Card>
  );
};

PricingIntelEmbeddedPricing.propTypes = {
  handleSelectPriceSuggestion: PropTypes.func,
  pageSource: PropTypes.string.isRequired,
  priceSuggestionButtonVariant: PropTypes.string,
  selectPriceSuggestionTargetField: PropTypes.string,
  cardClassName: PropTypes.string,
  initialPricingLoadSuccessAction: PropTypes.func
};
PricingIntelEmbeddedPricing.defaultProps = {
  handleSelectPriceSuggestion: () => {},
  pageSource: '',
  priceSuggestionButtonVariant: '',
  selectPriceSuggestionTargetField: '',
  cardClassName: ''
};

export default PricingIntelEmbeddedPricing;
