import {CreateSupplierUser, Supplier, SupplierUser, UpdateSupplier} from '@shipwell/backend-core-sdk';
import {isDefined} from 'App/utils/guard';

export const isExistingUser = (user: SupplierUser | CreateSupplierUser): user is SupplierUser => 'id' in user;
export const isUpdatedUser = ({
  formUser,
  serverUsers
}: {
  formUser: SupplierUser | CreateSupplierUser;
  serverUsers: SupplierUser[] | undefined;
}) => {
  if (!serverUsers || !isExistingUser(formUser)) return false;
  const matchingServerUser = serverUsers?.find((serverUser) => serverUser.id === formUser.id);
  if (!matchingServerUser) return false;
  return (
    formUser.email !== matchingServerUser.email ||
    formUser.full_name !== matchingServerUser.full_name ||
    formUser.phone_number !== matchingServerUser.phone_number ||
    formUser.should_send_notifications !== matchingServerUser.should_send_notifications
  );
};

export const getDeletedUserIds = ({
  formUsers,
  serverUsers
}: {
  formUsers: (SupplierUser | CreateSupplierUser)[];
  serverUsers?: SupplierUser[];
}) => {
  if (!serverUsers) return [];
  const serverUserIds = serverUsers.map((serverUser) => serverUser.id);
  const formUserIds = formUsers.map((formUser) => (isExistingUser(formUser) ? formUser.id : null)).filter(isDefined);

  return serverUserIds.filter((serverUserId) => !formUserIds.includes(serverUserId));
};

export const pickUpdateSupplierFields = (supplier: Supplier): UpdateSupplier => ({
  billing_address: supplier.billing_address,
  billing_email: supplier.billing_email,
  mailing_address: supplier.mailing_address,
  name: supplier.name,
  external_id: supplier.external_id,
  notes: supplier.notes
});

export const pickCreateUserFields = (user: CreateSupplierUser | SupplierUser): CreateSupplierUser => ({
  email: user.email,
  full_name: user.full_name,
  phone_number: user.phone_number,
  should_send_notifications: user.should_send_notifications
});

export const sortByCreatedAT = (a: SupplierUser, b: SupplierUser) => {
  const timeA = new Date(a.created_at).getTime();
  const timeB = new Date(b.created_at).getTime();
  return timeA - timeB;
};
