import {ShipwellError} from '@shipwell/backend-core-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosRequestConfig} from 'axios';
import {refreshSuiteAppConfig} from 'App/api/integrations/typed';
import {SUITE_APP_CONFIG_QUERY} from 'App/data-hooks/queryKeys';

export const useRefreshSuiteAppConfig = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshSuiteAppConfig>>,
    AxiosError<ShipwellError>,
    AxiosRequestConfig | undefined
  >
) => {
  const queryClient = useQueryClient();
  const refreshSuiteAppConfigMutation = useMutation([SUITE_APP_CONFIG_QUERY], refreshSuiteAppConfig, {
    onSuccess: () => {
      void queryClient.invalidateQueries([SUITE_APP_CONFIG_QUERY]);
    },
    ...options
  });
  return {
    generateNewKey: refreshSuiteAppConfigMutation.mutate,
    isGenerateNewKeyLoading: refreshSuiteAppConfigMutation.isLoading
  };
};
