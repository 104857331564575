import {
  GET_POWER_UNITS,
  ALERT_ERROR,
  GET_POWER_UNIT,
  CLEAR_POWER_UNIT,
  GET_TRAILERS,
  GET_TRAILER,
  CLEAR_TRAILER,
  GET_POWER_UNIT_LOCATIONS,
  GET_DRIVERS,
  FETCH_ALL_HOS,
  REQ_CARRIER_DATA
} from './types';
import {getShipwellApi} from 'App/api/config';
import {parseErrors} from 'App/utils/globals';

//---------- Power Units ----------//
//GET /carriers/{carrierId}/power-units/
export function getPowerUnits(body, opts) {
  return (dispatch) => {
    dispatch(requestData());
    return getPowerUnitsPromise(body, opts)
      .then((response) => {
        dispatch({type: GET_POWER_UNITS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}
async function getPowerUnitsPromise(body, opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsGet(body, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}
//POST /carriers/{carrierId}/power-units/
export function postPowerUnit(carrierId, body) {
  return (dispatch) => {
    return postPowerUnitPromise(carrierId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function postPowerUnitPromise(carrierId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsPost(carrierId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//GET /carriers/{carrierId}/power-units/{powerUnitId}
export function getPowerUnit(carrierId, powerUnitId) {
  return (dispatch) => {
    return getPowerUnitPromise(carrierId, powerUnitId)
      .then((response) => {
        dispatch({type: GET_POWER_UNIT, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'Unable to get power unit'
        });
        return response;
      });
  };
}
async function getPowerUnitPromise(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdGet(carrierId, powerUnitId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//PUT /carriers/{carrierId}/power-units/{powerUnitId}
export function putPowerUnit(carrierId, powerUnitId, body) {
  return (dispatch) => {
    return putPowerUnitPromise(carrierId, powerUnitId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function putPowerUnitPromise(carrierId, powerUnitId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdPut(carrierId, powerUnitId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//DELETE /carriers/{carrierId}/power-units/{powerUnitId}
export function deletePowerUnit(carrierId, powerUnitId) {
  return (dispatch) => {
    return deletePowerUnitPromise(carrierId, powerUnitId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function deletePowerUnitPromise(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdDelete(carrierId, powerUnitId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//GET /carriers/{carrierId}/power-units/{powerUnitId}/shipments/
export function getPowerUnitShipments(carrierId, powerUnitId) {
  return (dispatch) => {
    return getPowerUnitShipmentsPromise(carrierId, powerUnitId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getPowerUnitShipmentsPromise(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdPowerUnitsPowerUnitIdShipmentsGet(
      carrierId,
      powerUnitId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}
//Clear powerUnit in redux state
export function clearPowerUnit() {
  return (dispatch) => {
    dispatch({type: CLEAR_POWER_UNIT});
  };
}

//---------- Trailers ----------//
//GET /carriers/{carrierId}/trailer/
export function getTrailers(body, opts) {
  return (dispatch) => {
    dispatch(requestData());
    return getTrailersPromise(body, opts)
      .then((response) => {
        dispatch({type: GET_TRAILERS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}
async function getTrailersPromise(body, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdTrailersGet(body, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//POST /carriers/{carrierId}/trailer/
export function postTrailer(carrierId, body) {
  return (dispatch) => {
    return postTrailerPromise(carrierId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function postTrailerPromise(carrierId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdTrailersPost(carrierId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//GET /carriers/{carrierId}/trailer/{trailerId}
export function getTrailer(carrierId, trailerId) {
  return (dispatch) => {
    return getTrailerPromise(carrierId, trailerId)
      .then((response) => {
        dispatch({type: GET_TRAILER, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getTrailerPromise(carrierId, trailerId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdTrailersTrailerIdGet(carrierId, trailerId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//PUT /carriers/{carrierId}/trailer/{trailerId}
export function putTrailer(carrierId, trailerId, body) {
  return (dispatch) => {
    return putTrailerPromise(carrierId, trailerId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function putTrailerPromise(carrierId, trailerId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdTrailersTrailerIdPut(carrierId, trailerId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//DELETE /carriers/{carrierId}/trailer/{trailerId}
export function deleteTrailer(carrierId, trailerId) {
  return (dispatch) => {
    return deleteTrailerPromise(carrierId, trailerId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function deleteTrailerPromise(carrierId, trailerId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdTrailersTrailerIdDelete(carrierId, trailerId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//Clear trailers in redux state
export function clearTrailer() {
  return (dispatch) => {
    dispatch({type: CLEAR_TRAILER});
  };
}

//---------- ELD ----------//
export function getPowerUnitLocations(carrierId, opts) {
  return (dispatch) => {
    dispatch(requestData());
    return getPowerUnitLocationsPromise(carrierId, opts)
      .then((response) => {
        dispatch({
          type: GET_POWER_UNIT_LOCATIONS,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getPowerUnitLocationsPromise(carrierId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdEldsPowerUnitLocationsGet(carrierId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getPowerUnitLocation(carrierId, powerUnitId) {
  return (dispatch) => {
    return getPowerUnitLocationPromise(carrierId, powerUnitId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getPowerUnitLocationPromise(carrierId, powerUnitId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdEldsPowerUnitLocationsPowerUnitIdGet(
      carrierId,
      powerUnitId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//---------- Drivers Stuff ----------//
export function postDriver(companyId, opts, pulling) {
  return (dispatch) => {
    return postDriverPromise(companyId, opts)
      .then((response) => {
        if (response.skipped_drivers && response.skipped_drivers.length > 0) {
          const skipped = [];
          for (let i = 0; i < response.skipped_drivers.length; i++) {
            skipped.push(response.skipped_drivers[i].first_name + ' ' + response.skipped_drivers[i].last_name);
          }
          dispatch({
            type: ALERT_ERROR,
            payload: 'Skipped: ' + skipped.join(', ')
          });
        }
        return {status: 200, details: response};
      })
      .catch((response) => {
        if (pulling) {
          dispatch({
            type: ALERT_ERROR,
            payload: response.error_description
          });
        }
        return response;
      });
  };
}
async function postDriverPromise(companyId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdDriversPost(companyId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getDrivers(companyId, opts) {
  return (dispatch) => {
    dispatch(requestData());
    return getDriversPromise(companyId, opts)
      .then((response) => {
        dispatch({
          type: GET_DRIVERS,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getDriversPromise(companyId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdDriversGet(companyId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getHOS(carrierId, driverId) {
  return (dispatch) => {
    return getHOSPromise(carrierId, driverId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getHOSPromise(carrierId, driverId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdEldsHosDriverIdGet(carrierId, driverId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getAllHOS(carrierId, opts = []) {
  return (dispatch) => {
    return getAllHOSPromise(carrierId, opts)
      .then((response) => {
        dispatch({type: FETCH_ALL_HOS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
async function getAllHOSPromise(carrierId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.carriersCarrierIdEldsHosGet(carrierId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//---------- Spinner Stuff ----------//
// starts the Loading... spinner
function requestData() {
  return {type: REQ_CARRIER_DATA};
}
