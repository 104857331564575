import {useCallback} from 'react';
import {WithRouterProps} from 'react-router';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {FieldArray, Form, Formik, FormikHelpers} from 'formik';
import {v4} from 'uuid';
import {AxiosError} from 'axios';
import {
  DeliveryTypeEnum,
  DockSchedulingEquipmentTypeEnum,
  DockSchedulingModeEnum,
  LoadType,
  PackagingTypes,
  ShipwellApiErrorResponse
} from '@shipwell/tempus-sdk';
import {SvgIcon, Card, DeprecatedButton} from '@shipwell/shipwell-ui';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import Error404Page from 'App/common/Error404Page';
import Loader from 'App/common/shipwellLoader';
import {
  useGetLoadTypes,
  formatLoadTypesPayload,
  useUpdateLoadTypes,
  useCreateLoadTypes,
  useDeleteLoadTypes,
  useFacilityQuery
} from 'App/data-hooks';
import {facilityLoadTypesValidationSchema} from 'App/containers/facilities/schema';
import FacilityLoadTypesFields from 'App/formComponents/formSections/facilityLoadTypesFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {LoadTypeDraft} from 'App/data-hooks/facilities/types';
import {parseV3ApiError} from 'App/api/typedUtils';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

type FacilityLoadTypesProps = Omit<WithRouterProps<{id: string}>, 'location' | 'router' | 'routes'> &
  WithStatusToastProps;
export type FormValuesType = {loadTypes: (LoadType | LoadTypeDraft)[]};

const NewLoadType = {
  name: '',
  load_unload_duration: '',
  appointment_duration: '',
  delivery_type: '' as DeliveryTypeEnum,
  mode: '' as DockSchedulingModeEnum,
  equipment_type: '' as DockSchedulingEquipmentTypeEnum,
  product_reference: '',
  packaging_type: '' as PackagingTypes,
  key: '',
  product_category: []
} as LoadTypeDraft;

const FacilityLoadTypesBase = ({params: {id: facilityId}, setError, setSuccess}: FacilityLoadTypesProps) => {
  const {fiDockScheduling} = useFlags();
  const {data: fetchedLoadTypes, isLoading: isLoadTypesLoading} = useGetLoadTypes(facilityId);
  const {facility, isFacilityLoading} = useFacilityQuery({
    facilityId
  });
  const onError = useCallback(
    (error: AxiosError<ShipwellApiErrorResponse>) => {
      const formattedError = parseV3ApiError(error);
      if (formattedError.title && formattedError.detail) {
        setError(formattedError.title, formattedError.detail);
      } else if (formattedError.isError) {
        setError('Load Types update failed', 'Unrecognized internal error.');
      }
    },
    [setError]
  );

  const onSuccess = useCallback(() => {
    setSuccess('Update successful!', `Successfully updated Load Types for facility ${facility?.name ?? ''}`);
  }, [setSuccess, facility]);
  const {mutateAsync: updateLTMutation} = useUpdateLoadTypes({
    onSuccess,
    onError
  });
  const {mutateAsync: createLTMutation} = useCreateLoadTypes({
    onSuccess,
    onError
  });
  const {mutateAsync: deleteLTMutation} = useDeleteLoadTypes({
    onSuccess,
    onError
  });

  const onLoadTypesSubmit = async (values: FormValuesType, {resetForm}: FormikHelpers<FormValuesType>) => {
    const {loadTypes} = values;
    resetForm({values: {loadTypes}});

    const {toBeUpdatedLoadTypes, toBeCreatedLoadTypes, toBeDeletedLoadTypes} = formatLoadTypesPayload({
      fetchedLoadTypes: fetchedLoadTypes,
      submittedLoadTypes: values?.loadTypes
    });
    if (toBeUpdatedLoadTypes) {
      const updateLTMutations = toBeUpdatedLoadTypes?.map((toBeUpdatedLoadType) =>
        updateLTMutation({
          loadTypeId: toBeUpdatedLoadType.id,
          facilityId: facilityId,
          updateLoadType: omitEmptyKeysWithEmptyObjectsRemoved(toBeUpdatedLoadType)
        })
      );
      await Promise.allSettled(updateLTMutations);
    }
    if (toBeCreatedLoadTypes) {
      const createLTMutations = toBeCreatedLoadTypes?.map((toBeCreatedLoadType) =>
        createLTMutation({
          facilityId: facilityId,
          body: omitEmptyKeysWithEmptyObjectsRemoved(toBeCreatedLoadType)
        })
      );
      await Promise.allSettled(createLTMutations);
    }
    if (toBeDeletedLoadTypes) {
      const deleteLTMutations = toBeDeletedLoadTypes?.map((toBeDeletedLoadType) =>
        deleteLTMutation({
          loadTypeId: toBeDeletedLoadType.id,
          facilityId: facilityId
        })
      );
      await Promise.allSettled(deleteLTMutations);
    }
  };
  if (!fiDockScheduling) {
    return <Error404Page />;
  }
  if (isLoadTypesLoading || isFacilityLoading) return <Loader loading />;
  return (
    <Formik
      initialValues={{loadTypes: fetchedLoadTypes || []} as FormValuesType}
      validationSchema={facilityLoadTypesValidationSchema}
      onSubmit={onLoadTypesSubmit}
    >
      {({isValid, values, dirty, resetForm}) => {
        return (
          <>
            <Form noValidate role="form">
              <FieldArray name="loadTypes">
                {({push, remove, replace}) => (
                  <>
                    <div className="grid h-full grid-flow-row">
                      <h2 className="sw-page-header border-b border-sw-border pb-3">{facility?.name}</h2>
                      <Card
                        title="Load Types"
                        draggableProvided={null}
                        actions={
                          <div className="flex min-h-4 min-w-max">
                            <DeprecatedButton
                              variant="tertiary"
                              icon={<SvgIcon name="AddCircleOutlined" />}
                              onClick={() => push({...NewLoadType, key: v4()})}
                            >
                              Add Load Type
                            </DeprecatedButton>
                          </div>
                        }
                      >
                        {values?.loadTypes.map((loadType, index) => (
                          <Card
                            title={`Load Type ${index + 1}`}
                            className="my-4"
                            key={'key' in loadType ? loadType.key : loadType.id}
                            draggableProvided={null}
                            actions={<SvgIcon name="TrashOutlined" onClick={() => remove(index)} />}
                          >
                            <FacilityLoadTypesFields
                              key={'key' in loadType ? loadType.key : loadType.id}
                              currentFormIndex={index}
                              replace={replace}
                              values={values}
                            />
                          </Card>
                        ))}
                      </Card>
                      <div className={dirty ? 'h-24' : 'h-4'}></div>
                      {dirty ? (
                        <FormFooter secondaryActionName="Reset" onCancel={resetForm} dirty={dirty && isValid} />
                      ) : null}
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const FacilityLoadTypes = WithStatusToasts(FacilityLoadTypesBase);
export default FacilityLoadTypes;
