import {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import {Link} from 'react-router';
import {DisplayValue, Pill, TwoColumnLayout, TwoColumnLayoutLeft, TwoColumnLayoutRight} from '@shipwell/shipwell-ui';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {hasInstantRateBooked, getCurrentCarrier} from 'App/containers/Shipment/components/CarrierEquipment/utils';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

const CarrierDetails = ({carrierConfig, shipment}) => {
  const [carrierRelationshipId, setCarrierRelationshipId] = useState(null);
  const status = get(carrierConfig, 'carrier_status', 'ACTIVE');
  const carrier = hasInstantRateBooked(shipment) ? getCurrentCarrier(shipment) : get(carrierConfig, 'vendor');
  const carrierName = get(carrier, 'name');
  const vendorPOCName = get(carrierConfig, 'vendor_point_of_contact.id')
    ? `${get(carrierConfig, 'vendor_point_of_contact.first_name')} ${
        get(carrierConfig, 'vendor_point_of_contact.last_name') || ''
      }`
    : '--';
  const vendorPOCEmail = get(carrierConfig, 'vendor_point_of_contact.email');
  const vendorPOCPhone = get(carrierConfig, 'vendor_point_of_contact.phone_number');
  const usDotNumber = get(carrier, 'identifying_codes', []).find((e) => e.type === 'USDOT');
  const mcNumber = get(carrier, 'identifying_codes', []).find((e) => e.type === 'MC_NUMBER');

  const getCarrierRelationshipId = useCallback(async (carrierId) => {
    try {
      const response = await getCarrierRelationshipsPromise({vendorId: carrierId});
      setCarrierRelationshipId(response?.body?.results?.[0]?.id || null);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (carrier?.id) {
      getCarrierRelationshipId(carrier.id);
    }
  }, [getCarrierRelationshipId, carrier]);

  return (
    <>
      <DisplayValue label="Name">
        <div className="flex flex-col">
          <div className="mb-0.5 flex items-center">
            {carrierRelationshipId ? <Link to={`/carriers/${carrierRelationshipId}`}>{carrierName}</Link> : carrierName}
          </div>
          <div className="flex">
            {status ? (
              <Pill variant={['INACTIVE', 'DO_NOT_USE'].includes(status) ? 'inactive' : 'primary'}>
                {upperCase(status)}
              </Pill>
            ) : null}
          </div>
        </div>
      </DisplayValue>
      <DisplayValue label="Contact">
        <div>{vendorPOCName}</div>
        <div>{vendorPOCEmail && <a href={`mailto:${vendorPOCEmail}`}>{vendorPOCEmail}</a>}</div>
        <div>{vendorPOCPhone && getPhoneHyperlink(vendorPOCPhone)}</div>
      </DisplayValue>
      <TwoColumnLayout leftColumnMaxWidth={'50%'}>
        <TwoColumnLayoutLeft>
          <DisplayValue label="US DOT #">{usDotNumber?.value || '--'}</DisplayValue>
        </TwoColumnLayoutLeft>
        <TwoColumnLayoutRight>
          <DisplayValue label="MC #">{mcNumber?.value || '--'}</DisplayValue>
        </TwoColumnLayoutRight>
      </TwoColumnLayout>
    </>
  );
};

CarrierDetails.propTypes = {
  carrierConfig: PropTypes.shape({
    vendor: PropTypes.object,
    vendor_point_of_contact: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string
    })
  }),
  shipment: PropTypes.object
};

export default CarrierDetails;
