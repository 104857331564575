import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {Company} from '@shipwell/backend-core-sdk';
import orderBy from 'lodash/orderBy';
import {useGetLTLCarriers} from 'App/data-hooks/brokers/useGetLTLCarriers';

const QuoteAndTenderFields = ({index, company}: {index: number; company: Company}) => {
  const {LTLCarriers, isLoadingLTLCarriers} = useGetLTLCarriers(company);
  //order the carriers by name
  const orderedCarriers = orderBy(
    LTLCarriers?.map((carrier) => ({
      label: carrier.company?.name,
      value: carrier.company?.id
    })),
    'label'
  );

  return (
    <div className="grid-item-1-12">
      <Field
        name={`actions[${index}].allowed_carrier_company_ids`}
        simpleValue
        isMulti
        required
        label="Carrier"
        component={FormikSelect}
        options={orderedCarriers}
        isLoading={isLoadingLTLCarriers}
      />
    </div>
  );
};

export default QuoteAndTenderFields;
