const validate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = 'Current password is required';
  }
  if (!values.newPassword) {
    errors.newPassword = 'New password is required';
  }
  if (values.newPassword) {
    let capital = false;
    let special = false;
    for (let i = 0; i < values.newPassword.length; i++) {
      if (/[A-Z]/.test(values.newPassword[i])) {
        capital = true;
      }
      if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@]/.test(values.newPassword[i])) {
        special = true;
      }
    }
    if (!capital) {
      errors.newPassword = 'Password requires at least one captial letter';
    } else if (!special) {
      errors.newPassword = 'Password requires at least one special character';
    } else if (values.newPassword.length < 8) {
      errors.newPassword = 'Password is too short';
    }
  }
  if (values.newPassword && values.newPassword === values.currentPassword) {
    errors.newPassword = 'New password can not be the same as the current password';
  }
  return errors;
};

export default validate;
