import {useState, useEffect} from 'react';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {getWorkflows} from 'App/api/workflows';
import usePrevious from 'App/utils/hooks/usePrevious';

/*
 * Fetch workflow details for the given ids and make them available in an object keyed by those ids.
 */
function useWorkflowDetails(ids) {
  const previousIds = usePrevious(ids);
  const [workflowDetails, setWorkflowDetails] = useState({});

  useEffect(() => {
    if (isEmpty(ids) || isEqual(ids, previousIds)) {
      return;
    }
    const fetchWorkflowDetails = async () => {
      try {
        const response = await getWorkflows({
          'id.in': ids.join(', ')
        });
        setWorkflowDetails(keyBy(response.data.data, 'id'));
      } catch (error) {
        console.error('Error retrieving workflow details.', error);
      }
    };
    fetchWorkflowDetails();
  }, [ids, previousIds]);

  return workflowDetails;
}

export default useWorkflowDetails;
