import {TagStatus} from '@shipwell/corrogo-sdk';
import {TagFormValues} from './tagFormValues';

export const defaultValues: TagFormValues = {
  name: '',
  description: '',
  status: TagStatus.Active,
  color: '#006BA2',
  customColor: ''
};
