import {SvgIcon} from '@shipwell/shipwell-ui';
import {getWarningContent} from 'App/containers/LoadBoard/utils';
import './styles.scss';

const TenderRequestWarning = ({errors, loadBoardId}) => {
  return (
    <div className="edi-warning">
      <div className="edi-warning__header" />
      <div className="edi-warning__content">
        <div className="edi-warning__content-icon">
          <SvgIcon name="ErrorFilled" color="$sw-warning" width="20" height="20" />
        </div>
        {getWarningContent(errors, loadBoardId)}
      </div>
    </div>
  );
};

export default TenderRequestWarning;
