// ex: [apple, pear, orange] => apple, pear, and orange
// items: arrayof strings or numbers
export const createGrammaticList = (items: (string | number)[]) => {
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  }
  const lastItem = items[items.length - 1];
  return `${items
    .slice(0, -1)
    .map((item) => `${item}, `)
    .join('')}and ${lastItem}`;
};

/**
 * Prefixes currency code to number and applies North American style commas
 * to between every 3 numbers
 * @param {number} number to be converted into text
 * @param {'USD'} currency code to prefix number with
 * @returns {string} a reformatted string as `USD 1,222.00`
 */
export const toCurrency = (val: number, currency = 'USD') =>
  // this regex adds commas in big numbers, like 'USD 1,234.00'
  `${currency} ${val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;

export const ellipsis = (str?: string, maxLength = 25) => str?.replace(new RegExp(`(.{${maxLength}}).+`), '$1…') || '';
