import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import {ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import {SHIPMENT_STOPS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getShipmentStops, Stop} from 'App/api/shipment/typed';

export type UseShipmentStopsQueryRequest = {
  shipmentId: string;
};

export type UseShipmentStopsQueryOptions = Omit<
  UseQueryOptions<Stop[], AxiosError<ShipwellError, unknown>, Stop[], string[]>,
  'queryKey' | 'queryFn'
>;

/**
 * React Query Hook for getting all stops by shipment id. Stops are returned in
 * ascending order by `ordinal_index.`
 */
const useShipmentStopsQuery = ({shipmentId}: UseShipmentStopsQueryRequest, options?: UseShipmentStopsQueryOptions) => {
  // chain enabled logic with consumer and our custom logic to ensure this only triggers
  // when there is a valid shipment id
  const isEnabled = Boolean(shipmentId) && (options?.enabled ?? true);

  const query = useQuery([SHIPMENT_STOPS_QUERY_KEY, shipmentId], () => getShipmentStops({shipmentId}), {
    ...options,
    enabled: isEnabled
  });

  return {
    isShipmentStopsLoading: query.isInitialLoading || query.isLoading || query.isFetching || query.isRefetching,
    error: query.error?.response?.data || null,
    isShipmentStopsError: query.isError,
    isShipmentStopSuccess: query.isSuccess,
    stops: query.data || [],
    refetchStops: query.refetch
  };
};

export type UseShipmentStopQueryRequest = {
  shipmentId?: string | null;
  stopId?: string | null;
};
export type UseShipmentStopQueryOptions = Omit<
  UseQueryOptions<Stop | null, AxiosError<ShipwellError, unknown>, Stop | null, (string | null | undefined)[]>,
  'queryKey' | 'queryFn'
>;

/**
 * Gets a single stop for a shipment either from cache or by calling
 * the all stops by shipment id endpoint and caching both the list response
 * and the single stop recorded.
 */
const useShipmentStopQuery = (
  {shipmentId, stopId}: UseShipmentStopQueryRequest,
  options?: UseShipmentStopQueryOptions
) => {
  const queryClient = useQueryClient();
  // chain our logic to enable this query, but then also ensure that we follow the consumers logic as well.
  const isEnabled = Boolean(shipmentId) && Boolean(stopId) && (options?.enabled ?? true);

  const query = useQuery(
    [SHIPMENT_STOPS_QUERY_KEY, shipmentId, stopId],
    async () => {
      if (isNil(shipmentId) || isNil(stopId)) {
        return null;
      }
      const allStopsCacheKey = [SHIPMENT_STOPS_QUERY_KEY, shipmentId];
      const stops = queryClient.getQueryData<Stop[]>(allStopsCacheKey);
      let stop = stops?.find((s) => s.id === stopId);
      if (!isNil(stop)) {
        return stop;
      }

      // need to go get ALL shipment stops because we need to know the stopNumber
      // which isn't guaranteed to be correct on the ordinal_index property.
      const shipmentStops = await getShipmentStops({shipmentId});
      queryClient.setQueryData(allStopsCacheKey, shipmentStops);
      stop = shipmentStops.find((s) => s.id === stopId);
      return stop ?? null;
    },
    {
      ...options,
      enabled: isEnabled
    }
  );

  return {
    isStopsInitialLoading: query.isInitialLoading,
    isStopsFetching: query.isFetching || query.isRefetching,
    isStopsError: query.isError || query.isRefetchError || query.isLoadingError,
    isStopsSuccess: query.isSuccess,
    stopError: query.error?.response?.data ?? null,
    stop: query.data ?? null,
    refetchStop: query.refetch
  };
};

export {useShipmentStopQuery, useShipmentStopsQuery};
