import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Image} from 'react-bootstrap';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import './_admin-login.scss';

@connect(
  (state) => ({
    brokerLogos: state.brokers.brokerLogos,
    outsideShipment: state.shipments.outsideShipment
  }),
  {...actions, ...brokerActions}
)
export default class AdminLogin extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const url = new URL(window.location);
    const token = 'Token ' + url.searchParams.get('token');
    if (!localStorage.getItem('swlogout')) {
      localStorage.setItem('swlogout', 'logout' + Math.random());
    }
    if (token && token !== 'Token ') {
      //set an admin override value to ensure no login on other tabs immediately
      setTimeout(() => {
        localStorage.setItem('swimpersonator', token);
        localStorage.removeItem('swlogout');
        this.context.router.push('/');
      }, 1000);
    } else {
      //redirect to the login page if there's no token
      this.context.router.push('/');
    }
  }

  render() {
    let inlineLogo = '';
    let whiteLogo = '';

    if (this.props.brokerLogos) {
      for (var i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'INLINE_COLOR') {
          inlineLogo = this.props.brokerLogos[i];
        } else if (this.props.brokerLogos[i].image_type === 'LOGO_WHITE') {
          whiteLogo = this.props.brokerLogos[i];
        }
      }
    }
    return (
      <div className="admin">
        <section className="container">
          <div className="admin__container">
            <Image className="admin__shipwell" src={inlineLogo.logo} alt="Logo" />
          </div>
          <h3 className="admin__container-login">Logging you in...</h3>
        </section>

        <footer className="text-center">
          <small>© 2016-{new Date().getFullYear()} Shipwell®, All Rights Reserved.</small>
        </footer>
      </div>
    );
  }
}
