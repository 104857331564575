import {useContext} from 'react';
import {Formik, Form} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import {Title} from '@shipwell/shipwell-ui';
import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import {facilityInfoValidationSchema} from 'App/containers/facilities/schema';
import {facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';
import {FacilityCreationForm} from 'App/data-hooks/facilities/types';
import FacilityDocuments from 'App/containers/facilities/forms/FacilityDocuments';
import FacilityCreationModalFooter from 'App/containers/facilities/components/FacilityCreationModalFooter';
import FacilityInfoFields from 'App/formComponents/formSections/facilityInfoFields';

const FacilityInfoForm = () => {
  const {setNextStep, facilityFormData, setFacilityFormData} = useContext(StepFormContext);
  const onFacilityInfoSubmit = (values: FacilityCreationForm['facilityInfo']) => {
    const clonedAddress = cloneDeep(values?.address);
    if (clonedAddress.address) {
      if (!clonedAddress.timezone) {
        clonedAddress.timezone = values?.manual_timezone;
      }
    } else {
      if (!clonedAddress.timezone) {
        clonedAddress.timezone = values?.manual_timezone;
      }
    }
    setNextStep();
    setFacilityFormData({...facilityFormData, facilityInfo: {...values, address: clonedAddress}});
  };

  return (
    <div className="my-10">
      <Formik
        validateOnMount
        initialValues={{...facilityFormDataDefaultValues.facilityInfo, ...facilityFormData.facilityInfo}}
        validationSchema={facilityInfoValidationSchema}
        onSubmit={onFacilityInfoSubmit}
      >
        {({isValid}) => (
          <>
            <Title className="mb-2">Facility Information</Title>
            <Form noValidate>
              <FacilityInfoFields />
              <FacilityDocuments />
              <FacilityCreationModalFooter isValid={isValid} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default FacilityInfoForm;
