import {Card, DisplayValue, Title} from '@shipwell/shipwell-ui';
import {useCarrierRelationships, useTransportationOrder} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';

const TenderViewCard = ({transportationOrderId, shipmentId}: {transportationOrderId: string; shipmentId: string}) => {
  const {transportationOrderDisplayValues, queryInfo: transportationOrderQuery} = useTransportationOrder(
    transportationOrderId,
    shipmentId
  );

  const carrierId = transportationOrderDisplayValues.carrierId;
  const {
    isLoading: carrierQueryLoading,
    context: {carrierDisplayValues}
  } = useCarrierRelationships(
    {
      id: [carrierId]
    },
    {enabled: !!carrierId}
  );

  if (transportationOrderQuery.isInitialLoading || carrierQueryLoading) {
    return <Loader loading />;
  }

  const carrier = carrierDisplayValues[0];

  return (
    <Card draggableProvided={null} title="Tender Details">
      <div className="grid gap-2">
        <DisplayValue className="border-b border-sw-border pb-4" label="Submitted">
          {transportationOrderDisplayValues.createdAt}
        </DisplayValue>
        <div>
          <Title variant="sectionTitle">Carrier</Title>
          <div className="grid grid-cols-2 gap-2">
            <div className="grid gap-2">
              <DisplayValue label="Name">{carrier?.name}</DisplayValue>
              <DisplayValue label="Phone Number">{carrier?.phone}</DisplayValue>
            </div>
            <DisplayValue label="Address">
              <div className="flex flex-col">
                <div>{carrier?.addressLineOne}</div>
                {carrier?.addressLineTwo ? <div>{carrier?.addressLineTwo}</div> : null}
                <div>{carrier?.cityStateZip}</div>
              </div>
            </DisplayValue>
            <DisplayValue label="MC #">{carrier?.mcNumber}</DisplayValue>
            <DisplayValue label="USDOT #">{carrier?.usdotNumber}</DisplayValue>
          </div>
        </div>
        <div>
          <Title variant="sectionTitle">Contacts</Title>
          {carrier?.contacts.map((contact) => (
            <div className="grid grid-cols-2 gap-2" key={contact.name}>
              <DisplayValue label="Name">
                <>
                  <div>{contact.name}</div>
                  <div>{contact.phone}</div>
                </>
              </DisplayValue>
              <DisplayValue label="Load Board Access">{contact.loadBoardAccess}</DisplayValue>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default TenderViewCard;
