import {
  DaysOfWeek,
  DeliveryTypeEnum,
  DockSchedulingAccessorialTypeEnum,
  DockSchedulingEquipmentTypeEnum,
  DockSchedulingModeEnum,
  FacilityAppointmentType,
  FacilityPointOfContactRole,
  FacilityServices,
  LocationTypes,
  PackagingTypes,
  StandardHolidays,
  TemperatureUnitEnum,
  ReferenceQualifierEnum
} from '@shipwell/tempus-sdk';

import {FacilityCreationForm, DockTypeDraft} from './types';

import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';
import {generateKey} from 'App/utils/generateKey';

export const OTHER = 'other' as StandardHolidays;

export const facilityCreationSteps = ['FACILITY INFO', 'CONTACTS', 'ATTRIBUTES', 'LOAD TYPES', 'DOCKS'];

export const StandardHolidaysList = [...Object.values(StandardHolidays), OTHER] as StandardHolidays[];

export const WEEKLY_DEFAULT_OPEN_TIME = '07:00:00';
export const WEEKLY_DEFAULT_CLOSE_TIME = '17:00:00';

export const facilityInfoLocationTypeOptions = enumToOptionConverter(LocationTypes);
export const appointmentTypeOptions = enumToOptionConverter(FacilityAppointmentType);
export const facilityServicesOptions = enumToOptionConverter(FacilityServices);
export const facilityContactRoleOptions = enumToOptionConverter(FacilityPointOfContactRole);
export const deliveryTypeOptions = enumToOptionConverter(DeliveryTypeEnum);
export const equipmentOptions = enumToOptionConverter(DockSchedulingEquipmentTypeEnum);
export const packagingOptions = enumToOptionConverter(PackagingTypes);
export const dockAccessorialOptions = enumToOptionConverter(DockSchedulingAccessorialTypeEnum);
export const referenceTypeOptions = enumToOptionConverter(ReferenceQualifierEnum);
export const modeOptions = [
  {label: DockSchedulingModeEnum.Ftl.toUpperCase(), value: DockSchedulingModeEnum.Ftl},
  {label: DockSchedulingModeEnum.Ltl.toUpperCase(), value: DockSchedulingModeEnum.Ltl},
  {label: DockSchedulingModeEnum.Vltl.toUpperCase(), value: DockSchedulingModeEnum.Vltl},
  {label: DockSchedulingModeEnum.Drayage.toUpperCase(), value: DockSchedulingModeEnum.Drayage},
  {label: DockSchedulingModeEnum.Parcel.toUpperCase(), value: DockSchedulingModeEnum.Parcel},
  {label: DockSchedulingModeEnum.Intermodal.toUpperCase(), value: DockSchedulingModeEnum.Intermodal}
];
export const timezoneOptions = [
  {label: 'America/New_York', value: 'America/New_York'},
  {label: 'America/Chicago', value: 'America/Chicago'},
  {label: 'America/Denver', value: 'America/Denver'},
  {label: 'America/Los_Angeles', value: 'America/Los_Angeles'},
  {label: 'America/Anchorage', value: 'America/Anchorage'},
  {label: 'Pacific/Honolulu', value: 'Pacific/Honolulu'},
  {label: 'America/Puerto_Rico', value: 'America/Puerto_Rico'}
];

export const stackableOptions = [
  {label: 'Yes', value: 'true'},
  {label: 'No', value: 'false'},
  {label: 'Blank', value: 'undefined'}
];

export const hazmatOptions = [
  {label: 'Yes', value: 'true'},
  {label: 'No', value: 'false'},
  {label: 'Blank', value: 'undefined'}
];

export const FACILITY_DOCUMENT_UPLOAD_LIMIT = 5;

export const facilityFormDataDefaultValues: FacilityCreationForm = {
  facilityInfo: {
    name: '',
    // because the facility info fields heavily depends on the AddressSearch component which uses
    // the address book entry we make this look very similar with some customization for ourselves
    // to make future state mapping easier.
    address: {
      location_name: '',
      external_reference: '',
      country: '',
      postal_code: '',
      address_1: '',
      address_2: '',
      state_province: '',
      city: '',
      address: {
        country: '',
        postal_code: '',
        address_1: '',
        address_2: '',
        state_province: '',
        city: ''
      }
    },
    facility_services: [] as FacilityServices[],
    location_type: '' as LocationTypes
  },
  facilityContacts: [
    {
      phone_number: '',
      email: '',
      facility_role: '' as FacilityPointOfContactRole,
      person_name: '',
      key: '',
      is_default_for_facility: true
    }
  ],
  facilityOperationCapacity: [
    {
      day: DaysOfWeek.Monday,
      open_time: WEEKLY_DEFAULT_OPEN_TIME,
      close_time: WEEKLY_DEFAULT_CLOSE_TIME,
      is_facility_open: true,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Tuesday,
      open_time: WEEKLY_DEFAULT_OPEN_TIME,
      close_time: WEEKLY_DEFAULT_CLOSE_TIME,
      is_facility_open: true,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Wednesday,
      open_time: WEEKLY_DEFAULT_OPEN_TIME,
      close_time: WEEKLY_DEFAULT_CLOSE_TIME,
      is_facility_open: true,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Thursday,
      open_time: WEEKLY_DEFAULT_OPEN_TIME,
      close_time: WEEKLY_DEFAULT_CLOSE_TIME,
      is_facility_open: true,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Friday,
      open_time: WEEKLY_DEFAULT_OPEN_TIME,
      close_time: WEEKLY_DEFAULT_CLOSE_TIME,
      is_facility_open: true,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Saturday,
      open_time: undefined,
      close_time: undefined,
      is_facility_open: false,
      key: generateKey()
    },
    {
      day: DaysOfWeek.Sunday,
      open_time: undefined,
      close_time: undefined,
      is_facility_open: false,
      key: generateKey()
    }
  ],
  appointmentRules: {
    exclude_dock_assignment_from_appointment_scheduling: false,
    appointment_lead_time_duration: '',
    late_appointment_threshold_duration: '',
    first_appointment_start_time: '',
    last_appointment_end_time: ''
  },
  loadTypes: [
    {
      name: '',
      all_day_appointment: false,
      stackable: undefined,
      is_hazmat: undefined,
      load_unload_duration: '',
      appointment_duration: '',
      delivery_type: '' as DeliveryTypeEnum,
      mode: '' as DockSchedulingModeEnum,
      equipment_type: '' as DockSchedulingEquipmentTypeEnum,
      product_reference: '',
      packaging_type: '' as PackagingTypes,
      product_category: []
    }
  ],
  docks: [
    {
      key: generateKey(),
      name: '',
      color: '',
      dockRules: [
        {
          appointment_type: '' as FacilityAppointmentType,
          load_type_id: '',
          delivery_type: '' as DeliveryTypeEnum,
          mode: '' as DockSchedulingModeEnum,
          equipment_type: '' as DockSchedulingEquipmentTypeEnum,
          product_reference: '',
          packaging_type: '' as PackagingTypes,
          product_category: [],
          dock_accessorials: [] as DockSchedulingAccessorialTypeEnum[],
          first_appointment_start_time: '',
          last_appointment_end_time: '',
          temperature: {
            unit: TemperatureUnitEnum.F,
            minimum: '',
            maximum: ''
          },
          is_public: true,
          key: generateKey()
        }
      ]
    }
  ] as DockTypeDraft[]
};
