import {useState} from 'react';
import {CreateProductLegItem, ProductLegItem, UpdateProductLegItem} from '@shipwell/corrogo-sdk';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import useToggle from 'App/utils/hooks/useToggle';
import LineItemsModal from 'App/containers/shipments/drayage/components/ProductLineItems/LineItemsModal';
import LineItemsView from 'App/containers/shipments/drayage/components/ProductLineItems/LineItemsView';
import {ModalState} from 'App/containers/shipments/drayage/components/ProductLineItems/utils';

const LineItemsContainer = ({
  legItems,
  onSubmit,
  onDelete
}: {
  legItems: ProductLegItem[];
  onSubmit: (values: CreateProductLegItem[] | UpdateProductLegItem[]) => void;
  onDelete: (index: number) => void;
}) => {
  const [lineItemsModalOpen, toggleLineItemsModalOpen] = useToggle();
  const [modalState, setModalState] = useState<ModalState>({mode: 'create', index: null});

  return (
    <>
      {legItems.length === 0 ? (
        <DeprecatedButton
          variant="tertiary"
          onClick={() => {
            setModalState({mode: 'create', index: null});
            toggleLineItemsModalOpen();
          }}
        >
          <SvgIcon name="AddCircleOutlined" />
          <span>Add Line Items</span>
        </DeprecatedButton>
      ) : null}
      {legItems.length > 0 ? (
        <LineItemsView
          legItems={legItems}
          onClickLineItem={(indexOfLineItemToEdit?: number) => {
            setModalState(
              indexOfLineItemToEdit ? {mode: 'edit', index: indexOfLineItemToEdit} : {mode: 'create', index: null}
            );
            toggleLineItemsModalOpen();
          }}
          onDelete={onDelete}
        />
      ) : null}
      <LineItemsModal
        legItems={legItems}
        modalState={modalState}
        showModal={lineItemsModalOpen}
        onClose={toggleLineItemsModalOpen}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default LineItemsContainer;
