import {ShipwellError} from '@shipwell/backend-core-sdk';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {registrationUPSUpsAccountIdDelete} from 'App/api/registration/typed';
import {COMPANY_INTEGRATION_UPS} from 'App/data-hooks/queryKeys';

export const useDeleteUpsAccountMutation = (
  mutationOptions: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUPSUpsAccountIdDelete>>,
    AxiosError<ShipwellError>,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(async (upsAccountId: string) => await registrationUPSUpsAccountIdDelete(upsAccountId), {
    onSettled: async () => await queryClient.invalidateQueries([COMPANY_INTEGRATION_UPS]),
    ...mutationOptions
  });
};
