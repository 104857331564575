import {MouseEventHandler} from 'react';
import {Button, Popover} from '@shipwell/shipwell-ui';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Uuid4} from 'id128';
import {useShipmentStagesQuery} from '../../hooks/useShipmentStagesQuery';
import {
  getUpdatedStagesList,
  hasDirectlyDependsOnStages,
  resourceStageIsLegacyShipmentStage,
  StageDependencies
} from '../../utils';
import {GENERIC_UPDATE_ERROR_MESSAGE} from '../../constants';
import {useRelatedStages} from '../../StagesView';
import {updateShipmentStageDependencies, deleteServiceStage} from 'App/api/corrogo/typed';
import {useUserPermissions, withUserPermissionsFallback, useLegacyShipment} from 'App/data-hooks';
import {getStageByResourceId} from 'App/containers/shipments/utils/typed';
import {SHIPMENTS_STAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {deleteLegacyShipmentStage} from 'App/api/shipment/typed';

export type ActionMenuItemProps = {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
};

function AddStageMenuItemBase({onClick}: ActionMenuItemProps) {
  return <Popover.MenuListItemButton onClick={onClick}>Add</Popover.MenuListItemButton>;
}

export const AddStageMenuItem = withUserPermissionsFallback<ActionMenuItemProps>(['shipments.create_shipments'])(
  AddStageMenuItemBase
);

export function EditStageMenuItem({onClick}: ActionMenuItemProps) {
  return <Popover.MenuListItemButton onClick={onClick}>Edit</Popover.MenuListItemButton>;
}

export function CloneStageMenuItem({
  onClick,
  shipmentId,
  resourceId
}: ActionMenuItemProps & {shipmentId?: string; resourceId: string}) {
  const {stmDrayageStageCloningWorkflow} = useFlags();
  const hasCreateShipmentPermission = useUserPermissions(['shipments.create_shipments']);
  const {data: stages} = useShipmentStagesQuery(shipmentId || '');
  const resourceStage = getStageByResourceId(resourceId, stages);
  // A canonical uuid represents a legacy shipment
  const isLegacyShipmentId = Uuid4.isCanonical(resourceId);
  const {
    shipmentQuery: {data: legacyShipment}
  } = useLegacyShipment(resourceId, {enabled: isLegacyShipmentId});
  const isLegacyDrayageShipment = legacyShipment?.mode?.code === 'DRAYAGE';

  if (!stmDrayageStageCloningWorkflow || !hasCreateShipmentPermission) {
    return null;
  }
  // If the resource is a legacy drayage shipment without a v3 shipment id
  // (i.e., if no v3 shipment has been created) and without stages,
  // allow the user to clone the drayage shipment.
  if (!stages && isLegacyDrayageShipment) {
    return <Popover.MenuListItemButton onClick={onClick}>Clone</Popover.MenuListItemButton>;
  }
  if (!resourceStage || !isLegacyDrayageShipment || hasDirectlyDependsOnStages(resourceStage)) {
    return null;
  }
  return <Popover.MenuListItemButton onClick={onClick}>Clone</Popover.MenuListItemButton>;
}

interface DeleteStageMenuItemProps {
  resourceId: string;
  shipmentId?: string;
  handleError: (title: string, message: string) => void;
  onDeleteCompleted: () => void;
}

export function DeleteStageMenuItem({
  onDeleteCompleted,
  resourceId,
  shipmentId,
  handleError
}: DeleteStageMenuItemProps) {
  const queryClient = useQueryClient();
  const relatedStages = useRelatedStages(shipmentId, resourceId);
  const shipmentStagesQuery = useShipmentStagesQuery(shipmentId || '');
  const resourceStage = getStageByResourceId(resourceId, shipmentStagesQuery.data);
  const isLegacyShipmentStage = resourceStageIsLegacyShipmentStage(resourceStage);
  const {mutate: deleteServiceStageMutate, isLoading: isLoadingDeleteServiceMutation} = useMutation(
    () => deleteServiceStage(shipmentId || '', resourceStage?.id || ''),
    {
      onSettled: () => queryClient.invalidateQueries([SHIPMENTS_STAGES_QUERY_KEY, shipmentId]),
      onError: () => handleError('Error!', GENERIC_UPDATE_ERROR_MESSAGE)
    }
  );
  const {mutate: deleteLegacyShipmentStageMutate, isLoading: isLoadingDeleteLegacyShipmentMutation} = useMutation(
    () =>
      deleteLegacyShipmentStage({
        v2_shipment_id: isLegacyShipmentStage ? resourceStage.legacy_shipment_id : '',
        v3_shipment_id: isLegacyShipmentStage ? resourceStage.shipment_id : ''
      }),
    {
      onSettled: () => queryClient.invalidateQueries([SHIPMENTS_STAGES_QUERY_KEY, shipmentId]),
      onError: () => handleError('Error!', GENERIC_UPDATE_ERROR_MESSAGE)
    }
  );
  const {mutateAsync: updateShipmentStageDependenciesMutate, isLoading: isLoadingUpdateShipmentStageDependencies} =
    useMutation(
      (newStageDependencies: StageDependencies[]) =>
        updateShipmentStageDependencies(shipmentId || '', {stages: newStageDependencies}),
      {
        onError: () => handleError('Error!', GENERIC_UPDATE_ERROR_MESSAGE)
      }
    );
  const handleDeleteStage = async () => {
    const updatedStagesList = getUpdatedStagesList(
      relatedStages,
      resourceStage?.id || '',
      shipmentStagesQuery.data || []
    );
    await updateShipmentStageDependenciesMutate(updatedStagesList);
    onDeleteCompleted();
    if (isLegacyShipmentStage) {
      return deleteLegacyShipmentStageMutate();
    }
    return deleteServiceStageMutate();
  };

  const isLoadingDeleteMutation =
    isLoadingUpdateShipmentStageDependencies || isLoadingDeleteLegacyShipmentMutation || isLoadingDeleteServiceMutation;

  const isDisabled = relatedStages.length < 2;

  return (
    <Popover
      placement="left"
      theme="dark"
      portal={false} // see https://github.com/shipwell/shipwell-ui/blob/38a0aff0f2f56c793cc3d3ac447682c2b6d84c27/src/core/popover/Popover.tsx#L36-L42
      showArrow
      trigger={({
        isOpen,
        setIsOpen,
        setTriggerElement
      }: {
        isOpen: boolean;
        setIsOpen: (isOpen: boolean) => void;
        setTriggerElement: (triggerElement: HTMLElement | null) => void;
      }) => (
        // Yes, you're looking at a menu item button being used as the trigger for a popover, because this is basically
        // triggering a sub-menu (popover within a popover).
        <Popover.MenuListItemButton
          aria-haspopup
          aria-expanded={isOpen}
          ref={setTriggerElement}
          disabled={isDisabled}
          isDestructive
          isLoading={isLoadingDeleteMutation}
          onClick={() => setIsOpen(!isOpen)}
        >
          Delete
        </Popover.MenuListItemButton>
      )}
    >
      {({isOpen, setIsOpen}: {isOpen: boolean; setIsOpen: (isOpen: boolean) => void}) => (
        <div className="w-60 p-2">
          <div className="font-bold">Delete Stage</div>
          <div>Are you sure you want to delete this stage? This will cancel and remove the shipment from Shipwell.</div>
          <div className="mt-2 flex justify-end gap-2">
            <Button size="sm" onClick={() => setIsOpen(!isOpen)}>
              Cancel
            </Button>
            <Button size="sm" color="warning" onClick={() => void handleDeleteStage()}>
              Delete
            </Button>
          </div>
        </div>
      )}
    </Popover>
  );
}
