import {validateEmail, validatePhoneNumber} from 'App/utils/globals';

/**
 * Validation for `Point of Contact` form
 * @param  {Object} values Field values from form
 * @return {Object}        Errors
 */
export const validation = (contact) => {
  const errors = {};

  if (!contact.first_name) {
    errors.first_name = 'First name required';
  }

  if (!contact.email) {
    errors.email = 'Enter an email';
  } else if (!validateEmail(contact.email)) {
    errors.email = 'Invalid email';
  }

  if (!contact.phone_number) {
    errors.phone_number = 'Phone number required';
  } else if (!validatePhoneNumber(contact.phone_number)) {
    errors.phone_number = 'Invalid phone number';
  }

  return errors;
};
