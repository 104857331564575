import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import {compose} from 'recompose';
import {Formik, Field} from 'formik';
import {object, string} from 'yup';
import {DeprecatedButton, SvgIcon, FormikTextInput} from '@shipwell/shipwell-ui';
import {fetchTags} from 'App/actions/_shipments';
import {postTagPromise, editTagPromise} from 'App/api/shipment';
import {tagColors} from 'App/utils/globals';
import withStatusToasts from 'App/components/withStatusToasts';

const tagCreatorValidationSchema = object().shape({
  name: string().nullable().required('A tag name is required.'),
  color: string().nullable().required('A tag color is required.')
});

const ColorSelect = ({field, form: {setFieldValue, setFieldTouched}}) => {
  const handleColorSwatchClick = (colorName) => () => {
    setFieldTouched(field.name, true);
    setFieldValue(field.name, colorName);
  };

  return (
    <>
      <input type="hidden" {...field} />
      <div className="tags__tagList-creator-colorswatchcontainer">
        {tagColors.map((tagColor) => (
          <div
            key={tagColor.name}
            className={classnames('tags__tagList-creator-colorswatchwrapper', {
              'tags__tagList-creator-colorswatchwrapper-selected': tagColor.name === field.value
            })}
          >
            <div
              className={classnames('tags__tagList-creator-colorswatch', 'tag', 'tag-option', tagColor.name)}
              onClick={handleColorSwatchClick(tagColor.name)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

const TagEdit = ({dispatch, tag, onCancel, onSubmit, setError}) => {
  const handleSubmit = async (values, {setSubmitting}) => {
    const tagToUpdate = {...tag, ...values};
    try {
      await (tag.id ? editTagPromise(tag.id, tagToUpdate) : postTagPromise(tagToUpdate));
      onSubmit();
      return dispatch(fetchTags());
    } catch (error) {
      console.error(`Error creating tag`, error);
      setError('Error creating tag!', get(error, 'error_description', 'There was an error creating the tag'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="tags__tagList-creator">
      <Formik onSubmit={handleSubmit} validationSchema={tagCreatorValidationSchema} initialValues={tag}>
        {({isSubmitting, dirty, handleSubmit}) => (
          <div>
            <Field
              component={FormikTextInput}
              className="tags__tagList-creator-name"
              label="Tag name"
              name="name"
              autoFocus
              required
            />
            <Field component={ColorSelect} name="color" />
            <div className="tags__tagList-creator-buttons">
              <DeprecatedButton variant="tertiary" disabled={isSubmitting} onClick={onCancel}>
                <SvgIcon name="ArrowBack" />
                Back
              </DeprecatedButton>
              <DeprecatedButton onClick={handleSubmit} disabled={isSubmitting || !dirty}>
                {isSubmitting ? (
                  <>
                    <SvgIcon name="Refresh" className="rotate" /> Saving...
                  </>
                ) : (
                  'Save'
                )}
              </DeprecatedButton>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

TagEdit.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

TagEdit.defaultProps = {
  tag: {name: '', color: ''},
  onCancel: () => {},
  onSubmit: () => {}
};

export default compose(withStatusToasts, connect())(TagEdit);
