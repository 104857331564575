import {ReactNode, Ref, forwardRef} from 'react';
import {SvgIcon} from '@shipwell/shipwell-ui';

interface ReadbackTagProps {
  label: ReactNode;
  isHidden?: boolean;
  onClick: () => void;
}

export const ReadbackTag = forwardRef<HTMLButtonElement, ReadbackTagProps>(
  ({label, onClick, isHidden = false}, ref: Ref<HTMLButtonElement>) => {
    return (
      <button
        aria-hidden={isHidden}
        ref={ref}
        onClick={onClick}
        disabled={isHidden}
        className="flex items-center gap-2 whitespace-nowrap rounded border-none bg-sw-label p-1 pl-2 text-xs font-bold text-sw-text-reverse hover:bg-sw-selected"
        style={{opacity: isHidden ? 0 : 1}}
      >
        {label}
        <SvgIcon name="Close" width="16" height="16" />
      </button>
    );
  }
);

ReadbackTag.displayName = 'ReadbackTag';
