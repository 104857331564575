import {Component} from 'react';
import {AsyncTypeahead, tokenContainer} from 'react-bootstrap-typeahead';
import Select from 'react-select';
import {stateProvinceOptions, radius_options} from './constants';
import {formatPlacesAddressComponents} from 'App/components/TableFiltersWithSavedViews/FilterComponents/LocationLookup/utils';
import {loadGoogleMapsAPI} from 'App/utils/globals';
import {TokenDisplay} from 'App/components/TableFiltersWithSavedViews/FilterComponents/TokenDisplay';

class LocationLookup extends Component {
  constructor(props) {
    super(props);
    this.validateZip = this.validateZip.bind(this);

    this.state = {selected: [], value: '', isLoading: false};
  }

  componentDidMount() {
    /** Need bound GMaps object */
    if (window.google) {
      this.initGoogleMaps();
    } else {
      loadGoogleMapsAPI(() => this.initGoogleMaps());
    }
    //there may be default selections we need to populate the state initially
    if (this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValues !== this.props.defaultValues && this.props.defaultValues === null) {
      //we received a 'clear' signal from the parent
      this.setState({selected: []});
    } else if (prevProps.defaultValues !== this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
  }

  parseDefaultValues(defaultValues) {
    let selectedRadius = null;
    const selections = defaultValues.map((value) => {
      if (value.type && value.type === 'state_province') {
        //parse states
        const matchingState = stateProvinceOptions.filter((e) => e.value.formatted_address === value.label)[0];
        return matchingState;
        //parse a radius search
      }
      if (value.latlon || value.type === 'radius') {
        selectedRadius = parseInt(value.radius[0]);
        return {
          value: {formatted_address: value.label},
          latlon: value.latlon,
          label: value.label,
          type: 'radius'
        };
      }
      //any other specific search, selected radius should be 0
      selectedRadius = 0;
      return {value: {formatted_address: value}, label: value};
    });
    this.setState({selected: selections, selectedRadius: selectedRadius});
  }

  initGoogleMaps() {
    this.autocomplete = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
    this.geocoder = new google.maps.Geocoder();
  }

  /**
   * Request place predictions from Google Mapa
   * @param  {String} value Place search input
   * @return {Promise}
   */
  getPlacePredictions(value, types = ['(cities)']) {
    //hardcoded this to cities only for now
    return new Promise((resolve, reject) => {
      //restricting to US, Canada, and Mexico
      this.autocomplete.getPlacePredictions(
        {input: value, types: types, componentRestrictions: {country: ['us', 'ca', 'mx']}},
        (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  onInputValueChange({target}) {
    this.setState({
      value: target.value
    });
  }

  updateParent(selections) {
    const arrayToUpdate = selections.map((e) => {
      if (e.type && e.type === 'state_province') {
        return {label: e.value.formatted_address, type: 'state_province'};
      }
      if (((e.type && e.type === 'radius') || this.state.selectedRadius) && selections.length === 1) {
        return {
          label: e.label,
          latlon: e.latlon,
          type: 'radius',
          radius: [this.state.selectedRadius]
        };
      }
      return e.label;
    });
    if (selections.length > 1) {
      //reset the radius to 0
      this.setState({selectedRadius: 0});
    }
    const filterObj = {};
    filterObj[this.props.fieldName] = arrayToUpdate;
    this.props.updateActiveFilters(filterObj);
  }

  /*
   * Perform a search for the input value
   */
  async searchAddresses(value) {
    if (value) {
      this.setState({isLoading: true});
      const options = [];
      this.getPlacePredictions(value)
        .then((response) => {
          if (response.length) {
            for (let i = 0; i < response.length; i++) {
              //get the results and add them to the list of options
              options.push({
                value: response[i],
                label: response[i].description
              });
            }
          }
          //merge in any state options that match here
          const matchingStates = stateProvinceOptions.filter((state) =>
            state.label.toLowerCase().includes(value.toLowerCase())
          );
          if (matchingStates.length) {
            matchingStates.reverse().forEach((match) => {
              options.unshift(match);
            });
          }

          this.setState({options, isLoading: false});
        })
        .catch((response) => {
          this.setState({isLoading: false, options});
        });
    }
  }

  /**
   * Get formatted address is selected address is from Googles predicted places
   * @param  {Object} place.value Selected place
   */
  async handlePlaceSelection(place) {
    if (place && place.value && place.value.place_id) {
      const placeDetails = await new Promise((resolve, reject) => {
        this.placeService.getDetails({placeId: place.value.place_id}, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      const formattedAddress = formatPlacesAddressComponents(placeDetails?.address_components);
      return {
        label: formattedAddress || placeDetails.formatted_address,
        value: placeDetails
      };
    }
    if (place && place.customOption) {
      //this is a zip code, save the value as the formatted_address field to match the others
      //first geocode this so that we can get a latlon
      try {
        const placeDetails = await this.handleGeocode(place);
        let topResult = null;
        if (placeDetails.filter((e) => e.types.includes('postal_code')).length) {
          topResult = placeDetails.filter((e) => e.types.includes('postal_code'))[0];
        }
        if (topResult) {
          return {value: {formatted_address: place.label, geometry: topResult.geometry}, label: place.label};
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async handleGeocode(place) {
    return await new Promise((resolve, reject) => {
      this.geocoder.geocode({address: place.label}, function (results, status) {
        if (status === 'OK' && results.length > 0) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }

  /*
   * Validate that a given input is a zip code in the US or Canada
   */
  validateZip(results, props) {
    const input = props.text;
    if (
      !this.state.isLoading &&
      results.length === 0 &&
      input.match(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/)
      //regex validating US, mexico, and canada postal codes
    ) {
      return true;
    }

    return false;
  }

  handleRadiusChange(e) {
    this.setState({selectedRadius: e.value}, async () => {
      const {selected} = this.state;
      if (selected && selected.length && !selected[0].latlon) {
        //first we need to geocode this selection since there is no latlon
        try {
          const placeDetails = await this.handleGeocode(selected[0]);
          const topResult = placeDetails[0];

          if (topResult) {
            const latlon = {lat: topResult.geometry.location.lat(), lon: topResult.geometry.location.lng()};
            this.updateParent([
              {value: {formatted_address: selected[0].label}, latlon: latlon, label: selected[0].label}
            ]);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        this.updateParent(selected);
      }
    });
  }

  render() {
    const {selected, isLoading, selectedRadius} = this.state;
    return (
      <div className="tableFilters__locationLookup">
        <AsyncTypeahead
          id={`${this.props.fieldName}__lookup`}
          multiple
          autoFocus
          allowNew={this.validateZip.bind(this)}
          isLoading={isLoading}
          selected={selected ? selected : []}
          placeholder="Enter City, State, or Zip"
          useCache={false}
          filterBy={(value) => value}
          onSearch={this.searchAddresses.bind(this)}
          options={this.state.options}
          newSelectionPrefix="Add Zip: "
          renderToken={(option, props, index) => <TokenDisplay key={index} onRemove={props.onRemove} option={option} />}
          onChange={(selection) => {
            if (selection.length > this.state.selected.length) {
              //any new city selections need to go through google maps validation first before saving
              const needsValidation = selection.filter(
                (place) => !place.value || (place.value && !place.value.formatted_address)
              )[0];
              if (needsValidation) {
                this.handlePlaceSelection(needsValidation)
                  .then((newValue) => {
                    newValue.latlon = {
                      lat: newValue.value.geometry.location.lat(),
                      lon: newValue.value.geometry.location.lng()
                    };
                    const currentList = this.state.selected;
                    if (currentList.length === 0) {
                      //when this is the first selection, we want to search by radius immediately
                      newValue.type = 'radius';
                    }
                    currentList.push(newValue);
                    this.setState({selected: currentList});
                    if (currentList.length === 1) {
                      //set the radius and update from parent there
                      this.handleRadiusChange({value: 100});
                    } else {
                      this.updateParent(currentList);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                //primarily for state selections, put it directly into the selected array
                this.setState({selected: selection});
                this.updateParent(selection);
              }
            } else {
              this.setState({selected: selection});
              this.updateParent(selection);
            }
          }}
        />
        {selected.length === 1 &&
          (!selected[0].type || (selected[0].type && selected[0].type !== 'state_province')) && (
            <div className="tableFilters__locationLookup-radius" id={this.props.fieldName + '_radius'}>
              <Select
                clearable={false}
                value={this.state.selectedRadius}
                valueKey="value"
                labelKey="label"
                name={this.props.fieldName + '_radius'}
                options={radius_options}
                onChange={(value) => this.handleRadiusChange(value)}
              />
            </div>
          )}
      </div>
    );
  }
}

export default LocationLookup;
