import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Modal, Toast} from '@shipwell/shipwell-ui';
import {LoadboardType} from '@shipwell/tabula-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useGetUberFreightQuery} from '../details/components/uberFreightDetails/hooks/mutations';
import {useGetAmazonFreightQuery} from '../../amazon/hooks/useAmazonFreightMutation';
import checkIfResponseIsOk from '../details/components/netSuiteDetails/utils/checkIfResponseIsOk';
import {VisibilityCardContainer} from './components/visabilityCardContainer/visabilityCardContainer';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';
import {authenticateQuickBooks, authenticateTriumphPay} from 'App/containers/integrations/actions/async';
import {checkTriumphPayAuth, getFinancialManagementSystems} from 'App/actions/_integrations';
import {
  disconnectQuickBooks,
  checkRMISAuthentication,
  checkSaferwatchAuthentication,
  checkFedexAuthentication
} from 'App/api/integrations';
import {deleteNetSuiteConfig} from 'App/api/integrations/typed';
import CompanyIntegrationCard from 'App/containers/integrations/list/components/companyIntegrationCard';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Error404Page from 'App/common/Error404Page2';
import withFlags from 'App/utils/withFlags';
import useToggle from 'App/utils/hooks/useToggle';
import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import {FlexBox} from 'App/components/Box';
import useCreateLoadboard from 'App/api/loadboards/hooks/useCreateLoadboard';
import WithStatusToasts from 'App/components/withStatusToasts';
import {useGetSmc3Query} from 'App/containers/smc3/hooks/hooks';
import {useGetSuiteAppConfig} from 'App/formComponents/forms/suiteAppConfiguration/hooks/index';

const FinancialCardList = (props) => {
  const {
    dispatch,
    router,
    company,
    connectedFms,
    hasManageIntegrationsPermission,
    hasTriumphPayEnabledFeatureFlag,
    hasNetsuiteEnabledFeatureFlag,
    hasRemoveIntegrationsPermission,
    onLoading,
    onLoaded
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isTriumphPayAuthenticated, setIsTriumphPayAuthenticated] = useState(false);
  const [isTriumphPayAuthenticating, setIsTriumphPayAuthenticating] = useState(false);
  const [isQuickBooksAuthenticating, setIsQuickBooksAuthenticating] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [showDisconnectQuickBooks, setShowDisconnectQuickBooks] = useState(false);
  const [isDisconnectingQuickBooks, setIsDisconnectingQuickBooks] = useState(false);
  const [showDisconnectNetSuite, setShowDisconnectNetSuite] = useState(false);
  const [isDisconnectingNetSuite, setIsDisconnectingNetSuite] = useState(false);
  const {intNetsuiteSuiteApp} = useFlags();

  const {suiteAppConfig} = useGetSuiteAppConfig({
    enabled: !!intNetsuiteSuiteApp
  });

  const handleDisconnectQuickBooks = async () => {
    setIsDisconnectingQuickBooks(true);
    try {
      const response = await disconnectQuickBooks();
      if (response?.ok) {
        router.push(`/company/integrations/netsuite/configure`);
      }
    } catch (error) {
      console.error(error);
    }
    setIsDisconnectingQuickBooks(false);
  };

  const handleDisconnectNetSuite = async () => {
    setIsDisconnectingNetSuite(true);
    try {
      const response = await deleteNetSuiteConfig();
      if (checkIfResponseIsOk(response.status)) {
        await dispatch(getFinancialManagementSystems());
        setShowDisconnectNetSuite(false);
      }
    } catch (error) {
      console.error(error);
    }
    setIsDisconnectingNetSuite(false);
  };

  const handleAuthenticateQuickBooks = async () => {
    if (connectedFms.includes('NETSUITE')) {
      setShowDisconnectNetSuite(true);
    } else {
      setIsQuickBooksAuthenticating(true);
      try {
        const response = await dispatch(authenticateQuickBooks({redirectUrl: window.location}));
        if (response?.body?.redirect_url) {
          window.location = response.body.redirect_url;
        }
      } catch (error) {
        setConnectionError(error?.error_description);
      }
      setIsQuickBooksAuthenticating(false);
    }
  };

  const handleAuthenticateTriumphPay = async () => {
    setIsTriumphPayAuthenticating(true);
    try {
      const response = await dispatch(authenticateTriumphPay({redirectUrl: window.location}));
      if (response?.body?.redirect_url) {
        window.location = response.body.redirect_url;
      }
    } catch (error) {
      setConnectionError(error?.error_description);
    }
    setIsTriumphPayAuthenticating(false);
  };

  const handleAuthenticateNetSuite = () => {
    if (connectedFms.includes('QUICKBOOKS')) {
      setShowDisconnectQuickBooks(true);
    } else {
      router.push(`/company/integrations/netsuite/configure`);
    }
  };

  const getTriumphPayStatus = async () => {
    try {
      const response = await dispatch(checkTriumphPayAuth());
      if (response?.body?.is_authenticated) {
        setIsTriumphPayAuthenticated(true);
      }
    } catch (error) {
      console.error(error?.error_description);
    }
  };

  const fetchFinancialManagementSystems = async () => {
    try {
      await dispatch(getFinancialManagementSystems());
    } catch (error) {
      console.error(error);
    }
  };

  const initializeComponent = async () => {
    if (!company.id) {
      return; // nothing to do
    }
    setIsLoading(true);
    onLoading && onLoading();

    try {
      await fetchFinancialManagementSystems();
      if (hasTriumphPayEnabledFeatureFlag) {
        await getTriumphPayStatus();
      }
    } catch (err) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onLoaded && onLoaded();
    }
  };

  useEffect(() => {
    initializeComponent();
  }, [company, dispatch, isTriumphPayAuthenticated]);

  return (
    <>
      <CompanyIntegrationCard
        integration="QUICKBOOKS"
        isAuthenticating={isLoading || isQuickBooksAuthenticating}
        isAuthenticated={connectedFms.includes('QUICKBOOKS')}
        handleAuthenticate={handleAuthenticateQuickBooks}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard
      />
      <CompanyIntegrationCard
        integration="TRIUMPHPAY"
        isAuthenticating={isLoading || isTriumphPayAuthenticating}
        isAuthenticated={isTriumphPayAuthenticated}
        handleAuthenticate={handleAuthenticateTriumphPay}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasTriumphPayEnabledFeatureFlag}
      />
      <CompanyIntegrationCard
        integration="NETSUITE"
        isAuthenticating={isLoading}
        isAuthenticated={connectedFms.includes('NETSUITE')}
        isSuiteAppAuthenticated={!!(suiteAppConfig?.certificate_id && intNetsuiteSuiteApp)}
        handleAuthenticate={handleAuthenticateNetSuite}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasNetsuiteEnabledFeatureFlag}
      />
      <Toast
        show={Boolean(connectionError)}
        title="Unable to connect!"
        variant="error"
        anchor="bottom-right"
        onClose={() => setConnectionError(false)}
      >
        {connectionError}
      </Toast>
      <Modal
        show={Boolean(showDisconnectQuickBooks)}
        title="Warning"
        variant="warning"
        onClose={() => setShowDisconnectQuickBooks(false)}
        primaryBtnName="Continue"
        onPrimaryAction={hasRemoveIntegrationsPermission && handleDisconnectQuickBooks}
        primaryButtonProps={{loading: isDisconnectingQuickBooks}}
      >
        If you would like to connect to NetSuite, QuickBooks will disconnect and the mapping details will be lost.
      </Modal>
      <Modal
        show={Boolean(showDisconnectNetSuite)}
        title="Warning"
        variant="warning"
        onClose={() => setShowDisconnectNetSuite(false)}
        primaryBtnName="Continue"
        onPrimaryAction={hasRemoveIntegrationsPermission && handleDisconnectNetSuite}
        primaryButtonProps={{loading: isDisconnectingNetSuite}}
      >
        If you would like to connect to QuickBooks, NetSuite will disconnect and the mapping details will be lost.
      </Modal>
    </>
  );
};

const FinancialCardListContainer = compose(
  connect((state) => ({
    company: state.auth.company,
    connectedFms: state.integrations.connectedFms,
    hasManageIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.manage_integrations'),
    hasRemoveIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.remove_integrations'),
    hasTriumphPayEnabledFeatureFlag: state.auth?.company?.feature_flags?.triumph_pay_enabled,
    hasNetsuiteEnabledFeatureFlag: state.auth?.company?.feature_flags?.netsuite_enabled
  }))
)(FinancialCardList);

const CarrierCardList = (props) => {
  const {
    router,
    company,
    hasManageIntegrationsPermission,
    hasUpsEnabledFeatureFlag,
    connDigitalFreightMarketplace,
    publicLoadboardPost,
    intSmc3,
    hasFedexEnabledFeatureFlag,
    onLoading,
    onLoaded,
    setSuccess,
    setError
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isFedexAuthenticated, setIsFedexAuthenticated] = useState(false);
  const [isUberFreightAuthenticated, setIsUberFreightAuthenticated] = useToggle();
  const [isAmazonFreightAuthenticated, setIsAmazonFreightAuthenticated] = useToggle();
  const [isSmc3Authenticated, setIsSmc3Authenticated] = useToggle();
  const [isDatAuthenticating, setIsDatAuthenticating] = useState(false);
  const [isTruckstopAuthenticating, setIsTruckstopAuthenticating] = useState(false);

  useGetUberFreightQuery({
    onSuccess: (data) => {
      setIsUberFreightAuthenticated(Boolean(data));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {isUpsAuthenticated} = useGetUpsAccountsQuery();

  useGetAmazonFreightQuery({
    onSuccess: (data) => {
      setIsAmazonFreightAuthenticated(Boolean(data));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useGetSmc3Query({
    cacheTime: 0,
    onSuccess: (data) => {
      setIsSmc3Authenticated(Boolean(data));
    }
  });

  useGetActiveLoadboards({
    onSuccess: (loadboards) => {
      const dat = loadboards?.find((item) => item.loadboard_type === LoadboardType.Dat);
      const truckstop = loadboards?.find((item) => item.loadboard_type === LoadboardType.Truckstop);
      setIsDatAuthenticating(Boolean(dat?.is_active));
      setIsTruckstopAuthenticating(Boolean(truckstop?.is_active));
    }
  });

  const {mutate: authTruckstop, isLoading: isTruckstopLoading} = useCreateLoadboard({
    onSuccess: () => {
      setSuccess('Success!', 'You are now connected to Truckstop.');
      router.push('/company/integrations/truckstop');
    },
    onError: () => {
      setError('Error!', 'Something went wrong, please try again.');
    }
  });

  const onHandleTruckstopAuthenticate = () => {
    if (isTruckstopAuthenticating) {
      return router.push('/company/integrations/truckstop');
    }

    return authTruckstop({
      loadboardType: LoadboardType.Truckstop,
      createAuthRequest: {
        credentials: {
          key: 'key',
          secret: 'secret'
        },
        is_user: false
      }
    });
  };

  const getFedexStatus = async () => {
    try {
      const response = await checkFedexAuthentication();
      if (response?.body?.is_authenticated) {
        setIsFedexAuthenticated(true);
      }
    } catch (error) {
      console.error(error?.error_description);
    }
  };

  const initializeComponent = async () => {
    setIsLoading(true);
    onLoading && onLoading();

    await getFedexStatus();

    setIsLoading(false);
    onLoaded && onLoaded();
  };

  useEffect(() => {
    if (company.id) {
      initializeComponent();
    }
  }, [company.id, isFedexAuthenticated]);

  return (
    <>
      <CompanyIntegrationCard
        integration="ups"
        isAuthenticating={isLoading}
        isAuthenticated={isUpsAuthenticated}
        handleAuthenticate={() => router.push(`/ups`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasUpsEnabledFeatureFlag}
      />

      <CompanyIntegrationCard
        integration="fedex"
        isAuthenticating={isLoading}
        isAuthenticated={isFedexAuthenticated}
        handleAuthenticate={() => router.push(`/fedex`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasFedexEnabledFeatureFlag}
      />
      <CompanyIntegrationCard
        integration="uber"
        isAuthenticating={isLoading}
        isAuthenticated={isUberFreightAuthenticated}
        handleAuthenticate={() => router.push(`/uber`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={connDigitalFreightMarketplace}
      />
      <CompanyIntegrationCard
        integration="amazon"
        isAuthenticated={isAmazonFreightAuthenticated}
        handleAuthenticate={() => router.push(`/amazon`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={connDigitalFreightMarketplace}
      />
      <CompanyIntegrationCard
        integration={LoadboardType.Dat}
        isAuthenticating={isLoading}
        isAuthenticated={isDatAuthenticating}
        handleAuthenticate={() => router.push(`/company/integrations/dat/configure`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={publicLoadboardPost}
      />
      <CompanyIntegrationCard
        integration="smc3"
        isAuthenticating={isLoading}
        isAuthenticated={isSmc3Authenticated}
        handleAuthenticate={() => router.push(`/company/integrations/smc3/configure`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={intSmc3}
      />
      <CompanyIntegrationCard
        integration={LoadboardType.Truckstop}
        isAuthenticating={isTruckstopLoading}
        isAuthenticated={isTruckstopAuthenticating}
        handleAuthenticate={onHandleTruckstopAuthenticate}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={publicLoadboardPost}
      />
    </>
  );
};

const CarrierCardListContainer = compose(
  connect((state) => ({
    company: state.auth.company,
    hasIntegrationsEnabledFeatureFlag: state.auth?.company?.feature_flags?.integrations_enabled,
    hasManageIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.manage_integrations'),
    hasFedexEnabledFeatureFlag: state.auth?.company?.feature_flags?.fedex_enabled,
    hasUpsEnabledFeatureFlag: state.userCompany?.company?.feature_flags?.ups_enabled
  })),
  withFlags('connDigitalFreightMarketplace', 'publicLoadboardPost', 'intSmc3'),
  WithStatusToasts
)(CarrierCardList);

const ComplianceCardList = (props) => {
  const {
    router,
    company,
    hasManageIntegrationsPermission,
    hasSaferwatchEnabledFeatureFlag,
    hasRMISEnabledFeatureFlag,
    onLoading,
    onLoaded
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isRMISAuthenticated, setIsRMISAuthencticated] = useState(false);
  const [isSaferwatchAuthenticated, setisSaferwatchAuthenticated] = useState(false);

  const getRMISStatus = async () => {
    try {
      const response = await checkRMISAuthentication();
      if (response?.body?.is_authenticated) {
        setIsRMISAuthencticated(true);
      }
    } catch (error) {
      console.error(error?.error_description);
    }
  };

  const getSaferwatchStatus = async () => {
    try {
      const response = await checkSaferwatchAuthentication();
      if (response?.body?.is_authenticated) {
        setisSaferwatchAuthenticated(true);
      }
    } catch (error) {
      console.error(error?.error_description);
    }
  };

  const getStatus = async () => {
    setIsLoading(true);
    onLoading && onLoading();
    try {
      await getRMISStatus();
      await getSaferwatchStatus();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onLoaded && onLoaded();
    }
  };

  useEffect(() => {
    if (company?.id) {
      getStatus();
    }
  }, [company?.id]);

  return (
    <>
      <CompanyIntegrationCard
        integration="saferwatch"
        isAuthenticating={isLoading}
        isAuthenticated={isSaferwatchAuthenticated}
        handleAuthenticate={() => router.push(`/company/integrations/saferwatch`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasSaferwatchEnabledFeatureFlag}
      />
      <CompanyIntegrationCard
        integration="RMIS"
        isAuthenticating={isLoading}
        isAuthenticated={isRMISAuthenticated}
        handleAuthenticate={() => router.push(`/company/integrations/rmis`)}
        disabled={!hasManageIntegrationsPermission}
        router={router}
        canShowCard={hasRMISEnabledFeatureFlag}
      />
    </>
  );
};

const ComplianceCardListContainer = compose(
  connect((state) => ({
    company: state.auth.company,
    hasManageIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.manage_integrations'),
    hasRMISEnabledFeatureFlag: state.auth?.company?.feature_flags?.rmis_enabled,
    hasSaferwatchEnabledFeatureFlag: state.auth?.company?.feature_flags?.saferwatch_enabled
  }))
)(ComplianceCardList);

/**
 * Company Users Container
 * @param {*} props
 */
const CompanyIntegrationsContainer = ({
  router,
  hasViewIntegrationsPermission,
  hasIntegrationsEnabledFeatureFlag,
  categories
}) => {
  /** State hooks */

  const [loading, setLoading] = useState({
    financial: false,
    compliance: false,
    carrier: false
  });

  const onCategoryLoadingChange = (category) => {
    setLoading({
      ...loading,
      [category]: true
    });
  };

  const onCategoryLoadedChange = (category) => {
    setLoading({
      ...loading,
      [category]: false
    });
  };

  if (Object.keys(loading).every((key) => loading[key])) {
    return <ShipwellLoader loading />;
  }

  return (
    <>
      {hasViewIntegrationsPermission && hasIntegrationsEnabledFeatureFlag ? (
        <FlexBox wrap="wrap" justify="center">
          {categories.includes('financial') && (
            <FinancialCardListContainer
              router={router}
              onLoading={() => onCategoryLoadingChange('financial')}
              onLoaded={() => onCategoryLoadedChange('financial')}
            />
          )}
          {categories.includes('compliance') && (
            <ComplianceCardListContainer
              router={router}
              onLoading={() => onCategoryLoadingChange('compliance')}
              onLoaded={() => onCategoryLoadedChange('compliance')}
            />
          )}
          {categories.includes('carrier') && (
            <CarrierCardListContainer
              router={router}
              onLoading={() => onCategoryLoadingChange('carrier')}
              onLoaded={() => onCategoryLoadedChange('carrier')}
            />
          )}
          {categories.includes('visibility') && <VisibilityCardContainer router={router} />}
        </FlexBox>
      ) : (
        Error404Page
      )}
    </>
  );
};

CompanyIntegrationsContainer.defaultProps = {
  categories: ['financial', 'carrier', 'compliance', 'visibility']
};

export default compose(
  connect((state) => ({
    company: state.auth.company,
    hasIntegrationsEnabledFeatureFlag: state.auth?.company?.feature_flags?.integrations_enabled,
    hasViewIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.view_integrations'),
    hasManageIntegrationsPermission:
      state.userProfile.user.permissions &&
      state.userProfile.user.permissions.includes('integrations.manage_integrations')
  })),
  withConditionalFallback(
    ({hasIntegrationsEnabledFeatureFlag, hasViewIntegrationsPermission}) =>
      !hasIntegrationsEnabledFeatureFlag || !hasViewIntegrationsPermission
  )
)(CompanyIntegrationsContainer);
