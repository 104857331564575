import {
  CarrierCapacityApi,
  CarrierCapacityApiCarrierCapacityCarrierCapacityAvailableGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {configuration} from './configuration';

const carrierCapacityApi = new CarrierCapacityApi(configuration);

export const getCarrierCapacityAvailability = (
  params: CarrierCapacityApiCarrierCapacityCarrierCapacityAvailableGetRequest
) => carrierCapacityApi.carrierCapacityCarrierCapacityAvailableGet(params);
