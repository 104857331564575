import {PieceType} from '@shipwell/backend-core-sdk';
import {useQuery} from '@tanstack/react-query';
import {getPieceTypes} from 'App/api/shipment/typed';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {PIECE_TYPES_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetPieceTypes = () =>
  useQuery([PIECE_TYPES_QUERY_KEY], async () => (await getPieceTypes()).data, {
    staleTime: STATIC_DATA_STALE_TIME
  });

export const getPieceTypeReactSelectOptions = (pieceTypes: PieceType[]): {id: string; label: string}[] =>
  pieceTypes.map((pieceType) => ({id: pieceType.id, label: pieceType.description}));
