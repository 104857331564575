import {Button, Card, CollapsibleCardContent, TextInput, FormInspector} from '@shipwell/shipwell-ui';
import {CardTitle} from './cartTitle/cardTitle';
import {useMacropointDetailsForm} from './useMacropointDetailsForm/useTiveDetailsForm';
import {useMacropointService} from './useMacropointService/useMacropointService';
import {isLocalhost} from 'App/utils/globals';
import ShipwellLoader from 'App/common/shipwellLoader';

export function ConnectionCard() {
  const service = useMacropointService();

  const form = useMacropointDetailsForm({
    initialValues: service.data,
    onSubmit: (values) => {
      service.connect(values);
    }
  });

  return (
    <Card title={<CardTitle />} draggableProvided={null}>
      <CollapsibleCardContent>
        {service.isFetching && <ShipwellLoader loading />}

        {!service.isFetching && (
          <form onSubmit={form.handleSubmit} autoComplete="something-off">
            <fieldset disabled={false}>
              <div className="grid grid-cols-2 gap-3">
                <div className="col-span-2">
                  To enable the Descartes Macropoint integration, you will need to input your API key bellow.
                </div>

                <div className="col-span-1">
                  <TextInput label={'Partner Id*'} {...form.getBasicProps('partnerId')} />
                </div>

                <div className="col-span-1">
                  <TextInput label={'Password*'} type="password" {...form.getBasicProps('password')} />
                </div>

                <div className="col-span-2 flex flex-col items-end text-right ">
                  <Button isLoading={service.isSaving} type="submit">
                    Validate
                  </Button>
                </div>
              </div>
            </fieldset>
          </form>
        )}

        <FormInspector show={isLocalhost()} values={form.values} errors={form.errors} />
      </CollapsibleCardContent>
    </Card>
  );
}
