import {Field} from 'formik';
import {FormikTextInput, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {ShipmentMode} from '@shipwell/backend-core-sdk';
import {
  parcelReferences,
  drayageReferences,
  ltlReferences,
  vltlReferences,
  ftlReferences,
  intermodalReferences,
  railReferences,
  airReferences
} from 'App/containers/Shipment/components/References/utils/constants';
import {checkShipmentModes} from 'App/utils/globalsTyped';

const EditReferenceFields = ({
  mode,
  isQuotingLimitedUser = false
}: {
  mode?: ShipmentMode;
  isQuotingLimitedUser?: boolean;
}) => {
  const {hasParcel, hasDrayage, hasLTL, hasVLTL, hasFTL, hasIntermodal, hasRail, hasAir} = checkShipmentModes(mode);
  const fields = hasParcel
    ? parcelReferences
    : hasDrayage
    ? drayageReferences
    : hasLTL
    ? ltlReferences
    : hasVLTL
    ? vltlReferences
    : hasFTL
    ? ftlReferences
    : hasIntermodal
    ? intermodalReferences
    : hasRail
    ? railReferences
    : hasAir
    ? airReferences
    : [];

  return (
    <div className="field-grid">
      {fields.map(({label, fieldName, readonly, tooltip}) => {
        if (tooltip) {
          return (
            <div key={fieldName} className="grid grid-cols-[1fr_32px]">
              <Field
                label={label}
                name={fieldName}
                component={FormikTextInput}
                type="text"
                placeholder={label}
                normalize={(value: string) => value || null}
                disabled={isQuotingLimitedUser || readonly}
              />
              <Tooltip
                wrapperClassname="ml-2 flex items-center content-center"
                tooltipClassname="w-[237px]"
                placement="top"
                tooltipContent={
                  <div>
                    <b>{label}</b>
                    <p>{tooltip}</p>
                  </div>
                }
                trigger="hover"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </div>
          );
        }

        return (
          <Field
            key={fieldName}
            label={label}
            name={fieldName}
            component={FormikTextInput}
            type="text"
            placeholder={label}
            normalize={(value: string) => value || null}
            disabled={isQuotingLimitedUser || readonly}
          />
        );
      })}
    </div>
  );
};

export default EditReferenceFields;
