import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import get from 'lodash/get';
import {DeprecatedButton, Tooltip, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import ShipmentCancelForm from 'App/containers/Shipment/forms/ShipmentCancelForm';
import {cancelShipment, deleteScheduledCalls} from 'App/actions/shipmentdetails';
import withStatusToasts from 'App/components/withStatusToasts';
import './styles.scss';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const CancelAction = ({
  shipment,
  canEdit,
  onUpdateShipment,
  cancelShipment,
  isQuotingLimitedUser,
  hasFutureScheduledCalls,
  deleteScheduledCalls,
  setError
}) => {
  const [changingCancelStatus, setChangingCancelStatus] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [deletingScheduledCalls, setDeletingScheduledCalls] = useState(false);
  const [showDeleteCallsModal, setShowDeleteCallsModal] = useState(false);

  const isCancelled = get(shipment, 'state', '') === 'cancelled';
  const {cancellation_reason} = shipment;

  const handleCancelShipment = async (values) => {
    setChangingCancelStatus(true);

    try {
      const response = await cancelShipment(shipment.id, {
        cancellation_reason: values?.cancellation_reason
      });
      setShowCancelModal(false);
      if (hasFutureScheduledCalls) {
        setShowDeleteCallsModal(true);
      }
      onUpdateShipment(response);
    } catch (e) {
      const message = 'Error cancelling shipment.';
      console.error(message, e);
      setError('Error!', message);
    } finally {
      setChangingCancelStatus(false);
    }
  };

  const handleDeleteScheduledCalls = async () => {
    try {
      const response = await deleteScheduledCalls(shipment.id);
      setShowDeleteCallsModal(false);
      return response;
    } catch (e) {
      console.error(`Error deleting scheduled calls for ${shipment.id}.`);
    } finally {
      setDeletingScheduledCalls(false);
    }
  };

  if (isQuotingLimitedUser) {
    return null;
  }
  return (
    <>
      <DeprecatedButton
        onClick={() => !isCancelled && setShowCancelModal(true)}
        disabled={changingCancelStatus || !canEdit}
        variant="text"
      >
        {isCancelled ? (
          <>
            Cancelled
            <Tooltip
              tooltipContent={cancellation_reason}
              trigger="hover"
              placement="top"
              wrapperClassname="shipment__action-content-tooltip"
            >
              <SvgIcon name="InfoOutlined" width="16" height="16" />
            </Tooltip>
          </>
        ) : (
          'Cancel Shipment'
        )}
      </DeprecatedButton>

      <Modal
        show={showCancelModal}
        title="Cancel Shipment"
        onClose={() => setShowCancelModal(false)}
        footerComponent={null}
      >
        <ShipmentCancelForm onSubmit={handleCancelShipment} onCancel={() => setShowCancelModal(false)} />
      </Modal>

      <Modal
        show={showDeleteCallsModal}
        title="Delete Scheduled Calls"
        onClose={() => setShowDeleteCallsModal(false)}
        footerComponent={null}
      >
        <>
          <div className="confirmational-modal-paragraph">
            This shipment has automated calls scheduled which may now be out of date due to the changes you made.
          </div>
          <div className="confirmational-modal-paragraph">Do you want to delete the scheduled calls?</div>
          <div className="page-footer">
            <DeprecatedButton variant="secondary" onClick={() => setShowDeleteCallsModal(false)}>
              No, don&apos;t delete scheduled calls
            </DeprecatedButton>
            <DeprecatedButton
              disabled={deletingScheduledCalls}
              icon={deletingScheduledCalls && <i className="icon icon-Restart rotate" />}
              onClick={handleDeleteScheduledCalls}
            >
              Yes, delete scheduled calls
            </DeprecatedButton>
          </div>
        </>
      </Modal>
    </>
  );
};

CancelAction.propTypes = {
  shipment: PropTypes.object,
  canEdit: PropTypes.bool,
  isQuotingLimitedUser: PropTypes.bool,
  onUpdateShipment: PropTypes.func,
  cancelShipment: PropTypes.func,
  hasFutureScheduledCalls: PropTypes.bool,
  deleteScheduledCalls: PropTypes.func
};

CancelAction.defaultProps = {
  shipment: {},
  canEdit: false,
  isQuotingLimitedUser: false,
  onUpdateShipment: () => {},
  cancelShipment: () => {},
  hasFutureScheduledCalls: false,
  deleteScheduledCalls: () => {}
};

export default compose(
  withStatusToasts,
  connect(
    (state) => ({
      canEdit:
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION),
      isQuotingLimitedUser: get(state, 'userProfile.user.is_quoting_limited_user'),
      hasFutureScheduledCalls: get(state, 'shipmentdetails.hasFutureScheduledCalls')
    }),
    (dispatch) =>
      bindActionCreators(
        {
          cancelShipment,
          deleteScheduledCalls
        },
        dispatch
      )
  )
)(CancelAction);
