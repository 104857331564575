import {ShippingDashboardStopLocation} from '@shipwell/backend-core-singlerequestparam-sdk';

export const StopAddressCell = ({stopLocation}: {stopLocation?: ShippingDashboardStopLocation}) => {
  const renderPostalCode = () => {
    return [stopLocation?.address?.city, stopLocation?.address?.state_province, stopLocation?.address?.postal_code]
      .filter(Boolean)
      .join(', ');
  };

  if (stopLocation?.address?.city && stopLocation?.address?.state_province) {
    return <>{renderPostalCode()}</>;
  }
  if (stopLocation?.address?.latitude && stopLocation?.address?.longitude) {
    return (
      <>
        {stopLocation?.address?.latitude.toFixed(2)}, {stopLocation?.address?.longitude.toFixed(2)}
      </>
    );
  }
  return <>--</>;
};
