import {Select} from '@shipwell/shipwell-ui';

export const CustomDataFilter = ({
  defaultValues,
  onChange
}: {
  defaultValues: string[];
  onChange: (vals: string[]) => void;
}) => {
  return (
    <Select
      value={defaultValues.map((val) => ({
        value: val,
        label: val
      }))}
      onChange={(options: {value: string; option: string}[]) => {
        onChange(options.map((option) => option.value));
      }}
      isMulti
      allowCreate
      label="Search Custom Data"
    />
  );
};
