import {ApiClient, DefaultApi} from '@shipwell/backend-sdk-javascript';
import {getAccessToken} from 'App/api/utils';
import {decimalVersionHeader} from 'App/api/consts';

/**
 * Gest API Client to handle resource management between the client and server.
 * @returns {Promise<import('@shipwell/backend-sdk-javascript').DefaultApi>}
 */
export const getShipwellApi = async (headers) => {
  const defaultClient = new ApiClient();
  defaultClient.basePath = process.env.SHIPWELL_API_BASE_PATH;
  if (headers) {
    defaultClient.defaultHeaders = {...defaultClient.defaultHeaders, ...decimalVersionHeader};
  }
  const auth = defaultClient.authentications['AuthToken'];
  auth.apiKey = await getAccessToken('default-api');
  return new DefaultApi(defaultClient);
};

export const basePath = process.env.SHIPWELL_API_BASE_PATH;

export const basePathWithOutV2 = process.env.SHIPWELL_GENESIS_API_BASE_PATH;

// this is currently only used to satisfy outdated (mainly skipped) tests.
// this should NOT be referenced for new/future API calls. use getShipwellApi
export const shipwellApi = new DefaultApi();
