import {useEffect, useState} from 'react';
import {DeprecatedButton, Card} from '@shipwell/shipwell-ui';
import {LoadboardType} from '@shipwell/tabula-sdk';
import {withRouter, WithRouterProps} from 'react-router';
import {useQueryClient} from '@tanstack/react-query';

import DisconnectIntegrationModal from 'App/containers/integrations/details/components/truckstopDetails/components/DisconnectIntegrationModal';
import CompanyUsersTable, {
  UserDataProps
} from 'App/containers/integrations/details/components/truckstopDetails/components/CompanyUsersTable';
import useToggle from 'App/utils/hooks/useToggle';
import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import useCreateLoadboard from 'App/api/loadboards/hooks/useCreateLoadboard';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {getErrorDetail} from 'App/containers/Shipment/components/LoadBoards/utils';
import {LOADBOARDS_KEY} from 'App/data-hooks/queryKeys';

const TruckstopDetails = ({
  router,
  location,
  setSuccess,
  setError
}: WithRouterProps<unknown, {code?: string}> & WithStatusToastProps) => {
  const queryClient = useQueryClient();
  const [showDisconnectModal, toggleShowDisconnectModal] = useToggle(false);
  const [isActive, setIsActive] = useState(false);
  const {data: loadboards} = useGetActiveLoadboards();
  const truckstop = loadboards?.find((item) => item.loadboard_type === LoadboardType.Truckstop);
  const code = location?.query?.code;
  const userDataStringify = sessionStorage.getItem('truckstop-user-data');

  const {mutate: activeUser, isLoading: isActiveUserLoading} = useCreateLoadboard({
    onSuccess: () => {
      if (userDataStringify) {
        const userData = JSON.parse(userDataStringify) as UserDataProps;
        setSuccess(
          'Success!',
          `You have activated ${userData.first_name} ${userData.last_name} as a user for Truckstop.`
        );
      } else {
        setSuccess('Success!', `You have activated user for Truckstop.`);
      }
    },
    onError: (error) => {
      setError('Error!', getErrorDetail(error, 'Something went wrong, please try again.'));
    },
    onSettled: () => {
      void queryClient.invalidateQueries([LOADBOARDS_KEY]);
      router.replace(location.pathname);
    }
  });

  useEffect(() => {
    setIsActive(Boolean(truckstop?.is_active));
  }, [truckstop]);

  useEffect(() => {
    if (code && userDataStringify) {
      const userData = JSON.parse(userDataStringify) as UserDataProps;

      activeUser({
        loadboardType: 'truckstop',
        createAuthRequest: {
          credentials: {
            key: code
          },
          is_user: true,
          user_id: userData.id
        }
      });
    }
  }, [activeUser, code, userDataStringify]);

  return (
    <div className="integration-details-content">
      {isActive ? (
        <div className="disconnect">
          <DeprecatedButton variant="secondary" onClick={toggleShowDisconnectModal}>
            <span className="integration-container__rmis-disconnect-btn-text px-7">Disconnect</span>
          </DeprecatedButton>
        </div>
      ) : null}
      <div className="flex flex-col gap-4">
        <Card
          title={
            <div className="integration-details-card-title">
              <div className="flex grow items-center gap-1">
                <img alt="integration-logo" className="integration-logo" src="/images/truckstop_logo.svg" />
                <span>Truckstop Load Board</span>
              </div>
            </div>
          }
          draggableProvided={'false'}
        />
        <CompanyUsersTable
          truckstopUsers={truckstop?.users || []}
          isConnected={isActive}
          isActiveUserLoading={isActiveUserLoading}
        />
        <DisconnectIntegrationModal
          show={showDisconnectModal}
          toggleShow={toggleShowDisconnectModal}
          setIsActive={setIsActive}
        />
      </div>
    </div>
  );
};

export default withRouter(WithStatusToasts(TruckstopDetails));
