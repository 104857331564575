/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {compose} from 'recompose';
import {withRouter, WithRouterProps} from 'react-router';
import {Card, SvgIcon, Button} from '@shipwell/shipwell-ui';
import PageHeader from 'App/common/pageHeader';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {useUserMe} from 'App/data-hooks';
import {useAmazonFreightMutation} from 'App/containers/amazon/hooks/useAmazonFreightMutation';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

interface AmazonPropsTypes extends WithStatusToastProps, WithRouterProps<unknown, unknown> {}

interface QueryType {
  state: string;
}

/**
 * Amazon Freight Registration Container
 */
const AmazonFreightRegistration = (props: AmazonPropsTypes) => {
  const userMeQuery = useUserMe();
  const createStatus = 'created';
  const query = props?.location?.query as QueryType;
  const companyId = userMeQuery.data?.company?.id;

  const handleConnect = () => {
    const amazonFreightUrl = process.env.AMAZON_FREIGHT_URL;
    const clientId = process.env.CLIENT_ID_AMAZON_FREIGHT;
    const env = process.env.AMAZON_ENV;
    if (companyId && clientId && amazonFreightUrl && env) {
      let redirectUrl = `https://${env}-api.shipwell.com/hooks/amazon-freight/auth`;
      if (env === 'prod') {
        redirectUrl = 'https://api.shipwell.com/hooks/amazon-freight/auth';
      }
      const url = `${amazonFreightUrl}/integrations/${clientId}/authorize?state=${companyId}&redirect_uri=${redirectUrl}`;
      window.location.replace(url);
    }
  };

  const {mutate: createCapacityProviders} = useAmazonFreightMutation({
    onSuccess() {
      props.router.push('/carriers?page=1&q=amazon&amazonFreightRegistrationSuccess');
    }
  });

  useEffect(() => {
    if (query?.state === createStatus && companyId) {
      createCapacityProviders(companyId);
    }
  }, []);

  return (
    <div className="min-h-screen bg-sw-background">
      <PageHeader title="Amazon Freight Registration" backArrow />

      <div className="m-auto my-0 w-full max-w-5xl px-5 pb-24 pt-5">
        <Card title="Amazon Freight" img="/images/amazon_freight_logo.png" draggableProvided={'false'}>
          <div className="px-5 py-2.5">
            {query?.state === createStatus
              ? 'You are connected with Amazon Freight.'
              : 'To activate Amazon Freight in Shipwell platform, please use Login with Amazon below.'}
            <br />
            <br />
            {query?.state === createStatus ? (
              <div>
                <div className="flex w-96">
                  <span className="left">
                    <SvgIcon name="CheckCircleFilled" color="$sw-success" />
                  </span>
                  <span className="float-left mt-1 text-sw-success">Connected</span>
                </div>
                <br />
                <Button variant="primary" onClick={() => props.router.push('/carriers/search')}>
                  Go Marketplace
                </Button>
              </div>
            ) : (
              <input title="btnSubmit" type="image" src="/images/btn_amazon.png" onClick={handleConnect} />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default compose<AmazonPropsTypes, unknown>(
  withRouter,
  withFlags('connDigitalFreightMarketplace'),
  WithStatusToasts,
  withConditionalFallback(
    (props: {connDigitalFreightMarketplace?: boolean}) => !props.connDigitalFreightMarketplace,
    () => <p className="text-center">You don&#39;t have access to this integration.</p>
  )
)(AmazonFreightRegistration);
