import {useCallback, useRef} from 'react';
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {indexBy} from 'lodash/fp';
import {CreateFacilityPointOfContact, FacilityPointOfContact, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {AxiosError} from 'axios';
import {ContactDraft} from './types';
import {
  createPointOfContactFacility,
  deleteFacilityPointOfContact,
  getFacilityPointsOfContact,
  updateFacilityPointOfContact
} from 'App/api/facilities';
import {parseV3ApiError} from 'App/api/typedUtils';
import useConsistentReference from 'App/utils/hooks/useConsistentReference';
import {FACILITIES_CONTACTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

type ContactsUpdateApiActions = {
  toCreate: CreateFacilityPointOfContact[];
  toUpdate: FacilityPointOfContact[];
  toDelete: string[];
};

type ContactsUpdateApiActionResults = {
  created: FacilityPointOfContact[];
  updated: FacilityPointOfContact[];
  deleted: string[];
};

const indexById: <T extends {id: string}>(things: T[]) => Record<string, T> = indexBy((x) => x.id);

/**
 * A function which computes the necessary API actions to make `contacts` reflect the intent of
 * `updates`
 */
function computeContactsUpdateApiActions(
  contacts: FacilityPointOfContact[],
  updates: (FacilityPointOfContact | ContactDraft)[]
): ContactsUpdateApiActions {
  /** A Record of the original contacts list keyed by ID, so that we can easily and efficiently detect updates. */
  const contactsById = indexById(contacts);

  /** Request bodies to create new facilities for each `ContactDraft` instance in `updates` */
  const toCreate = updates
    .filter((c) => !('id' in c))
    .map(({person_name, facility_role, email, phone_number, is_default_for_facility}) => ({
      person_name,
      facility_role,
      email,
      phone_number,
      is_default_for_facility
    })) as CreateFacilityPointOfContact[];

  /** The `FacilityPointOfContact` instances from `updates` that have been changed from their original in `contacts` */
  const toUpdate = updates.filter(
    (c) => 'id' in c && JSON.stringify(c) !== JSON.stringify(contactsById[c.id])
  ) as FacilityPointOfContact[];

  /**
   * The IDs of contacts appearing in both the originals (`contacts`) and `updates`, so we can find
   * which IDs were removed, and should be deleted from the BE.
   */
  const presentIds = new Set(updates.map((c) => ('id' in c ? c.id : null)).filter(Boolean));

  /** IDs of contacts that we ought to delete.  */
  const toDelete = contacts.map((c) => c.id).filter((id) => !presentIds.has(id));

  return {toCreate, toUpdate, toDelete};
}

/**
 * A function to execute the actions computed by `computeContactsUpdateApiActions`.
 */
async function executeContactsUpdateApiActions(
  facilityId: string,
  {toCreate, toUpdate, toDelete}: ContactsUpdateApiActions
): Promise<ContactsUpdateApiActionResults> {
  /** Now we execute the API actions and gather a single promise for each kind. */
  const pCreated = Promise.all(toCreate.map((body) => createPointOfContactFacility(facilityId, body)));
  const pUpdated = Promise.all(toUpdate.map((contact) => updateFacilityPointOfContact(facilityId, contact)));
  const pDeleted = Promise.all(toDelete.map((contactId) => deleteFacilityPointOfContact(facilityId, contactId)));

  /** Now we await those actions, and extract the responses for creates and updates. */
  const [created, updated] = await Promise.all([pCreated, pUpdated, pDeleted]);
  return {created, updated, deleted: toDelete};
}

function updateContactsWithResults(
  contacts: FacilityPointOfContact[],
  {created, updated, deleted}: ContactsUpdateApiActionResults
): FacilityPointOfContact[] {
  /**
   * A Record key'd by ID of the returned updated `FacilityPointOfContact` instances, so we can
   * replace their previous version in the `contacts` array but keep the order consistent.
   */
  const updatedById = indexById(updated);

  /** start with the previous contacts list */
  let newContacts = contacts;
  /** Remove those contacts that were deleted  */
  newContacts = newContacts.filter(({id}) => !deleted.includes(id));
  /** Replace those instances that were updated, without changing the relative ordering. */
  newContacts = newContacts.map((contact) => updatedById[contact.id] ?? contact);
  /** Add the newly created contacts at the end */
  newContacts = newContacts.concat(created);

  return newContacts;
}

/**
 * A hook providing a `useQuery` like interface to get the contacts for a facility, and provides
 * an `mutate` callback too, via `useMutation`.
 *
 * Errors retrieving the contacts will be reflected in the returned `error` property.
 * Errors updating will be signaled by calling the passed in `onError` function.
 * This way, write errors are signalled just once and so are suitable for firing off a toast, while
 * read errors remain available over multiple renders so the UX can't get in a "where the heck are
 * the contacts" type state.
 */
export const useMutableFacilityPointsOfContact = (
  facilityId: string,
  {
    onError,
    onSuccess
  }: {
    onError: (error: Error) => unknown;
    onSuccess?: () => unknown;
  }
): {
  /** From `useQuery`, are we fetching the data? */
  isLoading: boolean;
  /**
   * Either:
   *    an empty array if we've not yet loaded any data,
   *    the most recent data loaded,
   *    or the most recent update passed to `mutate` that is either pending or was successful.
   */
  contacts: (FacilityPointOfContact | ContactDraft)[];
  /** If there was an error when we last fetched the contacts, it will appear here. */
  error: Error | null;
  /**
   * A function for updating facility contacts. The `contacts` property returned by this hook will
   * eagerly change to reflect the mutation. When the mutation succeeds, the returned `contacts`
   * property will update again to reflect the same entities, in the same order, but with the
   * entities returned from the backend, so they will all be `FacilityPointOfContact` instances.
   * If there is an error, this hook will call the passed in `onError` with the a corresponding
   * `Error` instance, the returned `contacts` will revert to when was previously cached, and the
   * query (as in `useQuery`) will be invalidated.
   *
   */
  mutate: (updates: (FacilityPointOfContact | ContactDraft)[]) => void;
  /**
   * Indicates that an update to the backend is in progress or pending, from `useMutation`.
   */
  isMutating: boolean;
} => {
  type T = FacilityPointOfContact | ContactDraft;
  /** The react-query client, useful for updating/invalidating the cache. */
  const client = useQueryClient();

  const fetcher = useCallback(() => getFacilityPointsOfContact(facilityId) ?? [], [facilityId]);
  /** The query instance of fetching the contacts. */
  const {
    isLoading: queryIsLoading,
    data: contactsData,
    error,
    isFetched
  } = useQuery<FacilityPointOfContact[], Error>([FACILITIES_CONTACTS_QUERY_KEY, facilityId], fetcher);

  /**
   * The `FacilityPointOfContact` fetched from the back end. This is not
   * This array object that will only ever become a different object, in the `===` sense, if and only
   * if the data changes, as in `JSON.stringify(arg)` returns something different.
   */
  const fetchedContacts = useConsistentReference(contactsData ?? []);

  /** The source of truth for `contacts` */
  const contactsRef = useRef<T[]>(fetchedContacts);

  /** The react-query mutation instance. */
  const mutation = useMutation<
    (FacilityPointOfContact | ContactDraft)[],
    Error,
    (FacilityPointOfContact | ContactDraft)[]
  >({
    mutationFn: useCallback(
      async (updates: (FacilityPointOfContact | ContactDraft)[]): Promise<FacilityPointOfContact[]> => {
        contactsRef.current = updates;
        const actions = computeContactsUpdateApiActions(fetchedContacts, updates);
        const result = await executeContactsUpdateApiActions(facilityId, actions);
        const newContacts = updateContactsWithResults(fetchedContacts, result);
        client.setQueryData([FACILITIES_CONTACTS_QUERY_KEY, facilityId], newContacts);
        contactsRef.current = newContacts;
        return newContacts;
      },
      [fetchedContacts, facilityId, client]
    ),

    onError: useCallback(
      (error: Error) => {
        client.setQueryData([FACILITIES_CONTACTS_QUERY_KEY, facilityId], fetchedContacts);
        contactsRef.current = fetchedContacts;
        void client.invalidateQueries([FACILITIES_CONTACTS_QUERY_KEY, facilityId]);
        onError(error);
      },
      [client, fetchedContacts, facilityId, onError]
    ),

    onSuccess: useCallback(() => {
      if (onSuccess) onSuccess();
    }, [onSuccess]),

    retry: useCallback((failureCount: number, error: Error) => {
      if (failureCount >= 3) {
        return false;
      }
      const {is4xx} = parseV3ApiError(error);
      return !is4xx;
    }, [])
  });

  const {mutate} = mutation;
  const isMutating = mutation.status === 'loading';

  if (!isMutating && isFetched && contactsRef.current !== fetchedContacts) {
    contactsRef.current = fetchedContacts;
  }

  const isLoading = queryIsLoading;

  return {isLoading, contacts: contactsRef.current, error, mutate, isMutating};
};

export type UseFacilityPointsOfContactQueryOption = UseQueryOptions<
  FacilityPointOfContact[],
  AxiosError<ShipwellApiErrorResponse, unknown>,
  FacilityPointOfContact[],
  string[]
>;

export const useFacilityPointsOfContactQuery = (
  facilityId: string,
  options?: Omit<UseFacilityPointsOfContactQueryOption, 'queryKey' | 'queryFn'>
) => {
  const query = useQuery(
    [FACILITIES_CONTACTS_QUERY_KEY, facilityId],
    async () => {
      const contacts = await getFacilityPointsOfContact(facilityId);

      return contacts;
    },
    options
  );

  return {
    isFacilityPointOfContactsLoading: query.isLoading || query.isFetching,
    facilityPointOfContacts: query.data ?? [],
    error: query.error ?? null,
    refetchFacilityPointOfContacts: query.refetch
  };
};
