import {Form, Formik} from 'formik';
import {CreateSupplier} from '@shipwell/backend-core-sdk';
import {Button, Title} from '@shipwell/shipwell-ui';
import {CreateSupplierStepsModalFooter} from '../../components';
import {CREATE_SUPPLIER_FORM_SCHEMA} from '../../schemas';
import {SupplierFields} from '../../fields/';

const getInitialValues = (supplier?: Partial<CreateSupplier>): CreateSupplier => ({
  mailing_address: {country: ''},
  billing_address: {country: ''},
  name: '',
  ...supplier
});

export type NewSupplierFormInitialValues = CreateSupplier;

export const NewSupplierForm = ({
  supplier,
  onNext,
  onCancel
}: {
  supplier?: CreateSupplier;
  onNext: (formValues: CreateSupplier) => void;
  onCancel: () => void;
}) => {
  return (
    <Formik
      initialValues={getInitialValues(supplier)}
      onSubmit={onNext}
      validationSchema={CREATE_SUPPLIER_FORM_SCHEMA}
      validateOnMount
    >
      <Form className="grid gap-4" noValidate>
        <Title>Supplier Information</Title>
        <SupplierFields />
        <CreateSupplierStepsModalFooter>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </CreateSupplierStepsModalFooter>
      </Form>
    </Formik>
  );
};
