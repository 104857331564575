import moment from 'moment';

// based on the date values we can derive which MODE is being used
export const transformDateRangeForReadback = (dateValues: (string | undefined)[]) => {
  const [start, stop] = dateValues;
  const formatDate = (date: (typeof dateValues)[number]) =>
    moment(date).isValid() ? moment(date).format('MMM D, YYYY') : date;
  if (start && stop) {
    if (start === stop) {
      return `On ${formatDate(start) || ''}`;
    }
    return `Between ${formatDate(start) || ''} and ${formatDate(stop) || ''}`;
  }
  if (start) {
    return `After ${formatDate(start) || ''}`;
  }
  if (stop) {
    return `Before ${formatDate(stop) || ''}`;
  }
};

// date can be either a string or array
const getDateFromFilter = (date: string[] | string | undefined) => (Array.isArray(date) ? date[0] : date);

// remove 'gte' or 'lte' from date key to get common key.
// EX: pickupStartDatetimeLte => pickupStartDatetime
const sterilizeDateKey = (dateKey: string) => dateKey.substring(0, dateKey.length - 3);

// takes in all filters, transforms date related filters, returns all filters with date filters transformed
// EX: {"overallStatus": [ "UNASSIGNED", "ASSIGNED" ], "plannedPickupStartDatetimeGte": "2023-06-15"} => {overallStatus: [ "UNASSIGNED", "ASSIGNED" ], plannedPickupStartDatetime: ["After 06/15/23"]}
export const transformFiltersForReadback = (activeFilters: Record<string, string[] | string | undefined>) => {
  const filterKeys = Object.keys(activeFilters);
  // get date filter keys
  const dateKeys = filterKeys.filter((key) => ['Gte', 'Lte'].some((item) => key.includes(item)));

  // get filters that are not date related so we can return them as is
  const filtersWithoutDates = filterKeys
    .filter((key) => dateKeys.every((dateKey) => dateKey !== key))
    .reduce((filtersObj, key) => {
      return {
        ...filtersObj,
        [key]: activeFilters[key]
      };
    }, {});

  // group lte and gte date keys of the same kind
  const dateKeyValues = dateKeys.reduce((datePairs, key) => {
    const isStartKey = key.includes('Gte');
    const isStopKey = key.includes('Lte');
    if (isStartKey) {
      datePairs.start = {...datePairs.start, [sterilizeDateKey(key)]: getDateFromFilter(activeFilters[key])};
    }
    if (isStopKey) {
      datePairs.stop = {...datePairs.stop, [sterilizeDateKey(key)]: getDateFromFilter(activeFilters[key])};
    }
    return datePairs;
  }, {} as {start: Record<string, string | undefined>; stop: Record<string, string | undefined>});
  const expectedDateObject = convertDatesToStringArrays(dateKeyValues);
  const dateFiltersObject = Object.keys(expectedDateObject).reduce((dateFilters, key) => {
    const readBackString = transformDateRangeForReadback(expectedDateObject[key]);
    // the TableReadback is very specific about what is and is not valid.
    // if no readbackString, we must return an empty array or the TableReadback will fail
    dateFilters[key] = readBackString ? [readBackString] : [];
    return dateFilters;
  }, {} as Record<string, string[]>);

  return {
    ...filtersWithoutDates,
    ...dateFiltersObject
  };
};

// data is received as: {start: {pickupDatetime: '2022-2-2'}, stop: {pickupDatetime: '2022-3-3'}}
// output is {pickupDatetime: ['2022-2-2','2022-3-3']}
// if a key is not available in start but found in stop or visa versa => {pickupDatetime: ['2022-2-2', undefined]}
export function convertDatesToStringArrays(dates: {
  start?: Record<string, string | undefined>;
  stop?: Record<string, string | undefined>;
}): Record<string, (string | undefined)[]> {
  const result: Record<string, (string | undefined)[]> = {};

  if (dates.start) {
    const startKeys = Object.keys(dates.start);
    for (const key of startKeys) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(dates.start[key]);

      if (!dates.stop || !(key in dates.stop)) {
        result[key].push(undefined);
      }
    }
  }

  if (dates.stop) {
    const stopKeys = Object.keys(dates.stop);
    for (const key of stopKeys) {
      if (!result[key]) {
        result[key] = [];
      }
      if (!(dates.start && key in dates.start)) {
        result[key].push(undefined);
      }
      result[key].push(dates.stop[key]);
    }
  }

  return result;
}
