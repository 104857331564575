import {createColumnHelper} from '@tanstack/react-table';
import {Person} from './playgroundUtils';

const columnHelper = createColumnHelper<Person>();

export const columns = [
  columnHelper.accessor('firstName', {
    id: 'firstName',
    header: 'First Name',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
    enableSorting: false
  }),
  columnHelper.accessor('lastName', {
    id: 'lastName',
    header: () => <span>Last Name</span>,
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('age', {
    id: 'age',
    header: () => 'Age',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id
  }),
  columnHelper.accessor('progress', {
    id: 'profileProgress',
    header: 'Profile Progress',
    cell: (info) => `${info.getValue()}%`,
    footer: (info) => info.column.id
  })
];
