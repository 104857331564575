import {compose} from 'recompose';
import {useQueryClient} from '@tanstack/react-query';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useShipmentActions} from 'App/data-hooks';
import {SHIPMENT_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

type PropTypes = {
  shipmentId: string;
};

const GenerateBOL = ({shipmentId, ...props}: PropTypes) => {
  const {generateShipmentBOLMutation, isLoading} = useShipmentActions(shipmentId);
  const queryClient = useQueryClient();

  return (
    <DeprecatedButton
      disabled={isLoading}
      variant="text"
      onClick={() =>
        generateShipmentBOLMutation.mutate(null, {
          onSuccess: () => {
            void queryClient.invalidateQueries([SHIPMENT_DOCUMENTS_QUERY_KEY, shipmentId]);
            const {setSuccess} = props as WithStatusToastProps;
            setSuccess('Success!', 'BOL(s) have been generated and can be previewed in the documents panel');
          }
        })
      }
    >
      Generate BOL
    </DeprecatedButton>
  );
};

export default compose<PropTypes, PropTypes>(WithStatusToasts)(GenerateBOL);
