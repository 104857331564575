import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import isNumber from 'lodash/isNumber';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {CreateStageTimelineEvent, CreateTrackingPoint, StageTimelineEventType} from '@shipwell/corrogo-sdk';
import {createLegTimelineEvent, createLegTrackingPoint, deleteLegTimelineEvent} from 'App/api/corrogo/typed';
import {LegTimeline} from 'App/components/ShipmentTrackingOverview/TimelineContainer/Timeline';
import NewEventModal from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import type {CreateEventValidation} from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import useToggle from 'App/utils/hooks/useToggle';
import {LEG_TIMELINE, LEG_TRACKING} from 'App/data-hooks/queryKeys';
import type {ShipmentTimelineSummaryEvent} from 'App/api/corrogo/typed';

export const LegTimelineContainer = ({legId}: {legId: string}) => {
  const [showNewEventModal, toggleShowNewEventModal] = useToggle();

  const queryClient = useQueryClient();
  const createEventMutation = useMutation<
    Awaited<ReturnType<typeof createLegTimelineEvent>>,
    AxiosError<Awaited<ReturnType<typeof createLegTimelineEvent>>, CreateStageTimelineEvent>,
    CreateStageTimelineEvent
  >((payload) => createLegTimelineEvent(legId, payload), {
    onSettled: () => queryClient.invalidateQueries([LEG_TIMELINE, legId])
  });
  const createTrackingMutation = useMutation<
    Awaited<ReturnType<typeof createLegTrackingPoint>>,
    AxiosError<Awaited<ReturnType<typeof createLegTrackingPoint>>>,
    CreateTrackingPoint
  >((payload) => createLegTrackingPoint(legId, payload), {
    onSettled: () => queryClient.invalidateQueries([LEG_TRACKING, legId])
  });

  const deleteLegTimelineEventMutation = useMutation<
    Awaited<ReturnType<typeof deleteLegTimelineEvent>>,
    unknown,
    {eventId: string}
  >(({eventId}) => deleteLegTimelineEvent(legId, eventId), {
    onSettled: () => queryClient.invalidateQueries([LEG_TIMELINE, legId])
  });

  const handleSubmit = (values: CreateEventValidation) => {
    createEventMutation.mutate({
      description: values.description,
      occurred_at: values.occurred_at,
      event_type: values.place_changed ? StageTimelineEventType.LocationUpdate : StageTimelineEventType.Info
    });

    if (values.place_changed && isNumber(values.place_changed.latitude) && isNumber(values.place_changed.longitude)) {
      createTrackingMutation.mutate({
        update_time: values.occurred_at,
        lat: values.place_changed.latitude,
        lon: values.place_changed.longitude
      });
    }

    toggleShowNewEventModal();
  };

  const handleDeleteEvent = (event: ShipmentTimelineSummaryEvent) => {
    return deleteLegTimelineEventMutation.mutate({eventId: event.id});
  };

  return (
    <>
      <div className="sticky top-0 z-10 mb-2 flex items-center bg-sw-background-component">
        <DeprecatedButton className="flex grow" onClick={toggleShowNewEventModal}>
          Add Event
        </DeprecatedButton>
      </div>
      <LegTimeline legId={legId} toggleShowEventModal={toggleShowNewEventModal} onDeleteEvent={handleDeleteEvent} />
      <NewEventModal show={showNewEventModal} onClose={toggleShowNewEventModal} handleSubmit={handleSubmit} />
    </>
  );
};
