import {checkShipmentModes, calculateShipmentTotals} from 'App/utils/globalsTyped';
import {METRIC, convertKilogramsToPounds} from 'App/utils/internationalConstants';
const WEIGHT_TOTAL = 6000;
const LINEAR_FEET_TOTAL = 12;

export const warnings = (values = {}) => {
  const {hasLTL, hasFTL, hasVLTL} = checkShipmentModes(values.mode);
  const warnings = {};

  // LTL only warnings
  if (hasLTL && !hasFTL && !hasVLTL) {
    const totals = calculateShipmentTotals({line_items: values?.line_items});
    const isExcessiveWeight =
      totals.system === METRIC
        ? convertKilogramsToPounds(totals.weight) >= WEIGHT_TOTAL
        : totals.weight >= WEIGHT_TOTAL;
    const isExcessiveLength = values.total_linear_feet >= LINEAR_FEET_TOTAL;
    warnings.excessiveSize = isExcessiveLength || isExcessiveWeight;
  }

  return warnings;
};
