import {Field} from 'formik';
import {FormikTextarea} from '@shipwell/shipwell-ui';

const CompanyNotesForCarrier = () => {
  return (
    <div>
      <div className="mb-3">
        The notes for carrier will be displayed on all shipments, BOLs and Rate Cons. They can be edited from the
        shipment at any time.
      </div>
      <Field
        name="default_carrier_notes"
        label="Notes for Carrier"
        minRows="3"
        maxRows="3"
        component={FormikTextarea}
      />
    </div>
  );
};

export default CompanyNotesForCarrier;
