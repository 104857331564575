import Lookup from 'App/components/_tableFiltersWithSavedViews/filterComponents/lookup';
import ListSelect from 'App/components/_tableFiltersWithSavedViews/filterComponents/listSelect';
import LocationSearch from 'App/components/_tableFiltersWithSavedViews/filterComponents/locationSearch';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import modeFilterOptions from 'App/utils/shipmentModeFilterOptions';

const getCarrierRelationships = async (input) => {
  try {
    const response = await getCarrierRelationshipsPromise({q: input});
    return response.body.results;
  } catch (error) {
    console.error(error);
  }
};

export const filters = [
  {
    name: 'carrierRelationshipId',
    label: 'Carrier',
    type: Lookup,
    isMulti: true,
    loadOptions: getCarrierRelationships,
    labelPath: 'shipwell_vendor.name',
    getOptionLabel: (option) => option.shipwell_vendor && option.shipwell_vendor.name,
    getOptionValue: (option) => option.id,
    useArray: true,
    secondaryName: 'carrierName'
  },
  {
    name: 'modes',
    type: ListSelect,
    label: 'Modes',
    options: modeFilterOptions(),
    useArray: true,
    allowMultiSelect: true
  },
  {
    name: 'equipmentTypes',
    type: Lookup,
    isMulti: true,
    label: 'Equipment',
    useStore: 'shipments.equipmentTypes',
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.machine_readable,
    useArray: true
  },
  {
    name: 'status',
    type: ListSelect,
    label: 'Status',
    options: [
      {label: 'Active', value: 'ACTIVE'},
      {label: 'Paused', value: 'PAUSED'},
      {label: 'Suspended', value: 'SUSPENDED'},
      {label: 'Expired', value: 'EXPIRED'}
    ],
    useArray: true,
    allowMultiSelect: true
  },
  {
    name: 'originsContains',
    type: LocationSearch,
    label: 'Origins',
    useArray: true,
    secondaryName: 'originsStateProvince',
    ignoreFieldWarnings: ['postal_code']
  },
  {
    name: 'destinationsContains',
    type: LocationSearch,
    label: 'Destinations',
    useArray: true,
    secondaryName: 'destinationsStateProvince',
    ignoreFieldWarnings: ['postal_code']
  }
];
