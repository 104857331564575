import {JSX} from 'react';
import {useState, useEffect} from 'react';
import {Sidebar} from '@shipwell/shipwell-ui';
import map from 'lodash/map';
import {getDisplayName} from 'recompose';
import {ShipmentSidebarActions} from '../SidebarActions';
import SidebarDocuments from './DocumentsController';

const KEY_IS_OPEN = 'shipmentSidebar_isOpen';
const KEY_SELECTED_INDEX = 'shipmentSidebar_selectedIndex';
const COMPONENTS = [
  {button: {name: 'ActionFilled', label: 'Actions'}, component: ShipmentSidebarActions},
  {button: {name: 'Clipboard', label: 'Documents'}, component: SidebarDocuments}
];

type PropTypes = {
  shipmentId: string;
};

const ShipmentSidebar = ({shipmentId}: PropTypes): JSX.Element => {
  const [isOpen, setIsOpen] = useState(localStorage.getItem(KEY_IS_OPEN) === 'true');
  const [selectedIndex, setSelectedIndex] = useState(Number(localStorage.getItem(KEY_SELECTED_INDEX)));
  const buttons = map(COMPONENTS, 'button');
  const sidebarComponents = map(COMPONENTS, 'component');

  useEffect(() => localStorage.setItem(KEY_IS_OPEN, String(isOpen)), [isOpen]);
  useEffect(() => localStorage.setItem(KEY_SELECTED_INDEX, String(selectedIndex)), [selectedIndex]);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelect = (index: number) => {
    setIsOpen(isOpen ? selectedIndex !== index : true);
    setSelectedIndex(index);
  };

  return (
    <Sidebar
      className="shipment__sidebar z-0 mt-2"
      buttons={buttons}
      initialIsOpen={isOpen}
      initialSelectedIndex={selectedIndex}
      onClose={onClose}
      onSelect={onSelect}
      tooltipPlacement="left"
    >
      {sidebarComponents.map((Component) => (
        <Component key={`sidebar-${getDisplayName(Component)}`} shipmentId={shipmentId} onClose={onClose} />
      ))}
    </Sidebar>
  );
};

export default ShipmentSidebar;
