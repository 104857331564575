import {Modal, FormikTextarea, FormikCheckbox, FormikDateTimePicker, SvgIcon, Title} from '@shipwell/shipwell-ui';
import {useQuery} from '@tanstack/react-query';
import {Field, Formik, Form} from 'formik';
import {object, string, InferType, boolean, number} from 'yup';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {TIMELINE_EVENT_TEMPLATES} from 'App/data-hooks/queryKeys';
import TemplateDescription from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal/TemplateDescription';
import {useCreateEventTemplate} from 'App/data-hooks';
import {getTimelineEventTemplates} from 'App/api/tracking/typed';

const createEventSchema = object().shape({
  description: string().nullable().required('A description is required.'),
  occurred_at: string().nullable().required('A valid time is required.'),
  save_template: boolean().nullable(),
  place_changed: object().nullable().shape({
    formatted_address: string().nullable(),
    latitude: number().nullable(),
    longitude: number().nullable()
  })
});

export type CreateEventValidation = InferType<typeof createEventSchema>;

const NewEventModal = ({
  show,
  onClose,
  values,
  handleSubmit,
  setError
}: {
  show: boolean;
  onClose(): void;
  values?: {
    description?: string | null;
    occurred_at?: string | null;
    template_description?: string;
    event_type?: string;
  };
  handleSubmit(values: CreateEventValidation): void;
  setError?(errorHeader: string, errorDescription: string): void;
}) => {
  const defaultFormValues = {
    description: values?.description || '',
    occurred_at: values?.occurred_at || new Date()
  };

  const eventTemplatesQuery = useQuery([TIMELINE_EVENT_TEMPLATES, {}], () => getTimelineEventTemplates({}));
  const existingTemplateDescriptions = eventTemplatesQuery?.data?.data?.results?.map((result) => result?.description);
  const createTemplateMutation = useCreateEventTemplate({
    onError: (error: {error_description: string}) => {
      if (setError) {
        setError(
          'Error!',
          error?.error_description || 'There was an error while creating the template, please try again.'
        );
      }
    }
  });

  const onSubmit = (values: CreateEventValidation) => {
    if (values?.save_template) {
      const payload = {description: values?.description};
      createTemplateMutation.mutate(payload);
    }

    handleSubmit(values);
  };

  const canShowSaveCheckbox = (values: CreateEventValidation) => {
    const trimmedDescription = values?.description?.trim();
    return (
      // A trailing space shouldn't be considered as a new entry to be saved.
      trimmedDescription !== values?.template_description && // If a description entered by the user matches an existing one, skip saving it
      !existingTemplateDescriptions?.includes(trimmedDescription)
    );
  };

  return (
    <Modal
      onClose={onClose}
      title="Add Timeline Event"
      show={show}
      bodyVariant="disableOverflowScroll"
      footerComponent={null}
    >
      <Formik
        validationSchema={createEventSchema}
        initialValues={{...defaultFormValues, ...values} as CreateEventValidation}
        onSubmit={onSubmit}
      >
        {({isSubmitting, values, dirty}) => (
          <Form className="mb-10 font-normal">
            <Title variant="formTitle" className="mb-2">
              Define Event
            </Title>
            <div className="flex flex-col gap-y-4">
              <TemplateDescription />
              <Field name="description" label="Custom Timeline Description" component={FormikTextarea} required />
              {dirty && canShowSaveCheckbox(values) ? (
                <Field
                  label="Save as a Templated Timeline Description"
                  name="save_template"
                  component={FormikCheckbox}
                />
              ) : null}
              <Field
                component={FormikDateTimePicker}
                name="occurred_at"
                label="Date and Time"
                prepend={<SvgIcon name="Calendar" />}
              />
              <Field
                disabled={values?.event_type}
                searchAddressBook={false}
                label="Location or Zipcode"
                name="place_changed"
                component={AddressSearch}
                prepend={<SvgIcon name="Location" />}
              />
            </div>
            <ModalFormFooter onCancel={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewEventModal;
