import {ChangeEvent, useState} from 'react';
import {useQueryClient, useMutation} from '@tanstack/react-query';
import {Shipment, ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Modal, Toast} from '@shipwell/shipwell-ui';
import {browserHistory} from 'react-router';
import {CARRIERS, DETAILS, TIMELINE, WORKFLOWS, STAGES, CARRIER_BIDS} from 'App/utils/dashboardConstants';
import {permViewCarriers, permViewContracts} from 'App/utils/globals';
import {
  SHIPMENTS_DASHBOARD_QUERY_KEY,
  CORE_SHIPMENTS_QUERY_KEY,
  CORE_SHIPMENT_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import LegacyDashboardSummary from 'App/components/dashboardSummary';
import TenderRequestForm from 'App/containers/tendering/create';
import {
  useGetCarrierRelationshipsById,
  useGetShipmentInvoices,
  useGetShipmentTimelineEvents,
  useShipment,
  useUserMe
} from 'App/data-hooks';
import {CancelShipmentForm} from 'App/containers/Dashboard/ShipmentSummary';
import {shareShipment, updateShipment} from 'App/api/shipment/typed';
import CarrierBidCreateContainer from 'App/containers/carrierBids/create';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import ShareLinkForm from 'App/containers/Shipment/forms/ShareLinkForm';
import {ROUTING_GUIDE_SUCCESS_ON_EXECUTION} from 'App/containers/workflows/workflowConstants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  SHIPMENT_INITIATE_ROUTING_GUIDE,
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export const LegacyDashboardSummaryWrapper = ({
  defaultShipment,
  onClose
}: {
  defaultShipment?: ShippingDashboardRow;
  onClose: () => void;
}) => {
  const {data: userData} = useUserMe();
  const userPermissions = userData?.user?.permissions || [];
  const canEditShipment =
    userPermissions.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    userPermissions.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);
  const canViewCarrier = userPermissions.includes(permViewCarriers);
  const canViewContracts = userPermissions.includes(permViewContracts);
  const userFeatureFlags = userData?.company?.feature_flags;
  const isContractsEnabled = userFeatureFlags?.contracts_enabled || false;
  const isRoutingGuidesEnabled = userFeatureFlags?.policies_enabled || false;
  const isBidManagerEnabled = userFeatureFlags?.bid_manager_enabled || false;

  const [showTenderRequestForm, setShowTenderRequestForm] = useState(false);
  const [showCarrierBidForm, setShowCarrierBidForm] = useState(false);
  const [showRoutingGuideModal, setShowRoutingGuideModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [showRoutingGuideInitiatedModal, setShowRoutingGuideInitiatedModal] = useState(false);
  const [showCarrierBidSuccess, setShowCarrierBidSuccess] = useState(false);
  const [archiveError, setArchiveError] = useState<string>();

  const queryClient = useQueryClient();

  const {
    shipment: fetchedShipment,
    isLoading: isLoadingFetchedShipment,
    isFetching: isFetchingShipment
  } = useShipment(defaultShipment?.id);
  // passing defaultShipment as a fallback to speed up apparent loadtimes for user
  // the "catch" is the defaultShipment type does not include the FULL shipment details so some calls will have to wait until the fetchedShipment is not in a loading state
  const shipment = isLoadingFetchedShipment || isFetchingShipment ? defaultShipment : fetchedShipment;

  const updateShipmentMutation = useMutation(updateShipment);

  const onUpdateShipment = (attrs: Partial<ShippingDashboardRow>, e: ChangeEvent<HTMLButtonElement>) => {
    if (isLoadingFetchedShipment || !fetchedShipment) {
      return;
    }
    const payload = Object.assign(fetchedShipment, attrs);
    e.target.disabled = true;
    updateShipmentMutation.mutate(
      {
        shipmentId: fetchedShipment.id,
        shipment: payload
      },
      {
        onSettled: (data, err) => {
          if (data) {
            void queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
            setShowArchiveModal(false);
            onClose();
          }
          if (err) {
            console.error(err);
            setArchiveError('Unable to archive shipment');
          }
        }
      }
    );
  };

  const shareShipmentMutation = useMutation(shareShipment);

  const handleShareLink = (
    values: Shipment & {
      emails: {label: string; value: string}[];
      include_map: boolean;
      recipient_phone_number?: string | null;
    }
  ) => {
    const shipmentId = shipment?.id;
    if (!shipmentId) {
      return;
    }
    const emailList = values.emails?.map((email) => email.value);
    shareShipmentMutation.mutate(
      {
        shipmentId,
        shareShipment: {
          external_link: values.external_tracking_link,
          include_map: values.include_map.toString(),
          recipient_emails: emailList,
          recipient_phone_number: values?.recipient_phone_number
        }
      },
      {
        onSettled: () => {
          setShowShareLinkModal(false);
        }
      }
    );
  };

  const isEnabledTimelineEvents = Boolean(shipment?.id);
  const {timelineEvents} = useGetShipmentTimelineEvents({
    requestBody: {
      shipmentId: shipment?.id || '',
      pageSize: 100
    },
    options: {
      enabled: isEnabledTimelineEvents
    }
  });

  const isEnabledShipmentInvoies = Boolean(shipment?.id);
  const {shipmentInvoices} = useGetShipmentInvoices({
    requestBody: {
      shipmentId: shipment?.id || '',
      pageSize: 100
    },
    options: {
      enabled: isEnabledShipmentInvoies
    }
  });

  const vendorId = shipment?.relationship_to_vendor?.id;
  const {carrierRelationships} = useGetCarrierRelationshipsById({
    vendorId: vendorId || '',
    options: {
      enabled: Boolean(vendorId)
    }
  });
  return (
    <>
      <div className="flex h-full flex-col justify-between overflow-hidden">
        <LegacyDashboardSummary
          shipmentId={shipment?.v3_shipment_id || ''}
          shipmentLoaded={Boolean(shipment)}
          shipmentDetails={shipment}
          renderSidebarDropdownContent={({onClick}: {onClick: () => void}) => (
            <>
              {shipment?.state === 'quote_accepted' && shipment?.metadata?.open && isBidManagerEnabled ? (
                <li
                  onClick={() => {
                    onClick();
                    setShowCarrierBidForm(true);
                  }}
                  key="new_big"
                >
                  New Bid
                </li>
              ) : null}
              {shipment?.mode?.id && [1, 2, 4, 5, 7, 8].includes(shipment?.mode?.id) ? (
                <>
                  <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
                    <li
                      id="tenderToCarriers"
                      onClick={() => {
                        onClick();
                        setShowTenderRequestForm(true);
                      }}
                      key="tender_to_carrier"
                    >
                      Tender to Carrier(s)
                    </li>
                  </TenderingUserPermissionFallback>
                  {[1, 8].includes(shipment?.mode?.id) ? (
                    <>
                      {canViewContracts && isContractsEnabled && isRoutingGuidesEnabled && (
                        <TenderingUserPermissionFallback permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]}>
                          <li
                            id="pushToRoutingGuide"
                            onClick={() => {
                              onClick();
                              setShowRoutingGuideModal(true);
                            }}
                            key="push_to_routing_guide"
                          >
                            Push to Routing Guide
                          </li>
                        </TenderingUserPermissionFallback>
                      )}
                      <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                        <li
                          id="requestBids"
                          onClick={() => {
                            onClick();
                            browserHistory.push(`/marketplace/${shipment.id}/bids?requestRates=true`);
                          }}
                          key="request_bids"
                        >
                          Request Bids
                        </li>
                      </TenderingUserPermissionFallback>
                    </>
                  ) : null}
                </>
              ) : null}
              <li
                id="archive"
                onClick={() => {
                  onClick();
                  setShowArchiveModal(true);
                }}
                key="archive"
              >
                Archive
              </li>
              <li
                key="cancel"
                onClick={() => {
                  onClick();
                  setShowCancelModal(true);
                }}
              >
                Cancel
              </li>
              <li
                key="share"
                onClick={() => {
                  onClick();
                  setShowShareLinkModal(true);
                }}
              >
                Share
              </li>
            </>
          )}
          timelineEvents={timelineEvents}
          shipmentInvoices={shipmentInvoices}
          shipmentPOC={carrierRelationships?.point_of_contacts?.[0]}
          company={userData?.company}
          onCloseSidebarDropdown={onClose}
          canViewCarrier={canViewCarrier}
          canEditShipment={canEditShipment}
          tabOptions={[DETAILS, TIMELINE, CARRIERS, WORKFLOWS, STAGES, CARRIER_BIDS]}
          onShipmentUpdate={async () => {
            await queryClient.invalidateQueries([CORE_SHIPMENT_QUERY_KEY, shipment?.id]);
            await queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
          }}
          allowStopAction
          showEditStopDropdown
        />
      </div>
      <TenderRequestForm
        show={showTenderRequestForm}
        onClose={() => setShowTenderRequestForm(false)}
        //this attribute is not needed on shipment details, return true
        submitSucceeded
        shipmentDetailData={shipment}
      />
      <Modal
        show={showCarrierBidForm}
        title="New Bid"
        footerComponent={null}
        onClose={() => setShowCarrierBidForm(false)}
      >
        <CarrierBidCreateContainer
          shipment={shipment}
          onCancel={() => setShowCarrierBidForm(false)}
          onSubmitSuccess={() => {
            setShowCarrierBidForm(false);
            setShowCarrierBidSuccess(true);
          }}
        />
      </Modal>
      <Modal
        show={showRoutingGuideModal}
        title="Push to Routing Guide"
        footerComponent={null}
        onClose={() => setShowRoutingGuideModal(false)}
      >
        <ExecuteRoutingGuide
          selectedShipment={shipment}
          onCancel={() => setShowRoutingGuideModal(false)}
          onSubmitSuccess={() => {
            setShowRoutingGuideModal(false);
            setShowRoutingGuideInitiatedModal(true);
          }}
        />
      </Modal>
      <InfoModalWrapper
        show={showArchiveModal}
        onHide={() => {
          setShowArchiveModal(false);
        }}
        title={'Archive Shipment'}
        primaryAction={{
          label: 'Archive',
          action: (event: ChangeEvent<HTMLButtonElement>) => {
            onUpdateShipment(
              {
                metadata: {
                  archived: true,
                  tags: shipment?.metadata?.tags || [],
                  open: shipment?.metadata?.open || false,
                  load_board_enabled: shipment?.metadata?.load_board_enabled || false,
                  has_open_auction: shipment?.metadata?.has_open_auction || false
                },
                state: shipment?.state
              },
              event
            );
          }
        }}
      >
        <p>
          Are you sure you want to archive: <span>{shipment?.name ?? shipment?.reference_id ?? ''}</span>?
        </p>
      </InfoModalWrapper>
      <InfoModalWrapper
        show={showCancelModal}
        onHide={() => {
          setShowCancelModal(false);
        }}
        title={'Cancel'}
      >
        {fetchedShipment ? (
          <CancelShipmentForm
            shipment={fetchedShipment}
            onClose={() => {
              setShowCancelModal(false);
              void queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
              void queryClient.invalidateQueries([CORE_SHIPMENTS_QUERY_KEY]);
            }}
          />
        ) : null}
      </InfoModalWrapper>
      <InfoModalWrapper
        show={showShareLinkModal}
        bsSize="large"
        extraClass="share-link-modal"
        onHide={() => setShowShareLinkModal(false)}
        title={`Share ${shipment?.name || shipment?.reference_id || ''}`}
      >
        <ShareLinkForm values={shipment} onSubmit={handleShareLink} onCancel={() => setShowShareLinkModal(false)} />
      </InfoModalWrapper>
      <Toast
        show={showRoutingGuideInitiatedModal}
        title="Your Routing Guide is Running!"
        anchor="bottom-right"
        onClose={() => setShowRoutingGuideInitiatedModal(false)}
      >
        {ROUTING_GUIDE_SUCCESS_ON_EXECUTION}
      </Toast>
      <Toast
        show={showCarrierBidSuccess}
        title="Bid Submitted!"
        anchor="bottom-right"
        onClose={() => setShowCarrierBidSuccess(false)}
      >
        Carrier bid submitted successfully.
      </Toast>
      <Toast variant="error" show={Boolean(archiveError)} title="Error!" onClose={() => setArchiveError(undefined)}>
        {archiveError}
      </Toast>
    </>
  );
};
