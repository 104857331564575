import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Recursively filter out keys of an object whose value is nil.
 *
 * @param {Object.<*, *>} obj
 *
 * @return {Object.<*, *>}
 */
export default function omitEmptyKeys(obj = {}) {
  return Object.keys(obj || {}).reduce((filtered, key) => {
    const value = get(obj, key);
    if (isNil(value)) {
      return filtered;
    }
    if (isPlainObject(value)) {
      return {...filtered, [key]: omitEmptyKeys(value)};
    }

    if (Array.isArray(value)) {
      return {...filtered, [key]: value.map((v) => (isPlainObject(v) ? omitEmptyKeys(v) : v))};
    }

    return {...filtered, [key]: value};
  }, {});
}
