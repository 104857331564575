import {Component} from 'react';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import moment from 'moment';
import ReactTable from 'react-table-6';
import QuoteDocumentModal from 'App/containers/LoadBoard/components/QuoteDocument';
import {formatDateTime} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';

export default class AuctionCarrierBids extends Component {
  constructor(props) {
    super(props);
    this.state = {showForm: false, documentPreviewQuote: null};
  }

  render() {
    const {
      selectedShipment = {},
      selectedSpotNegotiation = {},
      spotNegotiationQuotes = {},
      handleSelect,
      vendorPOCs
    } = this.props;
    const {documentPreviewQuote} = this.state;
    const hasAwardedQuote = selectedSpotNegotiation?.is_most_recent_winner;
    const isClosedByCustomer =
      selectedSpotNegotiation?.closed_by_customer_at && moment(selectedSpotNegotiation.closed_by_customer_at).isValid();
    const is_tendered = selectedSpotNegotiation?.tenders?.length;
    const is_rejected = selectedSpotNegotiation?.rejection_reason_code;

    return (
      <div className="marketplace__bids">
        <QuoteDocumentModal
          spotNegotiationId={selectedSpotNegotiation?.id}
          spotNegotiationQuoteId={documentPreviewQuote?.id}
          spotNegotiationQuoteDocumentId={documentPreviewQuote?.spot_negotiation_quote.documents[0]?.id}
          onClose={() => this.setState({documentPreviewQuote: null})}
          shipperView
        />
        {spotNegotiationQuotes?.results?.length ? (
          <div className="table__container">
            <ReactTable
              data={spotNegotiationQuotes.results}
              showPagination={false}
              minRows={0}
              defaultSorted={[
                {
                  id: 'bid',
                  desc: false
                }
              ]}
              columns={[
                {
                  Header: 'Contact',
                  id: 'contact',
                  accessor: (d) => d.created_by_user,
                  Cell: (row) => {
                    if (vendorPOCs.length) {
                      const match = vendorPOCs.find((e) => e.user === row.value);

                      if (match) {
                        return `${match.first_name} ${match.last_name}`;
                      }
                    }
                    return '--';
                  }
                },

                {
                  Header: () => (
                    <span>
                      Submitted
                      <span className="btn-sort" />
                    </span>
                  ),
                  id: 'submitted',
                  sortable: true,
                  accessor: (d) => d.created_at,

                  Cell: (row) => formatDateTime(row.value, true)
                },
                {
                  Header: 'Mode',
                  id: 'mode',
                  accessor: (d) => d.mode,
                  Cell: (row) => row.value?.code || '--'
                },
                {
                  Header: 'Equipment',
                  id: 'equipment_type',
                  accessor: (d) => d.equipment_type,
                  Cell: (row) => row.value?.name || '--'
                },
                {
                  Header: () => (
                    <span>
                      Bid
                      <span className="btn-sort" />
                    </span>
                  ),
                  id: 'bid',
                  sortable: true,
                  accessor: (d) => d.total,
                  Cell: (row) => {
                    const {documents} = row.original.spot_negotiation_quote;

                    return (
                      <div className="flex items-center gap-x-1">
                        {formatCurrency(row.value, row.original.currency)}
                        {documents?.length ? (
                          <DeprecatedButton
                            variant="icon"
                            onClick={() => this.setState({documentPreviewQuote: row.original})}
                            icon={<SvgIcon name="Document" />}
                          />
                        ) : null}
                      </div>
                    );
                  }
                },
                {
                  Header: '',
                  id: 'select',
                  width: 130,
                  accessor: (d) => d,
                  Cell: (row) => {
                    if (is_rejected) {
                      return <span className="text-danger">Rejected</span>;
                    }
                    if (row.original.accepted_at && moment(row.original.accepted_at).isValid()) {
                      return <span className="text-success">Won</span>;
                    }
                    return (
                      <DeprecatedButton
                        disabled={isClosedByCustomer || is_tendered}
                        onClick={() => handleSelect(row.original)}
                        size="small"
                      >
                        {isClosedByCustomer ? 'Auction Closed' : is_tendered ? 'Tendered' : 'Select'}
                      </DeprecatedButton>
                    );
                  }
                }
              ]}
            />
          </div>
        ) : (
          <div className="marketplace__bids-noBids text-center">
            <h3>No bids from this carrier.</h3>
          </div>
        )}
      </div>
    );
  }
}
