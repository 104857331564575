import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {saveAs} from 'file-saver';
import {isEqual} from 'lodash';
import {
  AppointmentReason,
  AppointmentStatusEnum,
  DateTimeWithTimezone,
  DeliveryTypeEnum,
  Facility,
  FacilityAppointmentType,
  FacilityDock,
  LoadType,
  ReferenceQualifierEnum,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {IconButton} from '@shipwell/shipwell-ui';

import {ReferencePreferenceOrder, ReferenceQualifierNames, StatusTexts} from '../../constants';
import {AppointmentVerbContext} from '../../AppointmentVerbContext';

import generateCSV from 'App/utils/generateCSV';
import {AppointmentEntry, AppointmentReference} from 'App/data-hooks/appointments/types';
import {toTitleCase} from 'App/utils/string';
import {useShipmentsForAppointments} from 'App/data-hooks/appointments/useShipmentsForAppointment';
import {convertDuration, formatDateMonthDayYear, formatHrsMins} from 'App/utils/dateTimeGlobalsTyped';
import {parseV3ApiError} from 'App/api/typedUtils';

function fRefFormat(q: ReferenceQualifierEnum): (references?: AppointmentReference[]) => string {
  return function (references?: AppointmentReference[]): string {
    return references?.find((r) => r.qualifier === q)?.value ?? '';
  };
}

type AuxiliaryData = {
  facilities: {
    facility: Facility;
    loadTypes: LoadType[];
    docks: FacilityDock[];
  }[];
  shipments: Shipment[];
};

const PossibleAppointmentCSVColumns: {
  title: string;
  prop: keyof AppointmentEntry & string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: (value: any, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => string;
}[] = [
  {
    title: 'Appointment Date',
    prop: 'start',
    format: (start: DateTimeWithTimezone): string => {
      return formatDateMonthDayYear(start.timestamp, start.timezone) ?? '';
    }
  },
  {
    title: 'Reference Type',
    prop: 'references',
    format: (references?: AppointmentReference[]) => {
      const qualifier = ReferencePreferenceOrder.find((q) => references?.some(({qualifier}) => qualifier === q));
      return qualifier ? ReferenceQualifierNames[qualifier] : '';
    }
  },
  {
    title: 'Reference #',
    prop: 'references',
    format: (references?: AppointmentReference[]) => {
      const qualifier = ReferencePreferenceOrder.find((q) => references?.some(({qualifier}) => qualifier === q));
      const referenceValues = references?.filter((ref) => ref.qualifier === qualifier).map((r) => r.value);
      return referenceValues?.join(',') ?? '';
    }
  },
  {
    title: 'PO#',
    prop: 'references',
    format: fRefFormat(ReferenceQualifierEnum.PoNumber)
  },
  {
    title: 'BOL#',
    prop: 'references',
    format: fRefFormat(ReferenceQualifierEnum.BolNumber)
  },
  {
    title: 'PRO#',
    prop: 'references',
    format: fRefFormat(ReferenceQualifierEnum.ProNumber)
  },
  {
    title: 'Customer#',
    prop: 'references',
    format: fRefFormat(ReferenceQualifierEnum.CustomerReferenceNumber)
  },
  {
    title: 'Shipment Reference',
    prop: 'references',
    format: fRefFormat(ReferenceQualifierEnum.ShipmentReferenceId)
  },
  {
    title: 'Start',
    prop: 'start',
    format: (start: DateTimeWithTimezone) => start.timestamp
  },
  {
    title: 'End',
    prop: 'end',
    format: (end: DateTimeWithTimezone) => end.timestamp
  },
  {
    title: 'Appointment Type',
    prop: 'appointmentType',
    format: (appointmentType: FacilityAppointmentType) => toTitleCase(appointmentType as string)
  },
  {
    title: 'Load Type',
    prop: 'matchedLoadTypeId',
    format: (loadTypeId: string, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) =>
      auxiliaryData.facilities
        .find((f) => f.facility.id === appointment.facilityId)
        ?.loadTypes?.find((loadType) => loadType.id === loadTypeId)?.name ?? ''
  },
  {
    title: 'Status',
    prop: 'status',
    format: (status: AppointmentStatusEnum) => StatusTexts[status]
  },
  {
    title: 'Reason',
    prop: 'reason',
    format: (reason?: AppointmentReason) => toTitleCase(reason as string)
  },
  {
    title: 'Name',
    prop: 'name',
    format: (name?: string) => name ?? ''
  },
  {
    title: 'Stop Number',
    prop: 'name',
    format: (stopId: string | undefined, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      if (!stopId) return '';
      const shipment = auxiliaryData.shipments.find((shipment) => shipment.id === appointment.scheduledResourceId);
      if (!shipment) return '';
      const stops = (shipment.stops ?? []).sort((a, b) => a.ordinal_index - b.ordinal_index);
      const iStop = 1 + stops.findIndex((stop) => stop.id === stopId);
      return iStop ? `${iStop}` : '';
    }
  },
  {
    title: 'Stop Location Name',
    prop: 'name',
    format: (stopId: string | undefined, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      if (!stopId) return '';
      const shipment = auxiliaryData.shipments.find((shipment) => shipment.id === appointment.scheduledResourceId);
      if (!shipment) return '';
      const stops = (shipment.stops ?? []).sort((a, b) => a.ordinal_index - b.ordinal_index);
      const stop = stops.find((stop) => stop.id === stopId);
      return stop?.location.location_name ?? '';
    }
  },
  {
    title: 'Delivery Type',
    prop: 'deliveryType',
    format: (deliveryType?: DeliveryTypeEnum) => toTitleCase(deliveryType ?? '')
  },
  {
    title: 'Check In Time',
    prop: 'checkedInAt',
    format: (time: DateTimeWithTimezone) => time.timestamp
  },
  {
    title: 'Check Out Time',
    prop: 'checkedOutAt',
    format: (time: DateTimeWithTimezone) => time.timestamp
  },
  {
    title: 'Rejected At',
    prop: 'rejectedAt',
    format: (time: DateTimeWithTimezone) => time.timestamp
  },
  {
    title: 'Rejection Reason',
    prop: 'rejectedReasons',
    format: (rejectedReasons?: string) => rejectedReasons ?? ''
  },
  {
    title: 'Mode',
    prop: 'matchedLoadTypeId',
    format: (loadTypeId: string, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      const loadType = auxiliaryData.facilities
        .find((f) => f.facility.id === appointment.facilityId)
        ?.loadTypes?.find((loadType) => loadType.id === loadTypeId);
      return loadType?.mode ?? '';
    }
  },
  {
    title: 'Equipment',
    prop: 'matchedLoadTypeId',
    format: (loadTypeId: string, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      const shipment = auxiliaryData.shipments.find((shipment) => shipment.id === appointment.scheduledResourceId);
      if (shipment?.equipment_type?.name) {
        return shipment.equipment_type.name;
      }
      const loadType = auxiliaryData.facilities
        .find((f) => f.facility.id === appointment.facilityId)
        ?.loadTypes?.find((loadType) => loadType.id === loadTypeId);
      if (loadType?.equipment_type) {
        return loadType.equipment_type;
      }
      if (appointment.scheduledResourceMetadata?.resource_type === ScheduledResourceTypeEnum.FreightGeneral) {
        return (appointment.scheduledResourceMetadata?.equipment_type as string) ?? '';
      }
      return '';
    }
  },
  {
    title: 'Appointment Window',
    prop: 'matchedLoadTypeId',
    format: (loadTypeId: string, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      if (appointment.allDay) {
        return 'All Day';
      }
      const loadType = auxiliaryData.facilities
        .find((f) => f.facility.id === appointment.facilityId)
        ?.loadTypes?.find((loadType) => loadType.id === loadTypeId);
      if (loadType?.appointment_duration) {
        const ms = convertDuration(loadType.appointment_duration, 'milliseconds');
        return formatHrsMins(ms);
      }
      if (appointment.start && appointment.end) {
        const ms = new Date(appointment.end.timestamp).getTime() - new Date(appointment.start.timestamp).getTime();
        return formatHrsMins(ms);
      }
      return '';
    }
  },
  {
    title: 'Carrier Name',
    prop: 'carrierName',
    format: (carrierName?: string) => carrierName ?? ''
  },
  {
    title: 'Dock',
    prop: 'dockId',
    format: (dockId: string, appointment: AppointmentEntry, auxiliaryData: AuxiliaryData) => {
      const dock = auxiliaryData.facilities
        .find((f) => f.facility.id === appointment.facilityId)
        ?.docks?.find((dock) => dock.id === dockId);
      return dock?.name ?? '';
    }
  }
];

const selectedColumns = [
  'Appointment Date',
  'Reference Type',
  'Reference #',
  'Mode',
  'Delivery Type',
  'Equipment',
  'Carrier Name',
  'Appointment Window',
  'Load Type',
  'Dock'
];

function doExport(filename: string, appointments: AppointmentEntry[], auxiliaryData: AuxiliaryData): void {
  const supportableColumns =
    auxiliaryData.shipments.length > 0 ? selectedColumns.filter((colName) => colName !== 'Equipment') : selectedColumns;
  const columns = supportableColumns.map((title) => {
    const column = PossibleAppointmentCSVColumns.find((c) => c.title === title);
    if (!column) {
      throw new Error(`Unrecognized column selection: '${title}'.`);
    }
    return {
      title,
      prop: column.prop,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      format: (value: any, _: any, appointment: AppointmentEntry) => column.format(value, appointment, auxiliaryData)
    };
  });

  const csv = generateCSV(appointments, {columns, columnsAreComplete: true});
  const blob = new Blob([csv], {type: 'text/plain;charset=utf-8'});
  saveAs(blob, filename);
}

type AppointmentsDownloadButtonProps = {
  filename: string;
  appointments: AppointmentEntry[];
  facilities: Facility[];
  loadTypes: LoadType[];
  docks: FacilityDock[];
};

export default function AppointmentsDownloadButton(props: AppointmentsDownloadButtonProps) {
  const {filename, appointments, facilities, loadTypes, docks} = props;

  const {setError} = useContext(AppointmentVerbContext);
  const [lastError, setLastError] = useState<unknown>(null);
  const handleError = useCallback(
    (errorIn: unknown) => {
      if (errorIn == null) {
        setLastError(null);
        return;
      }
      if (!isEqual(errorIn, lastError)) {
        setLastError(lastError);
        const {isError, title, detail} = parseV3ApiError(errorIn);
        if (isError) {
          setError(title, detail);
        }
      }
    },
    [setError, lastError, setLastError]
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const [haveDownloaded, setHaveDownloaded] = useState(false);
  // Go ahead and load shipments eagerly or else we take too long after download is clicked.
  const shipmentsQuery = useShipmentsForAppointments(appointments, true);

  const auxiliaryData: AuxiliaryData = useMemo(() => {
    return {
      facilities: facilities.map((facility) => ({
        facility,
        loadTypes: loadTypes.filter((loadType) => loadType.facility_id === facility.id),
        docks: docks.filter((dock) => dock.facility_id === facility.id)
      })),
      shipments: shipmentsQuery.shipments
    };
  }, [shipmentsQuery.shipments, facilities, loadTypes, docks]);

  useEffect(() => {
    if (isDownloading && !shipmentsQuery.isLoading && !haveDownloaded) {
      handleError(null);
      try {
        setHaveDownloaded(true);
        doExport(filename, appointments, auxiliaryData);
      } catch (error) {
        handleError(error);
      }
    }
  }, [appointments, auxiliaryData, filename, handleError, isDownloading, haveDownloaded, shipmentsQuery.isLoading]);

  useEffect(() => {
    handleError(shipmentsQuery.error);
  }, [handleError, shipmentsQuery.error]);

  return (
    <div className="cursor-pointer justify-self-end p-3">
      <IconButton
        iconName="Download"
        aria-label="Download"
        onClick={() => setIsDownloading(true)}
        disabled={isDownloading}
      />
    </div>
  );
}
