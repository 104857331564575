import {ShipmentsApiShipmentsShipmentIdPutRequest, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import isUndefined from 'lodash/isUndefined';
import {editShipmentById} from './typed';

const useUpdateShipment = (
  mutationOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof editShipmentById>>,
    AxiosError<ShipwellError>,
    ShipmentsApiShipmentsShipmentIdPutRequest
  >
) => {
  return useMutation<
    Awaited<ReturnType<typeof editShipmentById>>,
    AxiosError<ShipwellError>,
    ShipmentsApiShipmentsShipmentIdPutRequest
  >(({shipmentId, shipment}) => {
    // TITAN-4210: set stop.location.address.id to null so backend will create a new id
    const {stops} = shipment;
    const transformedStops = stops?.map((stop) => {
      const stopCopy = {...stop};
      if (stopCopy.location.address && isUndefined(stopCopy.location.address.id)) {
        stopCopy.location.address.id = null;
      }
      return stopCopy;
    });

    return editShipmentById({shipmentId, shipment: {...shipment, stops: transformedStops}});
  }, mutationOptions);
};

export default useUpdateShipment;
