import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import first from 'lodash/first';
import {DrayageBooking, Leg} from '@shipwell/corrogo-sdk';
import {SHIPMENT_LEGS_QUERY_KEY, SHIPMENTS_QUERY_KEY, DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getLegsFromShipment} from 'App/api/corrogo/typed';

const useShipmentLegs = (shipmentId?: string, queryOptions?: UseQueryOptions<(Leg | DrayageBooking)[]>) => {
  const queryClient = useQueryClient();

  const getLegsFromShipmentQuery = useQuery<(DrayageBooking | Leg)[]>(
    [SHIPMENTS_QUERY_KEY, shipmentId, SHIPMENT_LEGS_QUERY_KEY],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment ID'));
      }
      const response = await getLegsFromShipment(shipmentId);
      return response.data.data;
    },
    {
      // set the query data of all legs associated to the shipment
      onSuccess: (data) =>
        data.forEach((leg) => {
          queryClient.setQueryData([DRAYAGE_QUERY_KEY, leg.id], leg);
        }),
      enabled: !isEmpty(shipmentId),
      ...queryOptions
    }
  );

  const getItemFromLeg = (legId: string) => {
    const leg = getLegsFromShipmentQuery.data?.find((leg) => leg.id === legId);
    return first(get(leg, 'leg_items', []));
  };

  return {
    ...getLegsFromShipmentQuery,
    getItemFromLeg
  };
};

export default useShipmentLegs;
