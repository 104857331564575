import {Button} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {withUserPermissionsFallback} from 'App/data-hooks';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';

interface CreateInvoiceButtonProps {
  onClick: () => void;
  role: SettlementsDashboardGeneratedBy;
  shipmentId: string;
}
const CreateFreightInvoiceButton = ({onClick, role, shipmentId}: CreateInvoiceButtonProps) => {
  const {stmGenerateInvoiceOnBehalfOfCarrier, stmCarrierInvoicing} = useFlags();
  const {prefetch: prefetchShipment} = useGetFullShipmentDetails(shipmentId);

  if (role === SettlementsDashboardGeneratedBy.BookingParty && !stmGenerateInvoiceOnBehalfOfCarrier) {
    return null;
  }
  if (!stmCarrierInvoicing) {
    return null;
  }
  return (
    <Button variant="secondary" size="sm" onClick={onClick} onMouseEnter={() => void prefetchShipment()}>
      {role === SettlementsDashboardGeneratedBy.ServiceProvider ? 'Create Invoice' : 'Create Carrier Invoice'}
    </Button>
  );
};

export default compose<CreateInvoiceButtonProps, CreateInvoiceButtonProps>(
  withUserPermissionsFallback<CreateInvoiceButtonProps>(['invoicing.create_freight_invoices'])
)(CreateFreightInvoiceButton);
