import {useEffect, useState, useCallback} from 'react';
import {compose} from 'redux';
import {isEmpty, get} from 'lodash';
import {Card, DeprecatedButton, Modal} from '@shipwell/shipwell-ui';
import {
  getFTPCredentials,
  getAPICredentials,
  postFTPRegistration,
  postAPIRegistration,
  disconnectFTPConnection,
  disconnectAPIConnection,
  refreshRMISFTPConnection
} from 'App/api/integrations';
import RMISConfig from 'App/formComponents/forms/RMISConfig';
import WithStatusToasts from 'App/components/withStatusToasts';
import useInterval from 'App/utils/hooks/useInterval';
import {integrationsConstants} from 'App/containers/integrations/constants';
import './styles.scss';

const RMISDetails = (props) => {
  const {setSuccess, setError} = props;
  const [isFTPConnected, setIsFTPConnected] = useState(false);
  const [isAPIConnected, setIsAPIConnected] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [FTPCreds, setFTPCreds] = useState({});
  const [APICreds, setAPICreds] = useState({});
  const [isPolling, setIsPolling] = useState(false);

  const handleFTPSubmit = async (values, actions) => {
    try {
      const response = await postFTPRegistration(values);
      if (response?.ok) {
        setSuccess('Success!', 'You have successfully connected to the FTP for RMIS!');
        setIsFTPConnected(true);
        setFTPCreds(response?.body);
      }
    } catch (error) {
      actions.setErrors({
        username: 'Error, please try again',
        password: 'Error, please try again'
      });
      setError('Error!', error?.error_description);
    }
  };
  const handleAPISubmit = async (values, actions) => {
    try {
      const response = await postAPIRegistration(values);
      if (response?.ok) {
        setSuccess('Success!', 'You have successfully connected to the API for RMIS!');
        setIsAPIConnected(true);
        setAPICreds(response?.body);
      }
    } catch (error) {
      actions.setErrors({
        username: 'Error, please try again',
        password: 'Error, please try again'
      });
      setError('Error!', error?.error_description);
    }
  };

  const getFTPData = useCallback(async () => {
    try {
      const response = await getFTPCredentials();
      if (response?.body?.results[0]) {
        setFTPCreds(response.body.results[0]);
        // job is not running, stop polling
        if (!get(response, 'body.results[0].job_status.is_running', false)) {
          setIsPolling(false);
        }
      }
    } catch (error) {
      /**
       * only when fetching data - make the error toast stay for an extended amount of time
       *  until the user dismisses it manually
       */
      setError('Error!', error?.error_description, 'top-right', {delay: null});
    }
  }, [setError]);

  const getAPIData = async () => {
    try {
      const response = await getAPICredentials();
      if (response?.body?.results?.length > 0) {
        setAPICreds(response.body.results[0]);
      }
    } catch (error) {
      /**
       * only when fetching data - make the error toast stay for an extended amount of time
       *  until the user dismisses it manually
       */
      setError('Error!', error?.error_description, 'top-right', {delay: null});
    }
  };

  // get FTP and API data when page loads
  useEffect(() => {
    getFTPData();
    getAPIData();
  }, []);

  useEffect(() => {
    if (!isEmpty(FTPCreds)) {
      setIsFTPConnected(true);
      // job is running, keep polling until is_running is false
      if (FTPCreds?.job_status.is_running) {
        setIsPolling(true);
      }
    } else {
      setIsPolling(false);
      setIsFTPConnected(false);
    }
  }, [FTPCreds]);

  useEffect(() => {
    if (!isEmpty(APICreds)) {
      setIsAPIConnected(true);
    } else {
      setIsAPIConnected(false);
    }
  }, [APICreds]);

  // polling to check if job is running
  useInterval(getFTPData, isPolling ? 5000 : null);

  /** the RMIS configuration page will either have both FTP and API connections,
      or only the FTP connection. It will not have a scenario where only the API 
      connection is active. */
  const handleDisconnect = async () => {
    try {
      if (isFTPConnected) {
        const response = await disconnectFTPConnection(FTPCreds.id);
        if (response?.ok) {
          setSuccess(
            'Success!',
            'You have successfully disconnected from the RMIS Integration (FTP and API connection).'
          );
        }
        setFTPCreds({});
      }
      if (isAPIConnected) {
        const response = await disconnectAPIConnection(APICreds.id);
        if (response?.ok) {
          setSuccess(
            'Success!',
            'You have successfully disconnected from the RMIS Integration (FTP and API connection).'
          );
        }
        setAPICreds({});
      }
      setShowDisconnectModal(false);
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
    }
  };

  const handleRefresh = async () => {
    // show loading icon regardless of whether we're polling or not
    try {
      const response = await refreshRMISFTPConnection();
      // refresh endpoint returned success, trigger polling
      if (response.ok) {
        setIsPolling(true);
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
      setFTPCreds({});
    }
  };

  return (
    <div className="rmis-container">
      <div className="disconnect">
        <DeprecatedButton variant="secondary" onClick={() => setShowDisconnectModal(true)} disabled={!isFTPConnected}>
          <span className="integration-container__rmis-disconnect-btn-text">Disconnect</span>
        </DeprecatedButton>
      </div>
      <div className="rmis-wrapper">
        <Card
          title={
            <div className="rmis-details-card-title">
              <img alt="integration-logo" className="integration-logo" src={integrationsConstants.RMIS.logo} />
              <span>RMIS</span>
            </div>
          }
        >
          <div className="rmis-details-card-content">
            <div className="rmis-details-card-explainer">{integrationsConstants.RMIS.cardExplainerText}</div>
            <RMISConfig
              handleFTPSubmit={handleFTPSubmit}
              handleAPISubmit={handleAPISubmit}
              handleRefresh={handleRefresh}
              FTPCreds={FTPCreds}
              APICreds={APICreds}
              isFTPConnected={!isEmpty(FTPCreds)}
              isAPIConnected={!isEmpty(APICreds)}
              isBtnLoading={isPolling}
            />
          </div>
        </Card>
      </div>
      <Modal
        show={Boolean(showDisconnectModal)}
        title="Disconnect"
        variant="warning"
        onClose={() => setShowDisconnectModal(false)}
        primaryBtnName="Continue"
        onPrimaryAction={handleDisconnect}
      >
        Disconnecting will disconnect the FTP and API connection. Are you sure?
      </Modal>
    </div>
  );
};

export default compose(WithStatusToasts)(RMISDetails);
