import {useContext} from 'react';
import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import FacilityInfoForm from 'App/containers/facilities/forms/FacilityInfoForm';
import ContactsForm from 'App/containers/facilities/forms/ContactsForm';
import AttributesForm from 'App/containers/facilities/forms/AttributesForm';
import LoadTypeForm from 'App/containers/facilities/forms/LoadTypeForm';
import DocksForm from 'App/containers/facilities/forms/DocksForm';

const StepForms = () => {
  const {activeStep} = useContext(StepFormContext);
  switch (activeStep) {
    case 0:
      return <FacilityInfoForm />;
    case 1:
      return <ContactsForm />;
    case 2:
      return <AttributesForm />;
    case 3:
      return <LoadTypeForm />;
    case 4:
      return <DocksForm />;
    default:
      return null;
  }
};

export default StepForms;
