import {ChangeEvent, useState} from 'react';
import {Button, SearchField} from '@shipwell/shipwell-ui';
import {flexRender} from '@tanstack/react-table';
import {CreateSupplierStepsModal} from './CreateSupplierStepsModal';
import {getColumns} from './columns';
import PageHeader from 'App/common/pageHeader';
import Loader from 'App/common/shipwellLoader';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableContainer,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import {useTableFilters, useTypedTable} from 'App/components/TypedTable/hooks';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {HeaderContainer} from 'App/containers/shipments/components/Header/Header';
import useDebouncedEffect from 'App/utils/hooks/useDebouncedEffect';
import {useGetSuppliers} from 'App/data-hooks/suppliers';

const initialFilterState = {
  q: ''
};

const SuppliersListViewBase = ({setSuccess, setError}: WithStatusToastProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const {filters, onFilterChange} = useTableFilters(initialFilterState, true);
  const [isNewSupplierModalOpen, setIsNewSupplierModalOpen] = useState(false);
  const {paginatedSuppliers, isInitialLoading} = useGetSuppliers(filters);

  useDebouncedEffect(() => onFilterChange('q', searchTerm), 300, [searchTerm]);

  const table = useTypedTable({
    data: paginatedSuppliers?.data || [],
    columns: getColumns(setSuccess, setError)
  });

  if (isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <div className="flex flex-1 flex-col overflow-y-auto bg-sw-background">
      <HeaderContainer>
        <PageHeader
          title="Suppliers"
          actions={
            <Button variant="tertiary" iconName="AddCircleOutlined" onClick={() => setIsNewSupplierModalOpen(true)}>
              Supplier
            </Button>
          }
        >
          <SearchField
            label="Search suppliers"
            value={searchTerm}
            name="suppliersSearchBar"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
          />
        </PageHeader>
      </HeaderContainer>
      <div className="flex w-full items-center justify-center bg-sw-text-reverse h-screen-minus-34">
        <TableContainer>
          <Table
            head={table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    width={header.getSize()}
                    sortDirection={header.column.getIsSorted()}
                    onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                    onResize={header.getResizeHandler()}
                  >
                    <div className="flex items-center gap-1">
                      {header.column.getCanSort() ? (
                        <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                      ) : null}
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
            body={table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          />
        </TableContainer>
        <CreateSupplierStepsModal isOpen={isNewSupplierModalOpen} onClose={() => setIsNewSupplierModalOpen(false)} />
      </div>
    </div>
  );
};

export const SuppliersListView = withStatusToasts(SuppliersListViewBase);
