import {Link, RouterProps} from 'react-router';
import PageHeader from 'App/common/pageHeader';

export function DevToolsPage(props: RouterProps) {
  return (
    <>
      <PageHeader title="DevTools" />

      <div className="flex gap-2 px-5 py-2">
        <Link to="/dev-tools/info">Info</Link>
        <Link to="/dev-tools/feature-flags">Feature Flags</Link>
      </div>

      <div className="mt-5 px-5">{props.children}</div>
    </>
  );
}
