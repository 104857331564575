import {useMutation, UseMutationOptions, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {CapacityProviderSchema, ProviderCode, UberCapacityProviderAccountSchema} from '@shipwell/genesis-sdk';
import {getCarrierIntegrationApi} from 'App/api/integrations';
import {COMPANY_INTEGRATION_UBER} from 'App/data-hooks/queryKeys';
import {FormikSubmitEventData} from 'App/formComponents/forms/uberFreightRegistration';

/**
 * Deletes Uber Freight from the company belonging to the requesting user.
 */
const disconnectUberFreight = (opts = {}) =>
  getCarrierIntegrationApi().deleteProviderProvidersProviderCodeDelete(
    {
      providerCode: ProviderCode.Uber
    },
    opts
  );

/**
 * Gets the Uber Freight account number for company belonging to the requesting user.
 */
const getUberFreightAccount = (opts = {}) =>
  getCarrierIntegrationApi().getProviderProvidersProviderCodeGet(
    {
      providerCode: ProviderCode.Uber
    },
    opts
  );

/**
 * Gets the Uber Freight account number for company belonging to the requesting user.
 */
const updateUberFreightAccount = async (account: UberCapacityProviderAccountSchema, opts = {}) => {
  await disconnectUberFreight(opts);

  return await connectUberFreight(account.account_number, opts);
};

/**
 * Deletes Uber Freight from the company belonging to the requesting user.
 */
const connectUberFreight = (acountNumber: string, opts = {}) =>
  getCarrierIntegrationApi().createProviderProvidersPost(
    {
      createCapacityProviderSchema: {
        provider_code: ProviderCode.Uber,
        accounts: [{provider_code: ProviderCode.Uber, account_number: acountNumber}]
      }
    },
    opts
  );

export const useDisconnectUberFreightMutation = (options: UseMutationOptions<unknown, AxiosError, void, unknown>) =>
  useMutation([COMPANY_INTEGRATION_UBER], () => disconnectUberFreight(), options);

export const useGetUberFreightQuery = (
  options: UseQueryOptions<
    Promise<UberCapacityProviderAccountSchema | undefined>,
    AxiosError,
    UberCapacityProviderAccountSchema,
    [typeof COMPANY_INTEGRATION_UBER]
  >
) =>
  useQuery(
    [COMPANY_INTEGRATION_UBER],
    async () => {
      const {data} = await getUberFreightAccount();

      return data?.accounts && (data.accounts[0] as UberCapacityProviderAccountSchema);
    },
    options
  );

export const useUpdateUberFreightAccountMutation = (
  options: UseMutationOptions<CapacityProviderSchema, AxiosError, FormikSubmitEventData, unknown>
) =>
  useMutation(
    [COMPANY_INTEGRATION_UBER],
    async (formData: FormikSubmitEventData) => {
      formData.helpers.setSubmitting(true);
      try {
        const accountNumber: string = formData.values['account_number'] || '';
        const data = await updateUberFreightAccount({
          id: '', // right now this is never used
          account_number: accountNumber
        });
        return data.data;
      } finally {
        formData.helpers.setSubmitting(false);
      }
    },
    options
  );
