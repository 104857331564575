import {useEffect, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {getAllSpotNegotiationsByShipmentPromise, openCloseSpotNegotiationPromise} from 'App/api/quoting';
import {unassignCarrierPromise} from 'App/api/shipment';
import {PROPTYPE_COMPANY} from 'App/utils/propTypeConstants';

const CloseAuctionAction = ({shipment, company, onUpdateShipment}) => {
  const [allSpotNegs, setAllSpotNegs] = useState([]);
  const [hasSpotNegs, setHasSpotNegs] = useState(false);
  const [hasOpenSpotNegs, setHasOpenSpotNegs] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllSpotNegotiations = useCallback(async () => {
    if (shipment?.id) {
      try {
        const spotNegotiationsResult = await getAllSpotNegotiationsByShipmentPromise(shipment.id);
        setAllSpotNegs(spotNegotiationsResult.body.results);
        setHasSpotNegs(
          get(spotNegotiationsResult, 'body.results', []).filter((e) => e?.vendor?.id !== company?.id).length > 0
        );
        setHasOpenSpotNegs(
          get(spotNegotiationsResult, 'body.results', []).filter(
            (e) => !e.closed_by_customer_at || !moment(e.closed_by_customer_at).isValid()
          ).length > 0
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [shipment, company]);

  useEffect(() => {
    getAllSpotNegotiations();
  }, [getAllSpotNegotiations]);

  const toggleAuctionStatus = async () => {
    const body = {};
    const myAuctions = allSpotNegs.filter((e) => e.vendor?.id !== company?.id);
    body.spot_negotiations = myAuctions.map((e) => e.id);
    //toggle to opposite of current state on the negotiations
    body.close = !moment(myAuctions[0].closed_by_customer_at).isValid();
    setLoading(true);
    try {
      const toggleRes = await openCloseSpotNegotiationPromise(body);
      if (toggleRes?.status === 200 && body.close === false) {
        await unassignCarrierPromise(shipment.id);
      }
      await getAllSpotNegotiations();
    } catch (error) {
      console.error(error);
    } finally {
      onUpdateShipment(shipment.id);
    }
    setLoading(false);
  };

  return hasSpotNegs ? (
    <DeprecatedButton variant="text" onClick={toggleAuctionStatus} disabled={loading}>
      {hasOpenSpotNegs ? 'Close Auction' : 'Reopen Auction'}
    </DeprecatedButton>
  ) : null;
};

CloseAuctionAction.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }),
  company: PROPTYPE_COMPANY,
  onUpdateShipment: PropTypes.func
};

CloseAuctionAction.defaultProps = {
  onUpdateShipment: () => {}
};

export default CloseAuctionAction;
