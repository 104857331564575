import React from 'react';
import {Formik, Form} from 'formik';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import NewAirShipmentCustomFields from './AirShipmentCustomFields';
import NewAirShipmentFields from './AirShipmentFields';
import AirShipmentActionButtons from './AirShipmentActionButtons';
import {CustomFieldsContextProvider, useUserMe} from 'App/data-hooks';

interface AirShipmentFormProps {
  isEdit?: boolean;
  onCancel: () => void;
}
const AirShipmentForm: React.FC<AirShipmentFormProps> = ({isEdit, onCancel}) => {
  const {data: userMeData} = useUserMe();
  const companyId = userMeData?.company?.id || '';

  return (
    <CustomFieldsContextProvider companyId={companyId} entityType={CustomFieldEntityTypesEnum.Shipment}>
      <Formik onSubmit={(values) => console.log(values)} initialValues={{}}>
        {({isSubmitting, dirty}) => {
          return (
            <Form noValidate>
              <NewAirShipmentFields isEdit={isEdit} />
              <NewAirShipmentCustomFields userMeData={userMeData} />
              <AirShipmentActionButtons isSubmitting={isSubmitting} dirty={dirty} onCancel={onCancel} />
            </Form>
          );
        }}
      </Formik>
    </CustomFieldsContextProvider>
  );
};

export default AirShipmentForm;
