import {useWhitelabel} from 'App/utils/hooks/useWhitelabel';

export default function TrackingLogo() {
  const {logos} = useWhitelabel();

  return (
    <img
      src={logos.find((logo) => logo.image_type === 'INLINE_COLOR')?.logo}
      className="mx-auto my-6 w-full max-w-xs"
    />
  );
}
