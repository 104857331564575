import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {reduxForm, FieldArray, change, Field} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Toast, Modal, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import * as actions from 'App/actions/vendors';
import {deleteTriumphPayAccount, setDefaultTriumphAccount, getTriumphPayVendorDetails} from 'App/actions/_vendors';
import {postCarrierDocument, fetchCarrierDocuments} from 'App/actions/_documents';

import {scrollToFirstErrorField} from 'App/utils/globals';
import SelectField from 'App/formComponents/fields/select';
import renderField from 'App/formComponents/renderField';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import AddressSearchField from 'App/formComponents/fields/addressSearch';
import renderMultipleTriumphPayAccounts from 'App/formComponents/renderMultipleTriumphPayAccounts';
import LetterOfReleaseForm from 'App/formComponents/forms/TriumphLetterOfRelease';
import renderTextArea from 'App/formComponents/renderTextArea';

let VendorsTriumphPay = (props) => {
  const {
    ManageVendorsForm,
    triumphPay,
    manualEntry,
    carrierDocuments,
    selectedRelationship,
    postCarrierDocument,
    fetchCarrierDocuments,
    dispatch,
    deleteTriumphPayAccount,
    getTriumphPayVendorDetails,
    paymentProcessorUpdateEnabled,
    paymentProcessorCreateEnabled
  } = props;

  const [displayValues, setDisplayValues] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState('');
  const [showDefaultSuccessToast, setShowDefaultSuccessToast] = useState(false);
  const [showDeleteSuccessToast, setShowDeleteSuccessToast] = useState(false);
  const [showLetterOfReleaseSuccessToast, setShowLetterOfReleaseSuccessToast] = useState(false);
  const [factorCompanyIndex, setFactorCompanyIndex] = useState(0);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showDeleteFactorModal, setShowDeleteFactorModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState([]);
  const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const formValues = ManageVendorsForm && ManageVendorsForm.values && ManageVendorsForm.values.triumphPay;
  let display = [];
  const paymentAccounts =
    ManageVendorsForm &&
    formValues &&
    formValues.triumph_payment_accounts &&
    formValues.triumph_payment_accounts.length > 0 &&
    formValues.triumph_payment_accounts;

  const relationshipId = selectedRelationship && selectedRelationship.id ? selectedRelationship.id : null;
  const relationshipTriumphId = selectedRelationship && selectedRelationship.external_payment_processor_id;

  const handleFactorCompanyIndex = () => {
    ManageVendorsForm &&
      formValues &&
      paymentAccounts &&
      paymentAccounts.find((acct, index) => {
        if (acct.payment_type === 'FACTOR') {
          setFactorCompanyIndex(index);
        }
      });
  };
  const handleTriumphDisplay = () =>
    triumphPay.triumph_payment_accounts.forEach((acct) => {
      //set display value for each account type based on available keys
      if (acct.payment_type === 'ACH_BANK') {
        display = [
          ...display,
          {
            id: acct.id,
            display_value: `${acct.is_default ? `(Default) ` : ''} BANK: ${
              acct.account_name ? acct.account_name : '--'
            }`,
            is_default: acct.is_default
          }
        ];
      }
      if (acct.payment_type === 'MAIL_CHECK') {
        display = [
          ...display,
          {
            id: acct.id,
            display_value: `${acct.is_default ? `(Default) ` : ''} CHECK: ${
              acct.vendor_billing_address && acct.vendor_billing_address.formatted_address
                ? acct.vendor_billing_address.formatted_address
                : '--'
            }`,
            is_default: acct.is_default
          }
        ];
      }
      if (acct.payment_type === 'FACTOR') {
        display = [
          ...display,
          {
            id: acct.id,
            display_value: `${acct.is_default ? `(Default) ` : ''} FACTOR: ${
              acct.vendor_factor_company && acct.vendor_factor_company.company_name
                ? acct.vendor_factor_company.company_name
                : '--'
            }`,
            is_default: acct.is_default
          }
        ];
      }
    });
  //load up each triumph account to track edit state
  const setAccountEditArray = () => {
    const editModeInitialValues = [];
    if (paymentAccounts) {
      paymentAccounts.forEach((acct) => {
        editModeInitialValues.push(false);
      });
      setIsEditMode(editModeInitialValues);
    }
  };

  const updateAccountEditArray = (index) => {
    //update edit state for account index chosen
    const newEditArray = isEditMode;
    newEditArray.forEach((acct, idx) => {
      if (idx === index) {
        newEditArray[idx] = true;
      }
    });
    setIsEditMode(newEditArray);
    //refresh values to reflect account edit state update
    handleTriumphDisplay();
    setDisplayValues(display);
  };

  useEffect(() => {
    if (triumphPay && paymentAccounts) {
      //set initial state for isEditMode, set display values for dropdown
      handleTriumphDisplay();
      setDisplayValues(display);
      setAccountEditArray();
    }
    if (ManageVendorsForm && formValues && paymentAccounts) {
      handleFactorCompanyIndex();
    }
  }, [triumphPay, factorCompanyIndex]);

  const handleSetDefaultAccount = (index) => {
    dispatch(
      change(
        'ManageVendorsForm',
        `triumphPay.triumph_payment_accounts[${index}].is_default`,
        props.ManageVendorsForm && formValues && paymentAccounts[index] && !paymentAccounts[index].is_default
      )
    );
  };
  const handleClearSelectedAccount = () => {
    dispatch(change('ManageVendorsForm', `triumphPay.selected_account`, props.ManageVendorsForm && formValues && ''));
  };
  //close LOR modal
  const onClose = () => {
    setShowDeleteFactorModal(false);
  };
  //submit LOR
  const handleSubmit = (values, {setSubmitting, resetForm}) => {
    const {letter_of_release_description, letter_of_release_file} = values;
    // post new document
    return postCarrierDocument(
      {description: letter_of_release_description, file: letter_of_release_file},
      relationshipId
    ).then((response, error) => {
      if (response.data) {
        deleteTriumphPayAccount(triumphPay.id, paymentAccounts[factorCompanyIndex].id, {
          body: {
            letter_of_release_document_id: response.data.id
          }
        }).then((response, error) => {
          if (response.status === 204) {
            setShowDeleteFactorModal(false);
            fetchCarrierDocuments(relationshipId);
            getTriumphPayVendorDetails(relationshipTriumphId);
            setSubmitting(false);
            resetForm();
            setShowLetterOfReleaseSuccessToast(true);
          } else {
            setSubmitting(false);
            return error;
          }
        });
      } else {
        setSubmitting(false);
        return error;
      }
    });
  };
  return (
    <div>
      <InfoModalWrapper
        show={showPrivacyPolicy}
        onHide={() => setShowPrivacyPolicy(false)}
        bsSize="large"
        children={
          <iframe
            title="privacy policy"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              minHeight: '600px'
            }}
            src="https://s3-us-west-2.amazonaws.com/shipwell-static/privacy_policy.html"
          />
        }
      />
      <Toast
        show={showDefaultSuccessToast}
        title="Success!"
        anchor="top-right"
        onClose={() => setShowDefaultSuccessToast(false)}
      >
        Default account successfully updated.
      </Toast>

      <Toast
        show={showDeleteSuccessToast}
        title="Success!"
        anchor="top-right"
        onClose={() => setShowDeleteSuccessToast(false)}
      >
        Account details successfully updated.
      </Toast>
      <Toast
        show={showLetterOfReleaseSuccessToast}
        title="Delete Request Submitted!"
        anchor="top-right"
        onClose={() => setShowLetterOfReleaseSuccessToast(false)}
      >
        Your Letter of Release has been submitted. The account will be deleted once it is approved.
      </Toast>

      <Paper>
        <div className="vendors__header">
          <h3>
            <b>TriumphPay</b>
          </h3>
        </div>
        <div className="vendors__triumph-container">
          {ManageVendorsForm &&
          formValues &&
          triumphPay &&
          triumphPay.id &&
          paymentAccounts &&
          paymentAccounts.find((acct) => {
            return acct.id;
          }) &&
          !paymentAccounts.find((acct) => {
            return acct.payment_type === 'FACTOR';
          }) &&
          !manualEntry ? (
            <Grid item md={6} sm={6} xs={6} className="vendors__triumph-pay">
              <Field
                clearable
                labelKey="display_value"
                valueKey="id"
                name="triumphPay.selected_account"
                component={SelectField}
                label="Select TriumphPay Account"
                options={displayValues}
              />
            </Grid>
          ) : ManageVendorsForm &&
            formValues &&
            triumphPay &&
            triumphPay.id &&
            paymentAccounts &&
            paymentAccounts.find((acct) => {
              return acct.payment_type === 'FACTOR';
            }) &&
            !manualEntry ? (
            <Grid container spacing={8}>
              <Grid item md={12} sm={12} xs={12} className="vendors__triumph_bank_info__factor-message">
                {paymentProcessorUpdateEnabled ? (
                  <span>
                    Click the {<b>trash can icon</b>} below to upload a letter of release document in order to make
                    additional changes to your TriumphPay accounts.
                  </span>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                <div className="vendors__triumph-pay_payment-type">
                  <b>Factoring Company Name</b>
                  <p>
                    {paymentAccounts[factorCompanyIndex].vendor_factor_company &&
                      paymentAccounts[factorCompanyIndex].vendor_factor_company.company_name}
                  </p>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12} className="vendors__triumph_bank_info">
                <div className="vendors__triumph-pay_payment-type">
                  <b>Factoring Company Address</b>
                  <p>
                    {paymentAccounts[factorCompanyIndex].vendor_factor_company &&
                      paymentAccounts[factorCompanyIndex].vendor_factor_company.address &&
                      paymentAccounts[factorCompanyIndex].vendor_factor_company.address.formatted_address}
                  </p>
                </div>
              </Grid>
              <Grid item md={3} sm={3} xs={3} className="vendors__triumph_bank_info">
                {paymentProcessorUpdateEnabled && (
                  <DeprecatedButton
                    variant="icon"
                    onClick={() => {
                      setShowDeleteFactorModal(true);
                    }}
                    title={'Remove this TriumphPay Account'}
                  >
                    <SvgIcon name="TrashOutlined" className="showAsLink factor-company-delete" />
                  </DeprecatedButton>
                )}
              </Grid>

              {triumphPay && triumphPay.sync_status === 'SYNC_ERROR' && (
                <Grid item md={12} sm={12} xs={12}>
                  <span className="text-danger icon icon-Delayed" />
                  <span className="vendors__triumph_message">Factoring company update pending.</span>
                </Grid>
              )}
            </Grid>
          ) : (
            !paymentAccounts && (
              <Grid item md={12} sm={12} xs={12}>
                <span className="vendors__triumph_message"> No TriumphPay account to display.</span>
              </Grid>
            )
          )}
          {paymentAccounts && (
            <Modal
              show={Boolean(showDeleteFactorModal)}
              title="Delete Factoring Account"
              onClose={onClose}
              primaryBtnName="Submit"
              footerComponent={null}
            >
              <LetterOfReleaseForm
                showDeleteFactorModal={showDeleteFactorModal}
                factorCompanyIndex={factorCompanyIndex}
                setShowDeleteFactorModal={setShowDeleteFactorModal}
                relationshipId={relationshipId}
                dispatch={dispatch}
                paymentAccounts={paymentAccounts}
                triumphPay={triumphPay}
                relationshipTriumphId={relationshipTriumphId}
                setShowLetterOfReleaseSuccessToast={setShowLetterOfReleaseSuccessToast}
                onCancel={onClose}
                onClose={onClose}
                onSubmit={handleSubmit}
                handleSubmit={handleSubmit}
              />
            </Modal>
          )}
        </div>

        <FieldArray
          label="TriumphPay Accounts"
          name="triumphPay.triumph_payment_accounts"
          component={renderMultipleTriumphPayAccounts}
          props={{
            handleShowPrivacyPolicy,
            formValues: ManageVendorsForm && formValues,
            initial: ManageVendorsForm && ManageVendorsForm.initial,
            carrierDocuments: carrierDocuments,
            vendorName:
              props.selectedCarrier &&
              props.selectedCarrier.vendor &&
              props.selectedCarrier.vendor.shipwell_vendor.name,
            triumphPay: triumphPay,
            deleteTriumphPayAccount: props.deleteTriumphPayAccount,
            handleTriumphDisplay,
            handleSetDefaultAccount,
            setDefaultTriumphAccount: props.setDefaultTriumphAccount,
            paymentAccounts,
            setShowDefaultSuccessToast,
            setShowDeleteSuccessToast,
            displayValues,
            setDisplayValues,
            handleClearSelectedAccount,
            relationshipTriumphId,
            isEditMode,
            updateAccountEditArray,
            paymentProcessorUpdateEnabled,
            paymentProcessorCreateEnabled
          }}
        />
      </Paper>
    </div>
  );
};

VendorsTriumphPay = reduxForm({
  form: 'ManageVendorsForm',
  enableReinitialize: true,
  onSubmitFail: scrollToFirstErrorField
})(VendorsTriumphPay);

VendorsTriumphPay = connect(
  (state) => ({
    carrierDocuments: state.documents.carrierDocuments,
    selectedCarrier: state.vendors.selectedCarrier,
    triumphPay: state.vendors.triumphPay
  }),
  {
    ...actions,
    deleteTriumphPayAccount,
    setDefaultTriumphAccount,
    postCarrierDocument,
    fetchCarrierDocuments,
    getTriumphPayVendorDetails
  }
)(VendorsTriumphPay);

export default VendorsTriumphPay;
