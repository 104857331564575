import {flexRender} from '@tanstack/react-table';
import {SearchFormValues} from '../searchForm/searchFormValues';
import {columns} from './columns';
import {getTotalPages} from './helpers';
import {useTagsService} from './useTagsService/useTagsService';
import {
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import {TableLoadingBar} from 'App/components/TypedTable/complementaryComponents';
import {useTableColumns, useTablePagination, useTableSort, useTypedTable} from 'App/components/TypedTable/hooks';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/components/TypedTable/tableUtils';

const TAGS_TABLE = 'TAGS_TABLE';

export interface SearchTableProps {
  filters?: SearchFormValues;
}

export function SearchTable(props: SearchTableProps) {
  const tableColumns = useTableColumns(columns, TAGS_TABLE);
  const [sorting, setSorting] = useTableSort();
  const [pagination, setPagination] = useTablePagination({
    tableType: TAGS_TABLE,
    shouldUseRouter: true
  });

  const tagsService = useTagsService({
    filters: props.filters,
    pagination,
    sorting
  });

  const table = useTypedTable({
    data: tagsService.data?.data || [],
    columns: tableColumns.columns,
    pageCount: getTotalPages(pagination, tagsService.data),
    state: {
      sorting,
      pagination,
      columnOrder: tableColumns.columnOrder,
      columnVisibility: tableColumns.columnVisibility
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination
  });

  return (
    <TableContainer>
      {tagsService.isLoading && <TableLoadingBar />}
      <TableContainer>
        <Table
          head={table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => (
                <TableHeader
                  key={header.id}
                  isFixed={i === 0}
                  width={header.getSize()}
                  sortDirection={header.column.getIsSorted()}
                  onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                  onResize={header.getResizeHandler()}
                >
                  <div className="flex items-center gap-1">
                    {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </TableHeader>
              ))}
            </TableHeaderRow>
          ))}
          body={table.getRowModel().rows.map((row, rowIndex) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} rowIndex={rowIndex}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        />
      </TableContainer>
      <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
    </TableContainer>
  );
}
