import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Card, Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import ShipmentReps from '../index';
import {getRepsFromState} from '../View';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ActionsDropdown = ({onEdit}) => (
  <Dropdown variant="icon" drop="down" indicator={false} icon={<SvgIcon name="Overflow" />} alignEnd>
    {() => (
      <>
        <li title="Edit" onClick={onEdit} className="min-w-[80px]">
          Edit
        </li>
      </>
    )}
  </Dropdown>
);

const ShipmentCardReps = ({
  ordinalIndex,
  isCollapsed,
  onCollapse,
  draggableProvided,
  user,
  isQuotingLimitedUser,
  reps,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  const canEdit =
    (get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
    !isQuotingLimitedUser;

  const handleEdit = (isEditing) => () => setEditing(isEditing);

  return (
    <Card
      title={
        <div className="flex items-center py-4">
          <span className="mr-3">
            <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
          </span>
          <div className="flex items-baseline">
            <span className="mr-1">Reps</span>
            <span className="text-grey-5 text-xxs font-normal">• {(reps || []).length || 'NO'} REPS</span>
          </div>
        </div>
      }
      className="hipmentRepsCard"
      tabOrder={ordinalIndex}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      draggableProvided={draggableProvided}
      actions={canEdit && <ActionsDropdown onEdit={handleEdit(true)} />}
      bodyClassName="p-4 empty:p-0"
      {...props}
    >
      <ShipmentReps editing={editing} onCancelEdit={handleEdit(false)} onSuccessEdit={handleEdit(false)} {...props} />
    </Card>
  );
};

ShipmentCardReps.defaultProps = {
  ordinalIndex: 0,
  isCollapsed: false,
  onCollapse: () => {},
  draggableProvided: {
    draggableProps: {},
    dragHandleProps: {},
    innerRef: null
  },
  user: {},
  isQuotingLimitedUser: true
};

ShipmentCardReps.propTypes = {
  ordinalIndex: PropTypes.number,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  draggableProvided: PropTypes.shape({
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.elementType})])
  }),
  user: PropTypes.object,
  isQuotingLimitedUser: PropTypes.bool
};

const mapStateToProps = (state) => ({
  user: state.userProfile.user,
  isQuotingLimitedUser: state.userProfile.user.is_quoting_limited_user,
  reps: getRepsFromState(state)
});

export default connect(mapStateToProps)(ShipmentCardReps);
