import {EditUser} from './EditUser';
import {UserMeContextProvider} from 'App/data-hooks';

export default function CompanyUserDetailsContainer() {
  return (
    <UserMeContextProvider>
      <EditUser />
    </UserMeContextProvider>
  );
}
