import {useMemo} from 'react';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {StepInfo} from '../itemWithStepsCard';
import {getIconForStatus} from './helpers';

export interface StepStatusProps {
  stepInfo: StepInfo;
}

export function StepStatus(props: StepStatusProps) {
  const status = useMemo(() => getIconForStatus(props.stepInfo.status), [props.stepInfo.status]);

  const StatusIcon = () => (
    <SvgIcon
      name={status.iconName}
      className={status.className}
      aria-label={props.stepInfo.status}
      height="16"
      width="16"
    />
  );

  return (
    <div className="flex items-center gap-1">
      <div className="flex items-center gap-1">
        {props.stepInfo.extraInfo && (
          <Tooltip tooltipContent={props.stepInfo.extraInfo}>
            <StatusIcon />
          </Tooltip>
        )}

        {!props.stepInfo.extraInfo && <StatusIcon />}
      </div>
      <div className="whitespace-normal ">
        <span>{props.stepInfo.content}</span>
      </div>
    </div>
  );
}
