import {IconButton} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {AppointmentCalendarEntry} from './AppointmentCalendarEvent';
import {CalendarAppointmentEvent} from 'App/data-hooks/appointments/types';

export function AllDayEventsToggle(props: {
  events: CalendarAppointmentEvent[];
  allDayExpanded: boolean;
  setAllDayExpanded: (allDayExpanded: boolean) => unknown;
}) {
  const {events, allDayExpanded, setAllDayExpanded} = props;
  const rootClasses = [
    'sw-all-day-appointments-roll-up',
    'flex flex-row w-full',
    'bg-sw-overlay-background',
    'text-sw-info leading-3 font-bold'
  ];
  return allDayExpanded ? (
    <>
      <div className={classNames(rootClasses)}>
        <div className="sw-appointment-name flex-0">{`${events.length} All Day Appointments`}</div>
        <div className="flex flex-1">&nbsp;</div>
        <IconButton iconName="CarrotUp" onClick={() => setAllDayExpanded(false)} aria-label="Collapse All Day Events" />
      </div>
      {events.map(({extendedProps: {appointment, viewMode}}) => (
        <AppointmentCalendarEntry key={appointment.id} appointment={appointment} viewMode={viewMode} />
      ))}
    </>
  ) : (
    <div className={classNames(rootClasses)}>
      <div className="sw-appointment-name flex-0">{`${events.length} All Day Appointments`}</div>
      <div className="flex flex-1">&nbsp;</div>
      <IconButton iconName="CarrotDown" onClick={() => setAllDayExpanded(true)} aria-label="Expand All Day Events" />
    </div>
  );
}
