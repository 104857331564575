import {Fragment} from 'react';
import {Field} from 'redux-form';
import {Link} from 'react-router';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import renderField from 'App/formComponents/renderField';
import SelectField from 'App/formComponents/fields/select';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import renderToggle from 'App/formComponents/renderToggle';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

const renderVendorsPOC = ({
  fields,
  pocList,
  ManageVendorsForm,
  editPOC,
  deletePOC,
  addPOC,
  featureFlags,
  resendLoadboardEmail,
  carrierRoles,
  meta: {touched, error},
  ...rest
}) => (
  <div>
    {fields.map((poc, index) => {
      let formValue;
      const hasMultipleFields = fields.length > 1;
      if (
        ManageVendorsForm &&
        ManageVendorsForm.values &&
        ManageVendorsForm.values.point_of_contacts &&
        ManageVendorsForm.values.point_of_contacts[index]
      ) {
        formValue = ManageVendorsForm.values.point_of_contacts[index];
      }
      return (
        <div key={index} className={'vendors__shipwell-vendor vendors__poc' + (pocList[index] ? ' edit' : '')}>
          <div className="quote-flow__stopsPOC-header">
            {!pocList[index] && (
              <a role="button" className="">
                <i onClick={() => editPOC(index)} className="flaticon-edit action-link" />
                &nbsp;&nbsp;&nbsp;
              </a>
            )}
            <DeprecatedButton
              variant="icon"
              onClick={() => {
                if (fields.length > 1) {
                  fields.remove(index);
                }
                deletePOC(index);
              }}
            >
              <SvgIcon
                name="TrashOutlined"
                className={classnames({showAsLink: hasMultipleFields}, {hidden: !hasMultipleFields})}
                title={fields.length > 0 ? 'Remove this POC' : 'At least one POC is required'}
              />
            </DeprecatedButton>
          </div>
          <Grid container spacing={8}>
            <Grid item sm={6} xs={12}>
              {!pocList[index] ? (
                <div>
                  <p>
                    <b>First Name</b>
                  </p>
                  <p>{formValue && formValue.first_name ? formValue.first_name : '-'}</p>
                </div>
              ) : (
                <Field
                  name={`${poc}.first_name`}
                  component={renderField}
                  placeholder="First Name"
                  label="First Name"
                  req
                />
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {!pocList[index] ? (
                <div>
                  <p>
                    <b>Last Name</b>
                  </p>
                  <p>{formValue && formValue.last_name ? formValue.last_name : '-'}</p>
                </div>
              ) : (
                <Field name={`${poc}.last_name`} component={renderField} placeholder="Last Name" label="Last Name" />
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {!pocList[index] ? (
                <div>
                  <p>
                    <b>Phone</b>
                  </p>
                  <p>{formValue && formValue.phone_number ? getPhoneHyperlink(formValue.phone_number) : '-'}</p>
                </div>
              ) : (
                <Field
                  name={`${poc}.phone_number`}
                  component={renderPhoneNumberField}
                  placeholder="Phone"
                  label="Phone"
                  req
                />
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {!pocList[index] ? (
                <div>
                  <p>
                    <b>E-mail</b>
                  </p>
                  <p className="email">{formValue && formValue.email ? formValue.email : '-'}</p>
                </div>
              ) : (
                <Field name={`${poc}.email`} component={renderField} placeholder="E-mail" label="E-mail" req />
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {!pocList[index] ? (
                <div>
                  <p>
                    <b>Role</b>
                  </p>
                  <p>
                    {formValue && formValue.job_title && carrierRoles.find((el) => el.db_name === formValue.job_title)
                      ? carrierRoles.find((el) => el.db_name === formValue.job_title).human_readable_name
                      : '-'}
                  </p>
                </div>
              ) : (
                <Field
                  simpleValue
                  req
                  name={`${poc}.job_title`}
                  label="Role"
                  placeholder="Role"
                  labelKey="human_readable_name"
                  valueKey="db_name"
                  options={carrierRoles}
                  component={SelectField}
                />
              )}
            </Grid>
            {featureFlags.load_board_enabled && (
              <Fragment>
                <Grid item sm={6} xs={12} className="vendors__poc-load_board_enabled_fields">
                  <Field name={`${poc}.load_board_enabled`} component={renderToggle} label="Load Board Access" />
                </Grid>
                {formValue && !formValue.id && formValue.load_board_enabled && (
                  <Grid item sm={6} xs={12} className="vendors__poc-load_board_enabled_fields">
                    <Field
                      name={`${poc}.should_send_email`}
                      component={renderToggle}
                      label="Send Invite E-mail to Contact"
                    />
                  </Grid>
                )}
                {formValue && formValue.id && formValue.load_board_enabled && (
                  <Grid item sm={6} xs={12} className="vendors__poc-load_board_enabled_fields">
                    <Link onClick={() => resendLoadboardEmail(formValue)}>Resend Invite</Link>
                  </Grid>
                )}
              </Fragment>
            )}
          </Grid>
          {(fields.length - 1 === index || fields.length === 0) && (
            <div className="btn-container pad-top text-right">
              <a
                role="button"
                onClick={() => {
                  fields.push({load_board_enabled: false});
                  addPOC();
                }}
              >
                <i className="flaticon-plus pad-right" /> Add Point of Contact
              </a>
            </div>
          )}
        </div>
      );
    })}
  </div>
);

export default renderVendorsPOC;
