import {useFormikContext} from 'formik';
import {Rule} from '@shipwell/shipwell-ui';
import Stop from './Stop';
import {ContainerPickupStopType} from './StopTypeContactsFields';
import type {LegFormValues} from 'App/containers/shipments/drayage/create/components/LegForm/validation';

function ContainerPickup() {
  const {values} = useFormikContext<LegFormValues>();
  if (!values.containerPickupRequired) {
    return null;
  }
  return (
    <>
      <Stop stopType={ContainerPickupStopType} />
      <Rule />
    </>
  );
}

export default ContainerPickup;
