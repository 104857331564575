import {
  FETCH_SETTINGS,
  FETCH_AUTOMATED_VENDORS,
  FETCH_QUICKBOOKS_CUSTOMERS,
  GET_SHIPMENT_INVOICES,
  SHIPMENT_BILL_CREATE_SUCCESS,
  SHIPMENT_BILLS_REQUEST_SUCCESS,
  SHIPMENT_BILLS_REQUEST_ERROR,
  FETCH_QUICKBOOKS_VENDORS,
  FETCH_EXPENSE_CLASSES,
  FETCH_EXPENSE_ACCOUNTS,
  FETCH_PAYMENT_TERMS,
  FEDEX_REGISTRATION_SUCCESS,
  GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS,
  GET_CONNECTED_FMS
} from '../actions/types';

const INITIAL_STATE = {
  settings: null,
  automatedVendors: null,
  quickBooksCustomers: null,
  quickBooksCarriers: null,
  shipmentInvoices: null,
  expenseClasses: null,
  expenseAccounts: null,
  paymentTerms: null,
  shipmentBills: [],
  expenseClassesByName: {},
  expenseAccountsByName: {},
  isTriumphPayAuthed: false,
  connectedFms: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };

    case FETCH_AUTOMATED_VENDORS:
      return {
        ...state,
        automatedVendors: action.payload
      };

    case FETCH_QUICKBOOKS_CUSTOMERS:
      return {
        ...state,
        quickBooksCustomers: action.payload
      };

    case GET_SHIPMENT_INVOICES:
      return {
        ...state,
        shipmentInvoices: {
          ...action.payload,
          results: action.payload.results.map((invoice) => ({
            ...invoice
          }))
        }
      };

    case SHIPMENT_BILL_CREATE_SUCCESS:
      return {
        ...state,
        shipmentBills: [...state.shipmentBills, ...action.payload]
      };

    case SHIPMENT_BILLS_REQUEST_SUCCESS:
      return {
        ...state,
        shipmentBills: action.payload.results
      };

    case SHIPMENT_BILLS_REQUEST_ERROR:
      return {
        ...state,
        shipmentBills: []
      };

    case FETCH_QUICKBOOKS_VENDORS:
      return {
        ...state,
        quickBooksCarriers: action.payload
      };

    case FETCH_EXPENSE_CLASSES:
      return {
        ...state,
        expenseClasses: action.payload,
        expenseClassesByName: action.payload.results.reduce(
          (values, result) => ({...values, [result.name]: result}),
          {}
        )
      };
    case FETCH_EXPENSE_ACCOUNTS:
      return {
        ...state,
        expenseAccounts: action.payload,
        expenseAccountsByName: action.payload.results.reduce(
          (values, result) => ({...values, [result.name]: result}),
          {}
        )
      };

    case FETCH_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload
      };

    case FEDEX_REGISTRATION_SUCCESS:
      return {
        ...state,
        fedexAccount: action.payload
      };
    case GET_TRIUMPH_PAY_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        isTriumphPayAuthed: true
      };

    case GET_CONNECTED_FMS:
      return {
        ...state,
        connectedFms: action.payload.configured_integrations.map((fms) => fms.name)
      };

    default:
      return {
        ...state
      };
  }
}
