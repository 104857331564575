import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {LoadboardName} from '@shipwell/tabula-sdk';
import {getExternalAuthService} from 'App/api/loadboards';
import {EXTERNAL_AUTH_SERVICE} from 'App/data-hooks/queryKeys';

const useGetExternalAuthService = (loadboardType: LoadboardName, options?: UseQueryOptions<string>) => {
  const response = useQuery(
    [EXTERNAL_AUTH_SERVICE] as QueryKey,
    async () => {
      const response = await getExternalAuthService(loadboardType);
      return response.data.url;
    },
    options
  );

  return response;
};

export default useGetExternalAuthService;
