import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ExternalDocumentsListResponse} from '@shipwell/corrogo-sdk';
import {
  DocumentType,
  UpdateDocumentVariablesType,
  DeleteDocumentVariablesType,
  mergeUpdateDocument,
  mergeDeleteDocument
} from 'App/api/corrogo/utils/mergeDataFunctions';
import {deleteTransportationOrderDocument, updateTransportationOrderDocument} from 'App/api/corrogo/typed';
import {TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

type ErrorHandlerType = (error: unknown) => void;
type VariablesType = DeleteDocumentVariablesType | UpdateDocumentVariablesType;
type MergeDataType = (documents?: DocumentType[], variables?: VariablesType) => DocumentType[];

export default function useUpdateTransportationOrderDocuments(shipmentId: string) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DocumentType[], Error, VariablesType>();

  const createShipmentDocumentsOptimisticUpdateHandlers = ({
    errorHandler,
    mergeData
  }: {
    errorHandler: ErrorHandlerType;
    mergeData: MergeDataType;
  }) =>
    createOptimisticUpdateHandlers([TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY, shipmentId], {errorHandler, mergeData});

  const deleteTransportationOrderDocumentMutation = useMutation<
    Awaited<ReturnType<typeof deleteTransportationOrderDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    DeleteDocumentVariablesType & {transportationOrderId: string}
  >(
    ({transportationOrderId, documentId}) => deleteTransportationOrderDocument(transportationOrderId, documentId),
    createShipmentDocumentsOptimisticUpdateHandlers({
      errorHandler: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error deleting a transportation order document', message);
      },
      mergeData: mergeDeleteDocument
    })
  );

  const updateTransportationOrderDocumentMutation = useMutation<
    Awaited<ReturnType<typeof updateTransportationOrderDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    UpdateDocumentVariablesType & {transportationOrderId: string}
  >(
    ({transportationOrderId, documentId, description, type}) =>
      updateTransportationOrderDocument(transportationOrderId, documentId, description, type),
    createShipmentDocumentsOptimisticUpdateHandlers({
      errorHandler: (error: unknown) => {
        const {message} = error as Error;
        console.error('Error updating transportaion order document', message);
      },
      mergeData: mergeUpdateDocument
    })
  );

  return {
    deleteTransportationOrderDocumentMutation,
    updateTransportationOrderDocumentMutation,

    isLoading:
      deleteTransportationOrderDocumentMutation.isLoading || updateTransportationOrderDocumentMutation.isLoading
  };
}
