/* eslint-disable @typescript-eslint/no-explicit-any */
import {FormikConfig, FormikValues, useFormik} from 'formik';
import {get} from 'lodash';
import {useEffect, useState} from 'react';
import {PathedObj} from './pathedObj';

export function useEnhancedFormik<Values extends FormikValues = FormikValues>(props: FormikConfig<Values>) {
  const [shouldValidate, setShouldValidate] = useState(false);

  const form = useFormik({
    validateOnBlur: shouldValidate,
    validateOnChange: shouldValidate,
    ...props
  });

  function getBasicProps(path: PathedObj<Values>) {
    const props = {
      name: path as string,
      error: get(form.errors, path) as any,
      value: get(form.values, path) as any,
      onChange: (evtOrValue: any) => {
        let finalValue;

        if (evtOrValue?.target?.value !== undefined) finalValue = evtOrValue?.target?.value;
        else if (evtOrValue?.target?.checked !== undefined) finalValue = evtOrValue?.target?.checked;
        else finalValue = evtOrValue;

        void form.setFieldValue(path as string, finalValue);
      }
    };

    return props;
  }

  useEffect(() => {
    if (form.submitCount > 0) {
      setShouldValidate(true);
    }
  }, [form.submitCount]);

  return {
    ...form,
    getBasicProps
  };
}
