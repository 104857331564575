import {useQuery} from '@tanstack/react-query';
import {getSuppliers} from 'App/api/suppliers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetSuppliers = (filters: {q: string}) => {
  const {data, status, isInitialLoading} = useQuery([SUPPLIERS_QUERY_KEY, filters], async () => getSuppliers(filters), {
    keepPreviousData: true
  });

  return {paginatedSuppliers: data, status, isInitialLoading};
};
