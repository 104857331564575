import {CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CustomData} from '../../types';

export const getCustomDataValueByFieldType = (
  customData: CustomData,
  customField: CustomField,
  chargeLineItemsCustomFieldsFix?: boolean
) => {
  if (customField.field_type === 'STRING') return customData[customField.name] || '--';

  if (customField.field_type === 'SELECTION') {
    const allowedValue = customField.allowed_values?.find(
      (allowedValue) =>
        (chargeLineItemsCustomFieldsFix ? allowedValue.label : allowedValue.value) === customData[customField.name]
    );
    return allowedValue?.label || '--';
  }

  return '--';
};
