import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import {InputGroup, Button, FormGroup, FormControl} from 'react-bootstrap';
import * as actions from 'App/actions/carriers';
import * as shipmentActions from 'App/actions/shipments';
import {loadGoogleMapsAPI, numberWithCommas} from 'App/utils/globals';
import {options} from 'App/utils/map_options';
import './_track.scss';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {milesToKmConversion} from 'App/utils/internationalConstants';

@connect(
  (state) => ({
    isLoading: state.carriers.isLoading,
    powerUnitLocations: state.carriers.powerUnitLocations,
    company: state.auth.company,
    powerUnits: state.carriers.powerUnits,
    shipments: state.shipments.all
  }),
  {...actions, ...shipmentActions}
)
export default class Track extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.getLocations = this.getLocations.bind(this);
    this.triggerRefresh = this.triggerRefresh.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.putMarkersOnMap = this.putMarkersOnMap.bind(this);
    this.initialize = this.initialize.bind(this);
    this.getPowerUnits = this.getPowerUnits.bind(this);
    this.clickShipment = this.clickShipment.bind(this);
    this.setMarkerBounce = this.setMarkerBounce.bind(this);
    this.removeMarkerBounce = this.removeMarkerBounce.bind(this);
    this.renderInfoWindowText = this.renderInfoWindowText.bind(this);
    this.state = {
      searchValue: '',
      address: '',
      markerArray: [],
      locationArray: [],
      displayMarkerArray: [],
      mapTimeout: undefined,
      powerUnitPagination: {
        page: 1,
        pageSize: 200,
        ordering: 'name'
      },
      showLegend: false
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.getLocations();
      this.getPowerUnits();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company && !_.isEmpty(this.props.company)) {
      this.getLocations();
      this.getPowerUnits();
    }

    if (JSON.stringify(this.props.filteredPULocations) !== JSON.stringify(prevProps.filteredPULocations)) {
      this.getLocations();
    }
    if (prevProps.activeInfoTab !== this.props.activeInfoTab) {
      this.setState({markerArray: [], displayMarkerArray: []});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.mapTimeout);
  }

  getPowerUnits() {
    if (this.props.company?.carrier?.id) {
      this.props.getPowerUnits(this.props.company.carrier.id, this.state.powerUnitPagination);
    }
  }

  getLocations() {
    const markerArray = [];
    for (let i = 0; i < this.props.filteredPULocations.length; i++) {
      const vehicle = this.props.filteredPULocations[i];
      vehicle.i = i;
      markerArray.push(vehicle);
    }
    this.setState(
      {markerArray: markerArray},
      function () {
        if (typeof google !== 'undefined') {
          this.initialize(this.triggerRefresh);
        } else {
          loadGoogleMapsAPI(() => {
            this.initialize(this.triggerRefresh);
          });
        }
      }.bind(this)
    );
  }

  initialize(callback) {
    if (this.refs.mapTrack) {
      this.map = new google.maps.Map(this.refs.mapTrack, options);
    }
    window.geocoder = new google.maps.Geocoder();
    window.infowindow = new google.maps.InfoWindow(options.infoWindow);
    google.maps.event.addListener(this.map, 'click', function (event) {
      window.infowindow.close();
    });
    callback();
  }

  triggerRefresh() {
    const mapTimeout = setTimeout(
      function () {
        google.maps.event.trigger(this.map, 'resize');
        if (this.state.markerArray && this.state.markerArray.length > 0) {
          this.resetMap();
          this.putMarkersOnMap(this.state.markerArray);
        } else {
          this.resetMap();
        }
        this.setState({refreshMap: false});
      }.bind(this),
      1500
    );
    this.setState({mapTimeout: mapTimeout});
  }

  infoWindow;

  putMarkersOnMap(markerArray) {
    for (let i = 0; i < this.state.displayMarkerArray.length; i++) {
      this.state.displayMarkerArray[i].setMap(null);
    }
    //put the markers on the map
    const mapBounds = new google.maps.LatLngBounds();
    const markers = this.state.displayMarkerArray;

    for (let i = 0; i < markerArray.length; i++) {
      let icon;
      icon = {
        url: '/images/img/truck_map_icons/truck-2.png'
      };
      const marker = new google.maps.Marker({
        position: {lat: markerArray[i].lat, lng: markerArray[i].lon},
        map: this.map,
        icon: icon,
        i: markerArray[i].i
      });
      const assetsContainer = document.getElementsByClassName('track__list-container');
      if (assetsContainer && assetsContainer.length > 0) {
        const assets = assetsContainer[0].childNodes;
        if (assets[marker.i]) {
          marker.addListener(
            'click',
            function () {
              this.props
                .getShipments({
                  page: 1,
                  pageSize: 5,
                  ordering: '-pickup',
                  powerUnit: markerArray[i].unit_id
                })
                .then((response) => {
                  assets[marker.i].scrollIntoView({behavior: 'smooth'});
                  this.renderInfoWindowText(markerArray, i, marker);
                });
            }.bind(this)
          );
        }
      }
      if (markerArray[i].labelContent) {
        marker.set('labelContent', markerArray[i].labelContent);
      }
      markers.push(marker);

      mapBounds.extend(marker.position);
    }
    this.setState({displayMarkerArray: markers});
    if (this.map) {
      this.map.fitBounds(mapBounds);
    }
  }

  setMarkerBounce(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    let myIndex;
    for (let i = 0; i < this.state.markerArray.length; i++) {
      if (this.state.markerArray[i].i === index) {
        myIndex = i;
      }
    }
    if (displayMarkerArray[myIndex]) {
      displayMarkerArray[myIndex].setAnimation(google.maps.Animation.BOUNCE);
    }
  }

  removeMarkerBounce(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    let myIndex;
    for (let i = 0; i < this.state.markerArray.length; i++) {
      if (this.state.markerArray[i].i === index) {
        myIndex = i;
      }
    }
    if (displayMarkerArray[myIndex]) {
      displayMarkerArray[myIndex].setAnimation(-1);
    }
  }

  resetMap() {
    if (this.map) {
      //this.map.setCenter(options.center);
      //this.map.setZoom(options.zoom);
      for (let i = 0; i < this.state.markerArray.length; i++) {
        window.geocoder.geocode(
          {
            location: {
              lat: this.state.markerArray[i].lat,
              lng: this.state.markerArray[i].lon
            }
          },
          function (results, status) {
            if (status && status.length > 0) {
              const locationArray = JSON.parse(JSON.stringify(this.state.locationArray));
              const location = status[0].formatted_address;
              locationArray.push({
                location: location,
                vehicle_id: this.state.markerArray[i].vehicle_id
              });
              this.setState({locationArray: locationArray});
            }
          }.bind(this, i)
        );
      }
      for (var i = 0; i < this.state.displayMarkerArray.length; i++) {
        this.state.displayMarkerArray[i].setMap(null);
      }
      this.setState({displayMarkerArray: []});
    }
  }

  clickShipment(e, unit_id) {
    const displayMarkerArray = this.state.displayMarkerArray;
    const markerArray = this.state.markerArray;
    let myIndex;
    for (let i = 0; i < markerArray.length; i++) {
      if (markerArray[i].unit_id === unit_id) {
        myIndex = i;
      }
    }

    const marker = displayMarkerArray[myIndex];
    if (marker) {
      const myMap = window.infowindow.getMap();
      if (myMap) {
        window.infowindow.close(this.map, marker);
      }
      this.map.setZoom(20);
      this.map.panTo(marker.position);
      this.props
        .getShipments({
          page: 1,
          pageSize: 5,
          ordering: '-pickup',
          powerUnit: unit_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.renderInfoWindowText(markerArray, myIndex, marker);
          }
        });
    }
  }

  renderInfoWindowText(markerArray, myIndex, marker) {
    let vehicleName;
    const vehicleLicense = [];
    const makeModel = [];
    let odometer = markerArray[myIndex].odometer ? markerArray[myIndex].odometer.toFixed(2) : 0;
    if (odometer > 0) {
      odometer = odometer.toString().split('.');
      if (odometer.length > 0) {
        odometer[0] = numberWithCommas(odometer[0]);
      }
      odometer = odometer.join('.');
    }
    const speed = markerArray[myIndex].speed ? (markerArray[myIndex].speed / milesToKmConversion).toFixed(2) : 0;
    for (let i = 0; i < this.props.powerUnits.results.length; i++) {
      if (this.props.powerUnits.results[i].external_id === markerArray[myIndex].vehicle_id) {
        if (this.props.powerUnits.results[i].license_plate_state_province) {
          vehicleLicense.push(this.props.powerUnits.results[i].license_plate_state_province);
        }
        if (this.props.powerUnits.results[i].license_plate_number) {
          vehicleLicense.push(this.props.powerUnits.results[i].license_plate_number);
        }
        if (this.props.powerUnits.results[i].make) {
          makeModel.push(this.props.powerUnits.results[i].make);
        }
        if (this.props.powerUnits.results[i].model) {
          makeModel.push(this.props.powerUnits.results[i].model);
        }
        vehicleName = this.props.powerUnits.results[i].name;
      }
    }
    let string =
      '<h4>' +
      vehicleName +
      '</h4>' +
      '<table class="asset-card"><thead><tr><th style="vertical-align:top">#' +
      '</th><th>Name</th></tr></thead><tbody>';
    if (this.props.shipments && this.props.shipments.results.length > 0) {
      for (let i = 0; i < this.props.shipments.results.length; i++) {
        string +=
          '<tr><td style="vertical-align:top">' +
          this.props.shipments.results[i].reference_id +
          '</td><td>' +
          this.props.shipments.results[i].name +
          '</td></tr>';
      }
    } else {
      string += "<tr><td colspan='2'>No prev shipments</td></tr>";
    }
    string += '</tbody></table>';
    window.infowindow.setContent(string);
    setTimeout(
      function () {
        window.infowindow.open(this.map, marker);
      }.bind(this)
    );
  }

  renderSideBar() {
    let powerUnits = [];
    if (this.props.powerUnits) {
      powerUnits = this.props.powerUnits.results;
    }
    return this.props.isLoading ? (
      <ShipwellLoader loading={this.props.isLoading} />
    ) : (
      <Paper className="track__list">
        <div className="legends-container">
          <div className="track__list-toolbar">
            <FormGroup>
              <InputGroup>
                <InputGroup.Button>
                  <Button onClick={this.props.filterPULocations}>
                    <i className="icon icon-Search" />
                  </Button>
                </InputGroup.Button>
                <FormControl
                  name="search"
                  type="text"
                  value={this.props.searchValue}
                  placeholder="Search..."
                  onChange={this.props.searchDataTable}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.props.filterPULocations();
                    }
                  }}
                />
              </InputGroup>
            </FormGroup>
            <div className="track__list-toolbar-btns">
              <div className="legends-container">
                <div
                  className="legends-btn"
                  onClick={() => {
                    this.setState({showLegend: !this.state.showLegend});
                  }}
                >
                  <i className="icon icon-Info" />
                </div>
                <div className={'legends-items' + (this.state.showLegend ? '' : ' hidden')}>
                  <h4>Legend</h4>
                  <div className="legends-item">
                    <img className="pad-right" src="/images/img/truck_map_icons/truck-2.png" alt="truck icon" />
                    <span>Asset</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="track__list-container">
          {this.props.filteredPULocations.length > 0 ? (
            this.props.filteredPULocations.map((el, index) => {
              let name;
              let location;
              for (let i = 0; i < powerUnits.length; i++) {
                if (powerUnits[i].external_id === el.vehicle_id) {
                  name = powerUnits[i].name;
                }
              }
              for (let i = 0; i < this.state.locationArray.length; i++) {
                if (this.state.locationArray[i].vehicle_id === el.vehicle_id) {
                  location = this.state.locationArray[i].location;
                }
              }
              const vehicleLicense = [];
              const makeModel = [];
              let odometer = el.odometer ? el.odometer.toFixed(2) : 0;
              if (odometer > 0) {
                odometer = odometer.toString().split('.');
                if (odometer.length > 0) {
                  odometer[0] = numberWithCommas(odometer[0]);
                }
                odometer = odometer.join('.');
              }
              const speed = el.speed ? (el.speed / milesToKmConversion).toFixed(2) : 0;
              if (this.props.powerUnits) {
                for (let i = 0; i < this.props.powerUnits.results.length; i++) {
                  if (this.props.powerUnits.results[i].external_id === el.vehicle_id) {
                    if (this.props.powerUnits.results[i].license_plate_state_province) {
                      vehicleLicense.push(this.props.powerUnits.results[i].license_plate_state_province);
                    }
                    if (this.props.powerUnits.results[i].license_plate_number) {
                      vehicleLicense.push(this.props.powerUnits.results[i].license_plate_number);
                    }
                    if (this.props.powerUnits.results[i].make) {
                      makeModel.push(this.props.powerUnits.results[i].make);
                    }
                    if (this.props.powerUnits.results[i].model) {
                      makeModel.push(this.props.powerUnits.results[i].model);
                    }
                  }
                }
              }
              return (
                <div
                  key={el.vehicle_id}
                  onClick={(e) => {
                    this.clickShipment(e, el.unit_id);
                  }}
                  onMouseEnter={() => this.setMarkerBounce(index)}
                  onMouseLeave={() => this.removeMarkerBounce(index)}
                  className="track__list-item"
                >
                  <div className="track__list-item-header">
                    <div>
                      <strong>{name ? name : el.vehicle_id}</strong>
                    </div>
                    <div>
                      <div>
                        <strong>{location ? location : el.location_name ? el.location_name : 'Not Available'}</strong>
                      </div>
                      <div>{moment(el.update_time).fromNow()}</div>
                    </div>
                  </div>
                  <div className="track__list-item-body">
                    <div>
                      <div>License</div>
                      <div>{vehicleLicense.join(' - ')}</div>
                    </div>
                    <div>
                      <div>Odometer</div>
                      <div>{odometer} miles</div>
                    </div>
                    <div>
                      <div>Speed</div>
                      <div>{speed} mph</div>
                    </div>
                    <div>
                      <div>Make/Model</div>
                      <div>{makeModel.join(' ')}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-units">No units</div>
          )}
        </div>
      </Paper>
    );
  }

  render() {
    return (
      <div className="track">
        {this.renderSideBar()}

        <Paper className="track__map">
          <div id="mapTrack" ref="mapTrack" />
        </Paper>
      </div>
    );
  }
}
