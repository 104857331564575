import {
  ContractRateCurrencyEnum,
  DocumentMetadata,
  ContractRateTypeEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikCheckbox, FormikSelect, FormikTextInput, FormikTextarea} from '@shipwell/shipwell-ui';
import {Field, useFormikContext} from 'formik';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import {ContractFormValues} from 'App/formComponents/formSections/contractFields/constants';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';
import {getSLAs, CorrectedServiceLevelAgreement} from 'App/api/serviceLevelAgreements/typed';
import debounce from 'debounce-promise';

const threeDigitPostalCodeOptionalValues = ['state_province'];

// Fields shared across the two forms are abstracted out here
export const OriginsField = ({disabled}: {disabled?: boolean}) => {
  return (
    <Field
      searchAddressBook
      searchMaps
      searchStateProvince
      searchCountry
      searchThreeDigitPostalCode
      threeDigitPostalCodeOptionalValues={threeDigitPostalCodeOptionalValues}
      label="Origins"
      name="origins"
      allowCreate
      component={MultiAddressSearch}
      ignoreFieldWarnings={['postal_code']}
      required
      disabled={disabled}
    />
  );
};

export const DestinationsField = ({disabled}: {disabled?: boolean}) => {
  return (
    <Field
      searchAddressBook
      searchMaps
      searchStateProvince
      searchCountry
      searchThreeDigitPostalCode
      threeDigitPostalCodeOptionalValues={threeDigitPostalCodeOptionalValues}
      label="Destinations"
      name="destinations"
      allowCreate
      component={MultiAddressSearch}
      ignoreFieldWarnings={['postal_code']}
      required
      disabled={disabled}
    />
  );
};

export const RateField = ({required, disabled}: {required?: boolean; disabled?: boolean}) => {
  return <Field required={required} label="Rate" name="rate" component={FormikTextInput} disabled={disabled} />;
};

export const RateTypeField = ({
  name = 'rate_type',
  required,
  disabled
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const {values} = useFormikContext<ContractFormValues>();
  const isRateTable = values?.rate_type === ContractRateTypeEnum.RateTable;

  return (
    <Field
      simpleValue
      clearable={false}
      options={
        isRateTable
          ? [{value: ContractRateTypeEnum.RateTable, label: 'Rate Table'}]
          : [
              {value: ContractRateTypeEnum.PerMile, label: 'Per Mile'},
              {value: ContractRateTypeEnum.FlatRate, label: 'Flat Rate'},
              {value: ContractRateTypeEnum.PerHour, label: 'Hourly Rate'}
            ]
      }
      label="Rate Type"
      component={FormikSelect}
      name={name}
      required={required}
      disabled={isRateTable || disabled}
    />
  );
};

export const RateCurrencyField = () => {
  return (
    <Field
      simpleValue
      clearable={false}
      required
      label="Currency"
      name="rate_currency"
      component={FormikSelect}
      options={enumToOptionConverter(ContractRateCurrencyEnum, 'default')}
    />
  );
};

export const MinimumRateField = ({disabled}: {disabled?: boolean}) => (
  <Field
    required={false}
    label="Minimum Total Rate"
    name="minimum_rate"
    component={FormikTextInput}
    disabled={disabled}
  />
);

export const FuelIncludedField = ({name = 'fuel_included', disabled}: {name?: string; disabled?: boolean}) => {
  return <Field label="Fuel Included" name={name} component={FormikCheckbox} disabled={disabled} />;
};

export const DistanceSystemField = ({name = 'distance_estimation_system'}) => {
  return <Field required={false} label="Distance System & Version" name={name} component={FormikTextInput} />;
};

export const AdditionalStopChargeField = ({name = 'additional_stop_charge_flat_fee'}) => {
  return <Field required={false} label="Additional Stop Charge" name={name} component={FormikTextInput} />;
};

// keep this outside of the component
const debouncedGetServiceLevelAgreements = debounce(async (q?: string) => {
  const response = await getSLAs({q});
  return response.results || [];
}, 300);
export const ServiceLevelAgreementField = ({
  name = 'service_level_agreement',
  disabled
}: {
  name?: string;
  disabled?: boolean;
}) => {
  return (
    <Field
      async
      name={name}
      label="Service Level Agreement"
      required={false}
      disabled={disabled}
      getOptionLabel={(option: CorrectedServiceLevelAgreement) => option.name}
      getOptionValue={(option: CorrectedServiceLevelAgreement) => option.id}
      loadOptions={debouncedGetServiceLevelAgreements}
      component={FormikSelect}
      defaultOptions
    />
  );
};

export const NotesField = ({name = 'notes', disabled}: {name?: string; disabled?: boolean}) => {
  return <Field label="Notes" name={name} component={FormikTextarea} disabled={disabled} />;
};

export const DocumentsField = ({
  documents,
  name = 'documents',
  disabled
}: {
  documents: DocumentMetadata[];
  name?: string;
  disabled?: boolean;
}) => {
  return (
    <Field
      isMulti
      required={false}
      options={documents}
      getOptionLabel={(option: DocumentMetadata) => option.description}
      getOptionValue={(option: DocumentMetadata) => option.id}
      label="Documents"
      name={name}
      component={FormikSelect}
      disabled={disabled}
    />
  );
};
