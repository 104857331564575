import {useState} from 'react';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {InstantRatesModal} from 'App/containers/InstantRatesV2/components/InstantRatesModal';
import InstantRatesTableV2 from 'App/containers/InstantRatesV2/components/InstantRatesTable';
import {Shipment} from '@shipwell/backend-core-sdk';

export interface NewShipmentFormValues {
  transportation_mode?: string;
  equipment?: unknown;
  line_items?: unknown[];
  stops?: unknown[];
}

interface Props {
  isNewShipmentForm?: boolean;
  newShipmentFormValues?: NewShipmentFormValues;
  shipment: Shipment;
}

export const DigitalFreightMarketplaceRatesCardV2 = ({isNewShipmentForm, newShipmentFormValues, shipment}: Props) => {
  const [showInstantRatesModal, setInstantRatesModal] = useState(false);

  return (
    <>
      <Card draggableProvided={false} title="Instant Rates" bodyClassName="p-0" isCollapsible>
        <CollapsibleCardContent>
          <InstantRatesTableV2
            shipment={shipment}
            viewAllColumns={false}
            isNewShipmentForm={isNewShipmentForm}
            expandedColumnSet
            newShipmentInformation={newShipmentFormValues}
          />
        </CollapsibleCardContent>
      </Card>

      <InstantRatesModal
        shipment={shipment}
        show={showInstantRatesModal}
        onClose={() => setInstantRatesModal(false)}
        newShipmentInformation={newShipmentFormValues}
      />
    </>
  );
};
