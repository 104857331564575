import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {FreightInvoiceRuleConfiguration} from '@shipwell/backend-core-sdk';
import {getInvoiceRuleConfigurations} from '../../api/invoicing/typed';
import {INVOICE_RULE_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const useInvoiceRuleConfigurations = (
  shipmentId: string,
  invoiceSource: 'carrier_generated' | 'shipper_generated',
  queryOptions?: UseQueryOptions<FreightInvoiceRuleConfiguration[]>
) => {
  const getInvoiceRuleConfigurationsQuery = useQuery(
    [INVOICE_RULE_CONFIGURATIONS_QUERY_KEY, shipmentId] as QueryKey,
    async () => {
      if (!shipmentId) {
        return Promise.reject('Missing shipment id');
      }
      const response = await getInvoiceRuleConfigurations(shipmentId, invoiceSource);
      return response.data.data;
    },
    {enabled: !!shipmentId, staleTime: HOT_DATA_STALE_TIME, ...queryOptions}
  );
  return getInvoiceRuleConfigurationsQuery;
};
