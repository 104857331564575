import {Title} from '@shipwell/shipwell-ui';

import ExternalFooter from 'App/common/externalFooter';

const ErrorPage = ({primaryMessage, secondaryMessage}: {primaryMessage: string; secondaryMessage: string}) => (
  <div className="flex flex-col justify-between h-screen-minus-20">
    <div className="m-0 flex justify-between md:m-8 md:mr-16 md:mt-0">
      <div className="flex grow flex-col justify-center p-4 md:p-16">
        <img
          src={localStorage.getItem('whiteLabelLogo') ?? '/images/svgs/shipwell-logo.svg'}
          alt="Logo"
          className="mb-8 h-10 w-56 md:mb-16"
        />
        <div className="mb-8 flex self-center md:tw-hidden">
          <img
            src="/images/shipping-containers.png"
            alt="Shipping Containers"
            className="block size-full max-h-96 max-w-xs px-8 lg:hidden"
          />
        </div>
        <div>
          <Title className="pb-4 text-3xl text-sw-secondary lg:text-4xl">{primaryMessage}</Title>
          <div className="text-xl font-medium">{secondaryMessage}</div>
        </div>
      </div>
      <div className="m-0 flex-col p-0 tw-hidden md:flex">
        <img src="/images/shipping-containers.png" alt="Shipping Containers" className="w-full h-screen-minus-45" />
      </div>
    </div>
    {!localStorage.getItem('whiteLabelLogo') ? <ExternalFooter showLoginPolicy={false} /> : null}
  </div>
);

export default ErrorPage;
