import {JSX, useCallback} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {compose} from 'recompose';
import {WithRouterProps} from 'react-router';
import {FieldArray, Form, Formik, FormikHelpers, FormikProps} from 'formik';
import classNames from 'classnames';
import {Loader, SvgIcon, Card, DeprecatedButton} from '@shipwell/shipwell-ui';
import {FacilityPointOfContact, FacilityPointOfContactRole} from '@shipwell/tempus-sdk';

import {facilityContactsValidationSchema} from '../schema';
import {ContactDraft} from 'App/data-hooks/facilities/types';
import {useFacilityQuery, useFacilityPerms, useMutableFacilityPointsOfContact} from 'App/data-hooks';

import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import FacilityContactFields from 'App/formComponents/formSections/facilityContactFields';
import {generateKey} from 'App/utils/generateKey';
import ScrollToError from 'App/formComponents/formSections/scrollToError';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {parseV3ApiError} from 'App/api/typedUtils';
import Error404Page from 'App/common/Error404Page';
import ErrorPage from 'App/common/ErrorPage';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {useSmoothFlag} from 'App/utils/hooks/useSmoothFlag';

const NewContact = {
  person_name: '',
  email: '',
  phone_number: '',
  facility_role: '' as FacilityPointOfContactRole,
  key: '',
  is_default_for_facility: false
} as ContactDraft;

const FacilityContact = ({
  canUpdate,
  canDelete,
  index,
  remove
}: {
  index: number;
  remove: (index: number) => unknown;
  canUpdate: boolean;
  canDelete: boolean;
}): JSX.Element => {
  const onClick = useCallback(() => remove(index), [remove, index]);
  return (
    <Card
      className="my-4"
      draggableProvided={false}
      title={`Contact ${index + 1}`}
      actions={<>{canDelete ? <SvgIcon name="TrashOutlined" onClick={onClick} tabIndex={1} /> : null}</>}
    >
      <FacilityContactFields currentFormIndex={index} isEditable={canUpdate} tabIndex={1} />
    </Card>
  );
};

export type FormValuesType = {facilityContacts: (FacilityPointOfContact | ContactDraft)[]};

type FacilityContactsProps = WithRouterProps<{id: string}> & WithStatusToastProps;
const FacilityContacts = ({params: {id: facilityId}, setError, setSuccess}: FacilityContactsProps): JSX.Element => {
  const {contacts: perms} = useFacilityPerms();
  const {facility, isFacilityLoading} = useFacilityQuery({
    facilityId
  });

  const onError = useCallback(
    (error: Error) => {
      const formattedError = parseV3ApiError(error);
      if (formattedError.title && formattedError.detail) {
        setError(formattedError.title, formattedError.detail);
      } else if (formattedError.isError) {
        console.error('Unable to format contacts update error:', error);
        setError('Contacts update failed', 'Unrecognized internal error.');
      }
    },
    [setError]
  );

  const onSuccess = useCallback(() => {
    console.log('Update successful.');
    setSuccess(
      'Update successful.',
      `Successfully updated contacts for facility ${facility?.name ?? ''}`,
      'top-right',
      {delay: 3500}
    );
  }, [setSuccess, facility]);

  const {
    contacts,
    isLoading: areContactsLoading,
    error,
    mutate,
    isMutating
  } = useMutableFacilityPointsOfContact(facilityId, {onError, onSuccess});

  const showInitialLoading = isFacilityLoading || (areContactsLoading && contacts.length === 0);

  const isUpdatingUnobtrusively = useSmoothFlag(750, 0, (areContactsLoading || isMutating) && !showInitialLoading);

  const onSubmit = useCallback(
    (values: FormValuesType, {resetForm}: FormikHelpers<FormValuesType>) => {
      const {facilityContacts} = values;
      resetForm({values: {facilityContacts}});
      mutate(facilityContacts);
    },
    [mutate]
  );

  const formattedError = parseV3ApiError(error);

  if (formattedError.is404) {
    return <Error404Page />;
  }
  if (formattedError.isError && !formattedError.is4xx) {
    /* Note that for 4xx errors we'll have already showed a toast and client side validation ought to make problems
        clear when necessary. */
    return <ErrorPage primaryMessage={formattedError.title} secondaryMessage={formattedError.detail} />;
  }
  if (showInitialLoading) {
    return <Loader show>Loading Facility Points of Contact...</Loader>;
  }

  return (
    <Formik
      validateOnMount
      initialValues={{facilityContacts: contacts} as FormValuesType}
      validationSchema={facilityContactsValidationSchema}
      onSubmit={onSubmit}
    >
      {({isValid, dirty, isSubmitting, values: {facilityContacts}, errors, resetForm}: FormikProps<FormValuesType>) => (
        <Form role="form">
          <ScrollToError errors={errors} isSubmitting={isSubmitting} />
          <FieldArray name="facilityContacts">
            {({push, remove}) => (
              <>
                <div className="grid h-full grid-flow-row">
                  <h2 className="sw-page-header border-b border-sw-border pb-3">{facility?.name}</h2>
                  <Card
                    title="Contacts"
                    draggableProvided={false}
                    actions={
                      <div className="flex min-h-4 min-w-max flex-row">
                        <div className="flex size-10 content-center justify-center">
                          {isUpdatingUnobtrusively ? <CircularProgress className="m-auto flex" /> : null}
                        </div>
                        <DeprecatedButton
                          className={classNames('mt-1 flex pl-6', {'text-sw-primary': perms.canCreate})}
                          disabled={!perms.canCreate}
                          onClick={() => push({...NewContact, key: generateKey()})}
                          variant="tertiary"
                          icon={<SvgIcon name="AddCircleOutlined" />}
                        >
                          Add Contact
                        </DeprecatedButton>
                      </div>
                    }
                  >
                    {facilityContacts.map((contact, index) => (
                      <FacilityContact
                        key={'key' in contact ? contact.key : contact.id}
                        index={index}
                        remove={remove}
                        canDelete={perms.canDelete && facilityContacts.length > 1}
                        canUpdate={perms.canUpdate}
                      />
                    ))}
                  </Card>
                  {dirty ? <div className="flex h-12" /> : null}
                  {dirty ? (
                    <FormFooter
                      secondaryActionName="Reset"
                      onCancel={resetForm}
                      dirty={dirty && isValid}
                      isSubmitting={isSubmitting}
                    />
                  ) : null}
                </div>
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

const ConditionalFacilityContacts = compose<FacilityContactsProps, Partial<FacilityContactsProps>>(
  withFlags('fiDockScheduling'),
  withConditionalFallback(({fiDockScheduling}: {fiDockScheduling: boolean}) => !fiDockScheduling, Error404Page),
  WithStatusToasts
)(FacilityContacts);

export default ConditionalFacilityContacts;
