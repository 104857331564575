import {ReactNode} from 'react';
import {StepStatusEnum} from './stepStatusEnum';
import {GeneralStatus} from './generalStatus/generalStatus';
import {StepStatus} from './stepStatus/stepStatus';

import './styles.scss';

export interface MetaInfo {
  title: ReactNode;
  content: ReactNode;
}

export interface StepInfo {
  status: StepStatusEnum;
  content: ReactNode;
  extraInfo?: ReactNode;
}

export interface ItemWithStepsCardProps {
  title: ReactNode;
  metaInfos: MetaInfo[];
  stepsInfo: StepInfo[];
  status: StepStatusEnum;
  isColumnLayout?: boolean;
}

export function ItemWithStepsCard(props: ItemWithStepsCardProps) {
  return (
    <section
      aria-label="item with steps"
      className="itemWithStepsCard rounded-md border border-sw-border bg-sw-background-component drop-shadow"
    >
      <div className={`contentWrap`}>
        {/* Meta infos */}
        <section aria-label="meta info">
          <div className="flex flex-col justify-between">
            <div className="whitespace-normal">{props.title}</div>
            <div className="flex flex-col gap-1 pt-4 text-xs">
              {props.metaInfos.map((info, idx) => (
                <div key={idx}>
                  <strong>{info.title}:</strong> {info.content}
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Divider */}
        <div className="firstDivider" aria-hidden="true" />

        {/* StepStatus */}
        <section aria-label="item steps">
          <div>
            {props.stepsInfo.map((step, idx) => (
              <StepStatus key={idx} stepInfo={step} />
            ))}
          </div>
        </section>

        {/* Divider */}
        <div className="secondDivider" aria-hidden="true" />

        {/* GeneralStatus */}
        <section aria-label="general status">
          <GeneralStatus status={props.status} />
        </section>
      </div>
    </section>
  );
}
