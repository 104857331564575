import {Field} from 'formik';
import _ from 'lodash';
import {FormikSelect, FormikTextInput, Tooltip} from '@shipwell/shipwell-ui';
import {carriers} from 'App/api';
import {formatCarrierPOCs} from 'App/utils/carrierHelpers';
import StepTimerField from 'App/formComponents/formSections/stepTimerField';

const BroadcastFields = (props) => {
  const {index, setFieldValue, values} = props;

  const searchTags = async (input) => {
    try {
      const response = await carriers.getCarrierTagsPromise({name: input});
      return response.body.results;
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  const handleTagSelect = async (tag) => {
    if (tag) {
      try {
        const response = await carriers.getCarrierRelationshipsPromise({pageSize: 10000, tags: [tag.id]});
        if (response.body.results.length > 0) {
          const formValues = {...values.actions[index]};
          const currentSelections =
            values.actions[index].carriers && values.actions[index].carriers.length > 0
              ? [...values.actions[index].carriers]
              : [];
          const newSelections = formatCarrierPOCs(response.body.results);

          formValues.carriers = _.uniqBy([...currentSelections, ...newSelections], (e) => {
            return e.id;
          });
          //clear out tags
          formValues.carriers_by_tag = null;
          // populate the carriers Select with the result
          setFieldValue(`actions[${index}]`, formValues);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCarrierRelationships = async (input, index) => {
    try {
      const response = await carriers.getCarrierRelationshipsPromise({q: input});

      let POCResults = formatCarrierPOCs(response.body.results);
      //if we have any POCs selected already, filter down the POCs to ones with matching carrier
      if (
        values.actions[index].involved_tender_to_company_users &&
        values.actions[index].involved_tender_to_company_users.length > 0
      ) {
        POCResults = POCResults.filter(
          (e) => e.carrier === values.actions[index].involved_tender_to_company_users[0].carrier
        );
      }
      return POCResults;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="grid-item-12">
        <Field
          label="Carriers By Tag"
          loadOptions={searchTags}
          getOptionLabel={(e) => e.name}
          async
          name={`actions[${index}].carriers_by_tag`}
          component={FormikSelect}
          onChange={handleTagSelect}
        />
      </div>
      <div className="grid-item-1-12">
        <Field
          name={`actions[${index}].carriers`}
          async
          isMulti
          required
          label="Carriers"
          component={FormikSelect}
          loadOptions={(val) => getCarrierRelationships(val, index)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
        />
      </div>
      <div className="grid-item-1-11">
        <Field label="Book Now Rate" name={`actions[${index}].buy_it_now_amount`} component={FormikTextInput} />
      </div>
      <div className="grid-item-12-12 routingGuides__form-action-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="routingGuides__form-action-tooltips"
          tooltipContent={
            <div>
              <p>
                <b>Book Now Rate</b>
              </p>
              <p>If you set a book now rate here it will override the book now rate on the shipment when accepted.</p>
            </div>
          }
        >
          <span className="material-icons">info_outline</span>
        </Tooltip>
      </div>
      <div className="grid-item-1-11">
        <StepTimerField name={`actions[${index}].step_timer`} />
      </div>
      <div className="grid-item-12-12 routingGuides__form-action-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="routingGuides__form-action-tooltips"
          tooltipContent={
            <div>
              <p>
                <b>Step Timer</b>
              </p>
              <p>This is the amount of time that will pass before your workflow moves on to the next step.</p>
            </div>
          }
        >
          <span className="material-icons">info_outline</span>
        </Tooltip>
      </div>
      <div className="grid-item-1-12">
        <Field
          label="Special Instructions"
          name={`actions[${index}].special_instructions`}
          component={FormikTextInput}
        />
      </div>
    </>
  );
};

export default BroadcastFields;
