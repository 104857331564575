import {useQuery} from '@tanstack/react-query';
import {EQUIPMENT_TYPES_QUERY_KEY} from '../data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from './queryConstants';
import {fetchEquipmentTypesPromise} from 'App/api/shipment/typed';

export const useEquipmentTypesQuery = () => {
  const getShipmentModesQuery = useQuery(
    [EQUIPMENT_TYPES_QUERY_KEY],
    async () => {
      const response = await fetchEquipmentTypesPromise();
      return response.data;
    },
    {
      staleTime: STATIC_DATA_STALE_TIME
    }
  );

  return getShipmentModesQuery;
};
