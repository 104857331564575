import {ShipwellUiApi, Configuration, ShipmentTimelineFilterPreferences} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createShipwellUiApi() {
  return new ShipwellUiApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
export function createNewEventTemplate(createTimelineEventTemplate = {}) {
  return createShipwellUiApi().shipwellUiTimelineEventTemplatesPost(createTimelineEventTemplate);
}

export interface TimelineEventTemplatesOptions {
  search?: string;
  page?: number;
  pageSize?: number;
  ordering?: string;
}

export function getTimelineEventTemplates(opts: TimelineEventTemplatesOptions = {}) {
  return createShipwellUiApi().shipwellUiTimelineEventTemplatesGet(
    opts.search,
    opts.page,
    opts.pageSize,
    opts.ordering
  );
}

export function deleteTimelineEventTemplate(templateId: string) {
  return createShipwellUiApi().shipwellUiTimelineEventTemplatesTemplateIdDelete(templateId);
}
export function getTimelineFilterPreferences() {
  return createShipwellUiApi().shipwellUiTimelineFilterPreferencesGet();
}

export function updateTimelineFilterPreferences(opts: ShipmentTimelineFilterPreferences) {
  return createShipwellUiApi().shipwellUiTimelineFilterPreferencesPut(opts);
}
