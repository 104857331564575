import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ShipmentCurrentWorkflowExecutionInfo} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getWorkflowExecutions} from 'App/api/shipment/typed';
import {WORKFLOW_EXECUTIONS} from 'App/data-hooks/queryKeys';

export const useGetWorkflowExecutions = ({
  shipmentIds,
  options
}: {
  shipmentIds: string[];
  options?: Omit<
    UseQueryOptions<
      ShipmentCurrentWorkflowExecutionInfo[],
      unknown,
      ShipmentCurrentWorkflowExecutionInfo[],
      (string | string[])[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const workflowExecutionsQuery = useQuery(
    [WORKFLOW_EXECUTIONS, 'list', shipmentIds],
    () => getWorkflowExecutions(shipmentIds),
    {
      enabled: !!shipmentIds.length,
      ...options
    }
  );

  const {data: workflowExecutionsData, status: workflowExecutionsStatus} = workflowExecutionsQuery;
  return {
    workflowExecutionsData,
    workflowExecutionsStatus,
    workflowExecutionsQuery
  };
};
