import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose, branch} from 'recompose';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {Loader} from '@shipwell/shipwell-ui';
import {
  createActionUserMePending,
  createActionUserMeSuccess,
  createActionUserMeFailure
} from 'App/containers/userProfile/actions/async';
import {userToLDUser, ldIdentifyAsync} from 'App/utils/launchDarkly';
import {getSubdomain} from 'App/utils/location';
import {useUserMe} from 'App/data-hooks';
import AccessDeniedError from 'App/containers/authentication/components/AccessDeniedError';

const RequireAuth = (ComposedComponent) => {
  const AuthorizationWrapper = (props) => {
    const {userPerms, routes, router, dispatch, hasIdentifiedUser} = props;
    const ldClient = useLDClient();
    const useUserMeQuery = useUserMe({
      enabled: !hasIdentifiedUser,
      refetchOnMount: 'always',
      onSuccess(data) {
        dispatch(createActionUserMeSuccess(data));
        if (localStorage.getItem('swimpersonator')) {
          ldIdentifyAsync(ldClient, userToLDUser(data.user, data.company));
        }
      },
      onError() {
        dispatch(createActionUserMeFailure());
      }
    });

    useEffect(() => {
      if (useUserMeQuery.isInitialLoading) {
        dispatch(createActionUserMePending());
      }
    }, [useUserMeQuery.isInitialLoading, dispatch]);

    useEffect(() => {
      const checkPermissions = () => {
        const currentRoute = routes[routes.length - 1];
        const routePerms = currentRoute.auth || [];

        // check permissions on the route against the user
        const hasPermission = arrayContainsArray(userPerms, routePerms);

        // if the user doesn't have permission to access this route, take them back to the dashboard
        if (!hasPermission) {
          router.push('/');
        }
      };

      if (userPerms) {
        checkPermissions();
      }
    }, [routes, userPerms, router]);

    // Returns TRUE if the first array contains all elements
    // from the second one OR if empty array. FALSE otherwise.
    // @param {array} superset
    // @param {array} subset
    // @returns {boolean}
    const arrayContainsArray = (superset, subset) => {
      return subset.every((value) => {
        return superset.indexOf(value) >= 0;
      });
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return useUserMeQuery.isInitialLoading ? <Loader show /> : <ComposedComponent {...props} />;
  };

  AuthorizationWrapper.propTypes = {
    hasIdentifiedUser: PropTypes.bool,
    userPerms: PropTypes.array,
    router: PropTypes.object,
    routes: PropTypes.array,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    }),
    dispatch: PropTypes.func
  };

  const withAuthenticationRequiredOptions = (Component) => {
    const AuthenticationWrapper = (props) => {
      const {brokerLogos} = props;
      const {isAuthenticated} = useAuth0();
      const ComponentWithAuthenticationRequired = isAuthenticated
        ? Component
        : withAuthenticationRequired(Component, {
            loginOptions: {
              company: localStorage.getItem('whiteLabelTitle'),
              logo: brokerLogos.find((logo) => logo.image_type === 'INLINE_COLOR').logo,
              favIcon: brokerLogos.find((logo) => logo.image_type === 'LOGO_COLOR').logo,
              subdomain: getSubdomain()
            }
          });

      const searchParams = new URLSearchParams(window.location.search);
      if (!isAuthenticated && searchParams.get('error') === 'access_denied') {
        return (
          <div className="flex h-screen items-center">
            <AccessDeniedError />
          </div>
        );
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComponentWithAuthenticationRequired {...props} />;
    };

    AuthenticationWrapper.propTypes = {
      brokerLogos: PropTypes.arrayOf(
        PropTypes.shape({
          image_type: PropTypes.string,
          logo: PropTypes.string
        })
      )
    };

    return AuthenticationWrapper;
  };

  return compose(
    connect((state) => ({
      hasIdentifiedUser: state.userProfile.authenticated,
      userPerms: state.userProfile.user.permissions,
      brokerLogos: state.brokers.brokerLogos
    })),
    branch(() => !localStorage.getItem('swimpersonator'), withAuthenticationRequiredOptions)
  )(AuthorizationWrapper);
};

export default RequireAuth;
