import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import {
  FacilityHoliday,
  CreateFacilityHoliday,
  UpdateFacilityHoliday,
  StandardScheduledHoliday
} from '@shipwell/tempus-sdk';

import {OTHER, WEEKLY_DEFAULT_CLOSE_TIME, WEEKLY_DEFAULT_OPEN_TIME} from './constants';
import {FacilityHolidayEntry} from './types';

import {generateKey} from 'App/utils/generateKey';

export function newFacilityHolidayEntry(): FacilityHolidayEntry {
  return {
    key: generateKey(),
    date: '',
    isCustom: true,
    name: '',
    closedAllDay: false,
    firstAppointmentStartTime: WEEKLY_DEFAULT_OPEN_TIME,
    lastAppointmentEndTime: WEEKLY_DEFAULT_CLOSE_TIME,
    standard: OTHER
  } as FacilityHolidayEntry;
}

export function standardScheduledHolidayToEntry(standardHoliday: StandardScheduledHoliday): FacilityHolidayEntry {
  const {human_readable_holiday_name: name, date, standard_holiday_name: standard} = standardHoliday;
  if (standard === OTHER) {
    return {
      date,
      key: generateKey(),
      isCustom: true,
      name,
      closedAllDay: false,
      firstAppointmentStartTime: WEEKLY_DEFAULT_OPEN_TIME,
      lastAppointmentEndTime: WEEKLY_DEFAULT_CLOSE_TIME,
      standard: OTHER
    };
  }
  return {
    date,
    key: generateKey(),
    isCustom: false,
    standard,
    name,
    closedAllDay: false,
    firstAppointmentStartTime: WEEKLY_DEFAULT_OPEN_TIME,
    lastAppointmentEndTime: WEEKLY_DEFAULT_CLOSE_TIME
  };
}

export const facilityHolidayToEntry =
  (
    options: {
      label: string;
      value: string;
      standardHoliday: StandardScheduledHoliday;
    }[]
  ) =>
  (fh: FacilityHoliday): FacilityHolidayEntry => {
    let name: string;
    let date: string;

    if (fh.is_custom) {
      name = fh.custom_holiday_name ?? '';
      date = fh.custom_holiday_date ?? '';
    } else {
      const standard = fh.standard_holiday_name ?? OTHER;
      const option = options.find((o) => o.value === standard);
      name = option?.standardHoliday.human_readable_holiday_name ?? '';
      date = option?.standardHoliday.date ?? '';
    }

    return {
      id: fh.id,
      name,
      date,
      closedAllDay: fh.closed_all_day,
      ...(fh.closed_all_day
        ? {}
        : {
            firstAppointmentStartTime: fh.first_appointment_start_time ?? '',
            lastAppointmentEndTime: fh.last_appointment_end_time ?? ''
          }),
      isCustom: fh.is_custom,
      ...(fh.is_custom ? {standard: OTHER} : {standard: fh.standard_holiday_name ?? OTHER})
    } as FacilityHolidayEntry;
  };

export function entryToUpdateFacilityHoliday(entry: FacilityHolidayEntry): {
  id: string;
  update: UpdateFacilityHoliday;
} {
  if (!('id' in entry)) {
    throw new Error('Only Facility Holidays with back end generated IDs can be updated.');
  }
  const {id} = entry;
  const update: Partial<UpdateFacilityHoliday> = {
    closed_all_day: entry.closedAllDay
  };
  if (!entry.closedAllDay) {
    update.first_appointment_start_time = entry.firstAppointmentStartTime;
    update.last_appointment_end_time = entry.lastAppointmentEndTime;
  }
  if (entry.isCustom) {
    update.custom_holiday_name = entry.name;
    update.custom_holiday_date = entry.date;
  } else {
    update.standard_holiday_name = entry.standard;
  }
  return {id, update: update as UpdateFacilityHoliday};
}

export function entryToCreateFacilityHoliday(entry: FacilityHolidayEntry): CreateFacilityHoliday {
  const {isCustom, closedAllDay} = entry;
  const result: CreateFacilityHoliday = {
    closed_all_day: closedAllDay
  };

  if (isCustom) {
    result.custom_holiday_name = entry.name;
    result.custom_holiday_date = entry.date;
  } else {
    result.standard_holiday_name = entry.standard;
  }

  if (!closedAllDay) {
    result.first_appointment_start_time = entry.firstAppointmentStartTime;
    result.last_appointment_end_time = entry.lastAppointmentEndTime;
  }
  return result;
}

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

export function createFacilityHolidayToFacilityHoliday(
  facilityId: string,
  create: CreateFacilityHoliday
): FacilityHoliday {
  const {
    standard_holiday_name,
    custom_holiday_name,
    custom_holiday_date,
    first_appointment_start_time,
    last_appointment_end_time,
    closed_all_day
  } = create;
  const date = formatLocalDate(today);
  const holiday: Partial<FacilityHoliday> = {
    id: generateKey(),
    facility_id: facilityId,
    closed_all_day,
    is_custom: standard_holiday_name == null,
    created_at: date,
    updated_at: date
  };
  if (holiday.is_custom) {
    holiday.custom_holiday_name = custom_holiday_name;
    holiday.custom_holiday_date = custom_holiday_date;
  } else {
    holiday.standard_holiday_name = standard_holiday_name;
  }
  if (!closed_all_day) {
    holiday.first_appointment_start_time = first_appointment_start_time;
    holiday.last_appointment_end_time = last_appointment_end_time;
  }
  return holiday as FacilityHoliday;
}

function isUpdate(fh: FacilityHoliday, update: UpdateFacilityHoliday) {
  return (
    update.standard_holiday_name !== fh.standard_holiday_name ||
    update.custom_holiday_name !== fh.custom_holiday_name ||
    update.custom_holiday_date !== fh.custom_holiday_date ||
    update.closed_all_day !== fh.closed_all_day ||
    update.first_appointment_start_time !== fh.first_appointment_start_time ||
    update.last_appointment_end_time !== fh.last_appointment_end_time
  );
}

export function indexById<T extends {id: string}>(array: T[]): Record<string, T> {
  return keyBy(array, (item) => item.id);
}

export function computeFacilityHolidayUpdates(
  entries: FacilityHolidayEntry[],
  fetchedFacilityHolidays: FacilityHoliday[]
): {
  toCreate: CreateFacilityHoliday[];
  toUpdate: {id: string; update: UpdateFacilityHoliday}[];
  toDelete: string[];
} {
  const holidaysById = indexById(fetchedFacilityHolidays);
  const [keyEntries, idEntries] = partition(entries, (e) => 'key' in e);
  const toCreate = keyEntries.map(entryToCreateFacilityHoliday);
  const toUpdate = idEntries
    .map(entryToUpdateFacilityHoliday)
    .filter(({id, update}) => isUpdate(holidaysById[id], update));
  const toDelete = Object.keys(holidaysById).filter((id) => !entries.some((e) => 'id' in e && e.id === id));
  return {toCreate, toUpdate, toDelete};
}

export function updateFetchedFacilityHolidays(
  fetched: FacilityHoliday[],
  created: FacilityHoliday[],
  updated: FacilityHoliday[],
  toDelete: string[]
): FacilityHoliday[] {
  return fetched
    .filter((h) => !toDelete.includes(h.id))
    .map((h) => updated.find((u) => u.id === h.id) ?? h)
    .concat(created);
}

export function formatLocalDate(date: Date): string {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()].map((n) => n.toString().padStart(2, '0')).join('-');
}

export function formatLocalTime(date: Date): string {
  return [date.getHours(), date.getMinutes() + 1, date.getSeconds()]
    .map((n) => n.toString().padStart(2, '0'))
    .join(':');
}

export function formatLocalDateTime(date: Date): string {
  const [y, mon, d, h, min, s] = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ].map((n) => n.toString().padStart(2, '0'));
  return `${y}-${mon}-${d}T${h}:${min}:${s}`;
}
