import {Formik, Field, Form} from 'formik';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {object, string} from 'yup';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {timelineEventTypes} from 'App/utils/globals';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {postBreadcrumbPromise} from 'App/api/shipment';
import withStatusToasts from 'App/components/withStatusToasts';

const TimelineEventForm = ({initialValues, shipment, handleCancel, onSuccess, setError}) => {
  const handleSubmit = async (values, {setSubmitting}) => {
    setSubmitting(true);
    try {
      const location = {
        lat: values.location?.latitude,
        lon: values.location?.longitude,
        update_time: new Date(),
        address: convertToELDAddress(values.location),
        event_description: values.description
      };
      await postBreadcrumbPromise(shipment.id, {locations: [location]});
      onSuccess();
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const convertToELDAddress = (address) => {
    return {
      city: address.city || null,
      country: address.country || null,
      postal_code: address.postal_code || null,
      state: address.state_province || null
    };
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={object().shape({
        event_type: string(),
        description: string().nullable().required('A description is required.'),
        location: object()
          .required('A valid location is required.')
          .test('location', 'A valid location is required.', (value) => {
            return !isEmpty(value);
          })
      })}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({isSubmitting}) => (
        <Form className="field-grid grid-1-1 mb-14 overflow-visible">
          <Field
            label="Event Type"
            name="event_type"
            disabled //in the current use case (not tracking), this field is always disabled
            options={timelineEventTypes.map((type) => {
              return {label: type.name, value: type.id};
            })}
            simpleValue
            component={FormikSelect}
          />
          <Field
            required
            searchAddressBook={false}
            label="Location/Zip Code"
            name="location"
            component={AddressSearch}
          />
          <Field required label="Description" name="description" component={FormikTextInput} />

          <ModalFormFooter isSubmitting={isSubmitting} onCancel={handleCancel} />
        </Form>
      )}
    </Formik>
  );
};

TimelineEventForm.propTypes = {
  initialValues: PropTypes.object,
  shipment: PropTypes.object,
  handleCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  setError: PropTypes.func
};

TimelineEventForm.defaultProps = {
  onSuccess: () => {}
};

export default withStatusToasts(TimelineEventForm);
