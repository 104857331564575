import {AriaAttributes, MouseEvent, ReactNode, TouchEvent} from 'react';
import {SortDirection} from '@tanstack/react-table';
import classnames from 'classnames';
import {TableDragHandle} from './TableDragHandle';

const ariaSortMap = {
  asc: 'ascending',
  desc: 'descending',
  none: 'none'
} as const;

const getAriaSort = (sortDirection: SortDirection | false): AriaAttributes['aria-sort'] =>
  ariaSortMap[sortDirection || 'none'];

export function TableHeader({
  children,
  isFixed = false,
  width,
  sortDirection = false,
  onSort,
  onResize
}: {
  children?: ReactNode;
  isFixed?: boolean;
  width?: number;
  onResize?: (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
  sortDirection?: SortDirection | false;
  onSort?: (event: unknown) => void;
}) {
  return (
    <th
      aria-sort={getAriaSort(sortDirection)}
      className={classnames(
        'relative whitespace-nowrap rounded-tl border-b border-sw-border-light bg-sw-background-component font-normal text-sw-text last:rounded-tr last:border-r-0',
        {
          'sticky left-0 z-10 border-r-2': isFixed,
          'border-r-1': !isFixed,
          'p-2': !onSort
        }
      )}
      style={{width}}
    >
      {onSort ? (
        <button onClick={onSort} className="size-full p-2">
          {children}
        </button>
      ) : (
        children
      )}
      {onResize ? <TableDragHandle resizeHandler={onResize} /> : null}
    </th>
  );
}
