import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router';
import {SvgIcon, Toast} from '@shipwell/shipwell-ui';
import {resetPassword} from 'App/actions/_users';
import ForgotPasswordForm from 'App/formComponents/forms/ForgotPassword';
import withCompanyBrand from 'App/components/withCompanyBrand';
import './styles.scss';

const ForgotPasswordContainer = ({router, location, resetPassword}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const successRedirect = '/';

  const handleSubmit = async (values, {setSubmitting}) => {
    setShowSuccess(false);
    try {
      const response = await resetPassword(values.email);

      if (response?.ok) {
        setSubmitting(false);
        handleSuccess();
      }
    } catch (error) {
      if (error?.error_description) {
        setError(error.error_description);
      }
      console.error(error);
    }
  };

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      router.push(successRedirect);
    }, 3000);
  };

  return (
    <div className="forgot-password">
      <div className="forgot-password__wrapper">
        <div className="forgot-password__welcome-text">
          <strong>Forgot Password?</strong>
          <p>Enter your email below and we&apos;ll send you a link to reset your password.</p>
        </div>
        <ForgotPasswordForm onSubmit={handleSubmit} />
        <div className="forgot-password__actions">
          <Link to="/">Back to Sign In</Link>
        </div>
        {showSuccess && (
          <div className="forgot-password__success">
            <SvgIcon name="CheckCircleFilled" color="$sw-success" />
            We sent a link to the email address you provided.
          </div>
        )}
      </div>
      <Toast show={error} variant="error">
        {error}
      </Toast>
    </div>
  );
};

ForgotPasswordContainer.propTypes = {
  company: PropTypes.string,
  resetPassword: PropTypes.func
};

ForgotPasswordContainer.defaultProps = {
  company: ''
};

export default compose(connect(null, {resetPassword}), withCompanyBrand)(ForgotPasswordContainer);
