import {SpotNegotiationPreview} from '@shipwell/backend-core-sdk';

const RequestBidsMessagePreview = ({
  specialInstructions,
  spotNegotiationEmailPreview,
  loadId
}: {
  specialInstructions: string;
  spotNegotiationEmailPreview?: SpotNegotiationPreview;
  loadId?: string | null;
}) => {
  let emailToShow = '';

  if (spotNegotiationEmailPreview && spotNegotiationEmailPreview?.message && spotNegotiationEmailPreview.template) {
    emailToShow = spotNegotiationEmailPreview.template.replace('{{ message }}', spotNegotiationEmailPreview.message);
  } else if (spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
    emailToShow = spotNegotiationEmailPreview.template;
  }

  if (loadId && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
    emailToShow = emailToShow.replace('{{ load_id }}', loadId);
  }

  if (specialInstructions && spotNegotiationEmailPreview && spotNegotiationEmailPreview.template) {
    emailToShow = emailToShow.replace('{{ special_instructions }}', specialInstructions);
  } else {
    emailToShow = emailToShow.replace('{{ special_instructions }}', 'None');
  }

  //remove links from buttons
  emailToShow = emailToShow.replace(/{{ url }}/g, '#');
  emailToShow = emailToShow.replace(/target="_blank"/g, '');

  return (
    <div>
      {spotNegotiationEmailPreview && (
        <div
          dangerouslySetInnerHTML={{
            __html: emailToShow
          }}
        />
      )}
    </div>
  );
};

export default RequestBidsMessagePreview;
