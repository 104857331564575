import {useMutation, useQueryClient} from '@tanstack/react-query';
import isNumber from 'lodash/isNumber';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {
  CreateShipmentTimelineEvent as CreateShipmentTimelineEventType,
  ShipmentTimelineEventType,
  CreateStageTimelineEvent,
  StageTimelineEventType,
  CreateTrackingPoint
} from '@shipwell/corrogo-sdk';
import {
  createShipmentTimelineEvent,
  createServiceTimelineEvent,
  deleteShipmentTimelineEvent,
  deleteLegTimelineEvent,
  deleteServiceTimelineEvent,
  createServiceTrackingPoint
} from 'App/api/corrogo/typed';
import {Timeline} from 'App/components/ShipmentTrackingOverview/TimelineContainer/Timeline';
import NewEventModal from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import type {CreateEventValidation} from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import useToggle from 'App/utils/hooks/useToggle';
import {SHIPMENT_TIMELINE_SUMMARY, SHIPMENT_TRACKING_SUMMARY} from 'App/data-hooks/queryKeys';
import {useV3Shipment} from 'App/data-hooks';
import type {ShipmentTimelineSummaryEvent} from 'App/api/corrogo/typed';

export const TimelineContainer = ({shipmentId}: {shipmentId: string}) => {
  const [showNewEventModal, toggleShowNewEventModal] = useToggle();

  const {
    context: {service}
  } = useV3Shipment(shipmentId);

  const queryClient = useQueryClient();
  const createShipmentEventMutation = useMutation<
    Awaited<ReturnType<typeof createShipmentTimelineEvent>>,
    unknown,
    CreateShipmentTimelineEventType
  >((payload) => createShipmentTimelineEvent(shipmentId, payload), {
    onSettled: () => queryClient.invalidateQueries([SHIPMENT_TIMELINE_SUMMARY, shipmentId])
  });
  const createServiceEventMutation = useMutation<
    Awaited<ReturnType<typeof createServiceTimelineEvent>>,
    unknown,
    CreateStageTimelineEvent
  >(
    (payload) => {
      if (!service?.id) {
        return Promise.reject(new Error('Invalid service id when creating timeline event'));
      }
      return createServiceTimelineEvent(service.id, payload);
    },
    {
      onSettled: () => queryClient.invalidateQueries([SHIPMENT_TIMELINE_SUMMARY, shipmentId])
    }
  );
  const createTrackingMutation = useMutation<
    Awaited<ReturnType<typeof createServiceTrackingPoint>>,
    unknown,
    CreateTrackingPoint
  >(
    (payload) => {
      if (!service?.id) {
        return Promise.reject(new Error('Invalid service id when creating tracking point'));
      }
      return createServiceTrackingPoint(service.id, payload);
    },
    {
      onSettled: () => queryClient.invalidateQueries([SHIPMENT_TRACKING_SUMMARY, shipmentId])
    }
  );

  const deleteShipmentTimelineEventMutation = useMutation<
    Awaited<ReturnType<typeof deleteShipmentTimelineEvent>>,
    unknown,
    {eventId: string}
  >(({eventId}) => deleteShipmentTimelineEvent(shipmentId, eventId), {
    onSettled: () => queryClient.invalidateQueries([SHIPMENT_TIMELINE_SUMMARY, shipmentId])
  });

  const deleteLegTimelineEventMutation = useMutation<
    Awaited<ReturnType<typeof deleteLegTimelineEvent>>,
    unknown,
    {legId: string; eventId: string}
  >(({legId, eventId}) => deleteLegTimelineEvent(legId, eventId), {
    onSettled: () => queryClient.invalidateQueries([SHIPMENT_TIMELINE_SUMMARY, shipmentId])
  });

  const deleteServiceTimelineEventMutation = useMutation<
    Awaited<ReturnType<typeof deleteServiceTimelineEvent>>,
    unknown,
    {serviceId: string; eventId: string}
  >(({serviceId, eventId}) => deleteServiceTimelineEvent(serviceId, eventId), {
    onSettled: () => queryClient.invalidateQueries([SHIPMENT_TIMELINE_SUMMARY, shipmentId])
  });

  const handleSubmit = (values: CreateEventValidation) => {
    if (values.place_changed) {
      createServiceEventMutation.mutate({
        event_type: StageTimelineEventType.LocationUpdate,
        description: values.place_changed.formatted_address
          ? `${values.description} - ${values.place_changed.formatted_address}`
          : values.description,
        occurred_at: values.occurred_at
      });
      if (isNumber(values.place_changed.latitude) && isNumber(values.place_changed.longitude)) {
        createTrackingMutation.mutate({
          update_time: values.occurred_at,
          lat: values.place_changed.latitude,
          lon: values.place_changed.longitude
        });
      }
    } else {
      createShipmentEventMutation.mutate({
        description: values.description,
        occurred_at: values.occurred_at,
        event_type: ShipmentTimelineEventType.Info
      });
    }

    toggleShowNewEventModal();
  };

  const handleDeleteEvent = (event: ShipmentTimelineSummaryEvent) => {
    if (event.leg_id) {
      return deleteLegTimelineEventMutation.mutate({legId: event.leg_id, eventId: event.id});
    }
    if (event.service_id) {
      return deleteServiceTimelineEventMutation.mutate({serviceId: event.service_id, eventId: event.id});
    }
    return deleteShipmentTimelineEventMutation.mutate({eventId: event.id});
  };

  return (
    <>
      <div className="sticky top-0 z-10 mb-2 flex items-center bg-sw-background-component">
        <DeprecatedButton className="flex grow" onClick={toggleShowNewEventModal}>
          Add Event
        </DeprecatedButton>
      </div>
      <Timeline
        shipmentId={shipmentId}
        toggleShowEventModal={toggleShowNewEventModal}
        onDeleteEvent={handleDeleteEvent}
      />
      <NewEventModal show={showNewEventModal} onClose={toggleShowNewEventModal} handleSubmit={handleSubmit} />
    </>
  );
};
