import {useQuery} from '@tanstack/react-query';
import {getShipmentTrackingSummary} from 'App/api/corrogo/typed';
import {SHIPMENT_TRACKING_SUMMARY} from 'App/data-hooks/queryKeys';
import {createTrackingPointLocation} from 'App/components/trimbleMap/utils/typed';

function useShipmentTrackingSummary(shipmentId: string) {
  const shipmentTrackingSummaryQuery = useQuery(
    [SHIPMENT_TRACKING_SUMMARY, shipmentId],
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid leg id'));
      }
      const response = await getShipmentTrackingSummary(shipmentId);
      return response.data.data;
    },
    {
      enabled: !!shipmentId
    }
  );

  const trackingLocations =
    shipmentTrackingSummaryQuery.data?.map((tracking) => createTrackingPointLocation(tracking.lat, tracking.lon)) || [];

  return {queryInfo: shipmentTrackingSummaryQuery, trackingLocations};
}

export default useShipmentTrackingSummary;
