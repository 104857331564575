import {
  DeliveryTypeEnum,
  DockSchedulingModeEnum,
  DockSchedulingEquipmentTypeEnum,
  PackagingTypes,
  Facility,
  FacilityServices,
  LocationTypes,
  ReferenceQualifierEnum
} from '@shipwell/tempus-sdk';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';

export const supplierAppointmentFormDataDefaultValues: SupplierAppointmentCreationFormType = {
  appointment_date: new Date().toISOString().slice(0, 10),
  link_appointment: '',
  reference_type: '' as ReferenceQualifierEnum,
  reference_number: '',
  delivery_type: '' as DeliveryTypeEnum,
  load_type: '',
  mode: '' as DockSchedulingModeEnum,
  equipment_type: '' as DockSchedulingEquipmentTypeEnum,
  is_hazmat: undefined,
  stackable: undefined,
  carrier_name: '',
  carrier_email: '',
  carrier_phone_number: '',
  appointment_duration: '',
  notes: ''
};

export const supplierAppointmentLoadTypeDefaultValues = {
  name: '',
  id: '',
  stackable: undefined,
  is_hazmat: undefined,
  load_unload_duration: '',
  appointment_duration: '',
  delivery_type: '' as DeliveryTypeEnum,
  mode: '' as DockSchedulingModeEnum,
  equipment_type: '' as DockSchedulingEquipmentTypeEnum,
  product_reference: '',
  packaging_type: '' as PackagingTypes,
  created_at: '',
  updated_at: '',
  facility_id: '',
  display_name: ''
};

export const supplierAppointmentFacilityDefaultValues: Facility = {
  name: '',
  address: {
    country: '',
    postal_code: '',
    line_1: '',
    line_2: '',
    region: '',
    locality: '',
    timezone: ''
  },
  exclude_dock_assignment_from_appointment_scheduling: false,
  facility_services: [] as FacilityServices[],
  location_type: '' as LocationTypes,
  appointment_lead_time_duration: 'P1DT0H0M0.000000S',
  late_appointment_threshold_duration: 'P0DT0H10M0.000000S',
  id: '',
  created_at: '',
  updated_at: ''
};
