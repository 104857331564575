import isNumber from 'lodash/isNumber';
import {bool, object, string, ref, boolean} from 'yup';
import {validateMinMaxNumber} from 'App/utils/yupHelpersTyped';

export const companyLoadOptimizationValidationSchema = object().shape({
  setting_name: string().required('Setting name required.'),
  //truck settings
  average_truck_speed: validateMinMaxNumber(1, 999999, 'average_truck_speed').required('Average truck speed required.'),
  speed_unit: string().nullable().required('Speed unit required.'),
  //truckload capacity
  minimum_truck_weight: validateMinMaxNumber(1, 100000, 'minimum_truck_weight')
    .required('Minimum truck weight required.')
    .max(ref('maximum_truck_weight'), 'Must be less than maximum truck weight.'),
  maximum_truck_weight: validateMinMaxNumber(1, 100000, 'maximum_truck_weight')
    .required('Maximum truck weight required.')
    .min(ref('minimum_truck_weight'), 'Must not be less than minimum truck weight.'),
  weight_unit: string().required('Weight unit required.').nullable(),
  minimum_truck_volume: validateMinMaxNumber(1, 1000000, 'minimum_truck_volume')
    .nullable()
    .max(ref('maximum_truck_volume'), 'Must be less than maximum truck volume.'),
  maximum_truck_volume: validateMinMaxNumber(1, 1000000, 'maximum_truck_volume')
    .nullable()
    .min(ref('minimum_truck_volume'), 'Must not be less than minimum truck volume.'),
  volume_unit: string().required('Volume unit required').nullable(),
  minimum_package_count: validateMinMaxNumber(1, 1000, 'minimum_pallets')
    .required('Minimum pallets required.')
    .max(ref('maximum_package_count'), 'Must be less than maximum pallets.'),
  maximum_package_count: validateMinMaxNumber(1, 1000, 'maximum_pallets')
    .required('Maximum pallets required.')
    .min(ref('minimum_package_count'), 'Must not be less than minimum pallets.'),
  package_type: string().required('Package type required.').nullable(),
  //stop settings
  minimum_stop_time_seconds: string().nullable().required('Minimum stop time required.'),
  maximum_stops: validateMinMaxNumber(2, 100, 'maximum_stops').required('Maximum stops count required.'),
  stop_charges_dollars: validateMinMaxNumber(1, 999999, 'stop_charges_dollars').required(
    'Stop charges dollars required.'
  ),
  frozen_as_last_stop: boolean().nullable(),
  //facility operating hours
  open_monday: bool(),
  open_tuesday: bool(),
  open_wednesday: bool(),
  open_thursday: bool(),
  open_friday: bool(),
  open_saturday: bool(),
  open_sunday: bool(),

  monday_open_time: string()
    .nullable()
    .when('open_monday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  monday_close_time: string()
    .nullable()
    .when('open_monday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  tuesday_open_time: string()
    .nullable()
    .when('open_tuesday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  tuesday_close_time: string()
    .nullable()
    .when('open_tuesday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  wednesday_open_time: string()
    .nullable()
    .when('open_wednesday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  wednesday_close_time: string()
    .nullable()
    .when('open_wednesday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  thursday_open_time: string()
    .nullable()
    .when('open_thursday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  thursday_close_time: string()
    .nullable()
    .when('open_thursday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  friday_open_time: string()
    .nullable()
    .when('open_friday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  friday_close_time: string()
    .nullable()
    .when('open_friday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  saturday_open_time: string()
    .nullable()
    .when('open_saturday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  saturday_close_time: string()
    .nullable()
    .when('open_saturday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  sunday_open_time: string()
    .nullable()
    .when('open_sunday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),
  sunday_close_time: string()
    .nullable()
    .when('open_sunday', {
      is: (open: boolean) => open,
      then: string().required('A time is required.').typeError('A time is required.')
    }),

  return_to_pickup: bool(),
  //ltl settings
  ltl_freight_class: string().nullable().required('Freight class required.'),
  ltl_discount_percentage: validateMinMaxNumber(0, 99, 'ltl_discount_percentage').required(
    'LTL discount percentage required.'
  ),
  //fleet settings
  maximum_distance_per_route: validateMinMaxNumber(500, 9999, 'maximum_distance_per_route').nullable(),
  distance_unit: string()
    .nullable()
    .when('maximum_distance_per_route', {
      is: (distance: number) => isNumber(distance),
      then: string().required('Distance unit required.').typeError('Distance unit required.')
    }),
  maximum_time_per_route_seconds: string().nullable(),
  max_truck_count: validateMinMaxNumber(1, 999999, 'max_truck_count').nullable(),
  ltl_rate_adjustment_percentage: validateMinMaxNumber(-99, 500, 'ltl_rate_adjustment_percentage').nullable(),
  ftl_rate_adjustment_percentage: validateMinMaxNumber(-99, 500, 'ftl_rate_adjustment_percentage').nullable()
});
