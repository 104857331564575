import {SvgIcon} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum, Reference, ReferenceQualifierEnum} from '@shipwell/tempus-sdk';
import {useMemo} from 'react';
import {Cell} from 'react-table';
import {Link} from 'react-router';
import {AppointmentEntry, CarrierAppointment, TimezoneAwareDateTime} from 'App/data-hooks/appointments/types';
import {configureDate, isToday, isTomorrow, isYesterday} from 'App/utils/dateTimeGlobalsTyped';
import Table from 'App/components/Table';
import {toTitleCase} from 'App/utils/string';
import {AppointmentButtonGroup} from 'App/components/Buttons';

const getReferenceValue = (references: Reference[], qualifier: ReferenceQualifierEnum) => {
  if (!references?.length) {
    return null;
  }
  const filteredReferences = references.filter((ref) => ref.qualifier === qualifier).map(({value}) => value);
  if (!filteredReferences?.length) {
    return null;
  }
  const uniqueReferences = filteredReferences.filter((value, ix, arr) => {
    return arr.indexOf(value) === ix;
  });
  if (uniqueReferences.length === 1) {
    return uniqueReferences[0];
  }
  if (uniqueReferences.length > 1) {
    return `${uniqueReferences[0]} (+${uniqueReferences.length - 1})`;
  }
  return null;
};
const formatDateDisplayValue = (value?: Date | null): string => {
  if (!value) {
    return '--';
  }
  if (isToday(value)) {
    return 'Today';
  }
  if (isTomorrow(value)) {
    return 'Tomorrow';
  }
  if (isYesterday(value)) {
    return 'Yesterday';
  }
  const dateValueFormatted = Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }).format(value); // gives Aug 7, 2023

  return dateValueFormatted;
};

export type CarrierAppointmentTable = {
  appointments: CarrierAppointment[];
  isLoading: boolean;
  onRescheduleClick: (carrierAppointment: CarrierAppointment) => unknown;
  onCancelClick: (carrierAppointment: CarrierAppointment) => unknown;
  onScheduleClick: (carrierAppointment: CarrierAppointment) => unknown;
};

const CarrierAppointmentTable = ({
  appointments,
  isLoading,
  onRescheduleClick,
  onCancelClick,
  onScheduleClick
}: CarrierAppointmentTable) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        canSort: true,
        width: 70,
        Cell: ({value}: Cell<AppointmentEntry, AppointmentStatusEnum>) => {
          if ([AppointmentStatusEnum.Scheduled, AppointmentStatusEnum.Rescheduled].some((status) => status === value)) {
            return (
              <span className="flex flex-row items-center gap-x-1" title="Scheduled">
                <SvgIcon name="Calendar" />
                Scheduled
              </span>
            );
          }
          if (
            [AppointmentStatusEnum.Arrived, AppointmentStatusEnum.ArrivedLate, AppointmentStatusEnum.Completed].some(
              (status) => status === value
            )
          ) {
            return (
              <span className="flex flex-row items-center gap-x-1" title="Completed">
                <SvgIcon name="CheckCircleFilled" />
                Completed
              </span>
            );
          }
          if (value === AppointmentStatusEnum.Cancelled) {
            return (
              <span className="flex flex-row items-center gap-x-1 text-sw-error" title="Cancelled">
                <SvgIcon name="Cancel" />
                Cancelled
              </span>
            );
          }
          return (
            <span className="font-medium text-sw-active" title="Unscheduled">
              Unscheduled
            </span>
          );
        }
      },
      {
        Header: 'Delivery Type',
        accessor: 'deliveryType',
        width: 70,
        Cell: ({value}: Cell<CarrierAppointment, string | null>) => {
          if (!value) {
            return '--';
          }
          return toTitleCase(value);
        }
      },
      {
        Header: 'Planned Date',
        accessor: 'plannedDate',
        canSort: true,
        width: 70,
        Cell: ({value}: Cell<CarrierAppointment, string | null>) => {
          if (!value) {
            return '--';
          }
          // the plannedDate value is in the format 'YYYY-MM-dd' need
          const [year, month, day] = value.split('-');
          if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
            return '--'; // failed to parse on of the dates or all of them.
          }
          // need to create a new date an override the values because straight up parsing the
          // `YYYY-MM-dd` values in the plannedDate may give the wrong date because of a missing
          // timezone information. In this case we just need to display the date for the stop with the
          // correct date.
          const actualPlannedDate = configureDate(new Date(), {
            years: Number(year),
            months: Number(month) - 1, // API uses month indexes at "1"
            days: Number(day) // days are "1" indexed so. starting at "1"
          });

          return formatDateDisplayValue(actualPlannedDate);
        }
      },
      {
        Header: 'Appt. Date',
        accessor: 'start',
        canSort: true,
        width: 70,
        Cell: ({value}: Cell<CarrierAppointment, TimezoneAwareDateTime>) =>
          value ? formatDateDisplayValue(value.original) : '--'
      },
      {
        Header: 'Appt. Time',
        accessor: 'start.time',
        canSort: true,
        width: 70,
        Cell: ({value}: Cell<CarrierAppointment, string>) => {
          return (
            <span className="p-x-1 flex flex-row items-center gap-x-1">
              <SvgIcon name="Time" />
              {value ?? '--'}
            </span>
          );
        }
      },
      {
        Header: 'Facility',
        accessor: 'facility.name',
        canSort: true,
        width: 100
      },
      {
        Header: 'City',
        accessor: 'facility.address.locality',
        canSort: true,
        width: 100
      },
      {
        Header: 'State',
        accessor: 'facility.address.region',
        canSort: true,
        width: 50
      },
      {
        Header: 'Shipment ID',
        canSort: true,
        width: 100,
        Cell: ({row}: Cell<CarrierAppointment>) => {
          const {references = []} = row.original;
          // this column and the shipment id column both use the `references` data which cannot be used as an `accessor`
          // so we have to use the original row data
          if (!references.length) {
            return '--';
          }

          const displayReference = getReferenceValue(references, ReferenceQualifierEnum.ShipmentReferenceId);
          if (!displayReference) {
            return '--';
          }
          if (!row.original.shipmentId) {
            return displayReference;
          }

          return (
            <Link to={`/shipments/${row.original.shipmentId}`} target="_blank">
              {displayReference}
            </Link>
          );
        }
      },
      {
        Header: 'PO #',
        canSort: true,
        width: 100,
        Cell: ({row}: Cell<CarrierAppointment>) => {
          // this column and the shipment id column both use the `references` data which cannot be used as an `accessor`
          // so we have to use the original row data
          const {references = []} = row.original;
          if (!references?.length) {
            return '--';
          }

          const displayReference = getReferenceValue(references, ReferenceQualifierEnum.PoNumber);
          if (!displayReference) {
            return '--';
          }
          return displayReference;
        }
      },
      {
        Header: 'Actions',
        canSort: false,
        width: 120,
        Cell: ({row}: Cell<CarrierAppointment, AppointmentStatusEnum>) => {
          const {status, type} = row.original;
          return (
            <AppointmentButtonGroup
              status={status}
              disabled={type === 'FIRST_COME_FIRST_SERVE'}
              title={type === 'FIRST_COME_FIRST_SERVE' ? 'Cannot schedule FCFS appointments.' : undefined}
              onCancelClick={() => onCancelClick(row.original)}
              onRescheduleClick={() => onRescheduleClick(row.original)}
              onScheduleClick={() => onScheduleClick(row.original)}
            />
          );
        }
      }
    ],
    [onCancelClick, onRescheduleClick, onScheduleClick]
  );

  return <Table data={appointments} columns={columns} dataIsLoading={isLoading} />;
};

export default CarrierAppointmentTable;
