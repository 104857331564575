import {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table-6';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import QuoteDocumentModal from '../QuoteDocument';
import {formatDateTime} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';

export default class LoadBoardQuotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {showForm: false, documentPreviewQuote: null};
  }

  render() {
    const {quotes, handleDelete, shipment, spotNegotiation, isDeletingQuote} = this.props;
    const {documentPreviewQuote} = this.state;

    return (
      <div className="table__container">
        <QuoteDocumentModal
          spotNegotiationId={spotNegotiation?.id}
          spotNegotiationQuoteId={documentPreviewQuote?.id}
          spotNegotiationQuoteDocumentId={documentPreviewQuote?.spot_negotiation_quote.documents[0]?.id}
          onClose={() => this.setState({documentPreviewQuote: null})}
        />
        <ReactTable
          data={quotes}
          showPagination={false}
          minRows={5}
          className="-striped"
          getTrProps={(state, rowInfo = {}) => {
            return {
              className: rowInfo.original && rowInfo.original.accepted_at ? 'awarded' : ''
            };
          }}
          columns={[
            {
              Header: 'Bid',
              id: 'bid',
              accessor: (d) => d.total,
              Cell: (row) => {
                const {documents} = row.original.spot_negotiation_quote;

                return (
                  <div className="flex items-center gap-x-1">
                    {formatCurrency(row.value, row.original.currency)}
                    {documents?.length ? (
                      <DeprecatedButton
                        variant="icon"
                        onClick={() => this.setState({documentPreviewQuote: row.original})}
                        icon={<SvgIcon name="Document" />}
                      />
                    ) : null}
                  </div>
                );
              }
            },
            {
              Header: 'Mode',
              id: 'mode',
              accessor: (d) => d.mode,
              Cell: (row) => row.value?.code || '--'
            },
            {
              Header: 'Equipment',
              id: 'equipment_type',
              accessor: (d) => d.equipment_type,
              Cell: (row) => row.value?.name || '--'
            },
            {
              Header: 'Submitted',
              id: 'submitted',
              accessor: (d) => d.created_at,
              Cell: (row) =>
                row.original.accepted_at ? <span className="text-success">Won</span> : formatDateTime(row.value, true)
            },
            {
              Header: 'Actions',
              id: 'select',
              width: 125,
              accessor: (d) => d,
              Cell: (row) =>
                spotNegotiation?.is_most_recent_winner && row.original.accepted_at ? (
                  <Link to={`/shipments/${shipment.id}`}>View Shipment</Link>
                ) : (
                  <DeprecatedButton size="small" disabled={isDeletingQuote} onClick={() => handleDelete(row.original)}>
                    Retract Bid
                  </DeprecatedButton>
                )
            }
          ]}
        />
      </div>
    );
  }
}

LoadBoardQuotesTable.propTypes = {
  quotes: PropTypes.array,
  handleDelete: PropTypes.func,
  shipment: PropTypes.func,
  spotNegotiation: PropTypes.object,
  isDeletingQuote: PropTypes.bool
};
