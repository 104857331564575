import {
  AmountToleranceBoundaryType,
  AmountToleranceLimitDetails,
  AmountToleranceLimitType,
  DocumentsRequiredDetails,
  InvoiceRuleConfiguration
} from '@shipwell/settlements-sdk';
import {
  isDocumentRequiredRule,
  isDocumentsRequiredRule,
  isFinancialsThresholdRule,
  isInvoiceableStatusRule,
  isServiceProviderNameMatchRule,
  isMultipleInvoicesPerInvoiceableRule,
  InvoiceRule
} from 'App/api/settlements/typeGuards';
import {useDocumentShortNames} from 'App/data-hooks';
import {isDefined} from 'App/utils/guard';

export const InvoiceRuleDescription = ({invoiceRule}: {invoiceRule: InvoiceRuleConfiguration}) => {
  const getDocumentsRequiredRuleDescription = useGetDocumentsRequiredRuleDescription();

  let description = '';

  if (isDocumentsRequiredRule(invoiceRule) || isDocumentRequiredRule(invoiceRule)) {
    description = getDocumentsRequiredRuleDescription(invoiceRule);
  }

  if (isFinancialsThresholdRule(invoiceRule)) {
    description = getFinancialsThresholdDescription(invoiceRule);
  }

  if (isServiceProviderNameMatchRule(invoiceRule)) {
    description = 'The invoicing carrier must match the carrier on the shipment';
  }

  if (isInvoiceableStatusRule(invoiceRule)) {
    description = "Shipments not in 'Delivered' status will trigger an exception";
  }

  if (isMultipleInvoicesPerInvoiceableRule(invoiceRule)) {
    description = 'Multiple invoices for a shipment will trigger an exception';
  }

  //
  return <div>{description}</div>;
};

// TODO remove export once I delete external references
export const boundaryTypeLabels: Record<AmountToleranceBoundaryType, string> = {
  CEILING: 'upper',
  FLOOR: 'lower'
};

export const getFinancialsThresholdDescription = (
  invoiceRule: InvoiceRule<AmountToleranceLimitDetails, 'FINANCIALS_THRESHOLD'>
) => `${getAmount(invoiceRule.details)} ${boundaryTypeLabels[invoiceRule.details.boundary_type]} threshold`;

const useGetDocumentsRequiredRuleDescription = () => {
  const {data: documentShortNames} = useDocumentShortNames();
  return (invoiceRule: InvoiceRule<DocumentsRequiredDetails, 'DOCUMENT_REQUIRED' | 'DOCUMENTS_REQUIRED'>) => {
    if (!documentShortNames) return '';

    return invoiceRule.details.document_types
      .map((id) => documentShortNames[id])
      .filter(isDefined)
      .join(', ');
  };
};

// TODO remove export once I delete external references
export const getAmount = (details: AmountToleranceLimitDetails) => {
  switch (details.limit_type) {
    case AmountToleranceLimitType.MonetaryAmount:
      return `$${details.limit_value}`;
    case AmountToleranceLimitType.Percentage:
      return `${details.limit_value}%`;
    default:
      return details.limit_value;
  }
};
