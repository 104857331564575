import {useState} from 'react';
import {Field} from 'formik';
import {FormikTextarea, FormikFileSelect} from '@shipwell/shipwell-ui';
import {placeholder} from 'App/utils/placeholders';

/**
 * Letter of Release Fields
 * @param {*} props
 */
const LetterOfReleaseFields = () => {
  return (
    <>
      <div className="vendors__lor_margin_bottom">
        <span>
          Before deleting a factoring account, please upload a letter of release to be submitted for approval.
        </span>
      </div>
      <Field
        className="vendors__lor_margin_bottom"
        name="letter_of_release_description"
        required
        type="text"
        component={FormikTextarea}
        placeholder={placeholder.docDescription}
        label="Description"
      />
      <Field name="letter_of_release_file" component={FormikFileSelect} required />
    </>
  );
};
export default LetterOfReleaseFields;
