import {InitialFiltersType, dateFilters} from '../DashboardUtils';
import {tryGetDateFromRelativeDate} from 'App/containers/Dashboard/utils/date/dateUtils';

export function convertFiltersRelativeDateValues(filters: InitialFiltersType) {
  const newFilters = Object.assign({}, filters);

  dateFilters.forEach((field) => {
    newFilters[field] = tryGetDateFromRelativeDate(newFilters[field] as string) as string & never[];
  });

  return newFilters;
}
