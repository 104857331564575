import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {GlobalSettingsResponse} from '@shipwell/tabula-sdk';
import {getGlobalSettings} from 'App/api/loadboards';
import {LOADBOARDS_SETTINGS_KEY} from 'App/data-hooks/queryKeys';

const useGetGlobalSettings = (options?: UseQueryOptions<GlobalSettingsResponse>) => {
  const response = useQuery({
    queryKey: [LOADBOARDS_SETTINGS_KEY] as QueryKey,
    queryFn: async () => {
      const response = await getGlobalSettings();
      return response.data;
    },
    ...options
  });

  return response;
};

export default useGetGlobalSettings;
