import {Component} from 'react';
import {connect} from 'react-redux';
import {Table, Nav, NavItem} from 'react-bootstrap';
import Add_User_Form from './forms/Add_User_Form';
import * as actions from 'App/actions/users';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';

@connect(
  (state) => ({
    allUsers: state.users.allShipperUsers,
    error: state.users.error,
    details: state.users.details,
    shipper: state.users.shipperUser
  }),
  actions
)
export default class User_Permissions extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      updated: false,
      showError: false,
      errorDetails: '',
      tab: 'activeUsers'
    };
  }

  componentDidMount() {
    this.props.fetchAllShipperUsers();
    this.props.fetchShipperUser();
  }

  renderUsersList() {
    if (this.props.allUsers.length > 0) {
      const usersList = this.props.allUsers;
      return (
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Cell</th>
            </tr>
          </thead>
          <tbody>
            {usersList.map((person, index) => {
              return (
                <tr key={person.user.id}>
                  <td>{person.user.first_name + ' ' + person.user.last_name}</td>
                  <td>{person.user.email}</td>
                  <td>{person.cell_number !== 'null' ? person.cell_number : '--'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return <h4>No users associated with this shipper.</h4>;
  }

  // CREATING NEW USER ACCOUNT
  renderAll(obj) {
    this.props.createEmployeeAccount(obj).then(
      function (response) {
        if (response.status === 200) {
          this.setState({updated: true});
          this.props.fetchAllShipperUsers();
          this.renderUsersList();
        } else {
          this.setState({showError: true, errorDetails: response.details});
        }
      }.bind(this)
    );
  }

  handleSubmit(attrs) {
    // CREATING NEW USER ACCOUNT
    attrs.password2 = attrs.password1;
    attrs.shipper_id = this.props.shipper.shipper_id;
    this.renderAll(attrs);
  }

  render() {
    const pageTitle = 'Users';
    return (
      <div>
        <section className="content-header">
          <h2>
            <i className="icon icon-Users" />
            &nbsp;{pageTitle}
          </h2>
        </section>
        <section className="content" style={{minHeight: '0px'}}>
          <Nav bsStyle="tabs" justified activeKey={this.state.tab === 'activeUsers' ? 1 : 2}>
            <NavItem onSelect={() => this.setState({tab: 'activeUsers'})} eventKey={1}>
              <h4>Active Users</h4>
            </NavItem>
            <NavItem onSelect={() => this.setState({tab: 'addNew'})} eventKey={2}>
              <h4>Add New User</h4>
            </NavItem>
          </Nav>
          {this.state.tab === 'activeUsers' && this.renderUsersList()}
          {this.state.tab === 'addNew' && <Add_User_Form onSubmit={this.handleSubmit} updated={this.state.updated} />}

          <InfoModalWrapper
            show={this.state.updated}
            onHide={() => this.setState({updated: false})}
            title="Success"
            children={
              <h4 style={{padding: '30px 10px', textAlign: 'center'}}>
                User created. An activation email has been sent to the address you provided.
              </h4>
            }
          />

          <InfoModalWrapper
            show={this.state.showError}
            onHide={() => this.setState({showError: false, errorDetails: ''})}
            title="Error"
            children={
              <h4
                style={{
                  padding: '30px 10px',
                  textAlign: 'center'
                }}
              >
                {' '}
                <i className="icon icon-Delayed" />
                &nbsp;
                {this.state.errorDetails}
              </h4>
            }
          />
        </section>
      </div>
    );
  }
}
