import {JSX} from 'react';
import {PropsWithChildren} from 'react';
import {Link, WithRouterProps} from 'react-router';
import PageHeader from 'App/common/pageHeader';
import SideNav from 'App/common/sideNav';
import {useFacilityQuery} from 'App/data-hooks';

const FacilityWrapper = ({children}: PropsWithChildren<unknown>): JSX.Element => {
  return <div className="sw-background relative flex flex-1 flex-col h-screen-minus-16">{children}</div>;
};

export declare type FacilitiesWithSidebarProps = PropsWithChildren<WithRouterProps<{id: string}, unknown>>;

export const FacilitiesWithSidebar = ({children, params}: FacilitiesWithSidebarProps): JSX.Element => {
  const facilityId = params.id;
  const {facility} = useFacilityQuery({
    facilityId
  });
  const facilityDocksLinkText =
    facility?.dock_count && facility?.dock_count > 0 ? `Docks (${facility?.dock_count})` : 'Docks';
  return (
    <FacilityWrapper>
      <PageHeader title="Facilities" backRoute="/facilities" />
      <div className="relative flex flex-1 overflow-y-auto bg-sw-background">
        <SideNav>
          <Link
            name="details"
            title="Facility Name Here"
            to={`/facilities/${facilityId}/details`}
            activeClassName="active"
          >
            Facility Info
          </Link>
          <Link name="contacts" title="Contacts" to={`/facilities/${facilityId}/contacts`} activeClassName="active">
            Contacts
          </Link>
          <Link
            name="attributes"
            title="Attributes"
            to={`/facilities/${facilityId}/attributes`}
            activeClassName="active"
          >
            Attributes
          </Link>
          <Link name="loadtypes" title="Load Types" to={`/facilities/${params.id}/load-types`} activeClassName="active">
            Load Types
          </Link>
          <Link name="docks" title="Docks" to={`/facilities/${facilityId}/docks`} activeClassName="active">
            {facilityDocksLinkText}
          </Link>
        </SideNav>
        <div className="size-full px-4 pb-4">{children}</div>
      </div>
    </FacilityWrapper>
  );
};

export default FacilityWrapper;
