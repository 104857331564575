export function toTitleCase(str?: string) {
  if (!str) return '';
  str = str.split('_').join(' ');
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function camelToSentence(camelCaseString?: string, capitalizeAllWords?: boolean): string {
  if (!camelCaseString) {
    return '';
  }

  // split the string at every capital letter
  const words = camelCaseString.split(/(?=[A-Z])/);

  // If capitalizeAllWords is true, capitalize all words, otherwise capitalize only the first word
  if (capitalizeAllWords) {
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  } 
  return (
    words[0][0].toUpperCase() +
    words[0].slice(1).toLowerCase() +
    ' ' +
    words
      .slice(1)
      .map((word) => word.toLowerCase())
      .join(' ')
  );
}


