import {Modal, Popover} from '@shipwell/shipwell-ui';
import useToggle from 'App/utils/hooks/useToggle';
import {useDeleteSupplier} from 'App/data-hooks/suppliers';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {Dispatch, SetStateAction} from 'react';

const ActionsCell = ({
  supplierId,
  setSuccess,
  setError
}: {
  supplierId: string;
  setSuccess: WithStatusToastProps['setSuccess'];
  setError: WithStatusToastProps['setError'];
}) => {
  const [showDeleteSupplierModal, toggleShowDeleteSupplierModal] = useToggle(false);

  return (
    <>
      <Popover
        placement="auto-start"
        trigger={<Popover.IconButton iconName="Overflow" aria-label="Overflow menu" variant="tertiary" />}
      >
        {({setIsOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>}) => {
          return (
            <Popover.MenuList>
              <DeleteSupplierMenuItem
                onClick={() => {
                  setIsOpen(false);
                  toggleShowDeleteSupplierModal();
                }}
              />
            </Popover.MenuList>
          );
        }}
      </Popover>
      <DeleteSupplierModal
        supplierId={supplierId}
        show={showDeleteSupplierModal}
        toggleShow={toggleShowDeleteSupplierModal}
        setSuccess={setSuccess}
        setError={setError}
      />
    </>
  );
};

export default ActionsCell;

const DeleteSupplierMenuItem = ({onClick}: {onClick: () => void}) => (
  <Popover.MenuListItemButton onClick={onClick} isDestructive>
    Delete
  </Popover.MenuListItemButton>
);

const DeleteSupplierModal = ({
  supplierId,
  show,
  toggleShow,
  setSuccess,
  setError
}: {
  supplierId: string;
  show: boolean;
  toggleShow: () => void;
  setSuccess: WithStatusToastProps['setSuccess'];
  setError: WithStatusToastProps['setError'];
}) => {
  const {deleteSupplier} = useDeleteSupplier();
  const onDeleteSupplier = () => {
    deleteSupplier(supplierId, {
      onSuccess: () => {
        toggleShow();
        setSuccess('Success!', 'Supplier was deleted successfully.');
      },
      onError: () => {
        setError('Error Deleting Supplier', 'There was an error deleting the supplier. Please try again.');
      }
    });
  };

  return show ? (
    <Modal
      title="Delete Supplier"
      size="small"
      show
      onClose={toggleShow}
      variant="warning"
      primaryBtnName="Delete Supplier"
      onPrimaryAction={() => onDeleteSupplier()}
    >
      <div className="font-bold">Are you sure you want to delete this supplier?</div>
      <div>Deleting will remove access to the platform for all contacts associated with this supplier.</div>
    </Modal>
  ) : null;
};
