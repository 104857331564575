import {ShipmentChargeLineItem, StandardChargeCode} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Dropdown, InlineGroupSelect, SvgIcon} from '@shipwell/shipwell-ui';
import {useEffect, useState} from 'react';
import {FormGroup} from 'react-bootstrap';
import {
  groupChargeCodesInlineGroupSelect,
  categoryDropdownValue,
  chargeCodesWithNoGroup,
  type OptionsSubCategoryType,
  type OptionMetadataType
} from 'App/utils/chargeCodesHelpers';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';
import {useGetChargeCodes} from 'App/data-hooks';

type OptionType =
  | {
      label: string;
      options:
        | {
            label: string;
            value: string;
            category: string;
            chargeCode: string;
            id: string;
            categoryDisplay: string;
          }[]
        | undefined;
    }
  | {
      label: string;
      value: string;
      category: string;
      chargeCode: string;
      id: string;
      categoryDisplay: string;
    }
  | undefined;

export const InlineGroupDropDownSelector = ({
  input,
  changeField,
  index,
  chargeCodesValues,
  formName,
  mode,
  meta: {touched, error}
}: {
  input: {onChange: (value: string) => void; name: string};
  changeField: (form: string, field: string, value: string) => void;
  index: number;
  chargeCodesValues?: ShipmentChargeLineItem[];
  formName: string;
  mode: string;
  meta: {touched: boolean; error: string};
}) => {
  const [categorySelectValue, setCategorySelectValue] = useState<OptionsSubCategoryType | undefined>();
  const [searchInput, setsearchInput] = useState<string | undefined>();
  const {data: chargeCodes, isLoading: isLoadingChargecodes} = useGetChargeCodes(searchInput);
  const [storedChargeCodes] = useLocalStorage<string[] | null>({
    key: `${mode}-charge-codes`,
    initialValue: null
  });
  const fullStoredChargeCodes =
    (!isLoadingChargecodes ? chargeCodes?.data?.filter((code) => storedChargeCodes?.includes(code.code)) : []) ?? [];
  const options = groupChargeCodesInlineGroupSelect(chargeCodes?.data);
  const [hasChargeCode, setHasChargeCode] = useState<boolean>(!!chargeCodesValues?.[index].charge_code);

  const handleOnRecentSelect = (chargeCode: StandardChargeCode) => {
    changeField(formName, `financials['${index}'].unit_name`, chargeCode.name);
    changeField(formName, `financials['${index}'].charge_code`, chargeCode.code);
    setCategorySelectValue({
      category: chargeCode.category,
      categoryDisplay: chargeCode.category_display,
      chargeCode: chargeCode.code,
      id: chargeCode.id,
      label: `${chargeCode.code} - ${chargeCode.name}`,
      value: chargeCode.id
    });
    setHasChargeCode(true);
    input.onChange(chargeCode.category);
  };

  useEffect(() => {
    if (options.length > 0 && chargeCodesValues) {
      setHasChargeCode(!!chargeCodesValues?.[index].charge_code);
      setCategorySelectValue(
        // @ts-expect-error Even checking if we have a unit_name this still throws an error in the compilation.
        categoryDropdownValue(options, chargeCodesValues[index].charge_code, chargeCodesValues[index].unit_name)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingChargecodes]);

  const formatOptionLabel = ({label, categoryDisplay}: OptionMetadataType, {context}: {context: string}) => {
    const displayValue = hasChargeCode ? label : `N/A ${label.split(' - ')[1]}`;
    return context === 'value' ? (
      <div>{`${displayValue} (${categoryDisplay})`}</div>
    ) : (
      context === 'menu' && <div>{label}</div>
    );
  };

  const handleInputChange = (inputValue: string) => {
    setsearchInput(inputValue);
  };

  const handleOnChange = (event: OptionMetadataType) => {
    changeField(formName, `financials['${index}'].unit_name`, event?.label.split(' - ')[1].split(' (')[0]);
    changeField(formName, `financials['${index}'].charge_code`, event?.chargeCode);
    setCategorySelectValue(categoryDropdownValue(options, event.chargeCode, event.label));
    setHasChargeCode(true);
    input.onChange(event?.category);
  };

  return (
    <FormGroup controlId={input.name} validationState={touched && error ? 'error' : null}>
      <div className="grid items-start" style={{gridTemplateColumns: storedChargeCodes?.length ? '50px 1fr' : '1fr'}}>
        {storedChargeCodes?.length ? (
          <Dropdown
            className="mb-[15px] h-[34px] border border-[#dadada] border-solid rounded-l-md [&_button]:hover:bg-transparent"
            variant="icon"
            icon={<SvgIcon color="$sw-icon" name="Time" />}
            disabled={isLoadingChargecodes}
            portal
            inModal
          >
            {({onClick}: {onClick(): void}) => (
              <>
                {fullStoredChargeCodes.map((code) => {
                  return (
                    <li
                      key={code.code}
                      className="min-w-[320px] px-3"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnRecentSelect(code);
                        onClick();
                      }}
                    >{`${code.code} - ${code.name} (${code.category_display})`}</li>
                  );
                })}
              </>
            )}
          </Dropdown>
        ) : null}
        <div className={`max-h-[36px] ${storedChargeCodes?.length ? 'rounded-r-md' : 'rounded-md'}`}>
          <InlineGroupSelect
            menuClassName="!w-[110%]"
            menuListClassName="!max-h-[176px]"
            isLoading={isLoadingChargecodes}
            value={categorySelectValue}
            className=""
            placeholder="Type to Search"
            options={searchInput && !isLoadingChargecodes ? chargeCodesWithNoGroup(chargeCodes?.data) : options}
            formatOptionLabel={formatOptionLabel}
            clearable={false}
            onInputChange={handleInputChange}
            onChange={handleOnChange}
            filterOption={(option: OptionType) => {
              return option;
            }}
          />
        </div>
      </div>
      {touched && error && (
        <p className="error-text">
          <i className="icon icon-Delayed" />
          {error}
        </p>
      )}
    </FormGroup>
  );
};
