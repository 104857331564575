import {ReactNode} from 'react';
import classnames from 'classnames';
import useToggle from 'App/utils/hooks/useToggle';

function MenuToggle({name, children, defaultOpen = false}: {name: string; children: ReactNode; defaultOpen?: boolean}) {
  const [open, toggleOpen] = useToggle(defaultOpen);
  const icon = <i className="material-icons">{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>;

  return (
    <div>
      <span className="menu-toggle-title flex w-full justify-between" onClick={toggleOpen}>
        {name} {icon}
      </span>
      <div className={classnames('pl-4', {visible: open}, {'tw-hidden': !open})}>{children}</div>
    </div>
  );
}

export default MenuToggle;
