/* eslint-disable max-len */
import {Component} from 'react';
import InputField from '../input';
import {getCarrierPowerUnits} from 'App/api/carriers';
import './styles.scss';

/**
 * Truck Number Field
 */
class TruckNumberField extends Component {
  constructor() {
    super();

    this.state = {
      displayMatchResult: false,
      truckMatched: false,
      searchTerm: null
    };
  }

  static defaultProps = {
    name: 'power_unit',
    placeholder: 'Power Unit',
    label: 'Power Unit',
    value: '',
    input: {},
    carrier: {}
  };

  componentWillUnmount() {
    const {input} = this.props;

    // Clean up Redux store values, when field is removed.
    if (input.onChange) {
      input.onChange(null);
    }
  }

  async getCarrierPowerUnits(carrierId, searchTerm) {
    const {input} = this.props;
    const onChange = input.onChange || this.props.onChange;

    try {
      const response = await getCarrierPowerUnits(carrierId, {powerUnitName: searchTerm});

      if (response && response.body) {
        const powerUnitFound = response.body.total_count > 0;

        this.setState({displayMatchResult: true, truckMatched: powerUnitFound, searchTerm: searchTerm});

        if (powerUnitFound && onChange) {
          onChange(response.body.results[0]);
        } else if (onChange) {
          onChange(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleInputBlur(e) {
    const {carrier, input} = this.props;
    const onChange = input.onChange || this.props.onChange;
    const value = e.target.value;

    this.setState({displayMatchResult: false, truckMatched: false});

    if (value) {
      this.getCarrierPowerUnits(carrier.id, value);
    } else if (onChange) {
      onChange(null);
    }
  }

  render() {
    const {displayMatchResult, truckMatched, searchTerm} = this.state;
    const {req, input, disabled, placeholder, ...rest} = this.props;
    const name = input.name || this.props.name;
    const onChange = input.onChange || this.props.onChange;
    let value = input.value || this.props.value;

    if (value && value.name) {
      value = value.name;
    }

    return (
      <div className="trucknumber-field">
        <InputField
          {...rest}
          red={req}
          value={value}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          tooltip="Tracking begins two hours before planned pickup. You may change this field if the power unit changes."
          onBlur={this.handleInputBlur.bind(this)}
          onChange={(value) => onChange && onChange(value)}
        />
        {value && displayMatchResult && truckMatched && (
          <span className="field-message">
            <span className="success-text">
              <i className="icon icon-Check" /> Power unit found.
            </span>
          </span>
        )}
        {displayMatchResult && !truckMatched && (
          <span className="field-message error-text">
            <i className="icon icon-Delayed" /> Power unit {searchTerm} not found.
          </span>
        )}
      </div>
    );
  }
}

export default TruckNumberField;
