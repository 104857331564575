import {QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {CapacityProviderSchema, ProviderCode, TransportationMode} from '@shipwell/genesis-sdk';
import {AxiosError} from 'axios';
import {COMPANY_INTEGRATION_AMAZON} from 'App/data-hooks/queryKeys';
import {createProviderProvidersPost, getProviderProvidersProviderCodeGet} from 'App/api/integrations/typed';

/**
 * React-Query mutation hook for creating a POST request to the integration API
 * for connecting Amazon Freight as a possible Carrier.
 *
 * The Mutation hook as a dependency on formik and getting the values through a
 * form submit callback.
 */
const useAmazonFreightMutation = (options: UseMutationOptions<CapacityProviderSchema, AxiosError, unknown>) =>
  useMutation(
    [COMPANY_INTEGRATION_AMAZON],
    async (accountNumber: string) => {
      const {data} = await createProviderProvidersPost({
        provider_code: ProviderCode.Amazon,
        modes: [TransportationMode.Ftl],
        accounts: [
          {
            provider_code: ProviderCode.Amazon,
            account: {
              code: accountNumber
            }
          }
        ]
      });
      return data;
    },
    options
  );

/**
 * Get Amazon Freight status connection by user.
 */

const useGetAmazonFreightQuery = (options: UseQueryOptions<CapacityProviderSchema, AxiosError>) => {
  const getAmazonFreightQuery = useQuery(
    [COMPANY_INTEGRATION_AMAZON] as QueryKey,
    async () => {
      const response = await getProviderProvidersProviderCodeGet(ProviderCode.Amazon);
      return response.data;
    },
    options
  );
  return getAmazonFreightQuery?.data?.accounts?.[0] as CapacityProviderSchema;
};

export {useAmazonFreightMutation, useGetAmazonFreightQuery};
