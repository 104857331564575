import {ReactNode} from 'react';

export const TableFiltersTab = ({children, footer}: {children: ReactNode; footer?: ReactNode}) => {
  return (
    <div className="relative flex h-full flex-col overflow-y-auto">
      <div className="h-full overflow-y-auto">{children}</div>
      {footer}
    </div>
  );
};
