import {PostCompaniesCompanyidUsers, User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {COMPANY_USERS} from '../queryKeys';
import {updateUser} from 'App/api/user/typed';

export const useUpdateUser = (
  companyId: string,
  options?: Omit<
    UseMutationOptions<User, unknown, {userId: string; userDetails: PostCompaniesCompanyidUsers}, unknown>,
    'mutationFn'
  >
) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    ({userId, userDetails}: {userId: string; userDetails: PostCompaniesCompanyidUsers}) =>
      updateUser({companyId, userId, body: userDetails}),
    {
      ...options,
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries([COMPANY_USERS]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );
  return {updateUser: mutate, isUpdatingUser: isLoading};
};
