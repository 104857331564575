export const columnIds = {
  order_number: {id: 'order_number', label: 'Order #'},
  name: {id: 'name', label: 'Order Name'},
  origin_address: {id: 'origin_address', label: 'Origin'},
  destination_address: {id: 'destination_address', label: 'Destination'},
  customer_name: {id: 'customer_name', label: 'Customer'},
  supplier_name: {id: 'supplier_name', label: 'Supplier'},
  total_line_item_weight: {id: 'total_line_item_weight', label: 'Weight'},
  total_packages: {id: 'total_packages', label: 'Pallets'}
};
