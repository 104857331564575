import {useMutation, useQuery, useQueryClient, UseQueryOptions, UseMutationOptions} from '@tanstack/react-query';
import {
  DocumentMetadata,
  FreightInvoiceDocument,
  FreightInvoiceStatus,
  UpdateAddToDeliveredDashboardActionDetailsRuleTypeEnum,
  FreightInvoicesApiCreateFreightInvoiceDocumentRequest,
  ConfigDetails
} from '@shipwell/settlements-sdk';
import {AxiosError} from 'axios';
import {
  bulkUpdateSettlementsConfigurationsStatus,
  getSettlementsConfigurations,
  createFreightInvoiceDocument,
  getFreightInvoiceDocuments,
  updateAddToDeliveredDashboardConfiguration,
  updateFreightInvoiceStatus,
  updateRequiredDocumentInvoiceRuleConfiguration,
  updateAutoGenerateInvoiceConfiguration,
  updateFreightInvoiceDocument
} from 'App/api/settlements';
import {
  FREIGHT_INVOICES_QUERY_KEY,
  FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY,
  INVOICE_DOCUMENTS_KEY,
  INVOICE_RULE_CONFIGURATIONS_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export default function useUpdateFreightInvoiceStatus(shipmentId?: string) {
  const queryClient = useQueryClient();

  return useMutation(
    ({invoiceId, status}: {invoiceId: string; status: FreightInvoiceStatus}) =>
      updateFreightInvoiceStatus(invoiceId, status),
    {
      onSettled: () => queryClient.invalidateQueries([FREIGHT_INVOICES_QUERY_KEY, ...(shipmentId ? [shipmentId] : [])])
    }
  );
}

const getInvoiceDocuments = async (invoiceId: string) => await getFreightInvoiceDocuments(invoiceId);

export const useGetFreightInvoiceDocuments = (
  invoiceId: string,
  queryOptions?: UseQueryOptions<FreightInvoiceDocument[], AxiosError, FreightInvoiceDocument[], string[]>
) => {
  const queryClient = useQueryClient();

  return {
    queryInfo: useQuery([INVOICE_DOCUMENTS_KEY, invoiceId], async () => getInvoiceDocuments(invoiceId), queryOptions),
    prefetch: () =>
      queryClient.prefetchQuery({
        queryKey: [INVOICE_DOCUMENTS_KEY, invoiceId],
        queryFn: () => getInvoiceDocuments(invoiceId),
        staleTime: HOT_DATA_STALE_TIME // only prefetch if data is older than 3 seconds
      })
  };
};

export const useUpdateDocumentsRequiredDetails = (ruleId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (documentsRequiredDetails: ConfigDetails) =>
      updateRequiredDocumentInvoiceRuleConfiguration(ruleId, documentsRequiredDetails),
    {
      onSettled() {
        void queryClient.invalidateQueries([INVOICE_RULE_CONFIGURATIONS_QUERY_KEY]);
      }
    }
  );
};

export const useGetSettlementsConfigurations = () =>
  useQuery([FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY], async () => (await getSettlementsConfigurations()).data);

export const useBulkUpdateSettlementsConfigurationsStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(bulkUpdateSettlementsConfigurationsStatus, {
    onSettled() {
      void queryClient.invalidateQueries([FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY]);
    }
  });
};

export const useUpdateAutoGenerateInvoiceConfiguration = ({onSuccess}: {onSuccess?: () => void} = {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateAutoGenerateInvoiceConfiguration, {
    onSuccess,
    onSettled() {
      void queryClient.invalidateQueries([FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY]);
    }
  });
};

const updateManyFreightConfigurationMutationFn = ({
  ids,
  days_to_wait,
  rule_type
}: {
  ids: string[];
  days_to_wait: number;
  rule_type: UpdateAddToDeliveredDashboardActionDetailsRuleTypeEnum;
}) => Promise.all(ids.map((id) => updateAddToDeliveredDashboardConfiguration(id, {rule_type, days_to_wait})));

export const useUpdateManyDaysToDeliveredDashboardConfiguration = () => {
  const queryClient = useQueryClient();
  return useMutation(updateManyFreightConfigurationMutationFn, {
    onSettled() {
      void queryClient.invalidateQueries([FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY]);
    }
  });
};

export const useCreateFreightInvoiceDocument = (
  invoiceId: string,
  mutationOptions: UseMutationOptions<
    Awaited<ReturnType<typeof createFreightInvoiceDocument>>,
    AxiosError,
    Omit<FreightInvoicesApiCreateFreightInvoiceDocumentRequest, 'invoiceId'>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<
    Awaited<ReturnType<typeof createFreightInvoiceDocument>>,
    AxiosError,
    Omit<FreightInvoicesApiCreateFreightInvoiceDocumentRequest, 'invoiceId'>
  >(
    ({documents, documentsMetadata}: Omit<FreightInvoicesApiCreateFreightInvoiceDocumentRequest, 'invoiceId'>) => {
      if (!invoiceId) {
        throw new Error('Missing invoice id');
      }
      return createFreightInvoiceDocument({invoiceId, documents, documentsMetadata});
    },

    {
      onSettled: () =>
        void Promise.allSettled([
          queryClient.invalidateQueries([INVOICE_DOCUMENTS_KEY, invoiceId]),
          //invalidate the freight invoices list query,
          //since invoices have a documents property that could've changed length
          queryClient.invalidateQueries([FREIGHT_INVOICES_QUERY_KEY])
        ]),
      ...mutationOptions
    }
  );
};

export const useUpdateFreightInvoiceDocument = (invoiceId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({documentId, documentMetadata}: {documentId: string; documentMetadata: DocumentMetadata}) =>
      updateFreightInvoiceDocument({invoiceId, documentId, documentMetadata}),
    {
      onSettled: () => {
        void queryClient.invalidateQueries([INVOICE_DOCUMENTS_KEY, invoiceId]);
        void queryClient.invalidateQueries([FREIGHT_INVOICES_QUERY_KEY]);
      }
    }
  );
};
