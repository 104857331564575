import {Field} from 'formik';
import {FormikTextarea} from '@shipwell/shipwell-ui';

/**
 * Company Terms & Credit
 * @param {*} props
 */
const CompanyBillingInstructions = () => {
  return (
    <div>
      <Field
        name="billing_instructions"
        label="Billing Instructions"
        minRows="3"
        maxRows="3"
        component={FormikTextarea}
      />
    </div>
  );
};

export default CompanyBillingInstructions;
