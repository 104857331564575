import get from 'lodash/get';
export const driverAllowedModes = ['FTL', 'DRAYAGE'];
export const powerUnitAllowedModes = ['FTL', 'DRAYAGE'];
export const trailerAllowedModes = ['FTL'];
export const temperatureRequiredEquipment = ['REEFER'];

export const getCurrentCarrier = (shipment) => {
  return get(shipment, 'current_carrier');
};

export const hasInstantRateBooked = (shipment) => {
  return get(shipment, 'most_recently_awarded_quote.source_type') === 'INSTANT';
};
