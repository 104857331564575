import {RegularSelect} from '@shipwell/shipwell-ui';
import {useMemo} from 'react';

interface GenesisAccountSelectorProps {
  selectedAccountNumber: string | undefined;
  setSelectedAccountNumber: (selectedAccountNumber: string) => void;
  accounts: {label: string; value: string}[];
}

export const GenesisAccountSelector = ({
  selectedAccountNumber,
  setSelectedAccountNumber,
  accounts
}: GenesisAccountSelectorProps) => {
  const selectedValue = useMemo(() => {
    return accounts?.find((item) => item.value === selectedAccountNumber);
  }, [accounts, selectedAccountNumber]);

  return (
    <RegularSelect
      onChange={(account) => setSelectedAccountNumber(account?.value as string)}
      label="Account"
      value={selectedValue}
      options={accounts}
      isClearable
    />
  );
};
