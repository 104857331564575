import {MouseEvent} from 'react';
import {Route, IndexRoute, Link} from 'react-router';

import {useAppointmentPerms} from 'App/data-hooks';

import {AppointmentsRootWrapper, ConditionalAppointmentsContainer} from 'App/containers/appointments';

export type AppointmentsLinkProps = {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

const AppointmentsLink = ({onClick}: AppointmentsLinkProps) => (
  <Link to="/appointments" activeClassName="active" onClick={onClick}>
    Calendar
  </Link>
);

export const ConditionalAppointmentsLink = ({onClick}: AppointmentsLinkProps) => {
  const {canView} = useAppointmentPerms();
  return canView ? <AppointmentsLink onClick={onClick} /> : null;
};

export default (
  <Route component={AppointmentsRootWrapper}>
    <IndexRoute component={ConditionalAppointmentsContainer} />
  </Route>
);
