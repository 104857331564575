import {MovementListResponse} from '@shipwell/locus-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {getMovement} from 'App/api/locus';

export const LOCUS_GET_MOVEMENT = 'locusGetMovement';

export const useGetLocusMovementDetailsByShipmentId = ({
  shipmentId,
  options
}: {
  shipmentId: string;
  options?: Omit<
    UseQueryOptions<MovementListResponse, unknown, MovementListResponse, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const query = useQuery(
    [LOCUS_GET_MOVEMENT, shipmentId],
    async () => {
      const response = await getMovement({
        resourceType: 'V2_SHIPMENT',
        resourceId: shipmentId
      });
      return response;
    },
    options
  );

  const {data, status: movementDetailsStatus} = query;

  return {
    movementDetails: data?.data || [],
    movementDetailsStatus,
    query
  };
};
