import {useEffect, useRef} from 'react';

// used to memoize useQueries responses
export const useArrayMemo = <T>(currentArray: T[]): T[] => {
  // this holds reference to previous value
  const ref = useRef<T[]>([]);
  const {current: prevArray} = ref;
  // check if each element of the old and new array match
  const areArraysConsideredTheSame =
    prevArray && currentArray?.length === prevArray.length
      ? currentArray.every((element, index) => element === prevArray[index])
      : //initially there's no old array defined/stored, so set to false
        false;

  useEffect(() => {
    //only update prev results if array is not deemed the same
    if (!areArraysConsideredTheSame) {
      ref.current = currentArray;
    }
  }, [areArraysConsideredTheSame, currentArray]);

  return areArraysConsideredTheSame ? prevArray : currentArray;
};
