import {Title, Card, Rule, FormikTextInput, FormikCheckbox, FormikSelect} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {currencyUnit, dimensionUnit, EquipmentFormProps, tempUnit, weightUnit} from './utils';
import HazmatCodesField from 'App/formComponents/formSections/hazmatCodesField';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {equipmentTypesByModeFilter} from 'App/utils/filterEquipmentTypes';
import modeFilterOptions from 'App/utils/shipmentModeFilterOptions';

const EquipmentForm = ({values, setFieldValue, mode}: EquipmentFormProps) => {
  const equipmentQuery = useEquipmentTypesQuery();

  const equipmentTypeOptions = (equipmentQuery?.data || []).filter(equipmentTypesByModeFilter(mode)).map((item) => ({
    value: item.machine_readable,
    label: item.name
  }));

  return (
    <Card title="Equipment" draggableProvided={false} bodyClassName="p-4 empty:p-0" data-testid="equipment-content">
      <div>
        <Title variant="formTitle" className="mb-4">
          Equipment
        </Title>
        <div className="grid grid-cols-2 gap-4">
          <Field
            simpleValue
            searchable={false}
            label="Mode"
            name="mode"
            component={FormikSelect}
            options={modeFilterOptions()}
          />
          <Field
            simpleValue
            searchable={false}
            label="Equipment"
            name="equipment.0.equipment_type"
            component={FormikSelect}
            options={equipmentTypeOptions}
          />
          <div className="one-row-fields">
            <Field label="Weight" name="equipment.0.gross_weight.value" component={FormikTextInput} />
            <div className="max-w-[80px]">
              <Field
                simpleValue
                searchable={false}
                name="equipment.0.gross_weight.unit"
                options={weightUnit}
                component={FormikSelect}
                className="min-w-0"
                clearable={false}
              />
            </div>
          </div>
          <div className="one-row-fields">
            <Field label="Length" name="equipment.0.dimensions.length" component={FormikTextInput} />
            <div className="max-w-[80px]">
              <Field
                simpleValue
                searchable={false}
                name="equipment.0.dimensions.unit"
                options={dimensionUnit}
                component={FormikSelect}
                className="min-w-0"
                clearable={false}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <Field label="Carb" name="requirements.carb" component={FormikCheckbox} />
          <Field label="Lift Gate" name="requirements.lift_gate" component={FormikCheckbox} />
          <div>
            <Field label="Straps Required" name="straps_required" component={FormikCheckbox} />
            {values.straps_required ? (
              <Field label="Strap Count" name="requirements.strap_count" component={FormikTextInput} />
            ) : null}
          </div>
          <div>
            <Field label="Tarps Required" name="tarps_required" component={FormikCheckbox} />
            {values.tarps_required ? (
              <div className="one-row-fields">
                <Field label="Length" name="tarp.length" component={FormikTextInput} />
                <Field label="Width" name="tarp.width" component={FormikTextInput} />
                <Field
                  simpleValue
                  searchable={false}
                  name="tarp.unit"
                  options={dimensionUnit}
                  component={FormikSelect}
                  className="min-w-0"
                  clearable={false}
                  value={dimensionUnit[0].value}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <Field label="Hazmat" name="hazmat_required" component={FormikCheckbox} />
          {values.hazmat_required ? (
            <HazmatCodesField
              name="requirements.hazmat_identification_numbers"
              label="Hazmat Code"
              onChange={({identification_number}: {identification_number: string}) =>
                setFieldValue('requirements.hazmat_identification_numbers', identification_number)
              }
              value={{hazmat_identification_number: values.requirements?.hazmat_identification_numbers}}
            />
          ) : null}
        </div>
        <div className="mb-4">
          <Field label="Oversize" name="oversized_required" component={FormikCheckbox} />
          {values.oversized_required ? (
            <div className="one-row-fields">
              <Field label="Length" name="requirements.oversized_length" component={FormikTextInput} />
              <Field label="Width" name="requirements.oversized_width" component={FormikTextInput} />
              <Field label="Height" name="requirements.oversized_height" component={FormikTextInput} />
              <Field
                simpleValue
                name="requirements.dimension_unit"
                component={FormikSelect}
                searchable={false}
                options={dimensionUnit}
                className="min-w-0"
                clearable={false}
                value={dimensionUnit[0].value}
              />
            </div>
          ) : null}
        </div>
        <Rule />
        <Title variant="formTitle" className="mb-4">
          Temperature
        </Title>
        <div className="mb-6">
          <Field label="Refrigeration Required" name="refrigeration_required" component={FormikCheckbox} />
          {values.refrigeration_required ? (
            <div className="one-row-fields">
              <Field label="Min Temp" name="requirements.min_temperature" component={FormikTextInput} />
              <Field label="Max Temp" name="requirements.max_temperature" component={FormikTextInput} />
              <Field label="Pre Cool" name="requirements.pre_cool_temperature" component={FormikTextInput} />
              <Field
                simpleValue
                name="requirements.temperature_units"
                component={FormikSelect}
                searchable={false}
                options={tempUnit}
                className="min-w-0"
                clearable={false}
              />
            </div>
          ) : null}
        </div>
        <Rule />
        <Title variant="formTitle" className="mb-4">
          Load Details
        </Title>
        <div className="mb-6 grid grid-cols-2 gap-x-4">
          <Field label="Pallet Exchange" name="requirements.pallet_exchange" component={FormikCheckbox} />
          <Field label="Floor Loaded" name="requirements.floor_loaded" component={FormikCheckbox} />
          <Field label="Blind" name="requirements.blind" component={FormikCheckbox} />
          <Field label="High Value" name="requirements.high_value" component={FormikCheckbox} />
          <Field label="Insured Cargo" name="insured_cargo" component={FormikCheckbox} />
          <Field label="Hot Shot" name="requirements.hot_shot" component={FormikCheckbox} />
          {values.insured_cargo ? (
            <div className="one-row-fields">
              <Field
                label="Insured Value"
                name="requirements.cargo_value_insurance_amount"
                component={FormikTextInput}
              />
              <div className="max-w-[80px]">
                <Field
                  simpleValue
                  name="requirements.cargo_value_insurance_currency_code"
                  component={FormikSelect}
                  options={currencyUnit}
                  onChange={(value: string) => setFieldValue('requirements.cargo_value_insurance_currency_code', value)}
                  className="min-w-0"
                  clearable={false}
                  value={currencyUnit[0].value}
                />
              </div>
            </div>
          ) : null}
        </div>
        <Rule />
        <Title variant="formTitle" className="mb-4">
          Load Requirements
        </Title>
        <div className="grid grid-cols-2 gap-x-4">
          <Field label="Tracking Required" name="requirements.tracking_required" component={FormikCheckbox} />
          <Field label="Alcohol Permit" name="requirements.alcohol_permit" component={FormikCheckbox} />
          <Field label="Tanker Endorsement" name="requirements.tanker_endorsement" component={FormikCheckbox} />
          <Field label="Escorts" name="requirements.escorts" component={FormikCheckbox} />
          <Field label="Driver Assist" name="requirements.driver_assist" component={FormikCheckbox} />
          <Field label="TWIC Card" name="requirements.twic_card" component={FormikCheckbox} />
          <Field label="Team" name="requirements.team" component={FormikCheckbox} />
        </div>
      </div>
    </Card>
  );
};

export default EquipmentForm;
