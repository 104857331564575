import {Component} from 'react';
import {connect} from 'react-redux';
import SelectField from 'App/formComponents/fields/select';
import {fetchShipmentModes} from 'App/actions/shipments';
import {checkShipmentModes} from 'App/utils/globalsTyped';

/**
 * Mode Field
 */
class ModeField extends Component {
  static defaultProps = {
    shipmentModes: [],
    placeholder: '',
    multi: true,
    value: '',
    name: '',
    input: {}
  };

  componentDidMount() {
    const {shipmentModes} = this.props;

    if (!shipmentModes.length) {
      this.getShipmentModes();
    }
  }

  /**
   * Request available shipment modes
   */
  async getShipmentModes() {
    try {
      const results = {options: []};
      const response = await this.props.dispatch(fetchShipmentModes());

      if (response) {
        results.options = response;
      }
      return results;
    } catch (error) {
      console.error(error);
    }
  }

  filterModeOptions(value, options) {
    const {multi, form} = this.props;
    const {hasDrayage, hasParcel} = checkShipmentModes(value);

    if (hasParcel && multi) {
      return options.filter((option) => option.code === 'PARCEL');
    }
    if (hasDrayage && multi) {
      return options.filter((option) => option.code === 'DRAYAGE');
    }
    if (multi && value && value.length > 0) {
      return options.filter((option) => option.code !== 'DRAYAGE' && option.code !== 'PARCEL');
    }
    if (form && form === 'newShipmentForm') {
      return options.filter((option) => option.code !== 'PARCEL');
    }
    return options;
  }

  render() {
    const {input, placeholder, multi, shipmentModes, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const options = this.props.options || shipmentModes;
    const onChange = input.onChange || rest.onChange;

    return (
      <SelectField
        {...rest}
        multi={multi}
        name={name}
        value={value}
        placeholder={placeholder}
        options={this.filterModeOptions(value, options)}
        onChange={(value) => onChange && onChange(value)}
      />
    );
  }
}

export default connect((state) => ({
  shipmentModes: state.shipments.shipmentModes
}))(ModeField);
