import {
  VendorRelationship,
  VendorRelationshipsApiShipwellUiVendorRelationshipsGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {VENDOR_RELATIONSHIPS} from '../queryKeys';
import {getVendorRelationships} from 'App/api/vendors';

export const useGetVendorRelationships = (
  params?: VendorRelationshipsApiShipwellUiVendorRelationshipsGetRequest,
  options?: Omit<
    UseQueryOptions<
      VendorRelationship[] | undefined,
      unknown,
      VendorRelationship[],
      (string | VendorRelationshipsApiShipwellUiVendorRelationshipsGetRequest | undefined)[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const getVendorRelationshipsQuery = useQuery(
    [VENDOR_RELATIONSHIPS, 'list', params],
    async () => {
      const response = await getVendorRelationships(params);
      return response.data.results;
    },
    options
  );

  const {
    data: getVendorRelationshipsData,
    isFetching: getVendorRelationshipsIsFetching,
    status: getVendorRelationshipsStatus
  } = getVendorRelationshipsQuery;
  return {
    getVendorRelationshipsQuery,
    getVendorRelationshipsData,
    getVendorRelationshipsIsFetching,
    getVendorRelationshipsStatus
  };
};
