import {TemplateBuilderApiTemplateEditorRequest} from '@shipwell/template-builder-sdk';
import {useQuery} from '@tanstack/react-query';
import {TEMPLATE_EDITOR_QUERY_KEY} from '../queryKeys';
import {getEditTemplateUrl} from 'App/api/templates/typed';

export const useGetEditTemplateUrl = (params?: TemplateBuilderApiTemplateEditorRequest) => {
  const {data, isLoading, error} = useQuery(
    [TEMPLATE_EDITOR_QUERY_KEY, params?.templateId],
    () => getEditTemplateUrl(params ?? {templateId: ''}),
    {enabled: !!params?.templateId}
  );

  return {
    templateEditorUrl: data,
    isLoadingTemplateEditorUrl: isLoading,
    templateEditorUrlError: error
  };
};
