import {Field, Form, Formik} from 'formik';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import {FormikTextInput} from '@shipwell/shipwell-ui';
import {object, string} from 'yup';
import {Shipment, ShipmentMetadataBuyItNowAmountCurrencyEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ReactNodeLike} from 'prop-types';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {validateDollarValue, removeCommasAndDollarSign} from 'App/utils/globals';
import {transformAxiosError} from 'App/utils/globalsTyped';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';

interface FormValues {
  max_buy_amount: string;
  buy_it_now_amount: string;
  target_rate_amount: string;
}

const validateValue = (value: string | null | undefined) =>
  (typeof value === 'string' && +value > 0 && validateDollarValue(value, true)) || isNil(value);

const validationSchema = object().shape({
  max_buy_amount: string()
    .nullable()
    .test({
      message: 'Enter a valid dollar value',
      test: (value) => validateValue(value)
    }),
  buy_it_now_amount: string()
    .nullable()
    .test({
      message: 'Enter a valid dollar value',
      test: (value) => validateValue(value)
    }),
  target_rate_amount: string()
    .nullable()
    .test({
      message: 'Enter a valid dollar value',
      test: (value) => validateValue(value)
    })
});

const FinancialsForm = ({
  selectedShipment,
  handleClose,
  fetchShipmentDetails,
  setError
}: {
  selectedShipment: Shipment;
  handleClose: () => void;
  fetchShipmentDetails: () => Promise<void>;
  setError?: (title: string, error: ReactNodeLike) => void;
}) => {
  const {
    id: shipmentId,
    preferred_currency,
    metadata: {max_buy_amount, buy_it_now_amount, target_rate_amount} = {}
  } = selectedShipment;
  const initialValues = mapValues(
    {max_buy_amount, buy_it_now_amount, target_rate_amount},
    (value) => value?.toFixed(2)?.replace(/.00$/, '') || ''
  );

  const updateShipmentMutation = useUpdateShipment();

  const handleSubmit = (values: FormValues, {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}) => {
    if (!selectedShipment.metadata) return;

    const formattedValues = mapValues(values, (value) => +removeCommasAndDollarSign(value) || null);
    updateShipmentMutation.mutate(
      {
        shipmentId,
        shipment: {
          ...selectedShipment,
          metadata: {
            ...selectedShipment.metadata,
            // TODO remove type cast once QuoteChargeLineItemUnitAmountCurrencyEnum updated to accept any currency code
            buy_it_now_amount_currency: preferred_currency as ShipmentMetadataBuyItNowAmountCurrencyEnum,
            max_buy_amount_currency: preferred_currency as ShipmentMetadataBuyItNowAmountCurrencyEnum,
            target_rate_amount_currency: preferred_currency as ShipmentMetadataBuyItNowAmountCurrencyEnum,
            ...formattedValues
          }
        }
      },
      {
        onSuccess: () => {
          void fetchShipmentDetails();
          setSubmitting(false);
          handleClose();
        },
        onError: (error) => {
          if (setError) {
            const {title, message} = transformAxiosError(error);
            setError(title, message);
          }
          console.error(error);
          setSubmitting(false);
        }
      }
    );
  };

  return (
    <div className="mb-14 flex flex-col gap-y-4">
      <div className="font-bold">Edit Rates</div>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          handleSubmit(values, {setSubmitting});
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({isSubmitting, dirty}) => (
          <Form className="flex justify-between">
            <Field name="max_buy_amount" label="Max Buy" prepend={preferred_currency} component={FormikTextInput} />
            <Field name="buy_it_now_amount" label="Book Now" prepend={preferred_currency} component={FormikTextInput} />
            <Field
              name="target_rate_amount"
              label="Target Rate"
              prepend={preferred_currency}
              component={FormikTextInput}
            />
            <ModalFormFooter
              isSubmitting={isSubmitting}
              onCancel={handleClose}
              primaryActionName={'Submit'}
              primaryActionDisabled={!dirty}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FinancialsForm;
