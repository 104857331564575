import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, formValueSelector, change} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import CheckboxField from 'App/formComponents/fields/checkbox';
import InputField from 'App/formComponents/fields/input';
import {cleanNumber, truncateNumber} from 'App/utils/globals';
import './styles.scss';

/**
 * Shipment Total Value Fields
 */
class ShipmentTotalValueFields extends Component {
  constructor(props) {
    super(props);
    const {values = {}} = this.props;
    const {total_declared_value} = values;
    this.inputValue = total_declared_value > 0 ? truncateNumber(total_declared_value) : '';
  }

  componentDidUpdate(prevProps) {
    const {values = {}, form} = this.props;
    const {total_declared_value, manual_total_value, preferred_currency} = values;
    if (manual_total_value && total_declared_value != prevProps.values?.total_declared_value) {
      this.inputValue = total_declared_value > 0 ? truncateNumber(total_declared_value) : '';
    }
    if (manual_total_value && preferred_currency && preferred_currency !== prevProps.values?.preferred_currency) {
      this.props.dispatch(change(form, 'total_declared_value_currency', preferred_currency));
    }
  }

  handleValueInputChange(e, value) {
    e.preventDefault();
    const {form} = this.props;
    this.inputValue = value;
    this.props.dispatch(change(form, 'total_declared_value', value > 0 ? truncateNumber(value) : null));
  }

  handleCheckboxSelection(e, value) {
    const {form, unitPreferences, values} = this.props;
    if (!value) {
      this.inputValue = '';
      this.props.dispatch(change(form, 'total_declared_value', null));
    }
    this.props.dispatch(
      change(form, 'total_declared_value_currency', values?.preferred_currency || unitPreferences.currency)
    );
  }

  render() {
    const {values = {}, unitPreferences} = this.props;
    const {manual_total_value, total_declared_value_currency} = values;
    const currency = total_declared_value_currency || values?.preferred_currency || unitPreferences.currency;

    return (
      <Grid container spacing={2} className="shipment-total-value">
        <Grid item xs={12} className="shipment-total-value-field">
          <Field
            name="manual_total_value"
            label="Enter Declared Value Override Amount"
            component={CheckboxField}
            onChange={this.handleCheckboxSelection.bind(this)}
          />
        </Grid>
        {manual_total_value && (
          <Grid item xs={12} md={6}>
            <Field
              req
              name="total_declared_value"
              label={`Total Shipment Value (${currency})`}
              placeholder="Enter Value"
              format={(value) => truncateNumber(this.inputValue || value || '')}
              normalize={(value) => cleanNumber(value, {allowDecimals: true})}
              component={InputField}
              onChange={this.handleValueInputChange.bind(this)}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default connect((state, props) => ({
  values: getFormValues(props.form)(state),
  shipmentLineItems: formValueSelector(props.form)(state, 'line_items'),
  unitPreferences: state.userCompany.unitPreferences
}))(ShipmentTotalValueFields);
