import {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Field, useFormikContext} from 'formik';
import {
  Card,
  CollapsibleCardContent,
  FormikSelect,
  Tooltip,
  SvgIcon,
  FormikCounter,
  FormikCheckbox,
  Button
} from '@shipwell/shipwell-ui';

const carrierStatuses = [
  {label: 'Active', value: 'ACTIVE'},
  {label: 'Inactive', value: 'INACTIVE'},
  {label: 'Do Not Use', value: 'DO_NOT_USE'}
];

const StatusSection = ({sectionSettings, focusedRow, cardObjectTitle, setFocusedRow, hideBorder}) => {
  return (
    <div className={`mb-2 ml-0 mr-2 mt-1 flex flex-row items-stretch pt-4 ${!hideBorder ? 'sw-border-b' : ''}`}>
      <div className="flex shrink grow flex-col">
        <div className="inline-block items-start lg:flex">
          <div className="flex w-full grow items-center lg:w-3/6 ">
            <strong className="pr-1">{sectionSettings.header}</strong>
          </div>
          <div className="mt-2 w-full grow lg:mt-0 lg:w-3/6 ">
            {sectionSettings.selectHeader && (
              <div className="flex w-full grow items-center pb-2 lg:w-3/6">
                <strong className="pr-1">{sectionSettings.selectHeader}</strong>
                <Tooltip
                  tooltipContent={
                    <span className="inline-block w-80">
                      To assign a global carrier status for each status below, change the Carrier Status to either
                      Active, Inactive, or Do Not Use. If you don’t want a status applied to a section don’t assign any
                      status.
                    </span>
                  }
                >
                  <SvgIcon name="InfoOutlined" />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="field-grid">
          {sectionSettings.options.map((option) => {
            return (
              <div
                key={option.correspondingFieldId}
                className={classNames('grid-item-1-2 inline-block lg:flex items-start')}
              >
                <div className={classNames('w-full lg:w-3/6 mt-2 lg:mt-0 pt-3 inline-block sm:flex flex-grow')}>
                  <div className="w-auto shrink-0 grow-0">If status is:</div>
                  <div className="w-auto shrink-0 grow-0">
                    <strong className="pl-1">{option.label}</strong>
                  </div>
                  {option.correspondingFieldId === focusedRow && (
                    <div className="flex w-full">
                      <div className="ml-4 h-4 w-full bg-line bg-center tw-hidden lg:flex"></div>
                      <div className="mr-4 h-4 w-2 shrink-0 grow-0 bg-arrowhead bg-center tw-hidden lg:flex "></div>
                    </div>
                  )}
                </div>
                <div className="mt-2 w-full grow lg:mt-0 lg:w-3/6">
                  <Field
                    simpleValue
                    label="Carrier Status"
                    name={`${cardObjectTitle}.${option.correspondingFieldId}`}
                    options={carrierStatuses}
                    clearable
                    onFocus={() => setFocusedRow(option.correspondingFieldId)}
                    onBlur={() => setFocusedRow('')}
                    component={FormikSelect}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

StatusSection.propTypes = {
  cardObjectTitle: PropTypes.string,
  focusedRow: PropTypes.string,
  hideBorder: PropTypes.bool,
  sectionSettings: PropTypes.shape({
    header: PropTypes.string,
    options: PropTypes.array,
    selectHeader: PropTypes.string
  }),
  setFocusedRow: PropTypes.func
};

const SelectSection = ({sectionSettings, focusedRow, cardObjectTitle, setFocusedRow, hideBorder}) => {
  return (
    <div className={`mb-2 ml-0 mr-2 mt-1 flex flex-row items-stretch pt-4 ${!hideBorder ? 'sw-border-b' : ''}`}>
      <div className="flex shrink grow flex-col">
        <div className="flex items-start">
          <div className="mt-2 w-full grow pb-2 lg:mt-0 lg:w-3/6">
            <strong>{sectionSettings.header}</strong>
          </div>
        </div>
        <div className="field-grid">
          <div className={classNames('inline-block lg:flex items-start pb-0 grid-item-1-2')}>
            <div className={classNames(' w-full lg:w-3/6 mt-2 lg:mt-0 pt-3 inline-block sm:flex flex-grow')}>
              <div className="w-auto shrink-0 grow-0">
                <span>{sectionSettings.textBefore}</span>
              </div>
              <div className="m-0 shrink-0 grow-[0.5] sm:-mt-3 sm:mb-0 sm:ml-2 sm:mr-0">
                <Field
                  simpleValue
                  name={`${cardObjectTitle}.${sectionSettings.correspondingSelectFieldId}`}
                  options={sectionSettings.selectFieldOptions}
                  label={sectionSettings.fieldLabel}
                  clearable
                  onFocus={() => setFocusedRow(sectionSettings.correspondingSelectFieldId)}
                  onBlur={() => setFocusedRow('')}
                  component={FormikSelect}
                />
              </div>
              <div className="-mt-1 pl-2">
                <Tooltip tooltipContent={<span className="inline-block w-80">{sectionSettings.toolTipText}</span>}>
                  <SvgIcon name="InfoOutlined" />
                </Tooltip>
              </div>
              {sectionSettings.correspondingFieldId === focusedRow && (
                <div className="flex shrink grow-[0.5]">
                  <div className="ml-4 h-4 w-full bg-line bg-center tw-hidden lg:flex "></div>
                  <div className="mr-4 h-4 w-2 shrink-0 grow-0 bg-arrowhead bg-center tw-hidden lg:flex"></div>
                </div>
              )}
            </div>
            <div className="mt-2 w-full grow lg:mt-0 lg:w-3/6">
              <Field
                simpleValue
                label="Carrier Status"
                name={`${cardObjectTitle}.${sectionSettings.correspondingFieldId}`}
                options={carrierStatuses.filter((status) => status.label !== 'Active')}
                clearable
                onFocus={() => setFocusedRow(sectionSettings.correspondingFieldId)}
                onBlur={() => setFocusedRow('')}
                component={FormikSelect}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectSection.propTypes = {
  cardObjectTitle: PropTypes.string,
  focusedRow: PropTypes.string,
  hideBorder: PropTypes.bool,
  sectionSettings: PropTypes.shape({
    correspondingFieldId: PropTypes.string,
    correspondingSelectFieldId: PropTypes.string,
    fieldLabel: PropTypes.string,
    header: PropTypes.string,
    selectFieldOptions: PropTypes.array,
    textBefore: PropTypes.string,
    toolTipText: PropTypes.string
  }),
  setFocusedRow: PropTypes.func
};

const CounterSection = ({sectionSettings, cardObjectTitle, focusedRow, setFocusedRow, hideBorder}) => {
  return (
    <div className={`mb-2 ml-0 mr-2 mt-1 flex flex-row items-stretch pt-4 ${!hideBorder ? 'sw-border-b' : ''}`}>
      <div className="flex shrink grow flex-col">
        <div className="flex items-start">
          <div className="mt-2 w-full grow pb-2 lg:mt-0 lg:w-3/6">
            <strong>{sectionSettings.header}</strong>
          </div>
        </div>
        <div className={classNames('inline-block lg:flex items-start pb-0 grid-item-1-2')}>
          <div className={classNames('flex-grow w-full lg:w-3/6 mt-2 lg:mt-0 pt-3 inline-block sm:flex')}>
            <div className="w-auto shrink-0 grow-0">
              <span>{sectionSettings.textBefore}</span>
            </div>
            <div className="mt-0 px-2 sm:-mt-11">
              <Field
                minValue={1}
                maxValue={180}
                name={`${cardObjectTitle}.${sectionSettings.correspondingCounterFieldId}`}
                component={FormikCounter}
              />
            </div>
            <div className="w-auto shrink-0 grow-0">
              <span>{sectionSettings.textAfter}</span>
            </div>
            {sectionSettings.correspondingFieldId === focusedRow && (
              <div className="flex w-full">
                <div className="ml-4 h-4 w-full bg-line bg-center tw-hidden lg:flex"></div>
                <div className="mr-4 h-4 w-2 shrink-0 grow-0 bg-arrowhead bg-center tw-hidden lg:flex"></div>
              </div>
            )}
          </div>
          <div className="mt-2 w-full grow lg:mt-0 lg:w-3/6">
            <Field
              simpleValue
              label="Carrier Status"
              name={`${cardObjectTitle}.${sectionSettings.correspondingFieldId}`}
              options={carrierStatuses.filter((status) => status.label !== 'Active')}
              clearable
              onFocus={() => setFocusedRow(sectionSettings.correspondingFieldId)}
              onBlur={() => setFocusedRow('')}
              component={FormikSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CounterSection.propTypes = {
  cardObjectTitle: PropTypes.string,
  focusedRow: PropTypes.string,
  hideBorder: PropTypes.bool,
  sectionSettings: PropTypes.shape({
    correspondingCounterFieldId: PropTypes.string,
    correspondingFieldId: PropTypes.string,
    header: PropTypes.string,
    textAfter: PropTypes.string,
    textBefore: PropTypes.string
  }),
  setFocusedRow: PropTypes.func
};

const CheckboxSection = ({sectionSettings, cardObjectTitle}) => {
  return (
    <div>
      <Field
        label={sectionSettings.header}
        name={`${cardObjectTitle}.${sectionSettings.correspondingFieldId}`}
        component={FormikCheckbox}
      />
    </div>
  );
};

CheckboxSection.propTypes = {
  cardObjectTitle: PropTypes.string,
  sectionSettings: PropTypes.shape({
    correspondingFieldId: PropTypes.string,
    header: PropTypes.string
  })
};

const CardContent = ({sections, carrierSettings, cardObjectTitle}) => {
  const [focusedRow, setFocusedRow] = useState('');

  return sections.map((section) => {
    switch (section.type) {
      case 'status':
        return (
          <StatusSection
            key={section.header}
            sectionSettings={section}
            cardObjectTitle={cardObjectTitle}
            setFocusedRow={setFocusedRow}
            focusedRow={focusedRow}
            hideBorder={section.hideBorder}
          />
        );
      case 'date':
        return (
          <CounterSection
            key={section.header}
            sectionSettings={section}
            cardObjectTitle={cardObjectTitle}
            carrierSettings={carrierSettings}
            setFocusedRow={setFocusedRow}
            focusedRow={focusedRow}
            hideBorder={section.hideBorder}
          />
        );
      case 'select':
        return (
          <SelectSection
            key={section.header}
            sectionSettings={section}
            cardObjectTitle={cardObjectTitle}
            setFocusedRow={setFocusedRow}
            focusedRow={focusedRow}
            hideBorder={section.hideBorder}
          />
        );
      case 'checkbox':
        return <CheckboxSection key={section.header} sectionSettings={section} cardObjectTitle={cardObjectTitle} />;
      default:
        return;
    }
  });
};

const CarrierComplianceCard = ({cardSettings, carrierSettings, cardDisabled, redirect}) => {
  const [innerContentExpanded, setInnerContentExpanded] = useState(false);
  const {initialValues} = useFormikContext();

  return (
    <div>
      <Card
        title={
          <div className="flex w-full justify-between">
            <div className="flex items-center">
              <img src={cardSettings.cardIcon} className="pr-2" />
              <span>{cardSettings.cardTitle}</span>
            </div>
            {cardDisabled && (
              <div className="flex items-center">
                <a onClick={redirect} className="cursor-pointer pr-2">
                  Connect Account
                </a>
                <SvgIcon name="LockFilled" color="$sw-icon" />
              </div>
            )}
          </div>
        }
        isCollapsible
        // isCollapsible makes the collapse button appear, disableCollapse disables that button (but still makes it appear). we only want one of two states in this
        // case: collapsed (isCollapsed) and disabled (disableCollapse), or open (!isCollapsed) and enabled (!disableCollapse). in either case, the card should be collapsible
        isCollapsed={cardDisabled}
        disableCollapse={cardDisabled}
      >
        <CollapsibleCardContent>
          <div className="pb-4 sw-border-b">
            <span>{cardSettings.cardHeader}</span>
          </div>
          <CardContent
            sections={cardSettings.sections}
            carrierSettings={carrierSettings}
            cardObjectTitle={cardSettings.cardObjectTitle}
          />
          {cardSettings.collapsedSections && (
            <div>
              <div className="-ml-4 flex items-center p-2">
                <Button variant="tertiary" onClick={() => setInnerContentExpanded(!innerContentExpanded)}>
                  <SvgIcon name={innerContentExpanded ? 'ExpandLess' : 'ExpandMore'} />
                  Advanced Settings
                </Button>
                {!innerContentExpanded && (
                  <strong className="-mb-1 text-xs text-sw-disabled">
                    {
                      // calculates the number of collapsed rows which have been modified by the user (advanced settings)
                      cardSettings.collapsedSections
                        .map((section) =>
                          section.options
                            ? section.options.map(
                                (option) => initialValues[cardSettings.cardObjectTitle][option.correspondingFieldId]
                              )
                            : initialValues[cardSettings.cardObjectTitle][section.correspondingFieldId]
                        )

                        .flat()
                        .filter((value) => !!value).length
                    }
                    {` SETTINGS APPLIED`}
                  </strong>
                )}
              </div>
              {innerContentExpanded && (
                <div className="bg-sw-background px-6 py-0">
                  <CardContent
                    sections={cardSettings.collapsedSections}
                    carrierSettings={carrierSettings}
                    cardObjectTitle={cardSettings.cardObjectTitle}
                  />
                </div>
              )}
            </div>
          )}
        </CollapsibleCardContent>
      </Card>
    </div>
  );
};

CarrierComplianceCard.propTypes = {
  cardDisabled: PropTypes.bool,
  cardSettings: PropTypes.shape({
    cardHeader: PropTypes.string,
    cardIcon: PropTypes.string,
    cardObjectTitle: PropTypes.string,
    cardTitle: PropTypes.string,
    collapsedSections: PropTypes.array,
    sections: PropTypes.array
  }),
  carrierSettings: PropTypes.object,
  redirect: PropTypes.func
};

CarrierComplianceCard.defaultProps = {
  cardDisabled: false
};

export default CarrierComplianceCard;
