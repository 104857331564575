import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {LoadPostRequest} from '@shipwell/tabula-sdk';
import {getLoadPostRequestByShipmentId} from 'App/api/loadboards';
import {LOADPOST_REQUEST_KEY} from 'App/data-hooks/queryKeys';

const useGetLoadPostRequestByShipmentId = (shipmentId: string, options?: UseQueryOptions<LoadPostRequest>) => {
  const response = useQuery(
    [LOADPOST_REQUEST_KEY, shipmentId] as QueryKey,
    async () => {
      const response = await getLoadPostRequestByShipmentId(shipmentId);
      return response.data;
    },
    {
      enabled: !!shipmentId,
      ...options
    }
  );

  return response;
};

export default useGetLoadPostRequestByShipmentId;
