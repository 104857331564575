import {
  OpenTimes,
  OperatingHoursEnums
} from 'App/containers/userCompany/defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigDetail/OperatingDaysInputs';

export function getOperatingDays<T extends OpenTimes>(openTimes: T): OperatingHoursEnums {
  const openWeekdays = [
    openTimes?.open_monday,
    openTimes?.open_tuesday,
    openTimes?.open_wednesday,
    openTimes?.open_thursday,
    openTimes?.open_friday
  ];
  const openWeekends = [openTimes?.open_saturday, openTimes?.open_sunday];
  const weekdayOpenTimes = [
    openTimes?.monday_open_time,
    openTimes?.tuesday_open_time,
    openTimes?.wednesday_open_time,
    openTimes?.thursday_open_time,
    openTimes?.friday_open_time
  ];
  const weekdayCloseTimes = [
    openTimes?.monday_close_time,
    openTimes?.tuesday_close_time,
    openTimes?.wednesday_close_time,
    openTimes?.thursday_close_time,
    openTimes?.friday_close_time
  ];
  const openEveryWeekday = openWeekdays.every((weekday) => weekday);
  const closedOnWeekends = openWeekends.every((weekend) => !weekend);
  const weekdayOpenTimesMatch = weekdayOpenTimes.every((time) => weekdayOpenTimes[0] === time);
  const weekdayCloseTimesMatch = weekdayCloseTimes.every((time) => weekdayCloseTimes[0] === time);

  if (openEveryWeekday && closedOnWeekends && weekdayOpenTimesMatch && weekdayCloseTimesMatch) {
    return OperatingHoursEnums.weekdays;
  }
  if (openEveryWeekday && weekdayOpenTimesMatch && weekdayCloseTimesMatch) {
    return OperatingHoursEnums.weekdaysAndWeekends;
  }
  return OperatingHoursEnums.custom;
}
