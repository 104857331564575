import {
  Configuration,
  TemplateBuilderApi,
  TemplateBuilderApiListTemplatesRequest,
  TemplateBuilderApiGenerateDocumentFromTemplateRequest,
  TemplateBuilderApiUpdateTemplateRequest,
  TemplateBuilderApiTemplateEditorRequest
} from '@shipwell/template-builder-sdk';
import {getAccessToken} from 'App/api/utils';
const basePath = process.env.SHIPWELL_DIGITAL_DOCUMENTS_API_BASE_PATH;

const templateBuilderApi = new TemplateBuilderApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const listTemplates = (params: TemplateBuilderApiListTemplatesRequest) => {
  return templateBuilderApi.listTemplates(params);
};

export const generateDocumentFromTemplate = (params: TemplateBuilderApiGenerateDocumentFromTemplateRequest) => {
  return templateBuilderApi.generateDocumentFromTemplate(params);
};

export const updateTemplate = (params: TemplateBuilderApiUpdateTemplateRequest) => {
  return templateBuilderApi.updateTemplate(params);
};

export const getEditTemplateUrl = (params: TemplateBuilderApiTemplateEditorRequest) => {
  return templateBuilderApi.templateEditor(params);
};
