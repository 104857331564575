import {ShipmentTag} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getShipmentTags} from 'App/api/shipment/typed';
import {TAGS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useGetShipmentTags = ({
  options
}: {
  options?: Omit<
    UseQueryOptions<ShipmentTag[], unknown, ShipmentTag[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >;
}) => {
  const shipmentTagsQuery = useQuery(
    [TAGS_QUERY_KEY],
    async () => {
      const response = await getShipmentTags();
      return response.data;
    },
    {
      staleTime: Infinity,
      ...options
    }
  );

  const {data: shipmentTagsData, status: shipmentTagsStatus} = shipmentTagsQuery;

  return {
    shipmentTagsData,
    shipmentTagsStatus,
    shipmentTagsQuery
  };
};
