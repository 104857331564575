import {Field, Form, Formik} from 'formik';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {DeprecatedButton, Card, FormikTextInput, Modal, Title} from '@shipwell/shipwell-ui';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {browserHistory} from 'react-router';
import {object, string} from 'yup';
import {FlexBox, GridBox} from 'App/components/Box';
import {connectSmc3} from 'App/api/genesis/typed';
import {useDisconnectSmc3Mutation, useGetSmc3Query} from 'App/containers/smc3/hooks/hooks';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import useToggle from 'App/utils/hooks/useToggle';
import {COMPANY_INTEGRATION_SMC3} from 'App/data-hooks/queryKeys';
import Loader from 'App/common/shipwellLoader';

export const validationSchema = object().shape({
  username: string().required('Username is required.'),
  password: string().required('Password is required.'),
  license_key: string().required('License key is required.')
});

const initialValues = {
  username: '',
  password: '',
  license_key: ''
};

function Smc3Details({setSuccess}: WithStatusToastProps) {
  const {intSmc3} = useFlags();
  const [showDisconnectModal, toggleDisconnectModal] = useToggle();
  const queryClient = useQueryClient();
  const connectSmc3Mutation = useMutation(connectSmc3);
  const disconneSmc3Mutation = useDisconnectSmc3Mutation({
    onSuccess: () => {
      void queryClient.resetQueries([COMPANY_INTEGRATION_SMC3]);
      browserHistory.goBack();
    }
  });
  const {data, isLoading} = useGetSmc3Query({retry: false});

  const isSmc3Connected = Boolean(data);

  if (!intSmc3) return null;
  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <>
      {isSmc3Connected ? (
        <div className="flex justify-end pb-6">
          <DeprecatedButton variant="secondary" onClick={() => toggleDisconnectModal()}>
            Disconnect
          </DeprecatedButton>
        </div>
      ) : null}
      <Card
        draggableProvided={() => undefined}
        title={
          <FlexBox items="center" gap="s">
            <img className="h-4" src="/images/svgs/smc3_logo.svg" alt="SMC3 logo" />
            <Title>SMC3</Title>
          </FlexBox>
        }
        actions={<DeprecatedButton variant="tertiary">Configure</DeprecatedButton>}
      >
        <p>
          To enable the SMC3 integration, you will need to enter an organization username and password. This will then
          be used to validate each user. Each user must have their own DAT account in order to activate the integration
        </p>
        <Formik
          enableReinitialize
          initialValues={{...initialValues, ...data?.account}}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            connectSmc3Mutation.mutate(values, {
              onSuccess: () => {
                void queryClient.invalidateQueries([COMPANY_INTEGRATION_SMC3]);
                setSuccess('Success', 'Updated account information');
              }
            });
          }}
        >
          {() => (
            <Form>
              <FlexBox direction="col" gap="m">
                <GridBox cols={2} gap="m">
                  <Field
                    name="username"
                    label="Username"
                    disabled={isSmc3Connected}
                    component={FormikTextInput}
                    required
                  />
                  <Field
                    name="password"
                    label="Password"
                    disabled={isSmc3Connected}
                    component={FormikTextInput}
                    type="password"
                    required
                  />
                </GridBox>
                <Field
                  name="license_key"
                  label="License Key"
                  disabled={isSmc3Connected}
                  component={FormikTextInput}
                  required
                />
                <FlexBox justify="end">
                  <DeprecatedButton type="submit" disabled={isSmc3Connected}>
                    Enable
                  </DeprecatedButton>
                </FlexBox>
              </FlexBox>
            </Form>
          )}
        </Formik>
      </Card>
      <Modal
        show={showDisconnectModal}
        title="Disconnect"
        variant="warning"
        onClose={() => toggleDisconnectModal()}
        primaryBtnName="Disconnect"
        onPrimaryAction={disconneSmc3Mutation.mutate}
      >
        You will lose all connected account information if you choose to disconnect from SMC3. Are you sure about that?
      </Modal>
    </>
  );
}

export default WithStatusToasts(Smc3Details);
