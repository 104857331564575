import {MouseEvent} from 'react';
import {StorageServiceTypeEnum, TerminalFeeServiceTypeEnum, TransloadServiceTypeEnum} from '@shipwell/corrogo-sdk';
import {InjectedRouter, RouterState, withRouter} from 'react-router';
import {Modal, DeprecatedButton} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import buildPathParams from 'App/utils/buildPathParams';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import useToggle from 'App/utils/hooks/useToggle';
import NewStageForm from 'App/containers/shipments/components/DashboardSummary/NewStageForm';
import {
  GENERIC_UPDATE_ERROR_MESSAGE,
  GENERIC_UPDATE_MESSAGE
} from 'App/containers/shipments/components/DashboardSummary/constants';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {useUserPermissions} from 'App/data-hooks';
import {
  VIEW_SHIPMENTS_USER_PERMISSION,
  VIEW_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

interface IRow {
  value: string;
  original: {row_type?: string; mode?: {code: string}; id: string; v3_shipment_id: string};
}

const ServiceIdCell = ({
  row,
  router,
  setSuccess,
  setError
}: {
  row: IRow;
  router: InjectedRouter & RouterState<{reload: boolean}>;
} & WithStatusToastProps) => {
  const [showEditStageModal, toggleShowEditStageModal] = useToggle();
  const permissions = useUserPermissions([VIEW_SHIPMENTS_USER_PERMISSION, VIEW_MY_SHIPMENTS_USER_PERMISSION]);

  return permissions[VIEW_SHIPMENTS_USER_PERMISSION] || permissions[VIEW_MY_SHIPMENTS_USER_PERMISSION] ? (
    <>
      <DeprecatedButton
        variant="tertiary"
        onClick={(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
          // don't trigger a row click when we click this button
          e.stopPropagation();
          toggleShowEditStageModal();
        }}
      >
        {row.value}
      </DeprecatedButton>
      {showEditStageModal ? (
        <Modal
          show={showEditStageModal}
          onClose={toggleShowEditStageModal}
          title="Edit Stage"
          bodyVariant="disableOverflowScroll"
          footerComponent={null}
        >
          <NewStageForm
            shipmentId={row.original.v3_shipment_id}
            resourceId={row.original.id}
            onClose={toggleShowEditStageModal}
            serviceType={
              row.original.mode?.code === 'TRANSLOAD'
                ? TransloadServiceTypeEnum.Transload
                : row.original.mode?.code === 'TERMINAL_FEE'
                ? TerminalFeeServiceTypeEnum.TerminalFee
                : StorageServiceTypeEnum.Storage
            }
            handleSuccess={() => {
              // reload the dashboard with the existing parameters to get the up to date data post-edit
              router.push(buildPathParams(router.location, {reload: true}));
              setSuccess(`${startCaseToLower(row.original.mode?.code)} Stage Updated!`, GENERIC_UPDATE_MESSAGE);
            }}
            handleError={() => setError('Error!', GENERIC_UPDATE_ERROR_MESSAGE)}
          />
        </Modal>
      ) : null}
    </>
  ) : (
    <div>{row.value}</div>
  );
};

export default compose<
  {
    router: InjectedRouter & RouterState<{reload: boolean}>;
    row: IRow;
  } & WithStatusToastProps,
  unknown
>(
  withRouter,
  WithStatusToasts
)(ServiceIdCell);
