import {ChangeEvent, MouseEvent} from 'react';
import {ShipmentMetadataAlertWithShipment, ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Checkbox, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {Link} from 'react-router';
import {useIsFetching, useQuery, useQueryClient} from '@tanstack/react-query';
import {V3_VERSION} from '../utils/constants';
import {FlexBox} from 'App/components/Box';
import {getShipmentTags} from 'App/api/shipment/typed';
import {SHIPMENT_METADATA_ALERTS_KEY, TAGS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import StopFlagsTooltip from 'App/containers/Shipment/components/StopFlagsTooltip';

export const IdCell = ({
  shipment,
  isSelected,
  onSelect,
  disabled
}: {
  shipment: ShippingDashboardRow;
  isSelected: boolean;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  const isV3 = shipment.version === V3_VERSION;
  const v3ShipmentURI = `/shipments/${shipment.v3_shipment_id || ''}`;
  const tagIds = shipment.metadata?.tags;

  const tagsQuery = useQuery(
    [TAGS_QUERY_KEY],
    async () => {
      const response = await getShipmentTags();
      return response.data;
    },
    {
      // Infinity staleTime ensures that this call is not made for every row in the table
      // These tags really only need to be called once when the app loads
      staleTime: Infinity
    }
  );
  const tags = tagsQuery.data?.filter((tag) => tagIds?.some((tagId) => tagId === tag.id));

  const queryClient = useQueryClient();
  const isFetching = useIsFetching([SHIPMENT_METADATA_ALERTS_KEY, 'list']) > 0;

  const getShipmentMetadataAlerts = () => {
    if (!shipment.id) {
      return;
    }
    const shipmentMetadataQueries = queryClient.getQueriesData([SHIPMENT_METADATA_ALERTS_KEY, 'list']);
    const shipmentMetadataByShipmentId = shipmentMetadataQueries.reduce((alertsByShipment: string[], statsQuery) => {
      const [, queryData] = statsQuery;
      const typedQueryData = queryData as ShipmentMetadataAlertWithShipment[] | undefined;
      if (!typedQueryData?.length) {
        return alertsByShipment;
      }
      // look into each queryData array to find metadata errors that match the current shipment.id
      const matchingShipmentMetadataAlerts = typedQueryData.filter((data) => data.shipment_id === shipment.id);
      if (matchingShipmentMetadataAlerts.length) {
        return matchingShipmentMetadataAlerts.map((alert) => alert.message || '');
      }
      return alertsByShipment;
    }, []);
    return shipmentMetadataByShipmentId;
  };

  const metadataAlerts = getShipmentMetadataAlerts();
  const allAlerts = [
    ...new Set([
      ...(metadataAlerts?.length ? metadataAlerts : []),
      ...(shipment.metadata?.alert_message ? [shipment.metadata.alert_message] : [])
    ])
  ];
  return (
    <FlexBox gap="s" items="start">
      <Checkbox
        aria-label="select row"
        name={shipment.reference_id || ''}
        checked={isSelected}
        onChange={onSelect}
        onClick={(evt: MouseEvent) => evt.stopPropagation()}
        fixedHeight={false}
        disabled={disabled}
      />
      <FlexBox direction="col" gap="s">
        <FlexBox gap="s" items="center">
          <Link
            className="shipmentLink"
            role="link"
            to={isV3 ? v3ShipmentURI : `${shipment.state === 'draft' ? 'new-shipment' : 'shipments'}/${shipment.id}`}
          >
            {shipment.reference_id}
          </Link>
          {metadataAlerts?.length ? (
            <Tooltip
              portal
              placement="top"
              tooltipContent={
                <>
                  {allAlerts.map((alert, i) => {
                    if (typeof alert !== 'string') {
                      return null;
                    }
                    return <li key={`${alert}${i}`}>{alert}</li>;
                  })}
                </>
              }
            >
              <SvgIcon name="WarningFilled" height="20" width="20" color="sw-error" />
            </Tooltip>
          ) : isFetching ? (
            <SvgIcon name="LoadingDots" />
          ) : null}
          <StopFlagsTooltip stops={shipment.stops} />
        </FlexBox>
        {tags?.length ? (
          <FlexBox wrap="wrap" gap="s">
            {tags.slice(0, 3).map((tag) => (
              <Tooltip key={tag.id} portal placement="bottom" tooltipContent={tag.name}>
                <div
                  className={classNames('tag-option size-4 rounded-full', {[tag?.color || '']: Boolean(tag.color)})}
                  aria-label={tag.name ? `shipment has ${tag.name} tag` : ''}
                />
              </Tooltip>
            ))}
            {tags.length > 3 ? (
              <Tooltip
                tooltipContent={
                  <FlexBox direction="col" gap="s">
                    {tags.slice(3).map((tag) => (
                      <FlexBox key={tag.id} gap="s">
                        <div
                          className={classNames('tag-option size-4 rounded-full', {
                            [tag?.color || '']: Boolean(tag.color)
                          })}
                          aria-label={tag.name ? `shipment has ${tag.name} tag` : ''}
                        />
                        <span>{tag.name}</span>
                      </FlexBox>
                    ))}
                  </FlexBox>
                }
              >
                <SvgIcon name="Plus" height="16" width="16" />
              </Tooltip>
            ) : null}
          </FlexBox>
        ) : null}
      </FlexBox>
    </FlexBox>
  );
};
