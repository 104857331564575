import {useQuery} from '@tanstack/react-query';
import {getProductCategories} from 'App/api/products/typed';
import {PRODUCT_CATEGORIES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export const useGetProductCategories = () => {
  return useQuery(
    [PRODUCT_CATEGORIES_QUERY_KEY],
    async () => {
      const response = await getProductCategories();
      return response.data;
    },
    {
      staleTime: HOT_DATA_STALE_TIME
    }
  );
};
