import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';
import {ManageTrackingDevicesModal} from './manageTrackingDevicesModal/manageTrackingDevicesModal';
import {useActiveTrackingDevices} from './useActiveTrackingDevices/useActiveTrackingDevices';

export interface ManageTrackingDevicesActionProps {
  shipmentId: string;
  onUpdate: () => void;
}

export function ManageTrackingDevicesAction(props: ManageTrackingDevicesActionProps) {
  const modal = useVisibility();
  const checkService = useActiveTrackingDevices();

  return (
    <>
      <DeprecatedButton
        variant="text"
        loading={checkService.isFetching}
        disabled={!checkService.isServicesConnected}
        onClick={modal.toggle}
      >
        Manage Tracking Devices
      </DeprecatedButton>

      <ManageTrackingDevicesModal show={modal.isVisible} shipmentId={props.shipmentId} onClose={modal.toggle} />
    </>
  );
}
