import {useQuery} from '@tanstack/react-query';
import {ServiceProvider, CarrierTag, ServiceProviderCapabilitiesEnum} from '@shipwell/backend-core-sdk';
import {SERVICE_PROVIDERS_QUERY_KEY, CARRIER_TAGS_KEY} from 'App/data-hooks/queryKeys';
import {getServiceProviders} from 'App/api/serviceProviders/typed';
import {getCarrierTags} from 'App/api/carriers/typed';

const formatServiceProvidersToDisplayValues = (serviceProviders: ServiceProvider[], providerTags: CarrierTag[]) => {
  const capabilitiesOptions = Object.entries(ServiceProviderCapabilitiesEnum).map(([label, value]) => ({label, value}));

  return serviceProviders.map((serviceProvider) => {
    return {
      id: serviceProvider.id,
      name: serviceProvider.name || '--',
      status: serviceProvider.status,
      email: serviceProvider.contacts?.[0]?.email || '--',
      phoneNumber: serviceProvider.contacts?.[0]?.phone_number,
      contacts:
        serviceProvider.contacts?.map(({first_name, last_name}) => `${first_name} ${last_name || ''}`).join(', ') ||
        '--',
      address: serviceProvider.mailing_address?.formatted_address || '--',
      tags: serviceProvider.tags?.map((tagId) => providerTags.find((tag) => tag.id === tagId)?.name).join(', ') || '--',
      capabilities:
        serviceProvider.capabilities
          ?.map((capability) => capabilitiesOptions.find((option) => option?.value === capability)?.label)
          .join(', ') || '--'
    };
  });
};

const useServiceProviders = (opts = {}, queryOptions = {}) => {
  const serviceProvidersQuery = useQuery(
    [SERVICE_PROVIDERS_QUERY_KEY, opts],
    async () => {
      const response = await getServiceProviders(opts);
      return response.data;
    },
    queryOptions
  );

  const tagsQuery = useQuery([CARRIER_TAGS_KEY], async () => {
    const response = await getCarrierTags({pageSize: 1000, ordering: 'name'});
    return response.results;
  });
  const providerTags = tagsQuery.data || [];

  const serviceProviders = serviceProvidersQuery.data?.data || [];
  const serviceProvidersDisplayValues = formatServiceProvidersToDisplayValues(serviceProviders, providerTags);

  return {
    isLoading: serviceProvidersQuery.isInitialLoading,
    serviceProvidersQuery,
    serviceProvidersDisplayValues,
    providerTags
  };
};

export default useServiceProviders;
