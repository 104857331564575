import {Component} from 'react';
import {connect} from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead';
import FormGroup from '../../formGroup';
import {fetchHazmatCodes} from 'App/actions/shipments';

/**
 * Hazmat Search Field
 * @description Search hazmat details and codes from Shipwell API
 */
class HazmatSearchField extends Component {
  static defaultProps = {
    multi: false,
    disabled: false,
    cache: false,
    placeholder: 'Select hazmat code',
    icon: 'icon-Search'
  };

  componentDidMount() {
    const {hazmatCodes} = this.props;

    if (hazmatCodes && !hazmatCodes.length) {
      this.props.dispatch(fetchHazmatCodes());
    }
  }

  renderLabelKey(option) {
    const formattedOptions = [
      option.identification_number || option.hazmat_identification_number,
      option.proper_shipping_name || option.hazmat_proper_shipping_name,
      option.hazard_class || option.hazmat_hazard_class,
      option.packing_group || option.hazmat_packing_group
    ];
    return formattedOptions.filter((a) => !!a).join(' - ');
  }

  updatePackingGroup(hazmatCodes) {
    const defaultPackingGroup = 'II';

    return hazmatCodes.map((code) => {
      if (code && code.identification_number && !code.packing_group) {
        code.packing_group = defaultPackingGroup;
      }

      if (code && code.hazmat_identification_number && !code.hazmat_packing_group) {
        code.hazmat_packing_group = defaultPackingGroup;
      }

      return code;
    });
  }

  render() {
    const {input, req, cache, placeholder, hazmatCodes, ...rest} = this.props;

    const isLoading = !hazmatCodes || !hazmatCodes.length;
    let value = '';

    if (
      input.value &&
      (input.value.hazmat_hazard_class ||
        input.value.hazard_class ||
        input.value.hazmat_identification_number ||
        input.value.identification_number ||
        input.value.hazmat_packing_group ||
        input.value.packing_group ||
        input.value.hazmat_proper_shipping_name ||
        input.value.proper_shipping_name)
    ) {
      value = input.value;
    }

    return (
      <FormGroup {...this.props} className="address-search">
        {() => (
          <Typeahead
            {...rest}
            name={input.name}
            multiple={false}
            allowNew={false}
            cache={cache}
            isLoading={isLoading}
            selected={value ? [value] : []}
            placeholder={placeholder}
            options={this.updatePackingGroup(hazmatCodes)}
            labelKey={this.renderLabelKey.bind(this)}
            onChange={([selection]) => {
              input.onChange(selection);
            }}
          />
        )}
      </FormGroup>
    );
  }
}

export default connect((state) => ({
  hazmatCodes: state.shipments.hazmatCodes || []
}))(HazmatSearchField);
