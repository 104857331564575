import _ from 'lodash';
import {Component, Fragment} from 'react';
import moment from 'moment';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {InputGroup, Button, FormGroup, FormControl} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import * as actions from 'App/actions/shipmentdetails';
import * as carriersActions from 'App/actions/carriers';
import * as shipmentsActions from 'App/actions/shipments';
import {numberWithCommas, loadGoogleMapsAPI, formatDateTime} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import EquipmentConfigForm from 'App/containers/Dispatch/EquipmentConfigForm';
import {options} from 'App/utils/map_options';

import './_dispatch.scss';
import ShipwellLoader from 'App/common/shipwellLoader/index';

@connect(
  (state) => ({
    powerUnit: state.carriers.powerUnit,
    drivers: state.carriers.drivers,
    one: state.shipmentdetails.one,
    powerUnitLocations: state.carriers.powerUnitLocations,
    company: state.auth.company,
    hos: state.carriers.hos,
    formValues: state.form.EquipmentConfigForm
  }),
  {...actions, ...carriersActions, ...shipmentsActions}
)
export default class DisplayLoad extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.getLocation = this.getLocation.bind(this);
    this.goToShipment = this.goToShipment.bind(this);
    this.triggerRefresh = this.triggerRefresh.bind(this);
    this.renderEquipmentConfigModal = this.renderEquipmentConfigModal.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.clickMarker = this.clickMarker.bind(this);
    this.setMarkerBounce = this.setMarkerBounce.bind(this);
    this.removeMarkerBounce = this.removeMarkerBounce.bind(this);
    this.getPULocations = this.getPULocations.bind(this);
    this.getEquipmentConfig = this.getEquipmentConfig.bind(this);
    this.state = {
      showEquipmentConfigModal: false,
      isManualInput: true,
      isLoading: false,
      isHOSLoading: false,
      markerArray: [],
      displayMarkerArray: [],
      showDriverList: false,
      update: false,
      dispatchTimeout: undefined,
      showLegend: false,
      equipmentArray: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!_.isEmpty(this.props.company)) {
      if (this.props.activeInfoTab === 'assigned') {
        this.getEquipmentConfig();
      }

      this.props.getPowerUnitLocations(this.props.company.carrier.id).then((response) => {
        if (response.status === 200) {
          const markerArray = [];
          for (let i = 0; i < this.props.dispatchShipments.length; i++) {
            const marker = this.getLocation(this.props.dispatchShipments[i], i);
            if (marker && !_.isEmpty(marker)) {
              markerArray.push(marker);
            }
          }
          this.setState(
            {markerArray: markerArray},
            function () {
              if (typeof google !== 'undefined') {
                this.initialize(this.triggerRefresh);
              } else {
                loadGoogleMapsAPI(() => {
                  this.initialize(this.triggerRefresh);
                });
              }
              if (this.props.activeInfoTab === 'unassigned') {
                if (!_.isEmpty(this.props.company)) {
                  this.setState(
                    {isHOSLoading: true},
                    function () {
                      this.props.getAllHOS(this.props.company.carrier.id).then((response) => {
                        this.setState({isHOSLoading: false});
                      });
                    }.bind(this)
                  );
                  this.getPULocations();
                }
              }
            }.bind(this)
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dispatchShipments !== prevProps.dispatchShipments) {
      if (this._isMounted) {
        this.setState(
          {markerArray: []},
          function () {
            if (this.props.activeInfoTab === 'assigned') {
              this.getEquipmentConfig();
            }
            this.props.getPowerUnitLocations(this.props.company.carrier.id).then((response) => {
              if (response.status === 200) {
                const markerArray = [];
                for (let i = 0; i < this.props.dispatchShipments.length; i++) {
                  const marker = this.getLocation(this.props.dispatchShipments[i], i);
                  if (marker && !_.isEmpty(marker)) {
                    markerArray.push(marker);
                  }
                }
                this.setState(
                  {markerArray: markerArray},
                  function () {
                    if (typeof google !== 'undefined') {
                      this.initialize(this.triggerRefresh);
                    } else {
                      loadGoogleMapsAPI(() => {
                        this.initialize(this.triggerRefresh);
                      });
                    }
                    if (this.props.activeInfoTab === 'unassigned') {
                      if (!_.isEmpty(this.props.company)) {
                        this.setState(
                          {isHOSLoading: true},
                          function () {
                            this.props.getAllHOS(this.props.company.carrier.id).then((response) => {
                              this.setState({isHOSLoading: false});
                            });
                          }.bind(this)
                        );
                        this.getPULocations();
                      }
                    }
                  }.bind(this)
                );
              }
            });
          }.bind(this)
        );
      }
    }
    if (prevProps.activeInfoTab !== this.props.activeInfoTab) {
      this.setState({markerArray: [], displayMarkerArray: []});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.state.mapTimeout);
  }

  getEquipmentConfig() {
    if (this.props.dispatchShipments && this.props.dispatchShipments.length > 0) {
      for (let i = 0; i < this.props.dispatchShipments.length; i++) {
        this.props.fetchEquipmentConfig(this.props.dispatchShipments[i].id).then((response) => {
          if (response.status === 200) {
            const equipmentArray = JSON.parse(JSON.stringify(this.state.equipmentArray));
            const equipment_config = response.details;
            equipment_config.shipment_id = this.props.dispatchShipments[i].id;
            equipmentArray.push(equipment_config);
            this.setState({equipmentArray: equipmentArray});
          }
        });
      }
    }
  }

  getPULocations() {
    const markerArray = JSON.parse(JSON.stringify(this.state.markerArray));
    if (this.props.powerUnitLocations && this.props.powerUnitLocations.vehicle_locations) {
      for (let i = 0; i < this.props.powerUnitLocations.vehicle_locations.length; i++) {
        const vehicle = this.props.powerUnitLocations.vehicle_locations[i];
        vehicle.i = -1;
        markerArray.push(vehicle);
      }
      if (this._isMounted) {
        this.setState(
          {markerArray: markerArray},
          function () {
            if (typeof google !== 'undefined') {
              this.putMarkersOnMap(this.state.markerArray);
            }
          }.bind(this)
        );
      }
    }
  }

  initialize(callback) {
    if (this.refs.mapDispatch) {
      this.map = new google.maps.Map(this.refs.mapDispatch, options);
    }
    window.infowindow = new google.maps.InfoWindow(options.infoWindow);
    google.maps.event.addListener(this.map, 'click', function (event) {
      window.infowindow.close();
    });
    callback();
  }

  triggerRefresh() {
    const dispatchTimeout = setTimeout(
      function () {
        google.maps.event.trigger(this.map, 'resize');
        if (this.state.markerArray && this.state.markerArray.length > 0) {
          this.resetMap();
          this.putMarkersOnMap(this.state.markerArray);
        } else {
          this.resetMap();
        }
      }.bind(this),
      1500
    );
    if (this._isMounted) {
      this.setState({dispatchTimeout: dispatchTimeout});
    }
  }

  resetMap() {
    if (this.map) {
      //this.map.setCenter(options.center);
      //this.map.setZoom(options.zoom);
      for (var i = 0; i < this.state.displayMarkerArray.length; i++) {
        this.state.displayMarkerArray[i].setMap(null);
      }
      if (this._isMounted) {
        this.setState({displayMarkerArray: []});
      }
    }
  }

  putMarkersOnMap(markerArray) {
    for (let i = 0; i < this.state.displayMarkerArray.length; i++) {
      this.state.displayMarkerArray[i].setMap(null);
    }
    //put the markers on the map
    const mapBounds = new google.maps.LatLngBounds();
    const markers = this.state.displayMarkerArray;

    for (let i = 0; i < markerArray.length; i++) {
      var icon;
      icon =
        this.props.activeInfoTab === 'assigned'
          ? {url: '/images/img/truck_map_icons/truck-2.png'}
          : markerArray[i].i === -1
          ? {url: '/images/img/truck_map_icons/truck-2.png'}
          : {
              url: '/images/map/marker-generic.png'
            };
      const marker = new google.maps.Marker({
        position: {lat: markerArray[i].lat, lng: markerArray[i].lon},
        map: this.map,
        icon: icon,
        i: markerArray[i].i
      });
      const shipmentsContainer = document.getElementsByClassName('dispatch-load__shipments-container');

      if (shipmentsContainer && shipmentsContainer.length > 0) {
        const shipments = shipmentsContainer[0].childNodes;
        if (marker.i !== -1) {
          marker.addListener(
            'click',
            function () {
              shipments[marker.i].scrollIntoView({behavior: 'smooth'});
              this.clickMarker(marker.i);
            }.bind(this)
          );
        } else {
          marker.addListener(
            'click',
            function () {
              this.map.panTo(marker.position);
              const powerUnits = this.props.powerUnits ? this.props.powerUnits.results : [];
              let name = '';
              for (let j = 0; j < powerUnits.length; j++) {
                if (powerUnits[j].external_id === markerArray[i].vehicle_id) {
                  name = powerUnits[j].name;
                }
              }
              let string = '<div><strong>' + name + '</strong></div>';
              if (markerArray[i].location_name) {
                string += '<div>' + markerArray[i].location_name;
                window.infowindow.setContent(string);
                setTimeout(
                  function () {
                    window.infowindow.open(this.map, marker);
                  }.bind(this)
                );
              } else {
                let location;
                const address = [];
                if (markerArray[i].address) {
                  if (markerArray[i].address.city) {
                    address.push(markerArray[i].address.city);
                  }
                  if (markerArray[i].address.state) {
                    let myString = markerArray[i].address.state;
                    if (markerArray[i].address.postal_code) {
                      myString += ' ' + markerArray[i].address.postal_code;
                    }
                    address.push(myString);
                  }

                  if (markerArray[i].address.country) {
                    address.push(markerArray[i].address.country);
                  }
                }
                if (address.length > 0) {
                  location = address.join(', ');
                }

                string += '<div>' + location + '</div>';
                //show infowindow on hover on marker

                window.infowindow.setContent(string);
                setTimeout(
                  function () {
                    window.infowindow.open(this.map, marker);
                  }.bind(this)
                );
              }
            }.bind(this)
          );
        }
      }
      if (markerArray[i].labelContent) {
        marker.set('labelContent', markerArray[i].labelContent);
      }
      markers.push(marker);

      mapBounds.extend(marker.position);
    }
    if (this._isMounted) {
      this.setState({displayMarkerArray: markers});
    }
    this.map.fitBounds(mapBounds);
  }

  goToShipment(shipmentId) {
    this.context.router.push('/shipments/' + shipmentId);
  }

  getLocation(shipment, i) {
    let marker = {};
    let equipment_config;
    for (let i = 0; i < this.state.equipmentArray.length; i++) {
      if (shipment.id === this.state.equipmentArray[i].shipment_id) {
        equipment_config = this.state.equipmentArray[i];
      }
    }
    if (this.props.activeInfoTab === 'assigned') {
      if (this.props.powerUnitLocations) {
        for (let j = 0; j < this.props.powerUnitLocations.vehicle_locations.length; j++) {
          if (
            equipment_config &&
            equipment_config.power_unit &&
            equipment_config.power_unit.id === this.props.powerUnitLocations.vehicle_locations[j].unit_id
          ) {
            marker = this.props.powerUnitLocations.vehicle_locations[j];
            marker.i = i;
            marker.shipment = shipment.reference_id;
            marker.driver =
              equipment_config && equipment_config.driver.user
                ? equipment_config.driver.user.first_name + ' ' + equipment_config.driver.user.last_name
                : '';
            marker.power_unit = equipment_config && equipment_config.power_unit ? equipment_config.power_unit.name : '';
            marker.trailer = equipment_config && equipment_config.trailer ? equipment_config.trailer.name : '';
          }
        }
      }
    } else {
      if (shipment.stops.length > 0) {
        marker.lat = shipment.stops[0].location.address.latitude;
        marker.lon = shipment.stops[0].location.address.longitude;
        marker.address = shipment.stops[0].location.address;
        marker.i = i;
        marker.shipment = shipment.reference_id;
        marker.driver =
          equipment_config && equipment_config.driver && equipment_config.driver.user
            ? equipment_config.driver.user.first_name + ' ' + equipment_config.driver.user.last_name
            : 'No driver';
        marker.power_unit =
          equipment_config && equipment_config.power_unit ? equipment_config.power_unit.name : 'No power unit';
        marker.trailer = equipment_config && equipment_config.trailer ? equipment_config.trailer.name : 'No trailer';
      }
    }
    return marker;
  }

  onFormSubmit(attrs) {
    const body = {
      carrier: attrs.carrier,
      driver: {
        phone_number: attrs.driver && attrs.driver.phone_number ? attrs.driver.phone_number : attrs.driver
      },
      trailer: attrs.trailer && attrs.trailer.id ? attrs.trailer.id : attrs.trailer,
      power_unit: attrs.power_unit && attrs.power_unit.id ? attrs.power_unit.id : attrs.power_unit
    };
    return this.props.assignEquipmentConfig(this.props.one.id, body).then((response) => {
      if (response.status === 200) {
        this.setState({update: true});
        this.props.obtainShipments();
        setTimeout(
          function () {
            this.setState({showEquipmentConfigModal: false, update: false});
          }.bind(this),
          2000
        );
      }
    });
  }

  convertMinToHourMin(min) {
    const h = Math.floor(min / 60);
    const m = min % 60;
    return (h.toString().length < 2 ? '0' + h : h) + ' hr ' + (m.toString().length < 2 ? '0' + m : m) + ' min';
  }

  clickMarker(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    const markerArray = this.state.markerArray;
    let myIndex;

    for (let i = 0; i < markerArray.length; i++) {
      if (markerArray[i].i === index) {
        myIndex = i;
      }
    }

    const marker = displayMarkerArray[myIndex];
    if (marker) {
      const myMap = window.infowindow.getMap();
      if (myMap) {
        window.infowindow.close(this.map, marker);
      }
      this.map.panTo(marker.position);

      let string =
        '<div>SHIPMENT# <strong>' +
        markerArray[myIndex].shipment +
        '</strong></div><div>' +
        (markerArray[myIndex].event_description
          ? markerArray[myIndex].event_description.split('_').join(' ')
          : 'No events') +
        '</div><div>' +
        markerArray[myIndex].driver +
        '</div><div>' +
        markerArray[myIndex].power_unit +
        '</div><div>' +
        markerArray[myIndex].trailer +
        '</div><div>' +
        (markerArray[myIndex].speed ? (markerArray[myIndex].speed * 0.621371).toFixed(2) : (0).toFixed(2)) +
        ' mph</div>';
      if (markerArray[myIndex].location_name) {
        string += '<div>' + markerArray[myIndex].location_name;
        window.infowindow.setContent(string);
        setTimeout(
          function () {
            window.infowindow.open(this.map, marker);
          }.bind(this)
        );
      } else {
        let location;
        const address = [];
        if (markerArray[myIndex].address) {
          if (markerArray[myIndex].address.city) {
            let myString;
            myString = markerArray[myIndex].address.address_1;
            if (markerArray[myIndex].address.address_2) {
              myString += ' ' + markerArray[myIndex].address.address_2;
            }
            if (myString) {
              address.push(myString);
            }
          }
          if (markerArray[myIndex].address.city) {
            address.push(markerArray[myIndex].address.city);
          }
          if (markerArray[myIndex].address.state_province) {
            let myString = markerArray[myIndex].address.state_province;
            if (markerArray[myIndex].address.postal_code) {
              myString += ' ' + markerArray[myIndex].address.postal_code;
            }
            if (myString) {
              address.push(myString);
            }
          }

          if (markerArray[myIndex].address.country) {
            address.push(markerArray[myIndex].address.country);
          }
        }
        if (address.length > 0) {
          location = address.join(', ');
        }
        string += '<div>' + location + '</div>';
        //show infowindow on hover on marker

        window.infowindow.setContent(string);
        setTimeout(
          function () {
            window.infowindow.open(this.map, marker);
          }.bind(this)
        );
      }
    }
  }

  setMarkerBounce(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    let myIndex;
    for (let i = 0; i < this.state.markerArray.length; i++) {
      if (this.state.markerArray[i].i === index) {
        myIndex = i;
      }
    }

    if (displayMarkerArray[myIndex]) {
      displayMarkerArray[myIndex].setAnimation(google.maps.Animation.BOUNCE);
    }
  }

  removeMarkerBounce(index) {
    const displayMarkerArray = this.state.displayMarkerArray;
    let myIndex;
    for (let i = 0; i < this.state.markerArray.length; i++) {
      if (this.state.markerArray[i].i === index) {
        myIndex = i;
      }
    }
    if (displayMarkerArray[myIndex]) {
      displayMarkerArray[myIndex].setAnimation(-1);
    }
  }

  renderEquipmentConfigModal() {
    return (
      <EquipmentConfigForm
        powerUnits={this.props.powerUnits}
        trailers={this.props.trailers}
        drivers={this.props.drivers}
        onSubmit={this.onFormSubmit}
        activeInfoTab={this.props.activeInfoTab}
        isLoading={this.state.isLoading}
        update={this.state.update}
        formValues={this.props.formValues}
      />
    );
  }

  render() {
    return (
      <div className="dispatch-load">
        {this.props.isLoading ? (
          <Paper className="dispatch-load__loading">
            <ShipwellLoader loading={this.props.isLoading} />
          </Paper>
        ) : (
          <Paper className="dispatch-load__shipments">
            <div className="dispatch-load__shipments-toolbar">
              <FormGroup>
                <InputGroup>
                  <InputGroup.Button>
                    <Button onClick={this.props.loadSearch}>
                      <i className="icon icon-Search" />
                    </Button>
                  </InputGroup.Button>
                  <FormControl
                    name="search"
                    type="text"
                    value={this.props.searchValue}
                    placeholder="Search..."
                    onChange={this.props.searchDataTable}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.props.loadSearch();
                      }
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <div className="dispatch-load__shipments-toolbar-btns">
                {this.props.activeInfoTab === 'unassigned' && (
                  <div className="driver-container">
                    <div className={'driver-list ' + (this.state.showDriverList ? '' : 'hidden')}>
                      {this.props.drivers && this.props.drivers.length > 0 ? (
                        this.props.drivers.results.map((el, idx) => {
                          let myStatus;
                          let myHOS;
                          if (this.props.hos && this.props.hos.results && this.props.hos.results.length > 0) {
                            for (let i = 0; i < this.props.hos.results.length; i++) {
                              if (el.external_id === this.props.hos.results[i].driver_id) {
                                myHOS = this.props.hos.results[i].hos;
                                myStatus = this.props.hos.results[i].status;
                              }
                            }
                          }

                          return (
                            <div key={el.id} className="driver-list-driver">
                              <div className="user">
                                <div>
                                  <i className="icon icon-User" />
                                </div>
                                <div>
                                  <div>{el.user.first_name + ' ' + el.user.last_name}</div>
                                  <div>
                                    <i className="icon icon-Phone2 pad-right" />
                                    {el.phone_number ? getPhoneHyperlink(el.phone_number) : ''}
                                  </div>
                                  <div>
                                    <a href={'mailto:' + el.user.email}>
                                      <i className="icon icon-Mail pad-right" />
                                      {el.user.email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {this.state.isHOSLoading ? (
                                <div>
                                  <i className="icon icon-Restart rotate" />
                                  &nbsp;Loading
                                </div>
                              ) : (
                                <div>{myStatus ? myStatus.split('_').join(' ').toUpperCase() : 'NO STATUS'}</div>
                              )}
                              {this.state.isHOSLoading ? (
                                <div>
                                  <i className="icon icon-Restart rotate" />
                                  &nbsp;Loading
                                </div>
                              ) : (
                                <div>
                                  <Row>
                                    <Col sm={4}>Cycle:</Col>
                                    <Col sm={8}>{myHOS ? this.convertMinToHourMin(myHOS.cycle) : '--'}</Col>
                                  </Row>
                                  <Row>
                                    <Col sm={4}>Drive:</Col>
                                    <Col sm={8}>{myHOS ? this.convertMinToHourMin(myHOS.drive) : '--'}</Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div>No Drivers</div>
                      )}
                    </div>

                    <div
                      className="driver-list-btn"
                      onClick={() => {
                        if (!this.state.showDriverList) {
                          this.setState({showDriverList: !this.state.showDriverList, showLegend: false});
                        } else {
                          this.setState({showDriverList: !this.state.showDriverList});
                        }
                      }}
                    >
                      <i className="icon icon-Drivers" />
                    </div>
                  </div>
                )}
                <div className="legends-container">
                  <div
                    className="legends-btn"
                    onClick={() => {
                      if (!this.state.showLegend) {
                        this.setState({showLegend: !this.state.showLegend, showDriverList: false});
                      } else {
                        this.setState({showLegend: !this.state.showLegend});
                      }
                    }}
                  >
                    <i className="icon icon-Info" />
                  </div>
                  <div className={'legends-items' + (this.state.showLegend ? '' : ' hidden')}>
                    <h4>Legend</h4>
                    <div className="legends-item">
                      <img className="pad-right" src="/images/img/truck_map_icons/truck-2.png" alt="truck icon" />
                      <span>Asset</span>
                    </div>
                    {this.props.activeInfoTab === 'unassigned' && (
                      <div className="legends-item">
                        <img className="pad-right" src="/images/map/marker-generic.png" alt="shipment icon" />
                        <span>Shipment</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="dispatch-load__shipments-container">
              {this.props.dispatchShipments && this.props.dispatchShipments.length > 0 ? (
                this.props.dispatchShipments.map((el, index) => {
                  const sortedStops = el.stops.sort((a, b) => a.ordinal_index - b.ordinal_index);

                  const shipmentStatus = (
                    <div className="status">
                      {el.state === 'carrier_confirmed' || el.state === 'quote_accepted' ? (
                        <span className="table__label status-accepted">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : el.state === 'appointment_pending' || el.state === 'dispatched' ? (
                        <span className="table__label status-dispatched">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : el.state === 'in_transit' ? (
                        <span className="table__label status-intransit">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : el.state === 'tendered' || el.state === 'delivered' ? (
                        <span className="table__label status-delivered">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : el.state === 'reconciled' ? (
                        <span className="table__label status-exception">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : el.state === 'cancelled' ? (
                        <span className="table__label status-cancelled">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      ) : (
                        <span className="table__label status-intransit">
                          {el.state.split('_').join(' ').toLowerCase()}
                        </span>
                      )}
                      {el.delayed ? <br /> : ''}
                      {el.delayed ? <span className="table__label status-delayed">{'delayed'}</span> : ''}
                      {el.open ? <br /> : ''}
                      {el.open ? (
                        <span className="table__label status-accepted">
                          <i className="icon icon-Star" /> OPEN
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                  let location;
                  let updateTime;
                  let equipment_config;
                  for (let i = 0; i < this.state.equipmentArray.length; i++) {
                    if (el.id === this.state.equipmentArray[i].shipment_id) {
                      equipment_config = this.state.equipmentArray[i];
                    }
                  }
                  if (this.props.powerUnitLocations) {
                    for (let j = 0; j < this.props.powerUnitLocations.vehicle_locations.length; j++) {
                      if (
                        equipment_config &&
                        equipment_config.power_unit &&
                        equipment_config.power_unit.id === this.props.powerUnitLocations.vehicle_locations[j].unit_id
                      ) {
                        const address = [];
                        if (this.props.powerUnitLocations.vehicle_locations[j].address) {
                          if (this.props.powerUnitLocations.vehicle_locations[j].address.city) {
                            address.push(this.props.powerUnitLocations.vehicle_locations[j].address.city);
                          }
                          if (this.props.powerUnitLocations.vehicle_locations[j].address.state) {
                            let myString = this.props.powerUnitLocations.vehicle_locations[j].address.state;
                            if (this.props.powerUnitLocations.vehicle_locations[j].address.postal_code) {
                              myString += ' ' + this.props.powerUnitLocations.vehicle_locations[j].address.postal_code;
                            }
                            address.push(myString);
                          }

                          if (this.props.powerUnitLocations.vehicle_locations[j].address.country) {
                            address.push(this.props.powerUnitLocations.vehicle_locations[j].address.country);
                          }
                        }
                        if (address.length > 0) {
                          location = address.join(', ');
                        }
                        updateTime = this.props.powerUnitLocations.vehicle_locations[j].update_time;
                      }
                    }
                  }

                  const modeService = [];
                  if (el.mode) {
                    modeService.push(el.mode.code);
                  }
                  if (el.service_level) {
                    modeService.push(el.service_level.description);
                  }
                  return (
                    <div
                      key={index}
                      className="dispatch-load__shipment"
                      /*onClick={e => {
                        this.context.router.push('/dispatch/' + el.id);
                      }}*/
                      onMouseEnter={() => this.setMarkerBounce(index)}
                      onMouseLeave={() => this.removeMarkerBounce(index)}
                    >
                      <div className="dispatch-load__shipment-info">
                        <div>
                          <div>
                            SHIPMENT#{' '}
                            <strong>
                              <Link
                                className="shipmentLink"
                                to={`/shipments/${el.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.goToShipment(el.id);
                                }}
                              >
                                {el.reference_id}
                              </Link>
                            </strong>
                          </div>
                          <div>{shipmentStatus}</div>

                          {this.props.activeInfoTab === 'assigned' && (
                            <Fragment>
                              <div>
                                {location ? location : 'Current location: -'}
                                <div className="update-time">
                                  {updateTime ? moment(updateTime).fromNow() : 'Updated: -'}
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                        <div>
                          <Button
                            bsStyle="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState(
                                {showEquipmentConfigModal: true, isLoading: true},
                                function () {
                                  this.props.shipmentsShipmentIdGet(el.id).then((response) => {
                                    if (response.status === 200) {
                                      this.setState({isLoading: false});
                                    }
                                  });
                                }.bind(this)
                              );
                            }}
                          >
                            {this.props.activeInfoTab === 'assigned' ? <i className="icon icon-Pencil" /> : 'Assign'}
                          </Button>
                        </div>
                      </div>
                      <div className="dispatch-load__shipment-line-items">
                        <div>
                          <i className="icon icon-Cubes text-primary" />{' '}
                        </div>
                        <div>
                          <div>
                            {el.line_items.map((item, idx) => {
                              const lineItem = [];
                              if (item.total_packages) {
                                lineItem.push(item.total_packages);
                              }
                              if (item.package_type) {
                                lineItem.push(item.package_type);
                              }
                              if (item.package_weight) {
                                lineItem.push(numberWithCommas(item.package_weight) + item.weight_unit);
                              }
                              if (item.description) {
                                lineItem.push(item.description);
                              }
                              return <div key={idx}>{lineItem.join(' ')}</div>;
                            })}
                          </div>
                          <div>{modeService.length > 0 ? <div>{modeService.join(', ')}</div> : ''}</div>
                        </div>
                      </div>
                      <div className="dispatch-load__shipment-location">
                        {sortedStops.map((stop, stopIdx) => {
                          const addressLineOne = [];
                          const addressLineTwo = [];
                          if (stop.location && stop.location.address) {
                            if (stop.location.address.address_1) {
                              addressLineOne.push(stop.location.address.address_1);
                            }
                            if (stop.location.address.address_2) {
                              addressLineOne.push(stop.location.address.address_2);
                            }
                            if (stop.location.address.city) {
                              addressLineTwo.push(stop.location.address.city);
                            }
                            if (stop.location.address.state_province) {
                              addressLineTwo.push(
                                stop.location.address.state_province + ' ' + stop.location.address.postal_code
                              );
                            }
                            if (stop.location.address.country) {
                              addressLineTwo.push(stop.location.address.country);
                            }
                          }
                          return (
                            <div key={stop.id} className={stop.confirmed_departure_at ? 'completed' : ''}>
                              <div>
                                <i className={'text-primary icon icon-marker-' + (stop.ordinal_index + 1)} />
                              </div>
                              <div className={'stop-address'}>
                                <div>
                                  {stop.location && stop.location.company_name && (
                                    <div>
                                      <strong>{stop.location.company_name}</strong>
                                    </div>
                                  )}

                                  {stop.location && stop.location.address && (
                                    <div>
                                      {addressLineOne.length > 0 && <div>{addressLineOne.join(' ')}</div>}
                                      {addressLineTwo.length > 0 && <div>{addressLineTwo.join(', ')}</div>}
                                    </div>
                                  )}
                                  {stop.instructions && <div>{stop.instructions}</div>}
                                </div>
                              </div>
                              <div>
                                {stop.confirmed_departure_at ? (
                                  <div>
                                    <div>COMPLETED</div>
                                    <div>
                                      {moment(stop.confirmed_departure_at).isValid()
                                        ? formatDateTime(stop.confirmed_departure_at, true, moment.tz.guess())
                                        : null}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    {stop.is_pickup ? (
                                      <div>
                                        <i className="icon icon-ArrowUp pad-right" />
                                        Pickup
                                      </div>
                                    ) : (
                                      <div>
                                        <i className="icon icon-ArrowDown pad-right" />
                                        Drop off
                                      </div>
                                    )}
                                    <div>ETA</div>
                                    <div>{stop.display_eta_window}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {this.props.activeInfoTab === 'assigned' && (
                        <div className="dispatch-load__shipment-equipment-config">
                          <Fragment>
                            <div className="driver">
                              <div>
                                <i className="icon icon-User" />
                              </div>
                              {equipment_config && equipment_config.driver && (
                                <div>
                                  {equipment_config.driver.user && (
                                    <div className="name">
                                      <strong>
                                        {equipment_config.driver.user.first_name +
                                          ' ' +
                                          equipment_config.driver.user.last_name}
                                      </strong>
                                    </div>
                                  )}
                                  {equipment_config.driver.phone_number && (
                                    <div className="phone">
                                      <i className="icon icon-Phone2" />
                                      &nbsp;
                                      {getPhoneHyperlink(equipment_config.driver.phone_number)}
                                    </div>
                                  )}
                                  {equipment_config.driver.user && (
                                    <div className="email">
                                      <a href={'mailto:' + equipment_config.driver.user.email}>
                                        <i className="icon icon-Mail" />
                                        &nbsp;{equipment_config.driver.user.email}
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="equipment">
                              <div className="unit">
                                <div>POWER UNIT</div>

                                {equipment_config && equipment_config.power_unit ? (
                                  <div>{equipment_config.power_unit.name}</div>
                                ) : (
                                  '-'
                                )}
                              </div>

                              <div className="unit">
                                <div>TRAILER</div>

                                {equipment_config && equipment_config.trailer ? (
                                  <div>{equipment_config.trailer.name}</div>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </Fragment>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="no-loads">No Loads</div>
              )}
            </div>
            {this.props.renderPagination()}
          </Paper>
        )}
        <Paper className="dispatch-load__map">
          <div id="mapDispatch" ref="mapDispatch" />
        </Paper>
        <InfoModalWrapper
          show={this.state.showEquipmentConfigModal}
          onHide={() => this.setState({showEquipmentConfigModal: false})}
          title="Driver and Assets"
          children={this.renderEquipmentConfigModal()}
        />
      </div>
    );
  }
}
