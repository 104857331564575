import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {BulkCreateSpotNegotiationRequest, ShipwellError} from '@shipwell/backend-core-sdk';
import {SPOT_NEGOTIATIONS_KEY} from 'App/data-hooks/queryKeys';
import {createPrivateMarketplaceSpotNegotiations} from 'App/api/quoting/typed';

const useCreateSpotNegotiations = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof createPrivateMarketplaceSpotNegotiations>>,
    AxiosError<ShipwellError, unknown>,
    {shipmentId: string; body: BulkCreateSpotNegotiationRequest}
  >
) => {
  const createSpotNegotiationsMutation = useMutation(
    [SPOT_NEGOTIATIONS_KEY],
    ({shipmentId, body}: {shipmentId: string; body: BulkCreateSpotNegotiationRequest}) =>
      createPrivateMarketplaceSpotNegotiations({shipmentId, bulkCreateSpotNegotiationRequest: body}),
    {
      ...options
    }
  );
  return {
    createSpotNegotiations: createSpotNegotiationsMutation.mutate
  };
};

export default useCreateSpotNegotiations;
