import './NoFilterResultsSprinkles.scss';

export const NoFilterResultsSprinkles = () => {
  return (
    <div className="NoFilterResultsSprinkles__wrapper">
      <svg className="NoFilterResultsSprinkles" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 182">
        <g className="NoFilterResultsSprinkles__ornaments">
          <path
            className="NoFilterResultsSprinkles__ring NoFilterResultsSprinkles__ring--0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.168 21.68a7.34 7.34 0 1 0 0-14.68 7.34 7.34 0 0 0 0 14.68Zm0-3.669a3.67 3.67 0 1 0 0-7.34 3.67 3.67 0 0 0 0 7.34Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__ring NoFilterResultsSprinkles__ring--1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M315.679 43.4a4.893 4.893 0 1 0-.001-9.787 4.893 4.893 0 0 0 .001 9.787Zm0-2.445a2.446 2.446 0 1 0 0-4.893 2.446 2.446 0 0 0 0 4.893Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__ring NoFilterResultsSprinkles__ring--2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M264.808 173.617a8.81 8.81 0 1 0 0-17.618 8.81 8.81 0 0 0 0 17.618Zm0-4.404a4.404 4.404 0 1 0 0-8.807 4.404 4.404 0 0 0 0 8.807Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__ring NoFilterResultsSprinkles__ring--3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.297 116.128a8.808 8.808 0 1 0 0-17.616 8.808 8.808 0 1 0 0 17.616Zm0-4.404a4.404 4.404 0 1 0-.001-8.808 4.404 4.404 0 0 0 0 8.808Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.809 37.617H6.85v6.851H0v1.958h6.851v6.85H8.81v-6.85h6.85v-1.958H8.81v-6.85Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.809 37.617H6.85v6.851H0v1.958h6.851v6.85H8.81v-6.85h6.85v-1.958H8.81v-6.85Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.926 165.83h-2.022v7.071H74.83v2.021h7.074V182h2.022v-7.078H91v-2.021h-7.074v-7.071Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.926 165.83h-2.022v7.071H74.83v2.021h7.074V182h2.022v-7.078H91v-2.021h-7.074v-7.071Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M298.029 91.36h-1.468v5.137h-5.137v1.468h5.137v5.139h1.468v-5.139h5.139v-1.468h-5.139v-5.138Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M298.029 91.36h-1.468v5.137h-5.137v1.468h5.137v5.139h1.468v-5.139h5.139v-1.468h-5.139v-5.138Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.809 14h-1.958v6.851H245v1.957h6.851v6.851h1.958v-6.85h6.85V20.85h-6.85V14Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
          <path
            className="NoFilterResultsSprinkles__cross NoFilterResultsSprinkles__cross--3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.809 14h-1.958v6.851H245v1.957h6.851v6.851h1.958v-6.85h6.85V20.85h-6.85V14Z"
            fill="#0A6FDB"
            fillOpacity=".1"
          />
        </g>
        <circle cx="170" cy="91" r="91" fill="#F5F5F5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.287 105.845h4.905l26.323 26.448c2.546 2.545 2.546 6.705 0 9.25-2.545 2.546-6.705 2.546-9.25 0l-26.385-26.385v-4.905l-1.677-1.738c-8.691 7.45-20.549 11.299-33.152 9.188-17.259-2.918-31.042-17.321-33.153-34.704-3.228-26.262 18.874-48.363 45.135-45.135 17.383 2.11 31.787 15.893 34.704 33.153 2.111 12.603-1.738 24.46-9.188 33.152l1.738 1.676ZM130 77.912c0 15.459 12.479 27.937 27.938 27.937 15.458 0 27.937-12.478 27.937-27.937 0-15.459-12.479-27.938-27.937-27.938-15.459 0-27.938 12.48-27.938 27.938Z"
          fill="#fff"
        />
        <path
          d="m195.192 105.845.177-.176-.073-.074h-.104v.25Zm-4.905 0-.173.18.073.07h.1v-.25Zm31.228 26.448-.177.176.001.001.176-.177Zm0 9.25-.176-.177.176.177Zm-35.635-26.385h-.25v.103l.073.074.177-.177Zm0-4.905h.25v-.101l-.071-.072-.179.173Zm-1.677-1.738.18-.174-.163-.169-.179.153.162.19Zm-33.152 9.188-.042.247.042-.247Zm-33.153-34.704.248-.03-.248.03Zm45.135-45.135-.031.248h.001l.03-.248Zm34.704 33.153.247-.042-.247.042Zm-9.188 33.152-.19-.163-.153.179.17.164.173-.18Zm6.643 1.426h-4.905v.5h4.905v-.5Zm26.501 26.521-26.324-26.447-.354.353 26.323 26.447.355-.353Zm-.001 9.604c2.643-2.643 2.643-6.961 0-9.604l-.353.354c2.447 2.447 2.447 6.449 0 8.896l.353.354Zm-9.604 0c2.643 2.643 6.961 2.643 9.604 0l-.353-.354c-2.448 2.448-6.449 2.448-8.897 0l-.354.354Zm-26.385-26.385 26.385 26.385.354-.354-26.386-26.385-.353.354Zm-.073-5.082v4.905h.5v-4.905h-.5Zm-1.607-1.565 1.677 1.739.359-.347-1.676-1.739-.36.347Zm-33.014 9.262c12.68 2.124 24.612-1.75 33.357-9.245l-.325-.38c-8.639 7.404-20.423 11.23-32.949 9.132l-.083.493ZM117.65 83.029c2.124 17.493 15.991 31.984 33.359 34.921l.083-.493c-17.15-2.9-30.848-17.215-32.946-34.488l-.496.06Zm45.413-45.413c-26.422-3.248-48.661 18.99-45.413 45.413l.496-.06c-3.208-26.1 18.757-48.065 44.856-44.857l.061-.496Zm34.921 33.359c-2.936-17.368-17.427-31.235-34.921-33.36l-.06.497c17.273 2.098 31.588 15.796 34.488 32.946l.493-.083Zm-9.245 33.357c7.496-8.745 11.369-20.677 9.245-33.357l-.493.083c2.098 12.526-1.727 24.31-9.132 32.948l.38.326Zm1.722 1.333-1.738-1.676-.347.36 1.738 1.676.347-.36Zm-32.523-.066c-15.321 0-27.688-12.366-27.688-27.687h-.5c0 15.597 12.591 28.187 28.188 28.187v-.5Zm27.687-27.687c0 15.32-12.367 27.687-27.687 27.687v.5c15.596 0 28.187-12.59 28.187-28.187h-.5Zm-27.687-27.688c15.32 0 27.687 12.367 27.687 27.688h.5c0-15.597-12.591-28.188-28.187-28.188v.5ZM130.25 77.912c0-15.32 12.367-27.688 27.688-27.688v-.5c-15.597 0-28.188 12.591-28.188 28.188h.5Z"
          fill="#006BA2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.287 105.845h4.905l26.323 26.448c2.546 2.545 2.546 6.705 0 9.25-2.545 2.546-6.705 2.546-9.25 0l-26.385-26.385v-4.905l-1.677-1.738c-8.691 7.45-20.549 11.299-33.152 9.188-17.259-2.918-31.042-17.321-33.153-34.704-3.228-26.262 18.874-48.363 45.135-45.135 17.383 2.11 31.787 15.893 34.704 33.153 2.111 12.603-1.738 24.46-9.188 33.152l1.738 1.676ZM130 77.912c0 15.459 12.479 27.937 27.938 27.937 15.458 0 27.937-12.478 27.937-27.937 0-15.459-12.479-27.938-27.937-27.938-15.459 0-27.938 12.48-27.938 27.938Z"
          fill="#0A6FDB"
          fillOpacity=".1"
        />
        <path
          d="m195.192 105.845.177-.176-.073-.074h-.104v.25Zm-4.905 0-.173.18.073.07h.1v-.25Zm31.228 26.448-.177.176.001.001.176-.177Zm0 9.25-.176-.177.176.177Zm-35.635-26.385h-.25v.103l.073.074.177-.177Zm0-4.905h.25v-.101l-.071-.072-.179.173Zm-1.677-1.738.18-.174-.163-.169-.179.153.162.19Zm-33.152 9.188-.042.247.042-.247Zm-33.153-34.704.248-.03-.248.03Zm45.135-45.135-.031.248h.001l.03-.248Zm34.704 33.153.247-.042-.247.042Zm-9.188 33.152-.19-.163-.153.179.17.164.173-.18Zm6.643 1.426h-4.905v.5h4.905v-.5Zm26.501 26.521-26.324-26.447-.354.353 26.323 26.447.355-.353Zm-.001 9.604c2.643-2.643 2.643-6.961 0-9.604l-.353.354c2.447 2.447 2.447 6.449 0 8.896l.353.354Zm-9.604 0c2.643 2.643 6.961 2.643 9.604 0l-.353-.354c-2.448 2.448-6.449 2.448-8.897 0l-.354.354Zm-26.385-26.385 26.385 26.385.354-.354-26.386-26.385-.353.354Zm-.073-5.082v4.905h.5v-4.905h-.5Zm-1.607-1.565 1.677 1.739.359-.347-1.676-1.739-.36.347Zm-33.014 9.262c12.68 2.124 24.612-1.75 33.357-9.245l-.325-.38c-8.639 7.404-20.423 11.23-32.949 9.132l-.083.493ZM117.65 83.029c2.124 17.493 15.991 31.984 33.359 34.921l.083-.493c-17.15-2.9-30.848-17.215-32.946-34.488l-.496.06Zm45.413-45.413c-26.422-3.248-48.661 18.99-45.413 45.413l.496-.06c-3.208-26.1 18.757-48.065 44.856-44.857l.061-.496Zm34.921 33.359c-2.936-17.368-17.427-31.235-34.921-33.36l-.06.497c17.273 2.098 31.588 15.796 34.488 32.946l.493-.083Zm-9.245 33.357c7.496-8.745 11.369-20.677 9.245-33.357l-.493.083c2.098 12.526-1.727 24.31-9.132 32.948l.38.326Zm1.722 1.333-1.738-1.676-.347.36 1.738 1.676.347-.36Zm-32.523-.066c-15.321 0-27.688-12.366-27.688-27.687h-.5c0 15.597 12.591 28.187 28.188 28.187v-.5Zm27.687-27.687c0 15.32-12.367 27.687-27.687 27.687v.5c15.596 0 28.187-12.59 28.187-28.187h-.5Zm-27.687-27.688c15.32 0 27.687 12.367 27.687 27.688h.5c0-15.597-12.591-28.188-28.187-28.188v.5ZM130.25 77.912c0-15.32 12.367-27.688 27.688-27.688v-.5c-15.597 0-28.188 12.591-28.188 28.188h.5Z"
          fill="#006BA2"
        />
      </svg>
    </div>
  );
};
