import {Charge, CreateChargeLegItem} from '@shipwell/corrogo-sdk';

export const calculateLineHaulAndFuelSurchargeTotal = (lineHaul = 0, fuelSurcharge = 0) =>
  lineHaul + (fuelSurcharge / 100) * lineHaul;

export const calculateTotalCharge = (charges: (Charge | CreateChargeLegItem)[]) =>
  charges.reduce((total, charge) => total + charge.quantity * parseFloat(charge.unit_amount.value), 0);

export const calculateTotalChargesWithCredits = (
  debits: (Charge | CreateChargeLegItem)[],
  credits: (Charge | CreateChargeLegItem)[]
) => calculateTotalCharge(debits) - calculateTotalCharge(credits);
