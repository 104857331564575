import {useState} from 'react';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Field, Form, Formik} from 'formik';
import {Button, FormikTextarea, Toast} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import {FlexBox} from 'App/components/Box';
import {cancelShipment} from 'App/api/shipment/typed';

export const CancelShipmentForm = ({shipment, onClose}: {shipment: Shipment; onClose: () => void}) => {
  const shipmentName = shipment.name ?? shipment.reference_id;
  const isCancelled = shipment.state === 'cancelled';
  const vendor = shipment.relationship_to_vendor?.vendor;
  const vendorName = vendor?.name;
  const vendorPhone = vendor?.primary_phone_number;
  const [error, setError] = useState<string>();

  const cancelShipmentMutation = useMutation(cancelShipment);

  const handleCancel = (cancellationReason: string) => {
    cancelShipmentMutation.mutate(
      {
        shipmentId: shipment.id,
        cancellationRequest: {
          cancellation_reason: cancellationReason
        }
      },
      {
        onSettled: (data, err) => {
          if (data) {
            onClose();
          }
          if (err) {
            console.error(err);
            setError('Unable to cancel shipment');
          }
        }
      }
    );
  };

  if (isCancelled) {
    return <span>{shipmentName} has already been cancelled.</span>;
  }
  return (
    <div>
      <p>
        This will cancel your shipment ({shipmentName}) on the platform and send a cancellation email to the carrier,
        however, cancellation fees may still apply. In order to confirm cancellation please call the carrier{' '}
        {vendorName} {vendorPhone && ` at ${vendorPhone}`}
      </p>
      <Formik onSubmit={(values) => handleCancel(values.cancellation_reason)} initialValues={{cancellation_reason: ''}}>
        {({isSubmitting}) => (
          <Form noValidate>
            <FlexBox direction="col" gap="m">
              <Field
                name="cancellation_reason"
                label="Cancellation Reason"
                placeholder="Cancellation reason..."
                component={FormikTextarea}
                minRows="7"
              />
              <FlexBox justify="end">
                <Button disabled={isSubmitting} type="submit" isLoading={isSubmitting}>
                  Cancel this shipment
                </Button>
              </FlexBox>
            </FlexBox>
          </Form>
        )}
      </Formik>
      <Toast variant="error" show={Boolean(error)} title="Error!" onClose={() => setError(undefined)}>
        {error}
      </Toast>
    </div>
  );
};
