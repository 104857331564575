import {ComponentPropsWithoutRef, ReactNode} from 'react';
import {ErrorBoundary as RollbarErrorBoundary} from '@rollbar/react';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

const ShipwellRollbarErrorBoundary = ({
  errorMessage,
  ...props
}: {errorMessage: string} & ComponentPropsWithoutRef<typeof RollbarErrorBoundary>) => (
  <RollbarErrorBoundary
    // Typing for fallbackUI from `@rollbar/react` is incorrect. https://github.com/rollbar/rollbar-react#pass-a-fallback-ui
    fallbackUI={
      (({error}: {error: Error}) => (
        <ErrorBoundaryFallback errorMessage={errorMessage} error={error} />
      )) as unknown as ReactNode
    }
    {...props}
  />
);

export default ShipwellRollbarErrorBoundary;
