import {useEffect, useRef, useState} from 'react';

/**
 * Determines if an object is overflowing its parent
 * @param {number} dependencies an option list of dependencies that should cause the useEffect to reevaluate is something is truncated
 */
export function useIsTruncated<ParentT extends HTMLElement, ChildT extends HTMLElement>(dependencies?: unknown[]) {
  const parentRef = useRef<ParentT>(null);
  const childRef = useRef<ChildT>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (!parentRef.current) return;
    const getIsTruncated = () => {
      if (!childRef.current) return false;
      return childRef.current.offsetWidth < childRef.current.scrollWidth;
    };
    setIsTruncated(getIsTruncated());
    const observer = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        setIsTruncated(getIsTruncated());
      });
    });
    observer.observe(parentRef.current);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || []);

  return {
    parentRef,
    childRef,
    isTruncated
  };
}
