import _ from 'lodash';
import moment from 'moment';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import set from 'lodash/set';
import isNil from 'lodash/isNil';
import {validatePlannedTimeWindowBeforeNextStop} from './validatePlannedTimeWindow';
import {validation as stopValidation} from 'App/formComponents/formSections/shipmentStopFields/utils/validation';
import {
  validateEmail,
  validatePhoneNumber,
  validateDollarValue,
  validateIntegerValue,
  isBillToFormEmpty,
  reeferTypes,
  validateNumber,
  countDecimals
} from 'App/utils/globals';
import validateNMFCCode, {validateNMFCSubCode} from 'App/utils/validateNMFCCode';
import {makeValidationErrors} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';

const validate = (
  values,
  {referencesCustomFields = [], stopCustomFields = [], customFields = [], flagsToValidate = {}} = {}
) => {
  const errors = {};
  let isFTL = false;
  let isLTL = false;
  let isDrayage = false;
  let isParcel = false;
  if (Number(values.mode) === 1 || (values.mode && values.mode.id === 1)) {
    isFTL = true;
  }
  if (
    Number(values.mode) === 2 ||
    (values.mode && values.mode.id === 2) ||
    Number(values.mode) === 4 ||
    (values.mode && values.mode.id === 4) ||
    !values.mode
  ) {
    isLTL = true;
  }
  if (Number(values.mode) === 5 || (values.mode && values.mode.id === 5)) {
    isDrayage = true;
  }
  if (Number(values.mode) === 6 || (values.mode && values.mode.id === 6)) {
    isParcel = true;
  }

  if (values.ignoreValidation === true) {
    return errors;
  }

  if (values.multiplier && !validateIntegerValue(values.multiplier)) {
    errors.multiplier = 'Enter a valid number';
  }
  if (values.multiplier && values.multiplier > 50) {
    errors.multiplier = 'The maximum is 50 shipments';
  }

  if (!values.equipment_type) {
    errors.equipment_type = 'Select an equipment type';
  }

  //STOPS
  if (values.stops) {
    const prevConfirmDates = [];
    errors.stops = [];

    for (var i = 0; i < values.stops.length; i++) {
      const stopErrors = stopValidation(values.stops[i], stopCustomFields);

      if (Object.keys(stopErrors).length) {
        errors.stops[i] = stopErrors;
      } else {
        errors.stops[i] = {};
      }
      //check if dates are in proper order
      if (values.stops[i].planned_date && values.stops[i + 1] && values.stops[i + 1].planned_date) {
        if (moment(values.stops[i].planned_date).isAfter(moment(values.stops[i + 1].planned_date))) {
          if (errors.stops[i]) {
            errors.stops[i].planned_date = 'Date must be before subsequent stops';
          } else {
            errors.stops[i] = {planned_date: 'Date must be before subsequent stops'};
          }
        }
      }

      /** Ensure completion date is after arrival date */
      if (
        moment(values.stops[i].confirmed_arrival_at).isValid() &&
        moment(values.stops[i].confirmed_departure_at).isValid()
      ) {
        if (moment(values.stops[i].confirmed_departure_at).isBefore(moment(values.stops[i].confirmed_arrival_at))) {
          errors.stops[i].confirmed_departure_at = 'Completed date must be after arrival date.';
        }
      }
      /** Ensure current completion date and arrival date are after previous dates */
      if (i > 0 && moment(values.stops[i].confirmed_arrival_at).isValid()) {
        if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_arrival_at).isBefore(prevDate))) {
          errors.stops[i].confirmed_arrival_at = 'Date must be after previous dates.';
        }
      }
      if (i > 0 && moment(values.stops[i].confirmed_departure_at).isValid()) {
        if (prevConfirmDates.some((prevDate) => moment(values.stops[i].confirmed_departure_at).isBefore(prevDate))) {
          errors.stops[i].confirmed_departure_at = 'Date must be after previous dates.';
        }
      }

      if (moment(values.stops[i].confirmed_arrival_at).isValid()) {
        prevConfirmDates.push(moment(values.stops[i].confirmed_arrival_at));
      }
      if (moment(values.stops[i].confirmed_departure_at).isValid()) {
        prevConfirmDates.push(moment(values.stops[i].confirmed_departure_at));
      }

      if (values.stops[i].planned_time_window_start && values.stops[i].planned_time_window_end) {
        const startTime = moment(values.stops[i].planned_time_window_start, 'HH:mm');
        const stopTime = moment(values.stops[i].planned_time_window_end, 'HH:mm');
        //check if schedule selection is 'Open' before validating start and stop time
        const scheduleSelectOpen = values.stops[i]['schedule-select'] === 2;
        if (startTime.isValid() && stopTime.isValid() && moment(stopTime).isBefore(startTime) && scheduleSelectOpen) {
          errors.stops[i].planned_time_window_end = 'Stop time must be after start time';
        }
      }
      const isPlannedTimeWindowBeforeNextStop = validatePlannedTimeWindowBeforeNextStop(values, i);
      if (!isPlannedTimeWindowBeforeNextStop) {
        errors.stops[i].planned_time_window_start = `Appointment time must be before the next stop`;
      }
    }
  } else if (!values.stops) {
    errors.stops = [];
    errors.stops[0] = {};
    errors.stops[0].location = 'Enter a pickup location';
    errors.stops[1] = {};
    errors.stops[1].location = 'Enter a delivery location';
  }
  //LINE ITEMS
  if (isFTL && values.manual_total_weight) {
    if (!values.total_weight_override?.value || values.total_weight_override?.value <= 0) {
      errors.total_shipment_weight = 'Enter total shipment weight.';
    }
  }
  if (values.line_items && values.line_items.length > 0) {
    errors.line_items = [];
    for (var i = 0; i < values.line_items.length; i++) {
      errors.line_items[i] = {};
      if (!values.line_items[i].total_packages && isLTL) {
        errors.line_items[i].total_packages = 'Quantity required';
      } else if (
        values.line_items[i].total_packages &&
        !flagsToValidate?.decimalSupportForShipmentLineItems &&
        !validateIntegerValue(values.line_items[i].total_packages)
      ) {
        errors.line_items[i].total_packages = 'Enter a valid number';
      }
      if (!values.line_items[i].height && isLTL) {
        errors.line_items[i].height = 'Height required';
      } else if (values.line_items[i].height && !validateNumber(values.line_items[i].height)) {
        errors.line_items[i].height = 'Enter a valid number';
      }
      if (!values.line_items[i].width && isLTL) {
        errors.line_items[i].width = 'Width required';
      } else if (values.line_items[i].width && !validateNumber(values.line_items[i].width)) {
        errors.line_items[i].width = 'Enter a valid number';
      }
      if (!values.line_items[i].length && isLTL) {
        errors.line_items[i].length = 'Length required';
      } else if (values.line_items[i].length && !validateNumber(values.line_items[i].length)) {
        errors.line_items[i].length = 'Enter a valid number';
      }
      if (!values.line_items[i].total_packages) {
        errors.line_items[i].total_packages = 'Quantity required';
      }
      if (
        (!values.manual_total_weight && !values.line_items[i].package_weight) ||
        (values.manual_total_weight && !isFTL && !values.line_items[i].package_weight)
      ) {
        errors.line_items[i].package_weight = 'Package Weight required';
      } else if (values.line_items[i].package_weight && !validateNumber(values.line_items[i].package_weight)) {
        errors.line_items[i].package_weight = 'Enter a valid number';
      } else if (!isParcel && values.line_items[i].package_weight && values.line_items[i].package_weight < 1) {
        errors.line_items[
          i
        ].package_weight = `Package Weight cannot be lees than 1 ${values.line_items[i].weight_unit}`;
      }
      if (!values.line_items[i].description) {
        errors.line_items[i].description = 'Description required';
      }

      if (values.line_items[i].total_pieces && !validateIntegerValue(values.line_items[i].total_pieces)) {
        errors.line_items[i].total_pieces = 'Enter a valid whole number';
      }

      if (values.line_items[i].hazmat === true) {
        if (isNil(values.line_items[i].hazmat_identification_number)) {
          errors.line_items[i].hazmat_identification_number = 'Enter Hazmat Identification Number';
        } else if (values.line_items[i].hazmat_identification_number?.length > 16) {
          errors.line_items[i].hazmat_identification_number = 'Ensure this field has no more than 16 characters';
        }
        if (isNil(values.line_items[i].hazmat_hazard_class)) {
          errors.line_items[i].hazmat_hazard_class = 'Enter Hazmat Hazard Class';
        } else if (values.line_items[i].hazmat_hazard_class?.length > 6) {
          errors.line_items[i].hazmat_hazard_class = 'Ensure this field has no more than 6 characters';
        }
        if (isNil(values.line_items[i].hazmat_packing_group)) {
          errors.line_items[i].hazmat_packing_group = 'Select a Hazmat Packing Group';
        }
        if (isNil(values.line_items[i].hazmat_proper_shipping_name)) {
          errors.line_items[i].hazmat_proper_shipping_name = 'Enter Hazmat Proper Shipping Name';
        }
      }
      //in FTL, we dont need a freight class
      if ((!values.line_items[i].freight_class || values.line_items[i].freight_class === 'select') && isLTL) {
        errors.line_items[i].freight_class = 'Freight class required';
      }

      if (values.line_items[i].refrigeration_required && isNil(values.line_items[i].refrigeration_min_temp)) {
        errors.line_items[i].refrigeration_min_temp = 'Enter minimum temperature.';
      }
      if (values.line_items[i].refrigeration_required && isNil(values.line_items[i].refrigeration_max_temp)) {
        errors.line_items[i].refrigeration_max_temp = 'Enter maximum temperature.';
      }

      if (!values.line_items[i].package_type && isLTL) {
        errors.line_items[i].package_type = 'Select a package type';
      }

      /** Parcel line-item validation */
      if (isParcel) {
        if (!values.line_items[i].total_packages) {
          errors.line_items[i].total_packages = 'Quantity required';
        } else if (!validateIntegerValue(values.line_items[i].total_packages)) {
          errors.line_items[i].total_packages = 'Enter a valid whole number';
        }

        if (!values.line_items[i].package_type) {
          errors.line_items[i].package_type = 'Select a package type';
        }

        if (values.line_items[i].package_type === 'CUSTOM') {
          if (!values.line_items[i].height) {
            errors.line_items[i].height = 'Height required';
          } else if (!validateIntegerValue(values.line_items[i].height)) {
            errors.line_items[i].height = 'Enter a valid whole number';
          }

          if (!values.line_items[i].width) {
            errors.line_items[i].width = 'Width required';
          } else if (!validateIntegerValue(values.line_items[i].width)) {
            errors.line_items[i].width = 'Enter a valid whole number';
          }

          if (!values.line_items[i].length) {
            errors.line_items[i].length = 'Length required';
          } else if (!validateIntegerValue(values.line_items[i].length)) {
            errors.line_items[i].length = 'Enter a valid whole number';
          }
        }
      }

      set(
        errors.line_items[i],
        `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}`,
        makeValidationErrors(values.line_items[i], customFields)
      );

      if (values.line_items[i].nmfc_item_code) {
        try {
          values.line_items[i].nmfc_item_code = validateNMFCCode(values.line_items[i].nmfc_item_code);
        } catch (e) {
          errors.line_items[i].nmfc_item_code = e.message;
        }
      }
      if (values.line_items[i].nmfc_sub_code) {
        try {
          values.line_items[i].nmfc_sub_code = validateNMFCSubCode(values.line_items[i].nmfc_sub_code);
        } catch (e) {
          errors.line_items[i].nmfc_sub_code = e.message;
        }
      }
    }
  }
  //if no line items entered and mode selected includes LTL, error
  if (isLTL && (!values.line_items || values.line_items.length === 0)) {
    errors.line_items = {_error: 'At least one line item is required for LTL'};
  }

  errors.metadata = {};

  if (!values.preferred_currency) {
    errors.preferred_currency = 'Enter a currency of record';
  }

  //validate max buy if it exists
  if (values.metadata?.max_buy_amount) {
    if (!validateDollarValue(values.metadata.max_buy_amount)) {
      errors.metadata.max_buy_amount = 'Enter a valid dollar value';
    }
  }
  //validate buy it now if it exists
  if (values.metadata?.buy_it_now_amount) {
    if (!validateDollarValue(values.metadata.buy_it_now_amount)) {
      errors.metadata.buy_it_now_amount = 'Enter a valid dollar value';
    }
  }
  //validate target rate if it exists
  if (values.metadata?.target_rate_amount) {
    if (!validateDollarValue(values.metadata.target_rate_amount)) {
      errors.metadata.target_rate_amount = 'Enter a valid dollar value';
    }
  }
  if (Object.keys(errors.metadata).length === 0) {
    delete errors.metadata;
  }

  if (referencesCustomFields.length > 0) {
    set(
      errors,
      `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}`,
      makeValidationErrors(values, referencesCustomFields, CustomFieldEntityTypesEnum.Shipment)
    );
  }

  if (values.financials && values.financials.length > 0) {
    //loop through financial line items;
    errors.financials = [];
    for (let i = 0; i < values.financials.length; i++) {
      errors.financials[i] = {};
      if (!values.financials[i].unit_name) {
        errors.financials[i].unit_name = 'Description is required.';
      }
      if (!values.financials[i].unit_amount) {
        errors.financials[i].unit_amount = 'Rate is required.';
      } else if (values.financials[i].unit_amount) {
        if (!validateDollarValue(values.financials[i].unit_amount)) {
          errors.financials[i].unit_amount = 'Rate must be a valid dollar amount.';
        } else if (countDecimals(Number(values.financials[i].unit_amount)) > 4) {
          errors.financials[i].unit_amount = 'A maximum of 4 decimal places is allowed.';
        }
      }
      if (!values.financials[i].unit_quantity) {
        errors.financials[i].unit_quantity = 'Quantity is required.';
      } else if (values.financials[i].unit_quantity) {
        if (!validateNumber(values.financials[i].unit_quantity)) {
          errors.financials[i].unit_quantity = 'Quanity must be a number.';
        } else if (Number(values.financials[i].unit_quantity) < 0) {
          errors.financials[i].unit_quantity = 'Quantity must be greater than 0.';
        } else if (countDecimals(Number(values.financials[i].unit_quantity)) > 3) {
          errors.financials[i].unit_quantity = 'A maximum of 3 decimal places is allowed.';
        }
      }
    }
  }

  if (values.markup) {
    if (!validateDollarValue(values.markup)) {
      errors.markup = 'Markup must be a number';
    }
  }

  // since validate is not a react function cannot use ld useFlag
  // Checked for a new driver entry and no number for TMC driver workflow
  if (_.has(values, 'driver')) {
    if (values.driver && !values.driver_cell) {
      errors.driver_cell = 'Driver cell required';
    }
  }
  //if a driver's number has been entered, we need a carrier to be able to assign it
  if (values.driver_cell) {
    if (values.carrier === 'select' || !values.carrier) {
      errors.carrier = 'Carrier required for driver assignment';
    }
    if (!validatePhoneNumber(values.driver_cell)) {
      errors.driver_cell = 'Invalid number';
    }
  }

  if (values.carrier && values.carrier !== 'select' && !values.vendor_point_of_contact) {
    errors.vendor_point_of_contact = 'A carrier point of contact is required for the shipment.';
  }
  if (!values.book_as_customer || values.book_as_customer === null) {
    errors.book_as_customer = 'Select a customer account';
  }
  //if there are no line items and we're in FTL, then the total weight and commodity description are required
  if (isFTL && (!values.line_items || values.line_items.length === 0)) {
    if (!values.ftl_estimated_weight) {
      errors.ftl_estimated_weight = 'Total weight is required if there are no line items';
    } else if (!validateDollarValue(values.ftl_estimated_weight)) {
      errors.ftl_estimated_weight = 'Enter a valid number';
    }
    if (!values.ftl_description) {
      errors.ftl_description = 'Description is required if there are no line items';
    }
  }
  if (values.equipment_type && reeferTypes.includes(Number(values.equipment_type))) {
    if (isNil(values.temperature_lower_limit)) {
      errors.temperature_lower_limit = 'Enter a temperature';
    }
    if (isNil(values.temperature_upper_limit)) {
      errors.temperature_upper_limit = 'Enter a temperature';
    }
    if (parseFloat(values.temperature_lower_limit) > parseFloat(values.temperature_upper_limit)) {
      errors.temperature_upper_limit = 'Upper temperature must be greater than lower temperature.';
    }
  }

  const isEmptyBillToForm = isBillToFormEmpty(values.bill_to_override);
  const companyAddress = values?.bill_to_override?.company_address;

  if (!isEmptyBillToForm && values.bill_to_override) {
    errors.bill_to_override = {};
    if (
      !companyAddress ||
      (typeof companyAddress === 'object' && Object.keys(companyAddress).length === 0) ||
      (values.bill_to_override.direction &&
        values.bill_to_override.direction === 'COLLECT' &&
        !companyAddress.formatted_address)
    ) {
      errors.bill_to_override.company_address = 'Address is required';
    }
    if (!values.bill_to_override.company_name) {
      errors.bill_to_override.company_name = 'Company name is required';
    }
    if (!values.bill_to_override.contact_email) {
      errors.bill_to_override.contact_email = 'Email is required';
    }
    if (values.bill_to_override.contact_email && !validateEmail(values.bill_to_override.contact_email)) {
      errors.bill_to_override.contact_email = 'Invalid email';
    }
    if (!values.bill_to_override.contact_phone) {
      errors.bill_to_override.contact_phone = 'Phone is required';
    }
    if (values.bill_to_override.contact_phone && !validatePhoneNumber(values.bill_to_override.contact_phone)) {
      errors.bill_to_override.contact_phone = 'Invalid phone number';
    }
    if (!values.bill_to_override.direction || values.bill_to_override.direction === 'select') {
      errors.bill_to_override.direction = 'Select an option';
    }
  }

  return errors;
};

export default validate;
