export const REQUEST_PURCHASE_ORDER_LINEITEMS_PENDING = 'REQUEST_PURCHASE_ORDER_LINEITEMS_PENDING';
export const REQUEST_PURCHASE_ORDER_LINEITEMS_SUCCESS = 'REQUEST_PURCHASE_ORDER_LINEITEMS_SUCCESS';
export const REQUEST_SHIPMENT_PURCHASE_ORDERS_SUCCESS = 'REQUEST_SHIPMENT_PURCHASE_ORDERS_SUCCESS';
export const UPDATE_PURCHASE_ORDER_PENDING = 'UPDATE_PURCHASE_ORDER_PENDING';
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS';

/**
 * Purchase Order - Details actions
 *
 */

export const requestShipmentPurchaseOrderSuccess = (payload) => {
  return {
    type: REQUEST_SHIPMENT_PURCHASE_ORDERS_SUCCESS,
    payload: payload
  };
};

export const updatePurchaseOrderPending = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_PENDING
  };
};

export const updatePurchaseOrderSuccess = (payload) => {
  return {
    type: UPDATE_PURCHASE_ORDER_SUCCESS,
    payload: payload
  };
};

/**
 * Purchase Order - Line Item actions
 *
 */

export const requestPurchaseOrderLineItemsPending = () => {
  return {
    type: REQUEST_PURCHASE_ORDER_LINEITEMS_PENDING
  };
};

export const requestPurchaseOrderLineItemsSuccess = (payload) => {
  return {
    type: REQUEST_PURCHASE_ORDER_LINEITEMS_SUCCESS,
    payload: payload
  };
};
