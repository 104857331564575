import {SvgIconName} from '@shipwell/shipwell-ui';
import {StepStatusEnum} from '../stepStatusEnum';

type StatusDicType = {
  [key in StepStatusEnum]: {
    iconName: SvgIconName;
    text: string;
    className: string;
  };
};

const StatusDic: StatusDicType = {
  [StepStatusEnum.ERROR]: {
    className: 'text-sw-error',
    text: 'Failed',
    iconName: 'ErrorOutlined'
  },
  [StepStatusEnum.IDLE]: {
    className: 'text-sw-disabled-text',
    text: 'Idle',
    iconName: 'CheckCircleOutlined'
  },
  [StepStatusEnum.RUNNING]: {
    className: 'text-sw-active',
    text: 'Running',
    iconName: 'Running'
  },
  [StepStatusEnum.SUCCESS]: {
    className: 'text-sw-success',
    text: 'Success',
    iconName: 'CheckCircleFilled'
  }
};

export function getIconForStatus(status: StepStatusEnum) {
  return StatusDic[status];
}
