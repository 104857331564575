import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import {object, string} from 'yup';
import {DynamicFields} from 'App/components/dynamicForms/testFields';

export const useDynamicFields = (fields?: DynamicFields) => {
  const validationSchema = fields ? createValidationSchema(fields) : undefined;

  const initialValues = fields ? mapValues(fields, () => '') : undefined;

  return {
    validationSchema,
    initialValues
  };
};

const createValidationSchema = (fields: DynamicFields) => {
  const validationShape = mapValues(fields, ({label, input: {type, required, minLength, pattern}}) =>
    type === 'address'
      ? object().nullable()
      : string()
          .nullable()
          .when([], {
            is: () => !!required,
            then: string().nullable().required(`${label} is a required field.`)
          })
          .when([], {
            is: () => !isNil(minLength) && !isNaN(minLength),
            then: string()
              .nullable()
              .min(minLength as number, `${label} must be at least ${minLength as number} characters.`)
          })
          .when([], {
            is: () => !!pattern,
            then: string()
              .nullable()
              .trim()
              .matches(
                new RegExp(pattern as string),
                `${label} must match the following: "${new RegExp(pattern as string).toString()}"`
              )
          })
  );

  return object().shape(validationShape);
};
