import {
  ShipmentsApiShipmentsShipmentIdGroupedTimelineEventsGetRequest,
  GroupedShipmentTimelineEvents,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {QueryKey, useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {getGroupedTimelineEvents} from 'App/api/shipment/typed';
import {GROUPED_TIMELINE_EVENTS_QUERY_KEY, TIMELINE_EVENT_DETAILS} from 'App/data-hooks/queryKeys';

export type UseGetGroupedTimelineEventsOptions = Omit<
  ShipmentsApiShipmentsShipmentIdGroupedTimelineEventsGetRequest,
  'shipmentId'
>;

export const useGetGroupedTimelineEvents = (
  parameters: ShipmentsApiShipmentsShipmentIdGroupedTimelineEventsGetRequest,
  queryOptions: Omit<UseQueryOptions<GroupedShipmentTimelineEvents[], ShipwellError>, 'onSuccess'>
) => {
  const {shipmentId, ...opts} = parameters;
  const queryClient = useQueryClient();
  const groupedTimelineEventsQuery = useQuery(
    [GROUPED_TIMELINE_EVENTS_QUERY_KEY, shipmentId, opts] as QueryKey,
    async () => {
      const {data} = await getGroupedTimelineEvents(parameters);
      return data;
    },
    {
      onSuccess: (data) =>
        data?.forEach((timelineStop) =>
          timelineStop?.timeline_events?.forEach((timelineEvent) => {
            return queryClient.setQueryData([TIMELINE_EVENT_DETAILS, timelineEvent?.id], {data: timelineEvent});
          })
        ),
      ...queryOptions
    }
  );
  return groupedTimelineEventsQuery;
};
