import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {Field} from 'formik';
import {FormikTextInput, FormikSelect, FormikTextarea, FormikDateTimePicker, Tooltip} from '@shipwell/shipwell-ui';
import {getCompanyDocuments} from 'App/api/company';

/**
 * SLA Fields
 * @param {*} props
 */
const ServiceLevelAgreementFields = ({company, readOnly, cloningSLA}) => {
  const [companyDocuments, setCompanyDocuments] = useState([]);

  const inputRef = useRef();

  /**
   * Get company documents
   * @param {*} companyId
   */
  const getCompanyDocumentsList = async (companyId) => {
    try {
      const response = await getCompanyDocuments(companyId, {pageSize: 50});

      if (response && response.body) {
        setCompanyDocuments(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (company.id && companyDocuments.length < 1) {
      getCompanyDocumentsList(company.id);
    }
  }, [company, companyDocuments]);
  useEffect(() => {
    if (inputRef?.current && cloningSLA) {
      inputRef.current.select();
    }
  }, [inputRef, cloningSLA]);

  return (
    <>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-12">
          <Field
            required
            disabled={readOnly}
            label="Status"
            name="status"
            simpleValue
            clearable={false}
            component={FormikSelect}
            options={[
              {value: 'ACTIVE', label: 'Active'},
              {value: 'INACTIVE', label: 'Inactive'}
            ]}
          />
        </div>
      </div>
      <hr className="contracts__details-divider" />

      <p>
        <b>Details</b>
      </p>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-12">
          <Field
            ref={inputRef}
            required
            disabled={readOnly}
            label="Service Level Agreement Name"
            name="name"
            component={FormikTextInput}
          />
        </div>
        <div className="grid-item-1-6">
          <Field label="Total Capacity" disabled={readOnly} name="capacity" component={FormikTextInput} />
        </div>
        <div className="grid-item-7-12">
          <Field
            simpleValue
            disabled={readOnly}
            clearable={false}
            options={[
              {value: 'DAY', label: 'Per Day'},
              {value: 'WEEK', label: 'Per Week'},
              {value: 'MONTH', label: 'Per Month'}
            ]}
            name="capacity_interval"
            component={FormikSelect}
          />
        </div>
        <div className="grid-item-1-6">
          <Field
            label="Start Date"
            disabled={readOnly}
            name="start_date"
            showTimeSelect={false}
            component={FormikDateTimePicker}
          />
        </div>
        <div className="grid-item-7-12">
          <Field
            label="End Date"
            disabled={readOnly}
            name="end_date"
            showTimeSelect={false}
            component={FormikDateTimePicker}
          />
        </div>
      </div>
      <div className="grid-item-1-12 grid-auto-field">
        <Field label="Notes" name="notes" disabled={readOnly} component={FormikTextarea} />
      </div>

      <hr className="contracts__details-divider" />

      <p>
        <b>Documents</b>
      </p>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <Field
            isMulti
            required={false}
            options={companyDocuments}
            getOptionLabel={(option) => option.description}
            getOptionValue={(option) => option.id}
            label="Documents"
            name="documents"
            disabled={readOnly}
            component={FormikSelect}
          />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent={
              <span>Documents that have been uploaded to the Company &gt; Profile will display in this list.</span>
            }
          >
            <span className="material-icons">info_outline</span>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

ServiceLevelAgreementFields.propTypes = {
  cloningSLA: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.string
  }),
  readOnly: PropTypes.bool
};

export default connect((state) => ({
  company: state.userCompany.company
}))(ServiceLevelAgreementFields);
