import _ from 'lodash';
import store from '../routes/store';
import {
  CLEAR_NEWSHIPMENT_QUOTING_FORM,
  CLEAR_NEWSHIPMENT_FORM,
  CLEAR_NEWLOAD_FORM,
  CLEAR_EDIT_SHIPMENT_FORM,
  CLEAR_CONFIRM_SHIPMENT_FORM,
  CLEAR_ADDRESS_LOOKUP_FORM,
  CLEAR_SEND_DOCUMENTS_FORM,
  SET_PAGE,
  CLEAR_PASSWORD_FORM,
  CLEAR_TRAILER_FORM,
  CLEAR_POWERUNIT_FORM,
  CLEAR_ADDRESS_BOOK_FORM
} from './types';
import {formatDateTime} from 'App/utils/globals';

/*
========================================================================
    --- * *  LOAD Pickup and DropOff Checkboxes  * * ---
========================================================================
*/
const loadDataPromise = (stopsEmpty) => {
  return new Promise(function (resolve, reject) {
    loadStops(stopsEmpty, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
};

const loadStops = (stopsEmpty, callback) => {
  //pickupStart - 3 days @ 6:00
  var d = new Date();
  var e = d.setDate(d.getDate() + 3);
  var threeDaysStart = new Date(e);
  var f = threeDaysStart.setHours(6);

  //pickupEnd - 3 days @ 18:00
  var threeDaysEnd = new Date(e);
  var z = threeDaysEnd.setHours(18);

  const stops = [];
  const endCheck = stopsEmpty.length - 1;
  stopsEmpty.map((stop, i) => {
    stop && i === 0
      ? stops.push({
          load_pickup: true,
          load_unload_window_start: threeDaysStart,
          load_unload_window_stop: threeDaysEnd
        })
      : stop && i === endCheck
      ? stops.push({load_dropoff: true})
      : stops.push({});
  });
  stops.length !== stopsEmpty.length ? callback(Error('mismatch'), null) : callback(null, stops);
};

export const loadData = () => {
  const formState = store.getState().form.onDemandMultiple;
  const formKeys = _.has(formState, 'values.stops');
  let stopsEmpty;
  formKeys ? (stopsEmpty = formState.values.stops) : null;

  if (stopsEmpty && stopsEmpty.length) {
    // loadDataPromise(stopsEmpty);
    return (dispatch) => {
      return loadDataPromise(stopsEmpty).then((stops) => {
        const data = {
          stops
        };
        return dispatch({});
      });
    };
  }
};

/*
========================================================================
                  --- * *  LOAD ETA  * * ---
========================================================================
*/

const loadEtaPromise = (etaData) => {
  return new Promise(function (resolve, reject) {
    loadEta(etaData, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
};

const loadEta = (etaData, callback) => {
  const stops = [{eta_calc: ''}];
  const stopss = [{eta_calc: ''}];
  const stopse = [{eta_calc: ''}];
  const stopsl = [{eta_calc: ''}];

  let geoEta;
  let geoEtas;
  let setThreeDays;
  let getThreeDays;
  let setThreeDay;
  let getThreeDay;
  let setFiveMin;
  let getFiveMin;
  let baseDate;

  let baseDates;
  let baseEnd;
  let etaStart;
  let etaLastOne;
  let lastEnd;

  etaData.map((eta, i) => {
    geoEta = formatDateTime(eta);
    baseDate = new Date(eta);
    getThreeDays = eta.setHours(eta.getHours() + 5);
    setThreeDays = new Date(getThreeDays);

    stops.push({
      eta_calc: geoEta,
      load_unload_window_start: baseDate,
      load_unload_window_stop: setThreeDays
    });
  });

  stops.length !== etaData.length + 1 ? callback(Error('mismatch'), null) : callback(null, stops);
};

export function loadEtaData(etaData) {
  if (etaData && etaData.length) {
    return (dispatch) => {
      return loadEtaPromise(etaData).then((stops) => {
        const data = {
          stops
        };
        return dispatch({});
      });
    };
  }
}

/*
========================================================================
         --- * *  Reset the New Shipment form data  * * ---
========================================================================
*/

export function resetNewShipmentQuotingForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NEWSHIPMENT_QUOTING_FORM,
      payload: {}
    });
  };
}
export function resetNewShipmentForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NEWSHIPMENT_FORM,
      payload: {}
    });
  };
}
export function resetNewLoadForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NEWLOAD_FORM,
      payload: {}
    });
  };
}
export function resetEditShipmentForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EDIT_SHIPMENT_FORM,
      payload: {}
    });
  };
}
export function resetConfirmShipmentForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CONFIRM_SHIPMENT_FORM,
      payload: {}
    });
  };
}

export function resetAddressLookupForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ADDRESS_LOOKUP_FORM,
      payload: {}
    });
  };
}

export function resetSendDocumentsForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEND_DOCUMENTS_FORM,
      payload: {}
    });
  };
}

export function setPageNewShipment(pageNumber) {
  console.log('in formsjs ', pageNumber);
  return (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: {pageNumber}
    });
  };
}

export function resetPasswordForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PASSWORD_FORM,
      payload: {}
    });
  };
}

/*
========================================================================
         --- * *  Reset Equipment form data  * * ---
========================================================================
*/
export function resetTrailerForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TRAILER_FORM,
      payload: {}
    });
  };
}
export function resetPowerUnitForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_POWERUNIT_FORM,
      payload: {}
    });
  };
}

/*
========================================================================
         --- * *  Reset Address Book form data  * * ---
========================================================================
*/
export function resetAddressBookForm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ADDRESS_BOOK_FORM,
      payload: {}
    });
  };
}
