import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {useGetCompanyPreferences} from './useGetCompanyPreferences';
import {displayTotalWeight} from 'App/utils/internationalConstants';

export const WeightCell = ({shipment}: {shipment: ShippingDashboardRow}) => {
  const {isFetching, unitPreferences} = useGetCompanyPreferences();
  const totalWeight = displayTotalWeight({
    shipment,
    unitPreferences,
    returnNullWeight: true,
    returnWeightLabel: true,
    keepOnlyUnit: true
  });

  if (isFetching) {
    return <SvgIcon name="LoadingDots" />;
  }
  return <>{totalWeight || '--'}</>;
};
