import {DisplayValue, Title, FormikSelect, SvgIcon, FormikDateTimePicker, FormikTextarea} from '@shipwell/shipwell-ui';
import {ReactNode} from 'react';
import {Field, useFormikContext} from 'formik';
import uniqBy from 'lodash/uniqBy';
import {useMutation} from '@tanstack/react-query';
import {CarrierTag, SlimCarrierRelationship} from '@shipwell/backend-core-sdk';
import {getCarrierTags} from 'App/api/carriers/typed';
import {CarrierOption} from 'App/containers/userCompany/rfps/ActionButton';
import {getCarrierPocs} from 'App/containers/userCompany/rfps/utils/carriers';
import {getCarrierWithDataMetrics} from 'App/api/rfpOptimized/typed';

export type PointOfContact = ReturnType<typeof getCarrierPocs>[0];

export type BidRequestValues = {
  points_of_contact: PointOfContact[];
  company: string;
  bid_expiry_date_time: string;
  carrier_tags: CarrierTag[];
  notes: string;
};

export const SendRfpBidRequestFields = ({
  children,
  isDisabledDatePicker = false
}: {
  children?: ReactNode;
  isDisabledDatePicker?: boolean;
}) => {
  const {values, setFieldValue} = useFormikContext<BidRequestValues>();

  const carrierRelationshipMutation = useMutation(
    async (tags: string[]) => {
      const request = await getCarrierWithDataMetrics({pageSize: 10000, tags});
      return request.data;
    },
    {
      onSuccess: (data, tags) => {
        const currentFormikTags = values.carrier_tags;
        const uniqueCarriers = uniqBy(getCarrierPocs(data.results || []) || [], (result) => result?.id);
        const currentFormikPOCs = values.points_of_contact;
        const formikCarriersWithoutTags = currentFormikPOCs?.filter((poc) =>
          poc?.tags?.every((tag) => currentFormikTags.every((prevTag) => prevTag?.id !== tag.id))
        );
        // if user removed a tag, filter relavent carriers
        if (currentFormikTags?.length > tags.length) {
          const [removedTag] = currentFormikTags.filter((prevTag) => tags.every((t) => t !== prevTag?.id));
          // remove any carrier with tag === removed tag
          const filteredCarriers = uniqueCarriers.filter((carrier) =>
            carrier?.tags?.every((tag) => tag.id !== removedTag?.id)
          );
          // the points_of_contact field can contain carriers added via tag or directly, so we need to be able to update the field value with both
          setFieldValue('points_of_contact', [
            ...filteredCarriers,
            ...(formikCarriersWithoutTags ? formikCarriersWithoutTags : [])
          ]);
        } else {
          setFieldValue('points_of_contact', [
            ...uniqueCarriers,
            ...(formikCarriersWithoutTags ? formikCarriersWithoutTags : [])
          ]);
        }
      }
    }
  );

  const handleTagSelect = (tags: CarrierTag[]) => {
    setFieldValue('carrier_tags', tags);
    // if all tags are removed, the carrierRelationshipMutation does not need to run, but we should remove its related carriers
    if (tags.length === 0) {
      const currentFormikTags = values.carrier_tags.map((tag) => tag?.id);
      const currentCarriers = values.points_of_contact;
      const filteredCarriers = currentCarriers?.filter((carrier) =>
        carrier?.tags?.every((tag) => currentFormikTags.every((prevTag) => prevTag !== tag.id))
      );
      setFieldValue('points_of_contact', filteredCarriers);
      return;
    }
    carrierRelationshipMutation.mutate(tags.map((tag) => tag?.id || ''));
  };

  const handleCarrierSelect = (carriers: SlimCarrierRelationship[]) => {
    setFieldValue('points_of_contact', carriers);
    // if user clears out the carrier input, tags should be cleared as well
    if (carriers.length === 0) {
      setFieldValue('carrier_tags', []);
    }
  };

  const isOptionDisabled = (option: PointOfContact) => option?.carrier_status !== 'ACTIVE';

  return (
    <div className="flex flex-col gap-4">
      <DisplayValue>
        Select the Carriers in which you would like to receive your RFP to bid on your lanes. The carriers will have to
        respond by the date and time specified here and the RFP bidding is closed.
      </DisplayValue>
      <Title variant="formTitle">Add Carrier By Tag or Search for Carriers</Title>
      <Field
        async
        defaultOptions
        isMulti
        loadOptions={async (q: string) => {
          const paginatedCarrierTags = await getCarrierTags({name: q});
          return paginatedCarrierTags.results;
        }}
        getOptionValue={(option: CarrierTag) => option.id}
        formatOptionLabel={(option: CarrierTag) => option.name}
        label="Add Carriers by Tag"
        name="carrier_tags"
        component={FormikSelect}
        onChange={handleTagSelect}
      />
      <Field
        async
        isMulti
        required
        label="Carrier(s)"
        name="points_of_contact"
        onChange={handleCarrierSelect}
        getOptionValue={(option: PointOfContact) => option?.id}
        loadOptions={async (q: string) => {
          const response = await getCarrierWithDataMetrics({q});
          const paginatedResults = response.data.results || [];
          return getCarrierPocs(paginatedResults);
        }}
        component={FormikSelect}
        defaultOptions
        formatOptionLabel={(option: PointOfContact, contextWrapper: {context: string}) =>
          CarrierOption(option, contextWrapper, isOptionDisabled(option))
        }
        isOptionDisabled={isOptionDisabled}
      />
      <Title variant="formTitle">Select Bidding Expiration Date</Title>
      <Field
        required
        component={FormikDateTimePicker}
        name="bid_expiry_date_time"
        label="Date and Time"
        prepend={<SvgIcon name="Calendar" color="sw-icon" />}
        disabled={isDisabledDatePicker}
      />
      <Field label="Notes/Instructions" name="notes" component={FormikTextarea} minRows={8} />
      {children}
    </div>
  );
};
