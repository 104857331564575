import Select from 'react-select';
import FormGroup from '../../formGroup';
import './styles.scss';

/**
 * Select Field
 */
const SelectField = (props) => {
  const {input, req, placeholder, options, labelKey, valueKey, clearable, field, ...rest} = props;
  const value = input.value || field.value || props.value;
  const name = input.name || field.name || props.name;
  const onChange = input.onChange || field.onChange || props.onChange;

  return (
    <FormGroup {...props} className="select-group">
      {() => (
        <Select
          {...rest}
          name={name}
          value={value}
          componentClass="select"
          labelKey={labelKey}
          valueKey={valueKey}
          placeholder={placeholder}
          options={options}
          clearable={clearable}
          onChange={(value) => onChange && onChange(value)}
        />
      )}
    </FormGroup>
  );
};

SelectField.defaultProps = {
  name: '',
  value: '',
  input: {},
  field: {},
  labelKey: 'name',
  valueKey: 'id',
  clearable: false
};

export default SelectField;
