import {PackageType} from '@shipwell/backend-core-sdk';
import {useQuery} from '@tanstack/react-query';
import {fetchPackageTypesPromise} from 'App/api/shipment/typed';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {PACKAGE_TYPES_QUERY_KEY} from 'App/data-hooks/queryKeys';

export default function usePackageTypes() {
  return useQuery(
    [PACKAGE_TYPES_QUERY_KEY],
    async () => {
      const response = await fetchPackageTypesPromise();
      return response.data;
    },
    {
      staleTime: STATIC_DATA_STALE_TIME
    }
  );
}
// TODO: this needs to be moved to the view later i.e. components/containers
export const getPackageTypesReactSelectOptions = (packageTypes: PackageType[]) =>
  packageTypes?.map((packageType) => {
    return {
      label: packageType.description,
      value: packageType.code
    };
  });
