import {Contract} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon, Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {useGetContractChargeItems} from 'App/data-hooks/contracts/useGetContractChargeItems';
import {formatCurrencyValue} from 'App/utils/globals';
import {TenderContractError} from 'App/components/TenderContractError/TenderContractError';
import {SelectedContract} from 'App/formComponents/formSections/tenderRequest/SelectedContract';
import {ApplicableContractWithCharges} from 'App/data-hooks/contracts/useGetApplicableContracts';

type ContractsDetailsParams = {
  carrierCapacityIsAvailable?: boolean;
  modes?: string[];
  equipment?: string[];
  currencyOfRecord?: string;
  selectedApplicableContract?: ApplicableContractWithCharges;
  selectedMultiApplicableContracts?: ApplicableContractWithCharges[];
  shipmentId?: string;
  showFinancialInfo?: boolean;
};

export const ContractsDetails = ({
  carrierCapacityIsAvailable,
  selectedApplicableContract,
  selectedMultiApplicableContracts,
  modes,
  equipment,
  currencyOfRecord,
  shipmentId,
  showFinancialInfo = true
}: ContractsDetailsParams) => {
  return selectedApplicableContract || selectedMultiApplicableContracts?.length ? (
    <>
      {!carrierCapacityIsAvailable ? (
        <div className="flex rounded-md bg-sw-error-background p-2 pl-0 text-sw-error">
          <SvgIcon width="3rem" name="InfoFilled" />
          <span className="font-bold text-sw-text">
            <div className="ml-2">One or more carriers are at capacity and can&apos;t accept any more tenders.</div>
          </span>
        </div>
      ) : null}
      {selectedApplicableContract ? (
        <>
          <TenderContractError contract={selectedApplicableContract.contract} />
          <SelectedContract
            showFinancialInfo={showFinancialInfo}
            applicableContractWithCharges={selectedApplicableContract}
            modes={modes || []}
            equipment={equipment || []}
            shipmentId={shipmentId}
          />
        </>
      ) : (
        selectedMultiApplicableContracts?.map((applicableContract) => (
          <Card
            title={
              <SelectedContractCardTitle
                contract={applicableContract.contract}
                shipmentId={shipmentId}
                total={applicableContract.total}
                mode={modes?.[0] || ''}
                equipment={equipment?.[0]}
                currency={currencyOfRecord}
              />
            }
            isCollapsible
            draggableProvided={null}
            isCollapsed
            key={applicableContract.contract?.id}
          >
            <CollapsibleCardContent>
              <SelectedContract
                showFinancialInfo={showFinancialInfo}
                applicableContractWithCharges={applicableContract}
                modes={modes || []}
                equipment={equipment || []}
                shipmentId={shipmentId}
              />
            </CollapsibleCardContent>
          </Card>
        ))
      )}
    </>
  ) : null;
};

const SelectedContractCardTitle = ({
  contract,
  shipmentId,
  mode,
  equipment,
  currency,
  total
}: {
  contract?: Contract;
  shipmentId?: string;
  mode?: string;
  equipment?: string;
  currency?: string;
  total?: number;
}) => {
  const {total: calculatedTotal} = useGetContractChargeItems({
    contractId: contract?.id,
    shipmentId,
    options: {
      enabled: !total
    }
  });

  const hasTotal = !!total || !!calculatedTotal;

  return (
    <div className="flex gap-x-2">
      <span>{contract?.name}</span>
      <span className="text-xs font-normal uppercase text-sw-text-section-title">
        {' \u2022 '}
        {[
          contract?.carrier_name,
          mode || '--',
          equipment || '--',
          hasTotal ? `${currency || 'USD'} ${formatCurrencyValue(total || calculatedTotal)}` : '--'
        ].join(' \u2022 ')}
      </span>
    </div>
  );
};
