import {Component} from 'react';
import {connect} from 'react-redux';
import {FieldArray, change, touch} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import {compose} from 'recompose';
import ShipmentItemTotals from '../shipmentItemTotals';
import {FinancialsCurrencyOfRecordOnly} from 'App/containers/Book/FormSections/Financials/components';
import {Financials} from 'App/containers/Book/FormSections/Financials';
import ShipmentItemsFields from 'App/formComponents/formSections/shipmentItemsFields';
import ShipmentServices from 'App/formComponents/formSections/shipmentServices';
import ShipmentAccessorialsField from 'App/formComponents/fields/accessorials';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import isInternationalShipment from 'App/utils/isInternationalShipment';
import {showPricingIntel} from 'App/containers/pricingIntel/utils';
import './styles.scss';
import withFlags from 'App/utils/withFlags';
import NewShipmentEmbeddedPricingIntel from 'App/components/NewShipmentEmbeddedPricingIntel';

/**
 * Shipment Line-Item creation
 * @description Handles creating and importing shipment line item fields
 */
class ShipmentItems extends Component {
  constructor(props) {
    super(props);
    this.setDefaultDeclaredValue = this.setDefaultDeclaredValue.bind(this);
  }
  handleSelectPriceSuggestion = (field, price) => this.props.dispatch(change('newQuoteForm', field, price));

  triggerFieldTouch = (field) => {
    this.props.dispatch(touch(`newQuoteForm`, field));
  };

  setFieldValue = (field, value) => {
    this.props.dispatch(change('newQuoteForm', field, value));
  };

  componentDidMount() {
    if (this.props.formValues?.id && this.props.setShipmentIdToFetchRateRequest) {
      this.props.setShipmentIdToFetchRateRequest(this.props.formValues.id);
    }
    if (this.props.internationalPreferences) {
      const preferredCurrency =
        this.props.formValues?.preferred_currency || this.props.internationalPreferences.currency;
      this.props.dispatch(change('newQuoteForm', 'preferred_currency', preferredCurrency));
    }
    if (this.props.fiCarrierComplianceInsurance) {
      this.setDefaultDeclaredValue();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.formValues?.id &&
      this.props.setShipmentIdToFetchRateRequest &&
      prevProps.formValues?.id !== this.props.formValues?.id
    ) {
      this.props.setShipmentIdToFetchRateRequest(this.props.formValues.id);
    }
    if (
      this.props.internationalPreferences &&
      !isEqual(prevProps.internationalPreferences, this.props.internationalPreferences)
    ) {
      const preferredCurrency =
        this.props.formValues?.preferred_currency || this.props.internationalPreferences.currency;
      this.props.dispatch(change('newQuoteForm', 'preferred_currency', preferredCurrency));
    }
    if (this.props.fiCarrierComplianceInsurance) {
      this.setDefaultDeclaredValue();
    }
    if (
      this.props.formValues?.preferred_currency &&
      !isEqual(prevProps.formValues?.preferred_currency, this.props.formValues.preferred_currency)
    ) {
      const lineItems = this.props.formValues?.line_items?.map((lineItem) => ({
        ...lineItem,
        // do not alter the value_per_piece_currency if using a saved product that has a value_per_piece
        ...((!lineItem.products?.value?.id || (lineItem.products?.value?.id && !lineItem.value_per_piece)) && {
          value_per_piece_currency: this.props.formValues?.preferred_currency
        })
      }));
      if (lineItems?.length) {
        this.props.dispatch(change('newQuoteForm', 'line_items', lineItems));
      }
    }
  }

  setDefaultDeclaredValue() {
    const {userCompany, dispatch, formValues} = this.props;
    if (!formValues) {
      // when the shipment creation form is submit these values are empty. These are here to prevent errors on the redirect.
      return; // no form yet
    }
    if (formValues.total_declared_value) {
      return; // no need to set this value because there is one already that the user has entered
    }
    if (!userCompany) {
      return; // cannot set for the user
    }
    const {preferences} = userCompany;
    if (!preferences) {
      return; // cannot set for the user
    }
    const {mode_preferences} = preferences;
    if (!mode_preferences) {
      return; // cannot set for the user
    }
    const {mode} = formValues;
    if (!mode) {
      return; // cannot set for the user
    }
    const {hasDrayage, hasFTL, hasLTL, hasRail, hasVLTL} = checkShipmentModes(mode);
    let defaultDeclaredValue = null;
    if (hasFTL && mode_preferences.is_ftl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.ftl_default_declared_value;
    } else if (hasLTL && mode_preferences.is_ltl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.ltl_default_declared_value;
    } else if (hasVLTL && mode_preferences.is_vltl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.vltl_default_declared_value;
    } else if (hasDrayage && mode_preferences.is_drayage_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.drayage_default_declared_value;
    } else if (hasRail && mode_preferences.is_rail_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.rail_default_declared_value;
    }

    if (!defaultDeclaredValue) {
      return; // there is no default declared value set for this mode
    }

    dispatch(change('newQuoteForm', 'total_declared_value', defaultDeclaredValue));
  }

  render() {
    const {classes, formValues, company, unitPreferences, isLoadingParcelRates} = this.props;
    const {
      mode,
      line_items,
      total_linear_feet,
      total_weight_override,
      total_declared_value,
      total_quantity_override,
      stops,
      equipment_type,
      preferred_currency
    } = formValues;
    const unitPreferencesWithPreferredCurrency = {
      ...unitPreferences,
      ...(preferred_currency && {currency: preferred_currency})
    };
    const {hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel} = checkShipmentModes(mode);

    const shouldShowPricingIntel = showPricingIntel({
      hasFTL,
      historicalPricingEnabled: company?.feature_flags?.historical_pricing_enabled,
      equipmentType: equipment_type,
      stops
    });
    const canEnterBookNow = company && company.feature_flags && company.feature_flags.load_board_enabled;
    const isIntlParcel = hasParcel && isInternationalShipment(stops);
    const financialTotals = [];
    if (formValues.financials) {
      for (var i = 0; i < formValues.financials.length; i++) {
        if (
          formValues.financials[i] &&
          formValues.financials[i].unit_amount &&
          formValues.financials[i].unit_quantity
        ) {
          let value = formValues.financials[i].unit_amount;
          if (value && typeof value === 'string' && value.startsWith('$')) {
            value = value.slice(1);
          }
          if (value && typeof value === 'string') {
            value = value.replace(/,/g, '');
          }

          const valToAdd = parseFloat(value * formValues.financials[i].unit_quantity);

          if (!isNaN(valToAdd)) {
            financialTotals.push(valToAdd);
          } else {
            financialTotals.push(0);
          }
        } else {
          financialTotals.push(0);
        }
      }
    }

    return (
      <div className="quote-lineitems">
        {hasParcel && (
          <Paper square elevation={1} className="services-card">
            <ShipmentServices
              form="newQuoteForm"
              triggerFieldTouch={this.triggerFieldTouch}
              setFieldValue={this.setFieldValue}
              isLoadingParcelRates={isLoadingParcelRates}
            />
          </Paper>
        )}
        <Paper square elevation={1} classes={{root: classes.root}}>
          <div className="quote-tile-header">
            <h2>Shipping Items</h2>
          </div>
          <FieldArray
            form="newQuoteForm"
            name="line_items"
            component={ShipmentItemsFields}
            props={{
              hasFTL,
              hasLTL,
              hasVLTL,
              hasDrayage,
              hasParcel,
              isIntlParcel,
              slimForm: true,
              shipmentLineItems: line_items,
              totalLinearFeet: total_linear_feet
            }}
          />
          <ShipmentItemTotals
            totalValue={total_declared_value}
            unitPreferences={unitPreferencesWithPreferredCurrency}
            lineItems={line_items}
            totalWeight={total_weight_override}
            totalQuantity={total_quantity_override}
            hasFTL={hasFTL}
            hasLTL={hasLTL}
            hasVLTL={hasVLTL}
            form="newQuoteForm"
          />
        </Paper>
        <Paper square elevation={1} className="accessorials-card">
          <div className="accessorials-field">
            <ShipmentAccessorialsField
              dropup
              label="Shipment Accessorials"
              hasDrayage={hasDrayage}
              hasParcel={hasParcel}
            />
          </div>
        </Paper>
        {shouldShowPricingIntel && mode?.length === 1 && equipment_type?.length === 1 ? (
          <Paper square elevation={1} className="pricing-card">
            <NewShipmentEmbeddedPricingIntel
              stops={stops}
              equipmentType={equipment_type?.[0]}
              lineItems={line_items}
              totalWeightOverride={total_weight_override}
            />
          </Paper>
        ) : null}
        {canEnterBookNow && hasFTL && !hasLTL ? (
          <Paper square elevation={1} className="accessorials-card">
            <div className="financials-field">
              <Financials form="newQuoteForm" hideMarkup formValues={formValues} financialTotals={financialTotals} />
            </div>
          </Paper>
        ) : (
          <Paper square elevation={1} className="accessorials-card">
            <div className="financials-field">
              <FinancialsCurrencyOfRecordOnly />
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.069)'
  }
};

export default compose(
  withFlags('fiCarrierComplianceInsurance'),
  connect((state) => ({
    user: state.auth.user,
    company: state.auth.company,
    unitPreferences: state.userCompany.unitPreferences,
    userCompany: state.userCompany
  })),
  withStyles(styles)
)(ShipmentItems);
