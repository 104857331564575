import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';

export type EntityTypeGroupType = 'reference' | 'customer' | 'lineItem' | 'chargeLineItem' | 'stop';
export type EntityTypeGroupLabel = 'Reference' | 'Customer' | 'Line Item' | 'Charge Line Item' | 'Stop';

export type CustomFieldPropertyTypes =
  | 'label'
  | 'required'
  | 'allow_carrier_edit'
  | 'allow_carrier_view'
  | 'field_type'
  | 'ui_enabled';

type EntityTypeGroup = {
  label: EntityTypeGroupLabel;
  entityTypes: CustomFieldEntityTypesEnum[];
  formFields: CustomFieldPropertyTypes[];
};

export const entityTypeGroups: Record<EntityTypeGroupType, EntityTypeGroup> = {
  reference: {
    label: 'Reference',
    entityTypes: ['shipment', 'purchase_order'],
    formFields: ['label', 'required', 'allow_carrier_edit', 'allow_carrier_view', 'field_type']
  },
  customer: {
    label: 'Customer',
    entityTypes: ['customer'],
    formFields: ['label', 'field_type']
  },
  lineItem: {
    label: 'Line Item',
    entityTypes: ['shipment_line_item', 'purchase_order_line_item'],
    formFields: ['label', 'required', 'field_type']
  },
  chargeLineItem: {
    label: 'Charge Line Item',
    entityTypes: ['shipment_charge_line_item'],
    formFields: ['label', 'ui_enabled', 'field_type']
  },
  stop: {
    label: 'Stop',
    entityTypes: ['shipment_stop', 'purchase_order_stop'],
    formFields: ['label', 'required', 'field_type']
  }
};
