import {Field, reduxForm, Form} from 'redux-form';
import {Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderFieldPassword from 'App/formComponents/renderFieldPassword';
import validate from 'App/utils/validatePasswordForm';

let PasswordForm = (props) => {
  const {
    handleSubmit,
    submitting,
    handlePasswordChange,
    viewPasswordCurrent,
    viewPasswordNew,
    handlePasswordViewCurrent,
    handlePasswordViewNew,
    requirements,
    success,
    done
  } = props;
  return success ? (
    <Paper className="settings__card">
      <div className="login-box-msg" style={{textAlign: 'center'}}>
        <div className={done ? 'load-complete circle-loader' : 'circle-loader'}>
          <div className="checkmark draw" />
        </div>
        <p>Your password has been successfully changed.</p>
      </div>
    </Paper>
  ) : (
    <div>
      <Form onSubmit={handleSubmit} className="update-password">
        <Paper className="settings__card">
          <h2>Update your password</h2>

          <Field
            name="currentPassword"
            component={renderFieldPassword}
            label="Current Password"
            handlePasswordView={handlePasswordViewCurrent}
            viewPassword={viewPasswordCurrent}
          />
          <Field
            name="newPassword"
            component={renderFieldPassword}
            label="New Password"
            handlePasswordView={handlePasswordViewNew}
            viewPassword={viewPasswordNew}
            onChange={(e, value) => {
              handlePasswordChange(value);
            }}
          />
          <p>
            <i className={'icon icon-' + (requirements.length ? 'Check text-success' : 'x-small text-danger')} />
            &nbsp;Must be at least 8 characters
          </p>
          <p>
            <i className={'icon icon-' + (requirements.capital ? 'Check text-success' : 'x-small text-danger')} />
            &nbsp;At least 1 uppercase letter
          </p>
          <p>
            <i className={'icon icon-' + (requirements.special ? 'Check text-success' : 'x-small text-danger')} />
            &nbsp;At least 1 special character (e.g. !@#$%...)
          </p>
        </Paper>
        <div className="settings__footer">
          <Button type="submit" disabled={submitting} className="btn btn-primary">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

PasswordForm = reduxForm({
  form: 'passwordForm',
  validate: validate
})(PasswordForm);

export default PasswordForm;
