import {createColumnHelper} from '@tanstack/react-table';
import {SettlementsDashboardRow} from '@shipwell/backend-core-sdk';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {
  DocsCell,
  FreightInvoiceCostsCell,
  FreightInvoiceDatesCell,
  InvoiceNumberCell,
  MissingDocumentsCell
} from '../TableCells';
import {FreightInvoiceStatusCell} from 'App/containers/settlements/freightInvoices/components/FreightInvoicesList/components/FreightInvoiceStatusCell';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';
const columnHelper = createColumnHelper<SettlementsDashboardRow>();

export const getColumnData = (shipmentId: string, role: SettlementsDashboardGeneratedBy) => {
  return [
    columnHelper.accessor('invoice_number', {
      id: 'Invoice #',
      header: 'Invoice #',
      cell: (info) => <InvoiceNumberCell invoice={info.row.original} />,
      footer: (info) => info.column.id,
      enableSorting: false
    }),
    columnHelper.accessor('status', {
      id: 'Status',
      header: 'Status',
      cell: (props) => {
        const {original} = props.row;
        const {id, status, updated_at, invoice_number} = original;
        return id && status && updated_at ? (
          <FreightInvoiceStatusCell
            invoiceId={id}
            invoiceNumber={invoice_number}
            status={status}
            updatedAt={updated_at}
            shipmentId={shipmentId}
            role={role}
          />
        ) : null;
      },
      footer: (info) => info.column.id,
      size: 129,
      enableSorting: false
    }),
    columnHelper.display({
      id: 'Costs',
      header: 'Costs',
      cell: (props) => <FreightInvoiceCostsCell row={props.row?.original} />,
      footer: (info) => info.column.id,
      minSize: 199,
      enableSorting: false
    }),
    columnHelper.display({
      id: 'Docs',
      header: 'Docs',
      cell: (info) => (
        <DocsCell invoiceId={info.row?.original.id} documentCount={info.row?.original.documents?.length} />
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableSorting: false
    }),
    columnHelper.accessor('sub_statuses', {
      id: 'Missing Doc(s)',
      header: 'Missing Doc(s)',
      cell: (props) => <MissingDocumentsCell subStatuses={props.getValue()} />,
      footer: (info) => info.column.id,
      enableSorting: false
    }),
    columnHelper.display({
      id: 'Dates',
      header: () => (
        <div className="flex w-full items-center justify-between">
          Dates
          <Tooltip
            portal
            tooltipContent={
              <>
                <b>Dates</b>
                <br />
                Del - Shipment Delivery Date
                <br />
                Rec - Invoice Received Date
                <br />
                Due - Carrier Due Date
              </>
            }
          >
            <SvgIcon name="InfoOutlined" color={'$sw-icon'} />
          </Tooltip>
        </div>
      ),
      cell: (props) => <FreightInvoiceDatesCell row={props.row?.original} />,
      footer: (info) => info.column.id,
      minSize: 187,
      enableSorting: false
    })
  ];
};
