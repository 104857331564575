import {
  CreateTag,
  TagStatus,
  TagsApi,
  UpdateTag,
  TagsApi as TagsApiSingleRequest,
  TagsApiListTagsRequest
} from '@shipwell/corrogo-sdk';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import {configuration} from './configuration';

const tagsApi = new TagsApi(configuration);
const tagsApiSingleRequest = new TagsApiSingleRequest(configuration);

export async function listTags(q?: string, status?: TagStatus, page?: number, limit?: number) {
  return tagsApiSingleRequest.listTags({q, status, page, limit});
}

export async function listTagsSPR(params: TagsApiListTagsRequest) {
  return tagsApiSingleRequest.listTags(params);
}

export function getTag(tagId: string) {
  return tagsApi.getTag({tagId});
}

export function createTag(data: CreateTag) {
  const sanitizedData = omitBy(data, isEmpty) as CreateTag;
  return tagsApi.createTag({createTag: sanitizedData});
}

export function updateTag(tagId: string, data: UpdateTag) {
  const sanitizedData = omitBy(data, isEmpty) as UpdateTag;
  return tagsApi.updateTag({tagId, updateTag: sanitizedData});
}

export function deleteTag(tagId: string) {
  return tagsApi.deleteTag({tagId});
}
