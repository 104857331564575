import {Accessorial} from '@shipwell/backend-core-sdk';
import {ShippingDashboardAccessorial} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Tooltip} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

export const AccessorialsCell = ({accessorials}: {accessorials: Accessorial[] | ShippingDashboardAccessorial[]}) => {
  if (!accessorials.length) {
    return <>--</>;
  }
  return (
    <Tooltip
      portal
      tooltipContent={
        <div>
          <span className="font-bold">Shipment Accessorials</span>
          <FlexBox direction="col">
            {accessorials.map((accessorial) => (
              <span key={accessorial.id}>{accessorial.description}</span>
            ))}
          </FlexBox>
        </div>
      }
      placement="top"
    >
      {accessorials.length}
    </Tooltip>
  );
};
