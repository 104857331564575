import {Component} from 'react';
import {FormControl, Button} from 'react-bootstrap';
import {rejectionCodes, tenderStatuses} from 'App/containers/tendering/create/utils/constants';
export default class TenderRejectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {rejectReason: null, customRejectReason: ''};
  }
  render() {
    const {rejectingShipment, performingTenderAction} = this.props;
    const {rejectReason} = this.state;
    const tenderStatus = rejectingShipment?.bidding_status || rejectingShipment?.status;

    return (
      <form>
        <div className="loadBoard__actions-reject-form">
          <>
            {tenderStatus === tenderStatuses.ACCEPTED
              ? rejectionCodes.postAcceptance.map((code) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        value={code.value}
                        checked={rejectReason === code.value}
                        onChange={(e) => this.setState({rejectReason: e.target.value})}
                      />
                      {code.description}
                    </label>
                  );
                })
              : rejectionCodes.standard.map((code) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        value={code.value}
                        checked={rejectReason === code.value}
                        onChange={(e) => this.setState({rejectReason: e.target.value})}
                      />
                      {code.description}
                    </label>
                  );
                })}
          </>
          {rejectionCodes.other.map((code) => {
            return (
              <label>
                <input
                  type="radio"
                  value={code.value}
                  checked={rejectReason === code.value}
                  onChange={(e) => this.setState({rejectReason: e.target.value})}
                />
                {code.description}
              </label>
            );
          })}
          <FormControl
            placeholder="Add a comment"
            value={this.state.customRejectReason}
            onChange={(e) => this.setState({customRejectReason: e.target.value})}
            required
          />
        </div>

        <div className="loadBoard__actions-reject-buttons">
          <Button
            bsStyle="default"
            onClick={(e) => {
              this.props.handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              this.props.rejectTender(this.props.rejectingShipment, rejectReason, this.state.customRejectReason);
            }}
            //require custom reason if A13 selected
            disabled={
              performingTenderAction ||
              !this.state.rejectReason ||
              (this.state.rejectReason === 'A13' && !this.state.customRejectReason)
            }
            bsStyle="primary"
          >
            Reject
          </Button>
        </div>
      </form>
    );
  }
}
