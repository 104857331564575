import {FormikTextInput, FormikSelect, Title, FormikDateRangeBetweenPicker} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import getTimeOptions from 'App/utils/getTimeOptions';

const CriteriaFormFieldsAlpha = () => {
  return (
    <>
      <div className="mb-4">
        <Title variant="formTitle">
          These dates and routing guide tender expirations will be applied to all contracts and routing guides that are
          created with the data import. All Routing Guides will default to ACTIVE when created.
        </Title>
      </div>

      <Field className="mb-4" name="rfp_name" label="RFP Name" component={FormikTextInput} required />

      <div className="mb-10 grid grid-cols-2 gap-4">
        <div>
          <Title variant="formTitle" className="mb-2">
            RFP Dates
          </Title>
          <Field
            isBetweenDefault
            label="Select Date Range"
            name="rfp_date_range"
            component={FormikDateRangeBetweenPicker}
            required
          />
        </div>
        <div>
          <Title variant="formTitle" className="mb-2">
            Routing Guide Tender Expiration
          </Title>
          <Field
            label="Tender Expiration"
            options={getTimeOptions().filter((e) => e.value !== 0)}
            name="tender_expiration"
            component={FormikSelect}
            simpleValue
            required
          />
        </div>
      </div>
    </>
  );
};

export default CriteriaFormFieldsAlpha;
