export const printZplDocument = async (url: string) => {
  const response = await fetch(url);
  const text = await response.text();

  const printWindow = window.open();
  if (!printWindow) {
    return;
  }
  printWindow.document.open('text/plain');
  printWindow.document.write(text);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
};

export const downloadZplDocument = async (url: string, filename: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  a.click();
};
