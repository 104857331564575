import {Component, Fragment} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, FormControl, OverlayTrigger, Popover} from 'react-bootstrap';
import {Link} from 'react-router';
import './_tasks.scss';
import {SubmissionError} from 'redux-form';
import {bindActionCreators} from 'redux';
import Truncate from 'react-truncate';
import TasksForm from './TasksForm';
import * as actions from 'App/actions/users';
import {formatDate, formatTime, formatDateTime, unpackErrors} from 'App/utils/globals';

export class Tasks extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompleteTask = this.handleCompleteTask.bind(this);
    this.fetchTasks = this.fetchTasks.bind(this);
    this.editTask = this.editTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.state = {
      showTasksForm: false,
      tasksPagination: {
        page: 1,
        pageSize: 1000,
        ordering: '-created_at'
      },
      myTasks: true,
      showCompleted: true,
      editingTask: null
    };
  }

  componentDidMount() {
    const {caseId} = this.props;
    if (caseId) {
      //then get the tasks specific to this shipment
    } else {
      //get all tasks for this user
      this.fetchTasks();
    }

    if (
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('users.view_company_users') &&
      !this.props.caseOverride
    ) {
      this.props.getCompanyUsers(this.props.company.id, {
        page: 1,
        pageSize: 1000,
        ordering: 'freight_authority__company__name'
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.myTasks !== this.state.myTasks || prevState.showCompleted !== this.state.showCompleted) {
      //refetch with the new filter
      this.fetchTasks();
    }
  }

  fetchTasks() {
    const pagination = {...this.state.tasksPagination};
    pagination.assigneeId = null;
    pagination.isOpen = true;
    if (this.state.myTasks) {
      pagination.assigneeId = this.props.user.id;
    }
    if (this.state.showCompleted) {
      delete pagination.isOpen;
    }
    this.setState({tasksPagination: pagination});
    this.props.getTasks(pagination);
  }

  handleCompleteTask(task) {
    task.is_open = !task.is_open;
    this.props.toggleCompleteTask(task.id, task).then((response) => {
      if (response.status === 200) {
        if (this.props.caseOverride) {
          // get just the tasks that belong to this case
          this.props.getTasksByCaseId(task.case, {}).then(() => {
            this.forceUpdate();
          });
        } else {
          this.fetchTasks();
        }
        this.props.clearSelectedTask();
      }
    });
  }

  editTask(task) {
    this.setState({showTasksForm: false, editingTask: task.id}, () => {
      this.props.selectTask(task);
    });
  }

  deleteTask(task) {
    this.props.deleteTask(task.id).then((response) => {
      if (response.status === 200) {
        if (this.props.caseOverride) {
          // get just the tasks that belong to this case
          this.props.refreshCase();
          this.props.clearSelectedTask();
        } else {
          this.fetchTasks();
        }
        this.props.clearTask();
        this.setState({editingTask: null});
      }
    });
  }

  goToShipment(shipmentId) {
    this.context.router.push('/shipments/' + shipmentId);
    this.props.closeParent();
  }

  handleSubmit(attrs) {
    const newTask = JSON.parse(JSON.stringify(attrs));
    // due date
    if (newTask.due_date) {
      newTask.due_date = moment(newTask.due_date).format();
    }
    if (newTask.assignee) {
      newTask.assignee = this.props.companyUsers.results.filter((e) => e.id === newTask.assignee)[0];
    }
    //if there's an id on the task, do a PUT

    if (newTask.id) {
      return this.props.editTask(newTask.id, newTask).then((response) => {
        if (response.status === 200) {
          this.props.showTask(response.details);
        } else {
          const errors = response.field_errors || [];
          let submissionError = {};
          submissionError = unpackErrors(errors, submissionError);
          submissionError._error = response.error_description;

          //handle edge cases for errors here
          throw new SubmissionError(submissionError);
        }
        if (this.props.caseOverride) {
          // get just the tasks that belong to this case
          this.props.refreshCase();
          this.setState({showTasksForm: false, editingTask: null});
        } else {
          this.props.getTasks(this.state.tasksPagination).then((res) => {
            this.setState({showTasksForm: false, editingTask: null});
          });
        }
      });
    }
    if (this.props.caseOverride) {
      newTask.case = this.props.caseId;
      return this.props.createTaskInCase(this.props.caseId, newTask).then((response) => {
        this.props.refreshCase();
        this.setState({showTasksForm: false});
      });
    }
    return this.props.createTask(newTask).then((response) => {
      this.props.getTasks(this.state.tasksPagination).then((res) => {
        this.setState({showTasksForm: false});
      });
    });
  }

  renderTaskDetails() {
    const {selectedTask} = this.props;
    return this.state.editingTask === selectedTask.id ? (
      <TasksForm
        companyUsers={this.props.companyUsers}
        onSubmit={this.handleSubmit}
        cancel={() => {
          this.setState({showTasksForm: false, editingTask: null});
        }}
      />
    ) : (
      <div className="tasks__task-details">
        <div className="tasks__task-details-complete">
          {selectedTask.is_open ? (
            <i onClick={() => this.handleCompleteTask(selectedTask)} className="flaticon-check showAsLink pad-right" />
          ) : (
            <i
              onClick={() => this.handleCompleteTask(selectedTask)}
              className="flaticon-check_filled showAsLink pad-right"
            />
          )}
        </div>
        <div className="tasks__task-details-container">
          <div className="tasks__task-details-header">
            <div className="title">
              <Truncate lines={2} width={250}>
                {selectedTask.description ? selectedTask.description : '--'}
              </Truncate>
            </div>
            <div>
              {selectedTask && selectedTask.shipment && (
                <Button
                  bsStyle="link"
                  onClick={(e) => {
                    this.goToShipment(selectedTask.shipment);
                  }}
                >
                  Go to Shipment
                </Button>
              )}
              <Button
                bsStyle="link"
                onClick={() => {
                  this.editTask(selectedTask);
                }}
              >
                Edit
              </Button>
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                ref="overlay"
                overlay={
                  <Popover id="tasks-popover-delete" className="popover-delete-lineitem">
                    <h4>Are you sure you want to delete this task?</h4>
                    <div className="btns">
                      <Button
                        onClick={function () {
                          this.refs.overlay.hide();
                        }.bind(this)}
                        className="btn-secondary"
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => this.deleteTask(selectedTask)} className="btn-primary">
                        Yes
                      </Button>
                    </div>
                  </Popover>
                }
              >
                <Button bsStyle="link">Delete</Button>
              </OverlayTrigger>
            </div>
          </div>
          <div>
            <div>
              <strong>{selectedTask.is_open ? 'Due' : 'Completed'}</strong>
            </div>
            <div>
              {selectedTask.is_open
                ? selectedTask.due_date && moment(selectedTask.due_date).isValid()
                  ? formatDateTime(selectedTask.due_date, true)
                  : '--'
                : formatDateTime(selectedTask.updated_at, true)}
            </div>
          </div>
          <div>
            <div>
              <strong>Assigned to</strong>
            </div>
            <div>
              {selectedTask.assignee ? selectedTask.assignee.first_name + ' ' + selectedTask.assignee.last_name : '--'}
            </div>
          </div>
          <div>
            <div>
              <strong>Assigned by</strong>
            </div>
            <div>
              {selectedTask.created_by_user
                ? selectedTask.created_by_user.first_name + ' ' + selectedTask.created_by_user.last_name
                : '--'}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTask(task) {
    const {shouldShowOwner} = this.props;
    const {caseTitle} = this.props;
    return (
      <div className="tasks__task-container" onClick={() => this.props.showTask(task)}>
        <div className={task.is_open ? 'tasks__task tasks__task-open' : 'tasks__task tasks__task-closed'}>
          <span
            className="tasks__task-checkmark"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleCompleteTask(task);
            }}
          >
            {task.is_open ? (
              <i className="flaticon-check showAsLink" />
            ) : (
              <i className="flaticon-check_filled showAsLink" />
            )}
          </span>
          <div className="tasks__task-collect">
            <span className="tasks__task-description">
              <span className="tasks__task-description-text">
                <strong>{task.description}</strong>
              </span>
            </span>
            <span className="tasks__task-collect2">
              {shouldShowOwner && (
                <span className="tasks__task-assignee">
                  Assigned to{' '}
                  {task.assignee
                    ? task.assignee.id === this.props.user.id
                      ? 'Me'
                      : task.assignee.first_name + ' ' + task.assignee.last_name
                    : 'nobody'}
                  {task.is_open && task.due_date && moment(task.due_date).isValid() && (
                    <span className="tasks__task-due">
                      &nbsp;&ndash; Due {formatDate(task.due_date)} at {formatTime(task.due_date, true)}
                    </span>
                  )}
                  {!task.is_open && (
                    <span className="tasks__task-due"> &ndash; Completed {formatDate(task.updated_at)}</span>
                  )}
                </span>
              )}
            </span>
          </div>
          {!this.props.caseOverride && task.shipment ? (
            <div className="tasks__task-collectEnd">
              <span className="tasks__task-shipmentLink">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.goToShipment(task.shipment);
                  }}
                >
                  Go to Shipment
                </Link>
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  render() {
    const {shipmentId, selectedTask} = this.props;
    const isScopedToShipment = shipmentId ? true : false;

    let selectedTasks = this.props.tasks;

    const selectedUsers =
      this.props.formValues && this.props.formValues.values && this.props.formValues.values.assignee
        ? this.props.formValues.values.assignee
        : [];
    if (this.props.caseOverride) {
      selectedTasks = this.props.caseTasks;
    }

    return (
      <div className="tasks">
        {selectedTask ? (
          this.renderTaskDetails()
        ) : (
          <Fragment>
            {!this.props.caseOverride && (
              <div className="tasks__filter">
                <FormControl
                  componentClass="select"
                  value={this.state.myTasks === true ? 'mine' : 'all'}
                  onChange={(e) => this.setState({myTasks: e.target.value === 'mine' ? true : false})}
                >
                  <option value="mine">My Tasks</option>
                  <option value="all">All Tasks</option>
                </FormControl>
                <FormControl
                  componentClass="select"
                  value={this.state.showCompleted === true ? 'yes' : 'no'}
                  onChange={(e) => this.setState({showCompleted: e.target.value === 'yes' ? true : false})}
                >
                  <option value="no">Not Completed</option>
                  <option value="yes">All</option>
                </FormControl>
                <span className="tasks__add">
                  <Button
                    bsStyle="link"
                    onClick={() => {
                      this.setState({editingTask: null}, () => {
                        this.props.clearSelectedTask(this.props.user.id);
                        this.setState({showTasksForm: true});
                      });
                    }}
                  >
                    <i className="flaticon-plus" />
                    &nbsp;Create a Task
                  </Button>
                </span>
              </div>
            )}
            {this.state.showTasksForm && (
              <TasksForm
                companyUsers={this.props.companyUsers}
                onSubmit={this.handleSubmit}
                cancel={() => {
                  this.setState({showTasksForm: false});
                }}
              />
            )}
            <div className="tasks__container">
              {selectedTasks && selectedTasks.results && selectedTasks.results.length > 0 ? (
                selectedTasks.results.map((task, i) => {
                  return <Fragment key={i}>{this.renderTask(task)}</Fragment>;
                })
              ) : (
                <h3 className="text-center">
                  {this.props.caseOverride ? 'No tasks' : 'No tasks matching these filters.'}
                </h3>
              )}
            </div>
            {this.props.caseOverride && (
              <span className="tasks__add">
                <Button
                  bsStyle="link"
                  onClick={() => {
                    this.setState({editingTask: null}, () => {
                      this.props.clearSelectedTask(this.props.user.id);
                      this.setState({showTasksForm: true});
                    });
                  }}
                >
                  <i className="flaticon-plus pad-right" /> New Task
                </Button>
              </span>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.users.tasks,
    company: state.auth.company,
    user: state.auth.user,
    companyUsers: state.users.companyUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
