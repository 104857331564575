import {Product} from '@shipwell/backend-core-sdk';

function appendIfValueExists(formData: FormData, key: string, value: unknown) {
  if (value !== undefined && value !== null) {
    formData.append(key, value.toString());
  }
}

export const buildProductPayload = (values: Product): FormData => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    const value = values[key as keyof Product];
    if (key !== 'image') {
      appendIfValueExists(formData, key, value);
    }
  });
  if (values.image && typeof values.image !== 'string') {
    formData.append('image', values.image, `${values.product_ref?.toString() || ''}.png`);
  } else if (!values.image) {
    formData.append('image', '');
  }
  return formData;
};
