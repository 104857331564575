import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

/**
 * Purchase Orders - Build Shipment
 * API for managing purchase orders
 */
export async function createPurchaseOrdersShipment(options = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersBuildShipmentPost(options, apiCallback(resolve, reject));
  });
}

export async function createPurchaseOrder(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, apiCallback(resolve, reject));
  });
}

export async function updatePurchaseOrderById(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdPut(id, body, apiCallback(resolve, reject));
  });
}

/**
 * Purchase Orders
 * API for managing purchase orders
 */
export async function getPurchaseOrdersList(params = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersGet(params, apiCallback(resolve, reject));
  });
}

export async function createPurchaseOrders(body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPost(body, apiCallback(resolve, reject));
  });
}

export async function getPurchaseOrderById(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdGet(id, apiCallback(resolve, reject));
  });
}

/**
 * Purchase Orders Line Items
 * API for managing purchase order line items
 */

/**
 * GET / Request purchase order line items
 * @param  {String} id   Purchase order id
 * @return {Promise}
 */
export async function getPurchaseOrderLineItemList(id, options = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsGet(id, options, apiCallback(resolve, reject));
  });
}

/**
 * POST / Create purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function createPurchaseOrderLineItem(id, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPost(id, body, apiCallback(resolve, reject));
  });
}

/**
 * PUT / Update purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function updatePurchaseOrderLineItem(orderId, lineItemId, body = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdPut(
      orderId,
      lineItemId,
      body,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * DELETE / Delete purchase order line item
 * @param  {String} id   Purchase order id
 * @param  {Object} body Line Item details
 * @return {Promise}
 */
export async function deletePurchaseOrderLineItem(orderId, lineItemId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.purchaseOrdersPurchaseOrderIdLineItemsPurchaseOrderLineItemIdDelete(
      orderId,
      lineItemId,
      apiCallback(resolve, reject)
    );
  });
}

/**
 * GET supplier invites
 * @param  {Object} opts Optional parameters
 * @return {Promise}
 */
export async function getSupplierInvites(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.ordersSupplierInvitesGet(opts, apiCallback(resolve, reject));
  });
}

export async function createSupplierInvite(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.ordersSupplierInvitesPost(body, apiCallback(resolve, reject));
  });
}

export async function getSupplierInviteByKey(key) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.ordersSupplierInvitesSupplierInviteKeyGet(key, apiCallback(resolve, reject));
  });
}

export async function updateSupplierInvite(id, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.ordersSupplierInvitesSupplierInviteKeyPut(id, body, apiCallback(resolve, reject));
  });
}
