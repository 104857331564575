import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {ReactNode, JSX} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {VIEW_FREIGHT_INVOICES_PERMISSION} from 'App/containers/settlements/freightInvoices/permissionsConstants';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';
interface FreightInvoicesListCardProps {
  children: ReactNode;
  role: SettlementsDashboardGeneratedBy;
  actions: JSX.Element;
}
export const FreightInvoicesListCard = ({children, role, actions}: FreightInvoicesListCardProps) => {
  const {stmCarrierInvoicing} = useFlags();
  if (!stmCarrierInvoicing) {
    return null;
  }
  return (
    <PermissionsFallback permissions={[VIEW_FREIGHT_INVOICES_PERMISSION]}>
      <Card
        title={role === SettlementsDashboardGeneratedBy.ServiceProvider ? 'Invoices Sent' : 'Invoices Received'}
        isCollapsible
        draggableProvided={undefined}
        actions={actions}
        className="mt-4"
        bodyClassName="p-0"
      >
        <CollapsibleCardContent>{children}</CollapsibleCardContent>
      </Card>
    </PermissionsFallback>
  );
};
