import {useState} from 'react';
import {
  Card,
  CollapsibleCardContent,
  FormikRadioGroup,
  FormikSelect,
  SvgIcon,
  Title,
  Toast,
  Tooltip
} from '@shipwell/shipwell-ui';
import {
  UpdateCompanyParcelLabelConfigSchema,
  UPSImageTypes,
  UPSStockTypes,
  FedExImageTypes,
  FedExStockTypes
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {v4} from 'uuid';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Field, FieldArray, Form, Formik} from 'formik';
import {array, boolean, InferType, object, string} from 'yup';
import Loader from 'App/common/shipwellLoader';
import {FlexBox} from 'App/components/Box';
import {CustomParcelLabelHeader} from 'App/containers/userCompany/parcelLabel/CustomParcelLabelHeader';
import {getCompanyParcelConfig, putCompanyParcelConfig} from 'App/api/company/typed';
import {COMPANY_PARCEL_CONFIG_KEY} from 'App/data-hooks/queryKeys';
import FormFooter from 'App/formComponents/formSections/formFooter';

const upsImageTypeOptions = Object.values(UPSImageTypes).map((UPSImageType) => ({
  value: UPSImageType,
  label: UPSImageType
}));
const upsStockTypeOptions = [
  {label: '4x6', value: UPSStockTypes._4X6},
  {label: '4x8', value: UPSStockTypes._4X8}
];
const fedexImageTypeOptions = [
  {label: 'EPL2 (ELTRON)', value: FedExImageTypes.Epl2},
  {label: 'PDF', value: FedExImageTypes.Pdf},
  {label: 'ZPLII (ZEBRA)', value: FedExImageTypes.Zplii}
];
const fedexPaperTypeOptions = [
  {label: '4x6', value: FedExStockTypes.Paper4X6},
  {label: '4x6.75', value: FedExStockTypes.Paper4X675},
  {label: '4x8', value: FedExStockTypes.Paper4X8},
  {label: '4x9', value: FedExStockTypes.Paper4X9},
  {label: '8.5x11 Label Top Half', value: FedExStockTypes.Paper85X11TopHalfLabel},
  {label: '8.5x11 Label Bottom Half', value: FedExStockTypes.Paper85X11BottomHalfLabel}
];
const fedexStockTypeOptions = [
  {label: '4x6', value: FedExStockTypes.Stock4X6},
  {label: '4x6.75 Leading Doc Tab', value: FedExStockTypes.Stock4X675LeadingDocTab},
  {label: '4x6.75 Trailing Doc Tab', value: FedExStockTypes.Stock4X675TrailingDocTab},
  {label: '4x8', value: FedExStockTypes.Stock4X8},
  {label: '4x9', value: FedExStockTypes.Stock4X9},
  {label: '4x9 Leading Doc Tab', value: FedExStockTypes.Stock4X9LeadingDocTab},
  {label: '4x9 Trailing Doc Tab', value: FedExStockTypes.Stock4X9TrailingDocTab}
];

const validationSchema = object().shape({
  ups_config: object().shape({
    image_type: string().required('Image type is required'),
    stock_type: string().required('Label size is required')
  }),
  fedex_config: object().shape({
    image_type: string().required('Image type is required'),
    stock_type: string().required('Label size is required').nullable(),
    label_on_top: boolean().required('DocTab orientation is required'),
    label_data: array().of(
      object().shape({
        id: string(),
        label: string().nullable().required('A custom header is required'),
        value: string().nullable().required('A data display is required')
      })
    )
  })
});

export type ParcelLabelType = InferType<typeof validationSchema>;

const labelIds = Array.from({length: 6}, () => v4());

export const ParcelLabelsRoute = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const queryClient = useQueryClient();

  const companyParcelConfigQuery = useQuery([COMPANY_PARCEL_CONFIG_KEY], async () => {
    const response = await getCompanyParcelConfig();
    return response.data.data;
  });

  const initialValues = {
    ...companyParcelConfigQuery.data,
    fedex_config: {
      ...companyParcelConfigQuery.data?.fedex_config,
      label_data:
        companyParcelConfigQuery.data?.fedex_config?.label_data?.map((label, i) => ({
          id: labelIds[i],
          label: label.label,
          value: label.value
        })) || []
    }
  };

  const updateCompanyParcelConfigMutation = useMutation(putCompanyParcelConfig);

  const handleSubmit = (values: typeof initialValues, resetForm: () => void) => {
    const updateCompanyParcelLabelConfigSchema: UpdateCompanyParcelLabelConfigSchema = {
      ...values,
      fedex_config: {
        ...values.fedex_config,
        // removing ids from label data before passing to BE
        label_data: values.fedex_config.label_data.map(({label, value}) => ({label, value}))
      }
    };
    updateCompanyParcelConfigMutation.mutate(
      {updateCompanyParcelLabelConfigSchema},
      {
        onSuccess: () => {
          void queryClient.invalidateQueries([COMPANY_PARCEL_CONFIG_KEY]);
          setShowSuccess(true);
          resetForm();
        },
        onError: (error) => {
          console.error(error);
          setShowError(true);
        }
      }
    );
  };

  if (companyParcelConfigQuery.isLoading) {
    return <Loader loading />;
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {resetForm}) => handleSubmit(values, () => resetForm({values}))}
      >
        {({dirty, resetForm, values, setFieldValue, setFieldTouched}) => (
          <Form className="w-full">
            <div className={`flex flex-col gap-4 p-4 ${dirty ? 'pb-40' : ''}`}>
              <Card
                title={
                  <FlexBox gap="s" items="center">
                    <Title>UPS Label Settings</Title>
                    <Tooltip
                      tooltipContent={
                        <TooltipContent
                          title="UPS Label Settings"
                          description="This allows you to choose a default size & orientation for your UPS Parcel Labels."
                        />
                      }
                    >
                      <SvgIcon name="InfoOutlined" />
                    </Tooltip>
                  </FlexBox>
                }
                isCollapsible
                draggableProvided={() => undefined}
              >
                <CollapsibleCardContent>
                  <div className="grid gap-4 md:grid-cols-2">
                    <Field
                      simpleValue
                      label="Image type"
                      name="ups_config.image_type"
                      component={FormikSelect}
                      options={upsImageTypeOptions}
                      clearable={false}
                    />
                    <Field
                      simpleValue
                      label="Label size"
                      name="ups_config.stock_type"
                      component={FormikSelect}
                      options={upsStockTypeOptions}
                      clearable={false}
                    />
                  </div>
                </CollapsibleCardContent>
              </Card>

              <Card
                title={
                  <FlexBox gap="s" items="center">
                    <Title>FedEx Label Settings</Title>
                    <Tooltip
                      tooltipContent={
                        <TooltipContent
                          title="FedEx Label Settings"
                          description="This allows you to choose a default size, orientation & data display for your FedEx Parcel Labels."
                        />
                      }
                    >
                      <SvgIcon name="InfoOutlined" />
                    </Tooltip>
                  </FlexBox>
                }
                isCollapsible
                draggableProvided={() => undefined}
              >
                <CollapsibleCardContent>
                  <FlexBox direction="col" gap="m">
                    <div className="grid gap-4 border-b border-sw-border pb-4 md:grid-cols-2">
                      <Field
                        simpleValue
                        label="Image type"
                        name="fedex_config.image_type"
                        component={FormikSelect}
                        options={fedexImageTypeOptions}
                        clearable={false}
                        onChange={(option: FedExImageTypes) => {
                          setFieldValue('fedex_config.image_type', option);
                          // we should clear the fedex stock type if the previous and current selections are not PDF
                          // this is because the options for stock type updates depending on this selection
                          if (
                            ![values.fedex_config.image_type, option].every(
                              (selection) => selection !== FedExImageTypes.Pdf
                            )
                          ) {
                            setFieldValue('fedex_config.stock_type', '');
                            setFieldTouched('fedex_config.stock_type', false);
                          }
                        }}
                      />
                      <Field
                        simpleValue
                        label="Label size"
                        name="fedex_config.stock_type"
                        component={FormikSelect}
                        options={
                          values.fedex_config.image_type === FedExImageTypes.Pdf
                            ? fedexPaperTypeOptions
                            : fedexStockTypeOptions
                        }
                        clearable={false}
                      />
                    </div>
                    <div className="w-max">
                      <FlexBox gap="s" items="center" justify="start">
                        <Title>DocTab Orientation</Title>
                        <Tooltip
                          tooltipContent={
                            <TooltipContent
                              title="DocTab Orientation"
                              description="This will determine if your DocTab prints on the bottom or top of your label."
                            />
                          }
                        >
                          <SvgIcon name="InfoOutlined" />
                        </Tooltip>
                      </FlexBox>
                    </div>
                    <Field
                      name="fedex_config.label_on_top"
                      component={FormikRadioGroup}
                      options={[
                        {label: 'Print on Top', value: true},
                        {label: 'Print on Bottom', value: false}
                      ]}
                    />

                    <div className="w-max">
                      <FlexBox gap="s" items="center" justify="start">
                        <Title>Label Data Display</Title>
                        <Tooltip
                          tooltipContent={
                            <TooltipContent
                              title="Label Data Display"
                              description="These fields will display on the DocTab when you print your label."
                            />
                          }
                        >
                          <SvgIcon name="InfoOutlined" />
                        </Tooltip>
                      </FlexBox>
                    </div>
                    <FieldArray name="fedex_config.label_data">
                      {({push, remove}) =>
                        labelIds.map((id) => (
                          <CustomParcelLabelHeader key={id} id={id} onAdd={push} onRemove={remove} />
                        ))
                      }
                    </FieldArray>
                  </FlexBox>
                </CollapsibleCardContent>
              </Card>
            </div>
            {dirty ? <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} /> : null}
          </Form>
        )}
      </Formik>
      <Toast show={showSuccess} title="Success!" variant="success" onClose={() => setShowSuccess(false)}>
        <span>Your parcel labels have been updated.</span>
      </Toast>
      <Toast show={showError} title="Error!" variant="error" onClose={() => setShowError(false)}>
        <span>There was an error updating your parcel labels.</span>
      </Toast>
    </>
  );
};

const TooltipContent = ({title, description}: {title: string; description: string}) => {
  return (
    <div className="max-w-xs">
      <span className="font-bold">{title}</span>
      <p className="font-normal">{description}</p>
    </div>
  );
};
