import PropTypes from 'prop-types';
import {DisplayValue} from '@shipwell/shipwell-ui';
import './styles.scss';

const RoutingGuidePolicyDetails = ({policy}) => {
  return (
    <div className="routingGuidePolicyDetails">
      <div className="grid-1-2">
        <DisplayValue label="origins">
          {policy?.origins?.length > 0
            ? policy.origins.map((add) => {
                return <p key={add.id}>{add.formatted_address}</p>;
              })
            : '--'}
        </DisplayValue>
        <DisplayValue label="destinations">
          {policy?.destinations?.length > 0
            ? policy.destinations.map((add) => {
                return <p key={add.id}>{add.formatted_address}</p>;
              })
            : '--'}
        </DisplayValue>
      </div>
      <div className="grid-1-2">
        <DisplayValue label="modes">
          {policy?.modes?.length > 0 ? policy.modes.map((mode) => mode.code).join(', ') : '--'}
        </DisplayValue>
        <DisplayValue label="equipment">
          {policy?.equipment_types?.length > 0 ? policy.equipment_types.map((eq) => eq.name).join(', ') : '--'}
        </DisplayValue>
      </div>
    </div>
  );
};

RoutingGuidePolicyDetails.propTypes = {
  policy: PropTypes.shape({
    origins: PropTypes.array,
    destinations: PropTypes.array,
    modes: PropTypes.array,
    equipment_types: PropTypes.array
  })
};

export default RoutingGuidePolicyDetails;
