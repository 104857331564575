import {useState} from 'react';
import {Select} from '@shipwell/shipwell-ui';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {useCompanyUsers, useUserMe} from 'App/data-hooks';
import {SOURCES} from 'App/utils/createdBy';

export const CreatedByFilter = ({
  defaultIds,
  defaultLabels,
  defaultSources,
  onChange
}: {
  defaultIds: string[];
  defaultLabels: string[];
  defaultSources: string[];
  onChange: (ids: string[], labels: string[], sources: string[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearchVal = useDebounce(searchVal, 300);
  const user = useUserMe();
  const companyUsersQuery = useCompanyUsers(user.data?.company?.id || '', {
    q: debouncedSearchVal,
    pageSize: 100,
    page: 1
  });

  const sourceOptions = Object.keys(SOURCES)
    .filter((source) => source !== 'SHIPWELL_WEB')
    .reduce((results: {value: string; label: string}[], sourceKey) => {
      if (SOURCES[sourceKey].toLowerCase().includes(searchVal.toLowerCase())) {
        results.push({value: sourceKey, label: SOURCES[sourceKey]});
      }
      return results;
    }, []);

  const userOptions =
    companyUsersQuery.data?.results?.map((user) => {
      return {
        value: user.id || '',
        label: `${user.first_name} ${user.last_name}`
      };
    }) || [];

  const options = [...sourceOptions, ...userOptions].sort((a, b) => a.label.localeCompare(b.label));

  const defaultIdValues = defaultIds.map((userId, i) => ({
    value: userId,
    label: defaultLabels[i]
  }));
  const defaultSourceValues = Object.keys(SOURCES).reduce((acc: {value: string; label: string}[], sourceKey) => {
    if (defaultSources.some((source) => source === sourceKey)) {
      acc.push({value: sourceKey, label: SOURCES[sourceKey]});
    }
    return acc;
  }, []);

  const defaultValues = [...defaultIdValues, ...defaultSourceValues];

  return (
    <Select
      value={defaultValues}
      onInputChange={setSearchVal}
      onChange={(options: {label: string; value: string}[]) => {
        // separate source options from user options
        const sourceOptions = options
          .filter((option) => Object.keys(SOURCES).some((sourceKey) => sourceKey === option.value))
          .map((option) => option.value);
        const userIds = options
          .filter((option) => Object.keys(SOURCES).every((sourceKey) => sourceKey !== option.value))
          .map((option) => option.value);
        const userLabels = options
          .filter((option) => Object.keys(SOURCES).every((sourceKey) => sourceKey !== option.value))
          .map((option) => option.label);
        onChange(userIds, userLabels, sourceOptions);
      }}
      isLoading={companyUsersQuery.isInitialLoading}
      options={options}
      isMulti
      label="Search Users or Sources"
    />
  );
};
