import {ShippingDashboardStop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {filterConsequentStopsWithTripManagementEta} from 'App/components/stopEta/utils';
import {StopEtaConsequentStops, StopEtaLastUpdated, StopEtaPill, StopsItineraryOverlay} from 'App/components/stopEta';

export const UpcomingEtasCell = ({stops, isLTL}: {stops: ShippingDashboardStop[]; isLTL: boolean}) => {
  const nextStop = stops?.find((stop) => !stop.confirmed_arrival_at && !stop.confirmed_departure_at);
  const consequentStops = filterConsequentStopsWithTripManagementEta(stops);

  //render eta info if there's an active stop with trip management eta
  if (!nextStop?.trip_management_eta) {
    return <>--</>;
  }
  return (
    <StopsItineraryOverlay stops={stops} isLoadBoard={false} isLTL={isLTL}>
      <div className="overflow-auto">
        <div className="flex items-center">
          <StopEtaPill stop={nextStop} duration="hours" size="sm" />
          <StopEtaConsequentStops stops={consequentStops} />
        </div>
        <StopEtaLastUpdated
          warningIconWidth="16"
          warningIconHeight="16"
          stop={nextStop}
          isExpandedLastUpdateDisplay={false}
          // this component's PropTypes are being miscommunicated
          // default props are provided, but TS isn't taking the hint
          shipment={undefined}
          setSuccess={undefined}
          fetchDetails={undefined}
        />
      </div>
    </StopsItineraryOverlay>
  );
};
