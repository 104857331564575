import {
  Address,
  EquipmentTypeValues,
  ShipmentLineItem,
  TotalWeightOverride,
  ContractCriteriaByValueRequestCurrencyEnum,
  ContractCriteriaByValueRequestModeEnum,
  ContractCriteriaByValueRequestTotalWeightUnitEnum,
  ContractCriteriaByValueRequestRequestTypeEnum,
  ContractCriteriaByShipmentRequestRequestTypeEnum,
  ShipwellError,
  ContractCriteriaRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {CONTRACT_CHARGE_ITEMS} from '../queryKeys';
import {getChargeItemsTotals, getTotalPackagesAndWeight} from './utils';
import {getContractChargeItems, CorrectedContractCalculateChargeItemsResponse} from 'App/api/contracts/typed';
import {useDebounce} from 'App/utils/hooks/useDebounce';

type CalculateChargeItemsByValue = {
  contractRateCurrency?: ContractCriteriaByValueRequestCurrencyEnum;
  contractWeightUnit?: ContractCriteriaByValueRequestTotalWeightUnitEnum;
  equipmentType?: EquipmentTypeValues | null;
  mode?: ContractCriteriaByValueRequestModeEnum | null;
  stopAddresses?: Address[];
  totalPackages?: number | null;
  totalWeight?: number | null;
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
  shipmentId?: never;
};

type CalculateChargeItemsByShipment = {
  shipmentId: string;
  contractRateCurrency?: never;
  contractWeightUnit?: never;
  equipmentType?: never;
  mode?: never;
  stopAddresses?: never;
  totalPackages?: never;
  totalWeight?: never;
  lineItems?: never;
  totalWeightOverride?: never;
};

type GetContractChargeItemsParams = {
  contractId?: string | null;
  options?: UseQueryOptions<
    CorrectedContractCalculateChargeItemsResponse,
    ShipwellError,
    CorrectedContractCalculateChargeItemsResponse,
    (string | null | undefined | ContractCriteriaRequest)[]
  >;
} & (CalculateChargeItemsByValue | CalculateChargeItemsByShipment);

export const useGetContractChargeItems = (params: GetContractChargeItemsParams) => {
  const {
    contractId,
    contractRateCurrency,
    contractWeightUnit,
    equipmentType,
    mode,
    stopAddresses,
    totalPackages,
    totalWeight,
    lineItems,
    totalWeightOverride,
    shipmentId,
    options
  } = params;

  const {enabled = true, ...restOptions} = options || {};
  const isByShipmentId = !!shipmentId;
  const isValidForm =
    !!stopAddresses?.length && stopAddresses.length > 1 && !!contractRateCurrency && !!equipmentType && !!mode;

  const {shipmentTotalPackages, shipmentTotalWeightSum} = getTotalPackagesAndWeight({
    contractWeightUnit,
    totalWeight,
    totalPackages,
    lineItems,
    totalWeightOverride
  });

  const contractCriteriaRequest = isByShipmentId
    ? {
        shipment_id: shipmentId,
        request_type: ContractCriteriaByShipmentRequestRequestTypeEnum.ByShipment
      }
    : {
        currency: contractRateCurrency,
        equipment_type: equipmentType,
        ...(mode ? {mode} : {}),
        stop_addresses: stopAddresses,
        total_packages: shipmentTotalPackages,
        total_weight: shipmentTotalWeightSum,
        ...(totalWeight ? {total_weight_unit: contractWeightUnit} : {}),
        request_type: ContractCriteriaByValueRequestRequestTypeEnum.ByValue
      };

  const debouncedQueryKey = useDebounce(contractCriteriaRequest, 300);

  const {isError, data, isFetching} = useQuery(
    [CONTRACT_CHARGE_ITEMS, contractId, debouncedQueryKey],
    () => {
      if (!contractId) throw new Error('contractId is required.');

      return getContractChargeItems({contractId, contractCriteriaRequest});
    },
    {
      onError: (error) => console.error(error),
      enabled: enabled && !!contractId && (isByShipmentId || isValidForm),
      ...restOptions
    }
  );

  const {charge_items: charges} = data || {};

  const {lineHaulCharge, fuelCharge, additionalStopCharges, currency, total} = getChargeItemsTotals(charges);

  return {
    isLoadingChargeItems: isFetching,
    isError,
    lineHaulCharge,
    fuelCharge,
    additionalStopCharges,
    total,
    currency
  };
};
