import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {CompanyLogo} from '@shipwell/backend-core-sdk';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {getSubdomain} from 'App/utils/location';
import {COMPANY_LOGO_QUERY} from 'App/data-hooks/queryKeys';
import {getCompanyLogo} from 'App/api/company/typed';
import {isWhiteLabel} from 'App/utils/globals';

const nonWhiteLabel: CompanyLogo[] = [
  {
    image_type: 'INLINE_WHITE',
    logo: '/images/svgs/shipwell-logo-white.svg',
    company_name: 'Shipwell'
  },
  {
    image_type: 'LOGO_COLOR',
    logo: '/images/svgs/shipwell-logo.svg',
    company_name: 'Shipwell'
  },
  {
    image_type: 'INLINE_COLOR',
    logo: '/images/svgs/shipwell-logo.svg',
    company_name: 'Shipwell'
  },
  {
    image_type: 'LOGO_WHITE',
    logo: '/images/shipwell_logo_white.png',
    company_name: 'Shipwell'
  }
];

export const useWhitelabel = () => {
  const subdomain = getSubdomain();
  const companyLogoQuery = useQuery(
    [COMPANY_LOGO_QUERY, subdomain],
    async () => {
      const response = await getCompanyLogo(subdomain);
      return response.data;
    },
    {
      enabled: !!subdomain && isWhiteLabel(),
      staleTime: STATIC_DATA_STALE_TIME,
      onSuccess(data) {
        const companyLogo = data.find((logo) => logo.image_type === 'INLINE_COLOR');
        const inlineLogo = data.find((logo) => logo.image_type === 'LOGO_COLOR');

        if (companyLogo) {
          localStorage.setItem('whiteLabelTitle', companyLogo.company_name || '');
          localStorage.setItem('whiteLabelLogo', companyLogo.logo);
          document.title = companyLogo.company_name || '';
          document.body.classList.add(`brand-${subdomain}`);
        }
        if (inlineLogo) {
          document.querySelectorAll<HTMLAnchorElement>("link[rel*='icon']").forEach((link) => {
            link.href = inlineLogo.logo;
          });
        }
      }
    }
  );

  useEffect(() => {
    if (!companyLogoQuery.isSuccess && companyLogoQuery.fetchStatus === 'idle') {
      document.title = 'Shipwell';
      localStorage.setItem('whiteLabelTitle', 'Shipwell');
      localStorage.removeItem('whiteLabelLogo');
      document.body.classList.add('brand-shipwell');
    }
  }, [companyLogoQuery.isSuccess, companyLogoQuery.fetchStatus]);

  const logos = companyLogoQuery.isSuccess ? companyLogoQuery.data : nonWhiteLabel;

  return {
    logos,
    companyLogoQuery
  };
};
