import {useContext} from 'react';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';

const FacilityCreationModalFooter = ({isValid, isSubmitting}: {isValid: boolean; isSubmitting?: boolean}) => {
  const {activeStep, onClose, setPreviousStep} = useContext(StepFormContext);
  return (
    <ModalFormFooter
      onCancel={onClose}
      isValid={isValid}
      isSubmitting={isSubmitting}
      primaryActionName={activeStep === 4 ? 'Submit' : 'Next'}
      options={
        activeStep > 0 ? (
          <DeprecatedButton variant="tertiary" onClick={setPreviousStep}>
            <SvgIcon name="ArrowBack" />
            Back
          </DeprecatedButton>
        ) : null
      }
    />
  );
};

export default FacilityCreationModalFooter;
