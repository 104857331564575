/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, DependencyList} from 'react';
import debounce from 'lodash/debounce';

const useDebouncedEffect = (effect: () => void, delay: number, deps: DependencyList, options = {}) => {
  const debounceEffect = debounce(effect, delay, options);
  const callback = useCallback(debounceEffect, deps);

  useEffect(() => {
    callback();
    return () => debounceEffect.cancel();
  }, [callback, delay]);
};

export default useDebouncedEffect;
