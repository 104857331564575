import {useState, useEffect} from 'react';
import {Formik, Form, Field, useFormikContext, FieldArray} from 'formik';
import PropTypes from 'prop-types';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {compose} from 'recompose';
import classNames from 'classnames';
import pick from 'lodash/pick';
import {
  ContainerType,
  OrderStatus,
  ContainerPackagingType,
  StopReason,
  LegStageStageTypeEnum
} from '@shipwell/corrogo-sdk';
import {
  Card,
  DeprecatedButton,
  SvgIcon,
  Checkbox,
  FormikCheckbox,
  FormikTextInput,
  FormikDateTimePicker,
  FormikSelect,
  Tooltip,
  Dropdown,
  Modal
} from '@shipwell/shipwell-ui';
import {object, string, number, date, boolean, array} from 'yup';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import first from 'lodash/first';
import pluralize from 'pluralize';
import {v4 as generateUUID} from 'uuid';
import {
  createShipmentItem,
  updateShipmentItem,
  updateLegItem,
  createDrayageLeg,
  removeLegFromShipment
} from 'App/api/corrogo';
import {assignLegToShipment} from 'App/api/corrogo/typed';
import withStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {requiredMessage} from 'App/utils/yupHelpers';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';
import withExpanded from 'App/components/withExpanded';
import useListState from 'App/utils/hooks/useListState';
import {PROPTYPE_FORMIK_FIELD_ARRAY, PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import {
  checkIsOverweight,
  containerDisplayTypes,
  containerTypeOptions,
  containerInfoMap
} from 'App/containers/shipments/utils/typed';
import HazmatCodesField from 'App/formComponents/formSections/hazmatCodesField';
import getNil from 'App/utils/getNil';
import {useShipmentLegs, useDrayageLegs, useTransportationOrders, useV3Shipment} from 'App/data-hooks';
import PageHeader from 'App/common/pageHeader';
import Loader from 'App/common/shipwellLoader';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {LineItemsContainer} from 'App/containers/shipments/drayage/components/ProductLineItems';

const defaultFormValues = {
  containers: [
    {
      container_number: null,
      seal_number: null,
      quantity: 1,
      package_type: null,
      gross_weight: null,
      gross_weight_unit: 'LB',
      container_type: null,
      min_temp: null,
      max_temp: null,
      length: null,
      width: null,
      height: null,
      dimension_unit: 'FT',
      commodity: null,
      container_return_date: null,
      hazmat: false,
      hazmat_code: null,
      key: generateUUID()
    }
  ]
};

const refrigeratedFields = [
  containerDisplayTypes.TWENTY_FOOT_REEFER,
  containerDisplayTypes.FORTY_FOOT_REEFER,
  containerDisplayTypes.FORTY_FIVE_FOOT_REEFER,
  containerDisplayTypes.TWENTY_FOOT_REEFER_TANK
];

const specialFields = [...refrigeratedFields, containerDisplayTypes.OVERSIZE];

export const containerValidationSchema = object().shape({
  container_number: string().label('Container #').nullable(),
  seal_number: string().label('Seal #').nullable(),
  gross_weight: number().label('Total Weight').nullable().required(requiredMessage),
  gross_weight_unit: string().label('Weight Unit').nullable().required(requiredMessage),
  container_type: string().label('Container Type').nullable(),
  min_temp: number()
    .label('Min Temp (F)')
    .nullable()
    .when('container_type', (containerType, schema) =>
      refrigeratedFields.includes(containerType) ? schema.required(requiredMessage) : schema
    ),
  max_temp: number()
    .label('Min Temp (F)')
    .nullable()
    .when('container_type', (containerType, schema) =>
      refrigeratedFields.includes(containerType) ? schema.required(requiredMessage) : schema
    ),
  length: number()
    .label('Length')
    .nullable()
    .when('container_type', (containerType, schema) =>
      containerType === containerDisplayTypes.OVERSIZE ? schema.required(requiredMessage) : schema
    ),
  width: number()
    .label('Width')
    .nullable()
    .when('container_type', (containerType, schema) =>
      containerType === containerDisplayTypes.OVERSIZE ? schema.required(requiredMessage) : schema
    ),
  height: number()
    .label('Height')
    .nullable()
    .when('container_type', (containerType, schema) =>
      containerType === containerDisplayTypes.OVERSIZE ? schema.required(requiredMessage) : schema
    ),
  dimension_unit: string()
    .label('Dimensions Unit')
    .nullable()
    .when('container_type', (containerType, schema) =>
      containerType === containerDisplayTypes.OVERSIZE ? schema.required(requiredMessage) : schema
    ),
  commodity: string().label('Commodity').nullable(),
  container_return_date: date().label('Container Return Date').nullable(),
  hazmat: boolean().label('Hazmat'),
  hazmat_code: object()
    .shape({
      hazard_class: string().nullable(),
      id: number().nullable(),
      identification_number: string().nullable(),
      packing_group: string().nullable(),
      proper_shipping_name: string().nullable()
    })
    .label('Hazmat Code')
    .nullable()
    .when('hazmat', (hazmat, schema) => (hazmat ? schema.required(requiredMessage) : schema))
});

const validationSchema = object().shape({
  containers: array().of(containerValidationSchema)
});

export const grossWeightUnitTypeOptions = [
  {value: 'LB', label: 'LB'},
  {value: 'KG', label: 'KG'}
];
export const dimensionUnitTypeOptions = [
  {value: 'FT', label: 'FT'},
  {value: 'M', label: 'M'}
];

export const baseOverweightMessage = 'Warning - Overweight';

export const ContainerTypeSpecialFields = ({containerType, buildFieldName, requiredFields}) =>
  refrigeratedFields.includes(containerType) ? (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <Field name={buildFieldName('min_temp')} label="Min Temp (F)" required component={FormikTextInput} />
        <Field name={buildFieldName('max_temp')} label="Max Temp (F)" required component={FormikTextInput} />
      </div>
    </div>
  ) : containerType === containerDisplayTypes.OVERSIZE ? (
    <div className="grid grid-cols-9 ">
      <Field
        className="col-span-2"
        textInputClassname="rounded-r-none"
        name={buildFieldName('length')}
        label="Length"
        required={requiredFields}
        component={FormikTextInput}
      />
      <Field
        className="col-span-2"
        textInputClassname="rounded-none"
        name={buildFieldName('width')}
        label="Width"
        required={requiredFields}
        component={FormikTextInput}
      />
      <Field
        className="col-span-2"
        textInputClassname="rounded-none"
        name={buildFieldName('height')}
        label="Height"
        required={requiredFields}
        component={FormikTextInput}
      />
      <span className="col-span-2">
        <Field
          className="min-w-0"
          controlClassName="rounded-l-none"
          name={buildFieldName('dimension_unit')}
          label="Unit"
          required={requiredFields}
          clearable={false}
          simpleValue
          component={FormikSelect}
          options={dimensionUnitTypeOptions}
        />
      </span>
      <span className="place-self-center">
        <Tooltip
          tooltipContent={<span className="successLabel">Oversized Container tool tip</span>} // need to sync with design
          trigger="hover"
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </span>
    </div>
  ) : null;

ContainerTypeSpecialFields.propTypes = {
  containerType: PropTypes.string,
  buildFieldName: PropTypes.func,
  requiredFields: PropTypes.bool
};

const ContainersFields = ({
  isContainedInArray = true,
  showUniqueFields = true,
  requiredFields = true,
  containerType,
  hazmat,
  grossWeight,
  grossWeightUnit,
  index = 0
}) => {
  const [isOverweight, setIsOverweight] = useState(false);

  const buildFieldName = (fieldName) => {
    return isContainedInArray ? `containers[${index}].${fieldName}` : fieldName;
  };

  useEffect(() => {
    setIsOverweight(checkIsOverweight(grossWeight, grossWeightUnit));
  }, [grossWeightUnit, grossWeight]);

  const overweightMessage = isOverweight ? baseOverweightMessage : null;

  const showContainerTypeSpecialFields = specialFields.includes(containerType);

  return (
    <div className="mb-4 grid auto-rows-max">
      {showUniqueFields && (
        <div className="grid grid-cols-2 gap-4 py-2 pl-2">
          <Field name={buildFieldName('container_number')} label="Container #" component={FormikTextInput} />
          <Field name={buildFieldName('seal_number')} label="Seal #" component={FormikTextInput} />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 py-2 pl-2">
        <div className="grid grid-cols-5">
          <Field
            className="col-span-4"
            textInputClassname="rounded-r-none"
            name={buildFieldName('gross_weight')}
            component={FormikTextInput}
            label="Total Weight"
            required={requiredFields}
            type="number"
            isWarning={isOverweight}
            message={overweightMessage}
          />
          <Field
            className="min-w-0"
            controlClassName="rounded-l-none"
            name={buildFieldName('gross_weight_unit')}
            label="Unit"
            required={requiredFields}
            simpleValue
            clearable={false}
            component={FormikSelect}
            options={grossWeightUnitTypeOptions}
          />
        </div>
        <Field name={buildFieldName('commodity')} label="Commodity" component={FormikTextInput} />
      </div>
      <div className="grid grid-cols-2 pl-1">
        <div
          className={classNames('grid auto-rows-max px-1 pb-2 py-2 mr-1', {
            'bg-sw-background rounded-t': showContainerTypeSpecialFields
          })}
        >
          <Field
            name={buildFieldName('container_type')}
            label="Container Type"
            component={FormikSelect}
            simpleValue
            options={containerTypeOptions}
          />
        </div>
        <div className="py-2 pl-2">
          <Field
            name={buildFieldName('container_return_date')}
            label="Container Return Date"
            component={FormikDateTimePicker}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 pl-1">
        {showContainerTypeSpecialFields ? (
          <div
            className={classNames('px-1 pt-1 pb-2 mr-1', {
              'bg-sw-background rounded-b': showContainerTypeSpecialFields
            })}
          >
            <ContainerTypeSpecialFields
              buildFieldName={buildFieldName}
              requiredFields={requiredFields}
              containerType={containerType}
            />
          </div>
        ) : null}
        <div className="flex">
          <div className="self-center pl-1 pr-2 pt-1">
            <Field name={buildFieldName('hazmat')} component={FormikCheckbox} label="Hazmat" />
          </div>
          {hazmat ? (
            <div className={classNames({'pl-2': showContainerTypeSpecialFields}, 'px-1 pt-1 flex-auto')}>
              <HazmatCodesField name={buildFieldName('hazmat_code')} label="Hazmat Code" required={requiredFields} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ContainersFields.propTypes = {
  isContainedInArray: PropTypes.bool,
  showUniqueFields: PropTypes.bool,
  requiredFields: PropTypes.bool,
  containerType: PropTypes.string,
  hazmat: PropTypes.bool,
  grossWeight: PropTypes.number,
  grossWeightUnit: PropTypes.string,
  index: PropTypes.number
};

const UpdateContainerModal = ({onModalSubmit, numSelectedItems}) => {
  return (
    <Formik
      initialValues={{
        ...defaultFormValues.containers[0]
      }}
      onSubmit={onModalSubmit}
    >
      {({values}) => (
        <Form noValidate>
          <div className="pr-4 pt-2">
            <span className="text-sw-form-helper-text">
              If any containers share the same information, enter it below and it will be applied to all containers. You
              will be able to edit individual containers on the container details page.
            </span>
            <div className="pt-4">
              <ContainersFields
                isContainedInArray={false}
                showUniqueFields={false}
                requiredFields={false}
                containerType={values.container_type}
                hazmat={values.hazmat}
                grossWeight={values.gross_weight}
                grossWeightUnit={values.gross_weight_unit}
              />
            </div>
            <DeprecatedButton variant="primary" type="submit" className="float-right">
              {`Update ${numSelectedItems} ${pluralize('Container', numSelectedItems)}`}
            </DeprecatedButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UpdateContainerModal.propTypes = {
  onModalSubmit: PropTypes.func,
  numSelectedItems: PropTypes.number
};

const CollapsibleContainerFields = withExpanded(ContainersFields);

const Containers = ({push, remove, replace}) => {
  const {values, setFieldValue} = useFormikContext();
  const containers = get(values, 'containers', []);
  const {everyMatchesState, someMatchState, getItemState, filterByState, setAllItemStates, setItemState} =
    useListState(containers);
  const [showUpdateContainersModal, setShowUpdateContainersModal] = useState(false);

  const containsTruthyState = someMatchState(true);
  const containsOnlyTruthyStates = everyMatchesState(true);
  const selectedItems = filterByState(true);
  const numSelectedItems = selectedItems.length;

  const onModalSubmit = (values) => {
    selectedItems.forEach((item) => {
      const containerIndex = containers.findIndex((container) => isEqual(container.key, item.key));

      // merges the original container with the modal values, prioritizing any filled in modal value over the original
      replace(containerIndex, {
        ...mergeWith({}, containers[containerIndex], values, (baseValue, newValue) =>
          newValue ? newValue : baseValue
        ),
        key: item.key
      });
    });
    setAllItemStates(false);
    setShowUpdateContainersModal(false);
  };

  // do not allow for zero containers to be present
  useEffect(() => {
    if (isEqual(containers.length, 0)) {
      push({...defaultFormValues.containers[0], key: generateUUID()});
    }
  }, [containers, push]);

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: document.querySelector('body').scrollHeight
    });
  }, [containers.length]);

  const handleBulkClearFields = () => {
    filterByState(true).forEach((item) =>
      replace(
        containers.findIndex((container) => isEqual(container, item)),
        {...defaultFormValues.containers[0], key: item.key}
      )
    );
    setAllItemStates(false);
  };

  return (
    <>
      <p>
        Add all the containers that are a part of this shipment and fill out the details. You can bulk update by
        selecting all of the containers you want to edit.
      </p>
      <div className="mx-4 flex items-center justify-between">
        <div>
          <Checkbox
            checked={containsOnlyTruthyStates || containsTruthyState}
            name="select-all"
            label={containsTruthyState ? 'Deselect All' : 'Select All'}
            indeterminate={!containsOnlyTruthyStates && containsTruthyState}
            onClick={() => setAllItemStates(!containsTruthyState)}
          />
        </div>
        <div className="text-right text-[12px] text-sw-form-helper-text">Total Containers: {containers.length}</div>
      </div>
      {containers.map(
        (
          {
            container_type: containerType,
            hazmat,
            gross_weight: grossWeight,
            gross_weight_unit: grossWeightUnit,
            transportationOrderId
          },
          index
        ) => (
          <div key={index} className="my-4">
            <Card
              title={
                <>
                  <div className="mr-2">
                    <Checkbox
                      checked={getItemState(containers[index])}
                      name={`select-container-${index + 1}`}
                      placeholder="Select Container"
                      onClick={() => {
                        setItemState(containers[index], !getItemState(containers[index]));
                      }}
                    />
                  </div>
                  {`Container #${index + 1}`}
                </>
              }
              isCollapsible
              className="mb-6"
              actions={
                <Dropdown indicator={false} drop="left" variant="icon" icon={<SvgIcon name="Overflow" />}>
                  {({onClick}) => (
                    <>
                      <li
                        onClick={() => {
                          push({
                            ...values.containers[index],
                            container_number: '',
                            seal_number: '',
                            key: generateUUID(),
                            id: null
                          });

                          onClick();
                        }}
                      >
                        Clone Container
                      </li>
                      {!isEqual(containers[index], defaultFormValues.containers[0]) ? (
                        <li
                          onClick={() => {
                            replace(index, {...defaultFormValues.containers[0], key: containers[index].key});
                            onClick();
                          }}
                        >
                          Clear All Fields
                        </li>
                      ) : (
                        <li className="text-sw-disabled-text">Clear All Fields</li>
                      )}
                      {containers.length > 1 && !transportationOrderId ? (
                        <DeleteWarningTooltip
                          title="Delete Container"
                          primaryActionText="Delete"
                          onDelete={() => {
                            remove(index);
                            onClick();
                          }}
                          message="Are you sure you want to delete this container?"
                        >
                          <li>Delete Container</li>
                        </DeleteWarningTooltip>
                      ) : (
                        <li className="text-sw-disabled-text">Delete Container</li>
                      )}
                    </>
                  )}
                </Dropdown>
              }
            >
              <CollapsibleContainerFields
                containerType={containerType}
                hazmat={hazmat}
                grossWeight={grossWeight}
                grossWeightUnit={grossWeightUnit}
                index={index}
              />
              <LineItemsContainer
                legItems={containers[index].child_units || []}
                onSubmit={(lineItems) => setFieldValue(`containers[${index}].child_units`, lineItems)}
                onDelete={(lineItemIndex) =>
                  setFieldValue(
                    `containers[${index}].child_units`,
                    values.containers[index].child_units.filter(
                      (childUnit, childUnitIndex) => childUnitIndex !== lineItemIndex
                    )
                  )
                }
              />
            </Card>
          </div>
        )
      )}
      <Card
        title={
          <DeprecatedButton
            variant="tertiary"
            onClick={() => push({...defaultFormValues.containers[0], key: generateUUID()})}
          >
            <SvgIcon name="AddCircleOutlined" />
            <span>Add Container</span>
          </DeprecatedButton>
        }
      />
      {containsTruthyState ? (
        <div className="fixed bottom-[5.5rem] left-1/2 flex w-7/12 -translate-x-1/2 content-center justify-between rounded border-1 border-sw-border bg-sw-background p-2 drop-shadow-md">
          <div className="flex items-center justify-between text-xs">
            <span className="mx-2">
              {numSelectedItems} {pluralize('container', numSelectedItems)} selected
            </span>
            <a className="mx-2" href="#" onClick={() => setAllItemStates(false)}>
              Deselect All
            </a>
          </div>
          <div className="flex items-center">
            <DeprecatedButton
              variant="warning"
              size="small"
              className="mx-2"
              onClick={() => {
                selectedItems.forEach((item, index) => {
                  remove(containers.findIndex((container) => isEqual(container, item)) - index);
                });
              }}
            >
              Delete Containers
            </DeprecatedButton>
            <DeprecatedButton variant="secondary" size="small" className="mx-2" onClick={handleBulkClearFields}>
              Clear Container Fields
            </DeprecatedButton>
            <DeprecatedButton
              variant="secondary"
              size="small"
              className="mx-2"
              onClick={() => setShowUpdateContainersModal(true)}
            >
              Update Containers
            </DeprecatedButton>
          </div>
        </div>
      ) : null}
      <Modal
        show={showUpdateContainersModal}
        title={`Update ${numSelectedItems} ${pluralize('Container', numSelectedItems)}`}
        footerComponent={null}
        size="large"
        bodyVariant="disableOverflowScroll"
        classNamePrefix="containers"
        onClose={() => setShowUpdateContainersModal(false)}
      >
        <UpdateContainerModal onModalSubmit={onModalSubmit} numSelectedItems={numSelectedItems} />
      </Modal>
    </>
  );
};

Containers.propTypes = {
  ...PROPTYPE_FORMIK_FIELD_ARRAY
};

export const buildContainerPayload = (orderId, container) => {
  const containerInfo = containerInfoMap[container.container_type];
  const isOversize = container.container_type === containerDisplayTypes.OVERSIZE;
  return {
    associated_stops: [
      {stop: {sequence_id: 1}, reason: StopReason.Load},
      {stop: {sequence_id: 2}, reason: StopReason.Unload}
    ],
    order_id: orderId,
    identification_number: container.container_number || undefined,
    seals: [
      container.seal_number
        ? {
            qualifier: 'default-seal',
            value: container.seal_number
          }
        : null
    ].filter((seal) => !!seal),
    quantity: container.quantity,
    packaging_type: ContainerPackagingType.Container,
    gross_weight: {
      value: container.gross_weight,
      unit: container.gross_weight_unit
    },
    container_type: containerInfo?.container_type || null,
    dimensions: {
      length: isOversize ? container.length : containerInfo?.length || null,
      width: isOversize ? container.width : containerInfo?.width || null,
      height: isOversize ? container.height : containerInfo?.height || null,
      unit: isOversize ? container.dimension_unit : containerInfo?.dimension_unit || 'FT'
    },
    return_by: container.container_return_date || null,
    hazmat: container.hazmat ? container.hazmat_code : null,
    description: container.commodity,
    temperature:
      containerInfo?.container_type === ContainerType.Refrigerated ||
      containerInfo?.container_type === ContainerType.RefrigeratedTank
        ? {
            unit: 'F',
            minimum: container?.min_temp,
            maximum: container?.max_temp
          }
        : null,
    child_units: container.child_units || []
  };
};

const DrayageContainersForm = ({shipmentId, onSubmit, onPrevious, setSuccess, setError}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const createShipmentItemMutation = useMutation(
    ({shipmentId, shipmentItemData}) => createShipmentItem(shipmentId, shipmentItemData),
    {
      onError: (error) => setError('Error creating drayage container!', error.message),
      onSuccess: (data) => setSuccess('Success!', `Drayage container ${data.data.id} was successfully created!`)
    }
  );

  const updateShipmentItemMutation = useMutation(
    ({shipmentId, shipmentItemId, shipmentItemData}) =>
      updateShipmentItem(shipmentId, shipmentItemId, shipmentItemData),
    {
      onError: (error) => setError('Error updating drayage container!', error.message),
      onSuccess: (data) => setSuccess('Success!', `Drayage container ${data.data.id} was successfully updated!`)
    }
  );

  const orderId = first(getNil(shipmentQuery, 'data.customer.order_ids', []));
  const initialContainersValues = get(shipmentQuery, 'context.containerFormValues');

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const handleSubmit = async (values, actions) => {
    const containers = get(values, 'containers', []);
    const mutations = containers.map((container) => {
      const payload = buildContainerPayload(orderId, container);
      return container.id
        ? updateShipmentItemMutation.mutateAsync({
            shipmentId,
            shipmentItemId: container.id,
            shipmentItemData: payload
          })
        : createShipmentItemMutation.mutateAsync({shipmentId, shipmentItemData: payload});
    });
    await Promise.allSettled(mutations);

    actions.setSubmitting(false);
    onSubmit();
  };

  return (
    <div className="mx-4 mb-4 pb-10">
      <Formik
        initialValues={{
          ...defaultFormValues,
          ...initialContainersValues
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form noValidate>
          <Card title="Container Details" className="mb-10">
            <FieldArray name="containers" component={Containers} />
          </Card>
          <FormFooter
            primaryActionName="Next - Shipment Review"
            secondaryActionName="Previous - Shipment Information"
            onCancel={onPrevious}
            displaySecondaryLeft
            ignoreIsDirty
          />
        </Form>
      </Formik>
    </div>
  );
};

DrayageContainersForm.propTypes = {
  ...WithStatusToastsPropTypes,
  onSubmit: PropTypes.func,
  onPrevious: PropTypes.func,
  orderId: PropTypes.string
};

DrayageContainersForm.defaultProps = {
  onSubmit: () => {},
  onPrevious: () => {}
};

export default compose(withStatusToasts)(DrayageContainersForm);

function useCreateLeg(shipmentId) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate();

  const assignLegToShipmentMutation = useMutation(
    ({shipmentId, leg}) => assignLegToShipment(shipmentId, leg.id, []),
    createOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      onError: (error) => console.error('Error assigning leg to shipment:', error.message),
      mergeData: (prevData, {leg}) => ({
        ...prevData,
        stages: [...prevData.stages, {leg: leg, stage_type: LegStageStageTypeEnum.Leg}]
      })
    })
  );

  const createLegMutation = useMutation(({data}) => createDrayageLeg(data), {
    onError: (error) => console.error('Error creating leg!', error.message)
  });

  async function createLeg(data) {
    const leg = await createLegMutation.mutateAsync({data});
    return assignLegToShipmentMutation.mutateAsync({shipmentId, leg: leg.data});
  }

  return createLeg;
}

export const EditContainers = ({params, router}) => {
  const createOptimisticUpdateHandlers = useOptimisticUpdate();
  const shipmentQuery = useV3Shipment(params.shipment_id);
  const shipmentLegsQuery = useShipmentLegs(params.shipment_id, {
    cacheTime: 0
  });

  const {getTransportationOrdersOfStatus} = useTransportationOrders(params.shipment_id);
  const transportationOrders = getTransportationOrdersOfStatus(OrderStatus.Accepted);

  const legIds = get(shipmentLegsQuery, 'data', []).map((leg) => leg.id);
  const legQueries = useDrayageLegs(legIds);
  const queryClient = useQueryClient();

  const updateLegItemMutation = useMutation(
    ({legId, legItemId, data}) => updateLegItem(legId, legItemId, data),
    createOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, params.shipment_id], {
      onError: (error) => console.error('Error updating drayage leg container!', error.message),
      mergeData: (prevData, {data, legId}) => ({
        ...prevData,
        stages: prevData.stages.map((stage) =>
          stage.id === legId ? {...stage, leg: {...stage.leg, leg_items: [data]}} : stage
        )
      })
    })
  );

  const createLeg = useCreateLeg(params.shipment_id);

  const removeLegMutation = useMutation(
    ({legId}) => removeLegFromShipment(params.shipment_id, legId),
    createOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, params.shipment_id], {
      onError: (error) => console.error('Error removing drayage leg!', error.message),
      mergeData: (prevData, {legId}) => ({...prevData, stages: prevData.stages.filter((stage) => stage.id !== legId)})
    })
  );

  if (shipmentLegsQuery.isInitialLoading || legQueries.some((legQuery) => legQuery.isInitialLoading)) {
    return <Loader loading />;
  }

  const orderId = first(getNil(shipmentQuery, 'data.customer.order_ids', []));
  const initialContainersValues = {
    containers: legQueries.reduce((allContainers, {data: {id}, context: {containerFormValues}}) => {
      const transportationOrder = transportationOrders.find((transportationOrder) =>
        transportationOrder.leg_ids.includes(id)
      );
      const legContainers = get(containerFormValues, 'containers', []).map((container) => ({
        legId: id,
        transportationOrderId: transportationOrder?.id,
        ...container
      }));
      return [...allContainers, ...legContainers];
    }, [])
  };

  const handleSubmit = async (values, {setSubmitting}) => {
    const createAndUpdatePromises = values.containers.map(({legId, ...container}) => {
      const containerData = buildContainerPayload(orderId, container);
      if (container.id) {
        return updateLegItemMutation.mutateAsync({legId, legItemId: container.id, data: containerData});
      }
      return createLeg({
        ...pick(shipmentQuery.data, 'stops', 'references'),
        leg_items: [containerData]
      });
    });

    const valuesContainerIds = values.containers.map(({legId}) => legId);
    const legsToDelete = legIds.filter((legId) => valuesContainerIds.indexOf(legId) === -1);
    const deletePromises = legsToDelete.map((legId) => removeLegMutation.mutateAsync({legId}));

    await Promise.allSettled([...createAndUpdatePromises, ...deletePromises]);
    queryClient.invalidateQueries([SHIPMENTS_QUERY_KEY, params.shipment_id]);

    setSubmitting(false);
    router.push(`/shipments/${params.shipment_id}/containers`);
  };

  return (
    <div className="h-full bg-sw-background min-h-screen-minus-20">
      <PageHeader title="Add or Edit Containers" backArrow></PageHeader>
      <div className="m-4 pb-10">
        <Formik
          initialValues={{
            ...defaultFormValues,
            ...initialContainersValues
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form noValidate>
            <Card title="Container Details" className="mb-10">
              <FieldArray name="containers" component={Containers} />
            </Card>
            <FormFooter
              primaryActionName="Save Containers"
              secondaryActionName="Cancel"
              onCancel={() => router.push(`/shipments/${params.shipment_id}/containers`)}
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

EditContainers.propTypes = {
  params: PropTypes.shape({
    shipment_id: PropTypes.string
  }),
  router: PROPTYPE_ROUTER
};
