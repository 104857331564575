import {useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import PageHeader from 'App/common/pageHeader';
import IntegrationDetailsComponent from 'App/containers/integrations/details/components/detailsComponent';
import {integrationsConstants} from 'App/containers/integrations/constants';
import {getFinancialManagementSystems} from 'App/actions/_integrations';
import './styles.scss';

/**
 * Company Integration Details
 * @param {*} props
 *
 */
const CompanyIntegrationDetailsContainer = ({params: {integration = null, tab = null}, dispatch, router, setError}) => {
  useEffect(() => {
    const fetchFinancialManagementSystems = async () => {
      try {
        await dispatch(getFinancialManagementSystems());
      } catch (error) {
        console.error(error);
      }
    };
    fetchFinancialManagementSystems();
  }, [dispatch]);

  useEffect(() => {
    if (!integrationsConstants || !integration) {
      setError('Not a valid integration!');
    }
  }, [integration, setError]);

  return (
    <>
      <div className="integration-container">
        <PageHeader title="Integrations" backRoute="/company/integrations" />
        <div className="integration-wrapper">
          <IntegrationDetailsComponent integration={integration} tab={tab} router={router} />
        </div>
      </div>
    </>
  );
};

export default compose(connect())(CompanyIntegrationDetailsContainer);
