import {useCallback, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Contract} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ApplicableContractWithCharges} from './useGetApplicableContracts';
import {CARRIER_RELATIONSHIPS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getCarrierRelationshipsByIdPromise} from 'App/api/carriers/carrierRelationship';

export const isApplicableContract = (
  option: Contract | ApplicableContractWithCharges
): option is ApplicableContractWithCharges => {
  return 'contract' in option;
};

export const useSelectedContract = () => {
  const [selectedApplicableContract, setSelectedApplicableContract] = useState<ApplicableContractWithCharges>();

  const selectedContract = selectedApplicableContract?.contract;

  const {data: {data: contractCarrierRelationship} = {}, ...carrierRelationshipsQuery} = useQuery(
    [CARRIER_RELATIONSHIPS_QUERY_KEY, selectedContract?.carrier_relationship],
    () => getCarrierRelationshipsByIdPromise(selectedContract?.carrier_relationship || ''),
    {enabled: !!selectedContract}
  );

  return {
    selectedApplicableContract,
    handleContractChange: useCallback((value?: Contract | ApplicableContractWithCharges) => {
      if (value) {
        if (isApplicableContract(value)) {
          return setSelectedApplicableContract(value);
        }
        return setSelectedApplicableContract({contract: value});
      }
      return setSelectedApplicableContract(undefined);
    }, []),
    contractCarrierRelationship: selectedContract ? contractCarrierRelationship : undefined,
    chargeLineItems: selectedApplicableContract?.charges,
    isLoading: carrierRelationshipsQuery.isFetching
  };
};
