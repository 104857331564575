import {useQuery} from '@tanstack/react-query';
import {getMyCarriersPriceHistoricalCarrierGet} from 'App/api/pricing';
import {HistoricalLaneDataType} from 'App/containers/pricingIntel/create/mockData';
import {CARRIERS_PRICE_HISTORY_QUERY} from 'App/data-hooks/queryKeys';

export const useCarriersPriceHistory = ({
  equipmentType,
  stringifiedStops,
  pickupRadius = 0,
  dropOffRadius = 0,
  params
}: {
  equipmentType?: string;
  stringifiedStops: string;
  pickupRadius?: number;
  dropOffRadius?: number;
  params: {page?: number; limit?: number; filter?: string; sort?: string};
}) => {
  const isEnabled = stringifiedStops.length === 2 && !!equipmentType;

  const carriersPriceHistoryQuery = useQuery(
    [CARRIERS_PRICE_HISTORY_QUERY, params, stringifiedStops, equipmentType],
    async () => {
      const response = (await getMyCarriersPriceHistoricalCarrierGet(
        equipmentType ?? '',
        stringifiedStops,
        pickupRadius,
        dropOffRadius,
        params
      )) as {
        body: HistoricalLaneDataType;
      };
      return response.body;
    },
    {
      enabled: isEnabled
    }
  );

  return carriersPriceHistoryQuery;
};
