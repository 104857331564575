import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Card, DeprecatedButton, SvgIcon, Dropdown, Modal} from '@shipwell/shipwell-ui';
import SWTable from 'App/components/swTable';
import {getFedexAccounts, deleteFedexAccount, disconnectFedex} from 'App/api/integrations';
import WithStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import {useUserPageSize} from 'App/utils/hooks/useUserTableHooks';
import {FEDEX_ACCOUNTS} from 'App/containers/integrations/constants';
import './styles.scss';

const FedexDetails = (props) => {
  const {router, setSuccess, setError} = props;
  const {location} = router;
  const {query} = location;
  const [fedexAccounts, setFedexAccounts] = useState([]);
  const [accountToDelete, setAccountToDelete] = useState();
  const [isFetchingFedexAccounts, setIsFetchingFedexAccounts] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [meta, setMeta] = useState({page: 1, pages: 1, pageSize: 10});
  const userPageSize = useUserPageSize(FEDEX_ACCOUNTS);

  useEffect(() => {
    getFedexAccountsList(query);
  }, [getFedexAccountsList, query]);

  const getFedexAccountsList = useCallback(
    async (opts = {}) => {
      setIsFetchingFedexAccounts(true);
      opts = {
        ...meta,
        pageSize: userPageSize || 10,
        ...opts
      };
      try {
        const response = await getFedexAccounts(opts);
        if (response?.body?.results) {
          setFedexAccounts(response.body.results);
          setMeta({
            page: query.page || 1,
            pages: response.body.total_pages,
            pageSize: response.body.page_size
          });
        }
      } catch (error) {
        console.error(error);
        setError('Error!', error?.error_description);
      }
      setIsFetchingFedexAccounts(false);
    },
    [meta, query.page, setError, userPageSize]
  );

  const handleDeleteFedexAccount = async () => {
    setIsFetchingFedexAccounts(true);
    try {
      await deleteFedexAccount(accountToDelete.account_number);
      await getFedexAccountsList();
      setShowAccountDeleteModal(false);
      setSuccess('Success!', `FedEx account ${accountToDelete.account_number} was deleted successfully.`);
    } catch (error) {
      setError('Error!', error?.error_description);
    }
    setIsFetchingFedexAccounts(false);
  };

  const handleDisconnect = async () => {
    try {
      const response = await disconnectFedex();
      if (response?.ok) {
        router.push('/company/integrations');
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
    }
  };

  return (
    <div>
      <div className="flex justify-end pb-6">
        <DeprecatedButton variant="secondary" onClick={() => setShowDisconnectModal(true)} disabled={false}>
          <span>Disconnect</span>
        </DeprecatedButton>
      </div>
      <div className="flex flex-col divide-solid rounded border border-sw-border bg-sw-background-component px-6 py-7">
        <div className="mb-4 flex items-center justify-between">
          <span>
            For carrier information, please visit the <Link to={`/carriers?page=1&q=fedex`}>FedEx carrier page</Link>.
          </span>
        </div>
        <div>
          <Card
            title="Accounts"
            actions={
              <DeprecatedButton
                variant="tertiary"
                icon={<SvgIcon name="AddCircleOutlined" />}
                onClick={() => router.push(`/fedex`)}
              >
                Add more accounts
              </DeprecatedButton>
            }
          >
            <div className="fedex-accounts-list flex min-h-[400px]">
              <SWTable
                loading={isFetchingFedexAccounts}
                location={location}
                router={router}
                data={fedexAccounts}
                showPagination={fedexAccounts.length >= 5}
                tableType={FEDEX_ACCOUNTS}
                page={Number(meta.page) - 1}
                pages={Number(meta.pages)}
                pageSize={Number(meta.pageSize)}
                noDataText="Nothing to show here"
                columns={[
                  {
                    Header: 'Account Number',
                    id: 'account_number',
                    accessor: 'account_number',
                    sortable: false,
                    Cell: ({value}) => value
                  },
                  {
                    Header: 'Nickname',
                    id: 'account_nickname',
                    accessor: 'account_nickname',
                    sortable: false,
                    Cell: ({value}) => value
                  },
                  {
                    Header: 'Type',
                    id: 'type',
                    accessor: (d) => d?.is_connected,
                    sortable: false,
                    Cell: ({value}) => (value?.freight ? 'Freight' : value?.parcel ? 'Parcel' : 'Unknown')
                  },
                  {
                    Header: 'Status',
                    id: 'is_connected',
                    accessor: (d) => d?.is_connected,
                    sortable: false,
                    Cell: ({value}) => (value?.freight || value?.parcel ? 'Connected' : 'Not Connected')
                  },
                  {
                    Header: 'Actions',
                    id: 'actions',
                    accessor: 'actions',
                    sortable: false,
                    className: 'overflow-visible',

                    Cell: ({original}) => {
                      return (
                        <Dropdown
                          indicator={false}
                          drop="left"
                          variant="icon"
                          icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                        >
                          {({onClick}) => (
                            <>
                              <li
                                onClick={() => {
                                  onClick();
                                  router.push(`/fedex/${original?.account_number}`);
                                }}
                              >
                                Edit
                              </li>

                              <li
                                onClick={() => {
                                  onClick();
                                  router.push(`/fedex/clone/${original?.account_number}`);
                                }}
                              >
                                Clone
                              </li>

                              <li
                                onClick={() => {
                                  onClick();
                                  setAccountToDelete(original);
                                  setShowAccountDeleteModal(true);
                                }}
                              >
                                Delete this account
                              </li>
                            </>
                          )}
                        </Dropdown>
                      );
                    }
                  }
                ]}
              />
            </div>
          </Card>
        </div>
      </div>
      <Modal
        show={Boolean(showAccountDeleteModal)}
        title="Delete FedEx Account"
        variant="warning"
        onClose={() => setShowAccountDeleteModal(false)}
        primaryBtnName="Delete"
        PrimaryButtonProps={{disabled: isFetchingFedexAccounts}}
        onPrimaryAction={handleDeleteFedexAccount}
      >
        Are you sure you want to delete this FedEx account?
      </Modal>
      <Modal
        show={Boolean(showDisconnectModal)}
        title="Disconnect"
        variant="warning"
        onClose={() => setShowDisconnectModal(false)}
        primaryBtnName="Disconnect"
        onPrimaryAction={handleDisconnect}
      >
        You will lose all connected account information if you choose to disconnect from FedEx. Are you sure about that?
      </Modal>
    </div>
  );
};
FedexDetails.propTypes = {
  router: PropTypes.object,
  original: PropTypes.shape({
    account_number: PropTypes.string
  }),
  ...WithStatusToastsPropTypes
};

export default WithStatusToasts(FedexDetails);
