import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ExternalDocumentsListResponse} from '@shipwell/corrogo-sdk';
import {
  DocumentType,
  UploadDocumentVariablesType,
  UpdateDocumentVariablesType,
  DeleteDocumentVariablesType,
  mergeUploadDocument,
  mergeUpdateDocument,
  mergeDeleteDocument
} from 'App/api/corrogo/utils/mergeDataFunctions';
import {
  uploadOceanTrackingServiceDocument,
  uploadRailTrackingServiceDocument,
  deleteOceanTrackingDocument,
  deleteRailTrackingDocument,
  updateOceanTrackingServiceDocument,
  updateRailTrackingServiceDocument
} from 'App/api/corrogo/typed';
import {SERVICE_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

type ErrorHandlerType = (error: unknown) => void;
type VariablesType = DeleteDocumentVariablesType | UploadDocumentVariablesType | UpdateDocumentVariablesType;
type MergeDataType = (serviceDocuments?: DocumentType[], variables?: VariablesType) => DocumentType[];

export default function useUpdateServiceDocuments(serviceId: string) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate<DocumentType[], Error, VariablesType>();

  const createDocumentsOptimisticUpdateHandlers = ({
    errorHandler,
    mergeData
  }: {
    errorHandler: ErrorHandlerType;
    mergeData: MergeDataType;
  }) => createOptimisticUpdateHandlers([SERVICE_DOCUMENTS_QUERY_KEY, serviceId], {errorHandler, mergeData});

  const uploadDocumentOptimisticUpdateHandlers = createDocumentsOptimisticUpdateHandlers({
    errorHandler: (error: unknown) => {
      const {message} = error as Error;
      console.error('Error creating a service document', message);
    },
    mergeData: mergeUploadDocument
  });

  const deleteDocumentOptimisticUpdateHandlers = createDocumentsOptimisticUpdateHandlers({
    errorHandler: (error: unknown) => {
      const {message} = error as Error;
      console.error('Error deleting a service document', message);
    },
    mergeData: mergeDeleteDocument
  });

  const updateDocumentOptimisticUpdateHandlers = createDocumentsOptimisticUpdateHandlers({
    errorHandler: (error: unknown) => {
      const {message} = error as Error;
      console.error('Error updating a service document', message);
    },
    mergeData: mergeUpdateDocument
  });

  const uploadOceanTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof uploadOceanTrackingServiceDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; file: File; description: string; type: string}
  >(
    ({serviceId, file, description, type}) => uploadOceanTrackingServiceDocument(serviceId, file, description, type),
    uploadDocumentOptimisticUpdateHandlers
  );

  const uploadRailTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof uploadRailTrackingServiceDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; file: File; description: string; type: string}
  >(
    ({serviceId, file, description, type}) => uploadRailTrackingServiceDocument(serviceId, file, description, type),
    uploadDocumentOptimisticUpdateHandlers
  );

  const deleteOceanTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof deleteOceanTrackingDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; documentId: string}
  >(
    ({serviceId, documentId}) => deleteOceanTrackingDocument(serviceId, documentId),
    deleteDocumentOptimisticUpdateHandlers
  );

  const deleteRailTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof deleteRailTrackingDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; documentId: string}
  >(
    ({serviceId, documentId}) => deleteRailTrackingDocument(serviceId, documentId),
    deleteDocumentOptimisticUpdateHandlers
  );

  const updateOceanTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof updateOceanTrackingServiceDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; documentId: string; description: string; type: string}
  >(
    ({serviceId, documentId, description, type}) =>
      updateOceanTrackingServiceDocument(serviceId, documentId, description, type),
    updateDocumentOptimisticUpdateHandlers
  );

  const updateRailTrackingDocumentMutation = useMutation<
    Awaited<ReturnType<typeof updateRailTrackingServiceDocument>>,
    AxiosError<ExternalDocumentsListResponse>,
    {serviceId: string; documentId: string; description: string; type: string}
  >(
    ({serviceId, documentId, description, type}) =>
      updateRailTrackingServiceDocument(serviceId, documentId, description, type),
    updateDocumentOptimisticUpdateHandlers
  );

  return {
    uploadOceanTrackingDocumentMutation,
    uploadRailTrackingDocumentMutation,
    deleteOceanTrackingDocumentMutation,
    deleteRailTrackingDocumentMutation,
    updateRailTrackingDocumentMutation,
    updateOceanTrackingDocumentMutation,
    isLoading:
      uploadOceanTrackingDocumentMutation.isLoading ||
      uploadRailTrackingDocumentMutation.isLoading ||
      deleteOceanTrackingDocumentMutation.isLoading ||
      deleteRailTrackingDocumentMutation.isLoading ||
      updateRailTrackingDocumentMutation.isLoading ||
      updateOceanTrackingDocumentMutation.isLoading
  };
}
