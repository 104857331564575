import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {PaginatedPolicy, Policy} from '@shipwell/backend-core-sdk';
import {
  POLICIES_QUERY_KEY,
  POLICY_QUERY_KEY,
  WORKFLOW_DETAILS_QUERY_KEY,
  WORKFLOW_FORM_VALUES_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import {getPolicies, getPolicy} from 'App/api/policies/typed';
import {getWorkflow} from 'App/api/workflows/typed';
import {POLICY_TYPE_ROUTING_GUIDE} from 'App/containers/workflows/workflowConstants';
import {getWorkflowFormValuesTyped} from 'App/containers/workflows/utils/typed';
import {useModesQuery, useShipmentServiceLevels} from 'App/data-hooks';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';

// The overload's main function is to provide the proper policyQuery type based on...
// ...whether the user supplied a policyId or workflowId
export function useWorkflows({
  isEnabled,
  policyId,
  version,
  workflowId
}: {
  isEnabled: boolean;
  version?: number;
  policyId: string;
  workflowId?: never;
}): {
  policyQuery: UseQueryResult<Policy, unknown> | undefined;
  workflowFormValuesQuery: UseQueryResult<Awaited<ReturnType<typeof getWorkflowFormValuesTyped>>>;
  isLoading: boolean;
};
export function useWorkflows({
  isEnabled,
  policyId,
  version,
  workflowId
}: {
  isEnabled: boolean;
  version?: number;
  policyId?: never;
  workflowId: string;
}): {
  policyQuery: UseQueryResult<PaginatedPolicy, unknown> | undefined;
  workflowFormValuesQuery: UseQueryResult<Awaited<ReturnType<typeof getWorkflowFormValuesTyped>>>;
  isLoading: boolean;
};

export function useWorkflows({
  isEnabled,
  policyId,
  workflowId,
  version
}: {
  isEnabled: boolean;
  policyId?: string | never;
  workflowId?: string | never;
  version?: number;
}) {
  const modesQuery = useModesQuery({staleTime: STATIC_DATA_STALE_TIME});
  const equipmentQuery = useEquipmentTypesQuery();
  const serviceLevelsQuery = useShipmentServiceLevels();

  const hasModes = modesQuery.isSuccess;
  const hasEquipment = equipmentQuery.isSuccess;
  const hasServiceLevels = serviceLevelsQuery.isSuccess;

  // if user has policyId, get policy then find workflow via policy.data.workflow_id
  const policyQuery = useQuery(
    [POLICY_QUERY_KEY, policyId],
    async () => {
      const response = await getPolicy(policyId || '');
      return response.data;
    },
    {
      enabled: isEnabled && !!policyId
    }
  );

  // if user has workflowId, query policies and find where policy.name === workflow.name
  const policiesQuery = useQuery(
    [POLICIES_QUERY_KEY, workflowId],
    async () => {
      const response = await getPolicies({workflowId: [workflowId || '']});
      return response.data;
    },
    {
      enabled: isEnabled && !!workflowId
    }
  );

  const finalWorkflowId = policyQuery.data?.workflow_id || workflowId || '';

  const workflowQuery = useQuery(
    [WORKFLOW_DETAILS_QUERY_KEY, finalWorkflowId, version],
    async () => {
      const response = await getWorkflow({id: finalWorkflowId, version});
      return response.data;
    },
    {
      enabled: (policyQuery.isSuccess || policiesQuery.isSuccess) && !!finalWorkflowId
    }
  );

  const workflowFormValuesQuery = useQuery(
    [WORKFLOW_FORM_VALUES_QUERY_KEY, finalWorkflowId, version],
    () => {
      if (!(workflowQuery.isSuccess && hasModes && hasEquipment && hasServiceLevels)) return;
      const response = getWorkflowFormValuesTyped(
        workflowQuery.data,
        POLICY_TYPE_ROUTING_GUIDE,
        modesQuery.data,
        equipmentQuery.data,
        serviceLevelsQuery.data
      );
      return response;
    },
    {
      enabled: workflowQuery.isSuccess && hasModes && hasEquipment && hasServiceLevels
    }
  );

  const returnPolicyQuery = () => {
    if (policyId) {
      return policyQuery;
    }
    if (workflowId) {
      return policiesQuery;
    }
  };

  return {
    policyQuery: returnPolicyQuery(),
    workflowFormValuesQuery,
    isLoading:
      policyQuery.isInitialLoading ||
      modesQuery.isInitialLoading ||
      equipmentQuery.isInitialLoading ||
      workflowQuery.isInitialLoading ||
      workflowFormValuesQuery.isInitialLoading
  };
}
