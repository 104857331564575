import {useContext} from 'react';
import {SvgIcon, Button} from '@shipwell/shipwell-ui';
import {SupplierAppointmentContext} from 'App/containers/appointments/components/forms/SupplierAppointment';

const SupplierAppointmentTiles = () => {
  const {onCancel, handleNextStep} = useContext(SupplierAppointmentContext);
  return (
    <div className="flex h-72 flex-col justify-between">
      <div className="flex h-full gap-x-4">
        <div
          className="flex grow flex-col items-center justify-center border border-sw-border p-4 shadow-md"
          onClick={handleNextStep}
        >
          <div>
            <SvgIcon name="Truck" width="40" height="40" color="sw-icon" />
          </div>
          <div className="text-xl font-bold">Freight Appointments</div>
        </div>
        <div
          className="flex grow flex-col items-center justify-center border border-sw-border p-4 shadow-md"
          onClick={() => alert('Coming Soon!')}
        >
          <div>
            <SvgIcon name="CalendarEdit" width="40" height="40" color="sw-icon" />
          </div>
          <div className="text-xl font-bold">Miscellaneous Appointments</div>
        </div>
      </div>

      <div className="mt-4 flex flex-row justify-end">
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SupplierAppointmentTiles;
