import {useState} from 'react';
import {compose} from 'recompose';
import {useQuery} from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {useUserMe} from 'App/data-hooks';
import {getImpersonationTenants} from 'App/api/user/typed';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {IMPERSONATION_TENANTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import useStartImpersonation from 'App/components/TenantPicker/useStartImpersonation';

function useImpersonationTenants() {
  return useQuery(
    [IMPERSONATION_TENANTS_QUERY_KEY],
    async () => {
      const response = await getImpersonationTenants();
      return response.data;
    },
    {
      staleTime: Infinity
    }
  );
}

function TenantPicker() {
  const [impersonationUserId, setImpersonationUserId] = useState<string>();
  const userMeQuery = useUserMe();
  const impersonationTenantsQueryInfo = useImpersonationTenants();
  useStartImpersonation(impersonationUserId);
  if (userMeQuery.isInitialLoading || impersonationTenantsQueryInfo.isInitialLoading) {
    return (
      <div className="flex justify-center">
        <SvgIcon name="LoadingDots" />
      </div>
    );
  }

  if (
    impersonationTenantsQueryInfo.isError ||
    isNil(impersonationTenantsQueryInfo.data) ||
    !isArrayLike(impersonationTenantsQueryInfo.data?.tenants) ||
    isEmpty(impersonationTenantsQueryInfo.data?.tenants)
  ) {
    return <span>{userMeQuery.data?.company?.name}</span>;
  }

  return (
    <div className="flex flex-col">
      <span className="mb-2 tw-hidden xl:inline">Accounts</span>
      {impersonationTenantsQueryInfo.data.tenants.map((tenant) => {
        const isActiveTenant = tenant.tenant_id === userMeQuery.data?.company?.id;
        const disabled = isActiveTenant || !!impersonationUserId;
        return (
          <div key={tenant.tenant_id}>
            <span
              className={classnames('py-2 xl:py-1 ', 'w-full', 'inline-block', {
                'pointer-events-none border-l-4 border-sw-primary bg-sw-active-light pl-7 pr-4 font-bold xl:pl-3':
                  isActiveTenant,
                'px-8 xl:px-4': !isActiveTenant
              })}
            >
              <DeprecatedButton
                variant="tertiary"
                className={classnames({'cursor-default text-sw-text': disabled})}
                disabled={disabled}
                onClick={isActiveTenant ? undefined : () => setImpersonationUserId(tenant.impersonable_user_id)}
              >
                {tenant.tenant_name}
              </DeprecatedButton>
            </span>
          </div>
        );
      })}
    </div>
  );
}

const ConditionalTenantPicker = compose(
  withFlags('fmMultiAccountImpersonation'),
  withConditionalFallback(
    ({fmMultiAccountImpersonation}: {fmMultiAccountImpersonation: boolean}) => !fmMultiAccountImpersonation,
    null
  )
)(TenantPicker);

export default ConditionalTenantPicker;
