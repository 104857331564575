import {Field} from 'formik';
import {FormikTextarea, FormikTextInput, FormikCheckbox, FormikSelect, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';

/**
 * Supplier Shipment Request Fields
 */

const SupplierShipmentRequestFields = ({values, setFieldValue, getCustomers, customers}) => {
  const createAssignedCustomerTooltipContent = () => {
    return (
      <>
        <p>Change Customer</p>
        <p>
          {values.customer?.company?.name
            ? `The shipment that results from this request will be assigned to ${values.customer.company.name}. If you would like to change the assigned customer, choose it from the dropdown.`
            : 'Choose a customer to assign this shipment to.'}
        </p>
        <p>Once the shipment has been created, you will be unable to change the assigned customer.</p>
      </>
    );
  };
  return (
    <>
      <div className="field-grid">
        <Field
          component={FormikTextInput}
          name="supplier_email_address"
          label="Email"
          placeholder="Enter an email address"
          required
        />
        <Field component={FormikTextInput} name="po_number" label="PO Number" placeholder="Enter PO Number" required />
        <Field
          component={FormikTextarea}
          name="message_to_supplier"
          label="Message"
          placeholder="Enter Message"
          minRows={2}
        />
        <div className="grid-1-2">
          <Field
            component={FormikCheckbox}
            name="express_delivery"
            label="Expedited"
            disabled={!values.allow_autobook}
          />
          <Field
            component={FormikCheckbox}
            name="allow_autobook"
            label="Manual Book"
            checked={!values.allow_autobook}
            disabled={values.express_delivery}
            onChange={() => setFieldValue('allow_autobook', !values.allow_autobook)} // prevents autobook when manual booking is selected
          />
        </div>
      </div>
      {customers?.length > 1 ? (
        <div className="grid-1-12 assignCustomerContainer">
          <div className="grid-item-1-11">
            <Field
              async
              name="customer"
              label="Assigned Customer"
              getOptionLabel={(option) => option.company.name}
              defaultOptions={customers}
              loadOptions={(input) => getCustomers({companyContains: input})}
              component={FormikSelect}
            />
          </div>
          <div className="grid-item-12-12 assignCustomerTooltipContainer">
            <Tooltip tooltipClassname="assignCustomerTooltip" tooltipContent={createAssignedCustomerTooltipContent()}>
              <SvgIcon name="InfoOutlined" color="$sw-icon" />
            </Tooltip>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default SupplierShipmentRequestFields;
