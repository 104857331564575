import {LoadboardType, LoadPostRequest} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {createLoadPost} from 'App/api/loadboards';
import {LOADPOST_KEY} from 'App/data-hooks/queryKeys';

type CreateLoadPostPayload = {
  loadboardType: LoadboardType;
  loadPostRequest: LoadPostRequest;
};

const useCreateLoadPost = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof createLoadPost>>, unknown, CreateLoadPostPayload>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof createLoadPost>>, unknown, CreateLoadPostPayload>(
    ({loadboardType, loadPostRequest}) => createLoadPost(loadboardType, loadPostRequest),
    {
      onSettled: () => queryClient.invalidateQueries([LOADPOST_KEY]),
      ...mutationOptions
    }
  );
};

export default useCreateLoadPost;
