import {useQuery, useQueryClient} from '@tanstack/react-query';
import {JobStates, LoadSchema} from '@shipwell/shipment-assembly-sdk';
import {AxiosResponse} from 'axios';
import {useState} from 'react';
import {getLoadDetail} from 'App/api/loadOptimization';
import {LOAD_DETAILS_QUERY} from 'App/data-hooks/queryKeys';

export const useLoadDetail = (
  loadId: LoadSchema['id'],
  onPoll?: (data: AxiosResponse<LoadSchema, unknown>) => void,
  onError?: (error: unknown) => void
) => {
  const queryClient = useQueryClient();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const invalidateLoadDetailQueries = async () => await queryClient.invalidateQueries([LOAD_DETAILS_QUERY, loadId]);
  const loadDetailQuery = useQuery([LOAD_DETAILS_QUERY, loadId], () => getLoadDetail(loadId), {
    enabled: !!loadId,
    refetchInterval: (data) => {
      if (data?.data.job_status === JobStates.Running) {
        setIsPolling(true);
        return 2000;
      }
      setIsPolling(false);
      return 0;
    },
    refetchIntervalInBackground: true,
    retry: 0,
    onSuccess: (data) => {
      if (onPoll) {
        onPoll(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    }
  });
  const isLoadingLoadDetail = loadDetailQuery.isInitialLoading || isPolling;
  return {loadDetailQuery, invalidateLoadDetailQueries, isLoadingLoadDetail};
};
