import {CompanyInternationalPreferences} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateCompanyInternationalPreferences} from 'App/api/internationalPreferences';
import {useUserMe} from 'App/data-hooks';
import {COMPANY_UNIT_PREFERENCES} from 'App/data-hooks/queryKeys';
import {UnitPreferences} from 'App/reducers/types';

export const useUpdateInternationalPreferences = () => {
  const queryClient = useQueryClient();
  const userMeData = useUserMe();
  const companyId = userMeData?.data?.company?.id;
  const currentUnitPreferences: UnitPreferences | undefined = queryClient.getQueryData([
    COMPANY_UNIT_PREFERENCES,
    companyId
  ]);

  return useMutation(async ({values}: {values: CompanyInternationalPreferences}) => {
    if (!companyId) {
      return Promise.reject('Missing required company id parameter');
    }
    const response = await updateCompanyInternationalPreferences(companyId || '', values);
    if (response.data.currency && currentUnitPreferences) {
      queryClient.setQueryData([COMPANY_UNIT_PREFERENCES, companyId], {
        ...currentUnitPreferences,
        currency: response.data.currency
      });
    }
    return response;
  });
};
