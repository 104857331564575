import {Button, FormikTextarea} from '@shipwell/shipwell-ui';
import {Field, Form, Formik} from 'formik';
import {Dispatch, SetStateAction} from 'react';
import {array, object, string} from 'yup';
import {DynamicField} from '../testFields';

export const JSONEditor = ({
  setParsedData,
  initialValues
}: {
  setParsedData: Dispatch<SetStateAction<{fields?: Record<string, string | null>}>>;
  initialValues: unknown;
}) => {
  return (
    <div className="mb-12">
      <h3>
        Make sure your JSON is valid:{' '}
        <a
          href="https://codebeautify.org/json-fixer"
          target="_blank"
          rel="noreferrer noopener"
          className="text-blue-500"
        >
          {' '}
          https://codebeautify.org/json-fixer
        </a>
      </h3>

      <Formik
        initialValues={{
          jsonSchema: JSON.stringify(initialValues, null, 2)
        }}
        onSubmit={(values) => {
          try {
            const parsed = JSON.parse(values.jsonSchema) as {fields?: Record<string, string | null>};

            if (!parsed.fields) throw new Error('JSON must contain a "fields" key');

            const fields = Object.values(parsed.fields) as unknown as DynamicField[];
            for (const field of fields) {
              const fieldSchema = object().shape({
                label: string().required(),
                input: object().shape({
                  type: string().required(),
                  required: string().nullable(),
                  minLength: string().nullable(),
                  pattern: string().nullable(),
                  options: array().of(
                    object().shape({
                      value: string().required(),
                      label: string().required()
                    })
                  )
                }),
                tooltip: object().when([], {
                  is: () => !!field.tooltip,
                  then: object().shape({
                    title: string().required(),
                    description: string().required()
                  })
                }),
                data: object().shape({
                  type: string().required(),
                  visibility: string().required()
                })
              });

              fieldSchema.validateSync(field);
            }

            setParsedData(parsed);
          } catch (e) {
            alert(e);
          }
        }}
      >
        <Form className="space-y-4">
          <Field name="jsonSchema" component={FormikTextarea} className="font-mono" maxRows={20} />
          <Button type="submit">Change Form</Button>
        </Form>
      </Formik>
    </div>
  );
};
