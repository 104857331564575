export const PLAYGROUND_TABLE_KEY = 'PLAYGROUND_TABLE';
export const DRIVERS_LIST_TABLE_KEY = 'DRIVERS_LIST';
export const ADDRESS_BOOK_LIST_TABLE_KEY = 'ADDRESS_BOOK_LIST';
export const PRODUCTS_LIST_TABLE_KEY = 'PRODUCTS_LIST';
export const SETTLEMENTS_LIST_TABLE_KEY = 'SETTLEMENTS_LIST';
export const CAPACITY_SEARCH_TABLE_KEY = 'CAPACITY_SEARCH';
export const SHIPMENTS_LIST_TABLE_KEY = 'DASHBOARD';
export const SERVICE_PROVIDERS_LIST_KEY = 'SERVICE_PROVIDERS_LIST';
export const ORDERS_LIST_KEY = 'ORDERS_LIST';
export const ROUTING_GUIDE_LIST_KEY = 'ROUTING_GUIDE_LIST';
export const DELIVERED_SHIPMENTS_LIST_TABLE_KEY = 'DELIVERED_SHIPMENTS_LIST';
export const USERS_LIST_TABLE_KEY = 'USERS_LIST';
export const RATE_TABLE_LIST_KEY = 'RATE_TABLE_LIST';
