import {componentTypes} from 'App/components/TableFiltersWithSavedViews/constants';
import {
  TENDER_REQUESTED,
  TENDER_ACCEPTED,
  TENDER_REJECTED,
  TENDER_EXPIRED
} from 'App/containers/tendering/create/utils/constants';
import modeFilterOptions from 'App/utils/shipmentModeFilterOptions';

const filters = {
  requested_shipment_modes: {
    title: 'Mode',
    id: 'requestedShipmentModes',
    component: componentTypes.LIST_SELECT,
    options: modeFilterOptions({excludeParcel: true}),
    allowMultiSelect: true
  },
  bidding_status: {
    title: 'Status',
    id: 'biddingStatus',
    component: componentTypes.LIST_SELECT,
    options: [
      {value: 'open', label: 'Open'},
      {value: 'bidding', label: 'Bidding'},
      {value: 'won', label: 'Won'},
      {value: 'closed', label: 'Closed'},
      {value: 'tendered', label: TENDER_REQUESTED},
      {value: 'accepted', label: TENDER_ACCEPTED},
      {value: 'rejected', label: TENDER_REJECTED},
      {value: 'expired', label: TENDER_EXPIRED}
    ],
    allowMultiSelect: true
  },
  archived: {
    title: 'Archived',
    id: 'archived',
    component: componentTypes.LIST_SELECT,
    options: [{label: 'Include Archived Shipments', value: true}]
  },
  pickup_location: {
    title: 'Pickup Address',
    id: 'pickupStops',
    component: componentTypes.LOCATION_LOOKUP
  },
  delivery_location: {
    title: 'Delivery Address',
    id: 'deliveryStops',
    component: componentTypes.LOCATION_LOOKUP
  },
  vendor: {
    title: 'Carrier',
    id: 'vendor',
    component: componentTypes.TYPEAHEAD,
    placeholder: 'Search Carrier',
    valueField: 'shipwell_vendor.id',
    labelField: 'shipwell_vendor.name'
  },
  customer: {
    title: 'Shipper',
    id: 'customerContains',
    component: componentTypes.TEXT_INPUT,
    performSearch: (val) => ({customerContains: [val]}),
    placeholder: 'Search Shippers'
  },
  requested_equipment_types: {
    title: 'Equipment',
    id: 'requestedEquipmentTypes',
    component: componentTypes.TYPEAHEAD,
    searchLocalList: true,
    localOptionsLocation: 'shipments.equipmentTypes',
    placeholder: 'Search Equipment',
    valueField: 'machine_readable',
    labelField: 'name'
  },
  pickup_date: {
    title: 'Pickup Date',
    id: 'pickup',
    component: componentTypes.DATE_PICKER
  },
  delivery_date: {
    title: 'Delivery Date',
    id: 'dropoff',
    component: componentTypes.DATE_PICKER
  },
  expiresAt: {
    title: 'Tender Expires',
    id: 'expiresAt',
    component: componentTypes.DATE_PICKER
  }
};

export default filters;
