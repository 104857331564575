import {GlobalSettingsRequest} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {updateGlobalSettings} from 'App/api/loadboards';
import {LOADBOARDS_SETTINGS_KEY} from 'App/data-hooks/queryKeys';

const useUpdateGlobalSettings = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof updateGlobalSettings>>, unknown, GlobalSettingsRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof updateGlobalSettings>>, unknown, GlobalSettingsRequest>(
    async (globalSettings) => await updateGlobalSettings(globalSettings),
    {
      onSettled: () => queryClient.invalidateQueries([LOADBOARDS_SETTINGS_KEY]),
      ...mutationOptions
    }
  );
};

export default useUpdateGlobalSettings;
