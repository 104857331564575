import _ from 'lodash';
import {validateEmail, validatePhoneNumber} from 'App/utils/globals';

const validate = (values) => {
  const errors = {};
  if (!values.recipient_emails || (values.recipient_emails && values.recipient_emails.length === 0)) {
    errors.recipient_emails = 'Select at least one recipient';
  }
  if (!values.subject) {
    errors.subject = 'Subject is required';
  }
  if (!values.message) {
    errors.message = 'Message is required';
  }

  return errors;
};

export default validate;
