import {Appointment, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {updateAppointment, getAppointment} from 'App/api/appointments';
import {APPOINTMENT_QUERY_KEY, SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type UseRescheduleAppointmentMutationVariables = {
  id: string;
  appointment: {
    dockId: string;
    start: Date;
    end: Date;
  };
};

export type UseRescheduleAppointmentMutationOptions = Omit<
  UseMutationOptions<
    Appointment,
    AxiosError<ShipwellApiErrorResponse, unknown>,
    UseRescheduleAppointmentMutationVariables,
    Appointment
  >,
  'mutationFn'
>;

const useRescheduleAppointmentMutation = (options?: UseRescheduleAppointmentMutationOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async ({id, appointment}: UseRescheduleAppointmentMutationVariables) => {
      const appointmentQueryCacheKey = [APPOINTMENT_QUERY_KEY, id];

      const existingAppointment = await getAppointment(id);
      const rescheduledAppointment = await updateAppointment({
        id,
        appointment: {
          dock_id: appointment.dockId,
          start: {
            timestamp: appointment.start.toISOString(),
            timezone: existingAppointment?.start?.timezone || 'UTC'
          },
          end: {
            timestamp: appointment.end.toISOString(),
            timezone: existingAppointment?.end?.timezone || 'UTC'
          }
        }
      });

      queryClient.setQueryData(appointmentQueryCacheKey, rescheduledAppointment); // optimistic update

      return existingAppointment;
    },
    {
      ...options,
      onError: (error, variables, context) => {
        // rolleback changes to query data since a failure occured
        const appointmentQueryCacheKey = [APPOINTMENT_QUERY_KEY, variables.id];
        queryClient.setQueryData(appointmentQueryCacheKey, context);
      },
      onSettled: async (data) => {
        const appointmentQueryCacheKey = [APPOINTMENT_QUERY_KEY, data?.id];
        const shipmentStopAppointmentQueryCacheKey = [
          SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY,
          data?.scheduled_resource_id,
          data?.stop_id
        ];
        await queryClient.invalidateQueries(shipmentStopAppointmentQueryCacheKey);
        await queryClient.invalidateQueries(appointmentQueryCacheKey);
      }
    }
  );

  return {
    isReschedulingAppointment: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    rescheduleAppointmentErrors: mutation.error?.response?.data?.errors ?? [],
    rescheduleAppointment: mutation.mutate,
    rescheduleAppointmentAsync: mutation.mutateAsync
  };
};

export {useRescheduleAppointmentMutation};
