import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {deletePurchaseOrderLineItem} from 'App/api/purchaseOrders/typed';

export const useDeletePurchaseOrderLineItem = (
  purchaseOrderId?: string,
  mutationOptions: UseMutationOptions<Awaited<ReturnType<typeof deletePurchaseOrderLineItem>>, unknown, string> = {}
) => {
  return useMutation<Awaited<ReturnType<typeof deletePurchaseOrderLineItem>>, unknown, string>(
    async (purchaseOrderLineItemId: string) => {
      if (!purchaseOrderId) {
        return Promise.reject('Missing required purchase order id parameter');
      }
      const response = await deletePurchaseOrderLineItem(purchaseOrderId, purchaseOrderLineItemId);
      return response;
    },
    mutationOptions
  );
};
