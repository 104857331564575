import isNil from 'lodash/isNil';
import {ShipmentTemperatureUnitEnum, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import store from 'App/routes/store';
import {UnitPreferences} from 'App/reducers/types';

// 10/24 backend added temperature_unit
// shipments prior to this now have temperature_unit: null
// any shipment where lower/upper limits have a value should be considered to be in F
export const getShipmentTemperatureUnit = ({
  temperature_lower_limit: temperatureLowerLimit,
  temperature_upper_limit: temperatureUpperLimit,
  temperature_unit: temperatureUnit
}: Partial<
  Pick<Shipment, 'temperature_lower_limit' | 'temperature_upper_limit'> & {
    temperature_unit?: ShipmentTemperatureUnitEnum | null;
  }
>) => {
  // important to shortcut the return if temperatureUnit exists
  if (temperatureUnit) return temperatureUnit;

  // Important! Before 10/24 temperature_unit did not exist, all equipment temps were in F
  if (!isNil(temperatureLowerLimit) || !isNil(temperatureUpperLimit)) return ShipmentTemperatureUnitEnum.F;

  // By this return the shipment has no temperature limits so default to the user's unit preferences
  const {unitPreferences} = (store.getState()?.userCompany as {unitPreferences?: UnitPreferences} | undefined) || {};
  // failing over to F if for some reason the store doesn't have userPreferences
  return (unitPreferences?.temperatureUnit as ShipmentTemperatureUnitEnum) || ShipmentTemperatureUnitEnum.F;
};
