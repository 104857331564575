import {useQuery} from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import {COMPANY_USER} from 'App/data-hooks/queryKeys';
import {getCompanyUserPromise} from 'App/api/company';

const transformUserDataToDisplayValues = (userData) => {
  const name = [userData?.first_name, userData?.last_name].join(' ');
  return {name};
};

export default function useCompanyUser(companyId, userId) {
  const userQuery = useQuery([COMPANY_USER, companyId, userId], () => getCompanyUserPromise(companyId, userId), {
    enabled: !isNil(companyId) && !isNil(userId)
  });

  const userData = userQuery?.data?.body;

  const userDisplayValues = transformUserDataToDisplayValues(userData);

  return {...userQuery, context: {userDisplayValues}};
}
