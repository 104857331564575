import {ReactNode} from 'react';
import classnames from 'classnames';

function SequenceNumber({children, isActive = false}: {children: ReactNode; isActive?: boolean}) {
  return (
    <div
      className={classnames(
        'm-0',
        'inline-block',
        'h-5',
        'w-5',
        'place-self-center',
        'rounded-full',
        'p-0',
        'pb-1',
        'text-center',
        'text-sm',
        'border-2',
        'leading-4',
        {'border-sw-active bg-sw-active text-sw-text-reverse': isActive},
        {'border-sw-disabled': !isActive}
      )}
    >
      {children}
    </div>
  );
}

export default SequenceNumber;
