import {useQuery} from '@tanstack/react-query';
import {getCurrentLocationFromData} from './getCurrentLocationFromData';
import {getMovement} from 'App/api/locus';

export const LOCUS_GET_CURRENT_LOCATION = 'locusGetCurrentLocation';

export const useLocusAirShipmentsCurrentLocationByShipment = ({shipmentId}: {shipmentId: string}) => {
  const query = useQuery([LOCUS_GET_CURRENT_LOCATION, shipmentId], async () => {
    const response = await getMovement({
      resourceType: 'V2_SHIPMENT',
      resourceId: shipmentId
    });
    return response;
  });

  const {data, status: movementDetailsStatus} = query;

  return {
    location: getCurrentLocationFromData(data),
    locationStatus: movementDetailsStatus,
    query
  };
};
