import {useQueryClient} from '@tanstack/react-query';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {OrderStatus} from '@shipwell/corrogo-sdk';
import map from 'lodash/map';
import {useShipmentActions, useTransportationOrders} from 'App/data-hooks';
import {TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';

const GenerateRateConfirmation = ({shipmentId, setSuccess}: {shipmentId: string} & WithStatusToastProps) => {
  const {generateShipmentRateConfirmationMutation, isLoading} = useShipmentActions(shipmentId);
  const {getTransportationOrdersOfStatus} = useTransportationOrders(shipmentId);
  const transportationOrderIds = map(getTransportationOrdersOfStatus(OrderStatus.Accepted), 'id');
  const queryClient = useQueryClient();

  const handleGenerateRateConfirmation = async () => {
    const mutations = transportationOrderIds.map((transportationOrderId) =>
      generateShipmentRateConfirmationMutation.mutateAsync({transportationOrderId})
    );
    await Promise.allSettled(mutations);
    await queryClient.invalidateQueries([TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY, shipmentId]);
    setSuccess('Success!', 'Rate Confirmation(s) have been generated and can be previewed in the documents panel');
  };

  return (
    <DeprecatedButton
      disabled={transportationOrderIds.length === 0 || isLoading}
      variant="text"
      onClick={() => void handleGenerateRateConfirmation()}
    >
      Generate Rate Confirmation
    </DeprecatedButton>
  );
};

export default WithStatusToasts(GenerateRateConfirmation);
