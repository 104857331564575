import {ComponentType} from 'react';
import {compose} from 'recompose';
import {useUserMe} from 'App/data-hooks';
import withConditionalFallback from 'App/components/withConditionalFallback';

function useUserPermissions<Permission extends string = string>(permissions: Permission[]) {
  const userDetailsQuery = useUserMe();
  const userPermissions = userDetailsQuery.data?.user?.permissions || [];
  return permissions.reduce<PermissionsMap<(typeof permissions)[number]>>(
    (permissionsMap, permission) => ({...permissionsMap, [permission]: userPermissions.includes(permission)}),
    {} as PermissionsMap<(typeof permissions)[number]>
  );
}

export default useUserPermissions;

const useHasUserPermissions = (permissions: string[]) => {
  const {data: userData} = useUserMe();
  const userPermissionsSet = new Set(userData?.user?.permissions);
  const hasPermissions = permissions.every((permission) => userPermissionsSet.has(permission));

  return hasPermissions;
};

type PermissionsMap<Permission extends string = string> = {
  [key in Permission]: boolean;
};
type PermissionsProp<Permission extends string = string> = {permissionsMap: PermissionsMap<Permission>};
type UnknownProps = Record<string, unknown>;

function withUserPermissions<ComponentProps = UnknownProps, Permission extends string = string>(
  permissions: Permission[]
) {
  return function (Component: ComponentType<ComponentProps & PermissionsProp<(typeof permissions)[number]>>) {
    function ComponentWithPermissions(props: ComponentProps) {
      const userPermissionsMap = useUserPermissions(permissions);

      return <Component permissionsMap={userPermissionsMap} {...props} />;
    }
    return ComponentWithPermissions;
  };
}

function withUserPermissionsFallback<ComponentProps = UnknownProps, Permission extends string = string>(
  permissions: Permission[],
  Fallback?: ComponentType
) {
  return compose<ComponentProps & PermissionsProp<(typeof permissions)[number]>, ComponentProps>(
    withUserPermissions<ComponentProps>(permissions),
    withConditionalFallback(
      ({permissionsMap}: PermissionsProp<(typeof permissions)[number]>) =>
        Object.values(permissionsMap).some((permission) => !permission),
      Fallback
    )
  );
}

export {withUserPermissionsFallback, withUserPermissions, useHasUserPermissions};
