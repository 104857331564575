import {useState, useRef, JSX} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import {FormikTextInput, FormikSelect, SvgIcon, DeprecatedButton} from '@shipwell/shipwell-ui';
import {object, string} from 'yup';
import {
  LoadBoardShipment,
  ShipmentMode,
  EquipmentType,
  ChargeCategory,
  QuoteChargeLineItemUnitAmountCurrencyEnum,
  FreightInvoiceDocumentMetadataDocumentTypeEnum,
  CreateSpotNegotiationQuote
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {usePlaceBid} from './hooks/usePlaceBid';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {validateDollarValue, removeCommasAndDollarSign} from 'App/utils/globals';
import UploadDocuments, {
  UploadDocumentType
} from 'App/containers/shipments/details/components/SidebarDocuments/UploadDocuments';
import {useUserMe} from 'App/data-hooks';

const allowedDocuments = [
  FreightInvoiceDocumentMetadataDocumentTypeEnum.Insurance,
  FreightInvoiceDocumentMetadataDocumentTypeEnum.Other
];

interface FormValues {
  total: string;
  mode: ShipmentMode | undefined;
  equipment_type: EquipmentType | undefined;
}

const validationSchema = object().shape({
  total: string()
    .required()
    .test({
      message: 'Enter a valid dollar value',
      test: (value) => (typeof value === 'string' && +value > 0 && validateDollarValue(value, true)) || isNil(value)
    }),
  mode: object().required(),
  equipment_type: object().required()
});

export const PlaceBidForm = ({
  loadboardShipment,
  handleClose,
  handlePlaceBidSuccess,
  setError
}: {
  loadboardShipment: LoadBoardShipment;
  handleClose: () => void;
  handlePlaceBidSuccess: (spotNegotiationId: string) => Promise<void>;
  setError?: (title: string, message: string | JSX.Element) => void;
}) => {
  const [documentToUpload, setDocumentToUpload] = useState<UploadDocumentType | null>(null);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const {data: {user, company} = {}} = useUserMe();
  const carrier = company?.carrier?.id;

  const {
    equipment_types,
    modes,
    preferred_currency,
    spot_negotiation: spotNegotiationId,
    load_board_id: loadBoardShipmentId
  } = loadboardShipment;

  const initialValues = {
    total: '',
    mode: modes?.[0],
    equipment_type: equipment_types?.[0]
  };

  const currency = preferred_currency || QuoteChargeLineItemUnitAmountCurrencyEnum.Usd;

  const {createLoadboardBid, uploadDocumentError} = usePlaceBid({
    loadBoardShipmentId,
    spotNegotiationId,
    setError,
    user,
    documentToUpload,
    handlePlaceBidSuccess,
    handleClose,
    handleSettled: () => formRef.current?.setSubmitting?.(false)
  });

  const hasDocumentUploadError = !isNil(uploadDocumentError);

  const handleSubmit = (values: FormValues) => {
    const {total, mode, equipment_type} = values;
    const quote: CreateSpotNegotiationQuote = {
      charge_line_items: [
        {
          unit_amount: +removeCommasAndDollarSign(total),
          unit_amount_currency: currency as QuoteChargeLineItemUnitAmountCurrencyEnum,
          unit_name: 'Item Charge',
          unit_quantity: 1,
          category: ChargeCategory.Lh,
          charge_code: 'LHS'
        }
      ],
      ...(carrier && {carrier}),
      ...(mode?.id && {mode: mode.id}),
      ...(equipment_type?.id && {equipment_type: equipment_type.id}),
      service_level: 19, //default to standard service
      currency: currency as QuoteChargeLineItemUnitAmountCurrencyEnum
    };

    createLoadboardBid({quote});
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({isSubmitting}) => (
          <Form className="flex flex-col space-y-4">
            <Field
              disabled={hasDocumentUploadError}
              required
              name="total"
              label="Bid Amount"
              prepend={currency}
              component={FormikTextInput}
            />
            <Field
              disabled={hasDocumentUploadError}
              required
              clearable={false}
              options={modes}
              getOptionLabel={(option: ShipmentMode) => option.description}
              getOptionValue={(option: ShipmentMode) => option.id}
              label="Mode"
              name="mode"
              component={FormikSelect}
            />
            <Field
              disabled={hasDocumentUploadError}
              required
              clearable={false}
              options={equipment_types}
              getOptionLabel={(option: EquipmentType) => option.name}
              getOptionValue={(option: EquipmentType) => option.id}
              label="Equipment"
              name="equipment_type"
              component={FormikSelect}
            />
            <ModalFormFooter isSubmitting={isSubmitting} onCancel={handleClose} primaryActionName={'Submit'} />
          </Form>
        )}
      </Formik>
      <div className="flex">
        {documentToUpload ? (
          <div className="flex items-center gap-x-2">
            <SvgIcon name="Attachment" color="sw-icon" />
            <span className="text-sw-icon">{startCaseToLower(documentToUpload.type)}</span>
            <DeprecatedButton variant="text" onClick={() => setDocumentToUpload(null)}>
              <SvgIcon name="CloseCircleOutlined" color="sw-icon" />
            </DeprecatedButton>
          </div>
        ) : (
          <UploadDocuments
            label="Upload Document"
            onDocumentUpload={setDocumentToUpload}
            allowedDocuments={allowedDocuments}
          />
        )}
      </div>
    </div>
  );
};
