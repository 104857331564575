import {camelToSentence} from 'App/utils/string';

export const CurrencyOfRecordTooltipContent = (): string =>
  'Bulk tendering is only available for shipments with the same currency.';

export const ParcelShipmentsTooltipContent = (key: string): string =>
  `${camelToSentence(key)} is only available with parcel shipments.`;

export const NonParcelShipmentsTooltipContent = (key: string): string =>
  `${camelToSentence(key)} is not available with parcel shipments.`;

export const ParcelMismatchedShipmentsTooltipContent = (key: string) => {
  return (
    <>
      {ParcelShipmentsTooltipContent(key)} Ensure all shipments:
      <ul className="mt-2 list-disc px-4">
        <li>Use the same parcel provider</li>
        <li>Have the same origin address</li>
        <li>Have not been scheduled for pickup</li>
      </ul>
    </>
  );
};
