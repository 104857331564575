import {IntegrationCard} from '../../integrationCard/integrationCard';
import {useTiveService} from './useTiveService/useMacropointService';

export interface TiveCardProps {
  onConnect: () => void;
}

export function TiveCard(props: TiveCardProps) {
  const service = useTiveService();

  return (
    <IntegrationCard
      isConnected={service.isConnected}
      isLoading={service.isLoading}
      image={<img src="/images/tive_logo.svg" alt="Tive Logo" />}
      name="Tive"
      onConnectClick={props.onConnect}
      onCardClick={props.onConnect}
    />
  );
}
