import {FormGroup, ControlLabel, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Phone from 'react-phone-number-input';
const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  }
};

const renderPhoneNumberField = ({
  input,
  value,
  tabindex,
  label,
  icon,
  tooltip,
  placeholder,
  type,
  req,
  disabled,
  meta: {touched, error}
}) => (
  <FormGroup controlId={input.name} validationState={touched && error ? 'error' : null}>
    {label && (
      <ControlLabel>
        {label}
        {req && <span className="requiredField">*</span>}
      </ControlLabel>
    )}

    {tooltip && (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            <div style={style.tooltip}>{tooltip}</div>
          </Tooltip>
        }
      >
        <a tabIndex="-1">
          <i className="icon icon-Info" aria-hidden="true" />
        </a>
      </OverlayTrigger>
    )}
    <InputGroup className="input-group-inline" style={{width: '100%'}}>
      {icon && (
        <InputGroup.Addon>
          <i className={icon} />
        </InputGroup.Addon>
      )}
      <Phone
        country={'US'}
        value={input.value}
        onChange={(phone) => input.onChange(phone)}
        onBlur={(e) => {
          input.onBlur(e);
        }}
        placeholder={placeholder}
        type="text"
        disabled={disabled ? true : false}
        international
        countryOptionsOrder={['US', 'CA', 'MX', '|', '...']}
        defaultCountry="US"
      />
    </InputGroup>
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderPhoneNumberField;
