import {useEffect, useMemo} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikSelect, FormikTextInput} from '@shipwell/shipwell-ui';
import {states, provinces} from 'App/utils/globals';
import {mexicoStates} from 'App/components/addressVerification/utils';
import {AllowedCountries, countryOptions} from 'App/formComponents/forms/fedexRegistration/utils/constants';
import {UPSRegistrationFormValues} from 'App/formComponents/forms/upsRegistration';

export const UPSBillingAddress = () => {
  const {values, setFieldValue, initialValues} = useFormikContext<UPSRegistrationFormValues>();

  const {country_code: initialCountryCode, state_or_province: initialStateOrProvince} = initialValues.address;
  const {country_code: selectedCountryCode, state_or_province: selectedStateOrProvince} = values.address;

  const stateProvinceOptions = useMemo(() => {
    let options: {
      name: string;
      id: string;
    }[];

    switch (selectedCountryCode) {
      case AllowedCountries.CA:
        options = provinces;
        break;
      case AllowedCountries.MX:
        options = mexicoStates;
        break;
      default:
        options = states;
    }

    return options.map(({name, id}) => ({
      label: name,
      value: id
    }));
  }, [selectedCountryCode]);

  const stateProvinceLabel = selectedCountryCode === AllowedCountries.CA ? 'Province' : 'State';

  useEffect(() => {
    if (initialCountryCode !== selectedCountryCode || initialStateOrProvince !== selectedStateOrProvince)
      setFieldValue('address.state_or_province', '');
    // we only want to change the state when the selected country changes
    // but need to check the initial state so that our initial values do not get cleared
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, selectedCountryCode]);

  return (
    <div className="field-grid">
      <div className="grid-item-2">
        <Field required name="address.line_1" label="Street Address" component={FormikTextInput} />
      </div>
      <Field required name="address.city" label="City" component={FormikTextInput} />
      <Field
        simpleValue
        required
        clearable={false}
        name="address.state_or_province"
        label={stateProvinceLabel}
        options={stateProvinceOptions}
        component={FormikSelect}
      />
      <Field required name="address.postal_code" label="Postal Code" component={FormikTextInput} />
      <Field
        simpleValue
        required
        clearable={false}
        name="address.country_code"
        label="Country"
        options={countryOptions}
        component={FormikSelect}
      />
    </div>
  );
};
