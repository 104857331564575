import {object, string, bool, boolean, mixed} from 'yup';
import {validatePhoneNumber} from 'App/components/forms/utils';

/** FedEx Freight Registration validation rules */
const freightAccountSchema = object().shape({
  freight_account_number: string().required('Freight account number is required'),
  line_1: string().required('Street address is required.'),
  city: string().required('City is required.'),
  state_or_province: string().required('State is required.'),
  postal_code: string().required('Postal code is required.'),
  country_code: string().required('Country is required.')
});

/** FedEx Registration validation rules */
export const validationSchema = object().shape({
  account_number: string()
    .test(
      'account_number',
      'Account ID should be 8 or 9 digits.',
      (val?: string) => !!val && (val.length === 8 || val.length === 9)
    )
    .required('Account ID is required.'),
  billing_address: object().shape({
    line_1: string().required('Street address is required.'),
    city: string().required('City is required.'),
    state_or_province: string().required('State is required.'),
    postal_code: string().required('Postal code is required.'),
    country_code: string().required('Country is required.')
  }),
  contact: object().shape({
    first_name: string().required('First name is required.'),
    last_name: string().required('Last name is required.'),
    company_name: string().required('Company is required.'),
    email_address: string().email('Enter a valid email.').required('Email is required.'),
    phone_number: string()
      .test('valid-phone', 'Enter a valid phone.', (value) => {
        return validatePhoneNumber(value);
      })
      .required('Phone is required')
  }),
  smart_post_hub_id: string().nullable(),
  has_freight_account: boolean(),
  freight_account: mixed()
    .nullable()
    .when('has_freight_account', {
      is: true,
      then: () => freightAccountSchema,
      otherwise: () => object({}).nullable().optional().default(undefined)
    }),
  accept_terms: bool().oneOf([true], 'Must accept the FedEx End-User License Agreement.')
});
