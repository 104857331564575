import {Form, Formik, FormikHelpers} from 'formik';
import {useCallback, useMemo, JSX} from 'react';
import invariant from 'tiny-invariant';
import {Card, Loader} from '@shipwell/shipwell-ui';
import {FacilityDocument, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {WithRouterProps} from 'react-router';
import {compose} from 'recompose';

import {AxiosError} from 'axios';
import {facilityInfoValidationSchema} from '../schema';
import {useMutableFacility, useGetFacilityDocuments, useFacilityAddressBookEntry} from 'App/data-hooks';
import {formatFacilityAddress, formatV2AddressToFacilityAddress} from 'App/data-hooks/facilities/facilityUtils';
import {facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';
import FacilityInfoFields from 'App/formComponents/formSections/facilityInfoFields';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Error404Page from 'App/common/Error404Page';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import FormFooter from 'App/formComponents/formSections/formFooter';
import FacilityDocumentsList from 'App/containers/facilities/details/FacilityDocumentsList';
import {Facility, FacilityInfo} from 'App/data-hooks/facilities/types';

const nullableProps = ['external_reference', 'notes', 'carrier_instructions', 'disclaimers'] as const;

const FacilitiesDetails = ({
  params: {id: facilityId},
  setError,
  setSuccess
}: WithRouterProps<{id: string}> & WithStatusToastProps): JSX.Element => {
  const onMutationError = useCallback(
    ({response}: AxiosError<ShipwellApiErrorResponse>) => {
      if (!response?.data?.errors?.length) {
        console.error(
          'Unable to format facility info update error status=',
          response?.status,
          'headers =',
          response?.headers,
          'body=',
          response?.data
        );
        setError('Facility info update failed', 'Unrecognized internal error.');
        return;
      }

      setError(
        'Facility info update failed',
        <ul>
          {response?.data?.errors?.map((err) => (
            <li key={err.title}>{err.detail}</li>
          ))}
        </ul>
      );
    },
    [setError]
  );
  const onSuccess = useCallback(() => {
    setSuccess('Update successful!', `Successfully updated facility.`);
  }, [setSuccess]);

  const {
    facility,
    isLoading: isMutableFacilityLoading,
    updateFacilityAsync,
    error
  } = useMutableFacility(facilityId, {
    onSuccess,
    onError: onMutationError
  });
  const {
    addressBookEntry: currentAddressBookEntry,
    isAddressBookEntryLoading,
    addressBookEntryError
  } = useFacilityAddressBookEntry(facility?.id);

  const {data: fetchedDocuments, isLoading: isDocumentsLoading} = useGetFacilityDocuments(facilityId);

  const facilityInfoFormInitialValues = useMemo(() => {
    const facilityInfo: FacilityInfo = {
      ...facilityFormDataDefaultValues.facilityInfo,
      documents: [...(fetchedDocuments || [])],
      is_manual_timezone: false
    };
    if (facility) {
      Object.assign(facilityInfo, facility);
    }
    // we don't want to use the facility address if the user has selected an address book entry
    if (currentAddressBookEntry) {
      // because there is overlapping between address and address book entry
      // we should safely map these fields here rather than using the spread operator
      facilityInfo.address = {
        id: currentAddressBookEntry.id,
        location_name: currentAddressBookEntry.location_name,
        external_reference: currentAddressBookEntry.external_reference,
        formatted_address: currentAddressBookEntry.address.formatted_address,
        address_1: currentAddressBookEntry.address.address_1,
        address_2: currentAddressBookEntry.address.address_2,
        city: currentAddressBookEntry.address.city,
        state_province: currentAddressBookEntry.address.state_province,
        postal_code: currentAddressBookEntry.address.postal_code,
        country: currentAddressBookEntry.address.country,
        timezone: currentAddressBookEntry.address.timezone,
        address: currentAddressBookEntry.address
      };
    } else if (facility?.address) {
      // fallback to use facility address here
      facilityInfo.address = {
        location_name: facility.name,
        external_reference: facility.external_reference,
        address_1: facility.address.line_1,
        address_2: facility.address.line_2,
        city: facility.address.locality,
        state_province: facility.address.region,
        postal_code: facility.address.postal_code,
        country: facility.address.country || 'US',
        latitude: facility.address.geolocation?.latitude,
        longitude: facility.address.geolocation?.longitude,
        timezone: facility.address.timezone,
        formatted_address: facility.address.formatted_address,
        address: {
          address_1: facility.address.line_1,
          address_2: facility.address.line_2,
          city: facility.address.locality,
          state_province: facility.address.region,
          postal_code: facility.address.postal_code,
          latitude: facility.address.geolocation?.latitude,
          longitude: facility.address.geolocation?.longitude,
          country: facility.address.country || 'US'
        }
      };
    }
    for (const prop of nullableProps) {
      if (facilityInfo[prop] == null) {
        facilityInfo[prop] = '';
      }
    }
    return facilityInfo;
  }, [facility, fetchedDocuments, currentAddressBookEntry]);

  const onSubmit = useCallback(
    async (formValues: FacilityInfo, helpers: FormikHelpers<FacilityInfo>) => {
      invariant(
        facility,
        'Facility must have been loaded by the time the user is able to submit, so it is impossible that it be ' +
          'null-ish, but TS needs help asserting that.'
      );
      const updated: Partial<Facility> = {...facility};
      const update: Partial<Facility> = {
        ...formValues,
        address: formatV2AddressToFacilityAddress(formValues.address, facility.address.timezone)
      };
      if (
        update.address &&
        facility.address.geolocation &&
        formatFacilityAddress(update.address) === formatFacilityAddress(facility.address)
      ) {
        update.address.geolocation = {...facility.address.geolocation};
      }
      update.facility_services = update.facility_services ?? [];
      for (const key of nullableProps) {
        // These are all optional strings, so the user ought to be able to clear them if they like, but an empty string
        // will not validate server side, `undefined` won't serialize, and so we need a hack to assign `null` to those
        // properties, but only if they previously had a value.
        if (update[key] == null || update[key] === '') {
          if (facility[key]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (update as any)[key] = null;
          } else {
            delete update[key];
            delete updated[key];
          }
        }
      }
      Object.assign(updated, update);
      try {
        helpers.setSubmitting(true);
        await updateFacilityAsync(updated as Facility);
      } catch (error) {
        const apiError = error as AxiosError<ShipwellApiErrorResponse>;
        onMutationError(apiError);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [facility, onMutationError, updateFacilityAsync]
  );

  const {response} = error || addressBookEntryError || {response: null};
  const isLoading = Boolean(isMutableFacilityLoading || isAddressBookEntryLoading || isDocumentsLoading);

  return (
    <>
      {isLoading && !facility ? (
        <Loader show={isLoading}>Loading Facility...</Loader>
      ) : response?.status == 404 ? (
        <Error404Page />
      ) : (
        <div className="facility-details grid grid-flow-row">
          <h2 className="border-b border-sw-border pb-3">{facility?.name}</h2>
          <Formik
            validationSchema={facilityInfoValidationSchema}
            initialValues={facilityInfoFormInitialValues}
            initialTouched={{
              // these need to be set initially touched to show validation errors right away
              // otherwise the fields will not validate.
              name: true,
              external_reference: true,
              location_type: true,
              facility_services: true,
              notes: true,
              carrier_instructions: true,
              disclaimers: true
            }}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({dirty, resetForm, isValid, values}) => {
              return (
                <Form role="form">
                  <Card draggableProvided={null} title="Facility Information">
                    <FacilityInfoFields />
                  </Card>
                  <FacilityDocumentsList facility={facility} documents={values.documents as FacilityDocument[]} />
                  {dirty ? <div className="h-20">&nbsp;</div> : null}
                  {dirty ? (
                    <FormFooter
                      primaryActionName="Save"
                      secondaryActionName="Reset"
                      onCancel={resetForm}
                      dirty={dirty && isValid}
                    />
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

const ConditionalFacilitiesDetails = compose<
  WithRouterProps<{id: string}> & WithStatusToastProps,
  Partial<WithRouterProps<{id: string}> & WithStatusToastProps>
>(
  withFlags('fiDockScheduling'),
  withConditionalFallback(({fiDockScheduling}: {fiDockScheduling: boolean}) => !fiDockScheduling, Error404Page),
  WithStatusToasts
)(FacilitiesDetails);

export default ConditionalFacilitiesDetails;
