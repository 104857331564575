import {MouseEvent} from 'react';
import {IconButton, Modal, Pill, Tooltip} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import moment from 'moment';
import {
  RFPBidOptResponse,
  RFPBidOptResponseStateEnum,
  RfpCarrierBidRequestOptResponse,
  RfpCarrierBidRequestOptRequestBidRequestStateEnum,
  PaginatedRfpBidOpt
} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {InfoBlock} from './InfoBlock';
import ActionButton from 'App/containers/userCompany/rfps/ActionButton';
import {ProgressIndicator, Step} from 'App/components/ProgressIndicator';
import {isPastDate} from 'App/utils/dateTimeGlobalsTyped';
import {isShipperRfp} from 'App/containers/userCompany/rfps/utils/typeChecking';
import useToggle from 'App/utils/hooks/useToggle';
import {deleteOptimizedRfpBid, updateOptimizedRfpBid, UpdateRfpParams} from 'App/api/rfpOptimized/typed';
import {RFP_BIDS_QUERY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

const dayAndDateFormat = (date: string) => moment(date).format('ddd, MMM D YYYY');

const RfpTile = ({rfp}: {rfp: RFPBidOptResponse | RfpCarrierBidRequestOptResponse}) => {
  const rfpDetails = isShipperRfp(rfp) ? rfp : rfp.rfp_bid_opt_details;
  const [showDelete, toggleDelete] = useToggle();

  const isExpired = isPastDate(rfpDetails?.bid_expiry_date_time || '');
  const bidCompleteStatuses = [
    RFPBidOptResponseStateEnum.BidComplete,
    RFPBidOptResponseStateEnum.ContractsCreateRequested,
    RFPBidOptResponseStateEnum.ContractsRgCreateRequested
  ];
  const steps: Step[] = isShipperRfp(rfp)
    ? [
        {
          id: 'created',
          label: 'RFP Created',
          isActive: rfp.state === RFPBidOptResponseStateEnum.LanesNotImported,
          isComplete: true
        },
        {
          id: 'bids_requested',
          label: 'Bids Requested',
          isActive: rfp.state === RFPBidOptResponseStateEnum.BidRequested,
          isComplete: bidCompleteStatuses.some((state) => state === rfp.state)
        },
        {
          id: 'bidding_complete',
          label: 'Bidding Complete',
          isComplete: bidCompleteStatuses.some((state) => state === rfp.state)
        }
      ]
    : [
        {
          id: 'downloaded',
          label: 'Bid Request Downloaded',
          isComplete: rfp.bid_request_state !== RfpCarrierBidRequestOptRequestBidRequestStateEnum.RequestNotDownloaded
        },
        {
          id: 'uploaded',
          label: 'Bid Responses Uploaded',
          isComplete: rfp.bid_request_state === RfpCarrierBidRequestOptRequestBidRequestStateEnum.ResponseUploaded
        }
      ];

  const updateRfpBidOptimisticHandlers = useOptimisticUpdate<{pages: PaginatedRfpBidOpt[]}, unknown, UpdateRfpParams>();

  const queryClient = useQueryClient();
  const deleteRfpMutation = useMutation(deleteOptimizedRfpBid);
  const updateRfpMutation = useMutation(
    updateOptimizedRfpBid,
    updateRfpBidOptimisticHandlers([RFP_BIDS_QUERY], {
      mergeData(prevRfps, updatedRfp) {
        const pageIndex = prevRfps?.pages.findIndex((page) =>
          page.results?.some((rfp) => rfp.id === updatedRfp?.rfpBidOptId)
        );
        if (typeof pageIndex === 'undefined') {
          return prevRfps;
        }
        const rfpIndex = prevRfps?.pages[pageIndex].results?.findIndex((rfp) => rfp.id === updatedRfp?.rfpBidOptId);
        if (typeof rfpIndex === 'undefined') {
          return prevRfps;
        }
        const newRfps =
          prevRfps?.pages.map((page, index) => {
            if (pageIndex !== index) {
              return page;
            }
            return {
              ...page,
              results: page.results?.map((rfp, i) => {
                if (rfpIndex !== i) {
                  return rfp;
                }
                return {
                  ...rfp,
                  archived: updatedRfp?.archived
                };
              })
            };
          }) || [];
        return {
          ...prevRfps,
          pages: newRfps
        };
      }
    })
  );

  const canDeleteRfp = isShipperRfp(rfp) && rfp.state !== RFPBidOptResponseStateEnum.BidRequested;
  const isArchivedRfp = rfpDetails?.archived || false;

  const handleRfpDelete = () => {
    if (!canDeleteRfp) {
      return;
    }
    deleteRfpMutation.mutate(rfp.id || '', {
      onSuccess: () => {
        toggleDelete();
        void queryClient.invalidateQueries([RFP_BIDS_QUERY]);
      }
    });
  };

  const handleRfpUpdate = () => {
    updateRfpMutation.mutate({
      rfpBidOptId: rfpDetails?.id || '',
      archived: !isArchivedRfp,
      endDate: rfpDetails?.end_date || '',
      name: rfpDetails?.name || '',
      startDate: rfpDetails?.start_date || ''
    });
  };

  const lanesNotImported = isShipperRfp(rfp) ? rfp.state === RFPBidOptResponseStateEnum.LanesNotImported : null;
  const canArchive = isShipperRfp(rfp) ? rfp.state !== RFPBidOptResponseStateEnum.BidRequested : false;

  const to = isShipperRfp(rfp)
    ? `/company/lane-management/rfps/${rfp.id || ''}`
    : `/rfp-carrier-bid-requests/${rfp.id || ''}`;

  return (
    <div className={!isShipperRfp(rfp) && isExpired ? 'pointer-events-none opacity-60' : ''}>
      <Link to={to}>
        <div className="rounded border border-sw-border bg-sw-background-component text-sw-text drop-shadow-md hover:border-sw-focused">
          <div className="flex items-center justify-between gap-4 border-b border-sw-border p-4 py-3">
            <div className="flex items-baseline gap-4">
              {!isShipperRfp(rfp) ? (
                <span className="text-bold rounded bg-sw-background-header p-2 px-4 text-sw-text-reverse">
                  {rfp.shipper_name}
                </span>
              ) : null}
              <span className="text-base font-bold">{rfpDetails?.name}</span>
              {rfpDetails?.archived ? (
                <Pill variant="inactive">ARCHIVED</Pill>
              ) : rfpDetails?.updated_at ? (
                <span className="text-xs uppercase text-sw-disabled-text">
                  Last updated: {dayAndDateFormat(rfpDetails.updated_at)}
                </span>
              ) : null}
            </div>
            <div className="flex items-center gap-4">
              <div className="tw-hidden md:flex">{isShipperRfp(rfp) ? <ActionButton rfp={rfp} /> : null}</div>
              {isShipperRfp(rfp) ? (
                <Tooltip
                  placement="right"
                  tooltipContent={
                    <div className="flex flex-col gap-4 bg-sw-background-component p-4">
                      {canArchive ? (
                        <button
                          className="w-full text-left text-sw-text"
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            handleRfpUpdate();
                          }}
                        >
                          {isArchivedRfp ? 'Unarchive' : 'Archive'}
                        </button>
                      ) : null}
                      <button
                        className={`w-full text-left text-sw-text ${
                          canDeleteRfp ? 'text-sw-destroy' : 'text-sw-disabled'
                        }`}
                        disabled={!canDeleteRfp}
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          toggleDelete();
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  }
                  arrow={false}
                  trigger="click"
                  tooltipClassname="p-0"
                >
                  <IconButton
                    onClick={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                    iconName="Overflow"
                    aria-label="show RFP options"
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className="flex grid-cols-8 flex-col gap-4 p-4 md:grid">
            <div className="col-span-2 flex flex-col">
              <span className="text-xs uppercase text-sw-text-section-title">RFP Dates</span>
              <span>
                {dayAndDateFormat(rfpDetails?.start_date || '')} - {dayAndDateFormat(rfpDetails?.end_date || '')}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs uppercase text-sw-text-section-title">Lanes</span>
              <span>{lanesNotImported ? '--' : rfpDetails?.number_of_lanes}</span>
            </div>
            <InfoBlock rfp={rfp} />
            <div className="col-span-3 flex items-center gap-2 rounded bg-sw-background px-4 py-2">
              <ProgressIndicator steps={steps} />
            </div>
            <div className="flex flex-col md:hidden">{isShipperRfp(rfp) ? <ActionButton rfp={rfp} /> : null}</div>
          </div>
        </div>
      </Link>
      <Modal
        show={showDelete}
        variant="warning"
        onClose={toggleDelete}
        title="Delete RFP"
        secondaryBtnName="No, go Back"
        primaryBtnName="Yes, Delete RFP"
        onPrimaryAction={handleRfpDelete}
      >
        <div className="flex flex-col gap-2">
          <p>Are you sure you want to delete this RFP?</p>
          <p>
            Performing this action will retain all associated Routing Guides and Contracts that were created with this
            RFP.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default RfpTile;
