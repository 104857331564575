import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, SubmissionError, change} from 'redux-form';
import {Panel, Button, Image, ListGroup} from 'react-bootstrap';
import {placeholder} from 'App/utils/placeholders';
import * as documentActions from 'App/actions/documents';
import renderDropZone from 'App/formComponents/renderDropzone';
import validate from 'App/containers/Shipment/validateShipmentDocForm';
import NewDocumentsArray from 'App/components/DocumentsArray/NewDocumentsArray';
import renderTextArea from 'App/formComponents/renderTextArea';

@connect(
  (state) => ({
    newVendorDocForm: state.form.newVendorDocForm
  }),
  {...documentActions}
)
class NewVendorDocumentForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.setSelectedDoc = this.setSelectedDoc.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadDoc = this.handleUploadDoc.bind(this);

    this.state = {
      documentsList: [],
      showAddForm: false,
      selectedDoc: null,
      selectedDocIdx: -1,
      isExisting: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.submittedForm &&
      nextProps.newRelationshipId &&
      this.props.submittedForm !== nextProps.submittedForm
    ) {
      // save documents
      this.saveDocuments(nextProps.newRelationshipId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.selectedDoc && this.state.selectedDoc) ||
      (prevState.selectedDoc && this.state.selectedDoc && prevState.selectedDoc.id !== this.state.selectedDoc.id)
    ) {
      this.props.dispatch(change('newVendorDocForm', 'description', this.state.selectedDoc.description));
      this.props.dispatch(change('newVendorDocForm', 'files', [this.state.selectedDoc.fileComplete]));
    }
  }

  saveDocuments(newRelationshipId) {
    const documentsList = this.state.documentsList;
    documentsList.forEach((doc) => {
      const docForm = {
        description: doc.description,
        file: doc.fileComplete
      };

      this.props.postCarrierDocument(docForm, newRelationshipId);
    });
  }

  showAddForm() {
    this.setState({showAddForm: true});
  }

  handleUploadDoc(e) {
    this.showAddForm();
    this.props.selectDocAdd();
    this.setSelectedDoc(e);
  }

  handleCancel() {
    this.props.reset();
    this.setState({showAddForm: false, isExisting: false, selectedDocIdx: -1, selectedDoc: null});
  }

  formatName(name) {
    return name.replace(/ /g, '_');
  }

  handleSubmit() {
    const attrs = this.props.newVendorDocForm.values;
    // save all documents until the new customer is saved
    const documentsList = this.state.documentsList;
    if (this.state.selectedDocIdx !== -1) {
      documentsList[this.state.selectedDocIdx] = {
        description: attrs.description,
        file: attrs.files[0].preview,
        fileComplete: attrs.files[0],
        filename: this.formatName(attrs.files[0].name),
        id: _.uniqueId()
      };
    } else {
      documentsList.push({
        description: attrs.description,
        file: attrs.files[0].preview,
        fileComplete: attrs.files[0],
        filename: this.formatName(attrs.files[0].name),
        id: _.uniqueId()
      });
    }
    // reset the form
    this.setState({showAddForm: false, selectedDocIdx: -1, selectedDoc: null, isExisting: false});
    this.props.reset();
  }

  removeImage(doc) {
    const documentsList = this.state.documentsList;
    const selectedDoc = documentsList.indexOf(doc);
    documentsList.splice(selectedDoc, 1);
    this.setState({documentsList: documentsList});
  }

  setSelectedDoc(e) {
    const scrollTo = document.querySelector('.js-scrollto-form');

    if (scrollTo) {
      _.defer(() => {
        scrollTo.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  selectDocEdit(doc, idx) {
    this.setState({selectedDoc: doc, selectedDocIdx: idx});
  }

  render() {
    const {handleSubmit, pristine, error, isLoading, submitting} = this.props;
    const documentsList = this.state.documentsList;
    const textSaving = this.state.isExisting ? 'Saving' : 'Uploading';
    const textSave = this.state.isExisting ? 'Save' : 'Upload';

    const footerButtons = (
      <div className="document-edit__footer">
        <Button
          bsStyle="default"
          type="button"
          disabled={submitting}
          onClick={this.handleCancel}
          className="cancel-edit"
          name="cancel-edit"
        >
          Cancel
        </Button>

        <Button className="btn btn-primary" disabled={submitting || pristine} type="submit">
          {isLoading ? (
            <span>
              <i className="icon icon-Restart rotate" />
              &nbsp;&nbsp; {textSaving}
            </span>
          ) : (
            textSave
          )}
        </Button>
      </div>
    );

    const renderVendorDocImages = () => {
      if (!documentsList) {
        return;
      }
      return (
        <NewDocumentsArray
          documents={documentsList}
          tooltipContent="details"
          highlightBOL={false}
          handleDocumentClick={(id, e) => {
            const doc = documentsList.filter((doc) => doc.id === id)[0];
            this.removeImage(doc);
          }}
          selectDocEdit={(id, idx, e) => {
            const doc = documentsList.filter((doc) => doc.id === id)[0];
            this.setState({showAddForm: true, isExisting: true});
            this.setSelectedDoc(e);
            this.selectDocEdit(doc, idx);
          }}
        />
      );
    };

    const uploadButton = (
      <Button
        disabled={this.state.showAddForm}
        bsStyle="link"
        className="btn-tertiary"
        onClick={(e) => this.handleUploadDoc(e)}
      >
        <i className="flaticon-upload pad-right" />
        Upload Document
      </Button>
    );

    return (
      <div className="document-edit document-edit__new clearfix">
        <div className="doc-container">
          {documentsList && documentsList.length > 0 && <ListGroup>{renderVendorDocImages()}</ListGroup>}
        </div>
        <h3>
          <span className="document-edit__message">{uploadButton}</span>
        </h3>

        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          encType="multipart/form-data"
          className={`document-edit__form ${this.state.showAddForm ? 'show-flex' : 'hidden'}`}
        >
          <div className="document-edit__details">
            <Field
              name="description"
              type="text"
              minRows={3}
              component={renderTextArea}
              placeholder={placeholder.docDescription}
              req
              label="Description"
            />

            {error && <p className="error-text text-center">{error}</p>}
          </div>
          {this.state.showAddForm && (
            <Panel className="document-edit__dropzone">
              <Field name="files" component={renderDropZone} id="dropzone-doc" />
            </Panel>
          )}
          {footerButtons}
        </Form>
        <span className="js-scrollto-form" />
      </div>
    );
  }
}

NewVendorDocumentForm = reduxForm({
  form: 'newVendorDocForm',
  touchOnChange: true, // react-dropzone doesn't blur
  enableReinitialize: true,
  validate: validate
})(NewVendorDocumentForm);

NewVendorDocumentForm = connect((state) => ({
  initialValues: state.documents.selectedDocument
}))(NewVendorDocumentForm);

export default NewVendorDocumentForm;
