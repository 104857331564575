import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';
import {setCloneShipmentTrigger} from 'App/actions/shipments';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import withFlags from 'App/utils/withFlags';
import {checkShipmentModes} from 'App/utils/globalsTyped';

export const CLONE_AS_QUOTE = 'quote';
export const CLONE_AS_SHIPMENT = 'shipment';

const CloneAsAction = ({
  cloneAs,
  shipment,
  shipmentsShipmentIdGet,
  router,
  setCloneShipmentTrigger,
  setError,
  platNewCreateDrayage
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleCloneAsClick = async () => {
    setDisabled(true);
    try {
      const response = await shipmentsShipmentIdGet(shipment.id);
      const shipmentToClone = {
        ...response.details,
        name: '',
        state: 'quoting',
        line_items: get(response, 'details.line_items', []).map((li) => ({...li, purchase_order: null})),
        stops: get(response, 'details.stops', []).map((stop) => ({...stop, status: null}))
      };
      const {hasDrayage} = checkShipmentModes(shipmentToClone.mode);

      //save the shipment to be cloned in state
      //and a trigger so the quote form knows to clone immediately
      setCloneShipmentTrigger(true, shipmentToClone);
      if (cloneAs === CLONE_AS_SHIPMENT) {
        router.push(hasDrayage && platNewCreateDrayage ? '/drayage-shipment/create' : '/new-shipment/');
      } else {
        router.push(`/new-quote?from_shipment=${shipmentToClone.id}`);
      }
    } catch (e) {
      const message = `Error cloning shipment to new ${cloneAs}`;
      console.error(message, e);
      setError('Error!', message);
      setDisabled(false);
    }
  };

  const title = `Clone As A ${upperFirst(cloneAs)}`;

  return (
    <DeprecatedButton disabled={disabled} variant="text" onClick={handleCloneAsClick}>
      {title}
    </DeprecatedButton>
  );
};

CloneAsAction.propTypes = {
  cloneAs: PropTypes.oneOf([CLONE_AS_QUOTE, CLONE_AS_SHIPMENT]).isRequired,
  shipment: PropTypes.object,
  isQuotingLimitedUser: PropTypes.bool,
  router: PropTypes.object,
  shipmentsShipmentIdGet: PropTypes.func,
  setCloneShipmentTrigger: PropTypes.func,
  platNewCreateDrayage: PropTypes.bool,
  ...WithStatusToastsPropTypes
};

CloneAsAction.defaultProps = {
  onCloneShipment: () => {},
  isQuotingLimitedUser: false,
  router: {},
  shipmentsShipmentIdGet: () => {},
  setCloneShipmentTrigger: () => {},
  platNewCreateDrayage: false,
  ...WithStatusToastsDefaultProps
};

export default compose(
  withStatusToasts,
  withRouter,
  withFlags('platNewCreateDrayage'),
  connect(undefined, (dispatch) =>
    bindActionCreators(
      {
        shipmentsShipmentIdGet,
        setCloneShipmentTrigger
      },
      dispatch
    )
  )
)(CloneAsAction);
