import {DisplayValue, SvgIcon} from '@shipwell/shipwell-ui';
import {RfpCarrierBidRequestOptResponse} from '@shipwell/backend-core-sdk';
import {useQuery} from '@tanstack/react-query';
import moment from 'moment';
import {getRfpCarrierDocuments} from 'App/api/rfpOptimized/typed';
import {RFP_ATTACHMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const dayAndDateFormat = (date: string) => moment(date).format('ddd, MMM D YYYY');

export const BidRequestDetails = ({rfpBidRequest}: {rfpBidRequest: RfpCarrierBidRequestOptResponse}) => {
  const rfpAttachmentsQuery = useQuery(
    [RFP_ATTACHMENTS_QUERY_KEY, rfpBidRequest.id],
    async () => {
      const response = await getRfpCarrierDocuments(rfpBidRequest.id || '');
      return response.data;
    },
    {
      enabled: !!rfpBidRequest.id
    }
  );

  return (
    <div className="flex flex-col gap-4">
      <h2 className="m-0">You have a RFP Bid Request from {rfpBidRequest.shipper_name}</h2>
      <div className="grid gap-4 lg:grid-cols-4 lg:gap-12">
        <div className="flex flex-col gap-6">
          <DisplayValue label="RFP Title/Name">{rfpBidRequest.rfp_bid_opt_details?.name || '--'}</DisplayValue>
          <DisplayValue label="RFP Contract Dates">
            {dayAndDateFormat(rfpBidRequest.rfp_bid_opt_details?.start_date || '')} -{' '}
            {dayAndDateFormat(rfpBidRequest.rfp_bid_opt_details?.end_date || '')}
          </DisplayValue>
          <DisplayValue label="Number of Lanes">{rfpBidRequest.rfp_bid_opt_details?.number_of_lanes}</DisplayValue>
        </div>
        <div className="col-span-3 flex flex-col gap-4">
          <DisplayValue label="Notes/Instructions">
            {rfpBidRequest.rfp_bid_opt_details?.notes?.split('\n').map((line, i) => <p key={i}>{line}</p>) || '--'}
          </DisplayValue>
          <DisplayValue label="Attachments">
            <div className="mt-2 flex gap-4">
              {rfpAttachmentsQuery.data?.results?.length
                ? rfpAttachmentsQuery.data.results.map((attachment) => (
                    <a key={attachment.id} href={attachment.file} target="_blank" rel="noreferrer">
                      <div className="flex flex-col items-center text-sw-icon">
                        <SvgIcon name="DocumentBlank" />
                        <span className="text-xs">{attachment.filename}</span>
                      </div>
                    </a>
                  ))
                : '--'}
            </div>
          </DisplayValue>
        </div>
      </div>
    </div>
  );
};
