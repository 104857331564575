import {useMemo} from 'react';
import {Quote} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Rate} from '@shipwell/genesis-sdk';
import {CreateRateRequest} from '@shipwell/genesis-sdk';
import {combineQuotesWithParcelRates} from '../../helpers/combineQuotesWithParcelRates';
import {ParcelList} from './components/ParcelList';
import {CombinedQuote} from './types/combinedQuote';
import {transformGenesisRateIntoCombinedQuote} from './helpers/transformGenesisRateIntoCombinedQuote';
import {fedexServiceOptions, upsServiceOptions, uspsServiceOptions} from 'App/utils/parcelConstants';
import {useGetUpsPackageTypes} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsPackageTypes';
import {useGetUspsPackageTypes} from 'App/data-hooks/parcel/USPS/hooks/useGetUSPSPackageTypes';
import {useGetFedExPackageTypes} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedExPackageTypes';

export const AllParcelRates = ({
  packageType,
  numberOfPackages,
  quotes,
  handleSelectQuote,
  selectedTab,
  isFetchingRates,
  parcelRates,
  rateRequest
}: {
  packageType: string;
  numberOfPackages: number;
  quotes: Record<string, Quote[]>;
  parcelRates: Record<string, Rate[]>;
  handleSelectQuote: (quote: CombinedQuote) => Promise<void>;
  selectedTab: string;
  isFetchingRates: boolean;
  rateRequest?: CreateRateRequest | null;
}) => {
  const combined = combineQuotesWithParcelRates({parcelRates, quotes});
  const {service_options} = rateRequest?.capacity_providers?.[0] || {};
  const paymentOptions =
    service_options && 'payment_options' in service_options ? (service_options.payment_options as string) : undefined;

  const makeServiceLevels = (
    serviceOptions: {
      id: string;
      name: string;
    }[]
  ) => {
    return serviceOptions.reduce((options, option) => {
      const {id, name} = option;
      return {...options, [id]: name};
    }, {});
  };

  const fedexServiceLevels = makeServiceLevels(fedexServiceOptions) as {[key: string]: string};
  const upsServiceLevels = makeServiceLevels(upsServiceOptions) as {[key: string]: string};
  const uspsServiceLevels = makeServiceLevels(uspsServiceOptions) as {[key: string]: string};

  const {fedexPackageTypes} = useGetFedExPackageTypes();
  const {upsPackageTypes} = useGetUpsPackageTypes();
  const {uspsPackageTypes} = useGetUspsPackageTypes();

  const withProviderDirectCodeQuote = ({
    quote,
    providerCode,
    serviceLevels,
    packageTypes
  }: {
    quote: Quote;
    providerCode: string;
    serviceLevels: {[key: string]: string};
    packageTypes: {[key: string]: string};
  }) => {
    const providerDirectQuote = `${providerCode.toLowerCase()}_direct_quote` as
      | 'fedex_direct_quote'
      | 'ups_direct_quote'
      | 'usps_direct_quote';

    const dynamicQuoteOptions = quote[providerDirectQuote] as {
      service_code: string;
    };

    return {
      ...quote,
      serviceLevelLabel: serviceLevels?.[dynamicQuoteOptions?.service_code],
      imageUrl: `/images/${providerCode}_logo.svg`,
      packageTypes
    };
  };

  const {combinedQuotes, parcelWithQuotes} = useMemo(() => {
    const providerMappings: Record<
      string,
      {
        code: string;
        serviceLevels: {[key: string]: string};
        packageTypes: {[key: string]: string};
      }
    > = {
      fedex: {
        code: 'fedex',
        serviceLevels: fedexServiceLevels,
        packageTypes: fedexPackageTypes as {[key: string]: string}
      },
      ups: {code: 'ups', serviceLevels: upsServiceLevels, packageTypes: upsPackageTypes as {[key: string]: string}},
      usps: {code: 'usps', serviceLevels: uspsServiceLevels, packageTypes: uspsPackageTypes as {[key: string]: string}}
    };

    const parcelWithQuotes = Object.keys(combined).map((providerKey) => {
      return {
        providerKey,
        quotes: combined[providerKey].map((quote) => {
          // combine be-core rfq with genesis rates
          const isGenesisRate = (
            quote as Rate & {
              isGenesisRate: boolean;
            }
          ).isGenesisRate;

          if (isGenesisRate) {
            const genesisRate = quote as Rate;
            // payment_options only exists on the rate request, add it to the quote
            genesisRate.service_options = {
              ...genesisRate.service_options,
              ...(paymentOptions ? {payment_options: paymentOptions} : {})
            };

            return transformGenesisRateIntoCombinedQuote(genesisRate);
          }

          return withProviderDirectCodeQuote({
            quote,
            providerCode: providerMappings[providerKey].code,
            serviceLevels: providerMappings[providerKey].serviceLevels,
            packageTypes: providerMappings[providerKey].packageTypes
          });
        })
      };
    }) as {providerKey: string; quotes: CombinedQuote[]}[];

    const combinedQuotes = parcelWithQuotes.reduce((allQuotes: CombinedQuote[], parcel) => {
      return [...allQuotes, ...parcel.quotes];
    }, []);

    return {
      combinedQuotes,
      parcelWithQuotes
    };
  }, [
    combined,
    fedexPackageTypes,
    fedexServiceLevels,
    paymentOptions,
    upsPackageTypes,
    upsServiceLevels,
    uspsPackageTypes,
    uspsServiceLevels
  ]);

  const quotesByTab =
    selectedTab === 'all'
      ? combinedQuotes
      : parcelWithQuotes.find((quote) => quote.providerKey === selectedTab)?.quotes;

  if (!quotesByTab) {
    return <h3 className="mt-8 w-full text-center">No rates available for this shipment.</h3>;
  }

  return (
    <ParcelList
      quotes={quotesByTab}
      packageType={packageType}
      numberOfPackages={numberOfPackages}
      handleSelectQuote={handleSelectQuote}
      disableActions={isFetchingRates}
    />
  );
};
