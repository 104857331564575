import {useFlags} from 'launchdarkly-react-client-sdk';
import {ReactNode} from 'react';

export const HandlingUnitsFeatureFlag = ({children}: {children: ReactNode}) => {
  const {ciHandlingUnitBuilder} = useFlags();
  if (!ciHandlingUnitBuilder) {
    return null;
  }
  return <>{children}</>;
};
