import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, FormSection, FieldArray, change} from 'redux-form';
import {Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import renderField from 'App/formComponents/renderField';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import validateProfile from 'App/utils/validateProfile';
import renderToggle from 'App/formComponents/renderToggle';
import {toTitleCase, notificationDescriptions} from 'App/utils/globals';
import './_notification-preferences.scss';

const nonEmailStatuses = new Set([
  'delayed',
  'delivered_with_exception',
  'dispatched',
  'missed_pickup',
  'out_for_delivery',
  'reconsigned',
  'rejection_at_delivery'
]);

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    notificationsForm: state.form.notificationsForm
  }),
  {}
)
class NotificationForm extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notificationsForm && nextProps.notificationsForm.values && this.props.notificationsForm.values) {
      if (
        nextProps.notificationsForm.values.selectAll &&
        nextProps.notificationsForm.values.selectAll.email === true &&
        (!this.props.notificationsForm.values.selectAll ||
          (this.props.notificationsForm.values.selectAll &&
            this.props.notificationsForm.values.selectAll.email !== true))
      ) {
        //this changed from false to true
        const items = Object.keys(nextProps.notificationsForm.values);
        for (var i = 0; i < items.length; i++) {
          if (items[i] !== 'selectAll' && nextProps.notificationsForm.values[items[i]].email !== true) {
            const field = items[i];
            this.props.dispatch(change('notificationsForm', field + '.email', true));
          }
        }

        /*this.props.dispatch(
            change('notificationsForm', 'bill_to_override', defaultBillTo),
          );*/
      } else if (
        nextProps.notificationsForm.values.selectAll &&
        nextProps.notificationsForm.values.selectAll.inbox === true &&
        (!this.props.notificationsForm.values.selectAll ||
          (this.props.notificationsForm.values.selectAll &&
            this.props.notificationsForm.values.selectAll.inbox !== true))
      ) {
        //this changed from false to true
        const items = Object.keys(nextProps.notificationsForm.values);
        for (var i = 0; i < items.length; i++) {
          if (items[i] !== 'selectAll' && nextProps.notificationsForm.values[items[i]].inbox !== true) {
            const field = items[i];
            this.props.dispatch(change('notificationsForm', field + '.inbox', true));
          }
        }
      }
    }
  }

  render() {
    const {handleSubmit, submitting, notificationTypes, saved, notificationPreferences} = this.props;

    const emailAllSelected =
      this.props.notificationsForm.values &&
      this.props.notificationsForm.values.selectAll &&
      this.props.notificationsForm.values.selectAll.email === true;

    const inboxAllSelected =
      this.props.notificationsForm.values &&
      this.props.notificationsForm.values.selectAll &&
      this.props.notificationsForm.values.selectAll.inbox === true;
    return (
      <Form onSubmit={handleSubmit}>
        <Paper className="settings__card">
          <h2>Update your notification preferences</h2>

          <FormSection name="selectAll">
            <div className="notification-preferences__row-selectAll formstyle-dark">
              <h4>Turn on all notifications for</h4>
              <span className="notification-preferences__selector">
                <h5>Email</h5>
                <Field
                  extraClass="notification-preferences__toggleFields-items"
                  name="email"
                  component={renderToggle}
                />
              </span>
              <span className="notification-preferences__selector">
                <h5>Inbox</h5>
                <Field
                  extraClass="notification-preferences__toggleFields-items"
                  name="inbox"
                  component={renderToggle}
                />
              </span>
            </div>
          </FormSection>

          <div className="notification-preferences__row-header">
            <span className="notification-preferences__row-header-spacer" />
            <span>
              <h4>Email</h4>
            </span>
            <span>
              <h4>Inbox</h4>
            </span>
          </div>

          {notificationPreferences &&
            notificationPreferences.map((type, i) => {
              if (nonEmailStatuses.has(type.notification_type)) {
                return;
              }
              return (
                <div key={i} className="notification-preferences__row-boxed">
                  <span className="notification-preferences__row-boxed-title">
                    <h4>
                      <strong>{toTitleCase(type.notification_type)}</strong>
                    </h4>
                    <p>{notificationDescriptions[type.notification_type]}</p>
                  </span>
                  <span className="notification-preferences__toggleFields">
                    <Field
                      disabled={emailAllSelected}
                      extraClass="notification-preferences__toggleFields-items"
                      name={type.notification_type + '.email'}
                      component={renderToggle}
                    />
                  </span>
                  <span className="notification-preferences__toggleFields">
                    <Field
                      disabled={inboxAllSelected}
                      extraClass="notification-preferences__toggleFields-items"
                      name={type.notification_type + '.inbox'}
                      component={renderToggle}
                    />
                  </span>
                </div>
              );
            })}
        </Paper>
        <div className="settings__footer">
          {saved && (
            <span className="form-saved pad-right">
              <i className="icon icon-Check pad-right" />
              Saved!
            </span>
          )}
          <Button type="submit" disabled={submitting} className="btn btn-primary">
            Update
          </Button>
        </div>
      </Form>
    );
  }
}

NotificationForm = reduxForm({
  form: 'notificationsForm',
  validate: validateProfile,
  enableReinitialize: true
})(NotificationForm);

NotificationForm = connect((state) => ({
  initialValues: state.users.notificationForm
}))(NotificationForm);

export default NotificationForm;
