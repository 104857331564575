import {UIEvent} from 'react';
import {useInfiniteQuery, QueryKey, QueryFunctionContext, UseInfiniteQueryOptions} from '@tanstack/react-query';
import {REFETCH_ON_SCROLL_HEIGHT} from 'App/containers/userCompany/rfps/utils/constants';

export type PaginationParams = {
  page: number;
  pageSize: number;
};

type PaginatedResponse = {
  total_pages?: number;
};

export default function useInfiniteScroll<T extends PaginatedResponse>(
  queryKey: QueryKey,
  queryFn: <D extends PaginationParams>(params: D) => Promise<T>,
  options?: UseInfiniteQueryOptions<T>,
  pageSize = 20
) {
  const {fetchNextPage, isFetchingNextPage, isLoading, data} = useInfiniteQuery(
    queryKey,
    async ({pageParam = 1}: QueryFunctionContext<QueryKey, number>) => {
      const params = {page: pageParam, pageSize};
      const response = await queryFn(params);
      return response;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const currentPageIndex = pages.length;
        const totalPages = lastPage.total_pages;
        return currentPageIndex === totalPages ? undefined : currentPageIndex + 1;
      },
      ...options
    }
  );

  const pages = data?.pages || [];

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    void (async () => {
      const {offsetHeight, scrollTop, scrollHeight} = e.currentTarget;
      const scrollBottom = offsetHeight + scrollTop;
      if (scrollHeight - scrollBottom < REFETCH_ON_SCROLL_HEIGHT && !isFetchingNextPage) {
        await fetchNextPage();
      }
    })();
  };

  return {handleScroll, pages, isFetchingNextPage, isLoading};
}
