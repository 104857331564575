import moment from 'moment';
import {
  REQ_CARRIER_DATA,
  ALERT_ERROR,
  GET_CARRIER_TAGS,
  POST_CARRIER_TAGS,
  GET_CARRIER_RELATIONSHIPS,
  GET_CARRIER_RELATIONSHIP,
  SELECT_CARRIER,
  GET_CARRIER,
  GET_CARRIER_BY_ID,
  CLEAR_CARRIER,
  CLEAR_CARRIERS,
  CLEAR_SELECTED_RELATIONSHIP,
  SEARCH_FOR_CARRIERS,
  GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS,
  GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS,
  CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS,
  GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS
} from '../actions/types';

export const INITIAL_STATE = {
  carrierTags: [],
  alertError: null,
  carrierRelationships: null,
  carriersSearchResult: null,
  isLoading: false,
  relationship: null,
  selectedRelationship: null,
  selectedCarrier: null,
  triumphPay: null
};
let currentAccounts;
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQ_CARRIER_DATA:
      return {
        ...state,
        isLoading: true
      };

    case ALERT_ERROR:
      return {
        ...state,
        alertError: action.payload
      };
    case GET_CARRIER_TAGS:
      return {
        ...state,
        carrierTags: action.payload
      };
    case POST_CARRIER_TAGS:
      const currentTags = state.carrierTags;
      currentTags.push(action.payload);
      return {
        ...state,
        carrierTags: currentTags
      };
    case GET_CARRIER_RELATIONSHIPS:
      return {
        ...state,
        carrierRelationships: action.payload,
        isLoading: false
      };

    case GET_CARRIER_RELATIONSHIP:
      return {
        ...state,
        isLoading: false,
        selectedRelationship: action.payload,
        selectedCarrier: {
          vendor: {
            insurance_records: action.payload.insurance_records,
            shipwell_vendor: action.payload.shipwell_vendor,
            fmcsa_vendor: action.payload.fmcsa_vendor,
            fmcsa_census_data: action.payload.fmcsa_census_data,
            is_editable: action.payload.is_editable,
            external_payment_processor_sync_status: action.payload.external_payment_processor_sync_status,
            external_payment_processor_id: action.payload.external_payment_processor_id
          }
        }
      };

    case SELECT_CARRIER:
      return {
        ...state,
        selectedRelationship: action.payload
      };

    case GET_CARRIER:
      const obj = {vendor: action.payload};
      if (obj.vendor) {
        if (obj.vendor.shipwell_vendor.brokerage && obj.vendor.shipwell_vendor.brokerage.is_3pl === true) {
          obj.vendor.shipwell_vendor.is_3pl = true;
        }
        if (obj.vendor.shipwell_vendor.carrier) {
          obj.vendor.shipwell_vendor.is_carrier = true;
        }
        if (obj.vendor.shipwell_vendor && obj.vendor.shipwell_vendor.insurance_expires_at) {
          obj.vendor.shipwell_vendor.insurance_expires_at = moment(
            obj.vendor.shipwell_vendor.insurance_expires_at
          ).toDate();
        }
      }
      return {
        ...state,
        selectedCarrier: obj
      };

    case GET_CARRIER_BY_ID: {
      let selectedRelationship;
      if (action.payload.results && action.payload.results.length > 0) {
        selectedRelationship = action.payload.results[0];
      }

      if (selectedRelationship) {
        if (
          selectedRelationship.shipwell_vendor.brokerage &&
          selectedRelationship.shipwell_vendor.brokerage.is_3pl === true
        ) {
          selectedRelationship.shipwell_vendor.is_3pl = true;
        }
        if (selectedRelationship.shipwell_vendor.carrier) {
          selectedRelationship.shipwell_vendor.is_carrier = true;
        }
      }

      return {
        ...state,
        selectedRelationship: selectedRelationship
      };
    }

    case CLEAR_CARRIER:
      return {
        ...state,
        selectedCarrier: {
          identifying_codes: [{type: 'USDOT'}, {type: 'MC_NUMBER'}]
        }
      };

    case CLEAR_CARRIERS:
      return {
        ...state,
        carriersSearchResult: null
      };

    case CLEAR_SELECTED_RELATIONSHIP:
      return {
        ...state,
        selectedRelationship: null
      };

    case SEARCH_FOR_CARRIERS:
      return {
        ...state,
        carriersSearchResult: action.payload
      };

    case GET_TRIUMPH_PAY_VENDOR_DETAILS_SUCCESS:
      return {
        ...state,
        triumphPay: action.payload
      };

    case GET_TRIUMPH_PAY_VENDOR_INVOICES_SUCCESS:
      return {
        ...state,
        triumphPayInvoices: action.payload
      };

    case GET_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        triumphPay: action.payload
      };

    case EDIT_TRIUMPH_PAYMENT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        ...state.triumphPay,
        triumph_payment_accounts: [action.payload]
      };
    case CREATE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...state.triumphPay,
        triumph_payment_accounts: [action.payload]
      };
    case DELETE_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS:
      currentAccounts = state.triumphPay.triumph_payment_accounts;

      currentAccounts.forEach((acct, index, object) => {
        //factor accounts are not deleted immediately
        if (acct.payment_type !== 'FACTOR') {
          if (acct.id === action.payload) {
            object.splice(index, 1);
          }
        }
      });
      return {
        ...state,
        ...state.triumphPay,
        triumph_payment_accounts: currentAccounts
      };
    case SET_DEFAULT_TRIUMPH_PAYMENT_ACCOUNT_SUCCESS:
      currentAccounts = state.triumphPay.triumph_payment_accounts;
      currentAccounts.forEach((acct) => {
        //set default account
        if (acct.id === action.payload) {
          acct.is_default = true;
          //unset other account(s) set as default
        } else if (acct.id !== action.payload) {
          acct.is_default = false;
        }
      });
      return {
        ...state,
        ...state.triumphPay,
        triumph_payment_accounts: currentAccounts
      };
    default:
      return state;
  }
}
