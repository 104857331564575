import {useMemo} from 'react';
import {useQuery, UseQueryOptions, useQueries} from '@tanstack/react-query';
import {
  EquipmentTypeValues,
  Address,
  ContractStatusEnum,
  ApplicableContract,
  ContractChargeItem,
  Contract,
  ContractLane,
  ShipmentLineItem,
  TotalWeightOverride,
  ContractCriteriaByShipmentRequestRequestTypeEnum,
  ContractCriteriaByValueRequestModeEnum,
  ContractCriteriaByValueRequestCurrencyEnum,
  ContractCriteriaByValueRequestRequestTypeEnum,
  ContractCriteriaByValueRequestTotalWeightUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import orderBy from 'lodash/orderBy';
import {AxiosError} from 'axios';
import {APPLICABLE_CONTRACTS_KEY, CONTRACT_CHARGE_ITEMS} from '../queryKeys';
import {getChargeItemsTotals, getTotalPackagesAndWeight} from './utils';
import {getContractsApplicableForShipment, getContractChargeItems} from 'App/api/contracts/typed';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {useArrayMemo} from 'App/utils/hooks/useArrayMemo';

export type ApplicableContractWithCharges = {
  charges?: ContractChargeItem[];
  contract?: Contract;
  contract_lane?: ContractLane;
  chargeItemsTotals?: Omit<ReturnType<typeof getChargeItemsTotals>, 'total'>;
  total?: number;
};

type UseGetApplicableContractsByShipmentIdParams = {
  shipmentId?: string;
  // if getting by shipmentId do not pass shipment values
  currencyOfRecord?: never;
  equipmentType?: never;
  mode?: never;
  stopAddresses?: never;
  lineItems?: never;
  totalWeightOverride?: never;
  totalPackages?: never;
  totalWeight?: never;
};

type UseGetApplicableContractsByValuesParams = {
  currencyOfRecord?: ContractCriteriaByValueRequestCurrencyEnum;
  equipmentType?: EquipmentTypeValues;
  mode?: ContractCriteriaByValueRequestModeEnum;
  stopAddresses?: (Address | undefined)[];
  // optional items for getting the most accurate charge items
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
  totalPackages?: number | null;
  totalWeight?: number | null;
  // do not pass shipmentID if getting by shipment values
  shipmentId?: never;
};

type UseGetApplicableContractsParams = {
  status?: ContractStatusEnum;
  options?: Omit<
    UseQueryOptions<
      ApplicableContract[] | undefined,
      AxiosError,
      ApplicableContract[],
      Array<string | UseGetApplicableContractsParams>
    >,
    'queryFn' | 'queryKey' | 'initialData'
  >;
  enableFetchChargeItems?: boolean;
} & (UseGetApplicableContractsByShipmentIdParams | UseGetApplicableContractsByValuesParams);

export const useGetApplicableContracts = (params: UseGetApplicableContractsParams) => {
  const {
    shipmentId,
    currencyOfRecord = ContractCriteriaByValueRequestCurrencyEnum.Usd,
    equipmentType,
    mode,
    stopAddresses = [],
    status = ContractStatusEnum.Active,
    options,
    enableFetchChargeItems = true,
    lineItems,
    totalWeightOverride,
    totalPackages,
    totalWeight
  } = params;
  const {enabled = true, ...restOptions} = options || {};
  // used to set the charge items query key only. These values need the contract weight unit to be calculated correctly
  const {shipmentTotalPackages: queryKeyTotalPackages, shipmentTotalWeightSum: queryKeyTotalWeight} =
    getTotalPackagesAndWeight({
      totalWeight,
      totalPackages,
      lineItems,
      totalWeightOverride
    });
  const chargeItemsQueryKey = shipmentId
    ? {shipmentId}
    : {
        currencyOfRecord,
        equipmentType,
        mode,
        stopAddresses,
        queryKeyTotalPackages,
        queryKeyTotalWeight
      };
  const debouncedChargeItemsQueryKey = useDebounce(chargeItemsQueryKey, 300);

  const contractCriteriaRequest = shipmentId
    ? {shipment_id: shipmentId, request_type: ContractCriteriaByShipmentRequestRequestTypeEnum.ByShipment}
    : !!currencyOfRecord &&
      !!equipmentType &&
      !!mode &&
      stopAddresses?.length > 1 &&
      // minimum required entry for addresses is to contain a country
      stopAddresses?.every((stopAddress) => stopAddress && stopAddress?.country)
    ? {
        currency: currencyOfRecord,
        equipment_type: equipmentType,
        mode,
        stop_addresses: stopAddresses as Address[],
        request_type: ContractCriteriaByValueRequestRequestTypeEnum.ByValue
      }
    : undefined;

  const isApplicableContractsEnabled = enabled && !!contractCriteriaRequest;

  const {data: contractsData, ...applicableContractsQuery} = useQuery(
    [APPLICABLE_CONTRACTS_KEY, contractCriteriaRequest || ''],
    async () => {
      if (!contractCriteriaRequest) throw new Error('Unable to build applicableContractRequest');

      const {data} = await getContractsApplicableForShipment({
        contractCriteriaRequest
      });

      // need to filter to only the requested status, usually ACTIVE
      return data?.filter((applicableContract) => applicableContract.contract?.status === status);
    },
    {
      enabled: isApplicableContractsEnabled,
      onError: (error) => console.error(error),
      ...restOptions
    }
  );

  const contractsChargeItemsQuery = useQueries({
    queries: (contractsData || [])?.map((applicableContract) => ({
      queryKey: [CONTRACT_CHARGE_ITEMS, applicableContract.contract?.id, debouncedChargeItemsQueryKey],
      queryFn: () => {
        const {contract} = applicableContract;
        if (!contract?.id) throw new Error('contractId is required.');

        const {shipmentTotalPackages, shipmentTotalWeightSum} = getTotalPackagesAndWeight({
          contractWeightUnit: contract?.weight_unit as ContractCriteriaByValueRequestTotalWeightUnitEnum,
          totalWeight,
          totalPackages,
          lineItems,
          totalWeightOverride
        });
        return getContractChargeItems({
          contractId: contract?.id,
          contractCriteriaRequest: {
            ...(shipmentId
              ? {
                  shipment_id: shipmentId,
                  request_type: ContractCriteriaByShipmentRequestRequestTypeEnum.ByShipment
                }
              : {
                  ...contractCriteriaRequest,
                  total_packages: shipmentTotalPackages,
                  total_weight: shipmentTotalWeightSum,
                  total_weight_unit: contract?.weight_unit as ContractCriteriaByValueRequestTotalWeightUnitEnum,
                  request_type: ContractCriteriaByValueRequestRequestTypeEnum.ByValue
                })
          }
        });
      },
      enabled:
        !!contractsData && !!contractsData.length && enableFetchChargeItems && !applicableContractsQuery.isFetching,
      retry: false
    }))
  });

  const memoizedChargeItems = useArrayMemo(contractsChargeItemsQuery.map((query) => query.data));

  // we only care about charge queries that were successful
  const isSuccess = applicableContractsQuery.isSuccess && contractsChargeItemsQuery.every((query) => !query.isLoading);

  const mergedData = useMemo(
    () =>
      !applicableContractsQuery.isFetching && isSuccess && contractsData?.length
        ? orderBy(
            contractsData?.map((applicableContract, index) => {
              const {charge_items: charges} = memoizedChargeItems[index] || {};

              const {total, ...chargeItemsTotals} = getChargeItemsTotals(charges);

              return {
                ...applicableContract,
                charges,
                chargeItemsTotals,
                total
              };
            }),
            [
              // order any $0 totals last
              (applicableContract) => applicableContract.total || null,
              // for same dollar value or $0 values order by carrier name
              (applicableContract) => applicableContract.contract?.carrier_name
            ]
          )
        : !applicableContractsQuery.isFetching && isSuccess && !contractsData?.length
        ? []
        : undefined,
    [applicableContractsQuery.isFetching, contractsData, isSuccess, memoizedChargeItems]
  );

  const isFetchingCharges = contractsChargeItemsQuery.some((query) => query.isInitialLoading || query.isFetching);

  return {
    // backend does not allow filtering on status, frontend should return active contracts only be default
    applicableContracts: mergedData,
    applicableContractsQuery,
    isApplicableContractsEnabled,
    contractsChargeItemsQuery,
    isFetchingContracts: applicableContractsQuery.isFetching,
    isFetchingCharges,
    isFetchingApplicableContractsWithCharges:
      applicableContractsQuery.isFetching || applicableContractsQuery.isInitialLoading || isFetchingCharges
  };
};
