import {FreightSettlementsConfiguration} from '@shipwell/settlements-sdk';
import {
  AddToDeliveredDashboardConfiguration,
  AutoDisputeInvoiceConfiguration,
  AutoGenerateInvoiceConfiguration,
  isAddToDeliveredDashboardConfiguration,
  isAutoDisputeInvoiceConfiguration,
  isAutoGenerateInvoiceConfiguration
} from 'App/api/settlements/typeGuards';

export const groupConfigsByType = (configs: FreightSettlementsConfiguration[]) =>
  configs.reduce<{
    addToDeliveredDashboardConfigurations: AddToDeliveredDashboardConfiguration[];
    autoGenerateInvoiceConfigurations: AutoGenerateInvoiceConfiguration[];
    autoDisputeInvoiceConfigurations: AutoDisputeInvoiceConfiguration[];
  }>(
    (configGroups, config) => {
      if (isAddToDeliveredDashboardConfiguration(config)) {
        configGroups.addToDeliveredDashboardConfigurations.push(config);
      }
      if (isAutoGenerateInvoiceConfiguration(config)) {
        configGroups.autoGenerateInvoiceConfigurations.push(config);
      }
      if (isAutoDisputeInvoiceConfiguration(config)) {
        configGroups.autoDisputeInvoiceConfigurations.push(config);
      }
      return configGroups;
    },
    {
      addToDeliveredDashboardConfigurations: [],
      autoGenerateInvoiceConfigurations: [],
      autoDisputeInvoiceConfigurations: []
    }
  );
