import {FormikCheckbox, FormikSelect} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {preferredCurrencyOptionsShipwellUiSelect} from 'App/containers/Book/FormSections/Financials/components/utils';
import {FlexBox} from 'App/components/Box';

export const CurrenciesConfiguration = () => {
  return (
    <FlexBox gap={'l'} direction="col">
      <span>The currency of record will be used as the default currency throughout your account.</span>
      <FlexBox gap={'l'} direction="col">
        <Field
          label="Currency of Record"
          component={FormikSelect}
          options={preferredCurrencyOptionsShipwellUiSelect}
          name="currency"
          simpleValue
          required
          clearable={false}
        />
        <Field
          label="Allow users to change currency of record"
          component={FormikCheckbox}
          name="allow_currency_change"
        />
      </FlexBox>
    </FlexBox>
  );
};
