import {OrderItem, StartEndTimeWindow, WeightUnit} from '@shipwell/corrogo-sdk';
import moment from 'moment';
import {numberWithCommas} from 'App/utils/globals';
import {convertKilogramsToPounds} from 'App/utils/internationalConstants';

export const getTotalWeight = (items?: OrderItem[]) => {
  const totalWeight = items?.reduce((memo, item) => {
    const value =
      item.amount.unit === WeightUnit.Lb
        ? item.amount.value
        : Number(item.shipping_requirements?.gross_weight?.value) * Number(item.shipping_requirements?.quantity) || 0;
    const unit = item.shipping_requirements?.gross_weight?.unit || WeightUnit.Lb;
    return memo + Number(unit === WeightUnit.Lb ? value : convertKilogramsToPounds(value));
  }, 0);
  return totalWeight ? `${numberWithCommas(totalWeight)} LB` : '--';
};

export const getRange = (planWindow: StartEndTimeWindow | undefined, timezone: string | undefined) => {
  if (!planWindow) {
    return '--';
  }
  return [...new Set([planWindow.start, planWindow.end])]
    .filter(Boolean)
    .map((datetime) =>
      datetime ? moment.tz(datetime, timezone ?? moment.tz.guess()).format('ddd, MMM D [at] HH:mm z') : ''
    )
    .map((datetime, index) => (
      <>
        {index > 0 ? (
          <>
            {' '}
            to <br />
          </>
        ) : null}
        <span>{datetime}</span>
      </>
    ));
};

//this type ensures that the columns ids and the associated column arrays stay in sync.
//If a dashboard column's id is changed, it should be updated in this list.
export type DashboardColumnIds =
  | 'order_number'
  | 'references__purchase_order_number'
  | 'order_type'
  | 'name'
  | 'status'
  | 'items'
  | 'weight'
  | 'releases'
  | 'dropoff'
  | 'stops'
  | 'mode'
  | 'equipment_type'
  | 'weight'
  | 'releases_shipment_status'
  | 'ship_from__address'
  | 'pickup_range'
  | 'ship_to__address'
  | 'delivery_range'
  | 'customer'
  | 'handling_unit_ids'
  | 'supplier'
  | 'releases_present'
  | 'payment_term'
  | 'created_at';

export const dashboardLabelMap: {[K in DashboardColumnIds]?: string} = {
  order_number: 'Order #',
  references__purchase_order_number: 'PO #',
  name: 'Order Name',
  status: 'Order Status',
  releases: 'Shipment ID',
  dropoff: 'Dropoff',
  stops: 'Stops',
  mode: 'Mode',
  ship_from__address: 'Pickup',
  ship_to__address: 'Delivery',
  equipment_type: 'Equipment Type',
  releases_shipment_status: 'Shipment Status',
  created_at: 'Created'
};
