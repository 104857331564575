import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  FMCSACarrierPolicy,
  ShipwellError,
  CarrierRelationshipsApiCarrierComplianceIntegrationsFmcsaPolicyPutRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FMCSA_COMPLIANCE_POLICY} from '../queryKeys';
import {setFMCSACarrierCompliancePolicy} from 'App/api/carriers/carrierRelationship';

export type UseUpdateFMCSAPolicyMutationOptions = Omit<
  UseMutationOptions<
    FMCSACarrierPolicy,
    AxiosError<ShipwellError>,
    CarrierRelationshipsApiCarrierComplianceIntegrationsFmcsaPolicyPutRequest,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;

const useFMCSAPolicyMutation = (options?: UseUpdateFMCSAPolicyMutationOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (updatedPolicy: CarrierRelationshipsApiCarrierComplianceIntegrationsFmcsaPolicyPutRequest) =>
      setFMCSACarrierCompliancePolicy(updatedPolicy),
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        void queryClient.invalidateQueries([FMCSA_COMPLIANCE_POLICY]);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {
    isFMCSALoading: mutation.isLoading,
    isFMCSASuccess: mutation.isSuccess,
    isFMCSAError: mutation.isError,
    FMCSAError: mutation.error || null,
    FMCSAPolicy: mutation.data || null,
    updateFMCSAPolicy: mutation.mutate,
    updateFMCSAPolicyAsync: mutation.mutateAsync
  };
};

export default useFMCSAPolicyMutation;
