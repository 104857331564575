/* eslint-disable camelcase */
import moment from 'moment';
import {isNil} from 'lodash';
import {
  REQ_DATA_ADDRESS_BOOK,
  SELECT_ADDRESS,
  SELECT_ADDRESS_ADD,
  POST_ADDRESSBOOK,
  PUT_ADDRESSBOOK,
  GET_ADDRESSBOOK,
  GET_LOCATION_TYPES,
  DELETE_ADDRESSBOOK,
  ALERT_ERROR
} from 'App/actions/types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';

// starts the Loading... spinner
function requestData() {
  return {type: REQ_DATA_ADDRESS_BOOK};
}

export function selectAddressEdit(address) {
  return (dispatch) => {
    dispatch({type: SELECT_ADDRESS, payload: address});
  };
}

export function selectAddressAdd() {
  return (dispatch) => {
    dispatch({type: SELECT_ADDRESS_ADD});
  };
}
export const addressBookCreateSuccess = (response) => ({
  type: POST_ADDRESSBOOK,
  payload: response.body
});

export const addressBookUpdateSuccess = (response) => ({
  type: PUT_ADDRESSBOOK,
  payload: response.body
});
/**
 * Get a list of address book entries
 * @param {module:api/shipwellApi~addressBookGetCallback} callback
 * The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/InlineResponse200}
 */
export function addressBookGet(opts) {
  opts = opts || {};
  return (dispatch) => {
    dispatch(requestData());
    return getAddressBookPromise(opts)
      .then((response) => {
        dispatch({type: GET_ADDRESSBOOK, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Return list of address book entries
 */
export async function getAddressBookPromise(opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.addressBookGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Get a list of all available location types
 * @param {module:api/shipwellApi~locationTypesGetCallback} callback
 * The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/LocationType>}
 */
export function locationTypesGet() {
  return (dispatch) => {
    return getLocationTypesPromise()
      .then((response) => {
        dispatch({type: GET_LOCATION_TYPES, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Return list of location types
 */
async function getLocationTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.locationTypesGet(function (err, data, response) {
      if (err) {
        if (response && response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error getting permissions.');
        }
      } else {
        resolve(data);
      }
    });
  });
}

function mapAddressBook({
  accessorials,
  appointment_type,
  location,
  planned_time_window_start,
  planned_time_window_end
}) {
  let startTime = '08:00:00';
  let endTime = '18:00:00';
  const {city, country, postal_code: postalCode, company_name: companyName, state_province: stateProvince} = location;

  // format 24 hour (i.e. 18:00:00)
  if (planned_time_window_start && moment(planned_time_window_start, 'HH:mm:ss').isValid()) {
    startTime = moment(planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss');
  }
  if (planned_time_window_end && moment(planned_time_window_end, 'HH:mm:ss').isValid()) {
    endTime = moment(planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss');
  }

  const addressObj = {
    ...location,
    dock_hours_start: startTime,
    dock_hours_end: endTime,
    accessorials: accessorials,
    default_appointment_type: appointment_type
  };

  if (!addressObj.is_default_origin) {
    addressObj.is_default_origin = false;
  }
  if (isNil(addressObj.location_name)) {
    const locationString = [city, stateProvince, postalCode, country].filter(Boolean).join(', ');
    //backend allows 256 chars
    const locationName = (companyName ? `${companyName} - ${locationString}` : locationString).slice(0, 257);
    addressObj.location_name = locationName;
  }
  if (isNil(addressObj.external_reference)) {
    addressObj.external_reference = addressObj.location_name;
  }

  return addressObj;
}

/**
 * Creates a new address book entry
 * @param {import('@shipwell/backend-core-sdk').AddressBookEntry} body
 * @param {module:api/shipwellApi~addressBookPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/AddressBookEntry}
 */
// Create address book entry
export function postAddressBook(body) {
  const addressObj = mapAddressBook(body);

  return (dispatch) => {
    return postAddressBookPromise(addressObj).then((response) => {
      dispatch(addressBookCreateSuccess(response));
      return {status: 200, details: response};
    });
  };
}

async function postAddressBookPromise(addressBookEntry) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.addressBookPost(addressBookEntry, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Update an address book entry or add point of contacts
 * @param {String} addressBookId The id of a company address book entry
 * @param {module:model/AddressBookEntry} body
 * @param {module:api/shipwellApi~addressBookAddressBookIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/AddressBookEntry}
 */

// Update address book entry
export function addressBookAddressBookIdPut(addressBookId, body) {
  const addressObj = mapAddressBook(body);

  return (dispatch) => {
    return addressBookAddressBookIdPutPromise(addressBookId, addressObj).then((response) => {
      dispatch(addressBookUpdateSuccess(response));
      return {status: 200, details: response};
    });
  };
}

async function addressBookAddressBookIdPutPromise(addressBookId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.addressBookAddressBookIdPut(addressBookId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Delete an address book entry
 * @param {String} addressBookId The id of a company address book entry
 * @param {module:api/shipwellApi~addressBookAddressBookIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/InlineResponse204}
 */
export function deleteAddressBook(id) {
  return (dispatch) => {
    return deleteAddressBookPromise(id)
      .then((response) => {
        dispatch({type: DELETE_ADDRESSBOOK, payload: id});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting this address.'
        });
      });
  };
}

async function deleteAddressBookPromise(id) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.addressBookAddressBookIdDelete(id, function (err, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}

/**
 * Obtain information about an address book entry
 * @param {String} addressBookId The id of a company address book entry
 * @param {module:api/shipwellApi~addressBookAddressBookIdGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/AddressBookEntry}
 */

export function getAddress(addressBookId) {
  return (dispatch) => {
    return getAddressPromise(addressBookId)
      .then((response) => {
        dispatch({type: SELECT_ADDRESS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getAddressPromise(addressBookId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.addressBookAddressBookIdGet(addressBookId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}
