import {Quote} from '@shipwell/backend-core-sdk';
import {startCaseToLower} from 'App/utils/startCaseToLower';

export const serviceLevelAccessor = (quote: Quote): string => {
  if (quote.service_level?.description) {
    return quote.service_level.description;
  }
  if (quote.fedex_direct_quote?.service_code) {
    // split aparat the underscore all caps enum value and convert it to tile case
    // e.g. 'FEDEX_FREIGHT_PRIORITY' -> 'FedEx Freight Priority'
    return quote.fedex_direct_quote.service_code
      .split('_')
      .map<string>((word) => startCaseToLower(word))
      .reduce((left, right) => left + ' ' + right);
  }

  return '--';
};

export const instantQuotesFilter = (quotes: Quote[]): Quote[] => {
  const WORLDWIDE_EXPRESS_DISPLAY_NAME = 'Worldwide Express';

  // separate shipwell, worldwide express and other quotes
  const shipwellQuotes = quotes.filter((q) => q.created_by_company?.is_shipwell);
  const worldwideExpressQuotes = quotes.filter((q) => q.created_by_company?.name === WORLDWIDE_EXPRESS_DISPLAY_NAME);

  const shipwellAndWorldwideExpressQuotesSet = new Set([
    ...shipwellQuotes.map((q) => q.id),
    ...worldwideExpressQuotes.map((q) => q.id)
  ]);

  const otherQuotes = quotes.filter((q) => !shipwellAndWorldwideExpressQuotesSet.has(q.id));

  // check worldwide express quotes in order to remove the ones where we already have customer-specific rates
  const filteredWorldwideExpressQuotes = worldwideExpressQuotes.filter((q) => {
    const serviceLevel = serviceLevelAccessor(q);
    const carrierId = q.carrier?.id;

    const hasCustomerSpecificRate = otherQuotes.some(
      (otherQuote) => otherQuote.carrier?.id === carrierId && serviceLevelAccessor(otherQuote) === serviceLevel
    );

    return !hasCustomerSpecificRate;
  });

  // check shipwell quotes in order to remove the ones where we already have customer-specific rates
  const filteredShipwellQuotes = shipwellQuotes.filter((q) => {
    const serviceLevel = serviceLevelAccessor(q);
    const carrierId = q.carrier?.id;

    const hasCustomerSpecificRate = otherQuotes.some(
      (otherQuote) => otherQuote.carrier?.id === carrierId && serviceLevelAccessor(otherQuote) === serviceLevel
    );

    const hasWorldwideExpressRate = worldwideExpressQuotes.some(
      (worldwideExpressQuote) =>
        worldwideExpressQuote.carrier?.id === carrierId && serviceLevelAccessor(worldwideExpressQuote) === serviceLevel
    );

    return !hasCustomerSpecificRate && !hasWorldwideExpressRate;
  });

  // return all customer specific quotes, and the filtered SW and WWE quotes
  const filteredQuotes: Quote[] = [...otherQuotes, ...filteredShipwellQuotes, ...filteredWorldwideExpressQuotes];

  return filteredQuotes;
};
