import {CreateSupplierUser, Supplier} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateSupplierUser} from 'App/api/suppliers';
import {UseMutationOptionsUtil} from 'App/utils/queryHelpers';
import {SUPPLIERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const updateSupplierUsers = async ({
  supplierId,
  users
}: {
  supplierId: string;
  users: {id: string; data: CreateSupplierUser}[];
}) => {
  const results = await Promise.allSettled(
    users.map((user) => updateSupplierUser({supplierId, supplierUserId: user.id, createSupplierUser: user.data}))
  );
  if (results.some((result) => result.status === 'rejected')) {
    throw new Error('There was an error updating supplier contacts.');
  }
};

export const useUpdateSupplierUsers = (options?: UseMutationOptionsUtil<typeof updateSupplierUsers>) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(updateSupplierUsers, {
    ...options,
    async onMutate(variables) {
      await queryClient.cancelQueries([SUPPLIERS_QUERY_KEY]);
      const previousSupplier = queryClient.getQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId]);
      queryClient.setQueryData<Supplier>([SUPPLIERS_QUERY_KEY, variables.supplierId], (oldSupplier) => {
        if (oldSupplier)
          return {
            ...oldSupplier,
            users: oldSupplier.users?.map((oldUser) => {
              const updatedUser = variables.users.find((user) => user.id === oldUser.id);
              return updatedUser ? {...oldUser, ...updatedUser.data} : oldUser;
            })
          };
      });

      options?.onMutate?.(variables);

      return {previousSupplier};
    },
    onError(error, variables, context) {
      queryClient.setQueryData([SUPPLIERS_QUERY_KEY, variables.supplierId], context?.previousSupplier);
      options?.onError?.(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      void queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]);
      options?.onSettled?.(data, error, variables, context);
    }
  });

  return {updateSupplierUsers: mutate, isUpdatingUsers: isLoading};
};
