import {Route, Link} from 'react-router';
import {compose} from 'recompose';
import classNames from 'classnames';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import CreateDrayageShipment from 'App/containers/shipments/drayage/create';

export default <Route path="/drayage-shipment/create" component={CreateDrayageShipment} />;

type ConditionalLinkProps = {
  onClick?: () => unknown;
  isMainNav?: boolean;
  newShipmentBuilderPreference?: boolean;
};

const CreateDrayageShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference
}: ConditionalLinkProps) => (
  <Link
    to={newShipmentBuilderPreference === true ? '/shipment/drayage/create' : '/drayage-shipment/create'}
    className={classNames({'text-xs pl-6 uppercase': isMainNav})}
    activeClassName="active"
    onClick={() => (onClick ? onClick() : null)}
  >
    Drayage
  </Link>
);

const OldCreateDrayageShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference
}: ConditionalLinkProps) => (
  <Link
    to={newShipmentBuilderPreference === true ? '/shipment/drayage/create' : '/new-shipment-drayage'}
    className={classNames('uppercase', {'text-xs pl-6': isMainNav})}
    activeClassName="active"
    onClick={onClick}
  >
    Drayage
  </Link>
);

const CreateIntermodalShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference
}: ConditionalLinkProps) => (
  <Link
    to={newShipmentBuilderPreference === true ? '/shipment/intermodal/create' : '/new-shipment-intermodal'}
    className={classNames('uppercase', {'text-xs pl-6': isMainNav})}
    activeClassName="active"
    onClick={onClick}
  >
    Intermodal
  </Link>
);

export const ConditionalCreateDrayageShipmentLink = compose<ConditionalLinkProps, ConditionalLinkProps>(
  withFlags('platNewCreateDrayage'),
  withConditionalFallback(
    ({platNewCreateDrayage}: {platNewCreateDrayage: boolean}) => !platNewCreateDrayage,
    OldCreateDrayageShipmentLink
  )
)(CreateDrayageShipmentLink);

export const ConditionalCreateIntermodalShipmentLink = compose<ConditionalLinkProps, ConditionalLinkProps>(
  withFlags('fmNewCreateIntermodal'),
  withConditionalFallback(({fmNewCreateIntermodal}: {fmNewCreateIntermodal: boolean}) => !fmNewCreateIntermodal, null)
)(CreateIntermodalShipmentLink);
