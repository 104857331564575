import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SvgIcon, LabelInput} from '@shipwell/shipwell-ui';
import getNil from 'App/utils/getNil';
import {useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import {getReferenceValue, referenceTypes} from 'App/containers/shipments/utils/constants';
import {trimWhitespaceOnSubmit} from 'App/utils/labelInputUtils';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ShipmentNameBase = ({shipmentId, user, isQuotingLimitedUser}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const shipmentModel = getNil(shipmentQuery, 'data', {});

  const {updateShipmentSummaryMutation} = useCreateOrUpdateShipment(shipmentId);

  const handleUpdateShipmentSummary = (val) => {
    const payload = {
      ...shipmentModel,
      name: val
    };
    updateShipmentSummaryMutation.mutate({
      shipmentId,
      data: payload
    });
  };

  const handleSubmit = (value) => trimWhitespaceOnSubmit(shipmentName, value, handleUpdateShipmentSummary);

  const customerReferenceNumber = getReferenceValue(referenceTypes.CUSTOMER_REFERENCE_NUMBER, shipmentQuery?.data);
  const pickupCompany = getNil(shipmentQuery?.data, 'stops[0].location.company_name');
  const deliveryCompany = getNil(shipmentQuery?.data, 'stops[1].location.company_name');
  const defaultShipmentName = [customerReferenceNumber, 'Drayage', pickupCompany, deliveryCompany]
    .filter((nameComponent) => !!nameComponent)
    .join(' - ');

  const shipmentName = getNil(shipmentQuery?.data, 'name', defaultShipmentName);

  return (
    <div className="pb-2">
      <LabelInput
        value={shipmentName}
        onSubmit={handleSubmit}
        placeholder="Shipment Description"
        autoComplete="on"
        isDisabled={
          (!user.permissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) &&
            !user.permissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) ||
          isQuotingLimitedUser ||
          shipmentQuery.isInitialLoading
        }
      >
        <span className="ml-4">
          {updateShipmentSummaryMutation.isLoading ? <SvgIcon name="LoadingDots" /> : shipmentName}
        </span>
      </LabelInput>
    </div>
  );
};

ShipmentNameBase.propTypes = {
  shipmentId: PropTypes.string,
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }),
  isQuotingLimitedUser: PropTypes.bool
};

ShipmentNameBase.defaultProps = {
  isQuotingLimitedUser: false
};

const ShipmentName = connect((state) => ({
  user: state.userProfile.user,
  isQuotingLimitedUser: state.userProfile.is_quoting_limited_user
}))(ShipmentNameBase);

export default ShipmentName;
