import PropTypes from 'prop-types';
import has from 'lodash/has';
import {connect} from 'react-redux';
import {shipmentDetailsComponentMap} from 'App/containers/Shipment/shipmentDetailsSchema';

const makeOnCollapse = (onCollapse, id) => (result) => onCollapse(result, id);

const ShipmentCard = ({draggableId, onCollapse, ...props}) => {
  if (!has(shipmentDetailsComponentMap, draggableId)) {
    return null;
  }
  const Component = shipmentDetailsComponentMap[draggableId].card;
  return <Component draggableId={draggableId} onCollapse={makeOnCollapse(onCollapse, draggableId)} {...props} />;
};

ShipmentCard.defaultProps = {
  draggableId: 'draggable',
  onCollapse: () => {}
};

ShipmentCard.propTypes = {
  draggableId: PropTypes.string,
  onCollapse: PropTypes.func
};

export default connect()(ShipmentCard);
