import {Field, useFormikContext} from 'formik';
import {FormikDateTimePicker, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {DATE_TERMS} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/constants';

const DatePickerIconTooltipContent = () => (
  <>
    <div className="font-bold">Due Date</div>
    <div>The due date is calculated from today&apos;s date - the date on which the invoice is sent.</div>
  </>
);
const DatePickerIconTooltip = () => {
  return (
    <Tooltip trigger="hover" tooltipContent={<DatePickerIconTooltipContent />}>
      <SvgIcon name="InfoOutlined" />
    </Tooltip>
  );
};

const DatePickerField = ({showTooltip, isDueDateField = false}: {showTooltip?: boolean; isDueDateField?: boolean}) => {
  const {setFieldValue} = useFormikContext();
  const handleUpdateDateTerm = (val: string) => {
    const matchingDateTermOption = DATE_TERMS.find((termOption) => termOption.date === val);
    if (matchingDateTermOption) {
      setFieldValue(isDueDateField ? 'due_date_term' : 'approved_date_term', matchingDateTermOption);
    } else {
      setFieldValue(
        isDueDateField ? 'due_date_term' : 'approved_date_term',
        DATE_TERMS.find((termOption) => termOption.label === 'Custom')
      );
    }
  };
  return (
    <Field
      label={isDueDateField ? 'Due Date' : 'Approved Date'}
      name={isDueDateField ? 'due_date' : 'approved_date'}
      component={FormikDateTimePicker}
      prepend={<SvgIcon name="Calendar" />}
      append={showTooltip ? <DatePickerIconTooltip /> : null}
      onChange={handleUpdateDateTerm}
      showTimeSelect={false}
      required={false}
    />
  );
};
export default DatePickerField;
