import {getServiceProviders, ServiceProviderOptions} from './typed';

export const handleSearchServiceProviders = async (opts: ServiceProviderOptions) => {
  try {
    const response = await getServiceProviders(opts);
    return response?.data?.data;
  } catch (error) {
    console.error(error);
  }
};
