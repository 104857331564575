import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {ReactNode} from 'react';

export interface IntegrationCardProps {
  isConnected: boolean;
  isLoading: boolean;
  image: ReactNode;
  name: ReactNode;
  extraInfo?: ReactNode;
  description?: ReactNode;
  onConnectClick: () => void;
  onCardClick: () => void;
}

export function IntegrationCard(props: IntegrationCardProps) {
  const cardIsClickable = !props.isLoading && props.isConnected;

  return (
    <div
      role={cardIsClickable ? 'button' : 'none'}
      className={classNames(
        'group/item',
        'relative m-3 h-[300px] w-[286px] bg-[#fff] p-6',
        'rounded border-1 border-solid border-sw-disabled-alternate',
        'hover:border-sw-primary hover:shadow-[inset_0px_0px_0px_1px_#006ba2]',
        {'cursor-pointer': undefined}
      )}
      onClick={() => {
        if (cardIsClickable) props.onCardClick();
      }}
    >
      {props.description && (
        <div
          className={`
            text-white z-100 duration-250 absolute left-0 top-0 
            h-[131px] w-full bg-[rgba(38,40,43,0.9)] 
            px-6 py-3.5 text-xs leading-tight
            text-[#fff] opacity-0 transition-all ease-out
            group-hover/item:opacity-100
          `}
        >
          {props.description}
        </div>
      )}

      {props.isConnected && (
        <div className="absolute right-4 top-4">
          <SvgIcon name="CheckCircleFilled" className="fill-sw-success" />
        </div>
      )}

      <div className="flex size-full flex-col items-center justify-center gap-2">
        <div className="flex-1">
          <div className="mb-3 flex h-28 items-center justify-center">{props.image}</div>
          <div className="text-center text-lg font-bold">{props.name}</div>
        </div>
        {props.extraInfo && <div className="flex flex-1 items-center text-center text-xs">{props.extraInfo}</div>}
        <div>
          <DeprecatedButton
            disabled={props.isLoading || props.isConnected}
            className="w-52"
            variant="primary"
            onClick={(evt) => {
              evt.stopPropagation();
              props.onConnectClick();
            }}
          >
            {props.isConnected ? 'Connected' : 'Connect'}
          </DeprecatedButton>
        </div>
      </div>
    </div>
  );
}
