import {useState, useCallback, useEffect} from 'react';
import {fetchStopAlerts} from 'App/api/shipment';

export default function useStopFlags(stop, shipment) {
  const [alerts, setAlerts] = useState([]);

  const retrieveStopAlerts = useCallback(async () => {
    try {
      const response = await fetchStopAlerts(shipment.id, stop.id);
      setAlerts(response.body.results);
    } catch (e) {
      console.error('Error retrieving stop flags', e);
    }
  }, [shipment.id, stop.id]);

  useEffect(() => {
    retrieveStopAlerts();
  }, [retrieveStopAlerts]);

  return [alerts, retrieveStopAlerts];
}
