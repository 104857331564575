import {useContext} from 'react';
import {
  FormikTextInput,
  FormikSelect,
  SvgIcon,
  FormikDateTimePicker,
  FormikPhoneInput,
  FormikTextarea
} from '@shipwell/shipwell-ui';
import {LoadType} from '@shipwell/tempus-sdk';
import {Formik, Form, Field} from 'formik';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {SupplierAppointmentContext} from 'App/containers/appointments/components/forms/SupplierAppointment';
import {supplierAppointmentValidationSchema} from 'App/containers/appointments/components/forms/SupplierAppointment/schema';
import {supplierAppointmentFormDataDefaultValues} from 'App/containers/appointments/components/forms/SupplierAppointment/constants';
import {SupplierAppointmentCreationFormType} from 'App/containers/appointments/components/forms/SupplierAppointment/types';
import {generateTimeIntervalOptions} from 'App/data-hooks/facilities/facilityUtils';
import {
  deliveryTypeOptions,
  modeOptions,
  equipmentOptions,
  referenceTypeOptions,
  stackableOptions,
  hazmatOptions
} from 'App/data-hooks/facilities/constants';
import {formatISODate} from 'App/utils/dateTimeGlobalsTyped';

const appointmentWindowOptions = [
  {label: 'All Day', value: 'All Day'},
  ...generateTimeIntervalOptions({totalHours: 10, interval: 15})
];

const SupplierAppointmentForm = () => {
  const {
    facility,
    onCancel,
    loadTypes,
    handleNextStep,
    setSupplierAppointmentFormData,
    supplierAppointmentFormData,
    clickedTime
  } = useContext(SupplierAppointmentContext);
  const handleSubmitSupplierForm = (values: SupplierAppointmentCreationFormType) => {
    values.is_all_day = String(values.is_all_day) === 'true';
    setSupplierAppointmentFormData(values);
    handleNextStep();
  };

  const handleSelectLoadType = (
    loadTypeId: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  ) => {
    // user has cleared out the load type field, reset the other ones
    if (!loadTypeId) {
      setFieldValue('delivery_type', '');
      setFieldValue('load_type', '');
      setFieldValue('mode', '');
      setFieldValue('equipment_type', '');
      setFieldValue('product_reference', '');
      setFieldValue('packaging_type', '');
      setFieldValue('appointment_duration', '');
    }
    const targetLoadType = loadTypes.find((loadType) => loadType.id === loadTypeId);
    // valid selection, auto-populate the other fields
    if (targetLoadType) {
      setFieldValue('delivery_type', targetLoadType.delivery_type);
      setFieldValue('load_type', targetLoadType.id);
      setFieldValue('mode', targetLoadType.mode || '');
      setFieldValue('equipment_type', targetLoadType.equipment_type || '');
      setFieldValue('product_reference', targetLoadType.product_reference || '');
      setFieldValue('packaging_type', targetLoadType.packaging_type || '');
      if (targetLoadType.all_day_appointment) {
        setFieldValue('appointment_duration', 'All Day');
        setFieldValue('is_all_day', 'true');
      } else {
        setFieldValue('appointment_duration', targetLoadType.appointment_duration ?? '');
        setFieldValue('is_all_day', 'false');
      }
    }
  };

  const handleAppointmentWindowChange = (
    duration: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  ) => {
    if (duration === 'All Day') {
      setFieldValue('is_all_day', 'true');
    } else {
      setFieldValue('is_all_day', 'false');
    }
    setFieldValue('appointment_duration', duration);
  };

  const handleStackableChange = (stackableValue: string, setFieldValue: (field: string, value?: boolean) => void) => {
    setFieldValue('stackable', stackableValue === 'true' ? true : stackableValue === 'false' ? false : undefined);
  };
  const handleHazmatChange = (hazmatValue: string, setFieldValue: (field: string, value?: boolean) => void) => {
    setFieldValue('is_hazmat', hazmatValue === 'true' ? true : hazmatValue === 'false' ? false : undefined);
  };
  const appointmentDate = formatISODate(clickedTime ?? new Date(), facility?.address.timezone);
  return (
    <div className="mb-10 h-full">
      <Formik
        validateOnMount
        initialValues={{
          ...supplierAppointmentFormDataDefaultValues,
          ...supplierAppointmentFormData,
          appointment_date: appointmentDate
        }}
        validationSchema={supplierAppointmentValidationSchema}
        onSubmit={handleSubmitSupplierForm}
      >
        {({values, isValid, setFieldValue}) => (
          <Form noValidate>
            <div className="flex flex-col gap-y-4">
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  required
                  label="Appointment Date"
                  component={FormikDateTimePicker}
                  prepend={<SvgIcon name="Calendar" color="$sw-icon" />}
                  showTimeSelect={false}
                  portalId="portal"
                  name="appointment_date"
                />
                <div className="grid grid-cols-2 gap-x-2">
                  <Field
                    clearable={false}
                    simpleValue
                    label="Stackable"
                    component={FormikSelect}
                    name="stackable"
                    options={stackableOptions}
                    value={`${values?.stackable}`}
                    onChange={(stackableValue: string) => handleStackableChange(stackableValue, setFieldValue)}
                  />
                  <Field
                    clearable={false}
                    simpleValue
                    label="Hazmat"
                    component={FormikSelect}
                    name="is_hazmat"
                    options={hazmatOptions}
                    value={`${values?.is_hazmat}`}
                    onChange={(hazmatValue: string) => handleHazmatChange(hazmatValue, setFieldValue)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  required
                  simpleValue
                  label="Reference # Type"
                  component={FormikSelect}
                  name="reference_type"
                  options={referenceTypeOptions}
                />
                <Field required label="Reference #" component={FormikTextInput} name="reference_number" />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  simpleValue
                  required
                  label="Delivery Type"
                  component={FormikSelect}
                  name="delivery_type"
                  options={deliveryTypeOptions}
                  disabled={values?.load_type}
                />
                <Field
                  simpleValue
                  label="Load Type"
                  component={FormikSelect}
                  name="load_type"
                  getOptionLabel={(option: LoadType) => option.name}
                  getOptionValue={(option: LoadType) => option.id}
                  options={loadTypes}
                  clearable
                  onChange={(loadTypeId: string) => handleSelectLoadType(loadTypeId, setFieldValue)}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  simpleValue
                  label="Mode"
                  component={FormikSelect}
                  name="mode"
                  options={modeOptions}
                  disabled={values?.load_type}
                />
                <Field
                  simpleValue
                  label="Equipment"
                  component={FormikSelect}
                  name="equipment_type"
                  options={equipmentOptions}
                  disabled={values?.load_type}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field required label="Carrier Name" component={FormikTextInput} name="carrier_name" />
                <Field
                  prepend={<SvgIcon name="EmailOutlined" color="$sw-icon" />}
                  required
                  label="Email"
                  component={FormikTextInput}
                  name="carrier_email"
                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field required label="Phone Number" component={FormikPhoneInput} name="carrier_phone_number" />
                <Field
                  simpleValue
                  required
                  label="Appointment Window"
                  component={FormikSelect}
                  name="appointment_duration"
                  options={appointmentWindowOptions}
                  disabled={values?.load_type}
                  onChange={(duration: string) => handleAppointmentWindowChange(duration, setFieldValue)}
                />
              </div>
              <Field label="Notes" component={FormikTextarea} name="notes" minRows={4} maxRows={4} />
            </div>
            <ModalFormFooter isValid={isValid} onCancel={onCancel} primaryActionName="Next" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SupplierAppointmentForm;
