import {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {Field, change, clearFields} from 'redux-form';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import DateTimeField from 'App/formComponents/fields/dateTimePicker';

/**
 * Terminal Schedule Fields
 */
class TerminalScheduleFields extends Component {
  static defaultProps = {
    slimForm: false
  };

  componentWillUnmount() {
    const {form} = this.props;

    this.props.dispatch(
      clearFields(
        form,
        false,
        false,
        'drayage_estimated_arrival_date',
        'drayage_release_date',
        'drayage_last_free_date',
        'drayage_container_return_date'
      )
    );
  }

  formatDate(date) {
    if (!date) {
      return null;
    }
    if (moment(date).isValid()) {
      return moment(date).format('YYYY-MM-DD');
    }
    return moment().format('YYYY-MM-DD');
  }

  render() {
    const {slimForm} = this.props;

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Field
              name="drayage_estimated_arrival_date"
              label="Estimated Port Arrival"
              normalize={this.formatDate.bind(this)}
              component={DateTimeField}
            />
          </Grid>
          {!slimForm && (
            <Fragment>
              <Grid item xs={12} md={4}>
                <Field
                  name="drayage_release_date"
                  label="Release Date"
                  normalize={this.formatDate.bind(this)}
                  component={DateTimeField}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
        {!slimForm && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Field
                name="drayage_last_free_date"
                label="Last Free Date"
                normalize={this.formatDate.bind(this)}
                component={DateTimeField}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="drayage_container_return_date"
                label="Container Return Date"
                normalize={this.formatDate.bind(this)}
                component={DateTimeField}
              />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

export default connect()(TerminalScheduleFields);
