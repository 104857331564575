import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {PaginatedSpotNegotiation, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import reject from 'lodash/reject';
import {SHIPMENT_SPOT_NEGOTIATIONS} from '../queryKeys';
import {getShipmentSpotNegotiations} from 'App/api/quoting/typed';
import {getLowestBid} from 'App/utils/globalsTyped';
import {useUserMe} from 'App/data-hooks';

interface UseGetShipmentSpotNegotiationsParams extends UseQueryOptions<PaginatedSpotNegotiation, ShipwellError> {
  shipmentId: string;
  expandQuotes?: boolean;
  orderByLowestQuote?: boolean;
  omitOwnSpotNegotiations?: boolean;
}

export const useGetShipmentSpotNegotiations = ({
  shipmentId,
  expandQuotes = true,
  orderByLowestQuote = false,
  omitOwnSpotNegotiations = false,
  ...options
}: UseGetShipmentSpotNegotiationsParams) => {
  const {data: {company} = {}} = useUserMe();

  const {
    data: spotNegotiations,
    isInitialLoading,
    isLoading,
    isFetching
  } = useQuery(
    [SHIPMENT_SPOT_NEGOTIATIONS, shipmentId] as QueryKey,
    async () => {
      const {data} = await getShipmentSpotNegotiations({shipmentId, expandQuotes});
      let {results} = data;

      //remove your own company's spot negotiations
      if (omitOwnSpotNegotiations)
        results = reject(results, (spotNegotiation) => spotNegotiation.vendor?.id === company?.id);

      //order spot negotiations by lowest bid
      if (orderByLowestQuote)
        results?.sort((resultA, resultB) => {
          const lowestA = getLowestBid({shipmentSpotNegotiation: resultA});
          const lowestB = getLowestBid({shipmentSpotNegotiation: resultB});
          return lowestA?.total ? lowestA?.total - (lowestB?.total || 0) : 1;
        });

      return {...data, results};
    },
    {...options}
  );

  const isLoadingSpotNegotiations = isInitialLoading || isLoading || isFetching;

  return {spotNegotiations, isLoadingSpotNegotiations};
};
