import {useQuery} from '@tanstack/react-query';
import {getTimelineFilterPreferences} from 'App/api/tracking/typed';
import {TIMELINE_FILTER_PREFERENCES} from 'App/data-hooks/queryKeys';
import {timelineFilterTypes} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';
import {TimelineFilter} from 'App/components/ShipmentTrackingOverview/TimelineContainer/Timeline/LegacyTimeline';

export const useGetTimelineEventFilters = (queryOptions = {}) => {
  const timelineFiltersQuery = useQuery(
    [TIMELINE_FILTER_PREFERENCES],
    async () => {
      const response = await getTimelineFilterPreferences();
      return response?.data;
    },
    {
      ...queryOptions
    }
  );

  // required formatting of data until BE endpoint gets updated with values discussed for these fields.
  const formattedFilters = {
    ...timelineFiltersQuery?.data,
    timelineDisplayType: timelineFiltersQuery?.data?.rollup_stops
      ? timelineFilterTypes.ROLLUP_EVENTS
      : timelineFilterTypes.INDIVIDUAL_EVENTS,
    groupType: timelineFiltersQuery?.data?.group_type || timelineFilterTypes.ALL_EVENT_UPDATES,
    filterFutureStops: timelineFiltersQuery?.data?.filter_future_stops
      ? timelineFilterTypes?.HIDE_FUTURE_STOPS
      : timelineFilterTypes.SHOW_FUTURE_STOPS
  } as TimelineFilter;

  return {formattedFilters, context: {timelineFiltersQuery}};
};
