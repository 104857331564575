import {
  ChargeLineItemCategory,
  FreightInvoiceChargeLineItems,
  FreightInvoiceDocumentsMetadata,
  ShipmentChargeLineItem,
  PackageType,
  ShipmentLineItem,
  ShipmentLineItemWeightUnitEnum,
  FreightInvoiceRuleConfiguration,
  ChargeCategory,
  CarrierAssignment,
  ShipmentChargeLineItemUnitAmountCurrencyEnum,
  InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum
} from '@shipwell/backend-core-sdk';
import startCase from 'lodash/startCase';
import {FormikCheckbox, Pill} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {formatCurrency} from 'App/utils/internationalConstants';
import {
  DocumentRequiredRule,
  FreightInvoiceChargeLineItemsWithFormMetadata,
  FreightInvoiceFormData
} from 'App/containers/settlements/freightInvoices/types';
import {mapAddressLookupFieldsToAddress} from 'App/utils/corrogo/mappers';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';

const mapV2LineItem = (lineItem: ShipmentChargeLineItem): FreightInvoiceChargeLineItemsWithFormMetadata => {
  return {
    category: lineItem.category,
    description: lineItem.unit_name || '',
    quantity: lineItem.unit_quantity.toString(),
    unit_amount:
      lineItem.prepaid_amount && lineItem.unit_amount
        ? //if there is a prepaid amount, subtract it from the unit amount to get the total line item amount
          {
            value: (lineItem.unit_amount - lineItem.prepaid_amount / lineItem.unit_quantity).toString(),
            currency: lineItem.unit_amount_currency || 'USD'
          }
        : {value: (lineItem.unit_amount || 0).toString(), currency: lineItem.unit_amount_currency || 'USD'},
    add_to_invoice: true
  };
};

export const transformV2ChargeLineItemsToSettlementLineItems = (v2ShipmentLineItems: ShipmentChargeLineItem[]) =>
  v2ShipmentLineItems.map((lineItem) => mapV2LineItem(lineItem));

const mapSettlementsLineItemToLegacyFinancialLineItem = (
  lineItem: FreightInvoiceChargeLineItemsWithFormMetadata
): ShipmentChargeLineItem => {
  return {
    category: lineItem.category as ChargeCategory,
    unit_name: lineItem.description || '',
    charge_code: lineItem.charge_code || '',
    unit_quantity: parseFloat(lineItem.quantity),
    unit_amount: lineItem.unit_amount.value ? parseFloat(lineItem.unit_amount.value) : 0,
    unit_amount_currency: lineItem.unit_amount.currency as ShipmentChargeLineItemUnitAmountCurrencyEnum
  };
};

export const mapSettlementsLineItemsToLegacyFinancialLineItems = (
  settlementsLineItems: FreightInvoiceChargeLineItemsWithFormMetadata[]
): ShipmentChargeLineItem[] =>
  settlementsLineItems.map((lineItem) => mapSettlementsLineItemToLegacyFinancialLineItem(lineItem));

export const generateFinancialsLineItemRow = (
  lineItem: FreightInvoiceChargeLineItems,
  index: number,
  chargeCategoryData?: ChargeLineItemCategory[]
) => [
  <Field component={FormikCheckbox} key={lineItem.description} name={`charge_line_items[${index}].add_to_invoice`} />,
  //get the category label from the charge category list
  chargeCategoryData?.find((category) => category.id === lineItem.category)?.name || '--',
  startCase(lineItem.description || '--'),
  lineItem.quantity || '--',
  <div className="text-right" key={lineItem.description}>
    {formatCurrency(lineItem.unit_amount.value, lineItem.unit_amount.currency) || '--'}
  </div>
];

export const getInvoiceTotal = (chargeLineItems: FreightInvoiceChargeLineItems[]) =>
  chargeLineItems.reduce((chargeTotal, lineItem) => {
    return (chargeTotal +=
      ((lineItem.unit_amount?.value && parseFloat(lineItem.unit_amount?.value)) || 0) *
      ((lineItem.quantity && parseFloat(lineItem.quantity)) || 0));
  }, 0);

export const getFreightInvoicePayload = (formValues: FreightInvoiceFormData) => {
  const selectedShipmentDocumentIds = formValues.document_metadata
    ? formValues.document_metadata
        //shipment documents won't have the document to shipment linkage.
        ?.filter((document) => document.shipment && document.add_to_invoice)
        .map((shipmentDocument) => shipmentDocument.id || '')
    : [];
  return {
    ...formValues,
    //map the address lookup field values to the address fields the model expects
    remit_to: formValues.remit_to?.address
      ? {...formValues.remit_to, ...mapAddressLookupFieldsToAddress(formValues.remit_to.address)?.address}
      : formValues.remit_to,
    shipment_document_ids: selectedShipmentDocumentIds
  };
};

export const getSelectedFreightInvoiceDocuments = (formValues: FreightInvoiceFormData): File[] => {
  //freight invoice documents won't have the document to shipment linkage.
  return (
    formValues.document_metadata
      ?.filter((document) => !document.shipment && document.original_file && document.add_to_invoice)
      //the filter above doesn't affect the typing of the array items, so cast to file array
      .map((freightInvoiceDocument) => freightInvoiceDocument.original_file) as File[]
  );
};

export const getSelectedFreightInvoiceDocumentMetadata = (
  formValues: FreightInvoiceFormData
): FreightInvoiceDocumentsMetadata['document_metadata'] => {
  const freightInvoiceDocumentMetadata = formValues.document_metadata?.filter(
    (document) => !document.shipment && document.add_to_invoice
  );
  return freightInvoiceDocumentMetadata?.map((freightInvoiceDocument) => {
    return {
      description: freightInvoiceDocument.description || '',
      document_type: freightInvoiceDocument?.document_type
    };
  });
};
export const generateShipmentLineItemRow = (lineItem: ShipmentLineItem, packageTypes?: PackageType[]) => [
  lineItem.total_packages || 0,
  lineItem.description || '--',
  packageTypes?.find((packageType) => packageType.code === lineItem.package_type)?.description,
  `${lineItem.total_line_item_weight || '--'} ${lineItem.weight_unit || ShipmentLineItemWeightUnitEnum.Lb}`
];

export const getFreightInvoiceExpectedActualPercentageChange = (invoicedAmount: number, expectedAmount: number) => {
  if (expectedAmount === 0) {
    //if expected amount is 0 (no shipment charge line items), percentage change is 100%
    return 100;
  }
  const percentage = (invoicedAmount / expectedAmount) * 100 - 100;
  return Math.abs(percentage) < 1 ? Math.round(percentage * 100) / 100 : Math.round(percentage);
};

export const FreightInvoicePercentPill = ({
  isCarrier,
  hasWrongAmountSubStatus,
  percentChange
}: {
  isCarrier: boolean;
  hasWrongAmountSubStatus: boolean;
  percentChange: number;
}) => {
  const percentageValue = Math.abs(percentChange);
  const showWarningPill = isCarrier || hasWrongAmountSubStatus;

  return (
    <Pill
      minWidth={false}
      variant={showWarningPill ? 'warning' : 'default'}
      size="sm"
      iconName={percentChange > 0 ? 'ArrowUpward' : 'ArrowDownward'}
    >
      <span className="font-bold">{percentageValue < 1 ? percentageValue.toFixed(2) : percentageValue}%</span>
    </Pill>
  );
};

export const isDocumentRequiredRule = (rule: FreightInvoiceRuleConfiguration): rule is DocumentRequiredRule =>
  rule.rule_type === 'DOCUMENTS_REQUIRED';

export type FileDisplayDocument = {
  created_at?: string;
  created_by_name?: string;
  description?: string;
  file?: string;
  filename?: string;
  id?: string;
  type?: string;
};

export const constructCarrierAssignmentPayload = (
  carrierAssignment: CarrierAssignment,
  mappedFinancialLineItems: ShipmentChargeLineItem[]
) => {
  return {
    ...carrierAssignment,
    vendor_charge_line_items: [...(carrierAssignment.vendor_charge_line_items || []), ...mappedFinancialLineItems]
  };
};

export function isFile(file: Blob | string): file is File {
  return file instanceof File;
}

export const invoicingRoleKeyMap: Record<SettlementsDashboardGeneratedBy, string> = {
  BOOKING_PARTY: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum.BookingParty,
  SERVICE_PROVIDER: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum.ServiceProvider
};

export const getUpperCaseRoleValue = (
  role: SettlementsDashboardGeneratedBy
): InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum =>
  invoicingRoleKeyMap[role] as InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum;
