import {FormGroup as BootstrapFormGroup, ControlLabel, OverlayTrigger, Tooltip, InputGroup} from 'react-bootstrap';
import './styles.scss';

export const FieldTooltip = ({tooltip}) => {
  return (
    <Tooltip id="tooltip">
      <div className="tooltip">{tooltip}</div>
    </Tooltip>
  );
};

export const FieldLabel = ({label, nolabel, sronly, req, tooltip, displayData}) => {
  return (
    <ControlLabel className={sronly && 'sr-only'}>
      {label}
      {label && req && <span className="requiredField">*</span>}
      {tooltip && (
        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
          <a tabIndex="-1" className="icon icon-Info" aria-hidden="true"></a>
        </OverlayTrigger>
      )}
      {displayData && (
        <span className="form-display-data-small">
          &nbsp;
          {displayData}
        </span>
      )}
      &nbsp;
    </ControlLabel>
  );
};

const FormGroup = (props) => {
  const {input, label, nolabel, icon, className, meta = {}} = props;
  const {touched, error, warning} = meta;
  const errorClass = touched && error ? 'error' : null;
  const warningClass = touched && warning ? 'warning' : null;

  return (
    <BootstrapFormGroup
      className={className}
      controlId={input.name || props.name}
      validationState={errorClass || warningClass}
    >
      <FieldLabel {...props} />
      <InputGroup className="input-group-inline">
        {icon && (
          <InputGroup.Addon>
            <i className={icon} />
          </InputGroup.Addon>
        )}
        {props.children(props)}
      </InputGroup>
      {touched && error && (
        <p className="error-text">
          <i className="icon icon-Delayed" />
          {Array.isArray(error) ? error.join(' ') : error}
        </p>
      )}
      {touched && warning && (
        <p className="warning-text">
          <i className="icon icon-Flag" />
          {warning}
        </p>
      )}
    </BootstrapFormGroup>
  );
};

export default FormGroup;
