import {Formik, Form} from 'formik';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useState} from 'react';
import {JSONEditor} from './components/JSONEditor';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useDynamicFields} from 'App/components/dynamicForms/useDynamicFields';
import {DynamicFormFields} from 'App/components/dynamicForms/DynamicFields';
import {DynamicFieldsApiResponse, getTestFields} from 'App/components/dynamicForms/testFields';

export const DynamicFormPlayground = () => {
  const [parsedData, setParsedData] = useState<{fields?: Record<string, string | null>}>({});
  const {data} = useQuery(['dynamic-form-playground', getTestFields.name], getTestFields);
  const {fields} = parsedData.fields ? (parsedData as unknown as DynamicFieldsApiResponse) : data || {};
  const {validationSchema, initialValues} = useDynamicFields(fields);

  const {mutateAsync: onSubmit} = useMutation((values: Record<string, string | null>) => {
    console.log(values);
    return new Promise((resolve) => setTimeout(resolve, 1000));
  });

  const isLoadingDynamicFields = !initialValues || !fields;

  return isLoadingDynamicFields ? (
    <ShipwellLoader loading={isLoadingDynamicFields} />
  ) : (
    <div className="m-12">
      <JSONEditor setParsedData={setParsedData} initialValues={{fields}} />

      <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)} validationSchema={validationSchema}>
        <Form className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <DynamicFormFields fields={fields} />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
