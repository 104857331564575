import {FormGroup, InputGroup, Checkbox} from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import {formatCurrency} from 'App/utils/internationalConstants';

const ShipmentCustomerLineItems = ({financials, selectAll, selectOne, total, checkedAll, selectList}) => {
  const currency = financials?.find((financial) => !!financial.unit_amount_currency)?.unit_amount_currency || 'USD';

  return (
    <div className="shipment__financials-lineItems">
      <h3>Financials</h3>
      {financials.length > 0 && (
        <FormGroup>
          <InputGroup>
            <Checkbox
              checked={checkedAll}
              onChange={() => {
                selectAll(financials);
              }}
            >
              <Grid container spacing={8} className="shipment__financials-lineItem">
                <Grid item xs={3}>
                  <span>Line Items</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Description</span>
                </Grid>
                <Grid item xs={2}>
                  <span>Quantity</span>
                </Grid>
                <Grid item xs={3} className="shipment__financials-lineItem-cost">
                  <span>Cost</span>
                </Grid>
              </Grid>
            </Checkbox>
          </InputGroup>
        </FormGroup>
      )}
      {financials.length > 0 &&
        financials.map((el) => {
          const idx = selectList.findIndex((doc) => doc.id === el.id);

          return (
            <FormGroup key={el.id}>
              <InputGroup>
                <Checkbox
                  checked={idx === -1 ? false : true}
                  onChange={() => {
                    selectOne(financials, el);
                  }}
                >
                  {el.name}
                </Checkbox>
              </InputGroup>
            </FormGroup>
          );
        })}
      {financials.length === 0 && <div className="none">No line items</div>}
      <div className="shipment__financials-lineItemsTotal">
        <b>Total: {formatCurrency(total, currency)}</b>
      </div>
    </div>
  );
};
export default ShipmentCustomerLineItems;
