import {CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {createCompanyCustomField} from 'App/api/company/typed';
import {CUSTOM_FIELDS_KEY} from 'App/data-hooks/queryKeys';

export const useCreateCompanyCustomField = (
  companyId: string,
  options?: Omit<UseMutationOptions<CustomField, unknown, CustomField, unknown>, 'mutationFn'>
) => {
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    (customField: CustomField) => createCompanyCustomField({companyId, customField}),
    {
      ...options,
      onSettled(...args) {
        void queryClient.invalidateQueries([CUSTOM_FIELDS_KEY]);
        options?.onSettled?.(...args);
      }
    }
  );

  return {createCustomField: mutate, isCreating: isLoading};
};
