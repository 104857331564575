import {createRef, Fragment, Component} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {FormControl, Button, Checkbox} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import {withStyles} from '@material-ui/core/styles';
import {TextInput} from '@shipwell/shipwell-ui';
import FilterModifier, {allowedModifiers} from './FilterComponents/FilterModifier';
import DatePicker from 'App/components/TableFiltersWithSavedViews/FilterComponents/DatePicker';
import ListSelect from 'App/components/TableFiltersWithSavedViews/FilterComponents/ListSelect';
import LocationLookup from 'App/components/TableFiltersWithSavedViews/FilterComponents/LocationLookup';
import TypeaheadLookup from 'App/components/TableFiltersWithSavedViews/FilterComponents/TypeaheadLookup';
import TagSelector from 'App/components/TableFiltersWithSavedViews/FilterComponents/TagSelector';
import store from 'App/routes/store';
import TableReadback from 'App/components/TableFiltersWithSavedViews/TableReadback';
import './styles.scss';
import {componentTypes} from 'App/components/TableFiltersWithSavedViews/constants';
import withFlags from 'App/utils/withFlags';
import {CustomSelection} from 'App/components/TableFiltersWithSavedViews/FilterComponents/CustomSelection';

const OrdersTooltip = withStyles(() => ({
  popper: {
    opacity: 1
  },
  tooltip: {
    background: '#26282b',
    padding: '10px'
  }
}))(Tooltip);

class TableFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedView: false,
      columns: props.columns.map((col) => col.replace('-', '')),
      selectedColumns: props.selectedColumns.filter((e) => !this.props.lockedColumns.includes(e)),
      mode: 'filters', //either 'filters' or 'columns' depending on what the user is doing
      expandedFilters: [],
      activeFilters: {},
      modifiedFromOriginal: false, //tracking whether the ordering or filtering has changed from the initial load
      showSaveForm: false,
      showSaveSuccess: false,
      savedFilterName: '',
      setAsDefault: false,
      selectedFilter: null,
      showViewOptions: {},
      isDeletingView: false,
      renameOnly: false,
      filterModifiers: {}
    };

    this.tableReadbackRef = createRef();
    this.tableContainerRef = createRef();

    this.tableReadbackHeight = 0;
    this.tableContainerHeight = 0;
  }

  //Used to set filterModifiers in state when mounting the tableFilters component
  parseFiltersForModifiers() {
    //get all filters that end in a modifier
    const modifiedFilters = Object.keys(this.state.activeFilters).filter((filter) =>
      filter.match(new RegExp(allowedModifiers.map((modifier) => modifier + '$').join('|')))
    );
    //split each modified filter into {filterId: modifier}
    return Object.fromEntries(
      modifiedFilters.map((filter) =>
        filter.split(new RegExp(allowedModifiers.map((modifier) => `(?=${modifier}$)`).join('|')))
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    //when activeFilters changes update filterModifiers
    if (prevState.activeFilters !== this.state.activeFilters) {
      this.setState({filterModifiers: this.parseFiltersForModifiers()});
    }
    if (prevProps.selectedColumns !== this.props.selectedColumns) {
      this.setState({
        selectedColumns: this.props.selectedColumns
          .filter((column) => !column.startsWith('-'))
          .filter((e) => !this.props.lockedColumns.includes(e))
      });
    }
    if (prevProps.columns !== this.props.columns) {
      //if trip management flag is off, remove upcoming etas from column list
      if (!this.props.tripManagementPredictedEta) {
        const columnsWithoutTripManagementEta = this.props.columns.filter((column) => column !== 'upcoming_etas');
        this.setState({
          columns: columnsWithoutTripManagementEta.map((col) => col.replace('-', ''))
        });
      } else {
        this.setState({
          columns: this.props.columns.map((col) => col.replace('-', ''))
        });
      }
    }
    if (prevProps.defaultSelectedFilter !== this.props.defaultSelectedFilter) {
      const filterToApply = this.props.myViews.filter((e) => e.id === this.props.defaultSelectedFilter)[0];
      //make sure the active filters and columns are adjusted
      if (filterToApply) {
        const {expandedFilters} = this.state;
        const shouldExpandMyViews = expandedFilters.includes('myViews') ? false : true;
        if (shouldExpandMyViews) {
          expandedFilters.push('myViews');
        }
        this.setState({
          selectedFilter: filterToApply.id,
          activeFilters: this.props.parseFilters(filterToApply.config.filters, 'fromParent'),
          selectedColumns: filterToApply.config.columns,
          expandedFilters: expandedFilters,
          renameOnly: false
        });
      } else {
        //reset to defaults
        this.setState({
          selectedFilter: null,
          renameOnly: false,
          activeFilters: {},
          selectedColumns: this.props.defaultColumns //TODO should this go back to the user's default columns/filter?
        });
      }
    }
    if (prevProps.allFiltersSelected !== this.props.allFiltersSelected && this.props.allFiltersSelected) {
      this.setState({
        activeFilters: this.props.parseFilters(this.props.allFiltersSelected, 'fromParent')
      });
    }
    if (
      this.props.successfulSaveTrigger !== prevProps.successfulSaveTrigger &&
      this.props.successfulSaveTrigger === true
    ) {
      //we saved a filter, do things like close the form now
      this.setState(
        {
          showSaveForm: false,
          showSaveSuccess: true,
          modifiedFromOriginal: false,
          setAsDefault: false,
          renameOnly: false,
          mode: 'filters'
        },
        this.hideSuccessMessage
      );
    }
    this.handleResize();
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.handleResize.bind(this), 150));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  closeFilter() {
    this.setState({expandedView: false});
  }

  hideSuccessMessage() {
    const timeout = setTimeout(() => {
      this.setState({showSaveSuccess: false});
      clearTimeout(timeout);
    }, 1000);
  }

  handleToggleExpandFilter(id) {
    const {expandedFilters} = this.state;
    if (expandedFilters.includes(id)) {
      expandedFilters.splice(expandedFilters.indexOf(id), 1);
    } else {
      expandedFilters.push(id);
    }
    this.setState({expandedFilters});
  }

  /**
  Returns a modified filter id if modifier is found in state
   */
  getModifiedFilterId(filterId) {
    return filterId + (this.state.filterModifiers?.[filterId] || '');
  }

  /**
  Constructs the filter component to display
  */
  renderFilter(filterObj) {
    const filterId = this.getModifiedFilterId(filterObj.id);
    const isExpanded = this.state.expandedFilters.includes(filterObj.id);
    //determine if the given filter has any selections in it already
    const hasSelection =
      Object.keys(this.state.activeFilters).includes(filterId) &&
      ((Array.isArray(this.state.activeFilters[filterId]) && this.state.activeFilters[filterId].length) ||
        (!Array.isArray(this.state.activeFilters[filterId]) && this.state.activeFilters[filterId]));

    const modifier = this.state.filterModifiers[filterObj.id];

    return (
      <div className="tableFilters__filter">
        <div className="tableFilters__filter-header" onClick={() => this.handleToggleExpandFilter(filterObj.id)}>
          <div className="flex items-center space-x-2 divide-x">
            <span className="font-bold">{filterObj.title}</span>
            {filterObj.allowModifiers ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FilterModifier
                  modifierDisplayName={modifier}
                  filterId={filterObj.id}
                  handleChangeFilterModifier={this.handleChangeFilterModifier}
                />
              </div>
            ) : null}
          </div>
          <span>
            {hasSelection && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  this.clearFilterSelection(filterObj.id);
                }}
                className="tableFilters__filter-clearSelection"
              >
                Clear
              </span>
            )}
            <span className="tableFilters__filter-toggle actionLink">
              {isExpanded ? <i className="flaticon-substract" /> : <i className="flaticon-add" />}
            </span>
          </span>
        </div>
        <div className="tableFilters__filter-container">{isExpanded && this.renderSwitch(filterObj, hasSelection)}</div>
      </div>
    );
  }

  /*
   * Render the filter based on it's type
   */
  renderSwitch(filterObj, hasSelection) {
    const filterId = this.getModifiedFilterId(filterObj.id);
    const defaultValues = this.state.activeFilters[filterId];
    const preventAutoFocus = this.state.expandedFilters.length === this.props.filterableColumns.length;
    switch (filterObj.component) {
      case componentTypes.LIST_SELECT:
        return (
          <ListSelect
            defaultValues={hasSelection ? defaultValues : null} //make sure to load defaults when filter gets expanded if there are selections
            fieldName={filterId}
            updateActiveFilters={this.updateActiveFilters.bind(this)}
            options={filterObj.options}
            allowMultiSelect={filterObj.allowMultiSelect}
          />
        );
      case componentTypes.LOCATION_LOOKUP:
        return (
          <LocationLookup
            defaultValues={hasSelection ? defaultValues : null} //make sure to load defaults when filter gets expanded if there are selections
            fieldName={filterObj.id}
            updateActiveFilters={this.updateActiveFilters.bind(this)}
          />
        );
      case componentTypes.TYPEAHEAD:
        return (
          <TypeaheadLookup
            defaultValues={hasSelection ? defaultValues : null} //make sure to load defaults when filter gets expanded if there are selections
            fieldName={filterObj.id}
            performSearch={filterObj.performSearch}
            placeholder={filterObj.placeholder}
            valueField={filterObj.valueField}
            labelField={filterObj.labelField}
            localOptions={filterObj.localOptionsLocation ? get(store.getState(), filterObj.localOptionsLocation) : null}
            searchLocalList={filterObj.searchLocalList ? filterObj.searchLocalList : false}
            updateActiveFilters={this.updateActiveFilters.bind(this)}
            preventAutoFocus={preventAutoFocus} //when user expands ALL, don't want all the menus to pop up
            simpleValue={filterObj.simpleValue}
          />
        );
      case componentTypes.DATE_PICKER:
        return (
          <DatePicker
            defaultValues={hasSelection ? defaultValues : null} //make sure to load defaults when filter gets expanded if there are selections
            fieldName={filterObj.id}
            preventFuture={filterObj.preventFuture}
            updateActiveFilters={this.updateActiveFilters.bind(this)}
          />
        );
      case componentTypes.TAG_SELECTOR:
        return (
          <TagSelector
            defaultValues={hasSelection ? defaultValues : null} //make sure to load defaults when filter gets expanded if there are selections
            fieldName={filterId}
            placeholder={filterObj.placeholder}
            localOptions={
              filterObj.localOptionsLocation ? _.get(store.getState(), filterObj.localOptionsLocation) : null
            }
            updateActiveFilters={this.updateActiveFilters.bind(this)}
            preventAutoFocus={preventAutoFocus} //when user expands ALL, don't want all the menus to pop up
          />
        );
      case componentTypes.TEXT_INPUT:
        return (
          <div className="pt-1 text-sw-text">
            <TextInput
              label="Shipper"
              initialValue={hasSelection ? defaultValues : ''}
              placeholder={filterObj?.placeholder}
              onChange={(e) => this.performSearchWithDebounce(e.target.value, filterObj)}
            />
          </div>
        );
      case componentTypes.CUSTOM_SELECTION:
        return (
          <div className="pt-1 text-sw-text">
            <CustomSelection
              defaultValues={hasSelection ? defaultValues : null}
              fieldName={filterObj.id}
              updateActiveFilters={this.updateActiveFilters.bind(this)}
            />
          </div>
        );
    }
  }

  performSearchWithDebounce = debounce(
    (searchTerm, filterObj) => this.updateActiveFilters(filterObj.performSearch(searchTerm)),
    300
  );

  /**
   * Clear the selection from a specific filter
   */
  clearFilterSelection(filterObject) {
    const filterId = this.getModifiedFilterId(filterObject);
    const {correspondingFilters} = this.props;
    const obj = {};
    obj[filterId] = null;
    if (correspondingFilters && correspondingFilters[filterObject]) {
      correspondingFilters[filterObject].forEach((key) => {
        obj[key] = null;
      });
    }
    this.updateActiveFilters(obj);
  }

  /**
   * Expand all filters
   */
  expandAllFilters() {
    const {filterableColumns, filterMapping} = this.props;
    this.setState({
      expandedFilters: filterableColumns.map((column) => filterMapping[column]?.id)
    });
  }

  /**
   * Collaps all filters
   */
  collapseAllFilters() {
    this.setState({expandedFilters: []});
  }

  /**
   * Clear the selections from all filters
   */
  clearAllFilters() {
    this.setState({
      activeFilters: {},
      selectedFilter: null,
      modifiedFromOriginal: false,
      selectedColumns: this.props.defaultColumns
    });
    this.props.selectParentFilter(null);
  }

  /**
   * Update the active filters on the data when a filter is changed
   * and inform the parent component to reload
   */
  updateActiveFilters(filterObject) {
    const {activeFilters} = this.state;
    const {correspondingFilters} = this.props;

    //if this was removing the only value from this filter, do a few things:
    if (
      (Array.isArray(Object.values(filterObject)[0]) && Object.values(filterObject)[0].length === 0) ||
      Object.values(filterObject)[0] === null
    ) {
      //if there are no other filters selected... do something?
    }
    Object.keys(filterObject).forEach((filterKey) => {
      if (correspondingFilters && correspondingFilters[filterKey]) {
        //ensure any corresponding filters for this filter are removed first before applying new settings
        correspondingFilters[filterKey].forEach((key) => {
          activeFilters[key] = null;
        });
      }
    });

    const newFilters = Object.assign(activeFilters, filterObject);
    //step through each filter in the newfilters object and delete any fields that are simply null or empty;
    Object.keys(newFilters).forEach((key) => {
      if (!newFilters[key] || (Array.isArray(newFilters[key]) && newFilters[key].length === 0)) {
        delete newFilters[key];
      }
    });
    this.setState({newFilters, modifiedFromOriginal: true});
    this.props.applyFiltersToParent(newFilters);
    this.handleResize();
  }

  handleChangeFilterModifier = ({filterId, modifier = null}) => {
    const prevModifier = this.state.filterModifiers?.[filterId] || null;
    //if modifier selection did not change
    if (prevModifier === modifier) {
      return;
    }
    this.setState({filterModifiers: {...this.state.filterModifiers, [filterId]: modifier}}, () => {
      const {activeFilters} = this.state;
      const prevFilterId = prevModifier ? filterId + prevModifier : filterId;
      const prevFilters = activeFilters[prevFilterId];
      //if modifier was changed but there are no current active filter selections
      if (!prevFilters) {
        return;
      }
      const filterObject = {
        [modifier ? filterId + modifier : filterId]: prevFilters
      };
      delete activeFilters[prevFilterId];

      const newFilters = Object.assign(activeFilters, filterObject);
      //step through each filter in the newfilters object and delete any fields that are simply null or empty;
      Object.keys(newFilters).forEach((key) => {
        if (!newFilters[key] || (Array.isArray(newFilters[key]) && newFilters[key].length === 0)) {
          delete newFilters[key];
        }
      });
      this.setState({newFilters, modifiedFromOriginal: true});
      this.props.applyFiltersToParent(newFilters);
      this.handleResize();
    });
  };

  handleSelectFilter(filterId) {
    const {selectedFilter} = this.state;
    if (selectedFilter === filterId) {
      this.setState({selectedFilter: null, modifiedFromOriginal: false});
      this.props.selectParentFilter(null);
    } else {
      const selectedFilter = this.props.myViews.filter((e) => e.id === filterId)[0];
      //update the columns
      const selectedColumns = selectedFilter.config.columns
        .filter((d) => !d.startsWith('-'))
        .filter((s) => !this.props.lockedColumns.includes(s));
      this.setState({
        selectedFilter: filterId,
        selectedColumns: selectedColumns,
        modifiedFromOriginal: false
      });
      //inform parent component to send down new default values for all the filters
      this.props.selectParentFilter(filterId);
    }
  }

  /**
   * Handler for when dropping item
   * @param  {Object} result
   */
  handleDragEnd(result) {
    if (result.destination) {
      const columns = [...this.state.selectedColumns];
      const [movedItem] = columns.splice(result.source.index, 1);

      columns.splice(result.destination.index, 0, movedItem);

      this.setState({
        selectedColumns: columns.filter((e) => !this.props.lockedColumns.includes(e)),
        modifiedFromOriginal: true
      });
      this.updateParentView(columns);
    }
  }

  /**
   * Toggle column visibility
   * @param  {Number} id of column
   */
  handleToggleColumn(columnId) {
    const selectedColumns = [...this.state.selectedColumns];

    if (!selectedColumns.includes(columnId)) {
      selectedColumns.push(columnId);
    } else {
      selectedColumns.splice(selectedColumns.indexOf(columnId), 1);
    }
    //pass this change back up to the parent;
    this.updateParentView(selectedColumns);

    this.setState({
      selectedColumns: selectedColumns.filter((e) => !this.props.lockedColumns.includes(e)),
      modifiedFromOriginal: true
    });
    //filter out any locked columns that are always selected
  }

  updateParentView(selectedColumns) {
    //always pass locked columns back up to the parent as they are not toggleable
    this.props.updateParentView([...this.props.lockedColumns, ...selectedColumns]);
    //reorder the parent list view with the changes
  }

  /*
   * Displays the form to save the filter, and prefills the filter name if one is selected
   */
  showSaveForm() {
    let defaultFilterName = '';
    if (this.state.selectedFilter) {
      defaultFilterName = this.props.myViews.filter((e) => e.id === this.state.selectedFilter)[0]?.name;
    }

    this.setState({showSaveForm: true, savedFilterName: defaultFilterName});
    //always scroll to the top of the container to show the form
    const container = document.querySelector('.tableFilters__container');
    container.scrollTop = 0;
  }

  renderFilterView() {
    const {filterableColumns, filterMapping} = this.props;
    return (
      <div>
        {filterableColumns &&
          filterableColumns.length &&
          [...filterableColumns].sort().map((column, index) => {
            if (filterMapping[column]) {
              return <Fragment key={index}>{this.renderFilter(filterMapping[column])}</Fragment>;
            }
          })}
      </div>
    );
  }

  renderColumnView() {
    const {columns, selectedColumns} = this.state;

    const unselectedColumns = columns
      .filter((col) => !selectedColumns.includes(col))
      .filter((e) => !this.props.lockedColumns.includes(e))
      .sort(); // sorts alphabetically

    return (
      <div className="tableFilters__columnView">
        <p>Drag to reorder columns</p>
        <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={`draggable-item ${snapshot.isDraggingOver ? 'dragover' : ''}`}>
                {selectedColumns
                  .filter((e) => !this.props.lockedColumns.includes(e))
                  .map((column, index) => (
                    <Draggable key={column} index={index} draggableId={column}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="draggable-option"
                          style={{
                            background: snapshot.isDragging ? '#47484a' : '#58595b',
                            ...provided.draggableProps.style
                          }}
                        >
                          <span className="flex gap-0.5">
                            <span className="drag-icon">&#9776;</span>
                            <span className="max-w-[224px] truncate">{this.props.columnNamesById[column]}</span>
                          </span>
                          <Toggle
                            size={'xs'}
                            on={<span>&nbsp;&nbsp;&nbsp;</span>}
                            off={<span>&nbsp;&nbsp;&nbsp;</span>}
                            active={this.state.selectedColumns.includes(column)}
                            onClick={() => this.handleToggleColumn(column)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {unselectedColumns.map((column, index) => {
          return (
            <div key={index} className="draggable-option unselected">
              <span className="flex gap-0.5">
                <span className="drag-icon">&#9776;</span>
                <span className="max-w-[224px] truncate">{this.props.columnNamesById[column]}</span>
              </span>
              <Toggle
                size={'xs'}
                on={<span>&nbsp;&nbsp;&nbsp;</span>}
                off={<span>&nbsp;&nbsp;&nbsp;</span>}
                active={this.state.selectedColumns.includes(column)}
                onClick={() => this.handleToggleColumn(column)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  toggleView() {
    this.setState({mode: this.state.mode === 'filters' ? 'columns' : 'filters'});
  }

  submitForm(e) {
    e.preventDefault();
    this.props.saveView(
      this.state.savedFilterName,
      this.state.activeFilters,
      this.state.selectedColumns,
      this.state.setAsDefault,
      this.state.renameOnly ? this.state.selectedFilter : null
    );
  }

  renderSaveForm() {
    return (
      <form onSubmit={this.submitForm.bind(this)} className="tableFilters__saveFilterForm">
        <FormControl
          value={this.state.savedFilterName}
          placeholder="Enter a name for this dashboard"
          onChange={(e) => this.setState({savedFilterName: e.target.value})}
          onKeyPress={(e) => /*handle enter key */ {
            if (e.key === 'Enter') {
              this.submitForm(e);
            }
          }}
        />
        <div className="tableFilters__saveFilterForm-buttons">
          <Button
            type="button"
            onClick={() => this.setState({savedFilterName: '', showSaveForm: false, setAsDefault: false})}
            bsStyle="default"
          >
            Cancel
          </Button>
          <Button type="submit" bsStyle="primary" disabled={!this.state.savedFilterName}>
            Save
          </Button>
        </div>
        <Checkbox
          className={this.state.setAsDefault ? 'checked' : ''}
          checked={this.state.setAsDefault ? true : false}
          onChange={() => this.setState({setAsDefault: !this.state.setAsDefault})}
        >
          &nbsp;Set as Default View
        </Checkbox>
      </form>
    );
  }

  renameView(view) {
    this.hideViewOptions(this);
    this.setState({showSaveForm: true, savedFilterName: view.name, renameOnly: true});
  }

  /*
   * Render the popup modal for the view with options to set as default or delete
   */
  renderViewOptions(view) {
    const {isDeletingView} = this.state;

    if (isDeletingView) {
      return (
        <ClickAwayListener onClickAway={this.hideViewOptions.bind(this)}>
          <div className="order-tooltip">
            <h3>Delete View</h3>
            <p>Are you sure you want to delete</p>
            <p>{view.name}</p>

            <div className="order-tooltip-footer">
              <button className="btn btn-secondary" onClick={this.hideViewOptions.bind(this)}>
                Cancel
              </button>
              <button className="btn btn-primary" onClick={this.handleDeleteView.bind(this, view)}>
                Confirm
              </button>
            </div>
          </div>
        </ClickAwayListener>
      );
    }
    return (
      <ClickAwayListener onClickAway={this.hideViewOptions.bind(this)}>
        <div className="viewOptions">
          <p className="viewOptions__option" onClick={this.setViewAsDefault.bind(this, view)}>
            {view.is_default ? 'Remove as Default' : 'Set as Default'}
          </p>
          <p className="viewOptions__option" onClick={this.renameView.bind(this, view)}>
            Rename
          </p>
          <p className="viewOptions__option" onClick={() => this.setState({isDeletingView: true})}>
            Delete
          </p>
        </div>
      </ClickAwayListener>
    );
  }

  showViewOptions(view) {
    if (this.state.selectedFilter !== view) {
      this.handleSelectFilter(view);
    }
    this.setState({showViewOptions: view, isDeletingView: false});
  }

  hideViewOptions() {
    this.setState({showViewOptions: {}, isDeletingView: false});
  }

  handleDeleteView(view) {
    this.props.deleteView(view);
    this.hideViewOptions();
  }

  setViewAsDefault(view) {
    //save the view with the default selection as true
    this.props.saveView(
      view.name,
      this.props.parseFilters(view.config.filters, 'fromParent', true),
      view.config.columns,
      !view.is_default
    );
    this.hideViewOptions();
  }

  renderMyViews() {
    const {myViews, viewLabel} = this.props;
    const {showViewOptions, showSaveSuccess} = this.state;

    const isExpanded = true; // just always showing this section
    return (
      <div className="tableFilters__myViews">
        {(myViews && myViews.length > 0) || this.state.showSaveForm ? (
          <div className="tableFilters__myViews-header">
            <span>{viewLabel}</span>
            <span
              className="tableFilters__filter-toggle actionLink"
              onClick={() => this.handleToggleExpandFilter('myViews')}
            >
              {/*isExpanded ? <i className="flaticon-substract" /> : <i className="flaticon-add" />*/}
            </span>
          </div>
        ) : null}

        {isExpanded && myViews && myViews.length > 0 && !this.state.showSaveForm
          ? myViews.map((view, i) => {
              const isSelectedFilter = this.state.selectedFilter === view.id ? 'selected' : '';
              const tooltipMessage = this.renderViewOptions(view);
              const isDefault = view.is_default;
              return (
                <div className={`tableFilters__myViews-container ${isSelectedFilter}`} key={i}>
                  <span className="tableFilters__myViews-filter" onClick={() => this.handleSelectFilter(view.id)}>
                    {view.name} {isDefault && <i title="Default" className="flaticon-star" />}
                  </span>
                  {showSaveSuccess && this.state.selectedFilter === view.id ? (
                    <i className="flaticon-check_filled" />
                  ) : (
                    <OrdersTooltip
                      disableHoverListener
                      disableFocusListener
                      open={showViewOptions === view.id}
                      placement="bottom"
                      title={tooltipMessage}
                      PopperProps={{className: 'md-tooltip'}}
                      TransitionProps={{timeout: {enter: 100, exit: 0}}}
                      TransitionComponent={Zoom}
                    >
                      <span onClick={this.showViewOptions.bind(this, view.id)} className="tableFilters__myViews-delete">
                        <span />
                        <span />
                        <span />
                      </span>
                    </OrdersTooltip>
                  )}
                </div>
              );
            })
          : null}
      </div>
    );
  }

  renderExpandedView() {
    const filterHeaderBar = (
      <div className="tableFilters__header">
        <span>
          <span
            className={
              this.state.mode === 'filters'
                ? 'tableFilters__header-option showAsLink active'
                : 'tableFilters__header-option showAsLink '
            }
            onClick={this.state.mode === 'columns' ? this.toggleView.bind(this) : null}
          >
            Filters
          </span>
          <span
            className={
              this.state.mode === 'columns'
                ? 'tableFilters__header-option showAsLink active'
                : 'tableFilters__header-option showAsLink'
            }
            onClick={this.state.mode === 'filters' ? this.toggleView.bind(this) : null}
          >
            Columns
          </span>
        </span>
        <span className="tableFilters__header-actions">
          {((Object.keys(this.state.activeFilters).length > 0 && this.state.mode === 'filters') ||
            (this.state.mode === 'columns' && this.state.modifiedFromOriginal)) &&
            this.state.modifiedFromOriginal && (
              <Fragment>
                <span className="showAsLink" onClick={this.clearAllFilters.bind(this)}>
                  Clear
                </span>

                <span className="showAsLink" onClick={this.showSaveForm.bind(this)}>
                  Save
                </span>
              </Fragment>
            )}
          <span onClick={this.closeFilter.bind(this)}>
            <i className="flaticon-multiply" />
          </span>
        </span>
      </div>
    );

    const filterFooterBar = (
      <div className="tableFilters__footer">
        <div onClick={this.expandAllFilters.bind(this)} id="expandAllButton" className="tableFilters__footer-button">
          <span>Expand All</span>
        </div>
        <div
          onClick={this.collapseAllFilters.bind(this)}
          id="collapseAllButton"
          className="tableFilters__footer-button"
        >
          <span>Collapse All</span>
        </div>
      </div>
    );

    return (
      <Fragment>
        {filterHeaderBar}
        <div className={this.state.mode === 'filters' ? 'tableFilters__container' : 'tableFilters__container columns'}>
          {this.state.mode === 'filters' && this.props.myViews && this.renderMyViews()}
          {this.state.showSaveForm && this.renderSaveForm()}

          {this.state.mode === 'filters' ? this.renderFilterView() : this.renderColumnView()}
        </div>
        {this.state.mode === 'filters' && filterFooterBar}
      </Fragment>
    );
  }

  handleResize() {
    let readbackHeight = 0;
    let containerHeight = 0;
    if (this.tableReadbackRef) {
      readbackHeight = this.tableReadbackRef.clientHeight;
    }
    if (this.tableContainerRef && this.tableContainerRef.current && this.tableContainerRef.current.clientHeight) {
      containerHeight = this.tableContainerRef.current.clientHeight;
    }
    if (readbackHeight !== this.tableReadbackHeight || containerHeight !== this.tableContainerHeight) {
      //recalculate the max height
      const maxHeight = containerHeight - readbackHeight;
      this.tableReadbackHeight = readbackHeight;
      this.tableContainerHeight = containerHeight;
      if (maxHeight > 0) {
        document.querySelector('.ReactTable').style.height = maxHeight + 'px';
      }
    }
  }

  render() {
    const {expandedView, selectedFilter, activeFilters} = this.state;
    const {tableComponent} = this.props;
    const selectedFilterIcon =
      selectedFilter || Object.keys(this.state.activeFilters).length > 0 ? 'selectedFilter' : '';
    const showReadbackBar = selectedFilter || Object.keys(this.state.activeFilters).length > 0;
    return (
      <Fragment>
        <div className={expandedView ? 'tableFilters expanded' : 'tableFilters'}>
          {!expandedView && (
            <div className="tableFilters__filterButton">
              <i
                className={`${selectedFilterIcon} flaticon-funnel`}
                title="filterFunnelIcon"
                onClick={() => this.setState({expandedView: true, mode: 'filters'})}
              />
            </div>
          )}
          {expandedView && this.renderExpandedView()}
        </div>
        <div ref={this.tableContainerRef} className="tableFilters__tableContainer">
          {showReadbackBar && (
            <TableReadback
              readbackRef={(el) => (this.tableReadbackRef = el)}
              correspondingFilters={this.props.correspondingFilters}
              currentFilters={activeFilters}
              selectedFilter={this.state.selectedFilter}
              myViews={this.props.myViews}
              parseFiltersForReadback={this.props.parseFiltersForReadback}
              clearAll={this.clearAllFilters.bind(this)}
            />
          )}
          {tableComponent}
        </div>
      </Fragment>
    );
  }
}

export default withFlags('tripManagementPredictedEta')(TableFilters);
