export const scheduleTimeOptions = [
  {id: 1, name: 'At'},
  {id: 2, name: 'Open'},
  {id: 3, name: 'Before'},
  {id: 4, name: 'After'}
];

export enum ScheduleTimeEnum {
  At = 1,
  Open = 2,
  Before = 3,
  After = 4
}

export const startTimeWindowLabels = {
  '1': 'At Time',
  '2': 'Start Time',
  '4': 'After Time'
};

export const endTimeWindowLabels = {
  '1': null,
  '2': 'Stop Time',
  '3': 'Before Time'
};
