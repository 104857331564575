import {LoadboardType} from '@shipwell/tabula-sdk';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {deleteLoadboard} from 'App/api/loadboards';
import {LOADBOARDS_KEY} from 'App/data-hooks/queryKeys';

type DeleteLoadboardPayload = {
  loadboardType: LoadboardType;
  isUser?: boolean;
  userId?: string;
};

const useDeleteLoadboard = (
  mutationOptions?: UseMutationOptions<Awaited<ReturnType<typeof deleteLoadboard>>, unknown, DeleteLoadboardPayload>
) => {
  const queryClient = useQueryClient();

  return useMutation<Awaited<ReturnType<typeof deleteLoadboard>>, unknown, DeleteLoadboardPayload>(
    ({loadboardType, isUser, userId}) => deleteLoadboard(loadboardType, isUser, userId),
    {
      onSettled: () => queryClient.invalidateQueries([LOADBOARDS_KEY]),
      ...mutationOptions
    }
  );
};

export default useDeleteLoadboard;
