import {useQuery} from '@tanstack/react-query';
import {
  StorageServiceTypeEnum,
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  TerminalFeeServiceTypeEnum,
  TransloadServiceTypeEnum
} from '@shipwell/corrogo-sdk';
import {ServiceProvider} from '@shipwell/backend-core-sdk';
import StageCard from 'App/containers/shipments/components/DashboardSummary/StageCard';
import SequenceNumber from 'App/containers/shipments/components/DashboardSummary/SequenceNumber';
import StageActions from 'App/containers/shipments/components/DashboardSummary/StageActions';
import {ServiceCustomerDisplay} from 'App/containers/shipments/components/DashboardSummary/CustomerDisplay';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import Loader from 'App/common/shipwellLoader';
import {useRetrieveService} from 'App/containers/shipments/components/DashboardSummary/hooks/useServices';
import {toCurrency} from 'App/utils/grammar';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {SERVICE_PROVIDER_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getServiceProviderPromise} from 'App/api/serviceProviders/typed';
import {FinancialTenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/FinancialTenderingUserPermissionFallback';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';

function ServiceView({
  shipmentId,
  serviceId,
  serviceType,
  isActive,
  sequenceNumber
}: {
  shipmentId?: string;
  serviceId: string;
  serviceType:
    | TransloadServiceTypeEnum
    | TerminalFeeServiceTypeEnum
    | StorageServiceTypeEnum
    | OceanTrackingServiceTypeEnum
    | RailTrackingServiceTypeEnum
    | undefined;
  isActive?: boolean;
  sequenceNumber?: number;
}) {
  const serviceQuery = useRetrieveService(serviceType, serviceId);
  const serviceProviderId = serviceQuery.data?.service_provider.provider_id || '';
  const serviceProviderQuery = useQuery<ServiceProvider>(
    [SERVICE_PROVIDER_QUERY_KEY, serviceProviderId],
    async () => {
      const result = await getServiceProviderPromise(serviceProviderId);
      return result.data;
    },
    {enabled: !!serviceProviderId}
  );

  if (serviceQuery.isInitialLoading) {
    return (
      <StageCard isActive={isActive}>
        <Loader loading />
      </StageCard>
    );
  }

  const city = serviceQuery.data?.location.address.locality;
  const state = serviceQuery.data?.location.address.region;
  const lane = city && state ? `${city}, ${state}` : '--';
  const pickupDate = serviceQuery.data?.actual_datetimes?.start
    ? formatDateTime(serviceQuery.data.actual_datetimes.start)
    : null;

  const deliveryDate = serviceQuery.data?.actual_datetimes?.end
    ? formatDateTime(serviceQuery.data.actual_datetimes.end)
    : null;

  const customData = serviceQuery.data?.custom_data as {shipwell_custom_data?: {customer_tenant_id?: string}};
  const customerTenantId = customData?.shipwell_custom_data?.customer_tenant_id;

  return (
    <StageCard isActive={isActive}>
      <div className="flex flex-col gap-2 whitespace-normal">
        <div className="flex w-full items-center">
          <SequenceNumber isActive={isActive}>{sequenceNumber}</SequenceNumber>
          <div className="flex text-sm">
            <span className="mx-2 font-bold">
              {startCaseToLower(serviceType)} &middot; {serviceQuery.data?.reference_id}
            </span>
          </div>
          <div className="ml-auto flex items-center">
            <StageActions shipmentId={shipmentId} resourceId={serviceId} serviceType={serviceType} />
          </div>
        </div>
        <div className="flex flex-col gap-0 text-xs">
          {customerTenantId ? <ServiceCustomerDisplay customerId={customerTenantId} /> : null}
          <div>
            <span className="font-bold">Provider: </span>
            {serviceQuery.data?.service_provider.name || '--'}
            {serviceProviderQuery.data?.status ? ` (${serviceProviderQuery.data?.status})` : ''}
          </div>
          <div>
            <span className="font-bold">Lane: </span>
            {lane}
          </div>
          <div>
            <span className="font-bold">Dates: </span>
            {pickupDate && deliveryDate ? (
              <>
                {pickupDate}
                <> &ndash; </>
                {deliveryDate}
              </>
            ) : (
              '--'
            )}
          </div>
          <FinancialTenderingUserPermissionFallback permissions={[VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]}>
            <div>
              <span className="font-bold">Carrier Financials: </span>
              {serviceQuery.data?.provider_rate
                ? toCurrency(
                    Number(serviceQuery.data.provider_rate.value),
                    serviceQuery.data.provider_rate.currency_code
                  )
                : '--'}
            </div>
            <div>
              <span className="font-bold">Customer Financials: </span>
              {serviceQuery.data?.customer_rate
                ? toCurrency(
                    Number(serviceQuery.data.customer_rate.value),
                    serviceQuery.data.customer_rate.currency_code
                  )
                : '--'}
            </div>
          </FinancialTenderingUserPermissionFallback>
        </div>
      </div>
    </StageCard>
  );
}
export default ServiceView;
