import {REQUEST_USER_PROFILE_PENDING, REQUEST_USER_PROFILE_SUCCESS, REQUEST_USER_PROFILE_ERROR} from './actions';

/** Initial state */
export const DEFAULT_STATE = {
  authenticated: false,
  error: false,
  isUpdating: false,
  isFetching: false,
  user: {}
};

export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_USER_PROFILE_PENDING:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: payload,
        authenticated: true
      };
    case REQUEST_USER_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};
