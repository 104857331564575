import moment from 'moment';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  GET_SHIPMENT_BY_ID,
  MANUAL_RATE_FINANCIALS,
  GET_EQUIPMENT_TYPES,
  GET_AUTOMATED_EVENTS,
  SELECT_AUTOMATED_EVENT,
  GET_SHIPMENT_MODES,
  GET_SERVICE_LEVELS,
  GET_REP_ROLES,
  GET_NMFC_CODES,
  GET_HAZMAT_CODES,
  GET_PACKAGE_TYPES,
  GET_SHIPMENT_MESSAGES,
  SELECT_SHIPMENT,
  PUT_SHIPMENT_DETAILS,
  SELECT_FINANCIALS,
  CLEAR_RATE_TABLE,
  SELECT_RATE_TABLE,
  DELETE_SHIPMENT_LINEITEM,
  DELETE_SHIPMENT_STOP,
  POST_NEW_LINEITEM,
  POST_NEW_STOP,
  GET_SHIPMENT_TIMELINE,
  NEW_SHIPMENT_TIMELINE_EVENT,
  EDIT_SHIPMENT_TIMELINE_EVENT,
  POST_SHIPMENT_TIMELINE_EVENT,
  PUT_TIMELINE_EVENT,
  DELETE_SHIPMENT_TIMELINE_EVENT,
  NEW_TRUCK_LOCATION,
  CANCEL_SHIPMENT,
  SHARE_LINK,
  CLEAR_SHIPMENT_STATE,
  GET_NOTES,
  NOTES_LOADING,
  CLEAR_NOTES,
  FETCH_BREADCRUMBS,
  CLEAR_BREADCRUMBS,
  FETCH_STOPS,
  STOPS_LOADING,
  FETCH_STOP,
  CLEAR_STOP,
  FETCH_REPS,
  POST_REPS,
  PUT_REPS,
  GET_SHIPMENT_CARRIER
} from 'App/actions/types';
import {formatHrMinSec} from 'App/utils/globals';

const INITIAL_STATE = {
  breadcrumbs: [],
  breadcrumbsCount: 0,
  one: [],
  addingStop: false,
  isLoading: true,
  areNotesLoading: false,
  isTimelineLoading: true,
  messages: null,
  timelineEvents: null,
  bill_to_override: null,
  selectedStop: {
    stops: [
      {
        ordinal_index: 0,
        accessorials: [],
        location: {
          address: {},
          dock_hours_start: moment('8:00:00', formatHrMinSec).toDate(),
          dock_hours_end: moment('18:00:00', formatHrMinSec).toDate(),
          point_of_contacts: [],
          notes: null,
          location_type: {
            id: 1,
            name: 'Business (with dock or forklift)'
          }
        },
        is_pickup: true,
        is_dropoff: false,
        instructions: null
      }
    ]
  },
  selectedLineitem: {
    line_items: [
      {
        length_unit: 'IN',
        length: 40,
        width: 48,
        package_type: 'PLT'
      }
    ]
  },
  selectedFinancials: [{unit_quantity: 1, category: ChargeCategory.Lh, charge_code: 'LHS'}],
  selectedRateTable: null,
  notes: null,
  carrier: null,
  shipmentRepRoles: null,
  stops: null,
  stop: null,
  isStopsLoading: false,
  automatedEvents: [],
  hasFutureScheduledCalls: false,
  scheduleCallInitial: {
    call_time: moment().hour(9).minute(0).second(0).format(formatHrMinSec),
    call_date: moment().format('YYYY-MM-DD'),
    repeat_call: false,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(2, 'days').format('YYYY-MM-DD')
  },
  pollShipmentDetails: null,
  pristineShipment: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHIPMENT_BY_ID:
      const response = JSON.parse(JSON.stringify(action.payload));
      const metadata = response.metadata;
      let financials = [];
      let customerMarkup = null;
      let providerMarkup = null;
      let markup = '';
      let selectedRFQ = null;
      let selectedQuote = null;

      // check for NaN
      if (isNaN(response.temperature_lower_limit)) {
        response.temperature_lower_limit = null;
      }
      if (isNaN(response.temperature_upper_limit)) {
        response.temperature_upper_limit = null;
      }

      let bill_to_override = {};

      if (metadata && metadata.bill_to_override) {
        bill_to_override = {
          bill_to_override: JSON.parse(JSON.stringify(metadata.bill_to_override))
        };
      }
      if (metadata && metadata.bill_to_override && metadata.bill_to_override.company_address) {
        bill_to_override.bill_to_override.company_address = {
          formatted_address: metadata.bill_to_override.company_address
        };
      }

      if (response.drayage_estimated_arrival_date) {
        response.drayage_estimated_arrival_date = moment(response.drayage_estimated_arrival_date).isValid()
          ? moment.utc(response.drayage_estimated_arrival_date).format('YYYY-MM-DD')
          : null;
      }
      if (response.drayage_release_date) {
        response.drayage_release_date = moment(response.drayage_release_date).isValid()
          ? moment.utc(response.drayage_release_date).format('YYYY-MM-DD')
          : null;
      }
      if (response.drayage_last_free_date) {
        response.drayage_last_free_date = moment(response.drayage_last_free_date).isValid()
          ? moment.utc(response.drayage_last_free_date).format('YYYY-MM-DD')
          : null;
      }
      if (response.drayage_container_return_date) {
        response.drayage_container_return_date = moment(response.drayage_container_return_date).isValid()
          ? moment.utc(response.drayage_container_return_date).format('YYYY-MM-DD')
          : null;
      }

      // check customer_markup
      if (response.most_recently_awarded_quote && response.most_recently_awarded_quote.customer_markup) {
        customerMarkup = response.most_recently_awarded_quote.customer_markup;
      }
      // assign financials
      if (
        response.most_recently_awarded_quote &&
        response.most_recently_awarded_quote.charge_line_items &&
        response.most_recently_awarded_quote.charge_line_items.length > 0
      ) {
        selectedRFQ = response.most_recently_awarded_quote.rfq;
        selectedQuote = response.most_recently_awarded_quote.id;
        financials = response.most_recently_awarded_quote.charge_line_items;
        financials = financials.filter((e) => e.is_provider_markup === false);
      }

      // check for provider_markup
      if (
        response.most_recently_awarded_quote &&
        response.most_recently_awarded_quote.charge_line_items &&
        response.most_recently_awarded_quote.charge_line_items.length > 0 &&
        response.most_recently_awarded_quote.charge_line_items.filter((e) => e.is_provider_markup === true).length > 0
      ) {
        providerMarkup = response.most_recently_awarded_quote.charge_line_items.filter(
          (e) => e.is_provider_markup === true
        );
      }

      if (customerMarkup) {
        markup = customerMarkup.toString();
      } else if (providerMarkup) {
        markup = providerMarkup[0].unit_amount.toString();
      } else {
        markup = '0';
      }

      //transform references to specific form
      const shipment_references = {};
      shipment_references.pickup_number = JSON.parse(JSON.stringify(response.pickup_number));
      shipment_references.bol_number = JSON.parse(JSON.stringify(response.bol_number));
      shipment_references.pro_number = JSON.parse(JSON.stringify(response.pro_number));
      shipment_references.purchase_order_number = JSON.parse(JSON.stringify(response.purchase_order_number));
      shipment_references.customer_reference_number = JSON.parse(JSON.stringify(response.customer_reference_number));
      shipment_references.carrier_reference_code = JSON.parse(JSON.stringify(response.carrier_reference_code));
      shipment_references.drayage_booking_number = response.drayage_booking_number;
      shipment_references.drayage_chassis_number = response.drayage_chassis_number;
      shipment_references.drayage_container_number = response.drayage_container_number;
      shipment_references.drayage_carrier_scac_code = response.drayage_carrier_scac_code;
      shipment_references.drayage_house_bol_number = response.drayage_house_bol_number;
      shipment_references.drayage_seal_number = response.drayage_seal_number;
      shipment_references.tracking_number = response.tracking_number;
      shipment_references.custom_data = response.custom_data;
      shipment_references.rail_car_number = response.rail_car_number;
      shipment_references.rail_car_status = response.rail_car_status;

      let vendor;
      if (response.relationship_to_vendor) {
        vendor = response.relationship_to_vendor;
        response.vendor = {value: vendor.vendor.id, label: {shipwell_vendor: vendor.vendor}};
        response.vendor_point_of_contact = response.relationship_to_vendor.vendor_point_of_contact;
      }
      let carrier;
      if (response.current_carrier) {
        carrier = response.current_carrier;
        if (
          //if shipment is LTL/VLTL instant rate, assign the end carrier as the vendor
          response.mode &&
          (response.mode.code === 'LTL' || response.mode.code === 'VLTL') &&
          response.most_recently_awarded_quote?.source_type === 'INSTANT' &&
          response.most_recently_awarded_quote?.mode?.id !== 1
        ) {
          response.vendor = {value: carrier.id, label: {shipwell_vendor: carrier}};
        }
      }

      //power unit uses a slightly different name field
      if (
        response.equipment_config &&
        response.equipment_config.power_unit &&
        response.equipment_config.power_unit.name
      ) {
        response.equipment_config.power_unit_name = {
          value: response.equipment_config.power_unit.name,
          label: response.equipment_config.power_unit
        };
      }
      // trailer also uses a slightly different name field, similar to power unit
      if (response.equipment_config?.trailer?.name) {
        response.equipment_config.trailer_name = response.equipment_config.trailer.name;
      }

      if (response.total_declared_value) {
        response.manual_total_value = true;
      }

      if (response.total_quantity_override) {
        response.manual_quantity = true;
      }

      return {
        ...state,
        one: response,
        carrier: carrier,
        vendor: vendor,
        shipment_references: shipment_references,
        bill_to_override: bill_to_override,
        markup: markup,
        financials: financials,
        selectedRFQ: selectedRFQ,
        selectedQuote: selectedQuote,
        isLoading: false,
        pristineShipment: action.payload,
        pollShipmentDetails: action.payload
      };

    //enable override of markup and financials info if creating new manual rate
    case MANUAL_RATE_FINANCIALS:
      return {
        ...state,
        markup: action.payload.markup,
        financials: action.payload.financials
      };

    case GET_EQUIPMENT_TYPES:
      return {
        ...state,
        equipmentTypes: action.payload
      };

    case GET_AUTOMATED_EVENTS:
      //determine if there are scheduled calls in the future on this shipment
      const scheduledCalls = action.payload.results.filter((e) => !e.confirmed_departure_at && e.scheduled_for);
      const hasFutureScheduledCalls =
        scheduledCalls.filter((c) => moment(c.scheduled_for).isAfter(moment())).length > 0 ? true : false;

      return {
        ...state,
        automatedEvents: action.payload,
        hasFutureScheduledCalls: hasFutureScheduledCalls
      };

    case SELECT_AUTOMATED_EVENT:
      if (!action.payload) {
        return {
          ...state,
          scheduleCallInitial: INITIAL_STATE.scheduleCallInitial
        };
      }
      return {
        ...state,
        scheduleCallInitial: action.payload
      };

    case GET_SHIPMENT_MODES:
      return {
        ...state,
        shipmentModes: action.payload
      };

    case GET_SERVICE_LEVELS:
      return {
        ...state,
        serviceLevels: action.payload
      };

    case GET_REP_ROLES:
      return {
        ...state,
        shipmentRepRoles: action.payload
      };

    case GET_NMFC_CODES:
      return {
        ...state,
        nmfcCodes: action.payload
      };

    case GET_HAZMAT_CODES:
      return {
        ...state,
        hazmatCodes: action.payload
      };

    case GET_PACKAGE_TYPES:
      return {
        ...state,
        packageTypes: action.payload
      };

    case GET_SHIPMENT_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };

    case SELECT_SHIPMENT:
      return {
        ...state,
        one: action.payload
      };

    case PUT_SHIPMENT_DETAILS:
      return {
        ...state,
        one: action.payload,
        isLoading: false,
        pristineShipment: action.payload,
        pollShipmentDetails: action.payload
      };

    case SELECT_FINANCIALS:
      return {
        ...state,
        selectedFinancials: action.payload
      };

    case CLEAR_RATE_TABLE:
      return {
        ...state,
        selectedRateTable: {}
      };

    case SELECT_RATE_TABLE:
      return {
        ...state,
        selectedRateTable: action.payload
      };

    case DELETE_SHIPMENT_LINEITEM:
      return {
        ...state,
        one: action.payload,
        isLoading: false
      };

    case DELETE_SHIPMENT_STOP:
      return {
        ...state,
        isLoading: false
      };

    case POST_NEW_LINEITEM:
      return {
        ...state,
        addingLineItem: false,
        selectedLineitem: INITIAL_STATE.selectedLineitem
      };

    case POST_NEW_STOP:
      return {
        ...state,
        addingStop: true,
        selectedStop: INITIAL_STATE.selectedStop
      };

    case GET_SHIPMENT_TIMELINE:
      return {
        ...state,
        timelineEvents: action.payload,
        isTimelineLoading: false
      };

    case NEW_SHIPMENT_TIMELINE_EVENT:
      return {
        ...state,
        selectedEvent: {
          description: null,
          event_type: 'BOOKED',
          occurred_at: new Date()
        }
      };

    case EDIT_SHIPMENT_TIMELINE_EVENT:
      return {
        ...state,
        selectedEvent: action.payload
      };

    case POST_SHIPMENT_TIMELINE_EVENT:
      return {
        ...state,
        isTimelineLoading: false
      };

    case PUT_TIMELINE_EVENT:
      return {
        ...state,
        isTimelineLoading: false
      };

    case DELETE_SHIPMENT_TIMELINE_EVENT:
      return {
        ...state
      };

    case NEW_TRUCK_LOCATION:
      return {
        ...state,
        newTruckLocation: {
          event_description: null,
          place_changed: null,
          update_time: new Date()
        }
      };

    case CANCEL_SHIPMENT:
      return {
        ...state
      };

    case SHARE_LINK:
      return {
        ...state,
        sharedLink: action.payload
      };

    case CLEAR_SHIPMENT_STATE: {
      return {
        ...state,
        isLoading: true,
        messages: null,
        notes: null,
        one: null,
        timelineEvents: null,
        carrier: null,
        bill_to_override: null,
        shipment_references: null,
        vendor: null,
        pristineShipment: null
      };
    }

    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
        areNotesLoading: false
      };

    case NOTES_LOADING:
      return {
        ...state,
        areNotesLoading: true
      };

    case CLEAR_NOTES:
      return {
        ...state,
        notes: null
      };

    case FETCH_BREADCRUMBS:
      if (action.meta.key < state.breadcrumbsCount) {
        return {
          ...state
        };
      }
      const sortedBreadcrumbs = action.payload.shipment_locations.sort((a, b) => {
        return moment(a.update_time).isSameOrBefore(moment(b.update_time)) ? -1 : 1;
      });
      return {
        ...state,
        breadcrumbs: sortedBreadcrumbs,
        breadcrumbsCount: action.meta.key
      };

    case CLEAR_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: [],
        breadcrumbsCount: 0
      };

    case FETCH_STOPS:
      return {
        ...state,
        stops: action.payload,
        isStopsLoading: false
      };

    case STOPS_LOADING:
      return {
        ...state,
        isStopsLoading: true
      };

    case FETCH_STOP:
      return {
        ...state,
        stop: action.payload,
        isStopsLoading: false
      };

    case CLEAR_STOP:
      return {
        ...state,
        stop: null
      };

    case FETCH_REPS:
      const results = action.payload.results;
      if (results.length > 0) {
        results.forEach((result) => {
          result.display_name = `${result.user.first_name} ${result.user.last_name}`;
          // update form requires user id
          result.role_name = result.role ? result.role.name : null;
          result.role = result.role ? result.role.id : null;
          result.user = result.user.id;
        });
      }
      return {
        ...state,
        reps: action.payload
      };

    case POST_REPS:
      return {
        ...state
      };

    case PUT_REPS:
      return {
        ...state
      };

    case GET_SHIPMENT_CARRIER:
      return {
        ...state,
        shipmentCarrier: action.payload
      };

    default:
      return state;
  }
}
