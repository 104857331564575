import {MouseEvent} from 'react';
import {Route, IndexRoute, Link, IndexRedirect} from 'react-router';
import {compose} from 'recompose';
import FacilityWrapper, {FacilitiesWithSidebar} from 'App/containers/facilities';
import FacilitiesList from 'App/containers/facilities/list';
import withFlags from 'App/utils/withFlags';
import withConditionalFallback from 'App/components/withConditionalFallback';
import FacilityDetails from 'App/containers/facilities/details';
import FacilityContacts from 'App/containers/facilities/contacts';
import FacilityAttributes from 'App/containers/facilities/attributes';
import FacilityLoadTypes from 'App/containers/facilities/loadTypes';
import FacilityDocks from 'App/containers/facilities/docks';
import {useFacilityPerms} from 'App/data-hooks';

export interface FacilitiesRouteProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const FacilitiesLink = ({onClick}: FacilitiesRouteProps) => {
  const {canView} = useFacilityPerms();

  return (
    <>
      {canView ? (
        <Link to="/facilities" activeClassName="active" onClick={onClick}>
          My Facilities
        </Link>
      ) : null}
    </>
  );
};

export const ConditionalFacilitiesLink = compose<FacilitiesRouteProps, FacilitiesRouteProps>(
  withFlags('fiDockScheduling'),
  withConditionalFallback(({fiDockScheduling}: {fiDockScheduling?: boolean}) => !fiDockScheduling, null)
)(FacilitiesLink);

export default (
  <Route component={FacilityWrapper}>
    <IndexRoute component={FacilitiesList} />
    <Route path="/facilities/:id" component={FacilitiesWithSidebar}>
      <IndexRedirect to="/facilities/:id/details" />
      <Route path="/facilities/:id/details" component={FacilityDetails} />
      <Route path="/facilities/:id/contacts" component={FacilityContacts} />
      <Route path="/facilities/:id/attributes" component={FacilityAttributes} />
      <Route path="/facilities/:id/docks" component={FacilityDocks} />
      <Route path="/facilities/:id/load-types" component={FacilityLoadTypes} />
    </Route>
  </Route>
);
