export const trimWhitespaceOnSubmit = (
  previousValue: string,
  proposedValue: string,
  onSubmit: (val: string) => void
) => {
  const trimmedString = proposedValue.trim();
  if (trimmedString === '') {
    return previousValue;
  }
  onSubmit(trimmedString);
  return trimmedString;
};
