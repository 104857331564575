import {FuelSurchargeTableType} from 'src/@types/fuelSurchargeTableType';
// Return the Fuel Surcharge for the matching fuel surcharge table row,
// where the matching row's lowerbound is less than the rateToMatch,
// and the matching row's upperbound is greater than the rateToMatch
export const findMatchingFuelSurchargeRate = ({fuelSurchargeTable}: {fuelSurchargeTable: FuelSurchargeTableType}) => {
  // we want to always use the current_us_dept_of_energy_per_gallon_rate from surcharge table
  const rate = fuelSurchargeTable.current_us_dept_of_energy_per_gallon_rate;
  if (fuelSurchargeTable?.calculation_rows && rate >= 0) {
    let matchingRowIndex = -1;

    // if the rate is less than the first row's lower bound price, use the first row (https://shipwell.atlassian.net/browse/TITAN-3919?focusedCommentId=88284)
    if (rate < fuelSurchargeTable?.calculation_rows[0].fuel_price_lower_bound) {
      matchingRowIndex = 0;
    } else if (
      // if the rate is greater than the last row's lower bound price, use the last row
      rate >
      fuelSurchargeTable?.calculation_rows[fuelSurchargeTable?.calculation_rows.length - 1].fuel_price_lower_bound
    ) {
      matchingRowIndex = fuelSurchargeTable?.calculation_rows.length - 1;
    } else {
      // otherwise, find the matching row will be somewhere between the first and last rows
      // specfically it will be the row before the lowest row that has a lower bound greater than the rate
      matchingRowIndex = fuelSurchargeTable?.calculation_rows.findIndex((row) => row.fuel_price_lower_bound > rate) - 1;
    }

    if (matchingRowIndex >= 0) {
      return fuelSurchargeTable?.calculation_rows[matchingRowIndex].fuel_surcharge;
    }
  }
  return null;
};
