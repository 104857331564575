import {FormikSelect} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Field, useFormikContext} from 'formik';
import {NewStageFormValues} from './utils';
import {preferredCurrencyOptions} from 'App/containers/Book/FormSections/Financials/components/utils';

const options = preferredCurrencyOptions.map(({name, id}) => ({label: name, value: id}));

export const NewStageCurrencyOfRecordField = () => {
  const {setFieldValue} = useFormikContext<NewStageFormValues>();
  const {stmShipmentCurrencyOfRecord} = useFlags();
  if (!stmShipmentCurrencyOfRecord) return null;

  return (
    <Field
      label="Currency of Record"
      component={FormikSelect}
      options={options}
      onChange={(newValue: string) => {
        setFieldValue('preferred_currency', newValue);
      }}
      name="preferred_currency"
      simpleValue
      required
    />
  );
};
