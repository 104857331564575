import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {Shipment} from '@shipwell/backend-core-sdk';

type GeneratePDFResponse = {
  response: string;
  meta: {
    name: string;
    display_name: string;
    encoding: string;
    content_type: string;
  };
};

export function useGenerateBolPdf(shipment?: Shipment) {
  const baseUrl = 'https://us1.pdfgeneratorapi.com/api/v4/documents/generate';
  const tempToken = process.env.TEMPORARY_TESTING_JWT ?? '';
  const BOL_TEMPLATE_ID = 1002250;
  const PdfGenPayload = {
    template: {
      id: BOL_TEMPLATE_ID,
      data: shipment
    },
    format: 'pdf',
    output: 'base64',
    name: `${shipment?.reference_id ?? 'shipment'} BOL`
  };
  const {mutateAsync, isLoading} = useMutation(async () => {
    const response = await axios.post<GeneratePDFResponse>(baseUrl, PdfGenPayload, {
      headers: {
        Authorization: `Bearer ${tempToken}`
      }
    });
    return response.data;
  });
  return {generateBolPdf: mutateAsync, isGeneratingBolPdf: isLoading};
}
