import {object, string, array, number, InferType} from 'yup';
import {parseISO, isFuture} from 'date-fns';

export const validationSchema = object({
  rfp_name: string().required('RFP Name is required.'),
  rfp_date_range: array()
    .of(string().required())
    .required('Date range is required.')
    .test({
      name: 'future dates',
      message: 'Date range must be in the future.',
      test: (dates) => {
        if (!dates) {
          return true;
        }
        const allFutureDates = dates.every((date) => {
          return date && isFuture(parseISO(date));
        });
        return allFutureDates;
      }
    }),
  tender_expiration: number().nullable().required('Tender expiration required.')
});

export type CriteriaFormValues = InferType<typeof validationSchema>;
