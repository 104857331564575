import PropTypes from 'prop-types';
import './styles.scss';

const Icon = (props) => {
  return (
    <span className={`automation__icon ${props.type === 'add' ? 'add' : ''}`}>
      <img onClick={props.handleClick} src={`/images/automationUI/${props.type}.svg`} alt={props.type} />
    </span>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  handleClick: PropTypes.func
};

export default Icon;
