export const roundNumberToSpecificDecimals = (number: number, decimalPlace: number): number => {
  // multiplier takes the number of decimal places, creates an array of 0's...
  // ...joins the array with a 1 in front and turns that string into an int
  // ...Math.abs insures the decimalPlace is positive
  // ex: decimalPlace = 3; multiplier = 1000
  const multiplier = parseInt(`1${Array(Math.abs(decimalPlace)).fill(0).join('')}`);
  return Math.round(number * multiplier) / multiplier;
};

export const roundToThousandths = (val: number) => Math.round(val * 100) / 100;
