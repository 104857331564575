import {Person} from './playgroundUtils';

export const playgroundData: Person[] = [
  {
    firstName: 'Jeff',
    lastName: 'Thomas',
    age: 34,
    visits: 3,
    status: 'online',
    progress: 57
  },
  {
    firstName: 'George',
    lastName: 'Jetson',
    age: 45,
    visits: 12,
    status: 'offline',
    progress: 89
  },
  {
    firstName: 'Mario',
    lastName: 'Mario',
    age: 56,
    visits: 1,
    status: 'absent',
    progress: 99
  },
  {
    firstName: 'Luigi',
    lastName: 'Mario',
    age: 54,
    visits: 2,
    status: 'online',
    progress: 74
  },
  {
    firstName: 'Jeff',
    lastName: 'Thomas',
    age: 34,
    visits: 3,
    status: 'online',
    progress: 57
  },
  {
    firstName: 'George',
    lastName: 'Jetson',
    age: 45,
    visits: 12,
    status: 'offline',
    progress: 89
  },
  {
    firstName: 'Mario',
    lastName: 'Mario',
    age: 56,
    visits: 1,
    status: 'absent',
    progress: 99
  },
  {
    firstName: 'Luigi',
    lastName: 'Mario',
    age: 54,
    visits: 2,
    status: 'online',
    progress: 74
  },
  {
    firstName: 'Jeff',
    lastName: 'Thomas',
    age: 34,
    visits: 3,
    status: 'online',
    progress: 57
  },
  {
    firstName: 'George',
    lastName: 'Jetson',
    age: 45,
    visits: 12,
    status: 'offline',
    progress: 89
  },
  {
    firstName: 'Mario',
    lastName: 'Mario',
    age: 56,
    visits: 1,
    status: 'absent',
    progress: 99
  },
  {
    firstName: 'Luigi',
    lastName: 'Mario',
    age: 54,
    visits: 2,
    status: 'online',
    progress: 74
  },
  {
    firstName: 'Jeff',
    lastName: 'Thomas',
    age: 34,
    visits: 3,
    status: 'online',
    progress: 57
  },
  {
    firstName: 'George',
    lastName: 'Jetson',
    age: 45,
    visits: 12,
    status: 'offline',
    progress: 89
  },
  {
    firstName: 'Mario',
    lastName: 'Mario',
    age: 56,
    visits: 1,
    status: 'absent',
    progress: 99
  },
  {
    firstName: 'Luigi',
    lastName: 'Mario',
    age: 54,
    visits: 2,
    status: 'online',
    progress: 74
  },
  {
    firstName: 'Jeff',
    lastName: 'Thomas',
    age: 34,
    visits: 3,
    status: 'online',
    progress: 57
  },
  {
    firstName: 'George',
    lastName: 'Jetson',
    age: 45,
    visits: 12,
    status: 'offline',
    progress: 89
  },
  {
    firstName: 'Mario',
    lastName: 'Mario',
    age: 56,
    visits: 1,
    status: 'absent',
    progress: 99
  },
  {
    firstName: 'Luigi',
    lastName: 'Mario',
    age: 54,
    visits: 2,
    status: 'online',
    progress: 74
  }
];
