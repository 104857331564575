import {countries} from 'App/utils/countries';

const COUNTRY_VALUE_WHITELIST = ['US', 'CA', 'MX'];

export const countryOptions = countries.reduce(
  (acc, country) =>
    COUNTRY_VALUE_WHITELIST.includes(country.value)
      ? [
          ...acc,
          {
            value: {formatted_address: country.value, country: country.value},
            formatted_address: country.label,
            type: 'country',
            validated: true
          }
        ]
      : acc,
  []
);

export const stateProvinceOptions = [
  {
    value: {formatted_address: 'AL', state_province: 'AL', country: 'US'},
    formatted_address: 'Alabama - AL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'AK', state_province: 'AK', country: 'US'},
    formatted_address: 'Alaska - AK',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'AZ', state_province: 'AZ', country: 'US'},
    formatted_address: 'Arizona - AZ',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'AR', state_province: 'AR', country: 'US'},
    formatted_address: 'Arkansas - AR',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CA', state_province: 'CA', country: 'US'},
    formatted_address: 'California - CA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CO', state_province: 'CO', country: 'US'},
    formatted_address: 'Colorado - CO',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CT', state_province: 'CT', country: 'US'},
    formatted_address: 'Connecticut - CT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'DE', state_province: 'DE', country: 'US'},
    formatted_address: 'Delaware - DE',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'FL', state_province: 'FL', country: 'US'},
    formatted_address: 'Florida - FL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'GA', state_province: 'GA', country: 'US'},
    formatted_address: 'Georgia - GA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'HI', state_province: 'HI', country: 'US'},
    formatted_address: 'Hawaii - HI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'ID', state_province: 'ID', country: 'US'},
    formatted_address: 'Idaho - ID',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'IL', state_province: 'IL', country: 'US'},
    formatted_address: 'Illinois - IL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'IN', state_province: 'IN', country: 'US'},
    formatted_address: 'Indiana - IN',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'IA', state_province: 'IA', country: 'US'},
    formatted_address: 'Iowa - IA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'KS', state_province: 'KS', country: 'US'},
    formatted_address: 'Kansas - KS',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'KY', state_province: 'KY', country: 'US'},
    formatted_address: 'Kentucky - KY',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'LA', state_province: 'LA', country: 'US'},
    formatted_address: 'Louisiana - LA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'ME', state_province: 'ME', country: 'US'},
    formatted_address: 'Maine - ME',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MD', state_province: 'MD', country: 'US'},
    formatted_address: 'Maryland - MD',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MA', state_province: 'MA', country: 'US'},
    formatted_address: 'Massachusetts - MA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MI', state_province: 'MI', country: 'US'},
    formatted_address: 'Michigan - MI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MN', state_province: 'MN', country: 'US'},
    formatted_address: 'Minnesota - MN',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MS', state_province: 'MS', country: 'US'},
    formatted_address: 'Mississippi - MS',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MO', state_province: 'MO', country: 'US'},
    formatted_address: 'Missouri - MO',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MT', state_province: 'MT', country: 'US'},
    formatted_address: 'Montana - MT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NE', state_province: 'NE', country: 'US'},
    formatted_address: 'Nebraska - NE',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NV', state_province: 'NV', country: 'US'},
    formatted_address: 'Nevada - NV',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NH', state_province: 'NH', country: 'US'},
    formatted_address: 'New Hampshire - NH',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NJ', state_province: 'NJ', country: 'US'},
    formatted_address: 'New Jersey - NJ',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NM', state_province: 'NM', country: 'US'},
    formatted_address: 'New Mexico - NM',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NY', state_province: 'NY', country: 'US'},
    formatted_address: 'New York - NY',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NC', state_province: 'NC', country: 'US'},
    formatted_address: 'North Carolina - NC',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'ND', state_province: 'ND', country: 'US'},
    formatted_address: 'North Dakota - ND',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'OH', state_province: 'OH', country: 'US'},
    formatted_address: 'Ohio - OH',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'OK', state_province: 'OK', country: 'US'},
    formatted_address: 'Oklahoma - OK',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'OR', state_province: 'OR', country: 'US'},
    formatted_address: 'Oregon - OR',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'PA', state_province: 'PA', country: 'US'},
    formatted_address: 'Pennsylvania - PA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'RI', state_province: 'RI', country: 'US'},
    formatted_address: 'Rhode Island - RI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SC', state_province: 'SC', country: 'US'},
    formatted_address: 'South Carolina - SC',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SD', state_province: 'SD', country: 'US'},
    formatted_address: 'South Dakota - SD',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'TN', state_province: 'TN', country: 'US'},
    formatted_address: 'Tennessee - TN',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'TX', state_province: 'TX', country: 'US'},
    formatted_address: 'Texas - TX',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'UT', state_province: 'UT', country: 'US'},
    formatted_address: 'Utah - UT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'VT', state_province: 'VT', country: 'US'},
    formatted_address: 'Vermont - VT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'VA', state_province: 'VA', country: 'US'},
    formatted_address: 'Virginia - VA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'WA', state_province: 'WA', country: 'US'},
    formatted_address: 'Washington - WA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'WV', state_province: 'WV', country: 'US'},
    formatted_address: 'West Virginia - WV',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'WI', state_province: 'WI', country: 'US'},
    formatted_address: 'Wisconsin - WI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'WY', state_province: 'WY', country: 'US'},
    formatted_address: 'Wyoming - WY',
    type: 'state_province',
    validated: true
  },
  // Canada provinces
  {
    value: {formatted_address: 'AB', state_province: 'AB', country: 'CA'},
    formatted_address: 'Alberta - AB',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'BC', state_province: 'BC', country: 'CA'},
    formatted_address: 'British Columbia - BC',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MB', state_province: 'MB', country: 'CA'},
    formatted_address: 'Manitoba - MB',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NB', state_province: 'NB', country: 'CA'},
    formatted_address: 'New Brunswick - NB',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NL', state_province: 'NL', country: 'CA'},
    formatted_address: 'Newfoundland and Labrador - NL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NS', state_province: 'NS', country: 'CA'},
    formatted_address: 'Nova Scotia - NS',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NT', state_province: 'NT', country: 'CA'},
    formatted_address: 'Northwest Territories - NT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NU', state_province: 'NU', country: 'CA'},
    formatted_address: 'Nunavut - NU',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'ON', state_province: 'ON', country: 'CA'},
    formatted_address: 'Ontario - ON',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'PE', state_province: 'PE', country: 'CA'},
    formatted_address: 'Prince Edward Island - PE',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'QC', state_province: 'QC', country: 'CA'},
    formatted_address: 'Quebec - QC',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SK', state_province: 'SK', country: 'CA'},
    formatted_address: 'Saskatchewan - SK',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'YT', state_province: 'YT', country: 'CA'},
    formatted_address: 'Yukon - YT',
    type: 'state_province',
    validated: true
  },
  // Mexico states
  {
    value: {formatted_address: 'AG', state_province: 'AG', country: 'MX'},
    formatted_address: 'Aguascalientes - AG',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'BC', state_province: 'BC', country: 'MX'},
    formatted_address: 'Baja California - BC',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'BS', state_province: 'BS', country: 'MX'},
    formatted_address: 'Baja California Sur - BS',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CM', state_province: 'CM', country: 'MX'},
    formatted_address: 'Campeche - CM',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CS', state_province: 'CS', country: 'MX'},
    formatted_address: 'Chiapas - CS',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CH', state_province: 'CH', country: 'MX'},
    formatted_address: 'Chihuahua - CH',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CO', state_province: 'CO', country: 'MX'},
    formatted_address: 'Coahuila - CO',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'CL', state_province: 'CL', country: 'MX'},
    formatted_address: 'Colima - CL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'DF', state_province: 'DF', country: 'MX'},
    formatted_address: 'Mexico City - DF',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'DG', state_province: 'DG', country: 'MX'},
    formatted_address: 'Durango - DG',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'GT', state_province: 'GT', country: 'MX'},
    formatted_address: 'Guanajuato - GT',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'GR', state_province: 'GR', country: 'MX'},
    formatted_address: 'Guerrero - GR',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'HG', state_province: 'HG', country: 'MX'},
    formatted_address: 'Hidalgo - HG',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'JA', state_province: 'JA', country: 'MX'},
    formatted_address: 'Jalisco - JA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'EM', state_province: 'EM', country: 'MX'},
    formatted_address: 'México - EM',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MI', state_province: 'MI', country: 'MX'},
    formatted_address: 'Michoacán - MI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'MO', state_province: 'MO', country: 'MX'},
    formatted_address: 'Morelos - MO',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NA', state_province: 'NA', country: 'MX'},
    formatted_address: 'Nayarit - NA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'NL', state_province: 'NL', country: 'MX'},
    formatted_address: 'Nuevo León - NL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'OA', state_province: 'OA', country: 'MX'},
    formatted_address: 'Oaxaca - OA',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'PU', state_province: 'PU', country: 'MX'},
    formatted_address: 'Puebla - PU',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'QT', state_province: 'QT', country: 'MX'},
    formatted_address: 'Quintana Roo - QR',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SL', state_province: 'SL', country: 'MX'},
    formatted_address: 'San Luis Potosí - SL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SI', state_province: 'SI', country: 'MX'},
    formatted_address: 'Sinaloa - SI',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'SO', state_province: 'SO', country: 'MX'},
    formatted_address: 'Sonora - SO',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'TB', state_province: 'TB', country: 'MX'},
    formatted_address: 'Tabasco - TB',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'TM', state_province: 'TM', country: 'MX'},
    formatted_address: 'Tamaulipas - TM',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'TL', state_province: 'TL', country: 'MX'},
    formatted_address: 'Tlaxcala - TL',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'VE', state_province: 'VE', country: 'MX'},
    formatted_address: 'Veracruz - VE',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'YU', state_province: 'YU', country: 'MX'},
    formatted_address: 'Yucatán - YU',
    type: 'state_province',
    validated: true
  },
  {
    value: {formatted_address: 'ZA', state_province: 'ZA', country: 'MX'},
    formatted_address: 'Zacatecas - ZA',
    type: 'state_province',
    validated: true
  }
];
