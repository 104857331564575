import {Appointment, ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import {useQueryClient, useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {cancelAppointment, getAppointment} from 'App/api/appointments';
import {APPOINTMENT_QUERY_KEY, SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type UseCancelAppointmentMutationOptions = Omit<
  UseMutationOptions<Appointment, AxiosError<ShipwellApiErrorResponse, unknown>, string, Appointment>,
  'mutationFn'
>;

const useCancelAppointmentMutation = (options?: UseCancelAppointmentMutationOptions) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (id: string) => {
      const appointment = await getAppointment(id);
      await cancelAppointment(id);
      const appointmentCacheKey = [APPOINTMENT_QUERY_KEY, appointment?.id];
      const shipmentStopAppointmentQueryCacheKey = [
        SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY,
        appointment?.scheduled_resource_id,
        appointment?.stop_id
      ];

      // optomistic update
      queryClient.setQueryData(appointmentCacheKey, null);
      queryClient.setQueryData(shipmentStopAppointmentQueryCacheKey, null);

      return appointment;
    },
    {
      ...options,
      onError: (error, variables, context) => {
        const appointmentCacheKey = [APPOINTMENT_QUERY_KEY, context?.id];
        const shipmentStopAppointmentQueryCacheKey = [
          SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY,
          context?.scheduled_resource_id,
          context?.stop_id
        ];
        // set query data back since the operation failed
        queryClient.setQueryData(appointmentCacheKey, context);
        queryClient.setQueryData(shipmentStopAppointmentQueryCacheKey, context);
        options?.onError?.(error, variables, context);
      },
      onSettled: async (data, error, variables, context) => {
        const appointmentCacheKey = [APPOINTMENT_QUERY_KEY, data?.id];
        const shipmentStopAppointmentQueryCacheKey = [
          SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY,
          data?.scheduled_resource_id,
          data?.stop_id
        ];

        await queryClient.invalidateQueries(appointmentCacheKey);
        await queryClient.invalidateQueries(shipmentStopAppointmentQueryCacheKey);
        options?.onSettled?.(data, error, variables, context);
      }
    }
  );

  return {
    isDeleting: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    cancelAppointmentErrors: mutation.error?.response?.data?.errors,
    cancelAppointment: mutation.mutate,
    cancelAppointmentAsync: mutation.mutateAsync
  };
};

export {useCancelAppointmentMutation};
