import {PurolatorPackageTypes, ProviderCode} from '@shipwell/genesis-sdk';
import {startCaseToLower} from 'App/utils/startCaseToLower';

const packageTypesByProvider = {
  [ProviderCode.Purolator]: PurolatorPackageTypes
};

export const getPackageTypesByProvider = (providerCode?: ProviderCode) => {
  if (!providerCode || !(providerCode in packageTypesByProvider)) return [];

  const packageTypes = packageTypesByProvider[<keyof typeof packageTypesByProvider>providerCode];
  return Object.values(packageTypes).map((type) => {
    return {
      id: type,
      name: startCaseToLower(type)
    };
  });
};
