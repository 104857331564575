import {CustomField, Shipment, ShipmentChargeLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import {isString} from 'lodash';
import {AggregateCharge, AggregatePrepaidCharge, CustomData} from '../types';

export const getCalculatedAggregateTotals = ({
  customerAssignment,
  vendorAssignment,
  customFields
}: {
  customerAssignment: Shipment['relationship_to_customer'];
  vendorAssignment: Shipment['relationship_to_vendor'];
  customFields: CustomField[] | undefined;
}) => {
  /**
   * Given a charge line item, returns a function that can be used to determine if an aggregate charge is the correct
   * aggregate charge for this charge line item.
   */
  const isCorrectAggregateFor = (charge: ShipmentChargeLineItem, customData: CustomData) => {
    return (aggregate: AggregateCharge) => {
      return hasMergeableCustomData(aggregate, customData) && isSameCategoryAndDescription(charge, aggregate);
    };
  };

  // I think these variable names are pretty bad. I think the "my" and "their" prefixes are only valid from the
  // shippers perspective. Would these prefixes be reversed if logged in as a carrier? These were then names used
  // before the refactor and I don't have any better ideas. Maybe someone with a better understanding of the product
  // and how different parties use it could come up with better variables names.
  const myVendorCharges = sortByCreatedAt(vendorAssignment?.customer_charge_line_items);
  const theirVendorCharges = sortByCreatedAt(vendorAssignment?.vendor_charge_line_items);
  const myCustomerCharges = sortByCreatedAt(customerAssignment?.vendor_charge_line_items);
  const theirCustomerCharges = sortByCreatedAt(customerAssignment?.customer_charge_line_items);

  const aggregatePrepaidCharges = myVendorCharges.reduce<AggregatePrepaidCharge[]>((prepaidCharges, myVendorCharge) => {
    if (myVendorCharge.prepaid_amount) {
      prepaidCharges.push({
        category: myVendorCharge.category,
        total: myVendorCharge.prepaid_amount,
        chargeCode: myVendorCharge.charge_code,
        chargeName: myVendorCharge.unit_name
      });
    }

    return prepaidCharges;
  }, []);

  const aggregateVendorCharges: AggregateCharge[] = [];

  theirVendorCharges.forEach((theirVendorCharge) => {
    const customData = customFields ? getCustomData(theirVendorCharge, customFields) : {};
    const aggregate = aggregateVendorCharges.find(isCorrectAggregateFor(theirVendorCharge, customData));

    if (aggregate) {
      aggregate.total_theirs += theirVendorCharge.amount || 0;
      aggregate.customData = {...aggregate.customData, ...customData};
    } else {
      const {category, unit_name, amount, charge_code, unit_quantity, unit_amount_currency} = theirVendorCharge;

      aggregateVendorCharges.push({
        category,
        unit_name,
        charge_code,
        customData,
        total_theirs: amount || 0,
        total_mine: 0,
        unit_quantity,
        unit_amount_currency
      });
    }
  });

  myVendorCharges.forEach((myVendorCharge) => {
    const customData = customFields ? getCustomData(myVendorCharge, customFields) : {};
    const aggregate = aggregateVendorCharges.find(isCorrectAggregateFor(myVendorCharge, customData));

    if (aggregate) {
      aggregate.total_mine += myVendorCharge.amount || 0;
      aggregate.customData = {...aggregate.customData, ...customData};
    } else {
      const {category, unit_name, amount, charge_code, unit_quantity, unit_amount_currency} = myVendorCharge;
      aggregateVendorCharges.push({
        category,
        unit_name,
        charge_code,
        customData,
        total_mine: amount || 0,
        total_theirs: 0,
        unit_quantity,
        unit_amount_currency
      });
    }
  });

  const aggregateCustomerCharges: AggregateCharge[] = [];

  myCustomerCharges.forEach((myCustomerCharge) => {
    const customData = customFields ? getCustomData(myCustomerCharge, customFields) : {};
    const aggregate = aggregateCustomerCharges.find(isCorrectAggregateFor(myCustomerCharge, customData));

    if (aggregate) {
      aggregate.total_mine += myCustomerCharge.amount || 0;
      aggregate.customData = {...aggregate.customData, ...customData};
    } else {
      const {category, unit_name, amount, charge_code, unit_quantity, unit_amount_currency} = myCustomerCharge;
      aggregateCustomerCharges.push({
        category,
        unit_name,
        charge_code,
        customData,
        total_mine: amount || 0,
        total_theirs: 0,
        unit_quantity,
        unit_amount_currency
      });
    }
  });

  theirCustomerCharges.forEach((theirCustomerCharge) => {
    const customData = customFields ? getCustomData(theirCustomerCharge, customFields) : {};
    const aggregate = aggregateCustomerCharges.find(isCorrectAggregateFor(theirCustomerCharge, customData));

    if (aggregate) {
      aggregate.total_theirs += theirCustomerCharge.amount || 0;
      aggregate.customData = {...aggregate.customData, ...customData};
    } else {
      const {category, unit_name, amount, charge_code, unit_quantity, unit_amount_currency} = theirCustomerCharge;
      aggregateCustomerCharges.push({
        category: category,
        unit_name,
        charge_code,
        customData,
        total_theirs: amount || 0,
        total_mine: 0,
        unit_quantity,
        unit_amount_currency
      });
    }
  });

  return {
    aggregateVendorCharges,
    aggregatePrepaidCharges,
    aggregateCustomerCharges,
    myVendorCharges,
    theirVendorCharges,
    myCustomerCharges,
    theirCustomerCharges
  };
};

function sortByCreatedAt(charges: ShipmentChargeLineItem[] | undefined | null): ShipmentChargeLineItem[] {
  if (!charges) return [];

  return [...charges].sort((a, b) => {
    if (!a.created_at || !b.created_at) return 0;
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });
}

const getCustomData = (charge: ShipmentChargeLineItem, customFields: CustomField[]) => {
  const visibleCustomFields = customFields.filter((customField) => customField.ui_enabled);

  const customData = visibleCustomFields.reduce<CustomData>((customData, field) => {
    const fieldValueAny = charge.custom_data?.[field.name];
    customData[field.name] = isString(fieldValueAny) && fieldValueAny !== '' ? fieldValueAny : undefined;

    return customData;
  }, {});
  return customData;
};

/**
 * Custom data fields should be merged into the aggregate charge if:
 * - the aggregate charge does not have a value for this custom data field
 * - or, the aggregate charge has a value for this custom data field, but it's the same value the charge line item has
 */
const hasMergeableCustomData = (aggregate: AggregateCharge, customData: CustomData) => {
  const result = Object.keys(customData).every(
    (key) =>
      aggregate.customData[key] === undefined ||
      aggregate.customData[key] === null ||
      aggregate.customData[key] === '' ||
      aggregate.customData[key] === customData[key]
  );
  return result;
};

/**
 * Given an aggregate charge and a charge line item, does
 * this charge line item meet the criteria to be merged into this aggregate charge?
 */
const isSameCategoryAndDescription = (charge: ShipmentChargeLineItem, aggregate: AggregateCharge) =>
  aggregate.category === charge.category && aggregate.unit_name === charge.unit_name;
