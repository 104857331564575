import {Formik, Field, FormikHelpers, Form} from 'formik';
import {
  FormikTextInput,
  FormikPhoneInput,
  FormikCheckbox,
  FormikSelect,
  DeprecatedButton,
  SvgIcon
} from '@shipwell/shipwell-ui';
import {FedexRegistrationFormValues} from 'App/formComponents/forms/fedexRegistration/utils/types';
import {smartPostHubIds} from 'App/formComponents/forms/fedexRegistration/utils/constants';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {FedexBillingAddress} from 'App/formComponents/forms/fedexRegistration/fields/FedexBillingAddress';
import {validationSchema} from 'App/formComponents/forms/fedexRegistration/utils/validationSchema';
import {defaultValues, defaultFreightValues} from 'App/formComponents/forms/fedexRegistration/utils/defaultValues';
import './styles.scss';

type FedexRegistrationFormProps = {
  values?: FedexRegistrationFormValues;
  onCancel: () => void;
  onSubmit: (values: FedexRegistrationFormValues, actions: FormikHelpers<FedexRegistrationFormValues>) => Promise<void>;
  isEditing?: boolean;
};

export const FedexRegistrationForm = ({values, onCancel, onSubmit, isEditing}: FedexRegistrationFormProps) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{...defaultValues, ...values}}
    >
      {({handleSubmit, isSubmitting, dirty, values, isValid, setValues}) => {
        const handleAddFreightAccount = () =>
          setValues({
            ...values,
            ...defaultFreightValues
          });

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div title="Main Account" className="border-spacing-3 rounded border-1 border-solid border-sw-border">
              <div className="grid-col-1 grid-row-1 grid border-b-1 border-sw-border p-4">
                <h5>Main Account</h5>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 grid-rows-1 gap-6 border-b-1 border-sw-border pb-4 pt-1">
                  <Field
                    disabled={isEditing}
                    required
                    name="account_number"
                    label="Parcel Account Number"
                    component={FormikTextInput}
                  />
                  <Field name="account_nickname" label="Nickname" component={FormikTextInput} />
                </div>
                <div title="Parcel Contact Information" className="border-b-1 border-sw-border pb-4">
                  <h5>Contact Information</h5>
                  <div className="grid grid-rows-3 gap-6">
                    <div className="inline-grid grid-cols-2 gap-6">
                      <Field
                        required
                        name="contact.first_name"
                        label="Contact First Name"
                        component={FormikTextInput}
                      />
                      <Field required name="contact.last_name" label="Contact Last Name" component={FormikTextInput} />
                    </div>
                    <div className="inline-grid grid-cols-2 gap-6">
                      <Field required name="contact.company_name" label="Company Name" component={FormikTextInput} />
                      <Field required name="contact.email_address" label="Email Address" component={FormikTextInput} />
                    </div>
                    <div className="inline-grid grid-cols-2 gap-6">
                      <Field name="secondary_email" label="Secondary Email Address" component={FormikTextInput} />
                      <Field required name="contact.phone_number" label="Phone Number" component={FormikPhoneInput} />
                    </div>
                  </div>
                </div>
                <div title="Parcel Billing Address" className="border-b-1 border-sw-border pb-4">
                  <h5>Billing Address</h5>
                  <FedexBillingAddress namePrefix="billing_address" disabled={!!values.id} />
                </div>
                <div title="FedEx Smart Post">
                  <div>
                    <h5>FedEx SmartPost®</h5>
                    <p>Please enter FedEx SmartPost® Hub ID, if your account includes SmartPost.</p>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="col-span-1">
                      <Field
                        simpleValue
                        clearable
                        name="smart_post_hub_id"
                        label="FedEx SmartPost® Hub ID"
                        getOptionLabel={({value, label}: {value: string; label: string}) => `${label} ${value}`}
                        options={smartPostHubIds}
                        component={FormikSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5">
              <DeprecatedButton
                role="button"
                variant="tertiary"
                icon={<SvgIcon name="AddCircleOutlined" />}
                onClick={handleAddFreightAccount}
              >
                Add Freight Account
              </DeprecatedButton>
            </div>
            <div className={`${values.has_freight_account ? '' : 'collapse'}`}>
              <div title="Freight Account" className="border-spacing-3 rounded border-1 border-solid border-sw-border">
                <div className="grid-col-1 grid-row-1 grid border-b-1 border-sw-border p-4">
                  <h5>Freight Account</h5>
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-2 border-b-1 border-sw-border pb-4 pt-1">
                    <Field
                      required={values.has_freight_account}
                      name="freight_account.freight_account_number"
                      label="Freight Account Number"
                      component={FormikTextInput}
                    />
                  </div>
                  <div title="Freight Billing Address" className="pb-4">
                    <h5>Billing Address</h5>
                    <FedexBillingAddress namePrefix="freight_account" />
                  </div>
                </div>
              </div>
            </div>
            <div className="div-checkbox-field terms-checkbox">
              <Field
                name="accept_terms"
                label={
                  <span>
                    I accept the{' '}
                    <a href="http://www.fedex.com/us/developer/product/wsla_corp.pdf" target="_blank" rel="noreferrer">
                      FedEx End-User License Agreement
                    </a>
                    .
                  </span>
                }
                component={FormikCheckbox}
              />
            </div>
            <div className="fedex-copyright">
              <img src="/images/fedex_logo.svg" width="90" />
              <span>The FedEx service marks are owned by Federal Express Corporation and are used by permission.</span>
            </div>
            {dirty && (
              <FormFooter
                primaryActionName={isEditing ? 'Update FedEx Account' : 'Connect FedEx'}
                isValid={isValid}
                isSubmitting={isSubmitting}
                onCancel={onCancel}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
