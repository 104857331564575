import {
  LoadboardType,
  LoadPostRequest,
  Contact1,
  DimensionUnit,
  WeightUnit,
  TemperatureUnit,
  PreferredContactMethodEnum
} from '@shipwell/tabula-sdk';
import {FacilityAddress} from '@shipwell/tempus-sdk';
import {Shipment} from '@shipwell/backend-core-sdk';

import {defaultTimeOptions} from 'App/utils/getTimeOptions';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {
  facilityAddressToLegacyAddress,
  formatV2AddressToFacilityAddress
} from 'App/data-hooks/facilities/facilityUtils';

export type EditProps = {
  toggleModal: () => void;
  loadboardType: LoadboardType;
  loadboardName: string;
  loadpostId: string;
  shipmentId: string;
  setSuccess: WithStatusToastProps['setSuccess'];
  setError: WithStatusToastProps['setError'];
  contactInfo: Contact1;
  mode: Shipment['mode'];
};

type Tarp = {
  length: string;
  width: string;
  unit: string;
};

export type InitialValues = LoadPostRequest & {
  rerun_in_days: number;
  refrigeration_required: boolean;
  straps_required: boolean;
  tarps_required: boolean;
  hazmat_required: boolean;
  insured_cargo: boolean;
  oversized_required: boolean;
  tarp?: Tarp;
};

export type PostSettingsFormProps = {
  values: InitialValues;
  setFieldValue: (field: string, value: string) => void;
};

export type EquipmentFormProps = PostSettingsFormProps & {mode: Shipment['mode']};

const generateOptions = (options: unknown[]) => (options || []).map((option) => ({label: option, value: option}));

const generateDaysList = (daysLenght: number) =>
  Array.from({length: daysLenght}, (_, index) => {
    const value = index + 1;
    const label = `${value} ${value === 1 ? 'Day' : 'Days'}`;
    return {value: String(value), label};
  });

export const daysList = [
  {
    value: '999',
    label: 'Until expiration'
  },
  ...generateDaysList(7)
];

export const timeOptions = [
  {
    label: '15 Minutes',
    value: 900
  },
  ...defaultTimeOptions.slice(1)
].map((option) => ({
  ...option,
  value: option.value / 60
}));

export const currencyUnit = generateOptions(['USD', 'CAD']);

export const tempUnit = generateOptions(Object.values(TemperatureUnit));

export const weightUnit = generateOptions(Object.values(WeightUnit));

export const dimensionUnit = generateOptions(Object.values(DimensionUnit));

export const preferredContactMethod = generateOptions(Object.values(PreferredContactMethodEnum));

const removeCustomFields = {
  rerun_in_days: undefined,
  tarp: undefined,
  refrigeration_required: undefined,
  straps_required: undefined,
  tarps_required: undefined,
  hazmat_required: undefined,
  insured_cargo: undefined,
  oversized_required: undefined
};

const formatTarp = (tarp?: Tarp) => {
  if (tarp) return `${tarp.length}x${tarp.width} ${tarp.unit}`;
  return undefined;
};

export const formatValues = ({
  schedule,
  itinerary,
  tarp,
  refrigeration_required,
  straps_required,
  hazmat_required,
  insured_cargo,
  oversized_required,
  tarps_required,
  requirements,
  rate_attributes,
  ...rest
}: InitialValues) => {
  return {
    ...rest,
    itinerary: (itinerary || []).map((item) => {
      const {timezone, ...address} = formatV2AddressToFacilityAddress(item.location.address);
      return {
        ...item,
        location: {
          ...item.location,
          address,
          timezone: timezone || undefined
        }
      };
    }),
    schedule:
      schedule?.rerun_in_minutes && schedule?.until
        ? {
            rerun_in_minutes: schedule.rerun_in_minutes,
            until: schedule.until
          }
        : undefined,
    requirements: {
      ...requirements,
      min_temperature: refrigeration_required ? requirements?.min_temperature : undefined,
      max_temperature: refrigeration_required ? requirements?.max_temperature : undefined,
      pre_cool_temperature: refrigeration_required ? requirements?.pre_cool_temperature : undefined,
      strap_count: straps_required ? requirements?.strap_count : undefined,
      tarp_size: tarps_required ? formatTarp(tarp) : undefined,
      hazmat_identification_numbers: hazmat_required ? requirements?.hazmat_identification_numbers : undefined,
      cargo_value_insurance_amount: insured_cargo ? requirements?.cargo_value_insurance_amount : undefined,
      cargo_value_insurance_currency_code: insured_cargo
        ? requirements?.cargo_value_insurance_currency_code
        : undefined,
      oversized_length: oversized_required ? requirements?.oversized_length : undefined,
      oversized_width: oversized_required ? requirements?.oversized_width : undefined,
      oversized_height: oversized_required ? requirements?.oversized_height : undefined
    },
    rate_attributes:
      rate_attributes?.offer_all_in_rate?.value || rate_attributes?.book_now_or_tender_rate?.value
        ? {
            offer_all_in_rate: rate_attributes?.offer_all_in_rate?.value
              ? {
                  value: rate_attributes.offer_all_in_rate.value,
                  currency_code: rate_attributes.offer_all_in_rate.currency_code
                }
              : undefined,
            book_now_or_tender_rate: rate_attributes?.book_now_or_tender_rate?.value
              ? {
                  value: rate_attributes.book_now_or_tender_rate.value,
                  currency_code: rate_attributes.book_now_or_tender_rate.currency_code
                }
              : undefined
          }
        : undefined,
    ...removeCustomFields
  };
};

const getTarp = (tarp?: string) => {
  if (tarp) {
    const tarpSplitted = tarp.split(' ');
    const size = tarpSplitted[0].split('x');
    const unit = tarpSplitted[1];

    return {
      length: size[0],
      width: size[1],
      unit: unit
    };
  }

  return undefined;
};

const customFields = (values: LoadPostRequest) => {
  if (values?.requirements) {
    return {
      rerun_in_days: '999',
      refrigeration_required:
        Boolean(values.requirements.min_temperature) ||
        Boolean(values.requirements.max_temperature) ||
        Boolean(values.requirements.pre_cool_temperature),
      straps_required: Boolean(values.requirements.strap_count),
      tarps_required: Boolean(values.requirements.tarp_size),
      tarp: getTarp(values.requirements.tarp_size),
      hazmat_required: Boolean(values.requirements.hazmat_identification_numbers),
      insured_cargo: Boolean(values.requirements.cargo_value_insurance_amount),
      oversized_required:
        Boolean(values.requirements.oversized_height) ||
        Boolean(values.requirements.oversized_width) ||
        Boolean(values.requirements.oversized_length)
    };
  }
  return {
    rerun_in_days: '999',
    refrigeration_required: false,
    straps_required: false,
    tarps_required: false,
    tarp: getTarp(),
    hazmat_required: false,
    insured_cargo: false,
    oversized_required: false
  };
};

export const formatInitialValues = (initialValues: LoadPostRequest, contactInfo: Contact1) => {
  return {
    ...initialValues,
    contact: {
      preferred_contact_method: initialValues?.contact?.preferred_contact_method,
      ...contactInfo
    },
    schedule: {
      rerun_in_minutes: initialValues?.schedule?.rerun_in_minutes || 15,
      until: initialValues?.schedule?.until || initialValues?.expires_at
    },
    itinerary: (initialValues?.itinerary || []).map((item) => {
      return {
        ...item,
        location: {
          ...item.location,
          address: facilityAddressToLegacyAddress({
            ...item.location.address,
            timezone: item.location.timezone
          } as FacilityAddress)
        }
      };
    }),
    rate_attributes: {
      offer_all_in_rate: {
        value: initialValues?.rate_attributes?.offer_all_in_rate?.value,
        currency_code: 'USD'
      },
      book_now_or_tender_rate: {
        value: initialValues?.rate_attributes?.book_now_or_tender_rate?.value,
        currency_code: 'USD'
      }
    },
    ...customFields(initialValues)
  };
};
