import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router';
import {Modal, Toast, DeprecatedButton, Select} from '@shipwell/shipwell-ui';
import {
  getShipmentPickups,
  createShipmentPickup,
  updateShipmentPickup,
  cancelShipmentPickup
} from 'App/containers/fedex/actions/async';
import SchedulePickupForm from 'App/formComponents/forms/schedulePickup';
import SWTable from 'App/components/swTable';
import {unpackErrors, cleanPayload} from 'App/utils/globals';
import './styles.scss';

/**
 * Parcel Pickups List
 * @params {*} props
 */
const ParcelPickupsList = (props) => {
  const {dispatch, providerCode} = props;
  const pickupFilters = [
    {value: 'upcoming', label: 'Upcoming'},
    {value: 'recurring', label: 'Recurring'},
    {value: 'past', label: 'Past'},
    {value: 'canceled', label: 'Canceled'}
  ];

  // Data state
  const [parcelAccount, setParcelAccount] = useState({});
  const [pickup, setPickup] = useState({});
  const [pickups, setPickups] = useState([]);
  const [isFetchingPickups, setIsFetchingPickups] = useState(false);

  // UI state
  const [pickupFilter, setPickupFilter] = useState(pickupFilters[0].value);
  const [showCreatePickupModal, setShowCreatePickupModal] = useState(false);
  const [showUpdatePickupModal, setShowUpdatePickupModal] = useState(false);
  const [showCancelPickupModal, setShowCancelPickupModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  /**
   * Request Shipment Pickups
   * @param {Object} opts
   */
  const getShipmentPickupsList = async (opts = {}) => {
    setIsFetchingPickups(true);

    try {
      const response = await dispatch(getShipmentPickups(opts));

      if (response && response.body && response.body.results) {
        setPickups(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchingPickups(false);
  };

  const shouldUpdatePickupList = (pickup) => {
    let shouldUpdate = false;
    const pickupAccount = providerCode && pickup?.[`${providerCode.toLowerCase()}_account`];

    // if the account on the pickup matches the selected account, update the pickups list
    if (pickupAccount && pickupAccount === parcelAccount.id) {
      shouldUpdate = true;
      // if the provider is USPS, just update the list since the account is always the same for now
    } else if (pickup?.provider_code && pickup.provider_code === 'USPS') {
      shouldUpdate = true;
    }

    return shouldUpdate;
  };

  const getOptionsForPickupList = (pickup) => {
    const options = getShipmentFilters() || {};

    if (pickup?.fedex_account) {
      options.fedexAccount = pickup.fedex_account;
    } else if (pickup?.ups_account) {
      options.upsAccount = pickup.ups_account;
      // special handling for USPS since the account is always the same and is not attached to the pickup
    } else if (pickup?.provider_code && pickup.provider_code === 'USPS') {
      options.providerCode = 'USPS';
    }

    return options;
  };

  /**
   * Submit Create Shipment Pickup
   * @param {Object} values
   * @param {Object} Formik actions
   */
  const handleCreatePickup = async (values, {setSubmitting, setErrors}) => {
    try {
      /** Create shipment pickup */
      const response = await dispatch(createShipmentPickup(cleanPayload(values)));

      if (response?.body && shouldUpdatePickupList(response.body)) {
        getShipmentPickupsList(getOptionsForPickupList(response.body));
      }

      setSubmitSuccess('Pickup successfully created.');
    } catch (error) {
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }

    setShowCreatePickupModal(false);
    setSubmitting(false);
  };

  /**
   * Submit Update Shipment Pickup
   * @param {Object} values
   * @param {Object} formik actions
   */
  const handleUpdatePickup = async (values, {setSubmitting, setErrors}) => {
    try {
      const response = await dispatch(updateShipmentPickup(values.id, cleanPayload(values)));

      if (response?.body && shouldUpdatePickupList(response.body)) {
        getShipmentPickupsList(getOptionsForPickupList(response.body));
      }

      setSubmitSuccess('Pickup successfully updated.');
    } catch (error) {
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
      if (error && error.field_errors) {
        setErrors(unpackErrors(error.field_errors, {}));
      }
    }

    setShowUpdatePickupModal(false);
    setSubmitting(false);
  };

  /**
   * Cancel Shipment Pickup
   * @param {Object} values
   */
  const handleCancelPickup = async () => {
    try {
      const cancelled = await dispatch(cancelShipmentPickup(pickup.id));

      if (cancelled?.body && shouldUpdatePickupList(cancelled.body)) {
        getShipmentPickupsList(getOptionsForPickupList(cancelled.body));
      }

      setSubmitSuccess('Pickup successfully canceled.');
    } catch (error) {
      if (error && error.error_description) {
        setSubmitError(error.error_description);
      }
    }

    setShowCancelPickupModal(false);
  };

  /** Toggle Create Pickup modal */
  const handleToggleCreatePickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowCreatePickupModal(!showCreatePickupModal);
  };

  /** Toggle Update Pickup modal */
  const handleToggleUpdatePickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (showUpdatePickupModal) {
      setPickup({});
    }
    setShowUpdatePickupModal(!showUpdatePickupModal);
  };

  /** Toggle Cancel Pickup modal */
  const handleToggleCancelPickupModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (showCancelPickupModal) {
      setPickup({});
    }
    setShowUpdatePickupModal(false);
    setShowCancelPickupModal(!showCancelPickupModal);
  };

  /** Build pickup request filter based on filter selection */
  const getShipmentFilters = () => {
    const filters = {};

    if (pickupFilter === 'upcoming') {
      filters.scheduledDateGte = moment().format('YYYY-MM-DD');
    } else if (pickupFilter === 'recurring') {
      filters.isRegularlyScheduled = true;
    } else if (pickupFilter === 'canceled') {
      filters.cancelled = true;
    } else if (pickupFilter === 'past') {
      filters.scheduledDateLte = moment().subtract(1, 'days').format('YYYY-MM-DD');
    }
    return filters;
  };

  useEffect(() => {
    const {account} = props;
    const filters = getShipmentFilters();

    if (account && account.id) {
      const accountId = account.id;

      setParcelAccount(account);

      if (providerCode === 'FEDEX') {
        getShipmentPickupsList({fedexAccount: accountId, ...filters});
      }
      if (providerCode === 'UPS') {
        getShipmentPickupsList({upsAccount: accountId, ...filters});
      }
    }
    // don't filter by account since USPS account is always shipwell USPS account for now
    // just pull all USPS pickups, this route has auth and will return pickups scoped to company
    if (providerCode === 'USPS') {
      getShipmentPickupsList({providerCode, ...filters});
    }
  }, [props.account, providerCode, pickupFilter]);

  return (
    <div className="pickups-container">
      <div className={`pickups-header ${providerCode === 'USPS' ? 'pickups-header__usps' : ''}`}>
        <strong>Scheduled Pickups</strong>
        <DeprecatedButton variant="tertiary" onClick={handleToggleCreatePickupModal}>
          Add New Pickup
        </DeprecatedButton>
      </div>
      <div className="field-grid">
        <Select
          simpleValue
          clearable={false}
          label="Pickup Status"
          name="pickup_status"
          value={pickupFilter}
          options={pickupFilters}
          onChange={(option) => setPickupFilter(option)}
        />
      </div>
      <div className="flex min-h-[300px]">
        <SWTable
          data={pickups}
          loading={isFetchingPickups}
          showPagination={pickups.length > 5}
          defaultPageSize={5}
          noDataText="No Pickups Scheduled at this time"
          getTrProps={(state, rowInfo) => ({className: !rowInfo ? 'empty' : ''})}
          columns={[
            {
              Header: 'Date',
              id: 'date',
              accessor: 'scheduled_date',
              sortable: false,
              Cell: ({value}) => (moment(value).isValid() ? moment(value).format('MM/DD/YYYY') : '--')
            },
            {
              Header: 'Status',
              id: 'status',
              Cell: ({original}) => {
                if (original.cancelled_at) {
                  return 'Canceled';
                }
                if (original.scheduled_date < moment().format('YYYY-MM-DD')) {
                  return 'Past';
                }
                return 'Upcoming';
              }
            },
            {
              Header: 'Recurring',
              id: 'recurring',
              accessor: 'is_regularly_scheduled',
              sortable: false,
              Cell: ({value}) => (value ? 'Yes' : 'No')
            },
            {
              Header: 'Manifest',
              id: 'manifest',
              accessor: 'shipments',
              sortable: false,
              Cell: ({value, original}) => {
                if (!value.length) {
                  return <span className="disabled-link">View</span>;
                }
                return <Link to={`/pickup/${original.id}/manifest`}>View</Link>;
              }
            },
            {
              Header: 'Actions',
              id: 'actions',
              accessor: 'scheduled_date',
              sortable: false,
              Cell: ({original}) => {
                const hasScheduledShipment = original.shipments.find((shipment) => shipment.status !== 'NOT_SCHEDULED');

                return (
                  <DeprecatedButton
                    variant="tertiary"
                    disabled={hasScheduledShipment || original.cancelled_at}
                    onClick={() => {
                      setPickup(original);
                      handleToggleUpdatePickupModal();
                    }}
                  >
                    Edit
                  </DeprecatedButton>
                );
              }
            }
          ]}
        />
      </div>

      <Modal
        show={Boolean(showUpdatePickupModal)}
        title="Update Scheduled Pickup"
        footerComponent={null}
        onClose={handleToggleUpdatePickupModal}
      >
        <SchedulePickupForm
          values={pickup}
          isReadOnlyAddress
          onCancelPickup={handleToggleCancelPickupModal}
          onCancel={handleToggleUpdatePickupModal}
          onSubmit={handleUpdatePickup}
          providerCode={providerCode}
        />
      </Modal>
      <Modal
        show={Boolean(showCreatePickupModal)}
        title="Add Scheduled Pickup"
        footerComponent={null}
        onClose={handleToggleCreatePickupModal}
      >
        <SchedulePickupForm
          values={
            providerCode === 'FEDEX'
              ? {fedex_account: parcelAccount.id}
              : providerCode === 'UPS'
              ? {ups_account: parcelAccount.id}
              : providerCode === 'USPS'
              ? {usps_account: parcelAccount.id}
              : {}
          }
          onCancel={handleToggleCreatePickupModal}
          onSubmit={handleCreatePickup}
          providerCode={providerCode}
        />
      </Modal>
      <Modal
        variant="warning"
        show={Boolean(showCancelPickupModal)}
        title="Cancel Scheduled Pickup?"
        primaryBtnName="Cancel Pickup"
        onClose={handleToggleCancelPickupModal}
        onPrimaryAction={handleCancelPickup}
      >
        <p>Are you sure you want to cancel this pickup? Any shipments assigned to this pickup will not be picked up.</p>
        {pickup.is_regularly_scheduled && (
          <p>
            Cancelling a recurring pickup will remove it from your list of scheduled pickups. Contact{' '}
            {providerCode ? (providerCode === 'FEDEX' ? 'FedEx' : providerCode) : 'the carrier'}
            to cancel the pickup appointment.
          </p>
        )}
      </Modal>

      <Toast
        show={Boolean(submitSuccess)}
        title="Success"
        variant="success"
        anchor="bottom-right"
        onClose={() => setSubmitSuccess(null)}
      >
        {submitSuccess}
      </Toast>
      <Toast
        show={Boolean(submitError)}
        title="Error"
        variant="error"
        anchor="bottom-right"
        onClose={() => setSubmitError(null)}
      >
        {submitError}
      </Toast>
    </div>
  );
};

export default connect()(ParcelPickupsList);
