import {useMemo, useState, useCallback} from 'react';
import {DrayageLegOverallStatus, Hazmat, OrderStatus} from '@shipwell/corrogo-sdk';
import {useIsFetching} from '@tanstack/react-query';
import {Card, DeprecatedButton} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import Table from 'App/components/Table';
import Loader from 'App/common/shipwellLoader';
import {useTransportationOrders, useV3Shipment} from 'App/data-hooks';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ContainerPickupDeliveryDateModal} from 'App/containers/shipments/drayage/components/ContainerFields';
import useToggle from 'App/utils/hooks/useToggle';
import WeightCell from 'App/containers/shipments/drayage/components/ContainersView/WeightCell';
import {LegStatusPillBase} from 'App/containers/shipments/components/Status/LegStatusPill';

type Container = {
  pickup_date: string;
  delivery_date: string;
  status: DrayageLegOverallStatus;
  containerId: string;
  id: string;
};

const ContainerActions = ({
  shipmentId,
  selectedContainers,
  toggleShowPickupDeliveryModal,
  numberOfContainers
}: {
  shipmentId: string;
  toggleShowPickupDeliveryModal: () => void;
  selectedContainers: Container[];
  numberOfContainers: number;
}) => {
  const {
    context: {isCancelled, pickupStopTitle}
  } = useV3Shipment(shipmentId);
  const shipmentIsFetching = useIsFetching([SHIPMENTS_QUERY_KEY, shipmentId]);
  const addEditContainersText = `Add or Edit ${pluralize('Container', numberOfContainers)}`;

  return selectedContainers.length > 0 ? (
    <DeprecatedButton variant="secondary" disabled={!!shipmentIsFetching} onClick={toggleShowPickupDeliveryModal}>
      Edit {pickupStopTitle} & Delivery Dates
    </DeprecatedButton>
  ) : isCancelled ? (
    <span className="cursor-default text-sw-disabled">{addEditContainersText}</span>
  ) : (
    <Link to={`/shipments/${shipmentId}/containers/edit`}>{addEditContainersText}</Link>
  );
};

const ContainerDetailsLink = ({path, containerId}: {path: string; containerId: string}) => (
  <Link to={path}>{isEmpty(containerId) ? 'View' : containerId}</Link>
);

const LinkWrapper = ({legId, containerId}: {legId: string; containerId: string}) => {
  const path = `/leg/${legId}`;

  return containerId ? (
    <ContainerDetailsLink path={path} containerId={containerId} />
  ) : (
    <div className="flex">
      <div className="pr-2">--</div>
      <ContainerDetailsLink path={path} containerId={containerId} />
    </div>
  );
};

const ContainerCarrierName = ({shipmentId, legId}: {shipmentId: string; legId: string}) => {
  const {getTransportationOrdersOfStatus} = useTransportationOrders(shipmentId);
  const transportationOrders = getTransportationOrdersOfStatus(OrderStatus.Accepted) || [];
  const assignedTender = transportationOrders.find((tender) => tender.leg_ids?.includes(legId));
  return <>{assignedTender?.service_provider.name || '--'}</>;
};

export const MutableContainersTable = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId, {
    cacheTime: 0
  });
  const {
    context: {pickupStopTitle}
  } = shipmentQuery;
  const [selectedContainers, setSelectedContainers] = useState<Container[]>([]);
  const [showPickupDeliveryModal, toggleShowPickupDeliveryModal] = useToggle();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({value, row}: {value: string; row: {original: {containerId: string}}}) => (
          <LinkWrapper containerId={row.original.containerId} legId={value} />
        )
      },
      {
        Header: `${pickupStopTitle} Date`,
        accessor: 'pickupDate'
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate'
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 200,
        Cell: ({value}: {value: DrayageLegOverallStatus}) => <LegStatusPillBase fixedSize status={value} />
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
        Cell: ({row}: {row: {original: {id: string}}}) => (
          <ContainerCarrierName shipmentId={shipmentId} legId={row.original.id} />
        )
      }
    ],
    [shipmentId, pickupStopTitle]
  );

  const handleSelection = useCallback((selection: Container[]) => {
    setSelectedContainers(selection);
  }, []);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {
    context: {legsTableValues}
  } = shipmentQuery;

  return (
    <div className="p-4">
      <Card
        draggableProvided={null}
        title="Container Details"
        bodyClassName="p-0"
        actions={
          <ContainerActions
            toggleShowPickupDeliveryModal={toggleShowPickupDeliveryModal}
            selectedContainers={selectedContainers}
            numberOfContainers={legsTableValues.length}
            shipmentId={shipmentId}
          />
        }
      >
        <Table
          columns={columns}
          data={legsTableValues}
          showRowSelect
          itemLabel="Containers"
          onSelection={handleSelection}
        />
      </Card>
      <ContainerPickupDeliveryDateModal
        onClose={toggleShowPickupDeliveryModal}
        showModal={showPickupDeliveryModal}
        shipmentId={shipmentId}
        selectedContainers={selectedContainers}
      />
    </div>
  );
};
const IdCell = ({row}: {row: {original: {containerId: string; sealNumber: string}}}) => (
  <>
    <div>{row.original.containerId}</div>
    <div>{row.original.sealNumber}</div>
  </>
);

const DrayageContainersTable = ({
  containerDisplayValues
}: {
  containerDisplayValues: {
    containerId: string;
    sealNumber: string;
    quantity: string;
    packagingType: string;
    containerType: string;
    description: string;
    returnBy: string;
    hazmat?: Hazmat;
    weightInfo: {
      weightUnit: string;
      formattedWeightWithUnit: string;
      overweight: boolean;
    };
  }[];
}) => {
  const weightUnit = containerDisplayValues?.[0]?.weightInfo.weightUnit;
  const weightUnitDisplay = weightUnit ? ` (${weightUnit.toLowerCase()})` : '';

  const columns = useMemo(
    () => [
      {
        Header: 'IDs',
        Cell: IdCell
      },
      {
        Header: `Total Weight${weightUnitDisplay}`,
        accessor: 'weightInfo',
        Cell: WeightCell
      },
      {
        Header: 'Container Type',
        accessor: 'containerType'
      },
      {
        Header: 'Commodity',
        accessor: 'description'
      },
      {
        Header: 'Items',
        accessor: 'numOfItems',
        maxWidth: 80
      },
      {
        Header: 'Return Date',
        accessor: 'returnBy'
      },
      {
        Header: 'Hazmat',
        accessor: 'hazmat',
        Cell: ({value}: {value: string}) => (value ? 'Y' : 'N')
      }
    ],
    [weightUnitDisplay]
  );

  return <Table columns={columns} data={containerDisplayValues} />;
};

const DrayageContainersTableView = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const {
    context: {containerDisplayValues}
  } = shipmentQuery;

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return <DrayageContainersTable containerDisplayValues={containerDisplayValues} />;
};

export default DrayageContainersTableView;
