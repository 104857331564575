export function CardTitle() {
  return (
    <div className={'flex items-center gap-3'}>
      <div>
        <img src="/images/tive_logo.svg" alt="Tive Logo" className="h-4" />
      </div>
      <div>Tive Shipment Tracking</div>
    </div>
  );
}
