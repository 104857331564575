import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import get from 'lodash/get';
import {getCustomDataPath} from './customDataPath';

/**
 * Make a redux-form validation errors object given a value and custom fields definitions. This is
 * meant to be used in a redux-form `validate` function. Its signature mirrors the `validate` function
 * in that it takes a value and a "prop", but it's named as such to indicate that it is _not_ a
 * drop-in replacement for `validate`.
 *
 * @param {Object.<*, *>} value The value to validate, usually a line item with custom dataerror
 * @param {Array.<Object<string, string>>} customFields The list of custom fields against which we are validating
 */
export function makeValidationErrors(
  value,
  customFields = [],
  entityType = CustomFieldEntityTypesEnum.ShipmentLineItem,
  userCompanyId = null
) {
  return (
    customFields
      // filter to only required fields that are owned by the current user's company
      .filter((cf) => cf.required && isCustomFieldOwner(cf.company, userCompanyId))
      .reduce(
        (errors, cf) =>
          // get the value of this custom field
          get(value, `custom_data.${getCustomDataPath(entityType)}.${cf.id}`, null)
            ? errors
            : {...errors, [cf.id]: `${cf.label} is required.`}, // only set the error for this custom field if a value isn't set
        {}
      )
  );
}

/**
 * Returns a boolean that signifies whether the custom field in question was defined by the company of the current user.
 * For backwards compatibility and to avoid unnecessary failure where non-applicable, returns true by default if no userCompanyId is provided.
 */
export function isCustomFieldOwner(customFieldCompanyId, userCompanyId) {
  if (!userCompanyId) {
    return true;
  }
  return customFieldCompanyId === userCompanyId;
}
