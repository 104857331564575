import {FormGroup, ControlLabel, OverlayTrigger, Tooltip, FormControl} from 'react-bootstrap';

const style = {
  error: {
    color: '#d43b3b'
  },
  tooltip: {
    display: 'block',
    textAlign: 'left'
  }
};

const renderDropDownSelector = ({
  id,
  req,
  defaultValue,
  tabindex,
  input,
  tooltip,
  label,
  key,
  data,
  placeholder,
  displayData,
  extraClass,
  nolabel,
  disabled,
  sronly,
  meta: {touched, error},
  chargeLineItemsCustomFieldsFix
}) => (
  <FormGroup
    controlId={input.name}
    className={extraClass && extraClass}
    validationState={touched && error ? 'error' : null}
  >
    {label ? (
      <span className="dropdown-label">
        <ControlLabel className={sronly && 'sr-only'}>
          {label}
          {req && <span className="requiredField">*</span>}
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <div style={style.tooltip}>{tooltip}</div>
                </Tooltip>
              }
            >
              <a tabIndex="-1">
                <i className="icon icon-Info" aria-hidden="true" />
              </a>
            </OverlayTrigger>
          )}
        </ControlLabel>
        {displayData && <span className="form-display-data-small">{displayData}</span>}
      </span>
    ) : displayData ? (
      <p className="form-display-data">{displayData}</p>
    ) : nolabel ? null : (
      <ControlLabel>&nbsp;</ControlLabel>
    )}

    <FormControl
      {...input}
      componentClass="select"
      disabled={disabled}
      tabIndex={tabindex}
      id={id}
      onChange={(event) => {
        input.onChange(event.target.value);
      }}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {data &&
        data.map((val) => (
          <option value={chargeLineItemsCustomFieldsFix ? val.name : val.id} key={val.id}>
            {val.name}
          </option>
        ))}
    </FormControl>
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderDropDownSelector;
