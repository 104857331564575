import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {get, set} from 'lodash';
import {Toast} from '@shipwell/shipwell-ui';
import {Link} from 'react-router';
import {BulkOrderActions} from '../list/components/selectedPurchaseOrdersListActions';
import {
  requestPurchaseOrderLineItemsPending,
  requestPurchaseOrderLineItemsSuccess,
  requestShipmentPurchaseOrderSuccess,
  updatePurchaseOrderPending,
  updatePurchaseOrderSuccess
} from './index';
import {getCreatedShipmentQueryString, mapOrdersToLoad} from 'App/containers/loadOptimization/utils';
import {purchaseOrders, brokers} from 'App/api';
import {getPurchaseOrders as getPurchaseOrdersTyped} from 'App/api/purchaseOrders/typed';
import {createShipment} from 'App/api/loadOptimization';
import {
  getPurchaseOrdersList,
  updatePurchaseOrderById as updatePurchaseOrderByIdAction,
  getPurchaseOrderLineItemList as getPurchaseOrderLineItemListAction
} from 'App/actions/orders';

export function getPurchaseOrders(params = {}) {
  return (dispatch) => getPurchaseOrdersTyped(params);
}
export function getPurchaseOrderById(id) {
  return (dispatch) => purchaseOrders.getPurchaseOrderById(id);
}

export function updatePurchaseOrderById(id, body = {}) {
  //the list route of purchaseOrders has a slim shipment object, send a UUID string
  if (get(body, 'shipment.id')) {
    set(body, 'shipment', body.shipment.id);
  }
  return (dispatch) => purchaseOrders.updatePurchaseOrderById(id, body);
}

export function createPurchaseOrder(options) {
  return (dispatch) => purchaseOrders.createPurchaseOrder(options);
}

export function createPurchaseOrderLineItem(orderId, body = {}) {
  return (dispatch) => purchaseOrders.createPurchaseOrderLineItem(orderId, body);
}
export function updatePurchaseOrderLineItem(orderId, lineItemId, body = {}) {
  return (dispatch) => purchaseOrders.updatePurchaseOrderLineItem(orderId, lineItemId, body);
}
export function deletePurchaseOrderLineItem(orderId, lineItemId) {
  return (dispatch) => purchaseOrders.deletePurchaseOrderLineItem(orderId, lineItemId);
}

export function getPurchaseOrderLineItemList(orderId, options = {}) {
  return (dispatch) => purchaseOrders.getPurchaseOrderLineItemList(orderId);
}

/**
 * Create Shipment (or Quote)
 * @param {String} type
 */
export const createShipmentFromPurchaseOrders = async (
  creationType,
  companyId,
  selectedPurchaseOrders,
  mode,
  distributionMode
) => {
  //format purchase orders to use shipment-assembly api schema
  selectedPurchaseOrders = mapOrdersToLoad(selectedPurchaseOrders).orders;
  try {
    const response = await createShipment({
      orders: selectedPurchaseOrders,
      customer_id: companyId,
      mode,
      distribution_mode: distributionMode
    });
    return getCreatedShipmentQueryString(creationType, response.data.id, selectedPurchaseOrders, response.data.mode);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/**
 * Loading message component
 * @param {String} creationType
 * @param {Array} selectedPurchaseOrders
 * @param {Boolean} includeRefreshWarning
 * @return {Component}
 */
export const LoadingMessage = ({creationType, selectedPurchaseOrders, includeRefreshWarning}) => {
  return Array.isArray(selectedPurchaseOrders) && selectedPurchaseOrders.length > 0 ? (
    <div className="text-center">
      <div className="text-sw-text">
        {`Consolidating ${pluralize('Order', selectedPurchaseOrders.length, true)} into ${
          creationType === BulkOrderActions.MultipleShipments
            ? `${pluralize('Shipment', selectedPurchaseOrders.length, true)}`
            : creationType === BulkOrderActions.Quote
            ? 'a Quote'
            : 'a Shipment'
        }`}
      </div>
      {includeRefreshWarning ? (
        <div className="mt-2 text-sw-text">
          Do not refresh the page. You will be taken to the next step once the orders have finished consolidating.
        </div>
      ) : null}
    </div>
  ) : null;
};

LoadingMessage.propTypes = {
  includeRefreshWarning: PropTypes.bool,
  selectedPurchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number
    })
  ),
  creationType: PropTypes.string.isRequired
};
LoadingMessage.defaultProps = {
  includeRefreshWarning: false,
  selectedPurchaseOrders: [],
  type: []
};

/**
 * Get Customers
 * @param {Number} brokerageId
 * @param {Object} opts
 */
export function getCustomers(brokerageId, opts = {}) {
  return (dispatch) => brokers.fetchBrokerShipperRelationshipsPromise(brokerageId, opts);
}

export function getPurchaseOrderByShipment(id, options = {}) {
  return (dispatch) => {
    return getPurchaseOrdersList({shipmentId: id, ...options})
      .then((response) => {
        dispatch(requestShipmentPurchaseOrderSuccess(response));
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

/**
 * Async action - Update purchase order
 * @return {Promise}
 */
export function updatePurchaseOrder(id, body = {}) {
  return (dispatch) => {
    dispatch(updatePurchaseOrderPending());

    return updatePurchaseOrderByIdAction(id, body)
      .then((response) => {
        dispatch(updatePurchaseOrderSuccess(response));
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

/**
 * Async action - Fetch purchase orders line items
 * @return {Promise}
 */
export function getPurchaseOrderLineItems(id) {
  return (dispatch) => {
    dispatch(requestPurchaseOrderLineItemsPending());

    return getPurchaseOrderLineItemListAction(id)
      .then((response) => {
        dispatch(requestPurchaseOrderLineItemsSuccess(response));
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export const ShipmentCreationErrorsToast = ({selectedPurchaseOrdersWithErrors, show}) => {
  return (
    <Toast show={show} title="Order Consolidation Error" variant="error" anchor="top-right" delay={null}>
      {selectedPurchaseOrdersWithErrors?.length > 0 && (
        <>
          <p>
            Your {pluralize('order', selectedPurchaseOrdersWithErrors.length)} could not be consolidated. Correct the
            {pluralize(' error', selectedPurchaseOrdersWithErrors.length)} in the following
            {pluralize(' order', selectedPurchaseOrdersWithErrors.length)} and try again.
          </p>
          {selectedPurchaseOrdersWithErrors.map((orderWithError) => (
            <div className="error-link" key={orderWithError.order.id}>
              <Link
                key={orderWithError.order.id}
                to={`/purchase-orders/${orderWithError.order.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Order {orderWithError.order.order_number || ''}
              </Link>
              {Object.keys(orderWithError.error).length > 0 ? (
                <ul className="ml-6">
                  {Object.keys(orderWithError.error)?.map((error) => (
                    <li key={error} className="list-disc capitalize">
                      Invalid {error.split('_').join(' ')}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          ))}
        </>
      )}
    </Toast>
  );
};

ShipmentCreationErrorsToast.propTypes = {
  selectedPurchaseOrdersWithErrors: PropTypes.array,
  show: PropTypes.bool
};

export const handleCreateShipment = async (creationType, companyId, selectedPurchaseOrders, mode, distributionMode) =>
  await createShipmentFromPurchaseOrders(creationType, companyId, selectedPurchaseOrders, mode, distributionMode);
