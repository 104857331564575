import {Appointment} from '@shipwell/tempus-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ShipwellApiErrorResponse} from '@shipwell/settlements-sdk';
import {AxiosError} from 'axios';
import {scheduleAppointment} from 'App/api/appointments';

export type UseScheduleAppointmentMutationVariables = {
  appointmentId: string;
  timezone: string;
  start: Date;
  end: Date;
  dockId?: string;
  override?: boolean;
};

export type UseScheduleAppointmentMutationOptions = Omit<
  UseMutationOptions<
    Appointment,
    AxiosError<ShipwellApiErrorResponse, unknown>,
    UseScheduleAppointmentMutationVariables,
    unknown
  >,
  'mutationFn'
>;
/**
 * Use this to schedule appointments that are currently in an UNSCHEDULED status
 */
const useScheduleAppointmentMutation = (options?: UseScheduleAppointmentMutationOptions) => {
  const mutation = useMutation(
    ({appointmentId, timezone, start, end, dockId, override}: UseScheduleAppointmentMutationVariables) => {
      return scheduleAppointment(appointmentId, timezone, start, end, dockId, override);
    },
    options
  );

  return {
    isSchedulingAppointment: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    appointment: mutation.data || null,
    scheduleAppointmentErrors: mutation.error?.response?.data?.errors,
    scheduleAppointment: mutation.mutate,
    scheduleAppointmentAsync: mutation.mutateAsync
  };
};

export {useScheduleAppointmentMutation};
