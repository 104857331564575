import {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Grid} from 'react-bootstrap';

export default class Error404Page extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header" />
        <section className="content">
          <Grid fluid>
            <section className="content">
              <div className="error-page">
                <h2 className="headline text-yellow">404</h2>
                <div className="error-content">
                  <h3>
                    <i className="icon icon-Delayed pad-right" />
                    Oops! Page not found.
                  </h3>
                  <p>
                    We could not find the page you were looking for.
                    <br /> Meanwhile, you may&nbsp;
                    <Link to="/dashboard">&nbsp;return to dashboard</Link>
                  </p>
                </div>
              </div>
            </section>
          </Grid>
        </section>
      </div>
    );
  }
}
