import {CreateUser} from './CreateUser';
import {UserMeContextProvider} from 'App/data-hooks/users/useUserMe';

export default function CompanyUserCreeateContainer() {
  return (
    <UserMeContextProvider>
      <CreateUser />
    </UserMeContextProvider>
  );
}
