import {QueryKey, useIsFetching, useQueryClient} from '@tanstack/react-query';
import {COMPANY_UNIT_PREFERENCES} from 'App/data-hooks/queryKeys';
import {countryDefaults} from 'App/utils/internationalConstants';

type CountryDefault = (typeof countryDefaults)[number]['unitPreferences'];

// This is used by a few cells to get the company unit preferences from the query cache...
// ...so that only a single request needs to be made within the DashboardTable component
export const useGetCompanyPreferences = () => {
  const isFetching = useIsFetching([COMPANY_UNIT_PREFERENCES]) > 0;
  const queryClient = useQueryClient();
  const getCompanyUnitPreferencesQuery = () => {
    const companyUnitPreferencesQueries = queryClient.getQueriesData([COMPANY_UNIT_PREFERENCES]) as unknown as [
      QueryKey,
      CountryDefault | undefined
    ][];
    const [companyUnitPreferencesQuery] = companyUnitPreferencesQueries;
    return companyUnitPreferencesQuery?.[1];
  };

  const unitPreferences = getCompanyUnitPreferencesQuery();

  return {
    isFetching,
    unitPreferences
  };
};
