import {useContext, useState} from 'react';
import {Form, Formik, FieldArray} from 'formik';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import first from 'lodash/first';
import cloneDeep from 'lodash/cloneDeep';
import pluralize from 'pluralize';
import {v4} from 'uuid';
import classnames from 'classnames';
import {Card, SvgIcon, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {AxiosError, AxiosResponse} from 'axios';
import {
  CreateFacility,
  ShipwellApiErrorResponse,
  CreateFacilityPointOfContact,
  BulkUpdateHoursOfOperationRequest,
  CreateLoadType,
  CreateFacilityDock,
  CreateFacilityDockAppointmentRule,
  DeliveryTypeEnum,
  DockSchedulingModeEnum,
  DockSchedulingEquipmentTypeEnum,
  PackagingTypes,
  DockSchedulingAccessorialTypeEnum,
  TemperatureUnitEnum,
  CreateFacilityHoliday,
  FacilityDocumentTypeEnum,
  Facility,
  FacilityAppointmentType
} from '@shipwell/tempus-sdk';

import invariant from 'tiny-invariant';
import {facilityDocksValidationSchema} from 'App/containers/facilities/schema';
import {DockType, DockTypeDraft} from 'App/data-hooks/facilities/types';
import {entryToCreateFacilityHoliday} from 'App/data-hooks/facilities/holidayUtils';
import {
  formatV2AddressToFacilityAddress,
  isFulfilled,
  formatDockRuleCreationPayload
} from 'App/data-hooks/facilities/facilityUtils';

import {StepFormContext} from 'App/containers/facilities/FacilityCreationModal';
import FacilityCreationModalFooter from 'App/containers/facilities/components/FacilityCreationModalFooter';
import {FieldArraySideNav} from 'App/components/FieldArray/FieldArraySideNav';
import {facilityFormDataDefaultValues} from 'App/data-hooks/facilities/constants';
import FacilityDocksFields from 'App/formComponents/formSections/facilityDocksFields';
import {
  createFacility,
  createPointOfContactFacility,
  bulkUpdateHoursOfOperation,
  createFacilityLoadType,
  createDock,
  createDockRules,
  createHolidayFacility,
  createFacilityDocument
} from 'App/api/facilities';
import {FACILITIES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getAddressBookAddressById, updateAddressBookAddressById} from 'App/api/addressBook/typed';
import {useCreateAddressBookEntryMutation} from 'App/containers/addressBook/hooks';
import {convertShipwellError} from 'App/utils/errors';
import {mapLocationTypes} from 'App/data-hooks/locations/useUpdateFacilityAddressBookEntryMutation';
import {useLocationTypesQuery} from 'App/data-hooks';

const DocksForm = () => {
  const queryClient = useQueryClient();
  const {facilityFormData, setFacilityFormData, onClose, setFacilityCreationToast} = useContext(StepFormContext);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const handleSubmissionToast = (error?: AxiosError<ShipwellApiErrorResponse>) => {
    if (!error) {
      setFacilityCreationToast({title: 'Success!', message: 'Facility was created successfully.'});
    } else {
      const firstError = first(error?.response?.data.errors);
      setFacilityCreationToast({
        title: firstError?.title || 'Error!',
        message: firstError?.detail || 'Unknown error occurred.',
        isError: true
      });
    }
  };
  const handleFacilityDocksSubmit = (values: {docks: DockType[]}) => {
    const {docks} = values;
    const submittedDocks = docks?.length > 0 ? cloneDeep(docks) : [];
    /* since all the fields in a dock rule are optional
      we'll need to cleanup the docks list such that
      there isn't a dockRule where all its fields are empty */

    submittedDocks.forEach((dock) => {
      return dock.dockRules.forEach((dockRule, index) => {
        if (Object.values(dockRule).every((value) => !value)) {
          dock.dockRules.splice(index, 1);
        }
      });
    });

    const facilityFormDataWithDocks = {
      ...facilityFormData,
      docks: [...submittedDocks]
    };

    const formattedFacilityInfo = {
      ...facilityFormDataWithDocks.facilityInfo,
      address: formatV2AddressToFacilityAddress(facilityFormDataWithDocks.facilityInfo.address),
      exclude_dock_assignment_from_appointment_scheduling:
        facilityFormDataWithDocks.appointmentRules.exclude_dock_assignment_from_appointment_scheduling,
      appointment_lead_time_duration: facilityFormDataWithDocks.appointmentRules.appointment_lead_time_duration,
      late_appointment_threshold_duration:
        facilityFormDataWithDocks.appointmentRules.late_appointment_threshold_duration,
      first_appointment_start_time: facilityFormDataWithDocks.appointmentRules.first_appointment_start_time,
      last_appointment_end_time: facilityFormDataWithDocks.appointmentRules.last_appointment_end_time
    };

    setFacilityFormData(facilityFormDataWithDocks);
    //step 1: create facility
    createFacilityMutation.mutate(formattedFacilityInfo, {
      onSuccess: (async (data: AxiosResponse<Facility>) => {
        const newFacilityId = data.data.id;
        const facility = data.data;
        const {id: addressBookEntryId} = formattedFacilityInfo.address;
        if (addressBookEntryId) {
          // associate address book entry with facility
          try {
            const {data: addressBookEntry} = await getAddressBookAddressById(addressBookEntryId);
            addressBookEntry.facility_id = newFacilityId;
            await updateAddressBookAddressById({
              addressBookId: addressBookEntryId,
              addressBookEntry: addressBookEntry
            });
          } catch {
            console.warn('Unable to find address book entry', addressBookEntryId, 'for company.');
          }
        } else {
          // it's possible that if the user tries to create a new address book entry that already exists
          // with the same location_name or the same external_reference this logic will fail and they
          // will not be able to use dock scheduling until they have resolved it.
          // REF: https://shipwell.atlassian.net/browse/FI-2125
          invariant(facility.address.country, 'Country expected to create facility');
          // need to create a new address book entry for the user
          await createAddressBookEntry({
            location_name: facility.name,
            external_reference: facility.external_reference,
            is_default_origin: false,
            facility_id: facility.id,
            company_name: facility.name,
            address: {
              address_1: facility.address.line_1,
              address_2: facility.address.line_2,
              city: facility.address.locality,
              state_province: facility.address.region,
              country: facility.address.country,
              longitude: facility.address.geolocation?.longitude,
              latitude: facility.address.geolocation?.latitude
            },
            point_of_contacts: [],
            location_type: mapLocationTypes(facility.location_type, locationTypes || [])
          });
        }
        // step 1-a (optional): upload documents, if any
        const facilityDocuments = formattedFacilityInfo.documents || [];
        if (facilityDocuments) {
          const facilityDocumentMutations = facilityDocuments?.map((doc) => {
            if ('file' in doc) {
              return createFacilityDocumentMutation.mutateAsync(
                {
                  facilityId: newFacilityId,
                  file: doc.file,
                  document_type: FacilityDocumentTypeEnum.Map,
                  description: doc.description
                },
                {onError: (error) => handleSubmissionToast(error)}
              );
            }
          });
          await Promise.allSettled(facilityDocumentMutations);
        }
        // step 2: create PoC
        const pocMutations = facilityFormDataWithDocks.facilityContacts.map((contact) =>
          pocCreateMutation.mutateAsync(
            {facilityId: data.data.id, body: contact},
            {onError: (error) => handleSubmissionToast(error)}
          )
        );
        await Promise.allSettled(pocMutations);
        // step 3: create bulk hours of operations
        createBulkHoOpMutation.mutate(
          {
            facilityId: data.data.id,
            body: {hours_of_operation: facilityFormDataWithDocks.facilityOperationCapacity}
          },
          {onError: (error) => handleSubmissionToast(error)}
        );
        if (facilityFormDataWithDocks?.holidayRules) {
          const submittedHolidayRules = cloneDeep(facilityFormDataWithDocks?.holidayRules);
          const holidayMutations = submittedHolidayRules?.map((holidayRule) => {
            const createHoliday = entryToCreateFacilityHoliday(holidayRule);
            return createHolidayMutation.mutateAsync(
              {facilityId: newFacilityId, body: createHoliday},
              {onError: (error) => handleSubmissionToast(error)}
            );
          });
          if (holidayMutations?.length) {
            await Promise.allSettled(holidayMutations);
          }
        }
        // step 4: create load types
        const loadTypeMutations = facilityFormDataWithDocks.loadTypes.map((loadType) =>
          createLoadTypeMutation.mutateAsync(
            {facilityId: data.data.id, body: loadType},
            {onError: (error) => handleSubmissionToast(error)}
          )
        );
        /**
         * keep track of the successfully created load types
         * they will be needed when creating dock rules below.
         */
        const loadTypesResponses = await Promise.allSettled(loadTypeMutations);

        const fulfilledLoadTypes = loadTypesResponses.filter(isFulfilled);

        //step 5-a: create docks, after a dock gets created, get its ID
        for (const dock of facilityFormDataWithDocks.docks) {
          try {
            const {data: createdDock} = await createDockMutation.mutateAsync(
              {facilityId: newFacilityId, body: {name: dock.name, color: '#FFFFFF'}},
              {onError: (error) => handleSubmissionToast(error)}
            );
            const createdDockId = createdDock.id;
            // step 5-b: create dockRules for the newly created dock, using its ID
            for (const dockRule of dock.dockRules) {
              try {
                const createdLoadTypeId = fulfilledLoadTypes
                  .map((fulfilledLT) => fulfilledLT.value.data)
                  .find((ltValue) => ltValue.name === dockRule.load_type_id?.slice(0, -1))?.id;
                const rulesPayload = formatDockRuleCreationPayload(dockRule, createdLoadTypeId);
                await createDockRulesMutation.mutateAsync(
                  {
                    facilityId: newFacilityId,
                    dockId: createdDockId,
                    body: rulesPayload
                  },
                  {onError: (error) => handleSubmissionToast(error)}
                );
              } catch (error) {
                console.error('Error creating dock rule', error);
              }
            }
          } catch (error) {
            console.error('Error creating dock', error);
          }
        }

        return handleSubmissionToast();
      }) as (data: AxiosResponse<Facility>) => void,
      onError: (error) => handleSubmissionToast(error),
      onSettled: () => {
        void queryClient.invalidateQueries([FACILITIES_QUERY_KEY]);
        setFacilityFormData(facilityFormDataDefaultValues);
        onClose();
      }
    });
  };

  const {mutateAsync: createAddressBookEntry} = useCreateAddressBookEntryMutation({
    onError: (error) => {
      // AxiosError<ShipwellApiError> is legacy. To keep consistent and backwards compatible map here.
      if (error.response?.data) {
        handleSubmissionToast({
          ...error,
          response: {
            ...error.response,
            data: convertShipwellError(error.response?.data)
          }
        });
      }
    }
  });
  const {locationTypes} = useLocationTypesQuery();

  const createFacilityMutation = useMutation<
    Awaited<ReturnType<typeof createFacility>>,
    AxiosError<ShipwellApiErrorResponse>,
    CreateFacility
  >((payload) => createFacility(payload));

  const pocCreateMutation = useMutation<
    Awaited<ReturnType<typeof createPointOfContactFacility>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateFacilityPointOfContact}
  >(({facilityId, body}) => createPointOfContactFacility(facilityId, body));

  const createBulkHoOpMutation = useMutation<
    Awaited<ReturnType<typeof bulkUpdateHoursOfOperation>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: BulkUpdateHoursOfOperationRequest}
  >(({facilityId, body}) => bulkUpdateHoursOfOperation(facilityId, body));

  const createLoadTypeMutation = useMutation<
    Awaited<ReturnType<typeof createFacilityLoadType>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateLoadType}
  >(({facilityId, body}) => createFacilityLoadType(facilityId, body));

  const createDockMutation = useMutation<
    Awaited<ReturnType<typeof createDock>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateFacilityDock}
  >(({facilityId, body}) => createDock(facilityId, body));

  const createDockRulesMutation = useMutation<
    Awaited<ReturnType<typeof createDockRules>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; dockId: string; body: CreateFacilityDockAppointmentRule}
  >(({facilityId, dockId, body}) => createDockRules(facilityId, dockId, body));

  const createHolidayMutation = useMutation<
    Awaited<ReturnType<typeof createHolidayFacility>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; body: CreateFacilityHoliday}
  >(({facilityId, body}) => createHolidayFacility(facilityId, body));

  const createFacilityDocumentMutation = useMutation<
    Awaited<ReturnType<typeof createFacilityDocument>>,
    AxiosError<ShipwellApiErrorResponse>,
    {facilityId: string; file: File; document_type?: string; description?: string}
  >(({facilityId, file, document_type, description}) =>
    createFacilityDocument(facilityId, file, document_type, description)
  );

  const handleAddNew = (push: (dock: DockTypeDraft) => void, values: {docks: DockTypeDraft[]}) => {
    if (values?.docks) {
      setCurrentFormIndex(values?.docks?.length);
    } else {
      setCurrentFormIndex(0);
    }
    push({
      key: v4(),
      name: '',
      color: '',
      dockRules: [
        {
          appointment_type: '' as FacilityAppointmentType,
          load_type_id: '',
          delivery_type: '' as DeliveryTypeEnum,
          mode: '' as DockSchedulingModeEnum,
          equipment_type: '' as DockSchedulingEquipmentTypeEnum,
          product_reference: '',
          packaging_type: '' as PackagingTypes,
          product_category: [],
          dock_accessorials: [] as DockSchedulingAccessorialTypeEnum[],
          first_appointment_start_time: '',
          last_appointment_end_time: '',
          temperature: {
            unit: TemperatureUnitEnum.F,
            minimum: '',
            maximum: ''
          },
          is_public: true,
          key: v4()
        }
      ]
    });
  };
  const handleRemove = (remove: (index: number) => void, values: {docks: DockType[]}) => {
    setCurrentFormIndex(values?.docks?.length - 2);
    remove(currentFormIndex);
  };

  const getLabelsForDocks = (values: {docks: DockType[]}) => {
    return values?.docks?.map((dock, index) => `Dock ${index + 1} ${dock?.name && '\u00b7'} ${dock?.name}`);
  };

  const getCurrentDockTitle = (currentDock: DockType) => {
    const numberOfDockRules = currentDock?.dockRules?.length;
    return (
      <div className="flex items-center gap-x-2">
        <div>Dock</div>
        {currentDock?.name ? <div>{currentDock?.name}</div> : null}
        {currentDock?.name ? (
          <div className="text-xs font-normal text-sw-disabled">
            {numberOfDockRules} {pluralize('Rule', numberOfDockRules)}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="my-10">
      <Formik
        validateOnMount
        initialValues={{
          docks: facilityFormDataDefaultValues?.docks as DockTypeDraft[]
        }}
        validationSchema={facilityDocksValidationSchema}
        onSubmit={handleFacilityDocksSubmit}
      >
        {({isValid, values, errors, isSubmitting}) => (
          <Form className={classnames({'h-[520px]': !values?.docks?.length})} noValidate>
            <FieldArray
              name="docks"
              render={({push, remove}) => (
                <div className="flex h-full">
                  <div className="mr-3 max-h-[520px] flex-[1] bg-sw-background pt-5">
                    <FieldArraySideNav
                      title="Dock"
                      labels={getLabelsForDocks(values)}
                      activeIndex={currentFormIndex}
                      onActiveIndexChange={setCurrentFormIndex}
                      onAddNew={() => handleAddNew(push, values)}
                      isAddNewDisabled={!isValid}
                    />
                  </div>
                  <div className="flex-[4]">
                    <Card
                      draggableProvided={false}
                      title={getCurrentDockTitle(values?.docks?.[currentFormIndex])}
                      isCollapsible
                      actions={
                        <>
                          {values?.docks?.length > 1 ? (
                            <SvgIcon name="TrashOutlined" onClick={() => handleRemove(remove, values)} />
                          ) : null}
                        </>
                      }
                    >
                      <CollapsibleCardContent>
                        <FacilityDocksFields
                          currentFormIndex={currentFormIndex}
                          values={values}
                          loadTypesList={facilityFormData.loadTypes}
                          loadTypeOptions={facilityFormData.loadTypes.map((loadType, index) => ({
                            label: loadType.name,
                            value: `${loadType.name}${index}`
                          }))}
                        />
                      </CollapsibleCardContent>
                      {!Array.isArray(errors.docks) ? <div className="my-2 text-sw-error">{errors?.docks}</div> : null}
                    </Card>
                  </div>
                </div>
              )}
            />
            <FacilityCreationModalFooter isValid={isValid} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DocksForm;
