import {IconButton, Popover, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {camelCase, capitalize, isUndefined, startCase} from 'lodash';
import {DatResponse, ResponseStop} from 'App/data-hooks/pricing/useDatPricing';
import threeDigitZipCodes from 'App/utils/threeDigitZipCodes';
import {getFormattedPrice} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import {ExternalRatesType} from 'App/containers/Shipment/components/EmbeddedPricingIntel';

const DisplayRate = ({
  rateValues,
  showDPM,
  totalMiles,
  averageFuelSurchargePerTripUsd,
  averageFuelSurchargePerMileUsd,
  pricingSource,
  toggleShowCalculator,
  setInitialRate,
  handleApplyRate,
  isDisabledCalculator,
  datResponse,
  showOnlyDatRates = false
}: {
  rateValues: ExternalRatesType;
  showDPM: boolean;
  totalMiles?: number | null;
  averageFuelSurchargePerTripUsd: number;
  averageFuelSurchargePerMileUsd: number;
  pricingSource: string;
  toggleShowCalculator: () => void;
  setInitialRate: (rate: string | null) => void;
  handleApplyRate?: (applyFor: string, rate: string) => void;
  isDisabledCalculator: boolean;
  datResponse?: DatResponse;
  showOnlyDatRates?: boolean;
}) => {
  const {label, rate, currency, perMileRate, isProviderIntegrated = false, isDATRate} = rateValues;
  const response = datResponse?.rateResponses?.[0]?.response;
  const datOrigin = response?.escalation?.origin;
  const datDestination = response?.escalation?.destination;
  const datReports = response?.rate?.reports || '--';
  const datCompanies = response?.rate?.companies || '--';
  const timeSpan = startCase(camelCase(response?.escalation?.timeframe.replace(/_/g, ' ')));
  const totalDistance = totalMiles || 0;

  const DATStop = ({datStop = {name: '', type: ''}}: {datStop?: ResponseStop}) => {
    const {name, type} = datStop;
    const formattedStop =
      type === '3_DIGIT_ZIP'
        ? (() => {
            const {city, state} = threeDigitZipCodes[name.slice(0, 3)];
            return `${name}: ${city}${state ? `, ${state}` : ''}`;
          })()
        : name;
    return (
      <div className="flex justify-center gap-1">
        <span className="font-bold">{formattedStop || '--'}</span> ({timeSpan || '--'})
      </div>
    );
  };

  const RateTooltipContent = ({rate}: {rate: string}) => {
    return (
      <div>
        <div className="flex items-center ">
          <DATStop datStop={datOrigin} />
          <SvgIcon name="ArrowForward" className="mx-1 fill-sw-text-reverse" />
          <DATStop datStop={datDestination} />
        </div>
        <div>{`DAT Broker-to-Carrier ${capitalize(pricingSource)} Price: ${rate}`}</div>
        <div>{`Reports: ${datReports}`}</div>
        <div>{`Companies: ${datCompanies}`}</div>
      </div>
    );
  };

  const formattedRate =
    rate || rate === 0
      ? getFormattedPrice({
          rate,
          perMileRate,
          averageFuelSurchargePerTripUsd,
          averageFuelSurchargePerMileUsd,
          isDATRate,
          totalDistance,
          showDPM
        })
      : '0';
  const rateWithCurrency = `${currency} ${formattedRate}`;

  return (
    <div className="my-2 grid grid-cols-[85%,15%] rounded border-1 border-sw-border px-6 py-2">
      <div className="flex items-center justify-center">
        {isDATRate && isProviderIntegrated ? (
          <Tooltip
            trigger="hover"
            tooltipContent={<RateTooltipContent rate={rateWithCurrency} />}
            placement="left"
            portal
          >
            <div>
              {label}: <span className="font-bold"> {isProviderIntegrated ? rateWithCurrency : '--'}</span>
            </div>
          </Tooltip>
        ) : (
          <div>
            {label}: <span className="font-bold"> {isProviderIntegrated ? rateWithCurrency : '--'}</span>
          </div>
        )}
      </div>
      <div className="flex items-center justify-end">
        {!isUndefined(rate) ? (
          <>
            <Tooltip trigger="hover" tooltipContent="Calculator" placement="left" tooltipClassname="flex">
              <IconButton
                disabled={!isProviderIntegrated || isDisabledCalculator || !rate}
                variant="tertiary"
                size="xs"
                iconName="Calculator"
                aria-label="Open calculator"
                onClick={() => {
                  toggleShowCalculator();
                  setInitialRate(label);
                }}
              />
            </Tooltip>
            <div className="ml-2 flex">
              {isProviderIntegrated ? (
                <>
                  {!showOnlyDatRates ? (
                    <Popover
                      placement="left"
                      showArrow
                      trigger={({
                        isOpen,
                        setIsOpen,
                        setTriggerElement
                      }: {
                        isOpen: boolean;
                        setIsOpen: (open: boolean) => void;
                        setTriggerElement: (el: HTMLElement | null) => void;
                      }) => (
                        <Tooltip trigger="hover" tooltipContent="Apply Rate" placement="left">
                          <IconButton
                            iconName="Copy"
                            aria-label="Apply rate"
                            disabled={!rate}
                            size="xs"
                            ref={setTriggerElement}
                            onClick={() => setIsOpen(!isOpen)}
                          />
                        </Tooltip>
                      )}
                    >
                      {({setIsOpen}: {setIsOpen: (open: boolean) => void}) => (
                        <Popover.MenuList>
                          <Popover.MenuListItemButton
                            aria-label="Apply to Max Buy"
                            onClick={() => {
                              if (handleApplyRate) {
                                handleApplyRate('max_buy_amount', formattedRate);
                                setIsOpen(false);
                              }
                            }}
                          >
                            Apply to Max Buy
                          </Popover.MenuListItemButton>
                          <Popover.MenuListItemButton
                            aria-label="Apply to Book Now"
                            onClick={() => {
                              if (handleApplyRate) {
                                handleApplyRate('buy_it_now_amount', formattedRate);
                                setIsOpen(false);
                              }
                            }}
                          >
                            Apply to Book Now
                          </Popover.MenuListItemButton>
                          <Popover.MenuListItemButton
                            aria-label="Apply to Target Rate"
                            onClick={() => {
                              if (handleApplyRate) {
                                handleApplyRate('target_rate_amount', formattedRate);
                                setIsOpen(false);
                              }
                            }}
                          >
                            Apply to Target Rate
                          </Popover.MenuListItemButton>
                        </Popover.MenuList>
                      )}
                    </Popover>
                  ) : null}
                </>
              ) : (
                <SvgIcon height="16" width="16" name="LockFilled" color="$sw-icon" />
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DisplayRate;
