import {objectKeys} from './betterTypedArrayMethods';

import {toTitleCase} from './string';

/**
 * The default function for rendering a CSV cell.
 */

const defaultFormat = (datum: unknown) => `${datum ?? ''}`;

/**
 * Before rendering a CSV, we figure out / fill in column metadata so that
 * the rendering function can be simple.
 */
type Column<T> = {
  title: string;
  prop: string & keyof T;
  format: (datum: T[keyof T], prop: keyof T, row: T) => string;
};

/**
 * A helper for `generateCSV` that computes an intuitive ordering of columns,
 * taking into account any hints, and ensures we have a uniform renderer for
 * each column.
 */
function determineCSVColumns<T extends object | Record<string, unknown>>(
  data: T[],
  columns?: {title: string; prop: keyof T; format?: (datum: T[keyof T], prop: keyof T, row: T) => string}[],
  complete?: boolean
): Column<T>[] {
  const allProps = new Set<string>();
  const titleProps = {} as Record<string, string>;
  const allPropOrderingPairsTree = {} as Record<string, Record<string, number>>;
  if (columns) {
    for (let i = 0; i < columns.length; i++) {
      const a = columns[i].title;
      allProps.add(a);
      const bag = (allPropOrderingPairsTree[a] = allPropOrderingPairsTree[a] ?? {});
      for (let j = i + 1; j < columns.length; j++) {
        const b = columns[j].title;
        bag[b] = (bag[b] ?? 0) + (1 + data.length);
      }
    }
  }
  if (!complete) {
    for (const row of data) {
      const keys = objectKeys(row);
      const nKeys = keys.length;
      for (let i = 0; i < nKeys; i++) {
        const title = toTitleCase(keys[i] as string);
        if (!allProps.has(title) && !(title in titleProps)) {
          titleProps[title] = keys[i] as string;
        }
        allProps.add(title);
        for (let j = i + 1; j < nKeys; j++) {
          const titleB = toTitleCase(keys[j] as string);
          const bag = (allPropOrderingPairsTree[title] = allPropOrderingPairsTree[title] ?? {});
          bag[titleB] = (bag[titleB] ?? 0) + 1;
        }
      }
    }
  }
  const orderEncountered = Array.from(allProps);
  function comparePropOrder(a: string, b: string): -1 | 0 | 1 {
    const nAbeforeB = (allPropOrderingPairsTree[a] ?? {})[b] ?? 0;
    const nBbeforeA = (allPropOrderingPairsTree[b] ?? {})[a] ?? 0;
    if (nAbeforeB > nBbeforeA) {
      return -1;
    }
    if (nAbeforeB < nBbeforeA) {
      return 1;
    }
    const kA = orderEncountered.indexOf(a);
    const kB = orderEncountered.indexOf(b);
    if (kA < kB) {
      return -1;
    }
    if (kA > kB) {
      return 1;
    }
    return 0;
  }
  return orderEncountered.sort(comparePropOrder).map((title) => {
    const entry = columns?.find((c) => c.title === title) ?? {
      prop: titleProps[title] ?? title,
      title,
      format: defaultFormat
    };
    entry.format = entry.format ?? defaultFormat;
    return entry as Column<T>;
  });
}

/**
 * A helper to render an array of cell content into a line of CSV.
 * Handles quoting commas and quotes.
 */
function formatLine(rawCells: string[]): string {
  const cells = rawCells.map((raw) => (/[,"]/.test(raw) ? `"${raw.replace(/"/g, '""')}"` : raw));
  return cells.join(',');
}

export type GenerateCSVOptions<T> = {
  columns?: {
    /** The name of the column, used as the header. */
    title: string;
    /** The name of the property within row objects to find data for this column. */
    prop: keyof T;
    /** An optional formatting function to control how data for this column is rendered. */
    format?: (datum: T[keyof T], prop: keyof T, row: T) => string;
  }[];
  columnsAreComplete?: boolean;
};
/**
 * Renders an array of objects as a CSV.
 * Column data is optional and inferred when missing.
 * When column data is given the ordering of columns is respected, otherwise it
 * is inferred from the ordering of properties on data row objects.
 */
export default function generateCSV<T extends object | Record<string, unknown>>(
  data: T[],
  options: GenerateCSVOptions<T> = {}
): string {
  const columns = determineCSVColumns(data, options.columns, options.columnsAreComplete);
  const lines = data.map((row) => {
    const rawCells = columns.map((col) => col.format(row[col.prop], col.prop, row));
    return formatLine(rawCells);
  });
  lines.unshift(formatLine(columns.map((col) => col.title)));
  return lines.map((line) => `${line}\r\n`).join('');
}
