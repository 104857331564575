import {useEffect, useState} from 'react';
import {withRouter, WithRouterProps} from 'react-router';

const ProcessCode = ({router, location}: WithRouterProps<unknown, {state?: string}>) => {
  const [integration, setIntegration] = useState<string>();
  const queryParams = location.query;

  useEffect(() => {
    if (queryParams.state) {
      const integrationName = decodeURIComponent(escape(window.atob(queryParams.state)));
      setIntegration(integrationName);
    }
  }, [queryParams.state]);

  if (integration) {
    router.push(`/company/integrations/${integration.toLowerCase()}${location.search}`);
  }

  return null;
};

export default withRouter(ProcessCode);
