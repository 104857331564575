import {Field} from 'formik';
import {FormikCheckbox, FormikTextInput} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';

const TableViewFields = ({filterName} = {filterName: 'Dashboard'}) => {
  return (
    <div>
      <Field required name="name" label={`${filterName} Name`} component={FormikTextInput} />
      <Field name="is_default" label="Set as default" component={FormikCheckbox} />
    </div>
  );
};
export default TableViewFields;

TableViewFields.propTypes = {
  filterName: PropTypes.string
};
