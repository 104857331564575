import {
  Address,
  ContractCriteriaByValueRequestModeEnum,
  EquipmentTypeValues
} from '@shipwell/backend-core-singlerequestparam-sdk';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

const keysToMatch = ['address_1', 'city', 'state_province', 'postal_code', 'country'] as Array<keyof Address>;

export const getMatchingParams = ({
  modes,
  equipmentTypes,
  shipmentsStopsAddresses
}: {
  modes: ContractCriteriaByValueRequestModeEnum[];
  equipmentTypes: EquipmentTypeValues[];
  shipmentsStopsAddresses: Address[][];
}) => {
  const matchedMode = modes.every((mode) => mode === modes[0]) ? modes[0] : undefined;
  const matchedEquipment = equipmentTypes.every((equipmentType) => equipmentType === equipmentTypes[0])
    ? equipmentTypes[0]
    : undefined;

  // if all arrays of addresses have the same length we can use the whole thing to get applicable contracts
  const isEqualAddressArrayLengths = shipmentsStopsAddresses.every(
    (shipmentStopsAddresses) => shipmentStopsAddresses.length === shipmentsStopsAddresses[0].length
  );

  // else the best we can do is to match on the first/last stops, ie origin/destination
  const origins = shipmentsStopsAddresses.map((stopAddress) => stopAddress[0]);
  const destinations = shipmentsStopsAddresses.map((stopAddress) => stopAddress.at(-1) as Address);

  const matchedAddresses = isEqualAddressArrayLengths
    ? getMatchedAddresses(shipmentsStopsAddresses)
    : [getMatchingAddressValues(origins), getMatchingAddressValues(destinations)];

  return {matchedMode, matchedEquipment, matchedAddresses};
};

const getMatchedAddresses = (shipmentsStopsAddresses: Address[][]) =>
  // "rotating" a 2-dimensional array to match each address in order
  shipmentsStopsAddresses[0].map((val, index) =>
    getMatchingAddressValues(shipmentsStopsAddresses.map((row) => row[index]))
  );

const getMatchingAddressValues = (addresses: Address[]) => {
  const matchedAddressKeys = keysToMatch
    .map((keyToMatch) =>
      addresses.every((address) => address[keyToMatch] === addresses[0][keyToMatch]) ? keyToMatch : undefined
    )
    .filter(Boolean) as Array<keyof Address>;

  const bulkMatchedAddress = matchedAddressKeys.length
    ? omitBy(pick(addresses[0], matchedAddressKeys), isNil)
    : undefined;

  // if no postal code match we might still get a match on 3 digit codes
  if (!matchedAddressKeys.includes('postal_code')) {
    const matchedThreeDigitPostalCode = getMatchingThreeDigitPostalCode(addresses);
    if (bulkMatchedAddress && matchedThreeDigitPostalCode) {
      bulkMatchedAddress.postal_code = matchedThreeDigitPostalCode;
    }
  }

  return bulkMatchedAddress;
};

// used to attempt to match 3 digit postal codes, ie 78757 would match 78722
const getMatchingThreeDigitPostalCode = (addresses: Array<Address | undefined>) =>
  addresses.every((address) => address?.postal_code?.slice(0, 3) === addresses[0]?.postal_code?.slice(0, 3))
    ? addresses[0]?.postal_code?.slice(0, 3)
    : undefined;
