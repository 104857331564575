import {ShipmentStatesValues} from '@shipwell/backend-core-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';

interface TrackingBannerProps {
  status?: string;
}

export const TRACKING_BANNER_STATUSES: string[] = [
  ShipmentStatesValues.AppointmentPending,
  ShipmentStatesValues.Cancelled,
  ShipmentStatesValues.CarrierConfirmed,
  ShipmentStatesValues.Draft,
  ShipmentStatesValues.QuoteAccepted,
  ShipmentStatesValues.Quoting,
  ShipmentStatesValues.Tendered
];

export default function TrackingBanner({status}: TrackingBannerProps) {
  if (!status || !TRACKING_BANNER_STATUSES.includes(status)) {
    return null;
  }

  return (
    <div className="my-4 rounded bg-sw-active-light p-4">
      <div className="grid grid-flow-col grid-cols-[5%,95%] justify-center">
        <div className="flex justify-center">
          <SvgIcon name="ErrorFilled" color="$sw-active" />
        </div>
        <div>
          <div className="font-bold">Preparing for shipping, not yet dispatched</div>
        </div>
      </div>
    </div>
  );
}
