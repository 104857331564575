import {Button} from '@shipwell/shipwell-ui';
import Error404Page2 from 'App/common/Error404Page2';
import ShipwellLoader from 'App/common/shipwellLoader';
import {SmsToLocateState, useSmsToLocateTrackingInfo} from 'App/data-hooks/SmsToLocate/useSmsToLocate';
import {formatDateTime} from 'App/utils/dateTimeGlobals';

export function SmsToLocateContainer({
  location: {
    query: {token}
  },
  params: {shipmentId}
}: {
  location: {query: {token: string}};
  params: {shipmentId: string};
}) {
  const {status, data} = useSmsToLocateTrackingInfo({token, shipmentId});

  const lastLocationTimestampStr = data?.lastLocationTime ? formatDateTime(new Date(data.lastLocationTime)) : '';

  return status === SmsToLocateState.Unauthorized ? (
    <>
      <Error404Page2 />
    </>
  ) : status === SmsToLocateState.Loading ? (
    <>
      <ShipwellLoader />
    </>
  ) : (
    <div className="sms-to-locate-container fixed inset-0 flex flex-col items-stretch">
      <div className="flex-0 flex w-full items-center justify-center p-2">
        <img className="shipwell-logo flex h-[27px] w-[153px]" src="/images/shipwell_full_logo.png" />
      </div>
      <div className="flex-0 flex w-full items-center justify-center p-2">
        <div className="max-w-[343px] place-self-center rounded-lg bg-sw-active-light p-2 text-center text-2xl  font-bold text-sw-focused">
          Request for a one time location update
        </div>
      </div>
      <div className="flex-0 flex w-full items-center justify-center p-2">
        <div className="sms-to-locate-metadata flex-0 flex w-full flex-col text-left text-sm">
          <div className="text-sm font-bold">Last Location:</div>
          <div>{data?.lastLocationAddress}</div>
          <div className="text-xs italic">Tracking Received: {lastLocationTimestampStr}</div>
          <div className="flex-0 flex flex-row justify-between">
            <div className="mt-1 flex">
              <span className="text-sm font-bold">Shipment ID:</span>
              <span>&nbsp;{data?.shipmentReferenceId}</span>
            </div>
            <div className="mt-1 flex">
              <span className="text-sm font-bold">Status:</span>
              <span>&nbsp;{data?.shipmentStatus}</span>
            </div>
          </div>
          <div className="mt-1 flex">
            <span className="text-sm font-bold">Customer:</span>
            <span>&nbsp;{data?.customerName}</span>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-1 items-center justify-center border-1 border-sw-text p-2">
        <div className="sms-to-locate-map">
          <div>Map goes here. </div>
          <div>
            Also here are the props: <pre>{JSON.stringify({shipmentId, token}, null, 2)}</pre>
          </div>
        </div>
      </div>
      <div className="sms-to-locate-buttons flex-0 align-center flex flex-col items-center justify-center">
        <div className="flex w-full p-1">
          <Button width="full" onClick={() => alert('Not yet implemented')}>
            Accept One Time Update
          </Button>
        </div>
        <div className="flex w-full p-1">
          <Button width="full" variant="secondary" onClick={() => alert('Not yet implemented')}>
            Reject One Time Update
          </Button>
        </div>
      </div>
    </div>
  );
}
