import PropTypes from 'prop-types';
import classnames from 'classnames';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import {Tooltip} from '@shipwell/shipwell-ui';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import CardMenu from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineCard/CardMenu';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {concatUsername} from 'App/utils/concatUsername';
import useToggle from 'App/utils/hooks/useToggle';
import {eventTypes} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';

const CardDetails = ({
  timelineEvent = {},
  canShowExpandCollapse,
  toggleIsCollapsed,
  timelineEvents,
  isCollapsed,
  shipmentId,
  onUpdateEvent,
  onDeleteEvent
}) => {
  const userInfo = (timelineEvent) =>
    isEmpty(timelineEvent?.actor)
      ? 'Automatic Update'
      : concatUsername(timelineEvent?.actor?.first_name, timelineEvent?.actor?.last_name);
  const eventType = timelineEvent?.event_type;
  const occurredAt = (timelineEvent) => formatDateTime(timelineEvent?.occurred_at);
  const remainingEventsLength = timelineEvents?.length - 1;
  const remainingEvents = timelineEvents?.slice(1, timelineEvents?.length);
  const collapsedEventsTooltipContent = (
    <div>
      {remainingEvents?.map((remainingEvent, index) => (
        <div key={index} className="mb-1 flex">
          <div className="mr-1">{startCaseToLower(remainingEvent?.event_type)}:</div>
          <div>{occurredAt(remainingEvent)} </div>
        </div>
      ))}
    </div>
  );
  return (
    <>
      <div className="flex flex-col font-bold">
        {timelineEvent.title ? <div>{timelineEvent.title}</div> : null}
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            {eventType === eventTypes.STOP_EVENT
              ? timelineEvent?.custom_data?.shipwell_custom_data?.title
              : startCaseToLower(eventType)}
            {canShowExpandCollapse ? (
              <div className="ml-2 cursor-pointer text-sw-primary" onClick={() => toggleIsCollapsed()}>
                {isCollapsed ? (
                  <Tooltip placement="top" tooltipContent={collapsedEventsTooltipContent}>
                    + {remainingEventsLength} {pluralize('Event', remainingEventsLength)}
                  </Tooltip>
                ) : (
                  `${pluralize('Collapse Event', remainingEventsLength)}`
                )}
              </div>
            ) : null}
          </div>
          <CardMenu
            timelineEvent={timelineEvent}
            shipmentId={shipmentId}
            onUpdateEvent={onUpdateEvent}
            onDeleteEvent={onDeleteEvent}
          />
        </div>
      </div>
      <div className="italic text-sw-disabled-text">{`${occurredAt(timelineEvent)} • ${userInfo(timelineEvent)}`}</div>
      <div className="text-sw-disabled-text">{timelineEvent?.description}</div>
    </>
  );
};

CardDetails.propTypes = {
  timelineEvent: PropTypes.object,
  index: PropTypes.any,
  canShowExpandCollapse: PropTypes.bool,
  timelineEvents: PropTypes.array,
  toggleIsCollapsed: PropTypes.func,
  isCollapsed: PropTypes.bool,
  shipmentId: PropTypes.string,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func
};

const TimelineCard = ({timelineEvents = [], shipmentId, onUpdateEvent, onDeleteEvent}) => {
  const hasMultipleEvents = timelineEvents?.length > 1;
  const [isCollapsed, toggleIsCollapsed] = useToggle(hasMultipleEvents);

  return (
    <div className={classnames('mb-4 flex w-11/12 flex-col rounded-md border-1 border-sw-disabled p-2')}>
      {isCollapsed ? (
        <CardDetails
          timelineEvent={timelineEvents?.[0]}
          canShowExpandCollapse
          toggleIsCollapsed={toggleIsCollapsed}
          timelineEvents={timelineEvents}
          isCollapsed={isCollapsed}
          shipmentId={shipmentId}
          onUpdateEvent={onUpdateEvent}
          onDeleteEvent={onDeleteEvent}
        />
      ) : (
        timelineEvents?.map((timelineEvent, index) => (
          <div key={index}>
            <CardDetails
              timelineEvent={timelineEvent}
              canShowExpandCollapse={hasMultipleEvents && index === 0}
              toggleIsCollapsed={toggleIsCollapsed}
              timelineEvents={timelineEvents}
              isCollapsed={isCollapsed}
              shipmentId={shipmentId}
              onUpdateEvent={onUpdateEvent}
              onDeleteEvent={onDeleteEvent}
            />
            {index !== timelineEvents?.length - 1 ? (
              <hr className="mt-1 border-t border-solid border-sw-divider" />
            ) : null}
          </div>
        ))
      )}
    </div>
  );
};

TimelineCard.propTypes = {
  timelineEvents: PropTypes.array,
  shipmentId: PropTypes.string,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func
};

export default TimelineCard;
