import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import upperFirst from 'lodash/upperFirst';
import {withRouter, Link} from 'react-router';
import {
  DisplayValue,
  Title,
  Rule,
  DeprecatedButton,
  Pill,
  SvgIcon,
  TwoColumnLayout,
  TwoColumnLayoutLeft,
  TwoColumnLayoutRight,
  Tooltip,
  Card
} from '@shipwell/shipwell-ui';
import {startAppTrackingPromise, requestAppDownloadPromise} from 'App/api/shipment';
import WithStatusToasts from 'App/components/withStatusToasts';
import {
  driverAllowedModes,
  powerUnitAllowedModes,
  trailerAllowedModes,
  temperatureRequiredEquipment
} from 'App/containers/Shipment/components/CarrierEquipment/utils';
import {formatMileage, formatDateTime, permViewCarriers, permCreateCarrierRelationship} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import CarrierDetails from 'App/containers/Shipment/components/CarrierEquipment/View/components/carrierDetails';
import {METRIC, convertMilesToKilometers} from 'App/utils/internationalConstants';
import {SHIPMENT_ASSIGN_CARRIER} from 'App/components/permissions/PermissionsFallback/constants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';

export const carrierEquipmentContexts = {
  SHIPMENT_DETAILS: 'SHIPMENT_DETAILS',
  ALERTS_DASHBOARD: 'ALERTS_DASHBOARD'
};

const CarrierEquipmentView = ({
  carrierConfig,
  expanded,
  shipment,
  handleEdit,
  router,
  setSuccess,
  setError,
  unitPreferences = {},
  isQuotingLimitedUser,
  user,
  context
}) => {
  const {system} = unitPreferences;
  const shouldConvertToMetric = system === METRIC;
  const temperatureUnit = getShipmentTemperatureUnit(shipment);

  const requestAppDownload = async () => {
    try {
      await requestAppDownloadPromise(shipment.id);
      setSuccess('Success!', 'App install request sent.');
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const startAppTracking = async () => {
    try {
      await startAppTrackingPromise(shipment.id);
      setSuccess('Success!', 'Tracking request sent.');
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const getMileageString = (carrierConfig, mode) => {
    if (!get(carrierConfig, 'total_miles')) {
      return '--';
    }
    let mileageString = shouldConvertToMetric
      ? `${convertMilesToKilometers(get(carrierConfig, 'total_miles'), 3)} km`
      : `${formatMileage(get(carrierConfig, 'total_miles'), 3)} mi`;
    if (mode?.code === 'LTL' || mode?.code === 'VLTL') {
      mileageString += ' (estimated)';
    }

    return mileageString;
  };

  const renderEquipment = ({
    mode = {},
    equipment_type: equipmentType = {},
    service_level: serviceLevel = {},
    ...carrierConfig
  }) => {
    const leftColumn = [{label: 'mode', value: get(mode, 'description', '--')}];

    const rightColumn = [{label: 'equipment', value: get(equipmentType, 'name', '--')}];

    if (temperatureRequiredEquipment.includes(equipmentType?.machine_readable)) {
      leftColumn.push({
        label: `Minimum Temperature (${temperatureUnit})`,
        value: get(carrierConfig, 'temperature_lower_limit', '--')
      });
      rightColumn.push({
        label: `Maximum Temperature (${temperatureUnit})`,
        value: get(carrierConfig, 'temperature_upper_limit', '--')
      });
    }
    if (trailerAllowedModes.includes(mode?.code)) {
      leftColumn.push({label: 'Trailer', value: get(carrierConfig, 'trailer_name') || '--'});
    }
    if (powerUnitAllowedModes.includes(mode?.code)) {
      rightColumn.push({label: 'Power Unit', value: get(carrierConfig, 'power_unit_name') || '--'});
    }
    if (context === carrierEquipmentContexts.SHIPMENT_DETAILS) {
      leftColumn.push({label: 'service level', value: get(serviceLevel, 'description', '--')});
      rightColumn.push({label: 'total distance', value: getMileageString(carrierConfig, mode)});
    }

    return (
      <>
        <TwoColumnLayout leftColumnMaxWidth={'50%'}>
          <TwoColumnLayoutLeft>
            {leftColumn.map((field) => {
              return (
                <DisplayValue label={field.label} key={field.label}>
                  {field.value}
                </DisplayValue>
              );
            })}
          </TwoColumnLayoutLeft>
          <TwoColumnLayoutRight>
            {rightColumn.map((field) => {
              return (
                <DisplayValue label={field.label} key={field.label}>
                  {field.value}
                </DisplayValue>
              );
            })}
          </TwoColumnLayoutRight>
        </TwoColumnLayout>
      </>
    );
  };

  const renderDriverAppStatus = (driver) => {
    const platformIcons = {iOS: '/images/ios.svg', ANDROID: '/images/android.svg'};

    if (!driver) {
      return;
    }
    if (!driver.platform) {
      if (!driver.app_last_opened_at) {
        //driver hasn't installed the app
        return (
          <Tooltip
            tooltipClassname="shipment__carrierEquipment-driverTooltip"
            tooltipContent="Driver has not installed the Shipwell app."
          >
            <SvgIcon name="ErrorOutlined" color="$sw-error" />
          </Tooltip>
        );
      }
      return (
        <Tooltip
          tooltipClassname="shipment__carrierEquipment-driverTooltip"
          tooltipContent={
            <>
              <div>Driver has disabled notifications.</div>
              <div>Driver last opened app: {formatDateTime(driver.app_last_opened_at)}</div>
            </>
          }
        >
          <SvgIcon name="ErrorOutlined" color="$sw-error" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        tooltipClassname="shipment__carrierEquipment-driverTooltip"
        tooltipContent={
          <>
            <div>Driver last opened app: {formatDateTime(driver.app_last_opened_at)}</div>
          </>
        }
      >
        <img className="ml-1" height={16} width={16} alt={driver.platform} src={platformIcons[driver.platform]} />
      </Tooltip>
    );
  };

  const CarrierELDInformation = ({carrier}) => {
    if (!carrier) {
      return '--';
    }
    if (carrier?.eld_providers?.length < 1) {
      return 'No ELD Providers';
    }
    return carrier?.eld_providers.map((eld) => {
      return (
        <div className="my-1 flex items-center gap-2" key={eld.id}>
          {upperFirst(eld.name)}
          {eld?.connection_status ? (
            <Pill size="sm" variant={eld.connection_status === 'Active' ? 'primary' : 'inactive'}>
              {upperCase(eld.connection_status)}
            </Pill>
          ) : null}
        </div>
      );
    });
  };

  const renderDriverPhoneDetails = (driver) => {
    return driver?.phone_number ? (
      <span className="flex items-center">
        {getPhoneHyperlink(driver.phone_number)}
        {renderDriverAppStatus(driver)}
      </span>
    ) : (
      '--'
    );
  };

  const {mode} = carrierConfig;
  const carrier = get(carrierConfig, 'vendor');
  const driver = get(carrierConfig, 'driver');
  // platNewDriverWorkflow flag users have full_name and sometimes first/last, all others have only first/last
  const driverName = driver?.full_name
    ? driver.full_name
    : driver?.first_name
    ? `${driver.first_name}${driver.last_name ? ` ${driver.last_name}` : ''}`
    : null;

  const canAddCarrier = get(user, 'permissions', []).includes(permCreateCarrierRelationship);
  const canEditCarrier = canAddCarrier && get(user, 'permissions', []).includes(permViewCarriers);

  if (!expanded) {
    return null;
  }

  return (
    <div className="shipment__carrierEquipment-container">
      {!isQuotingLimitedUser && canEditCarrier && context === carrierEquipmentContexts.SHIPMENT_DETAILS && (
        <>
          <Title variant="sectionTitle">Carrier</Title>
          {carrier ? (
            <CarrierDetails carrierConfig={carrierConfig} shipment={shipment} />
          ) : (
            <div className="shipment__carrierEquipment-noCarrier">
              <TenderingUserPermissionFallback permissions={[SHIPMENT_ASSIGN_CARRIER]}>
                <DeprecatedButton onClick={handleEdit(true)} variant="primary" size="small">
                  Assign a Carrier
                </DeprecatedButton>
              </TenderingUserPermissionFallback>
              {canAddCarrier && (
                <DeprecatedButton
                  onClick={() => router.push(`/carriers/search?shipmentRedirect=${shipment.id}`)}
                  variant="secondary"
                  size="small"
                >
                  Create New Carrier
                </DeprecatedButton>
              )}
            </div>
          )}
        </>
      )}

      {mode && driverAllowedModes.includes(mode.code) && (
        <>
          <Rule />
          <div className="flex">
            <div className="flex-1">
              <Title variant="sectionTitle">
                <span className="shipment__carrierEquipment-driverHeader">Driver</span>
              </Title>
              <DisplayValue label="">
                {driver ? (
                  <>
                    <div>{driverName}</div>
                    {renderDriverPhoneDetails(driver)}
                    {driver.pin ? <div>{`PIN: ${driver.pin}`}</div> : null}
                  </>
                ) : (
                  '--'
                )}
              </DisplayValue>
            </div>
            <div className="flex-1">
              <Title variant="sectionTitle">
                <span className="shipment__carrierEquipment-driverHeader">ELD Provider</span>
              </Title>
              <DisplayValue label="">
                <CarrierELDInformation carrier={carrier?.carrier} />
              </DisplayValue>
            </div>
          </div>
          {carrier && !driver && (
            <Card
              guided
              className="my-4"
              title={
                <div className="py-2">
                  A valid mobile number for the driver must be added to access App Tracking and send Check Calls.{' '}
                  {canEditCarrier ? (
                    <Link className="cursor-pointer" onClick={handleEdit(true)}>
                      Add driver and equipment information.
                    </Link>
                  ) : null}
                </div>
              }
            ></Card>
          )}
          <DisplayValue
            LabelProps={{className: 'shipment__carrierEquipment-driverButtonsLabel'}}
            label={
              <>
                Shipwell App Tracking
                <Tooltip
                  tooltipClassname="shipment__carrierEquipment-standardTooltip"
                  tooltipContent="Shipwell app tracking sends the driver a text message to install the Shipwell app on their phone or activate their tracking if they have the app installed."
                >
                  <SvgIcon name="InfoOutlined" />
                </Tooltip>
              </>
            }
          >
            <div className="shipment__carrierEquipment-driverAppButtons">
              <DeprecatedButton
                disabled={!driver}
                onClick={() => requestAppDownload()}
                variant="secondary"
                size="small"
              >
                Request App Install
              </DeprecatedButton>
              <DeprecatedButton disabled={!driver} onClick={() => startAppTracking()} variant="secondary" size="small">
                Start App Tracking
              </DeprecatedButton>
            </div>
          </DisplayValue>
        </>
      )}

      <Rule />
      <Title variant="sectionTitle">Equipment</Title>
      {renderEquipment(carrierConfig)}
      {context === carrierEquipmentContexts.SHIPMENT_DETAILS && (
        <>
          <Rule />
          <Title variant="sectionTitle">Notes For Carrier</Title>
          <DisplayValue label="Notes">{get(carrierConfig, 'notes_for_carrier') || '--'}</DisplayValue>
        </>
      )}
    </div>
  );
};

CarrierEquipmentView.propTypes = {
  carrierConfig: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  shipment: PropTypes.object,
  handleEdit: PropTypes.func,
  router: PropTypes.object,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  initialCarrierDetails: PropTypes.object,
  unitPreferences: PropTypes.object,
  isQuotingLimitedUser: PropTypes.bool,
  user: PropTypes.object,
  context: PropTypes.string
};

CarrierEquipmentView.defaultProps = {
  carrierConfig: {},
  expanded: false,
  shipment: {},
  handleEdit: () => {},
  router: {},
  setSuccess: () => {},
  setError: () => {},
  initialCarrierDetails: {},
  unitPreferences: {},
  context: carrierEquipmentContexts.SHIPMENT_DETAILS
};

const mapStateToProps = (state) => ({
  user: state.userProfile.user,
  isQuotingLimitedUser: state.userProfile.user.is_quoting_limited_user,
  unitPreferences: state.userCompany.unitPreferences
});

export default compose(withRouter, WithStatusToasts, connect(mapStateToProps))(CarrierEquipmentView);
