import {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {Field, useFormikContext} from 'formik';
import {Card, FormikSelect, Tooltip, SvgIcon, DisplayValue} from '@shipwell/shipwell-ui';
import {FINANCIAL_SIDE_OPTIONS, RATE_TABLE_TYPE_OPTIONS, RATE_TABLE_TOOLTIP, RateTableRateTypeEnum} from './constants';
import RateTableDetailsAccessorial from './components/rateTableDetailsAccessorial';
import RateTableDetailsFuelSurcharge from './components/rateTableDetailsFuelSurcharge';
import {fetchAccessorialChargeTablesPromise} from 'App/api/shipment';
import {getFuelSurchargeTables} from 'App/api/rateTables';
import './styles.scss';

export function getStopState(stop) {
  return get(stop, 'location.address.state_province');
}

export function getStopCity(stop) {
  return get(stop, 'location.address.city');
}

function getShipmentLocationQueryInfo(shipment) {
  const stops = get(shipment, 'stops', []);
  const locations = stops.filter((stop) => stop.is_pickup || stop.is_dropoff);
  const locationsStateProvince = stops.filter((stop) => stop.is_pickup || stop.is_dropoff);
  return {
    locationsContains: locations.map(getStopCity),
    locationsStateProvinceContains: locationsStateProvince.map(getStopState)
  };
}

const CreateRateTable = ({customer, vendor, shipment}) => {
  const {values, setFieldValue} = useFormikContext();
  const [preloadACC, setPreloadACC] = useState(null);
  const [preloadFSC, setPreloadFSC] = useState(null);
  const rateType = values.rate_type;

  const fetchRateTables = useCallback(
    async (opts = {}, setError) => {
      if (opts === null) {
        return;
      }
      try {
        opts = {
          ...opts,
          pageSize: 20
        };
        if (rateType === 'accessorial') {
          const response = await fetchAccessorialChargeTablesPromise(opts);
          if (response && response.body) {
            return response.body.results;
          }
        } else if (rateType === 'fuelsurcharge') {
          const response = await getFuelSurchargeTables(opts);
          if (response && response.body) {
            return response.body.results;
          }
        }
      } catch (error) {
        console.error(error);
        setError?.('Error!', error.error_description);
      }
    },
    [rateType]
  );

  const queryRateTables = (q) => fetchRateTables({q});

  useEffect(() => {
    async function fetchOptions(setError) {
      const query = {
        modes: [get(shipment, 'mode.code')],
        equipmentTypes: [get(shipment, 'equipment_type.machine_readable')],
        ...getShipmentLocationQueryInfo()
      };
      try {
        const options = await fetchRateTables(query);
        if (rateType === 'accessorial') {
          setPreloadACC(options);
        } else {
          setPreloadFSC(options);
        }
        return options;
      } catch (error) {
        console.error(error);
        setError?.('Error!', error.error_description);
      }
    }
    fetchOptions();
  }, [shipment, rateType, fetchRateTables]);

  // clears the appropriate value when the rate_type is changed
  useEffect(() => {
    if (rateType) {
      if (rateType === RateTableRateTypeEnum.Accessorial) {
        setFieldValue(RateTableRateTypeEnum.FuelSurcharge, null);
      } else {
        setFieldValue(RateTableRateTypeEnum.Accessorial, null);
      }
    }
  }, [rateType, setFieldValue]);

  return (
    <Card title="Rate Table" expanded="true" className="rateTable__financial-card">
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <Field
            simpleValue
            name="applies_to"
            label="Financials"
            clearable={false}
            options={FINANCIAL_SIDE_OPTIONS}
            component={FormikSelect}
          />
        </div>
        <div className="grid-item-12-12">
          <Tooltip
            placement="left"
            tooltipClassname="rateTable__financial__tooltip-content"
            wrapperClassname="rateTable__financial__tooltip-wrapper"
            tooltipContent={RATE_TABLE_TOOLTIP}
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
        <div className="grid-item-1-12">
          <Field
            simpleValue
            name="rate_type"
            label="Rate Table Type"
            clearable={false}
            options={RATE_TABLE_TYPE_OPTIONS}
            component={FormikSelect}
          />
          {values.rate_type === 'accessorial' && (
            <Field
              async
              name="accessorial"
              label="Accessorial Table"
              clearable={false}
              loadOptions={queryRateTables}
              getOptionLabel={(opts) => opts?.name}
              getOptionValue={(opts) => opts?.id}
              defaultOptions={preloadACC}
              component={FormikSelect}
            />
          )}
          {values.rate_type === 'fuelsurcharge' && (
            <Field
              async
              name="fuelsurcharge"
              label="Fuel Surcharge Table"
              clearable={false}
              loadOptions={queryRateTables}
              getOptionLabel={(opts) => opts?.name}
              getOptionValue={(opts) => opts?.id}
              defaultOptions={preloadFSC}
              component={FormikSelect}
            />
          )}
          {values.accessorial && (
            <div className="rateTable__financial__details">
              <div className="rateTable__financial__details-accounts">
                <DisplayValue label="Carrier">{vendor ? vendor : '--'}</DisplayValue>
                <DisplayValue label="Customer">{customer ? customer : '--'}</DisplayValue>
              </div>
              <RateTableDetailsAccessorial values={values} />
            </div>
          )}
          {values.fuelsurcharge && (
            <div className="rateTable__financial__details">
              <div className="rateTable__financial__details-accounts">
                <DisplayValue label="Carrier">{vendor ?? '--'}</DisplayValue>
                <DisplayValue label="Customer">{customer ?? '--'}</DisplayValue>
              </div>
              <RateTableDetailsFuelSurcharge values={values} />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

CreateRateTable.propTypes = {
  customer: PropTypes.string,
  vendor: PropTypes.string,
  shipment: PropTypes.object
};

export default connect((state) => ({
  modes: state.shipments.shipmentModes,
  equipmentTypes: state.shipments.equipmentTypes,
  shipment: state.shipmentdetails.one
}))(CreateRateTable);
