import {useState} from 'react';

export function useSmoothFlag(minimumDurationTrueMs: number, minimumDurationFalseMs: number, flag: boolean) {
  const now = Date.now();
  const [state, setState] = useState({
    seqNo: 0,
    tIn: 0,
    vIn: flag,
    tOut: now,
    vOut: flag
  });
  let {seqNo, tIn, vIn, tOut, vOut} = state;

  let changed = false;

  if (flag !== vIn) {
    tIn = now;
    vIn = flag;
    changed = true;
  }
  if (vIn !== vOut) {
    const minDur = vOut ? minimumDurationTrueMs : minimumDurationFalseMs;
    const dt = now - tIn;
    if (dt >= minDur) {
      vOut = vIn;
      tOut = now;
      changed = true;
    } else {
      setTimeout(() => {
        setState((s) => ({...s, seqNo: s.seqNo + 1}));
      }, minDur - dt);
    }
  }
  if (changed) {
    seqNo++;
    setState({seqNo, tIn, vIn, tOut, vOut});
  }

  return vOut;
}
