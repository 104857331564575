import {Component} from 'react';
import {Async} from 'react-select';
import FormGroup from '../../formGroup';
import {getProductsPromise} from 'App/actions/products';

/**
 * Product Search Field -
 * Search field for user products
 */
class ProductSearchField extends Component {
  static defaultProps = {
    value: '',
    name: 'products',
    input: {},
    icon: 'icon-Search',
    placeholder: 'Load from products',
    autoload: true
  };

  /**
   * Search products by query value
   * @param  {String} value Search input value
   */
  async searchProductsByQuery(value) {
    try {
      const result = {options: []};
      const response = await getProductsPromise({q: value});

      if (response && response.results) {
        result.options = response.results.map((result) => ({
          label: result.product_ref,
          value: result
        }));
      }
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {input, req, icon, arrowRenderer, placeholder, autoload, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const onChange = input.onChange || rest.onChange;

    return (
      <FormGroup {...this.props}>
        {() => (
          <Async
            {...rest}
            value={value}
            name={name}
            autoload={autoload}
            placeholder={placeholder}
            arrowRenderer={arrowRenderer}
            loadOptions={this.searchProductsByQuery.bind(this)}
            filterOptions={(option) => option}
            onChange={(value) => onChange && onChange(value)}
          />
        )}
      </FormGroup>
    );
  }
}

export default ProductSearchField;
