import {useMutation, useQuery} from '@tanstack/react-query';
import {Tag} from '@shipwell/corrogo-sdk';
import {TagFormValues} from '../formUtils/tagFormValues';
import {getUpdateDataFromFormData, getFormDataFromResponse, getCreateDataFromFormData} from './helpers';
import {createTag, getTag, updateTag} from 'App/api/corrogo/tags';
import {parseV3ApiError} from 'App/api/typedUtils';

export interface UseTagServiceProps {
  tagId?: string;
  onSave: (data: Tag) => void;
  onError: (message: string) => void;
}

export function useTagService(props: UseTagServiceProps) {
  const listQuery = useQuery({
    queryKey: ['useTagService', props.tagId],
    queryFn: async () => {
      if (!props.tagId) return null;
      const result = await getTag(props.tagId);
      return getFormDataFromResponse(result.data);
    }
  });

  const saveMutation = useMutation({
    mutationFn: async (vars: TagFormValues) => {
      if (props.tagId) {
        const data = getUpdateDataFromFormData(vars);
        const response = await updateTag(props.tagId, data);
        return response.data;
      }

      const data = getCreateDataFromFormData(vars);
      const response = await createTag(data);
      return response.data;
    },
    onSuccess: props.onSave,
    onError: (error: unknown) => {
      const {detail} = parseV3ApiError(error);
      props.onError(detail);
    }
  });

  const loading = listQuery.isFetching || saveMutation.isLoading;

  return {
    loading,
    data: listQuery.data,
    refetch: listQuery.refetch,
    save: saveMutation.mutate
  };
}
