/* eslint-disable import/no-namespace */
/* eslint-disable camelcase */
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import {Card, CollapsibleCardContent, Dropdown, SvgIcon, Modal} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import FinancialsForm from '../forms/FinancialsForm';
import MarketplaceFinancials from '../MarketplaceFinancials';
import MarketplaceStats from '../MarketplaceStats';
import {MarketplaceTenders} from '../MarketplaceTenders';
import {MarketplaceBids} from '../MarketplaceBids';
import {getTenders} from 'App/api/tenders';
import * as shipmentActions from 'App/actions/shipments';
import * as formActions from 'App/actions/forms';
import * as userActions from 'App/actions/users';
import * as marketplaceActions from 'App/actions/marketplace';
import {quoting} from 'App/api';
import CarrierBidsListContainer from 'App/containers/carrierBids/list';
import {transformShipmentToForm} from 'App/utils/globals';
import {showPricingIntel} from 'App/containers/pricingIntel/utils';
import PricingIntelEmbeddedPricing from 'App/components/pricingIntelEmbeddedPricing';
import {
  PRICING_INTEL_SOURCE_SHIPMENT_DETAILS,
  DEFAULT_PICKUP_RADIUS,
  DEFAULT_DROPOFF_RADIUS,
  PRICING_INTEL_RANGE_12_MONTHS,
  PREDICTIVE_MARKET,
  PREDICTIVE_INTERNAL,
  HISTORICAL_PRICING
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {PricingIntelContextProvider} from 'App/data-hooks/pricing/usePricingIntel';
import {fetchCarrierConfig} from 'App/api/shipment';
import WithStatusToasts from 'App/components/withStatusToasts';

let shipmentInterval;

@connect(
  (state) => ({
    selectedShipment: state.shipmentdetails.one,
    hazmatCodes: state.shipments.hazmatCodes,
    company: state.auth.company,
    shipmentSpotNegotiations: state.marketplace.shipmentSpotNegotiations,
    selectedSpotNegotiation: state.marketplace.selectedSpotNegotiation,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    isBidManagerEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.bid_manager_enabled
  }),
  {
    ...shipmentActions,
    ...formActions,
    ...userActions,
    ...marketplaceActions
  }
)
class MarketplaceDetails extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);

    this.handleNew = this.handleNew.bind(this);
    this.getSpotNegotiationDetails = this.getSpotNegotiationDetails.bind(this);
    this.fetchShipmentDetails = this.fetchShipmentDetails.bind(this);
    this.reopenAuction = this.reopenAuction.bind(this);
    this.getCarrierBids = this.getCarrierBids.bind(this);
    this.state = {
      selectedCustomer: 0,
      selectedSpotNegotiation: 'all',
      currentMessage: '',
      loading: true,
      intervalId: null,
      quoteIntervalId: null,
      tenders: [],
      carrierBids: [],
      carrierBidsMeta: {
        page: 1,
        pageSize: 20,
        shipmentId: this.props.params.shipment_id
      },
      numCarrierBids: 0,
      carrierConfig: {},
      showFinancialsModal: false
    };
  }

  componentDidMount() {
    if (this.props.company && this.props.company.id) {
      this.initialize();
    }
  }

  initialize() {
    this.props.fetchHazmatCodes();
    if (this.props.selectedShipment) {
      this.getSpotNegotiationDetails(this.props.selectedShipment);
      this.pollDetails();
      this.getShipmentTenders();
      this.getCarrierBids({pageSize: 20, shipmentId: this.props.params.shipment_id});
      this.getCarrierConfig(this.props.selectedShipment);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company && this.props.company) {
      this.initialize();
    }
    if (this.props.selectedShipment !== prevProps.selectedShipment) {
      this.getSpotNegotiationDetails(this.props.selectedShipment);
      this.getShipmentTenders();
      this.getCarrierBids({pageSize: 20, shipmentId: this.props.params.shipment_id});
      this.getCarrierConfig(this.props.selectedShipment);
    }
  }

  componentWillUnmount() {
    clearInterval(shipmentInterval);
  }

  async getShipmentTenders() {
    const {selectedShipment = {}} = this.props;

    try {
      const response = await getTenders({pageSize: 1000, shipmentId: selectedShipment.id});

      if (response && response.body) {
        const tenders = response.body.results.filter(({shipment_id}) => shipment_id === selectedShipment.id);

        if (tenders && tenders.length) {
          this.setState({
            tenders: [tenders[0]]
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCarrierConfig = async (shipment) => {
    try {
      const response = await fetchCarrierConfig(shipment.id);
      this.setState({carrierConfig: response.body});
    } catch (error) {
      console.error(error);
    }
  };

  async fetchShipmentDetails() {
    const response = await this.props.getShipmentDetails(this.props.selectedShipment.id);
    if (response) {
      //if the customer in the response is not the currently logged in person, we want to be masquerading
      this.getSpotNegotiationDetails(response.details);
    }
  }

  async getSpotNegotiationDetails() {
    //use the shipmentId to get all the spot negotiations associated with this user for this shipment
    const response = await this.props.getAllSpotNegotiationsByShipment(this.props.params.shipment_id);
    if (response) {
      if (response.status === 200) {
        this.setState({loading: false});
      }
    }
  }

  async getCarrierBids(opts) {
    try {
      const response = await quoting.getCarrierBids({
        ...opts,
        shipmentId: this.props.params.shipment_id,
        ordering: 'bid_amount'
      });
      if (response.body) {
        this.setState({
          numCarrierBids: response.body.total_count,
          carrierBids: response.body.results,
          carrierBidsMeta: {
            page: opts.page || 1,
            pages: response.body.total_pages,
            pageSize: response.body.page_size,
            shipmentId: this.props.params.shipment_id
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  pollDetails(ms = 15000) {
    shipmentInterval = window.setInterval(() => {
      this.getSpotNegotiationDetails();
    }, ms);
  }

  handleNew() {
    this.setState({showCarrierRequestModal: true});
  }

  reopenAuction() {
    const body = {};
    body.spot_negotiations = this.props.shipmentSpotNegotiations.results.map((e) => e.id);
    body.close = false;

    const response = this.props.openCloseSpotNegotiation(body);
    if (response) {
      if (response.status === 200) {
        //refetch details
        this.fetchShipmentDetails();
      }
    }
  }

  handleSelectSpotNegotiation(spotNegotiation) {
    this.props.selectSpotNegotiation(spotNegotiation);
    this.context.router.push(`/marketplace/${this.props.selectedShipment.id}/bids/${spotNegotiation.id}`);
  }

  handleSelectQuote(quote) {
    this.props.selectQuote(quote);
    let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));
    const mode = quote.mode || {id: 1, code: 'FTL', description: 'Full Truckload'}; //default FTL
    const equipment_types = quote.equipment_type || {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}; //default dry van
    shipment = transformShipmentToForm(shipment, this.props.hazmatCodes, [mode], [equipment_types], true);
    this.props.selectFormShipment(shipment);
    //send to confirm page to complete the rest of the shipment details
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/confirm?mode=bids');
  }

  render() {
    const {selectedShipment, shipmentSpotNegotiations, featureFlags = {}, company, setError} = this.props;
    const {carrierBids, carrierBidsMeta, numCarrierBids, showFinancialsModal} = this.state;
    const isFTL = selectedShipment?.mode?.id === 1;
    const carrierAssigned = get(this.state.carrierConfig, 'vendor', null);
    const loadBoardEnabled = company?.feature_flags?.load_board_enabled;

    const handleClose = () => {
      this.setState({showFinancialsModal: false});
    };

    const ActionsDropdown = () => (
      <Dropdown
        disabled={!loadBoardEnabled}
        variant="icon"
        drop="down"
        indicator={false}
        icon={<SvgIcon name="Overflow" />}
        alignEnd
      >
        {() => (
          <>
            <li title="Edit" onClick={() => this.setState({showFinancialsModal: true})} className="min-w-[80px]">
              Edit
            </li>
          </>
        )}
      </Dropdown>
    );

    return (
      <>
        <Modal show={showFinancialsModal} title="Financials" footerComponent={null} onClose={handleClose}>
          <FinancialsForm
            selectedShipment={selectedShipment}
            handleClose={handleClose}
            fetchShipmentDetails={this.fetchShipmentDetails}
            setError={setError}
          />
        </Modal>
        <Grid className="marketplace__details" container spacing={2}>
          <Grid item md={12} lg={6}>
            <Card title="Stats">
              <MarketplaceStats
                selectedShipment={selectedShipment}
                carrierBids={carrierBids}
                numCarrierBids={numCarrierBids}
              />
            </Card>
          </Grid>
          <Grid item md={12} lg={6}>
            <Card title="Financials" actions={<ActionsDropdown />}>
              <MarketplaceFinancials
                selectedShipment={selectedShipment}
                shipmentSpotNegotiations={shipmentSpotNegotiations}
                carrierBids={carrierBids}
              />
            </Card>
          </Grid>
          {showPricingIntel({
            hasFTL: isFTL,
            historicalPricingEnabled: featureFlags?.historical_pricing_enabled,
            equipmentType: [selectedShipment.equipment_type],
            stops: selectedShipment.stops
          }) &&
          //only show embedded pricing if there's no carrier assigned to shipment
          !carrierAssigned ? (
            <PricingIntelContextProvider
              pageSource={PRICING_INTEL_SOURCE_SHIPMENT_DETAILS}
              equipmentType={[selectedShipment.equipment_type]}
              isShipwell={company?.is_shipwell}
              pickupRadius={DEFAULT_PICKUP_RADIUS}
              dropOffRadius={DEFAULT_DROPOFF_RADIUS}
              stops={selectedShipment.stops}
              pricingIntelDataOptions={[PREDICTIVE_MARKET, PREDICTIVE_INTERNAL, HISTORICAL_PRICING]}
              selectedFormRange={PRICING_INTEL_RANGE_12_MONTHS}
            >
              <Grid item xs={12}>
                <PricingIntelEmbeddedPricing
                  pageSource={PRICING_INTEL_SOURCE_SHIPMENT_DETAILS}
                  cardClassName="shipment__embedded-pricing"
                />
              </Grid>
            </PricingIntelContextProvider>
          ) : null}
          <Grid item xs={12}>
            <Card title="Tenders" isCollapsible bodyClassName="p-0">
              <CollapsibleCardContent>
                <MarketplaceTenders selectedShipment={selectedShipment} />
              </CollapsibleCardContent>
            </Card>
          </Grid>
          {this.props.isBidManagerEnabled ? (
            <Grid item xs={12}>
              <CarrierBidsListContainer
                selectedShipment={selectedShipment}
                bids={carrierBids}
                getCarrierBids={this.getCarrierBids}
                carrierBidsMeta={carrierBidsMeta}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Card title="Requested Bids" bodyClassName="carrierBids__list-container" isCollapsible>
              <CollapsibleCardContent>
                <MarketplaceBids
                  company={company}
                  featureFlags={featureFlags}
                  selectedShipment={selectedShipment}
                  shipmentSpotNegotiations={shipmentSpotNegotiations}
                  handleSelect={this.handleSelectQuote.bind(this)}
                  handleSelectSpotNegotiation={this.handleSelectSpotNegotiation.bind(this)}
                />
              </CollapsibleCardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default compose(WithStatusToasts)(MarketplaceDetails);

MarketplaceDetails.propTypes = {
  params: PropTypes.object,
  company: PropTypes.object,
  fetchHazmatCodes: PropTypes.func,
  selectedShipment: PropTypes.object,
  getShipmentDetails: PropTypes.func,
  getAllSpotNegotiationsByShipment: PropTypes.func,
  isBidManagerEnabled: PropTypes.bool,
  shipmentSpotNegotiations: PropTypes.array,
  featureFlags: PropTypes.object,
  openCloseSpotNegotiation: PropTypes.func,
  selectSpotNegotiation: PropTypes.func,
  selectQuote: PropTypes.func,
  selectFormShipment: PropTypes.func,
  hazmatCodes: PropTypes.array,
  setError: PropTypes.func
};
