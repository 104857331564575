import {User} from '@shipwell/backend-core-sdk';

const DEFAULT_AVATAR = 'cat';
const avatarUrl = (imageName: string) =>
  `https://s3-us-west-2.amazonaws.com/shipwell-static/user-avatars/default/${imageName}.png`;

export function getImageUrl(letter: string) {
  // if the letter is not between A-Z for some reason, use the default avatar
  return avatarUrl(/^[A-Z]+$/.test(letter) ? letter : DEFAULT_AVATAR);
}

export function getUserAvatarUrl(user?: Partial<User>) {
  if (!user) {
    // use default image if the value sent in is null
    return avatarUrl(DEFAULT_AVATAR);
  }
  if (user.avatar) {
    return user.avatar;
  }

  // first name is not always required on the backend, but last name is
  const letter = user.first_name
    ? user.first_name.trim().charAt(0).toUpperCase()
    : user.last_name
    ? user.last_name.trim().charAt(0).toUpperCase()
    : '';

  return getImageUrl(letter);
}

export function getMessageUserAvatarURL(fullName: string, avatar: string) {
  if (avatar) {
    return avatar;
  }

  return getImageUrl(fullName.trim().charAt(0).toUpperCase());
}
