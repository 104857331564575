import {Dispatch, SetStateAction} from 'react';
import {Shipment, PackageType, CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {LineItemCard} from './LineItemCard';

type ModalState = {
  mode: string;
  index: number;
};

type CardItems = {
  shipment: Shipment;
  packageTypes: PackageType[];
  canEdit: boolean;
  setShowEditModal: (x: boolean) => void;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  setLineItemIdToDelete: Dispatch<SetStateAction<string>>;
  setShowLineItemDeleteModal: (x: boolean) => void;
  customFields?: CustomField[];
};

const CardItems = ({
  shipment,
  packageTypes,
  canEdit,
  setShowEditModal,
  setModalState,
  setLineItemIdToDelete,
  setShowLineItemDeleteModal,
  customFields
}: CardItems) => {
  const lineitems = shipment?.line_items || [];
  // seperate line items with pieces (aka handling units) from line items without
  const handlingUnits = lineitems.filter((lineItem) => lineItem.pieces?.length);
  const lineItemsWithoutHandlingUnits = lineitems.filter((lineItem) => !lineItem.pieces?.length);
  const totalPieces = handlingUnits.reduce((total, handlingUnit) => (total += handlingUnit.pieces?.length || 0), 0);

  if (lineitems.length) {
    return (
      <div className="flex flex-col gap-y-4">
        {handlingUnits.map((handlingUnit) => (
          <div
            key={handlingUnit.id}
            className="flex flex-col gap-2 rounded border border-sw-border bg-sw-background p-2"
          >
            <span className="text-sm text-sw-disabled-text">
              <span className="text-md font-bold text-sw-text">
                {handlingUnit.package_type} {handlingUnit.serial_number}
              </span>{' '}
              • {handlingUnit.pieces?.length} PIECES • {handlingUnit.width}X{handlingUnit.height}X{handlingUnit.length}{' '}
              • {handlingUnit.package_weight?.toLocaleString()}
              {handlingUnit.weight_unit}
            </span>
            {handlingUnit.pieces?.map((piece, index) => (
              <LineItemCard
                key={piece.id}
                index={index}
                lineItem={piece}
                shipment={shipment}
                packageTypes={packageTypes}
                canEdit={false}
                setShowEditModal={setShowEditModal}
                setModalState={setModalState}
                setLineItemIdToDelete={setLineItemIdToDelete}
                setShowLineItemDeleteModal={setShowLineItemDeleteModal}
                customFields={customFields}
              />
            ))}
          </div>
        ))}
        {lineItemsWithoutHandlingUnits.map((item, i) => {
          return (
            <LineItemCard
              key={item.id}
              index={i + totalPieces}
              lineItem={item}
              shipment={shipment}
              packageTypes={packageTypes}
              canEdit={canEdit}
              setShowEditModal={setShowEditModal}
              setModalState={setModalState}
              setLineItemIdToDelete={setLineItemIdToDelete}
              setShowLineItemDeleteModal={setShowLineItemDeleteModal}
              customFields={customFields}
            />
          );
        })}
      </div>
    );
  }

  return null;
};

export default CardItems;
