import {useFlags} from 'launchdarkly-react-client-sdk';
import {CurrencyOfRecordField} from './CurrencyOfRecordField';
import '../_financials.scss';

export const FinancialsCurrencyOfRecordOnly = () => {
  const {stmShipmentCurrencyOfRecord} = useFlags();
  if (!stmShipmentCurrencyOfRecord) return null;
  return (
    <div>
      <h2 className="border-bottom">Financials</h2>
      <div className="quote-flow__financials">
        <CurrencyOfRecordField />
      </div>
    </div>
  );
};
