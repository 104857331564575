import moment from 'moment';
import {Configuration, InboxApi, InboxMessage} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createInboxApi() {
  return new InboxApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type GetInboxParameters = Parameters<typeof InboxApi.prototype.inboxGet>;

export type GetInboxNamedParameters = {
  page?: GetInboxParameters[0];
  pageSize?: GetInboxParameters[1];
  ordering?: GetInboxParameters[2];
  read?: GetInboxParameters[3];
  since?: GetInboxParameters[4];
};

export async function getInbox(opts: GetInboxNamedParameters) {
  const axiosResponse = await createInboxApi().inboxGet(
    opts.page,
    opts.pageSize,
    '-created_at',
    opts.read,
    moment().subtract(365, 'days').toDate() as unknown as string
  );
  return axiosResponse.data;
}

export async function updateInboxItemReadStatus(messageId: string, body: InboxMessage) {
  return createInboxApi().inboxMessageIdPut(messageId, body);
}

export async function deleteInboxMessage(messageId: string) {
  return createInboxApi().inboxMessageIdDelete(messageId);
}

export async function markAllAsRead() {
  return createInboxApi().inboxMarkAllAsReadGet();
}

export async function markAllAsUnread() {
  return createInboxApi().inboxMarkAllAsUnreadGet();
}

export async function inboxDeleteAllMessages() {
  return createInboxApi().inboxDeleteAllDelete();
}
