import {Field} from 'formik';
import {FormikCheckbox, Card} from '@shipwell/shipwell-ui';

const CompanyCarrierRatePreferences = () => {
  return (
    <Card title="Carrier Rate Preferences" className="mb-6" draggableProvided={false}>
      <div className="font-bold">Load Board</div>
      <Field component={FormikCheckbox} name="show_target_rate_on_loadboard" label="Show Target Rate On Load Board" />
    </Card>
  );
};

export default CompanyCarrierRatePreferences;
