import {useState} from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {useMutation} from '@tanstack/react-query';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import {DrayageShipmentModeSpecificDataModeEnum, LocationType, OceanTrackingReferenceType} from '@shipwell/corrogo-sdk';
import {Card, DeprecatedButton} from '@shipwell/shipwell-ui';
import {getIdentificationCodesPayload, validationSchema} from './validation';
import {LegDetailsFields} from 'App/containers/shipments/drayage/components/LegDetailsFields';
import Stops from 'App/containers/shipments/components/StopsFields';
import ReferencesFields from 'App/containers/shipments/components/ReferencesFields';
import Documents from 'App/containers/shipments/components/Documents';
import TagsFields from 'App/containers/shipments/components/TagsFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import useUpdateServices from 'App/data-hooks/services/useUpdateServices';
import {
  createOceanTracking,
  createRailTracking,
  createOrderFromShipment,
  uploadOceanTrackingServiceDocument,
  uploadRailTrackingServiceDocument
} from 'App/api/corrogo';
import {assignServiceToShipment} from 'App/api/corrogo/typed';
import withStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import Loader from 'App/common/shipwellLoader';
import getNil from 'App/utils/getNil';
import {portTypes, shipmentDirections, referenceTypes, contacts} from 'App/containers/shipments/utils/constants';
import {transformDocumentsToFormValues, transformShipmentToFormValues} from 'App/containers/shipments/utils';
import {transformIdentificationCodeScac} from 'App/containers/shipments/utils/typed';
import {
  useUpdateServiceDocuments,
  useBrokerCustomers,
  useShipmentTags,
  useCreateOrUpdateShipment,
  useV3Shipment
} from 'App/data-hooks';
import {StopTypeId} from 'App/containers/shipments/components/StopsFields/constants';

const defaultFormValues = {
  customer: null,
  name: null,
  direction: null,
  booking_number: null,
  bol_number: null,
  house_bol_number: null,
  customer_reference_number: null,
  po_number: null,
  port_type: null,
  steamship_line: null,
  identification_codes:
    //scac identification code
    [{qualifier: OceanTrackingReferenceType.Scac, value: null}],
  vessel_name: null,
  voyage_number: null,
  estimated_arrival: null,
  early_return_date: null,
  cut_date: null,
  third_party_contact: {
    first_name: null,
    last_name: null,
    phone_number: null,
    email: null
  },
  pickup: {},
  delivery: {},
  containerPickup: {},
  containerReturn: {},
  documents: []
};

export function createStops(values) {
  const hasContainerPickup = !isEmpty(values.containerPickup);
  const pickupSequenceNumber = hasContainerPickup ? 2 : 1;
  const deliverySequenceNumber = hasContainerPickup ? 3 : 2;
  return map(pick(values, 'containerPickup', 'pickup', 'delivery', 'containerReturn'), (stopValues, key) => {
    const isContainerPickup = key === 'containerPickup';
    const isContainerReturn = key === 'containerReturn';
    const isPickup = key === 'pickup';
    const isDelivery = key === 'delivery';

    if (
      (isContainerPickup && !values.containerPickupRequired) ||
      (isContainerReturn && !values.containerReturnRequired)
    ) {
      return;
    }

    const address = stopValues.address ?? stopValues;

    const sequenceNumber = isContainerPickup
      ? 1
      : isContainerReturn
      ? 3
      : isPickup
      ? pickupSequenceNumber
      : isDelivery
      ? deliverySequenceNumber
      : -1;

    return {
      sequence_number: sequenceNumber,
      location: {
        address: {
          country: address.country,
          postal_code: address.postal_code,
          line_1: address.address_1,
          line_2: address.address_2 || null,
          region: address.state_province,
          locality: address.city
        },
        company_name: stopValues.company_name || undefined,
        location_type: isContainerPickup || isContainerReturn ? LocationType.ContainerYard : undefined
      },
      instructions: stopValues.instructions || null,
      contacts: stopValues.point_of_contacts.map((contact) => ({
        person_name: `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || null,
        email: contact.email ? contact.email : null,
        phone: contact.phone_number,
        id: contact.id
      }))
    };
  }).filter((stopValues) => !isEmpty(stopValues));
}

const LegForm = ({user, company, onSubmit, shipmentId}) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const {
    addTagToShipmentMutation,
    removeTagFromShipmentMutation,
    context: {initialTags}
  } = useShipmentTags(shipmentId);

  const createOceanTrackingMutation = useMutation(createOceanTracking, {
    onError: (error) => console.error('Error creating ocean tracking service:', error.message)
  });

  const addDocumentToOceanTrackingServiceMutation = useMutation(
    ({serviceId, file, description, type}) => uploadOceanTrackingServiceDocument(serviceId, file, description, type),
    {
      onError: (error) => console.error(error.message)
    }
  );

  const createRailTrackingMutation = useMutation(createRailTracking, {
    onError: (error) => console.error('Error creating rail tracking service:', error.message)
  });

  const addDocumentToRailTrackingServiceMutation = useMutation(
    ({serviceId, file, description, type}) => uploadRailTrackingServiceDocument(serviceId, file, description, type),
    {
      onError: (error) => console.error(error.message)
    }
  );

  const assignServiceToShipmentMutation = useMutation(
    ({shipmentId, serviceId}) => assignServiceToShipment(shipmentId, serviceId, []),
    {
      onError: (error) => console.error('Error assigning service to shipment:', error.message)
    }
  );

  const createOrderMutation = useMutation(({shipmentId, createOrderFromShipmentRequest}) =>
    createOrderFromShipment(shipmentId, createOrderFromShipmentRequest)
  );

  const {createOrUpdateShipmentAsync, isLoading: createOrUpdateShipmentIsLoading} =
    useCreateOrUpdateShipment(shipmentId);

  const {
    updateOceanTrackingServiceMutation,
    updateRailTrackingServiceMutation,
    updateRailTrackingServiceContactMutation,
    updateOceanTrackingServiceContactMutation
  } = useUpdateServices(shipmentId);

  const shipmentQuery = useV3Shipment(shipmentId);

  const {
    context: {getCustomerOptionFromId}
  } = useBrokerCustomers(company);

  const {
    context: {
      serviceDocuments,
      pickupStopInfo,
      deliveryStopInfo,
      serviceFormValues,
      service,
      modeSpecificDisplayValues,
      modeSpecificFormValues: initialModeSpecificValues,
      shipmentInfoFormValues: initialShipmentValues,
      stopsFormValues,
      getStopType
    }
  } = shipmentQuery;

  const isImport = modeSpecificDisplayValues.isImport;

  const initialPickupStopValue = pickupStopInfo.stopFormValues;
  const initialDeliveryStopValue = deliveryStopInfo.stopFormValues;

  const [containerStopType, containerStopFormValues] =
    stopsFormValues?.reduce((_, stopFormValues) => {
      const stopType = getStopType(stopFormValues.sequenceNumber);
      return stopType ? [stopType.id, stopFormValues] : [];
    }) || [];
  const initialContainerStopValues =
    containerStopType && containerStopFormValues ? {[containerStopType]: containerStopFormValues} : {};
  const initialContainerStopRequiredValue =
    containerStopType === StopTypeId.ContainerPickup
      ? {containerPickupRequired: true}
      : containerStopType === StopTypeId.ContainerReturn
      ? {containerReturnRequired: true}
      : {};

  const initialDocumentValues = transformDocumentsToFormValues(serviceDocuments);
  const initialTrackingValues = serviceFormValues;
  const defaultTags = get(user, 'default_shipment_tags', []);
  const initialCustomerValue = transformShipmentToFormValues(getNil(shipmentQuery, 'data'), getCustomerOptionFromId);

  const {
    uploadRailTrackingDocumentMutation,
    uploadOceanTrackingDocumentMutation,
    deleteRailTrackingDocumentMutation,
    deleteOceanTrackingDocumentMutation,
    updateOceanTrackingDocumentMutation,
    updateRailTrackingDocumentMutation
  } = useUpdateServiceDocuments(service?.id);

  const handleCreateShipment = async (values) => {
    const {
      booking_number: bookingNumber,
      bol_number: bolNumber,
      house_bol_number: houseBolNumber,
      po_number: poNumber,
      customer_reference_number: customerReferenceNumber,
      direction,
      cut_date: cutDate,
      early_return_date: earlyReturnDate,
      name,
      tags,
      identification_codes: identificationCodes
    } = values;

    const stops = createStops(values);

    const references = [
      bookingNumber ? {qualifier: referenceTypes.BOOKING_NUMBER, value: bookingNumber || undefined} : null,
      bolNumber ? {qualifier: referenceTypes.BOL_NUMBER, value: bolNumber || undefined} : null,
      houseBolNumber ? {qualifier: referenceTypes.HOUSE_BOL_NUMBER, value: houseBolNumber || undefined} : null,
      poNumber ? {qualifier: referenceTypes.PO_NUMBER, value: poNumber || undefined} : null,
      customerReferenceNumber
        ? {qualifier: referenceTypes.CUSTOMER_REFERENCE_NUMBER, value: customerReferenceNumber || undefined}
        : null,
      transformIdentificationCodeScac(identificationCodes)
    ].filter((reference) => !!reference);

    const data = {
      name,
      stops,
      mode_specific_data: [
        {
          mode: DrayageShipmentModeSpecificDataModeEnum.Drayage,
          export_info:
            direction === shipmentDirections.EXPORT ? {cut_date: cutDate, return_date: earlyReturnDate} : null,
          import_info:
            direction === shipmentDirections.IMPORT ? {release_date: undefined, last_free_day: undefined} : null
        }
      ],
      references
    };

    const shipmentId = await createOrUpdateShipmentAsync(data);
    // filter existing tags before adding and removing tags to the shipment to prevent dupes
    tags
      .filter((tagId) => initialTags.indexOf(tagId) === -1)
      .forEach((tagId) => addTagToShipmentMutation.mutate({shipmentId, tagId}));

    initialTags
      .filter((initialTagId) => !tags.includes(initialTagId))
      .forEach((tagId) => removeTagFromShipmentMutation.mutate({shipmentId, tagId}));

    return shipmentId;
  };

  const createOrUpdateTrackingService = async (shipmentId, upsertedShipmentId, values) => {
    const shouldCreateNewService = isEmpty(shipmentId);

    const {
      port_type: portType,
      steamship_line: steamshipLine,
      identification_codes: identificationCodes,
      vessel_name: vesselName,
      voyage_number,
      estimated_arrival: estimatedArrival,
      third_party_contact: thirdPartyContact,
      documents
    } = values;

    const contact = {
      contact_type: contacts.OTHER,
      person_name: `${thirdPartyContact.first_name || ''} ${thirdPartyContact.last_name || ''}`.trim() || null,
      email: thirdPartyContact.email ? thirdPartyContact.email : null,
      phone: thirdPartyContact.phone_number
    };

    // if a port type is set, create a tracking service instance and assign it to the shipment
    if ([portTypes.OCEAN, portTypes.RAIL].includes(portType)) {
      const references = identificationCodes?.length
        ? [
            {
              qualifier: referenceTypes.SCAC,
              value: identificationCodes[0].value
            }
          ]
        : null;
      const trackingItem =
        portType === portTypes.OCEAN
          ? {
              steamship_line: steamshipLine || undefined,
              identification_codes: getIdentificationCodesPayload(identificationCodes),
              vessel_name: vesselName || undefined,
              planned_arrival: estimatedArrival ?? null,
              actual_arrival: null,
              contacts: [contact],
              voyage_number,
              references: references
            }
          : {
              rail_company: steamshipLine || undefined,
              identification_codes: getIdentificationCodesPayload(identificationCodes),
              planned_arrival: estimatedArrival ?? null,
              contacts: [contact],
              actual_arrival: null,
              references: references
            };

      const isOcean = portType === portTypes.OCEAN;

      const uploadDocuments = (documentMutation, serviceId) => {
        documents
          .filter((document) => !document.id)
          .forEach((document) =>
            documentMutation.mutate({
              serviceId,
              file: document.data,
              description: document.description,
              type: document.type
            })
          );
      };

      const deleteDocuments = (documentMutation, serviceId) => {
        const documentsIds = documents.map((document) => document.id);

        serviceDocuments
          .filter((document) => !documentsIds.includes(document.id))
          .forEach((document) =>
            documentMutation.mutate({
              serviceId,
              documentId: document.id
            })
          );
      };

      const updateDocuments = (documentMutation, serviceId) => {
        documents
          .filter((document) => {
            const serviceDocument = serviceDocuments.find((serviceDocument) => serviceDocument.id === document.id);
            return serviceDocument.type !== document.type || serviceDocument.description !== document.description;
          })
          .forEach((document) =>
            documentMutation.mutate({
              serviceId,
              documentId: document.id,
              description: document.description,
              type: document.type
            })
          );
      };

      // if a service already exists, do not create a new service and associate it to a shipment
      if (shouldCreateNewService) {
        const serviceData = isOcean
          ? await createOceanTrackingMutation.mutateAsync(trackingItem)
          : await createRailTrackingMutation.mutateAsync(trackingItem);

        const serviceId = serviceData.data.id;

        if (isOcean) {
          uploadDocuments(addDocumentToOceanTrackingServiceMutation, serviceId);
        } else {
          uploadDocuments(addDocumentToRailTrackingServiceMutation, serviceId);
        }

        await assignServiceToShipmentMutation.mutateAsync({shipmentId: upsertedShipmentId, serviceId});
      } else {
        const existingServiceId = service?.id;

        const updateService = (updateServiceMutation) => {
          updateServiceMutation.mutateAsync({serviceId: existingServiceId, data: trackingItem});
        };

        const updateThirdPartyContact = (updateContactMutation) => {
          updateContactMutation.mutateAsync({
            serviceId: existingServiceId,
            contactId: thirdPartyContact.id,
            data: contact
          });
        };

        if (isOcean) {
          updateService(updateOceanTrackingServiceMutation);
          updateThirdPartyContact(updateOceanTrackingServiceContactMutation);
          uploadDocuments(uploadOceanTrackingDocumentMutation, existingServiceId);
          deleteDocuments(deleteOceanTrackingDocumentMutation, existingServiceId);
          updateDocuments(updateOceanTrackingDocumentMutation, existingServiceId);
        } else {
          updateService(updateRailTrackingServiceMutation);
          updateThirdPartyContact(updateRailTrackingServiceContactMutation);
          uploadDocuments(uploadRailTrackingDocumentMutation, existingServiceId);
          deleteDocuments(deleteRailTrackingDocumentMutation, existingServiceId);
          updateDocuments(updateRailTrackingDocumentMutation, existingServiceId);
        }
      }
    }
  };

  const createOrder = async (shipmentId, values) => {
    const {customer} = values;

    const customerCompany = getNil(customer, 'company', {});

    // Don't create an order if the customer's company is the company creating the leg
    if (customerCompany.id === company?.id) {
      return;
    }

    const address = getNil(customerCompany, 'billing_address');
    const identifyingCodes = getNil(customerCompany, 'identifying_codes', []);
    const createOrderResult = await createOrderMutation.mutateAsync({
      shipmentId,
      createOrderFromShipmentRequest: {
        customer: {
          party_id: customer.id,
          name: customerCompany.name,
          address: address
            ? {
                country: address.country,
                postal_code: address.postal_code,
                line_1: address.address_1,
                line_2: address.address_2,
                region: address.state_province,
                locality: address.city
              }
            : address,
          identification_codes: identifyingCodes.map((identifyingCode) => ({
            qualifier: identifyingCode.type,
            value: identifyingCode.value
          }))
        }
      }
    });

    return createOrderResult.data?.data?.order_id;
  };

  const handleSubmit = async (values, actions) => {
    const upsertedShipmentId = await handleCreateShipment(values);
    await createOrUpdateTrackingService(shipmentId, upsertedShipmentId, values);

    if (isEmpty(shipmentId)) {
      await createOrder(upsertedShipmentId, values);
    }

    actions.setSubmitting(false);

    onSubmit({shipmentId: upsertedShipmentId});
  };

  return (
    <div className="px-6 pb-6">
      {isEmpty(user) ? (
        <Loader loading />
      ) : (
        <Formik
          initialValues={{
            ...defaultFormValues,
            ...initialShipmentValues,
            ...initialTrackingValues,
            ...initialModeSpecificValues,
            ...initialContainerStopValues,
            ...initialContainerStopRequiredValue,
            tags: shipmentId ? initialTags : defaultTags,
            pickup: initialPickupStopValue,
            delivery: initialDeliveryStopValue,
            documents: initialDocumentValues,
            customer: initialCustomerValue,
            direction: shipmentId ? (isImport ? shipmentDirections.IMPORT : shipmentDirections.EXPORT) : null
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form noValidate>
            <div className="mb-20">
              <Card title="Shipment Information" className="mb-6">
                <LegDetailsFields shipmentId={shipmentId} />
              </Card>
              <Card title="Stops" className="mb-6">
                <Stops />
              </Card>
              <div className="flex">
                <Card title="References" className="mb-6 mr-3 h-fit flex-1">
                  <ReferencesFields />
                </Card>
                <Card
                  title="Documents"
                  className="mb-6 ml-3 flex-1"
                  actions={
                    <DeprecatedButton variant="tertiary" onClick={() => setShowDocumentUpload(true)}>
                      Upload Documents
                    </DeprecatedButton>
                  }
                  bodyClassName="py-2 flex flex-col"
                >
                  <Documents showDocumentUpload={showDocumentUpload} setShowDocumentUpload={setShowDocumentUpload} />
                </Card>
              </div>
              <Card title="Tags">
                <TagsFields />
              </Card>
            </div>
            <FormFooter primaryActionName="Next - Container Details" ignoreIsDirty />
          </Form>
        </Formik>
      )}
      {createOrUpdateShipmentIsLoading ? <Loader loading /> : null}
    </div>
  );
};

LegForm.propTypes = {
  ...WithStatusToastsPropTypes,
  user: PropTypes.shape({
    default_shipment_tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onSubmit: PropTypes.func,
  shipmentId: PropTypes.string
};

LegForm.defaultProps = {
  onSubmit: () => {}
};

export default compose(
  connect((state) => ({
    user: state.userProfile.user,
    company: state.userCompany.company
  })),
  withStatusToasts
)(LegForm);
