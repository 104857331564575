import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import getTimeOptions from 'App/utils/getTimeOptions';

const ExpirationField = ({...props}) => (
  <Field
    label="Expiration"
    options={getTimeOptions().filter((e) => e.value !== 0)}
    name="expires_after_seconds"
    component={FormikSelect}
    {...props}
  />
);

export default ExpirationField;
