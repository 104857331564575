import {Link} from 'react-router';
import {Supplier} from '@shipwell/backend-core-sdk';
import {createColumnHelper} from '@tanstack/react-table';
import ActionsCell from 'App/containers/suppliers/components/ActionsCell';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

const columnHelper = createColumnHelper<Supplier>();

export const getColumns = (
  setSuccess: WithStatusToastProps['setSuccess'],
  setError: WithStatusToastProps['setError']
) => [
  columnHelper.accessor('name', {
    id: 'Name',
    header: 'Name',
    cell: (info) => {
      const supplier = info.row.original;
      return <Link to={`/suppliers/${supplier.id}`}>{info.getValue()}</Link>;
    }
  }),
  columnHelper.accessor('external_id', {
    id: 'ID',
    header: 'ID',
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('mailing_address', {
    id: 'Mailing Address',
    header: 'Mailing Address',
    cell: (info) => info.getValue()?.formatted_address || '--'
  }),
  columnHelper.accessor('billing_address', {
    id: 'Billing Address',
    header: 'Billing Address',
    cell: (info) => info.getValue()?.formatted_address || '--'
  }),
  columnHelper.accessor('billing_email', {
    id: 'Email',
    header: 'Email',
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('users', {
    id: 'Contact(s)',
    header: 'Contact(s)',
    enableSorting: false,
    cell: (info) =>
      info
        .getValue()
        ?.map((user) => user.full_name)
        .join(', ') || '--'
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    maxSize: 60,
    enablePinning: true,
    cell: (info) => {
      const supplier = info.row.original;
      return <ActionsCell supplierId={supplier.id} setSuccess={setSuccess} setError={setError} />;
    }
  })
];
