import {useQuery, useQueryClient} from '@tanstack/react-query';
import type {UseQueryOptions, QueryKey} from '@tanstack/react-query';
import type {Stop as SdkStop} from '@shipwell/backend-core-sdk';
import type {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getFullShipmentDetails, fetchExternalShipmentPromise, Stop} from 'App/api/shipment/typed';
import {LEGACY_SHIPMENTS_QUERY_KEY, SHIPMENT_STOPS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {formatDateTimeTimestamp} from 'App/utils/dateTimeGlobalsTyped';

function formatStopLane(stop: SdkStop | undefined) {
  const city = stop?.location.address.city;
  const state = stop?.location.address.state_province;
  return city && state ? `${city}, ${state}` : null;
}

function transformLaneDetails(stops: SdkStop[] | undefined) {
  const sortedStops = stops?.sort((a, b) => a.ordinal_index - b.ordinal_index) || [];
  const pickupStop = sortedStops.find((stop) => stop.is_pickup) || sortedStops.length > 1 ? sortedStops[0] : undefined;
  const deliveryStop =
    sortedStops.find((stop) => stop.is_dropoff) || sortedStops.length > 1 ? sortedStops[1] : undefined;
  const pickupLane = formatStopLane(pickupStop);
  const deliveryLane = formatStopLane(deliveryStop);
  const pickupDate = pickupStop?.display_planned_window;
  const deliveryDate = deliveryStop?.display_planned_window;
  const actualDeliveryDateTime = formatDateTimeTimestamp(
    deliveryStop?.confirmed_departure_at || '',
    deliveryStop?.location?.address?.timezone || ''
  );
  const lane = {
    pickupLane,
    deliveryLane,
    pickupDate,
    deliveryDate,
    actualDeliveryDateTime
  };

  return lane;
}

export default function useLegacyShipment(
  shipmentId?: string | null,
  queryOptions?: Pick<UseQueryOptions<Shipment>, 'enabled' | 'onSuccess' | 'refetchOnWindowFocus'>,
  isUnauth?: boolean,
  key?: string
) {
  const queryClient = useQueryClient();
  const shipmentQuery = useQuery(
    [LEGACY_SHIPMENTS_QUERY_KEY, shipmentId] as QueryKey,
    async () => {
      if (!shipmentId) {
        return Promise.reject(new Error('Invalid shipment id'));
      }
      if (isUnauth && !key) {
        return Promise.reject(new Error('Cannot use Unauthorized endpoint without shipment key'));
      }
      const fetchShipmentPromise =
        isUnauth && key ? fetchExternalShipmentPromise(shipmentId, key) : getFullShipmentDetails(shipmentId);
      const {data} = await fetchShipmentPromise;
      if (data?.stops?.length) {
        queryClient.setQueryData<Stop[]>(
          [SHIPMENT_STOPS_QUERY_KEY, shipmentId],
          data.stops
            .sort((left, right) => left.ordinal_index - right.ordinal_index)
            .map((s, i) => ({...s, stopNumber: i + 1}))
        );
      }
      return data;
    },
    {
      ...queryOptions,
      staleTime: HOT_DATA_STALE_TIME
    }
  );

  const customerName = shipmentQuery.data?.customer?.name || '--';
  const carrier = {
    name: shipmentQuery.data?.relationship_to_vendor?.vendor.name || '--',
    status: shipmentQuery.data?.relationship_to_vendor?.carrier_status,
    id: shipmentQuery.data?.relationship_to_vendor?.freight_authority_vendor_relationship
  };
  const lane = transformLaneDetails(shipmentQuery.data?.stops);

  return {shipmentQuery, customerName, carrier, lane};
}
