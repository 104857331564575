import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {ActionStepLabel} from './ActionStepLabel';
import {getActionParams} from './utils';
import {WORKFLOW_ACTION} from 'App/data-hooks/queryKeys';

export const WorkflowAction = ({action, stepNumber, id}) => {
  const actionId = action?.action_id || action?.type?.value;
  const stepId = action?.step_id || action?.STEP_ID;
  const actionQuery = useQuery([WORKFLOW_ACTION, actionId, id, stepId], () => getActionParams(action));
  const actionStatus = action?.status?.toLowerCase();

  const iconInfo = () => {
    if (actionStatus === 'end') {
      if (action?.extraInfo?.type === 'success') {
        return {
          name: 'CheckCircleFilled',
          color: 'sw-success',
          tooltip: action?.extraInfo?.message
        };
      }
      if (action?.extraInfo?.type === 'warning') {
        return {
          name: 'ErrorFilled',
          color: 'sw-warning',
          tooltip: action?.extraInfo?.message
        };
      }
      return {
        name: 'CheckCircleFilled',
        color: 'sw-success',
        tooltip: 'Step Successful'
      };
    }

    switch (actionStatus) {
      case 'failed':
        return {
          name: 'ErrorFilled',
          color: 'sw-error',
          tooltip: `Step failed${action?.error?.errorMessage ? `: ${action?.error?.errorMessage}` : ''}`
        };
      case 'skipped':
        return {
          name: 'ErrorFilled',
          color: 'sw-warning',
          tooltip: `Step skipped${action?.skippedStepExplanation ? `: ${action?.skippedStepExplanation}` : ''}`
        };
      case 'start':
        return {
          name: 'Running',
          color: 'sw-text-section-title',
          tooltip: 'Step running'
        };
      case 'cancelled':
        return {
          name: 'Cancel',
          color: 'sw-text-section-title',
          tooltip: 'Workflow cancelled'
        };
      default:
        return {
          name: 'CheckCircleOutlined',
          color: 'sw-disabled-alternate',
          tooltip: 'Step not started'
        };
    }
  };

  const {name, color, tooltip} = iconInfo();

  return (
    <div className="flex items-center gap-2">
      <Tooltip tooltipContent={tooltip} placement="top">
        <SvgIcon name={name} color={color} />
      </Tooltip>
      <span>
        <span className="font-bold">Step {stepNumber}: </span>
        {actionQuery.isInitialLoading ? <SvgIcon className="inline-block" name="LoadingDots" /> : null}
        {actionQuery.isSuccess ? <ActionStepLabel action={actionQuery.data} id={actionId} /> : null}
      </span>
    </div>
  );
};

WorkflowAction.propTypes = {
  action: PropTypes.shape({
    action_id: PropTypes.string,
    step_id: PropTypes.string,
    STEP_ID: PropTypes.string,
    actionParams: PropTypes.shape({
      to: PropTypes.string,
      exp: PropTypes.string,
      amount: PropTypes.string,
      currency: PropTypes.string
    }),
    type: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    status: PropTypes.oneOf(['end', 'failed', 'skipped', 'start', 'cancelled', 'future']),
    extraInfo: PropTypes.shape({
      step: PropTypes.string,
      type: PropTypes.string,
      message: PropTypes.string
    }),
    skippedStepExplanation: PropTypes.string,
    error: PropTypes.shape({
      errorMessage: PropTypes.string
    })
  }),
  stepNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string
};
