import get from 'lodash/get';

const urlMap = {
  dashboard: 'Dashboard',
  shipments: 'Shipment',
  workflows: 'Workflows',
  bids: 'Bids',
  tracking: 'Tracking',
  financials: 'Financials',
  'instant-rates': 'Instant Rates',
  'source-capacity': 'Source Capacity',
  'parcel-rates': 'Parcel Rates',
  addressbook: 'Address Book',
  'addressbook/create': 'Add Address Book Entry',
  products: 'Products',
  'products/create': 'Add Product',
  'company/profile': 'Company',
  'company/users': 'Users',
  'company/custom-data': 'Custom Data',
  'company/carrier-instructions': 'Carrier Instructions',
  'company/integrations': 'Integrations',
  'load-board': 'Load Board',
  load: 'Load #',
  carriers: 'Carriers',
  'carriers/create': 'Add Carrier',
  'carriers/search': 'Search for Carrier',
  customers: 'Customers',
  'customers/create': 'Add Customer',
  'automation/routing-guides': 'Routing Guides',
  'automation/contracts': 'Contracts',
  'automation/service-level-agreements': 'Service Level Agreements',
  'purchase-orders': 'Orders',
  'purchase-orders/create': 'Add Order',
  'purchase-order-detail': 'Order',
  documents: 'Shipment Document',
  analytics: 'Analytics',
  'bid-manager': 'Bid Manager',
  'pricing-intel': 'Pricing Intel',
  dispatch: 'Dispatch',
  'equipment/power-units': 'Power Units',
  'equipment/trailers': 'Trailers',
  'supplier-shipments': 'Shipment Requests',
  'new-shipment': 'New Shipment',
  'new-quote': 'New Quote',
  'edit-shipment': 'Edit Quote',
  'confirm-shipment': 'Confirm Details',
  'user/profile': 'User Profile',
  'user/notifications': 'Notifications',
  'user/reset-password': 'Reset Password',
  'user/user-defaults': 'User Defaults',
  drivers: 'Drivers',
  'drivers/create': 'Add Driver',
  compass: 'Compass',
  'tender-request': 'Tender Request',
  'load-optimizations': 'Load Optimization',
  'containers/edit': 'Edit Containers',
  settlements: 'Settlements',
  'purchase-orders-corrogo': 'Purchase Order',
  'sales-orders': 'Sales Order'
};

/**
 * This function provides a map of page titles for corresponding URL routes.
 * It also appends the identifier to the title (e.g., Shipment ABCDEF) if it exists.
 * @param {*} location
 * @param {*} params
 * @param {*} identifier
 */
export const getPageTitle = (location = {}, params, identifier = '') => {
  let {pathname = ''} = location;

  if (params && Object.keys(params).length) {
    if (pathname.includes('bids')) {
      pathname = '/bids/';
    }
    if (pathname.includes('workflows')) {
      pathname = '/workflows/';
    }
    if (pathname.includes('tracking')) {
      pathname = '/tracking/';
    }
    if (pathname.includes('financials')) {
      pathname = '/financials/';
    }
    if (pathname.includes('instant-rates')) {
      pathname = '/instant-rates/';
    }
    if (pathname.includes('source-capacity')) {
      pathname = '/source-capacity/';
    }
    if (pathname.includes('marketplace')) {
      pathname = '/parcel-rates/';
    }
    if (pathname.includes('confirm')) {
      pathname = '/confirm-shipment/';
    }
    if (pathname.includes('shipments') && pathname.includes('documents')) {
      pathname = '/documents/';
    }
    if (
      (pathname.includes('shipments') && pathname.includes('details')) ||
      (pathname.includes('shipments') && pathname.includes('bids'))
    ) {
      pathname = '/shipments/';
    }
    if (pathname.includes('load-optimizations')) {
      pathname = '/load-optimizations/';
    }
    if (pathname.includes('shipments') && pathname.includes('containers/edit')) {
      pathname = '/containers/edit/';
    }
    if (pathname.includes('settlements')) {
      pathname = '/settlements/';
    }

    //when we have params on the URL, remove those to get the base resource
    pathname = pathname.slice(0, pathname.lastIndexOf('/'));
  }
  // remove leading and trailing slashes for consistency
  const trimmedPathname = pathname.replace(/^\/|\/$/g, '');

  let title = get(urlMap, trimmedPathname, null);
  if (identifier) {
    title += ` ${identifier}`;
  }

  return title;
};
