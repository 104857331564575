import useGetActiveLoadboards from 'App/api/loadboards/hooks/useGetActiveLoadboards';
import {useUserMe} from 'App/data-hooks';
import {DAT} from 'App/containers/integrations/details/components/datDetails/utils';

const useDatIntegration = () => {
  const {data: userMeData} = useUserMe();
  const {data: activeLoadboardsData = [], error: activeLoadboardsError} = useGetActiveLoadboards({
    retry: false
  });

  const datLoadboardDetails = activeLoadboardsData.find((item) => item.loadboard_type === DAT);

  const isDatIntegrated =
    !activeLoadboardsError &&
    Boolean(datLoadboardDetails?.users?.some((user) => user.sw_user_id === userMeData?.user?.id));

  return {isDatIntegrated};
};

export default useDatIntegration;
