import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateAutoDisputeInvoiceConfiguration} from 'App/api/settlements';
import {FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useUpdateAutoDisputeInvoiceConfiguration = ({onSuccess}: {onSuccess?: () => void} = {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateAutoDisputeInvoiceConfiguration, {
    onSuccess,
    onSettled() {
      void queryClient.invalidateQueries([FREIGHT_SETTLEMENTS_CONFIGURATIONS_QUERY_KEY]);
    }
  });
};
