import {ReactNode} from 'react';
import {SvgIcon} from '@shipwell/shipwell-ui';

export const TableSidebar = ({
  children,
  isOpen,
  hasFiltersApplied = false,
  onClick
}: {
  children?: ReactNode;
  isOpen: boolean;
  hasFiltersApplied?: boolean;
  onClick: () => void;
}) => {
  return (
    <aside className="flex bg-sw-background-component">
      {isOpen ? <div className="flex h-full w-80 flex-col">{children}</div> : null}
      <div className="h-full bg-sw-primary">
        {!isOpen ? (
          <button
            aria-label="toggle table sidebar"
            className="p-2 text-sw-text-reverse"
            onClick={onClick}
            type="button"
          >
            <div className="relative">
              <SvgIcon name="Filter" />
              {hasFiltersApplied ? <div className="absolute right-0 top-0 size-2 rounded-full bg-sw-error" /> : null}
            </div>
          </button>
        ) : (
          <div className="w-2" />
        )}
      </div>
    </aside>
  );
};
