import {useEffect, useState} from 'react';
import get from 'lodash/get';
import {useEquipmentTypesQuery} from './useEquipmentTypesQuery';
import {drayageEquipmentTypesFilter} from 'App/utils/filterEquipmentTypes';

const truckLoadFields = ['FTL', 'LTL', 'VLTL'];
export default function useResponsiveModesAndEquipmentTypes({
  equipmentTypesFieldName,
  modesFieldName,
  values,
  setFieldValue
}) {
  const {data: equipmentTypesQueryData} = useEquipmentTypesQuery();
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState(equipmentTypesQueryData || []);
  const [hideDrayage, setHideDrayage] = useState(false);
  const [hideTruckLoad, setHideTruckLoad] = useState(false);
  const modesValue = get(values, modesFieldName, null);

  useEffect(() => {
    const currentModes = modesValue?.code ? [modesValue] : modesValue;

    if (!currentModes || currentModes?.length === 0) {
      setFieldValue(equipmentTypesFieldName, []);
      setEquipmentTypesOptions([]);
      setHideTruckLoad(false);
    } else if (currentModes?.map((mode) => mode.code).includes('DRAYAGE')) {
      setEquipmentTypesOptions(equipmentTypesQueryData?.filter(drayageEquipmentTypesFilter));
      setHideTruckLoad(true);
    } else if (currentModes?.map((mode) => mode.code).includes('FTL')) {
      setEquipmentTypesOptions(equipmentTypesQueryData);
      setHideTruckLoad(false);
    } else {
      setEquipmentTypesOptions(
        equipmentTypesQueryData?.filter((option) => option.machine_readable !== 'MULTI_COMPARTMENT')
      );
      setHideTruckLoad(false);
    }

    if (currentModes?.some((value) => truckLoadFields.includes(value.code))) {
      setHideDrayage(true);
    } else {
      setHideDrayage(false);
    }
  }, [modesValue, setFieldValue, equipmentTypesQueryData, equipmentTypesFieldName, modesFieldName]);

  const modesBlackList = ['PARCEL'];
  if (hideDrayage) {
    modesBlackList.push('DRAYAGE');
  }
  if (hideTruckLoad) {
    modesBlackList.push(...truckLoadFields);
  }

  return {equipmentTypesOptions, modesBlackList};
}
