import {ReactNode} from 'react';
import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import {Tooltip} from '@shipwell/shipwell-ui';
import {OperatingHoursEnums} from 'App/containers/userCompany/defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigDetail/OperatingDaysInputs';
import {getOperatingDays} from 'App/containers/userCompany/defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigDetail/utils';

type OpenTimeKeys = keyof Pick<
  AddressBookEntry,
  | 'sunday_open_time'
  | 'monday_open_time'
  | 'tuesday_open_time'
  | 'wednesday_open_time'
  | 'thursday_open_time'
  | 'friday_open_time'
  | 'saturday_open_time'
>;

export const AddressHoursTooltip = ({
  addressBookEntry,
  children
}: {
  addressBookEntry: AddressBookEntry;
  children?: ReactNode;
}) => {
  const operatingHours = getOperatingDays(addressBookEntry);
  const operatingHoursLabel = () => {
    switch (operatingHours) {
      case OperatingHoursEnums.weekdays:
        return 'Weekdays';
      case OperatingHoursEnums.weekdaysAndWeekends:
        return 'Weekdays & Weekends';
      default:
        return 'Custom';
    }
  };

  return (
    <Tooltip
      placement="top"
      portal
      tooltipContent={
        <div className="flex flex-col">
          <span className="font-bold">{operatingHoursLabel()}</span>
          {operatingHours !== OperatingHoursEnums.custom ? (
            <>
              <span className="flex w-full justify-between gap-2">
                <span className="font-bold">Mon-Fri:</span>
                {addressBookEntry?.monday_open_time}-{addressBookEntry?.monday_close_time}
              </span>
              <OpenTimes openDay="open_saturday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_sunday" addressBookEntry={addressBookEntry} />
            </>
          ) : (
            <>
              <OpenTimes openDay="open_monday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_tuesday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_wednesday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_thursday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_friday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_saturday" addressBookEntry={addressBookEntry} />
              <OpenTimes openDay="open_sunday" addressBookEntry={addressBookEntry} />
            </>
          )}
        </div>
      }
    >
      {children || operatingHoursLabel()}
    </Tooltip>
  );
};

const OpenTimes = ({
  openDay,
  addressBookEntry
}: {
  openDay: keyof Pick<
    AddressBookEntry,
    | 'open_friday'
    | 'open_monday'
    | 'open_saturday'
    | 'open_sunday'
    | 'open_thursday'
    | 'open_tuesday'
    | 'open_wednesday'
  >;
  addressBookEntry: AddressBookEntry;
}) => {
  const isOpen = addressBookEntry[openDay];
  const day = openDay.replace('open_', '');
  const openTimeKey = `${day}_open_time` as OpenTimeKeys;
  const closeTimeKey = `${day}_close_time` as OpenTimeKeys;
  if (!isOpen) {
    return null;
  }
  return (
    <span className="flex w-full justify-between gap-2">
      <span className="font-bold capitalize">{day}:</span>
      {addressBookEntry?.[openTimeKey]}-{addressBookEntry?.[closeTimeKey]}
    </span>
  );
};
