import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {createStopEvent} from 'App/api/shipment/typed';
import {ShipmentsApiShipmentsShipmentIdStopsStopIdEventsPostRequest} from '@shipwell/backend-core-singlerequestparam-sdk';

export const useCreateStopEvent = (
  mutationOptions: UseMutationOptions<
    Awaited<ReturnType<typeof createStopEvent>>,
    AxiosError,
    ShipmentsApiShipmentsShipmentIdStopsStopIdEventsPostRequest
  > = {}
) => {
  return useMutation<
    Awaited<ReturnType<typeof createStopEvent>>,
    AxiosError,
    ShipmentsApiShipmentsShipmentIdStopsStopIdEventsPostRequest
  >(
    async (stopEventRequestParameters: ShipmentsApiShipmentsShipmentIdStopsStopIdEventsPostRequest) =>
      createStopEvent(stopEventRequestParameters),
    mutationOptions
  );
};
