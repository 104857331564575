import {
  REQUEST_TRAILERS_SUCCESS,
  REQUEST_TRAILER_SUCCESS,
  CREATE_TRAILER_SUCCESS,
  UPDATE_TRAILER_SUCCESS,
  DELETE_TRAILER_SUCCESS
} from '../actions';

/** Initial reducer state */
export const INITIAL_STATE = {
  trailers: [],
  trailersById: {}
};

/**
 * Carrier trailers reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    case REQUEST_TRAILERS_SUCCESS:
      return {
        ...state,
        ...setTrailers(state, payload.results)
      };

    case REQUEST_TRAILER_SUCCESS:
    case CREATE_TRAILER_SUCCESS:
    case UPDATE_TRAILER_SUCCESS:
      return {
        ...state,
        ...setTrailers(state, [payload])
      };

    case DELETE_TRAILER_SUCCESS:
      return {
        ...state,
        ...removeTrailer(state, payload)
      };

    default:
      return state;
  }
};

/**
 * Normalize and set carrier trailers
 * @param {Object} state
 * @param {Array}  trailerList
 */
function setTrailers(state, trailerList = []) {
  const trailers = new Set(state.trailers);
  const trailersById = {...state.trailersById};

  trailerList.forEach((trailer) => {
    trailers.add(trailer.id);
    trailersById[trailer.id] = trailer;
  });

  return {trailers: [...trailers], trailersById};
}

/**
 * Remove carrier trailers
 * @param {Object} state
 * @param {Number} trailerId
 */
function removeTrailer(state, trailerId) {
  const trailers = new Set(state.trailers);
  const trailersById = {...state.trailersById};

  trailers.delete(trailerId);
  delete trailersById[trailerId];

  return {trailers: [...trailers], trailersById};
}
