import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';
import moment from 'moment';
import {Dropdown, DeprecatedButton, SvgIcon, Title, DisplayValue, Flex} from '@shipwell/shipwell-ui';
import './styles.scss';

const CardContentRow = (props) => {
  const {title, stop, undatedPurchaseOrders} = props;
  const {location, display_eta_window: displayEtaWindow} = stop;
  const {address} = location;

  const {values, setFieldValue, validateForm} = useFormikContext();

  const applyShipmentDateToOrders = (stopType, stop) => {
    const plannedStartKey = `planned_${stopType.toLowerCase()}_start_datetime`;
    const plannedEndKey = `planned_${stopType.toLowerCase()}_end_datetime`;

    undatedPurchaseOrders.map((order, index) => {
      if (!values.undatedOrders[index][plannedStartKey]) {
        setFieldValue(
          `undatedOrders[${index}].${plannedStartKey}`,
          moment(stop.planned_date, 'YYYY-MM-DD', moment.tz.guess())
        );
        setFieldValue(
          `undatedOrders[${index}].${plannedEndKey}`,
          moment(stop.planned_date, 'YYYY-MM-DD', moment.tz.guess())
        );
        validateForm();
      }
    });
  };

  return (
    <>
      <Flex flexDirection="column">
        <Title variant="sectionTitle">{title}</Title>
        <Flex className="shipment-section__row" justifyContent="space-between">
          <DisplayValue variant="sectionTitle" label="Address">
            <Flex flexDirection="column">
              <p>{address.address_1 ?? ''}</p>
              <p>{`${address.city ? `${address.city},` : ''} ${address.state_province ?? ''} ${
                address.postal_code ?? ''
              }`}</p>
            </Flex>
          </DisplayValue>

          <DisplayValue variant="sectionTitle" label={`${title} Date`}>
            <Flex flexDirection="column">
              <p>{displayEtaWindow}</p>
              <Dropdown
                className="apply-dates__dropdown"
                variant="tertiary"
                indicator={false}
                title="Apply Date to Order Stop"
                icon={<SvgIcon name="ExpandMore" />}
              >
                {() => [
                  <DeprecatedButton
                    key="aplly-dates-pickup"
                    className="apply-dates__button"
                    variant="tertiary"
                    onClick={() => applyShipmentDateToOrders('pickup', stop)}
                  >
                    All Unscheduled Pickups
                  </DeprecatedButton>,
                  <DeprecatedButton
                    key="aplly-dates-delivery"
                    className="apply-dates__button"
                    variant="tertiary"
                    onClick={() => applyShipmentDateToOrders('delivery', stop)}
                  >
                    All Unscheduled Deliveries
                  </DeprecatedButton>
                ]}
              </Dropdown>
            </Flex>
          </DisplayValue>
        </Flex>
      </Flex>
    </>
  );
};

CardContentRow.propTypes = {
  title: PropTypes.string,
  stop: PropTypes.object,
  undatedPurchaseOrders: PropTypes.array
};

/**
 * Shipment information card
 *
 * @param {Obj} shipment - the current shipment
 * @param {Function} applyShipmentDateToOrders - Applies the selected date to purchase all undated purchase order pickup dates, or purchase order delivery dates
 */
const ShipmentSection = (props) => {
  const {shipment, applyShipmentDateToOrders, undatedPurchaseOrders} = props;

  return (
    <Flex className="shipment-section" flexDirection="column">
      <Title className="shipment-section__title" variant="sectionTitle">
        Shipment Summary
      </Title>
      {shipment?.stops?.map((stop) => (
        <CardContentRow
          key={stop.id}
          title={stop.is_pickup ? 'Pickup' : 'Delivery'}
          stop={stop}
          applyShipmentDateToOrders={applyShipmentDateToOrders}
          undatedPurchaseOrders={undatedPurchaseOrders}
        />
      ))}
    </Flex>
  );
};

ShipmentSection.propTypes = {
  shipment: PropTypes.object,
  applyShipmentDateToOrders: PropTypes.func,
  undatedPurchaseOrders: PropTypes.array
};

export default ShipmentSection;
