import {CompanyInternationalPreferences, ShipmentPreferredCurrencyEnum} from '@shipwell/backend-core-sdk';
import {bool, string, object} from 'yup';

export const internationalConfigurationsSchema = object().shape({
  currency: string().nullable().required('Currency is required.'),
  allow_currency_change: bool().nullable()
});

export function getInitialValues(
  internationalPreferences?: CompanyInternationalPreferences
): CompanyInternationalPreferences {
  return {
    currency: ShipmentPreferredCurrencyEnum.Usd,
    allow_currency_change: false,
    //if there are international preferences, use those instead of the default values.
    ...internationalPreferences
  };
}
