import {Card, DisplayValue, Title, DeprecatedButton, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import get from 'lodash/get';
import Loader from 'App/common/shipwellLoader';
import ContainerModal from 'App/containers/shipments/drayage/components/ContainerFields';
import {useDrayageLeg} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import {PROPTYPE_DRAGGABLE_PROVIDED} from 'App/utils/propTypeConstants';

const ContainerCardBody = ({expanded, legId}) => {
  const drayageLegQuery = useDrayageLeg(legId);

  if (drayageLegQuery.isInitialLoading) {
    return <Loader loading={drayageLegQuery.isInitialLoading} />;
  }

  const {context} = drayageLegQuery;
  const {containerDisplayValues} = context;
  const container = get(containerDisplayValues, '0', {});
  const {
    returnBy = '',
    containerId = '',
    sealNumber = '',
    description = '',
    containerType = '',
    hazmat = '',
    hazmatDisplayName = '',
    weightInfo: {formattedWeight = '', weightUnit = '', overweight = ''} = {},
    dimensionsDisplay = '',
    temperatureRange
  } = container;

  return (
    <>
      <Title className="pb-2">IDs</Title>
      <div className="grid grid-cols-[60%,40%] gap-4">
        <DisplayValue label="Container #">{containerId}</DisplayValue>
        <DisplayValue label="Seal #">{sealNumber}</DisplayValue>
      </div>
      {expanded ? (
        <>
          <Title className="mt-1 border-t border-sw-border pb-2 pt-3">Container Items</Title>
          <div className="grid grid-cols-[60%,40%] gap-4 pb-2">
            <DisplayValue label="Container Type">
              {containerType}
              {dimensionsDisplay}
              {temperatureRange}
            </DisplayValue>
            <DisplayValue label="Commodity">{description}</DisplayValue>
          </div>
          <div className="grid grid-cols-[60%,40%] gap-4 pb-2">
            <DisplayValue label={`Total Weight (${pluralize(weightUnit)})`}>
              <div className="flex items-center">
                {formattedWeight}
                {overweight ? (
                  <Tooltip tooltipContent="Overweight" placement="top">
                    <SvgIcon name="ErrorOutlined" color="$sw-warning" className="ml-1" height="20px" width="20px" />
                  </Tooltip>
                ) : null}
              </div>
            </DisplayValue>
          </div>
          <div className="mb-3 border-b border-sw-border pb-2">
            {hazmat ? <DisplayValue label="Hazmat">{hazmatDisplayName}</DisplayValue> : null}
          </div>
          <Title className="pb-2">Dates</Title>
          <DisplayValue label="Container Return Date">{returnBy}</DisplayValue>
        </>
      ) : null}
    </>
  );
};

ContainerCardBody.propTypes = {
  legId: PropTypes.string.isRequired,
  expanded: PropTypes.bool
};

ContainerCardBody.defaultProps = {
  expanded: true
};

const ContainerView = ({legId, draggableProvided}) => {
  const [showContainerModal, toggleShowContainerModal] = useToggle();

  return (
    <>
      <Card
        className="mb-2"
        title="Container Details"
        isCollapsible
        actions={
          <DeprecatedButton variant="icon" onClick={toggleShowContainerModal}>
            <SvgIcon name="Edit" color="$sw-icon" />
          </DeprecatedButton>
        }
        draggableProvided={draggableProvided}
      >
        <ContainerCardBody legId={legId} />
      </Card>
      <ContainerModal legId={legId} showModal={showContainerModal} onClose={toggleShowContainerModal} />
    </>
  );
};

ContainerView.propTypes = {
  legId: PropTypes.string.isRequired,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

export default ContainerView;
