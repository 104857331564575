import {Select} from '@shipwell/shipwell-ui';
import {useFilteredAccessorials} from 'App/data-hooks';

export const AccessorialsFilter = ({
  defaultIds,
  defaultLabels,
  onChange
}: {
  defaultIds: string[];
  defaultLabels: string[];
  onChange: (ids: string[], labels: string[]) => void;
}) => {
  const {getFilteredAccessorialsQuery} = useFilteredAccessorials();
  const options = getFilteredAccessorialsQuery.data?.data?.map((accessorial) => ({
    label: accessorial.description,
    value: accessorial.code
  }));

  const defaultValues: {value: string; label: string}[] = defaultIds.map((id, i) => ({
    value: id,
    label: defaultLabels[i]
  }));

  return (
    <Select
      value={defaultValues}
      onChange={(options: {label: string; value: string}[]) => {
        const ids = options.map((option) => option.value);
        const labels = options.map((option) => option.label);
        onChange(ids, labels);
      }}
      isLoading={getFilteredAccessorialsQuery.isInitialLoading}
      options={options}
      isMulti
      label="Search Accessorials"
    />
  );
};
