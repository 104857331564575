import {LegsApi, LegsDrayageApi, ShipmentsApi, ServicesApi} from '@shipwell/corrogo-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_CORROGO_API_BASE_PATH;

// Shipments API
function createShipmentsApi() {
  return new ShipmentsApi({
    basePath,
    apiKey: getAccessToken
  });
}

export function createOrderFromShipment(shipmentId, createOrderFromShipmentRequest) {
  return createShipmentsApi().createOrderFromShipmentShipmentsShipmentIdOrdersPost(
    shipmentId,
    createOrderFromShipmentRequest
  );
}

export function createShipmentItem(shipmentId, data) {
  return createShipmentsApi().createShipmentItemShipmentsShipmentIdItemsPost(shipmentId, data);
}

export function updateShipmentItem(shipmentId, shipmentItemId, data) {
  return createShipmentsApi().updateShipmentItemShipmentsShipmentIdItemsShipmentItemIdPut(
    shipmentId,
    shipmentItemId,
    data
  );
}

export function createDrayageLegs(shipmentId) {
  return createShipmentsApi().createDrayageLegsFromShipmentShipmentsShipmentIdCreateDrayageLegsPost(shipmentId);
}

export function removeLegFromShipment(shipmentId, legId) {
  return createShipmentsApi().removeAssociationLegToShipmentShipmentsShipmentIdLegsLegIdDelete(shipmentId, legId);
}

// Services Api
function createServicesApi() {
  return new ServicesApi({
    basePath,
    apiKey: getAccessToken
  });
}
/**
 * Create an ocean tracking instance.
 *
 * @param {Object.<*, *>} oceanTrackingItem The data representing the ocean tracking
 *
 * @return {Promise}
 */
export function createOceanTracking(oceanTrackingItem) {
  return createServicesApi().createOceanTrackingServicesOceanTrackingPost(oceanTrackingItem);
}

/**
 * Create a rail tracking instance.
 *
 * @param {Object.<*, *>} railTrackingItem The data representing the rail tracking
 *
 * @return {Promise}
 */
export function createRailTracking(railTrackingItem) {
  return createServicesApi().createRailTrackingServicesRailTrackingPost(railTrackingItem);
}

export function updateOceanTrackingService(id, data) {
  return createServicesApi().updateOceanTrackingServicesOceanTrackingOceanTrackingIdPut(id, data);
}

export function updateOceanTrackingServiceContact(serviceId, contactId, contact) {
  return createServicesApi().updateOceanTrackingContactServicesOceanTrackingOceanTrackingIdContactsContactIdPut(
    serviceId,
    contactId,
    contact
  );
}

export function updateRailTrackingService(id, data) {
  return createServicesApi().updateRailTrackingServicesRailTrackingRailTrackingIdPut(id, data);
}

export function updateRailTrackingServiceContact(serviceId, contactId, contact) {
  return createServicesApi().updateRailTrackingContactServicesRailTrackingRailTrackingIdContactsContactIdPut(
    serviceId,
    contactId,
    contact
  );
}

export function uploadOceanTrackingServiceDocument(serviceId, file, description, type) {
  return createServicesApi().uploadOceanTrackingDocumentsServicesOceanTrackingOceanTrackingIdDocumentsPost(
    serviceId,
    file,
    description,
    type
  );
}

export function uploadRailTrackingServiceDocument(serviceId, file, description, type) {
  return createServicesApi().uploadRailTrackingDocumentsServicesRailTrackingRailTrackingIdDocumentsPost(
    serviceId,
    file,
    description,
    type
  );
}

// Legs Drayage API
function createLegsDrayageApi() {
  return new LegsDrayageApi({
    basePath,
    apiKey: getAccessToken
  });
}

/**
 * Create a drayage booking leg.
 *
 * @param {Object.<*, *>} data The data representing the drayage booking
 *
 * @return {Promise}
 */
export function createDrayageLeg(data) {
  return createLegsDrayageApi().createDrayageBookingLegsDrayagePost(data);
}

export function updateDrayageLegSummary(id, data) {
  return createLegsDrayageApi().updateDrayageBookingSummaryLegsDrayageDrayageBookingIdSummaryPut(id, data);
}

export function updateDrayageLegStops(id, data) {
  return createLegsDrayageApi().updateDrayageBookingStopsLegsDrayageDrayageBookingIdStopsPut(id, data);
}

export function updateDrayageLegStopsDetail(bookingId, sequenceId, data) {
  return createLegsDrayageApi().updateDrayageBookingsStopsDetailLegsDrayageDrayageBookingIdStopsSequenceIdPut(
    bookingId,
    sequenceId,
    data
  );
}

export function updateDrayageLegContacts(id, data) {
  return createLegsDrayageApi().updateDrayageLegContactsLegsDrayageDrayageBookingIdContactsPut(id, data);
}

export function updateDrayageLegAccessorials(legId, data) {
  return createLegsDrayageApi().updateDrayageBookingAccessorialsLegsDrayageDrayageBookingIdAccessorialsPut(legId, data);
}

export function updateDrayageLegReferences(legId, data) {
  return createLegsDrayageApi().updateDrayageBookingReferencesLegsDrayageDrayageBookingIdReferencesPut(legId, data);
}

export function uploadDrayageLegDocument(legId, file, description, type) {
  return createLegsDrayageApi().uploadDrayageLegDocumentsLegsDrayageDrayageBookingIdDocumentsPost(
    legId,
    file,
    description,
    type
  );
}

export function createTransportationOrder(createDrayageTransportationOrderFromMulitpleLegs) {
  return createLegsDrayageApi().createDrayageTransportationOrderFromMulitpleLegsLegsDrayageTransportationOrderPost(
    createDrayageTransportationOrderFromMulitpleLegs
  );
}

// Legs API
function createLegsApi() {
  return new LegsApi({
    basePath,
    apiKey: getAccessToken
  });
}

export function createDrayageContainer(legId, createContainerLegItem) {
  return createLegsApi().createLegItemLegsLegIdItemsPost(legId, createContainerLegItem);
}

export function updateLegItem(legId, legItemId, updateLegItemData) {
  return createLegsApi().updateLegItemLegsLegIdItemsLegItemIdPut(legId, legItemId, updateLegItemData);
}
