import {BillTo, OrderItem, OrderStop, PackagingType, PurchaseOrder, SalesOrder} from '@shipwell/corrogo-sdk';
import {
  BaseAddressSchema,
  BaseOrderSchema,
  BillToSchema,
  LineItemSchema,
  OrderResourceType,
  TempUnitType
} from '@shipwell/shipment-assembly-sdk';
import {hasLineItemsAndShipFrom, isTempUnit} from 'App/containers/orders/typeGuards';
import {
  getOrderReferencesObject,
  getOrderStopReferencesObject,
  isRefrigerationRequired
} from 'App/containers/orders/utils';
import {UnitPreferences} from 'App/reducers/types';
import {ShipwellCustomData} from 'App/utils/customDataPath';

//we send the order items to backend-core to create a shipment, and there are some discrepancies
//in the package type values. Also, package type is a string in the shipment assembly request, so
//here we cast the package type enum value to a string.
export const mapOrderItemPackageTypeToBackendCorePackageTypeString = (packageType: PackagingType) => {
  const unsupportedCorrogoPackageTypes = [
    PackagingType.Barrel,
    PackagingType.Basket,
    PackagingType.Container,
    PackagingType.Envelope,
    PackagingType.Hamper,
    PackagingType.None,
    PackagingType.Other,
    PackagingType.ProviderSpecific,
    PackagingType.Tank
  ];
  if (packageType === PackagingType.Pallet) {
    return 'PLT';
  }
  if (packageType === PackagingType.Piece) {
    return 'PIECES';
  }
  if (packageType === PackagingType.Package) {
    return 'PKG';
  }
  //if backend core does not supprt the corrogo package type, we convert the package
  //type to other
  if (unsupportedCorrogoPackageTypes.includes(packageType)) {
    return 'OTHER';
  }
  return packageType.toString();
};
export const mapOrderStopToBaseAddressSchema = (orderStop: OrderStop): BaseAddressSchema => {
  return {
    resource_type: 'address',
    address_1: orderStop.line_1,
    address_2: orderStop.line_2,
    city: orderStop.locality,
    state_province: orderStop.region,
    country: orderStop.country,
    postal_code: orderStop.postal_code,
    timezone: orderStop.timezone,
    //the BaseAddressSchema expects theses to be a number, even though they can be
    //undefined as per the OrderStop schema
    lat: orderStop.geolocation?.latitude || 0,
    long: orderStop.geolocation?.longitude || 0
  };
};

export const mapOrderItemsToLineItemsSchema = (
  orderItems: OrderItem[],
  companyUnitPreferences?: UnitPreferences
): LineItemSchema[] =>
  orderItems.map((item): LineItemSchema => {
    const totalItemWeight =
      item.shipping_requirements?.quantity && item.shipping_requirements.gross_weight?.value
        ? (Number(item.shipping_requirements.quantity) || 0) *
          (Number(item.shipping_requirements.gross_weight.value) || 0)
        : 0;
    return {
      resource_type: 'line_item',
      external_id: item.id,
      description: item.shipping_requirements?.description,
      total_line_item_weight: totalItemWeight,
      weight_unit: item.shipping_requirements?.gross_weight?.unit || companyUnitPreferences?.weightUnit,
      //just so that we preserve 0s entered in these fields (zero-dimensional objects?),
      //check the value of measurements before casting to a number
      height: item.shipping_requirements?.dimensions?.height
        ? Number(item.shipping_requirements.dimensions.height)
        : undefined,
      length: item.shipping_requirements?.dimensions?.length
        ? Number(item.shipping_requirements.dimensions.length)
        : undefined,
      width: item.shipping_requirements?.dimensions?.width
        ? Number(item.shipping_requirements.dimensions.width)
        : undefined,
      piece_type: item.shipping_requirements?.piece_type,
      length_unit: item.shipping_requirements?.dimensions?.unit || companyUnitPreferences?.lengthUnit,
      freight_class: item.shipping_requirements?.freight_class,
      stackable: item.shipping_requirements?.stackable,
      refrigeration_required: isRefrigerationRequired(item),
      total_pieces: item.shipping_requirements?.total_pieces,
      total_packages: Number(item.shipping_requirements?.quantity),
      package_type: item.shipping_requirements?.packaging_type
        ? mapOrderItemPackageTypeToBackendCorePackageTypeString(item.shipping_requirements?.packaging_type)
        : undefined,
      nmfc_item_code: item.shipping_requirements?.nmfc_item_code,
      nmfc_sub_code: item.shipping_requirements?.nmfc_sub_code,
      value_per_piece: Number(item.shipping_requirements?.value_per_piece?.value) || undefined,
      value_per_piece_currency: item.shipping_requirements?.value_per_piece?.currency_code,
      //since temperatures can be 0, we need to check the value of the temp before casting to a number.
      //Short circuit evaluation will not work here (0 is falsy and would be converted to undefined).
      refrigeration_min_temp: item.shipping_requirements?.temperature?.minimum
        ? Number(item.shipping_requirements?.temperature.minimum)
        : undefined,
      refrigeration_max_temp: item.shipping_requirements?.temperature?.maximum
        ? Number(item.shipping_requirements?.temperature.maximum)
        : undefined,
      temp_unit:
        item.shipping_requirements?.temperature?.unit && isTempUnit(item.shipping_requirements.temperature.unit)
          ? item.shipping_requirements.temperature.unit
          : (companyUnitPreferences?.temperatureUnit as TempUnitType),
      //there is not a provider_specific_packaging field in the corrogo order item
      package_weight: item.shipping_requirements?.gross_weight?.value,
      country_of_manufacture: item.shipping_requirements?.country_of_manufacture,
      custom_data: item.custom_data,
      hazmat_hazard_class: item.shipping_requirements?.hazmat?.hazard_class,
      hazmat_identification_number: item.shipping_requirements?.hazmat?.identification_number,
      hazmat_packing_group: item.shipping_requirements?.hazmat?.packing_group,
      hazmat_proper_shipping_name: item.shipping_requirements?.hazmat?.proper_shipping_name,
      handling_unit_id: item.shipping_requirements?.handling_unit_id,
      combined_handling_unit_info: item.shipping_requirements?.combined_handling_unit_info
    };
  });

export const mapCustomData = (order: PurchaseOrder | SalesOrder) => {
  const customDataOrder = order.custom_data as ShipwellCustomData;
  const customDataShipFrom = order?.ship_from?.custom_data as ShipwellCustomData;
  const customDataShipTo = order?.ship_to?.custom_data as ShipwellCustomData;

  if (!customDataShipFrom && !customDataShipTo) return order.custom_data;

  const customData = {
    shipwell_custom_data: {
      purchase_order: customDataOrder?.shipwell_custom_data?.purchase_order,
      purchase_order_stop: {
        pickup: customDataShipFrom?.shipwell_custom_data?.purchase_order_stop,
        delivery: customDataShipTo?.shipwell_custom_data?.purchase_order_stop
      }
    }
  };
  return customData;
};

const mapBillToData = (billTo: BillTo) => {
  const mappedData = {
    ...billTo,
    contact: {
      contact_email: billTo.contact?.email,
      contact_phone: billTo.contact?.phone_number
    }
  };

  return mappedData as BillToSchema;
};

export const mapPurchaseOrdersToBaseOrders = (
  selectedOrders: PurchaseOrder[],
  companyUnitPreferences?: UnitPreferences
): BaseOrderSchema[] =>
  selectedOrders.filter(hasLineItemsAndShipFrom).map((order) => ({
    external_id: order.id,
    resource_type: OrderResourceType.PurchaseOrder,
    order_number: order.order_number,
    order_name: order.name,
    purchase_order_number: getOrderReferencesObject(order.references)?.purchaseOrderNumber,
    customer_name: order.ship_to.company_name,
    supplier_name: order.supplier?.name,
    pickup_start_time: order.ship_from.shipping_requirements?.plan_window?.start,
    pickup_end_time: order.ship_from.shipping_requirements?.plan_window?.end,
    drop_off_start_time: order.ship_to.shipping_requirements?.plan_window?.start,
    drop_off_end_time: order.ship_to.shipping_requirements?.plan_window?.end,
    origin_dock_external_id: getOrderStopReferencesObject(order.ship_from.references).dockExternalId,
    destination_dock_external_id: getOrderStopReferencesObject(order.ship_to.references).dockExternalId,
    origin_address_book_entry: getOrderStopReferencesObject(order.ship_from.references).addressBookEntryId,
    destination_address_book_entry: getOrderStopReferencesObject(order.ship_to.references).addressBookEntryId,
    origin_address: mapOrderStopToBaseAddressSchema(order.ship_from),
    destination_address: mapOrderStopToBaseAddressSchema(order.ship_to),
    line_items: mapOrderItemsToLineItemsSchema(order.items, companyUnitPreferences),
    custom_data: mapCustomData(order),
    bill_to: order.shipping_requirements?.bill_to ? mapBillToData(order.shipping_requirements.bill_to) : undefined
  }));
