// NOTE: still imported my Ondemand/, Settings/forms, Shipment_Doc_Form,
// Shipment_Doc_Update_Form, SignIn_Form & SignUp_Form

// TODO: separate out what is used per page
import _ from 'lodash';
import {
  checkIsHoliday,
  phoneUtil,
  freightClasses,
  validateEmail,
  validatePhoneNumber,
  validateDollarValue,
  validatePercentValue
} from 'App/utils/globals';

const validate = (values) => {
  const errors = {
    user: {},
    company: {},
    markup: {}
  };
  const number = null;
  //manage customers
  const company = values.company || {};
  if (!company.name || !company.name.trim()) {
    errors.company.name = 'Company name is required.';
  }
  if (!company.primary_phone_number) {
    errors.company.primary_phone_number = 'Phone number is required.';
  }
  if (company.primary_phone_number && !validatePhoneNumber(company.primary_phone_number)) {
    errors.company.primary_phone_number = 'Phone number is invalid.';
  }

  if (!company.mailing_address || (company.mailing_address && !company.mailing_address.formatted_address)) {
    errors.company.mailing_address = 'Address is required.';
  }
  if (company.mailing_address && !company.mailing_address.address_1) {
    errors.company.mailing_address = 'A complete address is required.';
  }
  if (!company.primary_email) {
    errors.company.primary_email = 'Email is required.';
  }
  const user = values.user || {};
  //only check the following on new users (with no ID)
  if (!values.id) {
    if (!user.first_name) {
      errors.user.first_name = 'First name is required.';
    }
    if (!user.last_name) {
      errors.user.last_name = 'Last name is required.';
    }
  }
  if (company.primary_email && !validateEmail(company.primary_email)) {
    errors.company.primary_email = 'Invalid email address.';
  }
  if (company.billing_email && !validateEmail(company.billing_email)) {
    errors.company.billing_email = 'Invalid email address.';
  }

  if (!values.broker_imposed_credit_limit) {
    errors.broker_imposed_credit_limit = 'Credit limit is required.';
  }
  if (values.broker_imposed_credit_limit && !parseInt(values.broker_imposed_credit_limit)) {
    errors.broker_imposed_credit_limit = 'Credit limit must be a number.';
  }
  if (
    values.markup &&
    values.markup.ftl_markup_type == 1 &&
    values.markup.ftl_markup_flat_fee &&
    !validateDollarValue(values.markup.ftl_markup_flat_fee)
  ) {
    errors.markup.ftl_markup_flat_fee = 'Enter a valid number.';
  }

  if (
    values.markup &&
    values.markup.ftl_markup_type == 2 &&
    values.markup.ftl_markup_percentage &&
    !validatePercentValue(values.markup.ftl_markup_percentage)
  ) {
    errors.markup.ftl_markup_percentage = 'Enter a valid number.';
  }
  if (
    values.markup &&
    values.markup.ltl_markup_type == 1 &&
    values.markup.ltl_markup_flat_fee &&
    !validateDollarValue(values.markup.ltl_markup_flat_fee)
  ) {
    errors.markup.ltl_markup_flat_fee = 'Enter a valid number.';
  }
  if (
    values.markup &&
    values.markup.ltl_markup_type == 2 &&
    values.markup.ltl_markup_percentage &&
    !validatePercentValue(values.markup.ltl_markup_percentage)
  ) {
    errors.markup.ltl_markup_percentage = 'Enter a valid number.';
  }

  return errors;
};

export default validate;
