import _ from 'lodash';
import {Component} from 'react';
import {ControlLabel, FormGroup, InputGroup} from 'react-bootstrap';
import Select, {AsyncCreatable, Creatable} from 'react-select';

import {validateEmail} from 'App/utils/globals';

class renderEmailMultiselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailList: []
    };
  }

  addEmail(emailList) {
    const emailsToSave = [];
    if (Array.isArray(emailList)) {
      for (var i = 0; i < emailList.length; i++) {
        let type = 'string';
        let email = emailList[i];
        if (email.value.indexOf(',') >= 0) {
          email = email.value.split(',');
          type = 'object';
        } else if (email.value.indexOf(';') >= 0) {
          email = email.value.split(';');
          type = 'object';
        }
        if (validateEmail(email)) {
          if (type === 'object') {
            //turn each into an object that is saveable;
            for (var j = 0; j < email.length; j++) {
              email[j] = {value: email[j], label: email[j]};
              if (emailsToSave.filter((e) => e.value === email[j].value).length === 0) {
                emailsToSave.push(email[j]);
              }
            }
          } else {
            if (emailsToSave.filter((e) => e.value === email.value).length === 0) {
              emailsToSave.push(email);
            }
          }
        }
      }
    }
    this.setState({emailList: emailsToSave});
  }

  componentWillReceiveProps(nextProps) {
    // set the default value if passed in

    if (!_.isEqual(nextProps.defaultValue, this.props.defaultValue)) {
      this.setState({
        emailList: nextProps.defaultValue
      });
    }
  }

  render() {
    const {emailList} = this.state;
    const {
      input,
      meta: {touched, error},
      icon,
      label,
      data,
      req,
      sronly,
      allowNew,
      loadOptions,
      ...rest
    } = this.props;

    const CreatableSelect = loadOptions ? AsyncCreatable : Creatable;

    return (
      <FormGroup validationState={touched && error ? 'error' : null}>
        {label && (
          <ControlLabel className={sronly ? 'sr-only' : ''}>
            {label}
            {req && <span className="requiredField">*</span>}
          </ControlLabel>
        )}
        <InputGroup className="input-group-inline" style={{width: '100%'}}>
          {icon && (
            <InputGroup.Addon>
              <i className={icon} />
            </InputGroup.Addon>
          )}
          {allowNew ? (
            <CreatableSelect
              {...input}
              multi
              className={!icon ? 'no-icon' : ''}
              defaultValue={emailList ? emailList : this.props.defaultValue}
              onChange={(email) => this.addEmail(email)}
              placeholder={this.props.placeholder || ''}
              value={emailList ? emailList : this.props.defaultValue}
              options={data}
              loadOptions={loadOptions}
              onBlur={() => input.onBlur(emailList)}
              promptTextCreator={(value) =>
                validateEmail(value) || value.indexOf(',') >= 0 || value.indexOf(';') >= 0
                  ? 'Add new email: ' + value
                  : 'Invalid email: ' + value
              }
              {...rest}
            />
          ) : (
            <Select
              {...input}
              multi
              className={!icon ? 'no-icon' : ''}
              defaultValue={emailList ? emailList : this.props.defaultValue}
              onChange={(email) => this.addEmail(email)}
              placeholder={this.props.placeholder || ''}
              value={emailList ? emailList : this.props.defaultValue}
              options={data}
              onBlur={() => input.onBlur(emailList)}
              {...rest}
            />
          )}
        </InputGroup>
        {touched && error && (
          <p className="error-text">
            <i className="icon icon-Delayed" />
            {error}
          </p>
        )}
      </FormGroup>
    );
  }
}

export default renderEmailMultiselect;
