import moment from 'moment';
import {
  REQ_DATA_ADDRESS_BOOK,
  POST_ADDRESSBOOK,
  DELETE_ADDRESSBOOK,
  GET_ADDRESSBOOK,
  SELECT_ADDRESS_ADD,
  SELECT_ADDRESS,
  GET_LOCATION_TYPES,
  PUT_ADDRESSBOOK,
  CLEAR_ADDRESS_BOOK_FORM
} from 'App/actions/types';
import {formatHrMinSec} from 'App/utils/globals';

const INITIAL_STATE = {
  addressbook: [],
  locationTypes: [],
  isLoading: false,
  isTypesLoading: true,
  selectedAddress: {
    dock_hours_start: moment('8:00:00', formatHrMinSec).toDate(),
    dock_hours_end: moment('18:00:00', formatHrMinSec).toDate(),
    notes: null,
    location_name: null,
    location_type: {
      id: 1,
      name: 'Business (with dock or forklift)'
    },
    point_of_contacts: [
      {
        email: null,
        first_name: null,
        job_title: null,
        last_name: null,
        phone_number: null,
        preferences: {
          select_all: true,
          shipment_booked: true,
          picked_up: true,
          eta_changed: true,
          delayed: true,
          cancellation: true,
          delivered: true
        }
      }
    ],
    address: {
      address_1: null,
      address_2: null,
      city: null,
      state_province: null,
      postal_code: null,
      country: null,
      phone_number: null,
      latitude: null,
      longitude: null,
      timezone: null,
      formatted_address: null
    }
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQ_DATA_ADDRESS_BOOK:
      return {
        ...state,
        isLoading: true
      };
    case POST_ADDRESSBOOK:
      return {
        ...state,
        isLoading: false
      };

    case DELETE_ADDRESSBOOK:
      return {
        ...state,
        isLoading: false
      };

    case GET_ADDRESSBOOK:
      return {
        ...state,
        addressbook: action.payload,
        isLoading: false
      };

    // adding a new address
    case SELECT_ADDRESS_ADD:
      return {
        ...state,
        selectedAddress: INITIAL_STATE.selectedAddress
      };

    case SELECT_ADDRESS:
      const address = action.payload;
      // format hours for timefield to render
      // but you also have to display 24hr format in table
      address.dock_hours_start = moment(address.dock_hours_start, [formatHrMinSec]).toDate();
      address.dock_hours_end = moment(address.dock_hours_end, [formatHrMinSec]).toDate();

      //determine email notification settings here
      if (address.point_of_contacts && address.point_of_contacts.length > 0) {
        for (var i = 0; i < address.point_of_contacts.length; i++) {
          //we dont care about this for select all below
          const temp = JSON.parse(
            JSON.stringify(address.point_of_contacts[i].preferences.receive_bol_on_shipment_booked)
          );
          delete address.point_of_contacts[i].preferences.receive_bol_on_shipment_booked;

          if (
            Object.values(address.point_of_contacts[i].preferences).filter((e) => e === true).length ===
            Object.keys(address.point_of_contacts[i].preferences).length
          ) {
            //then all values are true
            address.point_of_contacts[i].preferences.select_all = true;
          } else {
            address.point_of_contacts[i].preferences.select_all = false;
          }
          address.point_of_contacts[i].preferences.receive_bol_on_shipment_booked = temp;
        }
      }

      return {
        ...state,
        selectedAddress: address
      };

    case GET_LOCATION_TYPES:
      return {
        ...state,
        locationTypes: action.payload,
        isTypesLoading: false
      };

    case PUT_ADDRESSBOOK:
      return {
        ...state,
        isLoading: false
      };

    case CLEAR_ADDRESS_BOOK_FORM:
      return {
        ...state,
        selectedAddress: {
          dock_hours_start: moment('8:00:00', formatHrMinSec).toDate(),
          dock_hours_end: moment('18:00:00', formatHrMinSec).toDate(),
          notes: null,
          location_name: null,
          location_type: {
            id: 1,
            name: 'Business (with dock or forklift)'
          },
          point_of_contacts: [
            {
              email: null,
              first_name: null,
              job_title: null,
              last_name: null,
              phone_number: null,
              preferences: {
                select_all: true,
                shipment_booked: true,
                picked_up: true,
                eta_changed: true,
                delayed: true,
                cancellation: true,
                delivered: true
              }
            }
          ],
          address: {
            address_1: null,
            address_2: null,
            city: null,
            state_province: null,
            postal_code: null,
            country: null,
            phone_number: null,
            latitude: null,
            longitude: null,
            timezone: null,
            formatted_address: null
          }
        }
      };

    default:
      return state;
  }
}
