import {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {Grid, Row, Col} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/users';
import NotificationForm from './forms/NotificationForm';

export class Notifications extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {saved: false};
  }

  componentDidMount() {
    this.props.getNotificationPreferences();
  }

  onSubmit(attrs) {
    const current = this.props.notificationOriginals;
    for (var i = 0; i < current.length; i++) {
      if (current[i].medium === 'inbox') {
        current[i].enabled = attrs[current[i].notification_type].inbox;
      } else if (current[i].medium === 'email') {
        current[i].enabled = attrs[current[i].notification_type].email;
      }
    }

    return this.props.editNotificationPreferences(current).then((response) => {
      if (response.status === 200) {
        this.setState({saved: true});
        setTimeout(() => {
          this.setState({saved: false});
        }, 5000);
      }
    });
  }

  render() {
    return (
      <div className="settings-form">
        <NotificationForm
          saved={this.state.saved}
          notificationPreferences={this.props.notificationPreferences}
          notificationTypes={this.props.notificationTypes}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.auth.company,
    notificationPreferences: state.users.notificationPreferences,
    notificationTypes: state.users.notificationTypes,
    notificationOriginals: state.users.notificationOriginals
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
