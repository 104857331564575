import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';

export async function getFuelSurchargeTables(opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesFuelSurchargeTablesGet(opts, apiCallback(resolve, reject));
  });
}

export async function createFuelSurchargeTable(fuelSurchargeTable, opts = {}) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesFuelSurchargeTablesPost(fuelSurchargeTable, opts, apiCallback(resolve, reject));
  });
}

export async function deleteFuelSurchargeTable(fuelSurchargeTableId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdDelete(
      fuelSurchargeTableId,
      apiCallback(resolve, reject)
    );
  });
}

export async function getFuelSurchargeTable(fuelSurchargeTableId) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdGet(
      fuelSurchargeTableId,
      apiCallback(resolve, reject)
    );
  });
}

export async function updateFuelSurchargeTable(fuelSurchargeTableId, fuelSurchargeTable) {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdPut(
      fuelSurchargeTableId,
      fuelSurchargeTable,
      apiCallback(resolve, reject)
    );
  });
}
