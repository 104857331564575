// NOTE: still imported my Ondemand/, Settings/forms, Shipment_Doc_Form,
// Shipment_Doc_Update_Form, SignIn_Form & SignUp_Form

// TODO: separate out what is used per page
import _ from 'lodash';
import {checkIsHoliday, phoneUtil, freightClasses, validateEmail} from 'App/utils/globals';

const validate = (values) => {
  const errors = {};

  if (!values.recipient_emails || values.recipient_emails.length === 0) {
    errors.recipient_emails = 'Enter at least one recipient';
  }
  if (!values.message) {
    errors.message = 'Enter a message';
  }

  return errors;
};

export default validate;
