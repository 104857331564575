import {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import pluralize from 'pluralize';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';
import {getTotalShipmentLineItemsWeightLbs} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields/utils';

export const TotalWeightLabel = () => {
  const {values} = useFormikContext<NewStageFormValues>();
  const [totalShipmentLineItemWeight, setTotalShipmentLineItemWeight] = useState<number>(0);
  useEffect(() => {
    const newTotalShipmentLineItemWeightLbs = getTotalShipmentLineItemsWeightLbs(values.line_items);
    setTotalShipmentLineItemWeight(newTotalShipmentLineItemWeightLbs);
  }, [values.line_items]);
  return (
    <>
      <span className="font-bold">Total Weight</span>
      <span>
        {`${totalShipmentLineItemWeight.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })} ${pluralize('LB', totalShipmentLineItemWeight)}`}
      </span>
    </>
  );
};
