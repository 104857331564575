import _ from 'lodash';
import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {Row, Col, Panel, Button, Image, ListGroup, ListGroupItem, Thumbnail} from 'react-bootstrap';
import {Document, Page} from 'react-pdf';
import Paper from '@material-ui/core/Paper';
import * as actions from '../../actions/documents';
import * as shipmentActions from '../../actions/shipments';
import SendShipmentDocumentsForm from './SendShipmentDocumentsForm';
import * as addressBookActions from 'App/actions/addressBook';
import * as formActions from 'App/actions/forms';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import DocumentsArray from 'App/components/DocumentsArray/DocumentsArray';
import {unpackErrors} from 'App/utils/globals';

var angle = 0;

@connect(
  (state) => ({
    shipmentDocuments: state.documents.documents,
    isLoading: state.documents.isLoading,
    one: state.shipmentdetails.one,
    error: state.documents.error,
    company: state.auth.company,
    addressBook: state.addresses.addressbook,
    user: state.auth.user,
    successfulDispatch: state.shipments.successfulDispatch
  }),
  {...actions, ...shipmentActions, ...addressBookActions, ...formActions}
)
export default class SendShipmentDocuments extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.showPreview = this.showPreview.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.state = {
      selectedDocuments: [],
      selectedDocument: null,
      showPreviewModal: false,
      sent: false
    };
  }

  componentDidMount() {
    if (!this.props.one || (Array.isArray(this.props.one) && this.props.one.length === 0)) {
      //if the shipment isnt found (on refresh), refetch the documents
      if (this.props.params.shipment_id && !_.isEmpty(this.props.company)) {
        this.fetchDocuments(this.props.params.shipment_id);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const id = this.props.params.shipment_id;
    if (id && nextProps.company && nextProps.company !== this.props.company) {
      this.fetchDocuments(id);
      this.props.addressBookGet({
        page: 1,
        pageSize: 10000,
        ordering: 'location_name',
        q: ''
      });
    }
  }

  fetchDocuments(id) {
    this.props.fetchDocuments(id).then((response) => {});
  }

  selectDocument(id) {
    const currentList = this.state.selectedDocuments;

    if (currentList.includes(id)) {
      //unselect it
      currentList.splice(currentList.indexOf(id), 1);
    } else {
      currentList.push(id);
    }
    this.setState({selectedDocuments: currentList});
  }

  showPreview(id) {
    const selectedDocument = this.props.shipmentDocuments.results.filter((e) => e.id === id)[0];
    if (selectedDocument) {
      this.setState({
        showPreviewModal: true,
        selectedDocument: selectedDocument
      });
    }
  }

  onFormSubmit(attrs) {
    const shipmentId = this.props.params.shipment_id;
    const body = attrs;
    body.documents = this.state.selectedDocuments;

    const emails = body.recipient_emails.map((email) => {
      return email.value;
    });

    body.recipient_emails = emails;

    //send the document;
    return this.props.shareDocument(shipmentId, body).then((response) => {
      if (response.status === 200) {
        this.setState({sent: true});
        this.props.resetSendDocumentsForm();
        setTimeout(() => {
          this.setState({sent: false});
          this.context.router.push('/shipments/' + shipmentId);
        }, 3000);
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  rotateImage() {
    angle += 90;
    if (angle === 360) {
      angle = 0;
    }
    var image = document.getElementById('docImg');
    image.className = 'rotate' + angle + ' shipmentDocument__previewImage';

    //javascript to correctly display image after rotation
    const imageContainer = document.getElementsByClassName('shipmentDocument__previewImage-container');
    const height = image.offsetHeight;
    const width = image.offsetWidth;
    let margin;
    if (angle === 90 || angle === 270) {
      // tall images
      if (height > width) {
        margin = (imageContainer[0].offsetWidth - width) / 2;
        //shrink to fit
        image.style.width = 'auto';
        image.style.height = width + 'px';
        //make sure modal hight fits pic
        imageContainer[0].style.height = height + 'px';
        //margin to center image
        image.style.margin = `0 ${margin}px`;
      } else {
        //margin to center image
        margin = (imageContainer[0].offsetWidth - height) / 2;
        image.style.margin = `0 ${margin}px`;
      }
      //make sure modal hight fits pic
      imageContainer[0].style.height = image.offsetWidth + 'px';
    } else {
      //clear to default
      image.style.width = 'unset';
      image.style.height = 'auto';
      imageContainer[0].style.height = 'auto';
      image.style.margin = '0 auto';
    }
  }

  render() {
    let sortedArray = [];
    const selectedDocArray = [];

    if (this.props.shipmentDocuments && this.props.shipmentDocuments.results) {
      sortedArray = this.props.shipmentDocuments.results.sort(function (a, b) {
        const keyA = new Date(a.updated_at),
          keyB = new Date(b.updated_at);
        if (a.type === 'BOL' && b.type !== 'BOL') {
          return -1;
        }
        if (b.type === 'BOL' && a.type !== 'BOL') {
          return 1;
        }

        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
        return 0;
      });

      if (this.state.selectedDocuments && this.state.selectedDocuments.length > 0) {
        this.state.selectedDocuments.forEach((item) => {
          selectedDocArray.push(this.props.shipmentDocuments.results.filter((e) => e.id === item)[0]);
        });
      }
    }

    return (
      <div className="shipment__document content-wrapper">
        <h1 className="section-header">Send Documents</h1>
        <section className="content">
          <div className="shipment__document-content">
            <Paper className="shipment__document-card">
              <DocumentsArray
                layout="vertical"
                showTooltip={false}
                highlightBOL={false}
                handleDocumentClick={(id) => this.selectDocument(id)}
                handlePreviewClick={(id) => this.showPreview(id)}
                documents={sortedArray}
                showSelections
                tooltipContent="preview"
                selectedDocuments={this.state.selectedDocuments}
              />
            </Paper>

            <SendShipmentDocumentsForm
              handleDocumentClick={(id) => this.selectDocument(id)}
              selectedDocuments={selectedDocArray}
              addressBook={this.props.addressBook}
              onSubmit={this.onFormSubmit}
              sent={this.state.sent}
              goBack={() => this.context.router.push('/shipments/' + this.props.params.shipment_id)}
            />
          </div>
        </section>

        <InfoModalWrapper
          show={this.state.showPreviewModal}
          onHide={() => {
            this.setState({
              showPreviewModal: false
            });
            angle = 0;
          }}
          bsSize="large"
          title="Document Preview"
          children={
            <div className="shipmentDocument__previewImageContainer">
              {this.state.selectedDocument && this.state.selectedDocument.file.indexOf('.pdf') === -1 && (
                <Fragment>
                  <div>
                    <Button className="pull-right" bsStyle="link" onClick={this.rotateImage}>
                      <i className="icon icon-Restart pad-right" />
                      Rotate Image
                    </Button>
                  </div>
                  <div className="shipmentDocument__previewImage-container">
                    <img
                      src={this.state.selectedDocument.file}
                      alt="shipment-document"
                      id="docImg"
                      className="shipmentDocument__previewImage"
                    />
                  </div>
                </Fragment>
              )}
              {this.state.selectedDocument && this.state.selectedDocument.file.indexOf('.pdf') !== -1 && (
                <object
                  style={{margin: '0px auto'}}
                  type="application/pdf"
                  data={this.state.selectedDocument.file}
                  width="100%"
                  height="600px"
                />
              )}
            </div>
          }
        />
      </div>
    );
  }
}
