import moment from 'moment';
import {validateEmail, validatePhoneNumber, validateIntegerValue, validateNumber, isWeekday} from 'App/utils/globals';
import validateNMFCCode, {validateNMFCSubCode} from 'App/utils/validateNMFCCode';

export const validate = (values) => {
  const errors = {};
  const isLTL = true;
  const isDrayage = false;

  errors.stops = [];
  if (values.stops && values.stops.length) {
    for (var i = 0; i < values.stops.length; i++) {
      errors.stops[i] = {};
      if (
        (values.stops[i] && Object.keys(values.stops[i]).length === 0) ||
        !values.stops[i].location ||
        Object.keys(values.stops[i].location).length === 0
      ) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      } else if (!values.stops[i]) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      } else if (
        values.stops[i] &&
        values.stops[i].location &&
        values.stops[i].location.address &&
        !values.stops[i].location.address.address_1
      ) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        };
      }
      if (!values.stops[i].location.company_name) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          company_name: 'Company required'
        };
      }

      if (values.stops[i].location.point_of_contacts && values.stops[i].location.point_of_contacts.length > 0) {
        errors.stops[i].location = {
          ...errors.stops[i].location,
          point_of_contacts: []
        };
        for (var k = 0; k < values.stops[i].location.point_of_contacts.length; k++) {
          errors.stops[i].location.point_of_contacts[k] = {};
          if (!values.stops[i].location.point_of_contacts[k].first_name) {
            errors.stops[i].location.point_of_contacts[k].first_name = 'First name required';
          }
          if (!values.stops[i].location.point_of_contacts[k].email) {
            errors.stops[i].location.point_of_contacts[k].email = 'Email required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].email &&
            !validateEmail(values.stops[i].location.point_of_contacts[k].email)
          ) {
            errors.stops[i].location.point_of_contacts[k].email = 'Invalid email';
          }
          if (!values.stops[i].location.point_of_contacts[k].phone_number) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Phone number required';
          }
          if (
            values.stops[i].location.point_of_contacts[k].phone_number &&
            !validatePhoneNumber(values.stops[i].location.point_of_contacts[k].phone_number)
          ) {
            errors.stops[i].location.point_of_contacts[k].phone_number = 'Invalid phone number';
          }
        }
      } else {
        errors.stops[i].location = {point_of_contacts: {}};
        errors.stops[i].location.point_of_contacts = {
          _error: 'At least one point of contact is required for each stop'
        };
      }
    }
  }
  if (values.stops && values.stops[0] && values.stops[0].planned_date) {
    //make sure date is not in the past
    if (isLTL && moment(values.stops[0].planned_date, 'MM/DD/YYYY').isBefore(moment(), 'day')) {
      errors.stops[0] = {};
      errors.stops[0].planned_date = 'Pickup date cannot be in the past';
    }
  }

  if (values.line_items && values.line_items.length > 0) {
    errors.line_items = [];
    for (var i = 0; i < values.line_items.length; i++) {
      errors.line_items[i] = {};
      if (!values.line_items[i].package_type && isLTL) {
        errors.line_items[i].package_type = 'Package type required';
      }
      if (!values.line_items[i].total_packages && isLTL) {
        errors.line_items[i].total_packages = 'Quantity required';
      } else if (values.line_items[i].total_packages && !validateIntegerValue(values.line_items[i].total_packages)) {
        errors.line_items[i].total_packages = 'Enter a valid whole number';
      }
      if (!values.line_items[i].height && isLTL) {
        errors.line_items[i].height = 'Height required';
      } else if (values.line_items[i].height && !validateNumber(values.line_items[i].height)) {
        errors.line_items[i].height = 'Enter a valid whole number';
      }
      if (!values.line_items[i].width && isLTL) {
        errors.line_items[i].width = 'Width required';
      } else if (values.line_items[i].width && !validateNumber(values.line_items[i].width)) {
        errors.line_items[i].width = 'Enter a valid whole number';
      }
      if (!values.line_items[i].length && isLTL) {
        errors.line_items[i].length = 'Length required';
      } else if (values.line_items[i].length && !validateNumber(values.line_items[i].length)) {
        errors.line_items[i].length = 'Enter a valid whole number';
      }
      if (!values.line_items[i].total_packages) {
        errors.line_items[i].total_packages = 'Quantity required';
      }
      if (!values.manual_total_weight && !values.line_items[i].package_weight) {
        errors.line_items[i].package_weight = 'Package Weight required';
      } else if (values.line_items[i].package_weight && !validateNumber(values.line_items[i].package_weight)) {
        errors.line_items[i].package_weight = 'Enter a valid number';
      }
      if (!values.line_items[i].description) {
        errors.line_items[i].description = 'Product description required';
      }

      if (values.line_items[i].total_pieces && !validateIntegerValue(values.line_items[i].total_pieces)) {
        errors.line_items[i].total_pieces = 'Enter a valid whole number';
      }

      if (
        values.line_items[i].hazmat === true &&
        (!values.line_items[i].hazmatDetails || values.line_items[i].hazmatDetails === null)
      ) {
        errors.line_items[i].hazmatDetails = 'Enter Hazmat details';
      }
      //in FTL, we dont need a freight class
      if ((!values.line_items[i].freight_class || values.line_items[i].freight_class === 'select') && isLTL) {
        errors.line_items[i].freight_class = 'Freight class required';
      }

      if (values.line_items[i].nmfc_item_code) {
        try {
          values.line_items[i].nmfc_item_code = validateNMFCCode(values.line_items[i].nmfc_item_code);
        } catch (e) {
          errors.line_items[i].nmfc_item_code = e.message;
        }
      }
      if (values.line_items[i].nmfc_sub_code) {
        try {
          values.line_items[i].nmfc_sub_code = validateNMFCSubCode(values.line_items[i].nmfc_sub_code);
        } catch (e) {
          errors.line_items[i].nmfc_sub_code = e.message;
        }
      }
    }
  }

  if (!values.purchase_order_number) {
    errors.purchase_order_number = 'PO # is required';
  }

  return errors;
};
