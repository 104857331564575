import {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import {connect} from 'react-redux';
import {Row, Image} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {signoutUser} from 'App/actions/auth';
import {setInitialShipmentValues, setInitialQuotingValues} from 'App/actions/shipments';
import {
  resetPowerUnitForm,
  resetTrailerForm,
  resetConfirmShipmentForm,
  resetEditShipmentForm,
  resetNewShipmentForm,
  resetNewLoadForm,
  resetNewShipmentQuotingForm
} from 'App/actions/forms';
import {signoutBrokerUser} from 'App/actions/brokers';
import {initialShipmentObj, initialQuotingObj} from 'App/utils/globals';
import {LOCALSTORAGE_DASHBOARD_COLUMNS} from 'App/containers/Dashboard/utils/constants';

const SignOut = ({brokerLogos, router}) => {
  const {logout, isAuthenticated} = useAuth0();

  const stackedColorLogo =
    brokerLogos?.length > 0 ? brokerLogos.find((logo) => logo.image_type === 'INLINE_COLOR') : '';

  useEffect(() => {
    cleanLocalStorage();
    setInitialValues();
    signoutUser();
    resetForms();

    const timeout = setTimeout(function () {
      signoutBrokerUser();
      if (isAuthenticated) {
        logout({returnTo: window.location.origin});
      } else {
        router.push('/');
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [cleanLocalStorage, setInitialValues, resetForms, logout, router, isAuthenticated]);

  const cleanLocalStorage = useCallback(() => {
    if (!localStorage.getItem('swlogout')) {
      localStorage.setItem('swlogout', 'logout' + Math.random());
    }
    localStorage.removeItem('dispatchInfoTab');
    localStorage.removeItem('dashboardSort');
    localStorage.removeItem('companyUsersSort');
    localStorage.removeItem('swimpersonator');
    localStorage.removeItem(LOCALSTORAGE_DASHBOARD_COLUMNS);
  }, []);

  const setInitialValues = useCallback(() => {
    setInitialShipmentValues(initialShipmentObj);
    setInitialQuotingValues(initialQuotingObj);
  }, []);

  //remove any half-entered shipments
  const resetForms = useCallback(() => {
    resetNewShipmentQuotingForm();
    resetNewShipmentForm();
    resetNewLoadForm();
    resetEditShipmentForm();
    resetConfirmShipmentForm();
    resetTrailerForm();
    resetPowerUnitForm();
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {brokerLogos?.length > 0 && (
            <Image
              src={stackedColorLogo.logo + '?cachebust=' + new Date().getTime()}
              height="80%"
              width="80%"
              style={{marginBottom: '16px'}}
            />
          )}
        </div>
        <div className="login-box-body">
          <br />
          <h4 className="login-box-msg">
            Signed out successfully.
            <br />
            You will be redirected to the login page.
          </h4>
          <Row />
        </div>
      </div>
    </div>
  );
};

SignOut.propTypes = {
  brokerLogos: PropTypes.arrayOf(
    PropTypes.shape({
      image_type: PropTypes.string,
      company_name: PropTypes.string
    })
  ),
  router: PropTypes.object.isRequired
};

export default connect(
  (state) => ({brokerLogos: state.brokers.brokerLogos}),
  (dispatch) => {
    return bindActionCreators(
      {
        resetPowerUnitForm,
        resetTrailerForm,
        resetConfirmShipmentForm,
        resetEditShipmentForm,
        resetNewShipmentForm,
        resetNewLoadForm,
        resetNewShipmentQuotingForm,
        signoutBrokerUser,
        signoutUser,
        setInitialShipmentValues,
        setInitialQuotingValues
      },
      dispatch
    );
  }
)(SignOut);
