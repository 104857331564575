import Search from 'App/components/_tableFiltersWithSavedViews/filterComponents/search';

export const filters = [
  {
    name: 'laneIdContains',
    label: 'Lane ID',
    type: Search,
    useArray: false
  },
  {
    name: 'originCityContains',
    label: 'Origin City',
    type: Search,
    useArray: false
  },
  {
    name: 'originStateContains',
    label: 'Origin State',
    type: Search,
    useArray: false
  },
  {
    name: 'originPostCodeContains',
    label: 'Origin Zip',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationCityContains',
    label: 'Destination City',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationStateContains',
    label: 'Destination State',
    type: Search,
    useArray: false
  },
  {
    name: 'destinationPostCodeContains',
    label: 'Destination Zip',
    type: Search,
    useArray: false
  }
];
