/* global */
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FieldArray, arrayPush, arraySplice, change} from 'redux-form';
// import {bindActionCreators} from 'redux';
import {FormGroup, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Modal, Button} from '@shipwell/shipwell-ui';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CurrencyOfRecordField} from './components/CurrencyOfRecordField';
import LoadFromContract from './components/LoadFromContract';
import renderField from 'App/formComponents/renderField';
import RenderMultipleFinancials, {FinancialsColumnHeaders} from 'App/formComponents/renderMultipleFinancials';
import renderMultipleFinancialsReadonly from 'App/formComponents/renderMultipleFinancialsReadonly';
import renderNoLabelField from 'App/formComponents/renderNoLabelField';
import * as actions from 'App/actions/shipments';
import {formatCurrency} from 'App/utils/internationalConstants';
import './_financials.scss';
import {FinancialTenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/FinancialTenderingUserPermissionFallback';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {
  VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS
} from 'App/components/permissions/PermissionsFallback/constants';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    chargeCategories: state.shipments.chargeCategories,
    unitPreferences: state.userCompany.unitPreferences
  }),
  {...actions, pushArray: arrayPush, spliceArray: arraySplice, changeField: change}
)
export class Financials extends Component {
  constructor(props) {
    super(props);

    if (this.props.triggerBillToEdit) {
      this.props.triggerBillToEdit();
    }

    this.state = {
      margin: '',
      showCustomerModal: false,
      financialItemsNumber: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.setMargin !== this.props.setMargin) {
      this.setState({margin: nextProps.setMargin});
    }
  }

  render() {
    const {company, unitPreferences, hideMarkup, formValues, form} = this.props;
    const canUseLoadboard = company?.feature_flags?.load_board_enabled;
    const activeCurrencyCode = this.props.formValues?.preferred_currency || unitPreferences.currency;

    const readOnly = Boolean(this.props.hasCustomerMarkup);
    let carrierTotal = 0;
    if (this.props.financialTotals?.length > 0) {
      carrierTotal = this.props.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
    }

    let customerTotal = formatCurrency(carrierTotal, activeCurrencyCode);
    if (this.props.markup && !isNaN(this.props.markup)) {
      customerTotal = formatCurrency(parseFloat(this.props.markup) + parseFloat(carrierTotal), activeCurrencyCode);
    }

    carrierTotal = formatCurrency(carrierTotal, activeCurrencyCode);

    const fieldArrayComponent = this.props.hasCustomerMarkup
      ? renderMultipleFinancialsReadonly
      : RenderMultipleFinancials;

    let categories = [];
    if (this.props.chargeCategories?.length > 0) {
      categories = this.props.chargeCategories.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }

    const handleModalClose = () => {
      this.setState({showCustomerModal: false});
      this.props.spliceArray(form, 'financials', this.state.financialItemsNumber, formValues?.financials?.length);
    };

    return (
      <div className={`${this.props.className ? this.props.className : ''} ${readOnly && 'readonly'}`}>
        <h2 className="flex items-center justify-between">
          Financials
          {form === 'newShipmentForm' ? <LoadFromContract /> : null}
        </h2>
        <div className="quote-flow__financials">
          <CurrencyOfRecordField />
          <FinancialTenderingUserPermissionFallback permissions={[VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]}>
            {canUseLoadboard ? (
              <>
                <h5 className="font-bold">Internal Pricing</h5>
                <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                  <div className="quote-flow__financials-maxBuy gap-x-[10px]">
                    {canUseLoadboard && (
                      <Field
                        name="metadata.buy_it_now_amount"
                        label="Book Now"
                        placeholder="Enter a price"
                        prepend={activeCurrencyCode}
                        component={renderField}
                      />
                    )}

                    <Field
                      name="metadata.max_buy_amount"
                      label="Max Buy"
                      placeholder="Enter a price"
                      prepend={activeCurrencyCode}
                      component={renderField}
                    />
                    <Field
                      name="metadata.target_rate_amount"
                      label="Target Rate"
                      placeholder="Enter a price"
                      prepend={activeCurrencyCode}
                      component={renderField}
                    />
                  </div>
                </TenderingUserPermissionFallback>
              </>
            ) : null}
            <div className="quote-flow__financialsForm">
              {hideMarkup ? (
                <h5>
                  <b>Customer</b>
                </h5>
              ) : null}

              <>
                {formValues?.financials?.length > 0 ? (
                  <div>
                    <table className="w-full">
                      <thead>
                        <tr className="uppercase text-sw-disabled-text">
                          <th className="pb-1.5 text-xxs font-normal">Category</th>
                          <th className="pb-1.5 text-xxs font-normal">Description</th>
                          <th className="pb-1.5 text-xxs font-normal">Rate</th>
                          <th className="pb-1.5 text-xxs font-normal">Qty</th>
                          <th className="w-24 pb-1.5 text-xxs font-normal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValues?.financials?.map((financial, index) => (
                          <tr key={index}>
                            <td className="text-sm">{`${financial.charge_code} - ${financial.unit_name} (${financial.category})`}</td>
                            <td className="text-sm">{financial.unit_name}</td>
                            <td className="text-sm">{financial.unit_amount}</td>
                            <td className="text-sm">{financial.unit_quantity}</td>
                            <td className="w-24 text-sm">
                              {formatCurrency((financial.unit_amount ?? 0) * financial.unit_quantity)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
                <div className="quote-flow__financialsLineItem-addButtons pad-top pl-4">
                  <Button
                    variant="tertiary"
                    iconName="AddCircleOutlined"
                    onClick={() => {
                      this.setState({financialItemsNumber: formValues?.financials?.length ?? 0});
                      this.setState({showCustomerModal: true});
                      this.props.pushArray(form, 'financials', {
                        unit_quantity: 1,
                        category: ChargeCategory.Lh,
                        charge_code: 'LHS'
                      });
                    }}
                    isCompact
                  >
                    Add Line Item
                  </Button>
                </div>
                <Modal
                  show={this.state.showCustomerModal}
                  footerComponent={() => (
                    <div className="flex flex-row-reverse items-center justify-start p-6 gap-6">
                      <Button
                        onClick={() => this.setState({showCustomerModal: false})}
                        disabled={
                          !formValues?.financials?.every((item) => {
                            const isAllFieldsFilled =
                              !!item.category &&
                              !!item.charge_code &&
                              !!item.unit_amount &&
                              !!item.unit_quantity &&
                              !!item.unit_name;

                            return isAllFieldsFilled;
                          })
                        }
                      >
                        Save
                      </Button>
                      <Button variant={'secondary'} onClick={handleModalClose}>
                        Cancel
                      </Button>
                    </div>
                  )}
                  onClose={handleModalClose}
                  title="Customer Financial"
                  size="large"
                >
                  <div className="new-financials-modal-form mb-[105px]">
                    <FinancialsColumnHeaders labels={['Charge Item', 'Description', 'Rate', 'Qty', 'Total']} />
                    <FieldArray
                      name="financials"
                      component={fieldArrayComponent}
                      props={{
                        financialTotals: this.props.financialTotals,
                        prepaidTotals: this.props.prepaidTotals,
                        chargeCategories: categories,
                        changeField: this.props.changeField,
                        form: 'newShipmentForm',
                        chargeCodesValues: formValues?.financials
                      }}
                      currency={activeCurrencyCode}
                    />
                  </div>
                </Modal>
              </>
            </div>
            {!hideMarkup ? (
              <>
                <div className="quote-flow__financialsTotalRow-border">
                  <h5>Shipment Total</h5>
                  <p className="quote-flow__financialsTotalRow-total">{carrierTotal}</p>
                </div>

                <div className="quote-flow__financialsTotalRow">
                  <h5>Markup</h5>
                  <span className="quote-flow__financialsTotalRow-markup">
                    <span className="quote-flow__financialsTotalRow-total">
                      <FormGroup>
                        <FormControl
                          value={this.state.margin}
                          placeholder="% Margin"
                          onChange={(val) => {
                            this.setState({
                              margin: val.target.value
                            });
                          }}
                          onBlur={(val) => this.props.dispatchMarkupChange(parseFloat(val.target.value))}
                        />
                      </FormGroup>
                    </span>
                    <span className="quote-flow__financialsTotalRow-total">
                      <Field
                        component={renderNoLabelField}
                        name="markup"
                        placeholder="Markup"
                        prepend={activeCurrencyCode}
                      />
                    </span>
                  </span>
                </div>
              </>
            ) : null}
            <div className="quote-flow__financialsTotalRow-grandTotal">
              <h5>Customer Total</h5>
              <p className="quote-flow__financialsTotalRow-total">{customerTotal}</p>
            </div>
          </FinancialTenderingUserPermissionFallback>
        </div>
      </div>
    );
  }
}

Financials.propTypes = {
  triggerBillToEdit: PropTypes.func,
  setMargin: PropTypes.string,
  company: PropTypes.object,
  unitPreferences: PropTypes.object,
  financialTotals: PropTypes.array,
  prepaidTotals: PropTypes.array,
  markup: PropTypes.number,
  hasCustomerMarkup: PropTypes.bool,
  chargeCategories: PropTypes.array,
  hideMarkup: PropTypes.bool,
  formValues: PropTypes.object,
  className: PropTypes.string,
  dispatchMarkupChange: PropTypes.func,
  form: PropTypes.string,
  pushArray: PropTypes.func,
  spliceArray: PropTypes.func,
  changeField: PropTypes.func
};
