import {ChangeEvent, useMemo, useState} from 'react';
import {Loader, SearchBar, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import debounce from 'lodash/debounce';
import {WithRouterProps} from 'react-router';
import {isNil, omitBy, omit} from 'lodash';
import {useQuery} from '@tanstack/react-query';
import PageHeader from 'App/common/pageHeader';
import Table from 'App/components/Table';
import {columnDataAlpha} from 'App/containers/userCompany/rfpsAlpha/RFPColumnsAlpha';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {formatDateToMMDDYY} from 'App/utils/dateTimeGlobals';
import {RFP_CSV_QUERY, RFP_DETAILS_QUERY, RFP_ROWS_QUERY} from 'App/data-hooks/queryKeys';
import {getRfpCsv, getRfpDetails, getRfpRows, RfpRowNamedParameters} from 'App/api/rfp/typed';
import {TableFiltersContextConsumer, TableFiltersContextProvider} from 'App/utils/hooks/useTableFilters';
import TableFiltersWithSavedViews from 'App/components/_tableFiltersWithSavedViews';
import buildPathParams from 'App/utils/buildPathParams';
import {filtersAlpha} from 'App/containers/userCompany/rfpsAlpha/RFPFiltersAlpha';
import ListViewLoader from 'App/containers/purchaseOrders/list/components/ListViewLoader';
import NoFilterResultsView from 'App/components/Table/components/NoFilterResultsSprinkles';
import {PAGE_OPTION_DEFAULTS} from 'App/containers/userCompany/constants';

const RFPTableAlpha = ({params, router, location}: Omit<WithRouterProps, 'routes'>) => {
  const [pageOptions, setPageOptions] = useState(PAGE_OPTION_DEFAULTS);
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearch = debounce((val: string) => setSearchVal(val), 300);
  const [columns, setColumns] = useState(columnDataAlpha);

  const id = params.rfpId;
  const rfpDetailsQuery = useQuery([RFP_DETAILS_QUERY, id], async () => {
    const response = await getRfpDetails(id);
    return response.data;
  });

  const query: RfpRowNamedParameters = {
    ...omitBy(omit(location.query, 'viewId'), isNil),
    ...pageOptions,
    ...(searchVal !== '' && {q: searchVal})
  };

  const rfpRowsQuery = useQuery(
    [RFP_ROWS_QUERY, id, query],
    async () => {
      const response = await getRfpRows(id, query);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  const hasQueryParams = Object.keys(omit(query, 'page', 'rfpBidId'))?.length > 0;

  const rfpCsvQuery = useQuery(
    [RFP_CSV_QUERY, id],
    async () => {
      const response = await getRfpCsv(id);
      return response.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const csvHeader = 'data:text/csv;charset=uft-8,';
        const csvContent = `${csvHeader}${data}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${rfpName}.csv`);
        document.body.appendChild(link);
        link.click();
      }
    }
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const handleFetchData = ({
    pageSize,
    pageIndex
  }: {
    pageSize: RfpRowNamedParameters['pageSize'];
    pageIndex: RfpRowNamedParameters['page'];
  }) => {
    setPageOptions({pageSize: pageSize || 20, page: pageIndex ? (pageIndex || 0) + 1 : 1});
  };

  const handleDownload = () => {
    void rfpCsvQuery.refetch();
  };

  const rfpRowData = rfpRowsQuery.data?.results;
  const data = useMemo(() => rfpRowData || [], [rfpRowData]);

  if (rfpDetailsQuery.isInitialLoading) {
    return <ListViewLoader />;
  }

  const rfpDetailsData = rfpDetailsQuery.data;
  const rfpName = rfpDetailsData?.name || id;
  const rfpStart = formatDateToMMDDYY(rfpDetailsData?.start_date || '');
  const rfpEnd = formatDateToMMDDYY(rfpDetailsData?.end_date || '');
  return (
    <TableFiltersContextProvider tableType="RFPAlpha" router={router} location={location} columns={columnDataAlpha}>
      <TableFiltersContextConsumer>
        {() => {
          return (
            <div className="RFPTableAlpha relative flex size-full flex-col overflow-hidden">
              <PageHeader
                backArrow
                backRoute="/company/lane-management/rfps_import"
                title={`${rfpName} (${rfpStart} - ${rfpEnd})`}
                actions={
                  <DeprecatedButton
                    onClick={handleDownload}
                    disabled={rfpCsvQuery.isInitialLoading}
                    icon={<SvgIcon name="Download" />}
                    variant="tertiary"
                  >
                    Download Imported File
                  </DeprecatedButton>
                }
              >
                <SearchBar label="Search RFP" name="rfp-search" onChange={handleSearch} />
              </PageHeader>
              <div className="flex h-full overflow-hidden">
                <TableFiltersWithSavedViews
                  router={router}
                  location={location}
                  buildPathParams={buildPathParams}
                  filters={filtersAlpha}
                  tableType="RFPAlpha"
                  tableColumns={columns}
                  setTableColumns={setColumns}
                />
                <Table
                  columns={columns}
                  data={data}
                  allowSort
                  manualSort={false}
                  showPaginationFooter
                  pageCount={rfpRowsQuery.data?.total_pages}
                  onFetchData={handleFetchData}
                  initialPageSize={20}
                />
                {data.length <= 0 ? (
                  hasQueryParams ? (
                    <NoFilterResultsView />
                  ) : (
                    <EmptyListView itemLabel="Table Data" />
                  )
                ) : null}
              </div>
              <Loader show={rfpDetailsQuery.isInitialLoading}>Retreiving data...</Loader>
            </div>
          );
        }}
      </TableFiltersContextConsumer>
    </TableFiltersContextProvider>
  );
};

export default RFPTableAlpha;
