import {useMemo} from 'react';
import {ItemWithStepsCard, ItemWithStepsCardProps} from '../../itemWithStepsCard/itemWithStepsCard';
import {InvocationData} from '../useScriptsService/invocationData';
import {getCardPropsFromInvocationData} from './helpers';
import {LogModal} from './logModal/logModal';
import {CardTitle} from './cardTitle/cardTitle';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';

export interface InvocationCardProps {
  invocation: InvocationData;
}

export function InvocationCard(props: InvocationCardProps) {
  const logModal = useVisibility();

  const cardProps = useMemo<ItemWithStepsCardProps>(
    () => getCardPropsFromInvocationData(props.invocation),
    [props.invocation]
  );

  return (
    <>
      <ItemWithStepsCard
        title={<CardTitle title={cardProps.title} onClick={logModal.toggle} />}
        metaInfos={cardProps.metaInfos}
        stepsInfo={cardProps.stepsInfo}
        status={cardProps.status}
      />

      <LogModal invocationId={props.invocation.id} show={logModal.isVisible} onClose={logModal.toggle} />
    </>
  );
}
