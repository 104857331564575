import {
  FETCH_BILLINGSOURCE,
  DELETE_BILLINGSOURCE,
  ADD_BILLINGSOURCE,
  FETCH_ALL_SUBSCRIPTION_PLANS,
  FETCH_CURRENT_SUBSCRIPTION
} from '../actions/types';

const INITIAL_STATE = {
  billingSources: [],
  allSubscriptionPlans: [],
  currentSubscription: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_BILLINGSOURCE:
      return {
        ...state,
        billingSources: action.payload.banks.concat(action.payload.cards)
      };
    case DELETE_BILLINGSOURCE:
      const sourceToRemove = action.payload.id;
      const allSources = state.billingSources.filter(function (item) {
        return item.id !== sourceToRemove;
      });
      return {
        ...state,
        billingSources: allSources
      };
    case ADD_BILLINGSOURCE:
      if (state.billingSources.length > 0) {
        return Object.assign({}, state, {
          billingSources: [...state.billingSources, action.payload]
        });
      }
      return {
        ...state,
        billingSources: [action.payload]
      };

    case FETCH_ALL_SUBSCRIPTION_PLANS:
      return {
        ...state,
        allSubscriptionPlans: action.payload
      };
    case FETCH_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload
      };
  }
  return state;
}
