import {useMemo} from 'react';
import {Shipment, TenderGet} from '@shipwell/backend-core-sdk';
import {Link} from 'react-router';

import {Card, SvgIcon, Rule, Title, DisplayValue, CollapsibleCardContent, Pill} from '@shipwell/shipwell-ui';
import Table from 'App/components/Table';
import {useGetTenders} from 'App/data-hooks';
import {formatCurrency} from 'App/utils/internationalConstants';
import {formatDateTime} from 'App/utils/globals';

type LoadCardProps = {
  ordinalIndex: number;
  isCollapsed: boolean;
  onCollapse: () => void;
  draggableProvided: {
    draggableProps: object;
    dragHandleProps: object;
    innerRef: unknown;
  };
  shipment: Shipment;
};

const getTenderStatus = (status: string) => {
  switch (status) {
    case 'open':
      return 'Requested';
    case 'accepted':
      return 'Accepted';
    case 'rejected':
      return 'Rejected';
    case 'revoked':
      return 'Revoked';
    case 'expired':
      return 'Expired';
    default:
      return '';
  }
};

const LoadCard = ({ordinalIndex = 0, isCollapsed = false, onCollapse, draggableProvided, shipment}: LoadCardProps) => {
  const {data} = useGetTenders({shipmentId: shipment.id});
  const tenders = data?.results || [];
  const hasOpenAuction = shipment?.metadata?.has_open_auction || false;
  const auctionStats = shipment?.auction_stats;

  const columns = useMemo(
    () => [
      {
        Header: () => <b>Carrier</b>,
        accessor: 'tender_to_company.name',
        maxWidth: 143,
        Cell: ({value}: {value: string}) =>
          value ? (
            <span className="break-words whitespace-normal" title={value}>
              {value}
            </span>
          ) : (
            '--'
          )
      },
      {
        Header: () => <b>Rate</b>,
        accessor: 'rate',
        maxWidth: 98,
        Cell: ({row: {original}}: {row: {original: TenderGet}}) => {
          if (original?.charge_line_items) {
            const result = original?.charge_line_items.reduce(
              (total, item) => total + item.unit_amount * item.unit_quantity,
              0
            );

            const currencyCode = original?.charge_line_items?.[0]?.unit_amount_currency || '';

            return (
              <span className="truncate text-nowrap" title={formatCurrency(result, currencyCode)}>
                {formatCurrency(result, currencyCode)}
              </span>
            );
          }
          return '--';
        }
      },
      {
        Header: () => <b>Tender Status</b>,
        accessor: 'status',
        maxWidth: 111,
        Cell: ({value}: {value: string}) =>
          value ? (
            <span className="truncate text-nowrap" title={getTenderStatus(value)}>
              {getTenderStatus(value)}
            </span>
          ) : (
            '--'
          )
      }
    ],
    []
  );

  const dataEmpty = [
    {
      tendered: '',
      rate: '',
      requested: ''
    }
  ];

  return (
    <Card
      title={
        <div className="flex items-center py-4">
          <span className="mr-3">
            <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
          </span>
          <div className="flex items-baseline">
            <span>Load</span>
            <span className="text-grey-5 text-xxs font-normal">
              <span className="mx-1">•</span>
              {shipment?.metadata?.load_board_id}
            </span>
          </div>
        </div>
      }
      tabOrder={ordinalIndex}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      draggableProvided={draggableProvided}
      bodyClassName="p-4 empty:p-0"
    >
      <CollapsibleCardContent>
        <div className="mb-4 flex items-center justify-between">
          <span className="flex items-center gap-2">
            <Title variant="formTitle">Auction</Title>
            <Link to={`/shipments/${shipment.id}/bids`}>View Details</Link>
          </span>
          <Pill variant="inactive">{hasOpenAuction ? 'RUNNING' : 'INACTIVE'}</Pill>
        </div>
        <div>
          <div className="mb-2 grid grid-cols-2">
            <DisplayValue label="Visibility">
              {auctionStats?.visible_companies ? `${auctionStats?.visible_companies} Carriers` : '--'}
            </DisplayValue>
            <DisplayValue label="Bids from all boards">
              {auctionStats?.quotes ? auctionStats?.quotes : '--'}
            </DisplayValue>
            <DisplayValue label="Last bid">
              {auctionStats?.most_recent_quote_created_at
                ? formatDateTime(auctionStats?.most_recent_quote_created_at, true)
                : '--'}
            </DisplayValue>
          </div>
        </div>
        <div className="mb-4">
          <Rule />
        </div>
        <div className="mb-4 flex items-center justify-between">
          <span className="flex items-center gap-2">
            <Title variant="formTitle">Tenders</Title>
            <Link to={`/shipments/${shipment.id}/bids`}>View Details</Link>
          </span>
        </div>
        <div className="overflow-hidden rounded border border-sw-border">
          <Table columns={columns} data={tenders?.length ? tenders : dataEmpty} />
        </div>
      </CollapsibleCardContent>
    </Card>
  );
};

export default LoadCard;
