import {useState} from 'react';
import {useQuery} from '@tanstack/react-query';

import {CORE_SHIPMENT_QUERY_KEY, SHIPMENT_SMS_TRACKING} from '../queryKeys';

import {getShipmentSmsTrackingInfo, getShipmentViaSmsToken} from 'App/api/shipment/typed';
import {parseV3ApiError} from 'App/api/typedUtils';

export enum SmsToLocateState {
  Loading = 'Loading',
  Unauthorized = 'Unauthorized',
  Loaded = 'Loaded',
  // statuses included in anticipation of this hook also providing verbs
  SubmittingApprove = 'SubmittingApprove',
  SubmittingDecline = 'SubmittingDecline',
  SubmittedApproved = 'SubmittedApproved',
  SubmittedDecline = 'SubmittedDecline'
}

export function useSmsToLocateTrackingInfo({token, shipmentId}: {token: string; shipmentId: string}): {
  status: SmsToLocateState;
  data?: {
    shipmentId: string;
    shipmentReferenceId: string;
    shipmentStatus: string;
    lastLocationAddress: string;
    lastLocationTime: Date;
    customerName: string;
  };
} {
  const [status, setStatus] = useState(SmsToLocateState.Loading);

  const shipmentQuery = useQuery([CORE_SHIPMENT_QUERY_KEY, shipmentId], () =>
    getShipmentViaSmsToken(token, shipmentId)
  );
  const trackingQuery = useQuery([SHIPMENT_SMS_TRACKING, shipmentId], () =>
    getShipmentSmsTrackingInfo(token, shipmentId)
  );

  if (status !== SmsToLocateState.Loading && (trackingQuery.isLoading || shipmentQuery.isLoading)) {
    setStatus(SmsToLocateState.Loading);
  }

  const queryError = shipmentQuery.error ?? trackingQuery.error;
  if (queryError) {
    const parsed = parseV3ApiError(queryError);
    if (parsed.status === 401 || parsed.status === 403) {
      if (status !== SmsToLocateState.Unauthorized) {
        setStatus(SmsToLocateState.Unauthorized);
      } else {
        throw queryError;
      }
    }
  }

  if (status === SmsToLocateState.Loading && shipmentQuery.data && trackingQuery.data) {
    setStatus(SmsToLocateState.Loaded);
  }

  const lastLocation = trackingQuery.data?.shipment_locations?.reduce((a, b) =>
    a.update_time > b.update_time ? a : b
  );
  const lastLocationTime = new Date(lastLocation?.update_time ?? 0);
  const lastLocationAddress = lastLocation?.location_name ?? '';

  return {
    status,
    data:
      status === SmsToLocateState.Loading || status === SmsToLocateState.Unauthorized
        ? undefined
        : {
            shipmentId,
            shipmentReferenceId: shipmentQuery.data?.reference_id ?? '',
            shipmentStatus: shipmentQuery.data?.state ?? '',
            lastLocationAddress,
            lastLocationTime,
            customerName: shipmentQuery.data?.customer?.name ?? ''
          }
  };
}
