export const OPTIMIZATION_LIST_INITIAL_PAGE_SIZE = 20;
export const OPTIMIZATION_LIST_INITIAL_SORT_FIELD = 'created_at';
export const OPTIMIZATION_LIST_INITIAL_DIRECTION = 'DESC';
export const OPTIMIZATION_CONFIG_LIST_INITIAL_PAGE_SIZE = 20;
export const OPTIMIZATION_CONFIG_LIST_INITIAL_SORT_FIELD = 'created_at';
export const OPTIMIZATION_CONFIG_LIST_INITIAL_DIRECTION = 'DESC';
export const OPTIMIZATION_LIST_TABLE_TYPE = 'LOAD_PLANS';

//order assigned to shipment
export const OPTIMIZATION_ORDER_ASSIGNED = 'Assigned';
export const OPTIMIZATION_ORDER_UNASSIGNED = 'Unassigned';
