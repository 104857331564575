import {TiveDetailsFormValues} from './tiveDetailsFormValues';
import {initialValues} from './initialValues';
import {validationSchema} from './validationSchema';
import {useEnhancedFormik} from 'App/hooks/useEnhacedFormik/useEnhacedFormik';

export interface UseTiveDetailsFormProps {
  initialValues?: TiveDetailsFormValues;
  onSubmit: (values: TiveDetailsFormValues) => void;
}

export function useTiveDetailsForm(props: UseTiveDetailsFormProps) {
  const form = useEnhancedFormik<TiveDetailsFormValues>({
    initialValues: props.initialValues || initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: props.onSubmit
  });

  return {
    ...form
  };
}
